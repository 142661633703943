import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setViewShip } from '../../store/actions/action';
import { dateTimeFormatter_DatePicker, formatDateTime_Application } from '../../utils/dateFormaters';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/bookVesselValidationRules';


const ViewShip = ({ userTokenDetails, viewShip, setViewShip, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [currencyData, setcurrencyData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [data, setData] = useState({
    charterType: viewShip.info.value.charter_type
  });
  const [showLoader, setshowLoader] = useState(false);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryData, setcountryData] = useState([]);

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  console.log('viewShip in ViewShip==>', viewShip);
  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    setData({
      ...data,
      'bookedFrom': viewShip.info.value.booked_from,
      'bookedTo': viewShip.info.value.booked_to
    })

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('get', 'getcurrencylist').then((result) => {
      setshowLoader(false)
      setcurrencyData(result)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    generateFormEntry()
    //------------------------------------------------------------------
  }, [])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      updateForm()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit Handlers

  function generateFormEntry() {
    console.log("viewShip.info.value===>", viewShip)

    if (viewShip.info.value && (Object.keys(viewShip.info.value).length)) {
      setData(data => ({
        ...data,
        id: viewShip.info.value.id,
        shipIMO: viewShip.info.value.imo_no,
        charterType: viewShip.info.value.charter_type,
        charterStart: dateTimeFormatter_DatePicker(viewShip.info.value.charter_start),
        charterEnd: dateTimeFormatter_DatePicker(viewShip.info.value.charter_end),
        price: viewShip.info.value.price,
        currency: viewShip.info.value.currency,
        loadingCountry: viewShip.info.value.load_country,
        loadingPort: viewShip.info.value.load_port,
        unloadingCountry: viewShip.info.value.unload_country,
        unloadingPort: viewShip.info.value.unload_port,
        bookedBy: viewShip.info.value.booked_by,
        bookedTo: viewShip.info.value.booked_to
      }));

      if (viewShip.info.value.charter_type / 1 === 1) {
        loadPort({ target: { value: viewShip.info.value.load_country }, persist: () => { } })
        unloadPort({ target: { value: viewShip.info.value.unload_country }, persist: () => { } })
      }

    }
  }

  const updateForm = () => {
    setshowLoader(true)
    call('post', 'updateShipBook', data).then((result) => {
      setshowLoader(false)
      setViewShip(false, { refresh: true });
      // toastDisplay("Ship Contract updated successfuly", "success");
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in updateShipBook', e);
    })
  }

  const prehandleUpdate = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  const confirmForm = () => {
    setshowLoader(true)
    let obj = {
      id: viewShip.info.value.id,
      bookedFromFlag: viewShip.info.value.booked_from_flag,
      bookedToFlag: viewShip.info.value.booked_to_flag,
      shipId: viewShip.info.value.tbl_ship_id,
      bookedFrom: viewShip.info.value.booked_from,
      bookedTo: viewShip.info.value.booked_to,
      shipIMO: viewShip.info.value.imo_no,
    }

    if (viewShip.info.value.booked_from / 1 === userId / 1) {
      obj.bookedFromFlag = 1
    } else if (viewShip.info.value.booked_to / 1 === userId / 1) {
      obj.bookedToFlag = 1
    }

    call('post', 'confirmShipBook', obj).then((result) => {
      setshowLoader(false)
      setViewShip(false, { refresh: true });
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in confirmShipBook', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Ports List Component
  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <div className="modal show" id="CreateContract">
      <div className={"modal-dialog modal-xl border-inner "} id="parent_class">
        <div className="modal-content">
          <div className="modal-header primary">
            <h4 className="modal-title text-white">Ship Contract</h4>
            <button type="button" className="close" onClick={() => setViewShip(false, {})}>×</button>
          </div>
          <div className="modal-body calc-modal">
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div className="modal-padding">
              <ul className="price-ul">
                <li>
                  <div className="price-left">{viewShip.info.value.imo_no}</div>
                  <div className="price-right">IMO No. </div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.ship_name} </div>
                  <div className="price-right"> Name of Ship</div>
                </li>
                <li>
                  <div className="price-left">{viewShip.info.value.type} </div>
                  <div className="price-right"> Ship Type</div>
                </li>
                <li>
                  <div className="price-left"> {formatDateTime_Application(viewShip.info.value.date_manufacture)} </div>
                  <div className="price-right">Date of Manufacture</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.registry_port} </div>
                  <div className="price-right">Port of Registry</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.open_from_country}</div>
                  <div className="price-right">Open From</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.ship_status === 0 ? 'Pending' : 'Booked'} </div>
                  <div className="price-right">Ship Status</div>
                </li>

                <li>
                  <div className="price-left"> {viewShip.info.value.lengthv} </div>
                  <div className="price-right">Length</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.breadthv} </div>
                  <div className="price-right">Breadth</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.depth} </div>
                  <div className="price-right">Depth</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.draft} </div>
                  <div className="price-right">Draft</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.dead_weight} </div>
                  <div className="price-right">Deadweight</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.gross} </div>
                  <div className="price-right">Gross Tons</div>
                </li>
                <li>
                  <div className="price-left"> {viewShip.info.value.net} </div>
                  <div className="price-right">Net Tons</div>
                </li>
              </ul>

              <div className="col-md-12 mt-3">
                <div className="row form-group">
                  <div className="col-md-12">
                    <label>Charter Type {astrix}</label>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="charterType" id="charterType1" value="1" checked={data.charterType / 1 === 1 ? true : false} onChange={handleChange} className="position-absolute" />
                          <label className="form-check-label" for="charterType1">Voyage Charter</label>
                        </div>
                        <div className="form-check form-check-inline ml-4">
                          <input className="form-check-input" type="radio" name="charterType" id="charterType2" value="2" checked={data.charterType / 1 === 2 ? true : false} onChange={handleChange} className="position-absolute" />
                          <label className="form-check-label" for="charterType2">Time Charter</label>
                        </div>
                      </div>
                      {error.charterType ? <div class="text-danger error-contract ml-3" >{error.charterType}</div> : ''}
                      {error.shipIMO ? <div class="text-danger error-contract ml-3" >{error.shipIMO}</div> : ''}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group row">
                  <label className="col-md-12">Price {astrix}</label>
                  <div className="col-md-6">
                    <input type="number" step="0.01" name="price" value={data.price} placeholder="Enter Price" className={" form-control" + (!error.price ? '' : ' border-danger')} onChange={handleChange} />
                    {error.price ? <div class="text-danger error-contract">{error.price}</div> : ''}
                  </div>

                  <div className="col-md-6">
                    <select className={" form-control" + (!error.currency ? '' : ' border-danger')} name="currency" value={data.currency} onChange={handleChange} >
                      <option value="" selected>--Select Currency--</option>
                      {
                        currencyData.map((currency) => {
                          return (
                            <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                          )
                        })
                      }
                    </select>
                    {error.currency ? <div class="text-danger error-contract">{error.currency}</div> : ''}
                  </div>
                </div>
              </div>
              {(data.charterType / 1 === 1) && <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Loading Country {astrix} </label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.loadingCountry ? '' : ' border-danger')} name="loadingCountry" value={data.loadingCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                          <option value="" selected> Select country </option>
                          {countryData.length ? countryData.map((item, index) => {
                            return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                          }) : ''}
                        </select>
                        {error.loadingCountry ? <div class="text-danger error-contract">{error.loadingCountry}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Loading Port {astrix} </label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.loadingPort ? '' : ' border-danger')} name="loadingPort" value={data.loadingPort} onChange={handleChange}>
                          <option value="" selected> Select loading port </option>
                          {portLoading.map((portLoading) => {
                            return (
                              <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                            )
                          })}
                        </select>
                        {error.loadingPort ? <div class="text-danger error-contract">{error.loadingPort}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Unloading Country {astrix} </label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.unloadingCountry ? '' : ' border-danger')} name="unloadingCountry" value={data.unloadingCountry} onChange={(event) => { handleChange(event); unloadPort(event); }}>
                          <option value="" selected> Select country </option>
                          {countryData.length ? countryData.map((item, index) => {
                            return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                          }) : ''}
                        </select>
                        {error.unloadingCountry ? <div class="text-danger error-contract">{error.unloadingCountry}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Unloading Port {astrix} </label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.unloadingPort ? '' : ' border-danger')} name="unloadingPort" value={data.unloadingPort} onChange={handleChange}>
                          <option value="" selected>Select Unloading port</option>
                          {
                            portUnloading.map((portUnloading) => {
                              return (
                                <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                              )
                            })
                          }
                        </select>
                        {error.unloadingPort ? <div class="text-danger error-contract">{error.unloadingPort}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              {(data.charterType / 1 === 2) && <>
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-6">
                      <label >Start Date {astrix} </label>
                      <input type="datetime-local" name="charterStart" value={data.charterStart} className={" form-control" + (!error.charterStart ? '' : ' border-danger')} onChange={handleChange} />
                      {error.charterStart ? <div class="text-danger error-contract">{error.charterStart}</div> : ''}
                    </div>
                    <div className="col-md-6">
                      <label >End Date {astrix} </label>
                      <input type="datetime-local" name="charterEnd" value={data.charterEnd} className={" form-control" + (!error.charterEnd ? '' : ' border-danger')} onChange={handleChange} />
                      {error.charterEnd ? <div class="text-danger error-contract">{error.charterEnd}</div> : ''}
                    </div>
                  </div>
                </div>
              </>}
            </div>
          </div>
          <div className="col-md-12 modal-footer primary">
            {(viewShip.info.value.booked_from_flag === 0 && viewShip.info.value.booked_to_flag === 0) &&
              <button type="button" className="btn btn-success float-right btn-sm" onClick={() => prehandleUpdate()} >Update</button >}

            {((viewShip.info.value.booked_from_flag === 0 && viewShip.info.value.booked_from === userId) || (viewShip.info.value.booked_to_flag === 0 && viewShip.info.value.booked_to === userId)) &&
              <button type="button" className="btn btn-danger float-right btn-sm" onClick={() => confirmForm()} >Accept</button >}

            {((viewShip.info.value.booked_from_flag === 1 && viewShip.info.value.booked_from === userId) || (viewShip.info.value.booked_to_flag === 1 && viewShip.info.value.booked_to === userId)) &&
              <button type="button" className="btn btn-success float-right btn-sm" disabled >Already Confirmed</button >}
            {/* {(viewShip.info.value.booked_to_flag === 0 && viewShip.info.value.booked_to === userId) &&
                  <input type="button" className="btn btn-danger float-left" value="Accept" onClick={confirmForm} />
                } */}
            {/* {(viewShip.info.value.booked_by === userId && viewShip.info.value.booked_by_flag === 0) &&
              <>
                <input type="button" className="btn btn-primary float-right" value="Update" onClick={updateForm} />
                <input type="button" className="btn btn-danger float-left" value="Accept" onClick={confirmForm} />
              </>
            }
            {(viewShip.info.value.booked_by === userId && viewShip.info.value.booked_by_flag === 1) &&
              <input type="button" className="btn btn-primary float-right" value="Accepted" disabled />
            }
            {(viewShip.info.value.booked_to === userId && viewShip.info.value.booked_to_flag === 0) &&
              <>
                <input type="button" className="btn btn-primary float-right" value="Update" onClick={updateForm} />
                <input type="button" className="btn btn-danger float-left" value="Accept" onClick={confirmForm} />
              </>
            }
            {(viewShip.info.value.booked_to === userId && viewShip.info.value.booked_to_flag === 1) &&
              <input type="button" className="btn btn-primary float-right" value="Accepted" disabled />
            } */}

          </div>
        </div>
      </div>
    </div >
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    viewShip: state.viewShip
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setViewShip: (flag, data) => { dispatch(setViewShip({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewShip);
