import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer'

const middlewares = [applyMiddleware(thunk)]

if (window.__REDUX_DEVTOOLS_EXTENSION__)
  middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__())

let store = createStore(rootReducer, compose(...middlewares))

// export default store
// import { createStore } from 'redux'
// import todoApp from './reducers/reducer'
// const store = createStore(todoApp, window.STATE_FROM_SERVER)

// Log the initial state
//console.log(store.getState())

// Every time the state changes, log it
// Note that subscribe() returns a function for unregistering the listener
// const unsubscribe = store.subscribe(() => console.log(store.getState()))

// Dispatch some actions
// store.dispatch(addTodo('Learn about actions'))
// store.dispatch(addTodo('Learn about reducers'))
// store.dispatch(addTodo('Learn about store'))
// store.dispatch(toggleTodo(0))
// store.dispatch(toggleTodo(1))
// store.dispatch(setVisibilityFilter(VisibilityFilters.SHOW_COMPLETED))

// Stop listening to state updates
// unsubscribe()

export default store
