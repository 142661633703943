import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../homeComponents/header';
import { setRegistrationData } from '../../store/actions/action';
import toastDisplay from '../../utils/toastNotification';


const TraderProfilePicker = ({ registrationData, setRegistrationData, dispatch }) => {
  var [selectedProfile, setSelectedProfile] = useState(0);

  const [showLoader, setshowLoader] = useState(true);
  let [error, seterror] = useState(false);
  var isAdminModal = !(window.location.href.includes('registration'));

  console.log('registrationData in TraderProfilePicker page==>', registrationData.type);


  useEffect(() => {
    setshowLoader(false)
    registrationData.payload.modalNav = 2;//for navigating next/back in case of modal
    //[code] select profile as pre registration data trader profile prop
    if (registrationData.payload.traderProfile) {
      // if (registrationData.type / 1 === 16) {
      //   setSelectedProfile({ 15: true, 3: true })
      // } else if (registrationData.type / 1 === 14) {
      //   setSelectedProfile({ 3: true, 4: true })
      // } else {
      //   setSelectedProfile({ 3: (registrationData.type === 3), 4: (registrationData.type === 4), 15: (registrationData.type === 15) })
      // }
      setSelectedProfile(registrationData.type / 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  function handleProfileSelection(id) {
    registrationData.payload.traderProfile = true;

    // if (!selectedProfile['15'] && id / 1 === 15) {
    //   setSelectedProfile({ ...selectedProfile, 4: false, [id]: !selectedProfile[id] })
    // } else if (!selectedProfile['4'] && id / 1 === 4) {
    //   setSelectedProfile({ ...selectedProfile, 15: false, [id]: !selectedProfile[id] })
    // } else {
    //   setSelectedProfile({ ...selectedProfile, [id]: !selectedProfile[id] })
    // }
    setSelectedProfile(id)

  }


  const handleNext = () => {
    // if (selectedProfile['15'] && selectedProfile['3']) {
    //   setRegistrationData(16, 2, registrationData.payload)//producer/manufacturer with buyer(16) 
    // } else if (selectedProfile['15']) {
    //   setRegistrationData(15, 2, registrationData.payload)//producer/manufacturer (15)
    // } else 
    if (selectedProfile / 1 === 14) {
      setRegistrationData(14, 3, registrationData.payload);//Trader
    } else if (selectedProfile / 1 === 4) {
      setRegistrationData(4, 3, registrationData.payload);//Seller
    } else if (selectedProfile / 1 === 3) {
      setRegistrationData(3, 3, registrationData.payload);//Buyer
    } else {
      seterror(true);
      toastDisplay("Form Validation Error", "warn")
    }
  }

  const handleBack = () => {
    // registrationData.type = selectedProfile['15'] ? 15 : (selectedProfile['3'] && selectedProfile['4']) ? 14 : selectedProfile['3'] ? 3 : 4;
    registrationData.type = (selectedProfile / 1) ? (selectedProfile / 1) : registrationData.type;
    setRegistrationData(registrationData.type, 2, registrationData.payload)
  }

  function profilePickerCommon() {
    return (
      <>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row">
          <div className="col-md-12 mb-2">
            <h3 className="green border-bottom pb-2">Select profile(s) to register as :</h3>
          </div>

          <div className="col-md-12 our-webcoderskull">

            <div className="col-md-12 mt-2 mb-2 shadow">
              <ul className="row pt-5 col-md-6 m-auto" id="">
                <li className={`col-md-12 ${selectedProfile / 1 === 14 ? 'active' : ''} `} true >
                  <div className="cnt-block equal-hight">
                    <figure onClick={() => { handleProfileSelection(14) }}>
                      <img width="92" src="../assets/images/onbording/usertype/14.png" className="img-responsive" alt="" />
                    </figure>
                    <h3 className={selectedProfile / 1 === 14 ? 'text-success' : 'text-dark'}>Trader (Buyer + Seller)</h3>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-12 mt-2 mb-2 shadow">
              <ul className="row pt-5 col-md-6 m-auto" id="">
                <li className={`col-md-12 ${selectedProfile / 1 === 3 ? 'active' : ''} `} true >
                  <div className="cnt-block equal-hight">
                    <figure onClick={() => { handleProfileSelection(3) }}>
                      <img width="92" src="../assets/images/onbording/usertype/3.png" className="img-responsive" alt="" />
                    </figure>
                    <h3 className={selectedProfile / 1 === 3 ? 'text-success' : 'text-dark'}>Buyer/Importer</h3>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-12 mt-5 mb-2 shadow">
              <ul className="row pt-5 col-md-10 m-auto" id="">
                <li className={`col-md-12 ${selectedProfile / 1 === 4 ? 'active' : ''} `} true >
                  <div className="cnt-block equal-hight">
                    <figure onClick={() => { handleProfileSelection(4) }}>
                      <img width="92" src="../assets/images/onbording/usertype/4.png" className="img-responsive" alt="" />
                    </figure>
                    <h3 className={selectedProfile / 1 === 4 ? 'text-success' : 'text-dark'}>Seller/Exporter</h3>
                  </div>
                </li>
                {/* <li className="col-md-2 text-center">
                  <i class="fas fa-dot-circle fa-2x text-primary"></i>
                </li>
                <li className={`col-md-5 ${selectedProfile['15'] ? 'active' : ''} `} true >
                  <div className="cnt-block equal-hight">
                    <figure onClick={() => { handleProfileSelection(15) }}>
                      <img width="92" src="../assets/images/onbording/usertype/4.png" className="img-responsive" alt="" />
                    </figure>
                    <h3>Producer/Manufacturer</h3>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        {error ? <p className="text-danger text-center">{"Please select at least one profile."}</p> : ""}
      </>
    )
  }

  // ----final component return[start]----
  if (isAdminModal) {
    return (
      <div className="modal-body calc-inner-modal">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="container-fluid">
          <div className="row">
            {/* Navigation in case of modal: 1-prev, 2-neutral, 3-next(not used 0 coz its falsy)*/}
            {(registrationData.payload.modalNav === 1) && (registrationData.payload.modalNav = 2) && handleBack()}
            {(registrationData.payload.modalNav === 3) && (registrationData.payload.modalNav = 2) && handleNext()}
            <div className="col-md-12">
              {profilePickerCommon()}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <ul className="next-button">
          <li onClick={handleBack}><button >{"<< Previous"}</button></li>
          <li onClick={handleNext}><button >{"Next >>"}</button></li>
        </ul>

        <div className="container-fluid">
          <div className="row">
            <Header title={'Company Type Information'} />
            <div className="col-md-8 mx-auto bg-white rounded p-4 mt-3 calc">
              {profilePickerCommon()}
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ----final component return[start]----

}


const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TraderProfilePicker);