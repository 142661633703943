export default function validate(values, tnc, activeclass, userTyepeId, contractTypeLogi) {
  console.log("values in validate-->", values)
  // console.log("tnc in validate-->", tnc)
  console.log("activeclass in validate-->", activeclass)
  let errors = {};
  if (activeclass === 1) {
    if (contractTypeLogi === 'brokerOfflineContract') {
      if (!values.contract_number) {
        errors.contract_number = 'Contract Number is Mandatory';
      }
      if (!values.chartererName) {
        errors.chartererName = 'Charterer Name is Mandatory';
      }
      if (!values.chartererEmail) {
        errors.chartererEmail = 'Charterer Email is Mandatory';
      }
      if (!values.chartererAddr) {
        errors.chartererAddr = 'Charterer Address is Mandatory';
      }
    }
    if (!values.contractAlias) {
      errors.contractAlias = 'Contract Alias is Mandatory';
    }
    if (contractTypeLogi === 'brokerOfflineContract') {
      if (!values.commdCategorys) {
        errors.commdCategorys = 'Commodity Category is mandatory'
      }
      if (!values.commName) {
        errors.commName = "Commodity Name is mandatory"
      }
    }
    if (!values.commodity_id) {
      errors.commodity_id = 'Please select at least one Commodity';
    }
    if (!values.laycanStart) {
      errors.laycanStart = 'Laycan Start is Required';
    }
    if (!values.laycanEnd) {
      errors.laycanEnd = 'Laycan End is Required';
    }

  } else if (activeclass === 2) {
    if (!values.quantity) {
      errors.quantity = 'Quantity is Required';
    }
    if (!values.quantityLoadUnit) {
      errors.quantityLoadUnit = 'Loading type is Required';
    }
    if (!values.quantityIndexType) {
      errors.quantityIndexType = 'Quantity Index type is Required';
    }
    if (!values.quantityIndex) {
      errors.quantityIndex = 'Quantity Index is Required';
    }
    if (!values.shipPrice) {
      errors.shipPrice = 'Price is Required';
    }
    if (!values.shipCurrency) {
      errors.shipCurrency = 'Currency is Required';
    }
    if (!values.demmurageRate) {
      errors.demmurageRate = 'Demmurage Rate is Required';
    }

  } else if (activeclass === 3) {
    if (!values.loadingCountry) {
      errors.loadingCountry = 'Loading Country is Required';
    }
    if (!values.unloadingCountry) {
      errors.unloadingCountry = 'Unloading Country is Required';
    }
    if (!values.loadingPort) {
      errors.loadingPort = 'Loading Port is Required';
    }
    if (!values.unloadingPort) {
      errors.unloadingPort = 'Unloading Port is Required';
    }
    if (!values.charterType) {
      errors.charterType = 'Charter Type is a Mandatory Field';
    }
    // if (values.charterType / 1 === 2) {
    //   if (!values.charterStart) {
    //     errors.charterStart = 'Charter Start is a Mandatory Field';
    //   }
    //   if (!values.charterEnd) {
    //     errors.charterEnd = 'Charter End is a Mandatory Field';
    //   }
    // }
    if (values.berthLoading) {
      if (!values.berthLoadingNumber) {
        errors.berthLoadingNumber = 'BerthLoading Number is a Mandatory Field';
      }
    }
    if (values.anchorageLoading) {
      if (!values.anchorageLoadingNumber) {
        errors.anchorageLoadingNumber = 'AnchorageLoading Number is a Mandatory Field';
      }
    }
    if (values.berthUnloading) {
      if (!values.berthUnloadingNumber) {
        errors.berthUnloadingNumber = 'BerthUnloading Number is a Mandatory Field';
      }
    }
    if (values.anchorageUnloading) {
      if (!values.anchorageUnloadingNumber) {
        errors.anchorageUnloadingNumber = 'AnchorageUnloading Number is a Mandatory Field';
      }
    }
    if (!values.berthLoading && !values.anchorageLoading) {
      errors.loadingType = 'Loading Type is a Mandatory Field';
    }
    if (!values.berthUnloading && !values.anchorageUnloading) {
      errors.unloadingType = 'Unloading Type is a Mandatory Field';
    }

    if (!values.loadingRate) {
      errors.loadingRate = 'Loading rate is a Mandatory Field';
    }
    if (!values.unloadingRate) {
      errors.unloadingRate = 'Unloading rate is a Mandatory Field';
    }
  } else if (activeclass === 4) {
    if (!values.paymentMode) {
      errors.paymentMode = 'Payment Mode is a Mandatory Field';
    }
    // if (!values.chartererBank) {
    //   errors.chartererBank = 'Bank is a Mandatory Field';
    // }
    // if (!values.shipownerBank) {
    //   errors.shipownerBank = 'Bank is a Mandatory Field';
    // }
    //bank validate rules
  } else if (activeclass === 5) {
    if (!Object.keys(tnc).length) {
      errors.tnc = 'TNC is Required';
    }
    //tnc validate rules
  }
  // else if (activeclass === 6) {
  //   //Document validate rules
  // }


  console.log(errors)
  return errors;
}
