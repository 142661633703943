import moment from "moment";
import React from "react";
import { FileInput } from "../../../utils/FileInput";
import avatarUrl from "../../../utils/makeAvatarUrl";

const ChatBody = ({ recievedchatdata, userId, selectedUser, userAvatar }) => {
  return (
    <div className="chatContainer">
      {/* meetinglink starts here */}
      {/* <div className='chatsend d-flex w-65 ms-auto justify-content-end gap-2 align-items-center'>
        <div className='w-100 meetingLink d-flex p-2 gap-2'>
          <div className='card w-100 borderRadius border-0 p-2'>
            <div className='d-flex gap-3 align-items-center'>
              <div className='w-auto'> <img src={'/assets/images/calender.png'} alt='' /></div>
              <div className='w-auto'>
                <p className='color434343 font-wt-500 font-size-14 mb-1'>Title</p>
                <p className='font-wt-500 d-flex font-size-12 text-secondary mb-0 gap-2 align-items-center'>  <img src={'/assets/images/chat-call.png'} alt='' height={18} /> <span> 02/10/2022 | 10 AM - 11 AM</span></p>
              </div>
            </div>
          </div>
          <div className='w-auto mt-auto '>
            <p className='text-secondary font-size-12 mb-1 '>19:24</p>
          </div>
        </div>
        <div className='w-auto '>
          <div className="img_cont">
            <img src={"/assets/images/chat-dp.png"} className="rounded-circle user_img" />
          </div>
        </div>
      </div> */}
      {/* meetinglink ends here */}
      {/* <p className='font-size-12 text-secondary text-center mt-4'>Yesterday</p> */}

      {recievedchatdata.map((key, index) => {
        return (
          <div className={userId === key.senderId ? 'chatsend d-flex w-65 ms-auto justify-content-end gap-2 align-items-center mt-4 ' : 'messages d-flex w-65 justify-content-start gap-2 align-items-center mt-3'}>
            <div className='w-auto '>
              <div className="img_cont">
                <img src={userId === key.senderId ? typeof (userAvatar) === 'string' ? avatarUrl(userAvatar) : "/assets/images/user-profile.png" : selectedUser?.ReceiverAvatar ? typeof (selectedUser.ReceiverAvatar) == 'string' ? avatarUrl(selectedUser.ReceiverAvatar) : selectedUser.ReceiverAvatar.filebase64 : "/assets/images/user-profile.png"} className={`rounded-circle user_img`} alt="" />
              </div>
            </div>
            <div className={`w-70 ${userId === key.senderId ? 'meetingLink' : 'messagesend'}  d-flex p-2 gap-2`}>
              <div className='w-100 message '>
                {key.chatFile ?
                  <FileInput value={key.chatFile} />
                  : <p className='mb-0' dangerouslySetInnerHTML={{ __html: key.text_message }}></p>
                }
              </div>
              <div className='w-auto mt-auto '>
                <p className='text-secondary font-size-12 mb-0 '>{moment(key.createdAt).format("HH:mm")}</p>
              </div>
            </div>
          </div>
        );
      })}
      {/* <div className='chatsend d-flex w-65 ms-auto justify-content-end gap-2 align-items-center mt-4'>
        <div className='w-auto meetingLink d-flex p-2 gap-2'>
          <div className='w-100 '>
            <p className='mb-0 color434343 font-size-13'>Consectetur malesuada mollis vel, laoreet tempora nsectetur malesuada mollis vel, laor </p>
          </div>
          <div className='w-auto mt-auto '>
            <p className='text-secondary font-size-12 mb-1 '>19:24</p>
          </div>
        </div>
        <div className='w-auto '>
          <div className="img_cont">
            <img src={"/assets/images/chat-dp.png"} className="rounded-circle user_img" />
          </div>
        </div>
      </div>
      <div className='messages d-flex w-65 justify-content-start gap-2 align-items-center mt-3'>
        <div className='w-auto '>
          <div className="img_cont">
            <img src={"/assets/images/chat-dp.png"} className={`rounded-circle user_img`} />
          </div>
        </div>
        <div className='w-auto messagesend d-flex p-2 gap-2'>
          <div className='w-100 message '>
            <p className='mb-0'>Consectetur malesuada mollis vel, laoreet tempor. </p>
          </div>
          <div className='w-auto mt-auto '>
            <p className='text-secondary font-size-12 mb-0 '>19:24</p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
export default ChatBody;