import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/userFinancialDetailsGrid';
import customStyles from '../dataTablesColumsMap/customTableCss';
import FilePreview from '../utilComponents/previewFile'
import toastDisplay from '../../utils/toastNotification';
import docJson from './financeDoc.json';
import validate from '../../utils/validation_files/creditlineByBank';
import approvedcolumns from '../dataTablesColumsMap/CreditLine';
import { formatDate_Application, } from '../../utils/dateFormaters';
import CreditLineGrid from './creditLineGrid';
import CreditLineBankGrid from './creditLineBankGrid';
import MyCounterParts from '../myCounterPartComp/myCounterPart';
import UserOffer from './userOfferTab';
import config from '../../config.json';
import { validate_text_field } from '../../utils/myFunctions';
import QmsWindow from '../contractDetails/qms';


const FinanceDetailsGrid = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail, creditlineDataBank, setCreditlineDataBank }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [fileData, setfileData] = useState({});
  const [data, setData] = useState({})
  const [error, setError] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState({ modal: false, id: "" });
  const [yearList, setyearList] = useState([]);
  const [adminAction, setadminAction] = useState({ modal: false, data: {} });
  const [adminActionData, setadminActionData] = useState({});
  const [bankAction, setbankAction] = useState({ modal: false, data: {} });
  const [creditBankAction, setCreditBankAction] = useState({ modal: false, data: {} });
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabing, settabing] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Credit Details");
  const [creditUserDetail, setCreditUserDetail] = useState([]);
  const [countryData, setcountryData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [kycUpdate, setkycUpdate] = useState(true);
  const [kycData, setkycData] = useState([]);
  const [accord, setaccord] = useState({
    0: false,
    1: true,
    2: true,
    3: true,
    4: true
  });
  const [buyersDetail, setBuyersDetail] = useState([]);
  const [assignBuyer, setAssignBuyer] = useState({ data: [] });
  const [shareHolderData, setShareHolderData] = useState([]);
  const [userBankDetails, setUserBankDetails] = useState([]);
  const [userBusinessDocs, setUserBusinessDocs] = useState([]);
  const [userOtherDocs, setUserOtherDocs] = useState([]);
  const [showQms, setShowQms] = useState(false);
  const [viewDirect, setViewDirect] = useState(false)

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})

    call('POST', 'getUserBanks', { "email": manualUserEmail, "inProfile": true }).then((result) => {
      // console.log("result in getUserBanks-->", result)
      if (result && result.message.length) {
        setUserBankDetails(result.message)
      }
    })

    call('POST', 'getuserprofiledata', { 'email': manualUserEmail, "kyc": true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)

      if (result.userProfileData) {
        call('POST', 'getShareHolderGrid', { "currentPage": 1, "resultPerPage": 100, "userId": result.userProfileData.tbl_user_id }).then((result) => {
          // console.log("result in getShareHolderGrid-->", result)
          if (result.message && result.message.length) {
            setShareHolderData(result.message)
          }
        })

        call('POST', 'getUserOtherDocs', { "userId": result.userProfileData.tbl_user_id }).then((result) => {
          // console.log("result in getUserOtherDocs-->", result)
          if (result && result.length) {
            setUserOtherDocs(result)
          }
        })

        call('post', 'getuserdetailsextra', { "userId": result.userProfileData.tbl_user_id }).then((result) => {
          // console.log("result in getuserdetailsextra-->", result)
          if (result.docDetails && result.docDetails.length) {
            setUserBusinessDocs(result.docDetails)
          }
        })
      }

      let userProfileData = result.userProfileData
      setkycData(result.userKYCData)
      setshowLoader(false)
      if (result.userProfileData) {
        setData({
          userTypeId: userProfileData.type_id,
          userId: userProfileData.tbl_user_id,
          email: userProfileData.login_id,
          contactPerson: userProfileData.contact_person,
          designation: userProfileData.designation,
          contactNo: userProfileData.contact_number,
          address: userProfileData.user_address,
          country: userProfileData.country_code,
          userAvatar: userProfileData.user_avatar ? userProfileData.user_avatar : null,
          kyc_done: (userProfileData.kyc_done / 1),
          company_pan_verification: userProfileData.company_pan_verification,
          company_cin_verification: userProfileData.company_cin_verification,
          company_gst_verification: userProfileData.company_gst_verification,
          company_iec_verification: userProfileData.company_iec_verification,
          refercode: userProfileData.refercode
        })
      }
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getuserprofiledata', e);
    })
    //---------------------------------------------------------------------------------------------------------------------
  }, [refresh, filterData]);

  useEffect(() => {
    let tempArray = []
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      tempArray.push(i)
    }
    setyearList(tempArray)

    data.userId = manualUserId

    call("POST", "getCreditlineDocDetail", data).then(async (result) => {
      setCreditUserDetail(result);
    }).catch((e) => {
      console.log("error in getCreditlineDocDetail", e);
    });

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    });

    let objectAPI = {
      "userId": manualUserId,
      // "userTypeId": userTypeId,
    }

    call("POST", "getBuyersDetail", objectAPI).then(async (result) => {
      console.log('result in getBuyersDetail  ', result);
      setBuyersDetail(result.buyerData);
    }).catch((e) => {
      console.log("error in getBuyersDetail", e);
    });

  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      doNext();
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState('');


  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": manualUserId,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      settableData(formatDataFortable(result.finData))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": manualUserId,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      settableData(formatDataFortable(result.finData))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.year = dataArray[i].year;
      tempObj.currency = dataArray[i].currency.split(":")[1];
      tempObj.turnover = dataArray[i].turnover + " " + dataArray[i].currency.split(":")[1];
      tempObj.netprofit = dataArray[i].netprofit + " " + dataArray[i].currency.split(":")[1];
      tempObj.networth = dataArray[i].networth + " " + dataArray[i].currency.split(":")[1];
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      if (manualUserId === undefined && userTypeId !== 8 && userTypeId !== 1) {
        tempObj.action = <button className="btn btn-danger btn-sm" onClick={() => setdeleteConfirm({ modal: true, id: dataArray[i].id })} >
          <i className={"fas fa-trash"} aria-hidden="true"></i>
        </button>
      } else if (userTypeId === 1) {
        tempObj.action = <button className="btn btn-success btn-sm" onClick={() => setadminAction({ modal: true, data: { id: dataArray[i].id, year: dataArray[i].year } })} >
          <i className={"fas fa-eye"} aria-hidden="true"></i>
        </button>
      } else {
        tempObj.action =
          <button className="btn btn-success btn-sm" onClick={() => setbankAction({ modal: true, data: { id: dataArray[i].id, year: dataArray[i].year, allData: dataArray[i] } })}><i className={"fa fa-pencil-square-o"}></i></button>
      }
      tempObj.finance_doc_1_year = dataArray[i].finance_doc_1_year;
      tempObj.finance_doc_2_year = dataArray[i].finance_doc_2_year;
      tempObj.finance_doc_3_year = dataArray[i].finance_doc_3_year;
      tempObj.finance_doc_current_year = dataArray[i].finance_doc_current_year;
      tempObj.gst_doc_6_month = dataArray[i].gst_doc_6_month;
      tempObj.itr_doc_1_year = dataArray[i].itr_doc_1_year;
      tempObj.itr_doc_2_year = dataArray[i].itr_doc_2_year;
      tempObj.itr_doc_3_year = dataArray[i].itr_doc_3_year;
      tempObj.debtor_doc = dataArray[i].debtor_doc;
      tempObj.creditor_doc = dataArray[i].creditors_doc;

      resultArray.push(tempObj)
    }
    return resultArray
  }


  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );


  function createMiniBody(data) {
    let keysArray = Object.keys(docJson)
    let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
      return (<>
        {docJson[keyValue].map((arrayElement) => {
          return (
            <div className="col-md-4 bg-light border-left mb-1">
              <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(data[arrayElement.name], "view")}>
                {keyValue + " - ( " + arrayElement.lable + " )"}
              </button>
            </div>
          )
        })}
      </>)
    }) : ""

    return (returnJSX)

  }


  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = manualUserId
    data.bankId = userId
    call('POST', "insertusercreditdetailbybbank", data).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 2)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };


  const reset = () => {
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }


  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  //---------------------------------------------------------------------------------------------------------------------
  //Port List

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  // functions
  function ExportTableCSV() {
    call('GET', 'getExportCSVData').then((result) => {
      let csvString = "id,year,currency,turnover,netprofit,networth,created_at,admin_status,admin_comment\r\n";
      for (let i = 0; i < result.length; i++) {
        let rowString = `${result[i].id},
      ${result[i].year},
      ${result[i].currency},
      ${result[i].turnover},
      ${(result[i].netprofit)},
      ${result[i].networth},
      ${result[i].created_at},
      ${result[i].admin_comment}
      ${result[i].admin_status === 0 ? "PENDING" :
            result[i].admin_status === 1 ? "REJECTED" :
              result[i].admin_status === 2 ? "APPROVED" : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString;
      }

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Finance_Record_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getExportCSVData', e);
    })
  }

  const handleChangeAdmin = (event) => {
    if (event.target.name === "adminStatus" && event.target.value / 1 === 2) {
      setadminActionData({ ...adminActionData, [event.target.name]: event.target.value, "adminComment": "Details OK" })
    } else {
      setadminActionData({ ...adminActionData, [event.target.name]: event.target.value })
    }
  };

  const handleChange = (event) => {
    event.persist();
    event.target.value = validate_text_field(event.target.value)
    setData(logdata => ({ ...logdata, [event.target.name]: event.target.value }));
    setError(errorData => ({ ...errorData, [event.target.name]: "" }))
  }

  function submitAdminStatus() {
    if (!adminActionData.adminStatus || !adminActionData.adminComment) {
      toastDisplay("Please Fill all details", "warn")
      return
    }

    call('POST', 'adminadduserfinacestatus', { ...adminActionData, ...adminAction.data }).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
      setadminAction({ modal: false, data: {} })
      setadminActionData({})
    }).catch((e) => {
      console.log('error in adminadduserfinacestatus', e);
      toastDisplay(e, "error");
    })

  }

  const handleActiveClass = (id) => {
    if (id < activeClass) {
      setActiveClass(id)
    } else {
      settempactiveClass(id);
      setIsSubmitting(true)
      settabing(true)
      // setError(validate(data, dataTnc, activeClass, userTypeId, contractLogi.info.logiContractType))
    }
  }

  const handlePrev = (id) => setActiveClass(id - 1);

  const handleNext = () => {
    if (activeClass < 2) {
      setActiveClass(activeClass + 1)
    }
    setIsSubmitting(true)

    // setError(validate(data, dataTnc, activeClass, userTypeId, contractLogi.info.logiContractType))
  }

  const doNext = () => {
    if (tabing === true) {
      setActiveClass(tempactiveClass);
      setIsSubmitting(false)
      settabing(false)
    } else {
      if (activeClass >= maxactiveClass) {
        setmaxactiveClass(activeClass + 1)
      }
      setActiveClass(activeClass + 1);
      setIsSubmitting(false)
    }
  }

  function documentsVault(allData) {
    for (let i = 0; i < creditUserDetail.length; i++) {
      if (creditUserDetail[i].userid == allData.id) {
        let docJson = creditUserDetail[i].userDetail;
        let keysArray = Object.keys(docJson)
        let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
          return (<>
            {docJson[keyValue].map((arrayElement) => {
              return (

                <div className="col-md-12">
                  <div className="row pt-2">
                    <div className="modal-padding" id={"CreditLine:49"}>
                      <ul className="other-documents pb-0 mb-0">
                        <li style={{ "min-width": "250px" }}>
                          <label className="mb-0" >
                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" />
                            </span>
                            {keyValue}
                          </label>
                        </li>
                        <li style={{ "min-width": "250px" }}>
                          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                            () => callPreviewFileComponent(arrayElement.docID, "view")}>
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </button>
                          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                            () => callPreviewFileComponent(arrayElement.docID, "download")}>
                            <i className="fa fa-download" aria-hidden="true"></i>
                          </button>
                        </li>
                      </ul>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              )
            })}
          </>)
        }) : ""

        return (returnJSX)
      }
    }
  }

  function openDOCPreview(file, action) {

    let fileOBJ = {}
    if (file === "finance_doc_1_year") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.finance_doc_1_year
      }
    } else if (file === "finance_doc_2_year") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.finance_doc_2_year
      }
    } else if (file === "finance_doc_3_year") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.finance_doc_3_year
      }
    } else if (file === "itr_doc_1_year") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.itr_doc_1_year
      }
    } else if (file === "itr_doc_2_year") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.itr_doc_2_year
      }
    } else if (file === "itr_doc_3_year") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.itr_doc_3_year
      }
    } else if (file === "gst_doc_6_month") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.gst_doc_6_month
      }
    } else if (file === "debtor_doc") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.debtor_doc
      }
    } else if (file === "creditor_doc") {
      fileOBJ = {
        "action": action,
        "tbl_doc_id": bankAction.data.allData.creditor_doc
      }
    }
    setfileData(fileOBJ)
    setshowPreviewModal(true)
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  //KYC

  function kycGridCreator() {
    let docElements = kycData ? kycData.map((values, index) => {
      return (<tr>
        <td>{index + 1}</td>
        {/* <td>{(values && values.document_name) ? values.document_name : 'NA'}</td> */}
        <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
        <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td>
        <td>{(values && values.uploaded_on) ? formatDate_Application(values.uploaded_on) : 'NA'}</td>
        <td className="text-center">
          {(values && values.doc_status === 1) ? <i className="text-success fa fa-check-circle" /> :
            (values && values.doc_status === 0) ? <i className="text-warning fa fa-hourglass-start" /> :
              (values && values.doc_status === 2) ? <i className="text-warning fa fa-hourglass-start" /> : ''}
        </td>
        <td className="">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values.tbl_doc_id, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values.tbl_doc_id, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function shareHolderGridCreator() {
    let docElements = shareHolderData ? shareHolderData.map((values, index) => {
      let docs = values.doc_array ? values.doc_array.split(",") : []
      return (<tr>
        <td>{index + 1}</td>
        {/* <td>{(values && values.document_name) ? values.document_name : 'NA'}</td> */}
        <td>{(values && values.name) ? values.name : 'NA'}</td>
        <td>{(values && values.country) ? values.country : 'NA'}</td>
        <td>{(values && values.nationality) ? values.nationality : 'NA'}</td>
        <td>{(values && values.email_id) ? values.email_id : 'NA'}</td>
        <td>{(values && values.share_in_company) ? values.share_in_company : 'NA'}</td>
        <td>{(values && values.itr) ? values.itr : 'NA'}</td>
        <td>{(values && values.din) ? values.din : 'NA'}</td>
        <td>{(values && values.signatory) ? values.signatory : 'NA'}</td>


        <td className="">
          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(docs[0], "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(docs[0], "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
        </td>

        <td className="">
          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(docs[1], "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(docs[1], "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
        </td>
      </tr>)
    }) : ''
    return (docElements)
  }


  function bankDetailsGridCreator() {
    let docElements = userBankDetails ? userBankDetails.map((values, index) => {
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.beneficiaryAccountNo) ? values.beneficiaryAccountNo : 'NA'}</td>
        <td>{(values && values.beneficiaryBankName) ? values.beneficiaryBankName : 'NA'}</td>
        <td>{(values && values.beneficiaryIfscCode) ? values.beneficiaryIfscCode : 'NA'}</td>
        <td>{(values && values.beneficiarySwiftCode) ? values.beneficiarySwiftCode : 'NA'}</td>
        <td>{(values && values.correspondentBankName) ? values.correspondentBankName : 'NA'}</td>
        <td>{(values && values.correspondentAccountNumber) ? values.correspondentAccountNumber : 'NA'}</td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function businessDocsGridCreator() {
    let docElements = userBusinessDocs ? userBusinessDocs.map((values, index) => {
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
        <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td className="">
          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values.id, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values.id, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
        </td>
      </tr>)
    }) : ''
    return (docElements)
  }


  function otherDocsGridCreator() {
    let docElements = userOtherDocs ? userOtherDocs.map((values, index) => {
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
        {/* <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td> */}
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td className="">
          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view", true)}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download", true)}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  function callPreviewFileComponent(values, action, viewDirect) {
    if (viewDirect) {
      setViewDirect(true)
      setfileData({
        id: values.id,
        file_hash: values.file_hash,
        file_name: values.file_name,
        file_type: values.doc_name,
        created_at: values.created_at,
        "action": action
      })
    }
    else {
      setViewDirect(false)
      setfileData({ tbl_doc_id: values, "action": action })
    }
    setshowPreviewModal(true)
  }

  function deleteUserFinDetails(finDtId) {

    call('POST', 'deleteuserfinancedetails', { finDtId: finDtId }).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
      setdeleteConfirm({ modal: false, id: '' })
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      toastDisplay(e, "error");
    })

  }

  function creditButton(value) {
    // console.log("credit button==>", value);

    let userValue = false;

    if (value.buyers_credit == null) {
      return (<div className="col-md-12">
        <div className="row pt-2">
          <div className="form-group col-md-6">
            <label>Assign Credit </label>
            <input type='number' className={" form-control"} placeholder=""
              // name="buyersCredit" 
              name={"buyersCredit" + value.id}
              // value={data.multiplebuyersCredit} 
              value={data["buyersCredit" + value.id]}
              onChange={handleChange} />
            {/* {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>} */}
          </div>

          <div className="form-group col-md-6">
            <label>Remark </label>
            <input type="text" className={" form-control"} placeholder=""
              // name="buyersRemark"
              name={"buyersRemark" + value.id}
              // value={data.multiplebuyersRemark}
              value={data["buyersRemark" + value.id]}
              onChange={handleChange} />
            {/* {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>} */}
          </div>
          <br />
          <br />
        </div>
        <div className="form-group col-md-4">
          <button className="btn btn-success btn-sm" onClick={() => assignCredit(value)}
            disabled={!(data["buyersCredit" + value.id] && data["buyersRemark" + value.id])}
          >
            Assign Credit
          </button>
        </div>

        <hr className="pt-1 pb-1" />
      </div>)
    }
    else {
      let buyersCreditList = JSON.parse(value.buyers_credit);
      for (let i = 0; i < buyersCreditList.length; i++) {
        if (buyersCreditList[i].lender_id == userId) {
          userValue = true;
        }
        if (userValue == true) {
          return (
            <div className="col-md-12">
              <div className="row pt-2">
                <div className="form-group col-md-6">
                  <label>Assigned Credit </label>
                  <input type="text" className={" form-control"} placeholder=""
                    name={"buyersCredit" + value.id}
                    // value={buyersCreditList[i].assignValue}
                    defaultValue={buyersCreditList[i].assignValue}
                    value={data["buyersCredit" + value.id]}
                    onChange={handleChange} />
                </div>

                <div className="form-group col-md-6">
                  <label>Remark</label>
                  <input type="text" className={" form-control"} placeholder=""
                    name={"buyersRemark" + value.id}
                    defaultValue={buyersCreditList[i].assignRemark}
                    // value={buyersCreditList[i].assignRemark}
                    value={data["buyersRemark" + value.id]}
                    onChange={handleChange} />
                </div>
                <br />
                <br />
              </div>

              <div className="form-group col-md-4">
                <button className="btn btn-info btn-sm"
                  disabled={!(data["buyersCredit" + value.id] && data["buyersRemark" + value.id])}
                  onClick={() => assignCredit(value)} >
                  Update Credit
                </button>
              </div>

              <hr className="pt-1 pb-1" />
            </div>
          )
        }

      }
      return (<div className="col-md-12">
        <div className="row pt-2">
          <div className="form-group col-md-6">
            <label>Assign Credit </label>
            <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder=""
              // name="buyersCredit" 
              name={"buyersCredit" + value.id}
              // value={data.multiplebuyersCredit} 
              value={data["buyersCredit" + value.id]}
              onChange={handleChange} />
            {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
          </div>

          <div className="form-group col-md-6">
            <label>Remark </label>
            <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder=""
              // name="buyersRemark" 
              name={"buyersRemark" + value.id}
              // value={data.multiplebuyersRemark} 
              value={data["buyersRemark" + value.id]}
              onChange={handleChange} />
            {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
          </div>
          <br />
          <br />
        </div>
        <div className="form-group col-md-4">
          <button className="btn btn-success btn-sm" onClick={() => assignCredit(value)}
            disabled={!(data["buyersCredit" + value.id] && data["buyersRemark" + value.id])}>
            Assign Credit
          </button>
        </div>

        <hr className="pt-1 pb-1" />
      </div>)
    }
  }

  const assignCredit = (assignData) => {
    let object = {
      "assignValue": data["buyersCredit" + assignData.id],
      "assignRemark": data["buyersRemark" + assignData.id],
      "buyer_id": assignData.id,
      "buyerEmail": assignData.buyerEmail,
      "lender_id": userId,
      "lender_name": userName,
      "lender_email": userEmail,
      "userId": manualUserId,
      "userName": manualUserName,
      "userEmail": manualUserEmail,
      "id": userTypeId
    }

    assignBuyer.data.push(object);
    setAssignBuyer(assignBuyer);

    call('POST', 'assignCreditToBuyer', {
      "data": assignBuyer.data, "baseUrl": config.baseUrl,
      "traderUserId": manualUserId,
      "buyerName": assignData.buyerName,
      "lenderName": userName
    }).then((result) => {
      console.log('running assignCreditToBuyer api-->', result);
      toastDisplay(result, "success")
    }).catch((e) => {
      console.log('error in assignCreditToBuyer', e);
      toastDisplay("Error", "error")
    });

  }

  //  Mini Preview Component
  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left">{manualUserName ? manualUserName : '--'}</div>
            <div className="price-right">Username </div>
          </li>
          <li>
            <div className="price-left">{bankAction.data.year ? bankAction.data.year : '--'}</div>
            <div className="price-right">Financial Year </div>
          </li>
          <li>
            <div className="price-left"> {bankAction.data.allData.turnover ? ((bankAction.data.allData.turnover + " " + bankAction.data.allData.currency.split(":")[1])) : '--'} </div>
            <div className="price-right">Turn Over</div>
          </li>
          <li>
            <div className="price-left">{bankAction.data.allData.netprofit ? ((bankAction.data.allData.netprofit + " " + bankAction.data.allData.currency.split(":")[1])) : '--'}</div>
            <div className="price-right"> Net Profit </div>
          </li>
          <li>
            <div className="price-left"> {bankAction.data.allData.networth ? ((bankAction.data.allData.networth + " " + bankAction.data.allData.currency.split(":")[1])) : '--'}</div>
            <div className="price-right">Net Worth</div>
          </li>
        </ul>
      </div>
    )
  }

  function miniCreditPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left">{manualUserName ? manualUserName : '--'}</div>
            <div className="price-right">Username </div>
          </li>
          <li>
            <div className="price-left">{creditBankAction.data.allData.company_name}</div>
            <div className="price-right">Lender Name </div>
          </li>
          <li>
            <div className="price-left"> {creditBankAction.data.allData.credit_amount} </div>
            <div className="price-right">Requested Amount</div>
          </li>
          <li>
            <div className="price-left">{(creditBankAction.data.allData.admin_status == 2) ? "Approved" :
              (creditBankAction.data.allData.admin_status == 1) ? "Rejected" : (creditBankAction.data.allData.admin_status == 0) ? "Pending" : "N/A"
            }</div>
            <div className="price-right"> Admin Status </div>
          </li>
          <li>
            <div className="price-left"> {creditBankAction.data.allData.admin_comment}</div>
            <div className="price-right">Admin Comment</div>
          </li>
          <li>
            <div className="price-left"> {creditBankAction.data.allData.created_at}</div>
            <div className="price-right">Created At</div>
          </li>
        </ul>
      </div>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="d-md-flex mt-3 mb-3">
        {userTypeId / 1 == 8 && (
          <>
            <div className="chat-button" onClick={() => { setShowQms(true) }}><i className="fa fa-comments-o" aria-hidden="true"></i></div>
            <div className="circle-rippl"></div>
            {showQms &&
              <>
                <div className="chat-box" id="chatBox" Style="display: block">
                  <div className="chat-header">
                    <h1 className="chat-title">QMS</h1>
                    <div className="chat-btn-bg">
                      <span className="chat-close" onClick={() => { setShowQms(false) }}>×</span>
                    </div>
                  </div>
                  <QmsWindow
                    userTokenDetails={userTokenDetails}
                    contractNo={"GENERAL" + manualUserId + "CHAT"}
                    withoutContractChat={true}
                    senderUserName={userName}
                    partyEmailId={buyersDetail.length ? [{ [manualUserId]: manualUserEmail }].concat(buyersDetail.map(value => ({ [value["id"]]: value.buyerEmail }))) : [{ [manualUserId]: manualUserEmail }]}
                    partyUserId={buyersDetail.length ? [manualUserId].concat(buyersDetail.map(value => value.id)) : [manualUserId]}
                  />
                </div>
              </>
            }
          </>
        )}

        {(userTypeId !== 1 && userTypeId !== 8 && userTypeId !== 20) &&
          <div className="ml-md-auto relative">
            <ul className="range-slider">
              <li>
                <input name="search" placeholder="Search" value={searchKey} onChange={event => setsearchKey(event.target.value)} id="searchInput" onKeyDown={handleKeyPress} />
                <button type="button" onClick={() => {
                  setFilterData({
                    ...filterData,
                    "search": searchKey
                  })
                }}><i class="fas fa-search fa-lg"></i></button>
              </li>
              <li>
                <select className={"form-control"} name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate">
                  <option value="" selected>From</option>
                  {yearList.map((year) => {
                    return (
                      <option value={year} >{year}</option>
                    )
                  })}
                </select>
              </li>
              <li>
                <select className={"form-control"} name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" >
                  <option value="" selected>To</option>
                  {yearList.map((year) => {
                    return (
                      <option value={year} >{year}</option>
                    )
                  })}
                </select>
              </li>
              <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

            </ul>
          </div>
        }
      </div>

      {(userTypeId !== 1 && userTypeId !== 8 && userTypeId !== 20) &&
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={tableData}
            progressPending={loading}
            pagination
            paginationServer
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
            persistTableHead
            expandableRows
            expandableRowsComponent={<TableExpandedComponent />}
            expandOnRowClicked
            highlightOnHover
            onSort={handleSort}
            sortServer
            striped
            noHeader
            customStyles={customStyles}
          />
        </div>
      }

      {(userTypeId == 1 || userTypeId == 8 || userTypeId == 20) &&
        <div className="creditline_listing">
          <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
            {(userTypeId == 1 || userTypeId == 8 || userTypeId == 20) &&
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                  setTab(0);
                }}>Applicant Financial Details</a>
              </li>
            }
            {(userTypeId == 1 || userTypeId == 8 || userTypeId === 20) &&
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                  setTab(1);
                }}>Credit Details</a>
              </li>}
            {(userTypeId == 8 || userTypeId == 20) &&
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                  setTab(2);
                }}>Applicant Details</a>
              </li>
            }
            {(userTypeId == 1 || userTypeId == 20) &&
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 3 ? " active show" : "")} onClick={() => {
                  setTab(3);
                }}>Applicant Buyer Details</a>
              </li>
            }
            {(userTypeId == 1) &&
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 4 ? " active show" : "")} onClick={() => {
                  setTab(4);
                }}>User Offer</a>
              </li>
            }
            {(userTypeId == 8) &&
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 5 ? " active show" : "")} onClick={() => {
                  setTab(5);
                }}>Applicant Buyer Details</a>
              </li>
            }
          </ul>

          <>
            {tab === 0 &&
              <>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    expandableRows
                    expandableRowsComponent={<TableExpandedComponent />}
                    expandOnRowClicked
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div>
                <hr />
                {/* <div className="float-left col-md-7 ">
                  <div className="col-md-12 row">
                    <div className="col-md-3">
                      <button className=" text-center btn btn-primary btn-sm m-0" onClick={ExportTableCSV}>
                        <i class="fas fa-file-csv"></i> Export CSV
                      </button>
                    </div>
                  </div>
                </div> */}
              </>
            }

            {tab === 1 &&
              <>
                {(userTypeId === 8 || userTypeId === 20) &&
                  <CreditLineBankGrid userTokenDetails={userTokenDetails} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} gridType="approved" creditlineDataBank={creditlineDataBank} setCreditlineDataBank={setCreditlineDataBank} />
                }
                {(userTypeId === 1) &&
                  <CreditLineGrid userTokenDetails={userTokenDetails} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} gridType="request" />
                }

              </>
            }

            {tab === 2 &&
              <>
                <div className="col-md-12 bg-light shadow">
                  <div className="col-md-12 p-2">
                    <div className="row">
                      <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord["0"] ? " closed" : " open")}>
                        <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, 0: !accord["0"] })}>KYC Documents</h2>
                        <div className="accordionItemContent">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                                      <thead className='text-center'>
                                        <tr>
                                          <th width="20">#</th>
                                          {/* <th>Type of Document</th> */}
                                          <th width="300">Document Name</th>
                                          <th width="200">Uploaded By</th>
                                          <th width="200">Uploaded On</th>
                                          <th width="100">Status</th>
                                          <th className="text-center" width="150">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody className='text-center'>
                                        {(kycUpdate && data.kyc_done) && kycGridCreator()}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div >

                      <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord["1"] ? " closed" : " open")}>
                        <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, 1: !accord["1"] })}>Shareholder Details</h2>
                        <div className="accordionItemContent">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                                      <thead className='text-center'>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="300">Name</th>
                                          <th width="200">Country</th>
                                          <th width="200">Nationality</th>
                                          <th width="100">Email Id</th>
                                          <th width="100">Share in Company (%)</th>
                                          <th width="100">ITR</th>
                                          <th width="100">Director Identification No</th>
                                          <th width="100">Authorized Signatory</th>
                                          <th width="100">PAN Document</th>
                                          <th width="100">Aadhar Document</th>
                                        </tr>
                                      </thead>
                                      <tbody className='text-center'>
                                        {shareHolderData.length && shareHolderGridCreator()}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord["2"] ? " closed" : " open")}>
                        <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, 2: !accord["2"] })}>Bank Details</h2>
                        <div className="accordionItemContent">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                                      <thead className='text-center'>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="300">Beneficiary Account No</th>
                                          <th width="200">Beneficiary Bank Name</th>
                                          <th width="200">Beneficiary IFSC Code</th>
                                          <th width="100">Beneficiary SWIFT Code</th>
                                          <th width="100">Correspondent Bank /name</th>
                                          <th width="100">Correspondent Account No</th>
                                        </tr>
                                      </thead>
                                      <tbody className='text-center'>
                                        {userBankDetails.length && bankDetailsGridCreator()}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord["3"] ? " closed" : " open")}>
                        <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, 3: !accord["3"] })}>Business Documents</h2>
                        <div className="accordionItemContent">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                                      <thead className='text-center'>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="300">Document Name</th>
                                          <th width="300">Uploaded By</th>
                                          <th width="300">Uploaded On</th>
                                          <th width="300">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody className='text-center'>
                                        {userBusinessDocs.length && businessDocsGridCreator()}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord["4"] ? " closed" : " open")}>
                        <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, 4: !accord["4"] })}>Other Documents</h2>
                        <div className="accordionItemContent">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                                      <thead className='text-center'>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="300">Document Name</th>
                                          {/* <th width="300">Uploaded By</th> */}
                                          <th width="300">Uploaded On</th>
                                          <th width="300">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody className='text-center'>
                                        {userBusinessDocs.length && otherDocsGridCreator()}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
                <hr />
                {/* <div className="float-left col-md-7 ">
                  <div className="col-md-12 row">
                    <div className="col-md-3">
                      <button className=" text-center btn btn-primary btn-sm m-0" onClick={ExportTableCSV}>
                        <i class="fas fa-file-csv"></i> Export CSV
                      </button>
                    </div>
                  </div>
                </div> */}
              </>
            }

            {tab == 3 &&
              <>
                <MyCounterParts userTokenDetails={userTokenDetails} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} adMinPortal={true} />
              </>}

            {tab == 4 &&
              <>
                <UserOffer userTokenDetails={userTokenDetails} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} />
              </>
            }

            {tab == 5 &&
              <>
                <div className="accordionWrapper row pr-0 pt-0">
                  <div className="container-fluid accordionItem open">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="green mt-3">
                              Buyer's List
                            </h3>
                            <hr />
                          </div>

                          <br />
                          <br />
                          <hr className="pt-1 pb-1" />

                          <div className="col-md-12">
                            <div className="row pt-2">
                              <div className="form-group col-md-12">

                                {buyersDetail && buyersDetail.length > 0 && buyersDetail.map((value) => {
                                  // console.log("buyersDetail==>", value);
                                  return (
                                    <>
                                      <div className="modal-padding">
                                        <ul className="price-ul">
                                          <li>
                                            <div className="price-left">{value.identifier ? value.identifier : ''}</div>
                                            <div className="price-right">Buyer Id </div>
                                          </li>
                                          <li>
                                            <div className="price-left">{value.buyerName ? value.buyerName : ''}</div>
                                            <div className="price-right">Buyer Name </div>
                                          </li>
                                          <li>
                                            <div className="price-left">{value.buyerEmail ? value.buyerEmail : ''}</div>
                                            <div className="price-right">Buyer Email </div>
                                          </li>
                                          <li>
                                            <div className="price-left"> {value.buyerPhone ? value.buyerPhone : ''} </div>
                                            <div className="price-right">Contact No</div>
                                          </li>
                                          <li>
                                            <div className="price-left">{value.buyerAddress ? value.buyerAddress : ''}</div>
                                            <div className="price-right">Buyer Address</div>
                                          </li>
                                          <li>
                                            <div className="price-left">{value.buyerCountry ? value.buyerCountry : ''}</div>
                                            <div className="price-right">Country</div>
                                          </li>
                                          <li>
                                            <div className="price-left">{value.buyerDUNSNo ? value.buyerDUNSNo : ''}</div>
                                            <div className="price-right">DUNS Number</div>
                                          </li>
                                          <li>
                                            <div className="price-left"> {value.previousAnnualSale ? value.previousAnnualSale + ' ' + value.buyerCurrency.split(':')[1] : ''}</div>
                                            <div className="price-right">Previous Sale</div>
                                          </li>
                                          <li>
                                            <div className="price-left"> {value.currentAnnualSale ? value.currentAnnualSale + ' ' + value.buyerCurrency.split(':')[1] : ''}</div>
                                            <div className="price-right">Expected Sale</div>
                                          </li>
                                          <li>
                                            <div className="price-left"> {value.productDetails ? value.productDetails : ''}</div>
                                            <div className="price-right">Product Details</div>
                                          </li>
                                          <li>
                                            <div className="price-left"> {value.buyersDocId ? <button className="btn btn-primary btn-sm" onClick={() => callPreviewFileComponent(value.buyersDocId.split(':')[0], "view")}>
                                              View Document
                                            </button> : 'NA'}</div>
                                            <div className="price-right"><b>Previous Invoice Doc</b></div>
                                          </li>
                                          <li>
                                            <div className="price-left"> {value.buyersDocId ? <button className="btn btn-primary btn-sm" onClick={() => callPreviewFileComponent(value.buyersDocId.split(':')[1], "view")}>
                                              View Document
                                            </button> : 'NA'}</div>
                                            <div className="price-right"><b>Previous PO Doc</b></div>
                                          </li>
                                        </ul>
                                      </div>

                                      {creditButton(value)}

                                      <hr className="pt-1 pb-1" />
                                    </>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </>

        </div>
      }

      {showPreviewModal && <FilePreview
        mktPlaceShipment={viewDirect}
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
      />}

      {deleteConfirm.modal &&
        <div className={"modal" + (deleteConfirm.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Confirmation</h4>
                <button type="button" className="close" onClick={() => { setdeleteConfirm({ modal: false, id: '' }) }}>×</button>
              </div>
              <div className="modal-body">
                Please Confirm Delete Operation
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteUserFinDetails(deleteConfirm.id)}>Delete</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm({ modal: false, id: "" })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

      {adminAction.modal &&
        <div className={"modal" + (adminAction.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Action Form</h4>
                <button type="button" className="close" onClick={() => { setadminAction({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body">

                <div className="col-md-12 text-center">
                  <h3> {`${manualUserName} - Financial Details for Year ${adminAction.data.year}`} </h3>
                </div>
                <hr className="pt-1 pb-1" />
                <div className="col-md-12">
                  <div className="row">

                    <div className="col-md-6 ">
                      <label>Choose Status for Curent Year Data</label>
                    </div>

                    <div className="row col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="adminStatus" id="adminStatus1" value="1" onChange={handleChangeAdmin} checked={adminActionData.adminStatus / 1 === 1 ? true : false} />
                        <label className="form-check-label text-danger" for="adminStatus1">Rejected</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="adminStatus" id="adminStatus2" value="2" onChange={handleChangeAdmin} checked={adminActionData.adminStatus / 1 === 2 ? true : false} />
                        <label className="form-check-label text-success" for="adminStatus2">Approved</label>
                      </div>
                    </div>
                    <hr className="col-md-11 pt-1 pb-1" />
                    <div className="row col-md-12">
                      <label className="col-md-2">Comment</label>
                      <div className="col-md-10">
                        <textarea name="adminComment" value={adminActionData.adminComment} placeholder="Enter Message" className="form-control" onChange={handleChangeAdmin} />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-success btn-sm" onClick={() => submitAdminStatus()}>Submit</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setadminAction({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

      {bankAction.modal &&
        <div className={"modal" + (bankAction.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Credit Limit Form</h4>
                <button type="button" className="close" onClick={() => { setbankAction({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body calc-modal">
                <div className="col-md-12 text-center">
                  <h3> {`${manualUserName} - Financial Details for Year ${bankAction.data.year}`} </h3>
                </div>
                <hr className="pt-1 pb-1" />
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <div id="tabs1" className="tab-contents no-js">
                  <div className="tab-nav">

                    <li id="1" className={"tab-link" + (activeClass === 1 ? " is-active" : "") + ((1 < maxactiveClass) ? " is-completed" : "")} onClick={(1 <= maxactiveClass) ? () => handleActiveClass(1) : ""}>Applicant Info</li>
                    <li id="2" className={"tab-link" + (activeClass === 2 ? " is-active" : "") + ((2 < maxactiveClass) ? " is-completed" : "")} onClick={(2 <= maxactiveClass) ? () => handleActiveClass(2) : ""}>Credit Limit Offer</li>
                  </div>


                  <div className={"tab-content" + (activeClass === 1 ? " is-active" : "")}>
                    < div className="calc-inner-modal">
                      {miniPreview()}
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12 row">
                        <div className="col-md-12">
                          <h3 className="green mt-3"> Applicant Financial Year Document </h3>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="modal-padding" id="finance_doc_1_year">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                Financial 1st Year
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("finance_doc_1_year", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("finance_doc_1_year", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="finance_doc_2_year">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                Financial 2nd Year
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("finance_doc_2_year", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("finance_doc_2_year", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="finance_doc_3_year">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                Financial 3rd Year
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("finance_doc_3_year", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("finance_doc_3_year", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="itr_doc_1_year">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                ITR 1st Year
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("itr_doc_1_year", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("itr_doc_1_year", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="itr_doc_2_year">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                ITR 2nd Year
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("itr_doc_2_year", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("itr_doc_2_year", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="itr_doc_3_year">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                ITR 3rd Year
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("itr_doc_3_year", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("itr_doc_3_year", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="gst_doc_6_month">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                GST 3B last 6 months
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("gst_doc_6_month", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("gst_doc_6_month", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="debtor_doc">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                Debtors
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("debtor_doc", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("debtor_doc", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                        </div>
                        <div className="modal-padding" id="creditor_doc">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                Creditors
                                <span className="required-field text-danger">*</span>
                              </label>
                            </li>
                            <li style={{ "min-width": "250px" }}>
                              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("creditor_doc", 'view')}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={() => openDOCPreview("creditor_doc", 'download')}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          <hr className="col-md-12" />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className={"tab-content" + (activeClass === 2 ? " is-active" : "")}>
                    <div className="calc-inner-modal">
                      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

                      {/* 1st Section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Funding Details
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Lender Name {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.lendername ? '' : ' border-danger')} placeholder="" name="lendername" value={userName} onChange={handleChange} />
                                      {error.lendername && <p className="text-danger error-contract">{error.lendername}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Approved Finance Amount {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.approvedFinanceAmount ? '' : ' border-danger')} placeholder="" name="approvedFinanceAmount" value={data.approvedFinanceAmount} onChange={handleChange} />
                                      {error.approvedFinanceAmount && <p className="text-danger error-contract">{error.approvedFinanceAmount}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Funding Percentage(%) {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.fundingPercentage ? '' : ' border-danger')} placeholder="" name="fundingPercentage" value={data.fundingPercentage} onChange={handleChange} />
                                      {error.fundingPercentage && <p className="text-danger error-contract">{error.fundingPercentage}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Funding Tenor {astrix}</label>
                                      <input type="number" className={" form-control" + (!error.fundingTenor ? '' : ' border-danger')} placeholder="" name="fundingTenor" value={data.fundingTenor} onChange={handleChange} />
                                      {error.fundingTenor && <p className="text-danger error-contract">{error.fundingTenor}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Due date calculation from</label>
                                      <input type="date" className={" form-control" + (!error.duedateCalcFrom ? '' : ' border-danger')} placeholder="" name="duedateCalcFrom" value={data.duedateCalcFrom} onChange={handleChange} />
                                      {error.duedateCalcFrom && <p className="text-danger error-contract">{error.duedateCalcFrom}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 2nd section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Interest Details
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Interest Rate {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.interestRate ? '' : ' border-danger')} placeholder="" name="interestRate" value={data.interestRate} onChange={handleChange} />
                                      {error.interestRate && <p className="text-danger error-contract">{error.interestRate}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Interest Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="interestCollection" value={data.interestCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="deducted_upfront">Deducted upfront</option>
                                          <option value="later">Later</option>
                                        </select>
                                      </div>
                                      {error.interestCollection && <p className="text-danger error-contract">{error.interestCollection}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 3rd section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Finance Request Step Fees
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Percentage {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.finRequestPercentage ? '' : ' border-danger')} placeholder="" name="finRequestPercentage" value={data.finRequestPercentage} onChange={handleChange} />
                                      {error.finRequestPercentage && <p className="text-danger error-contract">{error.finRequestPercentage}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="finRequestCollection" value={data.finRequestCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="upfront">Upfront</option>
                                          <option value="deductible">Deductible in first transaction</option>
                                        </select>
                                      </div>
                                      {error.finRequestCollection && <p className="text-danger error-contract">{error.finRequestCollection}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 4th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Additional Fees Detail
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Additional Fee 1 {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.additionalFee ? '' : ' border-danger')} placeholder="" name="additionalFee" value={data.additionalFee} onChange={handleChange} />
                                      {error.additionalFee && <p className="text-danger error-contract">{error.additionalFee}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Description 1 {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.additionalDescription ? '' : ' border-danger')} placeholder="" name="additionalDescription" value={data.additionalDescription} onChange={handleChange} />
                                      {error.additionalDescription && <p className="text-danger error-contract">{error.additionalDescription}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="additionalCollection" value={data.additionalCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="deducted_upfront">Deducted upfront</option>
                                          <option value="later">Later</option>
                                        </select>
                                      </div>
                                      {error.additionalCollection && <p className="text-danger error-contract">{error.additionalCollection}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Last Fee Charges(% or USD per day) {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.lastfeeCharges ? '' : ' border-danger')} placeholder="" name="lastfeeCharges" value={data.lastfeeCharges} onChange={handleChange} />
                                      {error.lastfeeCharges && <p className="text-danger error-contract">{error.lastfeeCharges}</p>}
                                    </div>
                                    <br />
                                    <br />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 5th section */}

                      {/* <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Platform Fees
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Platform Fee {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.platformFee ? '' : ' border-danger')} placeholder="" name="platformFee" value={data.platformFee} onChange={handleChange} />
                                      {error.platformFee && <p className="text-danger error-contract">{error.platformFee}</p>}
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* 6th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Other T&C
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Sanction Country {astrix} </label>
                                      <div className="col-md-12">
                                        <select className={" form-control" + (!error.sanctionCountry ? '' : ' border-danger')} name="sanctionCountry" value={data.sanctionCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                                          <option value="" selected>--Select country--</option>
                                          {/* <CountryList /> */}
                                          {
                                            countryData.length ? countryData.map((item, index) => {
                                              return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                            }) : ''
                                          }
                                        </select>
                                        {error.sanctionCountry && <p className="text-danger error-contract">{error.sanctionCountry}</p>}
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Sanction Port {astrix} </label>
                                      <div className="col-md-12">
                                        <select className={" form-control" + (!error.sanctionPort ? '' : ' border-danger')} name="sanctionPort" value={data.sanctionPort} onChange={handleChange}>
                                          <option value="" selected>--Select loading port--</option>
                                          {portLoading.map((portLoading) => {
                                            return (
                                              <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                            )
                                          })}
                                        </select>
                                        {error.sanctionPort && <p className="text-danger error-contract">{error.sanctionPort}</p>}
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Commodities {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.commodities ? '' : ' border-danger')} placeholder="" name="commodities" value={data.commodities} onChange={handleChange} />
                                      {error.commodities && <p className="text-danger error-contract">{error.commodities}</p>}
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="modal-footer primary">
                <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>
                {activeClass > 1 ?
                  <button type="button" className="btn btn-info btn-sm" onClick={() => preHandleSubmit()}>Submit</button>
                  :
                  <button type="button" className="btn btn-info btn-sm" onClick={() => handleNext()} id="nextid">Next</button>
                }
                <button type="button" className="btn btn-info btn-sm" onClick={() => setbankAction({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

      {creditBankAction.modal &&
        <div className={"modal" + (creditBankAction.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Credit Limit Form</h4>
                <button type="button" className="close" onClick={() => { setCreditBankAction({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body calc-modal">
                <div className="col-md-12 text-center">
                  <h3> {`${manualUserName} - Financial Details for Year`} </h3>
                </div>
                <hr className="pt-1 pb-1" />
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <div id="tabs1" className="tab-contents no-js">
                  <div className="tab-nav">

                    <li id="1" className={"tab-link" + (activeClass === 1 ? " is-active" : "") + ((1 < maxactiveClass) ? " is-completed" : "")} onClick={(1 <= maxactiveClass) ? () => handleActiveClass(1) : ""}>Applicant Info</li>
                    <li id="2" className={"tab-link" + (activeClass === 2 ? " is-active" : "") + ((2 < maxactiveClass) ? " is-completed" : "")} onClick={(2 <= maxactiveClass) ? () => handleActiveClass(2) : ""}>Credit Limit Offer</li>
                  </div>


                  <div className={"tab-content" + (activeClass === 1 ? " is-active" : "")}>
                    < div className="calc-inner-modal">
                      {/* {miniPreview()} */}
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12 row">
                        <div className="col-md-12">
                          <h3 className="green mt-3"> Applicant Financial Year Document </h3>
                        </div>
                        {
                          documentsVault(creditBankAction.data.allData)
                        }
                      </div>
                    </div>
                  </div>


                  <div className={"tab-content" + (activeClass === 2 ? " is-active" : "")}>
                    <div className="calc-inner-modal">
                      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

                      {miniCreditPreview()}
                      <hr className="pt-1 pb-1" />

                      {/* 1st Section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Funding Details
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Lender Name {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.lendername ? '' : ' border-danger')} placeholder="" name="lendername" value={userName} onChange={handleChange} />
                                      {error.lendername && <p className="text-danger error-contract">{error.lendername}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Approved Finance Amount {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.approvedFinanceAmount ? '' : ' border-danger')} placeholder="" name="approvedFinanceAmount" value={data.approvedFinanceAmount} onChange={handleChange} />
                                      {error.approvedFinanceAmount && <p className="text-danger error-contract">{error.approvedFinanceAmount}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Funding Percentage(%) {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.fundingPercentage ? '' : ' border-danger')} placeholder="" name="fundingPercentage" value={data.fundingPercentage} onChange={handleChange} />
                                      {error.fundingPercentage && <p className="text-danger error-contract">{error.fundingPercentage}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Funding Tenor {astrix}</label>
                                      <input type="number" className={" form-control" + (!error.fundingTenor ? '' : ' border-danger')} placeholder="" name="fundingTenor" value={data.fundingTenor} onChange={handleChange} />
                                      {error.fundingTenor && <p className="text-danger error-contract">{error.fundingTenor}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Due date calculation from</label>
                                      <input type="date" className={" form-control" + (!error.duedateCalcFrom ? '' : ' border-danger')} placeholder="" name="duedateCalcFrom" value={data.duedateCalcFrom} onChange={handleChange} />
                                      {error.duedateCalcFrom && <p className="text-danger error-contract">{error.duedateCalcFrom}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 2nd section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Interest Details
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Interest Rate {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.interestRate ? '' : ' border-danger')} placeholder="" name="interestRate" value={data.interestRate} onChange={handleChange} />
                                      {error.interestRate && <p className="text-danger error-contract">{error.interestRate}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Interest Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="interestCollection" value={data.interestCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="deducted_upfront">Deducted upfront</option>
                                          <option value="later">Later</option>
                                        </select>
                                      </div>
                                      {error.interestCollection && <p className="text-danger error-contract">{error.interestCollection}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 3rd section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Finance Request Step Fees
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Percentage {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.finRequestPercentage ? '' : ' border-danger')} placeholder="" name="finRequestPercentage" value={data.finRequestPercentage} onChange={handleChange} />
                                      {error.finRequestPercentage && <p className="text-danger error-contract">{error.finRequestPercentage}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="finRequestCollection" value={data.finRequestCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="upfront">Upfront</option>
                                          <option value="deductible">Deductible in first transaction</option>
                                        </select>
                                      </div>
                                      {error.finRequestCollection && <p className="text-danger error-contract">{error.finRequestCollection}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 4th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Additional Fees Detail
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Additional Fee 1 {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.additionalFee ? '' : ' border-danger')} placeholder="" name="additionalFee" value={data.additionalFee} onChange={handleChange} />
                                      {error.additionalFee && <p className="text-danger error-contract">{error.additionalFee}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Description 1 {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.additionalDescription ? '' : ' border-danger')} placeholder="" name="additionalDescription" value={data.additionalDescription} onChange={handleChange} />
                                      {error.additionalDescription && <p className="text-danger error-contract">{error.additionalDescription}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="additionalCollection" value={data.additionalCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="deducted_upfront">Deducted upfront</option>
                                          <option value="later">Later</option>
                                        </select>
                                      </div>
                                      {error.additionalCollection && <p className="text-danger error-contract">{error.additionalCollection}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Last Fee Charges(% or USD per day) {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.lastfeeCharges ? '' : ' border-danger')} placeholder="" name="lastfeeCharges" value={data.lastfeeCharges} onChange={handleChange} />
                                      {error.lastfeeCharges && <p className="text-danger error-contract">{error.lastfeeCharges}</p>}
                                    </div>
                                    <br />
                                    <br />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 5th section */}

                      {/* <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Platform Fees
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Platform Fee {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.platformFee ? '' : ' border-danger')} placeholder="" name="platformFee" value={data.platformFee} onChange={handleChange} />
                                      {error.platformFee && <p className="text-danger error-contract">{error.platformFee}</p>}
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* 6th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Other T&C
                                  </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Sanction Country {astrix} </label>
                                      <div className="col-md-12">
                                        <select className={" form-control" + (!error.sanctionCountry ? '' : ' border-danger')} name="sanctionCountry" value={data.sanctionCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                                          <option value="" selected>--Select country--</option>
                                          {/* <CountryList /> */}
                                          {
                                            countryData.length ? countryData.map((item, index) => {
                                              return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                            }) : ''
                                          }
                                        </select>
                                        {error.sanctionCountry && <p className="text-danger error-contract">{error.sanctionCountry}</p>}
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Sanction Port {astrix} </label>
                                      <div className="col-md-12">
                                        <select className={" form-control" + (!error.sanctionPort ? '' : ' border-danger')} name="sanctionPort" value={data.sanctionPort} onChange={handleChange}>
                                          <option value="" selected>--Select loading port--</option>
                                          {portLoading.map((portLoading) => {
                                            return (
                                              <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                            )
                                          })}
                                        </select>
                                        {error.sanctionPort && <p className="text-danger error-contract">{error.sanctionPort}</p>}
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Commodities {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.commodities ? '' : ' border-danger')} placeholder="" name="commodities" value={data.commodities} onChange={handleChange} />
                                      {error.commodities && <p className="text-danger error-contract">{error.commodities}</p>}
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="modal-footer primary">
                <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>
                {activeClass > 1 ?
                  <button type="button" className="btn btn-info btn-sm" onClick={() => preHandleSubmit()}>Submit</button>
                  :
                  <button type="button" className="btn btn-info btn-sm" onClick={() => handleNext()} id="nextid">Next</button>
                }
                <button type="button" className="btn btn-info btn-sm" onClick={() => setbankAction({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceDetailsGrid)
