export default function validateAddBank(data) {
  let errors = {};

  // if (!data.CorrbankName) {
  //   errors.CorrbankName = 'Correspondent Bank Name is Mandatory';
  // }
  // if (!data.CorrSwiftCode) {
  //   errors.CorrSwiftCode = 'Correspondent Swift Code is Mandatory';
  // }
  // if (!data.CorrAccountNo) {
  //   errors.CorrAccountNo = 'Correspondent Account Number is Mandatory';
  // }
  if (!data.BenfbankName) {
    errors.BenfbankName = 'Beneficiary Bank Name is Mandatory';
  }
  if (!data.BenfbranchName) {
    errors.BenfbranchName = 'Beneficiary Branch Name is Mandatory'
  }
  if (!data.BenfaccountHolder) {
    errors.BenfaccountHolder = 'Beneficiary Account holder name is Mandatory'
  }
  if (!data.BenfAccountNumber) {
    errors.BenfAccountNumber = 'Beneficiary Account Number is Mandatory'
  }
  if (!data.BenfIFSCCode) {
    errors.BenfIFSCCode = 'Beneficiary IFSC Code is Mandatory'
  }
  if (!data.BenfSwiftCode) {
    errors.BenfSwiftCode = 'Beneficiary Swift Code is Mandatory'
  }
  if (!data.BenfAddress) {
    errors.BenfAddress = 'Beneficiary Address is Mandatory'
  }
  if (!data.BenfIFSCCode) {
    errors.BenfIFSCCode = 'Beneficiary IFSC Code is Mandatory'
  }
  if (!data.BenfCity) {
    errors.BenfCity = 'Beneficiary City is Mandatory'
  }
  if (!data.bankState) {
    errors.bankState = 'Beneficiary State is Mandatory'
  }
  if (!data.bankCountry) {
    errors.bankCountry = 'Beneficiary Country is Mandatory'
  }
  if (!data.bankPostal) {
    errors.bankPostal = 'Beneficiary Postal Code is Mandatory'
  }
  console.log(errors);
  return errors;
}
