import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
];


const COLORS = ['#95CCFF', '#FF6A5A'];


export default class FinancerChart extends PureComponent {

  render() {

    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={this.props.chardata}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="Count"

          >
            <defs>
              <radialGradient id="pie1" x1="0" y1="0" x2="0" y2="1">
                <stop offset={"50%"} stopColor="#AED8FF" stopOpacity={0.58} />
                <stop offset={"100%"} stopColor="#95CCFF" stopOpacity={0.17} />
              </radialGradient>
            </defs>

            <defs>
              <radialGradient id="pie2" x1="0" y1="0" x2="0" y2="1">
                <stop offset={"50%"} stopColor="#FF7B6D" stopOpacity={0.58} />
                <stop offset={"100%"} stopColor="#FF6A5A" stopOpacity={0.17} />
              </radialGradient>
            </defs>
            {this.props.chardata && this.props.chardata.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} opacity={0.58} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
