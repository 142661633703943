export const AllnotificationsToday = [
  {
    Icon: "assets/images/Lc/coins.png",
    Description: "30,000 coins added to your wallet",
    Date: "10/10/2022 at 12:45pm",
    BtnName: "Go to wallet",
    Firstchild: true
  },
  {
    Icon: "assets/images/finance-disbursed.png",
    Description: "Finance amount disbursed for 859754865245895.",
    Date: "10/10/2022 at 12:45pm",
    BtnName: "View details",
  },
  {
    Icon: "assets/images/messages.png",
    Description: "Message from Rosti",
    Date: "10/10/2022 at 12:45pm",
    BtnName: "View Message",
  },
];

export const QuoteData = [
  {
    Icon: "assets/images/Lc/coins.png",
    Description: "Additional documents requested by financer",
    Date: "10/10/2022 at 12:45pm",
    BtnName: "View request",
    Firstchild: true
  },
  {
    Icon: "assets/images/finance-disbursed.png",
    Description: "Quote approved, send LC for amendment",
    Date: "10/10/2022 at 12:45pm",
    BtnName: "Send amendment request",
  },
  {
    Icon: "assets/images/messages.png",
    Description: "Amended LC received",
    Date: "10/10/2022 at 12:45pm",
    BtnName: "View LC",
  },
];