import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, GetCache, most_used_currencies, SetCache, toolTip, getDocDetails } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import { PopupMessage } from '../../../popupMessage';
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import { ToastContainer } from "react-toastify";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" }
]

const SendTermSheet = ({ userTokenDetails, navToggleState }) => {

  const [tab, setTab] = useState(1);
  const [signdoc, setSigndoc] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [remark, setRemark] = useState("");
  const [denyForm, setDenyForm] = useState({ modal: false, page: 0 });
  const [showLoader, setshowLoader] = useState(false)
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [moreDocsArray2, setMoreDocsArray2] = useState([])
  const [similarRequest, setSimilarRequest] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  let termSheetDetail = localStorage.getItem("termSheetDetail");
  termSheetDetail = termSheetDetail ? JSON.parse(termSheetDetail) : 'NA';

  async function asyncUseEffectFunction() {
    setshowLoader(true)
    call("POST", 'getSimilarTermSheetRequests', { userId, supplierId: termSheetDetail.user_id, applicationId: termSheetDetail.limit_id }).
      then(res => {
        if (res.length) {
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            if (element.id == termSheetDetail.limit_id) {
              res[index]["isChecked"] = true
              res[index]["isFixed"] = true
            }
          }
          setSimilarRequest(res)
        }
      })
    let invoiceDocument = await getDocDetails(termSheetDetail.buyersDocId.split(":")[0]);
    let poDocument = await getDocDetails(termSheetDetail.buyersDocId.split(":")[1]);
    let otherDocsArray = [];
    if (termSheetDetail.buyerOtherDocs) {
      let otherDocsID = termSheetDetail.buyerOtherDocs.split(":");
      for (let i = 0; i < otherDocsID.length; i++) {
        let otherDoc = await getDocDetails(otherDocsID[i]);
        otherDocsArray.push(otherDoc);
      }
    }
    setData({
      ...data, invoiceDocument, poDocument, otherDocsArray
    })
    setshowLoader(false)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitDocument();
    } else if (isSubmitting) {
      setIsSubmitting({ modal: false, type: '' })
    }
  }, [errors]);

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }
  }

  const handleRemark = async (event) => {
    event.persist()
    setRemark({ ...remark, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  function sendDocument() {
    let err = {};
    let validateFields = ["termSheet"];
    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })
    if (!(data.signTypeDigital || data.signTypeWet)) {
      err["signType"] = "Mandatory"
      toastDisplay('Kindly select sign type for term sheet', "info")
    }
    setErrors(err)
    setIsSubmitting(true)
  }

  async function submitDocument() {
    setshowLoader(true)
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append('limit_id', termSheetDetail.limit_id);
    formData.append('userId', userId);
    formData.append('buyersDocId', termSheetDetail.buyersDocId)
    formData.append('buyerId', termSheetDetail.id)
    if (data.termSheet && data.termSheet.signatureId) {
      formData.append('signatureId', data.termSheet.signatureId);
    }
    if (data.invoiceDocument && data.invoiceDocument.signatureId) {
      formData.append('invoiceDocSignId', data.invoiceDocument.signatureId);
      formData.append('invoiceDocID', termSheetDetail.buyersDocId.split(":")[0]);
    }
    if (data.poDocument && data.poDocument.signatureId) {
      formData.append('poDocSignId', data.poDocument.signatureId);
      formData.append('poDocID', termSheetDetail.buyersDocId.split(":")[1]);
    }
    formData.append("termSheetSignType", data.signTypeDigital ? "Digital" : "Wet")

    let tempSimilarRequest = []

    for (let index = 0; index < similarRequest.length; index++) {
      const element = similarRequest[index];
      if (element.isChecked && !element.isFixed) {
        tempSimilarRequest.push(element.id)
      }
    }

    if (tempSimilarRequest.length) {
      formData.append("similarRequest", tempSimilarRequest)
    }

    call('POST', 'sendTermSheet', formData).then(async (result) => {
      setshowLoader(false)
      toggleMsgPopup(true);
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error")
    })
  }

  function addRemark() {
    setData({ ...data, 'assignRemark': remark.assignRemark })
  }

  async function submitForm(value) {
    setshowLoader(true)
    let financierAction = '';
    if (value && value === 'deny') {
      financierAction = 'deny'
    }
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append("lenderId", userId);
    formData.append("buyerId", termSheetDetail.buyerId);
    formData.append("financierAction", financierAction);
    formData.append("limit_id", termSheetDetail.limit_id);
    if (financierAction == 'deny') {
      formData.append("deniedstage", 'send_term');
      formData.append('assignRemark', remark.assignRemark);
    }
    call('POST', 'denyLimit', formData).then(async (result) => {
      setshowLoader(false)
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error")
    })
  }

  return (
    <>
      {/* <AddMoreDocumentHandler hide={true}
        purpose={"ApplyForLimitInvoice"} purposeId={termSheetDetail.id}
        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
      /> */}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_termSheet" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice-discounting > Send Term Sheet >"}
              userTokenDetails={userTokenDetails} />
            {showMsgPopup ? (
              <PopupMessage
                onClose={() => { toggleMsgPopup(false); window.location = "/finInvoiceQuotes" }}
                title={''}
                msg={`Term Sheet sent successfully !`}
              />
            ) : null}
            <a className="cursor" href="/termSheet"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5 '>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                        <div className='row'>
                          {termSheetDetail.buyerDetails.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {item.val ? item.val : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                  {item.val ? item.val : "NA"} </span> </p>
                              </div>
                            )
                          })}
                          <div className="col-md-6">
                            <p className="d-flex d-flex align-items-top mb-2">
                              <span className="col-md-5 px-0 BuyerdetailsLabel">Limit Requested</span>
                              <span className="mx-3">:</span>
                              <span className="col-md-7 BuyerdetailsDesc" > {termSheetDetail.requiredLimit ? termSheetDetail.requiredLimit : "NA"}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        <div className='row mt-2'>
                          <div className="col-md-4 col-xl-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-xl-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          {moreDocsArray?.length ? moreDocsArray.map((item, index) => {
                            if (item?.name) {
                              return (
                                <div className="col-md-4 col-xl-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>

                    <div className='mb-3 d-flex pl-2 pt-2'>
                      <label className="font-wt-500 font-size-16 pt-1 mr-4" >Stamp & Sign Type</label>
                      <input type="radio" id="signTypeDigital" name="signTypeDigital"
                        checked={data.signTypeDigital ? true : false}
                        onClick={() => {
                          setData({ ...data, ["signTypeDigital"]: !data.signTypeDigital, signTypeWet: false })
                        }}
                      />
                      <label className="font-wt-500 font-size-14 mr-4" for="signTypeDigital">Digital</label>
                      <input className="mr-3" type="radio" id="signTypeWet" name="signTypeWet"
                        checked={data.signTypeWet ? true : false}
                        onClick={() => {
                          setData({ ...data, ["signTypeWet"]: !data.signTypeWet, signTypeDigital: false })
                        }} />
                      <label className="font-wt-500 font-size-14 mr-4" for="signTypeWet">Wet</label>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Term Sheet</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"termSheet"} value={data.termSheet} error={errors.termSheet}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "termSheet": null })}
                          />
                          {errors.termSheet ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.termSheet}</b></div> : ''}
                        </div>
                        {data.termSheet && data.termSheet.name && !data.termSheet.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.termSheet, docSignName: "termSheet" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.termSheet && data.termSheet.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>

                      {similarRequest?.length ?
                        <div className='mb-3 d-flex pt-4'
                          style={{ flexWrap: 'wrap' }}
                        >
                          <label className="font-wt-500 font-size-16 pt-1 mr-2" >Also send for:</label>
                          {similarRequest.map((item, index) => {
                            return (
                              <div className="d-flex flex-row align-items-center mb-3">
                                <div className="align-self-center">
                                  <img
                                    onClick={() => {
                                      if (item.isFixed) {
                                        return toastDisplay("Can't uncheck the default one", "info")
                                      }
                                      let temp = similarRequest
                                      temp[index]["isChecked"] = !temp[index]["isChecked"]
                                      setSimilarRequest([...temp])
                                    }}
                                    className='cursor mr-3' src={`assets/images/${item.isChecked ? 'checked-green' : 'empty-check'}.png`} />
                                </div>
                                <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{item.buyerName}</label>
                              </div>
                            )
                          })}
                        </div> : null}
                    </div>
                    {/* <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler
                        purpose={"SendTermSheet"} purposeId={termSheetDetail.limit_id}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray2(docs)} />
                    </div> */}
                    {data.invoiceDocument?.name ? (<div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                          {errors.invoiceDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invoiceDocument}</b></div> : ''}
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>) : null}

                    {data.poDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ?
                          (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          )
                          : null}
                      </div>
                    </div> : null}

                    {/* <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler notEditable={true} hideAddMoreOption={true}
                        purpose={"ApplyForLimitInvoice"} purposeId={termSheetDetail.id}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)} />
                    </div> */}
                    {data.otherDocsArray ? data.otherDocsArray.map((item, key) => {
                      return (<div className="col-md-8 mb-3">
                        <label className="font-size-14">Other Document</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"otherDoc" + (key + 1)} value={item}
                              onChange={handleFile} isEditable={false}
                            // onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                            />
                          </div>
                          {/* {(item) && item.name && !item.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: item, docSignName: "otherDoc" + (key + 1) }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null} */}
                          {/* {data.otherDocsArray[item] && data.otherDocsArray[item].signatureId ?
                            (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            )
                            : null} */}
                        </div>
                      </div>)
                    }) : null}
                  </div>
                ) : null}

                {tab === 0 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => setTab(1)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Continue"}
                    </button>
                    <button type="button"
                      onClick={() => setDenyForm({ modal: true, page: 0 })}
                      className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                      {"Deny"}
                    </button>
                  </div>
                )}

                {tab === 1 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => sendDocument()}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Send Documents"}
                    </button>
                  </div>
                )}

                {denyForm.modal && denyForm.page === 0 ? <>
                  <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-md">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
                          <p>Deny Finance Limit</p>
                          <button type="button" className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`} onClick={() => setDenyForm({ modal: true, page: 1 })}>Yes</button>
                          <button type="button" className={`mx-4 new-btn w-15 py-2 px-3 text-white`} onClick={() => setDenyForm({ modal: false, page: 0 })}>No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :
                  denyForm.page === 1 ? <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-md">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p>Denial Remark</p>
                          <div className="col-md-10 move-p">
                            <textarea rows="4" cols="50" className={"form-control"} placeholder="Leave a message for the supplier." name="assignRemark" value={data.assignRemark}
                              onChange={handleRemark} />
                          </div>
                          <button type="button"
                            onClick={() => { addRemark(); submitForm('deny'); setDenyForm({ modal: true, page: 2 }) }}
                            className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                            {"Send"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> :
                    denyForm.page === 2 && <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                      <div className="modal-dialog modal-md">
                        <div className="modal-content submitmodal pb-4">
                          <div className="modal-header border-0">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => { setDenyForm({ modal: false, page: 0 }); window.location = '/finInvoiceQuotes' }}></button>
                          </div>
                          <div className="modal-body text-center">
                            <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                            <p>Finance limit for LC confirmation denied successfully !</p>
                          </div>
                        </div>
                      </div>
                    </div>}

              </div>
            </div>
          </main>
        </div>
      </div >
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(SendTermSheet)