export default function validate(values, files) {

  let errors = {};

  if (!values.annualSale) {
    errors.annualSale = 'Field Missing';
  }
  if (!values.buyerAddress) {
    errors.buyerAddress = 'Field Missing';
  }
  // if (!values.buyerContact) {
  //   errors.buyerContact = 'Field Missing';
  // } else if (isNaN(values.buyerContact)) {
  //   errors.buyerContact = 'Contact No Should be a number';
  // } else if (values.buyerContact.length < 10) {
  //   errors.buyerContact = 'Contact No should be of atleast 10 digits';
  // }
  if (!values.buyerCountry) {
    errors.buyerCountry = 'Field Missing';
  }
  // if (!values.buyerEmail) {
  //   errors.buyerEmail = 'Field Missing';
  // }
  if (!values.buyerCurrency) {
    errors.buyerCurrency = 'Field Missing'
  }
  if (!values.buyerName) {
    errors.buyerName = 'Field Missing';
  }
  if (!values.expectedAnnualSale) {
    errors.expectedAnnualSale = 'Field Missing';
  }
  if (!values.payment) {
    errors.payment = 'Field Missing';
  }
  if (!values.incoTerms) {
    errors.incoTerms = "Field Missing"
  }
  if (!values.productDetail) {
    errors.productDetail = 'Field Missing';
  }
  if (!values.buyerPostalcode) {
    errors.buyerPostalcode = 'Field Missing';
  }
  if (!files.previousInvoiceDocument) {
    errors.previousInvoiceDocument = 'Upload the Previous Invoice Copy';
  }
  if (!files.previousPODocument) {
    errors.previousPODocument = 'Upload the Previous PO Copy';
  }

  console.log(errors);
  return errors;
}
