

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap'
import call from '../../service';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application, formatDateTime_Application } from '../../utils/dateFormaters';
import NewTablev2 from '../../utils/newTablev2';
import { green, red } from '@mui/material/colors';



const ContractTrail = ({ userTokenDetails, contractNo, modal }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States

  const [data, setData] = useState({});
  const [changes, setChanges] = useState([]);
  const [expandedField, setExpandedField] = useState(null);
  const [initialValueVisible, setInitialValueVisible] = useState({});
  const [updatedValueVisible, setUpdatedValueVisible] = useState({});
  const [author, setAuthor] = useState([]);
  const [changeAccordion, setChangeAccordion] = useState({})


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getcontractchangelogs', { "contract_number": contractNo }).then(async (result) => {
      console.log("result in getcontractchangelogs-->", result)
      setData(result)
    }).catch((error) => {
      console.log("error occur in getcontractchangelogs ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  //---------------------------------------------------------------------------------------------------------------------


  useEffect(() => {
    if (data.length > 1) {
      processChangeLogs()
      // const prevObj = data[data.length - 2];
      // const currentObj = data[data.length - 1];
      // const changedFields = Object.keys(currentObj).reduce((acc, key) => {
      //   if (key === 'specification_clog' || key === "tnc_clog") {
      //     if (JSON.stringify(currentObj[key]) !== JSON.stringify(prevObj[key])) {
      //       acc.push({
      //         field: key,
      //         prevValue: prevObj[key],
      //         updatedValue: currentObj[key],
      //         expanded: false
      //       });
      //     }
      //   }
      //   else if (currentObj[key] !== prevObj[key]) {
      //     acc.push({
      //       field: key,
      //       prevValue: prevObj[key],
      //       updatedValue: currentObj[key],
      //       expanded: false
      //     });
      //   }
      //   return acc;
      // }, [data]);
      // console.log(changedFields)
      // changedFields.shift()
      // setChanges(changedFields);
    }

  }, [data]);

  async function processChangeLogs() {
    let tempChangedFields = []
    let tempAuthors = []
    for (let indexx = 0; indexx < data.length; indexx++) {
      if (indexx != 0) {
        let currentObj = data[indexx];
        let prevObj = data[indexx - 1];
        console.log("currentObjKeyssssssssssssssssssssssss", Object.keys(currentObj).length);
        Object.keys(currentObj).map((key, index) => {
          if (key === 'specification_clog' || key === "tnc_clog") {
            let tempCurrenObjKey = key === 'specification_clog' ? currentObj?.[key]?.[0] : currentObj[key]
            let tempPrevObjKey = key === 'specification_clog' ? prevObj?.[key]?.[0] : prevObj[key]
            // console.log("tempCurrenObjKeyyyyyyyyyyyyyyyyy", tempCurrenObjKey, tempPrevObjKey);
            if (JSON.stringify(tempCurrenObjKey) !== JSON.stringify(tempPrevObjKey)) {
              let prevClogKeys = Object.keys(tempPrevObjKey)
              let currClogKeys = Object.keys(tempCurrenObjKey)
              let mergedClogKeys = []
              prevClogKeys.forEach(i => {
                if (!mergedClogKeys.includes(i)) {
                  mergedClogKeys.push(i)
                }
              })
              currClogKeys.forEach(i => {
                if (!mergedClogKeys.includes(i)) {
                  mergedClogKeys.push(i)
                }
              })
              // if (key === "specification_clog") {
              //   console.log("mergedClogKeyssssssssssss", mergedClogKeys);
              // }
              let tempPrevClogToStore = {}
              let tempNewClogToStore = {}
              for (let j = 0; j < mergedClogKeys.length; j++) {
                let oldKeyVal = tempPrevObjKey?.[mergedClogKeys[j]] ? JSON.stringify(tempPrevObjKey?.[mergedClogKeys[j]]) : null
                let newKeyVal = tempCurrenObjKey?.[mergedClogKeys[j]] ? JSON.stringify(tempCurrenObjKey?.[mergedClogKeys[j]]) : null
                // if (key === "specification_clog") {
                //   console.log("oldKeyVallllllllllllll", oldKeyVal, newKeyVal);
                // }
                if (oldKeyVal != newKeyVal) {
                  tempPrevClogToStore[mergedClogKeys[j]] = oldKeyVal ? JSON.parse(oldKeyVal) : {}
                  tempNewClogToStore[mergedClogKeys[j]] = newKeyVal ? JSON.parse(newKeyVal) : {}
                  tempAuthors[indexx - 1] = currentObj.changingParty
                  // if (key === "specification_clog") {
                  //   console.log("NewClogToStoreeeeeeeeeeee", tempPrevClogToStore, tempNewClogToStore);
                  // }
                  if (!tempChangedFields.filter(i => { if (i.field === key && i.changeId / 1 == indexx - 1 / 1) { return true } })?.[0]) {
                    tempChangedFields.push({
                      changeId: indexx - 1,
                      field: key,
                      prevValue: key === "specification_clog" ? [tempPrevClogToStore] : tempPrevClogToStore,
                      updatedValue: key === "specification_clog" ? [tempNewClogToStore] : tempNewClogToStore,
                      expanded: false
                    });
                  }
                }
              }
            }
          }
          else if (currentObj[key] !== prevObj[key]) {
            tempAuthors[indexx - 1] = currentObj.changingParty
            tempChangedFields.push({
              changeId: indexx - 1,
              field: key,
              prevValue: prevObj[key],
              updatedValue: currentObj[key],
              expanded: false
            });
          }
        })
      }
    }
    console.log("tempChangedFieldsssssssssssssss", tempChangedFields, tempAuthors);
    setChanges([...tempChangedFields]);
    setAuthor([...tempAuthors])
  }

  const toggleFieldExpansion = (field) => {
    setExpandedField(field === expandedField ? null : field);
  };

  const toggleInitialValueVisibility = (index) => {
    setInitialValueVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const toggleUpdatedValueVisibility = (index) => {
    setUpdatedValueVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (
    <div className="accordion" id="accordionExample">
      {author.map((authr, index) => {
        let allChanges = changes.filter((i) => {
          if (i.changeId / 1 == index / 1) {
            return true
          }
        })
        return (
          <div className="accordion-item" key={`change${index}`}>
            <h2 className="accordion-header" id={`change${index}`}>
              <button
                className="accordion-button"
                type="button"
                onClick={() => setChangeAccordion({ ...changeAccordion, [`change${index}`]: !changeAccordion[`change${index}`] })}
                aria-expanded={changeAccordion[`change${index}`]}
                aria-controls={`collapsechange${index}`}
              ><span className='font-size-15 font-wt-600'>{`Change No ${index + 1} - (made by ${authr})`}</span>
              </button>
            </h2>
            <div
              id={`collapsechange${index}`}
              className={`accordion-collapse collapse ${changeAccordion[`change${index}`] ? 'show' : ''}`}
              aria-labelledby={`headingchange${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                {
                  allChanges.map((change, index) => {
                    return (
                      <div className="accordion-item" key={`${change.changeId}${change.field}`}>
                        <h2 className="accordion-header" id={`heading${change.changeId}${change.field}`}>
                          <button
                            className="accordion-button"
                            type="button"
                            onClick={() => toggleFieldExpansion(`${change.changeId}${change.field}`)}
                            aria-expanded={expandedField === `${change.changeId}${change.field}`}
                            aria-controls={`collapse${change.changeId}${change.field}`}
                          >
                            <span className="mr-4" style={{ fontWeight: 600, opacity: 0.85 }}>{change.field} </span>
                          </button>
                        </h2>
                        <div
                          id={`collapse${change.changeId}${change.field}`}
                          className={`accordion-collapse collapse ${expandedField === `${change.changeId}${change.field}` ? 'show' : ''}`}
                          aria-labelledby={`heading${change.changeId}${change.field}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div>
                              <h2 className="accordion-header" id={`heading${change.changeId}${change.field}_initial`}>
                                <button
                                  className="accordion-button text-danger"
                                  type="button"

                                  onClick={() => toggleInitialValueVisibility(`${change.changeId}${change.field}`)}
                                  aria-expanded={initialValueVisible[`${change.changeId}${change.field}`]}
                                  aria-controls={`collapse${change.changeId}${change.field}_initial`}
                                >
                                  Initial Value
                                </button>
                              </h2>
                              <div
                                id={`collapse${change.changeId}${change.field}_initial`}
                                className={`accordion-collapse collapse ${initialValueVisible[`${change.changeId}${change.field}`] ? 'show' : ''}`}
                                aria-labelledby={`heading${change.changeId}${change.field}_initial`}
                                data-bs-parent={`#collapse${change.changeId}${change.field}`}
                              >
                                <div style={{ overflowX: 'auto' }} className="accordion-body">
                                  {typeof change.prevValue === 'object' ?
                                    (
                                      change.field === "specification_clog" ? (
                                        <Table striped bordered hover>
                                          <thead>
                                            <tr>
                                              <th>Field Name</th>
                                              <th>Value</th>
                                              <th>Rejection Value</th>
                                              <th>Rejection Symbol</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.entries(change.prevValue).map(([fieldName, fieldValue]) => (
                                              Object.entries(fieldValue).map(([key, value]) => (
                                                <tr key={key}>
                                                  <td>{key || "NA"}</td>
                                                  <td>{value.value || "NA"}</td>
                                                  <td>{value.rejectionValue || "NA"}</td>
                                                  <td>{value.rejectionSymbol || "NA"}</td>
                                                </tr>
                                              ))
                                            ))}
                                          </tbody>
                                        </Table>) : change.field === "tnc_clog" && <Table striped bordered hover>
                                          <thead>
                                            <tr>
                                              <th>Field Name</th>
                                              <th>Value</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.entries(change.prevValue).map(([fieldName, fieldValue]) => (
                                              <tr key={fieldName}>
                                                <td>{fieldName || "NA"}</td>
                                                <td dangerouslySetInnerHTML={{ __html: fieldValue.value || "NA" }}></td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>



                                    ) : change.prevValue}
                                </div>
                              </div>
                            </div>

                            <div>
                              <h2 className="accordion-header " id={`heading${change.changeId}${change.field}_updated`}>
                                <button
                                  className="accordion-button text-success"
                                  style={{ color: "#66bb6a !important" }}
                                  type="button"
                                  onClick={() => toggleUpdatedValueVisibility(`${change.changeId}${change.field}`)}
                                  aria-expanded={updatedValueVisible[`${change.changeId}${change.field}`]}
                                  aria-controls={`collapse${change.changeId}${change.field}_updated`}
                                >
                                  Updated Value
                                </button>
                              </h2>
                              <div
                                id={`collapse${change.changeId}${change.field}_updated`}
                                className={`accordion-collapse collapse ${updatedValueVisible[`${change.changeId}${change.field}`] ? 'show' : ''}`}
                                aria-labelledby={`heading${change.changeId}${change.field}_updated`}
                                data-bs-parent={`#collapse${change.changeId}${change.field}`}
                              >
                                <div style={{ overflowX: 'auto' }} className="accordion-body">
                                  <p onClick={() => toggleUpdatedValueVisibility(`${change.changeId}${change.field}`)}>
                                    {typeof change.updatedValue === 'object' ?
                                      (
                                        change.field === "specification_clog" ? (
                                          <Table striped bordered hover>
                                            <thead>
                                              <tr>
                                                <th>Field Name</th>
                                                <th>Value</th>
                                                <th>Rejection Value</th>
                                                <th>Rejection Symbol</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Object.entries(change.updatedValue).map(([fieldName, fieldValue]) => (
                                                Object.entries(fieldValue).map(([key, value]) => (
                                                  <tr key={key}>
                                                    <td>{key}</td>
                                                    <td>{value.value || "NA"}</td>
                                                    <td>{value.rejectionValue || "NA"}</td>
                                                    <td>{value.rejectionSymbol || "NA"}</td>
                                                  </tr>
                                                ))
                                              ))}
                                            </tbody>
                                          </Table>) : change.field === "tnc_clog" && <Table striped bordered hover>
                                            <thead>
                                              <tr>
                                                <th>Field Name</th>
                                                <th>Value</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Object.entries(change.updatedValue).map(([fieldName, fieldValue]) => (
                                                <tr key={fieldName}>
                                                  <td>{fieldName || "NA"}</td>
                                                  <td dangerouslySetInnerHTML={{ __html: fieldValue.value || "NA" }}></td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>



                                      ) : change.updatedValue}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>)
      })}
    </div>
  );

  // function isChanged(data, key, index) {
  //   try {
  //     if (index > 0) {
  //       let currentValue = data[index][key]
  //       let prevValue = data[index - 1][key]
  //       if (typeof (currentValue) === "object" && typeof (prevValue) === "object") {
  //         if (JSON.stringify(currentValue) === JSON.stringify(prevValue)) {
  //           return {
  //             status: false,
  //             msg: '-'
  //           }
  //         }
  //         else {
  //           return {
  //             status: true,
  //             msg: 'Updated'
  //           }
  //         }
  //       }
  //       else if (currentValue === prevValue) {
  //         return {
  //           status: false
  //         }
  //       }
  //       else {
  //         return {
  //           status: true,
  //           msg: currentValue
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log("errorInIsChanged", error);
  //   }
  //   return {
  //     status: false
  //   }
  // }
  // let res = [
  //   {
  //     "quantity_clog": "1",
  //     "currency_clog": "Dollars:$:USD",
  //     "laycan_start_clog": "2024-05-03T00:00:00.000Z",
  //     "laycan_end_clog": "2024-05-30T00:00:00.000Z",
  //     "load_country_clog": "AL:2",
  //     "unload_country_clog": "AL:2",
  //     "unloading_port_clog": "sarande_al",
  //     "loading_port_clog": "durres_al",
  //     "payment_mode_clog": "1",
  //     "price_type_clog": "1",
  //     "price_clog": 121,
  //     "created_at_clog": "2024-05-03T13:27:51.000Z",
  //     "created_by_clog": 1234,
  //     "contract_number": "CT-24FOBM4FQOM7885",
  //     "contract_name": "test-1",


  //     "agencynameExporter": null,
  //     "agencynameImporter": null,
  //     "agencynameThird": null,
  //     "tradeinsurer": null,
  //     "commodityInsurer": null,
  //     "shipOwner": null,
  //     "changingParty": "LINTL CLOTHING PVT LTD2",
  //     "commodity_name": null
  //   },
  //   {
  //     "quantity_clog": "1",
  //     "currency_clog": "Dollars:$:USD",
  //     "laycan_start_clog": "2024-05-03T00:00:00.000Z",
  //     "laycan_end_clog": "2024-05-30T00:00:00.000Z",
  //     "load_country_clog": "IN:101",
  //     "unload_country_clog": "AL:2",
  //     "unloading_port_clog": "albert_victor_beherai_in",
  //     "loading_port_clog": "sarande_al",
  //     "payment_mode_clog": "1",
  //     "price_type_clog": "1",
  //     "price_clog": 121,
  //     "created_at_clog": "2024-05-03T13:29:12.000Z",
  //     "created_by_clog": 5130,
  //     "contract_number": "CT-24FOBM4FQOM7885",
  //     "contract_name": "test-1",


  //     "agencynameExporter": null,
  //     "agencynameImporter": null,
  //     "agencynameThird": null,
  //     "tradeinsurer": null,
  //     "commodityInsurer": null,
  //     "shipOwner": null,
  //     "changingParty": "LINTL CLOTHING PVT LTD2",
  //     "commodity_name": null
  //   }
  // ]



  // return (<>
  //   <div className="mt-2">
  //     <label className="mt-1 mb-3 font-size-16 font-wt-500" >Change logs</label>
  //     <div style={{ overflowX: 'auto' }} >
  //       <Table responsive>
  //         <thead>
  //           <tr>
  //             <th><label className='font-size-12 font-wt-600' >#</label></th>
  //             <th><label className='font-size-12 font-wt-600' >Contract No</label></th>
  //             <th><label className='font-size-12 font-wt-600' >Contract Name</label></th>
  //             {/* <th><label className='font-size-12 font-wt-600 mr-3' >Commodity</label></th> */}
  //             <th><label className='font-size-12 font-wt-600' >Laycan Start</label></th>
  //             <th><label className='font-size-12 font-wt-600' >Laycan End</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Payment Mode</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Price Type</label></th>
  //             <th><label className='font-size-12 font-wt-600' >Price</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Currency</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Quantity</label></th>
  //             {/* <th>Exporter's Inv. Agency</th>
  //             <th>Importer's Inv. Agency</th>
  //             <th>Third Party Inv. Agency</th>
  //             <th>Trade Insurer</th>
  //             <th>Commodity Insurer</th> */}
  //             {/* <th><label className='font-size-12 font-wt-600 mr-3' >Ship Owner</label></th> */}
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Load Country</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Loading Port</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Unload Country</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Unloading Port</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Commodity Specifications</label></th>
  //             <th><label className='font-size-12 font-wt-600 mr-3' >Contract TNC</label></th>
  //             <th><label className='font-size-12 font-wt-600' >Dated</label></th>
  //             <th><label className='font-size-12 font-wt-600' >Changes By</label></th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {(Data && Data.length) ? Data.map((value, index) => {
  //             if (index > 0) {
  //               return (<tr>
  //                 <td><label className='font-size-12 font-wt-400' >{index}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{value.contract_number ? value.contract_number : "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "contract_name", index)["msg"] || "-"}</label></td>
  //                 {/* <td><label className='font-size-12 font-wt-400 mr-3' >{isChanged(Data, "commodity_name", index)["msg"] || "-"}</label></td> */}
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "laycan_start_clog", index)["msg"] ? formatDate_Application(value.laycan_start_clog) : "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "laycan_end_clog", index)["msg"] ? formatDate_Application(value.laycan_end_clog) : "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "payment_mode_clog", index)["msg"] || "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "price_type_clog", index)["msg"] || "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "price_clog", index)["msg"] || "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "currency_clog", index)["msg"] ? isChanged(Data, "currency_clog", index)["msg"]?.split(':')[1] : "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "quantity_clog", index)["msg"] || "-"}</label></td>
  //                 {/* <td>{value.agencynameExporter ? value.agencynameExporter : "-"}</td>
  //               <td>{value.agencynameImporter ? value.agencynameImporter : "-"}</td>
  //               <td>{value.agencynameThird ? value.agencynameThird : "-"}</td>
  //               <td>{value.tradeinsurer ? value.tradeinsurer : "-"}</td>
  //               <td>{value.commodityInsurer ? value.commodityInsurer : "-"}</td> */}
  //                 {/* <td><label className='font-size-12 font-wt-400' >{value.shipOwner ? value.shipOwner : "-"}</label></td> */}
  //                 <td>{isChanged(Data, "load_country_clog", index)["msg"] ? <span className="shadow">
  //                   <ReactCountryFlag
  //                     countryCode={isChanged(Data, "load_country_clog", index)["msg"].split(':')[0]}
  //                     style={{ width: '25px', height: '25px' }} svg />
  //                 </span> : "-"}</td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "loading_port_clog", index)["msg"] || "-"}</label></td>
  //                 <td>{isChanged(Data, "unload_country_clog", index)["msg"] ? <span className="shadow">
  //                   <ReactCountryFlag
  //                     countryCode={isChanged(Data, "unload_country_clog", index)["msg"].split(':')[0]}
  //                     style={{ width: '25px', height: '25px' }} svg />
  //                 </span> : "-"}</td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "unloading_port_clog", index)["msg"] || "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "specification_clog", index)["msg"] || "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{isChanged(Data, "tnc_clog", index)["msg"] || "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{value.created_at_clog ? formatDateTime_Application(value.created_at_clog) : "-"}</label></td>
  //                 <td><label className='font-size-12 font-wt-400' >{value.changingParty ? value.changingParty : "-"}</label></td>
  //               </tr>
  //               )
  //             }
  //           }) : ""}
  //         </tbody>
  //       </Table>
  //     </div>


  //   </div>
  // </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------


export default ContractTrail
