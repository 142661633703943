import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { formatDate_Application } from '../../utils/dateFormaters';
import avatarUrl from '../../utils/makeAvatarUrl';
import InvestigationReportForm from '../investigationReport/investigationReportForm';
import { ContractDocList } from '../logistics/contractDetailShipmentV2';
import Header from '../partial/header';
import { inspectionTypeIds } from '../partial/sidebar';

export const WorkOrderDetail = ({ userTokenDetails, offer_data, data, onClose }) => {

  const [showLoader, setshowLoader] = useState(false);
  const [offerData, setOfferData] = useState(offer_data)
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Summary");
  const [partyDetails, setPartyDetails] = useState({ trader: {}, agency: {} })
  const [contractDoc, setcontractDoc] = useState({
    show: false,
    data: []
  })

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  useEffect(() => {
    if (offerData.booking_id) {
      call('POST', 'getInspectionPartyDetails', { bookingId: offerData.booking_id, offerId: offerData.id }).then((result) => {
        setPartyDetails(result)
      }).catch((e) => {
      })
    }
  }, [offerData])

  return (
    <>
      <Header
        title={contractDoc.show ? "Workorder Documents" : 'Workorder Details'}
        userTokenDetails={userTokenDetails}
        showNavBack={true}
        onBackAction={onClose}
      />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {contractDoc.show ?
        <ContractDocList offerId={offerData.booking_id} userTokenDetails={userTokenDetails} /> : null
      }

      {!contractDoc.show ? (
        <>
          <div className="d-md-flex">
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <ul className="contract-left">
              <li>
                <h2>Contract #{offerData.booking_id ? offerData.booking_id + " : " + tabName : '--'}</h2>
                <p>{data.created_at ? formatDate_Application(data.created_at) : '--'}</p>
              </li>
            </ul>
            <div className="ml-auto pr-3">
              <ul className="contract-top d-flex flex-md-row align-items-center">

                <li>
                  <button type="button" className="btn btn-info btn-sm" onClick={() => {
                    setcontractDoc({ ...contractDoc, show: true })
                  }}>List of Documents</button>
                </li>

              </ul>

            </div>
          </div>

          <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                setTab(0);
                setTabName("Summary");
              }}>Summary</a>
            </li>

            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                setTab(2);
                setTabName("Investigation Process");
              }}>Investigation</a>
            </li>
          </ul>

          <>
            {tab === 0 &&
              <>
                <div className="tab-pane active show ">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="row mt-2">
                        <div className="col-md-3 ">
                          <div className="form-group">
                            <label className="col-md-12">Importer/Exporter</label>
                            <div className="chat-message pl-2">
                              <img src={avatarUrl(partyDetails.trader.user_avatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                <span className="text-primary">  {partyDetails.trader.company_name || "-"}</span><br />
                              </div>
                              <p className="date-format p-0 m-0">{partyDetails.trader.email_id || "-"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="form-group">
                            <label className="col-md-12">Investigation Agency</label>
                            <div className="chat-message pl-2">
                              <img src={avatarUrl(partyDetails.agency.user_avatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                <span className="text-primary">  {partyDetails.agency.company_name || "-"}</span><br />
                              </div>
                              <p className="date-format p-0 m-0">{partyDetails.agency.email_id || "-"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="d-md-flex align-content-around flex-wrap mb-3 contract-details">
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{data.commodity_name ? data.commodity_name.toUpperCase() : ""}</h3>
                            <p>Commodity</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{offerData.offer_details_obj.currency + " " + offerData.offer_details_obj.inspection_quote}</h3>
                            <p>Price</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{offerData.offer_details_obj.quantity + " mt"}</h3>
                            <p>Quantity</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{data.port_country}</h3>
                            <p>Port Country</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{data.port_name}</h3>
                            <p>Port Name</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{formatDate_Application(offerData.offer_details_obj.laycan_start_date)}</h3>
                            <p>Laycan Start Date</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{formatDate_Application(offerData.offer_details_obj.laycan_end_date)}</h3>
                            <p>Laycan End Date</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </>
            }

            {tab === 2 &&
              <div className="tab-pane active show col-md-12">
                <InvestigationReportForm
                  userTokenDetails={userTokenDetails}
                  mktPlaceContract={true}
                  contractNo={offerData.booking_id}
                  commoditySpecs={offerData.offer_details_obj.commodity_specs}
                  contractData={{
                    contractNo: offerData.booking_id,
                    contractId: offerData.booking_id,
                    inv_agency_importer: 1,
                    inv_agency_exporter: 1,
                    inv_agency_third: 1,
                    importer_id: 1,
                    exporter_id: 1
                  }}
                />
              </div>
            }
          </>
        </>
      ) : null}
    </>

  )
}


