import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import call from "../../service";
import { FileInput } from "../../utils/FileInput";
import { ExportExcel, encryptData, printDiv } from "../../utils/myFunctions";
import NewTablev2 from "../../utils/newTablev2";
import DonutChart from "../Dashboard/components/DonutChart";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import { BuyerShipmentsColumn, Shipmentcolumns } from "../Reports/TableJson";
import TableFilter from "../wallet/components/tableFilter";
import { useHistory } from "react-router";
import BuyerManagement from "./buyerManagement";
import ExporterManagement from "./exporterManagement";


const reviewForm = [
  { "name": "Contact Person Name", val: "contact_person", unit: "name_title" },
  { "name": "Company Name", val: "company_name" },
  { "name": "Country", val: "country_code" },
  { "name": "GST No", val: "gst_vat_no" },
  { "name": "CIN No", val: "cin_no" },
  { "name": "IEC No", val: "iec_no" },
  { "name": "PAN No", val: "pan_no" },
  { "name": "URN No", val: "urn" },
  { "name": "Email Id", val: "email_id" },
  { "name": "Contact Number:", val: "contact_number", unit: "phone_code" },
  { "name": "Address", val: "user_address" },
  { "name": "Website", val: "website" },
  { "name": "Previous year annual sales", val: "prevSale", unit: "currency" },
  { "name": "Expected current year’s annual sale", val: "annualSale", unit: "currency" },
  { "name": "Terms of Payment", val: "termsOfPayment" },
  { "name": "Product Details", val: "productDetail" },
  { "name": "HSN Code", val: "exporterHsnCode" },
  { "name": "Date of Commencement", val: "dateOfCommencement" },
  { "name": "Date of Incorporation", val: "dateOfIncorporation" },
  { "name": "District Industries Center", val: "districtIndustriesCentre" },
  { "name": "Exporter Type", val: "type" },
  { "name": "Organization Type", val: "organization_type" },
]

const currentYear = new Date().getFullYear();

const firstDay = new Date(currentYear, 0, 1).toISOString();

const lastDay = new Date().toISOString();

const ViewSupplierDetailsCP = ({ userTokenDetails, navToggleState, data, onEdit, onDelete, hideTopBuyersView, goback, disableSupplierView }) => {
  let history = useHistory()

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [top5suppliers, setTop5Suppliers] = useState([])
  const [chartdata, setChartdata] = useState([])
  const [tab, setTab] = useState('Details')
  const [businessUserToken, setBusinessUserToken] = useState({})

  useEffect(() => {
    getBusinessUserToken()
  }, [])

  async function getBusinessUserToken() {
    let tempBusinessUserToken = await call('post', 'loginV2', { username: data.email_id, password: data.password, bypassAccountNotActiveError: true })
    setBusinessUserToken(tempBusinessUserToken)
  }

  console.log('tempBusinessUserTokennnnnnnnnn', businessUserToken)
  const getShipmentsdata = () => {
    setShowLoader(true)
    let reqObj = {
      ttvId: data.ttvId,
      search: filter.search,
      resultPerPage: filter.resultPerPage / 1,
      currentPage: page / 1,
      EXPORTER_CODES: userTokenDetails?.ttvExporterCode?.split(',') || []
    }
    call('POST', 'getShipmentsByBuyer', reqObj).then(result => {
      setdbData(result.data)
      setCount(result.count_data)
      setShowLoader(false)
    }).catch(e => {
      console.log('error in getShipmentsByBuyer', e)
      setShowLoader(false)
    })
  }
  const getTop5Suppliers = () => {
    setShowLoader(true)
    let reqObj = {
      ttvId: data.ttvId,
      userId: userTokenDetails?.user_id
    }
    call('POST', 'getTop5Suppliers', reqObj).then(result => {
      setTop5Suppliers(result)
      setShowLoader(false)
    }).catch(e => {
      console.log('error in getTop5Suppliers', e)
      setShowLoader(false)
    })
  }
  const getActualSalesCurrentYear = () => {
    setShowLoader(true)
    let reqObj = {
      ttvId: data.ttvId,
    }
    call('POST', 'getActualSalesCurrentYear', reqObj).then(result => {
      setShowLoader(false)
      console.log('result in getActualSalesCurrentYear', result, data.currentAnnualSale)
      if (result) {
        let actualper = (result / data.currentAnnualSale) * 100
        let expectedPer = 100 - actualper
        const chartData = [
          { label: "Actual", value: actualper?.toFixed(2)?.toString() },
          { label: "Expected", value: expectedPer?.toFixed(2)?.toString() }
        ]
        console.log('Chartdata', chartData)
        setChartdata(chartData)
      } else {
        const chartData = [
          { label: "Actual", value: '0.00' },
          { label: "Expected", value: '100.00' }
        ]
        setChartdata(chartData)
      }

    }).catch(e => {
      console.log('error in getTop5Suppliers', e)
      setShowLoader(false)
    })
  }
  useEffect(() => {
    getShipmentsdata()
  }, [refresh, page])
  useEffect(() => {
    getActualSalesCurrentYear()
  }, [])
  console.log('chartdataaaaa', chartdata)
  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-4">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <img src="assets/images/ArrowBackLeft.png" height={20} width={20} className="cursor mr-3" onClick={() => {
              if (goback) {
                goback()
              } else if (disableSupplierView) {
                disableSupplierView()
              }
              else {
                history.go(-1)
              }
            }} />
            {/* <div className="mx-2">
              <p className="font-wt-600 font-size-16 p-0 m-0">{data.company_name}</p>
              <label className="font-wt-500 font-size-14 text-color1 p-0 m-0">{data.website || 'NA'}</label>
            </div> */}
          </div>
          {/* <div>
            {onEdit ? (
              <div className="d-flex flex-row">
                <img src="assets/images/bytesize_edit.png" height={20} width={20} onClick={() => onEdit(data.index)} className="cursor mx-2" />
                <img src="assets/images/deleteIcon.png" height={20} width={20} onClick={() => onDelete(data.index)} className="cursor mx-2" />
              </div>
            ) : null}
          </div> */}
          <div>
          </div>
        </div>
      </div>

      <div className='py-2 d-flex justify-content-between w-100 flex-col-mob'>
        <nav>
          <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
            <button className={`nav-link  bg-transparent ${tab === "Details" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("Details")}>Details</button>
            <button className={`nav-link  bg-transparent ${tab === "Buyers" && 'active'} paymenttab`} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Buyers")}>Buyers</button>
            <button className={`nav-link  bg-transparent ${tab === "Suppliers" && 'active'} paymenttab`} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Suppliers")}>Suppliers</button>
          </div>
        </nav>
      </div>
      {tab === "Details" ?
        <div className="row my-4">
          <div className="col-md-12">
            <div className="card border-0 chatlist p-4">
              <div className="row">
                {reviewForm.map((item) => {
                  if (data[item.val]) {
                    return (
                      <div className="col-md-6 pe-5">
                        <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div> : null}
      {tab === "Buyers" ?
        <div className="row my-4">
          <BuyerManagement userTokenDetails={businessUserToken} cpView={true} />
        </div> : null}
      {tab === "Suppliers" ?
        <div className="row my-4">
          <ExporterManagement userTokenDetails={businessUserToken} cpView={true} />
        </div> : null}
    </div >
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewSupplierDetailsCP)