import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getContactObject, getDocDetails, isEmpty, most_used_currencies, SetCache, toolTip, validateEmail } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn, wcQuotesColumnAdmin } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { companyTypes, industryData } from '../registration/newRegistration';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const SCFQuoteAdmin = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const [selectSupplierPopup, setselectSupplierPopup] = useState(false)
  const [suppliers, setsuppliersdata] = useState([])
  const [suppliersoverall, setsuppliersdataoverall] = useState([])
  const [supplierName, setSupplierName] = useState('')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getSCFQuoteListAdmin', objectAPI).then((result) => {
      console.log('running getSCFQuoteListAdmin api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBGQuoteList', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  useEffect(() => {
    getAllIndiaExporters()
    call('POST', 'getSCFQuoteListFilter', { userId }).then(res => {
      console.log("getWCQuoteListFilterthen", res);
      setFilterData(res)
    }).catch(err => { })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('scfApplicationId', selectedChat.scfApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const getAllIndiaExporters = () => {
    setshowLoader(true)
    call('POST', 'getAllIndiaExporters', { supplierName, userTypeId, userId }).then(result => {
      setsuppliersdata(result)
      setsuppliersdataoverall(result)
      setshowLoader(false)
    }).catch(e => {
      setsuppliersdata([{ id: "temp", "EXPORTER_NAME": "Add New Option", typedInput: supplierName }])
      setshowLoader(false)
    })
  }
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (supplierName) {
        getAllIndiaExporters()
      }
    }, 1000);
    return () => {
      clearTimeout(debounce)
    }
  }, [supplierName])

  const handleMultiSelectchange = async (e, name, val, singleSelect) => {
    if (singleSelect) {
      if (e?.[0]?.id === "temp") {
        console.log('1111111111111111111111111', { ...e[0], [val]: e[0]["typedInput"], id: e[0]["typedInput"] });
        let allsuppliers = suppliersoverall
        let EXPORTER_NAME = e[0]["typedInput"]
        allsuppliers.push({ ...e[0], ["EXPORTER_NAME"]: e[0]["typedInput"], id: e[0]["typedInput"] })
        setsuppliersdata(allsuppliers)
        setsuppliersdataoverall(allsuppliers)
        setData({
          ...data,
          [name]: EXPORTER_NAME,
          organization_type: (EXPORTER_NAME?.toLowerCase().includes("pvt ltd") || EXPORTER_NAME?.toLowerCase().includes("ltd")) ? 'pvtPubLtd' : EXPORTER_NAME?.toLowerCase().includes("llp") ? 'llp' : ''

        })
      } else {
        const exporter = suppliersoverall.find(item => item.EXPORTER_NAME == e?.[0]?.["EXPORTER_NAME"])
        let contact = getContactObject(exporter?.EXTRA_DETAILS || [])
        const result = contact ? await call('POST', 'checkduplicateEmailId', { email_id: contact["Email ID"] }) : ''

        setData({
          ...data,
          [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
          contactPerson: contact ? contact["Contact Person"] : "",
          contact_number: contact ? contact["Contact Number"] : "",
          designation: contact ? contact["Designation"] : "",
          email_id: contact ? contact["Email ID"] : "",
          user_address: exporter?.EXPORTER_ADDRESS || "",
          organization_type: (exporter?.EXPORTER_NAME?.toLowerCase().includes("pvt ltd") || exporter?.EXPORTER_NAME?.toLowerCase().includes("ltd")) ? 'pvtPubLtd' : exporter?.EXPORTER_NAME?.toLowerCase().includes("llp") ? 'llp' : ''
        })
        setErrors({
          email_id: result
        })
      }

    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };


  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === 'email_id') {
      let emailTovalidate = event.target.value
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
      if (validateEmail(emailTovalidate)) {
        const result = await call('POST', 'checkduplicateEmailId', { email_id: event.target.value })
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: result })
      } else {
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
      }

    } else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }

  }

  const handleValidation = () => {
    let err = {}
    const validationFields = ["supplierName", "contactPerson", "contact_number", "designation", "email_id"]
    for (let index = 0; index < validationFields.length; index++) {
      const element = validationFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      } else if (errors[element]) {
        err[element] = errors[element]
      }
    }
    let reqBodyFields = ["supplierName", "contactPerson", "name_title", "phone_code", "contact_number", "designation", "email_id", "industry_type", "organization_type", "user_address"]
    if (isEmpty(err)) {
      let obj = {
        'application_type': 'Invoice Limit'
      }
      reqBodyFields.forEach(k => {
        obj[k] = data[k]
      })
      let exporter = suppliersoverall.find(item => item.EXPORTER_NAME === data["supplierName"])
      if (exporter) {
        obj["EXPORTER_CODE"] = exporter.EXPORTER_CODE
      }
      setshowLoader(true)
      call('POST', 'createdirectapplication', obj).then(result => {
        localStorage.setItem("UserDetails", JSON.stringify(result))
        window.open(`/applylimitSCF`, "_blank")
        setshowLoader(false)
        setselectSupplierPopup(false)
        setData({
          ...data,
          supplierName: "",
          contactPerson: "",
          contact_number: "",
          designation: "",
          email_id: "",
          user_address: "",
          organization_type: ''
        })
        setSupplierName('')
        setsuppliersdata([])
        setsuppliersdataoverall([])
      }).catch(e => {
        setshowLoader(false)
        toastDisplay("Failed To Create Application", "error")
      })
    } else {
      console.log('error in mandat', data);
      toastDisplay("Form Validation Error", "info")
    }
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectSupplierPopup &&
        <FinanceInvoiceModal modalSize={"md"} isCentered={true} limitinvoice={selectSupplierPopup} closeSuccess={() => {
          setselectSupplierPopup(false)
          setData({
            ...data,
            supplierName: "",
            contactPerson: "",
            contact_number: "",
            designation: "",
            email_id: "",
            user_address: "",
            organization_type: ''
          })
          setSupplierName('')
          setsuppliersdata([])
          setsuppliersdataoverall([])
        }}>
          <div>
            <div className='col-md-12'>
              <MultipleSelect
                isMulti
                Id="Supplier Name"
                Label="Supplier Name"
                selectedvalue="Supplier Name"
                filterOption={() => true}
                onInputChange={(e) => {
                  setSupplierName(e)
                }}
                optiondata={suppliers}
                onChange={(e) => handleMultiSelectchange(e, "supplierName", "EXPORTER_NAME", true)}
                value={data.supplierName ? [data.supplierName] : []}
                name="supplierName"
                labelKey={"EXPORTER_NAME"}
                valKey={"EXPORTER_NAME"}
                customStyles={{
                  backgroundColor: '#DEF7FF',
                  borderRadius: '10px'
                }}
              />
            </div>
            <div className="col py-2 ">
              <div className="col-md-12 px-0">
                <InputWithSelect isAstrix={true} type={"text"} label={"Contact Person"}
                  selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                  selectName={"name_title"} selectValue={data.name_title}
                  optionLabel={"name"} optionValue={'name'}
                  name={'contactPerson'} value={data.contactPerson} error={errors.contactPerson}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2 ">
              <div className="col-md-12 px-0">
                {/* <label>Contact Number</label> */}
                <InputWithSelect isAstrix={true} type={"text"} label={"Contact Number"}
                  selectData={countrys}
                  selectName={"phone_code"} selectValue={data.phone_code}
                  optionLabel={"phonecode"} optionValue={'phonecode'}
                  name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={true} type={"text"} label={"Designation"}
                  name={"designation"} value={data.designation} error={errors.designation}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={true} type={"text"} label={"Email ID"}
                  name={"email_id"} value={data.email_id} error={errors.email_id}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect
                  isAstrix={true}
                  selectData={companyTypes}
                  optionLabel={'name'}
                  optionValue={'alt'}
                  name={"organization_type"}
                  label={'Organization Type'}
                  value={data.organization_type || ""}
                  onChange={handleChange}
                  error={errors.organization_type} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect
                  isAstrix={true}
                  selectData={industryData}
                  optionLabel={'name'}
                  optionValue={'value'}
                  name={"industry_type"}
                  label={'Industry Type'}
                  value={data.industry_type || ""}
                  onChange={handleChange}
                  error={errors.industry_type} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={false} type={"text"} label={"Address"}
                  name={"user_address"} value={data.user_address} error={errors.user_address}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="d-flex gap-4  px-3 ">
              <button className={`mt-4 new-btn w-40 py-2 px-2 text-white cursor`} onClick={handleValidation}>Create Application</button>
            </div>

          </div>
        </FinanceInvoiceModal>
      }
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="otherFinAdminSCFQuote" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Supply Chain Finance > Quotes"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} isAdditionalButton>
                  <div className="d-flex gap-4">
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setselectSupplierPopup(true)}>Create New Application</button>
                  </div>
                </Filter>

                {Object.values(filterData || {})?.filter(i => {
                  if (i.isFilterActive) { return true }
                })?.length ? null : <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                  {finHighlights.map((i, j) => {
                    return (<div className='d-flex align-items-center mx-2' >
                      <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                      <label className='font-size-13 font-wt-500 mt-2 mx-2' >{i.name}</label>
                    </div>)
                  })}
                </div>}

              </div>

              <div>
                <NewTablev2 columns={wcQuotesColumnAdmin} >
                  {dbData.map((item, j) => {
                    let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
                    let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
                    let buyersCredit = item.financierQuotes ? JSON.parse(item.financierQuotes) : []
                    let chatRoomIds = item.chatRoomIds?.split(",") || []
                    let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                    let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

                    let allDeniedCount = 0
                    let apporvedCount = 0

                    if (item.financierQuotes) {
                      for (let index = 0; index < JSON.parse(item.financierQuotes).length; index++) {
                        const j = JSON.parse(item.financierQuotes)[index];
                        if (j.financierAction === "deny") {
                          allDeniedCount += 1
                        }
                        else {
                          apporvedCount += 1
                        }
                      }
                    }

                    let supplierNames = []
                    let supplierLimits = []
                    let supplierCount = 0
                    for (let index = 0; index < 11; index++) {
                      if (item.details[`supplierName${index}`]) {
                        supplierCount += 1
                        supplierNames.push(item.details[`supplierName${index}`])
                        supplierLimits.push(item.details[`supplierCreditLimitRequired${index}`])
                      }

                    }

                    return (
                      <tr>
                        <td>
                          <label className='font-size-12 font-wt-500'>{moment(item.applicationUpdatedAt).format("DD/MM/YYYY")}</label>
                        </td>
                        <td>
                          <label className='font-size-12 font-wt-500'>{item.supplierCompanyName || "-"}</label>
                        </td>
                        <td >
                          <ul className='py-0 pl-3 cursor font-size-12 font-wt-500'>
                            {supplierNames.map(item => {
                              return <li >
                                <div className='font-size-12'>
                                  {item}
                                </div>
                              </li>
                            })}
                          </ul>
                        </td>
                        <td  >
                          <ul className='py-0 pl-3 cursor font-size-12 font-wt-500'>
                            {supplierLimits.map(item => {
                              return <li >
                                <div className='font-size-12'>
                                  {"$ " + item}
                                </div>
                              </li>
                            })}
                          </ul>
                        </td>
                        <td>
                          <div
                            className=''
                          >
                            {selectedLenderName.length ? selectedLenderName.map((key, j) => {
                              let isApprovedByFinancier = buyersCredit?.filter(i => {
                                if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "Approved") {
                                  return i
                                }
                              })?.[0]
                              let isRejectedByFinancier = buyersCredit?.filter(i => {
                                if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "deny") {
                                  return i
                                }
                              })?.[0]
                              let openChatRoomIndx = null
                              chatRoomUsers.forEach((u, i) => {
                                if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
                                  openChatRoomIndx = i
                                }
                              })
                              return (
                                <div className='position-relative' >
                                  <img className='cursor'
                                    onClick={async () => {
                                      let reqObj = {
                                        senderId: userId,
                                        receiverId: selectedLenderId[j],
                                        textMessage: 'Hii',
                                        chat_room_name: "CHAT" + new Date().getTime(),
                                        includeAdmins: true,
                                        scf: item.applicationId,
                                        receiverParties: selectedLenderId[j],
                                        dontSendInitialMsg: true,
                                        userTypeId,
                                        sellerId: item.supplierUserId
                                      }
                                      setshowLoader(true)
                                      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                      setshowLoader(false)
                                      getChatDetails({
                                        chat_room_id: apiResp.id,
                                        loggedInUser: userId
                                      })
                                      setSelectedChat({
                                        chatRoomId: apiResp.id,
                                        receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                        scfApplicationId: item.applicationId,
                                        parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                        userId: userId,
                                        isChatOpen: true,
                                        receiverId: selectedLenderId[j],
                                        sellerId: item.supplierUserId
                                      })
                                    }}
                                    src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                                  <label className={`ml-3 font-size-12 text-color-label font-wt-600 ${selectedLenderId[j] / 1 == item.selectedFinancier / 1 ? " text5CB8D3 " : isApprovedByFinancier ? " text2ECC71 " : " "}  ${isRejectedByFinancier ? ' text-danger ' : '   '} `} >{`${key} `}
                                    <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                                </div>
                              )
                            }) : "NA"}
                          </div>
                        </td>
                        <td >
                          <div className=''>
                            {apporvedCount ?
                              <button type="button"
                                class={`approved text-white border-0 `}>
                                {"Approved"}
                              </button> :
                              (allDeniedCount / 1 == selectedLenderName?.length / 1) ?
                                <button type="button"
                                  class={`rejected text-white border-0 `}>
                                  {"Rejected"}
                                </button>
                                :
                                <button type="button"
                                  class={`inprogress text-white border-0 `}>
                                  {"Inprogress"}
                                </button>}
                          </div>
                        </td>
                        <td
                          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          className='position-relative'>
                          <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                            onClick={() => setAction({ show: true, index: j })}
                            aria-hidden="true"></i>
                          {action.show && action.index === j ? (
                            <Action
                              id={j}
                              onDismiss={() => setAction({ show: false, index: j })}
                              options={[
                                {
                                  name: "Application", icon: "edit.png", onClick: () => {
                                    item["disableFinSelection"] = true
                                    item["isAdminUser"] = true
                                    item["manualUserId"] = item.supplierUserId
                                    localStorage.setItem("scfQuoteDetails", JSON.stringify(item))
                                    window.location = `/scfQuoteDetails`;
                                  }
                                }
                              ]} />
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}
                </NewTablev2>
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

            </div>
          </main>
        </div>
      </div>

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SCFQuoteAdmin)