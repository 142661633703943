import React, { PureComponent } from 'react';
import { MoonLoader } from 'react-spinners';
import { PieChart, Pie, Cell, Tooltip, Sector } from 'recharts';

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius - 40) * cos;
  const sy = cy + (outerRadius - 40) * sin;
  return (
    <Sector
      cx={sx}
      cy={sy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill="red"
    />
  );
};
export default class PieChartComponent extends PureComponent {
  render() {
    return this.props.loader ?
      <div className="d-flex flex-row justify-content-center align-items-center h-100">
        <MoonLoader
          color={"#1B94B7"}
          loading={this.props.loader}
          size={80}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      :
      <div>
        <PieChart width={250} height={this.props.height || 350} onMouseEnter={this.onPieEnter}>
          <Pie
            data={this.props.data}
            cx={120}
            cy={200}
            innerRadius={this.props.innerRadius ? this.props.innerRadius : this.props.totalCount ? 30 : 60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={this.props.paddingAngle ? this.props.paddingAngle : 10}
            dataKey={this.props.dataKey}
            label={this.props.hideLabel}
            cornerRadius={this.props.cornerRadius ? this.props.cornerRadius : 0}
          >

            {this.props.colors && this.props.colors.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry} />
            ))}
          </Pie>
          {this.props.customToolTip ?
            <Tooltip wrapperStyle={{ zIndex: 10 }} content={this.props.customToolTip} />
            : null}
        </PieChart>
        {this.props.label1 && this.props.label2 ?
          <div className='PieLabel col-md-5 text-center'>
            <label className='font-size-16 font-wt-500 m-0'>{this.props.label1}</label>
            <label className='font-size-16 font-wt-600 m-0'>{this.props.label2}</label>
          </div>
          : null
        }
        {this.props.totalCount &&
          <div
            style={{
              position: 'absolute',
              top: '49%',
              left: '52%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontSize: '20px',
            }}
          >
            {Intl.NumberFormat('en-US', { notation: 'compact' }).format(this.props.totalCount)}
          </div>
        }

        {this.props.totalVal ?
          <div style={{
            left: this.props.pieContainerLeft || null,
            top: this.props.pieContainerTop || null
          }} className='pieContainer d-flex align-items-center justify-content-center custom-margin'>
            <label className='font-size-16 font-wt-500'>{(this.props.hideDollar ? "" : "$ ") + Intl.NumberFormat('en-US', { notation: 'compact' }).format(this.props.totalVal)}</label>
          </div>
          : null
        }
        {this.props.reloadFn &&
          <div className='d-flex justify-content-end align-items-center cursor' onClick={this.props.reloadFn} >
            <img src='assets/images/refresh.png' height={25} width={25} />
            <label className='mb-0 font-size-13 font-wt-500'>Refresh</label>
          </div>
        }
      </div>

  }
}
