import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import call from "../../service";
import ChatBot from "../../utils/ChatBot";
import toastDisplay from "../../utils/toastNotification";
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import TutorialPopup, { TutorialVideoPopup } from "../tutorialPopup";
import FAQsCard from "./components/faqsCard";
import SupportChat from "./components/supportChat";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import MultipleSelect from "../../utils/MultipleSelect";
import { getDocDetails, handleFileWithAsyncAwait, multiSelectEventHandler } from "../../utils/myFunctions";
import { NewTextArea } from "../../utils/newInput";
import { FileInput } from "../../utils/FileInput";
import Filter from "../InvoiceDiscounting/components/Filter";
import NewTablev2 from "../../utils/newTablev2";
import moment from "moment";
import { Action } from "../myCounterPartComp/action";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";

let issueCategories = [{ name: "Account Related" }, { name: "Referral Bonus" },
{ name: "Network Management" }, { name: "Contract Management" },
{ name: "Export Factoring" }, { name: "Letter of Credit" },
{ name: "Working Capital" }, { name: "CGTMSE" },
{ name: "Bank Guarantee" }, { name: "Supply Chain Finance" },
{ name: "Domestic Invoice Discounting" }, { name: "Insurance" },
{ name: "Logistics" }, { name: "E-Docs" }, { name: 'Other' }]

const TrackSupportTicket = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)

  const [chat, setChat] = useState(false);
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 5)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [showLoader, setshowLoader] = useState(false)
  const [dbData, setDbData] = useState([])
  const [faqType, setFaqType] = useState('LC')
  const [data, setData] = useState({})
  const [supportTicketPopup, setSupportTicketPopup] = useState({ show: queryParams.get('createNew') ? true : false })
  const [error, setError] = useState({})
  const [allPlatformUsers, setAllPlatformUsers] = useState([])
  const [issueCategoriesFiltered, setIssueCategoriesFiltered] = useState(issueCategories)
  const [issueAttachments, setIssueAttachments] = useState([null])
  const [tickets, setTickets] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10, search: queryParams.get("search") })
  const [page, setPage] = useState(1)
  const [filterData, setFilterData] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [count, setCount] = useState(0)
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    call('POST', 'getAllUsersOfThePlatform', {}).then(res => {
      setAllPlatformUsers(res)
    })
  }, [])

  useEffect(() => {
    fetchSupportTickets()
  }, [page, filter, filterData, refresh])

  async function fetchSupportTickets() {
    call('POST', 'getSupportTicketRequest', { userId, userTypeId, ...filter, }).then(async (result) => {
      console.log('running getSupportTicketRequest api-->', result);
      setshowLoader(false)
      setTickets(result.data);
      setCount(result.count);
    }).catch((e) => {
      // console.log('error in getSupportTicketRequest', e);
      setshowLoader(false)
    });
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', selectedChat.chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError(error => ({ ...error, [event.target.name]: "" }))
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    if (name === "selectIssueCategory") {
      let filteredData = []
      if (typedInput) {
        filteredData = issueCategories.filter((i) => {
          if (i.name.toLowerCase().includes(typedInput.toLowerCase()) || i.name === "Other") {
            return true
          }
        })
      }
      else {
        filteredData = issueCategories
      }
      setIssueCategoriesFiltered([...filteredData])
    }
  }

  return (
    <>
      {supportTicketPopup.show && (
        <FinanceInvoiceModal
          limitinvoice={supportTicketPopup.show}
          closeSuccess={() => setSupportTicketPopup({ show: false })}
        ><div>
            <p className="text-center font-size-16 font-wt-600">Raise a Support Ticket</p>
            <div className="d-flex row mx-3 py-4" >
              {userTypeId / 1 == 1 ?
                <div className="position-relative mt-2">
                  <MultipleSelect singleSelection
                    Label={'Select User'}
                    Id={`selectedUser`}
                    optiondata={allPlatformUsers}
                    onChange={(e) => {
                      handleChange(multiSelectEventHandler(e, `selectedUser`, "id"))
                    }}
                    value={data[`selectedUser`] ? [data[`selectedUser`]] : []}
                    name={`selectedUser`}
                    labelKey={"company_name"}
                    valKey={"id"}
                    error={error[`selectedUser`]}
                  />
                </div> : null}
              <label className="font-size-15 font-wt-500">What is an nature of your request?</label>
              <div className="position-relative mt-2">
                <MultipleSelect singleSelection
                  Label={'Select Issue Category'}
                  Id={`selectIssueCategory`}
                  filterOption={() => true}
                  onInputChange={(e) => {
                    handleFilterOptions(e, "selectIssueCategory")
                  }}
                  optiondata={issueCategoriesFiltered}
                  onChange={(e) => {
                    handleChange(multiSelectEventHandler(e, `selectIssueCategory`, "name"))
                  }}
                  value={data[`selectIssueCategory`] ? [data[`selectIssueCategory`]] : []}
                  name={`selectIssueCategory`}
                  labelKey={"name"}
                  valKey={"name"}
                  error={error[`selectIssueCategory`]}
                />
              </div>
              <div className="position-relative mt-2">
                <NewTextArea label={"Write a brief description of your request"} rows={6}
                  name={"issueDescription"} value={data.issueDescription} onChange={handleChange} />
              </div>
              <label className='text-left font-wt-600 font-size-14 mb-3 w-100 '>Attachments</label>
              {issueAttachments.map((i, j) => {
                if (i === null) {
                  return (<div className='row w-100 d-flex align-items-center mb-3' >
                    <div className='col-11' >
                      <FileInput multipleDragDropEnable={true}
                        onMultipleDragDrop={async (e) => {
                          let tempErr = error
                          let tempData = data
                          for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                            let element = e.target.files[indexx];
                            let customEvent = {
                              target: { name: "issueAttachment" + ":" + (j + indexx), files: [element] },
                              persist: () => console.log("...")
                            }
                            let resp = await handleFileWithAsyncAwait(customEvent, setshowLoader)
                            if (resp.status / 1 == 0) {
                              tempErr = { ...tempErr, ...resp.msg }
                            }
                            if (resp.status / 1 == 1) {
                              tempData = { ...tempData, ...resp.msg }
                            }
                          }
                          setData(tempData)
                          setError(tempErr)
                          let temp = issueAttachments
                          for (let index = 0; index < e.target.files.length - 1; index++) {
                            temp.push(null)
                          }
                          setIssueAttachments([...temp])
                        }}
                        name={"issueAttachment" + ":" + j} value={data["issueAttachment" + ":" + j]} error={error["issueAttachment" + ":" + j]}
                        isEditable={true}
                        onUploadCancel={() => setData({ ...data, ["issueAttachment" + ":" + j]: null })}
                      />
                      {error["issueAttachment" + ":" + j] ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{error["issueAttachment" + ":" + j]}</b></div> : ''}
                    </div>
                    <div
                      className="col-1"
                    >
                      {(issueAttachments.length - 1 > j) ?
                        <img
                          onClick={() => {
                            let temp = issueAttachments
                            let tempUpdatedArr = []
                            for (let m = 0; m < temp.length; m++) {
                              if (m != j) {
                                tempUpdatedArr.push(null)
                              }
                            }
                            setIssueAttachments([...tempUpdatedArr])
                          }}
                          className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                        /> : <img
                          onClick={() => {
                            let temp = issueAttachments
                            temp.push(null)
                            setIssueAttachments([...temp])
                          }}
                          style={{ width: '22px', height: '22px' }}
                          className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                    </div>
                  </div>)
                }
              })}
              <div className="position-relative mt-4 d-flex justify-content-center">
                <button
                  disabled={userTypeId / 1 == 1 ?
                    !(data.selectedUser && data.selectIssueCategory && data.issueDescription) : !(data.selectIssueCategory && data.issueDescription)} type="button" className="new-btn text-white py-2 w-50"
                  onClick={() => {
                    setshowLoader(true)
                    let formData = new FormData()
                    formData.append("userId", userTypeId / 1 == 1 ? data.selectedUser : userId)
                    formData.append("selectIssueCategory", data.selectIssueCategory)
                    formData.append("issueDescription", data.issueDescription)
                    for (let index = 0; index < issueAttachments.length; index++) {
                      if (issueAttachments[index] === null && data?.[`issueAttachment:${index}`]?.name) {
                        formData.append(`issueDoc${index}`, data[`issueAttachment:${index}`])
                      }
                    }
                    call('POST', 'raiseSupportTicketRequest', formData).then(res => {
                      toastDisplay("Support ticket raised, wait for 24 hours to get revert", "success")
                      setSupportTicketPopup({ show: false })
                      setshowLoader(false)
                      setRefresh(refresh + 1)
                    }).catch(err => {
                      toastDisplay("Something went wrong", "error")
                      setshowLoader(false)
                    })
                  }} id="nextid">Submit</button>
              </div>
            </div>
          </div>
        </FinanceInvoiceModal>
      )}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDiv">
          <ChatBoxPopUp
            chatList={chatList}
            // user_avatar={user_avatar}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            onChatSend={(file) => sendChatMsg(file)}
            message={message}
            setTextMsg={setTextMsg}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="trackSupportTickets" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Track Support Tickets"}
              userTokenDetails={userTokenDetails} />
            <div>
              <div className='filter-div position-relative'>
                <Filter isAdditionalButton
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} >
                  <div className="d-flex gap-4">
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => { setSupportTicketPopup({ show: true }) }}>
                      Raise a Support Ticket
                    </button>
                  </div>
                </Filter>
              </div>
              <div>
                <NewTablev2 filterData={filterData} disableAction={false}
                  columns={userTypeId / 1 == 1 ?
                    [
                      { subColumns: 'Date', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Ticket Id', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Company Name', subColumnStyle: { width: '14%' } },
                      { subColumns: 'Issue Category', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Last Message', subColumnStyle: { width: '23%' } },
                      { subColumns: 'Ticket Status', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Trello Status', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '8%' } }] :
                    [
                      { subColumns: 'Date', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Ticket Id', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Issue Category', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Last Message', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Ticket Status', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}
                  data={tickets}
                >
                  {tickets.map((item, index) => {
                    return <tr>
                      <td><label className="font-size-13 font-wt-500 text-break">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</label></td>
                      <td><label className="font-size-13 font-wt-500 text-break">{item.identifier}</label></td>
                      {userTypeId / 1 == 1 ?
                        <td><label className="font-size-13 font-wt-500 text-break">{item.supportTicketForCompanyName}</label></td> : null}
                      <td><label className="font-size-13 font-wt-500 text-break">{item.category}</label></td>
                      <td>
                        <div
                          onClick={async () => {
                            let reqObj = {
                              userTypeId,
                              senderId: userId,
                              receiverId: `"${item.chatRoomUsers?.split("::")?.filter(i => { if (i / 1 != userId / 1) { return true } })?.[0]}"`,
                              textMessage: 'Hii',
                              chat_room_name: `${item.category} Issue - Support Ticket #${item.identifier}`,
                              receiverParties: `"${item.chatRoomUsers?.split("::")?.filter(i => { if (i / 1 != userId / 1) { return true } })?.[0]}"`,
                              dontSendInitialMsg: true
                            }
                            setshowLoader(true)
                            let apiResp = await call('POST', 'sendChatMessage', reqObj)
                            setshowLoader(false)
                            getChatDetails({
                              chat_room_id: apiResp.id,
                              loggedInUser: userId
                            })
                            setSelectedChat({
                              chatRoomId: apiResp.id,
                              receiverName: `${item.chatRoomIds || apiResp.id}`,
                              invApplicationId: item.limit_id,
                              parties: item.chatRoomUsers || apiResp.parties,
                              userId: userId,
                              isChatOpen: true,
                              receiverId: `"${item.chatRoomUsers?.split("::")?.filter(i => { if (i / 1 != userId / 1) { return true } })?.[0]}"`,
                              senderId: item.createdBy,
                              chatRoomName: `${item.category} Issue - Support Ticket #${item.identifier}`
                            })
                          }}
                        >
                          <div className='d-flex' >
                            <p className={`font-size-12 font-wt-500 p-0 m-0 ${item.chatRoomUnreadMsgCount > 0 ? ' w-80' : ' w-100 '}`} >
                              {item.lastMsgTime ?
                                <label className={`font-wt-700 w-100 p-0 m-0 `} >{moment(item.lastMsgTime).format("DD/MM/YYYY | HH:mm")}</label> : null}
                              <p className='ellipsis-container' >
                                <p className={`mb-0 ellipsis-text `} style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastMsg || "No message yet" }}></p>
                              </p>
                            </p>
                            {item.chatRoomUnreadMsgCount > 0 ?
                              <label
                                className={`lastMsgCount `}
                              >{item.chatRoomUnreadMsgCount}</label> : null}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className=''>
                          {item.status / 1 == 2 ?
                            <button type="button"
                              class={`approved text-white border-0 `}>
                              {"Closed"}
                            </button> :
                            <button type="button"
                              class={`inprogress text-white border-0 `}>
                              {"Inprogress"}
                            </button>}
                        </div>
                      </td>
                      {userTypeId / 1 == 1 ?
                        <td>
                          <div
                            onClick={() => {
                              if (item.trelloStatus) {
                                window.open(item.trelloInformation?.shortUrl, "_blank")
                              }
                            }}
                            className='cursor'>
                            {item.trelloStatus === "Resolved" ?
                              <button type="button"
                                class={`approved text-white border-0 `}>
                                {"Resolved"}
                              </button> :
                              <button type="button"
                                class={`inprogress text-white border-0 `}>
                                {item.trelloStatus || "NA"}
                              </button>}
                          </div>
                        </td> : null}
                      <td
                        className='position-relative'>
                        <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                          onClick={() => setAction({ show: true, index: index })}
                          aria-hidden="true"></i>
                        {action.show && action.index === index ? (
                          <Action
                            id={index}
                            onDismiss={() => setAction({ show: false, index: index })}
                            options={[
                              {
                                name: "Mark as closed", onClick: async () => {
                                  setshowLoader(true)
                                  await call('POST', 'updateSupportTicketRequest', { issueId: item.identifier, issueStatus: 2 })
                                  setshowLoader(false)
                                  toastDisplay("Status updated successfully", "success")
                                  setRefresh(refresh + 1)
                                }
                              }
                            ]} />
                        ) : null}
                      </td>
                    </tr>
                  })}
                </NewTablev2>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(TrackSupportTicket)