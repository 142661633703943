import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, decryptData, GetCache, getDocDetails, handleFileWithAsyncAwait, handleMultipleFile, mergeMultiplePdfs, most_used_currencies, multiSelectEventHandler, replaceFileBase64WithFileObj, SetCache, splitPdfIntoSinglePages, toolTip, toolTip2 } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';


import { getSocket } from '../../socket'
import NewTablev2 from '../../utils/newTablev2';
import { dateFormatter_DatePicker, formatDate_Application } from '../../utils/dateFormaters';
import { getStatusDisplayName } from '../dataTablesColumsMap/contractListColoums';
import { Action } from '../myCounterPartComp/action';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { DocumentViewer } from '../../utils/documentViewer';
import { DocumentDownloader } from '../../utils/DocumentDownloader';
import { getTraderType, getUserDataFromCookie, setTraderType } from '../../utils/cookieHelper';
import { setContractState, setaddBankObj, setClientType } from '../../store/actions/action';
import ReactCountryFlag from 'react-country-flag';
import MultipleSelectForTable from '../../utils/MultipleSelectForTable';
import validateContractPilot from '../../utils/validation_files/editContractValidationRules';
import validate, { gptContractOCRValidation, mapGptRespData } from '../../utils/validation_files/createContractValidationRules';
import price_in_words from '../../utils/wordtoNumber';
import SpecificationsPreview from '../specifications/specificationPreview';
import SpecificationsForm from '../specifications/specificationForm'
import AddBankComponent from '../utilComponents/addBankComponent';
import TncForm from '../tncComponents/tncForms';
import DocumentACL from '../tradeContract/documentACL';
import commImageUrl from '../../utils/makeCommImageUrl';
import { useHistory } from "react-router";
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import { bgGetConfirmationDocs } from '../Dashboard/TableJson';
import SignDocument from '../InvoiceDiscounting/components/SignDocument';
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication';
import BankReimburseForm2 from '../bank/ttPaymentProcess/bankReimburseForm2';
import { aiServerBackendUrl, platformURL } from '../../urlConstants';
import axios from 'axios';

export const contractTemplateTypes = [
  { name: 'Compact Contract' },
  { name: 'Bulk Contract' },
  { name: 'Container Contract' }
]

let incoTermsData = [{ name: 'EXW' }, { name: 'FCA' }, { name: 'FAS' }
  , { name: 'FOB' }, { name: 'CFR' }, { name: 'CIF' }, { name: 'CPT' }, { name: 'CIP' }
  , { name: 'DAP' }, { name: 'DPU' }, { name: 'DDP' }]

let dealStages = [{ name: "Deal Created" }, { name: 'Deal In Pilot' }, { name: 'Deal Locked' }, { name: 'Deal In-Progress' },
{ name: 'Shipment Scheduled' }, { name: 'Shipment Completed' }, { name: 'Payment Scheduled' }, { name: 'Payment Settled' }]

const CreateWorkOrderContract = ({ navToggleState, setContractState, setaddBankObj, addBankObj, clientType, dispatch,
  setClientType }) => {
  let userTokenDetails = getUserDataFromCookie()

  const queryParams = new URLSearchParams(window.location.search)
  let routeParams = queryParams.get('routeParams')
  let counterParty = queryParams.get('counterParty')
  let formContinue = queryParams.get("formContinue")
  if (counterParty) {
    counterParty = decodeURIComponent(counterParty)
    counterParty = decryptData(counterParty)
    counterParty = JSON.parse(counterParty)
  }
  if (routeParams) {
    routeParams = decodeURIComponent(routeParams)
    routeParams = JSON.parse(routeParams)
  }

  let contractState = {
    modal: true,
    info: {
      modalMode: queryParams.get("type") || "create",
      contractNo: queryParams.get("contractNo")
    }
  }
  //---------------------------------------------------------------------------------------------------------------------

  const aclMap = userTokenDetails?.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails?.type_id ? userTokenDetails.type_id : (counterParty?.userTypeId || null)
  const userEmail = userTokenDetails?.email ? userTokenDetails.email : (counterParty?.userEmail || null)
  const userName = userTokenDetails?.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails?.user_id ? userTokenDetails.user_id : (counterParty?.userId || null)
  const [traderActiveType, setTraderActiveType] = useState(getTraderType())

  console.log("contractStateeeeeeeeeeeeee", contractState, counterParty, userId);

  const astrix = <span className="required-field text-danger">*</span>


  // States and variables
  const [shouldSaveCache, toggleShouldSaveCache] = useState((formContinue || contractState.info.modalMode != "create") ? false : true)
  const [cacheId, setCacheId] = useState(formContinue || null)
  const [data, setData] = useState({
    'contractType': 'FOB',
    "paymentMode": 1,
    'currency': 'USD',
    quantity0: 1
  });
  const [supplierInfo, setSupplierInfo] = useState({})
  const [dbSupplier, setDbSupplier] = useState([])
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [contract, setContract] = useState({
    state: 0,
    col: 'FOB'
  });
  const [viewAccordion, setViewAccordion] = useState(0);
  const [commodity, setCommodity] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [changelogs, setchangelogs] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({ state: false, name: '', location: '' });
  const [spec, setSpecification] = useState([]);
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agency, setAgency] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portLoadingFiltered, setPortLoadingFiltered] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [portUnloadingFiltered, setPortUnloadingFiltered] = useState([]);
  const [buyerBank, setBuyerBank] = useState([]);
  const [sellerBank, setSellerBank] = useState([]);
  const [refresh, setrefresh] = useState(0)
  const [dataUpdate, setDataUpdate] = useState({});
  const [dataTnc, setdataTnc] = useState({});
  const [tabing, settabing] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [paymentModes, setpaymentModes] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierAvatar, setsupplierAvatar] = useState('');
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [countryData, setcountryData] = useState([]);
  const [contractEditMode, setcontractEditMode] = useState(false);
  const [refreshSupplier, setrefreshSupplier] = useState(0)
  const [supplierSearch, setsupplierSearch] = useState('')
  const [editCount, setEditCount] = useState(0);
  // const [extraTnc, setextraTnc] = useState([]);
  const [docCategoryACL, setdocCategoryACL] = useState({});
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [specChange, setspecChange] = useState(0)
  const [tncChange, settncChange] = useState(0)
  const [specOrder, setspecOrder] = useState([])
  const [tncOrder, settncOrder] = useState([])

  const [searchUserForListing, setSearchUserForListing] = useState("")
  const [trader, setTrader] = useState(traderActiveType);
  const [contractCopy, setContractCopy] = useState("Yes");
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({ show: routeParams?.contractTemplate ? false : true, data: { type: routeParams?.contractTemplate || 'Bulk Contract' } })
  let isCompactContract = templateSelectionForm.data.type === "Compact Contract"
  const [stageInfo, setStageInfo] = useState({ 'Deal Created': {} })
  const [templateName, setTemplatename] = useState("")
  const [allTemplates, setAllTemplates] = useState()
  const [tabsArr, setTabsArr] = useState(userTypeId / 1 == 19 ? [
    { name: "Contract Info" },
    { name: "Specification" },
    { name: "Quantity & Price" },
    { name: "Payment Details" },
    { name: "T&C" },
    // { name: "Document ACL" },
    { name: "Review" }
  ] : [
    { name: "Contract Info" },
    { name: "Specification" },
    { name: "Quantity & Price" },
    { name: "Payment Details" },
    { name: "T&C" },
    { name: "Review" }
  ])
  const [traderOnboardForm, toggleTraderOnboardForm] = useState({ show: false, data: {} })
  const [userData, setUserData] = useState({});
  const [autoSelectUserByEmail, setAutoSelectUserByEmail] = useState(null)
  const [multComm, setMultComm] = useState([null])
  const [mstCommList, setMstCommList] = useState([])
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [signdoc, setSigndoc] = useState(false);
  const [reimburseData, setReimburseData] = useState({})
  let [contractNumber, setContractNumber] = useState(`CT-${new Date().getTime()}`)
  const [txns, setTxns] = useState([null])
  const [contractsToReplicate, setContractsToReplicate] = useState([])
  const [isUserAccountActive, toggleIsUserAccountActive] = useState(false)

  let jj = -1
  let history = useHistory()
  let compactContractReview = [
    { "name": "Your Role", val: traderActiveType },
    { "name": "Counter Party Name", val: data.supplierName || data.buyerName },
    { "name": "Amount", val: data.currency + " " + data.price0 },
    { "name": "Stages Completed", val: Object.keys(stageInfo) }]

  useEffect(() => {
    if (cacheId) {
      getCache()
    }
  }, [])

  async function getCache() {
    let cacheDetails = await call('POST', 'getInvoiceLimitCacheById', { cacheId, forContract: true })
    if (cacheDetails?.details) {
      setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
      setActiveClass(cacheDetails.details.activeClass)
      setSpecification(cacheDetails.details.spec)
      setspecOrder(cacheDetails.details.specOrder)
      settncOrder(cacheDetails.details.tncOrder)
      toggleShouldSaveCache(true)
      toggleTemplateSelectionForm(cacheDetails.details.templateSelectionForm)
      setdataTnc(cacheDetails.details.dataTnc)
      setPortLoading(cacheDetails.details.portLoading)
      setPortLoadingFiltered(cacheDetails.details.portLoadingFiltered)
      setPortUnloading(cacheDetails.details.portUnloading)
      setPortUnloadingFiltered(cacheDetails.details.portUnloadingFiltered)
      setsupplierCommodityList(cacheDetails.details.supplierCommodityList)
      setSelectedSupplier(cacheDetails.details.selectedSupplier);
      setSupplierInfo(cacheDetails.details.supplierInfo)
      setsupplierAvatar(cacheDetails.details.supplierAvatar)
    }
  }

  useEffect(() => {
    if (shouldSaveCache) {
      saveCache()
    }
  }, [data, activeClass, spec, specOrder, tncOrder, templateSelectionForm, dataTnc, portLoading, portLoadingFiltered,
    portUnloading, portUnloadingFiltered, supplierCommodityList, selectedSupplier, supplierInfo, supplierAvatar])

  async function saveCache() {
    let reqBody = { userId, forContract: true }
    reqBody["details"] = {
      data, activeClass, spec, specOrder, tncOrder, templateSelectionForm, dataTnc,
      portLoading, portLoadingFiltered, portUnloading, portUnloadingFiltered, supplierCommodityList,
      selectedSupplier, supplierInfo, supplierAvatar, percentageComplete: (activeClass) * 25
    }
    if (cacheId) {
      reqBody["cacheId"] = cacheId
    }
    let tempCacheId = await call('POST', 'saveInvoiceLimitCache', reqBody)
    if (tempCacheId) {
      setCacheId(tempCacheId)
    }
  }

  useEffect(() => {
    checkIfUserAccountActive()
  }, [counterParty])

  async function checkIfUserAccountActive() {
    call('POST', 'isUserAccountActive', { userId }).then(res => {
      toggleIsUserAccountActive(true)
    }).catch(err => {
    })
  }

  useEffect(() => {
    if (routeParams && dbSupplier?.length) {
      let selectedCounterParty = dbSupplier.filter(i => {
        if (i.user_name === routeParams?.buyerEmail) { return true }
      })?.[0]
      let tempSpecification = spec
      tempSpecification[0] = routeParams.specifications ? routeParams.specifications : {}
      setSpecification([...tempSpecification]);
      let tempSpecOrder = specOrder
      tempSpecOrder[0] = routeParams.spec_order_string ? routeParams.spec_order_string.split(":") : ""
      setspecOrder([...tempSpecOrder])
      let dataToSet = {
        currency: routeParams.currency, commCatId0: routeParams.commodityCategoryId,
        commodity0: routeParams.commodityId, commodity_pretty_name0: routeParams.commodity_pretty_name0,
        price0: routeParams.price / 1, quantity0: routeParams.quantity / 1, priceType0: 1
      }
      if (selectedCounterParty) {
        handleSelectedSupplier(selectedCounterParty, dataToSet)
      }
      else {
        setData({
          ...data, ...dataToSet
        })
      }
    }
  }, [routeParams?.contractTemplate, dbSupplier])

  useEffect(() => {
    if (isCompactContract) {
      setTabsArr([
        { name: "Deal Info" },
        { name: "Review" }])
    }
  }, [isCompactContract])

  useEffect(() => {
    if (isUserAccountActive) {
      call('POST', 'getContractsToReplicate', { userId }).then(res => {
        setContractsToReplicate(res)
      })
      call('POST', 'getuserprofiledata', { 'email': userEmail, 'min': true }).then((result) => {
        console.log("result in getuserprofiledata-->", result)
        setUserData(result)
      }).catch((e) => {
        console.log('error in getuserprofiledata', e);
      })
      call('POST', 'getCommodityList', { "specifications": true, userId }).then((result) => {
        // console.log("result in getCommodityList-->", result)
        setMstCommList(result.message || [])
        setAllTemplates(result.message || []);


      }).catch((e) => {
        console.log('error in getCommodityList', e);
      })
      call('post', 'getusercommoditycategory', { userId: userId }).then((result) => {
        console.log("API result getusercommoditycategory in comm modal:-->", result);
        setCommCategory(result);
      }).catch((e) => {
        console.log("Error while querying getusercommoditycategory:", e);
      })
      getSupplierCommodities(null, userId)
    }
  }, [refresh, isUserAccountActive]);
  useEffect(() => {
    console.log("all templtes:..............", allTemplates)

  }, [mstCommList, allTemplates])


  useEffect(() => {
    // if (!queryParams.get("contractNo")) {
    //   if (!(aclMap.contract_access && aclMap.contract_access.cont_create && aclMap.contract_access.cont_create.isExist)) {
    //     toastDisplay("You don't have permission to create contract", "info", () => {
    //       history.go(-1)
    //     })
    //   }
    //   let planData = userPlanQuotaFetch()
    //   if (!planData?.trades?.limitLeft) {
    //     toastDisplay("You have already used 100% of commodity contracts quota", "info", () => {
    //       history.go(-1)
    //     })
    //   }
    // }
  }, [])



  //Submit Handlers
  const handleSubmit = () => {
    console.log("dataaaaaaaBeforeSubmittttttttttttttttttt", data);
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key != "loadingCountry" && key != "unloadingCountry" && key != "currency") {
        formData.append(key, data[key]);
      }
    })

    let selectedLoadingCountry = countryData.filter(i => {
      if (i.id / 1 == data.loadingCountry / 1) {
        return true
      }
    })?.[0] || {}
    let selectedUnloadingCountry = countryData.filter(i => {
      if (i.id / 1 == data.unloadingCountry / 1) {
        return true
      }
    })?.[0] || {}
    let selectedCurrency = currencyData.filter(i => {
      if (i.code === data.currency) {
        return i
      }
    })?.[0] || {}

    if (contractCopy) {
      formData.append("contractCopy", contractCopy)
    }

    formData.append('totalPrice', getTotalPriceOfContract())

    // formData.append("commodity", data.commodity_pretty_name + ":" + data.commodity)
    formData.append("loadingCountry", selectedLoadingCountry.sortname + ":" + data.loadingCountry)
    formData.append("unloadingCountry", selectedUnloadingCountry.sortname + ":" + data.unloadingCountry)
    formData.append("currency", selectedCurrency.name + ":" + selectedCurrency.symbol + ":" + selectedCurrency.code)

    formData.append("specification", JSON.stringify(spec));
    formData.append("contractTnc", JSON.stringify(dataTnc));
    formData.append("docCategoryACL", JSON.stringify(docCategoryACL));
    formData.append("orderJson", JSON.stringify({
      specOrder: specOrder && specOrder.length ? specOrder.join(":") : "",
      tncOrder: tncOrder.join(":")
    }));
    formData.append("multComm", JSON.stringify(multComm))
    formData.append("specOrder", JSON.stringify(specOrder))
    formData.append("contractTemplate", templateSelectionForm.data.type)
    formData.append('userName', userName)
    let tempData = data
    if (tempData?.selectedReplicateContract) {
      tempData["selectedReplicateContract"] = undefined
    }
    formData.append('jsonData', JSON.stringify({ data: tempData, specOrder, spec, tncOrder, dataTnc }))

    if (isCompactContract) {
      formData.append("contractNumber", contractNumber)
    }


    call('POST', 'initContract', formData).then(async (result) => {
      if (result) {
        setshowLoader(false)
        if (isCompactContract) {
          setData({ ...data, savedContractDetails: result.message })
        }
        else {
          if (cacheId) {
            await call("POST", 'clearInvoiceLimitCacheById', { cacheId, forContract: true })
          }
          toastDisplay("Contract created", "success", () => {
            window.location = '/contractManagementList'
          })
        }
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      // setContractState()
    })
  }

  // console.log("dataaaaaaaaaaaaaaaaaaaaa", data);

  const agreement = () => {
    if (!isUserAccountActive) {
      return toastDisplay("You are not allowed to make changes in the contract", "info")
    }
    else {
      setshowLoader(true)
    }

    let formData = new FormData();

    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("userTypeClient", traderActiveType ? traderActiveType : '');
    formData.append("contract_id", data.contract_id);
    formData.append("contract_number", data.contract_number);

    call('POST', 'agreeContract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        // setContractState(false, { refresh: true })
        toastDisplay("Contract agreed successfully", "success", () => {
          window.location = '/contractManagementList'
        })
      }
    }).catch(e => {
      console.log("Error while arreeing contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  const handleSubmitUpdate = (operator) => {

    if (!isUserAccountActive) {
      return toastDisplay("You are not allowed to make changes in the contract", "info")
    }
    else {
      setshowLoader(true)
    }

    var formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key != "loadingCountry" && key != "unloadingCountry" && key != "currency") {
        formData.append(key, data[key]);
      }
    })

    let selectedLoadingCountry = null
    let selectedUnloadingCountry = null
    let selectedCurrency = null

    if (data.loadingCountry) {
      selectedLoadingCountry = countryData.filter(i => {
        if (i.id / 1 == data.loadingCountry / 1) {
          return true
        }
      })?.[0] || {}
      formData.append("loadingCountry", selectedLoadingCountry.sortname + ":" + data.loadingCountry)
    }
    if (data.unloadingCountry) {
      selectedUnloadingCountry = countryData.filter(i => {
        if (i.id / 1 == data.unloadingCountry / 1) {
          return true
        }
      })?.[0] || {}
      formData.append("unloadingCountry", selectedUnloadingCountry.sortname + ":" + data.unloadingCountry)
    }
    if (data.currency) {
      selectedCurrency = currencyData.filter(i => {
        if (i.code === data.currency) {
          return i
        }
      })?.[0] || {}
      formData.append("currency", selectedCurrency.name + ":" + selectedCurrency.symbol + ":" + selectedCurrency.code)
    }

    let showToStringArrray = data.exporter_id + "," +
      data.importer_id + "," +
      data.investigationAgencyImporter / 1 + "," +
      data.investigationAgencyExporter / 1 + "," +
      data.investigationAgencythird / 1 + "," +
      data.commodityInsurer / 1 + "," +
      data.tradeInsurer / 1 + "," +
      data.supplierBank / 1 + "," +
      data.buyerBank / 1

    formData.append("showToStringArrray", showToStringArrray);
    // formData.append("user_type", userTypeId);
    // formData.append("loggedUserID", userId);
    // formData.append("importer_id", data.importer_id);
    // formData.append("exporter_id", data.exporter_id);
    // formData.append("userTypeClient", traderActiveType ? traderActiveType : '');
    // formData.append("contract_id", data.contract_id);
    // formData.append("contract_number", data.contract_number);
    // if (specChange / 1 > 0) {
    formData.append("specification", JSON.stringify(spec));
    formData.append("specOrder", JSON.stringify(specOrder));
    // }
    // if (tncChange / 1 > 0) {
    formData.append("contractTnc", JSON.stringify(dataTnc));
    // }

    if (specChange / 1 > 0 || tncChange / 1 > 0) {
      formData.append("orderJson", JSON.stringify({ specOrder: specOrder ? specOrder.join(":") : "", tncOrder: tncOrder.join(":") }));
    }

    formData.append("docCategoryACL", JSON.stringify(docCategoryACL));
    formData.append("specChange", specChange);
    formData.append("tncChange", tncChange);
    formData.append("multComm", JSON.stringify(multComm));
    formData.append('totalPrice', getTotalPriceOfContract())


    formData.delete("supplierAvatar")
    formData.delete("buyerAvatar")
    // console.log('Here --->>');
    call('POST', 'updateContract', formData).then((result) => {
      if (result) {
        if (operator === "U&A") {
          agreement()
        } else {
          setshowLoader(false)
          // setContractState(false, { refresh: true })
          toastDisplay("Contract updated successfully", "success", () => {
            window.location = '/contractManagementList'
          })
        }
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  useEffect(() => {

    if (contractState.info.modalMode === "create") {
      if (userEmail) {
        // call('POST', 'getUserBanks', { email: userEmail }).then((result) => {
        //   // console.log("result in bank api->", result)
        //   if (traderActiveType === 'buyer') {
        //     setBuyerBank(result)
        //   } else {
        //     setSellerBank(result)
        //   }
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log("result in bank api->", result)
          if (traderActiveType === 'buyer') {
            setBuyerBank(result)
          } else {
            setSellerBank(result)
          }
        }).catch((e) => {
          console.log("Error while querying getUserBanks:", e);
        })
      }

      if (supplierInfo.user_name) {
        // call('POST', 'getUserBanks', { email: supplierInfo.user_name }).then((result) => {
        //   // console.log("result in bank api->", result)
        //   if (traderActiveType === 'buyer') {
        //     setSellerBank(result)
        //   } else {
        //     setBuyerBank(result)
        //   }
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log("result in bank api->", result)
          if (traderActiveType === 'buyer') {
            setSellerBank(result)
          } else {
            setBuyerBank(result)
          }
        }).catch((e) => {
          console.log("Error while querying getUserBanks:", e);
        })
      }
    } else {
      if (data.supplierEmail) {
        // call('POST', 'getUserBanks', { "email": data.supplierEmail }).then((result) => {
        //   // console.log('api result for getUserBanks supplierEmail', result);
        //   setSellerBank(result)
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks for seller:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log('api result for getUserBanks supplierEmail', result);
          setSellerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks for seller:", e);
        })
      }

      if (data.buyerEmail) {
        // call('POST', 'getUserBanks', { "email": data.buyerEmail }).then((result) => {
        //   //  console.log('api result for getUserBanks buyerEmail', result);
        //   setBuyerBank(result)
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks for buyer:", e);
        // })

        call('POST', 'getBanksListMaster', {}).then((result) => {
          //  console.log('api result for getUserBanks buyerEmail', result);
          setBuyerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks for buyer:", e);
        })
      }
    }
  }, [addBankObj.info.refresh, supplierInfo, refreshSupplier]);

  useEffect(() => {
    if (autoSelectUserByEmail && dbSupplier.length) {
      let foundUser = null
      for (let index = 0; index < dbSupplier.length; index++) {
        const element = dbSupplier[index];
        if (element.user_name === autoSelectUserByEmail && !element.buyerId) {
          foundUser = element
          break
        }
      }
      if (foundUser) {
        handleSelectedSupplier(foundUser)
        setAutoSelectUserByEmail(null)
      }
    }
  }, [autoSelectUserByEmail, dbSupplier])

  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls

    if (contractState.info.modalMode === "create" && !formContinue) {
      if (traderActiveType === 'buyer') {
        setData({
          ...data,
          'user_type': userTypeId,
          'buyerEmail': userEmail,
          'importer_id': userId,
          'buyerName': userName,
          'supplierEmail': "",
          'exporter_id': "",
          'supplierName': "",
        })
      } else {
        setData({
          ...data,
          'user_type': userTypeId,
          'supplierEmail': userEmail,
          'exporter_id': userId,
          'supplierName': userName,
          'buyerEmail': "",
          'importer_id': "",
          'buyerName': "",
        })
      }

      // call('POST', 'getjsonfromdb', { "jsonFor": "fobTNCcoal" }).then((result) => {
      //   console.log("getjsonfromdb:=>", result);
      //   setdataTnc(result.json_data)
      //   settncOrder(result.order_array.split(":"))
      // }).catch((e) => {
      //   console.log("Error while querying getjsonfromdb:", e);
      // })

      call('POST', 'getAgencys', { traderId: (userTypeId === 19 ? userId : null) }).then((result) => {
        setAgency(result)
      }).catch((e) => {
        console.log("Error while querying getAgencys:", e);
      })

      call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 19 ? userId : null) }).then(async (result) => {
        setInsurers(result)
      }).catch((error) => {
        console.log("error occur in getinsuranceagencylist ->", error)
      })

      call('get', 'v1/plan/quota').then((result) => {
        if (result && result.trades && result.trades.enable && ((result.trades.limitLeft / 1) > 0 || (result.trades.limitLeft / 1) === -1))
          setQuotaAvail(true)
      }).catch((e) => {
        console.log("Error while  getUserCurrentPlanQuota header:-->", e);
      })

    } else {
      setshowLoader(true)
      setQuotaAvail(true)

      call('POST', 'getContracts', { "contract_number": contractState.info.contractNo, "contractOnly": true, "type_id": userTypeId }).then((result) => {
        console.log("api result get contract data--->", result)
        let tempTraderActiveType = result.importer_id / 1 == userId / 1 ? "buyer" : 'seller'
        setTraderActiveType(tempTraderActiveType)
        let commSpec = result.commoditySpecification?.split("|:|:")
        for (let index = 0; index < commSpec.length; index++) {
          let element = commSpec[index];
          commSpec[index] = JSON.parse(element)
        }
        setSpecification(commSpec)
        let commSpecOrder = result.commoditySpecOrder?.split("|:|:")
        for (let index = 0; index < commSpecOrder.length; index++) {
          let element = commSpecOrder[index];
          commSpecOrder[index] = JSON.parse(element)
        }
        setspecOrder(commSpecOrder)
        let addOnInData = {}
        let tempMultComm = []
        for (let index = 0; index < result.commodity_id?.toString()?.split(",").length; index++) {
          const element = result.commodity_id?.toString()?.split(",")[index];
          tempMultComm.push(null)
          addOnInData[`commodity_id${index}`] = element
          addOnInData[`commFilename${index}`] = result.comm_filename?.toString()?.split(",")[index]
          addOnInData[`quantity${index}`] = result.quantity?.toString()?.split(",")[index] / 1
          addOnInData[`commodity_unit${index}`] = result.commodityUnit?.toString()?.split(",")[index]
          addOnInData[`price${index}`] = result.commodityUnitPrice?.toString()?.split(",")[index] / 1
          addOnInData[`priceType${index}`] = result.price_type?.toString()?.split(",")[index]
          addOnInData[`commodity_name${index}`] = result.commodity_name?.toString()?.split(",")[index]
        }
        setMultComm([...tempMultComm])
        console.log("addOnInDataaaaaaaaaaaaa", addOnInData, commSpec, commSpecOrder);
        toggleTemplateSelectionForm({ show: false, data: { type: result.contractTemplate } })
        setData({
          ...addOnInData,
          "user_type": userTypeId,
          "loggedUserID": userId,
          "userTypeClient": tempTraderActiveType ? tempTraderActiveType : '',
          contract_id: result.id ? result.id : '',
          contract_type: result.contract_type ? result.contract_type : '',
          contractType: result.contract_type ? result.contract_type : '',
          contract_number: result.contract_number ? result.contract_number : '',
          contractAlias: result.contract_name ? result.contract_name : '',
          // commodity_id: result.commodity_id ? result.commodity_id : '',
          // commFilename: result.comm_filename ? result.comm_filename : '',
          // quantity: result.quantity ? result.quantity : '',
          currency: result.currency ? result.currency.split(":")[2] : '',
          laycanStart: result.laycan_start ? dateFormatter_DatePicker(result.laycan_start) : '',
          laycanEnd: result.laycan_end ? dateFormatter_DatePicker(result.laycan_end) : '',
          supplierBank: result.supplier_bank,
          buyerBank: result.buyer_bank,
          // price: result.price ? result.price : '',
          // priceType: result.price_type ? result.price_type : '',
          loadingCountry: result.load_country ? result.load_country.split(":")[1] / 1 : '',
          unloadingCountry: result.unload_country ? result.unload_country.split(":")[1] / 1 : '',
          loadingPort: result.loading_port ? result.loading_port : '',
          unloadingPort: result.unloading_port ? result.unloading_port : '',
          supplierName: result.suppliername ? result.suppliername : '',
          buyerName: result.buyername ? result.buyername : '',
          supplierEmail: result.supplier_email ? result.supplier_email : '',
          buyerEmail: result.buyer_email ? result.buyer_email : '',
          // commodity_name: result.commodity_name ? result.commodity_name : '',
          exporter_id: result.exporter_id ? result.exporter_id : '',
          importer_id: result.importer_id ? result.importer_id : '',
          investigationAgencyImporter: result.inv_agency_importer,
          investigationAgencyExporter: result.inv_agency_exporter,
          investigationAgencythird: result.inv_agency_third,
          commodityInsurer: result.commodity_insurer,
          tradeInsurer: result.trade_insurer,
          contract_type_id: result.contract_type_id ? result.contract_type_id : '',
          reference_contract: result.reference_contract ? result.reference_contract : 0,
          investigation_status: result.investigation_status ? result.investigation_status : '',
          payment_status: result.payment_status,
          created_by: result.created_by ? result.created_by : '',
          quotation_status: result.quotation_status ? result.quotation_status : '',
          shipment_status: result.shipment_status ? result.shipment_status : '',
          paymentMode: result.payment_mode ? result.payment_mode : '',
          rejection_level: result.rejection_level ? result.rejection_level : '',
          status_buyer: result.status_buyer,
          status_seller: result.status_seller,
          commenter: userId,
          supplierAvatar: result.supplieravatar ? result.supplieravatar : '',
          buyerAvatar: result.buyeravatar ? result.buyeravatar : '',
          edit_request: result.edit_request
        })

        // fillTNCValue(result.tnc ? JSON.parse(result.tnc) : {}, result.contract_type)
        // setCommodity(result.commodity_id)
        setdataTnc(result.tnc)

        if (((userTypeId === 19 || userTypeId === 3) && result.status_buyer === 0) || ((userTypeId === 4) && result.status_seller === 0)) {
          setcontractEditMode(true)
        }

        // call('POST', 'getAgencys', { traderId: (userTypeId === 19 ? userId : result.importer_id) }).then((result) => {
        //   setAgency(result)
        // }).catch((e) => {
        //   console.log("Error while querying getAgencys:", e);
        // })

        // call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 19 ? userId : result.importer_id) }).then((result) => {
        //   setInsurers(result)
        // }).catch((error) => {
        //   console.log("error occur in getinsuranceagencylist ->", error)
        // })

        call('POST', 'getCountryPort', { 'countryId': result.load_country.split(':')[1] }).then((resultt) => {
          // console.log('setPortLoading in getCountryPort-->', result);
          let tempPortLoadingFiltered = resultt?.length ? resultt : []
          let isAvailableInMst = tempPortLoadingFiltered.find(i => { if (i.port_name === result?.loading_port) { return true } })
          if (!isAvailableInMst) {
            tempPortLoadingFiltered.push({ port_name: result?.loading_port })
          }
          setPortLoading([...tempPortLoadingFiltered])
          setPortLoadingFiltered([...tempPortLoadingFiltered])
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })
        call('POST', 'getCountryPort', { 'countryId': result.unload_country.split(':')[1] }).then((resultt) => {
          //console.log('setPortUnloading in getCountryPort-->', result);
          let tempPortUnloadingFiltered = resultt
          let isAvailableInMst = tempPortUnloadingFiltered.find(i => { if (i.port_name === result?.unloading_port) { return true } })
          if (!isAvailableInMst) {
            tempPortUnloadingFiltered.push({ port_name: result?.unloading_port })
          }
          setPortUnloading([...tempPortUnloadingFiltered])
          setPortUnloadingFiltered([...tempPortUnloadingFiltered])
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })

        // setSpecification(result.specification)
        // setspecOrder(result.json_order.specOrder ? result.json_order.specOrder.split(":") : "")
        settncOrder(result.json_order.tncOrder.split(":"))
        loadbanks(result.buyer_email, result.supplier_email)
        setshowLoader(false)

      }).catch((e) => {
        setshowLoader(false)
        console.log("error in view contract useeffect-->", e)
      })

    }

    // call('get', 'getcurrencylist').then((result) => {
    //   setcurrencyData(result)
    // }).catch((e) => {
    //   console.log('error in getcurrencylist', e);
    // })

    call('get', 'getpaymentmodes').then((result) => {
      console.log("getpaymentmodes api result->", result)
      setpaymentModes(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSupplier]);

  var loadbanks = (buyerEmail, sellerEmail) => {
    // call('POST', 'getUserBanks', { email: sellerEmail }).then((result) => {
    //   // console.log('api result for getUserBanks supplierEmail load', result);
    //   setSellerBank(result)
    // }).catch((e) => {
    //   console.log("Error while querying getUserBanks for seller:", e);
    // })

    // call('POST', 'getUserBanks', { email: buyerEmail }).then((result) => {
    //   //console.log('api result for getUserBanks supplierEmail load', result);
    //   setBuyerBank(result)
    // }).catch((e) => {
    //   console.log("Error while querying getUserBanks for buyer:", e);
    // })

    call('GET', 'getBanksListMaster', {}).then((result) => {
      //console.log('api result for getUserBanks supplierEmail load', result);
      setBuyerBank(result)
      setSellerBank(result)
    }).catch((e) => {
      console.log("Error while querying getUserBanks for buyer:", e);
    })
  }

  useEffect(() => {
    if (contractState.info.modalMode === "create") {
      call('POST', 'getUsersListing', { "searchUserType": 19, "searchKey": searchUserForListing, "network": "innetwork", "userAvatars": true, includeBuyersToo: true }).then((result) => {
        // console.log("result of api in getUsersListing-->", result)
        let tempResult = [{ 'html': <label className='font-size-13 font-wt-400' >Add New</label>, company_name: 'Add New', id: 'Add New' }]
        tempResult = tempResult.concat(result || [])
        if (tempResult.length) {
          for (let index = 0; index < tempResult.length; index++) {
            if (index != 0) {
              tempResult[index][`html`] = <div>
                <label className="font-size-12 font-wt-500" >{`${tempResult[index]["company_name"]}`}
                  <ReactCountryFlag
                    countryCode={tempResult[index].country_code}
                    style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
                </label>
              </div>
            }
          }
          setDbSupplier(tempResult)
        }
        else {
          setDbSupplier(tempResult)
        }
      }).catch((e) => {
        console.log('error in getUsersListing', e);
      })
    }
  }, [refreshSupplier, clientType.type, searchUserForListing]);

  useEffect(() => {
    if (Object.keys(errorData).length === 0 && isSubmitting) {
      doNext();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errorData]);

  // console.log("activeClasssssssssssssssssssssss", activeClass);

  const doNext = () => {
    if (tabing === true) {
      setActiveClass(tempactiveClass);
      setIsSubmitting(false)
      settabing(false)
    } else {
      if (activeClass >= maxactiveClass) {
        if (activeClass / 1 == 5) {
          setmaxactiveClass(7)
        }
        else {
          setmaxactiveClass(activeClass + 1)
        }
      }
      if (activeClass / 1 == 5) {
        setActiveClass(7)
      }
      else {
        setActiveClass(activeClass + 1);
      }
      setIsSubmitting(false)
    }
  }


  const handleChange = (event) => {
    event.persist();

    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, [event.target.name]: event.target.value }));
    }
    setEditCount(editCount + 1)
    if (event.target.name?.includes("commodity") && event.target.value === "addNewCommodity") {
      return window.open(`${platformURL}/manageCommodities?add=true&closeAfter=true`, "_blank")
    }
    if (event.target.name?.includes("commodity") && event.target.value) {
      let selectedCommodity = supplierCommodityList.filter(item => { if (item.id === event.target.value) { return item } })[0]
      let commRowIndx = event.target.name.split("commodity")[1]
      let tempSpecification = spec
      tempSpecification[commRowIndx] = selectedCommodity.specifications ? selectedCommodity.specifications : {}
      setSpecification([...tempSpecification]);
      let tempSpecOrder = specOrder
      tempSpecOrder[commRowIndx] = selectedCommodity.spec_order_string ? selectedCommodity.spec_order_string.split(":") : ""
      setspecOrder([...tempSpecOrder])
      let procuredQuantity = 0
      for (let index = 0; index < selectedCommodity?.procured?.length; index++) {
        let element = selectedCommodity?.procured[index];
        if (element.state === "Procured") {
          procuredQuantity += element.quantity / 1 || 0
        }
      }
      setData(data => ({
        ...data, [event.target.name]: event.target.value, [`commodity_pretty_name${commRowIndx}`]: selectedCommodity.commodity_pretty_name,
        [`availableQuantity${commRowIndx}`]: procuredQuantity - (selectedCommodity.commQuantityUtilizedInContract ? selectedCommodity.commQuantityUtilizedInContract / 1 : 0)
      }));
      seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
    }
    else if (event.target.name?.includes("selectedReplicateContract") && event.target.value) {
      let selectedContract = contractsToReplicate.filter((i) => { if (i.id / 1 == event.target.value / 1) { return true } })?.[0]?.jsonData
      setData({ ...selectedContract.data, selectedReplicateContract: event.target.value })
      settncOrder([...selectedContract.tncOrder])
      setdataTnc({ ...selectedContract.dataTnc })
      setSpecification([...selectedContract.spec])
      setspecOrder([...selectedContract.specOrder])
    }
    else {
      setData(data => ({ ...data, [event.target.name]: event.target.value }));
      seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
    }
  };

  const handleSelectedSupplier = (value, addOnData) => {
    console.log("value in supplier-->", value)
    setSelectedSupplier({
      state: true,
      supplierId: value.id ? value.id : 0,
      name: value.company_name ? value.company_name : '',
      location: value.country_code ? value.country_code : '',
      address: value.user_address ? value.user_address : '',
    });
    setSupplierInfo(value)
    setsupplierAvatar(value.user_avatar ? value.user_avatar : '')
    seterrorData(errorData => ({ ...errorData, supplierName: "" }))

    if (traderActiveType === 'buyer') {
      setData(data => ({
        ...data,
        ...addOnData,
        'supplierName': value.company_name ? value.company_name : '',
        'supplierAddress': value.country_code ? value.country_code : '',
        'supplierEmail': value.user_name ? value.user_name : '',
        "exporter_id": value.id ? value.id : 0,
        "importer_id": userId,
        "buyerName": userName
      }));

      // call('post', 'getusercommoditycategory', { userId: value.id }).then((result) => {
      //   console.log("API result getusercommoditycategory in comm modal:-->", result);
      //   setCommCategory(result);
      // }).catch((e) => {
      //   console.log("Error while querying getusercommoditycategory:", e);
      // })

    } else {
      setData(data => ({
        ...data,
        ...addOnData,
        'buyerName': value.company_name ? value.company_name : '',
        'buyerAddress': value.country_code ? value.country_code : '',
        'buyerEmail': value.user_name ? value.user_name : '',
        "importer_id": value.id ? value.id : 0,
        "exporter_id": userId,
        supplierName: userName
      }));

      // call('post', 'getusercommoditycategory', { userId: userId }).then((result) => {
      //   console.log("API result getusercommoditycategory in comm modal:-->", result);
      //   setCommCategory(result);
      // }).catch((e) => {
      //   console.log("Error while querying getusercommoditycategory:", e);
      // })

    }
  }

  function getSupplierCommodities(event, supplierId) {
    if (event) {
      event.persist();
    }
    // setData(data => ({ ...data, "commodity": "" }));
    call('post', 'getusercommoditylist', {
      "userId": supplierId,
      // "commCatId": event.target.value === "All" ? "" : event.target.value
      "commCatId": ""
    }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      let tempResult = [{ id: "addNewCommodity", commodity_pretty_name: "Add New Commodity" }]
      tempResult = tempResult.concat(result || [])
      setsupplierCommodityList(tempResult);
    }).catch((e) => {
      console.log('error in getusercommoditylist', e);
    })
  }

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort unloadPort', e);
    })
  }

  function insertVariable(tncData) {
    const replaceData = {
      '{price}': data.currency ? data.currency.split(':')[1] : '' + data.price,
      '{currency}': data.currency ? data.currency.split(':')[1] : '',
      '{priceinwords}': price_in_words(data.price),
      '{priceWords}': price_in_words(data.price),
      '{grossCalorificVal}': ((data.commodity_pretty_name && data.commodity_pretty_name === "Coal") ? spec.grosscalorificvalue.value : ''),
      '{supplierAddress}': data.supplierAddress,
      '{commodity}': (data.commodity_pretty_name ? data.commodity_pretty_name : "Commodity"),
      '{quantity}': data.quantity,
      '{loadingPort}': data.loadingPort + "," + countryData?.filter((i, j) => {
        if (i.id / 1 === data.loadingCountry / 1) {
          return i
        }
      })?.[0]?.sortname,
      '{laycan}': formatDate_Application(data.laycanStart) + " - " + formatDate_Application(data.laycanEnd)
    };

    let keyArray = Object.keys(replaceData)
    for (let i = 0; i < keyArray.length; i++) {
      tncData = replaceGlobally(tncData, keyArray[i], replaceData[keyArray[i]])
    }

    return tncData;

    function replaceGlobally(original, searchTxt, replaceTxt) {
      const regex = new RegExp(searchTxt, 'g');
      return original.replace(regex, replaceTxt);
    }
  }

  function fillTNCValue() {

    let finalTnc = { ...dataTnc }

    let keyArray = Object.keys(finalTnc).length ? Object.keys(finalTnc) : []
    for (let i = 0; i < keyArray.length; i++) {
      finalTnc[keyArray[i]].value = insertVariable(finalTnc[keyArray[i]].value)
    }
    //console.log("fillTNCValue finalTnc in contract -->", finalTnc)
    setdataTnc(finalTnc)
  }

  const handleNext = () => {
    setIsSubmitting(true)
    if (contractState.info.modalMode === "create") {
      seterrorData(validate(data, spec, dataTnc, activeClass, multComm, templateSelectionForm.data.type))
    } else {
      seterrorData(validateContractPilot(data, spec, dataTnc, activeClass, contractEditMode, multComm))
    }
    if (activeClass === 6 && (contractState.info.modalMode === "create")) {
      fillTNCValue()
    }
  }

  function getPaymentOptionslist() {
    let PaymentOptionslist = paymentModes ? paymentModes.map((value, index) => {
      return (
        <>
          <div className="w-auto row d-flex my-0 p-0 px-3 font-size-13 font-wt-600" >
            <div className='w-100' onClick={() => {
              setData({ ...data, contractType: "FOB" })
            }}>
              <input className="form-check-input" type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id}
                checked={(data.paymentMode / 1 === value.id) ? true : false}
                onChange={handleChange} />
              <label for={"paymentMode" + value.id} className="form-check-label p-0 m-0" >
                {value.payment_type_pretty}
              </label>
            </div>
          </div>

          {/* <div className="switch-field ml-1 mr-1">
            <input type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id} checked={(data.paymentMode / 1 === value.id) ? true : false} onChange={handleChange} />
            <label for={"paymentMode" + value.id}>{value.payment_type_pretty}</label>
          </div> */}
        </>
      )
    }) : ''
    return PaymentOptionslist
  }

  //---------------------------------------------------------------------------------------------------------------------
  //supplierBankList
  function getsupplierBankList() {
    let supplierBankList = sellerBank && sellerBank.length ? sellerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return supplierBankList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //buyerBankList
  function getbuyerBankList() {
    let buyerBankList = buyerBank && buyerBank.length ? buyerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return buyerBankList
  }
  //---------------------------------------------------------------------------------------------------------------------

  function getSelectName(id, value) {
    let sel = document.getElementById(id);
    if (sel) {
      for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].value / 1 === value / 1) {
          return sel.options[i].text
        }
      }
    } else {
      return '--'
    }

  }

  function getUserArray() {
    let usersArray = [
      {
        "userId": data.exporter_id,
        "userName": data.supplierName ? data.supplierName : data.supplier
      },
      {
        "userId": data.importer_id,
        "userName": data.buyerName ? data.buyerName : data.buyerEmail
      },
      // {
      //   "userId": data.investigationAgencyExporter,
      //   "userName": getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)
      // },
      // {
      //   "userId": data.investigationAgencyImporter,
      //   "userName": getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)
      // },
      // {
      //   "userId": data.investigationAgencythird,
      //   "userName": getSelectName('investigationAgencythird', data.investigationAgencythird)
      // },
      // {
      //   "userId": data.commodityInsurer,
      //   "userName": getSelectName('commodityInsurer', data.commodityInsurer)
      // },
      // {
      //   "userId": data.tradeInsurer,
      //   "userName": getSelectName('tradeInsurer', data.tradeInsurer)
      // },
      // {
      //   "userId": data.supplierBank,
      //   "userName": getSelectName('supplierBank_select', data.supplierBank)
      // },
      // {
      //   "userId": data.buyerBank,
      //   "userName": getSelectName('buyerBank_select', data.buyerBank)
      // }
    ]

    let mapArray = [], finalArray = []
    for (let i = 0; i < usersArray.length; i++) {
      // console.log("here is id usersArray[i]===>", usersArray[i])
      if (usersArray[i].userId / 1 !== 0) {
        if (!mapArray.includes(usersArray[i].userId)) {
          finalArray.push(usersArray[i])
          mapArray.push(usersArray[i].userId)
        }
      }
    }
    // console.log("here is id finalArray===>", finalArray)
    return finalArray

  }

  const handleViewAccordion = () => {
    setViewAccordion(viewAccordion ? 0 : 1);
  }

  function tncPreviewGenerator() {
    let keyArray = tncOrder
    return (
      <ol className="terms-view-ul p-0 m-0 ">
        {keyArray.map((key, index) => {
          return (
            <li className='mb-4'>
              <label className="color-white text-color1 font-size-18 font-wt-700"><u>{dataTnc[key].name ? dataTnc[key].name : "--"}</u></label>
              <p dangerouslySetInnerHTML={{ __html: dataTnc[key].value || "--" }} className='font-size-13 font-wt-500' />
            </li>
          )
        })}
      </ol>
    )
  }

  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.supplierName ? data.supplierName : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Exporter </div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.commodity_pretty_name || data.commodity_name || '-'} </div>
            <div className="price-right font-size-13 font-wt-400"> Commodity</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.contractType ? data.contractType : "-"}</div>
            <div className="price-right font-size-13 font-wt-400"> Inco Terms</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.laycanStart ? data.laycanStart : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Laycan Start</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.buyerName ? data.buyerName : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Importer</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.price ? data.price : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">{data.priceType / 1 === 1 ? "Commodity Price/mt" : data.priceType / 1 === 2 ? "Current Index Price" : "Commodity Price"}</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500">{data.quantity ? data.quantity : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Quantity</div>
          </li>
          <li>
            <div className="price-left font-size-14 font-wt-500"> {data.laycanEnd ? data.laycanEnd : '-'}</div>
            <div className="price-right font-size-13 font-wt-400">Laycan End</div>
          </li>
        </ul>
      </div>
    )
  }

  const handleSpecification = (event, commRowIndx) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec[commRowIndx] }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    let tempSpec = spec
    tempSpec[commRowIndx] = specdataElement
    setSpecification([...tempSpec]);
    seterrorData(errorData => ({ ...errorData, "Specification": "" }))
    setEditCount(editCount + 1)
    setspecChange(specChange + 1)
  }

  const handleTNC = (event) => {
    event.persist();
    let tempArray = event.target.name.split(":|:|")
    let tncdataElement = { ...dataTnc }
    // console.log("handletnccccccccccccccccc", tncdataElement, tempArray,);
    tncdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setdataTnc(tncdataElement);
    seterrorData(errorData => ({ ...errorData, "tnc": "" }))
    setEditCount(editCount + 1)
    settncChange(tncChange + 1)
  }

  console.log("dbSupplierrrrrrrrrrrrrrrrrrrrrrrrr", dbSupplier, data);

  function loadIndexPrice(event) {
    event.persist();
    if ((event.target.value / 1) === 2) {
      call('POST', 'getcommodityindexprice', { commodity: data.commodity || 0 }).then((result) => {
        // console.log("result in getcommodityindexprice api->", result)
        setData(data => ({
          ...data,
          price: result.lastPrice,
          currency: result.priceUnit
        }));
      }).catch((e) => {
        console.log("Error while querying getcommodityindexprice:", e);
        toastDisplay(e, "info")
      })
    }
  }

  function handleInc(indx) {
    document.getElementById(`quantity${indx}`).stepUp(1)
    setData({
      ...data, [`quantity${indx}`]: (data[`quantity${indx}`] || 0) + 1
    })
    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) + 1 }));
    }
    seterrorData(errorData => ({ ...errorData, [`quantity${indx}`]: "" }))
    setEditCount(editCount + 1)
  }

  function handleDec(indx) {
    // document.getElementById(`quantity${indx}`).stepDown(1)
    setData({
      ...data, [`quantity${indx}`]: (data[`quantity${indx}`] || 0) - 1
    })
    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) - 1 }));
    }
    seterrorData(errorData => ({ ...errorData, [`quantity${indx}`]: "" }))
    setEditCount(editCount + 1)
  }


  function raiseContractEditRequest() {
    let formData = new FormData();
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("contract_id", data.contract_id);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);

    call('POST', 'raisecontracteditrequest', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Edit request raised", "success", () => {
          window.location = '/contractManagementList'
        })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  function openContractEdit(edit) {
    let formData = new FormData();
    formData.append("loggedUserID", userId);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);
    formData.append("contract_id", data.contract_id);
    formData.append("editSingle", edit);

    call('POST', 'opencontractforedit', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Edit request accepted", "success", () => {
          window.location = '/contractManagementList'
        })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  function switchTrader(traderType) {
    setTraderType(traderType);
    setTrader(traderType);
    setClientType(traderType);
    setrefreshSupplier(refreshSupplier + 1)
    setTraderActiveType(traderType)
  }

  async function signUpUser(companyName, contactPerson, companyAddress, email) {
    return new Promise((resolve) => {
      let reqObject = {
        "typeId": 19,
        "cinDocumentName": null,
        "gstDocumentName": null,
        "iecDocumentName": null,
        "panDocumentName": null,
        "aadharDocumentName": null,
        "iecDocumentName": null,
        "panDocumentName": null,
        "aadharDocumentName": null,
        "organizationType": "foreign",
        companyName,
        contactPerson,
        companyAddress,
        email,
        "contactNo": "",
        "gst_company_detail": null,
        "iec_company_detail": null,
        "cin_company_detail": null,
        "type": "finTech",
        "referralCode": userData.refercode,
        "password": "Pa$$w0rd!",
        "passwordConfirm": "Pa$$w0rd!",
        "termsAndCondition": true,
        "country": "",
        "industryType": "",
        "techType": 1,
        "companyCity": "",
        "companyPostalCode": "",
        "phoneCode": "",
        "nameTitle": "",
        "companyState": "",
        "designation": "",
        "accountStatus": "inactive"
      }
      setshowLoader(true)
      call('POST', 'registration', reqObject).then((result) => {
        setshowLoader(false)
        toastDisplay("Details saved", "info")
        setrefreshSupplier(refreshSupplier + 1)
        resolve(true)
      }).catch(err => {
        setshowLoader(false)
        toastDisplay(err || "Something went wrong", "error");
        resolve(false)
      })
    })
  }

  function getTotalPriceOfContract() {
    let totalPrice = 0
    for (let index = 0; index < multComm.length; index++) {
      const element = multComm[index];
      if (element === null && data[`price${index}`] && data[`quantity${index}`]) {
        totalPrice += data[`price${index}`] * data[`quantity${index}`]
      }
    }
    return totalPrice
  }

  async function fetchDetailsFromGPT(formData) {
    return new Promise(async (resolve) => {
      axios.post(aiServerBackendUrl + '/get_json_details_from_gpt', formData, {
        "Content-Type": "multipart/form-data"
      }).then(res => {
        resolve(res)
      }).catch(err => {
        resolve(err)
      })
    })
  }

  async function contractOCRInit(pdfFileObj) {
    let splittedPdfFileObjs = await splitPdfIntoSinglePages(pdfFileObj)
    // console.log("splittedPdfFileObjssssss", splittedPdfFileObjs);
    if (splittedPdfFileObjs?.status && splittedPdfFileObjs?.msg?.length) {
      let formDataArr = []
      for (let index = 0; index < splittedPdfFileObjs?.msg.length; index++) {
        let requiredInformation = {
          "contractName": "string",
          "buyerName": "string",
          "buyerEmail": "string",
          "supplierName": "string",
          "supplierEmail": "string",
          "incoTerms": "string 3 characters",
          "commodityName": "string",
          "commodityCategory": "string",
          "commoditySpecification": [{ "specName": "string", "typicalValue": "string", "rejectionValue": "string" }],
          "laycanStartDate": 'string (YYYY-MM-DD)',
          "laycanEndDate": 'string (YYYY-MM-DD)',
          "loadingCountry": "string 2 digit country code",
          "loadingPort": 'string',
          "unloadingCountry": "string 2 digit country code",
          "unloadingPort": 'string',
          "price": "number",
          "currency": "string 3 digit currency code",
          "quantity": "number",
          "quantityUnit": "string",
          "termsAndConditions": [{ "title": "string", "description": "string" }]
        }
        let formData = new FormData()
        formData.append("doc", splittedPdfFileObjs?.msg[index])
        formData.append("defaultResp", JSON.stringify(requiredInformation))
        formData.append("prompt", `Analyze below commodity contract and give me details like ${JSON.stringify(requiredInformation)} in an json format, and if you dont find details for any key, simply return null for that key, same goes for arrays if you dont find keys inside array make it null but the array structure should be same as i mentioned.
    docTxtData `)
        formDataArr.push(formData)
      }
      setshowLoader(true)
      const promises = formDataArr.map(formData => fetchDetailsFromGPT(formData));
      const chatGptApiResp = await Promise.all(promises);
      // const chatGptApiResp = contractOCRResp
      console.log('All promises resolved:', chatGptApiResp);
      // combine all responsed into one json object with validating everything
      let finalOCRApiResp = {}
      try {
        for (let index = 0; index < chatGptApiResp.length; index++) {
          const element = chatGptApiResp[index]?.data;
          gptContractOCRValidation(element, finalOCRApiResp, incoTermsData, countryData, currencyData)
        }
        let dataToSet = await mapGptRespData(contractState.info.modalMode, finalOCRApiResp, traderActiveType, dbSupplier, userId, setdataTnc, settncOrder, toggleTraderOnboardForm, setSpecification, setspecOrder)
        console.log("dataToSetttttttttttttt", finalOCRApiResp, dataToSet);
        setData({ ...data, ...dataToSet })
        setrefresh(refresh + 1)
      } catch (error) {
        console.log("errorInfinalOCRApiResp", error);
      }
      console.log("finalOCRApiResppppppppppp", finalOCRApiResp);
      setshowLoader(false)
    }
    else {
      toastDisplay(splittedPdfFileObjs?.msg, "error")
    }
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    if (name === "loadingPort") {
      let filteredPortsOfLoading = []
      let tempPortsOfLoading = portLoading
      tempPortsOfLoading.push({ port_name: typedInput })
      if (typedInput) {
        filteredPortsOfLoading = tempPortsOfLoading.filter((i) => {
          if (i.port_name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredPortsOfLoading = tempPortsOfLoading
      }
      setPortLoadingFiltered([...filteredPortsOfLoading])
    }
    if (name === "unloadingPort") {
      let filteredPortsOfUnloading = []
      let tempPortsOfUnloading = portUnloading
      tempPortsOfUnloading.push({ port_name: typedInput })
      if (typedInput) {
        filteredPortsOfUnloading = tempPortsOfUnloading.filter((i) => {
          if (i.port_name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredPortsOfUnloading = tempPortsOfUnloading
      }
      setPortUnloadingFiltered([...filteredPortsOfUnloading])
    }
  }

  return (
    <div>
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      {/* {templateSelectionForm.show ? (
        <FinanceInvoiceModal limitinvoice={templateSelectionForm.show} closeSuccess={() => {
          toggleTemplateSelectionForm({ show: false, data: templateSelectionForm.data })
        }}>
          <div>
            <label className='font-size-16 font-wt-600 w-100 text-center' >Select Template</label>
            <div className='d-flex gap-4 p-4' >
              {contractTemplateTypes.map((i, j) => {
                return (
                  <div
                    onClick={() => {
                      toggleTemplateSelectionForm({ show: false, data: { type: i.name } })
                    }}
                    className='w-35 cursor' >
                    <img className='contractTemplateImg' style={{}} src='/assets/images/bulkordercontracttemplate.PNG' />
                    <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                  </div>
                )
              })}
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null} */}

      {traderOnboardForm.show ? (
        <FinanceInvoiceModal limitinvoice={traderOnboardForm.show} closeSuccess={() => {
          toggleTraderOnboardForm({ show: false })
        }}>
          <div className="d-flex row justify-content-center" >
            <label className="font-size-16 font-wt-600 text-center w-100 col-12 pb-3" >{`Enter ${traderActiveType === "buyer" ? 'Seller' : 'Buyer'} Details`}</label>
            <div className="col-8 justify-self-center" >
              <NewInput label={"Company Name"} onChange={handleChange} name={"traderOnboardCompanyName"} value={data.traderOnboardCompanyName}
              />
            </div>
            <div className="col-8" >
              <NewInput label={"Email Id"} onChange={handleChange} name={"traderOnboardEmailId"} value={data.traderOnboardEmailId}
              />
            </div>
            {/* <div className="w-100 text-center" >
              <label class="form-check-label text-center font-size-13 font-wt-600" for="sendInvite">
                <input class="form-check-input new-check-box" type="checkbox" name="sendInvite" id="sendInvite"
                  value={!data.sendInvite}
                  onChange={handleChange}
                  checked={data.sendInvite}
                />
                Also add user in my Network</label>
            </div> */}
            <div className="col-6 mt-3" >
              <button type="button"
                onClick={async () => {
                  if (data.traderOnboardCompanyName && data.traderOnboardEmailId) {
                    // onboard user start
                    let userOnboardResp = await signUpUser(data.traderOnboardCompanyName, data.traderOnboardCompanyName, null, data.traderOnboardEmailId)
                    if (userOnboardResp) {
                      setAutoSelectUserByEmail(data.traderOnboardEmailId)
                      setrefreshSupplier(refreshSupplier + 1)
                      setData({ ...data, traderOnboardCompanyName: "", traderOnboardEmailId: '' })
                      toggleTraderOnboardForm({ show: false })
                    }
                  }
                  else {
                    toastDisplay('Fill details to continue', 'info')
                  }
                }}
                className={` new-btn w-100 py-2 px-2  text-white`}>
                {"Submit"}
              </button>
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null}

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        <div className="row">
          {isUserAccountActive ?
            <SideBarV2 state="contractManagement" userTokenDetails={userTokenDetails} /> : null}
          <main role="main" className={`ml-sm-auto ${isUserAccountActive ? ' col-lg-10 ' : " col-lg-12 "} ` + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
            {isUserAccountActive ? <><HeaderV2
              // titleClass={"cursor"}
              title={contractState.info.modalMode != "create" ?
                `Contract Form - (${templateSelectionForm.data?.type})`
                : `Create Contract - (${templateSelectionForm.data?.type})`}
              userTokenDetails={userTokenDetails}
            // onTitleClick={() => {
            //   toggleTemplateSelectionForm({ show: true, data: templateSelectionForm.data })
            // }}
            />
              <a
                onClick={() => {
                  history.go(-1)
                }}
                className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            </> : null}

            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabsArr.map((item, index) => {
                  if (!item.hide) {
                    return (
                      <li
                        style={{ width: `14.28%` }}
                        onClick={() => {
                          if (index < activeClass) {
                            setActiveClass(index + 1)
                          }
                        }}
                      >
                        <a
                          style={{ width: `100%` }}
                          className={"nav-link formTab px-4 cursor-pointer " + (activeClass === index + 1 ? " formActiveTab show" : "")}
                        >
                          {item.name}</a>
                      </li>
                    )
                  }
                })}
              </ul> */}
            </div>
            {templateSelectionForm.show ?
              <div className='card mt-1 px-2' >
                <div className='d-flex justify-content-center align-items-center pt-4'>
                  <label className='font-size-22 font-wt-600 text-center mr-5' >Select Template</label>
                  {/* <input className='ml-5 pl-3' placeholder='Search Template here' onChange={(e) => setTemplatename(e.target.value)} style={{ border: "1px solid grey", borderRadius: "5px", padding: "10px", width: "30%", height: "40%" }} /> */}
                  <div style={{ width: "50%" }} className="input-group rounded-pill bg-F8F8F8 chatsearch pl-5">
                    <span className="input-group-text border-0 bg-transparent px-0" id="basic-addon1"><img src={'/assets/images/fe_search.png'} alt='' /></span>
                    <input type="text" className="form-control border-0 bg-transparent" placeholder="Search Template here" onChange={(e) => setTemplatename(e.target.value)} />
                  </div>
                </div>

                {templateName ? (<div className='d-flex gap-4 p-4 flex-wrap' >
                  {allTemplates
                    .filter((template) => template.commodity_pretty_name.toLowerCase().includes(templateName.toLowerCase()))
                    .map((i, j) => {
                      return (
                        <div
                          onClick={async () => {
                            let formData = new FormData();
                            formData.append('owner_id', userId)
                            formData.append('selectedComm', i.id);
                            formData.append('spec', JSON.stringify(i.specifications || "{}"));
                            formData.append('specOrder', i.spec_order_string);
                            let commAddResp = await call('POST', 'setCommodityuser', formData)
                            setData({
                              ...data, commCatId0: i.category_id, commodity0: commAddResp.message.id
                            })
                            let tempSpecification = spec
                            tempSpecification[0] = i.specifications || {}
                            setSpecification([...tempSpecification]);
                            let tempSpecOrder = specOrder
                            tempSpecOrder[0] = i.spec_order_string ? i.spec_order_string.split(":") : ""
                            setspecOrder([...tempSpecOrder])
                            getSupplierCommodities(multiSelectEventHandler([{ id: i.category_id, category: i.category }], `commCatId${j}`, "id"), userId);
                            toggleTemplateSelectionForm({ show: false, data: { type: i.name } })
                          }}
                          className='w-30 cursor' >
                          <img className='contractTemplateImg' style={{}} src='/assets/images/bulkordercontracttemplate.PNG' />
                          <label className='font-size-14 font-wt-600 p-2 cursor' >{i.commodity_pretty_name + ' Contract'}</label>
                        </div>
                      )
                    })

                  }
                </div>) : <div className='d-flex gap-4 p-4 flex-wrap' >
                  {contractTemplateTypes.map((i, j) => {
                    return (
                      <div
                        onClick={() => {
                          toggleTemplateSelectionForm({ show: false, data: { type: i.name } })
                        }}
                        className='w-30 cursor' >
                        <img className='contractTemplateImg' style={{}} src='/assets/images/bulkordercontracttemplate.PNG' />
                        <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                      </div>
                    )
                  })}
                  {mstCommList.map((i, j) => {
                    return (
                      <div
                        onClick={async () => {
                          let formData = new FormData();
                          formData.append('owner_id', userId)
                          formData.append('selectedComm', i.id);
                          formData.append('spec', JSON.stringify(i.specifications || "{}"));
                          formData.append('specOrder', i.spec_order_string);
                          let commAddResp = await call('POST', 'setCommodityuser', formData)
                          setData({
                            ...data, commCatId0: i.category_id, commodity0: commAddResp.message.id
                          })
                          let tempSpecification = spec
                          tempSpecification[0] = i.specifications || {}
                          setSpecification([...tempSpecification]);
                          let tempSpecOrder = specOrder
                          tempSpecOrder[0] = i.spec_order_string ? i.spec_order_string.split(":") : ""
                          setspecOrder([...tempSpecOrder])
                          getSupplierCommodities(multiSelectEventHandler([{ id: i.category_id, category: i.category }], `commCatId${j}`, "id"), userId);
                          toggleTemplateSelectionForm({ show: false, data: { type: i.name } })
                        }}
                        className='w-30 cursor' >
                        <img className='contractTemplateImg' style={{}} src='/assets/images/bulkordercontracttemplate.PNG' />
                        <label className='font-size-14 font-wt-600 p-2 cursor' >{i.commodity_pretty_name + ' Contract'}</label>
                      </div>
                    )
                  })}
                </div>}
              </div> : <div className="card mt-1 px-2"
              >
                {!isUserAccountActive ? <label onClick={() => {
                  window.location = '/registration'
                }} className='font-size-16 font-wt-600 text-danger cursor m-4'
                >*Contract is opened in view only mode, you can able to make changes only when you complete registration, click here to complete registration</label> : null}
                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabsArr} activeTab={activeClass - 1} label={"name"}
                    separationWidth={'2.5rem'} handleClick={(i, j) => {
                      if (j < activeClass) {
                        setActiveClass(j + 1)
                      }
                    }}
                  />
                </div>
                {activeClass === 1 ? (
                  <div className="d-flex row p-4" >

                    {/* <div className="d-flex row align-items-center mb-4" >
                      <div className="col-auto pt-1" >
                        <label className="font-size-14 font-wt-500 ">Do you want the contract to include a copy of the system-generated contract in your contract document?</label>
                      </div>
                      <div className="col-auto row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >
                        <div className='w-auto' onClick={() => {
                          setContractCopy("Yes")
                        }}>
                          <input className="form-check-input" type="radio" value={"Yes"} checked={contractCopy === 'Yes'} />
                          <label className="form-check-label p-0 m-0" >
                            Yes
                          </label>
                        </div>
                        <div className='w-auto' onClick={() => {
                          setContractCopy("No")
                        }}>
                          <input className="form-check-input" type="radio" value={"No"} checked={contractCopy === 'No'} />
                          <label className="form-check-label p-0 m-0" >
                            No
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <>
                      <div className='d-flex row mb-4'>
                        {/* <div className='col-5'>
                          <label className='font-size-14 font-wt-500' >Upload Contract & our system will fetch details from contract automatically</label>
                          <div className='col-12'>
                            <FileInput
                              name={`contractDocForOCR`} value={data[`contractDocForOCR`]} error={errorData[`contractDocForOCR`]}
                              isEditable={true} onChange={async (e) => {
                                setshowLoader(true)
                                let fileData = await handleFileWithAsyncAwait(e)
                                // console.log("fileDataaaaaaaaaaaaaaa", fileData);
                                if (fileData.status) {
                                  seterrorData({ ...errorData, contractDocForOCR: "" })
                                  setData({ ...data, contractDocForOCR: fileData.msg?.contractDocForOCR })
                                  contractOCRInit(fileData.msg?.contractDocForOCR)
                                }
                                else {
                                  seterrorData({ ...errorData, contractDocForOCR: fileData.msg?.contractDocForOCR })
                                }
                                setshowLoader(false)
                              }}
                              onUploadCancel={() => setData({ ...data, [`contractDocForOCR`]: null })}
                            />
                            {errorData[`contractDocForOCR`] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errorData[`contractDocForOCR`]}</b></div> : ''}
                          </div>
                        </div> */}
                        {contractState.info.modalMode === "create" ? <>
                          {/* <div className='col-2 d-flex align-items-center justify-content-center'>
                            <label className='font-size-18 font-wt-600 text-color1'>OR</label>
                          </div> */}
                          <div className='col-5 d-flex align-items-center'>
                            <div className='w-80'>
                              <MultipleSelect
                                Label={'Replicate Previous Contracts'}
                                Id={`selectedReplicateContract`}
                                optiondata={contractsToReplicate}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `selectedReplicateContract`, "id"))
                                }}
                                value={data[`selectedReplicateContract`] ? [data[`selectedReplicateContract`]] : []}
                                name={`selectedReplicateContract`}
                                labelKey={"contract_name"}
                                valKey={"id"}
                                error={errorData[`selectedReplicateContract`]}
                              />
                            </div>
                          </div>
                        </> : null}
                      </div><hr className='mb-4 w-98' />
                    </>

                    {contractState.info.modalMode === "create" ? <div className={isCompactContract ? "col-md-6" : "col-md-6"} >
                      <div className="d-flex row align-items-center" >
                        <div className="col-3 pt-1" >
                          <label className="font-size-14 font-wt-500 ">Your Role</label>
                        </div>
                        <div className="col-9 row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >
                          <div className='w-auto' onClick={() => {
                            switchTrader("buyer")
                          }}>
                            <input className="form-check-input" type="radio" value={"buyer"} checked={trader === 'buyer'} />
                            <label className="form-check-label p-0 m-0" >
                              As Buyer
                            </label>
                          </div>
                          <div className='w-auto' onClick={() => {
                            switchTrader('seller')
                          }}>
                            <input className="form-check-input" type="radio" value={"CIF"} checked={trader === 'seller'} />
                            <label className="form-check-label p-0 m-0" >
                              As Seller
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> : null}
                    {contractState.info.modalMode != "create" ?
                      <>
                        <div className="col-md-4" >
                          <NewInput
                            label={`Contract Name`} value={data.contractAlias} error={errorData.contractAlias}
                            onChange={handleChange} name={"contractAlias"}
                          />
                        </div>
                        <div className="col-md-2">
                          <div className="row form-group">
                            <label className="col-md-12">{data.contractType} </label>
                            <div className="col-md-12"><span className="date-format">Inco Terms</span></div>
                          </div>
                        </div>
                        <div className="row col-md-6">
                          <div className="col-md-6 ">
                            <div className="mr-auto pr-3 form-group">
                              <label className="col-md-12">Buyer/Importer</label>
                              <div className="chat-message pl-2">
                                <img src={avatarUrl(data.buyerAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span><br />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="mr-auto pr-3 form-group">
                              <label className="col-md-12">Seller/Exporter</label>
                              <div className="chat-message pl-2">
                                <img src={avatarUrl(data.supplierAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span><br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      : null}
                    {contractState.info.modalMode === "create" ? <div className={isCompactContract ? "col-md-4" : "col-md-5"} >
                      <MultipleSelect
                        Label={(traderActiveType === 'buyer') ? "Select Seller/Exporter" : "Select Buyer/Importer"}
                        filterOption={() => true}
                        onInputChange={(e) => {
                          setSearchUserForListing(e)
                        }}
                        Id={`NA`}
                        optiondata={dbSupplier}
                        onChange={async (e) => {
                          if (e?.[0]?.id === "Add New") {
                            // window.open(window.location.origin + '/MyNetwork', "_blank")
                            toggleTraderOnboardForm({ show: true })
                          }
                          else if (e?.[0]?.id) {
                            handleSelectedSupplier(e?.[0])
                          }
                          else if (e?.[0]?.buyerId) {
                            let userOnboardResp = await signUpUser(e?.[0]?.company_name, e?.[0]?.contact_person || e?.[0]?.company_name, e?.[0]?.user_address, e?.[0]?.user_name)
                            if (userOnboardResp) {
                              setAutoSelectUserByEmail(e?.[0]?.user_name)
                            }
                          }
                          else {
                            setData({
                              ...data, importer_id: null, exporter_id: null
                            })
                          }
                        }}
                        value={traderActiveType === "buyer" && data["exporter_id"] ? [data[`exporter_id`]] :
                          data["importer_id"] ? [data["importer_id"]] : []}
                        name={`NA`}
                        labelKey={"html"}
                        valKey={"id"}
                        error={errorData[`supplierName`] || errorData[`buyerName`]}
                      />
                    </div> : null
                    }


                    <div className='d-flex row mt-4' >
                      {isCompactContract ?
                        <div className="col-md-3">
                          <div className="form-group">
                            <NewInput
                              type={"text"}
                              label={"Deal Name"}
                              value={data.contractAlias}
                              onChange={handleChange}
                              error={errorData[`contractAlias`]}
                              name={'contractAlias'}
                            />
                          </div>
                        </div> : null}

                      {isCompactContract ?
                        <div className="col-md-3">
                          <div className="form-group">
                            <InputWithSelect
                              type={"number"}
                              label={"Deal Amount"}
                              selectData={currencyData}
                              selectName={"currency"}
                              optionLabel={"code"}
                              optionValue={"code"}
                              selectValue={data.currency}
                              onChange={handleChange}
                              error={errorData[`currency`] || errorData[`price0`]}
                              name={'price0'}
                              value={data[`price0`]}
                            />
                          </div>
                        </div> : null}

                      {isCompactContract ?
                        <div className="col-md-3">
                          <div className="form-group">
                            <NewInput
                              type={"date"}
                              label={"Invoice Issue Date"}
                              value={data.invoiceIssueDate}
                              onChange={handleChange}
                              error={errorData[`invoiceIssueDate`]}
                              name={'invoiceIssueDate'}
                            />
                          </div>
                        </div> : null}

                      {isCompactContract ?
                        <div className="col-md-3">
                          <div className="form-group">
                            <NewInput
                              type={"date"}
                              label={"Invoice Due Date"}
                              value={data.invoiceDueDate}
                              onChange={handleChange}
                              error={errorData[`invoiceDueDate`]}
                              name={'invoiceDueDate'}
                            />
                          </div>
                        </div> : null}
                    </div>

                    {isCompactContract ? <>
                      <hr />
                      <label className='font-size-16 font-wt-600 mt-2' >Select Stage</label>
                      <div className='col-12 row d-flex ml-3 mb-2' >
                        {dealStages.map((i, j) => {
                          return (<div onClick={() => {
                            let tempStageInfo = {}
                            for (let index = 0; index < dealStages.length; index++) {
                              if (j >= index) {
                                tempStageInfo = { ...tempStageInfo, [dealStages[index]["name"]]: {} }
                              }
                            }
                            console.log("ttttttttttttttttttttttt", tempStageInfo);
                            setStageInfo({ ...tempStageInfo })
                          }} className='w-12 cursor m-0 p-0' >
                            <div className='d-flex row position-relative ' >
                              <div className='ml-2' >
                                <img className='cursor '
                                  src={stageInfo[i.name] ? "assets/images/checked-green.png" :
                                    "assets/images/empty-check.png"} />
                              </div>
                              {j != dealStages.length - 1 ?
                                <div className='' style={{ border: !stageInfo[dealStages?.[j + 1]?.["name"]] ? `2px solid #9C9C9C` : '2px solid green', width: '65%', position: 'absolute', top: '0.85rem', left: '3.1rem' }} />
                                : null}</div>
                            <label className='font-size-14 font-wt-500 cursor mt-2' >{i.name}</label>
                          </div>)
                        })}
                      </div>
                      <hr />
                      {(stageInfo[`Payment Scheduled`] || stageInfo["Payment Settled"]) ? <>
                        <label className='font-size-16 font-wt-600 mt-2 mb-0' >Payment Schedule</label>
                        <div>
                          <BankReimburseForm2
                            data={reimburseData}
                            setData={setReimburseData}
                            error={errorData}
                            setError={seterrorData}
                            txns={txns}
                            setTxns={setTxns}
                            hideSubmitBtn
                            userTokenDetails={userTokenDetails}
                            contractData={{
                              reimburse_data: null,
                              id: data.savedContractDetails?.id,
                              contractNo: data.savedContractDetails?.contract_number,
                              exporter_id: data.savedContractDetails?.exporter_id,
                              importer_id: data.savedContractDetails?.importer_id,
                              buyer_bank: data.savedContractDetails?.buyer_bank,
                              supplier_bank: data.savedContractDetails?.supplier_bank,
                              paymentType: "LC",
                              currency: data.currency
                            }}
                            setshowLoader={setshowLoader}
                            refresh={refresh}
                            setrefresh={setrefresh}
                          />
                        </div>
                        <hr />
                      </> : null}
                      <label className='font-size-16 font-wt-600 mt-2 mb-0' >Documents</label>
                      <div>
                        <ExtraDetailsForLimitApplication gstCredentialsViewHide gstDoc1Hide
                          bankDoc1Hide bankDoc2Hide bankDoc3Hide finDoc1Hide fintDoc2Hide finDoc3Hide finDoc4Hide
                          orgDoc1Hide orgDoc2Hide orgDoc3Hide hideInvestigationDocs hideInsuranceDocs hideDiscountingDocs
                          showContractPO={stageInfo[`Deal Created`]}
                          showContractInvoice={stageInfo[`Deal In Pilot`]}
                          showContractAgreement={stageInfo[`Deal Locked`]}
                          showShipmentDocs={stageInfo[`Shipment Scheduled`]}
                          contractNo={contractNumber} mainTagMarginPadding0={true}
                          userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                          hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                          showContinueButton={true} hideToastAfterDetailsSaved afterDetailsSavedOn={() => { handleNext() }}
                          loadDataInWriteModeFromCacheIfPresent={true} continueBtnCSS={{ opacity: 0, height: 0 }} />
                      </div>
                    </> : null}

                    {contractState.info.modalMode === "create" && !isCompactContract ? <div className="col-md-6" >
                      <div className="d-flex row align-items-center" >
                        <div className="col-3 pt-1" >
                          <label className="font-size-14 font-wt-500 ">Inco Terms</label>
                        </div>

                        <div className="col-9 row d-flex my-0 p-0 px-1 font-size-13 font-wt-600" >

                          <NewSelect label={''} name={"contractType"} selectData={incoTermsData} optionLabel={"name"}
                            optionValue={"name"} onChange={(event) => {
                              setData({ ...data, contractType: event.target.value })
                            }}
                            error={errorData.contractType} value={data.contractType} />
                        </div>
                      </div>
                    </div> : null}

                    {contractState.info.modalMode === "create" && !isCompactContract ?
                      <div className="col-md-5" >
                        <NewInput
                          label={`Contract Name`} value={data.contractAlias} error={errorData.contractAlias}
                          onChange={handleChange} name={"contractAlias"}
                        />
                      </div> : null}

                    {contractState.info.modalMode != "create" ?
                      <div className="modal-padding">
                        <hr />
                        <h3 className="mt-3 ml-2 font-size-14 font-wt-500"> Commodity </h3>
                        <div className='row mt-4 pl-3' >
                          {multComm.map((i, j) => {
                            if (i === null) {
                              return (
                                <div className="col-2 " >
                                  {data[`commFilename${j}`] && <img width="80" height="80" src={commImageUrl(data[`commFilename${j}`])} className="img-responsive" alt="description" />}
                                  <h5 className='font-size-13 font-wt-500 text-left w-100 ml-3' >{data[`commodity_name${j}`]}</h5>
                                </div>)
                            }
                          })}
                        </div>
                        <hr />
                      </div>
                      : null}

                    {isCompactContract ? null :
                      <div className='customHrLine mt-3 mb-4' />}
                    {contractState.info.modalMode === "create" && !isCompactContract && multComm.map((i, j) => {
                      if (i === null) {
                        jj = jj + 1
                        return (
                          <div className='row' >
                            {templateSelectionForm.data?.type != "Bulk Contract" ?
                              <label className='font-size-14 font-wt-500 w-100' >{`Commodity ${jj + 1}:`}</label> : null}
                            <div className="col-md-4" >
                              <MultipleSelect
                                Label={'Select Commodity Type/Category'}
                                Id={`commCatId${j}`}
                                optiondata={commCategory}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `commCatId${j}`, "id"))
                                  // getSupplierCommodities(multiSelectEventHandler(e, `commCatId${j}`, "id"), (traderActiveType === 'buyer') ? selectedSupplier.supplierId : userId);
                                }}
                                value={data[`commCatId${j}`] ? [data[`commCatId${j}`]] : []}
                                name={`commCatId${j}`}
                                labelKey={"category"}
                                valKey={"id"}
                                error={errorData[`commCatId${j}`]}
                              />
                            </div>
                            <div className="col-md-4" >
                              <MultipleSelect
                                onClick={() => { setrefresh(refresh + 1) }}
                                Label={'Select Commodity Name'}
                                Id={`commodity${j}`}
                                optiondata={supplierCommodityList}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `commodity${j}`, "id"))
                                }}
                                value={data[`commodity${j}`] ? [data[`commodity${j}`]] : []}
                                name={`commodity${j}`}
                                labelKey={"commodity_pretty_name"}
                                valKey={"id"}
                                error={errorData[`commodity${j}`]}
                              />
                            </div>
                            {templateSelectionForm.data?.type != "Bulk Contract" ?
                              <div className="col-md-4 d-flex pt-2">
                                {(multComm.length - multComm.filter(i => {
                                  if (i === undefined) {
                                    return true
                                  }
                                }).length) - 1 === jj ?
                                  <label
                                    onClick={async () => {
                                      let temp = multComm
                                      temp.push(null)
                                      setMultComm([...temp])
                                    }}
                                    className='text-color1 font-size-14 font-wt-700 cursor w-50 text-center' >Add more +</label> :
                                  <label
                                    onClick={() => {
                                      let temp = multComm
                                      temp[j] = undefined
                                      setMultComm([...temp])
                                    }} className='text-danger font-size-14 font-wt-700 cursor w-50 text-center' >Delete</label>}
                              </div> : null}
                          </div>
                        )
                      }
                    })}
                    {/* </>
                    ) : null} */}

                    <>
                      {contractState.info.modalMode === "create" && !isCompactContract ?
                        <div className='customHrLine mt-3 mb-4' /> : null}
                      {!isCompactContract ? <>
                        <div className="col-md-4" >
                          <NewInput
                            type={"date"}
                            label={'Laycan Start Date'}
                            onChange={handleChange}
                            value={data['laycanStart']}
                            name={`laycanStart`}
                            error={errorData[`laycanStart`]}
                          />
                        </div>
                        <div className="col-md-4" >
                          <NewInput
                            type={"date"}
                            label={'Laycan End Date'}
                            onChange={handleChange}
                            value={data['laycanEnd']}
                            name={`laycanEnd`}
                            error={errorData[`laycanEnd`]}
                          />
                        </div>
                      </> : null}
                    </>

                    {!isCompactContract ?
                      <>
                        <div className='customHrLine mt-3 mb-4' />
                        <div className="col-md-4" >
                          <MultipleSelect
                            Label={'Loading Country'}
                            Id={`loadingCountry`}
                            optiondata={countryData}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `loadingCountry`, "id"))
                              loadPort(multiSelectEventHandler(e, `loadingCountry`, "id"));
                            }}
                            value={data[`loadingCountry`] ? [data[`loadingCountry`]] : []}
                            name={`loadingCountry`}
                            labelKey={"name"}
                            valKey={"id"}
                            error={errorData[`loadingCountry`]}
                          />
                        </div>
                        <div className="col-md-4" >
                          <MultipleSelect
                            Label={'Loading Location/Port'}
                            Id={`loadingPort`}
                            optiondata={portLoadingFiltered}
                            filterOption={() => true}
                            onInputChange={(e) => {
                              handleFilterOptions(e, "loadingPort")
                            }}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `loadingPort`, "port_name"))
                            }}
                            value={data[`loadingPort`] ? [data[`loadingPort`]] : []}
                            name={`loadingPort`}
                            labelKey={"port_name"}
                            valKey={"port_name"}
                            error={errorData[`loadingPort`]}
                          />
                        </div>
                        <div className='col-4' />
                        <div className="col-md-4" >
                          <MultipleSelect
                            Label={'Unloading Country'}
                            Id={`unloadingCountry`}
                            optiondata={countryData}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `unloadingCountry`, "id"))
                              unloadPort(multiSelectEventHandler(e, `unloadingCountry`, "id"))
                            }}
                            value={data[`unloadingCountry`] ? [data[`unloadingCountry`]] : []}
                            name={`unloadingCountry`}
                            labelKey={"name"}
                            valKey={"id"}
                            error={errorData[`unloadingCountry`]}
                          />
                        </div>
                        <div className="col-md-4" >
                          <MultipleSelect
                            Label={'Unloading Location/Port'}
                            Id={`unloadingPort`}
                            optiondata={portUnloadingFiltered}
                            filterOption={() => true}
                            onInputChange={(e) => {
                              handleFilterOptions(e, "unloadingPort")
                            }}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `unloadingPort`, "port_name"))
                            }}
                            value={data[`unloadingPort`] ? [data[`unloadingPort`]] : []}
                            name={`unloadingPort`}
                            labelKey={"port_name"}
                            valKey={"port_name"}
                            error={errorData[`unloadingPort`]}
                          />
                        </div>
                      </> : null}
                  </div>) : null}

                {activeClass === 2 && isCompactContract ?
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mb-2'>
                      <div className='row'>
                        {compactContractReview.map((item) => {
                          if (item.val) {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {item.val ? (item.unit ? `${item.unit} ` : "") + (item.val) : "NA"}</span> </p>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <hr />
                    {(stageInfo[`Payment Scheduled`] || stageInfo["Payment Settled"]) ? <>
                      <label className='font-size-16 font-wt-600 mt-2 mb-0' >Payment Schedule</label>
                      <div>
                        <BankReimburseForm2
                          isDisabled
                          afterApiCall={() => {
                            toastDisplay("Contract created", "success", () => {
                              window.location = '/contractManagementList'
                            })
                          }}
                          doApiCall={data.savedContractDetails?.id}
                          data={reimburseData}
                          setData={setReimburseData}
                          error={errorData}
                          setError={seterrorData}
                          txns={txns}
                          setTxns={setTxns}
                          hideSubmitBtn
                          userTokenDetails={userTokenDetails}
                          contractData={{
                            reimburse_data: null,
                            id: data.savedContractDetails?.id,
                            contractNo: data.savedContractDetails?.contract_number,
                            exporter_id: data.savedContractDetails?.exporter_id,
                            importer_id: data.savedContractDetails?.importer_id,
                            buyer_bank: data.savedContractDetails?.buyer_bank,
                            supplier_bank: data.savedContractDetails?.supplier_bank,
                            paymentType: "LC",
                            currency: data.currency
                          }}
                          setshowLoader={setshowLoader}
                          refresh={refresh}
                          setrefresh={setrefresh}
                        />
                      </div>
                      <hr />
                    </> : null}
                    <label className='font-size-16 font-wt-600 mt-2 mb-0' >Documents</label>
                    <div>
                      <ExtraDetailsForLimitApplication isDisabled gstCredentialsViewHide gstDoc1Hide
                        bankDoc1Hide bankDoc2Hide bankDoc3Hide finDoc1Hide fintDoc2Hide finDoc3Hide finDoc4Hide
                        orgDoc1Hide orgDoc2Hide orgDoc3Hide hideInvestigationDocs hideInsuranceDocs hideDiscountingDocs
                        showContractPO={stageInfo[`Deal Created`]}
                        showContractInvoice={stageInfo[`Deal In Pilot`]}
                        showContractAgreement={stageInfo[`Deal Locked`]}
                        showShipmentDocs={stageInfo[`Shipment Scheduled`]}
                        contractNo={contractNumber} mainTagMarginPadding0={true}
                        userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton={true} hideToastAfterDetailsSaved afterDetailsSavedOn={() => { handleNext() }}
                        loadDataInWriteModeFromCacheIfPresent={true} continueBtnCSS={{ opacity: 0, height: 0 }} />
                    </div>
                  </div>
                  : null}

                {activeClass === 2 ? (
                  <div className="d-flex row p-4" >
                    <div className={"tab-content p-0 m-0" + (activeClass === 2 ? " is-active" : "")}>
                      <div className="">
                        {multComm.map((i, j) => {
                          if (i === null && (data[`commodity${j}`] || data[`commodity_id${j}`])) {
                            return (
                              <>
                                {errorData[`Specification${j}`] && <p className="text-danger text-center font-size-13 font-wt-500">{errorData[`Specification${j}`]}</p>}
                                {((data[`commodity${j}`] || data[`commodity_id${j}`]) && activeClass === 2) && <SpecificationsForm
                                  commodity={data[`commodity${j}`] ? data[`commodity${j}`] : data[`commodity_id${j}`]}
                                  spec={spec[j]}
                                  handleSpecification={(e) => handleSpecification(e, j)}
                                  // setSpecification={setSpecification}
                                  onSetSpecification={(dataArg) => {
                                    let tempSpec = spec
                                    tempSpec[j] = dataArg
                                    setSpecification([...tempSpec])
                                  }}
                                  specOrder={specOrder[j]}
                                  // setspecOrder={setspecOrder}
                                  onSetSpecOrder={(dataArg) => {
                                    let tempSpecOrder = specOrder
                                    tempSpecOrder[j] = dataArg
                                    setspecOrder([...tempSpecOrder])
                                  }}
                                  contMode={contractState.info.modalMode}
                                  editCount={editCount}
                                  setEditCount={setEditCount}
                                  editLevel={true}
                                  masterid={false} />}
                                <hr />
                              </>
                            )
                          }
                        })}
                        {/* {miniPreview()} */}
                        {/* <div className='customHrLine mt-3 mb-4' /> */}
                        {/* Commodity Form Component Start */}

                        {/* Commodity Form Component End */}
                      </div>
                    </div>
                  </div>) : null}


                {activeClass === 3 ?
                  <div className="d-flex row p-4" >
                    <div className={"tab-content p-0 m-0" + (activeClass === 3 ? " is-active" : "")}>
                      <div className="">
                        {multComm.map((i, j) => {
                          if (i === null) {
                            return (
                              <>
                                <div className="modal-padding">
                                  <div className="row">
                                    {contractState.info.modalMode === "create" ?
                                      <label className="col-md-3 font-size-13 font-wt-500">{`${supplierCommodityList.find(item => { if (item.id === data[`commodity${j}`]) { return true } })?.['commodity_pretty_name']} Price/${supplierCommodityList.find(item => { if (item.id === data[`commodity${j}`]) { return true } })?.['unit']}`}{astrix}</label>
                                      :
                                      <label className="col-md-3 font-size-13 font-wt-500">{`${data[`commodity_name${j}`]} Price/${data[`commodity_unit${j}`]}`}{astrix}</label>
                                    } <div className="col-md-3">
                                      <NewSelect label={'Select Price Type'} name={`priceType${j}`} selectData={[{ name: 'Fixed Price', val: "1" }, { name: 'Index Price', val: "2" }]} optionLabel={"name"}
                                        optionValue={"val"} onChange={(event) => { handleChange(event); loadIndexPrice(event) }}
                                        error={errorData[`priceType${j}`]} value={data[`priceType${j}`]} />
                                    </div>

                                    <div className="col-md-3">
                                      <div className="row">
                                        {(data[`priceType${j}`] / 1) === 2 ? <>
                                          <div className="col-md-5">
                                            <NewInput label={"Price"} name={`price${j}`} type={"number"} value={data[`price${j}`]} placeholder="Price" onChange={handleChange}
                                              error={errorData[`price${j}`]} isDisabled={(data[`priceType${j}`] / 1) === 2 ? true : false}
                                            />
                                          </div>
                                          <span className="col-md-2 font-size-20 font-wt-600 mt-2">+-</span>
                                          <div className="col-md-5">
                                            <NewInput label={"Price"} name={`priceTolerance${j}`} type={"number"} value={data[`priceTolerance${j}`]} placeholder="" onChange={handleChange}
                                              error={errorData[`priceTolerance${j}`]} isDisabled={(data[`priceType${j}`] / 1) === 2 ? true : false}
                                            />
                                          </div>
                                        </> : <>
                                          <div className="col-md-12">
                                            <NewInput label={"Price"} name={`price${j}`} type={"number"} value={data[`price${j}`]} placeholder="" onChange={handleChange}
                                              error={errorData[`price${j}`]}
                                            />
                                          </div>
                                        </>}
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <MultipleSelect
                                          Label={"Select Currency"}
                                          optiondata={currencyData}
                                          Id={`currency`}
                                          name={"currency"}
                                          labelKey={"code"}
                                          valKey={"code"}
                                          value={data.currency ? [data.currency] : []}
                                          onChange={(e) => {
                                            handleChange(multiSelectEventHandler(e, `currency`, "code"))
                                          }}
                                          error={errorData[`currency`]}
                                        />
                                      </div>
                                    </div>

                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-md-12">
                                      <div className="form-group d-flex align-items-center mb-0 pb-2">
                                        {contractState.info.modalMode === "create" ?
                                          <label for="message-text" className="col-form-label font-size-13 font-wt-500">{`Add Quantity ${supplierCommodityList.find(item => { if (item.id === data[`commodity${j}`]) { return true } })?.['unit']}`}{astrix}</label>
                                          :
                                          <label for="message-text" className="col-form-label font-size-13 font-wt-500">{`Add Quantity ${data[`commodity_unit${j}`]}`}{astrix}</label>
                                        }
                                        {/* <span className="ml-2">(mt)</span> */}
                                        <div className="ml-auto">
                                          <button type="button" onClick={() => { handleDec(j) }} value="-" className="edit-icon"><i class="fas fa-minus text-primary"></i></button>
                                          <input key={`quantity${j}`} type="number" name={`quantity${j}`} placeholder="0" min="0" size="1" id={`quantity${j}`} value={data[`quantity${j}`]} className={" text-center increment" + (!errorData[`quantity${j}`] ? '' : ' border-danger')} onChange={handleChange} />
                                          <button type="button" onClick={() => { handleInc(j) }} value="+" className="edit-icon"><i class="fas fa-plus text-primary"></i></button>
                                        </div>
                                        {contractState.info.modalMode === "create" && traderActiveType === "seller" ? <label style={{
                                          right: "1rem",
                                          top: "3rem"
                                        }} className='font-size-14 font-wt-600 text-color1 position-absolute'>{`Available quantity in inventory : ${data[`availableQuantity${j}`]} ${supplierCommodityList.find(item => { if (item.id === data[`commodity${j}`]) { return true } })?.['unit']}`}</label> : null}

                                      </div>
                                      <p className="text-primary font-size-15 font-wt-400">(Tolerance = +/- 10%)</p>
                                      {errorData[`quantity${j}`] && <p className="text-danger error-contract">{errorData[`quantity${j}`]}</p>}
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              </>
                            )
                          }
                        })}
                      </div>


                      <div className="row d-flex mt-5 mx-2">
                        <label className="col-md-6 font-size-13 font-wt-400 ">Total Amount = Commodity Price/unit x Quantity</label>
                        <div className="col-md-6 ">
                          <h4 className="text-color1 text-right font-size-18 font-wt-600" ><span >{data.currency ? (data.currency.split(':')[0]) : ''} </span>{Intl.NumberFormat('en-IN').format(getTotalPriceOfContract())}</h4>
                          <p className="text-color1 text-right font-size-18 font-wt-600" >{price_in_words(getTotalPriceOfContract())}<span >{data.currency ? (data.currency.split(':')[0]) : ''} </span></p>
                        </div>
                      </div>
                    </div>
                  </div> : null}

                {activeClass === 4 ? <div className="d-flex row px-4 py-4" >
                  <div className={"tab-content p-0 m-0" + (activeClass === 4 ? " is-active" : "")}>
                    <div className="">
                      {/* {miniPreview()} */}
                      {/* <hr /> */}
                      <div className="modal-padding">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row form-group">
                              <label className="col-md-12 font-size-13 font-wt-500">Payment Mode {astrix} {toolTip2('paymentMode')}</label>
                              <div className="modal-padding row">
                                {
                                  getPaymentOptionslist()
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* //---------------------------------------------- */}
                        {/* add bank Component */}
                        {addBankObj.modal && <AddBankComponent />}
                        {/* //---------------------------------------------- */}


                        {/* <div className="row" id="Bank1">
                        <div className="col-md-12 my-3">
                          <div className="row">
                            <div className="col-md-4">
                              <MultipleSelect
                                Label={`Importer's Bank`}
                                Id={`buyerBank_select`}
                                optiondata={buyerBank}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `buyerBank`, "id"))
                                }}
                                value={data[`buyerBank`] ? [data[`buyerBank`]] : []}
                                name={`buyerBank`}
                                labelKey={"company_name"}
                                valKey={"id"}
                                error={errorData[`buyerBank`]}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="modal-padding"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" id="Bank1">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <MultipleSelect
                                Label={`Exporter's Bank`}
                                Id={`supplierBank_select`}
                                optiondata={sellerBank}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `supplierBank`, "id"))
                                }}
                                value={data[`supplierBank`] ? [data[`supplierBank`]] : []}
                                name={`supplierBank`}
                                labelKey={"company_name"}
                                valKey={"id"}
                                error={errorData[`supplierBank`]}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="modal-padding"></div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div> : null}

                {activeClass === 5 ? <div className="d-flex row p-4" >
                  <div className={"tab-content p-0 m-0" + (activeClass === 5 ? " is-active" : "")}>
                    <div className="">
                      {/* {miniPreview()}
                      <hr /> */}
                      {/* <h2>Contract TNC</h2> */}
                      {errorData.tnc && <p className="text-danger text-center font-size-13 font-wt-500">{errorData.tnc}</p>}
                      {(activeClass === 5) ? <TncForm
                        dataTnc={dataTnc}
                        setdataTnc={setdataTnc}
                        tncOrder={tncOrder}
                        settncOrder={settncOrder}
                        contMode={contractState.info.modalMode}
                        editCount={editCount}
                        setEditCount={setEditCount}
                        handleTNC={handleTNC} /> : ''}
                    </div>
                  </div>
                </div> : null}

                {activeClass === 6 && userTypeId / 1 == 19 ? <div className="d-flex row p-4" >
                  <div className={"tab-content p-0 m-0" + (activeClass === 6 ? " is-active" : "")}>
                    <div className="calc-inner-modal">
                      {miniPreview()}
                      <hr />
                      {activeClass === 6 ? <DocumentACL
                        userArray={getUserArray()}
                        docCategoryACL={docCategoryACL}
                        setdocCategoryACL={setdocCategoryACL}
                        contractId={data.contract_id ? data.contract_id : null}
                        contractNO={null}
                        dataObj={{ "exporter_id": data.exporter_id / 1, "importer_id": data.importer_id / 1, "importer_bank": data.buyerBank / 1, "exporter_bank": data.supplierBank / 1 }}
                        modalType={contractState.info.modalMode} /> : ""}
                    </div>
                  </div>
                </div> : null}

                {activeClass === ((userTypeId === 19) ? 7 : 6) ?
                  <div className="d-flex row p-4" >
                    {/* just to set doc acl array  */}
                    <DocumentACL
                      userArray={getUserArray()}
                      docCategoryACL={docCategoryACL}
                      setdocCategoryACL={setdocCategoryACL}
                      contractId={data.contract_id ? data.contract_id : null}
                      contractNO={null}
                      dataObj={{ "exporter_id": data.exporter_id / 1, "importer_id": data.importer_id / 1, "importer_bank": data.buyerBank / 1, "exporter_bank": data.supplierBank / 1 }}
                      modalType={contractState.info.modalMode} />

                    <div className="">
                      <div className={"accordionWrapper p-0" + (viewAccordion === 0 ? " open" : " closed")}>
                        <span className='font-size-13 font-wt-400' >Show/Hide</span><br />
                        <i style={{ fontSize: '1.5em' }} className={"mb-2 p-2 fa" + (viewAccordion === 0 ? " fa-minus-square-o fa-2x" : " fa-plus-square-o fa-2x")} aria-hidden="true" onClick={handleViewAccordion}></i>
                        {/*className={ "accordionItem" + (viewAccordion === 0? " open": " closed") }*/}
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading font-size-15 font-wt-500" >Contract info</h2>
                          <div className="accordionItemContent">
                            {(contractState.info.modalMode === "create") ? <div className="mr-auto pr-3 form-group">
                              <label className="col-md-12">
                                {(traderActiveType === 'buyer') ? "Seller/Exporter" : "Buyer/Importer"}
                              </label>
                              <div className="chat-message">
                                <img src={avatarUrl(supplierAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  {(traderActiveType === 'buyer') ? <>
                                    <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span>
                                    <br />
                                    <span className="date-format">  {data.supplierAddress ? data.supplierAddress : '--'}</span>
                                  </> :
                                    <>
                                      <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span>
                                      <br />
                                      <span className="date-format">  {data.buyerAddress ? data.buyerAddress : '--'}</span>
                                    </>}
                                </div>
                              </div>
                            </div> :
                              <div className="row">
                                <div className="col-md-6 ">
                                  <div className="mr-auto pr-3 form-group">
                                    <label className="col-md-12">Buyer/Importer</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(data.buyerAvatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span><br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="mr-auto pr-3 form-group">
                                    <label className="col-md-12">Seller/Exporter</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(data.supplierAvatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span><br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                            <hr />
                            <div className="row">
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{data.contractType} </label>
                                  <div className="col-md-12"><span className="date-format">Inco Terms</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{multComm.map((i, j) => {
                                    if (i === null && data[`commodity_name${j}`]) {
                                      return data[`commodity_name${j}`] + ", "
                                    }
                                    else if (i === null && data[`commodity${j}`]) {
                                      return supplierCommodityList.find(ii => {
                                        if (ii.id == data[`commodity${j}`]) {
                                          return true
                                        }
                                      })["commodity_pretty_name"] + ", "
                                    }
                                  })} </label>
                                  <div className="col-md-12"><span className="date-format">Commodity</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{data.laycanStart ? formatDate_Application(data.laycanStart) : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Laycan Start Date</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{data.laycanEnd ? formatDate_Application(data.laycanEnd) : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Laycan End Date</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    <span className="shadow">
                                      <ReactCountryFlag
                                        countryCode={data.loadingCountry || ''}
                                        style={{ width: '25px', height: '25px' }} svg />
                                    </span>{data.loadingPort ? data.loadingPort : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Loading Country</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    <span className="shadow">
                                      <ReactCountryFlag
                                        countryCode={data.unloadingCountry || ''}
                                        style={{ width: '25px', height: '25px' }} svg />
                                    </span>{data.unloadingPort ? data.unloadingPort : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">UnLoading Country</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading font-size-15 font-wt-500" > Specification</h2>
                          <div className="accordionItemContent p-0">
                            {multComm.map((i, j) => {
                              if (i === null && data[`commodity_name${j}`]) {
                                return <div>
                                  <label className='font-size-14 font-wt-500 mt-2 mx-4' >
                                    {data[`commodity_name${j}`]}</label>
                                  <SpecificationsPreview specification={spec[j]} specOrder={specOrder[j]} />
                                </div>
                              }
                              else if (i === null && data[`commodity${j}`]) {
                                return <div>
                                  <label className='font-size-14 font-wt-500 mt-2 mx-4' >
                                    {supplierCommodityList.find((l, m) => {
                                      if (l.id === data[`commodity${j}`]) {
                                        return true
                                      }
                                    })?.["commodity_pretty_name"]}</label>
                                  <SpecificationsPreview specification={spec[j]} specOrder={specOrder[j]} />
                                </div>
                              }
                            })}
                            {/* Commodity Preview Component Start */}
                            {/* {((data[`commodity${j}`] || data.commodity_id) && activeClass === 7) && <SpecificationsPreview specification={spec} specOrder={specOrder} />} */}
                            {/* Commodity Preview Component Start */}
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading font-size-15 font-wt-500" > Price &amp; Rate</h2>
                          <div className="accordionItemContent p-0">
                            {multComm.map((i, j) => {
                              if (i === null && (data[`commodity${j}`] || data[`commodity_name${j}`])) {
                                return <div className="d-flex divider  ">
                                  <div className=" mx-4  ">
                                    <label style={{ marginLeft: '-1rem' }} className='font-size-14 font-wt-500 mt-2' >
                                      {data[`commodity_name${j}`] ? data[`commodity_name${j}`] : supplierCommodityList.find((l, m) => {
                                        if (l.id === data[`commodity${j}`]) {
                                          return true
                                        }
                                      })?.["commodity_pretty_name"]}</label>
                                    <div className="row mt-2">
                                      <div className="col-md-4 p-0 m-0">
                                        <div className="row form-group">
                                          <label className="col-md-12">{data[`priceType${j}`] / 1 === 1 ? "Fixed price" : data[`priceType${j}`] / 1 === 2 ? "Index Price" : "--"} </label>
                                          <div className="col-md-12">
                                            <span className="date-format">Price Type</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 p-0 m-0">
                                        <div className="row form-group">
                                          <label className="col-md-12">{data[`price${j}`] ? (data[`price${j}`] + " " + data[`currency`]) : 0} </label>
                                          <div className="col-md-12">
                                            <span className="date-format"> {data[`priceType${j}`] / 1 === 1 ? "Commodity Price" : data[`priceType${j}`] / 1 === 2 ? "Current Index Price" : "--"}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 p-0 m-0">
                                        <div className="row form-group">
                                          <label className="col-md-12">{data[`quantity${j}`] ? data[`quantity${j}`] : '--'}</label>
                                          <div className="col-md-12"> <span className="date-format">Quantity</span></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            })}
                            <div className="col-md-4 px-4 m-0 mt-2">
                              <div className="row form-group">
                                <label className="col-md-12">{getTotalPriceOfContract()} {data.currency.split(":")[0]}</label>
                                <div className="col-md-12">
                                  <span className="date-format">Total Amount = Commodity Price/unit x Quantity</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" >Investigation Agency</h2>
                        <div className="accordionItemContent">
                          <div className="d-flex divider">
                            <div className="modal-padding">
                              <div className="row">
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12"> {getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Importer's Investigation Agency</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Exporter's Investigation Agency</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0 m-0">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('investigationAgencythird', data.investigationAgencythird)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Third Party Investigation Agency</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                        {/* <div className="accordionItem">
                        <h2 className="accordionItemHeading font-size-15 font-wt-500" >Insurance Agency</h2>
                        <div className="accordionItemContent">
                          <div className="d-flex divider">
                            <div className="modal-padding">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('commodityInsurer', data.commodityInsurer)} </label>
                                    <div className="col-md-12">
                                      <span className="date-format">Commodity Insurer</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{getSelectName('tradeInsurer', data.tradeInsurer)}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">Trade Insurer</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                        <div className="accordionItem">
                          <h2 className="accordionItemHeading font-size-15 font-wt-500" >Payment </h2>
                          <div className="accordionItemContent">
                            <div className="d-flex divider">
                              <div className="modal-padding">
                                <div className="row" id="Bank1">
                                  <div className="col-md-6 p-0 m-0">
                                    <div className="row form-group">
                                      <label className="col-md-12 p-0 m-0">
                                        {(paymentModes && paymentModes.length) ? paymentModes.map((value) => {
                                          if (value.id === (data.paymentMode / 1)) {
                                            return value.payment_type_pretty
                                          }
                                        }) : ''}
                                      </label>
                                      <div className="col-md-12 p-0 m-0">
                                        <span className="date-format">Payment Mode</span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-4 p-0 m-0">
                                    <div className="row form-group">
                                      <label className="col-md-12 p-0 m-0">{buyerBank.filter((i) => {
                                        if (i.id / 1 === data.buyerBank / 1) {
                                          return true
                                        }
                                      })?.[0]?.company_name || "---"}</label>
                                      <div className="col-md-12 p-0 m-0">
                                        <span className="date-format">Importer's Bank</span>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-4 p-0 m-0">
                                    <div className="row form-group">
                                      <label className="col-md-12">{
                                        sellerBank.filter((i) => {
                                          if (i.id / 1 === data.supplierBank / 1) {
                                            return true
                                          }
                                        })?.[0]?.company_name || "---"
                                      }</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Exporter's Bank</span>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading font-size-15 font-wt-500" > Terms and Conditions</h2>
                          <div className="accordionItemContent">
                            {(activeClass === ((userTypeId === 19) ? 7 : 8)) ? tncPreviewGenerator() : ''}
                          </div>
                        </div>

                      </div>
                    </div>

                  </div> : null}

                {(activeClass < 7 && userTypeId / 1 == 19) || (userTypeId / 1 != 19 && activeClass < 6) || contractState.info.modalMode === "create" ?
                  <div className='px-1 pb-4 w-50'>
                    <div
                      className="d-flex finActionTabContainer mx-4 my-4 " >
                      {(!isCompactContract && activeClass / 1 != 7) || (isCompactContract && activeClass / 1 == 1) ? <div
                        onClick={() => {
                          if (isCompactContract && activeClass / 1 == 1) {
                            const buttonElement = document.getElementById('extraDetailsForLimitContinueBtn');
                            if (buttonElement) {
                              buttonElement.click();
                            }
                          }
                          else {
                            handleNext()
                          }
                        }}
                        style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                        className={`w-50 text-center py-0 cursor bg-5CB8D3 `} >
                        <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                      </div> : <div
                        onClick={() => { handleSubmit(); setshowLoader(true) }}
                        style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                        className={`w-50 text-center py-0 cursor bg-5CB8D3 `} >
                        <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                      </div>}
                      <div
                        onClick={() => {
                          if (activeClass > 1) {
                            setActiveClass(activeClass - 1)
                          }
                        }}
                        style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                        className={`position-relative w-50 text-center py-0 px-2 cursor ${activeClass === 1 ? ' disable-bg ' : " "}`} >
                        <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                      </div>
                      {/* <div
                      style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                      <label
                        onClick={() => { toastDisplay('Application saved as draft', "success"); setRefresh(refresh + 1) }}
                        className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 "} >Save as Draft</label>
                    </div> */}
                    </div>
                  </div> :
                  <div className="px-4 pb-4">
                    {(contractState.info.modalMode === "create") ? <>
                      {/* <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Edit</button> */}
                      <button type="button" className="new-btn text-white py-2 w-15" onClick={() => { handleSubmit(); setshowLoader(true) }} id="nextid">Submit</button>
                    </> :
                      <>
                        {/* <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button> */}
                        <div className='px-1 pb-4 w-75'>
                          <div
                            className="d-flex finActionTabContainer mx-4 my-4 " >

                            {(data.status_buyer === 1 || data.status_seller === 1) ? <>
                              {(data.edit_request / 1 === 0) ?
                                <>
                                  <div
                                    onClick={() => raiseContractEditRequest()}
                                    style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                                    className={`w-50 text-center py-0 cursor bg-5CB8D3 `} >
                                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Request Edit</label>
                                  </div>
                                  <div
                                    onClick={() => { setActiveClass(activeClass - 2) }}
                                    style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                                    className={`w-50 text-center py-0 cursor bg-white `} >
                                    <label className={"font-size-16 font-wt-700 text-color1 cursor d-flex justify-content-center align-items-center h-100"} >Previous</label>
                                  </div>
                                </>
                                : (data.edit_request / 1 === userId) ?
                                  <div
                                    style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                                    className={`w-50 text-center py-0 cursor bg-5CB8D3 `} >
                                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Edit Request Raised</label>
                                  </div> :
                                  <>
                                    <div
                                      onClick={() => { openContractEdit(false) }}
                                      style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                                      className={`w-50 text-center py-0 cursor bg-5CB8D3 `} >
                                      <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Accept Edit Request</label>
                                    </div>
                                    <div
                                      onClick={() => { setActiveClass(activeClass - 2) }}
                                      style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                                      className={`w-50 text-center py-0 cursor bg-white `} >
                                      <label className={"font-size-16 font-wt-700 text-color1 cursor d-flex justify-content-center align-items-center h-100"} >Previous</label>
                                    </div>
                                  </>
                              }
                            </>
                              : ""}

                            <div
                              onClick={() => {
                                setActiveClass(activeClass - 2)
                              }}
                              style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                              className={`position-relative w-50 text-center py-0 px-2 cursor ${activeClass === 1 ? ' disable-bg ' : " "}`} >
                              <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                            </div>
                            {(data.status_buyer === 0 && data.status_seller === 0) && <>
                              <div
                                onClick={() => { handleSubmitUpdate("U&A"); }}
                                style={{
                                  borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px",
                                  borderTopRightRadius: "8px"
                                }}
                                className={`w-50 text-center py-0 cursor bg-5CB8D3 `} >
                                <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Update & Agree</label>
                              </div> </>}

                            {((((userTypeId === 19 && traderActiveType === 'buyer')) && data.status_buyer === 0) ||
                              (((userTypeId === 19 && traderActiveType === 'seller')) && data.status_seller === 0)) && editCount === 0 &&
                              <div
                                onClick={() => { agreement(); }}
                                // style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                                className={`w-50 text-center py-0 cursor bg-white `} >
                                <label className={"font-size-16 font-wt-700 text-color1 cursor d-flex justify-content-center align-items-center h-100"} >Agree</label>
                              </div>}


                            {(data.status_buyer === 0 && data.status_seller === 0) &&
                              <div
                                onClick={() => { handleSubmitUpdate(""); }}
                                style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                                className={`w-50 text-center py-0 cursor bg-5CB8D3  `} >
                                <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Update</label>
                              </div>}
                          </div>
                        </div>
                      </>}
                  </div>}
              </div>}


          </main>
        </div>
      </div >
    </div >
  )


}

const mapStateToProps = state => {
  return {
    contractState: state.contract,
    clientType: state.clientType,
    addBankObj: state.addBankObj,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) },
    setClientType: (type) => { dispatch(setClientType({ type: type })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkOrderContract)