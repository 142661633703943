import React, { useState, useEffect, useRef } from "react";
import TypingAnimation from "./typingAnimation";
import ItemDetails from "./itemDetails";
import { blue } from "@mui/material/colors";

export default function View360({ allData, userId }) {
  const [recentSearch, setRecentSearch] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [filteredDropdownItems, setFilteredDropdownItems] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState('')
  const [selectedItem, setSelectedItem] = useState('');
  const [show360Home, setShow360Home] = useState(true);
  const [filter, setFilter] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredData, setFilteredData] = useState([])
  const [details, setDetails] = useState({
    AvgVal: 0,
    AvgRate: 0,
    AvgSku: 0,
    Invoices: 0,
    PinCode: 0,
    Customer: 0,
    Sku: 0,
    GrossSales: 0,
    GrossQty: 0,
    Gp: 0,
    GpPercentage: 0
  });
  const searchInputRef = useRef(null);
  const btns = ["Sales", "Profitability", "Exposure", "Invoices", "Width", "Depth"];
  const dataKeys = [
    {
      AvgVal: 0,
      Avg: 0,
      AvgSku: 0,
      Invoices: 0,
      PinCode: 0,
      Customer: 0,
      Sku: 0,
      GrossSales: 0,
      GrossQty: 0,
      Gp: 0,
      GpPercentage: 0
    }
  ];
  useEffect(() => {
    if (allData && allData.length) {
      const filtered = allData.filter(item => {
        return item[selectedLabel] === selectedItem
      });
      console.log("filtered basedon label,item", filtered)
      const groupdata = groupDataByMonthYear(filtered)
      console.log("inside Parent grouped data by month year", groupdata);
      setFilteredData(groupdata);


    }
  }, [allData, selectedItem, selectedLabel]);
  useEffect(() => {
    let savedSearches = localStorage.getItem('recentSearchesFor360View');
    if (savedSearches) {
      savedSearches = JSON.parse(savedSearches);
      setRecentSearch(savedSearches);
    } else {
      setRecentSearch([]);
    }
  }, []);

  useEffect(() => {
    // Transform allData to dropdown items
    const transformedItems = allData.flatMap(data => [
      { field: data.itemName, comingFrom: "itemName", forDropDown: "Item Name" },
      { field: data.state, comingFrom: "state", forDropDown: "State" },
      { field: data.country, comingFrom: "country", forDropDown: "Country" },
      { field: data.continent, comingFrom: "continent", forDropDown: "Continent" },
      { field: data.category, comingFrom: "category", forDropDown: "Category" },
      { field: data.customerGroup, comingFrom: "customerGroup", forDropDown: "Customer Group" },
      { field: data.itemGroup, comingFrom: "itemGroup", forDropDown: "Item Group" },
      { field: data.partyName, comingFrom: "partyName", forDropDown: "Party Name" }
    ]);

    const uniqueItems = Array.from(new Set(transformedItems.map(item => item.field)))
      .map(field => {
        return transformedItems.find(item => item.field === field);
      });

    setDropdownItems(uniqueItems);
    setFilteredDropdownItems(uniqueItems);
  }, [allData]);

  const handleSelectionInDropDown = (item) => {
    setShow360Home(false);
    setSelectedItem(item.field);
    setSelectedLabel(item.comingFrom);
    setRecentSearch(prev => {
      const updatedSearches = [item, ...prev.filter(i => i.field !== item.field)].slice(0, 5);
      localStorage.setItem('recentSearchesFor360View', JSON.stringify(updatedSearches));
      return updatedSearches;
    });
    setShowDropdown(false);
  };

  const handleRecentSearchClick = (item) => {
    setShow360Home(false);
    setSelectedItem(item.field);
    setSelectedLabel(item.comingFrom);
  };

  const handleGoBack = () => {
    setShow360Home(true);
    setSelectedItem('');
    setSelectedLabel('');
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);
    setFilteredDropdownItems(dropdownItems.filter(item => item.field.toLowerCase().includes(value)));
    setShowDropdown(true);
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };
  const groupDataByMonthYear = (data) => {
    const grouped = new Map();
    data.forEach(item => {
      const monthYear = new Date(item.date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      if (!grouped.has(monthYear)) {
        grouped.set(monthYear, []);
      }
      grouped.get(monthYear).push(item);
    });

    return Array.from(grouped, ([monthYear, data]) => ({ monthYear, data }));
  };
  useEffect(() => {
    updateDetailsForItemName(filteredData)
  }, [filteredData])
  const updateDetailsForItemName = (groupedData) => {
    console.log("clled it nm")
    if (groupedData.length > 0) {
      const detailsArray = groupedData.map(group => {
        const { monthYear, data } = group;
        const totalItems = data.length;
        const totalQuantity = data.reduce((acc, item) => acc + item.quantity, 0);
        const totalRate = data.reduce((acc, item) => acc + item.rate, 0);
        const totalGrossSales = data.reduce((acc, item) => acc + (item.quantity * item.rate), 0);
        const totalGp = data.reduce((acc, item) => acc + item.GP, 0);
        const totalSalesAmount = data.filter(item => item.type === "Sales").reduce((acc, item) => acc + item.totalAmount, 0);

        return {
          date: monthYear,
          AvgVal: parseFloat((totalSalesAmount / totalItems).toFixed(1)),
          AvgRate: parseFloat((totalRate / totalItems).toFixed(1)),
          AvgSku: parseFloat((totalQuantity / totalItems).toFixed(1)),
          Invoices: data.length,
          PinCode: 0, //calculate this based once data comes
          Customer: new Set(data.map(item => item.partyName)).size,
          Sku: totalQuantity,
          GrossSales: parseFloat(totalGrossSales.toFixed(1)),
          GrossQty: totalQuantity,
          Gp: parseFloat(totalGp.toFixed(1)),
          GpPercentage: totalGp ? (totalGp / totalSalesAmount).toFixed(1) : 0
        };
      });
      console.log(detailsArray)
      setDetails(detailsArray);
    }
  };


  return (
    <div className="w-100">
      {show360Home ? (
        <div className="d-flex justify-content-between align-items-center w-100 h-100">
          <div className="border border-secondary rounded p-4 h-100">
            <div style={{ textAlign: "center" }}>
              <div>
                <h1>360 °</h1>
                <p>Holistic View Of Your Business</p>
              </div>
              <h6 style={{ opacity: "0.7" }}>Type the name to inspect in a 360°</h6>
              <div style={{ minHeight: "30px" }}>
                <TypingAnimation />
              </div>
              <div className="mt-5">
                <div className="dropdown-search relative" style={{ width: '100%' }}>
                  <input
                    type="text"
                    className="form-control rounded-pill ps-5 fs-5"
                    placeholder="Search any variable"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={filter}
                    onChange={handleSearchChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={searchInputRef}
                    style={{ height: "50px" }}
                  />
                  {showDropdown && (
                    <div className="dropdown-menu show mt-0 absolute overflow-auto" style={{ width: '100%', maxHeight: "200px" }}>
                      {filteredDropdownItems.length ? filteredDropdownItems.map((item, index) => (
                        <div
                          key={index}
                          className="dropdown-item d-flex justify-content-between"
                          onMouseDown={() => { handleSelectionInDropDown(item) }}
                        >
                          <span style={{ color: blue[500] }}>{item.forDropDown}</span>
                          <span>{item.field}</span>
                        </div>
                      )) : <p className="text-danger dropdown-item" style={{ alignItems: "center" }}>No Variables to select</p>}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center" style={{ marginTop: "30%" }}>
              {btns.map(item => (
                <div key={item} className="p-3 me-2 bg-white rounded shadow d-flex justify-content-center align-items-center" style={{ width: "100px", height: "100px" }}>
                  <h6>{item}</h6>
                </div>
              ))}
            </div>
          </div>
          <div className="ms-2 w-50">
            <h4 className="text-primary">Recent Search</h4>
            <div className="overflow-auto w-100 h-50">
              {recentSearch && recentSearch.length ? recentSearch.map((item, index) => (
                <div
                  style={{ width: "100%", height: "40px" }}
                  className="p-1 ps-4 pe-4 mt-2 border border-secondary rounded cursor-pointer d-flex justify-content-between align-items-center "
                  onClick={() => { handleRecentSearchClick(item); }}
                >
                  <p key={index} className="m-0">
                    {item.field}
                  </p>
                  <p className="m-0">
                    <span style={{ color: blue[500] }}>{item.forDropDown}</span>
                  </p>
                </div>

              )) : null}
            </div>
          </div>
        </div>
      ) : (
        <ItemDetails data={allData} userId={userId} selectedItem={selectedItem} handleGoBack={handleGoBack} selectedLabel={selectedLabel} details={details} />
      )}
    </div>
  );
}
