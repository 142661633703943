import React from 'react'
import { FileInput } from '../../../utils/FileInput'
import { getPDFFromFile, isEmpty, most_used_currencies, printDiv } from '../../../utils/myFunctions'
import { NewInput, NewSelect } from '../../../utils/newInput'

export default function FinanceDetails({ setdetailsData, goBackAction, deleteDetails, detailsdata, handleChange, handleFile, isEditable, yearlist }) {
  return (
    <div className=''>
      <div className='d-flex justify-content-between'>
        <div>
          <img className='cursor' src={"/assets/images/ArrowBackLeft.png"} alt="" onClick={() => {
            setdetailsData({
              isVisible: false,
              data: null
            })
            goBackAction()
          }} />
        </div>
        <div className='d-flex '>
          <img src={"/assets/images/charm_download.png"} alt="" className='px-2 cursor' onClick={async () => {
            let pdfArr = []
            console.log(detailsdata.data)
            if (!isEmpty(detailsdata.data.auditDoc)) {
              pdfArr.push(await getPDFFromFile(detailsdata.data.auditDoc))
            }
            if (!isEmpty(detailsdata.data.GSTDoc)) {
              pdfArr.push(await getPDFFromFile(detailsdata.data.GSTDoc))

            }
            if (!isEmpty(detailsdata.data.ITRDoc)) {
              pdfArr.push(await getPDFFromFile(detailsdata.data.ITRDoc))

            }
            if (!isEmpty(detailsdata.data.DebDoc)) {
              pdfArr.push(await getPDFFromFile(detailsdata.data.DebDoc))

            }
            if (!isEmpty(detailsdata.data.CredDoc)) {
              pdfArr.push(await getPDFFromFile(detailsdata.data.CredDoc))

            }
            console.log('pdfarrrr', pdfArr)
            printDiv("financeDetails", `Finance-${detailsdata.data.year}`, pdfArr)
          }} />
          {deleteDetails && <img src={"/assets/images/deleteIcon.png"} alt="" className='px-2 cursor' onClick={deleteDetails} />}
        </div>
      </div>
      <div className="row p-3" id="financeDetails">
        <div className="col">
          <NewInput isAstrix={false} label={"Year"}
            selectData={yearlist} name={"selectedYear"}
            value={detailsdata.data.year} optionLabel={"name"} optionValue={'sortname'}
            onChange={handleChange} />
        </div>
        <div className="col">
          <NewInput isAstrix={false} label={"Currency"}
            selectData={most_used_currencies} name={"currencyType"}
            value={detailsdata.data.currency} optionLabel={"name"} optionValue={'code'}
            onChange={handleChange} />
        </div>
        <div className="col">
          <NewInput isAstrix={false} type={"number"} label={"Turnover"}
            name={"turnover"} value={detailsdata.data.turnover}
            onChange={handleChange} />
        </div>
        <div className="col">
          <NewInput isAstrix={false} type={"number"} label={"Net profit"}
            name={"netprofit"} value={detailsdata.data.netprofit}
            onChange={handleChange} />
        </div>
        <div className="col">
          <NewInput isAstrix={false} type={"number"} label={"Net Worth"}
            name={"networth"} value={detailsdata.data.networth}
            onChange={handleChange} />
        </div>
      </div>

      <label className='pl-2 font-size-14'><u>Documents</u></label>
      <div className="row">
        <div className='col'>
          <label className='pl-2 font-size-14'>Audit Report</label>
          <FileInput name={"auditDoc"} value={detailsdata.data.auditDoc} isEditable={isEditable} />
        </div>
        <div className='col'>
          <label className='pl-2 font-size-14'>GST Document</label>
          <FileInput name={"GSTDoc"} value={detailsdata.data.GSTDoc} isEditable={isEditable} />
        </div>
      </div>
      <div className="row py-5">
        <div className='col'>
          <label className='pl-2 font-size-14'>ITR Document</label>
          <FileInput name={"ITRDoc"} value={detailsdata.data.ITRDoc} isEditable={isEditable} />
        </div>
        <div className='col'>
          <label className='pl-2 font-size-14'>Debators Statement</label>
          <FileInput name={"DebDoc"} value={detailsdata.data.DebDoc} isEditable={isEditable} />
        </div>
      </div>
      <div className="row">
        <div className='col'>
          <label className='pl-2 font-size-14'>Creditors Document</label>
          <FileInput name={"CredDoc"} value={detailsdata.data.CredDoc} isEditable={isEditable} />
        </div>
        <div className='col'>
        </div>
      </div>
    </div>
  )
}
