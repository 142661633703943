import React from "react";

const AccordionCommon = ({ children, Heading, AccorHeading, AccorCollapse, accorshow }) => {


  return (
    <>
      <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
        <div className="accordion-item financeacc mb-3 ">
          <h2 className="accordion-header" id={AccorHeading}>
            <button className="accordion-button shadow-none bg-transparent border-0 font-size-14 font-wt-600 collapsed text-decoration-underline letter-spacing05" type="button" data-bs-toggle="collapse" data-bs-target={`#${AccorCollapse}`} aria-expanded="true" aria-controls={AccorCollapse}>
              {Heading}
            </button>

          </h2>
          <div id={AccorCollapse} className={`accordion-collapse collapse ${accorshow}`} aria-labelledby={AccorHeading} data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
export default AccordionCommon;