import React, { useEffect } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { NewInput, NewSelect, NewTextArea } from '../../utils/newInput'
import CustomBarChart from './components/CustomBarChart'
import CustomAreaChart from './components/CustomAreaChart'
import Filter from '../InvoiceDiscounting/components/Filter'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import { useState } from 'react'
import moment from 'moment'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import CalendarTab from './components/CalendarTab'
import call from '../../service'
import { NewTable } from '../../utils/newTable'
import BottomPopup from '../TaskManager/BottomPopup'
import toastDisplay from '../../utils/toastNotification'
import { ExportExcel, getContactObject, isEmpty } from '../../utils/myFunctions'
import { reminders } from '../chatRoom/components/calenderView'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
const LogsArr = [
  { name: "Contacts", val: 'Create New Task', color: 'text-color1', count: 0 },
  { name: "Lead Created", val: 'Didnt connect', color: 'color3DB16F', count: 0, status: 1 },
  { name: "Lost", val: 'Call back', color: 'colorFE4141', count: 0, status: 3 },
  { name: "Supplier Onboarded", val: 'Not Interested', color: 'text-color1', count: 0, status: 4 },
]
let logsObj = {
  'total_contacts': { name: "Contacts", val: 'Create New Task', color: 'text-color1' },
  4: { name: "Supplier Onboarded", val: 'Not Interested', color: 'text-color1' },
  3: {
    name: "Lost", val: 'Call back', color: 'colorFE4141'
  },
  1: { name: "Lead Created", val: 'Didnt connect', color: 'color3DB16F' }
}
const bardataConfig = [
  { dataKey: "int_count", fill: "#2ECC71", display: 'Interested' },
  { dataKey: "others_count", fill: "#AED8FF", display: 'Others' },
  { dataKey: "not_interested", fill: "#FF7B6D", display: 'Not Interested' },
]
const shipmentdetails = [
  { "name": "Date", val: "DATE", type: 'date' },
  { "name": "Amount", val: "FOB_VALUE_USD", unit: "$", type: 'amount' },
  { "name": "Location", val: "DESTINATION_PORT" },
  { "name": "Buyer", val: "CONSIGNEE_NAME" }

]
const label = {
  "int_count": "Interested",
  "others_count": "Others",
  "not_interested": "Not Interested"
}


const CRMDashboard = ({ userTokenDetails, dateRange, subadmins }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [data, setdata] = useState({})
  const [errors, setErrors] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [stats, setStats] = useState(LogsArr)
  const [overalldata, setoveralldata] = useState([])
  const [salesPerson, setSalesPerson] = useState([])
  const [notePopup, toggleNotePopup] = useState({ show: false, data: "", selectedIndex: null, noteFor: "" })
  const [graphConfiguration, setGraphConfiguration] = useState({
    taskGraphDuration: "6 Months",
    leadGraphDuration: "6 Months"
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableExpand, setTableExpand] = useState("");
  const [expandedData, setExapndedData] = useState([]);
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const userId = userTokenDetails?.user_id
  const userName = userTokenDetails?.userName
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState({
    data: null,
    isVisible: false
  });
  const [isOpenDidntRec, setisOpenDidntRec] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [isOpenCallback, setisOpenCallback] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [isOpenNotInt, setisOpenNotInt] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [isOpenLost, setisOpenLost] = useState({
    isVisible: false,
    selectedIndex: 0
  })

  const [isMinimized, setISMinimized] = useState(false)
  const [closeLeadPopup, setcloseLeadPopup] = useState(false)
  const [closeEventName, setcloseEventName] = useState('')
  const [contactsPopup, togglecontactsPopup] = useState({ show: false, data: [] })
  const [countriesPopup, togglecountriesPopup] = useState({ show: false, data: [] })
  const [taskgraph, setTaskgraph] = useState([])
  const [taskgraphoverall, setTaskGraphoverall] = useState([])
  const [leadsGraph, setLeadsGraph] = useState([])
  const [taskdate, onChangeTaskdate] = useState(new Date());
  const [calendardata, setCalendardata] = useState([])
  const [tableConfiguration, settableConfiguration] = useState({
    fromDate: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    toDate: todayDateObj.clone().format("YYYY-MM-DD"),
  })
  const [taskdue, settasksdue] = useState([])
  const [taskcreated, setTaskCreated] = useState([])
  const [TasksSelect, setTasksSelect] = useState('all')
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setdata({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const handleAccordianClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  useEffect(() => {
    getCRMDashboardStats()
  }, [subadmins])

  const getXAxisDateFormat = (data, value) => {
    let countForMonths = moment(dateRange.to).diff(dateRange.from, 'month')
    let dateFormat = ''
    if (countForMonths > 3) {
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths <= 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }
  useEffect(() => {
    let overalldata = []
    if (TasksSelect === 'all') {
      overalldata = taskgraphoverall
    } else {
      let keysincluded = ["xLabel"]
      keysincluded.push(TasksSelect)
      overalldata = taskgraphoverall?.map(obj => {
        const newObj = {};
        keysincluded.forEach(key => {
          newObj[key] = obj[key];
        });
        return newObj;
      }) || []
    }
    console.log('userOnboardSelect', overalldata);
    setTaskgraph(overalldata)
  }, [TasksSelect])
  const getCRMDashboardStats = () => {
    setShowLoader(true)
    let finaldata = []
    let objectAPI = {
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getCRMDashboardStats', objectAPI).then(result => {
      for (let i = 0; i <= LogsArr.length - 1; i++) {
        if (i === 0) {
          const totalContactsObj = result.find(data => data.total_contacts)
          console.log('dasdadwsaadsdsa', totalContactsObj);
          finaldata.push({
            name: "Contacts",
            val: 'Create New Task',
            color: 'text-color1',
            count: totalContactsObj?.total_contacts || 0
          })
        } else {
          const element = result.find(item => item.status === LogsArr[i].status)
          finaldata.push({
            ...LogsArr[i],
            count: element?.total_count || 0
          })
        }
      }
      setStats(finaldata)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    console.log('SalesPersoon', salesPerson)
    data.forEach((item, index) => {
      let mappedData = getContactObject(item.EXTRA_DETAILS ? item.EXTRA_DETAILS : [])

      row[0] = item.EXPORTER_NAME
      row[1] = mappedData ? mappedData['Contact Person'] ? mappedData['Contact Person'] : 'NA' : 'NA'
      row[2] = mappedData ? mappedData['Department'] ? mappedData['Department'] : 'NA' : 'NA'
      row[3] = <div onClick={() => handlecontactsPopup(item)} className='cursor'>
        {mappedData ? mappedData['Contact Number'] ? mappedData['Contact Number'] : 'NA' : 'NA'}
      </div>
      row[4] = item.FOB ? "$ " + Intl.NumberFormat("en", { notation: 'compact' }).format(item.FOB) : ''
      row[5] = <ul className='py-0 pl-3 cursor' onClick={() => handleCountriesPOPUP(item)} >
        {item?.TOP_COUNTRIES?.slice(0, 2)?.map(item => {
          return <li >
            <div>
              {item.destination_country}
            </div>
          </li>
        })}
      </ul>
      row[6] = item.LAST_NOTE ? item.LAST_NOTE : 'NA'
      row[7] = <div className='d-flex flex-row justify-content-between gap-3'>
        <img src='assets/images/createTask.svg' title='Create New Task' className='cursor' onClick={() => {
          setIsOpen({
            isVisible: true,
            data: item
          })
        }} />
        <img src='assets/images/didntconnect.svg' title="Call Didn't Connect" className='cursor' onClick={() => {
          setisOpenDidntRec({
            isVisible: true,
            selectedIndex: index
          })
        }} />
        <img src='assets/images/callback.svg' title='Call back requested' className='cursor' onClick={() => {
          setisOpenCallback({
            isVisible: true,
            selectedIndex: index
          })
        }} />
        <img src='assets/images/not_intrested.svg' title='Not Interested' className='cursor' onClick={() => {
          setisOpenNotInt({
            isVisible: true,
            selectedIndex: index
          })
        }} />
        <img src='assets/images/marked_as_lost.svg' title='Marked as Lost Lead' className='cursor' onClick={() => {
          setisOpenLost({
            isVisible: true,
            selectedIndex: index
          })
        }} />
      </div>

      tableData.push(row)
      row = []
    })
    return tableData
  }
  useEffect(() => {
    if (isOpen.isVisible) {
      console.log('data', isOpen);
      expandedTable(isOpen?.data, isOpen.data?.EXPORTER_NAME, isOpen.data?.EXPORTER_CODE)
    } else {
      expandedTableFN()
    }
  }, [isOpen])
  const expandedTableFN = () => {
    setTableExpand("");
    setExapndedData([]);
    //handleClose()
  }
  const expandedTable = (data, id, EXPORTER_NAME) => {
    setShowLoader(true)
    call('POST', 'getExporterExtraDetails', { EXPORTER_CODE: EXPORTER_NAME }).then(result => {
      setExapndedData([{
        ...data,
        ...result
      }])
      setShowLoader(false)
      setTableExpand(id);
    }).catch(e => {
      setShowLoader(false)
    })

  }
  async function handlecontactsPopup(itemData) {
    setShowLoader(true)
    console.log("getTransactionHistoryForLC api resp====>", itemData);
    setShowLoader(false)
    togglecontactsPopup({ show: true, data: itemData.EXTRA_DETAILS?.filter(item => item['Contact Number']) })
  }

  async function handleCountriesPOPUP(itemData) {
    setShowLoader(true)
    let apiResp = await call('POST', 'getTopCountries', {
      EXPORTER_CODE: itemData.EXPORTER_CODE
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setShowLoader(false)
    togglecountriesPopup({ show: true, data: apiResp })
  }
  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin || userPermissionsForSubAdmin?.["Assign Task"]) {
      setShowLoader(true)
      call("POST", 'getSubAdminUser', {}).then(res => {
        setShowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setShowLoader(false))
    }
  }, [])
  const getTaskGraphs = () => {
    setShowLoader(true)
    let objectAPI = {
      from: dateRange.from,
      to: dateRange.to,
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getTaskGraphs', objectAPI).then(result => {
      setTaskgraph(result)
      setTaskGraphoverall(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  const getLeadsGraphs = () => {
    setShowLoader(true)
    let objectAPI = {
      from: dateRange.from,
      to: dateRange.to,
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getLeadsGraphs', objectAPI).then(result => {
      setLeadsGraph(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  useEffect(() => {
    getTaskGraphs()
    getLeadsGraphs()
  }, [dateRange, subadmins])

  const getTasksBydate = () => {
    setShowLoader(true)
    let objectAPI = {
      eventdate: moment(taskdate).format('YYYY-MM-DD'),
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getTasksBydate', objectAPI).then(result => {
      setCalendardata(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  useEffect(() => {
    getTasksBydate()
  }, [taskdate, subadmins])
  const getTasks = () => {
    setShowLoader(true)
    let objectAPI = {
      currentPage: page,
      ...filter,
      resultPerPage: filter.resultPerPage,
      included_status: [0, 1, 2, 3],
      taskType: 'Call List'

    }
    if (!userPermissionsForSubAdmin?.mainAdmin) {
      objectAPI["onlyShowForUserId"] = userId
    }
    objectAPI["dateRangeFilter"] = [dateRange.from, dateRange.to]
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "leadAssignedTo" ||
                element.accordianId === "leadAssignmentStatus" ||
                element.accordianId === "leadStatus" || element.accordianId === "applicationStatus") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        } else if (element.type === 'singleDate') {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getAdminTasks', objectAPI)
      .then((result) => {
        setdbData(formatDataForTable(result.message))
        setCount(result.total_count)
        setoveralldata(result.message)
        setShowLoader(false)
      }).catch(e => {
        setShowLoader(false)
      })
  }
  useEffect(() => {
    // let isCacheExist = localStorage.getItem('taskManagerFilterData') != "{}"
    // if (!isCacheExist) {
    setShowLoader(true)
    let objectAPI = {
      ...filter,
      included_status: [1],
    }
    if (!userPermissionsForSubAdmin?.mainAdmin) {
      objectAPI["onlyShowForUserId"] = userId
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(i[element["labelName"]])
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        } else if (element.type === 'singleDate') {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getCallListFilters', objectAPI).then(res => {
      console.log("getCallListFilters then", res);
      setFilterData(res)
      setShowLoader(false)
    }).catch(err => {
      setShowLoader(false)
    })
  }, [filter, refresh, subadmins])
  useEffect(() => {
    getTasks()
  }, [page, refresh, salesPerson, filterData, subadmins])
  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }
  const handleTableConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    settableConfiguration({ ...tableConfiguration, [event.target.name]: event.target.value })
  }
  const getTasksCount = () => {
    setShowLoader(true)
    let objectAPI = {
      fromDate: dateRange.from,
      toDate: dateRange.to,
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getTasksCount', objectAPI).then(result => {
      settasksdue(formatDataForDueTable(result.taskDue))
      setTaskCreated(formatDataForCreatedTable(result.taskCreated))
      setShowLoader(false)

    }).catch(e => {
      console.log('Error in api', e);
      setShowLoader(false)

    })
  }
  useEffect(() => {
    getTasksCount()
  }, [dateRange])
  function formatDataForDueTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.total_count_due ? item.total_count_due : 0
      row[1] = item.hot_count_due ? item.hot_count_due : 0
      row[2] = item.warm_count_due ? item.warm_count_due : 0
      row[3] = item.cold_count_due ? item.cold_count_due : 0
      row[4] = item.pending_count_due ? item.pending_count_due : 0
      tableData.push(row)
      row = []
    })
    return tableData
  }
  function formatDataForCreatedTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.total_count ? item.total_count : 0
      row[1] = item.hot_count ? item.hot_count : 0
      row[2] = item.warm_count ? item.warm_count : 0
      row[3] = item.cold_count ? item.cold_count : 0
      row[4] = item.not_int_count ? item.not_int_count : 0
      row[5] = item.lost_count ? item.lost_count : 0
      row[6] = item.pending_count ? item.pending_count : 0
      tableData.push(row)
      row = []
    })
    return tableData
  }
  const handleClose = () => {
    setIsOpen({
      data: {},
      isVisible: false
    });
    setisOpenDidntRec({
      isVisible: false,
      selectedIndex: 0
    })
    setisOpenCallback({
      isVisible: false,
      selectedIndex: 0
    })
    setisOpenNotInt({
      isVisible: false,
      selectedIndex: 0
    })
    setisOpenLost({
      isVisible: false,
      selectedIndex: 0
    })
  };
  const updateCRMTask = (LOG_TYPE, index, type) => {
    let error = {}
    if (LOG_TYPE !== 'Lead Lost') {
      if (!data.event_status) {
        error.event_status = 'Mandatory Field'
      }
    }

    if (LOG_TYPE === 'Create New Task' || LOG_TYPE === 'Lead Created') {
      if (!data.event_date) {
        error.event_date = 'Mandatory Field'
      }
      if (!data.event_time) {
        error.event_time = 'Mandatory Field'
      }
      if (!data.reminder) {
        error.reminder = 'Mandatory Field'
      }
      if (!data.event_type) {
        error.event_type = 'Mandatory Field'
      }
      if (!data.assignedTo) {
        error.assignedTo = 'Mandatory Field'
      }
    } else if (LOG_TYPE === 'Didnt connect') {
      if (!data.assignedTo) {
        error.assignedTo = 'Mandatory Field'
      }
    } else if (LOG_TYPE === 'Call back') {
      if (!data.event_date) {
        error.event_date = 'Mandatory Field'
      }
      if (!data.event_time) {
        error.event_time = 'Mandatory Field'
      }
      if (!data.reminder) {
        error.reminder = 'Mandatory Field'
      }
      if (!data.assignedTo) {
        error.assignedTo = 'Mandatory Field'
      }
    } else if (LOG_TYPE === 'Not Interested') {
      if (!data.event_date) {
        error.event_date = 'Mandatory Field'
      }
      if (!data.assignedTo) {
        error.assignedTo = 'Mandatory Field'
      }
    }
    else if (LOG_TYPE === 'Lead Lost') {
      if (!data.reasonForLost) {
        error.reasonForLost = 'Mandatory Field'
      }
      if (!data.event_type) {
        error.event_type = 'Mandatory Field'
      }
    }
    if (type === 'closed') {
      error = {}
    }
    if (isEmpty(error)) {
      setShowLoader(true)
      let assignedObj = salesPerson.find(item => item.id == data.assignedTo) || {}
      let reqObj = {
        EVENT_TYPE: data.event_type,
        EVENT_STATUS: type === 'closed' ? "Call" : data.event_status,
        EVENT_TIME: data.event_date && data.event_time ? new Date(`${data.event_date} ${data.event_time}`).toISOString() : '',
        REMINDER: data.reminder,
        REMARK: data.remark ? data.remark : '',
        ASSIGN_TASK_TO: {
          id: assignedObj.id,
          contact_person: assignedObj.contact_person,
          name_title: assignedObj.name_title,
          designation: assignedObj.designation,
          email_id: assignedObj.email_id
        },
        LOG_TYPE,
        LOST_REASON: type === 'closed' ? "Lead Not interested" : data.reasonForLost,
        ADMIN_ID: userId,
        ADMIN_NAME: userName
      }
      if (LOG_TYPE === 'Create New Task' || LOG_TYPE === 'Lead Created' || type === 'closed') {
        reqObj["EXPORTER_CODE"] = expandedData[0]?.EXPORTER_CODE
        reqObj["EXPORTER_NAME"] = expandedData[0]?.EXPORTER_NAME
      } else {
        reqObj["EXPORTER_CODE"] = overalldata[index]?.EXPORTER_CODE
        reqObj["EXPORTER_NAME"] = overalldata[index]?.EXPORTER_NAME
      }
      call('POST', 'updateCRMTask', reqObj).then(result => {
        toastDisplay(result, 'success')
        setShowLoader(false)
        handleClose()
        getTasks()
        setdata({})
        setcloseLeadPopup(false)
      }).catch(e => {
        toastDisplay(e, 'error')
        setShowLoader(false)
      })
    } else {
      setErrors(error)
    }

  }
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {closeLeadPopup && <FinanceInvoiceModal headerTitle={'Close Task'} isCentered={true} limitinvoice={closeLeadPopup} closeSuccess={() => setcloseLeadPopup()}>
        <>
          <div className='d-flex align-items-center flex-column'>
            <div className='text-center col-md-5 mt-3'>
              <div className='d-flex flex-row px-2' onClick={() => setcloseEventName('User Onboarded')}>
                <input className="form-check-input" type="radio" value={"User Onboarded"} checked={closeEventName === 'User Onboarded'} />
                <label className="form-check-label p-0 m-0" >
                  User Onboarded
                </label>
              </div>
            </div>

            <div className='text-center col-md-5 mt-3'>
              <div className='d-flex flex-row px-2' onClick={() => setcloseEventName('Lead Lost')}>
                <input className="form-check-input" type="radio" value={"Lead Lost"} checked={closeEventName === 'Lead Lost'} />
                <label className="form-check-label p-0 m-0" >
                  Lead Lost
                </label>
              </div>
            </div>
          </div>

          <button className={` new-btn py-2 mt-4 px-4 text-white cursor`} onClick={() => closeEventName === '' ? toastDisplay("Type Not Selected", "info") : updateCRMTask(closeEventName, null, 'closed')}>Close Task</button>

        </>
      </FinanceInvoiceModal>}
      <div>
        <div className={`modal fade ${notePopup.show && "show"}`} style={notePopup.show ? { display: "block", "zIndex": '100001' } : {}}>
          <div className="modal-dialog modal-md mr-0 my-0">
            <div className="modal-content submitmodal pb-4"
            >

              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >{`Add Note for ${notePopup.noteFor}`}</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleNotePopup({ show: false, data: "", selectedIndex: null })}></button>
                  </div>
                </div>
              </div>

              <div className="modal-body px-4">
                <div className="col-md-12 px-0">
                  <div className="">
                    <NewTextArea
                      rows={6}
                      type={"text"} label={`Write Note`} name={"note"}
                      value={notePopup.data}
                      onChange={(e) => {
                        toggleNotePopup({ ...notePopup, data: e.target.value })
                      }}
                    />
                  </div>
                </div>

                <button type="button"
                  onClick={async () => {
                    setShowLoader(true)
                    let temp = overalldata[notePopup.selectedIndex]
                    // console.log("temp==========================>", overalldata, notePopup);
                    let req = { adminNote: notePopup.data, id: temp.EXPORTER_CODE }

                    call('POST', 'addNoteForCallList', req).then(res => {
                      setShowLoader(false)
                      toastDisplay(res, "success")
                      setRefresh(refresh + 1)
                      toggleNotePopup({ show: false, data: "", selectedIndex: null })
                    }).catch(err => {
                      setShowLoader(false)
                      toastDisplay("Something went wrong", "error")
                    })
                  }}
                  className={`new-btn w-20 py-2 mt-2 px-2 text-white enablesigncontract text-white `}>
                  {"Submit"}
                </button>

              </div>
            </div>
          </div>
        </div>
        <div className={`modal fade ${contactsPopup.show && "show"}`} style={contactsPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
          <div className="modal-dialog modal-md mr-0 my-0">
            <div className="modal-content submitmodal p-0"
            >

              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >Contact Details</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => togglecontactsPopup({ show: false, data: [] })}></button>
                  </div>
                </div>
              </div>

              <div className="modal-body p-0 ">
                <div className='px-4'>
                  {contactsPopup.data.length ? contactsPopup.data.map((item, index) => {
                    return (
                      <div className='d-flex flex-row ml-3'>
                        <div className="progressBarContainer">
                          <div className="progressBarInnerCircle">
                          </div>
                        </div>
                        <div className='pl-4 pt-3'>
                          <p className='font-size-14 text-color1 font-wt-500 mb-0'>
                            {item['Contact Person'] ? item['Contact Person'] : 'NA'}
                            <span className='font-size-14 text-color-label font-wt-500 mb-0'>{` (${item.Department}) `}</span>
                          </p>
                          <p className='font-size-14 text-color-label font-wt-500 mb-0 cursor' onClick={() => {
                            if (item['Contact Number']) {
                              window.open(`tel:${item['Contact Number']}`, '_top');
                            }

                          }}>{item['Contact Number'] ? item['Contact Number'] : '-'}</p>
                        </div>
                      </div>
                    )
                  }) :
                    null}
                </div>
                {/* <button className={`new-btn-r-0 w-100 mt-4 py-2 px-2 text-white cursor`} onClick={() => { }}>Add Contact Details</button> */}

              </div>

            </div>
          </div>
        </div>

        <div className={`modal fade ${countriesPopup.show && "show"}`} style={countriesPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
          <div className="modal-dialog modal-md mr-0 my-0">
            <div className="modal-content submitmodal pb-4"
            >

              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >Top Countries BY FOB</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => togglecountriesPopup({ show: false, data: [] })}></button>
                  </div>
                </div>
              </div>

              <div className="modal-body px-4">
                {countriesPopup.data.length ? countriesPopup.data.map((item, index) => {
                  return (
                    <div className='d-flex flex-row ml-3'>
                      <div className="progressBarContainer2">
                        <div className="progressBarInnerCircle">
                        </div>
                      </div>
                      <div className='pl-4 pt-4 mt-2'>
                        <p className='font-size-14 text-color1 font-wt-500 mb-0'>
                          {item['_id'] ? item['_id'] : 'NA'}
                          <span className='font-size-14 text-color-label font-wt-500 mb-0'>{` ${item.total_shipments} `}</span>
                          <span><img src='assets/images/arrow.png' className='cursor' onClick={() => handleAccordianClick(index)} /></span>
                        </p>
                        {activeIndex === index &&
                          <div>
                            <p className='mb-0 font-size-14'>Buyers</p>
                            <ol type="1" className='py-0 pl-3 cursor' onClick={() => handleCountriesPOPUP(item)} style={{ listStyle: 'decimal' }}>
                              {item?.top_buyers?.map(item => {
                                return <li >
                                  <div className='font-size-14'>
                                    {item.buyer_name + " - " + item.shipment_count}
                                  </div>
                                </li>
                              })}
                            </ol>
                          </div>
                        }
                      </div>
                    </div>
                  )
                }) :
                  null}
              </div>

            </div>
          </div>
        </div>

        {isOpen.isVisible &&
          <BottomPopup isOpen={isOpen.isVisible} onClose={handleClose}>
            <>
              <div className='d-flex flex-row align-items-center gap-3 justify-content-between'>
                {/* <p className='font-size-16 text-color1 font-wt-600 mb-0'>Create Task</p> */}
                <button className={` new-btn py-2 px-3 text-white cursor`} onClick={() => updateCRMTask('Create New Task', null)}>Save Task</button>
                <button className={` new-btn2 py-2 px-3 text-color1 cursor`} onClick={() => { updateCRMTask('Lead Created', null) }}>Add to Lead</button>
                <p className='font-size-16 text-color1 font-wt-600 mb-0 text-decoration-underline cursor' onClick={() => {
                  setcloseLeadPopup(true)
                  setcloseEventName('')
                }}>Close lead</p>
                <img src='assets/images/arrow.png' className='cursor' onClick={() => setISMinimized(!isMinimized)} style={isMinimized ? { transform: "rotate(180deg)" } : {}} />
                <img src='assets/images/cross.png' className='cursor' onClick={handleClose} />
              </div>
              {!isMinimized &&
                <div>
                  <div className="col p-0 mt-4">
                    <div className="col-md-12 px-0">
                      <NewInput isAstrix={true} type={"text"} label={"Name"}
                        name={"exporter_name"} isDisabled={true}
                        value={expandedData[0] ? expandedData[0].EXPORTER_NAME : '-'}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <NewSelect
                        selectData={[{ "label": "Call" }, { "label": "Offline Meet" }, { "label": "Online Meet" }]}
                        optionLabel={'label'} optionValue={'label'}
                        name={"event_type"} label={'Type'}
                        value={data.event_type} onChange={handleChange} error={errors.event_type}
                      />
                    </div>
                    <div className='col-md-6'>
                      <NewSelect
                        selectData={[{ "label": "Hot (30 days or less)" }, { "label": "Warm (30-60 days)" }, { "label": "Cold (60 days or more)" }]}
                        optionLabel={'label'} optionValue={'label'}
                        name={"event_status"} label={'Status'}
                        value={data.event_status} onChange={handleChange} error={errors.event_status}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-4">
                      <div className="col-md-12 px-0">
                        <NewInput isAstrix={true} type={"date"} label={"Date"}
                          name={"event_date"}
                          value={data.event_date} error={errors.event_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-12 px-0">
                        <NewInput isAstrix={true} type={"time"} label={"Time"}
                          name={"event_time"}
                          value={data.event_time} error={errors.event_time}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <NewSelect
                        selectData={reminders} name={"reminder"}
                        optionLabel={'name'} optionValue={'name'}
                        label={'Reminder (before)'} error={errors.reminder}
                        value={data.reminder} onChange={handleChange}
                      />
                    </div>

                  </div>
                  <div className="col-md-12 p-0">
                    <NewTextArea
                      rows={6}
                      type={"text"} label={`Remark`} name={"remark"}
                      value={data.remark} error={errors.remark}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col p-0 ">
                    <div className="col-md-12 px-0">
                      <NewSelect
                        selectData={salesPerson}
                        optionLabel={'contact_person'} optionValue={'id'}
                        name={"assignedTo"} label={"Assign Task to"}
                        value={data.assignedTo} onChange={handleChange} error={errors.assignedTo}
                      />
                    </div>
                  </div>
                </div>
              }

            </>
          </BottomPopup>
        }

        {isOpenDidntRec.isVisible &&
          <BottomPopup isOpen={isOpenDidntRec.isVisible} onClose={() => setisOpenDidntRec({ isVisible: false, selectedIndex: 0 })}>
            <>
              <div className='d-flex flex-row align-items-center gap-3 justify-content-between'>
                <p className='font-size-16 text-color1 font-wt-600 mb-0'>Create Task <span className='text-color-767676 font-size-12'>(didn't connect)</span></p>
                <button className={` new-btn2 py-2 px-3 text-color1 cursor`} onClick={() => updateCRMTask('Didnt connect', isOpenDidntRec.selectedIndex)}>Save Task</button>
                <img src='assets/images/arrow.png' className='cursor' onClick={() => setISMinimized(!isMinimized)} style={isMinimized ? { transform: "rotate(180deg)" } : {}} />
                <img src='assets/images/cross.png' className='cursor' onClick={() => setisOpenDidntRec({ isVisible: false, selectedIndex: 0 })} />
              </div>
              {!isMinimized &&
                <div>
                  <div className="col p-0 mt-4">
                    <div className="col-md-12 px-0">
                      <NewSelect
                        selectData={[{ "label": "Busy" }, { "label": "Not Reachable" }, { "label": "Other" }]}
                        optionLabel={'label'} optionValue={'label'}
                        name={"event_status"} label={'Current Call Status'}
                        value={data.event_status} onChange={handleChange} error={errors.event_status}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <NewTextArea
                      rows={6}
                      type={"text"} label={`Remark`} name={"remark"}
                      value={data.remark} error={errors.remark}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col p-0 ">
                    <div className="col-md-12 px-0">
                      <NewSelect
                        selectData={salesPerson}
                        optionLabel={'contact_person'} optionValue={'id'}
                        name={"assignedTo"} label={"Assign Task to"}
                        value={data.assignedTo} onChange={handleChange} error={errors.assignedTo}
                      />
                    </div>
                  </div>

                </div>
              }

            </>
          </BottomPopup>
        }

        {isOpenCallback.isVisible &&
          <BottomPopup isOpen={isOpenCallback.isVisible} onClose={() => setisOpenCallback({ isVisible: false, selectedIndex: 0 })}>
            <>
              <div className='d-flex flex-row align-items-center gap-3 justify-content-between'>
                <p className='font-size-16 text-color1 font-wt-600 mb-0'>Create Task <span className='text-color-767676 font-size-12'>(Call back)</span></p>
                <button className={` new-btn2 py-2 px-3 text-color1 cursor`} onClick={() => updateCRMTask('Call back', isOpenCallback.selectedIndex)}>Save Task</button>
                <img src='assets/images/arrow.png' className='cursor' onClick={() => setISMinimized(!isMinimized)} style={isMinimized ? { transform: "rotate(180deg)" } : {}} />
                <img src='assets/images/cross.png' className='cursor' onClick={() => setisOpenCallback({ isVisible: false, selectedIndex: 0 })} />
              </div>
              {!isMinimized &&
                <div>
                  <div className="col p-0 mt-4">
                    <div className="col-md-12 px-0">
                      <NewSelect
                        selectData={[{ "label": "Busy" }, { "label": "Not Reachable" }, { "label": "Other" }]}
                        optionLabel={'label'} optionValue={'label'}
                        name={"event_status"} label={'Current Call Status'}
                        value={data.event_status} onChange={handleChange} error={errors.event_status}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-4">
                      <div className="col-md-12 px-0">
                        <NewInput isAstrix={true} type={"date"} label={"Date"}
                          name={"event_date"}
                          value={data.event_date} error={errors.event_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-12 px-0">
                        <NewInput isAstrix={true} type={"time"} label={"Time"}
                          name={"event_time"}
                          value={data.event_time} error={errors.event_time}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <NewSelect
                        selectData={reminders} name={"reminder"}
                        optionLabel={'name'} optionValue={'name'}
                        label={'Reminder (before)'} error={errors.reminder}
                        value={data.reminder} onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <NewTextArea
                      rows={6}
                      type={"text"} label={`Remark`} name={"remark"}
                      value={data.remark} error={errors.remark}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col p-0 ">
                    <div className="col-md-12 px-0">
                      <NewSelect
                        selectData={salesPerson}
                        optionLabel={'contact_person'} optionValue={'id'}
                        name={"assignedTo"} label={"Assign Task to"}
                        value={data.assignedTo} onChange={handleChange} error={errors.assignedTo}
                      />
                    </div>
                  </div>

                </div>
              }

            </>
          </BottomPopup>
        }

        {isOpenNotInt.isVisible &&
          <BottomPopup isOpen={isOpenNotInt.isVisible} onClose={() => setisOpenNotInt({ isVisible: false, selectedIndex: 0 })}>
            <>
              <div className='d-flex flex-row align-items-center gap-3 justify-content-between'>
                <p className='font-size-16 text-color1 font-wt-600 mb-0'>Create Task <span className='text-color-767676 font-size-12'>(Not Interested)</span></p>
                <button className={` new-btn2 py-2 px-3 text-color1 cursor`} onClick={() => updateCRMTask('Not Interested', isOpenNotInt.selectedIndex)}>Save Task</button>
                <img src='assets/images/arrow.png' className='cursor' onClick={() => setISMinimized(!isMinimized)} style={isMinimized ? { transform: "rotate(180deg)" } : {}} />
                <img src='assets/images/cross.png' className='cursor' onClick={() => setisOpenNotInt({ isVisible: false, selectedIndex: 0 })} />
              </div>
              {!isMinimized &&
                <div>
                  <div className='row'>
                    <div className="col-md-8  mt-4">
                      <div className="col-md-12 px-0">
                        <NewSelect
                          selectData={[{ "label": "Busy" }, { "label": "Not Reachable" }, { "label": "Other" }]}
                          optionLabel={'label'} optionValue={'label'}
                          name={"event_status"} label={'Current Call Status'}
                          value={data.event_status} onChange={handleChange} error={errors.event_status}
                        />
                      </div>
                    </div>
                    <div className="col-md-4  mt-4">
                      <div className="col-md-12 px-0">
                        <NewInput isAstrix={true} type={"date"} label={"Date"}
                          name={"event_date"}
                          value={data.event_date} error={errors.event_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0">
                    <NewTextArea
                      rows={6}
                      type={"text"} label={`Remark`} name={"remark"}
                      value={data.remark} error={errors.remark}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col p-0 ">
                    <div className="col-md-12 px-0">
                      <NewSelect
                        selectData={salesPerson}
                        optionLabel={'contact_person'} optionValue={'id'}
                        name={"assignedTo"} label={"Assign Task to"}
                        value={data.assignedTo} onChange={handleChange} error={errors.assignedTo}
                      />
                    </div>
                  </div>

                </div>
              }

            </>
          </BottomPopup>
        }

        {isOpenLost.isVisible &&
          <BottomPopup isOpen={isOpenLost.isVisible} onClose={() => setisOpenLost({ isVisible: false, selectedIndex: 0 })}>
            <>
              <div className='d-flex flex-row align-items-center gap-3 justify-content-between'>
                <p className='font-size-16 text-color1 font-wt-600 mb-0'>Lost</p>
                <button className={`new-btn-reject2 py-2 px-3 text-color-E74C3C cursor`} onClick={() => updateCRMTask('Lead Lost', isOpenLost.selectedIndex)}>Save Task</button>
                <img src='assets/images/arrow.png' className='cursor' onClick={() => setISMinimized(!isMinimized)} style={isMinimized ? { transform: "rotate(180deg)" } : {}} />
                <img src='assets/images/cross.png' className='cursor' onClick={() => setisOpenLost({ isVisible: false, selectedIndex: 0 })} />
              </div>
              {!isMinimized &&
                <div>
                  <div className='row'>
                    <div className="col-md-6  mt-4">
                      <div className="col-md-12 px-0">
                        <NewSelect
                          selectData={[{ "label": "Busy" }, { "label": "Not Reachable" }, { "label": "Other" }]}
                          optionLabel={'label'} optionValue={'label'}
                          name={"reasonForLost"} label={'Reason for lost'}
                          value={data.reasonForLost} onChange={handleChange} error={errors.reasonForLost}
                        />
                      </div>
                    </div>
                    <div className="col-md-6  mt-4">
                      <div className="col-md-12 px-0">
                        <NewSelect
                          selectData={[{ "label": "Call" }, { "label": "Offline Meet" }, { "label": "Online Meet" }]}
                          optionLabel={'label'} optionValue={'label'}
                          name={"event_type"} label={'Contact Mode'}
                          value={data.event_type} onChange={handleChange} error={errors.event_type}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0">
                    <NewTextArea
                      rows={6}
                      type={"text"} label={`Remark`} name={"remark"}
                      value={data.remark} error={errors.remark}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              }

            </>
          </BottomPopup>
        }
        <div className='col-md-8'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-around'>
            <div className='row px-0 w-100'>
              {stats.map((item, index) => {
                return <div className={`w-25 cursor ${index !== 0 ? 'pl-4' : ''} ${index === LogsArr.length - 1 ? '' : 'border-right'} `}
                >
                  <label className={`value font-wt-600  w-100 cursor ${item.color}`}>
                    {item.count || 0}
                  </label>
                  <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{item.name}</label>
                </div>
              })}

            </div>
          </div>
        </div>
        <div className='row pl-4 mt-4 gap-4'>
          <div className='card h-75 w-55 dashboard-card shadow-sm '>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor text-color1' onClick={() => setTasksSelect('all')}>Task</label>

                  </div>

                  <div className='d-flex flex-row align-items-center gap-2'>
                    <div className="d-flex gap-2">
                      <label className="paymentsdots mb-0 cursor" onClick={() => setTasksSelect('int_count')}><div className='greendot' /> Intersted </label>
                      <label className="paymentsdots mb-0 cursor" onClick={() => setTasksSelect('others_count')}><div className='bluedot' /> Others </label>
                      <label className="paymentsdots mb-0 cursor" onClick={() => setTasksSelect('not_interested')}><div className='reddot' /> Not Intersted </label>

                    </div>
                    <div className='d-flex flex-row align-items-center'>
                      <img src='assets/images/downloadBu.svg' height={20} width={20} className='cursor' onClick={() => ExportExcel(taskgraph, 'Task_graph_data')} />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div>
              <CustomBarChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskGraphDuration, value)} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={taskgraph} xDataKey={"xLabel"} />
            </div>

          </div>
          <div className='card h-75 w-40 dashboard-card shadow-sm'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0  text-color1' >Lead Created</label>

                  </div>
                  <div className='d-flex flex-row align-items-center gap-2'>

                    <div className='d-flex flex-row align-items-center'>
                      <img src='assets/images/downloadBu.svg' height={20} width={20} className='cursor' onClick={() => ExportExcel(leadsGraph, 'Leads_graph_data')} />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <CustomAreaChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.leadGraphDuration, value)} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={leadsGraph} xDataKey={"xLabel"} dataKey={"lead_count"} />

          </div>
        </div>
        <div className='card p-4 h-100 dashboard-card border-0 borderRadius ml-3 my-4'>
          <div className='align-items-center d-flex justify-content-between'>
            <div className='w-auto pr-3'>
              <label className='text-color-value font-size-14 font-wt-600'>Task Update</label>
            </div>
            {/* <div className='d-flex'>
              <div className='pr-3'>
                <NewInput type={"date"} name={"fromDate"}
                  value={tableConfiguration.fromDate}
                  onChange={handleTableConfigurationChange}
                />
              </div>
              <div className='pr-3'>
                <NewInput type={"date"} name={"toDate"}
                  value={tableConfiguration.toDate}
                  onChange={handleTableConfigurationChange}
                />
              </div>
            </div> */}

          </div>
          <div className='row '>
            <div className='col-md-5'>
              <label className='text-center font-wt-600 w-100 border py-2 mb-0'>Task Due</label>
              <NewTable
                disableAction={true}
                columns={[{ name: "Total" }, { name: "Hot" }, { name: "Warm" },
                { name: "Cold" }, { name: "Pending" }]}
                data={taskdue}
              />
            </div>
            <div className='col-md-7'>
              <label className='text-center font-wt-600 w-100 border py-2 mb-0'>Task Created</label>
              <NewTable
                disableAction={true}
                columns={[{ name: "Total" }, { name: "Hot" }, { name: "Warm" },
                { name: "Cold" }, { name: "Not Interested" }, { name: "Lost" }, { name: "Pending" }]}
                data={taskcreated}
              />
            </div>
          </div>
        </div>
        <div className='card h-75 dashboard-card shadow-sm my-3 ml-3'>
          <div className="filter-div ml-0 mt-1">
            <Filter
              filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
              showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
          </div>

          <div className="mb-3">
            <ExpandableTable
              tableFixed data={dbData}
              columns={[
                { name: "Company Name", filter: true, width: '11%' },
                { name: "Director Name", filter: true, width: '11%' },
                { name: "Designation", filter: true, width: '11%' },
                { name: "Contact Number", filter: true, width: '11%' },
                { name: "FOB", filter: true, width: '6%' },
                { name: "Top Countries", filter: true, width: '11%' },
                { name: "Last Note", filter: true, width: '15%' },
                { name: "Action", width: '15%' }
              ]}
              overalldata={overalldata}
              expand={expandedData}
              tableExpand={tableExpand}
              expandKey={"EXPORTER_NAME"}
              options={
                [
                  {
                    name: "Add Note", icon: "edit.png", onClick: (index) => {
                      let temp = overalldata[index]
                      toggleNotePopup({ show: true, data: temp.invoiceLimitLeadNote || temp.lcLimitLeadNote || temp.LAST_NOTE, selectedIndex: index, noteFor: temp.EXPORTER_NAME })
                    }
                  }
                ]
              }
            >
              {expandedData.length === 1 &&
                <div className='row'>
                  <div className='col-md-4'>
                    <p className="text-decoration-underline font-size-12 font-wt-700">Recent 3 shipments</p>
                    {expandedData[0].Recent_Shipments?.map((data, index) => {
                      return <div className={`${index != expandedData[0]?.Recent_Shipments?.length - 1 ? 'border-bottom' : ''} py-2`}>
                        {
                          shipmentdetails.map((item, i) => {
                            return (
                              <div className="col">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-2 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col px-0 shareHolderValue" > {data[item.val] ? (item.unit ? item.unit : '') + (item.type === 'date' ? moment(data[item.val]).format('DD/MM/YYYY') : item.type === 'amount' ? Intl.NumberFormat("en", { notation: 'compact' }).format(data[item.val]) : data[item.val]) : "NA"}</span> </p>
                              </div>
                            );
                          })
                        }
                      </div>
                    })}
                  </div>
                  <div className='col-md-4'>
                    <p className="text-decoration-underline font-size-12 font-wt-700">Top 3 shipments</p>
                    {expandedData[0].TOP_Shipments?.map((data, index) => {
                      return <div className={`${index != expandedData[0]?.TOP_Shipments?.length - 1 ? 'border-bottom' : ''} py-2`}>
                        {
                          shipmentdetails.map((item, i) => {
                            return (
                              <div className="col">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-2 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col px-0 shareHolderValue" > {data[item.val] ? (item.unit ? item.unit : '') + (item.type === 'date' ? moment(data[item.val]).format('DD/MM/YYYY') : item.type === 'amount' ? Intl.NumberFormat("en", { notation: 'compact' }).format(data[item.val]) : data[item.val]) : "NA"}</span> </p>
                              </div>
                            );
                          })
                        }
                      </div>
                    })}
                  </div>
                  <div className='col-md-4'>
                    <p className="text-decoration-underline font-size-12 font-wt-700">Top 3 buyers and shipments</p>
                    <ul className='py-0 pl-3'>
                      {expandedData[0]?.TOP3_Buyers?.map(item => {
                        return <li >
                          <div className='font-size-14' >
                            {item._id + " - " + item.count}
                          </div>
                        </li>
                      })}
                    </ul>

                    <p className="text-decoration-underline font-size-12 font-wt-700">Other details</p>
                    <div className="col">
                      <p className="d-flex d-flex align-items-top mb-2">
                        <span className="col-md-3 px-0 BuyerdetailsLabel">{"Total Shipments"}</span>
                        <span className="mx-3">:</span>
                        <span className="col px-0 shareHolderValue" >{expandedData?.[0]?.TOTAL_SHIPMENTS}</span> </p>
                    </div>
                    <div className="col">
                      <p className="d-flex d-flex align-items-top mb-2">
                        <span className="col-md-3 px-0 BuyerdetailsLabel">{"Products"}</span>
                        <span className="mx-3">:</span>
                        <span className="col px-0 font-size-14" >
                          <ul className='py-0 pl-3'>
                            {expandedData?.[0]?.PRODUCTS?.map(item => {
                              return <li >
                                <div >
                                  {item._id}
                                </div>
                              </li>
                            })}
                          </ul>
                        </span>
                      </p>
                    </div>

                    <p className="text-decoration-underline font-size-12 font-wt-700">Company details</p>
                    <div className="col">
                      <p className="d-flex d-flex align-items-top mb-2">
                        <span className="col-md-3 px-0 BuyerdetailsLabel">{"Address"}</span>
                        <span className="mx-3">:</span>
                        <span className="col px-0 font-size-14"> {expandedData?.[0].EXPORTER_ADDRESS}</span>
                      </p>
                    </div>

                    <div className="col">
                      <p className="d-flex d-flex align-items-top mb-2">
                        <span className="col-md-3 px-0 BuyerdetailsLabel">{"Email ID"}</span>
                        <span className="mx-3">:</span>
                        <span className="col px-0 font-size-14"> {expandedData?.[0].EXTRA_DETAILS[0]?.["Email ID"]}</span>
                      </p>
                    </div>

                  </div>
                </div>
              }

            </ExpandableTable>

          </div>
          <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
        </div>
        <div className='card h-75 dashboard-card shadow-sm my-3 ml-3'>
          <CalendarTab value={taskdate} onChange={onChangeTaskdate} calendardata={calendardata} />
        </div>
      </div>
    </>
  )
}

export default CRMDashboard