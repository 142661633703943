import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import { InputForTable, InputWithSelectForTable, NewInput, NewSelect, NewTextArea, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import { most_used_currencies, multiSelectEventHandler, printDiv } from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import SignDocument, { signFonts } from "../InvoiceDiscounting/components/SignDocument";
import ReactCountryFlag from "react-country-flag";
import MultipleSelect from "../../utils/MultipleSelect";

const invoiceTemplateTypes = [
  { name: 'E-Invoice' },
  { name: 'Proforma Invoice' }
]

let supplyTypes = [
  { name: 'Business to Business', code: 'B2B' },
  { name: 'SEZ with payment', code: 'SEZWP' },
  { name: 'SEZ without payment', code: 'SEZWOP' },
  { name: 'Export with Payment', code: 'EXPWP' },
  { name: 'Export without payment', code: 'EXPWOP' },
  { name: 'Deemed Export', code: 'DEXP' }
]

let invItemsTable = [{ subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
{ subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '30%' } },
{ subColumns: "HSN NO.", subColumnStyle: { width: '10%' } },
{ subColumns: "QUANTITY", subColumnStyle: { width: '10%' } },
{ subColumns: "UNIT PRICE", subColumnStyle: { width: '12%' } },
{ subColumns: "TAX AMOUNT", subColumnStyle: { width: '12%' } },
{ subColumns: "TOTAL PRICE", subColumnStyle: { width: '15%' } }
]

let chargesTable = [{ subColumns: "CHARGE TITLE", subColumnStyle: { width: '45%' } }, { subColumns: "AMOUNT", subColumnStyle: { width: '45%' } }]

const CommercialInvoice = ({ userTokenDetails, navToggleState, selectedInvoiceData }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({ show: false, data: { type: 'E-Invoice' } })
  const [data, setData] = useState({
    regRev: true, vehicleType: 'R'
  })
  const [error, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [invOther, setInvOther] = useState([null, null, null, null])
  const [preview, setPreview] = useState({})
  const [signdoc, setSigndoc] = useState(false);
  const [states, setStates] = useState([])
  const [units, setUnits] = useState([])
  const [dbData, setDbData] = useState([])
  const [dbDataFilter, setDbDataFilter] = useState([])
  const [ShipperList, setShipperList] = useState([])
  const [ShipperListFilter, setShipperListFilter] = useState([])
  const [banks, setBanks] = useState([])
  const [banksFilter, setBanksFilter] = useState([])

  let jj = -1

  useEffect(() => {
    if (states.length) {
      fetchExporterDetails()
      getBuyerDetails()
      getShippers()
      getBanks()
    }
  }, [states])

  const getBanks = async () => {
    setshowLoader(true)
    call('POST', "getUserBanks", { email: userEmail, inProfile: true }).then(bankApiResp => {
      setshowLoader(false)
      setBanks(bankApiResp?.message?.length ? bankApiResp?.message : [])
      setBanksFilter(bankApiResp?.message?.length ? bankApiResp?.message : [])
    }).catch(err => {
      setshowLoader(false)
    })
  }

  const getShippers = async () => {
    setshowLoader(true)
    call('POST', "getShippers", {}).then(shipperApiResp => {
      setShipperList(shipperApiResp?.length ? shipperApiResp : [])
      setShipperListFilter(shipperApiResp?.length ? shipperApiResp : [])
    }).catch(err => {
      setshowLoader(false)
    })
  }

  async function fetchExporterDetails() {
    setshowLoader(true)
    call('POST', 'getUserDetailsById', { contactUserId: userId }).then(res => {
      setshowLoader(false)
      let userDetails = res?.[0]
      let webtelState = states.filter(i => { return i.StateName.toLowerCase() == userDetails?.company_state?.toLowerCase() })[0]?.Code
      // console.log("userDetailssssssssssssssssssss", userDetails, webtelState, states[0]);
      setData({
        ...data, exporterLegalName: userDetails?.company_name, exporterTradeName: userDetails?.company_name, exporterGstNo: userDetails?.gst_vat_no,
        exporterEmailId: userDetails?.email_id, exporterContactNo: userDetails?.contact_number, originAddress1: userDetails?.user_address,
        exporterLocation: userDetails?.company_city, exporterPinCode: userDetails?.company_postal_code, exporterState: webtelState,
        displayName: userDetails?.company_name, displayAddress1: userDetails?.user_address, displayLocation: userDetails?.company_city,
        displayPinCode: userDetails?.company_postal_code, displayState: webtelState
      })
    }).catch(err => {
      setshowLoader(false)
    })
  }

  const getBuyerDetails = () => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyerDetailsV2', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      let tempResultBuyerData = result.buyerData
      for (let index = 0; index < tempResultBuyerData.length; index++) {
        tempResultBuyerData[index][`buyerNameHtml`] = <div>
          <label className="font-size-12 font-wt-500" >{`${tempResultBuyerData[index]["buyerName"]}`}
            <ReactCountryFlag
              countryCode={tempResultBuyerData[index].buyerCountry}
              style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
          </label>
        </div>
      }
      setDbData([...tempResultBuyerData]);
      setDbDataFilter([...tempResultBuyerData]);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
  }

  useEffect(() => {
    if (selectedInvoiceData?.docData) {
      setData(selectedInvoiceData.docData?.data)
      setInvItems(selectedInvoiceData.docData?.invItems)
      setInvOther(selectedInvoiceData.docData.invOther)
    }
    call('POST', 'getWebtelStatesMaster').then(res => {
      setStates(res)
    })
    call('POST', 'getWebtelUnitsMaster').then(res => {
      setUnits(res)
    })
  }, [])

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountrys(result.data.message);
      }
    });
  }, []);

  async function handleChange(e) {
    e.persist()
    if (e.target.name.includes('selectedBuyer') && e.target.value) {
      let buyerDetails = dbData.filter(i => { return i.id / 1 == e.target.value / 1 })?.[0]
      let buyersApiDetails = JSON.parse(buyerDetails?.buyersAPIDetail || "{}")
      setData({
        ...data, [e.target.name]: e.target.value, cosigneeLegalName: buyerDetails?.buyerName,
        consigneeTradeName: buyerDetails?.buyerName, consigneeEmailId: buyerDetails?.buyerEmail,
        consigneeContactNo: buyerDetails?.buyerPhone, consigneeAddress1: buyerDetails?.buyerAddress,
        consigneeLocation: buyersApiDetails?.address?.city, consigneePinCode: buyersApiDetails?.address?.postalCode,
        consigneePos: buyerDetails?.buyerCountry != "IN" ? 99 : ""
      })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else if (e.target.name.includes('selectedShipper') && e.target.value) {
      let shipperDetails = ShipperList.filter(i => { return i.id / 1 == e.target.value / 1 })?.[0]
      let webtelState = states.filter(i => { return i.StateName.toLowerCase() == shipperDetails?.company_state?.toLowerCase() })[0]?.Code
      setData({
        ...data, [e.target.name]: e.target.value, shipperLegalName: shipperDetails?.name,
        shipperTradeName: shipperDetails?.name, shipperGstNo: shipperDetails?.gst_vat_no,
        shipperAddress1: shipperDetails?.user_address, shipperLocation: shipperDetails?.company_city,
        shipperPinCode: shipperDetails?.company_postal_code, shipperState: webtelState
      })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else if (e.target.name.includes('selectedBank') && e.target.value) {
      let bankDetails = banks.filter(i => { return i.id / 1 == e.target.value / 1 })?.[0]
      setData({
        ...data, [e.target.name]: e.target.value, payeeName: bankDetails?.beneficiaryAccountName,
        payeeBankAccNo: bankDetails?.beneficiaryAccountNo, payeeIFSCCode: bankDetails?.beneficiaryIfscCode,

      })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value })
      setErrors({ ...error, [e.target.name]: "" })
    }
  }

  // useEffect(() => {
  //   if (data?.commInvoice?.name) {
  //     saveCommInvoice()
  //   }
  // }, [data.commInvoice])

  // async function saveCommInvoice() {
  //   setshowLoader(true)
  //   let formData = new FormData()
  //   formData.append("userId", userId)
  //   formData.append("docType", "invoice")
  //   formData.append("docTemplate", "E-Invoice")
  //   formData.append("docName", `E-Invoice - ${data.invoiceNo}`)
  //   formData.append('docData', JSON.stringify({ data, invItems, invOther }))
  //   formData.append("doc", data.commInvoice)
  //   if (selectedInvoiceData?.id) {
  //     formData.append("updateDocId", selectedInvoiceData.id)
  //   }
  //   await call('POST', 'saveEdoc', formData)
  //   setshowLoader(false)
  //   toastDisplay("Document downloaded & saved", "success")
  // }

  async function handleValidation() {
    let err = {}
    let validateFiels = ["exporterLegalName", "exporterGstNo", "exporterEmailId", "exporterContactNo", "originAddress1",
      "exporterLocation", "exporterPinCode", "exporterState", "cosigneeLegalName", "consigneeGstNo",
      "consigneeEmailId", "consigneeContactNo", "consigneePos", "consigneeAddress1", "consigneeLocation", "consigneePinCode",
      "consigneeState", "displayName", "displayAddress1", "displayLocation", "displayPinCode", "displayState",
      "shipperLegalName", "shipperGstNo", "shipperAddress1", "shipperLocation", "shipperPinCode", "shipperState",
      "supplierType", "nicUsername", "nicPassword", "invoiceNo", "invoiceDate", "totalAssessableVal", "finalInvoiceVal"]
    for (let index = 0; index < validateFiels.length; index++) {
      const element = validateFiels[index];
      if (!data[element]) {
        err[element] = "Mandatory field"
      }
    }
    for (let index = 0; index < invItems.length; index++) {
      const element = invItems[index];
      if (element === null && data[`hsnCode${index}`]) {
        let validateItem = ["hsnCode", "itemUnitPrice", "itemTotalAmount", "itemGstRate", "itemTotalValue", "itemUnit", "itemQuantity"]
        for (let j = 0; j < validateItem.length; j++) {
          const element2 = validateItem[j];
          if (!data[`${element2}${index}`]) {
            err[`${element2}${index}`] = 'Mandatory Field'
          }
        }
      }
    }
    if (!Object.keys(err).length) {
      setshowLoader(true)
      call("POST", 'generateIRN', { data, invItems, userId }).then(res => {
        setshowLoader(false)
        toastDisplay("E-Invoice Generated Successfully", "success", () => {
          window.location.reload()
        })
      }).catch(err => {
        setshowLoader(false)
        toastDisplay(err, "error")
      })
    }
    else {
      toastDisplay("Form validation error", "error")
    }
    setErrors({ ...err })
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredBuyers = []
    let filteredShippers = []
    let filteredBanks = []
    if (name.includes("buyerName")) {
      if (typedInput) {
        filteredBuyers = dbData.filter((i) => {
          if (i.buyerName.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBuyers = dbData
      }
      setDbDataFilter([...filteredBuyers])
    }
    if (name.includes("name")) {
      if (typedInput) {
        filteredShippers = ShipperList.filter((i) => {
          if (i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredShippers = ShipperList
      }
      setShipperListFilter([...filteredShippers])
    }
    if (name.includes("beneficiaryBankName")) {
      if (typedInput) {
        filteredBanks = banks.filter((i) => {
          if (i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBanks = banks
      }
      setBanksFilter([...filteredBanks])
    }
  }

  return (
    <>
      {signdoc && <SignDocument onlyReturnSign={true} setSigndoc={setSigndoc}
        setUpdatedDoc={(signDetails) => {
          setData({ ...data, invSign: signDetails })
        }} />
      }
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='card mt-1 pt-4' >
        <div className="p-4" >
          <label className="font-size-16 font-wt-600 w-100 text-center mb-3" >Fill Below Details to Generate E-Invoice</label>
          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Seller/Exporter Details
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix
                        label={"Enter Legal Name"}
                        isDisabled={preview.show} name={"exporterLegalName"} value={data.exporterLegalName}
                        error={error.exporterLegalName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Enter Trade Name'} isDisabled={preview.show} name={"exporterTradeName"} value={data.exporterTradeName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix label={'Enter GST No'} isDisabled={preview.show} name={"exporterGstNo"} value={data.exporterGstNo} error={error.exporterGstNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={'Enter Email Id'} name={"exporterEmailId"} value={data.exporterEmailId} error={error.exporterEmailId} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={'Enter Contact Number'} name={"exporterContactNo"} value={data.exporterContactNo} error={error.exporterContactNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isAstrix isDisabled={preview.show} label={"Enter Address Line 1"} name={"originAddress1"} value={data.originAddress1} error={error.originAddress1} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isDisabled={preview.show} label={"Enter Address Line 2"} name={"originAddress2"} value={data.originAddress2} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Location"} name={"exporterLocation"} value={data.exporterLocation} error={error.exporterLocation} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Pincode"} name={"exporterPinCode"} value={data.exporterPinCode} error={error.exporterPinCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Select State"} name={"exporterState"} value={data.exporterState} error={error.exporterState} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Consignee/Importer Details
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <MultipleSelect singleSelection
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "buyerName")
                        }}
                        Label={"Select From Network"}
                        Id={`selectedBuyer`}
                        optiondata={dbDataFilter}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `selectedBuyer`, "id"))
                        }}
                        value={data[`selectedBuyer`] ? [parseInt(data[`selectedBuyer`])] : []}
                        name={`selectedBuyer`}
                        labelKey={"buyerNameHtml"}
                        valKey={"id"}
                        error={error[`selectedBuyer`]}
                      />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix
                        label={"Enter Legal Name"}
                        isDisabled={preview.show} name={"cosigneeLegalName"} value={data.cosigneeLegalName} error={error.cosigneeLegalName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Enter Trade Name'} isDisabled={preview.show} name={"consigneeTradeName"} value={data.consigneeTradeName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix label={'Enter GST No'} isDisabled={preview.show} name={"consigneeGstNo"} value={data.consigneeGstNo} error={error.consigneeGstNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={'Enter Email Id'} name={"consigneeEmailId"} value={data.consigneeEmailId} error={error.consigneeEmailId} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={'Enter Contact Number'} name={"consigneeContactNo"} value={data.consigneeContactNo} error={error.consigneeContactNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Place of Supply"} name={"consigneePos"} value={data.consigneePos} error={error.consigneePos} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isAstrix isDisabled={preview.show} label={"Enter Address Line 1"} name={"consigneeAddress1"} value={data.consigneeAddress1} error={error.consigneeAddress1} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isDisabled={preview.show} label={"Enter Address Line 2"} name={"consigneeAddress2"} value={data.consigneeAddress2} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Location"} name={"consigneeLocation"} value={data.consigneeLocation} error={error.consigneeLocation} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Pincode"} name={"consigneePinCode"} value={data.consigneePinCode} error={error.consigneePinCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Select State"} name={"consigneeState"} value={data.consigneeState} error={error.consigneeState} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Display Details
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewTextArea isAstrix
                        label={"Enter Legal Name"}
                        isDisabled={preview.show} name={"displayName"} value={data.displayName} error={error.displayName} onChange={handleChange} />
                    </div>
                    <div isAstrix className="position-relative col-3 mb-2" >
                      <NewTextArea isAstrix isDisabled={preview.show} label={"Enter Address Line 1"} name={"displayAddress1"} error={error.displayAddress1} value={data.displayAddress1} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isDisabled={preview.show} label={"Enter Address Line 2"} name={"displayAddress2"} value={data.displayAddress2} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Location"} name={"displayLocation"} value={data.displayLocation} error={error.displayLocation} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Pincode"} name={"displayPinCode"} value={data.displayPinCode} error={error.displayPinCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Select State"} name={"displayState"} value={data.displayState} error={error.displayState} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Shipper Details
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <MultipleSelect singleSelection
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "name")
                        }}
                        Label={"Select Shipper"}
                        Id={`selectedShipper`}
                        optiondata={ShipperListFilter}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `selectedShipper`, "id"))
                        }}
                        value={data[`selectedShipper`] ? [parseInt(data[`selectedShipper`])] : []}
                        name={`selectedShipper`}
                        labelKey={"name"}
                        valKey={"id"}
                        error={error[`selectedShipper`]}
                      />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix
                        label={"Enter Legal Name"}
                        isDisabled={preview.show} name={"shipperLegalName"} value={data.shipperLegalName} error={error.shipperLegalName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Enter Trade Name'} isDisabled={preview.show} name={"shipperTradeName"} value={data.shipperTradeName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix label={'Enter GST No'} isDisabled={preview.show} name={"shipperGstNo"} value={data.shipperGstNo} error={error.shipperGstNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isAstrix isDisabled={preview.show} label={"Enter Address Line 1"} name={"shipperAddress1"} value={data.shipperAddress1} error={error.shipperAddress1} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewTextArea isDisabled={preview.show} label={"Enter Address Line 2"} name={"shipperAddress2"} value={data.shipperAddress2} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Location"} name={"shipperLocation"} value={data.shipperLocation} error={error.shipperLocation} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isAstrix isDisabled={preview.show} label={"Enter Pincode"} name={"shipperPinCode"} value={data.shipperPinCode} error={error.shipperPinCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Select State"} name={"shipperState"} value={data.shipperState} error={error.shipperState} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  Transaction Details
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={supplyTypes} optionLabel={"name"} optionValue={"code"}
                        label={"Supplier Type"} name={"supplierType"} value={data.supplierType} error={error.supplierType} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show}
                        label={"GST No of E-Commerce Operator"} name={"ecmGstNo"} value={data.ecmGstNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2 d-flex row" >
                      <label className="font-size-14 font-wt-500 w-80" >Whether the tax liability is payable under
                        reverse charge</label>
                      <img
                        onClick={() => {
                          setData({ ...data, regRev: !data.regRev })
                        }}
                        className={'cursor w-20'} src={`assets/images/${data.regRev ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2 d-flex row" >
                      <label className="font-size-14 font-wt-500 w-80" >Is supply intra state but chargeable to IGST</label>
                      <img
                        onClick={() => {
                          setData({ ...data, IgstOnIntra: !data.IgstOnIntra })
                        }}
                        className={'cursor w-20'} src={`assets/images/${data.IgstOnIntra ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  NIC Credential Details
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} isAstrix label={"NIC Username"} name={"nicUsername"} value={data.nicUsername} error={error.nicUsername} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"password"} isDisabled={preview.show} isAstrix label={"NIC Password"} name={"nicPassword"} value={data.nicPassword} error={error.nicPassword} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  Item Details
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" style={{ overflowX: 'auto' }}>
                  <NewTablev2 columns={[{ subColumns: 'Item No', subColumnStyle: { width: '2%' } }, { subColumns: 'Description', subColumnStyle: { width: '5%' } },
                  { subColumns: 'Service?', subColumnStyle: { width: '1%' } }, { subColumns: 'HSN*', subColumnStyle: { width: '3%' } },
                  { subColumns: 'Batch Name', subColumnStyle: { width: '5%' } }, { subColumns: 'Batch Expiry', subColumnStyle: { width: '5%' } },
                  { subColumns: 'Batch Warranty', subColumnStyle: { width: '5%' } }, { subColumns: 'Barcode', subColumnStyle: { width: '5%' } },
                  { subColumns: 'Quantity*', subColumnStyle: { width: '3%' } }, { subColumns: 'Free Quantity', subColumnStyle: { width: '3%' } },
                  { subColumns: 'Unit*', subColumnStyle: { width: '8%' } }, { subColumns: 'Unit Price*', subColumnStyle: { width: '3%' } },
                  { subColumns: 'Total Amount*', subColumnStyle: { width: '3%' } }, { subColumns: 'Discount', subColumnStyle: { width: '3%' } }, { subColumns: 'PreTax Value', subColumnStyle: { width: '3%' } }, { subColumns: 'Taxable Value', subColumnStyle: { width: '3%' } },
                  { subColumns: 'GST Rate*', subColumnStyle: { width: '3%' } }, { subColumns: 'IGST Amount', subColumnStyle: { width: '3%' } }, { subColumns: 'CGST Amount', subColumnStyle: { width: '3%' } }, { subColumns: 'SGST Amount', subColumnStyle: { width: '3%' } },
                  { subColumns: 'CESS Rate', subColumnStyle: { width: '3%' } }, { subColumns: 'CESS Amount', subColumnStyle: { width: '3%' } }, { subColumns: 'CESS Non-advol Amount', subColumnStyle: { width: '3%' } }, { subColumns: 'State CESS Rate', subColumnStyle: { width: '3%' } }, { subColumns: 'State CESS Amount', subColumnStyle: { width: '3%' } },
                  { subColumns: 'State CESS Non-advol Amount', subColumnStyle: { width: '3%' } }, { subColumns: 'Other Charges', subColumnStyle: { width: '3%' } }, { subColumns: 'Total Value*', subColumnStyle: { width: '3%' } }, { subColumns: 'Reference No', subColumnStyle: { width: '3%' } },
                  { subColumns: 'Origin Country', subColumnStyle: { width: '8%' } }, { subColumns: 'Serial No', subColumnStyle: { width: '3%' } }, { subColumns: 'Action', subColumnStyle: { width: '3%' } }]} >

                    {invItems.map((i, j) => {
                      if (i === null) {
                        jj = jj + 1
                        return <tr>
                          <td><label className="font-size-14 font-wt-400 w-100 text-break" >{`${jj + 1}`}</label></td>
                          <td>
                            <InputForTable isDisabled={preview.show} name={`prodDesc${j}`} value={data[`prodDesc${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <img
                              onClick={() => {
                                setData({ ...data, [`isService${j}`]: !data[`isService${j}`] })
                              }}
                              className={'cursor'} src={`assets/images/${data[`isService${j}`] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                          </td>
                          <td>
                            <InputForTable isDisabled={preview.show} name={`hsnCode${j}`} value={data[`hsnCode${j}`]} error={error[`hsnCode${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable isDisabled={preview.show} name={`batchName${j}`} value={data[`batchName${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"date"} isDisabled={preview.show} name={`batchExpiryDate${j}`} value={data[`batchExpiryDate${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"date"} isDisabled={preview.show} name={`batchWarrantyDate${j}`} value={data[`batchWarrantyDate${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable isDisabled={preview.show} name={`barcode${j}`} value={data[`barcode${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]}
                              error={error[`itemQuantity${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemFreeQuantity${j}`} value={data[`itemFreeQuantity${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <div style={{ width: '5rem' }}>
                              <SelectForTable isDisabled={preview.show} label={""}
                                selectData={units} optionLabel={"UnitName"} optionValue={"Code"}
                                name={`itemUnit${j}`} value={data[`itemUnit${j}`]} error={error[`itemUnit${j}`]} onChange={handleChange} />
                            </div>
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemUnitPrice${j}`} value={data[`itemUnitPrice${j}`]} error={error[`itemUnitPrice${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemTotalAmount${j}`} value={data[`itemTotalAmount${j}`]} error={error[`itemTotalAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemDiscount${j}`} value={data[`itemDiscount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemPreTaxVal${j}`} value={data[`itemPreTaxVal${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemTaxableValue${j}`} value={data[`itemTaxableValue${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemGstRate${j}`} value={data[`itemGstRate${j}`]} error={error[`itemGstRate${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemIgstAmount${j}`} value={data[`itemIgstAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemCgstAmount${j}`} value={data[`itemCgstAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemSgstAmount${j}`} value={data[`itemSgstAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemCessRate${j}`} value={data[`itemCessRate${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemCessAmount${j}`} value={data[`itemCessAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemCessNonAdvolAmount${j}`} value={data[`itemCessNonAdvolAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemStateCessRate${j}`} value={data[`itemStateCessRate${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemStateCessAmount${j}`} value={data[`itemStateCessAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemStateCessNonAdvolAmount${j}`} value={data[`itemStateCessNonAdvolAmount${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemOtherCharges${j}`} value={data[`itemOtherCharges${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable type={"number"} isDisabled={preview.show} name={`itemTotalValue${j}`} value={data[`itemTotalValue${j}`]} error={error[`itemTotalValue${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable isDisabled={preview.show} name={`itemOrderLineReferenceNo${j}`} value={data[`itemOrderLineReferenceNo${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <div style={{ width: '5rem' }}>
                              <SelectForTable selectData={countrys}
                                optionLabel={"name"} optionValue={"code"}
                                isDisabled={preview.show} label={""} name={`itemOriginCountry${j}`} value={data[`itemOriginCountry${j}`]} onChange={handleChange} />
                            </div>
                          </td>
                          <td>
                            <InputForTable isDisabled={preview.show} name={`itemSerialNo${j}`} value={data[`itemSerialNo${j}`]} onChange={handleChange} />
                          </td>
                          <td>
                            <div className="" >
                              {(invItems.length - invItems.filter(i => {
                                if (i === undefined) {
                                  return true
                                }
                              }).length) - 1 === jj ?
                                <img
                                  onClick={async () => {
                                    let temp = invItems
                                    temp.push(null)
                                    setInvItems([...temp])
                                  }}
                                  className="cursor" src="assets/images/add_black_icon.png" /> :
                                <img
                                  onClick={() => {
                                    let temp = invItems
                                    temp[j] = undefined
                                    setInvItems([...temp])
                                  }}
                                  className="cursor" src="assets/images/delete.png" />}
                            </div>
                          </td>
                        </tr>
                      }
                    })}
                  </NewTablev2>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="true"
                  aria-controls="collapseSeven"
                >
                  Invoice Details
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} isAstrix label={"Invoice No"} name={"invoiceNo"} value={data.invoiceNo} error={error.invoiceNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} isAstrix label={"Invoice Date"} name={"invoiceDate"} value={data.invoiceDate} error={error.invoiceDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isAstrix isDisabled={preview.show} label={"Total Assessable Value"} name={"totalAssessableVal"} value={data.totalAssessableVal} error={error.totalAssessableVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Total CGST Value"} name={"totalCgstVal"} value={data.totalCgstVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Total SGST Value"} name={"totalSgstVal"} value={data.totalSgstVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Total IGST Value"} name={"totalIgstVal"} value={data.totalIgstVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Total CESS Value"} name={"totalCessVal"} value={data.totalCessVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Total State CESS Value"} name={"totalStateCessVal"} value={data.totalStateCessVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Discount Value"} name={"discountVal"} value={data.discountVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Other Charge Value"} name={"otherChargeVal"} value={data.otherChargeVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Rounding off Value"} name={"roundOffVal"} value={data.roundOffVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isAstrix isDisabled={preview.show} label={"Final Invoice Value"} name={"finalInvoiceVal"} value={data.finalInvoiceVal} error={error.finalInvoiceVal} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse8"
                  aria-expanded="true"
                  aria-controls="collapse8"
                >
                  Payment Details
                </button>
              </h2>
              <div
                id="collapse8"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <MultipleSelect singleSelection
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "beneficiaryBankName")
                        }}
                        Label={"Select Bank"}
                        Id={`selectedBank`}
                        optiondata={banksFilter}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `selectedBank`, "id"))
                        }}
                        value={data[`selectedBank`] ? [parseInt(data[`selectedBank`])] : []}
                        name={`selectedBank`}
                        labelKey={"beneficiaryBankName"}
                        valKey={"id"}
                        error={error[`selectedBank`]}
                      />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Payee Name"} name={"payeeName"} value={data.payeeName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Bank Account No"} name={"payeeBankAccNo"} value={data.payeeBankAccNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Mode of Payment"} name={"payeeModeOfPayment"} value={data.payeeModeOfPayment} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Branch/IFSC Code"} name={"payeeIFSCCode"} value={data.payeeIFSCCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Terms of Payment"} name={"payeeTNC"} value={data.payeeTNC} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Payment Instruction"} name={"payeeInstruction"} value={data.payeeInstruction} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Credit Transfer"} name={"payeeCreditTransfer"} value={data.payeeCreditTransfer} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Direct Debit"} name={"payeeDirectDebit"} value={data.payeeDirectDebit} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Credit Days"} name={"payeeCreditDays"} value={data.payeeCreditDays} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Advance Payment"} name={"payeeAdvancePayment"} value={data.payeeAdvancePayment} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Due Payment"} name={"payeePaymentDue"} value={data.payeePaymentDue} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  Reference Details
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Remarks/Note"} name={"referenceRemarks"} value={data.referenceRemarks} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"Invoice Period Start Date"} name={"invoicePeriodStartDate"} value={data.invoicePeriodStartDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"Invoice Period End Date"} name={"invoicePeriodEndDate"} value={data.invoicePeriodEndDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Previous Document Invoice No"} name={"prevDocInvNo"} value={data.prevDocInvNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"Previous Document Invoice Date"} name={"prevDocInvDate"} value={data.prevDocInvDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Previous Document Other Reference"} name={"prevDocOtherRef"} value={data.prevDocOtherRef} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Receipt Advice No"} name={"receiptAdviceNo"} value={data.receiptAdviceNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"Date of Receipt Advice"} name={"dateOfReceiptAdvice"} value={data.dateOfReceiptAdvice} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Lot/Batch Reference No"} name={"lotBatchRefNo"} value={data.lotBatchRefNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Contract Reference No"} name={"contractReferenceNo"} value={data.contractReferenceNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Any Other Reference"} name={"anyOtherReference"} value={data.anyOtherReference} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Project Reference No"} name={"projectReferenceNo"} value={data.projectReferenceNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Vendor PO Reference Number"} name={"vendorPoRefNo"} value={data.vendorPoRefNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"Vendor PO Reference date"} name={"vendorPoRefDate"} value={data.vendorPoRefDate} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="true"
                  aria-controls="collapseNine"
                >
                  Additional Documents
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Support Document Name"} name={"supportDocName"} value={data.supportDocName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Suppport Document URL"} name={"supportDocUrl"} value={data.supportDocUrl} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Support Document Additional Information"} name={"supportDocAddInfo"} value={data.supportDocAddInfo} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="true"
                  aria-controls="collapseTen"
                >
                  Shipping Details
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"BL No"} name={"blNo"} value={data.blNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"BL Date"} name={"blDate"} value={data.blDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Port Code"} name={"portCode"} value={data.portCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect selectData={most_used_currencies} optionLabel={"name"} optionValue={"code"} isDisabled={preview.show} label={"Currency"} name={"shippingCurrency"} value={data.shippingCurrency} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect selectData={countrys} optionLabel={"name"} optionValue={"sortcode"} isDisabled={preview.show} label={"Country"} name={"shippingCountry"} value={data.shippingCountry} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Export Duty"} name={"exportDuty"} value={data.exportDuty} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2 d-flex row" >
                      <label className="font-size-14 font-wt-500 w-80" >Is option for Refund Available?</label>
                      <img
                        onClick={() => {
                          setData({ ...data, optionForRefund: !data.optionForRefund })
                        }}
                        className={'cursor w-20'} src={`assets/images/${data.optionForRefund ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse11"
                  aria-expanded="true"
                  aria-controls="collapse11"
                >
                  EWB Details
                </button>
              </h2>
              <div
                id="collapse11"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Transporter Id/GSTIN"} name={"transporterId"} value={data.transporterId} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Name of Transporter"} name={"nameOfTransporter"} value={data.nameOfTransporter} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect selectData={[{ name: 'Road', id: 1 }, { name: 'Rail', id: 2 }, { name: 'Air', id: 3 }, { name: 'Ship', id: 4 }, { name: 'In Transit', id: 5 }]} optionLabel={"name"} optionValue={"id"} isDisabled={preview.show} label={"Transportation Mode"} name={"transportationMode"} value={data.transportationMode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"number"} isDisabled={preview.show} label={"Distance Between Source & Destination Pincode"} name={"distanceBetSourceDestPincode"} value={data.distanceBetSourceDestPincode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Tranport Document Number"}
                        name={"transportDocNo"} value={data.transportDocNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput type={"date"} isDisabled={preview.show} label={"Tranport Document Date"}
                        name={"transportDocDate"} value={data.transportDocDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput isDisabled={preview.show} label={"Vehicle Number"}
                        name={"vehicleNo"} value={data.vehicleNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect selectData={[{ name: 'ODC', id: 'O' }, { name: 'Regular', id: 'R' }]}
                        optionLabel={"name"} optionValue={"id"} isDisabled={preview.show} label={"Vehicle Type"}
                        name={"vehicleType"} value={data.vehicleType} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="d-flex row m-0 p-0 mb-3 justify-content-center " >
          <div className={`${!preview.show ? ' w-20 ' : ' w-30 '} d-flex row m-0 p-0 mb-2 justify-content-center`} >
            <button type="button"
              onClick={() => {
                handleValidation()
                // setPreview({ ...preview, show: !preview.show })
              }}
              className={` new-btn ${!preview.show ? ' w-100 ' : ' w-45 '} py-2 px-2 text-white`}>
              {!preview.show ? "Submit" : "Back to Editing"}
            </button>
            {preview.show ?
              <button type="button"
                onClick={() => {
                  printDiv("invoiceDiv", `E-Invoice - ${data.invoiceNo}`, [], data, setData, "commInvoice")
                }}
                className={` new-btn w-45 py-2 px-2  text-white`}>
                {"Save & Download"}
              </button> : null}
          </div>
        </div>
      </div>
    </>
  )

}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(CommercialInvoice)

