import React, { PureComponent } from 'react';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, AreaChart, Area } from 'recharts';

class CustomAreaChart extends PureComponent {
  render() {
    const { data, xDataKey, XFormatter, YFormatter, formatterFunction, dataKey, fillColor } = this.props;

    // Dynamic linear gradient IDs based on fillColor prop
    const gradientId1 = `fillcolor_${fillColor ? fillColor.replace('#', '') : '2'}_1`;
    const gradientId2 = `fillcolor_${fillColor ? fillColor.replace('#', '') : "5"}_2`;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey={xDataKey} tickFormatter={XFormatter} />
          <YAxis tickFormatter={YFormatter} />
          <Tooltip formatter={formatterFunction} />
          <defs>
            <linearGradient id={gradientId1} x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor={fillColor || "#5CB8D3"} stopOpacity={0.8} />
              <stop offset="40%" stopColor={fillColor || "#5CB8D3"} stopOpacity={0.6} />
              <stop offset="60%" stopColor={fillColor || "#5CB8D3"} stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id={gradientId2} x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor={fillColor || "#FDD775"} stopOpacity={0.8} />
              <stop offset="40%" stopColor={fillColor || "#FDD775"} stopOpacity={0.6} />
              <stop offset="60%" stopColor={fillColor || "#FDD775"} stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <Area
            type="linear"
            dataKey={dataKey}
            stroke={fillColor || "#5CB8D3"}
            fill={fillColor ? `url(#${gradientId2})` : `url(#${gradientId1})`}
            strokeWidth="2px"
            activeDot={{ r: 8 }}
            dot={{
              stroke: fillColor || "#5CB8D3",
              strokeWidth: 5,
              fill: fillColor || "#5CB8D3",
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default CustomAreaChart;
