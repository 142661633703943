import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import DocumentACL from '../tradeContract/documentACL'

const CategoryACLModal = ({ userTokenDetails, contractNo, categoryAClmodal, setcategoryAClmodal }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [parties, setParties] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [docCategoryACL, setdocCategoryACL] = useState({});

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getContractUsers', { contractNo: contractNo }).then((result) => {
      console.log("result getContractUsers:", result);
      if (result.length) {
        let tempArray = []
        for (let i = 0; i < result.length; i++) {
          tempArray.push({
            "userId": result[i].tbl_user_id,
            "userName": result[i].company_name
          })
        }
        setParties(tempArray);
        setshowLoader(false)
      }
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying getContractUsers:", e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  console.log("parties in request =>", parties)
  console.log("docCategoryACL in request =>", docCategoryACL)
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmitACL() {

    setshowLoader(true)
    call('POST', 'updatecontractdoccategoryacl', { "contractNo": contractNo, "aclObj": JSON.stringify(docCategoryACL) }).then((result) => {
      if (result) {
        setshowLoader(false)
        //console.log("result while insertcontractdoc:", result);
        setcategoryAClmodal(false)
        toastDisplay(result, "success")
      }
    }).catch(e => {
      setshowLoader(false)
      console.log("Error while insertcontractdoc:", e);
      toastDisplay(e, "error")
    })

  }
  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {categoryAClmodal && <div className={"modal" + (categoryAClmodal ? " show" : "")} id="DocumentUpload">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Update Category ACL</h4>
              <button type="button" className="close" onClick={() => { setcategoryAClmodal(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

              <DocumentACL
                userArray={parties}
                docCategoryACL={docCategoryACL}
                setdocCategoryACL={setdocCategoryACL}
                contractId={null}
                contractNo={contractNo}
                dataObj={{}}
                modalType={"update"} />
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-danger btn-sm float-right" onClick={() => setcategoryAClmodal(false)} >Close</button>
              <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => handleSubmitACL()} >Update ACL</button>
            </div>
          </div>
        </div>
      </div >}
    </>)
}


export default CategoryACLModal
