import React, { useState, useEffect } from 'react';
import DropdownSearch from '../dropdownWithSearch';
import NewTablev2 from '../../../../utils/newTablev2';
import call from '../../../../service';
import { getUserDataFromCookie } from '../../../../utils/cookieHelper';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default function TrendView({ data }) {
  console.log(data)
  // let userTokenDetails = getUserDataFromCookie();
  // const [data, setData] = useState(allData);
  const [filteredData, setFilteredData] = useState(data);
  const [typeOfVoucher, setTypeOfVoucher] = useState("Sales");
  const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedData, setSelectedData] = useState('itemName');
  const [selectedDuration, setSelectedDuration] = useState('Monthly');
  const [columns, setColumns] = useState([]);
  const trendDropDown = ["Sales", "Purchase", "Payment", "Receipt", "Purchase vs Payment", "Receipt vs Payment", "Sales vs Customers", "Sales vs Purchases", "Sales vs Receipt"];
  // const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;
  const durationDropDown = ['Day', 'Monthly', 'Quarterly', 'Yearly'];
  const itemsDropDown = ["Item Name", "Refference Number", "Customer Name", "State", "Items Group", "Customer Group", "Continent", "Item Category"];
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  // #1
  const filterDataBasedOnDates = () => {
    if (data && typeOfVoucher) {
      const voucherTypes = typeOfVoucher.toLowerCase().split(' vs ');
      const filteredItemsData = data.filter(item => {
        const isTypeMatch = voucherTypes.includes(item["type"].toLowerCase());
        return isTypeMatch;
      })
      console.log("here for", filteredItemsData)
      setFilteredData(filteredItemsData);
    }
  };


  // useEffect(() => {
  //   let objectAPI = {
  //     "userId": userId,
  //   };
  //   call('POST', 'gettallyreportsdata', objectAPI)
  //     .then((result) => {
  //       setData(result.allData);
  //       setFilteredData(result.allData);
  //     }).catch((e) => {
  //       console.log('error in getTransactionVoucher', e);
  //     });
  // }, []);

  useEffect(() => {
    filterDataBasedOnDates();
  }, [data, typeOfVoucher, fromDate, toDate]);



  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const updateColumns = () => {
    let columns = [{ subColumns: capitalizeFirstLetter(selectedData), subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null }];
    let start = new Date(fromDate);
    let end = new Date(toDate);
    columns.splice(1, 0, { subColumns: 'Report-Type', subColumnClass: 'text-nowrap', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null });


    if (selectedDuration === 'Day') {
      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        columns.push({ subColumns: d.toISOString().split('T')[0], subColumnClass: "text-nowrap", subColumnStyle: {}, thwidth: '', subColumnsOnClick: null });
      }
    } else if (selectedDuration === 'Monthly') {
      let d = new Date(start);
      while (d <= end) {
        const monthName = monthNames[d.getMonth()].slice(0, 3); // Get the month name
        const yearDigits = d.getFullYear().toString().slice(-2); // Get the last two digits of the year
        columns.push({ subColumns: `${monthName}-${yearDigits}`, subColumnClass: 'text-nowrap', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null });
        d.setMonth(d.getMonth() + 1);
      }
    } else if (selectedDuration === 'Quarterly') {
      let currentQuarter = Math.floor(start.getMonth() / 3) + 1;
      let currentYear = start.getFullYear();
      while (currentYear < end.getFullYear() || (currentYear === end.getFullYear() && currentQuarter <= Math.floor(end.getMonth() / 3) + 1)) {
        const quarterLabel = `${currentYear} Q${currentQuarter}`;
        columns.push({ subColumns: quarterLabel, subColumnClass: 'text-nowrap', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null });

        currentQuarter++;
        if (currentQuarter > 4) {
          currentQuarter = 1;
          currentYear++;
        }
      }
    } else if (selectedDuration === 'Yearly') {
      let d = new Date(start);
      while (d.getFullYear() <= end.getFullYear()) {
        columns.push({ subColumns: `${d.getFullYear()}`, subColumnClass: 'text-nowrap', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null });
        d.setFullYear(d.getFullYear() + 1);
      }
    }
    columns.push({ subColumns: 'Total', subColumnClass: 'text-nowrap', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null });


    setColumns(columns);
  };

  const handleSubmit = (e) => {
    setSelectedDuration(e);
  };

  useEffect(() => {
    updateColumns();
  }, [selectedData, filteredData, selectedDuration, typeOfVoucher]);

  const aggregateDataByProperty = (data, property) => {
    console.log("called agg");

    const aggregatedData = data.reduce((acc, item) => {
      const key = item[property];
      if (!acc[key]) {
        acc[key] = { ...item, totalAmount: 0 };
      }
      acc[key].totalAmount += item.totalAmount;
      return acc;
    }, {});
    return Object.values(aggregatedData);
  };


  const handleDataSelection = (element) => {
    setFilteredData([]);
    let needsAggregation = false;
    let fieldName;
    switch (element) {
      case 'Item Name':
        fieldName = "itemName";
        break;
      case "Refference Number":
        fieldName = "invoiceNumber";
        break;
      case "Customer Name":
        fieldName = "partyName";
        break;
      case "State":
        fieldName = "state";
        needsAggregation = true;
        break;
      case "Continent":
        fieldName = "continent";
        needsAggregation = true;
        break;
      case "Item Category":
        fieldName = "category";
        needsAggregation = true;
        break;
      case "Customer Group":
        fieldName = "customerGroup";
        needsAggregation = true;
        break;
      case "Items Group":
        fieldName = "itemGroup";
        needsAggregation = true;
        break;
      default:
        fieldName = "itemName";
        break;
    }
    setSelectedData(fieldName);
    if (needsAggregation) {
      filterDataBasedOnDates();
      const aggregatedData = aggregateDataByProperty(filteredData, fieldName);
      setFilteredData(aggregatedData);
    } else {
      filterDataBasedOnDates()
    }
  };

  const getAggregateTotal = (row, columnLabel) => {
    // console.log("name:",columnLabel)
    const rowDataDate = new Date(row.date);
    let total = 0;
    if (selectedDuration === 'Day') {
      const [yearLabel, monthLabel, dayLabel] = columnLabel.split('-');
      console.log(yearLabel, monthLabel, dayLabel)
      const dateObj = new Date(`${yearLabel}-${monthLabel}-${dayLabel}`);
      const b = dateObj.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
      const a = rowDataDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })
      // console.log(a,b)
      if (a === b) {
        total = row.totalAmount;
      }
    } else if (selectedDuration === 'Monthly') {
      const [monthLabel, yearLabel] = columnLabel.split('-');
      const month = monthNames.findIndex(m => m.slice(0, 3) === monthLabel);
      const year = parseInt(`20${yearLabel}`, 10);
      if (rowDataDate.getMonth() === month && rowDataDate.getFullYear() === year) {
        total = row.totalAmount;
      }
    } else if (selectedDuration === 'Quarterly') {
      const [yearLabel, quarterLabel] = columnLabel.split(' Q');
      const year = parseInt(yearLabel, 10);
      const quarter = parseInt(quarterLabel, 10);
      if (rowDataDate.getFullYear() === year && Math.floor(rowDataDate.getMonth() / 3) + 1 === quarter) {
        total = row.totalAmount;
      }
    } else if (selectedDuration === 'Yearly') {
      const year = parseInt(columnLabel, 10);
      if (rowDataDate.getFullYear() === year) {
        total = row.totalAmount;
      }
    }

    return total;
  };

  const getBackgroundClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return { backgroundColor: 'rgb(46, 204, 113)', color: 'white' };
    } else if (currentValue < previousValue) {
      return { backgroundColor: 'rgb(255, 123, 109)', color: 'white' };
    } else {
      return {};
    }
  };
  const getRowTotal = (row) => {
    return columns.reduce((sum, col, index) => {
      if (index === 0 || col.subColumns === 'Total') {
        return sum;
      }
      const total = getAggregateTotal(row, col.subColumns, selectedDuration);
      return sum + total;
    }, 0);
  };
  const specialCases = ["Purchase vs Payment", "Receipt vs Payment", "Sales vs Customers", "Sales vs Purchases", "Sales vs Receipt"];
  const getNamesForCompare = (typeOfVochers) => {
    let items = []
    switch (typeOfVochers) {
      case ("Purchase vs Payment"):
        items.push("Purchase")
        items.push("Payment")
        break
      case ("Receipt vs Payment"):
        items.push("Receipt")
        items.push("Payment")

      case ("Sales vs Customers"):
        items.push("Sales")
        items.push("partyName")
      case ("Sales vs Purchases"):
        items.push("Sales")
        items.push("Purchase")

      case ("Sales vs Receipt"):
        items.push("Sales")
        items.push("Receipt")
    }
    return items
  }
  const handleDownload = () => {
    const tableData = filteredData.map(row => {
      const rowData = {};
      columns.forEach(col => {
        if (col.subColumns === 'Total') {
          rowData[col.subColumns] = getRowTotal(row);
        } else if (col.subColumns === capitalizeFirstLetter(selectedData)) {
          rowData[col.subColumns] = row[selectedData];
        } else if (col.subColumns === 'Report-Type') {
          rowData[col.subColumns] = typeOfVoucher;
        } else {
          rowData[col.subColumns] = getAggregateTotal(row, col.subColumns);
        }
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "filteredData.xlsx");

  }

  return (
    <>
      <div className='w-100 rounded shadow'>
        <div className='d-flex border-bottom border-secondary mt-2 me-2 ps-2 pe-1'>
          <DropdownSearch defaultItem={"Sales"} items={trendDropDown} placeholder={"Select"} onSelect={(e) => setTypeOfVoucher(e)} />
          <label htmlFor="fromDate" className="me-2">Select From:</label>
          <input type="date" value={fromDate} onChange={handleFromDateChange} className="form-control me-2" style={{ width: '350px' }} />
          <label htmlFor="toDate" className="me-2">Select To:</label>
          <input type="date" value={toDate} onChange={handleToDateChange} className="form-control me-2 " style={{ width: '350px' }} />
          <DropdownSearch defaultItem={"Monthly"} items={durationDropDown} placeholder={"Select Duration"} className="" onSelect={(e) => handleSubmit(e)} />
        </div>
        <div className='p-2 d-flex justify-content-between'>
          <DropdownSearch placeholder={"Select Data for graph"} items={itemsDropDown} defaultItem={"Item Name"} onSelect={(e) => handleDataSelection(e)} />
          <button onClick={handleDownload} className="bg-white rounded" style={{ border: "0.5px solid grey", width: "40px" }}><img src='assets/images/new_dowd_icon.svg' /></button>
        </div>
        <div className="custom-scrollbar p-2">
          <NewTablev2 columns={columns} showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }}>
            {filteredData && filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => {
                  if (colIndex === 0) {
                    return (
                      <td key={colIndex}>
                        {row[selectedData]}
                      </td>
                    );
                  } else if (colIndex === 1) {
                    return (
                      <td key={colIndex} className={`${col.subColumnClass}`}>
                        {typeOfVoucher.includes('vs') ? typeOfVoucher.split(' vs ').map((type, index) => (
                          <div className='mb-3' key={index}>{type}</div>
                        )) : typeOfVoucher}
                      </td>
                    );
                  } else if (col.subColumns === 'Total') {
                    const rowTotal = getRowTotal(row);
                    return (
                      <td key={colIndex} className={`${col.subColumnClass}`} style={{ fontWeight: 'bold' }}>
                        {"$" + rowTotal}
                      </td>
                    )
                  } else {
                    const total = getAggregateTotal(row, col.subColumns);
                    // console.log(row)
                    const previousColumnLabel = columns[colIndex - 1].subColumns;
                    const previousTotal = getAggregateTotal(row, previousColumnLabel);
                    const backgroundStyle = getBackgroundClass(total, previousTotal);
                    return (
                      <td key={colIndex} className={`${col.subColumnClass}`} style={backgroundStyle}>
                        {specialCases.includes(typeOfVoucher) ? (

                          <div>
                            <p>{row.type === getNamesForCompare(typeOfVoucher)[0] ? total : 0}</p>
                            <p>{row.type === getNamesForCompare(typeOfVoucher)[1] ? total : 0}</p>
                          </div>

                        ) : (
                          total || 0
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </NewTablev2>
        </div>
      </div>
    </>
  );
}
