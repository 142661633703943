import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import call from "../../../service";

const transactions = [
  { name: "Quote", lc_label: "LC_Quotes_Count", inv_label: "INV_Quotes_Count" },
  { name: "Termsheet", lc_label: "LC_Termsheet", inv_label: "INV_Termsheet" },
  { name: "Finance Application", lc_label: "LC_Fin_Application_Count", inv_label: "INV_Fin_Application_Count" },
  { name: "Disbursement", lc_label: "LC_Disbursement", inv_label: "INV_Disbursement" },
  { name: "Request Letter", lc_label: "LC_Request_Letter", inv_label: "INV_Request_Letter" }
]

const Details = ({ DetailsData, userTokenDetails }) => {
  const userId = userTokenDetails?.user_id ? userTokenDetails.user_id : null

  const [transactionObj, setTransactionObj] = useState({})

  const getTransactionsCount = () => {
    call('POST', 'getFinTransactionCounts', { userId }).then(result => {
      setTransactionObj(result)
    }).catch(e => {
      setTransactionObj(e)
    })
  }

  useEffect(() => {
    getTransactionsCount()
  }, [])
  return (
    <>
      <div className='card p-3 border-0 card-layout borderRadius mb-4'>
        <div className='d-flex justify-content-between'>
          <div className='col-md-4 p-2'>
            <div className='mx-1 text-center lineheight19'>
              <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-1 mb-0'>Transaction type</p>
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className='mx-1 text-center lineheight19'>
              <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-1 mb-0'>Letter of Credit</p>
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className='mx-1 text-center lineheight19'>
              <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-1 mb-0'>Invoice Discounting</p>
            </div>
          </div>

        </div>
        {transactions.map((key, index) => {
          return (
            <div className='d-flex justify-content-between'>
              <div className='col-md-4 p-2'>
                <div className='mx-1 text-center lineheight19'>
                  <p className={`${index !== transactions.length - 1 && "border-bottom"} lineheight19 font-size-14 pb-1 mb-0`}>{key.name}</p>
                </div>
              </div>
              <div className='col-md-4 p-2'>
                <div className='mx-1 text-center lineheight19'>
                  <p className={`${index !== transactions.length - 1 && "border-bottom"} lineheight19 font-size-14 pb-1 mb-0`}><a href="" className=" color434343 text-decoration-none">{transactionObj[key.lc_label]}</a> </p>
                </div>
              </div>
              <div className='col-md-4 p-2'>
                <div className='mx-1 text-center lineheight19'>
                  <p className={`${index !== transactions.length - 1 && "border-bottom"} lineheight19 font-size-14 pb-1 mb-0`}><a href="" className=" color434343 text-decoration-none">{transactionObj[key.inv_label]}</a></p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default Details;