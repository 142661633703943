import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, GetCache, getDocDetails, industryTypesObj, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import { Action } from './action';
import ViewBuyerDetails from './viewBuyerDetails';
import CheckBuyerHealth from './checkBuyerHealth';
import SignDocument from '../InvoiceDiscounting/components/SignDocument';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { industryData } from '../registration/newRegistration';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';

const addBuyerTabs = [
  { name: "Select Buyer" },
  { name: "DUNS No." },
  { name: "Buyer details" },
  { name: "Documents" },
  // { name: "Select Financier" },
  { name: "Review" }
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },

  { "name": "Country", val: "buyerCountry" },
  { "name": "Postal Code", val: "buyerPostalCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },
]

// const financierData = [
//   { id: 100, name: "Stenn", icon: "stenn.png", rating: 4.6, reviews: 126 },
//   { id: 200, name: "Modifi", icon: "modifi.png", rating: 1, reviews: 130 },
// ]

const IncoTerms = [
  { name: "EXW" },
  { name: "FCA" },
  { name: "FAS" },
  { name: "FOB" },
  { name: "CFR" },
  { name: "CIF" },
  { name: "CPT" },
  { name: "CIP" },
  { name: "DAP" },
  { name: "DPU" },
  { name: "DDP" }
]


const SupplierList = ({ userTokenDetails, navToggleState, renderAsComponent }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const editBuyer = queryParams.get("editBuyer")

  const [dbData, setDbData] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [checkBuyerHealth, setCheckBuyerHealth] = useState({ show: false, data: {} })
  const [viewBuyerDetails, setViewBuyerDetails] = useState({ show: false, data: {} })
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [signdoc, setSigndoc] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("supplierList");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    //Get supplier list start
    let objectAPI =
    {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": page,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getuserwithfindetail', objectAPI).then((result) => {
      setshowLoader(false)
      console.log('running getuserwithfindetail api-->', result);
      setDbData(result.userData);
      setTableData(formatDataForTable(result.userData))
      setCount(result.countData);
    }).catch((e) => {
      // console.log('error in getuserwithfindetail', e);
      setshowLoader(false)
    });
    //Get supplier list end

    // Get master data start
    // call('get', 'getallCountry').then((result) => {
    //   setCountrys(result)
    // }).catch((e) => {
    //   console.log('error in getallCountry', e);
    // })
    // call('get', 'getHsnCodes').then((result) => {
    //   setHsnCodes(result)
    // }).catch((e) => {
    //   console.log('error in getHsnCodes', e);
    // })
    // call("POST", "getLenderDetails").then((result) => {
    //   setFinancierData(result);
    // }).catch((e) => {
    //   console.log("error in getLenderDetails", e);
    // });
    // Get master data end
  }, [refresh, page])

  async function onDelete(index) {
    setshowLoader(true)
    call('POST', 'deleteBuyerDetails', { finDtId: dbData[index]["id"] }).then((result) => {
      toastDisplay(result, "success");
      setRefresh(refresh + 1)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in deleteBuyerDetails', e);
      toastDisplay(e, "error");
      setshowLoader(false)
    })
  }

  async function onView(index) {
    let selectedBuyer = dbData[index]
    setshowLoader(true)
    let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
    let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])
    setshowLoader(false)
    setViewBuyerDetails({ show: true, data: { ...selectedBuyer, invoiceDocument, poDocument } })
  }

  function onCheckBuyerHealthClick(duns) {
    setshowLoader(true)
    call('POST', 'getBuyerHealth', { duns }).then((result) => {
      setshowLoader(false)
      setCheckBuyerHealth({ show: true, data: result })
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
      toastDisplay(e, "error")
    });
  }
  const handleClick = (item) => {
    if (booladd) {
      window.location = `/supplierDetails?id=${item.tbl_user_id}`;
    } else {
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  };

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let turnover = "NA"
      let netprofit = "NA"
      let currency = ""
      if (item.currency) {
        if (item.currency.includes(":")) {
          currency = item.currency.split(":")[1]
        } else {
          currency = item.currency
        }
      }
      if (item.turnover) {
        turnover = item.turnover + " " + currency
      }
      if (item.netprofit) {
        netprofit = item.netprofit + " " + currency
      }
      row[0] = item.company_name
      row[1] = industryTypesObj[item.industry_type] || item.industry_type
      row[2] = item.country_code
      row[3] = turnover
      row[4] = netprofit
      row[5] = item.buyersNumber
      row[6] =
        <div >
          <button type="button"
            onClick={() => handleClick(item)}
            className={`new-btn2 py-2 px-3 text-blue font-wt-600`}>
            {"View Details"}
          </button>
          {/* <button type="button"
              onClick={() => window.location = `/applyForLimit`}
              className={`new-btn py-2 px-3 mb-2 text-white`}>
              {"View Profile"}
            </button> */}
          {/* </a> */}
          {/* {item.buyerDUNSNo ? (
            <label
              onClick={() => {
                onCheckBuyerHealthClick(item.buyerDUNSNo)
              }}
              className='text-color1 font-wt-500 cursor'
            >Check Buyer Health  <i class="fas fa fa-arrow-right mx-1"></i> </label>
          ) : null} */}
        </div >
      tableData.push(row)
      row = []
    })
    return tableData
  }


  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  function getDunsList() {
    setData({
      ...data, selectedDuns: null,
      buyerAddress: "",
      buyerPostalCode: "", buyerDunsNo: ""
    })
    setshowLoader(true)
    call('POST', 'getDUNSNo', {
      companyName: data.buyerName, countryCode: data.buyerCountry,
      userId, userEmail
    }).then((result) => {
      setshowLoader(false)
      if (result.length > 0) {
        setDunsData(result);
        setTab(1)
      }
      else {
        toastDisplay("No Related Buyer Details Found", "error")
        setTab(2)
        setDunsData([])
      }
    })
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
          return getDunsList()
        }
        setTab(tab + 1)
      }
      else {
        submitBuyerDetails()
      }
    }
    setErrors(err)
  }

  async function submitBuyerDetails() {
    setshowLoader(true)
    let formData = new FormData();
    formData.append("buyerName", data.buyerName)
    formData.append("buyerCountry", data.buyerCountry)
    formData.append("buyerDUNSNo", data.buyerDunsNo)
    formData.append("buyerAddress", data.buyerAddress)
    formData.append("buyerPostalcode", data.buyerPostalCode)
    formData.append("buyerWebsite", data.buyerWebsite)
    formData.append("buyerEmail", data.buyerEmail)
    formData.append("buyerContact", data.buyerContact)
    formData.append("annualSale", data.buyerPrevSale)
    formData.append("expectedAnnualSale", data.buyerExpectedSale)
    let buyerCurrency = most_used_currencies.filter(item => item.code === data.buyerCurrency)[0]
    formData.append("buyerCurrency", buyerCurrency["id"] + ":" + buyerCurrency["code"])
    formData.append("incoTerms", data.buyerIncoTerms)
    formData.append("payment", data.buyerTermsPayment)
    formData.append("productDetail", data.buyerProductDetails)
    formData.append("userId", userId)
    formData.append("userEmail", userEmail)
    formData.append("userName", userName)
    formData.append("baseUrl", config.baseUrl)
    if (!data.invoiceDocument.noChange) {
      formData.append("previousInvoiceDocument", data.invoiceDocument)
    }
    if (!data.poDocument.noChange) {
      formData.append("previousPODocument", data.poDocument)
    }
    //Additional fields
    formData.append("buyerHsnCode", data.buyerHsnCode && data.buyerHsnCode.length ? data.buyerHsnCode.join(",") : "")
    formData.append("buyerNameTitle", data.buyerNameTitle)
    formData.append("buyerPhoneCode", data.buyerPhoneCode)
    if (data.invoiceDocument.signatureId) {
      formData.append("invoiceDocSignId", data.invoiceDocument.signatureId)
    }
    if (data.poDocument.signatureId) {
      formData.append("poDocSignId", data.poDocument.signatureId)
    }
    if (data.isEditing) {
      formData.append("buyerId", data.buyerId)
      formData.append("buyersDocId", data.buyersDocId)
    }
    call('POST', `${data.isEditing ? "updateBuyersDetails" : "insertBuyersDetail"}`, formData).then((result) => {
      setshowLoader(false)
      toggleMsgPopup(true)
      ClearCache("add_buyer_form_data");
    }).catch(err => {
      // console.log("insertBuyersDetail error:", err)
      setshowLoader(false)
      toastDisplay("Something went wrong", "error");
    })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1)
    }
  };

  console.log("ccccccccccccccccccccccccccccccccccc", count);


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showMsgPopup ? (
        <PopupMessage
          onClose={() => { toggleMsgPopup(false); window.location = "/supplierList" }}
          title={data.isEditing ? 'Buyer details updated successfully' : 'New buyer details are successfully saved!'}
          msg={`Buyer details can be viewed & edited any time in "Buyer Management" section.`}
        />
      ) : null}
      <div className="container-fluid">
        <div className="row">
          {!renderAsComponent &&
            <SideBarV2 state="supplierList" userTokenDetails={userTokenDetails} />
          }
          <main role="main" className={` ml-sm-auto ${renderAsComponent ? "col-lg-12" : "col-lg-10"}` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {!renderAsComponent &&
              <HeaderV2
                // title={`Buyer Management ${showAddBuyerForm ? ` > ${data.isEditing ? 'Edit Buyer' : 'Add Buyer'}` : ""}`}
                title={`Supplier List`}
                userTokenDetails={userTokenDetails} />
            }
            {checkBuyerHealth.show ? (
              <CheckBuyerHealth data={checkBuyerHealth.data} />
            ) : null}

            {viewBuyerDetails.show ? (
              <ViewBuyerDetails data={viewBuyerDetails.data} />
            ) : null}

            {!showAddBuyerForm ? (
              <>
                {!viewBuyerDetails.show && !checkBuyerHealth.show ? (

                  <div className='my-4'>
                    <div className='filter-div ml-4'>
                      <div className='row d-flex align-items-center'
                        style={{ width: "10rem" }}
                      >
                        <label className='col-6'>Show</label>
                        <input className='form-control pagination-input  my-4 px-2' type={"number"} name={"resultPerPage"} value={filter.resultPerPage}
                          onChange={(event) => setFilter({ ...filter, resultPerPage: event.target.value })}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="input-group mb-3 col-md-3 currency mt-3 pe-1">
                        <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                        <input type="text" name='search'
                          onKeyDown={handleKeyDown} onChange={(event) => {
                            setFilter({ ...filter, search: event.target.value })
                          }}
                          className="form-control border-start-0" placeholder="Search" />
                      </div>
                      {/* <ul class="range-slider">
                        <li>
                          <button type="button" onClick={() => {
                            setRefresh(refresh + 1)
                          }}>
                            <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span></button>
                          <input className='form-control' name="search" id="searchInput" placeholder="Search" value={filter.search}
                            onChange={(event) => {
                              setFilter({ ...filter, search: event.target.value })
                            }} onKeyDown={handleKeyDown} />
                        </li>
                      </ul> */}
                    </div>
                    <NewTable
                      disableAction={true}
                      columns={[{ name: "Supplier Name", filter: true }, { name: "Industry Type", filter: true },
                      { name: "Country", filter: true }, { name: "Turnover", filter: true }, { name: "Net Profit", filter: true },
                      { name: "No. of Buyers", filter: true }, { name: "", filter: false }]}
                      data={tableData}
                    />
                    <Pagination perPage={filter.resultPerPage} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
                  </div>
                ) : null}
              </>
            ) : (
              <div>
                <i
                  onClick={() => {
                    window.location = "/buyerManagement"
                  }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                  {addBuyerTabs.map((item, index) => {
                    return (
                      <li>
                        <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")} onClick={() => {
                          handleValidation(index)
                        }}>{item.name}</a>
                      </li>
                    )
                  })}
                </ul> */}

                <div className='bg-white pb-5'
                  style={{
                    "height": "40rem",
                    "overflow": "auto"
                  }}>

                  <div className="d-flex justify-content-center mt-5 mb-3" >
                    <FormProgressBar
                      tabs={addBuyerTabs} activeTab={tab} label={"name"}
                      separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                    />
                  </div>

                  {tab === 0 ? (
                    <div className='px-4 py-5'>
                      <div className="row">

                        <div className="col-md-4">
                          {/* <InputWithSelect isAstrix={true} type={"text"} label={"Buyer's Name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"buyerNameTitle"} selectValue={data.buyerNameTitle}
                            name={'buyerName'} value={data.buyerName} optionLabel={"name"} optionValue={'name'}
                            onChange={handleChange} error={errors.buyerName} /> */}
                          <NewInput isAstrix={true} type={"text"} label={"Buyer's Name"}
                            name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewSelect isAstrix={true} label={"Select Country"}
                            selectData={countrys} name={"buyerCountry"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {tab === 1 ? (
                    <div className='px-4 py-5'>
                      <label className='mb-4' >Select DUNS No.</label>
                      <div className='row dunsCardView px-3'>
                        {dunsData.length ? dunsData.map((item, index) => {
                          return (
                            <div
                              onClick={() => setData({
                                ...data, selectedDuns: index,
                                buyerAddress: item.address.street,
                                buyerPostalCode: item.address.postalCode, buyerDunsNo: item.duns
                              })}
                              className={`dunsCard cursor mb-5 mr-5 p-4 ${data.selectedDuns === index ? "selectedDunsCard" : ""} `}
                            >
                              <h6 className='text-color1'>{item.duns}</h6>
                              <label className='text-color-label' >Company name</label>
                              <br />
                              <label className='text-color-value'>{item.companyName}</label>
                              <br />
                              <label className='text-color-label'>Address</label>
                              <br />
                              <label className='text-color-value'>{`${item.address.street}, ${item.address.city}, ${item.address.countryCode}, ${item.address.postalCode}`}</label>
                            </div>
                          )
                        }) : (
                          <h6>No Related Buyer Details Found</h6>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {tab === 2 ? (
                    <div className='px-4 py-5'>
                      <div className="row">
                        <div className="col-md-4">
                          {/* <InputWithSelect isAstrix={true} type={"text"} label={"Buyer's Name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"buyerNameTitle"} selectValue={data.buyerNameTitle}
                            name={'buyerName'} value={data.buyerName} optionLabel={"name"} optionValue={'name'}
                            onChange={handleChange} error={errors.buyerName} /> */}
                          <NewInput isAstrix={true} type={"text"} label={"Buyer's Name"}
                            name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                            onChange={handleChange} />
                        </div>


                        <div className="col-md-4">
                          <NewSelect isAstrix={true} label={"Select Country"}
                            selectData={countrys} name={"buyerCountry"}
                            value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Email Id"}
                            name={"buyerEmail"} value={data.buyerEmail}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect type={"number"} label={"Contact Number"}
                            selectData={countrys}
                            selectName={"buyerPhoneCode"} selectValue={data.buyerPhoneCode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'buyerContact'} value={data.buyerContact}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Address"}
                            name={"buyerAddress"} value={data.buyerAddress}
                            onChange={handleChange} error={errors.buyerAddress} />
                        </div>

                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Postal Code"}
                            name={"buyerPostalCode"} value={data.buyerPostalCode}
                            onChange={handleChange} error={errors.buyerPostalCode} />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Website"}
                            name={"buyerWebsite"} value={data.buyerWebsite}
                            onChange={handleChange} error={errors.buyerWebsite} />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"DUNS No"}
                            name={"buyerDunsNo"} value={data.buyerDunsNo}
                            onChange={handleChange} error={errors.buyerDunsNo} />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect isAstrix={true} type={"number"} label={"Previous year annual sales"}
                            selectData={currencyData}
                            selectName={"buyerCurrency"} selectValue={data.buyerCurrency}
                            optionLabel={"code"} optionValue={'code'}
                            name={'buyerPrevSale'} value={data.buyerPrevSale} error={errors.buyerPrevSale}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect isAstrix={true} type={"number"} label={"Expected current year’s annual sale"}
                            selectData={currencyData}
                            selectName={"buyerCurrency"} selectValue={data.buyerCurrency}
                            optionLabel={"code"} optionValue={'code'}
                            name={'buyerExpectedSale'} value={data.buyerExpectedSale} error={errors.buyerExpectedSale}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewSelect isAstrix={true} label={"Select Inco Terms"}
                            selectData={IncoTerms} name={"buyerIncoTerms"}
                            value={data.buyerIncoTerms} optionLabel={"name"} optionValue={'name'}
                            onChange={handleChange} error={errors.buyerIncoTerms} />
                        </div>

                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Terms of Payment"}
                            name={"buyerTermsPayment"} value={data.buyerTermsPayment}
                            onChange={handleChange} error={errors.buyerTermsPayment} />
                        </div>

                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Product Details"}
                            name={"buyerProductDetails"} value={data.buyerProductDetails}
                            onChange={handleChange} error={errors.buyerProductDetails} />
                        </div>

                        <div className="col-md-4">
                          <MultipleSelect
                            Id="HSN Code"
                            Label="HSN Code"
                            selectedvalue="HSN Code"
                            optiondata={hsnCodes}
                            onChange={(e) => handleMultiSelectchange(e, "buyerHsnCode", "code")}
                            value={data.buyerHsnCode || []}
                            name="buyerHsnCode"
                            labelKey={"code"}
                            valKey={"code"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {tab === 3 ? (
                    <div className='px-4 py-5'>
                      <div className="row">

                        <div className="col-md-12">
                          <label className="">Invoice Document {astrix}</label>
                          <div className="row form-group">
                            <div className="col-md-5">
                              <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                              />
                              {errors.invoiceDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.invoiceDocument}</b></div> : ''}
                            </div>
                            {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label className="">Purchase Order Document {astrix}</label>
                          <div className="row form-group">
                            <div className="col-md-5">
                              <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "poDocument": null })}
                              />
                              {errors.poDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.poDocument}</b></div> : ''}
                            </div>
                            {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.poDocument && data.poDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* {tab === 4 ? (
                    <div className='px-4 py-5'>
                      <div className="row">
                        {financierData.length && financierData.map((item) => {
                          return (
                            <div className='d-flex mb-5 w-30'>
                              <div className='align-items-center d-flex mr-3'
                                onClick={() => handleSelectedFinanciers(item.id)}
                              >
                                {data.selectedFinanciers.length && data.selectedFinanciers.includes(item.id) ? (
                                  <img className='cursor' src={"assets/images/checked-green.png"} />
                                ) : (
                                  <img className='cursor' src={"assets/images/empty-check.png"} />)}
                              </div>
                              <div
                                className='financierCard px-5 py-4'>
                                <div className='d-flex'
                                  style={{
                                    "height": "2rem",
                                    "overflow": "hidden"
                                  }}
                                >
                                  <img
                                    src={avatarUrl(item.user_avatar)}
                                    className={""}
                                    style={{
                                      width: "30px",
                                      height: "30px"
                                    }}
                                  />
                                  <label
                                    className={"ml-1 overflow-hidden"}
                                  ><b>{item.company_name}</b></label>
                                </div>
                                <div className='row justify-content-between py-3'>
                                  <div>
                                    <label
                                      className={"ml-2"}
                                    >{item.rating}</label>
                                    <StarRating rating={item.rating} />
                                  </div>
                                  <div>
                                    <label
                                    >{item.reviews}</label><br />
                                    <label
                                    >{"See Reviews"}</label>
                                  </div>
                                </div>
                                <div>
                                  <img src={"assets/images/chat.png"} />
                                  <label className='ml-2'>Chat with financier</label>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : null} */}

                  {tab === 4 ? (
                    <div className='p-5'>
                      <div className="row">

                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                          <div className='row mt-2'>
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className='w-100 my-5'>
                          <label className='font-wt-500'><u>Financier Selected</u></label>
                          <div className='d-flex'>
                            {data.selectedFinanciers.map((item) => {
                              return (
                                <div className='bg-white d-flex text-center align-items-center py-2 mr-3'
                                  style={{
                                    "borderRadius": "10px",
                                    "border": "1px solid #D5D5D5",
                                    "width": "10rem",
                                    "display": "inline-block"
                                  }}
                                >
                                  <label className='w-100 lh-18 m-0 text-color-label'>{financierData.filter(i => i.id == item)[0]["company_name"]}</label>
                                </div>
                              )
                            })}
                          </div>
                        </div> */}
                        {data.isEditing ? null : (
                          <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {tab === 4 && !data.tcAccepted ? null : (
                    <div className="row mx-0 px-3">
                      <button type="button"
                        onClick={() => handleValidation(undefined)}
                        className={`new-btn w-20 py-2 px-2 text-white`}>
                        {tab === 4 ? `${data.isEditing ? 'Update Buyer' : 'Get Finance Limit'}` : "Continue"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </main>
        </div>
      </div >
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SupplierList)