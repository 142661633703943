import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../../../service';
import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';
import Filter from "../../../InvoiceDiscounting/components/Filter";
import moment from 'moment';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import { getDocDetails, LCPurposeObject, LCTypesObject } from '../../../../utils/myFunctions';
import ChatBoxPopUp from '../../../chatRoom/components/ChatBoxPopUp';


export const RequestLetter = ({ userTokenDetails, navToggleState }) => {
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [showLoader, setshowLoader] = useState(false)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userTypeId": userTypeId,
      currentPage: page,
      "onlySelectedQuote": true,
      ...filter
    }
    call('POST', 'getAppliedForLCLimit', objectAPI).then((result) => {
      console.log('running getAppliedForLCLimit api-->', result);
      setTableData(formatDataForTable(result.data))
      setdbData(result.limitData)
      setCount(result.totalCount)
    }).catch((e) => {
      // console.log('error in getAppliedForLCLimit', e);
    });
  }, [refresh]);

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)

      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }
  const getChatRoomDetails = () => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    call('POST', 'getChatRoomIdByName', { chat_room_name: chatRoomName }).then((result) => {
      console.log('success in getChatRoomIdByName ', result)
      getChatDetails({
        chat_room_id: result.chat_room_id,
        loggedInUser: userId
      })
    }).catch(e => {
      console.log('error in getChatRoomIdByName', e)
    })
  }
  useEffect(() => {
    getChatRoomDetails()
  }, [selectedChat])

  function formatDataForTable(data) {
    let tableData = []
    let row = [];
    // data = [{
    //   createdAt: '2022-10-25T19:01:26.000Z',
    //   supplierName: 'Rosti Suzhou Integrated',
    //   buyerName: 'Rosti Suzhou Integrated',
    //   lcNo: '23456789087654',
    //   lcType: 'LC Confirmation',
    //   LCTenor: '30 days'
    // },
    // {
    //   createdAt: '2022-10-25T19:01:26.000Z',
    //   supplierName: 'Pharmanex Electronic Optical Technology Shanghai',
    //   buyerName: 'Pharmanex Electronic Optical Technology Shanghai',
    //   lcNo: '23456789087654',
    //   lcType: 'LC Discounting',
    //   LCTenor: '30 days'
    // }];
    data.forEach((item, index) => {
      row[0] = item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : 'NA'
      row[1] = item.company_name ? item.company_name : 'NA'
      row[2] = item.buyerName ? item.buyerName : 'NA'
      row[3] = item.lcNo ? item.lcNo : 'NA'
      row[4] = item.lcType ? item.lcType : 'NA'
      row[5] = item.lcTenor ? item.lcTenor : 'NA'

      if (item.isAmendmentReceivedFromBuyer) {
        row[99] = <p className="font-size-12 text-color-value ml-3 ">
          <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2">
            <b>Amended LC received from buyer!</b> </span>
          <label
            onClick={() => {
              window.location = `/viewRequestLetter`;
              localStorage.setItem("applicationDetails", JSON.stringify({
                ...item, buyerCurrency: item.buyerCurrency.split(":")[1],
                lcPurpose: LCPurposeObject[item.lcPurpose],
                lcType: LCTypesObject[item.lcType],
                onlyView: item.isReadyForContract / 1 != 0 ? true : false
              }))
            }}
            className="text-color1 text-decoration-underline cursor mr-2">View LC</label>
        </p>
      }

      row[6] = <div className='row ' >
        <a>
          <button type="button"
            onClick={() => {
              window.location = `/viewRequestLetter`;
              localStorage.setItem("applicationDetails", JSON.stringify({
                ...item, buyerCurrency: item?.buyerCurrency?.split(":")[1],
                lcPurpose: LCPurposeObject[item.lcPurpose],
                lcType: LCTypesObject[item.lcType],
                onlyView: item.isReadyForContract / 1 != 0 ? true : false
              }))
            }}
            className={`border-0 mb-2 text-white ${item.isReadyForContract == 2 ? "rejectedQuotebtn" : "enableQuotebtn2"} `}>
            {item.isReadyForContract == 2 ? "Application Rejected" : (
              item.lcPurpose === "lc_discounting" ? "Send Contract" :
                "View Request Letter")}
          </button>
        </a>

      </div >
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const intiateChat = (id) => {
    console.log('tabldata', dbData[id])
    let key = dbData[id]
    setSelectedChat({
      receiverName: key.supplierName,
      receiverId: key.user_id,
      userId: userId,
      isChatOpen: true,
      logo: key.supplierAvatar
    })
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcRequestLetter" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC - Request Letter"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4'>
              <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              <NewTable tableContainsNotification={true} disableAction={true}
                columns={[{ name: "Date", filter: true }, { name: "Supplier Name", filter: true }, { name: "Buyer Name", filter: true }, { name: "LC No.", filter: true }, { name: "LC Type", filter: true }, { name: "LC Tenor", filter: true },
                { name: "", filter: false }, { name: "", filter: false }
                ]}
                data={tableData}
                options={[
                  { name: "Chat with supplier", icon: "edit.png", onClick: (id) => intiateChat(id) },
                  { name: "Documents", icon: "edit.png" }]}
              />
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>
        {selectedChat.isChatOpen &&
          <div className="chatboxDiv">
            <ChatBoxPopUp
              chatList={chatList}
              user_avatar={selectedChat.logo}
              userId={selectedChat.userId}
              receiverName={selectedChat.receiverName}
              onChatSend={(file) => sendChatMsg(file)}
              message={message}
              setTextMsg={setTextMsg}
              onPopupClose={() => {
                setSelectedChat({
                  receiverName: '',
                  receiverId: '',
                  userId: userId,
                  isChatOpen: false,
                  logo: ''
                })
              }}
            />
          </div>
        }
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(RequestLetter)