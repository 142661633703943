import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, LCTypesObject, most_used_currencies } from "../../../../utils/myFunctions";
import call from '../../../../service';
import config from "../../../../config.json";
import { FileInput } from "../../../../utils/FileInput";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import SubmitModal from "../../../InvoiceDiscounting/components/submitModal";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";


const addBuyerTabs = [
  { name: "Application form" }
  // { name: "Documents" },
  // { name: "Selected financer" },
  // { name: "Review" },
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  // { "name": "LC tenor", val:  },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]


const ApplyforSblc = ({ userTokenDetails, navToggleState }) => {

  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  const quoteId = queryParams.get("id")

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [lcFinanciers, setLCFinanciers] = useState([])

  const [limitinvoice, setLimitinvoice] = useState(false);
  const [lctype, setLctype] = useState(false);
  const [experience, setExperience] = useState(false);
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [slice, setSlice] = useState(1);

  const Next = () => {
    setFirstindex(firstindex + 4);
    setLastindex(lastindex + 4);
    setSlice(slice + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 4);
    setLastindex(lastindex - 4);
    setSlice(slice - 1);
  };


  useEffect(() => {
    // master data start
    call('POST', 'getFinanciersForLC', { sblcEnabled: true }).then((result) => {
      setLCFinanciers(result)
    }).catch((e) => {
      console.log('error in getFinanciersForLC', e);
    })
    //master data end
    //Get quote data start
    setshowLoader(true)
    call('POST', 'getLCQuoteApplicationById', { quoteId }).then(async (result) => {
      console.log('running getLCQuoteApplicationById api-->', result);
      if (result.length) {
        let reqLetterDocument = {}
        let additionalDetails = {}
        let draftLCDocument = await getDocDetails(result[0].draftLC)
        let invoiceDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[0] : null)
        let poDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[1] : null)
        let buyerOtherDocIdArr = result[0].buyerOtherDocs ? result[0].buyerOtherDocs.split(":") : []

        let otherDocObj = {}
        let moreDocArr = addMoreDoc

        for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
          otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
          moreDocArr.push(null)
        }

        setData({
          ...result[0], buyerCurrency: result[0].buyerCurrency ? result[0].buyerCurrency.split(":")[1] : null, invoiceDocument, poDocument, draftLCDocument, reqLetterDocument, ...additionalDetails,
          limitRequired: result[0]["requiredLimit"], lcType: LCTypesObject[result[0]["lcType"]],
          limitRequiredCurrency: result[0]["requiredLimitCurrency"], ...otherDocObj
        })
        setAddMoreDoc(moreDocArr)
      }
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      setshowLoader(false)
    });
    //Get quote data end
  }, [])

  // useEffect(() => {
  //   if (buyer && dbData.length) {
  //     let event = {
  //       target: {
  //         name: "selectedBuyer",
  //         value: buyer
  //       }
  //     }
  //     handleChange(event)
  //   }
  // }, [buyer, dbData])


  // const handleChange = async (event) => {
  //   if (event.persist) {
  //     event.persist()
  //   }

  //   if (event.target.name === "selectedBuyer") {
  //     setshowLoader(true)
  //     let selectedBuyer = dbData[0]
  //     let buyers_credit = JSON.parse(selectedBuyer.buyers_credit)

  //     for (let index = 0; index < buyers_credit.length; index++) {
  //       const element = buyers_credit[index];
  //       if (element.lender_name.toLowerCase().includes("stenn")) {
  //         buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
  //       }
  //       else if (element.lender_name.toLowerCase().includes("modifi")) {
  //         buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
  //       }
  //     }

  //     let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
  //     let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])
  //     setshowLoader(false)

  //     setData({
  //       ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
  //       invoiceDocument, poDocument, buyers_credit
  //     })
  //   }
  //   else {
  //     setData({ ...data, [event.target.name]: event.target.value })
  //     setErrors({ ...errors, [event.target.name]: "" })
  //   }
  // }

  const MoreFun = () => {
    setLctype(true);
    setLimitinvoice(false);
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }

  const closeExpFn = () => {
    setExperience(false);
  }

  // const handleFile = event => {
  //   event.persist()
  //   if (!event.target.files.length) {
  //     return null
  //   }
  //   else {
  //     let file_type = event.target.files[0]["type"].toLowerCase()
  //     if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
  //       setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
  //       return
  //     }
  //     if (event.target.name.includes("otherDoc")) {
  //       setAddMoreDoc(addMoreDoc.concat(null))
  //     }
  //     let reader = new FileReader();
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onloadend = async (e) => {
  //       let fileObj = event.target.files[0]
  //       let fileDataUrl = e.target.result
  //       if (!file_type.includes("pdf")) {
  //         let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
  //         console.log("pdfconversionresp", response);
  //         fileObj = response["file"]
  //         fileDataUrl = response['dataUrl']
  //         toastDisplay("File converted into pdf format", "success")
  //       }
  //       fileObj["filebase64"] = fileDataUrl
  //       setData({ ...data, [event.target.name]: fileObj })
  //       setErrors({ ...errors, [event.target.name]: "" });
  //     }
  //   }
  // }

  const closeFn = () => {
    setLimitinvoice(true);
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (!Object.keys(err).length) {
      if (tab === 0) {
        setshowLoader(true)
        call('POST', 'changeToSBLCFinanceLimit', { applicationId: quoteId, userId, userTypeId }).then((result) => {
          // console.log('running changeToSBLCFinanceLimit api-->', result);
          toastDisplay(result, "success", () => {
            history.go(-1)
          })
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in changeToSBLCFinanceLimit', e);
          toastDisplay(e || "Something went wrong", "error")
          setshowLoader(false)
        });
        return null
      }
    }
    setErrors(err)

  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="/ApplyforSblc" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for finance limit - SBLC"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"><img
              onClick={() => history.go(-1)}
              className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      {data.buyerId ? (
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16 p-0'><u>Buyer Details</u>
                            {/* <img
                            onClick={() => { window.location = `/buyerManagement?editBuyer=${data.buyerId}` }}
                            className="ms-3 cursor"
                            src={"assets/images/edit.png"} alt="edit" /> */}
                          </label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              return (
                                // <div className='col-5 p-0'>
                                //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                //   <label className='lh-18 font-size-14'>:</label>
                                //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                //   </label>
                                // </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                            {/* <div className='col-5 p-0'>
                            <label className='col-6 lh-18 text-color-label font-size-14'>{"Required Limit"}</label>
                            <label className='lh-18 font-size-14'>:</label>
                            <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              {data["limitRequiredCurrency"] + " " + data["limitRequired"]}
                            </label>
                          </div> */}
                            <div className="col-md-6 pe-5">
                              <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                {data["limitRequiredCurrency"] + " " + data["limitRequired"]}
                              </span> </p>
                            </div>
                          </div>
                        </div >
                      ) : null}
                      <div className={`w-100 ${data.buyerId ? 'mt-5' : ''}`}>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u>
                          {/* <img
                          className="ms-3 cursor"
                          src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                          {/* <img
                            onClick={() => { window.location = `/buyerManagement?editBuyer=${data.buyerId}` }}
                            className="ms-3 cursor"
                            src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row mt-2'>
                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          {data.invoiceDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>) : null}
                          {data.poDocument?.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            if (data["otherDoc" + index]?.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">Add More</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Financiers selected</u></label>
                          <div className="d-flex gap-4 my-3">
                            {lcFinanciers.map((key) => {
                              return (
                                <label key={key.name}
                                  className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                  {key.name}
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div >
                  </div >
                ) : null}
                {/* {tab === 1 ? (
                  <div className='px-5 pt-5 pb-3'>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Request letter of confirmation</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="cursor font-size-13 text-secondary"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">LC Draft</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="cursor font-size-13 text-secondary"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="cursor font-size-13 text-secondary"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                        </div>
                        <div className="col-md-4 text-end">
                          <a className="cursor font-size-13 text-secondary"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</a>
                        </div>
                      </div>
                    </div>
                    {addMoreDoc && addMoreDoc.length ? addMoreDoc.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">Add More</label>
                          <div className="row align-items-center">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                error={errors["otherDoc" + index]}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, ["otherDoc" + index]: null })}
                              />
                              {errors["otherDoc" + index] ? <div className="text-danger mt-2">
                                <i className="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors["otherDoc" + index]}</b></div> : ''}
                            </div>
                            <div className="col-md-4 text-end">
                              <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-size-13"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          </div>
                        </div>
                      )
                    }) : null}
                  </div>
                ) : null}
                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 my-3">
                      <div className="d-flex gap-2 justify-content-between align-items-center">
                        <div className="custom-control ps-0 custom-checkbox form-check">
                          <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked />
                          <label className="form-check-label" for="flexCheckChecked"></label>
                        </div>
                        <div className={`card p-3 col-11 selectfinancercardrec`}>
                          <p className="cursor"><img className="ms-2" src={"assets/images/lc/eximbank.png"} alt="" /> <span className="font-size-14 font-wt-600 text-dark">Exim bank</span></p>

                          <div className="d-flex my-2">
                            <div className="col-3 text-center px-0">
                              <p className="font-size-13">3.0%</p>
                              <p className="font10 font-wt-600 whitespace" >Confirmation Fees</p>
                            </div>
                            <div className="col-3 text-center px-0">
                              <p className="font-size-13">5.6%</p>
                              <p className="font10 font-wt-600 whitespace" >Discounting Rate</p>
                            </div>
                            <div className="col-3 text-center px-0">
                              <p className="font-size-13 mb-0">$ 750</p>
                              <p className="font10 mb-0 whitespace">(negotiable)</p>
                              <p className="font10 font-wt-600 whitespace" > One time set-up fees</p>
                            </div>
                            <div className="col-3 text-center px-0">
                              <p className="font-size-13 mb-0"> $ 1,837</p>
                              <p className="font10 mb-0 whitespace">($ 1,000/30 days)</p>
                              <p className="font10 font-wt-600 whitespace" >  Total Charges</p>
                            </div>
                          </div>
                          <div className="d-flex px-2 align-items-center">
                            <div className="col-4 d-flex align-items-center gap-2 px-0">
                              <p className="mb-1"><img className="" src={"assets/images/supplier-images/chat.png"} alt="" height={15} width={15} /><span className="font11 text-dark ms-1">Chat with financer</span></p>
                            </div>
                            <div className="col-4 d-flex align-items-center gap-2"><p className="mb-0 font11 mt-1">4.8</p>
                              <span ><img src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                              </span>
                            </div>
                            <div className="col-4 d-flex align-items-center gap-2"><p className="mb-0 font11">178</p><p className="font10 mb-0">See reviews</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {tab === 3 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-80'>
                        <label className='font-wt-500 font-size-16 p-0'><u>Buyer Details</u>
                        </label>
                        <div className='row'>
                          {reviewForm2.map((item) => {
                            return (
                              <div className='col-5 p-0 ml-3'>
                                <label className='w-35 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='w-35 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div >
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u> </label>
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                        </label>
                        <div className='row mt-2'>
                          <div className="col-md-4">
                            <label className="font-size-14">Request letter of confirmation</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                          <div className="d-flex">
                            <div className="my-3 col-2 px-0">
                              <p
                                className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                Exin bank
                              </p>
                            </div>

                          </div>
                        </div>

                      </div>
                      <div className='mt-3'>
                        <img
                          onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                          className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                        <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                      </div>
                    </div >
                  </div >
                ) : null} */}

                {tab === 4 && !data.tcAccepted ? null : (
                  <div className="pb-5 px-5">
                    <button type="button"
                      onClick={() => handleValidation(undefined)}
                      className={`new-btn w-20 py-2 px-2 text-white`}>
                      {tab === 3 ? "Get Finance Limit" : "Submit"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div >
      </div >
      {/* {signdoc && <SignDocument handleFile={handleFile} data={data} errors={errors} signdoc={signdoc} setSigndoc={setSigndoc} />} */}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for finance limit has been submitted successfully</p>

      </FinanceInvoiceModal>}
      {experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
        <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
        <p>Apply for LC discounting </p>
        <div className="mb-3 mt-4">
          <button type="button"
            className={`new-btn w-50 py-2 text-white cursor`}>
            Apply Now
          </button>
        </div>
        <div className="mb-2">
          <a href="" className="text-dark">Back to buyer management</a>
        </div>
      </FinanceInvoiceModal >}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyforSblc)