
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"
import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
// import { reviewBG, reviewBusiness, reviewOther } from "./BGGetConfirmation";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import moment from 'moment';
import { ONDCSUBSCRIBERID, platformBackendUrl } from "../../urlConstants";
import axios from 'axios'
import { ondcInitialSearchObj } from "./ondcSampleObjects";

const FillFormONDC = ({ userTokenDetails, navToggleState }) => {

  const [showLoader, setshowLoader] = useState(false)
  let [formHtml, setFormHtml] = useState(localStorage.getItem('ondcHtmlFormContent'))
  const formWrapperRef = useRef(null);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  let offerData = localStorage.getItem('offerData')
  offerData = JSON.parse(offerData)

  useEffect(() => {
    const formWrapper = formWrapperRef.current;
    if (formWrapper) {
      const forms = formWrapper.getElementsByTagName('form');
      if (forms.length > 0) {
        const form = forms[0]; // Assuming there's only one form within the wrapper
        form.addEventListener('submit', handleSubmit);
        return () => {
          form.removeEventListener('submit', handleSubmit);
        };
      }
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    // Get form data
    const formData = new FormData(formWrapperRef.current.getElementsByTagName('form')[0]);
    formData.append('userId', userId)
    formData.append("url", offerData?.message?.catalog?.providers?.[0]?.items?.[0]?.xinput?.form?.url)
    formData.append("offerData", localStorage.getItem('offerData'))
    // Extract form data
    const formDataObject = {};
    for (const [key, value] of formData.entries()) {
      formDataObject[key] = value;
    }
    setshowLoader(true)
    call('POST', 'postONDCHtmlForm', formData).then(res => {
      if (res?.submission_id) {
        toastDisplay("Form submitted successfully", "success", () => {
          window.location = `/gstBasedInvoiceFinanceProductCatalogue?submissionId=${encodeURIComponent(res.submission_id)}`
        })
      }
      else {
        setshowLoader(false)
        toastDisplay("Something went wrong", "error")
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    })
    // console.log("formDataObjectttttttttttttttttttttt", formDataObject);
  }

  return (<>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
    <div className="container-fluid">
      <div className="row">
        <SideBarV2 state={"gstBasedInvoiceFinance"} userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"Other Financial > GST Based Invoice Finance >  Check Offer"}
            userTokenDetails={userTokenDetails} />
          <a onClick={() => { window.location = '/gstBasedInvoiceFinanceDiscovery' }}
            className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

          <div className="card p-4 mt-1">
            <div ref={formWrapperRef} dangerouslySetInnerHTML={{
              __html: formHtml
            }} />
          </div>
        </main>
      </div>
    </div>
  </>)

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(FillFormONDC)