import React, { useEffect, useState } from 'react';

const PriceAdjustmentComp = ({ adjustPrice, setadjustPrice }) => {

  const [values, setValues] = useState([
    {
      key: "",
      value: "",
      operator: ""
    }
  ]);

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    if (adjustPrice)
      setValues([...adjustPrice])
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  function addElement() {

    let tempArray = [...values]
    tempArray.push({
      key: "",
      value: "",
      operator: ""
    })
    setValues(tempArray)
  }

  function removeElement(i) {
    let tempArray = [...values]
    tempArray.splice(i, 1)
    setValues(tempArray)
    setadjustPrice(tempArray);
  }


  const handleChange = (event) => {
    event.persist();
    let temp = [...values]
    temp[event.target.name.split(":")[1]][event.target.name.split(":")[0]] = event.target.value
    setValues(temp)
    setadjustPrice(temp);
  };


  function renderList() {

    let result = values.length ? values.map((value, index) => {
      return (<>
        <div className="col-md-12 row">
          <label className="col-md-3 mr-2 pl-0">Field Name</label>
          <label className="col-md-3 mr-2">-/+</label>
          <label className="col-md-3 mr-2">Amount</label>
        </div>
        <div className="col-md-12 row">
          <div className='col-md-3' >
            <input type="text" className="form-control " placeholder="Enter Field Name" name={"key:" + index} value={value.key} onChange={handleChange} />
          </div>
          <div className='col-md-3' >
            <input type="text" className="form-control " placeholder="Operator (-/+)" name={"operator:" + index} value={value.operator} onChange={handleChange} />
          </div>
          <div className='col-md-3' >
            <input type="text" className="form-control " placeholder="Amount" name={"value:" + index} value={value.value} onChange={handleChange} />
          </div>
          <div className='col-md-3' >
            <button type="button" className="btn btn-sm ml-0 pl-0 pr-3" onClick={() => removeElement(index)}>
              {/* <i class="fa fa-trash"></i> */}
              <img src='assets/images/delete.png' />
            </button>
          </div>
        </div>
        <hr />
      </>
      )
    }) : ""
    return result
  }

  // JSX return
  return (
    <>
      {renderList()}
      <div className="d-flex mt-2 ml-2 btn-sm"><button type="button" className="btn-primary btn btn-sm" onClick={() => addElement()}>Add Clause +</button></div>
    </>
  );
};

export default PriceAdjustmentComp;
