import React, { useEffect, useState } from "react"
import call from "../../../../service";
import toastDisplay from "../../../../utils/toastNotification";
import { InputWithSelect } from "../../../../utils/newInput";
import axios from "axios";
import { platformBackendUrl } from "../../../../urlConstants";

const PopUPEmailText = ({ userId, item, setShowAddReminderTab, clickedBtn }) => {
  console.log(clickedBtn)
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showEmailField, setShowEmailField] = useState(false);
  const [showPhoneField, setShowPhoneField] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [data, setData] = useState({
    nameTitle: "Mr", phoneCode: "91", tcAccept: false,
    designation: "Director"
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
  }, [])
  useEffect(() => {
    // Fetch customer email and phone number
    call('POST', 'getcustomeremail', { userId, customerName: item.customerName })
      .then(result => {
        console.log(result)
        if (result && result.email !== "not there") {
          setEmail(result.email);
          setShowEmailField(false);
        } else {
          setShowEmailField(true);
        }

        if (result && result.mobile !== "not there") {
          setPhoneNumber(result.mobile);
          setShowPhoneField(false);
        } else {
          console.log("done")
          setShowPhoneField(true);
        }
      })
      .catch(error => {
        toastDisplay(error, 'error');
      });
  }, [userId, item]);

  const handleSendEmail = () => {
    call('POST', 'sendPaymentEmail', { userId, bills: [item], email })
      .then(() => {
        toastDisplay('Email sent successfully', 'success');
      })
      .catch(error => {
        toastDisplay(error, 'error');
      });
    handleClose()
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleWhatsapp = () => {
    const { customerName, invoiceNo, TotalAmount, pendingAmount, pendingDays } = item;

    const billDetails = `
    <tr>
      <td>${invoiceNo}</td>
      <td>${TotalAmount}</td>
      <td>${pendingAmount}</td>
      <td>${pendingDays}</td>
    </tr>
  `;

    // const userName = "YourCompanyName";
    const today = new Date().toLocaleDateString();

    const message = `
    *Pending Payment Reminder*\n
    Dear Sir/Madam,
    Please find attached, list of your bills payable as on ${today}.
    Invoice No : ${invoiceNo} 
    Bill Amount : $ ${TotalAmount}
    Outstanding : $ ${pendingAmount} 
    We request you to make the payment at your earliest. Please ignore if already paid.\n\n
    
  `;

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    window.open(url, '_blank');
  };


  const handleSendText = () => {
    const phoneNumber = `+${data.phoneCode}${data.contactNo}`;
    call('POST', 'sendPaymentText', { userId, bills: [item], phoneNumber })
      .then(() => {
        toastDisplay('Text message sent successfully', 'success');
      })
      .catch(error => {
        toastDisplay(error, 'error');
      });
  };

  const handleClose = () => {
    setShowAddReminderTab(false);
  };

  return (
    <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Send Notification</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {clickedBtn === "email" ? (
              <div>
                {!showEmailField ? (
                  <p>Email: {email || 'N/A'}</p>
                ) : (
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                )}
              </div>
            ) : clickedBtn === "mobile" || clickedBtn === "whatsapp" ? (
              <div>
                {!showPhoneField ? (
                  <p>Phone Number: {phoneNumber || 'N/A'}</p>
                ) : (
                  <div className="mb-3">
                    {/* <label htmlFor="phoneNumber" className="form-label">Phone Number</label> */}
                    <InputWithSelect
                      selectData={countryData}
                      selectName="phoneCode"
                      selectValue={data.phoneCode}
                      optionLabel="phonecode"
                      optionValue="phonecode"
                      type="tel"
                      name="contactNo"
                      value={data.contactNo}
                      onChange={handleChange}
                      label="Mobile Number *"
                      error={errors["contactNo"]}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            {clickedBtn === "email" && <button type="button" className="btn btn-primary" onClick={handleSendEmail} disabled={!email}>Send Email</button>
            }{clickedBtn === "mobile" && <button type="button" className="btn btn-success" onClick={handleSendText} disabled={!data.contactNo}>Send Text</button>
            }{clickedBtn === "whatsapp" && <button type="button" className="btn btn-success" onClick={handleWhatsapp} disabled={!data.contactNo}>Send Whatsapp message</button>
            }</div>
        </div>
      </div>
    </div>
  );
};

export default PopUPEmailText;