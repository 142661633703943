import React, { useEffect, useState } from 'react';
import call from '../../service';

const CountryList = () => {

  const [countryData, setcountryData] = useState([]);

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Country List Component
  return (
    <>
      {  countryData.length ? countryData.map((item, index) => {
        return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
      }) : ''}
    </>
  )
}

//---------------------------------------------------------------------------------------------------------------------

export default CountryList
