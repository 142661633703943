const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Country',
    selector: 'country',
    sortable: true,
  },
  {
    name: 'Nationality',
    selector: 'nationality',
    sortable: true,
  },
  {
    name: 'Email ID',
    selector: 'email_id',
    sortable: true,
  },
  {
    name: 'Share in Company(%)',
    selector: 'share',
    sortable: false,
    grow: 2,
  },
  {
    name: 'ITR',
    selector: 'itr',
    sortable: false,
  },
  {
    name: 'DIN',
    selector: 'din',
    sortable: false,
  },
  {
    name: 'Authorized Signatory',
    selector: 'signatory',
    sortable: false,
  },
  {
    name: 'PAN Document',
    selector: 'kyc_doc1',
    sortable: false,
  },
  {
    name: 'Aadhaar Document',
    selector: 'kyc_doc2',
    sortable: false,
  },
  {
    name: 'Actions',
    selector: 'action',
    sortable: false,
  }
];

export default columns