import React, { useEffect, useState } from "react";
import call from "../../../../service";
import AddTarget from "./addTarget";
import ProgressBar from "./progressBar";
import { format } from "date-fns";

export default function TargetCard({ target, fetchAndSetTargets, userId, allData }) {

  console.log("taget in card", target)
  const [tableData, setTableData] = useState([]);
  const [editTarget, setEditTarget] = useState(target);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAchivement, setShowAchievement] = useState(false)
  const labelKeys = target["whom"];

  const columns = [
    { header: "Variables", accessor: "variable" },
    { header: "Target ($)", accessor: "target" },
    { header: "Achieved ($)", accessor: "achieved" },
    { header: "Percentage", accessor: "percentage" },
  ];

  const handleEditTarget = () => {
    setShowEditPopup(true);
  };

  const handleDeleteTarget = () => {
    call("POST", "deletetarget", { userId: userId, targetId: target.id })
      .then((result) => {
        fetchAndSetTargets()
      }).catch((e) => {
        console.log('error in target_deletion', e)
      })
  }
  const processTargetData = (target) => {
    const targetSums = {};
    const achievedSums = {};

    target.target.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!labelKeys.includes(key) && !key.startsWith("achieved_")) { // Exclude label keys and achieved keys
          targetSums[key] = (targetSums[key] || 0) + parseInt(item[key] || 0, 10);
          const achievedKey = `achieved_${key.toLowerCase()}`;
          achievedSums[key] = (achievedSums[key] || 0) + parseInt(item[achievedKey] || 0, 10);
        }
      });
    });

    const tableData = Object.keys(targetSums).map((variable) => {
      const targetValue = targetSums[variable];
      const achievedValue = achievedSums[variable];
      const percentage = targetValue > 0 ? (achievedValue / targetValue) * 100 : 0;

      return {
        variable,
        target: targetValue,
        achieved: achievedValue,
        percentage: percentage.toFixed(2),
      };
    });

    return tableData;
  };




  useEffect(() => {
    const aggData = processTargetData(target);
    setTableData(aggData);
    console.log(target)
    // console.log("Aggregated Data:", aggData);
  }, [target]);
  // TargetvsAchivementShow

  const handleTargetvsAchivementShow = () => {
    setShowAchievement(!showAchivement)
  }

  const totalTarget = tableData.reduce((sum, row) => sum + row.target, 0);
  const totalAchieved = target.achieved || 0;
  const percentageAchieved = totalTarget > 0 ? (totalAchieved / totalTarget) * 100 : 0;
  return (
    <div className="d-flex justify-content-between align-items-top border rounded shadow mt-3" >
      <div className="border-end w-30 " style={{ height: "fit-content" }}>
        <p
          style={{ color: "black", display: "inline-block", marginBottom: 0, backgroundColor: "#c7d4d3" }}
          className="p-1 fs-6 rounded-bottom"
        >
          {`${format(new Date(target.start_date), 'MM/dd/yyyy')} To ${format(new Date(target.end_date), 'MM/dd/yyyy')}`}
        </p>        <div className="m-2 border rounded">
          <p style={{ color: "white", display: "inline-block", backgroundColor: "rgb(136, 169, 199)" }} className="p-1 mb-2 fs-6 rounded-bottom">Companies</p>
          <div className="m-2">
            {
              target["companies"].map(item => <div style={{ display: "inline-block" }} className="p-1 border rounded me-1">{item}</div>)
            }

          </div>
        </div>
        <div className="m-2 border rounded">
          <p style={{ color: "white", display: "inline-block", backgroundColor: "rgb(136, 169, 199)" }} className="p-1 mb-2 fs-6 rounded-bottom">Labels</p>
          <div className="m-2">
            {
              target["whom"].map(item => <div style={{ display: "inline-block" }} className="p-1 border rounded me-1">{item}</div>)
            }

          </div>
        </div>
        <div className="m-2 border rounded">
          <p style={{ color: "white", display: "inline-block", backgroundColor: "rgb(136, 169, 199)" }} className="p-1 mb-2 fs-6 rounded-bottom">Variables</p>
          <div className="m-2">
            {Array.from(new Set(
              target.target.flatMap(obj =>
                Object.keys(obj).filter(key => !labelKeys.includes(key) && !key.startsWith("achieved_"))
              )
            )).map(item => (
              <div key={item} style={{ display: "inline-block" }} className="p-1 border rounded me-1">{item}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="border-start d-flex position-relative justify-content-start   align-items-top w-70 p-3">
        <div className="d-flex justify-content-end align-items-center position-absolute m-3 " style={{ top: 0, right: 0 }} >
          {/* <img onClick={handleEditTarget} className="d-block me-2 cursor-pointer border rounded p-1 border-secondary" src="assets/images/edit.png" width={"30px"} height={"30px"} /> */}
          <img onClick={handleDeleteTarget} className="d-block cursor-pointer border rounded p-1 border-secondary" src="assets/images/delete.png" width={"30px"} height={"30px"} />
        </div>
        {showEditPopup && (
          <AddTarget
            handleClosePopup={() => { setShowEditPopup(false) }}

            editTarget={editTarget}
            allData={allData}


            variables={Array.from(new Set(
              target.target.flatMap(obj =>
                Object.keys(obj).filter(key => !labelKeys.includes(key))
              )
            ))}

            onSave={() => {
              setShowEditPopup(false);
              setEditTarget(null);
              fetchAndSetTargets();
            }}

          />
        )}
        {showAchivement ? (
          <DetailedTable target={target} />
        ) : (
          <>
            <CustomTable columns={columns} data={tableData} />
            <ProgressBar percentage={percentageAchieved.toFixed(2)} />
          </>
        )}
        <div className="position-absolute m-3 p-1 cursor-pointer border rounded d-flex justify-content-center align-items-center " style={{ bottom: 0, right: 0, backgroundColor: "#c5e0cd", width: "50px", height: "30px" }}>
          <h3 onClick={handleTargetvsAchivementShow} className="p-0 m-0">→</h3>
        </div>
      </div>
    </div>
  );
}

const CustomTable = ({ columns, data, customWidth }) => {
  return (
    <table style={{ borderCollapse: 'collapse', width: customWidth ? customWidth : '50%', height: "fit-content" }} className="table me-3">
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index} style={{ border: '1px solid #D5D5D5', padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2' }}>
              {col.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <td key={colIndex} style={{ border: '1px solid #D5D5D5', padding: '8px' }}>
                {row[col.accessor] || 0}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const DetailedTable = ({ target }) => {
  // Extract all unique keys (except "Company") from the target objects
  const allKeys = target.target.flatMap(obj => Object.keys(obj)).filter(key => key !== "Company");

  // Create dynamic columns based on the unique keys
  const columns = [
    { header: "Company", accessor: "company" },
    ...Array.from(new Set(allKeys)).map(key => ({
      header: key,
      accessor: key.toLowerCase(),
    })),
  ];

  // Create data rows based on the target objects
  const data = target.target.map(item => {
    const rowData = { company: target.companies[0] };
    Object.keys(item).forEach(key => {
      if (key !== "Company") {
        rowData[key.toLowerCase()] = item[key];
      }
    });
    return rowData;
  });

  return <CustomTable columns={columns} data={data} customWidth={"80%"} />;
};
