import React, { useEffect, useState } from 'react';
import { setCreditlineDataBank } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import ReactCountryFlag from "react-country-flag";
import call from '../../service';
import Footer from '../partial/footer';
import validate from '../../utils/validation_files/uploadBuyerDetails';
import uploadvalidate from '../../utils/validation_files/uploadBuyerDocDetails';
import toastDisplay from '../../utils/toastNotification';
import MyCounterDetailsGrid from './myCounterDetailGrid';
import config from '../../config.json';
import { ClearCache, GetCache, most_used_currencies, SetCache } from '../../utils/myFunctions';

const AllCounterPart = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail, adMinPortal, creditlineDataBank, setCreditlineDataBank, navToggleState, dispatch }) => {

  let cache_data = GetCache("add_buyer_form_data");
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [count, setCount] = useState([]);
  const [data, setData] = useState(cache_data["data"] || {});
  const [error, setError] = useState({});
  const [file, setFile] = useState({});
  const [filterData, setFilterData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [accord, setaccord] = useState({
    0: false,
    1: false,
  });
  const [countryData, setcountryData] = useState([]);
  const [buyerDetail, setBuyerDetail] = useState({ modal: false });
  const [allBuyerDetail, setAllBuyerDetail] = useState([]);
  const [dunsNo, setDUNSNo] = useState({ modal: false, number: [] });
  const [selectedDunsDetails, setselectedDunsDetails] = useState(cache_data['selectedDunsDetails'] || '')
  const [buyerCredit, setBuyerCredit] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [subUserCriteria, setSubUserCriteria] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [userProfileStatus, setUserProfileStatus] = useState({ show: false, data: {} });
  const [showFullForm, toggleFullForm] = useState(false)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect

  useEffect(() => {

    setaccord({
      0: true,
      1: true
    });

    let objectAPI = {
      "userId": manualUserId ? manualUserId : userId,
      "userEmail": manualUserEmail ? manualUserEmail : userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }

    objectAPI = { ...objectAPI };

    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      setAllBuyerDetail(result.allBuyersDetail);
      setCount(result.countData);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    });

    call('POST', 'getBuyerCredit', objectAPI).then((result) => {
      console.log('running getBuyerCredit api-->', result);
      setBuyerCredit(result);

    }).catch((e) => {
      console.log('error in getBuyerCredit', e);
    });

    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    if (parentData == true) {
      call('POST', 'getSubUserCriteria', { "subUserId": subUserId }).then((result) => {
        console.log('result in getSubUserCriteria => ', result[0]);
        setSubUserCriteria(result[0]);
      })
    }

  }, []);

  useEffect(() => {
    SetCache('add_buyer_form_data', { data, selectedDunsDetails })
  }, [data, selectedDunsDetails])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      uploadHandleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    //---------------------------------------------------------------------------------------------------------------------

  }, [refresh, filterData]);

  // useEffect(() => {
  //   if (data.buyerName && data.buyerCountry) {
  //     call('POST', 'getDUNSNo', { companyName: data.buyerName, countryCode: data.buyerCountry, userId: manualUserId ? manualUserId : userId, userEmail: manualUserEmail ? manualUserEmail : userEmail }).then((result) => {
  //       if (result.length > 0) {
  //         setDUNSNo({ modal: true, number: result });
  //       }
  //       else {
  //         toastDisplay("No Related Buyer Details Found", "error")
  //         setDUNSNo({ modal: false, number: [] });
  //       }
  //     })
  //   }
  // }, [data.buyerName && data.buyerCountry])

  function getDunsList() {
    setshowLoader(true)
    call('POST', 'getDUNSNo', { companyName: data.buyerName, countryCode: data.buyerCountry, userId: manualUserId ? manualUserId : userId, userEmail: manualUserEmail ? manualUserEmail : userEmail }).then((result) => {
      setshowLoader(false)
      if (result.length > 0) {
        setDUNSNo({ modal: true, number: result });
      }
      else {
        toastDisplay("No Related Buyer Details Found", "error")
        setData({ ...data, buyerDUNSNo: "" })
        setDUNSNo({ modal: false, number: [] });
        toggleFullForm(true)
      }
    })
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  function toolTip(inputName) {
    if (inputName == 'uploadBuyersDetail') {
      let text = "Upload an excel file having extension of .xlsx with your buyer's detail having column i.e., Name, Email, Contact No., Address, Country Code, Postal Code, DUNS Number, Previous year annual sale, Expected annual sale for current year and product details.";
      return (
        <span className="help-icon" flow="right" tooltip={text}>i</span>
      )
    }
  };

  const handleChange = (event) => {
    event.persist();
    if (event.target.name == 'buyerDUNSNo') {
      toggleFullForm(true)
      setDUNSNo({ modal: false, number: [] });
      setselectedDunsDetails(event.target.value)
      setData({ ...data, [event.target.name]: event.target.value.split(':')[0], ['buyerAddress']: event.target.value.split(':')[1] + ', ' + event.target.value.split(':')[2], ['buyerPostalcode']: event.target.value.split(':')[3] });
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
    setError({ ...error, [event.target.name]: "" });
  };

  const handleFiles = (event) => {
    event.persist();
    setFile({ ...file, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  };

  const handleUploadSubmit = (event) => {
    if (event) event.preventDefault();
    setError(uploadvalidate(data));
    setIsSubmitting(true);
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, file));
    setIsSubmitting(true);
  };

  const uploadHandleSubmit = () => {
    data.userId = manualUserId ? manualUserId : userId
    data.userEmail = manualUserEmail ? manualUserEmail : userEmail
    data.userName = userName
    if (dunsNo.modal == true) {
      data.buyerDUNSNo = dunsNo.number
    }
    data["baseUrl"] = config.baseUrl

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    Object.keys(file).forEach((key) => {
      formData.append(key, file[key]);
    });
    setshowLoader(true);

    call('POST', "insertBuyersDetail", formData).then((result) => {
      console.log('post request result:', result);
      setshowLoader(false);
      ClearCache("add_buyer_form_data")
      toastDisplay('Details Saved Successfully', "success");
      setrefresh(refresh + 1)
      setIsSubmitting(false)
      setaccord(false);
      checkProfileStatus()

    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err, "error");
      checkProfileStatus()
    })
  };

  function checkProfileStatus() {
    call('POST', "getProfileStatus", { userId: userId, email: userEmail, kyc: true, manualUserId: userId }).then((result) => {
      // console.log('getProfileStatus result:', result);
      if (!(result.isBusinessDocUploaded && result.isFinancialDetailsUploaded && result.isKycDocUploaded)) {
        setUserProfileStatus({ show: true, data: result })
      }
    }).catch(err => {
      // console.log("conn:", err)
    })
  }
  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {!adMinPortal ?
        <>
          <div className="container-fluid">
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div className="row">
              <Sidebar state={'counterPart'} userTokenDetails={userTokenDetails} />
              <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

                <Header
                  title="Buyers Detail"
                  total={"Total - " + count + " Buyers Detail"}
                  userTokenDetails={userTokenDetails} />

                <div className="d-md-flex mt-3 mb-3 notify_list">
                  <div className="ml-md-auto relative">
                  </div>
                </div>
                <div className="creditline_listing">
                  <div className="col-md-12 bg-light shadow">
                    <div className="col-md-12 p-2">
                      <div className="row">

                        <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['0'] ? " open" : " closed")}>
                          <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '0': !accord['0'] })}>Add Buyers</h2>
                          <div className="accordionItemContent">
                            <div className="row pt-3">

                              <div className="form-group col-md-6">
                                <label>Buyer Name {astrix} </label>
                                <ul className="range-slider">
                                  <li className='w-100'>
                                    <input type="text" className={" form-control" + (!error.buyerName ? '' : ' border-danger')} placeholder="" name="buyerName" value={data.buyerName} onChange={handleChange} required disabled={parentData == true && subUserCriteria.myCounterParts == '0'} />
                                  </li>
                                </ul>
                                {error.buyerName && <p className="text-danger error-contract">{error.buyerName}</p>}
                              </div>

                              <div className="form-group col-md-6" id="POScountry">
                                <label>Buyer Country {astrix} </label>
                                <select className={" form-control" + (!error.buyerCountry ? '' : ' border-danger')} name="buyerCountry" value={data.buyerCountry} onChange={handleChange} disabled={parentData == true && subUserCriteria.myCounterParts == '0'}  >
                                  <option value="" selected>Select Country</option>
                                  {countryData.map((country) => {
                                    return (
                                      <option value={country.sortname} >{country.name}</option>
                                    )
                                  })}
                                </select>
                                {error.buyerCountry && <p className="text-danger error-contract">{error.buyerCountry}</p>}
                              </div>

                              <div className="form-group col-md-12 text-center" id="POScountry">
                                <button type="button"
                                  className='btn btn-primary btn-sm'
                                  disabled={!(data.buyerName && data.buyerCountry)}
                                  onClick={() => {
                                    getDunsList()
                                  }}
                                >Fetch From DUNS
                                  {/* <i class="fas fa-search fa-lg"></i> */}
                                </button>
                              </div>

                              {showFullForm &&
                                <>
                                  <div className="form-group col-md-6">
                                    <label>Buyer Email</label>
                                    <input type="email" className={" form-control" + (!error.buyerEmail ? '' : ' border-danger')} placeholder="" name="buyerEmail" value={data.buyerEmail} onChange={handleChange} required />
                                    {/* {error.buyerEmail && <p className="text-danger error-contract">{error.buyerEmail}</p>} */}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Buyer Contact No.</label>
                                    <input type="number" className={" form-control" + (!error.buyerContact ? '' : ' border-danger')} placeholder="" name="buyerContact" value={data.buyerContact} onChange={handleChange} required />
                                    {error.buyerContact && <p className="text-danger error-contract">{error.buyerContact}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Buyer Address {astrix} </label>
                                    <input type="text" className={" form-control" + (!error.buyerAddress ? '' : ' border-danger')} placeholder="" name="buyerAddress" value={data.buyerAddress} onChange={handleChange} required disabled={parentData == true && subUserCriteria.myCounterParts == '0'} />
                                    {error.buyerAddress && <p className="text-danger error-contract">{error.buyerAddress}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Postal Code {astrix} </label>
                                    <input type="text" className={" form-control" + (!error.buyerPostalcode ? '' : ' border-danger')} placeholder="" name="buyerPostalcode" value={data.buyerPostalcode} onChange={handleChange} required />
                                    {error.buyerPostalcode && <p className="text-danger error-contract">{error.buyerPostalcode}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Website</label>
                                    <input type="text" className={" form-control" + (!error.buyerWebsite ? '' : ' border-danger')} placeholder="" name="buyerWebsite" value={data.buyerWebsite} onChange={handleChange} required />
                                    {/* {error.buyerWebsite && <p className="text-danger error-contract">{error.buyerWebsite}</p>} */}
                                  </div>

                                  {/* <div className="form-group col-md-6">
                                    <label>Currency {astrix} </label>
                                    <select className={"form-control" + (error.buyerCurrency ? " border-danger" : "")} name="buyerCurrency" value={data.buyerCurrency} onChange={handleChange} >
                                      <option value="" selected>--Select Currency--</option>
                                      <option value="2:USD">Dollars ( USD )</option>
                                      <option value="11:EUR">Euro ( EUR )</option>
                                      <option value="19:GBP">Pounds ( GBP )</option>
                                    </select>
                                    {error.buyerCurrency && <p className="text-danger error-contract">{error.buyerCurrency}</p>}
                                  </div> */}

                                  <div className="form-group col-md-6">
                                    <label>DUNS No </label>
                                    {dunsNo.modal ?
                                      <input type="text" className={" form-control" + (!error.buyerDUNSNo ? '' : ' border-danger')} placeholder="" name="buyerDUNSNo" value={dunsNo.number} />
                                      :
                                      <input type="text" className={" form-control" + (!error.buyerDUNSNo ? '' : ' border-danger')} placeholder="" name="buyerDUNSNo" value={data.buyerDUNSNo} onChange={handleChange} required />
                                    }
                                    {/* {error.buyerDUNSNo && <p className="text-danger error-contract">{error.buyerDUNSNo}</p>} */}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Previous year annual sales {astrix} </label>
                                    <div className='row mx-1'>
                                      <input type="number" className={" form-control w-75" + (!error.annualSale ? '' : ' border-danger')} placeholder="" name="annualSale" value={data.annualSale} onChange={handleChange} required />
                                      <select className={"form-control w-25" + (error.buyerCurrency ? " border-danger" : "")} name="buyerCurrency" value={data.buyerCurrency} onChange={handleChange} >
                                        <option value={""}>Select Currency</option>
                                        {most_used_currencies.length ? most_used_currencies.map((item, index) => (
                                          <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                                        ))
                                          : null}
                                        <option value={""}>--- All Currencies ---</option>
                                        {currencyData.length ? currencyData.map((item, index) => (
                                          <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                                        ))
                                          : null}
                                      </select>
                                    </div>
                                    {error.annualSale && <p className="text-danger error-contract">{error.annualSale}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Expected annual sales for current year {astrix} </label>
                                    <div className='row mx-1'>
                                      <input type="number" className={" form-control w-75" + (!error.expectedAnnualSale ? '' : ' border-danger')} placeholder="" name="expectedAnnualSale" value={data.expectedAnnualSale} onChange={handleChange} required />
                                      <select className={"form-control w-25" + (error.buyerCurrency ? " border-danger" : "")} name="buyerCurrency" value={data.buyerCurrency} onChange={handleChange} >
                                        <option value={""}>Select Currency</option>
                                        {most_used_currencies.length ? most_used_currencies.map((item, index) => (
                                          <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                                        ))
                                          : null}
                                        <option value={""}>--- All Currencies ---</option>
                                        {currencyData.length ? currencyData.map((item, index) => (
                                          <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                                        ))
                                          : null}
                                      </select>
                                    </div>
                                    {error.expectedAnnualSale && <p className="text-danger error-contract">{error.expectedAnnualSale}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Inco Terms {astrix} </label>
                                    <select className={"form-control" + (error.incoTerms ? " border-danger" : "")} name="incoTerms" value={data.incoTerms} onChange={handleChange} >
                                      <option value="" selected>--Select Inco Terms--</option>
                                      <option value="EXW">EXW</option>
                                      <option value="FCA">FCA</option>
                                      <option value="FAS">FAS</option>
                                      <option value="FOB">FOB</option>
                                      <option value="CFR">CFR</option>
                                      <option value="CIF">CIF</option>
                                      <option value="CPT">CPT</option>
                                      <option value="CIP">CIP</option>
                                      <option value="DAP">DAP</option>
                                      <option value="DPU">DPU</option>
                                      <option value="DDP">DDP</option>
                                    </select>
                                    {error.incoTerms && <p className="text-danger error-contract">{error.incoTerms}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Terms of payment {astrix} </label>
                                    <input type="text" className={" form-control" + (!error.payment ? '' : ' border-danger')} placeholder="" name="payment" value={data.payment} onChange={handleChange} required />
                                    {error.payment && <p className="text-danger error-contract">{error.payment}</p>}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>Product Details {astrix} </label>
                                    <input type="text" className={" form-control" + (!error.productDetail ? '' : ' border-danger')} placeholder="" name="productDetail" value={data.productDetail} onChange={handleChange} required />
                                    {error.productDetail && <p className="text-danger error-contract">{error.productDetail}</p>}
                                  </div>

                                  <div className="col-md-12">
                                    <h3 className="pt-2 pb-2"><u>Documents</u></h3>
                                    <div className="col-md-12 pt-2 pb-3">
                                      <ul className="other-documents">
                                        <li>
                                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                        </li>
                                        <li>
                                          <h3 className={"custum-control" + (error.previousInvoiceDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Previous Invoice Doc {astrix}</h3>
                                        </li>
                                        <li>
                                          <div className="file-browse">
                                            <button className="btn btn-primary btn-sm">Upload Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf" name="previousInvoiceDocument" onChange={handleFiles} />
                                          </div>
                                        </li>
                                      </ul>
                                      {file.previousInvoiceDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.previousInvoiceDocument.name}`}</div></div> : ""}
                                      {error.previousInvoiceDocument ? <div class="text-danger error-contract">{error.previousInvoiceDocument}</div> : ''}
                                    </div>
                                    <hr />
                                    <div className="col-md-12 pt-2 pb-3">
                                      <ul className="other-documents">
                                        <li>
                                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                        </li>
                                        <li>
                                          <h3 className={"custum-control" + (error.previousPODocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Previous PO Doc {astrix}</h3>
                                        </li>
                                        <li>
                                          <div className="file-browse">
                                            <button className="btn btn-primary btn-sm">Upload Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf" name="previousPODocument" onChange={handleFiles} />
                                          </div>
                                        </li>
                                      </ul>
                                      {file.previousPODocument ? <div className="form-group mb-0"><div className="file-name">{`${file.previousPODocument.name}`}</div></div> : ""}
                                      {error.previousPODocument ? <div class="text-danger error-contract">{error.previousPODocument}</div> : ''}
                                    </div>
                                  </div>
                                </>
                              }

                              {/* {data.buyerEmail &&
                                <div className="col-md-12">
                                  <div className="form-check pl-0 mt-1 mb-1">
                                    <input className="form-check-input" type="checkbox" name="buyerNotification" id="buyerNotification" onChange={hanleDocChecks} checked={data.buyerNotification} />
                                    <label className="form-check-label" for="buyerNotification">
                                      I want to send notification to the newly added buyer through email.
                                    </label>
                                  </div>
                                </div>} */}

                              <hr className="col-md-12" />

                              {data.buyerDUNSNo && showFullForm &&
                                <div className="form-group col-md-12">
                                  <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={preHandleSubmit} disabled={parentData == true && subUserCriteria.myCounterParts == '0'} >Submit</button>
                                </div>}
                            </div>
                          </div>
                        </div>

                        {/* <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['1'] ? " open" : " closed")}>
                          <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '1': !accord['1'] })}>Upload Buyer Details</h2>
                          <div className="accordionItemContent">
                            <div className="row pt-3">

                              <div className="form-group col-md-6" id="buyerDetails">
                                <ul className="other-documents pb-0 mb-0">
                                  <li style={{ "min-width": "300px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      Buyer Details {astrix} {toolTip('uploadBuyersDetail')}
                                    </label>
                                  </li>
                                  <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                    <div className="file-browse">
                                      <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".xlsx" id="file_1" name={"buyerDetails" + "51"} onChange={handleFile} />
                                      {error["buyerDetails" + "51"] && <p className="error-contract p-0 m-0">{error["buyerDetails" + "51"]}</p>}
                                    </div>
                                  </li>
                                  <li style={{ "min-width": "500px" }}>
                                    {(data["buyerDetails" + "51"]) ? <div className="filePath text-left"><div className="file-name">{`${data["buyerDetails" + "51"].name}`}</div></div> : ""}
                                  </li>
                                  <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                    <div className="file-browse">
                                      <button type="button" className="btn btn-primary btn-sm" onClick={() => setBuyerDetail({ modal: true })}>Buyer's Detail</button>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div className="col-md-12">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="bulkBuyerNotification" id="bulkBuyerNotification" onChange={hanleDocChecks} checked={data.bulkBuyerNotification} />
                                  <label className="form-check-label" for="bulkBuyerNotification">
                                    I want to send notification to the newly added buyer through email.
                                  </label>
                                </div>
                              </div>

                              <hr className="col-md-12" />

                              <div className="form-group col-md-12">
                                <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={handleUploadSubmit} >Submit</button>
                              </div>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>

                    <hr />

                    <MyCounterDetailsGrid userTokenDetails={userTokenDetails} buyerCredit={buyerCredit} />

                    {dunsNo.modal &&
                      <div className={"modal" + (dunsNo.modal ? " show" : "")} id="Confirmation">
                        <div className="modal-dialog modal-lg border-inner" id="parent_class">
                          <div className="modal-content">
                            <div className="modal-header primary">
                              <h4 className="modal-title text-white">DUNS Number Verification</h4>
                              <button type="button" className="close" onClick={() => { setDUNSNo({ modal: false, number: '' }) }}>×</button>
                            </div>
                            <div className="modal-body">
                              <h6 className="text-success mr-1 text-center">Hey <b>'{data.buyerName}'</b> please select your DUNS Number</h6>
                              <div className="form-group col-md-12">
                                <label>DUNS Number</label>
                                <select className=" form-control" name="buyerDUNSNo"
                                  value={selectedDunsDetails}
                                  onChange={handleChange} >
                                  <option value="" selected>-- Select DUNS Number --</option>
                                  {dunsNo.number.map((dunsDetail) => {
                                    return (
                                      <option value={dunsDetail.duns + ':' + dunsDetail.address.street + ':' + dunsDetail.address.city + ':' + dunsDetail.address.postalCode} >{dunsDetail.companyName + " | DUNS Number -- " + dunsDetail.duns + "       |      " + dunsDetail.address.street + ', ' + dunsDetail.address.city + " - " + dunsDetail.address.postalCode}</option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="modal-footer primary">
                              {/* <button type="button" className="btn btn-success btn-sm" onClick={() => { setData({ ...data, buyerDUNSNo: dunsNo.number }); setDUNSNo({ modal: false, number: '' }) }}>Correct</button> */}
                              <button type="button" className="btn btn-info btn-sm" onClick={() => { setDUNSNo({ modal: false, number: '' }) }}>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {userProfileStatus.show &&
                      <div className={"modal" + (userProfileStatus.show ? " show" : "")} id="Confirmation">
                        <div className="modal-dialog modal-lg border-inner" id="parent_class">
                          <div className="modal-content">
                            <div className="modal-header primary">
                              <h4 className="modal-title text-white">Update Profile Details</h4>
                              <button type="button" className="close" onClick={() => { setUserProfileStatus({ show: false, data: {} }) }}>×</button>
                            </div>
                            <div className="modal-body">
                              <h6 className="text-success mr-1 text-center">Hey <b>'{userName}'</b> please complete below details to get best offer</h6>

                              <div className='text-center mt-4'>
                                {!userProfileStatus.data.isKycDocUploaded && <h6 className=''>1) KYC DOCUMENTS</h6>}
                                {!userProfileStatus.data.isFinancialDetailsUploaded && <h6 className=''>2) FINANCIAL DETAILS</h6>}
                                {!userProfileStatus.data.isBusinessDocUploaded && <h6 className=''>3) BUSINESS DOCUMENTS</h6>}
                              </div>

                            </div>
                            <div className="modal-footer primary">
                              {/* <button type="button" className="btn btn-success btn-sm" onClick={() => { setData({ ...data, buyerDUNSNo: dunsNo.number }); setDUNSNo({ modal: false, number: '' }) }}>Correct</button> */}
                              <button type="button" className="btn btn-info btn-sm" onClick={() => { window.location = "/editprofile" }}>Do Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {buyerDetail.modal &&
                      <div className={"modal" + (buyerDetail.modal ? " show" : "")} id="Confirmation">
                        <div className="modal-dialog modal-lg border-inner" id="parent_class">
                          <div className="modal-content">
                            <div className="modal-header primary">
                              <h4 className="modal-title text-white">Buyer's Detail</h4>
                              <button type="button" className="close" onClick={() => { setBuyerDetail({ modal: false }) }}>×</button>
                            </div>
                            <div className="modal-body calc-modal">
                              <div className="table-responsive" style={{ "min-height": "150px" }}>
                                <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                  <thead>
                                    <tr style={{ fontSize: "14px" }}>
                                      <th>#</th>
                                      <th width="500">Buyer Name</th>
                                      <th width="300">Buyer Email</th>
                                      {/* <th width="300">Buyer Contact No. </th>
                                  <th width="300">Buyer Address</th>
                                  <th width="300">Buyer Country</th>
                                  <th width="300">Previous year annual sales</th>
                                  <th width="150">Expected annual sales for current year</th>
                                  <th width="300">Terms of payment (days)</th>
                                  <th width="300">Product Details</th> */}
                                      <th width="300">DUNS No</th>
                                      <th width="300">Status</th>
                                    </tr>
                                  </thead>
                                  {allBuyerDetail.map((values, key) => {
                                    return (
                                      <tbody>
                                        <tr style={{ fontSize: "14px" }}>
                                          <td >{key + 1}</td>
                                          <td >{values.buyerName}</td>
                                          <td>{values.buyerEmail}</td>
                                          {/* <td >{values.buyerContact}</td>
                                      <td >{values.buyerAddress}</td>
                                      <td >{values.buyerCountry}</td>
                                      <td >{values.annualSale}</td>
                                      <td >{values.expectedAnnualSale}</td>
                                      <td >{values.payment} Days</td>
                                      <td >{values.productDetail}</td> */}
                                          <td >{values.buyerDUNSNo}</td>
                                          <td style={{ backgroundColor: values.verification_status == 1 ? 'rgba(63, 195, 128, 0.9)' : 'rgba(248, 0, 0, 0.9)', color: 'white' }}>{(values.verification_status == 1) ? 'Verified' : 'Not Verified'}</td>
                                        </tr>
                                      </tbody>
                                    )
                                  })}
                                </table>
                              </div>
                            </div>
                            <div className="modal-footer primary">
                              <button type="button" className="btn btn-info btn-sm" onClick={() => { setBuyerDetail({ modal: false }) }}>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </main>
            </div>
          </div>
          {/* footer start */}
          <Footer />
          {/* footer end */}
        </>
        :
        <>
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
          <div className="d-md-flex mt-3 mb-3 notify_list">
            <div className="ml-md-auto relative">
            </div>
          </div>
          <div className="creditline_listing">
            <div className="col-md-12 bg-light shadow">
              <div className="col-md-12 p-2">
                <div className="row">

                  <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['0'] ? " open" : " closed")}>
                    <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '0': !accord['0'] })}>Add Buyers (Manually)</h2>
                    <div className="accordionItemContent">
                      <div className="row pt-3">

                        <div className="form-group col-md-6">
                          <label>Buyer Name {astrix} </label>
                          <ul className="range-slider">
                            <li className='w-100'>
                              <input type="text" className={" form-control" + (!error.buyerName ? '' : ' border-danger')} placeholder="" name="buyerName" value={data.buyerName} onChange={handleChange}
                                required />
                              {/* <button type="button"
                                disabled={!(data.buyerName && data.buyerCountry)}
                                onClick={() => {
                                  getDunsList()
                                }}
                              ><i class="fas fa-search fa-lg"></i></button> */}
                            </li>
                          </ul>
                          {/* <input type="text" className={" form-control" + (!error.buyerName ? '' : ' border-danger')} placeholder="" name="buyerName" value={data.buyerName} onChange={handleChange} required /> */}
                          {error.buyerName && <p className="text-danger error-contract">{error.buyerName}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Buyer Address {astrix} </label>
                          <input type="text" className={" form-control" + (!error.buyerAddress ? '' : ' border-danger')} placeholder="" name="buyerAddress" value={data.buyerAddress} onChange={handleChange} required />
                          {error.buyerAddress && <p className="text-danger error-contract">{error.buyerAddress}</p>}
                        </div>

                        <div className="form-group col-md-6" id="POScountry">
                          <label>Buyer Country {astrix} </label>
                          <select className={" form-control" + (!error.buyerCountry ? '' : ' border-danger')} name="buyerCountry" value={data.buyerCountry} onChange={handleChange} >
                            <option value="" selected>Select Country</option>
                            {countryData.map((country) => {
                              return (
                                <option value={country.sortname} >{country.name}</option>
                              )
                            })}
                          </select>
                          {error.buyerCountry && <p className="text-danger error-contract">{error.buyerCountry}</p>}
                        </div>

                        <div className="form-group col-md-12 text-center" id="POScountry">
                          <button type="button"
                            className='btn btn-primary btn-sm'
                            disabled={!(data.buyerName && data.buyerCountry)}
                            onClick={() => {
                              getDunsList()
                            }}
                          >Fetch From DUNS
                            {/* <i class="fas fa-search fa-lg"></i> */}
                          </button>
                        </div>

                        {showFullForm &&
                          <>
                            <div className="form-group col-md-6">
                              <label>Buyer Email</label>
                              <input type="email" className={" form-control" + (!error.buyerEmail ? '' : ' border-danger')} placeholder="" name="buyerEmail" value={data.buyerEmail} onChange={handleChange} required />
                              {/* {error.buyerEmail && <p className="text-danger error-contract">{error.buyerEmail}</p>} */}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Buyer Contact No.</label>
                              <input type="number" className={" form-control" + (!error.buyerContact ? '' : ' border-danger')} placeholder="" name="buyerContact" value={data.buyerContact} onChange={handleChange} required />
                              {error.buyerContact && <p className="text-danger error-contract">{error.buyerContact}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Postal Code {astrix} </label>
                              <input type="text" className={" form-control" + (!error.buyerPostalcode ? '' : ' border-danger')} placeholder="" name="buyerPostalcode" value={data.buyerPostalcode} onChange={handleChange} required />
                              {error.buyerPostalcode && <p className="text-danger error-contract">{error.buyerPostalcode}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Website</label>
                              <input type="text" className={" form-control" + (!error.buyerWebsite ? '' : ' border-danger')} placeholder="" name="buyerWebsite" value={data.buyerWebsite} onChange={handleChange} required />
                              {/* {error.buyerWebsite && <p className="text-danger error-contract">{error.buyerWebsite}</p>} */}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Currency {astrix} </label>
                              <select className={"form-control" + (error.buyerCurrency ? " border-danger" : "")} name="buyerCurrency" value={data.buyerCurrency} onChange={handleChange} >
                                <option value="" selected>--Select Currency--</option>
                                <option value="2:USD">Dollars ( USD )</option>
                                <option value="11:EUR">Euro ( EUR )</option>
                                <option value="19:GBP">Pounds ( GBP )</option>
                                {/* {currencyData.map((currency) => {
                                  return (
                                    <option value={currency.id + ':' + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                                  )
                                })} */}
                              </select>
                              {error.buyerCurrency && <p className="text-danger error-contract">{error.buyerCurrency}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>DUNS No </label>
                              {dunsNo.modal ?
                                <input type="text" className={" form-control" + (!error.buyerDUNSNo ? '' : ' border-danger')} placeholder="" name="buyerDUNSNo" value={dunsNo.number} />
                                :
                                <input type="text" className={" form-control" + (!error.buyerDUNSNo ? '' : ' border-danger')} placeholder="" name="buyerDUNSNo" value={data.buyerDUNSNo} onChange={handleChange} required />
                              }
                              {/* {error.buyerDUNSNo && <p className="text-danger error-contract">{error.buyerDUNSNo}</p>} */}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Previous year annual sales {astrix} </label>
                              <input type="number" className={" form-control" + (!error.annualSale ? '' : ' border-danger')} placeholder="" name="annualSale" value={data.annualSale} onChange={handleChange} required />
                              {error.annualSale && <p className="text-danger error-contract">{error.annualSale}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Expected annual sales for current year {astrix} </label>
                              <input type="number" className={" form-control" + (!error.expectedAnnualSale ? '' : ' border-danger')} placeholder="" name="expectedAnnualSale" value={data.expectedAnnualSale} onChange={handleChange} required />
                              {error.expectedAnnualSale && <p className="text-danger error-contract">{error.expectedAnnualSale}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Inco Terms {astrix} </label>
                              <select className={"form-control" + (error.incoTerms ? " border-danger" : "")} name="incoTerms" value={data.incoTerms} onChange={handleChange} >
                                <option value="" selected>--Select Inco Terms--</option>
                                <option value="FOB">FOB</option>
                                <option value="CIF">CIF</option>
                                <option value="CNF">CNF</option>
                              </select>
                              {error.incoTerms && <p className="text-danger error-contract">{error.incoTerms}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Terms of payment {astrix} </label>
                              <input type="text" className={" form-control" + (!error.payment ? '' : ' border-danger')} placeholder="" name="payment" value={data.payment} onChange={handleChange} required />
                              {error.payment && <p className="text-danger error-contract">{error.payment}</p>}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Product Details {astrix} </label>
                              <input type="text" className={" form-control" + (!error.productDetail ? '' : ' border-danger')} placeholder="" name="productDetail" value={data.productDetail} onChange={handleChange} required />
                              {error.productDetail && <p className="text-danger error-contract">{error.productDetail}</p>}
                            </div>

                            <div className="col-md-12">
                              <h3 className="pt-2 pb-2"><u>Documents</u></h3>
                              <div className="col-md-12 pt-2 pb-3">
                                <ul className="other-documents">
                                  <li>
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                  </li>
                                  <li>
                                    <h3 className={"custum-control" + (error.previousInvoiceDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Previous Invoice Doc {astrix}</h3>
                                  </li>
                                  <li>
                                    <div className="file-browse">
                                      <button className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="previousInvoiceDocument" onChange={handleFiles} />
                                    </div>
                                  </li>
                                </ul>
                                {file.previousInvoiceDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.previousInvoiceDocument.name}`}</div></div> : ""}
                                {error.previousInvoiceDocument ? <div class="text-danger error-contract">{error.previousInvoiceDocument}</div> : ''}
                              </div>
                              <hr />
                              <div className="col-md-12 pt-2 pb-3">
                                <ul className="other-documents">
                                  <li>
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                  </li>
                                  <li>
                                    <h3 className={"custum-control" + (error.previousPODocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Previous PO Doc {astrix}</h3>
                                  </li>
                                  <li>
                                    <div className="file-browse">
                                      <button className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="previousPODocument" onChange={handleFiles} />
                                    </div>
                                  </li>
                                </ul>
                                {file.previousPODocument ? <div className="form-group mb-0"><div className="file-name">{`${file.previousPODocument.name}`}</div></div> : ""}
                                {error.previousPODocument ? <div class="text-danger error-contract">{error.previousPODocument}</div> : ''}
                              </div>
                            </div>
                          </>}

                        {/* {data.buyerEmail &&
                          <div className="col-md-12">
                            <div className="form-check pl-0 mt-1 mb-1">
                              <input className="form-check-input" type="checkbox" name="buyerNotification" id="buyerNotification" onChange={hanleDocChecks} checked={data.buyerNotification} />
                              <label className="form-check-label" for="buyerNotification">
                                I want to send notification to the newly added buyer through email.
                              </label>
                            </div>
                          </div>} */}

                        <hr className="col-md-12" />

                        {data.buyerDUNSNo && showFullForm &&
                          <div className="form-group col-md-12">
                            <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={preHandleSubmit} >Submit</button>
                          </div>}
                      </div>
                    </div>
                  </div>

                  {/* <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['1'] ? " open" : " closed")}>
                    <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '1': !accord['1'] })}>Upload Buyer Details</h2>
                    <div className="accordionItemContent">
                      <div className="row pt-3">

                        <div className="form-group col-md-6" id="buyerDetails">
                          <ul className="other-documents pb-0 mb-0">
                            <li style={{ "min-width": "300px" }}>
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                Buyer Details {astrix} {toolTip('uploadBuyersDetail')}
                              </label>
                            </li>
                            <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                              <div className="file-browse">
                                <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
                                <input type="file" accept=".xlsx" id="file_1" name={"buyerDetails" + "51"} onChange={handleFile} />
                                {error["buyerDetails" + "51"] && <p className="error-contract p-0 m-0">{error["buyerDetails" + "51"]}</p>}
                              </div>
                            </li>
                            <li style={{ "min-width": "500px" }}>
                              {(data["buyerDetails" + "51"]) ? <div className="filePath text-left"><div className="file-name">{`${data["buyerDetails" + "51"].name}`}</div></div> : ""}
                            </li>
                            <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                              <div className="file-browse">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => setBuyerDetail({ modal: true })}>Buyer's Detail</button>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-12">
                          <div className="form-check pl-0 mt-1 mb-1">
                            <input className="form-check-input" type="checkbox" name="bulkBuyerNotification" id="bulkBuyerNotification" onChange={hanleDocChecks} checked={data.bulkBuyerNotification} />
                            <label className="form-check-label" for="bulkBuyerNotification">
                              I want to send notification to the newly added buyer through email.
                            </label>
                          </div>
                        </div>

                        <hr className="col-md-12" />

                        <div className="form-group col-md-12">
                          <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={handleUploadSubmit} >Submit</button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>

              <hr />

              <MyCounterDetailsGrid userTokenDetails={userTokenDetails} buyerCredit={buyerCredit} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} />

              {dunsNo.modal &&
                <div className={"modal" + (dunsNo.modal ? " show" : "")} id="Confirmation">
                  <div className="modal-dialog modal-lg border-inner" id="parent_class">
                    <div className="modal-content">
                      <div className="modal-header primary">
                        <h4 className="modal-title text-white">DUNS Number Verification</h4>
                        <button type="button" className="close" onClick={() => { setDUNSNo({ modal: false, number: '' }) }}>×</button>
                      </div>
                      <div className="modal-body">
                        <h6 className="text-success mr-1 text-center">Hey <b>'{data.buyerName}'</b> please select your DUNS Number</h6>
                        <div className="form-group col-md-12">
                          <label>DUNS Number</label>
                          <select className=" form-control" name="buyerDUNSNo" value={data.buyerDUNSNo} onChange={handleChange} >
                            <option value="" selected>-- Select DUNS Number --</option>
                            {dunsNo.number.map((dunsDetail) => {
                              return (
                                <option value={dunsDetail.duns + ':' + dunsDetail.address.street + ':' + dunsDetail.address.city + ':' + dunsDetail.address.postalCode} >{dunsDetail.companyName + " | DUNS Number -- " + dunsDetail.duns + "       |      " + dunsDetail.address.street + ', ' + dunsDetail.address.city + " - " + dunsDetail.address.postalCode}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="modal-footer primary">
                        {/* <button type="button" className="btn btn-success btn-sm" onClick={() => { setData({ ...data, buyerDUNSNo: dunsNo.number }); setDUNSNo({ modal: false, number: '' }) }}>Correct</button> */}
                        <button type="button" className="btn btn-info btn-sm" onClick={() => { setDUNSNo({ modal: false, number: '' }) }}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {buyerDetail.modal &&
                <div className={"modal" + (buyerDetail.modal ? " show" : "")} id="Confirmation">
                  <div className="modal-dialog modal-lg border-inner" id="parent_class">
                    <div className="modal-content">
                      <div className="modal-header primary">
                        <h4 className="modal-title text-white">Buyer's Detail</h4>
                        <button type="button" className="close" onClick={() => { setBuyerDetail({ modal: false }) }}>×</button>
                      </div>
                      <div className="modal-body calc-modal">
                        <div className="table-responsive" style={{ "min-height": "150px" }}>
                          <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                            <thead>
                              <tr style={{ fontSize: "14px" }}>
                                <th>#</th>
                                <th width="500">Buyer Name</th>
                                <th width="300">Buyer Email</th>
                                {/* <th width="300">Buyer Contact No. </th>
                                  <th width="300">Buyer Address</th>
                                  <th width="300">Buyer Country</th>
                                  <th width="300">Previous year annual sales</th>
                                  <th width="150">Expected annual sales for current year</th>
                                  <th width="300">Terms of payment (days)</th>
                                  <th width="300">Product Details</th> */}
                                <th width="300">DUNS No</th>
                                <th width="300">Status</th>
                              </tr>
                            </thead>
                            {allBuyerDetail.map((values, key) => {
                              return (
                                <tbody>
                                  <tr style={{ fontSize: "14px" }}>
                                    <td >{key + 1}</td>
                                    <td >{values.buyerName}</td>
                                    <td>{values.buyerEmail}</td>
                                    {/* <td >{values.buyerContact}</td>
                                      <td >{values.buyerAddress}</td>
                                      <td >{values.buyerCountry}</td>
                                      <td >{values.annualSale}</td>
                                      <td >{values.expectedAnnualSale}</td>
                                      <td >{values.payment} Days</td>
                                      <td >{values.productDetail}</td> */}
                                    <td >{values.buyerDUNSNo}</td>
                                    <td style={{ backgroundColor: values.verification_status == 1 ? 'rgba(63, 195, 128, 0.9)' : 'rgba(248, 0, 0, 0.9)', color: 'white' }}>{(values.verification_status == 1) ? 'Verified' : 'Not Verified'}</td>
                                  </tr>
                                </tbody>
                              )
                            })}
                          </table>
                        </div>
                      </div>
                      <div className="modal-footer primary">
                        <button type="button" className="btn btn-info btn-sm" onClick={() => { setBuyerDetail({ modal: false }) }}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      }

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    creditlineDataBank: state.creditlineDataBank,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCreditlineDataBank: (modal, info) => { dispatch(setCreditlineDataBank({ modal: modal, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllCounterPart)