import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import DataTable from 'react-data-table-component';
import FilePreview from '../utilComponents/previewFile';
import customStyles from '../dataTablesColumsMap/customTableCss';
import SignPad from '../contractDocument/signPadForChannelPartner';
import dataUser from './agreementT&C.json';
import tradeTechAnnexure from './tradeTechAnnexure.json';
import finTechAnnexure from './finTechAnnexure.json';
import logiTechAnnexure from './logiTechAnnexure.json';
import insureTechAnnexure from './insureTechAnnexure.json';
import config from '../../config.json';
import validate from '../../utils/validation_files/channelPartnerAgreement';
import franchiseTC from './franchiseAgreementT&C.json'
import QRCode from 'qrcode';
import moment from 'moment';
import { DocumentViewer } from '../../utils/documentViewer';
import { getDocDetails } from '../../utils/myFunctions';
import { DocumentDownloader } from '../../utils/DocumentDownloader';
import { useHistory } from "react-router";
import { FileInput } from '../../utils/FileInput';
import SignDocument from '../InvoiceDiscounting/components/SignDocument';

const ChannelPartnerAgreement = ({ userTokenDetails }) => {
  let history = useHistory()

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const queryParams = new URLSearchParams(window.location.search)
  let requestId = queryParams.get('id')

  const [showLoader, setshowLoader] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [agreementUserInfo, setAgreementUserInfo] = useState({});
  const [reqId, setReqId] = useState(requestId / 1 || 0);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [refreshDoc, setrefreshDoc] = useState(0);
  const [addFileData, setaddFileData] = useState('');
  const [dataTnc, setdataTnc] = useState({});
  const [tradetechAnnexure, settradetechAnnexure] = useState({});
  const [fintechAnnexure, setfintechAnnexure] = useState({});
  const [logitechAnnexure, setlogitechAnnexure] = useState({});
  const [insuretechAnnexure, setinsuretechAnnexure] = useState({});
  const [tab, setTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accordion, setAccordion] = useState(0);
  const [agreementTemplate, setAgreementTemplate] = useState({})
  const [viewDocFromMstTable, toggleViewDocFromMstTable] = useState(false)
  const [userIp, setUserIp] = useState("")
  const [userLocation, setUserLocation] = useState("")
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [agreementFile, setAgreementFile] = useState(null)
  const [signdoc, setSigndoc] = useState(false);

  useEffect(() => {
    if (agreementTemplate.file_hash) {
      loadAgreementDoc()
    }
  }, [agreementTemplate.file_hash])

  async function loadAgreementDoc() {
    let doc = await getDocDetails(null, agreementTemplate.file_hash, null, null, true)
    doc["name"] = "Channel Partner Agreement.pdf"
    setAgreementFile(doc)
  }

  const columns = [
    {
      name: 'Sr. No.',
      selector: 'sr_no',
      sortable: true,
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        color: 'white',
      },
    },
    {
      name: 'Type of Document',
      selector: 'type_of_document',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Document Name',
      selector: 'document_name',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Created By',
      selector: 'created_by',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Signed By',
      selector: 'signed_by',
      sortable: true,
      grow: 1,
    },
    {
      name: "Action",
      selector: 'action',
      sortable: false,
      grow: 1
    }
  ];
  const signStyle = {
    "font-family": "Times New Roman",
    "font-style": "italic",
    "font-size": "28px"
  }
  const astrix = <span className="required-field text-danger">*</span>;

  const handleAccordion = (accordion) => setAccordion(accordion);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    // Fetch IP
    fetch(
      "https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then((json) => {
        // console.log("userippp", json);
        if (json.IPv4) {
          setUserIp(json.IPv4)
        }
      })
    // Fetch Location
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserLocation(position.coords.latitude + ", " + position.coords.longitude)
    });
    fetchTableData(1, {})
  }, [reqId])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitAgreement();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error, isSubmitting]);

  useEffect(() => {
    // window.onload = function () {
    try {
      var url_string = (window.location.href);
      var url = new URL(url_string);
      var id = url.searchParams.get('id');
      // setReqId(id);
      call('POST', 'getAgreementInfo', { "reqId": requestId }).then((result) => {
        console.log('running getAgreementInfo api-->', result);
        if (result[0].channel_partner_role === 'CP') {
          setdataTnc(franchiseTC);
        }
        if (result[0].channel_partner_role === 'FP') {
          setdataTnc(franchiseTC);
        }
        setAgreementUserInfo(result[0]);
      }).catch((e) => {
        console.log('error in getAgreementInfo', e);
        toastDisplay("Error", "error")
      });
    } catch (error) {
      console.log('error in getAgreementInfo ', error);
    }
    // }
    setdataTnc(dataUser);
    settradetechAnnexure(tradeTechAnnexure);
    setfintechAnnexure(finTechAnnexure);
    setlogitechAnnexure(logiTechAnnexure);
    setinsuretechAnnexure(insureTechAnnexure);
  }, []);
  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (agreementUserInfo.channel_partner_role && agreementUserInfo.techTypeId) {
  //     getAgreementTemplate(agreementUserInfo.channel_partner_role, agreementUserInfo.techTypeId)
  //   }
  // }, [agreementUserInfo])

  useEffect(() => {
    getAgreementTemplate()
  }, [])

  function getAgreementTemplate() {
    call('POST', 'getChannelPartnerAgreementTemplate', { role: 'CP', type: "5", reqId: requestId }).then(async (result) => {
      console.log("api result in getChannelPartnerAgreementTemplate-->", result)
      if (result.file_hash) {
        setAgreementTemplate(result)
      }
      else {
        setAgreementTemplate({})
      }
    }).catch((e) => {
      // console.log("Error while querying getChannelPartnerAgreementTemplate:", e);
    })
  }

  function fetchTableData(page, inptObject) {
    setLoading(true);

    call('POST', 'getAgreementInfo', { "reqId": reqId }).then((result) => {
      console.log('running getAgreementInfo api ---->', result);
      settableData(formatDataFortable(result))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getAgreementInfo', e);
      setLoading(false);
      toastDisplay("Error", "error")
    });
  }

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {};
      tempObj.sr_no = (i + 1);
      tempObj.type_of_document = 'Party Agreement';
      tempObj.document_name = `${(dataArray[i]["channel_partner_role"] === "CP" ? 'Channel Partner' : 'Franchise Partner') + (' Agreement Document')}`;
      tempObj.created_by = 'TradeReboot Fintech Pvt. Ltd.';
      tempObj.signed_by = <>{(dataArray[i] && dataArray[i].docSignArray.length) ?
        <pre><span className="text-success mr-1">{dataArray[i].docSignArray.map((signV) => { return (<><i className="fa fa-certificate" /> {signV.sign}<br /></>) })}</span></pre>
        : <pre><span className="text-success mr-1"> <><i className="fa fa-certificate" /> Party<br /></> </span></pre>}</>
      dataArray[i].doc_name = 'Channel Partner Agreement Document';
      dataArray[i].document_name = 'Party Agreement';
      dataArray[i].uploaded_by = `TradeReboot Fintech Pvt. Ltd.`;
      dataArray[i].uploaded_on = dataArray[i].created_at;
      dataArray[i].type_id = 20;
      dataArray[i].tbl_doc_id = dataArray[i].agreement_doc_id;
      dataArray[i].signType = 'Channel_Partner_Agreement';
      dataArray[i].userId = dataArray[i].tbl_user_id;
      dataArray[i].email = dataArray[i].email_id;
      tempObj.action = <>
        <button title="View File" className="text-center btn  btn-sm m-1" onClick={
          async () => {
            let doc = await getDocDetails(dataArray[i].agreement_doc_id, null, null, null, true)
            toggleViewDoc({ show: true, doc })
          }}>
          {/* <i class="fas fa-eye"></i> */}
          <img src='assets/images/eye.png' />
        </button>
        <button title="Download File" className="text-center btn  btn-sm m-1" onClick={
          async () => {
            let doc = await getDocDetails(dataArray[i].agreement_doc_id, null, null, null, true)
            toggleDowdDoc({ show: true, doc })
          }}>
          {/* <i className="fa fa-download" aria-hidden="true"></i> */}
          <img src='assets/images/download.png' />
        </button>
      </>
      resultArray.push(tempObj)
    }
    return resultArray
  }
  //--------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  // function callPreviewFileComponent(values, action) {
  //   setfileData({ tbl_doc_id: values, "action": action })
  //   setshowPreviewModal(true)
  // }

  function callPreviewFileComponent(values, action, viewFromMasterTable) {
    if (viewFromMasterTable) {
      setfileData({ tbl_doc_id: values, "action": action })
    }
    else {
      setfileData({
        id: values.id,
        file_hash: values.file_hash,
        file_name: values.file_name,
        file_type: values.doc_name,
        created_at: values.created_at,
        "action": action
      })
    }
    setshowPreviewModal(true)
    toggleViewDocFromMstTable(viewFromMasterTable)
  }


  function tncBodyGenerator() {
    let keyArray = Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!dataTnc[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{dataTnc[key].name ? dataTnc[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={dataTnc[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function tradeAnnexureBody() {
    let keyArray = Object.keys(tradetechAnnexure).length ? Object.keys(tradetechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!tradetechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{tradetechAnnexure[key].name ? tradetechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={tradetechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function finAnnexureBody() {
    let keyArray = Object.keys(fintechAnnexure).length ? Object.keys(fintechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!fintechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{fintechAnnexure[key].name ? fintechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={fintechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function logiAnnexureBody() {
    let keyArray = Object.keys(logitechAnnexure).length ? Object.keys(logitechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!logitechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{logitechAnnexure[key].name ? logitechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={logitechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function insureAnnexureBody() {
    let keyArray = Object.keys(insuretechAnnexure).length ? Object.keys(insuretechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!insuretechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{insuretechAnnexure[key].name ? insuretechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={insuretechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function submitStamp(type) {

    console.log("data in sign submit==>", data)
    setshowLoader(true);
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append('signPadBase64', addFileData);
    formData.append('userTypeId', 20);
    formData.append('reqId', reqId);
    formData.append('userIp', userIp)
    formData.append('userLocation', userLocation)

    call('POST', 'saveImage', formData).then((result) => {
      console.log("=== saveImage === ", result)
      setshowLoader(false);
      // alert("Digital Signature Added successfuly", "success");
      preHandleSubmit()
      setrefreshDoc(refreshDoc + 1)
    }).catch(err => {
      setshowLoader(false);
      alert(err, "error");
    })
  }

  async function submitAgreement() {
    let qrBase64 = await QRCode.toDataURL(
      `Link : ${window.location.href}
        IP: ${userIp}
        Location : ${userLocation}
        Datetime : ${moment().format('DD MMM, YYYY hh:mm a') + " IST"}`, { type: "png" })
    setshowLoader(true);
    data.reqId = reqId;
    // data.dataTnc = dataTnc;
    // data.tradetechAnnexure = tradetechAnnexure;
    // data.fintechAnnexure = fintechAnnexure;
    // data.insuretechAnnexure = insuretechAnnexure;
    // data.logitechAnnexure = logitechAnnexure;
    data.company_name = agreementUserInfo.company_name;
    data.contact_person = agreementUserInfo.contact_person;
    data.organization_type = agreementUserInfo.organization_type;
    data.address = agreementUserInfo.user_address;
    data.submitBy = 'Party';
    data.baseUrl = config.baseUrl
    data.typeId = agreementUserInfo.type_id
    data.password = agreementUserInfo.password;
    data.tbl_user_id = agreementUserInfo.tbl_user_id;
    data.iec_no = agreementUserInfo.iec_no;
    data.gst_vat_no = agreementUserInfo.gst_vat_no;
    data.email_id = agreementUserInfo.email_id;
    data.techTypeId = agreementUserInfo.techTypeId;
    data.agreementFileHash = agreementTemplate.file_hash
    data.role = agreementUserInfo.channel_partner_role
    data.pan_no = agreementUserInfo.pan_no
    data.country_code = agreementUserInfo.country_code
    data.aadhar_no = agreementUserInfo.aadhar_no
    data.qrBase64 = qrBase64
    data.party_designation = agreementUserInfo.designation
    call('POST', 'submitAgreement', data).then((result) => {
      console.log("=== submitAgreement === ", result)
      alert(result);
      setshowLoader(false);
      setrefreshDoc(refreshDoc + 1)
      history.go(-1)
    }).catch(err => {
      console.log(err);
      setshowLoader(false);
      alert(err);
      window.location.reload()
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  //Handlers

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleFile = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (e) => {
      setaddFileData(e.target.result);
    }
  }

  const preHandleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    };
    setError(validate(data, true));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <><DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {signdoc && <SignDocument customUserId={agreementUserInfo.tbl_user_id} signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={async (updatedDoc) => {
          // console.log("uppppppppppppppppppppppppp", updatedDoc);
          setAgreementFile(updatedDoc)
          setshowLoader(true)
          let formData = new FormData()
          formData.append("reqId", reqId)
          formData.append("tbl_user_id", agreementUserInfo.tbl_user_id)
          formData.append("file", updatedDoc)
          await call('POST', 'submitAgreement2', formData)
          setshowLoader(false)
          setrefreshDoc(refreshDoc + 1)
          history.go(-1)
        }} />}
      {/* logo start */}
      <div
        onClick={() => {
          window.location = 'https://app.tradereboot.com'
        }}
        className="logo cursor">
        <img height="60" className="default_logo" src="assets/images/logos/logo_1.png" />
        <img height="60" className="mini_logo" src="assets/images/logos/logo_mini.png" />
      </div>
      <div className="col-md-12 mb-2 text-center">
        <h1 style={{ fontSize: '32px' }} className="text-color1 border-bottom pb-2 font-wt-600">Party Agreement of {agreementUserInfo.company_name}</h1>
      </div>
      {/* logo end */}
      <div className="col-md-12 pt-2 pb-5 mt-5">

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 style={{ fontSize: '20px' }} className="text-color1 mt-3 font-wt-600 mx-2">
                      Company Party Info
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">
                      <div className="form-group col-md-12">
                        <div className="modal-padding">

                          <div className="row">
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Company Name'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {agreementUserInfo && agreementUserInfo.company_name ? agreementUserInfo.company_name : 'Not Available'}</span> </p>
                            </div>
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Email Id'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{agreementUserInfo && agreementUserInfo.email_id ? agreementUserInfo.email_id : 'Not Available'}</span> </p>
                            </div>
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Contact Person'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {agreementUserInfo && agreementUserInfo.contact_person ? agreementUserInfo.contact_person : 'Not Available'} </span> </p>
                            </div>
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Address'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {agreementUserInfo && agreementUserInfo.user_address ? agreementUserInfo.user_address : 'Not Available'}</span> </p>
                            </div>
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Country'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {agreementUserInfo && agreementUserInfo.country_code ? agreementUserInfo.country_code : 'Not Available'}</span> </p>
                            </div>
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Contact Number'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {agreementUserInfo && agreementUserInfo.contact_number ? agreementUserInfo.contact_number : 'Not Available'}</span> </p>
                            </div>
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'Entity'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{agreementUserInfo && agreementUserInfo.organization_type ? agreementUserInfo.organization_type : 'Not Available'}</span> </p>
                            </div>

                            {agreementUserInfo.country_code === 'IN' && agreementUserInfo.organization_type === 'individual' &&
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'PAN Number'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{agreementUserInfo && agreementUserInfo.pan_no ? agreementUserInfo.pan_no : 'Not Available'}</span> </p>
                              </div>
                            }
                            {agreementUserInfo.country === 'IN' && agreementUserInfo.organization_type !== 'individual' &&
                              <>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'PAN Number'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{agreementUserInfo && agreementUserInfo.pan_no ? agreementUserInfo.pan_no : 'Not Available'}</span> </p>
                                </div>
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'GST Number'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{agreementUserInfo && agreementUserInfo.gst_vat_no ? agreementUserInfo.gst_vat_no : 'Not Available'}</span> </p>
                                </div>
                              </>
                            }
                            {agreementUserInfo.country_code === 'IN' && agreementUserInfo.organization_type === 'pvtPubLtd' &&
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{'CIN Number'}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{agreementUserInfo && agreementUserInfo.cin_no ? agreementUserInfo.cin_no : 'Not Available'}</span> </p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {agreementUserInfo.reqStatus === 3 &&
          <>



            <div className={"accordionItem mt-3" + (" open")}>
              <h3 style={{ fontSize: '20px' }} className=" text-color1 mt-3 font-wt-600 mx-4">Agreement Document</h3>

              <hr className='mx-3' />
              <div className="">
                <div className='row my-4 px-4'>
                  {/* <div className='col-md-4'>
                    <input type="text" className={" form-control"} disabled placeholder={"Enter document name"} name={"docName"}
                      value={agreementTemplate.doc_name} />
                  </div> */}
                  {agreementFile ?
                    <div className='d-flex row align-items-centger mx-1'>
                      <div className="col-md-4" >
                        <FileInput value={agreementFile} name={"agreementFile"} isEditable={false} />
                      </div>
                      <div className='col-md-2 d-flex align-items-center' >
                        <img
                          onClick={() => { setSigndoc(true); setData({ ...data, docToSign: agreementFile, docSignName: "agreementFile" }) }}
                          className='cursor' src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                      </div>
                    </div> : null}

                  {/* <div className='col-md-2'>
                    {agreementTemplate.file_name ? (
                      <>
                        <button title="View File" className="text-center btn btn-sm m-1" onClick={
                          async () => {
                            let doc = await getDocDetails(null, agreementTemplate.file_hash)
                            doc["name"] = "Channel Partner Agreement.pdf"
                            toggleViewDoc({ show: true, doc })
                          }}>
                          <img src='assets/images/eye.png' />
                        </button>
                      </>
                    ) : null}
                  </div> */}
                </div>

              </div>
            </div>

            {/* <div className={"accordionItem"}>
              <h2 className="accordionItemHeading">Terms & Conditions</h2>
              <div className="accordionItemContent">
                <div className="col-md-12 row">
                  {tncBodyGenerator()}
                </div>
              </div>
            </div> */}

            {/* {agreementUserInfo.techTypeId == 4 &&
              <div className={"accordionItem"}>
                <h2 className="accordionItemHeading">ANNEXURE A</h2>
                <div className="accordionItemContent">
                  <div className="col-md-12 row">
                    {tradeAnnexureBody()}
                  </div>
                </div>
              </div>} */}

            {/* {agreementUserInfo.techTypeId == 5 &&
              <div className={"accordionItem"}>
                <h2 className="accordionItemHeading">ANNEXURE A</h2>
                <div className="accordionItemContent">
                  <div className="col-md-12 row">
                    {finAnnexureBody()}
                  </div>
                </div>
              </div>} */}

            {/* <button type="button" className="btn btn-primary float-right btn-sm"
              onClick={() => preHandleSubmit()}
            >Submit</button> */}
          </>}

        {agreementUserInfo.reqStatus === 4 && <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 style={{ fontSize: '20px' }} className="green mt-3">
                      Agreement Document
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={tableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      {
        showPreviewModal &&
        <FilePreview
          mktPlaceShipment={!viewDocFromMstTable}
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
          viewTrail={false}
        />
      }
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerAgreement)