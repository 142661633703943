const columns = [
  {
    name: 'Lender Name',
    selector: 'lendername',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Requested Amount',
    selector: 'credit_amount',
    sortable: true,
  },
  {
    name: 'Requested Credit Days',
    selector: 'credit_days',
    sortable: true,
  },
  // {
  //   name: 'Admin Approval',
  //   selector: 'admin_status',
  //   sortable: false,
  //   cell: row =>
  //     (row.admin_status === 0) ? "Pending" :
  //       (row.admin_status === 1) ? "Revision" :
  //         (row.admin_status === 2) ? "Approved" : "NA",
  //   conditionalCellStyles: [
  //     {
  //       when: row => (row.admin_status === 0),
  //       style: {
  //         backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.admin_status === 1),
  //       style: {
  //         backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.admin_status === 2),
  //       style: {
  //         backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
  //         color: 'white',
  //       },
  //     }
  //   ]
  // },
  // {
  //   name: 'Admin Comment',
  //   selector: 'admin_comment',
  //   sortable: false,
  //   grow: 2,
  // },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: false,
  },
  // {
  //   name: 'Loan Type',
  //   selector: 'loanType',
  //   sortable: true
  // },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    cell: row =>
      (row.status === 'Credit Line Created') ? "Credit Line Created" :
        (row.status === 'User Approval Pending') ? "User Approval Pending" :
          (row.status === 'Admin Revision') ? "Admin Revision" :
            (row.status === 'User Rejected') ? "User Rejected" :
              (row.status === 'User Approved') ? "User Approved" :
                (row.status === 'Admin Approved') ? "Admin Approved" : "NA",
    conditionalCellStyles: [
      {
        when: row => (row.status === 'Credit Line Created'),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'User Approval Pending'),
        style: {
          backgroundColor: 'rgba(255, 150, 0, 0.9)',  //orange
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'Admin Revision'),
        style: {
          backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'User Rejected'),
        style: {
          backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'Admin Approved'),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'User Approved'),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      }
    ]
  },
  // {
  //   name: 'Actions',
  //   selector: 'action',
  //   sortable: false,
  // }
];

export default columns