import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import { useState } from 'react'
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../utils/newInput'
import toastDisplay from '../../utils/toastNotification'
import call from '../../service'
import { AddMoreDocumentHandler } from '../addMoreDocumentHandler'
import { ExportExcel, convertImageToPdf, dataURItoBlobURL, getFiscalYearsDropdown, getInitials, isEmpty, most_used_currencies } from '../../utils/myFunctions'
import { FileInput } from '../../utils/FileInput'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import { ToastContainer } from 'react-toastify'
import XLSX, { readFile } from 'sheetjs-style';
import MultipleSelect from '../../utils/MultipleSelect'
import axios from 'axios'
import { aiServerBackendUrl, platformURL } from '../../urlConstants'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import LcDetailsV2 from '../lcV2/applyforLimit/components/lcDetailsV2'
import moment from 'moment'
import validateAddBank from '../../utils/validation_files/AddBankValidations'
const fiscalyears = getFiscalYearsDropdown()

const currencies = [
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { code: "%" }
]
let buyerExcelHeaders = [
  {
    "buyerName": "",
    "buyerCountry": "",
    "buyerAddress": "",
    "buyerPostalCode": "",
    "buyerDunsNo": "",
    "currMaxOutStanding": "",
    "creditDays": "",
    "termsOfPayment": "",
    "currMaxOutStandingCurrency": "",
    "limitRequiredCurrency": "",
    "currentProjTOCurrency": "",
    "buyerAmountCurrency": "",
    "buyerAmount": "",
    "currentProjTO": "",
    "limitRequired": ""
  }
]
const infoType = [
  { "name": "$ Currency", "unit": "currency" },
  { "name": "% Percentage", "unit": "percentage" },
  { "name": "Text", "unit": "text" },
  { "name": "Date", "unit": "date" }
]

export const ApplicationType = [
  { name: "Factoring" },
  { name: "Letter Of Credit" }
]

export const LCapplicationStatus = [
  { name: "Applied for Limit" },
  { name: "Quotes received from financiers" },
  { name: "Amendment request from financier" },
  { name: "Quote selected by supplier" },
  { name: "LC sent for amendment by supplier" },
  { name: "Ammended LC & request letter sent to financier" },
  { name: "Contract Received from financier" },
  { name: "Contract Signed by supplier" },
  { name: "Contract Signed by financier" },
  { name: "Limit Approved" }

]

export const INVapplicationStatus = [
  { name: "Applied for Limit" },
  { name: "Quotes received from financiers" },
  { name: "Amendment request from financier" },
  { name: "Quote selected by supplier" },
  { name: "Term Sheet Received from financier" },
  { name: "Term Sheet Signed by supplier" },
  { name: "Term Sheet Signed by financier" },
  { name: "Limit Approved" }

]

export const LCServiceTypes = [
  "LC Confirmation",
  "LC Discounting",
  "LC Confirmation + Discounting"
]

const organizationalDocs = [
  { label: 'Audited Financials including ITR (Last 2 years & provisional for current year)', docArr: [{ valKey: "AudFin_0", yearKey: "AudFinYear_0" }] },
  { label: 'GST Returns (3B, Apr21 - Present)', docArr: [{ valKey: "GSTReturns", yearKey: "GSTReturnsYear" }] },
  { label: 'Provisional P/L & B/S of current financial year', docArr: [{ valKey: "CurrentProvisionalPLBS", yearKey: "CurrentProvisionalPLBSYear" }] },
  { label: 'Latest Bank Statement (Last 6 Months) of all active banks', docArr: [{ valKey: "ListOfShareHolders", yearKey: "ListOfShareHoldersYear" }] },
  { label: 'List of Shareholders', docArr: [{ valKey: "Prev6MonBankStatement", yearKey: "Prev6MonBankStatementYear" }] },
  { label: 'Add More', docArr: [{ valKey: "add_more1", year: "add_more_year" }] },

]

const ApplicationForm = ({ userTokenDetails, navToggleState }) => {
  const [showLoader, setshowLoader] = useState(false)

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [requestMoreDetails, setRequestMoreDetails] = useState({ modal: false, page: 0 });
  const [moreDocs, setMoreDocs] = useState([])
  const [addMoreDocs, setAddMoreDocs] = useState([{
    key: 'moreDoc0',
    doc_name: 'Add More'
  }])
  const [addBuyerPopup, setAddBuyerPopup] = useState({
    show: false,
    isEdit: false
  })
  const [financers, setFinancers] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [tempValue, setTempValue] = useState("")
  const [docNamePicker, toggleDocNamePicker] = useState({ show: false, id: null })
  const [countrydata, setCountrydata] = useState([])
  const [dunsData, setDunsData] = useState([])
  const [stage, setStage] = useState(0)
  const [buyersdata, setBuyersdata] = useState([])
  const [dbData, setDbData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState([])
  const [docsTable, setDocsTable] = useState([])
  const [orgDocTblData, setorgDocTblData] = useState([])
  const [orgDocs, setOrgDocs] = useState(organizationalDocs)
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const [lcOrgDocs, setLCOrgDocs] = useState([{ serviceKey: 'lc_type_0', tenureKey: 'lc_tenure_0', issucountryKey: 'lc_issuing_country_0', issuBankKey: 'lc_issuing_bank_0', swiftCodeKey: 'lc_swift_code_0', draftKey: 'lc_draft_0' }])
  const [lcOrgTbl, setLCOrgTblDocs] = useState()
  const [draftLCBlob, setDraftLCBlob] = useState(null)
  const [ocrTxtFormat, setOcrTxtFormat] = useState([])
  const [showOCRPopup, setOCRPopup] = useState({ show: false, index: null })
  const [ocrData, setOCRData] = useState({ '32B1': 'USD' })
  const [allPorts, setAllPorts] = useState([])
  const [allSwiftData, setAllSwiftData] = useState([])
  const [countrys, setCountrys] = useState([])
  const [ports, setPorts] = useState([])
  const [swiftData, setSwiftData] = useState([])
  const [ocrFieldMapped, toggleOcrFieldMapped] = useState(false)
  const [lcCodes, setLCCodes] = useState([])
  const [userBanks, setUserBanks] = useState([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }])
  const [addBankPopup, setAddBankPopup] = useState(false)
  const [states, setstatedata] = useState([])

  console.log('dataaaaaaaa', data, typeof (data.AudFin));
  const handleOCRFieldsChange = async (event) => {
    if (event.target.name === "32B2" || event.target.name === "32B1") {
      setOCRData({ ...ocrData, "32B": ocrData["32B1"] + " " + ocrData["32B2"], [event.target.name]: event.target.value })
    }
    else {
      setOCRData({ ...ocrData, [event.target.name]: event.target.value })
    }
  }
  const getFinancersByServiceType = () => {
    if (data.applicationType) {
      let reqObj = {}
      reqObj["type"] = data.applicationType === 'Factoring' ? 'Invoice' : 'LC'
      call('POST', 'getFinancersByServiceType', reqObj).then(result => {
        setFinancers(result)
      }).catch(e => {

      })
    } else {
      setFinancers([])

    }
  }
  useEffect(() => {
    getFinancersByServiceType()
  }, [data.applicationType])
  const getCorporateExporters = () => {
    call('POST', 'getCorporateExporters', {}).then(result => {
      setSuppliers(result)
    }).catch(e => {

    })
  }

  const getTTVBuyers = (ttvExporterCode) => {
    call('POST', 'getBuyerListCRMV2', { ttvExporterCode }).then(result => {
      let finaldata = []
      for (let i = 0; i <= result.message.length - 1; i++) {
        let element = result.message[i]
        finaldata.push({
          buyerName: element.CONSIGNEE_NAME,
          buyerCountry: element.DESTINATION_COUNTRY,
          buyerAmount: element.FOB
        })
      }
      setBuyersdata([
        ...buyersdata,
        ...finaldata
      ])
    }).catch(e => {
      console.log('error in getBuyerListCRM', e);
    })
  }
  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setCountrydata(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    getCorporateExporters()
  }, [])
  const handleInputChange = (event, maxlength) => {
    if (event.target.name === 'supplierName') {
      getTTVBuyers(event.target.value)
    }
    if (event.target.name === 'beneficiaryCountry') {
      const country = countrys.find(data => data.sortname === event.target.value)
      getStateByCountry(country.id)
    }
    if (maxlength) {
      if (event.target.value?.length <= maxlength) {
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
        return
      }
    } else if (addBankPopup) {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (showOCRPopup.show) {
      setData({
        ...data, [`lc_Mapping_${showOCRPopup.index}`]: {
          ...data[`lc_Mapping_${showOCRPopup.index}`],
          [event.target.name]: event.target.value
        }
      })
      setErrors({ ...errors, [event.target.name]: "" })
    } else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  const createNewApplicaton = () => {
    let errors = {}
    if (!data.supplierName) {
      errors.supplierName = 'Mandatory Field'
    }
    if (!data.applicationType) {
      errors.applicationType = 'Mandatory Field'
    }

    if (isEmpty(errors)) {
      if (financers.some(item => item.is_checked)) {
        if (selectedIndex.length >= 1 || data.applicationType === 'Letter Of Credit') {
          setshowLoader(true)

          let formdata = new FormData()
          const exporterdata = suppliers.find(item => item.EXPORTER_CODE = data.supplierName)
          let buyers_data = []
          for (let i = 0; i <= buyersdata.length - 1; i++) {
            if (i === selectedIndex) {
              buyers_data.push({
                ...buyersdata[i],
                isAppliedForLimit: true
              })
            } else {
              buyers_data.push(buyersdata[i])
            }
          }
          Object.keys(data).forEach(element => {
            if (data[element]?.filebase64) {
              if (element.includes("extraDocs")) {
                formdata.append(element?.split("_")[1], data[element])
              } else {
                formdata.append(element, data[element])
              }

            }
          })
          let applicationState = 'Applied For Limit'
          let applicationNo = ''
          let lc_data = []
          if (data.applicationType === 'Factoring') {
            applicationNo = 'INV' + "/" + getInitials(exporterdata.EXPORTER_NAME) + "/" + new Date().getTime()
          } else {
            applicationNo = 'LC' + "/" + getInitials(exporterdata.EXPORTER_NAME) + "/" + new Date().getTime()
            for (let i = 0; i <= lcOrgDocs.length - 1; i++) {
              const element = lcOrgDocs[i]
              if (data[element.draftKey]) {
                lc_data.push(data[`lc_Mapping_${i}`])
              }
            }
          }
          formdata.append('userId', userId)
          formdata.append('EXPORTER_NAME', exporterdata.EXPORTER_NAME)
          formdata.append('EXPORTER_CODE', exporterdata.EXPORTER_CODE)
          formdata.append('APPLICATION_NUMBER', applicationNo)
          formdata.append('APPLICATION_TYPE', data.applicationType)
          formdata.append('APPLICATION_STATUS', applicationState)
          formdata.append('BUYERS_DATA', JSON.stringify(buyers_data))
          formdata.append('SHARED_WITH_FINANCER', JSON.stringify(financers.filter(item => item.is_checked)))
          formdata.append('LC_DATA', JSON.stringify(lc_data))
          formdata.append('USER_BANKS', JSON.stringify(userBanks))


          const finalres = []
          for (let i = data.additionalDetails; i <= data.additionalDetails?.length - 1; i++) {
            let element = data.additionalDetails[i]
            if (element.type === 'currency') {
              element[`${element.title}_unit`] = data[`${element.title}_currency`]
              element[`${element.title}_value`] = data[`${element.title}_value`]
            } else {
              element[`${element.title}_value`] = data[`${element.title}_value`]
            }
            finalres.push(element)
          }
          if (finalres.length !== 0) {
            formdata.append('EXTRA_FIELDS', JSON.stringify(finalres))
          }
          call('POST', 'createNewApplication', formdata).then(result => {
            toastDisplay(result, "success")
            setshowLoader(false)
            window.history.back()
          }).catch(e => {
            toastDisplay(e, "error")
            setshowLoader(false)
          })
        } else {
          toastDisplay("Please Select At least one buyer", "info")
        }
      } else {
        toastDisplay("Please Select At least One Financer", "info")
      }

    } else {
      setErrors(errors)
    }
  }

  const handleFile = (event, isImage) => {
    event.persist()
    try {
      if (!event.target.files.length) {
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
          return
        }
        console.log('Fileddatataaaa', event.target.files)
        if (event.target.name.includes("extraDocs")) {
          setAddMoreDocs(addMoreDocs.concat({ key: event.target.name, doc_name: 'Add More' }))
        }
        if (event.target.name.includes('AudFin')) {
          let tempdata = [...orgDocs]
          tempdata[0].docArr = [
            ...tempdata[0].docArr,
            { valKey: `AudFin_${tempdata[0].docArr.length}`, yearKey: `AudFinYear_${tempdata[0].docArr.length}`, yearKey: 'AudFinYear' }
          ]
          setOrgDocs(tempdata)
        }

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (event.target.name === 'buyerDetails') {
            const workbook = XLSX.read(e.target.result.split("base64,")[1], { type: 'base64' })
            const worksheetname = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetname]
            const finalRes = XLSX.utils.sheet_to_json(worksheet)
            setBuyersdata([
              ...buyersdata,
              ...finalRes
            ])
          }
          if (!file_type.includes("pdf") && !isImage) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          fileObj["filebase64"] = fileDataUrl
          setData({ ...data, [event.target.name]: fileObj })
          if (event.target.name.includes('draft')) {
            fetchLCDetails(event.target.files[0], event.target.name, fileObj)
            let index = lcOrgDocs.length
            setLCOrgDocs([
              ...lcOrgDocs,
              {
                serviceKey: 'lc_type_' + index,
                tenureKey: 'lc_tenure_' + index,
                issucountryKey: 'lc_issuing_country_' + index,
                issuBankKey: 'lc_issuing_bank_' + index,
                swiftCodeKey: 'lc_swift_code_' + index,
                draftKey: 'lc_draft_' + index
              }
            ])
          }
          setErrors({ ...errors, [event.target.name]: "" });
        }
      }
    } catch (e) {
      console.log('error in file uploadddd', e);
    }

  }
  function getDunsList() {
    setData({
      ...data, selectedDuns: null,
      buyerAddress: "",
      buyerPostalCode: "", buyerDunsNo: ""
    })
    setshowLoader(true)
    call('POST', 'getDUNSNo', {
      companyName: data.buyerName, countryCode: data.buyerCountry,
      userId, userEmail
    }).then((result) => {
      setshowLoader(false)
      if (result.length > 0) {
        setDunsData(result);
        setStage(1)
      }
      else {
        toastDisplay("No Related Buyer Details Found", "error")
        setDunsData([])
        setStage(1)
      }
    })
  }
  const addBuyer = () => {
    const validationFields = ["buyerDunsNo", "buyerCountry", "buyerName", "limitRequiredCurrency", "limitRequired"]
    let err = {}
    let buyerFields = [
      "buyerName",
      "buyerCountry",
      "buyerAddress",
      "buyerPostalCode",
      "buyerDunsNo",
      "currMaxOutStanding",
      "creditDays",
      "termsOfPayment",
      "currMaxOutStandingCurrency",
      "limitRequiredCurrency",
      "currentProjTOCurrency",
      "buyerAmountCurrency",
      "buyerAmount",
      "currentProjTO",
      "limitRequired"
    ]
    for (let index = 0; index < validationFields.length; index++) {
      const element = validationFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      }
    }
    if (!Object.keys(err).length) {
      let obj = {}
      buyerFields.forEach(k => {
        obj[k] = data[k]
      })
      console.log('BuyerObjjjj', obj);
      if (addBuyerPopup.isEdit) {
        let temp = [...buyersdata]
        const buyerindex = temp.findIndex(data => data.buyerName === obj.buyerName)
        if (buyerindex != -1) {
          temp[buyerindex] = obj
          setBuyersdata(temp)
        } else {
          toastDisplay('Buyer not found', "info")
        }
      } else {
        setBuyersdata(buyersdata.concat(obj))

      }
      setAddBuyerPopup({ show: false, isEdit: false })
    } else {
      setErrors(err)
    }
  }
  const onBuyerEdit = (item) => {
    setAddBuyerPopup({ show: true, isEdit: true, buyerName: item.buyerName })
    const country = item.buyerCountry.length === 2 ? { sortname: item.buyerCountry } : item.buyerCountry === 'UNITED STATES OF AMERICA' ? { sortname: "US" } : countrydata.find(data => data.name?.toLowerCase() == item.buyerCountry?.toLowerCase())
    setData({
      ...data,
      buyerName: item.buyerName,
      buyerCountry: country.sortname,
      buyerAmountCurrency: 'USD',
      buyerAmount: item.buyerAmount
    })
  }
  const formatDataForTable = () => {
    let tabledata = []
    buyersdata.forEach((item, index) => {
      let row = []
      row[0] = <img className='cursor' onClick={() => {
        let temp = [...selectedIndex]
        if (temp.indexOf(index) !== -1) {
          // If it does, remove it using splice
          temp.splice(temp.indexOf(index), 1);
        } else {
          // If it doesn't, add it to the array
          temp.push(index);
        }
        console.log('selectedindex', temp);
        setSelectedIndex(temp)
      }} src={`assets/images/${selectedIndex.includes(index) ? 'checked-green' : 'empty-check'}.png`} />
      row[1] = `${item.buyerName ? item.buyerName : 'NA'}`
      row[2] = `${item.buyerAmount ? Intl.NumberFormat("en", { notation: 'compact' }).format(item.buyerAmount) : 'NA'}`
      row[3] = `${item.buyerCountry ? item.buyerCountry : 'NA'}`
      row[4] = `${item.currentProjTOCurrency ? item.currentProjTOCurrency : ''} ${item.currentProjTO ? item.currentProjTO : 'NA'}`
      row[5] = `${item.limitRequiredCurrency ? item.limitRequiredCurrency : ''} ${item.limitRequired ? item.limitRequired : 'NA'}`
      row[6] = `${item.currMaxOutStandingCurrency ? item.currMaxOutStandingCurrency : ''} ${item.currMaxOutStanding ? item.currMaxOutStanding : 'NA'}`
      row[7] = `${item.creditDays || "NA"}`
      row[8] = `${item.termsOfPayment || "NA"}`
      row[9] = `${item.buyerDunsNo || "NA"}`
      row[10] = <img src='/assets/images/redirect.svg' className='cursor' onClick={() => onBuyerEdit(item)} />
      tabledata.push(row)
      row = []
    })
    setDbData(tabledata)
  }
  const formatDocsTable = () => {
    let tabledata = []
    buyersdata.filter((data, i) => selectedIndex.includes(i)).forEach((item, index) => {
      let row = []
      row[0] = item.buyerName
      row[1] = <div>
        <div className='col-md-12 my-3'>
          <label className='font-size-14'>Invoice Document </label>
        </div>
        <div className='col-md-12 my-3'>
          <label className='font-size-14'>PO Document </label>
        </div>


      </div>
      row[2] = <div>
        <div className='col-md-12 my-2'>
          <div className="col-md-12">
            <FileInput name={`${item.buyerName}_InvoiceDoc`} value={data[`${item.buyerName}_InvoiceDoc`]} error={errors[`${item.buyerName}_InvoiceDoc`]}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`${item.buyerName}_InvoiceDoc`]: null })} />
          </div>
        </div>
        <div className='col-md-12 my-2'>
          <div className="col-md-12">
            <FileInput name={`${item.buyerName}_PODoc`} value={data[`${item.buyerName}_PODoc`]} error={errors[`${item.buyerName}_PODoc`]}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`${item.buyerName}_PODoc`]: null })} />
          </div>
        </div>
      </div>
      tabledata.push(row)
      row = []
    })
    setDocsTable(tabledata)
  }
  const formatOrgDocsTable = () => {
    let tabledata = []
    orgDocs.forEach((item, index) => {
      let row = []
      row[0] = item.label
      row[1] = <div className='w-100'>
        {item.docArr.map(ele => {
          return <div class="dropdown w-100" >
            <div className='d-flex justify-content-between align-items-center cursor' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <label class="font-wt-600 font-size-13 cursor" >
                {data[ele.yearKey] || '-'}
              </label>
              <img src='/assets/images/arrowdown.png' className='ml-2' />
            </div>

            <ul class="dropdown-menu dropdownScroller" aria-labelledby="dropdownMenuButton1">
              {fiscalyears.map(element => {
                return <li className="dropdown-item cursor font-wt-500 " onClick={() => {
                  setData({
                    ...data,
                    [ele.yearKey]: element.label
                  })
                }} >{element.label}</li>
              })}
            </ul>
          </div>


        })}
      </div>
      row[2] = <div className='w-100'>
        {item.docArr.map(element => {
          return <div className='col-md-12 my-2'>
            <div className="col-md-12">
              <FileInput name={element.valKey} value={data[`${element.valKey}`]} error={errors[`${element.valKey}`]}
                onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`${element.valKey}`]: null })} />
            </div>
          </div>
        })}
      </div>
      tabledata.push(row)
    })
    setorgDocTblData(tabledata)
  }
  useEffect(() => {
    console.log('buyersdata', buyersdata);
    formatDataForTable()
  }, [buyersdata, selectedIndex])
  useEffect(() => {
    formatDocsTable()
  }, [buyersdata, data, selectedIndex])
  useEffect(() => {
    formatOrgDocsTable()
  }, [data, orgDocs])
  const handleMultiSelectchange = async (e, name, val, singleSelect) => {
    if (singleSelect) {
      if (name === 'supplierName') {
        getTTVBuyers(e.reverse()?.[0]?.[val])
      }
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  const formatLCOrgDocsTable = () => {
    let tabledata = []
    lcOrgDocs.forEach((item, index) => {
      let row = []
      const countryOG = countrys.find(ele => ele.sortname === data[`lc_Mapping_${index}`]?.["countryOfOrigin"])
      row[0] = <div class="dropdown w-100" >
        <div className='d-flex justify-content-between align-items-center cursor' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <label class="font-wt-600 font-size-13 cursor" >
            {data[`lc_Mapping_${index}`]?.["lc_purpose"] || '-'}
          </label>
          <img src='/assets/images/arrowdown.png' className='ml-2' />
        </div>

        <ul class="dropdown-menu dropdownScroller" aria-labelledby="dropdownMenuButton1">
          {LCServiceTypes.map(element => {
            return <li className="dropdown-item cursor font-wt-500 " onClick={() => {
              setData({
                ...data,
                [`lc_Mapping_${index}`]: {
                  ...data[`lc_Mapping_${index}`],
                  lc_purpose: element
                }
              })
            }} >{element}</li>
          })}
        </ul>
      </div>

      row[1] = <div className='w-100'>
        <label class="font-wt-600 font-size-13 cursor" >
          {data[`lc_Mapping_${index}`]?.["lcTenor"] || '-'}
        </label>
      </div>
      row[2] = <div class="dropdown w-100" >
        <div className='d-flex justify-content-between align-items-center cursor' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <label class="font-wt-600 font-size-13 cursor" >
            {countryOG?.name || '-'}
          </label>
          <img src='/assets/images/arrowdown.png' className='ml-2' />
        </div>

        <ul class="dropdown-menu dropdownScroller" aria-labelledby="dropdownMenuButton1">
          {countrys.map(element => {
            return <li className="dropdown-item cursor font-wt-500 " onClick={() => {
              setData({
                ...data,
                [`lc_Mapping_${index}`]: {
                  ...data[`lc_Mapping_${index}`],
                  countryOfOrigin: element.sortname
                }
              })
            }} >{element.name}</li>
          })}
        </ul>
      </div>
      row[3] = <div className='w-100'>
        <label class="font-wt-600 font-size-13 cursor" >
          {data[`lc_Mapping_${index}`]?.["lcIssuingBankName"] || '-'}
        </label>
      </div>
      row[4] = <div className='w-100'>
        <label class="font-wt-600 font-size-13 cursor" >
          {data[`lc_Mapping_${index}`]?.["lcIssuingBankSwiftCode"] || '-'}
        </label>
      </div>
      row[5] = <div className='w-100'>
        <div className='col-md-12 my-2'>
          <div className="col-md-12">
            <FileInput onEyeBuPress={() => {
              if (data[item.draftKey]) {
                onViewLC(index, item.draftKey)
              } else {
                toastDisplay("LC Draft Not Found", "info")
              }
            }} name={item.draftKey} value={data[`${item.draftKey}`]} error={errors[`${item.draftKey}`]}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`${item.draftKey}`]: null })} />
          </div>
        </div>
      </div>
      tabledata.push(row)
    })
    setLCOrgTblDocs(tabledata)
  }
  const handleMultiSelect = async (e, name, val) => {
    console.log("handleMultiSelect", e, name, val);
    if (e?.[0]?.id === "addNewBank") {
      setAddBankPopup(true)
      return
      // return window.open(`${platformURL}/viewprofile?action=addNewBank`, "_blank")
    }
    if (e?.[0]?.id === "temp") {
      setshowLoader(true)
      if (name === "lcIssuingBank") {
        let swiftId = await call('POST', 'insertSwiftBankInMaster', { bankName: e?.[0]?.typedInput })
        setshowLoader(false)
        call('POST', 'getSwiftMaster').then((result) => {
          setSwiftData(result)
          setAllSwiftData(result)
          if (showOCRPopup.show) {
            setData({
              ...data, [`lc_Mapping_${showOCRPopup.index}`]: {
                ...data[`lc_Mapping_${showOCRPopup.index}`],
                [name]: e?.[0]?.typedInput,
                "lcIssuingBankAddress": null,
                "lcIssuingBankSwiftCode": null
              }
            })
          } else {
            setData({
              ...data,
              [name]: swiftId,
              "lcIssuingBankName": e?.[0]?.typedInput, "lcIssuingBankAddress": null,
              "lcIssuingBankSwiftCode": null
            });
          }

        }).catch((e) => {
          console.log('error in getSwiftMaster', e);
        })
      }
      else {
        let portId = await call('POST', 'insertPortInMaster', { portName: e?.[0]?.typedInput })
        setshowLoader(false)
        call('POST', 'getOldPortMaster').then((result) => {
          setPorts(result)
          setAllPorts(result)
          if (showOCRPopup.show) {
            setData({
              ...data, [`lc_Mapping_${showOCRPopup.index}`]: {
                ...data[`lc_Mapping_${showOCRPopup.index}`],
                [name]: portId,
              }
            })
          } else {
            setData({
              ...data,
              [name]: portId
            });
          }

        }).catch((e) => {
          // console.log('error in getOldPortMaster', e);
        })
      }
    }
    else {
      if (name === "lcIssuingBank") {
        let selectedValue = e?.[0]?.id ? e.reverse()?.[0] : {}
        if (showOCRPopup.show) {
          setData({
            ...data, [`lc_Mapping_${showOCRPopup.index}`]: {
              ...data[`lc_Mapping_${showOCRPopup.index}`],
              [name]: selectedValue.id || null,
              "lcIssuingBankAddress": null,
              "lcIssuingBankSwiftCode": null,
              "lcIssuingBankName": selectedValue.bankName || "",
              "lcIssuingBankAddress": selectedValue.branchName || "",
              "lcIssuingBankSwiftCode": selectedValue.swiftCode || ""
            }
          })
        } else {
          setData({
            ...data,
            [name]: selectedValue.id || null,
            "lcIssuingBankName": selectedValue.bankName || "",
            "lcIssuingBankAddress": selectedValue.branchName || "",
            "lcIssuingBankSwiftCode": selectedValue.swiftCode || ""
          })
        }
      }
      else if (name === "beneficiaryBank") {
        let selectedEntry = e?.[0]?.id && e.reverse()?.[0]?.id ? e.reverse()?.[0] : {}
        if (showOCRPopup.show) {
          setData({
            ...data, [`lc_Mapping_${showOCRPopup.index}`]: {
              ...data[`lc_Mapping_${showOCRPopup.index}`],
              [name]: selectedEntry.id || null,
              "beneficiaryBankName": selectedEntry.beneficiaryBankName || "", "beneficiaryAccountNo": selectedEntry.beneficiaryAccountNo || "",
              "beneficiaryIfscCode": selectedEntry.beneficiaryIfscCode || "", "beneficiarySwiftCode": selectedEntry.beneficiarySwiftCode || ""
            }
          })
        } else {
          setData({
            ...data,
            [name]: selectedEntry.id || null,
            "beneficiaryBankName": selectedEntry.beneficiaryBankName || "", "beneficiaryAccountNo": selectedEntry.beneficiaryAccountNo || "",
            "beneficiaryIfscCode": selectedEntry.beneficiaryIfscCode || "", "beneficiarySwiftCode": selectedEntry.beneficiarySwiftCode || ""
          });
        }

      }
      else {
        if (showOCRPopup.show) {
          setData({
            ...data, [`lc_Mapping_${showOCRPopup.index}`]: {
              ...data[`lc_Mapping_${showOCRPopup.index}`],
              [name]: e?.[0]?.id ? e.reverse()?.[0]?.id : null
            }
          })
        } else {
          setData({
            ...data,
            [name]: e?.[0]?.id ? e.reverse()?.[0]?.id : null
          })
        }

      }
    }
  };
  useEffect(() => {
    formatLCOrgDocsTable()
  }, [lcOrgDocs, data, errors])

  const fetchLCDetails = (lcDocument, keyName, fileObj) => {
    try {
      let formData = new FormData()
      formData.append("doc", lcDocument)
      setshowLoader(true)
      toastDisplay("Analyzing document kindly wait", "info")
      axios.post(`${aiServerBackendUrl}/text-analyse`, formData, {
      }).then(res => {
        setshowLoader(false)
        console.log("then text-analyse", res);
        if (res.data.length) {
          //setData({ ...data, ...res.data[0], ["readerResp"]: res.data[0] })
          //toggleIsConfirmedLC(res.data[0].isConfirmedLC)
          //setTab(1)
          setOCRData({ ...res.data[0], '32B1': 'USD', ["readerResp"]: res.data[0] })
          mapOCRDataToFields({ ...res.data[0], '32B1': 'USD', ["readerResp"]: res.data[0] }, keyName, fileObj)

        }
        else {
          toastDisplay("Something went wrong", "error")
        }
      }).catch(function (response) {
        setshowLoader(false)
        console.log("error in text-analyse", response)
        toastDisplay("Something went wrong", "error")
      });

    } catch (e) {

    }
  }
  const onViewLC = (index, draftKey) => {
    let blob = dataURItoBlobURL(data[draftKey].filebase64)
    setDraftLCBlob(blob)
    convertPdfToTxt(blob, index)
  }
  console.log('UserBankssssss', userBanks);
  async function convertPdfToTxt(LCBlob, i) {
    setshowLoader(true)
    let formData = new FormData()
    let blob = await fetch(LCBlob).then((response) => response.blob());
    formData.append("doc", blob, 'draftLCDocument.pdf')
    axios.post(`${aiServerBackendUrl}/pdf-to-text`, formData, {
    })
      .then(res => {
        console.log("thenpdf_to_text", res);
        setshowLoader(false)
        setOcrTxtFormat(res?.data?.["pages"] || [])
        setOCRPopup({
          show: true,
          index: i
        })
      }).catch(function (response) {
        console.log("errorpdf_to_text", response)
        setshowLoader(false)
      });
  }
  async function mapOCRDataToFields(ocrData, keyName, fileObj) {
    if (Object.keys(ocrData).length) {
      let processedData = {}
      if (ocrData["lc_issuing_bank_name"]) {
        setshowLoader(true)
        let swiftId = await call('POST', 'insertSwiftBankInMaster', {
          bankName: ocrData["lc_issuing_bank_name"],
          bankAddress: ocrData["lc_issuing_bank_address"], swiftCode: ocrData["lc_issuing_bank_swift_code"]
        })
        let apiResult = await call('POST', 'getSwiftMaster')
        setshowLoader(false)
        setSwiftData(apiResult)
        setAllSwiftData(apiResult)
        processedData["lcIssuingBank"] = swiftId
        processedData["lcIssuingBankName"] = ocrData["lc_issuing_bank_name"]
        processedData["lcIssuingBankAddress"] = ocrData["lc_issuing_bank_address"]
        processedData["lcIssuingBankSwiftCode"] = ocrData["lc_issuing_bank_swift_code"]
      }
      if (ocrData["20"]) {
        processedData["lcNo"] = ocrData["20"]
      }
      if (ocrData["44E"]) {
        let matchWord = ocrData["44E"].split(" ")[0].toUpperCase()
        let temp = ports.filter((i) => {
          if (i.port_name.toUpperCase().includes(matchWord)) {
            return i
          }
        })
        if (temp.length) {
          processedData["portOfLoading"] = temp[0].id
          processedData["portOfLoadingName"] = temp[0].port_name
        }
      }
      if (ocrData["44F"]) {
        let matchWord = ocrData["44F"].split(" ")[0].toUpperCase()
        let temp = ports.filter((i) => {
          if (i.port_name.toUpperCase().includes(matchWord)) {
            return i
          }
        })
        if (temp.length) {
          processedData["portOfDischarge"] = temp[0].id
          processedData["portOfDischargeName"] = temp[0].port_name
        }
      }
      if (ocrData["45A"]) {
        processedData["commodity"] = ocrData["45A"]
      }
      if (ocrData["44C"]) {
        let temp = moment(ocrData["44C"]).isValid()
        if (temp) {
          processedData["expectedDateOfShipment"] = moment(ocrData["44C"]).format("YYYY-MM-DD")
        }
      }
      // if (ocrData["48"]) {
      //   let temp = lcTenorDD.filter((i) => {
      //     if (i.ocrValue.includes(ocrData["48"])) {
      //       return i
      //     }
      //   })
      //   if (temp.length) {
      //     processedData["lcTenor"] = temp[0]["name"]
      //   }
      // }
      console.log("processedDataaaaaaaaaaaaaaaaaaa", data);
      let keyArr = keyName.split("_")
      setData({
        ...data,
        [keyName]: fileObj,
        ['lc_Mapping_' + keyArr[keyArr.length - 1]]: processedData
      })
      toggleOcrFieldMapped(true)
    }
  }
  useEffect(() => {
    // Get master data start
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    call('POST', 'getOldPortMaster').then((result) => {
      setPorts(result)
      setAllPorts(result)
    }).catch((e) => {
      console.log('error in getOldPortMaster', e);
    })
    call('POST', 'getSwiftMaster').then((result) => {
      setSwiftData(result)
      setAllSwiftData(result)
    }).catch((e) => {
      console.log('error in getSwiftMaster', e);
    })
    // call('POST', 'getFinanciersForLC').then((result) => {
    //   setLCFinanciers(result)
    // }).catch((e) => {
    //   console.log('error in getFinanciersForLC', e);
    // })
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      setLCCodes(result.mandatoryFields.concat(result.optionalFields))
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
  }, [])
  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let tempPort = []
    let filtered = []
    if (name === "ports") {
      tempPort = [{ id: "temp", "port_name": "Add New Option", typedInput }]
      filtered = allPorts.filter((i) => {
        if (i.port_name && i.port_name.toLowerCase().includes(typedInput.toLowerCase())) {
          return i
        }
      })
    }
    if (name === "swiftData") {
      tempPort = [{ id: "temp", "bankName": "Add New Option", typedInput }]
      filtered = allSwiftData.filter((i) => {
        if (i.bankName && i.bankName.toLowerCase().includes(typedInput.toLowerCase())) {
          return i
        }
      })
    }
    if (!filtered.length) {
      filtered = tempPort
    }
    if (name === "ports") {
      setPorts(filtered)
    }
    if (name === "swiftData") {
      setSwiftData(filtered)
    }
  };
  const handleLCValidations = () => {
    let err = {}
    if (!data.supplierName) {
      err.supplierName = 'Mandatory Field'
    }
    if (!data.applicationType) {
      err.applicationType = 'Mandatory Field'
    }
    for (let i = 0; i <= lcOrgDocs.length - 1; i++) {
      const element = lcOrgDocs[i]
      if (data[element.draftKey]) {
        let validateFields = ["beneficiaryBank", "lcNo"]
        if (!(ocrData["32B1"] && ocrData["32B2"])) {
          err[element.draftKey] = "Missing Some Fields"
          err["32B2"] = "Mandatory Field"
        }
        validateFields.forEach((item) => {
          if (!data[`lc_Mapping_${i}`]?.[item]) {
            err[element.draftKey] = "Missing Some Fields"
            err[item] = 'Mandatory Field'
          }
        })
      }
    }
    if (isEmpty(err)) {
      //create new application
      createNewApplicaton()
    } else {
      setErrors(err)
    }
  }
  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showOCRPopup.show &&
        <FinanceInvoiceModal limitinvoice={showOCRPopup.show} closeSuccess={() => setOCRPopup({
          show: false,
          index: null
        })} isCentered={true} modalSize={"xl"}>
          <LcDetailsV2
            draftLCBlob={draftLCBlob}
            ocrTxtFormat={ocrTxtFormat}
            adminView={true}
            reloadUserBankDetails={() => { }}
            handleMultiSelect={handleMultiSelect}
            handleFilterOptions={handleFilterOptions}
            amendmentId={""}
            fieldLabels={[]}
            fieldComments={{}}
            fieldChanged={{}}
            ammendPopup={{}}
            toggleAmmendPopup={() => { }}
            ammendPopup2={{}}
            toggleAmmendPopup2={() => { }}
            ammendFields={{}}
            setAmmendFields={() => { }}
            onlyView={false} lcCodes={lcCodes} ocrData={ocrData} handleOCRFieldsChange={handleOCRFieldsChange}
            data={data[`lc_Mapping_${showOCRPopup.index}`]} setData={setData} handleInputChange={handleInputChange} errors={errors} handleFile={handleFile} countrys={countrys} currencyData={most_used_currencies}
            ports={ports} userBanks={userBanks} swiftData={swiftData}
            tab1={0} setTab1={() => { }} confirmbanking={false} setConfirmbanking={() => { }} confirmBank={false} />
        </FinanceInvoiceModal>
      }
      {addBankPopup &&
        <FinanceInvoiceModal limitinvoice={addBankPopup} closeSuccess={() => setAddBankPopup(false)} isCentered={true} modalSize={"xl"}>
          <div>
            <p className='text-decoration-underline text-color1 font-size-14 mt-3'>Add new bank</p>
            <>
              <p className='font-size-13 mt-4'>Beneficiary bank details</p>
              <div className='row justify-content-center'>
                <div className="col-md-5 pt-1 ">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Bank name"}
                      name={"beneficiaryBankName"} value={data.beneficiaryBankName} error={errors.beneficiaryBankName}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Branch"}
                      name={"beneficiaryBranch"} value={data.beneficiaryBranch} error={errors.beneficiaryBranch}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Account holder name"}
                      name={"beneficiaryAccountName"} value={data.beneficiaryAccountName} error={errors.beneficiaryAccountName}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Account no."}
                      name={"beneficiaryAccountNo"} value={data.beneficiaryAccountNo} error={errors.beneficiaryAccountNo}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"IFSC code"}
                      name={"beneficiaryIfscCode"} value={data.beneficiaryIfscCode} error={errors.beneficiaryIfscCode}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    {/* <NewSelect isAstrix={true} type={"text"} label={"SWIFT code"}
                                name={"BenfSwiftCode"} value={data.BenfSwiftCode} error={errors.BenfSwiftCode}
                                selectData={swiftCodedata} optionLabel={"swiftCode"} optionValue={'swiftCode'}
                                onChange={handleInputChange} /> */}
                    <MultipleSelect
                      Id="SWIFT code"
                      Label="SWIFT code"
                      filterOption={() => true}
                      onInputChange={(e) => {
                        handleFilterOptions(e, "swiftCodedata")
                      }}
                      optiondata={allSwiftData}
                      onChange={(e) => handleMultiSelectchange(e, "beneficiarySwiftCode", "swiftCode", true)}
                      value={data.beneficiarySwiftCode ? [data.beneficiarySwiftCode] : []}
                      name="beneficiarySwiftCode"
                      labelKey={"swiftCode"}
                      valKey={"swiftCode"}
                      error={errors.beneficiarySwiftCode}
                    />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewSelect isAstrix={true} label={"Country"}
                      selectData={countrys} name={"beneficiaryCountry"}
                      value={data.beneficiaryCountry} optionLabel={"name"} optionValue={'sortname'}
                      onChange={handleInputChange} error={errors.beneficiaryCountry} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewSelect isAstrix={true} label={"State"}
                      selectData={states} name={"beneficiaryState"}
                      value={data.beneficiaryState} optionLabel={"name"} optionValue={'sortname'}
                      onChange={handleInputChange} error={errors.beneficiaryState} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"City"}
                      name={"beneficiaryCity"} value={data.beneficiaryCity} error={errors.beneficiaryCity}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} type={"text"} label={"Address"}
                      name={"beneficiaryBranchAddress"} value={data.beneficiaryBranchAddress} error={errors.beneficiaryBranchAddress}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="col-md-5 pt-1">
                  <div className="col-md-11 px-0">
                    <NewInput isAstrix={true} label={"Postal Code"} type="number"
                      name={"beneficiaryPostalCode"} value={data.beneficiaryPostalCode}
                      onChange={handleInputChange} error={errors.beneficiaryPostalCode} />
                  </div>
                </div>
              </div>
            </>
            <>
              <p className='font-size-13 mt-4 text-decoration-underline'>Attach documents</p>
              <div className='row justify-content-center'>
                <div className="col-md-5">
                  <label className="font-size-13">Bank statement</label>
                  <div className="row form-group">
                    <div className="col-md-11">
                      <FileInput name={"Bank_Statement"} value={data.Bank_Statement} error={errors.Bank_Statement}
                        onChange={handleFile} isEditable={true}
                        onUploadCancel={() => setData({ ...data, "Bank_Statement": null })} />
                      {errors.Bank_Statement ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors.Bank_Statement}</b></div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <label className="font-size-13">Blank cheque</label>
                  <div className="row form-group">
                    <div className="col-md-11">
                      <FileInput name={"Blank_Cheque"} value={data.Blank_Cheque} error={errors.Blank_Cheque}
                        onChange={handleFile} isEditable={true}
                        onUploadCancel={() => setData({ ...data, "Blank_Cheque": null })} />
                      {errors.Blank_Cheque ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors.Blank_Cheque}</b></div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <label className="font-size-13">Passbook</label>
                  <div className="row form-group">
                    <div className="col-md-11">
                      <FileInput name={"Bank_Passbook"} value={data.Bank_Passbook} error={errors.Bank_Passbook}
                        onChange={handleFile} isEditable={true}
                        onUploadCancel={() => setData({ ...data, "Bank_Passbook": null })} />
                      {errors.Bank_Passbook ? <div class="text-danger mt-2 font-size-12">
                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        <b>{errors.Bank_Passbook}</b></div> : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <img
                  onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                  className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                <label>Select as default bank</label>
              </div>
            </>
            <button onClick={() => {
              let err = {}
              const fields = [
                "beneficiaryBankName",
                "beneficiaryAccountNo",
                "beneficiaryIfscCode",
                "beneficiarySwiftCode",
                "beneficiaryBranch",
                "beneficiaryBranchAddress",
                "beneficiaryCity",
                "beneficiaryState",
                "beneficiaryCountry",
                "beneficiaryPostalCode",
                "beneficiaryAccountName",
              ]
              fields.forEach(element => {
                if (!data[element]) {
                  err[element] = 'Mandatory Field'
                }
              })
              if (isEmpty(err)) {
                let bankFields = [
                  "beneficiaryBankName",
                  "beneficiaryAccountNo",
                  "beneficiaryIfscCode",
                  "beneficiarySwiftCode",
                  "beneficiaryBranch",
                  "beneficiaryBranchAddress",
                  "beneficiaryCity",
                  "beneficiaryState",
                  "beneficiaryCountry",
                  "beneficiaryPostalCode",
                  "beneficiaryAccountName",
                  "bankDocs"
                ]
                let reqObj = {}
                let resObj = {}
                bankFields.forEach(element => {
                  reqObj[element] = data[element] || ""
                  resObj[element] = ""

                })
                reqObj["id"] = new Date().getTime()
                resObj["id"] = ""
                setUserBanks(userBanks.concat([reqObj]))
                setAddBankPopup(false)
                setData({
                  ...data,
                  ...resObj
                })
              } else {
                setErrors(err)
              }
              //setSubmitted(true)
            }} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}>Add new bank details</button>
          </div>
        </FinanceInvoiceModal>
      }
      <div className="container-fluid">
        {requestMoreDetails.modal && requestMoreDetails.page === 1 &&
          <div className={`modal fade ${requestMoreDetails.modal && "show"}`} style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content submitmodal pb-4">
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setRequestMoreDetails({ modal: false, page: 0 })}></button>
                </div>
                <div className="modal-body text-center">
                  <p>Request more details</p>
                  <div className="row">
                    <div className='w-100'>
                      <div className='row'>
                        {data.additionalDetails && data.additionalDetails.map((item, index) => {
                          return (
                            <>
                              <div className='col-md-6 move-m p-0'>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <input disabled={true} className={"form-control bg-white"} placeholder={item.title} />
                                    {item.type == 'percentage' ? <p className="input-date-icon">%</p> :
                                      item.type == 'currency' ? <p className="input-date-icon">$</p> :
                                        item.type == 'text' ? <p className="input-date-icon">T</p> :
                                          <img src={"/assets/images/calender.png"} alt="" className="input-date-icon" />
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-2 move-m p-0'>
                                <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2 cursor"
                                  onClick={() => {
                                    let temp = data.additionalDetails;
                                    temp = temp.filter((i, j) => {
                                      if (j != index) {
                                        return i
                                      }
                                    })
                                    setData({ ...data, additionalDetails: temp, fieldTitle: item.title, fieldType: item.type })
                                  }}
                                />
                                <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2 cursor"
                                  onClick={() => {
                                    let temp = data.additionalDetails;
                                    temp = temp.filter((i, j) => {
                                      if (j != index) {
                                        return i
                                      }
                                    })
                                    setData({ ...data, additionalDetails: temp })
                                  }}
                                />
                              </div></>
                          )
                        })
                        }
                      </div>
                      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" >
                        <div class="col-md-6 move-m p-0">
                          <div class="col-md-6">
                            <p style={{ "fontSize": "12px" }}>Select field type</p>
                          </div>
                          <div className="row justify-between gap-2">
                            {infoType.map((item) => {
                              return (
                                <div
                                  onClick={() => setData({ ...data, fieldType: item.unit })}
                                  style={data.fieldType == item.unit ? {
                                    border: "2px solid #B9EFFF"
                                  } : null}
                                  className={`mb-1 col-md-6 form-control w-45 cursor `}>
                                  <label>{item.name}</label>
                                </div>
                              )
                            })}
                            {errors.fieldType ? <div class="text-danger mt-2 font-size-12">
                              <b>{errors.fieldType}</b></div> : ''}
                          </div>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className="col-md-6 move-m p-0">
                          <NewInput isAstrix={true} type={"text"} label={"Title"}
                            name={"fieldTitle"} value={data.fieldTitle} error={errors.fieldTitle} onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 move-left text-blue">
                    <a onClick={() => {
                      if (!(data.fieldType && data.fieldTitle)) {
                        toastDisplay("Fill details to add", "info")
                      }
                      else {
                        let temp = data.additionalDetails || []
                        temp.push({ title: data.fieldTitle, type: data.fieldType })
                        setData({ ...data, additionalDetails: temp, fieldTitle: "", fieldTitle: "" })
                        // setAddMore(true)
                      }
                    }}><p>Add more</p></a>
                  </div>
                  <button type="button" className={`mx-2 new-btn w-20 py-2 px-2 text-white`} onClick={async () => {
                    if (data?.additionalDetails?.length) {
                      setshowLoader(true)
                      // await call("POST", "raiseInvoiceAmendment", {
                      //   applicationId: quotesDetail.limit_id, additionalDetails: data.additionalDetails,
                      //   userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                      //   sellerId: quotesDetail.created_by
                      // })
                      toastDisplay("Request forwarded", "success")
                      setRequestMoreDetails({ modal: false, page: 0 })
                      setshowLoader(false)
                    }
                    else {
                      toastDisplay("Add atleast 1 request to send", "info")
                    }
                  }}>Save</button>
                </div>
              </div>
            </div>
          </div>}
        <div className={`modal fade ${addBuyerPopup.show && "show"}`} style={addBuyerPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
          <div className="modal-dialog modal-md mr-0 my-0">
            <div className="modal-content submitmodal p-0"
            >

              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >Add Buyer</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                      setAddBuyerPopup({ show: false, isEdit: false })
                      setStage(0)
                    }}></button>
                  </div>
                </div>
              </div>

              <div className="modal-body p-0 ">
                <div className='px-4'>
                  <div className='col-md-12'>
                    <div className="row align-items-center mt-2">
                      <div className="form-group col-md-12 mb-0">
                        <NewInput type={"text"} label={"Buyer Name"} isDisabled={addBuyerPopup.isEdit}
                          name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                          onChange={handleInputChange} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className="row align-items-center mt-2">
                      <div className="form-group col-md-12 mb-0">
                        <NewSelect isAstrix={true} label={"Country"}
                          selectData={countrydata} name={"buyerCountry"}
                          value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleInputChange} error={errors.buyerCountry} />
                      </div>
                    </div>
                  </div>
                  {stage === 1 &&
                    <div>
                      <label className='mb-4' >Select DUNS No.</label>
                      <div className='dunsDivCorporate'>
                        {dunsData.length ? dunsData.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setData({
                                  ...data, selectedDuns: index,
                                  buyerAddress: item.address.street,
                                  buyerPostalCode: item.address.postalCode, buyerDunsNo: item.duns,
                                  buyersAPIDetail: item
                                })
                              }}
                              className={`dunsCard cursor mb-4 px-4 py-2 ${data.selectedDuns === index ? "selectedDunsCard" : ""} w-100`}
                            >
                              <h6 className='text-color1 font-wt-500 mb-0'>{item.duns}</h6>
                              <label className='text-color-value mb-0'>{item.companyName}</label>
                              <label className='text-color-value mb-0'>{`${item.address.street}, ${item.address.city}, ${item.address.countryCode}, ${item.address.postalCode}`}</label>
                            </div>
                          )
                        }) : (
                          <h6>No Related Buyer Details Found</h6>
                        )}
                      </div>

                    </div>
                  }
                  {stage === 2 &&
                    <div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <NewInput type={"text"} label={"DUNS No."} isDisabled={data.selectedDuns !== undefined}
                              name={"buyerDunsNo"} value={data.buyerDunsNo} error={errors.buyerDunsNo}
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <InputWithSelect
                              isAstrix={false}
                              type={"number"}
                              label={"Amount (Cr.)"}
                              selectData={most_used_currencies}
                              selectName={"buyerAmountCurrency"}
                              selectValue={data.buyerAmountCurrency}
                              optionLabel={"code"}
                              optionValue={'code'}
                              name={"buyerAmount"}
                              value={data.buyerAmount}
                              error={errors.buyerAmount}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <InputWithSelect
                              isAstrix={false}
                              type={"number"}
                              label={"Projected T/O  current fy"}
                              selectData={most_used_currencies}
                              selectName={"currentProjTOCurrency"}
                              selectValue={data.currentProjTOCurrency}
                              optionLabel={"code"}
                              optionValue={'code'}
                              name={"currentProjTO"}
                              value={data.currentProjTO}
                              error={errors.currentProjTO}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <InputWithSelect
                              isAstrix={false}
                              type={"number"}
                              label={"Limit required"}
                              selectData={most_used_currencies}
                              selectName={"limitRequiredCurrency"}
                              selectValue={data.limitRequiredCurrency}
                              optionLabel={"code"}
                              optionValue={'code'}
                              name={"limitRequired"}
                              value={data.limitRequired}
                              error={errors.limitRequired}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <InputWithSelect
                              isAstrix={false}
                              type={"number"}
                              label={"Maximum outstanding current FY"}
                              selectData={most_used_currencies}
                              selectName={"currMaxOutStandingCurrency"}
                              selectValue={data.currMaxOutStandingCurrency}
                              optionLabel={"code"}
                              optionValue={'code'}
                              name={"currMaxOutStanding"}
                              value={data.currMaxOutStanding}
                              error={errors.currMaxOutStanding}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <NewInput type={"text"} label={"Credit Days"}
                              name={"creditDays"} value={data.creditDays} error={errors.creditDays}
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <NewInput type={"text"} label={"Terms Of Payment"}
                              name={"termsOfPayment"} value={data.termsOfPayment} error={errors.termsOfPayment}
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <NewInput type={"text"} label={"Address"}
                              name={"buyerAddress"} value={data.buyerAddress} error={errors.buyerAddress}
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className="row align-items-center mt-2">
                          <div className="form-group col-md-12 mb-0">
                            <NewInput type={"text"} label={"Postal Code"}
                              name={"buyerPostalCode"} value={data.buyerPostalCode} error={errors.buyerPostalCode}
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>

                    </div>
                  }
                </div>
                <button className={`new-btn w-30 my-4 mx-4 py-2 px-2 text-white cursor`} onClick={() => {
                  if (stage === 0) {
                    let err = {}
                    if (!data.buyerName) {
                      err.buyerName = 'Mandatory Field'
                    }
                    if (!data.buyerCountry) {
                      err.buyerCountry = 'Mandatory Field'
                    }
                    if (isEmpty(err)) {
                      getDunsList()
                    } else {
                      setErrors(err)
                    }
                  } else if (stage === 1) {
                    //add buyer api
                    if (data.selectedDuns !== undefined) {
                      setStage(2)
                    } else {
                      toastDisplay("No Duns Selected", "info")
                      setStage(2)

                    }
                  } else {
                    addBuyer()
                  }
                }}>{(stage === 0 || stage === 1) ? 'Continue' : addBuyerPopup.isEdit ? 'Update Buyer' : 'Add Buyer'}</button>

              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <SideBarV2 state={"taskManagercorporate"} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Application Form"}
              userTokenDetails={userTokenDetails} />


            <div className="p-2 card border-0 rounded-3 card-layout h-100">
              <label className='w-100 text-center mt-2 font-size-18 font-wt-600'>{`Add New Application`} </label>
              <div className='row p-4'>
                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      {/* <NewSelect isAstrix={true} label={"Supplier Name"}
                        selectData={suppliers} name={"supplierName"}
                        value={data.supplierName} optionLabel={"EXPORTER_NAME"} optionValue={'EXPORTER_CODE'}
                        onChange={handleInputChange} error={errors.supplierName} /> */}
                      <MultipleSelect
                        isMulti
                        Id="Supplier Name"
                        Label="Supplier Name"
                        selectedvalue="Supplier Name"
                        optiondata={suppliers}
                        onChange={(e) => handleMultiSelectchange(e, "supplierName", "EXPORTER_CODE", true)}
                        value={data.supplierName ? [data.supplierName] : []}
                        name="supplierName"
                        labelKey={"EXPORTER_NAME"}
                        valKey={"EXPORTER_CODE"}
                        customStyles={{
                          backgroundColor: '#DEF7FF',
                          borderRadius: '10px'
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <NewInput type={"text"} label={"Application No."}
                        name={"applicationNo"} value={data.applicationNo} error={errors.applicationNo}
                        onChange={handleInputChange} />
                    </div>
                  </div>
                </div> */}

                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <NewSelect isAstrix={true} label={"Application Type"}
                        selectData={ApplicationType} name={"applicationType"}
                        value={data.applicationType} optionLabel={"name"} optionValue={'name'}
                        onChange={handleInputChange} error={errors.applicationType} />
                    </div>
                  </div>
                </div>

                {data.additionalDetails && data.additionalDetails.length ?
                  <div className='w-100 mt-2'>
                    <div className="row">
                      {data.additionalDetails.map((item, index) =>
                        <div className='col-md-4'>
                          <div className="row form-group">
                            <div className="col-md-12">
                              {item.type === 'currency' ?
                                <InputWithSelect
                                  isAstrix={false}
                                  type={"number"}
                                  label={item.title}
                                  selectData={most_used_currencies}
                                  selectName={`${item.title}_currency`}
                                  selectValue={data[`${item.title}_currency`]}
                                  optionLabel={"code"}
                                  optionValue={'code'}
                                  name={`${item.title}_value`}
                                  value={data[`${item.title}_value`]}
                                  error={errors[`${item.title}_value`]}
                                  onChange={handleInputChange}
                                />
                                : <NewInput
                                  type={item.type === 'date' ? 'date' : 'text'}
                                  value={data[`${item.title}_value`]}
                                  label={item.title}
                                  onChange={handleInputChange}
                                  name={`${item.title}_value`}
                                  error={errors[`${item.title}_value`]}
                                />
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  : null}
                <div >
                  <div className='d-flex flex-row gap-2 align-items-center cursor w-auto' onClick={() => setRequestMoreDetails({ modal: true, page: 1 })}>
                    <label className='text-color1 font-size-14 font-wt-600 mb-0'>Add more details</label>
                    <img src={"assets/images/right-arrow-icon.svg"} alt="Back" height={15} width={15} />
                  </div>
                </div>
                {data.applicationType === 'Factoring' && <>
                  <div className=' row mt-4'>

                    <label className='font-size-14 font-wt-600 text-decoration-underline'>Organizational documents</label>

                    <ExpandableTable
                      filterData={[]}
                      setFilterData={[]}
                      filteredSearch={[]}
                      setFilteredSearch={[]}
                      tableFixed data={orgDocTblData}
                      columns={[
                        { name: "Document Name", width: "40%" },
                        { name: "Year", width: "10%" },
                        { name: "Attach Document", width: "50%" },
                      ]}
                      overalldata={buyersdata}
                      expand={[]}
                      tableExpand={[]}
                      expandKey={"buyerName"}
                      disableAction={false}
                    />
                    {/* <>
                    <div className='col-md-6 my-2'>
                      <label className="pl-2 font-size-14 font-wt-300">Audited Financial Statements of FY 21-22, FY 20-21, FY 19-20</label>
                      <div className="col-md-12">
                        <FileInput name={"AudFin"} value={data.AudFin} error={errors.AudFin}
                          onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "AudFin": null })} />
                      </div>
                    </div>
                    <div className='col-md-6 my-2'>
                      <label className="pl-2 font-size-14 font-wt-300">GST Returns (3B, Apr'21 - Present)</label>
                      <div className="col-md-12">
                        <FileInput name={"GSTReturns"} value={data.GSTReturns} error={errors.GSTReturns}
                          onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "GSTReturns": null })} />
                      </div>
                    </div>
                    <div className='col-md-6 my-2'>
                      <label className="pl-2 font-size-14 font-wt-300">Provisional P/L & B/S of current financial year</label>
                      <div className="col-md-12">
                        <FileInput name={"CurrentProvisionalPLBS"} value={data.CurrentProvisionalPLBS} error={errors.CurrentProvisionalPLBS}
                          onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "CurrentProvisionalPLBS": null })} />
                      </div>
                    </div>
                    <div className='col-md-6 my-2'>
                      <label className="pl-2 font-size-14 font-wt-300">List of Shareholders</label>
                      <div className="col-md-12">
                        <FileInput name={"ListOfShareHolders"} value={data.ListOfShareHolders} error={errors.ListOfShareHolders}
                          onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "ListOfShareHolders": null })} />
                      </div>
                    </div>
                    <div className='col-md-6 my-2'>
                      <label className="pl-2 font-size-14 font-wt-300">Latest Bank Statement (Last 6 Months) of all active banks</label>
                      <div className="col-md-12">
                        <FileInput name={"Prev6MonBankStatement"} value={data.Prev6MonBankStatement} error={errors.Prev6MonBankStatement}
                          onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "Prev6MonBankStatement": null })} />
                      </div>
                    </div>



                  </>

                  {addMoreDocs.map((item, index) => {
                    return <div className="col-md-6 my-2">
                      {docNamePicker.id === index ? (
                        <div className="w-25 d-inline-block">
                          <NewInput name={"addMore" + index} value={tempValue}
                            onChange={(e) => setTempValue(e.target.value)} />
                        </div>) : (
                        <label className="pl-2 font-size-14 font-wt-300">{item.doc_name}</label>
                      )}
                      <span>
                        {docNamePicker.id === index ? (
                          <>
                            <img
                              onClick={async () => {
                                if (tempValue) {
                                  let temp = addMoreDocs
                                  temp[index]['doc_name'] = tempValue
                                  setAddMoreDocs([...temp])
                                  toggleDocNamePicker({ show: false, id: null })
                                }
                                else {
                                  toastDisplay('Please enter document name', "info")
                                }
                              }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                            <img
                              onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                          </>
                        ) :
                          (
                            <img
                              onClick={() => { setTempValue(item.doc_name); toggleDocNamePicker({ show: true, id: index }) }}
                              style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}

                      </span>
                      <div className='col-md-12'>
                        <FileInput name={`extraDocs_${item.doc_name}`} value={data[`extraDocs_${item.doc_name}`]} error={errors[`extraDocs_${item.doc_name}`]}
                          onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`extraDocs_${item.doc_name}`]: null })} />
                      </div>
                    </div>
                  })} */}


                  </div>
                  <div className='row mt-4'>
                    <div>
                      <label className='font-size-14 font-wt-600 '><span className='font-size-14 font-wt-600  text-decoration-underline'>Add buyer details</span> (Upload buyer details in excel format or fill the below table manually)</label>
                      <div className='col-md-6 my-2'>
                        <div className="col-md-12">
                          <FileInput name={"buyerDetails"} value={data.buyerDetails} error={errors.buyerDetails}
                            onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, "buyerDetails": null })} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className='font-size-14 font-wt-600 text-color1' onClick={() => ExportExcel(buyerExcelHeaders, "add_buyers_dummy")}>Download File Format</label>
                    </div>

                  </div>
                  <div className='mt-4'>
                    <ExpandableTable
                      filterData={[]}
                      setFilterData={[]}
                      filteredSearch={[]}
                      setFilteredSearch={[]}
                      tableFixed data={dbData}
                      columns={[
                        {
                          name: <img className='cursor' onClick={() => {
                            if (selectedIndex.length === dbData.length) {
                              setSelectedIndex([])
                            } else {
                              let selectedData = []
                              for (let i = 0; i <= dbData.length - 1; i++) {
                                selectedData.push(i)
                              }
                              setSelectedIndex(selectedData)
                            }

                          }} src={
                            `assets/images/${selectedIndex.length === dbData.length ? 'checked-green' : 'empty-check'
                            }.png`
                          } />, width: "2%"
                        },
                        { name: "Buyer Name", width: "10%" },
                        { name: "Amount(Cr)", width: "10%" },
                        { name: "Country", width: "8%" },
                        { name: "Projected T/O Fy 2023-24", width: "10%" },
                        { name: "Limit Required", width: "10%" },
                        { name: "Max outstanding for FY 2022-23", width: "10%" },
                        { name: "Credit days", width: "9%" },
                        { name: "Terms of payment", width: "10%" },
                        { name: "Address/ DUNS no.", width: "10%" },
                        { name: "", width: "2%" }
                      ]}
                      overalldata={buyersdata}
                      expand={[]}
                      tableExpand={[]}
                      expandKey={"buyerName"}
                      disableAction={false}
                    />
                    <div className='mt-1' >
                      <div className='d-flex flex-row gap-2 align-items-center cursor w-auto' onClick={() => setAddBuyerPopup({ show: true, isEdit: false })}>
                        <label className='text-color1 font-size-14 font-wt-600 mb-0'>Add Buyer</label>
                        <img src={"assets/images/right-arrow-icon.svg"} alt="Back" height={15} width={15} />
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <label className='font-size-14 font-wt-600 text-decoration-underline'>Documents</label>

                    <ExpandableTable
                      filterData={[]}
                      setFilterData={[]}
                      filteredSearch={[]}
                      setFilteredSearch={[]}
                      tableFixed data={docsTable}
                      columns={[
                        { name: "Buyer Name", width: "30%" },
                        { name: "Document Name", width: "30%" },
                        { name: "Attach Document", width: "40%" }
                      ]}
                      overalldata={buyersdata}
                      expand={[]}
                      tableExpand={[]}
                      expandKey={"buyerName"}
                      disableAction={false}
                    />
                  </div>
                </>}
                {data.applicationType === 'Letter Of Credit' && <>
                  <div className=' row mt-4'>

                    <label className='font-size-14 font-wt-600 text-decoration-underline'>Organizational documents</label>

                    <ExpandableTable
                      filterData={[]}
                      setFilterData={[]}
                      filteredSearch={[]}
                      setFilteredSearch={[]}
                      tableFixed data={lcOrgTbl}
                      columns={[
                        { name: "Service", width: "12%" },
                        { name: "LC Tenure", width: "12%" },
                        { name: "Issuing Country", width: "12%" },
                        { name: "Issuing Bank", width: "12%" },
                        { name: "SWIFT Code", width: "12%" },
                        { name: "Upload LC Draft", width: "40%" },
                      ]}
                      overalldata={[]}
                      expand={[]}
                      tableExpand={[]}
                      expandKey={"buyerName"}
                      disableAction={true}
                    />
                  </div>
                </>}
                {(financers && financers.length) ?
                  <div className='mt-4 '>
                    <label className='font-size-14 font-wt-600 '>Select Finacier</label>
                    <div className='row gap-2'>
                      {financers.map((item, index) => {
                        return <div className='col-md-1'>
                          <div className='d-flex flex-row gap-2 mt-2' key={index}>
                            <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                              const updatedFin = [...financers]; // create a new array
                              updatedFin[index] = {
                                ...updatedFin[index],
                                is_checked: !updatedFin[index].is_checked
                              };
                              setFinancers(updatedFin);
                            }} />
                            <label className='font-size-14 font-wt-600'>{item.name}</label>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>

                  : null
                }
                <div className="d-flex gap-4 mt-4">
                  <button className={`new-btn  py-2 px-5 text-white cursor`} onClick={() => {
                    if (data.applicationType === 'Factoring') {
                      createNewApplicaton()
                    } else if (data.applicationType === 'Letter Of Credit') {
                      handleLCValidations()
                    } else {
                      toastDisplay("Please Select Application Type", "info")
                    }
                  }}>Submit</button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplicationForm)