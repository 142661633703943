import React, { useEffect, useState } from 'react';
import { setaddBankObj } from '../../store/actions/action';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/bankdetailsValidationRules';

const AddBankComponent = ({ setaddBankObj, addBankObj, editBankDetails, seteditBankDetails, registrationData, dispatch }) => {

  const [bankData, setBankData] = useState({});
  const [bankMasters, setbankMasters] = useState([]);
  const [errors, setErrors] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    call('GET', 'getBanksListMaster').then((result) => {
      // console.log("result in getBanksListMaster api->", result)
      setbankMasters(result)
    }).catch((e) => {
      console.log("Error while querying getBanksListMaster:", e);
    })


    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editBankDetails) {
      setBankData({
        ...bankData,
        id: editBankDetails.id,
        correspondentBankName: editBankDetails.correspondentBankName || "",
        correspondentSwift: editBankDetails.correspondentSwift || "",
        correspondentAccountNumber: editBankDetails.correspondentAccountNumber || "",
        beneficiaryBankName: editBankDetails.beneficiaryBankName || "",
        beneficiaryBranch: editBankDetails.beneficiaryBranch || "",
        beneficiaryBranchAddress: editBankDetails.beneficiaryBranchAddress || "",
        beneficiaryCity: editBankDetails.beneficiaryCity || "",
        beneficiaryState: editBankDetails.beneficiaryState || "",
        beneficiaryCountry: editBankDetails.beneficiaryCountry || "",
        beneficiaryPostalCode: editBankDetails.beneficiaryPostalCode || "",
        beneficiaryAccountName: editBankDetails.beneficiaryAccountName || "",
        beneficiaryAccountNo: editBankDetails.beneficiaryAccountNo || "",
        beneficiaryIfscCode: editBankDetails.beneficiaryIfscCode || "",
        beneficiarySwiftCode: editBankDetails.beneficiarySwiftCode || ""
      })
    }
  }, [editBankDetails])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleBankDataSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errors]);

  const handleBankData = (event) => {
    event.persist();
    setBankData({ ...bankData, [event.target.name]: event.target.value });
    setErrors(errors => ({ ...errors, [event.target.name]: "" }))
  }

  const preHandleBankData = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(bankData));
    setIsSubmitting(true);
  }

  const handleBankDataSubmit = () => {
    setshowLoader(true)

    var formData = new FormData();
    Object.keys(bankData).forEach((key) => {
      formData.append(key, bankData[key]);
    })

    formData.append("email", addBankObj.info.email);

    if (editBankDetails) {
      handleBankDataUpdate()
      return
    }
    call('POST', 'addBank', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Bank details saved successfuly!", "success");
        setaddBankObj(false, { refresh: true });
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err, "error");
    })
  }

  const handleBankDataUpdate = () => {
    setshowLoader(true)
    call('POST', 'updateBank', bankData).then((result) => {
      setshowLoader(false)
      if (result) {
        toastDisplay(result, "success");
        setaddBankObj(false, { refresh: true });
        seteditBankDetails(null)
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err, "error");
    })
  }

  const dismissComponent = () => {
    setaddBankObj(false, {})
    if (seteditBankDetails) {
      seteditBankDetails(null)
    }
  }

  return (
    <div className="accordionWrapper row pr-0 pt-0">
      <div className="container-fluid accordionItem open">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h3 className="green mt-3">
                  {editBankDetails ? 'Edit Bank Details' : "Enter Bank Details"}
                  <button type="button" onClick={dismissComponent} className="close pt-0 mb-4" data-dismiss="modal" >
                    <i class="fas fa-times fa-sm text-danger "></i>
                  </button>
                </h3>
                <hr />
              </div>
              <div className="col-md-12">
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

                <div className="pr-4">
                  <h3 className="mt-3 text-center">{editBankDetails ? "Edit Correspondent Bank Details" : "Enter Correspondent Bank Details"}</h3>
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email ID</label>
                        <input type="text" className="form-control" name="bankEmail" value={addBankObj.info.email} onChange={handleBankData} readOnly />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Bank Name</label>
                        <input type="text" className="form-control" name="correspondentBankName" value={bankData.correspondentBankName} placeholder="Enter Correspondent Bank Name" onChange={handleBankData} />
                        {errors.correspondentBankName && <p className="text-danger error-contract">{errors.correspondentBankName}</p>}
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>Select Bank </label><span className="required-field text-danger">*</span>
                        <select className="form-control" name="bankMaster" value={bankData.bankMaster} onChange={handleBankData}>
                          <option value="" selected>--Select Bank--</option>
                          {bankMasters.map((bank) => {
                            return (
                              <option value={bank.id + ":" + bank.company_name} >{bank.company_name}</option>
                            )
                          })}
                        </select>
                        {errors.bankMaster && <p className="text-danger error-contract">{errors.bankMaster}</p>}
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Swift Code</label>
                        <input type="text" className="form-control" name="correspondentSwift" value={bankData.correspondentSwift} placeholder="Enter Correspondent Swift Code" onChange={handleBankData} />
                        {errors.correspondentSwift && <p className="text-danger error-contract">{errors.correspondentSwift}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Account No</label>
                        <input type="text" className="form-control" name="correspondentAccountNumber" value={bankData.correspondentAccountNumber} placeholder="Enter Correspondent Swift Code" onChange={handleBankData} />
                        {errors.correspondentAccountNumber && <p className="text-danger error-contract">{errors.correspondentAccountNumber}</p>}
                      </div>
                    </div>

                  </div>

                  <h3 className="mt-3 text-center">{editBankDetails ? "Edit Beneficiary Bank Details" : "Enter Beneficiary Bank Details"}</h3>
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Bank Name</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryBankName" value={bankData.beneficiaryBankName} placeholder="Enter Beneficiary Bank Name" onChange={handleBankData} />
                        {errors.beneficiaryBankName && <p className="text-danger error-contract">{errors.beneficiaryBankName}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryBranch" value={bankData.beneficiaryBranch} placeholder="Enter Beneficiary Bank Branch" onChange={handleBankData} />
                        {errors.beneficiaryBranch && <p className="text-danger error-contract">{errors.beneficiaryBranch}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Address</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryBranchAddress" value={bankData.beneficiaryBranchAddress} placeholder="Enter Beneficiary Branch Address" onChange={handleBankData} />
                        {errors.beneficiaryBranchAddress && <p className="text-danger error-contract">{errors.beneficiaryBranchAddress}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>City</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryCity" value={bankData.beneficiaryCity} placeholder="Enter Beneficiary Bank City" onChange={handleBankData} />
                        {errors.beneficiaryCity && <p className="text-danger error-contract">{errors.beneficiaryCity}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryState" value={bankData.beneficiaryState} placeholder="Enter Beneficiary Bank State" onChange={handleBankData} />
                        {errors.beneficiaryState && <p className="text-danger error-contract">{errors.beneficiaryState}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Country</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryCountry" value={bankData.beneficiaryCountry} placeholder="Enter Beneficiary Bank Country" onChange={handleBankData} />
                        {errors.beneficiaryCountry && <p className="text-danger error-contract">{errors.beneficiaryCountry}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Postal Code</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryPostalCode" value={bankData.beneficiaryPostalCode} placeholder="Enter Beneficiary Bank Postal Code" onChange={handleBankData} />
                        {errors.beneficiaryPostalCode && <p className="text-danger error-contract">{errors.beneficiaryPostalCode}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Account Name</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryAccountName" value={bankData.beneficiaryAccountName} placeholder="Enter Beneficiary Account Name" onChange={handleBankData} />
                        {errors.beneficiaryAccountName && <p className="text-danger error-contract">{errors.beneficiaryAccountName}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Account No</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryAccountNo" value={bankData.beneficiaryAccountNo} placeholder="Enter Beneficiary Account No" onChange={handleBankData} />
                        {errors.beneficiaryAccountNo && <p className="text-danger error-contract">{errors.beneficiaryAccountNo}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>IFSC Code</label>{astrix}
                        <input type="text" className="form-control" name="beneficiaryIfscCode" value={bankData.beneficiaryIfscCode} placeholder="Enter Beneficiary IFSC Code" onChange={handleBankData} />
                        {errors.beneficiaryIfscCode && <p className="text-danger error-contract">{errors.beneficiaryIfscCode}</p>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Swift Code</label>{astrix}
                        <input type="text" className="form-control" name="beneficiarySwiftCode" value={bankData.beneficiarySwiftCode} placeholder="Enter Beneficiary Swift Code" onChange={handleBankData} />
                        {errors.beneficiarySwiftCode && <p className="text-danger error-contract">{errors.beneficiarySwiftCode}</p>}
                      </div>
                    </div>

                  </div>

                  <div className="float-right">
                    <input type="button" className="btn btn-sm btn-danger m-2" value="Cancel" onClick={dismissComponent} />
                    <input type="button" className="btn btn-sm btn-success m-2" value={editBankDetails ? "Update" : "Save"} onClick={() => preHandleBankData()} />
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div >)
}

const mapStateToProps = state => {
  return {
    addBankObj: state.addBankObj,
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBankComponent)
