import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Header from '../partial/header';
import LaytimeSheetTrail from '../laytimeSheets/laytimeSheetTrail';
import ContractDoc from '../contractDocument/contractDoc';
import { setContractDetails, setContractDocList, setViewTrack, setInvoiceDetail } from '../../store/actions/action';
import Invoice from '../invoice/invoice';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import InvestigationReportForm from '../investigationReport/investigationReportForm'
import InsuranceDetailAccord from '../insurance/insuranceDetailAccord'
import { QRCode } from 'react-qrcode-logo';
import LCPaymentProcess from '../bank/bankLCProcess/lcProcess'
import DaDpPaymentProcess from '../bank/bankDaDpProcess/dadpProcess'
import TTPaymentProcess from '../bank/ttPaymentProcess/ttProcess'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import BillOfLading from '../logistics/billOfLading.js'
import avatarUrl from '../../utils/makeAvatarUrl';
import Tracking from '../contractDetails/tracking.js';
import ContractTrail from '../contractDetails/contractTrail.js';
import ShipmentDetailAccord from '../contractDetails/shipmentDetailAccord.js';
import BlockchainTrail from '../contractDetails/blockchainTrail.js';
import QmsWindow from '../contractDetails/qms.js';
import ContractLogs from '../contractDetails/contractLogs.js';
import HeaderV2 from '../partial/headerV2.js';
import SideBarV2 from '../partial/sideBarV2.js';
import { useHistory } from "react-router";
import WorkOrderContractDocuments from './WorkOrderContractDocuments.js';
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication.js';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2.js';
import { getDocDetails } from '../../utils/myFunctions.js';
import BankReimburseForm from '../bank/ttPaymentProcess/bankReimburseForm.js';
import { Accordion, Card, Button } from 'react-bootstrap'
// import { userPlanQuotaFetch } from '../../utils/myFunctions.js';

const WorkOrderContractDetaills = ({ userTokenDetails, contractDocList, setContractDocList, setViewTrack, ViewTrack, setInvoiceDetail, InvoiceDetail, clientType, navToggleState, dispatch }) => {

  const queryParams = new URLSearchParams(window.location.search)
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  let cDetailsState = {
    cId: queryParams.get("contractNo")
  }
  const cId = cDetailsState.cId
  const [dbData, setDbData] = useState({})
  const [planData, setplanData] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [showQms, setShowQms] = useState(false);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Summary");
  const [contractClientType, setContractClientType] = useState(null)
  const [contractDoc, toggleContractDoc] = useState(false)
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')
  const [toggle2, settoggle2] = useState(false)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [refresh, setRefresh] = useState(0)

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  let history = useHistory()
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    let cType = (userTypeId === 3 || (userTypeId === 19 && clientType.type === 'buyer')) ? "buyer" : (userTypeId === 4 || (userTypeId === 19 && clientType.type === 'seller')) ? "seller" : ""
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getcontractdetails', { "contract_number": cId, "clientType": cType }).then(async (result) => {
      console.log("getcontractdetails ->", result)
      setDbData(result[0])
      setshowLoader(false)
      setContractClientType(result[0]["importer_id"] / 1 == userId / 1 ? "buyer" : 'seller')
    }).catch((error) => {
      console.log("error occur in getContracts ->", error)
    })

    call('get', `v1/contract/plan?contractNumber=${cId}`).then(async (result) => {
      // console.log("getcontractplan ->", result)
      setplanData(result.planDetails.features)
      setshowLoader(false)
    }).catch((error) => {
      console.log("error occur in getcontractplan ->", error)
    })

    //------------------------------------------------------------------

  }, [InvoiceDetail.info.refresh, cDetailsState.cId]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  //---------------------------------------------------------------------------------------------------------------------

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('contractApplicationId', selectedChat.contractApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  console.log("getcontractplan planData->", planData)
  // console.log('contractDocList.contractId', contractDocList.contractId);
  // console.log('ViewTrack.modal', ViewTrack.modal);
  // console.log('InvoiceDetail.modal', InvoiceDetail.modal);
  //console.log('InvoiceDetail', InvoiceDetail);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return


  return (
    <>
      {contractDoc ?
        <div className="container-fluid">
          <div className="row">
            <SideBarV2 state="contractManagement" userTokenDetails={userTokenDetails} />
            <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
              <HeaderV2
                title={userTypeId === 8 ? 'Workorder Contracts > Case Details' : 'Workorder Contracts > Contract Details'}
                userTokenDetails={userTokenDetails}
              />
              <div className='py-4 px-3 card mt-5'>
                <a style={{ top: '-2.8rem', zIndex: 10, left: 0 }} className="cursor position-absolute mb-3"
                  onClick={() => {
                    toggleContractDoc(false)
                    setContractDocList(null)
                  }}
                > <img className="" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <ExtraDetailsForLimitApplication
                  showContractPO
                  showContractInvoice
                  showContractAgreement
                  showShipmentDocs
                  contractClientType={contractClientType} userTokenDetails={userTokenDetails}
                  hideHeader={true} hideSideBar={true}
                  hideSubmitDiv={true} hideBackArrow={true}
                  expandAll={true}
                  cardBorder0={true} cardPadding0={true}
                  showContinueButton={true} continueBtnLabel={"Save"}
                  loadDataInWriteModeFromCacheIfPresent={true}
                // continueBtnCSS={{ opacity: 0, height: 0 }}
                />
              </div>
            </main>
          </div >
        </div >
        // <WorkOrderContractDocuments contractClientType={contractClientType} userTokenDetails={userTokenDetails} planData={planData} /> 
        :
        (!contractDoc && ViewTrack.modal && !InvoiceDetail.modal) ? <Tracking userTokenDetails={userTokenDetails} /> :
          (!contractDoc && !ViewTrack.modal && InvoiceDetail.modal) ? <Invoice navToggleState={navToggleState} userTokenDetails={userTokenDetails} planData={planData} /> :
            <div className="container-fluid">
              <div className="row">
                <SideBarV2 state="workOrderContracts" userTokenDetails={userTokenDetails} />
                <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
                  <HeaderV2
                    title={userTypeId === 8 ? 'Workorder Contracts > Case Details' : 'Workorder Contracts > Contract Details'}
                    userTokenDetails={userTokenDetails}
                  />
                  <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />


                  <div className="d-md-flex">
                    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                    <ul className="contract-left position-relative">
                      <a style={{ top: '1.2rem' }} className="cursor position-absolute"
                        onClick={() => { history.go(-1) }}
                      ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                      <li className='pl-4'>
                        <p className='font-size-15 font-wt-500 text-color-0C0C0C'>Contract #{dbData.contract_number ? dbData.contract_number + " : " + tabName : '--'}</p>
                        <p className='font-size-13 font-wt-400'>{dbData.created_at ? formatDate_Application(dbData.created_at) : '--'}</p>
                      </li>
                    </ul>
                    <div className="ml-auto pr-3">
                      <ul className="contract-top d-flex flex-md-row align-items-center">

                        {/* {(aclMap.contract_access && aclMap.contract_access.cont_viewDoc && aclMap.contract_access.cont_viewDoc.isExist) && */}
                        <li>
                          <button type="button" className="btn bg-1ea3ae text-white btn-sm " onClick={() => {
                            setContractDocList(dbData.contract_number);
                            toggleContractDoc(true)
                            setViewTrack(false, {});
                          }}>Contract Documents</button>
                        </li>
                        {/* } */}

                        {<>
                          <DropdownButton id="dropdownInvoice" title="Invoice" variant="" size="sm">
                            {(dbData.invoice_status === 0 && dbData.exporter_id === userId) ? <>
                              {
                                <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                  setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "Generate" })
                                }}>
                                  Generate Invoice
                                </Dropdown.Item>}
                              {
                                <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                  setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "Upload" })
                                }}>
                                  Upload Invoice
                                </Dropdown.Item>}
                            </> :
                              (dbData.invoice_status === 1 && dbData.exporter_id === userId) ?
                                <>
                                  {
                                    <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                      setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "ReGenerate" })
                                    }}>
                                      Re-Generate Invoice
                                    </Dropdown.Item>}
                                </> : (dbData.invoice_status === 0 && dbData.importer_id === userId) ?
                                  <Dropdown.Item>NA</Dropdown.Item> : ""}
                            {dbData.invoice_status === 1 &&
                              <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "View" })
                              }}>
                                View Invoice
                              </Dropdown.Item>}
                          </DropdownButton>
                        </>}

                      </ul>
                    </div>
                  </div>

                  <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                    <li>
                      <a
                        style={{ width: '9.4rem' }}
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === 0 ? " formActiveTab show " : "")} onClick={() => {
                          setTab(0);
                          setTabName("Summary");
                        }}>Summary</a>
                    </li>
                    {
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 1 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(1);
                            setTabName("Bank Process");
                          }}>Payment Details</a>
                      </li>}
                    {/* {(aclMap.contract_access && aclMap.contract_access.cont_invReport && aclMap.contract_access.cont_invReport.isExist) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 2 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(2);
                            setTabName("Investigation Process");
                          }}>Investigation</a>
                      </li>} */}
                    {/* {(aclMap.contract_access && aclMap.contract_access.cont_insuranceDetails && aclMap.contract_access.cont_insuranceDetails.isExist) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 3 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(3);
                            setTabName("Insurance Process");
                          }}>Insurance </a>
                      </li>} */}
                    {/* {(aclMap.contract_access && aclMap.contract_access.cont_shipmentDetails && aclMap.contract_access.cont_shipmentDetails.isExist) && (planData.shipmentTracking && planData.shipmentTracking.enable) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 4 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(4);
                            setTabName("Shipment Details");
                          }}>Shipment</a>
                      </li>} */}

                    {/* {(aclMap.contract_access && (aclMap.contract_access && aclMap.contract_access.cont_blprocess && aclMap.contract_access.cont_blprocess.isExist)) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 5 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(5);
                            setTabName("Bill Of Lading");
                          }}>B/L Process</a>
                      </li>} */}

                    {/* {(aclMap.contract_access && aclMap.contract_access.cont_bcAudit && aclMap.contract_access.cont_bcAudit.isExist) && */}
                    <li>
                      <a
                        style={{ width: '10rem' }}
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === 6 ? " formActiveTab show" : "")} onClick={() => {
                          setTab(6);
                          setTabName("Blockchain Audit");
                        }}>Blockchain Audit</a>
                    </li>

                    {/* {(aclMap.contract_access && aclMap.contract_access.cont_auditTrail && aclMap.contract_access.cont_auditTrail.isExist) && */}
                    <li>
                      <a
                        style={{ width: '9.4rem' }}
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === 7 ? " formActiveTab show" : "")} onClick={() => {
                          setTab(7);
                          setTabName("Contract Logs");
                        }}>Process Cycle</a>
                    </li>
                    <li>
                      <a
                        style={{ width: '9.4rem' }}
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === 8 ? " formActiveTab show" : "")} onClick={() => {
                          setTab(8);
                          setTabName("Change logs");
                        }}>Change logs</a>
                    </li>
                    {/* } */}
                  </ul>

                  <div className="card mt-1 p-3"
                  >
                    {tab === 0 &&
                      <>
                        <div className="tab-pane active show ">
                          {/* <div className="col-md-12"> */}

                          <div className="row">
                            <div className="col-md-10">
                              <div className="row mt-2">

                                <div className="col-md-3 ">
                                  <div className="form-group">
                                    <label className="col-md-12 font-size-14 font-wt-500">Importer</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(dbData.buyeravatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="text-color-value font-size-14 font-wt-400">  {dbData.buyername ? dbData.buyername : '--'}</span><br />
                                      </div>
                                      <p className="date-format p-0 m-0">{dbData.buyerAddress ? dbData.buyerAddress : '--'}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 ">
                                  <div className="form-group">
                                    <label className="col-md-12 font-size-14 font-wt-500">Exporter</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(dbData.supplieravatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="text-color-value font-size-14 font-wt-400">  {dbData.suppliername ? dbData.suppliername : '--'}</span><br />
                                      </div>
                                      <p className="date-format p-0 m-0">{dbData.supplierAddress ? dbData.supplierAddress : '--'}</p>
                                    </div>
                                  </div>
                                </div>
                                {/* {(userTypeId === 3 || (userTypeId === 19 && clientType.type === 'buyer')) ?
                                  <div className="col-md-3 ">
                                    <div className="form-group">
                                      <label className="col-md-12 font-size-14 font-wt-500">Investigation Agency</label>
                                      <div className="chat-message pl-2">
                                        <img src={avatarUrl(dbData.agencyavatarImporter)} alt="Avatar" width="32" height="32" />
                                        <div className="chat-message-content clearfix pt-2">
                                          <span className="text-primary">  {dbData.agencynameImporter ? dbData.agencynameImporter : '--'}</span><br />
                                        </div>
                                        <p className="date-format p-0 m-0">{dbData.agencyImporterAddress ? dbData.agencyImporterAddress : '--'}</p>
                                      </div>
                                    </div>
                                  </div> :
                                  (userTypeId === 4 || (userTypeId === 19 && clientType.type === 'seller')) ?
                                    <div className="col-md-3 ">
                                      <div className="form-group">
                                        <label className="col-md-12 font-size-14 font-wt-500">Investigation Agency</label>
                                        <div className="chat-message pl-2">
                                          <img src={avatarUrl(dbData.agencyavatarExporter)} alt="Avatar" width="32" height="32" />
                                          <div className="chat-message-content clearfix pt-2">
                                            <span className="text-primary">  {dbData.agencynameExporter ? dbData.agencynameExporter : '--'}</span><br />
                                          </div>
                                          <p className="date-format p-0 m-0">{dbData.agencyExporterAddress ? dbData.agencyExporterAddress : '--'}</p>
                                        </div>
                                      </div>
                                    </div> : ""} */}
                                {/* <div className="col-md-3 ">
                                  <div className="form-group">
                                    <label className="col-md-12 font-size-14 font-wt-500">Ship Owner</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(dbData.shipowneravatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="text-primary">  {dbData && dbData.shipOwner ? dbData.shipOwner : '--'}</span><br />
                                      </div>
                                      <p className="date-format p-0 m-0">{dbData.shipOwnerAddress ? dbData.shipOwnerAddress : '--'}</p>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-md-2 d-flex justify-content-end">
                              <div className="">
                                <ul>
                                  <li>
                                    <QRCode value={dbData.address ? dbData.address : cId} enableCORS={true} fgColor={'#17a2b8'} qrStyle={'dots'} />
                                    {/* <p>{dbData.address ? dbData.address + "\n\rSUmmer" : 'Not Available'}</p> */}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}


                          <div className="col-md-12 mt-1">
                            {/* <div className="col-md-10"> */}
                            <div className="d-md-flex align-content-around flex-wrap mb-3 contract-details">

                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>{dbData.commodity_name ? dbData.commodity_name.toUpperCase() : ""}</h3>
                                    <p>Commodity</p>
                                  </li>
                                </ul>
                              </div>
                              {(userTypeId === 19 || userTypeId === 3 || userTypeId === 4) &&
                                <>
                                  <div className="card-panel">
                                    <ul>
                                      <li>
                                        <h3>{dbData.currency ? dbData.currency.split(':')[1] : ''} {dbData.price ? dbData.price : '--'} </h3>
                                        <p>Price</p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="card-panel">
                                    <ul>
                                      <li>
                                        <h3>{dbData.quantity ? dbData.quantity : ''} {dbData.commodityUnit}</h3>
                                        <p>Quantity</p>
                                      </li>
                                    </ul>
                                  </div>
                                </>}
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>
                                      {dbData.load_country ? <span className="shadow">
                                        <ReactCountryFlag
                                          countryCode={dbData.load_country.split(':')[0]}
                                          style={{ width: '25px', height: '25px' }} svg />
                                      </span> : ''}
                                    </h3>
                                    <p>Loading Country</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>{dbData.loading_port ? dbData.loading_port : '--'} </h3>
                                    <p>loading Port</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>
                                      {dbData.unload_country ? <span className="shadow">
                                        <ReactCountryFlag
                                          countryCode={dbData.unload_country.split(':')[0]}
                                          style={{ width: '25px', height: '25px' }} svg />
                                      </span> : ''}
                                    </h3>
                                    <p>Unloading Country</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3> {dbData.unloading_port ? dbData.unloading_port : "-"} </h3>
                                    <p>Unoading Port</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>{dbData.laycan_start ? formatDate_Application(dbData.laycan_start) : '--'}</h3>
                                    <p>Laycan Start</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>{dbData.laycan_end ? formatDate_Application(dbData.laycan_end) : '--'} </h3>
                                    <p>Laycan End</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* </div> */}

                          </div>
                          <hr />

                          {/* {(aclMap.contract_access && aclMap.contract_access.cont_changelog && aclMap.contract_access.cont_changelog.isExist) && */}

                        </div>
                      </>
                    }
                    {tab === 1 &&
                      <div className="tab-pane active show  col-md-12">
                        {/* {<>
                          {dbData.payment_mode / 1 === 1 ?
                            <LCPaymentProcess
                              userTokenDetails={userTokenDetails}
                              contractNo={cId}
                              contractData={dbData} />
                            : dbData.payment_mode / 1 === 2 ?
                              <DaDpPaymentProcess
                                userTokenDetails={userTokenDetails}
                                contractNo={cId}
                                contractData={dbData}
                                dadpType={"DP"} />
                              : dbData.payment_mode / 1 === 3 ?
                                <DaDpPaymentProcess
                                  userTokenDetails={userTokenDetails}
                                  contractNo={cId}
                                  contractData={dbData}
                                  dadpType={"DA"} />
                                : dbData.payment_mode / 1 === 4 ?
                                  <TTPaymentProcess
                                    userTokenDetails={userTokenDetails}
                                    contractNo={cId}
                                    contractData={dbData} />
                                  : "Current Payment Mode is Under Development"}
                        </>
                        } */}
                        <div className=''>
                          <BankReimburseForm
                            userTokenDetails={userTokenDetails}
                            contractData={{
                              reimburse_data: dbData.reimburse_data,
                              id: dbData.contract_id,
                              contractNo: dbData.contract_number,
                              exporter_id: dbData.exporter_id,
                              importer_id: dbData.importer_id,
                              buyer_bank: dbData.buyer_bank,
                              supplier_bank: dbData.supplier_bank,
                              paymentType: "LC",
                              currency: dbData?.currency?.split(':')?.[2]
                            }}
                            setshowLoader={setshowLoader}
                            refresh={refresh}
                            setrefresh={setRefresh}
                          />
                          {/* <Accordion>
                            <Card>
                              <Card.Header>
                                <div className="col-md-12 row">
                                  <div className="col-md-11 row">
                                    <h3 className="text-color1 mt-2 font-size-16 font-wt-500">Provide LC Reimbursement Details</h3>
                                  </div>
                                  <div className="col-md-1">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                      <span className="cursor-pointer ml-5"
                                        onClick={() => settoggle2(!toggle2)}
                                      >
                                        {!toggle2 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                      </span>
                                    </Accordion.Toggle>
                                  </div>
                                </div>
                              </Card.Header>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <BankReimburseForm
                                    userTokenDetails={userTokenDetails}
                                    contractData={{
                                      reimburse_data: dbData.reimburse_data,
                                      id: dbData.contract_id,
                                      contractNo: dbData.contract_number,
                                      exporter_id: dbData.exporter_id,
                                      importer_id: dbData.importer_id,
                                      buyer_bank: dbData.buyer_bank,
                                      supplier_bank: dbData.supplier_bank,
                                      paymentType: "LC"
                                    }}
                                    setshowLoader={setshowLoader}
                                    refresh={refresh}
                                    setrefresh={setRefresh}
                                  />
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion> */}
                        </div>
                      </div>
                    }
                    {tab === 2 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_invReport && aclMap.contract_access.cont_invReport.isExist) &&
                          <InvestigationReportForm
                            userTokenDetails={userTokenDetails}
                            contractNo={cId}
                            contractData={{
                              contractNo: cId,
                              contractId: dbData.contract_id,
                              inv_agency_importer: dbData.inv_agency_importer,
                              inv_agency_exporter: dbData.inv_agency_exporter,
                              inv_agency_third: dbData.inv_agency_third,
                              importer_id: dbData.importer_id,
                              exporter_id: dbData.exporter_id,
                            }} />}
                      </div>
                    }
                    {tab === 3 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_insuranceDetails && aclMap.contract_access.cont_insuranceDetails.isExist) &&
                          <InsuranceDetailAccord
                            userTokenDetails={userTokenDetails}
                            contractDetails={{
                              contractNo: cId,
                              contractId: dbData.contract_id,
                              commodity_insurer: dbData.commodity_insurer,
                              trade_insurer: dbData.trade_insurer,
                              importer_id: dbData.importer_id,
                              exporter_id: dbData.exporter_id,
                            }}
                            contractType={"commodity"} />}
                      </div>
                    }
                    {tab === 4 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_shipmentDetails && aclMap.contract_access.cont_shipmentDetails.isExist) &&
                          <>
                            <ShipmentDetailAccord
                              userTokenDetails={userTokenDetails}
                              contractNo={cId}
                              shipContractNo={null} />
                            <hr />
                            <LaytimeSheetTrail
                              userTokenDetails={userTokenDetails}
                              laytimeType={1}
                              contractNo={cDetailsState.cId}
                              contractType={'master'} />
                            <hr />
                            <LaytimeSheetTrail
                              userTokenDetails={userTokenDetails}
                              laytimeType={1}
                              contractNo={cDetailsState.cId}
                              contractType={'master'} />
                          </>}
                      </div>
                    }
                    {tab === 5 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_blprocess && aclMap.contract_access.cont_blprocess.isExist) &&
                          <BillOfLading
                            userTokenDetails={userTokenDetails}
                            contractNo={cId}
                            shipContractNo={null} />}
                      </div>}

                    {tab === 6 &&
                      <div className="tab-pane active show col-md-12">
                        {/* {(aclMap.contract_access && aclMap.contract_access.cont_bcAudit && aclMap.contract_access.cont_bcAudit.isExist) && */}
                        <BlockchainTrail
                          userTokenDetails={userTokenDetails}
                          contractNo={cId} />
                        {/* } */}
                      </div>}

                    {tab === 7 &&
                      <div className="tab-pane active show col-md-12">
                        {/* {(aclMap.contract_access && aclMap.contract_access.cont_auditTrail && aclMap.contract_access.cont_auditTrail.isExist) && */}
                        <ContractLogs itemData={dbData}
                          userTokenDetails={userTokenDetails}
                          contractNo={cId} />
                        {/* } */}
                      </div>}
                    {tab === 8 &&
                      <div className="col-md-12">
                        <ContractTrail
                          userTokenDetails={userTokenDetails}
                          contractNo={cId} modal={false} />

                      </div>
                    }

                  </div>


                  {/* QMS code start */}
                  <>
                    <div className="chat-button" onClick={async () => {
                      let oppositeParty = dbData.exporter_id / 1 == userId / 1 ? dbData.importer_id : dbData.exporter_id
                      let reqObj = {
                        senderId: userId,
                        receiverId: oppositeParty,
                        textMessage: 'Hii',
                        chat_room_name: "CHAT" + new Date().getTime(),
                        includeAdmins: true,
                        contractApplicationId: dbData.contract_id,
                        receiverParties: oppositeParty,
                        dontSendInitialMsg: true
                      }
                      console.log("reqObjjjjjjjjjjjjjjj", reqObj);
                      setshowLoader(true)
                      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                      setshowLoader(false)
                      getChatDetails({
                        chat_room_id: apiResp.id,
                        loggedInUser: userId
                      })
                      setSelectedChat({
                        chatRoomId: apiResp.id,
                        receiverName: `${cId}`,
                        contractApplicationId: dbData.contract_id,
                        parties: apiResp.parties,
                        userId: userId,
                        isChatOpen: true,
                        receiverId: oppositeParty
                      })
                    }}>
                      <i className="fa fa-comments-o" aria-hidden="true"></i>
                      {/* <img src='assets/images/supplier-images/chat.png' /> */}
                    </div>
                    <div className="circle-rippl"></div>
                    {selectedChat.isChatOpen &&
                      <>
                        <div className="chatboxDivFixed">
                          <ChatBoxPopUp2
                            clientType={dbData.importer_id / 1 == userId / 1 ? "buyer" : "seller"}
                            forContract={true}
                            chatList={chatList}
                            user_avatar={selectedChat.logo}
                            userId={selectedChat.userId}
                            receiverName={selectedChat.receiverName}
                            parties={selectedChat.parties}
                            userTokenDetails={userTokenDetails}
                            onChatSend={(file, parties) => sendChatMsg(file, parties)}
                            message={message}
                            setTextMsg={setTextMsg}
                            reloadChatList={() => getChatDetails({
                              chat_room_id: selectedChat.chatRoomId,
                              loggedInUser: userId
                            })}
                            onPopupClose={() => {
                              setSelectedChat({
                                receiverName: '',
                                receiverId: '',
                                userId: userId,
                                isChatOpen: false,
                                logo: ''
                              })
                              setRefresh(refresh + 1)
                            }}
                          />
                        </div>

                      </>
                    }
                  </>
                </main>
              </div>
            </div>}
      {/* QMS code end */}
    </>
  )
};

// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractDocList: state.contractDocList,
    ViewTrack: state.ViewTrack,
    InvoiceDetail: state.InvoiceDetail,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: false })) },
    setContractDocList: (id) => { dispatch(setContractDocList({ modal: true, contractId: id })) },
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) },
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderContractDetaills)