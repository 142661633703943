import React, { useState } from 'react'
import { useEffect } from 'react'

const SuccessPayment = () => {
  const queryParams = new URLSearchParams(window.location.search)
  let forShipping = queryParams.get('forShipping')
  let forGeneric = queryParams.get('forGeneric')
  let [secondsLeft, setSecondsLeft] = useState(10)

  useEffect(() => {
    if (forGeneric) {
      setTimeout(() => {
        setSecondsLeft(s => s - 1)
      }, [1000])
    }
  }, [secondsLeft])

  useEffect(() => {
    if (forGeneric) {
      setTimeout(() => {
        localStorage.setItem('paymentStatus', 'success');
        window.close()
      }, 10000);
    }
    else {
      setTimeout(() => {
        if (forShipping) {
          window.location = '/ShipmentBookingQuotes'
        }
        else {
          window.location = '/wallet'
        }
      }, 1000);
    }
  }, [])
  return (
    <div>
      <div className='d-flex justify-content-center mt-5 mb-4' >
        <img style={{ width: '3rem', height: '3rem' }} src='assets/images/green_tick.png' />
      </div>
      <h1 className='text-center'>Payment Successful</h1>
      {forGeneric ?
        <>
          <label className='w-100 text-center font-size-13 font-wt-500' >{`Redirecting back in ${secondsLeft} seconds...`}</label>
          <label onClick={() => {
            localStorage.setItem('paymentStatus', 'success');
            window.close()
          }} className='w-100 text-center font-size-13 font-wt-500 cursor' >{`If you wish to skip this step, click here`}</label>
        </>
        : null}</div>
  )
}

export default SuccessPayment