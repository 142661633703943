import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import SmallCard from "./Cards/headerCard";
import { getUserDataFromCookie } from '../../utils/cookieHelper'
import GraphsCard from "./Cards/graphsCard";
import TrendView from "./Cards/trendView/trendView"
import call from '../../service'
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import DropdownSearch from "./Cards/dropdownWithSearch";
import BoostView from "./Cards/boostView/boostView";
import DayView from "./Cards/dayView/dayView";
import GPView from "./Cards/gpView/gpView";
import ItemView from "./Cards/itemView/itemView";
import View360 from "./Cards/360View/360view";
import ObjectiveView from "./Cards/Objective View/objectiveView";
import PaymentView from './Cards/paymentView/paymentView'
const TallyDashboard = ({ navToggleState }) => {
  let userTokenDetails = getUserDataFromCookie()
  const [allVouchersData, setAllVouchersData] = useState([])
  const [allData, setAllData] = useState([])
  const [commodityNames, setCommodityNames] = useState([])
  const [itemsData, setItemsData] = useState([])
  const [tab, setTab] = useState("Snap Zone")
  const [selectedPeriodAmount, setSelectedPeriodAmount] = useState(0)
  const [averageAmount, setAverageAmount] = useState("")
  const [duration, setDuration] = useState("This Year")
  const [activeButton, setActiveButton] = useState('sales');
  const [stateData, setStateData] = useState([])
  const [continentData, setContinentData] = useState([])
  const [customerGroupData, setCustomerGroupData] = useState([]);
  const [itemGroupData, setItemGroupData] = useState([]);
  const [itemCategoryData, setItemCategoryData] = useState([])
  //  SCROLLING WITH < AND >
  const navTabsRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);



  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("tallyReportsDashboard");


  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    setDuration("This Year")
  };
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const aggregateDataByProperty = (data, property) => {
    const result = data.reduce((acc, curr) => {
      const key = curr[property];
      if (!acc[key]) {
        acc[key] = {
          [property]: key,
          totalAmount: 0,
          quantity: 0,
          taxPaid: 0,
          taxLess: 0,
          ASPTaxPaid: 0,
          ASPTaxLess: 0,
        };
      }
      acc[key].totalAmount += curr.totalAmount || 0;
      acc[key].quantity += curr.quantity || 0;
      acc[key].taxPaid += curr.taxPaid || 0;
      acc[key].taxLess += curr.taxLess || 0;
      acc[key].ASPTaxPaid += curr.ASPTaxPaid || 0;
      acc[key].ASPTaxLess += curr.ASPTaxLess || 0;

      return acc;
    }, {});

    return Object.values(result);
  };
  const aggregateDataForAll = () => {
    setStateData(aggregateDataByProperty(itemsData, 'state'));
    setContinentData(aggregateDataByProperty(itemsData, 'continent'));
    setCustomerGroupData(aggregateDataByProperty(itemsData, 'customerGroup'));
    setItemGroupData(aggregateDataByProperty(itemsData, 'itemGroup'));
    setItemCategoryData(aggregateDataByProperty(itemsData, 'category'));

  }
  useEffect(() => {
    let objectAPI = {
      "userId": userId,
      "type": `${activeButton}`,
      "dataFor": "dashboard"
    }
    call('POST', 'gettallyreportsdata', objectAPI)
      .then((result) => {
        setAllData(result.allData)
        setItemsData(result.allData)
        console.log("all Data in Main:", result.allData)
        setCommodityNames(result.commodityNames)
        // setContinentData(result.continentData)

      }).catch((e) => {
        console.log('error in getTransactionVoucher', e)
      })

  }, [activeButton])
  useEffect(() => {
    let objectAPI = {
      "userId": userId,
    };
    call('POST', 'gettallyreportsdata', objectAPI)
      .then((result) => {
        console.log(result.allData, "here dash")
        setAllVouchersData(result.allData);
      }).catch((e) => {
        console.log('error in getTransactionVoucher', e);
      });
  }, []);
  useEffect(() => {
    const total = calculateContribution(allData)
    setSelectedPeriodAmount(total)
    setAverageAmount(Math.floor(total / 12) + "$ " + "/Month")

    // state data
    aggregateDataForAll();
  }, [allData])
  useEffect(() => {
    aggregateDataForAll()
  }, [itemsData])
  const headerItems = [{
    name: "Selected Period",
    number: `${selectedPeriodAmount} $`,
    src: "assets/images/sidebarV2/reports_icon.svg"
  },
  // {
  //   name: "Stock",
  //   number: '7,90,500',
  //   src: "assets/images/sidebarV2/reports_icon.svg"
  // },
  // {
  //   name: "Receivable",
  //   number: '7,90,500',
  //   src: "assets/images/sidebarV2/reports_icon.svg"
  // },
  // {
  //   name: "Payable",
  //   number: '7,90,500',
  //   src: "assets/images/sidebarV2/reports_icon.svg"
  // },
  {
    name: "Average",
    number: `${averageAmount}`,
    src: "assets/images/sidebarV2/reports_icon.svg"
  },
  ]
  function calculateContribution(data) {
    const filteredData = data.filter(Boolean);
    const totalAmount = filteredData.reduce((acc, item) => acc + item.totalAmount, 0);
    // console.log("calculateContribution:",totalAmount)
    return totalAmount
  }
  const dropdownForSelectPeriod = ["This Week", "This Month", "This Year", "This Quater"]
  const handleDurationChange = (selectedDuration) => {
    setDuration(selectedDuration)
    // console.log(selectedDuration)
    if (selectedDuration !== "Custom") {
      let avg_divid = 0
      let text;
      const currentDate = new Date();
      let startDate, endDate;
      switch (selectedDuration) {
        case "This Week":
          avg_divid = 7
          text = "/ Day"
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
          endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (6 - currentDate.getDay()));
          break;

        case "This Month":
          avg_divid = 30
          text = "/ Day"
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          break;
        case "This Year":
          text = "/ Month"
          avg_divid = 12
          startDate = new Date(currentDate.getFullYear(), 0, 1);
          endDate = new Date(currentDate.getFullYear(), 11, 31);
          break;
        case "This Quater":
          text = "/ Month"
          avg_divid = 4
          const quarter = Math.floor((currentDate.getMonth() / 3));
          startDate = new Date(currentDate.getFullYear(), quarter * 3, 1);
          endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
          break;
        default:
          break;
      }

      // Filter itemsData based on date range
      if (allData && allData.length) {
        const filteredItemsData = allData.filter(item => {
          const itemDate = new Date(item.date);
          return itemDate >= startDate && itemDate <= endDate;
        });
        setItemsData(filteredItemsData)
        const total = calculateContribution(filteredItemsData)
        setSelectedPeriodAmount(total)
        setAverageAmount(Math.floor(total / avg_divid) + "$ " + text)
        aggregateDataForAll();

      }

    }
  }
  const graphConfigs = [
    { defaultData: "Item Name", defaultGraph: "Table View" },
    { defaultData: "State", defaultGraph: "Area Chart" },
    { defaultData: "Customer Name", defaultGraph: "Bar Chart" },
    { defaultData: "Refference Number", defaultGraph: "Pie Chart" },
  ];

  //  SCROLLING WITH < AND >

  const checkScrollButtons = () => {
    if (navTabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navTabsRef.current;
      setShowLeftButton(scrollLeft > 0);
      // setShowRightButton(scrollLeft + clientWidth < scrollWidth);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth - 1);
    }
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, []);

  const scrollLeft = () => {
    if (navTabsRef.current) {
      navTabsRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (navTabsRef.current) {
      navTabsRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (navTabsRef.current) {
      navTabsRef.current.addEventListener('scroll', checkScrollButtons);
    }
    return () => {
      if (navTabsRef.current) {
        navTabsRef.current.removeEventListener('scroll', checkScrollButtons);
      }
    };
  }, []);

  return (
    <>

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyReportsDashboard" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Reports"}
              userTokenDetails={userTokenDetails} />
            <div>
            </div>
            <nav className="position-relative">
              {showLeftButton && (
                <button
                  className='p-0 m-0 fw-bold'
                  onClick={scrollLeft}
                  style={{
                    width: "30px", height: "30px",
                    fontSize: '20px', fontFamily: 'sans-serif', color: 'white', backgroundColor: '#858c8c',
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderRadius: '50%',
                    zIndex: 1,
                    // background: 'white',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                  }}
                >
                  &lt;
                </button>
              )}
              <div className="nav nav-tabs signdoctabs gap-4 border-0 w-100 d-flex flex-nowrap" id="signdoctabs" role="tablist" ref={navTabsRef} style={{ overflowX: 'hidden', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                {["Snap Zone", "Trend Monitor", "Uplift Insights", "Day Summary", "Profit Explorer", "Product Summary ", "Pan View", "Objective View", "Payment View"].map(tabName => (
                  <button
                    key={tabName}
                    className={`nav-link bg-transparent ${tab === tabName && 'active'} paymenttab`}
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    style={{ width: "fit-content" }}
                    aria-controls={`nav-${tabName.toLowerCase().replace(" ", "-")}`}
                    aria-selected={tab === tabName}
                    onClick={() => setTab(tabName)}
                  >
                    {tabName}
                  </button>
                ))}

              </div>
              {showRightButton && (
                <button
                  onClick={scrollRight}
                  className='p-0 m-0 fw-bold'

                  style={{
                    width: "30px", height: "30px",
                    fontSize: '20px', fontFamily: 'sans-serif', color: 'white', backgroundColor: '#858c8c',
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderRadius: '50%',
                    zIndex: 1,
                    // background: 'white',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                  }}
                >
                  &gt;
                </button>
              )}
            </nav>

            <div className="tab-content d-block" id="nav-tabContent" >
              {tab === "Snap Zone" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <div className="container" >
                  <div className='d-flex position-relative mt-4 mb-0 justify-content-start flex-wrap gap-0'>
                    {
                      headerItems.map((item, index) => {
                        return <SmallCard title={item.name} number={item.number} key={index} src={item.src} />
                      })
                    }
                    <div style={{ height: "80px", right: "0" }} className=" bg-white border rounded shadow p-2 position-absolute d-flex">
                      <div>
                        <h6>Select Duration:</h6>
                        <DropdownSearch items={dropdownForSelectPeriod} placeholder={"Select or Search"} onSelect={handleDurationChange} defaultItem={duration} />
                      </div>
                      <button style={{ width: "100px" }}
                        className={`btn ${activeButton === 'sales' ? 'btn-dark' : 'btn-light'} me-1`}
                        onClick={() => handleButtonClick('sales')}
                      >
                        Sales
                      </button>
                      <button style={{ width: "100px" }}
                        className={`btn ${activeButton === 'purchase' ? 'btn-dark' : 'btn-light'}`}
                        onClick={() => handleButtonClick('purchase')}
                      >
                        Purchase
                      </button>
                    </div>

                  </div>

                  <div className="row mt-2">
                    {graphConfigs.map((config, index) => (
                      <GraphsCard
                        key={index}
                        itemsData={itemsData}
                        stateData={stateData}
                        continentData={continentData}
                        customerGroupData={customerGroupData}
                        itemGroupData={itemGroupData}
                        itemCategoryData={itemCategoryData}
                        defaultData={config.defaultData}
                        defaultGraph={config.defaultGraph}
                      />
                    ))}
                  </div>
                </div>
              </div>
              }
              {tab === "Trend Monitor" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <div className="container" >
                  <div className='d-flex mt-5 mb-0 justify-content-between flex-wrap gap-0 w-100'>
                    <TrendView data={allVouchersData} />
                  </div>

                </div>
              </div>
              }
              {tab === "Uplift Insights" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <div className="container mt-4" >
                  <BoostView allData={allVouchersData} commodityNames={commodityNames} />
                </div>
              </div>
              }
              {tab === "Day Summary" && (
                <div className="tab-pane fade show active bg-transparent" role="tabpanel">
                  <div className="container mt-4">
                    <DayView allData={allVouchersData} commodityNames={commodityNames} />
                  </div>
                </div>
              )}
              {tab === "Profit Explorer" && (
                <div className="tab-pane fade show active bg-transparent" role="tabpanel">
                  <div className="container mt-4">
                    <GPView allData={allVouchersData} commodityNames={commodityNames} />
                  </div>
                </div>
              )}
              {tab === "Product Summary " && (
                <div className="tab-pane fade show active bg-transparent" role="tabpanel">
                  <div className="container mt-4">
                    <ItemView allData={allVouchersData} commodityNames={commodityNames} />
                  </div>
                </div>
              )}
              {tab === "Objective View" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <div className="container mt-4" >
                  <ObjectiveView allData={allVouchersData} commodityNames={commodityNames} userId={userId} />
                </div>
              </div>
              }
              {tab === "Payment View" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <div className="container mt-4" >
                  <PaymentView allData={allVouchersData} commodityNames={commodityNames} userId={userId} />
                </div>
              </div>
              }
              {tab === "Pan View" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <div className="container" >
                  <div className='d-flex mt-5 mb-0 justify-content-between flex-wrap gap-0 w-100'>
                    <View360 allData={allVouchersData} userId={userId} />
                  </div>

                </div>
              </div>
              }


            </div>
          </main>
        </div>
      </div >
    </>
  );
}


const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,

  }
}

export default connect(
  mapStateToProps,
  null
)(TallyDashboard)