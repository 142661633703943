import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, decryptData, GetCache, getDocDetails, getInvoiceFinalChargesObj, most_used_currencies, SetCache, toolTip } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import config from "../../../../config.json"
import SignDocument from "../../components/SignDocument";
import FinanceInvoiceModal from "../../contract/components/financeinvoiceModal";
import { InputWithSelect, NewInput, NewSelect } from "../../../../utils/newInput";
import { ToastContainer } from "react-toastify";
import swal from 'sweetalert';
import moment from "moment";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import { NewTable } from "../../../../utils/newTable";
import { InvoicePOComparisonModal } from "../../financier/financeApplication/InvoicePOComparisonModal";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" },
  { name: "Review" },
]
const reviewForm = [
  { "name": "Applicant Name", val: "applicantName", unit: "nameTitle" },
  { "name": "Request amount", val: "reqAmount", unit: "currency" },
  { "name": "Invoice amount:", val: "contractAmount", unit: "currency" },
  { "name": "Finance credit days", val: "creditDays" },
  { "name": "Invoice No.", val: "invoiceRefrenceNo" },
  { "name": "Bank", val: "beneficiaryBankTitle" },
  { "name": "Invoice issue date", val: "invoiceIssueDate" },
  { "name": "Lender", val: "lenderNameTitle" },
  { "name": "Invoice due date", val: "invoiceDueDate" },
  { "name": "Buyer", val: "buyerName" },
  { "name": "Purchase order no.", val: "poRefrenceNo" }
]

const reviewForm2 = [
  { "name": "Shipment Origin Country", val: "shipmentOriginCountry" },
  { "name": "Shipment Origin Address", val: "shipmentOriginAddress" },
  { "name": "Shipment Origin City", val: "shipmentOriginCity" },
  { "name": "Shipment Origin Postal Code", val: "shipmentOriginPostalCode" },
  { "name": "Shipment Destination Country", val: "shipmentDestinationCountry" },
  { "name": "Shipment Destination Address", val: "shipmentDestinationAddress" },
  { "name": "Shipment Destination City", val: "shipmentDestinationCity" },
  { "name": "Shipment Destination Postal Code", val: "shipmentDestinationPostalCode" },
]


const ApplyFinancebtn = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const view = queryParams.get("view") ? decryptData(queryParams.get("view")) : null

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [replaceDoc, setReplaceDoc] = useState({})
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [moreDocsArray2, setMoreDocsArray2] = useState([])

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [userBank, setUserBank] = useState([])
  const [bankMaster, setbankMasters] = useState([]);
  const [finalCharges, setFinalCharges] = useState({})
  const [showFees, toggleShowFees] = useState(false);
  const [feesData, setFeesData] = useState({});

  const [invPoComparisonData, setInvPoComparisonData] = useState(null)
  const [showInvPoAnalysis, setShowInvPoAnalysis] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null

  let selectedQuote = JSON.parse(localStorage.getItem("selectedQuote"))
  let isLenderModifi = selectedQuote.lender_name.toLowerCase().includes("modifi") ? true : false
  let limitPendingFrom = localStorage.getItem('limitPendingFrom') != "null"




  useEffect(() => {
    if (data.contractAmount && data.creditDays) {
      setFinalCharges(getInvoiceFinalChargesObj(data.contractAmount, data.creditDays, selectedQuote))
    }
  }, [data.contractAmount, data.creditDays])


  useEffect(() => {
    let viewOnlyData = {}
    call('POST', 'getBuyersDetailWithDoc', {
      buyersId: buyer,
      applicationId: localStorage.getItem('applicationId')
    }).then(async (result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setshowLoader(true)
      let termSheetDocument = await getDocDetails(localStorage.getItem("termSheet"))
      if (view) {
        viewOnlyData = await getInvoice()
      }
      setshowLoader(false)
      if (result.length) {
        console.log("vvvvvvvvvvvvvvvvvvvvvvvvv", viewOnlyData);

        setData({
          ...data, nameTitle: "Mr", currency: "USD", applicantName: userName, buyerName: result[0]["buyerName"],
          buyerPosition: result[0]["buyerPosition"], nameAuthorizedSignatory: result[0]["nameAuthorizedSignatory"],
          buyerEmail: result[0]["buyerEmail"], buyerPhoneCode: result[0]["buyerPhoneCode"], buyerContact: result[0]["buyerPhone"],
          buyerIdentifier: result[0]["identifier"], incoTerms: result[0]["incoTerms"],
          lenderId: selectedQuote.lender_id, termSheetDocument, lenderNameTitle: selectedQuote.lender_name, ...viewOnlyData
        })
      }
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      // console.log('running getUserBanks api -->', result);
      setUserBank(result.message);
    }).catch((e) => {
      // console.log('error in getUserBanks', e);
    })

    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    // Get bank master list that are involved in trade finance.
    call("GET", "getBanksListMaster")
      .then((result) => {
        setbankMasters(result);
      })
      .catch((e) => {
        console.log("Error while querying getBanksListMaster:", e);
      });
  }, [])

  let docIndex = {
    invoiceDocument: 0,
    poDocument: 1,
    logisticDocument: 2,
    invReportDocument: 3,
    grnDocument: 4
  }

  async function replaceDocument() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("replaceOnlyDoc", true)
    Object.keys(replaceDoc).map(key => {
      formData.append(key, data[key])
      if (data[key]["signatureId"]) {
        formData.append(key + "SignId", data[key]["signatureId"])
      }
    })
    formData.append("previousDocIds", data.docsId)
    formData.append("userId", userId)
    formData.append("invRefNo", view)
    if (data.modifi_deal_id) {
      formData.append("modifi_deal_id", data.modifi_deal_id)
    }
    call("POST", "updateInvoiceDiscounting", formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay('Updated successfully', "success", () => setTimeout(() => {
          window.location = '/apply-finance'
        }, 1000))
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        // console.log("error in updateInvoiceDiscounting", e);
      });
  }

  async function getInvoice() {
    return new Promise(resolve => {
      let viewOnlyData = {}
      call('POST', 'getInvDiscoutingDetails', { invNo: view }).then(async (result) => {
        // console.log('Invoice Get Discounting API  =====>', result);
        if (result && result.length) {
          let temp = result[0]
          let docsId = temp["tbl_doc_id"].split(",")

          viewOnlyData["modifi_deal_id"] = temp["modifi_deal_id"]
          viewOnlyData["tcAccepted"] = true
          viewOnlyData["docsId"] = docsId
          viewOnlyData["invoiceDocument"] = await getDocDetails(docsId[0])
          viewOnlyData["poDocument"] = await getDocDetails(docsId[1])
          viewOnlyData["logisticDocument"] = await getDocDetails(docsId[2])
          viewOnlyData["invReportDocument"] = await getDocDetails(docsId[3])
          viewOnlyData["grnDocument"] = await getDocDetails(docsId[4])
          viewOnlyData["packingListDocument"] = await getDocDetails(docsId[5])
          viewOnlyData["shippingBillDocument"] = await getDocDetails(docsId[6])

          viewOnlyData["poRefrenceNo"] = temp["po_no"]
          viewOnlyData["invoiceRefrenceNo"] = temp["reference_no"]
          viewOnlyData["beneficiaryBankName"] = temp["bankList"].split(":")[0] / 1
          viewOnlyData["currency"] = currencyData.filter(i => {
            if (i.id / 1 == temp["currency"] / 1) {
              return i
            }
          })[0]["code"]
          viewOnlyData["contractAmount"] = temp["contract_amount"]
          viewOnlyData["invoiceIssueDate"] = temp["issue_date"]
          viewOnlyData["invoiceDueDate"] = temp["due_date"]
          viewOnlyData["reqAmount"] = temp["amount"]
          viewOnlyData["lenderId"] = temp["lender_id"] / 1
          viewOnlyData["creditDays"] = temp["credit_days"]


          viewOnlyData["shipmentOriginCountry"] = temp["shipment_origin_country"]
          viewOnlyData["shipmentOriginAddress"] = temp["shipment_origin_address"]
          viewOnlyData["shipmentOriginCity"] = temp["shipment_origin_city"]
          viewOnlyData["shipmentOriginPostalCode"] = temp["shipment_origin_postal_code"]

          viewOnlyData["shipmentDestinationCountry"] = temp["shipment_destination_country"]
          viewOnlyData["shipmentDestinationAddress"] = temp["shipment_destination_address"]
          viewOnlyData["shipmentDestinationCity"] = temp["shipment_destination_city"]
          viewOnlyData["shipmentDestinationPostalCode"] = temp["shipment_destination_postal_code"]

          viewOnlyData["blNo"] = temp["blNo"]

          resolve(viewOnlyData)
        }
      }).catch((e) => {
        resolve({})
        // console.log('error in Invoice Get Discounting API  =====>', e);
      })
    })
  }

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === "beneficiaryBankName") {
      let beneficiaryBankTitle = userBank.filter(i => {
        if (i.id == event.target.value) {
          return i
        }
      })[0]["beneficiaryBankName"]
      setData({ ...data, [event.target.name]: event.target.value, beneficiaryBankTitle })
      setErrors({ ...errors, [event.target.name]: "" })
      return null
    }
    else if (event.target.name === "contractAmount") {
      setData({ ...data, [event.target.name]: event.target.value, reqAmount: event.target.value })
      setErrors({ ...errors, [event.target.name]: "", reqAmount: "" })
      return null
    }
    else if (event.target.name === "invoiceDueDate" && event.target.value) {
      let creditDays = moment(event.target.value, "YYYY-MM-DD").diff(moment(), "days")
      setData({ ...data, [event.target.name]: event.target.value, creditDays })
      setErrors({ ...errors, [event.target.name]: "", creditDays: "" })
      return null
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
  }



  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      // if (event.target.name.includes("otherDoc")) {
      //   setAddMoreDoc(addMoreDoc.concat(null))
      // }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        if (view) {
          setReplaceDoc({ ...replaceDoc, [event.target.name]: "" })
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        toggleShowFees(false)
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}

    if (tab === 0 && !view) {
      // if (data.contractAmount && selectedQuote.financeLimit && data.contractAmount > selectedQuote.financeLimit) {
      //   toastDisplay(`Invoice amount can't be more than pending finance limit of ${selectedQuote.financeLimit} `, "info")
      //   return null
      // }
    }

    if (tab === 0) {
      validateFields = ["applicantName", "poRefrenceNo", "buyerName", "buyerEmail", "buyerPosition", "buyerContact", "nameAuthorizedSignatory", "beneficiaryBankName",
        "contractAmount", "invoiceRefrenceNo", "invoiceIssueDate", "invoiceDueDate", "reqAmount", "lenderId", "creditDays"]
      if (isLenderModifi) {
        validateFields = validateFields.concat("shipmentOriginCountry", "shipmentOriginAddress", "shipmentOriginCity", "shipmentOriginPostalCode",
          "shipmentDestinationCountry", "shipmentDestinationAddress", "shipmentDestinationCity", "shipmentDestinationPostalCode")
      }
    }

    if (tab === 1) {
      if (!(data.invoiceDocument && data.invoiceDocument.name)) {
        err["invoiceDocument"] = "Mandatory field"
      }
      if (!(data.poDocument && data.poDocument.name)) {
        err["poDocument"] = "Mandatory field"
      }
      if (!(data.logisticDocument && data.logisticDocument.name)) {
        err["logisticDocument"] = "Mandatory field"
      }
    }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab === 2 || (tab === 1 && isLenderModifi && !view)) {
        if (view) {
          replaceDocument()
          return null
        }

        setshowLoader(true)
        let formData = new FormData()
        if (isLenderModifi) {
          formData.append("showFees", showFees)
          formData.append("quoteId", feesData.quoteId)
          formData.append("modifiCompleteResp", JSON.stringify(feesData.completeResp || {}))
        }
        formData.append("applicationId", localStorage.getItem('applicationId'))

        formData.append("userId", userId)
        formData.append("applicantEmail", userEmail)

        formData.append("selectedBuyerLimit", JSON.stringify({ limit: selectedQuote.assignValue }))
        formData.append("contractAmount", data.contractAmount)
        formData.append("invoiceRefrenceNo", data.invoiceRefrenceNo)
        formData.append("poRefrenceNo", data.poRefrenceNo)


        formData.append("invoiceIssueDate", data.invoiceIssueDate)
        formData.append("invoiceDueDate", data.invoiceDueDate)
        formData.append("reqAmount", data.reqAmount)
        formData.append("creditDays", data.creditDays)

        formData.append("currency", currencyData.filter(i => {
          if (i.code === data.currency) {
            return i
          }
        })[0]["id"])
        formData.append("currencyName", data.currency)
        formData.append("lenderId", selectedQuote.lender_id)
        formData.append("lenderName", selectedQuote.lender_name)
        formData.append("lenderEmail", bankMaster.filter(i => {
          if (i.id / 1 == data.lenderId / 1) {
            return i
          }
        })[0]["email_id"])

        formData.append("buyerId", buyer)
        formData.append("buyername", data.buyerName)

        formData.append("buyerEmail", data.buyerEmail)
        formData.append("bankListId", data.beneficiaryBankName)
        formData.append("bankListName", data.beneficiaryBankTitle)
        formData.append("logisticId", 0)

        formData.append("logisticName", "Others")
        formData.append("nameAuthorizedSignatory", data.nameAuthorizedSignatory)
        formData.append("buyerPosition", data.buyerPosition)
        formData.append("buyerContact", data.buyerContact)

        formData.append("invoiceDocument", data.invoiceDocument)
        formData.append("poDocument", data.poDocument)
        formData.append("logisticDocument", data.logisticDocument)

        if (data.invoiceDocument.signatureId) {
          formData.append("invoiceDocumentSignId", data.invoiceDocument.signatureId)
        }
        if (data.poDocument.signatureId) {
          formData.append("poDocumentSignId", data.poDocument.signatureId)
        }
        if (data.logisticDocument.signatureId) {
          formData.append("logisticDocumentSignId", data.logisticDocument.signatureId)
        }

        if (data.invReportDocument && data.invReportDocument.name) {
          formData.append("invReportDocument", data.invReportDocument)
          if (data.invReportDocument.signatureId) {
            formData.append("invReportDocumentSignId", data.invReportDocument.signatureId)
          }
        }
        if (data.grnDocument && data.grnDocument.name) {
          formData.append("grnDocument", data.grnDocument)
          if (data.grnDocument.signatureId) {
            formData.append("grnDocumentSignId", data.grnDocument.signatureId)
          }
        }
        if (data.packingListDocument && data.packingListDocument.name) {
          formData.append("packingListDocument", data.packingListDocument)
          if (data.packingListDocument.signatureId) {
            formData.append("packingListDocumentSignId", data.packingListDocument.signatureId)
          }
        }
        if (data.shippingBillDocument && data.shippingBillDocument.name) {
          formData.append("shippingBillDocument", data.shippingBillDocument)
          if (data.shippingBillDocument.signatureId) {
            formData.append("shippingBillDocumentSignId", data.shippingBillDocument.signatureId)
          }
        }

        formData.append("sellerId", userId)
        formData.append("dnc", "on")
        formData.append("otherDocMetaData", null)
        formData.append("baseUrl", config.baseUrl)
        formData.append("applicantName", data.applicantName)
        formData.append("userEmail", userEmail)
        formData.append("userIdentifier", userIdentifier)
        formData.append("buyerIdentifier", data.buyerIdentifier)
        formData.append("incoTerms", data.incoTerms)
        formData.append("bankAccountNo", userBank.filter(i => {
          if (i.id / 1 == data.beneficiaryBankName / 1) {
            return i
          }
        })[0]["beneficiaryAccountNo"])


        formData.append("shipmentOriginCountry", data.shipmentOriginCountry)
        formData.append("shipmentOriginAddress", data.shipmentOriginAddress)
        formData.append("shipmentOriginCity", data.shipmentOriginCity)
        formData.append("shipmentOriginPostalCode", data.shipmentOriginPostalCode)

        formData.append("shipmentDestinationCountry", data.shipmentDestinationCountry)
        formData.append("shipmentDestinationAddress", data.shipmentDestinationAddress)
        formData.append("shipmentDestinationCity", data.shipmentDestinationCity)
        formData.append("shipmentDestinationPostalCode", data.shipmentDestinationPostalCode)

        // Display the key/value pairs
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        call("post", "applyInvoiceDiscounting", formData)
          .then((result) => {
            // console.log("apiresultapplyInvoiceDiscounting===>", result);
            setshowLoader(false)
            if (isLenderModifi && !showFees) {
              toastDisplay("Kindly check fees structure to continue", "info");
              setFeesData(result)
              toggleShowFees(true)
              setTab(2)
            }
            else {
              toastDisplay('Applied for finance successfully', "success", () => setTimeout(() => {
                window.location = '/apply-finance'
              }, 1000))
            }
          })
          .catch((e) => {
            setshowLoader(false)
            toastDisplay(e, "error");
            console.log("error in create finance modal", e);
          });
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors(err)
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <InvoicePOComparisonModal invNo={view} buyerId={buyer} showInvPoAnalysis={showInvPoAnalysis} setShowInvPoAnalysis={setShowInvPoAnalysis}
        invPoComparisonData={invPoComparisonData} setInvPoComparisonData={setInvPoComparisonData} />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_apply-finance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for Finance"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/apply-finance"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row position-relative">

                      {Object.keys(invPoComparisonData || {})?.length ? (<img
                        src={'assets/images/details_icon.png'}
                        style={{
                          width: 50,
                          top: 0,
                          right: 0
                        }}
                        className="mx-3 cursor position-absolute"
                        onClick={() => {
                          setShowInvPoAnalysis({ show: true, data: invPoComparisonData })
                        }}
                      />) : null}

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <InputWithSelect isDisabled={view} isAstrix={true} type={"text"} label={"Applicant name"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"nameTitle"} selectValue={data.nameTitle}
                            optionLabel={"name"} optionValue={'name'}
                            name={'applicantName'} value={data.applicantName} error={errors.applicantName}
                            onChange={handleChange} />
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Purchase order number"}
                            name={"poRefrenceNo"} value={data.poRefrenceNo} error={errors.poRefrenceNo}
                            onChange={handleChange} />
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Buyer Email"}
                              name={"buyerEmail"} value={data.buyerEmail} error={errors.buyerEmail}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Buyer Position"}
                              name={"buyerPosition"} value={data.buyerPosition} error={errors.buyerPosition}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-6 ps-0">
                            <NewInput isAstrix={true} isDisabled={true} type={"text"} label={"Buyer Name"}
                              name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                              onChange={handleChange} />
                          </div>

                          <div className="col-md-6 pe-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Buyer Contact"}
                              selectData={countrys}
                              selectName={"buyerPhoneCode"} selectValue={data.buyerPhoneCode}
                              optionLabel={"phonecode"} optionValue={'phonecode'}
                              name={'buyerContact'} value={data.buyerContact} error={errors.buyerContact}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Name of Authorized Signatory (Buyer)"}
                            name={"nameAuthorizedSignatory"} value={data.nameAuthorizedSignatory} error={errors.nameAuthorizedSignatory}
                            onChange={handleChange} />
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewSelect isDisabled={view} isAstrix={true} label={"Select bank"}
                            selectData={userBank} name={"beneficiaryBankName"}
                            value={data.beneficiaryBankName} optionLabel={"beneficiaryBankName"} optionValue={'id'}
                            onChange={handleChange} error={errors.beneficiaryBankName} />
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-7 ps-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Invoice Amount"}
                              selectData={currencyData}
                              selectName={"currency"} selectValue={data.currency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'contractAmount'} value={data.contractAmount} error={errors.contractAmount}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-5 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Invoice Number"}
                              name={"invoiceRefrenceNo"} value={data.invoiceRefrenceNo} error={errors.invoiceRefrenceNo}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-7 ps-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Invoice Amount"}
                              selectData={currencyData}
                              selectName={"currency"} selectValue={data.currency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'contractAmount'} value={data.contractAmount} error={errors.contractAmount}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-5 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Invoice Number"}
                              name={"invoiceRefrenceNo"} value={data.invoiceRefrenceNo} error={errors.invoiceRefrenceNo}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"BL Number"}
                              name={"blNo"} value={data.blNo} error={errors.blNo}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"date"} label={"BL Issue Date"}
                              name={"invoiceIssueDate"} value={data.invoiceIssueDate} error={errors.invoiceIssueDate}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex">
                          {/* <div className="col-md-7 ps-0">
                            <InputWithSelect isDisabled={view} isAstrix={true} type={"number"} label={"Request amount"}
                              selectData={currencyData}
                              selectName={"currency"} selectValue={data.currency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'reqAmount'} value={data.reqAmount} error={errors.reqAmount}
                              onChange={handleChange} />
                          </div> */}
                          <div className="col-md-6 ps-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"date"} label={"BL Due Date"}
                              name={"invoiceDueDate"} value={data.invoiceDueDate} error={errors.invoiceDueDate}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-6 pe-0">
                            <NewInput isDisabled={view} isAstrix={true} type={"number"} label={"Finance Credit Days"}
                              name={"creditDays"} value={data.creditDays} error={errors.creditDays}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 mb-2">
                        <div className="col-md-12">
                          <NewSelect isDisabled={true} isAstrix={true} label={"Select lender"}
                            selectData={bankMaster} name={"lenderId"}
                            value={data.lenderId} optionLabel={"company_name"} optionValue={'id'}
                            onChange={handleChange} error={errors.buyerCountry} />
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <NewSelect isAstrix={true} label={"Shipper / broker"}
                          selectData={countrys} name={"Shipperbroker"}
                          value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.buyerCountry} />
                      </div> */}
                      <div className="col-md-5 mb-2">
                        <div className="col-md-12 d-flex ">
                          <div className={limitPendingFrom ? "col-md-6 ps-0" : "col-md-4 ps-0"}>
                            <NewInput isDisabled={true} type={"text"} label={"Factoring Fee"}
                              name={"factoringFees"} value={selectedQuote.factoringFees + " " + selectedQuote.factoringFeesCurrency} error={errors.interestRate}
                              onChange={handleChange} />
                          </div>
                          <div className={limitPendingFrom ? "col-md-6 ps-0" : "col-md-4 ps-0"}>
                            <NewInput isDisabled={true} type={"text"} label={"Interest Rate"}
                              name={"interestRate"} value={selectedQuote.interestRate + " %"} error={errors.interestRate}
                              onChange={handleChange} />
                          </div>
                          {limitPendingFrom ? null : (
                            <div className="col-md-4 px-0">
                              <NewInput isDisabled={true} type={"text"} label={"Setup fee"}
                                name={"setUpFees"} value={selectedQuote.setupFees + " " + selectedQuote.setupFeesCurrency} error={errors.setUpFees}
                                onChange={handleChange} />
                            </div>
                          )}
                        </div>
                      </div>
                      {isLenderModifi ? (
                        <>
                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewSelect isDisabled={view} isAstrix={true} label={"Shipment Origin Country"}
                                selectData={countrys} name={"shipmentOriginCountry"}
                                value={data.shipmentOriginCountry} optionLabel={"name"} optionValue={'sortname'}
                                onChange={handleChange} error={errors.shipmentOriginCountry} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Origin Address"}
                                name={"shipmentOriginAddress"} value={data.shipmentOriginAddress} error={errors.shipmentOriginAddress}
                                onChange={handleChange} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Origin City"}
                                name={"shipmentOriginCity"} value={data.shipmentOriginCity} error={errors.shipmentOriginCity}
                                onChange={handleChange} />
                            </div>
                          </div>


                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Origin Postal Code"}
                                name={"shipmentOriginPostalCode"} value={data.shipmentOriginPostalCode} error={errors.shipmentOriginPostalCode}
                                onChange={handleChange} />
                            </div>
                          </div>


                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewSelect isDisabled={view} isAstrix={true} label={"Shipment Destination Country"}
                                selectData={countrys} name={"shipmentDestinationCountry"}
                                value={data.shipmentDestinationCountry} optionLabel={"name"} optionValue={'sortname'}
                                onChange={handleChange} error={errors.shipmentDestinationCountry} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Destination Address"}
                                name={"shipmentDestinationAddress"} value={data.shipmentDestinationAddress} error={errors.shipmentDestinationAddress}
                                onChange={handleChange} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Destination City"}
                                name={"shipmentDestinationCity"} value={data.shipmentDestinationCity} error={errors.shipmentDestinationCity}
                                onChange={handleChange} />
                            </div>
                          </div>

                          <div className="col-md-5 mb-2">
                            <div className="col-md-12">
                              <NewInput isDisabled={view} isAstrix={true} type={"text"} label={"Shipment Destination Postal Code"}
                                name={"shipmentDestinationPostalCode"} value={data.shipmentDestinationPostalCode} error={errors.shipmentDestinationPostalCode}
                                onChange={handleChange} />
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/* <div className="col-md-3 ">
                        <NewInput isAstrix={true} type={"text"} label={"Finance credit date"}
                          name={"Finance credit date"} value={data.Setupfees} error={errors.Setupfees}
                          onChange={handleChange} />
                      </div> */}
                    </div>
                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice Document {astrix}</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                          {errors.invoiceDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invoiceDocument}</b></div> : ''}
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">PO Document {astrix}</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Bill of Lading/Airway Bill{astrix}</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "logisticDocument": null })}
                          />
                          {errors.logisticDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.logisticDocument}</b></div> : ''}
                        </div>
                        {data.logisticDocument && data.logisticDocument.name && !data.logisticDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.logisticDocument, docSignName: "logisticDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.logisticDocument && data.logisticDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Investigation Report</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invReportDocument": null })}
                          />
                          {errors.invReportDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invReportDocument}</b></div> : ''}
                        </div>
                        {data.invReportDocument && data.invReportDocument.name && !data.invReportDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invReportDocument, docSignName: "invReportDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invReportDocument && data.invReportDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Certificate of Origin</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "grnDocument": null })}
                          />
                          {errors.grnDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.grnDocument}</b></div> : ''}
                        </div>
                        {data.grnDocument && data.grnDocument.name && !data.grnDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.grnDocument, docSignName: "grnDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.grnDocument && data.grnDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Packing List</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "packingListDocument": null })}
                          />
                          {errors.packingListDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.packingListDocument}</b></div> : ''}
                        </div>
                        {data.packingListDocument && data.packingListDocument.name && !data.packingListDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.packingListDocument, docSignName: "packingListDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.packingListDocument && data.packingListDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Shipping Bill</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "shippingBillDocument": null })}
                          />
                          {errors.shippingBillDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.shippingBillDocument}</b></div> : ''}
                        </div>
                        {data.shippingBillDocument && data.shippingBillDocument.name && !data.shippingBillDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.shippingBillDocument, docSignName: "shippingBillDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.shippingBillDocument && data.shippingBillDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler
                        purpose={"ApplyForInvoiceFinance"} purposeId={localStorage.getItem("applicationId")}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
                      />
                    </div>

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Term Sheet</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                          />
                          {errors.termSheetDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.termSheetDocument}</b></div> : ''}
                        </div>
                        {data.termSheetDocument && data.termSheetDocument.name && !data.termSheetDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.termSheetDocument, docSignName: "termSheetDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.termSheetDocument && data.termSheetDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler
                        notEditable={true} titleNotEditable={true} hideAddMoreOption={true}
                        purpose={"SendTermSheet"} purposeId={localStorage.getItem("applicationId")}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray2(docs)} />
                    </div>

                  </div>
                ) : null}


                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Applicant Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                          {isLenderModifi ? reviewForm2.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          }) : null}
                        </div>
                      </div>

                      {isLenderModifi ? (
                        <div className='w-100 mt-4'>
                          <label className='font-wt-500 font-size-16'><u>Total Charges</u></label>
                          {feesData.fees && Object.keys(feesData.fees).length ? Object.keys(feesData.fees).map((item, index) => (
                            <div className='col-5 p-0'>
                              <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{item.toUpperCase().split("FEE")[0]}</label>
                              <label className='lh-18 font-size-14'>:</label>
                              <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                {(feesData.fees[item]["amount"] / 100).toFixed(2) + " " + feesData.fees[item]["currency"]}
                              </label>
                            </div>
                          )) : null}
                        </div>
                      ) : (
                        <div className='w-100 mt-4'>
                          <label className='font-wt-500 font-size-16'><u>Total Charges</u></label>
                          <div className='col-5 p-0'>
                            <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Factoring Rate"}</label>
                            <label className='lh-18 font-size-14'>:</label>
                            <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              {finalCharges["factoring"] || "NA"}
                            </label>
                          </div>
                          <div className='col-5 p-0'>
                            <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Interest Rate"}</label>
                            <label className='lh-18 font-size-14'>:</label>
                            <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              {finalCharges["interest"] || "NA"}
                            </label>
                          </div>
                          <div className='col-5 p-0'>
                            <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Setup Fees (One Time Only)"}</label>
                            <label className='lh-18 font-size-14'>:</label>
                            <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              {finalCharges["oneTime"] || "NA"}
                            </label>
                          </div>
                          {finalCharges.otherCharges && finalCharges.otherCharges.length ? finalCharges.otherCharges.map((item, index) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{selectedQuote.otherCharges[index]["name"]}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {item["OtherCharge" + (index + 1)] || "NA"}
                                </label>
                              </div>
                            )
                          }) : null}
                          <div className='col-5 p-0'>
                            <label className='col-6 lh-18 text-color-label font-size-14 p-0'>{"Total Payable"}</label>
                            <label className='lh-18 font-size-14'>:</label>
                            <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              {finalCharges["total"] || "NA"}
                            </label>
                          </div>
                        </div>
                      )}
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>

                        <div className='row mt-2'>

                          {data.invoiceDocument && data.invoiceDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice Document</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.poDocument && data.poDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">PO Document</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.logisticDocument && data.logisticDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Bill of Lading/Airway Bill</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"logisticDocument"} value={data.logisticDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }

                          {data.invReportDocument && data.invReportDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Investigation Report</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invReportDocument"} value={data.invReportDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.grnDocument && data.grnDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Certificate of Origin</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"grnDocument"} value={data.grnDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.packingListDocument && data.packingListDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Packing List</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"packingListDocument"} value={data.packingListDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {data.shippingBillDocument && data.shippingBillDocument.name ? (
                            <div className="col-md-4">
                              <label className="font-size-14">Shipping Bill</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {moreDocsArray?.length ? moreDocsArray.map((item, index) => {
                            if (item.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}

                          {data.termSheetDocument && data.termSheetDocument.name &&
                            <div className="col-md-4">
                              <label className="font-size-14">Term Sheet</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"termSheetDocument"} value={data.termSheetDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          {moreDocsArray2?.length ? moreDocsArray2.map((item, index) => {
                            if (item.name) {
                              return (
                                <div className="col-md-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                          <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}
                {tab === 2 && !data.tcAccepted ? null : (
                  <div className="px-5 pb-5">
                    <button type="button"
                      onClick={() => handleValidation(undefined)}
                      className={`new-btn w-20 py-2 px-2 text-white`}>
                      {tab === 2 ? `${view ? "Update" : "Submit"}` : "Continue"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for “Invoice discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyFinancebtn)