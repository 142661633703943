/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const AccountListTable = ({ TableHeadData, TableData, children }) => {
  return (
    <div className="tableHt mt-4">
      <table className="table table-striped text-center ">
        <thead className="align-middle">
          <tr>
            {TableHeadData.map((key, index) => {
              return (
                <th scope="col" key={index}>
                  {key}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
export default AccountListTable;
