import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import customStyles from '../dataTablesColumsMap/customTableCss';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import Footer from '../partial/footer';
import columns from '../dataTablesColumsMap/channelPartnerUserList';

const ChannelPartnerUserList = ({ userTokenDetails }) => {

  const [filterData, setFilterData] = useState({});
  const [refresh, setrefresh] = useState(0);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  useEffect(() => {

    fetchTableData(1, {});

  }, [filterData]);

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");
  const [completeButton, setcompleteButton] = useState(0);

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getUserNetworkChannelPartner', objectAPI).then((result) => {
      console.log('running getUserNetworkChannelPartner api-->', result);
      settableData(formatDataFortable(result));
      setTotalRows(result.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getUserNetworkChannelPartner', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getUserNetworkChannelPartner', objectAPI).then((result) => {
      settableData(formatDataFortable(result));
      setTotalRows(result.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getUserNetworkChannelPartner', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.user_name = dataArray[i].user_name;
      tempObj.user_email = (dataArray[i].user_email ? dataArray[i].user_email : 'NA');
      tempObj.buyers_added = dataArray[i].buyers_added;

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setFilterData({ supplierType: "", buyerType: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------
  //JSX
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className='row'>
          <Sidebar state={'channelUser'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 "} id="app-main-div">
            <Header
              title={'My User Details'}
              userTokenDetails={userTokenDetails}
            />

            <div class="d-md-flex mt-3 mb-3">
              <div class="ml-md-auto">
                <ul class="range-slider">
                  <li>
                    <input name="search" id="searchInput" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                    <button type="button" onClick={() => {
                      setFilterData({
                        ...filterData,
                        "search": searchKey
                      })
                    }}><i class="fas fa-search fa-lg"></i></button>
                  </li>
                  <li>
                    <label>From</label>
                    <input type="date" id="searchFromdate" name="fromDate" onChange={filterState} />
                  </li>
                  <li>
                    <label>To</label>
                    <input type="date" id="searchTodate" name="toDate" onChange={filterState} />
                  </li>
                  <li>
                    <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                  </li>
                </ul>
              </div>
            </div>

            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>

          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(
  mapStateToProps
)(ChannelPartnerUserList)