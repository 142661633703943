export default function validate(values) {

  let errors = {};
  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


  if (!values.currentPassword) {
    errors.currentPassword = "Field Missing";
  }

  if (!values.newPassword) {
    errors.newPassword = 'Password is required';
  } else if (!strongRegex.test(values.newPassword)) {
    errors.newPassword = 'Password Does not meet the Policy. It requires min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol';
  }

  if (values.newPassword === values.currentPassword) {
    errors.newPassword = "New Password can not be equal to current password";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Re-Enter the password";
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Those Passwords didn't match. Try again";
  }

  console.log(errors)
  return errors;
}
