import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  setContractDetails,
  setLaytimesheet,
  setContractState,
  setContractDetailLogi,
  setContractStateLogi,
  setshowModalAddComm,
  setViewTrack,
  setInvoiceDetail,
  setContractDocList,
  setPayment,
  setShowCreateProfileAdminModal,
  setAddShip,
  setDisbursed,
  setFinanceDetails,
  setAddDirectory,
  setRaiseQuotation,
  setoffContract,
  setChannelPartnerAccountList,
  setChannelPartnerDisbursedInvoice,
  setChannelPartnerRaisedInvoice,
  setShowViewProfileModal,
  setcreditLineDetails,
  setNavToggleState,
  setCreditlineDataBank,
  setCreditline,
  setCreditDocuments,
  setSidebarOpen,
} from "../../store/actions/action";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import logoutSession from "../../utils/logoutSession";
import avatarUrl from "../../utils/makeAvatarUrl";
import creditLineGrid from "../userProfile/creditLineGrid";
import ChangeFinanceMode from "../finance/changeFinanceType";
import { TabComponent } from "../CommonComponent/TabComponent";
import { inspectionTypeIds, shipperTypeIds } from "./sidebar";
import moment from "moment";
import {
  checkPermission,
  getNotificationIcon,
  multiSelectEventHandler,
  printDiv,
  redirectNotification,
  syndicationChargesTNCHtml,
} from "../../utils/myFunctions";
import { Link } from "react-router-dom";
import { CustomSelect, NewInput, NewSelect } from "../../utils/newInput";
import {
  getUserDataFromCookie,
  setCookieInAPP,
} from "../../utils/cookieHelper";
// import ApplyForCreditLine from '../creditLineComp/applyCreditLine';
import config from "../../config.json";
import swal from "sweetalert";
import MultipleSelect from "../../utils/MultipleSelect";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import html2pdf from 'html2pdf.js';

const HeaderV2 = ({
  userTokenDetails,
  title,
  titleClass,
  onTitleClick,
  total,
  setsidebarOpen,
  isFilter,
  filterSearch,
  downloadOption,
  tableViewOption,
  timeFilter,
  onChangeFilterData,
  filterData,
  referalCode,
  onRefresh,
}) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  let length = window.location.href.split("/").length;
  let url = window.location.href.split("/")[length - 1];
  const [profile, setProfile] = useState(false);
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [subUserDetail, setSubUserDetail] = useState({});
  const [subUserCriteria, setSubUserCriteria] = useState({});
  const [financeType, setFinanceType] = useState(null);
  const [changeFinanceMode, setchangeFinanceMode] = useState({
    modal: false,
    data: {},
  });
  // const [createCredit, setCreateCredit] = useState({ modal: false, data: {} })
  const [walletBalance, setWalletBalance] = useState(0);
  const [notificationdata, setnotificationdata] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [useMyMailPopup, toggleUseMyMailPopup] = useState({ show: false });
  const [data, setData] = useState({ account: "hostinger" });
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const contentRef = useRef(null);
  const [userData, setUserData] = useState({});
  const [syndicationTncPopup, setSyndicationTncPopup] = useState({
    show: false,
  });
  const [signdoc, setSigndoc] = useState(false);
  const referalCodeText = useRef(null);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {};
  const userId =
    userTokenDetails && userTokenDetails.user_id
      ? userTokenDetails.user_id
      : null;
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null;
  const userAvatar = userTokenDetails.userAvatar
    ? userTokenDetails.userAvatar
    : null;
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null;
  const subUserId = userTokenDetails.sub_user_id
    ? userTokenDetails.sub_user_id
    : null;
  const parentData = userTokenDetails.parent_data
    ? userTokenDetails.parent_data
    : null;
  const isEximBank = userTokenDetails.isEximBank
    ? userTokenDetails.isEximBank
    : null;
  // const userPermissions = userTokenDetails.UserAccessPermission
  //   ? userTokenDetails.UserAccessPermission
  //   : [];

  const userPermissions = userTokenDetails.UserAccessPermission
    ? userTokenDetails.UserAccessPermission
    : [];
  const sub_user_id = userTokenDetails.sub_user_id
    ? userTokenDetails.sub_user_id
    : 0;
  // const userPermissionsForSubAdmin =
  //   userTokenDetails.UserAccessPermission || "{}";
  const userPermissionsForSubAdmin = JSON.parse(
    userTokenDetails.UserAccessPermission || "{}"
  );
  const userIsAlsoCP = userTokenDetails.user_is_also_cp
    ? userTokenDetails.user_is_also_cp
    : null;

  const [colorchange, setColorchange] = useState(false);
  console.log(userPermissions, "this is user permissions in headerv2", userPermissionsForSubAdmin, 'subadmin pemrisisions')
  async function handleChange(e) {
    e.persist();
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const copyToClipboard = () => {
    // Create a range to select text
    const range = document.createRange();
    range.selectNode(referalCodeText.current);
    // Create a selection
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    // Execute the copy command
    document.execCommand("copy");
    // Clear the selection
    selection.removeAllRanges();
    toastDisplay("Copied to clipboard", "success");
  };

  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin) {
      handleSubadminNotificationPopup();
    }
    if (userTypeId / 1 == 19) {
      call("POST", "syndicationTNCStatus", { userId })
        .then((yes) => { })
        .catch((no) => {
          setSyndicationTncPopup({ show: true });
        });
    }
  }, []);

  async function handleSubadminNotificationPopup() {
    let reqApiResp = await call("POST", "getSubadminActionRequests", {});
    for (let index = 0; index < reqApiResp.length; index++) {
      let element = reqApiResp[index];
      if (element.status / 1 == 0) {
        await swal({
          title: "Please confirm!",
          text: `Request to ${element.action}, raised by ${element.actionRaisedBy}, do you want to approve this request?`,
          icon: "warning",
          buttons: ["No", "Yes"],
          dangerMode: true,
        }).then(async (yes) => {
          if (yes) {
            await call("POST", "updateSubadminActionRequests", {
              status: 1,
              reqId: element.id,
            });
            toastDisplay("Request approved", "success");
          } else {
            await call("POST", "updateSubadminActionRequests", {
              status: 2,
              reqId: element.id,
            });
            toastDisplay("Request rejected", "success");
          }
        });
      }
    }
  }

  useEffect(() => {
    call("POST", "getuserprofiledata", { email: userEmail, min: true })
      .then((result) => {
        console.log("result in getuserprofiledata-->", result);
        setUserData(result);
      })
      .catch((e) => {
        console.log("error in getuserprofiledata", e);
      });
  }, []);

  useEffect(() => {
    const scrollFn = () => {
      if (window.scrollY >= 50) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    };

    window.addEventListener("scroll", scrollFn);

    return () => {
      window.removeEventListener("scroll", scrollFn);
    };
  }, []);

  useEffect(() => {
    if (userTypeId / 1 == 19) {
      call("POST", "getTutorialStatus", { userId })
        .then(async (result) => {
          console.log(
            "API result getTutorialStatus",
            result,
            window.location.href
          );

          if (result.walletTutorial) {
            await call("POST", "activateFreePlan", { plan_id: 5, userId });
            // let walletLocationSteps = [
            //   "/wallet?showTutorial=true",
            //   "/plans?showTutorial=true",
            // ];
            // let currentWalletLocationStep =
            //   localStorage.getItem("walletTutorialStep") || 0;
            // localStorage.setItem(
            //   "walletTutorialStep",
            //   currentWalletLocationStep
            // );
            // if (
            //   !window.location.href.includes(
            //     walletLocationSteps[currentWalletLocationStep]
            //   )
            // ) {
            //   window.location = walletLocationSteps[currentWalletLocationStep];
            // }
          } else {
            // localStorage.removeItem("walletTutorialStep");
            // if (result.lcTutorial) {
            //   let lcLocationSteps = [
            //     "/LcQuote?showTutorial=true",
            //     "/Quotes?showTutorial=true",
            //     "/buyerManagement?showTutorial=true",
            //     "/Reportv2?showTutorial=true",
            //     "/ChatRoomV2?showTutorial=true",
            //     "/HelpSupport?showTutorial=true",
            //     "/viewprofile?showTutorial=true",
            //     "/applyForLimit?showTutorial=true",
            //     "tutorialNavLink",
            //     "/applyForLimit?lc=true&showTutorial=true",
            //     "tutorialNavLink",
            //     "/viewprofile?action=addNewBank&showTutorial=true",
            //   ];
            //   let currentLCLocationStep;
            //   if (localStorage.getItem("lcTutorialStep")) {
            //     currentLCLocationStep = localStorage.getItem("lcTutorialStep");
            //   } else if (result.planType === "Invoice") {
            //     currentLCLocationStep = 2;
            //   } else if (result.planType === "LC") {
            //     currentLCLocationStep = 11;
            //   }
            //   localStorage.setItem("lcTutorialStep", currentLCLocationStep);
            //   if (
            //     lcLocationSteps[currentLCLocationStep] === "tutorialNavLink"
            //   ) {
            //     lcLocationSteps[currentLCLocationStep] =
            //       localStorage.getItem("tutorialNavLink") +
            //       "&showTutorial=true";
            //   }
            //   if (
            //     !window.location.href.includes(
            //       lcLocationSteps[currentLCLocationStep]
            //     )
            //   ) {
            //     window.location = lcLocationSteps[currentLCLocationStep];
            //   }
            // } else {
            //   localStorage.removeItem("lcTutorialStep");
            // }
          }
        })
        .catch((e) => {
          console.log("Error while  getTutorialStatus", e);
        });
    }
    if (userTypeId === 19) {
      call("POST", "getFinanceType", { userId: userId })
        .then((result) => {
          console.log("result in getFinanceType", result);
          setFinanceType(result);
        })
        .catch((e) => {
          console.log("error in getFinanceType", e);
        });
    }

    call("POST", "getWalletBalance", { userId })
      .then((result) => {
        console.log("API result getWalletBalance", result);
        if (result && result.length) {
          setWalletBalance(result[0]["coins"]);
        }
      })
      .catch((e) => {
        console.log("Error while  getWalletBalance", e);
      });

    let notificationReq = {
      userId,
      period: "today",
      resultPerPage: 4,
      currentPage: 1,
      isAdmin: userTypeId === 1,
    };
    call("POST", "getNotification", notificationReq)
      .then((result) => {
        console.log("API result getNotification", result);
        setnotificationdata(result);
      })
      .catch((e) => {
        console.log("Error while  getNotification", e);
      });

    call("POST", "getSubUserName", { subUserId: subUserId }).then((result) => {
      console.log("result in getSubUserName => ", result);
      setSubUserDetail(result);
    });

    //------------------------------------------------------------------
  }, []);

  const logout = (redirectAfterLogout) => {
    logoutSession(redirectAfterLogout);
  };

  // useEffect(() => {
  //   if (syndicationTncPopup.show) {
  //     const handleScroll = () => {
  //       const contentElement = contentRef.current;
  //       if (!contentElement) return;

  //       const scrollTop = contentElement.scrollTop;
  //       const scrollHeight = contentElement.scrollHeight;
  //       const clientHeight = contentElement.clientHeight;

  //       if (scrollTop + clientHeight >= scrollHeight) {
  //         setScrolledToBottom(true);
  //       } else {
  //         setScrolledToBottom(false);
  //       }
  //     };

  //     const contentElement = contentRef.current;
  //     if (contentElement) {
  //       contentElement.addEventListener('scroll', handleScroll);
  //     }

  //     return () => {
  //       if (contentElement) {
  //         contentElement.removeEventListener('scroll', handleScroll);
  //       }
  //     };
  //   }
  // }, [syndicationTncPopup.show]);

  useEffect(() => {
    if (data.commercialTerms?.name) {
      setSigndoc(true);
      setData({
        ...data,
        docSignName: "commercialTerms",
        docToSign: data.commercialTerms,
      });
      setSyndicationTncPopup({ show: false });
    }
  }, [data.commercialTerms]);

  console.log("commercialTermssssssssssss", data, signdoc);

  return (
    <>
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      {signdoc && (
        <SignDocument
          signdoc={signdoc}
          setSigndoc={setSigndoc}
          docToSign={data.docToSign}
          setUpdatedDoc={async (updatedDoc) => {
            setShowLoader(true);
            let formData = new FormData();
            formData.append("commercialTerms", updatedDoc);
            formData.append("userId", userId);
            await call("POST", "updateSyndicationTNCStatus", formData);
            toastDisplay("Terms & Conditions accepted", "success");
            setSyndicationTncPopup({ show: false });
            setShowLoader(false);
          }}
        />
      )}
      {syndicationTncPopup.show ? (
        <FinanceInvoiceModal
          limitinvoice={syndicationTncPopup}
          closeSuccess={() => {
            setSyndicationTncPopup({ show: false });
          }}
          hideCloseIcon={true}
          modalSize={"lg"}
          marginTop={"2rem"}
        >
          <div style={{}}>
            <label className="font-size-18 font-wt-600 w-100 text-center pt-4 text-color1">
              Please accept terms and condition to use the platform
            </label>
            <div
              id={"termsAndConditionsView"}
              className="px-5 my-5 mx-4"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                height: "57vh",
              }}
              dangerouslySetInnerHTML={{
                __html: syndicationChargesTNCHtml({ userName }),
              }}
            ></div>
            <div className="d-flex row justify-content-evenly m-0 p-0">
              <button
                type="button"
                onClick={async () => {
                  setShowLoader(true);
                  await call("POST", "updateSyndicationTNCStatus", {
                    skipForNow: true,
                    userId,
                  });
                  setShowLoader(false);
                  setSyndicationTncPopup({ show: false });
                }}
                className={`new-btn2 w-30 py-2 px-3 text-color1 cursor`}
              >
                {"SKIP FOR NOW"}
              </button>
              <button
                type="button"
                onClick={async () => {
                  html2pdf()
                    .from(syndicationChargesTNCHtml({ userName }))
                    .toPdf()
                    .get("pdf")
                    .then((pdf) => {
                      let blobObj = new Blob([pdf.output("blob")], {
                        type: "application/pdf",
                      });
                      let fileObj = new File(
                        [blobObj],
                        "Commercial Terms.pdf",
                        { type: "application/pdf" }
                      );
                      const reader = new FileReader();
                      reader.readAsDataURL(fileObj);
                      reader.onload = () => {
                        fileObj["filebase64"] = reader.result;
                        setData({ ...data, commercialTerms: fileObj });
                      };
                      reader.onerror = (error) => {
                        console.error(
                          "Error converting file to base64:",
                          error
                        );
                      };
                    })
                    .catch((error) => {
                      console.log("errorInhtml2pdf", error);
                    });
                }}
                className={`new-btn w-30 py-2 px-3 text-white cursor`}
              >
                {"ACCEPT"}
              </button>
            </div>{" "}
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div
        className={`modal fade ${useMyMailPopup.show && "show"}`}
        style={
          useMyMailPopup.show ? { display: "block", zIndex: "1000001" } : {}
        }
      >
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              <div className="">
                <i
                  onClick={() => {
                    toggleUseMyMailPopup({ show: false });
                  }}
                  class="fas fa-2x fa-arrow-left mx-1 icon-color cursor"
                ></i>
                <label className="font-size-16 font-wt-600 text-color-value mx-3">
                  All Transactional mails will be sent from your Email
                </label>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="position-relative">
                {/* <NewSelect
                  optionLabel={"title"}
                  optionValue={"title"}
                  selectData={[{ title: "hostinger" }, { title: "gmail" }, { title: 'outlook' }]}
                  onChange={handleChange}
                  name={"account"}
                  value={data.account}
                  label={"Mail Account"}
                /> */}
                <MultipleSelect
                  singleSelection={true}
                  optiondata={[
                    {
                      name: (
                        <div>
                          <img
                            src="assets/images/hostinger.svg"
                            className="mr-2"
                            style={{ width: "20px", height: "15px" }}
                          />
                          Hostinger
                        </div>
                      ),
                      val: "hostinger",
                    },
                    {
                      name: (
                        <div>
                          <img
                            src="assets/images/gmail.jpg"
                            className="mr-2"
                            style={{ width: "20px", height: "15px" }}
                          />
                          Gmail
                        </div>
                      ),
                      val: "gmail",
                    },
                    {
                      name: (
                        <div>
                          <img
                            src="assets/images/outlook.png"
                            className="mr-2"
                            style={{ width: "20px", height: "15px" }}
                          />
                          Outlook
                        </div>
                      ),
                      val: "outlook",
                    },
                  ]}
                  value={data[`account`] ? [data[`account`]] : []}
                  onChange={(e) => {
                    handleChange(multiSelectEventHandler(e, `account`, "val"));
                  }}
                  name={`account`}
                  labelKey={"name"}
                  valKey={"val"}
                />
              </div>
              <div className="position-relative">
                <NewInput
                  onChange={handleChange}
                  name={"password"}
                  value={data.password}
                  label={"Mail Password"}
                />
              </div>
              <div className="my-2">
                <button
                  type="button"
                  disabled={!(data.account && data.password)}
                  onClick={async () => {
                    setShowLoader(true);
                    try {
                      await call("POST", "saveAdminMailCredentials", {
                        mail: userEmail,
                        account: data.account,
                        password: data.password,
                      });
                      toastDisplay("Details saved successfully", "success");
                      toggleUseMyMailPopup({ show: false });
                    } catch (error) {
                      toastDisplay(
                        "Entered credentials are wrong, please enter correct details",
                        "error"
                      );
                    }
                    setShowLoader(false);
                  }}
                  className={`new-btn w-20 py-2 px-2 text-white cursor`}
                >
                  {"Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`d-flex align-items-center ${isFilter ? "border-bottom mx-1 " : ""
          } breadcrumb-bg sticky-top ${colorchange ? "HeaderScrollbg" : "bg-transparent"
          } `}
        style={{ height: "10vh" }}
      >
        {/* UI RESP CHANGE */}
        <div className="hamburger-menu">
          <img
            src="assets/images/menu.png"
            height={30}
            className="cursor"
            onClick={() => {
              console.log("Hamburger icon");
              setsidebarOpen();
            }}
          />
        </div>

        {/* UI RESP CHANGE */}

        {isFilter ? (
          <div className="d-flex gap-3 flex-row align-items-center">
            {filterSearch ? (
              <div className={`input-group mb-3 col-md-8 currency mt-3 pe-1`}>
                <span
                  className="input-group-text bg-white border-end-0"
                  id="basic-addon1"
                >
                  <img src={"assets/images/fe_search.png"} alt="search" />
                </span>
                <input
                  type="text"
                  name="search"
                  value={""}
                  onKeyDown={() => { }}
                  onChange={() => { }}
                  className="form-control border-start-0"
                  placeholder="Search"
                />
              </div>
            ) : null}
            {downloadOption ? (
              <div onClick={() => { }} className="cursor">
                <img
                  className="cursor"
                  src={"/assets/images/download_text_icon.svg"}
                />
              </div>
            ) : null}
            {tableViewOption ? (
              <div onClick={() => { }} className="cursor">
                <img
                  className="cursor"
                  src={"/assets/images/table_view_icon.svg"}
                />
              </div>
            ) : null}

            {timeFilter ? (
              <>
                <div className="mt-3">
                  <NewInput
                    type={"date"}
                    name={"timeFilterFrom"}
                    value={filterData["timeFilterFrom"]}
                    onChange={(e) => onChangeFilterData(e)}
                  />
                </div>
                <div className="mt-3">
                  <NewInput
                    type={"date"}
                    name={"timeFilterTo"}
                    value={filterData["timeFilterTo"]}
                    onChange={(e) => onChangeFilterData(e)}
                  />
                </div>
                <div className="">
                  <button
                    type="button"
                    onClick={onRefresh}
                    className={`new-btn w-100 py-2 px-3 text-white cursor`}
                  >
                    {"Submit"}
                  </button>
                </div>
              </>
            ) : null}
            {referalCode ? (
              <div
                style={{ paddingTop: "0.4rem", paddingBottom: "0.4rem" }}
                onClick={copyToClipboard}
                className="card px-3 cursor"
              >
                <label className="font-size-14 font-wt-400 m-0 cursor">
                  Referral Code :
                  <label
                    ref={referalCodeText}
                    className="font-size-16 font-wt-500 text-color1 m-0 px-2 cursor"
                  >
                    {userData.refercode || "-"}
                  </label>
                  <img
                    className="ml-2 cursor"
                    src="assets/images/copyIcon.png"
                  />
                </label>
              </div>
            ) : null}
            {/* <CustomSelect
            selectData={[{ name: 'Overall' }, { name: 'Previous Month' }, { name: 'Previous Week' }, { name: 'Yesterday' }, { name: 'Today' }, { name: 'Current Week' }, { name: 'Current Month' }, { name: 'Custom' }]}
            optionLabel={'name'} optionValue={'name'}
            onItemCllick={(e) => {


            }}
            removeMb={true}
            value={'Overall'}
          /> */}
          </div>
        ) : (
          <ul className="breadcrumb-left">
            <li>
              <h2
                className={titleClass}
                onClick={() => {
                  if (onTitleClick) {
                    onTitleClick();
                  }
                }}
              >
                {title}
              </h2>
              <p>{total}</p>
            </li>
          </ul>
        )}

        <div className="ml-auto">
          <ul className="nav-top d-flex flex-md-row align-items-center">
            <li
              className={`helpicon shadow-sm  ${window.location.pathname === "/HelpSupport" && "bg-B9EFFF"
                }`}
              style={
                localStorage.getItem("lcTutorialStep") == 5
                  ? {}
                  : { background: "transparent" }
              }
            >
              <a href={"/HelpSupport"}>
                <img src={"assets/images/header/help_icon.png"} />
              </a>
            </li>
            <div className="dropdown">
              <li
                className={`helpicon shadow-sm ${window.location.pathname === "/AllNotifications" &&
                  "bg-B9EFFF"
                  }`}
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <a>
                  <img src={"assets/images/header/notification_icon.png"} />
                  <span className="task-label"></span>
                </a>
              </li>
              <ul
                className="dropdown-menu border-0 pt-0 borderRadius DropdownHeader"
                aria-labelledby="dropdownMenuButton1"
                style={{
                  width: "400px",
                  boxShadow:
                    "0px 1px 2px rgba(53, 53, 53, 0.5), 0px 4px 4px 2px rgba(226, 226, 226, 0.5)",
                }}
              >
                {notificationdata.result &&
                  notificationdata.result.map((item, index) => {
                    return (
                      <li
                        className="mx-0"
                        onClick={() =>
                          redirectNotification(
                            item.notification_type,
                            item.notification_sub_type,
                            item.refId
                          )
                        }
                      >
                        <div
                          className={`d-flex px-2 py-3 borderEEEEEE border-0 border-bottom align-items-top ${index === 0 ? "firstLi" : ""
                            }`}
                        >
                          {/* <div className='col-2'>
                      <img src={getNotificationIcon(item.notification_type, item.notification_sub_type)} alt="" />
                    </div> */}
                          <div className="col-10">
                            <p className="mt-0 heading">
                              {item.notification_description}
                            </p>
                            <p className="time">{`${moment(
                              item.createdAt
                            ).format("DD/MM/YYYY")} At ${moment(
                              item.createdAt
                            ).format("hh:mm A")}`}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                <li className="mx-0 text-center mt-2 font-size-14 letter-spacing05">
                  <Link
                    to="/AllNotifications"
                    className="text-decoration-none text-color1 mt-3 text-center letter-spacing05"
                    onClick={(e) =>
                      checkPermission(
                        userPermissions,
                        sub_user_id,
                        e,
                        "AllNotifications",
                        toastDisplay,
                        userId,
                        null,
                        userTypeId
                      )
                    }
                  >
                    View all
                  </Link>
                </li>
              </ul>
            </div>

            <li
              style={
                localStorage.getItem("lcTutorialStep") == 6
                  ? {
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "1000px",
                  }
                  : {}
              }
            >
              <div className="dropdown">
                <span
                  className="profile-icon d-flex align-items-center"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={avatarUrl(subUserDetail.subUserAvatar)}
                    alt="Avatar"
                  />
                  <i className="fa-sharp fa-solid fa-chevron-down text-secondary font10 ms-1"></i>
                </span>
                <ul
                  className="dropdown-menu viewprofiledropdown border-0 mt-3 text-center"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li
                    style={{ borderBottom: "1px solid #EEEEEE" }}
                    className={`mx-3 `}
                  >
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="/viewprofile"
                      onClick={(e) => {
                        console.log("userorasddasdas", userTokenDetails);
                        if (userTokenDetails?.isSubUser) {
                          e.preventDefault();
                          setShowLoader(true);
                          call("POST", "getSubAdminUserSummary", { userId })
                            .then((result) => {
                              setShowLoader(false);
                              localStorage.setItem(
                                "subAdminDetails",
                                JSON.stringify(result[0])
                              );
                              window.location = `/SubAdminProfileDetails`;
                            })
                            .catch((e) => {
                              toastDisplay("Profile data not found", "error");
                            });
                        } else {
                          checkPermission(
                            userPermissions,
                            sub_user_id,
                            e,
                            "viewprofile",
                            toastDisplay,
                            userId,
                            null,
                            userTypeId
                          );
                        }
                      }}
                    >
                      <img
                        src="assets/images/profile_icon.png"
                        className="mr-2"
                      />
                      Profile
                    </Link>
                  </li>

                  <li
                    style={{ borderBottom: "1px solid #EEEEEE" }}
                    className="mx-3"
                  >
                    <a
                      className="dropdown-item d-flex align-items-center"
                      // onClick={logout}
                      href="HelpSupport"
                    >
                      <img src="assets/images/help_icon.png" className="mr-2" />{" "}
                      Help
                    </a>
                  </li>

                  <li className="mx-3">
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="settings"
                    >
                      <img
                        src="assets/images/setting_icon.png"
                        className="mr-2"
                      />{" "}
                      Settings
                    </a>
                  </li>

                  <hr className="m-0" />

                  {userTypeId / 1 != 1 ? (
                    <li
                      id="swithcCPBtn"
                      style={{ borderBottom: "1px solid #EEEEEE" }}
                      className="mx-3 "
                    >
                      <a
                        className="dropdown-item d-flex align-items-center"
                        onClick={async () => {
                          if (userIsAlsoCP) {
                            if (userTypeId / 1 != 20) {
                              let cookieFromApp = getUserDataFromCookie();
                              cookieFromApp["type_id"] = 20;
                              setCookieInAPP(cookieFromApp);
                              window.location = "/dashboard";
                            } else {
                              let cookieFromApp = getUserDataFromCookie();
                              cookieFromApp["type_id"] =
                                cookieFromApp["primaryUserTypeId"];
                              setCookieInAPP(cookieFromApp);
                              window.location = "/dashboard";
                            }
                          } else {
                            if (userTypeId / 1 != 20) {
                              call("POST", "isCPAgreementSigned", { userId })
                                .then((yes) => {
                                  toastDisplay(
                                    "Please relogin to access CP profile",
                                    "success",
                                    () => {
                                      logout();
                                    }
                                  );
                                })
                                .catch((no) => {
                                  call(
                                    "POST",
                                    "getChannelPartnerAgreementTemplate",
                                    { role: "CP", type: "5" }
                                  ).then(async (res) => {
                                    if (res?.file_hash) {
                                      await call(
                                        "POST",
                                        "generateCPNetworkRequest",
                                        { userId, userEmail, userName }
                                      );
                                      call("POST", "getSingleUserByReq", {
                                        id: userId,
                                      }).then((result) => {
                                        let dbData = result[0]["userData"];
                                        let reqId = result[0]["id"];
                                        let reqObj = {
                                          techTypeId: dbData.tech_type_id,
                                          baseUrl: config.baseUrl,
                                          status: true,
                                          userData: {
                                            ...dbData,
                                            userId: userId,
                                            email: userEmail,
                                          },
                                          reqId,
                                          agreementFileHash: res?.file_hash,
                                          party_designation: dbData.designation,
                                          company_name: dbData.company_name,
                                          contact_person: dbData.contact_person,
                                          organization_type:
                                            dbData.organization_type,
                                          role: "CP",
                                          pan_no: dbData.pan_no,
                                          aadhar_no: dbData.aadhar_no,
                                          typeId: 20,
                                          country_code: dbData.country_code,
                                          email_id: dbData.email_id,
                                          address: dbData.user_address,
                                        };
                                        call(
                                          "POST",
                                          "submitAgreement",
                                          reqObj
                                        ).then((res) => {
                                          toastDisplay(
                                            "Sign agreement document to become channel partner ",
                                            "success",
                                            () => {
                                              window.location = `channelPartnerAgreement?id=${reqId}`;
                                            }
                                          );
                                        });
                                      });
                                    }
                                  });
                                });
                            } else {
                              toastDisplay(
                                "Kindly signup as company to continue",
                                "info",
                                () => {
                                  logout("/registration");
                                }
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src="assets/images/switch_to_icon.png"
                          className="mr-2"
                        />
                        {userTypeId / 1 != 20
                          ? `Switch to Channel Partner`
                          : `Switch to Company/Business Account`}
                      </a>
                    </li>
                  ) : null}

                  <li
                    style={{ borderBottom: "1px solid #EEEEEE" }}
                    className="mx-3 "
                  >
                    <a
                      className="dropdown-item d-flex align-items-center"
                      onClick={logout}
                    >
                      <img
                        src="assets/images/log_out_icon.png"
                        className="mr-2"
                      />{" "}
                      Log out
                    </a>
                  </li>

                  {userPermissionsForSubAdmin.mainAdmin ||
                    (userPermissionsForSubAdmin["SubAdmin Management"] ===
                      "SubAdmin Management") ? (
                    <li
                      style={{ borderBottom: "1px solid #EEEEEE" }}
                      className="mx-3 "
                    >
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="/SubAdminProfile"
                      >
                        SubAdmin management
                      </Link>
                    </li>
                  ) : null}

                  {/* {userTypeId / 1 == 1 ? ( */}
                  <li
                    style={{ borderBottom: "1px solid #EEEEEE" }}
                    onClick={() => toggleUseMyMailPopup({ show: true })}
                    className="mx-3 dropdown-item d-flex align-items-center"
                  >
                    Use My Mail
                  </li>
                  {/* ) : null} */}

                  {/* <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                </ul>
              </div>
            </li>
          </ul>
        </div>

        {/* {createCredit.modal &&
        <ApplyForCreditLine userTokenDetails={userTokenDetails} createCredit={createCredit} setCreateCredit={setCreateCredit} />
        // </div>
      } */}
      </div>

      {/* UI RESP CHANGE */}
      <ul className="breadcrumb-show">
        <li>
          <h2>{title}</h2>
          <p>{total}</p>
        </li>
      </ul>
      {/* UI RESP CHANGE */}

      {changeFinanceMode.modal && (
        <ChangeFinanceMode
          userTokenDetails={userTokenDetails}
          setchangeFinanceMode={setchangeFinanceMode}
        />
      )}
    </>
  );
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = (state) => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    contractLogi: state.contractLogi,
    contractDocList: state.contractDocList,
    payment: state.payment,
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    ViewTrack: state.ViewTrack,
    InvoiceDetail: state.InvoiceDetail,
    contractDetailLogi: state.contractDetailLogi,
    laytimesheet: state.laytimesheet,
    showAddShip: state.showAddShip,
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    creditLineDetails: state.creditLineDetails,
    CreditDocuments: state.CreditDocuments,
    showModalAddComm: state.showModalAddComm,
    addDirectory: state.addDirectory,
    offContract: state.offContract,
    channelPartnerAccountList: state.channelPartnerAccountList,
    channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice,
    channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    showViewProfileModal: state.showViewProfileModal,
    creditlineDataBank: state.creditlineDataBank,
    creditline: state.creditline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContractDetails: (id) => {
      dispatch(setContractDetails({ cId: id, modal: false }));
    },
    setContractState: (flag, data) => {
      dispatch(setContractState({ modal: flag, info: data }));
    },
    setContractStateLogi: (flag, data) => {
      dispatch(setContractStateLogi({ modal: flag, info: data }));
    },
    setContractDocList: (id, data) => {
      dispatch(setContractDocList({ cId: id, modal: false, info: data }));
    },
    setPayment: (state) => {
      dispatch(setPayment({ state: state }));
    },
    setShowCreateProfileAdminModal: (flag, data) => {
      dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data }));
    },
    setshowModalAddComm: (flag, data) => {
      dispatch(setshowModalAddComm({ modal: flag, info: data }));
    },
    setAddShip: (flag, data) => {
      dispatch(setAddShip({ modal: flag, info: data }));
    },
    setViewTrack: (flag, data) => {
      dispatch(setViewTrack({ modal: flag, info: data }));
    },
    setInvoiceDetail: (flag, data) => {
      dispatch(setInvoiceDetail({ modal: flag, info: data }));
    },
    setContractDetailLogi: (flag, data) => {
      dispatch(setContractDetailLogi({ page: flag, info: data }));
    },
    setLaytimesheet: (state, cNumber) => {
      dispatch(setLaytimesheet({ state: state, cNo: cNumber }));
    },
    setDisbursed: (flag, data) => {
      dispatch(setDisbursed({ modal: flag, info: data }));
    },
    setFinanceDetails: (status, type, page, info) => {
      dispatch(
        setFinanceDetails({
          status: status,
          type: type,
          page: page,
          info: info,
        })
      );
    },
    setAddDirectory: (modal, info) => {
      dispatch(setAddDirectory({ modal: modal, info: info }));
    },
    setRaiseQuotation: (modal, info) => {
      dispatch(setRaiseQuotation({ modal: modal, info: info }));
    },
    setoffContract: (modal, info) => {
      dispatch(setoffContract({ modal: modal, info: info }));
    },
    setChannelPartnerAccountList: (modal, info) => {
      dispatch(setChannelPartnerAccountList({ modal: modal, info: info }));
    },
    setChannelPartnerRaisedInvoice: (modal, info) => {
      dispatch(setChannelPartnerRaisedInvoice({ modal: modal, info: info }));
    },
    setChannelPartnerDisbursedInvoice: (modal, info) => {
      dispatch(setChannelPartnerDisbursedInvoice({ modal: modal, info: info }));
    },
    setShowViewProfileModal: (flag, data) => {
      dispatch(setShowViewProfileModal({ modal: flag, info: data }));
    },
    setCreditlineDataBank: (modal, info) => {
      dispatch(setCreditlineDataBank({ modal: modal, info: info }));
    },
    setcreditLineDetails: (status, info) => {
      dispatch(setcreditLineDetails({ status: status, info: info }));
    },
    setCreditDocuments: (status, info) => {
      dispatch(setCreditDocuments({ status: status, info: info }));
    },
    setCreditline: (modal, info) => {
      dispatch(setCreditline({ modal: modal, info: info }));
    },
    setsidebarOpen: () => {
      dispatch(setSidebarOpen());
    },
  };
};
//---------------------------------------------------------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(HeaderV2);
