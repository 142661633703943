import React from "react";

const EmailModal = ({ limitinvoice, children, closeSuccess }) => {
  return (
    <>
      <div className={`modal fade ${limitinvoice && "show"}`} style={{ display: "block" }}>
        <div className="modal-dialog modal-md">
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header justify-content-end border-0 pb-0">
              <span className="cursor" aria-label="Close" onClick={() => closeSuccess()}><img className='' src={"assets/images/cancel-icon.png"} /></span>
            </div>
            <div className="modal-body text-center py-0">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EmailModal;


