export default function validate(values, type) {
  let errors = {};


  let keysArray = Object.keys(values)

  if (keysArray.length) {
    keysArray.map((key) => {
      if (!values[key].value) {
        errors[key] = values[key].name + " is Mandatory"
      }
      // if (type === "bank") {
      if (!values[key].code) {
        errors[key + "code"] = values[key].name + ", Code is Mandatory (To be Filled by Bank)"
      }
      // }
      return null
    })
  } else {
    errors["formJson"] = "Form Json is Missing"
  }



  console.log(errors);
  return errors;
}
