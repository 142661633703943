export default function validateAddFinance(data) {
  let errors = {};

  if (!data.selectedYear) {
    errors.selectedYear = 'Year is Mandatory';
  }
  if (!data.currencyType) {
    errors.currencyType = 'Currency is Mandatory';
  }
  if (!data.turnover) {
    errors.turnover = 'Turnover is Mandatory';
  }
  if (!data.netprofit) {
    errors.netprofit = 'Net Profit is Mandatory';
  }
  if (!data.networth) {
    errors.networth = 'Net Worth is Mandatory';
  }
  if (!data.AuditDoc) {
    errors.AuditDoc = 'Audit Report document is Mandatory'
  }
  if (!data.GSTDoc) {
    errors.GSTDoc = 'GST document is Mandatory'

  }
  console.log(errors);
  return errors;
}
