import React from 'react'

const TableLegendComponent = ({ legends }) => {
  return (
    <div className="data">
      <div className="data-content">
        {legends.map((item) => {
          return <div className="data-buyer">
            <div className="data-Legend" style={{ backgroundColor: item.color }} />
          </div>
        })}
      </div>
      <div className="data-content">
        {legends.map((item) => {
          return <div className="data-buyer">
            <div className="data-text-wrapper">{item.name}</div>
          </div>
        })}
      </div>
      <div className="data-content">
        {legends.map((item) => {
          return <div className="data-buyer">
            <div className="data-text-wrapper">{item.percent}</div>
          </div>
        })}
      </div>
      <div className="data-content-2">
        {legends.map((item) => {
          return <div className="data-buyer">
            {
              item.unit ?
                <div className="data-text-wrapper">{item.unit + " " + Intl.NumberFormat("en", { notation: 'compact' }).format(item.value)}</div>
                :
                <div className="data-text-wrapper">{item.value}</div>
            }
          </div>
        })}
      </div>
    </div>
  )
}

export default TableLegendComponent
