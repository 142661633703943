

import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import FilePreview from '../utilComponents/previewFile'
import avatarUrl from '../../utils/makeAvatarUrl';
import { Accordion, Card, Button } from 'react-bootstrap'
import { todayDate_DatePicker, formatDate_Application } from '../../utils/dateFormaters';
import AddUserInContract from '../utilComponents/addUserInContract.js'


const InsuranceDetailAccord = ({ userTokenDetails, contractDetails, contractType }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [insurancedata, setinsurancedata] = useState({});
  const [insurerdata, setinsurerdata] = useState({});
  const [insuranceDocdata, setinsuranceDocdata] = useState([]);
  const [data, setdata] = useState({
    insuranceType: (contractType === "commodity" ? 1 : 2),
    contractid: contractDetails.contractId
  });
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);

  const [fileData, setfileData] = useState({})
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [toggle, settoggle] = useState(false)
  const [toggle1, settoggle1] = useState(false)

  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getcontractinsurancestatus', { "contract_number": contractDetails.contractNo, "contract_id": contractDetails.contractId, "insuranceType": (contractType === "commodity" ? 1 : 2) }).then(async (result) => {
      console.log("data of api in getcontractinsurancestatus-->", result)
      setinsurerdata(result.insurerData)
      setinsurancedata(result.insuranceData)
      setinsuranceDocdata(result.insuranceDocData)
      setshowLoader(false)
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in bccontractDetailsShipmenttrail ->", error)
    })
    //------------------------------------------------------------------
  }, [refresh]);


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const handleChange = (event) => {
    setdata({ ...data, [event.target.name]: event.target.value })
    // setError({ ...error, [event.target.name]: "" })
  }

  const handleFiles = (event) => {
    event.persist();
    setdata({ ...data, [event.target.name]: event.target.files[0] });
    // setError({ ...error, [event.target.name]: "" })
  };


  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      console.log("value in doc render=>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.doc_name) ? values.doc_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'Insurance'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmit() {
    setshowLoader(true)

    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })

    call('POST', 'setinsurancedetails', formData).then((result) => {
      console.log('post setinsurancedetails ===== API>', result);
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay("Insurance details Updated successfuly", "success");
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in setinsurancedetails', e);
      toastDisplay(e, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------


  return (<>
    {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

    {(contractDetails.commodity_insurer / 1 === 0 && contractDetails.trade_insurer / 1 === 0) ?
      <>
        <AddUserInContract
          userTokenDetails={userTokenDetails}
          addUserData={{ usertype: 13, name: "Insurance Agency" }}
          contractData={contractDetails}
          refresh={refresh}
          setrefresh={setrefresh}
        /></> :
      <div className="mt-5">
        <div className="col-md-12">
          <ul className="price-ul">
            <li >
              <div className="chat-message">
                <img src={avatarUrl(insurerdata.user_avatar)} alt="Avatar" width="50" height="50" />
              </div>
            </li>
            <li>
              <div className="price-left"> {insurerdata.company_name ? insurerdata.company_name : '--'} </div>
              <div className="price-right"> Insurer Name</div>
            </li>
            <li >
              <div className="price-left"> {insurerdata.user_address ? insurerdata.user_address : '--'}</div>
              <div className="price-right">Insurer Address</div>
            </li>
            <li>
              <div className="price-left">{insurancedata.id ? "Available" : 'Pending'}</div>
              <div className="price-right">Insurance Status</div>
            </li>
            {(insurancedata.id) && <>
              <li>
                <div className="price-left">{insurancedata.insurance_no ? insurancedata.insurance_no : '--'}</div>
                <div className="price-right">Insurance Number</div>
              </li>
              <li>
                <div className="price-left">{insurancedata.created_at ? insurancedata.created_at : '--'}</div>
                <div className="price-right">Initiated at</div>
              </li>
              <li>
                <div className="price-left">{insurancedata.modified_at ? insurancedata.modified_at : '--'}</div>
                <div className="price-right">Last Updated at</div>
              </li>
            </>}
          </ul>
        </div>
        <hr />

        {(userId / 1 === insurerdata.commodity_insurer / 1) && <>
          <Accordion>
            <Card>
              <Card.Header>
                <div className="col-md-12 row">
                  <div className="col-md-11 row">
                    <h3 className="text-primary mt-2">{(insurancedata.id) ? "Update" : "Upload"} Insurance Details</h3>
                  </div>
                  <div className="col-md-1">
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                        {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                      </span>
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <>
                    <div className="col-md-12 row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="">Insurer Name </label>
                          <div className="">
                            <input type="text" name="insurer" value={insurerdata.company_name} className="form-control" disabled required />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="">Insurance No</label>
                          <div className="">
                            <input type="text" name="insuranceNo" value={data.insuranceNo} className="form-control" onChange={handleChange} required />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="">Dated</label>
                          <div className="">
                            <input type="datetime-local" name="insurancedate" value={todayDate_DatePicker()} className="form-control" disabled required />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="">Insurance Document</label>
                          <div className="file-browse col-md-12">
                            <button className="btn btn-primary btn-sm col-md-6">Upload Document</button>
                            <input className="col-md-12" type="file" accept=".png,.jpg,.pdf" name="insuranceDoc" onChange={handleFiles} />
                          </div>
                        </div>
                      </div>
                      {(data.insuranceDoc) &&
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="col-md-12">Uploaded File</label>
                            <div className="file-name">{data.insuranceDoc.name}</div>
                          </div>
                        </div>}
                      {(data.insuranceDoc) &&
                        <div className="col-md-12 text-center">
                          <button className="btn btn-sm btn-success" onClick={() => handleSubmit()}>Submit</button>
                        </div>}
                    </div>
                  </>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <hr />
        </>}



        {(insuranceDocdata.length > 0) ?
          <Accordion>
            <Card>
              <Card.Header>
                <div className="col-md-12 row">
                  <div className="col-md-11 row">
                    <h3 className="text-primary mt-2">Insurance Documents Trail</h3>
                  </div>
                  <div className="col-md-1">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <span className="cursor-pointer ml-5" onClick={() => settoggle1(!toggle1)}>
                        {!toggle1 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                      </span>
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <>
                    <div className="col-md-12">
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th>Type of Document</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(insuranceDocdata && insuranceDocdata.length > 0) && docPreviewGenerator(insuranceDocdata)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion> :
          <>
            <div className="col-md-12 m-3 text-center ">
              <h3 className="text-warning">No action taken by Insurer yet , Operations pending</h3>
            </div>
          </>}

      </div>}

    {showPreviewModal && <FilePreview
      userTokenDetails={userTokenDetails}
      fileData={fileData}
      showPreviewModal={showPreviewModal}
      setshowPreviewModal={setshowPreviewModal} />}
  </>)
}



export default InsuranceDetailAccord
