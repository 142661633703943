import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import call from '../../service'
import { NewTable } from '../../utils/newTable'
import Filter from '../InvoiceDiscounting/components/Filter'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import ExportersTab from '../UserOnboard/ExportersTab'
import UserDetails from '../UserOnboard/UserDetails'
import ChannelPartnerDetails from '../UserOnboard/ChannelPartnerDetails'
import toastDisplay from '../../utils/toastNotification'
import { GetCache, evaluateCPCommissionPercentBasedOnInvoiceAmount, getInitials, insertObjectInArray, printDiv } from '../../utils/myFunctions'
import { NewInput, NewTextArea } from '../../utils/newInput'
import { Area, AreaChart, Bar, BarChart, Funnel, Legend, Line, FunnelChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import PieChartComponent from '../Reports/components/PieChartComponent'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import MultipleSelect from '../../utils/MultipleSelect'

const generateInvoiceLabels = [
  { name: 'Finance Amount', alt: "totalFinanceAmount", unit: 'USD' },
  { name: 'Total TR Earning', alt: "totalCommissionAmount", unit: "USD" },
  { name: 'CP Commission Percentage', alt: "CPCommissionPercentage", unit: "%" },
  { name: 'Total CP Commission Amount', alt: "totalCPCommissionAmount", unit: "USD" }
]

const InvoiceGenerateCP = ({ userTokenDetails, navToggleState, renderAsChildren, hideGraphs, showForThisUser, changedFilterData, setChangedFilterData }) => {

  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const [showLoader, setshowLoader] = useState(false)
  const [invBillfilterCount, setInvBillFilterCount] = useState({})
  const [invBillTableData, setInvBillTableData] = useState([])
  const [invBillData, setInvBillData] = useState([])
  const [graphConfiguration, setGraphConfiguration] = useState({
    commissionFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    commissionTo: todayDateObj.clone().format("YYYY-MM-DD")
  })
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState({ resultPerPage: 10, search: '' })
  const [refresh, setRefresh] = useState(0)
  const [invBillcount, setInvBillCount] = useState(0)
  const [cpList, setCPList] = useState([])
  const [showInvPdf, toggleInvPdf] = useState(false)
  const [graphData, setGraphData] = useState({})
  const [invBillPage, setInvBillPage] = useState(1)
  const [cardTypes, setCardTypes] = useState([
    { type: "Due", alt: "due", arr: [{ name: "Invoice", class: "colorF1C40F font-size-26 font-wt-600", alt: "totalApplicationCount" }, { name: "Amount", class: "colorF1C40F font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] },
    { type: "Over Due", alt: "overdue", arr: [{ name: "Invoice", class: "text-danger font-size-26 font-wt-600", alt: "totalApplicationCount" }, { name: "Amount", class: "text-danger font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] },
    { type: userTypeId / 1 == 20 ? "Received" : "Paid", alt: "paid", arr: [{ name: "Invoice", class: "text2ECC71 font-size-26 font-wt-600", alt: "totalApplicationCount" }, { name: "Amount", class: "text2ECC71 font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] },
    // { type: "Payables", alt: "payables", arr: [{ name: "Invoice", class: "text-custom2 font-size-26 font-wt-600", hide: true }, { name: "Amount", class: "text-custom2 font-size-26 font-wt-600", alt: "totalInvoiceAmount" }] }
  ])


  useEffect(() => {
    if (graphConfiguration.commissionFrom) {
      setshowLoader(true)
      call('POST', 'getCPInvoiceDetails', graphConfiguration).then(res => {
        // console.log("getCPInvoiceDetails=============>", res);
        setshowLoader(false)
        setGraphData({
          ...res, summaryOfInvoiceCommission: generateInvoiceSummary(res)
        })
      })
    }
    call('POST', 'getCPListWithCommission', {}).then(res => { setCPList(res) })
  }, [graphConfiguration])

  console.log("ggggggggggggggggggggggggggggg", graphData);

  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getCPGenerateInvoiceBills', { ...filter, currentPage: invBillPage, onlyShowForUserId: userTypeId / 1 == 20 ? userId : undefined }).then(res => {
      setshowLoader(false)
      setInvBillData(res.data)
      if (userTypeId / 1 == 20) {
        setInvBillTableData(formatInvBillData2(res.data))
      }
      else {
        setInvBillTableData(formatInvBillData(res.data))
      }
      setInvBillCount(res.count)
      setInvBillFilterCount(res.filter)
    }).catch(err => {
      setshowLoader(false)
      console.log("error in getCPGenerateInvoiceBills", err)
    })
  }, [refresh, invBillPage])

  function formatInvBillData2(data) {
    try {
      let tempData = []
      let row = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        row[0] = element.billDate
        row[1] = element.billNo
        row[2] = Intl.NumberFormat("en", { notation: 'compact' }).format(element.details?.commissionPayout) + " USD"
        row[3] = element.details?.CPCommissionPercentage
        row[4] = Intl.NumberFormat("en", { notation: 'compact' }).format(element.details.totalCPCommissionAmount) + " USD"
        row[5] = element.status / 1 == 0 && moment(element.billDate, "YYYY-MM-DD").add(1, "month") < moment() ?
          <button type="button"
            class={`rejected text-white border-0 `}>
            {"Over Due"}
          </button> :
          element.status / 1 == 0 ?
            <button type="button"
              class={`inprogress text-white border-0 `}>
              {"Due"}
            </button> :
            element.status / 1 == 1 ?
              <button type="button"
                class={`approved text-white border-0 `}>
                {userTypeId / 1 == 20 ? "Received" : "Paid"}
              </button> : null
        row[6] = <label
          onClick={() => {
            setGraphConfiguration({ generateInvoiceForId: [element.details.item.lenderId] })
            setGraphData({ summaryOfInvoiceCommission: { [element.details.item.lenderId]: element.details } })
            toggleInvPdf(true)
          }}
          className='cursor text-custom2 font-size-16 font-wt-600 '
        >Invoice Details</label>
        tempData.push(row)
        row = []
      }
      return tempData
    } catch (error) {
      console.log("error in formatInvBillData2", error);
    }
  }

  function formatInvBillData(data) {
    try {
      let tempData = []
      let row = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        row[0] = element.details.item.cpCompanyName
        row[1] = element.billDate
        row[2] = element.billNo
        row[3] = Intl.NumberFormat("en", { notation: 'compact' }).format(element.details.totalCPCommissionAmount) + " USD"
        row[4] = element.status / 1 == 0 && moment(element.billDate, "YYYY-MM-DD").add(1, "month") < moment() ?
          <button type="button"
            class={`rejected text-white border-0 `}>
            {"Over Due"}
          </button> :
          element.status / 1 == 0 ?
            <button type="button"
              class={`inprogress text-white border-0 `}>
              {"Due"}
            </button> :
            element.status / 1 == 1 ?
              <button type="button"
                class={`approved text-white border-0 `}>
                {userTypeId / 1 == 20 ? "Received" : "Paid"}
              </button> : null
        row[5] = <label
          onClick={() => {
            setGraphConfiguration({ generateInvoiceForId: [element.details.item.lenderId] })
            setGraphData({ summaryOfInvoiceCommission: { [element.details.item.lenderId]: element.details } })
            toggleInvPdf(true)
          }}
          className='cursor text-custom2 font-size-16 font-wt-600 '
        >Invoice Details</label>
        tempData.push(row)
        row = []
      }
      return tempData
    } catch (error) {
      console.log("error in formatInvBillData", error);
    }
  }

  // At a time only one CP Invoice can be generated
  function generateInvoiceSummary(resp) {
    if (graphConfiguration?.generateInvoiceForId?.length && resp?.commissionFromCPDbData?.length) {
      let summaryOfInvoiceCommission = {
        "totalFinanceAmount": 0, "totalCommissionPercentage": 0, "totalCommissionAmount": 0,
        "CPCommissionPercentage": 0, "totalCPCommissionAmount": 0
      }
      // summaryOfInvoiceCommission["noOfCPs"] = graphConfiguration?.generateInvoiceForId?.length
      let selectedCPDetails = cpList.filter((i, j) => {
        if (i.id / 1 == graphConfiguration?.generateInvoiceForId[0] / 1) {
          return i
        }
      })[0]

      console.log("selectedCPDetailsselectedCPDetails", selectedCPDetails, resp.commissionFromCPDbData);

      for (let index = 0; index < resp.commissionFromCPDbData.length; index++) {
        const element = resp.commissionFromCPDbData[index];
        let charges = element.invCharges || element.lcCharges
        summaryOfInvoiceCommission["totalFinanceAmount"] += element.contractAmount / 1
        summaryOfInvoiceCommission["totalCommissionPercentage"] += charges.commissionPercentage / 1
        summaryOfInvoiceCommission["totalCommissionAmount"] += (charges.totalCharges * (charges.commissionPercentage / 100))
      }

      summaryOfInvoiceCommission["totalFinanceAmount"] = summaryOfInvoiceCommission["totalFinanceAmount"].toFixed(2)
      summaryOfInvoiceCommission["totalCommissionAmount"] = summaryOfInvoiceCommission["totalCommissionAmount"].toFixed(2)
      summaryOfInvoiceCommission["totalCommissionPercentage"] = (summaryOfInvoiceCommission["totalCommissionPercentage"] / resp.commissionFromCPDbData.length).toFixed(2)

      summaryOfInvoiceCommission["CPCommissionPercentage"] = evaluateCPCommissionPercentBasedOnInvoiceAmount(summaryOfInvoiceCommission["totalFinanceAmount"], selectedCPDetails["commissionCharges"]).toFixed(2)
      summaryOfInvoiceCommission["totalCPCommissionAmount"] = (summaryOfInvoiceCommission["totalCommissionAmount"] * summaryOfInvoiceCommission["CPCommissionPercentage"] / 100).toFixed(2)

      // Creating Invoice data CP wise start
      for (let j = 0; j < resp.commissionFromCPDbData.length; j++) {
        const item = resp.commissionFromCPDbData[j];
        let charges = item.invCharges || item.lcCharges
        let status = item.invCharges ? item.invoiceCommissionStatus : item.lcCommissionStatus

        if (!summaryOfInvoiceCommission[selectedCPDetails["id"]]) {
          summaryOfInvoiceCommission[selectedCPDetails["id"]] = {
            data: [], item, cpId: selectedCPDetails.id, totalCharges: 0, totalChargesCurrency: 'NA',
            totalCommissionPercentage: 0, commissionPayout: 0,
            billNo: "TRINV" + new Date().getTime(), billCreatedAt: moment().format("YYYY-MM-DD"),
            commissionFrom: graphConfiguration.commissionFrom, commissionTo: graphConfiguration.commissionTo,
            "CPCommissionPercentage": summaryOfInvoiceCommission["CPCommissionPercentage"], "totalCPCommissionAmount": summaryOfInvoiceCommission["totalCPCommissionAmount"]
          }
        }

        summaryOfInvoiceCommission[selectedCPDetails["id"]]["data"].push([moment(item.applicationCreatedAt).format("YYYY-MM-DD"), item.sellerName, `${(item.invCharges ? 'Invoice Discounting - ' : 'LC Discounting - ')}${item.applicationNo}`, `${item.invoiceDueDate}`, `${item.contractAmount} USD`,
        (charges.totalCharges).toFixed(2) + " USD",
        charges.commissionPercentage,
        (charges.totalCharges * (charges.commissionPercentage / 100)).toFixed(2) + " USD"])

        summaryOfInvoiceCommission[selectedCPDetails["id"]]["totalCharges"] += charges.totalCharges
        summaryOfInvoiceCommission[selectedCPDetails["id"]]["totalChargesCurrency"] = charges.totalChargesCurrency
        summaryOfInvoiceCommission[selectedCPDetails["id"]]["totalCommissionPercentage"] += charges.commissionPercentage
        summaryOfInvoiceCommission[selectedCPDetails["id"]]["commissionPayout"] += (charges.totalCharges * (charges.commissionPercentage / 100))

      }
      // Creating Invoice data CP wise end
      return summaryOfInvoiceCommission
    }
    else {
      return {}
    }
  }

  async function changeInvoiceBillStatus(indx, markAs) {
    let tempDbData = invBillData[indx]
    setshowLoader(true)
    call('POST', 'changeCPInvoiceBillStatus', {
      markAs, billNo: tempDbData.billNo
    }).then(res => {
      setshowLoader(false)
      setGraphConfiguration({ ...graphConfiguration })
      toastDisplay(res, "success")
      setRefresh(refresh + 1)
    }).catch(err => {
      toastDisplay("Something went wrong", "error")
    })
  }

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="generateInvoiceCP" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={userTypeId / 1 == 20 ? "Payments" : "Invoice > Channel Partner Invoice"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4' style={{}} >
              <div className='row mb-3 d-flex justify-content-around'>
                {cardTypes.map((i, j) => {
                  return (
                    <div className='row w-30 card dashboard-card shadow-sm ml-0 mb-0 pb-0 pt-3'>
                      <label className={'font-size-14 font-wt-600 text-color-label pb-1'}>{i.type}</label>
                      <div className='row'>
                        {i.arr.map((k, l) => {
                          if (!k.hide) {
                            return (
                              <div className=' align-items-center w-50'>
                                <div>
                                  <label className={` ${k.class}`}>
                                    {(l / 1 == 1 ? '$ ' : "") + `${Intl.NumberFormat("en", { notation: 'compact' }).format(invBillfilterCount?.[i.alt]?.[k.alt])}` || "NA"}</label>
                                </div><div>
                                  <label className={'font-size-13 font-wt-600 text-color-value'}>{k.name}</label>
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>

              {graphConfiguration.generateInvoice ? (
                <FinanceInvoiceModal limitinvoice={graphConfiguration.generateInvoice} closeSuccess={() => {
                  // setGraphConfiguration({ ...graphConfiguration, generateInvoice: false })
                  window.location.reload()
                }} headerTitle={`Generating Invoice For ${graphConfiguration?.generateInvoiceForLabel}`}>
                  <div >
                    <div className='row my-3'>
                      <div className='col-md-4'>
                        <MultipleSelect
                          Id="Channel Partner"
                          Label="Channel Partner"
                          optiondata={cpList}
                          onChange={(e) => {
                            let generateInvoiceForLabel = []
                            let generateInvoiceForId = []
                            for (let index = 0; index < e.length; index++) {
                              const element = e[index];
                              generateInvoiceForLabel.push(element.name)
                              generateInvoiceForId.push(element.id)
                            }
                            setGraphConfiguration({ ...graphConfiguration, generateInvoiceForLabel: generateInvoiceForLabel, generateInvoiceForId: generateInvoiceForId })
                          }}
                          value={graphConfiguration.generateInvoiceForId || []}
                          name="generateInvoiceForId"
                          labelKey={"name"}
                          valKey={"id"}
                        />
                      </div>
                      <div className='col-md-4'>
                        <NewInput type={"date"} name={"commissionFrom"} value={graphConfiguration.commissionFrom}
                          onChange={handleGraphConfigurationChange} />
                      </div>

                      <div className='col-md-4'>
                        <NewInput type={"date"} name={"commissionTo"} value={graphConfiguration.commissionTo}
                          onChange={handleGraphConfigurationChange} />
                      </div>
                    </div>
                    <button type="button"
                      onClick={async () => {
                        if (graphConfiguration.generateInvoiceForId?.length) {
                          if (graphData.commissionFromCPDbData?.length) {
                            // show invoice
                            toggleInvPdf(true)
                            console.log("beeeeeeeeeeeeeeeeeeeeeeee", graphData.summaryOfInvoiceCommission);
                            // Save invoice details in backend start
                            setshowLoader(true)
                            try {
                              await call('POST', "generateCPInvoiceForBilling", { invoices: Object.values(graphData.summaryOfInvoiceCommission) })
                            } catch (error) {
                            }
                            setshowLoader(false)
                            // Save invoice details in backend end
                          }
                          else {
                            toastDisplay("data not found to generate invoice", "info")
                          }
                        }
                        else {
                          toastDisplay('Select channel partner', "info")
                        }
                      }}
                      className={` border-0 mb-2 text-white enableQuotebtn`}>
                      {"Generate Invoice"}
                    </button>
                    <div className={"d-flex row justify-content-evenly px-4"} >
                      {generateInvoiceLabels.map((l, m) => {
                        return (
                          <label className="w-50 font-wt-500 font-size-14 text-color-value text-left mt-3" >{`${l.name} - `}<b>{graphData?.summaryOfInvoiceCommission?.[l.alt] || 'NA'} {l.unit}</b></label>
                        )
                      })}
                    </div>
                  </div>
                </FinanceInvoiceModal>
              ) : null}

              {showInvPdf ? (
                <FinanceInvoiceModal modalSize={'xl'} limitinvoice={showInvPdf} closeSuccess={() => {
                  toggleInvPdf(false)
                }}>
                  <>
                    {graphConfiguration.generateInvoiceForId.map((i, j) => {
                      let cpWiseData = graphData.summaryOfInvoiceCommission?.[i]
                      console.log("cpWiseDatacpWiseDatacpWiseData", i, cpWiseData);
                      if (cpWiseData) {
                        return (
                          <>
                            <div id={"printCommissionInvoice" + j} className='px-4'>
                              <div className='d-flex flex-row justify-content-end mb-3'>
                                <button type="button"
                                  onClick={() => printDiv("printCommissionInvoice" + j, `Commission-Invoice-${cpWiseData.item.lenderName}`, [])}
                                  className={` border-0 mb-2 text-white enableQuotebtn`}>
                                  {"Print Invoice"}
                                </button>
                              </div>
                              <div className='d-flex flex-row justify-content-between'>
                                <div>
                                  <img width={"100px"} height={"55px"} src={"assets/images/logo_1.png"} />
                                </div>
                                <div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/call-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>+ 91 84509 69138</label>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/mail-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>info@tradereboot.com</label>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/web-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>www.tradereboot.com</label>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <label className='text-color-value font-size-18 font-wt-600 text-center'>Invoice</label>
                              <div className='d-flex flex-row justify-content-between'>
                                <div>
                                  <label className='text-color-value font-size-12 font-wt-600 w-100 text-left'>To</label>
                                  <label className='text-color-value font-size-14 font-wt-600 w-100 text-left'>{cpWiseData.item.cpCompanyName}</label>
                                  <label className='text-color-value font-size-12 font-wt-600 w-100 text-left'>{cpWiseData.item.cpAddress}</label>
                                </div>
                                <div>
                                  <p className='text-color-value font-size-12 font-wt-600 text-left'>{`Invoice Number: ${cpWiseData.billNo}`}</p>
                                  <p className='text-color-value font-size-12 font-wt-600 text-left'>{`Date: ${cpWiseData.billCreatedAt}`}</p>
                                </div>
                              </div>
                              <label className='text-color-value font-size-14 font-wt-600 text-center my-4'>{`Commission payout from ${cpWiseData.commissionFrom} - ${cpWiseData.commissionTo}`}</label>
                              <div>
                                <NewTable
                                  disableAction={true}
                                  data={cpWiseData.data}
                                  columns={[{ name: "Application Date" }, { name: "Exporter Name" }, { name: "Application Type & No." }, { name: "Due Date" }, { name: "Invoice Amount" }, { name: "Charges Amount Leived by Financier" },
                                  { name: "TR Commission (%)" }, { name: "TR Commission Amount" }]} />
                                <div className='d-flex flex-row justify-content-end'>
                                  <table className='border'>
                                    <tbody className='border'>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>TR Total Earning</th>
                                        <td className='font-size-14 font-wt-600'>{`${(cpWiseData.commissionPayout).toFixed(2)} USD`}</td>
                                      </tr>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>CP Commission Percentage (%)</th>
                                        <td className='font-size-14 font-wt-600'>{`${cpWiseData.CPCommissionPercentage}`}</td>
                                      </tr>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>CP Total Payable</th>
                                        <td className='font-size-14 font-wt-600'>{`${(cpWiseData.totalCPCommissionAmount)} USD`}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {!showInvPdf ? (
                                <Pagination perPage={filter.resultPerPage || 10} page={invBillPage} totalCount={invBillcount} onPageChange={(p) => setInvBillPage(p)} />) : null}
                            </div>
                            <div className='my-5'>
                              <hr />
                              <hr />
                            </div>
                          </>
                        )
                      }
                    })}
                  </>
                </FinanceInvoiceModal>
              ) : null}

              <div className='filter-div'>
                <Filter showDownloadIcon={false} isAdditionalButton={true}
                  // onDownloadClick={downloadCSVFile}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={invBillcount} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} >
                  {userTypeId / 1 == 20 ? null :
                    <div className="d-flex ml-3">
                      <button type="button"
                        onClick={() => {
                          setGraphConfiguration({ ...graphConfiguration, generateInvoice: true })
                        }}
                        className={` border-0 mb-2 text-white enableQuotebtn`}>
                        {"Generate Invoice"}
                      </button>
                    </div>}
                </Filter>
              </div>

              <div
                style={{}}>
                {userTypeId / 1 == 20 ? <NewTable disableAction={userTypeId / 1 == 20}
                  columns={[{
                    name: "Invoice Date", filter: false
                  },
                  {
                    name: "Invoice No", filter: false
                  }, {
                    name: "TR Earnings", filter: false
                  }, {
                    name: "Commission Percentage", filter: false
                  },
                  {
                    name: "Invoice Amount", filter: false
                  },
                  {
                    name: "Status", filter: false
                  },
                  {
                    name: "", filter: false
                  },
                  {
                    name: "", filter: false
                  }
                  ]}
                  data={invBillTableData}
                /> :
                  <NewTable disableAction={userTypeId / 1 == 20}
                    columns={[{
                      name: "CP Name", filter: false
                    }, {
                      name: "Invoice Date", filter: false
                    },
                    {
                      name: "Invoice No", filter: false
                    },
                    {
                      name: "Invoice Amount", filter: false
                    },
                    {
                      name: "Status", filter: false
                    },
                    {
                      name: "", filter: false
                    },
                    {
                      name: "", filter: false
                    }
                    ]}
                    data={invBillTableData}
                    options={[
                      {
                        name: "Delete Invoice", onClick: async (indx) => {
                          let tempDbData = invBillData[indx]
                          setshowLoader(true)
                          let res = await call('POST', "deleteCPInvoiceBill", { billNo: tempDbData.billNo })
                          toastDisplay(res, "success")
                          setshowLoader(false)
                          setRefresh(refresh + 1)
                        }
                      },
                      { name: "Mark as Paid", onClick: (indx) => changeInvoiceBillStatus(indx, 1) },
                      { name: "Mark as Due", onClick: (indx) => changeInvoiceBillStatus(indx, 0) }]}
                  />}
              </div>

            </div>
          </main>
        </div>
      </div>
    </>)


}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}
export default connect(mapStateToProps, null)(InvoiceGenerateCP) 