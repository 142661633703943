

import React, { useEffect, useState } from 'react';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';
import FilePreview from '../../utilComponents/previewFile'
import { formatDate_Application, formatDateTime_Application } from '../../../utils/dateFormaters';
import { Accordion, Card, Button } from 'react-bootstrap'
import BankReimburseForm from '../ttPaymentProcess/bankReimburseForm.js'
import avatarUrl from '../../../utils/makeAvatarUrl';
import AddUserInContract from '../../utilComponents/addUserInContract.js'

const TTPaymentProcess = ({ userTokenDetails, contractNo, contractData }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [contractTTData, setcontractTTData] = useState({});
  const [bankProfileData, setbankProfileData] = useState({});
  const [data, setdata] = useState({
    'uploadLC': false,
  });
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [formPreviewModal, setformPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})
  const [formJson, setformJson] = useState({})
  const [formJsonOrder, setformJsonOrder] = useState([])

  const [toggle, settoggle] = useState(false)
  const [toggle1, settoggle1] = useState(false)
  const [toggle2, settoggle2] = useState(false)

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getcontractttdetails', { "contract_number": contractNo }).then(async (result) => {
      console.log("data of api in getcontractttdetails-->", result)

      setcontractTTData(result.contractData)
      setbankProfileData(result.banksProfileData)
      setshowLoader(false)


    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in getcontractttdetails ->", error)
    })
    //------------------------------------------------------------------
  }, [refresh]);


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
  };


  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler


  function notifyUserManual(sendToUsers) {
    setshowLoader(true)

    let postObj = {
      contractNumber: contractNo,
      sendToUsers: sendToUsers,
      message: "From " + userName + " ," + data.comment
    }

    call('POST', 'manualnotifyuser', postObj).then(async (result) => {
      setshowLoader(false)
      setdata(data => ({ ...data, "comment": "" }));
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in manualnotifyuser ->", error)
      toastDisplay(error, "error")
    })
  }




  function openDOCPreview(id) {

    let fileOBJ = {
      "action": 'view',
      "tbl_doc_id": id
    }

    setfileData(fileOBJ)
    setshowPreviewModal(true)
  }


  function submitTTprocessData() {
    setshowLoader(true)
    let postObj = { ...data, "contract_number": contractNo }

    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    call('POST', 'raisettpaymentrequest', formData).then(async (result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in raisedprequest ->", error)
      toastDisplay(error, "error")
    })
  }



  //---------------------------------------------------------------------------------------------------------------------


  return (<>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

    {(contractData.supplier_bank / 1 === 0 && contractData.buyer_bank / 1 === 0) ?
      <>
        <AddUserInContract
          userTokenDetails={userTokenDetails}
          addUserData={{ usertype: 8, name: "Bank" }}
          contractData={contractData}
          refresh={refresh}
          setrefresh={setrefresh}
        />
      </> :
      <div className="mt-5">


        {/* banker's profile display start */}
        <div className="col-md-12">
          <div className="row bg-shadow bg-white" style={{ "min-height": "200px" }} >
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="chat-message pl-2">
                  <img src={avatarUrl(bankProfileData.buyer_bank_avatar)} alt="Avatar" width="32" height="32" />
                  <div className="chat-message-content clearfix pt-2">
                    <span className="date-format">  {bankProfileData.buyer_bank_name ? bankProfileData.buyer_bank_name : '--'}</span><br />
                  </div>
                  <p className="date-format p-0 m-0">{bankProfileData.buyer_bank_addr ? bankProfileData.buyer_bank_addr : '--'}</p>
                </div>
              </div>
              <div className="col-md-12">
                <label>{bankProfileData.buyer_ac_no ? bankProfileData.buyer_ac_no : '--'}</label>
                <div className="date-format">Importer Bank Account No</div>
              </div>
            </div>
            <div className="col-md-6 border-left">
              <div className="col-md-12">
                <div className="chat-message pl-2">
                  <img src={avatarUrl(bankProfileData.seller_bank_avatar)} alt="Avatar" width="32" height="32" />
                  <div className="chat-message-content clearfix pt-2">
                    <span className="date-format">  {bankProfileData.seller_bank_name ? bankProfileData.seller_bank_name : '--'}</span><br />
                  </div>
                  <p className="date-format p-0 m-0">{bankProfileData.seller_bank_addr ? bankProfileData.seller_bank_addr : '--'}</p>
                </div>
              </div>
              <div className="col-md-12">
                <label>{bankProfileData.seller_ac_no ? bankProfileData.seller_ac_no : '--'}</label>
                <div className="date-format">Exporter Bank Account No</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* banker's profile display end */}

        {(contractTTData.txn_status / 1 > 0) &&
          <>
            <div className="col-md-12">
              <ul className="price-ul">
                <li>
                  <div className="price-left">{contractTTData.created_at ? formatDateTime_Application(contractTTData.created_at) : '--'}</div>
                  <div className="price-right">Process Initiated at</div>
                </li>
                <li>
                  <div className="price-left"> {contractTTData.modified_at ? formatDateTime_Application(contractTTData.modified_at) : '--'}</div>
                  <div className="price-right">Process Updated at</div>
                </li>
              </ul>
            </div>
            <hr />
          </>}



        {(contractTTData.txn_status / 1 > 1) &&
          <>
            <div className="col-md-12">
              <h3 className="text-success">Payment Details</h3>
              <ul className="price-ul">
                <li>
                  <div className="price-left">{contractTTData.txn_id ? contractTTData.txn_id : '--'}</div>
                  <div className="price-right">Transaction ID </div>
                </li>
                <li>
                  <div className="price-left"> {contractTTData.txn_amount ? contractTTData.txn_amount : '--'} </div>
                  <div className="price-right"> Transaction Amount</div>
                </li>
                <li>
                  <div className="price-left"> {contractTTData.txn_date ? contractTTData.txn_date : '--'}</div>
                  <div className="price-right">Transaction Date/Time</div>
                </li>
                <li>
                  <div className="price-left"> <button type="button" className="btn btn-sm btn-link" onClick={() => openDOCPreview(contractTTData.txn_doc)}>Open Document</button></div>
                  <div className="price-right">View Payment TXN Doc</div>
                </li>
              </ul>
            </div>
          </>}

        {userId === contractData.importer_id / 1 && !contractTTData.txn_status && !showLoader ?
          <div className="">
            <div className="col-md-12">
              <h3 className="text-primary mt-2">Instruct Bank for Payment Initiation</h3>
              <hr />
            </div>

            <div className="col-md-12">
              <div className="form-check pl-0 mt-1 mb-1">
                <input className="form-check-input" type="checkbox" name="agreeTTPayment" id="agreeTTPayment" onChange={(event) => {
                  event.persist();
                  setdata(data => ({ ...data, "agreeTTPayment": event.target.checked }));
                }
                } checked={data.agreeTTPayment} />
                <label className="form-check-label" for="agreeTTPayment">
                  I notify my bank that i have received the goods and now bank can release the payment.
                </label>
              </div>
            </div>

            {(data.agreeTTPayment) && <div className="col-md-6 mt-3">
              <button type="button" className="btn btn-success btn-sm" onClick={() => submitTTprocessData()}>
                Submit details
              </button>
            </div>}
          </div> : (!contractTTData.txn_status && !showLoader) ? <><h3 className="text-warning">Process not Initiated by buyer yet</h3> </> : ""}

        {userId === contractData.buyer_bank / 1 && contractTTData.txn_status / 1 === 1 ?
          <Accordion>
            <Card>
              <Card.Header>
                <div className="col-md-12 row">
                  <div className="col-md-11 row">
                    <h3 className="text-primary mt-2">Provide LC Reimbursement Details</h3>
                  </div>
                  <div className="col-md-1">
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <span className="cursor-pointer ml-5" onClick={() => settoggle2(!toggle2)}>
                        {!toggle2 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                      </span>
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <BankReimburseForm
                    userTokenDetails={userTokenDetails}
                    contractData={{
                      id: contractTTData.contract_id,
                      contractNo: contractNo,
                      exporter_id: contractData.importer_id,
                      importer_id: contractData.supplier_bank,
                      buyer_bank: contractData.buyer_bank,
                      supplier_bank: contractData.exporter_id,
                      paymentType: "TT"
                    }}
                    setshowLoader={setshowLoader}
                    refresh={refresh}
                    setrefresh={setrefresh}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion> : (contractTTData.txn_status / 1 === 1 && !showLoader) ? <><h3 className="text-warning">Waiting for buyer bank to release the payment</h3> </> : ""}



        {/* notify module start */}
        <>
          <hr />
          <div className="col-md-12 mt-4 row">
            <div className="col-md-8">
              <h3 className="text-primary">Notify other party about update</h3>
            </div>
            <div className="col-md-4 row">
              <lable className="col-md-6"> Select party</lable>
              <select className="form-control col-md-6 mb-2" name="notifyParty" value={data.notifyParty} onChange={handleChange}>
                <option value="0" selected>-Select Party-</option>
                {(userId / 1 !== contractData.exporter_id / 1) && <option value={contractData.exporter_id} >Exporter</option>}
                {(userId / 1 !== contractData.importer_id / 1) && <option value={contractData.importer_id} >Importer</option>}
                {(userId / 1 !== contractData.supplier_bank / 1) && <option value={contractData.supplier_bank} >Exporter Bank</option>}
                {(userId / 1 !== contractData.buyer_bank / 1) && <option value={contractData.buyer_bank} >Importer Bank</option>}
              </select>
            </div>
            <div className="col-md-12">
              <textarea rows="2" maxlength="200" className="form-control col-md-12" placeholder="Enter Message" name="comment" onChange={handleChange} required></textarea>
              {(data.notifyParty) && <button type="button" className="btn btn-success btn-sm mt-2 mb-2" onClick={() => notifyUserManual(data.notifyParty)}>Send</button>}
            </div>
          </div>
        </>
        {/* notify module ends */}




      </div>}

    {
      showPreviewModal &&
      <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal} />
    }
  </>)
}


export default TTPaymentProcess
