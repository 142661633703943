import React from "react";
import PieChartComponent from "./PieChartComponent";

const productsColor = ['#8B1170', '#C134A2', '#E96DCD', '#FF8BE6', '#FFC0F1']
const lanesColor = ['#8B4C11', '#A8672B', '#CD8F55', '#ECB27D', '#F4D0AE']
const shipmentsFrom = ['#11708B', '#1B94B7', '#4DBFE0', '#76DDFB', '#AFEDFF']
const shipmentsTo = ['#118B49', '#27B96A', '#59DC95', '#7AF5B3', '#A6FFCF']

const GraphCards = ({ shipmentsport, shipmentsoriginports, shipmentsLane, shipmentsproducts }) => {
  return (
    <>
      <div className="row my-3">
        <div className="col-md-6 my-3">
          <div className="card boxshadowgraphs border-0 borderRadius p-4 me-3">
            <div className="d-flex gap-3 h-100">
              <div className="col-md-6 ">
                <PieChartComponent data={shipmentsproducts} dataKey="total_count" label1={"Top"} label2={"Products"} colors={productsColor} />
              </div>
              <div className="d-flex flex-column justify-content-center col-md-6 mt-3">
                {shipmentsproducts.map((item, index) => {
                  return <p className=" letter-spacing05 font-wt-300 font-size-12 mb-4" ><span className="Financelimitapplied me-2" style={{ backgroundColor: productsColor[index] }}></span>{item.product}</p>
                })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-3">
          <div className="card boxshadowgraphs border-0 borderRadius p-4 ms-3">
            <div className="d-flex gap-3 h-100">
              <div className="col-md-6">
                <PieChartComponent data={shipmentsLane} dataKey="port_count" label1={"Top"} label2={"Lanes Used"} colors={lanesColor} />
              </div>
              <div className="d-flex flex-column justify-content-center col-md-6 mt-3">
                {shipmentsLane.map((item, index) => {
                  return <p className=" letter-spacing05 font-wt-300 font-size-12 mb-4" ><span className="Financelimitapplied me-2" style={{ backgroundColor: lanesColor[index] }}></span>{item.INDIAN_PORT + " > " + item.DESTINATION_PORT}</p>
                })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-3">
          <div className="card boxshadowgraphs border-0 borderRadius p-4 ms-3">
            <div className="d-flex gap-3 h-100">
              <div className="col-md-6">
                <PieChartComponent data={shipmentsoriginports} dataKey={"port_count"} label1={"Top shipments"} label2={"port From"} colors={shipmentsFrom} />

              </div>
              <div className="d-flex flex-column justify-content-center col-md-6 mt-3">
                {shipmentsoriginports.map((item, index) => {
                  return <p className=" letter-spacing05 font-wt-300 font-size-12 mb-4" ><span className="Financelimitapplied me-2" style={{ backgroundColor: shipmentsFrom[index] }}></span>{item.INDIAN_PORT}</p>
                })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-3">
          <div className="card boxshadowgraphs border-0 borderRadius p-4 me-3">
            <div className="d-flex gap-3 h-100">
              <div className="col-md-6">
                <PieChartComponent data={shipmentsport} dataKey={"port_count"} label1={"Top shipment"} label2={"port To"} colors={shipmentsTo} />
              </div>
              <div className="d-flex flex-column justify-content-center col-md-6 mt-3">
                {shipmentsport.map((item, index) => {
                  return <p className=" letter-spacing05 font-wt-300 font-size-12 mb-4" ><span className="Financelimitapplied me-2" style={{ backgroundColor: shipmentsTo[index] }}></span>{item.DESTINATION_PORT}</p>
                })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GraphCards;