import React, { useRef, useState, useEffect, useCallback } from 'react';
import { createRef } from 'react';

const DropdownMenu = ({ title, values, isActive, popupWidthInRem, navIndex, containerStyle }) => {

  popupWidthInRem = popupWidthInRem || 11

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const boxRef = createRef(null);
  const boxRef2 = createRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [boxRef]
  );

  const handleClickOutsideBoxRef2 = useCallback(
    (event) => {
      if (boxRef2.current && !boxRef2.current.contains(event.target)) {
        setIsOpen2(false);
      }
    },
    [boxRef2]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutsideBoxRef2);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideBoxRef2);
    };
  }, [handleClickOutside]);
  return (
    <div style={containerStyle} className="position-relative">
      <div onClick={toggleDropdown} className="cursor navPopupLabel d-flex justify-content-between align-items-center">
        <label className={`mx-2 cursor ${isActive ? ' navLinkActive ' : ' navLinkInActive '} mb-0`}>{title}</label>
        <img className="cursor" src="assets/images/header/down_arrow.png" />
      </div>
      <div
        ref={boxRef}
        style={{ width: popupWidthInRem + "rem" }}
        className={`navPopup ${isOpen ? 'd-block' : 'd-none'}`}
      >
        {values.map((i, j) => {
          return (
            <div
              key={j}
              onClick={() => {
                if (i.Url) {
                  window.location = i.Url
                }
                if (i.action) {
                  i.action()
                }
                setIsOpen(false)
              }}
              className={`d-flex py-2 align-items-center px-2 ${isOpen2 === j ? ' selectedMenu ' : ''}`}
            >
              <label
                onMouseEnter={() => {
                  setIsOpen2(j)
                }}
                onMouseLeave={() => {
                  if (!isNaN(isOpen2) && !i.branchedMenus?.length) {
                    setIsOpen2(false)
                  }
                }}
                className="cursor navPopupLabel text-center w-100 mb-0">
                {i.name}
              </label>
              {i.branchedMenus?.length ? (
                <>
                  <img
                    style={{ transform: "rotate(-90deg)" }}
                    width={18} height={10} className="cursor" src="assets/images/header/down_arrow.png" />
                  <div
                    ref={boxRef2}
                    style={{ width: popupWidthInRem + "rem", left: popupWidthInRem + "rem", marginTop: `${(isOpen2 * 2) + 1}rem` }}
                    className={`navPopup2 ${isOpen2 === j ? 'd-block' : 'd-none'}`}
                  >
                    {i.branchedMenus.map((l, m) => {
                      return (
                        <div
                          key={j}
                          onClick={() => {
                            if (l.Url) {
                              window.location = l.Url
                            }
                            if (l.action) {
                              l.action()
                            }
                            setIsOpen(false)
                          }}
                          className={`d-flex py-2 align-items-center px-3 ${isOpen3 === m ? ' selectedMenu ' : ''} `}
                        >
                          <label
                            onMouseEnter={() => {
                              setIsOpen3(m)
                            }}
                            onMouseLeave={() => {
                              if (!isNaN(isOpen3)) {
                                setIsOpen3(false)
                              }
                            }}
                            className="cursor navPopupLabel text-center w-100 mb-0">
                            {l.name}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownMenu;
