import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { astrix, financialYearMonths, getCO2EmissionTotal, getDocDetails, getTotalLogisticAmount, handleFileCommonFunction, handleMultipleFile, mergeMultiplePdfs, most_used_currencies, multiSelectEventHandler } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import NewRegistration, { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import Papa from 'papaparse';
import MultipleSelect from "../../utils/MultipleSelect";
import { getTraderType, setTraderType } from "../../utils/cookieHelper";
import { IncoTerms } from "../UserOnboard/BuyersTab";
import ReactCountryFlag from "react-country-flag";
import MultipleSelectForTable from "../../utils/MultipleSelectForTable";
import { DeliveryMode, airTransportation, cargoType, landTransportation, seaTransportation, shipContainerType, shipType, truckType, uldType, unitSystem, wagonType } from "./ApplyForBooking";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";

let shipmentBookingQsts = [
  { name: 'Do You Need Customs Clearance?' },
  { name: 'Do You Need Insurance?' },
  { name: 'Do You Need Pre Shipment Inspection Service?' },
  { name: 'Do You Need to Defer Your Cargo Payment?' },
  {
    name: 'Do You Want to Offset Your Carbon Footprint?'
  }
]

let paymentTypes = [{ name: 'Pay With Invoice', img: 'assets/images/payWithInvoice.svg' },
{ name: 'Pay With Razorpay', img: 'assets/images/payWithCreditCard.svg' }]

const RaiseQuoteSeaRates = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let applicationNo = queryParams.get('applicationNo')
  let ShipmentQuoteDetails = JSON.parse(localStorage.getItem('ShipmentQuoteDetails') || "{}")

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  let stops = [{ distInPer: '100%' }, { distInPer: '0%' }]

  let containerTypes = [{ name: '20’ Standard' }, { name: '40’ Standard' }, { name: '40’ High Cube' }, { name: '45’ High Cube' }
    , { name: '20’ Refrigerated' }, { name: '40’ Refrigerated' }]

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
  })
  const [errors, setErrors] = useState({})
  const [commList, setCommList] = useState([])
  const [ports, setPorts] = useState([])
  const [countryData, setcountryData] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [ShipperList, setShipperList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})

  const [shipmentQuoteData, setShipmentQuoteData] = useState([])
  const [contractList, setContractList] = useState([])

  const [quoteArr, setQuoteArr] = useState([null, null, null, null, null, null, null])
  const [viewMore, toggleViewMore] = useState(null)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [bookingPopupForm, toggleBookingPopupForm] = useState({ show: false, data: {} })

  async function loadShipperQuotes(shipperId) {
    let shipperQuotesObj = JSON.parse(ShipmentQuoteDetails.shipperQuotes || "[]")
    shipperQuotesObj = shipperQuotesObj.find((i) => {
      if (i.shipper_id / 1 == shipperId / 1) {
        return true
      }
    }) || {}
    let shipperQuotes = shipperQuotesObj?.["charges"]
    let chargesType = Object.keys(shipperQuotes || {})
    let tempData = {}
    let tempQuoteArr = []
    for (let index = 0; index < chargesType.length; index++) {
      for (let j = 0; j < shipperQuotes[chargesType[index]].length; j++) {
        const element = shipperQuotes[chargesType[index]][j];
        tempData[`chargeType${tempQuoteArr.length}`] = chargesType[index]
        tempData[`chargeName${tempQuoteArr.length}`] = element.name
        tempData[`chargeTime${tempQuoteArr.length}`] = element.time
        tempData[`chargeDistance${tempQuoteArr.length}`] = element.distance
        tempData[`chargeAmount${tempQuoteArr.length}`] = element.amount
        tempData[`chargeAmountUnit${tempQuoteArr.length}`] = element.currency
        tempData[`chargeco2${tempQuoteArr.length}`] = element.co2
        tempQuoteArr.push(null)
      }
    }
    tempData["offerValidTill"] = shipperQuotesObj["offerValidTill"]
    tempData["oceanFreightDays"] = shipperQuotesObj["oceanFreightDays"]
    tempData["molType"] = shipperQuotesObj["molType"]
    tempData["quantityVaryIndexPercentage"] = shipperQuotesObj["quantityVaryIndexPercentage"]
    tempData["loadingType"] = shipperQuotesObj["loadingType"]
    tempData["unloadingType"] = shipperQuotesObj["unloadingType"]
    if (tempQuoteArr.length) {
      setData({ ...data, ...tempData })
      setQuoteArr([...tempQuoteArr])
    }
  }

  useEffect(() => {
    if (applicationNo) {
      setRedirectedApplication()
    }
    getContractList()
  }, [])

  async function getContractList() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getContracts', {
      "currentPage": 1,
      "resultPerPage": 1000,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    })
    // console.log("apiResppppppppppppppppppppppppppppppppp", apiResp);
    if (apiResp?.contractdata?.length) {
      setContractList(apiResp?.contractdata)
    }

    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })
    setshowLoader(false)
  }

  async function setRedirectedApplication() {
    setshowLoader(true)
    let tempShipmentQuoteData = JSON.parse(ShipmentQuoteDetails?.shipperQuotes || "[]")
    let shipperApiResp = await call('POST', "getShippers", {})

    for (let index = 0; index < tempShipmentQuoteData.length; index++) {
      const element = tempShipmentQuoteData[index];
      let tempShipperDetails = shipperApiResp.find(i => {
        if (i.id / 1 == element.shipper_id / 1) {
          return true
        }
      })
      element["logo"] = tempShipperDetails?.["user_avatar"] || null
      element["name"] = tempShipperDetails?.["name"] || null
    }

    setShipmentQuoteData([...tempShipmentQuoteData])
    setData({
      ...data,
      ...ShipmentQuoteDetails.details
    })
    setshowLoader(false)
  }

  console.log("shipmentquotedataaaaaaaaa", shipmentQuoteData);

  useEffect(() => {
    getPorts()
  }, [])

  async function getPorts() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getOldPortMaster', {})
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa", apiResp);
    let tempPorts = apiResp
    for (let index = 0; index < tempPorts.length; index++) {
      tempPorts[index][`html`] = <div>
        <ReactCountryFlag
          countryCode={tempPorts[index].sortname}
          style={{ width: '20px', height: '20px', marginRight: '1rem' }} svg />
        <label className="font-size-12 font-wt-500" >{`${tempPorts[index]["port_name"]} ${tempPorts[index]["name"] ? `(${tempPorts[index]["name"]})` : ''}`}
        </label>
      </div>
    }
    setshowLoader(false)
    setPorts([...tempPorts])
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredPorts = []
    if (name.includes("Port")) {
      if (typedInput) {
        filteredPorts = ports.filter((i) => {
          if (i.port_name.toLowerCase().includes(typedInput.toLowerCase()) || i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredPorts = ports
      }
      setPorts([...filteredPorts])
    }
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  console.log(data, "dataaaaaaaaa")

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('shipmentBookingId', selectedChat.shipmentBookingId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    formdata.append("traderActiveType", selectedChat.traderActiveType)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const onPurchasePlan = async (plan_id, plan_amount_usd, currency, shipper_id, booking_data) => {
    try {
      let reqObj = {
        plan_id,
        plan_amount_usd,
        userId,
        currency,
        forShipping: true,
        shipper_id,
        booking_data,
        userEmail
      }
      const result = await call('POST', booking_data.payemntType === "Pay With Invoice" ? 'sendDraftInvoice' : 'createPaymentlink', reqObj)
      if (booking_data.payemntType != "Pay With Invoice") {
        window.location = `${result}&forShipping=true`
      }
      else {
        toggleBookingPopupForm({ show: false, data: {} })
        toastDisplay("Request raised successfully, kindly check your mail", "success")
      }
    } catch (e) {
      console.log('error in createPaymentlink', e)
    }
  }

  return (
    <div>

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            forShipmentBooking={true}
            clientType={selectedChat.traderActiveType}
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }

      {bookingPopupForm.show ? (
        <FinanceInvoiceModal modalSize={"lg"} limitinvoice={bookingPopupForm.show} closeSuccess={() => {
          toggleBookingPopupForm({ show: false, data: {} })
        }}>
          <div>
            <label className='font-size-18 font-wt-600 w-100 text-left px-3 mb-4' ><u>Shipment Information</u></label>
            <div className='px-3' >
              <div
                className="d-flex row align-items-center"
              >
                <div className="col-4" >
                  <div>
                    <label
                      className="font-size-14 font-wt-600 textColorABABAB w-100"
                    >Origin Port</label>
                    <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{ports.find(i => {
                      if (i.port_name === data.loadingPort) { return true }
                    })?.['html']}</label>
                  </div>
                </div>
                <div className="col-4">
                  <div>
                    <label
                      className="font-size-14 font-wt-600 textColorABABAB w-100"
                    >Destination Port</label>
                    <label className="font-size-14 font-wt-600 textColorABABAB w-100" >{ports.find(i => {
                      if (i.port_name === data.unloadingPort) { return true }
                    })?.['html']}</label>
                  </div>
                </div>
                <div className="col-4">
                  <label
                    className="font-size-14 font-wt-600 textColorABABAB w-100"
                  >Delivery Mode</label>
                  <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{data.deliveryMode}</label>
                </div>
              </div>
              <div
                className="d-flex row align-items-center mt-4"
              >
                <div className="col-4" >
                  <label
                    className="font-size-14 font-wt-600 textColorABABAB w-100"
                  >Ready To Load</label>
                  <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{data.readyToLoad}</label>
                </div>
                <div className="col-4">
                  <label
                    className="font-size-14 font-wt-600 textColorABABAB w-100"
                  >Transportation By</label>
                  <label className="font-size-14 font-wt-600 textColorABABAB w-100" >{data.transportationBy}</label>
                </div>
                {data.containerTruckWagonUldType && data.transportationBy && (data.transportationBy.includes('FCL') || data.transportationBy.includes('FTL') ||
                  data.transportationBy.includes('FWL') || data.transportationBy.includes('ULD')) ?
                  <div className="col-4" >
                    <label
                      className="font-size-14 font-wt-600 textColorABABAB w-100"
                    >{data.transportationBy.includes('FCL') ? 'Container Type' :
                      data.transportationBy.includes('FTL') ? 'Truck Type' : data.transportationBy.includes('FWL') ? 'Wagon Type' : 'ULD Container Type'}</label>
                    <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{data.containerTruckWagonUldType}</label>
                  </div> : null}
              </div>
            </div>
            <div className="my-5 px-3" >
              {shipmentBookingQsts.map((i, j) => {
                return (
                  <div style={{ height: '4.5rem' }} className="card d-flex flex-row m-0 p-0 align-items-center mt-4" >
                    <div className="w-10" >
                      <img src={`assets/images/ShipmentBookingQst${j + 1}.svg`} />
                    </div>
                    <label className="font-size-16 font-wt-500 w-60" >{i.name}</label>
                    <div className="d-flex align-items-center w-30 justify-content-center" >
                      <img
                        onClick={() => {
                          toggleBookingPopupForm({
                            show: true, data: { ...bookingPopupForm.data, [i.name]: true }, i: bookingPopupForm.i,
                            item: bookingPopupForm.item, totalChargesLabel: bookingPopupForm.totalChargesLabel
                          })
                        }}
                        className="cursor" src={bookingPopupForm.data[i.name] ? "assets/images/radio-Select.svg" :
                          "assets/images/radio-NonSelect.svg"} />
                      <label className="font-size-14 font-wt-600 ml-3 mr-4 pt-1" >Yes</label>
                      <img
                        onClick={() => {
                          toggleBookingPopupForm({
                            show: true, data: { ...bookingPopupForm.data, [i.name]: false }, i: bookingPopupForm.i,
                            item: bookingPopupForm.item, totalChargesLabel: bookingPopupForm.totalChargesLabel
                          })
                        }} className="cursor" src={!bookingPopupForm.data[i.name] ? "assets/images/radio-Select.svg" :
                          "assets/images/radio-NonSelect.svg"} />
                      <label className="font-size-14 font-wt-600 ml-3 pt-1" >No</label>
                    </div>
                  </div>
                )
              })}
            </div>
            <label className='font-size-18 font-wt-600 w-100 text-left px-3 mb-3' ><u>Payment Information</u></label>
            <label className='font-size-16 font-wt-500 w-100 text-left px-3'>Choose payment method below</label>

            <div className="d-flex row mb-3 px-3 my-3" >
              {paymentTypes.map((i, j) => {
                return (
                  <div
                    onClick={() => {
                      toggleBookingPopupForm({
                        show: true, data: { ...bookingPopupForm.data, payemntType: i.name }, i: bookingPopupForm.i,
                        item: bookingPopupForm.item, totalChargesLabel: bookingPopupForm.totalChargesLabel
                      })
                    }}
                    style={i.name === bookingPopupForm.data.payemntType ? { border: "3px solid #B9EFFF", borderRadius: '10px' } :
                      {
                        boxShadow: "0px 1px 6px #ccc",
                        borderRadius: "10px"
                      }}
                    className="w-30 mx-2 d-flex align-items-center justify-content-center cursor" >
                    <img src={i.img} className="mr-2 cursor" />
                    <label className="font-size-13 font-wt-500 text-dark pt-2 cursor" >{i.name}</label>
                  </div>
                )
              })}
            </div>

            <div className="px-4 d-flex row mt-5 justify-content-start" >
              <button type="button"
                isDisabled={!bookingPopupForm.data.payemntType}
                onClick={() => {
                  onPurchasePlan(bookingPopupForm.item.applicationId, bookingPopupForm.totalChargesLabel.split(" ")[1],
                    bookingPopupForm.totalChargesLabel.split(" ")[0], bookingPopupForm.i.shipper_id, bookingPopupForm.data)
                }}
                className={` border-0 my-2 text-white enableQuotebtn  w-30`}>
                {bookingPopupForm.data.payemntType === "Pay With Invoice" ? "Book Now" : "Pay Now"}
              </button>
            </div>

          </div>
        </FinanceInvoiceModal>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state={"ShipmentBookingApply"} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Logistic > Request Quote"}
              userTokenDetails={userTokenDetails} />

            <div>
              <div style={{ width: '105%', height: '50rem' }}>
                <iframe
                  src={'https://www.searates.com/shipping/request?utm_source=google&utm_medium=cpc&utm_campaign=21230328716&utm_content=161541767757&utm_term=sea%20rates&gad_source=1&gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqGYCXFMi09v76cX4aiQYzdrCs1tj8i3is1G2LWOx8gu5JslWyCWE98aAmBfEALw_wcB'}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  title="Embedded Website"
                ></iframe>
              </div>
            </div>

          </main>
        </div >
      </div >
    </div >
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(RaiseQuoteSeaRates)