import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from "../../service";
import { formatDate_Application, dateFormatter_DatePicker } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile';
import toastDisplay from "../../utils/toastNotification";
import OTPVerificationDoc from '../contractDocument/otpVerificationDoc';

const FinanceRequest = ({ userTokenDetails, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [data, setData] = useState({});
  const [fileData, setfileData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [buyerResponse, setBuyerResponse] = useState("");

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    window.onload = function () {
      try {
        var url_string = (window.location.href);
        var url = new URL(url_string);
        var finance_id = url.searchParams.get('id');
        var type = url.searchParams.get('type');

        console.log(finance_id + ' ', type);

        call('POST', 'getDiscounting', { id: finance_id, type: type }).then((result) => {
          console.log('running getDiscounting api -->', result);
          if (type == 1) {
            setData({
              financeId: finance_id,
              type: type,
              applicantId: result.seller_id,
              applicantName: result.sellerName,
              buyerId: result.buyer_id,
              buyerName: result.buyerName,
              buyerEmail: result.buyerEmail,
              referenceNo: result.reference_no,
              poRefrenceNo: result.po_no,
              issueDate: result.issue_date ? dateFormatter_DatePicker(result.issue_date) : "",
              invoiceDueDate: result.due_date ? dateFormatter_DatePicker(result.due_date) : "",
              amountCurrencyName: result.amountCurrencyName,
              contractAmount: result.contract_amount,
              amountCurrency: result.currency,
              contractAmountCurrency: result.contract_amount_currency,
              contractAmountCurrencyName: result.contractAmountCurrencyName,
              amount: result.amount,
              lenderId: result.lender_id,
              lenderName: result.lenderName,
              interestRate: result.interest_rate,
              creditDays: result.credit_days,
              logisticName: result.shipperName === null ? "Not Availabe" : result.shipperName,
              grnDate: result.grn_date ? dateFormatter_DatePicker(result.grn_date) : "",
              doc: result.docData.docList,
              exhibitDocList: result.docData.exhibitDocList,
              NOADocList: result.docData.NOADocList,
              frameworkDocList: result.docData.frameworkDocList,
            })
          }
          else if (type == 2) {
            setData({
              financeId: finance_id,
              type: type,
              applicantId: result.seller,
              applicantName: result.sellerName,
              buyerId: result.buyer,
              buyerName: result.buyerName,
              buyerEmail: result.buyerEmail,
              referenceNo: result.lc_number,
              poRefrenceNo: result.po_no,
              issueDate: result.lc_issue_date ? dateFormatter_DatePicker(result.lc_issue_date) : "",
              invoiceDueDate: result.due_date ? dateFormatter_DatePicker(result.due_date) : "",
              currencyName: result.amountCurrencyName,
              contractAmount: result.contract_amount,
              currency: result.currency,
              contractAmountCurrency: result.contract_amount_currency,
              contractAmountCurrencyName: result.contractAmountCurrencyName,
              reqAmount: result.amount,
              lenderId: result.lender,
              lenderName: result.lenderName,
              interestRate: result.interest_rate,
              creditDays: result.credit_days,
              logisticName: result.shipperName === null ? "Not Availabe" : result.shipperName,
              grnDate: result.grn_date ? dateFormatter_DatePicker(result.grn_date) : "",
              doc: result.docData.docList,
              exhibitDocList: result.docData.exhibitDocList,
              NOADocList: result.docData.NOADocList,
              frameworkDocList: result.docData.frameworkDocList,
              logisticNameManual: result.offline_data.logistic_name
            })
          }
          else if (type == 3) {
            setData({
              financeId: finance_id,
              type: type,
              applicantId: result.seller_id,
              applicantName: result.sellerName,
              buyerId: result.buyer_id,
              buyerName: result.buyerName,
              buyerEmail: result.buyerEmail,
              invoiceRefrenceNo: result.reference_no,
              poRefrenceNo: result.po_no,
              invoiceIssueDate: result.issue_date ? dateFormatter_DatePicker(result.issue_date) : "",
              invoiceDueDate: result.due_date ? dateFormatter_DatePicker(result.due_date) : "",
              currencyName: result.amountCurrencyName,
              contractAmount: result.contract_amount,
              reqAmount: result.amount,
              lenderId: result.lender_id,
              lenderName: result.lenderName,
              interestRate: result.interest_rate,
              creditDays: result.credit_days,
              logisticName: result.shipperName === null ? "Not Availabe" : result.shipperName,
              grnDate: result.grn_date ? dateFormatter_DatePicker(result.grn_date) : "",
              doc: result.docData.docList,
              exhibitDocList: result.docData.exhibitDocList,
              NOADocList: result.docData.NOADocList,
              frameworkDocList: result.docData.frameworkDocList,
              logisticNameManual: result.offline_data.logistic_name
            })
          }
        }).catch((e) => {
          console.log('error in getDiscounting', e);
        });


        call('POST', 'getBuyerResponse', { type: type, financeId: finance_id }).then((result) => {
          console.log('running getBuyerResponse api-->', result);
          setBuyerResponse(result);
        }).catch((e) => {
          console.log('error in getBuyerResponse', e);
          toastDisplay("Error", "error")
        });
      } catch (error) {

      }
    }
  }, []);
  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      console.log("value in doc render =>", values)

      values.tbl_doc_id = values.id
      values.document_name = values.doc_name
      values.uploaded_by = values.createdBy
      values.uploaded_on = values.created_at
      values.signType = 'buyerVerification'

      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.document_name) ? values.document_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td>
          {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
        </td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
            () => callAclAuthComponent(values)}>
            <i class="fas fa-file-signature"></i>
          </button>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function docAgreementPreview(type) {

    if (type == 'Exhibit') {
      let exhibitDocElements = data.exhibitDocList.length ? data.exhibitDocList.map((values, index) => {
        console.log("value in doc render =>", values)

        return (<tr>
          <td>{1}</td>
          <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
          <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
          <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
          <td>
            {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
          </td>
          <td className="row justify-content-center  mt-0 pt-0 m-0 ">

            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>

          </td>
        </tr>)
      }) : ''
      return (exhibitDocElements)
    }

    if (type == 'NOA') {
      let NOADocElements = data.NOADocList.length ? data.NOADocList.map((values, index) => {
        console.log("value in doc render =>", values)

        values.tbl_doc_id = values.id
        values.document_name = values.doc_name
        values.uploaded_by = values.createdBy
        values.uploaded_on = values.created_at
        values.signType = 'buyerVerification'

        return (<tr>
          <td>{1}</td>
          <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
          <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
          <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
          <td>
            {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
          </td>
          <td className="row justify-content-center  mt-0 pt-0 m-0 ">

            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
              () => callAclAuthComponent(values)}>
              <i class="fas fa-file-signature"></i>
            </button>

          </td>
        </tr>)
      }) : ''
      return (NOADocElements)
    }

    // if (type == 'Framework') {
    //   let FrameworkDocElements = data.frameworkDocList.length ? data.frameworkDocList.map((values, index) => {

    //     if (values.lendername == data.lenderName) {

    //       console.log("value in doc render =>", values);
    //       let valueData = JSON.parse(values.frameworkDocList);

    //       valueData.reqId = data.id
    //       valueData.tbl_doc_id = valueData.id
    //       valueData.document_name = valueData.doc_name
    //       valueData.uploaded_by = values.lenderName
    //       valueData.uploaded_on = valueData.created_at
    //       valueData.signType = 'withoutBlockChain'

    //       return (<tr>
    //         <td>{1}</td>
    //         <td>{(valueData && valueData.doc_name) ? valueData.doc_name : 'NA'}</td>
    //         <td>{(data.lenderName) ? data.lenderName : 'NA'}</td>
    //         <td>{(valueData && valueData.created_at) ? formatDate_Application(valueData.created_at) : 'NA'}</td>
    //         <td>
    //           {(valueData && valueData.sign) ? <pre><span className="text-success mr-1">{valueData.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
    //         </td>
    //         <td className="row justify-content-center  mt-0 pt-0 m-0 ">

    //           <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
    //             () => callPreviewFileComponent(valueData, "view")}>
    //             <i className="fa fa-eye" aria-hidden="true"></i>
    //           </button>
    //           <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
    //             () => callPreviewFileComponent(valueData, "download")}>
    //             <i className="fa fa-download" aria-hidden="true"></i>
    //           </button>
    //           {userTypeId == 4 || userTypeId == 14 &&
    //             <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
    //               () => callAclAuthComponent(valueData)}>
    //               <i class="fas fa-file-signature"></i>
    //             </button>}

    //         </td>
    //       </tr>)
    //     }
    //   })
    //     : ''
    //   return (FrameworkDocElements)
    // }
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values, buyerId: data.buyerId })
    setAclAuthModal(true);
  }

  const handleSubmit = (event) => {

    call('POST', 'updateBuyerResponse', data).then((result) => {
      console.log('running updateBuyerResponse api-->', result);
      toastDisplay(result, "success");
    }).catch((e) => {
      console.log('error in updateBuyerResponse', e);
      toastDisplay("Error", "error")
    });

  };

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {data.type == 1 &&
        <div class="col-md-12 p-2">
          <h1 className="text-center"><u>Finance Aginst PO Form- Review</u></h1><br />
          <div className="row m-2">
            <div class="col-md-2 p-2">
              <label>Applicant</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.applicantName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Buyer</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.buyerNameManual ? data.buyerNameManual : data.buyerName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>PO No</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.referenceNo} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>PO Issue date</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.issueDate} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Currency</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.contractAmountCurrencyName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Contract Amount</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.contractAmount} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Currency</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.amountCurrencyName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Amount</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.amount} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Lender</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.lenderName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Interest Rate</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.interestRate} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Credit Days</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.creditDays} disabled />
            </div>

            <div className="col-md-12 text-left">
              <div className="row form-group">

                <>
                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Initial Documents</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th>Type of Document</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.doc && data.doc.length > 0) && docPreviewGenerator(data.doc)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Framework Agreement</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data.frameworkDocList && data.frameworkDocList.length) && docAgreementPreview('Framework')}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Exhibit Agreement</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  <div class="col-md-12">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Notice of Assignment</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </>

              </div>
            </div>
          </div>
        </div>}

      {data.type == 2 &&
        <div class="col-md-12 p-2">
          <h1 className="text-center"><u>Finance against LC Form- Review</u></h1><br />
          <div className="row m-2">
            <div class="col-md-2 p-2">
              <label>Applicant</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.applicantName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Buyer</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.buyerName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>LC No</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.referenceNo} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>LC Issue date</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.issueDate} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Currency</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.currencyName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Contract Amount</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.contractAmount} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Currency</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.currencyName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Requested Amount</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.reqAmount} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Lender</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.lenderName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Interest Rate</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.interestRate} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Credit Days</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.creditDays} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Shipper/Broker</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.logisticName == 'Not Availabe' ? data.logisticNameManual : data.logisticName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>GRN Date</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.grnDate} disabled />
            </div>
            <div className="col-md-12 text-left">
              <div className="row form-group">

                <>
                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Initial Documents</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th>Type of Document</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.doc && data.doc.length > 0) && docPreviewGenerator(data.doc)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Exhibit Agreement</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  <div class="col-md-12">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Notice of Assignment</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th>Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </>

              </div>
            </div>
          </div>
        </div>}


      {data.type == 3 &&
        <div class="col-md-12 p-2">
          <h1 className="text-center"><u>Finance against Invoice Form- Review</u></h1><br />
          <div className="row m-2">
            <div class="col-md-2 p-2">
              <label>Applicant</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.applicantName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Buyer</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.buyerName} disabled />
            </div>

            <div class="col-md-2 p-2">
              <label>Invoice No</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.invoiceRefrenceNo} disabled />
            </div>

            <div class="col-md-2 p-2">
              <label>Po No</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.poRefrenceNo} disabled />
            </div>

            <div class="col-md-2 p-2">
              <label>Invoice Issue date</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.invoiceIssueDate} disabled />
            </div>

            <div class="col-md-2 p-2">
              <label>Invoice Due date</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.invoiceDueDate} disabled />
            </div>

            <div class="col-md-2 p-2">
              <label>Currency</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.currencyName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Contract Amount</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.contractAmount} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Currency</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.currencyName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Requested Amount</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.reqAmount} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Lender</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.lenderName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Interest Rate</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.interestRate} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Credit Days</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.creditDays} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>Shipper/Broker</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.logisticName == 'Not Availabe' ? data.logisticNameManual : data.logisticName} disabled />
            </div>
            <div class="col-md-2 p-2">
              <label>GRN Date</label>
            </div>
            <div class="col-md-4 p-2">
              <input type="text" class="form-control" value={data.grnDate} disabled />
            </div>
            <div className="col-md-12 text-left">
              <div className="row form-group">

                <>
                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Initial Documents</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Type of Document</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th width="150">Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.doc && data.doc.length > 0) && docPreviewGenerator(data.doc)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* <div class="col-md-6">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Exhibit Agreement</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th width="150">Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  <div class="col-md-12">
                    <div className="col-md-12 mt-3">
                      <h3 className="pt-2 pb-2"><u>Notice of Assignment</u></h3>
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th width="200">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="150">Uploaded On</th>
                              <th width="150">Signed By</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </>

              </div>
            </div>
          </div>
        </div>}

      {(buyerResponse.buyer_status == null) ?
        <div>
          <div className="col-md-12 pt-3">
            <input type="checkbox" id="checkDNC" name="dnc" value="on" onChange={hanleDocChecks} required checked={data.dnc} />
            <label className="checkbox-smallLabel width-auto" for="checkDNC">
              <span>
                I hereby declare that the given information by me is best in my
                knowledge and I did not apply any loan against this contract.
            </span>
            </label>
          </div>
          <hr className="pt-2 pb-2 text-primary" />
          <div className="col-md-12">
            {data.dnc ?
              <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={handleSubmit}> <b> I Accept </b>
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </button>
              :
              <button type="button" className="btn btn-danger btn-sm border border-danger float-left" onClick={handleSubmit}>
                <i className="fa fa-window-close" aria-hidden="true"></i> I Reject
          </button>}
          </div>
        </div>
        :
        (buyerResponse.buyer_status == '1') ?
          <div class="mt-3 text-center">
            <br /><br />
            <h1 className="text-success">You have already accepted the {data.type == 1 ? 'PO' : data.type == 2 ? 'LC' : 'Invoice'} Finance.</h1>
          </div>
          :
          <div class="mt-3 text-center">
            <br /><br />
            <h1 className="text-success">You have already rejected the {data.type == 1 ? 'PO' : data.type == 2 ? 'LC' : 'Invoice'} Finance.</h1>
          </div>

      }
      {
        showPreviewModal && <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
        />
      }

      {showAclAuthModal &&
        <OTPVerificationDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          formData={data}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refresh}
          setrefreshDoc={setrefresh}
        />}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceRequest)