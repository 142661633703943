import React from "react";

const TooltipModal = ({ tooltip, setTooltip, data }) => {
  return (
    <>
      <div className={`modal fade ${tooltip && "show"}`} style={{ display: "block" }}>
        <div className="modal-dialog  modal-dialog-centered modal-sm">
          <div className="modal-content modalcontenttooltip">
            <div className="modal-header border-0">
              <p className="modal-title font-wt-500" id="exampleModalLabel">{`Remark : ${data.showLenderName}`}</p>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setTooltip(false)}></button>
            </div>
            <div className="modal-body text-center px-4 pt-0">
              {typeof (data.showRemark) === 'string'
                ? <p dangerouslySetInnerHTML={{ __html: data.showRemark }}
                  className="font-size-13 justifytext" />
                :
                <ol>
                  {data.showRemark?.map(item => {
                    return <li className="font-size-13 justifytext mt-3">{item}</li>
                  })}
                </ol>

              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TooltipModal;