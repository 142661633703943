import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButNot from './butNot';
import HaveNot from './haveNot';
import DropOff from './dropOff';
import Movement from './movment';
export default function BoostView({ allData, commodityNames }) {
  const [clickedIndex, setClickedIndex] = useState(null);


  const mainButtons = ["But Not", "Have Not", "Drop Off", "Movement"];
  const tooltips = [
    "Who have purchased ___ but have not purchased ___ [from date] to [to date] ?",
    "Who have not purchased ___ but have not purchased ___ from [from date] to [to date] ?",
    "Who have purchased ___ in one time period but have not in another time period ?",
    "In a Calendar or cohort view for all your business variables."
  ];


  const handleClick = (index) => {
    setClickedIndex(index);
  };


  useEffect(() => {


    // bootstarp tooltip
    const bootstrap = require('bootstrap');
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 100, hide: 10 },
        customClass: 'custom-tooltip',
        placement: "bottom",
        trigger: "hover"
      });
    });

  }, []);

  return (
    <div className='shadow rounded p-2'>
      <style>{`
        .custom-tooltip .tooltip-inner {
          background-color: rgb(27, 148, 183);
          color: white;
          border: 1px solid rgb(27, 148, 183);
        }

        .custom-tooltip .tooltip-arrow::before {
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
        }
      `}</style>
      <div className='border-bottom  pb-2'>
        {mainButtons.map((item, index) => (
          <button
            type="button"
            className="btn btn-primary me-2"
            style={{
              backgroundColor: clickedIndex === index ? "rgb(27, 148, 183)" : "transparent",
              color: clickedIndex === index ? "white" : "rgb(27, 148, 183)",
              border: `1px solid rgb(27, 148, 183)`
            }}
            onClick={() => handleClick(index)}
            data-bs-toggle="tooltip"
            title={tooltips[index]}
            key={index}
          >
            {item}
          </button>
        ))}
      </div>
      <div>
        {
          clickedIndex === 0 && <ButNot allData={allData} commodityNames={commodityNames} />
        }
        {
          clickedIndex === 1 && <HaveNot allData={allData} commodityNames={commodityNames} />
        }{
          clickedIndex === 2 && <DropOff allData={allData} commodityNames={commodityNames} />
        }
        {
          clickedIndex === 3 && <Movement allData={allData} commodityNames={commodityNames} />
        }
      </div>
    </div>
  );
}
