export default function validate(values) {
  let errors = {};


  if (!values.loanId) {
    errors.loanId = 'Finance ID is a Mandotry Field';
  }

  if (!values.financier) {
    errors.financier = 'Financier Name is a Mandatory Field';
  }

  if (!values.manager) {
    errors.manager = 'Manager is a Mandatory Field';
  }

  if (!values.disbursedDoc) {
    errors.disbursedDoc = 'Upload a Disbursed Document';
  }


  console.log(errors);
  return errors;
}
