import React, { useState, useEffect } from 'react';
import TableWithPages from '../tableWithArrows';

const TargetParameters = ({
  setShowParameters,
  selectedCompanies,
  selectedWhom,
  selectedTargetTypes,
  formattedDateRange,
  setIsPopupVisible,
  allData,
  onAddTarget
}) => {
  const [tableData, setTableData] = useState([]);
  const [targetValues, setTargetValues] = useState({});

  useEffect(() => {
    if (!allData || selectedCompanies.length === 0 || selectedWhom.length === 0) {
      setTableData([]);
      return;
    }

    // Filter data based on selected companies
    const filteredData = allData.filter(item => selectedCompanies.includes(item.partyName));

    // Create a set to store unique values based on the selected fields
    const uniqueData = new Set();

    // Filter unique data based on the selected fields
    const filteredUniqueData = filteredData.filter(item => {
      const uniqueKey = selectedWhom.map(whom => item[toCamelCase(whom)]).join('|');
      if (!uniqueData.has(uniqueKey)) {
        uniqueData.add(uniqueKey);
        return true;
      }
      return false;
    });

    // Add an id to each row to use as a key
    const dataWithId = filteredUniqueData.map((item, index) => ({ id: index, ...item }));

    setTableData(dataWithId);
  }, [allData, selectedCompanies, selectedWhom]);

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const key = `${rowIndex}-${colIndex}`;
    setTargetValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  };

  const handleSaveClick = () => {
    const filteredTargets = tableData.map((row, rowIndex) => {
      let targetEntry = {};
      selectedWhom.forEach((whom, whomIndex) => {
        targetEntry[whom] = row[toCamelCase(whom)];
      });
      selectedTargetTypes.forEach((type, typeIndex) => {
        const key = `${rowIndex}-${typeIndex + selectedWhom.length}`;
        const inputValue = targetValues[key];
        if (inputValue !== undefined && inputValue !== '') {
          targetEntry[type] = inputValue;
        }
      });
      return targetEntry;
    }).filter(target => Object.keys(target).length > 1); // Filter out entries with only whom keys

    const savedTargets = {
      duration: formattedDateRange,
      companies: selectedCompanies,
      whom: selectedWhom,
      target: filteredTargets
    };

    onAddTarget(savedTargets);
    setIsPopupVisible(false);
  };




  function toCamelCase(inputString) {
    return inputString.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  const columns = [
    ...selectedWhom.map(whom => ({ field_Name: toCamelCase(whom), subColumns: whom })),
    ...selectedTargetTypes.map(type => ({ field_Name: "inputBar", subColumns: type }))
  ];

  return (
    <div className=''>
      <div className="container w-100 h-100 position-relative p-0" style={{ width: "70vw" }}>
        <div className='pb-2 border-bottom'>
          <div className="d-flex justify-content-between align-items-center  ">
            <div className='d-flex justify-content-between align-items-center'>
              {/* <h6 className="p-0 m-0">
                Target Parameters : Companies
                <ScrollableSpan className='text-success' text={selectedCompanies.join(', ')} />
                Whom
                <ScrollableSpan className='text-warning' text={selectedWhom.join(', ')} />
                For
                <ScrollableSpan className='text-danger' text={selectedTargetTypes.join(', ')} />
              </h6> */}
            </div>

            {/* <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-primary mr-2" onClick={handleEditTarget}>Edit</button>
            </div> */}
          </div>
          {/* <div className="mt-0 ms-5 text-primary">
            {formattedDateRange}
          </div> */}
        </div>

        <div className='mt-2 p-2 border rounded'>
          <div>
            <h6>Target List</h6>
          </div>
          {/* <p>Selected Companies: {selectedCompanies.join(', ')} */}
          <TableWithPages
            data={tableData}
            columns={columns}
            noOfRowsPerPage={1000}
            freezeFirstColumn={false}
            handleInputChange={handleInputChange}
          />
          <button className='btn btn-primary mt-2' onClick={handleSaveClick}>Save Target</button>
        </div>
      </div>

      <style>
        {`
          span::-webkit-scrollbar {
            height: 1px; 
            border-radius: 0.5px
          }
          span::-webkit-scrollbar-thumb {
            background-color: #888; 
          }
          span::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }
        `}
      </style>
    </div>
  );
};

export default TargetParameters;
