import React from "react";
import Draggable from "react-draggable";

const BottomPopup = ({ isOpen, onClose, children }) => {
  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="bottom-popup-container">
      <Draggable axis="x" bounds="parent">
        <div className={`bottom-popup ${isOpen ? "open" : ""} bottomPopup`}>
          <div className="popup-content">
            {children}
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default BottomPopup;
