let columnNames = [
  {
    name: "Origin Country", selector: "origin_country", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    ddField: true, ddData: "ddDataCountrys", ddKey: "name", onddChange: "onOriginCountryChange", onddSelectionChangeClear: "origin_port",
    editExistData: false
  }, //0
  {
    name: "Origin Port", selector: "origin_port", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    ddField: true, ddData: "ddDataOriginPorts", ddKey: "name", editExistData: false
  }, //1
  {
    name: "Destination Country", selector: "destination_country", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    ddField: true, ddData: "ddDataCountrys", ddKey: "name", onddChange: "onDestinationCountryChange", onddSelectionChangeClear: "destination_port",
    editExistData: false
  }, //2
  {
    name: "Destination Port", selector: "destination_port", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    ddField: true, ddData: "ddDataDestinationPorts", ddKey: "name", editExistData: false
  }, //3

  {
    name: "Container Type", selector: "container_type", sortable: true, condition: "00", checkForMandatory: true,
    ddField: true, ddData: "ddDataContainerTypes", ddKey: "name", editExistData: true
  }, //4

  {
    name: "Container No", selector: "container_no", sortable: true, condition: "00", checkForMandatory: true,
    validation: "positive_no", editExistData: true
  }, //5

  {
    name: "Cargo Type", selector: "cargo_type", sortable: true, condition: "11:12", checkForMandatory: true,
    ddField: true, ddData: "ddDataCargoTypes", ddKey: "name", editExistData: true
  }, //6

  {
    name: "Start Date (yyyy-mm-dd)", selector: "start_date", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    validation: "date_format(yyyy-mm-dd)", editExistData: true, calendarField: true
  }, //7

  {
    name: "End Date (yyyy-mm-dd)", selector: "end_date", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    validation: "date_format(yyyy-mm-dd)", editExistData: true, calendarField: true
  }, //8

  {
    name: "Load Volume (cubic meter)", selector: "load_volume", sortable: true, condition: "01", checkForMandatory: true,
    validation: "positive_no", editExistData: true
  }, //9

  {
    name: "Load Weight (Kg)", selector: "load_weight", sortable: true, condition: "01", checkForMandatory: true,
    validation: "positive_no", editExistData: true
  }, //10

  {
    name: "Vessel Type", selector: "vessel_type", sortable: true, condition: "10:11:12", checkForMandatory: true,
    ddField: true, ddData: "ddDataVesselTypes", ddKey: "name", editExistData: true
  }, //11

  {
    name: "Commodity Type", selector: "commodity_type", sortable: true, condition: "10", checkForMandatory: true,
    ddField: true, ddData: "ddDataCommTypes", ddKey: "category", editExistData: true
  }, //12

  {
    name: "Flexibility Days", selector: "flexibility_days", sortable: true, condition: "11", checkForMandatory: true,
    validation: 'positive_no', editExistData: true
  }, //13

  {
    name: "Price ($)", selector: "price", sortable: true, condition: "00:01:10:11:12", checkForMandatory: true,
    validation: "positive_no", editExistData: true
  }, //14

  {
    name: "Created At", selector: "created_at", sortable: true, condition: ""
  }, //15
  {
    name: "Action", selector: "action", sortable: true, condition: "00:01:10:11:12"
  } //16
]


export default columnNames;