import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import { useState } from 'react'
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../utils/newInput'
import toastDisplay from '../../utils/toastNotification'
import call from '../../service'
import { AddMoreDocumentHandler } from '../addMoreDocumentHandler'
import { convertImageToPdf, isEmpty, most_used_currencies } from '../../utils/myFunctions'
import { FileInput } from '../../utils/FileInput'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import { ToastContainer } from 'react-toastify'

const currencies = [
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { code: "%" }
]

const infoType = [
  { "name": "$ Currency", "unit": "currency" },
  { "name": "% Percentage", "unit": "percentage" },
  { "name": "Text", "unit": "text" },
  { "name": "Date", "unit": "date" }
]

export const ApplicationType = [
  { name: "LC Confirnation (International)" },
  { name: "LC Discounting (International)" },
  { name: "LC Confirmation & Discounting (International)" },
  { name: "LC Confirnation (Domestic)" },
  { name: "LC Discounting (Domestic)" },
  { name: "LC Confirmation & Discounting (Domestic)" },
  { name: "Invoice Discounting" },
  { name: "SBLC" }
]

export const LCapplicationStatus = [
  { name: "Applied for Limit" },
  { name: "Quotes received from financiers" },
  { name: "Amendment request from financier" },
  { name: "Quote selected by supplier" },
  { name: "LC sent for amendment by supplier" },
  { name: "Ammended LC & request letter sent to financier" },
  { name: "Contract Received from financier" },
  { name: "Contract Signed by supplier" },
  { name: "Contract Signed by financier" },
  { name: "Limit Approved" }

]

export const INVapplicationStatus = [
  { name: "Applied for Limit" },
  { name: "Quotes received from financiers" },
  { name: "Amendment request from financier" },
  { name: "Quote selected by supplier" },
  { name: "Term Sheet Received from financier" },
  { name: "Term Sheet Signed by supplier" },
  { name: "Term Sheet Signed by financier" },
  { name: "Limit Approved" }

]

const services = [
  { name: "LC Confirnation", is_checked: false },
  { name: "LC Discounting", is_checked: false },
  { name: "Invoice Discounting", is_checked: false },
  { name: "SBLC", is_checked: false },
  { name: "Usance at sight", is_checked: false },
  { name: "Supply chain finance", is_checked: false },
  { name: "Import financing", is_checked: false },
  { name: "Purchase order financing", is_checked: false },
  { name: "Reverse factoring", is_checked: false },
  { name: "Freight finance", is_checked: false },
  { name: "Packing Credit/ Pre shipment financing", is_checked: false }


]

const AddFinancer = ({ userTokenDetails, navToggleState }) => {
  const [showLoader, setshowLoader] = useState(false)

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [requestMoreDetails, setRequestMoreDetails] = useState({ modal: false, page: 0 });
  const [addMoreDocs, setAddMoreDocs] = useState([{
    key: 'moreDoc0',
    doc_name: 'Add More'
  }])
  const [financers, setFinancers] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [tempValue, setTempValue] = useState("")
  const [docNamePicker, toggleDocNamePicker] = useState({ show: false, id: null })
  const [countrydata, setCountrydata] = useState([])
  const [buyersdata, setBuyersdata] = useState([{
    "buyerName": "Wallmart",
    "buyerCountry": "IN",
    "buyerAddress": "3-PA-18 GANDHI GRAH VIGYAN NAGAR ",
    "buyerPostalCode": "324005",
    "buyerDunsNo": "914487571",
    "currMaxOutStanding": "123213",
    "creditDays": "21",
    "termsOfPayment": "dsadsasad",
    "currMaxOutStandingCurrency": "USD",
    "limitRequiredCurrency": "USD",
    "currentProjTOCurrency": "USD",
    "buyerAmountCurrency": "USD",
    "buyerAmount": "213",
    "currentProjTO": "231213",
    "limitRequired": "12213"
  }])
  const [dbData, setDbData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState([])
  const [docsTable, setDocsTable] = useState([])
  const [servicesOffered, setServicesOffered] = useState(services)
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  console.log('dataaaaaaaa', data, typeof (data.AudFin));

  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setCountrydata(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
  }, [])
  const handleInputChange = (event, maxlength) => {
    if (maxlength) {
      if (event.target.value?.length <= maxlength) {
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
        return
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  const addNewFinancier = () => {
    let errors = {}
    if (!data.FinancierName) {
      errors.FinancierName = 'Mandatory Field'
    }
    if (!data.contact_person) {
      errors.contact_person = 'Mandatory Field'
    }
    if (!data.nameTitle) {
      errors.contact_person = 'Mandatory Field'
    }
    if (!data.contact_number) {
      errors.contact_number = 'Mandatory Field'
    }
    if (!data.phoneCode) {
      errors.contact_number = 'Mandatory Field'
    }
    if (!data.designation) {
      errors.designation = 'Mandatory Field'
    }
    if (!data.email_id) {
      errors.email_id = 'Mandatory Field'
    }


    if (isEmpty(errors)) {
      setshowLoader(true)

      let formdata = new FormData()
      formdata.append("userId", userId)
      formdata.append("COMPANY_NAME", data.FinancierName)
      formdata.append("CONTACT_PERSON", data.contact_person)
      formdata.append("NAME_TITLE", data.nameTitle)
      formdata.append("CONTACT_NUMBER", data.contact_number)
      formdata.append("COUNTRY_CODE", data.phoneCode)
      formdata.append("DESIGNATION", data.designation)
      formdata.append("EMAIL_ID", data.email_id)
      formdata.append("SERVICES_OFFERED", JSON.stringify(servicesOffered.filter(item => item.is_checked).map(data => data.name)))

      Object.keys(data).forEach(element => {
        if (data[element]?.filebase64) {
          if (element?.includes("extraDocs")) {
            formdata.append(element?.split("_")[1], data[element])
          } else {
            formdata.append(element, data[element])
          }

        }
      })

      const finalres = []
      for (let i = data.additionalDetails; i <= data.additionalDetails?.length - 1; i++) {
        let element = data.additionalDetails[i]
        if (element.type === 'currency') {
          element[`${element.title}_unit`] = data[`${element.title}_currency`]
          element[`${element.title}_value`] = data[`${element.title}_value`]
        } else {
          element[`${element.title}_value`] = data[`${element.title}_value`]
        }
        finalres.push(element)
      }
      if (finalres.length !== 0) {
        formdata.append('EXTRA_FIELDS', JSON.stringify(finalres))
      }
      call('POST', 'addNewFinancier', formdata).then(result => {
        toastDisplay(result, "success")
        setshowLoader(false)
        window.history.back()
      }).catch(e => {
        toastDisplay(e, "error")
        setshowLoader(false)
      })

    } else {
      setErrors(errors)
    }
  }

  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)
      if (event.target.name.includes("extraDocs")) {
        setAddMoreDocs(addMoreDocs.concat({ key: event.target.name, doc_name: 'Add More' }))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const formatDataForTable = () => {
    let tabledata = []
    buyersdata.forEach((item, index) => {
      let row = []
      row[0] = <img className='cursor' onClick={() => {
        let temp = [...selectedIndex]
        if (temp.indexOf(index) !== -1) {
          // If it does, remove it using splice
          temp.splice(temp.indexOf(index), 1);
        } else {
          // If it doesn't, add it to the array
          temp.push(index);
        }
        console.log('selectedindex', temp);
        setSelectedIndex(temp)
      }} src={`assets/images/${selectedIndex?.includes(index) ? 'checked-green' : 'empty-check'}.png`} />
      row[1] = `${item.buyerName ? item.buyerName : 'NA'}`
      row[2] = `${item.buyerCountry ? item.buyerCountry : 'NA'}`
      row[3] = `${item.currentProjTOCurrency ? item.currentProjTOCurrency : ''} ${item.currentProjTO ? item.currentProjTO : 'NA'}`
      row[4] = `${item.limitRequiredCurrency ? item.limitRequiredCurrency : ''} ${item.limitRequired ? item.limitRequired : 'NA'}`
      row[5] = `${item.currMaxOutStandingCurrency ? item.currMaxOutStandingCurrency : ''} ${item.currMaxOutStanding ? item.currMaxOutStanding : 'NA'}`
      row[6] = `${item.creditDays}`
      row[7] = `${item.termsOfPayment}`
      row[8] = `${item.buyerDunsNo}`
      row[9] = <img src='/assets/images/redirect.svg' className='cursor' />
      tabledata.push(row)
      row = []
    })
    setDbData(tabledata)
  }
  const formatDocsTable = () => {
    let tabledata = []
    buyersdata.forEach((item, index) => {
      let row = []
      row[0] = item.buyerName
      row[1] = <div>
        <div className='col-md-12 my-3'>
          <label className='font-size-14'>Invoice Document </label>
        </div>
        <div className='col-md-12 my-3'>
          <label className='font-size-14'>PO Document </label>
        </div>


      </div>
      row[2] = <div>
        <div className='col-md-12 my-2'>
          <div className="col-md-12">
            <FileInput name={`${item.buyerName}_InvoiceDoc`} value={data[`${item.buyerName}_InvoiceDoc`]} error={errors[`${item.buyerName}_InvoiceDoc`]}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`${item.buyerName}_InvoiceDoc`]: null })} />
          </div>
        </div>
        <div className='col-md-12 my-2'>
          <div className="col-md-12">
            <FileInput name={`${item.buyerName}_PODoc`} value={data[`${item.buyerName}_PODoc`]} error={errors[`${item.buyerName}_PODoc`]}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`${item.buyerName}_PODoc`]: null })} />
          </div>
        </div>
      </div>
      tabledata.push(row)
      row = []
    })
    setDocsTable(tabledata)
  }
  useEffect(() => {
    formatDataForTable()
  }, [buyersdata, selectedIndex])
  useEffect(() => {
    formatDocsTable()
  }, [buyersdata, data])
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        {requestMoreDetails.modal && requestMoreDetails.page === 1 &&
          <div className={`modal fade ${requestMoreDetails.modal && "show"}`} style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content submitmodal pb-4">
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setRequestMoreDetails({ modal: false, page: 0 })}></button>
                </div>
                <div className="modal-body text-center">
                  <p>Request more details</p>
                  <div className="row">
                    <div className='w-100'>
                      <div className='row'>
                        {data.additionalDetails && data.additionalDetails.map((item, index) => {
                          return (
                            <>
                              <div className='col-md-6 move-m p-0'>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <input disabled={true} className={"form-control bg-white"} placeholder={item.title} />
                                    {item.type == 'percentage' ? <p className="input-date-icon">%</p> :
                                      item.type == 'currency' ? <p className="input-date-icon">$</p> :
                                        item.type == 'text' ? <p className="input-date-icon">T</p> :
                                          <img src={"/assets/images/calender.png"} alt="" className="input-date-icon" />
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-2 move-m p-0'>
                                <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2 cursor"
                                  onClick={() => {
                                    let temp = data.additionalDetails;
                                    temp = temp.filter((i, j) => {
                                      if (j != index) {
                                        return i
                                      }
                                    })
                                    setData({ ...data, additionalDetails: temp, fieldTitle: item.title, fieldType: item.type })
                                  }}
                                />
                                <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2 cursor"
                                  onClick={() => {
                                    let temp = data.additionalDetails;
                                    temp = temp.filter((i, j) => {
                                      if (j != index) {
                                        return i
                                      }
                                    })
                                    setData({ ...data, additionalDetails: temp })
                                  }}
                                />
                              </div></>
                          )
                        })
                        }
                      </div>
                      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" >
                        <div class="col-md-6 move-m p-0">
                          <div class="col-md-6">
                            <p style={{ "fontSize": "12px" }}>Select field type</p>
                          </div>
                          <div className="row justify-between gap-2">
                            {infoType.map((item) => {
                              return (
                                <div
                                  onClick={() => setData({ ...data, fieldType: item.unit })}
                                  style={data.fieldType == item.unit ? {
                                    border: "2px solid #B9EFFF"
                                  } : null}
                                  className={`mb-1 col-md-6 form-control w-45 cursor `}>
                                  <label>{item.name}</label>
                                </div>
                              )
                            })}
                            {errors.fieldType ? <div class="text-danger mt-2 font-size-12">
                              <b>{errors.fieldType}</b></div> : ''}
                          </div>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className="col-md-6 move-m p-0">
                          <NewInput isAstrix={true} type={"text"} label={"Title"}
                            name={"fieldTitle"} value={data.fieldTitle} error={errors.fieldTitle} onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 move-left text-blue">
                    <a onClick={() => {
                      if (!(data.fieldType && data.fieldTitle)) {
                        toastDisplay("Fill details to add", "info")
                      }
                      else {
                        let temp = data.additionalDetails || []
                        temp.push({ title: data.fieldTitle, type: data.fieldType })
                        setData({ ...data, additionalDetails: temp, fieldTitle: "", fieldTitle: "" })
                        // setAddMore(true)
                      }
                    }}><p>Add more</p></a>
                  </div>
                  <button type="button" className={`mx-2 new-btn w-20 py-2 px-2 text-white`} onClick={async () => {
                    if (data?.additionalDetails?.length) {
                      setshowLoader(true)
                      // await call("POST", "raiseInvoiceAmendment", {
                      //   applicationId: quotesDetail.limit_id, additionalDetails: data.additionalDetails,
                      //   userId, userName, buyerId: quotesDetail.buyerId, buyerName: quotesDetail.buyerName,
                      //   sellerId: quotesDetail.created_by
                      // })
                      toastDisplay("Request forwarded", "success")
                      setRequestMoreDetails({ modal: false, page: 0 })
                      setshowLoader(false)
                    }
                    else {
                      toastDisplay("Add atleast 1 request to send", "info")
                    }
                  }}>Save</button>
                </div>
              </div>
            </div>
          </div>}

        <div className="row">
          <SideBarV2 state={""} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Application Form"}
              userTokenDetails={userTokenDetails} />


            <div className="p-2 card border-0 rounded-3 card-layout h-100">
              <label className='w-100 text-center mt-2 font-size-18 font-wt-600'>{`Add New Financier`} </label>
              <div className='row p-4'>
                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <NewInput type={"text"} label={"Financier Name"}
                        name={"FinancierName"} value={data.FinancierName} error={errors.FinancierName}
                        onChange={handleInputChange} />
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <InputWithSelect
                        isAstrix={false}
                        type={"text"}
                        label={"Contact Person Name"}
                        selectData={[{ name: "Mr" }, { name: "Miss" }]}
                        selectName={"nameTitle"}
                        selectValue={data.nameTitle}
                        optionLabel={"name"}
                        optionValue={'name'}
                        name={"contact_person"}
                        value={data.contact_person}
                        error={errors.contact_person}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <InputWithSelect
                        isAstrix={false}
                        type={"text"}
                        label={"Contact Number"}
                        selectData={countrydata}
                        selectName={"phoneCode"}
                        selectValue={data.phoneCode}
                        optionLabel={"phonecode"}
                        optionValue={'phonecode'}
                        name={"contact_number"}
                        value={data.contact_number}
                        error={errors.contact_number}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <NewInput type={"text"} label={"Designation"}
                        name={"designation"} value={data.designation} error={errors.designation}
                        onChange={handleInputChange} />
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-12 mb-0">
                      <NewInput type={"text"} label={"Email ID"}
                        name={"email_id"} value={data.email_id} error={errors.email_id}
                        onChange={handleInputChange} />
                    </div>
                  </div>
                </div>

                {data.additionalDetails && data.additionalDetails.length ?
                  <div className='w-100 mt-2'>
                    <div className="row">
                      {data.additionalDetails.map((item, index) =>
                        <div className='col-md-4'>
                          <div className="row form-group">
                            <div className="col-md-12">
                              {item.type === 'currency' ?
                                <InputWithSelect
                                  isAstrix={false}
                                  type={"number"}
                                  label={item.title}
                                  selectData={most_used_currencies}
                                  selectName={`${item.title}_currency`}
                                  selectValue={data[`${item.title}_currency`]}
                                  optionLabel={"code"}
                                  optionValue={'code'}
                                  name={`${item.title}_value`}
                                  value={data[`${item.title}_value`]}
                                  error={errors[`${item.title}_value`]}
                                  onChange={handleInputChange}
                                />
                                : <NewInput
                                  type={item.type === 'date' ? 'date' : 'text'}
                                  value={data[`${item.title}_value`]}
                                  label={item.title}
                                  onChange={handleInputChange}
                                  name={`${item.title}_value`}
                                  error={errors[`${item.title}_value`]}
                                />
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  : null}
                <div >
                  <div className='d-flex flex-row gap-2 align-items-center cursor w-auto' onClick={() => setRequestMoreDetails({ modal: true, page: 1 })}>
                    <label className='text-color1 font-size-14 font-wt-600 mb-0'>Add more details</label>
                    <img src={"assets/images/right-arrow-icon.svg"} alt="Back" height={15} width={15} />
                  </div>
                </div>
                <div className='mt-4'>
                  <label className='font-size-14 font-wt-600 text-decoration-underline'>Document</label>

                  <div className='row'>
                    {addMoreDocs.map((item, index) => {
                      return <div className="col-md-6 my-2">
                        {docNamePicker.id === index ? (
                          <div className="w-25 d-inline-block">
                            <NewInput name={"addMore" + index} value={tempValue}
                              onChange={(e) => setTempValue(e.target.value)} />
                          </div>) : (
                          <label className="pl-2 font-size-14 font-wt-300">{item.doc_name}</label>
                        )}
                        <span>
                          {docNamePicker.id === index ? (
                            <>
                              <img
                                onClick={async () => {
                                  if (tempValue) {
                                    let temp = addMoreDocs
                                    temp[index]['doc_name'] = tempValue
                                    setAddMoreDocs([...temp])
                                    toggleDocNamePicker({ show: false, id: null })
                                  }
                                  else {
                                    toastDisplay('Please enter document name', "info")
                                  }
                                }}
                                style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                              <img
                                onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                                style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                            </>
                          ) :
                            (
                              <img
                                onClick={() => { setTempValue(item.doc_name); toggleDocNamePicker({ show: true, id: index }) }}
                                style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}

                        </span>
                        <div className='col-md-12'>
                          <FileInput name={`extraDocs_${item.doc_name}`} value={data[`extraDocs_${item.doc_name}`]} error={errors[`extraDocs_${item.doc_name}`]}
                            onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...data, [`extraDocs_${item.doc_name}`]: null })} />
                        </div>
                      </div>
                    })}
                  </div>


                </div>
                {(servicesOffered && servicesOffered.length) ?
                  <div className='mt-4 '>
                    <label className='font-size-14 font-wt-600 '>Services Offered</label>
                    <div className='row gap-2'>
                      {servicesOffered.map((item, index) => {
                        return <div className='col-md-2'>
                          <div className='d-flex flex-row gap-2 mt-2' key={index}>
                            <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                              const updatedFin = [...servicesOffered]; // create a new array
                              updatedFin[index] = {
                                ...updatedFin[index],
                                is_checked: !updatedFin[index].is_checked
                              };
                              setServicesOffered(updatedFin);
                            }} />
                            <label className='font-size-14 font-wt-600'>{item.name}</label>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>

                  : null
                }
                <div className="d-flex gap-4 mt-4">
                  <button className={`new-btn  py-2 px-5 text-white cursor`} onClick={addNewFinancier}>Submit</button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AddFinancer)