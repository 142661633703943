import React, { useEffect, useState } from 'react';
import { NewTable } from "../../../utils/newTable";
import Filter from "../../InvoiceDiscounting/components/Filter";
import call from '../../../service';
import { convertImageToPdf, ExportExcel, getDocDetails, getPDFFromFile, isEmpty, printDiv } from '../../../utils/myFunctions';
import { most_used_currencies } from '../../../utils/myFunctions';
import { FileInput } from '../../../utils/FileInput';
import { InputWithSelect, NewInput, NewSelect } from '../../../utils/newInput';
import FilterProfile from '../../InvoiceDiscounting/components/FilterProfile';
import validateAddShareholder from '../../../utils/validation_files/AddShareholderValidation.js';


const shareHolderDetails = [
  { "name": "Name", val: "name" },
  { "name": "Nationality", val: "nationality" },
  { "name": "Contact Number:", val: "contact_number" },
  { "name": "Percentage(%) of holding", val: "share_in_company", unit: "%" },
  { "name": "Email ID", val: "email_id" },
  { "name": "Direct Identification Number", val: "din" },
  { "name": "Date of birth", val: "dob" },
  { "name": "Authorised signatory", val: "signatory" },

  { "name": "Address", val: "Fulladdress" },
  { "name": "ITR (Only for indians)", val: "itr" },

]

const Shareholder = ({ userTokenDetails, navToggleState, setshowLoader, toastDisplay }) => {

  const [tableData, setTableData] = useState([])
  const [dbData, setDbData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const [count, setCount] = useState(0)
  const [data, setData] = useState({ pageCount: 10 })
  const [errors, setErrors] = useState({})

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 100 })

  const [shareholder, setShareholder] = useState(false);
  const [countrys, setCountrys] = useState([])
  const [states, setstatedata] = useState([])
  const [cities, setcities] = useState([])
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [shareholderdetails, setshareholderdetails] = useState({ isVisible: false, data: null })
  const [isSubmitted, setSubmitted] = useState(false)


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const getShareHolderData = () => {
    setshowLoader(true)
    let reqObj = {
      currentPage: 1,
      resultPerPage: data.pageCount ? data.pageCount : 10,
      userId: userId,
      search: data.search_text
    }
    console.log('Reqa obj getShareHolderGrid', reqObj)
    call('POST', "getShareHolderGrid", reqObj).then(result => {
      setTableData(formatDataForTable(result.message))
      setDbData(result.message)
      setTotalRecords(result.total_records)
      if (result.total_records < 10) {
        setData({
          ...data,
          pageCount: result.total_records
        })
      } else {
        setData({
          ...data,
          pageCount: 10
        })
      }
      setshowLoader(false)

    }).catch(e => {
      console.log('Error', e)
      setTotalRecords(0)
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getShareHolderData()
  }, [])
  useEffect(() => {
    call("GET", "getallCountry").then((result) => {
      console.log('success getallCountry', result)
      setCountrys(result);
    }).catch(e => {
      console.log(e)
    });

  }, []);
  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }
  const getCitiesByState = (stateId) => {
    call("POST", "getCitiesByState", { stateId }).then(result => {
      console.log('Success getCitiesByState data', result)
      setcities(result)
    }).catch(e => {
      console.log("Error in getCitiesByState", e)
    })
  }
  const deleteShareHolderById = (finDtId) => {
    setshowLoader(true)
    call("POST", "deleteShareHolderGrid", { finDtId }).then(result => {
      setshareholderdetails({
        isVisible: false,
        data: null
      })
      getShareHolderData()
      setshowLoader(false)
      toastDisplay('Shareholder data deleted succesfully', 'success')
    }).catch(e => {
      console.log('Error in deleteShareHolderGrid', e)
      setshowLoader(false)
      toastDisplay('Failed to delete shareholder data', 'error')
    })
  }
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let hsnCodes = item.Financersselected ? item.Financersselected.split(",") : []
      item.Fulladdress = `${item.address_line1 ? item.address_line1 + "," : ""}${item.address_line2 ? item.address_line2 + "," : ""}${item.city ? item.city + "," : ""}${item.state ? item.state + "," : ""}${item.country ? item.country : ''}${item.postal_code ? '-' + item.postal_code : ''}`
      const docArray = item.doc_array ? item.doc_array.split(',') : []

      row[0] = item.name
      row[1] = item.contact_number
      row[2] = item.email_id
      row[3] = item.share_in_company
      row[4] = item.din
      row[5] = <p className='mb-0 text-color1 font-wt-600 cursor' onClick={async () => {
        if (docArray.length > 1) {
          item.panCard = await getDocDetails(docArray[0])
          item.aadharDoc = await getDocDetails(docArray[1])
        }
        setShareholder(false)
        setshareholderdetails({ isVisible: true, data: item })
      }}>View details</p>
      tableData.push(row)
      row = []
    })
    return tableData
  }
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target.name === 'shareholderCountry') {
      const country = countrys.find(data => data.sortname === event.target.value)
      getStateByCountry(country.id)
    }
    if (event.target.name === 'state') {
      const state = states.find(data => data.name === event.target.value)
      getCitiesByState(state.id)
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })

  }
  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  const addShareHolderData = () => {
    setshowLoader(true)
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('country', data.shareholderCountry)
    formData.append('nationality', data.nationality)
    formData.append('emailId', data.shareholderEmail)
    formData.append('sharePercent', data.sharePercent)
    formData.append('itr', data.itr)
    formData.append('din', data.din)
    formData.append('signatory', data.authSigntature)
    formData.append('PANCard', data.panDoc)
    formData.append('AadhaarCard', data.aadharDoc)
    formData.append('userId', userId)
    formData.append('userEmail', userEmail)
    formData.append('contactNo', data.phone_number)
    formData.append('dob', data.dob)
    formData.append('addressLine1', data.add_1)
    formData.append('addressLine2', data.add_2)
    formData.append('city', data.city)
    formData.append('state', data.state)
    formData.append('postalCode', data.postalCode)

    call('POST', 'insertshareholder', formData).then((result) => {
      //setShareholder(false)
      setshowLoader(false)
      toastDisplay("Shareholder added successfully", "success")
      setSubmitted(false)
    }).catch(e => {
      setshowLoader(false)
      toastDisplay("Failed To add share holder", "error")
      setSubmitted(false)
    })
  }
  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Name": data.name,
        "Contact Number": data.contact_number,
        "Email ID": data.email_id,
        "Date of birth": data.dob,
        "Nationality": data.nationality,
        "Percentage(%) of holding": data.share_in_company + '%',
        "Direct Identification Number": data.din,
        "Authorised signatory": data.signatory,
        "Address": data.Fulladdress,
        "ITR (Only for indians)": data.itr
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }
  useEffect(() => {
    console.log("Errors", errors)
    if (Object.values(errors).every(data => data === '') && isSubmitted) {
      addShareHolderData()
    } else {
      setSubmitted(false)
    }
  }, [errors])
  console.log('statedataaaa', data)
  return (
    <>
      {!shareholder && shareholderdetails.isVisible &&
        <>
          <div className="row" >
            <div className='d-flex justify-content-between'>
              <div>
                <img className='cursor' src={"/assets/images/ArrowBackLeft.png"} alt="" onClick={() => {
                  setshareholderdetails({
                    isVisible: false,
                    data: null
                  })
                }} />
              </div>
              <div className='d-flex '>
                <img src={"/assets/images/charm_download.png"} alt="" className='px-2 cursor' onClick={async () => {
                  let pdfArray = []
                  if (!isEmpty(shareholderdetails.data.panCard)) {
                    pdfArray.push(await getPDFFromFile(shareholderdetails.data.panCard))
                  }
                  if (!isEmpty(shareholderdetails.data.aadharDoc)) {
                    pdfArray.push(await getPDFFromFile(shareholderdetails.data.aadharDoc))
                    // const filedata = await fetch('data:application/pdf;base64,' + shareholderdetails.data.aadharDoc.filebase64)
                    // const blob = await filedata.blob();
                    // const aadharFile = new File([blob], shareholderdetails.data.aadharDoc.name, { type: blob.type });
                    // const aadharpdfFile = await createPDF.PDFDocumentFromFile(aadharFile)
                    // pdfArray.push(aadharpdfFile)
                  }
                  console.log(pdfArray)
                  printDiv("shareholderdetails", `shareHolder-${shareholderdetails.data.name}`, pdfArray)
                }} />
                <img src={"/assets/images/deleteIcon.png"} alt="" className='px-2 cursor' onClick={() => {
                  deleteShareHolderById(shareholderdetails.data.id)
                }} />
              </div>
            </div>
            <div className='col-lg-10' id="shareholderdetails">
              <div className='row py-4'>
                {shareHolderDetails.map((item) => {
                  return (
                    <div className="col-md-6">
                      <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-5 px-0 shareHolderValue" > {shareholderdetails.data[item.val] ? (shareholderdetails.data[item.val]) + (item.unit ? item.unit : '') : "NA"}</span> </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <label className="font-size-15 font-wt-500">
                <u>
                  Documents
                </u>
              </label>
              <div className="row">
                <div className='col'>
                  <label className='pl-2 font-size-14'>PAN card</label>
                  <FileInput name={"auditDoc"} isEditable={false} value={shareholderdetails.data.panCard} />
                </div>
                <div className='col'>
                  <label className='pl-2 font-size-14'>Aadhaar card</label>
                  <FileInput name={"GSTDoc"} isEditable={false} value={shareholderdetails.data.aadharDoc} />
                </div>
              </div>

            </div>
          </div>
        </>



      }
      {!shareholder && !shareholderdetails.isVisible && <>

        <FilterProfile pageCount={data.pageCount} handleChange={handleChange} isDisabled={false} maxCount={totalRecords} applyAction={getShareHolderData} searchValue={data.search_text} ExcelExportFunc={() => { exportToexcel(dbData, "ExcelSheet_ShareHolders") }} pdfExportFunc={() => { printDiv("shareholderTable", "PDF_ShareHolders", []) }} />
        <div id="shareholderTable">
          <NewTable
            disableAction={true}
            columns={[{
              name: "Name", filter: true
            }, { name: "Contact no.", filter: true },
            { name: "Email ID", filter: true },
            { name: "Holdings", filter: true },
            {
              name: "Direct identification no.", filter: true
            }, { name: "", filter: false },]}
            data={tableData} />
        </div>
        <button onClick={() => setShareholder(true)} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}>Add new shareholder</button>
      </>}
      {shareholder && <>
        <a className="cursor" onClick={() => {
          setShareholder(false)
          setSubmitted(false)
        }} ><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
        <p className='text-decoration-underline text-color1 font-size-14 mt-3 mb-4'>Add new shareholder</p>
        <div className='row'>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <InputWithSelect isAstrix={true} type={"text"} label={"Name"}
                selectData={[{ name: "Mr" }, { name: "Miss" }]}
                selectName={"Commodity"} selectValue={data.name}
                optionLabel={"name"} optionValue={'name'}
                name={'name'} value={data.name} error={errors.name}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <InputWithSelect isAstrix={true} type={"text"} label={"Contact number"}
                selectData={countrys}
                selectName={"phonecode"} selectValue={data.phone_number}
                optionLabel={"phonecode"} optionValue={'phonecode'}
                name={'phone_number'} value={data.phone_number} error={errors.phone_number}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"Email ID"}
                name={"shareholderEmail"} value={data.shareholderEmail} error={errors.shareholderEmail}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"date"} label={"Date of birth"}
                name={"dob"} value={data.dob} error={errors.dob}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"Address line 1"}
                name={"add_1"} value={data.add_1} error={errors.add_1}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"Address line 2"}
                name={"add_2"} value={data.add_2} error={errors.add_2}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewSelect isAstrix={true} type={"text"} label={"City"}
                selectData={cities} optionLabel={"city"} optionValue={'city'}
                name={"city"} value={data.city} error={errors.city}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1">
            <div className="col-md-9 px-0">
              <NewSelect isAstrix={true} label={"State"}
                selectData={states} name={"state"}
                value={data.state} optionLabel={"name"} optionValue={'name'}
                onChange={handleChange} error={errors.state} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"Nationality"}
                name={"nationality"} value={data.nationality} error={errors.nationality}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} label={"Postal Code"} type="number"
                name={"postalCode"} value={data.postalCode}
                onChange={handleChange} error={errors.postalCode} />
            </div>
          </div>
          <div className="col-md-6 pt-1">
            <div className="col-md-9 px-0">
              <NewSelect isAstrix={true} label={"Country"}
                selectData={countrys} name={"shareholderCountry"}
                value={data.shareholderCountry} optionLabel={"name"} optionValue={'sortname'}
                onChange={handleChange} error={errors.shareholderCountry} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"No. of shares"}
                name={"sharePercent"} value={data.sharePercent} error={errors.sharePercent}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"Direct identification no."}
                name={"din"} value={data.din} error={errors.din}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"Authorized signatory"}
                selectData={[{ name: 'Yes' }, { name: 'No' }]} optionLabel={"name"} optionValue={'name'}
                name={"authSigntature"} value={data.authSigntature} error={errors.authSigntature}
                onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 pt-1 ">
            <div className="col-md-9 px-0">
              <NewInput isAstrix={true} type={"text"} label={"ITR (for Indians only)"}
                name={"itr"} value={data.itr} error={errors.itr}
                onChange={handleChange} />
            </div>
          </div>
        </div>

        <>
          <p className='font-size-13 mt-4 text-decoration-underline'>Attach documents</p>
          <div className='row'>
            <div className="col-md-6">
              <label className="font-size-13">Pancard</label>
              <div className="row form-group">
                <div className="col-md-11">
                  <FileInput name={"panDoc"} value={data.panDoc} error={errors.panDoc}
                    onChange={handleFile} isEditable={true}
                    onUploadCancel={() => setData({ ...data, "panDoc": null })} />
                  {errors.panDoc ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors.panDoc}</b></div> : ''}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <label className="font-size-13">Aadhaar card</label>
              <div className="row form-group">
                <div className="col-md-11">
                  <FileInput name={"aadharDoc"} value={data.aadharDoc} error={errors.aadharDoc}
                    onChange={handleFile} isEditable={true}
                    onUploadCancel={() => setData({ ...data, "aadharDoc": null })} />
                  {errors.aadharDoc ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors.aadharDoc}</b></div> : ''}
                </div>
              </div>
            </div>
          </div>
        </>
        <button onClick={() => {
          setErrors(validateAddShareholder(data))
          setSubmitted(true)
        }} className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}>Add shareholder details</button>
      </>}
    </>
  );
}
export default Shareholder;