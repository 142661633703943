import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import Header from '../homeComponents/header';
import validate from '../../utils/validation_files/finTechKYCFormValidation';
import { ToastContainer } from 'react-toastify';
import { setRegistrationData, setaddBankObj } from '../../store/actions/action';
import toolTipText from '../../utils/toolTips/onboardingProfileForm.json'
import { finTypeKYCDocs } from "./objectsForReg.js";
import toastDisplay from '../../utils/toastNotification';


const FinTechKYCForm = ({ userTokenDetails, registrationData, setRegistrationData, dbData, setaddBankObj, dispatch }) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  var isAdminModal = !(window.location.href.includes('registration'));

  //User details from cookie
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null

  console.log('registrationData in main page==>', registrationData);

  useEffect(() => {
    //------------------------------------------------------------------
    if (registrationData.payload.hasOwnProperty('kycDetail')) {
      setValues({
        cinDocumentName: registrationData.payload.kycDetail.cinDocumentName,
        gstDocumentName: registrationData.payload.kycDetail.gstDocumentName,
        iecDocumentName: registrationData.payload.kycDetail.iecDocumentName,
        panDocumentName: registrationData.payload.kycDetail.panDocumentName,
        organizationType: registrationData.payload.kycDetail.organizationType
      })
    }
    if (dbData.iec) {
      setValues({ ...values, gstDocumentName: dbData.gst, iecDocumentName: dbData.iec })
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleNext();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errors]);

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(registrationData.type, values, finTypeKYCDocs));
    setIsSubmitting(true);
  };

  const handleBack = () => {
    registrationData.type = 19;
    setRegistrationData(registrationData.type, 0, registrationData.payload)
  }

  const handleNext = async function () {
    if (values.organizationType != 'foreign') {
      registrationData.payload.kycDetail = values;
      setshowLoader(true)
      call('POST', 'getKYCDetail', values).then((result) => {
        console.log(result);
        if (result.company_name != '') {
          console.log(result.company_name);
          registrationData.payload.step1 = {
            "companyName": result.company_name,
            "contactPerson": result.company_name,
            "companyAddress": result.company_address,
            "email": result.company_email,
            "contactNo": result.company_mobile,
            "gst_company_detail": result.gst_company_detail ? result.gst_company_detail : null,
            "iec_company_detail": result.iec_company_detail ? result.iec_company_detail : null,
            "cin_company_detail": result.cin_company_detail ? result.cin_company_detail : null,
            "type": "finTech"
          };
          setRegistrationData(registrationData.type, 2, registrationData.payload);
          toastDisplay('KYC Verified for ' + result.company_name + '', "success");
        }
        else {
          toastDisplay("Your KYC is Not-Verified", "error");
        }
        setshowLoader(false)
      }).catch((e) => {
        console.log('error in getKYCDetail', e);
      })
    }
    else {
      registrationData.payload.kycDetail = values;
      setRegistrationData(registrationData.type, 2, registrationData.payload);
    }

  }

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    setErrors(errors => ({ ...errors, [event.target.name]: "" }));
  };

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function mainForm() {
    return (
      <form className="pr-4">

        <div className="form-group">
          <label>Organization Type </label>
          <select className={" form-control" + (!errors.organizationType ? '' : ' border-danger')} name="organizationType" value={values.organizationType} onChange={handleChange} >
            <option value="" selected>Select Organization type</option>
            <option value="soleProprietorship">Sole Proprietorship</option>
            <option value="partnership">Partnership</option>
            <option value="pvtPubLtd">PVT/PUB.LTD</option>
            <option value="llp">LLP</option>
            <option value="foreign">Foreign Entity</option>
          </select>
          {errors.organizationType && (<p className="text-danger error-contract">{errors.organizationType}</p>
          )}
        </div>

        {values.organizationType && values.organizationType != 'foreign' && finTypeKYCDocs.map((item) => {
          return (<>
            {item.organizationType.includes(values.organizationType) && <>
              <div className="form-group pb-0 mb-0" id={item.key}>
                <ul className="other-documents pb-0 mb-0">
                  <li style={{ "min-width": "250px" }}>
                    <label className="mb-0" >
                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                      {item.name} Number
                      {([5, 6, 7, 10, 11, 13, 17, 18].includes(registrationData.type) && item.name == 'IEC') ? '' :
                        <span className="required-field text-danger">*</span>
                      }
                      <span>{toolTip(item.key)}</span>
                    </label>
                  </li>
                  <li style={{ "min-width": "250px" }}>
                    <input type="text" maxLength={item.length} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!errors[item.key + "Name"] ? '' : ' border-danger')} placeholder={"Enter " + item.name + " Number"} name={item.key + "Name"} value={values[item.key + "Name"]} onChange={handleChange} required />
                    {errors[item.key + "Name"] ? <p className="text-danger error-contract p-0 m-0">{errors[item.key + "Name"]}</p> : <p className="text-dark error-contract p-0 m-0">{item.name + " Number"}</p>}
                  </li>
                </ul>
                <hr className="" />
              </div>
            </>}
          </>)
        })}
      </form>
    )
  }


  //--------final return[start]--------
  return (
    <div className="on-bording">
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <ul className="next-button">
        <li ><button type="button" onClick={() => { setRegistrationData(2, 1, registrationData.payload) }}>{"<< Previous"}</button></li>
        <li >
          {[5, 6, 7, 10, 11, 13, 17, 18, 19, 20].includes(registrationData.type) ? <button type="button" onClick={() => preHandleSubmit()}>{"Next >>"}</button>
            : ''}
        </li>
      </ul>

      <div className="container-fluid">
        <div className="row">
          <Header title={"Provide Your Details"} />
          <div className="col-md-8 mx-auto bg-white rounded p-4 mt-3 calc">
            <div className="row">
              <div className="col-md-1">
              </div>
              <div className="col-md-11 calc-inner mt-5">
                {mainForm(isAdminModal)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  //--------final return[end]--------
}



const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) },
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinTechKYCForm)