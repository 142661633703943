import React from "react";
import { useState } from "react";
import { PaginationComponent } from "../../viewProfile/components/PaginationComponent";

const FilterProfile = ({ pageCount, handleChange, isDisabled, maxCount, applyAction, searchValue, pdfExportFunc, ExcelExportFunc }) => {
  const [dropdown, setDropDown] = useState(false)
  return (

    <div className="d-flex align-items-center justify-content-between">
      <PaginationComponent name={"pageCount"} onChange={handleChange} isDisabled={isDisabled} type={"number"} maxValue={maxCount} value={pageCount} />
      <div className="">
        <button type="button" className="btn btn-light bg-white px-4 border border-radius-12 text-start d-none"><img src={"assets/images/filter.png"} alt="" /><span className="px-2">Filter</span></button>
      </div>
      <div className="input-group mb-3 col-md-3 currency mt-3 pe-1">
        <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
        <input type="text" name='search_text' onChange={handleChange}
          className="form-control border-start-0" placeholder="Search" value={searchValue} />
      </div>
      <button onClick={applyAction} className={`my-4 new-btn w-8 py-2 px-3 text-white cursor`}>Apply</button>
      <div className="downloadDiv cursor">
        <div onClick={() => setDropDown(!dropdown)}>
          <img src="/assets/images/downloadBu.png" alt="" />
          <label className="font-size-14 px-2 pb-1">Download</label>
        </div>
        {dropdown &&
          <>
            <div className="row dropdowndiv mt-4" >
              <label className="py-2 LabelBottom cursor" onClick={() => {
                ExcelExportFunc()
                setDropDown(false)
              }}>Export to Excel</label>
              <label className="cursor" onClick={() => {
                pdfExportFunc()
                setDropDown(false)
              }}>Export as PDF</label>
            </div>
          </>

        }
      </div>
    </div>
  );
}
export default FilterProfile;