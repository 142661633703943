import React, { useEffect, useState } from 'react'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import { connect } from 'react-redux'
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import toastDisplay from '../../utils/toastNotification'
import { InputForTable, InputWithSelect, NewInput, NewSelect, SelectForTable } from '../../utils/newInput'
import call from '../../service'
import { FileInput } from '../../utils/FileInput'
import { SmallFileInput } from '../../utils/SmallFileInput'
import moment from 'moment'
import NewTablev2 from '../../utils/newTablev2'
import { ExportExcel, convertImageToPdf, dataURItoBlob, getDocDetails, most_used_currencies, multiSelectEventHandler, replaceFileBase64WithFileObj } from '../../utils/myFunctions'
import axios from 'axios'
import { aiServerBackendUrl, environment, platformBackendUrl } from '../../urlConstants'
import SignDocument from '../InvoiceDiscounting/components/SignDocument'
import { DocumentViewer } from '../../utils/documentViewer'
import { DocumentDownloader } from '../../utils/DocumentDownloader'
import { ToastContainer } from 'react-toastify'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import avatarUrl from '../../utils/makeAvatarUrl'
import { industryData } from '../registration/newRegistration'
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2'
import XLSX, { readFile } from 'sheetjs-style';
import Papa from 'papaparse';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication'
import { FormProgressBar } from '../CommonComponent/FormProgressBar'
import MultipleSelect from '../../utils/MultipleSelect'
import MultipleSelectForTable from '../../utils/MultipleSelectForTable'
import ReactCountryFlag from 'react-country-flag'

const scmformtabs = [
  { name: "Documents" },
  { name: "Company Details" },
  { name: "Vendor Details" },
  { name: "Select financer" },
  { name: "Review" }
]

export const companyDetailsReviewForm = [
  { "name": "Business Name", val: "companyName" },
  { "name": "Aadhar Number", val: "aadharNo" },
  { "name": "Contact Person", val: "contact_person" },
  { "name": "PAN Number", val: "panno" },
  { "name": "Contact number", val: "contact_number" },
  { "name": "Constitution", val: "deliveryIFSCCode" },
  { "name": "Email ID", val: "email_id" },
  { "name": "Address", val: "user_address" },
  { "name": "Start date of business", val: "startDateOfBusiness" },
  { "name": "Industry Type", val: "industry", },
  { "name": "Competition", val: "competition" },
  { "name": "Product Details", val: "productDetails" },
  { "name": "Sub-Sector", val: "subSector" }
]

const ownerReview = [
  { "name": "Owner Name", val: "ownerName" },
  { "name": "Category", val: "ownerCategory" },
  { "name": "Contact No.", val: "ownerContactNo", unit: "ownerPhoneCode" },
  { "name": "PAN No", val: "ownerPanNo" },
  { "name": "Email Id", val: "ownerEmailId" },
  { "name": "Aadhar No.", val: "ownerAadharNo" },
  { "name": "Address", val: "ownerAddressLine", after: "ownerAddressLine" },
  { "name": "Date of Birth", val: "ownerDOB" },
  { "name": "Net Worth", val: "ownerNetWorth" }
]

export const FinancialMonths = [
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March"
]
const reviewLabels = [
  { name: 'Total Finance Application', color: "#1B94B7", alt: "totalFinanceApplied" },
  { name: 'Application Rejected', color: '#FE4141', alt: "applicationRejected" },
  { name: 'Application Approved', color: '#00B74D', alt: "applicationApproved" },
]
export let yesnoqsts = {
  "qst1": {
    label: "Women Entrepreneur"
  },
  "qst2": {
    label: "ISO certified"
  },
  "qst3": {
    label: "Business continuity indemnity policy"
  },
  "qst4": {
    label: "The agreement between the client and the debtor does not prohibit assignment of receivables by the client to a third party including a Factoring Company."
  },
  "qst5": {
    label: "Any irregular and slow payments in the past by the proposed debtor."
  },
  "qst6": {
    label: "Are there any overdue invoices outstanding on account of the debtor in the client’s books."
  },
  "qst7": {
    label: "Whether there have been any disputes with the debtor in recent past with respect to the goods supplied by the client to the debtor or otherwise."
  },
  "qst6": {
    label: "Inform whether direct contact allowed"
  }
}
let currentYear = moment().format("YYYY")

let financialYearMonths = [{ name: "April", lastYear: true }, { name: "May", lastYear: true }, { name: 'June', lastYear: true }, { name: 'July', lastYear: true }, { name: "August", lastYear: true }, { name: "Sept", lastYear: true }, { name: "Oct", lastYear: true },
{ name: "Nov", lastYear: true }, { name: 'Dec', lastYear: true }, { name: 'Jan' }, { name: "Feb" }, { name: "March" }]

let last5Years = [
  { label: `${currentYear - 1}-${currentYear}` },
  { label: `${currentYear - 2}-${currentYear - 1}` },
  { label: `${currentYear - 3}-${currentYear - 2}` },
  { label: `${currentYear - 4}-${currentYear - 3}` },
  { label: `${currentYear - 5}-${currentYear - 4}` }]

let bankDocsInitial = [
  { name: 'Bank statement of all working capital & Current accounts  (last 12 months)', alt: "bankStatement" },
  { name: 'Limit details & sanctioned letter of existing banking lines', alt: 'limdetailsExisting' },
  { name: 'Add More', alt: "moreDoc1" }
]

let financialDocsInitial = [
  { name: 'Audited financials including ITR', alt: "aduFin", docArray: [{ valKey: "AudFin_0", yearKey: "AudFinYear_0" }, { valKey: "AudFin_1", yearKey: "AudFinYear_1" }] },
  { name: 'Ledger with all the buyer in the term sheet (last 12 months)', alt: 'limdetailsExisting' },
  { name: 'Stock statement shared with banks ageing breakup of debtor /creditors (last 2 months)', alt: "stockStatement" },
  { name: 'Add More', alt: "FinmoreDoc1" }
]

let organisationalDocs = [
  { name: "Partnership deed (in case of partnership firm)", alt: 'partnerShipdeed' },
  { name: "", docArray: [{ name: "Aadhaar Card", partnerNameKey: "parnter1AadhaarName", valKey: 'aadharDocPartner1' }, { name: "PAN Card", partnerNameKey: "parnter1PANName", valKey: 'PANDocPartner1' }] },
  { name: "", docArray: [{ name: "Aadhaar Card", partnerNameKey: "parnter2AadhaarName", valKey: 'aadharDocPartner2' }, { name: "PAN Card", partnerNameKey: "parnter2PANName", valKey: 'PANDocPartner2' }] },
  { name: 'Add More', alt: "organisationmoreDoc1" }
]

let suppliersFormat = [{
  "Supplier Name": "",
  "Address": "",
  "Credit Limit Required ($)": "",
  "Terms Of Payment (days)": "",
  "Annual Sale ($)": "",
  "Years of Relation": ""
}]
let buyersFormat = [{
  "Buyer Name": "",
  "Address": "",
  "Credit Limit Required ($)": "",
  "Terms Of Payment (days)": "",
  "Annual Sale ($)": "",
  "Years of Relation": ""
}]

const ApplyLimitSCF = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const formContinue = queryParams.get("formContinue")

  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ selectedFin: [], ownerNameTitle0: 'Mr', ownerPhoneCode0: '91', ownerCountry0: 'India' })
  const [errors, setErrors] = useState({})
  const [gstReturns, setGstReturns] = useState([null, null])
  const [bankDocs, setBankDocs] = useState(bankDocsInitial)
  const [finDocs, setFinDocs] = useState(financialDocsInitial)
  const [orgDocs, setOrgDocs] = useState(organisationalDocs)
  const [showLoader, setshowLoader] = useState(false)
  const [gstToggle, setGstToggle] = useState(0)
  const [signdoc, setSigndoc] = useState(false);
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})
  const [financiers, setFinList] = useState([])
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null])
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })
  const [owners, setOwners] = useState([null])
  const [countryData, setCountryData] = useState([])
  const [states, setstatedata] = useState([])

  const [finActionTab, setFinancierActionTab] = useState(0)
  const [draftData, setDraftData] = useState([])
  const [shouldSaveCache, toggleShouldSaveCache] = useState(formContinue ? false : true)
  const [cacheId, setCacheId] = useState(formContinue || null)

  const [sellers, setSellers] = useState([])
  const [sellersFiltered, setSellersFiltered] = useState([])
  const [buyers, setBuyers] = useState([])
  const [buyersFiltered, setBuyersFiltered] = useState([])

  const userData = localStorage.getItem("UserDetails")

  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const ttvExporterCode = userToken.ttvExporterCode ? userToken.ttvExporterCode : ''
  const subUserName = userToken?.sub_user_name
  const mainUserName = userToken?.main_user_name

  let jj = -1
  let kk = -1
  let ll = -1

  useEffect(() => {
    if (shouldSaveCache) {
      saveCache()
    }
  }, [data, tab, owners, top10Buyers, top10Suppliers])


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("applylimitSCF");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  async function saveCache() {
    let reqBody = { userId, forSCF: true }
    reqBody["details"] = { data, tab, owners, top10Buyers, top10Suppliers, percentageComplete: (tab) * 20 }
    if (cacheId) {
      reqBody["cacheId"] = cacheId
    }
    let tempCacheId = await call('POST', 'saveInvoiceLimitCache', reqBody)
    if (tempCacheId) {
      setCacheId(tempCacheId)
    }
  }

  useEffect(() => {
    if (cacheId) {
      getCache()
    }
    getBuyerDetails()
    getSellers()
  }, [])

  const getBuyerDetails = () => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyerDetailsV2', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      let tempResultBuyerData = result.buyerData
      for (let index = 0; index < tempResultBuyerData.length; index++) {
        tempResultBuyerData[index][`buyerNameHtml`] = <div>
          <label className="font-size-12 font-wt-500" >{`${tempResultBuyerData[index]["buyerName"]}`}
            <ReactCountryFlag
              countryCode={tempResultBuyerData[index].buyerCountry}
              style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
          </label>
        </div>
      }
      setBuyers([...tempResultBuyerData]);
      setBuyersFiltered([...tempResultBuyerData]);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)

    });
  }

  const getSellers = () => {
    setshowLoader(true)
    let objectAPI = {
      userId,
      userEmail,
      userTypeId,
      "type": "all",
      "currentPage": 1,
      "resultPerPage": 500,
      "search": "",
      "ttvExporterCode": ttvExporterCode
    }
    call('POST', 'getSuppliersInNetwork', objectAPI).then((result) => {
      // console.log('running getSuppliersInNetwork api-->', result);
      setshowLoader(false)
      let tempResultSellerData = result.data
      for (let index = 0; index < tempResultSellerData.length; index++) {
        tempResultSellerData[index][`sellerNameHtml`] = <div>
          <label className="font-size-12 font-wt-500" >{`${tempResultSellerData[index]["company_name"]}`}
            <ReactCountryFlag
              countryCode={tempResultSellerData[index].country_code}
              style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
          </label>
        </div>
      }
      setSellers([...tempResultSellerData]);
      setSellersFiltered([...tempResultSellerData]);
    }).catch((e) => {
      // console.log('error in getSuppliersInNetwork', e);
      setshowLoader(false)
    });
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredSellers = []
    let tempSellers = sellers
    tempSellers.push({ tbl_user_id: new Date().getTime(), sellerNameHtml: typedInput, company_name: typedInput })
    if (name.includes("company_name")) {
      if (typedInput) {
        filteredSellers = tempSellers.filter((i) => {
          if (i.company_name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredSellers = tempSellers
      }
      setSellersFiltered([...filteredSellers])
    }
    let filteredBuyers = []
    let tempBuyers = buyers
    tempBuyers.push({ id: new Date().getTime(), buyerNameHtml: typedInput, buyerName: typedInput })
    if (name.includes("buyerName")) {
      if (typedInput) {
        filteredBuyers = tempBuyers.filter((i) => {
          if (i.buyerName.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBuyers = tempBuyers
      }
      setBuyersFiltered([...filteredBuyers])
    }
  }

  async function getCache() {
    let cacheDetails = await call('POST', 'getInvoiceLimitCacheById', { cacheId, forSCF: true })
    if (cacheDetails?.details) {
      setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
      setTab(cacheDetails.details.tab)
      setOwners([...cacheDetails.details.owners])
      setTop10Buyers(cacheDetails.details.top10Buyers)
      setTop10Suppliers(cacheDetails.details.top10Suppliers)
      toggleShouldSaveCache(true)
    }
  }

  useEffect(() => {
    call('POST', 'getAllInvoiceLimitDrafts', { userId, forSCF: true }).then((res) => setDraftData(res))
  }, [])

  const handleCSV = (event, userTypee) => {
    event.persist();
    if (!event.target.files.length) {
      return null
    }
    setErrors({ ...errors, [event.target.name]: "" });
    if (!event.target.files[0]['name'].includes("csv")) {
      setErrors({ ...errors, [event.target.name]: "Please select csv file" });
      return
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      Papa.parse(event.target.files[0], {
        complete: (result) => {
          // console.log("csvParseComplete=======>", result);
          let tempObj = {}
          let tempTop3SuppliersData = top10Suppliers
          let tempTop3BuyersData = top10Buyers
          let tempSellers = sellersFiltered
          let tempBuyers = buyersFiltered
          for (let index = 0; index < result?.data?.length; index++) {
            const element = result.data[index];
            if (userTypee == "buyer" && element["Buyer Name"]) {
              let buyerId = Math.floor(10000 + Math.random() * 90000)
              tempBuyers.push({ id: buyerId, buyerNameHtml: element["Buyer Name"], buyerName: element["Buyer Name"] })

              tempTop3BuyersData.push(null)
              let arrIndx = tempTop3BuyersData.length - 1
              tempObj[`selectedBuyer${arrIndx}`] = buyerId
              tempObj[`buyerName${arrIndx}`] = element["Buyer Name"] || ""
              tempObj[`buyerAddress${arrIndx}`] = element["Address"] || ""
              tempObj[`buyerCreditLimitRequired${arrIndx}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
              tempObj[`buyerTermsOfPayment${arrIndx}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
              tempObj[`buyerAnnualSale${arrIndx}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
              tempObj[`buyerYearsOfRelation${arrIndx}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
              tempObj[`buyerRelationWithClient${arrIndx}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
            }
            else if (userTypee === "supplier" && element["Supplier Name"]) {
              let sellerId = Math.floor(10000 + Math.random() * 90000)
              tempSellers.push({ tbl_user_id: sellerId, sellerNameHtml: element["Supplier Name"], company_name: element["Supplier Name"] })

              tempTop3SuppliersData.push(null)
              let arrIndx = tempTop3SuppliersData.length - 1
              tempObj[`selectedSeller${arrIndx}`] = sellerId
              tempObj[`supplierName${arrIndx}`] = element["Supplier Name"] || ""
              tempObj[`supplierAddress${arrIndx}`] = element["Address"] || ""
              tempObj[`supplierCreditLimitRequired${arrIndx}`] = !isNaN(element["Credit Limit Required ($)"]) ? element["Credit Limit Required ($)"] : ""
              tempObj[`supplierTermsOfPayment${arrIndx}`] = !isNaN(element["Terms Of Payment (days)"]) ? element["Terms Of Payment (days)"] : ""
              tempObj[`supplierAnnualSale${arrIndx}`] = !isNaN(element["Annual Sale ($)"]) ? element["Annual Sale ($)"] : ""
              tempObj[`supplierYearsOfRelation${arrIndx}`] = !isNaN(element["Years of Relation"]) ? element["Years of Relation"] : ""
              tempObj[`supplierRelationWithClient${arrIndx}`] = !isNaN(element["Relation with Client ($)"]) ? element["Relation with Client ($)"] : ""
            }
          }
          setSellersFiltered([...tempSellers])
          setBuyersFiltered([...tempBuyers])
          setData({ ...data, ...tempObj, [event.target.name]: event.target.files[0] })
          setErrors({ ...errors, [event.target.name]: "" });
          setTop10Buyers([...tempTop3BuyersData])
          setTop10Suppliers([...tempTop3SuppliersData])
          console.log("tempObjjjjjjjjjjjjjjjjjjjjj", tempObj, tempTop3SuppliersData);
        },
        header: true
      });
    }
    reader.readAsText(event.target.files[0]);
  }


  async function handleValidation() {
    let err = {}
    if (tab === 1) {
      let panNoRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/
      if (!panNoRegex.test(data.panno)) {
        err["panno"] = "Invalid PAN No"
      }
      let aadharNoRegex = /^\d{4}-\d{4}-\d{4}$/
      if (data.aadharNo && !aadharNoRegex.test(data.aadharNo)) {
        err["aadharNo"] = "Invalid Aadhar No"
      }
    }
    if (tab === 2) {
      let isSupplierSelected = false
      for (let index = 0; index < top10Suppliers.length; index++) {
        if (top10Suppliers[index] === null && data[`supplierName${index}`]) {
          isSupplierSelected = true
        }
      }
      if (!isSupplierSelected) {
        err["supplierName"] = "Kindly enter atleast 1 buyer details"
        toastDisplay("Kindly enter atleast 1 supplier details", "info")
      }
      else {
        setTab(3)
      }
    }
    else if (tab === 3) {
      let isFinSelected = false
      for (let index = 0; index < financiers.length; index++) {
        const element = financiers[index];
        if (element.isChecked) {
          isFinSelected = true
        }
      }
      if (!isFinSelected) {
        toastDisplay("Kindly select atleast 1 financer", "info")
      }
      else {
        setTab(tab + 1)
      }
    }
    else if (tab === 4) {
      if (!data.tcAccepted) {
        toastDisplay('kindly accept declaration, to continue', "info")
        err["tcAccepted"] = 'kindly accept declaration, to continue'
      }
    }
    else {
      if (!Object.keys(err).length) {
        setTab(tab + 1)
      }
    }
    setErrors(err)
    if (!Object.keys(err).length && tab === 4) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("userId", userId)
      formData.append("userName", userName)
      Object.keys(data).forEach(key => {
        if (!key.includes("Document")) {
          formData.append(key, data[key])
        }
      })
      if (data.securityDocument?.name) {
        formData.append("securityDocument", data.securityDocument)
        if (data[`securityDocument`].signatureId) {
          formData.append("securityDocumentSignId", data[`securityDocument`].signatureId)
        }
      }
      formData.append("finList", JSON.stringify(financiers))
      formData.append('to', "+917310004469");
      formData.append('templateName', "scf_limit");
      formData.append('mediaUrl', "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
      formData.append('filename', "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
      formData.append('placeholders', JSON.stringify(["nikita"]));
      call('POST', 'applyForSCFLimit', formData).then(async res => {
        setshowLoader(false);
        toggleApplicationSubmitPopup({ show: true, data: {} })
        if (cacheId) {
          await call("POST", 'clearInvoiceLimitCacheById', { cacheId, forSCF: true })
        }
        if (userTokenDetails.type_id / 1 == 1) {
          localStorage.clear("UserDetails")
          window.close()
        }
      }).catch(err => { setshowLoader(false); toastDisplay("Something went wrong") })
    }
  }
  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountryData(result.data.message);
      }
    });
    getStateByCountry(101)
  }, [])
  const getStateByCountry = (countryId) => {
    call("POST", "getStateByCountry", { countryId }).then(result => {
      console.log('Success getStateByCountry data', result)
      setstatedata(result)
    }).then(e => {
      console.log("Error in getStateByCountry", e)
    })
  }
  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
  const handleChange = async (event, indx) => {
    console.log('dataaaaaaa', event);
    if (event.persist) {
      event.persist()
    }
    if (event.target.name?.includes("selectedSeller") && event.target.value) {
      let seller = sellers.filter((i, j) => { return i.tbl_user_id == event.target.value })?.[0]
      setData({
        ...data, [event.target.name]: event.target.value, [`supplierName${indx}`]: seller?.company_name,
        [`supplierAddress${indx}`]: seller?.user_address
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name?.includes("selectedBuyer") && event.target.value) {
      let buyer = buyers.filter((i, j) => { return i.id / 1 == event.target.value / 1 })?.[0]
      setData({
        ...data, [event.target.name]: event.target.value, [`buyerName${indx}`]: buyer?.buyerName,
        [`buyerAddress${indx}`]: buyer?.buyerAddress
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }
  async function validateUserLogin() {
    let validateFields = ["gstUsername", "gstPassword", "typedCaptcha"]
    let err = {}
    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      }
    }
    if (data.typedCaptcha && validateCaptcha(data.typedCaptcha) != true) {
      err["typedCaptcha"] = "Invalid Captcha"
    }
    if (!Object.keys(err)?.length) {
      // setshowLoader(true)
      toastDisplay("Fetching GST details in background", "info")
      call('POST', 'fetchUserGSTReport', { userId, gstUsername: data.gstUsername, gstPassword: data.gstPassword })
        .then(res => {
          setData({ ...data, gstDetailsFetched: true })
          toastDisplay("GST details fetched", "success")
          setshowLoader(false)
        }).catch(err => {
          toastDisplay("Unable to fetch GST details, check if credentials are proper", "error")
          setshowLoader(false)
        })
    }
    setErrors(err)
  }

  const handleFileWithAsyncAwait = async (event) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setshowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setshowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }
  console.log('dataaaaaaaaaaa', data);
  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (event.target.name === 'topBuyersDetail') {
          const workbook = XLSX.read(e.target.result.split("base64,")[1], { type: 'base64' })
          const worksheetname = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[worksheetname]
          const finalRes = XLSX.utils.sheet_to_json(worksheet)
          console.log('finalREssssss', finalRes);
          let totaldata = 0
        }
        if (event.target.name === 'topsupplierDetails') {
          const workbook = XLSX.read(e.target.result.split("base64,")[1], { type: 'base64' })
          const worksheetname = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[worksheetname]
          const finalRes = XLSX.utils.sheet_to_json(worksheet)
          console.log('finalREssssss', finalRes);
        }

        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            setshowLoader(false)
            console.log('error in conversion', error);
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        let tempData = { ...data, [event.target.name]: fileObj }
        if (event.target.name.includes('aadharDoc') || event.target.name.includes('panDoc')) {
          toastDisplay('Analyzing document kindly wait', "info")
          setshowLoader(true)
          let formData = new FormData()
          formData.append(`doc`, fileObj)
          let kycOCRResp = await axios.post(`${aiServerBackendUrl}/analyzeKYCDoc`, formData, {
          })
          if (kycOCRResp?.data?.doc_number) {
            tempData[`${event.target.name}DocNo`] = kycOCRResp.data?.doc_number
            tempData[`partnerName:${event.target.name.split(":")[1]}`] = kycOCRResp.data?.user_name
          }
        }
        setshowLoader(false)
        setData(tempData)
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
  const getFinancers = async () => {
    setshowLoader(true)
    let finApiResp = await call('POST', "getFinanciersForSCF", {})
    let finIds = []
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
      finIds.push(element.id)
    }
    let tempData = {
      ...data,
      selectedFin: finIds
    }
    fetchUserDetails(tempData)
    setshowLoader(false)
    setFinList(finApiResp)
  }
  function handleFinSelection(j) {
    let temp = financiers
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setFinList([...temp])
  }

  useEffect(() => {
    if (countryData.length) {
      getFinancers()
    }
  }, [countryData])

  async function fetchUserDetails(tempData) {
    let resp = await call("POST", "getUserDetailsById", { contactUserId: userId })
    let userResp = resp?.[0]
    let userIECResp = await call("POST", "getUserDetailsFromIEC", { userId })
    // console.log("userIECResppppppppppppp", userIECResp, userResp);
    let userDirectorsResp = await call("POST", "getDirectorDetailsFromKarza", { userId })
    let tempOwners = []
    if (userDirectorsResp?.length) {
      for (let index = 0; index < userDirectorsResp.length; index++) {
        const element = userDirectorsResp[index];
        tempData[`ownerNameTitle${index}`] = 'Mr'
        tempData[`ownerName${index}`] = `${element.firstName} ${element.lastName}`
        tempData[`ownerAddressLine1${index}`] = element?.address
        tempData[`ownerPanNo${index}`] = element?.pans?.[0]
        tempData[`ownerDOB${index}`] = element?.dateOfBirth ? moment(element?.dateOfBirth).format("YYYY-MM-DD") : ""
        tempData[`ownerGender${index}`] = element.gender
        tempData[`ownerCountry${index}`] = element.country
        tempData[`ownerCity${index}`] = element.city
        tempData[`ownerState${index}`] = element.state
        tempData[`ownerPostalCode${index}`] = element.pincode
        tempData[`ownerContactNo${index}`] = element.additionalContacts?.[0]
        tempData[`ownerEmailId${index}`] = element.email
        tempData[`ownerPhoneCode${index}`] = countryData.filter((i) => {
          if (i.name === element.country) {
            return true
          }
        })?.[0]?.phonecode
        tempOwners.push(null)
      }
      setOwners([...tempOwners])
    }
    setData({
      ...tempData,
      companyName: userResp.company_name,
      nameTitle: userResp.name_title,
      panno: userResp.pan_no,
      aadharNo: userResp.aadhar_no,
      contact_person: userResp.contact_person,
      contact_number: userResp.contact_number,
      phone_code: userResp.phone_code,
      email_id: userResp.email_id,
      companyAdd1: userResp.user_address,
      city: userResp.company_city,
      state: userResp.company_state,
      country: userResp.country_code,
      PostalCode: userResp.company_postal_code,
      industry: industryData?.filter(i => {
        if (i.value === userResp.industry_type) {
          return true
        }
      })?.[0]?.["name"],
      startDateOfBusiness: userIECResp?.dateOfIncorporation ? moment(userIECResp.dateOfIncorporation).format("YYYY-MM-DD") : null,
      user_address: userResp.user_address,
      userResp: userResp
    })


  }

  const fetchOwnerDetails = (ownerIndex, action) => {
    const userResp = data.userResp
    let objectToSet = {
      [`ownerName${ownerIndex}`]: "",
      [`ownerNameTitle${ownerIndex}`]: "",
      [`ownerContactNo${ownerIndex}`]: "",
      [`ownerPhoneCode${ownerIndex}`]: "",
      [`ownerEmailId${ownerIndex}`]: "",
      [`ownerAddressLine1${ownerIndex}`]: "",
      [`ownerAddressLine2${ownerIndex}`]: "",
      [`ownerCity${ownerIndex}`]: "",
      [`ownerState${ownerIndex}`]: "",
      [`ownerCountry${ownerIndex}`]: "",
      [`ownerPostalCode${ownerIndex}`]: "",
      [`ownerPanNo${ownerIndex}`]: "",
      [`ownerAadharNo${ownerIndex}`]: ""
    }
    if (action) {
      setData({ ...data, ...objectToSet, [`sameAsUserDetailsOwner${ownerIndex}`]: false })
    }
    else {
      objectToSet = {
        [`ownerName${ownerIndex}`]: userResp.contact_person,
        [`ownerNameTitle${ownerIndex}`]: userResp.name_title,
        [`ownerContactNo${ownerIndex}`]: userResp.contact_number,
        [`ownerPhoneCode${ownerIndex}`]: userResp.phone_code,
        [`ownerEmailId${ownerIndex}`]: userResp.email_id,
        [`ownerAddressLine1${ownerIndex}`]: userResp.user_address,
        [`ownerAddressLine2${ownerIndex}`]: "",
        [`ownerCity${ownerIndex}`]: userResp.company_city,
        [`ownerState${ownerIndex}`]: userResp.company_state,
        [`ownerCountry${ownerIndex}`]: userResp.country_code,
        [`ownerPostalCode${ownerIndex}`]: userResp.company_postal_code,
        [`ownerPanNo${ownerIndex}`]: userResp.pan_no,
        [`ownerAadharNo${ownerIndex}`]: userResp.aadhar_no
      }
      setData({ ...data, ...objectToSet, [`sameAsUserDetailsOwner${ownerIndex}`]: true })
    }
  }
  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('bgApplicationId', selectedChat.bgApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }
  return (
    <div className="container-fluid">
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userToken}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup(false)
          window.location = 'scfQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >Supply Chain Finance </span> has been submitted successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div>

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <SideBarV2 state="otherFinSCFApplyLimit" userTokenDetails={userToken} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"Supply Chain Finance  >   Apply for Limit"}
            userTokenDetails={userToken} />
          <a
            onClick={() => {
              window.location = '/scfQuote'
            }}
            className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
          <div>
            {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
              {scmformtabs.map((item, index) => {
                return (
                  <li>
                    <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      onClick={() => { setTab(index) }}
                    >
                      {item.name}</a>
                  </li>
                )
              })}
            </ul> */}
            <div className="card mt-1">

              {data.applicationType ? <>
                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={scmformtabs} activeTab={tab} label={"name"}
                    separationWidth={'2.5rem'} handleClick={(i, index) => { setTab(index) }}
                  />
                </div>
                {
                  tab === 0 ? (
                    <div className='py-4 px-3'>
                      <ExtraDetailsForLimitApplication userTokenDetails={userToken} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton={true} afterDetailsSavedOn={() => setTab(1)}
                        loadDataInWriteModeFromCacheIfPresent={true} continueBtnCSS={{ opacity: 0, height: 0 }} />
                    </div>
                  ) : null
                }

                {tab === 1 ? (
                  <div className='p-4'>
                    <div>
                      <label className='font-size-14 font-wt-500 text-decoration-underline'>Business details</label>
                      <div className='row'>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"text"} label={"Business Name"}
                            name={"companyName"} value={data.companyName} error={errors.companyName}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"text"} label={"PAN No."}
                            name={"panno"} value={data.panno} error={errors.panno}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput type={"text"} label={"Aadhaar No."}
                            name={"aadharNo"} value={data.aadharNo} error={errors.aadharNo}
                            onChange={e => {
                              let value = e.target.value
                              value = value.replace(/\D/g, '')
                              value = value.replace(/(\d{4})(?=\d)/g, '$1-')
                              setData({ ...data, aadharNo: value })
                            }} />
                        </div>
                        <div className='col-md-4'>
                          <InputWithSelect isAstrix={true} type={"text"} label={"Contact Person"}
                            selectData={[{ name: "Mr" }, { name: "Miss" }]}
                            selectName={"nameTitle"} selectValue={data.nameTitle}
                            optionLabel={"name"} optionValue={'name'}
                            name={'contact_person'} value={data.contact_person} error={errors.contact_person}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <InputWithSelect type={"number"} label={"Contact Number"}
                            selectData={countryData}
                            selectName={"phone_code"} selectValue={data.phone_code}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"email"} label={"Email ID"}
                            name={"email_id"} value={data.email_id} error={errors.email_id}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"text"} label={"Address Line 1"}
                            name={"companyAdd1"} value={data.companyAdd1} error={errors.companyAdd1}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput type={"text"} label={"Address Line 2"}
                            name={"companyAdd2"} value={data.companyAdd2} error={errors.companyAdd2}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"text"} label={"City"}
                            name={"city"} value={data.city} error={errors.city}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"text"} label={"State"}
                            name={"state"} value={data.state} error={errors.state}
                            onChange={handleChange} />
                        </div>
                        <div className='col-md-4'>
                          {/* <NewInput isAstrix={true} type={"text"} label={"Country"}
                          name={"country"} value={data.country} error={errors.country}
                          onChange={handleChange} /> */}
                          <NewSelect selectData={countryData} isAstrix={true} label={"Country"} name={"country"} value={data.country} error={errors.country}
                            onChange={handleChange} optionLabel={"name"} optionValue={"sortname"} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"text"} label={"Postal Code"}
                            name={"PostalCode"} value={data.PostalCode} error={errors.PostalCode}
                            onChange={handleChange} />
                        </div>
                        {/* <div className='col-md-4'>
                        <NewSelect isAstrix={true} label={"Constitution"}
                          selectData={[]} name={"constitution"}
                          value={data.constitution} optionLabel={"name"} optionValue={'sortname'}
                          onChange={handleChange} error={errors.constitution} />
                      </div> */}
                        <div className='col-md-4'>
                          <NewSelect isAstrix={true} label={"Industry"}
                            selectData={industryData} name={"industry"}
                            value={data.industry} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.industry} />
                        </div>
                        <div className='col-md-4'>
                          <NewInput isAstrix={true} type={"date"} label={"Start date of business"}
                            name={"startDateOfBusiness"} value={data.startDateOfBusiness} error={errors.startDateOfBusiness}
                            onChange={handleChange} />
                        </div>
                        {/* <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Sector"}
                          name={"sector"} value={data.sector} error={errors.sector}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Sub-Sector"}
                          name={"subsector"} value={data.subsector} error={errors.subsector}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Competition"}
                          name={"competition"} value={data.competition} error={errors.competition}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Factory Premises"}
                          name={"factoryPremises"} value={data.factoryPremises} error={errors.factoryPremises}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewInput isAstrix={true} type={"text"} label={"Product Description"}
                          name={"productDescription"} value={data.productDescription} error={errors.productDescription}
                          onChange={handleChange} />
                      </div>
                      <div className='col-md-4'>
                        <NewSelect isAstrix={true} label={"Application Type"}
                          selectData={[{ name: 'Domestic' }, { name: 'International' }]} name={"applicationType"}
                          value={data.applicationType} optionLabel={"name"} optionValue={'name'}
                          onChange={handleChange} error={errors.applicationType} />
                      </div> */}
                      </div>
                    </div>

                    {owners.map((i, j) => {
                      if (i === null) {
                        jj = jj + 1
                        return (
                          <div className="mb-3" >
                            <div className='mb-3 d-flex m-2'>
                              <label className="font-wt-500 font-size-14 pt-1 mr-4" ><u>{`Owner ${jj + 1} details`}</u></label>
                              <div className="mr-4">
                                <img
                                  onClick={() => {
                                    fetchOwnerDetails(j, data[`sameAsUserDetailsOwner${j}`])
                                  }}
                                  className='cursor mr-3' src={`assets/images/${data[`sameAsUserDetailsOwner${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-13">Same as user details</label>
                              </div>
                              <div>
                                <img
                                  onClick={() => setData({ ...data, [`mainUserOwner${j}`]: !data[`mainUserOwner${j}`] })}
                                  className='cursor mr-3' src={`assets/images/${data[`mainUserOwner${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                                <label className="font-wt-500 font-size-13">is main user</label>
                              </div>
                              <div className="ml-4">
                                {(owners.length - owners.filter(i => {
                                  if (i === undefined) {
                                    return true
                                  }
                                }).length) - 1 === jj ?
                                  <img style={{ width: '20px' }}
                                    onClick={async () => {
                                      let temp = owners
                                      temp.push(null)
                                      setOwners([...temp])
                                      setData({
                                        ...data, [`ownerNameTitle${temp.length - 1}`]: 'Mr',
                                        [`ownerCountry${temp.length - 1}`]: "India", [`ownerPhoneCode${temp.length - 1}`]: '91'
                                      })
                                    }}
                                    className="cursor" src="assets/images/add_black_icon.png" /> :
                                  <img style={{ width: '20px' }}
                                    onClick={() => {
                                      let temp = owners
                                      temp[j] = undefined
                                      setOwners([...temp])
                                    }}
                                    className="cursor" src="assets/images/delete.png" />}
                              </div>
                            </div>


                            <div className="d-flex row" >
                              <div className="col-md-4" >
                                <InputWithSelect label={"Owner Name"}
                                  selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                                  selectName={`ownerNameTitle${j}`} selectValue={data[`ownerNameTitle${j}`]}
                                  optionLabel={"name"} optionValue={'name'}
                                  name={`ownerName${j}`} value={data[`ownerName${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className="col-md-4" >
                                <NewInput type={"date"}
                                  label={"Date of Birth"} value={data[`ownerDOB${j}`]} name={`ownerDOB${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"Gender"} value={data[`ownerGender${j}`]} name={`ownerGender${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              {/* <div className="col-md-4" >
                                <NewInput
                                  label={"Category"} value={data[`ownerCategory${j}`]} name={`ownerCategory${j}`}
                                  onChange={handleChange}
                                />
                              </div> */}
                              <div className="col-md-4" >
                                <InputWithSelect label={"Contact No"}
                                  selectData={countryData}
                                  selectName={`ownerPhoneCode${j}`} selectValue={data[`ownerPhoneCode${j}`]}
                                  optionLabel={"phonecode"} optionValue={'phonecode'}
                                  name={`ownerContactNo${j}`} value={data[`ownerContactNo${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"Email ID"} value={data[`ownerEmailId${j}`]} name={`ownerEmailId${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"Address Line 1"} value={data[`ownerAddressLine1${j}`]} name={`ownerAddressLine1${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"Address Line 2"} value={data[`ownerAddressLine2${j}`]} name={`ownerAddressLine2${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"City"} value={data[`ownerCity${j}`]} name={`ownerCity${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"State"} value={data[`ownerState${j}`]} name={`ownerState${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewSelect
                                  selectData={countryData} optionLabel={"name"} optionValue={"name"}
                                  onChange={e => {
                                    let newData = {}
                                    if (e.target.value) {
                                      let phoneCode = countryData.filter((i) => {
                                        if (i.name === e.target.value) {
                                          return true
                                        }
                                      })?.[0]?.phonecode
                                      newData[`ownerPhoneCode${j}`] = phoneCode
                                    }
                                    setData({ ...data, [e.target.name]: e.target.value, ...newData })
                                  }}
                                  label={"Country"} name={`ownerCountry${j}`} value={data[`ownerCountry${j}`]}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"Postal Code"} value={data[`ownerPostalCode${j}`]} name={`ownerPostalCode${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4" >
                                <NewInput
                                  label={"PAN No"} value={data[`ownerPanNo${j}`]} name={`ownerPanNo${j}`}
                                  onChange={handleChange}
                                />
                              </div>
                              {/* <div className="col-md-4" >
                                <NewInput
                                  label={"Aadhar No"} value={data[`ownerAadharNo${j}`]} name={`ownerAadharNo${j}`}
                                  onChange={handleChange}
                                />
                              </div> */}
                              {/* <div className="col-md-4" >
                                <NewInput
                                  label={"New Worth"} value={data[`ownerNetWorth${j}`]} name={`ownerNetWorth${j}`}
                                  onChange={handleChange}
                                />
                              </div> */}
                            </div>
                          </div>
                        )
                      }
                    })}

                    {/* <div className="mt-4">
                    <NewTablev2 showSideBorders={true} columns={[{ subColumns: "Yes / No Questions" }, { subColumns: 'YES' }, { subColumns: 'NO' }]}>
                      {Object.keys(yesnoqsts).map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1 }}>
                            <td>{yesnoqsts[i]["label"]}</td>
                            <td>
                              <img
                                onClick={() => {
                                  setData({ ...data, [i]: data[i] === true ? undefined : true })
                                }}
                                className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                            <td>
                              <img
                                onClick={() => {
                                  setData({ ...data, [i]: data[i] === false ? undefined : false })
                                }}
                                className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div> */}

                    {/* <div className="mt-4">
                    <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>

                    <NewTablev2 showSideBorders={true} columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]}>
                      {FinancialMonths.map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <div className="col-md-12 px-0">
                                <InputWithSelect isAstrix={false} type={"text"} label={"Net Worth"}
                                  selectData={most_used_currencies}
                                  selectName={`Currency`} selectValue={data[`Currency`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`${i}_2ndLastValue`} value={data[`${i}_2ndLastValue`]} error={errors[`${i}_2ndLastValue`]}
                                  onChange={handleChange} />
                              </div>
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <div className="col-md-12 px-0">
                                <InputWithSelect isAstrix={false} type={"text"} label={"Net Worth"}
                                  selectData={most_used_currencies}
                                  selectName={`Currency`} selectValue={data[`Currency`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`${i}_LastValue`} value={data[`${i}_LastValue`]} error={errors[`${i}_LastValue`]}
                                  onChange={handleChange} />
                              </div>
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <div className="col-md-12 px-0">
                                <InputWithSelect isAstrix={false} type={"text"} label={"Net Worth"}
                                  selectData={[]}
                                  selectName={`${i}_CurrentCurrency`} selectValue={data[`${i}_CurrentCurrency`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`${i}_CurrenntValue`} value={data[`${i}_CurrenntValue`]} error={errors[`${i}_CurrenntValue`]}
                                  onChange={handleChange} />
                              </div>
                            </td>

                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div> */}

                  </div>
                )
                  : null}

                {tab === 2 ? (
                  <div className='p-4'>

                    <div className='d-flex row px-3 pt-5 pb-0'>
                      <label className='font-size-14 font-wt-400 '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 3 supplier details</span> (Upload supplier details in excel format or fill the below table manually)</label>
                      <div className='col-md-6 my-2'>
                        <div className="col-md-12">
                          <FileInput name={"topsupplierDetails"} value={data.topsupplierDetails} error={errors.topsupplierDetails}
                            onChange={(e) => handleCSV(e, "supplier")} isEditable={true} userTokenDetails={userToken} onUploadCancel={() => setData({ ...data, "topsupplierDetails": null })} />
                        </div>
                      </div>
                      <div className="col-md-4" >
                        <h6 className=''>
                          <a className="text-dark cursor-pointer" href="./assets/docs/Top10Suppliers.csv"
                            target="_blank" download>
                            <img className='mr-2' src="assets/images/csv_file_icon.svg" alt="" width="45" height="45"></img>
                            {"Download Template"}</a>
                        </h6>
                      </div>

                      <div className='mt-4'>
                        <NewTablev2
                          showSideBorders={true}
                          columns={[
                            { subColumns: "Supplier Name", thwidth: "w-12" },
                            { subColumns: "Address", thwidth: "w-12" },
                            { subColumns: "Credit Limit Required ($)", thwidth: "w-12" },
                            { subColumns: "Terms of Payment (days)", thwidth: "w-12" },
                            { subColumns: "Annual Sale ($)", thwidth: "w-12" },
                            { subColumns: "Years of relation", thwidth: "w-12" },
                            { subColumns: "Action", thwidth: "w-12" }

                          ]}
                        >
                          {top10Suppliers.map((i, j) => {
                            if (i === null) {
                              kk = kk + 1
                              return (
                                <tr>
                                  <td>
                                    <MultipleSelectForTable
                                      filterOption={() => true}
                                      onInputChange={(e) => {
                                        handleFilterOptions(e, "company_name")
                                      }}
                                      Label={""}
                                      Id={`selectedSeller${j}`}
                                      optiondata={sellersFiltered}
                                      onChange={(e) => {
                                        handleChange(multiSelectEventHandler(e, `selectedSeller${j}`, "tbl_user_id"), j)
                                      }}
                                      value={data[`selectedSeller${j}`] ? [parseInt(data[`selectedSeller${j}`])] : []}
                                      name={`selectedSeller${j}`}
                                      labelKey={"sellerNameHtml"}
                                      valKey={"tbl_user_id"}
                                      error={errors[`selectedSeller${j}`]}
                                    />
                                  </td>
                                  <td>
                                    <InputForTable name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <div className="d-flex w-100 justify-content-between" >
                                      <div className="" >
                                        {(top10Suppliers.length - top10Suppliers.filter(i => {
                                          if (i === undefined) {
                                            return true
                                          }
                                        }).length) - 1 === kk ?
                                          <img
                                            onClick={async () => {
                                              let temp = top10Suppliers
                                              temp.push(null)
                                              setTop10Suppliers([...temp])
                                            }}
                                            className="cursor" src="assets/images/add_black_icon.png" /> :
                                          <img
                                            onClick={() => {
                                              let temp = top10Suppliers
                                              temp[j] = undefined
                                              setTop10Suppliers([...temp])
                                            }}
                                            className="cursor" src="assets/images/delete.png" />}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </NewTablev2>
                      </div>

                    </div>


                    <div className='d-flex row px-3 pt-5 pb-0'>
                      <label className='font-size-14  '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 3 buyer details</span> (Upload buyer details in excel format or fill the below table manually)</label>
                      <div className='col-md-6 my-2'>
                        <div className="col-md-12">
                          <FileInput name={"topBuyersDetail"} value={data.topBuyersDetail} error={errors.topBuyersDetail}
                            onChange={(e) => handleCSV(e, "buyer")} isEditable={true} userTokenDetails={userToken} onUploadCancel={() => setData({ ...data, "topBuyersDetail": null })} />
                        </div>
                      </div>
                      <div className="col-md-4" >
                        <h6 className=''>
                          <a className="text-dark cursor-pointer" href="./assets/docs/Top10Buyers.csv"
                            target="_blank" download>
                            <img className='mr-2' src="assets/images/csv_file_icon.svg" alt="" width="45" height="45"></img>
                            {"Download Template"}</a>
                        </h6>
                      </div>
                      <div className='mt-4'>
                        <NewTablev2
                          showSideBorders={true}
                          columns={[
                            { subColumns: "Buyer Name", thwidth: "w-12" },
                            { subColumns: "Address", thwidth: "w-12" },
                            { subColumns: "Credit Limit Required ($)", thwidth: "w-12" },
                            { subColumns: "Terms of Payment (days)", thwidth: "w-12" },
                            { subColumns: "Annual Sale ($)", thwidth: "w-12" },
                            { subColumns: "Years of relation", thwidth: "w-12" },
                            { subColumns: "Action", thwidth: "w-12" }
                          ]}
                        >
                          {top10Buyers.map((i, j) => {
                            if (i === null) {
                              ll = ll + 1
                              return (
                                <tr>
                                  <td>
                                    <MultipleSelectForTable
                                      filterOption={() => true}
                                      onInputChange={(e) => {
                                        handleFilterOptions(e, "buyerName")
                                      }}
                                      Label={""}
                                      Id={`selectedBuyer${j}`}
                                      optiondata={buyersFiltered}
                                      onChange={(e) => {
                                        handleChange(multiSelectEventHandler(e, `selectedBuyer${j}`, "id"), j)
                                      }}
                                      value={data[`selectedBuyer${j}`] ? [parseInt(data[`selectedBuyer${j}`])] : []}
                                      name={`selectedBuyer${j}`}
                                      labelKey={"buyerNameHtml"}
                                      valKey={"id"}
                                      error={errors[`selectedBuyer${j}`]}
                                    />
                                  </td>
                                  <td>
                                    <InputForTable name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <div className="d-flex w-100 justify-content-between" >
                                      <div className="" >
                                        {(top10Buyers.length - top10Buyers.filter(i => {
                                          if (i === undefined) {
                                            return true
                                          }
                                        }).length) - 1 === ll ?
                                          <img
                                            onClick={async () => {
                                              let temp = top10Buyers
                                              temp.push(null)
                                              setTop10Buyers([...temp])
                                            }}
                                            className="cursor" src="assets/images/add_black_icon.png" /> :
                                          <img
                                            onClick={() => {
                                              let temp = top10Buyers
                                              temp[j] = undefined
                                              setTop10Buyers([...temp])
                                            }}
                                            className="cursor" src="assets/images/delete.png" />}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </NewTablev2>
                      </div>

                    </div>




                  </div>
                )
                  : null}

                {tab === 3 ? (
                  <div className='p-4'>
                    <label className='font-size-14 font-wt-600 text-decoration-underline'>Select any 5 Financier</label>
                    <div className='row gap-4 justify-content-between p-4'>
                      {financiers.map((item, index) => {
                        return <div className={`w-20 ${item.isRecommened ? ' financierCardNewRecommended ' : ' financierCardNew '} `}>
                          {item.isRecommened ? <img src="assets/images/recommended_ribbon.svg" className='recommendRibbon' /> : null}
                          <div className='d-flex w-100 gap-4 align-items-center justify-content-between'>
                            <div className='d-flex gap-4 align-items-center'>
                              <img src={avatarUrl(item.user_avatar)} height={25} width={25} />
                              <label className='font-size-14 font-wt-500 mb-0'>{item.name}</label>
                            </div>
                            <div>
                              <img
                                onClick={() => {
                                  if (item.isAvailable || environment === "dev") {
                                    handleFinSelection(index)
                                  } else {
                                    toastDisplay("Action not availble", "info")
                                  }
                                }}
                                className='cursor mr-3' src={`assets/images/${financiers?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                            </div>
                          </div>
                          <div className="d-flex px-2 pt-3 w-100">
                            <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{item.avgRatings.toFixed(2)}</p>
                              <span>
                                <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                              </span>
                            </div>
                            <div
                              onClick={async () => {
                                // Get getUserTradeInfoWithFinancier
                                let temp = item
                                temp["show"] = true
                                toggleReviewScrollIndex(0)
                                setshowLoader(true)
                                temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: item.id, sellerId: userId }) }
                                setshowLoader(false)
                                setSeeReview(temp)
                              }}
                              className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{item.reviewsCount}</p><p className="font10 font-size-14 ">See reviews</p></div>
                          </div>
                          <p
                            onClick={() => {
                              setSelectedChat({
                                receiverName: item.name,
                                receiverId: item.id,
                                userId: userId,
                                isChatOpen: true
                              })
                            }} className="cursor"
                          ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2">Chat with financer</span></p>
                        </div>
                      })}
                    </div>

                  </div>
                )
                  : null}

                {tab === 4 ? (
                  <div className='d-flex row px-5 pt-2 pb-4'>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-14'><u>Company Details</u></label>
                      <div className='row'>
                        {companyDetailsReviewForm.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2 font-size-14 font-wt-400"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    {owners.map((i, j) => {
                      if (i === null) {
                        jj = jj + 1
                        return (
                          <div className='w-100 mb-3'>
                            <label className='font-wt-500 font-size-16'><u>{`Owner ${jj + 1} Details`}</u></label>
                            <div className='row'>
                              {ownerReview.map((item) => {
                                return (
                                  <div className="col-md-6 pe-5">
                                    {item.name ?
                                      <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                      : null} </div>
                                );
                              })}
                            </div>
                          </div>
                        )
                      }
                    })}
                    {/* <div className="mt-4 px-0">
                    <NewTablev2 showSideBorders={true} columns={[{ subColumns: "Yes / No Questions" }, { subColumns: 'YES' }, { subColumns: 'NO' }]}>
                      {Object.keys(yesnoqsts).map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1 }}>
                            <td>{yesnoqsts[i]["label"]}</td>
                            <td>
                              <img
                                className='mr-3' src={`assets/images/${data[i] === true ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                            <td>
                              <img
                                className='mr-3' src={`assets/images/${data[i] === false ? 'checked_vector' : 'unchecked_vector'}.svg`} />
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div> */}

                    {/* <div className="mt-4">
                    <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>
                    <NewTablev2 columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]} showSideBorders={true}>
                      {FinancialMonths.map((i, j) => {
                        return (
                          <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              {data[`${i}_2ndLastValue`] || '-'}
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              {data[`${i}_LastValue`] || '-'}
                            </td>
                            <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              {data[`${i}_CurrenntValue`] || '-'}
                            </td>

                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div> */}

                    <div className='row mt-4'>
                      <label className='font-size-14 font-wt-400 '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 3 supplier details</span></label>
                      <div className='mt-4'>
                        <NewTablev2
                          showSideBorders={true}
                          columns={[
                            { subColumns: "Supplier Name", thwidth: "w-14" },
                            { subColumns: "Address", thwidth: "w-14" },
                            { subColumns: "Credit Limit Required ($)", thwidth: "w-14" },
                            { subColumns: "Terms of Payment (days)", thwidth: "w-14" },
                            { subColumns: "Annual Sale ($)", thwidth: "w-14" },
                            { subColumns: "Years of relation", thwidth: "w-14" }

                          ]}
                        >
                          {top10Suppliers.map((i, j) => {
                            if (i === null) {
                              return (
                                data[`supplierName${j}`] &&
                                <tr>
                                  <td>
                                    {data[`supplierName${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`supplierAddress${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`supplierCreditLimitRequired${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`supplierTermsOfPayment${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`supplierAnnualSale${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`supplierYearsOfRelation${j}`] || '-'}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </NewTablev2>
                      </div>

                    </div>

                    <div className='row mt-4'>
                      <label className='font-size-14  '><span className='font-size-14 font-wt-400  text-decoration-underline'>Top 3 buyer details</span></label>
                      <div className='mt-4'>
                        <NewTablev2
                          showSideBorders={true}
                          columns={[
                            { subColumns: "Buyer Name", thwidth: "w-14" },
                            { subColumns: "Address", thwidth: "w-14" },
                            { subColumns: "Credit Limit Required ($)", thwidth: "w-14" },
                            { subColumns: "Terms of Payment (days)", thwidth: "w-14" },
                            { subColumns: "Annual Sale ($)", thwidth: "w-14" },
                            { subColumns: "Years of relation", thwidth: "w-14" }

                          ]}
                        >
                          {top10Buyers.map((i, j) => {
                            if (i === null) {
                              return (
                                data[`buyerName${j}`] &&
                                <tr>
                                  <td>
                                    {data[`buyerName${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`buyerAddress${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`buyerCreditLimitRequired${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`buyerTermsOfPayment${j}`] || "-"}
                                  </td>
                                  <td>
                                    {data[`buyerAnnualSale${j}`] || '-'}
                                  </td>
                                  <td>
                                    {data[`buyerYearsOfRelation${j}`] || '-'}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </NewTablev2>
                      </div>

                    </div>



                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {financiers.map((key) => {
                          if (key.isChecked) {
                            return (
                              <label key={key.name}
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {key.name}
                              </label>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className='mt-3'>
                      <img
                        onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                        className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                      <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                    </div>

                  </div>
                )
                  : null
                }

                <div
                  style={tab === 0 ? { marginTop: '-3rem', marginBottom: '1.5rem' } : { marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  className={"d-flex finActionTabContainer mx-4 "} >
                  {tab / 1 != 4 ? <div
                    onClick={() => {
                      if (tab === 0) {
                        const buttonElement = document.getElementById('extraDetailsForLimitContinueBtn');
                        if (buttonElement) {
                          buttonElement.click();
                        }
                      }
                      else {
                        handleValidation(undefined)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                  </div> : <div
                    onClick={() => {
                      setFinancierActionTab(0)
                      handleValidation(undefined)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                  </div>}
                  <div
                    onClick={() => {
                      if (tab > 0) {
                        setTab(tab - 1)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor ${tab === 0 ? ' disable-bg ' : " "}`} >
                    <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                    <label
                      onClick={() => { toastDisplay('Application saved as draft', "success"); }}
                      className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 "} >Save as Draft</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                    }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor`} >
                    {finActionTab == 2 && draftData.length ? <div
                      style={{
                        width: '14rem',
                        top: `${draftData.length * -2.5}rem`,
                        borderRadius: "10px"
                      }}
                      className="position-absolute " >
                      {draftData.map((i, j) => {
                        return (
                          <div
                            onClick={() => { window.location = `/applylimitSCF?formContinue=${i.id}` }}
                            style={{ height: '2.5rem' }} className="d-flex bg-white border-bottom-solid-dee2e6 align-items-center" >
                            <label
                              className="font-size-14 font-wt-500 col-8 cursor truncate" >{`Application ${j + 1}`}</label>
                            <div className="col-4 d-flex align-items-center" >
                              <label
                                style={{
                                  background: "#5CB8D3",
                                  color: "#fff",
                                  borderRadius: "100%"
                                }}
                                className="ml-3 px-1 py-2 m-0" >{i.details.percentageComplete + " %"}</label>
                            </div>
                          </div>
                        )
                      })}
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Draft
                      <span
                        style={{
                          background: "#5CB8D3",
                          color: "#fff",
                          borderRadius: "100%"
                        }}
                        className="ml-3 px-2 py-1" >{draftData.length}</span>
                    </label>
                  </div>
                </div></> : <>
                <div className='mt-5 mb-3'>
                  <label className='font-wt-500 font-size-16 mb-3 w-100 text-center'><u>Select Application type</u></label>
                  <div className='d-flex justify-content-center mb-3'>
                    {[{ name: 'Domestic' }, { name: 'International' }].map((key) => {
                      return (
                        <div className={`col-md-3 mt-4 cursor `} key={key.name}>
                          <div className={`card border-0 text-center borderRadius`}>
                            <a onClick={() => {
                              setData({ ...data, applicationType: key.name })
                            }
                            } className={`font-size-13 mb-0 p-2 borderRadius  ${data.applicationType === key.name ? "activebox " : " border"}`}> {key.name}</a>
                          </div>
                        </div>
                      );
                    })}</div>
                </div>
              </>}

            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data
  }
}

export default connect(mapStateToProps)(ApplyLimitSCF)
