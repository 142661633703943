import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}

var columns = [
  {
    name: 'Contract Name',
    selector: 'contract_name',
    sortable: true,
    grow: 3,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Contract ID',
    selector: 'contract_number',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Charterer',
    selector: 'charterer',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Commodity',
    selector: 'commodity_name',
    sortable: false,
  },
  {
    name: 'Quantity',
    selector: 'quantity',
    sortable: false,
  },
  {
    name: 'Loading Port',
    selector: 'loadingPort',
    grow: 2,
    sortable: false,
  },
  {
    name: 'Unloading Port',
    selector: 'unloadingPort',
    grow: 2,
    sortable: false,
  },
  {
    name: 'Quotation Status',
    selector: 'quotStatus',
    sortable: false,
    grow: 2,

    conditionalCellStyles: [

      {
        when: row => (row.status / 1 === 0),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.status / 1 === 1),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      },
      {
        when: row => (row.status / 1 === 2),
        style: {
          backgroundColor: 'rgba(255, 0, 0, 1)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status / 1 === 3),
        style: {
          backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
          color: 'white',
        },
      },
      {
        when: row => (![0, 1, 2, 3].includes(row.status / 1)),
        style: {
          backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
          color: 'white',
        },
      }
    ]
  },
  {
    name: 'My Action',
    selector: 'myAction',
    grow: 2,
    sortable: false,
  },
  {
    name: 'Contract Creation Date',
    selector: 'created_at',
    grow: 2,
    sortable: true,
  }
];

if (aclMap.oquotation_access && aclMap.oquotation_access.oquotation_manage && aclMap.oquotation_access.oquotation_manage.isExist) {
  columns.push({
    name: "Actions",
    grow: 2,
    selector: 'action',
    center: true
  })
}

export default columns;
