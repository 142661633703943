export default function validate(typeId, values, kycDocs) {

  let errors = {};

  if (!values.organizationType) {
    errors.organizationType = 'Organization type is required';
  }

  // doc checks

  if (!values.gstDocumentName && values.organizationType !== "foreign") {
    errors.gstDocumentName = 'GST Number is required'
  }
  else if (values.gstDocumentName && values.gstDocumentName.length < 15) {
    errors.gstDocumentName = 'GST Number should be of 15 digits'
  }

  if (!values.iecDocumentName && typeId == 19 && values.organizationType !== "foreign") {
    errors.iecDocumentName = 'IEC Number is required'
  }
  else if (values.iecDocumentName && values.iecDocumentName.length < 10) {
    errors.iecDocumentName = 'IEC Number should be of 10 digits'
  }

  if (!values.cinDocumentName && values.organizationType == "pvtPubLtd") {
    errors.cinDocumentName = 'CIN Number is required'
  }
  else if (values.cinDocumentName && values.cinDocumentName.length < 21) {
    errors.cinDocumentName = 'CIN Number should be of 21 digits'
  }

  console.log(errors)
  return errors;
}