import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import DataTable from 'react-data-table-component';
import docJson from '../userProfile/financeDoc.json';
import fincolumns from '../dataTablesColumsMap/userFinancialDetailsGrid';
import FilePreview from '../utilComponents/previewFile';
import customStyles from '../dataTablesColumsMap/customTableCss';
import config from '../../config.json';

const UserDetailedList = ({ userTokenDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [buyersDetail, setBuyersDetail] = useState([]);
  const [financialDetail, setFinancialDetails] = useState([]);
  const [lenderDetail, setLenderDetails] = useState([]);
  const [userType, setUserType] = useState({});
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [assignBuyer, setAssignBuyer] = useState({ data: [] });

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    window.onload = function () {
      try {
        var url_string = (window.location.href);
        var url = new URL(url_string);
        var id = url.searchParams.get('id');
        var user_type = url.searchParams.get('type');
        setUserType({ "user_type": user_type, "id": id });

        call('POST', 'userDetailedList', { "id": id }).then((result) => {
          console.log('running userDetailedList api-->', result);
          setApplicantInfo(result.userDetail);
          setBuyersDetail(result.buyersDetail);
          setFinancialDetails(result.financialDetails);
          setLenderDetails(result.lenderDetail);

        }).catch((e) => {
          console.log('error in userDetailedList', e);
          toastDisplay("Error", "error")
        });

      } catch (error) {
        console.log('error in userDetailedList ', error);
      }
    }
  }, []);

  useEffect(() => {
    fetchfinTableData(1, {});
  }, [applicantInfo.length > 0])

  //--------------------------------------------------------------------------------------------------------------------
  //Datatable

  const [fintableData, setfintableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  function fetchfinTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "grid": "LIP",
      "manualUserId": applicantInfo.length > 0 ? applicantInfo[0].id : '',
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setfintableData(formatfinDataFortable(result.finData))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  }

  const handlefinPageChange = page => {
    fetchfinTableData(page, {});
  };

  const handlefinPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": applicantInfo.length > 0 ? applicantInfo[0].id : '',
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setfintableData(formatfinDataFortable(result.finData))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  };

  function formatfinDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.year = dataArray[i].year;
      tempObj.currency = dataArray[i].currency.split(":")[1];
      tempObj.turnover = dataArray[i].turnover + " " + dataArray[i].currency.split(":")[1];
      tempObj.netprofit = dataArray[i].netprofit + " " + dataArray[i].currency.split(":")[1];
      tempObj.networth = dataArray[i].networth + " " + dataArray[i].currency.split(":")[1];
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      tempObj.action = 'N/A';

      tempObj.finance_doc_1_year = dataArray[i].finance_doc_1_year;
      tempObj.finance_doc_2_year = dataArray[i].finance_doc_2_year;
      tempObj.finance_doc_3_year = dataArray[i].finance_doc_3_year;
      tempObj.finance_doc_current_year = dataArray[i].finance_doc_current_year;
      tempObj.gst_doc_6_month = dataArray[i].gst_doc_6_month;
      tempObj.itr_doc_1_year = dataArray[i].itr_doc_1_year;
      tempObj.itr_doc_2_year = dataArray[i].itr_doc_2_year;
      tempObj.itr_doc_3_year = dataArray[i].itr_doc_3_year;
      tempObj.debtor_doc = dataArray[i].debtor_doc;
      tempObj.creditor_doc = dataArray[i].creditors_doc;

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function createMiniBody(data) {
    let keysArray = Object.keys(docJson)
    let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
      return (<>
        {docJson[keyValue].map((arrayElement) => {
          return (
            <div className="col-md-4 bg-light border-left mb-1">
              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
              <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(data[arrayElement.name], "view")}>
                {keyValue + " - ( " + arrayElement.lable + " )"}
              </button>
            </div>
          )
        })}
      </>)
    }) : ""

    return (returnJSX)

  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }


  //--------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  function creditButton(value) {

    let userValue = false;

    if (userType.user_type == 8) {
      if (value.buyers_credit == null) {
        return (<div className="col-md-12">
          <div className="row pt-2">
            <div className="form-group col-md-6">
              <label>Assign Credit </label>
              <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} onChange={handleChange} />
              {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
            </div>

            <div className="form-group col-md-6">
              <label>Remark </label>
              <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} onChange={handleChange} />
              {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
            </div>
            <br />
            <br />
          </div>
          <div className="form-group col-md-4">
            <button className="btn btn-success btn-sm" onClick={() => assignCredit(value)} >
              Assign Credit
            </button>
          </div>

          <hr className="pt-1 pb-1" />
        </div>)
      }
      else {
        let buyersCreditList = JSON.parse(value.buyers_credit);
        for (let i = 0; i < buyersCreditList.length; i++) {
          if (lenderDetail && lenderDetail.length > 0 && buyersCreditList[i].lender_id == lenderDetail[0].id) {
            userValue = true;
          }
          if (userValue == true) {
            return (
              <div className="col-md-12">
                <div className="row pt-2">
                  <div className="form-group col-md-6">
                    <label>Assigned Credit </label>
                    <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={buyersCreditList[i].assignValue} onChange={handleChange} />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Remark </label>
                    <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={buyersCreditList[i].assignRemark} onChange={handleChange} />
                  </div>
                  <br />
                  <br />
                </div>

                {/* <div className="form-group col-md-4">
                  <button className="btn btn-info btn-sm" onClick={() => assignCredit(value)} >
                    Update Credit
                  </button>
                </div> */}

                <hr className="pt-1 pb-1" />
              </div>
            )
          }

        }
        return (<div className="col-md-12">
          <div className="row pt-2">
            <div className="form-group col-md-6">
              <label>Assign Credit </label>
              <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} onChange={handleChange} />
              {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
            </div>

            <div className="form-group col-md-6">
              <label>Remark </label>
              <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} onChange={handleChange} />
              {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
            </div>
            <br />
            <br />
          </div>
          <div className="form-group col-md-4">
            <button className="btn btn-success btn-sm" onClick={() => assignCredit(value)} >
              Assign Credit
            </button>
          </div>

          <hr className="pt-1 pb-1" />
        </div>)
      }
    }
    else if (userType.user_type == 1) {
      if (value.buyers_credit == null) {
        return (<div className="col-md-12">
          <div className="row pt-2">
            <div className="form-group col-md-6">
              <label>Assigned Credit </label>
              <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} disabled />
            </div>

            <div className="form-group col-md-6">
              <label>Remark </label>
              <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} disabled />
            </div>
            <br />
            <br />
          </div>

          <hr className="pt-1 pb-1" />
        </div>)
      }
      else {
        let buyersCreditList = JSON.parse(value.buyers_credit);
        for (let i = 0; i < buyersCreditList.length; i++) {
          if (lenderDetail && lenderDetail.length > 0 && buyersCreditList[i].lender_id == lenderDetail[0].id) {
            userValue = true;
          }
          if (userValue == true) {
            return (
              <div className="col-md-12">
                <div className="row pt-2">
                  <div className="form-group col-md-6">
                    <label>Assigned Credit </label>
                    <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={buyersCreditList[i].assignValue} disabled />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Remark </label>
                    <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={buyersCreditList[i].assignRemark} disabled />
                  </div>
                  <br />
                  <br />
                </div>

                {/* <div className="form-group col-md-4">
                  <button className="btn btn-info btn-sm" onClick={() => assignCredit(value)} >
                    Update Credit
                  </button>
                </div> */}

                <hr className="pt-1 pb-1" />
              </div>
            )
          }

        }
        return (<div className="col-md-12">
          <div className="row pt-2">
            <div className="form-group col-md-6">
              <label>Assigned Credit </label>
              <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} disabled />
              {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
            </div>

            <div className="form-group col-md-6">
              <label>Remark </label>
              <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} disabled />
              {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
            </div>
            <br />
            <br />
          </div>

          <hr className="pt-1 pb-1" />
        </div>)
      }
    }
  }

  const handleChange = (event) => {
    event.persist();
    setData(logdata => ({ ...logdata, [event.target.name]: event.target.value }));
    setError(errorData => ({ ...errorData, [event.target.name]: "" }))
  };

  const assignCredit = (assignData) => {
    let object = {
      "assignValue": data.buyersCredit,
      "assignRemark": data.buyersRemark,
      "buyer_id": assignData.id,
      "buyerEmail": assignData.buyerEmail,
      "lender_id": lenderDetail[0].tbl_user_id,
      "lender_name": lenderDetail[0].company_name,
      "lender_email": lenderDetail[0].email_id,
      "userId": applicantInfo[0].id,
      "userName": applicantInfo[0].company_name,
      "userEmail": applicantInfo[0].email_id,
      "id": userType.id
    }

    console.log('object => ', object);

    assignBuyer.data.push(object);
    setAssignBuyer(assignBuyer);

  }

  const handleSubmit = function () {
    call('POST', 'assignCreditToBuyer', { "data": assignBuyer.data, "baseUrl": config.baseUrl }).then((result) => {
      console.log('running userDetailedList api-->', result);
      toastDisplay(result, "success")
    }).catch((e) => {
      console.log('error in userDetailedList', e);
      toastDisplay("Error", "error")
    });

  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {/* logo start */}
      <div className="logo">
        <img height="60" className="default_logo" src="assets/images/logos/logo_1.png" />
        <img height="60" className="mini_logo" src="assets/images/logos/logo_mini.png" />
      </div>
      <div className="col-md-12 mb-2 text-center">
        <h1 className="green border-bottom pb-2"><b>Offer Credit Line</b></h1>
      </div>
      {/* logo end */}

      <div className="col-md-12 pt-2 pb-5 mt-5">

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Applicants Info
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">
                      <div className="form-group col-md-12">
                        <div className="modal-padding">
                          <ul className="price-ul">
                            <li>
                              <div className="price-left"> {applicantInfo.length > 0 && applicantInfo[0].company_name ? applicantInfo[0].company_name : 'Not Available'}</div>
                              <div className="price-right">Username </div>
                            </li>
                            <li>
                              <div className="price-left">{applicantInfo.length > 0 && applicantInfo[0].email_id ? applicantInfo[0].email_id : 'Not Available'}</div>
                              <div className="price-right">Email Id</div>
                            </li>
                            <li>
                              <div className="price-left">{applicantInfo.length > 0 && applicantInfo[0].country_code ? applicantInfo[0].country_code : 'Not Available'}</div>
                              <div className="price-right">Country</div>
                            </li>
                            <li>
                              <div className="price-left">{applicantInfo.length > 0 && applicantInfo[0].user_address ? applicantInfo[0].user_address : 'Not Available'}</div>
                              <div className="price-right">Address</div>
                            </li>
                            <li>
                              <div className="price-left">{applicantInfo.length > 0 && applicantInfo[0].gst_vat_no ? applicantInfo[0].gst_vat_no : 'Not Available'}</div>
                              <div className="price-right">GST Number</div>
                            </li>
                            <li>
                              <div className="price-left">{applicantInfo.length > 0 && applicantInfo[0].iec_no ? applicantInfo[0].iec_no : 'Not Available'}</div>
                              <div className="price-right">IEC Number</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Buyer's List
                    </h3>
                    <hr />
                  </div>

                  <br />
                  <br />
                  <hr className="pt-1 pb-1" />

                  <div className="col-md-12">
                    <div className="row pt-2">
                      <div className="form-group col-md-12">

                        {buyersDetail && buyersDetail.length > 0 && buyersDetail.map((value) => {
                          return (
                            <>
                              <div className="modal-padding">
                                <ul className="price-ul">
                                  <li>
                                    <div className="price-left">{value.buyerName ? value.buyerName : 'Not Available'}</div>
                                    <div className="price-right">Buyer Name </div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerEmail ? value.buyerEmail : 'Not Available'}</div>
                                    <div className="price-right">Buyer Email </div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.buyerPhone ? value.buyerPhone : 'Not Available'} </div>
                                    <div className="price-right">Contact No</div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerAddress ? value.buyerAddress : 'Not Available'}</div>
                                    <div className="price-right">Buyer Address</div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerCountry ? value.buyerCountry : 'Not Available'}</div>
                                    <div className="price-right">Country</div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerWebsite ? value.buyerWebsite : 'Not Available'}</div>
                                    <div className="price-right">Website</div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerDUNSNo ? value.buyerDUNSNo : 'Not Available'}</div>
                                    <div className="price-right">DUNS Number</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.incoTerms ? value.incoTerms : 'Not Available'}</div>
                                    <div className="price-right">Inco Terms</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.previousAnnualSale ? value.previousAnnualSale + ' ' + value.buyerCurrency.split(':')[1] : 'Not Available'}</div>
                                    <div className="price-right">Previous Sale</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.currentAnnualSale ? value.currentAnnualSale + ' ' + value.buyerCurrency.split(':')[1] : 'Not Available'}</div>
                                    <div className="price-right">Expected Sale</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.termsOfPayment ? value.termsOfPayment : 'Not Available'}</div>
                                    <div className="price-right">Terms of Payment</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.productDetails ? value.productDetails : ''}</div>
                                    <div className="price-right">Product Details</div>
                                  </li>
                                </ul>
                              </div>

                              {creditButton(value)}

                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {userType.user_type == 8 &&
              <div className="form-group col-md-4">
                <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={() => handleSubmit()}>Submit</button>
              </div>}
          </div>


        </div>

        {financialDetail && financialDetail.length > 0 &&
          < div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Financial Details
                      </h3>
                      <hr />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                    <div className="table-responsive">
                      <DataTable
                        columns={fincolumns}
                        data={fintableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handlefinPerRowsChange}
                        onChangePage={handlefinPageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={<TableExpandedComponent />}
                        expandOnRowClicked
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                      />
                    </div>
                    <br />
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>}

      </div>
      {
        showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
          viewTrail={false}
        />
      }
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailedList)