export default function validate(data, file, type) {
  let errors = {};



  if (!data.dirContractNo) {
    errors.dirContractNo = 'Contract No is Mandatory';
  }
  if (!data.dirContractName) {
    errors.dirContractName = 'Contract Name is Mandatory';
  }
  if (!data.dirContractType) {
    errors.dirContractType = 'Contract Type is Mandatory';
  }
  if (!data.buyerId) {
    errors.buyerId = 'Buyer is Mandatory';
  }
  if (!data.dirLaycanStart) {
    errors.dirLaycanStart = 'Laycan Start is Mandatory';
  }
  if (!data.dirLaycanEnd) {
    errors.dirLaycanEnd = 'Laycan End is Mandatory';
  }
  if (!data.dirCommodityCategory) {
    errors.dirCommodityCategory = 'Commodity Category is Mandatory';
  }
  if (!data.dirCommodity) {
    errors.dirCommodity = 'Commodity is Mandatory';
  }
  if (!data.dirCommCountryOrigin) {
    errors.dirCommCountryOrigin = 'Country of Origin is Mandatory';
  }
  if (!data.dirQuantity) {
    errors.dirQuantity = 'Quantity is Mandatory';
  }
  if (!data.dirCurrency) {
    errors.dirCurrency = 'Currency is Mandatory';
  }
  if (!data.dirPrice) {
    errors.dirPrice = 'Price is Mandatory';
  }
  if (!data.dirInvoicePrice) {
    errors.dirInvoicePrice = 'Invoice Amount is Mandatory';
  }
  if (!data.dirLoadingCountry) {
    errors.dirLoadingCountry = 'Loading Country is Mandatory';
  }
  if (!data.dirLoadingPort) {
    errors.dirLoadingPort = 'Loading Port is Mandatory';
  }
  if (!data.dirUnloadingCountry) {
    errors.dirUnloadingCountry = 'Unloading Country is Mandatory';
  }
  if (!data.dirUnloadingPort) {
    errors.dirUnloadingPort = 'Unloading Port is Mandatory';
  }
  if (!data.dirShipper) {
    errors.dirShipper = 'Ship Owner is Mandatory';
  }
  if (!data.dirVessel) {
    errors.dirVessel = 'Vessel is Mandatory';
  }
  if (!data.dirBLNumber) {
    errors.dirBLNumber = 'BL Number is Mandatory';
  }
  if (!data.dirBLDate) {
    errors.dirBLDate = 'BL Date is Mandatory';
  }
  if (!data.dirPaymentMode) {
    errors.dirPaymentMode = 'Payment Mode is Mandatory';
  }
  if (!data.dirInvoiceNumber) {
    errors.dirInvoiceNumber = 'Invoice No is Mandatory';
  }
  if (!data.dirCredittenor) {
    errors.dirCredittenor = 'Credit Tenor is Mandatory';
  }
  if (!data.dirInvoiceDate) {
    errors.dirInvoiceDate = 'Invoice Date is Mandatory';
  }
  if (!data.dirInvoiceDueDate) {
    errors.dirInvoiceDueDate = 'Invoice Due Date is Mandatory';
  }
  if (type === "create") {
    if (!file.dirContractDocument) {
      errors.dirContractDocument = 'Document is Mandatory';
    }
    if (!file.dirInvoiceDocument) {
      errors.dirInvoiceDocument = 'Document is Mandatory';
    }
    if (!file.dirBLDocument) {
      errors.dirBLDocument = 'Document is Mandatory';
    }
  }


  console.log(errors);
  return errors;
}
