import React, { useEffect, useState } from "react";
import { ExportExcel, printDiv } from "../../../utils/myFunctions";
import TableFilter from "../../wallet/components/tableFilter";
import moment from "moment";
import call from "../../../service";
import NewTablev2 from "../../../utils/newTablev2";
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
import History from "./history";
import { Financecolumns, tableFinanceData } from "../TableJson";
import Action from "../../lcV2/qoutes/actions";
import LineChartComponent from "./LineChartComponent";
import { Calendar } from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import Filter from "../../InvoiceDiscounting/components/Filter";
import { NewTable } from "../../../utils/newTable";


const Finance = ({ userTokenDetails, navToggleState }) => {

  const [dbData, setDbData] = useState([])

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [data, setData] = useState({ pageCount: 10 })

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({
    groupBy: "WEEK", fromDate: moment().startOf('month').format("YYYY-MM-DD"),
    toDate: moment().endOf('month').format("YYYY-MM-DD"),
  })
  const [tableFilter, setTableFilter] = useState({
    resultPerPage: 10,
  })

  const [history, setHistory] = useState(false)
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [display, setDisplay] = useState(false);
  const [finSummary, setFinSummary] = useState({})
  const [showloader, setshowloader] = useState(false)
  const [LCchartdata, setLCChartdata] = useState([])
  const [INVchartdata, setINVChartdata] = useState([])
  const [buyerSearch, setBuyerSearch] = useState()
  const [selectedFilter, setSelectedFilter] = useState('This Month')
  const [isDropDown, setisDropDown] = useState(false)
  const [refreshChart, setRefreshChart] = useState(0)
  const [filterData, setFilterData] = useState({})

  const [action, setAction] = useState({ show: false, id: null });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const getreportsfinsummary = () => {
    setshowloader(true)
    call('POST', 'getreportsfinsummary', { userId }).then(result => {
      setFinSummary(result)
      setshowloader(false)

    }).catch(e => {
      setshowloader(false)

    })
  }
  const getTasksTableFilters = () => {
    setshowloader(true)
    call('POST', 'getTasksTableFilters', { userId }).then(result => {
      setFilterData(result)
      setshowloader(false)
    }).catch(e => {
      setshowloader(false)
    })
  }
  const getTaskdata = () => {
    console.log('filterdata', tableFilter)
    setshowloader(true)
    let reqObj = {
      userId,
      resultPerPage: tableFilter.resultPerPage,
      currentPage: page,
      ...tableFilter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          reqObj[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              if (element.accordianId === 'finType') {
                reqObj[element.accordianId].push(`${i[element["labelName"]]}`.toLowerCase().split(' ').join("_"))

              } else {
                reqObj[element.accordianId].push(`${i[element["labelName"]]}`)
              }
            }
          })
        }
        else if (element.type === "minMax") {
          reqObj[element.accordianId] = element["value"]
        } else if (element.type === "date") {
          reqObj[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getTasks', reqObj).then(result => {
      console.log('success in getTasks', result)
      setDbData(formatDataForTable(result.data))
      setCount(result.countdata)
      setshowloader(false)


    }).catch(e => {
      console.log('error in getTasks', e)
      setshowloader(false)
    })
  }
  const getLCLimitChardata = () => {
    setshowloader(true)
    let reqObj = {
      userId,
      ...filter
    }
    call('POST', 'getLCLimitChardata', reqObj).then(result => {
      console.log('success in getTasks', result)
      setLCChartdata(result)
      setshowloader(false)
    }).catch(e => {
      console.log('error in getTasks', e)
      setshowloader(false)
    })
  }
  const getINVLimitChardata = () => {
    setshowloader(true)
    let reqObj = {
      userId,
      ...filter
    }
    call('POST', 'getINVLimitChardata', reqObj).then(result => {
      console.log('success in getTasks', result)
      setINVChartdata(result)
      setshowloader(false)
    }).catch(e => {
      console.log('error in getTasks', e)
      setshowloader(false)
    })
  }
  useEffect(() => {
    getLCLimitChardata()
    getINVLimitChardata()
  }, [refreshChart])
  useEffect(() => {
    getreportsfinsummary()
    getTasksTableFilters()
  }, [])
  useEffect(() => {
    getTaskdata()
  }, [refresh, page, filterData])
  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Name": data.createdByName,
        "Service Name": data.serviceName,
        "Transaction ID": data.transactionId,
        "Type of Transaction": data.type,
        "Charges": data.charges,
        "Mode Of Payment": data.modeOfPayment,
        "Date And Time": moment(data.createdAt).format('DD-MM-YYYY hh:ss:mm A'),
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }

  const handleDropDown = (index) => {
    if (!action.show)
      setAction({ show: true, id: index });
    else
      setAction({ show: false, id: index })
  }

  const onClickApplication = () => {

  }
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefreshChart(refreshChart + 1)
      setisDropDown(false)
    }

  };
  const handleChange = async (event) => {
    event.persist()
    setFilter({ ...filter, [event.target.name]: event.target.value })
  }
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((key, index) => {
      let createdAt = moment(key.createdAt)
      let todaysDate = moment()

      let diff = createdAt.diff(todaysDate, 'days') + 5
      const allquotes = JSON.parse(key.buyers_credit)

      let Action = ''
      let TranscationType = ''


      if (key.finance_type === 'invoice_discounting') {
        if (key.selectedFinancier === null || key.buyers_credit === null) {
          Action = 'In-Progress'
        } else if (allquotes.some(data => data.financierAction == 'Approved')) {
          Action = 'Approved'
        } else if (allquotes.every(data => data.financierAction == 'deny')) {
          Action = 'Rejected'
        }
      } else {
        if (key.selectedFinancier === null || key.buyers_credit === null) {
          Action = 'In-Progress'
        } else if (allquotes.some(data => data.status == 'approved')) {
          Action = 'Approved'
        } else if (allquotes.every(data => data.status == 'denied')) {
          Action = 'Rejected'
        }
      }


      if (key.finance_type === 'sblc') {
        TranscationType = 'SBLC'
      } else if (key.finance_type === 'lc_discounting') {
        TranscationType = 'LC Discounting'
      } else if (key.finance_type === 'lc_confirmation') {
        TranscationType = 'LC Confirmation'
      } else if (key.finance_type === 'invoice_discounting') {
        TranscationType = 'Invoice Discounting'
      }

      row[0] = key.transactionId ? key.transactionId : '-'
      row[1] = key.buyerName && key.buyerName.length > 30 ? key.buyerName.slice(0, 30) + '...' : key.buyerName
      row[2] = key.FinancierName ? key.FinancierName : '-'
      row[3] = TranscationType
      row[4] = key.requiredLimit ? "$ " + Intl.NumberFormat('en-US').format(key.requiredLimit) : "-"
      row[5] = <div>
        {Action === "Approved" && <div className="d-flex gap-5 align-items-center">< button className="rounded-pill bg76EEA9 py-1 px-2 text-color-0C0C0C finance-statusbtn">Approved</button>

        </div>
        }
        {Action === "In-Progress" && <div className="d-flex gap-5 align-items-center">< button className="rounded-pill bg-FFCA72 py-1 px-2 text-color-0C0C0C finance-statusbtn">In Process</button>

        </div>
        }
        {Action === "Disbursed" &&
          <div className="d-flex gap-5 align-items-center">
            < button className="rounded-pill bgAED8FF py-1 px-2 text-color-0C0C0C finance-statusbtn">Disbursed</button>

          </div>
        }
        {Action === "Rejected" && <div className="d-flex gap-5 align-items-center">
          <button className="rounded-pill bgFF7B6D py-1 px-2 text-color-0C0C0C finance-statusbtn"> Rejected</button>
        </div>
        }
      </div>

      tableData.push(row)
      row = []
    })
    return tableData
  }
  return (
    <>
      <div className="card p-3 dashboard-card border-0 borderRadius mt-5 justify-content-between" style={{ height: "392px" }} >
        {showloader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="">
            <p className="font-wt-500 letter-spacing05 text-color-0C0C0C font-size-14 lineheight19 mb-0">Letter of Credit</p>
          </div>
          <div>
            <div class="dropdown">
              <button class="form-select border-0 monthsingraph " onClick={() => setisDropDown(!isDropDown)}>
                {selectedFilter}
              </button>
              {isDropDown &&
                <ul class="graphdropdown borderRadius" >
                  <li><a class="dropdown-item cursor" onClick={() => {
                    setFilter({
                      ...filter,
                      fromDate: moment().startOf('month').format("YYYY-MM-DD"),
                      toDate: moment().endOf('month').format("YYYY-MM-DD"),
                      groupBy: "WEEK"
                    })
                    setRefreshChart(refreshChart + 1)
                    setSelectedFilter("This Month")
                    setisDropDown(false)
                  }}>This month</a></li>
                  <li><a class="dropdown-item cursor" onClick={() => {
                    setFilter({
                      ...filter,
                      fromDate: moment().startOf('year').format("YYYY-MM-DD"),
                      toDate: moment().endOf('year').format("YYYY-MM-DD"),
                      groupBy: "MONTH"
                    })
                    setRefreshChart(refreshChart + 1)
                    setSelectedFilter("This Year")
                    setisDropDown(false)
                  }}>This year</a></li>
                  <li>
                    <a class="dropdown-item cursor" onClick={() => setSelectedFilter("Custom date")}> Custom date</a>
                    {selectedFilter === 'Custom date' &&
                      <div className="tab-content d-block graphcalendardiv" id="nav-tabContent">
                        <div className="border-rounded mt-4">
                          <div className="px-2 py-1">
                            <Calendar onChange={(val) => {

                              const dateDiff = moment(val[1]).diff(val[0], 'days')
                              console.log('datedifffffff', dateDiff)
                              let groupBy = 'WEEK'
                              if (dateDiff <= 14) {
                                groupBy = 'DAY'
                              } else if (dateDiff <= 31) {
                                groupBy = 'WEEK'
                              } else if (dateDiff <= 365) {
                                groupBy = 'MONTH'
                              } else {
                                groupBy = 'YEAR'
                              }
                              setFilter({
                                ...filter,
                                fromDate: moment(val[0]).format("YYYY-MM-DD"),
                                toDate: moment(val[1]).format("YYYY-MM-DD"),
                                groupBy
                              })
                              setRefreshChart(refreshChart + 1)
                              setisDropDown(false)
                            }} value={[new Date(filter.fromDate), new Date(filter.toDate)]} className="borderRadius border-0 calenderBorder col-md-12" next2Label={null} prev2Label={null} selectRange={true} calendarType={"US"} />
                          </div>
                        </div>
                      </div>
                    }
                  </li>
                  <li>
                    <a class="dropdown-item cursor" onClick={() => setSelectedFilter("Buyer")}>Buyer</a>
                    {selectedFilter == 'Buyer' && <div className="input-group mb-3 currency mt-3 pe-1 position-absolute searchBarDropdown" style={{ top: "7.3rem" }}>
                      <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                      <input type="text" name='searchbuyer' value={filter.searchbuyer} onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        className="form-control border-start-0" placeholder="Search" />
                    </div>

                    }
                  </li>
                  <li>
                    <a class="dropdown-item cursor" onClick={() => setSelectedFilter("HSN code")}>HSN code</a>
                    {selectedFilter == 'HSN code' && <div className="input-group mb-3 currency mt-3 pe-1 position-absolute searchBarDropdown" style={{ top: "9.3rem" }}>
                      <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                      <input type="text" name='hsnSearch' value={filter.hsnSearch} onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        className="form-control border-start-0" placeholder="Search" />
                    </div>
                    }
                  </li>
                  <li>
                    <a class="dropdown-item border-0 cursor" onClick={() => setSelectedFilter("Product")}>Product</a>
                    {selectedFilter == 'Product' && <div className="input-group mb-3 currency mt-3 pe-1 position-absolute searchBarDropdown" style={{ top: "11.3rem", zIndex: 1000 }}>
                      <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                      <input type="text" name='productSearch' value={filter.productSearch} onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        className="form-control border-start-0" placeholder="Search" />
                    </div>
                    }
                  </li>
                </ul>
              }
            </div>
          </div>
          <div className="">
            <p className="font-wt-500 letter-spacing05 text-color-0C0C0C font-size-14 lineheight19 mb-0">Invoice Discounting</p>
          </div>
        </div>

        <div className="d-flex justify-content-around">
          <div className="my-5 text-center col-md-4">
            <LineChartComponent data={LCchartdata} key1={"total_applications"} key2={"limitrejected"} key3={"total_limit_applied"} />
          </div>
          <div className="my-5 text-center col-md-4">
            <LineChartComponent data={INVchartdata} key1={"total_applications"} key2={"limitrejected"} key3={"total_limit_applied"} />
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-center">
          <p className="text-color-0C0C0C letter-spacing05 lh-16 font-wt-300 font-size-12" ><span className="bgAED8FF Financelimitapplied me-2"></span> Finance limit applied</p>
          <p className="text-color-0C0C0C letter-spacing05 lh-16 font-wt-300 font-size-12" ><span className="bgFF7B6D Financelimitapplied me-2"></span> Finance limit rejected</p>
          <p className="text-color-0C0C0C letter-spacing05 lh-16 font-wt-300 font-size-12" ><span className="bg76EEA9 Financelimitapplied me-2"></span> Finance application</p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="d-flex my-5">
          <div className="col-md-2">
            <div class="loader-circle testing1">
              <span></span>
            </div>
            <div className="">
              <p className="mb-2 color434343 font-size-16 text-center circletext" style={{ left: "3.1rem" }} >Total Limit <br />
                Application
              </p>
              <p className="color9C53C0 font-size-20 font-wt-600 text-center circlecount" style={{ left: "4.2rem" }}>{finSummary.total_limit_count}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div class="loader-circle testing2">
              <span></span>
            </div>
            <div className="">
              <p className="mb-2 color434343 font-size-16 text-center circletext" style={{ left: "3.3rem" }}>Total limit <br />
                Rejected
              </p>
              <p className="color636E72 font-size-20 font-wt-600 text-center circlecount" style={{ left: "4.5rem" }}>{finSummary.total_rejected_count}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div class="loader-circle testing3">
              <span></span>
            </div>
            <div className="">
              <p className="mb-2 color434343 font-size-16 text-center circletext">Total finance <br />
                Application
              </p>
              <p className="text-color1 font-size-20 font-wt-600 text-center circlecount " style={{ left: "4rem" }} >{finSummary.total_finance_count}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div class="loader-circle testing4">
              <span></span>
            </div>
            <div className="">
              <p className="mb-2 color434343 font-size-16 text-center circletext">Total finance <br />
                Received
              </p>
              <p className="color3DB16F font-size-20 font-wt-600 text-center circlecount" style={{ left: "3.2rem" }}>{"$ " + Intl.NumberFormat('en', { notation: 'compact' }).format(finSummary.total_finance_received)}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div class="loader-circle testing5">
              <span></span>
            </div>
            <div className="">
              <p className="mb-2 color434343 font-size-16 text-center circletext">Total finance <br />
                Due
              </p>
              <p className="colorFFA41C font-size-20 font-wt-600  text-center circlecount" style={{ left: "4.2rem" }}>{"$ " + Intl.NumberFormat('en', { notation: 'compact' }).format(finSummary.total_finance_due)}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div class="loader-circle testing6">
              <span></span>
            </div>
            <div className="">
              <p className="mb-2 color434343 font-size-16 text-center circletext">Total finance <br />
                Overdue
              </p>
              <p className="colorFE4141 font-size-20 font-wt-600  text-center circlecount" style={{ left: "4.2rem" }}>{"$ " + Intl.NumberFormat('en', { notation: 'compact' }).format(finSummary.total_finance_overdue)}</p>
            </div>
          </div>
        </div>
      </div>


      <div className="filter-div ml-0 mt-1">
        <Filter
          filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
          showResultPerPage={true} count={count} filter={tableFilter} setFilter={setTableFilter} refresh={refresh} setRefresh={setRefresh} onExcelExport={() => exportToexcel(dbData, "Excel_WalletTransactions")} onPDFExport={() => printDiv("transactionTableDiv", "Excel_WalletTransactions", [])} />
      </div>

      <div className="mb-4">
        <NewTable
          tableFixed
          data={dbData}
          columns={[{
            name: "Transaction ID", filter: true, filterDataKey: "Date", sort: [
              { name: "Latest First", selected: tableFilter.sortdate === 'ASC', onActionClick: () => { setTableFilter({ ...tableFilter, sortdate: 'ASC', sortBuyerName: false }); setRefresh(refresh + 1) } },
              { name: "Earliest First", selected: tableFilter.sortdate === 'DESC', onActionClick: () => { setTableFilter({ ...tableFilter, sortdate: 'DESC', sortBuyerName: false }); setRefresh(refresh + 1) } }]
          }, {
            name: "Buyer name", filter: true, filterDataKey: "Buyer name",
            sort: [
              { name: "A to Z", selected: tableFilter.sortBuyerName === 'ASC', onActionClick: () => { setTableFilter({ ...tableFilter, sortBuyerName: 'ASC', sortdate: false }); setRefresh(refresh + 1) } },
              { name: "Z to A", selected: tableFilter.sortBuyerName === 'DESC', onActionClick: () => { setTableFilter({ ...tableFilter, sortBuyerName: 'DESC', sortdate: false }); setRefresh(refresh + 1) } }]
          },
          { name: "Financer", filter: false }, { name: "Finance type", filter: false }, { name: "Amount", filter: false },
          { name: "Status", filter: false }
          ]}
          disableAction={true}
        />
      </div>
      <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={tableFilter.resultPerPage || 10} />
      {history && <History setHistory={setHistory} history={history} />}
    </>
  );
}
export default Finance;