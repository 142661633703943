
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges, handleFileCommonFunction, getWCTotalCharges } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { facilityNames1, facilityNames2, ownerReview, reviewBusiness, wcyesnoqsts } from "../workingCapital/WCApplyLimit";
import { InputForTable, InputWithSelect, SelectForTable } from "../../utils/newInput";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import avatarUrl from "../../utils/makeAvatarUrl";
import { FinancialMonths, companyDetailsReviewForm, yesnoqsts } from "./ApplyLimitCGTMSE";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

const tabLabels = [
  { name: "Application Form" },
  { name: "Documents" },
  { name: "Select Financier" }
]

const CGTMSEQuoteDetails = ({ userTokenDetails, navToggleState }) => {

  let cgtmseQuoteDetails = JSON.parse(localStorage.getItem("cgtmseQuoteDetails"))

  const userId = cgtmseQuoteDetails.isAdminUser ? cgtmseQuoteDetails.manualUserId : (userTokenDetails.user_id ? userTokenDetails.user_id : null)
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(cgtmseQuoteDetails.disableFinSelection ? 0 : 2)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30 })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [owners, setOwners] = useState([])
  const [exFacilties, setExFacilities] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [currencyData, setCurrencyData] = useState(most_used_currencies)


  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  async function loadData() {
    setshowLoader(true)
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: cgtmseQuoteDetails.applicationId, fetchCGTMSEDocs: true })
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    setData({
      ...data, ...cgtmseQuoteDetails.details, ...tempLimitDocsDetails, selectedLenderName: cgtmseQuoteDetails.selectedLenderName,
      financierQuotes: JSON.parse(cgtmseQuoteDetails.financierQuotes)
    })
    call('POST', 'getRecommendedFinanciers', { forBG: true }).then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    // Set lists
    let tempOwners = []
    let tempExFac = []
    let tempSuppliers = []
    let tempBuyers = []
    let allKeyes = Object.keys(cgtmseQuoteDetails.details) || []
    for (let index = 0; index < allKeyes.length; index++) {
      const element = allKeyes[index];
      if (element.includes(`ownerNameTitle`)) {
        tempOwners.push(null)
      }
      if (element.includes(`lenderName`)) {
        tempExFac.push(null)
      }
      if (element.includes(`supplierName`)) {
        tempSuppliers.push(null)
      }
      if (element.includes(`buyerName`)) {
        tempBuyers.push(null)
      }
    }
    setOwners(tempOwners)
    setExFacilities(tempExFac)
    setshowLoader(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  async function handleValidation() {
    let err = {}
    if (tab === 0) {
      setTab(1)
    }
    if (tab === 1) {
      setTab(2)
    }
    setErrors(err)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('cgtmseApplicationId', cgtmseQuoteDetails.applicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'cgtmseQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Application submitted successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state={cgtmseQuoteDetails.isAdminUser ? "otherFinAdminCGTMSEQuote" : "otherFinCGTMSEQuoteDetails"} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > CGTMSE > Quote Details"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (cgtmseQuoteDetails.isAdminUser) {
                            setTab(index)
                          }
                          else {
                            if (index < tab) {
                              setTab(index)
                            }
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabLabels} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => {
                      if (cgtmseQuoteDetails.isAdminUser) {
                        setTab(index)
                      }
                      else {
                        if (index < tab) {
                          setTab(index)
                        }
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mb-3'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {companyDetailsReviewForm.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    {owners.map((i, j) => {
                      return (
                        <div className='w-100 mb-3'>
                          <label className='font-wt-500 font-size-16'><u>{`Owner ${j + 1} Details`}</u></label>
                          <div className='row'>
                            {ownerReview.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  {item.name ?
                                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                    : null} </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    })}

                    <div className="mb-3">
                      <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } }, { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                        {Object.keys(yesnoqsts).map((i, j) => {
                          console.log('dataaaaaaaaaaaa', data, data[i]);
                          return (
                            <tr>
                              <td>{yesnoqsts[i]["label"]}</td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setData({ ...data, [i]: data[i] === true ? undefined : true })
                                  // }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === "true" ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setData({ ...data, [i]: data[i] === false ? undefined : false })
                                  // }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === "false" ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="mt-4">
                      <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>

                      <NewTablev2 columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]} showSideBorders={true}>
                        {FinancialMonths.map((i, j) => {
                          return (
                            <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_2ndLastValue`] || '-'}
                              </td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_LastValue`] || '-'}
                              </td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_CurrenntValue`] || '-'}
                              </td>

                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    {userTypeId / 1 == 8 ?
                      <div className="d-flex align-items-center my-2" >
                        <label className='font-wt-500 font-size-16 w-40'><u>Buyer Details</u></label>
                        <div className="d-flex w-60 justify-content-end" >
                          <div className="mr-4" >
                            <img
                              className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                            <label className="font-size-14 font-wt-600" >Approve Buyer</label>
                          </div>
                          <div>
                            <img
                              className="cursor mr-3"
                              src="assets/images/reject_new_btn.svg" />
                            <label className="font-size-14 font-wt-600">Reject Buyer</label>
                          </div>
                        </div>
                      </div> : null}
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={userTypeId / 1 == 8 ? [{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '10%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '10%' } }, { name: " ", width: '11%' }] :
                        [{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                        { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '10%' } },
                        { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '10%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '10%' } },
                        { subColumns: 'Years of Relation', subColumnStyle: { width: '10%' } },
                        { subColumns: 'Relation with Client', subColumnStyle: { width: '10%' } }]} >
                        {top10Buyers.map((i, j) => {
                          if (data[`buyerName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                                {userTypeId / 1 == 8 ?
                                  <td>
                                    <div className="d-flex">
                                      {data[`buyerAction${j}`] === "Denied" ? null : <img
                                        onClick={() => {
                                          setData({ ...data, [`buyerAction${j}`]: data[`buyerAction${j}`] ? undefined : "Approved" })
                                        }}
                                        className="cursor mr-3" src="assets/images/approve_new_btn.svg" />}
                                      {data[`buyerAction${j}`] === "Approved" ? null : <img
                                        onClick={() => {
                                          setData({ ...data, [`buyerAction${j}`]: data[`buyerAction${j}`] ? undefined : "Denied" })
                                        }}
                                        className="cursor"
                                        src="assets/images/reject_new_btn.svg" />}
                                    </div>
                                  </td> : null}
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>

                    <label className="font-size-15 font-wt-500 mb-1" ><u>Supplier Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Suppliers.map((i, j) => {
                          if (data[`supplierName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {
                  tab === 1 ? (
                    <div className='d-flex row px-5 pt-4 pb-4'>
                      <ExtraDetailsForLimitApplication isDisabled userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton={false} mainTagMarginPadding0={true} />
                    </div>
                  ) : null
                }

                {tab === 2 ? (
                  <div className='px-5 pt-5 pb-4 '>
                    <div className="lineheight19">
                      <label className='font-wt-500 font-size-16 mb-0 letter-spacing05'><u>Select a financer</u> </label>
                      <span> (By clicking on "Select Quote" button the respective financier will be selected for the furthur process)</span><br />
                    </div>
                    {data.financierQuotes?.map((key, index) => {

                      return (
                        <div className="card my-4">
                          <div className="d-flex w-100 p-4" >
                            <div className="col-10" >
                              <img className="mr-2"
                                src={typeof (key.logo) === 'string' ? avatarUrl(key.logo) : null} alt="" height={31} width={31} />
                              <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                            </div>
                            <div className="col-2" >
                              {key.financierAction === "Approved" ? (
                                <button
                                  onClick={async () => {
                                    setshowLoader(true)
                                    await call('POST', 'selectCGTMSEQuote', { selectedQuote: key, selectedFinancier: key.lender_id, applicationId: cgtmseQuoteDetails.applicationId, userName })
                                    setshowLoader(false)
                                    toggleApplicationSubmitPopup({ show: true })
                                  }}
                                  className={`mx-2  w-85 py-2 px-2 new-btn text-white `}>Select Quote</button>
                              ) : <button
                                className={`border-0 mb-2 text-white rejectedQuotebtn w-85`}>Rejected</button>}
                            </div>
                          </div>
                          <div className="d-flex w-100 px-4" >
                            <div>
                              <label className="font-size-14 font-wt-500" >{`Factoring Rate : ${key.factoringRate ? (key.factoringRate + " " + key.factoringRateCurrency) : "NA"}`}</label>
                            </div>
                            <div>
                              <label className="font-size-14 font-wt-500">{` | Interest Rate : ${key.termSofr ? ` (${key.termSofr}) ` : ""} ${key.interestRate ? (key.interestRate + " " + key.interestRateCurrency) : "NA"}`}</label>
                            </div>
                            <div>
                              <label className="font-size-14 font-wt-500">{` | Set-Up Fees : ${key.setUpFees ? (key.setUpFees + " " + key.setUpFeesCurrency) : "NA"}`}</label>
                            </div>
                            <div className="position-relative" >
                              <label className="font-size-14 font-wt-500 totalChargesLabel">{` | Other Charges : ${getBGTotalOtherCharges(key.otherCharges).toFixed(2) + " " + (key.otherCharges?.[0]?.["unit"] || "-")}`}</label>
                              <div className="otherChargePopup">
                                {key.otherCharges && key.otherCharges.length ? key.otherCharges.map((j) => (
                                  <>
                                    <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                      <label className="text-color-label">{j.name}</label>
                                      <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                    </div>
                                  </>
                                )) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex w-100 px-4" >
                            <div className="my-3" >
                              <NewTablev2 showSideBorders={false} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '12%' } },
                              { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '7%' } },
                              { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '7%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '7%' } },
                              { subColumns: 'Years of Relation', subColumnStyle: { width: '8%' } },
                              { subColumns: 'Relation with Client', subColumnStyle: { width: '8%' } },
                              { subColumns: 'Finance Limit', subColumnStyle: { width: '12%' } },
                              { subColumns: 'Discounting Percentage', subColumnStyle: { width: '12%' } },
                              { subColumns: 'Remarks', subColumnStyle: { width: '14%' } }, { name: "Status", width: '11%' }]} >
                                {top10Buyers.map((i, j) => {
                                  if (data[`buyerName${j}`]) {
                                    return (
                                      <tr>
                                        <td className="font-size-13 font-wt-500" >
                                          <InputForTable isDisabled name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                                        </td>
                                        <td className="font-size-13 font-wt-500">
                                          <InputForTable isDisabled type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                                        </td>
                                        <td className="font-size-13 font-wt-500">
                                          <InputForTable isDisabled type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                                        </td>
                                        <td className="font-size-13 font-wt-500">
                                          <InputForTable isDisabled type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                                        </td>
                                        <td className="font-size-13 font-wt-500">
                                          <InputForTable isDisabled type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                                        </td>
                                        <td className="font-size-13 font-wt-500">
                                          <InputForTable isDisabled type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                                        </td>
                                        <td className='font-size-13 font-wt-500 ' >
                                          {key.buyerQuoteDetails?.[`financeLimit${j}`] ? `${key.buyerQuoteDetails?.[`financeLimit${j}`]} ${key.buyerQuoteDetails?.[`financeLimitCurrency${j}`]}` : "-"}
                                        </td>
                                        <td className='font-size-13 font-wt-500' >
                                          {key.buyerQuoteDetails?.[`discountingPercentage${j}`] ? `${key.buyerQuoteDetails?.[`discountingPercentage${j}`]} %` : "-"}
                                        </td>
                                        <td className='font-size-13 font-wt-500' >
                                          {key.buyerQuoteDetails?.[`buyerRemark${j}`] || "-"}
                                        </td>
                                        <td>
                                          <div className="d-flex">
                                            {key.buyerQuoteDetails[`buyerAction${j}`] === "Approved" ?
                                              <button
                                                className={`border-0 mb-2 text-white approvedQuotebtn w-100`}>Approved</button> :
                                              <button
                                                className={`border-0 mb-2 text-white rejectedQuotebtn w-100`}>Rejected</button>}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  }
                                })}
                              </NewTablev2>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {/* <div id={"scrollContainerId"} style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className="row align-items-center d-flex w-100 my-4">
                      {da
                              {recommendedFinancie(key.factoringRate + " " + key.factoringRateCurrency) : "NA"}</label>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #dee2e6' }} className="d-flex w-100 py-2" >
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-50 text-left">Interest Rate</label>
                                      {key.termSofr ?
                                        <label className="mb-0 text-black font-size-11 font-wt-500 w-50 text-left">{`(${key.termSofr})`}</label> : null}
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-50 text-right">{key.interestRate ? (key.interestRate + " " + key.interestRateCurrency) : "NA"}</label>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #dee2e6' }} className="d-flex w-100 py-2" >
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-70 text-left">{`One time set-up fees ${key.setUpFeesNegotiable ? ' (negotiable)' : ''} `}</label>
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-30 text-right">{key.setUpFees ? (key.setUpFees + " " + key.setUpFeesCurrency) : "NA"}</label>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #dee2e6' }} className="d-flex w-100 py-2 position-relative" >
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-50 text-left">Other Charges</label>
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-50 text-right totalChargesLabel">{getBGTotalOtherCharges(key.otherCharges).toFixed(2) + " " + (key.otherCharges?.[0]?.["unit"] || "-")}</label>
                                      <div className="otherChargePopup">
                                        {key.otherCharges && key.otherCharges.length ? key.otherCharges.map((j) => (
                                          <>
                                            <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                              <label className="text-color-label">{j.name}</label>
                                              <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                            </div>
                                          </>
                                        )) : null}
                                      </div>
                                    </div>
                                    <div className="d-flex w-100 py-2 position-relative" >
                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-70 text-left">Total Charges
                                        <label className="selectfinacne-subheading font-size-10">($ 50,000/30 days)</label></label>

                                      <label className="mb-0 text-black font-size-13 font-wt-500 w-30 text-right totalChargesLabel">{getWCTotalCharges(key).toFixed(2) + " " + (key.setUpFeesCurrency || "-")}</label>
                                    </div>
                                  </div>
                                </>
                              )}
                              {cgtmseQuoteDetails.disableFinSelection ? null : (
                                <div>
                                  {key.financierAction === "Approved" ? (
                                    <button
                                      onClick={async () => {
                                        setshowLoader(true)
                                        await call('POST', 'selectWCQuote', { selectedQuote: key, selectedFinancier: key.lender_id, applicationId: cgtmseQuoteDetails.applicationId, userName })
                                        setshowLoader(false)
                                        toggleApplicationSubmitPopup({ show: true })
                                      }}
                                      className={`mx-2  w-85 py-2 px-2 new-btn text-white `}>Select Quote</button>
                                  ) : null}</div>
                              )}

                              <p className="mt-1 mb-4"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-12 color434343 letter-spacing05 ms-2 cursor"
                                onClick={async () => {
                                  let reqObj = {
                                    senderId: userId,
                                    receiverId: key.lender_id / 1,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    wcApplicationId: cgtmseQuoteDetails.applicationId,
                                    receiverParties: key.lender_id / 1,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${key.lender_name} - ${apiResp.id}`,
                                    wcApplicationId: cgtmseQuoteDetails.applicationId,
                                    parties: apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: key.lender_id / 1
                                  })
                                }}
                              >Chat/Remarks</span></p>
                            </div>
                          </div>
                        )
                      }) : null}
                    </div>
                    {data.financierQuotes?.length > 4 && quoteView != 'table' ? (
                      <div className="col-12 mx-auto text-end">
                        <img onClick={() => {
                          scrollLeft()
                        }}
                          className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                        <img onClick={() =>
                          scrollRight()
                        }
                          className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                      </div>
                    ) : null} */}
                  </div>
                ) : null}

                {tab === 2 ? null : <div className="pb-5 px-5">
                  <button type="button"
                    onClick={() => handleValidation()}
                    className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                    {"Continue"}
                  </button>
                </div>}

              </div>

            </div>

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(CGTMSEQuoteDetails)