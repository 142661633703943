import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import SubAdminProfileAdmin from "./SubAdminProfileAdmin";
import SubAdminProfileCRM from "./SubAdminProfileCRM";

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "18rem",
      "right": "6rem"
    },
    arrow: {
      "top": "28rem",
      "zIndex": 10,
      transform: "rotate(110deg)",
      left: "15.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>You can check your "Payment, Finance & Shipment report" by clicking on the respective tab under Reports.</span></label>
  }
}

const SubAdminTab = ({ userTokenDetails, navToggleState }) => {

  const [tab, setTab] = useState("Admin");
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 3)
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="AdminReports" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Reports"}
              userTokenDetails={userTokenDetails} />
            <div className='d-flex justify-content-between w-100'>

              <nav>
                <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                  <button className={`nav-link  bg-transparent ${tab === "Admin" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("Admin")}>Admin</button>
                  <span className="border-left"></span>
                  <button className={`nav-link  bg-transparent ${tab === "CRM" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("CRM")}>CRM</button>

                </div>
              </nav>
            </div>
            <div className="tab-content d-block" id="nav-tabContent" style={lcTutorial ? { position: 'relative', zIndex: -1 } : {}}>
              {tab === 'Admin' &&
                <SubAdminProfileAdmin userTokenDetails={userTokenDetails} navToggleState={navToggleState} />
              }
              {tab === 'CRM' &&
                <SubAdminProfileCRM userTokenDetails={userTokenDetails} navToggleState={navToggleState} />
              }
            </div>
          </main>
        </div>
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SubAdminTab)