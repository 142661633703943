import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { mainUrl } from '../../urlConstants';
import { getUserDataFromCookie } from '../../utils/cookieHelper';

const Header = ({ title }) => {

  var [login, setlogin] = useState(false);

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    let userDetails = getUserDataFromCookie()
    let userTypeId = userDetails.type_id ? userDetails.type_id : null
    let userToken = userDetails.token ? userDetails.token : null
    let status = userDetails.status ? userDetails.status : null
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //Redirect if user already logged in
    if ((userToken !== undefined && userToken !== null) && (userTypeId !== undefined && userTypeId !== null) && (status !== undefined && status !== null)) {
      setlogin(true)
    } else {
      setlogin(false)
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  return (
    <div className="col-md-12 bording-h2">
      <ul className="header-onboarding">
        <li><a href={mainUrl}><img height="50" src="../assets/images/logos/logo_1.png" alt="Logo" /></a></li>
        <li><h2 className="text-center">{title}</h2></li>
        <li><Link to="/login">{login ? "Dashboard" : "Login"}</Link></li>
      </ul>
    </div>
  )
}

export default Header;
