import React, { useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import ContainerComp from './containersComp.js'


const AssignContainerModal = ({ userTokenDetails, assignContainer, setassignContainer, setrefresh, refresh }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [containerDetails, setContainerDetails] = useState({});
  const [showLoader, setshowLoader] = useState(false);

  //---------------------------------------------------------------------------------------------------------------------

  console.log("AssignContainerModal containerDetails=>", containerDetails)

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function saveContainerInfo() {

    setshowLoader(true)

    call('POST', 'updatecontainerincontract', { contractNo: assignContainer.data.contract_no, containerDetails: containerDetails }).then((result) => {
      if (result) {
        setshowLoader(false)
        setassignContainer({ modal: false, data: {} })
        toastDisplay(" Succesfully", "success")
        setrefresh(refresh + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      //setAddShip(false, {})
    })

  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {assignContainer.modal && <div className={"modal" + (assignContainer.modal ? " show" : "")} id="shipmodal">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Assign Containers for Contract {assignContainer.data.contract_no}</h4>
              <button type="button" className="close" onClick={() => setassignContainer({ modal: false, data: {} })}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="modal-padding">
                <ul className="price-ul">
                  <li>
                    <div className="price-left">{assignContainer.data.contract_no}</div>
                    <div className="price-right">Contract</div>
                  </li>
                  <li>
                    <div className="price-left">{assignContainer.data.charterer}</div>
                    <div className="price-right">Charterer </div>
                  </li>
                  <li>
                    <div className="price-left">{assignContainer.data.shipOwner}</div>
                    <div className="price-right">Ship Owner</div>
                  </li>
                  <li>
                    <div className="price-left">{assignContainer.data.loadingPort}  -  {assignContainer.data.unloadingPort}</div>
                    <div className="price-right">Loading Port - Unloading Port</div>
                  </li>
                </ul>
              </div>

              <div className="modal-padding mt-4">
                <ContainerComp
                  userTokenDetails={userTokenDetails}
                  contractNo={assignContainer.data.contract_no}
                  JSONData={containerDetails}
                  setContainerDetails={setContainerDetails} />
              </div>

            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => setassignContainer({ modal: false, data: {} })}>Cancel</button>
              <button type="button" className="btn btn-info btn-sm" onClick={() => saveContainerInfo()}>Update</button>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------

export default AssignContainerModal
