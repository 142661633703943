import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setFinanceDetails } from "../../../store/actions/action";
import Header from '../../partial/header';
import { ToastContainer } from 'react-toastify';
import ChooseFinance from "./chooseFinance.js";
import POFinance from "./poFinance.js";
import LCFinance from './lcFinance.js'
import InvoiceFinance from './invoiceFinance.js'
import MyTradeOffline from './myTradeOffline.js'
import MyTradeOnline from './myTradeOnline.js'
import FreightFinance from './freightFinance';
import call from '../../../service';

const CreateFinancePages = ({ userTokenDetails, setFinanceDetails, financeDetails, dispatch, viewOnly, buyerUserId }) => {

  const [financeType, setFinanceType] = useState(null);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;

  useEffect(() => {
    if (userTypeId === 19) {
      call('POST', 'getFinanceType', { 'userId': userId }).then((result) => {
        console.log('result in getFinanceType', result);
        setFinanceType(result);

      }).catch((e) => {
        console.log('error in getFinanceType', e);
      });
    }
  }, [])

  console.log(financeDetails);
  // JSX body
  return (
    <>
      {!viewOnly ? (
        <Header
          title={financeDetails.type === 0 ? 'Apply Finance against' :
            financeDetails.type === 1 ? 'Finance against Purchase Order' :
              financeDetails.type === 2 ? 'Finance against Letter of Credit' :
                financeDetails.type === 3 ? 'Finance against Invoice' :
                  financeDetails.type === 4 ? 'Finance against My Trade' :
                    financeDetails.type === 5 ? 'Finance against My Trade' :
                      financeDetails.type === 6 ? 'Finance against Freight' : '---'}
          userTokenDetails={userTokenDetails} />
      ) : null}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {userTypeId === 19 ? (financeDetails.info.action === 'viewFinance') ?
        <div className="col-md-12 mt-5">

          {financeDetails.status && financeDetails.type === 0 && financeDetails.page === 0 &&
            <ChooseFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 1 &&
            <POFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 2 &&
            <LCFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 3 &&
            <InvoiceFinance
              userTokenDetails={userTokenDetails} viewOnly={viewOnly} buyerUserId={buyerUserId} />}


          {financeDetails.status && financeDetails.type === 4 &&
            <MyTradeOffline
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 5 &&
            <MyTradeOnline
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 6 &&
            <FreightFinance
              userTokenDetails={userTokenDetails} />}


        </div>
        :
        <div className="col-md-12 mt-5" >
          {financeDetails.status && financeDetails.type === 0 && financeDetails.page === 0 &&
            <ChooseFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 2 &&
            <LCFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 3 &&
            <InvoiceFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 4 &&
            <MyTradeOffline
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 5 &&
            <MyTradeOnline
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 6 &&
            <FreightFinance
              userTokenDetails={userTokenDetails} />}
          {/* {financeType === 'Invoice Discounting' ? */}
          {/* <InvoiceFinance viewOnly={viewOnly} buyerUserId={buyerUserId}
            userTokenDetails={userTokenDetails} /> */}
          {/* :
            (financeType === 'Letter of Credit (LC) Discounting') ? */}
          {/* <LCFinance
            userTokenDetails={userTokenDetails} /> */}
          {/* :
              (financeType === 'Freight Finance') ? */}
          {/* <FreightFinance
            userTokenDetails={userTokenDetails} /> */}
          {/* : ''} */}
        </div> :
        <div className="col-md-12 mt-5">

          {financeDetails.status && financeDetails.type === 0 && financeDetails.page === 0 &&
            <ChooseFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 1 &&
            <POFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 2 &&
            <LCFinance
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 3 &&
            <InvoiceFinance viewOnly={viewOnly} buyerUserId={buyerUserId}
              userTokenDetails={userTokenDetails} />}


          {financeDetails.status && financeDetails.type === 4 &&
            <MyTradeOffline
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 5 &&
            <MyTradeOnline
              userTokenDetails={userTokenDetails} />}

          {financeDetails.status && financeDetails.type === 6 &&
            <FreightFinance
              userTokenDetails={userTokenDetails} />}


        </div>
      }
    </>
  );
};

// Redux
// Getting the global state from redux store.
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    financeDetails: state.financeDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(mapStateToProps, mapDispatchToProps)(CreateFinancePages);
