import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewSelect } from "../../../utils/newInput";
import SelectBuyerModal from "../../InvoiceDiscounting/components/selectBuyerModal";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import { getDocDetails, most_used_currencies, } from "../../../utils/myFunctions";
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import TutorialPopup, { TutorialVideoPopup } from "../../tutorialPopup";
import MultipleSelect from "../../../utils/MultipleSelect";
import { platformURL } from "../../../urlConstants";

const tutorialPopupCSS = {
  "0": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "2rem",
      "right": "-20rem",
      "display": "none"
    },
    arrow: {
      "top": "11rem",
      "zIndex": 10,
      transform: "rotate(236deg)",
      left: "48.5%",
      "display": "none"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span></span></label>
  },
  "1": {
    stageName: "Apply For Finance Limit",
    showNext: false,
    showBack: false,
    popup: {
      top: '3rem',
      right: '-14rem'
    },
    arrow: {
      "top": "11rem",
      "zIndex": 10,
      transform: "rotate(263deg)",
      left: '38.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Select any one Limit type to "Apply for Finance Limit"</span></label>
  }
}

const limdata = [
  {
    Icon: "assets/images/Lc/bank.png",
    Heading: "Invoice Discounting",
    Link: "/applyLimitV2",
    value: "invoice_discounting"
  },
  {
    Icon: "assets/images/lc_discounting.svg",
    Heading: "LC Discounting (International)",
    Link: "/LcDiscountingV2",
    value: "lc_discounting"
  },
  {
    Icon: "assets/images/lc_confirmation.svg",
    Heading: "LC Confirmation (International)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation"
  },
  {
    Icon: "assets/images/lc_confirmation_discounting.svg",
    Heading: "LC Confirmation & Discounting (International)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation_discounting"
  },
  {
    Icon: "assets/images/lc_discounting.svg",
    Heading: "LC Discounting (Domestic)",
    Link: "/LcDiscountingV2",
    value: "lc_discounting_domestic"
  },
  {
    Icon: "assets/images/lc_confirmation.svg",
    Heading: "LC Confirmation (Domestic)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation_domestic"
  },
  {
    Icon: "assets/images/lc_confirmation_discounting.svg",
    Heading: "LC Confirmation & Discounting (Domestic)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation_discounting_domestic"
  },
  // {
  //   Icon: "assets/images/Lc/sblc.png",
  //   Heading: "SBLC",
  //   Link: "/LcDiscountingV2",
  //   value: "sblc"
  // },
];

const onlyLimdata = [
  {
    Icon: "assets/images/lc_discounting.svg",
    Heading: "LC Discounting (International)",
    Link: "/LcDiscountingV2",
    value: "lc_discounting"
  },
  {
    Icon: "assets/images/lc_confirmation.svg",
    Heading: "LC Confirmation (International)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation"
  },
  {
    Icon: "assets/images/lc_confirmation_discounting.svg",
    Heading: "LC Confirmation & Discounting (International)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation_discounting"
  },
  {
    Icon: "assets/images/lc_discounting.svg",
    Heading: "LC Discounting (Domestic)",
    Link: "/LcDiscountingV2",
    value: "lc_discounting_domestic"
  },
  {
    Icon: "assets/images/lc_confirmation.svg",
    Heading: "LC Confirmation (Domestic)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation_domestic"
  },
  {
    Icon: "assets/images/lc_confirmation_discounting.svg",
    Heading: "LC Confirmation & Discounting (Domestic)",
    Link: "/LcDiscountingV2",
    value: "lc_confirmation_discounting_domestic"
  },
  // {
  //   Icon: "assets/images/Lc/sblc.png",
  //   Heading: "SBLC",
  //   Link: "/LcDiscountingV2",
  //   value: "sblc"
  // },
]

const ApplyforLimit = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyLC = queryParams.get("lc")
  console.log('Historyyyyy', window.location.pathname)
  const [dbData, setDbData] = useState([])
  const [modal, setModal] = useState(true);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [serviceCharges, setServiceCharges] = useState([])
  const [continueWithoutBuyer, setCountinueWithoutBuyer] = useState(false)
  const [LimitData, setLimitData] = useState(limdata)
  const [lcTutorial, toggleLCTutorial] = useState((localStorage.getItem("lcTutorialStep") == 7 || localStorage.getItem("lcTutorialStep") == 9))
  const [tutorialStage, setTutorialStage] = useState(0)
  const [buyerdata, setBuyerdata] = useState([])
  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const getBuyerDetails = () => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyerDetailsV2', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      setDbData([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData]);
      setBuyerdata([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData])
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)

    });
  }
  useEffect(() => {
    //Get Buyer list start
    getBuyerDetails()
    call('POST', 'getWallertServiceCharges', { userId, userTypeId }).then((result) => {
      console.log("API result getWallertServiceCharges", result);
      setshowLoader(false)
      setServiceCharges(result)
    }).catch((e) => {
      console.log("Error while getWallertServiceCharges", e);
      setshowLoader(false)
    });
  }, [])

  useEffect(() => {
    if (buyer && dbData.length) {
      let temp = {
        target: {
          name: "selectedBuyer",
          value: buyer
        }
      }
      handleChange(temp)
      hideBuyerModal()
    }
  }, [dbData])

  const hideBuyerModal = () => {
    setModal(false);
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData.filter(item => {
        if (item.id == event.target.value) {
          return item
        }
      })[0]
      // let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
      // let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])
      setshowLoader(false)
      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1]
        // invoiceDocument, poDocument
      })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }
  const handleFilterOptions = (typedInput) => {
    // console.log("typedInput", typedInput);
    let tempPort = []
    let filtered = []
    tempPort = [{ id: "addNewBuyer", buyerName: "Add New Buyer", typedInput }]
    filtered = dbData.filter((i) => {
      if (i.buyerName && i.buyerName?.toLowerCase()?.includes(typedInput?.toLowerCase())) {
        return i
      }
    })
    if (!filtered.length) {
      filtered = tempPort
    }
    setBuyerdata(filtered)
  };
  const handleMultiSelect = async (e, name, val) => {
    if (e?.[0]?.id === "addNewBuyer") {
      return window.open(`/buyerManagement?action=addNewBuyer`, "_blank")
    }
    if (e?.[0]?.id === "temp") {

    } else {
      setData({
        ...data,
        ...e?.[0],
        selectedBuyer: e?.[0]?.id
      })
    }
  }
  console.log('selectedBuyer', data.selectedBuyer)
  return (
    <>

      <TutorialPopup show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"]
        }} showSkip={true} userId={userId} showNext={tutorialPopupCSS[tutorialStage]["showNext"]}
        showBack={tutorialPopupCSS[tutorialStage]["showBack"]}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="applyForLimit" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for Limit"}
              userTokenDetails={userToken} />
            <div className="col-md-11 mx-auto mt-4">
              <div className="p-5 card border-0 rounded-3">
                <a className="cursor"
                  onClick={() => { window.location = "/buyerManagement" }}
                ><img
                    src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div className="text-center">
                  <p className="mb-0 font-size-14 mt-3" >Select Limit type</p>
                  <small className="text-secondary font-size-13 ">Applying finance limit for {data.buyerName}  </small>
                </div>
                <p className="mb-0 font-size-14 my-4">Non -  recourse</p>
                <div className="row mt-4 mb-5 pb-5"
                  style={tutorialStage == 1 ? {
                    "background": "white",
                    "position": "relative",
                    "zIndex": 10001,
                    "padding": "1rem 0rem 1rem 0rem",
                    "borderRadius": "1rem",
                  } : {}}>
                  {LimitData.map((key, index) => {
                    return (
                      <div className="col-md-4 mt-4" key={index}>
                        <a onClick={() => {
                          if (lcTutorial) {
                            if (onlyLC) {
                              localStorage.setItem("lcTutorialStep", 10)
                              localStorage.setItem("tutorialNavLink", `${key.Link}?buyer=${data.id}&purpose=${key.value}&sendLCForReview=true${userData ? '&adminLCApply=true' : ''}`)
                            }
                            else {
                              localStorage.setItem("lcTutorialStep", 8)
                              localStorage.setItem("tutorialNavLink", `/applyLimit?buyer=${data.id}&purpose=invoice_discounting${userData ? '&adminINVApply=true' : ''}`)
                            }
                            window.location.reload()
                          }
                          else {
                            if (key.value.includes("lc")) {
                              window.location = `${key.Link}?buyer=${data.id}&purpose=${key.value}&sendLCForReview=true${userData ? '&adminLCApply=true' : ''}`
                            }
                            else {
                              window.location = `${key.Link}?buyer=${data.id}&purpose=${key.value}${userData ? '&adminINVApply=true' : ''}`
                            }
                          }
                        }} className="text-decoration-none text-dark">
                          <div className="card p-3 limitypecard cursor">
                            <div className="d-flex gap-3 align-items-center">
                              <img src={key.Icon} alt="" />
                              <div className="row">
                                <p className="font-size-14 mb-1">{key.Heading}</p>
                                {/* <div className="col">
                                    <span className="font-size-12 mb-2">{"Avail Using"}</span><span> <img src={'/assets/images/Lc/Dollar.png'} alt='' />
                                      {serviceCharges[key.Heading] && serviceCharges[key.Heading]["discountedPrice"] ? (
                                        <del className='mr-2'>{serviceCharges[key.Heading] && serviceCharges[key.Heading]["serviceCharges"] * -1}</del>
                                      ) : serviceCharges[key.Heading] && serviceCharges[key.Heading]["serviceCharges"] * -1}
                                      {serviceCharges[key.Heading] && serviceCharges[key.Heading]["discountedPrice"] ? (serviceCharges[key.Heading]["discountedPrice"] * -1) : ""}
                                      {" Coins"}</span>
                                  </div> */}
                                {/* {key.Heading === "LC discounting" && < p className="text-secondary font-size-13 mb-0">Avail using <img src={"assets/images/Lc/Dollar.png"} alt="coin" /> <span className="text-color1">500 coins</span></p>}
                                {key.Heading === "SBLC" && < p className="text-secondary font-size-13 mb-0"></p>}
                                {key.Heading === "LC confirmation" && < p className="text-secondary font-size-13 mb-0"><img src={"assets/images/warning.png"} alt="coin" /> <span className="text-color1">See Quotes</span></p>} */}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div >
      {modal && <SelectBuyerModal modal={modal} setModal={() => { window.location = "/buyerManagement" }} >
        <h6 className="mb-4">Select Buyer</h6>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="col-md-7 mx-auto my-4 mb-5">
          <MultipleSelect
            Id=""
            Label=""
            onClick={getBuyerDetails}
            filterOption={() => true}
            onInputChange={(e) => {
              console.log('On autocomplete change input', e)
              handleFilterOptions(e)
            }}
            optiondata={buyerdata}
            onChange={(e) => {
              console.log('On autocomplete change', e)
              handleMultiSelect(e, "id")
            }}
            value={data.selectedBuyer ? [data.selectedBuyer] : []}
            name="selectedBuyer"
            labelKey={"buyerName"}
            valKey={"id"}
            error={errors.selectedBuyer}
          />
          {/* <NewSelect isAstrix={false} label={""}
            selectData={dbData} name={"selectedBuyer"}
            value={data.selectedBuyer} optionLabel={"buyerName"} optionValue={'id'}
            onChange={handleChange} /> */}

        </div>
        <div className="text-center">
          <button type="button" disabled={!data.selectedBuyer} onClick={() => {
            if (lcTutorial) {
              setTutorialStage(1)
            }
            hideBuyerModal()
          }}
            className={`new-btn w-20 py-2 px-2 text-white`}>
            {"Continue"}
          </button>
        </div>
        {(onlyLC || userData) ? (
          <>
            <label className="font-size-20 font-wt-600 mt-4 text-color-label">OR</label><br />
            <label
              onClick={() => {
                if (lcTutorial) {
                  setTutorialStage(1)
                }
                setCountinueWithoutBuyer(true); setModal(false)
                setLimitData(onlyLimdata)
              }}
              className="font-size-18 font-wt-600 mt-3 anchor cursor">{"Continue without selecting buyer "}<img
                style={{ "transform": "rotate(180deg)" }}
                className="ml-2" src={"assets/images/back.png"} alt="Back" height={20} width={20} /></label>
          </>
        ) : null}
      </SelectBuyerModal>}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyforLimit)