import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

export default class ShipmentCountryChart extends PureComponent {

  render() {

    return (
      <PieChart width={250} height={350} onMouseEnter={this.onPieEnter}>
        <Pie
          data={this.props.data}
          cx={120}
          cy={200}
          fill="#8884d8"
          paddingAngle={5}
          dataKey={this.props.dataKey}
          label
        >
          {this.props.data && this.props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <label>TOP 10 Shipments</label>
      </PieChart>
    );
  }
}
