import React from "react";
import { useEffect } from "react";

export const Action = ({ id, options, top, onDismiss, isExpandedTable }) => {
  const box = React.createRef();

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, [])

  const handleOutsideClick = (event) => {
    let eventTargetStr = event?.target?.outerHTML
    // console.log("handleOutsideClick", box.current, event?.target?.outerHTML);
    if (box && box.current && !box.current.contains(event.target) && !eventTargetStr?.includes("<i class=")) {
      onDismiss()
    }
  }


  return (
    <div ref={box}
      style={isExpandedTable ? {
        marginTop: "9rem",
        "zIndex": 10,
        // "top": "9rem",
        // "zIndex": 10,
        // "right": "1rem"
      } : {
        "top": "2rem",
        "zIndex": 10,
        "right": "3rem"
      }}
      className="bg-white action-view text-left">
      {options.map((item, index) => {
        return (
          <div className="py-2 px-4 action-label d-flex align-items-center"
            onClick={() => { item.onClick(id); onDismiss() }}>
            {
              item.icon ? (
                <img className="mr-2 " src={`assets/images/${item.icon}`} height={15} width={15} />) : null
            }
            <label className="m-0 font-size-12">{item.name}</label>
          </div >
        )
      })}
    </div >
  )
}