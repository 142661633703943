import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { get } from 'store';
import call from '../../service';
import { formatDate_Application } from '../../utils/dateFormaters';
import customStyles from '../dataTablesColumsMap/customTableCss';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import { lcfast_grid_columns } from './lcFastGridData';
import { LCQuoteForm } from './lcQuoteForm';
import moment from 'moment'
import Footer from '../partial/footer';
import FilePreview from '../utilComponents/previewFile';
import toastDisplay from '../../utils/toastNotification';

const LCFastDashboard = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {
  console.log("tokennnnn", userTokenDetails);

  const [showLCQuoteForm, toggleLCQuoteForm] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [dbData, setDbData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [selectedLCData, setSelectedLCData] = useState({})
  const [filterCount, setFilterCount] = useState({})
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>

  const isBankerLogin = userTypeId / 1 == 8 ? true : false

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      getLCFastData()
    }
  };

  useEffect(() => {
    getLCFastData()
  }, [filterData, currentPage, perPage, showLCQuoteForm])


  function getStatus(item) {
    if (true) {
      if (item.offer_id) {
        return item.offer_status === 0 ? "Approved by Bank" : item.offer_status === 1 ? "Rejected by Bank" :
          item.offer_status === 2 ? "Deal Locked" : ""
      }
      else {
        return "Pending"
      }
    }
  }

  function callPreviewFileComponent(values, action) {
    if (values.base64 && values.file_data) {
      setfileData({ "action": action, "file_data": values.file_data, "base64": values.base64 })
    }
    else {
      setfileData({ "action": action, "file_hash": values.file_hash, "file_name": values.file_name, "file_type": values.doc_type })
    }
    setshowPreviewModal(true)
  }

  function formatResult(result) {
    result.forEach((item, index) => {
      result[index]["exp_date_of_shipment"] = moment(item.exp_date_of_shipment).format('YYYY-MM-DD')
      result[index]["status"] = getStatus(item)
      result[index]["roi"] = item.offer_id ? item.offer_details.roi : "NA"
      result[index]["amount"] = item.offer_id ? item.offer_details.amount + (" " + item.offer_details.currency.split("::")[1]) : "NA"
      result[index]["lc_doc_action"] = (<>
        {<button className="btn btn-info btn-sm mr-2 ml-2"
          onClick={() => { callPreviewFileComponent({ "doc_type": "LC Document", ...result[index]["lc_doc"] }, "view") }} >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>}
      </>)
      result[index]["action"] = (<>
        {<button className="btn btn-info btn-sm mr-2 ml-2" onClick={() => { toggleLCQuoteForm(true); setSelectedLCData(result[index]) }} >
          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        </button>}
      </>)
    })
    return result
  }


  function getLCFastData() {
    setshowLoader(true)
    call("POST", "getLCFast", {
      userId, userTypeId, ...filterData, currentPage, perPage
    })
      .then((result) => {
        setDbData(formatResult(result.data))
        setTotalRows(result.totalCount)
        setFilterCount(result.filterCount)
        setshowLoader(false)
      })
      .catch((e) => {
        // console.log("Error while querying getLCFast:", e);
        setshowLoader(false)
      });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
    setCurrentPage(page)
  };

  const handlePageChange = page => {
    setCurrentPage(page)
  };

  function getTableColumns() {
    if (filterData.status == 0) {
      return lcfast_grid_columns.filter(item => {
        if (item.selector != "roi" && item.selector != "amount" && item.selector != 'offer_by_bank') {
          return item
        }
      })
    }
    else {
      if (userTypeId / 1 == 8) {
        return lcfast_grid_columns.filter(item => {
          if (item.selector != 'offer_by_bank') {
            return item
          }
        })
      }
      else {
        return lcfast_grid_columns
      }
    }
  }

  function ExportTableCSV() {

    setshowLoader(true)

    call('POST', 'getLCFast',
      { userId, userTypeId, ...filterData, currentPage, perPage: totalRows }).then((result) => {
        setshowLoader(false)
        console.log('running getLCFast api-->', result);

        if (result.data && result.data.length) {
          const dbResult = formatResult(result.data)
          let csvString = "Application No.,Benificiary Name,Buyer Name,DUNS No.,Offer By Bank Name,Rate of Interest,Amount,LC Tenor,Shipment From Country,Shipment To Country,Expected date of shipment,Country of origin,Commodity,Issuing Bank Name,Issuing Bank Address,Status\r\n"


          for (let i = 0; i < dbResult.length; i++) {
            let rowString = `${dbResult[i].application_id},
        ${dbResult[i].benificiary_name || "NA"},
        ${dbResult[i].buyer_name || "NA"},
        ${dbResult[i].buyer_duns_no || "NA"},
        ${dbResult[i].offer_by_bank || "NA"},
        ${dbResult[i].roi || "NA"},
        ${dbResult[i].amount || "NA"},
        ${dbResult[i].lc_tenor || "NA"},
        ${dbResult[i].shipment_from_country || "NA"},
        ${dbResult[i].shipment_to_country || "NA"},
        ${dbResult[i].exp_date_of_shipment || "NA"},
        ${dbResult[i].country_of_origin || "NA"},
        ${dbResult[i].commodity || "NA"},
        ${dbResult[i].issuing_bank_name || "NA"},
        ${dbResult[i].issuing_bank_address || "NA"},
        ${dbResult[i].status || "NA"}\r\n`

            rowString = rowString.replace(/(\n)/gm, "")
            csvString += rowString
          }

          let link = document.createElement('a');
          link.style.display = 'none';
          link.setAttribute('target', '_blank');
          link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
          link.setAttribute('download', `LC_FAST_${userEmail.split('@')[0]}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          toastDisplay("No data found to download", "error")
        }

      }).catch((e) => {
        setshowLoader(false)
        console.log('error in ExportTableCSV', e);
      })
  }

  return (
    <>
      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
        mktPlaceShipment={true}
      />}
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className='row'>
          <Sidebar state={'lc-fast'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={showLCQuoteForm ? "LC Quote Application" : 'LC Fast'}
              total={'Total LC Quotes ' + (filterCount.total || 0)}
              userTokenDetails={userTokenDetails}
              onButtonClick={() => toggleLCQuoteForm(true)}
              showNavBack={showLCQuoteForm}
              onBackAction={() => { toggleLCQuoteForm(false); setSelectedLCData({}) }}
              showHeaderButton={!showLCQuoteForm}
            />
            {showLCQuoteForm &&
              <LCQuoteForm
                userTokenDetails={userTokenDetails}
                onClose={() => { toggleLCQuoteForm(false); setSelectedLCData({}) }}
                selectedLCData={selectedLCData}
              />}


            {!showLCQuoteForm && (
              <div class="d-md-flex mt-3 mb-3">
                <div class="filter-bg">
                  <ul>
                    <li className={"pilot-cont  cursor-pointer " + (filterData.status === 0 ? "shadow" : '')} id="progessButton" onClick={() => {
                      setFilterData({ ...filterData, status: 0 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Pending (${filterCount.pending || 0})`}
                    </li>
                    <li className={"complete-cont  cursor-pointer " + (filterData.status === 1 ? "shadow" : '')} onClick={() => {
                      setFilterData({ ...filterData, status: 1 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Approved (${filterCount.approved || 0})`}</li>
                    <li className={"danger-cont text-danger  cursor-pointer " + (filterData.status === 2 ? "shadow" : '')} onClick={() => {
                      setFilterData({ ...filterData, status: 2 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Rejected (${filterCount.rejected || 0})`}</li>
                    <li className={"primary-cont cursor-pointer " + (filterData.status === 3 ? "shadow" : '')} onClick={() => {
                      setFilterData({ ...filterData, status: 3 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Deal Locked (${filterCount.deal_locked || 0})`}</li>
                  </ul>
                </div>


                <div class="ml-md-auto">
                  <ul class="range-slider">
                    <li>
                      <input name="search" id="searchInput" placeholder="Search" value={filterData.search} onChange={(event) => {
                        setFilterData({ ...filterData, search: event.target.value })
                      }} onKeyDown={handleKeyPress} />
                      <button type="button" onClick={() => {
                        getLCFastData()
                      }}><i class="fas fa-search fa-lg"></i></button>
                    </li>

                    <li>
                      <label>From</label>
                      <input type="date" id="searchFromdate" name="fromDate" value={filterData.fromDate} onChange={(event) => {
                        setFilterData({ ...filterData, fromDate: event.target.value })
                      }} />
                    </li>
                    <li>
                      <label>To</label>
                      <input type="date" id="searchTodate" name="toDate" value={filterData.toDate} onChange={(event) => {
                        setFilterData({ ...filterData, toDate: event.target.value })
                      }} />
                    </li>
                    <li>
                      <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() =>
                        setFilterData({ search: "", fromDate: "", toDate: "" })} />
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {!showLCQuoteForm &&
              <div class="table-responsive">
                <DataTable
                  columns={getTableColumns()}
                  data={dbData}
                  progressPending={showLoader}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                  persistTableHead
                  // expandableRows
                  // expandableRowsComponent={<></>}
                  // expandOnRowClicked
                  highlightOnHover
                  // onSort={handleSort}
                  sortServer
                  striped
                  noHeader
                  customStyles={customStyles}
                />
              </div>
            }

            <hr />
            <div className="float-left col-md-6 ">
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
                  </button>
                </div>
              </div>
            </div>

          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}


const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(LCFastDashboard)
