import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
// const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}
let userTypeId = userDetails.type_id ? userDetails.type_id : null

var columns = [
  {
    name: 'Application No.',
    selector: 'reference_no',
    sortable: false,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Credit Days',
    selector: 'credit_days',
    sortable: false,
  },
  {
    name: 'Request Date',
    selector: 'created_at',
    sortable: false,
  },
  {
    name: 'Disbursed Date',
    selector: 'issue_date',
    sortable: false,
  },
  {
    name: "Action",
    grow: 3,
    selector: 'action',
    center: true
  }
];


export default columns;