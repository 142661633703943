const columns = [
  {
    name: 'Deal Id',
    selector: 'txnId',
    sortable: false,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Buyer',
    selector: 'buyer',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Plan Name',
    selector: 'planName',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
  },
  {
    name: 'Currency',
    selector: 'currency',
    sortable: false,
  },
  {
    name: 'Dated',
    selector: 'dated',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Payment Status',
    selector: 'status',
    sortable: false,
    conditionalCellStyles: [
      {
        when: row => (row.status === "REFUNDED" || row.status === "PARTIALLY REFUNDED"),
        style: {
          backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
          color: 'white',
        },
      },
      {
        when: row => (row.status === "PENDING"),
        style: {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
          color: 'white',
        },
      },
      {
        when: row => (row.status === "DECLINED"),
        style: {
          backgroundColor: 'rgba(255, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === "COMPLETED"),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      }
    ]
  },
  {
    name: "Action",
    grow: 2,
    selector: 'action',
    center: true
  }
];

export default columns
