import React, { useEffect, useState } from 'react'
import CustomAreaChart from '../../Dashboard/components/CustomAreaChart'
import { NewSelect } from '../../../utils/newInput'
import CustomBarChart from '../../Dashboard/components/CustomBarChart'
import moment from 'moment'
import { ExportExcel } from '../../../utils/myFunctions'
import CustomLineChart from '../../CRM/CustomLineChart'
import call from '../../../service'
import { platformBackendUrl } from '../../../urlConstants'
import { NewTable } from '../../../utils/newTable'
import Filter from '../../InvoiceDiscounting/components/Filter'
import Pagination from '../../InvoiceDiscounting/contract/components/pagination'
const LogsArr = [
  { name: "Contacts", val: 'Create New Task', color: 'text-color1', count: 0 },
  { name: "Lead Created", val: 'Didnt connect', color: 'color3DB16F', count: 0 },
  { name: "Lost", val: 'Call back', color: 'colorFE4141', count: 0 },
  { name: "Supplier Onboarded", val: 'Not Interested', color: 'text-color1', count: 0 },
]
const bardataConfig = [
  { dataKey: "int_count", fill: "#2ECC71", display: 'Interested' },
  { dataKey: "others_count", fill: "#AED8FF", display: 'Others' },
  { dataKey: "not_interested", fill: "#FF7B6D", display: 'Not Interested' },
]

const taskAssignConfig = [
  { dataKey: "total_exporters", fill: "#76EEA9", display: 'Exporters' },
  { dataKey: "total_importers", fill: "#FFB801", display: 'Importers' },
  { dataKey: "total_audiotrs", fill: "#F26191", display: 'Auditors' },
]

const inboundConfig = [
  { dataKey: "value", fill: "#76EEA9", display: "Users" }
]
const label = {
  "int_count": "Interested",
  "others_count": "Others",
  "not_interested": "Not Interested"
}

const taskAssignLabel = {
  "total_exporters": "Exporters",
  "total_importers": "Importers",
  "total_audiotrs": "Auditors"
}

const taskColorMapping = {
  'Hot': 'text-color1',
  'Cold': 'text-color1',
  'Cold': 'text-color1',
  'Onboarded': 'color-2ECC71',
  'Not Reachable': 'text-F1C40F',
  'Not Interested': 'text-color-E74C3C',
  'Lost': 'text-color-value',
  'Call': 'color-2ECC71'
}

let dataArr = [
  { name: "Lead", val: 'Lead Created', color: 'text-custom2' },
  { name: "Hot", val: 'Hot (30 days or less)', color: 'text-custom2' },
  { name: "Warm", val: 'Cold (60 days or more)', color: 'text-custom2' },
  { name: "Cold", val: 'Warm (30-60 days)', color: 'text-custom2' },
  { name: "Not Intrested", val: 'Not Interested', color: 'colorFE4141' },
  { name: 'Lost', val: 'Lead Lost', color: 'text-secondary' }
]


let callingArray = [
  { name: "CRM", val: 'crm_total', color: 'text-color1' },
  { name: "Users", val: 'userMangement_count', color: 'text-color1' },
  { name: "Enquiry", val: 'webinquiry_count', color: 'text-color1' }
]


let followupArr = [
  { name: "Didn't Connect", val: 'Didnt connect', color: 'text-color1' },
  { name: "Call back", val: 'Call back', color: 'text-color1' },
]
const CRMReportsTab = ({ userTokenDetails, dateRange, userIds }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [data, setdata] = useState({})
  const [errors, setErrors] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [filteredSearch, setFilteredSearch] = useState({})
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [overalldata, setoveralldata] = useState([])
  const [graphConfiguration, setGraphConfiguration] = useState({
    taskAssignGraphDuration: "6 Months",
    taskGraphUpdateDuration: "6 Months"
  })
  const [showLoader, setshowLoader] = useState(false)
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const userId = userTokenDetails?.user_id
  const [activeIndex, setActiveIndex] = useState(null);

  const [TasksSelect, setTasksSelect] = useState('all')

  const [taskAssignedGraph, setTaskAssignedGraph] = useState([])
  const [taskUpdateGraph, settaskUpdateGraph] = useState([])
  const [inBoundTasksAssigned, setInboundTaskAssigned] = useState([])
  const [callHistoryPopup, toggleCallHistoryPopup] = useState({ show: false, data: [] })
  const [statsdata, setstatsdata] = useState({})
  const [dataStatus, setdataStatus] = useState(dataArr)
  const [callingdata, setcallingdata] = useState(callingArray)
  const [followupStatus, setfollowupStatus] = useState(followupArr)
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    // let isCacheExist = localStorage.getItem('taskManagerFilterData') != "{}"
    // if (!isCacheExist) {
    let objectAPI = {}
    if (!userPermissionsForSubAdmin?.mainAdmin) {
      objectAPI["onlyShowForUserId"] = userId
    }
    call('POST', 'getOverallCRMTasksFilters', objectAPI).then(res => {
      console.log("getOverallCRMTasksFilters then", res);
      setFilterData(res)
    }).catch(err => { })

    // }
  }, [])
  const getTasks = () => {
    setshowLoader(true)
    let objectAPI = {
      currentPage: page,
      ...filter,
      resultPerPage: filter.resultPerPage,
      included_status: [0, 1, 2, 3, 4],
      dateRangeFilter: dateRange.from && dateRange.to ? [dateRange.from, dateRange.to] : null
    }
    if (!userPermissionsForSubAdmin?.mainAdmin) {
      objectAPI["onlyShowForUserId"] = userId
    }
    if (data.subadmins) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["leadAssignedTo"] = data.subadmins
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "newUserType" ||
                element.accordianId === "leadStatus" || element.accordianId === "applicationStatus") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        } else if (element.type === 'singleDate') {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getCallingHistory', objectAPI)
      .then((result) => {
        setdbData(formatDataForTable(result.message))
        setCount(result.total_records)
        setoveralldata(result.message)
        setshowLoader(false)
      }).catch(e => {
        setshowLoader(false)
      })
  }

  async function handleCallHistoryPopup(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', itemData.TASK_TYPE === 'User Management' ? 'getUserOnboardedHistory' : 'getCRMCallHistory', {
      EXPORTER_CODE: itemData.EXPORTER_CODE
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleCallHistoryPopup({ show: true, data: apiResp })
  }


  const getTasksStats = () => {
    setshowLoader(true)
    let objectAPI = {
      currentPage: page,
      ...filter,
      resultPerPage: filter.resultPerPage,
      included_status: [0, 1, 2, 3, 4],
      dateRangeFilter: dateRange.from && dateRange.to ? [dateRange.from, dateRange.to] : null
    }
    if (onlyShowForUserId) {
      objectAPI["onlyShowForUserId"] = userId
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(i[element["labelName"]])
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        } else if (element.type === 'singleDate') {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (data.subadmins) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["leadAssignedTo"] = data.subadmins
    }
    call('POST', 'getCallingHistroyStats', objectAPI).then(result => {
      console.log('resulttttt', result);
      let total_tasks = 0
      let total_exporters = 0
      let total_followups = 0
      let eventStatusArr = []
      for (let i = 0; i <= dataStatus.length - 1; i++) {
        const element = dataStatus[i]
        const matcheddata = result?.eventsumaary?.find(item => item.label === element.val)
        element["count"] = matcheddata ? matcheddata.total_records : 0
        total_tasks += matcheddata ? matcheddata.total_records : 0
        eventStatusArr.push(element)
      }
      let followupArr = []
      for (let i = 0; i <= followupStatus.length - 1; i++) {
        const element = followupStatus[i]
        const matcheddata = result?.eventsumaary?.find(item => item.label === element.val)
        element["count"] = matcheddata ? matcheddata.total_records : 0
        total_followups += matcheddata ? matcheddata.total_records : 0
        followupArr.push(element)
      }

      let callingArr = []
      for (let i = 0; i <= callingdata.length - 1; i++) {
        const element = callingdata[i]
        element["count"] = result?.[element.val] || 0
        total_exporters += result?.[element.val] || 0
        callingArr.push(element)
      }
      setdataStatus(eventStatusArr)
      setfollowupStatus(followupArr)
      setcallingdata(callingArr)
      setshowLoader(false)
      setstatsdata({
        total_tasks,
        total_followups,
        total_exporters
      })

    }).catch(e => {
      setshowLoader(false)

    })

  }

  const getXAxisDateFormat = (data, value) => {
    let countForMonths = moment(dateRange.to).diff(dateRange.from, 'month')
    let dateFormat = ''
    if (countForMonths > 3) {
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths <= 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }
  const handleAccordianClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      try {
        let status = item.EVENT_STATUS ? item.EVENT_STATUS?.split("(")[0] || 'NA' : 'NA'
        row[0] = <div class="dropdown w-100" >
          <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {item.TASK_ASSIGNED_TO[0].contact_person || '-'}
          </label>
        </div>
        row[1] = item.EXPORTER_NAME
        row[2] = item.EXPORTER_CITY
        row[3] = item.LastEventTime ? moment().diff(item.LastEventTime, 'days') + " days" : '-'
        row[4] = item.TASK_TYPE
        row[5] = <span className={`${taskColorMapping[status.trim()]} font-wt-600`}>{status}</span>
        row[6] = <span className='cursor' onClick={() => handleCallHistoryPopup(item)}>
          <span className='font-wt-600'>
            {item.LastEventTime ? moment(item.LastEventTime).format('DD/MM/YYYY') + ": " : ''}
          </span>
          <span className='font-wt-600'>
            {item.LastEventType ? item.LastEventType + "-" : ''}
          </span>
          <span className='font-wt-500' dangerouslySetInnerHTML={{ __html: item.LastNote ? item.LastNote.length > 60 ? item.LastNote.slice(0, 60) + "......." : item.LastNote : item.LAST_NOTE ? item.LAST_NOTE.length > 60 ? item.LAST_NOTE.slice(0, 60) + "......." : item.LAST_NOTE : '' }}>
          </span>
        </span>
        tableData.push(row)
        row = []
      } catch (e) {
        console.log('error in formatdatafortable', e);
      }

    })
    return tableData
  }
  useEffect(() => {
    getTasks()
    getTasksStats()
  }, [page, refresh, filterData, dateRange, userIds])
  const getTaskAssignedGraph = () => {
    setshowLoader(true)
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds,
      onlyShowForUserId
    }
    call('POST', 'getTaskAssignedGraph', reqObj).then(result => {
      setTaskAssignedGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  const getTaskUpdateGraph = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds,
      onlyShowForUserId
    }
    setshowLoader(true)
    call('POST', 'getTaskUpdateGraph', reqObj).then(result => {
      settaskUpdateGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getTaskUpdateGraph()
    getInboundTasks()
    getTaskAssignedGraph()
  }, [dateRange, userIds])

  const getInboundTasks = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds,
      onlyShowForUserId
    }
    setshowLoader(true)
    call('POST', 'getInboundTasks', reqObj).then(result => {
      setInboundTaskAssigned(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className={`modal fade ${callHistoryPopup.show && "show"}`} style={callHistoryPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Call History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleCallHistoryPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {callHistoryPopup.data.length ? callHistoryPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer2">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-4 mt-2'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>
                        {item.CREATED_AT ? moment(item.CREATED_AT).format('Do MMM, YYYY - hh:mm A') : '-'}
                        <span><img src='assets/images/arrow.png' className='cursor' onClick={() => handleAccordianClick(index)} /></span>
                      </p>
                      {activeIndex === index &&
                        <div>
                          <p className='mb-0 font-size-14'>{item.LOG_TYPE}</p>
                          <p className='mb-0 font-size-14 text-break' dangerouslySetInnerHTML={{ __html: item.REMARK }}>
                          </p>
                          <p>
                            {item.CONTACT_PERSON && <span className='mb-0 font-size-14 font-wt-600'>{item.CONTACT_PERSON + " - "}</span>}
                            {item.CONTACT_NUMBER && <span className='mb-0 font-size-14 font-wt-600'>{item.CONTACT_NUMBER}</span>}
                          </p>
                          <p>
                            {item.EVENT_TIME &&
                              <span className='mb-0 font-size-14 '>Next followup date:
                                <span className='mb-0 font-size-14 '>
                                  {moment(item.EVENT_TIME).format('DD/MM/YYYY')}
                                </span>
                              </span>
                            }
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      <div className='row pl-4 mt-4 gap-4'>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Outbound Task Assigned</label>

                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomLineChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskAssignGraphDuration, value)} YFormatter={(value) => value} bardataConfig={taskAssignConfig} formatterFunction={(value, name) => [value, taskAssignLabel[name]]} data={taskAssignedGraph} xDataKey={"xLabel"} tab={'Count'} type={"Sum"} />
          </div>

        </div>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Inbound Task Assigned</label>


                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={inboundConfig} formatterFunction={(value, name) => [value, name]} data={inBoundTasksAssigned} xDataKey={"label"} tab={'Count'} type={"Sum"} />
          </div>

        </div>
      </div>
      <div className='row pl-4 mt-4 gap-4'>

        <div className='card h-50 w-100 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Task Update</label>

                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={taskUpdateGraph} xDataKey={"label"} dataKey={"value"} />
          </div>

        </div>
        {/* <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>New User Status</label>


                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={[
              {
                "label": "Send Agreement",
                "value": 4
              },
              {
                "label": "Sign Agreement",
                "value": 1
              },
              {
                "label": "Add buyer",
                "value": 1
              },
              {
                "label": "apply for limit",
                "value": 3
              },
              {
                "label": "apply for finance",
                "value": 1
              }
            ]} xDataKey={"label"} dataKey={"value"} />
          </div>

        </div> */}
      </div>
      {/* <div className='row pl-4 mt-4 gap-4'>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Outbound Task Assigned</label>
                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomLineChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskAssignGraphDuration, value)} YFormatter={(value) => value} bardataConfig={taskAssignConfig} formatterFunction={(value, name) => [value, taskAssignLabel[name]]} data={[]} xDataKey={"xLabel"} />
          </div>

        </div>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setTasksSelect('all')}>Inbound Task Assigned</label>
                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            <CustomAreaChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.taskGraphUpdateDuration, value)} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={leadsGraph} xDataKey={"xLabel"} dataKey={"lead_count"} />
          </div>

        </div>
      </div> */}

      <div className='pl-4'>
        <div className='row mt-3 pl-0'>
          <div className='w-45  pl-0'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`Total Tasks - `}
                <label className='font-size-14 text-color-value font-wt-600 text-color1'>{statsdata.total_tasks || 0}</label></label>
              <div className='row px-0 w-100'>
                {dataStatus.map(item => {
                  return <div className='w-16 cursor'>
                    <label className={`value font-wt-600  w-100 cursor ${item.color}`}>
                      {item.count || 0}
                    </label>
                    <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{item.name}</label>
                  </div>
                })}
              </div>
            </div>
          </div>

          <div className='w-30'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`Total Connected - `}
                <label className='font-size-14 text-color-value font-wt-600 text-color1'>{statsdata.total_exporters || 0}</label></label>
              <div className='row px-0 w-100'>
                {callingdata.map((item, index) => {
                  return <div className='w-33 cursor'>
                    <label className={`value font-wt-600  w-100 cursor ${item.color}`}>
                      {item.count || 0}
                    </label>
                    <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{item.name}</label>
                  </div>
                })}

              </div>
            </div>
          </div>

          <div className='w-25 '>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`Total Followups - `}
                <label className='font-size-14 text-color-value font-wt-600 text-color1'>{statsdata.total_followups || 0}</label></label>
              <div className='row px-0 w-100'>
                {followupStatus.map(item => {
                  return <div className='w-50 cursor'>
                    <label className={`value font-wt-600  w-100 cursor ${item.color}`}>
                      {item.count || 0}
                    </label>
                    <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{item.name}</label>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pl-2'>
        <div className='card dashboard-card shadow-sm '>
          <div className='col-md-12'>
            <div className='filter-div ml-4'>
              <Filter
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />

            </div>
            <div>
              <NewTable
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData}
                setFilterData={setFilterData}
                columns={[

                  {
                    name: "Admin", filter: true, filterDataKey: "Lead Assigned To", sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortBydate: false, sortFinName: false, sortAmount: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortBydate: false, sortFinName: false, sortAmount: false }); setRefresh(refresh + 1) } }]
                  },
                  { name: "Exporter Name" },
                  {
                    name: "Exporter City", filter: true, filterDataKey: "Application Type"
                  },
                  { name: "Days" },
                  { name: "Source" },
                  { name: "Status" },
                  { name: "Last note" }
                ]}
                data={dbData}
                options={[]}
                disableAction={true}
              />
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 10} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CRMReportsTab