import React, { useState } from "react";
import { NewInput } from "../../../../utils/newInput";
import toastDisplay from "../../../../utils/toastNotification";

const Pagination = ({ page, totalCount, onPageChange, perPage, refresh, setRefresh }) => {
  const [data, setdata] = useState({ page_number: page })
  const [errors, setErrors] = useState({ page_number: false })
  let startIndex = totalCount == 0 ? 0 : ((((page - 1) * (perPage || 10))) + 1)
  let lastIndex = startIndex + (perPage ? (perPage - 1) : 9)

  function handlePageChange(type) {
    if (type === "prev") {
      onPageChange(page - 1)
    }
    if (type === "next") {
      onPageChange(page + 1)
    }
    if (setRefresh) {
      setRefresh(refresh + 1)
    }
  }


  return (
    <>
      <div className="my-3">
        <div className="d-flex justify-content-end align-items-center">
          <div className="d-flex gap-3 align-items-center justify-content-end">
            <label className="font-size-14 mb-0 font-wt-500">Go To : </label>
            <div className="w-20 mx-2">
              <NewInput type={"number"} onChange={(e) => {
                e.preventDefault()
                if (!e.target.value) {
                  setdata({ page_number: parseInt(e.target.value) })
                  setErrors({ page_number: "" })
                } else {
                  if (parseInt(e.target.value) > 0) {
                    if (parseInt(e.target.value) <= (parseInt(totalCount / perPage) + 1)) {
                      setdata({ page_number: parseInt(e.target.value) })
                      //setErrors({ page_number: "" })
                      onPageChange(parseInt(e.target.value))

                    } else {
                      setdata({ page_number: parseInt(e.target.value) })
                      toastDisplay("Exceeded the page limit", "info")
                      //setErrors({ page_number: 'Exceeded the page limit' })
                    }
                  }
                }
              }} onKeyDown={(event) => {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                  event.preventDefault();
                }
              }} name={"page_number"} value={data.page_number} label="" customclass={"mb-0 h-100"} removeMb={true} error={errors.page_number} />
            </div>
          </div>


          {page > 1 ? (
            <img onClick={() => handlePageChange("prev")} src={"/assets/images/arrow-left.png"} alt="" className="cursor" />
          ) : null}
          <span className="PaginationFont">{page}/{parseInt(totalCount / perPage) + 1}</span>
          {totalCount > lastIndex ? (
            <img onClick={() => handlePageChange("next")} src={"/assets/images/arrow-right.png"} className="cursor" alt="" />
          ) : null}
        </div>
      </div>
    </>
  );
}
export default Pagination