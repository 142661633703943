export default function validate(values) {
  let errors = {};

  if (!values.qLoaded) {
    errors.qLoaded = 'Quantity is Mandatory';
  }

  if (!values.loadRate) {
    errors.loadRate = 'Loading Rate is Mandatory';
  }

  if (!values.arrivalTime) {
    errors.arrivalTime = 'Arrival Time is Mandatory';
  }

  if (!values.nor) {
    errors.nor = 'NOR is Mandatory';
  }

  if (!values.laytimeUnit) {
    errors.laytimeUnit = 'Laytime Unit is Mandatory';
  }

  if (!values.laytimeCommenced) {
    errors.laytimeCommenced = 'Laytime Commenced is Mandatory';
  }

  if (!values.loadCommenced) {
    errors.loadCommenced = 'Loading Commenced is Mandatory';
  }

  if (!values.loadCompleted) {
    errors.loadCompleted = 'Loading Complete is Mandatory';
  }

  if (!values.demurageRate) {
    errors.demurageRate = 'Demurage Rate is Mandatory';
  }

  if (!values.dispatchRate) {
    errors.dispatchRate = 'Dispatch Rate is Mandatory';
  }

  // if (!values.laytimeAllowed) {
  //   errors.laytimeAllowed = 'Laytime Allowed is Mandatory';
  // }

  console.log(errors);
  return errors;
}
