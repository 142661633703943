import React, { useEffect, useState } from "react"
import DropdownAndInput from "../itemView/dropdownWithInput";
import ReminderTab from "./reminderTab";
import call from "../../../../service";
import NewTablev2 from "../../../../utils/newTablev2";
import AddReminder from "./addreminder";
import PopUPEmailText from "./popUpEmailText";
import * as XLSX from 'xlsx';

const PaymentView = ({ allData, userId }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showReminderTab, setShowReminderTab] = useState(false);
  const [showNoData, setShowNoData] = useState("");
  const [pendingData, setPendingData] = useState([])
  const [showAddreminderTab, setShowAddreminderTab] = useState(false);
  const [editReminder, setEditReminder] = useState(null)
  const [selectedItem, setSelectedItem] = useState([])
  const [clickedBtn, setClickedBtn] = useState('')
  useEffect(() => {
    setShowLoader(true)
    call('POST', 'sendsalespendings', { userId: userId })
      .then((result) => {
        console.log(result)
        setPendingData(result)
      })
      .catch((e) => {
        console.log('error in getcustomeremail', e);
      })
      .finally(() => {
        setShowNoData("No Pending Payments")
        setShowLoader(false);
      });

  }, [])

  const handleSendMail = (item) => {
    call('POST', 'sendPaymentEmail', { userId: userId, bills: [item] })
      .then(() => {
        console.log("Email sent successfully");
      })
      .catch((e) => {
        console.log('Error in sending email', e);
      });
    console.log("sent mail")
    setShowAddreminderTab(false);

  }
  const handleMailClick = (item) => {
    setShowAddreminderTab(true);
    setEditReminder({
      rule: `Pending Payment for Invoice: ${item.invoiceNo}`,
      customerSelected: [item.customerName]
    })
    setSelectedItem(item)
  };

  const sendWhatsapp = (item) => {
    setShowAddreminderTab(true)
    setClickedBtn("whatsapp")
    setSelectedItem(item)

  }
  const tableColumns = [
    { subColumns: 'Invoice No', accessor: 'invoiceNo' },
    { subColumns: 'Customer Name', accessor: 'customerName' },
    { subColumns: 'Total Sale Amount ($)', accessor: 'totalSaleAmount' },
    { subColumns: 'Outstanding ($)', accessor: 'outstanding' },
    { subColumns: 'Pending Days', accessor: 'pendingDays' },
    { subColumns: 'Action', accessor: 'action' }
  ];
  const filteredData = pendingData.filter(item =>
    item.invoiceNo?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
    item.customerName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
  const tableRows = filteredData.map((item, index) => (
    <tr key={index}>
      <td>{item.invoiceNo}</td>
      <td>{item.customerName}</td>
      <td>{item.TotalAmount}</td>
      <td>{item.pendingAmount}</td>
      <td>{item.pendingDays}</td>
      <td>
        {/* <button type="button" className="btn btn-info" onClick={() => handleMailClick(item)}>Send Mail</button> */}
        <button onClick={() => { handleMailClick(item); setClickedBtn("email") }} className="bg-white rounded mr-1 p-1" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src='assets/images/mail.png' style={{ width: "15px", height: "15px" }} /></button>
        <button onClick={() => { handleMailClick(item); setClickedBtn("mobile") }} className="bg-white rounded mr-1 p-1" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src='assets/images/messages.png' style={{ width: "15px", height: "15px" }} /></button>
        <button onClick={() => sendWhatsapp(item)} className="bg-white rounded mr-1 p-1" style={{ border: "0.5px solid grey", width: "30px", height: "30px" }}><img src='assets/images/whatsapp.png' style={{ width: "15px", height: "15px" }} /></button>


      </td>
    </tr>
  ));

  const btnsToShow = ["Receivables", "Payable", "0-50", "51-90", ">90", "Other"]
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleDownload = () => {
    // Map pendingData to align with tableColumns
    const mappedData = pendingData.map(item => ({
      'Invoice No': item.invoiceNo,
      'Customer Name': item.customerName,
      'Total Sale Amount ($)': item.TotalAmount,
      'Outstanding ($)': item.pendingAmount,
      'Pending Days': item.pendingDays
    }));

    // Create a worksheet from the mapped data
    const ws = XLSX.utils.json_to_sheet(mappedData, {
      header: tableColumns.map(col => col.subColumns)
    });

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pending Data');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'PendingData.xlsx');
  };

  const handleGoBack = () => {
    setShowReminderTab(false)
  }
  return (
    <div className='shadow rounded p-2'>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {!showReminderTab ?
        <div>
          <div className='d-flex justify-content-between w-100 mb-2'>
            <p>Payment Report</p>
            <div className='d-flex justify-content-between align-items-center' style={{ height: "40px" }}>
              <button className='btn btn-primary me-2 ' onClick={() => { setShowReminderTab(true) }}>Auto Set Reminder</button>
              <input
                type="text"
                style={{ width: "350px" }}
                placeholder="Search by Customer Name or Invoice Numbers"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control"
              />

              {/* <button className='btn btn-primary ms-2' onClick={() => { setShowFilterPopup(true) }}>Filter</button> */}
              <button onClick={handleDownload} className="bg-white rounded m-3" style={{ border: "0.5px solid grey", width: "40px", height: "40px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>
            </div>
          </div>
          <div>
            {
              pendingData.length ? (
                <NewTablev2 showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }} columns={tableColumns}>
                  {tableRows}
                </NewTablev2>
              ) : <div className="d-flex justify-content-center align-items-center">
                <h6 className="m-5">{showNoData}</h6>
              </div>
            }
            {
              showAddreminderTab &&
              <PopUPEmailText userId={userId} setShowAddReminderTab={setShowAddreminderTab} handleAddReminder={handleSendMail} item={selectedItem} clickedBtn={clickedBtn} />

            }
          </div>
        </div> : <ReminderTab userId={userId} handleGoBack={handleGoBack} allData={allData} />}

    </div>
  )

}

export default PaymentView