import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import call from "../../service";
import { FileInput } from "../../utils/FileInput";
import { ExportExcel, printDiv } from "../../utils/myFunctions";
import NewTablev2 from "../../utils/newTablev2";
import DonutChart from "../Dashboard/components/DonutChart";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import { BuyerShipmentsColumn, Shipmentcolumns } from "../Reports/TableJson";
import TableFilter from "../wallet/components/tableFilter";
import { useHistory } from "react-router";


const reviewForm = [
  { "name": "Contact Person Name", val: "contact_person", unit: "name_title" },
  { "name": "Company Name", val: "company_name" },
  { "name": "Country", val: "country_code" },
  { "name": "GST No", val: "gst_vat_no" },
  { "name": "CIN No", val: "cin_no" },
  { "name": "IEC No", val: "iec_no" },
  { "name": "PAN No", val: "pan_no" },
  { "name": "URN No", val: "urn" },
  { "name": "Email Id", val: "email_id" },
  { "name": "Contact Number:", val: "contact_number", unit: "phone_code" },
  { "name": "Address", val: "user_address" },
  { "name": "Website", val: "website" },
  { "name": "Previous year annual sales", val: "prevSale", unit: "currency" },
  { "name": "Expected current year’s annual sale", val: "annualSale", unit: "currency" },
  { "name": "Terms of Payment", val: "termsOfPayment" },
  { "name": "Product Details", val: "productDetail" },
  { "name": "HSN Code", val: "exporterHsnCode" },
  { "name": "Date of Commencement", val: "dateOfCommencement" },
  { "name": "Date of Incorporation", val: "dateOfIncorporation" },
  { "name": "District Industries Center", val: "districtIndustriesCentre" },
  { "name": "Exporter Type", val: "type" },
  { "name": "Organization Type", val: "organization_type" },
]

const currentYear = new Date().getFullYear();

const firstDay = new Date(currentYear, 0, 1).toISOString();

const lastDay = new Date().toISOString();

const ViewSupplierDetails = ({ userTokenDetails, navToggleState, data, onEdit, onDelete, hideTopBuyersView, goback }) => {
  let history = useHistory()

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [top5suppliers, setTop5Suppliers] = useState([])
  const [chartdata, setChartdata] = useState([])
  console.log('dataaaaaaaa', data)
  const getShipmentsdata = () => {
    setShowLoader(true)
    let reqObj = {
      ttvId: data.ttvId,
      search: filter.search,
      resultPerPage: filter.resultPerPage / 1,
      currentPage: page / 1,
      EXPORTER_CODES: userTokenDetails?.ttvExporterCode?.split(',') || []
    }
    call('POST', 'getShipmentsByBuyer', reqObj).then(result => {
      setdbData(result.data)
      setCount(result.count_data)
      setShowLoader(false)
    }).catch(e => {
      console.log('error in getShipmentsByBuyer', e)
      setShowLoader(false)
    })
  }
  const getTop5Suppliers = () => {
    setShowLoader(true)
    let reqObj = {
      ttvId: data.ttvId,
      userId: userTokenDetails?.user_id
    }
    call('POST', 'getTop5Suppliers', reqObj).then(result => {
      setTop5Suppliers(result)
      setShowLoader(false)
    }).catch(e => {
      console.log('error in getTop5Suppliers', e)
      setShowLoader(false)
    })
  }
  const getActualSalesCurrentYear = () => {
    setShowLoader(true)
    let reqObj = {
      ttvId: data.ttvId,
    }
    call('POST', 'getActualSalesCurrentYear', reqObj).then(result => {
      setShowLoader(false)
      console.log('result in getActualSalesCurrentYear', result, data.currentAnnualSale)
      if (result) {
        let actualper = (result / data.currentAnnualSale) * 100
        let expectedPer = 100 - actualper
        const chartData = [
          { label: "Actual", value: actualper?.toFixed(2)?.toString() },
          { label: "Expected", value: expectedPer?.toFixed(2)?.toString() }
        ]
        console.log('Chartdata', chartData)
        setChartdata(chartData)
      } else {
        const chartData = [
          { label: "Actual", value: '0.00' },
          { label: "Expected", value: '100.00' }
        ]
        setChartdata(chartData)
      }

    }).catch(e => {
      console.log('error in getTop5Suppliers', e)
      setShowLoader(false)
    })
  }
  useEffect(() => {
    getShipmentsdata()
  }, [refresh, page])
  useEffect(() => {
    getActualSalesCurrentYear()
  }, [])
  console.log('chartdataaaaa', chartdata)
  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-4">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <img src="assets/images/ArrowBackLeft.png" height={20} width={20} className="cursor mx-2" onClick={() => {
              if (goback) {
                goback()
              } else {
                // window.location.reload()
                history.go(-1)
              }
            }} />
            <div className="mx-2">
              <p className="font-wt-600 font-size-16 p-0 m-0">{data.company_name}</p>
              <label className="font-wt-500 font-size-14 text-color1 p-0 m-0">{data.website || 'NA'}</label>
            </div>
          </div>
          <div>
            {onEdit ? (
              <div className="d-flex flex-row">
                <img src="assets/images/bytesize_edit.png" height={20} width={20} onClick={() => onEdit(data.index)} className="cursor mx-2" />
                <img src="assets/images/deleteIcon.png" height={20} width={20} onClick={() => onDelete(data.index)} className="cursor mx-2" />
              </div>
            ) : null}
          </div>
          <div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-md-12">
          <div className="card border-0 chatlist p-4">
            <div className="row">
              {reviewForm.map((item) => {
                if (data[item.val]) {
                  return (
                    <div className="col-md-6 pe-5">
                      <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
        {/* <div className="col-md-4 ">
          <div className="card border-0 chatlist p-4 h-100">
            <label className="font-size-14 font-wt-600 text-center">Shipment Statistics</label>
            <label className="font-size-14 font-wt-500 text-center pt-1 m-0" >{`Duration: ${moment(firstDay).format('Do MMM YYYY')} - ${moment(lastDay).format('Do MMM YYYY')}`}</label>
            {chartdata.length &&
              <DonutChart idContainer="container-1" data={chartdata} />
            }
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-4 text-center">
                <label className="font-size-14 font-wt-600">{`$${data.previousAnnualSale}`}</label>
                <label className="font-size-12 font-wt-400">Previous Year sale</label>
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                <img className="text-center" height={30} width={30} src={"/assets/images/shipments_stats_icon.svg"} />
              </div>
              <div className="col-md-4 text-center">
                <label className="font-size-14 font-wt-600">{`$${data.currentAnnualSale}`}</label>
                <label className="font-size-12 font-wt-400">Expected Current Year sale</label>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="mt-4">
        <TableFilter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} onExcelExport={() => { exportToexcel(dbData, "shipment_data_" + data.buyerName) }} onPDFExport={() => { printDiv("shipmentTablediv", "shipment_data_" + data.buyerName, []) }} />
        <div className="mb-3" id="shipmentTablediv">
          <NewTablev2 columns={BuyerShipmentsColumn} tableFixed dataCount={dbData.length} >
            {dbData.map((key, index) => {
              return (
                <tr>
                  <td>{moment(key.DATE).format('DD-MM-YYYY')}</td>
                  <td title={key.EXPORTER_NAME} className="cursor"> {key.EXPORTER_NAME && key.EXPORTER_NAME.length > 60 ? key.EXPORTER_NAME.slice(0, 60) + '...' : key.EXPORTER_NAME} </td>
                  <td>{key.TOTAL_GROSS_WEIGHT + " Kg"}</td>
                  <td>{key.INDIAN_PORT}</td>
                  <td>{key.DESTINATION_COUNTRY}</td>
                  <td className="d-flex gap-4">
                    <div>
                      {key.PRODUCT_DESCRIPTION && key.PRODUCT_DESCRIPTION.length > 60 ? key.PRODUCT_DESCRIPTION.slice(0, 60) + '...' : key.PRODUCT_DESCRIPTION}
                    </div>
                  </td>
                </tr>
              );
            })}
            {dbData.length === 0 &&
              <tr className="w-100 py-4 text-center">
                <td className="font-size-16 font-wt-600"></td>
                <td className="font-size-16 font-wt-600"></td>
                <td className="font-size-16 font-wt-600 py-5">No Data Found</td>
                <td className="font-size-16 font-wt-600"></td>
                <td className="font-size-16 font-wt-600"></td>
                <td className="font-size-16 font-wt-600"></td>
              </tr>
            }
          </NewTablev2>
        </div>
        <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
      </div> */}
      {/* {!hideTopBuyersView ? (
        <div className="mt-4">
          <div className="card border-0 chatlist p-4">
            <div className="col-md-12">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row">
                  <label className="font-size-15">Top 5 suppliers & shipments</label>
                  <img src="/assets/images/arrowdown.png" height={25} width={25} />
                </div>
                <button className={`new-btn p-2 text-white cursor`} onClick={getTop5Suppliers}>
                  Get it for<img src="/assets/images/getfinancedetails-icon.png" alt="" height="20" class="ms-1" /> 30 Coins
                </button>
              </div>
              {
                <div className="col-md-12">
                  <div className="row">
                    {top5suppliers.map((item, index) => {
                      return <div className="col-md-3 mt-4">
                        <div className="card border-0 chatlist p-4 h-100" key={index}>
                          <label className="font-size-14 font-wt-400 text-center py-2">{item.EXPORTER_NAME}</label>
                          <label className="font-size-16 font-wt-600 text-center pt-2 m-0">{item.total_shipments}</label>
                          <label className="font-size-16 font-wt-400 text-center pb-2">Shipments done</label>
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      ) : null} */}
    </div >
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewSupplierDetails)