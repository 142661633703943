import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import CommercialInvoice from "./CommercialInvoice";
import ProformaInvoice from "./ProformaInvoice";
import call from "../../service";
import moment from "moment";
import { Action } from "../myCounterPartComp/action";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import { getDocDetails, printPDF, printPDFFromBase64 } from "../../utils/myFunctions";
import toastDisplay from "../../utils/toastNotification";
import { NewInput, NewTextArea } from "../../utils/newInput";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";

export let invoiceTemplateTypes = [
  // { name: 'E-Invoice', src: 'assets/images/commercialInv.png' },
  { name: 'E-Invoice', src: '/assets/images/commercialInv.PNG' },
  // { name: 'Proforma Invoice', src: 'assets/images/proformaInv.png' }
]

const Einvoice = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({ show: false, data: { type: '' } })
  const [dbData, setDbData] = useState([])
  const [page, setPage] = useState(1)
  const [action, setAction] = useState({ show: false, index: null })
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({})
  const [recentTemplates, setRecentTemplates] = useState([])
  const [data, setData] = useState({})
  const [error, setErrors] = useState({})

  useEffect(() => {
    call("POST", 'getRecentlyUsedEdocs', { userId }).then(res => {
      setRecentTemplates(res)
    })
    setFilterData({
      "Invoice Type": {
        "accordianId": 'invoiceType',
        type: "checkbox",
        data: invoiceTemplateTypes,
        labelName: "name"
      }
    })
  }, [])


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("edocsEinvoice");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "invoiceTypee") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
      }
    }
    call('POST', 'getEdocs', objectAPI).then(async (result) => {
      console.log('running getEdocs api-->', result);
      setDbData(result.data);
      setCount(result.count);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getEdocs', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])

  async function handleChange(e) {
    e.persist()
    setData({ ...data, [e.target.name]: e.target.value })
    setErrors({ ...error, [e.target.name]: '' })
  }


  const handleClick = () => {

    console.log(booladd, "this in handleClick before toggleAddBuyerForm");
    if (booladd || userPermissions == null) {
      toggleTemplateSelectionForm({ show: true, data: { type: "" } })
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  };
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />

      {data.showCancellationPopup &&
        <FinanceInvoiceModal modalSize={"md"} limitinvoice={data.showCancellationPopup} closeSuccess={() => {
          setData({
            ...data,
            cancellationReason: "",
            showCancellationPopup: false
          })
        }}>
          <div className="">
            <label className="font-size-16 font-wt-500 w-100 text-center">Cancel IRN</label>
            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewTextArea rows={3} isAstrix={true} label={"Cancellation Reason"}
                  name={"cancellationReason"} value={data.cancellationReason} error={error.cancellationReason}
                  onChange={handleChange} />
              </div>
            </div>
            <div className="d-flex justify-content-center px-3 ">
              <button className={`new-btn w-40 py-2 px-2 text-white cursor`} onClick={() => {
                if (!data.cancellationReason) {
                  return setErrors({ ...error, cancellationReason: "Mandatory field" })
                }
                setshowLoader(true)
                call('POST', 'cancelIRN', {
                  IRN: data.selectedInvoice.webtelApi.response[0].Irn, GSTIN: data.selectedInvoice.docData.data.exporterGstNo,
                  cancellationReason: data.cancellationReason, EInvUserName: data.selectedInvoice.webtelApi.request.EInvUserName,
                  EInvPassword: data.selectedInvoice.webtelApi.request.EInvPassword, invAppId: data.selectedInvoice.id
                }).then(res => {
                  setshowLoader(false)
                  toastDisplay('IRN Cancelled Successfully', "success")
                  setData({
                    ...data,
                    cancellationReason: "",
                    showCancellationPopup: false
                  })
                }).catch(err => {
                  setshowLoader(false)
                  toastDisplay(err, "error")
                })
              }}>Submit</button>
            </div>
          </div>
        </FinanceInvoiceModal>
      }
      {data.printByIRN &&
        <FinanceInvoiceModal modalSize={"md"} limitinvoice={data.printByIRN} closeSuccess={() => {
          setData({
            ...data,
            printByIRN: false
          })
        }}>
          <div className="">
            <label className="font-size-16 font-wt-500 w-100 text-center">Print Invoice By IRN Number</label>
            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput label={"IRN No"}
                  name={"irnNo"} value={data.irnNo} error={error.irnNo}
                  onChange={handleChange} />
              </div>
              <div className="col-md-12 px-0">
                <NewInput label={"NIC User Name"}
                  name={"nicUserName"} value={data.nicUserName} error={error.nicUserName}
                  onChange={handleChange} />
              </div>
              <div className="col-md-12 px-0">
                <NewInput label={"NIC Password"} type={"password"}
                  name={"nicPassword"} value={data.nicPassword} error={error.nicPassword}
                  onChange={handleChange} />
              </div>
            </div>
            <div className="d-flex justify-content-center px-3 ">
              <button className={`new-btn w-40 py-2 px-2 text-white cursor`} onClick={() => {
                if (!data.irnNo || !data.nicUserName || !data.nicPassword) {
                  return toastDisplay("Fill all details to continue", "error")
                }
                setshowLoader(true)
                call('POST', 'printIRN', {
                  Irn: data.irnNo,
                  GSTIN: data.nicUserName,
                  EInvUserName: data.nicUserName,
                  EInvPassword: data.nicPassword
                }).then(res => {
                  setshowLoader(false)
                  printPDFFromBase64(res)
                  setData({
                    ...data,
                    printByIRN: false
                  })
                }).catch(err => {
                  setshowLoader(false)
                  toastDisplay(err, "error")
                })
              }}>Submit</button>
            </div>
          </div>
        </FinanceInvoiceModal>
      }
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="edocsEinvoice" userTokenDetails={userTokenDetails} />
          <main role="main" className={" ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={templateSelectionForm.show ? `E Docs > E-Invoice > Create Invoice (${templateSelectionForm.data?.type})` : `E Docs > E-Invoice`}
              userTokenDetails={userTokenDetails} />
            {templateSelectionForm.data.type ? <>
              {templateSelectionForm.data.type === 'E-Invoice' ? <>
                <a
                  onClick={() => {
                    toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                    setSelectedInvoiceData({})
                  }}
                  className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <CommercialInvoice selectedInvoiceData={selectedInvoiceData} userTokenDetails={userTokenDetails} /> </> : templateSelectionForm.data.type === 'Proforma Invoice' ?
                <>
                  <a
                    onClick={() => {
                      toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                      setSelectedInvoiceData({})
                    }}
                    className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                  <ProformaInvoice selectedInvoiceData={selectedInvoiceData} userTokenDetails={userTokenDetails} /> </> :
                null}
            </> : <>
              {templateSelectionForm.show ?
                <>
                  <a
                    onClick={() => {
                      toggleTemplateSelectionForm({ show: false, data: { type: "" } })
                      setSelectedInvoiceData({})
                    }}
                    className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                  <div className='card mt-1 px-2' >
                    <label className='font-size-16 font-wt-600 w-100 text-center pt-4' >Select Template</label>
                    <div className='d-flex gap-4 p-4 flex-wrap' >
                      {invoiceTemplateTypes.map((i, j) => {
                        return (
                          <div
                            onClick={() => {
                              toggleTemplateSelectionForm({ show: true, data: { type: i.name } })
                            }}
                            className='w-20 cursor' >
                            <img className='contractTemplateImg2' style={{}} src={i.src} />
                            <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                          </div>
                        )
                      })}
                    </div>
                    {recentTemplates?.length ? <>
                      <hr />
                      <label className='font-size-16 font-wt-600 w-100 pt-4 px-4 m-0' >Recently Used Templates</label>
                      <div className='d-flex gap-4 p-4 flex-wrap' >
                        {invoiceTemplateTypes.map((i, j) => {
                          let isRecentlyUsed = recentTemplates.find(m => {
                            if (m.template === i.name) {
                              return true
                            }
                          })?.["template"]
                          if (isRecentlyUsed) {
                            return (
                              <div
                                onClick={() => {
                                  toggleTemplateSelectionForm({ show: true, data: { type: i.name } })
                                }}
                                className='w-20 cursor' >
                                <img className='contractTemplateImg2' style={{}} src={i.src} />
                                <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                              </div>
                            )
                          }
                        })}
                      </div></> : null}
                  </div>
                </> : <>
                  <div className='filter-div position-relative'>
                    <Filter isAdditionalButton
                      filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                      showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}>
                      <div className="d-flex gap-4">
                        <button className={`new-btn  py-2 px-2 text-white cursor`}
                          // onClick={() => {
                          //   toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                          // }}
                          onClick={handleClick}
                        >Create New</button>

                        <button className={`new-btn  py-2 px-2 text-white cursor`}
                          onClick={() => {
                            setData({ ...data, printByIRN: true })
                          }}
                        >Print By IRN</button>
                      </div>
                    </Filter>
                  </div>
                  <div >
                    <NewTablev2
                      columns={[
                        { subColumns: "Date", subColumnStyle: { width: '10%' } },
                        { subColumns: "IRN No", subColumnStyle: { width: '15%' } },
                        { subColumns: "Invoice No", subColumnStyle: { width: '10%' } },
                        { subColumns: "Invoice Type", subColumnStyle: { width: '8%' } },
                        { subColumns: "Total Amount", subColumnStyle: { width: '10%' } },
                        { subColumns: "Origin Address", subColumnStyle: { width: '13%' } },
                        { subColumns: "Destination Address", subColumnStyle: { width: '13%' } },
                        { subColumns: "Status", subColumnStyle: { width: '10%' } },
                        { subColumns: "Action", subColumnStyle: { width: '5%' } }
                      ]}>
                      {dbData.map((i, j) => {
                        return (
                          <tr>
                            <td><label className="font-size-13 font-wt-400 text-break" >{moment(i.createdAt).format("YYYY-MM-DD")}</label></td>
                            <td><label className="font-size-13 font-wt-400 text-break" >{i?.webtelApi?.response?.[0]?.Irn || "-"}</label></td>
                            <td><label className="font-size-13 font-wt-400 text-break" >{i?.docData?.data?.invoiceNo || "-"}</label></td>
                            <td><label className="font-size-13 font-wt-400 text-break" >{i.template}</label></td>
                            <td><label className="font-size-13 font-wt-400 text-break" >INR {i.docData?.data?.finalInvoiceVal}</label></td>
                            <td><label className="font-size-13 font-wt-400 text-break" >{i.docData?.data?.originAddress1 || "-"}</label></td>
                            <td><label className="font-size-13 font-wt-400 text-break" >{i.docData?.data?.consigneeAddress1 || "-"}</label></td>
                            <td>
                              <div className=''>
                                {!i.IRNCancelled ?
                                  <button type="button"
                                    class={`approved text-white border-0 `}>
                                    {"Active"}
                                  </button> :
                                  <button type="button"
                                    class={`rejected text-white border-0 `}>
                                    {"Cancelled"}
                                  </button>}
                              </div>
                            </td>
                            <td
                              // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                              className='position-relative'>
                              <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                onClick={() => setAction({ show: true, index: j })}
                                aria-hidden="true"></i>
                              {action.show && action.index === j ? (
                                <Action
                                  id={j}
                                  onDismiss={() => setAction({ show: false, index: j })}
                                  options={[
                                    {
                                      name: "Cancel IRN", onClick: async () => {
                                        setData({ ...data, selectedInvoice: i, showCancellationPopup: true })
                                        // let docDetails = await getDocDetails(i.docId)
                                        // if (Object.keys(docDetails)?.length) {
                                        //   toggleViewDoc({ show: true, doc: docDetails })
                                        // }
                                        // else {
                                        //   toastDisplay("No document found", "error")
                                        // }
                                      }
                                    },
                                    {
                                      name: "Print", onClick: async () => {
                                        setshowLoader(true)
                                        call('POST', 'printIRN', {
                                          Irn: i.webtelApi.response[0].Irn,
                                          GSTIN: i.docData.data.exporterGstNo,
                                          EInvUserName: i.webtelApi.request.EInvUserName,
                                          EInvPassword: i.webtelApi.request.EInvPassword
                                        }).then(res => {
                                          setshowLoader(false)
                                          printPDFFromBase64(res)
                                        }).catch(err => {
                                          setshowLoader(false)
                                          toastDisplay(err, "error")
                                        })
                                      }
                                    },
                                    // {
                                    //   name: "Download", onClick: async () => {
                                    //     let docDetails = await getDocDetails(i.docId)
                                    //     if (Object.keys(docDetails)?.length) {
                                    //       toggleDowdDoc({ show: true, doc: docDetails })
                                    //     }
                                    //     else {
                                    //       toastDisplay("No document found", "error")
                                    //     }
                                    //   }
                                    // }
                                  ]} />
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                    <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
                  </div>
                </>}
            </>}
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(Einvoice)