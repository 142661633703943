import React, { useState, useEffect } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import useForm from '../../utils/useForm';
import validate from '../../utils/validation_files/commoditiesDocsValidationRules';
import { ToastContainer } from 'react-toastify';
import { setRegistrationData } from '../../store/actions/action';
import Header from '../homeComponents/header';
import commImageUrl from '../../utils/makeCommImageUrl'
import toastDisplay from '../../utils/toastNotification';
import submitPayload from './submitPayload';


const DocUploadForm = ({ registrationData, setRegistrationData, employeeData, dispatch }) => {

  const [dbData, setdbData] = useState([]);
  const [showLoader, setshowLoader] = useState(true);
  let [buttoncount, setbuttoncount] = useState(0);
  const [otherDoc, setotherDoc] = useState([]);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  // const [selectedComm, setselectedComm] = useState([]);
  var isAdminModal = !(window.location.href.includes('registration'));
  let tempArray = []

  const {
    values,
    errors,
    handleChange,
    handleSubmitcomdoc,
    handleFile,
    SETvaluesData
  } = useForm(form_sub, scrolltoError, validate, tempArray);

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    // call('POST', 'getCommodityList', { specifications: false, commadd: false }).then((result) => {
    //   console.log("API result getCommodityList in comm modal:-->", result);
    //   // setdbData(result.message);
    //   setshowLoader(false)
    // }).catch((e) => {
    //   console.log("Error while querying getCommodityList:", e);
    //   setshowLoader(false)
    // })
    //------------------------------------------------------------------

    setshowLoader(false)
    setdbData(registrationData.payload.step2 ? registrationData.payload.step2 : [])

    // setselectedComm(registrationData.payload.step2 ? registrationData.payload.step2 : [])
    SETvaluesData(registrationData.payload.step3)
    registrationData.payload.modalNav = 2;//for navigating next/back in case of modal

    //---create Other data fields with retained data[start]---------
    if (registrationData.payload.otherDocDataArray && registrationData.payload.otherDocDataArray.length) {
      setotherDoc(registrationData.payload.otherDocDataArray);
    }
    //---create Other data fields with retained data[end]---------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("selectedComm---> in step3-->", selectedComm)
  console.log("values---> in step3-->", values)


  function form_sub() {
    handleNext(values);
  }

  // function addElement() {
  //   const data = [...otherDoc]
  //   data.push({})
  //   setotherDoc(data)
  // }

  // function removeElement(i) {
  //   const data = [...otherDoc]
  //   data[i] = null
  //   setotherDoc(data)
  // }
  // function checkElementNo() {
  //   let count = 0
  //   otherDoc.map((otherDoc) => {
  //     if (otherDoc) {
  //       count++
  //     }
  //   })
  //   return count
  // }

  function scrolltoError() {
    toastDisplay("Form Validation Error", "warn")
  }

  // function moreElement(idx) {
  //   return (
  //     <div>
  //       <ul className="other-documents">
  //         <li>
  //           <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
  //         </li>
  //         <li>
  //           <input type="text" className="custum-control" placeholder="Doc Type" name={`otherdocName`} num={idx} value={otherDoc[idx][`otherdocName`]} onChange={handleOtherDoc} />
  //         </li>
  //         <li>
  //           <div className="document-name-top">Valid Upto </div>
  //           <div className="document-name-bottom">(If applicable )</div>
  //         </li>
  //         <li>
  //           <input type="text" className="custum-control year" name={`otherdocMM`} num={idx} value={otherDoc[idx][`otherdocMM`]} placeholder="MM" maxlength="2" onChange={handleOtherDoc} />
  //           <input type="text" className="custum-control year" name={`otherdocYY`} num={idx} value={otherDoc[idx][`otherdocYY`]} placeholder="YYYY" maxlength="4" onChange={handleOtherDoc} />
  //         </li>
  //         <li>
  //           <div className="file-browse">
  //             <button className="btn btn-primary btn-sm">Upload Document</button>
  //             <input type="file" accept=".png,.jpg,.pdf" id="file0" name={`otherdoc`} num={idx} multiple onChange={handleOtherDoc} />
  //           </div>
  //         </li>
  //         <li>
  //           <button type="button" className="delete-icon" onClick={() => removeElement(idx)}><i className="fa fa-trash"></i></button>
  //         </li>
  //       </ul>
  //     </div>
  //   )
  // }

  // var handleOtherDoc = (event) => {
  //   let num = event.target.getAttribute('num');
  //   let data = otherDoc[num];
  //   if (event.target.files && event.target.files[0]) {
  //     data = { ...data, [event.target.name]: event.target.files[0] }
  //     otherDoc[num] = data;
  //   } else {
  //     data = { ...data, [event.target.name]: event.target.value }
  //     otherDoc[num] = data;
  //   }
  //   setotherDoc([...otherDoc]);
  //   setbuttoncount(buttoncount++);
  //   console.log(otherDoc);
  // }


  // const commDocComponent = (dbData && dbData.length) ? dbData.map((comm) => {
  //   if (selectedComm.includes(comm.id)) {
  //     tempArray.push(comm.commodity_name)
  //     return (
  //       <div>
  //         <h3 className="green pb-2">{comm.commodity_pretty_name ? comm.commodity_pretty_name : '--'} :<span className="required-field text-danger">*</span></h3>
  //         <ul className="other-documents">
  //           <li>
  //             <span className="file-icon"><img width="20" src={commImageUrl(comm.filename)} /> </span>
  //           </li>
  //           {/*  ../assets/images/onbording/file-icon.svg*/}
  //           <li style={{ "min-width": "250px" }}>
  //             <input type="text" className="custum-control" name={`${comm.commodity_name}Name`} placeholder="Doc Type" value={comm.commodity_pretty_name + ' Commodity documents'} onChange={handleChange} />
  //             {(values[`${comm.commodity_name}doc:11:15`]) ? <div className="form-group mb-0"><div className="file-name">{`${values[`${comm.commodity_name}doc:11:15`].name}`}</div></div> : <p className="text-danger pl-2 pt-1">{errors[`${comm.commodity_name}doc:11:15`]}</p>}
  //           </li>
  //           <li><span className="help-icon" flow="right" tooltip="Add Document for respective Commodity">i</span>
  //           </li>
  //           <li style={{ "min-width": "250px" }}>
  //             {/* <input type="number" className={" custum-control year" + (!errors[`${comm.commodity_name}docMM`] ? '' : ' border-danger')} name={`${comm.commodity_name}MM`} value={values[`${comm.commodity_name}MM`]} placeholder="MM" onChange={handleChange} />
  //             <input type="number" className={" custum-control year" + (!errors[`${comm.commodity_name}docYY`] ? '' : ' border-danger')} name={`${comm.commodity_name}YY`} value={values[`${comm.commodity_name}YY`]} placeholder="YYYY" onChange={handleChange} /> */}
  //           </li>
  //           <li className="pr-0">
  //             <div className="file-browse">
  //               <button className="btn btn-primary btn-sm">Upload Document</button>
  //               <input type="file" accept=".png,.jpg,.pdf" name={`${comm.commodity_name}doc:11:15`} onChange={handleFile} />
  //             </div>
  //           </li>
  //         </ul>
  //         <hr />
  //       </div>
  //     )
  //   } else {
  //     return ('')
  //   }
  // }) : '';

  const commDocComponent = (dbData && dbData.length) ? dbData.map((comm) => {
    if (comm.id) {
      tempArray.push(comm.commodity_name)
      return (
        <div>
          <h3 className="green pb-2">{comm.commodity_pretty_name ? comm.commodity_pretty_name : '--'} :<span className="required-field text-danger">*</span></h3>
          <ul className="other-documents">
            <li>
              <span className="file-icon"><img width="20" src={commImageUrl(comm.filename)} /> </span>
            </li>
            {/*  ../assets/images/onbording/file-icon.svg*/}
            <li style={{ "min-width": "250px" }}>
              <input type="text" className="custum-control" name={`${comm.commodity_name}Name`} placeholder="Doc Type" value={comm.commodity_pretty_name + ' Commodity documents'} onChange={handleChange} />
              {(values[`${comm.commodity_name}doc:11:15`]) ? <div className="form-group mb-0"><div className="file-name">{`${values[`${comm.commodity_name}doc:11:15`].name}`}</div></div> : <p className="text-danger pl-2 pt-1">{errors[`${comm.commodity_name}doc:11:15`]}</p>}
            </li>
            <li><span className="help-icon" flow="right" tooltip="Add Document for respective Commodity">i</span>
            </li>
            <li style={{ "min-width": "250px" }}>
              {/* <input type="number" className={" custum-control year" + (!errors[`${comm.commodity_name}docMM`] ? '' : ' border-danger')} name={`${comm.commodity_name}MM`} value={values[`${comm.commodity_name}MM`]} placeholder="MM" onChange={handleChange} />
              <input type="number" className={" custum-control year" + (!errors[`${comm.commodity_name}docYY`] ? '' : ' border-danger')} name={`${comm.commodity_name}YY`} value={values[`${comm.commodity_name}YY`]} placeholder="YYYY" onChange={handleChange} /> */}
            </li>
            <li className="pr-0">
              <div className="file-browse">
                <button className="btn btn-primary btn-sm">Upload Document</button>
                <input type="file" accept=".png,.jpg,.pdf" name={`${comm.commodity_name}doc:11:15`} onChange={handleFile} />
              </div>
            </li>
          </ul>
          <hr />
        </div>
      )
    } else {
      return ('')
    }
  }) : '';


  async function handleNext(values) {
    registrationData.payload.step3 = values;
    registrationData.payload.otherDocDataArray = otherDoc;
    if (registrationData.type === 6 || registrationData.type === 14 || registrationData.type === 3 || registrationData.type === 4) {
      // setRegistrationData(registrationData.type, 8, registrationData.payload);
      try {
        console.log("in submit payload==>")
        setshowLoader(true)
        let result = await submitPayload(registrationData, employeeData)
        setshowLoader(false)
        toastDisplay(result, "success");
        setTimeout(() => { }, 5000);
        setRegistrationData(0, 6, {});
      } catch (error) {
        setshowLoader(false)
        toastDisplay(error, "error");
        console.log("error in submit payload==>", error)
      }
    }
    else {
      setRegistrationData(registrationData.type, 5, registrationData.payload);
    }
  }

  function handleBack() {
    registrationData.payload.step3 = values;
    setRegistrationData(registrationData.type, 3, registrationData.payload)
  }

  function docUploadFormCommon() {
    return (
      <div className="row">
        {/* <div className="col-md-12 mb-3">
          <h3 className="green border-bottom pb-2">Provide below the Commodity Documents :</h3>
        </div> */}
        <div className="col-md-12">
          <h3 className="green pb-2" id="POSdoc" >Commodities Documents<span className="required-field text-danger">*</span></h3>
          {commDocComponent}
          {/* <div>
            <h3 className="green pb-2">Other Documents :</h3>
            {otherDoc.map((otherDocData, idx) => {
              return (
                <>
                  {otherDocData != null ? moreElement(idx) : ""}
                  {otherDoc[idx] && otherDoc[idx][`otherdoc`] ? <div className="filePath"><div className="file-name">{otherDoc[idx][`otherdoc`].name}</div></div> : ""}
                  {otherDocData != null ? <br /> : ""}
                </>
              )
            })}
            {(checkElementNo() <= 4) && <div className="d-flex mt-2 pr-0"><button className="btn-primary btn btn-sm" onClick={() => addElement()}>Add +</button></div>}
          </div>
          <hr className="pt-1 pb-1" /> */}
        </div>
      </div>
    )
  }

  // --final component return[start]-----
  if (isAdminModal) {
    return (
      <div className="modal-body calc-inner-modal">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="container-fluid">
          <div className="row">
            {/* Navigation in case of modal: 1-prev, 2-neutral, 3-next(not used 0 coz its falsy)*/}
            {(registrationData.payload.modalNav === 1) && (registrationData.payload.modalNav = 2) && handleBack()}
            {(registrationData.payload.modalNav === 3) && (registrationData.payload.modalNav = 2) && handleSubmitcomdoc()}
            <div className="col-md-12">
              {docUploadFormCommon()}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <ul className="next-button">
          <li ><button onClick={handleBack}>{"<< Previous"}</button></li>
          {(registrationData.type === 6 || registrationData.type === 14 || registrationData.type === 3 || registrationData.type === 4) ? null : (
            <li ><button onClick={handleSubmitcomdoc} noValidate >{"Next >>"}</button></li>
          )}
        </ul>

        <div className="container-fluid">
          <div className="row">
            <Header title={'Documentation'} />
            <div className="col-md-12">
            </div>
            <div className="col-md-8 mx-auto bg-white rounded  pb-5 mt-3 mb-5 calc pt-3 overflow-auto">
              {docUploadFormCommon()}
              {
                (registrationData.type === 6 || registrationData.type === 14 || registrationData.type === 3 || registrationData.type === 4) ? (
                  <div className="text-center mt-3 mb-3">
                    <div className="col-md-10 mt-1 offset-md-1">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                          name="termsAndCondition" id="termsAndCondition"
                          onChange={() => setTermsAndCondition(!termsAndCondition)} checked={termsAndCondition} />
                        <label className="form-check-label text-center" for="termsAndCondition">
                          I acknowledge that i have read and fully understand the <a href="./assets/docs/Terms_of_Use.pdf" target="true">terms and conditions</a> of the
                          tradereboot onboarding agreement and that i will comply with the same.
                        </label>
                      </div>
                    </div>
                    {termsAndCondition ? (
                      <button className="btn btn-primary btn-sm mt-3" type="button"
                        onClick={() => { handleSubmitcomdoc() }} noValidate>Submit</button>
                    ) : null}
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
  // --final component return[end]-----

}


const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocUploadForm)
