import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewTable } from "../../../utils/newTable";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from "../components/Filter";
import config from '../../../config.json';
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { convertImageToPdf, getDocDetails, SetCache, ClearCache, most_used_currencies, GetCache, roundOfNumberInDecimal } from "../../../utils/myFunctions";
import Pagination from "../contract/components/pagination";
import FinanceInvoiceModal from "../contract/components/financeinvoiceModal";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { DocumentViewer } from "../../../utils/documentViewer";

export const EmojiData = [
  {
    image: "/assets/images/angry.png",
    fillimage: "/assets/images/angry-fill.png",
    name: "Angry",
    rating: 1
  },
  {
    image: "/assets/images/Not satisfied.png",
    fillimage: "/assets/images/notsatisfed-fill.png",
    name: "Not satisfied",
    rating: 2
  },
  {
    image: "/assets/images/ok.png",
    fillimage: "/assets/images/ok-fill.png",
    name: "ok",
    rating: 3
  }, {
    image: "/assets/images/Satisfied.png",
    fillimage: "/assets/images/satisfied-fill.png",
    name: "Satisfied",
    rating: 4
  }, {
    image: "/assets/images/excellent.png",
    fillimage: "/assets/images/excellent-fill.png",
    name: "excellent",
    rating: 5
  },
];

const ApprovedFinance = ({ userTokenDetails, navToggleState }) => {

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' });
  const [limitinvoice, setLimitinvoice] = useState({ show: false, data: [] });
  const [experience, setExperience] = useState({ show: false, data: {} });
  const [emoji, setEmoji] = useState("");
  const [page, setPage] = useState(1)
  const [showLoader, setshowLoader] = useState(false)
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [filterData, setFilterData] = useState({})


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  const closeSuccess = () => {
    setLimitinvoice({ show: false, data: [] });
  }

  const closeExpFn = () => {
    setExperience({ show: false, data: {} });
    setEmoji("")
    setData({})
  }

  useEffect(() => {
    call('POST', 'getApprovedInvoiceFilter', { userId }).then(res => {
      // console.log("getApprovedInvoiceFilter then", res);
      setFilterData(res)
    }).catch(err => { })
  }, [])

  useEffect(() => {
    setshowLoader(true)
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      onlyAgreementSigned: true,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "invoiceNo" || element.accordianId === "financeAmountRange" || element.accordianId === "poNo" || element.accordianId === "status") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getBuyersDetail', objectAPI).then(async (result) => {
      console.log('running getBuyersDetail api-->', result);
      if (result.buyerData.length) {
        for (let index = 0; index < result.buyerData.length; index++) {
          result.buyerData[index]["docSignStatus"] = await call("POST", 'invoiceAgreementSignStatus', { invRefNo: result.buyerData[index]["invRefNo"] })
        }
      }
      setshowLoader(false)
      setDbData(result.buyerData);
      setTableData(formatDataForTable(result.buyerData))
      setCount(result.countData);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
    //Get buyer list end
  }, [refresh, page, filterData])


  async function submitRating(index) {
    setExperience({ show: true, data: dbData[index] })
  }

  async function postRating() {
    setshowLoader(true)
    await call("POST", "postRatingToFinancier", {
      ratingFor: 'ID', applicationId: experience.data.invRefNo, lenderId: experience.data.selectedQuote.lender_id,
      ratings: emoji, remarks: data.remarks, userId
    })
    toastDisplay("Feedback submitted successfully", "success")
    closeExpFn()
    setshowLoader(false)
  }

  async function onEdit(index) {
    toggleAddBuyerForm(true)
    let selectedBuyer = dbData[index]
    let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
    let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])
    setData({
      buyerId: selectedBuyer.id,
      buyerName: selectedBuyer.buyerName,
      buyerCountry: selectedBuyer.buyerCountry,
      buyerEmail: selectedBuyer.buyerEmail,
      buyerContact: selectedBuyer.buyerPhone,
      buyerPhoneCode: selectedBuyer.buyerPhoneCode,
      buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
      buyerWebsite: selectedBuyer.buyerWebsite,
      buyerPrevSale: selectedBuyer.previousAnnualSale,
      buyerExpectedSale: selectedBuyer.currentAnnualSale,
      buyerIncoTerms: selectedBuyer.incoTerms,
      buyerTermsPayment: selectedBuyer.termsOfPayment,
      buyerProductDetails: selectedBuyer.productDetails,
      buyerHsnCode: selectedBuyer.buyerHsnCode ? selectedBuyer.buyerHsnCode.split(",") : null,
      buyersDocId: selectedBuyer.buyersDocId,
      invoiceDocument: { ...invoiceDocument, noChange: true },
      poDocument: { ...poDocument, noChange: true },
      isEditing: true,
      tcAccepted: true
    })
  }

  async function onView(index) {
    // alert(index)
    window.location = `/viewDetails`
    localStorage.setItem("item", JSON.stringify(dbData[index]))
  }

  async function showDisbursmentSchedule(inv) {
    setshowLoader(true)
    call('POST', 'getInvoiceDisbursementSchedule', { invRefNo: inv }).then(async (result) => {
      // console.log('running getInvoiceDisbursementSchedule api-->', result);
      setshowLoader(false)
      if (result.length) {
        setLimitinvoice({ show: true, data: result })
      }
      else {
        toastDisplay("No schedule provided by financier", "info")
      }
    }).catch((e) => {
      // console.log('error in getInvoiceDisbursementSchedule', e);
      setshowLoader(false)
      toastDisplay('Something went wrong', "error")
    });
  }



  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = moment(item.invModifiedAt).format('DD/MM/YYYY')
      row[1] = item.buyerName
      row[2] = item.po_no
      row[3] = item.reference_no
      row[4] = item.due_date
      row[5] = roundOfNumberInDecimal(item.contract_amount) + " " + (item?.buyerCurrency?.split(":")[1] || "NA")
      row[6] = item.credit_days + " days"
      if (item.invoiceStatus / 1 == 6 || item.invoiceStatus / 1 == 4) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Disbursement scheduled</b></span>
          <label
            onClick={() => showDisbursmentSchedule(item.reference_no)}
            className="text-color1 text-decoration-underline cursor ml-2">View Schedule</label> </p>
      }
      row[7] =
        <div className=' ' >
          {item.invoiceStatus / 1 == 5 && <button type="button"
            className={`rejected text-white border-0 `}>
            {"Declined"}
          </button>}
          {item.invoiceStatus / 1 == 3 && <button type="button"
            className={`approved text-white border-0 `}>
            {"Approved"}
          </button>}
          {item.invoiceStatus / 1 == 4 && <button type="button"
            className={`approved text-white border-0 `}>
            {"Disbursed"}
          </button>}
          {item.invoiceStatus / 1 == 6 && <button type="button"
            className={`inprogress text-white border-0 `}>
            {"Inprogress"}
          </button>}
        </div>
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    // setErrors({ ...errors, [event.target.name]: "" })
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_approvedFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discounting > Approved Finance"}
              userTokenDetails={userTokenDetails} />

            <div>
              {/* <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
              {/* <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} /> */}
              <div className='filter-div'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div>
            </div>
            <NewTable
              tableContainsNotification={true}
              columns={[{
                name: "Date", filter: true, filterDataKey: "Date", sort: [
                  { name: "Latest First", selected: filter.sortDateBy === "DESC", onActionClick: () => { setFilter({ ...filter, sortDateBy: 'DESC', sortBuyerName: false }); setRefresh(refresh + 1) } },
                  { name: "Oldest First", selected: filter.sortDateBy === "ASC", onActionClick: () => { setFilter({ ...filter, sortDateBy: "ASC", sortBuyerName: false }); setRefresh(refresh + 1) } }]
              },
              {
                name: "Buyer Name", filter: true, filterDataKey: "Buyer Name", sort: [
                  { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false }); setRefresh(refresh + 1) } },
                  { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false }); setRefresh(refresh + 1) } }]
              },
              {
                name: "Purchase Order No."
              },
              {
                name: "Invoice No."
              },
              {
                name: "Invoice Due Date"
              },
              { name: "Finance Amount" },
              {
                name: "Finance Credit Days"
              }, {
                name: "Status"
              }]}
              data={tableData}
              options={
                [
                  { name: "Give ratings", icon: "rating.png", onClick: (index) => submitRating(index) },
                  { name: "View Details", icon: "eye.png", onClick: onView }]}
            />
            <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />


          </main>
        </div>
      </div>
      {limitinvoice && limitinvoice.show && <FinanceInvoiceModal modalSize={"lg"} limitinvoice={limitinvoice.show} closeSuccess={closeSuccess} >
        <p>Disbursement schedule</p>
        <table
          className='details text-center mx-5'>
          <tr className='th p-1 d-flex justify-content-around'>
            <th className='heading text-secondary width16' scope="col">
              Scheduled Date
            </th>
            <th className='heading text-secondary width16' scope="col">
              Scheduled Amount
            </th>
            <th className='heading text-secondary width16' scope="col">
              Disbursement Date
            </th>
            <th className='heading text-secondary width16' scope="col">
              Disbursement Amount
            </th>
            <th className='heading text-secondary width16' scope="col">
              Status
            </th>
            <th className='heading text-secondary width16' scope="col">
              {" "}
            </th>
          </tr>

          {limitinvoice.data.map((item, index) => {
            return (
              <tr className={`th bg-white rounded-0 p-0`}
              >
                <td className='heading width16'>
                  {moment(item.scheduledOn).format('DD/MM/YYYY')}
                </td>
                <td className='heading width16'>
                  {item.currency + " " + item.amount}
                </td>
                <td className='heading width16'>
                  {item.status == 1 ? moment(item.updatedAt).format('DD/MM/YYYY') : "-"}
                </td>
                <td className='heading width16'>
                  {item.status == 1 ? (item.currency + " " + item.disbursedAmount) : "-"}
                </td>
                <td className='heading width16'>
                  {item.status ? (
                    <img src={"/assets/images/upload_done.png"} alt="Yes" height={16} width={17} />
                  ) : (
                    <img src={"/assets/images/processing.png"} alt="" height={16} width={17} />
                  )}
                </td>
                <td className='heading width16'>
                  <label
                    onClick={async () => {
                      if (item.attachment) {
                        let docFileObj = await getDocDetails(item.attachment)
                        toggleViewDoc({ show: true, doc: docFileObj })
                        setLimitinvoice({ show: false, data: [] });
                      }
                      else {
                        toastDisplay("Receipt not uploaded by financer", "info")
                      }
                    }}
                    className="text-color1 font-wt-600 cursor"
                  >{item.attachment ? "View Receipt" : "NA"}</label>
                </td>
              </tr>
            )
          })}
        </table>
      </FinanceInvoiceModal>}

      {experience.show && <FinanceInvoiceModal limitinvoice={experience.show} closeSuccess={closeExpFn}>
        <p>How was your experience with <span className="text-custom">{experience.data.selectedQuote.lender_name}</span> ?</p>
        <div className="d-flex gap-3 justify-content-center">
          {EmojiData.map((key, index) => {

            return (
              <>
                <img src={emoji === key.rating ? key.fillimage : key.image} alt="angry" key={index} className="cursor " onClick={() => setEmoji(key.rating)} />
              </>
            );
          })}

        </div>
        {emoji !== "" && < div className="my-3 col-9 mx-auto text-end border px-0 send p-2">
          <textarea name={"remarks"} value={data.remarks} onChange={handleChange} className="form-control border-0 bg-transparent" id="exampleFormControlTextarea1" rows="3" placeholder="Write a review..."></textarea>
          <img className="cursor px-1" src={"/assets/images/Send.png"} alt="" onClick={() => postRating()} />
        </div>}
      </FinanceInvoiceModal >}

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApprovedFinance)