import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../../../service';
import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';
import Filter from "../../components/Filter";
import moment from 'moment';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import { ToastContainer } from 'react-toastify';
import ChatBoxPopUp2 from '../../../chatRoom/components/ChatBoxPopUp2';
import { getDocDetails } from '../../../../utils/myFunctions';
import NewTablev2 from '../../../../utils/newTablev2';
import { Action } from '../../../myCounterPartComp/action';


export const FinInvoiceQuotes = ({ userTokenDetails, navToggleState }) => {

  let searchBy = localStorage.getItem('finInvoiceQuotesSearch') || ""

  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, singleApplication: true, search: searchBy })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [showLoader, setshowLoader] = useState(false)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [filterData, setFilterData] = useState({})
  const [action, setAction] = useState({ show: false, index: null })
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    fetchApplicationCounts()
  }, [])

  async function fetchApplicationCounts() {
    call('POST', 'getInvoiceLimitApplicationCounts', { userId }).then(res => setApplicationCounts(res))
  }

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "currentPage": page,
      "resultPerPage": 10,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getInvoiceLimit', objectAPI).then((result) => {
      // setTableData(formatDataForTable(result.limitData))
      setTableData(result.limitData)
      setCount(result.countData)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      throw e;
    });
  }, [refresh, filter]);


  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  function modifyObjBeforeStoring(obj) {
    obj["createdAt"] = obj.createdAt.toString().split(",")[0]
    obj["buyerName"] = obj.buyerName.toString().split(",")[0]
    obj["requiredLimit"] = obj.requiredLimit.toString().split(",")[0]
    obj["requiredLimitCurrency"] = obj.requiredLimitCurrency.toString().split(",")[0]

    obj["limit_id"] = obj.limit_id.toString().split(",")[0]
    obj["chatRoomIds"] = obj.chatRoomIds ? obj.chatRoomIds.toString().split("|:|:")[0] : null
    obj["chatRoomUsers"] = obj.chatRoomUsers ? obj.chatRoomUsers.toString().split("|:|:")[0] : null
    obj["lastMsg"] = obj.lastMsg ? obj.lastMsg.toString().split("|:|:")[0] : null
    obj["lastMsgTime"] = obj.lastMsgTime ? obj.lastMsgTime.toString().split("|:|:")[0] : null
    obj["chatRoomUnreadMsgCount"] = obj.chatRoomUnreadMsgCount ? obj.chatRoomUnreadMsgCount.toString().split("|:|:")[0] : null
    return obj
  }

  function getTextColor(approved, rejected) {
    if (approved) {
      return ` text-48DA87 `
    }
    else if (rejected) {
      return ` text-color-E74C3C `
    }
    else {
      return ``
    }
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="fininvoiceQuotes" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Export Factoring > Limit Application"}
              userTokenDetails={userTokenDetails} />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4  ' style={{ width: '43.22%' }} >
                <label className='font-size-16 font-wt-500 color0C0C0C' >Application in process - <label className='text-color1 font-size-16 font-wt-500' >{count || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.new || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>New</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.quoteSent || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Quote</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.sendTermsheet || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Send Termsheet</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.termsheetSent || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Termsheet Sent</p>
                  </div>
                </div>
              </div>
              <div className='card p-4  ' style={{ width: '24.22%' }} >
                <label className='font-size-16 font-wt-500 color0C0C0C' >Rejected Application - <label className='text-danger font-size-16 font-wt-500' >{applicationCounts.rejected || 0}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{applicationCounts.rejectedByYou || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>By You</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{applicationCounts.rejectedBySupplier || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>By Supplier</p>
                  </div>
                </div>
              </div>
              <div
                className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-secondary p-0 m-0' >{applicationCounts.expired || 0}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Expired</p>
                </div>
              </div>
              <div
                className='' style={{ width: '12.44%' }} >
              </div>
            </div>

            <div className='filter-div position-relative'>
              <Filter singleMultApplication={true}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>
            <div className='mt-2'>
              <NewTablev2
                columns={[{ subColumns: "Date", filter: true, subColumnStyle: { width: '10%' } },
                { subColumns: "Supplier Name", filter: true, subColumnStyle: { width: '14%' } },
                { subColumns: "Buyer Name", filter: true, subColumnStyle: { width: '14%' } },
                { subColumns: "Limit Requested", subColumnStyle: { width: '10%' } },
                { subColumns: "Finance Credit Days", subColumnStyle: { width: '11%' } }, { subColumns: "Remark", subColumnStyle: { width: '16%' } },
                { subColumns: "Action", subColumnStyle: { width: '13%' } }
                ]}
              >{
                  tableData.map((item, index) => {
                    let isQuoteApproved = null
                    let isQuoteRejected = null
                    let financeLimitWithCurrency = null
                    let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : null;
                    let threeDaysFromCreationDate = moment(item.createdAt).add(4, "days")
                    let daysLeft = threeDaysFromCreationDate.clone().diff(moment(), "days")


                    let mySentQuote = buyers_credit && buyers_credit.filter(i => {
                      if (i.lender_id / 1 == userId / 1) {
                        if (i.financierAction === "Approved") {
                          isQuoteApproved = true
                          financeLimitWithCurrency = `${i.financeLimit} ${i.financeLimitCurrency}`
                        }
                        else {
                          isQuoteRejected = true
                        }
                        return i
                      }
                    })[0]
                    let multipleApplicationBuyersCredit = []
                    if (item.multipleApplicationBuyersCredit) {
                      let temp = item.multipleApplicationBuyersCredit?.split("|:|:")
                      // for (let index = 0; index < temp.length; index++) {
                      //   if (temp[index] === "null") {
                      //     multipleApplicationBuyersCredit.push("no_action")
                      //   }
                      //   else {
                      //     let allQuotes = []
                      //     try {
                      //       allQuotes = JSON.parse(temp[index])
                      //     } catch (error) {
                      //       console.log("erroroccureforjsonnnnnnnnnnnnnnnnn", temp);
                      //     }
                      //     for (let index = 0; index < allQuotes.length; index++) {
                      //       const element = allQuotes[index];
                      //       if (element.lender_id / 1 == userId / 1) {
                      //         if (element.financierAction === "deny") {
                      //           multipleApplicationBuyersCredit.push("deny")
                      //         }
                      //         else {
                      //           multipleApplicationBuyersCredit.push("approved")
                      //         }
                      //       }
                      //     }
                      //   }
                      // }
                    }
                    let totalChatApplications = !filterData.singleApplication && item.chatRoomIds?.split('|:|:') || []

                    // console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmm", index, item.multipleApplicationBuyersCredit, multipleApplicationBuyersCredit);

                    return (
                      <tr>
                        {!filter.singleApplication ? <td style={{ whiteSpace: 'pre-line' }} ><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{
                          item.createdAt?.split(",").map((i, j) => {
                            return (<><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`}>{`${item.createdAt?.split(",").length > 1 ? (j + 1) + "." : ""} ${i ? moment(i).format('DD/MM/YYYY') : 'NA'}`}</label><br /></>)
                          })}</label></td> :
                          <td><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : 'NA'}</label></td>}
                        <td><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{item.supplierName ? item.supplierName : 'NA'}</label></td>
                        {!filter.singleApplication ?
                          <td style={{ whiteSpace: 'pre-line' }} ><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{
                            item.buyerName.split(",").map((i, j) => {
                              return (<><label className={`${multipleApplicationBuyersCredit?.[j] === "deny" ? ' text-danger ' : multipleApplicationBuyersCredit?.[j] === "approved" ? ' text2ECC71 ' : ''}`} >
                                {`${item.buyerName.split(",").length > 1 ? (j + 1) + "." : ""} ${i}`}</label><br /></>)
                            })}</label></td> :
                          <td style={{ whiteSpace: 'pre-line' }} ><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{item.buyerName || 'NA'}</label></td>}
                        <td style={{ whiteSpace: 'pre-line' }}><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{
                          filter.singleApplication ?
                            `${item.requiredLimit} ${item.requiredLimitCurrency}${financeLimitWithCurrency ? `(${financeLimitWithCurrency})` : ""}` :
                            item.requiredLimit?.toString().split(",").map((i, j) => `${item.requiredLimit?.toString().split(",").length > 1 ? (j + 1) + "." : ""} ${i} ${item.requiredLimitCurrency?.split(",")?.[j]}`).join("\n")}</label></td>
                        {!filter.singleApplication ? <td style={{ whiteSpace: 'pre-line' }} ><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{
                          item.termsOfPayment?.split(",").map((i, j) => {
                            return (<><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`}>{`${item.termsOfPayment?.split(",").length > 1 ? (j + 1) + "." : ""} ${i}`}</label><br /></>)
                          })}</label></td> :
                          <td><label className={`font-size-13 font-wt-400 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{item.termsOfPayment ? item.termsOfPayment : 'NA'}</label></td>}
                        {filter.singleApplication ?
                          <td>
                            <div
                              onClick={async () => {
                                let reqObj = {
                                  userTypeId,
                                  senderId: userId,
                                  sellerId: item.created_by,
                                  receiverId: userId,
                                  textMessage: 'Hii',
                                  chat_room_name: "CHAT" + new Date().getTime(),
                                  includeAdmins: true,
                                  invApplicationId: item.limit_id,
                                  receiverParties: userId,
                                  dontSendInitialMsg: true
                                }
                                setshowLoader(true)
                                let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                setshowLoader(false)
                                getChatDetails({
                                  chat_room_id: apiResp.id,
                                  loggedInUser: userId
                                })
                                setSelectedChat({
                                  chatRoomId: apiResp.id,
                                  receiverName: `${item.chatRoomIds || apiResp.id}`,
                                  invApplicationId: item.limit_id,
                                  parties: item.chatRoomUsers || apiResp.parties,
                                  userId: userId,
                                  isChatOpen: true,
                                  receiverId: userId,
                                  sellerId: item.created_by
                                })
                              }}
                            >
                              <div className='d-flex' >
                                <p className={`font-size-12 font-wt-500 p-0 m-0 ${getTextColor(isQuoteApproved, isQuoteRejected)} ${item.chatRoomUnreadMsgCount > 0 ? ' w-80' : ' w-100 '}`} >
                                  {item.lastMsgTime ?
                                    <label className={`font-wt-700 w-100 p-0 m-0 ${getTextColor(isQuoteApproved, isQuoteRejected)}`} >{moment(item.lastMsgTime).format("DD/MM/YYYY | HH:mm")}</label> : null}
                                  <p className='ellipsis-container' >
                                    <p className={`mb-0 ellipsis-text ${getTextColor(isQuoteApproved, isQuoteRejected)}`} style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastMsg || "No message yet" }}></p>
                                  </p>
                                </p>
                                {item.chatRoomUnreadMsgCount > 0 ?
                                  <label
                                    className={`lastMsgCount ${getTextColor(isQuoteApproved, isQuoteRejected)}`}
                                  >{item.chatRoomUnreadMsgCount}</label> : null}
                              </div>
                            </div>
                          </td> : totalChatApplications.map((o, p) => {
                            let multlimit_id = item.limit_id?.toString()?.split(",") || []
                            let chatRoomIds = item.chatRoomIds?.split("|:|:") || []
                            chatRoomIds = chatRoomIds[p] || []
                            let chatRoomUsers = item.chatRoomUsers?.split("|:|:") || []
                            chatRoomUsers = chatRoomUsers[p] || []

                            // console.log("multipleApppppppppppppppppppppppppppp", p, multlimit_id, chatRoomIds, chatRoomUsers, item.chatRoomUnreadMsgCount, item.lastMsgTime, item.lastMsg);

                            return (
                              <div
                                onClick={async () => {
                                  let reqObj = {
                                    userTypeId,
                                    senderId: userId,
                                    sellerId: item.created_by,
                                    receiverId: userId,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    invApplicationId: multlimit_id[p],
                                    receiverParties: userId,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${chatRoomIds || apiResp.id}`,
                                    invApplicationId: multlimit_id[p],
                                    parties: chatRoomUsers || apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: userId,
                                    sellerId: item.created_by
                                  })
                                }}
                              >
                                <div className='d-flex' >
                                  <p className={`font-size-12 font-wt-500 p-0 m-0  ${item.chatRoomUnreadMsgCount?.split("|:|:")?.[p] > 0 ? ' w-80' : ' w-100 '}`} >
                                    {item.lastMsgTime?.split("|:|:")?.[p] && item.lastMsgTime?.split("|:|:")?.[p] != 'NULL' ?
                                      <label className={`font-wt-700 w-100 p-0 m-0 `} >{moment(item.lastMsgTime?.split("|:|:")?.[p]).format("DD/MM/YYYY | HH:mm")}</label> : null}
                                    <p className='ellipsis-container' >
                                      <p className={`mb-0 ellipsis-text `} style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{
                                        __html: item.lastMsg?.split("|:|:")?.[p] && item.lastMsg?.split("|:|:")?.[p] != "NULL" ? `${p + 1}.${item.lastMsg?.split("|:|:")?.[p]}` : `${p + 1}. No message yet`
                                      }}></p>
                                    </p>
                                  </p>
                                  {item.chatRoomUnreadMsgCount?.split("|:|:")?.[p] > 0 ?
                                    <label
                                      className={`lastMsgCount `}
                                    >{item.chatRoomUnreadMsgCount?.split("|:|:")?.[p]}</label> : null}
                                </div>
                              </div>
                            )
                          })}

                        {filter.singleApplication ?
                          <td>{moment().diff(moment(item.createdAt), "days") >= 60 ?
                            <div className='row ' >
                              <a>
                                <button type="button"
                                  onClick={() => {
                                    localStorage.setItem("quotesDetail", JSON.stringify(modifyObjBeforeStoring(item)));
                                    window.location = `/finQuotesDetails`;
                                  }}
                                  className={`border-0 mb-2 text-white disablesigncontract2`}>
                                  {"Expired"}
                                </button>
                              </a>
                            </div > : !mySentQuote ?
                              <div className='row ' >
                                <a>
                                  <button type="button"
                                    onClick={() => {
                                      localStorage.setItem("quotesDetail", JSON.stringify(modifyObjBeforeStoring(item)));
                                      window.location = `/finQuotesDetails`;
                                    }}
                                    className={`border-0 mb-2 text-white enableQuotebtn2`}>
                                    {"View Application"}
                                  </button>
                                </a>
                              </div > : mySentQuote.financierAction === "deny" ?
                                <div className='row ' ><a>
                                  <button type="button"
                                    onClick={() => {
                                      localStorage.setItem("quotesDetail", JSON.stringify(modifyObjBeforeStoring(item)));
                                      window.location = `/finQuotesDetails`;
                                    }}
                                    className={`border-0 mb-2 text-white rejectedQuotebtn`}>
                                    {"Limit Denied"}
                                  </button>
                                </a>
                                </div > : !item.selectedFinancier ? <div className='row ' >
                                  <a>
                                    <button type="button"
                                      onClick={() => {
                                        localStorage.setItem("quotesDetail", JSON.stringify(modifyObjBeforeStoring(item)));
                                        window.location = `/finQuotesDetails`;
                                      }}
                                      className={`viewfinancersbtnnew text-color1`}>
                                      {"Quote Sent"}
                                    </button>
                                    <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                      <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Quote sent waiting for supplier's response</span>
                                    </p>
                                  </a>
                                </div > :
                                  !item.termSheet ?
                                    <div className='row ' >
                                      <a>
                                        <button type="button"
                                          onClick={() => {
                                            localStorage.setItem("termSheetDetail", JSON.stringify(modifyObjBeforeStoring(item)));
                                            window.location = `/sendTermSheet`;
                                          }}
                                          className={`border-0 mb-2 text-white enableQuotebtn2`}>
                                          {"Send Termsheet"}
                                        </button>
                                        <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Quote selected by supplier, send termsheet</span>
                                        </p>
                                      </a>
                                    </div > : !item.termSheetSignedByExporter ?
                                      <div className='row ' >
                                        <a>
                                          <button type="button"
                                            className={`viewfinancersbtnnew text-color1`}>
                                            {"Termsheet Sent"}
                                          </button>
                                          <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Termsheet sent waiting for supplier to sign it</span>
                                          </p>
                                        </a>
                                      </div >
                                      : item.selectedFinancier / 1 == userId / 1 ? <div className='row ' >
                                        <a>
                                          <button
                                            type="button"
                                            className={`viewfinancersbtnnew text-color1`}>
                                            {"Termsheet Signed"}
                                          </button>
                                          <p className="font-size-12 text-color-value p-0 m-0 ml-3 mt-2">
                                            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Termsheet signed by supplier</span>
                                          </p>
                                        </a>
                                      </div > : <div className='row ' >
                                        <a>
                                          <button type="button"
                                            className={`border-0 mb-2 text-white disablesigncontract2`}>
                                            {"Not Available"}
                                          </button>
                                        </a>
                                      </div >
                          }</td> : <td>
                            <div className='row ' >
                              <a>
                                <button type="button"
                                  onClick={() => {
                                    localStorage.setItem("quotesDetail", JSON.stringify(modifyObjBeforeStoring(item)));
                                    window.location = `/finQuotesDetails`;
                                  }}
                                  className={`border-0 mb-2 text-white enableQuotebtn2`}>
                                  {"View Application"}
                                </button>
                              </a>
                            </div >
                          </td>}
                        {/* <td
                          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          className='position-relative'>
                          <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                            onClick={() => setAction({ show: true, index })}
                            aria-hidden="true"></i>
                          {action.show && action.index === index ? (
                            <Action
                              id={index}
                              onDismiss={() => setAction({ show: false, index })}
                              options={[
                                {
                                  name: "Chat With Supplier", icon: "",
                                  onClick: async () => {
                                    let reqObj = {
                                      userTypeId,
                                      senderId: userId,
                                      sellerId: item.created_by,
                                      receiverId: userId,
                                      textMessage: 'Hii',
                                      chat_room_name: "CHAT" + new Date().getTime(),
                                      includeAdmins: true,
                                      invApplicationId: item.limit_id,
                                      receiverParties: userId,
                                      dontSendInitialMsg: true
                                    }
                                    setshowLoader(true)
                                    let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                    setshowLoader(false)
                                    getChatDetails({
                                      chat_room_id: apiResp.id,
                                      loggedInUser: userId
                                    })
                                    setSelectedChat({
                                      chatRoomId: apiResp.id,
                                      receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                      invApplicationId: item.limit_id,
                                      parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                      userId: userId,
                                      isChatOpen: true,
                                      receiverId: userId,
                                      sellerId: item.created_by
                                    })
                                  }
                                }
                              ]} />
                          ) : null}
                        </td> */}
                      </tr>
                    )
                  })
                }
              </NewTablev2>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div >
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(FinInvoiceQuotes)