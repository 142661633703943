import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import CalenderView from './components/calenderView';
import ChatBody from './components/chatBody';
import ChatBodyGroup from './components/ChatBodyGroup';
import ChatFooter from './components/chatFooter';
import ChatHeader from './components/chatHeader';
import ChatList from './components/chatList';
import ScheduleList from './components/scheduleList';
import { getDocDetails } from '../../utils/myFunctions';
import { selectIsLocalAudioEnabled, selectIsLocalVideoEnabled, selectLocalPeer, selectPeers, selectRemotePeers, useHMSActions, useHMSStore, useVideo } from '@100mslive/react-sdk';
import { NewSelect } from '../../utils/newInput';
import MultipleSelect from '../../utils/MultipleSelect';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "8rem",
      "right": "10rem"
    },
    arrow: {
      "top": "20rem",
      "zIndex": 10,
      transform: "rotate(453deg)",
      left: "14.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>You can chat, call or schedule a with Financers & your Buyers in "Chat room".</span></label>
  }
}


const recievedchatdata = [
  {
    receivedtext: "Consectetur malesuada mollis vel, laoreet tempor. ",
    dpdisplay: ""
  },
  {
    receivedtext: "Consectetur malesuada mollis vel ",
    dpdisplay: "d-none"
  },
  {
    receivedtext: "Consectetur malesuada mollis vel, laoreet temperfi mollis vel, laoreet temperfi",
    dpdisplay: "d-none"
  },
];


const ChatRoomV2 = ({ userTokenDetails, navToggleState, refresh }) => {
  const chat_type = localStorage.getItem("chat_type")
  const creatorId = localStorage.getItem('userId')
  const room_id = localStorage.getItem('chat_room_id')
  console.log('chat type', chat_type, creatorId)
  const [videocall, setVideocall] = useState(false);
  const [callend, setCallend] = useState(false);
  const [callchat, setCallchat] = useState(false);
  const [calendar, setCalender] = useState(false);
  const [chatList, setChatList] = useState([])
  const [selectedChat, setSelectedChat] = useState(0)
  const [detailsChat, setDetailsChat] = useState([])
  const [textMsg, setTextMsg] = useState("")
  const [tab, setTab] = useState("People");
  const [addNew, setAddNew] = useState(false)
  const [searchText, setSearchText] = useState(room_id ? room_id : '')
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 4)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [showLoader, setShowLoader] = useState(false)
  const videoRef = useRef(null)
  const [socketId, setSocketId] = useState('')
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);
  const Localpeers = useHMSStore(selectLocalPeer);
  const Remotepeers = useHMSStore(selectRemotePeers);
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);

  const [userId, setUserId] = useState(creatorId ? creatorId : userTokenDetails.user_id ? userTokenDetails.user_id : null)
  const [userName, setUserName] = useState(userTokenDetails.userName ? userTokenDetails.userName : null)
  const [userEmail, setuserEmail] = useState(userTokenDetails.email ? userTokenDetails.email : null)
  const [userAvatar, setuserAvatar] = useState(userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null)
  const [userTypeId, setuserTypeId] = useState(userTokenDetails.type_id ? userTokenDetails.type_id : null)

  const [data, setData] = useState({
    meetingDate: new Date(),
    selectedUsers: [],
    selectedUser: creatorId ? creatorId : null
  })
  const [errors, setErrors] = useState({})
  const [userList, setUserList] = useState([])
  const [dbdata, setDbdata] = useState([])
  console.log('UseRtyesdaa', userTokenDetails);
  const [checked, setChecked] = useState({});
  const [calendarList, setCalendarList] = useState([])





  const getCalendarList = () => {
    setShowLoader(true)
    let reqObj = {
      userId,
      date: moment(data.meetingDate).format('YYYY-MM-DD')
    }
    call('POST', 'getCalendarList', reqObj).then(result => {
      setShowLoader(false)
      setCalendarList(formatCalendarEvents(result))
    }).catch(e => {
      setShowLoader(false)

    })
  }
  const formatCalendarEvents = (data) => {
    let events = []
    data.forEach(item => {
      events.push({
        ...item,
        start: moment(item.meetingstartTime).toDate(),
        end: moment(item.meetingEndTime).toDate(),
        title: item.meetingTitle
      })
    })
    return events
  }
  useEffect(() => {
    getCalendarList()
  }, [data.meetingDate])
  useEffect(() => {
    if (chat_type === "Users") {
      getUsersList()
    }
  }, [])
  const handleChange = (e, item, key) => {
    e.preventDefault()
    let temp = checked
    if (temp[item[key]]) {
      delete temp[item[key]]
    } else {
      temp[item[key]] = item
    }
    setChecked(temp);
    console.log('clickedon', item)
  };
  const getUserFeed = () => {
    navigator.mediaDevices.getUserMedia({ video: { height: 1920, width: 1080 } }).then(stream => {
      let video = videoRef.current
      console.log('streammmm', stream)
      video.srcObject = stream
      video.play()
    }).catch(e => {
      console.log('error')
    })
  }
  const getSocketId = () => {
    const selectedObj = chatList[selectedChat]
    let senderId = selectedObj.senderId == userId ? selectedObj.receiverId : selectedObj.receiverId
    call('POST', 'getuserSocketId', { userId: senderId }).then(result => {
      console.log('success in getuserSocketId', result)
      setSocketId(result)
    }).catch(e => {
      console.log('Error in getuserSocketId', e)
    })
  }
  const getUsersList = () => {
    call('POST', 'getUsersList', {}).then(result => {
      setDbdata(result)
      setUserList(result)
    }).catch(e => {

    })
  }
  const getChatList = (chat_type) => {
    setShowLoader(true)
    call('POST', 'getChatsForUser', { userId, chat_type, search: searchText }).then(result => {
      console.log('success in getChatsForUser', result)
      setChatList(result)
      getChatDetails({
        chat_room_id: result[selectedChat].chat_room_id,
        loggedInUser: userId
      })
      setShowLoader(false)
    }).catch(e => {
      console.log('Error in getChatsForUser', e)
      setShowLoader(false)
    })
  }
  useEffect(() => {
    getChatList(tab === 'People' ? 'Private' : 'Group')
  }, [])

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)

      }
      setDetailsChat(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }
  const sendChatMsg = (file) => {
    const selectedObj = chatList[selectedChat]
    const receivers = selectedObj.receiverId.split(',')

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', receivers.includes(`${userId}`) ? receivers.filter(ids => ids !== `${userId}`).concat(selectedObj.senderId).join(',') : selectedObj.receiverId)
    if (textMsg) {
      formdata.append('textMessage', textMsg)
    }
    formdata.append('chat_room_name', selectedObj.chat_room_name)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  useEffect(() => {
    getChatList(tab === 'People' ? 'Private' : 'Group')

  }, [refresh])
  useEffect(() => {
    const selectedObj = chatList[selectedChat]
    console.log('SelectedChat', selectedObj)
    if (selectedObj) {
      getChatDetails({
        chat_room_id: selectedObj.chat_room_id,
        loggedInUser: userId
      })
    }
  }, [selectedChat])
  console.log('SelectedChat', chatList[selectedChat], selectedChat)
  const onSearchPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      console.log('onsearch')
      getChatList(tab === 'People' ? 'Private' : 'Group')
    }
  }

  const onJoinMeeting = async (room_id, hostId, call_type) => {
    setShowLoader(true)
    try {
      let reqObj = {
        room_id: room_id,
        role: userId === hostId ? 'host' : 'guest',
        userId: userId
      }
      const res = await call('POST', 'get100msToken', reqObj)
      console.log('responsee', res)
      const config = {
        userName: userName,
        authToken: res, // client-side token generated from your token service
        settings: {
          isAudioMuted: false,
          isVideoMuted: false,
        },
        //metaData: JSON.stringify({ city: 'Winterfell', knowledge: 'nothing' }),
        rememberDeviceSelection: true,  // remember manual device change
      }
      await hmsActions.join(config)
      setVideocall(true)
      setShowLoader(false)
    } catch (e) {
      setShowLoader(false)
    }
  }
  function VideoTile({ peer }) {
    const { videoRef } = useVideo({
      trackId: peer.videoTrack
    });

    return <video ref={videoRef} autoPlay muted playsInline style={{ width: '100%', height: 'auto' }}></video>;
  }
  async function toggleAudio() {
    await hmsActions.setLocalAudioEnabled(!audioEnabled);
  }

  async function toggleVideo() {
    await hmsActions.setLocalVideoEnabled(!videoEnabled);
  }
  const handleFilterOptions = (typedInput) => {
    let filtered = []
    filtered = dbdata.filter((i) => {
      if (i.userName && i.userName?.toLowerCase()?.includes(typedInput?.toLowerCase())) {
        return i
      }
    })
    setUserList(filtered)
  };
  const handleMultiSelect = async (e) => {
    let selectedUser = e[0]
    setData({
      ...data, ...selectedUser, selectedUser: selectedUser?.user_id
    })
    setShowLoader(true)
    const result = e[0]
    if (result) {
      setShowLoader(false)
      setUserId(result.user_id)
      setUserName(result.userName)
      setuserEmail(result.email)
      setuserAvatar(result.user_avatar)
      setuserTypeId(result.type_id)
    } else {
      setShowLoader(false)
    }

  }
  useEffect(() => {
    getChatList(tab === 'People' ? 'Private' : 'Group')
  }, [userId])
  console.log('selectedUSerssss', data)
  return (
    <>

      <TutorialPopup show={lcTutorial} featureName={"Chat Room"} positioning={{
        ...tutorialPopupCSS[tutorialStage]["popup"]
      }} showSkip={true} userId={userId} showNext={true}
        onNext={() => {
          localStorage.setItem('lcTutorialStep', 5)
          window.location.reload()
        }}
        onBack={() => {
          localStorage.setItem("lcTutorialStep", 3)
          window.location.reload()
        }}
        showBack={true} videoLinkLabel={"Watch detailed video about Chat Room"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      <div className="">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

        <div className="row">
          <SideBarV2 state="ChatRoomV2" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div" style={{ height: "80.042vh" }}>
            <HeaderV2
              title={"Chat room"}
              userTokenDetails={userTokenDetails} />

            {chat_type === 'Users' &&
              <div class="d-flex justify-content-end">
                <div className="col-md-4">
                  <div className="col-md-12 px-0">
                    <MultipleSelect
                      Id=""
                      Label="Select User"
                      filterOption={() => true}
                      onInputChange={(e) => {
                        console.log('On autocomplete change input', e)
                        handleFilterOptions(e)
                      }}
                      optiondata={userList}
                      onChange={(e) => {
                        console.log('On autocomplete change', e)
                        handleMultiSelect(e, "id")
                      }}
                      value={data.selectedUser ? [data.selectedUser] : []}
                      name="selectedUser"
                      labelKey={"userName"}
                      valKey={"user_id"}
                      error={errors.selectedUser}
                    />
                  </div>
                </div>
              </div>
            }
            {!videocall && <div className='row' style={lcTutorial ? { position: 'relative', zIndex: -1 } : {}}>
              <div className='col-md-4'>
                {calendar ? <CalenderView calendar={calendar} setCalender={setCalender} userId={userId} setShowLoader={setShowLoader} data={data} setData={setData} userName={userName} chat_type={chat_type} getCalendarList={getCalendarList} /> : <ChatList calendar={calendar} setCalender={setCalender} chatList={chatList} selectedChat={selectedChat} setSelectedChat={setSelectedChat} getChatListData={(chat_type) => getChatList(chat_type)} setDetailsChat={setDetailsChat} setChatList={setChatList} getChatDetails={getChatDetails} userId={userId} getchatList={(type) => getChatList(type)} tab={tab} setTab={setTab} addNew={addNew} setAddNew={setAddNew} checked={checked} handleChange={handleChange} userEmail={userEmail} userName={userName} userTypeId={userTypeId} searchText={searchText} onSearchPress={onSearchPress} setSearchText={setSearchText} chat_type={chat_type} />}
              </div>
              <div className='col-md-8 '>
                {calendar ? <>
                  <div className="card border-0 chatlist h-90 rounded-0">
                    <ScheduleList userId={userId} setShowLoader={setShowLoader} meetingDate={data.meetingDate} data={data} setData={setData} onMeetPress={(room_id, hostId, call_type) => onJoinMeeting(room_id, hostId, call_type)} calendarList={calendarList} />
                  </div>
                </> :
                  addNew && tab === 'Group'
                    ?
                    <div className="card border-0 chatlist h-100 borderRadius">
                      <ChatBodyGroup recievedchatdata={detailsChat} userId={userId} selectedUser={chatList[selectedChat]} checked={checked} goback={() => {
                        setAddNew(false)
                        getChatList('Group')
                      }} />
                    </div>
                    :
                    <div className="card border-0 chatlist h-90 rounded-0">
                      <div className='card-header bg-95E7FF p-3 rounded-0 chatlist d-flex justify-content-between'>
                        <ChatHeader videocall={videocall} setVideocall={() => {
                          setVideocall(true)
                          //getUserFeed()
                        }} selectedUser={chatList[selectedChat]} chat_type={chat_type} />
                      </div>
                      <div className='card-body'>
                        <ChatBody recievedchatdata={detailsChat} userId={userId} selectedUser={chatList[selectedChat]} userAvatar={userAvatar} />
                      </div>
                      <div className='card-footer rounded-0 d-flex bg-transparent align-items-center chatfooter'>
                        <ChatFooter message={textMsg} setTextMsg={setTextMsg} sendChatMsg={sendChatMsg} />
                      </div>
                    </div>

                }

              </div>
            </div>}
            {videocall &&
              <>
                <div className="card p-4 borderRadius videocallcard h-100">
                  {!callchat && <><p onClick={() => {
                    setVideocall(false)
                    hmsActions.leave()

                  }} className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></p>
                    <div className='card col-md-10 mx-auto pb-5 my-3 border-0 borderRadius bg-EEEEEE px-0 text-center h-90'>
                      <div>
                        {Remotepeers.map((peer) => (
                          <VideoTile key={peer.id} peer={peer} />
                        ))}
                        <div className='d-flex gap-3 my-3 align-items-center justify-content-center cursor vcActions' >
                          <div><img src={"/assets/images/bxs_microphone.png"} alt="" onClick={() => toggleAudio()} /></div>
                          <div><img src={"/assets/images/fa6-solid_video.png"} alt="" onClick={() => toggleVideo()} /></div>
                          <div>
                            {<img onClick={() => {
                              setCallend(true)
                              setVideocall(false)
                              hmsActions.leave()
                            }} src={"/assets/images/end call.png"} alt="" />}
                          </div>
                          <div><img onClick={() => setCallchat(true)} src={"/assets/images/bi_chat-left-dots-fill.png"} alt="" /></div>

                        </div>
                        {/* <img src={'/assets/images/callframe.png'} alt='' height={150} /> */}
                      </div>
                      <div className='col-md-4 ms-auto card border-0  videoframe text-center bg-F8F8F8' style={{ borderRadius: "0px 10px", position: 'absolute', right: 0 }}>
                        {Localpeers &&
                          <VideoTile key={"1"} peer={Localpeers} />
                        }
                        {/* <video ref={myVideo} /> */}

                        {/* <img src={'/assets/images/callframe.png'} alt='' height={120} /> */}

                      </div>
                    </div>
                  </>}
                  {callchat &&
                    <div>
                      <div className='col-md-9'>
                        <a onClick={() => setVideocall(false)} className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                        <div className='card col-md-10 mx-auto pb-5 my-3 border-0 borderRadius bg-EEEEEE px-0 text-center'>
                          <div className='col-md-4 ms-auto card border-0 p-3 videoframe text-center bg-F8F8F8' style={{ borderRadius: "0px 10px" }}>
                            <div><img src={'/assets/images/callframe.png'} alt='' height={120} /></div>
                          </div>
                          <div className='pb-5'><img src={'/assets/images/callframe.png'} alt='' height={150} /></div>

                        </div>
                        <div className='d-flex gap-3 my-3 align-items-center justify-content-center cursor'>
                          <div><img src={"/assets/images/bxs_microphone.png"} alt="" /></div>
                          <div><img src={"/assets/images/fa6-solid_video.png"} alt="" /></div>
                          <div>
                            {!callend && <img onClick={() => setCallend(true)} src={"/assets/images/end call.png"} alt="" />}
                            {callend && <img onClick={() => setCallend(false)} src={"/assets/images/callendhover.png"} alt="" />}
                          </div>
                          <div><img onClick={() => setCallchat(true)} src={"/assets/images/bi_chat-left-dots-fill.png"} alt="" /></div>

                        </div>
                      </div>
                      <div className='col-md-3 card ms-auto border-0 position-absolute end-0 top-0 h-100 chatoption p-0'>
                        <div className='text-end'><img onClick={() => setCallchat(false)} className='cursor pe-2' src={'/assets/images/cancel-icon.png'} alt='' /> </div>
                        <div className='mt-auto mb-3'>
                          <div className='messages d-flex w-95 justify-content-start gap-2 align-items-center px-2'>
                            <div className='w-auto '>
                              <div className="img_cont">
                                <img src={"/assets/images/chat-dp.png"} className={`rounded-circle user_img `} />
                              </div>
                            </div>
                            <div className='w-auto messagesend d-flex p-2 gap-2'>
                              <div className='w-100 message '>
                                <p className='mb-0'>Consectetur malesuada mollis vel, laoreet tempor. </p>
                              </div>
                              <div className='w-auto mt-auto '>
                                <p className='text-secondary font-size-12 mb-0 '>19:24</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-footer rounded-0 d-flex bg-transparent align-items-center chatfooter '>
                          <ChatFooter />
                        </div>
                      </div>
                    </div>


                  }
                </div>

              </>
            }
          </main >
        </div >
      </div >

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    refresh: state.chatRefresh.refresh
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ChatRoomV2)