import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, finLogoByUserId, GetCache, getDocDetails, getFinancerAvailability, most_used_currencies, SetCache, toolTip } from "../../utils/myFunctions";
import call from '../../service';
import config from '../../config.json';
import { InputWithSelect, NewInput, NewSelect } from "../../utils/newInput";
import { FileInput } from "../../utils/FileInput";
import SelectBuyerModal from "./components/selectBuyerModal";
import SignDocument from "./components/SignDocument";
import { ToastContainer } from "react-toastify";
import TutorialPopup from "../tutorialPopup";
import moment from "moment";
import TooltipModal from "./components/Tooltipmodal";
import MultipleSelect from "../../utils/MultipleSelect";
import { environment, platformURL } from "../../urlConstants";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { NewTable } from "../../utils/newTable";
import avatarUrl from "../../utils/makeAvatarUrl";
import { documentTable, documentTable2, documentTable3 } from "../Dashboard/TableJson";
import NewTablev2 from "../../utils/newTablev2";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import ReactSwitch from "react-switch";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

const tutorialPopupCSS = {
  "0": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Enter required limit for you & click on submit.</span></label>
  }
}

const addBuyerTabs = [
  { name: "Buyer details" },
  { name: "Documents" },
  { name: "Select financer" },
  { name: "Review" }
]
const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "Buyer Annual Turnover", val: "annualTurnOver" }

]

const reviewForm2 = [
  {
    name: "Buyer Name",
    value: "XYZ Pvt. Ltd.",
  },
  { name: "Previous year annual sale", value: "NIL" },

  { name: "Contact Number", value: "+91 85694 25634", },
  {
    name: "Expected current year’s annual sale",
    value: "$ 30,00,000"
  },
  { name: "Email ID", value: "xyzpvtltd@gmail.com", },
  {
    name: "Inco Terms",
    value: "$ 32,00,000"
  },
  { name: "Website", value: "www.xyzpvtltd.com", },
  {
    name: "Terms of Payment",
    value: "30 Days"
  },
  {
    name: "Address", value: "Manufacturing Solutions No.1, Huazheng Street Suzhou,Mumbai, Maharashtra,India - 400078",
  },
  {
    name: "Product details",
    value: "Accent Lumispa White Clear Linc Cbhu, Tpc Auto Nvocc Banqsha,  Lumispa Dock Spare Ass Pkgs."
  },
  { name: "Industry Type", value: "Packaging ", },
  {
    name: "HSN Code",
    value: "8509.90 ; 5896.12 ; 8549.45 ; 9856"
  },
  { name: "DUNS No.", value: "458965234751265", },

];

const reviewLabels = [
  { name: 'Total Finance Application', color: "#1B94B7", alt: "totalFinanceApplied" },
  { name: 'Application Rejected', color: '#FE4141', alt: "applicationRejected" },
  { name: 'Application Approved', color: '#00B74D', alt: "applicationApproved" },
]

const ApplyLimitComponent = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const adminINVApply = queryParams.get("adminINVApply") ? true : false
  const [dbData, setDbData] = useState([])
  const [userdata, setUserdata] = useState([])
  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(true);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ limitRequiredCurrency: "USD", currentDocuments: true })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [tooltip, setTooltip] = useState(false)
  const [tooltipdata, setTooltipdata] = useState({
    lenderName: '',
    remarks: ''
  })

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [tempValue, setTempValue] = useState("")
  const [docNamePicker, toggleDocNamePicker] = useState({ show: false, id: null })
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [seeReview, setSeeReview] = useState({})

  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 8)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [finList, setFinList] = useState([])
  const [buyerdata, setBuyerdata] = useState([])
  const [multInvPo, setMultInvPo] = useState([{ purchase_orders: [null], invoices: [null] }])
  const [tableDocsData, setTableDocsData] = useState([])


  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false
  })
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')

  const [overlay, setOverlay] = useState(false);
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const user_avatar = userToken.userAvatar ? userToken.userAvatar : null

  useEffect(() => {
    formatInvPoDocsForReview()
  }, [data])

  async function formatInvPoDocsForReview() {
    let row = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      row.push(["Invoice Details " + (index + 1)])
      for (let j = 0; j < element.invoices.length; j++) {
        if (data?.["invoiceDocument" + index + ":" + j]?.name) {
          row.push([`Invoice - ${data["invoiceRefrenceNo" + index + ":" + j]}`, `${data["currency" + index + ":" + j]} ${data["contractAmount" + index + ":" + j]}`,
          <FileInput
            isEditable={false}
            value={data["invoiceDocument" + index + ":" + j]}
          />,
          <>
            {
              !data?.["invoiceDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["invoiceDocument" + index + ":" + j], docSignName: ["invoiceDocument" + index + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["invoiceDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      for (let j = 0; j < element.purchase_orders.length; j++) {
        if (data?.["poDocument" + index + ":" + j]?.name) {
          row.push([`PO - ${data["poRefrenceNo" + index + ":" + j]}`, `-`,
          <FileInput
            isEditable={false}
            value={data["poDocument" + index + ":" + j]}
          />,
          <>
            {
              !data?.["poDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["poDocument" + index + ":" + j], docSignName: ["poDocument" + index + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["poDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      if (row.length) {
        let lastElementInArr = row[row.length - 1]
        lastElementInArr[100] = "To Add Line"
      }
    }
    setTableDocsData([...row])
  }

  useEffect(() => {
    if (buyer && dbData.length) {
      let event = {
        target: {
          name: "selectedBuyer",
          value: buyer
        }
      }
      handleChange(event)
    }
  }, [buyer, dbData])
  const getBuyerDetails = () => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      setDbData([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData]);
      setBuyerdata([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData])
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)

    });
  }

  useEffect(() => {

    //Get Buyer list start
    getBuyerDetails()
    call('POST', 'getUserdataForCredit', { userId }).then(result => {
      setUserdata(result)
    }).catch(e => {
      {
        console.log('error in getUserdataForCredit', e)
      }
    })
    //Get buyer list end

    // Get master data start
    // call('get', 'getallCountry').then((result) => {
    //   setCountrys(result)
    // }).catch((e) => {
    //   console.log('error in getallCountry', e);
    // })
    // call('get', 'getHsnCodes').then((result) => {
    //   setHsnCodes(result)
    // }).catch((e) => {
    //   console.log('error in getHsnCodes', e);
    // })
    // call("get", "getcurrencylist").then((result) => {
    //   setcurrencyData(result);
    // }).catch((e) => {
    //   console.log("error in getcurrencylist", e);
    // });
    // call("POST", "getLenderDetails").then((result) => {
    //   setFinancierData(result);
    // }).catch((e) => {
    //   console.log("error in getLenderDetails", e);
    // });
    // Get master data end
    setOverlay(true);
  }, [])
  const getFinancersForInvoice = async (buyerdata) => {
    setshowLoader(true)
    let exporterTurnOver = await call('POST', 'getUserDetailsFromIEC', { userId })
    exporterTurnOver = exporterTurnOver.fobUsd
    call('POST', "getFinanciersForInvoice", {}).then(async res => {
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        // element["logo"] = finLogoByUserId[element["id"]]
        element["logo"] = element.user_avatar || null
        console.log('userdataaaaaaa', {
          ...userdata,
          ...buyerdata,
          exporterTurnOver
        })
        const financerAvailability = await getFinancerAvailability(element, 'invoice', {
          ...userdata,
          ...buyerdata,
          exporterTurnOver
        })
        console.log('Financerdata', financerAvailability)
        element["isChecked"] = financerAvailability.isAvailable
        element["isAvailable"] = financerAvailability.isAvailable
        element["info_text"] = financerAvailability.info_text
        element["isRecommened"] = financerAvailability.isAvailable
      }
      console.log("inside getFinanciersForInvoice", res);
      setFinList(res)
      setshowLoader(false)
    }).catch(err => setshowLoader(false))
  }

  const hideBuyerModal = () => {
    setModal(false);
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "limitRequired") {
      setOverlay(false);
      getFinancersForInvoice({
        ...data,
        limitCurrency: data.limitRequiredCurrency,
        limitRequired: event.target.value
      })
    }
    if (event.target.name === 'limitRequiredCurrency') {
      getFinancersForInvoice({
        ...data,
        limitCurrency: event.target.value,
        limitRequired: data.limitRequired
      })
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData.filter(item => {
        if (item.id == event.target.value) {
          return item
        }
      })[0]
      if (selectedBuyer?.ttvId && selectedBuyer.isPromotedToEdit == 0) {
        window.location = `/buyerManagement?editBuyer=${selectedBuyer.id}`
        localStorage.setItem('redirectBackTo', `/applyLimit?buyer=${selectedBuyer.id}&purpose=invoice_discounting`)
      }
      getFinancersForInvoice({
        ...selectedBuyer,
        limitCurrency: data.limitRequiredCurrency,
        limitRequired: data.limitRequired
      })
      let buyerRelatedDocs = selectedBuyer.buyersDocId?.split(":") || []
      let invoiceDocument = await getDocDetails(buyerRelatedDocs[0])
      let poDocument = await getDocDetails(buyerRelatedDocs[1])
      let logisticDocument = await getDocDetails(buyerRelatedDocs[2])
      let invReportDocument = await getDocDetails(buyerRelatedDocs[3])
      let grnDocument = await getDocDetails(buyerRelatedDocs[4])
      let packingListDocument = await getDocDetails(buyerRelatedDocs[5])
      let shippingBillDocument = await getDocDetails(buyerRelatedDocs[6])
      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        invoiceDocument, poDocument, logisticDocument, invReportDocument, grnDocument, packingListDocument, shippingBillDocument
      })
      hideBuyerModal()
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  console.log('ShowBuyerrrr', data)
  // function getDunsList() {
  //   setData({
  //     ...data, selectedDuns: null,
  //     buyerAddress: "",
  //     buyerPostalCode: "", buyerDunsNo: ""
  //   })
  //   setshowLoader(true)
  //   call('POST', 'getDUNSNo', {
  //     companyName: data.buyerName, countryCode: data.buyerCountry,
  //     userId, userEmail
  //   }).then((result) => {
  //     setshowLoader(false)
  //     if (result.length > 0) {
  //       setDunsData(result);
  //       setTab(1)
  //     }
  //     else {
  //       toastDisplay("No Related Buyer Details Found", "error")
  //       setTab(2)
  //       setDunsData([])
  //     }
  //   })
  // }

  // async function submitBuyerDetails() {
  //   setshowLoader(true)
  //   let formData = new FormData();
  //   formData.append("buyerName", data.buyerName)
  //   formData.append("buyerCountry", data.buyerCountry)
  //   formData.append("buyerDUNSNo", data.buyerDunsNo)
  //   formData.append("buyerAddress", data.buyerAddress)
  //   formData.append("buyerPostalcode", data.buyerPostalCode)
  //   formData.append("buyerWebsite", data.buyerWebsite)
  //   formData.append("buyerEmail", data.buyerEmail)
  //   formData.append("buyerContact", data.buyerContact)
  //   formData.append("annualSale", data.buyerPrevSale)
  //   formData.append("expectedAnnualSale", data.buyerExpectedSale)
  //   let buyerCurrency = most_used_currencies.filter(item => item.code === data.buyerCurrency)[0]
  //   formData.append("buyerCurrency", buyerCurrency["id"] + ":" + buyerCurrency["code"])
  //   formData.append("incoTerms", data.buyerIncoTerms)
  //   formData.append("payment", data.buyerTermsPayment)
  //   formData.append("productDetail", data.buyerProductDetails)
  //   formData.append("userId", userId)
  //   formData.append("userEmail", userEmail)
  //   formData.append("userName", userName)
  //   formData.append("baseUrl", config.baseUrl)
  //   formData.append("previousInvoiceDocument", data.invoiceDocument)
  //   formData.append("previousPODocument", data.poDocument)
  //   //Additional fields
  //   formData.append("buyerHsnCode", data.buyerHsnCode.length ? data.buyerHsnCode.join(",") : "")
  //   formData.append("buyerNameTitle", data.buyerNameTitle)
  //   formData.append("buyerPhoneCode", data.buyerPhoneCode)
  //   call('POST', "insertBuyersDetail", formData).then((result) => {
  //     setshowLoader(false)
  //     toggleMsgPopup(true)
  //   }).catch(err => {
  //     // console.log("insertBuyersDetail error:", err)
  //     setshowLoader(false)
  //     toastDisplay("Something went wrong", "error");
  //   })
  // }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}

    if (tab === 1) {
      if (!(data.currentDocuments || data.pastDocuments)) {
        err["currentDocuments"] = "mandatory"
        toastDisplay('Kindly select document status', "info")
      }
      if (!data.salesContractDocument?.name && !data.salesContractNotAvailable) {
        err["salesContractDocument"] = "Mandatory"
      }
      for (let index = 0; index < multInvPo.length; index++) {
        const element = multInvPo[index];
        for (let j = 0; j < element.invoices.length; j++) {
          if (data?.["invoiceDocument" + index + ":" + j]?.name) {
            // if (!data?.["invoiceRefrenceNo" + index + ":" + j]) {
            //   err["invoiceRefrenceNo" + index + ":" + j] = "Enter Invoice Number"
            // }
            // if (!data?.["currency" + index + ":" + j]) {
            //   err["currency" + index + ":" + j] = "Select Currency"
            // }
            // if (!data?.["contractAmount" + index + ":" + j]) {
            //   err["contractAmount" + index + ":" + j] = "Enter Invoice Amount"
            // }
          }
        }
        for (let j = 0; j < element.purchase_orders.length; j++) {
          if ((data.currentAnnualSale || data.previousAnnualSale) && !data?.["poDocument" + index + ":" + j]?.name) {
            err["poDocument" + index + ":" + j] = "Po Document is mandatory"
          }
          if (data?.["poDocument" + index + ":" + j]?.name) {
            // if (!data?.["poRefrenceNo" + index + ":" + j]) {
            //   err["poRefrenceNo" + index + ":" + j] = "Enter PO Number"
            // }
          }
        }
      }
    }

    if (tab === 2) {
      let isFinSelected = false
      for (let index = 0; index < finList.length; index++) {
        const element = finList[index];
        if (element.isChecked) {
          isFinSelected = true
        }
      }
      if (!isFinSelected) {
        err["selectFinancer"] = "Mandatory"
        toastDisplay("Kindly select atleast 1 financer", "info")
      }
    }

    if (tab == 3 && !data.tcAccepted) {
      err["tcAccepted"] = "Mandatory"
      toastDisplay("Kindly accept terms & conditions, to continue", "info")
    }

    if (tab === 0) {
      if (!data.selectedBuyer) {
        toastDisplay("Select buyer to continue", "error")
      }
      else if (!data.limitRequired) {
        err["limitRequired"] = "Mandatory field"
      }
      else if (!data.expShipmentDate) {
        err["expShipmentDate"] = "Mandatory field"
      }
    }

    if (!Object.keys(err).length) {

      if (tab == 3) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("userEmail", userEmail)
        formData.append("userId", userId)
        formData.append("buyerId", data.selectedBuyer)
        formData.append("limitRequired", data.limitRequired)
        formData.append("limitRequiredCurrency", data.limitRequiredCurrency)
        formData.append("userName", userName)
        formData.append("expShipmentDate", data.expShipmentDate)
        formData.append("buyersDocId", data.buyersDocId)
        formData.append("bankList", JSON.stringify(finList))

        for (let index = 0; index < multInvPo.length; index++) {
          const element = multInvPo[index];
          for (let j = 0; j < element.invoices.length; j++) {
            if (data["invoiceDocument" + index + ":" + j]?.name) {
              formData.append("invoiceDocument" + index + ":" + j, data["invoiceDocument" + index + ":" + j])
              if (data["invoiceDocument" + index + ":" + j]?.signatureId) {
                formData.append("invoiceRefrenceNo" + index + ":" + j + "SignId", data["invoiceRefrenceNo" + index + ":" + j].signatureId)
              }
              formData.append("invoiceRefrenceNo" + index + ":" + j, data["invoiceRefrenceNo" + index + ":" + j])
              formData.append("currency" + index + ":" + j, data["currency" + index + ":" + j])
              formData.append("contractAmount" + index + ":" + j, data["contractAmount" + index + ":" + j])
            }
          }
          for (let j = 0; j < element.purchase_orders.length; j++) {
            if (data["poDocument" + index + ":" + j]?.name) {
              formData.append("poDocument" + index + ":" + j, data["poDocument" + index + ":" + j])
              if (data["poDocument" + index + ":" + j]?.signatureId) {
                formData.append("poDocument" + index + ":" + j + "SignId", data["poDocument" + index + ":" + j].signatureId)
              }
              formData.append("poRefrenceNo" + index + ":" + j, data["poRefrenceNo" + index + ":" + j])
              formData.append("poAmount" + index + ":" + j, data["poAmount" + index + ":" + j])
            }
          }
        }

        if (data?.logisticDocument?.name && !data?.logisticDocument?.fromDb) {
          formData.append("logisticDocument", data.logisticDocument)
        }
        if (data?.invReportDocument?.name && !data?.invReportDocument?.fromDb) {
          formData.append("invReportDocument", data.invReportDocument)
        }
        if (data?.grnDocument?.name && !data?.grnDocument?.fromDb) {
          formData.append("grnDocument", data.grnDocument)
        }
        if (data?.packingListDocument?.name && !data?.packingListDocument?.fromDb) {
          formData.append("packingListDocument", data.packingListDocument)
        }
        if (data?.shippingBillDocument?.name && !data?.shippingBillDocument?.fromDb) {
          formData.append("shippingBillDocument", data.shippingBillDocument)
        }
        if (data?.salesContractDocument?.name && !data?.salesContractDocument?.fromDb) {
          formData.append("salesContractDocument", data.salesContractDocument)
        }
        if (data?.invoiceDocument?.signatureId) {
          formData.append("invoiceDocSignId", data.invoiceDocument.signatureId)
        }
        if (data?.poDocument?.signatureId) {
          formData.append("poDocSignId", data.poDocument.signatureId)
        }
        if (data?.logisticDocument?.signatureId) {
          formData.append("logisticDocumentSignId", data.logisticDocument.signatureId)
        }
        if (data?.invReportDocument?.signatureId) {
          formData.append("invReportDocumentSignId", data.invReportDocument.signatureId)
        }
        if (data?.grnDocument?.signatureId) {
          formData.append("grnDocumentSignId", data.grnDocument.signatureId)
        }
        if (data?.packingListDocument?.signatureId) {
          formData.append("packingListDocumentSignId", data.packingListDocument.signatureId)
        }
        if (data?.shippingBillDocument?.signatureId) {
          formData.append("shippingBillDocumentSignId", data.shippingBillDocument.signatureId)
        }
        if (data?.salesContractDocument?.signatureId) {
          formData.append("salesContractDocumentSignId", data.salesContractDocument.signatureId)
        }
        if (data.currentDocuments) {
          formData.append("documentStatus", "currentDocuments")
        }
        else if (data.pastDocuments) {
          formData.append("documentStatus", "pastDocuments")
        }
        call('POST', 'applyForBuyerLimitV3', formData).then((result) => {
          // console.log('running applyForBuyerLimitV2 api-->', result);
          setshowLoader(false)
          if (lcTutorial) {
            localStorage.setItem("lcTutorialStep", 1)
          }
          toastDisplay("Applied for buyer limit successfully", "success",
            () => {
              // window.location = "/Quotes"
              window.location = `/extraDetails?buyerId=${data.selectedBuyer}${adminINVApply ? '&adminINVApply=true' : ''}`
            })
        }).catch((e) => {
          // console.log('error in applyForBuyerLimitV2', e);
          setshowLoader(false)
          toastDisplay(e, "error")
        });
      }
      else {
        setTab(tab + 1)

      }
    }
    setErrors(err)
  }

  function handleFinSelection(index) {
    let temp = finList
    temp[index]["isChecked"] = !temp[index]["isChecked"]
    setFinList([...temp])
  }

  const handleFilterOptions = (typedInput) => {
    // console.log("typedInput", typedInput);
    let tempPort = []
    let filtered = []
    tempPort = [{ id: "addNewBuyer", buyerName: "Add New Buyer", typedInput }]
    filtered = dbData.filter((i) => {
      if (i.buyerName && i.buyerName?.toLowerCase()?.includes(typedInput?.toLowerCase())) {
        return i
      }
    })
    if (!filtered.length) {
      filtered = tempPort
    }
    setBuyerdata(filtered)
  };
  const handleMultiSelect = async (e, name, val) => {
    if (e?.[0]?.id === "addNewBuyer") {
      return window.open(`${platformURL}/buyerManagement?action=addNewBuyer`, "_blank")
    } else {
      let selectedBuyer = e[0]
      if (selectedBuyer?.ttvId && selectedBuyer.isPromotedToEdit == 0) {
        window.location = `/buyerManagement?editBuyer=${selectedBuyer.id}`
        localStorage.setItem('redirectBackTo', `/applyLimit?buyer=${selectedBuyer.id}&purpose=invoice_discounting`)
      }
      getFinancersForInvoice({
        ...selectedBuyer,
        limitCurrency: data.limitRequiredCurrency
      })
      let buyerRelatedDocs = selectedBuyer.buyersDocId?.split(":") || []
      let invoiceDocument = await getDocDetails(buyerRelatedDocs[0])
      let poDocument = await getDocDetails(buyerRelatedDocs[1])
      let logisticDocument = await getDocDetails(buyerRelatedDocs[2])
      let invReportDocument = await getDocDetails(buyerRelatedDocs[3])
      let grnDocument = await getDocDetails(buyerRelatedDocs[4])
      let packingListDocument = await getDocDetails(buyerRelatedDocs[5])
      let shippingBillDocument = await getDocDetails(buyerRelatedDocs[6])
      setshowLoader(false)

      setData({
        ...data, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        invoiceDocument, poDocument, selectedBuyer: selectedBuyer.id, logisticDocument, invReportDocument,
        grnDocument, packingListDocument, shippingBillDocument
      })
      hideBuyerModal()
    }
  }
  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(result => {
      setChatList(result)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleFileWithAsyncAwait = async (event, poNo, poSubNo, prevData, tempMultInvPoLength) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setshowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setshowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }


  return (
    <>
      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div>

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <TutorialPopup show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"]
        }} showSkip={true} userId={userId}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: tooltipdata.remarks, showLenderName: tooltipdata.lenderName }} />}
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {selectedChat.isChatOpen &&
        <div className="chatboxDiv">
          <ChatBoxPopUp
            chatList={chatList}
            user_avatar={user_avatar}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            onChatSend={() => sendChatMsg()}
            message={message}
            setTextMsg={setTextMsg}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false
              })
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className={`${overlay && 'overlay_in'}`}></div>
        <div className="row">
          <SideBarV2 state="invoice_applyLimit" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for Limit"}
              userTokenDetails={userToken} />
            <a className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1"
                style={lcTutorial ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem 0rem 1rem 0rem",
                  "borderRadius": "1rem",
                } : {}}>

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row"
                    >
                      <div className="col-md-4 col-xl-3 col-lg-4 px-0" style={{ position: "relative", zIndex: "9" }} >
                        {/* <label className='font-wt-500 font-size-16 mb-4'><u>Limit required</u></label> */}
                        {/* <InputCurrency /> */}
                        <InputWithSelect isAstrix={true} type={"number"} label={"Enter Limit Required"}
                          selectData={currencyData}
                          selectName={"limitRequiredCurrency"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} InputFocus />
                      </div>

                      <div className="col-md-4 col-xl-3 col-lg-4 px-0 ml-5" style={{ position: "relative", zIndex: "9" }} >
                        <NewInput isAstrix={true} type={"date"} label={"Expected date of shipment"} minDate={moment().subtract(1, "month").format("YYYY-MM-DD")}
                          name={'expShipmentDate'} value={data.expShipmentDate} error={errors.expShipmentDate}
                          onChange={handleChange} InputFocus />
                      </div>


                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16 mb-3'><u>Buyer Details</u> <img
                          onClick={() => { window.location = `/buyerManagement?editBuyer=${data.selectedBuyer}` }}
                          className="ms-3 cursor"
                          src={"assets/images/edit.png"} alt="edit" />
                        </label>
                        {/* <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                        </div> */}
                        <div className="row">
                          {reviewForm.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* {data?.invoiceDocument?.name || data?.poDocument?.name ? (
                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u>
                          </label>
                          <div className='row mt-2'>
                            {data?.invoiceDocument?.name ? (
                              <div className="col-md-5 col-xl-4">
                                <label className="font-size-14">Invoice</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.poDocument?.name ? (
                              <div className="col-md-5 col-xl-4">
                                <label className="font-size-14">Purchase Order</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className='mb-3 d-flex pl-2 pt-2 align-items-center'>
                      {/* <label className="font-wt-600 font-size-16 pt-1 mr-4" >Document Status</label> */}
                      {/* <input type="radio" id="currentDocuments" name="currentDocuments"
                        checked={data.currentDocuments ? true : false}
                        onClick={() => {
                          setData({ ...data, ["currentDocuments"]: !data.currentDocuments, pastDocuments: false })
                        }}
                      /> */}
                      <label className={!data.currentDocuments ? "font-wt-500 font-size-16" :
                        "font-wt-800 font-size-16 color-2ECC71"} for="currentDocuments">Current Trade Documents</label>
                      {/* <input className="mr-3" type="radio" id="pastDocuments" name="pastDocuments"
                        checked={data.pastDocuments ? true : false}
                        onClick={() => {
                          setData({ ...data, ["pastDocuments"]: !data.pastDocuments, currentDocuments: false })
                        }} /> */}
                      {/* <ReactSwitch height={20} width={40}
                        onChange={() => setData({ ...data, currentDocuments: !data.currentDocuments, pastDocuments: !data.pastDocuments })}
                        checked={data.currentDocuments} /> */}
                      <img
                        className="cursor mx-3"
                        onClick={() => setData({ ...data, currentDocuments: !data.currentDocuments, pastDocuments: !data.pastDocuments })}
                        src={data.currentDocuments ? "assets/images/toggle_off.svg" : "assets/images/toggle_on.svg"}
                      />
                      <label className={!data.pastDocuments ? "font-wt-500 font-size-16" :
                        "font-wt-800 font-size-16 color-2ECC71"} for="pastDocuments">Past Trade Documents</label>
                      <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2 mb-2" />
                      <div style={{ top: '3rem' }} className="otherChargePopup p-3">
                        <div>
                          <label className="font-size-13 font-wt-600" ><u>Current Trade Documents:</u></label>
                          <label className="font-size-12 font-wt-500">Current trade documents are documents related to ongoing or future trade transactions</label>
                          <label className="font-size-13 font-wt-600" ><u>Past Trade Documents:</u></label>
                          <label className="font-size-12 font-wt-500">Past trade documents are records of completed trade transactions</label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-8 my-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div>
                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> */}

                    <div
                      className={"mx-1 px-2"}
                    >
                      <label
                        className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                      ><u>{`Invoice & Purchase Order`}</u></label>
                      <NewTablev2 columns={documentTable}>
                        {multInvPo.map((i, j) => {
                          return <>
                            {i.invoices?.map((o, p) => {
                              return (
                                <tr>
                                  <td>{"Invoice"}</td>
                                  <td><div>
                                    <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                      onMultipleDragDrop={async (e) => {
                                        let tempErr = errors
                                        let tempData = data
                                        for (let index = 0; index < e.target.files.length; index++) {
                                          let element = e.target.files[index];
                                          let customEvent = {
                                            target: { name: "invoiceDocument" + j + ":" + (p + index), files: [element] },
                                            persist: () => console.log("...")
                                          }
                                          let resp = await handleFileWithAsyncAwait(customEvent, j, p + index, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                          if (resp.status / 1 == 0) {
                                            tempErr = { ...tempErr, ...resp.msg }
                                          }
                                          if (resp.status / 1 == 1) {
                                            tempData = { ...tempData, ...resp.msg }
                                          }
                                        }
                                        setData(tempData)
                                        setErrors(tempErr)
                                        let temp = multInvPo
                                        for (let index = 0; index < e.target.files.length - 1; index++) {
                                          temp[j].invoices.push(null)
                                        }
                                        setMultInvPo([...temp])
                                      }}
                                      name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]} error={errors["invoiceDocument" + j + ":" + p]}
                                      isEditable={true} onChange={handleFile}
                                      onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                    />
                                    {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                      <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                  </div></td>
                                  <td><div className="position-relative">
                                    <NewInput isAstrix={false} type={"text"} label={""}
                                      name={"invoiceRefrenceNo" + j + ":" + p} value={data["invoiceRefrenceNo" + j + ":" + p]} error={errors["invoiceRefrenceNo" + j + ":" + p]}
                                      onChange={handleChange} />
                                  </div></td>
                                  <td><div className="position-relative">
                                    <InputWithSelect isAstrix={false} type={"number"} label={""}
                                      selectData={currencyData}
                                      selectName={"currency" + j + ":" + p} selectValue={data["currency" + j + ":" + p]}
                                      optionLabel={"code"} optionValue={'code'}
                                      name={'contractAmount' + j + ":" + p} value={data["contractAmount" + j + ":" + p]} error={errors["contractAmount" + j + ":" + p] || errors["currency" + j + ":" + p]}
                                      onChange={handleChange} />
                                  </div></td>
                                  <td>
                                    {!data["invoiceDocument" + j + ":" + p]?.signatureId ?
                                      <img className="cursor"
                                        onClick={() => { setSigndoc(data["invoiceDocument" + j + ":" + p]?.name); setData({ ...data, docToSign: data["invoiceDocument" + j + ":" + p], docSignName: "invoiceDocument" + j + ":" + p }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                      :
                                      <img style={{ width: '20px' }} className=""
                                        src={"assets/images/doc_sign_filled.svg"} alt="" />
                                    }
                                  </td>
                                  <td><div
                                    className="d-flex"
                                  >
                                    {data["invoiceDocument" + j + ":" + p]?.name ? (
                                      <>
                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data["invoiceDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data["invoiceDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                      </>
                                    ) : null}
                                    {(i.invoices.length - 1 > p) ?
                                      <img
                                        onClick={() => {
                                          let temp = multInvPo
                                          let tempUpdatedPo = []
                                          for (let m = 0; m < temp[j].invoices.length; m++) {
                                            if (m != p) {
                                              tempUpdatedPo.push(null)
                                            }
                                          }
                                          temp[j].invoices = tempUpdatedPo
                                          setMultInvPo([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = multInvPo
                                          temp[j].invoices.push(null)
                                          setMultInvPo([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </div></td>
                                </tr>
                              )
                            })}
                            {
                              i.purchase_orders?.map((o, p) => {
                                return (
                                  <tr>
                                    <td>{"Purchase Order"}</td>
                                    <td>
                                      <div>
                                        <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                          onMultipleDragDrop={async (e) => {
                                            let tempErr = errors
                                            let tempData = data
                                            for (let index = 0; index < e.target.files.length; index++) {
                                              let element = e.target.files[index];
                                              let customEvent = {
                                                target: { name: "poDocument" + j + ":" + (p + index), files: [element] },
                                                persist: () => console.log("...")
                                              }
                                              let resp = await handleFileWithAsyncAwait(customEvent, j, p + index, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                              if (resp.status / 1 == 0) {
                                                tempErr = { ...tempErr, ...resp.msg }
                                              }
                                              if (resp.status / 1 == 1) {
                                                tempData = { ...tempData, ...resp.msg }
                                              }
                                              // console.log("customEventtttttttttttttttt", customEvent, resp);
                                            }
                                            setData(tempData)
                                            setErrors(tempErr)
                                            let temp = multInvPo
                                            for (let index = 0; index < e.target.files.length - 1; index++) {
                                              temp[j].purchase_orders.push(null)
                                            }
                                            setMultInvPo([...temp])
                                          }}
                                          name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                          isEditable={true}
                                          // onChange={(e) => handleFile(e, j, p)}
                                          onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                        />
                                        {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                          <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                      </div>
                                    </td>
                                    <td><div className="position-relative">
                                      <NewInput isAstrix={false} type={"text"} label={""}
                                        name={"poRefrenceNo" + j + ":" + p} value={data["poRefrenceNo" + j + ":" + p]} error={errors["poRefrenceNo" + j + ":" + p]}
                                        onChange={(e) => handleChange(e, j, p)} />
                                    </div></td>
                                    <td><div className="position-relative">
                                      <InputWithSelect isAstrix={false} type={"number"} label={""}
                                        selectData={currencyData}
                                        selectName={"currency" + j + ":" + p} selectValue={data["currency" + j + ":" + p]}
                                        optionLabel={"code"} optionValue={'code'}
                                        name={'poAmount' + j + ":" + p} value={data["poAmount" + j + ":" + p]} error={errors["poAmount" + j + ":" + p] || errors["currency" + j + ":" + p]}
                                        onChange={handleChange} />
                                    </div></td>
                                    <td>
                                      {!data["poDocument" + j + ":" + p]?.signatureId ?
                                        <img className="cursor"
                                          onClick={() => { setSigndoc(data["poDocument" + j + ":" + p]?.name); setData({ ...data, docToSign: data["poDocument" + j + ":" + p], docSignName: "poDocument" + j + ":" + p }) }}
                                          src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                        :
                                        <img className=""
                                          style={{ width: '20px' }}
                                          src={"assets/images/doc_sign_filled.svg"} alt="" />
                                      }
                                    </td>
                                    <td><div
                                      className="d-flex"
                                    >
                                      {data["poDocument" + j + ":" + p]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["poDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["poDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                      {i.purchase_orders.length - 1 > p ?
                                        <img
                                          onClick={() => {
                                            let temp = multInvPo
                                            let tempUpdatedPo = []
                                            for (let m = 0; m < temp[j].purchase_orders.length; m++) {
                                              if (m != p) {
                                                tempUpdatedPo.push(null)
                                              }
                                            }
                                            temp[j].purchase_orders = tempUpdatedPo
                                            setMultInvPo([...temp])
                                          }}
                                          className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                        /> :
                                        <img
                                          onClick={() => {
                                            let temp = multInvPo
                                            temp[j].purchase_orders.push(null)
                                            setMultInvPo([...temp])
                                          }}
                                          style={{ width: '22px', height: '22px' }}
                                          className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />
                                      }
                                    </div></td>
                                  </tr>
                                )
                              })}
                          </>
                        })}

                      </NewTablev2>
                    </div>

                    <div
                      className={"mx-1 px-2"}
                    >
                      <label
                        className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                      ><u>{`Other Documents`}</u></label>
                      <NewTablev2 columns={documentTable2}>
                        <tr>
                          <td>Investigation Report</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "invReportDocument": null })}
                            />
                          </td>
                          <td>
                            {!data["invReportDocument"]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data["invReportDocument"]?.name); setData({ ...data, docToSign: data["invReportDocument"], docSignName: "invReportDocument" }) }}
                                src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data["invReportDocument"]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["invReportDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["invReportDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Bill of Lading / Airway Bill</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "logisticDocument": null })}
                            />
                          </td>
                          <td>
                            {!data["logisticDocument"]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data["logisticDocument"]?.name); setData({ ...data, docToSign: data["logisticDocument"], docSignName: "logisticDocument" }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data["logisticDocument"]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["logisticDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["logisticDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Certificate of Origin</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "grnDocument": null })}
                            />
                          </td>
                          <td>
                            {!data["grnDocument"]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data["grnDocument"]?.name); setData({ ...data, docToSign: data["grnDocument"], docSignName: "grnDocument" }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data["grnDocument"]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["grnDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["grnDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>

                        <tr>
                          <td><div>
                            <label>Sales Contract</label>
                            <div className="align-self-center">
                              <img
                                onClick={() => {
                                  setData({ ...data, "salesContractNotAvailable": !data.salesContractNotAvailable })
                                }}
                                className='cursor mr-3' src={`assets/images/${data.salesContractNotAvailable ? 'checked-green' : 'empty-check'}.png`} />
                              <label>Not Available</label>
                            </div>
                          </div></td>
                          <td>
                            <FileInput hideViewDowdBtns={true} name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "salesContractDocument": null })}
                            />
                            {errors["salesContractDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["salesContractDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data["salesContractDocument"]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data["salesContractDocument"]?.name); setData({ ...data, docToSign: data["salesContractDocument"], docSignName: "salesContractDocument" }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data["salesContractDocument"]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["salesContractDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["salesContractDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Shipping Bill</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "shippingBillDocument": null })}
                            />
                          </td>
                          <td>
                            {!data["shippingBillDocument"]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data["shippingBillDocument"]?.name); setData({ ...data, docToSign: data["shippingBillDocument"], docSignName: "shippingBillDocument" }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data["shippingBillDocument"]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["shippingBillDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["shippingBillDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Packing List</td>
                          <td>
                            <FileInput hideViewDowdBtns={true} name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "packingListDocument": null })}
                            />
                          </td>
                          <td>
                            {!data["packingListDocument"]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data["packingListDocument"]?.name); setData({ ...data, docToSign: data["packingListDocument"], docSignName: "packingListDocument" }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data["packingListDocument"]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["packingListDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["packingListDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>
                      </NewTablev2>
                    </div>


                    {/* <div className="col-md-8">
                      <AddMoreDocumentHandler
                        purpose={"ApplyForLimitInvoice"} purposeId={data.selectedBuyer}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
                      />
                    </div> */}

                    {/* {addMoreDoc.map((item, index) => {
                      return (
                        <div className="col-md-8 mb-3">
                          {docNamePicker.id === index ? (
                            <div className="w-25 d-inline-block">
                              <NewInput name={"addMore" + index} value={tempValue}
                                onChange={(e) => setTempValue(e.target.value)} />
                            </div>) : (
                            <label className="font-size-14">{item.doc_name}</label>
                          )}
                          <span>
                            {docNamePicker.id === index ? (
                              <>
                                <img
                                  onClick={() => {
                                    if (tempValue) {
                                      let temp = addMoreDoc
                                      temp[index]['doc_name'] = tempValue
                                      setAddMoreDoc([...temp])
                                      toggleDocNamePicker({ show: false, id: null })
                                    }
                                    else {
                                      toastDisplay('Please enter document name', "info")
                                    }
                                  }}
                                  style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                                <img
                                  onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                                  style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                              </>
                            ) : (
                              <img
                                onClick={() => { setTempValue(item.doc_name); toggleDocNamePicker({ show: true, id: index }) }}
                                style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}
                          </span>

                          <div className="row align-items-center">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"addMore" + index} value={data["addMore" + index]} error={errors["addMore" + index]}
                                onChange={handleFile} isEditable={true}
                              />
                            </div>
                            {!data?.["addMore" + index]?.signatureId && data?.["addMore" + index]?.name ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data?.["addMore" + index]?.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>) : null}
                          </div>
                        </div>
                      )
                    })} */}

                  </div>
                ) : null}
                {tab === 2 ? (
                  <div className='px-5 pt-5 pb-3'>
                    <label className='font-wt-500 font-size-16'>Select financer</label>
                    <div className="row my-4">
                      {finList.map((key, index) => {
                        return (
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-3">
                            <div className="d-flex gap-2 justify-content-between align-items-center">
                              <div class="custom-control ps-0 custom-checkbox form-check">
                                <input type="checkbox" class="form-check-input" id={"flexCheckChecked" + index} name="isChecked" value="on" onClick={
                                  () => {
                                    if (key.isAvailable || environment === "dev") {
                                      handleFinSelection(index)
                                    } else {
                                      toastDisplay("Action not availble", "info")
                                    }
                                  }
                                } required checked={finList[index]["isChecked"] ? true : false} />
                                <label class="form-check-label" for={"flexCheckChecked" + index}></label>
                              </div>
                              <div className={`card p-3 col-10 ${key.isAvailable ? key.isRecommened ? "selectfinancercardrec" : "selectfinancercard" : 'disabledFinCard'}`}>
                                {key.isRecommened && <div class="containercustom" data-ribbon="Recommended"></div>}
                                <div className="d-flex flex-row">
                                  <div>
                                    <p className="cursor">
                                      {key.logo ? (<img
                                        style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                                        className="mx-2" src={avatarUrl(key.logo)} alt="" />) : null}</p>
                                  </div>
                                  <div className="d-flex flex-row justify-content-between w-100">
                                    {/* <span className="font-size-14 font-wt-600 text-dark">{key.name}</span> */}
                                    {!key.isAvailable &&
                                      <img src="/assets/images/info-circle-outlined_remark.png" alt="" height={20} width={20} onClick={() => {
                                        setTooltip(true)
                                        setTooltipdata({
                                          lenderName: key.name,
                                          remarks: key.info_text
                                        })
                                      }} />
                                    }
                                  </div>
                                </div>
                                <div className="d-flex px-2">
                                  <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{key.avgRatings.toFixed(2)}</p>
                                    <span>
                                      <img className={`${key.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                    </span>
                                  </div>
                                  <div
                                    onClick={async () => {
                                      // Get getUserTradeInfoWithFinancier
                                      let temp = key
                                      temp["show"] = true
                                      toggleReviewScrollIndex(0)
                                      setshowLoader(true)
                                      temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: key.id, sellerId: userId }) }
                                      setshowLoader(false)
                                      setSeeReview(temp)
                                    }}
                                    className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{key.reviewsCount}</p><p className="font10 font-size-14 ">See reviews</p></div>
                                </div>
                                <p
                                  onClick={() => {
                                    setSelectedChat({
                                      receiverName: key.name,
                                      receiverId: key.id,
                                      userId: userId,
                                      isChatOpen: true
                                    })
                                  }} className="cursor"
                                ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2">Chat with financer</span></p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {tab === 3 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            );
                          })}
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Limit Required"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                              {data.limitRequired + " " + data.limitRequiredCurrency}</span> </p>
                          </div>
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Expected Date of Shipment"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                              {data.expShipmentDate}</span> </p>
                          </div>
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Document</u></label>
                        <div className='row mt-2'>
                          {/* {moreDocsArray ? moreDocsArray.map((item, index) => {
                            if (item?.name) {
                              return (
                                <div className="col-md-5">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>)
                            }
                          }) : null} */}

                          <div className="">
                            <NewTablev2 columns={documentTable3}>
                              {multInvPo.map((i, j) => {
                                return <>
                                  {i.invoices?.map((o, p) => {
                                    if (data["invoiceDocument" + j + ":" + p]?.name) {
                                      return (<tr>
                                        <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p] || ""}`}</td>
                                        <td>{`${data["contractAmount" + j + ":" + p] || ""} ${data["currency" + j + ":" + p] || ""}`}</td>
                                        <td>
                                          <FileInput value={data["invoiceDocument" + j + ":" + p]} isEditable={false}
                                          />
                                        </td>
                                        <td>
                                          <div
                                            className="d-flex"
                                          >
                                            {data["invoiceDocument" + j + ":" + p]?.name ? (
                                              <>
                                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["invoiceDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["invoiceDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                              </>
                                            ) : null}
                                          </div>
                                        </td>
                                      </tr>
                                      )
                                    }
                                  })}
                                  {i.purchase_orders?.map((o, p) => {
                                    if (data["poDocument" + j + ":" + p]?.name) {
                                      return (<tr>
                                        <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p] || ""}`}</td>
                                        <td>{`${data["poAmount" + j + ":" + p] || ""} ${data["currency" + j + ":" + p] || ""}`}</td>
                                        <td>
                                          <FileInput value={data["poDocument" + j + ":" + p]} isEditable={false}
                                          />
                                        </td>
                                        <td>
                                          <div
                                            className="d-flex"
                                          >
                                            {data["poDocument" + j + ":" + p]?.name ? (
                                              <>
                                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data["poDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                <img onClick={() => { toggleViewDoc({ show: true, doc: data["poDocument" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                              </>
                                            ) : null}
                                          </div>
                                        </td>
                                      </tr>
                                      )
                                    }
                                  })}
                                </>
                              })}
                              {data.invReportDocument?.name ?
                                <tr>
                                  <td>Investigation Report</td>
                                  <td>-</td>
                                  <td>
                                    <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      className="d-flex"
                                    >
                                      {data["invReportDocument"]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["invReportDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["invReportDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr> : null}
                              {data.logisticDocument?.name ?
                                <tr>
                                  <td>Bill of Lading / Airway Bill</td>
                                  <td>-</td>
                                  <td>
                                    <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      className="d-flex"
                                    >
                                      {data["logisticDocument"]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["logisticDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["logisticDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr> : null}
                              {data.grnDocument?.name ?
                                <tr>
                                  <td>Certificate of Origin</td>
                                  <td>-</td>
                                  <td>
                                    <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      className="d-flex"
                                    >
                                      {data["grnDocument"]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["grnDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["grnDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr> : null}
                              {data.salesContractDocument?.name ?
                                <tr>
                                  <td>Sales Contract</td>
                                  <td>-</td>
                                  <td>
                                    <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      className="d-flex"
                                    >
                                      {data["salesContractDocument"]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["salesContractDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["salesContractDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr> : null}
                              {data.shippingBillDocument?.name ?
                                <tr>
                                  <td>Shipping Bill</td>
                                  <td>-</td>
                                  <td>
                                    <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      className="d-flex"
                                    >
                                      {data["shippingBillDocument"]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["shippingBillDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["shippingBillDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr> : null}
                              {data.packingListDocument?.name ?
                                <tr>
                                  <td>Packing List</td>
                                  <td>-</td>
                                  <td>
                                    <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                                      onChange={handleFile} isEditable={false}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      className="d-flex"
                                    >
                                      {data["packingListDocument"]?.name ? (
                                        <>
                                          <img onClick={() => { toggleDowdDoc({ show: true, doc: data["packingListDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                          <img onClick={() => { toggleViewDoc({ show: true, doc: data["packingListDocument"] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr> : null}
                            </NewTablev2>
                          </div>

                          <div className='w-100 mt-5'>
                            <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                            <div className="d-flex gap-4 my-3">
                              {finList.map((key) => {
                                if (key.isChecked) {
                                  return (
                                    <label key={key.name}
                                      className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                      {key.name}
                                    </label>
                                  );
                                }
                              })}

                            </div>

                          </div>

                          <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="px-5 pb-5">
                  <button type="button"
                    onClick={() => handleValidation(undefined)}
                    className={` new-btn w-17 py-2 px-2  text-white`}>
                    {tab == 3 ? "Submit" : "Next"}
                  </button>
                </div>

              </div>
            </div>
          </main>
        </div>
      </div >
      {modal && <SelectBuyerModal modal={modal} setModal={setModal} hideBuyerModal={hideBuyerModal} >
        <h6 className="mb-4">Select Buyer</h6>
        <div className="col-md-7 mx-auto my-4">
          <MultipleSelect
            Id=""
            Label=""
            onClick={getBuyerDetails}
            filterOption={() => true}
            onInputChange={(e) => {
              console.log('On autocomplete change input', e)
              handleFilterOptions(e)
            }}
            optiondata={buyerdata}
            onChange={(e) => {
              console.log('On autocomplete change', e)
              handleMultiSelect(e, "id")
            }}
            value={data.selectedBuyer ? [data.selectedBuyer] : []}
            name="selectedBuyer"
            labelKey={"buyerName"}
            valKey={"id"}
            error={errors.selectedBuyer}
          />
          {/* <NewSelect isAstrix={false} label={""}
            selectData={dbData} name={"selectedBuyer"}
            value={data.selectedBuyer} optionLabel={"buyerName"} optionValue={'id'}
            onChange={handleChange} /> */}
        </div>
        <div className="text-center">
          <button type="button" disabled={!data.selectedBuyer} onClick={hideBuyerModal}
            className={`mx-2 new-btn w-20 py-2 px-2 text-white`}>
            {"Continue"}
          </button>
        </div>
      </SelectBuyerModal>}
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyLimitComponent)