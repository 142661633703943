import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, shipmentBookingQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import GoogleMapReact from 'google-map-react';
import { googleApiKey } from '../../urlConstants';
import ReactCountryFlag from 'react-country-flag';
import MapTracking from './MapTracking';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const defaultProps = {
  center: {
    lat: 20.5937,
    lng: 78.9629
  },
  zoom: 4
};

function getStatusColor(status) {
  if (status === "IN_TRANSIT") {
    return 'inprogress'
  }
  else {
    return 'approved'
  }
}


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ShipmentBookingTracking = ({ userTokenDetails, navToggleState }) => {
  console.log(userTokenDetails, "usertokennn")
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [containerEvents, setContainerEvents] = useState({})
  const [data, setData] = useState({})
  const [routeCoordinates, setRouteCoordinates] = useState([])
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  function convertToTitleCase(text) {
    return text.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("ShipmentBookingTracking");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  async function handleChange(e) {
    e.persist()
    setData({ ...data, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: '' })
  }

  useEffect(() => {
    if (data?.trackingInfo?.locations?.length && data?.trackingInfo?.route) {
      let tempRouteCoordinates = [];
      let routeKeys = Object.keys(data.trackingInfo.route) || []
      for (let index = 0; index < routeKeys.length; index++) {
        let element = routeKeys[index];
        if (data.trackingInfo.route?.[element]?.["location"]) {
          let locationDetails = data.trackingInfo.locations.filter(i => {
            if (i.id / 1 == data.trackingInfo.route?.[element]?.["location"] / 1) {
              return true
            }
          })?.[0]
          if (locationDetails) {
            tempRouteCoordinates.push({
              id: index + 1, lat: locationDetails.lat, lng: locationDetails.lng,
              description: { ...data.trackingInfo.route?.[element], ...locationDetails }
            })
          }
        }
      }
      setRouteCoordinates([...tempRouteCoordinates])
    }
  }, [data.trackingInfo])

  // console.log("temppppppppppppppppppppppppppppppp", routeCoordinates);

  async function handleValidation() {
    let err = {}
    if (!data.trackingNo) {
      err["trackingNo"] = "Mandatory Field"
    }
    if (!Object.keys(err).length) {
      fetchShipmentBLDetails()
    }
    setErrors({ ...err })
  }

  async function fetchShipmentBLDetails() {
    setshowLoader(true)
    if (data.trackingNo === "HLCUXM1230445340") {
      setData({
        ...data, dataNotFound: false, trackingInfo: {
          "metadata": {
            "type": "BL",
            "number": "HLCUXM1230445340",
            "sealine": "HLCU",
            "sealine_name": "Hapag-Lloyd",
            "status": "IN_TRANSIT",
            "updated_at": "2023-07-16 20:27:20",
            "api_calls": {
              "total": 1000,
              "used": 3,
              "remaining": 997
            },
            "unique_shipments": {
              "total": 0,
              "used": 0,
              "remaining": 0
            }
          },
          "locations": [
            {
              "id": 1,
              "name": "Savannah",
              "state": "Georgia",
              "country": "United States",
              "country_code": "US",
              "locode": "USSAV",
              "lat": 32.08354,
              "lng": -81.09983
            },
            {
              "id": 2,
              "name": "Byron",
              "state": "Georgia",
              "country": "United States",
              "country_code": "US",
              "locode": "USBY8",
              "lat": 32.65376,
              "lng": -83.75963
            },
            {
              "id": 3,
              "name": "Xiamen",
              "state": "Fujian Sheng",
              "country": "China",
              "country_code": "CN",
              "locode": "CNXMG",
              "lat": 24.47979,
              "lng": 118.08187
            }
          ],
          "route": {
            "prepol": {
              "location": 3,
              "date": "2023-05-24 13:25:00",
              "actual": true
            },
            "pol": {
              "location": 3,
              "date": "2023-05-24 18:44:00",
              "actual": true
            },
            "pod": {
              "location": 1,
              "date": "2023-07-18 07:00:00",
              "actual": false,
              "predictive_eta": null
            },
            "postpod": {
              "location": null,
              "date": null,
              "actual": null
            }
          },
          "vessels": [
            {
              "id": 1,
              "name": "YM WIDTH",
              "imo": 9708447,
              "call_sign": "VRPE5",
              "mmsi": 477129300,
              "flag": "HK"
            }
          ],
          "containers": [
            {
              "number": "UACU5367911",
              "iso_code": "45G1",
              "status": "IN_TRANSIT",
              "events": [
                {
                  "order_id": 1,
                  "location": 3,
                  "description": "Gate out empty",
                  "status": "CEP",
                  "date": "2023-05-24 13:25:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 2,
                  "location": 3,
                  "description": "Arrival in",
                  "status": "CGI",
                  "date": "2023-05-24 18:44:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 3,
                  "location": 3,
                  "description": "Loaded",
                  "status": "CLL",
                  "date": "2023-06-01 13:41:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "sea",
                  "transport_type": "VESSEL",
                  "vessel": 1,
                  "voyage": "029E"
                },
                {
                  "order_id": 4,
                  "location": 3,
                  "description": "Vessel departed",
                  "status": "VDL",
                  "date": "2023-06-01 14:15:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "sea",
                  "transport_type": "VESSEL",
                  "vessel": 1,
                  "voyage": "029E"
                },
                {
                  "order_id": 5,
                  "location": 1,
                  "description": "Vessel arrival",
                  "status": "VAD",
                  "date": "2023-07-18 07:00:00",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "sea",
                  "transport_type": "VESSEL",
                  "vessel": 1,
                  "voyage": "029E"
                },
                {
                  "order_id": 6,
                  "location": 1,
                  "description": "Departure from",
                  "status": "CGO",
                  "date": "2023-07-18 07:00:01",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 7,
                  "location": 2,
                  "description": "Arrival in",
                  "status": "LTS",
                  "date": "2023-07-18 07:00:02",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 8,
                  "location": 2,
                  "description": "Departure from",
                  "status": "LTS",
                  "date": "2023-07-18 07:00:03",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                }
              ]
            },
            {
              "number": "UACU5710126",
              "iso_code": "45G1",
              "status": "IN_TRANSIT",
              "events": [
                {
                  "order_id": 1,
                  "location": 3,
                  "description": "Gate out empty",
                  "status": "CEP",
                  "date": "2023-05-24 13:25:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 2,
                  "location": 3,
                  "description": "Arrival in",
                  "status": "CGI",
                  "date": "2023-05-24 18:44:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 3,
                  "location": 3,
                  "description": "Loaded",
                  "status": "CLL",
                  "date": "2023-06-01 13:41:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "sea",
                  "transport_type": "VESSEL",
                  "vessel": 1,
                  "voyage": "029E"
                },
                {
                  "order_id": 4,
                  "location": 3,
                  "description": "Vessel departed",
                  "status": "VDL",
                  "date": "2023-06-01 14:15:00",
                  "actual": true,
                  "is_additional_event": false,
                  "type": "sea",
                  "transport_type": "VESSEL",
                  "vessel": 1,
                  "voyage": "029E"
                },
                {
                  "order_id": 5,
                  "location": 1,
                  "description": "Vessel arrival",
                  "status": "VAD",
                  "date": "2023-07-18 07:00:00",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "sea",
                  "transport_type": "VESSEL",
                  "vessel": 1,
                  "voyage": "029E"
                },
                {
                  "order_id": 6,
                  "location": 1,
                  "description": "Departure from",
                  "status": "CGO",
                  "date": "2023-07-18 07:00:01",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 7,
                  "location": 2,
                  "description": "Arrival in",
                  "status": "LTS",
                  "date": "2023-07-18 07:00:02",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                },
                {
                  "order_id": 8,
                  "location": 2,
                  "description": "Departure from",
                  "status": "LTS",
                  "date": "2023-07-18 07:00:03",
                  "actual": false,
                  "is_additional_event": false,
                  "type": "land",
                  "transport_type": "TRUCK",
                  "vessel": null,
                  "voyage": null
                }
              ]
            }
          ]
        }
      })
    }
    else {
      setData({ ...data, dataNotFound: true, trackingInfo: null })
    }
    setshowLoader(false)
  }

  return (<>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
    <div className="container-fluid">
      <div className="row">
        <SideBarV2 state="ShipmentBookingTracking" userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"Logistic > Tracking"}
            userTokenDetails={userTokenDetails} />
          <div className='d-flex row' >
            <div className='col-6' >
              <div style={{ height: '100vh', overflowY: 'auto', overflowX: "hidden" }} className='card'>
                <div className='d-flex row align-items-center m-0 p-0' >
                  <div style={{ width: '84%' }}
                    className="pl-4 mt-4 position-relative" >
                    <NewInput label={"Container, Booking, Bill of Lading"}
                      name={"trackingNo"} value={data.trackingNo} error={errors.trackingNo} onChange={handleChange} />
                  </div>
                  <div
                    onClick={handleValidation}
                    style={{ width: '12%' }} className="mt-2" >
                    <img src="assets/images/shipmentSearchIcon.png" className="cursor" />
                  </div>
                </div>
                {data.dataNotFound ?

                  <div className='d-flex row px-3 mt-4' >
                    <div className='d-flex justify-content-center'>
                      <img className='my-5 '
                        style={{
                          width: '12rem',
                          height: '12rem'
                        }}
                        src='assets/images/resultNotFound.svg' />
                    </div>
                    <label className='font-size-20 font-wt-600 w-100 text-center mb-5' >No Results Found</label>
                    <div className='d-flex row align-items-center justify-content-evenly'>
                      <div className='col-5'>
                        <button type="button"
                          onClick={() => { window.location = '/ShipmentBookingMarketPlace' }}
                          className={` border-0 my-2 text-white enableQuotebtn  w-100`}>
                          {"Book Now"}
                        </button>
                      </div>
                      <div className='col-5'>
                        <label
                          onClick={() => { window.location = '/ShipmentBookingQuotes' }}
                          className='cursor font-size-18 font-wt-600 text-color1 mt-2' >{`Request a Quote >`}</label>
                      </div>
                    </div>
                  </div> :
                  data.trackingInfo ?
                    <div className='d-flex row px-3' >
                      <a className="cursor px-4"
                        onClick={() => { setData({}) }}
                      ><img
                          src={"assets/images/back.png"} alt="Back" height={15} width={15} />
                        <label className='font-size-14 font-wt-500 pl-3' >Back</label></a>
                      <div className='' >
                        <div className='px-3 d-flex mt-4'>
                          <label className='font-size-14 font-wt-600 text-color-label mr-2'>{data.trackingInfo?.metadata?.type || "-"}</label>
                          <label className='font-size-14 font-wt-600 text-color-value'>{data.trackingInfo?.metadata?.number || "-"}</label>
                          <label className={`ml-auto text-center pt-1 font-size-12 font-wt-500 ${getStatusColor(data.trackingInfo?.metadata?.status)}`}>
                            {convertToTitleCase(data.trackingInfo?.metadata?.status)}</label>
                        </div>
                        <div className='mb-4 px-3 d-flex'>
                          <label className='font-size-14 font-wt-600 text-color-label mr-2'>Sealine Name</label>
                          <label className='font-size-14 font-wt-600 text-color-value'>{data.trackingInfo?.metadata?.sealine_name || "-"}</label>
                        </div>
                        <div className='mx-2'>
                          <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                            {[{ name: 'Route' }, { name: 'Vessel' }, { name: 'Container' }].map((item, index) => {
                              if (!item.hide) {
                                return (
                                  <li>
                                    <a className={"nav-link userformTab formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                                      onClick={() => { setTab(index) }}
                                    >
                                      {item.name}</a>
                                  </li>
                                )
                              }
                            })}
                          </ul>
                        </div>
                        {tab / 1 == 0 ? <div className='mt-4' ><div className="col-md-1 justify-content-center mb-4 pb-4 left-class stepper-desktop-view" style={{}}>
                          <div className="d-flex flex-column stepper-div ">
                            {[{ name: 'prepol' }, { name: 'pol' }, { name: 'pod' }, { name: 'postpod' }].map((it, index) => {
                              let item = data.trackingInfo?.route?.[it.name]
                              let location = data.trackingInfo?.locations?.filter(i => { if (i.id / 1 == item.location / 1) { return true } })?.[0]
                              if (item.location) {
                                return (
                                  <>
                                    <div className='position-relative' style={{
                                      "border": `1px solid ${true ? "#5CB8D3" : "#BDBDBD"}`,
                                      "background": true ? "#5CB8D3" : "#BDBDBD",
                                      "border-radius": "100%", "height": '1rem', "width": '1rem'
                                    }}>
                                      <div style={{ width: '23rem', top: '-0.5rem' }} className='card ml-4 p-2 position-absolute' >
                                        <label className="font-size-12 font-wt-600"
                                        >{location?.name + "," + location?.state + "," + location?.country}</label>
                                        <label className="font-size-12 font-wt-400"
                                        >{it.name + " (" + item.date + ") "}</label>
                                      </div>
                                    </div>
                                    <div className={`${item.last ? "d-none" : "" + "stepper-bar2"}`} style={{
                                      "border": `1px solid ${true ? "#5CB8D3" : "#BDBDBD"}`,
                                      "background": true ? "#5CB8D3" : "#BDBDBD"
                                    }} />
                                  </>
                                )
                              }
                            })}
                          </div>
                        </div>
                        </div> : null}
                        {tab / 1 == 1 ? <div className='px-2'>
                          {data.trackingInfo?.vessels?.length ? data.trackingInfo?.vessels?.map((i, j) => {
                            return (<div className='card w-80 p-3 mt-4' >
                              <div className='position-absolute' style={{ right: 0 }}>
                                <ReactCountryFlag
                                  countryCode={i.flag}
                                  style={{ width: '20px', height: '20px', marginRight: '1rem' }} svg />
                              </div>
                              <div className='d-flex row mb-2' >
                                <div className='col-6'>
                                  <label className='font-size-14 font-wt-600 text-color-label mr-2'>Name</label>
                                  <label className='font-size-14 font-wt-600 text-color-value'>{i.name || "-"}</label>
                                </div>
                                <div className='col-6'>
                                  <label className='font-size-14 font-wt-600 text-color-label mr-2'>IMO</label>
                                  <label className='font-size-14 font-wt-600 text-color-value'>{i.imo || "-"}</label>
                                </div>
                              </div>
                              <div className='d-flex row'>
                                <div className='col-6'>
                                  <label className='font-size-14 font-wt-600 text-color-label mr-2'>Call Sign</label>
                                  <label className='font-size-14 font-wt-600 text-color-value'>{i.call_sign || "-"}</label>
                                </div>
                                <div className='col-6'>
                                  <label className='font-size-14 font-wt-600 text-color-label mr-2'>MMSI</label>
                                  <label className='font-size-14 font-wt-600 text-color-value'>{i.mmsi || "-"}</label>
                                </div>
                              </div>
                            </div>)
                          }) : <label className='font-size-14 font-wt-600 w-100 text-center mt-5'>No Vessel Information Available</label>}
                        </div> : null}
                        {tab / 1 == 2 ? <div className='px-2'>
                          {data.trackingInfo?.containers?.length ? data.trackingInfo?.containers?.map((i, j) => {
                            return (<div className='card w-85 p-3 mt-4' >
                              <div className='position-absolute' style={{ right: '1rem', top: '-0.9rem' }}>
                                <label className={`ml-auto text-center pt-1 font-size-12 font-wt-500 ${getStatusColor(i.status)}`}>
                                  {convertToTitleCase(i.status)}</label>
                              </div>
                              <div className='d-flex row mb-2' >
                                <div className='col-6'>
                                  <label className='font-size-14 font-wt-600 text-color-label mr-2'>Number</label>
                                  <label className='font-size-14 font-wt-600 text-color-value'>{i.number || "-"}</label>
                                </div>
                                <div className='col-6'>
                                  <label className='font-size-14 font-wt-600 text-color-label mr-2'>ISO Code</label>
                                  <label className='font-size-14 font-wt-600 text-color-value'>{i.iso_code || "-"}</label>
                                </div>
                              </div>
                              <label
                                onClick={() => {
                                  setContainerEvents({ ...containerEvents, [j]: !containerEvents[j] })
                                }}
                                className='text-color1 font-size-14 font-wt-600 cursor'>{containerEvents[j] ? `Hide Events` : `View Events`}</label>
                              {containerEvents[j] ? <div className='mt-4' ><div className="col-md-1 justify-content-center mb-4 pb-4 left-class stepper-desktop-view" style={{}}>
                                <div className="d-flex flex-column stepper-div ">
                                  {i.events?.map((item, index) => {
                                    let location = data.trackingInfo?.locations?.filter(i => { if (i.id / 1 == item.location / 1) { return true } })?.[0]
                                    return (
                                      <>
                                        <div className='position-relative' style={{
                                          "border": `1px solid ${true ? "#5CB8D3" : "#BDBDBD"}`,
                                          "background": true ? "#5CB8D3" : "#BDBDBD",
                                          "border-radius": "100%", "height": '1rem', "width": '1rem'
                                        }}>
                                          <div style={{ width: '20rem', top: '-0.5rem' }} className='card ml-4 p-2 position-absolute' >
                                            <label className="font-size-12 font-wt-600"
                                            >{location?.name + "," + location?.state + "," + location?.country}</label>
                                            <label className="font-size-12 font-wt-400"
                                            >{item.description + " (" + item.date + ") "}</label>
                                          </div>
                                        </div>

                                        <div className={`${item.last ? "d-none" : "" + "stepper-bar2"}`} style={{
                                          "border": `1px solid ${true ? "#5CB8D3" : "#BDBDBD"}`,
                                          "background": true ? "#5CB8D3" : "#BDBDBD"
                                        }} />
                                      </>
                                    )

                                  })}
                                </div>
                              </div>
                              </div> : null}
                            </div>)
                          }) : <label className='font-size-14 font-wt-600 w-100 text-center mt-5'>No Container Information Available</label>}
                        </div> : null}
                      </div>
                    </div> :
                    <div className='d-flex row px-3' >
                      <div className='d-flex justify-content-center'>
                        <img className='my-5 shippingBannerImgBlack' style={{
                          width: '9rem',
                          height: '9rem'
                        }} src='assets/images/ShippingBanner.png' />
                      </div>
                      <label className='font-size-20 font-wt-600 w-100 text-center' >Tracking starts here</label>
                      <p className='mt-2 mb-5 px-3 font-size-16 font-wt-500 w-100 text-center' >
                        Track your shipments with the most convenient tool.
                        Want more visibility of deliveries? Just enter the tracking number. Or explore more opportunities with us:
                      </p>
                      <div className='d-flex row align-items-center justify-content-evenly'>
                        <div className='col-5'>
                          <button type="button"
                            onClick={() => { window.location = '/ShipmentBookingMarketPlace' }}
                            className={` border-0 my-2 text-white enableQuotebtn  w-100`}>
                            {"Book Now"}
                          </button>
                        </div>
                        <div className='col-5'>
                          <label
                            onClick={() => { window.location = '/ShipmentBookingQuotes' }}
                            className='cursor font-size-18 font-wt-600 text-color1 mt-2' >{`Request a Quote >`}</label>
                        </div>
                      </div>
                    </div>}
              </div>
            </div>
            <div className='col-6' >
              <div style={{ height: '100vh', width: '100%' }}>
                <MapTracking routeCoordinates={routeCoordinates} />
                {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: googleApiKey }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  />
                  <Polyline
                    path={routeCoordinates}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2} />
                </GoogleMapReact> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentBookingTracking)