import React, { useState, useEffect } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import useForm from '../../utils/useForm';
import validate from '../../utils/validation_files/bankdetailsValidationRules';
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import Header from '../homeComponents/header';

import { setRegistrationData } from '../../store/actions/action';
import submitPayload from './submitPayload';

const BankForm = ({ userTokenDetails, registrationData, setRegistrationData, dispatch }) => {

  const [bankMasters, setbankMasters] = useState([]);
  const [bankData, setBankData] = useState({});
  const [userEmail, setuserEmail] = useState('');
  const [showLoader, setshowLoader] = useState(false);

  const astrix = <span className="required-field text-danger">*</span>

  var isAdminModal = !(window.location.href.includes('registration'));

  const {
    values,
    errors,
    handleChange,
    handleSubmitbank,
    SETvaluesData
  } = useForm(form_sub, null, validate);

  useEffect(() => {

    setuserEmail(registrationData && registrationData.payload.step1.email ? registrationData.payload.step1.email : '')
    //------------------------------------------------------------------
    //API Calls
    call('GET', 'getBanksListMaster').then((result) => {
      console.log("result in getBanksListMaster api->", result)
      setbankMasters(result)
    }).catch((e) => {
      console.log("Error while querying getBanksListMaster:", e);
    })

    //------------------------------------------------------------------

    registrationData.payload.modalNav = 2;//for navigating next/back in case of modal
    SETvaluesData(registrationData.payload.step4)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  function isEmptykeyvalues() {
    var counter = 0
    for (var key in values) {
      var value = values[key];
      if (value !== '')
        counter++;
    }
    return counter
  }


  async function form_sub() {

    try {

      // let finalRegPayload = JSON.parse(JSON.stringify(registrationData))

      if ((values.accountNo)) {
        console.log("inside my condition")
        registrationData.payload.step4 = values;
        // await setRegistrationData(registrationData.type, registrationData.step, registrationData.payload);
      }


      setshowLoader(true)
      let result = await submitPayload(registrationData)
      setshowLoader(false)
      toastDisplay(result, "success");
      setTimeout(() => { }, 5000);
      setRegistrationData(0, 6, {});
    } catch (error) {
      setshowLoader(false)
      toastDisplay(error, "error");
    }
  }

  function handleBack() {
    registrationData.payload.step4 = values;
    if (registrationData.type / 1 === 14 || registrationData.type / 1 === 4 || registrationData.type / 1 === 15 || registrationData.type / 1 === 16) {//seller(4) and trader(14), producer(15) and 'producer with buyer'(16) (redirected to commodities)
      setRegistrationData(registrationData.type, 4, registrationData.payload);
    } else if (registrationData.type / 1 === 3) {
      setRegistrationData(registrationData.type, 7, registrationData.payload);
    } else if ([10, 11, 5, 17].includes(registrationData.type)) {//buyer(3), broker(10), stevedore(11), shipper(5) (redirected to bank form)
      setRegistrationData(registrationData.type, 2, registrationData.payload);
    }
  }

  const hanleDocChecks = (event) => {
    event.persist();
    SETvaluesData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }


  function bankFormCommon() {
    return (
      <div className="pr-4">
        <div className="row">

          <div className="pr-4">
            <h3 className="mt-3 text-center">Enter Correspondent Bank Details</h3>
            <div className="row">

              <div className="col-md-6">
                <div className="form-group">
                  <label>Email ID</label>
                  <input type="text" className="form-control" name="bankEmail" value={'addBankObj.info.email'} onChange={handleChange} readOnly />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Bank Name</label>
                  <input type="text" className="form-control" name="correspondentBankName" value={bankData.correspondentBankName} placeholder="Enter Correspondent Bank Name" onChange={handleChange} />
                  {errors.correspondentBankName && <p className="text-danger error-contract">{errors.correspondentBankName}</p>}
                </div>
              </div>

              {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>Select Bank </label><span className="required-field text-danger">*</span>
                        <select className="form-control" name="bankMaster" value={bankData.bankMaster} onChange={handleChange}>
                          <option value="" selected>--Select Bank--</option>
                          {bankMasters.map((bank) => {
                            return (
                              <option value={bank.id + ":" + bank.company_name} >{bank.company_name}</option>
                            )
                          })}
                        </select>
                        {errors.bankMaster && <p className="text-danger error-contract">{errors.bankMaster}</p>}
                      </div>
                    </div> */}

              <div className="col-md-6">
                <div className="form-group">
                  <label>Swift Code</label>
                  <input type="text" className="form-control" name="correspondentSwift" value={bankData.correspondentSwift} placeholder="Enter Correspondent Swift Code" onChange={handleChange} />
                  {errors.correspondentSwift && <p className="text-danger error-contract">{errors.correspondentSwift}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Account No</label>
                  <input type="text" className="form-control" name="correspondentAccountNumber" value={bankData.correspondentAccountNumber} placeholder="Enter Correspondent Swift Code" onChange={handleChange} />
                  {errors.correspondentAccountNumber && <p className="text-danger error-contract">{errors.correspondentAccountNumber}</p>}
                </div>
              </div>

            </div>

            <h3 className="mt-3 text-center">Enter Beneficiary Bank Details</h3>
            <div className="row">

              <div className="col-md-6">
                <div className="form-group">
                  <label>Bank Name</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryBankName" value={bankData.beneficiaryBankName} placeholder="Enter Beneficiary Bank Name" onChange={handleChange} />
                  {errors.beneficiaryBankName && <p className="text-danger error-contract">{errors.beneficiaryBankName}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Branch</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryBranch" value={bankData.beneficiaryBranch} placeholder="Enter Beneficiary Bank Branch" onChange={handleChange} />
                  {errors.beneficiaryBranch && <p className="text-danger error-contract">{errors.beneficiaryBranch}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Branch Address</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryBranchAddress" value={bankData.beneficiaryBranchAddress} placeholder="Enter Beneficiary Branch Address" onChange={handleChange} />
                  {errors.beneficiaryBranchAddress && <p className="text-danger error-contract">{errors.beneficiaryBranchAddress}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>City</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryCity" value={bankData.beneficiaryCity} placeholder="Enter Beneficiary Bank City" onChange={handleChange} />
                  {errors.beneficiaryCity && <p className="text-danger error-contract">{errors.beneficiaryCity}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>State</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryState" value={bankData.beneficiaryState} placeholder="Enter Beneficiary Bank State" onChange={handleChange} />
                  {errors.beneficiaryState && <p className="text-danger error-contract">{errors.beneficiaryState}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Country</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryCountry" value={bankData.beneficiaryCountry} placeholder="Enter Beneficiary Bank Country" onChange={handleChange} />
                  {errors.beneficiaryCountry && <p className="text-danger error-contract">{errors.beneficiaryCountry}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Postal Code</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryPostalCode" value={bankData.beneficiaryPostalCode} placeholder="Enter Beneficiary Bank Postal Code" onChange={handleChange} />
                  {errors.beneficiaryPostalCode && <p className="text-danger error-contract">{errors.beneficiaryPostalCode}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Account Name</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryAccountName" value={bankData.beneficiaryAccountName} placeholder="Enter Beneficiary Account Name" onChange={handleChange} />
                  {errors.beneficiaryAccountName && <p className="text-danger error-contract">{errors.beneficiaryAccountName}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Account No</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryAccountNo" value={bankData.beneficiaryAccountNo} placeholder="Enter Beneficiary Account No" onChange={handleChange} />
                  {errors.beneficiaryAccountNo && <p className="text-danger error-contract">{errors.beneficiaryAccountNo}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>IFSC Code</label>{astrix}
                  <input type="text" className="form-control" name="beneficiaryIfscCode" value={bankData.beneficiaryIfscCode} placeholder="Enter Beneficiary IFSC Code" onChange={handleChange} />
                  {errors.beneficiaryIfscCode && <p className="text-danger error-contract">{errors.beneficiaryIfscCode}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Swift Code</label>{astrix}
                  <input type="text" className="form-control" name="beneficiarySwiftCode" value={bankData.beneficiarySwiftCode} placeholder="Enter Beneficiary Swift Code" onChange={handleChange} />
                  {errors.beneficiarySwiftCode && <p className="text-danger error-contract">{errors.beneficiarySwiftCode}</p>}
                </div>
              </div>

            </div>

          </div>

          <div className="col-md-10 mt-1">
            <div className="form-check pl-0 mt-1 mb-1">
              <input className="form-check-input" type="checkbox" name="termsAndCondition" id="termsAndCondition" onChange={hanleDocChecks} checked={values.termsAndCondition} />
              <label className="form-check-label" for="termsAndCondition">
                I acknowledge that i have read and fully understand the <a href="./assets/docs/Terms_of_Use.pdf" target="true">terms and conditions</a> of the
                tradereboot onboarding agreement and that i will comply with the same.
              </label>
            </div>
          </div>

        </div>
      </div>
    )
  }

  //----final component return[start]----
  if (isAdminModal) {
    return (
      <div className="modal-body calc-inner-modal">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="container-fluid">
          <div className="row">
            {/* Navigation in case of modal: 1-prev, 2-neutral, 3-next(not used 0 coz its falsy)*/}
            {(registrationData.payload.modalNav === 1) && (registrationData.payload.modalNav = 2) && handleBack()}
            {(registrationData.payload.modalNav === 3) && (registrationData.payload.modalNav = 2) && handleSubmitbank()}
            <div className="col-md-12">
              <h3 className="green border-bottom mb-4 mt-2 pb-2">Enter Bank Details:</h3>
            </div>
            <div className="col-md-10 mt-1 offset-md-1">
              {bankFormCommon()}
            </div>
            <div className="text-left mt-0 mb-0" style={{ fontSize: 10 }}>
              <ul className="list-unstyled text-warning" >
                <li>* All fileds will be mandatory if on-boarding with bank details</li>
                <li>* or Just submit empty form to skip</li>
              </ul>
            </div>
            <div className="form-group col-md-12 text-center mt-0 mb-5 ">
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <ul className="next-button">
          <li ><button onClick={handleBack}>{"<< Previous"}</button></li>
          {/* <li ><button onClick={handleSubmitbank} noValidate><i className="fa fa-angle-right" aria-hidden="true"></i></button></li> */}
        </ul>

        <div className="container-fluid">
          <div className="row">
            <Header title={localStorage.getItem('headerName') ? localStorage.getItem('headerName') : "Bank Details"} />
            <div className="col-md-8 mx-auto bg-white rounded p-4 mt-3 calc">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="green mb-4 mt-2">Bank Details:</h3>
                </div>
                <div className="col-md-10 calc-inner-modal mt-1 offset-md-1">
                  {bankFormCommon()}
                </div>

                {values.termsAndCondition == true &&
                  <div className="col-md-10 mt-1 text-center">
                    <button className="btn btn-primary btn-sm" type="button" onClick={handleSubmitbank} noValidate>Submit</button>
                  </div>
                }

                <div className="text-left mt-0 mb-0" style={{ fontSize: 10 }}>
                  <ul className="list-unstyled " >
                    <li>* All fileds will be mandatory if on-boarding with bank details</li>
                    <li>* or Just submit empty form to skip</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
  //----final component return[end]----
}


const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankForm)
