export const columns = [
  {
    subColumns: "Transaction ID",
    thwidth: "w-13",
    filter: true
  },
  {
    subColumns: "Buyer name",
    thwidth: "w-15",
    filter: true
  },
  {
    subColumns: "Financer",
    thwidth: "w-8",
    filter: true
  },
  {
    subColumns: "Disbursement date",
    thwidth: "w-12",
    filter: true
  },
  {
    subColumns: "Disbursement Amount",
    thwidth: "w-12",
    filter: true
  },
  {
    subColumns: "Outstanding amount",
    thwidth: "w-12",
    filter: true
  },
  {
    subColumns: "Outstanding days",
    thwidth: "w-12",
    filter: true
  },
  {
    subColumns: "Status",
    thwidth: "w-8",
    filter: true
  },
  {
    subColumns: "Send message",
    thwidth: "w-10",
    filter: false
  },
];


export const actionsdata = [
  {
    name: "Buyer profile"
  }, {
    name: "Application details"
  },
  {
    name: "Invoice"
  },
  {
    name: "History"
  },
];

export const tablenewData = [
  {
    TransactionID: "256985647854589",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    Financer: "Drip",
    Disbursementdate: "21/02/2022",
    DisbursementAmount: "$ 90,000",
    Outstandingamount: "-",
    Outstandingdays: "-",
    Status: "Approved overdue",
    DisbursementAmountDue: "$ 10,000",
    DisbursementdateDue: "21/06/2022",
    OutstandingamountDue: "$ 10,000",
    OutstandingdaysDue: "5 Days"
  },
  {
    TransactionID: "256985647854589",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    Financer: "Drip",
    Disbursementdate: "21/02/2022",
    DisbursementAmount: "$ 90,000",
    Outstandingamount: "-",
    Outstandingdays: "-",
    Status: "Pending Pending",
    DisbursementAmountDue: "$ 10,000",
    DisbursementdateDue: "21/06/2022",
    OutstandingamountDue: "$ 10,000",
    OutstandingdaysDue: "5 Days"
  },
  {
    TransactionID: "256985647854589",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    Financer: "Drip",
    Disbursementdate: "21/02/2022",
    DisbursementAmount: "$ 90,000",
    Outstandingamount: "-",
    Outstandingdays: "-",
    Status: "Approved Pending",
    DisbursementAmountDue: "$ 10,000",
    DisbursementdateDue: "21/06/2022",
    OutstandingamountDue: "$ 10,000",
    OutstandingdaysDue: "5 Days"
  },
];


export const Financecolumns = [
  {
    subColumns: "Transaction ID",
    filter: true
  },
  {
    subColumns: "Buyer name",
    filter: true
  },
  {
    subColumns: "Financer",
    filter: true
  },
  {
    subColumns: "Finance type",
    filter: true
  },
  {
    subColumns: "Amount",
    filter: true
  },
  // {
  //   subColumns: "History",
  //   filter: true
  // },
  {
    subColumns: "Status",
    filter: true
  },
];

export const tableFinanceData = [
  {
    TransactionID: "158565248598754",
    buyerName: "Rosti Suzhou Integrated  ",
    Financer: "Modifi",
    Financetype: "LC discounting Limit",
    Amount: "$ 1,00,000",
    History: "-",
    Status: "Approved",
  },
  {
    TransactionID: "158565248598754",
    buyerName: "Rosti Suzhou Integrated  ",
    Financer: "Modifi",
    Financetype: "LC discounting Limit",
    Amount: "$ 1,00,000",
    History: "-",
    Status: "Disbursed",
  },
  {
    TransactionID: "158565248598754",
    buyerName: "Rosti Suzhou Integrated  ",
    Financer: "Modifi",
    Financetype: "LC discounting Limit",
    Amount: "$ 1,00,000",
    History: "-",
    Status: "In process",
  },
  {
    TransactionID: "158565248598754",
    buyerName: "Rosti Suzhou Integrated  ",
    Financer: "Modifi",
    Financetype: "LC discounting Limit",
    Amount: "$ 1,00,000",
    History: "-",
    Status: "Rejected",
  },
];



export const Shipmentcolumns = [
  {
    subColumns: "Date",
    filter: true
  },
  // {
  //   subColumns: "Bill of Lading",
  //   filter: true
  // },
  {
    subColumns: "Buyer name",
    filter: true
  },
  // {
  //   subColumns: "Finance type",
  //   filter: true
  // },
  {
    subColumns: "Weight",
    filter: true
  },
  {
    subColumns: "From",
    filter: true
  },
  {
    subColumns: "To",
    filter: true
  },
  {
    subColumns: "Product",
    filter: true
  },
];

export const tableShipmentData = [
  {
    Date: "12/10/2022",
    BillofLading: "158565248598754",
    buyerName: "Rosti Suzhou Integrated  ",
    Financetype: "LC discounting Limit",
    Weight: "100 kg",
    From: "SHANGHAI",
    To: "SAVANNAH, GA",
    Product: "SAVANNAH, GA"
  },
  {
    Date: "12/10/2022",
    BillofLading: "158565248598754",
    buyerName: "Pharmanex Huzhou Health Products  ",
    Financetype: "LC discounting Limit",
    Weight: "100 kg",
    From: "SHANGHAI",
    To: "SAVANNAH, GA",
    Product: "SAVANNAH, GA"
  },
  {
    Date: "12/10/2022",
    BillofLading: "158565248598754",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    Financetype: "LC discounting Limit",
    Weight: "100 kg",
    From: "SHANGHAI",
    To: "SAVANNAH, GA",
    Product: "SAVANNAH, GA"
  },
  {
    Date: "12/10/2022",
    BillofLading: "158565248598754",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    Financetype: "LC discounting Limit",
    Weight: "100 kg",
    From: "SHANGHAI",
    To: "SAVANNAH, GA",
    Product: "SAVANNAH, GA"
  },
  {
    Date: "12/10/2022",
    BillofLading: "158565248598754",
    buyerName: "Pharmanex Electronic Optical Technology Shanghai ",
    Financetype: "LC discounting Limit",
    Weight: "100 kg",
    From: "SHANGHAI",
    To: "SAVANNAH, GA",
    Product: "SAVANNAH, GA"
  },

];

export const BuyerShipmentsColumn = [
  {
    subColumns: "Date",
    filter: true
  },
  {
    subColumns: "Supplier name",
    filter: true
  },
  {
    subColumns: "Weight",
    filter: true
  },
  {
    subColumns: "From",
    filter: true
  },
  {
    subColumns: "To",
    filter: true
  },
  {
    subColumns: "Product",
    filter: true
  },
];

export const TTVBuyerColumns = [
  {
    subColumns: "Buyer Name",
    filter: true
  },
  {
    subColumns: "No. of Suppliers",
    filter: true
  },
  {
    subColumns: "Shipments",
    filter: true
  },
  {
    subColumns: "Turnover",
    filter: true
  },
  {
    subColumns: "TOP Suppliers",
    filter: true
  },
  {
    subColumns: "Location",
    filter: true
  },
  {
    subColumns: "Website",
    filter: false
  },
  { subColumns: "" }
]

export const TTVExporterColumns = [
  {
    subColumns: "Exporter Name",
    filter: true
  },
  {
    subColumns: "No. of Buyers",
    filter: true
  },
  {
    subColumns: "Shipments",
    filter: true
  },
  {
    subColumns: "Turnover",
    filter: true
  },
  {
    subColumns: "TOP Buyers",
    filter: true
  },
  {
    subColumns: "Location",
    filter: true
  },
  {
    subColumns: "Website",
    filter: false
  },
  { subColumns: "" }
]