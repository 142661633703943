import React, { useEffect } from 'react'
import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import call from '../../service';
import TTVBuyerList from './TTVBuyerList';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { NewInput, NewSelect } from '../../utils/newInput';
import toastDisplay from '../../utils/toastNotification';
import { platformURL } from '../../urlConstants';
import moment from 'moment';
let regions = [
  {
    region_id: 1,
    region: "Africa",
  },
  {
    region_id: 2,
    region: "Americas"
  },
  {
    region_id: 3,
    region: "Asia"
  },
  {
    region_id: 4,
    region: "Europe"
  },
  {
    region_id: 5,
    region: "Oceania"
  }
]

const turnoverRange = [
  { min: "100,000", max: '1 Million', is_checked: false, minVal: 100000, maxVal: 1000000 },
  { min: "1 Million", max: '5 Million', is_checked: false, minVal: 1000000, maxVal: 5000000 },
  { min: "5 Million", max: '10 Million', is_checked: false, minVal: 5000000, maxVal: 10000000 },
  { min: "10 Million", max: 'More', is_checked: false, maxVal: 10000000 }
]

const tabs = [
  // "PC Code",
  "2 Digit",
  "4 Digit",
  "6 Digit",
  "8 Digit"
]


const FindBuyers = ({ userTokenDetails }) => {
  const [filter, setFilter] = useState({ search: '', showExports: true, showImports: false })
  const [refresh, setRefresh] = useState(1)
  const [showLoader, setShowLoader] = useState(false)
  const [region, setRegion] = useState("Africa")
  const [dbdata, setDbdata] = useState([])
  const [showBuyerdetails, setShowBuyerdetails] = useState({
    show: false,
    data: {}
  })
  const [hsndigits, setHSNdigits] = useState('2 Digit')
  const [HS_CODES, setHS_CODES] = useState([])
  const [turnOverFilter, setturnOverFilter] = useState(turnoverRange)
  const [turnOverPopup, toggleTurnOverPopup] = useState(false)
  const [data, setData] = useState({
    countSort: 'count_desc', countFrom: moment().subtract(1, "years").format("YYYY-MM-DD"),
    countTo: moment().format("YYYY-MM-DD")
  })
  const [errors, setErrors] = useState({})
  const [recentViewed, setRecentViewed] = useState([])
  const [countData, setCountData] = useState({})

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null



  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1)
    }
  }

  const loadBothExpImpCount = () => {
    setShowLoader(true)
    setShowLoader(false)
  }

  const searchbuyers = () => {
    setShowLoader(true)
    call('POST', 'searchbuyersV2', {
      showImports: filter.showImports,
      showExports: filter.showExports,
      searchParam: filter.search, region_name: region,
      HS_CODES: HS_CODES.filter(item => item.is_checked).map(item => item.HS_CODE),
      TURNOVER_RANGE: turnOverFilter.filter(item => item.is_checked),
      countFrom: data.countFrom,
      countTo: data.countTo,
      countSort: data.countSort
    }).then(async result => {
      let otherApiRes = await call('POST', 'searchbuyersV2', {
        showImports: !filter.showImports,
        searchParam: filter.search, region_name: region,
        HS_CODES: HS_CODES.filter(item => item.is_checked).map(item => item.HS_CODE),
        TURNOVER_RANGE: turnOverFilter.filter(item => item.is_checked),
        countFrom: data.countFrom,
        countTo: data.countTo,
        countSort: data.countSort
      })
      // console.log('success in searchbuyers', result, otherApiRes)
      setCountData({
        imports: filter.showImports ? result.totalCount : otherApiRes.totalCount,
        exports: filter.showExports ? result.totalCount : otherApiRes.totalCount
      })
      setDbdata(result.data || [])
      setShowLoader(false)
    }).catch(e => {
      console.log('error in searchbuyers', e)
      setShowLoader(false)
    })
  }

  async function getRecentlyViewedBuyers() {
    setShowLoader(true)
    let apiResp = await call('POST', "getRecentlyViewedBuyer", { userId })
    setRecentViewed(apiResp)
    setShowLoader(false)
  }

  useEffect(() => {
    getRecentlyViewedBuyers()
  }, [])

  useEffect(() => {
    if (filter.search !== '' && data.countFrom?.length == 10 && data.countTo?.length == 10) {
      searchbuyers()
    }
  }, [region, HS_CODES, turnOverFilter, data.countFrom, data.countTo, data.countSort])

  const getHSCodes = (searchparam) => {
    setShowLoader(true)
    const digits = parseInt(hsndigits)
    let searchdata = ""
    if (searchparam) {
      searchdata = searchparam
    } else {
      searchdata = filter.search
    }
    call('POST', 'getHSCodes', { digits, searchparam: searchdata }).then(result => {
      setHS_CODES(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }

  useEffect(() => {
    if (!isNaN(filter.search?.trim())) {
      getHSCodes()
    }
  }, [hsndigits, filter.search, filter.showExports, filter.showImports])

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  // console.log("turnOverFilterrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", turnOverFilter);

  return (
    <>
      {turnOverPopup ? (<FinanceInvoiceModal limitinvoice={turnOverPopup} closeSuccess={() => {
        toggleTurnOverPopup(false)
      }}>
        <div className='text-center'>
          <p className='font-size-18 font-wt-800 mt-2'>Set Turnover Range</p>
          <div className='row px-4' >
            <div className="col-md-6">
              <NewInput isAstrix={true} type={"number"} label={"Minimum Range"}
                name={"minTurnoverRange"} value={data.minTurnoverRange} error={errors.minTurnoverRange}
                onChange={handleChange} />
            </div>
            <div className="col-md-6">
              <NewInput isAstrix={true} type={"number"} label={"Maximum Range"}
                name={"maxTurnoverRange"} value={data.maxTurnoverRange} error={errors.maxTurnoverRange}
                onChange={handleChange} />
            </div>
          </div>
          <div className='row d-flex justify-content-center'>
            <button className={`mt-2 new-btn w-30 py-2 px-1 text-white cursor`} onClick={() => {
              if (data.minTurnoverRange && data.maxTurnoverRange) {
                if (data.minTurnoverRange / 1 > data.maxTurnoverRange / 1) {
                  return toastDisplay("Minimum range should be less than maximum range value", "info")
                }
                let temp = turnOverFilter
                temp.push({
                  min: Intl.NumberFormat("en", { notation: 'compact' }).format(data.minTurnoverRange),
                  max: Intl.NumberFormat("en", { notation: 'compact' }).format(data.maxTurnoverRange), is_checked: false,
                  minVal: data.minTurnoverRange, maxVal: data.maxTurnoverRange
                })
                setturnOverFilter(temp)
                toggleTurnOverPopup(false)
              }
              else {
                toastDisplay("Fill all fields", "info")
              }
            }}>Continue</button>
          </div>
        </div>
      </FinanceInvoiceModal>) : null}

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <a className="cursor"
        onClick={() => {
          // if (window.location.href?.includes("tradeDiscovery")) {
          //   window.location = "/dashboard"
          // }
          // else {
          if (showBuyerdetails.show) {
            setShowBuyerdetails({
              show: false,
              data: {}
            })
          } else {
            if (window.location.href?.includes("tradeDiscovery")) {
              window.location = "/dashboard"
            }
            else {
              window.location = "/buyerManagement"
            }
          }
          // }
        }}
      ><img
          src={"assets/images/back.png"} alt="Back" height={20} width={20} /></a>
      {showBuyerdetails.show &&
        <>
          <TTVBuyerList prevScreenData={data} showBuyerdetails={showBuyerdetails} filter={filter} showLoader={showLoader} setShowLoader={setShowLoader} userTokenDetails={userTokenDetails} />
        </>
      }
      {!showBuyerdetails.show &&
        <>
          <div className='d-flex row mx-auto mt-3'>
            <div className=' w-80 align-items-center justify-content-center position-relative'>
              <NewInput label={"Search by HSN Code, Company Name"}
                name={"search"} value={filter.search} className={'w-100 '}
                onChange={(event) => {
                  setFilter({ ...filter, search: event.target.value })
                }} onKeyDown={handleKeyDown} />
            </div>
            <div className='w-20 align-items-center'
            >
              <button className={` new-btn w-100 py-2 px-4 text-white cursor`} onClick={searchbuyers}>Search</button>
            </div>
          </div>
          {/* <div className='col-md-10 mx-auto mt-4'>
            <div className='findbuyersDiv'>
              <div className="input-group mb-3 currency mt-3 pt-4 px-4 pb-5">
                <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                <input type="text" name='search' value={filter.search}
                  onKeyDown={handleKeyDown} onChange={(event) => {
                    setFilter({ ...filter, search: event.target.value })
                  }}
                  className="form-control border-start-0" placeholder="Search by HSN Code, Company Name, Industry type" />
              </div>
              <div className="d-flex gap-4 searchBuyerDiv">
                <button className={`my-4 new-btn w-100 py-2 px-2 text-white cursor`} onClick={searchbuyers}>Search buyers</button>
              </div>
            </div>
          </div> */}

          <div className='row mt-3 ml-3'>
            <div className='card p-4 w-35 rounded '>
              <div className='d-flex flex-row justify-content-between'>
                {tabs.map((item, index) => {
                  if (hsndigits === item) {
                    return <div className='' >
                      <button className={`new-btn w-100 h-100  py-1 px-4 text-white cursor`}>{item}</button>
                    </div>
                  } else {
                    return <div className={`${tabs.length - 1 !== index ? 'border-right' : ''} p-2`} onClick={() => { setHSNdigits(item) }}>
                      <label className='font-size-14 font-wt-600'>{item}</label>
                    </div>
                  }

                })}
              </div>
              <div className="input-group mb-3 currency col-md-12 p-0 mt-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                <input type="text" name='search'
                  onKeyDown={(e) => {
                    e.persist();
                    if (e.keyCode === 13) {
                      getHSCodes(e.target.value)
                    }
                  }}
                  className="form-control border-start-0" placeholder="Search by HSN Code" />
              </div>
              <div className='hsndiv'>
                {HS_CODES.map((item, index) => {
                  console.log('HSFilters', item);
                  return <div className='d-flex flex-row gap-2' >
                    <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                      const updatedHS_CODES = [...HS_CODES]; // create a new array
                      updatedHS_CODES[index] = {
                        ...updatedHS_CODES[index],
                        is_checked: !updatedHS_CODES[index].is_checked
                      };
                      setHS_CODES(updatedHS_CODES);
                    }} />
                    <label className='font-size-14 font-wt-600'>{item.HS_CODE + "-" + item.Description}</label>
                  </div>
                })}
              </div>
            </div>
            <div
              style={{
                height: '16.5rem'
              }}
              className='card p-4 w-25 rounded ml-3' >
              <div className='d-flex flex-row justify-content-between'>
                <label className='font-size-14 font-wt-600'>Turnover</label>
                <img
                  onClick={() => { toggleTurnOverPopup(true) }}
                  src={'/assets/images/add_black_icon.png'} height={20} width={20} className='mr-2 cursor' />
              </div>
              <div className=''>
                {turnOverFilter.map((item, index) => {
                  return <div className='d-flex flex-row gap-2 mt-2'>
                    <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                      const updatedTO = [...turnOverFilter]; // create a new array
                      updatedTO[index] = {
                        ...updatedTO[index],
                        is_checked: !updatedTO[index].is_checked
                      };
                      setturnOverFilter(updatedTO);
                    }} />
                    <label className='font-size-14 font-wt-600'>{item.min + " - " + item.max}</label>
                  </div>
                })}
              </div>
            </div>
            <div className='card p-4 w-20 h-50 rounded ml-3' >
              <div className=''>
                <div className='d-flex flex-row gap-2 mt-2'>
                  <img src={filter.showExports ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                    setFilter({ ...filter, showExports: true, showImports: false })
                  }} />
                  <label className='font-size-16 font-wt-600 text-underline'>{"Total Exporters"}</label>
                </div>
                <label className='font-size-26 font-wt-600 text-underline ml-5'>{countData.exports || 0}</label>
              </div>

              <div className=''>
                <div className='d-flex flex-row gap-2 mt-2'>
                  <img src={filter.showImports ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                    setFilter({ ...filter, showExports: false, showImports: true })
                  }} />
                  <label className='font-size-16 font-wt-600 text-underline'>{"Total Importers"}</label>
                </div>
                <label className='font-size-26 font-wt-600 text-underline ml-5'>{countData.imports || 0}</label>
              </div>
            </div>
          </div>

          <div className='card dashboard-card shadow-sm mt-5 mx-3'>
            <div className="p-2 card border-0 rounded-3 ">
              <div className='d-flex'>
                <div className='w-75'>
                  <div className='position-relative w-25'>
                    <NewSelect isAstrix={false} label={""}
                      selectData={[{ label: "Count Descending", val: "count_desc" }, { label: "Count Ascending", val: "count_asc" }]} name={"countSort"}
                      value={data.countSort} optionLabel={"label"} optionValue={'val'}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className='w-25 d-flex'>
                  <div className='position-relative w-50'>
                    <NewInput isAstrix={false} type={"date"} label={""}
                      name={"countFrom"} value={data.countFrom}
                      onChange={handleChange} />
                  </div>
                  <div className='position-relative w-50'>
                    <NewInput isAstrix={false} type={"date"} label={""}
                      name={"countTo"} value={data.countTo}
                      onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div>
                <img src='/assets/images/warning.png' height={20} width={20} className='cursor mr-2' />
                <label className='font-size-12 font-wt-400'>{filter.showImports ? `Below list of countries shows no. of buyers.` :
                  `Below list of countries shows no. of exporters.`}</label>
              </div>
              <div className="p-4 card border-1 rounded-3 m-2">
                <div className='row'>
                  {regions.map(item => {
                    return <div className={`d-flex flex-row align-items-center w-auto p-2 ${region == item.region ? 'regionDiv' : ''} m-2`} onClick={() => setRegion(item.region)}>
                      <label className='font-size-15 font-wt-400 m-0'>{item.region}</label>
                      <img src='/assets/images/arrowdown.png' className='ml-2' />
                    </div>
                  })}
                </div>
                <div className='row'>
                  {dbdata.map(item => {
                    return <div className='text-center col-md-2 p-2 regionDiv m-2' onClick={() => {
                      setShowBuyerdetails({
                        show: true,
                        data: {
                          ...item, HS_CODES: HS_CODES.filter(item => item.is_checked).map(item => item.HS_CODE),
                          TURNOVER_RANGE: turnOverFilter.filter(item => item.is_checked),
                          searchParam: filter.search,
                        }
                      })
                    }}>
                      <ReactCountryFlag
                        countryCode={item.sortname}
                        style={{ width: '50px', height: '50px', "borderRadius": "15px" }} svg />
                      {/* <img src={`/assets/images/${item.name}.svg`} className="rounded my-2" /> */}
                      <p className='font-size-14 font-wt-500 m-0'>{item.country}</p>
                      <p className='font-size-14 font-wt-500 m-0'>{item.total_buyers}</p>
                    </div>
                  })}
                </div>
              </div>
            </div>
          </div>

          {
            recentViewed?.length ?
              <div
                className='card dashboard-card shadow-sm mt-5 mx-3'
              >
                <label className='font-size-15 font-wt-600 mb-4'>Recent</label>
                <div
                  className='d-flex flex-row w-100 justify-content-evenly flex-wrap'
                >
                  {recentViewed.map((i, j) => {
                    return (
                      <div className='w-30 p-3 mb-3'
                        style={{
                          border: "1px solid #EEEEEE",
                          borderRadius: "8px"
                        }}
                      >
                        <p className='font-size-14 font-wt-600 color0C0C0C'>{i.details.expName || i.details.buyerName}</p>
                        <div
                          style={{
                            border: "1px solid #95E7FF",
                            borderRadius: "10px"
                          }}
                        >
                          <div className='w-100 row p-0 m-0 highlightedRow '>
                            <div className='col-8'>
                              <label className='font-size-14 font-wt-600 color0C0C0C' >No. of Shipment</label>
                            </div>
                            <div className='col-4 text-right'>
                              <label className='font-size-14 font-wt-600 color0C0C0C'>{i.details.total_shipments}</label>
                            </div>
                          </div>

                          <div className='w-100 row p-0 m-0'>
                            <div className='col-8'>
                              <label className='font-size-14 font-wt-600 color0C0C0C' >HSN Code</label>
                            </div>
                            <div className='col-4 text-right'>
                              <label className='font-size-14 font-wt-600 color0C0C0C'>{i.details.uniqueHSCode}</label>
                            </div>
                          </div>

                          <div className='w-100 row highlightedRow p-0 m-0 '>
                            <div className='col-8'>
                              <label className='font-size-14 font-wt-600 color0C0C0C' >Location</label>
                            </div>
                            <div className='col-4 text-right'>
                              <label className='font-size-14 font-wt-600 color0C0C0C'>{i.details.port}</label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            onClick={() => {
                              localStorage.setItem("ttvBuyerInfo", JSON.stringify({ ...i.details, purchased: true }))
                              window.open(platformURL + `/userDetail?type=${!i.details.expName ? "buyer" : "exporter"}`, '_blank');
                            }}
                            className={`mt-4 new-btn w-100 py-2 px-2 text-white cursor`} >View Details</button>
                        </div>
                      </div>
                    )
                  })}

                </div>
              </div> : null
          }

        </>
      }

    </>
  )
}

export default FindBuyers