import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, handleFileCommonFunction, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { TRBackendServerIP, platformBackendUrl } from '../../urlConstants';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const OpenMoneyBankIntegration = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  let addNew = queryParams.get('addNew')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(3)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: addNew ? true : false })
  const [ledgerUploadPopup, setLedgerUploadPopup] = useState({ show: false })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [parent, setParent] = useState([])
  const [states, setStates] = useState([])


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      parent: 'Bank Accounts',
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getAccountingLedgers', objectAPI).then((result) => {
      console.log('running getAccountingLedgers api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getAccountingLedgers', e);
      setshowLoader(false)
    });
    SetCache("accountingGroupsFilterData", filterData)
    call('POST', 'getWebtelStatesMaster').then(res => {
      setStates(res)
    })
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getAccountingMastersParentGroups', { userId }).then(res => {
      setParent(res)
    })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', selectedChat.wcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
    }

    let err = {}
    if (tab / 1 == 0) {
      let validateFields = ["name", "parent"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = 'Mandatory field'
        }
      }
    }
    if (!Object.keys(err).length) {
      if (tab / 1 == 4) {
        setshowLoader(true)
        call('POST', 'saveAccountingMastersLedger', {
          ...data, userId
        },
        ).then(res => {
          toastDisplay(data.id ? "Ledger Updated" : "Ledger Created", "success", () => {
            if (serarchParam || addNew) {
              window.opener.postMessage('RefreshData', window.location.origin);
              window.close()
            }
            setshowLoader(false)
            toggleCreateGroupView({ show: false })
            setData({})
            setTab(3)
            setRefresh(refresh + 1)
          })
        })
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors({ ...err })
  }

  async function processJSONFileObj() {
    setshowLoader(true)
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let json = JSON.parse(e.target.result);
        json = json.Ledger
        if (json.length) {
          await call('POST', 'saveAccountingMastersLedger', { userId, tallyData: json })
          toastDisplay('Data uploaded successfully', "success", () => {
            setLedgerUploadPopup({ show: false })
            setRefresh(refresh + 1)
          })
        }
        else {
          toastDisplay('Data not found', "error")
        }
        setshowLoader(false)
      } catch (error) {
        console.error('ErrorInprocessJSONFileObj', error);
        toastDisplay('Something went wrong', "error")
        setshowLoader(false)
      }
    };
    reader.readAsText(data.tallyLedgerJsonFile);
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="openMoneyBankIntegration" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Banking > OpenMoney Integration`}
              userTokenDetails={userTokenDetails} />
            {ledgerUploadPopup.show && (
              <FinanceInvoiceModal
                limitinvoice={ledgerUploadPopup.show}
                setLimitinvoice={() => { setLedgerUploadPopup({ show: false }) }}
                closeSuccess={() => { setLedgerUploadPopup({ show: false }) }}
              >
                <label className="text-center font-size-16 font-wt-600 w-100">Import From Tally</label>
                <label className="text-center font-size-13 font-wt-600 w-100 mb-4">Please upload the ledger JSON file exported using the tally TCP file.</label>
                <div className="d-flex justify-content-center">
                  <div className='col-10'>
                    <FileInput isEditable={true} name={"tallyLedgerJsonFile"} value={data.tallyLedgerJsonFile}
                      onChange={e => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader, ["json"])}
                      onUploadCancel={() => setData({ ...data, [`tallyLedgerJsonFile`]: null })}
                    />
                    {errors[`tallyLedgerJsonFile`] ? <div class="text-danger mt-2 font-size-12">
                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                      <b>{errors[`tallyLedgerJsonFile`]}</b></div> : ''}
                    <div className='d-flex justify-content-center mt-4'>
                      <button disabled={!data.tallyLedgerJsonFile?.name} className={`new-btn w-60 py-2 px-2 text-white cursor`}
                        onClick={processJSONFileObj}
                      >Submit</button>
                    </div>
                  </div>
                </div>
              </FinanceInvoiceModal>
            )}

            <div className='mt-4' style={{}} >
              {createGroupView.show ? null : <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                  isAdditionalButton={false}>
                  <div className="d-flex gap-3">
                    {/* <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setLedgerUploadPopup({ show: true })}>Import From Tally</button>
                    </div> */}
                    <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => toggleCreateGroupView({ show: true })}>Create New</button>
                    </div></div>
                </Filter>
              </div>}

              {createGroupView.show ?
                <>
                  <i
                    onClick={() => {
                      toggleCreateGroupView({ show: false })
                      setData({})
                      setTab(0)
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                  <div className="bg-white pb-2">
                    <div className="d-flex justify-content-center pt-5 mb-1" >
                      <FormProgressBar
                        tabs={
                          [{ name: "Basic Details" }, { name: "Statutory Details" }, { name: "Mailing Details" }
                            , { name: "Banking Details" }, { name: "Tax Registration Details" }
                          ]} activeTab={tab} label={"name"} handleClick={(i, index) => { handleValidation(index) }}
                        separationWidth={'3rem'}
                      />
                    </div>
                    {tab / 1 == 0 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput isAstrix type={"text"} label={"Name"}
                          name={"name"} value={data.name} error={errors.name}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput type={"text"} label={"Alias"}
                          name={"alias"} value={data.alias} error={errors.alias}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Under'}
                          Id={`parent`}
                          isAstrix
                          optiondata={parent}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `parent`, "name"))
                          }}
                          value={data[`parent`] ? [data[`parent`]] : []}
                          name={`parent`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`parent`]}
                        />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={[{ name: 'Not Applicable' }, { name: 'Discount' }, { name: 'Invoice Rounding' }]} label={"Type of Ledger"}
                          name={"type"} value={data.type} error={errors.type} optionLabel={"name"} optionValue={"name"}
                          onChange={handleChange} />
                      </div>
                    </div> : null}
                    {tab / 1 == 1 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewSelect selectData={[{ name: 'GST' }]}
                          label={"Tax Type"} name={"taxType"} value={data.taxType} error={errors.taxType} optionLabel={"name"} optionValue={"name"}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={[{ name: 'Goods' }, { name: 'Goods and Services' }, { name: 'Services' }]}
                          label={"Appropriate For"} name={"taxAppropriate"} value={data.taxAppropriate} error={errors.taxAppropriate} optionLabel={"name"} optionValue={"name"}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={[{ name: 'Based on Quantity' }, { name: 'Based on Value' }]}
                          label={"Method of Calculation"} name={"calculationMethod"} value={data.calculationMethod} error={errors.calculationMethod} optionLabel={"name"} optionValue={"name"}
                          onChange={handleChange} />
                      </div>
                    </div> : null}
                    {tab / 1 == 2 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput
                          label={"Name"} name={"mailingName"} value={data.mailingName} error={errors.mailingName}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Email Id"} name={"mailingEmailId"} value={data.mailingEmailId} error={errors.mailingEmailId}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Contact Number"} name={"mailingContactNo"} value={data.mailingContactNo} error={errors.mailingContactNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewTextArea
                          label={"Address"} name={"mailingAddress"} value={data.mailingAddress} error={errors.mailingAddress}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={states} optionLabel={"StateName"} optionValue={"StateName"}
                          label={"State"} name={"mailingState"} value={data.mailingState} error={errors.mailingState}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={countrys} optionLabel={"name"} optionValue={"name"}
                          label={"Country"} name={"mailingCountry"} value={data.mailingCountry} error={errors.mailingCountry}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Pincode"} name={"mailingPinCode"} value={data.mailingPinCode} error={errors.mailingPinCode}
                          onChange={handleChange} />
                      </div>
                    </div> : null}
                    {tab / 1 == 3 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput
                          label={"Account Holder Name"} name={"bankACHolderName"} value={data.bankACHolderName} error={errors.bankACHolderName}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Account No"} name={"bankACNo"} value={data.bankACNo} error={errors.bankACNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"IFSC Code"} name={"bankIFSC"} value={data.bankIFSC} error={errors.bankIFSC}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Swift Code"} name={"swiftCode"} value={data.swiftCode} error={errors.swiftCode}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Bank Name"} name={"bankName"} value={data.bankName} error={errors.bankName}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Branch"} name={"bankBranchName"} value={data.bankBranchName} error={errors.bankBranchName}
                          onChange={handleChange} />
                      </div>
                      {data.parent?.includes("Bank Acc") ? <>
                        <div>
                          <label className='font-size-16 font-wt-500 my-3'>Enter OpenMoney account details below</label>
                          {/* <img onClick={() => {
                            setData({ ...data, razorpayXConnected: !data.razorpayXConnected })
                          }} className='cursor mx-3' src={data.razorpayXConnected ? 'assets/images/checked-green.png' : 'assets/images/empty-check.png'} /> */}
                        </div>
                        {/* <>
                          <div className="col-md-3">
                            <NewInput
                              label={"Razorpay Key Id"} name={"razorpayKeyId"} value={data.razorpayKeyId} error={errors.razorpayKeyId}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4">
                            <NewInput
                              label={"Razorpay Key Secret"} name={"razorpayKeySecret"} value={data.razorpayKeySecret} error={errors.razorpayKeySecret}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            {data.razorpayKeyId && data.razorpayKeySecret ?
                              <label className='font-size-15 font-wt-400 text-color-value'>{`Whitelist Tradereboot IP on RazorpayX - `}
                                <label className='font-size-15 font-wt-400 text-color-label'>{TRBackendServerIP}</label></label> : null}
                          </div>
                          <div className="col-md-3">
                            {data.razorpayKeyId && data.razorpayKeySecret ?
                              <label className='font-size-15 font-wt-400 text-color-value'>{`Add below URL in webhook on RazorpayX - `}
                                <label className='font-size-15 font-wt-400 text-color-label'>{`${platformBackendUrl}/razorpayx`}</label></label> : null}
                          </div>
                        </> */}
                        {/* <div>
                          <label className='font-size-15 font-wt-600 my-3'>This bank account is connected to my OpenMoney account</label>
                          <img onClick={() => {
                            setData({ ...data, openMoneyConnected: !data.openMoneyConnected })
                          }} className='cursor mx-3' src={data.openMoneyConnected ? 'assets/images/checked-green.png' : 'assets/images/empty-check.png'} />
                        </div> */}
                        <>
                          <div className="col-md-3">
                            <NewInput
                              label={"OpenMoney Key Id"} name={"openMoneyKeyId"} value={data.openMoneyKeyId} error={errors.openMoneyKeyId}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4">
                            <NewInput
                              label={"OpenMoney Key Secret"} name={"openMoneyKeySecret"} value={data.openMoneyKeySecret} error={errors.openMoneyKeySecret}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            {data.openMoneyKeyId && data.openMoneyKeySecret ?
                              <label className='font-size-15 font-wt-400 text-color-value'>{`Add below URL in webhook on OpenMoney - `}
                                <label className='font-size-15 font-wt-400 text-color-label'>{`${platformBackendUrl}/openmoney`}</label></label> : null}
                          </div>
                        </>
                      </> : null}
                    </div> : null}
                    {tab / 1 == 4 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput
                          label={"PAN/IT No"} name={"panITNo"} value={data.panITNo} error={errors.panITNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"GSTIN/UIN"} name={"GSTINUIN"} value={data.GSTINUIN} error={errors.GSTINUIN}
                          onChange={handleChange} />
                      </div>
                    </div> : null}

                    <div className='d-flex justify-content-center'>
                      <button type="button"
                        onClick={() => {
                          handleValidation(undefined)
                        }}
                        className={` new-btn w-15 py-2 px-2 text-white mb-5`}>
                        {tab / 1 == 4 ? (createGroupView.viewUpdate ? "Update" : "Submit") : "Next"}
                      </button>
                    </div>

                  </div></> :
                <>
                  <div>
                    <NewTablev2 filterData={filterData} columns={[
                      { subColumns: 'Date', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Name' },
                      { subColumns: 'Alias' },
                      { subColumns: "Bank Name" },
                      { subColumns: "Bank IFSC Code" },
                      { subColumns: "Bank Swift Code" },
                      { subColumns: 'Link With OpenMoney' }
                    ]} >
                      {dbData.map((item, j) => {
                        return (
                          <tr>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{moment(item.createdAt).format("DD/MM/YYYY")}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.name}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.alias || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.bankName || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.bankIFSC || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.swiftCode || "-"}</label></td>
                            <td>
                              <div className='row '>
                                <a className='mt-2'>
                                  <button type="button"
                                    onClick={() => {
                                      setData({ ...item.details, id: item.id })
                                      toggleCreateGroupView({ show: true, viewUpdate: true })
                                    }}
                                    className={` border-0 mb-2 ${item.details.openMoneyKeyId ? "disabledQuotebtn text-dark" : "text-white enableQuotebtn"}`}>
                                    {item.details.openMoneyKeyId ? "Linked" : "Link Now"}
                                  </button></a>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                </>}

            </div>
          </main>
        </div >
      </div >

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(OpenMoneyBankIntegration)