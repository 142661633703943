import React from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";

const Invoice = ({ userTokenDetails, navToggleState }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoiceDiscouting" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discouting"}
              userTokenDetails={userTokenDetails} />
            <div className=''>
              <h3>Invoice Discounting</h3>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Invoice)