import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { financialYearMonths, handleFileCommonFunction, handleFileWithAsyncAwait, handleMultipleFile, mergeMultiplePdfs, monthsNoMapping, most_used_currencies, multiSelectEventHandler, replaceFileBase64WithFileObj } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, NewTextArea, SelectForTable } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl, platformURL } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import Papa from 'papaparse';
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import { NewTable } from "../../utils/newTable";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import Filter from "../InvoiceDiscounting/components/Filter";
import MultipleSelect from "../../utils/MultipleSelect";
import { getBulkBreakMailTemplate } from "./BulkBreakTemplate";
import { contractTemplateTypes } from "../contractManagement/CreateWorkOrderContract";
import { setTraderType } from "../../utils/cookieHelper";

export let wcyesnoqsts = {
  "qst1": {
    label: "Women Entrepreneur"
  },
  "qst2": {
    label: "ISO certified"
  },
  "qst3": {
    label: "Business continuity indemnity policy"
  },
  "qst4": {
    label: "The agreement between the client and the debtor does not prohibit assignment of receivables by the client to a third party including a Factoring Company."
  },
  "qst5": {
    label: "Any irregular and slow payments in the past by the proposed debtor."
  },
  "qst6": {
    label: "Are there any overdue invoices outstanding on account of the debtor in the client’s books."
  },
  "qst7": {
    label: "Whether there have been any disputes with the debtor in recent past with respect to the goods supplied by the client to the debtor or otherwise."
  },
  "qst8": {
    label: "Inform whether direct contact allowed"
  }
}

const tabsArr = [
  { name: "Select Buyers" },
  { name: "Commodity Details" },
  { name: "Contract Template" },
  { name: "Email Template Details" },
  // { name: "Review" }
]

export const reviewBusiness = [
  { "name": "Business Name", val: "businessName" },
  { "name": "Aadhar No", val: "aadharNo" },
  { "name": "Contact Person", val: "contactPerson" },
  { "name": "PAN No", val: "panNo" },
  { "name": "Contact Number", val: "contactNo", unit: "phoneCode" },
  { "name": "Constitution", val: "constitution" },
  { "name": "Email Id", val: "emailId" },
  { "name": "Factory Premises", val: "factoryPremises" },
  { "name": "Address", val: "addressLine1", after: "addressLine2" },
  { "name": "Start Date of Business", val: "startDateOfBusiness" },
  { "name": "Industry Type", val: "industry" },
  { "name": "Competition", val: "competition" },
  { "name": "Sector", val: "sector" },
  { "name": "Product Details", val: "productDescription" },
  { "name": "Sub-Sector", val: "subSector" }
]

export const ownerReview = [
  { "name": "Owner Name", val: "ownerName" },
  { "name": "Category", val: "ownerCategory" },
  { "name": "Contact No.", val: "ownerContactNo", unit: "ownerPhoneCode" },
  { "name": "PAN No", val: "ownerPanNo" },
  { "name": "Email Id", val: "ownerEmailId" },
  { "name": "Aadhar No.", val: "ownerAadharNo" },
  { "name": "Address", val: "ownerAddressLine", after: "ownerAddressLine" },
  { "name": "Date of Birth", val: "ownerDOB" },
  { "name": "Net Worth", val: "ownerNetWorth" }
]

const briefFinancials = [
  { name: 'Sales' },
  { name: 'COGS' },
  { name: 'EBITDA' },
  { name: 'EBITDA %' },
  { name: 'PAT' },
  { name: 'PAT %' },
  { name: 'Trade Receivables' },
  { name: 'Trade Payables' },
  { name: 'Debtor Days' },
  { name: 'Creditors Day' },
  { name: 'Bank' }
]

export const facilityNames1 = [
  { name: 'CC (Cash Credit)' },
  { name: 'OD (Overdraft)' },
  { name: 'LC' },
  { name: 'BG' },
  { name: 'TL' },
]
export const facilityNames2 = [
  { name: 'ODFD' },
  { name: 'MEOD' },
  { name: 'WCDL' },
  { name: 'DRUL' },
  { name: 'FPC/FBD' },
]

const BulkBreak = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const formContinue = queryParams.get("formContinue")
  let commDetails = localStorage.getItem("commDetails")
  commDetails = JSON.parse(commDetails)

  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }

  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const user_avatar = userToken.userAvatar ? userToken.userAvatar : null
  const ttvExporterCode = userTokenDetails.ttvExporterCode ? userTokenDetails.ttvExporterCode : ''

  let jj = -1

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
    currency: commDetails?.rateCurrency || "INR",
    quantityToBulkBreak: commDetails?.quantityToBulkBreak,
    perUnitPriceFromContract: commDetails?.perUnitPriceFromContract,
    createContractAutomatically: true
  })
  const [errors, setErrors] = useState({})
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [signdoc, setSigndoc] = useState(false);
  const [filteredSearch, setFilteredSearch] = useState([])
  const [filterData, setFilterData] = useState({})
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: '' })
  const [refresh, setRefresh] = useState(0)
  const [count, setCount] = useState(0)


  const [finActionTab, setFinancierActionTab] = useState(0)
  const [draftData, setDraftData] = useState([])
  const [shouldSaveCache, toggleShouldSaveCache] = useState(formContinue ? false : true)
  const [cacheId, setCacheId] = useState(formContinue || null)
  const [dbData, setDbData] = useState([])
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [selectedBuyersData, setSelectedBuyersData] = useState([])
  const [selecter, toggleSelecter] = useState({})
  let [tableColumns, setTableColumns] = useState([])
  let [mailDetails, setMailDetails] = useState({})
  const [referCode, setReferCode] = useState(null)

  useEffect(() => {
    let tempSelecter = [
      { subColumns: "Buyer Name", },
      { subColumns: "Shipments", },
      { subColumns: "HSN Code", },
      { subColumns: "Address", },
      { subColumns: "Country" }
    ]
    setTableColumns([{
      subColumns: !selecter.selectAll ? 'Select All' : "Unselect All",
      subColumnsOnClick: () => handleSelectAllBuyers()
    }, ...tempSelecter])
  }, [selecter, dbData])

  async function handleSelectAllBuyers() {
    let tempSelectedBuyers = []
    let tempSelectedBuyersData = []
    if (selecter.selectAll) {
      setSelectedBuyers([])
      setSelectedBuyersData([])
    }
    else {
      for (let index = 0; index < dbData.length; index++) {
        const element = dbData[index];
        tempSelectedBuyers.push(element.id)
        tempSelectedBuyersData.push(element)
      }
    }
    setSelectedBuyers([...tempSelectedBuyers])
    setSelectedBuyersData([...tempSelectedBuyersData])
    toggleSelecter({ selectAll: !selecter.selectAll })
  }


  useEffect(() => {
    call('POST', 'getBuyerListFilters', { userId }).then(res => {
      console.log("getBuyerListFilters then", res);
      setFilterData(res)
    }).catch(err => { })
    call('POST', 'getuserprofiledata', { 'email': userEmail, 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      setReferCode(result?.refercode)
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    })
  }, [])

  const getBuyerDetails = () => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter,
      ttvExporterCode: ttvExporterCode,
      onlyBuyersWithEmailId: true
    }
    setshowLoader(true)
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(element.accordianId === "hsCodes" ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      setshowLoader(false)
      console.log('running getBuyersDetail api-->', result);
      setDbData(result.buyerData);
      setCount(result.countData);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });

  }

  useEffect(() => {
    getBuyerDetails()
  }, [page, refresh, filterData])

  function calculateContractAmt(tempData) {
    for (let index = 0; index < selectedBuyers.length; index++) {
      if (tempData[`quantity${index}`] && tempData[`ratePerUnit${index}`]) {
        tempData[`contractAmt${index}`] = tempData[`quantity${index}`] * tempData[`ratePerUnit${index}`]
      }
    }
    setData({ ...tempData })
  }

  const handleChange = async (event) => {
    event.persist()
    let tempData = data
    if (event.target.name === "commonRatePerUnit") {
      for (let index = 0; index < selectedBuyers.length; index++) {
        tempData[`ratePerUnit${index}`] = event.target.value
      }
      calculateContractAmt(tempData)
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name?.includes("ratePerUnit")) {
      tempData[event.target.name] = event.target.value
      calculateContractAmt(tempData)
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name?.includes("quantity")) {
      tempData[event.target.name] = event.target.value
      calculateContractAmt(tempData)
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  async function signUpUser(companyName, contactPerson, companyAddress, email) {
    return new Promise((resolve) => {
      let reqObject = {
        "typeId": 19,
        "organizationType": "foreign",
        companyName,
        contactPerson,
        companyAddress,
        email,
        "type": "finTech",
        "referralCode": referCode,
        "password": "Pa$$w0rd!",
        "passwordConfirm": "Pa$$w0rd!",
        "termsAndCondition": true,
        "techType": 1,
        "accountStatus": "inactive"
      }
      call('POST', 'registration', reqObject).then((result) => {
        resolve(true)
      }).catch(err => {
        resolve(true)
      })
    })
  }

  async function handleValidation() {
    let err = {}
    if (tab / 1 == 0 && !selectedBuyers.length) {
      err["selectedBuyers"] = `Select atleast 1 buyer to continue`
      toastDisplay(err["selectedBuyers"], "error")
    }
    if (tab / 1 == 1) {
      let tempMailDetails = {}
      let entriesValidated = true
      for (let index = 0; index < selectedBuyers.length; index++) {
        const element = selectedBuyers[index];
        if (!(data[`ratePerUnit${index}`] > 0 && data[`quantity${index}`] > 0 && data[`contractAmt${index}`] > 0)) {
          entriesValidated = false
        }
        else {
          tempMailDetails[selectedBuyersData[index].id] = {
            to: [selectedBuyersData[index].buyerEmail || "NA"],
            cc: [],
            bcc: [],
            subject: `New Contract Created for ${commDetails.commodity_pretty_name} commodity`,
            html: getBulkBreakMailTemplate({
              ...selectedBuyersData[index], ...commDetails, quantity: data[`quantity${index}`], ratePerUnit: data[`ratePerUnit${index}`],
              totalPrice: data[`contractAmt${index}`], link: 'www.google.com', userName,
              link: `${platformURL}/contractManagementList`,
              currency: data.currency
            }),
            attachments: [null]
          }
        }
      }
      setMailDetails({ ...tempMailDetails })
      if (!entriesValidated) {
        err['entriesValidated'] = 'Please fill all details to continue'
        toastDisplay(err["entriesValidated"], "error")
      }
      else {
        setData({ ...data, selectedBuyerId: selectedBuyersData[0]["id"] })
      }
    }
    if (tab / 1 == 2) {
      if (!data[`selectedContractTemplate`]) {
        err["selectedContractTemplate"] = "Mandatory Field"
      }
    }
    if (!Object.keys(err).length) {
      if (tab / 1 == 3) {
        setshowLoader(true)
        for (let index = 0; index < selectedBuyersData.length; index++) {
          const element = selectedBuyersData[index];
          // onboard all selectedbuyers to get importer user id to map in contract
          await signUpUser(element.buyerName, element.buyerName, element.buyerAddress, element.buyerEmail)
          if (data.createContractAutomatically) {
            let formData = new FormData()
            formData.append("contractType", "")
            formData.append("paymentMode", "")
            formData.append("quantity0", data[`quantity${index}`])
            formData.append("user_type", userTypeId)
            formData.append("supplierEmail", userEmail)
            formData.append("exporter_id", userId)
            formData.append("supplierName", userName)
            formData.append("buyerEmail", element.buyerEmail)
            formData.append("importer_id", "NA")
            formData.append("buyerName", element.buyerName)
            formData.append("buyerAddress", element.buyerAddress)
            formData.append("contractAlias", commDetails.commodity_pretty_name)
            formData.append("commCatId0", commDetails.category_id)
            formData.append("commodity0", commDetails.id)
            formData.append("commodity_pretty_name0", commDetails.commodity_pretty_name)
            formData.append("priceType0", 1)
            formData.append("price0", data[`ratePerUnit${index}`])
            formData.append("contractCopy", "Yes")
            formData.append("totalPrice", data[`contractAmt${index}`])
            let selectedCurrency = most_used_currencies.filter(i => {
              if (i.code === data.currency) { return true }
            })?.[0]
            formData.append("currency", `${selectedCurrency.name}:${selectedCurrency.symbol}:${selectedCurrency.code}`)
            formData.append("specification", JSON.stringify(commDetails.specifications))
            formData.append("contractTnc", JSON.stringify({ "title": { name: 'Title', value: 'Description' } }))
            formData.append("docCategoryACL", JSON.stringify({}))
            formData.append('multComm', JSON.stringify([null]))
            formData.append('specOrder', JSON.stringify(commDetails.spec_order_string?.length ? [commDetails.spec_order_string.split(":")] : []))
            formData.append('contractTemplate', 'Bulk Contract')
            formData.append('userName', userName)
            formData.append("mailDetails", JSON.stringify(mailDetails[element.id]))
            try {
              await call('POST', 'initContract', formData)
            } catch (error) {
              console.log("errorOccuredWhileCreatinContractttttttttttttt", error);
            }
            setshowLoader(false)
            toastDisplay("Contracts created successfully", "success", () => {
              window.location = '/contractManagementList'
            })
          }
          else {
            setshowLoader(false)
            let routeParams = {
              contractTemplate: data.selectedContractTemplate,
              commodityId: commDetails?.id,
              commodityCategoryId: commDetails?.category_id,
              buyerEmail: element.buyerEmail,
              currency: data.currency,
              price: data[`ratePerUnit${index}`],
              totalPrice: data[`contractAmt${index}`],
              mailDetails: mailDetails[element.id],
              quantity: data[`quantity${index}`],
              specifications: commDetails?.specifications,
              spec_order_string: commDetails?.spec_order_string
            }
            setTraderType("seller")
            window.open(`/contractManagementAdd?routeParams=${encodeURIComponent(JSON.stringify(routeParams))}`, "_blank")
          }
        }
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors(err)
  }

  function handleQuantityDistribution(type) {
    if (data.quantityToBulkBreak && data.quantityToBulkBreak > 0) {
      let tempData = {}
      for (let index = 0; index < selectedBuyers.length; index++) {
        const element = selectedBuyers[index];
        if (type === "Distribute Equally") {
          tempData[`quantity${index}`] = (data.quantityToBulkBreak / selectedBuyers.length).toFixed(2)
        }
        else if (type === "Get Bids") {
          tempData[`quantity${index}`] = data.quantityToBulkBreak
        }
        else {
          tempData[`quantity${index}`] = undefined
        }
      }
      return tempData
    }
    else {
      toastDisplay("First enter quantity to bulk break", "error")
      return false
    }
  }

  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaa", data, mailDetails, userTokenDetails);

  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}


      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyMastersStockItem" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Bulk Break"}
              userTokenDetails={userToken} />
            <div>
              <div className="card mt-1"
              >
                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabsArr} activeTab={tab} label={"name"}
                    separationWidth={'8rem'} handleClick={(i, index) => {
                      if (index < tab) {
                        setTab(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='d-flex row px-5 mb-5 pb-4 pt-4'>
                    <div className='filter-div'>
                      <Filter
                        filteredSearch={filteredSearch}
                        setFilteredSearch={setFilteredSearch}
                        filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                        showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                    </div>
                    <NewTablev2
                      filteredSearch={filteredSearch} setFilteredSearch={setFilteredSearch}
                      filterData={filterData} setFilterData={setFilterData}
                      columns={tableColumns}
                    // options={
                    // [
                    //   { name: "Edit", icon: "edit.png", onClick: onEdit },
                    //   { name: "Delete", icon: "delete.png", onClick: onDelete },
                    //   { name: "View Details", icon: "eye.png", onClick: onView }
                    //   ]
                    //   }
                    >
                      {dbData.map((item, j) => {
                        let hsnCodes = item.buyerHsnCode ? item.buyerHsnCode.split(",") : []
                        return (
                          <tr>
                            <td>
                              <div>
                                <img className='cursor' onClick={() => {
                                  let tempSelectedBuyers = selectedBuyers
                                  let tempSelectedBuyersData = selectedBuyersData
                                  if (tempSelectedBuyers.includes(item.id)) {
                                    tempSelectedBuyers = tempSelectedBuyers.filter(i => i != item.id)
                                    tempSelectedBuyersData = tempSelectedBuyersData.filter(i => i.id != item.id)
                                    setSelectedBuyers([...tempSelectedBuyers])
                                    setSelectedBuyersData([...tempSelectedBuyersData])
                                  }
                                  else {
                                    tempSelectedBuyers.push(item.id)
                                    tempSelectedBuyersData.push(item)
                                    setSelectedBuyers([...tempSelectedBuyers])
                                    setSelectedBuyersData([...tempSelectedBuyersData])
                                  }
                                }} src={
                                  `assets/images/${selectedBuyers.includes(item.id) ? 'checked-green' : 'empty-check'
                                  }.png`
                                } />
                              </div>
                            </td>
                            <td><label className="font-size-13 font-wt-500 text-break">{item.buyerName}</label></td>
                            <td><label className="font-size-13 font-wt-500 text-break">{item.totalShipments || 'NA'}</label></td>
                            <td><label className="font-size-13 font-wt-500 text-break">{<div
                              className='flex-row'
                            >
                              {hsnCodes.length ? hsnCodes.map((item, index) => {
                                return (
                                  <label className='bg-color1 p-1 mx-1 border-radius-5' >{item}</label>
                                )
                              }) : "NA"}
                            </div>}</label></td>
                            <td><label className="font-size-13 font-wt-500 text-break">{item.buyerAddress}</label></td>
                            <td><label className="font-size-13 font-wt-500 text-break">{item.countryName}</label></td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                    <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
                    <div
                      className="cursor" >
                      <label
                        onClick={() => {
                          window.open(`/buyerManagement?action=addNewBuyer`, "_blank");
                          localStorage.setItem('afterUpdateCloseTab', true)
                        }} className="font-size-14 text-color1 font-wt-600 mx-1 cursor">{"Add New Buyer"}</label>
                      <img
                        onClick={() => {
                          window.open(`/buyerManagement?action=addNewBuyer`, "_blank");
                          localStorage.setItem('afterUpdateCloseTab', true)
                        }}
                        className="cursor text-color1" src={'assets/images/right-arrow.png'} />
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='d-flex row px-5 mb-5 pb-4 pt-4'>
                    <div className="col-2 mb-4">
                      <NewInput type={"number"} label={`Bulk Break Quantity (${commDetails?.unit})`} name={"quantityToBulkBreak"} value={data.quantityToBulkBreak}
                        onChange={handleChange} />
                    </div>
                    <div className="col-2 mb-4">
                      <InputWithSelect type={"number"} label={`Rate Per (${commDetails?.unit})`} name={"commonRatePerUnit"} value={data.commonRatePerUnit}
                        onChange={handleChange} selectData={most_used_currencies} optionLabel={"code"} optionValue={"code"} selectName={"currency"}
                        selectValue={data.currency} />
                    </div>
                    <div className="col-8 d-flex row justify-content-end pb-2">
                      <div className="w-30"
                        onClick={() => {
                          let isActionPerformed = handleQuantityDistribution(!data.equallyDistribute ? 'Distribute Equally' : "")
                          if (isActionPerformed) {
                            calculateContractAmt({ ...data, ...isActionPerformed, equallyDistribute: !data.equallyDistribute, totalQuantity: false, manuallyEnter: false })
                          }
                        }}
                      >
                        <img
                          className='cursor mr-3' src={`assets/images/${data[`equallyDistribute`] === true ? 'checked-green' : 'empty-check'}.png`} />
                        <label className="font-size-14 font-wt-500" >Distribute Equally</label>
                      </div>
                      <div className="w-30"
                        onClick={() => {
                          let isActionPerformed = handleQuantityDistribution(!data.totalQuantity ? 'Get Bids' : "")
                          if (isActionPerformed) {
                            calculateContractAmt({ ...data, ...isActionPerformed, totalQuantity: !data.totalQuantity, equallyDistribute: false, manuallyEnter: false })
                          }
                        }}
                      >
                        <img
                          className='cursor mr-3' src={`assets/images/${data[`totalQuantity`] === true ? 'checked-green' : 'empty-check'}.png`} />
                        <label className="font-size-14 font-wt-500" >Get Bids</label>
                      </div>
                      <div className="w-30"
                        onClick={() => {
                          let isActionPerformed = handleQuantityDistribution("")
                          if (isActionPerformed) {
                            calculateContractAmt({ ...data, ...isActionPerformed, manuallyEnter: !data.manuallyEnter, equallyDistribute: false, totalQuantity: false })
                          }
                        }}
                      >
                        <img
                          className='cursor mr-3' src={`assets/images/${data[`manuallyEnter`] === true ? 'checked-green' : 'empty-check'}.png`} />
                        <label className="font-size-14 font-wt-500" >Enter Manually</label>
                      </div>
                    </div>
                    <div>
                      <NewTablev2 columns={[
                        { subColumns: "Buyer Name", },
                        { subColumns: "Buyer Country", },
                        { subColumns: "Quantity", },
                        { subColumns: `Rate Per (${commDetails?.unit})`, },
                        { subColumns: "Contract Amount" }]}>
                        {selectedBuyersData.map((item, j) => {
                          return (<tr>
                            <td><label className="font-size-13 font-wt-500 text-break">{item.buyerName}</label></td>
                            <td><label className="font-size-13 font-wt-500 text-break">{item.countryName}</label></td>
                            <td>
                              <div>
                                <InputForTable type={"number"} name={`quantity${j}`} value={data[`quantity${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            <td>
                              <div>
                                <InputWithSelectForTable type={"number"} label={`Rate Per (${commDetails?.unit})`} name={`ratePerUnit${j}`} value={data[`ratePerUnit${j}`]}
                                  onChange={handleChange} selectData={most_used_currencies} optionLabel={"code"} optionValue={"code"} selectName={"currency"}
                                  selectValue={data.currency} />
                                {(data.perUnitPriceFromContract && data[`ratePerUnit${j}`]) ?
                                  <label className="font-size-14 font-wt-500 mt-3">{`Margin ${(((data[`ratePerUnit${j}`] - data.perUnitPriceFromContract) / data.perUnitPriceFromContract) * 100).toFixed(2)} %`}</label>
                                  : null}
                              </div>
                            </td>
                            <td>
                              <div>
                                <InputForTable type={"number"} name={`contractAmt${j}`} value={data[`contractAmt${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                          </tr>)
                        })}
                      </NewTablev2>
                    </div>
                  </div>) : null}

                {tab === 2 ?
                  <div className="d-flex row px-5 mb-0 pb-4 pt-4">
                    <label className='text-left font-wt-500 font-size-16 w-100 text-center mb-4'>Select Contract Details</label>
                    <div className="position-relative col-4">
                      <MultipleSelect
                        Label={"Contract Template"}
                        Id={`selectedContractTemplate`}
                        optiondata={contractTemplateTypes}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `selectedContractTemplate`, "name"))
                        }}
                        value={data[`selectedContractTemplate`] ? [data[`selectedContractTemplate`]] : []}
                        name={`selectedContractTemplate`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`selectedContractTemplate`]}
                      />
                    </div>
                    <div className="col-8"
                      onClick={() => {
                        setData({ ...data, createContractAutomatically: !data.createContractAutomatically })
                      }}
                    >
                      <img
                        className='cursor mr-3' src={`assets/images/${data[`createContractAutomatically`] === true ? 'checked-green' : 'empty-check'}.png`} />
                      <label className="font-size-14 font-wt-500" >Create contracts automatically, i will edit details later on if required</label>
                    </div>
                  </div> : null}

                {tab === 3 ? (
                  <div className="d-flex row px-5 mb-0 pb-4 pt-4">
                    <div className="col-12">
                      {/* <label className='text-left font-wt-600 text-color1 font-size-16 w-100 text-center'>Send Mail</label> */}
                      <label className='text-left font-wt-600 text-color1 font-size-14  w-100 text-center mb-3'>(Mail template for your counterparty so they can also work on this contract with you collaboratively)</label>
                      <div className="position-relative">
                        <MultipleSelect
                          // filterOption={() => true}
                          // onInputChange={(e) => {
                          //   handleFilterOptions(e, "buyerName")
                          // }}
                          Label={"Select Buyer"}
                          Id={`selectedBuyerId`}
                          optiondata={selectedBuyersData}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `selectedBuyerId`, "id"))
                          }}
                          value={data[`selectedBuyerId`] ? [data[`selectedBuyerId`]] : []}
                          name={`selectedBuyerId`}
                          labelKey={"buyerName"}
                          valKey={"id"}
                          error={errors[`selectedBuyerId`]}
                        />
                      </div>
                      {data.selectedBuyerId ? <>
                        <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>To (add multiple email ids separared by comma)</label>
                        <NewTextArea label={""} value={mailDetails[data.selectedBuyerId]["to"]} onChange={(e) => {
                          let emailIds = e.target.value?.trim()
                          let tempMailDetails = mailDetails
                          tempMailDetails[data.selectedBuyerId]["to"] = emailIds ? emailIds.split(",") : []
                          setMailDetails({ ...tempMailDetails })
                        }} />
                        <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>CC (add multiple email ids separared by comma)</label>
                        <NewTextArea label={""} value={mailDetails[data.selectedBuyerId]["cc"]} onChange={(e) => {
                          let emailIds = e.target.value?.trim()
                          let tempMailDetails = mailDetails
                          tempMailDetails[data.selectedBuyerId]["cc"] = emailIds ? emailIds.split(",") : []
                          setMailDetails({ ...tempMailDetails })
                        }} />
                        <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>BCC (add multiple email ids separared by comma)</label>
                        <NewTextArea label={""} value={mailDetails[data.selectedBuyerId]["bcc"]} onChange={(e) => {
                          let emailIds = e.target.value?.trim()
                          let tempMailDetails = mailDetails
                          tempMailDetails[data.selectedBuyerId]["bcc"] = emailIds ? emailIds.split(",") : []
                          setMailDetails({ ...tempMailDetails })
                        }} />
                        <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>Subject</label>
                        <NewTextArea label={""} value={mailDetails[data.selectedBuyerId]["subject"]} onChange={(e) => {
                          let tempMailDetails = mailDetails
                          tempMailDetails[data.selectedBuyerId]["subject"] = e.target.value
                          setMailDetails({ ...tempMailDetails })
                        }} />
                        <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>Message</label>
                        <NewTextArea rows={10} label={""} value={mailDetails[data.selectedBuyerId]["html"]} onChange={(e) => {
                          let tempMailDetails = mailDetails
                          tempMailDetails[data.selectedBuyerId]["html"] = e.target.value
                          setMailDetails({ ...tempMailDetails })
                        }} />
                        {/* <label className='text-left font-wt-600 font-size-14 mb-3 w-100 '>Attachments</label>
                        {mailDetails[data.selectedBuyerId].attachments.map((i, j) => {
                          if (i === null) {
                            jj = jj + 1
                            return (<div className='row w-100 d-flex align-items-center mb-3' >
                              <div className='col-8' >
                                <FileInput multipleDragDropEnable={true}
                                  onMultipleDragDrop={async (e) => {
                                    let tempErr = errors
                                    let tempData = data
                                    for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                                      let element = e.target.files[indexx];
                                      let customEvent = {
                                        target: { name: `mailAttachment${data.selectedBuyerId}` + ":" + (j + indexx), files: [element] },
                                        persist: () => console.log("...")
                                      }
                                      let resp = await handleFileWithAsyncAwait(customEvent, setshowLoader)
                                      if (resp.status / 1 == 0) {
                                        tempErr = { ...tempErr, ...resp.msg }
                                      }
                                      if (resp.status / 1 == 1) {
                                        tempData = { ...tempData, ...resp.msg }
                                      }
                                    }
                                    setData(tempData)
                                    setErrors(tempErr)
                                    let tempMailDetails = mailDetails
                                    for (let index = 0; index < e.target.files.length - 1; index++) {
                                      tempMailDetails[data.selectedBuyerId].attachments.push(null)
                                    }
                                    setMailDetails({ ...tempMailDetails })
                                  }}
                                  name={`mailAttachment${data.selectedBuyerId}` + ":" + j} value={data[`mailAttachment${data.selectedBuyerId}` + ":" + j]} error={errors["mailAttachment" + ":" + j]}
                                  isEditable={true}
                                  onUploadCancel={() => setData({ ...data, [`mailAttachment${data.selectedBuyerId}` + ":" + j]: null })}
                                />
                                {errors[`mailAttachment${data.selectedBuyerId}` + ":" + j] ? <div class="text-danger mt-2 font-size-12">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors[`mailAttachment${data.selectedBuyerId}` + ":" + j]}</b></div> : ''}
                              </div>
                              <div
                                className="col-4"
                              >
                                {(mailDetails[data.selectedBuyerId].attachments.length - 1 > j) ?
                                  <img
                                    onClick={() => {
                                      let tempMailDetails = mailDetails
                                      let tempUpdatedArr = []
                                      for (let m = 0; m < tempMailDetails[data.selectedBuyerId].attachments.length; m++) {
                                        if (m != j) {
                                          tempUpdatedArr.push(null)
                                        }
                                      }
                                      tempMailDetails[data.selectedBuyerId].attachments = tempUpdatedArr
                                      setMailDetails({ ...tempMailDetails })
                                    }}
                                    className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                  /> : <img
                                    onClick={() => {
                                      let tempMailDetails = mailDetails
                                      tempMailDetails[data.selectedBuyerId].attachments.push(null)
                                      setMailDetails({ ...tempMailDetails })
                                    }}
                                    style={{ width: '22px', height: '22px' }}
                                    className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                              </div>
                            </div>)
                          }
                        })} */}
                      </> : null}
                    </div>
                  </div>
                ) : null}

                <div
                  style={tab === 0 ? { marginTop: '-3rem', marginBottom: '1.5rem' } : { marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  className={"d-flex finActionTabContainer mx-4 "} >
                  {tab / 1 != 3 ? <div
                    onClick={() => {
                      handleValidation()
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                  </div> : <div
                    onClick={() => {
                      setFinancierActionTab(0)
                      handleValidation()
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                  </div>}
                  <div
                    onClick={() => {
                      if (tab > 0) {
                        setTab(tab - 1)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor ${tab === 0 ? ' disable-bg ' : " "}`} >
                    <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                    <label
                      onClick={() => { toastDisplay('Application saved as draft', "success"); }}
                      className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 "} >Save as Draft</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                    }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor`} >
                    {finActionTab == 2 && draftData.length ? <div
                      style={{
                        width: '14rem',
                        top: `${draftData.length * -2.5}rem`,
                        borderRadius: "10px"
                      }}
                      className="position-absolute " >
                      {draftData.map((i, j) => {
                        return (
                          <div
                            onClick={() => { window.location = `/wcApplyLimit?formContinue=${i.id}` }}
                            style={{ height: '2.5rem' }} className="d-flex bg-white border-bottom-solid-dee2e6 align-items-center" >
                            <label
                              className="font-size-14 font-wt-500 col-8 cursor truncate" >{`Application ${j + 1}`}</label>
                            <div className="col-4 d-flex align-items-center" >
                              <label
                                style={{
                                  background: "#5CB8D3",
                                  color: "#fff",
                                  borderRadius: "100%"
                                }}
                                className="ml-3 px-1 py-2 m-0" >{i.details.percentageComplete + " %"}</label>
                            </div>
                          </div>
                        )
                      })}
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Draft
                      <span
                        style={{
                          background: "#5CB8D3",
                          color: "#fff",
                          borderRadius: "100%"
                        }}
                        className="ml-3 px-2 py-1" >{draftData.length}</span>
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </main>

        </div>
      </div>
    </div>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(BulkBreak)