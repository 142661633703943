import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import call from '../../../service';
import customStyles from '../../dataTablesColumsMap/customTableCss';
import Header from '../../partial/header';
import Sidebar from '../../partial/sidebar';
import moment from 'moment';
import Footer from '../../partial/footer';
import toastDisplay from '../../../utils/toastNotification';
import { rateManagementColumns } from './rateManagementColumns';
import Switch from "react-switch";
import Login from '../../homeComponents/login';

const RateManagement = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {

  const [showLoader, setshowLoader] = useState(false);
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [refresh, setrefresh] = useState(0);
  const [filterData, setFilterData] = useState({})
  const [searchKey, setsearchKey] = useState("")
  const [currentPage, setCurrentPage] = useState(1)

  let commissionRates = {}

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  useEffect(() => {
    fetchTableData(currentPage);
  }, [refresh, perPage, filterData])

  const handleCommissionChange = (e) => {
    e.persist()
    commissionRates[e.target.name] = e.target.value
  }

  function saveCommission(index, element) {
    // console.log("saveCommission", index, commissionRates, element);
    if (commissionRates["commission_percentage" + index] === null) {
      toastDisplay("Please enter commission percentage to save", "info")
    }
    else {
      setshowLoader(true)
      call('POST', 'addBrokerCommission', {
        info_dump_id: element.info_dump_id,
        commission_percentage: commissionRates["commission_percentage" + index],
        userId,
        infoDumpData: element
      }).then((result) => {
        console.log('running addBrokerCommission api-->', result);
        toastDisplay(result, "success")
        setrefresh(refresh + 1);
        setshowLoader(false);
      }).catch((e) => {
        // console.log('error in addBrokerCommission', e);
        toastDisplay("Something went wrong", "error")
        setshowLoader(false)
      })
    }
  }

  const formatDataFortable = (result) => {

    let tableData = []

    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      let tempObj = {}
      tempObj.info_dump_id = element.info_dump_id
      // tempObj.sr_no = index + 1
      tempObj.origin_port = element.origin_port
      tempObj.destination_port = element.destination_port
      tempObj.mode = element.mode
      tempObj.carrier_scac = element.carrier_scac
      tempObj.carrier_name = element.carrier_name
      tempObj.rates = element.rates
      tempObj.departure_date = moment(element.departure_date).format('DD/MM/YYYY')
      tempObj.commission_percentage = element.commission_percentage
      tempObj.isAllRatesDeleted = element.isAllRatesDeleted
      commissionRates["commission_percentage" + index] = element.commission_percentage
      tempObj.commission_percentage_input =
        <input key={element.created_at} disabled={!element.isAllRatesDeleted}
          type="number" name={"commission_percentage" + index} placeholder=""
          defaultValue={commissionRates["commission_percentage" + index]}
          className={"form-control"} onChange={(event) => handleCommissionChange(event)} />
      tempObj.action =
        <>
          {!element.isAllRatesDeleted ? (
            <button
              className="btn btn-primary btn-sm" onClick={() => enableDisableRateFromMarketplace(null, element, true)}>
              EDIT
            </button>
          ) : (
            <>
              <button
                className="btn btn-success btn-sm" onClick={() => saveCommission(index, element)}>
                SAVE
              </button>
              <button
                className="btn btn-danger btn-sm ml-3" onClick={() => enableDisableRateFromMarketplace(null, element, null, true)}>
                CANCEL
              </button>
            </>
          )}
        </>
      tempObj.status = element.isAllRatesDeleted ? "On Hold" : "Rate Published"
      tempObj.last_updated_at = element.last_updated_at ? moment(element.last_updated_at).format("DD/MM/YYYY HH:mm") : "NA"
      tableData.push(tempObj)
    }

    return tableData
  }

  function fetchTableData(page) {
    setshowLoader(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getShippingDumpData', objectAPI).then((result) => {
      console.log('running getShippingDumpData api-->', result);
      settableData(formatDataFortable(result.data))
      setTotalRows(result.totalCount)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in getShippingDumpData', e);
      setshowLoader(false)
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(1)
    setPerPage(newPerPage)
  };

  const handlePageChange = page => {
    setCurrentPage(page)
    fetchTableData(page, {});
  };

  const expandableColumns = [
    { "name": 'No.', "selector": "no" },
    { "name": 'Basis', "selector": "basis" },
    { "name": 'Type Code', "selector": "type_code" },
    { "name": 'Description', "selector": "description" },
    { "name": 'Container Type', "selector": "container_type" },
    { "name": 'Container Quantity', "selector": "container_quantity" },
    { "name": 'Currency', "selector": "currency" },
    { "name": 'Amount', "selector": "amount" },
    { "name": 'Amount Post Commission', "selector": "amount_post_commission" },
    { "name": 'Enable for marketplace', "selector": "enable_for_marketplace" }
  ]

  function enableDisableRateFromMarketplace(currentStatus, row, isEditingRates, cancelEditing) {
    setshowLoader(true)
    call('POST', 'enableDisableRateFromMarketplace', {
      currentStatus, userId,
      info_dump_id: row.info_dump_id,
      rate_dump_id: row.rate_dump_id,
      isEditingRates,
      cancelEditing
    }).then((result) => {
      // console.log('running enableDisableRateFromMarketplace api-->', result);
      toastDisplay(result, "success")
      setshowLoader(false);
      setrefresh(refresh + 1)
    }).catch((e) => {
      // console.log('error in enableDisableRateFromMarketplace', e);
      toastDisplay("Something went wrong", "error")
      setshowLoader(false)
    })
  }

  const TableExpandedComponent = (props) => {
    let { data } = props
    // let isCommissionAvailable = data.commission_percentage != null ? true : false
    // console.log("data props==>", data, data.sr_no - 1, commissionRates);
    if (data.rates.length) {
      return (
        <>
          <div className="col-md-12 row pt-2 text-center pb-0 mb-0 my-2">
            <table className="table table-bordered" >
              <thead className=''>
                <tr>
                  {expandableColumns.map((item, index) => (
                    <th className='transparent-th'>{item.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.rates.map((row, index) => (
                  <tr>
                    {
                      expandableColumns.map(col => {
                        if (col.selector === "no") {
                          return (
                            <td>{index + 1}</td>
                          )
                        }
                        if (col.selector === "enable_for_marketplace") {
                          return (
                            <td>
                              <Switch disabled={data.isAllRatesDeleted} height={20} width={40}
                                onChange={() => enableDisableRateFromMarketplace(row.isRateDeleted === 0, row)}
                                checked={row.isRateDeleted === 0} />
                            </td>
                          )
                        }
                        return (
                          <td>{row[col["selector"]]}</td>
                        )
                      })
                    }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )
    }
    else {
      return (
        <div className="text-center my-4">
          <h6>No Rates Found</h6>
        </div>
      )

    }
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
      setCurrentPage(1)
    }
  };

  const reset = () => {
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setsearchKey("")
    setCurrentPage(1)
    setrefresh(refresh + 1)
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
    setCurrentPage(1)
  };

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

        <div className='row'>
          <Sidebar state={'rate-management'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={'Rate Management'}
              // total={'Total Finance Transactions'}
              userTokenDetails={userTokenDetails}
            // onButtonClick={() => toggleRateListPopup(true)}
            />
            <div class="d-md-flex mt-3 mb-3">
              {/* <div class="filter-bg">
                <ul>
                  <li className={"pilot-cont  cursor-pointer " + (completeButton === 1 ? "shadow" : '')} onClick={() => { setcompleteButton(1); filterStateButtons(1); }}>
                    <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Pending (${filterCount.pending || 0})`}</li>
                  <li className={"progress-cont  cursor-pointer " + (completeButton === 2 ? "shadow" : '')} onClick={() => { setcompleteButton(2); filterStateButtons(2); }}>
                    <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Approved (${filterCount.approved || 0})`}</li>
                  <li className={"complete-cont  cursor-pointer " + (completeButton === 3 ? "shadow" : '')} onClick={() => { setcompleteButton(3); filterStateButtons(3); }}>
                    <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Disbursed (${filterCount.disbursed || 0})`}</li>
                  <li className={"danger-cont text-danger  cursor-pointer " + (completeButton === 4 ? "shadow" : '')} onClick={() => { setcompleteButton(4); filterStateButtons(4); }}>
                    <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Declined (${filterCount.declined || 0})`}</li>
                </ul>
              </div> */}
              <div class="ml-md-auto">
                <ul class="range-slider">
                  <li>
                    <input name="search" id="searchInput" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                    <button type="button" onClick={() => {
                      setFilterData({
                        ...filterData,
                        "search": searchKey
                      })
                      setCurrentPage(1)
                    }}><i class="fas fa-search fa-lg"></i></button>
                  </li>

                  <li>
                    <label>From</label>
                    <input type="date" id="searchFromdate" name="fromDate" onChange={filterState} />
                  </li>
                  <li>
                    <label>To</label>
                    <input type="date" id="searchTodate" name="toDate" onChange={filterState} />
                  </li>
                  <li>
                    <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                  </li>
                </ul>
              </div>
            </div>
            <div class="table-responsive">
              <DataTable
                columns={rateManagementColumns}
                data={tableData}
                progressPending={showLoader}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                expandableRows
                expandableRowsComponent={<TableExpandedComponent />}
                expandOnRowClicked
                highlightOnHover
                // onSort={handleSort}
                // sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
          </main>

        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(RateManagement)