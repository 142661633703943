import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import { InputForTable, InputWithSelectForTable, NewInput, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import { handleFileCommonFunction, most_used_currencies, printDiv } from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import avatarUrl from "../../utils/makeAvatarUrl";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FileInput } from "../../utils/FileInput";
import SignDocument, { signFonts } from "../InvoiceDiscounting/components/SignDocument";


let invItemsTable = [{ subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
{ subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '30%' } },
{ subColumns: "HSN NO.", subColumnStyle: { width: '10%' } },
{ subColumns: "QUANTITY", subColumnStyle: { width: '10%' } },
{ subColumns: "UNIT PRICE", subColumnStyle: { width: '12%' } },
{ subColumns: "TAX AMOUNT", subColumnStyle: { width: '12%' } },
{ subColumns: "TOTAL PRICE", subColumnStyle: { width: '15%' } }
]

let chargesTable = [{ subColumns: "CHARGE TITLE", subColumnStyle: { width: '45%' } }, { subColumns: "AMOUNT", subColumnStyle: { width: '45%' } }]

const ECreditNote = ({ userTokenDetails, navToggleState, selectedInvoiceData }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState({
    invCurrency: 'USD', invChargeTitle0: "SUBTOTAL", invChargeTitle1: 'HANDLING', invChargeTitle2: 'FREIGHT',
    invChargeTitle3: 'MISC.'
  })
  const [error, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [invOther, setInvOther] = useState([null, null, null, null])
  const [preview, setPreview] = useState({})
  const [showPopup, togglePopup] = useState(false)
  const [signdoc, setSigndoc] = useState(false);

  let jj = -1
  let kk = -1

  useEffect(() => {
    if (selectedInvoiceData?.docData) {
      setData(selectedInvoiceData.docData?.data)
      setInvItems(selectedInvoiceData.docData?.invItems)
      setInvOther(selectedInvoiceData.docData.invOther)
    }
  }, [])

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountrys(result.data.message);
      }
    });
  }, []);

  async function handleChange(e) {
    e.persist()
    if (e.target.name.includes('itemTotalAmount')) {
      let invSubTotalAmount = 0
      for (let index = 0; index < invItems.length; index++) {
        let amountToAdd = e.target.name === `itemTotalAmount${index}` ? e.target.value :
          (data[`itemTotalAmount${index}`] || 0)
        if (invItems[index] === null) {
          invSubTotalAmount += amountToAdd / 1
        }
      }
      setData({ ...data, [e.target.name]: e.target.value, [`invSubTotalAmount0`]: invSubTotalAmount })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else if (e.target.name.includes(`invSubTotalAmount`)) {
      let invTotalAmount = 0
      for (let index = 0; index < invOther.length; index++) {
        let amountToAdd = e.target.name === `invSubTotalAmount${index}` ? e.target.value :
          (data[`invSubTotalAmount${index}`] || 0)
        if (invOther[index] === null) {
          invTotalAmount += amountToAdd / 1
        }
      }
      setData({ ...data, [e.target.name]: e.target.value, invTotalAmount })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value })
      setErrors({ ...error, [e.target.name]: "" })
    }
  }

  useEffect(() => {
    if (data?.commInvoice?.name) {
      saveCommInvoice()
    }
  }, [data.commInvoice])

  async function saveCommInvoice() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("userId", userId)
    formData.append("docType", "credit_note")
    formData.append("docTemplate", "Credit Note")
    formData.append("docName", `Credit Note - ${data.invoiceNo}`)
    formData.append("doc", data.commInvoice)
    if (selectedInvoiceData?.id) {
      formData.append("updateDocId", selectedInvoiceData.id)
    }
    let tempData = data
    delete tempData["commInvoice"]
    formData.append('docData', JSON.stringify({ data: tempData, invItems, invOther }))
    await call('POST', 'saveEdoc', formData)
    setshowLoader(false)
    toastDisplay("Document downloaded & saved", "success", () => {
      window.location.reload()
    })
  }

  return (
    <>
      {signdoc && <SignDocument onlyReturnSign={true} setSigndoc={setSigndoc}
        setUpdatedDoc={(signDetails) => {
          console.log("signDetailsssssssssssssssss", signDetails);
          setData({ ...data, invSign: signDetails })
        }} />
      }
      {showPopup && <FinanceInvoiceModal limitinvoice={showPopup} setLimitinvoice={togglePopup} closeSuccess={() => togglePopup(false)} >
        <div className="col-md-10 mb-2 ml-5">
          <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>Upload Company Logo</label>
          <FileInput
            onUploadCancel={() => { setData({ ...data, companyLogo: {} }) }} name={"companyLogo"} value={data["companyLogo"]}
            onChange={(event) => {
              let file_type = event.target.files?.[0]?.["type"]?.toLowerCase()
              if (file_type && (file_type.includes("png") || file_type.includes("jpeg"))) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = async (e) => {
                  let fileObj = event.target.files[0]
                  let fileDataUrl = e.target.result
                  fileObj["filebase64"] = fileDataUrl
                  setData({ ...data, [event.target.name]: fileObj })
                  togglePopup(false)
                }
              }
              else {
                setErrors({ ...error, companyLogo: 'Only png & jpeg images are supported' })
              }
            }}
            error={error.companyLogo} isEditable={true} />
          {error.companyLogo ? <div class="text-danger mt-2 font-size-12">
            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
            <b>{error.companyLogo}</b></div> : ''}
        </div>
      </FinanceInvoiceModal>}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='card mt-1 pt-4' >
        <div className="d-flex row m-0 p-0 justify-content-end" >
          <div className={`${!preview.show ? ' w-20 ' : ' w-30 '} d-flex justify-content-between`} >
            <button type="button"
              onClick={() => { setPreview({ ...preview, show: !preview.show }) }}
              className={` new-btn ${!preview.show ? ' w-100 ' : ' w-45 '} py-2 px-2 text-white`}>
              {!preview.show ? "Preview" : "Back to Editing"}
            </button>
            {preview.show ?
              <button type="button"
                onClick={() => {
                  printDiv("invoiceDiv", `Credit Note - ${data.invoiceNo}`, [], data, setData, "commInvoice")
                }}
                className={` new-btn w-45 py-2 px-2  text-white`}>
                {"Save & Download"}
              </button> : null}
          </div>
        </div>
        <div id={"invoiceDiv"} className="p-4" >
          <div style={{ borderRadius: "5px" }} >
            <div
              style={{ border: '2px solid #000', borderBottom: '1px solid #000' }}
              className="d-flex justify-content-center align-items-center py-2" >
              <label className="font-size-18 font-wt-600" >CREDIT NOTE</label>
            </div>
            <div className="row m-0 d-flex row" >
              <div className="col-8 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderLeft: '2px solid #000' }}>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      fontClass={" font-size-22 font-wt-600 "}
                      isDisabled={preview.show} placeholder={(!data.companyName && !preview.show) ? "<BILL TO COMPANY NAME>" : ""} name={"companyName"} value={data.companyName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.originAddress && !preview.show) ? "Enter Address" : ""} name={"originAddress"} value={data.originAddress} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.companyWebsite && !preview.show) ? "Enter Website" : ""} name={"companyWebsite"} value={data.companyWebsite} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.companyEmailId && !preview.show) ? "Enter Email Id" : ""} name={"companyEmailId"} value={data.companyEmailId} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.companyContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"companyContactNo"} value={data.companyContactNo} onChange={handleChange} />
                  </div>
                </div>
              </div>

              <div className="col-4 p-0 m-0">
                <div style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }} className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>CREDIT NOTE NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} placeholder={(!data.invoiceNo && !preview.show) ? "Enter No" : ""} name={"invoiceNo"} value={data.invoiceNo} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>CREDIT NOTE DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={data.invoiceDate} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>DUE DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.dueDate && !preview.show) ? "Select Date" : ""} name={"dueDate"} value={data.dueDate} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>REFERENCE ID</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} placeholder={(!data.referenceId && !preview.show) ? "Enter Text" : ""} name={"referenceId"} value={data.referenceId} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>DATE OF EXPIRY</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.expiryDate && !preview.show) ? "Select Date" : ""} name={"expiryDate"} value={data.expiryDate} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>CUSTOMER ID</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} placeholder={(!data.customerId && !preview.show) ? "Enter Id" : ""} name={"customerId"} value={data.customerId} onChange={handleChange} />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div className="col-2 position-relative p-3"
                style={{ border: '1px solid #000' }}
              >
                <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.additionalText1 && !preview.show) ? "Enter Additional Marks (optional)" : ""} name={"additionalText1"} value={data.additionalText1} onChange={handleChange} />
              </div> */}
              {/* <div className="col-2 position-relative p-3"
                style={{ border: '1px solid #000' }}
              >
                <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.additionalText2 && !preview.show) ? "Enter Additional Marks (optional)" : ""} name={"additionalText2"} value={data.additionalText2} onChange={handleChange} />
              </div> */}
              {/* <div className="col-3 p-3 d-flex justify-content-center align-items-center"
                style={{ border: '1px solid #000', borderRight: '2px solid #000' }} >
                {preview.show ? null :
                  <label
                    onClick={() => togglePopup(true)} className="font-size-14 font-wt-500 cursor" >
                    <img src="assets/images/edit.png" />
                    {`Company Logo`}</label>}
                {preview.show && !data.companyLogo?.filebase64 ? null :
                  <img style={{ maxWidth: '7.5rem', maxHeight: '7.5rem' }}
                    src={data.companyLogo?.filebase64 || avatarUrl("NA")} />}
              </div> */}
            </div>


            <div className="row m-0 d-flex row" >
              {/* <div className="col-6 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderLeft: '2px solid #000', borderBottom: '2px solid #000' }}>
                  <label className="font-size-15 font-wt-500" ><u>#VENDOR</u></label>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      isDisabled={preview.show} placeholder={(!data.clientContactName && !preview.show) ? "Enter Contact Name" : ""} name={"clientContactName"} value={data.clientContactName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.clientCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"clientCompanyName"} value={data.clientCompanyName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.clientEmailId && !preview.show) ? "Enter Email Id" : ""} name={"clientEmailId"} value={data.clientEmailId} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.clientContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"clientContactNo"} value={data.clientContactNo} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.clientAddress && !preview.show) ? "Enter Address" : ""} name={"clientAddress"} value={data.clientAddress} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderBottom: '2px solid #000', borderRight: '2px solid #000' }}>
                  <label className="font-size-15 font-wt-500" ><u>#SHIP TO</u></label>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      isDisabled={preview.show} placeholder={(!data.shipToContactName && !preview.show) ? "Enter Contact Name" : ""} name={"shipToContactName"} value={data.shipToContactName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.shipToCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"shipToCompanyName"} value={data.shipToCompanyName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.shipToEmailId && !preview.show) ? "Enter Email Id" : ""} name={"shipToEmailId"} value={data.shipToEmailId} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.shipToContactNo && !preview.show) ? "Enter Contact Number" : ""} name={"shipToContactNo"} value={data.shipToContactNo} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.finalDestination && !preview.show) ? "Enter Address" : ""} name={"finalDestination"} value={data.finalDestination} onChange={handleChange} />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-4 p-0 m-0">
                <div style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }} className="d-flex flex-wrap" >
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>INVOICE NO.</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} placeholder={(!data.invoiceNo && !preview.show) ? "Enter No" : ""} name={"invoiceNo"} value={data.invoiceNo} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>INVOICE DATE</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.invoiceDate && !preview.show) ? "Select Date" : ""} name={"invoiceDate"} value={data.invoiceDate} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>DATE OF EXPIRY</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} type={"date"} placeholder={(!data.expiryDate && !preview.show) ? "Select Date" : ""} name={"expiryDate"} value={data.expiryDate} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="w-50 p-0" >
                    <div className="p-3"
                      style={{ border: '1px solid #000' }}>
                      <label className="font-size-15 font-wt-500" ><u>CUSTOMER ID</u></label>
                      <div className="position-relative" >
                        <InputForTable isDisabled={preview.show} placeholder={(!data.customerId && !preview.show) ? "Enter Id" : ""} name={"customerId"} value={data.customerId} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="row m-0 d-flex row mt-2" >
              <div className="col-12 p-0" >
                <div className=""
                >
                  {/* <label
                    style={{ border: '2px solid #000', borderBottom: 0 }} className="font-size-15 font-wt-500 p-3 w-100 text-center m-0 p-0" ><u>#SHIPMENT INFORMATION</u></label> */}
                  {/* <div className="col-12 p-0 m-0">
                    <div style={{}} className="d-flex flex-wrap" >
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000' }}
                        >
                          <label className="font-size-15 font-wt-500" ><u>REQUISITIONER</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} placeholder={(!data.requisitioner && !preview.show) ? "Enter Requisitioner" : ""} name={"requisitioner"} value={data.requisitioner} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000', borderLeft: 0 }}>
                          <label className="font-size-15 font-wt-500" ><u>SHIP VIA</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} placeholder={(!data.shipVia && !preview.show) ? "Enter Ship Via" : ""} name={"shipVia"} value={data.shipVia} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000', borderLeft: 0 }}>
                          <label className="font-size-15 font-wt-500" ><u>INCO TERMS</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} placeholder={(!data.incoTerms && !preview.show) ? "Enter IncoTerms" : ""} name={"incoTerms"} value={data.incoTerms} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 p-0" >
                        <div className="p-3"
                          style={{ border: '2px solid #000', borderLeft: 0 }}>
                          <label className="font-size-15 font-wt-500" ><u>SHIPPING TERMS</u></label>
                          <div className="position-relative" >
                            <InputForTable isDisabled={preview.show} placeholder={(!data.shippingTerms && !preview.show) ? "Enter Shipping Terms" : ""} name={"shippingTerms"} value={data.shippingTerms} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="p-3 mt-2"
              style={{ border: '2px solid #000' }}>
              <NewTablev2 addTableClass={`m-0 invBorder`}
                columns={preview.show ? invItemsTable : invItemsTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '5%' } }])}
              >
                {invItems.map((i, j) => {
                  if (i === null) {
                    jj = jj + 1
                    if (preview.show && !data[`itemDesc${j}`]) {
                      return null
                    }
                    return (
                      <tr>
                        <td><label className="font-size-16 font-wt-500" >{jj + 1}</label></td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show} placeholder={(!data[`itemDesc${j}`] && !preview.show) ? "Enter Description" : ""} name={`itemDesc${j}`} value={data[`itemDesc${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemHSN${j}`] && !preview.show) ? "Enter HSN" : ""} name={`itemHSN${j}`} value={data[`itemHSN${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemQuantity${j}`] && !preview.show) ? "Enter Quantity" : ""} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemUnitPrice${j}`} value={data[`itemUnitPrice${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemTaxAmount${j}`} value={data[`itemTaxAmount${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemTotalAmount${j}`} value={data[`itemTotalAmount${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        {!preview.show ?
                          <td>
                            <div className="d-flex w-100 justify-content-between" >
                              <div className="" >
                                {(invItems.length - invItems.filter(i => {
                                  if (i === undefined) {
                                    return true
                                  }
                                }).length) - 1 === jj ?
                                  <img
                                    onClick={async () => {
                                      let temp = invItems
                                      temp.push(null)
                                      setInvItems([...temp])
                                    }}
                                    className="cursor" src="assets/images/add_black_icon.png" /> :
                                  <img
                                    onClick={() => {
                                      let temp = invItems
                                      temp[j] = undefined
                                      setInvItems([...temp])
                                    }}
                                    className="cursor" src="assets/images/delete.png" />}
                              </div>
                            </div>
                          </td> : null}
                      </tr>
                    )
                  }
                })}
              </NewTablev2>

              <div className="d-flex justify-content-end"
              >
                <div className="w-33" >
                  <NewTablev2 hideHeading={preview.show} addTableClass={"m-0 invBorder"}
                    columns={preview.show ? chargesTable : chargesTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '10%' } }])}>
                    {invOther.map((i, j) => {
                      if (i === null) {
                        kk = kk + 1
                        if (preview.show && !data[`invChargeTitle${j}`]) {
                          return null
                        }
                        return (
                          <tr>
                            <td><label className="font-size-16 font-wt-500" >
                              <InputForTable isDisabled={j === 0 || preview.show} placeholder={(!data[`invChargeTitle${j}`] && !preview.show) ? "Enter Title" : ""} name={`invChargeTitle${j}`}
                                value={data[`invChargeTitle${j}`]} onChange={handleChange} />
                            </label></td>
                            <td>
                              <div className="position-relative " >
                                <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                                  selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                  optionLabel={"code"} optionValue={"code"}
                                  name={`invSubTotalAmount${j}`} value={data[`invSubTotalAmount${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            {!preview.show ?
                              <td>
                                {j === 0 ? null :
                                  <div className="d-flex w-100 justify-content-between" >
                                    <div className="" >
                                      {(invOther.length - invOther.filter(i => {
                                        if (i === undefined) {
                                          return true
                                        }
                                      }).length) - 1 === kk ?
                                        <img
                                          onClick={async () => {
                                            let temp = invOther
                                            temp.push(null)
                                            setInvOther([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = invOther
                                            temp[j] = undefined
                                            setInvOther([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div>
                                  </div>}
                              </td> : null}
                          </tr>
                        )
                      }
                    })}
                  </NewTablev2>
                </div>
              </div>

              <div className="d-flex justify-content-end m-0 p-0">
                <div className="w-33 d-flex row align-items-center m-0 p-0 py-2 "
                  style={{ border: '1px solid #000' }} >
                  <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL</label>
                  <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                    <InputWithSelectForTable isDisabled={preview.show} type={"number"}
                      selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                      optionLabel={"code"} optionValue={"code"}
                      name={`invTotalAmount`} value={data[`invTotalAmount`]} onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>


            <div className="px-3 py-5 mt-2"
              style={{ border: '2px solid #000' }}>
              <div className="d-flex m-0 p-0 align-items-center" >
                <label className="font-size-14 font-wt-500 w-45" >"WE HEREBY CERTIFY THIS INVOICE TO BE TRUE AND CORRECT."</label>
                <div className="w-55  d-flex" >
                  <div className="position-relative w-50" >
                    <label className="font-size-14 font-wt-500" ><u>Name of Authorized Signatory</u></label>
                    <InputForTable isDisabled={preview.show}
                      placeholder={(!data.authorizedSignatoryName && !preview.show) ? "Enter Name" : ""} name={"authorizedSignatoryName"} value={data.authorizedSignatoryName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-50" >
                    <label
                      onClick={() => {
                        setSigndoc(true);
                      }}
                      className="font-size-14 font-wt-500 cursor" ><u>Signature</u>
                      {preview.show ? null :
                        <img src="assets/images/edit.png" className="cursor" />}</label>
                    <div>
                      {data?.invSign?.typedSign ?
                        <label style={{
                          fontFamily: signFonts.find((i, j) => {
                            if (j === data.invSign.typedSignStyle) {
                              return true
                            }
                          })['font']
                        }} className="font-size-16" >{data.invSign.typedSign}</label>
                        : data?.invSign?.signBase64 ?
                          <img src={data?.invSign?.signBase64} />
                          : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ECreditNote)

