


import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import call from '../../service'
import config from '../../config.json'
import { NewInput, NewSelect } from '../../utils/newInput'
import { loadScript } from '../../utils/PaymentGateway'
import { useRef } from 'react'
import { decryptData, isEmpty } from '../../utils/myFunctions'
import toastDisplay from '../../utils/toastNotification'
import { async } from '@firebase/util'
const bankcodes = [
  {
    "bank name": "Aditya Birla Payments Bank",
    "codes": "ABIRLA"
  },
  {
    "bank name": "Airtel Payments Bank",
    "codes": "AIRNB"
  },
  {
    "bank name": "Allahabad Bank",
    "codes": "ALLB"
  },
  {
    "bank name": "Allahabad Bank",
    "codes": "ALLB"
  },
  {
    "bank name": "Andhra Bank",
    "codes": "ADBB"
  },
  {
    "bank name": "Andhra Bank",
    "codes": "ADBB"
  },
  {
    "bank name": "AU Small Finance Bank",
    "codes": "AUSFNB"
  },
  {
    "bank name": "AXIS Bank",
    "codes": "AXIB"
  },
  {
    "bank name": "Axis Corporate Netbanking",
    "codes": "AXISCNB"
  },
  {
    "bank name": "Bank of Bahrain & Kuwait",
    "codes": "BBKB"
  },
  {
    "bank name": "Bank of Baroda",
    "codes": "BBRB"
  },
  {
    "bank name": "Bank of Baroda - Corporate Banking",
    "codes": "BBCB"
  },
  {
    "bank name": "Bank of India",
    "codes": "BOIB"
  },
  {
    "bank name": "Bank of Maharashtra",
    "codes": "BOMB"
  },
  {
    "bank name": "Bhartiya Mahila Bank",
    "codes": "BMNN"
  },
  {
    "bank name": "Canara Bank",
    "codes": "CABB"
  },
  {
    "bank name": "Canara Bank (Erstwhile - Syndicate Bank)",
    "codes": "SYNDB"
  },
  {
    "bank name": "Capital Small Finance Bank Corporate",
    "codes": "CSFBC"
  },
  {
    "bank name": "Capital Small Finance Bank Retail",
    "codes": "CSFBR"
  },
  {
    "bank name": "Catholic Syrian Bank",
    "codes": "CSBN"
  },
  {
    "bank name": "Central Bank of India",
    "codes": "CBIB"
  },
  {
    "bank name": "Citi Bank",
    "codes": "CITNB"
  },
  {
    "bank name": "City Union Bank",
    "codes": "CUBB"
  },
  {
    "bank name": "Corporation Bank",
    "codes": "CRBP"
  },
  {
    "bank name": "Cosmos Bank",
    "codes": "CSMSNB"
  },
  {
    "bank name": "DBS Bank",
    "codes": "DBSB"
  },
  {
    "bank name": "DCB Bank - Corporate Banking",
    "codes": "DCBCORP"
  },
  {
    "bank name": "Dena Bank",
    "codes": "DENN"
  },
  {
    "bank name": "Deutsche Bank",
    "codes": "DSHB"
  },
  {
    "bank name": "Development Credit Bank - Corporate Banking",
    "codes": "DCBCORP"
  },
  {
    "bank name": "Dhanlaxmi Bank",
    "codes": "DLSB"
  },
  {
    "bank name": "Dhanlaxmi Bank - Corporate",
    "codes": "DLSBCORP"
  },
  {
    "bank name": "Dhanlaxmi Bank - Retail",
    "codes": "DLSB"
  },
  {
    "bank name": "Federal Bank",
    "codes": "FEDB"
  },
  {
    "bank name": "Federal Bank Corporate",
    "codes": "FEDCORP"
  },
  {
    "bank name": "HDFC Bank",
    "codes": "HDFB"
  },
  {
    "bank name": "HDFC Bank - Corporate Banking",
    "codes": "HDFCCONB"
  },
  {
    "bank name": "ICICI - Corporate Banking",
    "codes": "ICICB"
  },
  {
    "bank name": "ICICI Bank",
    "codes": "ICIB"
  },
  {
    "bank name": "ICICI Corporate Netbanking",
    "codes": "ICICICNB"
  },
  {
    "bank name": "IDBI Bank",
    "codes": "IDBB"
  },
  {
    "bank name": "IDBI Corp Netbanking",
    "codes": "IDBICORP"
  },
  {
    "bank name": "IDFC FIRST Bank",
    "codes": "IDFCNB"
  },
  {
    "bank name": "Indian Bank",
    "codes": "INDB"
  },
  {
    "bank name": "Indian Bank (Erstwhile Allahabad Bank)",
    "codes": "ALLB"
  },
  {
    "bank name": "Indian Overseas Bank",
    "codes": "INOB"
  },
  {
    "bank name": "IndusInd Bank",
    "codes": "INIB"
  },
  {
    "bank name": "ING Vyasa Bank",
    "codes": "INGB"
  },
  {
    "bank name": "Jammu & Kashmir Bank",
    "codes": "JAKB"
  },
  {
    "bank name": "Janata Sahakari Bank Pune",
    "codes": "JSBNB"
  },
  {
    "bank name": "Karnataka Bank",
    "codes": "KRKB"
  },
  {
    "bank name": "Karur Vysya - Corporate Banking",
    "codes": "KRVBC"
  },
  {
    "bank name": "Karur Vysya Bank",
    "codes": "KRVB"
  },
  {
    "bank name": "Kotak Mahindra Bank",
    "codes": "162B"
  },
  {
    "bank name": "Kotak Mahindra Bank - Corp NB",
    "codes": "KTKBCORP"
  },
  {
    "bank name": "Lakshmi Vilas Bank",
    "codes": "LVBD"
  },
  {
    "bank name": "Lakshmi Vilas Bank - Corporate Banking",
    "codes": "LVCB"
  },
  {
    "bank name": "Oriental Bank of Commerce",
    "codes": "OBCB"
  },
  {
    "bank name": "Paytm Payments Bank",
    "codes": "PAYTMNB"
  },
  {
    "bank name": "PNB (Erstwhile -Oriental Bank of Commerce)",
    "codes": "OBCB"
  },
  {
    "bank name": "PNB (Erstwhile-United Bank of India)",
    "codes": "UNIB"
  },
  {
    "bank name": "Post Office Savings Bank (POSB)",
    "codes": "INDPOST"
  },
  {
    "bank name": "Punjab & Maharashtra Co-operative Bank Limited",
    "codes": "PMNB"
  },
  {
    "bank name": "Punjab & Sind Bank",
    "codes": "PSBNB"
  },
  {
    "bank name": "Punjab National Bank",
    "codes": "PNBB"
  },
  {
    "bank name": "Punjab National Bank - Corporate Banking",
    "codes": "CPNB"
  },
  {
    "bank name": "RBL Bank",
    "codes": "RBL"
  },
  {
    "bank name": "RBL Corporate Banking",
    "codes": "RBLCNB"
  },
  {
    "bank name": "Royal Bank of Scotland",
    "codes": "RYBS"
  },
  {
    "bank name": "Saraswat Bank",
    "codes": "SRSWT"
  },
  {
    "bank name": "Shamrao Vithal Co-operative Bank Ltd.",
    "codes": "SVCNB"
  },
  {
    "bank name": "South Indian Bank",
    "codes": "SOIB"
  },
  {
    "bank name": "Standard Chartered Bank",
    "codes": "SDCB"
  },
  {
    "bank name": "State Bank of Bikaner and Jaipur",
    "codes": "SBBJB"
  },
  {
    "bank name": "State Bank of Hyderabad",
    "codes": "SBHB"
  },
  {
    "bank name": "State Bank of India",
    "codes": "SBIB"
  },
  {
    "bank name": "State Bank of India (Corporate)",
    "codes": "SBNCORP"
  },
  {
    "bank name": "State Bank of Mysore",
    "codes": "SBMB"
  },
  {
    "bank name": "State Bank of Patiala",
    "codes": "SBPB"
  },
  {
    "bank name": "State Bank of Travancore",
    "codes": "SBTB"
  },
  {
    "bank name": "SVC Co-operative Bank Ltd.",
    "codes": "SVCNB"
  },
  {
    "bank name": "Syndicate Bank",
    "codes": "SYNDB"
  },
  {
    "bank name": "Tamilnad Mercantile Bank",
    "codes": "TMBB"
  },
  {
    "bank name": "The Nainital Bank",
    "codes": "TBON"
  },
  {
    "bank name": "UCO Bank",
    "codes": "UCOB"
  },
  {
    "bank name": "Union Bank - Corporate Banking",
    "codes": "UBIBC"
  },
  {
    "bank name": "Union Bank of India",
    "codes": "UBIB"
  },
  {
    "bank name": "Union Bank of India (Erstwhile Andhra Bank)",
    "codes": "ADBB"
  },
  {
    "bank name": "Union Bank of India (Erstwhile Corporation Bank)",
    "codes": "CRPB"
  },
  {
    "bank name": "Union Bank OLT - Corporate Banking",
    "codes": "UBIBC"
  },
  {
    "bank name": "Vijaya Bank",
    "codes": "VJYB"
  },
  {
    "bank name": "Vijaya Bank",
    "codes": "VJYB"
  },
  {
    "bank name": "Yes Bank",
    "codes": "YESB"
  }
]

const walletscodes = [
  {
    "wallet_name": "PayTM",
    "codes": "PAYTM"
  },
  {
    "wallet_name": "Freecharge",
    "codes": "FREC"
  },
  {
    "wallet_name": "Amazon Pay",
    "codes": "AMZPAY"
  },
  {
    "wallet_name": "Airtel Money",
    "codes": "AMON"
  },
  {
    "wallet_name": "Oxigen",
    "codes": "OXYCASH"
  },
  {
    "wallet_name": "Ola Money",
    "codes": "OLAM"
  },
  {
    "wallet_name": "Jio Money",
    "codes": "JIOM"
  },
  {
    "wallet_name": "ItzCash",
    "codes": "ITZC"
  },
  {
    "wallet_name": "HDFC PayZapp",
    "codes": "PAYZ"
  },
  {
    "wallet_name": "Yes Bank",
    "codes": "YESW"
  },
  {
    "wallet_name": "MobiKwik",
    "codes": "mobikwik"
  }
]
let transactionId = "TXN" + new Date().getTime()

const PayUPaymentScreen = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const pid = queryParams.get('pid')

  const [transactionID, setTXNId] = useState(transactionId)
  const [paymentmode, setpaymentMode] = useState('UPI')
  const paymentModeRef = useRef('UPI')
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [wallets, setwallets] = useState([])
  const [netBanks, setNetBanks] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [isValidUser, setValidUser] = useState(false)
  const [paymentdetails, setpaymentdetails] = useState({})
  const [errormessage, setErrormessage] = useState('')
  const timer = useRef(null)
  const [timerString, setTimerString] = useState('10:00')
  const paymentsdetRef = useRef(null)

  const [isWindowFocused, setIsWindowFocused] = useState(true);
  useEffect(() => {
    setNetBanks(bankcodes)
    setwallets(walletscodes)
  })
  useEffect(() => {
    const handleWindowFocus = () => {
      console.log('Window Focused')
      onValidateTransactions()
      setIsWindowFocused(true);
    };

    const handleWindowBlur = () => {
      console.log('Out of focus')
      setIsWindowFocused(false);
    };

    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);
  const getPaymentdetails = async () => {
    try {
      setshowLoader(true)
      const result = await axios({
        method: 'POST',
        url: config.baseUrl + 'getpaymentdata',
        data: { pid: pid?.split(" ").join("+") }
      })
      setValidUser(result.data.success)
      setshowLoader(false)
      setpaymentdetails(result.data.message)
      paymentsdetRef.current = result.data.message
      if (!result.data.success) {
        setErrormessage(result.data.message)
      }
      if (result.data.success) {
        timer.current = setInterval(function () {
          timeBetweenDates(result.data.message.createdAt, result.data.message.expires_at);
        }, 1000);
      }
    } catch (e) {
      console.log('error in fetch payments', e)
      setValidUser(false)
      setshowLoader(false)
    }
  }
  useEffect(() => {
    getPaymentdetails()
  }, [])

  const handValidations = () => {
    let errors = {}
    if (paymentmode === 'UPI') {
      if (!data.upiId) {
        errors.upiId = 'UPI ID cannot be Empty'
      }
    }
    if (paymentmode === 'Wallets') {
      if (!data.wallet) {
        errors.wallet = 'Wallet cannot be Empty'
      }
    }
    if (paymentmode === 'Cards') {
      if (!data.cardNo) {
        errors.cardNo = 'Card Number cannot be Empty'
      }
      if (!data.expyear) {
        errors.expyear = 'Expiry Year cannot be Empty'
      }
      if (!data.expmonth) {
        errors.expmonth = 'Expiry Month cannot be Empty'
      }
      if (!data.holderName) {
        errors.holderName = 'Card Holder Name cannot be Empty'
      }
      if (!data.cvv) {
        errors.cvv = 'CVV Number cannot be Empty'
      }
    }
    if (paymentmode === 'Netbanking') {
      if (!data.netbank) {
        errors.netbank = 'Bank cannot be Empty'
      }
    }
    if (isEmpty(errors)) {
      onPaynowPayU()
    } else {
      setErrors(errors)
    }

  }
  const onPaymentFailure = async (errMsg) => {
    console.log('Payments', paymentsdetRef.current)

    clearInterval(timer.current);
    let url = ''
    let reqObj = {
      userId: paymentsdetRef.createdBy,
      modeOfPayment: paymentmode,
      order_data: {
        payment_id: transactionID
      },
      userId: paymentsdetRef.current.createdBy,
    }
    if (paymentsdetRef?.payment_type?.includes('Topup')) {
      reqObj["topup_id"] = paymentsdetRef.current.pay_id
      url = 'failedTopupPayment'
    } else {
      reqObj["plan_id"] = paymentsdetRef.current.pay_id
      url = 'failedPlanPayment'
    }
    const paymentfailed = await axios({
      method: 'POST',
      url: config.baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + decryptData(paymentsdetRef.current.userToken)
      },
      data: reqObj
    })
    const paymendetailsupdate = await axios({
      method: 'POST',
      url: config.baseUrl + 'updatePaymentdetails',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + decryptData(paymentsdetRef.current.userToken)
      },
      data: {
        status: 2,
        userId: paymentsdetRef.current.createdBy,
        modeOfPayment: paymentmode,
        transactionId: transactionID,
        failure_reason: errMsg,
        tbl_payment_id: paymentsdetRef.current.id
      }
    })
    window.location = '/failedPayment'
  }
  const onPaynowPayU = async () => {
    if (isValidUser) {
      setshowLoader(true)
      let reqObj = {
        modeOfPayment: paymentmode,
        order_data: {
          amount: paymentdetails.payment_amount,
          firstname: paymentdetails.contact_person,
          email: paymentdetails.email_id,
          phone: paymentdetails.contact_number,
          furl: `https://www.tradereboot.com/payment-failure`,
          surl: `https://www.tradereboot.com/payment-success`,
          productinfo: paymentdetails.payment_type,
          txnid: transactionID
        }
      }
      switch (paymentmode) {
        case 'UPI':
          reqObj["order_data"]["pg"] = 'UPI'
          reqObj["order_data"]["bankcode"] = 'UPI'
          reqObj["order_data"]["VPA"] = data.upiId
          break;
        case 'Wallets':
          reqObj["order_data"]["pg"] = 'cash'
          reqObj["order_data"]["bankcode"] = data.wallet
          break;
        case 'Cards':
          reqObj["order_data"]["pg"] = 'cc'
          reqObj["order_data"]["bankcode"] = 'cc'
          reqObj["order_data"]["cardnumber"] = data.cardNo
          reqObj["order_data"]["expirymonth"] = data.expmonth
          reqObj["order_data"]["expiryYear"] = data.expyear
          reqObj["order_data"]["ccvv"] = data.cvv
          break
        case 'Netbanking':
          reqObj["order_data"]["pg"] = 'TESTPG'
          reqObj["order_data"]["bankcode"] = data.netbank
          break
      }
      console.log('reqObjjjjj', reqObj)
      try {
        const result = await call('POST', 'onBuyPlanPayU', reqObj)
        setTXNId(result.transactionId)
        if (result.isHTML) {
          const newWindow = window.open();
          newWindow.document.write(result.message);
          newWindow.document.close();
        } else {
          window.open(result.message)
        }


      } catch (e) {
        console.log('is in catch', e, paymentModeRef)
        if (paymentModeRef.current === 'UPI') {
          setErrors({
            upiId: e
          })
        } else if (paymentModeRef.current === 'Cards') {
          setErrors({
            cardNo: e
          })
        }
        setshowLoader(false)
        toastDisplay(e, 'error')
      }

    }
  }
  const handleChange = (value) => {
    setpaymentMode(value)
    paymentModeRef.current = value
  }
  const handInputChange = (event, maxlength) => {
    if (maxlength) {
      if (event.target.value?.length <= maxlength) {
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
        return
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }
  async function timeBetweenDates(startdate, expdate) {
    const sdate = new Date()
    const edate = new Date(expdate)
    var difference = edate.getTime() - sdate.getTime();

    if (difference <= 0) {
      // Timer done
      onPaymentFailure('Timer Expired')
    } else {

      var seconds = Math.floor(difference / 1000);
      var minutes = Math.floor(seconds / 60);

      minutes %= 60;
      seconds %= 60;
      setTimerString(minutes + ":" + seconds)
    }
  }
  const onValidateTransactions = async () => {
    const reqObj = {
      transactionId: transactionID
    }
    try {
      const result = await call('POST', 'onVerifyPaymentPayU', reqObj)
      console.log('resultttttttt', result)
      if (result.status === 'success') {
        setshowLoader(false)
        onPaymentSuccess()
      } else if (result.status === 'failure') {
        setshowLoader(false)
        onPaymentFailure(result.error_Message)
      }
    } catch (e) {
      setshowLoader(false)
      //window.location = '/failedPayment'
    }
  }
  const onPaymentSuccess = async () => {
    console.log('Payments', paymentsdetRef.current)

    clearInterval(timer.current);
    let url = ''
    let reqObj = {
      userId: paymentsdetRef.createdBy,
      modeOfPayment: paymentModeRef.current,
      order_data: {
        payment_id: transactionID
      },
      userId: paymentsdetRef.current.createdBy,
    }
    if (paymentsdetRef?.current?.payment_type?.includes('Topup')) {
      reqObj["topup_id"] = paymentsdetRef.current.pay_id
      url = 'buyTopup'
    } else {
      reqObj["plan_id"] = paymentsdetRef.current.pay_id
      url = 'buyNewPlan'
    }
    const paymentfailed = await axios({
      method: 'POST',
      url: config.baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + decryptData(paymentsdetRef.current.userToken)
      },
      data: reqObj
    })
    const paymendetailsupdate = await axios({
      method: 'POST',
      url: config.baseUrl + 'updatePaymentdetails',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + decryptData(paymentsdetRef.current.userToken)
      },
      data: {
        status: 1,
        userId: paymentsdetRef.current.createdBy,
        modeOfPayment: paymentModeRef.current,
        transactionId: transactionID,
        failure_reason: '',
        tbl_payment_id: paymentsdetRef.current.id
      }
    })
    window.location = '/paymentSuccess'
  }
  return (
    <div className="col-md-11 col-sm-12 mx-auto mt-4">
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="p-2 card border-0 rounded-3 card-layout">
        {isValidUser ?
          <div className='p-5 row'>
            <div className=''>
              <div className='col-md-8 col-sm-12 mt-'>
                <img src={'/assets/images/logos/logo_1.png'} alt="" height={80} width={190} />
              </div>
              <div className='col-md-4 col-sm-12 mt-2'>
                <label className='font-size-16 font-wt-600'>{`Timer Left ${timerString} Minutes`}</label>
              </div>
            </div>
            <div className='col-md-8 col-sm-12 mt-2'>
              <div className="form-check cursor ps-0 py-2 " onClick={() => handleChange("UPI")}>
                <input className="form-check-input" type="radio" value={"UPI"} checked={paymentmode === 'UPI'} />
                <label className="form-check-label" >
                  UPI
                </label>
                {paymentmode === 'UPI' &&
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-6 mb-0">
                      <NewInput type={"text"} label={"UPI ID"}
                        name={"upiId"} value={data.upiId} error={errors.upiId}
                        onChange={handInputChange} />
                    </div>
                  </div>
                }
              </div>
              <div className="form-check cursor ps-0 py-2" onClick={() => handleChange("Wallets")}>
                <input className="form-check-input" type="radio" value={"Wallets"} checked={paymentmode === 'Wallets'} />
                <label className="form-check-label" >
                  Wallets
                </label>
                {paymentmode === 'Wallets' &&
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-6 mb-0">
                      <NewSelect isAstrix={true} label={"Wallet"}
                        selectData={wallets} name={"wallet"}
                        value={data.wallet} optionLabel={"codes"} optionValue={'codes'}
                        onChange={handInputChange} error={errors.wallet} />
                    </div>
                  </div>
                }
              </div>
              <div className="form-check cursor ps-0  py-2 " onClick={() => handleChange("Cards")}>
                <input className="form-check-input" type="radio" value={"Cards"} checked={paymentmode === 'Cards'} />
                <label className="form-check-label">
                  Debit / Credit / ATM Card
                </label>
                {paymentmode === 'Cards' &&
                  <div>
                    <div className="d-flex flex-row align-items-center mt-2">
                      <div className="form-group col-md-5 col-sm-12 mb-0">
                        <NewInput type={"number"} label={"Card No"}
                          name={"cardNo"} value={data.cardNo} error={errors.cardNo}
                          onChange={(event) => handInputChange(event, 16)} />
                      </div>
                      <div className="form-group col-md-2 mb-0">
                        <NewInput type={"number"} label={"Month"}
                          name={"expmonth"} value={data.expmonth} error={errors.expmonth}
                          onChange={(event) => handInputChange(event, 2)} />
                      </div>
                      <label> / </label>
                      <div className="form-group col-md-2 mb-0">
                        <NewInput type={"number"} label={"Year"}
                          name={"expyear"} value={data.expyear} error={errors.expyear}
                          onChange={(event) => handInputChange(event, 4)} />
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mt-2">
                      <div className="form-group col-md-5 mb-0">
                        <NewInput type={"text"} label={"Name of the holder"}
                          name={"holderName"} value={data.holderName} error={errors.holderName}
                          onChange={handInputChange} />
                      </div>
                      <div className="form-group col-md-2 mb-0">
                        <NewInput type={"password"} label={"CVV"}
                          name={"cvv"} value={data.cvv} error={errors.cvv}
                          onChange={handInputChange} maxlength={"3"} />
                      </div>
                    </div>

                  </div>

                }
              </div>
              <div className="form-check cursor ps-0  py-2 " onClick={() => handleChange("Netbanking")}>
                <input className="form-check-input" type="radio" value={"Netbanking"} checked={paymentmode === 'Netbanking'} />
                <label className="form-check-label">
                  Net banking
                </label>
                {paymentmode === 'Netbanking' &&
                  <div className="row align-items-center mt-2">
                    <div className="form-group col-md-6 mb-0">
                      <NewSelect isAstrix={true} label={"Net Banking"}
                        selectData={netBanks} name={"netbank"}
                        value={data.netbank} optionLabel={"codes"} optionValue={'codes'}
                        onChange={handInputChange} error={errors.netbank} />
                    </div>
                  </div>
                }
              </div>
              <button type="button" onClick={() => { handValidations() }}
                className={` new-btn w-auto py-2 px-5 mt-4 text-white cursor`}>
                Continue
              </button>
            </div>
            <div className='col-md-4 col-sm-12 mt-2'>
              <div className="p-4 card border-0 rounded-3 card-layout">
                <label className='font-size-16 font-wt-600 text-custom2 '>Price details</label>
                <label className='font-size-16 font-wt-500 mt-2'>{paymentdetails.payment_type} </label>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <label className='font-size-13 font-wt-400'>Plan Cost</label>
                  <label className='font-size-13 font-wt-500'> {`${paymentdetails.currency ? paymentdetails.currency : "$"} ${paymentdetails.payment_amount}`} </label>
                </div>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <label className='font-size-13 font-wt-400'>Coupon</label>
                  <label className='font-size-13 font-wt-500'> {"- $ " + "0"} </label>
                </div>
                <div className='dashedBorder'></div>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <label className='font-size-16 font-wt-600 text-custom2'>Total</label>
                  <label className='font-size-16 font-wt-600 text-custom2'>{`${paymentdetails.currency ? paymentdetails.currency : "$"} ${paymentdetails.payment_amount}`}</label>
                </div>
              </div>
            </div>

          </div>
          : <h1 className='text-center'>{errormessage ? errormessage : 'Unauthorized User'}</h1>
        }
      </div>
    </div>
  )
}

export default PayUPaymentScreen