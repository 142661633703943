import { useEffect, useState } from "react";
import DropdownSearch from "../dropdownWithSearch";
import call from "../../../../service";

export default function AddReminder({ setShowAddReminderTab, handleAddReminder, allData, userId, editingReminder = {} }) {
  const [rule, setRule] = useState(editingReminder.rule || "");
  const [customersNames, setCustomersNames] = useState([])
  const [customerSelected, setCustomerSelected] = useState(editingReminder.customerSelected || []);
  const [sendReminder, setSendReminder] = useState(editingReminder.sendReminder || null);
  // const [dueDate, setDueDate] = useState(new Date(editingReminder.dueDate).toISOString().split('T')[0] || null);
  const [dueDate, setDueDate] = useState(() => {
    const date = new Date(editingReminder.dueDate);
    return isNaN(date.getTime()) ? null : date.toISOString().split('T')[0];
  });

  const [interval, setInterval] = useState(editingReminder.interval || "After Due Date");
  const [reminderBetween, setReminderBetween] = useState(editingReminder.reminderBetween || '');
  const [markCopyTo, setMarkCopyTo] = useState(editingReminder.markCopyTo || "No");
  const [waitFor, setWaitFor] = useState(editingReminder.waitFor || null);
  const [skipDays, setSkipDays] = useState(editingReminder.skipDays || []);
  const [skipDaysOptions, setSkipDaysOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emails, setEmails] = useState(editingReminder.emailsToSend ? editingReminder.emailsToSend.map(email => ({ customer: email.customer, email: email.email })) : []);


  // [{ customer: "madhu", email: "madhu@gmail.com" }, { customer: "madhu1", email: "not there" }]


  // 

  useEffect(() => {
    if (customerSelected.length > 0) {
      customerSelected.forEach(item => {
        if (!emails.some(emailObj => emailObj.customer === item)) {
          let objectAPI = {
            userId: userId,
            customerName: item,
          };

          call('POST', 'getcustomeremail', objectAPI)
            .then((result) => {
              console.log("mail obj", result);
              // Ensure the result has the expected structure
              if (result && result.customer && result.email) {
                setEmails(prev => [...prev, result]);
              } else {
                console.error("Invalid result format", result);
              }
            })
            .catch((e) => {
              console.log('error in getcustomeremail', e);
            });
        }
      });
    }
  }, [customerSelected, emails, userId]);

  // validate form
  const validateForm = () => {
    const newErrors = {};
    if (!rule) newErrors.rule = "Rule Name cannot be empty";
    if (!sendReminder) newErrors.sendReminder = "Send Reminder cannot be empty";
    if (!dueDate) newErrors.dueDate = "Due Date cannot be empty";
    if (!interval) newErrors.interval = "Interval cannot be empty";
    // if (!reminderBetween) newErrors.reminderBetween = "Reminder Between cannot be empty";
    if (markCopyTo === null) newErrors.markCopyTo = "Mark Copy To Yourself cannot be empty";
    if (!waitFor) newErrors.waitFor = "Wait For cannot be empty";
    if (customerSelected.length === 0) newErrors.customerSelected = "At least one customer must be selected";
    console.log(newErrors)
    if (Object.keys(newErrors).length === 0) {
      setShowCreateButton(true);
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handlePreviewClick = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      console.log("preview clicked")

    }
  };

  // useEffect(() => {
  //   if (editingReminder.length) {
  //     setRule(editingReminder.rule);
  //     setCustomerSelected(editingReminder.customerSelected);
  //     setSendReminder(editingReminder.sendReminder);
  //     setDueDate(editingReminder.dueDate);
  //     setInterval(editingReminder.interval);
  //     setReminderBetween(editingReminder.reminderBetween);
  //     setMarkCopyTo(editingReminder.markCopyTo);
  //     setWaitFor(editingReminder.waitFor);
  //     setSkipDays(editingReminder.skipDays);
  //     setEmails(editingReminder.emailsToSend.map(email => ({ customer: email.customer, email: email.email })));
  //   }
  // }, [editingReminder]);
  useEffect(() => {
    // console.log(allData)
    const items = Array.from(new Set(allData.map((item) => item["partyName"]).filter(Boolean)));
    setCustomersNames(items)
    setSkipDaysOptions(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
  }, [allData])
  // #2
  const handleSubmit = () => {
    const emailsToSend = emails
    const newReminder = { userId, rule, customerSelected, sendReminder, dueDate, interval, reminderBetween: "10AM To 11AM", emailsToSend, markCopyTo, waitFor, skipDays }
    if (editingReminder['id']) newReminder['id'] = editingReminder.id
    handleAddReminder(newReminder);
  };

  const handleCustomerSelected = (items) => {
    setCustomerSelected(items)
  }
  const handleSkipDays = (items) => {
    setSkipDays(items)
  }
  const handleEmailChange = (e) => {
    setNewEmail(e.target.value)
  };
  const handleEmailChangeSubmit = (customer) => {
    setEmails(emails.map(item =>
      item.customer === customer ? { ...item, email: newEmail } : item
    ));
    setNewEmail("")
  };
  useEffect(() => { console.log("emails:", emails) }, [emails])
  const reminderTimes = ["1 AM to 2 AM", "1 PM to 2 PM", "10 AM to 11 AM", "10 PM to 11 PM", "11 AM to 12 PM", "11 PM to 12 PM", "12 AM to 1 PM", "12 PM to 1 PM"];
  const dueDates = ["After Due Date", "Before Due Date", "On Due Date"];
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 100000,

    }}>
      <div style={{
        background: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        minWidth: '600px',
        width: '60%',
        maxHeight: "80%",
        overflowY: "scroll"
      }}>
        <div onClick={() => { setShowAddReminderTab(false); }} className='cursor-pointer border rounded-circle shadow d-flex justify-content-center align-items-center' style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
          width: '40px',
          backgroundColor: '#858c8c',
          height: '40px',
        }}>
          <p style={{ fontSize: '20px', fontFamily: 'sans-serif', color: 'white', backgroundColor: '#858c8c' }} className='p-0 m-0  fw-bold'>X</p>
        </div>
        <h4>{editingReminder.length ? 'Edit Reminder' : 'Set Reminder'}</h4>
        <div className=" d-flex justify-content-between align-items-center mb-1">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>Rule Name: <span className="text-danger"> *</span></label>
          <input style={{ width: "70%" }} type="text" className="form-control" value={rule} onChange={(e) => setRule(e.target.value)} />
        </div>
        {errors.rule && <div className="text-danger mb-2 p-0">{errors.rule}</div>}
        <div className=" d-flex justify-content-left align-items-center">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px", width: "30%" }}>Filter By Customer:<span className="text-danger"> *</span></label>
          <DropdownSearch defaultItem={editingReminder ? editingReminder.customerSelected : []}
            items={customersNames} onSelect={handleCustomerSelected} placeholder={'Select Customers'} multipleSelection={true} customStyles={{ marginRight: "0", widthOfInput: "70%" }} />
        </div>
        {errors.customerSelected && <div className="text-danger mb-2 p-0">{errors.customerSelected}</div>}
        {
          emails.length ? <div className="mt-1">
            {emails.map(item => (
              <div key={item.customer} className="d-flex justify-content-start align-items-center mb-2">
                <p className="m-0 me-2 p-0">{item.customer}</p> :
                {item.email === "not there" ? (
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control m-0 ms-2"
                      placeholder="Enter Email Here"
                      value={newEmail}
                      onChange={(e) => handleEmailChange(e)}
                    />
                    <button
                      className="btn btn-success ms-2"
                      onClick={() => handleEmailChangeSubmit(item.customer, item.email)}
                    >
                      ✓
                    </button>
                  </div>
                ) : (
                  <p className="m-0 ms-2 p-0">{item.email}</p>
                )}
              </div>
            ))}
          </div> : <></>
        }
        <div className=" d-flex justify-content-between align-items-center mb-1">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>Select Due Date:<span className="text-danger"> *</span></label>
          <input
            type="date"
            className="form-control"
            style={{ width: "70%" }}
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            aria-label="Due Date"
          />
        </div>
        {errors.dueDate && <div className="text-danger mb-2 p-0">{errors.dueDate}</div>}

        <div className=" d-flex justify-content-between align-items-center">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }} className="d-block">Send Reminder:<span className="text-danger"> *</span></label>
          <input type="number" className="form-control" style={{ width: "100px" }} value={sendReminder} onChange={(e) => setSendReminder(e.target.value)} />
          <label style={{ fontSize: '16px' }} className="d-block ms-0 me-3">Days</label>
          <DropdownSearch defaultItem={"After Due Date"} items={dueDates} customStyles={{ marginRight: "0" }} onSelect={(e) => { setInterval(e) }} />
        </div>
        {errors.sendReminder && <div className="text-danger mb-2 p-0">{errors.sendReminder}</div>}

        {/* <div className=" d-flex justify-content-between align-items-center">
          <label className="d-block" style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>Send Reminder Between:<span className="text-danger "> *</span></label>
          <DropdownSearch defaultItem={editingReminder ? editingReminder.reminderBetween : []} items={reminderTimes} customStyles={{ marginRight: "0", widthOfInput: "70%" }} onSelect={(e) => { setReminderBetween(e) }} />
        </div> */}
        {/* {errors.reminderBetween && <div className="text-danger mb-2 p-0">{errors.reminderBetween}</div>} */}

        <div className=" d-flex justify-content-between align-items-center mb-1">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>Mark Copy To Yourself:<span className="text-danger"> *</span></label>
          <select className="form-control" style={{ width: "70%" }} value={markCopyTo} onChange={(e) => setMarkCopyTo(e.target.value)}>
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </select>
        </div>
        {errors.markCopyTo && <div className="text-danger mb-2 p-0">{errors.markCopyTo}</div>}

        <div className=" d-flex justify-content-between align-items-center mb-1 ">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>Wait For:<span className="text-danger"> *</span></label>
          <div style={{ width: "70%" }} className=" d-flex justify-content-start align-items-center">
            <input type="number" className="form-control me-3" value={waitFor} onChange={(e) => setWaitFor(e.target.value)} style={{ width: "100px" }} />
            <label style={{ fontSize: '16px', fontWeight: 'bold' }}>Days Before Sending Next Reminder</label>
          </div>
        </div>
        {errors.waitFor && <div className="text-danger mb-2 p-0">{errors.waitFor}</div>}

        <div className=" d-flex justify-content-between align-items-center">
          <label style={{ fontSize: '16px', fontWeight: 'bold', marginRight: "5px" }}>Don't Send Reminder On:</label>
          <DropdownSearch defaultItem={editingReminder ? editingReminder.skipDays : []}
            items={skipDaysOptions} onSelect={handleSkipDays} placeholder={'Select Skip Days'} multipleSelection={true} customStyles={{ marginRight: "0", widthOfInput: "70%" }} />
        </div>
        {!showCreateButton ? (
          <button className="btn btn-primary" onClick={handlePreviewClick}>Preview</button>
        ) : (
          <button className="btn btn-success" onClick={handleSubmit}>{editingReminder.length ? "Update" : "Create"}</button>
        )}
        {/* <button className="btn btn-primary" onClick={handlePreviewClick}>{showCreateButton ? 'Update' : 'Preview'}</button>
        {showCreateButton && <button className="btn btn-success mt-3" onClick={handleSubmit}>Create</button>} */}

      </div>
    </div>
  );

}
