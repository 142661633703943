import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import toastDisplay from '../../utils/toastNotification';
import call from '../../service';
import validate from '../../utils/validation_files/applyCreditLineByUser';
import FilePreview from '../utilComponents/previewFile'
import customStyles from '../dataTablesColumsMap/customTableCss';
import { setcreditLineDetails } from '../../store/actions/action';
import toolTipText from '../../utils/toolTips/creditLine.json';
import columns from '../dataTablesColumsMap/myCounterDetailsGrid';
import fincolumns from '../dataTablesColumsMap/userFinancialDetailsGrid';
import authorizedcolumns from '../dataTablesColumsMap/userShareholderDetailsGrid';
import bankcolumns from '../dataTablesColumsMap/bankDetailsGrid';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import docJson from '../userProfile/financeDoc.json';


const CreateCreditLine = ({ userTokenDetails, creditLineDetails, setcreditLineDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [bankList, setBankList] = useState([]);
  const [bankcreditDoc, setBankcreditDoc] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [documentRow, setDocumentRow] = useState({ data: [{ documentName: null, document: null }] });
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState([]);
  const [financial, setFinancial] = useState({ type: 'Recourse', data: null });
  const [secondfinancial, setsecondFinancial] = useState({ type: 'Non-Recourse', data: null });
  const [selectedAuthorized, setSelectedAuthorized] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    fetchTableData(1, {});
    fetchfinTableData(1, {});
    fetchauthorizedTableData(1, {});
    fetchbankTableData(1, {})
    call('GET', 'getBanksListMaster').then((result) => {
      setBankList(result);
    })
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  // Datatable
  const [tableData, settableData] = useState([]);
  const [fintableData, setfintableData] = useState([]);
  const [authorizedtableData, setauthorizedtableData] = useState([]);
  const [banktableData, setbanktableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId,
      "userTypeId": userTypeId,
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      settableData(formatDataFortable(result.buyerData))
      setTotalRows(result.countData)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "userTypeId": userTypeId,
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      settableData(formatDataFortable(result.buyerData))
      setTotalRows(result.countData)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
      setLoading(false)
    })
  };

  const handleCheckChange = (state) => {
    setSelectedBuyer(state.selectedRows);
  };

  const rowSelectCritera = row => {
    return (row.id > 0)
  }

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.buyerName = dataArray[i].buyerName;
      tempObj.buyerEmail = dataArray[i].buyerEmail;
      tempObj.buyerContact = dataArray[i].buyerPhone;
      tempObj.buyerAddress = dataArray[i].buyerAddress;
      tempObj.buyerCountry = dataArray[i].buyerCountry;
      tempObj.annualSale = dataArray[i].previousAnnualSale;
      tempObj.expectedAnnualSale = dataArray[i].currentAnnualSale;

      tempObj.action = 'N/A'

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //Financial Grid

  function fetchfinTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setfintableData(formatfinDataFortable(result.finData))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  }

  const handlefinPageChange = page => {
    fetchfinTableData(page, {});
  };

  const handlefinPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setfintableData(formatfinDataFortable(result))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  };

  const handlefinCheckChange = (state) => {
    setFinancial({ type: 'Recourse', data: state.selectedRows });
  };

  const handlefin2CheckChange = (state) => {
    setsecondFinancial({ type: 'Non-Recourse', data: state.selectedRows });
  }

  function formatfinDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.year = dataArray[i].year;
      tempObj.currency = dataArray[i].currency.split(":")[1];
      tempObj.turnover = dataArray[i].turnover + " " + dataArray[i].currency.split(":")[1];
      tempObj.netprofit = dataArray[i].netprofit + " " + dataArray[i].currency.split(":")[1];
      tempObj.networth = dataArray[i].networth + " " + dataArray[i].currency.split(":")[1];
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      tempObj.action = 'N/A';

      tempObj.finance_doc_1_year = dataArray[i].finance_doc_1_year;
      tempObj.finance_doc_2_year = dataArray[i].finance_doc_2_year;
      tempObj.finance_doc_3_year = dataArray[i].finance_doc_3_year;
      tempObj.finance_doc_current_year = dataArray[i].finance_doc_current_year;
      tempObj.gst_doc_6_month = dataArray[i].gst_doc_6_month;
      tempObj.itr_doc_1_year = dataArray[i].itr_doc_1_year;
      tempObj.itr_doc_2_year = dataArray[i].itr_doc_2_year;
      tempObj.itr_doc_3_year = dataArray[i].itr_doc_3_year;
      tempObj.debtor_doc = dataArray[i].debtor_doc;
      tempObj.creditor_doc = dataArray[i].creditors_doc;

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function createMiniBody(data) {
    let keysArray = Object.keys(docJson)
    let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
      return (<>
        {docJson[keyValue].map((arrayElement) => {
          return (
            <div className="col-md-4 bg-light border-left mb-1">
              <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(data[arrayElement.name], "view")}>
                {keyValue + " - ( " + arrayElement.lable + " )"}
              </button>
            </div>
          )
        })}
      </>)
    }) : ""

    return (returnJSX)

  };

  //Authorized Signatory Grid

  function fetchauthorizedTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getShareHolderGrid', objectAPI).then((result) => {
      console.log('running getShareHolderGrid api-->', result);
      setauthorizedtableData(formatauthorizedDataFortable(result.message));
      setLoading(false);
    }).catch((e) => {
      console.log('error in getShareHolderGrid', e);
      setLoading(false)
    })
  }

  const handleauthorizedPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getShareHolderGrid', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      setauthorizedtableData(formatauthorizedDataFortable(result.contractdata, objectAPI.userId))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  };

  const handleauthorizedPageChange = page => {
    fetchauthorizedTableData(page, {});
  };

  function formatauthorizedDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.name = dataArray[i].name;
      tempObj.country = dataArray[i].country;
      tempObj.nationality = dataArray[i].nationality;
      tempObj.email_id = dataArray[i].email_id;
      tempObj.share = dataArray[i].share_in_company;
      tempObj.admin_status = dataArray[i].admin_status;
      tempObj.signatory = dataArray[i].signatory;
      tempObj.itr = dataArray[i].itr;
      tempObj.din = dataArray[i].din;

      let doc = dataArray[i].doc_array.split(',');
      tempObj.kyc_doc1 = <button className="btn btn-link btn-sm p-0 m-0" onClick={() => callPreviewFileComponent(doc[0], "view")}>
        View
      </button>
      tempObj.kyc_doc2 = <button className="btn btn-link btn-sm p-0 m-0" onClick={() => callPreviewFileComponent(doc[1], "view")}>
        View
      </button>

      tempObj.action = 'N/A'

      resultArray.push(tempObj)
    }
    return resultArray
  };

  const handleauthorizedCheckChange = (state) => {
    setSelectedAuthorized(state.selectedRows);
  };

  //Bank Details Grid

  function fetchbankTableData(page, inptObject) {
    setLoading(true);

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      console.log('running getUserBanks api-->', result);
      setbanktableData(formatbankDataFortable(result.message));
      setLoading(false);
    }).catch((e) => {
      console.log('error in getUserBanks', e);
      setLoading(false)
    })
  }

  const handlebankPerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      console.log('running getUserBanks api-->', result);
      setbanktableData(formatbankDataFortable(result.message));
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getUserBanks', e);
      setLoading(false);
    })
  };

  const handlebankPageChange = page => {
    fetchbankTableData(page, {});
  };

  function formatbankDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.userEmail = dataArray[i].email;
      tempObj.account = dataArray[i].beneficiaryAccountNo;
      tempObj.bankName = dataArray[i].beneficiaryBankName;
      tempObj.accountType = dataArray[i].acount_type;
      tempObj.ifscCode = dataArray[i].beneficiaryIfscCode;
      tempObj.swiftCode = dataArray[i].beneficiarySwiftCode;
      tempObj.beneficiaryName = dataArray[i].beneficiaryAccountName;
      tempObj.beneficiaryBranchAddress = dataArray[i].beneficiaryBranchAddress;

      resultArray.push(tempObj)
    }
    return resultArray
  };

  const handlebankCheckChange = (state) => {
    setSelectedBank(state.selectedRows);
  };

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  const handledocumentChange = (event, ind) => {
    if (event.target.name === 'name') {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    } else {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    }
    setDocumentRow({ ...documentRow })
  };

  const handledocumentFiles = (event, ind) => {
    event.persist();
    documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.files[0] }
    setDocumentRow({ ...documentRow });
  };

  function addDocumentRow() {
    if (documentRow.data.length < 5) {
      documentRow.data.push({ name: null });
      setDocumentRow({ ...documentRow });
    }
  }

  function deleteDocumentRow(ind) {
    documentRow.data.splice(ind, 1)
    setDocumentRow({ ...documentRow })
  }

  function getDocumentRow() {

    let rowsArr = [];
    for (let i = 0; i < documentRow.data.length; i++) {
      rowsArr.push(
        <>
          <div className="modal-padding" id={"CreditLine:49"}>
            <ul className="other-documents pb-0 mb-0">
              <li >
                <label className="mb-0" >
                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                </label>
              </li>


              <li>
                <input type="text" className={" form-control" + (!error.multipledocumentName ? '' : ' border-danger')} placeholder="Document Name" name="document" value={data.document} onChange={(event) => handledocumentChange(event, i)} required />
                {error.multipledocumentName && <p className="text-danger error-contract">{error.multipledocumentName}</p>}
              </li>


              <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                <div className="file-browse">
                  <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
                  <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={documentRow.data[i].document + ":49"} onChange={(event) => handledocumentFiles(event, i)} disabled={!documentRow.data[i].document} />
                  {error[documentRow.data[i].dirContractDocument] && <p lassName="text-danger error-contract">{error[documentRow.data[i].dirContractDocument]}</p>}
                </div>
              </li>
              <li style={{ "min-width": "150px" }}>
                {(documentRow.data[i][documentRow.data[i].document + ":49"]) ? <div className="filePath text-left"><div className="file-name">{`${documentRow.data[i][documentRow.data[i].document + ":49"].name}`}</div></div> : ""}
              </li>
              <li>
                <div className="col-md-1">
                  <span className="field">
                    <i className="fa fa-trash fa-2x" aria-hidden="true" onClick={() => deleteDocumentRow(i)}></i>
                  </span>
                </div>
              </li>
            </ul>
            <br />
            <br />
          </div>
        </>
      )
    }
    return rowsArr;
  }

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const getBankCreditDocument = (event) => {
    if (event.target.name == 'lender') {
      call('POST', 'getCreditlineDocname', { lendername: event.target.value }).then((result) => {
        setBankcreditDoc(result.finalObj);
      })
    }
  }

  const handleFile = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    setError(error => ({ ...error, [event.target.name]: "" }))
  }

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, documentRow, selectedBuyer, secondfinancial));
    setIsSubmitting(true);
  };

  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = userId
    data.userEmail = userEmail
    let formData = new FormData();
    data.buyersDetail = JSON.stringify(selectedBuyer);
    data.financialDetail = financial.data !== null ? JSON.stringify(financial.data) :
      secondfinancial.data !== null ? JSON.stringify(secondfinancial.data) : '';
    data.authorizedSignatoryDetail = JSON.stringify(selectedAuthorized);
    data.bankDetail = JSON.stringify(selectedBank);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });


    let docName = [];

    Object.keys(documentRow.data).forEach((key) => {
      if (documentRow.data[key].document) {
        docName.push(documentRow.data[key].document);
      }
      formData.append('document', docName);

      if (documentRow.data[key][documentRow.data[key].document + ":49"]) {
        formData.append((documentRow.data[key].document + ":49"), documentRow.data[key][documentRow.data[key].document + ":49"])
      }
    });

    call('POST', "insertCreditLineByUser", formData).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setIsSubmitting(false)
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }


  // JSX body
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 pt-2 pb-5 mt-5">

        <div className="col-md-12 mb-2 text-center">
          <h3 className="green border-bottom pb-2">Apply Credit Line</h3>
        </div>
        <br />

        {/* 1st section */}

        {/* <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Select Lender
                                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">

                      <div className="form-group col-md-6">
                        <label>Lender Name {astrix} </label>
                        <select className={"form-control" + (error.lender ? " border-danger" : "")} name="lender" value={data.lender} onChange={
                          (event) => {
                            setrefresh(refresh + 1);
                            handleChange(event);
                            getBankCreditDocument(event);
                          }}  >
                          <option value="" selected>--Select Lender--</option>
                          {bankList.map((value) => {
                            return (
                              <option value={value.company_name + ":" + value.id + ":" + value.email_id} >{value.company_name}</option>
                            )
                          })}
                        </select>
                        {error.lender && <p className="text-danger error-contract">{error.lender}</p>}
                      </div>

                      {data.lender &&
                        <div className="col-md-12">
                          <div className="row pt-2">
                            <div className="form-group col-md-6">
                              <label>Loan type {astrix} </label>
                              <select className={"form-control" + (error.loanType ? " border-danger" : "")} name="loanType" value={data.loanType} onChange={
                                (event) => {
                                  setrefresh(refresh + 1);
                                  handleChange(event);
                                }}  >
                                <option value="" selected>--Select Loan type--</option>
                                <option value="Recourse">Recourse</option>
                                <option value="Non-Recourse">Non-Recourse</option>
                              </select>
                              {error.loanType && <p className="text-danger error-contract">{error.loanType}</p>}
                            </div>
                            <div className="col-md-12">
                              <div className="form-check pl-0 mt-1 mb-1">
                                <input className="form-check-input" type="checkbox" name="loanTerms" id="loanTerms" onChange={hanleDocChecks} checked={data.loanTerms} />
                                <label className="form-check-label" for="loanTerms">
                                  I accept/Agree to all the details provided by me.
                              </label>
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        {/* 2nd Section */}

        {/* {(data.loanTerms) &&
          <>
            <div className="accordionWrapper row pr-0 pt-0">
              <div className="container-fluid accordionItem open">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="green mt-3">
                          {data.lender.split(':')[0]} Documents
                                    </h3>
                        <hr />
                      </div>
                      <div className="col-md-12">
                        <div className="row pt-2">
                          <div className="form-group col-md-6">
                            <div className="form-group pb-0 mb-0">
                              <ul className="other-documents pb-0 mb-0">
                                <li style={{ "min-width": "300px" }}><label className="mb-0" ><b>Document Name</b></label></li>
                                <li style={{ "min-width": "210px" }}><label className="mb-0" ><b>Sample Copy</b></label></li>
                                <li style={{ "min-width": "200px" }}><label className="mb-0" ><b>Upload Document</b></label></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row pt-2">
                          <div className="form-group col-md-6">
                            {(bankcreditDoc !== null) &&
                              bankcreditDoc.map((item) => {
                                return (
                                  <div className="form-group pb-0 mb-0" id={item.key}>
                                    <ul className="other-documents pb-0 mb-0">
                                      <li style={{ "min-width": "300px" }}>
                                        <label className="mb-0" >
                                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                          {item.name}
                                        </label>
                                      </li>

                                      <li style={{ "min-width": "200px" }}>
                                        <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                          () => callPreviewFileComponent(item.docId, "view")}>
                                          <i className="fa fa-eye" aria-hidden="true"></i>
                                        </button>
                                        <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                          () => callPreviewFileComponent(item.docId, "download")}>
                                          <i className="fa fa-download" aria-hidden="true"></i>
                                        </button>
                                      </li>

                                      <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                        <div className="file-browse">
                                          <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
                                          <input type="file" accept=".png,.jpg,.pdf" id="file_1" name={item.key} onChange={handleFile} />
                                          {error[item.key] && <p className="error-contract p-0 m-0">{error[item.key]}</p>}
                                        </div>
                                      </li>

                                      <li style={{ "min-width": "500px" }}>
                                        {(data[item.key]) ? <div className="filePath text-left"><div className="file-name">{`${data[item.key].name}`}</div></div> : ""}
                                      </li>
                                    </ul>
                                    <hr className="" />
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>} */}


        {/* 3rd section */}

        <>
          {/* <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Select Buyers {astrix} {toolTip('counterParty')}
                      </h3>
                      {error.selectedBuyer && <p className="text-danger error-contract">{error.selectedBuyer}</p>}
                      <hr />
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={tableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handleCheckChange}
                        selectableRowSelected={rowSelectCritera}
                      />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Select Financial Details {astrix} {toolTip('financialDetails')}
                      </h3>
                      {/* <p>(To add more Financial Details go to Edit Profile section.)</p> */}
                      {error.financial && <p className="text-danger error-contract">{error.financial}</p>}
                      <hr />
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={fincolumns}
                        data={fintableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handlefinPerRowsChange}
                        onChangePage={handlefinPageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={<TableExpandedComponent />}
                        expandOnRowClicked
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                        //rows with checkbox
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handlefinCheckChange}
                      />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        {/* 4th section */}

        {/* {data.loanTerms && data.loanType === 'Non-Recourse' && */}
        {/* <>

          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Select Counter Party {astrix} {toolTip('counterParty')}
                      </h3>
                      {error.selectedBuyer && <p className="text-danger error-contract">{error.selectedBuyer}</p>}
                      <hr />
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={tableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                        //rows with checkbox
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handleCheckChange}
                      />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Select Financial Details {toolTip('financialDetails')}
                      </h3>
                      <hr />
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={fincolumns}
                        data={fintableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handlefinPerRowsChange}
                        onChangePage={handlefinPageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={<TableExpandedComponent />}
                        expandOnRowClicked
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                        //rows with checkbox
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handlefin2CheckChange}
                      />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </> */}
        {/* } */}

        {/* 5th section */}

        {/* {data.loanTerms && */}
        <>

          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Authorized Signatory {toolTip('authorizedSignatory')}
                      </h3>
                      <hr />
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={authorizedcolumns}
                        data={authorizedtableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handleauthorizedPerRowsChange}
                        onChangePage={handleauthorizedPageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                        //rows with checkbox
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handleauthorizedCheckChange}
                      />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Bank Details {toolTip('bankDetails')}
                      </h3>
                      <hr />
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={bankcolumns}
                        data={banktableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handlebankPerRowsChange}
                        onChangePage={handlebankPageChange}
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                        //rows with checkbox
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handlebankCheckChange}
                      />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* } */}

        {/* 6th section */}

        {/* {data.loanTerms && */}
        <>
          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Credit Line Details
                      </h3>
                      <hr />
                    </div>
                    <div className="col-md-12">
                      <div className="row pt-2">

                        <div className="form-group col-md-6">
                          <label>Credit Amount (USD) {astrix} </label>
                          <input type="number" className={" form-control" + (!error.creditAmount ? '' : ' border-danger')} placeholder="" name="creditAmount" value={data.creditAmount} onChange={handleChange} />
                          {error.creditAmount && <p className="text-danger error-contract">{error.creditAmount}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Days Required {astrix} </label>
                          <input type="text" className={" form-control" + (!error.daysRequired ? '' : ' border-danger')} placeholder="" name="daysRequired" value={data.daysRequired} onChange={handleChange} />
                          {error.daysRequired && <p className="text-danger error-contract">{error.daysRequired}</p>}
                        </div>

                        <div className="col-md-12">
                          <div className="form-check pl-0 mt-1 mb-1">
                            <input className="form-check-input" type="checkbox" name="companyProfile" id="companyProfile" onChange={hanleDocChecks} checked={data.companyProfile} />
                            <label className="form-check-label" for="companyProfile">
                              I accept/Agree to share my Company Profile, KYC documents and Financial Details with lender.
                            </label>
                          </div>
                        </div>

                        {/* <div className="col-md-12">
                            <div className="form-check pl-0 mt-1 mb-1">
                              <input className="form-check-input" type="checkbox" name="kycDocument" id="kycDocument" onChange={hanleDocChecks} checked={data.kycDocument} />
                              <label className="form-check-label" for="kycDocument">
                                I accept/Agree to share my KYC documents with lender.
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-check pl-0 mt-1 mb-1">
                              <input className="form-check-input" type="checkbox" name="finDetails" id="finDetails" onChange={hanleDocChecks} checked={data.finDetails} />
                              <label className="form-check-label" for="finDetails">
                                I accept/Agree to share my Financial details with lender.
                              </label>
                            </div>
                          </div> */}
                        <br />
                        <br />
                        <hr className="pt-1 pb-1" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* } */}
        {(data.companyProfile == true) &&
          <button type="button" className="btn btn-success btn-sm" onClick={() => preHandleSubmit()} >Submit</button>
        }
      </div>

      {showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
          viewTrail={false}
        />}
    </>
  );
};

// Redux
// Getting the global state from redux store.
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    creditLineDetails: state.creditLineDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setcreditLineDetails: (status, info) => { dispatch(setcreditLineDetails({ status: status, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(mapStateToProps, mapDispatchToProps)(CreateCreditLine);
