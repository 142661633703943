export const lcfast_grid_columns = [
  {
    name: 'Application No.',
    selector: 'application_id',
    grow: 3,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    }
  },
  {
    name: 'Benificiary Name',
    selector: 'benificiary_name',
    grow: 2,
  },
  // {
  //   name: 'Benificiary Email Id',
  //   selector: 'benificiary_email_id',
  //   grow: 2,
  // },
  {
    name: 'Buyer Name',
    selector: 'buyer_name',
    grow: 2,
  },
  {
    name: 'DUNS No.',
    selector: 'buyer_duns_no',
    grow: 2,
  },
  {
    name: 'Offer By Bank Name',
    selector: 'offer_by_bank',
    grow: 2,
  },
  {
    name: 'Rate of Interest',
    selector: 'roi',
    grow: 2,
  },
  {
    name: 'Amount',
    selector: 'amount',
    grow: 2,
  },
  {
    name: 'LC Tenor',
    selector: 'lc_tenor',
    grow: 2,
  },
  {
    name: 'Shipment From Country',
    selector: 'shipment_from_country',
    grow: 2,
  },
  {
    name: 'Shipment To Country',
    selector: 'shipment_to_country',
    grow: 2,
  },
  {
    name: 'Expected date of shipment',
    selector: 'exp_date_of_shipment',
    grow: 2,
  },
  {
    name: 'Country of origin',
    selector: 'country_of_origin',
    grow: 2,
  },
  {
    name: 'Commodity',
    selector: 'commodity',
    grow: 2,
  },
  {
    name: 'Issuing Bank Name',
    selector: 'issuing_bank_name',
    grow: 2,
  },
  {
    name: 'Issuing Bank Address',
    selector: 'issuing_bank_address',
    grow: 2,
  },
  {
    name: "LC Document",
    grow: 2,
    selector: 'lc_doc_action',
    center: true
  },
  {
    name: 'Status',
    selector: 'status',
    grow: 3,
    conditionalCellStyles: [
      {
        when: row => (row.status === "Pending"),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        }
      },
      {
        when: row => (row.status === "Approved by Bank"),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        }
      },
      {
        when: row => (row.status === "Rejected by Bank"),
        style: {
          backgroundColor: 'rgba(255, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === "Deal Locked"),
        style: {
          backgroundColor: 'rgba(48, 135, 142, 0.9)',  //primary
          color: 'white',
        },
      }
    ]
  },
  {
    name: "Action",
    grow: 2,
    selector: 'action',
    center: true
  }
]

export const lcfast_grid_columns_v2 = [
  {
    name: 'Document Credit Number',
    selector: 'docCreditNo',
    grow: 1,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    }
  },
  {
    name: 'Benificiary Name',
    selector: '59',
    grow: 1,
  },
  {
    name: 'Currency and Amount',
    selector: '32B',
    grow: 1,
  },
  {
    name: 'Port of Loading/Airport of Departure',
    selector: '44E',
    grow: 1,
  },
  {
    name: 'Port of Discharge/Airport of Destination',
    selector: '44F',
    grow: 1,
  },
  {
    name: 'Latest Date of Shipment',
    selector: '44C',
    grow: 1,
  },
  {
    name: 'Applicant Bank',
    selector: '51a',
    grow: 1,
  },
  {
    name: 'Reimbursing Bank',
    selector: '53a',
    grow: 1,
  },
  {
    name: "LC Document",
    grow: 1,
    selector: 'lc_doc_action',
    center: true
  },
  {
    name: 'Status',
    selector: 'status',
    grow: 1,
    conditionalCellStyles: [
      {
        when: row => (row.status === "Pending"),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        }
      },
      {
        when: row => (row.status === "Approved by Bank"),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        }
      },
      {
        when: row => (row.status === "Rejected by Bank"),
        style: {
          backgroundColor: 'rgba(255, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === "Deal Locked"),
        style: {
          backgroundColor: 'rgba(48, 135, 142, 0.9)',  //primary
          color: 'white',
        },
      }
    ]
  },
  {
    name: "Action",
    grow: 1,
    selector: 'action',
    center: true
  }
]