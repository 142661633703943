import React, { useState } from "react";
import { NewInput } from "../../../utils/newInput";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";

const BalanceCard = ({ data, coindata }) => {
  const [addcoinpopup, setaddcoinpopup] = useState()
  const [coins, setcoins] = useState({
    coins: 10,
    amount: 1
  })
  const [errors, setErrors] = useState({})
  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    const amount = event.target.value / 10
    const coins = event.target.value
    setcoins({
      coins,
      amount
    })
    if (event.target.value === '') {
      setErrors({ ...errors, [event.target.name]: "Coins to be added cannot be empty" })
      return
    }
    if (event.target.value < 10) {
      setErrors({ ...errors, [event.target.name]: "Minimum coins purchased should be greater than 10" })
      return
    }
    setErrors({ ...errors, [event.target.name]: "" })
  }
  return (
    <>
      <div className='card p-3 borderRadius StandardCard' style={{
        width: "240px",
        height: "130px"
      }}>
        {/* <div className=""> */}
        <a className='font-size-16 text-color1 text-decoration-none font-wt-600' href="/plans">{data.plan_name + " - " + data.plan_type}</a>
        {/* </div> */}
        <div className="col d-flex flex-row align-items-end px-0">
          {/* <div className="col">
            <div className="text-center">
              <label className='font-size-26 lh-35 text-color1 text-decoration-none font-wt-600 mb-0'>{data.lc_nos}</label>
            </div>
            <div className="text-center">
              <label className="font-size-14 lineheight19 letter-spacing05 font-wt-500 text-center mb-0">LC</label>
            </div>
          </div> */}
          <div className="col">
            <div className="text-center">
              <label className='font-size-26 lh-35 text-color1 text-decoration-none font-wt-600 mb-0'>{data.invoice_nos}</label>
            </div>
            <div className="text-center">
              <label className="font-size-16 font-wt-500 text-center mb-0">Invoice</label>
            </div>
          </div>
          {coindata &&
            <div className="col">
              <div className="text-center">
                <label className='font-size-26 lh-35 text-color1 text-decoration-none font-wt-600 mb-0'>{coindata.coins}</label>
              </div>
              <div className="text-center">
                <label className="font-size-16 font-wt-500 text-center mb-0">Coins</label>
              </div>
            </div>
          }

        </div>
      </div>
    </>
  );
}
export default BalanceCard;