import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import call from '../../service';
import columns from '../dataTablesColumsMap/userShareholderDetailsGrid';
import customStyles from '../dataTablesColumsMap/customTableCss';
import toastDisplay from '../../utils/toastNotification';
import FilePreview from '../utilComponents/previewFile'

const ShareholderDetailGrid = ({ userTokenDetails, refresh, setrefresh }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  // const [refresh, setrefresh] = useState(0);
  const [deleteConfirm, setdeleteConfirm] = useState({ modal: false, id: "" });
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  // const [adminAction, setadminAction] = useState({ modal: false, data: {} });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const parentId = userTokenDetails.parentId !== undefined ? userTokenDetails.parentId : null

  //---------------------------------------------------------------------------------------------------------------------

  //UseEffect
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})
    //---------------------------------------------------------------------------------------------------------------------
  }, [refresh, filterData]);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchKey, setsearchKey] = useState('');
  const [perPage, setPerPage] = useState(10);

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getShareHolderGrid', objectAPI).then((result) => {
      console.log('running getShareHolderGrid api-->', result);
      settableData(formatDataFortable(result.message));
      setLoading(false);
    }).catch((e) => {
      console.log('error in getShareHolderGrid', e);
      setLoading(false)
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "userParentId": parentId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getShareHolderGrid', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.contractdata, objectAPI.userId))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  };

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  function deleteUserFinDetails(finDtId) {

    call('POST', 'deleteShareHolderGrid', { finDtId: finDtId }).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
      setdeleteConfirm({ modal: false, id: '' })
    }).catch((e) => {
      console.log('error in deleteShareHolderGrid', e);
      toastDisplay(e, "error");
    })

  }

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.name = dataArray[i].name;
      tempObj.country = dataArray[i].country;
      tempObj.nationality = dataArray[i].nationality;
      tempObj.email_id = dataArray[i].email_id;
      tempObj.share = dataArray[i].share_in_company;
      tempObj.admin_status = dataArray[i].admin_status;
      tempObj.signatory = dataArray[i].signatory;
      tempObj.itr = dataArray[i].itr;
      tempObj.din = dataArray[i].din;

      let doc = dataArray[i].doc_array.split(',');
      tempObj.kyc_doc1 = <button className="btn btn-link btn-sm p-0 m-0" onClick={() => callPreviewFileComponent(doc[0], "view")}>
        View
      </button>
      tempObj.kyc_doc2 = <button className="btn btn-link btn-sm p-0 m-0" onClick={() => callPreviewFileComponent(doc[1], "view")}>
        View
      </button>

      tempObj.action = <button className="btn btn-danger btn-sm" onClick={() => setdeleteConfirm({ modal: true, id: dataArray[i].id })} >
        <i className={"fas fa-trash"} aria-hidden="true"></i>
      </button>

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  //---------------------------------------------------------------------------------------------------------------------

  // JSX Return
  return (
    <>
      <div className="d-md-flex mt-3 mb-3">
        <div className="ml-md-auto relative">
          <ul className="range-slider">
            <li>
              <input name="search" placeholder="Search" value={searchKey} onChange={event => setsearchKey(event.target.value)} id="searchInput" onKeyDown={handleKeyPress} />
              <button type="button" onClick={() => {
                setFilterData({
                  ...filterData,
                  "search": searchKey
                })
              }}><i class="fas fa-search fa-lg"></i></button>
            </li>
            <li>
              <input className={"form-control"} name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" type="date" />


            </li>
            <li>
              <input className={"form-control"} name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" type="date" />

            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

          </ul>
        </div>
      </div>


      <div className="table-responsive">
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
          persistTableHead
          highlightOnHover
          onSort={handleSort}
          sortServer
          striped
          noHeader
          customStyles={customStyles}
        />
      </div>
      <hr />

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
      />}

      {deleteConfirm.modal &&
        <div className={"modal" + (deleteConfirm.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Confirmation</h4>
                <button type="button" className="close" onClick={() => { setdeleteConfirm({ modal: false, id: '' }) }}>×</button>
              </div>
              <div className="modal-body">
                Please Confirm Delete Operation
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteUserFinDetails(deleteConfirm.id)}>Delete</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm({ modal: false, id: "" })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default ShareholderDetailGrid;