import React, { useEffect, useState } from 'react';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import { connect } from 'react-redux';
import { setshowModalAddComm } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import Header from '../partial/header';
import UserCommodityAddModal from './userCommodityAddModal'
import AdminCommodityAddModal from './adminCommodityAddModal'
import toastDisplay from '../../utils/toastNotification';
import commImageUrl from '../../utils/makeCommImageUrl'
import Footer from '../partial/footer';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import NewTablev2 from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import Filter from '../InvoiceDiscounting/components/Filter';
import { useHistory } from "react-router";
import { FileInput } from '../../utils/FileInput';
import { handleFileCommonFunction } from '../../utils/myFunctions';
import { aiServerBackendUrl } from '../../urlConstants';
import axios from 'axios';
import { Action } from '../myCounterPartComp/action';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
// import { userPlanQuotaFetch } from '../../utils/myFunctions';


const CommoditiesListV2 = ({ userTokenDetails, showModalAddComm, setshowModalAddComm, navToggleState, dispatch }) => {

  const queryParams = new URLSearchParams(window.location.search)

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  let [selectedComm, setselectedComm] = useState('');
  let [refresh, setrefresh] = useState(0);
  // pagination
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [entryCountField, setEntryCountField] = useState('');
  const [commodityCategories, setcommodityCategories] = useState([])
  const [categoryId, setcategoryId] = useState('');
  const [searchKey, setsearchKey] = useState('')
  const [filterData, setFilterData] = useState({})
  const [filter, setFilter] = useState({ resultPerPage: 10, search: queryParams.get("search") ? decodeURIComponent(queryParams.get("search")) : null })
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [action, setAction] = useState({ show: false, index: null })
  const [groupUploadPopup, setGroupUploadPopup] = useState({ show: false })
  const [data2, setData2] = useState({})

  let history = useHistory()


  const [tabsArr, setTabsArr] = useState([
    { name: "Item Details" },
    // { name: "Commodity Specification" }
  ])
  const [activeClass, setActiveClass] = useState(0);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // console.log("aclMappppppppppppppppppppppppppppppppppppppppppppp", aclMap);
  //---------------------------------------------------------------------------------------------------------------------




  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("manageCommodities");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  function addNewCommodity() {
    setshowModalAddComm(true, { "type": "addNewCommodityUser" })
    // if (aclMap.commodity_access?.commodity_addUser?.isExist) {
    //   let planData = userPlanQuotaFetch()
    //   if (!planData?.["commodities"]?.limitLeft) {
    //     toastDisplay("You have already used 100% of commodity contracts quota", "info")
    //   }
    //   else {
    //     setshowModalAddComm(true, { "type": "addNewCommodityUser" })
    //   }
    // }
    // else {
    //   toastDisplay("Access not available to add commodity", "info")
    // }
  }

  useEffect(() => {
    call('get', 'v1/plan/quota').then((result) => {
      console.log("API result getUserCurrentPlanQuota header:-->", result);
      setUserPlanQuota(result)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota header:-->", e);
    });
  }, [])

  useEffect(() => {
    if (queryParams.get("add")) {
      addNewCommodity()
    }
  }, [])

  useEffect(() => {
    call('POST', 'getusercommoditycategory', { userId: userId }).then((result) => {
      if (result.length) {
        setcommodityCategories(result);
        let filterData = {}
        filterData["Categories"] = {
          "accordianId": 'Categories',
          type: "checkbox",
          data: result,
          labelName: "category"
        }
        setFilterData(filterData)
      }
    }).catch((e) => {
      console.log("Error while querying getcommoditycategory:", e);
    })
  }, [])

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (userTypeId == 1) {
      call('GET', 'getcommoditycategory').then((result) => {
        if (result.length) {
          setcommodityCategories(result);
        }
      }).catch((e) => {
        console.log("Error while querying getcommoditycategory:", e);
      })
    }
    else {
    }
    getCommodityList()
    // let last = count ? Math.ceil(count / PAGE_CAPACITY) : 0;
    // if ((data[page] && data[page].length) && (page !== last)) {
    // setPagination(count)
    // setshowLoader(false)
    // } else {
    // let reqBody = { "userId": userId, 'userTypeId': userTypeId, page, pageLimit: PAGE_CAPACITY, grid: true }
    // if (categoryId) {
    //   reqBody["commCatId"] = categoryId
    // }
    // call('POST', 'getusercommoditylist', reqBody).then((result) => {
    //   console.log("api result in getusercommoditylist->", result)
    //   data[page] = result.data;
    //   setData(data);
    //   setCount(result.count)
    //   setshowLoader(false)
    //   setPagination(result.count)

    // }).catch((e) => {
    //   setshowLoader(false)
    //   console.log("Error while querying users:", e);
    // })
    // }
    //------------------------------------------------------------------

  }, [page, refresh, showModalAddComm.info.refresh, categoryId, filterData]);
  //---------------------------------------------------------------------------------------------------------------------

  function getCommodityList() {
    setshowLoader(true)
    let reqBody = { "userId": userId, 'userTypeId': userTypeId, page, searchKey: filter.search, pageLimit: filter.resultPerPage, grid: true }
    if (categoryId) {
      reqBody["commCatId"] = categoryId
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          reqBody["commCatId"] = ""
          element["data"].forEach((i) => {
            if (i.isChecked && !reqBody["commCatId"]) {
              reqBody["commCatId"] = i.id
            }
          })
        }
      }
    }
    call('POST', 'getusercommoditylist', reqBody).then((result) => {
      console.log("api result in getusercommoditylist->", result)
      setshowLoader(false)
      if (result.data.length) {
        data[page] = result.data;
        setData(data);
        setCount(result.count)
        setPagination(result.count)
      }
      else {
        setData([]);
      }

    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying users:", e);
    })
  }


  //---------------------------------------------------------------------------------------------------------------------
  // Pagination

  function setPagination(count) {
    const last = Math.ceil(count / filter.resultPerPage);
    let items = [];

    items.push(<Pagination.Prev onClick={() => { setshowLoader(true); setPage(1) }} />)
    for (let number = Math.max(1, page - 1); number <= Math.min(page + 1, last); number++) {

      items.push(
        <Pagination.Item key={number} active={number === page.currentPage} onClick={() => { if (number !== page) setshowLoader(true); setPage(number) }}>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next onClick={() => { setshowLoader(true); setPage(last) }} />)

    let startingEntry = ((page - 1) * filter.resultPerPage) + 1;
    let endingEntry = page !== last ? (page * filter.resultPerPage) : count;
    setItems(items);
    setEntryCountField(<p className='col-md-6' style={{ flexGrow: true }}>Showing {startingEntry + '-' + endingEntry + ' of ' + count} Entities</p>);
  }

  // Pagination End
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function deleteSelectedComm() {
    call('POST', 'deleteCommodity', { "selectedComm": selectedComm, 'userTypeId': userTypeId }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      // setrefresh(refresh + 1)
      toastDisplay("Operation Success", "success", () => window.location.reload())
      setdeleteConfirm(false)
    }).catch((e) => {
      console.log("Error while querying users:", e);
      toastDisplay("Something Went Wrong", "error")
      setdeleteConfirm(false)
    })

  }

  const handleChange = (event) => {
    event.persist();
    setcategoryId(event.target.value);
    setCount(0)
    setPagination(0)
    setPage(1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      getCommodityList()
    }
  };

  useEffect(() => {
    if (data.warehouseReceipt?.name) {
      analyzeWarehouseReceipt()
    }
  }, [data.warehouseReceipt])

  async function analyzeWarehouseReceipt() {
    setshowLoader(true)
    try {
      let formData = new FormData()
      formData.append("doc", data.warehouseReceipt)
      let apiResp = await axios.post(`${aiServerBackendUrl}/analyzeWarehouseReceipt`, formData, {})
      // console.log("apireSPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP", apiResp);
      let commDetails = JSON.parse(apiResp.data)
      if (commDetails?.[0]?.product_category && commDetails?.[0]?.product_name) {
        await call('POST', 'addNewCategory', { categoryName: commDetails?.[0]?.product_category })
        setData({ ...data, commDetails })
      }
      else {
        toastDisplay("Auto detection failed, please enter manually", "error")
      }
    } catch (error) {
      console.log("errorinanalyzeWarehouseReceipt", error);
      toastDisplay("Something went wrong", "error")
    }
    setshowLoader(false)
  }

  async function processJSONFileObj() {
    setshowLoader(true)
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let json = JSON.parse(e.target.result);
        json = json.StockItem
        if (json?.length) {
          for (let j = 0; j < json.length; j++) {
            const element = json[j];
            let formData = new FormData();
            formData.append('commdCategory', 0);
            formData.append('commodityPrettyName', element.Name);
            formData.append('commodityName', element.Name.toLowerCase().replace(/\s/g, ''));
            formData.append('spec', JSON.stringify({}));
            formData.append('specOrder', "");
            formData.append('hsn', element.HSNCode)
            formData.append('group', element.Parent)
            formData.append('taxabilityType', !isNaN(element.GSTRate) ? "Taxable" : "")
            if (!isNaN(element.GSTRate)) {
              formData.append('gstRate', element.GSTRate)
            }
            let result = await call('POST', 'savecommoditymaster', formData)
            if (result && result.message && result.message.id) {
              let newFormData = new FormData();
              newFormData.append('owner_id', userId)
              newFormData.append('selectedComm', result.message.id);
              newFormData.append('commQuantity', 0);
              newFormData.append('inprocessCommQuantity', 0);
              newFormData.append('processedCommQuantity', 0);
              newFormData.append('procuredCommQuantity', 0);
              newFormData.append('spec', JSON.stringify({}));
              newFormData.append('specOrder', "");
              newFormData.append('procuredDetails', JSON.stringify([]))
              newFormData.append('commUnit', element.Units)
              await call('POST', 'setCommodityuser', newFormData)
            }
          }
          toastDisplay('Data uploaded successfully', "success", () => {
            setGroupUploadPopup({ show: false })
            setrefresh(refresh + 1)
          })
        }
        else {
          toastDisplay('Data not found', "error")
        }
        setshowLoader(false)
      } catch (error) {
        console.error('ErrorInprocessJSONFileObj', error);
        toastDisplay('Something went wrong', "error")
        setshowLoader(false)
      }
    };
    reader.readAsText(data2.tallyGroupJsonFile);
  }
  //---------------------------------------------------------------------------------------------------------------------



  const handleCreateNew = () => {

    if (booladd || userPermissions == null) {
      window.location = '/manageCommodities?add=true'
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid" >
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <SideBarV2 context={'admin'} state={'tallyMastersStockItem'} userTokenDetails={userTokenDetails} />   {/* Sidebar */}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <HeaderV2
              title={!showModalAddComm.modal ? 'Tally > Inventory Master > Stock Item' : showModalAddComm.info.status ? ' Tally > Inventory Master > Stock Item > View/Update' : ' Tally > Inventory Master > Stock Item > Create New '}
              // total={'Total ' + count + ' Commodity'}
              // showNavBack={showModalAddComm.modal}
              onBackAction={() => setshowModalAddComm(false, {})}
              userTokenDetails={userTokenDetails} />
            {groupUploadPopup.show && (
              <FinanceInvoiceModal
                limitinvoice={groupUploadPopup.show}
                setLimitinvoice={() => { setGroupUploadPopup({ show: false }) }}
                closeSuccess={() => { setGroupUploadPopup({ show: false }) }}
              >
                <label className="text-center font-size-16 font-wt-600 w-100">Import From Tally</label>
                <label className="text-center font-size-13 font-wt-600 w-100 mb-4">Please upload the Stock Item JSON file exported using the tally TCP file.</label>
                <div className="d-flex justify-content-center">
                  <div className='col-10'>
                    <FileInput isEditable={true} name={"tallyGroupJsonFile"} value={data2.tallyGroupJsonFile}
                      onChange={e => handleFileCommonFunction(e, data2, errors, setData2, setErrors, setshowLoader, ["json"])}
                      onUploadCancel={() => setData2({ ...data2, [`tallyGroupJsonFile`]: null })}
                    />
                    {errors[`tallyGroupJsonFile`] ? <div class="text-danger mt-2 font-size-12">
                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                      <b>{errors[`tallyGroupJsonFile`]}</b></div> : ''}
                    <div className='d-flex justify-content-center mt-4'>
                      <button disabled={!data2.tallyGroupJsonFile?.name} className={`new-btn w-60 py-2 px-2 text-white cursor`}
                        onClick={processJSONFileObj}
                      >Submit</button>
                    </div>
                  </div>
                </div>
              </FinanceInvoiceModal>
            )}
            {showModalAddComm.modal && showModalAddComm.info?.type != "editCommodityUser" ?
              <a
                onClick={() => {
                  history.go(-1)
                }}
                className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> : null}

            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

            {showModalAddComm.modal ?
              <div>
                {!queryParams.get('add') ?
                  <a
                    className="cursor "
                    onClick={() => { setshowModalAddComm(false, {}) }}
                  ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> : null}
                <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                  {tabsArr.map((item, index) => {
                    return (
                      <li
                        style={{ width: `auto` }}
                        onClick={() => {
                          setActiveClass(index)
                        }}
                      >
                        <a
                          style={{ width: `100%` }}
                          className={"nav-link formTab px-4 cursor-pointer " + (activeClass === index ? " formActiveTab show" : "")}
                        >
                          {item.name}</a>
                      </li>
                    )
                  })}
                </ul>
              </div> :
              <>
                <div className='filter-div position-relative'>
                  <Filter
                    filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                    showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} isAdditionalButton>
                    <div className="d-flex gap-3">
                      {/* <div>
                        <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setGroupUploadPopup({ show: true })}>Import From Tally</button>
                      </div> */}
                      <div>
                        <button className={`new-btn  py-2 px-2 text-white cursor`}
                          onClick={handleCreateNew}
                        >Add New</button>
                      </div>
                    </div>
                  </Filter>
                </div>
                {/* {!userPlanQuotaFetch()?.["commodities"]?.limitLeft ?
                  <label className='text-danger font-size-13 font-wt-600' >{'You have already used 100% of your commodity quota. Please go to your plan and make a purchase in order to be able to add new commodity.'}</label>
                  : null} */}
                <div>
                  <NewTablev2
                    columns={[
                      { subColumns: "Item", subColumnStyle: { width: '13%' } },
                      { subColumns: "Godown", subColumnStyle: { width: '13%' }, subColumnClass: 'ml-2' },
                      { subColumns: "Type/Category", subColumnStyle: { width: '13%' } },
                      { subColumns: "Group", subColumnStyle: { width: '13%' } },
                      { subColumns: "Utilized In Contract", subColumnStyle: { width: '15%' } },
                      { subColumns: "Procured", subColumnStyle: { width: '10%' } },
                      { subColumns: "Procuring", subColumnStyle: { width: '10%' } },
                      { subColumns: "Action", subColumnStyle: { width: '14%' } }]}>
                    {(data.length && data[page] && data[page].length) ? data[page].map((commData, j) => {
                      let totalProcured = 0
                      let totalProcuring = 0
                      let godowns = []
                      for (let index = 0; index < commData?.procured?.length; index++) {
                        let element = commData?.procured[index];
                        if (element.godown) {
                          godowns.push(element.godown)
                        }
                        if (element.state === "Procured" && element.quantity) {
                          totalProcured += element.quantity / 1
                        }
                        if (element.state === "Procuring" && element.quantity) {
                          totalProcuring += element.quantity / 1
                        }
                      }
                      return (
                        <tr>
                          {/* <td>
                            <div className='ml-2'>
                              <img width="60" src={commImageUrl(commData.filename)} className="img-responsive" alt="" />
                            </div>
                          </td> */}
                          <td><label className='font-size-13 font-wt-400' >{commData.commodity_pretty_name ? commData.commodity_pretty_name : "-"}</label></td>
                          <td><label className='font-size-13 font-wt-400' >{godowns.length ? godowns.join(",") : "-"}</label></td>
                          <td><label className='font-size-13 font-wt-400' >{commData.category || "-"}</label></td>
                          <td><label className='font-size-13 font-wt-400' >{commData.group || "-"}</label></td>
                          <td><label className='font-size-13 font-wt-400' >{commData.commQuantityUtilizedInContract || "-"} {commData.unit}</label></td>
                          <td><label className='font-size-13 font-wt-400' >{totalProcured} {commData.unit}</label></td>
                          <td><label className='font-size-13 font-wt-400' > {totalProcuring} {commData.unit}</label></td>
                          <td
                            // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                            className='position-relative'>
                            <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                              onClick={() => setAction({ show: true, index: j })}
                              aria-hidden="true"></i>
                            {action.show && action.index === j ? (
                              <Action
                                id={j}
                                onDismiss={() => setAction({ show: false, index: j })}
                                options={[
                                  {
                                    name: "View/Update",
                                    onClick: async () => { setshowModalAddComm(true, { ...commData, status: true, "type": "editCommodityUser" }) }
                                  }, {
                                    name: "Delete", onClick: async () => { setdeleteConfirm(true); setselectedComm(commData.id) }
                                  }, {
                                    name: "Bulk Break", onClick: async () => {
                                      localStorage.setItem("commDetails", JSON.stringify(commData))
                                      window.location = `/bulkBreak`
                                    }
                                  }
                                ]} />
                            ) : null}
                          </td>
                        </tr>
                      )
                    }) : null}
                  </NewTablev2>
                </div>
              </>
            }

            {/* admin commodity edit modal start */}
            {/* {(showModalAddComm.modal && (aclMap.commodity_access && aclMap.commodity_access.commodity_addAdmin && aclMap.commodity_access.commodity_addAdmin.isExist)) &&
              <AdminCommodityAddModal userTokenDetails={userTokenDetails} />} */}
            {/* admin commodity edit modal end */}

            {/* user commodity edit modal start */}
            {showModalAddComm.modal &&
              <>
                <div className={showModalAddComm.info?.type != "editCommodityUser" ? 'card p-4 d-flex' : ''} >
                  {/* {showModalAddComm.info?.type != "editCommodityUser" ?
                    <>
                      <label className='font-size-16 font-wt-600 text-color-label mb-3 w-100 text-center' >Upload Warehouse Receipt to fetch commodity automatically</label>
                      <div className='d-flex text-center align-items-center justify-content-center' >
                        <div className='w-40 mb-3' >
                          <FileInput name={`warehouseReceipt`} value={data[`warehouseReceipt`]} error={errors[`warehouseReceipt`]}
                            onChange={(e) => { handleFileCommonFunction(e, data, errors, setData, setErrors) }} isEditable
                            onUploadCancel={() => setData({ ...data, [`warehouseReceipt`]: null })} />
                          <label className='font-size-16 font-wt-600 text-color-label my-3' >OR enter details below manually</label>
                        </div>
                      </div> </> : null} */}

                  <UserCommodityAddModal productDetails={data.commDetails?.[0] || null} userTokenDetails={userTokenDetails} activeClass={activeClass} />
                </div>
              </>}
            {/* {showModalAddComm.modal &&
              <UserCommodityAddModal userTokenDetails={userTokenDetails} activeClass={activeClass} />} */}
            {/* user commodity edit modal end */}

            {deleteConfirm &&
              <div className={"modal" + (deleteConfirm ? " show d-block" : "")} id="Confirmation">
                <div className="modal-dialog modal-s border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white font-size-16 font-wt-600">Confirmation</h4>
                      <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
                    </div>
                    <div className="modal-body">
                      <label className='font-size-14 font-wt-400' >Please Confirm Delete Operation</label>
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteSelectedComm()}>Delete</button>
                      <button type="button" className="btn bg-1ea3ae text-white btn-sm" onClick={() => setdeleteConfirm(false)}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>}

            {!showModalAddComm.modal ? (
              <>
                {/* <hr /> */}
                {(items.length !== 0) && data.length ? <div className="float-right row">
                  {/* {entryCountField} */}
                  {/* <Pagination className='col-md-6'>{items}</Pagination> */}
                  <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} />
                  {/* <br /> */}
                </div> : null}
              </>
            ) : null}
          </main>
        </div>
      </div >

    </>
  )
};

const mapStateToProps = state => {

  return {
    showModalAddComm: state.showModalAddComm,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommoditiesListV2)
