import React, { useEffect, useState } from 'react';
import { Map, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react';
import { googleApiKey } from '../../urlConstants';

// Your Map Component
const MapTracking = ({ google, routeCoordinates }) => {

  const [map, setMap] = useState(null);

  // Function to handle map initialization
  const onMapReady = (mapProps, map) => {
    setMap(map);
  };

  // Function to change the center of the map with animation
  const changeMapCenter = (newCenter) => {
    if (map) {
      map.panTo(newCenter);
    }
  };

  // useEffect to trigger the change of map center once the map is ready
  useEffect(() => {
    if (routeCoordinates?.[0]) {
      changeMapCenter(routeCoordinates?.[0]);
    }
  }, [routeCoordinates]);

  return (
    <Map
      google={google}
      zoom={4}
      initialCenter={{ lat: 20.5937, lng: 78.9629 }}
      onReady={onMapReady}
    >
      {/* Render polyline */}
      {routeCoordinates?.length ?
        <Polyline
          path={routeCoordinates}
          strokeColor="#FF0000"
          strokeOpacity={1.0}
          strokeWeight={2}
        /> : null}
      {/* Render markers */}
      {routeCoordinates?.length ? routeCoordinates.map((i, j) => {
        return <Marker position={i} icon={{
          url: j / 1 == 0 ? undefined : `http://maps.google.com/mapfiles/ms/icons/${j / 1 == routeCoordinates.length - 1 ? "green" : "yellow"}-dot.png`,
          scaledSize: new google.maps.Size(32, 32)
        }} />
      }) : null}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: googleApiKey,
})(MapTracking);