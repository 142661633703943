import React from "react";

const SubmitModal = ({ setModal, modal }) => {

  const modalfun = () => {
    setModal(false);
  }

  return (
    <>
      <div className={`modal fade ${modal && "show"}`} style={{ display: "block" }}>
        <div className="modal-dialog modal-md">
          <div className="modal-content submitmodal mb-5 pb-4">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" aria-label="Close" onClick={() => modalfun()}></button>
            </div>
            <div className="modal-body text-center">
              <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
              <p>Requested document has been sent to the selected banks successfully !</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SubmitModal;