import moment from "moment";
import React, { useState } from "react";
import { copyToClipboard } from "../../../utils/myFunctions";
// import { Action } from "../components/myCounterPartComp/action";

export const AccordionTable = ({ columns, data, options, disableAction, expand, tableExpand, toggleInvPdf, setInvoicedata }) => {

  const [action, setAction] = useState({ show: false, index: null })
  console.log("Table expand", tableExpand, expand);
  return (
    <table
      className='tablev2 text-start'>
      <tr className='th'>
        {columns.map((item, index) => {
          return (
            <th
              style={!item.subColumns ? { width: `${item.width}` } : {
                width: `${item.width}`, height: '3.2rem', alignItems: 'baseline'
              }}
              className='heading'>{item.name}
              {item.filter ? (
                <img className='mx-2' src={"assets/images/header/down_arrow.png"} />
              ) : null}
              {item.subColumns ? (
                <div className="tableSubColumns" >
                  {item.subColumns.map(i => (
                    <div>{i}</div>
                  ))}
                </div>
              ) : null}
            </th>)
        })}
      </tr>
      {data.length ? data.map((item, index) => {
        return (
          <>
            <tr className={`th bg-white ${expand.some(code => code.transactionId === tableExpand) && (item.length && item.some((key) => key === tableExpand)) && expand.length && 'border-bottom-0'}`}
            >
              {item.length ? item.map((item, i) => (
                <td
                  style={{ width: `${columns[i]?.width}` }}
                  className='heading'>{item}</td>
              )) : null}

              {disableAction ? null : (
                <td
                  style={{ width: `${100 / columns.length}%` }}
                  className='heading '>
                  <i className="fa fa-ellipsis-v cursor ms-3"
                    onClick={() => setAction({ show: true, index })}
                    aria-hidden="true"></i>
                  {/* {action.show && action.index === index ? (
                  <Action
                    id={index}
                    onDismiss={() => setAction({ show: false, index })}
                    top={`${6 * (index + 1)}rem`} options={options} />
                ) : null} */}
                </td>
              )}


            </tr>
            {expand.some(code => code.transactionId === tableExpand) && (item.length && item.some((key) => key === tableExpand)) && expand.length ?
              <div className="row align-items-center w-100 p-3">
                <div className="col-md-10  pe-2">
                  <div className="row">
                    <div className="col-md-3">
                      <p className="text-secondary font-size-12 mb-1">From</p>
                      <p className="font-size-13 ">{expand[0].createdByName}</p>
                    </div>
                    <div className="col-md-3">
                      <p className="text-secondary font-size-12 mb-1">To</p>
                      <p className="font-size-13 ">Trade Reboot FinTech</p>
                    </div>
                    <div className="col-md-3">
                      <p className="text-secondary font-size-12 mb-1">Transaction Id <img src={"/assets/images/copy.png"} alt="" height={14} width={14} className="cursor" onClick={() => copyToClipboard("transactionId")} /> </p>
                      <p className="font-size-13" id="transactionId">{expand[0].transactionId}</p>
                    </div>
                    {/* <div className="col-md-3">
                      <p className="text-secondary font-size-12 mb-1">Bank reference no. <img src={"/assets/images/copy.png"} alt="" height={14} width={14} /> </p>
                      <p className="font-size-13 ">856974585695234</p>
                    </div> */}
                    <div className="col-md-3">
                      <p className="text-secondary font-size-12 mb-1">Payment status </p>
                      <p className="font-size-13 ">{expand[0].status === 1 ? 'In-Progress' : expand[0].status === 2 ? 'Succesful' : 'Failed'}</p>
                    </div>
                    <div className="col-md-3">
                      <p className="text-secondary font-size-12 mb-1">Time </p>
                      <p className="font-size-13 ">{moment(expand[0].createdAt).format("HH:mm A")}</p>
                    </div>
                  </div>
                </div>
                {((expand[0] && expand[0].charges) && (expand[0] && expand[0].modeOfPayment !== 'Coins') && (expand[0] && expand[0].status === 2)) &&
                  <div className="col-md-1 text-end px-0">
                    {/* <p className="font-size-13 mb-1"> <img src={"/assets/images/copy-icon.png"} alt="" height={14} width={14} /> Copy</p> */}
                    <p className="font-size-13 mb-0" onClick={() => {
                      toggleInvPdf(true)
                      setInvoicedata(expand[0])
                    }}> <img src={"/assets/images/carbon_receipt.png"} alt="" height={14} width={14} /> Invoice</p>
                  </div>
                }
              </div>
              : null}

          </>

        )
      })
        : <tr className="w-100 py-4 text-center">
          <label className="font-size-16 font-wt-600">No Data Found</label>
        </tr>}
    </table>
  )
}