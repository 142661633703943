export default function validate(values) {
  let errors = {};

  if (!values.beneficiaryAccountNo) {
    errors.beneficiaryAccountNo = 'Account No is required';
  } else if (isNaN(values.beneficiaryAccountNo)) {
    errors.beneficiaryAccountNo = 'Account No Should be a number';
  }
  if (!values.beneficiaryBankName) {
    errors.beneficiaryBankName = 'Enter a Bank Name';
  }
  if (!values.beneficiaryBranch) {
    errors.beneficiaryBranch = 'Provide Bank Branch';
  }
  if (!values.beneficiaryBranchAddress) {
    errors.beneficiaryBranchAddress = 'Provide Bank Branch Address';
  }
  if (!values.beneficiaryCity) {
    errors.beneficiaryCity = 'Enter Branch City';
  }
  if (!values.beneficiaryState) {
    errors.beneficiaryState = 'Enter Branch State';
  }
  if (!values.beneficiaryCountry) {
    errors.beneficiaryCountry = 'Enter Branch Country';
  }
  if (!values.beneficiaryPostalCode) {
    errors.beneficiaryPostalCode = 'Enter Branch Postal Code';
  }
  if (!values.beneficiaryAccountName) {
    errors.beneficiaryAccountName = 'Enter Account Name';
  }
  if (!values.beneficiaryIfscCode) {
    errors.beneficiaryIfscCode = 'Enter IFSC Code';
  }
  if (!values.beneficiarySwiftCode) {
    errors.beneficiarySwiftCode = 'Enter Swift Code';
  }


  console.log(errors);
  return errors;
}
