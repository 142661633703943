import React, { PureComponent } from 'react'
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, BarChart, Legend } from 'recharts'
import { MoonLoader } from 'react-spinners'
class CustomBarChart extends PureComponent {
  render() {
    return this.props.loader ?
      <div className="d-flex flex-row justify-content-center align-items-center h-100">
        <MoonLoader
          color={"#1B94B7"}
          loading={this.props.loader}
          size={80}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      : <div><ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}

        >
          <XAxis dataKey={this.props.xDataKey} tickFormatter={this.props.XFormatter} />
          <YAxis tickFormatter={this.props.YFormatter} />
          <Tooltip formatter={this.props.formatterFunction} />
          {(this.props.data?.length && this.props.isLegend) &&
            <Legend iconType={this.props.legendShape || 'circle'} formatter={(value) => this.props.legendsMapper[value]} onClick={this.props.onLegendClick} />
          }
          {this.props.bardataConfig && this.props.bardataConfig.map((item, index) => {
            return <Bar dataKey={item.dataKey} fill={item.fill} stackId={this.props.isStackedChart ? "A" : index} barSize={this.props.barSize ? this.props.barSize : null} radius={this.props.isStackedChart ? (index === this.props.bardataConfig?.length - 1) ? [4, 4, 0, 0] : [0, 0, 0, 0] : [4, 4, 0, 0]} />
          })}
        </BarChart>
      </ResponsiveContainer>
        {this.props.reloadFn &&
          <div className='d-flex justify-content-end align-items-center cursor' onClick={this.props.reloadFn} >
            <img src='assets/images/refresh.png' height={25} width={25} />
            <label className='mb-0 font-size-13 font-wt-500'>Refresh</label>
          </div>
        }
      </div>
  }
}

export default CustomBarChart