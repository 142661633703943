export default function validate(values, formJson) {
  let errors = {};

  let accordKeyArray = (formJson && formJson.properties && Object.keys(formJson.properties).length) ? Object.keys(formJson.properties) : []

  if (accordKeyArray.length) {
    accordKeyArray.map((key, index) => {
      Object.keys(formJson.properties[key].properties).map((subKey) => {
        if (formJson.properties[key].required && formJson.properties[key].required.includes(subKey)) {
          if (!values[key + ":" + subKey]) {
            errors[key + ":" + subKey] = (formJson.properties[key].properties[subKey].label ? formJson.properties[key].properties[subKey].label :
              formJson.properties[key].properties[subKey].title ? formJson.properties[key].properties[subKey].title :
                subKey) + " is Mandatory"
          }
        }
        return null
      })
      return null
    })
  }

  console.log(errors);
  return errors;
}
