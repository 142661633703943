const columns = [

  {
    name: 'Vessel Name',
    selector: 'ship_name',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Imo No',
    selector: 'imo_no',
    sortable: true,
  },
  {
    name: 'Charter Type',
    selector: 'charter_type',
    sortable: true,
  },
  {
    name: 'From',
    selector: 'load_port',
    sortable: true,
  },
  {
    name: 'To',
    selector: 'unload_port',
    sortable: true,
  },
  {
    name: 'Price',
    selector: 'price',
    sortable: true,
  },
  {
    name: 'Contract Date',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'My Role',
    selector: 'myRole',
    sortable: false,
  },

  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    grow: 1,
    cell: row =>
      (row.status === 1) ? "Confirmation Pending" :
        (row.status === 2) ? "Booker Confirmed" :
          (row.status === 3) ? "Provider Confirmed" :
            (row.status === 4) ? "Booking Complete" : "NA",
    conditionalCellStyles: [
      {
        when: row => (row.status === 1),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.status === 2),
        style: {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
          color: 'white',
        },
      },
      {
        when: row => (row.status === 3),
        style: {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
          color: 'white',
        },
      },
      {
        when: row => (row.status === 4),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      }
    ]
  },

  {
    name: 'Actions',
    selector: 'action',
    sortable: true,
  }
];

export default columns
