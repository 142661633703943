import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../../../service';
import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';
import Filter from "../../components/Filter";
import moment from 'moment';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import { getDocDetails } from '../../../../utils/myFunctions';
import ChatBoxPopUp from '../../../chatRoom/components/ChatBoxPopUp';

export const SignTermSheet = ({ userTokenDetails, navToggleState }) => {
  const [dbData, setdbData] = useState([])
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    let objectAPI = {
      "userId": userId,
      "type": 'sign',
      "currentPage": page,
      "resultPerPage": 10,
      ...filter
    }
    call('POST', 'getTermSheet', objectAPI).then((result) => {
      setTableData(formatDataForTable(result.limitData))
      setdbData(result.limitData)
      setCount(result.countData)
    }).catch((e) => {
      throw e;
    });
  }, [refresh])

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)

      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }
  const getChatRoomDetails = () => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    call('POST', 'getChatRoomIdByName', { chat_room_name: chatRoomName }).then((result) => {
      console.log('success in getChatRoomIdByName ', result)
      getChatDetails({
        chat_room_id: result.chat_room_id,
        loggedInUser: userId
      })
    }).catch(e => {
      console.log('error in getChatRoomIdByName', e)
    })
  }
  useEffect(() => {
    getChatRoomDetails()
  }, [selectedChat])

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach(async (item, index) => {
      let selectedQuote = item.selectedQuote ? item.selectedQuote : null;
      row[0] = moment(item.modified_at).format('DD/MM/YYYY')
      row[1] = item.supplierName
      row[2] = item.buyerName
      row[3] = item.incoTerms
      row[4] = item.termsOfPayment ? item.termsOfPayment : null
      row[5] = (selectedQuote && selectedQuote.deniedstage) ?
        <div className='row ' >
          <a>
            <button type="button"
              disabled
              className={`border-0 mb-2 disablesigncontract2 text-dark`}>
              {"Quote Rejected"}
            </button>
          </a>
        </div >
        : (item.termSheetSignedByBank == 1) ?
          <div className='row ' >
            <a>
              <button type="button"
                disabled
                className={`border-0 mb-2 disablesigncontract2 text-dark`}>
                {"Term Sheet Signed"}
              </button>
            </a>
          </div >
          :
          <div className='row ' >
            <a>
              <button type="button"
                onClick={() => {
                  window.location = `/viewSignTermSheet`;
                  localStorage.setItem("termSheetDetail", JSON.stringify(item));
                }}
                className={`border-0 mb-2 text-white enableQuotebtn2`}>
                {"Sign Term Sheet"}
              </button>
            </a>
          </div >
      if (item.termSheetSignedByExporter == 0 && selectedQuote && !selectedQuote.deniedstage) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Term Sheet Uploaded, Waiting For Signature</b></span>
          {/* <label
          className="text-color1 text-decoration-underline cursor ml-2">View Schedule</label>  */}
        </p>
      }
      tableData.push(row)
      row = []
    })
    return tableData
  }

  async function onView() {
    window.location = `viewSignTermSheet?view=true`
  }

  const intiateChat = (id) => {
    let key = dbData[id]
    setSelectedChat({
      receiverName: key.supplierName,
      receiverId: key.user_id,
      userId: userId,
      isChatOpen: true,
      logo: key.supplierAvatar
    })
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_signTermSheet" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice-discounting > Sign Term Sheet >"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4'>
              <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              <NewTable disableAction={false}
                columns={[{ name: "Date", filter: true }, { name: "Supplier Name", filter: true }, { name: "Buyer Name", filter: true }, { name: "Inco Terms", filter: true }, { name: "Terms of Payment", filter: true },
                { name: "", filter: false }, { name: "", filter: false }
                ]}
                data={tableData}
                options={[
                  { name: "Chat with supplier", icon: "edit.png", onClick: (id) => intiateChat(id) },
                  { name: "Documents", icon: "eye.png", onClick: onView },
                  { name: "Quote", icon: "edit.png" }]}
              />
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>
        {selectedChat.isChatOpen &&
          <div className="chatboxDiv">
            <ChatBoxPopUp
              chatList={chatList}
              user_avatar={selectedChat.logo}
              userId={selectedChat.userId}
              receiverName={selectedChat.receiverName}
              onChatSend={(file) => sendChatMsg(file)}
              message={message}
              setTextMsg={setTextMsg}
              onPopupClose={() => {
                setSelectedChat({
                  receiverName: '',
                  receiverId: '',
                  userId: userId,
                  isChatOpen: false,
                  logo: ''
                })
              }}
            />
          </div>
        }
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(SignTermSheet)