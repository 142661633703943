import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setShowCreateProfileAdminModal, setShowViewProfileModal } from '../../store/actions/action';
import CreateProfileAdminModal from '../admin/createProfileModal'
import { ToastContainer } from 'react-toastify';
import EditProfile from '../userProfile/editProfile';
import Pagination from 'react-bootstrap/Pagination';
import commImageUrl from '../../utils/makeCommImageUrl'
import avatarUrl from '../../utils/makeAvatarUrl';
import ViewProfileDemo from '../admin/viewProfileToAdmin';
import toastDisplay from '../../utils/toastNotification';

const PAGE_CAPACITY = 7;

const ManageProfile = ({ userTokenDetails, channelPartnerUser, setShowCreateProfileAdminModal, showCreateProfileAdminModal, setShowViewProfileModal, showViewProfileModal, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(true);
  const [refresh, setrefresh] = useState(0)
  const [userEmailEdit, setuserEmailEdit] = useState('');
  const [openEditProfile, setopenEditProfile] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [entryCountField, setEntryCountField] = useState('');
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [dbTypeData, setdbTypeData] = useState([]);
  const [filterData, setFilterData] = useState({})
  const [planList, setplanList] = useState([])
  const [searchKey, setsearchKey] = useState("")
  const [searchenable, setsearchenable] = useState(false)
  const [subUser, setSubUser] = useState({ modal: false, userData: {} });
  const [addMoreProfile, setAddMoreProfile] = useState({ modal: false, data: {} });
  const [errors, seterrors] = useState({});

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    let last = count ? Math.ceil(count / PAGE_CAPACITY) : 0;

    if ((data[page] && data[page].length) && (page !== last)) {
      setPagination(count)
      setshowLoader(false)
    } else {
      call('POST', 'getusersmanageprofile', { "loggedUserType": userTypeId, "loggedUserId": userId, page, pageLimit: PAGE_CAPACITY, searchUserType: "20", ...filterData }).then((result) => {
        console.log("result while querying users:", result);
        data[page] = result.data;
        setData(data);
        setCount(result.count)
        setshowLoader(false)
        if (result.count) {
          setPagination(result.count)
          setsearchenable(true)
        } else {
          setsearchenable(false)
        }
      }).catch((e) => {
        console.log("Error while querying users:", e);
      })
    }

    call('get', 'v1/plan/quota').then((result) => {
      setUserPlanQuota(result)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota ManageProfile:-->", e);
    })

    //------------------------------------------------------------------
  }, [showCreateProfileAdminModal.info.refresh, page]);

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getusersmanageprofile', { "loggedUserType": userTypeId, "loggedUserId": userId, page, pageLimit: PAGE_CAPACITY, searchUserType: "20", ...filterData }).then((result) => {
      console.log("result while querying users:", result);
      data[page] = result.data;
      setData(data);
      setCount(result.count)
      setshowLoader(false)
      if (result.count) {
        setPagination(result.count)
        setsearchenable(true)
      } else {
        setsearchenable(false)
      }

    }).catch((e) => {
      console.log("Error while querying users:", e);
    })

    //------------------------------------------------------------------
  }, [filterData]);

  useEffect(() => {
    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    call('get', 'getplanslist').then((result) => {
      console.log("API result gettraderplans:-->", result);
      setplanList(result)
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying gettraderplans:", e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Pagination

  function setPagination(count) {
    const last = Math.ceil(count / PAGE_CAPACITY);
    let items = [];

    items.push(<Pagination.Prev onClick={() => { setshowLoader(true); setPage(1) }} />)
    for (let number = Math.max(1, page - 1); number <= Math.min(page + 1, last); number++) {

      items.push(
        <Pagination.Item key={number} active={number === page.currentPage} onClick={() => { if (number !== page) setshowLoader(true); setPage(number) }}>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next onClick={() => { setshowLoader(true); setPage(last) }} />)

    let startingEntry = ((page - 1) * PAGE_CAPACITY) + 1;
    let endingEntry = page !== last ? (page * PAGE_CAPACITY) : count;
    setItems(items);
    setEntryCountField(<p className='col-md-6' style={{ flexGrow: true }}>Showing {startingEntry + '-' + endingEntry + ' of ' + count} Entities</p>);
  }

  // Pagination End
  //---------------------------------------------------------------------------------------------------------------------

  function getprofileData() {

    const profileData = data.length && data[page] && data[page].length ? data[page].map((data) => {
      let typeOfData = [];
      if (data.domain_key) {
        typeOfData = data.domain_key.split(',');
      }
      return (<div className="col-md-3 col-md-3 col-sm-6 col-xs-12">
        <div className={"card mb-3 profile-view " + (data.status / 1 === 1 ? "shadow-sm-success" : data.status / 1 === 2 ? "shadow-sm-danger" : data.status / 1 === 0 ? "shadow-sm-warn" : "shadow-sm")}>

          {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.edit_user_profile && aclMap.mng_subusers_profile.edit_user_profile.isExist) && (data.userDetails && data.userDetails.email_id) &&
            <>
              <button className="edit-icon" onClick={() => { setuserEmailEdit(data.userDetails.email_id); setopenEditProfile(true); setShowViewProfileModal(true, { "data": data, "page": (data.reqStatus == 1 || data.reqStatus == 2) ? 1 : 2 }); }}>
                <i class="fas fa-user-edit text-primary"></i>
              </button>
              <button className={"display-icon rounded-right text-light pl-2 pr-2 " + (data.status / 1 === 1 ? "bg-success" : data.status / 1 === 2 ? "bg-danger" : data.status / 1 === 0 ? "bg-warning" : "")}>
                {data.status / 1 === 1 ? " APPROVED" : data.status / 1 === 2 ? "REJECTED" : data.status / 1 === 0 ? "PENDING" : "NA"}
              </button>
            </>}

          <div className="card-header">
            <img src={avatarUrl(data.userDetails && data.userDetails.user_avatar ? data.userDetails.user_avatar : "default.png")} />
            <h3>{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.contact_person : ""}</h3>
            <p className="card-text">{data.userDetails !== null && data.userDetails !== undefined ? data.userDetails.email_id : ""}</p>
          </div>
          {data.type_id === 4 ?
            <div className="card-body">
              <h4>{data.userType.usertype_pretty_name}{typeOfData.length > 1 ? `  + ${typeOfData.length - 1}  ` : ''}</h4>
              {/* <button type='button' className="btn btn-primary btn-sm" onClick={() => { setAddMoreProfile({ modal: true, data: data }) }} >Add On</button> */}
              {data.type_id != '9' && <button type="button" className="btn btn-primary float-right btn-sm" onClick={() => setSubUser({ modal: true, userData: data })}>Add Sub-User</button>}
              <ul>
                <li>{data.commodities !== undefined && data.commodities !== null ? data.commodities.map((commData) => {
                  return (
                    <img src={commImageUrl(commData.commodityInfo.filename)} />)
                }) : ""}
                </li>
              </ul>
            </div>
            : <div className="card-body">
              <h4>{data.type_id == '20' ? `${data.userDetails && data.userDetails.role === "CP" ? "Channel Partner" : "Franchise Partner"}` : data.userType.usertype_pretty_name}{typeOfData.length > 1 ? `  + ${typeOfData.length - 1}  ` : ''}</h4>
              {/* <button type='button' className="btn btn-primary btn-sm" onClick={() => { setAddMoreProfile({ modal: true, data: data }) }} >Add On</button> */}
              {data.type_id != '9' && data.type_id != '20' && <button type="button" className="btn btn-primary float-right btn-sm" onClick={() => setSubUser({ modal: true, userData: data })}>Add Sub-User</button>}
              <ul>
                <li>
                  <img src={"../assets/images/onbording/usertype/" + `${data.type_id}.png`} />
                </li>
              </ul>
            </div>
          }
        </div>
      </div>)
    }) : ''
    return profileData
  }

  function addNewUser() {
    setShowCreateProfileAdminModal(true, {})
  }

  const setChannelPartner = (status) => {
    if (status === true) {
      call('POST', 'setUserAsChannelPartner', { "userEmail": userEmailEdit }).then((result) => {
        toastDisplay('Successfully Accepted', 'success');
      }).catch((e) => {
        console.log('error in getuserstypelist', e);
      })
    }
    else {
      toastDisplay('Successfully Rejected', 'success');
    }
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  const reset = () => {
    setFilterData({ search: "" })
    setsearchKey("")
  }

  if (showViewProfileModal.modal === false && openEditProfile === true && showViewProfileModal.info.refeshData === true) {
    setrefresh(refresh + 1)
    setopenEditProfile(false)
  }

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };


  const setMultipleDomain = (userdata) => {
    console.log('data => ', userdata);
    data.adminProfile = true;
    data.typeId = data.multipleDomain;
    data.email = userdata.userDetails.email_id;
    call('POST', 'registration', data).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Html body
  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="d-flex mt-3 mb-3">
        <div className="ml-auto">
          <ul className="range-slider">
            <li>
              <div className="d-md-flex">

                <div className=" ml-1 position-relative input-margin">
                  <input placeholder="Search" type="text" name="search" className="form-control" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                  <button type="button" onClick={() => {
                    setFilterData({
                      ...filterData,
                      "search": searchKey
                    })
                  }}><i class="fas fa-search fa-lg"></i></button>
                </div>
              </div>
            </li>
            <li>
            </li>
            <li>
            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
          </ul>
        </div>
      </div>


      <div className="d-flex mt-3 custom-tab-content">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row w-100">

          {/* {channelPartnerUser !== true && */}
          <div className="col-md-3 col-md-3 col-sm-6 col-xs-12">
            <div className="card mb-3 shadow-sm profile-view">
              {(aclMap.mng_subusers_profile) &&
                <ul className="add-profile">
                  <li onClick={() => addNewUser()}>
                    {/* <div className={"add-another " + ((userPlanQuota.partners && (userPlanQuota.partners.limitLeft / 1) === 0) ? "text-danger" : "text-success")}><i class="fas fa-user-plus"></i></div> */}
                    <div className={"add-another " + "text-success"}><i class="fas fa-user-plus"></i></div>

                    <p className="">Add a New User</p>
                    {userPlanQuota.partners &&
                      <span class="fa-stack" style={{ "vertical-align": "top" }}>
                        <i class="far fa-circle fa-stack-2x"></i>
                        <i class={(userPlanQuota.partners.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                          {(userPlanQuota.partners.limitLeft / 1) === -1 ? "" : userPlanQuota.partners.limitLeft}
                        </i>
                      </span>}
                  </li>
                </ul>}
            </div>
          </div>
          {/* } */}
          {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.get_users_profiles && aclMap.mng_subusers_profile.get_users_profiles.isExist) &&
            <>
              {getprofileData()}
            </>}
        </div>
      </div>

      {(showCreateProfileAdminModal.modal && (aclMap.mng_subusers_profile)) &&
        <CreateProfileAdminModal userTokenDetails={userTokenDetails} channelPartnerUser={channelPartnerUser} />}

      {/* {openEditProfile && showViewProfileModal.modal && <ViewProfileDemo
        userTokenDetails={userTokenDetails}
        refresh={refresh}
        setrefresh={setrefresh}
        compType={'admin'}
        type={'channel_partner'} />} */}

      <hr />
      {(items.length !== 0 && searchenable) &&
        <div className="float-right row">
          {entryCountField}
          <Pagination className='col-md-6'>{items}</Pagination>
          <br />
        </div>
      }

      {addMoreProfile.modal &&
        <div className={"modal show"} id="registration" >
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Add Channel Partner</h4>
                <button type="button" className="close" onClick={() => { setAddMoreProfile({ modal: false, data: {} }) }} >×</button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group col-md-12">
                          <select className={" form-control" + (!errors.multipleDomain ? '' : ' border-danger')} name="multipleDomain" value={data.multipleDomain} onChange={handleChange} >
                            <option value="" selected>Select Category</option>
                            {(dbTypeData.length) && dbTypeData.map((item, key) => {
                              if (key > 1) {
                                return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                              }
                            })
                            }
                          </select>
                          {errors.multipleDomain && <p className="text-danger error-contract">{errors.multipleDomain}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer primary">
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => setAddMoreProfile({ modal: false, data: {} })} >Cancel</button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => setMultipleDomain(addMoreProfile.data)} disabled={data.multipleDomain ? false : true} >Submit</button>
              </div>

            </div>
          </div>
        </div>}

    </div >
  )
};

const mapStateToProps = state => {
  return {
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    showViewProfileModal: state.showViewProfileModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProfile)