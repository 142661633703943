import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import AssignedCreditToBuyer from './assignedCreditToBuyer';
import config from '../../config.json';

const UserOfferModule = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [bankList, setBankList] = useState([]);
  const [accord, setaccord] = useState({
    0: false,
    1: false,
  });
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("");
  const [mailSent, setMailSent] = useState([]);

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect

  useEffect(() => {

    call('GET', 'getBanksListMaster').then((result) => {
      setBankList(result);
    });

    let objectAPI = {
      "userId": manualUserId,
      "username": manualUserName,
    }

    call('POST', 'getMaiSentToLender', objectAPI).then((result) => {
      console.log('running getMaiSentToLender api-->', result);
      setMailSent(result);
    }).catch((e) => {
      console.log('error in getMaiSentToLender', e);
      toastDisplay('Error', 'error');
    })

  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  const handleChange = (event) => {
    event.persist();
    setData(logdata => ({ ...logdata, [event.target.name]: event.target.value }));
    setError(errorData => ({ ...errorData, [event.target.name]: "" }))
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  };

  const handleSubmit = (event) => {

    let objectAPI = {
      "userId": manualUserId,
      "username": manualUserName,
      "data": data,
      "baseUrl": config.baseUrl
    }

    call('POST', 'sendMailToLender', objectAPI).then((result) => {
      console.log('running sendMailToLender api-->', result);
      toastDisplay(result, 'success');
    }).catch((e) => {
      console.log('error in sendMailToLender', e);
      toastDisplay('Error', 'error');
    })

  };

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="d-md-flex mt-3 mb-3 notify_list">
        <div className="ml-md-auto relative">
        </div>
      </div>
      <div className="creditline_listing">
        <div className="col-md-12 bg-light shadow">
          <div className="col-md-12 p-2">
            <div className="row">

              <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['0'] ? " closed" : " open")}>
                <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '0': !accord['0'] })}>Get Buyer limit from Lender</h2>
                <div className="accordionItemContent">
                  <div className="row pt-3">

                    {(mailSent.length > 0) ?
                      <div className="col-md-12">
                        <div className="row form-group">
                          <label className="col-md-12 text-primary">
                            E-Mail has been already send to {mailSent.join(',')}
                          </label>
                        </div>
                      </div>
                      :
                      <div className="col-md-12">
                        <div className="row form-group">
                          <label className="col-md-12 text-danger">
                            No E-Mail has been send yet.
                          </label>
                        </div>
                      </div>
                    }

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Select Lender {astrix}</label>
                        <div className="col-md-12">
                          <select className={"form-control" + (error.lender ? " border-danger" : "")} name="lender" id="select_lender" value={data.lender} onChange={handleChange} >
                            <option value="">--Select Lender--</option>
                            {bankList.map((bank) => {
                              return (
                                <option value={bank.id + ':' + bank.company_name + ':' + bank.email_id} >{bank.company_name}</option>
                              )
                            })}
                          </select>
                          <br />
                          {error.lender ? <div class="text-danger error-contract">{error.lender}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="buyersDetail" id="buyersDetail" onChange={hanleDocChecks} checked={data.buyersDetail} />
                        <label className="form-check-label" for="buyersDetail">
                          {`I want to share the ${manualUserName}'s buyer detail with the selected lender through email.`}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="financialDetail" id="financialDetail" onChange={hanleDocChecks} checked={data.financialDetail} />
                        <label className="form-check-label" for="financialDetail">
                          {`I want to share the ${manualUserName}'s financial detail with the selected lender through email.`}
                        </label>
                      </div>
                    </div>

                    <hr className="col-md-12" />

                    {data.lender && (data.buyersDetail || data.financialDetail) &&
                      <div className="form-group col-md-12">
                        <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={handleSubmit} >Send</button>
                      </div>}

                  </div>
                </div>
              </div>

            </div>
          </div>

          <hr />
        </div>
      </div>

      <div className="creditline_listing">
        <div className="col-md-12 bg-light shadow">
          <div className="col-md-12 p-2">
            <div className="row">
              <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['1'] ? " closed" : " open")}>
                <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '1': !accord['1'] })}>Buyer limit from Lender</h2>
                <div className="accordionItemContent">
                  <ul className="nav nav-tabs-custom-2 mb-3" id="myTab" role="tablist">
                    {bankList.map((bank, key) => {
                      return (
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === key ? " active show" : "")} onClick={() => {
                            setTab(key);
                            setTabName(`${bank.company_name}`);
                          }}>{bank.company_name}</a>
                        </li>
                      )
                    })
                    }
                  </ul>

                  {bankList.map((bank, key) => {
                    return (<>
                      {tab === key &&
                        <>
                          <AssignedCreditToBuyer userTokenDetails={userTokenDetails} bank={bank} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} />
                        </>
                      }
                    </>)
                  })}
                  <>
                    <hr />
                    <div className="text-center">
                      <button type="button" className="btn btn-info btn-sm mr-2" onClick={tab > 0 ? () => setTab(tab - 1) : ''} id="previd"><i class="fa fa-angle-left" style={{ width: "25px" }} aria-hidden="true"></i></button>
                      <button type="button" className="btn btn-info btn-sm ml-2" onClick={tab < 4 ? () => setTab(tab + 1) : ''} id="nextid"><i class="fa fa-angle-right" style={{ width: "25px" }} aria-hidden="true"></i></button>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOfferModule)