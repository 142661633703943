import moment from "moment";
import React, { useEffect, useState } from "react";
import { getNotificationIcon, redirectNotification } from "../../../utils/myFunctions";

const NotificationCard = ({ notificationcardData, Heading, data, getNotificationdata }) => {
  const [isViewAll, setViewAll] = useState(false)
  useEffect(() => {
    console.log('resultperpage', isViewAll ? null : 3, isViewAll)
    getNotificationdata(isViewAll ? null : 3)
  }, [isViewAll])
  return (
    <>
      <p className="font-size-16 font-wt-500 letter-spacing05 color0C0C0C lh-22">{Heading}</p>
      {data.result && data.result.length > 0 &&
        <div className="card notification-card borderRadius border-0">
          {data.result && data.result.map((key, index) => {
            return (
              <div className={`d-flex p-3 justify-content-between borderEEEEEE border-0 border-bottom align-items-center  ${key.Firstchild ? "firstLi" : "bg-white "}`}>
                <div className='col-md-8 d-flex align-items-center'>
                  {/* <div className="w-8"><img src={getNotificationIcon(key.notification_type, key.notification_sub_type)} height={30} alt="" /></div> */}
                  <div className="w-90">
                    <p className='mb-1 heading'>{key.notification_description}</p>
                    <p className='mb-0 font-size-12 letter-spacing05 text-secondary lh-16 '>{`${moment(key.createdAt).format('DD/MM/YYYY')} At ${moment(key.createdAt).format('hh:mm A')}`}</p>
                  </div>
                </div>
                <div className='col-md-4 text-end'>
                  <p className="mb-0 text-color1 font-wt-500 font-size-16 letter-spacing05 cursor" onClick={() => redirectNotification(key.notification_type, key.notification_sub_type, key.refId)}>{`View ${key.notification_type === 'Other' ? key.notification_sub_type : key.notification_type}`}</p>
                </div>
              </div>
            );
          })}
          {data.total_count > data.result.length &&
            <div className='cursor mx-0 text-center my-3 font-size-16 font-wt-500 letter-spacing05'>
              <p className='text-decoration-none text-color1 mt-3 text-center letter-spacing05' onClick={() => setViewAll(!isViewAll)} >{!isViewAll ? 'View All' : 'View Less'}</p>
            </div>
          }

        </div>}
    </>
  );
}
export default NotificationCard;