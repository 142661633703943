import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

export const PaginationComponent = ({ value, name, onChange, isDisabled, type, maxValue, }) => {
  const [error, setErrors] = useState({ isError: false, msg: '' })
  useEffect(() => {
    if (value > maxValue) {
      setErrors({
        isError: true,
        msg: 'Page count cannot be greater than maximum page size.'
      })
    } else {
      setErrors({
        isError: false,
        msg: ''
      })
    }
  }, [value, maxValue])
  return (
    <div className='col'>
      <div className='d-flex align-items-center '>
        <label className='px-2 mt-2 font-size-12 font-wt-400'>Show</label>
        <div className='w-8 h-2'>
          <input value={value} name={name} onChange={onChange} disabled={isDisabled} type={type} className={"form-control w-10 bg-white"} />
        </div>
        <label className='px-2 mt-2 font-size-12 font-wt-400'>{`of ${maxValue}`}</label>
      </div>
      {error.isError &&
        <label className='text-danger font-size-10'>{error.msg}</label>
      }
    </div>
  )
}

