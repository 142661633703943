import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
// import { Table } from 'react-bootstrap'
import AclLogin from '../utilComponents/aclLogin';
import { formatDate_Application, } from '../../utils/dateFormaters';
import SignPad from './signPad';

const DigitalSignDoc = ({ userTokenDetails, fileData, showAclAuthModal, setAclAuthModal, refreshDoc, setrefreshDoc, mktPlaceShipment, viewOnly, buyerUserId }) => {

  const [data, setData] = useState({});
  const [tab, setTab] = useState(0);
  const [login, setLogin] = useState({
    state: false,
    validated: viewOnly ? true : false
  });
  const [accord, setAccord] = useState({
    0: false
  });
  const [bcInfoForDoc, setbcInfoForDoc] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [addFileData, setaddFileData] = useState('');
  const [authorityId, setAuthorityId] = useState({});
  const [userIp, setUserIp] = useState(null)

  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null


  const signStyle = {
    "font-family": "Times New Roman",
    "font-style": "italic",
    "font-size": "28px"
  }

  //---------------------------------------------------------------------------------------------------------------------
  // use effects


  // useEffect(() => {
  //   call('POST', 'getAuthorityId', { 'tbldocid': fileData.tbl_doc_id, 'userId': userId }).then((result) => {
  //     console.log("getAuthorityId Result-->", result)
  //     setAuthorityId(result)
  //   }).catch(err => {
  //     console.log("getAuthorityId error:->", err)
  //   })
  // }, [])

  async function asyncUseEffectFunction() {
    setData({ ...data, userId: viewOnly ? buyerUserId : userId, tblDocId: fileData.tbl_doc_id, email: viewOnly ? null : userEmail })

    fetch(
      "https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then((json) => {
        // console.log("userippp", json);
        if (json.IPv4) {
          setUserIp(json.IPv4)
        }
      })
    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getdigitalsigntrail', { 'tbldocid': fileData.tbl_doc_id, 'type': fileData.type ? fileData.type : null, userId: userId }).then((result) => {
      console.log("getdigitalsigntrail Result-->", result)
      setbcInfoForDoc(result)
    }).catch(err => {
      console.log("getdigitalsigntrail error:->", err)
      // toastDisplay(err, "error");
    })
    //------------------------------------------------------------------

  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [refresh]);

  // Handlers

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  // console.log('signPadBase64 => ', data.sign ? data.sign.getTrimmedCanvas().toDataURL('image/png') : '');

  const handleFile = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (e) => {
      setaddFileData(e.target.result);
    }
  }

  function submitStamp(type) {

    setshowLoader(true)
    console.log("data in sign submit==>", data)

    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append('signType', fileData.signType ? fileData.signType : null);
    formData.append('document_name', fileData.document_name ? fileData.document_name : null);
    // formData.append('reqId', authorityId ? authorityId.id : null);
    formData.append('signPadBase64', addFileData);
    if (!viewOnly) {
      formData.append('userName', userName);
      formData.append('userTypeId', userTypeId);
    }
    else {
      formData.append('userName', "Buyer");
      formData.append('userTypeId', "3");
    }
    formData.append('tbldocid', fileData.tbl_doc_id);

    formData.append('userIp', userIp)

    if (fileData.financeId) {
      formData.append('financeId', fileData.financeId)
    }

    if (mktPlaceShipment) {
      formData.append("mktPlaceShipment", true)
    }

    call('POST', 'signimage', formData).then((result) => {
      console.log("=== signimage === ", result)
      setshowLoader(false)
      setrefresh(refresh + 1)
      setAclAuthModal(false)
      toastDisplay("Digital Signature Added successfuly", "success");
      setrefreshDoc(refreshDoc + 1)
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err, "error");
    })
  }

  return (
    <>

      {showAclAuthModal && <div className={"modal" + (showAclAuthModal ? " show" : "")} id="docAcl">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Document Authorize</h4>
              <button type="button" className="close" onClick={() => { setAclAuthModal(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

              <div className=" col-md-12 mt-0 pt-0" >
                <div className="col-md-12 mt-0 pt-0 row">
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.document_name) ? fileData.document_name : 'NA'}</h3>
                        <p>Type of Document</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.doc_name) ? fileData.doc_name : 'NA'}</h3>
                        <p>Document Name</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.uploaded_by) ? fileData.uploaded_by : 'NA'}</h3>
                        <p>Uploaded By</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.uploaded_on) ? formatDate_Application(fileData.uploaded_on) : 'NA'}</h3>
                        <p>Uploaded On</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                  <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}> Certification of Completion in Blockchain</h2>
                  <div className="accordionItemContent">
                    This is to certify that I have accepted the document as genuine and will work with all parties involved for the successfull completion fo the trade contract.
                    I agree with the trade parties to adhere to the agreed terms in the contract and accept mutual concensus as key for successfull completion.
                  </div>
                </div>


                {login.validated &&
                  <div className={"accordionItem mt-3" + (accord['1'] ? " closed" : " open")}>
                    <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}> Electronic Signature Process</h2>
                    <div className="accordionItemContent">
                      <div className="row">

                        <ul className="nav nav-tabs-custom btn m-0 p-0" id="myTab" role="tablist">
                          <li>
                            <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>Type Sign</a>
                          </li>

                          <li>
                            <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>E-Sign</a>
                          </li>

                          <li>
                            <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>Upload Sign</a>
                          </li>
                        </ul>

                        <div className="d-flex m-0 p-0" style={{ "height": "90%" }}>
                          {tab === 0 &&
                            <div className="col-md-12">
                              <br />
                              <br />
                              <h2>Type Sign</h2>
                              <div className="col-md-12 row form-group float-right d-flex">
                                <div className="col-md-5">
                                  <label >Place</label>
                                  <textarea rows="2" className="form-control" placeholder="Enter Comments" name="comment" onChange={handleChange} required></textarea>
                                </div>
                                <div className="col-md-5">
                                  <label >Digital Signature</label>
                                  <textarea rows="1" className="form-control" style={signStyle} placeholder="Enter Full Name" name="sign" onChange={handleChange} required></textarea>
                                </div>
                                <div className="col-md-2">
                                  <button
                                    disabled={!(data.comment && data.sign)}
                                    type="button" className="btn btn-success mt-5 text-center btn-sm" onClick={() => submitStamp()} >Submit</button>
                                </div>
                              </div>
                            </div>
                          }
                          {tab === 1 &&
                            <div className="col-md-12">
                              <br />
                              <br />
                              <h2>E-Sign Pad</h2>
                              <SignPad reqId={authorityId.id} tbldocid={fileData.tbl_doc_id} userIp={userIp} fileData={fileData}
                                userTypeId={viewOnly ? "3" : userTypeId} signType={fileData.signType} document_name={fileData.document_name} userId={viewOnly ? buyerUserId : userId}
                                email={viewOnly ? null : userEmail} userName={viewOnly ? "Buyer" : userName}
                                refreshDoc={refreshDoc} setrefreshDoc={setrefreshDoc} mktPlaceShipment={mktPlaceShipment} />
                            </div>
                          }
                          {tab === 2 &&
                            <div className="form-group pb-0 mb-0">
                              <br />
                              <br />
                              <h2>Upload E-Sign Image</h2>
                              <ul className="other-documents pb-0 mb-0">
                                <li style={{ "min-width": "300px" }}>
                                  <label className="mb-0" >
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                    E-Sign Image Upload
                                    <span className="required-field text-danger">*</span>
                                  </label>
                                </li>
                                <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                  <div className="file-browse">
                                    <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                    <input type="file" accept=".png" id="file_1" name={"e_sign"} onChange={handleFile} />
                                  </div>
                                </li>
                                <li>
                                  {
                                    addFileData &&
                                    <iframe title="Document Preview" frameborder="0" src={addFileData} >
                                    </iframe>
                                  }
                                </li>
                              </ul>
                              <button type="button" className="btn btn-success mt-5 text-center btn-sm" onClick={() => submitStamp()} >Submit</button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }



              </div>
              {login.state && <AclLogin setLogin={setLogin} />}
            </div>
            <div className="modal-footer primary">
              <div className="col-md-12">
                {authorityId && authorityId.doc_signed == "1" ? <h1 className="text-success text-center">You have already signed the document.</h1> :
                  (!login.validated) ? <button type="button" className="btn btn-success btn-sm float-right" onClick={() => setLogin({ ...login, state: true })} >Login to Authorize</button> : ''
                }
                {/* {!login.validated && <button type="button" className="btn btn-success btn-sm float-right" onClick={() => setLogin({ ...login, state: true })} >Login to Authorize</button>} */}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>)
}



export default DigitalSignDoc
