import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { GetCache, isOutStandingDatePassed, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import MultipleSelect from '../../../utils/MultipleSelect';
import toastDisplay from '../../../utils/toastNotification';
import SubAdminPopup from '../SubAdminPopup';

const overallCounts = [
  { name: "Disbursed", alt: 'totalDisbursed', class: ' text-F1C40F ', src: '/assets/images/disbursed_icon.png' },
  { name: "Over due", alt: 'totalOverDue', class: ' colorFF7B6D ', src: '/assets/images/OverDue.png' }
]

const twelveWeeksSummary = [
  { name: "Disbursed", alt: 'totalDisbursed12Week', class: ' text-F1C40F ' },
  { name: "Due", alt: 'totalDue12Week', class: ' colorFF7B6D ' },
  { name: "Over Due", alt: 'totalOverDue12Week', class: ' colorFF7B6D ' }
]

const CPInvoiceApprovedFinance = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [filterCount, setFilterCount] = useState({})


  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState(Object.keys(GetCache("invoiceApprovedFinanceFilter")).length ? GetCache("invoiceApprovedFinanceFilter") : { resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState(GetCache("invoiceApprovedFinanceFilterData"))
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [filteredSearch, setFilteredSearch] = useState(GetCache("invoiceApprovedFinanceSearchFilterData"))
  const [salesPerson, setSalesPerson] = useState([])
  const [data, setdata] = useState({})
  const [subadminPopup, togglesubadminPopup] = useState({ data: [], show: false, userId: '' })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  useEffect(() => {
    SetCache("invoiceApprovedFinanceSearchFilterData", filteredSearch)
  }, [filteredSearch])
  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  const getAdminInvoiceApprovedFinance = () => {
    SetCache("invoiceApprovedFinanceFilterData", filterData)
    SetCache("invoiceApprovedFinanceFilter", filter)
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      atApprovedStage: true,
      ...filter
    }
    // objectAPI["onlyShowForUserId"] = onlyShowForUserId

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (data.subadmins && data.subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = data.subadmins
    }
    call('POST', 'getInvoiceDiscountingListForCP', objectAPI).then((result) => {
      console.log('running getInvoiceDiscountingListForCP api-->', result);
      setDbData(result.listData);
      setTableData(formatDataForTable(result.listData))
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getInvoiceDiscountingListForCP', e);
      setshowLoader(false)
    });
  }
  useEffect(() => {
    getAdminInvoiceApprovedFinance()
  }, [refresh, page, data.subadmins, salesPerson])
  const getAdminDisbursmentStats = () => {
    let objectAPI = {
      userId,
      atApprovedStage: true
    }
    // objectAPI["onlyShowForUserId"] = onlyShowForUserId
    if (data.subadmins && data.subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = data.subadmins
    }
    call('POST', 'getDisbursementStatsSummaryCP', objectAPI).then(res => {
      console.log("getDisbursementStatsSummaryCP then", res);
      setFilterCount(res)
    }).catch(err => { })
  }
  useEffect(() => {
    getAdminDisbursmentStats()
  }, [data.subadmins])
  useEffect(() => {
    let isCacheExist = localStorage.getItem('invoiceApprovedFinanceFilterData') != "{}"
    let isSearchCacheExist = localStorage.getItem('invoiceApprovedFinanceSearchFilterData') != "{}"
    call('POST', 'getInvoiceDiscountingFiltersForAdmin', {
      userId,
      atApprovedStage: true
    }).then(res => {
      console.log("getInvoiceDiscountingFiltersForAdmin then", res);
      if (!isCacheExist) {
        setFilterData(res)
      }
      if (!isSearchCacheExist) {
        setFilteredSearch(res)
      }
    }).catch(err => { })

  }, [])

  async function handleTransactionPopup(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.id, applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      let allDeniedCount = 0
      let allApprovedCount = 0

      if (item.buyers_credit) {
        for (let index = 0; index < JSON.parse(item.buyers_credit).length; index++) {
          const j = JSON.parse(item.buyers_credit)[index];
          if (j.financierAction === "deny") {
            allDeniedCount += 1
          }
          else {
            allApprovedCount += 1
          }
        }
      }

      row[0] = item.reference_no
      row[1] = <label className='font-size-13 font-wt-400'
      > {moment(item.invoiceApplicationDate).format("DD/MM/YYYY")}</label>
      row[2] = <label className='cursor font-size-13 font-wt-400'
        onClick={() => {
          window.location = `/supplierDetails?id=${item.user_id}`
        }}
      > {item.supplierName}</label>
      row[3] = <label className='cursor font-size-13 font-wt-400'
        onClick={() => {
          window.location = `/buyerManagement?viewBuyerId=${item.id}&viewUserTypeId=19&viewUserId=${item.user_id}`
        }}
      > {item.buyerName}</label>
      // row[4] = <div class="dropdown w-100" >
      //   <label class="font-wt-600 font-size-13 cursor" onClick={() => {
      //     togglesubadminPopup({
      //       show: true,
      //       data: [],
      //       userId: item.user_id
      //     })
      //   }}>
      //     {item.leadAssignToName || '-'}
      //   </label>
      // </div>
      row[4] = item.lenderName

      let disbScheduledOn = item.disbScheduledOn?.split(",") || []
      let disbCurrency = item.disbCurrency?.split(",") || []
      let disbAmount = item.disbAmount?.split(",") || []
      let disbActualAmount = item.disbActualAmount?.split(",") || []
      let disbActualDate = item.disbActualDate?.split(",") || []
      let disbAttachment = item.disbAttachment?.split(",") || []
      let disbStatus = item.disbStatus?.split(",") || []

      row[5] = <div
        className=''
      >
        {disbScheduledOn.length ? disbScheduledOn.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className='font-size-13 text-color-label font-wt-400' >{`${moment(key).format("DD/MM/YYYY")}`}</label>
            </div>
          )
        }) : "NA"}
      </div>
      row[6] = <div
        className=''
      >
        {disbAmount.length ? disbAmount.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className='font-size-13 text-color-label font-wt-400' >{`${key} ${disbCurrency[index]}`}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[7] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className={`font-size-13 
              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-400`} >
                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? `${disbAmount[index]} ${disbCurrency[index]}` : '-'}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[8] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className={`font-size-13 
              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-400`} >
                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? (moment().diff(moment(disbScheduledOn[index]), "days") + " Days") : '-'}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[9] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative mb-2' >
              {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ?
                <img src={"/assets/images/overdue_icon.png"} alt="" height={24} width={25} /> : disbStatus / 1 == 0 ?
                  <img src={"/assets/images/processing.png"} alt="" height={24} width={25} /> :
                  <img src={"/assets/images/upload_done.png"} alt="" height={24} width={25} />}
            </div>
          )
        }) : "NA"}
      </div>

      tableData.push(row)
      row = []
    }
    return tableData
  }

  function openApplication(indx, tabIndx) {
    let item = dbData[indx]
    localStorage.setItem("item", JSON.stringify(item))
    localStorage.setItem("isAdminUser", true)
    localStorage.setItem("defaultSetTab", tabIndx)

    // setting manual user id & email for user
    localStorage.setItem("manualUserId", item.user_id)
    localStorage.setItem("manualUserEmail", item.supplierEmailId)
    localStorage.setItem("headerBreadCum", "Invoice Discounting > Approved Finance > Application Details")
    window.location = `/sign-agreement`
  }

  async function downloadCSVFile() {
    try {
      let csvString = "Application No,Supplier,Buyer,Financer,Disbursement Date,Disbursement Amount,Outstanding Amount,Outstanding Days,Status,Credit Days,Invoice Amount(USD)\r\n"

      for (let i = 0; i < dbData.length; i++) {
        let element = dbData[i]

        let disbScheduledOn = element.disbScheduledOn?.split(",") || []
        let disbCurrency = element.disbCurrency?.split(",") || []
        let disbAmount = element.disbAmount?.split(",") || []
        let disbStatus = element.disbStatus?.split(",") || []

        let disbDateString = ''
        let disbAmountString = ''
        let outstandingAmountString = ''
        let outstandingDaysString = ''
        let statusString = ''

        for (let index = 0; index < disbScheduledOn.length; index++) {
          const disbElement = disbScheduledOn[index];
          disbDateString += `${disbElement}||`
          disbAmountString += `${disbAmount[index]} ${disbCurrency[index]}||`
          if (isOutStandingDatePassed(disbElement, disbStatus[index])) {
            outstandingAmountString += `${disbAmount[index]} ${disbCurrency[index]}||`
            outstandingDaysString += `${moment().diff(moment(disbScheduledOn[index]), "days") + " Days"}||`
            statusString += `Overdue||`
          }
          else {
            outstandingAmountString += `NA||`
            outstandingDaysString += `NA||`
            if (disbStatus[index] / 1 == 1) {
              statusString += `Disbursed||`
            }
            if (disbStatus[index] / 1 == 0) {
              statusString += `Pending||`
            }
          }
        }

        let rowString = `${element.reference_no},
          ${element.supplierName || 'NA'},
          ${element.buyerName || 'NA'},
          ${element.lenderName || 'NA'},
          ${disbDateString || 'NA'},
          ${disbAmountString || 'NA'},
          ${outstandingAmountString || 'NA'},
          ${outstandingDaysString || 'NA'},
          ${statusString || 'NA'},
          ${element.credit_days || 'NA'},
          ${element.contractAmountInUSD || 'NA'}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ApprovedFinanceList.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.log("error in downloadCSVFile", error);
    }
  }

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setdata({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setdata({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getAdminInvoiceApprovedFinance()
      getAdminDisbursmentStats()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <SubAdminPopup togglesubadminpopup={togglesubadminPopup} subadminpopup={subadminPopup} setShowLoader={setshowLoader} refreshtable={getAdminInvoiceApprovedFinance} />

      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="CPinvoiceApprovedFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discounting > Approved Finance"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              {/* <div className='d-flex justify-content-end p-0 '>
                <div className="col-md-3">
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      Id="Select User"
                      Label="Select User"
                      selectedvalue="Select Requirement"
                      optiondata={salesPerson}
                      onChange={(e) => handleMultiSelectchange(e, "subadmins", "id")}
                      value={data.subadmins ? data.subadmins : []}
                      name="subadmins"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                      isCheckableList={true}
                    />
                  </div>
                </div>
              </div> */}
              <div className='row mb-3'>
                <div className='row w-50'>
                  {overallCounts.map((i, j) => {
                    return (
                      <div className='w-50'>
                        <div className='card dashboard-card shadow-sm align-items-center'>
                          <label className={`value font-wt-600 ${i.class}`}>
                            {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(filterCount[i.alt])}` || "NA"}</label>
                          <label className={'font-size-14 font-wt-600 text-color-value'}>
                            <img src={i.src} width={30} height={32} className="mr-1" />{i.name}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='row w-50 card dashboard-card shadow-sm ml-2 mb-0 pb-0 pt-3 ml-2'>
                  <label className={'font-size-14 font-wt-600 text-color-label pb-1'}>{`12 Weeks Payment Summary`}</label>
                  <div className='row'>
                    {twelveWeeksSummary.map((i, j) => {
                      return (
                        <div className=' align-items-center w-33'>
                          <div>
                            <label className={`value font-wt-600 ${i.class}`}>
                              {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(filterCount[i.alt])}` || "NA"}</label>
                          </div><div>
                            <label className={'font-size-13 font-wt-600 text-color-value'}>{i.name}</label>
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </div>
              </div>

              <div className='filter-div'>
                <Filter showApplyBtn={true} showDownloadIcon={true}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  onDownloadClick={downloadCSVFile}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div>

              <div
                style={{}}>
                <NewTable disableAction={false}
                  columns={[{
                    name: "Invoice No", filter: false
                  }, {
                    name: "Application Date", filter: false
                  },
                  {
                    name: "Supplier", filter: true,
                    filterDataKey: "Exporter Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortExpName === "ASC", onActionClick: () => { setFilter({ ...filter, sortExpName: 'ASC', sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortExpName === "DESC", onActionClick: () => { setFilter({ ...filter, sortExpName: "DESC", sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Buyer", filter: true,
                    filterDataKey: "Buyer Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  // {
                  //   name: "Admin", filter: true,
                  //   filterDataKey: "Admins"
                  // },
                  { name: "Financer", filter: true, filterDataKey: 'Financier Selected' },
                  { name: "Disbursement Date" },
                  { name: "Disbursement Amount" },
                  {
                    name: "Outstanding Amount"
                  },
                  {
                    name: "Outstanding Days"
                  },
                  { name: "Status" }, { name: ' ' }
                  ]}
                  data={tableData}
                  options={[
                    { name: "Application", onClick: (indx) => openApplication(indx, 0) },
                    { name: "Documents", onClick: (indx) => openApplication(indx, 1) },
                    { name: "Quote", onClick: (indx) => openApplication(indx, 2) },
                    { name: "Disbursement", onClick: (indx) => openApplication(indx, 3) },
                    // { name: "Chat with Supplier", onClick: () => null },
                    // { name: "Chat with Buyer", onClick: () => null }
                  ]}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                />
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div>
      </div>
    </>)
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(CPInvoiceApprovedFinance)