import React, { useEffect, useState } from 'react';
import { setproductDetails } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import Footer from '../partial/footer';
import toastDisplay from '../../utils/toastNotification';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import ProductDetails from './productDetails';
import EditableGrid from '../CommonComponent/EditableGrid';
import InterestRatePopup from './interestRatePopup';

let columnNames = [
  {
    name: "Country", selector: "country", sortable: true, condition: "1", checkForMandatory: true,
    ddField: true, ddData: "ddDataCountrys", ddKey: "name",
    editExistData: true
  },
  {
    name: "Payment Terms (in days)", selector: "payment_terms_in_days", sortable: true, condition: "1", checkForMandatory: true,
    validation: "positive_no", editExistData: true
  },
  {
    name: "Interest Rate (in %)", selector: "interest_rate", sortable: true, condition: "1", checkForMandatory: true,
    editExistData: true
  },
  {
    name: "Factoring Fees Type", selector: "factoring_fees_type", sortable: true, condition: "1", checkForMandatory: true,
    ddField: true, ddData: "factoringFeesType", ddKey: "name",
    editExistData: true
  },
  {
    name: "Factoring Fees (in %/$)", selector: "factoring_fees_in_usd", sortable: true, condition: "1", checkForMandatory: true,
    editExistData: true
  },
  {
    name: "One Time Setup Fees (in $)", selector: "one_time_setup_fees_in_usd", sortable: true, condition: "1", checkForMandatory: true,
    editExistData: true
  },
  {
    name: "Action", selector: "action", sortable: true, condition: "1", showDeleteAction: true
  }

]

const factoringFeesType = [
  { name: "Fixed" },
  { name: "Variable" }
]

const InterestRates = ({ userTokenDetails }) => {

  const [showLoader, setShowLoader] = useState(false)
  const [dbData, setDbData] = useState([])
  const [countries, setCountries] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [showPopup, togglePopup] = useState(false)

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  useEffect(() => {
    getCountries()
    setShowLoader(true)
    call('POST', 'financeCalculator/getInterestRates', { userId, type: 'ID' }).then(result => {
      setDbData(result)
    })
    setShowLoader(false)
  }, [refresh])

  async function getCountries() {
    setShowLoader(true)
    let result = await call('get', 'getallCountry')
    setShowLoader(false)
    setCountries(result)
  }

  const onDataSubmit = async (row) => {
    row["country_code"] = countries.filter((item) => {
      if (item.name === row.country) {
        return item
      }
    })[0]["sortname"]
    row["type"] = "ID"
    row["userId"] = userId
    setShowLoader(true)
    let result = await call('POST', `financeCalculator/${row.is_new_row ? "addInterestRate" : "editInterestRate"}`, row)
    console.log("row==>", row, result);
    toastDisplay(`Data ${row.is_new_row ? "added" : "updated"} successfully`, "success")
    setShowLoader(false)
    setRefresh(refresh + 1)
  }

  const onDeleteAction = async (rowId) => {
    if (dbData[rowId]) {
      setShowLoader(true)
      await call('POST', `financeCalculator/deleteInterestRate`, { ...dbData[rowId], type: "ID" })
      setShowLoader(false)
      setRefresh(refresh + 1)
      toastDisplay(`Data removed successfully`, "success")
    }
  }

  return (
    <>
      <div className="container-fluid">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row">
          <Sidebar state={'interestRates'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 expanded-right"} id="app-main-div">
            <Header
              title={'Interest Rates'}
              userTokenDetails={userTokenDetails}
              onButtonClick={() => togglePopup(true)} />

            {showPopup ? (
              <InterestRatePopup togglePopup={togglePopup} userTokenDetails={userTokenDetails} />
            ) : null}

            <div className="mt-3 pt-3">
              <h3 className="my-2 pb-3">Add Interest Rates</h3>
              <EditableGrid headings={columnNames} conditionForColumn={"1"}
                data={dbData}
                onDataSubmit={(row) => onDataSubmit(row)}
                onDeleteAction={rowId => onDeleteAction(rowId)}
                ddDataCountrys={countries}
                factoringFeesType={factoringFeesType}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default InterestRates;