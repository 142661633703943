import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import { getDocDetails } from '../../utils/myFunctions';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import NewTablev2 from '../../utils/newTablev2';
import { bgFinQuotesColumn, tciFinQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';



export const TCIFinancierQuote = ({ userTokenDetails, navToggleState }) => {

  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [showLoader, setshowLoader] = useState(false)
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "currentPage": page,
      "resultPerPage": 10,
      ...filter
    }
    call('POST', 'getTCIQuoteListFinancier', objectAPI).then((result) => {
      setTableData(result.limitData)
      setCount(result.countData)
    }).catch((e) => {
      throw e;
    });
  }, [refresh]);



  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('insuranceApplicationId', selectedChat.insuranceApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="other_services_credit_insurance_quote" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Insurance > Quote"}
              userTokenDetails={userTokenDetails} />

            <div className='filter-div position-relative'>
              <Filter
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>

            <div className='mt-4'>
              <NewTablev2 columns={tciFinQuotesColumn}>
                {tableData.map((item, j) => {
                  let financierQuotes = item.financierQuotes ? JSON.parse(item.financierQuotes) : []
                  let chatRoomIds = item.chatRoomIds?.split(",") || []
                  let chatRoomUsers = item.chatRoomUsers?.split(",") || []

                  let openChatRoomIndx = null
                  chatRoomUsers.forEach((u, i) => {
                    if (u?.split("::")[1] / 1 == userId / 1) {
                      openChatRoomIndx = i
                    }
                  })
                  let mySentQuote = financierQuotes.filter(i => {
                    if (i.lender_id / 1 == userId / 1) {
                      return i
                    }
                  })[0]
                  return (
                    <tr>
                      <td ><label className='font-size-13 font-wt-400 text-break' >{moment(item.updatedAt).format("DD/MM/YYYY")}</label> </td>
                      <td ><label className='font-size-13 font-wt-400 text-break'>{item.supplierName || "-"}</label> </td>
                      <td ><label className='font-size-13 font-wt-400 text-break'>{item.type || "-"}</label> </td>
                      <td ><label className='font-size-13 font-wt-400 text-break'>{item.details.expBankName || "-"}</label> </td>
                      <td>
                        <div
                          onClick={async () => {
                            let reqObj = {
                              userTypeId,
                              senderId: userId,
                              sellerId: item.createdBy,
                              receiverId: userId,
                              textMessage: 'Hii',
                              chat_room_name: "CHAT" + new Date().getTime(),
                              includeAdmins: true,
                              insuranceApplicationId: item.limit_id,
                              receiverParties: userId,
                              dontSendInitialMsg: true
                            }
                            setshowLoader(true)
                            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                            setshowLoader(false)
                            getChatDetails({
                              chat_room_id: apiResp.id,
                              loggedInUser: userId
                            })
                            setSelectedChat({
                              chatRoomId: apiResp.id,
                              receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                              insuranceApplicationId: item.limit_id,
                              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                              userId: userId,
                              isChatOpen: true,
                              receiverId: userId,
                              sellerId: item.createdBy
                            })
                          }}
                        >
                          {openChatRoomIndx != null ? (
                            <div className='d-flex' >
                              <p className={`font-size-12 font-wt-500 p-0 m-0 ${item.chatRoomUnreadMsgCount > 0 ? ' w-80' : ' w-100 '}`} >
                                {item.lastMsgTime ?
                                  <label className='font-wt-700 w-100 p-0 m-0' >{moment(item.lastMsgTime).format("DD/MM/YYYY | HH:mm")}</label> : null}
                                <p className='ellipsis-container' >
                                  <p className='mb-0 ellipsis-text' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastMsg }}></p>
                                </p>
                              </p>
                              {item.chatRoomUnreadMsgCount > 0 ?
                                <label
                                  className='lastMsgCount'
                                >{item.chatRoomUnreadMsgCount}</label> : null}
                            </div>) : "-"}
                        </div>
                      </td>
                      <td>
                        {!mySentQuote ? <div className='row ' >
                          <a>
                            <button type="button"
                              onClick={() => {
                                window.location = `/tciFinancierSendQuote`;
                                localStorage.setItem("bgQuoteDetails", JSON.stringify(item));
                              }}
                              className={`border-0 mb-2 text-white enableQuotebtn2`}>
                              {"View Application"}
                            </button>
                          </a>
                        </div > : item.selectedFinancier / 1 === userId / 1 && !item.termSheet ?
                          <div className='row ' >
                            <a>
                              <button type="button"
                                // onClick={() => {
                                //   window.location = `/bgFinancierSendTermSheet`;
                                //   localStorage.setItem("bgQuoteDetails", JSON.stringify(item));
                                // }}
                                className={`border-0 mb-2 text-white enableQuotebtn2`}>
                                {"Quote Selected"}
                              </button>
                              <p className="font-size-12 text-color-value ml-3">
                                <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Quote selected by supplier</span>
                              </p>
                            </a>
                          </div > :
                          item.termSheet && !item.termSheetSignedByExporter ?
                            <div className='row '
                            >
                              <a>
                                <button type="button"
                                  className={`viewfinancersbtnnew text-color1`}>
                                  {"Termsheet Sent"}
                                </button>
                                <p className="font-size-12 text-color-value ml-3">
                                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600">Waiting for financier to sign termsheet</span>
                                </p>
                              </a>
                            </div>
                            : mySentQuote.financierAction === 'deny' ? <div className='row ' >
                              <a>
                                <button type="button"
                                  className={`border-0 mb-2 text-white rejectedQuotebtn`}>
                                  {"Rejected"}
                                </button>
                                <p className="font-size-12 text-color-value ml-3">
                                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 text-danger">This application was rejected by you.</span>
                                  <span
                                    onClick={() => {
                                      window.location = `/tciFinancierSendQuote`;
                                      localStorage.setItem("bgQuoteDetails", JSON.stringify(item));
                                    }}
                                    className=" font-wt-600 fc-5CB8D3 cursor"> View Application</span>
                                </p>
                              </a>
                            </div > : <div className='row ' >
                              <a>
                                <button type="button"
                                  onClick={() => {
                                    window.location = `/tciFinancierSendQuote`;
                                    localStorage.setItem("bgQuoteDetails", JSON.stringify(item));
                                  }}
                                  className={`border-0 mb-2 text-white approvedQuotebtn`}>
                                  {"Approved"}
                                </button>
                              </a>
                            </div >}
                      </td>
                      <td
                        // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                        className='position-relative'>
                        <i className="fa fa-ellipsis-v cursor mt-2 "
                          onClick={() => setAction({ show: true, index: j })}
                          aria-hidden="true"></i>
                        {action.show && action.index === j ? (
                          <Action
                            id={j}
                            onDismiss={() => setAction({ show: false, index: j })}
                            options={[
                              {
                                name: "Application", icon: "edit.png", onClick: () => {
                                  window.location = `/tciFinancierSendQuote`;
                                  localStorage.setItem("bgQuoteDetails", JSON.stringify(item));
                                }
                              }
                            ]} />
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
              </NewTablev2>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(TCIFinancierQuote)