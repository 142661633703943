// src/components/Navbar.js

import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  setClientType,
  setNavToggleState,
  setNavbarConfiguration,
  setSidebarOpen,
} from "../../store/actions/action";
import { connect } from "react-redux";
import LiveNotif from "../notifications/liveNotifications";
import { setTraderType, getTraderType } from "../../utils/cookieHelper";
import {
  setNavToggleStateLS,
  getNavToggleState,
} from "../../utils/cookieHelper";
import call from "../../service";
import { TabComponent } from "../CommonComponent/TabComponent";
import { Link } from "react-router-dom";
import {
  checkPermission,
  FilterPermissionAccess,
} from "../../utils/myFunctions";
import toastDisplay from "../../utils/toastNotification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let validLCTutorialSteps = ["0", "1", "3", "4", "5", "6"];

const SidebarV2 = ({
  userTokenDetails,
  clientType,
  setClientType,
  state,
  navToggleState,
  setNavToggleState,
  dispatch,
  notifications,
  items,
  setNavbarConfigurations,
  isEditable,
  setisEditable,
  isMobileSidebarOpen,
  setsidebarOpen,
}) => {
  console.log(userTokenDetails, "tokennnnnnnnnnnn");
  console.log("this is navbar configuratiom", setNavbarConfigurations);
  const [lcTutorial, toggleLCTutorial] = useState(
    validLCTutorialSteps.includes(localStorage.getItem("lcTutorialStep"))
  );
  const [overlay, setOverlay] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lcTutorialStep, setLCTutorialStep] = useState(
    localStorage.getItem("lcTutorialStep")
  );
  const [Roleterms, setroleTerms] = useState([]);
  const [navbarconfig, setnavbarconfig] = useState(items);

  const [optionsToHide, setOptionsToHide] = useState([]);
  console.log(items, "navbarconfig------------------------------------?");
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {};
  const userPermissions = userTokenDetails.UserAccessPermission
    ? userTokenDetails.UserAccessPermission
    : null;
  console.log(userPermissions, "this is userPermissions ---.............")
  // if (userPermissions) {
  //   try {
  //     const parsedPermission = JSON.parse(userPermissions);
  //     console.log(parsedPermission, "Parsed userPermission");
  //     const extractedNames = parsedPermission.map(
  //       (permission) => permission.name
  //     );
  //     setroleTerms(extractedNames);
  //     const filteredItems = items.filter((item) =>
  //       extractedNames.includes(item.text)
  //     );
  //     setnavbarconfig(filteredItems);
  //   } catch (error) {
  //     console.error("Failed to parse userPermission:", error);
  //   }
  // } else {
  //   console.log("No userPermission found");
  // }

  // useEffect(() => {
  //   // Function to process user permissions and update state
  //   const processPermissions = () => {
  //     const userPermissions = userTokenDetails.UserAccessPermission;

  //     console.log("userPermissions:", userPermissions);
  //     console.log("items:", items);

  //     if (userPermissions) {
  //       try {
  //         // Parse userPermissions and log the result
  //         const parsedPermission = JSON.parse(userPermissions);
  //         console.log("Parsed userPermission:", parsedPermission);

  //         // Extract names and log
  //         const extractedNames = parsedPermission.map(
  //           (permission) => permission.name
  //         );
  //         console.log("Extracted names:", extractedNames);

  //         // Filter items based on extracted names
  //         const filteredItems = [];
  //         extractedNames.forEach((element) => {
  //           const val = items.filter((ele) => ele.text == element);
  //           console.log(val, "this is val");
  //           filteredItems.push(val);
  //         });
  //         console.log("Filtered items:", filteredItems);

  //         // Update state
  //       } catch (error) {
  //         console.error("Failed to parse userPermission:", error);
  //       }
  //     } else {
  //       console.log("No userPermission found");
  //     }
  //   };

  //   processPermissions();
  // }, [userTokenDetails.UserAccessPermission, items]); // Dependencies to watch

  useEffect(() => {
    console.log("Updated navbarConfig:", navbarconfig);
  }, [navbarconfig]);
  console.log(Roleterms, "role terms--->>>>");
  const userId =
    userTokenDetails && userTokenDetails.user_id
      ? userTokenDetails.user_id
      : null;
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const subUserId = userTokenDetails.sub_user_id
    ? userTokenDetails.sub_user_id
    : null;
  const isEximBank = userTokenDetails.isEximBank
    ? userTokenDetails.isEximBank
    : null;
  const isLCEnabled = userTokenDetails.isLCEnabled
    ? userTokenDetails.isLCEnabled
    : null;
  const isInvoiceEnabled = userTokenDetails.isInvoiceEnabled
    ? userTokenDetails.isInvoiceEnabled
    : null;
  const isBGEnabled = userTokenDetails.isBGEnabled
    ? userTokenDetails.isBGEnabled
    : null;
  const isSCFEnabled = userTokenDetails.isSCFEnabled
    ? userTokenDetails.isSCFEnabled
    : null;
  const isWCEnabled = userTokenDetails.isWCEnabled
    ? userTokenDetails.isWCEnabled
    : null;
  const isCGTMSEEnabled = userTokenDetails.isCGTMSEEnabled
    ? userTokenDetails.isCGTMSEEnabled
    : null;
  const isInsuaranceEnabled = userTokenDetails.isInsuaranceEnabled
    ? userTokenDetails.isInsuaranceEnabled
    : null;

  // const userPermissions = userTokenDetails.UserAccessPermission
  //   ? Object.values(JSON.parse(userTokenDetails.UserAccessPermission))
  //       .join(",")
  //       .split(",")
  //   : [];
  // console.log(
  //   userPermissions,
  //   "this is to check user permission---->>>>>>>>>>>>>>>>>>>>>."
  // );
  // const dupuserPermission = userTokenDetails.userPermission ?
  const userPermissionsForSubAdmin =
    userTokenDetails.UserAccessPermission || "{}";

  const sub_user_id = userTokenDetails.sub_user_id
    ? userTokenDetails.sub_user_id
    : 0;
  console.log("isMobileSidebarOpen", isMobileSidebarOpen);

  // UseEffects
  useEffect(() => {
    setNavToggleState(getNavToggleState());
  }, []);

  // Function to handle scroll events
  const handleScroll = () => {
    const sidebar = document.getElementById("app-nav-div");
    // console.log("1111111111111111111111111111", sidebar?.scrollTop);
    if (sidebar) {
      const scrollTop = sidebar.scrollTop;
      setScrollPosition(scrollTop);
      // Store the scroll position in local storage
      localStorage.setItem("sidebarScrollPosition", scrollTop.toString());
    }
  };

  // Attach scroll event listener when the component mounts
  useEffect(() => {
    const sidebar = document.getElementById("app-nav-div");
    // console.log("22222222222222222222222222222222", sidebar);
    if (sidebar) {
      sidebar.addEventListener("scroll", handleScroll);
    }

    // Check for a stored scroll position in local storage
    const storedScrollPosition = localStorage.getItem("sidebarScrollPosition");
    if (storedScrollPosition) {
      // Scroll the sidebar to the stored position
      sidebar.scrollTop = parseInt(storedScrollPosition, 10);
      setScrollPosition(parseInt(storedScrollPosition, 10));
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (sidebar) {
        sidebar.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  //---------------------------------------------------------------------------------------------------------------------

  function handleNavBarToggle() {
    let currentNavState = getNavToggleState() / 1;
    let newNavState = currentNavState ? 0 : 1;

    setNavToggleStateLS(newNavState);
    setNavToggleState(newNavState);
  }
  console.log("IncomingNotifications", state);

  const isSideBarOpen = (type) => {
    let cacheData = JSON.parse(localStorage.getItem("sideBarStatus") || "{}");
    return cacheData[type];
  };

  const storeSideBarStatusInCache = (type) => {
    let cacheData = JSON.parse(localStorage.getItem("sideBarStatus") || "{}");
    cacheData[type] = !cacheData[type];
    localStorage.setItem("sideBarStatus", JSON.stringify(cacheData));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(navbarconfig);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setnavbarconfig(reorderedItems);
    if (!isEditable) {
      setNavbarConfigurations(reorderedItems);
    }
  };
  function isMobileView() {
    return window.innerWidth <= 767; // Adjust the breakpoint as needed
  }

  const OptionsToShow = (reqId) => {
    // const val = call('POST',"getuserFeatures", {userId:reqId}).then((values)=>console.log(values,"valuesssssssss"))

    //  const newNavConfigOptions = navbarconfig.filter(value=>!optionsToHide.includes(value.id))
    //  console.log(newNavConfigOptions,"newwwwwwwwwwwwwwwwww")

    // call("POST", "getuserFeatures", { userId: reqId })
    //   .then((response) => {
    //     const newval = response?.menusNotVisible
    //       ? response?.menusNotVisible.toString().split()
    //       : [];
    //       console.log(newval,"valllll")
    //     const newNavConfigOptions = navbarconfig.filter(
    //       (value) => !newval.includes(value.id)
    //     );
    //       console.log(newNavConfigOptions,"navvvvvv")
    //     setnavbarconfig(newNavConfigOptions);
    //   })

    //   .catch((error) => {
    //     console.error("Error fetching user features:", error);
    //   });

    call("POST", "getuserFeatures", { userId: reqId })
      .then((response) => {
        const newval = response?.menusNotVisible
          ? response?.menusNotVisible.toString().split(",")
          : [];
        console.log(newval, "valllll");

        // let newNavConfigOptions = navbarconfig.filter(
        //   (value) => !newval.includes(value.id)
        // );
        // // console.log(newNavConfigOptions, "navvvvvv", userTokenDetails.iecNo, userTypeId);

        // newNavConfigOptions = newNavConfigOptions.filter((item) => {
        //   if (
        //     userTypeId / 1 == 19 &&
        //     !userTokenDetails.iecNo &&
        //     item.text === "Export Factoring"
        //   ) {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // });
        // setnavbarconfig(newNavConfigOptions);
      })
      .catch((error) => {
        console.error("Error fetching user features:", error);
      });
  };

  useEffect(() => {
    OptionsToShow(userTokenDetails.user_id);
  }, []);

  return (
    <>
      <div className={`${isEditable && 'overlay_in'}`}></div>
      <nav className={(navToggleState.status ? "w-5 " : "col-md-2 col-sm-12") + "d-md-block sidebar px-3  sidebarscroll" + (navToggleState.status ? " expanded-left" : "") + (isMobileSidebarOpen ? ' sidebarOpen' : ' sidebarClosed')} id="app-nav-div" style={{
        height: "750px", height: "105.04201680672269vh", zIndex: isEditable ? 100001 : 1, backgroundColor: "var(--bg-color-for-sidenav)",
        borderRight: '1px solid #ced4da'
      }}>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover toastClassName="toaster-container" />

        <div style={isEditable ? { zIndex: -1 } : {}}>
          {/* logo start */}
          <div className="logo px-0 headerAlignment" style={{ height: "90px" }}>
            {/* <div className="expanded-menu cursor-pointer" id="app-nav-toggle" onClick={() => handleNavBarToggle()}><i className="fa fa-bars" aria-hidden="true"></i></div> */}

            <div
              className="headerAlignment"
              onClick={() => {
                if (!isMobileView()) {
                  if (!isEditable) {
                    handleNavBarToggle();
                  }
                }
              }}
            >
              <img
                src="assets/images/menu.png"
                height={30}
                className="hamburger-menu cursor"
                onClick={setsidebarOpen}
              />
              <img
                height="60"
                className="default_logo"
                src="assets/images/logos/logo_1.png"
              />
              <img
                height="60"
                className="mini_logo"
                src="assets/images/logos/logo_mini.png"
              />
            </div>
          </div>
          {/* logo end */}
        </div>

        <div
          className="menu-overflow"
          style={{ backgroundColor: "var(--bg-color-for-sidenav)" }}
        >
          <div className="sidebar-sticky " id="scrollbarStyle-custom">
            {/* nav panel start */}
            <div className="col-md-12 px-1">
              <div className="">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="navbar">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <ul className="nav flex-column gap-2">
                          {navbarconfig.map((item, index) => {
                            if (
                              userTypeId === 8 &&
                              item.text === "Letter of Credit" &&
                              (!isLCEnabled || isEximBank)
                            ) {
                              return null;
                            } else if (
                              userTypeId === 8 &&
                              item.text === "SBLC" &&
                              !isEximBank
                            ) {
                              return null;
                            } else if (
                              userTypeId === 8 &&
                              item.text === "Export Factoring" &&
                              !isInvoiceEnabled
                            ) {
                              return null;
                            } else if (
                              userTypeId === 8 &&
                              item.text === "Insurance" &&
                              !isInsuaranceEnabled
                            ) {
                              return null;
                            } else {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="navbar-item"
                                    >
                                      {item.level === 0 ? (
                                        <Link
                                          onClick={() => {
                                            call(
                                              "POST",
                                              "setNavBarActivities",
                                              {
                                                userId,
                                                navBarName: item.stateVal,
                                              }
                                            );
                                          }}
                                          className="nav-link mb-0"
                                          to={item.redirectURL}
                                        >
                                          <li
                                            style={{
                                              backgroundColor:
                                                "var(--bg-color-for-sidenav)",
                                            }}
                                            className={
                                              "nav-item " +
                                              (state === item.stateVal
                                                ? "active2"
                                                : "text-dark")
                                            }
                                          >
                                            {isEditable && (
                                              <span>
                                                <img
                                                  src={
                                                    "assets/images/navbar_draggable_icon.svg"
                                                  }
                                                  className="pr-1"
                                                  title="Dashboard"
                                                  style={{ width: "auto" }}
                                                />
                                              </span>
                                            )}
                                            <span>
                                              <img
                                                src={item.icon}
                                                height="25px"
                                                className="pr-1"
                                                title={item.text}
                                              />
                                            </span>
                                            <span>{item.text}</span>
                                            {/* <span><img src="assets/images/sidebarV2/active_notifiation_dot.svg" className="pl-2" style={{ width: 18, height: 35 }} /> </span> */}
                                          </li>
                                        </Link>
                                      ) : item.level === 1 ? (
                                        <a
                                          className={`nav-link accordion invoiceacc  cursor`}
                                          id="accordionExample"
                                          style={
                                            lcTutorialStep == 1
                                              ? { zIndex: "9" }
                                              : {}
                                          }
                                          onClick={(e) => {
                                            if (navToggleState.status) {
                                              e.preventDefault();
                                              handleNavBarToggle();
                                            }
                                            call(
                                              "POST",
                                              "setNavBarActivities",
                                              {
                                                userId,
                                                navBarName: item.stateVal,
                                              }
                                            );
                                          }}
                                        >
                                          <li
                                            onClick={() =>
                                              storeSideBarStatusInCache(
                                                item.stateVal
                                              )
                                            }
                                            className="accordion-item border-0 acordion-default-icon-remove"
                                          >
                                            <li
                                              style={{
                                                backgroundColor:
                                                  "var(--bg-color-for-sidenav)",
                                              }}
                                              className={
                                                `nav-item   ${navToggleState.status
                                                  ? ""
                                                  : "accordion-button"
                                                }  ${state.includes(
                                                  item.stateVal
                                                ) ||
                                                  isSideBarOpen(item.stateVal)
                                                  ? ""
                                                  : "collapsed"
                                                } rounded-0` +
                                                (state.includes(
                                                  item.stateVal
                                                ) ||
                                                  isSideBarOpen(item.stateVal)
                                                  ? " active2"
                                                  : " text-dark")
                                              }
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#${item.collapseTarget}`}
                                              aria-expanded={
                                                state.includes(item.stateVal) ||
                                                  isSideBarOpen(item.stateVal)
                                                  ? "true"
                                                  : "false"
                                              }
                                              aria-controls={
                                                item.collapseTarget
                                              }
                                            >
                                              {isEditable && (
                                                <span>
                                                  <img
                                                    src={
                                                      "assets/images/navbar_draggable_icon.svg"
                                                    }
                                                    className="pr-1"
                                                    title="Dashboard"
                                                    style={{ width: "auto" }}
                                                  />
                                                </span>
                                              )}
                                              <span className="">
                                                <img
                                                  src={item.icon}
                                                  height="25px"
                                                  className="pr-1"
                                                  title={item.text}
                                                />
                                              </span>
                                              <span>{item.text}</span>
                                              {notifications.findIndex((ele) =>
                                                ele
                                                  ?.toLowerCase()
                                                  .includes(
                                                    item.notificationVal
                                                  )
                                              ) !== -1 ? (
                                                <span className="pl-0">
                                                  <img
                                                    src="assets/images/sidebarV2/active_notifiation_dot.svg"
                                                    className="pl-2"
                                                    style={{
                                                      width: 18,
                                                      height: 35,
                                                    }}
                                                  />{" "}
                                                </span>
                                              ) : null}
                                            </li>
                                          </li>

                                          <div
                                            id={item.collapseTarget}
                                            style={{
                                              backgroundColor:
                                                "var(--bg-color-for-sidenav)",
                                            }}
                                            className={`accordion-menus-container ${lcTutorialStep == 1
                                              ? ""
                                              : " NavDropdown "
                                              } accordion-collapse  ${lcTutorialStep == 1
                                                ? ""
                                                : " collapse "
                                              } ${(state.includes(item.stateVal) ||
                                                isSideBarOpen(item.stateVal)) &&
                                                !navToggleState.status
                                                ? "show"
                                                : ""
                                              }`}
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample"
                                          >
                                            {item.subItems.map((ele, j) => {
                                              return (
                                                <Link
                                                  className="nav-link borderInfo"
                                                  to={ele.redirectURL}
                                                  onClick={(e) => {
                                                    if (item.localStorageKey) {
                                                      checkPermission(
                                                        userPermissions,
                                                        sub_user_id,
                                                        e,
                                                        ele.permissionVal,
                                                        toastDisplay,
                                                        userId,
                                                        () => {
                                                          localStorage.setItem(
                                                            item.localStorageKey,
                                                            ele.name
                                                          );
                                                          window.location =
                                                            ele.redirectURL;
                                                        }
                                                      );
                                                    } else {
                                                      checkPermission(
                                                        userPermissions,
                                                        sub_user_id,
                                                        e,
                                                        ele.permissionVal,
                                                        toastDisplay,
                                                        userId,
                                                        null
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <li
                                                    style={{
                                                      backgroundColor:
                                                        "var(--bg-color-for-sidenav)",
                                                    }}
                                                    className={
                                                      "nav-item " +
                                                      (item.localStorageKey
                                                        ? localStorage.getItem(
                                                          item.localStorageKey
                                                        ) === ele.name &&
                                                          state.includes(
                                                            item.stateVal
                                                          )
                                                          ? "active3 border-0"
                                                          : "text-dark"
                                                        : state === ele.stateVal
                                                          ? "active3 border-0"
                                                          : "text-dark")
                                                    }
                                                  >
                                                    <span className="text-secondary font-size-13">
                                                      {ele.name}
                                                    </span>
                                                    {notifications.findIndex(
                                                      (item) =>
                                                        item
                                                          ?.toLowerCase()
                                                          .includes(
                                                            ele.notificationVal
                                                          )
                                                    ) !== -1 ? (
                                                      <span className="pl-0">
                                                        <img
                                                          src="assets/images/sidebarV2/active_notifiation_dot.svg"
                                                          className="pl-2"
                                                          style={{
                                                            width: 18,
                                                            height: 35,
                                                          }}
                                                        />{" "}
                                                      </span>
                                                    ) : null}
                                                  </li>
                                                </Link>
                                              );
                                            })}
                                          </div>
                                        </a>
                                      ) : item.level === 2 ? (
                                        userTypeId === 8 ? (
                                          item.text === "Other Financial" &&
                                            (isCGTMSEEnabled ||
                                              isBGEnabled ||
                                              isSCFEnabled ||
                                              isWCEnabled) ? (
                                            <a
                                              style={{
                                                backgroundColor:
                                                  "var(--bg-color-for-sidenav)",
                                              }}
                                              className="nav-link accordion invoiceacc  cursor"
                                              id="accordionExample"
                                              onClick={(e) => {
                                                if (navToggleState.status) {
                                                  e.preventDefault();
                                                  handleNavBarToggle();
                                                }
                                                call(
                                                  "POST",
                                                  "setNavBarActivities",
                                                  {
                                                    userId,
                                                    navBarName: item.stateVal,
                                                  }
                                                );
                                              }}
                                            >
                                              <li
                                                onClick={() =>
                                                  storeSideBarStatusInCache(
                                                    item.stateVal
                                                  )
                                                }
                                                style={{
                                                  backgroundColor:
                                                    "var(--bg-color-for-sidenav)",
                                                }}
                                                className="accordion-item border-0 acordion-default-icon-remove"
                                              >
                                                <li
                                                  className={
                                                    `nav-item   ${navToggleState.status
                                                      ? ""
                                                      : "accordion-button"
                                                    }  ${state.includes(
                                                      item.stateVal
                                                    ) ||
                                                      isSideBarOpen(
                                                        item.stateVal
                                                      )
                                                      ? ""
                                                      : "collapsed"
                                                    } rounded-0` +
                                                    (state.includes(
                                                      item.stateVal
                                                    ) ||
                                                      isSideBarOpen(item.stateVal)
                                                      ? " active2"
                                                      : " text-dark")
                                                  }
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#${item.collapseTarget}`}
                                                  aria-expanded={
                                                    state.includes(
                                                      item.stateVal
                                                    ) ||
                                                      isSideBarOpen(item.stateVal)
                                                      ? "true"
                                                      : "false"
                                                  }
                                                  aria-controls={
                                                    item.collapseTarget
                                                  }
                                                >
                                                  {isEditable && (
                                                    <span>
                                                      <img
                                                        src={
                                                          "assets/images/navbar_draggable_icon.svg"
                                                        }
                                                        className="pr-1"
                                                        title="Dashboard"
                                                        style={{
                                                          width: "auto",
                                                        }}
                                                      />
                                                    </span>
                                                  )}
                                                  <span className="">
                                                    <img
                                                      src={item.icon}
                                                      height="25px"
                                                      className="pr-1"
                                                      title={item.text}
                                                    />
                                                  </span>
                                                  <span>{item.text}</span>
                                                </li>
                                              </li>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    "var(--bg-color-for-sidenav) !important",
                                                }}
                                                id={item.collapseTarget}
                                                className={`accordion-menus-container ${" NavDropdown "} accordion-collapse  ${" collapse "} ${(state.includes(
                                                  item.stateVal
                                                ) ||
                                                  isSideBarOpen(
                                                    item.stateVal
                                                  )) &&
                                                  !navToggleState.status
                                                  ? "show"
                                                  : ""
                                                  }`}
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionOtherFin"
                                              >
                                                {item?.subItems?.map(
                                                  (ele, j) => {
                                                    return ele.name ===
                                                      "Working Capital" &&
                                                      userTypeId === 8 &&
                                                      !isWCEnabled ? null : ele.name ===
                                                        "CGTMSE" &&
                                                        userTypeId === 8 &&
                                                        !isCGTMSEEnabled ? null : ele.name ===
                                                          "Bank Gurantee" &&
                                                          userTypeId === 8 &&
                                                          !isBGEnabled ? null : ele.name ===
                                                            "Supply Chain Finance" &&
                                                            userTypeId === 8 &&
                                                            !isSCFEnabled ? null : ele.name ===
                                                              "Domestic Invoice Discounting" &&
                                                              userTypeId === 8 &&
                                                              !isSCFEnabled ? null : (
                                                      <>
                                                        <>
                                                          <li
                                                            className="accordion-item border-0"
                                                            style={{
                                                              backgroundColor:
                                                                "var(--bg-color-for-sidenav)",
                                                            }}
                                                          >
                                                            <li
                                                              className={
                                                                `nav-item   ${navToggleState.status
                                                                  ? ""
                                                                  : "accordion-button-submenu"
                                                                }  ${state.includes(
                                                                  ele.stateVal
                                                                )
                                                                  ? ""
                                                                  : "collapsed"
                                                                } rounded-0` +
                                                                (state.includes(
                                                                  ele.stateVal
                                                                )
                                                                  ? " active2"
                                                                  : " text-dark")
                                                              }
                                                              data-bs-toggle="collapse"
                                                              data-bs-target={`#${ele.collapseTarget}`}
                                                              aria-expanded={
                                                                state.includes(
                                                                  ele.stateVal
                                                                )
                                                                  ? "true"
                                                                  : "false"
                                                              }
                                                              aria-controls={
                                                                ele.collapseTarget
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  "var(--bg-color-for-sidenav)",
                                                              }}
                                                            >
                                                              {ele.name ===
                                                                "Domestic Invoice Discounting" ? (
                                                                <div
                                                                  style={{
                                                                    paddingLeft: 29,
                                                                  }}
                                                                >
                                                                  <span className="font-size-13 px-0">
                                                                    Domestic
                                                                    Invoice
                                                                    Discounting
                                                                  </span>
                                                                </div>
                                                              ) : (
                                                                <span className="font-size-13">
                                                                  {ele.name}
                                                                </span>
                                                              )}
                                                            </li>
                                                          </li>
                                                          <div
                                                            id={
                                                              ele.collapseTarget
                                                            }
                                                            className={`accordion-menus-container ${" NavDropdown "} accordion-collapse  ${" collapse "} ${state.includes(
                                                              ele.stateVal
                                                            ) &&
                                                              !navToggleState.status
                                                              ? "show"
                                                              : ""
                                                              }`}
                                                            aria-labelledby="headingOne"
                                                            data-bs-parent="#accordionExample"
                                                          >
                                                            {ele?.stages?.map(
                                                              (stage, k) => {
                                                                return (
                                                                  <Link
                                                                    className="nav-link borderInfo"
                                                                    to={
                                                                      stage.redirectURL
                                                                    }
                                                                  >
                                                                    <li
                                                                      style={{
                                                                        backgroundColor:
                                                                          "var(--bg-color-for-sidenav)",
                                                                      }}
                                                                      className={
                                                                        "nav-item " +
                                                                        (state ===
                                                                          stage.stateVal
                                                                          ? " active3 border-0"
                                                                          : "text-dark")
                                                                      }
                                                                    >
                                                                      <span className="text-secondary font-size-13">
                                                                        {
                                                                          stage.name
                                                                        }
                                                                      </span>
                                                                    </li>
                                                                  </Link>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </a>
                                          ) : null
                                        ) : (
                                          <a
                                            className="nav-link accordion invoiceacc  cursor"
                                            id="accordionExample"
                                            style={{}}
                                            onClick={(e) => {
                                              if (navToggleState.status) {
                                                e.preventDefault();
                                                handleNavBarToggle();
                                              }
                                              call(
                                                "POST",
                                                "setNavBarActivities",
                                                {
                                                  userId,
                                                  navBarName: item.stateVal,
                                                }
                                              );
                                            }}
                                          >
                                            <li
                                              onClick={() =>
                                                storeSideBarStatusInCache(
                                                  item.stateVal
                                                )
                                              }
                                              style={{
                                                backgroundColor:
                                                  "var(--bg-color-for-sidenav)",
                                              }}
                                              className="accordion-item border-0 acordion-default-icon-remove"
                                            >
                                              <li
                                                style={{
                                                  backgroundColor:
                                                    "var(--bg-color-for-sidenav)",
                                                }}
                                                className={
                                                  `nav-item   ${navToggleState.status
                                                    ? ""
                                                    : "accordion-button"
                                                  }  ${state.includes(
                                                    item.stateVal
                                                  ) ||
                                                    isSideBarOpen(item.stateVal)
                                                    ? ""
                                                    : "collapsed"
                                                  } rounded-0` +
                                                  (state.includes(
                                                    item.stateVal
                                                  ) ||
                                                    isSideBarOpen(item.stateVal)
                                                    ? " active2"
                                                    : " text-dark")
                                                }
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#${item.collapseTarget}`}
                                                aria-expanded={
                                                  state.includes(
                                                    item.stateVal
                                                  ) ||
                                                    isSideBarOpen(item.stateVal)
                                                    ? "true"
                                                    : "false"
                                                }
                                                aria-controls={
                                                  item.collapseTarget
                                                }
                                              >
                                                {isEditable && (
                                                  <span>
                                                    <img
                                                      src={
                                                        "assets/images/navbar_draggable_icon.svg"
                                                      }
                                                      className="pr-1"
                                                      title="Dashboard"
                                                      style={{ width: "auto" }}
                                                    />
                                                  </span>
                                                )}
                                                <span className="">
                                                  <img
                                                    src={item.icon}
                                                    height="25px"
                                                    className="pr-1"
                                                    title={item.text}
                                                  />
                                                </span>
                                                <span>{item.text}</span>
                                              </li>
                                            </li>
                                            <div
                                              style={{
                                                backgroundColor:
                                                  "var(--bg-color-for-sidenav)",
                                              }}
                                              id={item.collapseTarget}
                                              className={`accordion-menus-container ${" NavDropdown "} accordion-collapse  ${" collapse "} ${(state.includes(
                                                item.stateVal
                                              ) ||
                                                isSideBarOpen(
                                                  item.stateVal
                                                )) &&
                                                !navToggleState.status
                                                ? "show"
                                                : ""
                                                }`}
                                              aria-labelledby="headingOne"
                                              data-bs-parent="#accordionOtherFin"
                                            >
                                              {item?.subItems?.map((ele, j) => {
                                                return ele.name ===
                                                  "Working Capital" &&
                                                  userTypeId === 8 &&
                                                  !isWCEnabled ? null : ele.name ===
                                                    "CGTMSE" &&
                                                    userTypeId === 8 &&
                                                    !isCGTMSEEnabled ? null : ele.name ===
                                                      "Bank Gurantee" &&
                                                      userTypeId === 8 &&
                                                      !isBGEnabled ? null : ele.name ===
                                                        "Supply Chain Finance" &&
                                                        userTypeId === 8 &&
                                                        !isSCFEnabled ? null : ele.name ===
                                                          "Domestic Invoice Discounting" &&
                                                          userTypeId === 8 &&
                                                          !isSCFEnabled ? null : (
                                                  <>
                                                    <>
                                                      <li
                                                        style={{
                                                          backgroundColor:
                                                            "var(--bg-color-for-sidenav)",
                                                        }}
                                                        className="accordion-item border-0 "
                                                      >
                                                        <li
                                                          style={{
                                                            backgroundColor:
                                                              "var(--bg-color-for-sidenav)",
                                                          }}
                                                          className={
                                                            `nav-item   ${navToggleState.status
                                                              ? ""
                                                              : "accordion-button-submenu"
                                                            }  ${state.includes(
                                                              ele.stateVal
                                                            )
                                                              ? ""
                                                              : "collapsed"
                                                            } rounded-0` +
                                                            (state.includes(
                                                              ele.stateVal
                                                            )
                                                              ? " active2"
                                                              : " text-dark")
                                                          }
                                                          data-bs-toggle="collapse"
                                                          data-bs-target={`#${ele.collapseTarget}`}
                                                          aria-expanded={
                                                            state.includes(
                                                              ele.stateVal
                                                            )
                                                              ? "true"
                                                              : "false"
                                                          }
                                                          aria-controls={
                                                            ele.collapseTarget
                                                          }
                                                        >
                                                          {ele.name ===
                                                            "Domestic Invoice Discounting" ? (
                                                            <div
                                                              style={{
                                                                paddingLeft: 29,
                                                                backgroundColor:
                                                                  "var(--bg-color-for-sidenav)",
                                                              }}
                                                            >
                                                              <span className="font-size-13 px-0">
                                                                Domestic Invoice
                                                                Discounting
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <span className="font-size-13">
                                                              {ele.name}
                                                            </span>
                                                          )}
                                                        </li>
                                                      </li>
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            "var(--bg-color-for-sidenav)",
                                                        }}
                                                        id={ele.collapseTarget}
                                                        className={`accordion-menus-container ${" NavDropdown "} accordion-collapse  ${" collapse "} ${state.includes(
                                                          ele.stateVal
                                                        ) &&
                                                          !navToggleState.status
                                                          ? "show"
                                                          : ""
                                                          }`}
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionExample"
                                                      >
                                                        {ele?.stages?.map(
                                                          (stage, k) => {
                                                            return (
                                                              <Link
                                                                className="nav-link borderInfo"
                                                                to={
                                                                  stage.redirectURL
                                                                }
                                                              >
                                                                <li
                                                                  style={{
                                                                    backgroundColor:
                                                                      "var(--bg-color-for-sidenav)",
                                                                  }}
                                                                  className={
                                                                    "nav-item " +
                                                                    (state ===
                                                                      stage.stateVal
                                                                      ? " active3 border-0"
                                                                      : "text-dark")
                                                                  }
                                                                >
                                                                  <span className="text-secondary font-size-13">
                                                                    {stage.name}
                                                                  </span>
                                                                </li>
                                                              </Link>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </>
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </a>
                                        )
                                      ) : null}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          })}
                        </ul>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            {isEditable && (
              <div className="mt-2 mx-3 text-center ">
                <button
                  type="button"
                  className={`new-btn w-80  py-2 px-2 text-white cursor`}
                  onClick={() => {
                    setisEditable(false);
                    setNavbarConfigurations(navbarconfig);
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState,
    notifications: state.IncomingNotifications.notification_data,
    items: state.NavConfiguration.navbarItems,
    isMobileSidebarOpen: state.MobileSideBar.isOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setClientType: (type) => {
      dispatch(setClientType({ type: type }));
    },
    setNavToggleState: (status) => {
      dispatch(setNavToggleState({ status: status }));
    },
    setNavbarConfigurations: (data) => {
      dispatch(setNavbarConfiguration(data));
    },
    setsidebarOpen: () => {
      dispatch(setSidebarOpen());
    },
  };
};
//---------------------------------------------------------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(SidebarV2);
