import { useState, useEffect } from 'react';
import Charts from './charts';
import DropdownSearch from './dropdownWithSearch';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default function GraphsCard({ itemsData, defaultData, defaultGraph, stateData, continentData, customerGroupData, itemGroupData, itemCategoryData }) {
  // console.log(defaultData, defaultGraph)
  const graphsTypes = [
    { symbol: "|T|", tooltip: "Table View" },
    { symbol: "|A|", tooltip: "Area Chart" },
    { symbol: "|B|", tooltip: "Bar Chart" },
    { symbol: "|D|", tooltip: "Donut Chart" },
    { symbol: "|P|", tooltip: "Pie Chart" }
  ];

  const [selectedGraph, setSelectedGraph] = useState(defaultGraph);
  const [showAll, setShowAll] = useState(false);
  const [dataForGraphs, setDataForGraphs] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(defaultData);
  const [dataForTableDownload, setDataForTableDownload] = useState([])

  useEffect(() => {
    handleDataselection(dropdownValue);
  }, [itemsData, stateData, continentData, customerGroupData, itemGroupData, itemCategoryData]);

  useEffect(() => {
    const bootstrap = require('bootstrap');
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 100, hide: 10 },
        customClass: 'custom-tooltip',
        placement: "bottom",
        trigger: "hover"
      });
    });
  }, []);

  const handleDataselection = (dataDropdown) => {
    console.log('Selected Dropdown Value:', dataDropdown);
    setDropdownValue(dataDropdown);
    switch (dataDropdown) {
      case 'Item Name':
        setDataForGraphs(calculateContribution(itemsData));
        break;
      case "Refference Number":
        setDataForGraphs(calculateContribution(itemsData));
        break;
      case "Customer Name":
        setDataForGraphs(calculateContribution(itemsData));
        break;
      case "State":
        setDataForGraphs(calculateContribution(stateData));
        break;
      case "Continent":
        setDataForGraphs(calculateContribution(continentData));
        break;
      case "Item Category":
        setDataForGraphs(calculateContribution(itemCategoryData));
        break;
      case "Customer Group":
        setDataForGraphs(calculateContribution(customerGroupData));
        break;
      case "Items Group":
        setDataForGraphs(calculateContribution(itemGroupData));
        break;
      default:
        setDataForGraphs([]);
        break;
    }
  };

  function calculateContribution(data) {
    // const filteredData = data.filter(Boolean);
    const totalAmount = data.reduce((acc, item) => acc + parseInt(item.totalAmount), 0);
    // console.log("totalAmount",totalAmount)
    return data.map(item => ({
      ...item,
      contribution: parseFloat((item.totalAmount / totalAmount * 100).toFixed(1))
    }));
  }
  const handleDownload = () => {
    if (selectedGraph === "Table View") {
      downloadExcel();
    } else {
      downloadPDF();
    }
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataForGraphs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "data.xlsx");
  };
  function resizeAndCompressImage(imageSrc, maxWidth, maxHeight, quality) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        // Calculate new dimensions
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw image on canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Get resized image data with higher quality
        const resizedImgData = canvas.toDataURL('image/jpeg', quality);

        resolve(resizedImgData);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = imageSrc;
    });
  }

  const downloadPDF = () => {
    let idForDiv = ""
    switch (selectedGraph) {
      case "Area Chart":
        idForDiv = "areachart";
        break;
      case "Bar Chart":
        idForDiv = "barchart";
        break;
      case "Pie Chart":
        idForDiv = "piechart";
        break;
      case "Donut Chart":
        idForDiv = "donutchart";
        break;
      default:
        idForDiv = "areachart";
        break;
    }

    const input = document.getElementById(idForDiv);
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg', 0.7);
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // URL of the logo image
      const logoImgSrc = 'assets/images/logos/logo_1.png';

      // Resize and compress logo image
      resizeAndCompressImage(logoImgSrc, 150, 150, 0.9).then(logoImgData => {
        const logoImg = new Image();
        logoImg.src = logoImgData;

        logoImg.onload = () => {
          const logoWidth = 50;
          const logoHeight = (logoImg.height / logoImg.width) * logoWidth;

          // Add logo to PDF
          pdf.addImage(logoImg, 'PNG', (pdfWidth - logoWidth) / 2, 10, logoWidth, logoHeight);

          // Add chart image to PDF
          const imgHeight = canvas.height * 190 / canvas.width;
          const imgY = pdfHeight - imgHeight - 20;
          pdf.addImage(imgData, 'JPEG', 10, imgY, 190, imgHeight);

          // Add border to PDF
          pdf.setLineWidth(0.5);
          pdf.rect(5, 5, pdfWidth - 10, pdfHeight - 10);

          // Save the PDF
          pdf.save("chart.pdf");
        };
      })
    })


  };



  return (
    <div className="col-sm-12 col-md-12 col-lg-6 mb-2">
      <div className="bg-white card shadow w-100 h-100">
        <div className="bg-white rounded card-body p-0">
          <div className="bg-white rounded-top d-flex align-items-center justify-content-between upper border-bottom border-secondary p-2">
            <DropdownSearch items={["Item Name", "Refference Number", "Customer Name", "State", "Items Group", "Customer Group", "Continent", "Item Category"]} placeholder={"Select or Search"} onSelect={handleDataselection} defaultItem={defaultData} />
            <div className="d-flex align-items-center">
              <div
                onMouseEnter={() => setShowAll(true)}
                onMouseLeave={() => setShowAll(false)}
                style={{ display: 'inline-block' }}
              >
                {graphsTypes.map((button) => (
                  <button
                    key={button.tooltip}
                    className="bg-white rounded me-1"
                    style={{
                      border: "0.5px solid grey",
                      display: showAll || button.tooltip === selectedGraph ? 'inline-block' : 'none'
                    }}
                    onClick={() => setSelectedGraph(button.tooltip)}
                    data-toggle="tooltip"
                    title={button.tooltip}
                  >
                    {button.symbol}
                  </button>
                ))}
              </div>
              <button onClick={handleDownload} className="bg-white rounded" style={{ border: "0.5px solid grey" }}><img src='assets/images/new_dowd_icon.svg' /></button>
            </div>
          </div>
          <div className="graphArea bg-white overflow-auto  rounded p-2" style={{ minHeight: "500px" }}>
            <Charts customStyles0={{ overflowY: "scroll", height: "300px", width: "180px" }} selectedChart={selectedGraph} selectedData={dataForGraphs} dropdownValue={dropdownValue} setDataForTableDownload={setDataForTableDownload} />
          </div>
        </div>
      </div>
    </div>
  );
}
