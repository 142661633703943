import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import Header from '../partial/header';
import Footer from '../partial/footer';
import Sidebar from '../partial/sidebar';
import { TabComponent } from '../CommonComponent/TabComponent';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/shipmentMarketPlaceValidation';
import ShippingSearch_Component from './shipping/ShippingSearch_Component_V2';

const MarketPlace = ({ userTokenDetails }) => {

  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className='row'>
          <Sidebar state={'marketplace'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 "} id="app-main-div">
            <Header
              title={'Marketplace'}
              userTokenDetails={userTokenDetails}
            />

            <div className={"my-4 accordionItem" + (accorddata["accord_0"] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}>{
                "Shipping Marketplace"
              }</h2>
              <div className="accordionItemContent">
                <ShippingSearch_Component userTypeId={userTypeId} />
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(
  mapStateToProps,
  null
)(MarketPlace)