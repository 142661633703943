import { useEffect, useState } from "react";
import AddReminder from "./addreminder";
import call from "../../../../service";
import NewTablev2 from "../../../../utils/newTablev2";

export default function ReminderTab({ handleGoBack, allData, userId }) {
  const [showAddReminderTab, setShowAddReminderTab] = useState(false);
  const [showEditReminderTab, setShowEditReminderTab] = useState(false);

  const [reminders, setReminders] = useState([]);
  const [editingReminder, setEditingReminder] = useState(null);

  const fetchandupdatetable = () => {
    call('POST', 'getallreminders', { userId: userId })
      .then((result) => {
        const allReminders = result["allReminders"]
        console.log("allReminders", allReminders)
        if (allReminders) {
          setReminders(allReminders)
        }

      }).catch((e) => {
        console.log('error in target_view', e)
      })
    setShowEditReminderTab(false)
    setShowAddReminderTab(false)
  }
  useEffect(() => {
    fetchandupdatetable()
  }, [])
  const handleAddReminder = (newReminder) => {
    call('POST', 'addreminder', newReminder)
      .then((result) => {
        if (result) {
          console.log("new", result)
          setReminders([...reminders, newReminder]);
          fetchandupdatetable()
          setShowAddReminderTab(false);
        }

      }).catch((e) => {
        console.log('error in target_view', e)
      })
  };

  const setEditReminderfields = (reminder) => {
    setShowEditReminderTab(true);
    setShowAddReminderTab(false)
    setEditingReminder(reminder);
  };
  const handleEditReminder = (reminder) => {
    setShowEditReminderTab(false);
    call('POST', 'updatereminder', reminder)
      .then((result) => {
        if (result.success) {
          fetchandupdatetable()

        }
      })
      .catch((e) => {
        console.log('error in updatereminder', e);
      });

  }
  const handleDeleteReminder = (reminderId) => {
    call('POST', 'deletereminder', { id: reminderId, userId: userId })
      .then((result) => {
        console.log(result.message)
        fetchandupdatetable()
      })
      .catch((e) => {
        console.log('error in deletereminder', e);
      });
  };
  const columns = [
    { subColumns: "Rule", width: '20%' },
    { subColumns: "Customer", width: '20%' },
    { subColumns: "Actions", width: '20%' }
  ];
  return (
    <div className='d-flex flex-column w-100 mb-2'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='d-flex justify-content-between align-items-center'>
          <img className="bg-transparent d-block cursor-pointer me-2" onClick={handleGoBack} src="assets/images/back.png" alt="Back" height="15" width="20" />
          <p className="m-0 p-0">Payment Reminders</p>
        </div>
        <div className='d-flex justify-content-between align-items-center' style={{ height: "40px" }}>
          <button className='btn btn-primary me-2' onClick={() => { setShowAddReminderTab(true) }}>Set Custom Reminder</button>
        </div>
      </div>
      {showAddReminderTab && (
        <AddReminder userId={userId} allData={allData} setShowAddReminderTab={setShowAddReminderTab} handleAddReminder={handleAddReminder} />
      )}
      {showEditReminderTab && (
        <AddReminder userId={userId} allData={allData} setShowAddReminderTab={setShowEditReminderTab} handleAddReminder={handleEditReminder} editingReminder={editingReminder} />
      )}
      <NewTablev2 showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }} columns={columns}>
        {reminders.map((reminder, index) => (
          <tr key={index}>
            <td>{reminder.rule}</td>
            <td>{reminder.customerSelected}</td>


            <td>
              <button className="btn btn-sm btn-primary me-2" onClick={() => setEditReminderfields(reminder)}>Edit</button>
              <button className="btn btn-sm btn-danger" onClick={() => handleDeleteReminder(reminder.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </NewTablev2>
    </div>
  );
}
