import React from "react";
import { useState } from "react";

const AdavancePaymentTab = ({ adavanceData }) => {

  const [upi, setUpi] = useState("");

  return (
    <>
      <div className="row gap-3 justify-content-between">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 paymentmethod ">
          <div className="form-check cursor border-bottom pb-2 mb-2">
            <input className="form-check-input" type="radio" name="UPI" id="UPI" onChange={(e) => setUpi(e.target.name)} checked={upi === "UPI" && true} />
            <label className="form-check-label" for="UPI">
              UPI
            </label>
            {upi === "UPI" && < div className="mb-3 paymentinput">
              <label for="exampleFormControlInput1" className="form-label ms-2 mt-2 mb-0">Enter UPI ID</label>
              <input type="text" className="form-control " id="exampleFormControlInput1" placeholder="" />
            </div>}
          </div>

          <div className="form-check cursor border-bottom pb-2 mb-3">
            <input className="form-check-input" type="radio" name="Wallets" id="Wallets" onChange={(e) => setUpi(e.target.name)} checked={upi === "Wallets" && true} />
            <label className="form-check-label" for="Wallets">
              Wallets
            </label>
            {upi === "Wallets" && < div className="mb-3 paymentinput">
              <label for="exampleFormControlInput1" className="form-label ms-2 mt-2 mb-0">Enter UPI ID</label>
              <input type="text" className="form-control " id="exampleFormControlInput1" placeholder="" />
            </div>}
          </div>
          <div className="form-check cursor border-bottom pb-2 mb-3">
            <input className="form-check-input" type="radio" name="Debit" id="Debit" />
            <label className="form-check-label" for="Debit">
              Debit / Credit / ATM card
            </label>
          </div>
          <div className="form-check cursor border-bottom pb-2 mb-3">
            <input className="form-check-input" type="radio" name="banking" id="banking" />
            <label className="form-check-label" for="banking">
              Net banking
            </label>
          </div>

          <div className="form-check cursor border-bottom pb-2 mb-3">
            <input className="form-check-input" type="radio" name="Cash" id="Cash" />
            <label className="form-check-label" for="Cash">
              Cash
            </label>
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 ">
          <div className="card p-4 pricedetails">
            <p className="text-color1 font-wt-600 font-size-14 mb-2">Price details</p>
            <p className="cursor "><img className="ms-2" src={"assets/images/supplier-images/stenn-logo.png"} alt="stenn" /> <span className="font-size-14 font-wt-600 text-dark">Stenn</span></p>
            <div className="borderdashed pb-3 mb-2">
              {adavanceData.map((key) => {
                return (
                  <div className="d-flex justify-content-between mt-2 font-size-14" key={key.name}>
                    <span>{key.name}</span>
                    <span>{key.amount}</span>
                  </div>
                );
              })}

            </div>
            <div className="">
              <div className="d-flex justify-content-between mt-2 font-size-14">
                <span>Total</span>
                <span>$ 10,000</span>
              </div>
              <div className="d-flex justify-content-between mt-2 font-size-14">
                <span className="fs-6 font-wt-600">Advance payment</span>
                <span className="fs-6 font-wt-600">$ 2,000</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdavancePaymentTab;