

import React, { useEffect, useState } from 'react';
import call from '../../service';
import { formatDateTime_Application } from '../../utils/dateFormaters';


const ContractLogs = ({ userTokenDetails, contractNo, itemData }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [Data, setData] = useState({});
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getcontractlogs', { "contract_number": contractNo }).then(async (result) => {
      console.log("result  in getcontractlogs ->", result)
      let tempData = result
      let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
        applicationId: itemData.invLimitAppId, buyerId: itemData.invLimitBuyerId
      })
      if (apiResp.length) {
        tempData = tempData.concat(apiResp)
      }
      apiResp = await call('POST', 'getTransactionHistoryForLC', {
        buyerId: itemData.lcLimitBuyerId, applicationId: itemData.lcLimitAppId
      })
      if (apiResp.length) {
        tempData = tempData.concat(apiResp)
      }
      apiResp = await call('POST', 'getTransactionsHistoryForShipmentBooking', {
        contractNo: itemData.contract_number
      })
      if (apiResp.length) {
        tempData = tempData.concat(apiResp)
      }
      tempData.sort(function (a, b) {
        return new Date(a.dateTime || a.created_at) - new Date(b.dateTime || b.created_at);
      })
      setData(tempData)
    }).catch((error) => {
      console.log("error occur in getcontractlogs ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          <div className="main-timeline2">

            {Data.length ? Data.map((data) => {
              return (
                <div className="timeline">
                  <span className="icon fa fa-globe" />
                  <a href="javascript:void(0);" className="timeline-content text-decoration-none">
                    <h3 className="title font-size-18 font-wt-500">{data.message || data.action}</h3>
                    <p className="description">
                      On {formatDateTime_Application(data.created_at || data.dateTime)}
                    </p>
                  </a>
                </div>
              )
            }) : ""}

          </div>
        </div>
      </div>
    </div>

  </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------


export default ContractLogs
