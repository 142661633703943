export default function ProgressBar({ percentage = 0 }) {
  const radius = 130;
  const strokeWidth = 20;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div class="border border-info rounded me-2" style={{ position: 'relative', width: `${radius * 2}px`, height: `${radius * 2}px` }}>
      <svg height={radius * 2} width={radius * 2}>
        {/* Grey background circle */}
        <circle
          stroke="#e0e0e0"  // Grey color for the remaining portion
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {/* Green progress circle */}
        <circle
          stroke="#6abf69"  // Green color for the filled portion
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        <div style={{ fontSize: '20px', color: '#6abf69' }}>{percentage}%</div>
        <div style={{ fontSize: '18px' }}>Overall Achieved</div>
      </div>
    </div>
  );
};
