import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Header from '../partial/header';
import LaytimeSheetTrail from '../laytimeSheets/laytimeSheetTrail';
import ContractDoc from '../contractDocument/contractDoc';
import Tracking from './tracking';
import { setContractDetails, setContractDocList, setViewTrack, setInvoiceDetail } from '../../store/actions/action';
import Invoice from '../invoice/invoice';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import InvestigationReportForm from '../investigationReport/investigationReportForm'
import ShipmentDetailAccord from './shipmentDetailAccord'
import InsuranceDetailAccord from '../insurance/insuranceDetailAccord'
import BlockchainTrail from './blockchainTrail'
import { QRCode } from 'react-qrcode-logo';
import QmsWindow from './qms';
import ContractTrail from './contractTrail'
import LCPaymentProcess from '../bank/bankLCProcess/lcProcess'
import DaDpPaymentProcess from '../bank/bankDaDpProcess/dadpProcess'
import TTPaymentProcess from '../bank/ttPaymentProcess/ttProcess'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import ContractLogs from './contractLogs'
import BillOfLading from '../logistics/billOfLading.js'
import avatarUrl from '../../utils/makeAvatarUrl';


const ContractDetail = ({ userTokenDetails, cDetailsState, contractDocList, setContractDocList, setViewTrack, ViewTrack, setInvoiceDetail, InvoiceDetail, clientType, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const cId = cDetailsState.cId
  const [dbData, setDbData] = useState({})
  const [planData, setplanData] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [showQms, setShowQms] = useState(false);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Summary");

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    let cType = (userTypeId === 3 || (userTypeId === 14 && clientType.type === 'buyer')) ? "buyer" : (userTypeId === 4 || (userTypeId === 14 && clientType.type === 'seller')) ? "seller" : ""
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getcontractdetails', { "contract_number": cId, "clientType": cType }).then(async (result) => {
      console.log("getcontractdetails ->", result)
      setDbData(result[0])
      setshowLoader(false)
    }).catch((error) => {
      console.log("error occur in getContracts ->", error)
    })

    call('get', `v1/contract/plan?contractNumber=${cId}`).then(async (result) => {
      // console.log("getcontractplan ->", result)
      setplanData(result.planDetails.features)
      setshowLoader(false)
    }).catch((error) => {
      console.log("error occur in getcontractplan ->", error)
    })

    //------------------------------------------------------------------

  }, [InvoiceDetail.info.refresh, cDetailsState.cId]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  console.log("getcontractplan planData->", planData)
  // console.log('contractDocList.contractId', contractDocList.contractId);
  // console.log('ViewTrack.modal', ViewTrack.modal);
  // console.log('InvoiceDetail.modal', InvoiceDetail.modal);
  //console.log('InvoiceDetail', InvoiceDetail);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return

  return (
    <>
      {(contractDocList.modal && !ViewTrack.modal && !InvoiceDetail.modal) ? <ContractDoc userTokenDetails={userTokenDetails} planData={planData} /> :
        (!contractDocList.modal && ViewTrack.modal && !InvoiceDetail.modal) ? <Tracking userTokenDetails={userTokenDetails} /> :
          (!contractDocList.modal && !ViewTrack.modal && InvoiceDetail.modal) ? <Invoice userTokenDetails={userTokenDetails} planData={planData} /> :
            <>
              <Header
                title={userTypeId === 8 ? 'Case Details' : 'Contract Details'}
                userTokenDetails={userTokenDetails}
              />
              <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

              <div className="d-md-flex">
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <ul className="contract-left">
                  <li>
                    <h2>Contract #{dbData.contract_number ? dbData.contract_number + " : " + tabName : '--'}</h2>
                    <p>{dbData.created_at ? formatDate_Application(dbData.created_at) : '--'}</p>
                  </li>
                </ul>
                <div className="ml-auto pr-3">
                  <ul className="contract-top d-flex flex-md-row align-items-center">

                    {(aclMap.contract_access && aclMap.contract_access.cont_viewDoc && aclMap.contract_access.cont_viewDoc.isExist) &&
                      <li>
                        <button type="button" className="btn btn-info btn-sm" onClick={() => {
                          setContractDocList(dbData.contract_number);
                          setViewTrack(false, {});
                        }}>Contract Documents</button>
                      </li>}

                    {((aclMap.contract_access && aclMap.contract_access.cont_genInvoice && aclMap.contract_access.cont_genInvoice.isExist) ||
                      (aclMap.contract_access && aclMap.contract_access.cont_uploadInvoice && aclMap.contract_access.cont_uploadInvoice.isExist) ||
                      (aclMap.contract_access && aclMap.contract_access.cont_getInvoice && aclMap.contract_access.cont_getInvoice.isExist)) && <>
                        <DropdownButton id="dropdownInvoice" title="Invoice" variant="primary" size="sm">
                          {(dbData.invoice_status === 0 && dbData.exporter_id === userId) ? <>
                            {(aclMap.contract_access && aclMap.contract_access.cont_genInvoice && aclMap.contract_access.cont_genInvoice.isExist && (planData.invoiceGen && planData.invoiceGen.enable)) &&
                              <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "Generate" })
                              }}>
                                Generate Invoice
                              </Dropdown.Item>}
                            {(aclMap.contract_access && aclMap.contract_access.cont_uploadInvoice && aclMap.contract_access.cont_uploadInvoice.isExist) &&
                              <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "Upload" })
                              }}>
                                Upload Invoice
                              </Dropdown.Item>}
                          </> :
                            (dbData.invoice_status === 1 && dbData.exporter_id === userId) ?
                              <>
                                {(aclMap.contract_access && aclMap.contract_access.cont_genInvoice && aclMap.contract_access.cont_genInvoice.isExist) &&
                                  <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                    setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "ReGenerate" })
                                  }}>
                                    Re-Generate Invoice
                                  </Dropdown.Item>}
                              </> : (dbData.invoice_status === 0 && dbData.importer_id === userId) ?
                                <Dropdown.Item>NA</Dropdown.Item> : ""}
                          {(dbData.invoice_status === 1 && (aclMap.contract_access && aclMap.contract_access.cont_getInvoice && aclMap.contract_access.cont_getInvoice.isExist)) &&
                            <Dropdown.Item href="javascript:void(0)" onClick={() => {
                              setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "View" })
                            }}>
                              View Invoice
                            </Dropdown.Item>}
                        </DropdownButton>
                      </>}

                  </ul>
                </div>
              </div>

              <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                <li>
                  <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                    setTab(0);
                    setTabName("Summary");
                  }}>Summary</a>
                </li>
                {(aclMap.contract_access && aclMap.contract_access.cont_lcDetails && aclMap.contract_access.cont_lcDetails.isExist) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                      setTab(1);
                      setTabName("Bank Process");
                    }}>Bank Process</a>
                  </li>}
                {(aclMap.contract_access && aclMap.contract_access.cont_invReport && aclMap.contract_access.cont_invReport.isExist) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                      setTab(2);
                      setTabName("Investigation Process");
                    }}>Investigation</a>
                  </li>}
                {(aclMap.contract_access && aclMap.contract_access.cont_insuranceDetails && aclMap.contract_access.cont_insuranceDetails.isExist) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 3 ? " active show" : "")} onClick={() => {
                      setTab(3);
                      setTabName("Insurance Process");
                    }}>Insurance </a>
                  </li>}
                {(aclMap.contract_access && aclMap.contract_access.cont_shipmentDetails && aclMap.contract_access.cont_shipmentDetails.isExist) && (planData.shipmentTracking && planData.shipmentTracking.enable) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 4 ? " active show" : "")} onClick={() => {
                      setTab(4);
                      setTabName("Shipment Details");
                    }}>Shipment</a>
                  </li>}

                {(aclMap.contract_access && (aclMap.contract_access && aclMap.contract_access.cont_blprocess && aclMap.contract_access.cont_blprocess.isExist)) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 5 ? " active show" : "")} onClick={() => {
                      setTab(5);
                      setTabName("Bill Of Lading");
                    }}>B/L Process</a>
                  </li>}

                {(aclMap.contract_access && aclMap.contract_access.cont_bcAudit && aclMap.contract_access.cont_bcAudit.isExist) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 6 ? " active show" : "")} onClick={() => {
                      setTab(6);
                      setTabName("Blockchain Audit");
                    }}>Blockchain Audit</a>
                  </li>}

                {(aclMap.contract_access && aclMap.contract_access.cont_auditTrail && aclMap.contract_access.cont_auditTrail.isExist) &&
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 7 ? " active show" : "")} onClick={() => {
                      setTab(7);
                      setTabName("Contract Logs");
                    }}>Logs</a>
                  </li>}
              </ul>

              <>
                {tab === 0 &&
                  <>
                    <div className="tab-pane active show ">
                      {/* <div className="col-md-12"> */}

                      <div className="row">
                        <div className="col-md-10">
                          <div className="row mt-2">

                            <div className="col-md-3 ">
                              <div className="form-group">
                                <label className="col-md-12">Importer</label>
                                <div className="chat-message pl-2">
                                  <img src={avatarUrl(dbData.buyeravatar)} alt="Avatar" width="32" height="32" />
                                  <div className="chat-message-content clearfix pt-2">
                                    <span className="text-primary">  {dbData.buyername ? dbData.buyername : '--'}</span><br />
                                  </div>
                                  <p className="date-format p-0 m-0">{dbData.buyerAddress ? dbData.buyerAddress : '--'}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <div className="form-group">
                                <label className="col-md-12">Exporter</label>
                                <div className="chat-message pl-2">
                                  <img src={avatarUrl(dbData.supplieravatar)} alt="Avatar" width="32" height="32" />
                                  <div className="chat-message-content clearfix pt-2">
                                    <span className="text-primary">  {dbData.suppliername ? dbData.suppliername : '--'}</span><br />
                                  </div>
                                  <p className="date-format p-0 m-0">{dbData.supplierAddress ? dbData.supplierAddress : '--'}</p>
                                </div>
                              </div>
                            </div>
                            {(userTypeId === 3 || (userTypeId === 14 && clientType.type === 'buyer')) ?
                              <div className="col-md-3 ">
                                <div className="form-group">
                                  <label className="col-md-12">Investigation Agency</label>
                                  <div className="chat-message pl-2">
                                    <img src={avatarUrl(dbData.agencyavatarImporter)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix pt-2">
                                      <span className="text-primary">  {dbData.agencynameImporter ? dbData.agencynameImporter : '--'}</span><br />
                                    </div>
                                    <p className="date-format p-0 m-0">{dbData.agencyImporterAddress ? dbData.agencyImporterAddress : '--'}</p>
                                  </div>
                                </div>
                              </div> :
                              (userTypeId === 4 || (userTypeId === 14 && clientType.type === 'seller')) ?
                                <div className="col-md-3 ">
                                  <div className="form-group">
                                    <label className="col-md-12">Investigation Agency</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(dbData.agencyavatarExporter)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="text-primary">  {dbData.agencynameExporter ? dbData.agencynameExporter : '--'}</span><br />
                                      </div>
                                      <p className="date-format p-0 m-0">{dbData.agencyExporterAddress ? dbData.agencyExporterAddress : '--'}</p>
                                    </div>
                                  </div>
                                </div> : ""}
                            <div className="col-md-3 ">
                              <div className="form-group">
                                <label className="col-md-12">Ship Owner</label>
                                <div className="chat-message pl-2">
                                  <img src={avatarUrl(dbData.shipowneravatar)} alt="Avatar" width="32" height="32" />
                                  <div className="chat-message-content clearfix pt-2">
                                    <span className="text-primary">  {dbData && dbData.shipOwner ? dbData.shipOwner : '--'}</span><br />
                                  </div>
                                  <p className="date-format p-0 m-0">{dbData.shipOwnerAddress ? dbData.shipOwnerAddress : '--'}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="card-panel">
                            <ul>
                              <li>
                                <QRCode value={dbData.address ? dbData.address : cId} enableCORS={true} fgColor={'#17a2b8'} qrStyle={'dots'} />
                                {/* <p>{dbData.address ? dbData.address + "\n\rSUmmer" : 'Not Available'}</p> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}


                      <div className="col-md-12 mt-2">
                        {/* <div className="col-md-10"> */}
                        <div className="d-md-flex align-content-around flex-wrap mb-3 contract-details">

                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{dbData.commodity_name ? dbData.commodity_name.toUpperCase() : ""}</h3>
                                <p>Commodity</p>
                              </li>
                            </ul>
                          </div>
                          {(userTypeId === 14 || userTypeId === 3 || userTypeId === 4) &&
                            <>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>{dbData.currency ? dbData.currency.split(':')[1] : ''} {dbData.price ? dbData.price : '--'} </h3>
                                    <p>Price</p>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-panel">
                                <ul>
                                  <li>
                                    <h3>{dbData.quantity ? dbData.quantity : ''} mt</h3>
                                    <p>Quantity</p>
                                  </li>
                                </ul>
                              </div>
                            </>}
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>
                                  {dbData.load_country ? <span className="shadow">
                                    <ReactCountryFlag
                                      countryCode={dbData.load_country.split(':')[0]}
                                      style={{ width: '25px', height: '25px' }} svg />
                                  </span> : ''}
                                </h3>
                                <p>Loading Country</p>
                              </li>
                            </ul>
                          </div>
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{dbData.loading_port ? dbData.loading_port : '--'} </h3>
                                <p>loading Port</p>
                              </li>
                            </ul>
                          </div>
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>
                                  {dbData.unload_country ? <span className="shadow">
                                    <ReactCountryFlag
                                      countryCode={dbData.unload_country.split(':')[0]}
                                      style={{ width: '25px', height: '25px' }} svg />
                                  </span> : ''}
                                </h3>
                                <p>Unloading Country</p>
                              </li>
                            </ul>
                          </div>
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3> {dbData.unloading_port ? dbData.unloading_port : "-"} </h3>
                                <p>Unoading Port</p>
                              </li>
                            </ul>
                          </div>
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{dbData.laycan_start ? formatDate_Application(dbData.laycan_start) : '--'}</h3>
                                <p>Laycan Start</p>
                              </li>
                            </ul>
                          </div>
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{dbData.laycan_end ? formatDate_Application(dbData.laycan_end) : '--'} </h3>
                                <p>Laycan End</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* </div> */}

                      </div>
                      <hr />
                      <div className="col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_changelog && aclMap.contract_access.cont_changelog.isExist) &&
                          <ContractTrail
                            userTokenDetails={userTokenDetails}
                            contractNo={cId} modal={false} />}
                      </div>
                    </div>
                  </>
                }
                {tab === 1 &&
                  <div className="tab-pane active show  col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_lcDetails && aclMap.contract_access.cont_lcDetails.isExist && (planData.lcIssuance && planData.lcIssuance.enable)) &&
                      <>
                        {dbData.payment_mode / 1 === 1 ?
                          <LCPaymentProcess
                            userTokenDetails={userTokenDetails}
                            contractNo={cId}
                            contractData={dbData} />
                          : dbData.payment_mode / 1 === 2 ?
                            <DaDpPaymentProcess
                              userTokenDetails={userTokenDetails}
                              contractNo={cId}
                              contractData={dbData}
                              dadpType={"DP"} />
                            : dbData.payment_mode / 1 === 3 ?
                              <DaDpPaymentProcess
                                userTokenDetails={userTokenDetails}
                                contractNo={cId}
                                contractData={dbData}
                                dadpType={"DA"} />
                              : dbData.payment_mode / 1 === 4 ?
                                <TTPaymentProcess
                                  userTokenDetails={userTokenDetails}
                                  contractNo={cId}
                                  contractData={dbData} />
                                : "Current Payment Mode is Under Development"}
                      </>}
                  </div>
                }
                {tab === 2 &&
                  <div className="tab-pane active show col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_invReport && aclMap.contract_access.cont_invReport.isExist) &&
                      <InvestigationReportForm
                        userTokenDetails={userTokenDetails}
                        contractNo={cId}
                        contractData={{
                          contractNo: cId,
                          contractId: dbData.contract_id,
                          inv_agency_importer: dbData.inv_agency_importer,
                          inv_agency_exporter: dbData.inv_agency_exporter,
                          inv_agency_third: dbData.inv_agency_third,
                          importer_id: dbData.importer_id,
                          exporter_id: dbData.exporter_id,
                        }} />}
                  </div>
                }
                {tab === 3 &&
                  <div className="tab-pane active show col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_insuranceDetails && aclMap.contract_access.cont_insuranceDetails.isExist) &&
                      <InsuranceDetailAccord
                        userTokenDetails={userTokenDetails}
                        contractDetails={{
                          contractNo: cId,
                          contractId: dbData.contract_id,
                          commodity_insurer: dbData.commodity_insurer,
                          trade_insurer: dbData.trade_insurer,
                          importer_id: dbData.importer_id,
                          exporter_id: dbData.exporter_id,
                        }}
                        contractType={"commodity"} />}
                  </div>
                }
                {tab === 4 &&
                  <div className="tab-pane active show col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_shipmentDetails && aclMap.contract_access.cont_shipmentDetails.isExist) &&
                      <>
                        <ShipmentDetailAccord
                          userTokenDetails={userTokenDetails}
                          contractNo={cId}
                          shipContractNo={null} />
                        <hr />
                        <LaytimeSheetTrail
                          userTokenDetails={userTokenDetails}
                          laytimeType={1}
                          contractNo={cDetailsState.cId}
                          contractType={'master'} />
                        <hr />
                        <LaytimeSheetTrail
                          userTokenDetails={userTokenDetails}
                          laytimeType={1}
                          contractNo={cDetailsState.cId}
                          contractType={'master'} />
                      </>}
                  </div>
                }
                {tab === 5 &&
                  <div className="tab-pane active show col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_blprocess && aclMap.contract_access.cont_blprocess.isExist) &&
                      <BillOfLading
                        userTokenDetails={userTokenDetails}
                        contractNo={cId}
                        shipContractNo={null} />}
                  </div>}

                {tab === 6 &&
                  <div className="tab-pane active show col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_bcAudit && aclMap.contract_access.cont_bcAudit.isExist) &&
                      <BlockchainTrail
                        userTokenDetails={userTokenDetails}
                        contractNo={cId} />}
                  </div>}

                {tab === 7 &&
                  <div className="tab-pane active show col-md-12">
                    {(aclMap.contract_access && aclMap.contract_access.cont_auditTrail && aclMap.contract_access.cont_auditTrail.isExist) &&
                      <ContractLogs
                        userTokenDetails={userTokenDetails}
                        contractNo={cId} />}
                  </div>}

              </>


              {/* QMS code start */}
              {(aclMap.contract_access && aclMap.contract_access.cont_qms && aclMap.contract_access.cont_qms.isExist && (planData.qms && planData.qms.enable)) && <>
                <div className="chat-button" onClick={() => { setShowQms(true) }}><i className="fa fa-comments-o" aria-hidden="true"></i></div>
                <div className="circle-rippl"></div>
                {showQms &&
                  <>
                    <div className="chat-box" id="chatBox" Style="display: block">
                      <div className="chat-header">
                        <h1 className="chat-title">QMS</h1>
                        <div className="chat-btn-bg">
                          <span className="chat-close" onClick={() => { setShowQms(false) }}>×</span>
                        </div>
                      </div>
                      <QmsWindow
                        userTokenDetails={userTokenDetails}
                        contractNo={cId} />
                    </div>
                  </>
                }
              </>}
            </>}
      {/* QMS code end */}
    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractDocList: state.contractDocList,
    ViewTrack: state.ViewTrack,
    InvoiceDetail: state.InvoiceDetail,
    clientType: state.clientType,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: false })) },
    setContractDocList: (id) => { dispatch(setContractDocList({ modal: true, contractId: id })) },
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) },
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractDetail)
