import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/financeDetailsComp';
import FinanceDetailsGrid from './financeDetailGrid'
import docJson from './financeDoc.json'

const FinanceDetailsComp = ({ userTokenDetails, compCall, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [showLoader, setshowLoader] = useState(false);
  const [update, setupdate] = useState(false);
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [currencyData, setcurrencyData] = useState([]);
  const [accord, setaccord] = useState(false);
  const [yearList, setyearList] = useState([]);


  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null

  const astrix = <span className="required-field text-danger">*</span>
  console.log("docJson in FinanceDetailsComp", docJson);

  //------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //API Calls
    // Get currency list from Master table.
    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    let tempArray = []

    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      tempArray.push(i)
    }
    setyearList(tempArray)

  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  const handleChange = (event) => {
    event.persist();
    let tempObj = null
    if (event.target.name === "year") {
      tempObj = {
        finance_doc_1_year: event.target.value - 1,
        finance_doc_2_year: event.target.value - 2,
        finance_doc_3_year: event.target.value - 3,
        itr_doc_1_year: event.target.value - 1,
        itr_doc_2_year: event.target.value - 2,
        itr_doc_3_year: event.target.value - 3
      }
    }
    if (tempObj) {
      setData({ ...data, [event.target.name]: event.target.value, ...tempObj });
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
    setError({ ...error, [event.target.name]: "" });
  };

  const handleFiles = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };


  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = userId
    data.userEmail = userEmail

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    call('POST', "insertuserfinancedetails", formData).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setIsSubmitting(false)
      setaccord(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }


  //---------------------------------------------------------------------------------------------------------------------


  function docFormGenerator() {

    let keysArray = Object.keys(docJson)
    let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
      return (<>
        <div className="col-md-12 pt-1 pb-1">
          <h3>{keyValue}</h3>
          {docJson[keyValue].map((arrayElement) => {
            return (
              <div className="row">
                <div className="col-md-8">
                  <ul className="other-documents">
                    <li>
                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                    </li>
                    <li>
                      {arrayElement.type === 'select' ?
                        <select disabled className={"form-control" + (error[arrayElement.name + ":" + arrayElement.id] ? " border-danger" : "")} style={{ "min-width": "215px" }} name={arrayElement.name}
                          value={data[arrayElement.name]} onChange={handleChange} >
                          <option value="" selected>--Select Year--</option>
                          {yearList.map((year) => {
                            return (
                              <option value={year}>{year}</option>
                            )
                          })}
                        </select>
                        :
                        <h3 className={"form-control" + (error[arrayElement.name + ":" + arrayElement.id] ? " border-danger" : "")} style={{ "min-width": "215px" }}>{arrayElement.lable} {(arrayElement.lable === "Debtors" || arrayElement.lable === "Creditors") ? "" : astrix}  </h3>
                      }
                      {error[arrayElement.name] && <p className="text-danger error-contract">{error[arrayElement.name]}</p>}
                    </li>
                    <li>
                      {arrayElement.type === 'select' ?
                        <div className="file-browse">
                          <button className="btn btn-primary btn-sm">Select Document</button>
                          <input type="file" accept=".png,.jpg,.pdf" name={arrayElement.name + ":" + arrayElement.id} onChange={handleFiles} disabled={!data[arrayElement.name]} />
                        </div>
                        :
                        <div className="file-browse">
                          <button className="btn btn-primary btn-sm">Select Document</button>
                          <input type="file" accept=".png,.jpg,.pdf" name={arrayElement.name + ":" + arrayElement.id} onChange={handleFiles} />
                        </div>
                      }
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  {data[arrayElement.name + ":" + arrayElement.id] ? <div className="form-group mb-0"><div className="file-name m-0">{`${data[arrayElement.name + ":" + arrayElement.id].name}`}</div></div> : ""}
                  {error[arrayElement.name + ":" + arrayElement.id] ? <div class="text-danger error-contract">{error[arrayElement.name + ":" + arrayElement.id]}</div> : ''}
                </div>
              </div>
            )
          })}
        </div>
        <hr />
      </>)
    }) : ""
    return (returnJSX)
  }

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <div className="col-md-12 bg-light shadow">
        <div className="col-md-12 p-2">
          <div className="row">
            <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord ? " open" : " closed")}>
              <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord(!accord)}>Add Financial Details</h2>
              <div className="accordionItemContent">
                <div className="row pt-3">
                  <div className="form-group col-md-2">
                    <label>Year {astrix} </label>
                    <select className={"form-control" + (error.currency ? " border-danger" : "")} name="year" value={data.year} onChange={handleChange} >
                      <option value="" selected>--Select Year--</option>
                      {yearList.map((year) => {
                        return (
                          <option value={year} >{year}</option>
                        )
                      })}
                    </select>
                    {error.year && <p className="text-danger error-contract">{error.year}</p>}
                  </div>
                  <div className="form-group col-md-2">
                    <label>Currency{astrix} </label>
                    <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency} onChange={handleChange} >
                      <option value="" selected>--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.id + ':' + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {error.currency && <p className="text-danger error-contract">{error.currency}</p>}
                  </div>
                  <div className="form-group col-md-2">
                    <label>Turnover{astrix} </label>
                    <input type="number" className={" form-control" + (!error.turnover ? '' : ' border-danger')} placeholder="" name="turnover" value={data.turnover} onChange={handleChange} required />
                    {error.turnover && <p className="text-danger error-contract">{error.turnover}</p>}
                  </div>
                  <div className="form-group col-md-2">
                    <label>Net Profit{astrix} </label>
                    <input type="number" className={" form-control" + (!error.netProfit ? '' : ' border-danger')} placeholder="" name="netProfit" value={data.netProfit} onChange={handleChange} required />
                    {error.netProfit && <p className="text-danger error-contract">{error.netProfit}</p>}
                  </div>
                  <div className="form-group col-md-2">
                    <label>Net Worth{astrix} </label>
                    <input type="number" className={" form-control" + (!error.netWorth ? '' : ' border-danger')} placeholder="" name="netWorth" value={data.netWorth} onChange={handleChange} required />
                    {error.netWorth && <p className="text-danger error-contract">{error.netWorth}</p>}
                  </div>

                  <hr className="col-md-12" />

                  <div className="form-group col-md-12">
                    <h2>Related Documents</h2>
                  </div>
                  <div className="form-group col-md-12">

                    {docFormGenerator()}

                  </div>
                  <div className="form-group col-md-12">
                    <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={() => preHandleSubmit()}>{update ? "Update" : "Submit"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <FinanceDetailsGrid userTokenDetails={userTokenDetails} />

      </div>
    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceDetailsComp)