import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SideBarV2 from '../../partial/sideBarV2';
import HeaderV2 from '../../partial/headerV2';

const ViewdetailsData = [
  {
    subheading: "Price",
    starter: "$ 485",
    basic: "$ 830",
    growth: "$ 830",
    standard: "$ 1,499"
  },
  {
    subheading: "Coins",
    starter: "30,000",
    basic: "75,000",
    growth: "75,000",
    standard: "4,00,000"
  },
  {
    subheading: "Validity",
    starter: "6 months",
    basic: "1 year",
    growth: "1 year",
    standard: "1 year"
  },
  {
    subheading: "Data access",
    starter: "Jan 2020 onwards (except India weekly & India SEZ) ",
    basic: "All (Except India weekly) ",
    growth: "All (Except India weekly) ",
    standard: "All"
  },
  {
    subheading: "Search queries",
    starter: "4,000",
    basic: "4,000",
    growth: "6,000",
    standard: "6,000"
  },
  {
    subheading: "Work space limit",
    starter: "25",
    basic: "25",
    growth: "25",
    standard: "25"
  },
  {
    subheading: "Work space deletation",
    starter: "5 / month",
    basic: "10 / month",
    growth: "10 / month",
    standard: "20 / month"
  },
  {
    subheading: "Display all fields ",
    starter: "No",
    basic: "No",
    growth: "No",
    standard: "Yes"
  },
  {
    subheading: "Record per workspace ",
    starter: "-",
    basic: "-",
    growth: "-",
    standard: "10,000"
  },
];

const viewDetails = ({ userTokenDetails, navToggleState }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="wallet" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Plans"}
              userTokenDetails={userTokenDetails} />
            <h5 className="text-center my-3"> <a href="/wallet" className="text-decoration-none"> <img src={"/assets/images/back.png"} alt="back" className="me-3" /> </a>Transparent pricing for you</h5>

            <div className='mt-5'>
              <table className="table table-bordered Detailspage">
                <thead>
                  <tr>
                    <th scope="col" className="bg-EEEEEE text-dark ">Plans <img src={'/assets/images/ArrowBack.png'} alt='' /> </th>
                    <th scope="col" className="bg-B9EFFF text-dark text-center">Starter</th>
                    <th scope="col" className="bg-B9EFFF text-dark text-center">Basic</th>
                    <th scope="col" className="bg-B9EFFF text-dark text-center">Growth</th>
                    <th scope="col" className="bg-5CB8D3 text-white text-center">Standard</th>
                    <th scope="col" className="bg-5CB8D3 text-white text-center">Standard</th>
                  </tr>
                </thead>
                <tbody>
                  {ViewdetailsData.map((key) => {
                    return (
                      <tr>
                        <td className='detailssection'>{key.subheading}</td>
                        <td className='text-center'>{key.starter}</td>
                        <td className='text-center'>{key.basic}</td>
                        <td className='text-center'>{key.growth}</td>
                        <td className='text-center'>{key.standard}</td>
                        <td className='text-center'>{key.standard}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className='detailssection'></td>
                    <td className='text-center'><div className="text-center">
                      <button type="button"
                        className={`new-btn py-2 rounded-3 px-2 w-90 chooseplan text-white font-size-13`}>
                        choose plan
                      </button>
                    </div></td>
                    <td className='text-center'><div className="text-center">
                      <button type="button"
                        className={`new-btn py-2 rounded-3 px-2 w-90 chooseplan text-white font-size-13`}>
                        choose plan
                      </button>
                    </div></td>
                    <td className='text-center'><div className="text-center">
                      <button type="button"
                        className={`new-btn py-2 rounded-3 px-2 w-90 chooseplan text-white font-size-13`}>
                        choose plan
                      </button>
                    </div></td>
                    <td className='text-center'><div className="text-center">
                      <button type="button"
                        className={`new-btn py-2 rounded-3 px-2 w-90 chooseplan text-white font-size-13`}>
                        choose plan
                      </button>
                    </div></td>
                    <td className='text-center'><div className="text-center">
                      <button type="button"
                        className={`new-btn py-2 rounded-3 px-2 w-90 chooseplan text-white font-size-13`}>
                        choose plan
                      </button>
                    </div></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(viewDetails)