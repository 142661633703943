import React from 'react'
import { ExpandableTable } from '../wallet/components/ExpandableTable'
import { NewTable } from '../../utils/newTable'
import ReactECharts from 'echarts-for-react';
let option = {
  tooltip: {
    trigger: 'item'
  },

  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
        innerWidth: 5
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Comission' },
        { value: 735, name: 'Plan' }
      ]
    }
  ]
};
const AdminDashboardNew = () => {
  return (
    <div className='master-admin'>
      <div className="card">
        <div className="div-18">
          <p className="p mb-0">
            <span className="span">Data</span>
            <span className="text-wrapper-26"> - </span>
            <span className="text-wrapper-27">652</span>
          </p>
          <div className="summary">
            <div className="over-due">
              <div className="text-wrapper-28">02</div>
              <div className="text-wrapper-29">Task</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-28">02</div>
              <div className="text-wrapper-29">Lead</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-30">02</div>
              <div className="text-wrapper-29">Pending Task</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-31">02</div>
              <div className="text-wrapper-29">Not Interested</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-32">02</div>
              <div className="text-wrapper-29">Lost</div>
            </div>
          </div>
        </div>
        <div className="div-18">
          <p className="p mb-0">
            <span className="span">Application</span>
            <span className="text-wrapper-26"> - </span>
            <span className="text-wrapper-27">652</span>
          </p>
          <div className="summary-2">
            <div className="over-due">
              <div className="text-wrapper-30">19</div>
              <div className="text-wrapper-29">Ongoing</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-33">294</div>
              <div className="text-wrapper-29">Approved</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-31">21</div>
              <div className="text-wrapper-29">Rejected</div>
            </div>
            <div className="over-due">
              <div className="text-wrapper-32">20</div>
              <div className="text-wrapper-29">Expired</div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-19 mt-4">
        <div className="transaction-grid">
          <div className="TOR">
            <div className="header-2">
              <div className="text-wrapper-34">Transaction type</div>
            </div>
            <div className="header-3">
              <div className="text-wrapper-35">Limit application</div>
            </div>
            <div className="header-3">
              <div className="text-wrapper-35">Quote</div>
            </div>
            <div className="header-3">
              <div className="text-wrapper-35">Termsheet/Contract</div>
            </div>
            <div className="header-3">
              <div className="text-wrapper-35">Finance Application</div>
            </div>
            <div className="header-3">
              <div className="text-wrapper-35">Agreement</div>
            </div>
            <div className="approved-finance-wrapper">
              <div className="text-wrapper-35">Approved&nbsp;&nbsp;Finance</div>
            </div>
          </div>
          <div className="TOR-2">
            <div className="header-4">
              <div className="text-wrapper-36">Letter of Credit</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">4</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">3</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">1</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">2</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-37">-</div>
            </div>
            <div className="header-6">
              <div className="text-wrapper-35">10</div>
            </div>
          </div>
          <div className="TOR-2">
            <div className="header-4">
              <div className="text-wrapper-36">Exporting factoring</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">2</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">2</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">1</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">4</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">0</div>
            </div>
            <div className="header-6">
              <div className="text-wrapper-35">8</div>
            </div>
          </div>
          <div className="TOR-2">
            <div className="header-4">
              <div className="text-wrapper-36">Other services</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">2</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">2</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">1</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">4</div>
            </div>
            <div className="header-5">
              <div className="text-wrapper-35">0</div>
            </div>
            <div className="header-6">
              <div className="text-wrapper-35">8</div>
            </div>
          </div>
        </div>
        <div className="reminder">
          <div className="text-wrapper-38">Today’s Reminder</div>
          <div className="list">
            <div className="reminders">
              <div className="reminder-2">
                <div className="text-wrapper-39">
                  Video call&nbsp;&nbsp;|&nbsp;&nbsp;10:00 AM&nbsp;&nbsp;|&nbsp;&nbsp;Participants
                </div>
              </div>
              <div className="reminder-2">
                <div className="text-wrapper-39">
                  Voice call&nbsp;&nbsp;|&nbsp;&nbsp;10:00 AM&nbsp;&nbsp;|&nbsp;&nbsp;Participants
                </div>
              </div>
              <div className="reminder-3">
                <p className="text-wrapper-40">Make payment&nbsp;&nbsp;|&nbsp;&nbsp;$ 10,000 - XYZ Pvt. Ltd.</p>
              </div>
              <div className="reminder-3">
                <p className="text-wrapper-40">Receive payment&nbsp;&nbsp;|&nbsp;&nbsp;$ 10,000 - XYZ Pvt. Ltd.</p>
              </div>
              <div className="reminder-3">
                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
              </div>
              <div className="reminder-3">
                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
              </div>
              <div className="reminder-3">
                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
              </div>
              <div className="reminder-3">
                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
              </div>
            </div>
            <div className="rectangle" />
          </div>
        </div>
      </div>
      <div className="div-19 mt-4">
        <div className="top-products">
          <div className="pie">
            <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
          </div>
          <div className="legend">
            <div className="div-20">
              <div className="text-wrapper-42">Earnings</div>
            </div>
            <div className="title">
              <div className="div-20">
                <div className="color" />
                <div className="text-wrapper-43">Commission -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-2" />
                <div className="text-wrapper-43">Plan -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-products">
          <div className="pie">
            <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
          </div>
          <div className="legend">
            <div className="div-20">
              <div className="text-wrapper-42">Disbursement</div>
            </div>
            <div className="title">
              <div className="div-20">
                <div className="color" />
                <div className="text-wrapper-43">Upcoming -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-3" />
                <div className="text-wrapper-43">Due -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-4" />
                <div className="text-wrapper-43">Over due -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-products">
          <div className="pie">
            <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
          </div>
          <div className="legend">
            <div className="div-20">
              <div className="text-wrapper-42">Payments</div>
            </div>
            <div className="title">
              <div className="div-20">
                <div className="color" />
                <div className="text-wrapper-43">Receivable</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-2" />
                <div className="text-wrapper-43">Payable -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-19 mt-4">
        <div className="top-products">
          <div className="pie">
            <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
          </div>
          <div className="legend">
            <div className="div-20">
              <div className="text-wrapper-42">User onboarded</div>
            </div>
            <div className="title">
              <div className="div-20">
                <div className="color" />
                <div className="text-wrapper-43">Active -</div>
                <div className="text-wrapper-42">23</div>
              </div>
              <div className="div-20">
                <div className="color-4" />
                <div className="text-wrapper-43">Inactive -</div>
                <div className="text-wrapper-42">32</div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-products">
          <div className="pie">
            <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
          </div>
          <div className="legend-2">
            <div className="div-20">
              <div className="text-wrapper-42">Limit</div>
            </div>
            <div className="title">
              <div className="div-20">
                <div className="color-2" />
                <div className="text-wrapper-43">Available -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-3" />
                <div className="text-wrapper-43">In process -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color" />
                <div className="text-wrapper-43">Approved -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-4" />
                <div className="text-wrapper-43">Rejected -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-5" />
                <div className="text-wrapper-43">Expired -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-products">
          <div className="pie">
            <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
          </div>
          <div className="legend-2">
            <div className="div-20">
              <div className="text-wrapper-42">Finance</div>
            </div>
            <div className="title">
              <div className="div-20">
                <div className="color-2" />
                <div className="text-wrapper-43">Available -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-3" />
                <div className="text-wrapper-43">In process -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color" />
                <div className="text-wrapper-43">Approved -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-4" />
                <div className="text-wrapper-43">Rejected -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
              <div className="div-20">
                <div className="color-5" />
                <div className="text-wrapper-43">Expired -</div>
                <div className="text-wrapper-42">$ 1 million</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <ExpandableTable columns={[
          { name: "Supplier name", width: '24%' },
          { name: "Buyer name/LC no.", width: '24%' },
          { name: "Service Type", width: '24%' },
          { name: "Remark", width: '24%' },
          { name: "", width: '4%' }
        ]}
          data={[["LINTL CLOTHING PVT LTD", "Wallmart", "Invoice Limit", "Quote Recieved"]]} disableAction expand={[]} expandKey={"Date"} />
      </div>
      <div className=' h-100  ml-2 my-4'>
        <div className='align-items-center d-flex justify-content-between'>
          <div className='w-auto pr-3'>
            <label className='text-color-value font-size-14 font-wt-600'>Task Update</label>
          </div>
        </div>
        <div className='row '>
          <div className='col-md-5'>
            <label className='text-center font-wt-600 w-100 border py-2 mb-0'>Task Due</label>
            <NewTable
              disableAction={true}
              columns={[{ name: "Total" }, { name: "Hot" }, { name: "Warm" },
              { name: "Cold" }, { name: "Pending" }]}
              data={[]}
            />
          </div>
          <div className='col-md-7'>
            <label className='text-center font-wt-600 w-100 border py-2 mb-0'>Task Created</label>
            <NewTable
              disableAction={true}
              columns={[{ name: "Total" }, { name: "Hot" }, { name: "Warm" },
              { name: "Cold" }, { name: "Not Interested" }, { name: "Lost" }, { name: "Pending" }]}
              data={[]}
            />
          </div>
        </div>
      </div>
      <div className=' h-100  ml-2 my-4'>
        <div className='align-items-center d-flex justify-content-between'>
          <div className='w-auto pr-3'>
            <label className='text-color-value font-size-14 font-wt-600'>Inquiries</label>
          </div>
        </div>
        <div className='row '>
          <div className='col-md-5'>
            <label className='text-center font-wt-600 w-100 border py-2 mb-0'>Pending</label>
            <NewTable
              disableAction={true}
              columns={[{ name: "Source" }, { name: "Hot" }, { name: "Warm" },
              { name: "Cold" }]}
              data={[]}
            />
          </div>
          <div className='col-md-7'>
            <label className='text-center font-wt-600 w-100 border py-2 mb-0'>Task Created</label>
            <NewTable
              disableAction={true}
              columns={[{ name: "Hot" }, { name: "Warm" },
              { name: "Cold" }, { name: "Not Interested" }, { name: "Lost" }, { name: "Total" }]}
              data={[]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardNew
