import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import toastDisplay from '../../utils/toastNotification';
import call from '../../service';
import validate from '../../utils/validation_files/createProduct';
import FilePreview from '../utilComponents/previewFile'
import { setcreditLineDetails } from '../../store/actions/action';
import toolTipText from '../../utils/toolTips/creditLine.json';
import { formatDate_Application } from '../../utils/dateFormaters';
import ReactCountryFlag from "react-country-flag";


const CreateCreditLine = ({ userTokenDetails, type, creditLineDetails, setcreditLineDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Funding Details");
  const [secondtab, setSecondTab] = useState(0);
  const [secondtabName, setSecondTabName] = useState("Details");
  const [productList, setProductList] = useState([]);
  const [getDetail, setGetDetail] = useState({ modal: false, data: {} });
  const [eligibilityCriteria, setEligibilityCriteria] = useState([]);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    });

    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    let objectAPI = {
      "userId": userId,
      "userTypeId": userTypeId,
      "userName": userName,
      "userEmail": userEmail,
    }

    call('POST', 'getAdvanceProductList', objectAPI).then((result) => {
      setProductList(result);
    }).catch((e) => {
      console.log('error in getProductList', e);
    })

    call('POST', 'getEligibilityCriteria').then((result) => {
      console.log('running getEligibilityCriteria api-->', result);
      setEligibilityCriteria(result);
    }).catch((e) => {
      console.log("getEligibilityCriteria:", e);
    })

  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  //Port List

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    data.currency = "2:USD"
    setError(validate(data));
    setIsSubmitting(true);
  };

  const handleSubmit = function () {
    setshowLoader(true)

    data.type = type ? type : null;
    data.userId = userId
    data.userTypeId = userTypeId
    data.userEmail = userEmail


    call('POST', "insertProductDetail", data).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }


  // JSX body
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="pt-2 pb-5 mt-2">

        {type != 'user' &&
          <div className="mb-2 ">
            <h3 className=" border-bottom pb-2">Create Product</h3>
          </div>}

        {type != 'user' &&
          <br />}

        <div className="creditline_listing">
          <ul className="nav nav-tabs-custom-2 mb-3" id="myTab" role="tablist">
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                setTab(0);
                setTabName("Funding Details");
              }}>Funding Details</a>
            </li>
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
                setTabName("Interest Details");
              }}>Interest Details</a>
            </li>
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                setTab(2);
                setTabName("Finance Request Step Fees");
              }}>Finance Request Step Fees</a>
            </li>
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 3 ? " active show" : "")} onClick={() => {
                setTab(3);
                setTabName("Other T & C");
              }}>Other T & C</a>
            </li>
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 4 ? " active show" : "")} onClick={() => {
                setTab(4);
                setTabName("Required Documents");
              }}>Required Documents</a>
            </li>
          </ul>

          <>
            {tab === 0 &&
              <>
                <div className="col-md-12">
                  <div className="row ">

                    <div className="form-group col-md-6">
                      <label>Product Name {astrix} </label>
                      <input type="text" className={" form-control" + (!error.productName ? '' : ' border-danger')} placeholder="" name="productName" value={data.productName} onChange={handleChange} />
                      {error.productName && <p className="text-danger error-contract">{error.productName}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Approved Finance Amount (in USD) {astrix} </label>
                      <input type="number" className={" form-control" + (!error.approvedFinanceAmount ? '' : ' border-danger')} placeholder="" name="approvedFinanceAmount" value={data.approvedFinanceAmount} onChange={handleChange} />
                      {error.approvedFinanceAmount && <p className="text-danger error-contract">{error.approvedFinanceAmount}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Funding Percentage(%) {astrix} </label>
                      <input type="number" className={" form-control" + (!error.fundingPercentage ? '' : ' border-danger')} placeholder="" name="fundingPercentage" value={data.fundingPercentage} onChange={handleChange} />
                      {error.fundingPercentage && <p className="text-danger error-contract">{error.fundingPercentage}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Funding Tenor (in Years) {astrix}</label>
                      <input type="number" className={" form-control" + (!error.fundingTenor ? '' : ' border-danger')} placeholder="" name="fundingTenor" value={data.fundingTenor} onChange={handleChange} />
                      {error.fundingTenor && <p className="text-danger error-contract">{error.fundingTenor}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Minimum Invoice Value (in USD) {astrix}</label>
                      <input type="number" className={" form-control" + (!error.minInvoice ? '' : ' border-danger')} placeholder="" name="minInvoice" value={data.minInvoice} onChange={handleChange} />
                      {error.minInvoice && <p className="text-danger error-contract">{error.minInvoice}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Maximum Credit Term (in days) {astrix}</label>
                      <input type="number" className={" form-control" + (!error.maxCredit ? '' : ' border-danger')} placeholder="" name="maxCredit" value={data.maxCredit} onChange={handleChange} />
                      {error.maxCredit && <p className="text-danger error-contract">{error.maxCredit}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Minimum Credit Term (in days) {astrix}</label>
                      <input type="number" className={" form-control" + (!error.minCredit ? '' : ' border-danger')} placeholder="" name="minCredit" value={data.minCredit} onChange={handleChange} />
                      {error.minCredit && <p className="text-danger error-contract">{error.minCredit}</p>}
                    </div>

                  </div>
                </div>
              </>
            }

            {tab === 1 &&
              <>
                <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group col-md-6">
                      <label>Interest Rate {astrix} </label>
                      <input type="number" className={" form-control" + (!error.interestRate ? '' : ' border-danger')} placeholder="" name="interestRate" value={data.interestRate} onChange={handleChange} />
                      {error.interestRate && <p className="text-danger error-contract">{error.interestRate}</p>}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-md-12">Interest Collection {astrix} </label>
                      <div className="col-md-12">
                        <select className="form-control" name="interestCollection" value={data.interestCollection} onChange={handleChange} >
                          <option value="" selected>Select any one</option>
                          <option value="Deducted upfront">Deducted upfront</option>
                          <option value="Later">Later</option>
                        </select>
                      </div>
                      {error.interestCollection && <p className="text-danger error-contract">{error.interestCollection}</p>}
                    </div>

                  </div>
                </div>
              </>
            }

            {tab === 2 &&
              <>
                <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group col-md-6">
                      <label>Percentage {astrix} </label>
                      <input type="number" className={" form-control" + (!error.finRequestPercentage ? '' : ' border-danger')} placeholder="" name="finRequestPercentage" value={data.finRequestPercentage} onChange={handleChange} />
                      {error.finRequestPercentage && <p className="text-danger error-contract">{error.finRequestPercentage}</p>}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-md-12">Collection {astrix} </label>
                      <div className="col-md-12">
                        <select className="form-control" name="finRequestCollection" value={data.finRequestCollection} onChange={handleChange} >
                          <option value="" selected>Select any one</option>
                          <option value="Upfront">Upfront</option>
                          <option value="Deductible in first transaction">Deductible in first transaction</option>
                        </select>
                      </div>
                      {error.finRequestCollection && <p className="text-danger error-contract">{error.finRequestCollection}</p>}
                    </div>
                  </div>
                </div>
              </>
            }

            {tab === 3 &&
              <>
                <div className="col-md-12">
                  <div className="row ">

                    <div className="form-group col-md-6">
                      <label>Sanction Country {astrix} </label>

                      <select className={" form-control" + (!error.sanctionCountry ? '' : ' border-danger')} name="sanctionCountry" value={data.sanctionCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                        <option value="" selected>--Select country--</option>
                        {/* <CountryList /> */}
                        {
                          countryData.length ? countryData.map((item, index) => {
                            return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                          }) : ''
                        }
                      </select>
                      {error.sanctionCountry && <p className="text-danger error-contract">{error.sanctionCountry}</p>}

                    </div>

                    <div className="form-group col-md-6">
                      <label>Sanction Port {astrix} </label>

                      <select className={" form-control" + (!error.sanctionPort ? '' : ' border-danger')} name="sanctionPort" value={data.sanctionPort} onChange={handleChange}>
                        <option value="" selected>--Select loading port--</option>
                        {portLoading.map((portLoading) => {
                          return (
                            <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                          )
                        })}
                      </select>
                      {error.sanctionPort && <p className="text-danger error-contract">{error.sanctionPort}</p>}

                    </div>

                    <div className="form-group col-md-6">
                      <label>Commodities {astrix} </label>
                      <input type="text" className={" form-control" + (!error.commodities ? '' : ' border-danger')} placeholder="" name="commodities" value={data.commodities} onChange={handleChange} />
                      {error.commodities && <p className="text-danger error-contract">{error.commodities}</p>}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Currency {astrix} </label>
                      <input type="text" className={" form-control" + (!error.currency ? '' : ' border-danger')} placeholder="" name="currency" value="Dollars ( USD ) ( $ )" onChange={handleChange} />
                      {error.currency && <p className="text-danger error-contract">{error.currency}</p>}
                    </div>

                    {/* <div className="form-group col-md-6">
                        <label>Currency {astrix} </label>
                        <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency} onChange={handleChange} >
                          <option value="" selected>--- Select Country ---</option>
                          {currencyData.map((currency) => {
                            return (
                              <option value={currency.id + ':' + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                            )
                          })}
                        </select>
                        {error.currency && <p className="text-danger error-contract">{error.currency}</p>}
                      </div> */}

                    <div className="form-group col-md-6">
                      <label>Offer Valid Till {astrix} </label>
                      <input type="date" className={" form-control" + (!error.offerValidity ? '' : ' border-danger')} placeholder="" name="offerValidity" value={data.offerValidity} onChange={handleChange} />
                      {error.offerValidity && <p className="text-danger error-contract">{error.offerValidity}</p>}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
              </>
            }

            {tab === 4 &&
              <>
                <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group col-md-4">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="invoice" id="invoice" onChange={hanleDocChecks} checked={data.invoice} />
                        <label className="form-check-label" for="invoice">Invoice</label>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="packingList" id="packingList" onChange={hanleDocChecks} checked={data.packingList} />
                        <label className="form-check-label" for="packingList">Packing List</label>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="billOfLading" id="billOfLading" onChange={hanleDocChecks} checked={data.billOfLading} />
                        <label className="form-check-label" for="billOfLading">Bill of lading/ Airway bill</label>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="inspectionCertificate" id="inspectionCertificate" onChange={hanleDocChecks} checked={data.inspectionCertificate} />
                        <label className="form-check-label" for="inspectionCertificate">Inspection Certificate</label>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="insuranceCopy" id="insuranceCopy" onChange={hanleDocChecks} checked={data.insuranceCopy} />
                        <label className="form-check-label" for="insuranceCopy">Insurance Copy</label>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <div className="form-check pl-0 mt-1 mb-1">
                        <input className="form-check-input" type="checkbox" name="mandatoryDoc" id="mandatoryDoc" onChange={hanleDocChecks} checked={data.mandatoryDoc} />
                        <label className="form-check-label" for="mandatoryDoc">Any other mandatory doc</label>
                      </div>
                    </div>

                  </div>
                </div>

                <button type="button" className="btn btn-success btn-sm" onClick={() => preHandleSubmit()} >Submit</button>
              </>
            }
          </>
          <>
            <hr />
            <div className="text-center">
              <button type="button" className="btn btn-info btn-sm mr-2" onClick={tab > 0 ? () => setTab(tab - 1) : ''} id="previd"><i class="fa fa-angle-left" style={{ width: "25px" }} aria-hidden="true"></i></button>
              <button type="button" className="btn btn-info btn-sm ml-2" onClick={tab < 4 ? () => setTab(tab + 1) : ''} id="nextid"><i class="fa fa-angle-right" style={{ width: "25px" }} aria-hidden="true"></i></button>
            </div>
          </>
        </div>

        {type == 'user' &&
          <>
            <hr />
            <div className="table-responsive" style={{ "min-height": "150px" }}>
              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr style={{ fontSize: "14px" }}>
                    <th width="20">#</th>
                    <th width="300">Product Name</th>
                    <th width="300">Amount</th>
                    <th width="300">Interest Rate</th>
                    <th width="300">Funding Tenor</th>
                    <th width="300">Minimum Invoice Value</th>
                    <th width="300">Maximum Credit Term</th>
                    <th width="300">Minimum Credit Term</th>
                    <th width="150">Valid upto</th>
                    <th width="300">Actions</th>
                  </tr>
                </thead>

                {productList.map((values, key) => {
                  return (
                    <tbody>
                      <tr style={{ fontSize: "14px" }}>
                        <td >{key + 1}</td>
                        <td>{values.productName}</td>
                        <td >Upto {values.approvedFinanceAmount + ' ' + values.currency.split(':')[1]}</td>
                        <td >{values.interestRate}%</td>
                        <td >{values.fundingTenor} Years</td>
                        <td >{values.minInvoice + ' ' + values.currency.split(':')[1]}</td>
                        <td >{values.maxCredit} Days</td>
                        <td >{values.minCredit} Days</td>
                        <td >{formatDate_Application(values.offerValidity)}</td>
                        <td>
                          <button type="button" className="btn btn-success btn-sm" onClick={() => setGetDetail({ modal: true, data: { id: values.id } })}>Get Detail</button>
                        </td>
                      </tr>
                    </tbody>
                  )
                })}

              </table>
            </div>
          </>
        }

        {getDetail.modal &&
          productList.map((values) => {
            if (values.id == getDetail.data.id) {
              return (
                <div className={"modal" + (getDetail.modal ? " show" : "")} id="ApplyCredit">
                  <div className="modal-dialog modal-lg border-inner" id="parent_class">
                    <div className="modal-content">
                      <div className="modal-header primary">
                        <h3 className="modal-title text-white">Product Details</h3>
                        <button type="button" className="close" onClick={() => { setGetDetail({ modal: false, data: {} }) }}>×</button>
                      </div>
                      <div className="modal-body calc-modal">
                        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                        <div className="calc-inner-modal">

                          <div className="creditline_listing">
                            <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                              <li>
                                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (secondtab === 0 ? " active show" : "")} onClick={() => {
                                  setSecondTab(0);
                                  setSecondTabName("Details");
                                }}>Details</a>
                              </li>
                              <li>
                                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (secondtab === 1 ? " active show" : "")} onClick={() => {
                                  setSecondTab(1);
                                  setSecondTabName("Eligibility Criteria");
                                }}>Eligibility Criteria</a>
                              </li>
                            </ul>

                            <>
                              {secondtab === 0 &&
                                <>
                                  {/* a Section */}
                                  {/* Funding Details */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Funding Details</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  <ul className="price-ul">
                                                    <li>
                                                      <div className="price-left">{values.productName}</div>
                                                      <div className="price-right">Product Name</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.approvedFinanceAmount}</div>
                                                      <div className="price-right">Approved Finance Amount</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.fundingPercentage}%</div>
                                                      <div className="price-right">Funding Percentage(%)</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.fundingTenor} Years</div>
                                                      <div className="price-right">Funding Tenor</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.minInvoice} Years</div>
                                                      <div className="price-right">Minimum Invoice Value</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.maxCredit} Years</div>
                                                      <div className="price-right">Maximum Credit Term</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.minCredit} Years</div>
                                                      <div className="price-right">Minimum Credit Term</div>
                                                    </li>
                                                  </ul>

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* b section */}
                                  {/* Interest Details */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Interest Details</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  <ul className="price-ul">
                                                    <li>
                                                      <div className="price-left">{values.interestRate}</div>
                                                      <div className="price-right">Interest Rate</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.interestCollection}</div>
                                                      <div className="price-right">Interest Collection</div>
                                                    </li>
                                                  </ul>

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* c section */}
                                  {/* Finance Request Step Fees */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Finance Request Step Fees</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  <ul className="price-ul">
                                                    <li>
                                                      <div className="price-left">{values.finRequestPercentage}%</div>
                                                      <div className="price-right">Percentage</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.finRequestCollection}</div>
                                                      <div className="price-right">Collection</div>
                                                    </li>
                                                  </ul>

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* d section */}
                                  {/* Other T&C */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Other T&C</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  <ul className="price-ul">
                                                    <li>
                                                      <div className="price-left">{values.sanctionCountry ? <span className="shadow">
                                                        <ReactCountryFlag
                                                          countryCode={values.sanctionCountry.split(':')[0]}
                                                          style={{ width: '25px', height: '25px' }} svg />
                                                      </span> : ''}</div>
                                                      <div className="price-right">Sanction Country</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.sanctionPort}</div>
                                                      <div className="price-right">Sanction Port</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.commodities}</div>
                                                      <div className="price-right">Commodities</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{values.currency.split(':')[1]}</div>
                                                      <div className="price-right">Currency</div>
                                                    </li>
                                                    <li>
                                                      <div className="price-left">{formatDate_Application(values.offerValidity)}</div>
                                                      <div className="price-right">Offer Valid Till</div>
                                                    </li>
                                                  </ul>

                                                </div>

                                                <br />
                                                <br />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* e section */}
                                  {/* Required Documents */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Required Documents</h3>
                                              <hr />
                                            </div>

                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  <ul className="price-ul">
                                                    <li>
                                                      {values.requiredDocuments.split(':').map((docs) => {
                                                        return (
                                                          <div className="price-left">{docs}</div>
                                                        )
                                                      })}
                                                    </li>
                                                  </ul>

                                                </div>
                                                <br />
                                                <br />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </>
                              }

                              {secondtab === 1 &&
                                <>
                                  {/* Onboarding */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Onboarding</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  {eligibilityCriteria &&
                                                    <ul className="price-ul">
                                                      {eligibilityCriteria.supplierProfile != null &&
                                                        <li>
                                                          <div className="price-left">Supplier Profile</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.auditedFin != null &&
                                                        <li>
                                                          <div className="price-left">Audited Financials</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.receivableBuyer != null &&
                                                        <li>
                                                          <div className="price-left">Accounts receivables ageing as on date,showing receivables from all buyers</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.buyersClient != null &&
                                                        <li>
                                                          <div className="price-left">List of buyers that client wants to work with us in the attached format</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.email != null &&
                                                        <li>
                                                          <div className="price-left">Email (gmail & yahoo, hotmail wont be accepted for legal framework)</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.dunsNo != null &&
                                                        <li>
                                                          <div className="price-left">DUNS NO (optional)</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.website != null &&
                                                        <li>
                                                          <div className="price-left">Webiste</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.minBusiness != null &&
                                                        <li>
                                                          <div className="price-left">Minimum Exisiting business with buyer (in USD)</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.maxBusiness != null &&
                                                        <li>
                                                          <div className="price-left">Minimum Expected Business with buyer (in USD)</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.newBuyer != null &&
                                                        <li>
                                                          <div className="price-left">New Buyer are Accepted</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.soleProprietorship != null &&
                                                        <li>
                                                          <div className="price-left">Sole Proprietorship</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.partnership != null &&
                                                        <li>
                                                          <div className="price-left">Partnership</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.pvtPubLtd != null &&
                                                        <li>
                                                          <div className="price-left">PVT/PUB.LTD</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.LLP != null &&
                                                        <li>
                                                          <div className="price-left">LLP</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.apparelTextile != null &&
                                                        <li>
                                                          <div className="price-left">Apparels & Textile</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.industrialMech != null &&
                                                        <li>
                                                          <div className="price-left">Industrial & Mechanical</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.foodBeverage != null &&
                                                        <li>
                                                          <div className="price-left">Food & Beverage</div>
                                                        </li>
                                                      }
                                                    </ul>
                                                  }

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Finance Available For */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Finance Available For</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  {eligibilityCriteria &&
                                                    <ul className="price-ul">
                                                      {eligibilityCriteria.soleProprietorship != null &&
                                                        <li>
                                                          <div className="price-left">Sole Proprietorship</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.partnership != null &&
                                                        <li>
                                                          <div className="price-left">Partnership</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.pvtPubLtd != null &&
                                                        <li>
                                                          <div className="price-left">PVT/PUB.LTD</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.LLP != null &&
                                                        <li>
                                                          <div className="price-left">LLP</div>
                                                        </li>
                                                      }
                                                    </ul>
                                                  }

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Eligible Industry */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Eligible Industry</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  {eligibilityCriteria &&
                                                    <ul className="price-ul">
                                                      {eligibilityCriteria.apparelTextile != null &&
                                                        <li>
                                                          <div className="price-left">Apparels & Textile</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.industrialMech != null &&
                                                        <li>
                                                          <div className="price-left">Industrial & Mechanical</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.foodBeverage != null &&
                                                        <li>
                                                          <div className="price-left">Food & Beverage</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.gamingMedia != null &&
                                                        <li>
                                                          <div className="price-left">Gaming & Media</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.electronicConsumer != null &&
                                                        <li>
                                                          <div className="price-left">Electronics & Consumer Goods</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.eCommerce != null &&
                                                        <li>
                                                          <div className="price-left">E-Commerce</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.fmcg != null &&
                                                        <li>
                                                          <div className="price-left">FMCG</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.medicalPharma != null &&
                                                        <li>
                                                          <div className="price-left">Medical & Pharmaceutical</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.auto != null &&
                                                        <li>
                                                          <div className="price-left">Auto</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.frozenFood != null &&
                                                        <li>
                                                          <div className="price-left">Frozen Foods</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.argo != null &&
                                                        <li>
                                                          <div className="price-left">AGRO</div>
                                                        </li>
                                                      }
                                                    </ul>
                                                  }

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Restricted Industry */}

                                  <div className="accordionWrapper ">
                                    <div className="container-fluid accordionItem open">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h3 className="green mt-3">Restricted Industry</h3>
                                              <hr />
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="form-group col-md-12">

                                                  {eligibilityCriteria &&
                                                    <ul className="price-ul">
                                                      {eligibilityCriteria.govtEntity != null &&
                                                        <li>
                                                          <div className="price-left">Buyer/Supplier Government Entity</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.oilGas != null &&
                                                        <li>
                                                          <div className="price-left">Oil/Gas</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.diamond != null &&
                                                        <li>
                                                          <div className="price-left">Diamond</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.preciousStone != null &&
                                                        <li>
                                                          <div className="price-left">Precious Stone</div>
                                                        </li>
                                                      }
                                                      {eligibilityCriteria.gold != null &&
                                                        <li>
                                                          <div className="price-left">Gold</div>
                                                        </li>
                                                      }
                                                    </ul>
                                                  }

                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                            </>

                          </div>

                        </div>
                      </div>
                      <div className="modal-footer primary">
                        <button type="button" className="btn btn-default btn-sm" onClick={secondtab > 0 ? () => setSecondTab(secondtab - 1) : ''} id="previd">Back</button>
                        <button type="button" className="btn btn-info btn-sm" onClick={secondtab < 1 ? () => setSecondTab(secondtab + 1) : ''} id="nextid">Next</button>
                        <button type="button" className="btn btn-info btn-sm" onClick={() => setGetDetail({ modal: false, data: {} })} >Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })
        }

      </div>
    </>
  );
};

// Redux
// Getting the global state from redux store.
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    creditLineDetails: state.creditLineDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setcreditLineDetails: (status, info) => { dispatch(setcreditLineDetails({ status: status, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(mapStateToProps, mapDispatchToProps)(CreateCreditLine);
