import React, { useEffect, useState } from 'react';
import {
  AreaChart, Area, BarChart, Bar, PieChart, Pie, Cell, Tooltip, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer,
} from 'recharts';
import NewTablev2 from '../../../utils/newTablev2'
import PaginatedTable from './pagesTable';
import TableWithPages from './tableWithArrows';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// const COLORS = ['rgb(232, 175, 123)', 'rgb(152, 188, 222)', 'rgb(253, 182, 1)', 'rgb(46, 204, 113)', 'rgb(255, 123, 109)'];
const COLORS = [
  'rgb(209, 157, 110)',
  'rgb(136, 169, 199)',
  'rgb(227, 163, 0)',
  'rgb(41, 183, 101)',
  'rgb(229, 110, 98)'
]

// const COLORS = [
//   'rgb(185, 140, 98)',
//   'rgb(121, 150, 177)',
//   'rgb(202, 145, 0)',
//   'rgb(37, 163, 90)',
//   'rgb(204, 98, 87)'
// ];

const tooltipStyle = {
  borderRadius: '5px',
  // padding: '5px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #ccc',
};
const formatLabel = (label) => {
  if (typeof label !== 'string') return label;
  label = label.toLowerCase();
  return label
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^[a-z]/, str => str.toUpperCase())
    .replace(/\b\w/g, str => str.toUpperCase())
};
const CustomTooltip = ({ active, payload, label }) => {
  // console.log(payload);
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip p-2" style={tooltipStyle}>
        <p className='text-warning'>{formatLabel(label)}</p>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="d-flex justify-content-between p-0 m-0">
            <p className=''>{formatLabel(entry.name)}:</p>
            <p className=''>{entry.value}</p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};




const Charts = ({ selectedChart, selectedData, dropdownValue, setIdForDiv, customStyles = {}, customStyles0 = {} }) => {
  console.log(" Drop:", dropdownValue, selectedData)
  const data = selectedData ? selectedData.filter(i => i !== null) : [];
  // const fieldName= dropdownValue
  const [tableHeader, setTableHeader] = useState("")
  // const [idForDiv, setIdForDiv] = useState('')
  const columns = [
    { field_Name: tableHeader, subColumns: dropdownValue, subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "rate", subColumns: 'Rate $', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "QtyWithUnits", subColumns: 'Quantity', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "taxPaid", subColumns: 'Tax Paid $', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "taxLess", subColumns: 'Tax Less $', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "ASPTaxPaid", subColumns: 'ASP TaxPaid $', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "ASPTaxLess", subColumns: 'ASP TaxLess $', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "totalAmount", subColumns: 'Total Amount $', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },
    { field_Name: "contribution", subColumns: 'Cont %', subColumnClass: '', subColumnStyle: {}, thwidth: '', subColumnsOnClick: null },

  ];
  const [filteredColumns, setfilteredColumns] = useState(columns)
  const [areaX, setAreaX] = useState(null);
  const [areaY, setAreay] = useState(null);
  const [barX, setBarX] = useState(null);
  const [bar1, setBar1] = useState(null);
  const [bar2, setBar2] = useState(null);
  const [pieX, setPieX] = useState(null);
  const [pieY, setPieY] = useState(null);


  useEffect(() => {
    // console.log(dropdownValue)
    let header;
    switch (dropdownValue) {
      case "Item Name":
        // setFieldName("Item Name");
        setAreaX("itemName");
        setAreay("totalAmount");
        setBarX("itemName");
        setBar1("rate");
        setBar2("quantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("itemName");
        header = "itemName"
        break;
      case "State":
        // setFieldName("State Name");
        setAreaX("state");
        setAreay("totalAmount");
        setBarX("state");
        setBar1("totalAmount");
        setBar2("totalQuantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("state");
        header = "state"
        break;
      case "Refference Number":
        // setFieldName("Invoice Number");
        setAreaX("invoiceNumber");
        setAreay("totalAmount");
        setBarX("invoiceNumber");
        setBar1("totalAmount");
        setBar2("quantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("invoiceNumber");
        header = "invoiceNumber"
        break;
      case "Customer Name":
        // setFieldName("Customer Name");
        setAreaX("partyName");
        setAreay("totalAmount");
        setBarX("partyName");
        setBar1("totalAmount");
        setBar2("quantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("partyName");
        header = "partyName"
        break;
      case "Continent":
        // setFieldName("Customer Name");
        setAreaX("continent");
        setAreay("totalAmount");
        setBarX("continent");
        setBar1("totalAmount");
        setBar2("quantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("continent");
        header = "continent"
        break;
      case "Items Group":
        // setFieldName("Customer Name");
        setAreaX("itemGroup");
        setAreay("totalAmount");
        setBarX("itemGroup");
        setBar1("totalAmount");
        setBar2("quantity");
        console.log(customStyles.dataToShow)
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("itemGroup");
        header = "itemGroup"
        break;
      case "Customer Group":
        // setFieldName("Customer Name");
        setAreaX("customerGroup");
        setAreay("totalAmount");
        setBarX("customerGroup");
        setBar1("totalAmount");
        setBar2("quantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("customerGroup");
        header = "customerGroup"
        break;
      case "Item Category":
        // setFieldName("Customer Name");
        setAreaX("category");
        setAreay("totalAmount");
        setBarX("category");
        setBar1("totalAmount");
        setBar2("quantity");
        setPieX(customStyles.dataToShow ? customStyles.dataToShow : "contribution");
        setPieY("category");
        header = "category"
        break;
      default:
        header = "Name"
    }
    setTableHeader(header)

  }, [dropdownValue]);

  useEffect(() => {
    if (selectedData.length) {
      const filtered = columns.map(column => ({
        ...column,
        field_Name: column.field_Name === tableHeader ? tableHeader : column.field_Name,
        subColumns: column.field_Name === tableHeader ? dropdownValue : column.subColumns
      })).filter(column => selectedData[0].hasOwnProperty(column.field_Name));

      setfilteredColumns(filtered);
    }
  }, [tableHeader, selectedData, dropdownValue]);


  const CustomLegend = ({ payload }) => {
    // console.log(payload)
    return (
      <div className="custom-scrollbar" style={customStyles0 ? customStyles0 : ""} >
        <ul className='me-2' >
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${formatLabel(entry.value)}: ${formatLabel(entry.payload[pieX])}`}
            </li>
          ))}
        </ul>
      </div>
    );
  };


  const renderChart = () => {
    switch (selectedChart) {
      case 'Area Chart':
        return (
          <ResponsiveContainer id='areachart' width="100%" height={500}>
            <AreaChart data={data}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="1" x2="1" y2="0">
                  <stop offset="0%" stopColor={COLORS[4]} stopOpacity={0.8} />
                  <stop offset="100%" stopColor={COLORS[4]} stopOpacity={0.8} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={areaX} />

              <YAxis label={{
                value: 'Total Amount($)',
                angle: -90,
                position: 'insideLeft',
                offset: 0,
                fill: '#666'
              }} />
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend align="right" verticalAlign="top" layout="vertical" /> */}
              <Area type="monotone" dataKey={areaY} stroke={COLORS[4]} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        );
      case 'Bar Chart':
        return (
          <ResponsiveContainer id='barchart' width="100%" height={500}>
            <BarChart data={selectedData} layout="vertical" >
              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis dataKey={barX} />
              <YAxis label={{
                value: 'Total Amount($)',
                angle: -90,
                position: 'insideLeft',
                offset: 0,
                fill: '#666'
              }} /> */}
              <XAxis type="number" label={{
                value: 'Total Amount($)',
                angle: 0,
                position: 'insideBottom',
                offset: -5,
                fill: '#666'
              }} />
              <YAxis type="category" dataKey={barX} width={80}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar dataKey={bar1} fill={COLORS[1]} />
              <Bar dataKey={bar2} fill={COLORS[2]} />
              {/* <Legend align="right" verticalAlign="top" layout="horizontal" content={<CustomLegend />} /> */}

            </BarChart>
          </ResponsiveContainer>
        );
      case 'Pie Chart':

        return (
          <ResponsiveContainer id='piechart' width="100%" height={customStyles?.heightOfDiv ? customStyles.heightOfDiv : 500}>
            <PieChart>
              <Pie
                data={data}
                dataKey={pieX}
                nameKey={pieY}
                cx="50%"
                cy="50%"
                outerRadius={customStyles?.outerRadius ? customStyles.outerRadius : 160}
                fill="#8884d8"

              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend align={customStyles.align ? customStyles.align : "right"} verticalAlign={customStyles.verticalAlign ? customStyles.verticalAlign : "bottom"} layout="vertical" content={<CustomLegend />} />
            </PieChart>
          </ResponsiveContainer>
        );
      case 'Donut Chart':

        return (
          <ResponsiveContainer id='piechart' width="100%" height={customStyles?.heightOfDiv ? customStyles.heightOfDiv : 500}>
            <PieChart>
              <Pie
                data={data}
                dataKey={pieX}
                nameKey={pieY}
                cx="50%"
                cy="50%"
                innerRadius={customStyles?.innerRadius ? customStyles.innerRadius : 90}
                outerRadius={customStyles?.outerRadius ? customStyles.outerRadius : 160}
                fill="#8884d8"

              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend align={customStyles.align ? customStyles.align : "right"} verticalAlign={customStyles.verticalAlign ? customStyles.verticalAlign : "bottom"} layout="vertical" content={<CustomLegend />} />

            </PieChart>
          </ResponsiveContainer>
        );
      case 'Table View':


        return (

          <PaginatedTable
            id='tableview'
            data={data}
            columns={filteredColumns}
            tableHeader={tableHeader}
            selectedData={selectedData}
            freezeFirstColumn={true}
          />
        );

      // return (
      //   <NewTablev2 className="overflow-auto" columns={filteredColumns}>
      //     {data.map((row, index) => (
      //       <tr key={index}>
      //         <td style={{ padding: '20px !important' }}>{row[tableHeader]}</td>
      //         {selectedData[0].hasOwnProperty("rate") && <td>{row.rate}</td>}
      //         {selectedData[0].hasOwnProperty("quantity") && <td>{row.quantity}</td>}
      //         {selectedData[0].hasOwnProperty("taxPaid") && <td>{row.taxPaid}</td>}
      //         {selectedData[0].hasOwnProperty("taxLess") && <td>{row.taxLess}</td>}
      //         {selectedData[0].hasOwnProperty("ASPTaxPaid") && <td>{row.ASPTaxPaid}</td>}
      //         {selectedData[0].hasOwnProperty("ASPTaxLess") && <td>{row.ASPTaxLess}</td>}
      //         {selectedData[0].hasOwnProperty("totalAmount") && <td>{row.totalAmount}</td>}
      //         {selectedData[0].hasOwnProperty("contribution") && <td>{row.contribution}</td>}

      //       </tr>
      //     ))}

      //   </NewTablev2>

      // );
      default:
        return <></>;
    }
  };

  return (
    <>
      {data && data.length ? <div id='graphOrTable' style={{ backgroundColor: 'white', position: "relative", height: customStyles?.heightOfDiv ? customStyles.heightOfDiv : "" }}>
        {/* <button onClick={handleDownload} className="bg-white rounded" style={{ border: "0.5px solid grey" }}><img src='assets/images/new_dowd_icon.svg' /></button> */}

        {renderChart()}
      </div> : <p className='text-warning'>No Data Found</p>}
    </>

  );
};

export default Charts;




