
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"
import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
// import { reviewBG, reviewBusiness, reviewOther } from "./BGGetConfirmation";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import moment from 'moment';
import { ONDCSUBSCRIBERID, platformBackendUrl } from "../../urlConstants";
import axios from 'axios'
import { htmlFormTemplate, ondcFirstSelectObj, ondcInitialSearchObj, ondcSecondSearchObj } from "./ondcSampleObjects";


const GSTBasedInvoiceFinanceProductCatalogue = ({ userTokenDetails, navToggleState }) => {

  const [showLoader, setshowLoader] = useState(false)
  const [quotes, setQuotes] = useState([])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const quoteCardRef = useRef([])

  const queryParams = new URLSearchParams(window.location.search)
  let submissionId = queryParams.get('submissionId') ? decodeURIComponent(queryParams.get('submissionId')) : null

  let offerData = localStorage.getItem('offerData')
  offerData = JSON.parse(offerData)

  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("gstBasedInvoiceFinanceProductCatalogue");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  useEffect(() => {
    if (submissionId) {
      loadQuotes()
    }
    else {
      toastDisplay('Invalid link', "error")
    }
  }, [submissionId])

  async function loadQuotes() {
    setshowLoader(true)

    let INVOICE_NUMBER = offerData?.message?.catalog?.providers?.[0]?.items?.[0]?.tags[0]?.list?.filter((i, j) => {
      if (i?.descriptor?.code === 'INVOICE_NUMBER') {
        return true
      }
    })?.[0]?.value
    let ondcSearchObjWithSubmissionId = ondcSecondSearchObj

    ondcSearchObjWithSubmissionId["context"]["transaction_id"] = `ONDCTXN-${new Date().getTime()}`
    ondcSearchObjWithSubmissionId["context"]["message_id"] = `ONDCMSG-${new Date().getTime()}`
    ondcSearchObjWithSubmissionId["context"]["timestamp"] = moment()
    ondcSearchObjWithSubmissionId["context"]["bpp_id"] = offerData?.context?.bpp_id
    ondcSearchObjWithSubmissionId["context"]["bpp_uri"] = offerData?.context?.bpp_uri

    ondcSearchObjWithSubmissionId["message"]["intent"]["provider"] = {
      "id": offerData?.message?.catalog?.providers?.[0]?.id,
      "items": [
        {
          "id": INVOICE_NUMBER,
          "xinput": {
            "form": {
              "id": offerData?.message?.catalog?.providers?.[0]?.items?.[0]?.xinput?.form?.id
            },
            "form_response": {
              "status": "SUCCESS",
              "submission_id": submissionId
            }
          }
        }
      ]
    }

    call('POST', 'searchGSTBasedInvoiceOffers', {
      "ondc": ondcSearchObjWithSubmissionId,
      // searchUrl: offerData?.context?.bpp_uri
    }).then(res => {
      setshowLoader(false)
      if (res?.length) {
        setQuotes(res)
      }
      else {
        toastDisplay("Failed to load offers", "error")
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay("Failed to load offers", "error")
    })
  }

  async function selectQuote(quote) {
    // setshowLoader(true)

    // let ondcSelectObj = ondcFirstSelectObj
    // ondcSelectObj["context"]["transaction_id"] = `ONDCTXN-${new Date().getTime()}`
    // ondcSelectObj["context"]["message_id"] = `ONDCMSG-${new Date().getTime()}`
    // ondcSelectObj["context"]["timestamp"] = moment()
    // ondcSelectObj["context"]["bpp_id"] = quote?.context?.bpp_id
    // ondcSelectObj["context"]["bpp_uri"] = quote?.context?.bpp_uri
    // ondcSelectObj["message"]["intent"]["provider"] = {
    //   "id": quote?.message?.catalog?.providers?.[0]?.id,
    //   "items": [
    //     {
    //       "id": "CHILD_ITEM_ID_INVOICE_LOAN",
    //       "parent_item_id": "ITEM_ID_INVOICE_LOAN",
    //       "xinput": {
    //         "form": {
    //           "id": formId
    //         },
    //         "form_response": {
    //           "status": "SUCCESS",
    //           "submission_id": submissionId
    //         }
    //       }
    //     }
    //   ]
    // }

    // call('POST', 'selectGSTBasedInvoiceOffers', {
    //   "ondc": ondcSelectObj
    // }).then(res => {
    //   setshowLoader(false)
    //   if (res?.length) {
    //     setQuotes(res)
    //   }
    //   else {
    //     toastDisplay("Failed to load offer", "error")
    //   }
    // }).catch(err => {
    //   setshowLoader(false)
    //   toastDisplay("Failed to load offer", "error")
    // })
  }

  console.log("quotessssssssssssssssssssssssssss", quotes);

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {/* <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} /> */}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state={"otherFingstBasedInvoiceFinanceProductCatalogue"} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > GST Based Invoice Finance >  Product Catalogue"}
              userTokenDetails={userTokenDetails} />
            <div className='px-2 pt-4 pb-4 '>
              <div className="lineheight19">
                {/* <label className='font-wt-500 font-size-16 mb-0 letter-spacing05'><u>Select Offer </u></label> */}
                <span className="font-size-15 font-wt-600">{` (By clicking on the 'Select Offer' button, the respective financier's form will open for the further process.)`}</span><br />
              </div>
              <div id={"scrollContainerId"} style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className="row align-items-center d-flex w-100 my-4">
                {quotes?.length ? quotes.map((key, index) => {
                  return (
                    <div
                      ref={(el) => quoteCardRef.current[index] = el}
                      className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                      <div className={`text-center card py-4 pb-0 px-3 selectfinancercardrec`}
                      >
                        <>
                          <p className={`cursor ${recommendedFinanciers.includes(key.lender_id / 1) ? ' mt-5 ' : ' mt-1 '} pt-2 mb-1 d-flex flex-row justify-content-center align-items-center`}><img className=""
                            style={{ width: '5rem' }} src={key.message?.catalog?.providers?.[0]?.descriptor?.images?.[0]?.url} alt="" />
                          </p>
                          <label className="font-size-16 mt-3 font-wt-600 text-dark">{key.message?.catalog?.descriptor?.name}</label>
                        </>
                        {(
                          <>
                            <div className="mt-2 lineheight19">
                              {/* <p className="font-size-14 mb-1">
                                {`Offer Validity : ${key.offerValidity}`}</p> */}
                              <p className="mb-2" style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                            </div>
                            {key.message?.catalog?.providers?.[0]?.items?.[0]?.tags?.[0]?.list?.length ?
                              key.message?.catalog?.providers?.[0]?.items?.[0]?.tags[0].list.map((o, p) => {
                                return (
                                  <div className="mt-2 lineheight19">
                                    <p className="mb-0 text-secondary  font-size-12">{o.descriptor?.name || "-"}</p>
                                    <p className="font-size-16">{o.descriptor?.short_desc || "-"}</p>
                                  </div>
                                )
                              })
                              : null}
                          </>
                        )}
                        <div className="mb-4">
                          <button
                            onClick={() => {
                              // console.log("cccccccccccccccccccccccccccccccc", key);
                              selectQuote(key)
                            }}
                            className={`mx-2  w-75 py-2 px-2 new-btn text-white `}>Select Offer</button>
                        </div>
                      </div>
                    </div>
                  )
                }) : null}
              </div>
              {quotes?.length > 4 ? (
                <div className="col-12 mx-auto text-end">
                  <img onClick={() => {
                    scrollLeft()
                  }}
                    className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                  <img onClick={() =>
                    scrollRight()
                  }
                    className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(GSTBasedInvoiceFinanceProductCatalogue)