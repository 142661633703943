import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { getSocket } from '../../socket'
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import { setContractDetails, setContractDetailLogi } from '../../store/actions/action';


const LiveNotif = ({ userTokenDetails, cDetailsState, setContractDetails, setContractDetailLogi, contractDetailLogi, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  var Socket = getSocket()
  const [dbData, setdbData] = useState([])
  const [eventMessage, seteventMessage] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const currentRoute = window.location.pathname
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    // console.log("userId LiveNotif-->>:", userId, userEmail, userTypeId);
    // console.log("currentRoute===>", currentRoute)
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getNotifications', { userTypeId: userTypeId, userID: userId, email: userEmail, sidebar: true, filter: false }).then((result) => {
      // console.log("result in sidebar :-->", result);
      setdbData(result.Notifications)
    }).catch((e) => {
      console.log("Error while querying getNotifications live:", e);
    })

    Socket.on("notification", (data) => {
      // console.log("event message in notification event:-->", data);
      seteventMessage(data)
    })
    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    // console.log("eventMessage in in notif comp---===>", eventMessage)

    if (Object.keys(eventMessage).length) {
      let newTempArray = [...dbData]
      newTempArray.unshift(eventMessage)
      newTempArray.pop();
      setdbData(newTempArray)
      toastDisplay(eventMessage.message, 'info')
    }
  }, [eventMessage]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  function handleContractredirect(contractNO, type) {
    if (type === 1) {
      setContractDetails(contractNO)
    } else {
      setContractDetailLogi(true, { contract_number: contractNO })
    }
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // JSX returm
  return (
    <>
      <div className="notification-bg" >
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <h2>Notifications {/*<span>10</span>*/}</h2>
        <div className="col-md-12">
          <div className="row">
            {/* <div className="col-md-12 overflow-auto" style={{ "maxHeight": "250px" }}> */}
            <ul id="scrollbarStyle-custom">
              {dbData ? dbData.map((value, index) => {
                // console.log("value in sidebar notif-->", value)
                return (
                  <li>
                    <span className={(value.notif_type / 1 === 1 ? "text-danger" :
                      value.notif_type / 1 === 2 ? "text-success" :
                        value.notif_type / 1 === 3 ? "text-primary" :
                          value.notif_type / 1 === 4 ? "text-warning" :
                            value.notif_type / 1 === 8 ? "text-danger" : "text-info")}>
                      <i className="fa fa-circle" aria-hidden="true"></i>
                    </span>

                    {(value.notif_type / 1 === 3 && value.event === "QUOTATION_SUBMIT" && [5, 10, 11].indexOf(userTypeId) === -1) ?
                      value.message.replace('Submited', 'Received') :
                      value.message}

                    {(value.event === "AGREE_CONTRACT_SELLER" || value.event === "UPDATE_CONTRACT_SELLER") ?
                      value.supplier :
                      (value.event === "AGREE_CONTRACT_BUYER" || value.event === "UPDATE_CONTRACT_BUYER") ?
                        value.buyer : ''}

                    {(value.address && currentRoute === '/contract') ?
                      <>
                        [<a className="cursor-pointer text-info" onClick={() => handleContractredirect(value.contract_no, value.notif_type / 1)}>
                          #{value.contract_no + " (" + (value.contract_name ? value.contract_name : "") + ")"}
                        </a>]
                      </> :
                      " " + ['#' + value.contract_no + (value.contract_name ? (" (" + value.contract_name + ")") : "")]}
                  </li>
                )
              }) : ""}
            </ul>
          </div>
        </div>

        <a href={'/notifications'}>
          <button className="btn all-notification btn-block btn-sm">View all notifications</button>
        </a>
      </div>
    </>
  );
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractDetailLogi: state.contractDetailLogi
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractDetailLogi: (flag, data) => { dispatch(setContractDetailLogi({ page: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveNotif)