import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setViewShip } from '../../store/actions/action';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import DataTable from 'react-data-table-component';
import columnsBookedShips from '../dataTablesColumsMap/bookedShipsListColumns';
import customStyles from '../dataTablesColumsMap/customTableCss';

const BookedShipsListing = ({ userTokenDetails, viewShip, setViewShip, dispatch }) => {

  const [filterData, setFilterData] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, { userId: userId, type_id: userTypeId })
    //------------------------------------------------------------------

  }, [filterData, viewShip.info.refresh]);



  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("")
  const [statusFilter, setstatusFilter] = useState(0)

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getbookedshipslist', objectAPI).then((result) => {
      console.log('running getbookedshipslist api-->', result);
      settableData(formatDataFortable(result.shipdata, objectAPI.userId))
      setTotalRows(result.countdata.totalCount)

      setLoading(false);
    }).catch((e) => {
      console.log('error in getbookedshipslist', e);
      setLoading(false);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getbookedshipslist', objectAPI).then((result) => {
      console.log('running getbookedshipslist api-->', result);
      settableData(formatDataFortable(result.shipdata, objectAPI.userId))
      setTotalRows(result.countdata.totalCount)

      setLoading(false);
    }).catch((e) => {
      console.log('error in getbookedshipslist', e);
      setLoading(false);
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };


  function formatDataFortable(dataArray, userId) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.ship_name = dataArray[i].ship_name
      tempObj.imo_no = dataArray[i].imo_no
      tempObj.charter_type = dataArray[i].charter_type === 1 ? 'Voyage Charter' : dataArray[i].charter_type === 2 ? "Time Charter" : "N/A"
      tempObj.load_port = dataArray[i].charter_type === 1 ?
        <><span className="shadow">
          <ReactCountryFlag
            countryCode={dataArray[i].load_country ? dataArray[i].load_country.split(':')[0] : ''}
            style={{ width: '25px', height: '25px' }} svg />
        </span>{dataArray[i].load_port}</> :
        dataArray[i].charter_type === 2 ? dataArray[i].charter_start : "N/A"


      tempObj.unload_port = dataArray[i].charter_type === 1 ?
        <><span className="shadow">
          <ReactCountryFlag
            countryCode={dataArray[i].unload_country ? dataArray[i].unload_country.split(':')[0] : ''}
            style={{ width: '25px', height: '25px' }} svg />
        </span>{dataArray[i].unload_port}</> :
        dataArray[i].charter_type === 2 ? dataArray[i].charter_end : "N/A"
      tempObj.price = dataArray[i].currency.split(":")[1] + " " + dataArray[i].price
      tempObj.created_at = dataArray[i].created_at ? formatDate_Application(dataArray[i].created_at) : "NA"
      tempObj.myRole = dataArray[i].booked_from / 1 === userId ? "Ship Provider" : dataArray[i].booked_to / 1 === userId ? "Ship Booker" : "NA"
      tempObj.status = (dataArray[i].booked_from_flag / 1 === 1 && dataArray[i].booked_to_flag / 1 === 1) ? 4 :
        (dataArray[i].booked_from_flag / 1 === 1 && dataArray[i].booked_to_flag / 1 === 0) ? 3 :
          (dataArray[i].booked_from_flag / 1 === 0 && dataArray[i].booked_to_flag / 1 === 1) ? 2 :
            (dataArray[i].booked_from_flag / 1 === 0 && dataArray[i].booked_to_flag / 1 === 0) ? 1 : 0

      tempObj.action = <button type="button" className="btn btn-primary btn-sm" title="View Ship" onClick={() => setViewShip(true, { value: dataArray[i] })} >
        <i className="fa fa-eye" aria-hidden="true" ></i>
      </button>

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Filters

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    if (type === 3) {
      setFilterData({
        ...filterData,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false
      })
    }
  };

  const reset = () => {
    // setstatusFilter(0)
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setsearchKey("")
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // functions
  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getbookedshipslist', objectAPI).then((result) => {
      console.log('running getbookedshipslist api-->', result);

      const dbResult = result.shipdata
      let csvString = "Ship Name,Imo No,Charter Type,Load Port,Unload Port,Price,Contract Date\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].ship_name},
      ${dbResult[i].imo_no},
      ${dbResult[i].charter_type === 0 ? 'Voyage Charter' : 'Time Charter'},
      ${dbResult[i].load_port ? dbResult[i].load_port : "NA"},
      ${dbResult[i].unload_port ? dbResult[i].unload_port : "NA"},
      ${dbResult[i].price ? dbResult[i].price : "NA"},
      ${dbResult[i].createdAt ? formatDate_Application(dbResult[i].createdAt) : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Trade_Contracts_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getbookedshipslist', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      <div className="d-md-flex mt-3 mb-3">
        <div className="filter-bg">
          <ul>
            <li className={"pilot-cont cursor-pointer " + (statusFilter === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setstatusFilter(1); filterStateButtons(1) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Confirmation Pending
            			</li>
            <li className={"progress-cont cursor-pointer " + (statusFilter === 2 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(2); filterStateButtons(2) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>In-Progress
                  </li>
            <li className={"complete-cont cursor-pointer " + (statusFilter === 3 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(3); filterStateButtons(3) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Booking Confirmed
                  </li>
          </ul>
        </div>

        <div className="ml-md-auto relative">
          {/* {(Object.keys(filterData).length) ? <div className="reset" onClick={() => reset()}>reset</div> : ''} */}
          <ul className="range-slider">
            <li>
              <input name="search" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} id="searchInput" onKeyDown={handleKeyPress} />
              <button type="button" onClick={() => {
                setFilterData({
                  ...filterData,
                  "search": searchKey
                })
              }}><i class="fas fa-search fa-lg"></i></button>
            </li>
            <li>
              <label>From</label>
              <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
            </li>
            <li>
              <label>To</label>
              <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
          </ul>
        </div>
      </div>
      <div className="table-responsive">
        <DataTable
          columns={columnsBookedShips}
          data={tableData}
          progressPending={loading}
          pagination
          progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
          persistTableHead
          striped
          noHeader
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover
          onSort={handleSort}
          sortServer
          customStyles={customStyles}
        />
      </div>
      <hr />
      <div className="float-left col-md-6 ">
        <div className="col-md-12 row">
          <div className="col-md-6">
            <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
              <i class="fas fa-file-csv"></i> Export CSV
              </button>
          </div>
        </div>
      </div>
    </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    viewShip: state.viewShip
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setViewShip: (flag, data) => { dispatch(setViewShip({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookedShipsListing)
