import React, { useEffect, useState } from 'react';
import { setRaiseQuotation } from '../../store/actions/action';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/raiseQuotationValidation';


const RaiseQuotation = ({ userTokenDetails, addQuotation, setRaiseQuotation, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({ quoatUsers: [] });
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [userExistData, setuserExistData] = useState({});
  const [tabing, settabing] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [portCountry, setportCountry] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [loadingPort, setLoadingPort] = useState([]);
  const [dischargePort, setDischargePort] = useState([]);

  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const astrix = <span className="required-field text-danger">*</span>
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    
    setshowLoader(true)
    call('POST', 'getUsersListing', { "searchUserType": 5, "searchKey": '', "network": "fullnetwork", "userAvatars": false }).then((result) => {
      console.log("result of api in getUsersListing-->", result)
      setshowLoader(false)
      setShippers(result)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getUsersListing', e);
    })

    call('POST', 'getUsersListing', { "searchUserType": 10, "searchKey": '', "network": "fullnetwork", "userAvatars": false }).then((result) => {
      // console.log("result of api in getUsersListing-->", result)
      setshowLoader(false)
      setBrokers(result)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getUsersListing', e);
    })

    call('POST', 'getPortCountry').then((result) => {
      console.log("result of api in getUsersListing-->", result)
      setshowLoader(false)
      setportCountry(result)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getUsersListing', e);
    })
    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      doNext();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  useEffect(() => {

    call('POST', 'getPort', {country: data.loadingCountry}).then((result) => {
      console.log("result of api in getPort-->", result)
      setLoadingPort(result)
    }).catch((e) => {
      console.log('error in getPort', e);
    })

  }, [data.loadingCountry])

  useEffect(() => {

    call('POST', 'getPort', {country: data.dischargeCountry}).then((result) => {
      console.log("result of api in getPort-->", result)
      setDischargePort(result)
    }).catch((e) => {
      console.log('error in getPort', e);
    })

  }, [data.dischargeCountry])
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  };

  const handleActiveClass = (id) => {
    if (id === 2) {
    //   fillTNCValue()
    }
    if (id < activeClass) {
      setActiveClass(id)
    } else {
      settempactiveClass(id);
      setIsSubmitting(true)
      settabing(true)
    }
  }

  const doNext = () => {
    if (tabing === true) {
      setActiveClass(tempactiveClass);
      setIsSubmitting(false)
      settabing(false)
    } else {
      if (activeClass >= maxactiveClass) {
        setmaxactiveClass(activeClass + 1)
      }
      setActiveClass(activeClass + 1);
      setIsSubmitting(false)
    }
  }

  const handleCheckbox = (event) => {
    event.persist();
    let tempArray = data[event.target.name] ? [...data[event.target.name]] : []
    let selectedUser = event.target.value / 1
    let index = tempArray.indexOf(selectedUser)
    if (index !== -1) {
      tempArray = tempArray.slice(0, index)
    } else {
      tempArray.push(selectedUser / 1)
    }
    setData(data => ({ ...data, [event.target.name]: tempArray }));
  }

  const handleCheckAllShipper = (event) => {
    event.persist();
    let tempArray = [...data.quoatUsers]
    for (let i = 0; i < shippers.length; i++) {
      if (!tempArray.includes(shippers[i])) {
        tempArray.push(shippers[i].id)
      }
    }
    setData(data => ({ ...data, quoatUsers: tempArray }));
  }

  const handleCheckAllBroker = (event) => {
    event.persist();
    let tempArray = [...data.quoatUsers]
    for (let i = 0; i < brokers.length; i++) {
      if (!tempArray.includes(brokers[i])) {
        tempArray.push(brokers[i].id)
      }
    }
    setData(data => ({ ...data, quoatUsers: tempArray }));
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  //---------------------------------------------------------------------------------------------------------------------

  console.log('userExistData---->', userExistData);
  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler

  const handleSubmit = () => {

    
  }

  const handleNext = () => {
    setIsSubmitting(true)
    setError(validate(data));
    if (activeClass === 2) {
      // fillTNCValue()
    }
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, userExistData));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {addQuotation.modal &&
        <div className={"modal" + (addQuotation.modal ? " show" : "")} id="shipmodal">
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Raise New Quotation</h4>
                <button type="button" className="close" onClick={() => { setRaiseQuotation(false, {}); }}>×</button>
              </div>
              <div className="modal-body calc-inner-modal">
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                
                <div id="tabs1" className="tab-contents no-js">
                    <div className="tab-nav">
                      <li id="1" className={"tab-link" + (activeClass === 1 ? " is-active" : "") + ((1 < maxactiveClass) ? " is-completed" : "")} onClick={(1 <= maxactiveClass) ? () => handleActiveClass(1) : ""}>Shippment info</li>
                      <li id="2" className={"tab-link" + (activeClass === 2 ? " is-active" : "") + ((2 < maxactiveClass) ? " is-completed" : "")} onClick={(2 <= maxactiveClass) ? () => handleActiveClass(2) : ""}>Quotation</li>
                    </div>

                    <div className={"tab-content" + (activeClass === 1 ? " is-active" : "")}>
                        <div className="col-md-12">
                            <div className="row pt-2">
                                <div className="form-group col-md-6">
                                    <label>Shippment Type {astrix}</label>
                                    <select className={" form-control" + (!error.shippmentType ? '' : ' border-danger')} name="shippmentType" value={data.shippmentType} onChange={handleChange} >
                                        <option value="" selected>-- Select Shippment Type --</option>
                                        <option value="import">Import</option>
                                        <option value="export">Export</option>
                                    </select>
                                    {error.shippmentType && <p className="text-danger error-contract">{error.shippmentType}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Container Type {astrix}</label>
                                    <select className={" form-control" + (!error.containerType ? '' : ' border-danger')} name="containerType" value={data.containerType} onChange={handleChange} >
                                        <option value="" selected>-- Select Container Type --</option>
                                        <option value="20dryvan">20' Dry Van</option>
                                        <option value="40dryvan">40' Dry Van</option>
                                        <option value="40highcube">40' High Cube</option>
                                        <option value="20reefer">20' Refrigerated Container (Reefer)</option>
                                        <option value="40reefer">40' Reefer</option>
                                        <option value="40highcubereefer">40' High Cube Reefer</option>
                                        <option value="20opentop">20' Open Top</option>
                                        <option value="40opentop">40' Open Top</option>
                                        <option value="20flatrack">20' Flat Rack</option>
                                        <option value="40flatrack">40' Flat Rack</option>
                                        <option value="20platform">20' Platfrom</option>
                                        <option value="40platform">40' Platform</option>
                                    </select>
                                    {error.containerType && <p className="text-danger error-contract">{error.containerType}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Loading Country {astrix}</label>
                                    <select className={" form-control" + (!error.loadingCountry ? '' : ' border-danger')} name="loadingCountry" value={data.loadingCountry} onChange={handleChange} >
                                        <option value="" selected>-- Select Loading Country --</option>
                                        {portCountry.length > 0 && portCountry.map((value) => {
                                            return(
                                                <option value={value.port_country}>{value.port_country}</option>
                                            )
                                        })}
                                    </select>
                                    {error.loadingCountry && <p className="text-danger error-contract">{error.loadingCountry}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Port of Loading {astrix}</label>
                                    <select className={" form-control" + (!error.loadingPort ? '' : ' border-danger')} name="loadingPort" value={data.loadingPort} onChange={handleChange} >
                                        <option value="" selected>-- Select Loading Country --</option>
                                        {loadingPort.length > 0 && loadingPort.map((value) => {
                                            return(
                                                <option value={value.port_name}>{value.port_name}</option>
                                            )
                                        })}
                                    </select>
                                    {error.loadingPort && <p className="text-danger error-contract">{error.loadingPort}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Discharge Country {astrix}</label>
                                    <select className={" form-control" + (!error.dischargeCountry ? '' : ' border-danger')} name="dischargeCountry" value={data.dischargeCountry} onChange={handleChange} >
                                        <option value="" selected>-- Select Loading Country --</option>
                                        {portCountry.length > 0 && portCountry.map((value) => {
                                            return(
                                                <option value={value.port_country}>{value.port_country}</option>
                                            )
                                        })}
                                    </select>
                                    {error.dischargeCountry && <p className="text-danger error-contract">{error.dischargeCountry}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Port of Discharge {astrix}</label>
                                    <select className={" form-control" + (!error.dischargePort ? '' : ' border-danger')} name="dischargePort" value={data.dischargePort} onChange={handleChange} >
                                        <option value="" selected>-- Select Loading Country --</option>
                                        {dischargePort.length > 0 && dischargePort.map((value) => {
                                            return(
                                                <option value={value.port_name}>{value.port_name}</option>
                                            )
                                        })}
                                    </select>
                                    {error.dischargePort && <p className="text-danger error-contract">{error.dischargePort}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Commodity Type {astrix} </label>
                                  <input type="text" className={" form-control" + (!error.commodityType ? '' : ' border-danger')} placeholder="" name="commodityType" value={data.commodityType} onChange={handleChange} />
                                  {error.commodityType && <p className="text-danger error-contract">{error.commodityType}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Shipping Volume {astrix}</label>
                                  <select className={" form-control" + (!error.shippingVol ? '' : ' border-danger')} name="shippingVol" value={data.shippingVol} onChange={handleChange} >
                                    <option value="" selected>-- Select Shipping Volume --</option>
                                    <option value="annum">Per Annum</option>
                                    <option value="month">Per Month</option>
                                    <option value="day">Per Day</option>
                                    <option value="offday">One off</option>
                                  </select>
                                  {error.shippingVol && <p className="text-danger error-contract">{error.shippingVol}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Quantity {astrix}</label>
                                  <input type="number" className={" form-control" + (!error.quantity ? '' : ' border-danger')} placeholder="" name="quantity" value={data.quantity} onChange={handleChange} />
                                  {error.quantity && <p className="text-danger error-contract">{error.quantity}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Weight (KG) {astrix} </label>
                                  <input type="number" className={" form-control" + (!error.weight ? '' : ' border-danger')} placeholder="" name="weight" value={data.weight} onChange={handleChange} />
                                  {error.weight && <p className="text-danger error-contract">{error.weight}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Is this Shippment Hazardous? {astrix} </label>
                                  <select className={" form-control" + (!error.shippmentHazardous ? '' : ' border-danger')} name="shippmentHazardous" value={data.shippmentHazardous} onChange={handleChange} >
                                    <option value="" selected>-- Select Shippment Hazardous Type --</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                  {error.shippmentHazardous && <p className="text-danger error-contract">{error.shippmentHazardous}</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"tab-content" + (activeClass === 2 ? " is-active" : "")}>
                      <div className=" col-md-12 row" >

                        <div className="col-md-12">
                          <ul className="price-ul">
                            <li>
                              <div className="price-left">{data.shippmentType}</div>
                              <div className="price-right">Shippment Type</div>
                            </li>
                            <li>
                              <div className="price-left">{data.containerType}</div>
                              <div className="price-right">Container Type </div>
                            </li>
                            <li>
                              <div className="price-left">{userName}</div>
                              <div className="price-right">Exporter </div>
                            </li>
                            <li>
                              <div className="price-left">{data.commodityType}</div>
                              <div className="price-right">Commodity </div>
                            </li>
                            <li>
                              <div className="price-left">{data.quantity}</div>
                              <div className="price-right">Quantity </div>
                            </li>
                            <li>
                              <div className="price-left">{data.loadingPort}</div>
                              <div className="price-right">Loading Port</div>
                            </li>
                            <li>
                              <div className="price-left">{data.dischargePort}</div>
                              <div className="price-right">Discharge Port</div>
                            </li>
                            <li>
                              <div className="price-left">{data.weight}</div>
                              <div className="price-right">Weight (KG) </div>
                            </li>
                          </ul>
                        </div>

                        <div className=" col-md-12 row mt-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Loading Rate (PDPR)</label>
                              <input type="text" name="quotLoadingRate" placeholder="" value={data.quotLoadingRate} className="form-control" onChange={handleChange} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Unloading Rate (PDPR)</label>
                              <input type="text" name="quotUnloadingRate" placeholder="" value={data.quotUnloadingRate} className="form-control" onChange={handleChange} />
                            </div>
                          </div>
                        </div>

                        <div className=" col-md-12 row mt-2">
                          <div className=" col-md-6">
                            <label >Select Ship Owners to send</label>
                            {(shippers.length > 1) &&
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="checkAll" name="shipperCheckall" id="shipperCheckall" onChange={handleCheckAllShipper} />
                                <label className="form-check-label" htmlFor="shipperCheckall">"Check All"</label>
                              </div>
                            }
                            {shippers && shippers.length ? shippers.map((user, index) => {
                              return (
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value={user.id} name="quoatUsers" id={"quoatUsersS" + index} onChange={handleCheckbox} checked={data.quoatUsers.includes(user.id)} />
                                  <label className="form-check-label" htmlFor={"quoatUsersS" + index}>{user.company_name}</label>
                                </div>
                              )
                            }) : ""
                            }
                          </div>
                          <div className=" col-md-6">
                            <label >Select Brokers to send</label>
                            {(brokers.length > 1) &&
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="checkAll" name="brokerCheckall" id="brokerCheckall" onChange={handleCheckAllBroker} />
                                <label className="form-check-label" htmlFor="brokerCheckall">"Check All"</label>
                              </div>
                            }
                            {brokers && brokers.length ? brokers.map((user, index) => {
                              return (
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value={user.id} name="quoatUsers" id={"quoatUsersB" + index} onChange={handleCheckbox} checked={data.quoatUsers.includes(user.id)} />
                                  <label className="form-check-label" htmlFor={"quoatUsersB" + index}>{user.company_name}</label>
                                </div>
                              )
                            }) : ""
                            }
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => { setRaiseQuotation(false, {}); }}>Cancel</button>
                {activeClass === 2 ?
                  <button type="button" className="btn btn-info btn-sm" onClick={() => handleNext()}>Submit</button>
                  :
                  <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Next</button>  
                }
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {

  return {
    addQuotation: state.addQuotation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRaiseQuotation: (modal, info) => { dispatch(setRaiseQuotation({ modal: modal, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RaiseQuotation)