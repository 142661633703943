import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { NewTable } from '../../../utils/newTable'
const { useState, useEffect, default: React } = require("react");


const customizeUserAcess = {
  "Buyer Management": "buyerManagement",
  "LC Apply For Limit": "applyForLimit,LcDiscounting",
  "LC Quotes": "LcQuote,LcSeequotes",
  "LC Contract": "LcContract,LcSignContract",
  "LC Finance": "LcApplyforFinance,LcFinancebtn,LcApprovedFinance",
  "LC Ammendment": "LcAmendment,amendmentDetails",
  "Invoice Apply For Limit": "applyLimit",
  "Invoice Quotes": "Quotes,seequotes,DocsRequested",
  "Invoice Contract": "invoicecontract,signContract",
  "Invoice Finance": "apply-finance,apply-invoice",
  "Invoice Approved Finance": "approvedFinance,viewDetails",
};

const UserAcessFinancer = {
  "Supply Chain Specialist": "supplierList,supplierDetails",
  "International Financial Services Manager": "finLCQuotes,lcFinApplication,finInvoiceQuotes,disbursementV2,financeApplication,finQuotesDetails,viewFinanceApplication",
  "Domestic Financial Services Manager": "cgtmseFinancierQuote,bgFinancierQuote,cgtmseFinancierSendQuote,bgFinancierSendQuote,scfFinancierQuote,scfFinancierSendQuote,approvedLimitSCF,scfSignTermsheet",
  "Insurance Manager": "insuranceFinancierQuote",
  "Trade Finance Specialist": "edocsLC",
  "Support": "ChatRoomV2",

  "Accounting Analyst": "Reportv2",
  // Notifications: "AllNotifications",
  Profile: "viewprofile",
};

const accessItem = {

  "Network Management":
    "buyerManagement,nwManagementExporter,nwManagementLogisticPartner",
  "International Financial Services Manager":
    "LcDiscountingV2,LcQuote,applyLimitV2,LcContract,LcApprovedFinance,LcApplyforFinance,Quotes,invoicecontract,apply-finance,invoice-agreement,approvedFinance",
  "Domestic Financial Services Manager":
    " wcApplyLimit,wcQuote,applylimitCGTMSE,cgtmseQuote,bgGetConfirmation,bgQuote,bgConfirmed,applylimitSCF,scfQuote,approvedLimitSCF,applyfinDID,invoiceFinance,gestBasedInvoiceFinanceDiscovery",

  "Accounting Analyst": "Reportv2,tallyReportsDashboard",
  "Contract Administrator": "contractManagementList,contractManagementAdd",
  "Operations Manager":
    "ShipmentBookingMarketPlace,ShipmentBookingApply,ShipmentBookingQuotes,ShipmentBookingTracking",
  "Insurance Manager": "CreditInsurance,insuranceQuote",
  "Trade Finance Specialist":
    "edocsEinvoice,edocsEwaybill,edocsPOGenerator,edocsLC,edocsBL",
  "Chartered Accountant":
    "tallyMastersAccountingGroup,tallyMastersAccountingLedger,tallyMastersAccountingVoucherType,tallyMastersStockGroup,tallyMastersStockCategory,tallyMastersStockGodown,manageCommodities,tallyTxnsVoucher,tallyReportsDashboard",
  Support: "ChatRoomV2,tradeGpt",

  Profile: "viewprofile",
  Wallet: "wallet,payment-gateway,plans",

}


const accessItemAdmin = {

  "Task Manager":
    "taskManager,enquiry,callList,corporate,financier",
  "International Financial Services Manager":
    "lcLimit,invoiceLimit,invoiceFinance,invoiceApprovedFinance",
  "Domestic Financial Services Manager":
    " otherFinAdminwcQuote,otherFinAdmincgtmseQuote,otherFinAdminbgQuote,otherFinAdminscfQuote",

  "User Management": "usersonboard",
  "CRM Manager": "leads,crmdata",
  "Finance Specialist": "generateInvoiceFinancier,generateInvoiceCP",
  "Admin Payments": "adminPayments",
  "Accounting Analyst": "AdminReports,tradeDiscovery",
  "MasterData Analyst": "masterdata",
  "User credit Administrator": "buyercreditcheck,suppliercreditcheck",

  "Contract Administrator": "contractManagementList,contractManagementAdd",
  "Operations Manager":
    "ShipmentBookingQuotes",

  Support: "ChatRoomV2,tradeGpt",

  Profile: "viewprofile",


}






const briefitems = [
  {
    item: "Network Management",
    intro:
      "Manage new and shows exisiting buyers, supliers, logistic partners on the platform",
  },
  {
    item: "International Financial Services Manager",
    intro:
      " Applications for financial limits, Letter of Credit, contracts, finance Management and international financial transactions and services.",
  },
  {
    item: "Domestic Financial Services Manager",
    intro:
      "Applications for working capital, CGTMSE limits, handling quotes and confirmations for bank guarantees and operations.",
  },
  {
    item: "Accounting Analyst",
    intro:
      "Manages reports generated for contracts, invoices and letter of Credit ",
  },
  {
    item: "Contract Administrator",
    intro:
      "Administer contract-related activities, including maintaining the contract list and adding new contracts for efficient contract administration.",
  },
  {
    item: "Operations Manager",
    intro:
      "Efficient management shipment bookings, including marketplace listings  applications,and tracking  logistics operations.",
  },
  {
    item: "Insurance Manager",
    intro:
      "Manages credit insurance policies and manages insurance quotes ensuring comprehensive coverage and risk management.",
  },
  {
    item: "Trade Finance Specialist",
    intro:
      "Electronic documents management for invoicing  e-way bills, purchase orders letters of credit and bills of lading",
  },
  {
    item: "Chartered Accountant",
    intro:
      "Manages accounting groups, ledgers, stock groups, categories, godowns, voucher transactions, accounting dashboard, for efficient financial management.",
  },
  {
    item: "Wallet",
    intro:
      "Integrates payment gateway and manages subscription plans for financial transactions and service subscriptions.",
  },
  {
    item: "Support",
    intro:
      "Interactive communication and tradeGpt for assisting with trade-related queries, ensuring comprehensive assistance and guidance for users.",
  },
  {
    item: "Profile",
    intro: "Learn more about yourself",
  },
]

const briefitemsFin = [
  {
    item: "Supply Chain Specialist",
    intro:
      "Oversees the end-to-end process of the supply chain, ensuring efficient and effective procurement, managing supplier relationships, coordinating logistics, and ensuring the timely delivery of commodities and products"
  },
  {
    item: "International Financial Services Manager",
    intro:
      " Applications for financial limits, Letter of Credit, contracts, finance Management and international financial transactions and services.",
  },
  {
    item: "Domestic Financial Services Manager",
    intro:
      "Applications for working capital, CGTMSE limits, handling quotes and confirmations for bank guarantees and operations.",
  },
  {
    item: "Accounting Analyst",
    intro:
      "Manages reports generated for Payments and Shipments",
  },

  {
    item: "Insurance Manager",
    intro:
      "Manages credit insurance policies and manages insurance quotes ensuring comprehensive coverage and risk management.",
  },
  {
    item: "Trade Finance Specialist",
    intro:
      "Electronic documents management for invoicing  e-way bills, purchase orders letters of credit and bills of lading",
  },

  {
    item: "Support",
    intro:
      "Interactive communication and tradeGpt for assisting with trade-related queries, ensuring comprehensive assistance and guidance for users.",
  },
  {
    item: "Profile",
    intro: "Learn more about yourself",
  },
]


const briefitemsAdmin = [
  {
    item: "User Management",
    intro:
      "Manage new and shows exisiting buyers, supliers, logistic partners on the platform",
  },
  {
    item: "Task Manager",
    intro:
      "Manage users and their aggrements, termsheets, finance limits and leads assigned on the platform",
  },
  {
    item: "CRM Manager",
    intro:
      "Manage new and shows exisiting buyers, supliers, logistic partners on the platform",
  },
  {
    item: "International Financial Services Manager",
    intro:
      " Applications for financial limits, Letter of Credit, contracts, finance Management and international financial transactions and services.",
  },
  {
    item: "Domestic Financial Services Manager",
    intro:
      "Applications for working capital, CGTMSE limits, handling quotes and confirmations for bank guarantees and operations.",
  },
  {
    item: "Accounting Analyst",
    intro:
      "Manages reports generated for contracts, invoices and letter of Credit ",
  },
  {
    item: "Contract Administrator",
    intro:
      "Administer contract-related activities, including maintaining the contract list and adding new contracts for efficient contract administration.",
  },
  {
    item: "Operations Manager",
    intro:
      "Efficient management shipment bookings, including marketplace listings  applications,and tracking  logistics operations.",
  },
  {
    item: "Insurance Manager",
    intro:
      "Manages credit insurance policies and manages insurance quotes ensuring comprehensive coverage and risk management.",
  },
  {
    item: "Finance Specialist",
    intro:
      "Manages and generates the invoices for the financier.",
  },
  {
    item: "User credit Administrator",
    intro:
      "Tracks the credit score of exporters and buyers .",
  },
  {
    item: "Admin Payments",
    intro:
      "Manages the transactions, keeps track of payment summary and the commission earned. ",
  },
  {
    item: "MasterData Analyst",
    intro:
      "Manages and generates the data for all the exporters along with their HSN codes, oraganizations, region , turnover and contacts .",
  },

  {
    item: "Support",
    intro:
      "Interactive communication and tradeGpt for assisting with trade-related queries, ensuring comprehensive assistance and guidance for users.",
  },
  {
    item: "Profile",
    intro: "Learn more about yourself",
  },
]


const RolesTab = ({ userTokenDetails, isEditable, userId }) => {
  const [UserAcess, setUserAccess] = useState(accessItem);
  const [UserAcessFin, setUserAccessFin] = useState(UserAcessFinancer)
  const [UserAcessAdmin, setUserAccessAdmin] = useState(accessItemAdmin)
  const [briefintro, setbriefIntro] = useState(briefitems);
  const [briefintroFin, setbriefIntroFin] = useState(briefitemsFin);
  const [briefintroAdmin, setbriefIntroAdmin] = useState(briefitemsAdmin);

  useEffect(() => {
    call("POST", "getallroles", {}).then((res) => {
      // Assuming res is an array with multiple items
      console.log(res, "res in roels---");
      res.forEach((item) => {
        if (item.field && typeof item.field === "string") {
          try {
            const parsedField = JSON.parse(item.field);
            Object.keys(parsedField).forEach((key) => {
              const roles = parsedField[key]
                .split(",")
                .map((role) => role.trim());
              roles.forEach((role) => {
                // setbriefIntro([...briefintro, { item: role, intro: "" }]);
              });
            });
          } catch (error) {
            console.error("Error parsing field:", error);
          }
        }
      });

    });
  }, []);



  const [viewAccess, setviewaccess] = useState([
    {
      item: "buyerManagement",
      name: "Buyer Management",
      action: ["create", "view"],
      intro: "Create and view buyers",
      storeid: "2",
      selected: []
    },
    {
      item: "nwManagementExporter",
      name: "Exporter Management",
      action: ["create", "view"],
      intro: "Create and view exporters",
      storeid: "2", selected: []
    },
    {
      item: "nwManagementLogisticPartner",
      name: "Channel Partner Management",
      storeid: "2",
      action: ["create", "view"],
      intro: "Create and view Channel Partners", selected: []
    },


    {
      item: "LcDiscountingV2",
      name: "LC Discounting",
      action: ["create", "view"],
      intro: "Apply for Letter of credit discount",
      storeid: "3",
      selected: []
    },
    {
      item: "LcQuote",
      name: "LC Quote",
      action: ["create", "view"],
      intro: "Create Letter of Credit Quote",
      storeid: "3",
      selected: []
    },
    {
      item: "applyLimitV2",
      name: "Apply Limit for Export Factoring",
      action: ["create", "view"],
      intro: "Create Letter of Credit Quote",
      storeid: "4",
      selected: []
    },
    {
      item: "Quotes",
      name: "Apply Quote for Export Factoring",
      action: ["create", "view"],
      intro: "Create Quote for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "invoicecontract",
      name: "Apply Contract for Export Factoring",
      action: ["create", "view"],
      intro: "Create Contract for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "apply-finance",
      name: "Apply Finance for Export Factoring",
      action: ["create", "view"],
      intro: "Apply Contract for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "invoice-agreement",
      name: "Apply invoice Agreement for Export Factoring",
      action: ["create", "view"],
      intro: "Apply invoice Agreement for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "approvedFinance",
      name: "Apply Finance approval for Export Factoring",
      action: ["create", "view"],
      intro: "Approved Finance for Export Factoring",
      storeid: "4",
      selected: []
    },

    {
      item: "LcContract",
      name: "LC Contract",
      action: ["create", "view"],
      intro: "Letter of Credit Contract",
      storeid: "3",
      selected: []
    },

    {
      item: "LcApplyforFinance",
      name: "LC Apply For Finance",
      action: ["create"],
      intro: "Apply for Finance",
      storeid: "3",
      selected: []
    },
    {
      item: "LcApprovedFinance",
      name: "LC Approve For Finance",
      action: ["create"],
      intro: "Approve for Finance",
      storeid: "3",
      selected: []
    },




    {
      item: "wcApplyLimit",
      name: "Apply Limit For working Capital",
      action: ["create"],
      intro: "Apply limit for working capital",
      storeid: "5", selected: []

    },
    {
      item: "wcQuote",
      name: "Apply Quote for Working Capital",
      action: ["create"],
      intro: "Create Quote",
      storeid: "5",
      selected: []
    },
    {
      item: "applylimitCGTMSE",
      name: "Apply Limit for CGTMSE",
      action: ["view"],
      tro: "Apply Limit for Credit Guarantee Fund Trust for Micro and Small Enterprises",
      storeid: "5", selected: []
    },
    {
      item: "bgQuote",
      name: "Bank Gurantee Quote",
      action: ["view"],
      intro: "Get Bank Gurantee Quote",
      storeid: "5",
      selected: []
    },
    {
      item: "applylimitSCF",
      name: "Apply limit for SCF",
      action: ["view"],
      intro: "Apply Limit for Supply Chain Finance",
      storeid: "5", selected: []
    },
    {
      item: "scfQuote",
      name: "Apply Quote for SCF",
      action: ["view"],
      intro: "Apply Quote for Supply Chain Finance",
      storeid: "5", selected: []
    },
    {
      item: "approvedLimitSCF",
      name: "Approve Limit for SCF",
      action: ["view"],
      intro: "Approve Limit for Supply Chain Finance",
      storeid: "5", selected: []
    },
    {
      item: "invoiceFinance",
      name: "Invoice Finance",
      action: ["view"],
      intro: "Get invoice finance",
      storeid: "5", selected: []
    },
    {
      item: "Reportv2",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "10",
      selected: []
    },
    {
      item: "tallyReportsDashboard",
      name: "Tally Report Analysis",
      action: ["view"],
      intro: "View Tally Report analysis",
      storeid: "10",
      selected: []
    },
    {
      item: "contractManagementList",
      name: "Contract Management",
      action: ["view"],
      intro: "View contract list",
      storeid: "11", selected: []
    },
    {
      item: "contractManagementAdd",
      name: "Create Contract",
      action: ["create"],
      intro: "Create contract",
      storeid: "11", selected: []
    },

    {
      item: "ShipmentBookingMarketPlace",
      name: "Shipment Booking",
      action: ["view"],
      intro: "View Shipment Bookings",
      storeid: "14", selected: []
    },
    {
      item: "ShipmentBookingApply",
      name: "Apply for Shipment Booking",
      action: ["view"],
      intro: "Apply for Shipment Booking",
      storeid: "14", selected: []
    },
    {
      item: "ShipmentBookingQuotes",
      name: "Booking Quotes",
      action: ["view"],
      intro: "View shipment booking quotes",
      storeid: "14", selected: []
    },
    {
      item: "CreditInsurance",
      name: "Insurance Credit",
      action: ["view"],
      intro: "View Insurance Credit",
      storeid: "13",
      selected: []

    },
    {
      item: "insuranceQuote",
      name: "Insurance Quotes",
      action: ["view"],
      intro: "View insurance quotes",
      storeid: "13",
      selected: []
    },
    {
      item: "edocsEinvoice",
      name: "Edocs Invoice",
      action: ["view", "create"],
      intro: "View and create edocs invoice",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsEwaybill",
      name: "Edocs Eway Bill",
      action: ["view"],
      intro: "View Edocs Way bills",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsPOGenerator",
      name: "Edocs PO Generator",
      action: ["view", "create"],
      intro: "create and view edocs PO",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsLC",
      name: "Edocs Letter of Credit",
      action: ["view", "create"],
      intro: "create and view Edocs letter of credit",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsBL",
      name: "Edocs BL",
      action: ["view", "create"],
      intro: "View and create edocs",
      storeid: "14",
      selected: []
    },
    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "8",
      selected: []
    },
    {
      item: "tradeGpt",
      name: "Trade Gpt",
      action: ["view", "create"],
      intro: "Use Trade GPT to query",
      storeid: "16",
      selected: []
    },


    {
      item: "wallet",
      name: "Wallet",
      action: ["view", "create"],
      intro: "View wallet ",
      storeid: "7",
      selected: []
    },
    {
      item: "payment-gateway",
      name: "Payments",
      action: ["view", "create"],
      intro: "View and create payment gateway",
      storeid: "5",
      selected: []
    },
    {
      item: "plans",
      name: "Plans",
      action: ["view", "create"],
      intro: "View and create plans",
    },

    {
      item: "tallyMastersAccountingGroup",
      name: "Tally Master Accounting Group",
      action: ["view", "create"],
      intro: "Create and View tally accounting group",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyMastersAccountingLedger",
      name: "Tally Master Accounting Ledger",
      action: ["view", "create"],
      intro: "Create and View tally Ledger ",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyMastersAccountingVoucherType",
      name: "Tally Master Accounting Voucher",
      action: ["view", "create"],
      intro: "Create and View tally master accounting vouchers ",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyMastersStockGroup",
      name: "Tally Master Accouning stock",
      action: ["view", "create"],
      intro: "Create and View tally master accounting stocks ",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyMastersStockCategory",
      name: "Tally Master Accouning stock category",
      action: ["view", "create"],
      intro: "Create and View tally master accounting stocks categories ",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyMastersStockGodown,",
      name: "Tally Master Accouning stock Godown",
      action: ["view", "create"],
      intro: "Create and View tally master accounting stocks and godowns ",
      storeid: "15",
      selected: []

    },
    {
      item: "manageCommodities",
      name: "Tally Master commodities",
      action: ["view", "create"],
      intro: "Create and View tally master commodities ",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyTxnsVoucher,",
      name: "Tally Master Voucher",
      action: ["view", "create"],
      intro: "Create and View tally master voucher ",
      storeid: "15",
      selected: []
    },
    {
      item: "tallyReportsDashboard",
      name: "Tally Master dashboard",
      action: ["view", "create"],
      intro: "Create and View tally master dashboard ",
      storeid: "15",
      selected: []
    },
  ]);


  const [viewAccessFin, setviewaccessFin] = useState([


    {
      item: "supplierList",
      name: "Suppliers ",
      action: ["create", "view"],
      intro: "View Supplier List",
      storeid: "2",
      selected: []
    },
    {
      item: "supplierDetails",
      name: "Supplier Details",
      action: ["create", "view"],
      intro: "View Details of selected suppliers",
      storeid: "2",
      selected: []
    },
    {
      item: "finLCQuotes",
      name: "Limit Application ",
      action: ["create", "view"],
      intro: "Apply for Quotes",
      storeid: "3",
      selected: []
    },
    {
      item: "lcFinApplication",
      name: "Finance Application",
      action: ["create", "view"],
      intro: "Create Quote for Finance Application",
      storeid: "3",
      selected: []
    },
    {
      item: "finInvoiceQuotes",
      name: "Apply for Invoice Quotes",
      action: ["create", "view"],
      intro: "Create Invoice Contract",
      storeid: "4",
      selected: []
    },
    {
      item: "financeApplication",
      name: "Apply Finance for Export Factoring",
      action: ["create", "view"],
      intro: "Apply Finance for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "disbursementV2",
      name: "Disbursement for Export Factoring",
      action: ["create", "view"],
      intro: "Apply disbursement for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "finQuotesDetails",
      name: "Apply Finance Quote for Export Factoring",
      action: ["create"],
      intro: "View detail Finance Quote for Export Factoring",
      storeid: "4",
      selected: []
    },

    {
      item: "viewFinanceApplication",
      name: "Finance Application",
      action: ["create"],
      intro: "View Fiannce Application",
      storeid: "4",
      selected: []
    },

    {
      item: "cgtmseFinancierQuote",
      name: "Apply Quote for CGTMSE",
      action: ["create", "view"],
      intro: "Apply Quote for Finance",
      storeid: "5",
      selected: []
    },
    {
      item: "bgFinancierQuote",
      name: "Apply Quote for Bank GUrantee",
      action: ["create", "view"],
      intro: "Apply Quote for Bank Gurantee",
      storeid: "5",
      selected: []
    },




    {
      item: "bgConfirmedFinancier",
      name: "Bank Guarantee Financier",
      action: ["view"],
      intro: "Bank Guarantee for Financier",
      storeid: "5", selected: []

    },
    {
      item: "scfFinancierQuote",
      name: "Apply Finance Quote for Supply Chain Finance",
      action: ["create"],
      intro: "Apply Finance Quote for SCF",
      storeid: "5",
      selected: []
    },


    {
      item: "approvedLimitSCF",
      name: "Approve Limit for SCF",
      action: ["view"],
      intro: "Approve Limit for Supply Chain Finance",
      storeid: "5", selected: []
    },

    {
      item: "Reportv2",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "12",
      selected: []
    },

    {
      item: "insuranceFinancierQuote",
      name: "Insurance Quote",
      action: ["view"],
      intro: "View Insurance Quote",
      storeid: "7",
      selected: []

    },



    {
      item: "edocsLC",
      name: "Edocs Letter of Credit",
      action: ["view", "create"],
      intro: "create and view Edocs letter of credit",
      storeid: "10",
      selected: []
    },

    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "11",
      selected: []
    },




  ]);



  const [viewAccessAdmin, setviewaccessAdmin] = useState([
    {
      item: "usersonboard",
      name: "User Management",
      action: ["create", "view"],
      intro: "Create and view buyers",
      storeid: "6",
      selected: []
    },



    {
      item: "taskManager",
      name: "Task Manager",
      action: ["view"],
      intro: "Manages users onboard",
      storeid: "2",
      selected: []
    },

    {
      item: "enquiry",
      name: "Task Manager Enquiry",
      action: ["view"],
      intro: "Keeps Track of user enquiries",
      storeid: "2",
      selected: []
    },
    {
      item: "callList",
      name: "Task Manager Call List",
      action: ["view"],
      intro: "Keeps track of call history of users onboard",
      storeid: "2",
      selected: []
    },

    {
      item: "corporate",
      name: "Task Manager Corporate",
      action: ["view"],
      intro: "Lists out the details of directors along with their company information",
      storeid: "2",
      selected: []
    },


    {
      item: "financier",
      name: "Financiers",
      action: ["view"],
      intro: "Lists of Banks providing financier services",
      storeid: "2",
      selected: []
    },




    {
      item: "tradeDiscovery",
      name: "Trade Discovery",
      action: ["create"],
      intro: "Allows to search and view trader information ",
      storeid: "3", selected: []

    },
    {
      item: "lcLimit",
      name: "Letter of credit ",
      action: ["create", "view"],
      intro: "View and Create limit application",
      storeid: "4",
      selected: []
    },
    {
      item: "invoiceLimit",
      name: "Apply Limit for Export Factoring",
      action: ["view", "create"],
      tro: "Apply Limit for Credit Guarantee Fund Trust for Micro and Small Enterprises",
      storeid: "5", selected: []
    },
    {
      item: "invoiceFinance",
      name: "Apply limit Finance ",
      action: ["view"],
      intro: "Apply Limit Finance for Export Factoring",
      storeid: "5",
      selected: []
    },
    {
      item: "invoiceApprovedFinance",
      name: "Finance Invoice Approved ",
      action: ["view"],
      intro: "Approved Finance Invoice ",
      storeid: "5", selected: []
    },
    {
      item: "leads",
      name: "Leads Assigned to traders",
      action: ["view"],
      intro: "View traders with their task and leads assigned to them",
      storeid: "7", selected: []
    },
    {
      item: "crmdata",
      name: "CRM Traders Data",
      action: ["view"],
      intro: "View the CRM data ",
      storeid: "7", selected: []
    },
    {
      item: "adminPayments",
      name: "Admin Payments",
      action: ["view"],
      intro: "Monitors the payments across the platform",
      storeid: "8", selected: []
    },
    {
      item: "generateInvoiceFinancier",
      name: "Generate Invoice",
      action: ["view", "create"],
      intro: "Generate Invoice for Financier",
      storeid: "9", selected: []
    },
    {
      item: "generateInvoiceCP",
      name: "Generate Invoice for Channel Partner",
      action: ["view", "create"],
      intro: "Generate Invoice for Channel Partner",
      storeid: "9", selected: []
    },

    {
      item: "AdminReports",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "11",
      selected: []
    },
    {
      item: "masterdata",
      name: "Master Data Analysis",
      action: ["view"],
      intro: "View Master Data analysis",
      storeid: "12",
      selected: []
    },
    {
      item: "buyercreditcheck",
      name: "Check Buyer Credit",
      action: ["create"],
      intro: "View Buyers Credit",
      storeid: "13",
      selected: []
    },
    {
      item: "suppliercreditcheck",
      name: "Check Supplier Credit",
      action: ["create"],
      intro: "View Suppliers Credit",
      storeid: "13",
      selected: []
    },
    {
      item: "otherFinAdminwcQuote",
      name: "Working Capital Quote",
      action: ["view", "create"],
      intro: "Apply for Working Capital Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "otherFinAdmincgtmseQuote",
      name: "CGTMSE Finance Quote",
      action: ["view", "create"],
      intro: "Apply for CGTMSE Quote",
      storeid: "15",
      selected: []
    }, {
      item: "otherFinAdminbgQuote",
      name: "Bank Gurantee  Quote",
      action: ["view", "create"],
      intro: "Apply for Bank Gurantee Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "otherFinAdminscfQuote",
      name: "Supply Chain Finance  Quote",
      action: ["view", "create"],
      intro: "Apply for Supply Chain Finance Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "contractManagementList",
      name: "Contract Management",
      action: ["view"],
      intro: "View contract list",
      storeid: "16", selected: []
    },




    {
      item: "ShipmentBookingQuotes",
      name: "Booking Quotes",
      action: ["view"],
      intro: "View shipment booking quotes",
      storeid: "17", selected: []
    },



    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "10",
      selected: []
    },


  ]);





  const introList = userTokenDetails?.type_id === 19 ? briefintro : userTokenDetails?.type_id === 8 ? briefintroFin : userTokenDetails?.type_id === 1 ? briefintroAdmin : [];


  const [togglecomp, setcomponent] = useState(false);
  const [toggleaddnew, setaddnew] = useState(false);

  const [editedCard, setEditedCard] = useState("");
  const [filteredcustomization, setfilteredCustomization] = useState({});
  const [expandedCard, setExpandedCard] = useState({});
  const [selectedCards, setSelectedCards] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [accessObj, setAccessObj] = useState([]);
  const [nextStep, setNextStep] = useState(false);

  const handlerole = () => {
    setaddnew(true);
    setAddNew((prevState) => !prevState);
  };
  const [filteredItems, setFilteredItems] = useState([]);

  const handleCardClick = (item) => {
    console.log(item, "this is item selceted------");
    setcomponent((prevState) => !prevState);
    setAddNew((prevState) => !prevState);
    // setExpandedCard(null);
    if (userTokenDetails.type_id === 19) {
      const foundKey = Object.keys(UserAcess).find((k) => k === item);
      const val = foundKey ? UserAcess[foundKey].split(",") : [];

      console.log(val, "hey this is val--->>>>>");
      setSelectedCards(val);

      const items = viewAccess.filter((access) => val.includes(access.item));
      setFilteredItems(items);

      console.log(items, "this is viewaccess new--->>>>");
      setExpandedCard(item);
    } else if (userTokenDetails.type_id === 8) {
      const foundKey = Object.keys(UserAcessFin).find((k) => k === item);
      const val = foundKey ? UserAcessFin[foundKey].split(",") : [];

      console.log(val, "hey this is val--->>>>>");
      setSelectedCards(val);

      const items = viewAccessFin.filter((access) => val.includes(access.item));
      setFilteredItems(items);

      console.log(items, "this is viewaccess new--->>>>");
      setExpandedCard(item);
    } else if (userTokenDetails.type_id === 1) {
      const foundKey = Object.keys(UserAcessAdmin).find((k) => k === item);
      const val = foundKey ? UserAcessAdmin[foundKey].split(",") : [];

      console.log(val, "hey this is val--->>>>>");
      setSelectedCards(val);

      const items = viewAccessAdmin.filter((access) => val.includes(access.item));
      setFilteredItems(items);

      console.log(items, "this is viewaccess new--->>>>");
      setExpandedCard(item);
    }

  };


  const [selectcustom, setselectcustom] = useState([]);

  const handleNewClick = (item) => {
    console.log(item, "this is new click item--->>>");
    setSelectedCards((prevSelectedCards) => {
      if (prevSelectedCards.includes(item)) {
        return prevSelectedCards.filter((card) => card !== item);
      } else {
        return [...prevSelectedCards, item];
      }
    });

    setselectcustom((selectcustom) => [...selectcustom, item]);

    console.log(selectcustom, "this is select custom--->>>");

    const foundKey = Object.keys(UserAcess).find((k) => k === item);
    const val = foundKey ? UserAcess[foundKey].split(",") : [];

    console.log(val, "hey this is val--->>>>>");
    //  setSelectedCards(val);

    const items = viewAccess.filter((access) => val.includes(access.item));
    setFilteredItems(items);
    setExpandedCard(item);


    selectedCards.forEach((item) => {
      if (UserAcess.hasOwnProperty(item)) {
        setAccessObj((prevAccessObj) => ([
          ...prevAccessObj,
          { [item]: UserAcess[item] },
        ]));
      }

      const foundKey = Object.keys(UserAcess).find((k) => k === item);
      console.log(foundKey, "this is item selected----/////");
      const valselected = foundKey ? UserAcess[foundKey].split(",") : [];

      const filtered = Object.keys(customizeUserAcess).filter((key) =>
        customizeUserAcess[key]
          .split(",")
          .some((val) => !valselected.includes(val))
      );
      console.log(filtered, "this is filtered----->>>>>>");
      const fcustomize = Object.keys(customizeUserAcess)
        .filter((key) => filtered.includes(key))
        .reduce((obj, key) => {
          obj[key] = customizeUserAcess[key];
          return obj;
        }, {});

      setfilteredCustomization(fcustomize);
    });
  };


  const handleEditChange = (e) => {
    setEditedCard(e.target.value);
  };

  const handleCreateClick = () => {
    const valuesToAdd = Object.values(accessObj).join(",");
    console.log(valuesToAdd, "valuestoadd--......");
    console.log(
      editedCard,
      "this is edited card val-->>>>",
      valuesToAdd,
      "this is values to addd"
    );
    setUserAccess((prevCustomizeUserAccess) => ({
      ...prevCustomizeUserAccess,
      [editedCard]: valuesToAdd,
    }));
    toastDisplay("Created Successfully", "success");
    setaddnew((prev) => !prev);
    // call("POST", "getnewroles", { role: { [editedCard]: valuesToAdd } });
    call('POST', 'rolesandorganizations', { role: editedCard, permissions: accessObj, userId }).then(() => toastDisplay("New Role created successfully", "success"))
  };
  // console.log(UserAcess, "this is customize user aceess---->>>>>>");
  const [selectedModes, setSelectedModes] = useState({});

  useEffect(() => {
    const initialModes = filteredItems.reduce((acc, item, index) => {
      acc[index] = {
        create: item.action.includes("create"),
        view: item.action.includes("view"),
      };
      return acc;
    }, {});
    setSelectedModes(initialModes);
  }, [filteredItems]);


  const handleCheckboxClick = (index, type) => {
    setSelectedModes((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [type]: !prevState[index]?.[type],
      },
    }));
  };

  const handleNext = () => {
    const value = `Custom Copy`;
    setEditedCard(value);


    setNextStep(true);
  };
  console.log(nextStep, "thhis is next step.");
  console.log(accessObj, "this is accessobj in custom---.....");
  const handleBack = () => {
    setNextStep(false);
    setSelectedCards([]);
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  // const handleCheckboxChange = (index, entry) => {
  //   console.log(entry, "this is checked enrtry-----")
  //   setCheckedItems(prevState => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //     item: entry
  //   }));
  //   console.log(entry, "this is entryyy---///////")

  //   const entryPair = Object.entries(UserAcess).find(([key, value]) => key === entry.item);
  //   setAccessObj({ ...accessObj, entryPair })
  //   if (entryPair) {
  //     // Destructure the key and value from the entryPair
  //     const [key, value] = entryPair;
  //     console.log({ [key]: value }, "found key-value pair--->>>>/////");
  //   } else {
  //     console.error(`Key "${entry.item}" not found in UserAcess`);
  //   }

  // };

  const [selectedValues, setSelectedValues] = useState([]);
  const handleCheckboxChange = (index, entry) => {
    console.log(entry, "this is checked entry-----");

    // Update the checked state for the checkbox
    setCheckedItems(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    const entryPair = Object.entries(UserAcess).find(([key, value]) => key === entry.item);
    if (entryPair) {
      const [key, value] = entryPair;
      console.log({ [key]: value }, "found key-value pair--->>>>/////");

      // Update the accessObj with the new entryPair
      setAccessObj(prevState => ([
        ...prevState,
        { [key]: value }
      ]));

      // Update the selected values array
      setSelectedValues(prevValues => {
        // If the checkbox is checked, add the values to the array
        if (!checkedItems[index]) {
          return [...prevValues, ...value.split(',')];
        } else {
          // If the checkbox is unchecked, remove the values from the array
          return prevValues.filter(val => !value.split(',').includes(val));
        }
      });
    } else {
      console.error(`Key "${entry.item}" not found in UserAcess`);
    }
  };

  console.log(selectedValues, "this is selected valuesss-->>>>")

  console.log(checkedItems, "checked items--->>>>///////")
  useEffect(() => {
    if (userTokenDetails.type_id === 19) {
      if (searchQuery === '') {
        setUserAccess(accessItem);
        setbriefIntro(briefitems);
      } else {
        const filtered = Object.entries(accessItem).filter(([key, value]) =>
          key.toLowerCase().includes(searchQuery.toLowerCase()) ||
          value.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const filteredObject = Object.fromEntries(filtered);
        console.log(filteredObject, "this is filtered ---->>>>");

        const filteredBriefIntro = briefitems.filter(entry => filteredObject.hasOwnProperty(entry.item));
        console.log(filteredBriefIntro, "this is brief introo--->>>/////");
        setbriefIntro(filteredBriefIntro);
      }
    } else if (userTokenDetails.type_id === 8) {
      if (searchQuery === '') {
        console.log("hello else-->>>>")
        setUserAccessFin(UserAcessFinancer);
        setbriefIntroFin(briefitemsFin);
      } else {
        const filtered = Object.entries(UserAcessFinancer).filter(([key, value]) =>
          key.toLowerCase().includes(searchQuery.toLowerCase()) ||
          value.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const filteredObject = Object.fromEntries(filtered);
        console.log(filteredObject, "this is filtered ---->>>>");

        const filteredBriefIntro = briefitemsFin.filter(entry => filteredObject.hasOwnProperty(entry.item));
        console.log(filteredBriefIntro, "this is brief introo--->>>/////");
        setbriefIntroFin(filteredBriefIntro);
      }
    } else {
      if (searchQuery === '') {
        console.log("hello else-->>>>")
        setUserAccessAdmin(UserAcessAdmin);
        setbriefIntroAdmin(briefitemsAdmin);
      } else {
        const filtered = Object.entries(UserAcessAdmin).filter(([key, value]) =>
          key.toLowerCase().includes(searchQuery.toLowerCase()) ||
          value.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const filteredObject = Object.fromEntries(filtered);
        console.log(filteredObject, "this is filtered ---->>>>");

        const filteredBriefIntro = briefitemsAdmin.filter(entry => filteredObject.hasOwnProperty(entry.item));
        console.log(filteredBriefIntro, "this is brief introo--->>>/////");
        setbriefIntroAdmin(filteredBriefIntro);
      }
    }
  }, [searchQuery, userTokenDetails.type_id]);


  console.log(UserAcess, "this is useraccess----->>>>")

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const [tableData, settabledata] = useState(Object.keys(UserAcess))
  const [addnew, booladdnew] = useState(false)
  const val = Object.keys(UserAcess)



  const handleShowAdd = (entry) => {
    booladdnew((prev) => !prev)
    if (userTokenDetails.type_id === 19) {


      const foundKey = Object.keys(UserAcess).find((k) => k === entry.item);
      const val = foundKey ? UserAcess[foundKey].split(",") : [];

      console.log(val, "hey this is val--->>>>>");
      //  setSelectedCards(val);

      const items = viewAccess.filter((access) => val.includes(access.item));
      setFilteredItems(items);
      setExpandedCard(entry.item);
    } else if (userTokenDetails.type_id === 8) {
      const foundKey = Object.keys(UserAcessFin).find((k) => k === entry.item);
      const val = foundKey ? UserAcessFin[foundKey].split(",") : [];

      console.log(val, "hey this is val--->>>>>");
      //  setSelectedCards(val);

      const items = viewAccessFin.filter((access) => val.includes(access.item));
      setFilteredItems(items);
      setExpandedCard(entry.item);
    } else if (userTokenDetails.type_id === 1) {
      const foundKey = Object.keys(UserAcessAdmin).find((k) => k === entry.item);
      const val = foundKey ? UserAcessAdmin[foundKey].split(",") : [];

      console.log(val, "hey this is val--->>>>>");
      //  setSelectedCards(val);

      const items = viewAccessAdmin.filter((access) => val.includes(access.item));
      setFilteredItems(items);
      setExpandedCard(entry.item);
    }
  }


  return (
    <>
      <div className="row py-2">
        {userTokenDetails?.type_id !== 1 && (<div
          className="card"
          style={{
            width: "28%",
            fontSize: "1rem",
            height: "10rem",
            cursor: "pointer",
            border: "dotted",
            marginLeft: "20px",
          }}
        >
          <div
            className="card-body d-flex align-items-center justify-content-center"
            onClick={() => handlerole()}
          >
            Add New +
          </div>
        </div>
        )
        }

        <div className="row mt-3">
          {Object.keys(
            userTokenDetails?.type_id === 19
              ? UserAcess
              : userTokenDetails?.type_id === 8
                ? UserAcessFinancer
                : userTokenDetails?.type_id === 1 ? UserAcessAdmin : []
          ).map((item, index) => {
            const briefIntroList = userTokenDetails?.type_id === 8 ? briefintroFin : userTokenDetails?.type_id === 19 ? briefintro : userTokenDetails?.type_id === 1 ? briefintroAdmin : [];
            const intro = briefIntroList.find(
              (intro) => intro.item === item
            )?.intro;
            return (
              <div className="col-md-4 pt-2" key={index}>
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className={`card ml-2 d-flex justify-content-between align-items-center ${accessObj[item] ? "selectedacl" : ""
                      }`}
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                      height: "10rem",
                      cursor: "pointer",
                      border: accessObj[item]
                        ? "2px solid #5CB8D3"
                        : "1px solid #ccc",
                    }}
                    onClick={() => {
                      if (!isEditable) {
                        const temp = [...accessObj];
                        console.log(temp, "this is temp ");

                        const itemIndex = temp.findIndex(element => element.item === item);
                        if (itemIndex !== -1) {
                          // If the item exists in the array, remove it
                          temp.splice(itemIndex, 1);
                        } else {
                          // If the item does not exist in the array, add it
                          let userAccess =
                            userTokenDetails?.type_id === 19
                              ? UserAcess
                              : userTokenDetails?.type_id === 8
                                ? UserAcessFinancer
                                : [];

                          const accessItem = userAccess.find(element => element.item === item);
                          if (accessItem) {
                            temp.push(accessItem);
                            console.log(accessItem, "this is temp item--->>>>");
                          } else {
                            console.error(`Key "${item}" not found in UserAccess`);
                          }
                        }
                        setAccessObj(temp);
                        setExpandedCard(item);
                      }
                    }}

                  >
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <div
                        onClick={() => handleCardClick(item)}
                        className="d-flex align-items-center"
                      >
                        <span>{item}</span>
                        <img
                          src={"/assets/images/arrow-right.png"}
                          alt="Arrow Right"
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                    {intro && (
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "0.8rem",
                          color: "#777",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        {intro}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {togglecomp ? (
        <FinanceInvoiceModal
          limitinvoice={togglecomp}
          closeSuccess={() => {
            setcomponent(!togglecomp);
          }}
          modalSize={"xl"}
          marginTop={"1rem"}
        >
          <div
            className="text-center"
            style={{
              border: "1px solid lightgrey",
              borderRadius: "10px",
              marginTop: "1rem",
              width: "67rem",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
          >
            <div className="col-12">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                }}
              >
                <label
                  className="font-size-18 mb-2 text-center"
                  style={{
                    color: "#5CB8D3",
                    marginBottom: "8rem",
                    marginTop: "2rem",
                  }}
                >
                  {`Permissions for ${expandedCard}`}
                </label>
              </div>

              <div
                className="mb-12 mt-2 py-8 d-flex justify-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                }}
              >
                Users with this role can perform the access the following
                permissions
              </div>
              <div className="d-flex flex-column align-items-center">
                <div
                  style={{
                    width: "100%",
                    marginBottom: "15px",
                    marginTop: "2rem",
                  }}
                >
                  {filteredItems.map((ele, index) => (
                    <div
                      key={index}
                      className="p-2 py-3 row align-items-center"
                    >
                      <div className="col-4 text-center">
                        <div>{ele.name}</div>
                        <p
                          style={{
                            fontSize: "0.8rem",
                            color: "#777",
                          }}
                        >
                          {ele.intro}
                        </p>
                      </div>
                      <div className="col-8 text-center">
                        <div className="d-flex justify-content-around align-items-center">
                          <div
                            className="checkbox-container"
                            onClick={() => handleCheckboxClick(index, "create")}
                          >
                            {ele.action.includes("create") && (
                              <img
                                src={
                                  selectedModes[index]?.create
                                    ? "/assets/images/checked-green.png"
                                    : "/assets/images/unchecked-box.png"
                                }
                                height={20}
                                width={20}
                                className="checkbox-image cursor"
                                alt="checkbox"
                              />
                            )}
                            {ele.action.includes("create") ? "Create" : ""}
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() => handleCheckboxClick(index, "view")}
                          >
                            {ele.action.includes("view") && (
                              <img
                                src={
                                  selectedModes[index]?.view
                                    ? "/assets/images/checked-green.png"
                                    : "/assets/images/unchecked-box.png"
                                }
                                height={20}
                                width={20}
                                className="checkbox-image cursor"
                                alt="checkbox"
                              />
                            )}
                            {ele.action.includes("view") ? "View" : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null}

      {toggleaddnew ? (
        <FinanceInvoiceModal
          limitinvoice={toggleaddnew}
          closeSuccess={() => {
            setaddnew(!toggleaddnew);
          }}
          modalSize={"xl"}
          marginTop={"1rem"}
        >
          {!nextStep && (
            <>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: '100%', padding: '0 2rem' }}
              >
                <label
                  className="mt-2 mb-4"
                  style={{
                    color: "#5CB8D3",
                    fontSize: "1rem",
                    marginLeft: "7rem"
                  }}
                >
                  Select one of the items below to customize your card
                </label>
                <input
                  type="text"
                  className="form-control mb-4"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                  style={{ width: "25rem" }}
                />
              </div>
            </>


          )}
          <div className="text-center">
            {nextStep && (
              <div className="pt-2">
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: "4rem", columnGap: "8rem" }}
                >
                  <button
                    className="mt-2 new-btn w-10  py-2 px-2 text-white cursor"
                    style={{ marginLeft: "4rem" }}
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <label
                    className="mt-2 cursor-pointer"
                    style={{
                      color: "#5CB8D3",
                      fontSize: "1rem",
                      marginBottom: "",
                    }}
                  >
                    This is card selected for your customization and you can
                    select more permissions.
                  </label>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="card ml-2 "
                    style={{
                      width: "50%",
                      fontSize: "1rem",
                      height: "12rem",
                      marginLeft: "4rem",
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center card-body item-center cursor-pointer">
                      <div style={{ position: "relative", width: "80%" }}>
                        <input
                          type="text"
                          value={editedCard}
                          onChange={handleEditChange}
                          style={{
                            width: "100%",
                            fontSize: "1rem",
                            border: "1px solid lightgray",
                            textAlign: "center",
                            outline: "none",
                            paddingRight: "25px", // Add padding to make space for the image
                            marginBottom: "1rem",
                            borderRadius: "1rem",
                            height: "2.5rem"
                          }}
                        />
                        <img
                          src={"/assets/images/edit.png"}
                          height={20}
                          width={20}
                          className="cursor"
                          alt="cb"
                          style={{
                            position: "absolute",
                            right: "1rem", // Position the image inside the input box
                            top: "40%",
                            transform: "translateY(-50%)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>


                <button
                  className={`new-btn w-20 py-2 px-2 text-white cursor`}
                  onClick={handleCreateClick}
                  style={{
                    marginTop: "3rem",
                  }}
                >
                  Create
                </button>
              </div>
            )}

            {!nextStep && (
              <>
                <div className="row">
                  <div style={{ border: "1px solid lightgrey", borderRadius: "1rem" }}>
                    <table className="styled-table">
                      <thead>
                        <tr
                          style={{
                            height: "3.2rem",
                            alignItems: "baseline",
                            borderBottom: "1px solid lightgrey"
                          }}
                          className="position-relative font-wt-600 text-color-value font-size-13 pl-2"
                        >
                          <th>Action</th>
                          <th style={{ width: "30rem" }}>Role</th>
                          <th>Description</th>

                        </tr>
                      </thead>
                      <tbody>
                        {introList.map((entry, index) => (
                          <>
                            <tr key={index} style={{ borderBottom: "1px solid lightgrey" }}>
                              <td>
                                <img
                                  src={
                                    checkedItems[index]
                                      ? "/assets/images/checked-green.png"
                                      : "/assets/images/unchecked-box.png"
                                  }
                                  height={20}
                                  width={20}
                                  className="checkbox-image cursor"
                                  alt="checkbox"
                                  onClick={() => handleCheckboxChange(index, entry)}
                                />
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                <img
                                  src="assets/images/add_icon.png"
                                  alt="add icon"
                                  style={{ width: '15px', height: '15px', marginRight: '8px', cursor: "pointer" }}
                                  onClick={() => handleShowAdd(entry)}
                                />
                                {entry.item}
                              </td>
                              <td style={{ color: "grey" }}>{entry.intro}</td>
                            </tr>
                            {(expandedCard === entry.item && addnew === true) && (
                              <tr>
                                <td colSpan="3" style={{ padding: 0 }}>
                                  <div
                                    className="expanded-content text-center"
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "10px",
                                      marginTop: "0",
                                      width: "68rem",
                                      marginLeft: "0",
                                      marginBottom: "1rem",
                                      padding: "1rem",
                                    }}
                                  >
                                    <div className="col-12">
                                      <label
                                        className="font-size-18 mb-2 text-center d-flex justify-center"
                                        style={{
                                          color: "#5CB8D3",
                                          marginLeft: "20rem",
                                          marginBottom: "2rem",
                                          marginTop: "",
                                        }}
                                      >
                                        {`Permissions for ${expandedCard}`}
                                      </label>
                                      <div className="d-flex flex-column align-items-center">
                                        <div
                                          style={{
                                            width: "100%",
                                            marginBottom: "15px",
                                          }}
                                        >
                                          {filteredItems.map((ele, idx) => (
                                            <div
                                              key={idx}
                                              className="p-2 py-3 row align-items-center"
                                            >
                                              <div className="col-4 text-center">
                                                <div>{ele.name}</div>
                                              </div>
                                              <div className="col-8 text-center">
                                                <div className="d-flex justify-content-around align-items-center">
                                                  <div
                                                    className="checkbox-container"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleCheckboxClick(idx, "create");
                                                    }}
                                                  >
                                                    {ele.action.includes("create") && (
                                                      <img
                                                        src={
                                                          selectedModes[idx]?.create
                                                            ? "/assets/images/checked-green.png"
                                                            : "/assets/images/unchecked-box.png"
                                                        }
                                                        height={20}
                                                        width={20}
                                                        className="checkbox-image cursor"
                                                        alt="checkbox"
                                                      />
                                                    )}
                                                    {ele.action.includes("create") ? "Create" : ""}
                                                  </div>
                                                  <div
                                                    className="checkbox-container"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleCheckboxClick(idx, "view");
                                                    }}
                                                  >
                                                    {ele.action.includes("view") && (
                                                      <img
                                                        src={
                                                          selectedModes[idx]?.view
                                                            ? "/assets/images/checked-green.png"
                                                            : "/assets/images/unchecked-box.png"
                                                        }
                                                        height={20}
                                                        width={20}
                                                        className="checkbox-image cursor"
                                                        alt="checkbox"
                                                      />
                                                    )}
                                                    {ele.action.includes("view") ? "View" : ""}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>


                    </table>
                  </div>
                </div>
                <button
                  className={`mt-4 new-btn w-20 py-2 px-2 text-white cursor`}
                  onClick={handleNext}
                >
                  Next{" "}
                </button>
              </>
            )}




          </div>
        </FinanceInvoiceModal>
      ) : null}
    </>
  );
};

export default RolesTab;
