import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import call from '../../service'
import config from '../../config.json'
import { NewInput, NewSelect } from '../../utils/newInput'
import { loadScript } from '../../utils/PaymentGateway'
import { useRef } from 'react'
import { decryptData, isEmpty } from '../../utils/myFunctions'
import { environment } from '../../urlConstants'

const PaymentScreen = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const pid = queryParams.get('pid')
  let forShipping = queryParams.get('forShipping')
  let forGeneric = queryParams.get('forGeneric')

  const [paymentmode, setpaymentMode] = useState('UPI')
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [wallets, setwallets] = useState([])
  const [netBanks, setNetBanks] = useState([])
  const paymentRef = useRef(null)
  const [showLoader, setshowLoader] = useState(false)
  const [isValidUser, setValidUser] = useState(false)
  const [paymentdetails, setpaymentdetails] = useState({})
  const [errormessage, setErrormessage] = useState('')
  const timer = useRef(null)
  const [timerString, setTimerString] = useState('10:00')
  const paymentsdetRef = useRef(null)


  const initatePaymentGateway = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/razorpay.js"
    );
    const options = {
      key: environment === 'dev' ? config.razorypay_key_id_dev : config.razorypay_key_id_prod, // Enter the Key ID generated from the Dashboard
    };

    paymentRef.current = new window.Razorpay(options);
    paymentRef.current.once('ready', function (response) {
      //window.location
      const { netbanking, wallet } = response.methods
      setNetBanks(Object.keys(netbanking).map(data => {
        return {
          name: data
        }
      }))
      setwallets(Object.keys(wallet).map(data => {
        return {
          name: data
        }
      }))
    })
  }
  const getPaymentdetails = async () => {
    try {
      setshowLoader(true)
      const result = await axios({
        method: 'POST',
        url: config.baseUrl + 'getpaymentdata',
        data: { pid: pid?.split(" ").join("+"), forShipping, forGeneric }
      })
      setValidUser(result.data.success)
      setshowLoader(false)
      setpaymentdetails(result.data.message)
      paymentsdetRef.current = result.data.message
      initatePaymentGateway()
      if (!result.data.success) {
        setErrormessage(result.data.message)
      }
      if (result.data.success) {
        timer.current = setInterval(function () {
          timeBetweenDates(result.data.message.createdAt, result.data.message.expires_at);
        }, 1000);
      }
    } catch (e) {
      console.log('error in fetch payments', e)
      setValidUser(false)
      setshowLoader(false)
    }
  }
  useEffect(() => {
    getPaymentdetails()
  }, [])

  const handValidations = () => {
    let errors = {}
    if (paymentmode === 'UPI') {
      if (!data.upiId) {
        errors.upiId = 'UPI ID cannot be Empty'
      }
    }
    if (paymentmode === 'Wallets') {
      if (!data.wallet) {
        errors.wallet = 'Wallet cannot be Empty'
      }
    }
    if (paymentmode === 'Cards') {
      if (!data.cardNo) {
        errors.cardNo = 'Card Number cannot be Empty'
      }
      if (!data.expyear) {
        errors.expyear = 'Expiry Year cannot be Empty'
      }
      if (!data.expmonth) {
        errors.expmonth = 'Expiry Month cannot be Empty'
      }
      if (!data.holderName) {
        errors.holderName = 'Card Holder Name cannot be Empty'
      }
      if (!data.cvv) {
        errors.cvv = 'CVV Number cannot be Empty'
      }
    }
    if (paymentmode === 'Netbanking') {
      if (!data.netbank) {
        errors.netbank = 'Bank cannot be Empty'
      }
    }
    if (isEmpty(errors)) {
      onPaynow()
    } else {
      setErrors(errors)
    }

  }
  const onPaymentFailure = async () => {
    console.log('Paymentsssss', paymentsdetRef.current)
    let transactionId = "T" + new Date().getTime()
    clearInterval(timer.current);
    let url = ''
    let reqObj = {
      forShipping,
      forGeneric,
      userId: paymentsdetRef.createdBy,
      modeOfPayment: paymentmode,
      order_data: {
        payment_id: transactionId
      },
      userId: paymentsdetRef.current.createdBy,
    }
    if (paymentsdetRef?.payment_type?.includes('Topup')) {
      reqObj["topup_id"] = paymentsdetRef.current.pay_id
      url = 'failedTopupPayment'
    } else {
      reqObj["plan_id"] = paymentsdetRef.current.pay_id
      url = 'failedPlanPayment'
    }
    const paymentfailed = await axios({
      method: 'POST',
      url: config.baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + decryptData(paymentsdetRef.current.userToken)
      },
      data: reqObj
    })
    const paymendetailsupdate = await axios({
      method: 'POST',
      url: config.baseUrl + 'updatePaymentdetails',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + decryptData(paymentsdetRef.current.userToken)
      },
      data: {
        forShipping,
        forGeneric,
        status: 2,
        userId: paymentsdetRef.current.createdBy,
        modeOfPayment: paymentmode,
        transactionId: transactionId,
        failure_reason: 'Payment Timer Expired',
        tbl_payment_id: paymentsdetRef.current.id
      }
    })
    if (forGeneric) {
      window.location = '/failedPayment?forGeneric=true'
    }
    else {
      window.location = '/failedPayment'
    }
  }
  const onPaynow = async () => {
    if (isValidUser) {
      setshowLoader(true)
      try {
        const result = await axios({
          method: 'POST',
          url: config.baseUrl + 'createOrder',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + decryptData(paymentdetails.userToken)
          },
          data: {
            amount: paymentdetails.payment_amount, userId: paymentdetails.createdBy,
            currency: forShipping ? paymentdetails.currency : (paymentdetails.currency === "$" ? "USD" : "INR")
          }
        })
        if (!result) {
          alert("Server error. Are you online?");
          return;
        }
        const { amount, id: order_id, currency } = result.data.message;
        let commonObj = {
          amount: amount.toString(),
          currency: currency,
          email: paymentdetails.email_id,
          order_id: order_id,
          contact: paymentdetails.contact_number,
        }
        let paymentObj = {}
        switch (paymentmode) {
          case 'UPI':
            paymentObj = {
              method: 'upi',
              vpa: data.upiId
            }
            break;
          case 'Wallets':
            paymentObj = {
              method: 'wallet',
              wallet: data.wallet
            }
            break;
          case 'Cards':
            paymentObj = {
              method: 'card',
              card: {
                number: data.cardNo,
                name: data.holderName,
                expiry_month: data.expmonth,
                expiry_year: data.expyear,
                cvv: data.cvv
              }
            }
            break
          case 'Netbanking':
            paymentObj = {
              method: 'netbanking',
              bank: data.netbank
            }
            break
        }
        paymentRef.current.createPayment({
          ...commonObj,
          ...paymentObj
        })

        paymentRef.current.on('payment.success', async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          let url = ''
          let reqObj = {
            userId: paymentdetails.createdBy,
            modeOfPayment: paymentmode,
            order_data: data,
            charges: paymentdetails.currency + " " + paymentdetails.payment_amount
          }
          if (paymentdetails?.payment_type?.includes('Topup')) {
            reqObj["topup_id"] = paymentdetails.pay_id
            url = 'buyTopup'
          } else {
            reqObj["plan_id"] = paymentdetails.pay_id
            if (forShipping) {
              reqObj["tbl_payment_id"] = paymentdetails.id
              url = 'bookShipment'
            }
            else if (forGeneric) {
              reqObj["tbl_payment_id"] = paymentdetails.id
              url = 'sendInvoiceForUdyamDetails'
            }
            else {
              url = 'buyNewPlan'
            }
          }
          if (url) {
            const paymentsuccess = await axios({
              method: 'POST',
              url: config.baseUrl + url,
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + decryptData(paymentdetails.userToken)
              },
              data: reqObj
            })
          }
          const paymendetailsupdate = await axios({
            method: 'POST',
            url: config.baseUrl + 'updatePaymentdetails',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + decryptData(paymentdetails.userToken)
            },
            data: {
              forShipping,
              forGeneric,
              status: 1,
              userId: paymentdetails.createdBy,
              modeOfPayment: paymentmode,
              transactionId: order_id,
              tbl_payment_id: paymentdetails.id
            }
          })
          setshowLoader(false)
          if (forShipping) {
            window.location = '/paymentSuccess?forShipping=true'
          }
          else if (forGeneric) {
            window.location = '/paymentSuccess?forGeneric=true'
          }
          else {
            window.location = '/paymentSuccess'
          }
        });

        paymentRef.current.on('payment.error', async function (error) {
          console.log('errorrrr', error)
          let url = ''
          let reqObj = {
            forShipping,
            forGeneric,
            userId: paymentdetails.createdBy,
            modeOfPayment: paymentmode,
            order_data: error.error.metadata,
            tbl_payment_id: paymentdetails.id,
            charges: paymentdetails.currency + " " + paymentdetails.payment_amount
          }
          if (paymentdetails?.payment_type?.includes('Topup')) {
            reqObj["topup_id"] = paymentdetails.pay_id
            url = 'failedTopupPayment'
          } else {
            reqObj["plan_id"] = paymentdetails.pay_id
            url = 'failedPlanPayment'
          }
          const paymentfailed = await axios({
            method: 'POST',
            url: config.baseUrl + url,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + decryptData(paymentdetails.userToken)
            },
            data: reqObj
          })
          const paymendetailsupdate = await axios({
            method: 'POST',
            url: config.baseUrl + 'updatePaymentdetails',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + decryptData(paymentdetails.userToken)
            },
            data: {
              forShipping,
              forGeneric,
              status: 2,
              userId: paymentdetails.createdBy,
              modeOfPayment: paymentmode,
              transactionId: order_id,
              failure_reason: error.error.description,
              tbl_payment_id: paymentdetails.id
            }
          })
          setshowLoader(false)
          if (forGeneric) {
            window.location = '/failedPayment?forGeneric=true'
          }
          else {
            window.location = '/failedPayment'
          }
        });
      } catch (e) {
        console.log('errorrrrCatch', e)
        setshowLoader(false)
      }
    }

  }
  const handleChange = (value) => {
    setpaymentMode(value)
  }
  const handInputChange = (event, maxlength) => {
    if (maxlength) {
      if (event.target.value?.length <= maxlength) {
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
        return
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }
  async function timeBetweenDates(startdate, expdate) {
    const sdate = new Date()
    const edate = new Date(expdate)
    var difference = edate.getTime() - sdate.getTime();

    if (difference <= 0) {
      // Timer done
      onPaymentFailure()
    } else {

      var seconds = Math.floor(difference / 1000);
      var minutes = Math.floor(seconds / 60);

      minutes %= 60;
      seconds %= 60;
      setTimerString(minutes + ":" + seconds)
    }
  }
  return (
    <div className="col-md-11 col-sm-12 mx-auto mt-4">
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="p-2 card border-0 rounded-3 card-layout">
        {isValidUser ?
          <div className='p-5 row'>
            <div className=''>
              <div className='col-md-8 col-sm-12 mt-'>
                <img src={'/assets/images/logos/logo_1.png'} alt="" height={80} width={190} />
              </div>
              <div className='col-md-4 col-sm-12 mt-2'>
                <label className='font-size-16 font-wt-600'>{`Timer Left ${timerString} Minutes`}</label>
              </div>
            </div>
            <div className='col-md-8 col-sm-12 mt-2'>
              {(paymentdetails.currency === "₹" || paymentdetails.currency === "INR") ?
                <div className="form-check cursor ps-0 py-2 " onClick={() => handleChange("UPI")}>
                  <input className="form-check-input" type="radio" value={"UPI"} checked={paymentmode === 'UPI'} />
                  <label className="form-check-label" >
                    UPI
                  </label>
                  {paymentmode === 'UPI' &&
                    <div className="row align-items-center mt-2">
                      <div className="form-group col-md-6 mb-0">
                        <NewInput type={"text"} label={"UPI ID"}
                          name={"upiId"} value={data.upiId} error={errors.upiId}
                          onChange={handInputChange} />
                      </div>
                    </div>
                  }
                </div>
                : null
              }

              {(paymentdetails.currency === "₹" || paymentdetails.currency === "INR") ?
                <div className="form-check cursor ps-0 py-2" onClick={() => handleChange("Wallets")}>
                  <input className="form-check-input" type="radio" value={"Wallets"} checked={paymentmode === 'Wallets'} />
                  <label className="form-check-label" >
                    Wallets
                  </label>
                  {paymentmode === 'Wallets' &&
                    <div className="row align-items-center mt-2">
                      <div className="form-group col-md-6 mb-0">
                        <NewSelect isAstrix={true} label={"Wallet"}
                          selectData={wallets} name={"wallet"}
                          value={data.wallet} optionLabel={"name"} optionValue={'name'}
                          onChange={handInputChange} error={errors.wallet} />
                      </div>
                    </div>
                  }
                </div>
                : null
              }


              <div className="form-check cursor ps-0  py-2 " onClick={() => handleChange("Cards")}>
                <input className="form-check-input" type="radio" value={"Cards"} checked={paymentmode === 'Cards'} />
                <label className="form-check-label">
                  Debit / Credit / ATM Card
                </label>
                {paymentmode === 'Cards' &&
                  <div>
                    <div className="d-flex flex-row align-items-center mt-2">
                      <div className="form-group col-md-5 col-sm-12 mb-0">
                        <NewInput type={"number"} label={"Card No"}
                          name={"cardNo"} value={data.cardNo} error={errors.cardNo}
                          onChange={(event) => handInputChange(event, 16)} />
                      </div>
                      <div className="form-group col-md-2 mb-0">
                        <NewInput type={"number"} label={"Month"}
                          name={"expmonth"} value={data.expmonth} error={errors.expmonth}
                          onChange={(event) => handInputChange(event, 2)} />
                      </div>
                      <label> / </label>
                      <div className="form-group col-md-2 mb-0">
                        <NewInput type={"number"} label={"Year"}
                          name={"expyear"} value={data.expyear} error={errors.expyear}
                          onChange={(event) => handInputChange(event, 4)} />
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mt-2">
                      <div className="form-group col-md-5 mb-0">
                        <NewInput type={"text"} label={"Name of the holder"}
                          name={"holderName"} value={data.holderName} error={errors.holderName}
                          onChange={handInputChange} />
                      </div>
                      <div className="form-group col-md-2 mb-0">
                        <NewInput type={"password"} label={"CVV"}
                          name={"cvv"} value={data.cvv} error={errors.cvv}
                          onChange={handInputChange} maxlength={"3"} />
                      </div>
                    </div>

                  </div>

                }
              </div>

              {(paymentdetails.currency === "₹" || paymentdetails.currency === "INR") ?
                <div className="form-check cursor ps-0  py-2 " onClick={() => handleChange("Netbanking")}>
                  <input className="form-check-input" type="radio" value={"Netbanking"} checked={paymentmode === 'Netbanking'} />
                  <label className="form-check-label">
                    Net banking
                  </label>
                  {paymentmode === 'Netbanking' &&
                    <div className="row align-items-center mt-2">
                      <div className="form-group col-md-6 mb-0">
                        <NewSelect isAstrix={true} label={"Net Banking"}
                          selectData={netBanks} name={"netbank"}
                          value={data.netbank} optionLabel={"name"} optionValue={'name'}
                          onChange={handInputChange} error={errors.netbank} />
                      </div>
                    </div>
                  }
                </div>
                : null
              }

              <button type="button" onClick={() => { handValidations() }}
                className={` new-btn w-auto py-2 px-5 mt-4 text-white cursor`}>
                Continue
              </button>
            </div>
            <div className='col-md-4 col-sm-12 mt-2'>
              <div className="p-4 card border-0 rounded-3 card-layout">
                <label className='font-size-16 font-wt-600 text-custom2 '>Price details</label>
                <label className='font-size-16 font-wt-500 mt-2'>{paymentdetails.payment_type} </label>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <label className='font-size-13 font-wt-400'>Plan Cost</label>
                  <label className='font-size-13 font-wt-500'> {paymentdetails.currency + " " + paymentdetails.payment_amount} </label>
                </div>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <label className='font-size-13 font-wt-400'>Coupon</label>
                  <label className='font-size-13 font-wt-500'> {"- " + paymentdetails.currency + " " + "0"} </label>
                </div>
                <div className='dashedBorder'></div>
                <div className='d-flex flex-row justify-content-between mt-3'>
                  <label className='font-size-16 font-wt-600 text-custom2'>Total</label>
                  <label className='font-size-16 font-wt-600 text-custom2'> {paymentdetails.currency + " " + paymentdetails.payment_amount} </label>
                </div>
              </div>
            </div>

          </div>
          : <h1 className='text-center'>{errormessage ? errormessage : 'Unauthorized User'}</h1>
        }
      </div>
    </div>
  )
}

export default PaymentScreen