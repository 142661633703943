import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import NewTablev2 from '../../../../utils/newTablev2';

const Movement = ({ allData }) => {
  const [activeButton, setActiveButton] = useState('Churn');
  const [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    if (allData.length) {
      calculateDataToShow(allData, activeButton);
    }
  }, [allData, activeButton]);

  const calculateDataToShow = (data, buttonType) => {
    const twelveMonthsAgo = moment().subtract(12, 'months');
    const monthlyData = {};

    // Initialize monthlyData with empty sets for unique partyNames
    for (let i = 0; i < 12; i++) {
      const month = twelveMonthsAgo.clone().add(i + 1, 'months').format('MMMM YYYY');
      monthlyData[month] = new Set();
    }

    // Populate unique partyNames in each month
    data.forEach(item => {
      const monthYear = moment(item.date).format('MMMM YYYY');
      if (monthlyData[monthYear]) {
        if (item.partyName) {
          monthlyData[monthYear].add(item.partyName);
        }
      }
    });

    // Calculate churn counts based on unique partyNames missing in each month
    const churnCounts = {};
    const months = Object.keys(monthlyData);

    for (let i = 1; i < months.length; i++) {
      const currentMonth = months[i];
      const previousMonth = months[i - 1];
      let churnCount;
      if (buttonType === "Churn") { churnCount = calculateChurn(monthlyData[previousMonth], monthlyData[currentMonth]); }
      else { churnCount = calculateRetention(monthlyData[previousMonth], monthlyData[currentMonth]) }
      churnCounts[currentMonth] = churnCount;
    }

    // Convert churnCounts object into an array of objects for NewTablev2 component
    const churnData = Object.keys(churnCounts).map(month => ({
      month,
      count: churnCounts[month]
    }));

    setDataToShow(churnData);
  };

  const calculateChurn = (previousSet, currentSet) => {
    // Calculate churn as the difference between previous month's set and current month's set
    let churnCount = 0;
    previousSet.forEach(partyName => {
      if (!currentSet.has(partyName)) {
        churnCount++;
      }
    });
    return churnCount;
  };
  const calculateRetention = (previousSet, currentSet) => {
    // Calculate retention as the number of repeated partyNames from previous month in current month
    let retentionCount = 0;
    currentSet.forEach(partyName => {
      if (previousSet.has(partyName)) {
        retentionCount++;
      }
    });
    return retentionCount;
  };
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const renderRows = () => {
    return dataToShow.map((row, index) => (
      <tr key={index}>
        <td>{row.month}</td>
        <td>{row.count}</td>
      </tr>
    ));
  };

  const columns = [
    { name: 'Previous 12 Months', subColumns: 'Previous 12 Months' },
    { name: 'Customers Count', subColumns: 'Customers Count' }
  ];

  return (
    <div>
      <div className='mt-2 mb-2'>
        <button
          style={{ width: '100px' }}
          className={`btn ${activeButton === 'Churn' ? 'btn-dark' : 'btn-light'} me-1`}
          onClick={() => handleButtonClick('Churn')}
        >
          Churn
        </button>
        <button
          style={{ width: '100px' }}
          className={`btn ${activeButton === 'Retention' ? 'btn-dark' : 'btn-light'}`}
          onClick={() => handleButtonClick('Retention')}
        >
          Retention
        </button>
      </div>
      <NewTablev2 showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }} columns={columns} addTableClass='custom-table-class'>
        {renderRows()}
      </NewTablev2>
      <div><p style={{ color: "#f0ab5d" }}>* All values are calculated considering previous month data</p></div>

    </div>
  );
};

export default Movement;
