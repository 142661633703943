import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import call from "../../../service";
import { FileInput } from "../../../utils/FileInput";
import avatarUrl from "../../../utils/makeAvatarUrl";
import { convertImageToPdf, copyToClipboard } from "../../../utils/myFunctions";
import { InputWithSelect, NewInput, NewSelect } from "../../../utils/newInput";
import { NewTable } from "../../../utils/newTable";
import toastDisplay from "../../../utils/toastNotification";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import InputDropdown from "./inputDropdown";
import InputEdit from "./inputEdit";
import SubUserModal from "./SubUserModal";
import ExtraDetailsForLimitApplication from "../../InvoiceDiscounting/extraDetailsForLimitApplication";

const UserTab = ({
  formData,
  handleChange,
  handleUserSubmit,
  handleFile,
  userTokenDetails,
  setData,
  userId,
  countrys,
  errors,
  userTypeId,
}) => {
  // console.log("userTokenDetailsssssssssssssssssssssssssssss", userTokenDetails);
  const [modal, setModal] = useState(false);
  const [changeProfilePic, setChangeProfilePic] = useState(false);
  const [removeProfilePic, setRemoveProfilePic] = useState(false);
  const [data, setuserdata] = useState({});
  const [subUserList, setsubUserList] = useState([]);
  const [subUserModal, setSubUserModal] = useState(false);
  const [viewdetails, setviewdetails] = useState({
    isVisible: false,
    data: {},
  });
  const [userCurrentPlan, setUserCurrentPlan] = useState({});
  const [onSave, toggleOnSave] = useState(false);
  console.log(subUserModal, "subUserModal------->>>>>>>");

  const getSubUsers = () => {
    call("POST", "getSubUsers", { parent_id: userId })
      .then((result) => {
        setsubUserList(formatDataForTable(result));
      })
      .catch((e) => {
        console.log("eror in getSubUsers", e);
      });
    call("POST", "getUserCurrentPlan", { userId }).then((res) => {
      setUserCurrentPlan(res);
    });
  };
  useEffect(() => {
    getSubUsers();
  }, []);

  function formatDataForTable(data) {
    let tableData = [];
    let row = [];
    data.forEach((item, index) => {
      const modules = item.UserPermissions
        ? JSON.parse(item.UserPermissions)
        : [];
      const AccessArr = Object.keys(modules).map((data) => data);
      row[0] = item.name_title + " " + item.contact_person;
      row[1] = "+ " + item.phone_code + " " + item.contact_number;
      row[2] = item.email_id;
      row[3] =
        AccessArr.slice(0, 2).join(",") +
        (AccessArr.length > 2 ? "+" + (AccessArr.length - 2) + " more" : "");
      row[4] = (
        <label
          className="text-color1 font-wt-600 m-0 cursor"
          onClick={() => {
            setSubUserModal(true);
            setviewdetails({
              isVisible: true,
              data: item,
            });
          }}
        >
          View Details
        </label>
      );
      tableData.push(row);
      row = [];
    });
    return tableData;
  }

  console.log(viewdetails, "this is viewdetails---->>>>>");

  return (
    <div className="">
      {modal && (
        <div
          class="modal show"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div
            class="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close"
                  onClick={() => {
                    setModal(false);
                    setChangeProfilePic(false);
                    setRemoveProfilePic(false);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {removeProfilePic ? (
                  <div className="row">
                    <div className="text-center p-2">
                      <img
                        className="rounded-circle profileImg"
                        src={"/assets/images/Questionmark.png"}
                        alt=""
                        height={30}
                        width={30}
                      />
                    </div>
                    <label className="font-size-14 font-wt-600 text-center p-2">
                      Are you sure you want to remove profile picture?
                    </label>
                    <div className="d-flex justify-content-around">
                      <button
                        className={`my-4 new-btn w-17 py-2 px-2 bg-white text-color1 cursor viewdocbtn`}
                        onClick={() => {
                          setModal(false);
                          setChangeProfilePic(false);
                          setRemoveProfilePic(false);
                          setData({
                            ...formData,
                            user_avatar: null,
                          });
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}
                        onClick={() => {
                          setModal(false);
                          setChangeProfilePic(false);
                          setRemoveProfilePic(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="text-center p-2">
                      <img
                        className="rounded-circle profileImg"
                        src={
                          formData.user_avatar
                            ? typeof formData.user_avatar == "string"
                              ? avatarUrl(formData.user_avatar)
                              : formData.user_avatar.filebase64
                            : "/assets/images/user-profile.png"
                        }
                        alt=""
                        height={60}
                        width={60}
                      />
                    </div>
                    {changeProfilePic ? (
                      <div className="row">
                        <label className="font-size-12">
                          Change Profile Picture
                        </label>
                        <div className="col">
                          <FileInput
                            name={"user_avatar"}
                            value={formData.user_avatar}
                            error={errors.user_avatar}
                            onChange={(e) => {
                              handleFile(e, true);
                              setModal(false);
                              setChangeProfilePic(false);
                              setRemoveProfilePic(false);
                            }}
                            isEditable={true}
                            userTokenDetails={userTokenDetails}
                            onUploadCancel={() => {
                              setData({
                                ...formData,
                                user_avatar: null,
                              });
                            }}
                          />
                          {errors.user_avatar ? (
                            <div class="text-danger mt-2 font-size-12">
                              <i
                                class="fa fas fa-exclamation-circle mr-1"
                                aria-hidden="true"
                              ></i>
                              <b>{errors.user_avatar}</b>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col">
                          <div
                            className="card"
                            onClick={() => setChangeProfilePic(true)}
                          >
                            <div className="text-center rounded">
                              <img
                                className="rounded-circle p-3 mt-2"
                                src={"/assets/images/reload.png"}
                                alt=""
                                style={{ backgroundColor: "#95E7FF" }}
                              />
                            </div>
                            <label className="text-center p-3">
                              Change Profile Picture
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className="card"
                            onClick={() => setRemoveProfilePic(true)}
                          >
                            <div className="text-center  rounded">
                              <img
                                className="rounded-circle p-3 mt-2"
                                src={"/assets/images/deleteIcon.png"}
                                alt=""
                                style={{ backgroundColor: "#95E7FF" }}
                              />
                            </div>
                            <label className="text-center p-3">
                              Remove Profile Picture
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-6">
          <p className="text-decoration-underline font-size-14">
            Personal details
          </p>
          <div className="col-md-10 px-0">
            <InputDropdown
              Label="User name"
              Dropdown="Mr"
              Dropdownname="Mr"
              Placeholder={"User Name"}
              name={"user_name"}
              value={formData.user_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-10 px-0">
            <InputDropdown
              Label="Contact No."
              Dropdown="+91"
              Dropdownname="+91"
              Placeholder={"Contact Number"}
              name={"contact_number"}
              value={formData.contact_number}
              onChange={handleChange}
              type={"number"}
            />
          </div>
          <div className="col-md-10 px-0">
            <InputEdit
              Placeholder={"Email"}
              Label="Email ID"
              name={"email_id"}
              value={formData.email_id}
              onChange={handleChange}
              type={"email"}
            />
          </div>
          <div className="col-md-10 px-0">
            <InputEdit
              Placeholder={"Address"}
              Label="Address"
              name={"user_address"}
              value={formData.user_address}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col">
          <div className="d-flex flex-column align-items-end justify-content-between pr-5">
            <div className="justify-content-end pr-5 position-relative">
              <img
                src={
                  formData.user_avatar
                    ? typeof formData.user_avatar == "string"
                      ? avatarUrl(formData.user_avatar)
                      : formData.user_avatar.filebase64
                    : "/assets/images/user-profile.png"
                }
                alt=""
                className="rounded-circle profileImg"
                height={180}
                width={180}
              />
              <div className="edit-img-icon" onClick={() => setModal(!modal)}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <rect
                    x="1.5"
                    y="1.5"
                    width="47"
                    height="47"
                    rx="23.5"
                    fill="white"
                  />{" "}
                  <path
                    d="M35.5 18.25L31.75 14.5L16.75 29.5L15.25 34.75L20.5 33.25L35.5 18.25ZM28.75 17.5L32.5 21.25L28.75 17.5ZM16.75 29.5L20.5 33.25L16.75 29.5Z"
                    stroke="#0C0C0C"
                    stroke-width="1.125"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />{" "}
                  <rect
                    x="1.5"
                    y="1.5"
                    width="47"
                    height="47"
                    rx="23.5"
                    stroke="#95E7FF"
                    stroke-width="3"
                  />{" "}
                </svg>
              </div>
            </div>
            {userTypeId / 1 == 20 ? null : (
              <div className="d-flex justify-content-end w-60 pt-5">
                <div className="card p-4 borderRadius referalDiv h-100">
                  <div className="d-flex justify-content-around">
                    <div className="col-8 text-start pt-3">
                      <label className="font-size-14 font-wt-400 referalLabel">
                        My Referral Code
                      </label>
                      <div className="row">
                        <label
                          className="font-size-16 font-wt-500 referralCode"
                          id="referCode"
                        >
                          {formData.referralCode}
                          <img
                            src={"/assets/images/copy_svg.svg"}
                            alt=""
                            className="ps-2"
                            onClick={() => copyToClipboard("referCode")}
                          />
                        </label>
                      </div>
                      <div className="row">
                        <label className="ViewMoreTxt">
                          View More
                          <img
                            src={"/assets/images/Arrow_right.svg"}
                            alt=""
                            className="ps-2"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <img src={"/assets/images/ref_img.svg"} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <ExtraDetailsForLimitApplication
          userTokenDetails={userTokenDetails}
          hideHeader={true}
          hideSideBar={true}
          hideSubmitDiv={true}
          hideBackArrow={true}
          expandAll={true}
          cardBorder0={true}
          cardPadding0={true}
          showContinueButton={false}
          afterDetailsSavedOn={() => {
            toggleOnSave(false);
          }}
          gstCredentialsViewHide
          gstDoc1Hide
          bankDoc1Hide
          bankDoc2Hide
          bankDoc3Hide
          finDoc1Hide
          fintDoc2Hide
          finDoc3Hide
          finDoc4Hide
          orgDoc1Hide
          orgDoc3Hide
          orgDocLabel={"Documents"}
          mainTagMarginPadding0
          onSave={onSave}
          hideToastAfterDetailsSaved
        />
      </div>

      {/* <div className="row">
        <label className="font-size-15 font-wt-500">
          <u>
            Documents
          </u>
        </label>
        <div>
          <label className="pl-2 font-size-14 font-wt-300">Aadhar card</label>
          <div className="col-6">
            <FileInput name={"Aadhaar_Document"} value={formData.Aadhaar_Document} error={errors.Aadhaar_Document}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...formData, "Aadhaar_Document": null })} />
          </div>
          <label className="pl-2 font-size-14 font-wt-300">PAN card</label>
          <div className="col-6">
            <FileInput name={"PAN_Document"} value={formData.PAN_Document} error={errors.PAN_Document}
              onChange={handleFile} isEditable={true} userTokenDetails={userTokenDetails} onUploadCancel={() => setData({ ...formData, "PAN_Document": null })} />
          </div>

        </div>

      </div> */}

      {userTypeId / 1 != 21 && userTypeId / 1 != 20 && (
        <div className="my-2">
          <label className="font-size-15 font-wt-500">
            <u>Other details</u>
          </label>
          <div>
            <NewTable
              disableAction
              columns={[
                { name: "Name", filter: true },
                { name: "Contact no.", filter: true },
                { name: "Email ID", filter: false },
                { name: "Access", filter: false },
                { name: "", filter: false },
              ]}
              data={subUserList}
            />
            {/* <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} /> */}
          </div>
        </div>
      )}

      <div className="d-flex gap-4">
        <button
          className={`my-4 ${
            userTypeId / 1 == 21
              ? "new-btn text-white "
              : "new-btn-secondary text-black "
          }  w-17 py-2 px-2  cursor`}
          onClick={() => {
            handleUserSubmit();
            toggleOnSave(true);
          }}
        >
          Save
        </button>
        {userTypeId / 1 != 21 && userTypeId / 1 != 20 && (
          <button
            className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`}
            style={{ display: "none" }}
            onClick={() => {
              setSubUserModal(true);
              // if ((userCurrentPlan?.subUsers / 1 > subUserList?.length / 1) || userTypeId / 1 == 8) {
              //   setSubUserModal(true)
              // }
              // else {
              //   toastDisplay("Please upgrade your plan to add more sub users", "info")
              // }
            }}
          >
            Add New User
          </button>
        )}
      </div>

      <>
        {subUserModal && (
          <SubUserModal
            setSubUserModal={setSubUserModal}
            subUserModal={subUserModal}
            countrys={countrys}
            userTokenDetails={userTokenDetails}
            isEditable={viewdetails.isVisible}
            formdata={viewdetails.data}
            userId={userId}
          />
        )}
      </>
    </div>
  );
};
export default UserTab;
