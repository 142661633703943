export const SupplierData = [
  {
    Count: "26",
    Name: "Buyers",
    addicon: true,
    Customcolor: "text-custom2"
  },
  {
    Count: "5,000",
    Name: "Shipments",
    Customcolor: "text-custom2"
  },
  {
    Count: "198",
    Name: "Finance Applied",
    Customcolor: "text-custom2"
  },
  {
    Count: "104",
    Name: "Finance Approved",
    Customcolor: "color3DB16F"
  },
  {
    Count: "$ 10,00,000",
    Name: "Limit Available",
    Customcolor: "text-custom2"
  },
  {
    Count: "$ 5 million",
    Name: "Finance Disbursed",
    Customcolor: "colorFFA41C"
  },
  {
    Count: "$ 1,00,000",
    Name: "Finance Overdue",
    Customcolor: "colorFE4141"
  },
];


export const FinanceData = [
  {
    Count: "26",
    Name: "Supplier",
    Customcolor: "text-custom2"
  },
  {
    Count: "5,000",
    Name: "Quotes",
    Customcolor: "text-custom2"
  },
  {
    Count: "104",
    Name: "Finance Approved",
    Customcolor: "color3DB16F"
  },
  {
    Count: "$ 10,00,000",
    Name: "Limit Given",
    Customcolor: "text-custom2"
  },
  {
    Count: "$ 5 million",
    Name: "Finance Disbursed",
    Customcolor: "colorFFA41C"
  },
  {
    Count: "$ 1,00,000",
    Name: "Finance Overdue",
    Customcolor: "colorFE4141"
  },
];


export const SupplierDetailsData = [
  {
    Transactiontype: "Finance Limit  Application",
    Lettercount: "4",
    Invoicecount: "2",
    borderbottom: true
  },
  {
    Transactiontype: "Finance Limit Quote",
    Lettercount: "3",
    Invoicecount: "2",
    borderbottom: true
  },
  {
    Transactiontype: "Finance Limit Contract",
    Lettercount: "1",
    Invoicecount: "1",
    borderbottom: true
  },
  {
    Transactiontype: "Finance Application",
    Lettercount: "2",
    Invoicecount: "4",
    borderbottom: true
  },
  {
    Transactiontype: "Agreement",
    Lettercount: "-",
    Invoicecount: "0",
    borderbottom: true
  },
  {
    Transactiontype: "Approved  Finance",
    Lettercount: "10",
    Invoicecount: "8",
    borderbottom: true
  },
  {
    Transactiontype: "Amendment",
    Lettercount: "2",
    Invoicecount: "0",
    borderbottom: false
  },
];

export const FinancerDetailsData = [
  {
    Transactiontype: "Quote",
    Lettercount: "4",
    Invoicecount: "3",
    borderbottom: true
  },
  {
    Transactiontype: "LC / Termsheet",
    Lettercount: "2",
    Invoicecount: "1",
    borderbottom: true
  },
  {
    Transactiontype: "Finance Application",
    Lettercount: "6",
    Invoicecount: "4",
    borderbottom: true
  },
  {
    Transactiontype: "Disbursement",
    Lettercount: "3",
    Invoicecount: "2",
    borderbottom: false
  },
];