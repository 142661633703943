import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { ClearCache, convertImageToPdf, GetCache, most_used_currencies, SetCache } from '../../utils/myFunctions';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import FilePreview from '../utilComponents/previewFile';
import moment from 'moment'
import { aiServerBackendUrl } from '../../urlConstants';
import axios from 'axios';


const toolTipText = {

}


export const LCQuoteFormV2 = ({ userTokenDetails, onClose, selectedLCData }) => {

  const applyFinance = Object.keys(selectedLCData).length ? false : true

  console.log("selectedLCData=======>", selectedLCData);

  const [bankMaster, setbankMasters] = useState([]);
  const [saveDocResult, setSaveDocResult] = useState({});
  const [buyerArr, setBuyerArr] = useState([]);
  const [buyerType, setBuyerType] = useState(1);
  const [logiArr, setLogiArr] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [data, setData] = useState({});
  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Application Form");
  const [fileData, setfileData] = useState({});
  const [fileDetail, setfileDetail] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [addPreviewModal, setaddPreviewModal] = useState(false);
  const [addFileData, setaddFileData] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [creditData, setCreditData] = useState([]);
  const [counterPart, setCounterPart] = useState([]);
  const [userBank, setUserBank] = useState([]);
  const [accord, setAccord] = useState({
    0: false,
    1: true
  });
  const [insuranceData, setInsuranceData] = useState({ modal: false, data: {} });
  const [showQms, setShowQms] = useState(false);
  const [countrys, setCountrys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleOffline, toggleVisibleOffline] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState([])
  const [optionalFields, setOptionalFields] = useState([])
  const [missMandatoryFieldsCount, setMissMandatoryFieldsCount] = useState(0)
  const [missOptionalFieldCount, setMissOptionalFieldsCount] = useState(0)
  const [isConfirmedLC, toggleIsConfirmedLC] = useState(false)


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>

  const isBankerLogin = userTypeId / 1 == 8 ? true : false

  useEffect(() => {
    if (mandatoryFields.length && optionalFields.length) {
      let temp = 0
      let temp2 = 0
      mandatoryFields.forEach((item, index) => {
        if (!data[item.tag]) {
          temp += 1
        }
      })
      optionalFields.forEach((item, index) => {
        if (!data[item.tag]) {
          temp2 += 1
        }
      })
      setMissMandatoryFieldsCount(temp)
      setMissOptionalFieldsCount(temp2)
    }
  }, [data, mandatoryFields, optionalFields])

  //To handle back with header tabs

  useEffect(() => {
    if (tab === 0 && applyFinance) {
      setData({})
    }
  }, [tab])


  useEffect(() => {

    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      console.log('running getLCFieldMaster api -->', result);
      setMandatoryFields(result.mandatoryFields)
      setOptionalFields(result.optionalFields)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })

    if (!applyFinance) {
      let temp = {
        // applicantName: selectedLCData.benificiary_name,
        // applicantEmail: selectedLCData.benificiary_email_id,
        // buyerIdName: selectedLCData.buyer + "::" + selectedLCData.buyer_name,
        // lcTenor: selectedLCData.lc_tenor,
        // shipmentFromCountry: selectedLCData.shipment_from_country,
        // shipmentToCountry: selectedLCData.shipment_to_country,
        // expDateOfShipment: selectedLCData.exp_date_of_shipment,
        // countryOfOrigin: selectedLCData.country_of_origin,
        // benificiaryBank: selectedLCData.benificiary_bank,
        // beneficiaryBankName: selectedLCData.beneficiaryBankName,
        // beneficiaryAccountNo: selectedLCData.beneficiaryAccountNo,
        // beneficiaryIfscCode: selectedLCData.beneficiaryIfscCode,
        // beneficiarySwiftCode: selectedLCData.beneficiarySwiftCode,
        // commodity: selectedLCData.commodity,
        // issuingBankName: selectedLCData.issuing_bank_name,
        // issuingBankAddr: selectedLCData.issuing_bank_address,
        // buyerName: selectedLCData.buyer_name,
        docArray: [{ "doc_type": "LC Document", ...selectedLCData.lcDoc }],
        lcCreatedAt: moment(selectedLCData.createdAt).format('YYYY-MM-DD HH:mm')
      }

      // if (selectedLCData.offer_id) {
      //   temp["currency"] = selectedLCData.offer_details.currency
      //   temp["exportHandlingCharges"] = selectedLCData.offer_details.exportHandlingCharges
      //   temp["courierCharges"] = selectedLCData.offer_details.courierCharges
      //   temp["amount"] = selectedLCData.offer_details.amount
      //   temp["roi"] = selectedLCData.offer_details.roi
      //   temp["offerValidTill"] = selectedLCData.offer_details.offerValidTill
      //   temp["remarks"] = selectedLCData.offer_details.remarks
      // }
      setData({ ...temp, ...selectedLCData.userResp })
      toggleIsConfirmedLC(selectedLCData.isConfirmedLC / 1 == 0 ? false : true)

    }
    // else if (applyFinance && !data.applicantEmail) {
    //   setData({ applicantEmail: userEmail, applicantName: userName })
    // }

    // call('POST', 'getBuyersDetail', {
    //   "userId": userId,
    //   "userTypeId": userTypeId
    // }).then((result) => {
    //   // console.log('running getBuyersDetail api -->', result.buyerData);
    //   setCounterPart(result.buyerData);
    // }).catch((e) => {
    //   // console.log('error in getBuyersDetail', e);
    // })

    // call('POST', 'getCountryMaster', { getAllData: true }).then((result) => {
    //   setCountrys(result);
    // }).catch((e) => {
    //   // console.log('error in getCountryMaster', e);
    // })

    // Get bank master list that are involved in trade finance.
    // call("GET", "getBanksListMaster")
    //   .then((result) => {
    //     setbankMasters(result);
    //   })
    //   .catch((e) => {
    //     // console.log("Error while querying getBanksListMaster:", e);
    //   });

    // Get bank from user profile
    // call("POST", "getUserBanks", { "email": userEmail, "inProfile": true })
    //   .then((result) => {
    //     setbankMasters(result);
    //   })
    //   .catch((e) => {
    //     // console.log("Error while querying getBanksListMaster:", e);
    //   });

    // call("get", "getcurrencylist").then((result) => {
    //   setcurrencyData(result);
    // }).catch((e) => {
    //   // console.log("error in getcurrencylist", e);
    // });

  }, [applyFinance])


  const handleNext = () => {
    // let validateFields = ["buyerIdName", "lcTenor", "shipmentFromCountry", "shipmentToCountry", "expDateOfShipment", "countryOfOrigin",
    //   "benificiaryBank", "commodity", "issuingBankAddr", "issuingBankName"]

    // if (isBankerLogin) {
    //   validateFields = ["exportHandlingCharges", "currency", "courierCharges", "amount", "roi", "offerValidTill", "remarks"]
    // }

    let tempErr = {}
    // validateFields.forEach(item => {
    //   if (!data[item]) {
    //     tempErr[item] = "Field is mandatory"
    //   }
    //   if (item === "offerValidTill" && data[item]) {
    //     if (moment() > moment(data[item], "YYYY-MM-DD")) {
    //       tempErr[item] = "Offer date must be greater than present date"
    //     }
    //   }
    // })
    if (!Object.keys(file).length && applyFinance) {
      tempErr["lcDocument"] = "Please upload document"
    }
    if (Object.keys(tempErr).length === 0) {
      if (tab === 0) {
        if (applyFinance) {
          fetchDocData()
        }
        else {
          setTab(1)
        }
        // setTab(1)
        // setCurrentPage(2)
      }
      if (tab === 1) {
        setTab(2)
      }
      // if (tab === 1) {
      //   if (!Object.keys(file).length && applyFinance) {
      //     tempErr["lcDocument"] = "Please upload document"
      //   }
      //   else {
      //     setCurrentPage(2)
      //   }
      // }
    }
    setError(tempErr)
  }

  async function fetchDocData() {
    let formData = new FormData()
    formData.append("doc", file.lcDocument)
    setshowLoader(true)
    toastDisplay("Analyzing document kindly wait", "info")
    axios.post(`${aiServerBackendUrl}/text-analyse`, formData, {
    })
      .then(res => {
        setshowLoader(false)
        console.log("then text-analyse", res);
        if (res.data.length) {
          setData({ ...data, ...res.data[0], ["readerResp"]: res.data[0] })
          toggleIsConfirmedLC(res.data[0].isConfirmedLC)
          setTab(1)
        }
        else {
          toastDisplay("Something went wrong", "error")
        }
      }).catch(function (response) {
        setshowLoader(false)
        console.log("error in text-analyse", response)
        toastDisplay("Something went wrong", "error")
      });
    // axios({
    //   method: "post",
    //   url: `${aiServerBackendUrl}/text-analyse`,
    //   data: formData,
    //   headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}`, },
    // })
    //   .then(function (response) {
    //     console.log("then text-analyse", response);
    //   })
    //   .catch(function (response) {
    //     console.log("error in text-analyse", response)
    //     toastDisplay("Something went wrong", "error")
    //   });
  }

  function onSubmit() {
    let isValid = true
    mandatoryFields.forEach((element, index) => {
      if (!data[element.tag]) {
        isValid = false
      }
    });
    if (!isValid && !isConfirmedLC) {
      return toastDisplay("Kindly fill mandatory fields to proceed", "info")
    }
    let lcData = {}
    mandatoryFields.forEach((item) => {
      if (data[item.tag]) {
        lcData[item.tag] = data[item.tag]
      }
    })
    optionalFields.forEach((item) => {
      if (data[item.tag]) {
        lcData[item.tag] = data[item.tag]
      }
    })
    let formData = new FormData();
    formData.append("lcDocument", file.lcDocument)
    formData.append('userId', userId)
    formData.append('userEmail', userEmail)
    formData.append("readerResp", JSON.stringify(data.readerResp))
    formData.append("lcData", JSON.stringify(lcData))
    formData.append("docCreditNo", data[20])
    formData.append("isConfirmedLC", isConfirmedLC)
    setshowLoader(true)
    call('POST', 'applyLCFastV2', formData).then((result) => {
      // console.log("after form submit", result);
      setshowLoader(false)
      toastDisplay(result, "success")
      onClose()
    }).catch((e) => {
      // console.log('error in applyLCFastV2', e);
      setshowLoader(false)
      toastDisplay(e || "Something went wrong", "error")
    })
  }

  function handleBack() {
    if (currentPage === 1) {
      if (tab === 0) {
        onClose()
      }
      else {
        setTab(0)
      }
      if (tab === 2) {
        setTab(1)
      }
    }
    // else {
    //   setCurrentPage(1);
    //   // setTab(1)
    // }
  }

  function onBankAction(action) {
    let offerDetails = {
      "currency": data.currency,
      "exportHandlingCharges": data.exportHandlingCharges,
      "courierCharges": data.courierCharges,
      'amount': data.amount,
      "roi": data.roi,
      "offerValidTill": data.offerValidTill,
      "remarks": data.remarks
    }
    setshowLoader(true)
    call('POST', 'updateLCOffer', {
      userId, applicationId: selectedLCData.application_id, offerDetails,
      offerStatus: action === "approve" ? 0 : 1, offerId: selectedLCData.offer_id,
      "traderUserId": selectedLCData.created_by,
      "bankerName": userName
    }).then((result) => {
      // console.log("updateLCOffer result", result);
      setshowLoader(false)
      toastDisplay(result, "success")
      onClose()
    }).catch((e) => {
      // console.log('error in updateLCOffer', e);
      setshowLoader(false)
    })
  }

  function onDealLock() {
    setshowLoader(true)
    call('POST', 'lockLCOffer', {
      offerId: selectedLCData.offer_id,
      applicationId: selectedLCData.application_id,
      bankerUserId: selectedLCData.offer_created_by,
      traderUserName: userName
    }).then((result) => {
      // console.log("lockLCOffer result", result);
      setshowLoader(false)
      toastDisplay(result, "success")
      onClose()
    }).catch((e) => {
      // console.log('error in lockLCOffer', e);
      setshowLoader(false)
    })
  }


  const NavigationButtons = ({ }) => {
    return (
      <div className="col-md-12">
        {tab === 1 ?
          !isConfirmedLC && userTypeId / 1 == 8 ?
            <button type="button"
              onClick={handleNext}
              className="btn btn-success float-right btn-sm">
              {"Next"}
            </button>
            :
            <button type="button"
              disabled={data.dnc != "on"}
              onClick={onSubmit}
              className="btn btn-success float-right btn-sm">
              {"Submit"}
            </button>
          : (
            <button
              type="button" className="btn btn-success float-right btn-sm"
              onClick={() => { handleNext() }}>
              Next<i className="fa fa-chevron-right pl-2" aria-hidden="true"></i>
            </button >
          )}
        <button type="button" className="btn btn-danger float-left btn-sm"
          onClick={() => { handleBack() }}>
          <i className="fa fa-chevron-left pr-2" aria-hidden="true"></i>Back
        </button>
      </div>
    )
  }


  const handleFiles = (event) => {
    event.persist();
    if (!event.target.files[0]) {
      return null
    }
    let file_type = event.target.files[0]["type"].toLowerCase()
    if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
      toastDisplay('Files with pdf, png & jpeg extension are allowed', 'info')
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0]
      let fileDataUrl = e.target.result
      if (!file_type.includes("pdf")) {
        let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
        fileObj = response["file"]
        fileDataUrl = response['dataUrl']
        toastDisplay("File converted into pdf format", "success")
        // console.log("response==>", response);
      }
      setFile({ ...file, [event.target.name]: fileObj, [event.target.name + "dataUrl"]: fileDataUrl });
      setData({ ...data, [event.target.name]: fileObj })
      setError({ ...error, [event.target.name]: "" });
      setfileDetail({
        "file_name": event.target.name,
        "file_data": { [event.target.name]: fileObj }
      });
      setaddFileData(fileDataUrl);
      setaddPreviewModal(true);
    }
  };



  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function handleChange(event) {
    event.persist()
    if (event.target.name === "benificiaryBank") {
      if (!event.target.value) {
        return setData({
          ...data, [event.target.name]: event.target.value, beneficiaryAccountNo: "",
          beneficiaryIfscCode: "", beneficiarySwiftCode: "", beneficiaryBankName: ""
        })
      }
      let bankId = event.target.value.split("::")[0]
      let selectedBank = bankMaster.filter(item => {
        if (item.id == bankId) {
          return item
        }
      })
      // console.log("selectedBankselectedBank", selectedBank, bankId);
      setData({
        ...data, [event.target.name]: event.target.value,
        beneficiaryBankName: selectedBank[0]["beneficiaryBankName"], beneficiaryAccountNo: selectedBank[0]["beneficiaryAccountNo"],
        beneficiaryIfscCode: selectedBank[0]["beneficiaryIfscCode"], beneficiarySwiftCode: selectedBank[0]["beneficiarySwiftCode"]
      })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
    setError({ ...error, [event.target.name]: "" });
  }

  function callPreviewFileComponent(values, action) {
    if (values.base64 && values.file_data) {
      setfileData({ "action": action, "file_data": values.file_data, "base64": values.base64 })
      toggleVisibleOffline(true)
    }
    else {
      setfileData({ "action": action, "file_hash": values.file_hash, "file_name": values.file_name, "file_type": values.doc_type })
      toggleVisibleOffline(false)
    }
    setshowPreviewModal(true)
  }


  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      console.log("value in doc render =>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{values.doc_type || 'NA'}</td>
        <td>{data["59"] || 'NA'}</td>
        <td>{data.lcCreatedAt || "NA"}</td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">
          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  return (
    <div className=''>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
        mktPlaceShipment={!visibleOffline}
      />}

      {currentPage === 1 ? (
        <ul className="nav nav-tabs-custom mt-4 pt-4" id="myTab" role="tablist">
          <li>
            <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
              setTab(0);
            }}>Upload Document</a>
          </li>
          {tab === 1 || !applyFinance ? (
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
              }}>Application Details</a>
            </li>
          ) : null}
          {!isConfirmedLC && userTypeId / 1 == 8 ? (
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                setTab(2);
              }}>Add Confirmation Quote</a>
            </li>
          ) : null}
        </ul>
      ) : null}

      {currentPage === 1 ? (
        <div className="col-md-12">

          {tab === 0 ? (
            <div className="row bg-white shadow mt-3 mb-4 py-2">
              <>
                {applyFinance ? (
                  <div className="col-md-12 my-3">
                    <h3 className="pt-2 pb-2"><u>Upload LC Draft</u></h3>
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className={"custum-control" + (error.lcDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>LC Draft {astrix}</h3>
                        </li>
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="lcDocument" onChange={handleFiles} />
                          </div>
                        </li>
                      </ul>
                      {file.lcDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.lcDocument.name}`}</div></div> : ""}
                      {error.lcDocument ? <div class="text-danger error-contract">{error.lcDocument}</div> : ''}
                    </div>
                  </div>
                ) :
                  (<>
                    <div class="col-md-12">
                      <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                        <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Documents</h2>
                        <div className="accordionItemContent">
                          <div className="col-md-12">
                            <div className="table-responsive" style={{ "min-height": "150px" }}>
                              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                <thead>
                                  <tr>
                                    <th width="20">#</th>
                                    <th width="200">Type of Document</th>
                                    <th width="200">Uploaded By</th>
                                    <th width="200">Uploaded On</th>
                                    <th className="text-center" width="150">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(data && data.docArray && data.docArray.length) && docPreviewGenerator(data.docArray)}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>)}
              </>

            </div>
          ) : null}

          {tab === 1 ? (
            <>
              {true ? (
                <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                  <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>
                    A. Mandatory Fields
                    <label className={`${missMandatoryFieldsCount ? "text-danger" : "text-success"} font-weight-bold pl-4`}>
                      {`${missMandatoryFieldsCount ? `(${missMandatoryFieldsCount} fields missing out of ${mandatoryFields.length})` : 'Kindly Review Before Submitting'}`}
                    </label> </h2>
                  <div className="accordionItemContent">
                    <div className='row'>
                      {mandatoryFields.length ? mandatoryFields.map((item, index) => {
                        return (
                          <div className="col-md-3">
                            <div className="row form-group">
                              <label className="col-md-12">{`${item.tag} : ${item.fieldName}`} {astrix}</label>
                              <div className="col-md-12">
                                <textarea type="text" readOnly={!applyFinance || isConfirmedLC} className={"form-control" + (!data[item.tag] ? " border-danger" : "")}
                                  name={item.tag} value={data[item.tag]} onChange={handleChange} />
                                {!data[item.tag] ? <div class="text-danger error-contract">{"Field Missing"}</div> : ''}
                              </div>
                            </div>
                          </div>
                        )
                      }) : ""}
                    </div>
                  </div>
                </div>
              ) : null}

              {true ? (
                <div className={"accordionItem mt-3" + (accord['1'] ? " closed" : " open")}>
                  <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>
                    B. Optional Fields
                    <label className={`${missOptionalFieldCount ? "text-danger" : "text-success"} font-weight-bold pl-4`}>
                      {`${missOptionalFieldCount ? `(${missOptionalFieldCount} fields missing out of ${optionalFields.length})` : 'Kindly Review Before Submitting'}`}
                    </label> </h2>
                  <div className="accordionItemContent">
                    <div className='row'>
                      {optionalFields.length ? optionalFields.map((item, index) => {
                        return (
                          <div className="col-md-3">
                            <div className="row form-group">
                              <label className="col-md-12">{`${item.tag} : ${item.fieldName}`}</label>
                              <div className="col-md-12">
                                <textarea type="text" readOnly={!applyFinance || isConfirmedLC} className={"form-control" + (!data[item.tag] ? " border-danger" : "")}
                                  name={item.tag} value={data[item.tag]} onChange={handleChange} />
                                {!data[item.tag] ? <div class="text-danger error-contract">{"Field Missing"}</div> : ''}
                              </div>
                            </div>
                          </div>
                        )
                      }) : ""}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}






          {/* <div className="row">
            {tab === 1 ? (
              <>
                {applyFinance ? (
                  <div className="col-md-12">
                    <h3 className="pt-2 pb-2"><u>Supporting Documents</u></h3>
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className={"custum-control" + (error.lcDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>LC Document {astrix}</h3>
                        </li>
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="lcDocument" onChange={handleFiles} />
                          </div>
                        </li>
                      </ul>
                      {file.lcDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.lcDocument.name}`}</div></div> : ""}
                      {error.lcDocument ? <div class="text-danger error-contract">{error.lcDocument}</div> : ''}
                    </div>
                  </div>
                ) :
                  (<>
                    <div class="col-md-12">
                      <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                        <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Documents</h2>
                        <div className="accordionItemContent">
                          <div className="col-md-12">
                            <div className="table-responsive" style={{ "min-height": "150px" }}>
                              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                <thead>
                                  <tr>
                                    <th width="20">#</th>
                                    <th width="200">Type of Document</th>
                                    <th width="200">Uploaded By</th>
                                    <th width="200">Uploaded On</th>
                                    <th className="text-center" width="150">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(data && data.docArray && data.docArray.length) && docPreviewGenerator(data.docArray)}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>)}
              </>
            ) : null}
          </div> */}

          <hr />
          {tab === 1 && applyFinance ? (
            <div className="pt-3 my-4">
              <input type="checkbox" id="checkDNC" name="dnc" value="on" onChange={handleChange} required checked={data.dnc === "on" ? true : false} />
              <label className="checkbox-smallLabel width-auto" for="checkDNC">
                <span>
                  I hereby declare that the given information by me is best in my
                  knowledge and I did not apply any loan against this contract.
                </span>
              </label>
            </div>
          ) : null}
          <NavigationButtons />
        </div>
      ) : (
        <div class="col-md-12 p-2 mt-4 pt-4">
          <h1 className="text-center"><u>LC Quote Application - Review</u></h1><br />

          <div className="row m-2">
            <>
              <div class="col-md-2 p-2">
                <label>Benificiary Name</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.applicantName} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Benificiary Email Id</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.applicantEmail} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Buyer Name</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.buyerIdName.split("::")[1]} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>LC Tenor</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.lcTenor} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Shipment From Country</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.shipmentFromCountry} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Shipment To Country</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.shipmentToCountry} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Expected Date Of Shipment</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.expDateOfShipment} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Country Of Origin</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.countryOfOrigin} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Benificiary Bank Name</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.beneficiaryBankName} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Commodity</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.commodity} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>LC Issuing Bank Name</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.issuingBankName} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>LC Issuing Bank Address</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.issuingBankAddr} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Export handling charges</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.exportHandlingCharges ? (data.exportHandlingCharges + (data.currency ? " " + data.currency.split("::")[1] : "")) : ""} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Courier charges</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.courierCharges ? (data.courierCharges + (data.currency ? " " + data.currency.split("::")[1] : "")) : ''} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Amount</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.amount ? (data.amount + (data.currency ? " " + data.currency.split("::")[1] : "")) : ""} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Rate of interest</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.roi} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Offer valid till</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.offerValidTill} disabled />
              </div>

              <div class="col-md-2 p-2">
                <label>Remarks</label>
              </div>
              <div class="col-md-4 p-2">
                <input type="text" class="form-control" value={data.remarks} disabled />
              </div>
            </>
          </div>

          {(applyFinance && file && Object.keys(file).length > 0) &&
            <div className="col-md-12 row my-4 py-4">
              <div className="col-md-5">
                <h3 className="col-md-12 pl-0 ml-0"><u>Document Name</u></h3>
              </div>
              <div className="col-md-5">
                <h3 className="col-md-12  pl-0 ml-0"><u>File Name</u></h3>
              </div>
              <div className="col-md-2">
                <h3 className="col-md-12  pl-0 ml-0"><u>View File</u></h3>
              </div>

              {file && Object.keys(file).length ? Object.keys(file).map((doc) => {
                if (!doc.includes("dataUrl")) {
                  return (
                    <>
                      <div className="col-md-5">
                        <div className="row form-group">
                          <label className="col-md-12">{doc.toUpperCase() || '--'}</label>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="row form-group">
                          <label className="col-md-12 text-primary">{file[doc].name}</label>
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                          () => callPreviewFileComponent({ file_data: file[doc], base64: file[doc + "dataUrl"] }, "view")}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                      </div>
                    </>
                  )
                }
              }) : ''}
            </div>}

          {!applyFinance && (
            <div class="col-md-12">
              <div className="col-md-12 mt-3">
                <h3 className="pt-2 pb-2"><u>Documents</u></h3>
                {/* <div className="accordionItemContent"> */}
                {/* <div className="col-md-12"> */}
                <div className="table-responsive" style={{ "min-height": "150px" }}>
                  <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                    <thead>
                      <tr>
                        <th width="20">#</th>
                        <th width="200">Type of Document</th>
                        {/* <th width="200">Document Name</th> */}
                        <th width="200">Uploaded By</th>
                        <th width="200">Uploaded On</th>
                        {/* <th>Signed By</th> */}
                        <th className="text-center" width="150">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data && data.docArray && data.docArray.length) && docPreviewGenerator(data.docArray)}
                    </tbody>
                  </table>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          )}
          <NavigationButtons />
        </div>

      )}

    </div>
  )
}