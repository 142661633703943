import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const label = {
  "receievedSum": "Receieved",
  "duesome": "Due",
  "overduesum": "Overdue"
}

export default class DisbursementChart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="week" />
          <YAxis />
          <Tooltip formatter={(value, name) => ["$ " + Intl.NumberFormat('en-US').format(value), label[name]]} />
          <Legend formatter={(value, name) => [label[value]]} />
          <Bar dataKey="receievedSum" fill="#76EEA9" width={10} display="Receieved" />
          <Bar dataKey="duesome" fill="#AED8FF" width={10} display="Due" />
          <Bar dataKey="overduesum" fill="#FF7B6D" width={10} display="Overdue" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
