import React, { useEffect, useState } from 'react';

const BillingDetails = ({ userTokenDetails }) => {

  const [modalStatus, setmodalStatus] = useState(true);
  const [showLoader, setshowLoader] = useState(false);


  return (
    <>
      { modalStatus ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Billing Details
                      <button type="button" onClick={() => setmodalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-times fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              Billing Details
              <button type="button" onClick={() => setmodalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>}
    </>)
}



export default BillingDetails
