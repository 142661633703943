import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewTable } from "../../../utils/newTable";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from "../../InvoiceDiscounting/components/Filter";
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { most_used_currencies, LCTypesObject } from "../../../utils/myFunctions";
import moment from "moment";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { useHistory } from "react-router-dom";

const EmojiData = [
  {
    image: "/assets/images/angry.png",
    fillimage: "/assets/images/angry-fill.png",
    name: "Angry",
    rating: 1
  },
  {
    image: "/assets/images/Not satisfied.png",
    fillimage: "/assets/images/notsatisfed-fill.png",
    name: "Not satisfied",
    rating: 2
  },
  {
    image: "/assets/images/ok.png",
    fillimage: "/assets/images/ok-fill.png",
    name: "ok",
    rating: 3
  }, {
    image: "/assets/images/Satisfied.png",
    fillimage: "/assets/images/satisfied-fill.png",
    name: "Satisfied",
    rating: 4
  }, {
    image: "/assets/images/excellent.png",
    fillimage: "/assets/images/excellent-fill.png",
    name: "excellent",
    rating: 5
  },
];

const Amendment = ({ userTokenDetails, navToggleState }) => {

  const history = useHistory();

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })

  const [limitinvoice, setLimitinvoice] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const [experience, setExperience] = useState({ show: false, data: {} });
  const [emoji, setEmoji] = useState("");

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    //Get Buyer list start
    let objectAPI = {
      userId,
      showAmmendments: true,
      currentPage: 1,
      ...filter
    }
    setshowLoader(true)
    call('POST', 'getLCQuoteApplications', objectAPI).then((result) => {
      console.log('running getLCQuoteApplications api-->', result);
      setshowLoader(false)
      setDbData(result.data);
      setTableData(formatDataForTable(result.data))
      setCount(result.totalCount);
    }).catch((e) => {
      // console.log('error in getLCQuoteApplications', e);
      setshowLoader(false)
    });
  }, [refresh])


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = moment(item.modified_at).format('DD/MM/YYYY')
      row[1] = item.buyerName
      row[2] = item.lcNo
      row[3] = LCTypesObject[item.lcType]
      row[4] = JSON.parse(item.selectedQuote || "{}")?.["lender_name"]
      // row[5] = <>
      //   {
      //     item.Timeleft === "Success" && <p
      //       class={`text-success mb-0 font-size-13`}>
      //       {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //     </p>
      //   }
      //   {
      //     item.Timeleft === "inprogress" && <p type="button"
      //       class={`text-warning border-0 font-size-14`}>
      //       {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //     </p>
      //   }
      //   {
      //     item.Timeleft === "failed" && <p type="button"
      //       class={`text-danger border-0 font-size-14`}>
      //       {item.days} days <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      //     </p>
      //   }
      //   {
      //     item.Timeleft === "" && <p type="button"
      //       class={`text-dark border-0 font-size-14`}>
      //       -
      //     </p>
      //   }
      // </>
      if (item.amendmentStatus / 1 == 1) {
        // return (
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Application forwarded to buyer</b></span>
          {/* <label
                  onClick={() => showDisbursmentSchedule(item.id)}
                  className="text-color1 text-decoration-underline cursor">Forwarded to buyer</label> */}
        </p>
        // )
      }
      row[5] =
        <div>
          {item.amendmentStatus / 1 == 2 && <button type="button"
            className={`approved text-white border-0 `}>
            {"Approved"}
          </button>}
          {(item.amendmentStatus / 1 == 0 || item.amendmentStatus / 1 == 1) && <button type="button"
            className={`inprogress text-white border-0 `}>
            {"Inprogress"}
          </button>}
        </div >
      tableData.push(row)
      row = []
    })
    return tableData
  }


  const closeExpFn = () => {
    setExperience({ show: false, data: {} });
    setEmoji("")
    setData({})
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function postRating() {
    setshowLoader(true)
    await call("POST", "postRatingToFinancier", {
      ratingFor: 'LC', applicationId: experience.data.id, lenderId: JSON.parse(experience.data.selectedQuote)["lender_id"],
      ratings: emoji, remarks: data.remarks, userId
    })
    toastDisplay("Feedback submitted successfully", "success")
    closeExpFn()
    setshowLoader(false)
  }

  async function submitRating(index) {
    setExperience({ show: true, data: dbData[index] })
  }

  async function onView(index) {
    window.location = `/amendmentDetails?amendmentId=${dbData[index]["ammendmentId"]}&quoteId=${dbData[index]["id"]}`
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="LcAmendment" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC- Amendment"}
              userTokenDetails={userTokenDetails} />
            <div>
              {/* <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
              <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>

            <NewTable
              disableAction={false}
              columns={[{ name: "Date", filter: true }, { name: "Buyer name", filter: true },
              { name: "LC no.", filter: true },
              { name: "LC type", filter: true },
              {
                name: "Financer", filter: true
              },
              // {
              //   name: "Financer selected", filter: true
              // },
              { name: "", filter: false }, { name: "", filter: false }]}
              data={tableData}
              options={
                [
                  // { name: "Give ratings", icon: "rating.png", onClick: (index) => submitRating(index) },
                  // { name: "Delete", icon: "delete.png", onClick: onDelete },
                  { name: "View Details", icon: "eye.png", onClick: onView }]}
            />
            <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 0} />


          </main>
        </div>
      </div>

      {experience.show && <FinanceInvoiceModal limitinvoice={experience} closeSuccess={closeExpFn}>
        <p>How was your experience with <span className="text-custom">{JSON.parse(experience.data.selectedQuote)["lender_name"]}</span> ?</p>
        <div className="d-flex gap-3 justify-content-center">
          {EmojiData.map((key, index) => {
            return (
              <>
                <img src={emoji === key.rating ? key.fillimage : key.image} alt="angry" key={index} className="cursor " onClick={() => setEmoji(key.rating)} />
              </>
            );
          })}

        </div>

        {emoji !== "" && < div className="my-3 col-9 mx-auto text-end border px-0 send p-2">
          <textarea name={"remarks"} value={data.remarks} onChange={handleChange} className="form-control border-0 bg-transparent" id="exampleFormControlTextarea1" rows="3" placeholder="Write a review..."></textarea>
          <img className="cursor px-1" src={"/assets/images/Send.png"} alt="" onClick={() => postRating()} />
        </div>}
      </FinanceInvoiceModal >}

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Amendment)