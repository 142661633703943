import React, { useState } from 'react'
import { getKeyByValue, isEmpty, most_used_currencies } from '../../../utils/myFunctions'
import { InputWithSelect } from '../../../utils/newInput'
import InputEdit from './inputEdit'
import SliderComponent from './SliderComponent'
import { companyTypes, industryData } from '../../registration/newRegistration'
import call from '../../../service'
import { useEffect } from 'react'
let OnboardingOptions = [
  { name: 'Supplier Profile', alt: 'suppProfile' },
  { name: 'Website', alt: 'website' },
  { name: 'New Buyers', alt: 'newBuyer' },
  { name: 'DUNS number', alt: 'dunsNo' },
  { name: 'Email (gmail, hotmail & yahoo won’t be accepted for legal framework ', alt: 'EmailsAccepted' }

]

let supplierDetails = [
  { name: 'Contact Details', alt: 'contactDetails' },
  { name: 'Finance Details', alt: 'financeDetails' },
  { name: 'Company Details', alt: 'companyDetails' },
  { name: 'Credit Report', alt: 'credReport' },
  { name: 'KYC & registration details with documents', alt: 'kyc' },
  { name: 'Shareholder (with share cerificate)', alt: 'shareholder' },
  { name: 'Email (gmail, hotmail & yahoo won’t be accepted for legal framework ', alt: 'EmailsAccepted' }
]

let buyerDetails = [
  { name: 'New Buyer wont be accept', alt: 'newBuyerNotAccept' },
  { name: 'Contact Details', alt: 'buyercontactDetails' },
  { name: 'Email (gmail, hotmail & yahoo won’t be accepted for legal framework ', alt: 'buyerEmailsAccepted' },
  { name: 'Company Details', alt: 'buyercompanyDetails' },
  { name: 'DUNS number', alt: 'dunsNo' },
]

let ServicesOffered = [
  { name: 'LC Discounting', alt: 'lc_discounting' },
  { name: 'LC Confirmation', alt: 'lc_confirmation' },
  { name: 'Invoice Discounting', alt: 'invoice_discounting' },
  { name: 'SBLC', alt: 'sblc' }
]

let turnoverMapping = {
  "Less than 1M": "0 - 1000000",
  "Between 1M-10M": "1000000 - 10000000",
  "More than 10M": "10000000 - greater",
  "Others": "others"

}

const CreditLineTab = ({ userTokenDetails, toastDisplay }) => {
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const [data, setData] = useState({
    onboarding: {},
    companyTypes: {},
    services: {},
    industry: {},
    HSNCodes: {},
    supplierCountries: {},
    buyerCountries: {},
    currencies: {}
  })
  const [showLoader, setShowLoader] = useState(false)
  const [errors, setErrors] = useState({})
  const [exportRange, setexportRange] = useState('');
  const [domestingRange, setdomestingRange] = useState('');
  const [turnoverRange, setturnoverRange] = useState('');
  const [hsnCodes, setHSNCodes] = useState([])
  const [regions, setregions] = useState([])
  const [countries, setCountries] = useState([])
  const [Currency, setCurrency] = useState(most_used_currencies.slice(0, 10))
  const [buyerAnnualTurnover, setBuyerAnnualTurnOver] = useState('')
  const handleChange = (event, key, alt, value) => {
    if (event.persist) {
      event.persist()
    }
    if (alt) {
      setData({
        ...data,
        [key]: {
          ...data[key],
          [alt?.toString()]: value
        }
      })
      setErrors({
        ...errors,
        [errors[key]]: {
          ...errors[key],
          [alt?.toString()]: ""
        }
      })
    } else {
      setData({
        ...data,
        [event.target.name]: event.target.value
      })
      setErrors({
        ...errors,
        [event.target.name]: ""
      })
    }

  }
  const selectAll = (typeArr, key, alt, alt2) => {
    let obj = {}
    for (let i = 0; i <= typeArr.length - 1; i++) {
      const element = typeArr[i]
      obj[`${element[alt]?.toString()}${alt2 ? "-" + element[alt2]?.toString() : ''}`] = true
      //obj[element[alt]?.toString() + alt2 ? "-" + element[alt2]?.toString() : ''] = true
    }
    setData({
      ...data,
      [key]: obj
    })
  }
  const deselectAll = (typeArr, key, alt, alt2) => {
    let obj = {}
    for (let i = 0; i <= typeArr.length - 1; i++) {
      const element = typeArr[i]
      obj[`${element[alt]?.toString()}${alt2 ? "-" + element[alt2]?.toString() : ''}`] = false
      //obj[element[alt]?.toString() + alt2 ? "-" + element[alt2]?.toString() : ''] = false
    }
    setData({
      ...data,
      [key]: obj
    })
  }
  console.log('stateDataaaaa', data)
  const getHsnCodes = async () => {
    try {
      const result = await call('GET', 'getHsnCodes', {})
      setHSNCodes(result)
    } catch (e) {
      console.log('error in getHsnCodes', e)
    }
  }
  const getWorldRegions = async () => {
    try {
      const result = await call('GET', 'getWorldRegions', {})
      setregions(result)
    } catch (e) {
      console.log('error in getWorldRegions', e)
    }
  }
  const getCountriesByRegionId = async (region_id) => {
    try {
      const result = await call('POST', 'getCountriesByRegionId', { region_id })
      setCountries(result)
    } catch (e) {
      console.log('error in getCountriesByRegionId', e)
    }
  }
  const getcurrencylist = async () => {
    try {
      const result = await call('GET', 'getcurrencylist', {})
      setCurrency(result)
    } catch (e) {
      console.log('error in getcurrencylist', e)
    }
  }
  const addCreditLine = async () => {
    setShowLoader(true)
    try {
      let reqObj = {
        userId,
        exportRange: turnoverMapping[exportRange],
        turnoverRange: turnoverMapping[turnoverRange],
        buyerAnnualturnover: turnoverMapping[buyerAnnualTurnover],
        audFin: parseInt(data.audFin),
        onboarding: data["onboarding"],
        companyTypes: data["companyTypes"],
        services: data["services"],
        industry: data["industry"],
        HSNCodes: data["HSNCodes"],
        supplierCountries: data["supplierCountries"],
        buyerCountries: data["buyerCountries"],
        currencies: data["currencies"],
        LCBanks: []
      }
      const result = await call('POST', 'addCreditLine', reqObj)
      toastDisplay(result)
      setShowLoader(false)
    } catch (e) {
      console.log('error in addCreditLine', e)
      toastDisplay(e)
      setShowLoader(false)
    }
  }
  const getCreditLine = async () => {
    try {
      setShowLoader(true)
      const result = await call('POST', 'getCreditLine', { userId })
      console.log('success in getCreditLine', result, result["onboarding"])
      setShowLoader(false)
      setData({
        audFin: result.audFin,
        onboarding: JSON.parse(result["onboarding"]),
        companyTypes: JSON.parse(result["companyTypes"]),
        services: JSON.parse(result["services"]),
        industry: JSON.parse(result["industry"]),
        HSNCodes: JSON.parse(result["HSNCodes"]),
        supplierCountries: JSON.parse(result["supplierCountries"]),
        buyerCountries: JSON.parse(result["buyerCountries"]),
        currencies: JSON.parse(result["currencies"])
      })
      setBuyerAnnualTurnOver(getKeyByValue(turnoverMapping, result.buyerAnnualturnover))
      setexportRange(getKeyByValue(turnoverMapping, result.exportRange))
      setturnoverRange(getKeyByValue(turnoverMapping, result.turnoverRange))
    } catch (e) {
      console.log('error in getCreditLine', e)
      setShowLoader(false)
    }
  }
  useEffect(() => {
    getCreditLine()
    getHsnCodes()
    getWorldRegions()
  }, [])
  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className='px-5 py-4 pt-5'>
        <p className='font-size-14 font-wt-700'>Select Eligibility Criteria</p>
        <div className='card border-0 chatlist p-4'>
          <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Onboarding</p>
          <p className='font-size-14 font-wt-700 my-2 text-decoration-underline'>Supplier Profile</p>
          <div className='row py-2 pl-3'>
            {supplierDetails.map((item, index) => {
              return <div className="col-md-6 pt-2" key={index}>
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["onboarding"][item.alt] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "onboarding", item.alt, !data["onboarding"][item.alt])} />
                  <p className="m-0">{item.name}</p>
                </div>
              </div>
            })}
          </div>
          <div className='row py-2 pl-3'>
            <div className='col-md-6 p-0'>
              <div className='col-md-10'>
                <label className='font-size-14 font-wt-700 '>Existing Export Turnover</label>
                <div className='row'>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setexportRange('Less than 1M')}>
                    <input className="form-check-input" type="radio" value={"Less than 250 Cr"} checked={exportRange === 'Less than 1M'} />
                    <label className="form-check-label p-0 m-0" >
                      Less than 1M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setexportRange('Between 1M-10M')}>
                    <input className="form-check-input" type="radio" value={"Between 1M-10M"} checked={exportRange === 'Between 1M-10M'} />
                    <label className="form-check-label p-0 m-0" >
                      Between 1M-10M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setexportRange('More than 10M')}>
                    <input className="form-check-input" type="radio" value={"More than 10M"} checked={exportRange === 'More than 10M'} />
                    <label className="form-check-label p-0 m-0" >
                      More than 10M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6 ' onClick={() => setexportRange('Others')}>
                    <input className="form-check-input" type="radio" value={"Others"} checked={exportRange === 'Others'} />
                    <label className="form-check-label p-0 m-0" >
                      Others
                    </label>
                  </div>
                </div>
                <div className='col-md-12 pl-0'>
                  <div className='col-md-10 p-0'>
                    <InputEdit Placeholder="Audited Financials (in years)" Label="" name={"audFin"} value={data.audFin} onChange={(e) => handleChange(e)} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 p-0'>
              <div className='col-md-10'>
                <label className='font-size-14 font-wt-700 '>Annual Turnover</label>
                <div className='row'>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setturnoverRange('Less than 1M')}>
                    <input className="form-check-input" type="radio" value={"Less than 250 Cr"} checked={turnoverRange === 'Less than 1M'} />
                    <label className="form-check-label p-0 m-0" >
                      Less than 1M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setturnoverRange('Between 1M-10M')}>
                    <input className="form-check-input" type="radio" value={"Between 1M-10M"} checked={turnoverRange === 'Between 1M-10M'} />
                    <label className="form-check-label p-0 m-0" >
                      Between 1M-10M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setturnoverRange('More than 10M')}>
                    <input className="form-check-input" type="radio" value={"More than 10M"} checked={turnoverRange === 'More than 10M'} />
                    <label className="form-check-label p-0 m-0" >
                      More than 10M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6 ' onClick={() => setturnoverRange('Others')}>
                    <input className="form-check-input" type="radio" value={"Others"} checked={turnoverRange === 'Others'} />
                    <label className="form-check-label p-0 m-0" >
                      Others
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-md-12 pl-0'>
                <div className='col-md-10 p-0'>
                  <InputEdit Placeholder="Minimum export experience (in years)" Label="" name={"minExp"} value={data.minExp} onChange={(e) => handleChange(e)} />
                </div>
              </div>
            </div>

          </div>

          <p className='font-size-14 font-wt-700 my-2 text-decoration-underline'>Buyer Profile</p>
          <div className='row py-2 pl-3'>
            {buyerDetails.map((item, index) => {
              return <div className="col-md-6 pt-2" key={index}>
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["onboarding"][item.alt] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "onboarding", item.alt, !data["onboarding"][item.alt])} />
                  <p className="m-0">{item.name}</p>
                </div>
              </div>
            })}
          </div>
          <div className='row py-2 pl-3'>
            <div className='col-md-6 p-0'>
              <div className='col-md-10'>
                <label className='font-size-14 font-wt-700 '>Annual Turnover</label>
                <div className='row'>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setBuyerAnnualTurnOver('Less than 1M')}>
                    <input className="form-check-input" type="radio" value={"Less than 250 Cr"} checked={buyerAnnualTurnover === 'Less than 1M'} />
                    <label className="form-check-label p-0 m-0" >
                      Less than 1M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setBuyerAnnualTurnOver('Between 1M-10M')}>
                    <input className="form-check-input" type="radio" value={"Between 1M-10M"} checked={buyerAnnualTurnover === 'Between 1M-10M'} />
                    <label className="form-check-label p-0 m-0" >
                      Between 1M-10M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6' onClick={() => setBuyerAnnualTurnOver('More than 10M')}>
                    <input className="form-check-input" type="radio" value={"More than 10M"} checked={buyerAnnualTurnover === 'More than 10M'} />
                    <label className="form-check-label p-0 m-0" >
                      More than 10M
                    </label>
                  </div>
                  <div className='d-flex flex-row p-2 col-6 ' onClick={() => setBuyerAnnualTurnOver('Others')}>
                    <input className="form-check-input" type="radio" value={"Others"} checked={buyerAnnualTurnover === 'Others'} />
                    <label className="form-check-label p-0 m-0" >
                      Others
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row py-2 pl-3'>
            <div className='col-md-6'>
              <div className='col-md-10 p-0'>
                <InputEdit Placeholder="Past relation between buyer and seller(in years)" Label="" name={"buyerSellerInYears"} value={data.buyerSellerInYears} onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='col-md-10 p-0'>
                <InputEdit Placeholder="Audited Financials (in years)" Label="" name={"audFin"} value={data.audFin} onChange={(e) => handleChange(e)} />
              </div>
            </div>
          </div>
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Finance available for</p>
          <div className='row py-2'>
            {companyTypes.map((item, index) => {
              console.log('CompanyTypes', data["companyTypes"][item.alt])
              return <div className="col-md-6 pt-2" key={index}>
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["companyTypes"][item.alt] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "companyTypes", item.alt, !data["companyTypes"][item.alt])} />
                  <p className="m-0">{item.name}</p>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Services offered</p>
          <div className='row py-2'>
            {ServicesOffered.map((item, index) => {
              return <div className="col-md-6 pt-2">
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["services"][item.alt] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "services", item.alt, !data["services"][item.alt])} />
                  <p className="m-0">{item.name}</p>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <div className='d-flex flex-row justify-content-between'>
            <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Industry</p>
            <div className='d-flex flex-row align-items-center'>
              <img src={industryData.every(currency => data["industry"][currency.value]) ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => { industryData.every(currency => data["industry"][currency.value]) ? deselectAll(industryData, "industry", "value") : selectAll(industryData, "industry", "value") }} />
              <p className="m-0">{"Select all"}</p>
            </div>
          </div>
          <div className='row py-2'>
            {industryData.map((item, index) => {
              return <div className="col-md-4 pt-2">
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["industry"][item.value] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "industry", item.value, !data["industry"][item.value])} />
                  <p className="m-0">{item.name}</p>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <div className='d-flex flex-row justify-content-between'>
            <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>HSN Codes</p>
            <div className='d-flex flex-row align-items-center'>
              <img src={hsnCodes.every(hsncode => data["HSNCodes"][hsncode.code]) ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => { hsnCodes.every(hsncode => data["HSNCodes"][hsncode.code]) ? deselectAll(hsnCodes, "HSNCodes", "code") : selectAll(hsnCodes, "HSNCodes", "code") }} />
              <p className="m-0">{"Select all"}</p>
            </div>
          </div>
          <div className='row py-2'>
            {hsnCodes.map((item, index) => {
              return <div className="col-md-2 pt-2">
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["HSNCodes"][item.code] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "HSNCodes", item.code, !data["HSNCodes"][item.code])} />
                  <p className="m-0">{item.code}</p>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <div className='d-flex flex-row justify-content-between'>
            <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Supplier Country (All deals will received excluding selected below)</p>
            <div className='d-flex flex-row align-items-center'>
              <img src={countries.every(country => data["supplierCountries"][country.sortname + "-" + country.region_id]) && !isEmpty(data["supplierCountries"]) ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => { countries.every(country => data["supplierCountries"][country.name + "-" + country.region_id]) ? deselectAll(countries, "supplierCountries", "sortname", "region_id") : selectAll(countries, "supplierCountries", "sortname", "region_id") }} />
              <p className="m-0">{"Select all"}</p>
            </div>
          </div>
          <div className='row py-2'>
            {regions.map((item, index) => {
              const isRegionSelected = Object.keys(data.supplierCountries).some(key => key?.split('-')[1] == item.region_id && data["supplierCountries"][key])
              return <div className="col-md-2 pt-2">
                <div className='d-flex flex-row align-items-center'>
                  <img src={isRegionSelected ? '/assets/images/checkbox_some.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' />
                  <p className="m-0">{item.region}</p>
                  <img src='/assets/images/arrowdown.png' height={20} width={20} className='ml-2 cursor' onClick={() => getCountriesByRegionId(item.region_id)} />
                </div>
              </div>
            })}
          </div>
          {countries.length > 0 ?
            <div className='row p-3'>
              {countries.map((item, index) => {
                return <div className="col-md-3 pt-2">
                  <div className='d-flex flex-row align-items-center'>
                    <img src={data["supplierCountries"][item.sortname + "-" + item.region_id] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "supplierCountries", item.sortname + "-" + item.region_id, !data["supplierCountries"][item.sortname + "-" + item.region_id])} />
                    <p className="m-0">{item.name}</p>
                  </div>
                </div>
              })}
            </div>
            : null}
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <div className='d-flex flex-row justify-content-between'>
            <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Buyer Country (All deals will received excluding selected below)</p>
            <div className='d-flex flex-row align-items-center'>
              <img src={countries.every(country => data["buyerCountries"][country.sortname + "-" + country.region_id]) && !isEmpty(data["buyerCountries"]) ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => { countries.every(country => data["buyerCountries"][country.sortname + "-" + country.region_id]) ? deselectAll(countries, "buyerCountries", "sortname", "region_id") : selectAll(countries, "buyerCountries", "sortname", "region_id") }} />
              <p className="m-0">{"Select all"}</p>
            </div>
          </div>
          <div className='row py-2'>
            {regions.map((item, index) => {
              const isRegionSelected = Object.keys(data.buyerCountries).some(key => key?.split('-')[1] == item.region_id && data["buyerCountries"][key])
              return <div className="col-md-2 pt-2">
                <div className='d-flex flex-row align-items-center'>
                  <img src={isRegionSelected ? '/assets/images/checkbox_some.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' />
                  <p className="m-0">{item.region}</p>
                  <img src='/assets/images/arrowdown.png' height={20} width={20} className='ml-2 cursor' onClick={() => getCountriesByRegionId(item.region_id)} />
                </div>
              </div>
            })}
          </div>
          {countries.length > 0 ?
            <div className='row p-3'>
              {countries.map((item, index) => {
                return <div className="col-md-3 pt-2">
                  <div className='d-flex flex-row align-items-center'>
                    <img src={data["buyerCountries"][item.sortname + "-" + item.region_id] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "buyerCountries", item.sortname + "-" + item.region_id, !data["buyerCountries"][item.sortname + "-" + item.region_id])} />
                    <p className="m-0">{item.name}</p>
                  </div>
                </div>
              })}
            </div>
            : null}
        </div>
        <div className='card border-0 chatlist p-4 mt-4'>
          <div className='d-flex flex-row justify-content-between'>
            <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>Currencies</p>
            <div className='d-flex flex-row align-items-center'>
              <img src={Currency.every(currency => data["currencies"][currency.code]) ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => { Currency.every(currency => data["currencies"][currency.code]) ? deselectAll(Currency, "currencies", "code") : selectAll(Currency, "currencies", "code") }} />
              <p className="m-0">{"Select all"}</p>
            </div>
          </div>
          <div className='row py-2'>
            <div className="col-md-4 pt-2">
              <div className='d-flex flex-row align-items-center'>
                <img src={most_used_currencies.some(currency => data["currencies"][currency.code]) ? '/assets/images/checkbox_some.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' />
                <p className="m-0">{"Top 10 Currencies"}</p>
                <img src='/assets/images/arrowdown.png' height={20} width={20} className='ml-2 cursor' onClick={() => setCurrency(most_used_currencies.slice(0, 10))} />
              </div>
            </div>
            <div className="col-md-4 pt-2">
              <div className='d-flex flex-row align-items-center'>
                <img src={Currency.some(currency => data["currencies"][currency.code]) ? '/assets/images/checkbox_some.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' />
                <p className="m-0">{"Other Currencies"}</p>
                <img src='/assets/images/arrowdown.png' height={20} width={20} className='ml-2 cursor ' onClick={() => getcurrencylist()} />
              </div>
            </div>
          </div>
          <div className='row p-3'>
            {Currency.map((item, index) => {
              return <div className="col-md-4 pt-2">
                <div className='d-flex flex-row align-items-center'>
                  <img src={data["currencies"][item.code] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={(event) => handleChange(event, "currencies", item.code, !data["currencies"][item.code])} />
                  <p className="m-0">{`${item.symbol} ${item.code} - ${item.name}`}</p>
                </div>
              </div>
            })}
          </div>
        </div>
        {Object.keys(data['services']).some(item => item.includes('lc') && data['services'][item]) &&
          <div className='card border-0 chatlist p-4 mt-4'>
            <p className='font-size-14 font-wt-700 text-color1 text-decoration-underline'>LC issuing bank SWIFT code</p>
          </div>
        }
        <div className="d-flex gap-4">
          <button className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`} onClick={addCreditLine}>Save</button>
        </div>
      </div>
    </div >
  )
}

export default CreditLineTab