import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import FilePreview from '../utilComponents/previewFile';

const OtherDocuments = ({ userTokenDetails }) => {

  const [docList, setDocList] = useState([{}])
  const [refresh, setrefresh] = useState(0)
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  useEffect(() => {
    call('POST', 'getUserOtherDocs', { userId }).then((result) => {
      if (result.length) {
        result.forEach((item, index) => {
          result[index]["isDbData"] = true
        });
        setDocList(result)
      }
      else {
        setDocList([{}])
      }
    }).catch((error) => {
      // console.log("error occur in getUserOtherDocs ->", error)
    })
  }, [refresh])

  function addDocList() {
    let temp = docList
    temp = temp.concat([{}])
    setDocList(temp)
  }

  function removeDoc(id) {
    call('POST', 'deleteUserOtherDocs', { userId, docId: id }).then((result) => {
      toastDisplay("Document removed successfully", "success")
      setrefresh(refresh + 1)
    }).catch((error) => {
      // console.log("error occur in deleteUserOtherDocs ->", error)
    })
  }

  function submitDoc(index) {
    let formData = new FormData();
    formData.append('file', docList[index]["doc:" + index]);
    formData.append('userId', userId);
    formData.append('doc_name', docList[index]["docName:" + index]);
    call('POST', 'addUserOtherDocs', formData).then((result) => {
      toastDisplay("Document uploaded successfully", "success")
      setrefresh(refresh + 1)
    }).catch((error) => {
      // console.log("error occur in addUserOtherDocs ->", error)
    })
  }

  function handleInput(event) {
    event.persist()
    let temp = docList
    let docIndex = event.target.name.split(":")[1]
    let eventName = event.target.name.split(":")[0]
    if (eventName === "doc") {
      temp[docIndex][event.target.name] = event.target.files[0]
    }
    else {
      temp[docIndex][event.target.name] = event.target.value
    }
    setDocList([...temp])
  }

  function callPreviewFileComponent(values, action) {
    setfileData({
      id: values.id,
      file_hash: values.file_hash,
      file_name: values.file_name,
      file_type: values.doc_name,
      created_at: values.created_at,
      "action": action
    })
    setshowPreviewModal(true)
  }

  return (
    <>
      {showPreviewModal &&
        <FilePreview
          mktPlaceShipment={true}
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="row">
        <div className="col-md-12">
          <h2>Other Documents</h2>
        </div>
        <div className='col-md-12 pb-3'>
          {docList.map((item, index) => {
            return (
              <div className='row mt-3'>
                <div className='col-md-4'>
                  <input type="text" className={" form-control"} placeholder={"Enter document name"} name={"docName:" + index}
                    defaultValue={item.isDbData ? item.doc_name : ""} disabled={item.isDbData} onChange={handleInput} />
                </div>
                <div className='col-md-4'>
                  {item.isDbData ? null : (
                    <div className="file-browse ">
                      <button className="btn btn-primary btn-sm">Select Document</button>
                      <input type="file" accept=".png,.jpg,.pdf" name={"doc:" + index} onChange={handleInput} />
                    </div>
                  )}
                  {item && item["doc:" + index] && item["doc:" + index]["name"] ?
                    <div className="form-group mb-0"><div className="file-name mt-2">{`${item["doc:" + index]["name"]}`}</div></div> : ""}
                  {item && item.isDbData ?
                    <div className="form-group mb-0"><div className="file-name mt-2">{`${item.file_name}`}</div></div> : ""}
                </div>
                <div className='col-md-2'>
                  {item.isDbData ? (
                    <>
                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(item, "view")}>
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button title="Delete File" className="text-center btn btn-danger btn-sm m-1" onClick={
                        () => removeDoc(item.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </>
                  ) : (<button disabled={!(item && item["docName:" + index] && item["doc:" + index])}
                    onClick={() => submitDoc(index)} className="btn btn-primary btn-sm">Submit</button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className="col-md-12">
          <div className="d-flex mt-2 ml-2"><button className="btn-primary btn btn-sm" onClick={() => addDocList()}>Add Document +</button></div>
        </div>
      </div>
    </>)
}



export default OtherDocuments
