import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import call from '../../service'
import { FileInput } from '../../utils/FileInput'
import { convertImageToPdf, dataURItoBlob, most_used_currencies } from '../../utils/myFunctions'
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput'
import toastDisplay from '../../utils/toastNotification'
import SignDocument from '../InvoiceDiscounting/components/SignDocument'
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2'
import avatarUrl from '../../utils/makeAvatarUrl'
import { environment, platformURL } from '../../urlConstants'
import moment from 'moment'
import MultipleSelect from '../../utils/MultipleSelect'
import NewTablev2 from '../../utils/newTablev2'
import { documentTable3 } from '../Dashboard/TableJson'
import { DocumentViewer } from '../../utils/documentViewer'
import { DocumentDownloader } from '../../utils/DocumentDownloader'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import { FormProgressBar } from '../CommonComponent/FormProgressBar'

let InsuaranceTabs = [
  { name: "Applicant & Buyer" },
  { name: "Bank" },
  { name: "Shipment" },
  { name: "Select Insurer" },
  { name: "Review" }
]


const exorterDetailsForm = [
  { "name": "Exporter Name", val: "companyName" },
  { "name": "Contact Number", val: "contact_number", unit: "phonecode" },
  { "name": "Email ID", val: "email_id" },
  { "name": "Website", val: "website" },
  { "name": "Address", val: ["companyAdd1", "companyAdd2", "city", "state", "country", "postal_code"], isMultipleKeys: true },
]

const buyerDetailsForm = [
  { "name": "Buyer name", val: "buyer_name" },
  { "name": "Contact no.", val: "buyer_contact_number", unit: "buyer_phonecode" },
  { "name": "Email ID", val: "buyer_email_id" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Address", val: ["buyeraddLine1", "buyeraddLine2", "buyercity", "buyerstate", "buyercountry", "buyerpostal_code"], isMultipleKeys: true }
]


const expBankDetails = [
  { "name": "Bank Name", val: "expBankName" },
  { "name": "Branch", val: "expBankBranch" },
  { "name": "Bank Address", val: ["expbankaddLine1", "expbankaddLine2", "expbankcity", "expbankstate", "expbankcountry", "expbankpostal_code"], isMultipleKeys: true },
  { "name": "Account Holder Name", val: "expbankAccName" },
  { "name": "Account No.", val: "expbankAccNo" },
  { "name": "IFSC Code", val: "expbankIFSC_code" },
  { "name": "MICR Code", val: "expbankMICR_code" },
  { "name": "Payment Type", val: "expbankAcc_Type" }
]

const shipmentsReview = [
  { "name": "Date Of Contract", val: "dateContract" },
  { "name": "Date Of Commencement of Contract", val: "dateOfContractStart" },
  { "name": "Estimated Date Of Completion Of Contract", val: "estContractCompletionDate" },
  { "name": "Description Of Goods", val: "goods_desc" },
  { "name": "Gross Invoice Value Of Contract", val: "grossInvValue", unit: "shipmentCurrency" },
  { "name": "Amount Payable (Advance / Downpayment)", val: "advPayment", unit: "shipmentCurrency" },
  { "name": "Amount Payable After Each Shipment", val: "amtAfterShipment", unit: "shipmentCurrency" },
  { "name": "No. Of Shipments Under Contract", val: "noOfShipments" }
]

const TCITransactional = ({ userTokenDetails, navToggleState }) => {
  let quotesDetailTemp = localStorage.getItem("quotesDetail");
  const quoteDetails = JSON.parse(quotesDetailTemp)
  const queryParams = new URLSearchParams(window.location.search)
  const buyerId = queryParams.get("buyerid")
  const exporterId = queryParams.get("exporterId")

  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ isChecked: false, buyer_name: quoteDetails?.buyerName })
  const [errors, setErrors] = useState({})
  const [countrydata, setCountrydata] = useState([])
  const [signdoc, setSigndoc] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [InsurerList, setInsurerList] = useState([])
  const [userBanks, setUserBanks] = useState([])
  const [multInvPo, setMultInvPo] = useState([{ purchase_orders: [null], invoices: [null] }])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [tableDocsData, setTableDocsData] = useState([])
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const getallCountry = () => {
    setShowLoader(true)
    call('GET', 'getallCountry').then((result) => {
      console.log('running getallCountry api-->', result);
      setCountrydata(result)
      setShowLoader(false)
    }).catch((e) => {
      setShowLoader(false)
      // console.log('error in getBuyersDetail', e);
    });
  }
  useEffect(() => {
    getallCountry()
  }, [])
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const handleValidations = () => {
    let err = {}
    if (tab == 4 && !data.tcAccepted) {
      err["tcAccepted"] = "Mandatory"
      toastDisplay("Kindly accept terms & conditions, to continue", "info")
      return
    } else if (tab == 4 && data.tcAccepted) {
      let formData = new FormData()
      formData.append("financierId", userId)
      formData.append("buyerId", buyerId)
      formData.append("exporterId", exporterId)
      formData.append("service_name", "Direct")
      formData.append("type", "Transactional")
      formData.append("selectedInsuarerLists", JSON.stringify(InsurerList))

      Object.keys(data).forEach(key => {
        if (!key.includes("Document")) {
          formData.append(key, data[key])
        }
      })
      for (let index = 0; index < multInvPo.length; index++) {
        const element = multInvPo[index];
        for (let j = 0; j < element.invoices.length; j++) {
          if (data[`invoiceDocument:${index}:${j}`]?.name) {
            formData.append("invoiceDocument" + index + ":" + j, data[`invoiceDocument:${index}:${j}`])
            if (data[`invoiceDocument:${index}:${j}`]?.signatureId) {
              formData.append("invoiceRefrenceNo" + index + ":" + j + "SignId", data[`invoiceDocument:${index}:${j}`].signatureId)
            }
            formData.append("invoiceRefrenceNo" + index + ":" + j, data["invoiceRefrenceNo" + ":" + index + ":" + j])
            formData.append("currency" + index + ":" + j, data["currency" + ":" + index + ":" + j])
            formData.append("contractAmount" + index + ":" + j, data["contractAmount" + ":" + index + ":" + j])
          }
        }
        for (let j = 0; j < element.purchase_orders.length; j++) {
          if (data[`poDocument:${index}:${j}`]?.name) {
            formData.append("poDocument" + index + ":" + j, data[`poDocument:${index}:${j}`])
            if (data[`poDocument:${index}:${j}`]?.signatureId) {
              formData.append("poDocument" + index + ":" + j + "SignId", data[`poDocument:${index}:${j}`].signatureId)
            }
            formData.append("poRefrenceNo" + index + ":" + j, data["poRefrenceNo" + ":" + index + ":" + j])
            formData.append("poAmount" + index + ":" + j, data["poAmount" + ":" + index + ":" + j])
          }
        }
      }
      setShowLoader(true)
      call('POST', 'applyInsuarance', formData).then(res => {
        setShowLoader(false);
        toggleApplicationSubmitPopup({ show: true, data: {} })
      }).catch(err => { setShowLoader(false); toastDisplay("Something went wrong") })
      return
    }

    setTab(tab + 1)
  }
  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  useEffect(() => {
    fetchUserDetails()
  }, [])
  function handleFinSelection(j) {
    let temp = InsurerList
    temp[j]["isChecked"] = !temp[j]["isChecked"]
    setInsurerList([...temp])
  }
  async function fetchUserDetails() {
    let resp = exporterId ? await call("POST", "getUserDetailsById", { contactUserId: exporterId }) : []
    let userResp = resp?.[0] || {}
    // console.log("userIECResppppppppppppp", userIECResp);
    let buuyerp = buyerId ? await call("POST", "getBuyerDetailsById", { buyerId: buyerId }) : []
    let BuyerResp = buuyerp?.[0] || {}
    // console.log("userIECResppppppppppppp", userIECResp);
    let addressComponents = {}
    if (BuyerResp?.buyerAddress?.length) {
      addressComponents = await call('POST', 'getAddressComponents', { address: BuyerResp.buyerAddress })
    }

    let finApiResp = await call('POST', "getFinanciersForInsurance", {})
    let finIds = []
    for (let index = 0; index < finApiResp.length; index++) {
      const element = finApiResp[index];
      element["isAvailable"] = true
      element["logo"] = element.user_avatar || null
      element["isChecked"] = true
      element["ratings"] = element.avgRatings || 0
      finIds.push(element.id)
    }

    setInsurerList(finApiResp)

    setData({
      ...data,
      selectedInsuarer: finApiResp,
      companyName: userResp.company_name,
      contact_person: userResp.contact_person,
      contact_number: userResp.contact_number,
      phonecode: userResp.phone_code,
      email_id: userResp.email_id,
      companyAdd1: userResp.user_address,
      city: userResp.company_city,
      state: userResp.company_state,
      country: userResp.country_code,
      postal_code: userResp.company_postal_code,
      user_address: userResp.user_address,
      designation: userResp.designation,
      name_title: userResp.name_title,
      buyer_contact_number: BuyerResp.buyerPhone,
      buyer_phonecode: BuyerResp.buyerPhoneCode,
      buyer_email_id: BuyerResp.buyerEmail,
      buyeraddLine1: BuyerResp.buyerAddress,
      buyercity: addressComponents.city,
      buyerstate: addressComponents.state,
      buyercountry: BuyerResp.buyerCountry,
      buyerpostal_code: addressComponents.postalCode
    })
    call("POST", "getUserBanks", { "email": userResp.email_id, "inProfile": true })
      .then((result) => {
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        // console.log("Error while querying getUserBanks:", e);
      });
  }
  async function formatInvPoDocsForReview() {
    let row = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      row.push(["Invoice Details " + (index + 1)])
      for (let j = 0; j < element.invoices.length; j++) {
        if (data?.["invoiceDocument" + ":" + j]?.name) {
          row.push([`Invoice - ${data["invoiceRefrenceNo" + ":" + j]}`, `${data["currency" + ":" + j]} ${data["contractAmount" + ":" + j]}`,
          <FileInput
            isEditable={false}
            value={data["invoiceDocument" + ":" + j]}
          />,
          <>
            {
              !data?.["invoiceDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["invoiceDocument" + ":" + j], docSignName: ["invoiceDocument" + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["invoiceDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      for (let j = 0; j < element.purchase_orders.length; j++) {
        if (data?.["poDocument" + ":" + j]?.name) {
          row.push([`PO - ${data["poRefrenceNo" + ":" + j]}`, `-`,
          <FileInput
            isEditable={false}
            value={data["poDocument" + ":" + j]}
          />,
          <>
            {
              !data?.["poDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["poDocument" + ":" + j], docSignName: ["poDocument" + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["poDocument" + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      if (row.length) {
        let lastElementInArr = row[row.length - 1]
        lastElementInArr[100] = "To Add Line"
      }
    }
    setTableDocsData([...row])
  }
  useEffect(() => {
    formatInvPoDocsForReview()
  }, [data])
  const handleMultiSelect = async (e, name, val, index) => {
    console.log("handleMultiSelect", e, name, val);
    if (e?.[0]?.id.toString().includes("addNewBank")) {
      return window.open(`${platformURL}/viewprofile?action=addNewBank`, "_blank")
    }

    if (name?.includes("expBankName")) {
      let selectedEntry = e?.[0]?.id && e.reverse()?.[0]?.id ? e.reverse()?.[0] : {}
      setData({
        ...data,
        [name]: selectedEntry.id || null,
        [`expBankBranch`]: selectedEntry.beneficiaryBranch || "",
        [`expbankcity`]: selectedEntry.beneficiaryCity || "",
        [`expbankstate`]: selectedEntry.beneficiaryState || "",
        [`expbankcountry`]: selectedEntry.beneficiaryCountry || "",
        [`expbankpostal_code`]: selectedEntry.beneficiaryPostalCode || "",
        [`expbankaddLine1`]: selectedEntry.beneficiaryBranchAddress || ""

      });
    }
    else {
      setData({
        ...data,
        [name]: e?.[0]?.id ? e.reverse()?.[0]?.id : null
      })
    }

  };
  async function reloadUserBankDetails() {
    setShowLoader(true)
    call("POST", "getUserBanks", { "email": data.email_id, "inProfile": true })
      .then((result) => {
        setShowLoader(false)
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        setShowLoader(false)
        // console.log("Error while querying getUserBanks:", e);
      });
  }
  const handleFileWithAsyncAwait = async (event, poNo, poSubNo, prevData, tempMultInvPoLength) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setShowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setShowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          console.log("insidehandleFileeeeeeeeeeeeeeee", tempData);
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <SideBarV2 state="other_services_credit_insurance" userTokenDetails={userTokenDetails} />
        <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
        <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        {applicationSubmitPopup.show ? (
          <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
            toggleApplicationSubmitPopup(false)
            window.history.back()
          }}>
            <div className='text-center'>
              <img src='/assets/images/successful_icon.svg' />
              <div className="my-4">
                <img style={{}} src="assets/images/greetings_congratulation.svg" />
              </div>
              <p className="m-0 p-0 px-5 font-size-16" >Your application for <span className="fc-3DB16F" >Transactional Insurance </span> has been submitted successfully</p>
            </div>
          </FinanceInvoiceModal>
        ) : null}
        <main role="main" className={"container-bg ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={`Insuarance > Trade Credit Insuarance > Transactional`}
            userTokenDetails={userTokenDetails} />
          <div className='d-flex flex-row align-items-center'>
            <a className="cursor"
              onClick={() => { window.location = "/CreditInsurance" }}
            ><img
                src={"assets/images/back.png"} alt="Back" height={25} width={25} /></a>
          </div>
          <div className='mt-4'>
            {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
              {InsuaranceTabs.map((item, index) => {
                return (
                  <li>
                    <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                      onClick={() => { setTab(index) }}
                    >
                      {item.name}</a>
                  </li>
                )
              })}
            </ul> */}
            <div className="card mt-1">

              <div className="d-flex justify-content-center mt-5 mb-3" >
                <FormProgressBar
                  tabs={InsuaranceTabs} activeTab={tab} label={"name"}
                  separationWidth={'7rem'} handleClick={(i, index) => { setTab(index) }}
                />
              </div>

              {tab === 0 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Exporter details</label>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Company Name"}
                            name={"companyName"} value={data.companyName} error={errors.companyName}
                            onChange={handleChange} isDisabled={true} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Contact Number"}
                            selectData={countrydata}
                            selectName={"phonecode"} selectValue={data.phonecode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Email ID"}
                            name={"email_id"} value={data.email_id} error={errors.email_id}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Website"}
                            name={"website"} value={data.website} error={errors.website}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 1"}
                            name={"addLine1"} value={data.addLine1} error={errors.addLine1}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 2"}
                            name={"addLine2"} value={data.addLine2} error={errors.addLine2}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"City"}
                            name={"city"} value={data.city} error={errors.city}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"State"}
                            name={"state"} value={data.state} error={errors.state}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewSelect isAstrix={false} label={"Country"}
                            selectData={countrydata} name={"country"}
                            value={data.country} optionLabel={"name"} optionValue={'sortname'}
                            onChange={handleChange} error={errors.country} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Postal Code"}
                            name={"postal_code"} value={data.postal_code} error={errors.postal_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Contact Number"}
                            selectData={countrydata}
                            selectName={"phonecode"} selectValue={data.phonecode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Contact Number"}
                            selectData={countrydata}
                            selectName={"phonecode"} selectValue={data.phonecode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                            onChange={handleChange} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='col-md-10 py-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Buyer details</label>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Buyer Name"}
                            name={"buyer_name"} value={data.buyer_name} error={errors.buyer_name}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"number"} label={"Contact No."}
                            selectData={countrydata}
                            selectName={"buyer_phonecode"} selectValue={data.buyer_phonecode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'buyer_contact_number'} value={data.buyer_contact_number} error={errors.buyer_contact_number}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Email ID"}
                            name={"buyer_email_id"} value={data.buyer_email_id} error={errors.buyer_email_id}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput label={"Website"}
                            type={"text"} name={"buyerWebsite"}
                            value={data.buyerWebsite} optionLabel={"name"} optionValue={'value'}
                            onChange={handleChange} error={errors.buyerWebsite} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 1"}
                            name={"buyeraddLine1"} value={data.buyeraddLine1} error={errors.buyeraddLine1}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 2"}
                            name={"buyeraddLine2"} value={data.buyeraddLine2} error={errors.buyeraddLine2}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"City"}
                            name={"buyercity"} value={data.buyercity} error={errors.buyercity}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"State"}
                            name={"buyerstate"} value={data.buyerstate} error={errors.buyerstate}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewSelect isAstrix={false} label={"country"}
                            selectData={countrydata} name={"buyercountry"}
                            value={data.buyercountry} optionLabel={"name"} optionValue={'value'}
                            onChange={handleChange} error={errors.buyercountry} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Postal Code"}
                            name={"buyerpostal_code"} value={data.buyerpostal_code} error={errors.buyerpostal_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              ) : null}
              {tab === 1 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-10 py-2 mt-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-4'>Exporter bank details</label>
                    <div className='row'>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">

                          <MultipleSelect
                            onClick={reloadUserBankDetails}
                            Id="Beneficiary bank name"
                            Label="Beneficiary bank name"
                            optiondata={userBanks}
                            onChange={(e) => handleMultiSelect(e, `expBankName`, "id")}
                            value={data[`expBankName`] ? [data[`expBankName`]] : []}
                            name={`expBankName`}
                            labelKey={"beneficiaryBankName"}
                            valKey={"id"}
                            error={errors[`expBankName`]}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"text"} label={"Branch"}
                            name={"expBankBranch"} value={data.expBankBranch} error={errors.expBankBranch}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 1"}
                            name={"expbankaddLine1"} value={data.expbankaddLine1} error={errors.expbankaddLine1}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Address line 2"}
                            name={"expbankaddLine2"} value={data.expbankaddLine2} error={errors.expbankaddLine2}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"City"}
                            name={"expbankcity"} value={data.expbankcity} error={errors.expbankcity}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"State"}
                            name={"expbankstate"} value={data.expbankstate} error={errors.expbankstate}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewSelect isAstrix={false} label={"country"}
                            selectData={countrydata} name={"expbankcountry"}
                            value={data.expbankcountry} optionLabel={"name"} optionValue={'value'}
                            onChange={handleChange} error={errors.expbankcountry} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Postal Code"}
                            name={"expbankpostal_code"} value={data.expbankpostal_code} error={errors.expbankpostal_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Account Holder Name"}
                            name={"expbankAccName"} value={data.expbankAccName} error={errors.expbankAccName}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Account Number"}
                            name={"expbankAccNo"} value={data.expbankAccNo} error={errors.expbankAccNo}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"IFSC Code"}
                            name={"expbankIFSC_code"} value={data.expbankIFSC_code} error={errors.expbankIFSC_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"MICR Code"}
                            name={"expbankMICR_code"} value={data.expbankMICR_code} error={errors.expbankMICR_code}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Account Type"}
                            name={"expbankAcc_Type"} value={data.expbankAcc_Type} error={errors.expbankAcc_Type}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 2 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-10 py-2 mt-2'>
                    <div className='row my-4'>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput type={"date"} label={"Date Of Contract"}
                            name={"dateContract"} value={data.dateContract} error={errors.dateContract}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"date"} label={"Date Of Commencement of Contract"}
                            name={"dateOfContractStart"} value={data.dateOfContractStart} error={errors.dateOfContractStart}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"date"} label={"Estimated Date Of Completion Of Contract"}
                            name={"estContractCompletionDate"} value={data.estContractCompletionDate} error={errors.estContractCompletionDate}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"Description Of Goods"}
                            name={"goods_desc"} value={data.goods_desc} error={errors.goods_desc}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Gross Invoice Value Of Contract"}
                            selectData={most_used_currencies}
                            selectName={"shipmentCurrency"} selectValue={data.grossInvValue}
                            optionLabel={"code"} optionValue={'code'}
                            name={'grossInvValue'} value={data.grossInvValue} error={errors.grossInvValue}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Amount Payable (Advance / Downpayment)"}
                            selectData={most_used_currencies}
                            selectName={"shipmentCurrency"} selectValue={data.advPayment}
                            optionLabel={"code"} optionValue={'code'}
                            name={'advPayment'} value={data.advPayment} error={errors.advPayment}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <InputWithSelect type={"text"} label={"Amount Payable After Each Shipment"}
                            selectData={most_used_currencies}
                            selectName={"shipmentCurrency"} selectValue={data.amtAfterShipment}
                            optionLabel={"code"} optionValue={'code'}
                            name={'amtAfterShipment'} value={data.amtAfterShipment} error={errors.amtAfterShipment}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-0">
                          <NewInput isAstrix={false} type={"text"} label={"No. Of Shipments Under Contract"}
                            name={"noOfShipments"} value={data.noOfShipments} error={errors.noOfShipments}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 3 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-12 py-4 my-2'>
                    <label className='font-size-14 font-wt-600 my-2'>Select insurer (any 5)</label>
                    <div className='row'>
                      {InsurerList.map((item, index) => {
                        return <div className='col-md-4 mt-4 text-center'>
                          <div className='col-md-12'>
                            <div className="card p-4 cursor rounded-3 chatlist h-100">
                              <div className="d-flex gap-3 pb-3 align-items-center">
                                <div>
                                  <img
                                    onClick={() => {
                                      if (item.isAvailable || environment === "dev") {
                                        handleFinSelection(index)
                                      } else {
                                        toastDisplay("Action not availble", "info")
                                      }
                                    }}
                                    className='cursor mr-3' src={`assets/images/${InsurerList?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                                </div>

                                <img
                                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                                  src={avatarUrl(item.logo)} alt="" />
                                <div className='d-flex gap-2 align-items-center'>
                                  <label className='font-size-14 font-wt-500 text-color1 m-0'>View More</label>
                                  <img src='/assets/images/ArrowBack.png' height={20} width={20} style={{ filter: "invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)" }} />
                                </div>
                              </div>
                              <div className="d-flex gap-3 py-3 align-items-center justify-content-between">
                                <div className="d-flex align-items-center gap-2 px-0 cursor">
                                  <div>
                                    <p className="mb-0 font-size-14 text-right">{item.avgRatings.toFixed(2)}
                                    </p>
                                  </div>
                                  <div>
                                    <span>
                                      <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  <p className="font-size-14 mb-1 text-right">{item.reviewsCount}</p>
                                  <p className="font-size-14 mb-1 text-right">{"See reviews"}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 4 ? (
                <div className='px-5 py-4 pt-0'>
                  <div className='col-md-12 py-4 my-2'>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Exporter details</label>
                    <div className="row">
                      {exorterDetailsForm.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Buyer details</label>
                    <div className="row">
                      {buyerDetailsForm.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Exporter Bank details</label>
                    <div className="row">
                      {expBankDetails.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <label className='font-size-14 font-wt-500 text-decoration-underline my-2'>Shipment</label>
                    <div className="row">
                      {shipmentsReview.map((item) => {
                        return (
                          <div className="col-md-6 pe-5">
                            <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {
                              item.isMultipleKeys ? item.val.map(item => data[item]).join(",")
                                : data[item.val] ? item.unit ? data[item.unit] : "" + data[item.val] : "NA"}</span> </p>
                          </div>
                        );
                      })}
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Insurer selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {InsurerList.map((key) => {
                          if (key.isChecked) {
                            return (
                              <label key={key.name}
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {key.name}
                              </label>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className='mt-5'>
                      <div className='d-flex flex-row align-items-center'>
                        <img className='mr-4 cursor' src={data.tcAccepted ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })} />
                        <p className="declarationText mb-0" >{"I hereby declare that the above given information by me is true to my knowledge."}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                className="d-flex finActionTabContainer mx-5 mb-5 p-0" >
                <div
                  onClick={() => handleValidations()}
                  style={{ borderRight: '2px solid #5CB8D3' }}
                  className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                  <label className={"font-size-16 font-wt-700 text-white cursor"} >{tab === 4 ? "Submit" : "Next"}</label>
                </div>
                <div
                  onClick={() => {
                    if (tab > 0) {
                      setTab(tab - 1)
                    }
                  }}
                  style={{ borderRight: '2px solid #5CB8D3' }} className={`w-25 text-center py-2 ${tab === 0 ? ' disable-bg ' : " "}`}>

                  <label className={`font-size-14 font-wt-500 text-dark cursor `} >Previous</label>
                </div>
                <div
                  onClick={() => { }}
                  style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-2">

                  <label className={"font-size-14 font-wt-500 text-dark cursor"} >Save as draft</label>
                </div>
                <div className="w-25 text-center py-2">
                  <label className={"font-size-14 font-wt-500 text-dark cursor"} >Draft</label>

                </div>
              </div>
            </div>
          </div>
        </main >
        {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />
        }
      </div >

    </>
  )
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}
export default connect(mapStateToProps, null)(TCITransactional)

