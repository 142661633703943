import React from "react";
import { getNumberUnit } from "../../../utils/myFunctions";

const Stats = ({ StatsData }) => {
  return (
    <>
      <div className='d-flex justify-content-between borderright'>
        {StatsData.map((key, index) => {
          return (
            <div className='col-auto' key={index}>
              <a href="" className="text-decoration-none"><p className={`dasboard-count ${key.Customcolor} `}>{key.isFormatrequired ? `$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(key.Count)}` : key.Count}</p>
                <label className='dashboard-name cursor'>{key.Name} {key.addicon && <img className='ml-2' src={"assets/images/add_icon.png"} />}</label></a>
            </div>
          );
        })
        }
      </div >
    </>
  );
}
export default Stats;