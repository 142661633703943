import React, { useEffect, useRef, useState } from 'react';
import { convertImageToPdf } from '../../../utils/myFunctions';
import toastDisplay from '../../../utils/toastNotification';

const ChatFooter = ({ message, setTextMsg, sendChatMsg }) => {

  const [dropdown, setDropdown] = useState(false);
  const [submenu, setSubmenu] = useState(false)
  const inputFile = useRef(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const multiDropdownFun = (e) => {
    setDropdown(true);
    setSubmenu(true);
    e.stopPropagation();
    // e.preventDefault();
  }
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      sendChatMsg()
      setTextMsg('')
    }
  };
  const handleFile = (event, isImage) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      console.log('Fileddatataaaa', event.target.files)
      if (event.target.name.includes("otherDoc")) {
        //setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        console.log('File Uploaded', event.target.files[0])
        sendChatMsg(event.target.files[0])
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  return (
    <>
      <div className='w-auto me-3 z-index99'>
        <div className="btn-group dropup">
          <img onClick={(e) => inputFile.current.click()} src={'/assets/images/icon_attachment.png'} alt='' className="cursor" />
          {/* <ul className={`dropdown-menu borderRadius chatmore font-size-12 z-index99 ${dropdown ? "show" : ""}`}>
            <li className="dropstart right-0">
              <a onClick={(e) => inputFile.current.click()} data-bs-toggle="dropdown" aria-expanded="false" className={`dropdown-item ${submenu ? "show" : ""}`}>Document
                <img src={'/assets/images/docs-arrow.png'} alt='' className='ms-3' />
              </a>
              {submenu && <ul className={`subdropdown dropdown-menu borderRadius chatmore font-size-12 z-index99 ${submenu ? "show" : ""}`}>
                <li><a className="dropdown-item">Document vault</a></li>
                <li><a className="dropdown-item">My computer</a></li>
              </ul>}
            </li>
            <li><a className="dropdown-item">Photos</a></li>
          </ul> */}
          <input type='file' id='inputFile' ref={inputFile} name="chatDoc" style={{ display: 'none' }} onClick={event => event.target.value = null} onChange={(e) => {
            handleFile(e)
          }} />
        </div>
      </div>
      <input className='form-control w-100 border send-btn' type="text" placeholder="Type" value={message} onChange={(e) => {
        e.preventDefault()
        setTextMsg(e.target.value)
      }} onKeyDown={handleKeyDown} />
    </>
  );
}
export default ChatFooter;