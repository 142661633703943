const columns = [
  {
    name: 'User Email',
    selector: 'userEmail',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Beneficiary Name',
    selector: 'beneficiaryName',
    sortable: false,
  },
  {
    name: 'Account No.',
    selector: 'account',
    sortable: true,
  },
  {
    name: 'Bank Name',
    selector: 'bankName',
    sortable: true,
  },
  // {
  //   name: 'Account Type',
  //   selector: 'accountType',
  //   sortable: true,
  // },
  {
    name: 'IFSC Code',
    selector: 'ifscCode',
    sortable: false,
    grow: 2,
  },
  {
    name: 'SWIFT Code',
    selector: 'swiftCode',
    sortable: false,
  },
  {
    name: 'Branch Address',
    selector: 'beneficiaryBranchAddress',
    sortable: true,
  },
];

export default columns