import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import { InputForTable, InputWithSelectForTable, NewInput, NewSelect, NewTextArea, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import { most_used_currencies, multiSelectEventHandler, printDiv } from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import SignDocument, { signFonts } from "../InvoiceDiscounting/components/SignDocument";
import ReactCountryFlag from "react-country-flag";
import MultipleSelect from "../../utils/MultipleSelect";
import moment from "moment";

const invoiceTemplateTypes = [
  { name: 'E-Invoice' },
  { name: 'Proforma Invoice' }
]

let supplyTypes = [
  { name: 'Business to Business', code: 'B2B' },
  { name: 'SEZ with payment', code: 'SEZWP' },
  { name: 'SEZ without payment', code: 'SEZWOP' },
  { name: 'Export with Payment', code: 'EXPWP' },
  { name: 'Export without payment', code: 'EXPWOP' },
  { name: 'Deemed Export', code: 'DEXP' }
]

let invItemsTable = [{ subColumns: "SR. NO.", subColumnStyle: { width: '7%' } },
{ subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: '30%' } },
{ subColumns: "HSN NO.", subColumnStyle: { width: '10%' } },
{ subColumns: "QUANTITY", subColumnStyle: { width: '10%' } },
{ subColumns: "UNIT PRICE", subColumnStyle: { width: '12%' } },
{ subColumns: "TAX AMOUNT", subColumnStyle: { width: '12%' } },
{ subColumns: "TOTAL PRICE", subColumnStyle: { width: '15%' } }
]

let chargesTable = [{ subColumns: "CHARGE TITLE", subColumnStyle: { width: '45%' } }, { subColumns: "AMOUNT", subColumnStyle: { width: '45%' } }]

const DirectEwayBill = ({ userTokenDetails, navToggleState, selectedInvoiceData }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({ show: false, data: { type: 'E-Invoice' } })
  const [data, setData] = useState({
    appDate: moment().format("YYYY-MM-DD")
  })
  const [error, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [invOther, setInvOther] = useState([null, null, null, null])
  const [preview, setPreview] = useState({})
  const [signdoc, setSigndoc] = useState(false);
  const [states, setStates] = useState([])
  const [units, setUnits] = useState([])
  const [dbData, setDbData] = useState([])
  const [dbDataFilter, setDbDataFilter] = useState([])
  const [ShipperList, setShipperList] = useState([])
  const [ShipperListFilter, setShipperListFilter] = useState([])
  const [banks, setBanks] = useState([])
  const [banksFilter, setBanksFilter] = useState([])

  let jj = -1

  useEffect(() => {
    if (states.length) {
      if (!selectedInvoiceData?.viewApiResp) {
        fetchExporterDetails()
      }
      getBuyerDetails()
      getShippers()
      getBanks()
    }
  }, [states])

  const getBanks = async () => {
    // setshowLoader(true)
    call('POST', "getUserBanks", { email: userEmail, inProfile: true }).then(bankApiResp => {
      setshowLoader(false)
      setBanks(bankApiResp?.message?.length ? bankApiResp?.message : [])
      setBanksFilter(bankApiResp?.message?.length ? bankApiResp?.message : [])
    }).catch(err => {
      setshowLoader(false)
    })
  }

  const getShippers = async () => {
    // setshowLoader(true)
    call('POST', "getShippers", {}).then(shipperApiResp => {
      setshowLoader(false)
      setShipperList(shipperApiResp?.length ? shipperApiResp : [])
      setShipperListFilter(shipperApiResp?.length ? shipperApiResp : [])
    }).catch(err => {
      setshowLoader(false)
    })
  }

  async function fetchExporterDetails() {
    // setshowLoader(true)
    call('POST', 'getUserDetailsById', { contactUserId: userId }).then(res => {
      setshowLoader(false)
      let userDetails = res?.[0]
      let webtelState = states.filter(i => { return i.StateName.toLowerCase() == userDetails?.company_state?.toLowerCase() })[0]?.Code
      // console.log("userDetailssssssssssssssssssss", userDetails, webtelState, states[0]);
      setData({
        ...data, supplierTradeName: userDetails?.company_name, supplierName: userDetails?.company_name, supplierGSTIN: userDetails?.gst_vat_no,
        supplierAdd1: userDetails?.user_address, supplierCity: userDetails?.company_city, supplierPinCode: userDetails?.company_postal_code, supplierState: webtelState
      })
    }).catch(err => {
      setshowLoader(false)
    })
  }

  const getBuyerDetails = () => {
    // setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyerDetailsV2', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      let tempResultBuyerData = result.buyerData
      for (let index = 0; index < tempResultBuyerData.length; index++) {
        tempResultBuyerData[index][`buyerNameHtml`] = <div>
          <label className="font-size-12 font-wt-500" >{`${tempResultBuyerData[index]["buyerName"]}`}
            <ReactCountryFlag
              countryCode={tempResultBuyerData[index].buyerCountry}
              style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
          </label>
        </div>
      }
      setDbData([...tempResultBuyerData]);
      setDbDataFilter([...tempResultBuyerData]);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
  }

  useEffect(() => {
    if (selectedInvoiceData?.docData) {
      setData(selectedInvoiceData.docData?.data)
      // setInvItems(selectedInvoiceData.docData?.invItems)
      // setInvOther(selectedInvoiceData.docData.invOther)
    }
    call('POST', 'getWebtelStatesMaster').then(res => {
      setStates(res)
    })
    call('POST', 'getWebtelUnitsMaster').then(res => {
      setUnits(res)
    })
  }, [])

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountrys(result.data.message);
      }
    });
  }, []);

  async function handleChange(e) {
    e.persist()
    if (e.target.name === 'supplyType') {
      setData({ ...data, [e.target.name]: e.target.value, subType: null })
      setErrors({ ...error, [e.target.name]: "" })
    }
    if (e.target.name.includes('selectedBuyer') && e.target.value) {
      let buyerDetails = dbData.filter(i => { return i.id / 1 == e.target.value / 1 })?.[0]
      let buyersApiDetails = JSON.parse(buyerDetails?.buyersAPIDetail || "{}")
      setData({
        ...data, [e.target.name]: e.target.value, receiverTradeName: buyerDetails?.buyerName,
        receiverName: buyerDetails?.buyerName, receiverAdd1: buyerDetails?.buyerAddress,
        receiverCity: buyersApiDetails?.address?.city, receiverPinCode: buyersApiDetails?.address?.postalCode,
        receiverState: buyerDetails?.buyerCountry != "IN" ? 99 : ""
      })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else if (e.target.name.includes('selectedShipper') && e.target.value) {
      let shipperDetails = ShipperList.filter(i => { return i.id / 1 == e.target.value / 1 })?.[0]
      let webtelState = states.filter(i => { return i.StateName.toLowerCase() == shipperDetails?.company_state?.toLowerCase() })[0]?.Code
      setData({
        ...data, [e.target.name]: e.target.value, shipperLegalName: shipperDetails?.name,
        shipperTradeName: shipperDetails?.name, shipperGstNo: shipperDetails?.gst_vat_no,
        shipperAddress1: shipperDetails?.user_address, shipperLocation: shipperDetails?.company_city,
        shipperPinCode: shipperDetails?.company_postal_code, shipperState: webtelState
      })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else if (e.target.name.includes('selectedBank') && e.target.value) {
      let bankDetails = banks.filter(i => { return i.id / 1 == e.target.value / 1 })?.[0]
      setData({
        ...data, [e.target.name]: e.target.value, payeeName: bankDetails?.beneficiaryAccountName,
        payeeBankAccNo: bankDetails?.beneficiaryAccountNo, payeeIFSCCode: bankDetails?.beneficiaryIfscCode,

      })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value })
      setErrors({ ...error, [e.target.name]: "" })
    }
  }

  // useEffect(() => {
  //   if (data?.commInvoice?.name) {
  //     saveCommInvoice()
  //   }
  // }, [data.commInvoice])

  // async function saveCommInvoice() {
  //   setshowLoader(true)
  //   let formData = new FormData()
  //   formData.append("userId", userId)
  //   formData.append("docType", "invoice")
  //   formData.append("docTemplate", "E-Invoice")
  //   formData.append("docName", `E-Invoice - ${data.invoiceNo}`)
  //   formData.append('docData', JSON.stringify({ data, invItems, invOther }))
  //   formData.append("doc", data.commInvoice)
  //   if (selectedInvoiceData?.id) {
  //     formData.append("updateDocId", selectedInvoiceData.id)
  //   }
  //   await call('POST', 'saveEdoc', formData)
  //   setshowLoader(false)
  //   toastDisplay("Document downloaded & saved", "success")
  // }

  async function handleValidation() {
    let err = {}
    let validateFiels = ["appDate", "supplyType", "subType", "documentType", "documentNo", "supplierName", "supplierState", "supplierPinCode",
      "receiverName", "receiverState", "receiverPinCode", "transporterId", "transportDistance", "vehicleType",
      "HSNCode", "taxableVal", "sgstRate", "sgstVal", "cgstRate", "cgstVal", "igstRate", "igstVal", "cessRate", "cessVal",
      "ewbUsername", "ewbPassword", "subSupplyDesc", "shipmentFromState", "shipmentToState",
      "isBillFromShipFromSame", "isBillToShipToSame", "isGSTINSEZ"]
    for (let index = 0; index < validateFiels.length; index++) {
      const element = validateFiels[index];
      if (!data[element]) {
        err[element] = "Mandatory field"
      }
    }
    console.log("errrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", err);
    if (!Object.keys(err).length) {
      setshowLoader(true)
      call("POST", 'generateDirectEwayBill', { data, userId }).then(res => {
        setshowLoader(false)
        toastDisplay("E-Way Bill Generated Successfully", "success", () => {
          window.location.reload()
        })
      }).catch(err => {
        setshowLoader(false)
        toastDisplay(err, "error")
      })
    }
    else {
      toastDisplay("Form validation error", "error")
    }
    setErrors({ ...err })
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredBuyers = []
    let filteredShippers = []
    let filteredBanks = []
    if (name.includes("buyerName")) {
      if (typedInput) {
        filteredBuyers = dbData.filter((i) => {
          if (i.buyerName.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBuyers = dbData
      }
      setDbDataFilter([...filteredBuyers])
    }
    if (name.includes("name")) {
      if (typedInput) {
        filteredShippers = ShipperList.filter((i) => {
          if (i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredShippers = ShipperList
      }
      setShipperListFilter([...filteredShippers])
    }
    if (name.includes("beneficiaryBankName")) {
      if (typedInput) {
        filteredBanks = banks.filter((i) => {
          if (i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBanks = banks
      }
      setBanksFilter([...filteredBanks])
    }
  }

  return (
    <>
      {signdoc && <SignDocument onlyReturnSign={true} setSigndoc={setSigndoc}
        setUpdatedDoc={(signDetails) => {
          setData({ ...data, invSign: signDetails })
        }} />
      }
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='card mt-1 pt-4' >
        <div className="p-4" >
          <label className="font-size-16 font-wt-600 w-100 text-center mb-3" >{selectedInvoiceData?.viewApiResp ? `E-Way Bill Details (${selectedInvoiceData.viewApiResp?.EWayBill}) (Valid Upto - ${selectedInvoiceData.viewApiResp?.ValidUpTo})`
            : `Fill Below Details to Generate E-Way Bill`}</label>
          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Supplier Details
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"GSTIN"}
                        isDisabled={preview.show} name={"supplierGSTIN"} value={data.supplierGSTIN}
                        error={error.supplierGSTIN} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput type={"date"} isAstrix label={'Application Date'} isDisabled={preview.show} name={"appDate"} error={error.appDate} value={data.appDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={[{ name: 'Inward', code: 'I' }, { name: 'Outward', code: 'O' }]} optionLabel={"name"} optionValue={"code"}
                        label={"Supply Type"} name={"supplyType"} value={data.supplyType} error={error.supplyType} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={data.supplyType === "I" ? [{ name: 'Supply', code: 1 }, { name: 'Export', code: 3 },
                        { name: 'Job Work', code: 4 }, { name: 'For Own Use', code: 5 }, { name: 'Others', code: 8 }, { name: 'SKD/CKD', code: 9 },
                        { name: 'Line Sales', code: 10 }, { name: 'Recipient Not Known', code: 11 }, { name: 'Exhibition or Fairs', code: 12 }] :
                          [{ name: 'Supply', code: 1 }, { name: 'Import', code: 2 }, { name: 'For Own Use', code: 5 }, { name: 'Job Work Returns', code: 6 },
                          { name: 'Sales Return', code: 7 }, { name: 'Others', code: 8 }, { name: 'SKD/CKD', code: 9 }, { name: 'Exhibition or Fairs', code: 12 }]} optionLabel={"name"} optionValue={"code"}
                        label={"Sub Type"} name={"subType"} value={data.subType} error={error.subType} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={[{ name: 'Tax Invoice', code: 'INV' }, { name: 'Bill of Supply', code: 'BIL' }, { name: 'Bill of Entry', code: 'BOE' },
                        { name: 'Challan', code: 'CHL' }, { name: 'Credit Note', code: 'CNT' }, { name: 'Others', code: 'OTH' }]} optionLabel={"name"} optionValue={"code"}
                        label={"Document Type"} name={"documentType"} value={data.documentType} error={error.documentType} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix label={'Document No'} isDisabled={preview.show} name={"documentNo"} value={data.documentNo} error={error.documentNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput isAstrix label={'Company Name'} isDisabled={preview.show} name={"supplierName"} value={data.supplierName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Trade Name'} isDisabled={preview.show} name={"supplierTradeName"} value={data.supplierTradeName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Address Line 1'} isDisabled={preview.show} name={"supplierAdd1"} value={data.supplierAdd1} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Address Line 2'} isDisabled={preview.show} name={"supplierAdd2"} value={data.supplierAdd2} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'City'} isDisabled={preview.show} name={"supplierCity"} value={data.supplierCity} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Supplier State"} name={"supplierState"} value={data.supplierState} error={error.supplierState} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Pin Code'} isAstrix isDisabled={preview.show} name={"supplierPinCode"} value={data.supplierPinCode}
                        error={error.supplierPinCode} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  aria-expanded="true"
                  aria-controls="collapse2"
                >
                  Receiver Details
                </button>
              </h2>
              <div
                id="collapse2"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <MultipleSelect singleSelection
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "buyerName")
                        }}
                        Label={"Select From Network"}
                        Id={`selectedBuyer`}
                        optiondata={dbDataFilter}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `selectedBuyer`, "id"))
                        }}
                        value={data[`selectedBuyer`] ? [parseInt(data[`selectedBuyer`])] : []}
                        name={`selectedBuyer`}
                        labelKey={"buyerNameHtml"}
                        valKey={"id"}
                        error={error[`selectedBuyer`]}
                      />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"GSTIN"}
                        isDisabled={preview.show} name={"receiverGSTIN"} value={data.receiverGSTIN}
                        error={error.receiverGSTIN} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Company Name"} isAstrix
                        isDisabled={preview.show} name={"receiverName"} value={data.receiverName}
                        error={error.receiverName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={'Trade Name'} isDisabled={preview.show} name={"receiverTradeName"} value={data.receiverTradeName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Address Line 1"}
                        isDisabled={preview.show} name={"receiverAdd1"} value={data.receiverAdd1}
                        error={error.receiverAdd1} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Address Line 2"}
                        isDisabled={preview.show} name={"receiverAdd2"} value={data.receiverAdd2}
                        error={error.receiverAdd2} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"City"}
                        isDisabled={preview.show} name={"receiverCity"} value={data.receiverCity}
                        error={error.receiverCity} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Receiver State"} name={"receiverState"} value={data.receiverState} error={error.receiverState} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Pin Code"} isAstrix
                        isDisabled={preview.show} name={"receiverPinCode"} value={data.receiverPinCode}
                        error={error.receiverPinCode} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  aria-expanded="true"
                  aria-controls="collapse3"
                >
                  Transport Details
                </button>
              </h2>
              <div
                id="collapse3"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewSelect selectData={[{ name: 'Road', id: 1 }, { name: 'Rail', id: 2 }, { name: 'Air', id: 3 }, { name: 'Ship', id: 4 }, { name: 'In Transit', id: 5 }]} optionLabel={"name"} optionValue={"id"} isDisabled={preview.show}
                        label={"Transportation Mode"} name={"transportationMode"} value={data.transportationMode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Transporter Id"} isAstrix
                        isDisabled={preview.show} name={"transporterId"} value={data.transporterId}
                        error={error.transporterId} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Transporter Name"}
                        isDisabled={preview.show} name={"transporterName"} value={data.transporterName}
                        error={error.transporterName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Transport Distance (KM)"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"transportDistance"} value={data.transportDistance}
                        error={error.transportDistance} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Transport Document No"}
                        isDisabled={preview.show} name={"transportDocumentNo"} value={data.transportDocumentNo}
                        error={error.transportDocumentNo} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Transport Document Date"} type={"date"}
                        isDisabled={preview.show} name={"transportDocumentDate"} value={data.transportDocumentDate}
                        error={error.transportDocumentDate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewSelect selectData={[{ name: 'ODC', id: 'O' }, { name: 'Regular', id: 'R' }]} isAstrix
                        optionLabel={"name"} optionValue={"id"} isDisabled={preview.show} label={"Vehicle Type"}
                        name={"vehicleType"} value={data.vehicleType} error={error.vehicleType} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Vehicle No"}
                        isDisabled={preview.show} name={"vehicleNo"} value={data.vehicleNo}
                        error={error.vehicleNo} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="accordionExample"  >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-size-15 font-wt-500"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse4"
                >
                  Invoice Details
                </button>
              </h2>
              <div
                id="collapse4"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div className="d-flex row">
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Product Name"}
                        isDisabled={preview.show} name={"productName"} value={data.productName}
                        error={error.productName} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Product Description"}
                        isDisabled={preview.show} name={"productDesc"} value={data.productDesc}
                        error={error.productDesc} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"HSNCode"} isAstrix
                        isDisabled={preview.show} name={"HSNCode"} value={data.HSNCode}
                        error={error.HSNCode} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Product Quantity"} type={"number"}
                        isDisabled={preview.show} name={"productQuantity"} value={data.productQuantity}
                        error={error.productQuantity} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewSelect isDisabled={preview.show} label={"Quantity Unit"}
                        selectData={units} optionLabel={"UnitName"} optionValue={"Code"}
                        name={`quantityUnit`} value={data[`quantityUnit`]} error={error[`quantityUnit`]} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Taxable Value"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"taxableVal"} value={data.taxableVal}
                        error={error.taxableVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"SGST Rate"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"sgstRate"} value={data.sgstRate}
                        error={error.sgstRate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"SGST Value"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"sgstVal"} value={data.sgstVal}
                        error={error.sgstVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"CGST Rate"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"cgstRate"} value={data.cgstRate}
                        error={error.cgstRate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"CGST Value"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"cgstVal"} value={data.cgstVal}
                        error={error.cgstVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"IGST Rate"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"igstRate"} value={data.igstRate}
                        error={error.igstRate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"IGST Value"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"igstVal"} value={data.igstVal}
                        error={error.igstVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"CESS Rate"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"cessRate"} value={data.cessRate}
                        error={error.cessRate} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"CESS Non advol"} type={"number"}
                        isDisabled={preview.show} name={"cessNonAdvol"} value={data.cessNonAdvol}
                        error={error.cessNonAdvol} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"CESS Value"} type={"number"} isAstrix
                        isDisabled={preview.show} name={"cessVal"} value={data.cessVal}
                        error={error.cessVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"EWB Username"} isAstrix
                        isDisabled={preview.show} name={"ewbUsername"} value={data.ewbUsername}
                        error={error.ewbUsername} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"EWB Password"} isAstrix type={"password"}
                        isDisabled={preview.show} name={"ewbPassword"} value={data.ewbPassword}
                        error={error.ewbPassword} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2 mt-2" >
                      <NewInput label={"Sub Supply Description"} isAstrix
                        isDisabled={preview.show} name={"subSupplyDesc"} value={data.subSupplyDesc}
                        error={error.subSupplyDesc} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Shipment from State"} name={"shipmentFromState"} value={data.shipmentFromState} error={error.shipmentFromState} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={states} optionLabel={"StateName"} optionValue={"Code"}
                        label={"Shipment to State"} name={"shipmentToState"} value={data.shipmentToState} error={error.shipmentToState} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput label={"Total Invoice Value"} type={"number"}
                        isDisabled={preview.show} name={"totalInvoiceVal"} value={data.totalInvoiceVal}
                        error={error.totalInvoiceVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput label={"Other Charges Value"} type={"number"}
                        isDisabled={preview.show} name={"otherChargesVal"} value={data.otherChargesVal}
                        error={error.otherChargesVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput label={"CESS Non Advol Value"} type={"number"}
                        isDisabled={preview.show} name={"cessNonAdvolVal"} value={data.cessNonAdvolVal}
                        error={error.cessNonAdvolVal} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={[{ name: 'Yes', code: 1 }, { name: 'No', code: 0 }]} optionLabel={"name"} optionValue={"code"}
                        label={"Is Bill From Ship From Same"} name={"isBillFromShipFromSame"} value={data.isBillFromShipFromSame} error={error.isBillFromShipFromSame} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={[{ name: 'Yes', code: 1 }, { name: 'No', code: 0 }]} optionLabel={"name"} optionValue={"code"}
                        label={"Is Bill To Ship To Same"} name={"isBillToShipToSame"} value={data.isBillToShipToSame} error={error.isBillToShipToSame} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewSelect isDisabled={preview.show} isAstrix
                        selectData={[{ name: 'Yes', code: 1 }, { name: 'No', code: 0 }]} optionLabel={"name"} optionValue={"code"}
                        label={`Is ${data.supplyType === "O" ? 'Receiver' : 'Supplier'} GSTIN SEZ`} name={"isGSTINSEZ"} value={data.isGSTINSEZ} error={error.isGSTINSEZ} onChange={handleChange} />
                    </div>
                    <div className="position-relative col-3 mb-2" >
                      <NewInput label={"IRN No"}
                        isDisabled={preview.show} name={"irnNo"} value={data.irnNo}
                        error={error.irnNo} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {selectedInvoiceData?.viewApiResp ? null :
          <div className="d-flex row m-0 p-0 mb-3 justify-content-center " >
            <div className={`${!preview.show ? ' w-20 ' : ' w-30 '} d-flex row m-0 p-0 mb-2 justify-content-center`} >
              <button type="button"
                onClick={() => {
                  handleValidation()
                  // setPreview({ ...preview, show: !preview.show })
                }}
                className={` new-btn ${!preview.show ? ' w-100 ' : ' w-45 '} py-2 px-2 text-white`}>
                {!preview.show ? "Submit" : "Back to Editing"}
              </button>
              {preview.show ?
                <button type="button"
                  onClick={() => {
                    printDiv("invoiceDiv", `E-Invoice - ${data.invoiceNo}`, [], data, setData, "commInvoice")
                  }}
                  className={` new-btn w-45 py-2 px-2  text-white`}>
                  {"Save & Download"}
                </button> : null}
            </div>
          </div>}
      </div>
    </>
  )

}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(DirectEwayBill)