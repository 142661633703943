import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default class Shipmentschart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height={150}>
        <AreaChart
          width={500}
          height={400}
          data={this.props.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="year" type="category" />
          <YAxis />
          <Tooltip />
          <defs>
            <linearGradient id="fillcolor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={"50%"} stopColor="#D0E9FF" stopOpacity={0.58} />
              <stop offset={"100%"} stopColor="#D0E9FF" stopOpacity={0.17} />
            </linearGradient>
          </defs>
          <Area type="linear" dataKey="shipments" stroke="#D0E9FF" fill="url(#fillcolor)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}






