

import React, { useEffect, useState } from 'react';
import call from '../../service';
import { formatDateTime_Application } from '../../utils/dateFormaters';
import moment from 'moment';


const BlockchainTrail = ({ userTokenDetails, contractNo }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [trailData, setTrailData] = useState([])
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'bctrail', { "contract_number": contractNo }).then(async (result) => {
      // console.log("api result in bctrail ->", result)
      setTrailData(result)
    }).catch((error) => {
      console.log("error occur in bctrail ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="d-flex flex-column mt-2">
      {/* <div className="d-flex">
        <div className="">
          <h2>Blockchain Audit Trail</h2>
        </div>
      </div> */}
      {/* <div className="table-responsive"> */}
      <div className="bk_details">
        <ul>
          {(trailData && trailData.length > 0) ? trailData.map((data, j) => {
            return (
              <li className="col-md-12 bg-white">
                <div className="row">
                  <div className="col-md-8 p-0"><label className='font-size-13 font-wt-600' > TXN_ID</label> <span className="swatch-green font-size-12 font-wt-500">{data.value?.txID || "--"}</span> </div>
                  <div className="col-md-4 p-0"><label className='font-size-13 font-wt-600'>Reference_ID</label> <span className='font-size-12 font-wt-500' >{data.value?.blockChainReferenceId || "--"}</span> </div>
                </div>
                <div className="row design-row m-0 p-0 mt-2">
                  <div className="col-md-8 p-0"><label className='font-size-13 font-wt-600'>Timestamp</label> <span className='font-size-12 font-wt-500'>{moment.utc(data.timestamp?.seconds * 1000).format("YYYY-MM-DD hh:mm a")}</span> </div>
                  {/* <div className="col-md-6 p-0"><label>Type</label> <span>{data.metadata ? data.metadata : "--"}</span> </div> */}
                  <div className="col-md-4 p-0"><label className='font-size-13 font-wt-600'>Type</label> <span className='font-size-12 font-wt-500'>{j === trailData.length - 1 ? "Contract Created" : "Contract Updated"}</span> </div>
                  {/* <div className="col-md-6 p-0"><label>Actions</label> <span>{data.action ? data.action : "--"}</span> </div> */}
                </div>
              </li>
            )
          }) : <label className='font-size-16 font-wt-500' >{"No Data available"}</label>
          }
        </ul>
      </div>
      {/* </div> */}
    </div>
  </>)
}


export default BlockchainTrail
