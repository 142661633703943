import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import Filter from "./components/Filter";
import moment from 'moment';
import Pagination from "./contract/components/pagination";
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import NewTablev2 from '../../utils/newTablev2';
import ColumnSelector from '../lcV2/qoutes/columnselector';



const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "18rem",
      "right": "6rem"
    },
    arrow: {
      "top": "18rem",
      "zIndex": 10,
      transform: "rotate(179deg)",
      left: "14.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>To apply for Invoice Discounting limit or finance click on the respective tab at navbar under Invoice Discounting</span></label>
  },
  "1": {
    popup: {
      top: '-1rem',
      right: '-19rem'
    },
    arrow: {
      "top": "3rem",
      "zIndex": 10,
      transform: "rotate(255deg)",
      left: '45.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Application of Invoice Discounting finance limit will be show in "Quotes" under Invoice Discounting tab</span></label>
  }
}

const addBuyerTabs = [
  { name: "Select Buyer" },
  { name: "DUNS No." },
  { name: "Buyer details" },
  { name: "Documents" },
  // { name: "Select Financier" },
  { name: "Review" }
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },

  { "name": "Country", val: "buyerCountry" },
  { "name": "Postal Code", val: "buyerPostalCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },
]

// const financierData = [
//   { id: 100, name: "Stenn", icon: "stenn.png", rating: 4.6, reviews: 126 },
//   { id: 200, name: "Modifi", icon: "modifi.png", rating: 1, reviews: 130 },
// ]

const IncoTerms = [
  { name: "EXW" },
  { name: "FCA" },
  { name: "FAS" },
  { name: "FOB" },
  { name: "CFR" },
  { name: "CIF" },
  { name: "CPT" },
  { name: "CIP" },
  { name: "DAP" },
  { name: "DPU" },
  { name: "DDP" }
]

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

export const Quotes = ({ userTokenDetails, navToggleState }) => {

  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("QuotesFilterData"))

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [draftCount, setDraftCount] = useState(0)
  const [onlyShowDrafts, toggleOnlyShowDrafts] = useState(false)
  const [applicationCounts, setApplicationCounts] = useState({})
  const [filteredSearch, setFilteredSearch] = useState([])
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const invColumns = [
    {
      name: "Date",
      filter: true,
      filterDataKey: "Date",
      sort: [
        {
          name: "Latest First",
          selected: filter.sortDateBy === "DESC",
          onActionClick: () => {
            setFilter({ ...filter, sortDateBy: "DESC", sortBuyerName: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Oldest First",
          selected: filter.sortDateBy === "ASC",
          onActionClick: () => {
            setFilter({ ...filter, sortDateBy: "ASC", sortBuyerName: false });
            setRefresh(refresh + 1);
          },
        },
      ],
    },
    {
      name: "Buyer Name",
      filter: true,
      filterDataKey: "Buyer Name",
      sort: [
        {
          name: "Sort A-Z",
          selected: filter.sortBuyerName === "ASC",
          onActionClick: () => {
            setFilter({ ...filter, sortBuyerName: "ASC", sortDateBy: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Sort Z-A",
          selected: filter.sortBuyerName === "DESC",
          onActionClick: () => {
            setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false });
            setRefresh(refresh + 1);
          },
        },
      ],
    },
    {
      name: "Country",
      filter: true,
      filterDataKey: "Country",
      sort: [
        {
          name: "Sort A-Z",
          selected: filter.sortCountryName === "ASC",
          onActionClick: () => {
            setFilter({ ...filter, sortCountryName: "ASC", sortDateBy: false, sortBuyerName: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Sort Z-A",
          selected: filter.sortCountryName === "DESC",
          onActionClick: () => {
            setFilter({ ...filter, sortCountryName: "DESC", sortDateBy: false, sortBuyerName: false });
            setRefresh(refresh + 1);
          },
        },
      ],
    },
    {
      name: "Terms of Payments", filter: true,
      filterDataKey: "Terms of Payments",
      sort: [
        {
          name: "Sort A-Z",
          selected: filter.sortTermsOfPayment === "ASC",
          onActionClick: () => {
            setFilter({ ...filter, sortTermsOfPayment: "ASC", sortDateBy: false, sortBuyerName: false, sortCountryName: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Sort Z-A",
          selected: filter.sortTermsOfPayment === "DESC",
          onActionClick: () => {
            setFilter({ ...filter, sortTermsOfPayment: "DESC", sortDateBy: false, sortBuyerName: false, sortCountryName: false });
            setRefresh(refresh + 1);
          },
        },
      ],
    },
    {
      name: "Financers Selected",
      filter: true,
      filterDataKey: "Financers Selected",
      sort: [
        {
          name: "Sort A-Z",
          selected: filter.sortFinancerName === "ASC",
          onActionClick: () => {
            setFilter({ ...filter, sortFinancerName: "ASC", sortDateBy: false, sortBuyerName: false, sortCountryName: false, sortTermsOfPayment: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Sort Z-A",
          selected: filter.sortFinancerName === "DESC",
          onActionClick: () => {
            setFilter({ ...filter, sortFinancerName: "DESC", sortDateBy: false, sortBuyerName: false, sortCountryName: false, sortTermsOfPayment: false });
            setRefresh(refresh + 1);
          },
        },
      ]
    },
    {
      name: "Status",
      filter: true,
      filterDataKey: "Status",
      sort: [
        {
          name: "Approved",
          selected: filter.sortbyStatus === "Approved",
          onActionClick: () => {

            setFilter({ ...filter, sortbyStatus: "Approved", sortDateBy: false, sortBuyerName: false, sortCountryName: false, sortTermsOfPayment: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "In-Progress",
          selected: filter.sortbyStatus === "In-Progress",
          onActionClick: () => {
            setFilter({ ...filter, sortbyStatus: "In-Progress", sortDateBy: false, sortBuyerName: false, sortCountryName: false, sortTermsOfPayment: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Rejected",
          selected: filter.sortbyStatus === "Rejected",
          onActionClick: () => {
            setFilter({ ...filter, sortbyStatus: "Rejected", sortDateBy: false, sortBuyerName: false, sortCountryName: false, sortTermsOfPayment: false });
            setRefresh(refresh + 1);
          },
        },
        {
          name: "Expired",
          selected: filter.sortbyStatus === "Expired",
          onActionClick: () => {
            setFilter({ ...filter, sortbyStatus: "Expired", sortDateBy: false, sortBuyerName: false, sortCountryName: false, sortTermsOfPayment: false });
            setRefresh(refresh + 1);
          },
        },
      ],
    },
    { name: "Action", filter: false },
  ];



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("Quotes");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  useEffect(() => {
    loadApplicationCounts()
  }, [])

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getIDQuoteCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  useEffect(() => {
    //Get Buyer list start
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      onlyAppliedForInvoiceQuote: true,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getBuyersDetail', objectAPI).then(async (result) => {
      console.log('running getBuyersDetail api-->', result);
      setDbData(result.buyerData);
      let table1 = formatDataForTable(result.buyerData)
      if (onlyShowDrafts) {
        table1 = []
      }
      let table2 = await call('POST', 'getAllInvoiceLimitDrafts', { userId })
      table2 = formatDataForTable2(table2)
      if (table2.length) {
        setDraftCount(table2.length)
        setTableData(table1.concat(table2))
      }
      else {
        setTableData(table1)
      }
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
    SetCache("QuotesFilterData", filterData)
  }, [refresh, page, filterData, onlyShowDrafts])


  useEffect(() => {
    if (!Object.keys(filterData)?.length) {
      call('POST', 'getApplyForQuoteFilter', { userId }).then(res => {
        console.log("getApplyForQuoteFilter then", res);
        res["Grid Columns"] = { accordianId: 'GridColumns', data: [], labelName: 'name', type: 'checkbox', isAllChecked: true }
        for (let index = 0; index < invColumns.length; index++) {
          const element = invColumns[index];
          res["Grid Columns"].data.push({ name: element.name, isChecked: true })
        }
        setFilterData(res)
        setFilteredSearch(prevFilterData => ({
          ...prevFilterData,
          ...res
        }))
      }).catch(err => { })
    }
  }, [])


  console.log("tableDatatableData", tableData);

  function formatDataForTable2(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      row[0] = moment(item.createdAt).format('DD/MM/YYYY')
      row[1] = item.details?.data?.["buyerName0"] || "-"
      row[2] = item.details?.data?.["buyerCountry0"] || "-"
      row[3] = item.details?.data?.["termsOfPayment0"] || "-"
      row[4] = "-"
      row[5] = "-"
      // row[6] = "-"
      row[6] = <div className='row ' >
        <a className='mt-2'>
          <button type="button"
            onClick={() => {
              window.location = `/applyLimitV2?formContinue=${item.id}`;
            }}
            className={` border-0 mb-2 text-white enableQuotebtn `}>
            {"Continue"}
          </button>
          <p className="font-size-12 text-color-value w-80">
            <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">This application is saved as draft</span>
          </p>
        </a>
      </div >
      tableData.push(row)
      row = []
    }
    return tableData
  }


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      let allDeniedCount = 0
      let apporvedCount = 0

      if (item.buyers_credit) {
        for (let index = 0; index < JSON.parse(item.buyers_credit).length; index++) {
          const j = JSON.parse(item.buyers_credit)[index];
          if (j.financierAction === "deny") {
            allDeniedCount += 1
          }
          else {
            apporvedCount += 1
          }
        }
      }

      // let allDenied = true
      // if (item.buyers_credit) {
      //   for (let index = 0; index < JSON.parse(item.buyers_credit).length; index++) {
      //     const j = JSON.parse(item.buyers_credit)[index];
      //     if (j.financierAction === "Approved") {
      //       allDenied = false
      //     }
      //   }
      // }
      row[0] = moment(item.applicationUpdatedAt).format('DD/MM/YYYY')
      row[1] = item.buyerName
      row[2] = item.countryName || "-"
      row[3] = item.termsOfPayment

      let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
      let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
      let buyersCredit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

      row[4] = <div
        className=''
      >
        {selectedLenderName.length ? selectedLenderName.map((key, j) => {
          let isApprovedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "Approved") {
              return i
            }
          })?.[0]
          let isRejectedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.financierAction === "deny") {
              return i
            }
          })?.[0]
          let openChatRoomIndx = null
          chatRoomUsers.forEach((u, i) => {
            if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
              openChatRoomIndx = i
            }
          })
          return (
            <div className='position-relative' >
              {/* <label className={`font-wt-600 font-size-22 position-absolute ${isApprovedByFinancier ? " text2ECC71 " : ''} ${isRejectedByFinancier ? ' text-danger ' : '   '} `} style={{ top: "-0.8rem" }} >{`.`}</label> */}
              <img className='cursor'
                onClick={async () => {
                  let reqObj = {
                    senderId: userId,
                    receiverId: selectedLenderId[j],
                    textMessage: 'Hii',
                    chat_room_name: "CHAT" + new Date().getTime(),
                    includeAdmins: true,
                    invApplicationId: item.applicationId,
                    receiverParties: selectedLenderId[j],
                    dontSendInitialMsg: true
                  }
                  setshowLoader(true)
                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                  setshowLoader(false)
                  getChatDetails({
                    chat_room_id: apiResp.id,
                    loggedInUser: userId
                  })
                  setSelectedChat({
                    chatRoomId: apiResp.id,
                    receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                    invApplicationId: item.applicationId,
                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                    userId: userId,
                    isChatOpen: true,
                    receiverId: selectedLenderId[j]
                  })
                }}
                src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
              <label className={`ml-3 font-size-13 text-color-label font-wt-400 ${selectedLenderId[j] / 1 == item.selectedFinancier / 1 ? " text5CB8D3 " : isApprovedByFinancier ? " text2ECC71 " : " "}  ${isRejectedByFinancier ? ' text-danger ' : '   '} `} >{`${key} `}
                <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
            </div>
          )
        }) : "NA"}
      </div>

      row[5] = <div className=''>
        {apporvedCount ?
          <button type="button"
            class={`approved text-white border-0 `}>
            {"Approved"}
          </button> :
          (allDeniedCount / 1 == selectedLenderName?.length / 1) ?
            <button type="button"
              class={`rejected text-white border-0 `}>
              {"Rejected"}
            </button>
            :
            moment().diff(moment(item.applicationCreatedAt), "days") >= 60 && !item.buyers_credit ?
              <button type="button"
                class={`expiredStatus text-white border-0 `}>
                {"Expired"}
              </button>
              :
              <button type="button"
                class={`inprogress text-white border-0 `}>
                {"Inprogress"}
              </button>}
      </div>

      let threeDaysFromCreationDate = moment(item.applicationCreatedAt).add(4, "days")
      let daysLeft = threeDaysFromCreationDate.clone().diff(moment(), "days")

      // row[5] = (daysLeft / 1 > 0) ? <p
      //   class={`${daysLeft == 3 ? 'text-success' : daysLeft == 2 ? 'text-F1C40F' : 'text-danger'} mb-0 font-size-13`}>
      //   {daysLeft + `${daysLeft == 1 ? ' day' : ' days'}`} <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      // </p> : "-";

      row[6] =
        <div className='row ' >
          <a className='mt-2'>
            <button type="button"
              onClick={async () => {
                setshowLoader(true)
                let syndicationChargesAccepted
                try {
                  syndicationChargesAccepted = await call('POST', 'syndicationTNCStatus', { userId })
                } catch (error) {
                  syndicationChargesAccepted = false
                }
                if (syndicationChargesAccepted) {
                  window.location = `/seequotes?buyer=${item.id}`;
                  localStorage.setItem("applicationId", item.applicationId)
                  localStorage.setItem("invIfAppliedNo", item.invRefNo)
                }
                else {
                  toastDisplay("You have to accept commercial terms in order to proceed", "info", async () => {
                    await call('POST', 'updateSyndicationTNCStatus', { resetStatus: true, userId })
                    window.location.reload()
                  })
                }
              }}
              disabled={!item.buyers_credit}
              className={` border-0 mb-2 ${!item.buyers_credit ? "disabledQuotebtn text-dark" : "text-white enableQuotebtn"}`}>
              {"See Quotes"}
            </button>
            {item.selectedFinancier && !item.termSheet && !item.termSheetLink ?
              <p className="font-size-12 text-color-value ">
                <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Quote selected, waiting for Termsheet.</span>
              </p>
              : allDeniedCount / 1 == selectedLenderName?.length / 1 ?
                <p className="font-size-12 text-color-value ">
                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 text-danger">Application rejected by all financiers.</span>
                </p>
                : apporvedCount && !item.selectedFinancier ?
                  <p className="font-size-12 text-color-value ">
                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Quote received.</span>
                  </p>
                  : null}
          </a>
        </div >

      // let isBuyerRemarkVisible = false

      // for (let index = 0; index < Object.values(item.buyersRemark || {}).length; index++) {
      //   const element = Object.values(item.buyersRemark)[index];
      //   if (element.isVisible) {
      //     isBuyerRemarkVisible = true
      //   }
      // }

      // if (isBuyerRemarkVisible) {
      //   row[99] = <p className="font-size-12 text-color-value ">
      //     <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>Remarks received from financier</b> </span>
      //     <label
      //       onClick={() => {
      //         window.location = `/seequotes?buyer=${item.id}`;
      //         localStorage.setItem("applicationId", item.applicationId)
      //         localStorage.setItem("invIfAppliedNo", item.invRefNo)
      //       }}
      //       className="text-color1 text-decoration-underline cursor">View request</label>
      //   </p>
      // }

      tableData.push(row)
      row = []

    }
    return tableData
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
          // return getDunsList()
        }
        setTab(tab + 1)
      }
      else {
        // submitBuyerDetails()
      }
    }
    setErrors(err)
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }

  function openApplication(indx) {
    let item = dbData[indx]
    window.location = `/seequotes?buyer=${item.id}&onlyView=true`;
    localStorage.setItem("applicationId", item.applicationId)
    localStorage.setItem("invIfAppliedNo", item.invRefNo)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  console.log("filterDataaaaaaaaaaaaaaaaaaaaaaaaaaaa", filterData);

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <TutorialPopup show={lcTutorial} featureName={"Invoice Discounting"} positioning={{
        ...tutorialPopupCSS[tutorialStage]["popup"]
      }} showSkip={true} userId={userId} showNext={true}
        onNext={() => {
          if (tutorialStage == 1) {
            localStorage.setItem('lcTutorialStep', 3)
            window.location.reload()
          }
          else {
            setTutorialStage(tutorialStage + 1)
          }
        }}
        onBack={() => {
          setTutorialStage(tutorialStage - 1)
        }}
        showBack={tutorialStage != 0} videoLinkLabel={"Watch detailed video about ID"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_Quotes" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discounting > Quotes"}
              userTokenDetails={userTokenDetails} />
            {/* <div>
              <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            </div> */}
            <div className='mt-4' style={lcTutorial && tutorialStage == 0 ? { "zIndex": '-1', position: 'relative' } : {}} >

              <div className='d-flex row justify-content-between px-2' >
                <div className='card p-4  ' style={{ width: '43.22%' }} >
                  <label className='font-size-16 font-wt-500 color0C0C0C' >Application in Process - <label className='text-color1 font-size-16 font-wt-600' >{applicationCounts.total}</label></label>
                  <div className='d-flex row' >
                    <div className='w-auto' >
                      <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.inprogress || 0}</p>
                      <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Waiting for Quote</p>
                    </div>
                    <div className='w-auto' >
                      <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.quotes || 0}</p>
                      <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Quotes</p>
                    </div>
                    <div className='w-auto' >
                      <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.waitingForTermsheet || 0}</p>
                      <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Waiting for Termsheet</p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    let tempFilterData = filterData
                    let doesFilterActivated = false
                    for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
                      let element = tempFilterData["Status"]["data"][index];
                      if (element.name === "Approved") {
                        element["isChecked"] = !element["isChecked"]
                        if (element["isChecked"]) {
                          doesFilterActivated = true
                        }
                      }
                      else {
                        element["isChecked"] = false
                      }
                    }
                    tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
                    setFilterData({ ...filterData })
                  }}
                  className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                  <div>
                    <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{applicationCounts.approved || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Approved</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    let tempFilterData = filterData
                    let doesFilterActivated = false
                    for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
                      let element = tempFilterData["Status"]["data"][index];
                      if (element.name === "Rejected") {
                        element["isChecked"] = !element["isChecked"]
                        if (element["isChecked"]) {
                          doesFilterActivated = true
                        }
                      }
                      else {
                        element["isChecked"] = false
                      }
                    }
                    tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
                    setFilterData({ ...filterData })
                  }}
                  className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                  <div>
                    <p className='font-size-22 font-wt-600 text-color-E74C3C p-0 m-0' >{applicationCounts.rejected || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    let tempFilterData = filterData
                    let doesFilterActivated = false
                    for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
                      let element = tempFilterData["Status"]["data"][index];
                      if (element.name === "Expired") {
                        element["isChecked"] = !element["isChecked"]
                        if (element["isChecked"]) {
                          doesFilterActivated = true
                        }
                      }
                      else {
                        element["isChecked"] = false
                      }
                    }
                    tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
                    setFilterData({ ...filterData })
                  }}
                  className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                  <div>
                    <p className='font-size-22 font-wt-600 text-secondary p-0 m-0' >{applicationCounts.expired || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Expired</p>
                  </div>
                </div>
                <div
                  onClick={() => toggleOnlyShowDrafts(!onlyShowDrafts)}
                  className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
                  <div>
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{draftCount || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Draft</p>
                  </div>
                </div>
              </div>

              <div className='filter-div position-relative'>
                <Filter
                  // showDraftCount={draftCount} 
                  // onShowDraftClick={() => toggleOnlyShowDrafts(!onlyShowDrafts)}
                  // draftModeActive={onlyShowDrafts}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />

                {Object.values(filterData || {})?.filter(i => {
                  if (i.isFilterActive) { return true }
                })?.length ? null :
                  <div style={{ left: '21rem' }} className='d-flex position-absolute'>
                    {finHighlights.map((i, j) => {
                      return (<div className='d-flex align-items-center mx-2' >
                        <div style={{ background: i.color, height: '10px', width: '10px', borderRadius: 1000 }} />
                        <label className='font-size-14 font-wt-500 mt-2 mx-2' >{i.name}</label>
                      </div>)
                    })}
                  </div>}
                <ColumnSelector filterData={filterData} setFilterData={setFilterData} />
              </div>

              <div
                style={tutorialStage == 1 ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem 0rem 1rem 0rem",
                  "borderRadius": "1rem",
                } : {}}>
                <NewTable filterData={filterData} disableAction={false}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  columns={invColumns}
                  data={tableData}
                  options={[
                    { name: "Application", icon: "edit.png", onClick: openApplication },
                    // { name: "Delete", icon: "delete.png", onClick: onDelete },
                    // { name: "View Details", icon: "eye.png", onClick: onView }
                  ]}
                />
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Quotes)