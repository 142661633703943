import React, { useEffect, useState } from "react";
import TableFilter from "../../wallet/components/tableFilter";
import moment from "moment";
import { printDiv } from "../../../utils/myFunctions";
import call from "../../../service";
import { ExportExcel } from "../../../utils/myFunctions";
import Pagination from "../../InvoiceDiscounting/contract/components/pagination";
import NewTablev2 from "../../../utils/newTablev2";
import GraphCards from "./graphCards";
import ShipmentGraphs from "./shipmentGraphs";
import ShipmentMap from "./shipmentMap";
import { tableShipmentData, Shipmentcolumns, actionsdata } from "../TableJson"
import Action from "../../lcV2/qoutes/actions";
import Filter from "../../InvoiceDiscounting/components/Filter";
import { NewTable } from "../../../utils/newTable";

const ShipmentTab = ({ userTokenDetails, navToggleState }) => {

  const [dbData, setDbData] = useState([])

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [data, setData] = useState({ pageCount: 10 })
  const [shipmentsport, setshipmentports] = useState([])

  const [shipmentscountry, setshipmentscountrydata] = useState([])
  const [shipmentcountrygraph, setshipmentcountrygraph] = useState([])

  const [shipmentsoriginports, setshipmentsoriginports] = useState([])
  const [shipmentsproducts, setshipmentsproducts] = useState([])
  const [shipmentsLane, setshipmentsLane] = useState([])

  const [showLoader, setshowLoader] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})


  const [action, setAction] = useState({ show: false, id: null });
  const [filteredSearch, setFilteredSearch] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : userTokenDetails?.company_name ? userTokenDetails.company_name : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const ttvExporterCode = userTokenDetails.ttvExporterCode ? userTokenDetails.ttvExporterCode : ''

  let EXP_NAME = userName
  if (userName.includes("Private Limited")) {
    EXP_NAME = userName.replace(/Private Limited/g, "PVT LTD");
  } else if (userName.includes("Limited")) {
    EXP_NAME = userName.replace(/Limited/g, "LTD");
  }
  let EXPORTER_NAME = EXP_NAME.replace(/LTD\d*/g, "LTD");
  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Name": data.createdByName,
        "Service Name": data.serviceName,
        "Transaction ID": data.transactionId,
        "Type of Transaction": data.type,
        "Charges": data.charges,
        "Mode Of Payment": data.modeOfPayment,
        "Date And Time": moment(data.createdAt).format('DD-MM-YYYY hh:ss:mm A'),
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }

  const getShipmentCountrydata = () => {
    setshowLoader(true)
    let reqObj = {
      userId,
      EXPORTER_NAME
    }
    call('POST', 'getshipmentschartV2', reqObj).then(result => {
      setshipmentscountrydata(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  const getShipmentTable = () => {
    console.log('filterdata', filter)
    setshowLoader(true)
    let reqObj = {
      userId,
      currentPage: page,
      resultPerPage: filter.resultPerPage,
      ttvExporterCode,
      EXPORTER_NAME: EXPORTER_NAME,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          reqObj[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              reqObj[element.accordianId].push(`${i[element["labelName"]]}`)
            }
          })
        }
        else if (element.type === "minMax") {
          reqObj[element.accordianId] = element["value"]
        } else if (element.type === "date") {
          reqObj[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getShipmentsTableV2', reqObj).then(result => {
      setDbData(formatDataForTable(result.data))
      setCount(result.count_data)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  const getshipmentsCountry = () => {
    setshowLoader(true)
    call('POST', 'getshipmentsCountryV2', { userId, EXPORTER_NAME }).then(result => {
      setshipmentcountrygraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  const gettopportsshipments = () => {
    setshowLoader(true)
    call('POST', 'gettopportsshipmentsV2', { userId, EXPORTER_NAME }).then(result => {
      setshipmentports(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  const gettoporiginports = () => {
    setshowLoader(true)
    call('POST', 'gettoporiginportsV2', { userId, EXPORTER_NAME }).then(result => {
      setshipmentsoriginports(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  const getProductsCount = () => {
    setshowLoader(true)
    call('POST', 'getProductsCountV2', { userId, EXPORTER_NAME }).then(result => {
      setshipmentsproducts(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }

  const getSourceDestinationCount = () => {
    setshowLoader(true)
    call('POST', 'getSourceDestinationCountV2', { userId, EXPORTER_NAME }).then(result => {
      setshipmentsLane(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }


  useEffect(() => {
    getShipmentCountrydata()
    gettopportsshipments()
    gettoporiginports()
    getProductsCount()
    getSourceDestinationCount()
    getshipmentsCountry()
    getShipmentsTableFilters()
  }, [])
  const getShipmentsTableFilters = () => {
    call('POST', 'getShipmentsTableFilters', { ttvExporterCode }).then(result => {
      setFilterData(result)
    }).catch(e => {
      console.log('error in getShipmentsTableFilters', e)
    })
  }
  useEffect(() => {
    getShipmentTable()
  }, [refresh, page, filterData])

  const handleDropDown = (index) => {
    if (!action.show)
      setAction({ show: true, id: index });
    else
      setAction({ show: false, id: index })
  }

  const onClickApplication = () => {

  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((key, index) => {
      row[0] = moment(key.DATE).format('DD-MM-YYYY')
      row[1] = key.CONSIGNEE_NAME && key.CONSIGNEE_NAME.length > 60 ? key.CONSIGNEE_NAME.slice(0, 60) + '...' : key.CONSIGNEE_NAME
      row[2] = key.TOTAL_GROSS_WEIGHT + " " + key.GROSS_WEIGHT_UNIT
      row[3] = key.INDIAN_PORT
      row[4] = key.DESTINATION_PORT
      row[5] = key.PRODUCT_TYPE && key.PRODUCT_TYPE.length > 60 ? key.PRODUCT_TYPE.slice(0, 60) + '...' : key.PRODUCT_TYPE

      tableData.push(row)
      row = []
    })
    return tableData
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ShipmentGraphs ttvExporterCode={ttvExporterCode} userId={userId} shipmentCountrydata={shipmentscountry} setshowLoader={setshowLoader} EXPORTER_NAME={EXPORTER_NAME} />
      <div className="filter-div ml-0 mt-1">
        <Filter
          filteredSearch={filteredSearch}
          setFilteredSearch={setFilteredSearch}
          filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
          showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} onExcelExport={() => exportToexcel(dbData, "Excel_WalletTransactions")} onPDFExport={() => printDiv("transactionTableDiv", "Excel_WalletTransactions", [])} />
      </div>

      <div className="mb-3">
        <NewTable
          disableAction={true}
          //columns={Shipmentcolumns} 
          filterData={filterData}
          setFilterData={setFilterData}
          filteredSearch={filteredSearch}
          setFilteredSearch={setFilteredSearch}
          tableFixed data={dbData}
          columns={[{
            name: "Date", filter: true, filterDataKey: "Date", sort: [
              { name: "Latest First", selected: filter.sortdate === 1, onActionClick: () => { setFilter({ ...filter, sortdate: 1, sortBuyerName: false }); setRefresh(refresh + 1) } },
              { name: "Earliest First", selected: filter.sortdate === -1, onActionClick: () => { setFilter({ ...filter, sortdate: -1, sortBuyerName: false }); setRefresh(refresh + 1) } }]
          }, {
            name: "Buyer name", filter: true, filterDataKey: "Buyer name",
            sort: [
              { name: "A to Z", selected: filter.sortBuyerName === 1, onActionClick: () => { setFilter({ ...filter, sortBuyerName: 1, sortdate: false }); setRefresh(refresh + 1) } },
              { name: "Z to A", selected: filter.sortBuyerName === -1, onActionClick: () => { setFilter({ ...filter, sortBuyerName: -1, sortdate: false }); setRefresh(refresh + 1) } }]
          },
          { name: "Weight", filter: false }, { name: "From", filter: false }, { name: "To", filter: false },
          { name: "Product", filter: false }
          ]}

        />

      </div>
      <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
      <GraphCards shipmentsport={shipmentsport} shipmentsoriginports={shipmentsoriginports} shipmentsproducts={shipmentsproducts} shipmentsLane={shipmentsLane} />
      <ShipmentMap data={shipmentcountrygraph} />
      {/* <div className="mt-5">
        <p className="font-size-16 font-wt-500 color0C0C0C letter-spacing05 lh-22">Top 10 buyers & their other top 3 suppliers</p>
      </div> */}

    </>
  );
}
export default ShipmentTab;