import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { validate_text_field } from '../../utils/myFunctions';
import { NewInput, NewSelect } from '../../utils/newInput';
//import { getUserDataFromCookie } from '../../utils/cookieHelper';

// editLevel (true= editable, false=viewonly) ; userLevel (1->admin , 2->User)
const SpecificationsForm = ({ hideModalPadding, commodity, spec, handleSpecification, setSpecification, onSetSpecification, contMode, editCount, setEditCount, editLevel, masterid, specOrder, setspecOrder, onSetSpecOrder, commName }) => {

  let [dbSpecData, setdbSpecData] = useState({});
  const [newSpec, setnewSpec] = useState({ newSpecificationName: "", newSpecificationValue: "", newSpecificationrejectionValue: '' });
  // const [userEmail, setuserEmail] = useState('');
  // const [userTypeId, setuserTypeId] = useState('');
  // const [userId, setuserId] = useState('');

  console.log("commodity in spec form -->", commodity)

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (!commName) {
      call('POST', 'getcommoditydatabyid', { commId: commodity, masterid: masterid }).then((result) => {
        console.log("API result getcommoditydatabyid:-->", result);
        setdbSpecData(result.message[0]);
      }).catch((e) => {
        console.log("Error while querying getCommodityList:", e);
      })
    }

    //------------------------------------------------------------------

  }, [commodity]);

  console.log("spec in form component-->", spec)
  console.log("specOrder in form component-->", specOrder)


  //---------------------------------------------------------------------------------------------------------------------
  // Functions to add dynamic specification elements
  function addElement() {
    let newSpecObj = { "name": newSpec.newSpecificationName, "value": newSpec.newSpecificationValue, "rejectionValue": newSpec.newSpecificationrejectionValue, "rejectionSymbol": newSpec.newSpecificationrejectionSymbol }
    if (onSetSpecification) {
      onSetSpecification({ ...spec, [newSpec.newSpecificationName.toLowerCase().replace(/\s/g, '')]: newSpecObj });
    }
    else {
      setSpecification({ ...spec, [newSpec.newSpecificationName.toLowerCase().replace(/\s/g, '')]: newSpecObj });
    }
    setnewSpec(newSpec => ({
      ...newSpec,
      newSpecificationName: "",
      newSpecificationValue: "",
      newSpecificationrejectionSymbol: '',
      newSpecificationrejectionValue: ''
    }));

    if (contMode)
      setEditCount(editCount + 1)

    let tempOrderArray = [...specOrder]
    tempOrderArray.push(newSpec.newSpecificationName.toLowerCase().replace(/\s/g, ''))
    if (onSetSpecOrder) {
      onSetSpecOrder(tempOrderArray)
    }
    else {
      setspecOrder(tempOrderArray)
    }

  }

  function removeElement(key) {
    let specdataElement = { ...spec }
    delete specdataElement[key]
    if (onSetSpecification) {
      onSetSpecification(specdataElement)
    }
    else {
      setSpecification(specdataElement)
    }

    let tempOrderArray = [...specOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    if (onSetSpecOrder) {
      onSetSpecOrder(tempOrderArray)
    }
    else {
      setspecOrder(tempOrderArray)
    }

  }


  const handlenewSpec = (event) => {
    event.persist();
    setnewSpec(newSpec => ({ ...newSpec, [event.target.name]: validate_text_field(event.target.value) }));
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Commodity Spec Components

  function createSpecComponet(specification) {
    let keyArray = specOrder?.length ? specOrder : Object.keys(specification)?.length ? Object.keys(specification) : []
    return (
      <div className={`modal-padding ${hideModalPadding ? ' p-0 ' : ' '} `}>
        <h3 className="mt-4 mb-4 font-size-14 font-wt-500">Specification ({commName ? commName : dbSpecData.commodity_pretty_name}) <span className="required-field text-danger">*</span> <span className="help-icon" flow="right" tooltip="Commodity Specifications">i</span></h3>
        <div className="row">
          {
            keyArray.length ? keyArray.map((key) => {
              return (
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          {/* <div className="form-group">
                            <label className="">{specification[key].name ? specification[key].name + (" - (Typical Value)") : '--'}</label>
                            <input type="text" className={"form-control " + (!masterid && !specification[key].value && contMode === "edit" ? ' border-danger' : "")} name={key ? key + ',value' : ''} value={specification[key].value} placeholder="" onChange={handleSpecification} disabled={editLevel ? false : true} />
                          </div> */}
                          <NewInput label={specification?.[key]?.name ? specification[key].name + (" - (Typical Value)") : '--'} value={specification?.[key]?.value}
                            error={!masterid && !specification?.[key]?.value && contMode === "edit"} name={key ? key + ',value' : ''}
                            onChange={handleSpecification} isDisabled={editLevel ? false : true} />
                        </div>
                        <div className="col-md-3">
                          {/* <div className="form-group">
                            <label className="">Rejection At</label>
                            <select className={"form-control " + (!masterid && !specification[key].rejectionSymbol && contMode === "edit" ? ' border-danger' : "")} name={key ? key + ',rejectionSymbol' : ''} value={specification[key].rejectionSymbol} onChange={handleSpecification} disabled={editLevel ? false : true} >
                              <option value="" selected>-select-</option>
                              <option value="!">N/A</option>
                              <option value="<">Below</option>
                              <option value=">">Above</option>
                            </select>
                          </div> */}
                          <NewSelect label={"Rejection At"} error={!masterid && !specification?.[key]?.rejectionSymbol && contMode === "edit"} selectData={[{ name: 'N/A', val: "!" }, { name: 'Below', val: "<" }, { name: 'Above', val: ">" }, { name: 'Range', val: "-" }]}
                            optionLabel={"name"} optionValue={"val"}
                            name={key ? key + ',rejectionSymbol' : ''} value={specification?.[key]?.rejectionSymbol} onChange={handleSpecification} isDisabled={editLevel ? false : true} />
                        </div>
                        <div className="col-md-4">
                          {/* <div className="form-group">
                            <label className="">Rejection Value</label>
                            <input type="text" className={"form-control " + (!masterid && !specification[key].rejectionValue && contMode === "edit" ? ' border-danger' : "")} name={key ? key + ',rejectionValue' : ''} value={specification[key].rejectionValue} placeholder="" onChange={handleSpecification} disabled={editLevel ? false : true} />
                          </div> */}
                          <NewInput label={"Rejection Value"} error={!masterid && !specification?.[key]?.value && contMode === "edit"}
                            name={key ? key + ',rejectionValue' : ''} value={specification?.[key]?.rejectionValue} placeholder=""
                            onChange={handleSpecification} isDisabled={editLevel ? false : true} />
                        </div>
                        <div className="col-md-1">
                          <img className='cursor mt-2' src='assets/images/delete.png' onClick={() => removeElement(key)} />
                          {/* <button type="button" className="delete-icon spec-delete" onClick={() => removeElement(key)} ><i className="fa fa-trash"></i></button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }) : (
              <h3 className="ml-3 font-size-14 font-wt-600">No Specification Added</h3>
            )
          }
        </div>

        <div className='customHrLine mt-3 mb-4' />

        <div className="row">
          <div className="col-md-12">
            <h3 className="col-md-12 pl-0 mt-2 font-size-14 font-wt-600">ADD New Specification</h3>
            <div className="row mt-3">
              <div className="col-md-3">
                {/* <div className="form-group">
                  <label className="">Specification Name<span className="required-field text-danger">*</span></label>
                  <input type="text" value={newSpec.newSpecificationName} name="newSpecificationName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewSpec} />
                </div> */}
                <NewInput label={'Specification Name *'} value={newSpec.newSpecificationName} name="newSpecificationName" placeholder="Enter New Spec Name" onChange={handlenewSpec} />
              </div>
              <div className="col-md-2">
                {/* <div className="form-group">
                  <label className="">Typical Value<span className="required-field text-danger">*</span></label>
                  <input type="text" value={newSpec.newSpecificationValue} name="newSpecificationValue" placeholder="Value" className="form-control" onChange={handlenewSpec} />
                </div> */}
                <NewInput label={'Typical Value *'} value={newSpec.newSpecificationValue} name="newSpecificationValue" placeholder="Value" onChange={handlenewSpec} />
              </div>
              <div className="col-md-2">
                {/* <div className="form-group">
                  <label className="">Rejection At{!masterid ? <span className="required-field text-danger"></span> : ""}</label>
                  <select className="form-control" name="newSpecificationrejectionSymbol" value={newSpec.newSpecificationrejectionSymbol} onChange={handlenewSpec} >
                    <option value="" selected>-select-</option>
                    <option value="!">N/A</option>
                    <option value="<">Below</option>
                    <option value=">">Above</option>
                  </select>
                </div> */}
                <NewSelect label={"Rejection At"} selectData={[{ name: 'N/A', val: "!" }, { name: 'Below', val: "<" }, { name: 'Above', val: ">" }, { name: 'Range', val: "-" }]} value={newSpec.newSpecificationrejectionSymbol} name="newSpecificationrejectionSymbol" placeholder="Value" onChange={handlenewSpec}
                  optionLabel={"name"} optionValue={"val"} />
              </div>
              <div className="col-md-3">
                {/* <div className="form-group">
                  <label className="">Rejection Value{!masterid ? <span className="required-field text-danger"></span> : ""}</label>
                  <input type="text" value={newSpec.newSpecificationrejectionValue} name="newSpecificationrejectionValue" placeholder="Rejection Value" className="form-control" onChange={handlenewSpec} />
                </div> */}
                <NewInput label={"Rejection Value"} value={newSpec.newSpecificationrejectionValue} name="newSpecificationrejectionValue" placeholder="Value" onChange={handlenewSpec} />
              </div>
              <div className="col-md-2">
                {(newSpec.newSpecificationName !== "" && newSpec.newSpecificationValue !== "") && <button className=" new-btn w-80 py-2 px-2  text-white " onClick={() => addElement()} >Add +</button>}
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }


  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>

      {createSpecComponet(spec)}

    </>)
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecificationsForm)
