import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { FileInput } from "../../../../utils/FileInput";
import toastDisplay from "../../../../utils/toastNotification";
import call from "../../../../service";
import { ToastContainer } from "react-toastify";
import { getDocDetails, most_used_currencies, convertImageToPdf, LcFieldsMaster } from "../../../../utils/myFunctions";
import SideBarV2 from "../../../partial/sideBarV2";
import HeaderV2 from "../../../partial/headerV2";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import TooltipModal from "../../../InvoiceDiscounting/components/Tooltipmodal";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from "../../../../utils/newInput";
import { Lcdetailsdata } from "../../applyforLimit/components/lcdiscountingcard";
import { getUserDataFromCookie } from "../../../../utils/cookieHelper";
import { useHistory } from "react-router";
import { formatDate_Application } from "../../../../utils/dateFormaters";

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]


const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  // { "name": "LC tenor", val:  },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]


const AmmendLC = ({ navToggleState, params }) => {

  // console.log("AmmendLC=====================>", params);

  let financierQuotes = params?.financierQuotes || JSON.parse(localStorage.getItem('financierQuotes'))
  let selectedFinancier = params?.selectedFinancier || localStorage.getItem('selectedFinancier')
  let isAdmin = params?.isAdmin


  const queryParams = new URLSearchParams(window.location.search)
  const quoteId = params?.id || queryParams.get("id")

  let history = useHistory()

  const [accord, setaccord] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });

  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [lcCodes, setLCCodes] = useState({})
  const [LcFields, setLCFields] = useState(LcFieldsMaster)

  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [ammendData, setAmmendData] = useState({})
  const [ammendFields, setAmmendFields] = useState({})
  const [ocrFields, setOcrFields] = useState({})
  const [moreLCDetails, setMoreLCDetails] = useState(false)

  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [contractDocs, setContractDocs] = useState([])
  const [LCComment1, toggleLCComment1] = useState({})
  const [LCComment2, toggleLCComment2] = useState({})
  const [LCComment3, toggleLCComment3] = useState({})

  const [showBuyerComment, toggleBuyerComment] = useState(null)

  const [formTabs, setFormTabs] = useState([])

  const [amend, setAmend] = useState(false);

  const [amendDbData, setAmendDbData] = useState([])
  const [addtionalChangesDbData, setAdditionalChangeDbData] = useState([])
  const [fieldValues, setFieldValues] = useState({ lcType: 'sight_lc' })

  let userTokenDetails = getUserDataFromCookie()

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  const closeSuccess = () => {
    setAmend(false);
    // setExperience(true);
  }

  async function getMasterData() {
    let temp = LcFields
    setshowLoader(true)
    temp["shipmentFromCountry"]["selectData"] = await call('get', 'getallCountry')
    temp["shipmentToCountry"]["selectData"] = temp["shipmentFromCountry"]["selectData"]
    temp["countryOfOrigin"]["selectData"] = temp["shipmentFromCountry"]["selectData"]
    temp["portOfDischarge"]["selectData"] = await call('POST', 'getOldPortMaster')
    temp["portOfLoading"]["selectData"] = temp["portOfDischarge"]["selectData"]
    setshowLoader(false)
    setLCFields(temp)
  }

  useEffect(() => {
    //Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    getMasterData()
    // Get master data end

    //Get ammendments & required changes data start
    call('POST', 'getAmendmentsByApplicationId', {
      quoteId
    }).then((result1) => {
      console.log('running getAmendmentsByApplicationId api -->', result1);
      setAmendDbData(result1)
      createFormTabs(result1, [])
    }).catch((e) => {
      // console.log('error in getAmendmentsByApplicationId', e);
    })
    //Get ammendments & required changes data end
  }, [])

  async function createFormTabs(data1, data2) {
    let tempObj = {}
    let fieldValues = {}

    let additionalDocument = {}

    for (let index = 0; index < data1.length; index++) {
      const element = data1[index];

      let quote = financierQuotes.filter((i) => {
        if (i.lender_id / 1 == element.createdBy / 1) {
          return i
        }
      })[0]

      tempObj[element.company_name] = {
        ...tempObj[element.company_name],
        "company_id": element.createdBy,
        "id": element.id,
        "fieldLabels": Object.keys(element.details),
        "fieldComments": Object.values(element.details),
        "fields": element.lcAdditionalDetails || [],
        'documents': element.additionalDocuments || [],
        "remark": quote.assignRemark,
        "status": element.status,
        forAmendment: true
      }

      for (let index = 0; index < element.additionalDocuments.length; index++) {
        if (element.additionalDocuments[index]["doc"]) {
          setshowLoader(true)
          additionalDocument[element.additionalDocuments[index]["title"]] = await getDocDetails(element.additionalDocuments[index]["doc"])
          setshowLoader(false)
        }
      }

      fieldValues = { ...fieldValues, ...additionalDocument, ...element.beforeDetails }
    }
    let tabs = []
    Object.keys(tempObj).forEach((item, index) => {
      tabs.push({ "company_name": item, ...tempObj[item] })
    })
    console.log("tabs==============>", tabs);
    setFormTabs(tabs)
    setFieldValues(fieldValues)
  }

  async function validateBuyerDetails(docsArr, amendmentId) {
    let err = {}
    docsArr.forEach((i) => {
      if (!fieldValues[i["title"]]) {
        err[i["title"]] = "Mandatory Field"
      }
    })
    if (!Object.keys(err).length) {
      let formData = new FormData()
      formData.append("amendmentId", amendmentId)
      formData.append("userId", userId)
      formData.append("docsArr", JSON.stringify(docsArr))
      docsArr.forEach((i) => {
        formData.append(i["title"], fieldValues[i["title"]])
      })
      setshowLoader(true)
      call('POST', 'submitAdditionalRequirements', formData).then((result) => {
        // console.log('running submitAdditionalRequirements api -->', result);
        toastDisplay(result, "success")
        setshowLoader(false)
        history.go(-1)
      }).catch((e) => {
        // console.log('error in submitAdditionalRequirements', e);
        toastDisplay("Something went wrong", "error")
        setshowLoader(false)
      })
    }
    setErrors(err)
  }




  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setFieldValues({ ...fieldValues, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex, ammendmentStatus) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    // if (tab === 1) {
    //   let validateDocs = ["originalLC", "invReport", "certOfOrigin", "billOfLading"]
    //   validateDocs.forEach((item, index) => {
    //     if (!(data[item] && data[item].signatureId)) {
    //       err[item] = "Signing document is mandatory"
    //     }
    //     if (!(data[item] && data[item].filebase64)) {
    //       err[item] = "Mandatory Field"
    //     }
    //   })
    // }

    if (!Object.keys(err).length) {
      if (tab === 1) {
        setshowLoader(true)
        let formData = new FormData()
        // formData.append("onlyView", onlyView)
        // formData.append("buyerId", data.buyerId)
        // formData.append("buyerOtherDocs", data.buyerOtherDocs)
        // formData.append("userId", userId)
        // formData.append("applicationId", data.id)

        // formData.append("originalLC", data.originalLC)
        // formData.append("invReport", data.invReport)
        // formData.append("certOfOrigin", data.certOfOrigin)
        // formData.append("billOfLading", data.billOfLading)

        // formData.append("originalLCSignId", data.originalLC.signatureId)
        // formData.append("invReportSignId", data.invReport.signatureId)
        // formData.append("certOfOriginSignId", data.certOfOrigin.signatureId)
        // formData.append("billOfLadingSignId", data.billOfLading.signatureId)

        // addMoreDoc.forEach((k, index) => {
        //   if (data["otherDoc" + index]) {
        //     formData.append("otherDoc" + index, data["otherDoc" + index])
        //     if (data["otherDoc" + index]["signatureId"]) {
        //       formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
        //     }
        //   }
        // })
        // formData.append("applicationId", quoteId)
        // formData.append("amendmentId", ammendmentId)
        // formData.append('changedDetails', JSON.stringify(ammendmentStatus / 1 == 2 ? {} : ocrFields))
        // formData.append('ammendmentStatus', ammendmentStatus)

        // call('POST', 'updateAmmendmentRequest', formData).then((result) => {
        //   // console.log('running updateAmmendmentRequest api-->', result);
        //   toastDisplay(result, "success", () => window.location = "/login")
        //   setshowLoader(false)
        // }).catch((e) => {
        //   // console.log('error in updateAmmendmentRequest', e);
        //   toastDisplay("Something went wrong", "error")
        //   setshowLoader(false)
        // });
        return null
      }
      else {
        setTab(tab + 1)
      }
    }

    setErrors(err)
  }

  const handleChange = async (event) => {
    event.persist()
    setFieldValues({ ...fieldValues, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  console.log("fieldValues=========>", fieldValues)

  async function forwardRequestToBuyer(amendmentId) {
    call('POST', 'forwardAmendmentRequestToBuyer', {
      quoteId,
      amendmentId,
      userName,
      buyerEmail: params?.buyerEmail || localStorage.getItem("buyerEmail")
    }).then((result) => {
      // console.log('running forwardAmendmentRequestToBuyer api -->', result);
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
      toastDisplay("Something went wrong", "error")
    })
  }

  const handleAccordion = (accordion) => {
    setaccord(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          {userTypeId && !isAdmin ? (
            <SideBarV2 state="LcFinancebtn" userTokenDetails={{}} />
          ) : null}
          <main role="main" className={`ml-sm-auto col-lg-${isAdmin ? '12' : '10'} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {userTypeId && !isAdmin ? (
              <HeaderV2
                title={"LC - Additional Required Changes"}
                userTokenDetails={{}} />
            ) : null}
            {!isAdmin ?
              <a className="cursor" ><img
                onClick={() => history.go(-1)}
                className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> : null}
            <div className="mt-4">
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">

                {formTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index + 1) }}
                      >
                        {item.company_name}</a>
                    </li>
                  )
                })}

                {/* {!onlyView ? (
                  <a onClick={() => setAmend(true)} alt="" className="text-decoration-none text-color1 font-weight500 font-size-13 w-49 text-end cursor">Amend Application <img src={"assets/images/Lc/ArrowBack.png"} alt="" height={10} width={10} /></a>
                ) : null} */}
              </ul>
              <div className="card mt-1 pb-5">

                {formTabs.length ? formTabs.map((item, index) => {
                  return (
                    <div className="pb-5">
                      {tab === index ? (
                        <div className="mt-5">
                          {item.forAmendment ? (
                            <>
                              <div className="col-12 px-5">
                                <div className={" accordionItem" + (accord["accord_0"] ? " open" : " closed")}>
                                  <h2 className="accordionItemHeading py-4 pl-4" onClick={() => handleAccordion('accord_0')}>
                                    <u>LC details</u></h2>
                                  {accord["accord_0"] && (
                                    <div className="accordionItemContent ">
                                      <div className="row d-flex justify-content-evenly">
                                        <div className="w-45 p-0">
                                          <label className="font-size-16">Additional request</label>
                                        </div>
                                        <div className="w-45 p-0">
                                          <label className="font-size-16">Comments on existing information</label>
                                        </div>
                                      </div>

                                      <div className="row d-flex justify-content-evenly">
                                        <div className="w-45 amendmentDetailsCard pt-4 mt-2">
                                          {item.fields.length ? item.fields.map((i, j) => {
                                            return (
                                              <div className='col-md-12'>
                                                <div className="row form-group">
                                                  <div className="col-md-8">
                                                    {i.type === "date" ? (
                                                      <img
                                                        src={"/assets/images/calender.png"} alt="" className="input-date-icon" />) : null}
                                                    <input disabled={true} className={"form-control bg-white"}
                                                      placeholder={`${i.type === "percentage" ? "%  " : ""}${i.type === "currency" ? "$  " : ""}${i["title"]}`} />
                                                    <img onClick={() => { toggleLCComment1({ ...LCComment1, [j]: !LCComment1[j] }) }} src={"assets/images/warning.png"} alt="info" className="amendmentCommentIcon" />
                                                  </div>
                                                  {LCComment1[j] ? (
                                                    <div className="col-12">
                                                      <p className="pt-2 font-size-14 text-color-label">{
                                                        i.reason
                                                      }
                                                      </p>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )
                                          }) : null}
                                        </div>

                                        <div className="w-45 amendmentDetailsCard pt-4 mt-2">
                                          <div className='col-md-12'>
                                            {item.fieldLabels.map((i, j) => {
                                              let DynamicFieldComponent = LcFields[i] && LcFields[i]["component"] ? LcFields[i]["component"] : null
                                              return (
                                                <div className="row form-group">
                                                  <div className="col-md-8">
                                                    {lcCodes[i] ? (
                                                      <NewTextArea
                                                        isDisabled={true}
                                                        label={i + "-" + lcCodes[i]} name={i} value={fieldValues[i]} />
                                                    ) : (
                                                      DynamicFieldComponent ? (
                                                        <DynamicFieldComponent
                                                          isDisabled={true}
                                                          type={LcFields[i]["type"]}
                                                          label={LcFields[i]["title"]} selectData={LcFields[i]["selectData"]}
                                                          optionLabel={LcFields[i]["optionLabel"]} optionValue={LcFields[i]["optionValue"]}
                                                          name={LcFields[i]["name"]} value={fieldValues[LcFields[i]["value"]]}
                                                          error={errors[LcFields[i]["error"]]}
                                                          onChange={handleChange}
                                                        />
                                                      ) : null)}
                                                    <img onClick={() => { toggleLCComment2({ ...LCComment2, [j]: !LCComment2[j] }) }} src={"assets/images/warning.png"} alt="info" className="amendmentCommentIcon" />
                                                  </div>
                                                  {LCComment2[j] ? (
                                                    <div className="col-12">
                                                      <p className="font-size-14 text-color-label">
                                                        {item.fieldComments[j] || "NA"}
                                                      </p>
                                                    </div>
                                                  ) : null}
                                                </div>)
                                            })}
                                          </div>
                                        </div>

                                      </div>

                                      {!isAdmin ?
                                        <button type="button"
                                          disabled={selectedFinancier != item.company_id}
                                          onClick={() => forwardRequestToBuyer(item.id)}
                                          className={`ml-4 mt-4 new-btn w-20 py-2 px-2 text-white`}>
                                          {"Forward To Buyer"}
                                        </button> : null}

                                      {selectedFinancier != item.company_id ? (
                                        <p className="font-size-14 ml-4 mt-2"><img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className="font-size-13 text-secondary">Quote not selected yet, you need to select a quote first for approval. </span>
                                          <label
                                            onClick={() => { history.go(-1) }}
                                            className="text-color1 text-decoration-underline cursor">Go back to quote</label> </p>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-12 px-5">
                                <div className={" accordionItem mt-4" + (accord["accord_1"] ? " open" : " closed")}>
                                  <h2 className="accordionItemHeading py-4 pl-4" onClick={() => handleAccordion('accord_1')}>
                                    <u>Buyer details</u></h2>
                                  {accord["accord_1"] && (
                                    <div className="accordionItemContent ">
                                      <div className="row d-flex justify-content-evenly">
                                        <div className="w-45 p-0">
                                          <label className="font-size-16">Additional request</label>
                                        </div>
                                        <div className="w-45 p-0">
                                          {/* <label className="font-size-16">Comments on existing information</label> */}
                                        </div>
                                      </div>

                                      <div className="row d-flex justify-content-evenly">
                                        <div className="w-45 amendmentDetailsCard pt-4 mt-2">
                                          {item.documents.length ? item.documents.map((i, j) => {
                                            return (
                                              <div className='col-md-12'>
                                                <div className="row form-group">
                                                  <div className="col-md-12">
                                                    <label>{i["title"]}</label>
                                                    <img onClick={() => { toggleLCComment3({ ...LCComment3, [j]: !LCComment3[j] }) }} src={"assets/images/warning.png"} alt="info" className="amendmentCommentIconForDoc" />
                                                    {LCComment3[j] ? (
                                                      <div className="col-12 p-0">
                                                        <p className="pt-2 font-size-14 text-color-label">{
                                                          i.reason
                                                        }
                                                        </p>
                                                      </div>
                                                    ) : null}
                                                    <FileInput isEditable={item.status != 1 && !isAdmin} name={i["title"]} value={fieldValues[i["title"]]}
                                                      error={errors[i["title"]]} onChange={handleFile} />
                                                    {errors[i["title"]] ? <div class="text-danger mt-2">
                                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                      <b>{errors[i["title"]]}</b></div> : ''}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          }) : null}
                                        </div>

                                        <div className="w-45 pt-4 mt-2">
                                        </div>

                                      </div>
                                      {item.status != 1 && !isAdmin ? (
                                        <button type="button"
                                          onClick={() => validateBuyerDetails(item.documents, item.id)}
                                          className={`ml-4 mt-4 mb-2 new-btn w-20 py-2 px-2 text-white`}>
                                          {"Submit"}
                                        </button>
                                      ) : null}

                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-12 px-5">
                                <div className={" accordionItem mt-4" + (accord["accord_2"] ? " open" : " closed")}>
                                  <h2 className="accordionItemHeading py-4 pl-4" onClick={() => handleAccordion('accord_2')}>
                                    <u>Remark on application</u></h2>
                                  {accord["accord_2"] && (
                                    <div className="accordionItemContent ">
                                      <div className="col-6">
                                        <NewTextArea
                                          rows={5}
                                          value={item.remark} />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                            </>
                          ) : null}






                          {/* {item.forAdditionalChanges ? (
                            <div className="pl-4 ml-3">
                              <label className='font-wt-500 font-size-16 mt-4 pt-4'><u>Additional information requested</u></label>
                              {item.fields.length ? item.fields.map((i, j) => {
                                return (
                                  <div className="col-md-4 mt-4 p-0">
                                    <span className="error-icon-small" flow="right">i</span>
                                    <div
                                      className="tooltipDiv"
                                    >
                                      <label className="mt-3 text-color-value font-wt-600 font-size-16 ml-3">Comment</label>
                                      <p className="font-size-14 text-color-label ml-3 mr-3 font-wt-400" >{i["reason"] || "NA"}</p>
                                    </div>
                                    {(i["type"] === "currency" || i["type"] === "percentage") ? (
                                      <InputWithSelect label={i["title"]}
                                        type={i["number"]}
                                        name={i["title"]}
                                        value={fieldValues[i["title"]]}
                                        selectData={i["type"] === "currency" ? most_used_currencies : [{ "code": "%" }]}
                                        selectName={i["title"] + "Unit"} selectValue={fieldValues[i["title"] + "Unit"]}
                                        error={errors[i["title"]]}
                                        optionLabel={'code'} optionValue={"code"}
                                        onChange={handleChange}
                                      />
                                    ) : null}
                                    {i["type"] === "text" ? (
                                      <NewInput label={i["title"]}
                                        type={i["type"]}
                                        name={i["title"]}
                                        value={fieldValues[i["type"]]}
                                        onChange={handleChange}
                                      />
                                    ) : null}
                                  </div>
                                )
                              })
                                : null}
                              {item.documents.length ? item.documents.map((i, j) => {
                                return (
                                  <div className="col-md-5 mt-4 p-0">
                                    <label className="font-size-14">{i["title"]}</label>
                                    <span className="error-icon-small ml-2"
                                      style={{
                                        "top": "0.2rem",
                                        "left": "0",
                                        "position": 'relative'
                                      }}
                                      flow="right">i</span>
                                    <div
                                      className="tooltipDiv"
                                    >
                                      <label className="mt-3 text-color-value font-wt-600 font-size-16 ml-3">Comment</label>
                                      <p className="font-size-14 text-color-label ml-3 mr-3 font-wt-400" >{i["reason"] || "NA"}</p>
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-md-12">
                                        <FileInput
                                          name={i["title"]} value={fieldValues[i["title"]]}
                                          onChange={handleChange} error={errors[i["title"]]}
                                          onUploadCancel={() => { setFieldValues({ ...fieldValues, [i["title"]]: null }) }}
                                          isEditable={true} />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                                : null}
                            </div>
                          ) : null} */}
                          {/* {item.forAmendment ? (
                            <div className="pl-4 ml-3">
                              <label className='font-wt-500 font-size-16 mt-4 '><u>Comments on information provided</u></label>
                              {item.fieldLabels.map((i, j) => {
                                let DynamicFieldComponent = LcFields[i] && LcFields[i]["component"] ? LcFields[i]["component"] : null
                                return (
                                  <div className="col-md-4 mt-4 p-0">
                                    <span className="error-icon-small" flow="right">i</span>
                                    <div
                                      className="tooltipDiv"
                                    >
                                      <label className="mt-3 text-color-value font-wt-600 font-size-16 ml-3">Comment</label>
                                      <p className="font-size-14 text-color-label ml-3 mr-3 font-wt-400" >{item.fieldComments[j] || "NA"}</p>
                                    </div>
                                    {lcCodes[i] ? (
                                      <NewTextArea
                                        isDisabled={(userTypeId && userTypeId / 1 == 19)}
                                        label={i + "-" + lcCodes[i]} name={i} value={fieldValues[i]} />
                                    ) : (
                                      <>
                                        {DynamicFieldComponent ? (
                                          <DynamicFieldComponent
                                            isDisabled={(userTypeId && userTypeId / 1 == 19)}
                                            type={LcFields[i]["type"]} noIcon={true}
                                            label={LcFields[i]["title"]} selectData={LcFields[i]["selectData"]}

                                            optionLabel={LcFields[i]["optionLabel"]} optionValue={LcFields[i]["optionValue"]}

                                            name={LcFields[i]["name"]} value={fieldValues[LcFields[i]["value"]]}

                                            error={errors[LcFields[i]["error"]]}

                                            onChange={handleChange}
                                          />
                                        ) : null}
                                      </>)}
                                  </div>
                                )
                              })}
                            </div>
                          ) : null} */}
                        </div>
                      ) : null}
                    </div>
                  )
                }) : null}

                {/* <button type="button"
                  // disabled={(!data.tcAccepted && tab === 2)}
                  onClick={() => forwardRequestToBuyer()}
                  className={`ml-5 pl-5 new-btn w-20 py-2 px-2 text-white`}>
                  {"Forward To Buyer"}
                </button> */}

              </div>

            </div>
          </main>
        </div >
      </div >

      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p className="mx-4">Your application for “Non resource LC Discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}

      {
        tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
          data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />
      }

      {
        signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
          setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />
      }

      {
        amend && <FinanceInvoiceModal limitinvoice={amend} setLimitinvoice={setAmend} closeSuccess={closeSuccess} >
          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
          <p className="font-size-13 font-wt-600">Do you want to amend your application?</p>
          <button
            onClick={() => { window.location = `/LcDiscounting?buyer=${data.buyerId}&purpose=${data.lcPurpose}&id=${data.id}&view=true` }}
            className="bg-white px-3 py-2 btnsmall font-size-13">Yes</button>
          <button className="bg-white px-3 py-2 btnsmall font-size-13 ms-3">No</button>
        </FinanceInvoiceModal>
      }
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AmmendLC)