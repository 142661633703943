export default function validate(values, modeTab, shippingTypeTab) {
  let errors = {};
  let validateFields = []

  // validateFields = ['origin_country', "destination_country", "origin_port", "destination_port", "start_date", "end_date"]
  // validateFields = ['origin_country', "destination_country", "origin_port", "destination_port", "exp_date"]
  validateFields = ["origin_port", "destination_port", "exp_date"]
  for (let i = 0; i < validateFields.length; i++) {
    if (!values[validateFields[i]]) {
      errors[validateFields[i]] = 'is required'
    }
  }

  // if (modeTab === 0 && shippingTypeTab === 0) {
  //   // validateFields = ["origin_port", "destination_port", "containerType", "containerNo", "cargoType", "start_date", "end_date"]
  //   validateFields = ["origin_port", "destination_port", "start_date", "end_date"]
  //   for (let i = 0; i < validateFields.length; i++) {
  //     if (!values[validateFields[i]]) {
  //       errors[validateFields[i]] = 'is required'
  //     }
  //   }
  // }
  // if (modeTab === 0 && shippingTypeTab === 1) {
  //   // validateFields = ["origin_port", "destination_port", "cargoType", "loadVolume", "loadWeight", "start_date", "end_date"]
  //   validateFields = ["origin_port", "destination_port", "start_date", "end_date"]
  //   for (let i = 0; i < validateFields.length; i++) {
  //     if (!values[validateFields[i]]) {
  //       errors[validateFields[i]] = 'is required'
  //     }
  //   }
  // }
  // if (modeTab === 1 && shippingTypeTab === 0) {
  //   // validateFields = ["origin_port", "destination_port", "vesselType", "commType", "start_date", "end_date"]
  //   validateFields = ["origin_port", "destination_port", "start_date", "end_date"]
  //   for (let i = 0; i < validateFields.length; i++) {
  //     if (!values[validateFields[i]]) {
  //       errors[validateFields[i]] = 'is required'
  //     }
  //   }
  // }
  // if (modeTab === 1 && shippingTypeTab === 1) {
  //   // validateFields = ["origin_port", "destination_port", "vesselType", "cargoType", 'flexibilityDays', "start_date", "end_date"]
  //   validateFields = ["origin_port", "destination_port", "start_date", "end_date"]
  //   for (let i = 0; i < validateFields.length; i++) {
  //     if (!values[validateFields[i]]) {
  //       errors[validateFields[i]] = 'is required'
  //     }
  //   }
  // }
  // if (modeTab === 1 && shippingTypeTab === 2) {
  //   // validateFields = ["origin_port", "destination_port", "vesselType", "cargoType", "start_date", "end_date"]
  //   validateFields = ["origin_port", "destination_port", "start_date", "end_date"]
  //   for (let i = 0; i < validateFields.length; i++) {
  //     if (!values[validateFields[i]]) {
  //       errors[validateFields[i]] = 'is required'
  //     }
  //   }
  // }
  // if (!values.portA) {
  //   errors.portA = 'Field Missing'
  // }
  // if (!values.portB) {
  //   errors.portB = 'Field Missing';
  // }
  // if (!values.fromdate) {
  //   errors.fromdate = 'Field Missing';
  // }
  // if (!values.weeksahead) {
  //   errors.weeksahead = 'Field Missing';
  // }
  // if (!values.direction) {
  //   errors.direction = 'Field Missing';
  // }
  // if (!values.sealine) {
  //   errors.sealine = 'Field Missing';
  // }
  console.log("errors in shipping form", errors);
  return errors;
}