import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setAddShip } from '../../store/actions/action';
import DataTable from 'react-data-table-component';
import columnsMyShips from '../dataTablesColumsMap/myShipsListColumns';
import toastDisplay from '../../utils/toastNotification';
import customStyles from '../dataTablesColumsMap/customTableCss';

const MyShipsListing = ({ userTokenDetails, showAddShip, setAddShip, dispatch }) => {

  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, { userId: userId, type_id: userTypeId })
    //------------------------------------------------------------------

  }, [filterData, refresh, showAddShip.info.refresh]);



  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("")
  const [statusFilter, setstatusFilter] = useState(0)

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getmyshipslist', objectAPI).then((result) => {
      console.log('running getmyshipslist api-->', result);
      settableData(formatDataFortable(result.shipdata, objectAPI.userId))
      setTotalRows(result.countdata.totalCount)

      setLoading(false);
    }).catch((e) => {
      console.log('error in getmyshipslist', e);
      setLoading(false);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getmyshipslist', objectAPI).then((result) => {
      console.log('running getmyshipslist api-->', result);
      settableData(formatDataFortable(result.shipdata, objectAPI.userId))
      setTotalRows(result.countdata.totalCount)

      setLoading(false);
    }).catch((e) => {
      console.log('error in getmyshipslist', e);
      setLoading(false);
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };


  function formatDataFortable(dataArray, userId) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.ship_name = dataArray[i].ship_name
      tempObj.type = dataArray[i].type
      tempObj.category = dataArray[i].category
      tempObj.imo_no = dataArray[i].imo_no
      tempObj.availability = dataArray[i].availability
      tempObj.registry_port = dataArray[i].registry_port
      tempObj.lengthv = dataArray[i].lengthv
      tempObj.breadthv = dataArray[i].breadthv
      tempObj.depth = dataArray[i].depth
      tempObj.dead_weight = dataArray[i].dead_weight
      tempObj.gross = dataArray[i].gross
      tempObj.net = dataArray[i].net
      tempObj.update = (dataArray[i].owner === userId) ? <>
        <span className="btn btn-info btn-sm mr-2 ml-2" onClick={() => setAddShip(true, { value: dataArray[i] })}> <i className="fa fa-pencil-square-o" aria-hidden="true"></i></span>
      </> :
        <>
          <span className="btn btn-success btn-sm mr-2 ml-2" onClick={""}> <i className="fa fa-bookmark" aria-hidden="true"></i></span>
        </>
      tempObj.action = <>
        <div className='custom-control custom-switch'>
          <input
            type='checkbox'
            className='custom-control-input'
            id={'customSwitches' + i}
            value={dataArray[i].ship_status}
            checked={dataArray[i].ship_status === 1 ? true : false}
            onClick={() => updateStatus(dataArray[i].id, dataArray[i].ship_status)} />
          <label className='custom-control-label' htmlFor={'customSwitches' + i} ></label>
        </div></>

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    if (type === 4) {
      setFilterData({
        ...filterData,
        onlyNotAvailable: false,
        onlyAvailable: false,
        onlypending: false,
        onlyBooked: true,
      })
    } else if (type === 3) {
      setFilterData({
        ...filterData,
        onlyNotAvailable: false,
        onlyAvailable: false,
        onlypending: true,
        onlyBooked: false,
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyNotAvailable: false,
        onlyAvailable: true,
        onlypending: false,
        onlyBooked: false,
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyPilot: true,
        onlyNotAvailable: true,
        onlyAvailable: false,
        onlypending: false,
        onlyBooked: false,
      })
    }
  };

  const reset = () => {
    setstatusFilter(0)
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setsearchKey("")
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // functions
  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getmyshipslist', objectAPI).then((result) => {
      console.log('running getmyshipslist api-->', result);

      const dbResult = result.shipdata
      let csvString = "Ship Name,Type,Category,Imo No,Ship Status,Commodities,Registry Port,Length,Breadth,Depth,Deadweight,Gross Ton,Net Ton\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].ship_name},
        ${dbResult[i].type ? dbResult[i].type : "NA"},
        ${dbResult[i].category ? dbResult[i].category : "NA"},
        ${dbResult[i].imo_no ? dbResult[i].imo_no : "NA"},
        ${dbResult[i].availability === 3 ? "Booked" :
            dbResult[i].availability === 2 ? "Pending" :
              dbResult[i].availability === 1 ? "Available" :
                dbResult[i].availability === 0 ? "Not-Available" : ""},
        ${dbResult[i].commodities ? dbResult[i].commodities.replace(/,/g, " ") : "NA"},
        ${dbResult[i].registry_port ? dbResult[i].registry_port : "NA"},
        ${dbResult[i].lengthv ? dbResult[i].lengthv : "NA"},
        ${dbResult[i].breadthv ? dbResult[i].breadthv : "NA"},
        ${dbResult[i].depth ? dbResult[i].depth : "NA"},
        ${dbResult[i].dead_weight ? dbResult[i].dead_weight : "NA"},
        ${dbResult[i].gross ? dbResult[i].gross : "NA"},
        ${dbResult[i].net ? dbResult[i].net : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Trade_Contracts_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getmyshipslist', e);
    })
  }

  const updateStatus = (id, status) => {
    // console.log(id, availability);
    // event.preventDefault();
    // console.log(event.target.value);
    // let flag = event.target.value === '1' ? 0 : 1;
    // console.log('flag', flag);
    call('POST', 'updateShipStatus', { id: id, status: !status }).then((result) => {
      setrefresh(refresh + 1);
      toastDisplay(result, "success");
    }).catch((e) => {
      console.log('error in updateStatus', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      <div className="d-md-flex mt-3 mb-3">
        <div className="filter-bg">
          <ul>
            <li className={"pilot-cont cursor-pointer " + (statusFilter === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setstatusFilter(1); filterStateButtons(1) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Busy/In-Use
            			</li>
            <li className={"complete-cont cursor-pointer " + (statusFilter === 2 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(2); filterStateButtons(2) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Free/Not In-use
                  </li>
            <li className={"progress-cont cursor-pointer " + (statusFilter === 3 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(3); filterStateButtons(3) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Under Booking
                  </li>
            <li className={"primary-cont cursor-pointer " + (statusFilter === 4 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(4); filterStateButtons(4) }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Already Booked
                  </li>
          </ul>
        </div>

        <div className="ml-md-auto relative">
          {/* {(Object.keys(filterData).length) ? <div className="reset" onClick={() => reset()}>reset</div> : ''} */}
          <ul className="range-slider">
            <li>
              <input name="search" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} id="searchInput" onKeyDown={handleKeyPress} />
              <button type="button" onClick={() => {
                setFilterData({
                  ...filterData,
                  "search": searchKey
                })
              }}><i class="fas fa-search fa-lg"></i></button>
            </li>
            <li>
              <label>From</label>
              <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
            </li>
            <li>
              <label>To</label>
              <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
          </ul>
        </div>
      </div>
      <div className="table-responsive">
        <DataTable
          columns={columnsMyShips}
          data={tableData}
          progressPending={loading}
          pagination
          progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
          persistTableHead
          striped
          noHeader
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover
          onSort={handleSort}
          sortServer
          customStyles={customStyles}
        />
      </div>
      <hr />
      <div className="float-left col-md-6 ">
        <div className="col-md-12 row">
          <div className="col-md-6">
            <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
              <i class="fas fa-file-csv"></i> Export CSV
              </button>
          </div>
        </div>
      </div>
    </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    showAddShip: state.showAddShip
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAddShip: (flag, data) => { dispatch(setAddShip({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyShipsListing)
