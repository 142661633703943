import React, { useEffect, useState } from 'react';
import call from '../../../../service';
import { cargoTypeDD, vesselTypeDD } from '../../../../utils/myFunctions';
import toastDisplay from '../../../../utils/toastNotification';
import columnNames from '../../rateList/rateListColumns';
import moment from 'moment';
import { shipperTypeIds, traderTypeIds } from '../../../partial/sidebar';
import { websiteBackendURL } from '../../../../urlConstants';
import axios from 'axios';

const Fields = {
  "origin_country": "00:01:10:11:12:NV",
  "origin_port": "00:01:10:11:12",
  "destination_country": "00:01:10:11:12:NV",
  "destination_port": "00:01:10:11:12",
  "container_type": "00",
  "container_no": "00",
  "cargo_type": "11:12",
  "start_date": "00:01:10:11:12",
  "end_date": "00:01:10:11:12",
  "load_volume": "01:NV",
  "load_weight": "01:NV",
  "vessel_type": "10:11:12",
  "commodity_type": "10",
  "flexibility_days": "11",
  "price": "00:01:10:11:12",
  "offer_valid_till": "00:01:10:11:12"

}

const popupTypeLabel = {
  0: "Send Offer",
  1: "View Offer",
  2: "Update Offer",
  3: "View Offer & Contact Details"
}

const NegotiationPopup = ({ shipment_data, closePopup, userId, userTypeId, popupType }) => {
  const [showLoader, setshowLoader] = useState(false)
  const [shipmentData, setshipmentData] = useState(shipment_data)
  const [offerTab, setOfferTab] = useState(0)
  const [data, setdata] = useState(popupType === 0 ? shipment_data[0] :
    { ...shipment_data[0].offer_details_obj, offer_id: shipment_data[0].id })
  const [country, setcountry] = useState([])
  const [errorData, setErrorData] = useState({})
  const [ports, setPorts] = useState({ origin: [], destination: [] })
  const [commCategorys, setcommCategorys] = useState([])
  const [contactUserDetails, setcontactUserDetails] = useState({})
  const [containerTypeDD, setContainerTypeDD] = useState([])

  const astrix = <span className="required-field text-danger">*</span>
  const Container = ["FCL", "LCL"]
  const Vessel = ['Voyage Charter', 'Time Charter', 'Bareboat Charter']

  useEffect(() => {
    // API Calls
    setshowLoader(true)
    call('POST', 'getCountryMaster', {}).then((result) => {
      setcountry(result)
      setshowLoader(false);
    }).catch((e) => {
      // console.log('error in getCountryMaster', e);
      setshowLoader(false);
    })

    call('GET', 'getcommoditycategory').then((result) => {
      setcommCategorys(result);
      setshowLoader(false)
    }).catch((e) => {
      // console.log("Error while querying getcommoditycategory:", e);
      setshowLoader(false)
    })

    axios.post(websiteBackendURL + "/shippingMarketPlace/getContainerTypesFromRateMaster", {}).
      then(result => {
        setContainerTypeDD(result.data)
      });
    getPortDetailsByCountry()
  }, [])

  const getPortDetailsByCountry = (country_id, type) => {
    setshowLoader(true)
    // axios.post(websiteBackendURL + "/shippingMarketPlace/getCountryPorts", { country_id }).
    //   then(result => {
    //     setShowLoader(false);
    //     setPorts({
    //       ...ports,
    //       [type]: result.data
    //     })
    //   });
    axios.post(websiteBackendURL + "/shippingMarketPlace/getPortsFromRateMaster", { type: "origin" }).
      then(result => {
        axios.post(websiteBackendURL + "/shippingMarketPlace/getPortsFromRateMaster", { type: "destination" }).
          then(result2 => {
            setshowLoader(false);
            setPorts({
              ...ports,
              origin: result.data,
              destination: result2.data
            })
          });
      });
  }

  useEffect(() => {
    if (data.contactUserId) {
      call('POST', 'getUserDetailsById', { contactUserId: data.contactUserId }).then((result) => {
        if (result.length) {
          setcontactUserDetails(result[0])
        }
        setshowLoader(false);
      }).catch((e) => {
        // console.log('error in getUserDetailsById', e);
        setshowLoader(false);
      })
    }
  }, [data.contactUserId])

  useEffect(() => {
    if (country.length) {
      // call('POST', 'getCountryPorts', {
      //   country_id: country.filter(item => { if (item.name === data.origin_country) { return item } })[0]["id"]
      // }).then((result) => {
      //   call('POST', 'getCountryPorts', {
      //     country_id: country.filter(item => { if (item.name === data.destination_country) { return item } })[0]["id"]
      //   }).then((result2) => {
      //     setPorts({ origin: result, destination: result2 })
      //   }).catch((e) => {
      //     // console.log("Error while querying getCountryPorts:", e);
      //   })
      // }).catch((e) => {
      //   // console.log("Error while querying getCountryPorts:", e);
      // })
    }
  }, [country])

  const handleChange = event => {
    event.persist()
    setdata({ ...data, [event.target.name]: event.target.value })
  }

  const isFieldVisible = name => {
    let condition = "" + (data.mode === "Container" ? ("0" + Container.indexOf(data.mode_type)) : ("1" + Vessel.indexOf(data.mode_type))) + ""
    if (Fields[name].includes(condition)) {
      return true
    }
    return false
  }

  const validateFields = (type) => {
    let FieldsToValidate = Object.keys(data)
    if (shipperTypeIds.includes(userTypeId)) {
      FieldsToValidate.push("offer_valid_till")
    }
    let condition = "" + (data.mode === "Container" ? ("0" + Container.indexOf(data.mode_type)) : ("1" + Vessel.indexOf(data.mode_type))) + ""
    let err = {}
    for (let i = 0; i < FieldsToValidate.length; i++) {
      if (Fields[FieldsToValidate[i]] && Fields[FieldsToValidate[i]].includes(condition) && !Fields[FieldsToValidate[i]].includes("NV")) {
        if (!data[FieldsToValidate[i]]) {
          err[FieldsToValidate[i]] = "Field is required"
        }
        else {
          let validateAsNo = ["container_no", "load_volume", "load_weight", "flexibility_days", 'price']
          for (let j = 0; j < validateAsNo.length; j++) {
            if (validateAsNo.indexOf(FieldsToValidate[i]) != -1 && data[FieldsToValidate[i]] <= 0) {
              err[FieldsToValidate[i]] = "Invalid number"
            }
          }
        }
      }
    }
    setErrorData(err)
    if (!Object.keys(err).length) {
      setshowLoader(true)

      if (type === "sendoffer") {
        call('POST', 'raiseOfferAgainstBookingQuery', { id: data.tbl_booking_id, offerDetailsObj: data, userId }).then((result) => {
          // console.log("raiseOfferAgainstBookingQuery", result);
          toastDisplay(result, "success")
          closePopup()
          setshowLoader(false);
        }).catch((e) => {
          // console.log('error in raiseOfferAgainstBookingQuery', e);
          toastDisplay(e, "error")
          setshowLoader(false);
        })
      }

      // if (type === "update" || type === "agree" || type === "update&agree") {
      else {
        call('POST', 'updateShipmentOffer', {
          userTypeId, type, userId, offerId: data.offer_id, offerDetailsObj: data,
          updatedAt: moment.utc(new Date()).format('YYYY-MM-DD hh:mm:ss')
        }).then((result) => {
          // console.log("updateShipment", result);
          toastDisplay(result, "success")
          closePopup()
          setshowLoader(false);
        }).catch((e) => {
          // console.log('error in updateShipment', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false);
        })
      }
    }
  }

  const setOfferData = (index) => {
    setOfferTab(index)
    setdata({ ...shipmentData[index].offer_details_obj, "offer_id": shipmentData[index].id })
  }

  const getOfferStatusLabel = data => {
    // console.log("getOfferStatusLabel", data);
    if (!data.updated_at) {
      return "Offer Received"
    }
    if (data.agreed_by_trader && data.agreed_by_shipper) {
      return "Shipment locked"
    }
    if (data.agreed_by_trader) {
      if (data.updated_by == userId) {
        return "Updated & Agreed by you"
      }
      else {
        return "Agreed by you"
      }
    }
    if (data.agreed_by_shipper) {
      if (data.updated_by && data.updated_by != userId) {
        return "Updated & Agreed by shipping company"
      }
      else {
        return "Agreed by shipping company"
      }
    }
    if (data.updated_by == userId) {
      return "Updated by you"
    }
    else {
      return "Updated by shipping company"
    }
  }

  const getShipperOfferStatusLabel = data => {
    // console.log("getShipperOfferStatusLabel", data);
    if (!data.updated_at) {
      return "Offer Sent"
    }
    if (data.agreed_by_trader && data.agreed_by_shipper) {
      return "Shipment locked"
    }
    if (data.agreed_by_shipper) {
      if (data.updated_by == userId) {
        return "Updated & Agreed by you"
      }
      else {
        return "Agreed by you"
      }
    }
    if (data.agreed_by_trader) {
      if (data.updated_by && data.updated_by != userId) {
        console.log("debugging", data);
        return "Updated & Agreed by trader company"
      }
      else {
        return "Agreed by trader company"
      }
    }
    if (data.updated_by == userId) {
      return "Updated by you"
    }
    else {
      return "Updated by trader company"
    }
  }


  return (
    <div className={"modal show"} id="negotiationPopup">
      <div className="modal-dialog d-flex modal-xl border-inner" id="parent_class">

        <div className="modal-content">
          <div className="modal-header primary">
            <h4 className="modal-title text-white">{popupTypeLabel[popupType]}</h4>
            <button type="button" className="close" onClick={closePopup}>×</button>
          </div>

          <div className="modal-body calc-modal modal-view">
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div className="calc-inner-modal">

              {traderTypeIds.includes(userTypeId) ? (
                <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                  {shipmentData.map((item, index) => (
                    <li>
                      <a className={"nav-link pl-4 pr-4 cursor-pointer " + (offerTab === index ? " active show" : "")} onClick={() => {
                        setOfferData(index)
                      }}>{"Offer " + (index + 1) + " | " + shipmentData[index].company_name + " | "
                        + shipmentData[index].offer_details_obj.price + " $"} </a>
                    </li>
                  ))}
                </ul>
              ) : null
              }

              <div className='row mx-2'>
                {isFieldVisible("origin_country") && (
                  <>
                    {/* <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Origin Country {astrix} </label>
                        <div className="col-md-12">
                          <select disabled className="form-control" name={"origin_country"}
                            onChange={handleChange} value={data.origin_country}
                          >
                            <option value="" selected>--Select--</option>
                            {country.map((item) => {
                              return (<option value={item.name}>{item.name}</option>)
                            })}
                          </select>
                          {errorData.origin_country && <p className="text-danger error-contract">{errorData.origin_country}</p>}
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Origin Port {astrix} </label>
                        <div className="col-md-12">
                          <select className="form-control" name={"origin_port"}
                            onChange={handleChange} value={data.origin_port}
                          >
                            <option value="" selected>--Select--</option>
                            {ports.origin.map((item) => {
                              return (<option value={item.name}>{item.name}</option>)
                            })}
                          </select>
                          {errorData.origin_port && <p className="text-danger error-contract">{errorData.origin_port}</p>}
                        </div>
                      </div>
                    </div>


                    {/* <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Destination Country {astrix} </label>
                        <div className="col-md-12">
                          <select disabled className="form-control" name={"destination_country"}
                            onChange={handleChange} value={data.destination_country}
                          >
                            <option value="" selected>--Select--</option>
                            {country.map((item) => {
                              return (<option value={item.name}>{item.name}</option>)
                            })}
                          </select>
                          {errorData.destination_country && <p className="text-danger error-contract">{errorData.destination_country}</p>}
                        </div>
                      </div>
                    </div> */}


                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Destination Port {astrix} </label>
                        <div className="col-md-12">
                          <select className="form-control" name={"destination_port"}
                            onChange={handleChange} value={data.destination_port}
                          >
                            <option value="" selected>--Select--</option>
                            {ports.destination.map((item) => {
                              return (<option value={item.name}>{item.name}</option>)
                            })}
                          </select>
                          {errorData.destination_port && <p className="text-danger error-contract">{errorData.destination_port}</p>}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {isFieldVisible("container_type") && (
                  <>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Container Type {astrix} </label>
                        <div className="col-md-12">
                          <select className="form-control" name={"container_type"}
                            onChange={handleChange} value={data.container_type}
                          >
                            <option value="" selected>--Select--</option>
                            {containerTypeDD.map((item) => {
                              return (<option value={item.name}>{item.name}</option>)
                            })}
                          </select>
                          {errorData.container_type && <p className="text-danger error-contract">{errorData.container_type}</p>}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Container No {astrix} </label>
                        <div className="col-md-12">
                          <input type="number" className="form-control" name={"container_no"}
                            onChange={handleChange} value={data.container_no}
                          />
                          {errorData.container_no && <p className="text-danger error-contract">{errorData.container_no}</p>}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {isFieldVisible("cargo_type") && (
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Cargo Type {astrix} </label>
                      <div className="col-md-12">
                        <select className="form-control" name={"cargo_type"}
                          onChange={handleChange} value={data.cargo_type}
                        >
                          <option value="" selected>--Select--</option>
                          {cargoTypeDD.map((item) => {
                            return (<option value={item.name}>{item.name}</option>)
                          })}
                        </select>
                        {errorData.cargo_type && <p className="text-danger error-contract">{errorData.cargo_type}</p>}
                      </div>
                    </div>
                  </div>
                )}

                {isFieldVisible("start_date") && (
                  <>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Start Date (yyyy-mm-dd) {astrix} </label>
                        <div className="col-md-12">
                          <input type="date"
                            className="form-control fs-6 p-3" name="start_date" value={data.start_date}
                            id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Date"
                            required onChange={handleChange} />
                          {errorData.start_date && <p className="text-danger error-contract">{errorData.start_date}</p>}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">End Date (yyyy-mm-dd) {astrix} </label>
                        <div className="col-md-12">
                          <input type="date"
                            className="form-control fs-6 p-3" name="end_date" value={data.end_date}
                            id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Date"
                            required onChange={handleChange} />
                          {errorData.end_date && <p className="text-danger error-contract">{errorData.end_date}</p>}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {isFieldVisible("load_volume") && (
                  <>
                    {/* <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Load Volume (cubic meter) {astrix} </label>
                        <div className="col-md-12">
                          <input type="number" className="form-control" name={"load_volume"}
                            onChange={handleChange} value={data.load_volume}
                          />
                          {errorData.load_volume && <p className="text-danger error-contract">{errorData.load_volume}</p>}
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Load Weight (Kg) {astrix} </label>
                        <div className="col-md-12">
                          <input type="number" className="form-control" name={"load_weight"}
                            onChange={handleChange} value={data.load_weight}
                          />
                          {errorData.load_weight && <p className="text-danger error-contract">{errorData.load_weight}</p>}
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

                {isFieldVisible("vessel_type") && (
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Vessel Type {astrix} </label>
                      <div className="col-md-12">
                        <select className="form-control" name={"vessel_type"}
                          onChange={handleChange} value={data.vessel_type}
                        >
                          <option value="" selected>--Select--</option>
                          {vesselTypeDD.map((item) => {
                            return (<option value={item.name}>{item.name}</option>)
                          })}
                        </select>
                        {errorData.vessel_type && <p className="text-danger error-contract">{errorData.vessel_type}</p>}
                      </div>
                    </div>
                  </div>
                )}

                {isFieldVisible("commodity_type") && (
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Commodity Type {astrix} </label>
                      <div className="col-md-12">
                        <select className="form-control" name={"commodity_type"}
                          onChange={handleChange} value={data.commodity_type}
                        >
                          <option value="" selected>--Select--</option>
                          {commCategorys.map((item) => {
                            return (<option value={item.category}>{item.category}</option>)
                          })}
                        </select>
                        {errorData.commodity_type && <p className="text-danger error-contract">{errorData.commodity_type}</p>}
                      </div>
                    </div>
                  </div>
                )}

                {isFieldVisible("flexibility_days") && (
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Flexibility Days {astrix} </label>
                      <div className="col-md-12">
                        <input type="number" className="form-control" name={"flexibility_days"}
                          onChange={handleChange} value={data.flexibility_days}
                        />
                        {errorData.flexibility_days && <p className="text-danger error-contract">{errorData.flexibility_days}</p>}
                      </div>
                    </div>
                  </div>
                )}

                {isFieldVisible("price") && (
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Price ($) {astrix} </label>
                      <div className="col-md-12">
                        <input type="number" className="form-control" name={"price"}
                          onChange={handleChange} value={data.price}
                        />
                        {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                      </div>
                    </div>
                  </div>
                )}

                {isFieldVisible("offer_valid_till") && (
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Offer Valid Till (yyyy-mm-dd) {astrix} </label>
                      <div className="col-md-12">
                        <input type="date"
                          disabled={traderTypeIds.includes(userTypeId)}
                          className="form-control fs-6 p-3" name="offer_valid_till" value={data.offer_valid_till ? data.offer_valid_till : ""}
                          id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Date"
                          required onChange={handleChange} />
                        {errorData.offer_valid_till && <p className="text-danger error-contract">{errorData.offer_valid_till}</p>}
                      </div>
                    </div>
                  </div>
                )}

                {contactUserDetails.email_id && (
                  <>
                    <h5 className='col-md-12 my-4'>Contact Details</h5>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <h6 className="col-md-12">Contact Person Name</h6>
                        <label className="col-md-12">{contactUserDetails.contact_person}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <h6 className="col-md-12">Company Name</h6>
                        <label className="col-md-12">{contactUserDetails.company_name}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <h6 className="col-md-12">User Address</h6>
                        <label className="col-md-12">{contactUserDetails.user_address}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <h6 className="col-md-12">Contact Number</h6>
                        <label className="col-md-12">{contactUserDetails.contact_number}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <h6 className="col-md-12">Email Id</h6>
                        <label className="col-md-12">{contactUserDetails.email_id}</label>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {popupType != 0 ? (
                <div className="row mx-4 bg-primary text-white py-2 my-2">
                  <label className="form-check-label col-md-4">{(traderTypeIds.includes(userTypeId) ? "Received On : " : "Sent On : ")
                    + moment(shipmentData[offerTab].created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  {shipmentData[offerTab].updated_at ? (
                    <label className="form-check-label col-md-4">{"Updated On : " + moment(shipmentData[offerTab].updated_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  ) : null}
                  {traderTypeIds.includes(userTypeId) ? (
                    <label className=" form-check-label col-md-4">{"Status : " + (getOfferStatusLabel(shipmentData[offerTab]))}</label>
                  ) : (
                    <label className=" form-check-label col-md-4">{"Status : " + (getShipperOfferStatusLabel(shipmentData[offerTab]))}</label>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="modal-footer primary">
            <button type="button" className="btn btn-dark btn-sm" onClick={closePopup} id="previd">Cancel</button>
            {popupType === 0 ? (
              <button type="button" className="btn btn-info btn-sm" onClick={() => validateFields("sendoffer")} id="nextid">{popupTypeLabel[popupType]}</button>
            ) : null}
            {traderTypeIds.includes(userTypeId) && !(data.offer_valid_till && data.offer_valid_till >= moment(new Date()).format("YYYY-MM-DD")) ? (
              <button type="button" disabled className="btn btn-danger btn-sm" id="nextid">{"Offer Expired"}</button>
            ) : (
              <>
                {popupType === 2 ? (
                  <>
                    <button type="button" className="btn btn-info btn-sm" onClick={() => validateFields("update")} id="nextid">{"Update"}</button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => validateFields("agree")} id="nextid">{"Agree"}</button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => validateFields('update&agree')} id="nextid">{"Update & Agree"}</button>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div >
  )
}

export default NegotiationPopup;