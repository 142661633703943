import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, GetCache, getDocDetails, handleFileWithAsyncAwait, mapOrganizationTypeWithKarza, most_used_currencies, SetCache, toolTip } from '../../utils/myFunctions';
import { InputForTable, InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import ViewBuyerDetails from './viewSupplierDetails';
import CheckBuyerHealth from './checkBuyerHealth';
import SignDocument from '../InvoiceDiscounting/components/SignDocument';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import Filter from "../InvoiceDiscounting/components/Filter";
import AddBuyerHealth from './AddBuyerHealth';
import FindBuyers from './FindBuyers';
import { useHistory } from "react-router";
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import ViewSupplierDetails from './viewSupplierDetails';
import Papa from 'papaparse';
import NewTablev2 from '../../utils/newTablev2';
import { Action } from '../myCounterPartComp/action';
import ViewSupplierDetailsCP from './ViewSupplierDetailsCP';

const tutorialPopupCSS = {
  "0": {
    stageName: "Buyer Management",
    showNext: true,
    showBack: false,
    popup: {
      "top": "2rem",
      "right": "-20rem"
    },
    arrow: {
      "top": "11rem",
      "zIndex": 10,
      transform: "rotate(236deg)",
      left: "48.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>View all your buyer & their details by clicking on "Buyer Management".</span></label>
  },
  "1": {
    stageName: "Buyer Management",
    showNext: false,
    showBack: true,
    popup: {
      top: '14rem',
      right: '-4rem'
    },
    arrow: {
      top: "9rem",
      zIndex: 10,
      transform: "rotate(373deg)",
      left: "54.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Click here to add new buyer</span></label>
  },
  "2": {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: '-1rem',
      right: '-8rem'
    },
    arrow: {
      "top": "8rem",
      "zIndex": 100001,
      transform: "rotate(262deg)",
      left: '32.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Enter buyer name and country & click on continue button.</span></label>
  },
  "3": {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: '-1rem',
      right: '-8rem'
    },
    arrow: {
      "top": "8rem",
      "zIndex": 100001,
      transform: "rotate(262deg)",
      left: '32.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Select a DUNS No. & click on continue button.</span></label>
  },
  "4": {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: '-1rem',
      right: '-8rem'
    },
    arrow: {
      "top": "8rem",
      "zIndex": 100001,
      transform: "rotate(262deg)",
      left: '32.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Fill buyer details & click on continue button.</span></label>
  },
  "5": {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: '-1rem',
      right: '-8rem'
    },
    arrow: {
      "top": "8rem",
      "zIndex": 100001,
      transform: "rotate(262deg)",
      left: '32.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Attach all documents & click on continue button.</span></label>
  },
  "6": {
    stageName: "Add Buyer",
    showNext: false,
    showBack: false,
    popup: {
      top: '-1rem',
      right: '-8rem'
    },
    arrow: {
      "top": "8rem",
      "zIndex": 100001,
      transform: "rotate(262deg)",
      left: '32.5%'
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Review all form details, click on check box & click on Add Buyer button.</span></label>
  },
}




const reviewForm = [
  { "name": "Contact Person Name", val: "exporterName" },
  { "name": "Company Name", val: "exporterCompanyName" },
  { "name": "Country", val: "exporterCountry" },
  { "name": "GST No", val: "gstNo" },
  { "name": "CIN No", val: "cinNo" },
  { "name": "IEC No", val: "iecNo" },
  { "name": "PAN No", val: "panNo" },
  { "name": "Udyam No", val: "urn" },
  { "name": "Email Id", val: "exporterEmail" },
  { "name": "Contact Number:", val: "exporterContact", unit: "exporterPhoneCode" },
  { "name": "Address", val: "exporterAddress" },
  { "name": "Website", val: "exporterWebsite" },
  { "name": "Previous year annual sales", val: "exporterPrevSale", unit: "exporterCurrency" },
  { "name": "Expected current year’s annual sale", val: "exporterExpectedSale", unit: "exporterCurrency" },
  { "name": "Terms of Payment", val: "exporterTermsPayment" },
  { "name": "Product Details", val: "exporterProductDetails" },
  { "name": "HSN Code", val: "exporterHsnCode" },
  { "name": "Date of Registration", val: "dateOfRegistration" },
  { "name": "Date of Commencement", val: "dateOfCommencement" },
  { "name": "Date of Incorporation", val: "dateOfIncorporation" },
  { "name": "Supplier Type", val: "type" },
  { "name": "Organization Type", val: "organizationType" },
]

// const financierData = [
//   { id: 100, name: "Stenn", icon: "stenn.png", rating: 4.6, reviews: 126 },
//   { id: 200, name: "Modifi", icon: "modifi.png", rating: 1, reviews: 130 },
// ]

export const IncoTerms = [
  { name: "EXW" },
  { name: "FCA" },
  { name: "FAS" },
  { name: "FOB" },
  { name: "CFR" },
  { name: "CIF" },
  { name: "CPT" },
  { name: "CIP" },
  { name: "DAP" },
  { name: "DPU" },
  { name: "DDP" }
]


const ExporterManagement = ({ userTokenDetails, navToggleState, cpView }) => {
  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  const editBuyer = queryParams.get("editBuyer")
  let serarchParam = queryParams.get('search')
  const buyerAction = queryParams.get("action")
  const viewBuyerId = queryParams.get("viewBuyerId")
  const viewUserId = queryParams.get("viewUserId")
  const viewUserTypeId = queryParams.get("viewUserTypeId")


  let cache_data = GetCache("add_exporter_form_data");

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(buyerAction === 'addNewBuyer')
  const [checkBuyerHealth, setCheckBuyerHealth] = useState({ show: false, data: {} })
  const [viewSupplierDetails, setViewSupplierDetails] = useState({ show: false, data: {} })
  const [tab, setTab] = useState(0)
  const [data, setData] = useState(
    { selecterAction: 'Get by Mail', serviceType: 'Free', selectedFinanciers: [], nameTitle: "Mr", exporterPhoneCode: "91", exporterCurrency: "INR", exporterCountry: 'IN' })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [applyLimitPopup, setapplyLimitPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [signdoc, setSigndoc] = useState(false);
  const [totalShipments, setTotalShipments] = useState(0)
  const [page, setPage] = useState(1)
  const [filterData, setFilterData] = useState({})

  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 2)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [newBuyerId, setnewBuyerId] = useState('')
  const [annualTurnover, setAnnualTurnover] = useState('Less than 250 Cr')
  const [addBuyerhealth, setaddbuyerhealth] = useState({ show: false, data: {} })
  const [findMoreBuyers, setfindMoreBuyers] = useState(false)
  const [filteredSearch, setFilteredSearch] = useState([])
  const [csvTemplateData, setCSVTemplateData] = useState([])
  const [selecter, toggleSelecter] = useState({ show: false, data: {} })
  const [action, setAction] = useState({ show: false, index: null })
  let [tableColumns, setTableColumns] = useState([
    { subColumns: "Contact Person Name", },
    { subColumns: "Company NameE", },
    { subColumns: "Email Id", },
    { subColumns: "Contact No", },
    { subColumns: "Entity Type", },
    { subColumns: "Udyam No", },
    { subColumns: "Action", }
  ])

  let [userRefCode, setUserRefCode] = useState("")

  const [panArr, setPanArr] = useState([])
  const [showPopup, togglePopup] = useState(false)
  let [paymentDetails, setPaymentDetails] = useState({})
  let [cc, setCC] = useState([])
  let [bcc, setBCC] = useState([])
  let [mailToUsers, setMailToUsers] = useState([])
  let [mailContentPopup, setMailContentPopup] = useState({
    show: false, subject: 'Request for UDYAM No.',
    message: `Dear Sir/Madam,\n\nIf your Firm/Company is registered under MSMED Act, 2006 please send us a copy of the registration certificate before 27th March 2024 at _______\nWe require your UDYAM No. for the underwriting process. Kindly reply to this email with the UDYAM No._______ (e.g. UDYAM-MH-00-0000000)\nIf copy of registration certificate is not received before said date, it is assumed that provision of MSMED Act, 2006 are not applicable to your Company/Firm.\nPlease find the attached declaration for MSMED Confirmation.\n\nWarm Regards,\n${userTokenDetails.userName}\n\nNote: - While replying to the mail, don't remove message id from the mail subject`,
    attachments: [null]
  })

  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const ttvExporterCode = userToken.ttvExporterCode ? userToken.ttvExporterCode : ''
  const subUserName = userToken?.sub_user_name
  const mainUserName = userToken?.main_user_name

  const addBuyerTabs = [
    { name: "Enter KYC Details" },
    { name: userTypeId / 1 == 20 ? "Business Details" : "Supplier Details" },
    { name: "Review" }
  ]

  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("nwManagementExporter");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    call('POST', 'getuserprofiledata', { 'email': userEmail, 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      setUserRefCode(result?.refercode)
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    })

    call('POST', 'getSupplierListFilters', { userId }).then(res => {
      console.log("getSupplierListFilters then", res);
      setFilterData(res)
    }).catch(err => { })
  }, [])

  useEffect(() => {
    if (editBuyer && dbData.length) {
      onEdit(0)
    }
    if (viewBuyerId && dbData.length) {
      onView(0)
    }
  }, [editBuyer, dbData, viewBuyerId])

  async function asyncUseEffectFunction() {
    let dataToSet = {}
    if (data.iecNo && data.iecArr?.length) {
      let addressComponents = {}
      let selectedIecDetails = data.iecArr.filter((i, j) => {
        if (i.iec === data.iecNo) {
          return i
        }
      })?.[0]
      if (selectedIecDetails?.address?.length) {
        addressComponents = await call('POST', 'getAddressComponents', { address: selectedIecDetails.address })
      }
      let directorArr = selectedIecDetails?.directors || []
      directorArr.push({ name: "Other" })
      dataToSet = {
        ...data,
        exporterCompanyName: selectedIecDetails.name,
        exporterAddress: selectedIecDetails.address,
        exporterCity: addressComponents.city,
        "exporterState": addressComponents.state,
        "exporterPostalCode": addressComponents.postalCode,
        "exporterCountry": addressComponents.countrySortName,
        exporterContact: selectedIecDetails.contact || "",
        exporterEmail: selectedIecDetails.email || "",
        directorArr,
        contactPersonDropDown: directorArr?.[0]?.["name"] || "",
        exporterName: directorArr?.[0]?.["name"] || ""
      }
    }
    else {
      dataToSet = {
        ...data,
        directorArr: [{ name: "Other" }]
      }
    }
    setData(dataToSet)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [data.iecNo])

  const getSupplierDetails = () => {
    //Get Buyer list start
    let objectAPI = {
      "userId": viewUserId || userId,
      "userEmail": userEmail,
      "userTypeId": viewUserTypeId || userTypeId,
      "type": 'all',
      currentPage: page,
      exactMatchId: editBuyer || viewBuyerId || undefined,
      ...filter,
      ttvExporterCode: ttvExporterCode
    }
    setshowLoader(true)
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(element.accordianId === "hsCodes" ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getSuppliersInNetwork', objectAPI).then((result) => {
      setshowLoader(false)
      console.log('running getBuyersDetail api-->', result);
      setDbData(result.data);
      // setTableData(formatDataForTable(result.data))
      setCount(result.count);
    }).catch((e) => {
      // console.log('error in getSuppliersInNetwork', e);
      setshowLoader(false)
    });

  }

  useEffect(() => {
    getSupplierDetails()
  }, [page, refresh, filterData])

  useEffect(() => {
    call('POST', 'getTotalShipmentsCount', { userId, ttvExporterCode }).then((result) => {
      console.log('running getTotalShipmentsCount api-->', result);
      setTotalShipments(result)
    }).catch((e) => {
      // console.log('error in getTotalShipmentsCount', e);
    });
    // Get master data start
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    call('get', 'getHsnCodes').then((result) => {
      setHsnCodes(result)
    }).catch((e) => {
      console.log('error in getHsnCodes', e);
    })
    call("POST", "getLenderDetails").then((result) => {
      setFinancierData(result);
    }).catch((e) => {
      console.log("error in getLenderDetails", e);
    });
    // Get master data end
  }, [refresh])

  async function onDelete(index) {
    setshowLoader(true)
    call('POST', 'removeSupplierFromNetwork', { userId, supplierId: dbData[index]["tbl_user_id"] }).then((result) => {
      toastDisplay("User removed successfully", "success");
      setRefresh(refresh + 1)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in removeSupplierFromNetwork', e);
      toastDisplay(e, "error");
      setshowLoader(false)
    })
  }

  async function onEdit(index) {
    setViewSupplierDetails({ show: false, data: {} })
    toggleAddBuyerForm(true)
    setshowLoader(true)
    let selectedSupplier = dbData[index]
    setshowLoader(false)
    let { urn, dateOfCommencement, dateOfIncorporation, dateOfRegistration,
      type } = selectedSupplier?.company_urn_result || {}
    setData({
      exporterId: selectedSupplier.tbl_user_id,
      nameTitle: selectedSupplier.name_title,
      exporterName: selectedSupplier.contact_person,
      exporterCompanyName: selectedSupplier.company_name,
      exporterCountry: selectedSupplier.country_code,
      exporterEmail: selectedSupplier.email_id,
      exporterContact: selectedSupplier.contact_number,
      exporterPhoneCode: selectedSupplier.phone_code,
      exporterCurrency: selectedSupplier.currency,
      exporterHsnCode: selectedSupplier.exporterHsnCode ? selectedSupplier.exporterHsnCode.split(",") : null,
      exporterWebsite: selectedSupplier.website,
      exporterPrevSale: selectedSupplier.prevSale,
      exporterExpectedSale: selectedSupplier.annualSale,
      exporterTermsPayment: selectedSupplier.termsOfPayment,
      exporterProductDetails: selectedSupplier.productDetail,
      gstNo: selectedSupplier.gst_vat_no,
      iecNo: selectedSupplier.iec_no,
      cinNo: selectedSupplier.cin_no,
      panNo: selectedSupplier.pan_no,
      urn, dateOfCommencement, dateOfIncorporation, dateOfRegistration,
      type,
      isEditing: true,
      tcAccepted: true
    })
  }

  async function onView(index) {
    let selectedSupplier = dbData[index]
    setshowLoader(true)
    setshowLoader(false)
    let { urn, dateOfCommencement, dateOfIncorporation, dateOfRegistration,
      type } = selectedSupplier?.company_urn_result || {}

    setViewSupplierDetails({
      show: true, data: {
        ...selectedSupplier, index,
        urn, dateOfCommencement, dateOfIncorporation, dateOfRegistration,
        type
      }
    })
  }

  function onCheckBuyerHealthClick(duns, index) {
    setshowLoader(true)
    call('POST', 'getBuyerHealth', { duns }).then((result) => {
      setshowLoader(false)
      setCheckBuyerHealth({ show: true, data: result })
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
      //setaddbuyerhealth({ show: true, data: dbData[index] })
      toastDisplay(e, "error")
    });
  }


  // function formatDataForTable(data) {
  //   let tableData = []
  //   let row = []
  //   data.forEach((item, index) => {
  //     let hsnCodes = item.exporterHsnCode ? item.exporterHsnCode.split(",") : []
  //     row[0] = item.name_title + " " + item.contact_person
  //     row[1] = item.company_name || "-"
  //     // row[2] = <div
  //     //   className='flex-row'
  //     // >
  //     //   {hsnCodes.length ? hsnCodes.map((item, index) => {
  //     //     return (
  //     //       <label className='bg-color1 p-1 mx-1 border-radius-5' >{item}</label>
  //     //     )
  //     //   }) : "NA"}
  //     // </div>
  //     row[2] = item.email_id
  //     row[3] = item.contact_number ? (item.phone_code + " " + item.contact_number) : "-"
  //     row[4] = item.company_urn_result?.type || "-"
  //     row[5] = item.company_urn_result?.urn || "-"
  // if (selecter.show) {
  //   row.unshift(<div>
  //     <img className='cursor' onClick={() => {
  //       let tempMailToUsers = mailToUsers
  //       if (mailToUsers.includes(item.tbl_user_id)) {
  //         tempMailToUsers.remove(item.tbl_user_id)
  //         setMailToUsers([...tempMailToUsers])
  //       }
  //       else {
  //         tempMailToUsers.push(item.tbl_user_id)
  //         setMailToUsers([...tempMailToUsers])
  //       }
  //     }} src={
  //       `assets/images/${mailToUsers.includes(item.tbl_user_id) ? 'checked-green' : 'empty-check'
  //       }.png`
  //     } />
  //   </div>);
  // }
  //     tableData.push(row)
  //     console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrr", row);
  //     row = []
  //   })
  //   return tableData
  // }

  useEffect(() => {
    if (!data.isEditing) {
      // SetCache('add_exporter_form_data', {
      //   data: {
      //     ...data, invoiceDocument: null, poDocument: null, docToSign: {}
      //   }
      // })
    }
  }, [data])

  async function handleSelectAllForMailToUsers() {
    let tempMailToUsers = []
    if (selecter.selectAll) {
      setMailToUsers([])
    }
    else {
      for (let index = 0; index < dbData.length; index++) {
        const element = dbData[index];
        if (!element.company_urn_result?.urn) {
          tempMailToUsers.push(element.email_id)
        }
      }
    }
    setMailToUsers([...tempMailToUsers])
    toggleSelecter({ show: true, selectAll: !selecter.selectAll })
  }

  useEffect(() => {
    let tempSelecter = [
      { subColumns: "Contact Person Name", },
      { subColumns: "Company Name", },
      { subColumns: "Email Id", },
      { subColumns: "Contact No", },
      { subColumns: "Entity Type", },
      { subColumns: "Udyam No", },
      { subColumns: "Action", }
    ]
    if (selecter.show) {
      setTableColumns([{
        subColumns: !selecter.selectAll ? 'Select All' : "Unselect All",
        subColumnsOnClick: () => handleSelectAllForMailToUsers()
      }, ...tempSelecter])
    }
    else {
      setTableColumns([...tempSelecter])
    }
  }, [selecter.show, mailToUsers])

  // Listen for changes in the local storage
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'paymentStatus' && event.newValue === "success") {
        localStorage.clear("paymentStatus")
        if (csvTemplateData.length) {
          saveCSVData()
          toastDisplay("Payment completed successfully", "success")
          setPaymentDetails({})
        }
        else {
          setTab(tab + 1)
          toastDisplay("Payment completed successfully", "success")
          setPaymentDetails({})
        }
      }
      if (event.key === 'paymentStatus' && event.newValue === "failed") {
        localStorage.clear("paymentStatus")
        toastDisplay("Payment didn't completed, you can try again", "info")
        setPaymentDetails({ ...paymentDetails, linkId: undefined })
      }
    };
    window.addEventListener('storage', handleStorageChange);
    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  const handleChange = async (e) => {
    e.persist()
    if (e.target.name === "contactPersonDropDown") {
      if (e.target.value === "Other") {
        setData({ ...data, exporterName: "", [e.target.name]: e.target.value })
      }
      else {
        setData({ ...data, exporterName: [e.target.value], [e.target.name]: e.target.value })
      }
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value })
      setErrors({ ...errors, [e.target.name]: "" })
    }
  }

  const saveCSVData = async () => {
    let tempErrObj = {}
    let nullCheckFor = [`Contact Person*`, `Company Name*`, 'GST No*', 'Email Id*']
    for (let index = 0; index < csvTemplateData.length; index++) {
      const element = csvTemplateData[index];
      for (let j = 0; j < nullCheckFor.length; j++) {
        if (element[`Company Name*`]) {
          if (!element[nullCheckFor[j]]) {
            tempErrObj[`${nullCheckFor[j]}${index}`] = `Mandatory Field`
          }
          else if (element[`Email Id*`]) {
            let userApiResp = await call('POST', 'isUserAlredyExists', { emailId: element[`Email Id*`] })
            if (userApiResp) {
              // tempErrObj[`Email Id*${index}`] = `Email id already exists`
              element[`userIdToOnboard`] = userApiResp
            }
            else {
              element[`userIdToOnboard`] = null
            }
          }
        }
      }
    }
    if (!Object.keys(tempErrObj)?.length) {
      let paymentDetails = { service: [] }
      setshowLoader(true)
      for (let index = 0; index < csvTemplateData.length; index++) {
        const element = csvTemplateData[index];
        if (!element[`operationStatus`]) {
          if (element[`Company Name*`]) {
            if (element[`GST No*`] && !element[`Udyam No`] && data.serviceType === "Paid") {
              try {
                let apiResp = await call('POST', 'getAndVerifyKYCV2', { "gst": element[`GST No*`], typeId: 19, bypassGSTCheck: true })
                if (apiResp) {
                  let addressComponents = {}
                  if (apiResp?.company_address?.length) {
                    addressComponents = await call('POST', 'getAddressComponents', { address: apiResp.company_address })
                  }
                  element[`IEC No`] = apiResp.iecArr?.[0]?.["iec"] || null
                  element[`PAN No`] = apiResp.pan || null
                  element[`CIN No`] = apiResp.cin || null
                  element[`Company Name*`] = apiResp.company_name || element[`Company Name*`]
                  element[`Address`] = apiResp.company_address || element[`Address`]
                  element[`Country Code(2 Digit)*`] = addressComponents.countrySortName || "IN"
                  element["kid"] = apiResp.kid
                }
              } catch (error) {
                console.log("errorrr", error);
              }
            }
            let udyamDetails = {}
            if (element[`kid`] || element[`Udyam No`]) {
              udyamDetails = await call('POST', 'getUdyamDetails', {
                kid: element[`kid`] || undefined,
                urnNo: element[`Udyam No`] || undefined
              })
              let { urn } = udyamDetails
              element[`udyamDetails`] = udyamDetails
              if (element[`GST No*`] && !element[`Udyam No`] && urn) {
                element[`Udyam No`] = urn
                paymentDetails = {
                  paymentRequired: true, amount: ((paymentDetails.amount || 0) + 50), currency: 'INR',
                  service: paymentDetails.service.concat([{ urn }])
                }
              }
            }
            setPaymentDetails(paymentDetails)
          }
        }
        csvTemplateData["operationStatus"] = true
      }
      let tempSendMailForUdyamNo = []
      if (!paymentDetails.paymentRequired) {
        for (let index = 0; index < csvTemplateData.length; index++) {
          const element = csvTemplateData[index];
          if (element[`Company Name*`]) {
            let reqObject = {
              "userIdToOnboard": element[`userIdToOnboard`],
              "typeId": 19,
              "cinDocumentName": element[`CIN No`],
              "gstDocumentName": element[`GST No*`],
              "iecDocumentName": element[`IEC No`],
              "panDocumentName": element[`PAN No`],
              "aadharDocumentName": null,
              companyName: element[`Company Name*`],
              contactPerson: element[`Contact Person*`],
              companyAddress: element[`Address`],
              email: element[`Email Id*`],
              "contactNo": element[`Contact No`],
              "gst_company_detail": null,
              "iec_company_detail": null,
              "cin_company_detail": null,
              "type": "finTech",
              "referralCode": userRefCode,
              "password": "Pa$$w0rd!",
              "passwordConfirm": "Pa$$w0rd!",
              "termsAndCondition": true,
              "country": element[`Country Code(2 Digit)*`],
              "industryType": "",
              "techType": 1,
              "companyCity": "",
              "companyPostalCode": "",
              "phoneCode": element[`Phone Code`],
              "nameTitle": "Mr",
              "companyState": "",
              "designation": "",
              "accountStatus": "inactive",

              "website": element[`Website`],
              "urn_company_detail": element[`udyamDetails`],
              "prevSale": element[`Previous Year Annual Sale`],
              "annualSale": element[`Expected Current Year Annual Sale`],
              "currency": element[`Currency Code(3 Digit)`],
              "termsOfPayment": element[`Terms of Payment`],
              "productDetail": element[`Product Details`],
              "exporterHsnCode": element[`HSN Code`] || ""
            }
            try {
              await call('POST', 'registration', reqObject)
            } catch (error) {
              console.log("errorInregistrationBulkUpload", error);
            }
            if ((data.serviceType === "Paid" || data.sendRequestForUdyamNoMail) && !element['Udyam No']) {
              tempSendMailForUdyamNo.push(element[`Email Id*`])
            }
          }
        }
        if (tempSendMailForUdyamNo?.length) {
          toastDisplay('Details saved', "success")
          setMailToUsers([...tempSendMailForUdyamNo])
          setMailContentPopup({ ...mailContentPopup, show: true })
        }
        else {
          toastDisplay('Details saved', "success", () => {
            if (cpView) {
              resetDataAndHideForm()
            }
            else {
              window.location.reload()
            }
          })
        }
      }
      setshowLoader(false)
    }
    setErrors({ ...tempErrObj })
  }

  const handleCSVData = async (event, index) => {
    event.persist()
    let tempCSVData = csvTemplateData
    tempCSVData[index][event.target.name] = event.target.value
    setCSVTemplateData([...tempCSVData])
    setErrors({ ...errors, [`${event.target.name}${index}`]: "" })
  }

  const handleCSV = (event) => {
    event.persist();
    if (!event.target.files.length) {
      return null
    }
    setErrors({ ...errors, [event.target.name]: "" });
    if (!event.target.files[0]['name'].includes("csv")) {
      setErrors({ ...errors, [event.target.name]: "Please select csv file" });
      return
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      Papa.parse(event.target.files[0], {
        complete: (result) => {
          console.log("csvParseComplete=======>", result);
          if (!result?.data?.length) {
            setErrors({ ...errors, [event.target.name]: "Uploaded CSV is empty" });
          }
          else {
            setCSVTemplateData([...result.data])
          }
          setData({ ...data, [event.target.name]: event.target.files[0] })
          setErrors({ ...errors, [event.target.name]: "" });
          togglePopup(false)
        },
        header: true
      });
    }
    reader.readAsText(event.target.files[0]);
  }


  async function fetchUserKYC() {
    setshowLoader(true)
    let tempData = { ...data }
    if (data.gstNo && !data.urn && data.serviceType === "Paid") {
      try {
        let apiResp = await call('POST', 'getAndVerifyKYCV2', { "gst": data.gstNo, typeId: 19, bypassGSTCheck: true })
        setshowLoader(false)
        if (apiResp) {
          let addressComponents = {}
          setshowLoader(true)
          if (apiResp?.company_address?.length) {
            addressComponents = await call('POST', 'getAddressComponents', { address: apiResp.company_address })
          }
          setshowLoader(false)
          let tempMultPanArr = []
          for (let index = 0; index < apiResp.multiplePans?.length; index++) {
            tempMultPanArr.push({ name: apiResp.multiplePans[index] })
          }
          setPanArr(tempMultPanArr)
          tempData = {
            ...tempData,
            kid: apiResp.kid,
            iecArr: apiResp.iecArr,
            organizationType: mapOrganizationTypeWithKarza(apiResp.organizationType),
            iecNo: apiResp.iecArr?.[0]?.["iec"] || null,
            panNo: apiResp.pan,
            cinNo: apiResp.cin || null,
            exporterCompanyName: apiResp.company_name,
            exporterAddress: apiResp.company_address,
            exporterCity: addressComponents.city,
            exporterPostalCode: addressComponents.postalCode,
            exporterCountry: addressComponents.countrySortName || "IN",
            exporterState: addressComponents.state
          }
          console.log("apiRespppppppppppppppppppppppp", apiResp, addressComponents, tempData);
        }
      } catch (error) {
        console.log("errorrr", error);
        setshowLoader(false)
        toastDisplay(error, "error")
        return null
      }
    }

    let udyamDetails = {}
    if (tempData.kid || tempData.urn) {
      udyamDetails = await call('POST', 'getUdyamDetails', {
        kid: tempData.kid || undefined,
        urnNo: tempData.urn || undefined
      })
      let { urn, dateOfCommencement, dateOfIncorporation, dateOfRegistration,
        type } = udyamDetails
      console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu", udyamDetails);
      tempData = {
        ...tempData, urn, dateOfCommencement, dateOfIncorporation, dateOfRegistration,
        type, udyamDetails
      }
      if (data.serviceType === "Paid" && urn) {
        setPaymentDetails({ paymentRequired: true, amount: 50, currency: 'INR', service: [{ urn }] })
      }
      else {
        setPaymentDetails({})
        setTab(tab + 1)
      }
    }
    else {
      setTab(tab + 1)
    }
    setData({ ...tempData })
    setshowLoader(false)
  }

  function getDunsList() {
    setData({
      ...data, selectedDuns: null,
      exporterAddress: "",
      exporterPostalCode: "", buyerDunsNo: ""
    })
    setshowLoader(true)
    call('POST', 'getDUNSNo', {
      companyName: data.exporterName, countryCode: data.exporterCountry,
      userId, userEmail
    }).then((result) => {
      setshowLoader(false)
      if (result.length > 0) {
        setDunsData(result);
        setTab(1)
      }
      else {
        toastDisplay("No Related Exporter Details Found", "error")
        setTab(2)
        setDunsData([])
      }
    })
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ['gstNo']
      // if (data.serviceType === "Free") {
      //   validateFields.push("urn")
      // }
    }
    if (tab === 1) {
      validateFields = ["nameTitle", "contactPersonDropDown", "exporterName", "exporterCompanyName", "exporterCountry", "exporterEmail"]
      if (data.exporterEmail) {
        let userApiResp = await call('POST', 'isUserAlredyExists', { emailId: data.exporterEmail })
        if (userApiResp) {
          // err[`exporterEmail`] = `Email id already exists`
          data[`userIdToOnboard`] = userApiResp
        }
        else {
          data[`userIdToOnboard`] = null
        }
      }
    }

    // if (tab === 2) {
    //   validateFields = ["exporterName", "exporterCountry", "exporterAddress", "exporterPostalCode",
    //     "exporterPrevSale", "exporterExpectedSale", "exporterTermsPayment", "exporterProductDetails"]
    // }

    // if (tab === 3) {
    //   validateFields = []
    // }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      // let redirectBackTo = localStorage.getItem("redirectBackTo")
      // if (redirectBackTo && tab == 2) {
      //   submitExporterDetails()
      //   return null
      // }
      if (tab != 2) {
        if (tab === 0) {
          // return getDunsList()
          return fetchUserKYC()
        }
        setTab(tab + 1)
      }
      else {
        submitExporterDetails()
      }
    }
    setErrors(err)
  }

  console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeee", errors);

  async function submitExporterDetails() {
    setshowLoader(true)
    let reqObject = {
      "userIdToOnboard": data.exporterId || data.userIdToOnboard || undefined,
      "typeId": 19,
      "cinDocumentName": data.cinNo,
      "gstDocumentName": data.gstNo,
      "iecDocumentName": data.iecNo,
      "panDocumentName": data.panNo,
      "aadharDocumentName": null,
      "organizationType": data.organizationType || "foreign",
      companyName: data.exporterCompanyName,
      contactPerson: data.exporterName,
      companyAddress: data.exporterAddress,
      email: data.exporterEmail,
      "contactNo": data.exporterContact,
      "gst_company_detail": null,
      "iec_company_detail": null,
      "cin_company_detail": null,
      "type": "finTech",
      "referralCode": userRefCode,
      "password": "Pa$$w0rd!",
      "passwordConfirm": "Pa$$w0rd!",
      "termsAndCondition": true,
      "country": data.exporterCountry,
      "industryType": "",
      "techType": 1,
      "companyCity": data.exporterCity,
      "companyPostalCode": data.exporterPostalCode,
      "phoneCode": data.exporterPhoneCode,
      "nameTitle": data.nameTitle,
      "companyState": data.exporterState,
      "designation": "",
      "accountStatus": "inactive",

      "website": data.exporterWebsite,
      "urn_company_detail": data.udyamDetails,
      "prevSale": data.exporterPrevSale,
      "annualSale": data.exporterExpectedSale,
      "currency": data.exporterCurrency,
      "termsOfPayment": data.exporterTermsPayment,
      "productDetail": data.exporterProductDetails,
      "exporterHsnCode": data.exporterHsnCode ? data.exporterHsnCode?.join(",") : ""
    }
    call('POST', 'registration', reqObject).then((result) => {
      setshowLoader(false)
      toastDisplay("Details saved", "success", () => {
        if (cpView) {
          resetDataAndHideForm()
        }
        else {
          window.location = '/nwManagementExporter'
        }
      })
      ClearCache("add_exporter_form_data");
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err || "Something went wrong", "error");
    })
  }

  function resetDataAndHideForm() {
    setData({ selecterAction: 'Get by Mail', serviceType: 'Free', selectedFinanciers: [], nameTitle: "Mr", exporterPhoneCode: "91", exporterCurrency: "INR", exporterCountry: 'IN' })
    toggleAddBuyerForm(false)
    setTab(0)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1)
    }
  };

  const onPurchasePlan = async (plan_id, plan_amount_usd, currency, shipper_id, booking_data) => {
    try {
      let reqObj = {
        plan_id,
        plan_amount_usd,
        userId,
        currency,
        forGeneric: true,
        shipper_id,
        booking_data,
        userEmail
      }
      const result = await call('POST', 'createPaymentlink', reqObj)
      window.open(`${result}&forGeneric=true`, '_blank')
      setPaymentDetails({ ...paymentDetails, linkId: result.split("?pid=")[1] })
    } catch (e) {
      console.log('error in createPaymentlink', e)
    }
  }

  function onMailTemplatePopupClose() {
    setMailContentPopup({ ...mailContentPopup, show: false })
  }

  const handleClick = () => {
    if (lcTutorial) {
      setTutorialStage(2);
    }
    console.log(booladd, "this in handleClick before toggleAddBuyerForm");
    if (booladd || userPermissions == null) {
      toggleAddBuyerForm(true);
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  };


  return (
    <>
      <TutorialPopup show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"]
        }} showSkip={true} userId={userId} showNext={tutorialPopupCSS[tutorialStage]["showNext"]}
        onNext={() => {
          setTutorialStage(tutorialStage + 1)
        }}
        onBack={() => {
          if (tutorialStage > 0) {
            setTutorialStage(tutorialStage - 1)
          }
          else {
            localStorage.setItem("lcTutorialStep", 1)
            window.location.reload()
          }
        }}
        showCustomButtonLabel={(tutorialStage == 1 && count > 0) ? "No new buyer to add" : null}
        onCustomButtonLabel={() => {
          toggleLCTutorial(false)
          localStorage.setItem("lcTutorialStep", 7)
          window.location.reload()
        }}
        showBack={tutorialPopupCSS[tutorialStage]["showBack"]} videoLinkLabel={"Watch detailed video about Buyer Management"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      {mailContentPopup.show && <FinanceInvoiceModal modalSize={'lg'} limitinvoice={mailContentPopup.show} setLimitinvoice={onMailTemplatePopupClose} closeSuccess={onMailTemplatePopupClose} >
        <div className="col-12">
          <label className='text-left font-wt-600 text-color1 font-size-16 w-100 text-center'>Send Mail</label>
          <label className='text-left font-wt-600 text-color1 font-size-14  w-100 text-center mb-3'>(To get Udyam No. from the client on mail and we will fetch entity type for you)</label>
          <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>To (add multiple email ids separared by comma)</label>
          <NewTextArea label={""} value={mailToUsers} onChange={(e) => {
            let emailIds = e.target.value?.trim()
            setMailToUsers(emailIds ? emailIds.split(",") : [])
          }} />
          <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>CC (add multiple email ids separared by comma)</label>
          <NewTextArea label={""} value={cc} onChange={(e) => {
            let emailIds = e.target.value?.trim()
            setCC(emailIds ? emailIds.split(",") : [])
          }} />
          <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>BCC (add multiple email ids separared by comma)</label>
          <NewTextArea label={""} value={bcc} onChange={(e) => {
            let emailIds = e.target.value?.trim()
            setBCC(emailIds ? emailIds.split(",") : [])
          }} />
          <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>Subject</label>
          <NewTextArea label={""} value={mailContentPopup.subject} onChange={(e) => {
            setMailContentPopup({ ...mailContentPopup, subject: e.target.value })
          }} />
          <label className='text-left font-wt-600 font-size-14 mb-2 w-100 '>Message</label>
          <NewTextArea rows={10} label={""} value={mailContentPopup.message} onChange={(e) => {
            setMailContentPopup({ ...mailContentPopup, message: e.target.value })
          }} />
          <label className='text-left font-wt-600 font-size-14 mb-3 w-100 '>Attachments</label>
          {mailContentPopup.attachments.map((i, j) => {
            if (i === null) {
              return (<div className='row w-100 d-flex align-items-center mb-3' >
                <div className='col-8' >
                  <FileInput multipleDragDropEnable={true}
                    onMultipleDragDrop={async (e) => {
                      let tempErr = errors
                      let tempData = data
                      for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                        let element = e.target.files[indexx];
                        let customEvent = {
                          target: { name: "mailAttachment" + ":" + (j + indexx), files: [element] },
                          persist: () => console.log("...")
                        }
                        let resp = await handleFileWithAsyncAwait(customEvent, setshowLoader)
                        if (resp.status / 1 == 0) {
                          tempErr = { ...tempErr, ...resp.msg }
                        }
                        if (resp.status / 1 == 1) {
                          tempData = { ...tempData, ...resp.msg }
                        }
                      }
                      setData(tempData)
                      setErrors(tempErr)
                      let temp = mailContentPopup.attachments
                      for (let index = 0; index < e.target.files.length - 1; index++) {
                        temp.push(null)
                      }
                      setMailContentPopup({ ...mailContentPopup, temp })
                    }}
                    name={"mailAttachment" + ":" + j} value={data["mailAttachment" + ":" + j]} error={errors["mailAttachment" + ":" + j]}
                    isEditable={true} onChange={handleFile}
                    onUploadCancel={() => setData({ ...data, ["mailAttachment" + ":" + j]: null })}
                  />
                  {errors["mailAttachment" + ":" + j] ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{errors["mailAttachment" + ":" + j]}</b></div> : ''}
                </div>
                <div
                  className="col-4"
                >
                  {(mailContentPopup.attachments.length - 1 > j) ?
                    <img
                      onClick={() => {
                        let temp = mailContentPopup.attachments
                        let tempUpdatedArr = []
                        for (let m = 0; m < temp.length; m++) {
                          if (m != j) {
                            tempUpdatedArr.push(null)
                          }
                        }
                        setMailContentPopup({ ...mailContentPopup, attachments: tempUpdatedArr })
                      }}
                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                    /> : <img
                      onClick={() => {
                        let temp = mailContentPopup.attachments
                        temp.push(null)
                        setMailContentPopup({ ...mailContentPopup, attachments: temp })
                      }}
                      style={{ width: '22px', height: '22px' }}
                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                </div>
              </div>)
            }
          })}
          <div>
            <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={async () => {
              if (mailToUsers.length && mailContentPopup.subject && mailContentPopup.message) {
                setshowLoader(true)
                let formData = new FormData()
                formData.append("userId", userId)
                formData.append("mailToUsers", mailToUsers)
                formData.append("content", mailContentPopup.message)
                formData.append("cc", cc)
                formData.append("bcc", bcc)
                formData.append("subject", mailContentPopup.subject)
                for (let index = 0; index < mailContentPopup.attachments.length; index++) {
                  if (mailContentPopup.attachments[index] === null && data[`mailAttachment:${index}`]?.name) {
                    formData.append(`mailAttachment:${index}`, data[`mailAttachment:${index}`])
                  }
                }
                await call('POST', 'sendMailToRequestUdyam', formData)
                setshowLoader(false)
                toastDisplay("Mail sent successfully", "success")
                setMailContentPopup({ ...mailContentPopup, show: false })
                setMailToUsers([])
                setCC([])
                setBCC([])
                toggleSelecter({ show: false })
                resetDataAndHideForm()
              }
              else {
                toastDisplay('Make sure to fill to, subject & message', "info")
              }
            }} type='button'>{"Send Mail"}</button>
          </div>
        </div>
      </FinanceInvoiceModal>}

      {showPopup && <FinanceInvoiceModal limitinvoice={showPopup} setLimitinvoice={togglePopup} closeSuccess={() => togglePopup(false)} >
        <div className="col-md-10 mb-2 ml-5">
          <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>Upload In Bulk</label>
          <FileInput
            onUploadCancel={() => { setData({ ...data, exporterAddTemplate: {} }) }} name={"exporterAddTemplate"} value={data["exporterAddTemplate"]}
            onChange={handleCSV}
            error={errors.exporterAddTemplate} isEditable={true} />
          {errors.exporterAddTemplate ? <div class="text-danger mt-2 font-size-12">
            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
            <b>{errors.exporterAddTemplate}</b></div> : ''}
        </div>
      </FinanceInvoiceModal>}

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showMsgPopup ? (
        <PopupMessage
          onClose={() => {
            toggleMsgPopup(false)
            if (data.isEditing) {
              window.location.reload()
            } else {
              setapplyLimitPopup(true)
            }
          }}
          title={data.isEditing ? 'Buyer details updated successfully' : 'New buyer details are successfully saved!'}
          msg={`Buyer details can be viewed & edited any time in "Buyer Management" section.`}
        />
      ) : null}
      {applyLimitPopup ? (
        <FinanceInvoiceModal limitinvoice={applyLimitPopup} closeSuccess={() => {
          setapplyLimitPopup(false)
          window.location.reload()
        }}>
          <div className='text-center'>
            <img src='/assets/images/Questionmark.png' />
            <p className='font-size-14 font-wt-400 mt-2'>Apply Limit Finance</p>
            <button className={`my-4 new-btn w-30 py-2 px-1 text-white cursor`} onClick={() => {
              window.location = `applyForLimit?buyer=${newBuyerId}`
            }}>Continue</button>
            <p className='font-size-14 font-wt-400 cursor' onClick={() => {
              window.location = '/buyerManagement'
            }}><u>Back to buyer management</u></p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          {cpView ? null :
            <SideBarV2 state="nwManagementExporter" userTokenDetails={userToken} />}
          <main role="main" className={` ml-sm-auto ${cpView ? ' col-lg-12 ' : ' col-lg-10 '} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {cpView ? null :
              <HeaderV2
                // title={`Buyer Management ${showAddBuyerForm ? ` > ${data.isEditing ? 'Edit Buyer' : 'Add Buyer'}` : ""}`}
                title={userTypeId / 1 == 20 ? "Business" : `Suppliers`}
                userTokenDetails={userToken} />}

            {checkBuyerHealth.show ? (
              <CheckBuyerHealth data={checkBuyerHealth.data} />
            ) : null}

            {addBuyerhealth.show ? (
              <AddBuyerHealth data={addBuyerhealth.data} countrydata={countrys} />
            ) : null}

            {viewSupplierDetails.show && userTypeId / 1 != 20 ? (
              <ViewSupplierDetails
                data={viewSupplierDetails.data} userTokenDetails={userTokenDetails} onEdit={onEdit} onDelete={onDelete} />
            ) : null}

            {viewSupplierDetails.show && userTypeId / 1 == 20 ? (
              <ViewSupplierDetailsCP disableSupplierView={() => {
                setViewSupplierDetails({ show: false, data: {} })
              }}
                data={viewSupplierDetails.data} userTokenDetails={userTokenDetails} onEdit={onEdit} onDelete={onDelete} />
            ) : null}

            {findMoreBuyers ? (
              <FindBuyers userTokenDetails={userTokenDetails} />
            ) : null}

            {!showAddBuyerForm ? (
              <>
                {!viewSupplierDetails.show && !checkBuyerHealth.show && !addBuyerhealth.show && !findMoreBuyers ? (
                  <div className='row'>
                    <div className='w-12'>
                      <div className='card h-100 dashboard-card shadow-sm'>
                        <label className='value font-wt-600'>{count}</label>
                        <label className='font-size-14 font-wt-400'>{userTypeId / 1 == 20 ? "Business" : `Suppliers`}</label>
                        {/* <div className='d-flex justify-c-ontent-between'>
                          <div className=''>
                            <label className='heading'>Total No. of Buyers</label>
                            <label
                              onClick={() => {
                                if (lcTutorial) {
                                  setTutorialStage(2)
                                }
                                toggleAddBuyerForm(true)
                              }}
                              className='subheading cursor'>Add New <img className='ml-2' src={"assets/images/add_icon.png"} /></label>
                          </div>
                          <label className='value ml-4'>{count}</label>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className='w-15'>
                      <div className='card h-100 dashboard-card shadow-sm'>
                        <label className='value font-wt-600'>{totalShipments}</label>
                        <label className='font-size-14 font-wt-400'>Shipments</label>
                      </div>
                    </div> */}
                    <div className='w-20' >
                      <div className='' style={tutorialStage == 1 ? {
                        "background": "white",
                        "position": "relative",
                        "zIndex": 10001,
                        "padding": "1rem 0rem 1rem 0rem",
                        "borderRadius": "1rem",
                      } : {}}>
                        <div className='card h-100 addBuyerDiv shadow-sm'
                        //  onClick={() => {
                        //   if (lcTutorial) {
                        //     setTutorialStage(2)
                        //   }
                        //   if (booladd) {
                        //     toggleAddBuyerForm(true)
                        //   } else {
                        //     toastDisplay("Permission Denied, Ask Owner for permission.", "info");
                        //   }
                        // }} 
                        >
                          <div className='d-flex flex-row align-items-center' onClick={handleClick}>
                            <img src='/assets/images/add_buyer_icon.svg' height={30} width={30} />
                            <label className='font-size-14 font-wt-500 text-color-1 ml-3 mb-0 '>{userTypeId / 1 == 20 ? `Add New Business` : `Add New Supplier`}</label>
                          </div>
                        </div>
                      </div>

                      {userTypeId / 1 != 20 ?
                        <div className='mt-2'>
                          <div className='card h-100 addBuyerDiv shadow-sm' onClick={() => setfindMoreBuyers(true)}>
                            <div className='d-flex flex-row align-items-center'>
                              <img src='/assets/images/internet_icon.svg' height={30} width={30} />
                              <label className='font-size-14 font-wt-500 text-color-1 ml-3 mb-0 '>Trade Discovery</label>
                            </div>
                          </div>
                        </div> : null}

                    </div>

                  </div>
                ) : null}

                {!viewSupplierDetails.show && !checkBuyerHealth.show && !addBuyerhealth.show && !findMoreBuyers ? (

                  <div className='my-4'>
                    <div className='filter-div ml-4'>
                      <Filter
                        filteredSearch={filteredSearch}
                        setFilteredSearch={setFilteredSearch}
                        filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                        showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />

                      {selecter.show && mailToUsers.length ?
                        <div className="w-50 d-flex justify-content-end align-items-center">
                          <div className='w-65 mx-1 pt-3' >
                            <NewSelect name={'selecterAction'} value={data.selecterAction}
                              selectData={[{ name: 'Get by Mail' }, { name: 'Pay for Data' }]}
                              optionLabel={"name"} optionValue={"name"} onChange={handleChange}
                            />
                          </div>
                          <button className={`new-btn py-2 px-2 text-white cursor w-35`} onClick={async () => {
                            if (data.selecterAction === "Get by Mail") {
                              setMailContentPopup({ ...mailContentPopup, show: true })
                            }
                            else if (data.selecterAction === "Pay for Data") {
                              let tempCSVData = []
                              for (let index = 0; index < dbData.length; index++) {
                                const element = dbData[index];
                                if (mailToUsers.includes(element.email_id)) {
                                  tempCSVData.push({
                                    "Company Name*": element.company_name,
                                    "GST No*": element.gst_vat_no,
                                    "Contact Person*": element.contact_person,
                                    'Email Id*': element.email_id,
                                    'Contact No': element.contact_number,
                                    'PAN No': element.pan_no,
                                    'Address': element.user_address,
                                    'Website': element.website,
                                    "Terms of Payment": element.termsOfPayment,
                                    "Product Details": element.productDetail,
                                    "IEC No": element.iec_no,
                                    "PAN No": element.pan_no,
                                    "CIN No": element.cin_no,
                                    "Country Code(2 Digit)*": element.country_code
                                  })
                                }
                              }
                              setCSVTemplateData(tempCSVData)
                              toggleAddBuyerForm(true)
                              setData({ ...data, serviceType: 'Paid' })
                            }
                          }} type='button'>{"Next"}</button>
                        </div> : null}

                      {userTypeId / 1 != 20 ?
                        <div className="w-35 d-flex justify-content-end">
                          <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => {
                            toggleSelecter({ show: !selecter.show, data: {} })
                          }} type='button'>{selecter.show ? 'Cancel Operation' : 'Request for URN'}</button>
                        </div> : null}

                      {/* <div className='row align-items-center'
                       >
                        <label className='col-auto pt-2'>Show</label>
                        <input className='col-auto form-control pagination-input  my-4' type={"number"} name={"resultPerPage"} value={filter.resultPerPage}
                          onChange={(event) => setFilter({ ...filter, resultPerPage: event.target.value })}
                          onKeyDown={handleKeyDown}
                        />
                        <label className='col-auto pt-2'>{`of ${count}`}</label>
                      </div>
                      <div className="input-group mb-3 col-md-3 currency mt-3 pe-1">
                        <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
                        <input type="text" name='search' value={filter.search}
                          onKeyDown={handleKeyDown} onChange={(event) => {
                            setFilter({ ...filter, search: event.target.value })
                          }}
                          className="form-control border-start-0" placeholder="Search" />
                      </div> */}
                      {/* <ul class="range-slider">
                        <li>
                          <button type="button" onClick={() => {
                            setRefresh(refresh + 1)
                          }}>
                            <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span></button>
                          <input className='form-control' name="search" id="searchInput" placeholder="Search" value={filter.search}
                            onChange={(event) => {
                              setFilter({ ...filter, search: event.target.value })
                            }} onKeyDown={handleKeyDown} />
                        </li>
                      </ul> */}
                    </div>
                    <div
                      style={lcTutorial && tutorialStage == 0 ? {
                        "background": "white",
                        "position": "relative",
                        "zIndex": 10001,
                        "padding": "1rem 0rem 1rem 0rem",
                        "borderRadius": "1rem",
                      } : {}}>
                      <NewTablev2
                        filteredSearch={filteredSearch} setFilteredSearch={setFilteredSearch}
                        filterData={filterData} setFilterData={setFilterData}
                        columns={tableColumns}
                      >
                        {dbData.map((item, j) => {
                          let hsnCodes = item.exporterHsnCode ? item.exporterHsnCode.split(",") : []
                          return (
                            <tr>
                              {selecter.show ? <td>
                                <div>
                                  <img className='cursor' onClick={() => {
                                    if (item.company_urn_result?.urn) { return toastDisplay("URN already exists", "info") }
                                    let tempMailToUsers = mailToUsers
                                    if (mailToUsers.includes(item.email_id)) {
                                      tempMailToUsers = tempMailToUsers.filter(i => i != item.email_id)
                                      setMailToUsers([...tempMailToUsers])
                                    }
                                    else {
                                      tempMailToUsers.push(item.email_id)
                                      setMailToUsers([...tempMailToUsers])
                                    }
                                  }} src={
                                    `assets/images/${mailToUsers.includes(item.email_id) ? 'checked-green' : 'empty-check'
                                    }.png`
                                  } />
                                </div>
                              </td> : null}
                              <td>
                                <label className='font-size-13 font-wt-400 text-break' >{item.name_title + " " + item.contact_person}</label>
                              </td>
                              <td>
                                <label className='font-size-13 font-wt-400 text-break' >{item.company_name || "-"}</label>
                              </td>
                              <td>
                                <label className='font-size-13 font-wt-400 text-break' >{item.email_id}</label>
                              </td>
                              <td>
                                <label className='font-size-13 font-wt-400 text-break' >{item.contact_number ? (item.phone_code + " " + item.contact_number) : "-"}</label>
                              </td>
                              <td>
                                <label className='font-size-13 font-wt-400 text-break' >{item.company_urn_result?.type || "-"}</label>
                              </td>
                              <td>
                                <label className='font-size-13 font-wt-400 text-break' >{item.company_urn_result?.urn || "-"}</label>
                              </td>
                              <td
                                // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                className='position-relative'>
                                <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                  onClick={() => setAction({ show: true, index: j })}
                                  aria-hidden="true"></i>
                                {action.show && action.index === j ? (
                                  <Action
                                    id={j}
                                    onDismiss={() => setAction({ show: false, index: j })}
                                    options={item.userActiveStatus ?
                                      [
                                        { name: "Edit", onClick: () => onEdit(j) },
                                        { name: "Delete", onClick: () => onDelete(j) },
                                        { name: "View Details", onClick: () => onView(j) }] :
                                      [
                                        { name: "Edit", onClick: () => onEdit(j) },
                                        { name: "Delete", onClick: () => onDelete(j) },
                                        { name: "View Details", onClick: () => onView(j) },
                                        {
                                          name: "Invite on TradeReboot", onClick: async () => {
                                            setshowLoader(true)
                                            await call('POST', 'sendInviteToJoinTRPlatform',
                                              { counterPartyUserEmail: dbData[j]["email_id"], userName })
                                            setshowLoader(false)
                                            toastDisplay("Invitation success", "success")
                                          }
                                        }]
                                    } />
                                ) : null}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                      <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div>
                <i
                  onClick={() => {
                    if (cpView) {
                      toggleAddBuyerForm(false)
                    }
                    else {
                      window.location = "/nwManagementExporter"
                    }
                  }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                {data.isEditing ? null : userTypeId / 1 != 20 ?
                  <div className='row position-absolute d-flex align-items-center w-40'
                    style={{ right: '-3rem', top: cpView ? '2rem' : '5rem' }}>
                    <div className='col-6'>
                      <h6 >
                        <a className="text-dark cursor-pointer" href="./assets/docs/UploadSupplierTemplate.csv"
                          target="_blank" download>
                          <img className='mr-2' src="assets/images/csv_file_icon.svg" alt="" width="45" height="45"></img>
                          {"Download Template"}</a>
                      </h6>
                    </div>
                    <div className='col-6' >
                      <button type="button"
                        onClick={() => { togglePopup(true) }}
                        className={`new-btn py-2 px-3 mb-2 text-white`}>
                        {"Upload In Bulk"}
                      </button>
                    </div>
                  </div> : null}

                {tab === 0 && !csvTemplateData.length && userTypeId / 1 != 20 ?
                  <div>
                    {paymentDetails.paymentRequired ? null :
                      <div className='mb-3 d-flex pr-2 align-items-center'>
                        <label className={data.serviceType === "Paid" ? "font-wt-500 font-size-16" :
                          "font-wt-700 font-size-16 color-2ECC71"} for="currentDocuments">Free</label>
                        <img
                          className="cursor mx-3"
                          onClick={() => setData({ ...data, serviceType: data.serviceType === "Free" ? "Paid" : "Free" })}
                          src={data.serviceType === "Free" ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                        />
                        <label className={data.serviceType === "Free" ? "font-wt-500 font-size-16" :
                          "font-wt-700 font-size-16 color-2ECC71"} for="pastDocuments">Paid (50 INR)</label>
                      </div>}
                  </div> : null}

                {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                  {addBuyerTabs.map((item, index) => {
                    return (
                      <li>
                        <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")} onClick={() => {
                          handleValidation(index)
                        }}>{item.name}</a>
                      </li>
                    )
                  })}
                </ul> */}

                <div className='bg-white pb-5'
                  style={(lcTutorial) ? {
                    "background": "white",
                    "position": "relative",
                    "zIndex": 10001,
                    "padding": "1rem 0rem 1rem 0rem",
                    "borderRadius": "1rem",
                    "height": "auto",
                    "maxHeight": "40rem",
                    "overflow": "auto"
                  } : {
                    "height": "40rem",
                    "overflow": "auto"
                  }}
                >



                  {/* {tab === 0 ? (
                    <div className='px-4 py-5'>
                      <div className="row">

                        <div className="col-md-3">
                          <NewInput isAstrix={true} type={"text"} label={"Exporter's Name"}
                            name={"exporterName"} value={data.exporterName} error={errors.exporterName}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-3">
                          <MultipleSelect
                            Id="Select Country"
                            Label="Select Country"
                            selectedvalue="Select Country"
                            optiondata={countrys}
                            onChange={(e) => handleMultiSelectchange(e, "exporterCountry", "sortname", true)}
                            value={data.exporterCountry ? [data.exporterCountry] : []}
                            name="exporterCountry"
                            labelKey={"name"}
                            valKey={"sortname"}
                            error={errors.exporterCountry}
                          />
                        </div>
                        <div className='col-md-3' >
                          <NewInput type={"text"} label={"GST No"}
                            name={"gstNo"} value={data.gstNo}
                            onChange={handleChange} error={errors.gstNo} />
                        </div>
                      </div>
                    </div>
                  ) : null} */}

                  {/* {tab === 1 ? (
                    <div className='px-4 py-5'>
                      <label className='mb-4' >Select DUNS No.</label>
                      <div className='row dunsCardView px-3'>
                        {dunsData.length ? dunsData.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setData({
                                  ...data, selectedDuns: index,
                                  exporterAddress: item.address.street,
                                  exporterPostalCode: item.address.postalCode, buyerDunsNo: item.duns,
                                  buyersAPIDetail: item
                                })
                              }}
                              className={`dunsCard cursor mb-5 mr-5 p-4 ${data.selectedDuns === index ? "selectedDunsCard" : ""} `}
                            >
                              <h6 className='text-color1'>{item.duns}</h6>
                              <label className='text-color-label' >Company name</label>
                              <br />
                              <label className='text-color-value'>{item.companyName}</label>
                              <br />
                              <label className='text-color-label'>Address</label>
                              <br />
                              <label className='text-color-value'>{`${item.address.street}, ${item.address.city}, ${item.address.countryCode}, ${item.address.postalCode}`}</label>
                            </div>
                          )
                        }) : (
                          <h6>No Related Exporter Details Found</h6>
                        )}
                      </div>
                    </div>
                  ) : null} */}

                  {csvTemplateData?.length ? <div style={{ overflowX: 'auto' }} className='m-4' >
                    {paymentDetails.paymentRequired ? null :
                      <div className='mb-3 d-flex pr-2 align-items-center'>
                        <label className={data.serviceType === "Paid" ? "font-wt-500 font-size-16" :
                          "font-wt-700 font-size-16 color-2ECC71"} for="currentDocuments">Free</label>
                        <img
                          className="cursor mx-3"
                          onClick={() => { setData({ ...data, serviceType: data.serviceType === "Free" ? "Paid" : "Free" }) }}
                          src={data.serviceType === "Free" ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                        />
                        <label className={data.serviceType === "Free" ? "font-wt-500 font-size-16" :
                          "font-wt-700 font-size-16 color-2ECC71"} for="pastDocuments">Paid (50 INR For Each Entry if Udyam Details Found)</label>
                      </div>}
                    {data.serviceType === "Free" ?
                      <div className="p-0 m-0 d-flex mx-0 my-3">
                        <div>
                          <img
                            onClick={() => { setData({ ...data, sendRequestForUdyamNoMail: !data.sendRequestForUdyamNoMail }) }}
                            className='cursor'
                            src={`assets/images/${data.sendRequestForUdyamNoMail ? 'checked-green' : 'empty-check'}.png`} />
                        </div>
                        <label className='font-wt-600 font-size-15 text-color1 w-100 mx-3'>Send request Udyam No mail, if Udyam No not found</label>
                      </div> : null}
                    <label className='font-size-14 font-wt-600 mb-2' >Kindly validate below details before clicking on save</label>
                    <NewTablev2 columns={[{ subColumns: "Company Name*", subColumnStyle: { width: '10%' } },
                    { subColumns: "GST No*", subColumnStyle: { width: '10%' } },
                    { subColumns: "Contact Person*", subColumnStyle: { width: '10%' } },
                    { subColumns: "Email Id*", subColumnStyle: { width: '10%' } }, { subColumns: "Contact No", subColumnStyle: { width: '10%' } },
                    { subColumns: "Udyam No", subColumnStyle: { width: '10%' } }, { subColumns: "PAN No", subColumnStyle: { width: '10%' } }, { subColumns: "Address", subColumnStyle: { width: '10%' } },
                      , { subColumns: "Website", subColumnStyle: { width: '10%' } }, { subColumns: "Terms of Payment", subColumnStyle: { width: '10%' } },
                    { subColumns: "Product Details", subColumnStyle: { width: '10%' } }, { subColumns: "HSN Code", subColumnStyle: { width: '10%' } }]} >
                      {csvTemplateData.map((i, j) => {
                        return <tr>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Company Name*`} value={i[`Company Name*`]} error={errors[`Company Name*${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`GST No*`} value={i[`GST No*`]} error={errors[`GST No*${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Contact Person*`} value={i[`Contact Person*`]} error={errors[`Contact Person*${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Email Id*`} value={i[`Email Id*`]} error={errors[`Email Id*${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Contact No`} value={i[`Contact No`]} error={errors[`Contact No${j}`]} />
                          </td>
                          <td>
                            <InputForTable isDisabled={i[`Udyam No`] && i[`kid`] && data.serviceType === "Paid"} onChange={e => handleCSVData(e, j)} name={`Udyam No`}
                              value={i[`Udyam No`] && i[`kid`] && data.serviceType === "Paid" ? "XXXXXXXX" : i['Udyam No']} error={errors[`Udyam No${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`PAN No`} value={i[`PAN No`]} error={errors[`PAN No${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Address`} value={i[`Address`]} error={errors[`Address${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Website`} value={i[`Website`]} error={errors[`Website${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Terms of Payment`} value={i[`Terms of Payment`]} error={errors[`Terms of Payment${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`Product Details`} value={i[`Product Details`]} error={errors[`Product Details${j}`]} />
                          </td>
                          <td>
                            <InputForTable onChange={e => handleCSVData(e, j)} name={`HSN Code`} value={i[`HSN Code`]} error={errors[`HSN Code${j}`]} />
                          </td>
                        </tr>
                      })}
                    </NewTablev2>
                    <div><button className={`my-4 new-btn w-25 py-2 px-1 text-white cursor`}
                      onClick={async () => {
                        if (paymentDetails.paymentRequired && !paymentDetails.linkId) {
                          onPurchasePlan(0, paymentDetails.amount, paymentDetails.currency,
                            0, paymentDetails)
                        }
                        else if (paymentDetails.linkId) {
                          setshowLoader(true)
                          let resp = await call('POST', 'validatePayment', { linkId: paymentDetails.linkId, forGeneric: true })
                          if (resp) {
                            saveCSVData()
                            toastDisplay("Payment completed successfully", "success")
                            setPaymentDetails({})
                          }
                          else {
                            toastDisplay("Payment didn't completed", "info")
                            setPaymentDetails({ ...paymentDetails, linkId: undefined })
                          }
                          setshowLoader(false)
                        }
                        else {
                          saveCSVData()
                        }
                      }}
                    >
                      {paymentDetails.paymentRequired ? paymentDetails.linkId ?
                        `Validate Payment` : `Continue with the Payment of ${paymentDetails.amount} ${paymentDetails.currency}` : `Save`}</button>
                    </div>
                  </div> : <>
                    <div className="d-flex justify-content-center mt-5 mb-3" >
                      <FormProgressBar
                        tabs={addBuyerTabs} activeTab={tab} label={"name"}
                        separationWidth={'10rem'} handleClick={(i, index) => { handleValidation(index) }}
                      />
                    </div>

                    {tab === 0 ? (
                      <div className='px-4 py-5'>
                        <div className="row">
                          <div className="col-md-4">
                            <NewInput isAstrix={true} type={"text"} label={"GST No"}
                              name={"gstNo"} value={data.gstNo} error={errors.gstNo}
                              onChange={handleChange} />
                          </div>
                          {data.serviceType === "Free" ?
                            <div className="col-md-4">
                              <NewInput type={"text"} label={"Udyam No"}
                                name={"urn"} value={data.urn} error={errors.urn}
                                onChange={handleChange} />
                            </div> : null}
                        </div>
                      </div>
                    ) : null}
                  </>}

                  {tab === 1 && !csvTemplateData.length ? (
                    <div className='px-4 py-5'>
                      <div className="row">

                        {/* <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Exporter's Name"}
                            name={"exporterName"} value={data.exporterName} error={errors.exporterName}
                            onChange={handleChange} />
                        </div> */}

                        <div className='col-md-4' >
                          <div className=" form-floating mb-2 w-100 position-relative row m-0 p-0">
                            <div className="w-25 p-0 m-0">
                              <NewSelect selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                                name="nameTitle" value={data.nameTitle} optionLabel={'name'} optionValue={'name'}
                                onChange={handleChange} error={errors.nameTitle} />
                            </div>
                            <div className="w-75 p-0 m-0">
                              <NewSelect selectData={data.directorArr || []} name={"contactPersonDropDown"} value={data.contactPersonDropDown}
                                optionLabel={'name'} optionValue={'name'} onChange={handleChange}
                                error={errors.contactPersonDropDown || errors.exporterName} label={"Select Contact Person Name*"}
                              />
                            </div>
                          </div>
                        </div>

                        {data.contactPersonDropDown === "Other" ? <div className='col-md-4' >
                          <NewInput name={'exporterName'} value={data.exporterName} onChange={handleChange}
                            error={errors.exporterName} label={"Contact Person Name*"}
                          />
                        </div> : null}

                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"Company Name"}
                            name={"exporterCompanyName"} value={data.exporterCompanyName} error={errors.exporterCompanyName}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <MultipleSelect
                            isAstrix={true}
                            Id="Select Country"
                            Label="Select Country"
                            selectedvalue="Select Country"
                            optiondata={countrys}
                            onChange={(e) => handleMultiSelectchange(e, "exporterCountry", "sortname", true)}
                            value={data.exporterCountry ? [data.exporterCountry] : []}
                            name="exporterCountry"
                            labelKey={"name"}
                            valKey={"sortname"}
                            error={errors.exporterCountry}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput isAstrix={true} type={"text"} label={"GST No"}
                            name={"gstNo"} value={data.gstNo} error={errors.gstNo}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewSelect selectData={data.iecArr || []} name={"iecNo"} value={data["iecNo"]} onChange={handleChange}
                            error={errors["iecNo"]} label={"Enter IEC Number"} optionLabel={"iec"} optionValue={"iec"}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"CIN No"}
                            name={"cinNo"} value={data.cinNo} error={errors.cinNo}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewSelect selectData={panArr} name={"panNo"} value={data["panNo"]} onChange={handleChange}
                            error={errors["panNo"]} label={"Select PAN Number"} optionLabel={"name"} optionValue={"name"}
                          />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Udyam No"}
                            name={"urn"} value={data.urn} error={errors.urn}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Email Id"} isAstrix={true}
                            name={"exporterEmail"} value={data.exporterEmail} error={errors.exporterEmail}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect type={"number"} label={"Contact Number"}
                            selectData={countrys}
                            selectName={"exporterPhoneCode"} selectValue={data.exporterPhoneCode}
                            optionLabel={"phonecode"} optionValue={'phonecode'}
                            name={'exporterContact'} value={data.exporterContact}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Address"}
                            name={"exporterAddress"} value={data.exporterAddress}
                            onChange={handleChange} error={errors.exporterAddress} />
                        </div>

                        {/* <div className="col-md-4">
                          <NewInput type={"text"} label={"Postal Code"}
                            name={"exporterPostalCode"} value={data.exporterPostalCode}
                            onChange={handleChange} error={errors.exporterPostalCode} />
                        </div> */}

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Website"}
                            name={"exporterWebsite"} value={data.exporterWebsite}
                            onChange={handleChange} error={errors.exporterWebsite} />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect type={"number"} label={"Previous year annual sales"}
                            selectData={currencyData}
                            selectName={"exporterCurrency"} selectValue={data.exporterCurrency}
                            optionLabel={"code"} optionValue={'code'}
                            name={'exporterPrevSale'} value={data.exporterPrevSale} error={errors.exporterPrevSale}
                            onChange={handleChange} />
                        </div>

                        <div className="col-md-4">
                          <InputWithSelect type={"number"} label={"Expected current year’s annual sale"}
                            selectData={currencyData}
                            selectName={"exporterCurrency"} selectValue={data.exporterCurrency}
                            optionLabel={"code"} optionValue={'code'}
                            name={'exporterExpectedSale'} value={data.exporterExpectedSale} error={errors.exporterExpectedSale}
                            onChange={handleChange} />
                        </div>

                        {/* <div className="col-md-4">
                          <NewSelect isAstrix={true} label={"Select Inco Terms"}
                            selectData={IncoTerms} name={"buyerIncoTerms"}
                            value={data.buyerIncoTerms} optionLabel={"name"} optionValue={'name'}
                            onChange={handleChange} error={errors.buyerIncoTerms} />
                        </div> */}

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Terms of Payment"}
                            name={"exporterTermsPayment"} value={data.exporterTermsPayment}
                            onChange={handleChange} error={errors.exporterTermsPayment} />
                        </div>

                        <div className="col-md-4">
                          <NewInput type={"text"} label={"Product Details"}
                            name={"exporterProductDetails"} value={data.exporterProductDetails}
                            onChange={handleChange} error={errors.exporterProductDetails} />
                        </div>

                        <div className="col-md-4">
                          <MultipleSelect
                            Id="HSN Code"
                            Label="HSN Code"
                            selectedvalue="HSN Code"
                            optiondata={hsnCodes}
                            onChange={(e) => handleMultiSelectchange(e, "exporterHsnCode", "code")}
                            value={data.exporterHsnCode || []}
                            name="exporterHsnCode"
                            labelKey={"code"}
                            valKey={"code"}
                          />
                        </div>
                      </div>
                      {/* <div className='d-flex flex-row align-items-center'>
                        <div className='d-flex flex-row px-2'>
                          <label className="form-check-label mt-1 font-size-15 font-wt-500" >
                            Buyer Annual Turnover
                          </label>
                        </div>
                        <div className='d-flex flex-row px-2' onClick={() => setAnnualTurnover('Less than 250 Cr')}>
                          <input className="form-check-input" type="radio" value={"Less than 250 Cr"} checked={annualTurnover === 'Less than 250 Cr'} />
                          <label className="form-check-label p-0 m-0" >
                            Less than 250 Cr
                          </label>
                        </div>
                        <div className='d-flex flex-row px-2' onClick={() => setAnnualTurnover('More than 250 Cr')}>
                          <input className="form-check-input" type="radio" value={"More than 250 Cr"} checked={annualTurnover === 'More than 250 Cr'} />
                          <label className="form-check-label p-0 m-0" >
                            More than 250 Cr
                          </label>
                        </div>
                      </div> */}
                    </div>
                  ) : null}

                  {/* {tab === 3 ? (
                    <div className='px-4 py-5'>
                      <div className="row">

                        <div className="col-md-12">
                          <label className="">Invoice Document</label>
                          <div className="row form-group">
                            <div className="col-md-5">
                              <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                              />
                              {errors.invoiceDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.invoiceDocument}</b></div> : ''}
                            </div>
                            {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label className="">Purchase Order Document</label>
                          <div className="row form-group">
                            <div className="col-md-5">
                              <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "poDocument": null })}
                              />
                              {errors.poDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.poDocument}</b></div> : ''}
                            </div>
                            {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.poDocument && data.poDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}

                  {/* {tab === 4 ? (
                    <div className='px-4 py-5'>
                      <div className="row">
                        {financierData.length && financierData.map((item) => {
                          return (
                            <div className='d-flex mb-5 w-30'>
                              <div className='align-items-center d-flex mr-3'
                                onClick={() => handleSelectedFinanciers(item.id)}
                              >
                                {data.selectedFinanciers.length && data.selectedFinanciers.includes(item.id) ? (
                                  <img className='cursor' src={"assets/images/checked-green.png"} />
                                ) : (
                                  <img className='cursor' src={"assets/images/empty-check.png"} />)}
                              </div>
                              <div
                                className='financierCard px-5 py-4'>
                                <div className='d-flex'
                                  style={{
                                    "height": "2rem",
                                    "overflow": "hidden"
                                  }}
                                >
                                  <img
                                    src={avatarUrl(item.user_avatar)}
                                    className={""}
                                    style={{
                                      width: "30px",
                                      height: "30px"
                                    }}
                                  />
                                  <label
                                    className={"ml-1 overflow-hidden"}
                                  ><b>{item.company_name}</b></label>
                                </div>
                                <div className='row justify-content-between py-3'>
                                  <div>
                                    <label
                                      className={"ml-2"}
                                    >{item.rating}</label>
                                    <StarRating rating={item.rating} />
                                  </div>
                                  <div>
                                    <label
                                    >{item.reviews}</label><br />
                                    <label
                                    >{"See Reviews"}</label>
                                  </div>
                                </div>
                                <div>
                                  <img src={"assets/images/chat.png"} />
                                  <label className='ml-2'>Chat with financier</label>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : null} */}

                  {tab === 2 ? (
                    <div className='p-5'>
                      <div className="row">

                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>{userTypeId / 1 == 20 ? `Business Details` : `Supplier Details`}</u></label>
                          <div className='row'>
                            {reviewForm.map((item) => {
                              if (data[item.val]) {
                                return (
                                  <div className="col-md-6 pe-5">
                                    <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>

                        {/* <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                          <div className='row mt-2'>
                            <div className="col-md-4">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className='w-100 my-5'>
                          <label className='font-wt-500'><u>Financier Selected</u></label>
                          <div className='d-flex'>
                            {data.selectedFinanciers.map((item) => {
                              return (
                                <div className='bg-white d-flex text-center align-items-center py-2 mr-3'
                                  style={{
                                    "borderRadius": "10px",
                                    "border": "1px solid #D5D5D5",
                                    "width": "10rem",
                                    "display": "inline-block"
                                  }}
                                >
                                  <label className='w-100 lh-18 m-0 text-color-label'>{financierData.filter(i => i.id == item)[0]["company_name"]}</label>
                                </div>
                              )
                            })}
                          </div>
                        </div> */}
                        {data.isEditing ? null : (
                          <div className='mt-3'>
                            <img
                              onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                              className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                            <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {tab === 2 && !data.tcAccepted ? null : csvTemplateData?.length ? null : (
                    <div className={"row mx-0 px-3"}>
                      <button type="button"
                        onClick={async () => {
                          if (paymentDetails.paymentRequired && !paymentDetails.linkId) {
                            onPurchasePlan(0, paymentDetails.amount, paymentDetails.currency,
                              0, paymentDetails)
                          }
                          else if (paymentDetails.linkId) {
                            setshowLoader(true)
                            let resp = await call('POST', 'validatePayment', { linkId: paymentDetails.linkId, forGeneric: true })
                            if (resp) {
                              setTab(tab + 1)
                              toastDisplay("Payment completed successfully", "success")
                              setPaymentDetails({})
                            }
                            else {
                              toastDisplay("Payment didn't completed", "info")
                              setPaymentDetails({ ...paymentDetails, linkId: undefined })
                            }
                            setshowLoader(false)
                          }
                          else {
                            handleValidation(undefined)
                          }
                        }}
                        className={`mx-2 new-btn w-25 py-2 px-2 text-white`}>
                        {tab === 2 ? `${data.isEditing ? `${userTypeId / 1 == 20 ? 'Update Business' : 'Update Supplier'}`
                          : `${userTypeId / 1 == 20 ? 'Add Business' : 'Add Supplier'}`}` :
                          (paymentDetails.paymentRequired ?
                            paymentDetails.linkId ? `Validate Payment` :
                              `Continue with the Payment of ${paymentDetails.amount} ${paymentDetails.currency}` : "Continue")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ExporterManagement)