import React, { useState } from "react";
import { NewInput } from "../../../utils/newInput";
import { displayRazorpay } from "../../../utils/PaymentGateway";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";

const StandardCard = ({ data, amount, userId, toastDisplay }) => {
  const [addcoinpopup, setaddcoinpopup] = useState()
  const [coins, setcoins] = useState({
    coins: 10,
    amount: 1
  })
  const [errors, setErrors] = useState({})
  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    const amount = event.target.value / 10
    const coins = event.target.value
    setcoins({
      coins,
      amount
    })
    if (event.target.value === '') {
      setErrors({ ...errors, [event.target.name]: "Coins to be added cannot be empty" })
      return
    }
    if (event.target.value < 10) {
      setErrors({ ...errors, [event.target.name]: "Minimum coins purchased should be greater than 10" })
      return
    }
    setErrors({ ...errors, [event.target.name]: "" })
  }
  return (
    <>
      <div className='card p-4 borderRadius StandardCard h-100'>
        <p className='d-flex justify-content-between'><span className="font-size-12 ">My coins</span> <a className='font-size-14 text-color1 text-decoration-none' href="/plans">Standard</a> </p>
        <div className='d-flex align-items-center gap-2 mt-4'>
          <img src={'/assets/images/Lc/coins.png'} alt='' />
          <h4 className='mb-0 text-color1 font-size-30 font-wt-600'>{data.coins || 0}</h4>
          <img className='cursor ms-3' src={'/assets/images/add more-bg-white.png'} alt='' onClick={() => {
            setaddcoinpopup(true)
            //displayRazorpay(amount, userId, toastDisplay)
          }} />
        </div>
        {addcoinpopup &&
          <FinanceInvoiceModal limitinvoice={addcoinpopup} setLimitinvoice={setaddcoinpopup} closeSuccess={() => setaddcoinpopup(false)} >
            <div>
              <div className="row">
                <label className="font-size-14 font-wt-600 text-center p-2">Enter the coins you want to add</label>
                <div className="d-flex justify-content-center">
                  <div className="col-md-6 px-0">
                    <NewInput isAstrix={true} type={"number"} label={"Coins"}
                      name={"coins"} value={coins.coins} error={errors.coins}
                      onChange={handleChange} />
                  </div>
                </div>
                <label className="font-size-13">{`Total Amount`}<b>{` Rs.${coins.amount}`}</b></label>
                <div className="d-flex justify-content-center">
                  <button className={`my-4 new-btn w-17 py-2 px-2 text-white cursor`} disabled={errors.coins !== ''} onClick={() => {
                    setaddcoinpopup(false)
                    displayRazorpay(coins.amount, userId, toastDisplay)
                  }}>Pay Now</button>
                </div>

              </div>
            </div>
          </FinanceInvoiceModal>
        }
        {/* <div className='d-flex align-items-center gap-2 mt-3 font-size-13 text-secondary'>
          <p><img src={'/assets/images/Lc/Dollar.png'} alt='' /> 1 Coin</p> <p>=</p> <p><img src={'/assets/images/Usd.png'} alt='' /> 1 USD</p>
        </div> */}
      </div>
    </>
  );
}
export default StandardCard;