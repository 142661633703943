import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import call from "../../../../service";
import { getDocDetails, most_used_currencies } from "../../../../utils/myFunctions";
import { convertImageToPdf } from "../../../../utils/myFunctions";
import toastDisplay from "../../../../utils/toastNotification";
import { LCTypesObject } from "../../../../utils/myFunctions";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from "../../../../utils/newInput";
import AccordionCommon from "./accordionCommon";
import { FileInput } from "../../../../utils/FileInput";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import MultipleSelect from "../../../../utils/MultipleSelect";
import { IncoTerms } from "../../../myCounterPartComp/buyerManagement";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import { useHistory } from "react-router";


const addBuyerTabs = [
  { name: "finName" }
]

const documentsInformation = [
  { label: "Invoice", val: 'invoiceDocument' },
  { label: "Purchase Order", val: 'poDocument' },
  { label: "Bill of Lading/Airway Bill", val: 'logisticDocument' },
  { label: "Investigation Report", val: 'invReportDocument' },
  { label: "Certificate of Origin", val: 'grnDocument' }
]


const InformationIcon = ({ userTokenDetails, navToggleState, additionalReqObj, selectedFinancierRemarks }) => {

  // console.log("aaaaaaaaaaaaaaaaaaaaaaaa", additionalReqObj, selectedFinancierRemarks);

  let isQuoteDenied = selectedFinancierRemarks?.buyers_credit?.financierAction === "deny"

  let isAdmin = localStorage.getItem("isAdmin")

  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  const quoteId = queryParams.get("id")
  const amendmentId = queryParams.get("amendmentId")
  const reqLetterRequired = queryParams.get("reqLetterRequired") ? true : false

  let onlyView = additionalReqObj ? JSON.parse(additionalReqObj)["status"] / 1 == 1 : false
  let invNo = localStorage.getItem("invIfAppliedNo") && localStorage.getItem("invIfAppliedNo") != "null" ? localStorage.getItem("invIfAppliedNo") : null

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = localStorage.getItem("manualUserEmail") ? localStorage.getItem("manualUserEmail") : (userTokenDetails.email ? userTokenDetails.email : null)
  const userId = localStorage.getItem("manualUserId") ? localStorage.getItem("manualUserId") : (userTokenDetails.user_id ? userTokenDetails.user_id : null)
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null

  let isUserLender = userTypeId / 1 == 8

  const [additionalDetails, setAdditionalDetails] = useState(additionalReqObj ? (JSON.parse(additionalReqObj)?.["additionalDetails"] || []) : [])
  const [commentsOnInformation, setCommentsOnInformation] = useState(additionalReqObj ? (JSON.parse(additionalReqObj)?.["commentsOnInformation"] || []) : {})


  const [tab, setTab] = useState(amendmentId ? 0 : 0)
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [countrys, setCountrys] = useState([])
  const [userBank, setUserBank] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [bankMaster, setbankMasters] = useState([]);
  const [moreDocs, setMoreDocs] = useState({})
  const [refresh, setRefresh] = useState(1)


  const [limitinvoice, setLimitinvoice] = useState(false);
  const [accordion, setAccordion] = useState("");
  const [infoopenone, setInfoopenone] = useState({});
  const [openReplyWindow, toggleReplyWindow] = useState({})
  const [replyData, setReplyData] = useState({})
  const [replyDbData, setReplyDbData] = useState([])
  const [additionalDocs, setAdditionalDocs] = useState(additionalReqObj ? (JSON.parse(additionalReqObj)?.["additionalDocuments"] || []) : [])

  const fieldsInformation = [
    { "name": "Buyer Name", val: "buyerName", comp: NewInput, type: 'text' },
    {
      "name": "Contact Number", val: "buyerPhone", unit: 'buyerPhoneCode', comp: InputWithSelect, type: "text", selectData: countrys,
      optionLabel: 'phonecode', optionValue: 'phonecode'
    },
    { "name": "Email Id", val: 'buyerEmail', comp: NewInput, type: "text" },
    { "name": "Website", val: "buyerWebsite", comp: NewInput, type: 'text' },
    { "name": "Address", val: "buyerAddress", comp: NewInput, type: "text" },
    { "name": "Country", val: "buyerCountry", comp: NewSelect, selectData: countrys, optionLabel: 'name', optionValue: 'sortname' },
    { "name": "Duns No.", val: "buyerDUNSNo", comp: NewInput, type: "text" },
    {
      "name": "Previous year annual sale", val: "previousAnnualSale", unit: 'currency', convUnit: (data) => {
        return data.split(":")[1]
      }, comp: InputWithSelect, selectData: most_used_currencies, optionLabel: 'code', optionValue: 'code'
    },
    {
      "name": "Expected current year's annual sale", val: "currentAnnualSale", unit: 'currency', convUnit: (data) => {
        return data.split(":")[1]
      }, comp: InputWithSelect, selectData: most_used_currencies, optionLabel: 'code', optionValue: 'code'
    },
    { "name": "Inco Terms", val: "incoTerms", comp: NewSelect, selectData: IncoTerms, optionLabel: 'name', optionValue: 'name' },
    { "name": "Terms of Payment", val: "termsOfPayment", comp: NewInput, type: 'text' },
    { "name": "Product details", val: "productDetails", comp: NewInput, type: 'text' }
  ]


  async function getInvoice() {
    return new Promise(resolve => {
      let viewOnlyData = {}
      call('POST', 'getInvDiscoutingDetails', { invNo }).then(async (result) => {
        // console.log('Invoice Get Discounting API  =====>', result);
        if (result && result.length) {
          let temp = result[0]
          let docsId = temp["tbl_doc_id"].split(",")

          viewOnlyData["tcAccepted"] = true
          viewOnlyData["docsId"] = docsId
          viewOnlyData["invoiceDocument"] = await getDocDetails(docsId[0])
          viewOnlyData["poDocument"] = await getDocDetails(docsId[1])
          viewOnlyData["logisticDocument"] = await getDocDetails(docsId[2])
          viewOnlyData["invReportDocument"] = await getDocDetails(docsId[3])
          viewOnlyData["grnDocument"] = await getDocDetails(docsId[4])
          viewOnlyData["packingListDocument"] = await getDocDetails(docsId[5])
          viewOnlyData["shippingBillDocument"] = await getDocDetails(docsId[6])

          viewOnlyData["poRefrenceNo"] = temp["po_no"]
          viewOnlyData["invoiceRefrenceNo"] = temp["reference_no"]
          viewOnlyData["beneficiaryBankName"] = temp["bankList"].split(":")[0] / 1
          viewOnlyData["currency"] = currencyData.filter(i => {
            if (i.id / 1 == temp["currency"] / 1) {
              return i
            }
          })[0]["code"]
          viewOnlyData["contractAmount"] = temp["contract_amount"]
          viewOnlyData["invoiceIssueDate"] = temp["issue_date"]
          viewOnlyData["invoiceDueDate"] = temp["due_date"]
          viewOnlyData["reqAmount"] = temp["amount"]
          viewOnlyData["lenderId"] = temp["lender_id"] / 1
          viewOnlyData["creditDays"] = temp["credit_days"]


          viewOnlyData["shipmentOriginCountry"] = temp["shipment_origin_country"]
          viewOnlyData["shipmentOriginAddress"] = temp["shipment_origin_address"]
          viewOnlyData["shipmentOriginCity"] = temp["shipment_origin_city"]
          viewOnlyData["shipmentOriginPostalCode"] = temp["shipment_origin_postal_code"]

          viewOnlyData["shipmentDestinationCountry"] = temp["shipment_destination_country"]
          viewOnlyData["shipmentDestinationAddress"] = temp["shipment_destination_address"]
          viewOnlyData["shipmentDestinationCity"] = temp["shipment_destination_city"]
          viewOnlyData["shipmentDestinationPostalCode"] = temp["shipment_destination_postal_code"]

          resolve(viewOnlyData)
        }
      }).catch((e) => {
        resolve({})
        // console.log('error in Invoice Get Discounting API  =====>', e);
      })
    })
  }

  // console.log("dddddddddddddddddddddddddddddddddddd", data);

  useEffect(() => {
    call("POST", "getAmendmentReplys", {
      applicationId: localStorage.getItem('applicationId'),
      fromUser: userId, toUser: localStorage.getItem(isUserLender ? "sellerId" : 'lenderId')
    })
      .then(res => {
        setReplyDbData(res)
        setReplyData({})
      })
  }, [refresh])

  async function asyncUseEffectFunction() {

    setshowLoader(true)
    let viewOnlyData = invNo ? await getInvoice() : {}
    setshowLoader(false)

    let tempDataValues = {}

    // update sample documents in additionalDocs start
    let tempAdditionalDocs = additionalDocs
    for (let index = 0; index < tempAdditionalDocs.length; index++) {
      const element = tempAdditionalDocs[index];
      setshowLoader(true)
      if (element.documentSample) {
        tempAdditionalDocs[index]["documentSample"] = await getDocDetails(element.documentSample)
      }
      setshowLoader(false)
    }
    setAdditionalDocs([...tempAdditionalDocs])
    // update sample documents in additionalDocs end

    if (additionalDocs?.length) {
      let tempAllDocs = {}
      for (let index = 0; index < additionalDocs.length; index++) {
        const element = additionalDocs[index];
        tempAllDocs[element.doc_name] = await getDocDetails(element.doc_id || null)
      }
      tempDataValues = tempAllDocs
    }

    let buyerData = JSON.parse(localStorage.getItem("buyerData"))

    console.log("buyerData===============>", buyerData, viewOnlyData);

    // Get buyer docs start
    let tempBuyerDocs = {}
    let buyersDocId = buyerData?.buyersDocId ? buyerData.buyersDocId.split(":") : []
    tempBuyerDocs["invoiceDocument"] = await getDocDetails(buyersDocId[0])
    tempBuyerDocs["poDocument"] = await getDocDetails(buyersDocId[1])
    tempBuyerDocs["logisticDocument"] = await getDocDetails(buyersDocId[2])
    tempBuyerDocs["invReportDocument"] = await getDocDetails(buyersDocId[3])
    tempBuyerDocs["grnDocument"] = await getDocDetails(buyersDocId[4])
    tempBuyerDocs["packingListDocument"] = await getDocDetails(buyersDocId[5])
    tempBuyerDocs["shippingBillDocument"] = await getDocDetails(buyersDocId[6])
    // Get buyer docs end

    setData({
      ...data, nameTitle: "Mr", currency: "USD", applicantName: userTypeId / 1 == 1 ? localStorage.getItem("manualApplicantName") : userName, buyerName: buyerData["buyerName"],
      buyerPosition: buyerData["buyerPosition"], nameAuthorizedSignatory: buyerData["nameAuthorizedSignatory"],
      buyerEmail: buyerData["buyerEmail"], buyerPhoneCode: buyerData["buyerPhoneCode"],
      buyerContact: buyerData["buyerPhone"], buyerPhone: buyerData["buyerPhone"], buyerIdentifier: buyerData["buyerIdentifier"], incoTerms: buyerData["incoTerms"],
      buyerWebsite: buyerData["buyerWebsite"], buyerAddress: buyerData.buyerAddress,
      buyerCountry: buyerData.buyerCountry, buyerDUNSNo: buyerData.buyerDUNSNo,
      previousAnnualSale: buyerData.previousAnnualSale, currentAnnualSale: buyerData.currentAnnualSale,
      termsOfPayment: buyerData.termsOfPayment, productDetails: buyerData.productDetails,
      ...viewOnlyData, ...tempBuyerDocs, buyersDocId: buyerData.buyersDocId, ...tempDataValues
    })

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      // console.log('running getUserBanks api -->', result);
      setUserBank(result.message);
    }).catch((e) => {
      // console.log('error in getUserBanks', e);
    })

    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    // Get bank master list that are involved in trade finance.
    call("GET", "getBanksListMaster")
      .then((result) => {
        setbankMasters(result);
      })
      .catch((e) => {
        console.log("Error while querying getBanksListMaster:", e);
      });

  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [onlyView])


  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target.name === "beneficiaryBankName") {
      let beneficiaryBankTitle = userBank.filter(i => {
        if (i.id == event.target.value) {
          return i
        }
      })[0]["beneficiaryBankName"]
      setData({ ...data, [event.target.name]: event.target.value, beneficiaryBankTitle })
      setErrors({ ...errors, [event.target.name]: "" })
      return null
    }
    else if (event.target.name === "invoiceDueDate" && event.target.value) {
      let creditDays = moment(event.target.value, "YYYY-MM-DD").diff(moment(), "days")
      setData({ ...data, [event.target.name]: event.target.value, creditDays })
      setErrors({ ...errors, [event.target.name]: "", creditDays: "" })
      return null
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  const handleAdditionalDetailsChange = async (event, index) => {
    event.persist()
    let temp = additionalDetails
    if (event.target.name === "unit") {
      temp[index]["unit"] = event.target.value
    }
    else {
      temp[index]["val"] = event.target.value
    }
    setAdditionalDetails([...additionalDetails])
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleFile2 = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setMoreDocs({ ...moreDocs, [event.target.name]: fileObj })
      }
    }
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  const closeFn = () => {
    setModal(true);
  }

  async function handleValidation(tabIndex) {
    let err = {}

    for (let index = 0; index < additionalDocs?.length; index++) {
      const element = additionalDocs[index];
      if (!data?.[element.doc_name]?.name && element.isMandatory) {
        err[element.doc_name] = "Mandatory Field"
      }
    }

    if (localStorage.getItem("lenderId") / 1 != 51) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("reqId", JSON.parse(additionalReqObj)["id"])
      formData.append("userId", userId)
      formData.append("userName", userName)
      formData.append("buyerId", localStorage.getItem("buyerId"))
      formData.append("lenderId", localStorage.getItem("lenderId"))
      formData.append("additionalDocs", JSON.stringify(additionalDocs))
      formData.append("additionalDetails", JSON.stringify(additionalDetails))

      // Updating existing information object start
      formData.append("buyerName", data.buyerName)
      formData.append("buyerPhone", data.buyerPhone)
      formData.append("buyerPhoneCode", data.buyerPhoneCode)
      formData.append("buyerEmail", data.buyerEmail)
      formData.append("buyerWebsite", data.buyerWebsite)
      formData.append("buyerAddress", data.buyerAddress)
      formData.append("buyerCountry", data.buyerCountry)
      formData.append("buyerDUNSNo", data.buyerDUNSNo)
      formData.append("previousAnnualSale", data.previousAnnualSale)
      formData.append("currentAnnualSale", data.currentAnnualSale)
      formData.append("currency", data.currency)
      formData.append("incoTerms", data.incoTerms)
      formData.append("termsOfPayment", data.termsOfPayment)
      formData.append("productDetails", data.productDetails)

      formData.append("buyersDocId", data.buyersDocId)
      formData.append("invoiceDocument", data.invoiceDocument)
      formData.append("poDocument", data.poDocument)
      formData.append("logisticDocument", data.logisticDocument)
      formData.append("invReportDocument", data.invReportDocument)
      formData.append("grnDocument", data.grnDocument)

      for (let index = 0; index < Object.keys(moreDocs || {}).length; index++) {
        const element = Object.keys(moreDocs || {})[index];
        if (moreDocs?.[element]?.name) {
          formData.append(element, moreDocs[element])
        }
      }
      // Updating existing information object end

      for (let index = 0; index < additionalDocs?.length; index++) {
        const element = additionalDocs[index];
        if (data?.[element.doc_name]?.name) {
          formData.append(element.doc_name, data[element.doc_name])
        }
      }
      await call('POST', 'updateInvoiceAmendmentRequest', formData)
      setshowLoader(false)
      toastDisplay("Details updated successfully", "success", () => {
        window.location = '/Quotes'
      })
      return null
    }

    let validateFields = ["applicantName", "poRefrenceNo", "buyerName", "buyerEmail", "buyerPosition", "buyerContact", "nameAuthorizedSignatory", "beneficiaryBankName",
      "contractAmount", "invoiceRefrenceNo", "invoiceIssueDate", "invoiceDueDate", "creditDays"]

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    console.log("eeeeeeeeeeeeeeeeeeee", err);

    if (!Object.keys(err).length) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("reqId", JSON.parse(additionalReqObj)["id"])
      formData.append("userId", userId)
      formData.append("userName", userName)
      formData.append("buyerId", localStorage.getItem("buyerId"))
      formData.append("lenderId", localStorage.getItem("lenderId"))
      formData.append("additionalDocs", JSON.stringify(additionalDocs))
      for (let index = 0; index < additionalDocs.length; index++) {
        const element = additionalDocs[index];
        if (data?.[element.doc_name]?.name) {
          formData.append(element.doc_name, data[element.doc_name])
        }
      }
      call('POST', 'updateAdditionalDetailsReqByFinancer', formData).then(async (result) => {
        // console.log('running updateAdditionalDetailsReqByFinancer api-->', result);
        setshowLoader(true)

        // Send deal start
        let newFormData = new FormData()

        newFormData.append("applicationId", localStorage.getItem('applicationId'))
        newFormData.append("userId", userId)
        newFormData.append("applicantEmail", userEmail)

        newFormData.append("selectedBuyerLimit", JSON.stringify({ limit: localStorage.getItem("financeLimit") }))
        newFormData.append("contractAmount", data.contractAmount)
        newFormData.append("invoiceRefrenceNo", data.invoiceRefrenceNo)
        newFormData.append("poRefrenceNo", data.poRefrenceNo)

        newFormData.append("invoiceIssueDate", data.invoiceIssueDate)
        newFormData.append("invoiceDueDate", data.invoiceDueDate)
        newFormData.append("reqAmount", data.contractAmount)
        newFormData.append("creditDays", data.creditDays)

        newFormData.append("currency", currencyData.filter(i => {
          if (i.code === data.currency) {
            return i
          }
        })[0]["id"])
        newFormData.append("currencyName", data.currency)
        newFormData.append("lenderId", localStorage.getItem("lenderId"))
        newFormData.append("lenderName", localStorage.getItem("lenderName"))
        newFormData.append("lenderEmail", bankMaster.filter(i => {
          if (i.id / 1 == localStorage.getItem("lenderId") / 1) {
            return i
          }
        })[0]["email_id"])

        newFormData.append("buyerId", localStorage.getItem("buyerId"))
        newFormData.append("buyername", data.buyerName)

        newFormData.append("buyerEmail", data.buyerEmail)
        newFormData.append("bankListId", data.beneficiaryBankName)
        newFormData.append("bankListName", data.beneficiaryBankTitle)
        newFormData.append("logisticId", 0)

        newFormData.append("logisticName", "Others")
        newFormData.append("nameAuthorizedSignatory", data.nameAuthorizedSignatory)
        newFormData.append("buyerPosition", data.buyerPosition)
        newFormData.append("buyerContact", data.buyerContact)

        newFormData.append("invoiceDocument", data["Invoice Document"])
        newFormData.append("poDocument", data["PO Document"])
        newFormData.append("logisticDocument", data["Bill of Lading/Airway Bill"])

        if (data?.["Investigation Report"]?.name) {
          newFormData.append("invReportDocument", data["Investigation Report"])
        }
        if (data?.["Certificate of Origin"]?.name) {
          newFormData.append("grnDocument", data["Certificate of Origin"])
        }
        if (data?.["Packing List"]?.name) {
          newFormData.append("packingListDocument", data["Packing List"])
          if (localStorage.getItem("lenderId") / 1 == 51 && userTypeId / 1 == 19) {
            let tempFormData = new FormData()
            tempFormData.append("file", data["Packing List"])
            tempFormData.append("userEmail", userEmail)
            try {
              await call("POST", "uploadAdditionalStennDocument", tempFormData)
            } catch (error) {
              console.log("error occured while sending packing list doc to stenn", error);
            }
          }
        }
        if (data?.["Shipping Bill"]?.name) {
          newFormData.append("shippingBillDocument", data["Shipping Bill"])
          if (localStorage.getItem("lenderId") / 1 == 51 && userTypeId / 1 == 19) {
            let tempFormData = new FormData()
            tempFormData.append("file", data["Shipping Bill"])
            tempFormData.append("userEmail", userEmail)
            try {
              await call("POST", "uploadAdditionalStennDocument", tempFormData)
            } catch (error) {
              console.log("error occured while sending shipping bill to stenn", error);
            }
          }
        }

        newFormData.append("sellerId", userId)
        newFormData.append("dnc", "on")
        newFormData.append("otherDocMetaData", null)
        newFormData.append("baseUrl", "")
        newFormData.append("applicantName", data.applicantName)
        newFormData.append("userEmail", userEmail)
        newFormData.append("userIdentifier", userIdentifier)
        newFormData.append("buyerIdentifier", data.buyerIdentifier)
        newFormData.append("incoTerms", data.incoTerms)
        newFormData.append("bankAccountNo", userBank.filter(i => {
          if (i.id / 1 == data.beneficiaryBankName / 1) {
            return i
          }
        })[0]["beneficiaryAccountNo"])

        call("post", "applyInvoiceDiscounting", newFormData)
          .then((result) => {
            // console.log("apiresultapplyInvoiceDiscounting===>", result);
            setshowLoader(false)
            toastDisplay("Documents forwarded to stenn kinldy wait till stenn approves it", "success",
              () => {
                window.location = '/Quotes'
              }
            )
          })
          .catch((e) => {
            console.log("error in create finance modal", e);
            toastDisplay(e || "Something went wrong", "error")
            setshowLoader(false)
          });
        // Send deal end

      }).catch((e) => {
        // console.log('error in updateAdditionalDetailsReqByFinancer', e);
        toastDisplay("Something went wrong", "error")
        setshowLoader(false)
      });
    }
    setErrors(err)
  }

  async function askForDiscountingBank() {
    if (data.lcPurpose === "lc_confirmation") {
      setLimitinvoice(true)
    }
  }


  // console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm", infoopenone);

  async function saveReply(tagName) {
    setshowLoader(true)
    await call('POST', 'saveAmendmentReplys', {
      tag: tagName, userId, userName, msg: replyData[tagName], to: localStorage.getItem(isUserLender ? "sellerId" : "lenderId"),
      applicationId: localStorage.getItem('applicationId')
    })
    setshowLoader(false)
    toastDisplay("Reply forwarded successfully", "success")
    setRefresh(refresh + 1)
  }

  const handleReplyChange = (event) => {
    event.persist()
    setReplyData({ ...replyData, [event.target.name]: event.target.value })
  }

  function ReplyOnCommentBox(reason, tagName) {
    return (
      <div className="my-3">
        {replyDbData?.length ? replyDbData.map((item, index) => {
          if (item.tag === tagName) {
            return (
              <div className="my-3 p-3 dunsCard w-100"
              >
                <label className="font-size-14 font-wt-600 text-color-label">{item.fromUser == userId ? `${isAdmin ? "Exporter Comment" : 'Your Comment'} ` : `Reply from ${item.msgFromCompanyName}`}
                  {" | "}{moment(item.createdAt).format("DD/MM/YYYY hh:mm a")}
                </label>
                <p className="font-size-14 font-wt-500 text-color-label" dangerouslySetInnerHTML={{ __html: item.msg }}></p>
              </div>
            )
          }
        }) : null}
        {!isAdmin ? <>
          {!openReplyWindow[tagName] && reason ? (
            <label
              onClick={() => { toggleReplyWindow({ ...openReplyWindow, [tagName]: !openReplyWindow[tagName] }) }}
              className="font-size-16 text-color1 font-wt-600 cursor d-flex">{"Reply "}
              <span className="px-2" style={{ paddingTop: '0.1rem' }}>
                <i
                  className="cursor
                fas font-size-18 fa-arrow-right"></i>
              </span></label>
          ) : (
            <div className="position-relative">
              <NewTextArea key={tagName}
                label={"Reply on Comment"}
                rows={4} name={tagName} value={replyData[tagName] || ""}
                onChange={handleReplyChange} />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    if (!replyData[tagName]) {
                      toastDisplay("Kindly add reply to send", "info")
                    }
                    else {
                      saveReply(tagName)
                    }
                  }}
                  className={`mx-2 new-btn w-auto py-2 px-4 text-white cursor `}>
                  {"Save Reply"}
                </button>
              </div>
            </div>
          )}</> : null}
      </div>
    )

  }


  return (
    <>
      <AddMoreDocumentHandler hide={true}
        purpose={"ApplyForLimitInvoice"} purposeId={localStorage.getItem("buyerId")}
        userTokenDetails={userTokenDetails} onChange={(docs) => {
          let tempMoreDocsObj = {}
          for (let index = 0; index < docs.length; index++) {
            const element = docs[index];
            tempMoreDocsObj["addMore" + index] = element
          }
          setMoreDocs({ ...tempMoreDocsObj })
        }}
      />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div>
        <div className="card mt-1">
          {tab === 0 ? (
            <div className='px-5 py-4 pt-5'>
              <div className="col-md-12 px-0 mt-2">
                <AccordionCommon Heading="Application Form" AccorHeading="HeadingTwo" AccorCollapse="CollapseTwo" accorshow="show" setAccordion={setAccordion}>
                  <div>
                    <p className="requestText">Additional Request</p>
                    <div className="row px-0 mx-0 gap-4">
                      {/* {additionalDocs?.length ? ( */}
                      <div className="col-md-5 px-0">
                        <div className="card p-3 pb-0 borderEEEEEE borderRadius">
                          <p className="requestText text-decoration-underline">Documents</p>
                          {additionalDocs?.length ? additionalDocs.map((item, index) => {
                            return (
                              <div className="col-md-12 mb-3 px-0">
                                <label className="font-size-14">{item.doc_name} <img
                                  onClick={() => { setInfoopenone({ ...infoopenone, [item.doc_name]: !infoopenone[item.doc_name] }) }}
                                  className="w-auto cursor" src={"assets/images/warning.png"} alt="" /></label>
                                {!infoopenone[item.doc_name] && <p className="my-2 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >
                                  {item.documentSample ? (
                                    <div className="my-3">
                                      <FileInput value={item.documentSample} isEditable={false}
                                      />
                                    </div>
                                  ) : null}
                                  {item.reason}
                                  {ReplyOnCommentBox(item.reason, item.doc_name)}
                                </p>}
                                <div className="row align-items-center ">
                                  <div className="form-group col-md-12 mb-0">
                                    <FileInput name={item.doc_name} value={data[item.doc_name]} error={errors[item.doc_name]}
                                      onChange={handleFile} isEditable={!onlyView && !isUserLender && !isAdmin}
                                      onUploadCancel={() => setData({ ...data, [item.doc_name]: null })}
                                    />
                                    {errors[item.doc_name] ? <div class="text-danger mt-2">
                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                      <b>{errors[item.doc_name]}</b></div> : ''}
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null}
                          <div className="col-md-12 mb-3 px-0">
                            <div className="row align-items-center ">
                              <div className="form-group col-md-12 mb-0">
                                <AddMoreDocumentHandler
                                  onDocumentAdd={(fileBinary) => {
                                    if (localStorage.getItem("lenderId") / 1 == 51 && userTypeId / 1 == 19) {
                                      setshowLoader(true)
                                      let formData = new FormData()
                                      formData.append("file", fileBinary)
                                      formData.append("userEmail", userEmail)
                                      call("POST", "uploadAdditionalStennDocument", formData).then(res => {
                                        console.log("inside then uploadAdditionalStennDocument", res);
                                        setshowLoader(false)
                                      }).catch(err => {
                                        console.log("error in uploadAdditionalStennDocument", err);
                                        setshowLoader(false)
                                      })
                                    }
                                  }}
                                  notEditable={isUserLender || isAdmin}
                                  hideSignDocument={true}
                                  // showInfoIcon={true}
                                  //   documentInfoRender={<p className="my-2 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >                                 
                                  //   </p>}
                                  purpose={"AdditionalDocsForQuote"} purposeId={localStorage.getItem('applicationId')}
                                  userTokenDetails={userTokenDetails} onChange={() => { }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ) : null} */}

                      {additionalDetails?.length ? (
                        <div className="col-md-5 px-0">
                          <div className="card p-3 pb-0 borderEEEEEE borderRadius">
                            <p className="requestText text-decoration-underline">Information</p>
                            <div className="col-md-12 mb-3 px-0">

                              {additionalDetails.map((item, index) => {
                                return (
                                  <div>
                                    <div className="row align-items-center ">
                                      {(item.type === "text" || item.type === "date") ?
                                        <div className="form-group col-md-10 mb-0">
                                          <NewInput isDisabled={onlyView || isUserLender} type={item.type} label={item.title}
                                            name={item.title} value={item.val}
                                            onChange={(e) => handleAdditionalDetailsChange(e, index)} />
                                        </div>
                                        : (item.type === "currency" || item.type === "percentage") ?
                                          <div className="form-group col-md-10 mb-0">
                                            <InputWithSelect isDisabled={onlyView || isUserLender} type={"number"} label={item.title}
                                              selectData={item.type === "currency" ? most_used_currencies : [{ code: '%' }]}
                                              selectName={"unit"} selectValue={item.unit}
                                              optionLabel={"code"} optionValue={'code'}
                                              name={item.title} value={item.val}
                                              onChange={(e) => handleAdditionalDetailsChange(e, index)} />
                                          </div>
                                          : null
                                      }

                                      <div className="col-md-2 align-items-center">
                                        <label className="font-size-14"><img
                                          onClick={() => { setInfoopenone({ ...infoopenone, [item.title]: !infoopenone[item.title] }) }}
                                          className="w-auto cursor" src={"assets/images/warning.png"} alt="" /></label>
                                      </div>
                                    </div>

                                    {!infoopenone[item.title] && <p className="mb-4 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >
                                      {item.reason}
                                      {ReplyOnCommentBox(item.reason, item.title)}
                                    </p>}

                                  </div>
                                )
                              })}

                            </div>
                          </div>
                        </div>
                      ) : null}

                      {localStorage.getItem("lenderId") == 51 ? (
                        <div className="col-md-5 px-0">
                          <div className="card p-3 pb-0 borderEEEEEE borderRadius">
                            <p className="requestText text-decoration-underline">Information</p>
                            <div className="col-md-12 mb-3 px-0">

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <InputWithSelect isDisabled={onlyView || isUserLender} type={"text"} label={"Applicant name"}
                                    selectData={[{ name: "Mr" }, { name: "Miss" }]}
                                    selectName={"nameTitle"} selectValue={data.nameTitle}
                                    optionLabel={"name"} optionValue={'name'}
                                    name={'applicantName'} value={data.applicantName} error={errors.applicantName}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"text"} label={"Purchase order number"}
                                    name={"poRefrenceNo"} value={data.poRefrenceNo} error={errors.poRefrenceNo}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"text"} label={"Buyer Email"}
                                    name={"buyerEmail"} value={data.buyerEmail} error={errors.buyerEmail}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"text"} label={"Buyer Position"}
                                    name={"buyerPosition"} value={data.buyerPosition} error={errors.buyerPosition}
                                    onChange={handleChange} />
                                </div>
                              </div>


                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={true} type={"text"} label={"Buyer Name"}
                                    name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <InputWithSelect isDisabled={onlyView || isUserLender} type={"number"} label={"Buyer Contact"}
                                    selectData={countrys}
                                    selectName={"buyerPhoneCode"} selectValue={data.buyerPhoneCode}
                                    optionLabel={"phonecode"} optionValue={'phonecode'}
                                    name={'buyerContact'} value={data.buyerContact} error={errors.buyerContact}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"text"} label={"Name of Authorized Signatory (Buyer)"}
                                    name={"nameAuthorizedSignatory"} value={data.nameAuthorizedSignatory} error={errors.nameAuthorizedSignatory}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewSelect isDisabled={onlyView || isUserLender} label={"Select bank"}
                                    selectData={userBank} name={"beneficiaryBankName"}
                                    value={data.beneficiaryBankName} optionLabel={"beneficiaryBankName"} optionValue={'id'}
                                    onChange={handleChange} error={errors.beneficiaryBankName} />
                                </div>
                              </div>


                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <InputWithSelect isDisabled={onlyView || isUserLender} type={"number"} label={"Invoice Amount"}
                                    selectData={currencyData}
                                    selectName={"currency"} selectValue={data.currency}
                                    optionLabel={"code"} optionValue={'code'}
                                    name={'contractAmount'} value={data.contractAmount} error={errors.contractAmount}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"text"} label={"Invoice Number"}
                                    name={"invoiceRefrenceNo"} value={data.invoiceRefrenceNo} error={errors.invoiceRefrenceNo}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"date"} label={"Invoice issue date"}
                                    name={"invoiceIssueDate"} value={data.invoiceIssueDate} error={errors.invoiceIssueDate}
                                    onChange={handleChange} />
                                </div>
                              </div>

                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender}
                                    minDate={moment().format("YYYY-MM-DD")}
                                    type={"date"} label={"Invoice due date"}
                                    name={"invoiceDueDate"} value={data.invoiceDueDate} error={errors.invoiceDueDate}
                                    onChange={handleChange} />
                                </div>
                              </div>


                              <div className="row align-items-center ">
                                <div className="form-group col-md-12 mb-0">
                                  <NewInput isDisabled={onlyView || isUserLender} type={"number"} label={"Finance Credit Days"}
                                    name={"creditDays"} value={data.creditDays} error={errors.creditDays}
                                    onChange={handleChange} />
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      ) :
                        null}

                    </div>
                  </div>

                  <div>
                    <p className="requestText mt-4">Comments on existing information</p>

                    <div className="row px-0 mx-0 gap-4">
                      {Object.keys(commentsOnInformation || {})?.length ? (
                        <div className="col-md-5 px-0">
                          <div className="card p-3 pb-0 borderEEEEEE borderRadius">
                            <p className="requestText text-decoration-underline">Documents</p>
                            <div className="row mb-3 px-0">
                              {documentsInformation.map((item, index) => {
                                if (commentsOnInformation[item.val]) {
                                  return (
                                    <>
                                      <div>
                                        <label className="font-size-14">{item.label} <img
                                          onClick={() => { setInfoopenone({ ...infoopenone, [item.val]: !infoopenone[item.val] }) }}
                                          className="w-auto cursor" src={"assets/images/warning.png"} alt="" /></label>
                                        {!infoopenone[item.val] && <p className="mb-4 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >
                                          {commentsOnInformation[item.val]}
                                          {ReplyOnCommentBox(commentsOnInformation[item.val], item.val)}
                                        </p>}
                                      </div>
                                      <div className="form-group col-md-12 mb-4">
                                        <FileInput name={item.val} label={item.label} value={data[item.val]} onChange={handleFile}
                                          onUploadCancel={() => setData({ ...data, [item.val]: null })}
                                          isEditable={!onlyView && !isUserLender && !isAdmin} />
                                      </div>
                                    </>
                                  )
                                }
                              })}

                              {Object.keys(moreDocs || {}).map((item, index) => {
                                if (commentsOnInformation[item]) {
                                  return (
                                    <>
                                      <div>
                                        <label className="font-size-14">{moreDocs?.[item]?.["doc_name"] || moreDocs?.[item]?.["name"] || "Add More"} <img
                                          onClick={() => { setInfoopenone({ ...infoopenone, [item]: !infoopenone?.[item] }) }}
                                          className="w-auto cursor" src={"assets/images/warning.png"} alt="" /></label>
                                        {!infoopenone[item] && <p className="mb-4 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >
                                          {commentsOnInformation[item]}
                                          {ReplyOnCommentBox(commentsOnInformation[item], item)}
                                        </p>}
                                      </div>
                                      <div className="form-group col-md-12 mb-4">
                                        <FileInput name={item} value={moreDocs[item]}
                                          onUploadCancel={() => setMoreDocs({ ...moreDocs, [item]: null })} onChange={handleFile2}
                                          isEditable={!onlyView && !isUserLender && !isAdmin}
                                        />
                                      </div>
                                    </>
                                  )
                                }
                              })}

                            </div>
                          </div>
                        </div>
                      ) : null}

                      {Object.keys(commentsOnInformation || {})?.length ? (
                        <div className="col-md-5 px-0">
                          <div className="card p-3 pb-0 borderEEEEEE borderRadius">
                            <p className="requestText text-decoration-underline">Information</p>
                            <div className="row mb-3 px-0">
                              {fieldsInformation.map((item, index) => {
                                // console.log("cccccccccccccccccccc", commentsOnInformation);
                                if (commentsOnInformation[item.val]) {
                                  return (
                                    <>
                                      <div className="form-group col-md-10 mb-0">
                                        <item.comp isDisabled={onlyView || isUserLender} type={item.type} label={item.name}
                                          value={data[item.val]} name={item.val}
                                          onChange={handleChange}
                                          selectData={item.selectData || []}
                                          selectName={item.unit} selectValue={data[item.unit]}
                                          optionLabel={item.optionLabel} optionValue={item.optionValue}
                                        />
                                      </div>
                                      <div className="col-md-2 align-items-center">
                                        <label className="font-size-14"><img
                                          onClick={() => { setInfoopenone({ ...infoopenone, [item.val]: !infoopenone[item.val] }) }}
                                          className="w-auto cursor" src={"assets/images/warning.png"} alt="" /></label>
                                      </div>
                                      {!infoopenone[item.val] && <p className="mb-4 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >
                                        {commentsOnInformation[item.val]}
                                        {ReplyOnCommentBox(commentsOnInformation[item.val], item.val)}
                                      </p>}
                                    </>
                                  )
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {!isAdmin && !onlyView && !isUserLender && (additionalDetails?.length || additionalDocs?.length || Object.keys(commentsOnInformation || {})?.length) ? (
                    <div className="pt-4 ">
                      <button type="button"
                        onClick={() => {
                          if (isQuoteDenied) {
                            toastDisplay('Action not available, as quote is in denied state', "info")
                          }
                          else {
                            handleValidation()
                          }
                        }}
                        className={`mx-2 new-btn w-auto py-2 px-4 text-white cursor `}>
                        {"Submit Details"}
                      </button>
                      {/* <p className="text-color-0C0C0C font-wt-400 font-size-12 letter-spacing05 mx-4 mt-3 mb-0"> <span><img className="w-auto cursor px-2" src={"assets/images/warning.png"} alt="" /></span>Quote not selected yet, you need to select a quote first for approval.  <a href="/LcSeequotes" className="text-color1">Go back to quote </a></p> */}
                    </div>
                  ) : null}
                </AccordionCommon>
              </div>



              <div className="col-md-12 px-0 mt-2">
                <AccordionCommon Heading="Remark on application" accorshow="show" AccorHeading="HeadingOne" AccorCollapse="CollapseOne">
                  {/* {selectedFinancierRemarks?.buyers_remark?.remark ? (
                    <>
                      <div className="card col-md-10 p-3 borderRadius" accordion={accordion} setAccordion={setAccordion}>
                        <p className="mb-0 font-size-14 font-wt-300 text-color-0C0C0C letter-spacing04 lineheight19">{`Remark Without Quote`}</p>
                        <p className="mb-0 font-size-14 font-wt-300 text-color-0C0C0C letter-spacing04 lineheight19">{selectedFinancierRemarks?.buyers_remark?.remark || 'NA'}</p>
                      </div>
                      {ReplyOnCommentBox(selectedFinancierRemarks?.buyers_remark?.remark, "replyOnRemark")}
                    </>
                  ) : null} */}
                  {selectedFinancierRemarks?.buyers_credit?.assignRemark ? (
                    <>
                      <div className="card col-md-10 p-3 borderRadius mt-4" accordion={accordion} setAccordion={setAccordion}>
                        <p className="mb-0 font-size-14 font-wt-300 text-color-0C0C0C letter-spacing04 lineheight19">{`Remark With Quote`}</p>
                        <p className="mb-0 font-size-14 font-wt-300 text-color-0C0C0C letter-spacing04 lineheight19">{selectedFinancierRemarks?.buyers_credit?.assignRemark || 'NA'}</p>
                      </div>
                      {ReplyOnCommentBox(selectedFinancierRemarks?.buyers_credit?.assignRemark, "replyOnRemarkWithQuote")}
                    </>
                  ) : null}
                </AccordionCommon>
              </div>
            </div >
          ) : null}
          {/* {tab === 1 ? (
            <div className='px-5 py-4 pt-5'>
              <p>tab 1</p>
            </div >
          ) : null} */}
        </div>
      </div >
    </>
  );
}
export default InformationIcon;