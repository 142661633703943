
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import { reviewBG, reviewBusiness, reviewOther } from "./BGGetConfirmation";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

const tabLabels = [
  { name: "Financier" },
  { name: "Application Form" },
  { name: "Documents" }
]

const BGSignTermSheet = ({ userTokenDetails, navToggleState }) => {

  let bgQuoteDetails = JSON.parse(localStorage.getItem("bgQuoteDetails"))

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(2)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30 })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])
  const [additionalReqDetails, setAdditionalReqDetails] = useState([])
  const [overlay, setOverlay] = useState(false);
  const [enableStennQuote, toggleStennQuote] = useState(false)
  const [seeRateCalculator, toggleSeeRateCalculator] = useState(false)
  const [financiers, setFinanciers] = useState([])
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [availableTermSheets, setAvailableTermSheets] = useState([])
  const [tableDocsData, setTableDocsData] = useState([])
  const [multInvPo, setMultInvPo] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })


  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  async function loadData() {
    setshowLoader(true)
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: bgQuoteDetails.applicationId, fetchBGDocs: true })
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    let termSheetDocument = await getDocDetails(bgQuoteDetails.termSheet)
    setData({
      ...data, ...bgQuoteDetails.details, ...tempLimitDocsDetails, selectedLenderName: bgQuoteDetails.selectedLenderName,
      financierQuotes: JSON.parse(bgQuoteDetails.financierQuotes), termSheetDocument
    })
    call('POST', 'getRecommendedFinanciers', { forBG: true }).then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    setshowLoader(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  async function handleValidation() {
    let err = {}
    if (tab === 0) {
      setTab(1)
    }
    else if (tab === 1) {
      setTab(2)
    }
    if (tab === 2) {
      if (!(data.termSheetDocument && data.termSheetDocument.signatureId)) {
        err["termSheetDocument"] = "Signing term sheet is mandatory"
      }
      if (!Object.keys(err).length) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("applicationId", bgQuoteDetails.applicationId)
        formData.append("exporterSignedContract", true)
        formData.append("selectedLenderId", bgQuoteDetails.selectedFinancier)
        formData.append("userName", userName)
        formData.append("termSheetDocId", bgQuoteDetails.termSheet)
        if (data.termSheetDocument?.name) {
          formData.append("termSheetDocument", data.termSheetDocument)
        }
        if (data.termSheetDocument?.signatureId) {
          formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)
        }
        call('POST', 'signBgTermSheet', formData).then((result) => {
          // console.log('running signBgTermSheet api-->', result);
          toggleApplicationSubmitPopup({ show: true })
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in signBgTermSheet', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false)
        });
      }
    }
    setErrors(err)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('bgApplicationId', bgQuoteDetails.applicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'bgQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Termsheet signed successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="bg_quote_details" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Bank Guarantee > Sign Termsheet"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (index < tab) {
                            setTab(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabLabels} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => {
                      if (index < tab) {
                        setTab(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 '>
                    <div className="px-5 d-flex justify-content-end">
                      <button type="button"
                        onClick={() => handleValidation()}
                        className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                        {"Continue"}
                      </button>
                    </div>
                    <div id={"scrollContainerId"} style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className="row align-items-center d-flex w-100 my-4">
                      {data.financierQuotes?.length ? data.financierQuotes.map((key, index) => {
                        return (
                          <div
                            ref={(el) => quoteCardRef.current[index] = el} className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                            <div className={`text-center card py-4 pb-0 px-3
                              ${key.financierAction != "Approved" ? "limitdeniedcontract" : "selectfinancercardrec"}`}
                            >
                              <>
                                <p className={`cursor ${recommendedFinanciers.includes(key.lender_id / 1) ? ' mt-5 ' : ' mt-1 '} pt-2 mb-1 d-flex flex-row justify-content-center align-items-center`}><img className="mr-2"
                                  src={key.logo} alt="" />
                                  <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                                </p>
                              </>
                              {key.financierAction != "Approved" ? (
                                <>
                                  <div className="limitDeniedCardV2">
                                    <div className="mb-2">
                                      <img src={"assets/images/block.png"} height={25} width={25} />
                                    </div>
                                    <div className="font-size-14 font-wt-600">
                                      Limit Denied
                                    </div>
                                  </div>
                                </>)
                                : null}
                              {key.lender_id / 1 == bgQuoteDetails.selectedFinancier / 1 ? (
                                <div style={{ right: '-1.4rem' }} className="position-absolute" >
                                  <img src="assets/images/selected_tag.svg" />
                                </div>
                              ) : null}
                              {key.financierAction != "Approved" ? <></> : (
                                <>
                                  <div className="mt-2 lineheight19">
                                    <p className="font-size-14 mb-1">
                                      {`Offer Validity : ${key.offerValidity}`}</p>
                                    <p style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                                  </div>
                                  <div className="mt-2 lineheight19">
                                    <p className="mb-0 text-secondary  font-size-12">Confirmation Rate</p>
                                    <p className="font-size-16">{key.confirmationRate ? (key.confirmationRate + " %") : "NA"}</p>
                                  </div>
                                  <div className="position-relative lineheight19">
                                    <p
                                      className="mb-0 font-wt-600 font-size-12">Other Charges
                                    </p>
                                    <p className="font-size-16 totalChargesLabel">{getBGTotalOtherCharges(key.otherCharges).toFixed(2) + " " + (key.otherCharges?.[0]?.["unit"] || "-")}</p>
                                    <div className="otherChargePopup">
                                      {key.otherCharges && key.otherCharges.length ? key.otherCharges.map((j) => (
                                        <>
                                          <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                            <label className="text-color-label">{j.name}</label>
                                            <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                          </div>
                                        </>
                                      )) : null}
                                    </div>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">Total Charges</p>
                                    <span className="selectfinacne-subheading font-size-10">($ 50,000/30 days)</span>
                                    <p className="font-size-16">{getBGTotalCharges(key).toFixed(2) + " " + (key.otherCharges?.[0]?.["unit"] || "-")}</p>
                                  </div>
                                </>
                              )}

                              <p className="mt-1 mb-4"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-12 color434343 letter-spacing05 ms-2 cursor"
                                onClick={async () => {
                                  let reqObj = {
                                    senderId: userId,
                                    receiverId: key.lender_id / 1,
                                    textMessage: 'Hii',
                                    chat_room_name: "CHAT" + new Date().getTime(),
                                    includeAdmins: true,
                                    bgApplicationId: bgQuoteDetails.applicationId,
                                    receiverParties: key.lender_id / 1,
                                    dontSendInitialMsg: true
                                  }
                                  setshowLoader(true)
                                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                  setshowLoader(false)
                                  getChatDetails({
                                    chat_room_id: apiResp.id,
                                    loggedInUser: userId
                                  })
                                  setSelectedChat({
                                    chatRoomId: apiResp.id,
                                    receiverName: `${key.lender_name} - ${apiResp.id}`,
                                    bgApplicationId: bgQuoteDetails.applicationId,
                                    parties: apiResp.parties,
                                    userId: userId,
                                    isChatOpen: true,
                                    receiverId: key.lender_id / 1
                                  })
                                }}
                              >Chat with financer</span></p>

                            </div>
                          </div>
                        )
                      }) : null}
                    </div>
                    {data.financierQuotes?.length > 4 && quoteView != 'table' ? (
                      <div className="col-12 mx-auto text-end">
                        <img onClick={() => {
                          scrollLeft()
                        }}
                          className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                        <img onClick={() =>
                          scrollRight()
                        }
                          className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {reviewBusiness.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>BG Details</u></label>
                      <div className='row'>
                        <div className="col-md-6 pe-5">
                          <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">BG type</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >{data.domesticBG ? "Domestic" : 'Foreign'}</span> </p>
                        </div>
                        {reviewBG.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Other Details</u></label>
                      <div className='row'>
                        {reviewOther.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='w-100 mt-5'>
                      <NewTablev2 columns={[
                        {
                          subColumns: "Document Name", subColumnStyle: { width: '30%' }
                        },
                        {
                          subColumns: "Attach Document", subColumnStyle: { width: '40%' }
                        },
                        {
                          subColumns: "Sign", subColumnStyle: { width: '15%' }
                        },
                        {
                          subColumns: "Action", subColumnStyle: { width: '15%' }
                        }
                      ]}>
                        {data.bgTextDocument?.name ?
                          <tr>
                            <td>BG Text</td>
                            <td><FileInput isEditable={false} value={data.bgTextDocument} /></td>
                            <td>
                              {!data[`bgTextDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`bgTextDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.contractCopyDocument?.name ?
                          <tr>
                            <td>Contract Copy</td>
                            <td><FileInput isEditable={false} value={data.contractCopyDocument} /></td>
                            <td>
                              {!data[`contractCopyDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`contractCopyDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.declarationDocument?.name ?
                          <tr>
                            <td>Declaration</td>
                            <td><FileInput isEditable={false} value={data.declarationDocument} /></td>
                            <td>
                              {!data[`declarationDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`declarationDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.boardResolutionDocument?.name ?
                          <tr>
                            <td>Board Resolution</td>
                            <td><FileInput isEditable={false} value={data.boardResolutionDocument} /></td>
                            <td>
                              {!data[`boardResolutionDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`boardResolutionDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                      </NewTablev2>
                    </div>
                    <div className='w-100 mt-4'>
                      <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {data.selectedLenderName?.split(",")?.map((key) => {
                          return (
                            <label key={key}
                              className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                              {key}
                            </label>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 2 ? (
                  <div className="d-flex row px-5 pt-4 pb-4">
                    <div className='w-100 mt-5'>
                      <NewTablev2 columns={[
                        {
                          subColumns: "Document Name", subColumnStyle: { width: '30%' }
                        },
                        {
                          subColumns: "Attach Document", subColumnStyle: { width: '40%' }
                        },
                        {
                          subColumns: "Sign", subColumnStyle: { width: '15%' }
                        },
                        {
                          subColumns: "Action", subColumnStyle: { width: '15%' }
                        }
                      ]}>
                        <tr>
                          <td>Term Sheet
                            {bgQuoteDetails.termSheetSignType === "Wet" ? (
                              <div class="text-danger mb-4 font-size-10">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{"Upload Signed & Stamped Termsheet"}</b>
                              </div>
                            ) : null}
                          </td>
                          <td>
                            <FileInput
                              changeEditIconToUpload={true}
                              name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                              onChange={handleFile} isEditable={bgQuoteDetails.termSheetSignType === "Wet"}
                              onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                            />
                            {errors.termSheetDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.termSheetDocument}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`termSheetDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`termSheetDocument`]?.name); setData({ ...data, docToSign: data[`termSheetDocument`], docSignName: `termSheetDocument` }) }}
                                src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`termSheetDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>
                        {data.bgTextDocument?.name ?
                          <tr>
                            <td>BG Text</td>
                            <td><FileInput isEditable={false} value={data.bgTextDocument} /></td>
                            <td>
                              {!data[`bgTextDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`bgTextDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.contractCopyDocument?.name ?
                          <tr>
                            <td>Contract Copy</td>
                            <td><FileInput isEditable={false} value={data.contractCopyDocument} /></td>
                            <td>
                              {!data[`contractCopyDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`contractCopyDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.declarationDocument?.name ?
                          <tr>
                            <td>Declaration</td>
                            <td><FileInput isEditable={false} value={data.declarationDocument} /></td>
                            <td>
                              {!data[`declarationDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`declarationDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.boardResolutionDocument?.name ?
                          <tr>
                            <td>Board Resolution</td>
                            <td><FileInput isEditable={false} value={data.boardResolutionDocument} /></td>
                            <td>
                              {!data[`boardResolutionDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`boardResolutionDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 0 ? null :
                  <div className="pb-5 px-5">
                    <button type="button"
                      onClick={() => handleValidation()}
                      className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                      {tab === 2 ? "Submit" : "Continue"}
                    </button>
                  </div>}

              </div>

            </div>

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(BGSignTermSheet)