

import React, { useEffect, useState } from 'react';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';
import { dataURItoBlob, getDocDetails, handleFileCommonFunction } from '../../../utils/myFunctions';
import FilePreview from '../../utilComponents/previewFile';
import { NewInput, NewSelect } from '../../../utils/newInput';
import { FileInput } from '../../../utils/FileInput';


const BankReimburseForm = ({ doApiCall, afterApiCall, userTokenDetails, contractData, setshowLoader, refresh, setrefresh, hideSubmitBtn, isDisabled }) => {
  console.log("reimburse_dataaaaaaaaaaaaaaaaaa", contractData);
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [txns, setTxns] = useState([null])
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" })
  };
  const handleFiles = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    setError({ ...error, [event.target.name]: "" })
  };

  useEffect(() => {
    patchPaymentDetailsFromDb()
  }, [])

  async function patchPaymentDetailsFromDb() {
    if (contractData?.reimburse_data) {
      let i = 0
      let tempTxn = []
      let tempData = {}
      while (contractData?.reimburse_data?.[`txnId${i}`]) {
        tempTxn.push(null)
        tempData[`txnId${i}`] = contractData?.reimburse_data?.[`txnId${i}`]
        tempData[`txnAmount${i}`] = contractData?.reimburse_data?.[`txnAmount${i}`]
        tempData[`txnDate${i}`] = contractData?.reimburse_data?.[`txnDate${i}`]
        tempData[`txnStatus${i}`] = contractData?.reimburse_data?.[`txnStatus${i}`]

        if (contractData?.reimburse_data?.[`txnDoc${i}`]) {
          let docApiResp = await getDocDetails(contractData?.reimburse_data?.[`txnDoc${i}`])
          console.log("docApiRespppppppppppp", docApiResp);
          if (docApiResp?.filebase64) {
            let blob = dataURItoBlob(docApiResp?.filebase64);
            tempData[`txnDoc${i}`] = new File([blob], docApiResp.name, { type: "application/pdf" });
            tempData[`txnDoc${i}`]["filebase64"] = docApiResp?.filebase64
          }
        }
        i = i + 1
      }
      setTxns([...tempTxn])
      setData({ ...data, ...tempData })
    }
  }

  useEffect(() => {
    if (doApiCall) {
      submitLCprocessData()
    }
  }, [doApiCall])

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function submitLCprocessData() {
    // validation
    let paymentStatus = 1
    let err = {}
    let validate = [`txnId`, `txnAmount`, `txnDate`, `txnDoc`, `txnStatus`]
    for (let index = 0; index < txns.length; index++) {
      if (data[`txnStatus${index}`] === "Pending") {
        paymentStatus = 0
      }
      for (let j = 0; j < validate.length; j++) {
        if (!data[`${validate[j]}${index}`]) {
          err[`${validate[j]}${index}`] = "Mandatory field"
        }
      }
    }
    if (Object.keys(err).length) {
      setError({ ...error, ...err })
      if (afterApiCall) {
        afterApiCall()
      }
      return null
    }

    setshowLoader(true)

    let postObj = {}
    if (contractData.paymentType === "LC") {
      postObj = {
        paymentStatus,
        "contract_id": contractData.id,
        "contract_no": contractData.contractNo,
        "party_a": contractData.buyer_bank,
        "party_b": contractData.exporter_id,
        "process_status": 9,
        "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank,
        "reimburseData": JSON.stringify(data),
        "txns": JSON.stringify(txns),
        "contractCurrency": contractData.currency
      }
    } else if (contractData.paymentType === "DADP") {
      postObj = {
        "contract_id": contractData.id,
        "contract_no": contractData.contractNo,
        "process_status": 7,
        "dadpType": contractData.dadpType,
        "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank,
        "reimburseData": JSON.stringify(data)
      }
    } else if (contractData.paymentType === "TT") {
      postObj = {
        "contract_id": contractData.id,
        "contract_no": contractData.contractNo,
        "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank,
        "reimburseData": JSON.stringify(data)
      }
    }
    for (let index = 0; index < txns.length; index++) {
      if (data[`txnDoc${index}`]?.name) {
        postObj[`txnDoc${index}`] = data[`txnDoc${index}`]
      }
    }

    console.log("postObj===>", postObj)

    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    let route = contractData.paymentType === "LC" ? 'updatelcprocess' : contractData.paymentType === "DADP" ? 'updatedpprocess' : contractData.paymentType === "TT" ? 'updatettprocess' : ""
    call('POST', route, formData).then(async (result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay("details saved", "success")
      if (afterApiCall) {
        afterApiCall()
      }
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in updatelcprocess ->", error)
      toastDisplay(error, "error")
      if (afterApiCall) {
        afterApiCall()
      }
    })

  }

  //---------------------------------------------------------------------------------------------------------------------

  return (<>

    {showPreviewModal &&
      <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal} />}

    <div className="col-md-12 mt-3 row">
      {txns.map((i, j) => {
        return <>
          <label className="col-md-12 text-center font-wt-600 font-size-16 mb-3 mt-1">{`Payment Details: ${j + 1}`}</label>
          <div className="col-md-3 mt-2">
            <NewInput isDisabled={isDisabled} label={'Transaction ID'} name={`txnId${j}`} value={data[`txnId${j}`]} onChange={handleChange}
              error={error[`txnId${j}`]} />
          </div>
          <div className="col-md-3 mt-2">
            <NewInput isDisabled={isDisabled} type={"number"} label={`Transaction Amount (${contractData.currency})`} name={`txnAmount${j}`} value={data[`txnAmount${j}`]} onChange={handleChange}
              error={error[`txnAmount${j}`]} />
          </div>
          <div className="col-md-3 mt-2">
            <NewInput isDisabled={isDisabled} type={"datetime-local"} label={'Transaction Date/Time'} name={`txnDate${j}`} value={data[`txnDate${j}`]} onChange={handleChange}
              error={error[`txnDate${j}`]} />
          </div>
          <div className="col-md-3 mt-2">
            <NewSelect isDisabled={isDisabled} selectData={[{ name: 'Pending' }, { name: 'Completed' }]} label={'Payment Status'} name={`txnStatus${j}`} value={data[`txnStatus${j}`]} onChange={handleChange}
              error={error[`txnStatus${j}`]} optionLabel={"name"} optionValue={"name"} />
          </div>

          <div className="col-md-12 my-4 pb-3">
            <div className="d-flex align-items-center">
              {isDisabled && !data[`txnDoc${j}`] ? null :
                <div className='col-5'>
                  <label className='font-size-16 font-wt-500'>Payment Receipt</label>
                  <FileInput name={`txnDoc${j}`} value={data[`txnDoc${j}`]} error={error[`txnDoc${j}`]} isEditable={!isDisabled}
                    onChange={e => {
                      handleFileCommonFunction(e, data, error, setData, setError, setshowLoader)
                    }} onUploadCancel={() => { setData({ ...data, [`txnDoc${j}`]: null }) }} />
                  {error[`txnDoc${j}`] ? <div class="text-danger mt-2 font-size-12">
                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    <b>{error[`txnDoc${j}`]}</b></div> : ''}
                </div>}
              {isDisabled ? null : <>
                {txns.length > 1 ?
                  <div className='col-3 d-flex justify-content-center pt-4'>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                      let tempTxns = txns
                      tempTxns = tempTxns.filter((l, m) => {
                        if (m != j) {
                          return true
                        }
                      })
                      setTxns([...tempTxns])
                    }}>Remove</button>
                  </div> : null}
                <div className='col-3 d-flex justify-content-center pt-4'>
                  <button type="button" className="btn btn-success btn-sm" onClick={() => {
                    setTxns(txns.concat([null]))
                  }}>Add more</button>
                </div>
              </>}
            </div>
          </div>
          {hideSubmitBtn ? null :
            <hr />}
        </>
      })}

      {hideSubmitBtn ? null :
        <div className="col-md-12 mt-3 text-center">
          <button type="button" className="new-btn btn-sm py-2 text-white px-3" onClick={() => submitLCprocessData()}>Save Details</button>
        </div>}
    </div>

  </>)
}


export default BankReimburseForm
