import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { getUserDataFromCookie } from '../../utils/cookieHelper';
import { encryptData } from '../../utils/myFunctions';

const AclLogin = ({ setLogin }) => {

  const [data, setData] = useState({});
  const userDetails = getUserDataFromCookie();

  useEffect(() => {
    //User details from cookie

    setData({ ...data, 'loggedInUser': userDetails.email })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  // Apply Loan Form
  function validateUser() {
    console.log('==== Login data ====', data);
    call('post', 'validateAclUser', { ...data, password: encryptData(data["password"]) }).then((result) => {
      console.log('==== Login result ====', result);
      if (result.validated) {
        toastDisplay(result.msg, "success")
        setLogin({ state: false, validated: result.validated })
        // Disabled Auth after a defined time
        setTimeout(function () { setLogin({ state: false, validated: false }) }, 60000);
      } else {
        toastDisplay(result.msg, "error")
      }
    }).catch((e) => {
      toastDisplay(e.msg, "error")
      console.log('error in getSuppvalidateAclUserliers', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  // JSX return
  return (
    <div className="modal show d-block" id="CreateContract">
      <div className={"modal-dialog modal-xs border-inner "} id="parent_class">
        <form noValidate>
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white font-size-16 font-wt-600">Login</h4>
              <button type="button" className="close" onClick={() => setLogin({ state: false, validated: false })}>×</button>
            </div>
            <div className="modal-body">

              <div className="col-md-12">
                <div className="row form-group">

                  <div className="col-md-4 form-group">
                    <label>Username</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input className="form-control" type="email" name="email" onChange={handleChange} autocomplete="off" required />
                  </div>

                  <div className="col-md-4 form-group">
                    <label>Password</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input className="form-control" type="password" name="password" onChange={handleChange} autocomplete="off" required />
                  </div>

                </div>
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn bg-1ea3ae text-white btn-sm" onClick={() => validateUser()} >Login</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AclLogin;
