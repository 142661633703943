import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import avatarUrl from '../../utils/makeAvatarUrl';


const SendInviteToUser = ({ userTokenDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // states and variables
  const [data, setdata] = useState({})
  const [userData, setUserData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Useeffect
  useEffect(() => {

    setshowLoader(true)
    call('POST', 'getuserprofiledata', { 'email': userEmail, 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      setUserData(result)
      setshowLoader(false)

    }).catch((e) => {
      setshowLoader(false)

      console.log('error in getuserprofiledata', e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Subimit handlers
  function sendInvites(mails) {
    setshowLoader(true)

    let InviteCard = document.getElementById("InviteCard").outerHTML;

    const mailData = {
      mailReceiver: mails,
      mailSubject: "Join Me on Tradereboot",
      mailBodyHtml: InviteCard
    }

    toastDisplay("sending mail...", "info");
    call('POST', 'sendMail', mailData).then((res) => {
      console.log("here is the mail res=>", res)
      setshowLoader(false)
      toastDisplay("Mail sent successfuly.", "success");
    }).catch(error => {
      console.log("Error occure while sending mail:", error)
      setshowLoader(false)
      toastDisplay("Unable to process mail.", "error");
    });
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Html body
  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <div className="modal-body calc-inner-modal">
        <div className="col-md-12 mb-2 text-center">
          <div className="col-md-12 row">
            <label className="col-md-12 m-2 text-info">Enter User's Email to send Invite</label>
            <div className="col-md-12">
              <textarea name="userEmails" placeholder="Enter comma seperated user emails here" value={data.userEmails} className="form-control" onChange={handleChange} />
            </div>
            <div className="col-md-12 m-4">
              <input type="button" value="Send Your Invite Card" className="btn btn-primary btn-sm" onClick={() => sendInvites(data.userEmails)} />
            </div>
            <div className="col-md-12 m-2">
              <h3 className="text-danger error-contract">{data.displayError}</h3>
            </div>
          </div>
          <hr />

          <div className="col-md-12" >
            <div className="row bg-shadow" style={{ "min-height": "200px" }} >
              <div className="col-md-12 row">
                <div className="col-md-3 chat-message mr-0 ml-3 mt-3">
                  <img src={avatarUrl(userData.user_avatar)} alt="Avatar" width="150" height="150" />
                </div>
                <div className="col-md-8 mt-3 text-center" id="InviteCard">
                  <h1 className="mt-2 text-primary">  {userData.company_name ? userData.company_name : '--'}</h1>
                  <h3 className="mt-2 text-primary">My Referral Code:  {userData.refercode ? userData.refercode : '--'}</h3>
                  <p>Hi Join me on Tradereboot, Use my Referral Code to directly join my Network</p>
                  <div className="col-md-12 text-center">
                    <a className="btn btn-primary" href={window.location.origin + "/registration?referralCode=" + userData.refercode}>Join Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}


export default SendInviteToUser