import React from "react";

const SelectBuyerModal = ({ modal, setModal, children, customsize }) => {
  return (
    <>
      <div className={`modal fade ${modal && "show"}`} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className={`modal-content pb-5`} style={{ width: "768px" }}>
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
            </div>
            <div className="modal-body text-center">
              {children}
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
export default SelectBuyerModal;