import React, { useState, useRef, useEffect } from "react";
import DropdownSearch from "../dropdownWithSearch";
import AddTarget from "./addTarget";
import TargetCard from "./targetCard";
import call from "../../../../service";
import { useId } from "react";
import { format } from "date-fns";

export default function ObjectiveView({ allData, userId }) {
  const [dropdownItems, setDropdownItems] = useState(["Select All"]);
  const [selectedItem, setSelectedItem] = useState('');
  const [itemsForCompanies, setItemsForCompanies] = useState([]);
  const [itemsForWhom, setItemsForWhom] = useState(["Item Name", "Invoice Number", "State", "Item Group", "Customer Group", "Continent", "Item Category"]);
  const [itemsForTargetTypes, setItemsForTargetTypes] = useState(["Customer Count", "GP", "Purchase", "Sales"]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [targets, setTargets] = useState([]);
  const [allTargets, setAllTargets] = useState([]);


  const fetchAndSetTargets = () => {
    call('POST', 'getalltargets', { userId: userId })
      .then((result) => {
        const savedTargets = result["allTargets"]
        console.log("targets", savedTargets)
        if (savedTargets) {
          setAllTargets(savedTargets)
          setTargets(savedTargets)
          const durations = [...new Set(savedTargets.map(target => `${format(new Date(target.start_date), 'MM/dd/yyyy')} To ${format(new Date(target.end_date), 'MM/dd/yyyy')}`))];
          // setDropdownItems(durations);
          setDropdownItems([...dropdownItems, ...durations]);
        }

      }).catch((e) => {
        console.log('error in target_view', e)
      })
  }
  useEffect(() => {
    fetchAndSetTargets()
  }, []);
  const quotes = [
    { quote: 'Once your target is clear, the effort will follow.', author: 'Anand Mahindra' },
    { quote: 'Setting goals is the first step in turning the invisible into the visible.', author: 'Tony Robbins' },
    { quote: 'A goal without a plan is just a wish.', author: 'Antoine de Saint-Exupéry' },
    { quote: 'You are never too old to set another goal or to dream a new dream.', author: 'C.S. Lewis' },
    { quote: 'The trouble with not having a goal is that you can spend your life running up and down the field and never score.', author: 'Bill Copeland' }
  ];

  const randomQuoteObj = quotes[Math.floor(Math.random() * quotes.length)];
  const handleAddTarget = (target) => {

    // save to db
    let objectAPI = {
      "userId": userId,
      "duration": target.duration,
      "companies": target.companies,
      "whom": target.whom,
      "target": target.target
    }
    call('POST', 'addtarget', objectAPI)
      .then((result) => {
        if (result) {
          console.log("new", result)
          const updatedTargets = [...allTargets, ...result];
          setTargets(updatedTargets);
          setAllTargets(updatedTargets)
          if (!dropdownItems.includes(target.duration)) {
            // setDropdownItems([...dropdownItems, `${format(new Date(target.start_date), 'MM/dd/yyyy')} To ${format(new Date(target.end_date), 'MM/dd/yyyy')}`]);
            setDropdownItems([...dropdownItems, `${format(new Date(target.start_date), 'MM/dd/yyyy')} To ${format(new Date(target.end_date), 'MM/dd/yyyy')}`]);
          }
        }

      }).catch((e) => {
        console.log('error in target_view', e)
      })
  };
  const handleSelectionInDropdown = (item) => {
    setSelectedItem(item);
    // Filter targets based on selected duration
    const filteredTargets = item === "Select All" ? allTargets : allTargets.filter(target => `${format(new Date(target.start_date), 'MM/dd/yyyy')} To ${format(new Date(target.end_date), 'MM/dd/yyyy')}` === item);
    setTargets(filteredTargets);

  };
  const pickerRef = useRef(null);



  const handleAddTargetClick = () => {

    console.log("show")
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    console.log("closed")
    setIsPopupVisible(false);
  };

  useEffect(() => {
    if (allData) {
      const partyNames = [...new Set(allData.map((item) => item.partyName))]
      setItemsForCompanies(partyNames)


    }

  }, [allData])



  return (
    <div className="container bg-white w-100 h-100 rounded shadow position-relative">
      <div className="d-flex justify-content-between align-items-center p-2 border-bottom">
        <h6 className="p-0 m-0">Target</h6>
        <div className="d-flex justify-content-between align-items-center">
          <DropdownSearch className="mt-1" items={dropdownItems} onSelect={handleSelectionInDropdown} placeholder={"Select Target Duration"} defaultItem={"Select All"} />
          <button className="btn btn-primary" onClick={handleAddTargetClick}>Add Target</button>
        </div>
      </div>
      <div>
      </div>
      <div>
        {targets?.length ? (
          targets.map((target, index) => (
            <div key={index}>
              <TargetCard target={target} allData={allData} userId={userId} fetchAndSetTargets={fetchAndSetTargets} />
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: '20px', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <h3 className="m-5 p-5" style={{ color: "#0C0C0C", fontSize: '24px', fontWeight: 'normal', marginBottom: '10px' }}>
                "{randomQuoteObj.quote}"
              </h3>
              <p style={{ color: "#6C757D", fontStyle: 'italic', fontSize: '18px' }}>
                — {randomQuoteObj.author}
              </p>
            </div>
          </div>
        )}
      </div>


      {isPopupVisible && (
        <AddTarget
          setIsPopupVisible={setIsPopupVisible}
          handleClosePopup={handleClosePopup}

          pickerRef={pickerRef}
          itemsForCompanies={itemsForCompanies}
          itemsForWhom={itemsForWhom}
          itemsForTargetTypes={itemsForTargetTypes}
          allData={allData}
          onAddTarget={handleAddTarget}
        />
      )}
    </div>
  );
}
