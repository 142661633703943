import React, { useState, useEffect } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Header from '../homeComponents/header';
import validate from '../../utils/validation_files/companyProfileFormValidation';
import useForm from '../../utils/useForm';
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import { setRegistrationData, setaddBankObj } from '../../store/actions/action';
import submitPayload from './submitPayload';
import avatarUrl from '../../utils/makeAvatarUrl';
import toolTipText from '../../utils/toolTips/onboardingProfileForm.json'
import { profileIndex, kycDocs } from "./objectsForReg.js"
import { NavItem } from 'react-bootstrap';
import { decryptData } from '../../utils/myFunctions';
const queryString = require('query-string');


const CompanyProfileForm = ({ userTokenDetails, employeeData, registrationData, setRegistrationData, setaddBankObj, dbData, dispatch }) => {

  var [buttoncount, setbuttoncount] = useState(1);
  let [otherDoc, setotherDoc] = useState([]);
  let [countryData, setcountryData] = useState([]);
  let [referUser, setreferUser] = useState("NA");
  const [showLoader, setshowLoader] = useState(false);
  var isAdminModal = !(window.location.href.includes('registration'));

  const {
    values,
    errors,
    handleChange,
    handleAvatar,
    handleSubmit,
    handleFile,
    SETvaluesData,
  } = useForm(form_sub, scrolltoError, validate(registrationData.type, kycDocs, registrationData.payload));

  //User details from cookie
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null

  console.log('registrationData in main page==>', registrationData);

  useEffect(() => {
    //------------------------------------------------------------------

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    let query = queryString.parse(window.location.search);
    console.log("query params in reg route==>", query)
    let referralCode = query.referralCode ? query.referralCode : null

    if (referralCode) {
      referralVerify(referralCode)
    }

    {
      employeeData ? registrationData.payload["step1"] = registrationData.payload.step1 === undefined ? { "loggedUserId": employeeData.manualUserId, "loggedUserType": employeeData.manualUserTypeId, "referralCode": referralCode } : { ...registrationData.payload.step1 }
        :
        registrationData.payload["step1"] = registrationData.payload.step1 === undefined ? { "loggedUserId": userId, "loggedUserType": userTypeId, "referralCode": referralCode } : { ...registrationData.payload.step1 }
    }
    // console.log("registrationData.payload.step1->", registrationData.payload.step1)

    if (dbData.email) {
      let tempObj = {}
      if (registrationData.type != 19) {
        tempObj.companyName = dbData["company_name"]
      }
      tempObj.email = dbData["email"]
      tempObj.password = decryptData(dbData["password"])
      tempObj.passwordConfirm = decryptData(dbData["password"])
      tempObj.contactNo = dbData["contact"]
      tempObj.contactPerson = dbData["name"]
      tempObj.country = dbData["country"]
      tempObj.industryType = dbData["industry_type"]
      tempObj.gstDocumentName = dbData["gst"]
      tempObj.iecDocumentName = dbData["iec"]
      // console.log('tempobj', tempObj);
      SETvaluesData({ ...registrationData.payload.step1, ...tempObj })
    }
    else {
      SETvaluesData(registrationData.payload.step1)
    }
    // registrationData.payload.step1.loggedUserId = userId
    // registrationData.payload.step1.loggedUserType = userTypeId
    registrationData.payload.modalNav = 2;//for navigating next/back in case of modal

    //---create Other data fields with retained data[start]---------
    if (registrationData.payload.otherDocDataArray && registrationData.payload.otherDocDataArray.length) {
      setotherDoc(registrationData.payload.otherDocDataArray);
    }
    //---create Other data fields with retained data[end]---------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function form_sub() {
    handleNext(values);
  }

  function referralVerify(refCode) {
    call('post', 'verifyrefercode', { referCode: refCode }).then((result) => {
      if (result.length) {
        setreferUser(result[0].company_name)
      } else {
        setreferUser("")
      }
    }).catch((e) => {
      console.log('error in verifyrefercode', e);
    })
  }

  //------------------component functions[start]---------------------------------------
  const handleNext = async function (values) {
    console.log("in handle next==>")
    registrationData.payload.step1 = values;
    registrationData.payload.otherDocDataArray = otherDoc;

    if (registrationData.type === 6) {
      setRegistrationData(registrationData.type, 9, registrationData.payload);
      return
    }

    if ([14, 3, 4].includes(registrationData.type)) {//trader profile(14),buyer(3), seller(4) (redirected to trader profile picker)
      setRegistrationData(registrationData.type, 7, registrationData.payload);
    }
    // else if ([].includes(registrationData.type)) {//broker(10), stevedore(11), shipper(5), captain(17) (redirected to bank form)
    // setRegistrationData(registrationData.type, 5, registrationData.payload);
    // } 
    else if ([5, 12, 8, 9, 10, 11, 13, 17, 7, 18, 19, 20].includes(registrationData.type)) {//agency(6), custom(12), bank(8), employee(9) redirected to Thankyou page after submit payload
      setRegistrationData(registrationData.type, registrationData.step, registrationData.payload);

      //---------Submitting payload [start]---------
      try {
        console.log("in submit payload==>")
        setshowLoader(true)
        let result = await submitPayload(registrationData, employeeData)
        setshowLoader(false)
        toastDisplay(result, "success");
        setTimeout(() => { }, 5000);
        setRegistrationData(0, 6, {});
      } catch (error) {
        setshowLoader(false)
        toastDisplay(error, "error");
        console.log("error in submit payload==>", error)
      }
      //---------Submitting payload [end]---------
    }
  }


  // Seller profile file handling functions:
  function addElement() {
    const data = [...otherDoc]
    data.push({})
    setotherDoc(data)
  }

  function removeElement(i) {
    const data = [...otherDoc]
    data[i] = null
    setotherDoc(data)
  }
  function checkElementNo() {
    let count = 0
    otherDoc.map((otherDoc) => {
      if (otherDoc) {
        count++
      }
    })
    return count
  }

  function moreElement(idx) {

    return (
      <div>
        <ul className="other-documents">
          <li>
            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
          </li>
          <li style={{ "min-width": "250px" }}>
            <input type="text" className="custum-control" placeholder="Doc Type" name={`otherdocName`} num={idx} value={otherDoc[idx][`otherdocName`]} onChange={handleOtherDoc} />
          </li>
          <li >
            {toolTip("extraDoc")}
            {/* <div className="document-name-top">Valid Upto </div>
            <div className="document-name-bottom">(If applicable )</div> */}
          </li>
          <li style={{ "min-width": "250px" }}>
            {/* <input type="text" className="custum-control year" name={`otherdocMM`} num={idx} value={otherDoc[idx][`otherdocMM`]} placeholder="MM" maxlength="2" onChange={handleOtherDoc} />
            <input type="text" className="custum-control year" name={`otherdocYY`} num={idx} value={otherDoc[idx][`otherdocYY`]} placeholder="YYYY" maxlength="4" onChange={handleOtherDoc} />*/}
          </li>
          <li>
            <div className="file-browse">
              <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
              <input type="file" accept=".png,.jpg,.pdf" name={"otherdoc" + ":2:1"} num={idx} onChange={handleOtherDoc} />
            </div>
          </li>
          <li>
            <button type="button" className="delete-icon" onClick={() => removeElement(idx)}><i className="fa fa-trash"></i></button>
          </li>
        </ul>
      </div>
    )
  }

  const hanleDocChecks = (event) => {
    event.persist();
    SETvaluesData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  var handleOtherDoc = (event) => {
    let num = event.target.getAttribute('num');
    let data = otherDoc[num];
    if (event.target.files && event.target.files[0]) {
      data = { ...data, [event.target.name]: event.target.files[0] }
      otherDoc[num] = data;
    } else {
      data = { ...data, [event.target.name]: event.target.value }
      otherDoc[num] = data;
    }
    setotherDoc([...otherDoc]);
    setbuttoncount(buttoncount++);
    console.log(otherDoc);
  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  //------------------component functions[end]---------------------------------------


  function scrolltoError() {
    toastDisplay("Form Validation Error", "warn")
    return (errors.companyName ? document.getElementById("POScompanyName").scrollIntoView({ behavior: "smooth", block: "center" })
      : errors.contactPerson ? document.getElementById("POScontactPerson").scrollIntoView({ behavior: "smooth", block: "center" })
        : errors.email ? document.getElementById("POSemail").scrollIntoView({ behavior: "smooth", block: "center" })
          : errors.password ? document.getElementById("POSpassword").scrollIntoView({ behavior: "smooth", block: "center" })
            : errors.passwordConfirm ? document.getElementById("POSpasswordConfirm").scrollIntoView({ behavior: "smooth", block: "center" })
              : errors.contactNo ? document.getElementById("POScontactNo").scrollIntoView({ behavior: "smooth", block: "center" })
                : errors.companyAddress ? document.getElementById("POScompanyAddress").scrollIntoView({ behavior: "smooth", block: "center" })
                  : errors.country ? document.getElementById("POScountry").scrollIntoView({ behavior: "smooth", block: "center" })
                    : errors.KYCDoc ? document.getElementById("KYCDoc").scrollIntoView({ behavior: "smooth", block: "center" })
                      : errors.avatar ? document.getElementById("POSavatar").scrollIntoView({ behavior: "smooth", block: "center" })
                        : "")
  }



  function profileAvatarDiv() {
    return (
      <div className="profile-sidebar">
        <div className="profile-userpic" id="POSavatar">
          <img src={values.avatarBase64 ? values.avatarBase64 : avatarUrl("default.png")} id="myImg" className="img-responsive" alt="" />
          {errors.avatar && <p className="text-danger error-contract">{errors.avatar}</p>}
          <span className="icon-edit-profile">
            <i className="fa fa-pencil" aria-hidden="true"></i>
            <input type="file" accept=".png,.jpg" id="file_3" dir="2" name="avatar" onChange={handleAvatar} />
          </span>
        </div>
      </div>
    )
  }

  function mainForm(isAdminModal) {
    return (
      <form className="pr-4">
        <div className="row pt-3">

          {registrationData.payload.hasOwnProperty('kycDetail') ? ''
            : <>
              {(registrationData.type == 19 || registrationData.type == 3 || registrationData.type == 4 || registrationData.type == 14) &&
                <div className="form-group col-md-6">
                  <label>Organization Type </label>
                  <select className={" form-control" + (!errors.organizationType ? '' : ' border-danger')} name="organizationType" value={values.organizationType} onChange={handleChange} >
                    <option value="" selected>Select Organization type</option>
                    <option value="soleProprietorship">Sole Proprietorship</option>
                    <option value="partnership">Partnership</option>
                    <option value="pvtPubLtd">PVT/PUB.LTD</option>
                    <option value="llp">LLP</option>
                  </select>
                  {errors.organizationType && (<p className="text-danger error-contract">{errors.organizationType}</p>
                  )}
                </div>
              }
            </>}


          <div className="form-group col-md-6" id="POScompanyName">
            <label>{(registrationData.type === 8) ? "Bank Name" : "Company Name"}</label><span className="required-field text-danger">*</span>
            {registrationData.payload.hasOwnProperty('step1') && registrationData.payload.step1.hasOwnProperty('type') && registrationData.payload.step1.type == 'finTech' ?

              <input type="text" className={" form-control" + (!errors.companyName ? '' : ' border-danger')} placeholder={(registrationData.type === 8) ? "Enter Bank Name" : "Enter Company Name"}
                name="companyName" value={values.companyName} onChange={handleChange} disabled={(registrationData.type === 8) ? true : false} maxLength={(registrationData.type === 8) ? null : 40} />
              :
              <input maxLength={(registrationData.type === 8) ? null : 40} type="text" className={" form-control" + (!errors.companyName ? '' : ' border-danger')} placeholder={(registrationData.type === 8) ? "Enter Bank Name" : "Enter Company Name"} name="companyName" value={values.companyName} onChange={handleChange} required />

            }
            {errors.companyName && <p className="text-danger error-contract">{errors.companyName}</p>}
          </div>

          <div className="form-group col-md-6" id="POScontactPerson">
            <label>Contact Person</label><span className="required-field text-danger">*</span>
            <input type="text" className={" form-control" + (!errors.contactPerson ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="contactPerson" value={values.contactPerson} onChange={handleChange} required />
            {errors.contactPerson && (<p className="text-danger error-contract">{errors.contactPerson}</p>
            )}
          </div>

          <div className="form-group col-md-6" id="POSemail">
            <label>Email ID</label><span className="required-field text-danger">*</span>
            <input type="text" className={" form-control" + (!errors.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={values.email} onChange={handleChange} required />
            {errors.email && (<p className="text-danger error-contract">{errors.email}</p>
            )}
          </div>

          <div className="form-group col-md-6" id="POSpassword">
            <label>Password</label><span className="required-field text-danger">*</span>{toolTip("password")}
            <input type="password" className={" form-control" + (!errors.password ? '' : ' border-danger')} placeholder="Min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol" name="password" value={values.password} onChange={handleChange} required />
            {errors.password && (<p className="text-danger error-contract">{errors.password}</p>
            )}
          </div>

          <div className="form-group col-md-6" id="POSpasswordConfirm">
            <label>Re-enter password</label><span className="required-field text-danger">*</span>{toolTip("password")}
            <input type="password" className={" form-control" + (!errors.passwordConfirm ? '' : ' border-danger')} placeholder="Re-enter password.." name="passwordConfirm" value={values.passwordConfirm} onChange={handleChange} required />
            {errors.passwordConfirm && (<p className="text-danger error-contract">{errors.passwordConfirm}</p>
            )}
          </div>

          <div className="form-group col-md-6" id="POScontactNo">
            <label>Contact No.</label><span className="required-field text-danger">*</span>
            <input type="number" min="1000000001" min="9999999999" className={" form-control" + (!errors.contactNo ? '' : ' border-danger')} placeholder="Enter Contact No" name="contactNo" value={values.contactNo} onChange={handleChange} required />
            {errors.contactNo && (<p className="text-danger error-contract">{errors.contactNo}</p>
            )}
          </div>

          <div className="form-group col-md-6" id="POScountry">
            {/* <div className="flag-icon">
              {values.country ? <span className="shadow">
                <ReactCountryFlag
                  countryCode={values.country}
                  style={{ width: '25px', height: '25px' }} svg />
              </span> :
                <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image" />}
            </div> */}
            <label>Country </label><span className="required-field text-danger">*</span>
            <select className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={values.country} onChange={handleChange} >
              <option value="" selected>Select Country</option>
              {countryData.map((country) => {
                return (
                  <option value={country.sortname} >{country.name}</option>
                )
              })}
            </select>
            {errors.country && (<p className="text-danger error-contract">{errors.country}</p>
            )}
          </div>


          {(registrationData.type == 19 || registrationData.type == 3 || registrationData.type == 4 || registrationData.type == 14 || registrationData.type == 9 || registrationData.type == 20) &&
            <div className="form-group col-md-6" id="POSindustryType">
              <label>Industry Type </label><span className="required-field text-danger">*</span>
              <select className={" form-control" + (!errors.industryType ? '' : ' border-danger')} name="industryType" value={values.industryType} onChange={handleChange} >
                <option value="" selected>Select Industry Type</option>
                <option value="apparelTextile" >Apparels & Textile</option>
                <option value="industrialMech" >Industrial & Mechanical</option>
                <option value="foodBeverage" >Food & Beverage</option>
                <option value="gamingMedia" >Gaming & Media</option>
                <option value="electronicConsumer" >Electronics & Consumer Goods</option>
                <option value="eCommerce" >E-Commerce</option>
                <option value="fmcg" >FMCG</option>
                <option value="medicalPharma" >Medical & Pharmaceutical</option>
                <option value="auto" >Auto</option>
                <option value="frozenFood" >Frozen Foods</option>
                <option value="ITStaffing" >IT Staffing</option>
                <option value="argo" >AGRO</option>
                <option value="govtEntity" >Buyer/Supplier Government Entity</option>
                <option value="oilGas" >Oil/Gas</option>
                <option value="diamond" >Diamond</option>
                <option value="preciousStone" >Precious Stone</option>
                <option value="gold" >Gold</option>
                <option value="others" >Others</option>
              </select>
              {errors.industryType && (<p className="text-danger error-contract">{errors.industryType}</p>
              )}
            </div>}

          {(!userId && registrationData.type != 20) &&
            <div className="form-group col-md-6" >
              <label>User Referral Code (If Available)</label>
              <input type="text" className="form-control" placeholder="Enter Referral Code" name="referralCode" value={values.referralCode} onChange={handleChange} onBlur={(event) => {
                event.preventDefault();
                referralVerify(event.target.value)
              }} />
              {referUser !== "NA" && (<p className={"error-contract " + (referUser ? "text-success" : "text-warning")}>{referUser ? ("Code Belongs to " + referUser) : (values.referralCode) ? "Invalid Code" : ""}</p>)}
            </div>}

          <div className="form-group col-md-12" id="POScompanyAddress">
            <label>Company Address </label>
            <textarea type="text" className={" form-control" + (!errors.companyAddress ? '' : ' border-danger')} placeholder="Enter Company Address" name="companyAddress" value={values.companyAddress} onChange={handleChange} required />
            {errors.companyAddress && (<p className="text-danger error-contract">{errors.companyAddress}</p>
            )}
          </div>
        </div>



        {/* Docs first page doc - start ( buyer(3), bank(8), broker(10), agency(6), custom(12), stevedore(11), shipper(5), employee(9) ) */}

        {([9].includes(registrationData.type) && userTypeId != 8) &&
          <>
            <h3 className="mb-4" id="KYCDoc" >Access Criteria</h3>
            <div className="row pt-2">

              {([3, 4, 14, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="commodityContracts" id="commodityContracts" onChange={hanleDocChecks} checked={values.commodityContracts} />
                    <label className="form-check-label" for="commodityContracts">
                      Create Commodity Contracts
                    </label>
                  </div>
                </div>
              }

              {([3, 4, 14, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="shipmentContracts" id="shipmentContracts" onChange={hanleDocChecks} checked={values.shipmentContracts} />
                    <label className="form-check-label" for="shipmentContracts">
                      Create Shipment Contracts
                    </label>
                  </div>
                </div>
              }

              {([3, 4, 14, 19, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="myCounterParts" id="myCounterParts" onChange={hanleDocChecks} checked={values.myCounterParts} />
                    <label className="form-check-label" for="myCounterParts">
                      Create Buyer Details
                    </label>
                  </div>
                </div>}

              {([3, 4, 14, 19, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="creditLines" id="creditLines" onChange={hanleDocChecks} checked={values.creditLines} />
                    <label className="form-check-label" for="creditLines">
                      Request Credit Lines
                    </label>
                  </div>
                </div>}

              {([3, 4, 14, 19, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="finance" id="finance" onChange={hanleDocChecks} checked={values.finance} />
                    <label className="form-check-label" for="finance">
                      Request Finance
                    </label>
                  </div>
                </div>
              }

              {([3, 4, 14, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="manageCommodities" id="manageCommodities" onChange={hanleDocChecks} checked={values.manageCommodities} />
                    <label className="form-check-label" for="manageCommodities">
                      Add Commodities
                    </label>
                  </div>
                </div>
              }

              {([3, 4, 14, 19, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="contractsDirectory" id="contractsDirectory" onChange={hanleDocChecks} checked={values.contractsDirectory} />
                    <label className="form-check-label" for="contractsDirectory">
                      Contract Directory Management
                    </label>
                  </div>
                </div>}

              {([3, 4, 14, 19, 1].includes(userTypeId)) &&
                <div className="col-md-6">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <input className="form-check-input" type="checkbox" name="personalDirectory" id="personalDirectory" onChange={hanleDocChecks} checked={values.personalDirectory} />
                    <label className="form-check-label" for="personalDirectory">
                      Personal Directory Management
                    </label>
                  </div>
                </div>
              }

            </div>

            <br />
          </>
        }

        {([3, 4, 9, 12, 14].includes(registrationData.type)) &&
          <>
            {/* kyc docs not mandatory for bank so conditions applied for '*' */}
            <h3 className="mb-4" id="KYCDoc" >KYC Documents<>{(registrationData.type !== 8) && (registrationData.type != 9 ? <span className="required-field text-danger">*</span> : '')}</></h3>
            <div id="newDoc">

              {kycDocs.map((item) => {
                return (<>
                  {item.userType.includes(registrationData.type) && <>

                    <div className="form-group pb-0 mb-0" id={item.key}>
                      <div className="row">
                        <ul className="other-documents pb-0 mb-0">
                          <li style={{ "min-width": "250px" }}>
                            {item.name == 'CIN' &&
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                {item.name} Number
                                <span>{toolTip(item.key)}</span>

                              </label>
                            }
                            {item.name !== 'CIN' &&
                              <label className="mb-0" >
                                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                {item.name} Number
                                {(registrationData.type != 9 ? <span className="required-field text-danger">*</span> : '')}
                                <span>{toolTip(item.key)}</span>

                              </label>
                            }
                          </li>
                          <li style={{ "min-width": "250px" }}>
                            <input type="text" maxLength={item.length} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!errors[item.key + "Name"] ? '' : ' border-danger')} placeholder={"Enter " + item.name + " Number"} name={item.key + "Name"} value={values[item.key + "Name"]} onChange={handleChange} required />
                            {errors[item.key + "Name"] ? <p className="text-danger error-contract p-0 m-0">{errors[item.key + "Name"]}</p> : <p className="text-dark error-contract p-0 m-0">{item.name + " Number"}</p>}
                          </li>
                          {/* <li className="text-right pr-0">
                          <div className="file-browse">
                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" id="file_1" name={item.key + item.dbId} onChange={handleFile} />
                            {errors[item.key + item.dbId] && <p className="error-contract p-0 m-0">{errors[item.key + item.dbId]}</p>}
                          </div>
                        </li> */}
                        </ul>

                        {/* {(values[item.key + item.dbId]) ? <div className="filePath text-right"><div className="file-name">{`${values[item.key + item.dbId].name}`}</div></div> : ""} */}
                        <hr className="" />
                      </div>
                    </div>

                  </>}
                </>)
              })}


            </div>
            {/* <div className="form-group d-flex">
              <div>
                <h3 className="mb-4" id="POSdoc" >Other Documents ({2 - checkElementNo()} left)</h3>
                {(buttoncount > 0) && otherDoc.map((otherDocData, idx) => {
                  return (
                    <>
                      {otherDocData != null ? moreElement(idx) : ""}
                      {otherDoc[idx] && otherDoc[idx]["otherdoc" + ":2:1"] ? <div className="filePath"><div className="file-name">{otherDoc[idx]["otherdoc" + ":2:1"].name}</div></div> : ""}
                      {otherDocData && <br />}
                    </>
                  )
                })}
                {(checkElementNo() <= 1) && <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => addElement()}>Add +</button>}
              </div>
            </div> */}
          </>
        }
        {/* Docs first page doc - end */}

        <div className="text-center mb-3">
          {[5, 12, 8, 9, 10, 13, 7, 11, 17, 18, 19, 20].includes(registrationData.type) && !isAdminModal ?
            <>
              <div className="col-md-10 mt-1 offset-md-1">
                <div className="col-md-10 mt-1">
                  <div className="form-check pl-0 mt-1 mb-1">
                    <label className="form-check-label" for="termsAndCondition">
                      <input className="form-check-input new-check-box" type="checkbox" name="termsAndCondition" id="termsAndCondition" onChange={hanleDocChecks} checked={values.termsAndCondition} />
                      I acknowledge that i have read and fully understand the <a href="./assets/docs/Terms_of_Use.pdf" target="true">terms and conditions</a> of the
                      tradereboot onboarding agreement and that i will comply with the same.
                    </label>
                  </div>
                </div>
              </div>

              {values.termsAndCondition == true &&
                <button className="btn btn-primary btn-sm" type="button" onClick={() => { handleSubmit(); setaddBankObj(true, { "email": JSON.stringify(registrationData.payload) }); }} noValidate>Submit</button>}
            </>
            : ""}
        </div>
      </form>
    )
  }


  //--------final return[start]--------
  if (isAdminModal) {
    return (
      <div className="modal-body calc-inner-modal">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {/* Navigation in case of modal: 1-prev, 2-neutral, 3-next(not used 0 coz its falsy)*/}
                {(registrationData.payload.modalNav === 1) && setRegistrationData(registrationData.type, 0, registrationData.payload)}
                {(registrationData.payload.modalNav === 3) && (registrationData.payload.modalNav = 2) && handleSubmit()}

                <div className="col-md-12 mb-3">
                  <h3 className="green border-bottom pb-2">Create Profile :</h3>
                </div>
                <div className="col-md-3">
                  {profileAvatarDiv()}
                </div>
                <div className="col-md-9">
                  {mainForm(isAdminModal)}
                  {employeeData && <button type="button" className="btn btn-primary float-right btn-sm" onClick={() => form_sub()}>Save</button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <ul className="next-button">
          <li >{registrationData.type == 19 ?
            <button type="button" onClick={() => { setRegistrationData(registrationData.type, 8, registrationData.payload) }} >{"<< Previous"}</button>
            :
            <button type="button" onClick={() => { setRegistrationData(2, 1, registrationData.payload) }} >{"<< Previous"}</button>
          }
          </li>
          <li >
            {[5, 7, 8, 10, 11, 13, 17, 18, 19, 20].includes(registrationData.type) ? ''
              : <button type="button" onClick={() => { handleSubmit(); setaddBankObj(true, { "email": JSON.stringify(registrationData.payload) }) }} noValidate>{"Next >>"}</button>}
          </li>
        </ul>

        <div className="container-fluid">
          <div className="row">
            <Header title={"Company Profile " + profileIndex[registrationData.type]} />
            <div className="col-md-8 mx-auto bg-white rounded p-4 mt-3 calc">
              <div className="row">
                <div className="col-md-3">
                  <h3 className="green mb-4 mt-2 text-center">Create Profile</h3>
                  {profileAvatarDiv()}
                </div>
                <div className="col-md-9 calc-inner mt-5">
                  {mainForm(isAdminModal)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  //--------final return[end]--------
}



const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) },
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfileForm)
