import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone'
import { Line, Circle } from 'rc-progress';
import DocViewer from "react-doc-viewer";
import JSZip from "jszip";
import { LCPurposeObjectV2, convertZipFileArrayToFilePdfObjects, dataURItoBlob, getDocDetails, isFilePreviewAvailable } from "./myFunctions";
import { NewInput } from "./newInput";
import MultipleSelect from "./MultipleSelect";
import FinanceInvoiceModal from "../components/InvoiceDiscounting/contract/components/financeinvoiceModal";
import { ToastContainer } from "react-toastify";
import call from "../service";
import { getUserDataFromCookie } from "./cookieHelper";
import DocVaultTransactionDetails from "../components/documentVault/DocVaultTransactionDetails";
import toastDisplay from "./toastNotification";
import { invoiceTemplateTypes } from "../components/Edocs/Einvoice";

function downloadTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  if (result.name.split(".").pop() === "png" || result.name.split(".").pop() === "PNG") {
    console.log(result.name);
    let link = document.createElement("a");
    console.log(link);
    link.download = result.name.split(".")[0] + ".png";
    link.href = 'data:application/png;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "jpg" || result.name.split(".").pop() === "JPG") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".jpeg";
    link.href = 'data:application/jpeg;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (result.name.split(".").pop() === "pdf" || result.name.split(".").pop() === "PDF") {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".PDF";
    link.href = 'data:application/pdf;base64,' + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function getSourceType(mime) {
  return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
    (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," :
      (mime === "pdf" || mime === "PDF") ? "data:application/pdf;base64," : "")
}

function viewTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1]
  }
  let mime = result.name.split(".").pop()
  let sourceType = getSourceType(mime)
  let filePrevAvailable = isFilePreviewAvailable(result["filebase64"])
  if (filePrevAvailable) {
    return (
      <>
        {
          (mime === "pdf" || mime === "PDF") ?
            // <iframe title="Document Preview" frameborder="0" height="100%" className="col-md-12 m-0 p-0"
            //   src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")}></iframe>
            <object
              style={{ overflowX: 'hidden' }}
              data={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")}
              scrolling="no"
              type="application/pdf" width="100%" height="100%">
              <param name="navpanes" value="0"></param>
            </object>
            :
            <img src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")} alt={result.name} />
        }
      </>
    )
  }
  else {
    return (<div className="bg-white d-flex justify-content-center align-items-center h-100">
      <div>
        <label className="font-size-20 font-wt-600 text-color1 w-100 text-center">{`No Preview Available`}</label>
        <label className="font-size-16 font-wt-600 text-dark w-100 my-2 text-center">Download File</label>
        <div className="d-flex justify-content-center">
          <img onClick={() => {
            downloadTheFile(result)
          }} className="cursor" src="assets/images/new_dowd_icon.svg" />
        </div>
      </div>
    </div>)
  }
}


export const FileInput = ({ showDocName, showFileNameW100, hideViewDowdBtns, multipleDragDropEnable, onMultipleDragDrop, hideVault, name, value, onChange, error, onUploadCancel, onView, isEditable, extra_class, changeEditIconToUpload, onEyeBuPress }) => {

  let userTokenDetails = getUserDataFromCookie()

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [uploadProgress, toggleUplaodProgress] = useState(!isEditable ? 100 : 0)
  const [showDoc, toggleShowDoc] = useState(false)
  const [valutPopup, toggleVaultPopup] = useState(false)
  const [data, setData] = useState({})
  const [applications, setApplications] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [vaultFileView, toggleVaultFileView] = useState(false)
  const [value2, setValue2] = useState(value)
  console.log("selectedfile", value, name, value2);

  let isFileSelected = value && value.name ? true : false

  useEffect(() => {
    if (isFileSelected && uploadProgress < 100) {
      setTimeout(() => {
        toggleUplaodProgress(uploadProgress + 35)
      }, 1000);
    }
  }, [value, uploadProgress])

  const onDrop = (async (acceptedFiles, fileRejections, event) => {
    // console.log("acceptedFilesss", acceptedFiles);
    if (acceptedFiles.length) {
      let tempZipConvertedFiles = []
      let indexToDelete = []
      for (let index = 0; index < acceptedFiles.length; index++) {
        const element = acceptedFiles[index];
        if (element.type.includes("zip")) {
          indexToDelete.push(index)
          const zip = new JSZip();
          try {
            const zipData = await zip.loadAsync(element);
            tempZipConvertedFiles = tempZipConvertedFiles.concat(await convertZipFileArrayToFilePdfObjects(zipData))
          } catch (error) {
            console.error("Error opening ZIP file:", error);
          }
        }
      }
      acceptedFiles = acceptedFiles.concat(tempZipConvertedFiles)
      // console.log("indexToDeleteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", indexToDelete, acceptedFiles);
      if (indexToDelete.length) {
        acceptedFiles = acceptedFiles.filter((i, j) => {
          if (!indexToDelete.includes(j)) {
            return true
          }
        })
      }
      // console.log("ppppppppppppppppppppppppppppppppppppppppppppppppp", acceptedFiles);
      let event = {
        target: { name: name, files: acceptedFiles },
        persist: () => console.log("onDrop called")
      }
      if (multipleDragDropEnable) {
        onMultipleDragDrop(event)
      }
      else {
        onChange(event)
      }
      toggleUplaodProgress(0)
    }
  })

  useEffect(() => {
    loadApplicationByServiceType()
  }, [data.selectedService])

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      let eventVal = e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      setData({
        ...data,
        [name]: eventVal
      })
    }
    else {
      if (name === "selectedService") {
        setData({
          ...data,
          [name]: Array.isArray(e) ? e.map((x) => x[val]) : [],
          selectedApplication: null
        })
      }
      else {
        setData({
          ...data,
          [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
        });
      }
      toggleVaultFileView(false)
    }
  };

  useEffect(() => {
    if (data.selectedApplication?.length) {
      for (let index = 0; index < data.selectedApplication.length; index++) {
        let applicationDetails = applications.find((i) => {
          if (i.applicationId === data.selectedApplication[index]) {
            return true
          }
        })
        // console.log("applicationDetailsssssssssssssss", applicationDetails);
        localStorage.setItem(`docVaultTransactionDetails${index}`, JSON.stringify(applicationDetails))
        toggleVaultFileView(true)
      }
    }
    else {
      toggleVaultFileView(false)
    }
  }, [data.selectedApplication])

  async function loadApplicationByServiceType() {
    let tempSelectedApps = []
    if (data.selectedService?.length) {
      for (let index = 0; index < data.selectedService.length; index++) {
        const serviceType = data.selectedService[index];
        if (serviceType != "Edocs") {
          setshowLoader(true)
          let apiResp = await call('POST', 'getDocVaultTransactionList', { serviceType, userId, resultPerPage: 2000, "page": 1 })
          for (let index = 0; index < apiResp.data.length; index++) {
            let element = apiResp.data[index];
            element['label'] = element.lcPurpose ? `${LCPurposeObjectV2[element.lcPurpose]}-${element.applicationNo}`
              : `${element.buyerName} ${element.invRefNo ? `-${element.invRefNo} ` : ""} `
          }
          tempSelectedApps = tempSelectedApps.concat(apiResp.data)
          setshowLoader(false)
        }
        else {
          tempSelectedApps = tempSelectedApps.concat([{ "label": 'Invoice', applicationId: 'invoice' }, { "label": 'Purchase Order', applicationId: 'po' }])
        }
      }
    }
    setApplications([...tempSelectedApps])
  }

  function onCloseDocVaultPicker() {
    toggleVaultPopup(false)
    toggleVaultFileView(false)
    setData({})
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <> {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {valutPopup && <FinanceInvoiceModal modalSize={"xl"} limitinvoice={valutPopup} setLimitinvoice={onCloseDocVaultPicker}
        closeSuccess={onCloseDocVaultPicker} >
        <div className="" >
          <label className="font-size-22 font-wt-600 w-100 text-center">Document Vault</label>
          <label className="font-size-14 font-wt-500 w-100 text-center">(Pick document from document vault)</label>
          <div className="d-flex mt-4 justify-content-between" >
            <div className="w-28 position-relative" >
              <MultipleSelect
                Id="Select Service"
                Label="Select Service"
                selectedvalue="Select Service"
                optiondata={[{ name: 'Export Factoring' }, { name: 'LC' }, , { name: 'Edocs' }]}
                onChange={(e) => { handleMultiSelectchange(e, "selectedService", "name") }}
                value={data.selectedService || []}
                name="selectedService"
                labelKey={"name"}
                valKey={"name"}
              />
            </div>
            <div className="w-28 position-relative" >
              <MultipleSelect
                Id="Select Application/Type"
                Label="Select Application"
                selectedvalue="Select Application"
                optiondata={applications}
                onChange={(e) => handleMultiSelectchange(e, "selectedApplication", "applicationId")}
                value={data.selectedApplication || []}
                name="selectedApplication"
                labelKey={"label"}
                valKey={"applicationId"}
              />
            </div>
            <div className="w-28 position-relative">
              <NewInput label={"Search"} name={"search"} data={data.search} value={data.search} onChange={(e) => { setData({ ...data, search: e.target.value }) }} />
            </div>
            <div className="w-10" >
              <div
                onClick={() => { setData({ ...data, folderView: data.folderView === "list" ? "grid" : 'list' }) }}
                style={{ width: '90%', height: '2.5rem' }} className='card d-flex flex-row cursor pl-3' >
                <div className=' d-flex align-items-center cursor' >
                  <img className='cursor' src={data.folderView === "list" ? `assets/images/list_icon.png` : `assets/images/grid_icon.png`} />
                </div>
                <label className='font-size-13 font-wt-600 mt-2 ml-2 cursor'>{data.folderView === "list" ? "List" : "Grid"}</label>
              </div>
            </div>
          </div>
          {!data.selectedApplication?.length && data.search ?
            <div className="px-2" >
              <DocVaultTransactionDetails
                title={"Global Search"}
                hideDefaultFilter
                search={data.search}
                folderView2={data.folderView}
                localStorageKeyName={`NA`}
                onSelectFile={(tempFileObj) => {
                  // console.log("ffffffffffffffffff", tempFileObj);
                  let convertedFileObj = tempFileObj.filebase64 ? dataURItoBlob(tempFileObj.filebase64) : null
                  if (convertedFileObj) {
                    convertedFileObj = new File([convertedFileObj], tempFileObj.name, { type: "application/pdf" });
                    convertedFileObj["filebase64"] = tempFileObj.filebase64
                    convertedFileObj["signatureId"] = tempFileObj["signatureId"]
                    let event = {
                      target: { name: name, files: [convertedFileObj] },
                      persist: () => console.log("onVault Pickup called")
                    }
                    onChange(event)
                    onCloseDocVaultPicker()
                  }
                  else {
                    toastDisplay("Something went wrong", "error")
                  }
                }}
                userTokenDetails={userTokenDetails} viewInPopup />
            </div>
            : null}
          {vaultFileView ? <>{data?.selectedApplication?.map((i, j) => {
            return <div className="px-2" >
              <DocVaultTransactionDetails
                title={applications.find(k => {
                  if (k.applicationId === i) {
                    return true
                  }
                })?.["label"]}
                hideDefaultFilter
                search={data.search}
                folderView2={data.folderView}
                localStorageKeyName={`docVaultTransactionDetails${j}`}
                onSelectFile={(tempFileObj) => {
                  console.log("ffffffffffffffffff", tempFileObj);
                  let convertedFileObj = tempFileObj.filebase64 ? dataURItoBlob(tempFileObj.filebase64) : null
                  if (convertedFileObj) {
                    convertedFileObj = new File([convertedFileObj], tempFileObj.name, { type: "application/pdf" });
                    convertedFileObj["filebase64"] = tempFileObj.filebase64
                    convertedFileObj["signatureId"] = tempFileObj["signatureId"]
                    let event = {
                      target: { name: name, files: [convertedFileObj] },
                      persist: () => console.log("onVault Pickup called")
                    }
                    onChange(event)
                    onCloseDocVaultPicker()
                  }
                  else {
                    toastDisplay("Something went wrong", "error")
                  }
                }}
                userTokenDetails={userTokenDetails} viewInPopup />
            </div>
          })}</> : null}
        </div>
      </FinanceInvoiceModal>}
      {showDoc && (value.filebase64 || value2.filebase64) ? (
        <div className={"modal d-block show "} id="docPreview">
          <div className="modal-dialog modal-fullscreen " id="parent_class">
            <div className="modal-content bg-transparent">
              <div className="modal-header border-0 px-4 pe-5 d-flex justify-content-between" style={{ background: "rgba(0,0,0,0.3)" }}>

                <div>
                  <a className="cursor close" onClick={() => { toggleShowDoc(false) }}><img
                    style={{ filter: 'invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)' }}
                    src={"assets/images/pdf-back.png"} alt="Back" height={25} width={25} /></a>
                </div>
                <div>
                  <h6 className="modal-title text-color1 text-capitalize">{value.name}</h6>
                </div>
                <div className="d-flex gap-3">
                  <a className="cursor"><img
                    style={{ filter: 'invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)' }}
                    src={"assets/images/download-pdf.png"} alt="Back" height={25} width={25} /></a>
                  <a className="cursor"><img
                    style={{ filter: 'invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)' }}
                    src={"assets/images/printer.png"} alt="Back" height={25} width={25} /></a>
                  <a className="cursor"><img
                    style={{ filter: 'invert(44%) sepia(97%) saturate(487%) hue-rotate(149deg) brightness(91%) contrast(82%)' }}
                    src={"assets/images/share.png"} alt="Back" height={25} width={25} /></a>
                </div>


              </div>
              <div className="modal-body p-0">
                <div className="d-flex" style={{ height: "670px" }}>
                  <div className="col-md-2 " style={{ background: "rgba(0,0,0,0.3)" }}>

                  </div>
                  <div className=" col-md-8 mx-auto pt-3" >
                    <div className="d-flex m-0 p-0 bg-transparent" >
                      <div className={"tab-pane active show col-md-12"} style={{ height: "650px" }}>
                        {viewTheFile(value.filebase64 ? value : value2)}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : null
      }
      <div className={"custom-file-upload" + (error ? " border-danger" : "") + (extra_class ? (" " + extra_class) : "")}>
        {isFileSelected ? (
          <>
            {uploadProgress >= 100 ? (
              <div
                className="selectedFile-div align-items-center"
              >
                <div className={`d-flex align-items-center ${showFileNameW100 ? ' w-100 ' : ' w-65 '} `} >
                  <img className="mr-3 " src={value.doc_name?.includes("xlsx") || value.name?.includes("xlsx") ? "assets/images/xls_file_icon.svg" : "assets/images/pdf_file_icon.svg"} height={25} width={25} />
                  <label className="file-text m-0 truncate">{showDocName ? value.doc_name : value.name}</label>
                </div>
                <div>
                  {isEditable ? (
                    <label
                      onClick={onUploadCancel}
                      className="cursor m-0">
                      <img
                        style={changeEditIconToUpload ? { transform: "rotate(180deg)" } : {}}
                        src={changeEditIconToUpload ? "assets/images/download_light.png" : "assets/images/edit.png"} />
                    </label>
                  ) : null}
                  {hideViewDowdBtns ? null : (
                    <>
                      <img
                        style={
                          {
                            width: "22px",
                            height: "22px"
                          }
                        }
                        src={"assets/images/show_pass.png"}
                        className='cursor mx-2' onClick={async () => {
                          if (onEyeBuPress) {
                            onEyeBuPress()
                          } else {
                            if (!value.filebase64 && value.doc_id) {
                              setshowLoader(true)
                              let tempVal = await getDocDetails(value.doc_id, null, null, null, true)
                              setValue2({ ...tempVal })
                              setshowLoader(false)
                            }
                            toggleShowDoc(true)
                          }
                        }}
                      />
                      <img src={"assets/images/download_light.png"} className='cursor'
                        onClick={async () => {
                          if (!value.filebase64 && value.doc_id) {
                            setshowLoader(true)
                            let tempVal = await getDocDetails(value.doc_id, null, null, null, true)
                            downloadTheFile(tempVal)
                            setValue2({ ...tempVal })
                            setshowLoader(false)
                          }
                          else {
                            downloadTheFile(value)
                          }
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Line percent={uploadProgress}
                  style={{ marginLeft: '1%', width: '85%' }}
                  strokeWidth={2} strokeColor="#1B94B7" />
                <img
                  onClick={onUploadCancel}
                  src={"assets/images/cancel-icon.png"} color={"#fff"} />
              </>
            )}
          </>
        ) : (
          <>
            {isEditable ? (
              <>
                <div
                  {...getRootProps()}
                  className={`dragDrop-div ${isDragActive ? "dragDrop-div-active" : ""}`}
                >
                  <img src={"assets/images/drag.png"} />
                  <span className="truncate text-dark" >Drag & Drop</span>
                </div>
                <label
                  className="browse-div cursor text-center m-0"
                  for={name}
                >
                  <img src={"assets/images/folder.png"} />
                  Browse
                </label>
                <div
                  className="vault-div"
                  onClick={() => {
                    toggleVaultPopup(true)
                  }}
                >
                  <img src={"assets/images/vault-white.png"} color={"#fff"} />
                  Vault
                </div>
              </>
            ) :
              <label className="font-wt-600">No Document Found</label>}
          </>
        )
        }
      </div >
      <input
        {...getInputProps()}
        id={name}
        onChange={(e) => {
          // if (multipleDragDropEnable) {
          //   onMultipleDragDrop(e)
          // }
          // else {
          //   onChange(e);
          // }
          // console.log("fffffffffffffffffffffffff", e.target?.files);
          if (e.target?.files?.[0]) {
            let collectedFiles = []
            for (let index = 0; index < Object.keys(e.target?.files).length; index++) {
              const element = Object.keys(e.target?.files)[index];
              collectedFiles.push(e.target.files[element])
            }
            onDrop(collectedFiles)
          }
          toggleUplaodProgress(0)
        }}
        type="file" className={"d-none"}
        onClick={event => event.target.value = null}
        name={name}
      />
    </>)
}