
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges, handleFileCommonFunction, getWCTotalCharges, most_used_currencies_with_percentage, multiSelectEventHandler, handleMultipleFile } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import moment from "moment";
import { Action } from "../myCounterPartComp/action";
import MultipleSelect from "../../utils/MultipleSelect";
import { documentTableForBL, documentTableForFinancierBL } from "../Dashboard/TableJson";

function getQuoteStatusForFinancier(quote) {
  if (quote.quoteLocked) {
    return "Quote locked"
  }
  if (quote.acceptedByShipper && quote.updatedByShipper) {
    return "Updated & Agreed by You"
  }
  if (quote.acceptedByShipper) {
    return "Agreed by You"
  }
  if (quote.updatedByShipper) {
    return "Updated by You"
  }
  if (quote.acceptedByTrader && quote.updatedByTrader) {
    return "Updated & Agreed by Trader"
  }
  if (quote.acceptedByTrader) {
    return "Agreed by Trader"
  }
  if (quote.updatedByTrader) {
    return "Updated by Trader"
  }
  if (quote.shipperAction === "Approved") {
    return "Quote Given by You"
  }
  if (quote.shipperAction === "deny") {
    return "Quote rejected by You"
  }
}

const tabLabels = [
  { name: "Application Form" },
  { name: "Locked Quote" },
  { name: "Documents" }
]



let docDescByName = {
  "Shipment Booking Invoice Receipt": 'The "Shipment Booking Invoice Receipt" is a summary confirming a booked shipment.It outlines key details like the booking number, date, trader info, origin, destination, product breakdown, and associated costs.',
  "BL Document": 'The Bill of Lading is a critical document for shippers, acting as a receipt, contract, and proof of ownership for shipped goods.',
  'SOF Document': 'The Statement of Facts (SOF) document provides a concise record of essential details and circumstances related to a specific situation, often used for legal or formal purposes in shipping and logistics.',
  'Loading Document': 'The loading document records and verifies the details of goods being loaded onto a vessel or transport, ensuring accurate documentation of the shipping process.',
  'Unloading Document': 'The unloading document records and verifies the details of goods being offloaded from a vessel or transport, ensuring accurate documentation of the receiving process.'
}

const ShipmentBookingShipperContractDetails = ({ userTokenDetails, navToggleState }) => {

  let [shipmentFinancierQuoteDetails, setShipmentFinancierQuoteDetails] = useState(JSON.parse(localStorage.getItem("shipmentFinancierQuoteDetails") || "{}"))

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  const quote = queryParams.get("quote")

  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({
    doc0Name: 'Invoice Receipt', doc1Name: 'BL Document', doc2Name: 'SOF Document', doc3Name: 'Loading Document', doc4Name: 'Unloading Document'
  })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [owners, setOwners] = useState([])
  const [exFacilties, setExFacilities] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [currencyData, setCurrencyData] = useState(most_used_currencies_with_percentage)

  const [finActionTab, setFinancierActionTab] = useState(0)
  const [quoteScreen, toggleQuoteScreen] = useState({ show: false })
  const [otherCharges, setOtherCharges] = useState([null])
  const [otherDocs, setOtherDocs] = useState([null])
  const [action, setAction] = useState({ show: false, index: null })
  const [shipperQuotes, setShipperQuotes] = useState([])
  const [containerTypeData, setcontainerTypeData] = useState([]);

  const [multDocs, setMultDocs] = useState([null, null, null, null, null])
  const [quoteArr, setQuoteArr] = useState([null, null, null, null, null, null, null])

  // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);

  let reviewForm = [
    { "name": "Trader Role", val: shipmentFinancierQuoteDetails.details.traderType },
    { "name": "Commodity Contract", val: shipmentFinancierQuoteDetails.details.commodityContract },
    {
      "name": "Unit System", val: shipmentFinancierQuoteDetails.details.unitSystem
    },
    {
      "name": "Commodity", val: shipmentFinancierQuoteDetails.details.commodity
    },
    {
      "name": "Cargo Type", val: shipmentFinancierQuoteDetails.details.cargoType
    },
    {
      "name": "Delivery Mode", val: shipmentFinancierQuoteDetails.details.deliveryMode
    },
    {
      "name": "Transportation By", val: shipmentFinancierQuoteDetails.details.transportationBy
    },
    {
      "name": "Ship Type", val: shipmentFinancierQuoteDetails.details.shipType
    },
    { "name": "Loading Rate", val: shipmentFinancierQuoteDetails.details.loadingRate, unit: `${shipmentFinancierQuoteDetails.details.weightUnit}/day` },
    { "name": "Discharging Rate", val: shipmentFinancierQuoteDetails.details.dischargeRate, unit: `${shipmentFinancierQuoteDetails.details.weightUnit}/day` },
    { "name": "Container Type", val: shipmentFinancierQuoteDetails.details.containerTruckWagonUldType, unit: `${shipmentFinancierQuoteDetails.details.containerTruckWagonUldTypeQuantity} X ` },
    {
      "name": "Weight", val: shipmentFinancierQuoteDetails.details.weight, unit: shipmentFinancierQuoteDetails.details.weightUnit
    },
    { "name": "Volume", val: shipmentFinancierQuoteDetails.details.volume, unit: shipmentFinancierQuoteDetails.details.volumeUnit },
    {
      "name": "Loading Country", val: shipmentFinancierQuoteDetails.details.loadingCountry
    },
    {
      "name": "Loading Port", val: shipmentFinancierQuoteDetails.details.loadingPort
    },
    {
      "name": "Unloading Country", val: shipmentFinancierQuoteDetails.details.unloadingCountry
    },
    {
      "name": "Unloading Port", val: shipmentFinancierQuoteDetails.details.unloadingPort
    },
    {
      "name": "Inco Terms", val: shipmentFinancierQuoteDetails.details.incoTerms
    },
    {
      "name": "Ready To Load", val: shipmentFinancierQuoteDetails.details.readyToLoad
    },

    { "name": "Total Shipment Value", val: shipmentFinancierQuoteDetails.details.commodityPrice, unit: shipmentFinancierQuoteDetails.details.commodityPriceUnit }

  ]

  useEffect(() => {
    loadDocuments()
  }, [])

  async function loadDocuments() {
    let tempData = {}
    // set bl documents if exist
    let tempMoreDocs = []
    if (shipmentFinancierQuoteDetails.blDetails) {
      for (let index = 0; index < shipmentFinancierQuoteDetails.blDetails.length; index++) {
        const element = shipmentFinancierQuoteDetails.blDetails[index];
        tempData[`doc${index}`] = await getDocDetails(element)
        if (tempData?.[`doc${index}`]?.doc_id) {
          let signatureId = tempData?.[`doc${index}`]?.signatureId
          tempData[`doc${index}No`] = tempData[`doc${index}`]?.doc_no
          tempData[`doc${index}Name`] = tempData[`doc${index}`]?.doc_name
          let blob = dataURItoBlob(tempData?.[`doc${index}`]?.filebase64);
          let fileObj = new File([blob], tempData?.[`doc${index}`]?.name, { type: "application/pdf" });
          fileObj['filebase64'] = tempData?.[`doc${index}`]?.filebase64
          tempData[`doc${index}`] = fileObj
          tempData[`doc${index}`]["signatureId"] = signatureId
          tempMoreDocs.push(null)
        }
      }
      if (tempMoreDocs.length > 5) {
        setMultDocs([...tempMoreDocs])
      }
    }
    let shipperQuotesObj = JSON.parse(shipmentFinancierQuoteDetails.shipperQuotes || "[]")
    shipperQuotesObj = shipperQuotesObj.find((i) => {
      if (i.shipper_id / 1 == shipmentFinancierQuoteDetails.selectedShipper / 1) {
        return true
      }
    }) || {}
    let shipperQuotes = shipperQuotesObj?.["charges"]
    let chargesType = Object.keys(shipperQuotes || {})
    let tempQuoteArr = []
    for (let index = 0; index < chargesType.length; index++) {
      for (let j = 0; j < shipperQuotes[chargesType[index]].length; j++) {
        const element = shipperQuotes[chargesType[index]][j];
        tempData[`chargeType${tempQuoteArr.length}`] = chargesType[index]
        tempData[`chargeName${tempQuoteArr.length}`] = element.name
        tempData[`chargeTime${tempQuoteArr.length}`] = element.time
        tempData[`chargeDistance${tempQuoteArr.length}`] = element.distance
        tempData[`chargeAmount${tempQuoteArr.length}`] = element.amount
        tempData[`chargeAmountUnit${tempQuoteArr.length}`] = element.currency
        tempData[`chargeco2${tempQuoteArr.length}`] = element.co2
        tempQuoteArr.push(null)
      }
    }
    tempData["offerValidTill"] = shipperQuotesObj["offerValidTill"]
    tempData["oceanFreightDays"] = shipperQuotesObj["oceanFreightDays"]
    tempData["molType"] = shipperQuotesObj["molType"]
    tempData["quantityVaryIndexPercentage"] = shipperQuotesObj["quantityVaryIndexPercentage"]
    tempData["loadingType"] = shipperQuotesObj["loadingType"]
    tempData["unloadingType"] = shipperQuotesObj["unloadingType"]
    setData({ ...data, ...tempData })
    setQuoteArr([...tempQuoteArr])
  }


  useEffect(() => {
    if (quote) {
      let objectAPI = {
        "userId": userId,
        "userEmail": userEmail,
        "userTypeId": userTypeId,
        "currentPage": 1,
        "resultPerPage": 10,
        "onlyShowQuote": quote
      }
      call('POST', 'getShippingQuoteListFinancier', objectAPI, "shipment").then((result) => {
        setShipmentFinancierQuoteDetails(result?.limitData?.[0] || {})
      }).catch((e) => {
        console.log("erroringetShippingQuoteListFinancier", e);
      });
    }
  }, [quote])



  useEffect(() => {
    let shipperQuotes = JSON.parse(shipmentFinancierQuoteDetails.shipperQuotes || "[]")
    if (!shipperQuotes?.length) {
      shipperQuotes.push({
        shipper_id: userId,
        name: userName,
        details: {},
        email: userEmail
      })
    }
    shipperQuotes = shipperQuotes.filter((i) => {
      if (i.shipper_id / 1 == userId / 1) {
        return true
      }
    })
    setShipperQuotes([...shipperQuotes])
    call('get', 'getcontainertypes').then((result) => {
      setcontainerTypeData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })
  }, [])


  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('shipmentBookingId', shipmentFinancierQuoteDetails.limit_id)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }


  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }


  const handleQuoteChange = (event) => {
    event.persist()
    let tempUpdatedQuoteDetails = shipperQuotes
    tempUpdatedQuoteDetails[tab - 1]["details"][event.target.name] = event.target.value
    setShipperQuotes([...tempUpdatedQuoteDetails])
  }

  async function updateShipmentContractBL(status) {
    if (status === 0) {
      let formData = new FormData()
      let isFileUploaded = false
      formData.append('traderUserId', shipmentFinancierQuoteDetails.createdBy)
      formData.append('applicationNo', shipmentFinancierQuoteDetails.applicationNo)
      formData.append('userId', userId)
      formData.append('quoteId', shipmentFinancierQuoteDetails.limit_id)
      for (let index = 0; index < multDocs.length; index++) {
        if (data[`doc${index}`]?.name) {
          isFileUploaded = true
          formData.append(`doc${index}`, data[`doc${index}`])
          if (data[`doc${index}`]?.signatureId) {
            formData.append(`doc${index}SignatureId`, data[`doc${index}`]?.signatureId)
          }
          if (data[`doc${index}Name`]) {
            formData.append(`doc${index}Name`, data[`doc${index}Name`])
          }
          if (data[`doc${index}No`]) {
            formData.append(`doc${index}No`, data[`doc${index}No`])
          }
        }
      }
      if (!isFileUploaded) {
        return toastDisplay("Please upload file to proceed", "info")
      }
      setshowLoader(true)
      await call('POST', 'updateShipmentContractBL', formData, "shipment")
      setshowLoader(false)
      toastDisplay("Documents uploaded successfully", "success")
    }
    else if (status === 1) {
      setshowLoader(true)
      await call('POST', 'updateShipmentContractBL', {
        quoteId: shipmentFinancierQuoteDetails.limit_id,
        traderUserId: shipmentFinancierQuoteDetails.createdBy, applicationNo: shipmentFinancierQuoteDetails.applicationNo
      }, "shipment")
      setshowLoader(false)
      toastDisplay("application rejected successfully", "success")
    }
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'ShipmentBookingShipperQuotes'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            {/* <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div> */}
            <p className="m-0 p-0 px-5 font-size-16 mt-4" >{applicationSubmitPopup.msg}</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          {quote ? null :
            <SideBarV2 state="ShipmentBookingShipperContract" userTokenDetails={userTokenDetails} />}
          <main role="main" className={`ml-sm-auto ${quote ? ' col-lg-12 mt-5' : ' col-lg-10 '} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {quote ? null :
              <>
                <HeaderV2
                  title={"Logistic > Contract > Contract Details"}
                  userTokenDetails={userTokenDetails} />
                <a className="cursor"
                  onClick={() => {
                    if (quoteScreen.show) {
                      toggleQuoteScreen({ show: false })
                    }
                    else {
                      history.go(-1)
                    }
                  }}
                ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
              </>}

            <div>
              {quoteScreen.show ? null : <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          setTab(index)
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>}

              <div className="card mt-1">
                {tab === 0 ?
                  <div className='p-4'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Application Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            if (item.val) {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {item.val ? (item.unit ? `${item.unit} ` : "") + (item.val) : "NA"}</span> </p>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}


                {tab === 1 ?
                  <div className="pt-4 px-4 pb-4">
                    <label className="font-size-16 font-wt-500 mb-3" >Charges Details</label>
                    <NewTablev2 stickTableHeaderToTop showSideBorders={true} columns={[{ subColumns: 'Type', subColumnStyle: { width: '15%' } }, { subColumns: 'Name', subColumnStyle: { width: '15%' } },
                    { subColumns: 'Time (days)', subColumnStyle: { width: '15%' } }, { subColumns: 'Distance (km)', subColumnStyle: { width: '15%' } },
                    { subColumns: 'Rate/Fees', subColumnStyle: { width: '20%' } }, { subColumns: 'co2 Release (kg)', subColumnStyle: { width: '15%' } },
                    ]} >
                      <tr>
                        <td>
                          <label className="font-size-12 font-wt-500" >MOL Type</label>
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <SelectForTable optionLabel={"name"} optionValue={"name"} selectData={[{ name: 'MOLOO' }, { name: 'MOLCO' }]} error={errors.molType} label={""}
                            name={'molType'} value={data.molType} />
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                      </tr>
                      <tr>
                        <td>
                          <label className="font-size-12 font-wt-500" >Quantity Vary Index (%)</label>
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <InputForTable error={errors.quantityVaryIndexPercentage} label={""} type={"number"}
                            name={'quantityVaryIndexPercentage'} value={data.quantityVaryIndexPercentage} />
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                      </tr>
                      <tr>
                        <td>
                          <label className="font-size-12 font-wt-500" >Loading Type</label>
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <SelectForTable optionLabel={"name"} optionValue={"name"} selectData={[{ name: 'Berth Loading' }, { name: 'Anchorage Loading' }]} error={errors.loadingType} label={""}
                            name={'loadingType'} value={data.loadingType} />
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                      </tr>
                      <tr>
                        <td>
                          <label className="font-size-12 font-wt-500" >Unloading Type</label>
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                        <td>
                          <SelectForTable optionLabel={"name"} optionValue={"name"} selectData={[{ name: 'Berth Unloading' }, { name: 'Anchorage Unloading' }]} error={errors.unloadingType} label={""}
                            name={'unloadingType'} value={data.unloadingType} />
                        </td>
                        <td>
                          <label className="font-size-12 font-wt-500" >-</label></td>
                      </tr>
                      {quoteArr.map((i, j) => {
                        if (i === null) {
                          return (
                            <tr>
                              <td>
                                <InputForTable name={`chargeType${j}`} value={data[`chargeType${j}`] || "-"}
                                  error={errors[`chargeType${j}`]} />
                              </td>
                              <td>
                                <InputForTable name={`chargeName${j}`} value={data[`chargeName${j}`] || "-"}
                                  error={errors[`chargeName${j}`]} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`chargeTime${j}`} value={data[`chargeTime${j}`] || "-"}
                                  error={errors[`chargeTime${j}`]} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`chargeDistance${j}`} value={data[`chargeDistance${j}`] || "-"}
                                  error={errors[`chargeDistance${j}`]} />
                              </td>
                              <td>
                                <InputWithSelectForTable type={"number"}
                                  selectData={currencyData}
                                  selectName={`chargeAmountUnit${j}`} selectValue={data[`chargeAmountUnit${j}`]}
                                  optionLabel={"code"} optionValue={'code'}
                                  name={`chargeAmount${j}`} value={data[`chargeAmount${j}`] || "-"}
                                  error={errors[`chargeAmount${j}`] || errors[`chargeAmountUnit${j}`]} />
                              </td>
                              <td>
                                <InputForTable type={"number"} name={`chargeco2${j}`} value={data[`chargeco2${j}`] || "-"}
                                  error={errors[`chargeco2${j}`]} />
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </NewTablev2>
                  </div>
                  : null}

                {tab === 2 ?
                  <div className='p-4'><div>
                    <NewTablev2 columns={documentTableForFinancierBL}>
                      {multDocs.map((i, j) => {
                        return <tr>
                          <td><div>
                            <FileInput hideViewDowdBtns={false} multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                let tempErr = errors
                                let tempData = data
                                for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                                  let element = e.target.files[indexx];
                                  let customEvent = {
                                    target: { name: `doc${j + indexx}`, files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (!resp.success) {
                                    tempErr[`doc${j + indexx}`] = resp.msg
                                  }
                                  else {
                                    tempData[`doc${j + indexx}`] = resp.msg
                                    tempErr[`doc${j + indexx}`] = ''
                                  }
                                }
                                setData(tempData)
                                setErrors(tempErr)
                                let temp = multDocs
                                for (let index = 0; index < e.target.files.length - 1; index++) {
                                  temp.push(null)
                                }
                                setMultDocs([...temp])
                                console.log("insideonMultipleDragDroppppp", e, tempData, tempErr, temp);
                              }}
                              name={`doc${j}`} value={data[`doc${j}`]} error={errors[`doc${j}`]}
                              isEditable={true}
                              onUploadCancel={() => setData({ ...data, [`doc${j}`]: null })}
                            />
                            {errors[`doc${j}`] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors[`doc${j}`]}</b></div> : ''}
                          </div></td>
                          <td><div className="position-relative row ">
                            <div className="col-8" >
                              <InputForTable isAstrix={false} type={"text"} label={""}
                                name={`doc${j}Name`} value={data[`doc${j}Name`]} error={errors[`doc${j}Name`]}
                                onChange={handleChange} />
                            </div>
                            {docDescByName[data[`doc${j}Name`]] ?
                              <div className="col-1" >
                                <img src={"assets/images/warning.png"} className="totalChargesLabel " />
                                <div style={{ top: '1rem' }} className="otherChargePopup p-3">
                                  <div>
                                    <label className="font-size-13 font-wt-600" ><u>{`${data[`doc${j}Name`]}:`}</u></label>
                                    <label className="font-size-12 font-wt-500">{docDescByName[data[`doc${j}Name`]]}</label>
                                  </div>
                                </div>
                              </div> : null}
                          </div></td>
                          <td><div className="position-relative">
                            <InputForTable isAstrix={false} type={"text"} label={""}
                              name={`doc${j}No`} value={data[`doc${j}No`]} error={errors[`doc${j}No`]}
                              onChange={handleChange} />
                          </div></td>
                          <td>
                            {!data[`doc${j}`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`doc${j}`]?.name); setData({ ...data, docToSign: data[`doc${j}`], docSignName: `doc${j}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td>
                            {(multDocs.length - 1 > j) ?
                              <img
                                onClick={() => {
                                  let temp = multDocs
                                  temp = temp.filter((m, n) => {
                                    if (n != j) {
                                      return true
                                    }
                                  })
                                  console.log("deleteeeeeeeeeeeeeeeeeeeeee", temp);
                                  setMultDocs([...temp])
                                }}
                                className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                              /> : <img
                                onClick={() => {
                                  let temp = multDocs
                                  temp.push(null)
                                  setMultDocs([...temp])
                                }}
                                style={{ width: '22px', height: '22px' }}
                                className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                          </td>
                        </tr>
                      })}
                    </NewTablev2>
                    <div className="row d-flex mt-4" >
                      <div className="col-2" >
                        <button type="button" className="new-btn text-white py-2 w-100"
                          onClick={() => { updateShipmentContractBL(0) }} id="">
                          Save
                        </button>
                      </div>
                      {/* <div className="col-2" >
                        <button type="button" className="new-btn text-white py-2 w-100  bg-danger"
                          onClick={() => { updateShipmentContractBL(1) }} id="">
                          Reject
                        </button>
                      </div> */}
                      <div>
                      </div>
                    </div>
                  </div>
                  </div>
                  : null}

              </div>

            </div>

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentBookingShipperContractDetails)