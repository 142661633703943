import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import mammoth from 'mammoth';

const RichTextEditor3 = ({ name, value, onChange, rows }) => {
  rows = rows || 5;
  const editorRef = useRef(null);
  const [content, setContent] = useState('');

  const handlePaste = async (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const docxFile = clipboardData.files[0];

    if (docxFile && docxFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      e.preventDefault();

      const reader = new FileReader();
      reader.onload = async (event) => {
        const arrayBuffer = event.target.result;
        const { value } = await mammoth.convertToHtml({ arrayBuffer });
        if (editorRef.current) {
          editorRef.current.setContent(value);
        }
      };
      reader.readAsArrayBuffer(docxFile);
    }
  };

  const handleEditorChange = (content) => {
    console.log("contenttttttttttttttttttttttt", content);
    let customEvent = {
      target: {
        name,
        value: content || ""
      },
      persist: () => { }
    };
    onChange(customEvent);
  };

  const handleFilePicker = (callback, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        callback(e.target.result, { alt: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  return (
    <div>
      <Editor
        apiKey="21kfaf7lgnm1tk8jws4utqobgp53z3ayy4vorywjus3iu1nz" // Replace with your TinyMCE API key
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.on('Change', () => handleEditorChange(editor.getContent()));
        }}
        initialValue={value}
        init={{
          height: rows * 100,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'table',
            'media',
            'image'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | \
            table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | image | media',
          file_picker_callback: handleFilePicker,
          file_picker_types: 'image',
          paste_data_images: true,
          paste_preprocess: (plugin, args) => {
            args.content = args.content.replace(/<p[^>]*><\/p>/g, '');
          },
          setup: (editor) => {
            editor.on('Paste', handlePaste);
          }
        }}
      />
    </div>
  );
};

export default RichTextEditor3;
