const columns = [
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Company Name',
    selector: 'name',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Address',
    selector: 'user_address',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Country',
    selector: 'country',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    cell: row => (row.status === 1 || row.status === 2) ? "Request Raised" :
      (row.status === 4) ? "Agreement signed by party & Request Accepted" :
        (row.status === 3) ? "Admin Send Agreement" : "",
    conditionalCellStyles: [

      {
        when: row => (row.status === 1 || row.status === 2),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 4),
        style: {
          backgroundColor: 'rgba(9, 164, 170, 1)',  //green
          color: 'rgba(71, 71, 71, 1)',
        },
      },
      {
        when: row => (row.status === 3),
        style: {
          backgroundColor: 'rgba(255, 150, 0, 0.9)',  //orange
          color: 'white',
        },
      },
    ]
  },
  {
    name: "Action",
    selector: 'action',
    sortable: false,
    grow: 1
  },
];

export default columns
