import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import FAQs from './components/faqs';
import MonthCard from './components/monthCard';
import StandardCard from './components/standardCard';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import TableFilter from './components/tableFilter';
import { useHistory } from 'react-router';
import call from '../../service';
import { ExportExcel, most_used_currencies, printDiv } from '../../utils/myFunctions';
import moment from 'moment';
import { convertImageToPdf } from '../../utils/myFunctions';
import toastDisplay from '../../utils/toastNotification';
import { getDocDetails } from '../../utils/myFunctions';
import { AccordionTable } from "./components/accordionTable";
import { ToastContainer } from 'react-toastify';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';



const servicesList = [
  {
    name: "LC Confirmation",
    amount: "100",
  },
  {
    name: "LC Discounting",
    amount: "200",
  },
  {
    name: "Invoice Discounting",
    amount: "300",
  },
  {
    name: "SBLC",
    amount: "400",
  },
];

const Refinance = [
  {
    name: "LC Discounting",
    amount: "200",
  },
  {
    name: "Invoice Discounting",
    amount: "300",
  },
];

const tableData2 = [
  {

    buyerName: "Rosti Suzhou Integrated ",
    LCno: "256985632547854",
    LCtype: "LC confirmation",
    Financeamount: "$ 1,00,000",
    Financer: "ICICI bank",
    Timeleft: "inprogress",
    days: "2",
    status: false,
    viewMore: [{
      company: 'xyz pvt',
      buyerName: "Rosti Suzhou Integrated ",
    }]
  },
  {

    buyerName: "HDFC",
    LCno: "256985632547854",
    LCtype: "LC confirmation",
    Financeamount: "$ 1,00,000",
    Financer: "ICICI bank",
    Timeleft: "inprogress",
    days: "2",
    status: false,
    viewMore: [{
      company: 'xyz pvt',
      buyerName: "HDFC",
    }]
  },
];


const Wallet = ({ userTokenDetails, navToggleState }) => {

  const [limitinvoice, setLimitinvoice] = useState(false);

  const history = useHistory();

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)


  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)

  const [tableExpand, setTableExpand] = useState("");
  const [expandedData, setExapndedData] = useState([]);
  const [walletData, setWalletData] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [serviceCharges, setServiceCharges] = useState({})


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getWalletBalance', { userId }).then((result) => {
      console.log("API result getWalletBalance", result);
      setshowLoader(false)
      if (result && result.length) {
        setWalletData(result[0])
      }
    }).catch((e) => {
      console.log("Error while getWalletBalance", e);
      setshowLoader(false)
    });
    call('POST', 'getWallertServiceCharges', { userId, userTypeId }).then((result) => {
      console.log("API result getWallertServiceCharges", result);
      setshowLoader(false)
      setServiceCharges(result)
    }).catch((e) => {
      console.log("Error while getWallertServiceCharges", e);
      setshowLoader(false)
    });
    let walletTransactionReq = {
      userId,
      search: filter.search,
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      fromDate: filter.fromDate,
      toDate: filter.toDate
    }
    console.log('getWalletTransactionHistory req', walletTransactionReq, filter)
    call('POST', 'getWalletTransactionHistory', walletTransactionReq).then((result) => {
      console.log("API result getWalletTransactionHistory", result.message);
      setTableData(formatDataForTable(result.message))
      setDbData(result.message)
      setCount(result.countdata.total_transactions)
    }).catch((e) => {
      console.log("Error while getWalletTransactionHistory", e);
    });
  }, [refresh, page])


  const closeFn = () => {
    setLimitinvoice(false);
  }
  const exportToexcel = (data, filename) => {
    let downloaddata = []
    data.forEach(data => {
      let downloadobj = {
        "Name": data.createdByName,
        "Service Name": data.serviceName,
        "Transaction ID": data.transactionId,
        "Type of Transaction": data.type,
        "Charges": data.charges,
        "Mode Of Payment": data.modeOfPayment,
        "Date And Time": moment(data.createdAt).format('DD-MM-YYYY hh:ss:mm A'),
      }
      downloaddata.push(downloadobj)
    })
    ExportExcel(downloaddata, filename)
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      console.log(tableExpand, item)

      row[0] = <div>
        <img src={item.status === 1 ? "/assets/images/supplier-images/inProgress_icon.png" : item.status === 3 ? "/assets/images/supplier-images/failed_icon.png" : item.type === 'DEBIT' ? "/assets/images/supplier-images/debit_icon.png" : "/assets/images/supplier-images/credit_icon.png"} alt='' />
      </div>
      row[1] = item.createdByName
      row[2] = moment(item.updatedAt).format('DD/MM/YYYY hh:mm a')
      row[3] = item.serviceName
      row[4] = item.modeOfPayment
      row[5] = <>
        <span> <img src={'/assets/images/Lc/Dollar.png'} alt='' />{Math.abs(item.charges)}</span>
      </>
      // row[6] = item.type
      row[6] = item.transactionId === tableExpand ? <div className='row' >
        <a onClick={() => expandedTableFN()}>
          View Less
        </a>
      </div > : <div className='row' >
        <a onClick={() => expandedTable([item], item.transactionId)} className="text-color1 font-wt-600">
          View More
        </a>
      </div >
      row[7] = item.transactionId
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const expandedTable = (more, buyer) => {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeee", more, buyer);
    setTableExpand(buyer);
    setExapndedData(more);
  }

  const expandedTableFN = () => {
    setTableExpand("");
    setExapndedData([]);

  }


  async function onEdit(index) {
    toggleAddBuyerForm(true)
    let selectedBuyer = dbData[index]
    let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
    let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])
    setData({
      buyerId: selectedBuyer.id,
      buyerName: selectedBuyer.buyerName,
      buyerCountry: selectedBuyer.buyerCountry,
      buyerEmail: selectedBuyer.buyerEmail,
      buyerContact: selectedBuyer.buyerPhone,
      buyerPhoneCode: selectedBuyer.buyerPhoneCode,
      buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
      buyerWebsite: selectedBuyer.buyerWebsite,
      buyerPrevSale: selectedBuyer.previousAnnualSale,
      buyerExpectedSale: selectedBuyer.currentAnnualSale,
      buyerIncoTerms: selectedBuyer.incoTerms,
      buyerTermsPayment: selectedBuyer.termsOfPayment,
      buyerProductDetails: selectedBuyer.productDetails,
      buyerHsnCode: selectedBuyer.buyerHsnCode ? selectedBuyer.buyerHsnCode.split(",") : null,
      buyersDocId: selectedBuyer.buyersDocId,
      invoiceDocument: { ...invoiceDocument, noChange: true },
      poDocument: { ...poDocument, noChange: true },
      isEditing: true,
      tcAccepted: true
    })
  }

  async function onView(index) {
    // alert(index)
    history.push("/viewDetails");
  }

  console.log('ExpandedTable', tableData)

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="wallet" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Wallet"}
              userTokenDetails={userTokenDetails} />
            <div className='row gap-4'>
              <div className='col-md-3'>
                <StandardCard data={walletData} amount={1} userId={userId} toastDisplay={(msg, status) => toastDisplay(msg, status)} />
              </div>
              <div className='col-md-3'>
                <MonthCard setshowLoader={setshowLoader} userId={userId} />
              </div>
              <div className='my-4'>
                <button className='walletbtn font-size-14'>Pay</button>
                <button onClick={() => setLimitinvoice(true)} className='walletbtn mx-3 font-size-14'>Service charges</button>
                <button className='walletbtn font-size-14'>Rewards</button>
              </div>
            </div>
            <div className='my-3'>
              <h6>Recent transaction</h6>
              <TableFilter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} onExcelExport={() => exportToexcel(dbData, "Excel_WalletTransactions")} onPDFExport={() => printDiv("transactionTableDiv", "Excel_WalletTransactions", [])} />
              <div id="transactionTableDiv">
                <AccordionTable disableAction={false}
                  columns={[{ name: "", filter: false },
                  { name: "Name", filter: true },
                  { name: "Date", filter: true },
                  { name: "Purpose", filter: true },
                  { name: "Mode of Payment", filter: true },
                  { name: "Amount", filter: true },
                  // { name: "Type", filter: true },
                  { name: "", filter: false }
                  ]}
                  data={tableData} overalldata={dbData}
                  expand={expandedData} tableExpand={tableExpand} />
              </div>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 10} />
            </div>
            <FAQs />
          </main>
        </div>
      </div >
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeFn}>
        <p className='font-wt-600'>Service charges</p>
        <div className=' mx-5'>
          <p className='text-secondary mb-3 text-start'>Finance limit</p>
          {servicesList.map((key, index) => {
            return (
              <p className='d-flex justify-content-between border-bottom pb-2 mt-2 font-size-13 align-items-center' key={key.name}>
                <>
                  <span>{key.name}</span><span> <img src={'/assets/images/Lc/Dollar.png'} alt='' />
                    {serviceCharges[key.name] && serviceCharges[key.name]["discountedPrice"] ? (
                      <del className='mr-2'>{serviceCharges[key.name] && serviceCharges[key.name]["serviceCharges"] * -1}</del>
                    ) : serviceCharges[key.name] && serviceCharges[key.name]["serviceCharges"] * -1}
                    {serviceCharges[key.name] && serviceCharges[key.name]["discountedPrice"] ? (serviceCharges[key.name]["discountedPrice"] * -1) : ""}
                    {" Coins"}</span>
                </>
              </p>
            );
          })}
        </div>
        <div className=' mx-5'>
          <p className='text-secondary mb-3 text-start'>Re - Finance Application</p>
          {Refinance.map((key, index) => {
            return (
              <p className='d-flex justify-content-between border-bottom pb-2 mt-2 font-size-13 align-items-center' key={key.name}>
                <>
                  <span>{key.name}</span><span> <img src={'/assets/images/Lc/Dollar.png'} alt='' />
                    {serviceCharges[key.name + " Finance Application"] && serviceCharges[key.name + " Finance Application"]["discountedPrice"] ? (
                      <del className='mr-2'>{serviceCharges[key.name] && serviceCharges[key.name + " Finance Application"]["serviceCharges"] * -1}</del>
                    ) : serviceCharges[key.name + " Finance Application"] && serviceCharges[key.name + " Finance Application"]["serviceCharges"] * -1}
                    {serviceCharges[key.name + " Finance Application"] && serviceCharges[key.name + " Finance Application"]["discountedPrice"] ? (serviceCharges[key.name + " Finance Application"]["discountedPrice"] * -1) : ""}
                    {" Coins"}</span>
                </>
              </p>
            );
          })}
        </div>
      </FinanceInvoiceModal>}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Wallet)