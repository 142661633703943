export let getBulkBreakMailTemplate = (data) => {
  return `Dear ${data.buyerName},

I hope this email finds you well.

I am pleased to inform you that the contract for the ${data.commodity_pretty_name} commodity has been successfully created on TradeReboot platform. Below are the details of the contract for your reference:

Commodity Name: ${data.commodity_pretty_name}
Quantity: ${data.quantity} ${data.unit}
Rate: ${data.ratePerUnit} ${data.currency}
Total Pricing Details: ${data.totalPrice} ${data.currency}

Please review the contract at your earliest convenience and let us know if you have any questions or require any further information. We are looking forward to working with you collaboratively on this contract.

You can access the contract and all related documents on TradeReboot platform using the following link:
${data.link}

Thank you for your attention to this matter.

Best regards,

${data.userName}`;
};

export let getBulkBreakMailTemplateforLC = (data) => {
  return `Dear ${data.user_name},

I hope this email finds you well.

I am pleased to inform you that the Letter of credit has been successfully created on TradeReboot platform. Below are the details of the contract for your reference.
Please review the contract at your earliest convenience and let us know if you have any questions or require any further information. We are looking forward to working with you collaboratively on this contract.

You can access the contract and all related documents on TradeReboot platform using the following link :
${data.link}

Thank you for your attention to this matter.

Best regards,

${data.userName}`;
};
