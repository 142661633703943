import React, { useState } from "react";

const Topup = ({ onCurrentDetailsClick, onToupClick }) => {

  return (
    <>
      <div className='card Month borderRadius px-3 justify-content-evenly' style={{
        width: "240px",
        height: "130px"
      }}>
        <div className="text-center topupDiv border-bottom">
          <label className="cursor" onClick={() => onCurrentDetailsClick(true)}>Current Plan Details</label>
        </div>
        <div className="text-center topupDiv border-bottom">
          <label className="cursor" onClick={() => onToupClick(true)}>Top-up</label>
        </div>
        <div className="text-center">
          <label className="cursor">Rewards</label>
        </div>

      </div>
    </>
  );
}
export default Topup;