import React, { useState, useRef, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import format from 'date-fns/format';
import NewTablev2 from "../../../../utils/newTablev2";
import DropdownSearch from "../dropdownWithSearch";
import * as XLSX from 'xlsx';


export default function DropOff({ allData }) {
  const [showPicker, setShowPicker] = useState({ during: false, dropOff: false });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
  });
  const [dropOffDateRange, setDropOffDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
  });
  const [filterCustomerBy, setFilterCustomerBy] = useState(null);
  const [valuesForBilled, setValuesForBilled] = useState([]);
  const [propertyForFilter, setPropertyForFilter] = useState('');
  const [haveBilled, setHaveBilled] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showText, setShowText] = useState(false);

  const customerDataDropDown = ["Item Name", "Customer Group", "Items Group", "Item Category"];
  const columns = [
    { subColumns: "Customer", subColumnClass: "customer-class" },
    { subColumns: "Invoice Number", subColumnClass: "invoice-class" },
    { subColumns: "Quantity", subColumnClass: "quantity-class" },
    { subColumns: "Taxless", subColumnClass: "taxless-class" },
    { subColumns: "Taxpaid", subColumnClass: "taxpaid-class" }
  ];

  useEffect(() => {
    if (allData.length) {
      const items = allData.filter(item => item.type === "Sales");
      console.log("sales items", items);
      setSalesData(items);
    }
  }, [allData]);

  useEffect(() => {
    if (allData.length && filterCustomerBy) {
      let segment;
      switch (filterCustomerBy) {
        case "Item Name":
          segment = 'itemName';
          break;
        case "Items Group":
          segment = "itemGroup";
          break;
        case "Item Category":
          segment = "category";
          break;
        case "Customer Group":
          segment = "customerGroup";
          break;
        default:
          segment = "";
      }
      if (segment) {
        setPropertyForFilter(segment);
        const items = allData.map((item) => item[segment]).filter(Boolean);
        setValuesForBilled(items);
      }
    }
  }, [filterCustomerBy, allData]);

  const pickerRef = useRef(null);
  const dropOffPickerRef = useRef(null);

  const handleSelect = (ranges) => {
    setShowText(false);
    setShowTable(false);
    setDateRange(ranges.selection);
  };

  const handleDropOffSelect = (ranges) => {
    setShowText(true);
    setShowTable(false);
    setDropOffDateRange(ranges.selection);
  };

  const handleFilterCustomerChange = (value) => {
    setShowText(false);
    setShowTable(false);
    setFilterCustomerBy(value);
  };

  const handleHaveBilledChange = (selectedItems) => {
    setShowText(false);
    setShowTable(false);
    setHaveBilled(selectedItems);
  };

  const formattedDateRange = dateRange.startDate && dateRange.endDate
    ? `${format(dateRange.startDate, 'MM/dd/yyyy')} - ${format(dateRange.endDate, 'MM/dd/yyyy')}`
    : '';

  const formattedDropOffDateRange = dropOffDateRange.startDate && dropOffDateRange.endDate
    ? `${format(dropOffDateRange.startDate, 'MM/dd/yyyy')} - ${format(dropOffDateRange.endDate, 'MM/dd/yyyy')}`
    : '';

  const handleClickOutside = (event) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target) &&
      !event.target.classList.contains('during-input')
    ) {
      setShowPicker((prev) => ({ ...prev, during: false }));
    }
    if (
      dropOffPickerRef.current &&
      !dropOffPickerRef.current.contains(event.target) &&
      !event.target.classList.contains('dropOff-input')
    ) {
      setShowPicker((prev) => ({ ...prev, dropOff: false }));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filterSalesData = () => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const dropOffStartDate = dropOffDateRange.startDate;
    const dropOffEndDate = dropOffDateRange.endDate;
    console.log(startDate, endDate, dropOffEndDate, dropOffStartDate, haveBilled, propertyForFilter)

    // const billedInDateRange = salesData.filter(item => {
    //   const itemDate = new Date(item.date);
    //   return haveBilled.includes(item[propertyForFilter])  && itemDate >= startDate && itemDate <= endDate;
    // });
    // billedInDateRange.forEach(item => {
    //   const itemDate = new Date(item.date);
    //   if( itemDate >= dropOffStartDate && itemDate <= dropOffEndDate){
    //     billedInDateRange.pop(item)
    //   }
    // });
    // setFilteredSalesData(billedInDateRange)
    //Filter sales data for the specified property within the initial date range
    console.log(salesData)
    const billedInDateRange = salesData.filter(item => {
      const itemDate = new Date(item.date);
      return haveBilled.includes(item[propertyForFilter]) && itemDate >= startDate && itemDate <= endDate;
    });
    console.log("billedInDateRange", billedInDateRange)
    //Filter sales data for the specified property within the drop-off date range
    const billedInDropOffRange = salesData.filter(item => {
      const itemDate = new Date(item.date);
      return haveBilled.includes(item[propertyForFilter]) && itemDate >= dropOffStartDate && itemDate <= dropOffEndDate;
    });
    console.log("billedInDropOffRange", billedInDropOffRange)
    //Identify customers who billed in the first range but dropped off in the second range
    const customersBilledInDateRange = new Set(billedInDateRange.map(item => item[propertyForFilter]));
    const customersBilledInDropOffRange = new Set(billedInDropOffRange.map(item => item[propertyForFilter]));

    const customersWhoDroppedOff = [...customersBilledInDateRange].filter(customer => !customersBilledInDropOffRange.has(customer));

    //Filter the original sales data to only include those customers who dropped off
    const filteredData = billedInDateRange.filter(item => customersWhoDroppedOff.includes(item[propertyForFilter]));

    if (filteredData.length === 0) {
      console.log("No Data Found");
    } else {
      console.log(filteredData);
    }
    setFilteredSalesData(filteredData);
  };

  const handleSubmit = () => {
    filterSalesData();
    setShowTable(true);
  };
  const handleDownload = () => {
    const tableData = filteredSalesData.map(row => ({
      Customer: row.partyName,
      "Invoice Number": row.invoiceNumber,
      Quantity: row.quantity,
      Taxless: row.taxLess,
      Taxpaid: row.taxPaid,
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "filteredData.xlsx");
  };
  return (
    <div className='mt-2'>
      <div className='inputboxes d-flex justify-content-between align-items-center'>
        <div className="mb-3">
          <label htmlFor="filterCustomer" className="form-label">
            Filter Customer By:
          </label>
          <DropdownSearch
            placeholder="Filter Customer By"
            items={customerDataDropDown}
            onSelect={handleFilterCustomerChange}
            isDisabled={!dateRange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="haveBilled" className="form-label">
            Who Have Billed
          </label>
          <DropdownSearch
            placeholder="Who Have Billed"
            items={valuesForBilled}
            onSelect={handleHaveBilledChange}
            isDisabled={!filterCustomerBy}
            multipleSelection={true}
            selectAll={true}
          />
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="during" className="form-label d-block">
            During
          </label>
          <input
            className='rounded p-1 border during-input fs-6'
            type="text"
            readOnly
            value={formattedDateRange}
            onClick={() => setShowPicker((prev) => ({ ...prev, during: !prev.during }))}
            disabled={!haveBilled.length}
            placeholder='DD-MM-YYYY'
          />
          {showPicker.during && (
            <div
              className='position-absolute mt-3'
              style={{ width: "150px", height: "150px", right: "90%" }}
              ref={pickerRef}
            >
              <DateRangePicker
                style={{ width: "300px", height: "250px" }}
                className='border rounded shadow fs-6'
                ranges={[dateRange]}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
              />
            </div>
          )}
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="dropOff" className="form-label d-block">
            Drop of Duration
          </label>
          <input
            className='rounded p-1 border dropOff-input fs-6'
            type="text"
            readOnly
            value={formattedDropOffDateRange}
            placeholder='DD-MM-YYYY'
            disabled={!dateRange.startDate || !dateRange.endDate}
            onClick={() => setShowPicker((prev) => ({ ...prev, dropOff: !prev.dropOff }))}
          />
          {showPicker.dropOff && (
            <div
              className='position-absolute mt-3'
              style={{ width: "150px", height: "150px", right: "200%" }}
              ref={dropOffPickerRef}
            >
              <DateRangePicker
                style={{ width: "300px", height: "250px" }}
                className='border rounded shadow fs-6'
                ranges={[dropOffDateRange]}
                onChange={handleDropOffSelect}
                moveRangeOnFirstSelection={false}
              />
            </div>
          )}
        </div>
      </div>
      {dropOffDateRange.startDate && dropOffDateRange.endDate && showText && (
        <div className="mt-2 mb-2 w-100 d-flex justify-content-between align-items-center">
          <div>
            <p className="fs-6 m-0">List Of Customers Who Have Billed <span style={{ color: "rgb(46, 204, 113)" }}>{haveBilled.join(", ")}</span></p>
            <p className="fs-6 m-0">During <span style={{ color: "rgb(255, 123, 109)" }}>{format(dateRange.startDate, 'MM/dd/yyyy')}</span> To <span style={{ color: "rgb(255, 123, 109)" }}>{format(dateRange.endDate, 'MM/dd/yyyy')}</span> But Got Dropped Off During <span style={{ color: "rgb(255, 123, 109)" }}>{format(dropOffDateRange.startDate, 'MM/dd/yyyy')}</span> To <span style={{ color: "rgb(255, 123, 109)" }}>{format(dropOffDateRange.endDate, 'MM/dd/yyyy')}</span></p>
          </div>
          <button onClick={handleSubmit} className="btn btn-info">Go</button>
        </div>
      )}
      {showTable && (
        filteredSalesData.length > 0 ? (
          <div>
            <div className='position-relative'>

              <h7 className="border border-secondary rounded d-inline-block p-2">
                Quantity: {filteredSalesData.reduce((sum, item) => sum + item.quantity, 0)}
              </h7>
              <h7 className="border border-secondary rounded d-inline-block p-2 m-3">
                Tax Less: ${filteredSalesData.reduce((sum, item) => sum + item.taxLess, 0).toFixed(2)}
              </h7>
              <h7 className="border border-secondary rounded d-inline-block p-2 m-3">
                Tax Paid: ${filteredSalesData.reduce((sum, item) => sum + item.taxPaid, 0).toFixed(2)}
              </h7>
              <button onClick={handleDownload} className="bg-white rounded position-absolute m-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>

            </div>
            <NewTablev2 className="mt-5" columns={columns} tableFixed={true} showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }} hideHeading={false}>
              {filteredSalesData.map((data, index) => (
                <tr key={index}>
                  <td>{data.partyName}</td>
                  <td>{data.invoiceNumber}</td>
                  <td>{data.quantity}</td>
                  <td>{data.taxLess}</td>
                  <td>{data.taxPaid}</td>
                </tr>
              ))}
            </NewTablev2>
          </div>
        ) : (
          <h2 style={{ color: "rgb(255, 123, 109)" }} className="text-center m-5 rounded">
            No Data Found
          </h2>
        )
      )}
    </div>
  );
}
