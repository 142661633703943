import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import call from "../../../service";
import MultipleSelect from "../../../utils/MultipleSelect";
import { NewInput, NewSelect, NewTextArea } from "../../../utils/newInput";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import toastDisplay from "../../../utils/toastNotification";
import { ToastContainer } from "react-toastify";

export const reminders = [
    { name: '15 minutes' },
    { name: '30 minutes' },
    { name: '60 minutes' },
]

const CalenderView = ({ calender, setCalender, userId, setShowLoader, data, setData, userName, getCalendarList }) => {
    const [tab, setTab] = useState("People");
    const [showPopup, setShowPopup] = useState(false)

    const [userList, setUserList] = useState([])
    const [filteredUserList, setFilteredUserList] = useState([])
    const [errors, setErrors] = useState({})
    const [meetType, setMeetType] = useState('')
    const [timezones, setTimeZone] = useState([])
    const getTimeZones = () => {
        call('GET', 'gettimezones', {}).then(result => {
            setTimeZone(result)
        }).catch(e => {
            console.log('error in gettimezones', e)
        })
    }
    const getAllUsers = () => {
        call('GET', 'getAllUsers', {}).then(result => {
            setUserList(result)
            setFilteredUserList(result)
        }).catch(e => {
            console.log('error in getAllUsers', e)
        })
    }
    useEffect(() => {
        getTimeZones()
        getAllUsers()
    }, [])
    const handleChange = async (event) => {
        if (event.persist) {
            event.persist()
        }
        if (event.target.name === 'meetingDate') {
            setData({ ...data, [event.target.name]: new Date(event.target.value) })
            setErrors({ ...errors, [event.target.name]: "" })
            return
        }
        setData({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
    }
    const handleMultiSelect = async (e, name, val) => {
        console.log('handleMultiselect', e)
        setData({
            ...data,
            [name]: Array.isArray(e) ? e.map((x) => x["name"]) : []
        });
    }
    const createMeeting = () => {
        setShowLoader(true)
        let startdate = new Date(data.meetingDate)
        let enddate = new Date(data.meetingDate)
        const fromTime = data.from.split(":")
        const endTime = data.to.split(":")
        startdate.setHours(parseInt(fromTime[0]))
        startdate.setMinutes(parseInt(fromTime[1]))
        enddate.setHours(parseInt(endTime[0]))
        enddate.setMinutes(parseInt(endTime[1]))
        const reminder = data.reminder.split(" ")
        let participantInfo = []
        data.selectedUsers.forEach(item => {
            const match = userList.find(element => element.name === item)
            if (match) {
                participantInfo.push(match.userId)
            }
        })

        const reqObj = {
            userId,
            startTime: startdate,
            EndTime: enddate,
            meetingType: meetType === 'audio_active' ? 0 : meetType === 'video_active' ? 1 : 0,
            timeZone: data.timezone,
            participantInfo,
            reminder_duration: reminder[0],
            reminder_unit: reminder[1],
            meetingTitle: data.title,
            meeting_description: data.description,
            meeting_location: data.location,
            userName
        }
        call('POST', 'createMeeting', reqObj).then(result => {
            console.log('success in create meeting link', result)
            setShowPopup(false)
            setShowLoader(false)
            getCalendarList()
            toastDisplay(result, "success")
        }).catch(e => {
            console.log('Failed to create meeting link', e)
            toastDisplay(e, "error")
            setShowLoader(false)
        })
    }
    return (
        <>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

            {showPopup &&
                <FinanceInvoiceModal limitinvoice={showPopup} closeSuccess={() => setShowPopup(false)}>
                    <div>
                        <NewInput name={"title"} value={data.title} label="Title" type={"text"} onChange={handleChange} />
                        <div className="row">
                            <div className="col-md-4">
                                <NewInput name={"meetingDate"} value={moment(data.meetingDate).format('YYYY-MM-DD')} label="Date" type={"date"} onChange={handleChange} />
                            </div>
                            <div className="col-md-4">
                                <NewInput name={"from"} value={data.from} label="From" type={"time"} onChange={handleChange} />
                            </div>
                            <div className="col-md-4">
                                <NewInput name={"to"} value={data.to} label="To" type={"time"} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <img src={meetType === '' ? `/assets/images/audio_meet.svg` : meetType === 'audio_active' ? `/assets/images/audio_meet_active.svg` : `/assets/images/audio_meet_inactive.svg`} className="cursor" onClick={() => setMeetType('audio_active')} width={64} height={38} />
                                <img src={meetType === '' ? `/assets/images/video_meet.svg` : meetType === 'video_active' ? `/assets/images/video_meet_active.svg` : `/assets/images/video_meet_inactive.svg`} className="ml-2 cursor" onClick={() => setMeetType('video_active')} width={64} height={38} />
                            </div>
                            <div className="col-md-4">
                                <NewSelect
                                    isAstrix={true} label={"Time Zone"}
                                    selectData={timezones} name={"timezone"}
                                    value={data.timezone} optionLabel={"timezone_code"} optionValue={'timezone_code'}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <NewSelect
                                    isAstrix={true} label={"Select Location"}
                                    selectData={[{ name: "Trade Reboot Platform" }]} name={"location"}
                                    value={data.location} optionLabel={"name"} optionValue={'name'}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 p-0">
                            <label className="text-left font-size-13 font-wt-400 col-md-12 p-0">Add description</label>
                            <NewTextArea
                                rows={6}
                                type={"text"} label={``} name={"description"}
                                value={data.description}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <MultipleSelect
                                    Id=""
                                    Label="Select Participants"
                                    onClick={() => { }}
                                    optiondata={filteredUserList}
                                    onChange={(e) => {
                                        console.log('On autocomplete change', e)
                                        handleMultiSelect(e, "selectedUsers", "userId")
                                    }}
                                    value={data.selectedUsers || []}
                                    hideLabel={true}
                                    name="selectedUsers"
                                    labelKey={"name"}
                                    valKey={"name"}
                                    error={errors.selectedUsers}
                                />
                            </div>
                            <div className="col-md-4">
                                <NewSelect
                                    isAstrix={true} label={"Reminder (before)"}
                                    selectData={reminders} name={"reminder"}
                                    value={data.reminder} optionLabel={"name"} optionValue={'name'}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="selectedDiv">
                                {data.selectedUsers && data.selectedUsers.map(name => {
                                    return <div className="SelectedPart ">
                                        <label className="text-center pt-2">{name}</label>
                                        <img src="/assets/images/cross.png" className="cursor" onClick={() => {
                                            const temp = data.selectedUsers.filter(item => item !== name)
                                            console.log('tempppppppppp', temp)
                                            setData({
                                                ...data,
                                                selectedUsers: temp
                                            })
                                        }} />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="d-flex gap-4" onClick={createMeeting}>
                            <button className={`new-btn w-40 py-2 px-2 text-white cursor`} onClick={() => { }}>Save</button>
                        </div>
                    </div>
                </FinanceInvoiceModal>
            }
            <div className='card p-3 borderRadius border-0 chatlist h-90'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <img src="/assets/images/ArrowBackLeft.png" className="ml-3 cursor" onClick={() => setCalender(false)} />
                    </div>
                    <div className='col-2'>
                        <img src={'/assets/images/calender.png'} alt='' onClick={() => setCalender(false)} className="cursor" />
                    </div>
                </div>

                {/* <nav>
                    <div className="nav nav-tabs signdoctabs justify-content-around border-0" id="nav-tab" role="tablist">
                        <button className="nav-link active bg-transparent font-size-14" id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("People")}>People</button>
                        <button className="nav-link  bg-transparent font-size-14" id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("Group")}>Group</button>
                        <button className="nav-link  bg-transparent font-size-14" id="nav-contact-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Calls")}>Calls</button>
                    </div>
                </nav> */}
                <div className="tab-content d-block" id="nav-tabContent">
                    <div className="border-rounded mt-4">
                        <div className="px-2 py-1">
                            <Calendar onChange={(val) => {
                                console.log('valllllllllll', val)
                                setData({
                                    ...data,
                                    meetingDate: val
                                })
                            }} value={data.meetingDate} className="borderRadius border-0 calenderBorder col-md-12" next2Label={null} prev2Label={null} selectRange={false} calendarType={"US"} />
                        </div>
                    </div>
                </div>

                <button type="button" onClick={() => setShowPopup(true)}
                    className={` new-btn col-md-7 mt-4 mx-auto py-2 px-2 text-white`}>
                    Schedule new meet
                </button>
            </div>
        </>
    )
}

export default CalenderView;