import NewTablev2 from "../../../../utils/newTablev2";
import TableWithPages from "../tableWithArrows";
import GraphCard from "./graphcard";
import { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx';
import { DateRangePicker } from 'react-date-range';
import { startOfYear, endOfYear } from 'date-fns';
import format from 'date-fns/format';

export default function GPView({ allData }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [salesData, setSalesData] = useState([])
  const [f_salesData, f_setSalesData] = useState([])
  const [dateRange, setDateRange] = useState({
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
    key: 'selection',
  });
  const [stateData, setStateData] = useState([])
  const [continentData, setContinentData] = useState([])
  const [customerGroupData, setCustomerGroupData] = useState([]);
  const [itemGroupData, setItemGroupData] = useState([]);
  const [itemCategoryData, setItemCategoryData] = useState([])
  const [showPicker, setShowPicker] = useState({ during: false, dropOff: false });
  const pickerRef = useRef(null);
  const [totals, setTotals] = useState({
    totalAmountTotal: 0,
    GPTotal: 0,
    GPPercentageTotal: 0,
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
  };
  const formattedDateRange = dateRange.startDate && dateRange.endDate
    ? `${format(dateRange.startDate, 'MM/dd/yyyy')} - ${format(dateRange.endDate, 'MM/dd/yyyy')}`
    : '';
  const [columns, setColumns] = useState([
    { field_Name: "itemName", subColumns: 'Item Name' },

    { field_Name: "invoiceNumber", subColumns: 'Bill No' },

    { field_Name: "QtyWithUnits", subColumns: 'Quantity' },
    { field_Name: "taxPaid", subColumns: 'Tax Paid' },
    { field_Name: "rate", subColumns: 'Rate ($)' },
    { field_Name: "taxLess", subColumns: 'Tax Less ($)' },
    { field_Name: "ASPTaxPaid", subColumns: 'ASP TaxPaid ($)' },
    { field_Name: "ASPTaxLess", subColumns: 'ASP TaxLess ($)' },
    {
      field_Name: "totalAmount", subColumns: 'Total Amount ($)'
    },
    {
      field_Name: "GP", subColumns: "GP"
    },
    {
      field_Name: "GPPercentage", subColumns:

        "GP %"


    },
    { field_Name: "contribution", subColumns: 'Cont %' },




  ]);
  const aggregateDataByProperty = (data, property) => {
    const result = data.reduce((acc, curr) => {
      const key = curr[property];
      if (!acc[key]) {
        acc[key] = {
          [property]: key,
          totalAmount: 0,
          quantity: 0,
          taxPaid: 0,
          taxLess: 0,
          ASPTaxPaid: 0,
          ASPTaxLess: 0,
          GP: 0,
        };
      }
      acc[key].totalAmount += curr.totalAmount || 0;
      acc[key].quantity += curr.quantity || 0;
      acc[key].taxPaid += curr.taxPaid || 0;
      acc[key].taxLess += curr.taxLess || 0;
      acc[key].ASPTaxPaid += curr.ASPTaxPaid || 0;
      acc[key].ASPTaxLess += curr.ASPTaxLess || 0;
      acc[key].GP += (curr.GP === "NA" ? 0 : curr.GP) || 0;

      return acc;
    }, {});
    console.log(result)
    return Object.values(result).map(item => ({
      ...item,
      totalAmount: parseFloat(item.totalAmount.toFixed(1)),
      quantity: parseFloat(item.quantity.toFixed(1)),
      taxPaid: parseFloat(item.taxPaid.toFixed(1)),
      taxLess: parseFloat(item.taxLess.toFixed(1)),
      ASPTaxPaid: parseFloat(item.ASPTaxPaid.toFixed(1)),
      ASPTaxLess: parseFloat(item.ASPTaxLess.toFixed(1)),
      GP: item.GP === "NA" ? "NA" : parseFloat(item.GP.toFixed(1)),
    }))

  };
  const aggregateDataForAll = () => {
    if (salesData.length) {
      setStateData(aggregateDataByProperty(salesData, 'state'));
      setContinentData(aggregateDataByProperty(salesData, 'continent'));
      setCustomerGroupData(aggregateDataByProperty(salesData, 'customerGroup'));
      setItemGroupData(aggregateDataByProperty(salesData, 'itemGroup'));
      setItemCategoryData(aggregateDataByProperty(salesData, 'category'));
    }
  }
  function calculateGPPercentage(grossProfit, totalAmount) {
    if (totalAmount === 0) {
      return 0; // To avoid division by zero
    }
    return (grossProfit / totalAmount) * 100;
  }
  function calculateContributionAndGP(data) {
    // const filteredData = data.filter(Boolean);
    const totalAmount = data.reduce((acc, item) => acc + parseInt(item.totalAmount), 0);
    return data.map(item => ({
      ...item,
      contribution: parseFloat((item.totalAmount / totalAmount * 100).toFixed(1)),
      GPPercentage: calculateGPPercentage(item.GP, item.totalAmount).toFixed(2)
    }));
  }
  const calculateTotals = (data) => {
    const totalAmountTotal = data.reduce((acc, item) => acc + item.totalAmount, 0);
    const GPTotal = data.reduce((acc, item) => acc + (item.GP === "NA" ? 0 : item.GP), 0);
    const GPPercentageTotal = totalAmountTotal === 0 ? 0 : (GPTotal / totalAmountTotal) * 100;

    setTotals({
      totalAmountTotal: parseFloat(totalAmountTotal.toFixed(1)),
      GPTotal: parseFloat(GPTotal.toFixed(1)),
      GPPercentageTotal: parseFloat(GPPercentageTotal.toFixed(2)),
    });
    setColumns([
      { field_Name: "itemName", subColumns: 'Item Name' },
      { field_Name: "invoiceNumber", subColumns: 'Bill No' },
      { field_Name: "QtyWithUnits", subColumns: 'Quantity' },
      { field_Name: "taxPaid", subColumns: 'Tax Paid ($)' },
      { field_Name: "rate", subColumns: 'Rate ($)' },
      { field_Name: "taxLess", subColumns: 'Tax Less ($)' },
      { field_Name: "ASPTaxPaid", subColumns: 'ASP TaxPaid ($)' },
      { field_Name: "ASPTaxLess", subColumns: 'ASP TaxLess ($)' },
      {
        field_Name: "totalAmount", subColumns: (
          <div>
            Total Amount
            <div style={{ color: '#fd7e14' }}>{parseFloat(totalAmountTotal.toFixed(1))}</div>
          </div>
        )
      },
      {
        field_Name: "GP", subColumns: (
          <div>
            GP
            <div style={{ color: '#fd7e14' }}>{parseFloat(GPTotal.toFixed(1))}</div>
          </div>
        )
      },
      {
        field_Name: "GPPercentage", subColumns: (
          <div>
            GP %
            <div style={{ color: '#fd7e14' }}>{parseFloat(GPPercentageTotal.toFixed(2))}</div>
          </div>
        )
      },
      { field_Name: "contribution", subColumns: 'Cont %' },
    ])
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    const filteredSalesData = allData?.filter(item => item.type.toLowerCase() === "sales")
    const capsItemNameData = filteredSalesData.map(item => ({
      ...item,
      itemName: capitalizeFirstLetter(item.itemName)
    }));
    const filteredByDateRange = capsItemNameData.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= dateRange.startDate && itemDate <= dateRange.endDate;
    });
    const salesDataWithContributionAndGP = calculateContributionAndGP(filteredByDateRange);
    setSalesData(salesDataWithContributionAndGP);
    // f_setSalesData(salesDataWithContributionAndGP)
    calculateTotals(salesDataWithContributionAndGP);
    console.log("here sales data", salesDataWithContributionAndGP)
  }, [allData, dateRange])
  useEffect(() => {
    aggregateDataForAll()
  }, [salesData])

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(salesData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "salesData.xlsx");
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    let data = salesData.filter(item =>
      item.itemName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    f_setSalesData(data)
  };

  return (
    <div className="d-flex justify-content-between align-items-top w-100 gap-1" style={{ height: "100%" }}>
      <div className="w-30 " style={{ minHeight: "100%" }}>
        <GraphCard
          itemsData={salesData}
          stateData={stateData}
          continentData={continentData}
          customerGroupData={customerGroupData}
          itemGroupData={itemGroupData}
          itemCategoryData={itemCategoryData}
          defaultData={"State"}
          defaultGraph={"Pie Chart"}

        />
      </div>
      <div className="w-70 h-100">
        {/* <NewTablev2 columns={columns}>
          {salesData.map((row, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column.field_Name === "date" ? new Date(row[column.field_Name]).toLocaleDateString() : row[column.field_Name]}
                </td>
              ))}
            </tr>
          ))}
        </NewTablev2> */}
        <div className="position-relative w-100 d-flex justify-content-between align-items-center position-relative" style={{ height: "50px" }}>

          <input
            type="text"
            style={{
              width: "300px",
              height: "40px"
            }}
            placeholder="Search by item name"
            value={searchQuery}
            onChange={handleSearchChange}
            className="form-control me-2"
          />
          <div className="w-50 d-flex justify-content-right align-items-center">

            <div className="position-relative">
              <label htmlFor="during" className="form-label me-2">
                Duration
              </label>
              <input
                className='rounded p-1 border during-input fs-6 ps-2'
                style={{
                  width: "250px",
                  height: "40px"
                }}
                type="text"
                readOnly
                value={formattedDateRange}
                onClick={() => setShowPicker((prev) => ({ ...prev, during: !prev.during }))}
                placeholder='DD-MM-YYYY'
              />
              {showPicker.during && (
                <div
                  className='position-absolute'
                  style={{ width: "350px", zIndex: 1, right: "90%", display: 'flex', flexDirection: 'row' }}
                  ref={pickerRef}
                >
                  <DateRangePicker
                    className='border rounded shadow fs-6'
                    ranges={[dateRange]}
                    onChange={handleSelect}
                    moveRangeOnFirstSelection={false}
                  />
                </div>
              )}
            </div>

            {/* <div className="position-absolute" style={{ top: 0, right: 0 }}>
              <DateRangePicker
                style={{ width: "300px" }}
                className=' border rounded shadow fs-6'
                ranges={[dateRange]}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
              />
            </div> */}

            <button onClick={handleDownload} className="bg-white rounded ms-3" style={{ border: "0.5px solid grey", width: "40px", height: "40px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>
          </div>
        </div>
        <div className="custom-scrollbar h-100" >
          <TableWithPages data={salesData} columns={columns} noOfRowsPerPage={13} />

        </div>
      </div>

    </div >
  )
}