import moment from "moment"
import { extractFloatFromText } from "../myFunctions"
import toastDisplay from "../toastNotification"
import call from "../../service"

export async function mapGptRespData(contractState, finalOCRApiResp, traderType, dbSupplier, userId, setdataTnc, settncOrder, toggleTraderOnboardForm, setSpecification, setspecOrder) {
  let tempData = {}
  let isContractCreation = contractState === "create"
  try {
    if (finalOCRApiResp?.contractName) {
      tempData["contractAlias"] = finalOCRApiResp?.contractName
    }
    if (isContractCreation) {
      if (traderType === "buyer") {
        if (finalOCRApiResp?.supplierName) {
          let selectedSeller = dbSupplier.filter(i => {
            if (i.company_name?.toLowerCase() === finalOCRApiResp.supplierName?.toLowerCase()) { return true }
          })?.[0]
          if (selectedSeller?.company_name) {
            tempData["supplierName"] = selectedSeller.company_name
            tempData["supplierAddress"] = selectedSeller.country_code
            tempData["supplierEmail"] = selectedSeller.user_name
            tempData["exporter_id"] = selectedSeller.id || 0
            tempData["importer_id"] = userId
          }
        }
      }
      else {
        if (finalOCRApiResp?.buyerName) {
          let selectedBuyer = dbSupplier.filter(i => {
            if (i.company_name?.toLowerCase() === finalOCRApiResp.buyerName?.toLowerCase()) { return true }
          })?.[0]
          if (selectedBuyer?.company_name) {
            tempData["buyerName"] = selectedBuyer.company_name
            tempData["buyerAddress"] = selectedBuyer.country_code
            tempData["buyerEmail"] = selectedBuyer.user_name
            tempData["importer_id"] = selectedBuyer.id || 0
            tempData["exporter_id"] = userId
          }
        }
      }
      if (!tempData?.importer_id && !tempData?.exporter_id) {
        if (traderType === "buyer" && finalOCRApiResp?.supplierName) {
          tempData["traderOnboardCompanyName"] = finalOCRApiResp?.supplierName
          tempData["traderOnboardEmailId"] = finalOCRApiResp?.supplierEmail
          toggleTraderOnboardForm({ show: true })
        }
        else if (finalOCRApiResp?.buyerName) {
          tempData["traderOnboardCompanyName"] = finalOCRApiResp?.buyerName
          tempData["traderOnboardEmailId"] = finalOCRApiResp?.buyerEmail
          toggleTraderOnboardForm({ show: true })
        }
      }
      if (finalOCRApiResp?.incoTerm) {
        tempData["contractType"] = finalOCRApiResp?.incoTerm
      }
      let commCatAddApiResp = await call('POST', 'addnewcategory', { categoryName: finalOCRApiResp.commodityCategory || "UNKNOWN" })
      if (commCatAddApiResp?.message?.id) {
        tempData["commCatId0"] = commCatAddApiResp?.message?.id
        if (finalOCRApiResp?.commodityName) {
          let spec = {}
          let specOrder = []
          for (let index = 0; index < finalOCRApiResp["commoditySpecification"].length; index++) {
            const element = finalOCRApiResp["commoditySpecification"][index];
            spec[element.name.toLowerCase().replace(/\s/g, '')] = element
            specOrder.push(element.name.toLowerCase().replace(/\s/g, ''))
          }
          let commSaveMasterApiResp = await call('POST', 'saveCommodityMaster', {
            commodityName: finalOCRApiResp?.commodityName.toLowerCase().replace(/\s/g, ''), commodityPrettyName: finalOCRApiResp?.commodityName,
            commdCategory: tempData["commCatId0"], spec: JSON.stringify(spec), specOrder: specOrder.join(":")
          })
          if (commSaveMasterApiResp?.message?.id) {
            let setCommodityResp = await call("POST", 'setCommodityuser', {
              owner_id: userId, commodityName: finalOCRApiResp?.commodityName.toLowerCase().replace(/\s/g, ''),
              commodityPrettyName: finalOCRApiResp?.commodityName, spec: JSON.stringify(spec),
              specOrder: specOrder.join(":"), selectedComm: commSaveMasterApiResp?.message?.id,
              commUnit: finalOCRApiResp?.quantityUnit
            })
            tempData["commodity0"] = setCommodityResp?.message?.id
            tempData['commodity_pretty_name0'] = finalOCRApiResp?.commodityName
            setSpecification([setCommodityResp?.message?.specifications || {}])
            if (setCommodityResp?.message?.spec_order_string) {
              setspecOrder([setCommodityResp?.message?.spec_order_string?.split(":")])
            }
          }
        }
      }
    }
    if (!isContractCreation) {
      let spec = [{}]
      let specOrder = [[]]
      for (let index = 0; index < finalOCRApiResp["commoditySpecification"]?.length; index++) {
        const element = finalOCRApiResp["commoditySpecification"][index];
        spec[0][element.name.toLowerCase().replace(/\s/g, '')] = element
        specOrder[0].push(element.name.toLowerCase().replace(/\s/g, ''))
      }
      setSpecification([...spec])
      setspecOrder([...specOrder])
    }
    if (finalOCRApiResp?.laycanStartDate) {
      tempData["laycanStart"] = finalOCRApiResp.laycanStartDate
    }
    if (finalOCRApiResp?.laycanEndDate) {
      tempData["laycanEnd"] = finalOCRApiResp.laycanEndDate
    }
    if (finalOCRApiResp?.loadingCountry) {
      tempData["loadingCountry"] = finalOCRApiResp?.loadingCountry
    }
    if (finalOCRApiResp?.unloadingCountry) {
      tempData["unloadingCountry"] = finalOCRApiResp?.unloadingCountry
    }
    // if (element?.loadingPort && finalOCRApiResp["loadingCountry"]) {
    //   finalOCRApiResp["loadingPort"] = element?.loadingPort
    // }
    // if (element?.unloadingPort && finalOCRApiResp["unloadingCountry"]) {
    //   finalOCRApiResp["unloadingPort"] = element?.unloadingPort
    // }
    if (finalOCRApiResp?.price) {
      tempData["price0"] = finalOCRApiResp?.price
    }
    if (finalOCRApiResp?.currency) {
      tempData["currency"] = finalOCRApiResp?.currency
    }
    if (finalOCRApiResp.quantity) {
      tempData["quantity0"] = finalOCRApiResp.quantity
    }
    let tempTncOrder = []
    let tempDataTnc = {}
    for (let index = 0; index < finalOCRApiResp?.termsAndConditions.length; index++) {
      const element = finalOCRApiResp?.termsAndConditions[index];
      tempTncOrder.push(element.name.toLowerCase().replace(/\s/g, ''))
      tempDataTnc[element.name.toLowerCase().replace(/\s/g, '')] = element
    }
    setdataTnc(tempDataTnc)
    settncOrder(tempTncOrder)
  } catch (error) {
    console.log("errorInmapGptRespData", error);
  }
  return tempData
}

function getRejectionSymbol(text) {
  try {
    if (text.toLowerCase().includes("below")) {
      return "<"
    }
    if (text.toLowerCase().includes("above")) {
      return ">"
    }
    if (text.toLowerCase().includes("range")) {
      return "-"
    }
    return "!"
  } catch (error) {
    return "!"
  }
}

export function gptContractOCRValidation(element, finalOCRApiResp, incoTermsData, countryData, currencyData) {
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (element?.contractName) {
    finalOCRApiResp["contractName"] = element?.contractName
  }
  if (element?.supplierName) {
    finalOCRApiResp["supplierName"] = element?.supplierName
  }
  if (element?.supplierEmail && emailRegex.test(element?.supplierEmail)) {
    finalOCRApiResp["supplierEmail"] = element?.supplierEmail
  }
  if (element?.buyerName) {
    finalOCRApiResp["buyerName"] = element?.buyerName
  }
  if (element?.buyerEmail && emailRegex.test(element?.buyerEmail)) {
    finalOCRApiResp["buyerEmail"] = element?.buyerEmail
  }
  if (element?.incoTerms?.length == 3) {
    let incoTerm = element?.incoTerms.toUpperCase()
    incoTerm = incoTermsData.filter(i => {
      if (i === incoTerm) {
        return true
      }
    })?.[0]
    finalOCRApiResp["incoTerm"] = incoTerm
  }
  if (element?.commodityCategory) {
    finalOCRApiResp["commodityCategory"] = element.commodityCategory
  }
  if (element?.commodityName) {
    finalOCRApiResp["commodityName"] = element.commodityName
  }
  if (element?.commoditySpecification?.length) {
    finalOCRApiResp["commoditySpecification"] = finalOCRApiResp["commoditySpecification"]?.length ? finalOCRApiResp["commoditySpecification"] : []
    for (let index = 0; index < element?.commoditySpecification.length; index++) {
      const element2 = element?.commoditySpecification[index];
      if (element2?.specName && (element2?.typicalValue || element2?.rejectionValue)) {
        finalOCRApiResp["commoditySpecification"].push({
          "name": element2?.specName,
          "value": element2?.typicalValue,
          "rejectionValue": element2?.rejectionValue,
          "rejectionSymbol": getRejectionSymbol(element2?.rejectionValue)
        })
      }
    }
  }
  if (element?.laycanStartDate && moment(element.laycanStartDate, "YYYY-MM-DD").isValid()) {
    finalOCRApiResp["laycanStartDate"] = element.laycanStartDate
  }
  if (element?.laycanEndDate && moment(element.laycanEndDate, "YYYY-MM-DD").isValid()) {
    finalOCRApiResp["laycanEndDate"] = element.laycanEndDate
  }
  if (element?.loadingCountry?.length == 2) {
    let loadingCountry = countryData?.filter((i) => {
      if (i?.sortname === element.loadingCountry) { return true }
    })?.[0]?.id
    if (loadingCountry) {
      finalOCRApiResp["loadingCountry"] = loadingCountry
    }
  }
  if (element?.unloadingCountry?.length == 2) {
    let unloadingCountry = countryData?.filter((i) => {
      if (i?.sortname === element.unloadingCountry) { return true }
    })?.[0]?.id
    if (unloadingCountry) {
      finalOCRApiResp["unloadingCountry"] = unloadingCountry
    }
  }
  if (element?.loadingPort && finalOCRApiResp["loadingCountry"]) {
    finalOCRApiResp["loadingPort"] = element?.loadingPort
  }
  if (element?.unloadingPort && finalOCRApiResp["unloadingCountry"]) {
    finalOCRApiResp["unloadingPort"] = element?.unloadingPort
  }
  if (element?.price && extractFloatFromText(element.price)) {
    finalOCRApiResp["price"] = element?.price
  }
  if (element?.currency?.length == 3) {
    let currency = currencyData.filter(i => {
      if (i.code === element?.currency) { return true }
    })?.[0]?.code
    finalOCRApiResp["currency"] = currency
  }
  if (element?.quantity && extractFloatFromText(element.quantity)) {
    finalOCRApiResp["quantity"] = element?.quantity
  }
  if (element?.quantityUnit) {
    finalOCRApiResp["quantityUnit"] = element?.quantityUnit
  }
  if (element?.termsAndConditions?.length) {
    finalOCRApiResp["termsAndConditions"] = finalOCRApiResp["termsAndConditions"]?.length ? finalOCRApiResp["termsAndConditions"] : []
    for (let index = 0; index < element?.termsAndConditions.length; index++) {
      const element2 = element?.termsAndConditions[index];
      if (element2?.title) {
        finalOCRApiResp["termsAndConditions"].push({
          "name": element2.title,
          "value": element2.description ? element2.description.replace(/\n/g, '<br>') : ""
        })
      }
    }
  }
}

export default function validate(values, spec, tnc, activeclass, multComm, templateType) {
  console.log("values in validate-->", values, spec)
  let isCompactContract = templateType === "Compact Contract"
  //console.log("spec in validate-->", spec)
  //console.log("tnc in validate-->", tnc)
  //console.log("activeclass in validate-->", activeclass)
  let errors = {};
  if (activeclass === 1) {
    if (!isCompactContract) {
      if (!values.contractAlias) {
        errors.contractAlias = 'Contract Name is Mandatory';
      }
      if (!values.contractType) {
        errors.contractType = 'Contract Type is Mandatory';
      }
    }
    if (!values.supplierName) {
      errors.supplierName = 'Select Second Party';
    }
    if (!values.buyerName) {
      errors.buyerName = 'Select Second Party';
    }
    if (isCompactContract) {
      if (!values.currency) {
        errors.currency = 'Currency is Required';
      }
      if (!values.price0) {
        errors.price0 = 'Amount is Required';
      }
      return errors
    }
    let atleast1CommPresent = false
    for (let index = 0; index < multComm.length; index++) {
      let element = multComm[index];
      if (element === null) {
        if (values[`commCatId${index}`] && values[`commodity${index}`]) {
          atleast1CommPresent = true
          break
        }
        if (!values[`commCatId${index}`]) {
          errors[`commCatId${index}`] = 'Select a Commodity Type/Category'
        }
        if (!values[`commodity${index}`]) {
          errors[`commodity${index}`] = 'Select a Commodity Name'
        }
      }
    }

    // if (!values.commodity) {
    //   errors.commodity = 'Select a Commodity Name';
    // }
    // if (!values.commCatId) {
    //   errors.commCatId = 'Select a Commodity Type/Category';
    // }
    // if (!values.laycanStart) {
    //   errors.laycanStart = 'Laycan Start is Required';
    // }
    // if (!values.laycanEnd) {
    //   errors.laycanEnd = 'Laycan End is Required';
    // }
    // if (!values.loadingCountry) {
    //   errors.loadingCountry = 'Loading Country is Required';
    // }
    // if (!values.loadingPort) {
    //   errors.loadingPort = 'Loading Port is Required';
    // }
    // if (!values.unloadingCountry) {
    //   errors.unloadingCountry = 'Unloading Country is Required';
    // }
    // if (!values.unloadingPort) {
    //   errors.unloadingPort = 'Unloading Port is Required';
    // }
  } else if (activeclass === 2) {
    for (let index = 0; index < multComm.length; index++) {
      const element = multComm[index];
      if (element === null) {
        let keysArray = Object.keys(spec?.[index] || {})
        if (keysArray.length) {
          for (let i = 0; i < keysArray.length; i++) {
            if (!spec[index]?.[keysArray[i]].value || !spec[index]?.[keysArray[i]].rejectionValue || !spec[index]?.[keysArray[i]].rejectionSymbol) {
              errors[`Specification${index}`] = 'Make Sure all the Values, Rejection values and Rejection At are filled';
              break;
            }
          }
        } else {
          errors[`Specification${index}`] = 'Specification is Required';
        }
      }
    }
  } else if (activeclass === 3) {
    for (let index = 0; index < multComm.length; index++) {
      const element = multComm[index];
      if (element === null) {
        if (!values[`priceType${index}`] / 1) {
          errors[`priceType${index}`] = 'Price Type is Required';
        }
        if (!(values[`price${index}`] / 1)) {
          errors[`price${index}`] = 'Price is Required';
        }
        if (values[`quantity${index}`] / 1 <= 0) {
          errors[`quantity${index}`] = 'Quantity is Required';
        }
      }
    }
    if (!values.currency) {
      errors.currency = 'Currency is Required';
    }
  }
  // else if (activeclass === 4) {
  //   // if (!values.investigationAgencyImporter || values.investigationAgencyImporter === "") {
  //   //   errors.investigationAgencyImporter = 'investigationAgencyImporter is Required';
  //   // }
  //   // if (!values.investigationAgencyExporter || values.investigationAgencyExporter === "") {
  //   //   errors.investigationAgencyExporter = 'investigationAgencyExporter is Required';
  //   // }
  //   // if (!values.investigationAgencythird || values.investigationAgencythird === "") {
  //   //   errors.investigationAgencythird = 'investigationAgencythird is Required';
  //   // }

  // } else if (activeclass === 5) {
  //   // if (!values.commodityInsurer || values.commodityInsurer === "") {
  //   //   errors.commodityInsurer = 'commodityInsurer is Required';
  //   // }
  //   // if (!values.tradeInsurer || values.tradeInsurer === "") {
  //   //   errors.tradeInsurer = 'tradeInsurer is Required';
  //   // }
  // } 
  else if (activeclass === 4) {
    if (!values.paymentMode) {
      errors.paymentMode = 'Payment Mode is a Mandatory Field';
    }
    // if (!values.buyerBank) {
    //   errors.buyerBank = 'Bank is a Mandatory Field';
    // }
    // if (!values.supplierBank) {
    //   errors.supplierBank = 'Bank is a Mandatory Field';
    // }
    //bank validate rules
  } else if (activeclass === 5) {
    let keysArray = Object.keys(tnc)

    if (keysArray.length) {
      for (let i = 0; i < keysArray.length; i++) {
        if (!tnc[keysArray[i]].value) {
          errors.tnc = 'Make Sure all the Values are filled, Check TNC key named - ' + tnc[keysArray[i]].name;
          break;
        }
      }
    } else {
      errors.tnc = 'TNC is Required';
    }
  }

  console.log("errorssss", errors)
  return errors;
  // return {}
}
