import React from "react";
import { useState } from "react";
import WorldMap from "./WorldMap";

const ShipmentMap = ({ data }) => {

  const [list, setList] = useState(false);
  return (
    <>
      {!list && <div className="card p-0 dashboard-card border-0 borderRadius mapboxshadow">
        <div className="card-header bg-transparent d-flex p-3 justify-content-between">
          <div>
            <p className="mb-0 mapcardheader">Countries covered - <span className="text-color1 font-wt-600">{data.length ? data.length : ''}</span></p>
          </div>
          <div className="d-flex gap6rem">
            <div className="d-flex gap-3 align-items-center">
              <p className="text-color-0C0C0C letter-spacing05  font-wt-300 font-size-12 mb-0" ><span className="bgFFA502 Financelimitapplied me-2"></span>Top 5 countries</p>
              <p className="text-color-0C0C0C letter-spacing05  font-wt-300 font-size-12 mb-0" ><span className="bg00D2D3 Financelimitapplied me-2"></span> Others</p>
            </div>
            <div className="d-flex me-5 align-items-center">
              <a onClick={() => setList(true)} className="text-color-0C0C0C letter-spacing05  font-wt-300 font-size-12 mb-0 cursor " ><img className='me-2' src={"assets/images/List-icon.png"} />List</a>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#E0EEFE' }}>
          <WorldMap data={data} />
        </div>
      </div>}
      {list && <div className="card p-0 dashboard-card border-0 borderRadius mapboxshadow">
        <div className="card-header bg-transparent d-flex p-3 justify-content-between">
          <div>
            <p className="mb-0 mapcardheader">Countries covered - <span className="text-color1 font-wt-600">{data.length ? data.length : ''}</span></p>
          </div>
          <div onClick={() => setList(false)} className="d-flex me-5 align-items-center">
            <p className="text-color-0C0C0C letter-spacing05  font-wt-300 font-size-12 mb-0 cursor " ><img className='me-2' src={"assets/images/ep_map-location.png"} />Map</p>
          </div>
        </div>
        <div className="card-body mapcardbody mx-5">
          <div className="d-flex">
            <div className="col-md-3">
              <p className="top5countries colorFFA502"> <img className='me-2' src={"assets/images/Top-5-countries.png"} /> Top 5 countries</p>
              <ol style={{ listStyleType: "number" }}>
                {data.slice(0, 5).map(item => {
                  return <li className="maplist">{item.DESTINATION_COUNTRY + " - "}<span className="font-wt-600">{item.country_count}</span></li>
                })}
              </ol>
            </div>
            <div className="col">
              <p className="top5countries color00D2D3"> <img className='me-2' src={"assets/images/other-countries.png"} /> Others</p>
              <ol className="otherslist" style={{ listStyleType: "number" }}>
                {data.slice(4, data.length - 1).map(item => {
                  return <li className="maplist">{item.DESTINATION_COUNTRY}</li>
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
export default ShipmentMap;