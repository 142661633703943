import React, { useState } from "react";
import NotificationOtherCard from "./notificationOtherCard";
import { AllnotificationsToday } from "../JsonData";

const NotificationsOthers = ({ Heading, tab, setTab, data, getNotificationdata }) => {


  return (
    <>
      <p className="font-size-16 font-wt-500 letter-spacing05 color0C0C0C lh-22">{Heading}</p>
      <div className="card notification-card borderRadius border-0">
        <nav className="navheader">
          <div className="nav nav-tabs faqs-tab border-0 " id="faqs-tab" role="tablist">
            <button className={`nav-link fablink borderright rounded-0 bg-transparent ${tab === "Wallet" && 'active'}`} id="nav-Wallet-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Wallet" aria-selected="true" onClick={() => setTab("Wallet")}>Wallet</button>
            <button className={`nav-link d-flex gap-2 align-items-center fablink borderright rounded-0 bg-transparent ${tab === "Profile" && 'active'}`} id="nav-Profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Profile" aria-selected="false" onClick={() => setTab("Profile")}>
              <span>Profile</span>
              <img className="" src={"assets/images/red-dot.png"} alt="" />
            </button>
            {/* <button className={`nav-link fablink borderright rounded-0 bg-transparent ${tab === "TCI" && 'active'}`} id="nav-TCI-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-TCI" aria-selected="false" onClick={() => setTab("TCI")}>TCI</button> */}
            <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "Buyer management" && 'active'}`} id="nav-Buyermanagement-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Buyermanagement" aria-selected="false" onClick={() => setTab("Buyer management")}>Buyer management</button>
            <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "Reports" && 'active'}`} id="nav-Reports-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Reports" aria-selected="false" onClick={() => setTab("Reports")}>Reports</button>
            <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "Chat room" && 'active'}`} id="nav-Chatroom-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Chatroom" aria-selected="false" onClick={() => setTab("Chat room")}>Chat room</button>
            {/* <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "News & Events" && 'active'}`} id="nav-NewsEvents-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-NewsEvents" aria-selected="false" onClick={() => setTab("News & Events")}>News & Events</button> */}
            {/* <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "Settings" && 'active'}`} id="nav-Settings-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Settings" aria-selected="false" onClick={() => setTab("Settings")}>Settings</button> */}
          </div>
        </nav>

        <div className="pb-2 tab-content d-block" id="nav-tabContent">
          {tab === "Wallet" && <div className="tab-pane fade show active bg-transparent " id="nav-Wallet" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Profile" && <div className="tab-pane fade show active bg-transparent " id="nav-Profile" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "TCI" && <div className="tab-pane fade show active bg-transparent " id="nav-TCI" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Buyer management" && <div className="tab-pane fade show active bg-transparent " id="nav-Buyermanagement" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Reports" && <div className="tab-pane fade show active bg-transparent " id="nav-Reports" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Chat room" && <div className="tab-pane fade show active bg-transparent " id="nav-Chatroom" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "News & Events" && <div className="tab-pane fade show active bg-transparent " id="nav-NewsEvents" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Settings" && <div className="tab-pane fade show active bg-transparent " id="nav-Settings" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
        </div>
      </div>
    </>
  );
}
export default NotificationsOthers;