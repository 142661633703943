import React from "react";

const CoinsCard = ({ data }) => {

  return (
    <>
      <div className='card Month borderRadius p-3' style={{
        width: "240px",
        height: "130px"
      }}>
        <p className='d-flex justify-content-between'><span className="font-size-14 letter-spacing06 color0C0C0C font-wt-400 lineheight19">Coins Available</span>
        </p>
        <div className='d-flex align-items-center gap-2'>
          <img src={'/assets/images/Lc/coins.png'} alt='' />
          <h4 className='mb-0 text-color1 font-size-30 font-wt-600'>{data.coins || 0}</h4>
        </div>
      </div>
    </>
  );
}
export default CoinsCard;