export let getEmailPaymentReminderBody = ({ counterpartycompanyname, contractno, sendercompanyname, contractname,
  dueamounts, senderphonecode, sendercontactno
}) => {
  return (`Dear ${counterpartycompanyname},\n
I hope you are doing well.\n
We are writing to remind you that payment for Contract/Invoice No. ${contractno} is due. Please find the details below:\n
Company Name: ${sendercompanyname}
Contract Name: ${contractname}
Contract/Invoice Number: ${contractno}
Amount Due:
${dueamounts}
Please make the payment at the earliest.
Should you have any questions or need further assistance, please do not hesitate to contact us at +${senderphonecode} ${sendercontactno}.
Thank you for your cooperation.\n
Best Wishes,
${sendercompanyname}`)
}