import React, { useEffect, useState, useRef } from "react";
import NewTablev2 from "../../../../utils/newTablev2";
import { DateRangePicker } from "react-date-range";
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import PaginatedTable from "../pagesTable";
import TableWithPages from "../tableWithArrows";
import * as XLSX from 'xlsx';

export default function DayView({ allData }) {
  const [activeButton, setActiveButton] = useState('sales');
  const [salesData, setSalesData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [showPicker, setShowPicker] = useState({ during: false, dropOff: false });
  const [dateRange, setDateRange] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });
  const pickerRef = useRef(null);

  useEffect(() => {
    if (allData.length) {
      const filteredSales = allData.filter(item => item.type === "Sales");
      const filteredPurchase = allData.filter(item => item.type === "Purchase");
      setSalesData(filteredSales);
      setPurchaseData(filteredPurchase);
      setDataToShow(filteredSales.filter(item =>
        isWithinInterval(new Date(item.date), { start: dateRange.startDate, end: dateRange.endDate })
      ));
    }
  }, [allData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker({ during: false, dropOff: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    const dataToFilter = buttonType === "sales" ? salesData : purchaseData;
    setDataToShow(dataToFilter.filter(item =>
      isWithinInterval(new Date(item.date), { start: dateRange.startDate, end: dateRange.endDate })
    ));
  };

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    const dataToFilter = activeButton === "sales" ? salesData : purchaseData;
    setDataToShow(dataToFilter.filter(item =>
      isWithinInterval(new Date(item.date), { start: ranges.selection.startDate, end: ranges.selection.endDate })
    ));
  };

  const columns = [
    { field_Name: "date", subColumns: "Date", subColumnsOnClick: null, thwidth: "200px" },
    { field_Name: "invoiceNumber", subColumns: "Invoice Number", subColumnsOnClick: null, subColumnStyle: { width: "200px" } },
    { field_Name: "itemName", subColumns: "Item Name", subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "partyName", subColumns: "Customer Name", subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "itemGodown", subColumns: "Godown", subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "country", subColumns: "Country", subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "state", subColumns: "State", subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "QtyWithUnits", subColumns: 'Quantity', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "taxPaid", subColumns: 'Tax Paid ($)', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "rate", subColumns: 'Rate ($)', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "taxLess", subColumns: 'Tax Less ($)', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "ASPTaxPaid", subColumns: 'ASP TaxPaid ($)', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "ASPTaxLess", subColumns: 'ASP TaxLess ($)', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
    { field_Name: "totalAmount", subColumns: 'Total Amount ($)', subColumnsOnClick: null, subColumnStyle: { minWidth: "150px" } },
  ];

  const formattedDateRange = dateRange.startDate && dateRange.endDate
    ? `${format(dateRange.startDate, 'MM/dd/yyyy')} - ${format(dateRange.endDate, 'MM/dd/yyyy')}`
    : '';
  const handleDownload = () => {
    const tableData = dataToShow.map(row => {
      let rowData = {};
      columns.forEach(col => {
        rowData[col.subColumns] = row[col.field_Name];
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "filteredData.xlsx");
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center position-relative w-100">
        <div>
          <button style={{ width: "100px" }}
            className={`btn ${activeButton === 'sales' ? 'btn-dark' : 'btn-light'} me-1`}
            onClick={() => handleButtonClick('sales')}
          >
            Sales
          </button>
          <button style={{ width: "100px" }}
            className={`btn ${activeButton === 'purchase' ? 'btn-dark' : 'btn-light'}`}
            onClick={() => handleButtonClick('purchase')}
          >
            Purchase
          </button>
        </div>
        <div className="position-relative">
          <label htmlFor="during" className="form-label d-block">
            During
          </label>
          <input
            className='rounded p-1 border during-input fs-6 ps-2'
            width={"300px"}
            type="text"
            readOnly
            value={formattedDateRange}
            onClick={() => setShowPicker((prev) => ({ ...prev, during: !prev.during }))}
            placeholder='DD-MM-YYYY'
          />
          {showPicker.during && (
            <div
              className='position-absolute mt-2'
              style={{ width: "350px", zIndex: 1, right: "90%" }}
              ref={pickerRef}
            >
              <DateRangePicker
                className='border rounded shadow fs-6'
                ranges={[dateRange]}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
              />
            </div>
          )}
        </div>
      </div>


      <div className="mt-2">
        {dataToShow.length ?
          // <NewTablev2 className="overflow-auto" columns={columns}>
          //   {dataToShow.map((row, index) => (
          //     <tr key={index}>
          //       {columns.map((column, colIndex) => (
          //         <td key={colIndex}>
          //           {column.field_Name === "date" ? new Date(row[column.field_Name]).toLocaleDateString() : row[column.field_Name]}
          //         </td>
          //       ))}
          //     </tr>
          //   ))}
          // </NewTablev2>
          <>

            <div style={{ height: "50px" }} className=" d-block position-relative w-100">

              <button onClick={handleDownload} className="bg-white rounded position-absolute m-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>
            </div>
            <TableWithPages data={dataToShow} columns={columns} noOfRowsPerPage={10} />
          </>
          : <h2 style={{ color: "rgb(255, 123, 109)" }} className="text-center m-5 rounded">--- No Entries ---</h2>}
      </div>
    </div>
  );
}
