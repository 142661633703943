import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import call from "../../../service";

function getLast6Months() {
  let currentDate = moment().add(1, "month")
  let i = 1
  let result = []
  while (i <= 6) {
    result.push({ from: moment(currentDate.clone().subtract(1, "month")).startOf("month"), to: moment(currentDate.clone()).endOf("month") })
    currentDate = currentDate.subtract(1, "month")
    i = i + 1
  }
  return result
}

const MonthCard = ({ setshowLoader, userId }) => {

  const [last6Monts, setLast6Monts] = useState(getLast6Months())
  const [selectedMonth, setSelectedMonth] = useState(moment())
  const [filter, setFilter] = useState({ from: moment(moment()).startOf("month"), to: moment(moment()).endOf("month") })
  const [dbData, setDbData] = useState({})

  useEffect(() => {
    setshowLoader(true)
    if (filter.from && filter.to) {
      call('POST', 'getWalletSummaryByMonth', { userId, from: filter.from.clone().format("YYYY-MM-DD"), to: filter.to.clone().format("YYYY-MM-DD") }).then((result) => {
        console.log("API result getWalletSummaryByMonth", result);
        setshowLoader(false)
        setDbData(result)
      }).catch((e) => {
        console.log("Error while getWalletSummaryByMonth", e);
        setshowLoader(false)
      });
    }
  }, [filter])

  return (
    <>
      <div className='card h-100 Month borderRadius p-4'>
        <div className="dropdown">
          <p className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedMonth.format("MMMM YYYY")}
          </p>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {last6Monts.length ? last6Monts.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    setSelectedMonth(item.from);
                    setFilter({ ...filter, from: moment(item.from).startOf("month"), to: moment(item.from).endOf("month") })
                  }}
                ><a className="dropdown-item" >{item.from.format("MMMM YYYY")}</a></li>
              )
            }) : null}
          </ul>
        </div>

        <div className='d-flex mt-3 justify-content-around '>
          <div>
            <p className='text-color1 font-size-18 font-wt-600 ms-4 ps-2 mb-0'>{dbData["received"] || 0}</p>
            <p className="mb-0"> <img src={'/assets/images/sidebarV2/coin.png'} alt='' height={30} width={30} /> <span className='font-size-13 text-secondary'> Received </span></p>
          </div>
          <div>
            <p className='text-color1 font-size-18 font-wt-600  ms-4 ps-2 mb-0'>{dbData["paid"] ? (dbData["paid"] * -1) : 0}</p>
            <p className="mb-0"> <img src={'/assets/images/sidebarV2/coin.png'} alt='' height={30} width={30} /> <span className='font-size-13 text-secondary'>Paid</span></p>
          </div>
        </div>
      </div>
    </>
  );
}
export default MonthCard;