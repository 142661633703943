import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function SingleSliderComponent({ label, value, setValue, max, min, steps }) {

  const handleChange1 = (event, newValue, activeThumb) => {
    // console.log("newvalueeeeeeeeeeeeeee", newValue);
    setValue(newValue)
  };

  return (
    <Box >
      <div className='d-flex flex-row justify-content-between'>
        <p className='font-size-13 font-wt-400'>{label}</p>
        <p className='font-size-20 font-wt-600 text-color1'>{value}</p>
      </div>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        defaultValue={value}
        onChange={handleChange1}
        valueLabelDisplay="auto"
        disableSwap
        min={min}
        max={max}
        step={steps || 1}
      />
      <div className='d-flex flex-row justify-content-between'>
        <p className='font-size-13 font-wt-400'>{min}</p>
        <p className='font-size-13 font-wt-400'>{max}</p>
      </div>
    </Box>
  );
}