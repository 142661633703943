import React, { useEffect, useState } from 'react';
// import { } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import ReactCountryFlag from "react-country-flag";
import call from '../../service';
import { ToastContainer } from 'react-toastify';
import toastDisplay from '../../utils/toastNotification';
import { formatDateTime_Application, formatDate_Application } from '../../utils/dateFormaters';
import toolTipText from '../../utils/toolTips/quotations.json'
import validate from '../../utils/validation_files/quotationValidationRules'
import customStyles from '../dataTablesColumsMap/customTableCss';
import columns from '../dataTablesColumsMap/quotationTableColumns';
import DataTable from 'react-data-table-component';
import Footer from '../partial/footer';

const Shipment = ({ userTokenDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [messageData, setmessageData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [contractData, setcontractData] = useState({});
  const [chat, setChat] = useState(false);
  const [quotationdata, setquotationdata] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [currencyData, setcurrencyData] = useState([]);
  const [data, setData] = useState({})
  const [completeButton, setcompleteButton] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [containerTypeData, setcontainerTypeData] = useState([]);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------

    setData({
      ...data,
      fromUserId: userId,
      user_type: userTypeId
    })

    //------------------------------------------------------------------
    //API Calls

    fetchTableData(1, { userId, userTypeId })

    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

  }, [refresh, filterData]);

  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errorData).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errorData]);

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [count, setCount] = useState({})
  const [searchKey, setsearchKey] = useState("")



  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "user_id": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'raiseQuotationContracts', objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.quotdata, inptObject.userId))
      setTotalRows(result.countdata.total)
      setCount(result.countdata.total)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "user_id": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'raiseQuotationContracts', objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.quotdata, userId))
      setTotalRows(result.countdata.total)
      setCount(result.countdata.total)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const filterStateButtons = (type) => {
    if (type === 1) {
      setFilterData({
        ...filterData,
        onlyProgress: true,
        onlyCompleted: false,
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyProgress: false,
        onlyCompleted: true,
      })
    }
  };

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setcompleteButton(0)
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  function formatDataFortable(dataArray, userId) {
    let resultArray = []
    // console.log("daata: ", dataArray)
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.contract_name = dataArray[i].contract_name;
      tempObj.contract_number = dataArray[i].contract_number ? dataArray[i].contract_number : '--';
      tempObj.charterer = dataArray[i].contract_type === 'FOB' ? dataArray[i].buyername : dataArray[i].suppliername;
      tempObj.commodity_name = dataArray[i].commodity_name ? dataArray[i].commodity_name : '--';
      tempObj.quantity = dataArray[i].quantity ? dataArray[i].quantity + ' mt' : '--';
      tempObj.loadingPort = (dataArray[i].load_country && dataArray[i].loading_port) ?
        (<>
          <span className="shadow">
            <ReactCountryFlag
              countryCode={dataArray[i].load_country ? dataArray[i].load_country.split(':')[0] : ''}
              style={{ width: '25px', height: '25px' }} svg />
          </span> {dataArray[i].loading_port}
        </>) :
        '--';
      tempObj.unloadingPort = (dataArray[i].unload_country && dataArray[i].unloading_port) ?
        (<>
          <span className="shadow">
            <ReactCountryFlag
              countryCode={dataArray[i].unload_country ? dataArray[i].unload_country.split(':')[0] : ''}
              style={{ width: '25px', height: '25px' }} svg />
          </span> {dataArray[i].unloading_port}
        </>) :
        '--';
      tempObj.status = (dataArray[i].quotationFillStatus !== null) ? dataArray[i].quotationFillStatus : -1;
      switch (tempObj.status / 1) {
        case 0:
          tempObj.quotStatus = "Pending";
          tempObj.myAction = "Quotation Sent";
          break;
        case 1:
          tempObj.quotStatus = "Accepted";
          tempObj.myAction = "Quotation Accepted";
          break;
        case 2:
          tempObj.quotStatus = "Rejected";
          tempObj.myAction = "Quotation Rejected";
          break;
        case 3:
          tempObj.quotStatus = "Closed";
          tempObj.myAction = "Quotation Closed";
          break;
        default:
          tempObj.quotStatus = "Not Sent";
          tempObj.myAction = "Quotation Not Sent";
      }
      tempObj.created_at = dataArray[i].created_at ? formatDate_Application(dataArray[i].created_at) : '';
      tempObj.action = (<>
        <span className="btn btn-info btn-sm mr-2 ml-2" onClick={() => {
          setupdateModal(true);
          setQuotationDetails(dataArray[i], userId, dataArray[i].contract_type === 'FOB' ? dataArray[i].importer_id : dataArray[i].exporter_id)
        }}>
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </span>
      </>)

      resultArray.push(tempObj)
    }
    return resultArray
  }

  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "user_id": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'raiseQuotationContracts', objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);

      let quotationList = result.quotdata;

      let csvString = "Contract No.,Contract Name,Charterer,Commodity,Quantity,Loading Port,Unloading Port,Quotation Status,My Action\r\n"

      for (let i = 0; i < quotationList.length; i++) {
        let rowString = `${quotationList[i].contract_number},
      ${quotationList[i].contract_name},
      ${quotationList[i].contract_type === 'FOB' ? quotationList[i].buyername : quotationList[i].suppliername},
      ${quotationList[i].commodity_name},
      ${quotationList[i].quantity + ' mt'},
      ${quotationList[i].loading_port ? quotationList[i].ship_loaloading_portd_port + "| " + quotationList[i].load_country.split(':')[0] : '--'},
      ${quotationList[i].unloading_port ? quotationList[i].unloading_port + "| " + quotationList[i].unload_country.split(':')[0] : "--"},
      ${checkquotstatus(quotationList[i].quotationFillStatus)}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', "Open_Quotations" + `_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })

  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
  }
  const handleChecked = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
  }

  const handleShipmentType = (event) => {
    event.persist();
    if (event.target.value === "container") {
      call('get', 'getcontainertypes').then((result) => {
        setcontainerTypeData(result)
      }).catch((e) => {
        console.log('error in getcurrencylist', e);
      })
    }
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function setQuotationDetails(ContractData, from, to) {
    setshowLoader(true)
    console.log("checking ContractData->", ContractData)
    // console.log("checking from->", from)
    // console.log("checking to->", to)
    setcontractData(ContractData);

    call('post', 'getquotationlist', { listType: "single", contractId: ContractData.contract_number, from: from, to: to }).then((result) => {
      console.log('result in setQuotationDetails==>', result);
      if (result.length) {
        let dataObj = result[0]
        setquotationdata(dataObj)
        setData(data => ({
          fromUserId: userId,
          user_type: userTypeId,
          quotStatus: dataObj.status,
          shipPrice: dataObj.bid_amount,
          shipCurrency: dataObj.currency,
          quantity: dataObj.quantity,
          quantityIndexType: dataObj.quantity_index_type,
          quantityIndex: dataObj.quantity_index,
          quantityLoadUnit: dataObj.mol_type,
          demmurageRate: dataObj.demurrage_rate,
          charterType: `${dataObj.charter_type}`,
          containerCount: dataObj.container_count,
          containerType: dataObj.container_type,
          shipmentType: dataObj.shipment_type,
          berthLoadingNumber: dataObj.berth_loading,
          anchorageLoadingNumber: dataObj.anchorage_loading,
          berthUnloadingNumber: dataObj.berth_unloading,
          anchorageUnloadingNumber: dataObj.anchorage_unloading,
          exporter_id: ContractData.exporter_id,
          importer_id: ContractData.importer_id,
          contract_type: ContractData.contract_type,
          contract_number: ContractData.contract_number,
          berthLoading: dataObj.berth_loading > 0 ? true : false,
          anchorageLoading: dataObj.anchorage_loading > 0 ? true : false,
          berthUnloading: dataObj.berth_unloading > 0 ? true : false,
          anchorageUnloading: dataObj.anchorage_unloading > 0 ? true : false,
          loadingRate: dataObj.loading_rate ? dataObj.loading_rate : 0,
          unloadingRate: dataObj.unloading_rate ? dataObj.unloading_rate : 0,
        }));

        handleShipmentType({ target: { value: dataObj.shipment_type }, persist: () => { } })

      } else {
        setData(data => ({
          quotStatus: 0,
          quantity: ContractData.quantity,
          exporter_id: ContractData.exporter_id,
          importer_id: ContractData.importer_id,
          contract_type: ContractData.contract_type,
          contract_number: ContractData.contract_number,
          fromUserId: userId,
          user_type: userTypeId,
          loadingRate: ContractData.quoat_data.quotLoadingRate ? ContractData.quoat_data.quotLoadingRate : 0,
          unloadingRate: ContractData.quoat_data.quotUnloadingRate ? ContractData.quoat_data.quotUnloadingRate : 0,
          charterType: '1'
        }));
      }
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in raiseQuotationContracts', e);
    })

    call('post', 'getcommunication', { contractId: ContractData.contract_number, user_id: userId, type: 3, userType: userTypeId }).then((result) => {
      // console.log('result in getcommunication', result);
      setmessageData(result)
    }).catch((e) => {
      console.log('error in getcommunication', e);
    })
  }

  function checkquotstatus(status) {
    switch (status / 1) {
      case 0:
        return "Pending,Quotation Sent";
      case 1:
        return "Accepted,Quotation Accepted";
      case 2:
        return "Rejected,Quotation Rejected";
      case 3:
        return "Closed,Quotation Closed";
      default:
        return "Not Sent,Quotation Not Sent";
    }
  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit Handler

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    seterrorData(validate(data));
    setIsSubmitting(true);
  };

  function handleSubmit() {
    setshowLoader(true)
    setIsSubmitting(false)

    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    call('POST', 'insertquotation', formData).then((result) => {
      // console.log('post request result:', result);
      if (result) {
        setshowLoader(false)
        setupdateModal(false)
        setrefresh(refresh + 1)
        // toastDisplay("Quotation Submited", "success");
        setData(data => ({ ...data, "quotMessage": "" }));
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Mini Preview Component
  function miniPreview() {
    return (
      // <div className="modal-padding">
      <ul className="price-ul">
        <li>
          <div className="price-left"> {contractData ? '#' + contractData.contract_number : "--"}</div>
          <div className="price-right"> Type/Contract No.</div>
        </li>
        <li>
          <div className="price-left">{contractData ? contractData.buyername : "--"}</div>
          <div className="price-right"> Importer</div>
        </li>
        <li>
          <div className="price-left">{contractData ? contractData.suppliername : "--"}</div>
          <div className="price-right"> Exporter</div>
        </li>
        <li>
          <div className="price-left">{contractData.commodity_name ? contractData.commodity_name : "--"}</div>
          <div className="price-right"> Commodity</div>
        </li>
        {(contractData.specification && contractData.specification.type && contractData.specification.type.value) && <li>
          <div className="price-left">{(contractData.specification && contractData.specification.type && contractData.specification.type.value) ? contractData.specification.type.value : "--"}</div>
          <div className="price-right"> Commodity Type</div>
        </li>}
        <li>
          <div className="price-left">{contractData ? contractData.quantity + ' mt' : "--"}</div>
          <div className="price-right"> Quantity</div>
        </li>
        <li>
          <div className="price-left">{contractData && contractData.load_country ?
            <span className="shadow">
              <ReactCountryFlag
                countryCode={contractData.load_country ? contractData.load_country.split(':')[0] : ''}
                style={{ width: '25px', height: '25px' }} svg />
            </span> : "--"}
            {" " + (contractData.load_country ? contractData.load_country.split(':')[0] : '')}
          </div>
          <div className="price-right"> Loading Country</div>
        </li>
        <li>
          <div className="price-left">{contractData.loading_port}</div>
          <div className="price-right"> Loading Port</div>
        </li>
        <li>
          <div className="price-left">{contractData.laycan_end ? formatDate_Application(contractData.laycan_end) : "--"}</div>
          <div className="price-right"> Laycan Start</div>
        </li>
        <li>
          <div className="price-left">{contractData.laycan_end ? formatDate_Application(contractData.laycan_end) : "--"}</div>
          <div className="price-right">Laycan End</div>
        </li>
        <li>
          <div className="price-left">{contractData && contractData.unload_country ?
            <span className="shadow">
              <ReactCountryFlag
                countryCode={contractData.unload_country ? contractData.unload_country.split(':')[0] : ''}
                style={{ width: '25px', height: '25px' }} svg />
            </span> : "--"}
            {" " + (contractData.unload_country ? contractData.unload_country.split(':')[0] : '')}
          </div>
          <div className="price-right"> Unloading Country</div>
        </li>
        <li>
          <div className="price-left"> {contractData.unloading_port}</div>
          <div className="price-right"> Unloading Port</div>
        </li>
        <li>
          <div className="price-left">{quotationdata.currency ? quotationdata.currency.split(':')[1] + quotationdata.amount : "--"}</div>
          <div className="price-right"> Price</div>
        </li>
        <li>
          <div className="price-left">{quotationdata.currency ? quotationdata.currency.split(':')[1] + quotationdata.bid_amount : "--"}</div>
          <div className="price-right">Biding Price</div>
        </li>
      </ul>
      // </div>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // Message Trail
  function gettrailList() {
    const trailList = messageData ? messageData.map((messageData, index) => {
      return (
        <>
          {(messageData.msg_from !== userId) &&
            <div className="incoming_msg">
              <div className="msg_img">
                <img className="rounded-circle" src={"https://ui-avatars.com/api/?name=" + messageData.from_company} alt="" />
              </div>
              <div className="msg_parent">
                <p>{messageData.message}</p>
                <span className="time_date"><i className="fa fa-user-o"></i> {messageData.from_company}</span>
                <span className="time_date"><i className="fa fa-clock-o  pl-5"></i> {formatDateTime_Application(messageData.created_at)}</span>
              </div>
            </div>
          }
          {(messageData.msg_from === userId) &&
            <div className="outgoing_msg">
              <div className="msg_parent">
                <p>{messageData.message}</p>
                <span className="time_date"><i className="fa fa-user-o"></i> {messageData.from_company}</span>
                <span className="time_date"><i className="fa fa-clock-o  pl-5"></i> {formatDateTime_Application(messageData.created_at)}</span>
              </div>
              <div className="msg_img">
                <img className="rounded-circle" src={"https://ui-avatars.com/api/?name=" + messageData.from_company} alt="" />
              </div>
            </div>
          }
        </>
      )
    }) : ''
    return trailList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  // console.log('contractData ==>', contractData);
  // console.log('quotationdata ==>', quotationdata);
  // console.log('data ==>', data);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar state={'quotation'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={'Quotation'}
              total={'Total Quotations ' + count}
              userTokenDetails={userTokenDetails} />
            <div className="d-md-flex mt-3 mb-3">
              <div className="filter-bg">
                <ul>
                  <li className={"pilot-cont cursor-pointer " + (completeButton === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setcompleteButton(1); filterStateButtons(1); }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" alt="" /></span> In-progress
            			</li>
                  <li className={"complete-cont cursor-pointer " + (completeButton === 2 ? "shadow" : '')} id="completeButton" onClick={() => { setcompleteButton(2); filterStateButtons(2); }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" alt="" /></span> Completed
                </li>
                </ul>
              </div>
              <div className="ml-md-auto relative">
                <ul className="range-slider">
                  <li>
                    <input name="search" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} id="searchInput" onKeyDown={handleKeyPress} />
                    <button type="button" onClick={() => {
                      setFilterData({
                        ...filterData,
                        "search": searchKey
                      })
                    }}><i class="fas fa-search fa-lg"></i></button>
                  </li>
                  <li>
                    <label>From</label>
                    <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
                  </li>
                  <li>
                    <label>To</label>
                    <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
                  </li>
                  <li><input type="button" value="Reset" className="btn btn-primary btn-sm" onClick={() => reset()} /></li>

                </ul>
              </div>
            </div>
            <div className="table-responsive">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                // expandableRows
                // expandableRowsComponent={<TableExpandedComponent />}
                expandOnRowClicked
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
            <hr />
            <div className="float-left col-md-7 ">
              <div className="col-md-12 row">
                <div className="col-md-3">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
              </button>
                </div>
              </div>
            </div>

            <div className={"modal" + (updateModal ? " show" : "")} id="updateModal">
              <div className="modal-dialog d-flex modal-xl border-inner" id="parent_class">
                <div className="modal-content">
                  <div className="modal-header primary">
                    <h4 className="modal-title text-white">Fill Quotation</h4>
                    <button type="button" className="close" onClick={() => { setupdateModal(false) }}>×</button>
                  </div>
                  <div className="modal-body calc-inner-modal">

                    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                    <div className="">
                      {miniPreview()}
                      <hr />
                      <h3 className="text-primary text-center" >Quantity & Pricing</h3>
                      <hr width="50%" />

                      {/* <div className="row d-flex"> */}
                      <div className="row form-group">
                        <label className="col-md-6">Quantity to be Shipped {astrix} {toolTip('quantity')}</label>
                        <div className="col-md-6">
                          <input type="number" name="quantity" value={data.quantity} className="form-control" disabled />
                        </div>
                        {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                      </div>

                      <div className="row form-group">
                        <label className="col-md-6">MOL Type {astrix}  {toolTip('quantityLoadUnit')}</label>
                        <div className="col-md-6">
                          <select className={"form-control" + (!errorData.quantityLoadUnit ? '' : ' border-danger')} name="quantityLoadUnit" value={data.quantityLoadUnit} onChange={handleChange}>
                            <option value="" selected>--Select MOL Type--</option>
                            <option value="MOLOO">MOLOO</option>
                            <option value="MOLCO">MOLCO</option>
                          </select>
                          {errorData.quantityLoadUnit && <p className="text-danger error-contract">{errorData.quantityLoadUnit}</p>}
                        </div>
                      </div>

                      <div className="row form-group">
                        <label className="col-md-6">Quantity vary index  {astrix} {toolTip('quantityIndexType')}</label>
                        <div className="col-md-3">
                          <select className={"form-control form-control-bottom" + (!errorData.quantityIndexType ? '' : ' border-danger')} name="quantityIndexType" value={data.quantityIndexType} onChange={handleChange}>
                            <option value="" selected>--Select Quantity vary index--</option>
                            <option value="Percentage">Percentage</option>
                            <option value="Min/Max">Min/Max</option>
                          </select>
                          {errorData.quantityIndexType && <p className="text-danger error-contract">{errorData.quantityIndexType}</p>}
                        </div>
                        {(data.quantityIndexType === "Percentage") && <div className="col-md-3">
                          <input type="number" name="quantityIndex" placeholder="Enter Percentage ±" value={data.quantityIndex} className={" form-control" + (!errorData.quantityIndex ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.quantityIndex && <p className="text-danger error-contract">{errorData.quantityIndex}</p>}
                        </div>}
                        {(data.quantityIndexType === "Min/Max") && <div className="col-md-3">
                          <input type="number" name="quantityIndex" placeholder="Enter Min/Max" value={data.quantityIndex} className={" form-control " + (!errorData.quantityIndex ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.quantityIndex && <p className="text-danger error-contract">{errorData.quantityIndex}</p>}
                        </div>}

                      </div>
                      {/* </div> */}

                      <div className="row form-group">
                        <label className="col-md-6">Freight (pmt)  {astrix} {toolTip('shipPrice')}</label>
                        <div className="col-md-3">
                          <input type="number" step="0.01" name="shipPrice" placeholder="Enter Price" value={data.shipPrice} className={"form-control-bottom form-control" + (!errorData.shipPrice ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.shipPrice && <p className="text-danger error-contract">{errorData.shipPrice}</p>}
                        </div>
                        <div className="col-md-3">
                          <select className={" form-control" + (!errorData.shipCurrency ? '' : ' border-danger')} name="shipCurrency" value={data.shipCurrency} onChange={handleChange}>
                            <option value="" selected>--Select Currency--</option>
                            {currencyData.map((currency) => {
                              return (
                                <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                              )
                            })}
                          </select>
                          {errorData.shipCurrency && <p className="text-danger error-contract">{errorData.shipCurrency}</p>}
                        </div>
                      </div>

                      <div className="row form-group">
                        <label className="col-md-6">Demurrage Rate {astrix}  {toolTip('demmurageRate')}</label>
                        <div className="col-md-3">
                          <input type="number" step="0.01" name="demmurageRate" placeholder="Enter Price" value={data.demmurageRate} className={"form-control-bottom form-control" + (!errorData.demmurageRate ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.demmurageRate && <p className="text-danger error-contract">{errorData.demmurageRate}</p>}
                        </div>
                        <div className="col-md-3">
                          <select className="form-control" name="demmurageCurrency" value={data.shipCurrency} disabled>
                            <option value="" selected>--Select Currency--</option>
                            {currencyData.map((currency) => {
                              return (
                                <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                              )
                            })}
                          </select>
                          {errorData.shipCurrency && <p className="text-danger error-contract">{errorData.shipCurrency}</p>}
                        </div>
                      </div>

                      <hr />
                      <h3 className="text-primary text-center" >Shipment Details</h3>
                      <hr width="50%" />

                      {/* <div className="row mt-2"> */}

                      <div className="row form-group">
                        <label className="col-md-6 ">Shipment Type {astrix}  {toolTip('shipmentType')}</label>
                        <div className="col-md-6">
                          <select className={"form-control" + (!errorData.shipmentType ? '' : ' border-danger')} name="shipmentType" value={data.shipmentType} onChange={(event) => { handleChange(event); handleShipmentType(event) }}>
                            <option value="" selected>--Select Shipment Type--</option>
                            <option value="bulk">Bulk Shipment</option>
                            <option value="container">Container Shipment</option>
                          </select>
                          {errorData.shipmentType && <p className="text-danger error-contract">{errorData.shipmentType}</p>}
                        </div>
                      </div>

                      {(data.shipmentType === "container") && <>
                        <div className="col-md-12">
                          <div className="row form-group">
                            <div className="col-md-6">
                              <label >Container Type {toolTip('containerType')}</label>
                              <select className={" form-control" + (!errorData.containerType ? '' : ' border-danger')} name="containerType" value={data.containerType} onChange={handleChange}>
                                <option value="" selected>--Select Container Type--</option>
                                {containerTypeData.map((contType) => {
                                  return (<option value={contType.id} >{contType.container_type_pretty}</option>)
                                })}
                              </select>
                              {errorData.containerType && <p className="text-danger error-contract">{errorData.containerType}</p>}
                            </div>
                            <div className="col-md-6">
                              <label >No. Of Containers{toolTip('containerCount')}</label>
                              <input type="number" name="containerCount" value={data.containerCount} className="form-control" onChange={handleChange} />
                              {errorData.containerCount && <p className="text-danger error-contract">{errorData.containerCount}</p>}
                            </div>
                          </div>
                        </div>
                      </>}

                      <div className="row form-group ">
                        <label className="col-md-6">Charter Type  {astrix} {toolTip('charterType')}</label>
                        <div className="row col-md-6 ">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="charterType" id="charterType1" value="1" onChange={handleChange} checked={data.charterType === '1' ? true : false} />
                            <label className="form-check-label" for="charterType1">Voyage Charter  </label>
                          </div>
                          {/* <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="charterType" id="charterType2" value="2" onChange={handleChange} checked={data.charterType === '2' ? true : false} />
                            <label className="form-check-label" for="charterType2">Time Charter  </label>
                          </div> */}
                          {errorData.charterType && <p className="text-danger error-contract">{errorData.charterType}</p>}
                        </div>
                      </div>
                      {/* {(data.charterType / 1 === 2) && <>
                        <div className="col-md-12">
                          <div className="row form-group">
                            <div className="col-md-6">
                              <label >Start Date {astrix}  {toolTip('charterStart')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="charterStart" value={data.charterStart} className="form-control" onChange={handleChange} />
                              {errorData.charterStart && <p className="text-danger error-contract">{errorData.charterStart}</p>}
                            </div>
                            <div className="col-md-6">
                              <label >End Date {astrix}  {toolTip('charterEnd')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="charterEnd" value={data.charterEnd} className="form-control" onChange={handleChange} />
                              {errorData.charterEnd && <p className="text-danger error-contract">{errorData.charterEnd}</p>}
                            </div>
                          </div>
                        </div>
                      </>} */}
                      <div className="row ">
                        <label className="col-md-12">Loading Type {astrix}  {toolTip('loadingType')}</label>
                        <div className="row col-md-6 mt-2">
                          <div className="col-md-6">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" name="berthLoading" id="berthLoading" onChange={handleChecked} checked={data.berthLoading} />
                              <label className="form-check-label" for="berthLoading">Berth Loading</label>
                            </div>
                          </div>
                          <div className="row col-md-6 ">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="berthLoadingNumber" id="berthLoadingNumber1" value="1" onChange={handleChange} checked={data.berthLoadingNumber / 1 === 1 ? true : false} disabled={!data.berthLoading ? true : false} />
                              <label className="form-check-label" for="berthLoadingNumber1">1</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="berthLoadingNumber" id="berthLoadingNumber2" value="2" onChange={handleChange} checked={data.berthLoadingNumber / 1 === 2 ? true : false} disabled={!data.berthLoading ? true : false} />
                              <label className="form-check-label" for="berthLoadingNumber2">2</label>
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-6 mt-2">
                          <div className="col-md-6">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" name="anchorageLoading" id="anchorageLoading" onChange={handleChecked} checked={data.anchorageLoading} />
                              <label className="form-check-label" for="anchorageLoading">Anchorage Loading</label>
                            </div>
                          </div>
                          <div className="row col-md-6 ">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="anchorageLoadingNumber" id="anchorageLoadingNumber1" value="1" onChange={handleChange} checked={data.anchorageLoadingNumber / 1 === 1 ? true : false} disabled={!data.anchorageLoading ? true : false} />
                              <label className="form-check-label" for="anchorageLoadingNumber1">1</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="anchorageLoadingNumber" id="anchorageLoadingNumber2" value="2" onChange={handleChange} checked={data.anchorageLoadingNumber / 1 === 2 ? true : false} disabled={!data.anchorageLoading ? true : false} />
                              <label className="form-check-label" for="anchorageLoadingNumber2">2</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 row mt-2">
                          <div className="col-md-12">
                            {errorData.loadingType && <p className="text-danger error-contract">{errorData.loadingType}</p>}
                          </div>
                          <div className="col-md-6">
                            {errorData.berthLoadingNumber && <p className="text-danger error-contract">{errorData.berthLoadingNumber}</p>}
                          </div>
                          <div className="col-md-6">
                            {errorData.anchorageLoadingNumber && <p className="text-danger error-contract">{errorData.anchorageLoadingNumber}</p>}
                          </div>
                        </div>

                        <div className="col-md-6 mt-3">
                          <div className="row form-group">
                            <label className="col-md-12">Loading Rate (PDPR) {astrix}  {toolTip('loadingRate')}</label>
                            <div className="col-md-12">
                              <input type="number" name="loadingRate" placeholder="Enter Loading Rate" value={data.loadingRate} className={" form-control" + (!errorData.loadingRate ? '' : ' border-danger')} onChange={handleChange} />
                              {errorData.loadingRate && <p className="text-danger error-contract">{errorData.loadingRate}</p>}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row ">
                        <label className="col-md-12">Unloading Type  {astrix} {toolTip('unloadingType')}</label>
                        <div className="row col-md-6 mt-2">
                          <div className="col-md-6">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" name="berthUnloading" id="berthUnloading" onChange={handleChecked} checked={data.berthUnloading} />
                              <label className="form-check-label" for="berthUnloading">Berth Unloading</label>
                            </div>
                          </div>
                          <div className="row col-md-6 ">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="berthUnloadingNumber" id="berthUnloadingNumber1" value="1" onChange={handleChange} checked={data.berthUnloadingNumber / 1 === 1 ? true : false} disabled={!data.berthUnloading ? true : false} />
                              <label className="form-check-label" for="berthUnloadingNumber1">1</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="berthUnloadingNumber" id="berthUnloadingNumber2" value="2" onChange={handleChange} checked={data.berthUnloadingNumber / 1 === 2 ? true : false} disabled={!data.berthUnloading ? true : false} />
                              <label className="form-check-label" for="berthUnloadingNumber2">2</label>
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-6 mt-2">
                          <div className="col-md-6">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" name="anchorageUnloading" id="anchorageUnloading" onChange={handleChecked} checked={data.anchorageUnloading} />
                              <label className="form-check-label" for="anchorageUnloading">Anchorage Unloading</label>
                            </div>
                          </div>
                          <div className="row col-md-6 ">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="anchorageUnloadingNumber" id="anchorageUnloadingNumber1" value="1" onChange={handleChange} checked={data.anchorageUnloadingNumber / 1 === 1 ? true : false} disabled={!data.anchorageUnloading ? true : false} />
                              <label className="form-check-label" for="anchorageUnloadingNumber1">1</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="anchorageUnloadingNumber" id="anchorageUnloadingNumber2" value="2" onChange={handleChange} checked={data.anchorageUnloadingNumber / 1 === 2 ? true : false} disabled={!data.anchorageUnloading ? true : false} />
                              <label className="form-check-label" for="anchorageUnloadingNumber2">2</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 row mt-2">
                          <div className="col-md-12">
                            {errorData.unloadingType && <p className="text-danger error-contract">{errorData.unloadingType}</p>}
                          </div>
                          <div className="col-md-6">
                            {errorData.berthUnloadingNumber && <p className="text-danger error-contract">{errorData.berthUnloadingNumber}</p>}
                          </div>
                          <div className="col-md-6">
                            {errorData.anchorageUnloadingNumber && <p className="text-danger error-contract">{errorData.anchorageUnloadingNumber}</p>}
                          </div>
                        </div>

                        <div className="col-md-6 mt-3">
                          <div className="row form-group">
                            <label className="col-md-12">Unloading Rate (PDPR)  {astrix} {toolTip('unloadingRate')}</label>
                            <div className="col-md-12">
                              <input type="number" name="unloadingRate" placeholder="Enter Unloading Rate" value={data.unloadingRate} className={" form-control" + (!errorData.unloadingRate ? '' : ' border-danger')} onChange={handleChange} />
                              {errorData.unloadingRate && <p className="text-danger error-contract">{errorData.unloadingRate}</p>}
                            </div>
                          </div>
                        </div>

                      </div>
                      {/* </div> */}

                      <hr />
                      <h3 className="text-primary text-center" >Message</h3>
                      <hr width="50%" />
                      <div className="row d-flex mt-3">
                        <div className="d-flex col-md-12">
                          <label className="col-md-6 pl-0">Message {toolTip('quotMessage')}</label>
                          <div className="col-md-6">
                            <textarea name="quotMessage" value={data.quotMessage} placeholder="Enter Message" className="form-control" onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer primary">
                    <div className="col-md-12 row">
                      <button type="button" className="btn btn-secondary ml-2 btn-sm" onClick={() => { setupdateModal(false); seterrorData({}) }}>Close</button>
                      {(aclMap.oquotation_access && aclMap.oquotation_access.oquotation_manage && aclMap.oquotation_access.oquotation_manage.isExist) &&
                        <>{data.quotStatus === 0 ?
                          <button type="button" className="btn btn-primary ml-2 btn-sm" onClick={() => prehandleSubmit()}>Save changes</button> :
                          data.quotStatus === 1 ?
                            <button type="button" className="btn btn-success ml-2 btn-sm" onClick="">Quotation Got Accepted</button> :
                            data.quotStatus === 2 ?
                              <button type="button" className="btn btn-danger ml-2 btn-sm" onClick="">Quotation Got Rejected</button> : ""}
                        </>}
                    </div>
                    <span className="chat_button" onClick={() => setChat(chat ? false : true)}><i className="fa fa-comments-o"></i></span>
                  </div>
                </div>
                <div className={"messaging modal-content " + (chat ? " active" : "")} id="messaging" >
                  <div className="modal-header primary">
                    <h4 className="modal-title text-white">Chat</h4>
                  </div>
                  <div className="inbox_msg">
                    <div className="mesgs">
                      <div className="msg_history">
                        {gettrailList()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div >
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shipment)
