import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../../../partial/sideBarV2";
import HeaderV2 from "../../../partial/headerV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlobURL, extractNoFromTxt, finLogoByUserId, GetCache, getDocDetails, LCPurposeObject, LCTypesObject, most_used_currencies, SetCache, toolTip } from "../../../../utils/myFunctions";
import call from "../../../../service";
import config from "../../../../config.json";
import { FileInput } from "../../../../utils/FileInput";
import AdavancePaymentTab from "../../../InvoiceDiscounting/contract/components/adavancePaymentTab";
import { InputWithSelect, NewTextArea } from "../../../../utils/newInput";
import LcDetails from "./lcDetails";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { ToastContainer } from "react-toastify";
import { drawEllipsePath } from "pdf-lib";
import axios from "axios";
import { aiServerBackendUrl, platformURL } from "../../../../urlConstants";
import { formatMs, useEventCallback } from "@material-ui/core";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import moment from "moment";
import TutorialPopup from "../../../tutorialPopup";
import avatarUrl from "../../../../utils/makeAvatarUrl";
import LcDetailsV2 from "./lcDetailsV2";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const reviewLabels = [
  { name: 'Total Finance Application', color: "#1B94B7", alt: "totalFinanceApplied" },
  { name: 'Application Rejected', color: '#FE4141', alt: "applicationRejected" },
  { name: 'Application Approved', color: '#00B74D', alt: "applicationApproved" },
]

const tutorialPopupCSS = {
  "0": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Enter required limit for you & click on Continue.</span></label>
  },
  "1": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Select Your LC Type.</span></label>
  },
  "2": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Upload LC & Manually enter LC details which are failed to fetch from document, review & click on continue button.</span></label>
  },
  "3": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Sign documents & click on continue button.</span></label>
  },
  "4": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Kindly select financier with whom you want to share LC.</span></label>
  },
  "5": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Review all form details, click on check box & click on Submit button.</span></label>
  }
}


const addBuyerTabs = [
  { name: "Buyer details" },
  { name: "LC details" },
  { name: "Documents" },
  { name: "Select financer" },
  { name: "Review" },
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

export const Lcdetailsdata = [
  { name: "Sight LC", value: "sight_lc" },
  { name: "Usance LC", value: "usance_lc" },
  { name: "Back to back LC", value: "b2bank_lc" },
  { name: "Green Clause LC", value: "green_clause_lc" },
  { name: "Red Clause LC", value: "red_clause_lc" },
  { name: "Transferable LC", value: "transferable_lc" },
];

const reviewForm3 = [
  { "name": "LC type", val: "lctype" },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo" },
  { "name": "LC number", val: "lcNo" },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode" },

  { "name": "LC tenor", val: "lcTenor" },
  { "name": "LC issuing bank name", val: "lcIssuingBankName" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" },

  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode" },
]

export const lcTenorDD = [
  { name: "30 Days", ocrValue: "30" },
  { name: "45 Days", ocrValue: "45" },
  { name: "60 Days", ocrValue: "60" },
  { name: "90 Days", ocrValue: "90" },
  { name: "120 Days", ocrValue: "120" }
]


const LcDiscountingcard = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  let isBuyerUndefined = buyer === "undefined" || buyer === "null"
  const lcPurpose = queryParams.get("purpose")
  const onlyView = queryParams.get("view") ? true : false
  const adminView = queryParams.get("adminView") ? true : false
  const adminLCApply = queryParams.get("adminLCApply") ? true : false
  const uploadReqLetter = queryParams.get("uploadReqLetter") ? true : false
  const quoteId = queryParams.get("id")
  const amendmentId = queryParams.get("amendmentId")
  const sendLCForReview = queryParams.get("sendLCForReview")
  const history = useHistory();

  const [tab, setTab] = useState(!isBuyerUndefined ? 1 : 1)
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);

  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [ocrData, setOCRData] = useState({ '32B1': 'USD' })
  const [ocrTxtFormat, setOcrTxtFormat] = useState([])

  const [countrys, setCountrys] = useState([])
  const [ports, setPorts] = useState([])
  const [swiftData, setSwiftData] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [lcFinanciers, setLCFinanciers] = useState([])

  const [lcCodes, setLCCodes] = useState([])
  const [userBanks, setUserBanks] = useState([])
  const [inputdisplay, setInputdisplay] = useState(onlyView);

  const [amendReq, setAmendReq] = useState({});

  const [textarea, setTextarea] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const [askForConfirmingBank, setAskForConfirmingBank] = useState(0)
  const [tab1, setTab1] = useState(false);
  const [confirmbanking, setConfirmbanking] = useState(false);
  const [ammendPopup, toggleAmmendPopup] = useState({ show: false, key: null })
  const [ammendPopup2, toggleAmmendPopup2] = useState({ show: false, key: null })
  const [ammendFields, setAmmendFields] = useState({})
  const [allPorts, setAllPorts] = useState([])
  const [allSwiftData, setAllSwiftData] = useState([])

  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 10)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [finList, setFinList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})
  const [draftLCBlob, setDraftLCBlob] = useState(null)
  const [ocrFieldMapped, toggleOcrFieldMapped] = useState(false)

  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }


  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = localStorage.getItem("manualEmailId") || (userToken.email ? userToken.email : null)
  const userId = localStorage.getItem("manualUserId") || (userToken.user_id ? userToken.user_id : null)
  const userName = localStorage.getItem("manualUserName") || (userToken.userName ? userToken.userName : null)
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const subUserName = userToken?.sub_user_name
  const mainUserName = userToken?.main_user_name
  console.log('Usernameeeeeeeeee', subUserName, mainUserName, userData)

  useEffect(() => {
    if (lcTutorial) {
      if (tab == 0) {
        setTutorialStage(0)
      }
      if (tab == 1) {
        setTutorialStage(1)
      }
      if (tab == 1 && inputdisplay) {
        setTutorialStage(2)
      }
      if (tab == 2) {
        setTutorialStage(3)
      }
      if (tab == 3) {
        setTutorialStage(4)
      }
      if (tab == 4) {
        setTutorialStage(5)
      }
    }
  }, [tab, inputdisplay])

  useEffect(() => {
    if (data?.draftLCDocument?.filebase64) {
      let blob = null
      blob = dataURItoBlobURL(data.draftLCDocument.filebase64);
      setDraftLCBlob(blob)
    }
  }, [data.draftLCDocument])

  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }


  const confirmBank = () => {
    setConfirmbanking(true);
    setAskForConfirmingBank(askForConfirmingBank + 1)
  }

  async function reloadUserBankDetails() {
    setshowLoader(true)
    call("POST", "getUserBanks", { "email": userEmail, "inProfile": true })
      .then((result) => {
        setshowLoader(false)
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        setshowLoader(false)
        // console.log("Error while querying getUserBanks:", e);
      });
  }

  async function getFinanceDetails() {
    setshowLoader(true)
    call('POST', 'getLCQuoteApplicationById', { quoteId }).then(async (result) => {
      console.log('running getLCQuoteApplicationById api-->', result);
      let invoiceDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[0] : null)
      let poDocument = await getDocDetails(result[0].buyersDocId ? result[0].buyersDocId.split(":")[1] : null)
      let draftLCDocument = await getDocDetails(result[0].draftLC, null, null, null, true)
      let buyerOtherDocIdArr = result[0].buyerOtherDocs ? result[0].buyerOtherDocs.split(":") : []
      let otherDocObj = {}
      let moreDocArr = addMoreDoc
      for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
        otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
        moreDocArr.push(null)
      }
      if (result[0]["confirmingBankName"]) {
        setConfirmbanking(true)
        setAskForConfirmingBank(2)
      }
      setData({
        ...result[0], buyerCurrency: result[0].buyerCurrency ? result[0].buyerCurrency.split(":")[1] : null, invoiceDocument, poDocument, ...otherDocObj, draftLCDocument,
        limitRequired: result[0]["requiredLimit"],
        limitRequiredCurrency: result[0]["requiredLimitCurrency"], lctype: result[0]["lcType"],
        portOfLoading: result[0]["portOfLoadingId"], portOfDischarge: result[0]["portOfDischargeId"], tcAccepted: true
      })
      setAddMoreDoc(moreDocArr)
      setOCRData(result[0].ocrFields)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      setshowLoader(false)
    });

  }

  async function getAmendmentDetailsById() {
    if (amendmentId) {
      let amendmentData = {}
      let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, amendmentId })
      if (amendmentDataRes && amendmentDataRes.length) {
        amendmentData["fieldLabels"] = Object.keys(amendmentDataRes[0].details) || []
        amendmentData["fieldComments"] = amendmentDataRes[0].details || {}
        // amendmentData["changedDetails"] = amendmentDataRes[0].changedDetails || {}
        amendmentData["changedDetails"] = amendmentDataRes[0].beforeDetails || {}
        setAmendReq(amendmentData)
      }
      else {
        toastDisplay("Invalid Link", "info", () => {
          history.go(-1)
        })
      }
    }
  }

  const handleMultiSelect = async (e, name, val) => {
    console.log("handleMultiSelect", e, name, val);
    if (e?.[0]?.id === "addNewBank") {
      return window.open(`${platformURL}/viewprofile?action=addNewBank`, "_blank")
    }
    if (e?.[0]?.id === "temp") {
      setshowLoader(true)
      if (name === "lcIssuingBank") {
        let swiftId = await call('POST', 'insertSwiftBankInMaster', { bankName: e?.[0]?.typedInput })
        setshowLoader(false)
        call('POST', 'getSwiftMaster').then((result) => {
          setSwiftData(result)
          setAllSwiftData(result)
          setData({
            ...data,
            [name]: swiftId,
            "lcIssuingBankName": e?.[0]?.typedInput, "lcIssuingBankAddress": null,
            "lcIssuingBankSwiftCode": null
          });
        }).catch((e) => {
          console.log('error in getSwiftMaster', e);
        })
      }
      else {
        let portId = await call('POST', 'insertPortInMaster', { portName: e?.[0]?.typedInput })
        setshowLoader(false)
        call('POST', 'getOldPortMaster').then((result) => {
          setPorts(result)
          setAllPorts(result)
          setData({
            ...data,
            [name]: portId
          });
        }).catch((e) => {
          // console.log('error in getOldPortMaster', e);
        })
      }
    }
    else {
      if (name === "lcIssuingBank") {
        let selectedValue = e?.[0]?.id ? e.reverse()?.[0] : {}
        setData({
          ...data,
          [name]: selectedValue.id || null,
          "lcIssuingBankName": selectedValue.bankName || "", "lcIssuingBankAddress": selectedValue.branchName || "",
          "lcIssuingBankSwiftCode": selectedValue.swiftCode || ""
        })
      }
      else if (name === "beneficiaryBank") {
        let selectedEntry = e?.[0]?.id && e.reverse()?.[0]?.id ? e.reverse()?.[0] : {}
        setData({
          ...data,
          [name]: selectedEntry.id || null,
          "beneficiaryBankName": selectedEntry.beneficiaryBankName || "", "beneficiaryAccountNo": selectedEntry.beneficiaryAccountNo || "",
          "beneficiaryIfscCode": selectedEntry.beneficiaryIfscCode || "", "beneficiarySwiftCode": selectedEntry.beneficiarySwiftCode || ""
        });
      }
      else {
        setData({
          ...data,
          [name]: e?.[0]?.id ? e.reverse()?.[0]?.id : null
        })
      }
    }
  };

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let tempPort = []
    let filtered = []
    if (name === "ports") {
      tempPort = [{ id: "temp", "port_name": "Add New Option", typedInput }]
      filtered = allPorts.filter((i) => {
        if (i.port_name && i.port_name.toLowerCase().includes(typedInput.toLowerCase())) {
          return i
        }
      })
    }
    if (name === "swiftData") {
      tempPort = [{ id: "temp", "bankName": "Add New Option", typedInput }]
      filtered = allSwiftData.filter((i) => {
        if (i.bankName && i.bankName.toLowerCase().includes(typedInput.toLowerCase())) {
          return i
        }
      })
    }
    if (!filtered.length) {
      filtered = tempPort
    }
    if (name === "ports") {
      setPorts(filtered)
    }
    if (name === "swiftData") {
      setSwiftData(filtered)
    }
  };

  useEffect(() => {
    getFinanciersForLC()
  }, [data.selectedLenderId])

  useEffect(() => {
    getAmendmentDetailsById()
    if (onlyView || adminView) {
      getFinanceDetails()
    }
    else {
      if (!isBuyerUndefined)
        fetchBuyerData()
    }
    // Get master data start
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    call('POST', 'getOldPortMaster').then((result) => {
      setPorts(result)
      setAllPorts(result)
    }).catch((e) => {
      console.log('error in getOldPortMaster', e);
    })
    call('POST', 'getSwiftMaster').then((result) => {
      setSwiftData(result)
      setAllSwiftData(result)
    }).catch((e) => {
      console.log('error in getSwiftMaster', e);
    })
    // call('POST', 'getFinanciersForLC').then((result) => {
    //   setLCFinanciers(result)
    // }).catch((e) => {
    //   console.log('error in getFinanciersForLC', e);
    // })
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      setLCCodes(result.mandatoryFields.concat(result.optionalFields))
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    call("POST", "getUserBanks", { "email": userEmail, "inProfile": true })
      .then((result) => {
        setUserBanks(result.message.concat([{ id: "addNewBank", beneficiaryBankName: "Add New Bank" }]));
      })
      .catch((e) => {
        // console.log("Error while querying getUserBanks:", e);
      });
    // Get master data end
    console.log('bbsdadsa', isBuyerUndefined, amendmentId, onlyView, adminView);
    if (!isBuyerUndefined && !amendmentId && !onlyView && !adminView && !adminLCApply) {
      setOverlay(true);
    }
  }, [])

  async function fetchBuyerData() {
    setshowLoader(true)
    call('POST', 'getBuyersDetailWithDoc', {
      buyersId: buyer,
      applicationId: quoteId
    }).then(async (result) => {
      console.log('running getBuyersDetailWithDoc api-->', result);
      if (result.length) {
        let invoiceDocument = await getDocDetails(result[0].buyersDocId.split(":")[0])
        let poDocument = await getDocDetails(result[0].buyersDocId.split(":")[1])
        setData({
          ...result[0], buyerCurrency: result[0].buyerCurrency.split(":")[1], invoiceDocument, poDocument, limitRequiredCurrency: "USD"
        })
      }
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
      setshowLoader(false)
    });
  }

  useEffect(() => {
    // Only to fetch pdf in txt format start
    if (draftLCBlob) {
      convertPdfToTxt()
    }
    // Only to fetch pdf in txt format end
  }, [draftLCBlob])

  useEffect(() => {
    if (data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId && !onlyView && !adminView) {
      getDetailsByOCR()
    }
    // if (data.draftLCDocument && data.draftLCDocument.name && tab != 2 && amendReq && amendReq.fieldLabels && amendReq.fieldLabels.length) {
    //   getDetailsByOCR(amendReq.fieldLabels)
    // }
  }, [data.draftLCDocument, amendReq])

  async function mapOCRDataToFields(ocrData) {
    if (Object.keys(ocrData).length) {
      let processedData = {}
      if (ocrData["lc_issuing_bank_name"]) {
        setshowLoader(true)
        let swiftId = await call('POST', 'insertSwiftBankInMaster', {
          bankName: ocrData["lc_issuing_bank_name"],
          bankAddress: ocrData["lc_issuing_bank_address"], swiftCode: ocrData["lc_issuing_bank_swift_code"]
        })
        let apiResult = await call('POST', 'getSwiftMaster')
        setshowLoader(false)
        setSwiftData(apiResult)
        setAllSwiftData(apiResult)
        processedData["lcIssuingBank"] = swiftId
        processedData["lcIssuingBankName"] = ocrData["lc_issuing_bank_name"]
        processedData["lcIssuingBankAddress"] = ocrData["lc_issuing_bank_address"]
        processedData["lcIssuingBankSwiftCode"] = ocrData["lc_issuing_bank_swift_code"]
      }
      if (ocrData["20"]) {
        processedData["lcNo"] = ocrData["20"]
      }
      if (ocrData["44E"]) {
        let matchWord = ocrData["44E"].split(" ")[0].toUpperCase()
        let temp = ports.filter((i) => {
          if (i.port_name.toUpperCase().includes(matchWord)) {
            return i
          }
        })
        if (temp.length) {
          processedData["portOfLoading"] = temp[0].id
          processedData["portOfLoadingName"] = temp[0].port_name
        }
      }
      if (ocrData["44F"]) {
        let matchWord = ocrData["44F"].split(" ")[0].toUpperCase()
        let temp = ports.filter((i) => {
          if (i.port_name.toUpperCase().includes(matchWord)) {
            return i
          }
        })
        if (temp.length) {
          processedData["portOfDischarge"] = temp[0].id
          processedData["portOfDischargeName"] = temp[0].port_name
        }
      }
      if (ocrData["45A"]) {
        processedData["commodity"] = ocrData["45A"]
      }
      if (ocrData["44C"]) {
        let temp = moment(ocrData["44C"]).isValid()
        if (temp) {
          processedData["expectedDateOfShipment"] = moment(ocrData["44C"]).format("YYYY-MM-DD")
        }
      }
      if (ocrData["48"]) {
        processedData["lcTenor"] = extractNoFromTxt(ocrData["48"]) || ""
      }
      // console.log("processedDataaaaaaaaaaaaaaaaa", processedData)
      // if (ocrData["48"]) {
      //   let temp = lcTenorDD.filter((i) => {
      //     if (i.ocrValue.includes(ocrData["48"])) {
      //       return i
      //     }
      //   })
      //   if (temp.length) {
      //     processedData["lcTenor"] = temp[0]["name"]
      //   }
      // }
      // console.log("processedDataaaaaaaaaaaaaaaaaaa", processedData, ocrData);
      setData({ ...data, ...processedData })
      toggleOcrFieldMapped(true)
    }
  }

  // useEffect(() => {
  //   console.log("ocrFieldMappedddddddddddddddddddd", ocrFieldMapped);
  //   if (!ocrFieldMapped) {
  //     mapOCRDataToFields()
  //   }
  // }, [ocrData])

  const getFinanciersForLC = () => {
    setshowLoader(true)
    call('POST', "getFinanciersForLCV2", {}).then(async res => {
      console.log("inside getFinanciersForLCV2", res);
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        element["isChecked"] = true
        element["isAvailable"] = true
        if (adminView && !data?.selectedLenderId?.includes(element.id)) {
          element["isChecked"] = false
        }
        element["logo"] = element.user_avatar || null
      }
      setFinList(res)
      setshowLoader(false)
    }).catch(err => setshowLoader(false))
  }

  async function convertPdfToTxt() {
    setshowLoader(true)
    let formData = new FormData()
    let blob = await fetch(draftLCBlob).then((response) => response.blob());
    formData.append("doc", blob, 'draftLCDocument.pdf')
    axios.post(`${aiServerBackendUrl}/pdf-to-text`, formData, {
    })
      .then(res => {
        console.log("thenpdf_to_text", res);
        setInputdisplay(true);
        // setshowLoader(false)
        setOcrTxtFormat(res?.data?.["pages"] || [])
      }).catch(function (response) {
        console.log("errorpdf_to_text", response)
        setInputdisplay(true);
        setshowLoader(false)
      });
  }

  async function getDetailsByOCR(onlyChangeFields) {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("doc", data.draftLCDocument)
    toastDisplay("Analyzing document kindly wait", "info")
    axios.post(`${aiServerBackendUrl}/text-analyse`, formData, {
    })
      .then(res => {
        setshowLoader(false)
        // console.log("then text-analyse", res);
        if (res.data.length) {
          if (onlyChangeFields) {
            let temp = Object.keys(res.data[0]) || []
            let onlyUpdateOCRFields = {}
            for (let index = 0; index < temp.length; index++) {
              const element = temp[index];
              if (onlyChangeFields.includes(element)) {
                onlyUpdateOCRFields[element] = res.data[0][element] || ""
              }
            }
            setOCRData({ ...ocrData, ...onlyUpdateOCRFields })
          }
          else {
            setOCRData({
              ...res.data[0], '32B1': 'USD', ["readerResp"]: res.data[0],
              '32B2': extractNoFromTxt(res.data[0]["32B"]) || ""
            })
            mapOCRDataToFields({
              ...res.data[0], '32B1': 'USD', ["readerResp"]: res.data[0]
            })
          }
        }
        else {
          toastDisplay("OCR Failed", "error")
        }
      }).catch(function (response) {
        setshowLoader(false)
        // console.log("error in text-analyse", response)
        toastDisplay("OCR Failed", "error")
      });
  }

  const handleOCRFieldsChange = async (event) => {
    if (event.target.name === "32B2" || event.target.name === "32B1") {
      setOCRData({ ...ocrData, "32B": ocrData["32B1"] + " " + ocrData["32B2"], [event.target.name]: event.target.value })
    }
    else {
      setOCRData({ ...ocrData, [event.target.name]: event.target.value })
    }
  }

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === "limitRequired") {
      setOverlay(false);
    }
    if (event.target.name === "portOfLoading" && event.target.value) {
      let selectedPort = ports.filter((i) => {
        if (i.id == event.target.value) {
          return i
        }
      })
      setData({
        ...data, [event.target.name]: event.target.value, portOfLoadingName: selectedPort[0].port_name
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name === "portOfDischarge" && event.target.value) {
      let selectedPort = ports.filter((i) => {
        if (i.id == event.target.value) {
          return i
        }
      })
      setData({
        ...data, [event.target.name]: event.target.value, portOfDischargeName: selectedPort[0].port_name
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name === "beneficiaryBank") {
      let selectedBank = userBanks.filter(i => {
        if (i.id == event.target.value) {
          return i
        }
      })[0]
      setData({
        ...data, [event.target.name]: event.target.value, "beneficiaryBankName": selectedBank.beneficiaryBankName, "beneficiaryAccountNo": selectedBank.beneficiaryAccountNo,
        "beneficiaryIfscCode": selectedBank.beneficiaryIfscCode, "beneficiarySwiftCode": selectedBank.beneficiarySwiftCode
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name === "lcIssuingBank") {
      let selectedBank = swiftData.filter(i => {
        if (i.id == event.target.value) {
          return i
        }
      })[0]
      setData({
        ...data, [event.target.name]: event.target.value, lcIssuingBankName: selectedBank.bankName, "lcIssuingBankAddress": selectedBank.branchName,
        "lcIssuingBankSwiftCode": selectedBank.swiftCode
      })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  const closeFn = () => {
    setLimitinvoice(true);
  }

  const closeExpFn = () => {
    setExperience(false);
  }

  // console.log("dddddddddddddddddddddddddddddddddddddddddddd", data);

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          // console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }


  const handleFun = () => {
    if (tab === 1 && !inputdisplay) {
      if (!data.lctype) {
        return toastDisplay("Please select LC type to continue", "info")
      }
      else if (!data.draftLCDocument?.name) {
        setErrors({ ...errors, ["draftLCDocument"]: "Please upload draft LC document to continue" })
      }
      else {
        setInputdisplay(true);
      }
    }
    else {
      handleValidation(undefined)
    }
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}

    if (tab === 0 && !isBuyerUndefined) {
      if (!data.limitRequired) {
        err["limitRequired"] = 'Mandatory Field'
      }
    }

    if (tab === 1) {
      // validateFields = sendLCForReview ? ["draftLCDocument", "beneficiaryBank", "lcNo"] : ["draftLCDocument", "lcNo", "lcTenor", "shipmentFromCountry", "shipmentToCountry", "portOfLoading", "portOfDischarge", "commodity", "expectedDateOfShipment", "beneficiaryBank",
      //   "lcIssuingBank", "countryOfOrigin"]
      validateFields = sendLCForReview ? ["draftLCDocument", "lcNo"] : ["draftLCDocument", "lcNo", "lcTenor", "shipmentFromCountry", "shipmentToCountry", "portOfLoading", "portOfDischarge", "commodity", "expectedDateOfShipment", "beneficiaryBank",
        "lcIssuingBank", "countryOfOrigin"]
      if (!(ocrData["32B1"] && ocrData["32B2"])) {
        err["32B2"] = "Mandatory Field"
      }
    }

    if (tab === 1 && confirmbanking) {
      validateFields = sendLCForReview ? [] : ["confirmingBankName", "confirmingBankAddress", "confirmingBankSwiftCode"]
    }

    if (tab === 2) {
      // if (!(data.draftLCDocument && data.draftLCDocument.signatureId)) {
      //   err["draftLCDocument"] = 'Signing document is mandatory'
      // }
      // if (!(data.invoiceDocument && data.invoiceDocument.name)) {
      //   err["invoiceDocument"] = 'Mandatory Field'
      // }
      // if (!(data.poDocument && data.poDocument.name)) {
      //   err["poDocument"] = 'Mandatory Field'
      // }
    }

    if (tab === 3) {
      let isFinSelected = false
      for (let index = 0; index < finList.length; index++) {
        const element = finList[index];
        if (element.isChecked) {
          isFinSelected = true
        }
      }
      if (!isFinSelected) {
        err["selectFinancer"] = "Mandatory"
        toastDisplay("Kindly select atleast 1 financer", "info")
      }
    }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (tab === 1 && data.lcTenor && !(0 < data.lcTenor / 1 && data.lcTenor / 1 < 366)) {
      err["lcTenor"] = "LC Tenor should be from 1 to 365 days only"
    }

    if (!Object.keys(err).length) {
      if (tab === 1 && askForConfirmingBank == 0) {
        if (lcPurpose === "lc_discounting") {
          if (!onlyView && !adminView) {
            setAskForConfirmingBank(askForConfirmingBank + 1)
          }
          else {
            setTab(tab + 1)
          }
        }
        else {
          setTab(tab + 1)
        }
      }
      else {
        if (tab === 4) {
          if (adminView) {
            adminUpdateLCDetails()
          }
          else {
            submitDetails()
          }
        }
        else {
          setTab(tab + 1)
        }
      }
    }
    else {
      toastDisplay("Please fill all the mandatory details in the form", "error")
    }
    setErrors(err)
  }

  async function submitDetails() {

    setshowLoader(true)

    let formData = new FormData()
    formData.append("userName", userName)

    if (onlyView) {
      formData.append("buyerEmail", data.buyerEmail)
      formData.append("onlyView", onlyView)
      formData.append("applicationId", quoteId)
      // formData.append("buyerOtherDocs", data.buyerOtherDocs)
    }

    if (amendmentId) {
      formData.append("amendmentId", amendmentId)
      let fieldLabels = amendReq.fieldLabels || []
      let changedDetails = {}
      for (let index = 0; index < fieldLabels.length; index++) {
        const element = fieldLabels[index];
        changedDetails[element] = data[element] ? data[element] : ocrData[element] ? ocrData[element] : ""
      }
      formData.append("changedDetails", JSON.stringify(changedDetails))
    }

    if (JSON.stringify(ammendFields) != "{}") {
      formData.append("ammendFields", JSON.stringify(ammendFields))
    }

    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })

    if (data.invoiceDocument?.signatureId) {
      formData.append("invoiceDocSignId", data.invoiceDocument.signatureId)
    }

    if (data.poDocument?.signatureId) {
      formData.append("poDocSignId", data.poDocument.signatureId)
    }

    if (data.draftLCDocument.signatureId) {
      formData.append("draftLCDocSignId", data.draftLCDocument.signatureId)
    }
    formData.append("lcPurpose", lcPurpose)
    formData.append("userId", userId)
    formData.append("ocrData", JSON.stringify(ocrData))
    addMoreDoc.forEach((item, index) => {
      if (data["otherDoc" + index] && data["otherDoc" + index]["signatureId"]) {
        formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
      }
    })
    formData.append("lcTypeTemplateName", LCTypesObject[data.lctype])
    formData.append("lcPurposeTemplateName", LCPurposeObject[lcPurpose])
    formData.append("ActionBy", subUserName ? subUserName : mainUserName)
    if (sendLCForReview) {
      formData.append("sendLCForReview", sendLCForReview)
    }
    if (finList.length) {
      formData.append("bankList", JSON.stringify(finList))
    }
    // console.log("data==========>", data, ocrData);
    call('POST', 'applyForLCQuote', formData).then((result) => {
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        if (lcTutorial) {
          localStorage.setItem("lcTutorialStep", 0)
        }
        if (onlyView) {
          history.go(-1)
        }
        else {
          if (adminLCApply) {
            window.close()
          } else {
            window.location = "LcQuote"
          }

        }
      })
    }).catch((e) => {
      // console.log('error in applyForLCQuote', e);
      toastDisplay(e, "error")
      setshowLoader(false)
    })
  }

  function handleFinSelection(index) {
    let temp = finList
    temp[index]["isChecked"] = !temp[index]["isChecked"]
    setFinList([...temp])
  }

  async function adminUpdateLCDetails() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("userName", userName)
    formData.append("userId", userId)
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    formData.append("ocrData", JSON.stringify(ocrData))
    if (finList.length) {
      formData.append("bankList", JSON.stringify(finList))
    }
    formData.append("lcPurposeTemplateName", LCPurposeObject[data[`lcPurpose`]])
    formData.append("lcTypeTemplateName", LCTypesObject[data[`lctype`]])
    formData.append("shipmentFromCountryName", countrys.find(i => {
      if (i.sortname === data.shipmentFromCountry) {
        return true
      }
    })?.["name"])
    formData.append("shipmentToCountryName", countrys.find(i => {
      if (i.sortname === data.shipmentToCountry) {
        return true
      }
    })?.["name"])
    formData.append("countryOfOriginName", countrys.find(i => {
      if (i.sortname === data.countryOfOrigin) {
        return true
      }
    })?.["name"])
    formData.append("portOfLoadingName", ports.find(i => {
      if (i.id === data.portOfLoading) {
        return true
      }
    })?.["port_name"])
    formData.append("portOfDischargeName", ports.find(i => {
      if (i.id === data.portOfDischarge) {
        return true
      }
    })?.["port_name"])
    call('POST', 'adminUpdateLCQuote', formData).then((result) => {
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      // console.log('error in adminUpdateLCQuote', e);
      toastDisplay(e, "error")
      setshowLoader(false)
    })
  }



  return (
    <>
      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ width: '4rem', height: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div>

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <TutorialPopup show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"]
        }} showSkip={true} userId={userId}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid" >
        <div className={`${overlay && 'overlay_in'}`}></div>
        <div className={`row`}>
          <SideBarV2 state={userTypeId / 1 == 20 ? 'CPlcLimit' : "LcDiscounting"} userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for finance limit"}
              userTokenDetails={userToken} />
            <a
              onClick={() => history.go(-1)}
              className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1"
                style={(lcTutorial) ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem 0rem 1rem 0rem",
                  "borderRadius": "1rem",
                  "height": "auto",
                  "maxHeight": "40rem",
                  "overflow": "auto"
                } : {}}
              >

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'5rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="mb-5">
                      <label className='font-wt-500 font-size-16 mb-3'><u>Limit required</u></label>
                      <div className="col-md-4 col-xl-3 col-lg-4 px-0" style={{ position: "relative", zIndex: "9" }} >
                        <InputWithSelect isAstrix={true} type={"number"} label={"Enter Limit required"}
                          selectData={currencyData}
                          selectName={"limitRequiredCurrency"} selectValue={data.limitRequiredCurrency}
                          optionLabel={"code"} optionValue={'code'}
                          name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                          onChange={handleChange} InputFocus />
                      </div>
                    </div>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u> <img
                          onClick={() => { window.location = `/buyerManagement?editBuyer=${buyer}` }}
                          className="ms-3 cursor"
                          src={"assets/images/edit.png"} alt="edit" /></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                          {/* <img className="ms-3 cursor"
                          onClick={() => { window.location = `/buyerManagement?editBuyer=${buyer}` }}
                          src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row mt-2'>
                          <div className="col-md-5 col-xl-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-xl-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          {/* {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            if (data["otherDoc" + index]) {
                              return (
                                <div className="col-md-5 col-xl-4">
                                  <label className="font-size-14">Add More</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null} */}
                        </div>
                      </div>
                    </div>

                    {/* textarea starts here */}
                    {/* <div class="input-group mb-3 col-md-4 px-0 border borderRadius input-group-lg textareablur" id="hamburger">
                      <textarea contenteditable="true" class="form-control border-0 w-90" aria-label="With textarea" placeholder="23 - Reference to pre-advice23" aria-describedby="inputGroup-sizing-lg"></textarea>

                      <span onClick={() => setTextarea(true)} id="inputGroup-sizing-lg" class=" cursor h-100 input-group-text bg-transparent border-0 position-absolute mt-2" style={{ right: "12px " }}><img className="genericImgIcon" src={"assets/images/chat.png"} alt="" /></span>
                    </div> */}

                    {/* {textarea && <div class={`modal fade ${textarea && "show"}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
                      <div class="modal-dialog modal-md modal-dialog-centered justify-content-center">
                        <div class="modal-content borderRadius submitmodal TextareaModal">
                          <div class="modal-body">
                            <div className="text-end mb-2">
                              <img onClick={() => setTextarea(false)} src={"/assets/images/cancel-icon.png"} alt="" className="cursor" />
                            </div>
                            <div>
                              <textarea contenteditable="true" class="form-control border w-90" aria-label="With textarea" placeholder="23 - Reference to pre-advice23" aria-describedby="inputGroup-sizing-lg" disabled></textarea>
                              <div className="text-center mt-3">
                                <NewTextArea
                                  rows={3}
                                  type={"text"} label={`Comment`} name={ammendPopup["lcNo"]}
                                  value={ammendFields[ammendPopup["lcNo"]]}
                                  onChange={(e) => {
                                    setAmmendFields({ ...ammendFields, ["lcNo"]: e.target.value || undefined })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="text-end">
                              <button className="new-btn py-2 px-3 text-white font-size-12">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>} */}


                    {/* textarea ends here */}

                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='px-5 pb-5'>
                    {!inputdisplay && <div className="row pt-5">
                      <label className='font-wt-500 font-size-16 mb-3'><u>Select LC type</u></label>
                      {Lcdetailsdata.map((key) => {
                        return (
                          <div className={`col-md-3 mt-4 cursor `} key={key.value}>
                            <div className={`card border-0 text-center borderRadius`}>
                              <a onClick={() => {
                                setData({ ...data, lctype: key.value })
                              }
                              } className={`font-size-13 mb-0 p-2 borderRadius  ${data.lctype === key.value ? "activebox " : " border"}`}> {key.name}</a>
                            </div>
                          </div>
                        );
                      })}
                      <div className="mt-5">
                        <label className='font-wt-500 font-size-16 mb-3'><u>{`Attach ${amendmentId ? "Amended LC" : 'Draft LC'} `}</u></label>
                        <div className="col-md-5">
                          <div className="col-md-11 px-0">
                            {/* <label className="font-size-14">{amendmentId ? "Amended LC" : "Draft LC"}</label> */}
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                                  onChange={handleFile} isEditable={adminView ? false : true}
                                  onUploadCancel={() => setData({ ...data, "draftLCDocument": null })} />
                                {errors.draftLCDocument ? <div class="text-danger mt-2 font-size-12">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors.draftLCDocument}</b></div> : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-5">
                        <label className='font-wt-500 font-size-16 mb-3'><u>Attach LC draft</u></label>
                        <div className="col-md-8 mb-3 px-0">
                          <label className="font-size-14">LC draft</label>
                          <div className="row align-items-center ">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                onChange={handleFile} isEditable={true}
                                onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                              />
                              {errors.invoiceDocument ? <div class="text-danger mt-2">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{errors.invoiceDocument}</b></div> : ''}
                            </div>
                            {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                              </div>
                            ) : null}
                            {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                              <div className="col-md-4 text-end">
                                <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div> */}

                    </div>}
                    {inputdisplay &&
                      <div className="mt-3">
                        <LcDetailsV2
                          draftLCBlob={draftLCBlob}
                          ocrTxtFormat={ocrTxtFormat}
                          adminView={adminView}
                          reloadUserBankDetails={reloadUserBankDetails}
                          handleMultiSelect={handleMultiSelect}
                          handleFilterOptions={handleFilterOptions}
                          amendmentId={amendmentId}
                          fieldLabels={amendReq.fieldLabels || []} fieldComments={amendReq.fieldComments || {}}
                          fieldChanged={amendReq.changedDetails || {}}
                          ammendPopup={ammendPopup} toggleAmmendPopup={toggleAmmendPopup}
                          ammendPopup2={ammendPopup2} toggleAmmendPopup2={toggleAmmendPopup2}
                          ammendFields={ammendFields} setAmmendFields={setAmmendFields}
                          onlyView={onlyView} lcCodes={lcCodes} ocrData={ocrData} handleOCRFieldsChange={handleOCRFieldsChange}
                          data={data} setData={setData} handleChange={handleChange} errors={errors} handleFile={handleFile} countrys={countrys} currencyData={currencyData}
                          ports={ports} userBanks={userBanks} swiftData={swiftData}
                          tab1={tab1} setTab1={setTab1} confirmbanking={confirmbanking} setConfirmbanking={setConfirmbanking} confirmBank={confirmBank} />
                        {/* ) : (
                          <LcDetails
                            adminView={adminView}
                            reloadUserBankDetails={reloadUserBankDetails}
                            handleMultiSelect={handleMultiSelect}
                            handleFilterOptions={handleFilterOptions}
                            amendmentId={amendmentId}
                            fieldLabels={amendReq.fieldLabels || []} fieldComments={amendReq.fieldComments || {}}
                            fieldChanged={amendReq.changedDetails || {}}
                            ammendPopup={ammendPopup} toggleAmmendPopup={toggleAmmendPopup}
                            ammendPopup2={ammendPopup2} toggleAmmendPopup2={toggleAmmendPopup2}
                            ammendFields={ammendFields} setAmmendFields={setAmmendFields}
                            onlyView={onlyView} lcCodes={lcCodes} ocrData={ocrData} handleOCRFieldsChange={handleOCRFieldsChange}
                            data={data} setData={setData} handleChange={handleChange} errors={errors} handleFile={handleFile} countrys={countrys} currencyData={currencyData}
                            ports={ports} userBanks={userBanks} swiftData={swiftData}
                            tab1={tab1} setTab1={setTab1} confirmbanking={confirmbanking} setConfirmbanking={setConfirmbanking} confirmBank={confirmBank} />
                        )} */}
                      </div>}
                  </div>
                ) : null}
                {tab === 2 ? (

                  <div className='px-5 py-4 pt-5'>

                    {uploadReqLetter ? (
                      <div className="col-md-8 mb-3">
                        <label className="font-size-14">{"Request Letter of Confirmation"}</label>
                        <div className="row align-items-center ">
                          <div className="form-group col-md-8 mb-0">
                            <FileInput name={"reqLetterDocument"} value={data.reqLetterDocument} error={errors.reqLetterDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "reqLetterDocument": null })}
                            />
                            {errors.reqLetterDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.reqLetterDocument}</b></div> : ''}
                          </div>
                          {data.reqLetterDocument && data.reqLetterDocument.name && !data.reqLetterDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.reqLetterDocument, docSignName: "reqLetterDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          ) : null}
                          {data.reqLetterDocument && data.reqLetterDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <div className="col-md-8 mb-3">
                      <label className="font-size-14">{amendmentId ? "Amended LC" : "Draft LC"}</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "draftLCDocument": null })}
                          />
                          {errors.draftLCDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.draftLCDocument}</b></div> : ''}
                        </div>
                        {data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.draftLCDocument, docSignName: "draftLCDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.draftLCDocument && data.draftLCDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {!isBuyerUndefined ? (<div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                          {errors.invoiceDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invoiceDocument}</b></div> : ''}
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>) : null}


                    {!isBuyerUndefined ? (<div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div>) : null}

                    {/* {!isBuyerUndefined ? (
                      <>
                        {addMoreDoc && addMoreDoc.length ? addMoreDoc.map((item, index) => {
                          return (
                            <div className="col-md-8 mb-3">
                              <label className="font-size-14">Add More</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-8 mb-0">
                                  <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                    error={errors["otherDoc" + index]}
                                    onChange={handleFile} isEditable={true}
                                    onUploadCancel={() => setData({ ...data, ["otherDoc" + index]: null })}
                                  />
                                  {errors["otherDoc" + index] ? <div class="text-danger mt-2">
                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    <b>{errors["otherDoc" + index]}</b></div> : ''}
                                </div>
                                {data["otherDoc" + index] && data["otherDoc" + index].name && !data["otherDoc" + index].signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["otherDoc" + index], docSignName: "otherDoc" + index }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                                  </div>
                                ) : null}
                                {data["otherDoc" + index] && data["otherDoc" + index].signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )
                        }) : null}
                      </>
                    ) : null} */}

                  </div>
                ) : null}

                {tab === 3 ? (
                  <div className='px-5 pt-5 pb-3'>
                    <label className='font-wt-500 font-size-16'>Select financer</label>
                    <div className="row my-4">
                      {finList.map((key, index) => {
                        return (
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-3">
                            <div className="d-flex gap-2 justify-content-between align-items-center">
                              <div class="custom-control ps-0 custom-checkbox form-check">
                                <input type="checkbox" class="form-check-input" id={"flexCheckChecked" + index} name="isChecked" value="on" onClick={
                                  () => {
                                    if (key.isAvailable) {
                                      handleFinSelection(index)
                                    } else {

                                    }
                                  }
                                } required checked={finList[index]["isChecked"] ? true : false} />
                                <label class="form-check-label" for={"flexCheckChecked" + index}></label>
                              </div>
                              <div className={`card p-3 col-10 ${key.isAvailable ? key.isRecommened ? "selectfinancercardrec" : "selectfinancercard" : 'disabledFinCard'}`}>
                                {/* {key.isRecommened && <div class="containercustom" data-ribbon="Recommended"></div>} */}
                                <div className="d-flex flex-row">
                                  <div>
                                    <p className="cursor">
                                      {key.logo ? (<img
                                        style={{ width: '4rem', height: '2rem' }}
                                        className="mx-2" src={avatarUrl(key.logo)} alt="" />) : null}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-row justify-content-between w-100">
                                    <span className="font-size-14 font-wt-600 text-dark">{key.name}</span>
                                    {/* {!key.isAvailable &&
                                      <img src="/assets/images/info-circle-outlined_remark.png" alt="" height={20} width={20} onClick={() => {
                                        setTooltip(true)
                                        setTooltipdata({
                                          lenderName: key.name,
                                          remarks: key.info_text
                                        })
                                      }} />
                                    } */}
                                  </div>
                                </div>
                                <div className="d-flex px-2">
                                  <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14">{key.avgRatings.toFixed(2)}</p>
                                    <span>
                                      <img className={`${key.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                      <img className={`${key.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                    </span>
                                  </div>
                                  <div
                                    onClick={async () => {
                                      // Get getUserTradeInfoWithFinancierLC
                                      let temp = key
                                      temp["show"] = true
                                      toggleReviewScrollIndex(0)
                                      setshowLoader(true)
                                      temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancierLC', { lenderId: key.id, sellerId: userId }) }
                                      setshowLoader(false)
                                      setSeeReview(temp)
                                    }}
                                    className="col-6 px-0 cursor"><p className="mb-1 font-size-14">{key.reviewsCount}</p><p className="font10 font-size-14 ">See reviews</p></div>
                                </div>
                                {/* <p
                                  onClick={() => {
                                    setSelectedChat({
                                      receiverName: key.name,
                                      receiverId: key.id,
                                      userId: userId,
                                      isChatOpen: true
                                    })
                                  }} 
                                  className="cursor"
                                ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2">Chat with financer</span></p> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                {tab === 4 ? (
                  <div className='px-5 py-4 pt-5'>
                    {!isBuyerUndefined ? (
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u> </label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                          {/* <div className='col-5 p-0'>
                          <label className='col-6 lh-18 text-color-label font-size-14'>{"Required Limit"}</label>
                          <label className='lh-18 font-size-14'>:</label>
                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                            {data["limitRequiredCurrency"] + " " + data["limitRequired"]}
                          </label>
                        </div> */}

                          <div className="col-md-6 pe-5">
                            <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                              {data["limitRequiredCurrency"] + " " + data["limitRequired"]}
                            </span> </p>
                          </div>

                        </div>
                      </div>
                    ) : null}

                    <div className={`w-100 ${!isBuyerUndefined ? 'mt-5' : ''}`}>
                      <label className='font-wt-500 font-size-16'><u>LC Details</u> </label>
                      <div className='row'>
                        {reviewForm3.map((item) => {
                          return (
                            // <div className='col-5 p-0'>
                            //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                            //   <label className='lh-18 font-size-14'>:</label>
                            //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                            //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                            //   </label>
                            // </div>

                            <div className="col-md-6 pe-5">
                              <p className=" d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <div className='w-100 mt-5'>
                      <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                      <div className='row mt-2'>
                        <div className="col-md-4">
                          <label className="font-size-14">{amendmentId ? "Amended LC" : "Draft LC"}</label>
                          <div className="row form-group">
                            <div className="col-md-12">
                              <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                                onChange={handleFile} isEditable={false}
                              />
                            </div>
                          </div>
                        </div>

                        {data?.invoiceDocument?.name ? (
                          <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {data?.poDocument?.name ? (
                          <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {/* {addMoreDoc.map((item, index) => {
                          if (data["otherDoc" + index]) {
                            return (
                              <div className="col-md-4">
                                <label className="font-size-14">Add More</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]} isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          else {
                            return null
                          }
                        })} */}

                      </div>
                    </div>


                    <div className='w-100 mt-5'>
                      <label className='font-wt-500 font-size-16'><u>Financiers Selected</u></label>
                      <div className="d-flex gap-4 my-3">
                        {finList.map((key) => {
                          if (key.isChecked) {
                            return (
                              <label key={key.name}
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {key.name}
                              </label>
                            );
                          }
                        })}
                      </div>
                    </div>


                    <div className='mt-3'>
                      <img
                        onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                        className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                      <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                    </div>

                  </div>

                ) : null}
                <div className="px-5 pb-5">
                  <button type="button"
                    disabled={(!data.tcAccepted && tab === 4)}
                    onClick={() => handleFun()}
                    className={`new-btn w-20 py-2 px-2 text-white enablesigncontract text-white `}>
                    {tab === 4 ? `${(onlyView || adminView) ? "Update" : " Submit "}` : "Continue"}
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div >

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your Application for Finance limit has been submitted successfully !</p>
      </FinanceInvoiceModal>}

      {/* {experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
        <img src={"/assets/images/lc/coins.png"} alt="" className="mb-3" />
        <p>100 coins debited from your wallet for finance limit application. </p>
        <p>Available balance : <img src={"/assets/images/sidebarV2/coin.png"} alt="" /> <span>10,000</span></p>
      </FinanceInvoiceModal >} */}

      {askForConfirmingBank == 1 && <FinanceInvoiceModal limitinvoice={askForConfirmingBank == 1}
        closeSuccess={() => setAskForConfirmingBank(askForConfirmingBank + 1)}>
        <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
        <p className="font-size-13 font-wt-600">Do you have any other confirming bank?</p>
        <button className="bg-white px-3 py-2 btnsmall font-size-13" onClick={() => confirmBank()}>Yes</button>
        <button className="bg-white px-3 py-2 btnsmall font-size-13 ms-3" onClick={() => setAskForConfirmingBank(askForConfirmingBank + 1)}>No</button>
      </FinanceInvoiceModal>
      }

    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(LcDiscountingcard)