import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Area, AreaChart, Bar, BarChart, Funnel, Legend, Line, FunnelChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import call from '../../service';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import LineChartComponent from '../Reports/components/LineChartComponent';
import PieChartComponent from '../Reports/components/PieChartComponent';
import { AccordionTable } from '../wallet/components/accordionTable';
import { NewTable } from '../../utils/newTable'
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import { connect } from 'react-redux';
import { NewInput, NewSelect } from '../../utils/newInput';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import MultipleSelect from '../../utils/MultipleSelect';
import { printDiv } from '../../utils/myFunctions';
import toastDisplay from '../../utils/toastNotification';

const lanesColor = ['#5CB8D3', '#2ECC71']

const label = {
  "paid": "Paid",
  "upcoming": "Upcoming",
  "due": "Due",
  "overdue": "Overdue",
  "paidApp": "Paid",
  "dueApp": "Due",
  "exporterCoinsUsed": "Exporter",
  "importerCoinsUsed": "Importer",
  "financierCoinsUsed": "Financier"
}

const generateInvoiceLabels = [
  { name: 'No. of Financers', alt: 'noOfFinanciers' },
  { name: 'Finance Amount', alt: "totalFinanceAmount" },
  { name: 'Total Commission (%)', alt: "totalCommissionPercentage" },
  { name: 'Total Commission', alt: "totalCommissionAmount" }
]

let invoiceFunnelGraphColors = ["#FF774D", "#FFA64C", "#F26191", "#CC66FF", "#39BF3F"]

const AdminPayments = ({ userTokenDetails, navToggleState }) => {

  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")


  const [isDropDown, setisDropDown] = useState(false)
  const [page, setPage] = useState(1)
  const [filterData, setFilterData] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: '' })
  const [count, setCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [expandedData, setExapndedData] = useState([]);
  const [dbData, setDbData] = useState([])
  const [tableExpand, setTableExpand] = useState("");
  const [showLoader, setshowLoader] = useState(false)


  const [Activitypage, setActivitypage] = useState(1)
  const [ActivityfilterData, setActivityfilterData] = useState({})
  const [Activityrefresh, setActivityrefresh] = useState(0)
  const [ActivityFilter, setActivityFilter] = useState({ resultPerPage: 10, search: '' })
  const [ActivityCount, setActivityCount] = useState(0)
  const [ActivityDbData, setActivityDbData] = useState([])
  const [ActivitytableData, setActivityTableData] = useState([])
  const [financierList, setFinancierList] = useState([])

  const [graphConfiguration, setGraphConfiguration] = useState({
    "paymentSummaryDuration": '6 Months',
    "generateInvoiceForId": [],
    "generateInvoiceForLabel": [],
    commissionGraphMode: true,
    earningGraphMode: true,
    coinsGraphMode: true,
    commissionFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    commissionTo: todayDateObj.clone().format("YYYY-MM-DD"),
    earningFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    earningTo: todayDateObj.clone().format("YYYY-MM-DD"),
    coinsSummaryFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    coinsSummaryTo: todayDateObj.clone().format("YYYY-MM-DD")
  })

  const [graphData, setGraphData] = useState({})


  const userId = userTokenDetails?.user_id
  const typeId = userTokenDetails?.type_id

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = <div>
        <img src={item.status === 1 ? "/assets/images/supplier-images/inProgress_icon.png" : item.status === 3 ? "/assets/images/supplier-images/failed_icon.png" : item.type === 'DEBIT' ? "/assets/images/supplier-images/debit_icon.png" : "/assets/images/supplier-images/credit_icon.png"} alt='' />
      </div>
      row[1] = item.createdByName
      row[2] = moment(item.updatedAt).format('DD/MM/YYYY hh:mm a')
      row[3] = item.serviceName
      row[4] = item.modeOfPayment
      row[5] = item.charges ? <>
        <span>{item.modeOfPayment === 'Coins' && <img src={'/assets/images/Lc/Dollar.png'} alt='' />}  {item.modeOfPayment === 'Coins' ? Math.abs(item.charges) : item.charges}</span>
      </> : <span>N/A</span>
      // row[6] = item.type
      row[6] = item.transactionId === tableExpand ? <div className='row' >
        <p onClick={() => expandedTableFN()} className="text-color1 font-wt-600 cursor">
          View Less
        </p>
      </div > : <div className='row' >
        <p onClick={() => expandedTable([item], item.transactionId)} className="text-color1 font-wt-600 cursor">
          View More
        </p>
      </div >
      row[7] = item.transactionId
      tableData.push(row)
      row = []
    })
    return tableData
  }

  function formatDataForActivityTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = <div>
        <img src={item.status === 1 ? "/assets/images/supplier-images/inProgress_icon.png" : item.status === 3 ? "/assets/images/supplier-images/failed_icon.png" : item.type === 'DEBIT' ? "/assets/images/supplier-images/debit_icon.png" : "/assets/images/supplier-images/credit_icon.png"} alt='' />
      </div>
      row[1] = moment(item.updatedAt).format('DD/MM/YYYY hh:mm a')
      row[2] = item.createdByName

      row[3] = item.modeOfPayment
      row[4] = item.charges ? <>
        <span>{item.modeOfPayment === 'Coins' && <img src={'/assets/images/Lc/Dollar.png'} alt='' />}  {item.modeOfPayment === 'Coins' ? Math.abs(item.charges) : item.charges}</span>
      </> : <span>N/A</span>
      tableData.push(row)
      row = []
    })
    return tableData
  }


  const expandedTableFN = () => {
    setTableExpand("");
    setExapndedData([]);

  }

  const expandedTable = (more, buyer) => {
    setTableExpand(buyer);
    setExapndedData(more);
  }

  function formatDataForTable2(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      let isInvoice = item.invCharges ? true : false
      let charges = item.invCharges || item.lcCharges
      row[0] = item.lenderName
      row[1] = `${isInvoice ? 'Invoice ' : 'LC '} Discounting - ${item.applicationNo}`
      row[2] = `${Intl.NumberFormat("en", { notation: 'compact' }).format(item.contractAmount)} USD`
      row[3] = charges.commissionPercentage
      row[4] = `${Intl.NumberFormat("en", { notation: 'compact' }).format(charges.totalCharges / charges.commissionPercentage)} USD`
      row[5] = item.invoiceDueDate
      if (isInvoice) {
        if (item.invoiceCommissionStatus / 1 == 1) {
          row[6] = <button type="button"
            class={`approved text-white border-0 `}>
            {"Paid"}
          </button>
        }
        if (item.invoiceCommissionStatus / 1 == 0) {
          row[6] = <button type="button"
            class={`inprogress text-white border-0 `}>
            {"Due"}
          </button>
        }
      }
      else {
        if (item.lcCommissionStatus / 1 == 1) {
          row[6] = <button type="button"
            class={`approved text-white border-0 `}>
            {"Paid"}
          </button>
        }
        if (item.lcCommissionStatus / 1 == 0) {
          row[6] = <button type="button"
            class={`inprogress text-white border-0 `}>
            {"Due"}
          </button>
        }
      }
      tableData.push(row)
      row = []
    }

    return tableData
  }

  function formatEarningThroughPlansTableData(resp) {
    let tableData = []
    let row = []

    if (resp.invoiceCommissionFunnelGraphData?.length && resp.lcCommissionFunnelGraphData?.length) {
      row[0] = "Invoice"
      row[1] = resp.invoiceCommissionFunnelGraphData[0]["value"]
      row[2] = resp.invoiceCommissionFunnelGraphData[1]["value"]
      row[3] = resp.invoiceCommissionFunnelGraphData[2]["value"]
      row[4] = resp.invoiceCommissionFunnelGraphData[3]["value"]
      row[5] = resp.invoiceCommissionFunnelGraphData[4]["value"]

      tableData.push(row)
      row = []

      row[0] = "LC"
      row[1] = resp.lcCommissionFunnelGraphData[0]["value"]
      row[2] = resp.lcCommissionFunnelGraphData[1]["value"]
      row[3] = resp.lcCommissionFunnelGraphData[2]["value"]
      row[4] = resp.lcCommissionFunnelGraphData[3]["value"]
      row[5] = resp.lcCommissionFunnelGraphData[4]["value"]
      tableData.push(row)
    }

    return tableData
  }

  function formatCoinsSummaryTableData(resp) {
    let tableData = []
    let row = []

    if (resp.coinsSummaryGraphData?.length) {
      row[0] = "Buyer Health"
      row[1] = resp.coinsSummaryGraphData[0]["exporterCoinsUsed"]
      row[2] = resp.coinsSummaryGraphData[0]["importerCoinsUsed"]
      row[3] = resp.coinsSummaryGraphData[0]["financierCoinsUsed"]
      tableData.push(row)
      row = []
      row[0] = "Credit Report"
      row[1] = resp.coinsSummaryGraphData[1]["exporterCoinsUsed"]
      row[2] = resp.coinsSummaryGraphData[1]["importerCoinsUsed"]
      row[3] = resp.coinsSummaryGraphData[1]["financierCoinsUsed"]
      tableData.push(row)
      row = []
      row[0] = "Finance Details"
      row[1] = resp.coinsSummaryGraphData[2]["exporterCoinsUsed"]
      row[2] = resp.coinsSummaryGraphData[2]["importerCoinsUsed"]
      row[3] = resp.coinsSummaryGraphData[2]["financierCoinsUsed"]
      tableData.push(row)
      row = []
      row[0] = "Buyer/Seller Discovery"
      row[1] = resp.coinsSummaryGraphData[3]["exporterCoinsUsed"]
      row[2] = resp.coinsSummaryGraphData[3]["importerCoinsUsed"]
      row[3] = resp.coinsSummaryGraphData[3]["financierCoinsUsed"]
      tableData.push(row)
      row = []
      row[0] = "Other Supplier"
      row[1] = resp.coinsSummaryGraphData[4]["exporterCoinsUsed"]
      row[2] = resp.coinsSummaryGraphData[4]["importerCoinsUsed"]
      row[3] = resp.coinsSummaryGraphData[4]["financierCoinsUsed"]
      tableData.push(row)
      row = []
    }
    return tableData
  }

  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getAdminPaymentsResport', graphConfiguration).then(res => {
      setshowLoader(false)
      setGraphData({
        ...res, commissionFromFinancierTableData: formatDataForTable2(res.commissionFromFinancierDbData),
        summaryOfInvoiceCommission: generateInvoiceSummary(res), earningFromPlanTableData: formatEarningThroughPlansTableData(res),
        coinsSummaryTableData: formatCoinsSummaryTableData(res)
      })
    })
    call('POST', 'getAllFinanciersList', {}).then(res => setFinancierList(res))
  }, [graphConfiguration])



  useEffect(() => {
    setTableData(formatDataForTable(dbData))
  }, [expandedData, tableExpand])

  const getPlanTransactions = () => {
    setshowLoader(true)
    let reqObj = {
      userId,
      typeId,
      search: filter.search,
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      type: 'transaction'
    }
    call('POST', 'getSubscriptionHistory', reqObj).then(result => {
      setTableData(formatDataForTable(result.message))
      setDbData(result.message)
      setCount(result.countdata.total_transactions)
      setshowLoader(false)
    }).catch(e => {
      console.log("Error while getSubscriptionHistory", e);
      setshowLoader(false)
    })
  }
  const getActivityTransactions = () => {
    setshowLoader(true)
    let reqObj = {
      userId,
      typeId,
      search: ActivityFilter.search,
      resultPerPage: ActivityFilter.resultPerPage,
      currentPage: Activitypage,
      fromDate: ActivityFilter.fromDate,
      toDate: ActivityFilter.toDate,
      type: 'activity'

    }
    call('POST', 'getSubscriptionHistory', reqObj).then(result => {
      setActivityTableData(formatDataForActivityTable(result.message))
      setActivityDbData(result.message)
      setActivityCount(result.countdata.total_transactions)
      setshowLoader(false)
    }).catch(e => {
      console.log("Error while getSubscriptionHistory", e);
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getPlanTransactions()
  }, [page, refresh])
  useEffect(() => {
    getActivityTransactions()
  }, [Activitypage, Activityrefresh])

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  const CustomTooltipForPieChart = ({ payload }) => {
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10,
          color: payload?.[0]?.payload?.fill
        }}>
        <p className='font-wt-600 font-size-14 m-0 p-0'>{payload?.[0]?.payload?.payload?.type}</p>
        <p
          className='font-wt-600 font-size-14 m-0 p-0'>{`$ ${payload?.[0]?.payload?.payload?.value}`}</p>
      </div>
    );
  };

  const CustomTooltipForCommission = ({ payload }) => {
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10
        }}>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>
          {`${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["label"]} : (${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["from"]} - ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["to"]}) `}</p>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>{`Invoice Commission`}</p>
        <p className='font-wt-600 text-48DA87 font-size-14 m-0 p-0'>{`Paid - $ ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["invPaid"]}`}</p>
        <p className='font-wt-600 text-F1C40F font-size-14 m-0 p-0'>{`Due - $ ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["invDue"]}`}</p>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>{`LC Commission`}</p>
        <p className='font-wt-600 text-48DA87 font-size-14 m-0 p-0'>{`Paid - $ ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["lcPaid"]}`}</p>
        <p className='font-wt-600 text-F1C40F font-size-14 m-0 p-0'>{`Due - $ ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["lcDue"]}`}</p>
      </div>
    );
  };

  const CustomTooltipForCommissionApplications = ({ payload }) => {
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10
        }}>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>
          {`${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["label"]} : (${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["from"]} - ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["to"]}) `}</p>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>{`Invoice Applications`}</p>
        <p className='font-wt-600 text-48DA87 font-size-14 m-0 p-0'>{`Paid - ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["invPaidApp"]}`}</p>
        <p className='font-wt-600 text-F1C40F font-size-14 m-0 p-0'>{`Due - ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["invDueApp"]}`}</p>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>{`LC Applications`}</p>
        <p className='font-wt-600 text-48DA87 font-size-14 m-0 p-0'>{`Paid - ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["lcPaidApp"]}`}</p>
        <p className='font-wt-600 text-F1C40F font-size-14 m-0 p-0'>{`Due - ${graphData?.commissionFromFinancierGraphData?.[payload?.[0]?.payload?.index]?.["lcDueApp"]}`}</p>
      </div>
    );
  };

  const CustomTooltipForCoinsUtilization = ({ payload }) => {
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10
        }}>
        <p className='font-wt-600 text-white font-size-14 m-0 p-0'>{`${graphData?.coinsSummaryGraphData?.[payload?.[0]?.payload?.index]?.["label"]} Coins Earning`}</p>
        <p className='font-wt-600 text2ECC71 font-size-14 m-0 p-0'>{`Exporter - ${graphData?.coinsSummaryGraphData?.[payload?.[0]?.payload?.index]?.["exporterCoinsUsed"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Importer - ${graphData?.coinsSummaryGraphData?.[payload?.[0]?.payload?.index]?.["importerCoinsUsed"]}`}</p>
        <p className='font-wt-600 textFFB801 font-size-14 m-0 p-0'>{`Financier - ${graphData?.coinsSummaryGraphData?.[payload?.[0]?.payload?.index]?.["financierCoinsUsed"]}`}</p>
      </div>
    );
  };

  async function downloadCommissionFromFinancierData() {
    if (!graphData?.commissionFromFinancierDbData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let csvString = "Financier,Application type & no,Finance Amount,Commission (%),Commission,Buyer Payment Date,Status\r\n"
      for (let index = 0; index < graphData?.commissionFromFinancierDbData.length; index++) {
        const element = graphData?.commissionFromFinancierDbData[index];
        let isInvoice = element.invCharges ? true : false
        let charges = isInvoice ? element.invCharges : element.lcCharges
        let status = isInvoice ? element.invoiceCommissionStatus : element.lcCommissionStatus
        let rowString = `${element.lenderName},
          ${isInvoice ? 'Invoice ' : 'LC '}Discounting - ${element.applicationNo},
          ${element.contractAmount} USD,
          ${charges.commissionPercentage},
          ${(charges.totalCharges / charges.commissionPercentage).toFixed(2)} USD,
          ${element.invoiceDueDate},
          ${status / 1 == 0 ? "Due" : "Paid"}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `CommissionFromFinancierData.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in CommissionFromFinancierData", error);
    }
  }

  async function downloadEarningThroughPlans() {
    if (!graphData.earningFromPlanTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.earningFromPlanTableData
      let csvString = "Type,Starter,Growth,Pro,Pro Plus,Top-Up\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `EarningThroughPlansData.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadEarningThroughPlans", error);
    }
  }

  async function downloadCoinsSummaryData() {
    if (!graphData.coinsSummaryTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.coinsSummaryTableData
      let csvString = "Type,Exporter,Importer,Financier\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[2][0]},${temp[2][1]},${temp[2][2]},${temp[2][3]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[3][0]},${temp[3][1]},${temp[3][2]},${temp[3][3]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[4][0]},${temp[4][1]},${temp[4][2]},${temp[4][3]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `CoinsSummaryData.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadCoinsSummaryData", error);
    }
  }



  function generateInvoiceSummary(resp) {
    if (graphConfiguration?.generateInvoiceForId?.length && resp?.commissionFromFinancierDbData?.length) {
      let summaryOfInvoiceCommission = { "totalFinanceAmount": 0, "totalCommissionPercentage": 0, "totalCommissionAmount": 0 }
      summaryOfInvoiceCommission["noOfFinanciers"] = graphConfiguration?.generateInvoiceForId?.length
      for (let index = 0; index < resp.commissionFromFinancierDbData.length; index++) {
        const element = resp.commissionFromFinancierDbData[index];
        let charges = element.invCharges || element.lcCharges
        summaryOfInvoiceCommission["totalFinanceAmount"] += element.contractAmount / 1
        summaryOfInvoiceCommission["totalCommissionPercentage"] += charges.commissionPercentage / 1
        summaryOfInvoiceCommission["totalCommissionAmount"] += (charges.totalCharges / charges.commissionPercentage)
      }
      summaryOfInvoiceCommission["totalFinanceAmount"] = summaryOfInvoiceCommission["totalFinanceAmount"].toFixed(2)
      summaryOfInvoiceCommission["totalCommissionAmount"] = summaryOfInvoiceCommission["totalCommissionAmount"].toFixed(2)
      summaryOfInvoiceCommission["totalCommissionPercentage"] = (summaryOfInvoiceCommission["totalCommissionPercentage"] / resp.commissionFromFinancierDbData.length).toFixed(2)

      // Creating Invoice data financier wise start
      for (let j = 0; j < resp.commissionFromFinancierDbData.length; j++) {
        const item = resp.commissionFromFinancierDbData[j];
        let charges = item.invCharges || item.lcCharges
        let status = item.invCharges ? item.invoiceCommissionStatus : item.lcCommissionStatus

        if (!summaryOfInvoiceCommission[item.lenderId]) {
          summaryOfInvoiceCommission[item.lenderId] = {
            data: [], item, totalCharges: 0, totalChargesCurrency: 'NA',
            totalCommissionPercentage: 0, commissionPayout: 0
          }
        }

        summaryOfInvoiceCommission[item.lenderId]["data"].push([item.sellerName, `${(item.invCharges ? 'Invoice Discounting - ' : 'LC Discounting - ')}${item.applicationNo}`, `${item.contractAmount} ${item.contractAmountCurrency}`,
        (charges.totalCharges).toFixed(2) + " " + charges.totalChargesCurrency,
        charges.commissionPercentage,
        (charges.totalCharges / charges.commissionPercentage).toFixed(2) + " " + charges.totalChargesCurrency, `${status / 1 == 1 ? "Paid" : "Due"}`,
        `${item.commissionDate ? moment(item.commissionDate).format("YYYY-MM-DD") : "NA"}`])

        summaryOfInvoiceCommission[item.lenderId]["totalCharges"] += charges.totalCharges
        summaryOfInvoiceCommission[item.lenderId]["totalChargesCurrency"] = charges.totalChargesCurrency
        summaryOfInvoiceCommission[item.lenderId]["totalCommissionPercentage"] += charges.commissionPercentage
        summaryOfInvoiceCommission[item.lenderId]["commissionPayout"] += (charges.totalCharges / charges.commissionPercentage)
      }
      // Creating Invoice data financier wise end
      return summaryOfInvoiceCommission
    }
    else {
      return {}
    }
  }

  async function changeCommissionStatus(indx, markAs) {
    let tempDbData = graphData.commissionFromFinancierDbData[indx]
    setshowLoader(true)
    call('POST', 'changeCommissionStatus', {
      markAs, invApplicationId: tempDbData.invCharges ? tempDbData.applicationId : undefined,
      lcApplicationId: tempDbData.lcCharges ? tempDbData.applicationId : undefined
    }).then(res => {
      setshowLoader(false)
      setGraphConfiguration({ ...graphConfiguration })
      toastDisplay("Status updated successfully", "success")
    })
  }



  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="adminPayments" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Payments"}
              userTokenDetails={userTokenDetails} />

            <div className='row'>
              <div className='col-md-6'>
                <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
                  <div className='col-md-12 text-center ml-9rem'>
                    <PieChartComponent customToolTip={CustomTooltipForPieChart} data={graphData?.piedata || []} dataKey="value"
                      colors={lanesColor} cornerRadius={20} totalVal={graphData.totalEarningLCInvoicePlan} />
                  </div>
                  <label className='text-center font-size-16 font-wt-600'>Earning Through Plans</label>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
                  <div>
                    <div class="dropdown">
                      <div className='d-flex align-items-center my-3 ml-3'>
                        <label className='text-left font-size-14 font-wt-600 mr-3 mb-3'>Payment Summary</label>
                        <NewSelect selectData={[{ name: "1 Month" }, { name: "2 Months" }, { name: "3 Months" }, { name: "4 Months" },
                        { name: "5 Months" }, { name: "6 Months" }, { name: "7 Months" },
                        { name: "8 Months" }, { name: "9 Months" }, { name: "10 Months" }, { name: "11 Months" }, { name: "12 Months" }]}
                          name={"paymentSummaryDuration"} value={graphConfiguration.paymentSummaryDuration}
                          onChange={handleGraphConfigurationChange} optionLabel={"name"} optionValue={"name"}
                        />
                      </div>
                    </div>
                  </div>
                  <ResponsiveContainer width="100%" height={250}>
                    <AreaChart
                      width={500}
                      height={400}
                      data={graphData?.planEarningBarChartData || []}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <XAxis dataKey="label" type="category" />
                      <YAxis type="number" tickFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                      <Tooltip
                      />
                      <defs>
                        <linearGradient id="fillcolor" x1="0" y1="0" x2="0" y2="1" >
                          <stop offset={"30%"} stopColor="#95E7FF" stopOpacity={1} />
                          <stop offset={"100%"} stopColor="#95E7FF" stopOpacity={0.3} />
                        </linearGradient>
                      </defs>
                      <Area type="linear" dataKey="value" stroke="#D0E9FF" fill="url(#fillcolor)" dot={{ stroke: '#1B94B7', strokeWidth: 5, fill: "#1B94B7" }} />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div>
                <div className='my-4'>
                  <label className='font-size-14 font-wt-700 my-2 text-decoration-underline'>Transaction</label>
                  <div className='filter-div ml-4'>
                    <Filter
                      filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                      showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                  </div>
                  <div id="transactionTableDiv">
                    <AccordionTable disableAction={false}
                      columns={[{ name: "", filter: false, width: '4%' },
                      { name: "Name", filter: true, width: '16%' },
                      { name: "Date", filter: true, width: '16%' },
                      { name: "Purpose", filter: true, width: '16%' },
                      { name: "Mode of Payment", filter: true, width: '16%' },
                      { name: "Amount", filter: true, width: '16%' },
                      // { name: "Type", filter: true },
                      { name: "", filter: false, width: '16%' }
                      ]}
                      data={tableData} overalldata={dbData}
                      expand={expandedData} tableExpand={tableExpand} />
                    <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage} />

                  </div>
                </div>
              </div>
              <div>
                <div className='my-4'>
                  <label className='font-size-14 font-wt-700 my-2 text-decoration-underline'>Activitys</label>
                  <div className='filter-div ml-4'>
                    <Filter
                      filterData={ActivityfilterData} setFilterData={setActivityFilter} showFilterBtn={true}
                      showResultPerPage={true} count={ActivityCount} filter={ActivityFilter} setFilter={setActivityFilter} refresh={Activityrefresh} setRefresh={setActivityrefresh} />
                  </div>
                  <div>
                    <NewTable
                      disableAction={true}
                      columns={[{ name: "" }, { name: "Date" },
                      { name: "Name" }, { name: "Purpose" }, { name: "Coins" }]}
                      data={ActivitytableData} />
                    <Pagination page={Activitypage} totalCount={ActivityCount} onPageChange={(p) => setActivitypage(p)} refresh={Activityrefresh} setRefresh={setActivityrefresh} perPage={filter.resultPerPage} />

                  </div>
                </div>
              </div>

              {/* more graphs */}

              {graphConfiguration.generateInvoice ? (
                <FinanceInvoiceModal limitinvoice={graphConfiguration.generateInvoice} closeSuccess={() => {
                  // setGraphConfiguration({ ...graphConfiguration, generateInvoice: false })
                  window.location.reload()
                }} headerTitle={`Generating Invoice For ${graphConfiguration?.generateInvoiceForLabel}`}>
                  <div >
                    <div className='row my-3'>
                      <div className='col-md-4'>
                        <MultipleSelect
                          Id="Financier"
                          Label="Financier"
                          optiondata={financierList}
                          onChange={(e) => {
                            let generateInvoiceForLabel = []
                            let generateInvoiceForId = []
                            for (let index = 0; index < e.length; index++) {
                              const element = e[index];
                              generateInvoiceForLabel.push(element.name)
                              generateInvoiceForId.push(element.id)
                            }
                            setGraphConfiguration({ ...graphConfiguration, generateInvoiceForLabel, generateInvoiceForId })
                          }}
                          value={graphConfiguration.generateInvoiceForId || []}
                          name="generateInvoiceForId"
                          labelKey={"name"}
                          valKey={"id"}
                        />
                      </div>
                      <div className='col-md-4'>
                        <NewInput type={"date"} name={"commissionFrom"} value={graphConfiguration.commissionFrom}
                          onChange={handleGraphConfigurationChange} />
                      </div>

                      <div className='col-md-4'>
                        <NewInput type={"date"} name={"commissionTo"} value={graphConfiguration.commissionTo}
                          onChange={handleGraphConfigurationChange} />
                      </div>
                    </div>
                    <button type="button"
                      onClick={() => {
                        if (graphConfiguration.generateInvoiceForId?.length) {
                          if (graphData.commissionFromFinancierDbData?.length) {
                            // show invoice
                            setGraphConfiguration({ ...graphConfiguration, generatedInvoice: true })
                          }
                          else {
                            toastDisplay("data not found to generate invoice", "info")
                          }
                        }
                        else {
                          toastDisplay('Select financier', "info")
                        }
                      }}
                      className={` border-0 mb-2 text-white enableQuotebtn`}>
                      {"Generate Invoice"}
                    </button>
                    <div className={"d-flex row justify-content-evenly px-4"} >
                      {generateInvoiceLabels.map((l, m) => {
                        return (
                          <label className="w-50 font-wt-500 font-size-14 text-color-value text-left mt-3" >{`${l.name} - `}<b>{graphData?.summaryOfInvoiceCommission?.[l.alt] || 'NA'}</b></label>
                        )
                      })}
                    </div>
                  </div>
                </FinanceInvoiceModal>
              ) : null}

              {graphConfiguration.generatedInvoice ? (
                <FinanceInvoiceModal modalSize={'lg'} limitinvoice={graphConfiguration.generatedInvoice} closeSuccess={() => {
                  setGraphConfiguration({ ...graphConfiguration, generatedInvoice: false })
                }}>
                  <>
                    {graphConfiguration.generateInvoiceForId.map((i, j) => {
                      // console.log("ssssssssssssssssssssssssssssssssssss", graphData.summaryOfInvoiceCommission);
                      let lenderWiseData = graphData.summaryOfInvoiceCommission?.[i]
                      if (lenderWiseData) {
                        return (
                          <>
                            <div id={"printCommissionInvoice" + j} className='px-4'>
                              <div className='d-flex flex-row justify-content-end mb-3'>
                                <button type="button"
                                  onClick={() => printDiv("printCommissionInvoice" + j, `Commission-Invoice-${lenderWiseData.item.lenderName}`, [])}
                                  className={` border-0 mb-2 text-white enableQuotebtn`}>
                                  {"Print Invoice"}
                                </button>
                              </div>
                              <div className='d-flex flex-row justify-content-between'>
                                <div>
                                  <img width={"100px"} height={"55px"} src={"assets/images/logo_1.png"} />
                                </div>
                                <div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/call-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>+ 91 84509 69138</label>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/mail-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>info@tradereboot.com</label>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <div><img width={"20px"} height={"20px"} src={"assets/images/web-vector.png"} /></div>
                                    <label className='text-color-value font-size-14 font-wt-600 text-center pl-2'>www.tradereboot.com</label>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <label className='text-color-value font-size-18 font-wt-600 text-center'>Invoice</label>
                              <div className='d-flex flex-row justify-content-between'>
                                <div>
                                  <label className='text-color-value font-size-12 font-wt-600 w-100 text-left'>To</label>
                                  <label className='text-color-value font-size-14 font-wt-600 w-100 text-left'>{lenderWiseData.item.lenderName}</label>
                                  <label className='text-color-value font-size-12 font-wt-600 w-100 text-left'>{lenderWiseData.item.lenderAddress}</label>
                                </div>
                                <div>
                                  <label className='text-color-value font-size-12 font-wt-600'>{`Date: ${moment().format("YYYY-MM-DD")}`}</label>
                                </div>
                              </div>
                              <label className='text-color-value font-size-14 font-wt-600 text-center my-4'>{`Commission payout from ${graphConfiguration.commissionFrom} - ${graphConfiguration.commissionTo}`}</label>
                              <div>
                                <NewTable
                                  disableAction={true}
                                  data={lenderWiseData.data}
                                  columns={[{ name: "Exporter Name" }, { name: "Application Type & No." }, { name: "Invoice Amount" }, { name: "Charges Amount" },
                                  { name: "Commission (%)" }, { name: "Commission Amount" }, { name: "Commission Status" }, { name: "Commission Paid On" }]} />
                                <div className='d-flex flex-row justify-content-end'>
                                  <table className='border'>
                                    <tbody className='border'>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>Total Charges</th>
                                        <td className='font-size-14 font-wt-600'>{`${(lenderWiseData.totalCharges).toFixed(2)} ${lenderWiseData.totalChargesCurrency}`}</td>
                                      </tr>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>Commission (%)</th>
                                        <td className='font-size-14 font-wt-600'>{`${(lenderWiseData.totalCommissionPercentage / lenderWiseData.data.length).toFixed(2)}`}</td>
                                      </tr>
                                      <tr className='border'>
                                        <th scope="row" className='font-size-14 font-wt-600'>Total Payout</th>
                                        <td className='font-size-14 font-wt-600'>{`${(lenderWiseData.commissionPayout).toFixed(2)} ${lenderWiseData.totalChargesCurrency}`}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className='my-5'>
                              <hr />
                              <hr />
                            </div>
                          </>
                        )
                      }
                    })}
                  </>
                </FinanceInvoiceModal>
              ) : null}

              <label className='font-size-14 font-wt-700 mt-2 mb-3 text-decoration-underline'>Commission From Financier</label>

              <div className='row  mb-3'>
                <div className='w-80 align-items-center d-flex'>
                  <div className='w-auto pr-3'>
                    <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"commissionFrom"} value={graphConfiguration.commissionFrom}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"commissionTo"} value={graphConfiguration.commissionTo}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                </div>
                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, commissionGraphMode: !graphConfiguration.commissionGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.commissionGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className='pr-3'>
                    <img
                      onClick={downloadCommissionFromFinancierData}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                  {/* <div className=''>
                    <button type="button"
                      onClick={() => {
                        setGraphConfiguration({ ...graphConfiguration, generateInvoice: true })
                      }}
                      className={` border-0 mb-2 text-white enableQuotebtn`}>
                      {"Generate Invoice"}
                    </button>
                  </div> */}
                </div>
              </div>

              {!graphConfiguration.commissionGraphMode ? (
                <div>
                  <NewTable
                    data={graphData.commissionFromFinancierTableData || []}
                    columns={[{ name: "Financier" }, { name: "Application type & no" }, { name: "Finance Amount" },
                    { name: "Commission (%)" }, { name: "Commission" }, { name: "Buyer Payment Date" }, { name: "Status" }]}
                    options={[
                      { name: "Mark as Paid", onClick: (indx) => changeCommissionStatus(indx, 1) },
                      { name: "Mark as Due", onClick: (indx) => changeCommissionStatus(indx, 0) }]}
                  />
                </div>
              ) : (
                <div className='card p-3 dashboard-card border-0 borderRadius h-100 d-flex flex-row pt-5 pb-4 mx-3' >
                  <div className='col-6'>
                    <ResponsiveContainer width={"100%"} height={200}>
                      <LineChart
                        width={500}
                        height={300}
                        data={graphData.commissionFromFinancierGraphData || []}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}

                      >
                        <XAxis dataKey="label" type="category" />
                        <YAxis type="number" tickFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                        <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltipForCommission />} />
                        <Legend formatter={(value, name) => [label[value]]} />
                        <Line dataKey={"paid"} stroke="#2ECC71" strokeWidth={"2px"} activeDot={{ r: 8 }} />
                        <Line dataKey={"due"} stroke="#FFB801" strokeWidth={"2px"} activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                    <label className='text-color-value font-size-16 font-wt-600 w-100 text-center'>Commission in ($)</label>
                  </div>
                  <div className='col-6'>
                    <ResponsiveContainer width={"100%"} height={200}>
                      <LineChart
                        width={500}
                        height={300}
                        data={graphData.commissionFromFinancierGraphData || []}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}

                      >
                        <XAxis dataKey="label" type="category" />
                        <YAxis type="number" tickFormatter={(value) => Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                        <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltipForCommissionApplications />} />
                        <Legend formatter={(value, name) => [label[value]]} />
                        <Line dataKey={"paidApp"} stroke="#2ECC71" strokeWidth={"2px"} activeDot={{ r: 8 }} />
                        <Line dataKey={"dueApp"} stroke="#FFB801" strokeWidth={"2px"} activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                    <label className='text-color-value font-size-16 font-wt-600 w-100 text-center'>No. of application</label>
                  </div>
                </div>
              )}

              <label className='font-size-14 font-wt-700 mt-5 mb-3 text-decoration-underline'>Earning Through Plans</label>
              <div className='row  mb-3'>
                <div className='w-70 align-items-center d-flex'>
                  <div className='w-auto pr-3'>
                    <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"earningFrom"} value={graphConfiguration.earningFrom}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"earningTo"} value={graphConfiguration.earningTo}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                </div>
                <div className='w-30 align-items-center d-flex justify-content-end'>
                  <div className='card text-center'>
                    <label className='font-size-16 font-wt-600 px-3'>{`Total - ${graphData.totalCommissionThroughPlan} USD`}</label>
                  </div>
                  <div className='px-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, earningGraphMode: !graphConfiguration.earningGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.earningGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={downloadEarningThroughPlans}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>
              </div>
              {!graphConfiguration.earningGraphMode ? (
                <div>
                  <NewTable
                    disableAction={true}
                    data={graphData.earningFromPlanTableData || []}
                    columns={[{ name: "Type" }, { name: "Starter" }, { name: "Growth" },
                    { name: "Pro" }, { name: "Pro Plus" }, { name: "Top-Up" }]}
                  />
                </div>
              ) : (
                <div className='card p-3 dashboard-card border-0 borderRadius h-100 d-flex flex-row pt-5 pb-4 mx-3' >
                  <div className='col-6'>
                    {graphData?.lcCommissionFunnelGraphData?.length ? (
                      <ResponsiveContainer width={"100%"} height={400}>
                        <FunnelChart >
                          <Tooltip />
                          <Funnel
                            labelLine={true}
                            data={graphData.lcCommissionFunnelGraphData} dataKey="value" nameKey="name">
                            <Legend />
                            {invoiceFunnelGraphColors.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry} />
                            ))}
                          </Funnel>
                        </FunnelChart>
                      </ResponsiveContainer>
                    ) : null}
                    <label className='text-color-value font-size-16 font-wt-600 w-100 text-center'>LC Plans</label>
                  </div>
                  <div className='col-6'>
                    {graphData?.invoiceCommissionFunnelGraphData?.length ? (
                      <ResponsiveContainer width={"100%"} height={400}>
                        <FunnelChart >
                          <Tooltip />
                          <Funnel
                            labelLine={true}
                            data={graphData.invoiceCommissionFunnelGraphData} dataKey="value" nameKey="name">
                            <Legend />
                            {invoiceFunnelGraphColors.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry} />
                            ))}
                          </Funnel>
                        </FunnelChart>
                      </ResponsiveContainer>
                    ) : null}
                    <label className='text-color-value font-size-16 font-wt-600 w-100 text-center'>Invoice Plans</label>
                  </div>
                </div>
              )}

              <label className='font-size-14 font-wt-700 mt-5 mb-3 text-decoration-underline'>Coins Summary</label>
              <div className='row  mb-3'>
                <div className='w-70 align-items-center d-flex'>
                  <div className='w-auto pr-3'>
                    <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"coinsSummaryFrom"} value={graphConfiguration.coinsSummaryFrom}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                  <div className='w-20 pr-3'>
                    <NewInput type={"date"} name={"coinsSummaryTo"} value={graphConfiguration.coinsSummaryTo}
                      onChange={handleGraphConfigurationChange} />
                  </div>
                </div>
                <div className='w-30 align-items-center d-flex justify-content-end'>
                  <div className='card text-center'>
                    <label className='font-size-16 font-wt-600 px-3'>{`Total - ${graphData.totalCoinsSummary} Coins`}</label>
                  </div>
                  <div className='px-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, coinsGraphMode: !graphConfiguration.coinsGraphMode }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.coinsGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={downloadCoinsSummaryData}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>
              </div>
              {!graphConfiguration.coinsGraphMode ? (
                <div>
                  <NewTable
                    disableAction={true}
                    data={graphData.coinsSummaryTableData || []}
                    columns={[{ name: "Type" }, { name: "Exporter" }, { name: "Importer" },
                    { name: "Financier" }]}
                  />
                </div>
              ) : (
                <div className='card p-3 dashboard-card border-0 borderRadius h-100 d-flex flex-row pt-5 pb-4 mx-3' >
                  <div className='col-12'>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        width={500}
                        height={300}
                        data={graphData.coinsSummaryGraphData || []}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <XAxis dataKey="label" />
                        <YAxis type="number" tickFormatter={(value) => Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                        <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltipForCoinsUtilization />} />
                        <Legend formatter={(value, name) => [label[value]]} />
                        <Bar dataKey="exporterCoinsUsed" fill="#2ECC71" width={10} display="Exporter" />
                        <Bar dataKey="importerCoinsUsed" fill="#5CB8D3" width={10} display="Importer" />
                        <Bar dataKey="financierCoinsUsed" fill="#FFB801" width={10} display="Financier" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}

            </div>
          </main>
        </div>
      </div>


    </div>

  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AdminPayments)