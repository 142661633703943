import React, { useEffect } from 'react';
import Chart from "chart.js"

const ContractLineChart = ({ data }) => {
  useEffect(() => {
    var ctx = document.getElementById('line-area').getContext('2d');
    window.myPie = new Chart(ctx, getConfig())
  })

  function getConfig() {
    var config = {
      type: 'line',
      data: {
        labels: data.dates,
        datasets: [{
          label: 'Payments',
          backgroundColor: '#b9e4e6',
          borderColor: '#227b82',
          data: data.amount,
          fill: true,
          pointBackgroundColor: '#227b82',
          pointRadius: '6',
        }]
      },
      options: {
        responsive: true,
        elements: {
          line: {
            tension: 0
          }
        },
        title: {
          display: true,
          text: 'Payments Statistic For The Financial Year 2020'
        },
        scales: {
          xAxes: [{
            title: 'Display: true',
            gridLines: {
              display: true
            },
          }],
          yAxes: [{
            title: 'Display: true',
            gridLines: {
              display: true
            }
          }]
        }
      }
    };
    return config;
  }

  return (
    <canvas id="line-area" height="100px"></canvas>
  )
}
export default ContractLineChart;
