
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import toastDisplay from "../../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob } from "../../../utils/myFunctions";
import call from '../../../service';
import config from "../../../config.json"
import { FileInput } from "../../../utils/FileInput";
import SignDocument from "./SignDocument";
import InputCurrency from "./InputCurrency";
import { ToastContainer } from "react-toastify";
import avatarUrl from "../../../utils/makeAvatarUrl";
import SubmitModal from "./submitModal";
import TooltipModal from "./Tooltipmodal";
import { enableStennReqDocFlow, environment } from "../../../urlConstants";
import ChatBoxPopUp from "../../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../contract/components/financeinvoiceModal";
import { AddMoreDocumentHandler } from "../../addMoreDocumentHandler";
import { InputWithSelect, NewInput } from "../../../utils/newInput";
import SliderComponent from "../../viewProfile/components/SliderComponent";
import SingleSliderComponent from "../../viewProfile/components/SingleSliderComponent";
import { useHistory } from "react-router";
import { NewTable } from "../../../utils/newTable";
import ChatBoxPopUp2 from "../../chatRoom/components/ChatBoxPopUp2";
import { quoteViewColumns } from "../../Dashboard/TableJson";
import NewTablev2 from "../../../utils/newTablev2";
import { FormProgressBar } from "../../CommonComponent/FormProgressBar";

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "Limit Required", val: "requiredLimit", unit: "currency" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "Expected date of Shipment", val: "expShipmentDate" },
  { "name": "Buyer Annual Turn Over", val: "annualTurnOver" }

]

const reviewForm2 = [
  { "name": "Buyer Name", val: "buyerName", unit: "buyerNameTitle" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },
  { "name": "Industry Type", val: "industryType" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },
  { "name": "Limit Required", val: "LimitRequired" },
]

// const financierData = [
//   { id: 100, name: "Stenn", icon: "stenn.png", rating: 4.6, reviews: 126 },
//   { id: 200, name: "Modifi", icon: "modifi.png", rating: 1, reviews: 130 },
// ]

// const IncoTerms = [
//   { name: "EXW" },
//   { name: "FCA" },
//   { name: "FAS" },
//   { name: "FOB" },
//   { name: "CFR" },
//   { name: "CIF" },
//   { name: "CPT" },
//   { name: "CIP" },
//   { name: "DAP" },
//   { name: "DPU" },
//   { name: "DDP" }
// ]

const financeSelected = [
  { name: "Drip" },
];

const financeSelected2 = [
  { name: "Stenn" },
  { name: "Drip" },
  { name: "Modifi" },
  { name: "ICICI" },
  { name: "Federal" },
];



const SeeQuotesDetails = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(!isAdmin ? (onlyView ? 0 : 1) : defaultTabForAdmin / 1)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30 })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])
  const [additionalReqDetails, setAdditionalReqDetails] = useState([])
  const [overlay, setOverlay] = useState(false);
  const [enableStennQuote, toggleStennQuote] = useState(environment === "prod" ? false : true)
  const [seeRateCalculator, toggleSeeRateCalculator] = useState(false)
  const [financiers, setFinanciers] = useState([])
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [availableTermSheets, setAvailableTermSheets] = useState([])
  const [tableDocsData, setTableDocsData] = useState([])
  const [multInvPo, setMultInvPo] = useState([])

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const addBuyerTabs = isAdmin ? [
    { name: "Application form" },
    { name: "Documents" },
    { name: "Quote" }
  ] : onlyView ? [
    { name: "Application form" }
  ] : [
    { name: "Application form" },
    { name: "Select financer" },
    { name: "Documents" },
    { name: "Review" },
  ]

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [moreDocs, setMoreDocs] = useState([])
  const [refresh, setRefresh] = useState(0)


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [slice, setSlice] = useState(1);

  const Next = () => {
    setFirstindex(firstindex + 4);
    setLastindex(lastindex + 4);
    setSlice(slice + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 4);
    setLastindex(lastindex - 4);
    setSlice(slice - 1);
  };

  async function asyncUseEffectFunction() {
    // if (userTypeId / 1 == 1) {
    setshowLoader(true)
    call("POST", 'getAllAvailableTermSheets', { applicationId: localStorage.getItem("applicationId") }).then(async res => {
      let tempDocArr = []
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        tempDocArr.push({
          lenderName: element.company_name,
          file: await getDocDetails(element.tbl_doc_id),
          lender_id: element.lender_id
        })
      }
      setshowLoader(false)
      setAvailableTermSheets(tempDocArr)
    }).catch(err => {
      console.log("error in getAllAvailableTermSheets", err);
      setshowLoader(false)
    })
    // }
  }

  useEffect(() => {
    formatInvPoDocsForReview()
  }, [data, multInvPo])

  async function formatInvPoDocsForReview() {
    let row = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      row.push(["Invoice Details " + (index + 1)])
      for (let j = 0; j < element.invoices.length; j++) {
        if (data?.["invoiceDocument" + index + ":" + j]?.name) {
          row.push([`Invoice - ${data["invoiceRefrenceNo" + index + ":" + j]}`, `${data["currency" + index + ":" + j]} ${data["contractAmount" + index + ":" + j]}`,
          <FileInput
            isEditable={false}
            value={data["invoiceDocument" + index + ":" + j]}
          />,
          <>
            {
              !data?.["invoiceDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["invoiceDocument" + index + ":" + j], docSignName: ["invoiceDocument" + index + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["invoiceDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      for (let j = 0; j < element.purchase_orders.length; j++) {
        if (data?.["poDocument" + index + ":" + j]?.name) {
          row.push([`PO - ${data["poRefrenceNo" + index + ":" + j]}`, `-`,
          <FileInput
            isEditable={false}
            value={data["poDocument" + index + ":" + j]}
          />,
          <>
            {
              !data?.["poDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["poDocument" + index + ":" + j], docSignName: ["poDocument" + index + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["poDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      if (row.length) {
        let lastElementInArr = row[row.length - 1]
        lastElementInArr[100] = "To Add Line"
      }
    }
    setTableDocsData([...row])
  }


  useEffect(() => {
    asyncUseEffectFunction()
  }, [])

  console.log("setAvailableTermSheets", availableTermSheets);

  useEffect(() => {
    call('POST', 'getFinanciersForInvoice', { applicationId: localStorage.getItem("applicationId") }).then(res => {
      setFinanciers(res)
    })
    // Enable stenn quote start
    call('POST', 'isStennApprovedDeal', { invRefNo: localStorage.getItem("invIfAppliedNo"), emailId: userEmail }).then((result) => {
      console.log("isStennApprovedDeal", result);
      toggleStennQuote(true)
    }).catch((e) => {
      console.log('error in isStennApprovedDeal', e);
    })
    // Enable stenn quote end
    if (!onlyView && !isAdmin && localStorage.getItem("overlayDisplayedForApplicationId") / 1 != localStorage.getItem("applicationId") / 1) {
      // setOverlay(true)
    }
    call('POST', 'getRecommendedFinanciers', { forInvoice: true }).then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    //Get Buyer list start
    call('POST', 'getBuyersDetailWithDoc', { buyersId: buyer, applicationId: localStorage.getItem("applicationId") }).then((result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setDbData(result);
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });
    //Get buyer list end
  }, [])

  useEffect(() => {
    if (buyer && dbData.length) {
      let event = {
        target: {
          name: "selectedBuyer",
          value: buyer
        }
      }
      handleChange(event)
    }
  }, [buyer, dbData])


  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData[0]
      let buyers_credit = selectedBuyer.buyers_credit ? JSON.parse(selectedBuyer.buyers_credit) : []
      let buyersRemark = selectedBuyer.buyersRemark || {}

      for (let index = 0; index < Object.keys(buyersRemark).length; index++) {
        const element = Object.keys(buyersRemark)[index];
        if (buyersRemark[element]["lenderName"].toLowerCase().includes("stenn")) {
          buyersRemark[element]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (buyersRemark[element]["lenderName"].toLowerCase().includes("modifi")) {
          buyersRemark[element]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }

      console.log("bbbbbbbbbbbbbbbbbbb", buyersRemark);

      for (let index = 0; index < buyers_credit.length; index++) {
        const element = buyers_credit[index];
        if (element.lender_name.toLowerCase().includes("stenn")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (element.lender_name.toLowerCase().includes("modifi")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }

      setshowLoader(true)

      let tempLimitDocsDetails = {}
      let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: localStorage.getItem("applicationId") })
      let totalInvoiceDetails = limitDocs.maxIndx
      let maxInvPoDocs = limitDocs.maxSubIndx

      for (let index = 0; index < limitDocs.data.length; index++) {
        const element = limitDocs.data[index];
        tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
        if (element.attributes) {
          tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
        }
      }
      setshowLoader(false)
      setMultInvPo([...new Array(totalInvoiceDetails).fill({ invoices: new Array(maxInvPoDocs).fill(null), purchase_orders: new Array(maxInvPoDocs).fill(null) })])
      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        ...tempLimitDocsDetails, buyers_credit, buyersRemark
      })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  const closeFn = () => {
    setModal(true);
  }



  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (tab === 3) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("userName", userName)
      formData.append("buyerId", data.id)
      if (!data.invoiceDocument?.fromDb) {
        formData.append("previousInvoiceDocument", data.invoiceDocument)
      }
      if (!data.poDocument?.fromDb) {
        formData.append("previousPODocument", data.poDocument)
      }
      formData.append("buyersDocId", data.buyersDocId)
      formData.append("userId", userId)
      formData.append("selectedFinancier", data.selectedFinancier)
      formData.append("selectedQuote", JSON.stringify(data.selectedQuote))
      formData.append("applicationId", localStorage.getItem("applicationId"))
      formData.append("isSelectQuoteStage", true)
      if (data.invoiceDocument?.signatureId) {
        formData.append("invoiceDocSignId", data.invoiceDocument?.signatureId)
      }
      if (data.poDocument?.signatureId) {
        formData.append("poDocSignId", data.poDocument?.signatureId)
      }
      addMoreDoc.forEach((k, index) => {
        if (data["otherDoc" + index]) {
          formData.append("otherDoc" + index, data["otherDoc" + index])
          if (data["otherDoc" + index]["signatureId"]) {
            formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
          }
        }
      })
      call('POST', 'submitInvQuotation', formData).then((result) => {
        // console.log('running submitInvQuotation api-->', result);
        toastDisplay("Quotation updated successfully", "success", () => {
          if (data.selectedFinancier / 1 == 51) {
            window.location = "/apply-finance"
          }
          else {
            window.location = "/invoicecontract"
          }
        })
        setshowLoader(false)
      }).catch((e) => {
        // console.log('error in submitInvQuotation', e);
        toastDisplay("Something went wrong", "error")
        setshowLoader(false)
      });
      return null
    }
    if (!Object.keys(err).length) {
      setTab(tab + 1)
    }
    setErrors(err)

  }

  useEffect(() => {
    if (data.invoiceAmount && data.termsOfPaymentInDays) {
      getRateCalculatorResult()
    }
  }, [seeRateCalculator])

  async function getRateCalculatorResult() {
    let rateCalculatorResult = {}
    setshowLoader(true)
    for (let index = 0; index < financiers.length; index++) {
      const element = financiers[index];
      let dbRes = await call('POST', 'getLenderQuoteInformation', { userId: element.id })
      // console.log("getRateCalculatorResult================>", dbRes);
      if (dbRes?.[0]?.["rate"]) {
        rateCalculatorResult[element.id] = getInvoiceTotalCharges(dbRes[0]["rate"], data.invoiceAmount, data.termsOfPaymentInDays).toFixed(2)
      }
    }
    setshowLoader(false)
    setData({ ...data, rateCalculatorResult })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', localStorage.getItem('applicationId'))
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    if (userTypeId / 1 == 1) {
      formdata.append("userTypeId", userTypeId)
      formdata.append("sellerId", localStorage.getItem("manualUserId"))
    }
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }


  return (
    <>
      <div className={`modal fade ${seeRateCalculator && "show"}`} style={seeRateCalculator ? { display: "block", "zIndex": '1000001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="">
                <i onClick={() => { toggleSeeRateCalculator(false) }}
                  class="fas fa-2x fa-arrow-left mx-1 icon-color cursor"></i>
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Estimated Charges</label>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="position-relative">
                <InputWithSelect
                  selectData={most_used_currencies}
                  selectName={"invoiceAmountCurrency"}
                  selectValue={data.invoiceAmountCurrency}
                  optionLabel={'code'}
                  optionValue={'code'}
                  name={"invoiceAmount"}
                  value={data.invoiceAmount}
                  label={"Invoice Amount"}
                  onChange={handleChange}
                />
              </div>
              <div className="px-2 mt-4 mb-2">
                <SingleSliderComponent label={"Terms of Payment "}
                  value={data.termsOfPaymentInDays}
                  setValue={(val) => {
                    setData({ ...data, termsOfPaymentInDays: val })
                  }} min={30} max={180}
                  steps={15} />
              </div>
              <div className="my-2">
                <button type="button"
                  onClick={() => getRateCalculatorResult()}
                  className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                  {"Calculate"}
                </button>
              </div>

              <div className="my-3">
                <label
                  className="font-size-14 font-wt-600 text-color-value text-decoration-underline "
                >Estimated Charges</label>
              </div>

              <div>
                {financiers?.length ? financiers.map((item, index) => {
                  if (data.buyers_credit?.filter((i) => {
                    if (i.lender_id / 1 == item.id / 1) {
                      return true
                    }
                  })?.[0]) {
                    return (
                      <div
                        className="eximBankQuoteCard px-3 py-2 mb-3 d-flex justify-content-between"
                      >
                        <div className="w-50">
                          {finLogoByUserId[item["id"]] ?
                            <img className="mr-2" src={finLogoByUserId[item["id"]]} /> : null}
                          <label
                            className="font-size-14 font-wt-600 text-color-value "
                          >{item.name}</label>
                        </div>
                        <label
                          className="font-size-15 font-wt-600 text-color1 "
                        >{data.invoiceAmountCurrency + " " + (data?.["rateCalculatorResult"]?.[item.id] || 'NA')}</label>
                      </div>
                    )
                  }
                }) : null}
              </div>

            </div>
          </div>
        </div>
      </div>
      <AddMoreDocumentHandler hide={true}
        purpose={"ApplyForLimitInvoice"} purposeId={data.id}
        userTokenDetails={userTokenDetails} onChange={(docs) => { setMoreDocs(docs) }}
      />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className={`${overlay && 'overlay_in'}`}></div>

        <div className="row">
          <SideBarV2 state={userTypeId / 1 == 1 ? 'invoiceLimit' : userTypeId / 1 == 20 ? "CPinvoiceLimit" : "invoice_Quotes"} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={isAdmin ? "Invoice Discounting > Limit > Application Details" : "Quote Details"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (isAdmin) {
                            setTab(index)
                          }
                          else {
                            handleValidation(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => {
                      if (isAdmin) {
                        setTab(index)
                      }
                      else {
                        handleValidation(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='ulFormsContainer'>
                    <div className="row">
                      <div className='w-80'>
                        <label className='font-wt-500 font-size-16 p-0'><u>Buyer Details</u>
                          {/* <img
                          onClick={() => { window.location = `/buyerManagement?editBuyer=${data.selectedBuyer}` }}
                          className="ms-3 cursor"
                          src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div >

                      {isAdmin ?
                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                          <div className="d-flex gap-4 my-3">
                            {localStorage.getItem('selectedLenderName')?.split(",").map((i, j) => {
                              return (
                                <label
                                  className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                  {i}
                                </label>
                              )
                            })}
                          </div>
                        </div> : null}

                      {!isAdmin ? (
                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u>
                            {/* <img className="ms-3 cursor"
                            onClick={() => { window.location = `/buyerManagement?editBuyer=${data.selectedBuyer}` }}
                            src={"assets/images/edit.png"} alt="edit" /> */}
                          </label>
                          <div className='row mt-2'>
                            <div className="my-4">
                              <NewTable
                                hideRowBorderDefault={true}
                                disableAction={true}
                                columns={[
                                  { name: "Document Name" },
                                  { name: "Invoice Amount" },
                                  { name: "Attach Document" },
                                  { name: "" }
                                ]}
                                data={tableDocsData}
                              />
                            </div>

                            {/* <AddMoreDocumentHandler displayHorizontal={true} hideSignDocument={true}
                              purpose={"ApplyForLimitInvoice"} purposeId={data.id}
                              userTokenDetails={userTokenDetails} onChange={(docs) => { setMoreDocs(docs) }}
                            /> */}

                          </div>
                        </div>
                      ) : null}


                    </div >
                  </div >
                ) : null}
                {
                  ((tab === 1 && !isAdmin) || (tab === 2 && isAdmin)) ? (
                    <div className='ulFormsContainer position-relative'>
                      <div
                        className="cursor"
                        onClick={() => {
                          setQuoteView(quoteView === "card" ? "table" : 'card')
                        }}
                        style={{ position: 'absolute', right: '5.5rem', top: quoteView === "card" ? '2.7rem' : '3.4rem' }}
                      >
                        <img src={quoteView != "card" ? "assets/images/card_icon.svg" : "assets/images/table_icon.svg"} />
                      </div>
                      <div
                        className="cursor"
                        onClick={() => {
                          toggleSeeRateCalculator(true)
                        }}
                        style={{ position: 'absolute', right: '2rem', top: '2.7rem' }}
                      >
                        <img src={"assets/images/calculator_updated_icon.svg"} />
                      </div>
                      {!isAdmin ? (
                        <div className="text-center lineheight19">
                          <label className='font-wt-500 font-size-16 mb-0 letter-spacing05'>Select financer</label><br />
                          <small className="selectfinacne-subheading">Quotes for Invoice discounting</small>
                        </div>
                      ) : null}
                      {quoteView === "card" ? (
                        <div id={"scrollContainerId"} style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className="row align-items-center d-flex w-100 my-4">
                          {data.buyers_credit && data.buyers_credit.length ? data.buyers_credit.map((key, index) => {
                            return (
                              <div
                                ref={(el) => quoteCardRef.current[index] = el} className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                                <div className={`text-center card py-4 pb-0 px-3
                              ${key.financierAction != "Approved" ? "limitdeniedcontract" : "selectfinancercardrec"}`}
                                >
                                  <>
                                    <p className={`cursor ${recommendedFinanciers.includes(key.lender_id / 1)
                                      && key.financierAction === "Approved" ? ' mt-5 ' : ' mt-1 '} pt-2 mb-1 d-flex flex-row justify-content-center align-items-center`}><img className="mr-2"
                                        src={key.logo} alt="" />
                                      <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                                      {/* <div
                                      style={overlay ? {
                                        "background": "white",
                                        "position": "relative",
                                        "zIndex": 10001,
                                        // "padding": "1rem 0rem 1rem 0rem",
                                        "borderRadius": "1rem",
                                      } : {}}> */}
                                      <div className="" style={{ position: "relative", zIndex: "100002", backgroundColor: 'white', marginLeft: 2, borderRadius: 1000, padding: 10 }} >
                                        <img
                                          // onClick={() => { setTooltip(true); setData({ ...data, showRemark: key.assignRemark, showLenderName: key.lender_name }) }}
                                          onClick={async () => {
                                            localStorage.setItem("financeLimit", key.financeLimit)
                                            localStorage.setItem("lenderId", key.lender_id)
                                            localStorage.setItem("buyerId", data.id)
                                            localStorage.setItem("buyerData", JSON.stringify({
                                              buyerName: data.buyerName, buyerPosition: data.buyerPosition,
                                              nameAuthorizedSignatory: data.nameAuthorizedSignatory, buyerEmail: data.buyerEmail,
                                              buyerPhoneCode: data.buyerPhoneCode, buyerPhone: data.buyerPhone,
                                              buyerIdentifier: data.identifier, incoTerms: data.incoTerms,
                                              buyerWebsite: data.buyerWebsite, buyerAddress: data.buyerAddress,
                                              buyerCountry: data.buyerCountry, buyerDUNSNo: data.buyerDUNSNo,
                                              previousAnnualSale: data.previousAnnualSale, currentAnnualSale: data.currentAnnualSale,
                                              termsOfPayment: data.termsOfPayment, productDetails: data.productDetails,
                                              buyersDocId: data.buyersDocId
                                            }))
                                            localStorage.setItem("lenderName", key.lender_name)
                                            localStorage.setItem("lenderRemark", key.assignRemark)
                                            setshowLoader(true)
                                            let additionalReqResp = await call('POST', 'isAdditionalDetailsReqByFinancer', {
                                              lenderIdArr: [{ lender_id: key.lender_id }],
                                              applicationId: localStorage.getItem('applicationId')
                                            })
                                            // console.log("additionalReqResp========", additionalReqResp);
                                            setAdditionalReqDetails(additionalReqResp)
                                            setshowLoader(false)
                                            if (additionalReqResp?.[0]) {
                                              localStorage.setItem("additionalReqObj", JSON.stringify(additionalReqResp?.[0]))
                                            }
                                            else {
                                              localStorage.removeItem("additionalReqObj")
                                            }
                                            window.location = `/docsRequested`
                                          }}
                                          src={"assets/images/warning.png"} alt="" />
                                      </div>

                                      {/* </div> */}
                                      {/* <a href="/docsRequested" >
                                      <img src={"assets/images/warning.png"} alt="" />
                                    </a> */}
                                    </p>
                                  </>
                                  {key.financierAction != "Approved" ? (
                                    <>
                                      <div className="limitDeniedCard">
                                        <div className="mb-2">
                                          <img src={"assets/images/block.png"} height={25} width={25} />
                                        </div>
                                        <div className="font-size-14 font-wt-600">
                                          Limit Denied
                                        </div>
                                      </div>
                                    </>)
                                    : null}
                                  {recommendedFinanciers.includes(key.lender_id / 1) && key.financierAction === "Approved" ? (
                                    <div style={{ right: '-1.4rem' }} className="position-absolute" >
                                      <img src="assets/images/recommended_label_updated.svg" />
                                    </div>
                                  ) : null}

                                  {key.financierAction != "Approved" ? <></> : (
                                    <>
                                      <p className="font-size-12 mb-1 font-wt-600 text-color1">
                                        {`Limit ${key.financeLimit} ${key.financeLimitCurrency} upto ${key.discountingPercentage} % of invoice`}</p>
                                      <p className="font-size-14 mb-1">
                                        {`Offer Validity : ${key.offerValidity}`}</p>
                                      <p style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                                      <div className="mt-2 lineheight19">
                                        <p className="mb-0  font-size-13 font-wt-500">Factoring Fees</p>
                                        <p className="font-size-16">{key.factoringFees ? (key.factoringFees + " " + key.factoringFeesCurrency + " " +
                                          (key.factoringFeesCurrency === "%" ? `(${key.factoringFeesType || "Annually"})` : "")) : "NA"}</p>
                                      </div>
                                      <div className="mt-0 lineheight19">
                                        <p className="mb-0 font-size-13 font-wt-500">Interest Rate
                                        </p>
                                        {key.termSofrType ? (
                                          <span className="selectfinacne-subheading font-size-12">{"(" + key.termSofrType + " " + key.termSofr + ")"}</span>) : null}
                                        <p className="font-size-16">{key.interestRate ? (key.interestRate + " % " + `(${key.interestRateType || "Annually"})`) : "NA"}</p>
                                      </div>
                                      <div className="mt-0 lineheight19">
                                        <p className="mb-0 font-size-13 font-wt-500">One time set-up fees</p>
                                        {/* <span className="selectfinacne-subheading font-size-10">(negotiable)</span> */}
                                        <p className="font-size-16">{key.setupFees ? (key.setupFees + " " + key.setupFeesCurrency) : "NA"}</p>
                                      </div>

                                      <div className="position-relative lineheight19">
                                        <p
                                          className="mb-0 font-size-13 font-wt-500">Other Charges
                                        </p>
                                        <p className="font-size-16 totalChargesLabel">{getInvoiceTotalOtherCharges(key.otherCharges).toFixed(2) + " " + key.financeLimitCurrency}</p>
                                        <div className="otherChargePopup">
                                          {key.otherCharges && key.otherCharges.length ? key.otherCharges.map((j) => (
                                            <>
                                              <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                                <label className="text-color-label">{j.name}</label>
                                                <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                              </div>
                                            </>
                                          )) : null}
                                        </div>
                                      </div>
                                      <div className="mt-0 lineheight19">
                                        <p className="mb-0 font-size-13 font-wt-500">Total Charges</p>
                                        <span className="selectfinacne-subheading font-size-12">($ 50,000/30 days)</span>
                                        <p className="font-size-16">{getInvoiceTotalCharges(key).toFixed(2) + " " + key.financeLimitCurrency}</p>
                                      </div>
                                      {/* <div>
                                      <p className="mb-0 font-size-14 font-wt-500">Offer validity
                                      </p>
                                      <p className="font-size-16">{key.offerValidity || "NA"}</p>
                                    </div> */}
                                    </>
                                  )}
                                  {/* {key.financierAction === "Approved" ? ( */}
                                  {!isAdmin || !dbData?.[0]?.invRefNo ?
                                    <>
                                      <div>
                                        {key.financierAction === "Approved" ? (
                                          <button
                                            disabled={(key.lender_name.toLowerCase().includes("stenn") && !enableStennQuote)}
                                            onClick={() => { setData({ ...data, selectedFinancier: key.lender_id, selectedFinancierName: key.lender_name, selectedQuote: key }); setTab(tab + 1) }}
                                            className={`mx-2  w-75 py-2 px-2 
                                        ${(key.lender_name.toLowerCase().includes("stenn") && !enableStennQuote) ? ' disabledQuotebtn ' : ' new-btn '}
                                        ${(key.lender_name.toLowerCase().includes("stenn") && !enableStennQuote) ? ' text-black ' : ' text-white '}`}>Continue</button>
                                        ) : null}</div>
                                      <p className="mt-1"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-12 font-wt-500 color434343 letter-spacing05 ms-2 cursor"
                                        onClick={async () => {
                                          let reqObj = userTypeId / 1 == 1 ? {
                                            userTypeId,
                                            senderId: userId,
                                            sellerId: localStorage.getItem("manualUserId"),
                                            receiverId: key.lender_id / 1,
                                            textMessage: 'Hii',
                                            chat_room_name: "CHAT" + new Date().getTime(),
                                            includeAdmins: true,
                                            invApplicationId: localStorage.getItem('applicationId'),
                                            receiverParties: key.lender_id / 1,
                                            dontSendInitialMsg: true
                                          } : {
                                            senderId: userId,
                                            receiverId: key.lender_id / 1,
                                            textMessage: 'Hii',
                                            chat_room_name: "CHAT" + new Date().getTime(),
                                            includeAdmins: true,
                                            invApplicationId: localStorage.getItem('applicationId'),
                                            receiverParties: key.lender_id / 1,
                                            dontSendInitialMsg: true
                                          }
                                          setshowLoader(true)
                                          let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                          setshowLoader(false)
                                          getChatDetails({
                                            chat_room_id: apiResp.id,
                                            loggedInUser: userId
                                          })
                                          setSelectedChat({
                                            chatRoomId: apiResp.id,
                                            receiverName: `${key.lender_name} - ${apiResp.id}`,
                                            invApplicationId: localStorage.getItem('applicationId'),
                                            parties: apiResp.parties,
                                            userId: userId,
                                            isChatOpen: true,
                                            receiverId: key.lender_id / 1
                                          })
                                        }}
                                      >Chat with financer</span></p></> : null}
                                  {availableTermSheets?.filter(i => {
                                    if (i.lender_id / 1 == key.lender_id / 1) {
                                      return true
                                    }
                                  })?.length ?
                                    <>
                                      <p className=""><img className="ms-2" src={"assets/images/pdf_file.png"} alt="" /><span className="font-size-12 font-wt-500 color434343 letter-spacing05 ms-2 cursor"
                                        onClick={async () => {
                                          let reqObj = userTypeId / 1 == 1 ? {
                                            userTypeId,
                                            senderId: userId,
                                            sellerId: localStorage.getItem("manualUserId"),
                                            receiverId: key.lender_id / 1,
                                            textMessage: 'Hii',
                                            chat_room_name: "CHAT" + new Date().getTime(),
                                            includeAdmins: true,
                                            invApplicationId: localStorage.getItem('applicationId'),
                                            receiverParties: key.lender_id / 1,
                                            dontSendInitialMsg: true
                                          } : {
                                            senderId: userId,
                                            receiverId: key.lender_id / 1,
                                            textMessage: 'Hii',
                                            chat_room_name: "CHAT" + new Date().getTime(),
                                            includeAdmins: true,
                                            invApplicationId: localStorage.getItem('applicationId'),
                                            receiverParties: key.lender_id / 1,
                                            dontSendInitialMsg: true
                                          }
                                          setshowLoader(true)
                                          let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                          setshowLoader(false)
                                          getChatDetails({
                                            chat_room_id: apiResp.id,
                                            loggedInUser: userId
                                          })
                                          setSelectedChat({
                                            chatRoomId: apiResp.id,
                                            receiverName: `${key.lender_name} - ${apiResp.id}`,
                                            invApplicationId: localStorage.getItem('applicationId'),
                                            parties: apiResp.parties,
                                            userId: userId,
                                            isChatOpen: true,
                                            receiverId: key.lender_id / 1
                                          })
                                        }}
                                      >View termsheet</span></p></> : null}
                                  {/* ) : (<></>
                                )} */}
                                </div>
                              </div>
                            );
                          }) : null}
                        </div>
                      ) :
                        <div className="my-4" >
                          <NewTablev2 columns={quoteViewColumns} >
                            {data.buyers_credit && data.buyers_credit.length ? data.buyers_credit.map((key, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div>
                                      <img className="mr-2"
                                        src={key.logo} alt="" />
                                      <span className="font-size-14 font-wt-600 text-dark mx-1">{key.lender_name}</span>
                                      {recommendedFinanciers.includes(key.lender_id / 1) ? (
                                        <img src="assets/images/recommended_circle_icon.svg" />
                                      ) : null
                                      }
                                    </div></td>
                                  <td>{`Limit ${key.financeLimit} ${key.financeLimitCurrency} upto ${key.discountingPercentage} % of invoice`}</td>
                                  <td>{key.factoringFees ? (key.factoringFees + " " + key.factoringFeesCurrency) : "NA"}</td>
                                  <td>{key.interestRate ? (key.interestRate + " %") : "NA"}</td>
                                  <td>{key.setupFees ? (key.setupFees + " " + key.setupFeesCurrency) : "NA"}</td>
                                  <td>{getInvoiceTotalOtherCharges(key.otherCharges).toFixed(2) + " " + key.financeLimitCurrency}</td>
                                  <td>{getInvoiceTotalCharges(key).toFixed(2) + " " + key.financeLimitCurrency}</td>
                                  <td>{key.offerValidity}</td>
                                  <td><img onClick={async () => {
                                    let reqObj = {
                                      senderId: userId,
                                      receiverId: key.lender_id / 1,
                                      textMessage: 'Hii',
                                      chat_room_name: "CHAT" + new Date().getTime(),
                                      includeAdmins: true,
                                      invApplicationId: localStorage.getItem('applicationId'),
                                      receiverParties: key.lender_id / 1,
                                      dontSendInitialMsg: true
                                    }
                                    setshowLoader(true)
                                    let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                                    setshowLoader(false)
                                    getChatDetails({
                                      chat_room_id: apiResp.id,
                                      loggedInUser: userId
                                    })
                                    setSelectedChat({
                                      chatRoomId: apiResp.id,
                                      receiverName: `${key.lender_name} - ${apiResp.id}`,
                                      invApplicationId: localStorage.getItem('applicationId'),
                                      parties: apiResp.parties,
                                      userId: userId,
                                      isChatOpen: true,
                                      receiverId: key.lender_id / 1
                                    })
                                  }} className="cursor" src={"assets/images/supplier-images/chat.png"} /></td>
                                  <td>
                                    {key.financierAction === "Approved" ? (
                                      <button
                                        disabled={(key.lender_name.toLowerCase().includes("stenn") && !enableStennQuote)}
                                        onClick={() => { setData({ ...data, selectedFinancier: key.lender_id, selectedFinancierName: key.lender_name, selectedQuote: key }); setTab(tab + 1) }}
                                        className={`mx-2  w-75 py-2 px-2 
                                        ${(key.lender_name.toLowerCase().includes("stenn") && !enableStennQuote) ? ' disabledQuotebtn ' : ' new-btn '}
                                        ${(key.lender_name.toLowerCase().includes("stenn") && !enableStennQuote) ? ' text-black ' : ' text-white '}`}>Select Quote</button>
                                    ) : null}
                                  </td>
                                </tr>
                              )
                            }) : null}
                          </NewTablev2>
                        </div>}

                      {data.buyers_credit?.length > 4 && quoteView != 'table' ? (
                        <div className="col-12 mx-auto text-end">
                          <img onClick={() => {
                            scrollLeft()
                          }}
                            className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                          <img onClick={() =>
                            scrollRight()
                          }
                            className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                        </div>
                      ) : null}
                      {selectedChat.isChatOpen &&
                        <div className="chatboxDivFixed">
                          <ChatBoxPopUp2
                            chatList={chatList}
                            user_avatar={selectedChat.logo}
                            userId={selectedChat.userId}
                            receiverName={selectedChat.receiverName}
                            parties={selectedChat.parties}
                            userTokenDetails={userTokenDetails}
                            onChatSend={(file, parties) => sendChatMsg(file, parties)}
                            message={message}
                            setTextMsg={setTextMsg}
                            reloadChatList={() => getChatDetails({
                              chat_room_id: selectedChat.chatRoomId,
                              loggedInUser: userId
                            })}
                            onPopupClose={() => {
                              setSelectedChat({
                                receiverName: '',
                                receiverId: '',
                                userId: userId,
                                isChatOpen: false,
                                logo: ''
                              })
                              setRefresh(refresh + 1)
                            }}
                          />
                        </div>
                      }
                    </div>
                  ) : null}

                {tab === 1 && isAdmin ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className='mb-3 d-flex pl-2 pt-2'>
                      <label className="font-wt-600 font-size-16 pt-1 mr-4" >Document Status</label>
                      <input type="radio" id="currentDocuments" name="currentDocuments"
                        checked={localStorage.getItem("documentStatus") === "currentDocuments"}
                      />
                      <label className="font-wt-500 font-size-14 mr-4" for="currentDocuments">Current Documents</label>
                      <input className="mr-3" type="radio" id="pastDocuments" name="pastDocuments"
                        checked={localStorage.getItem("documentStatus") === "pastDocuments"}
                      />
                      <label className="font-wt-500 font-size-14 mr-4" for="pastDocuments">Past Documents</label>
                    </div>

                    {/* <div className="col-md-8 my-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> */}

                    {/* <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> */}

                    <div className="my-4 ml-3">
                      <NewTable
                        hideRowBorderDefault={true}
                        disableAction={true}
                        columns={[
                          { name: "Document Name" },
                          { name: "Invoice Amount" },
                          { name: "Attach Document" },
                          { name: "" }
                        ]}
                        data={tableDocsData}
                      />
                    </div>

                    {data.logisticDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Bill of Lading/Airway Bill</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "logisticDocument": null })}
                          />
                        </div>
                        {data.logisticDocument && data.logisticDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> : null}

                    {data.invReportDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Investigation Report</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument}
                            onChange={handleFile} isEditable={false}
                            onUploadCancel={() => setData({ ...data, "invReportDocument": null })}
                          />
                        </div>
                        {data.invReportDocument && data.invReportDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> : null}

                    {data.grnDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Certificate of Origin</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument}
                            onUploadCancel={() => setData({ ...data, "grnDocument": null })}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        {data.grnDocument && data.grnDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> : null}

                    {data.shippingBillDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Shipping Bill</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                            onUploadCancel={() => setData({ ...data, "shippingBillDocument": null })}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        {data.shippingBillDocument && data.shippingBillDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> : null}

                    {data.packingListDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Packing List</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                            onUploadCancel={() => setData({ ...data, "packingListDocument": null })}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        {data.packingListDocument && data.packingListDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> : null}

                    {data.salesContractDocument?.name ? <div className="col-md-8 mb-3">
                      <label className="font-size-14">Sales Contact</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument}
                            onUploadCancel={() => setData({ ...data, "salesContractDocument": null })}
                            onChange={handleFile} isEditable={false}
                          />
                        </div>
                        {data.salesContractDocument && data.salesContractDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>) : null}
                      </div>
                    </div> : null}

                    {availableTermSheets?.length ? availableTermSheets.map((i, j) => {
                      return (
                        <div className="col-md-8 mb-3">
                          <label className="font-size-14">{`Term Sheet By ${i.lenderName}`}</label>
                          <div className="row align-items-center">
                            <div className="form-group col-md-8 mb-0">
                              <FileInput name={"availableTermSheet" + j} value={i.file}
                                onChange={handleFile} isEditable={false}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }) : null}

                    {/* <div className="col-md-8">
                      <AddMoreDocumentHandler notEditable={true} hideSignDocument={true}
                        colFixedLength={8}
                        purpose={"ApplyForLimitInvoice"} purposeId={data.selectedBuyer}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
                      />
                    </div> */}

                  </div>
                ) : null}

                {(tab === 2 && !isAdmin) ? (
                  <div className='px-5 py-4 pt-5'>
                    {/* <div className="col-md-8 mb-3">
                      <label className="font-size-14">Invoice</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "invoiceDocument": null })}
                          />
                          {errors.invoiceDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.invoiceDocument}</b></div> : ''}
                        </div>
                        {data.invoiceDocument && data.invoiceDocument.name && !data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.invoiceDocument, docSignName: "invoiceDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.invoiceDocument && data.invoiceDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                    {/* <div className="col-md-8 mb-3">
                      <label className="font-size-14">Purchase Order</label>
                      <div className="row align-items-center">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                            onChange={handleFile} isEditable={true}
                            onUploadCancel={() => setData({ ...data, "poDocument": null })}
                          />
                          {errors.poDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.poDocument}</b></div> : ''}
                        </div>
                        {data.poDocument && data.poDocument.name && !data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.poDocument, docSignName: "poDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.poDocument && data.poDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div> */}

                    <div className="my-4 ">
                      <NewTable
                        hideRowBorderDefault={true}
                        disableAction={true}
                        columns={[
                          { name: "Document Name" },
                          { name: "Invoice Amount" },
                          { name: "Attach Document" },
                          { name: "" }
                        ]}
                        data={tableDocsData}
                      />
                    </div>

                    <div className="d-flex row mt-4" >
                      {data.logisticDocument?.name ? <div className="col-md-4 mb-3">
                        <label className="font-size-14">Bill of Lading/Airway Bill</label>
                        <div className="row align-items-center">
                          <div className="form-group col-md-12 mb-0">
                            <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "logisticDocument": null })}
                            />
                          </div>
                          {data.logisticDocument && data.logisticDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>) : null}
                        </div>
                      </div> : null}

                      {data.invReportDocument?.name ? <div className="col-md-4 mb-3">
                        <label className="font-size-14">Investigation Report</label>
                        <div className="row align-items-center">
                          <div className="form-group col-md-12 mb-0">
                            <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument}
                              onChange={handleFile} isEditable={false}
                              onUploadCancel={() => setData({ ...data, "invReportDocument": null })}
                            />
                          </div>
                          {data.invReportDocument && data.invReportDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>) : null}
                        </div>
                      </div> : null}

                      {data.grnDocument?.name ? <div className="col-md-4 mb-3">
                        <label className="font-size-14">Certificate of Origin</label>
                        <div className="row align-items-center">
                          <div className="form-group col-md-12 mb-0">
                            <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument}
                              onUploadCancel={() => setData({ ...data, "grnDocument": null })}
                              onChange={handleFile} isEditable={false}
                            />
                          </div>
                          {data.grnDocument && data.grnDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>) : null}
                        </div>
                      </div> : null}

                      {data.shippingBillDocument?.name ? <div className="col-md-4 mb-3">
                        <label className="font-size-14">Shipping Bill</label>
                        <div className="row align-items-center">
                          <div className="form-group col-md-12 mb-0">
                            <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                              onUploadCancel={() => setData({ ...data, "shippingBillDocument": null })}
                              onChange={handleFile} isEditable={false}
                            />
                          </div>
                          {data.shippingBillDocument && data.shippingBillDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>) : null}
                        </div>
                      </div> : null}

                      {data.packingListDocument?.name ? <div className="col-md-4 mb-3">
                        <label className="font-size-14">Packing List</label>
                        <div className="row align-items-center">
                          <div className="form-group col-md-12 mb-0">
                            <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                              onUploadCancel={() => setData({ ...data, "packingListDocument": null })}
                              onChange={handleFile} isEditable={false}
                            />
                          </div>
                          {data.packingListDocument && data.packingListDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>) : null}
                        </div>
                      </div> : null}

                      {data.salesContractDocument?.name ? <div className="col-md-4 mb-3">
                        <label className="font-size-14">Sales Contact</label>
                        <div className="row align-items-center">
                          <div className="form-group col-md-12 mb-0">
                            <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument}
                              onUploadCancel={() => setData({ ...data, "salesContractDocument": null })}
                              onChange={handleFile} isEditable={false}
                            />
                          </div>
                          {data.salesContractDocument && data.salesContractDocument.signatureId ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>) : null}
                        </div>
                      </div> : null}

                      {availableTermSheets?.length ? availableTermSheets.map((i, j) => {
                        return (
                          <div className="col-md-4 mb-3">
                            <label className="font-size-14">{`Term Sheet By ${i.lenderName}`}</label>
                            <div className="row align-items-center">
                              <div className="form-group col-md-12 mb-0">
                                <FileInput name={"availableTermSheet" + j} value={i.file}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }) : null}
                    </div>

                    {/* <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler
                        purpose={"ApplyForLimitInvoice"} purposeId={data.id}
                        userTokenDetails={userTokenDetails} onChange={(docs) => { setMoreDocs(docs) }}
                      />
                    </div> */}
                  </div>
                ) : null}

                {tab === 3 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-80'>
                        <label className='font-wt-500 font-size-16 p-0'><u>Buyer Details</u>
                        </label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div >
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                        </label>
                        <div className='row mt-2'>
                          {/* {data.invoiceDocument && data.invoiceDocument.filebase64 &&
                            <div className="col-md-5">
                              <label className="font-size-14">Invoice</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"invoiceDocument"} value={data.invoiceDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          {data.poDocument && data.poDocument.filebase64 &&
                            <div className="col-md-5">
                              <label className="font-size-14">Purchase Order</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"poDocument"} value={data.poDocument}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          } */}
                          <div className="my-4 ml-0">
                            <NewTable
                              hideRowBorderDefault={true}
                              disableAction={true}
                              columns={[
                                { name: "Document Name" },
                                { name: "Invoice Amount" },
                                { name: "Attach Document" },
                                { name: "" }
                              ]}
                              data={tableDocsData}
                            />
                          </div>

                          <div className="d-flex row mt-4" >
                            {data.logisticDocument?.name ? <div className="col-md-4 mb-3">
                              <label className="font-size-14">Bill of Lading/Airway Bill</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-12 mb-0">
                                  <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument}
                                    onChange={handleFile} isEditable={false}
                                    onUploadCancel={() => setData({ ...data, "logisticDocument": null })}
                                  />
                                </div>
                                {data.logisticDocument && data.logisticDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>) : null}
                              </div>
                            </div> : null}

                            {data.invReportDocument?.name ? <div className="col-md-4 mb-3">
                              <label className="font-size-14">Investigation Report</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-12 mb-0">
                                  <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument}
                                    onChange={handleFile} isEditable={false}
                                    onUploadCancel={() => setData({ ...data, "invReportDocument": null })}
                                  />
                                </div>
                                {data.invReportDocument && data.invReportDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>) : null}
                              </div>
                            </div> : null}

                            {data.grnDocument?.name ? <div className="col-md-4 mb-3">
                              <label className="font-size-14">Certificate of Origin</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-12 mb-0">
                                  <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument}
                                    onUploadCancel={() => setData({ ...data, "grnDocument": null })}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                                {data.grnDocument && data.grnDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>) : null}
                              </div>
                            </div> : null}

                            {data.shippingBillDocument?.name ? <div className="col-md-4 mb-3">
                              <label className="font-size-14">Shipping Bill</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-12 mb-0">
                                  <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument}
                                    onUploadCancel={() => setData({ ...data, "shippingBillDocument": null })}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                                {data.shippingBillDocument && data.shippingBillDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>) : null}
                              </div>
                            </div> : null}

                            {data.packingListDocument?.name ? <div className="col-md-4 mb-3">
                              <label className="font-size-14">Packing List</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-12 mb-0">
                                  <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument}
                                    onUploadCancel={() => setData({ ...data, "packingListDocument": null })}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                                {data.packingListDocument && data.packingListDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>) : null}
                              </div>
                            </div> : null}

                            {data.salesContractDocument?.name ? <div className="col-md-4 mb-3">
                              <label className="font-size-14">Sales Contact</label>
                              <div className="row align-items-center">
                                <div className="form-group col-md-12 mb-0">
                                  <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument}
                                    onUploadCancel={() => setData({ ...data, "salesContractDocument": null })}
                                    onChange={handleFile} isEditable={false}
                                  />
                                </div>
                                {data.salesContractDocument && data.salesContractDocument.signatureId ? (
                                  <div className="col-md-4 text-end">
                                    <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                  </div>) : null}
                              </div>
                            </div> : null}

                            {availableTermSheets?.length ? availableTermSheets.map((i, j) => {
                              return (
                                <div className="col-md-4 mb-3">
                                  <label className="font-size-14">{`Term Sheet By ${i.lenderName}`}</label>
                                  <div className="row align-items-center">
                                    <div className="form-group col-md-12 mb-0">
                                      <FileInput name={"availableTermSheet" + j} value={i.file}
                                        onChange={handleFile} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }) : null}
                          </div>
                          {/* {moreDocs?.length ? moreDocs.map((item, index) => {
                            if (item?.name) {
                              return (
                                <div className="col-md-5">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item} isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null} */}
                        </div>
                        {data.selectedFinancierName ?
                          <div className='w-100 mt-5'>
                            <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                            <div className="d-flex gap-4 my-3">
                              <label
                                className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                {data.selectedFinancierName}
                              </label>
                            </div>
                          </div> : null}
                      </div>

                    </div >
                  </div >
                ) : null}

                {/* {dbData?.[0]?.invRefNo && tab != 3 ?
                  <>
                    <div className="pb-5 px-5">
                      <button type="button"
                        onClick={() => handleValidation()}
                        className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                        {"Continue"}
                      </button>
                    </div>
                  </> : null} */}
                {!isAdmin || !dbData?.[0]?.invRefNo ?
                  <>
                    {tab != 1 && !onlyView ? (
                      <div className="pb-5 px-5">
                        <button type="button"
                          onClick={() => handleValidation()}
                          className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                          {tab === 3 ? "Submit" : "Continue"}
                        </button>
                      </div>
                    ) : null}
                  </> : null}

              </div>
            </div>
          </main >
        </div>
        {/* {
          overlay && <FinanceInvoiceModal limitinvoice={overlay} setLimitinvoice={setOverlay} closeSuccess={async () => {
            // let totalRemarks = Object.keys(data.buyersRemark || {})
            // let totalActiveRemarksOnScreen = 0
            // // console.log("buyerreamks", data.buyersRemark);
            // for (let index = 0; index < totalRemarks.length; index++) {
            //   const element = totalRemarks[index];
            //   if (data.buyersRemark?.[element]?.["isVisible"]) {
            //     totalActiveRemarksOnScreen += 1
            //   }
            // }
            // let totalQuotesOnScreen = (data?.buyers_credit?.length || 0) + totalActiveRemarksOnScreen
            // console.log("totalActiveRemarksOnScreen", totalActiveRemarksOnScreen);
            if (data?.buyers_credit?.length) {
              let key = data.buyers_credit[0]
              localStorage.setItem("financeLimit", key.financeLimit)
              localStorage.setItem("lenderId", key.lender_id)
              localStorage.setItem("buyerId", data.id)
              localStorage.setItem("buyerData", JSON.stringify({
                buyerName: data.buyerName, buyerPosition: data.buyerPosition,
                nameAuthorizedSignatory: data.nameAuthorizedSignatory, buyerEmail: data.buyerEmail,
                buyerPhoneCode: data.buyerPhoneCode, buyerPhone: data.buyerPhone,
                buyerIdentifier: data.identifier, incoTerms: data.incoTerms,
                buyerWebsite: data.buyerWebsite, buyerAddress: data.buyerAddress,
                buyerCountry: data.buyerCountry, buyerDUNSNo: data.buyerDUNSNo,
                previousAnnualSale: data.previousAnnualSale, currentAnnualSale: data.currentAnnualSale,
                termsOfPayment: data.termsOfPayment, productDetails: data.productDetails,
                buyersDocId: data.buyersDocId
              }))
              localStorage.setItem("lenderName", key.lender_name)
              localStorage.setItem("lenderRemark", key.assignRemark)
              setshowLoader(true)
              let additionalReqResp = await call('POST', 'isAdditionalDetailsReqByFinancer', {
                lenderIdArr: [{ lender_id: key.lender_id }],
                applicationId: localStorage.getItem('applicationId')
              })
              // console.log("additionalReqResp========", additionalReqResp);
              setAdditionalReqDetails(additionalReqResp)
              setshowLoader(false)
              if (additionalReqResp?.[0]) {
                localStorage.setItem("additionalReqObj", JSON.stringify(additionalReqResp?.[0]))
              }
              else {
                localStorage.removeItem("additionalReqObj")
              }
              if (additionalReqResp?.[0]) {
                window.location = `/docsRequested`
                localStorage.setItem("overlayDisplayedForApplicationId", localStorage.getItem("applicationId"))
              }
            }
            setOverlay(false)
          }} >
            <div>
              <p className="font-size-16 text-center font-wt-600">Financer has some request/remark to you</p>
              <p className="font-size-14 text-center">Check these request/ remark before moving forward with the application.</p>
            </div>
          </FinanceInvoiceModal>
        } */}
      </div >
      {modal && <SubmitModal modal={modal} setModal={setModal} />
      }
      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SeeQuotesDetails)