import React, { useEffect, useState } from "react";
import { NewSelect, NewInput } from "../../utils/newInput";
const CommodityAdd = ({ commodityDropDown, onPlus, setAddMoreCommodity, onCancel, indexOf }) => {
  const [selectedCommodity, setSelectedCommodity] = useState('')
  const [commodityName, setCommodityName] = useState("")
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (setAddMoreCommodity) setAddMoreCommodity(false)
  }, [])
  const [showAddButton, setShowAddButton] = useState(true);

  const handleAddCommodity = () => {
    if (selectedCommodity && commodityName) {
      onPlus(selectedCommodity, commodityName);
      setShowAddButton(false)
    }
  };

  const handleCancel = () => {
    // setShowAddButton(true);
    // onCancel(selectedCommodity, commodityName);
    setSelectedCommodity('');
    setCommodityName('');
    onCancel(selectedCommodity, commodityName);
    console.log(indexOf)
    if (indexOf === 1) { setShow(true); setShowAddButton(true) }
    else setShow(false)

  };
  return (<div className="w-50" style={{ display: show ? "block" : "none" }}>
    <div className="form-floating d-flex justify-content-between mb-2 w-100 position-relative">
      <NewSelect style={{ marginRight: "5px" }}
        label={"Select Commodity"} value={selectedCommodity} optionLabel={"category"} optionValue={"id"}
        selectData={commodityDropDown} name="commodity"
        onChange={(e) => { setSelectedCommodity(e.target.value) }}
      />
      <NewInput style={{ marginLeft: "5px" }} type="text" name={""} value={commodityName} onChange={(e) => { setCommodityName(e.target.value) }}
        label={"Commodity Name    "} optionLabel={"category"} optionValue={"id"} />
      {/* <button onClick={() => onPlus(selectedCommodity, commodityName)} style={{ height: "30px", paddingBottom: "30px", marginLeft: "5px" }} type="button" class="btn btn-primary">{showCancel ? "x" : "+"}</button> */}
      {showAddButton ? (
        <button
          onClick={handleAddCommodity}
          style={{ height: "30px", paddingBottom: "30px", marginLeft: "5px" }}
          type="button"
          className="btn btn-primary"
        >
          +
        </button>
      ) : (
        <button
          onClick={handleCancel}
          style={{ height: "30px", paddingBottom: "30px", marginLeft: "5px" }}
          type="button"
          className="btn btn-danger"
        >
          x
        </button>
      )}
    </div>
  </div>)

}

export default CommodityAdd