import React from "react";
import { useState } from "react";


const FAQsData = [
  {
    Heading: "How to apply for LC limit",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingOne",
    FaqsCollapse: "CollapseOne"
  },
  {
    Heading: "How to apply for SBLC",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingTwo",
    FaqsCollapse: "CollapseTwo"
  },
  {
    Heading: "How to apply for SBLC",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingThree",
    FaqsCollapse: "CollapseThree"
  },
  {
    Heading: "How to apply for SBLC",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingFour",
    FaqsCollapse: "CollapseFour"
  },
];

const FAQsList = ({ dbData }) => {

  const [accordion, setAccordion] = useState("");
  const [video, setVideo] = useState(false);



  return (
    <>
      <div className="accordion accordion-flush border-0 borderRadius" id={"Faqs"}>
        {dbData?.length ? dbData.map((key) => {
          return (
            < div className="accordion-item border-0 rounded-0 border-bottom helpsupportfaqlist" key={key.qst}>
              <h2 className="accordion-header borderbottom" id={key.id}>
                <button onClick={() => setAccordion(key.id)} className={`accordion-button border-0 py-4 ${accordion !== key.id && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${key.FaqsCollapse}`} aria-expanded="true" aria-controls={key.FaqsCollapse} >
                  {key.qst}
                </button>
              </h2>
              <div id={key.FaqsCollapse} className={`accordion-collapse collapse ${accordion === key.id && "show"}`} aria-labelledby={key.id} data-bs-parent="#Faqs">
                <div className="accordion-body pt-0 d-flex justify-content-between">
                  <div className="col-md-6 px-0">
                    <p className="font-size-13 font-wt-400 lh-18 letter-spacing05 color0C0C0C">{key.answer}</p>
                  </div>
                  <div className="col-md-5 px-0">
                    {/* <img src={"assets/images/videoscreenshot.png"} alt="" className="img-fluid borderRadius position-relative" /> */}
                    {/* <div onClick={() => setVideo(true)} class="cursor position-absolute top-50 start-50 translate-middle"><img src={"assets/images/video-play.png"} alt="" /></div> */}
                    {/* {video && <div onClick={() => setVideo(false)} class="cursor position-absolute top-50 start-50 translate-middle"><img src={"assets/images/video-pause.png"} alt="" /></div>} */}
                    {/* <div class="position-absolute bottom-0 end-0"><img src={"assets/images/video-zoom.png"} alt="" />
                    </div> */}
                    <div>
                      <iframe
                        style={{ width: '90%', height: '15rem' }}
                        // height={500} width={500}
                        src={key.videoLink} allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen">
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }) :
          <div className="pt-3">
            <p className="font-size-13 font-wt-600 lh-18 letter-spacing05 color0C0C0C text-center">{'Result not found, for what you are looking, kindly click on chat with us button to get more help!'}</p>
          </div>}
      </div>
    </>
  );
}
export default FAQsList