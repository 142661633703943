// import docJson from '../../components/userProfile/financeDoc.json'

export default function validate(values) {

  let docJson = {
    "Financial Document - Audit Report": [
      // {
      //   "lable": "Last 1st Year",
      //   "name": "finance_doc_1_year",
      //   "type": "select",
      //   "id": "30"
      // },
      // {
      //   "lable": "Last 2nd Year",
      //   "name": "finance_doc_2_year",
      //   "type": "select",
      //   "id": "31"
      // },
      // {
      //   "lable": "Last 3rd Year",
      //   "name": "finance_doc_3_year",
      //   "type": "select",
      //   "id": "32"
      // },
      {
        "lable": "Provisional",
        "name": "finance_doc_current_year",
        "type": "text",
        "id": "33"
      }
    ],
    "GST Document": [
      {
        "lable": "GST 3B last 6 months",
        "name": "gst_doc_6_month",
        "id": "34"
      }
    ],
    "ITR Document": [
      // {
      //   "lable": "Last 1st Year",
      //   "name": "itr_doc_1_year",
      //   "type": "select",
      //   "id": "35"
      // },
      // {
      //   "lable": "Last 2nd Year",
      //   "name": "itr_doc_2_year",
      //   "type": "select",
      //   "id": "36"
      // },
      // {
      //   "lable": "Last 3rd Year",
      //   "name": "itr_doc_3_year",
      //   "type": "select",
      //   "id": "37"
      // }
    ],
    // "Debtors Statement": [
    //   {
    //     "lable": "Debtors",
    //     "name": "debtor_doc",
    //     "id": "38"
    //   }
    // ],
    // "Creditors Statement": [
    //   {
    //     "lable": "Creditors",
    //     "name": "creditor_doc",
    //     "id": "39"
    //   }
    // ]
  }

  let errors = {};

  if (!values.year) {
    errors.year = "Field Missing";
  }
  if (!values.currency) {
    errors.currency = "Field Missing";
  }
  if (!values.turnover) {
    errors.turnover = "Field Missing";
  }
  if (!values.netProfit) {
    errors.netProfit = "Field Missing";
  }
  if (!values.netWorth) {
    errors.netWorth = "Field Missing";
  }
  // if (!values.finance_doc_1_year) {
  //   errors.finance_doc_1_year = "Field Missing";
  // }
  // if (!values.finance_doc_2_year) {
  //   errors.finance_doc_2_year = "Field Missing";
  // }
  // if (!values.finance_doc_3_year) {
  //   errors.finance_doc_3_year = "Field Missing";
  // }
  // if (!values.itr_doc_1_year) {
  //   errors.itr_doc_1_year = "Field Missing";
  // }
  // if (!values.itr_doc_2_year) {
  //   errors.itr_doc_2_year = "Field Missing";
  // }
  // if (!values.itr_doc_3_year) {
  //   errors.itr_doc_3_year = "Field Missing";
  // }

  let keysArray = Object.keys(docJson)
  for (let i = 0; i < keysArray.length; i++) {

    let newArray = docJson[keysArray[i]]
    for (let j = 0; j < newArray.length; j++) {
      if (!(values[newArray[j].name + ":" + newArray[j].id])) {
        errors[newArray[j].name + ":" + newArray[j].id] = "Doc Missing";
      }
    }
  }


  console.log(errors)
  return errors;
}
