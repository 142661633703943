import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import HeaderV2 from '../partial/headerV2'
import SideBarV2 from '../partial/sideBarV2';
import Details from './components/Details';
import FinancerDv2Taskupdate from './components/financerDv2taskupdate';
import Stats from './components/Stats';
import { FinancerDetailsData } from "./DataJson"
import FinancerChart from './components/FinancerChart';
import FinancerDisbursement from './components/FinancerDisbursementChart'
import { ToastContainer } from 'react-toastify'
import moment from 'moment';
import AdminDashboardNew from './AdminDashboardNew';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ReactECharts from 'echarts-for-react';
import TableLegendComponent from './TableLegendComponent';
import { ExpandableTable } from '../wallet/components/ExpandableTable';
import axios from 'axios';
import { platformBackendUrl } from '../../urlConstants';
let option = {
  tooltip: {
    trigger: 'item'
  },
  color: ['#2ECC71', '#FE4141', '#FFB801'],
  series: [
    {
      name: 'Disbursement',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: true,
        position: 'center',
        formatter: (props) => {
          return 1048 + 735 + 100
        },
        fontSize: 16,
        fontFamily: 'noto-sans',
        fontFamily: 500
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Upcoming' },
        { value: 735, name: 'Due' },
        { value: 100, name: 'Over due' }
      ]
    }
  ]
}
let shipmentoption = {
  tooltip: {
    trigger: 'item'
  },
  color: ['#2ECC71', '#96A0F6', '#FFB801', '#D083FF'],
  series: [
    {
      name: 'Shipment',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: true,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Succesful' },
        { value: 735, name: 'Port of Loading' },
        { value: 100, name: 'In transit' },
        { value: 100, name: 'Port of Discharge' }
      ]
    }
  ]
}

let limitOption = {
  tooltip: {
    trigger: 'item'
  },
  color: [
    "#5CB8D3",
    "#FFB801",
    "#2ECC71",
    "#FE4141",
    "#ABABAB"
  ],
  series: [
    {
      name: 'Limit',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: true,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Available' },
        { value: 735, name: 'In Process' },
        { value: 100, name: 'Approved' },
        { value: 100, name: 'Rejected' },
        { value: 100, name: 'Expired' }
      ]
    }
  ]
}

let shipmentsLegend = [
  { name: 'Succesful', color: '#2ECC71', value: 0, percent: '-', unit: '' },
  { name: 'Port of loading', color: '#96A0F6', value: 0, percent: '-', unit: '' },
  { name: 'In Transit', color: '#FFB801', value: 0, percent: '-', unit: '' },
  { name: 'Port of discharge', color: '#D083FF', value: 0, percent: '-', unit: '' },

]
let buyersLegend = [
  { name: 'Approved', color: '#2ECC71', value: 344, percent: '60%', unit: '$' },
  { name: 'Limit Available', color: '#FFB801', value: 123, percent: '10%', unit: '$' },
  { name: 'Rejected', color: '#FE4141', value: 453, percent: '20%', unit: '$' },
  { name: 'Limit Not Applied', color: '#ABABAB', value: 453, percent: '20%', unit: '$' }

]

let financeLegend = [
  { name: 'Available', color: '#5CB8D3', value: 344, percent: '60%', unit: '$' },
  { name: 'In Process', color: '#FFB801', value: 123, percent: '10%', unit: '$' },
  { name: 'Approved', color: '#2ECC71', value: 453, percent: '20%', unit: '$' },
  { name: 'Rejected', color: '#FE4141', value: 453, percent: '20%', unit: '$' },
  { name: 'Expired', color: '#ABABAB', value: 453, percent: '20%', unit: '$' }
]
let limitLegend = [
  { name: 'Available', color: '#5CB8D3', value: 344, percent: '60%', unit: '$' },
  { name: 'In Process', color: '#FFB801', value: 123, percent: '10%', unit: '$' },
  { name: 'Approved', color: '#2ECC71', value: 453, percent: '20%', unit: '$' },
  { name: 'Rejected', color: '#FE4141', value: 453, percent: '20%', unit: '$' },
  { name: 'Expired', color: '#ABABAB', value: 453, percent: '20%', unit: '$' }
]

let comparsionLegend = [
  { name: 'LC', color: '#5CB8D3', value: 344, percent: '60%' },
  { name: 'Invoice', color: '#FFB801', value: 123, percent: '10%' },
  { name: 'Other Services', color: '#2ECC71', value: 453, percent: '20%' }
]
const transactions = [
  { name: "Finance Limit Quote", lc_label: "LC_Quotes_Count", inv_label: "INV_Quotes_Count" },
  { name: "Finance Limit Contract", lc_label: "LC_Contracts_Count", inv_label: "INV_Contracts_Count" },
  { name: "Finance Application", lc_label: "LC_Apply_Fin_Count", inv_label: "INV_Apply_Fin_Count" },
  { name: "Agreement", lc_label: "LC_Agreement", inv_label: "INV_Agreement" },
  { name: "Approved  Finance", lc_label: "LC_Approved_Fin_Count", inv_label: "INV_Approved_Fin_Count" },
  { name: "Amendment", lc_label: "LC_Ammendments_Count", inv_label: "INV_Ammendments_Count" },
]

const DashboardV3 = ({ userTokenDetails, navToggleState }) => {
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const [disbursementdata, setdisbursementdata] = useState({
    pieconfiguration: {},
    legends: []
  })
  const [buyersdata, setbuyersdata] = useState({
    pieconfiguration: {},
    legends: []
  })
  const [salesPerson, setSalesPerson] = useState([])
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const [showLoader, setshowLoader] = useState(false)
  const [transactionObj, setTransactionObj] = useState({})
  const [newsData, setNewsData] = useState([])
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  useEffect(() => {
    const tabName = localStorage.getItem('admin_report_name');
    setTab(tabName)
  }, [])
  const getTransactionsCount = () => {
    call('POST', 'getTransactionCounts', { userId }).then(result => {
      setTransactionObj(result)
    }).catch(e => {
      setTransactionObj(e)
    })
  }
  const getdisbursementdata = () => {
    call('POST', 'getdisbursementdataV2', { userId }).then(result => {
      setdisbursementdata({
        pieconfiguration: {
          tooltip: {
            trigger: 'item',
            valueFormatter: (value) => {
              return "$ " + Intl.NumberFormat("en", { notation: 'compact' }).format(value)
            }
          },
          color: ['#2ECC71', '#FFB801', '#FE4141'],
          series: [
            {
              name: 'Disbursement',
              type: 'pie',
              radius: ['40%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return "$ " + Intl.NumberFormat("en", { notation: 'compact' }).format(result.total)
                },
                fontSize: 15,
                fontFamily: 'noto-sans',
                fontWeight: 500
              },
              labelLine: {
                show: false
              },
              data: result.data
            }
          ]
        },
        legends: result.data
      })
    }).catch(e => {

    })
  }
  const getbuyersummaryV2 = () => {
    call('POST', 'getbuyersummaryV2', { userId }).then(result => {
      setbuyersdata({
        pieconfiguration: {
          tooltip: {
            trigger: 'item'
          },
          color: ['#2ECC71', '#FFB801', '#FE4141', '#ABABAB'],
          series: [
            {
              name: 'Buyers',
              type: 'pie',
              radius: ['40%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return result.total
                },
                fontSize: 15,
                fontFamily: 'noto-sans',
                fontWeight: 500
              },
              labelLine: {
                show: false
              },
              data: result.data
            }
          ]
        },
        legends: result.data
      })
    }).catch(e => {

    })
  }
  useEffect(() => {
    getdisbursementdata()
    getnewdata()
    getbuyersummaryV2()
  }, [])

  const getnewdata = () => {

    axios.post(platformBackendUrl + "/blogs/fetch-blogs").then((result) => {
      console.log("inside /blogs/fetch-blogs", result);
      setshowLoader(false)
      if (result.data?.data?.items?.length) {
        setNewsData(result.data.data?.items);
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("Error in /blogs/fetch-blogs====>", err)
    });
  }

  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
    getTransactionsCount()
  }, [])

  //1 - Exporter
  //2 - Financier
  //3 - Admin
  const [tab, setTab] = useState(null);

  //console.log('discbarrrtttttttttt', salesPerson, onlyShowForUserId)
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

        <div className="row">
          <SideBarV2 state="dashboard" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Dashboard"}
              userTokenDetails={userTokenDetails} />
            {userTypeId / 1 == 19 && <div className=''>


              <div className='row mt-5 justify-content-between dashboard'>
                <div className='col'>
                  <div className='d-flex flex-row'>
                    <div className='col-md-8 pl-0'>
                      <div className='d-flex justify-content-between card p-3 flex-row'>
                        <div className='col-md-3 p-0'>
                          <div className='mx-0 text-center lineheight19'>
                            <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-2 mb-3'>Transaction type</p>
                            {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? ' lineheight19 font-size-14 pb-0 my-0' : 'border-bottom lineheight19 font-size-14 pb-2 my-3'}>{tranaction.name}</p>)}
                          </div>
                        </div>
                        <div className='col-md-3 p-0'>
                          <div className='mx-0 text-center lineheight19'>
                            <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-2 mb-3'>Letter of Credit</p>
                            {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? ' lineheight19 font-size-14 pb-0 my-0' : 'border-bottom lineheight19 font-size-14 pb-2 my-3'}>{transactionObj[tranaction.lc_label]}</p>)}
                          </div>
                        </div>
                        <div className='col-md-3 p-0'>
                          <div className='mx-0 text-center lineheight19'>
                            <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-2 mb-3'>Invoice Discounting</p>
                            {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? ' lineheight19 font-size-14 pb-0 my-0' : 'border-bottom lineheight19 font-size-14 pb-2 my-3'}>{transactionObj[tranaction.inv_label]}</p>)}
                          </div>
                        </div>
                        <div className='col-md-3 p-0'>
                          <div className='mx-0 text-center lineheight19'>
                            <p className='border-bottom text-custom2 lineheight19 font-size-14 pb-2 mb-3'>Other Services</p>
                            {transactions.map((tranaction, index) => <p className={index === transactions.length - 1 ? 'lineheight19 font-size-14 pb-0 my-0' : 'border-bottom lineheight19 font-size-14 pb-2 my-3'}>{transactionObj[tranaction.inv_label]}</p>)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 pr-0'>
                      <div className="header-wrapper">
                        <div className="header-8">
                          <div className="text">
                            <div className="text-wrapper-23">Complete your profile!</div>
                            <p className="p">Complete your profile to increase the chances of application approval</p>
                          </div>
                          <div className="progress-div">
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: '#2ECC71',
                                textColor: '#000000'
                              })}
                              value={47} text={`47%`} />
                          </div>
                        </div>
                      </div>
                      <div className='master-admin mt-3'>
                        <div className="reminder">
                          <div className="text-wrapper-38">Today’s Reminder</div>
                          <div className="list">
                            <div className="reminders" style={{ height: '115px' }}>
                              <div className="reminder-2">
                                <div className="text-wrapper-39">
                                  Video call&nbsp;&nbsp;|&nbsp;&nbsp;10:00 AM&nbsp;&nbsp;|&nbsp;&nbsp;Participants
                                </div>
                              </div>
                              <div className="reminder-2">
                                <div className="text-wrapper-39">
                                  Voice call&nbsp;&nbsp;|&nbsp;&nbsp;10:00 AM&nbsp;&nbsp;|&nbsp;&nbsp;Participants
                                </div>
                              </div>
                              <div className="reminder-3">
                                <p className="text-wrapper-40">Make payment&nbsp;&nbsp;|&nbsp;&nbsp;$ 10,000 - XYZ Pvt. Ltd.</p>
                              </div>
                              <div className="reminder-3">
                                <p className="text-wrapper-40">Receive payment&nbsp;&nbsp;|&nbsp;&nbsp;$ 10,000 - XYZ Pvt. Ltd.</p>
                              </div>
                              <div className="reminder-3">
                                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
                              </div>
                              <div className="reminder-3">
                                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
                              </div>
                              <div className="reminder-3">
                                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
                              </div>
                              <div className="reminder-3">
                                <p className="text-wrapper-40">Generate invoice&nbsp;&nbsp;|&nbsp;&nbsp;$ 100,000 - XYZ Pvt. Ltd</p>
                              </div>
                            </div>
                            <div className="rectangle" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='master-admin'>
                    <div className="div-19 mt-4">
                      <div className="top-products">
                        <div className="pie">
                          <ReactECharts option={disbursementdata.pieconfiguration} style={{ height: '270px', width: '300px' }} />

                        </div>
                        <div className="legend">
                          <div className="div-20">
                            <div className="text-wrapper-42">Disbursement</div>
                          </div>
                          <TableLegendComponent legends={disbursementdata.legends} />
                        </div>
                      </div>
                      <div className="top-products">
                        <div className="pie">
                          <div className='d-flex align-items-center justify-content-center' style={{ height: 270 }}>
                            <label className='font-size-22'>Coming Soon.....</label>

                          </div>
                          {/* <ReactECharts option={shipmentoption} style={{ height: '270px', width: '300px' }} /> */}
                        </div>
                        <div className="legend">
                          <div className="div-20">
                            <div className="text-wrapper-42">Shipments</div>
                          </div>
                          <TableLegendComponent legends={shipmentsLegend} />
                        </div>
                      </div>
                      <div className="top-products">
                        <div className="pie">
                          <ReactECharts option={buyersdata.pieconfiguration} style={{ height: '270px', width: '300px' }} />
                        </div>
                        <div className="legend">
                          <div className="div-20">
                            <div className="text-wrapper-42">Buyers</div>
                          </div>
                          <TableLegendComponent legends={buyersdata.legends} />

                        </div>
                      </div>
                    </div>
                    <div className="div-19 mt-4">
                      <div className="top-products">
                        <div className="pie">
                          <ReactECharts option={limitOption} style={{ height: '270px', width: '300px' }} />
                        </div>
                        <div className="legend">
                          <div className="div-20">
                            <div className="text-wrapper-42">Limit</div>
                          </div>
                          <TableLegendComponent legends={limitLegend} />

                        </div>
                      </div>
                      <div className="top-products">
                        <div className="pie">
                          <ReactECharts option={limitOption} style={{ height: '270px', width: '300px' }} />
                        </div>
                        <div className="legend">
                          <div className="div-20">
                            <div className="text-wrapper-42">Finance</div>
                          </div>
                          <TableLegendComponent legends={financeLegend} />

                        </div>
                      </div>
                      <div className="top-products">
                        <div className="pie">
                          <ReactECharts option={option} style={{ height: '270px', width: '300px' }} />
                        </div>
                        <div className="legend">
                          <div className="div-20">
                            <div className="text-wrapper-42">Comparision</div>
                          </div>
                          <TableLegendComponent legends={comparsionLegend} />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <ExpandableTable columns={[
                      { name: "Supplier name", width: '20%' },
                      { name: "Service Type", width: '20%' },
                      { name: "Stage", width: '20%' },
                      { name: "Remark", width: '20%' },
                      { name: "Action", width: '20%' }
                    ]}
                      data={[["LINTL CLOTHING PVT LTD", "Invoice Limit", "Quote", "Quote Recieved"]]} disableAction expand={[]} expandKey={"Date"} />
                  </div>

                  <div className='dashboard mt-5 d-flex flex-row gap-4 blogsDiv pb-3'>
                    {newsData.map((item, index) => {
                      return <div className="col-md-4 px-0 cardborder">
                        <div className="image-5">
                          <img className="image-6" alt="Image" src={item.thumbnail} />
                        </div>
                        <div className="content-3">
                          <div className="details">
                            <div className="text-wrapper-37">{item.title}</div>
                            <div className="text-wrapper-38">{moment(item.created).format("DD/MM/YYYY hh:mm a")}</div>
                          </div>
                          <div className="description">
                            <p className="penatibus-dolor-arcu">
                              <span className="text-wrapper-39" dangerouslySetInnerHTML={{ __html: item.description }}>

                              </span>
                              <span className="text-wrapper-40 cursor" onClick={() => {
                                window.open(`https://www.tradereboot.com/blogs?title=${item.blogEndPoint}`, '__blank')
                              }}>Read more</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    })}

                  </div>
                </div>
              </div>
            </div>}

            {userTypeId / 1 == 1 && <div >
              <AdminDashboardNew />

            </div>}
          </main>

        </div>
      </div>

    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(DashboardV3)