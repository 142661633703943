import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getDateTimeString } from "../../../utils/myFunctions";

const TableFilter = ({ filter, setFilter, refresh, setRefresh, onPDFExport, onExcelExport, isTimeFilter, isAdvancedFilter }) => {
  const [selectedDropDown, setselectedDropDown] = useState('This Week')
  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1)
    }
  };

  const handleChange = async (event) => {
    event.persist()
    setFilter({ ...filter, [event.target.name]: event.target.value })
  }
  const setWeekTimeRange = () => {
    var startOfWeek = moment().startOf('isoWeek');
    var endOfWeek = moment().endOf('isoWeek');
    setselectedDropDown('This Week')
    setFilter({ ...filter, fromDate: startOfWeek.format("YYYY-MM-DD"), toDate: endOfWeek.format("YYYY-MM-DD") })
  }
  const setMonthTimeRange = () => {
    var startOfWeek = moment().startOf('month');
    var endOfWeek = moment().endOf('month');
    setselectedDropDown('This Month')
    setFilter({ ...filter, fromDate: startOfWeek.format("YYYY-MM-DD"), toDate: endOfWeek.format("YYYY-MM-DD") })
  }
  useEffect(() => {
    setRefresh(refresh + 1)
  }, [filter.fromDate, filter.toDate])
  return (
    <>
      <div className="row align-items-center">
        <div className="d-flex col-md-6 align-items-center gap-3">
          <div className='row d-flex align-items-center'>
            <label className='col mb-0 me-2'>Show</label>
            <input className='form-control pagination-input px-2 border-0' type="number" value={filter.resultPerPage} style={{ height: "34px" }} onChange={handleChange} onKeyDown={handleKeyDown} name={"resultPerPage"} />
          </div>
          {isAdvancedFilter &&
            <div className="">
              <button type="button" className="tablefilterbtn btn btn-light bg-white px-4 border-0 border-radius-12 text-start"><img src={"assets/images/filter.png"} alt="" /><span className="px-2 ">Filter</span></button>
            </div>
          }
          {isTimeFilter &&
            <div class="dropdown">
              <button class="tablefilterweek form-select font-wt-600 font-size-13 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {selectedDropDown}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li class="dropdown-item" onClick={setWeekTimeRange}>This week</li>
                <li class="dropdown-item" onClick={setMonthTimeRange}>This Month</li>
              </ul>
            </div>
          }
        </div>
        <div className="d-flex col-md-6 justify-content-end gap-3 align-items-center">
          <div className="input-group mb-3 col-md-5 currency mt-3 pe-1">
            <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
            <input type="text" name='search' onChange={handleChange} onKeyDown={handleKeyDown} value={filter.search}
              className="form-control border-start-0 px-0" placeholder="Search" />
          </div>
          <div className="">
            <button type="button" className="btn btn-light bg-white px-4 border-0 border-radius-12 text-start" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"><img src={"assets/images/charm_download.png"} alt="" /><span className="px-2 text-secondary">Download</span></button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li><p class="dropdown-item" onClick={onExcelExport}>Export as Excel</p></li>
              <li><p class="dropdown-item" onClick={onPDFExport}>Export as PDF</p></li>
            </ul>
          </div>
        </div>

      </div>
    </>
  );
}
export default TableFilter;