import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store'
import { HMSRoomProvider } from '@100mslive/react-sdk';

// import config from './config'
// import socketIOClient from "socket.io-client";

// const socket = socketIOClient(config.socketEndPoint);
// socket.on('connect', function () {
//   console.log("Socket connection done---  :)")
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
  <HMSRoomProvider>
    <App />
  </HMSRoomProvider>
</Provider>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
