
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges, handleFileCommonFunction, getWCTotalCharges, multiSelectEventHandler } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { InputForTable, InputWithSelect, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import avatarUrl from "../../utils/makeAvatarUrl";
import MultipleSelect from "../../utils/MultipleSelect";
import moment from "moment";



let reviewForm = [
  { "name": "Trader Role", val: "traderType" },
  { "name": "Commodity Contract", val: "commodityContract" },
  { "name": "Loading Country", val: "loadingCountry" },
  { "name": "Loading Port", val: "loadingPort" },

  { "name": "Unloading Country", val: "unloadingCountry" },
  { "name": "Unloading Port", val: "unloadingPort" },
  { "name": "Inco Terms", val: "incoTerms" },

  { "name": "Container Type", val: "containerType" },
  { "name": "Container No", val: "containerNo" },

  { "name": "Expected Shipment Date", val: "expectedShipmentDate" },
  { "name": "Commodity Name", val: "commodity" },

  { "name": "Commodity Quantity", val: "quantity", unit: 'quantityUnit' },
  { "name": "Total Shipment Value", val: "commodityPrice", unit: 'commodityPriceUnit' }

]

function getQuoteStatusForTrader(quote) {
  if (quote.quoteLocked) {
    return "Quote locked"
  }
  if (quote.acceptedByTrader && quote.updatedByTrader) {
    return "Updated & Agreed by You"
  }
  if (quote.acceptedByTrader) {
    return "Agreed by You"
  }
  if (quote.updatedByTrader) {
    return "Updated by You"
  }
  if (quote.acceptedByShipper && quote.updatedByShipper) {
    return "Updated & Agreed by Shipper"
  }
  if (quote.acceptedByShipper) {
    return "Agreed by Shipper"
  }
  if (quote.updatedByShipper) {
    return "Updated by Shipper"
  }
  if (quote.shipperAction === "Approved") {
    return "Quote Given by Shipper"
  }
  if (quote.shipperAction === "deny") {
    return "Quote rejected by Shipper"
  }
}

const ShipmentQuoteDetails = ({ userTokenDetails, navToggleState }) => {

  let ShipmentDetailsLocalStorage = JSON.parse(localStorage.getItem("ShipmentQuoteDetails"))

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30 })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [owners, setOwners] = useState([])
  const [exFacilties, setExFacilities] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [currencyData, setCurrencyData] = useState(most_used_currencies)

  const [tabLabels, setTabLabels] = useState([
    { name: "Application Form" }
  ])
  const [shipperQuotes, setShipperQuotes] = useState([])
  const [containerTypeData, setcontainerTypeData] = useState([]);

  // console.log("ssssssssssssssssssssssssssssssssssssssssssss", ShipmentDetailsLocalStorage);

  useEffect(() => {
    let tempLabels = tabLabels
    let shipperQuotes = JSON.parse(ShipmentDetailsLocalStorage.shipperQuotes || "[]")
    setShipperQuotes([...shipperQuotes])
    for (let index = 0; index < shipperQuotes.length; index++) {
      const element = shipperQuotes[index];
      tempLabels.push({ name: `${element.name}'s Quote` })
    }
    call('get', 'getcontainertypes').then((result) => {
      setcontainerTypeData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', ShipmentDetailsLocalStorage.applicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleQuoteChange = (event) => {
    event.persist()
    let tempUpdatedQuoteDetails = shipperQuotes
    tempUpdatedQuoteDetails[tab - 1]["details"][event.target.name] = event.target.value
    setShipperQuotes([...tempUpdatedQuoteDetails])
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}

      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'wcQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Quote Selected successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="ShipmentBookingQuotes" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Shipment Booking > Quotes > Quote Details"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer w-auto " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          // if (index < tab) {
                          setTab(index)
                          // }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="card mt-1"
            >
              {tab === 0 ?
                <div className='p-4'>
                  <div className="row">
                    <div className='w-100'>
                      <label className='font-wt-500 font-size-16'><u>Application Details</u></label>
                      <div className='row'>
                        {reviewForm.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {ShipmentDetailsLocalStorage["details"][item.val] ? (item.unit ? `${ShipmentDetailsLocalStorage["details"][item.unit]} ` : "") + (ShipmentDetailsLocalStorage["details"][item.val]) : (item.directValue || "NA")}</span> </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='w-100 mt-5'>
                      <label className='font-wt-500 font-size-16'><u>Shipper selected</u></label>
                      <div className="d-flex gap-4 my-3 flex-wrap">
                        {ShipmentDetailsLocalStorage["selectedShipperName"]?.split(",")?.map((key) => {
                          return (
                            <label key={key}
                              className="browse-div cursor text-center m-0 w-auto border-finance text-dark" >
                              {key}
                            </label>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                : null}

              {tab != 0 && shipperQuotes[tab - 1]?.["details"] ?
                <div className='p-4'>
                  <hr className="mt-2 mb-2" />
                  <label className="font-size-14 font-wt-600 text-color1 text-center w-100" >Quantity & Pricing</label>
                  <hr className="mt-1 mb-3" />
                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Quantity to be Shipped</label>
                    </div>
                    <div className="col-6" >
                      <NewInput label={""} type={"number"} name={"quantityToBeShipped"} value={shipperQuotes[tab - 1]["details"]["quantityToBeShipped"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>
                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">MOL Type</label>
                    </div>
                    <div className="col-6" >
                      <NewSelect label={""} selectData={[{ name: 'MOLOO' }, { name: 'MOLCO' }]}
                        optionLabel={"name"} optionValue={"name"}
                        name={"molType"} value={shipperQuotes[tab - 1]["details"]["molType"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>
                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Quantity Vary Index</label>
                    </div>
                    <div className="col-3" >
                      <NewSelect label={""} selectData={[{ name: 'Percentage' }, { name: 'Min/Max' }]}
                        optionLabel={"name"} optionValue={"name"}
                        name={"quantityVaryIndexUnit"} value={shipperQuotes[tab - 1]["details"]["quantityVaryIndexUnit"]}
                        onChange={handleQuoteChange} />
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"quantityVaryIndex"} value={shipperQuotes[tab - 1]["details"]["quantityVaryIndex"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>
                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Freight (pmt)</label>
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"freightPmt"} value={shipperQuotes[tab - 1]["details"]["freightPmt"]}
                        onChange={handleQuoteChange} />
                    </div>
                    <div className="col-3" >
                      <MultipleSelect
                        Label={""}
                        optiondata={most_used_currencies}
                        Id={"freightPmtUnit"}
                        name={"freightPmtUnit"}
                        labelKey={"code"}
                        valKey={"code"}
                        value={shipperQuotes[tab - 1]["details"]["freightPmtUnit"] ? [shipperQuotes[tab - 1]["details"]["freightPmtUnit"]] : []}
                        onChange={(e) => {
                          handleQuoteChange(multiSelectEventHandler(e, `freightPmtUnit`, "code"))
                        }}
                      />
                    </div>
                  </div>


                  <hr className="mt-2 mb-2" />
                  <label className="font-size-14 font-wt-600 text-color1 text-center w-100" >Shipment Details</label>
                  <hr className="mt-1 mb-3" />
                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Shipment Type</label>
                    </div>
                    <div className="col-6" >
                      <NewSelect label={""}
                        selectData={[{ name: 'Bulk Shipment' }, { name: 'Container Shipment' }]}
                        optionLabel={"name"} optionValue={"name"} name={"shipmentType"} value={shipperQuotes[tab - 1]["details"]["shipmentType"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>
                  {shipperQuotes[tab - 1]["details"]["shipmentType"] === "Container Shipment" ?
                    <div className="d-flex row" >
                      <div className="col-6" >
                        <label className="font-size-13 font-wt-500">Container Type</label>
                        <NewSelect label={""} selectData={containerTypeData}
                          optionLabel={"container_type_pretty"} optionValue={"container_type_pretty"}
                          name={"containerType"} value={shipperQuotes[tab - 1]["details"]["containerType"]}
                          onChange={handleQuoteChange} />
                      </div>
                      <div className="col-6" >
                        <label className="font-size-13 font-wt-500">No. Of Containers</label>
                        <NewInput label={""} type={"number"} name={"noOfContainers"} value={shipperQuotes[tab - 1]["details"]["noOfContainers"]}
                          onChange={handleQuoteChange} />
                      </div>
                    </div> : null}

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Charter Type</label>
                    </div>
                    <div className="col-6" >
                      <NewSelect label={""}
                        selectData={[{ name: 'Voyage Charter' }]}
                        optionLabel={"name"} optionValue={"name"} name={"charterType"} value={shipperQuotes[tab - 1]["details"]["charterType"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Bearth Loading</label>
                    </div>
                    <div className="col-3" >
                      <NewSelect label={""}
                        selectData={[{ name: 'Yes' }, { name: 'No' }]}
                        optionLabel={"name"} optionValue={"name"} name={"loadingTypeBearth"} value={shipperQuotes[tab - 1]["details"]["loadingTypeBearth"]}
                        onChange={handleQuoteChange} />
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"loadingTypeBearthAmt"} value={shipperQuotes[tab - 1]["details"]["loadingTypeBearthAmt"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Anchorage Loading</label>
                    </div>
                    <div className="col-3" >
                      <NewSelect label={""}
                        selectData={[{ name: 'Yes' }, { name: 'No' }]}
                        optionLabel={"name"} optionValue={"name"} name={"loadingTypeAnchorage"} value={shipperQuotes[tab - 1]["details"]["loadingTypeAnchorage"]}
                        onChange={handleQuoteChange} />
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"loadingTypeAnchorageAmt"} value={shipperQuotes[tab - 1]["details"]["loadingTypeAnchorageAmt"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Loading Rate (PDPR)</label>
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"loadingRatePdrr"} value={shipperQuotes[tab - 1]["details"]["loadingRatePdrr"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>


                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Bearth Unloading</label>
                    </div>
                    <div className="col-3" >
                      <NewSelect label={""}
                        selectData={[{ name: 'Yes' }, { name: 'No' }]}
                        optionLabel={"name"} optionValue={"name"} name={"unloadingTypeBearth"} value={shipperQuotes[tab - 1]["details"]["unloadingTypeBearth"]}
                        onChange={handleQuoteChange} />
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"unloadingTypeBirthAmt"} value={shipperQuotes[tab - 1]["details"]["unloadingTypeBirthAmt"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Anchorage Unloading</label>
                    </div>
                    <div className="col-3" >
                      <NewSelect label={""}
                        selectData={[{ name: 'Yes' }, { name: 'No' }]}
                        optionLabel={"name"} optionValue={"name"} name={"unloadingTypeAnchorage"} value={shipperQuotes[tab - 1]["details"]["unloadingTypeAnchorage"]}
                        onChange={handleQuoteChange} />
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"unloadingTypeAnchorageAmt"} value={shipperQuotes[tab - 1]["details"]["unloadingTypeAnchorageAmt"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Unloading Rate (PDPR)</label>
                    </div>
                    <div className="col-3" >
                      <NewInput label={""} type={"number"} name={"unloadingRatePdrr"} value={shipperQuotes[tab - 1]["details"]["unloadingRatePdrr"]}
                        onChange={handleQuoteChange} />
                    </div>
                  </div>

                  <div className="d-flex row" >
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">Status</label>
                    </div>
                    <div className="col-6" >
                      <label className="font-size-13 font-wt-500">{`${getQuoteStatusForTrader(shipperQuotes[tab - 1])} (${moment(shipperQuotes[tab - 1]["lastUpdated"]).format('YYYY-MM-DD hh:mm a')})`}</label>
                    </div>
                  </div>

                  {shipperQuotes[tab - 1]["shipperAction"] === "deny" || shipperQuotes.filter(m => {
                    if (m.quoteLocked) {
                      return true
                    }
                  })?.[0] ? null :
                    <div className="d-flex row mt-4" >
                      <div className="col-2" >
                        <button type="button" className="new-btn text-white py-2 w-100 bg-primary"
                          onClick={async () => {
                            setshowLoader(true)
                            let apiResp = await call('POST', 'changeQuoteStatusForSupplier', {
                              applicationNo: ShipmentDetailsLocalStorage.applicationNo,
                              quoteId: ShipmentDetailsLocalStorage.applicationId,
                              quoteIndex: tab - 1, remark: 'agree', userName, userId
                            }, "shipment")
                            setshowLoader(false)
                            setShipperQuotes([...apiResp])
                            ShipmentDetailsLocalStorage["shipperQuotes"] = JSON.stringify(apiResp)
                            localStorage.setItem("ShipmentQuoteDetails", JSON.stringify(ShipmentDetailsLocalStorage))
                            toastDisplay("Quote agreed successfully", "success")
                          }} id="">
                          Agree
                        </button>
                      </div>
                      <div className="col-2" >
                        <button type="button" className="new-btn text-white py-2 w-100"
                          onClick={async () => {
                            setshowLoader(true)
                            let apiResp = await call('POST', 'changeQuoteStatusForSupplier', {
                              applicationNo: ShipmentDetailsLocalStorage.applicationNo,
                              quoteId: ShipmentDetailsLocalStorage.applicationId,
                              quoteIndex: tab - 1, remark: 'update', updatedQuote: shipperQuotes[tab - 1],
                              userName, userId
                            }, 'shipment')
                            setshowLoader(false)
                            setShipperQuotes([...apiResp])
                            ShipmentDetailsLocalStorage["shipperQuotes"] = JSON.stringify(apiResp)
                            localStorage.setItem("ShipmentQuoteDetails", JSON.stringify(ShipmentDetailsLocalStorage))
                            toastDisplay("Quote updated successfully", "success")
                          }} id="">
                          Update
                        </button>
                      </div>
                      <div className="col-2" >
                        <button type="button" className="new-btn text-white py-2 w-100  bg-primary"
                          onClick={async () => {
                            setshowLoader(true)
                            let apiResp = await call('POST', 'changeQuoteStatusForSupplier', {
                              applicationNo: ShipmentDetailsLocalStorage.applicationNo,
                              quoteId: ShipmentDetailsLocalStorage.applicationId,
                              quoteIndex: tab - 1, remark: 'update&agree', updatedQuote: shipperQuotes[tab - 1],
                              userName, userId
                            }, 'shipment')
                            setshowLoader(false)
                            setShipperQuotes([...apiResp])
                            ShipmentDetailsLocalStorage["shipperQuotes"] = JSON.stringify(apiResp)
                            localStorage.setItem("ShipmentQuoteDetails", JSON.stringify(ShipmentDetailsLocalStorage))
                            toastDisplay("Quote updated & agreed successfully", "success")
                          }} id="">
                          Update & Agree
                        </button>
                      </div>
                    </div>
                  }

                </div>
                : null}

            </div>
          </main>
        </div>
      </div>



    </>)

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentQuoteDetails)