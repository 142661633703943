export default function validateContractPilot(values, spec, tnc, activeclass, contractEditMode, multComm) {
  //console.log("contractEditMode in validate-->", contractEditMode)

  //console.log("values in validate-->", values)
  console.log("spec in validate-->", spec)
  // console.log("tnc in validate-->", tnc)
  //console.log("activeclass in validate-->", activeclass)
  let errors = {};
  if (contractEditMode) {
    if (activeclass === 1) {
      if (!values.contractAlias) {
        errors.contractAlias = 'Contract Name is Mandatory';
      }
      if (!values.contract_type) {
        errors.contract_type = 'Contract Type is Mandatory';
      }
      if (!values.supplierName) {
        errors.supplierName = 'Select a Supplier';
      }
      // if (!values.commodity_name) {
      //   errors.commodity_name = 'Select a Commodity';
      // }
      // if (!values.laycanStart) {
      //   errors.laycanStart = 'Laycan Start is Required';
      // }
      // if (!values.laycanEnd) {
      //   errors.laycanEnd = 'Laycan End is Required';
      // }
      // if (!values.loadingCountry) {
      //   errors.loadingCountry = 'Loading Country is Required';
      // }
      // if (!values.loadingPort) {
      //   errors.loadingPort = 'Loading Port is Required';
      // }
      // if (!values.unloadingCountry) {
      //   errors.unloadingCountry = 'Unloading Country is Required';
      // }
      // if (!values.unloadingPort) {
      //   errors.unloadingPort = 'Unloading Port is Required';
      // }
    } else if (activeclass === 2) {

      for (let index = 0; index < multComm.length; index++) {
        const element = multComm[index];
        if (element === null) {
          let keysArray = Object.keys(spec?.[index] || {})
          if (keysArray.length) {
            for (let i = 0; i < keysArray.length; i++) {
              if (!spec[index]?.[keysArray[i]].value || !spec[index]?.[keysArray[i]].rejectionValue || !spec[index]?.[keysArray[i]].rejectionSymbol) {
                errors[`Specification${index}`] = 'Make Sure all the Values, Rejection values and Rejection At are filled';
                break;
              }
            }
          } else {
            errors[`Specification${index}`] = 'Specification is Required';
          }
        }
      }

    } else if (activeclass === 3) {
      for (let index = 0; index < multComm.length; index++) {
        const element = multComm[index];
        if (element === null) {
          if (!values[`priceType${index}`] / 1) {
            errors[`priceType${index}`] = 'Price Type is Required';
          }
          if (!(values[`price${index}`] / 1)) {
            errors[`price${index}`] = 'Price is Required';
          }
          if (values[`quantity${index}`] / 1 <= 0) {
            errors[`quantity${index}`] = 'Quantity is Required';
          }
        }
      }
      if (!values.currency) {
        errors.currency = 'Currency is Required';
      }
    }
    // else if (activeclass === 4) {
    //   // if (!values.investigationAgencyImporter || values.investigationAgencyImporter === "") {
    //   //   errors.investigationAgencyImporter = 'investigationAgencyImporter is Required';
    //   // }
    //   // if (!values.investigationAgencyExporter || values.investigationAgencyExporter === "") {
    //   //   errors.investigationAgencyExporter = 'investigationAgencyExporter is Required';
    //   // }
    //   // if (!values.investigationAgencythird || values.investigationAgencythird === "") {
    //   //   errors.investigationAgencythird = 'investigationAgencythird is Required';
    //   // }

    // } else if (activeclass === 5) {
    //   // if (!values.commodityInsurer || values.commodityInsurer === "") {
    //   //   errors.commodityInsurer = 'commodityInsurer is Required';
    //   // }
    //   // if (!values.tradeInsurer || values.tradeInsurer === "") {
    //   //   errors.tradeInsurer = 'tradeInsurer is Required';
    //   // }
    // } 
    else if (activeclass === 4) {
      if (!values.paymentMode) {
        errors.paymentMode = 'Payment Mode is a Mandatory Field';
      }
      // if (!values.buyerBank) {
      //   errors.buyerBank = 'buyerBank is Required';
      // }
      // if (!values.supplierBank) {
      //   errors.supplierBank = 'supplierBank is Required';
      // }
    } else if (activeclass === 5) {

      let keysArray = Object.keys(tnc)

      if (keysArray.length) {
        for (let i = 0; i < keysArray.length; i++) {
          if (!tnc[keysArray[i]].value) {
            errors.tnc = 'Make Sure all the Values are filled, Check TNC key named - ' + tnc[keysArray[i]].name;
            break;
          }
        }
      } else {
        errors.tnc = 'TNC is Required';
      }

    }
  }
  console.log(errors)
  return errors;

}
