import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const tabs = [
  { name: "Credit summary" },
  // { name: "Buyer financial" },
  // { name: "Credit report" }
]

export const CustomProgressBar = ({ min, max, value, reverse, textValue }) => {

  const bars = [
    { id: 1, activeColor: "bg-8DE4A8 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: "left-bar-border mr-1" },
    { id: 2, activeColor: "bg-F8EB74 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: 'mr-1' },
    { id: 3, activeColor: "bg-FFCA72 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: 'mr-1' },
    { id: 4, activeColor: "bg-FF5833 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: "rigth-bar-border" }
  ]

  const reverseBars = [
    { id: 1, activeColor: "bg-FF5833 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: "left-bar-border mr-1" },
    { id: 2, activeColor: "bg-FFCA72 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: 'mr-1' },
    { id: 3, activeColor: "bg-F8EB74 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: 'mr-1' },
    { id: 4, activeColor: "bg-8DE4A8 pt-1", inactiveColor: "bg-EEEEEE", additionalClass: "rigth-bar-border" }
  ]

  let useBars = reverse ? reverseBars : bars

  const [minValue, setMinValue] = useState(min)
  const [maxValue, setMaxValue] = useState(max)
  const [activeBar, setActiveBar] = useState(null)

  useEffect(() => {
    let diff = maxValue - (minValue - 1)
    let percentage = value / diff
    if (percentage > 0 && percentage <= 0.25) {
      setActiveBar(1)
    }
    else if (percentage > 0.25 && percentage <= 0.50) {
      setActiveBar(2)
    }
    else if (percentage > 0.50 && percentage <= 0.75) {
      setActiveBar(3)
    }
    else if (percentage > 0.75 && percentage <= 1) {
      setActiveBar(4)
    }
  }, [value])

  return (
    <div className="progress-bar-div p-0"
    >
      {useBars.map((item) => {
        return (
          <div className={`${item.id === activeBar ? item.activeColor : item.inactiveColor} ${item.additionalClass || ""} text-center
          font-wt-600 w-25 h-100`}
          >
            {item.id === activeBar ? (textValue || value) : ""}
          </div>
        )
      })}

    </div>
  )
}

const ProgressBars = [
  // { name: "Portpolio comparision score", min: 1, max: 9 },
  { name: "Failure score", min: 1, max: 100, key: "failure_score" },
  { name: "Delinqency score", min: 1, max: 100, key: "delinquency_score" },
  { name: "Paydex score", min: 1, max: 100, key: "paydex_scrore", reverse: true }
]

const financeDetails = [
  { year: "2020-2021", turnover: "$ 1,50,000", netProfit: "$ 100", netWorth: "$ 500" },
  { year: "2020-2021", turnover: "$ 1,50,000", netProfit: "$ 100", netWorth: "$ 500" },
  { year: "2020-2021", turnover: "$ 1,50,000", netProfit: "$ 100", netWorth: "$ 500" }
]

const CheckBuyerHealth = ({ userTokenDetails, navToggleState, data }) => {

  console.log("CheckBuyerHealth", data);

  const [tab, setTab] = useState(0)

  const handleValidation = (index) => {
    setTab(index)

  }

  return (
    <div>
      {/* <i
        onClick={() => {
          window.location.reload()
        }}
        class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i> */}
      <a className="cursor"
        onClick={() => {
          window.location.reload()
        }}
      ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
      <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
        {tabs.map((item, index) => {
          return (
            <li>
              <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")} onClick={() => {
                handleValidation(index)
              }}>{item.name}</a>
            </li>
          )
        })}
      </ul>
      <div className='bg-white pb-5'
        style={{
          "height": "40rem",
          "overflow": "auto"
        }}>

        {tab === 0 ? (
          <div className='px-4 py-5'>
            <label className="font-size-16 text-color1 font-wt-600" >Maximum credit recommendation : NA</label>
            <div className="row d-flex px-2 pt-2">
              {ProgressBars.map((item, index) => {
                return (
                  <div className="bg-white w-35 card-layout mr-5 mb-4 px-4 py-2">
                    <label className="font-wt-400 font-size-14" >{item.name}</label>
                    <CustomProgressBar reverse={item.reverse}
                      value={data[item.key] ? data[item.key] / 1 : 0}
                      min={item.min} max={item.max} />
                    <div className="row pt-2 d-flex">
                      <div className="w-50">
                        <label>{item.reverse ? "High Risk" : "Low risk :"} <label className="font-wt-600">{item.min}</label></label>
                      </div>
                      <div className="w-50 text-right">
                        <label>{item.reverse ? "Low Risk" : "High risk :"} <label className="font-wt-600">{item.max}</label></label>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}

        {tab === 1 ? (
          <div className='px-4 py-5'>
            <label className="font-size-16 font-wt-600" >Finance Details</label>
            <div className="row d-flex px-2 pt-2">
              {financeDetails.map((item, index) => {
                return (
                  <div>
                    <label>Financial year</label>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}

      </div>

    </div>
  )
}

export default CheckBuyerHealth;