import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import { most_used_currencies, getDocDetails } from "../../utils/myFunctions";
import { NewSelect, InputWithSelect } from "../../utils/newInput";
import { FileInput } from "../../utils/FileInput";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ViewFinancers from "./components/viewFinacersModal";


const selectFinancedata = [
  { companyName: "Stenn", logo: "assets/images/supplier-images/stenn-logo.png", rating: 4.6, reviews: 126 },
  { companyName: "Modifi", logo: "assets/images/supplier-images/modifi-logo.png", rating: 4.6, reviews: 126 },
  { companyName: "Drip", logo: "assets/images/supplier-images/Drip-logo.png", rating: 4.6, reviews: 126 },
  { companyName: "Federal bank", logo: "assets/images/supplier-images/federal-logo.png", rating: 4.6, reviews: 126 },
]


const QuickFinance = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")

  let onlyViewMode = localStorage.getItem('onlyViewMode')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)


  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([])

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState(false);

  const [display, setDisplay] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [limitset, setLimitset] = useState(false);

  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }

  const closeFn = () => {
    setLimitinvoice(true);
  }

  const closeExpFn = () => {
    setExperience(false);
  }

  const ViewerFun = () => {
    setFinancierData(false);
  }

  const closeLimit = () => {
    setLimitset(false);
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "Buyername") {
      setLimitset(true);
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData[0]
      let buyers_credit = JSON.parse(selectedBuyer.buyers_credit)


      for (let index = 0; index < buyers_credit.length; index++) {
        const element = buyers_credit[index];
        if (element.lender_name.toLowerCase().includes("stenn")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (element.lender_name.toLowerCase().includes("modifi")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }
      let termSheetDocument = await getDocDetails(localStorage.getItem("termSheet"))
      let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
      let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])

      let otherDocsObj = {}

      if (selectedBuyer.buyerOtherDocs) {
        let buyerOtherDocs = selectedBuyer.buyerOtherDocs.split(":")
        for (let index = 0; index < buyerOtherDocs.length; index++) {
          const element = buyerOtherDocs[index];
          otherDocsObj["otherDoc" + index] = await getDocDetails(element)
          addMoreDoc.push(null)
        }
      }

      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        termSheetDocument, invoiceDocument, poDocument, ...otherDocsObj, buyers_credit
      })

    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="QuickFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Quick Finance"}
              userTokenDetails={userTokenDetails} />
            <div className="card p-5 mt-3 border-0">
              <a className="cursor"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
              <h6 className="font-size-14 font-wt-500 text-center">Quick Finance</h6>

              <div className="row mt-3">
                <div className="col-md-6 mt-5">
                  <div className="col-md-8" >
                    <a>
                      <NewSelect isAstrix={true} label={"Enter buyer name"}
                        selectData={countrys} name={"Buyername"}
                        value={data.buyerCountry} optionLabel={"name"} optionValue={'sortname'}
                        onChange={handleChange} error={errors.buyerCountry} />
                    </a>

                  </div>
                  <div className="col-md-8">
                    <InputWithSelect isAstrix={true} type={"text"} label={"Enter finance amount"}
                      selectData={currencyData}
                      selectName={"Commodity"} selectValue={data.limitRequiredCurrency}
                      optionLabel={"code"} optionValue={'code'}
                      name={'limitRequired'} value={data.limitRequired} error={errors.limitRequired}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="font-size-13 ">Select finance type</p>
                  <div className="d-flex mb-3">
                    <div className="form-check cursor ps-0 col-5">
                      <input onClick={() => setDisplay(true)} className="form-check-input" type="radio" name="LCconfirmation" id="LCconfirmation" />
                      <label className="form-check-label" for="LCconfirmation">
                        LC confirmation
                      </label>
                    </div>
                    <div className="col-5 align-items-center d-flex gap-1">
                      <img src={"assets/images/dollar.png"} alt="Back" height={15} width={15} />
                      <p className="mb-0 font-size-13 text-color1">100 coins</p>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="form-check cursor ps-0 col-5">
                      <input onClick={() => setDisplay(true)} className="form-check-input" type="radio" name="LCdiscounting" id="LCdiscounting" />
                      <label className="form-check-label" for="LCdiscounting">
                        LC discounting
                      </label>
                    </div>
                    <div className="col-5 align-items-center d-flex gap-1">
                      <img src={"assets/images/dollar.png"} alt="Back" height={15} width={15} />
                      <p className="mb-0 font-size-13 text-color1">100 coins</p>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="form-check cursor ps-0 col-5">
                      <input onClick={() => setDisplay(true)} className="form-check-input" type="radio" name="LCdiscounting" id="LCdiscounting" />
                      <label className="form-check-label" for="LCdiscounting">
                        Invoice discounting
                      </label>
                    </div>
                    <div className="col-5 align-items-center d-flex gap-1">
                      <img src={"assets/images/dollar.png"} alt="Back" height={15} width={15} />
                      <p className="mb-0 font-size-13 text-color1">100 coins</p>
                    </div>
                  </div>

                </div>
              </div>

              {display && <>
                <div className="col-md-4 mt-3">
                  <label className="font-size-14">Draft LC</label>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                        isEditable={false} />
                    </div>
                  </div>
                  <div className="">
                    <a onClick={() => setSigndoc(true)} className="text-custom2 cursor font-wt-500 font-size-13"><img className=" me-1" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />Sign document</a>
                  </div>
                </div>
                <div className="col-md-7 mt-4">
                  <p className="font-size-13 ">I agree to:</p>
                  <div className='mt-3'>
                    <img
                      onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                      className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                    <label>Share my company’s profile with all the financers.</label>
                  </div>
                  <div className='mt-3'>
                    <img
                      onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                      className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                    <label>Share my company’s financial report with all the financers.</label>
                  </div>
                  <div className='mt-3'>
                    <img
                      onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                      className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                    <label>Share my company’s KYC details with all the financers.</label>
                  </div>
                </div>
              </>}
              <div className="col-md-7 mt-5">
                <button type="button" onClick={() => closeFn()}
                  className={`mx-2 new-btn w-25 py-2 px-2 text-white`}>
                  Get Finance Limit
                </button>
                <button type="button" onClick={() => setFinancierData(true)}
                  className={`mx-2 viewfinancersbtn w-25 py-2 px-2 text-color1`}>
                  View financers
                </button>
              </div>

            </div>
          </main>
        </div>
      </div >
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {
        limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
          <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
          <p>Your Application for Finance limit has been submitted successfully !</p>
        </FinanceInvoiceModal>
      }

      {
        experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
          <img src={"/assets/images/lc/coins.png"} alt="" className="mb-3" />
          <p>100 coins debited from your wallet for finance limit application. </p>
          <p>Available balance : <img src={"/assets/images/sidebarV2/coin.png"} alt="" /> <span>10,000</span></p>
        </FinanceInvoiceModal >
      }
      {financierData && <ViewFinancers limitinvoice={financierData} setLimitinvoice={setFinancierData} closeSuccess={ViewerFun} >
        <h5>Financers</h5>
        <div className="row justify-content-center">
          {selectFinancedata.map((key, index) => {
            return (
              <div className="col-xl-5 col-lg-4 col-md-6 col-sm-12 my-3">
                <div className={`card p-4 col-10 text-start ${key.ribbon ? "selectfinancercardrec" : "selectfinancercard"}`}>
                  <p className="cursor"><img className="ms-2 img-fluid" src={key.logo} alt="" /> <span className="font-size-14 font-wt-600 text-dark">{key.companyName}</span></p>
                  <div className="d-flex px-2">
                    <div className="col-6 px-0"><p className="mb-0 font-size-14">{key.rating}</p>
                      <span><img src={"assets/images/supplier-images/Rating.png"} alt="" />
                        <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                        <img src={"assets/images/supplier-images/Rating.png"} alt="" /></span>
                      <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                      <img src={"assets/images/supplier-images/Rating.png"} alt="" />
                    </div>
                    <div className="col-6 px-0"><p className="mb-1 font-size-14">{key.reviews}</p><p className="font10 font-size-14 ">See reviews</p></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ViewFinancers>}

      {
        limitset && <FinanceInvoiceModal limitinvoice={limitset} setLimitinvoice={setLimitset} closeSuccess={closeLimit} >
          <img src={"/assets/images/WarningBig.png"} alt="" className="mb-3" />
          <p>Already applied for limit !</p>
          <button type="button" onClick={() => setFinancierData(true)}
            className={`viewfinancersbtn py-2 px-3 text-color1`}>
            See quotes
          </button>
          <button type="button" onClick={() => closeFn()}
            className={`new-btn py-2 px-3 ms-4 text-white`}>
            Increase limit
          </button>
        </FinanceInvoiceModal>
      }
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(QuickFinance)