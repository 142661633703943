import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import call from "../service";
import { FileInput } from "../utils/FileInput";
import { convertImageToPdf, getDocDetails } from "../utils/myFunctions";
import { NewInput } from "../utils/newInput";
import toastDisplay from "../utils/toastNotification";
import SignDocument from "./InvoiceDiscounting/components/SignDocument";

export const AddMoreDocumentHandler = ({ displayHorizontal, purpose, purposeId, userTokenDetails, onChange, hide, notEditable, hideAddMoreOption, titleNotEditable, changeEditIconToUpload, showInfoIcon, documentInfoRender, hideSignDocument, colFixedLength,
  onDocumentAdd }) => {

  const [addMoreDoc, setAddMoreDoc] = useState(hideAddMoreOption ? [] : [{ doc_name: 'Add More' }])
  const [tempValue, setTempValue] = useState("")
  const [docNamePicker, toggleDocNamePicker] = useState({ show: false, id: null })
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [signdoc, setSigndoc] = useState(false);
  const [showLoader, setshowLoader] = useState(false)
  const [infoopenone, setInfoopenone] = useState({});

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", purpose, addMoreDoc);

  useEffect(() => {
    if (purpose && purposeId) {
      getDocs()
    }
  }, [purpose, purposeId])

  useEffect(() => {
    onChange(addMoreDoc)
  }, [addMoreDoc])

  async function getDocs() {
    call('POST', 'getMoreDocs', { purpose, purposeId }).then(async (result) => {
      // console.log("then in getdocs", { purpose, purposeId }, result);
      if (result.length) {
        let tempAddMoreDoc = []
        let tempFileObj = {}
        setshowLoader(true)
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          tempFileObj["addMore" + index] = await getDocDetails(element.docId)
          tempAddMoreDoc.push({ ...tempFileObj["addMore" + index] })
        }
        if (!hideAddMoreOption) {
          tempAddMoreDoc.push({ doc_name: 'Add More' })
        }
        setshowLoader(false)
        setData({ ...tempFileObj })
        setAddMoreDoc([...tempAddMoreDoc])
      }
      else {
        if (!hideAddMoreOption) {
          setAddMoreDoc([{ doc_name: 'Add More' }])
        }
        else {
          setAddMoreDoc([])
        }
      }
    }).catch(err => {
      console.log("errror in getMoreDocs", err);
    })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl

        setshowLoader(true)
        let fileIndex = event.target.name.split("More")[1]
        let formData = new FormData()
        formData.append("file", fileObj)
        formData.append("doc_name", addMoreDoc[fileIndex]["doc_name"] === "Add More" ? fileObj.name.split(".")[0] : addMoreDoc[fileIndex]["doc_name"])
        formData.append("userId", userId)
        formData.append("purpose", purpose)
        formData.append("purposeId", purposeId)
        await call('POST', 'addMoreDoc', formData)
        // Callback after document is added successfully start
        if (onDocumentAdd) {
          onDocumentAdd(fileObj)
        }
        // Callback after document is added successfully end
        setshowLoader(false)
        getDocs()
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  return (
    <div className={hide ? 'd-none' : '' + (displayHorizontal ? " d-flex flex-row flex-wrap " : "")}>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {addMoreDoc.map((item, index) => {
        return (
          <div className={`mb-3 ${displayHorizontal ? ' col-md-5 ' : ''}`}>
            {docNamePicker.id === index ? (
              <div className="w-25 d-inline-block">
                <NewInput name={"addMore" + index} value={tempValue}
                  onChange={(e) => setTempValue(e.target.value)} />
              </div>) : (
              <label className="font-size-14">{item.doc_name}</label>
            )}
            <span>
              {docNamePicker.id === index ? (
                <>
                  <img
                    onClick={async () => {
                      if (tempValue) {
                        let temp = addMoreDoc
                        temp[index]['doc_name'] = tempValue
                        setAddMoreDoc([...temp])
                        toggleDocNamePicker({ show: false, id: null })
                        if (item.doc_id) {
                          setshowLoader(true)
                          let formData = new FormData()
                          formData.append("docId", item.doc_id)
                          formData.append('updateOnlyDocName', true)
                          formData.append("doc_name", tempValue)
                          formData.append("userTypeId", userTypeId)
                          await call('POST', 'editMoreDoc', formData)
                          setshowLoader(false)
                        }
                      }
                      else {
                        toastDisplay('Please enter document name', "info")
                      }
                    }}
                    style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/ok-icon.png"} />
                  <img
                    onClick={() => { toggleDocNamePicker({ show: false, id: null }) }}
                    style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/cross-icon.png"} />
                </>
              ) : notEditable ? null :
                titleNotEditable ? null :
                  (
                    <img
                      onClick={() => { setTempValue(item.doc_name); toggleDocNamePicker({ show: true, id: index }) }}
                      style={{ width: "20px", height: "20px" }} className="ml-2 cursor" src={"assets/images/edit-icon.png"} />)}
              {showInfoIcon ? (
                <img
                  onClick={() => setInfoopenone({ ...infoopenone, ["addMore" + index]: !infoopenone["addMore" + index] })}
                  className="w-auto cursor ml-2" src={"assets/images/warning.png"} alt="" />
              ) : null}
            </span>
            {!infoopenone["addMore" + index] && <p className="my-2 w-100 font-wt-300 font-size-13 color434343 letter-spacing04 lh-18" >
              {documentInfoRender}
            </p>}
            <div className="row align-items-center">
              <div className={`form-group ${hideSignDocument ? ` col-md-${colFixedLength || 12} ` : ` col-md-${colFixedLength || 8} `} mb-0`}>
                <FileInput name={"addMore" + index} value={item.name ? data["addMore" + index] : null} error={errors["addMore" + index]}
                  onChange={handleFile} isEditable={notEditable ? false : true}
                  onUploadCancel={async () => {
                    setshowLoader(true)
                    await call('POST', 'deleteMoreDoc', { docId: item.doc_id })
                    setshowLoader(false)
                    getDocs()
                  }}
                  changeEditIconToUpload={changeEditIconToUpload}
                />
                {errors["addMore" + index] ? <div class="text-danger mt-2">
                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                  <b>{errors["addMore" + index]}</b></div> : ''}
              </div>
              {
                (!data?.["addMore" + index]?.signatureId && data?.["addMore" + index]?.name) && !hideSignDocument ? (
                  <div className="col-md-4 text-end">
                    <a onClick={async () => {
                      setSigndoc(true);
                      setData({ ...data, docToSign: data["addMore" + index], docSignName: "addMore" + index })

                    }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                  </div>
                ) : null}

              {data?.["addMore" + index]?.signatureId && !hideSignDocument ? (
                <div className="col-md-4 text-end">
                  <p className="cursor"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>) : null}
            </div>
          </div>
        )
      })}
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={async (updatedDoc) => {
          setData({ ...data, [data.docSignName]: updatedDoc })
          let fileIndex = data.docSignName.split("More")[1]
          console.log("fileIndex====================>", fileIndex, addMoreDoc);
          setshowLoader(true)
          let formData = new FormData()
          formData.append("docId", addMoreDoc[fileIndex]["doc_id"])
          formData.append('doc_name', addMoreDoc[fileIndex]["doc_name"])
          formData.append("file", updatedDoc)
          formData.append("signId", updatedDoc.signatureId)
          formData.append("userTypeId", userTypeId)
          await call('POST', 'editMoreDoc', formData)
          setshowLoader(false)
        }
        } />}
    </div>
  )
}