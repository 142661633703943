import React, { useEffect, useState } from 'react';
import call from "../../service";
import { connect } from "react-redux";
import { setoffContract } from '../../store/actions/action';
import toastDisplay from "../../utils/toastNotification";
import toolTipText from '../../utils/toolTips/financeForm.json'
import { formatDate_Application } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile'
import OtherDocs from "../finance/financeApply/otherDocs";
import validate from '../../utils/validation_files/addOfflineContractDirectory'
import ReactCountryFlag from "react-country-flag";


const AddOfflineContractDirectory = ({ userTokenDetails, offContract, setoffContract, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // Const State and their handlers declaration and initiation.
  const [buyerArr, setBuyerArr] = useState([]);
  const [buyerType, setBuyerType] = useState(1);
  const [currencyData, setcurrencyData] = useState([]);
  const [data, setData] = useState({});
  const [docData, setDocData] = useState({});

  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accord, setAccord] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true
  });
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [fileData, setfileData] = useState({});
  const [countryData, setcountryData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [paymentModes, setpaymentModes] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    if (offContract.info.type === "update" || offContract.info.type === "view") {
      setData(offContract.info.jsonData)

      call("post", "getdircontractdetails", { dirContractId: offContract.info.id, onlyDoc: true }).then((result) => {
        console.log("result in getdircontractdetails", result);

        setDocData(result);
      }).catch((e) => {
        console.log("error in getdircontractdetails", e);
      });

    }

    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    call('get', 'getpaymentmodes').then((result) => {
      console.log("getpaymentmodes api result->", result)
      setpaymentModes(result)
    }).catch((e) => {
      console.log('error in getpaymentmodes', e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (buyerType === 1) {
      call('POST', 'getUsersListing', { "searchUserType": 3, "searchKey": '', "network": "innetwork", "userAvatars": false })
        .then((result) => {
          console.log("result in getUsersListing", result);
          setBuyerArr(result);
          setshowLoader(false)
        })
        .catch((e) => {
          console.log("error in getUsersListing", e);
        });
    } else if (buyerType === 2) {
      call('POST', 'getuserdirectorybytype', { "searchUserType": 3, "searchKey": '' })
        .then((result) => {
          console.log("result in getuserdirectorybytype", result);
          setBuyerArr(result);
          setshowLoader(false)

        })
        .catch((e) => {
          console.log("error in getUsersListing", e);
        });
    }

  }, [buyerType]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // console.log("data====>", data)
  // console.log("file====>", file)
  // console.log("error====>", error)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const handleFiles = (event) => {
    event.persist();
    setFile({ ...file, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, file, offContract.info.type));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Functions



  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      // console.log("value in doc render=>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.doc_name) ? values.doc_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Ports List
  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort unloadPort', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Payment options list
  function getPaymentOptionslist() {
    let PaymentOptionslist = paymentModes ? paymentModes.map((value, index) => {
      return (
        <>
          <div className="switch-field ml-1 mr-1">
            <input type="radio" name="dirPaymentMode" id={"dirPaymentMode" + value.id} value={value.id} checked={(data.dirPaymentMode / 1 === value.id) ? true : false} onChange={handleChange} disabled={offContract.info.type === "view"} />
            <label for={"dirPaymentMode" + value.id}>{value.payment_type_pretty}</label>
          </div>
        </>
      )
    }) : ''
    return PaymentOptionslist
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Submit handlers

  const handleSubmit = (event) => {

    if (offContract.info.type === "view") {
      toastDisplay("You can not call update in view mode", "warn");
      return
    }

    setshowLoader(true)
    let currentRoute
    if (offContract.info.type === "create") {
      currentRoute = "adddirectorycontract"
    } else if (offContract.info.type === "update") {
      currentRoute = "updatedirectorycontract"
    }

    data.sellerId = userId
    data.buyerType = buyerType
    if (offContract.info.type === "update") {
      data.dirContractPrimaryId = offContract.info.id
    }

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    Object.keys(file).forEach((key) => {
      formData.append(key, file[key]);
    })

    call("post", currentRoute, formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay(result, "success");
        setoffContract(false, { refresh: true })
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in create finance modal", e);
      });
  };

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 pt-2 pb-5 mt-5">
        <h1 className="text-center"><u>Add Contract in your Directory </u></h1><br />

        <div className={"accordionItem mt-3" + (accord['1'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Contract Details</h2>
          <div className="accordionItemContent">
            <div className="col-md-12 row">

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Contract Number  {astrix} {toolTip('dirContractNo')}</label>
                  <div className="col-md-12">
                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={data.dirContractNo} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirContractNo ? <div class="text-danger error-contract">{error.dirContractNo}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Contract Name  {astrix} {toolTip('dirContractName')}</label>
                  <div className="col-md-12">
                    <input type="text" className={"form-control" + (error.dirContractName ? " border-danger" : "")} name="dirContractName" value={data.dirContractName} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirContractName ? <div class="text-danger error-contract">{error.dirContractName}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Contract Type {astrix}  {toolTip('dirContractType')}</label>
                  <div className="col-md-12">
                    <input type="text" className={"form-control" + (error.dirContractType ? " border-danger" : "")} name="dirContractType" value={data.dirContractType} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirContractType ? <div class="text-danger error-contract">{error.dirContractType}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row form-group">
                  <label className="col-md-12">Data Line {toolTip('dirDataLine')}</label>
                  <div className="col-md-12">
                    <select className="form-control" name="buyerType" value={data.buyerType} onChange={(event) => {
                      setshowLoader(true); setBuyerType(event.target.value / 1)
                    }} disabled={offContract.info.type === "view"}>
                      <option value="1" selected>Network</option>
                      <option value="2">Directory</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">Select Buyer {astrix}  {toolTip('buyerId')}</label>
                  {/* <label className="col-md-6">
                    {buyerType === 1 ? <b>Network Users</b> : "Network Users"}
                    {buyerType === 1 ? <button className="btn btn-sm btn-primary ml-2 mr-2 pt-0 pb-0" onClick={() => {
                      setshowLoader(true); setBuyerType(2)
                    }} disabled={(offContract.info.type === "update" || offContract.info.type === "create") ? false : true}><i class="fas fa-toggle-off"></i></button> :
                      buyerType === 2 ? <button className="btn btn-sm btn-primary ml-2 mr-2 pt-0 pb-0" onClick={() => {
                        setshowLoader(true); setBuyerType(1)
                      }} disabled={(offContract.info.type === "update" || offContract.info.type === "create") ? false : true}><i class="fas fa-toggle-on"></i></button> :
                        ""}
                    {buyerType === 2 ? <b>Directory Users</b> : "Directory Users"} </label> */}
                  <div className="col-md-12">
                    <select className={"form-control" + (error.buyerId ? " border-danger" : "")} name="buyerId" value={data.buyerId} onChange={handleChange} disabled={offContract.info.type === "view"}>
                      <option value="" selected>--Select Buyer--</option>
                      <option value="ME">(N/A) Enter Manually</option>
                      {buyerArr.map((buyer) => {
                        return (
                          <option value={buyer.id} >{buyer.company_name}</option>
                        )
                      })}
                    </select>
                    {error.buyerId ? <div class="text-danger error-contract">{error.buyerId}</div> : ''}
                  </div>
                </div>
              </div>

              {(data.buyerId === "ME") && <>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Buyer Name (Manual Entry) {astrix} {toolTip('dirbuyerNameManual')}</label>
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="dirbuyerNameManual" value={data.dirbuyerNameManual} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                      {error.dirbuyerNameManual ? <div class="text-danger error-contract">{error.dirbuyerNameManual}</div> : ''}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Buyer Address (Manual Entry) {astrix} {toolTip('drbuyerAddrManual')}</label>
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="drbuyerAddrManual" value={data.drbuyerAddrManual} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                      {error.drbuyerAddrManual ? <div class="text-danger error-contract">{error.drbuyerAddrManual}</div> : ''}
                    </div>
                  </div>
                </div>
              </>}


              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Laycan Start {astrix}  {toolTip('dirLaycanStart')}</label>
                  <div className="col-md-12">
                    <input type="date" name="dirLaycanStart" value={data.dirLaycanStart} className={"form-control" + (error.dirLaycanStart ? " border-danger" : "")} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirLaycanStart ? <div class="text-danger error-contract">{error.dirLaycanStart}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Laycan End {astrix}  {toolTip('dirLaycanEnd')}</label>
                  <div className="col-md-12">
                    <input type="date" name="dirLaycanEnd" value={data.dirLaycanEnd} className={"form-control" + (error.dirLaycanEnd ? " border-danger" : "")} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirLaycanEnd ? <div class="text-danger error-contract">{error.dirLaycanEnd}</div> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"accordionItem" + (accord['2'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Commodity Details</h2>
          < div className="accordionItemContent" >
            <div className="col-md-12 row">

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Commodity Category {astrix}  {toolTip('dirCommodityCategory')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirCommodityCategory ? " border-danger" : "")} type="text" name="dirCommodityCategory" value={data.dirCommodityCategory} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirCommodityCategory ? <div class="text-danger error-contract">{error.dirCommodityCategory}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Commodity {astrix}  {toolTip('dirCommodity')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirCommodity ? " border-danger" : "")} type="text" name="dirCommodity" value={data.dirCommodity} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirCommodity ? <div class="text-danger error-contract">{error.dirCommodity}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group flag-relative" id="POScountry">
                  <div className="flag-icon">
                    {data.dirCommCountryOrigin ? <span className="shadow">
                      <ReactCountryFlag
                        countryCode={data.dirCommCountryOrigin}
                        style={{ width: '25px', height: '25px' }} svg />
                    </span> :
                      <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image" />}
                  </div>
                  <label>Country of Origin {astrix}  </label>
                  <select className={"form-control" + (error.dirCommCountryOrigin ? " border-danger" : "")} name="dirCommCountryOrigin" value={data.dirCommCountryOrigin} onChange={handleChange} readOnly={offContract.info.type === "view"} >
                    <option value="" selected>Select Country</option>
                    {countryData.map((country) => {
                      return (
                        <option value={country.sortname} >{country.name}</option>
                      )
                    })}
                  </select>
                  {error.dirCommCountryOrigin ? <div class="text-danger error-contract">{error.dirCommCountryOrigin}</div> : ''}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Quantity (mt){astrix}  {toolTip('dirQuantity')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirQuantity ? " border-danger" : "")} type="text" name="dirQuantity" value={data.dirQuantity} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirQuantity ? <div class="text-danger error-contract">{error.dirQuantity}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row form-group">
                  <label className="col-md-12">Currency {astrix}  {toolTip('dirCurrency')}</label>
                  <div className="col-md-12 ">
                    <select className={"form-control" + (error.dirCurrency ? " border-danger" : "")} name="dirCurrency" value={data.dirCurrency} onChange={handleChange} disabled={offContract.info.type === "view"}>
                      <option value="">--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.id + ':' + currency.name} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {error.dirCurrency ? <div class="text-danger error-contract">{error.dirCurrency}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">Price/Unit {astrix}  {toolTip('dirPrice')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirPrice ? " border-danger" : "")} type="text" name="dirPrice" value={data.dirPrice} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirPrice ? <div class="text-danger error-contract">{error.dirPrice}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row form-group">
                  <label className="col-md-12">Currency {astrix}  {toolTip('dirCurrency')}</label>
                  <div className="col-md-12 ">
                    <select className={"form-control" + (error.dirCurrency ? " border-danger" : "")} name="dirCurrency" value={data.dirCurrency} onChange={handleChange} disabled>
                      <option value="">--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.id + ':' + currency.name} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {error.dirCurrency ? <div class="text-danger error-contract">{error.dirCurrency}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">Total Invoice value {astrix}  {toolTip('dirInvoicePrice')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirInvoicePrice ? " border-danger" : "")} type="text" name="dirInvoicePrice" value={data.dirInvoicePrice} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirInvoicePrice ? <div class="text-danger error-contract">{error.dirInvoicePrice}</div> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"accordionItem" + (accord['3'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '3': !accord['3'] })}>Shipping Details</h2>
          <div className="accordionItemContent">
            <div className="col-md-12 row">


              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Loading Country {astrix} {toolTip('dirLoadingCountry')}</label>
                  <div className="col-md-12">
                    <select className={" form-control" + (!error.dirLoadingCountry ? '' : ' border-danger')} name="dirLoadingCountry" value={data.dirLoadingCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                      <option value="" selected>--Select country--</option>
                      {/* <CountryList /> */}
                      {
                        countryData.length ? countryData.map((item, index) => {
                          return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                        }) : ''
                      }
                    </select>
                    {error.dirLoadingCountry && <p className="text-danger error-contract">{error.dirLoadingCountry}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Loading Port {astrix} {toolTip('dirLoadingPort')}</label>
                  <div className="col-md-12">
                    <select className={" form-control" + (!error.dirLoadingPort ? '' : ' border-danger')} name="dirLoadingPort" value={data.dirLoadingPort} onChange={handleChange}>
                      <option value="" selected>--Select loading port--</option>
                      {portLoading.map((portLoading) => {
                        return (
                          <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                        )
                      })}
                    </select>
                    {error.loadingdirLoadingPortPort && <p className="text-danger error-contract">{error.dirLoadingPort}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Unloading Country {astrix} {toolTip('dirUnloadingCountry')}</label>
                  <div className="col-md-12">
                    <select className={" form-control" + (!error.dirUnloadingCountry ? '' : ' border-danger')} name="dirUnloadingCountry" value={data.dirUnloadingCountry} onChange={(event) => { handleChange(event); unloadPort(event); }}>
                      <option value="" selected>--Select country--</option>
                      {/* <CountryList /> */}
                      {
                        countryData.length ? countryData.map((item, index) => {
                          return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                        }) : ''
                      }
                    </select>
                    {error.dirUnloadingCountry && <p className="text-danger error-contract">{error.dirUnloadingCountry}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Unloading Port {astrix} {toolTip('dirUnloadingPort')}</label>
                  <div className="col-md-12">
                    <select className={" form-control" + (!error.dirUnloadingPort ? '' : ' border-danger')} name="dirUnloadingPort" value={data.dirUnloadingPort} onChange={handleChange}>
                      <option value="" selected>--Select Unloading port--</option>
                      {
                        portUnloading.map((portUnloading) => {
                          return (
                            <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                          )
                        })
                      }
                    </select>
                    {error.dirUnloadingPort && <p className="text-danger error-contract">{error.dirUnloadingPort}</p>}
                  </div>
                </div>
              </div>



              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Shipping Company {astrix}  {toolTip('dirShipper')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirShipper ? " border-danger" : "")} type="text" name="dirShipper" value={data.dirShipper} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirShipper ? <div class="text-danger error-contract">{error.dirShipper}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Vessel Name {astrix}  {toolTip('dirVessel')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirVessel ? " border-danger" : "")} type="text" name="dirVessel" value={data.dirVessel} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirVessel ? <div class="text-danger error-contract">{error.dirVessel}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Bl Number {astrix}  {toolTip('dirBLNumber')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirBLNumber ? " border-danger" : "")} type="text" name="dirBLNumber" value={data.dirBLNumber} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirBLNumber ? <div class="text-danger error-contract">{error.dirBLNumber}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">BL Date {astrix}  {toolTip('dirBLDate')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirBLDate ? " border-danger" : "")} type="date" name="dirBLDate" value={data.dirBLDate} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirBLDate ? <div class="text-danger error-contract">{error.dirBLDate}</div> : ''}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className={"accordionItem" + (accord['4'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '4': !accord['4'] })}>Payment Details</h2>
          <div className="accordionItemContent">
            <div className="col-md-12 row">

              <div className="col-md-12">
                <div className="row form-group">
                  <label className="col-md-12 mb-1">Payment Mode {astrix} {toolTip('paymentMode')}</label>
                  <div className="modal-padding row">
                    {
                      getPaymentOptionslist()
                    }
                  </div>
                </div>
                <hr />
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Invoice Number {astrix}  {toolTip('dirInvoiceNumber')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirInvoiceNumber ? " border-danger" : "")} type="text" name="dirInvoiceNumber" value={data.dirInvoiceNumber} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirInvoiceNumber ? <div class="text-danger error-contract">{error.dirInvoiceNumber}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Credit Tenor {astrix}  {toolTip('dirCredittenor')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirCredittenor ? " border-danger" : "")} type="number" name="dirCredittenor" value={data.dirCredittenor} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirCredittenor ? <div class="text-danger error-contract">{error.dirCredittenor}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Invoice Date {astrix}  {toolTip('dirInvoiceDate')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirInvoiceDate ? " border-danger" : "")} type="date" name="dirInvoiceDate" value={data.dirInvoiceDate} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirInvoiceDate ? <div class="text-danger error-contract">{error.dirInvoiceDate}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Invoice Due Date {astrix}  {toolTip('dirInvoiceDueDate')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.dirInvoiceDueDate ? " border-danger" : "")} type="date" name="dirInvoiceDueDate" value={data.dirInvoiceDueDate} onChange={handleChange} readOnly={offContract.info.type === "view"} />
                    {error.dirInvoiceDueDate ? <div class="text-danger error-contract">{error.dirInvoiceDueDate}</div> : ''}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className={"accordionItem" + (accord['5'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '5': !accord['5'] })}>Documents</h2>
          <div className="accordionItemContent">
            <div className="col-md-12">

              {(offContract.info.type === "create") ?
                <>
                  <div className="col-md-12 pt-2 pb-3">
                    <ul className="other-documents">
                      <li>
                        <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                      </li>
                      <li>
                        <h3 className={"custum-control" + (error.dirContractDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Contract Document {astrix}  {toolTip('dirContractDocument')}</h3>
                      </li>
                      <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"dirContractDocumentMM"} value={data.dirContractDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"dirContractDocumentYY"} value={data.dirContractDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li>
                      <li>
                        <div className="file-browse">
                          <button className="btn btn-primary btn-sm">Upload Document</button>
                          <input type="file" accept=".png,.jpg,.pdf" name="dirContractDocument" onChange={handleFiles} />
                        </div>
                      </li>
                      <li> </li>
                    </ul>
                    {file.dirContractDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.dirContractDocument.name}`}</div></div> : ""}
                    {error.dirContractDocument ? <div class="text-danger error-contract">{error.dirContractDocument}</div> : ''}
                  </div>
                  <hr />
                  <div className="col-md-12 pt-2 pb-3">
                    <ul className="other-documents">
                      <li>
                        <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                      </li>
                      <li>
                        <h3 className={"custum-control" + (error.dirInvoiceDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Invoice Document {astrix}  {toolTip('dirInvoiceDocument')}</h3>
                      </li>
                      <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"dirInvoiceDocumentMM"} value={data.dirInvoiceDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"dirInvoiceDocumentYY"} value={data.dirInvoiceDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li>
                      <li>
                        <div className="file-browse">
                          <button className="btn btn-primary btn-sm">Upload Document</button>
                          <input type="file" accept=".png,.jpg,.pdf" name="dirInvoiceDocument" onChange={handleFiles} />
                        </div>
                      </li>
                      <li> </li>
                    </ul>
                    {file.dirInvoiceDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.dirInvoiceDocument.name}`}</div></div> : ""}
                    {error.dirInvoiceDocument ? <div class="text-danger error-contract">{error.dirInvoiceDocument}</div> : ''}
                  </div>
                  <hr />
                  <div className="col-md-12 pt-2 pb-3">
                    <ul className="other-documents">
                      <li>
                        <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                      </li>
                      <li>
                        <h3 className={"custum-control" + (error.dirBLDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>BL Document {astrix}  {toolTip('dirBLDocument')}</h3>
                      </li>
                      <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"dirBLDocumentMM"} value={data.dirBLDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"dirBLDocumentYY"} value={data.dirBLDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li>
                      <li>
                        <div className="file-browse">
                          <button className="btn btn-primary btn-sm">Upload Document</button>
                          <input type="file" accept=".png,.jpg,.pdf" name="dirBLDocument" onChange={handleFiles} />
                        </div>
                      </li>
                      <li> </li>
                    </ul>
                    {file.dirBLDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.dirBLDocument.name}`}</div></div> : ""}
                    {error.dirBLDocument ? <div class="text-danger error-contract">{error.dirBLDocument}</div> : ''}
                  </div>
                  <hr />
                </> :
                <div className="col-md-12">
                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                      <thead>
                        <tr>
                          <th width="20">#</th>
                          <th>Type of Document</th>
                          <th width="200">Document Name</th>
                          <th width="200">Uploaded By</th>
                          <th width="150">Uploaded On</th>
                          <th className="text-center" width="150">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(docData && docData.length > 0) && docPreviewGenerator(docData)}
                      </tbody>
                    </table>
                  </div>
                </div>}

              {(offContract.info.type === "update" || offContract.info.type === "create") &&
                <OtherDocs
                  data={data}
                  setData={setData}
                  file={file}
                  handleFiles={handleFiles}
                  docLimit={2}
                />}


            </div>
          </div>
        </div>

        <div className="row">
        </div>

      </div>

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
      />}

      <hr className="pt-2 pb-2 text-primary" />
      <div className="col-md-12">
        <button type="button" className="btn btn-danger float-left btn-sm" onClick={() => { setoffContract(false, {}) }}>
          <i className="fa fa-chevron-left pr-2" aria-hidden="true"></i>Back
          </button>
        {offContract.info.type === "create" && <button type="button" className="btn btn-success float-right btn-sm" onClick={() => { preHandleSubmit() }}>
          Submit
          </button>}
        {offContract.info.type === "update" && <button type="button" className="btn btn-success float-right btn-sm" onClick={() => { preHandleSubmit() }}>
          Update
          </button>}
      </div>
    </>
  );
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    offContract: state.offContract
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setoffContract: (modal, info) => { dispatch(setoffContract({ modal: modal, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOfflineContractDirectory)
