

import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { FileInput } from '../../utils/FileInput';
import { handleMultipleFile, mergeMultiplePdfs } from '../../utils/myFunctions';

const DocUploadModal = ({ userTokenDetails, contractNo, setshowUploadModal, showUploadModal, refreshDoc, setrefreshDoc, mktPlaceShipment }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [docTypeData, setdocTypeData] = useState([])
  const [buttoncount, setbuttoncount] = useState(0);
  const [files, setfiles] = useState({});
  const [values, setValues] = useState({});
  const [parties, setParties] = useState([]);
  const [accorddata, setaccorddata] = useState({});
  const [docLength, setdocLength] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [errors, setErrors] = useState({})

  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    if (!mktPlaceShipment) {
      setshowLoader(true)
      call('get', 'getdoctypes').then(async (result) => {
        // console.log("api result in getdoctypes ->", result)
        setdocTypeData(result)
        checkUploadDocLength(result)
      }).catch((error) => {
        setshowLoader(false)
        console.log("error occur in getdoctypes ->", error)
      })
      call('POST', 'getContractUsers', { categoryId: showUploadModal.categoryid, contractNo: contractNo }).then((result) => {
        setParties(result);
      }).catch((e) => {
        console.log("Error while querying getContractUsers:", e);
      })
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    let temp = { ...values }
    temp[event.target.name.split(":")[1]][event.target.name.split(":")[0]] = event.target.value
    setValues(temp)
  };

  const handleFile = (event) => {
    event.persist();
    let temp = { ...values }
    temp[event.target.name.split(":")[1]][event.target.name] = event.target.files[0]
    console.log("temppppppppppppppppppppppppppppp", event.target.name, temp);
    setValues(temp)
    setfiles(files => ({ ...files, [event.target.name]: event.target.files[0] }));
  }

  const handleParty = (event) => {
    event.persist();
    let temp = { ...values }
    let keyArray = event.target.name.split(":")
    let key = keyArray[1], index = keyArray[2]
    temp[key].docACL[index][keyArray[0]] = keyArray[0] === "party" ? event.target.value : event.target.checked
    setValues(temp)
  }

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function addElement() {
    setValues(values => ({
      ...values,
      [buttoncount]: {
        "docType": "",
        "docName": "",
        "docMM": "",
        "docYY": "",
        "docACL": [{ party: userId, auth: true, upload: true, remove: true }],
        ["docFile:" + buttoncount]: "",
      }
    }));
    setbuttoncount(buttoncount + 1)
  }

  function removeElement(i) {
    let temp = { ...values }
    delete temp[i]
    setValues(temp)
  }

  function addParty(index) {
    let temp = { ...values }
    temp[index].docACL.push({ party: "", auth: false, upload: false, remove: false })
    setValues(temp)
  }

  function removeParty(key, index) {
    let temp = { ...values }
    temp[key].docACL.splice(index, 1)
    setValues(temp)
  }

  // console.log("values in doc modal-->", values)
  // console.log("files in doc modal-->", files)

  function checkUploadDocLength(data) {
    let count = 0
    data.map((item, index) => {
      if ((showUploadModal.categoryid / 1 === item.category_id / 1) && (!showUploadModal.uploadedDocArray.includes(item.document_name))) {
        count++;
      }
    })
    setdocLength(count)
    setshowLoader(false)
  }

  function getDoctypelist() {
    let result = docTypeData.length ? docTypeData.map((item, index) => {
      return (((showUploadModal.categoryid / 1 === item.category_id / 1) && (!showUploadModal.uploadedDocArray.includes(item.document_name))) ? <option value={item.id + ':' + item.category_id}>{item.document_name + " - " + item.pretty_name}</option> : "")
    }) : ''
    return result
  }

  function getPartieslist() {
    let result = parties.map((item) => {
      return (
        <option value={item.tbl_user_id}>{item.company_name}</option>
      )
    })
    return result
  }

  // console.log("valuesssssssssssssssssssssssss", values);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmitDocUpload() {

    // console.log("values in doc modal-->", values)
    setshowLoader(true)
    let formData = new FormData();

    if (mktPlaceShipment) {
      formData.append("offerId", contractNo);
      formData.append("type", showUploadModal.categoryName);
      formData.append("userId", userId);
      formData.append("userName", userName);
      Object.keys(files).forEach((key, index) => {
        if (values[index]["docName"]) {
          formData.append(values[index]["docName"], files[key])
        }
        else {
          formData.append(files[key]["name"], files[key]);
        }
      })
      call('POST', 'addMktPlaceContractDocs', formData).then((result) => {
        setshowLoader(false)
        setshowUploadModal(false)
        toastDisplay(result, "success")
        setrefreshDoc(refreshDoc + 1)
      }).catch(e => {
        setshowLoader(false)
        // console.log("Error while addMktPlaceContractDocs:", e);
        toastDisplay(e, "error")
      })
      return null
    }

    formData.append("userId", userId);
    formData.append("contractNo", contractNo);
    formData.append("filesinfo", JSON.stringify(values));

    Object.keys(files).forEach((key) => {
      formData.append(key, files[key]);
    })



    call('POST', 'insertcontractdoc', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        // console.log("result while insertcontractdoc:", result);
        setshowUploadModal(false)
        toastDisplay(result, "success")
        setrefreshDoc(refreshDoc + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      console.log("Error while insertcontractdoc:", e);
      toastDisplay(e, "error")
    })

  }
  //---------------------------------------------------------------------------------------------------------------------

  function renderList() {
    let keyArray = Object.keys(values)

    let result = keyArray.map((key) => {
      return (<>
        <div className="col-md-12 row">
          <div className="col-md-12">
            <ul className="other-documents">
              <li>
                <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
              </li>
              {!mktPlaceShipment ? (
                <li>
                  <select className="form-control" name={"docType:" + key} value={values[key]["docType"]} onChange={handleChange}>
                    <option value="" selected>--Select Doc Type--</option>
                    {docTypeData && getDoctypelist()}
                  </select>
                </li>
              ) : null}
              <li>
                <input type="text" className="custum-control" placeholder="Doc Name" name={"docName:" + key} value={values[key]["docName"]} onChange={handleChange} />
              </li>
              {!mktPlaceShipment ? (
                <li>
                  <div className="document-name-top">Valid Upto </div>
                  <div className="document-name-bottom">(If applicable )</div>
                </li>
              ) : null}
              {!mktPlaceShipment ? (
                <li>
                  <input type="text" className="custum-control year" name={"docMM:" + key} value={values[key]["docMM"]} placeholder="MM" maxlength="2" onChange={handleChange} />
                  <input type="text" className="custum-control year" name={"docYY:" + key} value={values[key]["docYY"]} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                </li>
              ) : null}
              <li>
                {/* <div className="file-browse">
                  <button className="btn bg-1ea3ae text-white btn-sm">Upload Document</button>                  
                  <input type="file" accept=".png,.jpg,.pdf" name={"docFile:" + key} value={values[key]["docType:" + key]} onChange={handleFile} />
                </div> */}
              </li>
              <li>
                <img className='mt-2' onClick={() => removeElement(key)} height="20" width="20" src='assets/images/delete.png' />
                {/* <button type="button" class="delete-icon" onClick={() => removeElement(key)}><i class="fa fa-trash"></i></button> */}
              </li>
            </ul>
            <div className='w-40 mt-3' >
              <FileInput multipleDragDropEnable={true}
                onMultipleDragDrop={async (e) => {
                  setshowLoader(true)
                  let tempFilesArr = []
                  let tempData = values
                  let tempFiles = files
                  let tempErrors = errors
                  for (let index = 0; index < e.target.files.length; index++) {
                    let element = e.target.files[index];
                    let customEvent = {
                      target: { name: "docFile:" + key, files: [element] },
                      persist: () => console.log("...")
                    }
                    let resp = await handleMultipleFile(customEvent)
                    if (resp.success) {
                      tempFilesArr.push(resp.msg)
                    }
                    else {
                      tempErrors = { ...tempErrors, ...resp.msg }
                    }
                  }
                  if (tempFilesArr.length) {
                    let mergedFileObj = await mergeMultiplePdfs(tempFilesArr, "docFile:" + key)
                    tempData[("docFile:" + key).split(":")[1]]["docFile:" + key] = mergedFileObj
                    tempFiles["docFile:" + key] = mergedFileObj
                  }
                  console.log("tempDataaaaaaaaaaaaaaaaaaaaaaaaa", tempData, tempFiles, tempErrors);
                  setValues(tempData)
                  setfiles(tempFiles)
                  setErrors(tempErrors)
                  setshowLoader(false)
                }}
                value={values[key]["docFile:" + key]}
                error={errors["docFile:" + key]}
                isEditable={true}
                onUploadCancel={() => {
                  setValues({ ...values, [("docFile:" + key).split(":")[1]]: { ...values[("docFile:" + key).split(":")[1]], ["docFile:" + key]: null } })
                  setfiles({ ...files, ["docFile:" + key]: null })
                }}
              />
              {errors["bgTextDocument"] ? <div class="text-danger mt-2 font-size-12">
                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                <b>{errors["bgTextDocument"]}</b></div> : ''}
            </div></div>
          {/* {!mktPlaceShipment ? (
            <div className="col-md-12 mt-3 float-right">
              <div className={"accordionItem" + (accorddata[key] ? " open" : " closed")}>
                <h2 className="accordionItemHeading font-size-13 font-wt-500" onClick={() => handleAccordion(key)}> Set Document ACL for - {values[key]["docName"]}</h2>
                <div className="accordionItemContent">
                  {(values[key].docACL.length) ? values[key].docACL.map((acl, index) => {
                    return (
                      <div className="col-md-12 mt-1">
                        <ul className="other-documents">
                          <li>
                            <span className="file-icon"> <i className="fa fa-key" aria-hidden="true"></i></span>
                          </li>
                          <li>
                            <select className="form-control" name={"party:" + key + ":" + index} id={"party:" + key + ":" + index} value={acl.party} onChange={handleParty} required>
                              <option value="" selected>--Select Party--</option>
                              {getPartieslist()}
                            </select>
                          </li>
                          <li>
                            <input type="checkbox" name={"auth:" + key + ":" + index} id={"auth:" + key + ":" + index} onChange={handleParty} checked={acl.auth} />
                            <label className="checkbox" for={"auth:" + key + ":" + index}>Authentication</label>
                          </li>
                          <li>
                            <input type="checkbox" name={"upload:" + key + ":" + index} id={"upload:" + key + ":" + index} onChange={handleParty} checked={acl.upload} />
                            <label className="checkbox" for={"upload:" + key + ":" + index}>Upload</label>
                          </li>
                          <li>
                            <input type="checkbox" name={"remove:" + key + ":" + index} id={"remove:" + key + ":" + index} onChange={handleParty} checked={acl.remove} />
                            <label className="checkbox" for={"remove:" + key + ":" + index}>Delete</label>
                          </li>
                          <li>
                            <button type="button" class="delete-icon" onClick={() => removeParty(key, index)}><i class="fa fa-trash"></i></button>
                          </li>
                        </ul>
                      </div>
                    )
                  }) : ""}
                  <div className="d-flex mt-2 pr-0"><button className="btn bg-1ea3ae text-white btn-sm" onClick={() => addParty(key)}>Add Party +</button></div>
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
        <hr />
      </>
      )

    })
    return result
  }


  return (
    <>
      {showUploadModal.modal && <div className={"modal " + (showUploadModal.modal ? " show d-block " : "")} id="DocumentUpload">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header ">
              <h4 className="modal-title text-dark font-size-16 font-wt-600 text-center w-100">Document Upload | Category : {showUploadModal.categoryName}</h4>
              <button type="button" className="close" onClick={() => { setshowUploadModal(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className=" col-md-12 row" >
                <div className=" col-md-12">

                  {renderList()}

                  {docLength || mktPlaceShipment ?
                    <div className="d-flex mt-2 pr-0"><button className="btn bg-1ea3ae text-white btn-sm" onClick={() => addElement()}>Add Document +</button></div>
                    :
                    <h1 className="text-center font-size-16 font-wt-500"> {!showLoader ? "No Action Needed! , All Doc from this category are already uploaded" : ""}</h1>}

                </div>
              </div>
            </div>

            {docLength ? <div className="modal-footer primary">
              <button type="button" className="btn bg-1ea3ae text-white btn-sm float-right" onClick={() => handleSubmitDocUpload()} >Submit</button>
            </div> : ""}

            {mktPlaceShipment && Object.keys(files).length ? <div className="modal-footer primary">
              <button type="button" className="btn bg-1ea3ae text-white btn-sm float-right" onClick={() => handleSubmitDocUpload()} >Submit</button>
            </div> : ""}
          </div>
        </div>
      </div >}
    </>)
}


export default DocUploadModal
