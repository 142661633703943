import moment from 'moment';
import React, { useEffect, useState } from 'react';
import avatarUrl from '../../../utils/makeAvatarUrl';
import NewChat from './NewChat';
import NewGroupChat from './NewGroupChat';


const callsdata = [
  {
    calltype: "call",
    callstatus: "outgoingmissedcall",
  },
  {
    calltype: "video",
    callstatus: "incomingmissedcall",
  },
  {
    calltype: "video",
    callstatus: "outgoingcall",
    received: true
  },
  {
    calltype: "video",
    callstatus: "incomingcall",
    received: true
  },
];

const ChatList = ({ calendar, setCalender, chatList, selectedChat, setSelectedChat, getChatListData, setDetailsChat, setChatList, getChatDetails, userId, getchatList, tab, setTab, addNew, setAddNew, checked, handleChange, userName, userEmail, userTypeId, searchText, onSearchPress, setSearchText, chat_type }) => {

  useEffect(() => {
    if (tab === 'People') {
      getChatListData('Private')
    } else if (tab === 'Group') {
      getChatListData('Group')
    }
  }, [tab])
  console.log('ChatList', chatList)
  return (
    <>
      <div className='card p-3 borderRadius border-0 chatlist h-100 '>

        {addNew && tab === 'People'
          ? <NewChat chat_type={chat_type} goback={() => {
            setAddNew(false)
            getchatList(tab === 'People' ? 'Private' : 'Group')
          }} setSelectedChat={setSelectedChat} setDetailsChat={setDetailsChat} setChatList={setChatList} chatList={chatList} getChatDetails={getChatDetails} userId={userId} userEmail={userEmail} userName={userName} userTypeId={userTypeId} />
          : addNew && tab === 'Group'
            ? <NewGroupChat chat_type={chat_type} goback={() => {
              setAddNew(false)
              getchatList(tab === 'People' ? 'Private' : 'Group')
            }} setSelectedChat={setSelectedChat} setDetailsChat={setDetailsChat} setChatList={setChatList} chatList={chatList} getChatDetails={getChatDetails} userId={userId} checked={checked} handleChange={handleChange} userEmail={userEmail} userName={userName} userTypeId={userTypeId} />
            : <div>

              <div className='d-flex align-items-center'>
                <div className="input-group rounded-pill col-10 bg-F8F8F8 chatsearch">
                  <span className="input-group-text border-0 bg-transparent px-0" id="basic-addon1"><img src={'/assets/images/fe_search.png'} alt='' /></span>
                  <input type="text" className="form-control border-0 bg-transparent" placeholder="Search" onChange={(e) => setSearchText(e.target.value)} onKeyDown={onSearchPress} value={searchText} />
                </div>
                <div className='col-2'>
                  <img className="cursor" src={'/assets/images/calender.png'} alt='' onClick={() => setCalender(!calendar)} />
                </div>
              </div>

              <nav>
                <div className="nav nav-tabs signdoctabs justify-content-around border-0" id="nav-tab" role="tablist">
                  <button className="nav-link active bg-transparent font-size-14" id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => {
                    setTab("People")

                  }}>People</button>
                  <button className="nav-link  bg-transparent font-size-14" id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => {
                    setTab("Group")
                  }}>Group</button>
                  {/* <button className="nav-link  bg-transparent font-size-14" id="nav-contact-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Calls")}>Calls</button> */}
                </div>
              </nav>
              <div className="tab-content d-block" id="nav-tabContent">
                {(tab === "People" || tab === 'Group') && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                  <div className="col-md-12 pt-2 chatContainer">
                    <ul className="chatcontacts">
                      {chatList.map((key, index) => {
                        let extraId = key.chat_room_name?.split('-')?.[1] || key.chat_room_name
                        return (
                          <li className={` ${index === selectedChat ? "active" : ""}`} onClick={() => setSelectedChat(index)} key={index}>
                            <div className="d-flex p-2 align-items-center w-100 mb-3">
                              <div className="img_cont w-17">
                                <img src={key.ReceiverAvatar ? typeof (key.ReceiverAvatar) == 'string' ? avatarUrl(key.ReceiverAvatar) : key.ReceiverAvatar.filebase64 : "/assets/images/user-profile.png"} alt="" className="rounded-circle user_img" />
                                {/* {key.activestate ? <span className="online_icon"></span> : <span className=""></span>} */}
                              </div>
                              <div className={`user_info ms-2 w-83 ${key.activestate ? "" : "border-bottom pb-2"}`}>
                                <p className={`mb-1 d-flex justify-content-between w-100 ${key.unreadstate ? "font-wt-600" : ""}`}>  <span>{`${key.ReceiverName} ${extraId ? ' - ' + extraId : ''}`}</span> <span className={`font-size-12 ${key.seen ? "text-dark" : "text-secondary"}`}>{moment(key.updatedAt).format("HH:mm")}</span></p>

                                {/* {key.msgstatus === "read" && */}
                                <p className='font-size-13 mb-0 chatListParagraph' dangerouslySetInnerHTML={{ __html: key.last_message }}></p>
                                {/* } */}
                                {/* {key.msgstatus === "sent" &&
                            <p className='font-size-13 mb-0'><img src={"/assets/images/charm_tick.png"} alt="" height={15} /> Nunc convallis quam lobortis orc...</p>
                          }
                          {key.msgstatus === "unread" &&
                            <p className='font-size-13 mb-0'><img src={"/assets/images/charm_tick-double-read.png"} alt="" height={15} /> Nunc convallis quam lobortis orc...</p>
                          }
                          {key.msgstatus === "Typing" &&
                            <p className='font-size-13 mb-0 text-color1'> Typing...</p>
                          }
                          {key.unreadstate === "unreadstate" &&
                            <p className='mb-0 d-flex justify-content-between w-100 text-dark font-wt-600 align-items-center'>  <span className='font-size-12 text-dark'>Nunc convallis quam lobortis orc...</span> <span className='font-size-13 unreadcount p-1 text-white text-center'>1</span></p>
                          } */}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>

                  </div>
                </div>}
                {/* {tab === "Group" && <div className="tab-pane fade show active bg-transparent" id="nav-profile" role="tabpanel">
            <div className="col-md-12 pt-2">
              <ul className="chatcontacts">
                {chatgroupdata.map((key, index) => {
                  return (
                    <li className={` ${key.activestate ? "active" : ""}`}>
                      <div className="d-flex p-2 align-items-center w-100 mb-3">
                        <div className="img_cont w-17">
                          <img src={"/assets/images/chat-dp.png"} className="rounded-circle user_img" />
                          {key.activestate ? <span className="online_icon"></span> : <span className=""></span>}
                        </div>
                        <div className={`user_info ms-2 w-83 ${key.activestate ? "" : "border-bottom pb-2"}`}>
                          <p className={`mb-1 d-flex justify-content-between w-100 ${key.unreadstate ? "font-wt-600" : ""}`}>  <span>158965235874598</span> <span className={`font-size-12 ${key.unreadstate ? "text-dark" : "text-secondary"}`}>09:00 PM</span></p>

                          {key.msgstatus === "read" &&
                            <p className='font-size-13 mb-0'><img src={"/assets/images/charm_tick-double.png"} alt="" height={15} /> Nunc convallis quam lobortis orc...</p>
                          }
                          {key.msgstatus === "sent" &&
                            <p className='font-size-13 mb-0'><img src={"/assets/images/charm_tick.png"} alt="" height={15} /> Nunc convallis quam lobortis orc...</p>
                          }
                          {key.msgstatus === "unread" &&
                            <p className='font-size-13 mb-0'><img src={"/assets/images/charm_tick-double-read.png"} alt="" height={15} /> Nunc convallis quam lobortis orc...</p>
                          }
                          {key.msgstatus === "Typing" &&
                            <p className='font-size-13 mb-0 text-color1'> Typing...</p>
                          }
                          {key.unreadstate === "unreadstate" &&
                            <p className='mb-0 d-flex justify-content-between w-100 text-dark font-wt-600 align-items-center'>  <span className='font-size-12 text-dark'>Nunc convallis quam lobortis orc...</span> <span className='font-size-13 unreadcount p-1 text-white text-center'>1</span></p>
                          }
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>} */}
                {tab === "Calls" && <div className="tab-pane fade show active bg-transparent" id="nav-contact" role="tabpanel">
                  <div className="col-md-12 pt-2">
                    <ul className="chatcontacts">
                      {callsdata.map((key, index) => {
                        return (
                          <li className="">
                            <div className="d-flex p-2 align-items-center w-100 mb-3">
                              <div className="img_cont w-17">
                                <img src={"/assets/images/chat-dp.png"} className="rounded-circle user_img" />
                              </div>
                              <div className={`user_info ms-2 w-83 border-bottom pb-2 d-flex align-items-center`}>
                                <div className='w-75'>
                                  <p className={`mb-1 d-flex justify-content-between w-100`}>  <span>158965235874598</span> </p>
                                  <p className='font-size-13 mb-0 font-wt-600 colorFE4141'>
                                    {key.callstatus === "outgoingmissedcall" && <img src={"/assets/images/missed-call.png"} alt="" className='me-1' />}
                                    {key.callstatus === "incomingmissedcall" && <img src={"/assets/images/incoming-missed.png"} alt="" className='me-1' />}
                                    {key.callstatus === "outgoingcall" && <img src={"/assets/images/outgoing-call.png"} alt="" className='me-1' />}
                                    {key.callstatus === "incomingcall" && <img src={"/assets/images/incoming-received.png"} alt="" className='me-1' />}
                                    <span className={`${key.received ? "color3DB16F" : "colorFE4141"}`}>Today at 09:00 PM</span> </p>
                                </div>
                                <div className='w-25 text-center'>
                                  {key.calltype === "call" && <img src={'/assets/images/chat-call.png'} alt='' />}
                                  {key.calltype === "video" && <img src={'/assets/images/chat_video.png'} alt='' />}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>}
              </div>
              {
                <img alt='' src={tab === "People" ? '/assets/images/new-chat.svg' : '/assets/images/new-group.svg'} className='newChatIcon cursor' onClick={() => setAddNew(true)} />
              }
            </div>
        }
      </div>


    </>
  );
}
export default ChatList;