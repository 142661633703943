import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import call from "../../../service";
import { FileInput } from "../../../utils/FileInput";
import {
  convertImageToPdf,
  decryptData,
  encryptData,
  exportersNavConfig,
  isEmpty,
} from "../../../utils/myFunctions";
import { InputWithSelect, NewInput } from "../../../utils/newInput";
import toastDisplay from "../../../utils/toastNotification";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { useHistory } from "react-router";
import { NewTable } from "../../../utils/newTable";
import MultipleSelect from "../../../utils/MultipleSelect";
import Switch from "react-switch";
import SideBarV2 from "../../partial/sideBarV2";
import HeaderV2 from "../../partial/headerV2";


const customizeUserAcess = {
  "Buyer Management": "buyerManagement",
  "LC Apply For Limit": "applyForLimit,LcDiscounting",
  "LC Quotes": "LcQuote,LcSeequotes",
  "LC Contract": "LcContract,LcSignContract",
  "LC Finance": "LcApplyforFinance,LcFinancebtn,LcApprovedFinance",
  "LC Ammendment": "LcAmendment,amendmentDetails",
  "Invoice Apply For Limit": "applyLimit",
  "Invoice Quotes": "Quotes,seequotes,DocsRequested",
  "Invoice Contract": "invoicecontract,signContract",
  "Invoice Finance": "apply-finance,apply-invoice",
  "Invoice Approved Finance": "approvedFinance,viewDetails",
};



const UserAcessFinancer = {
  "Supplier List": "supplierList,supplierDetails",
  "LC Quotes": "finLCQuotes,finLCQuotesDetails",
  "LC Request Letter": "lcRequestLetter,viewRequestLetter",
  "LC Finance": "lcFinApplication,viewLCFinApplication",
  "Invoice Quotes": "finInvoiceQuotes,finQuotesDetails,docsRequested",
  "Invoice Send Termsheet": "termSheet,sendTermSheet",
  "Invoice Sign Termsheet": "signTermSheet,viewSignTermSheet",
  "Invoice Finance": "financeApplication,viewFinanceApplication",
  "Invoice Disbursement": "disbursementV2",
  "Chat Room": "ChatRoomV2",
  Reports: "Reportv2",
  Notifications: "AllNotifications",
  Profile: "viewprofile",
};

const CreateNewSubAdmin = ({

  userTokenDetails,
  isEditable,
  userId,
  isAdmin
}) => {
  const [data, setData] = useState({
    contact_phonecode: 91,
    contact_personTitle: "Mr",
  });

  let history = useHistory();
  const formdata = new FormData();
  const [errors, setErrors] = useState({});
  const [accessObj, setAccessObj] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [securePw, toggleSecurePw] = useState(true);
  const [selectedCards, setSelectedCards] = useState({});
  const [addNew, setAddNew] = useState(false);
  const [countrys, setCountrys] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);


  const [UserAcessAdmin, setUserAccessAdmin] = useState({

    "Task Manager":
      "taskManager,enquiry,callList,corporate,financier",
    "International Financial Services Manager":
      "lcLimit,invoiceLimit,invoiceFinance,invoiceApprovedFinance",
    "Domestic Financial Services Manager":
      " otherFinAdminwcQuote,otherFinAdmincgtmseQuote,otherFinAdminbgQuote,otherFinAdminscfQuote",

    "User Management": "usersonboard",
    "CRM Manager": "leads,crmdata",
    "Finance Specialist": "generateInvoiceFinancier,generateInvoiceCP",
    "Admin Payments": "adminPayments",
    "Accounting Analyst": "AdminReports,tradeDiscovery",
    "MasterData Analyst": "masterdata",
    "User credit Administrator": "buyercreditcheck,suppliercreditcheck",

    "Contract Administrator": "contractManagementList,contractManagementAdd",
    "Operations Manager":
      "ShipmentBookingQuotes",

    Support: "ChatRoomV2,tradeGpt",

    Profile: "viewprofile",


  }
  )




  const [routeObjAdmin, setrouteObjAdmin] = useState([
    {
      id: "1",
      name: "User Management",
    },
    {
      id: "2",
      name: "International Financial Services Manager",
    },
    {
      id: "3",
      name: "Domestic Financial Services Manager",
    },
    {
      id: "4",
      name: "Accounting Analyst",
    },
    {
      id: "5",
      name: "Contract Administrator",
    },
    {
      id: "6",
      name: "Operations Manager",
    },
    {
      id: "7",
      name: "Admin Payments",
    },

    {
      id: "8",
      name: "Finance Specialist",
    },

    {
      id: "9",
      name: "CRM Manager",
    },
    {
      id: "10",
      name: "Support",
    },
    {
      id: "11",
      name: "Profile",
    },
    {
      id: "12",
      name: "MasterData Analyst",
    },
    {
      id: "13",
      name: "User credit Administrator",
    },
    {
      id: "14",
      name: "Task Manager",
    },

  ]);

  const [viewAccessAdmin, setviewaccessAdmin] = useState([
    {
      item: "usersonboard",
      name: "User Management",
      action: ["create", "view"],
      intro: "Create and view buyers",
      storeid: "6",
      selected: []
    },



    {
      item: "taskManager",
      name: "Task Manager",
      action: ["view"],
      intro: "Manages users onboard",
      storeid: "2",
      selected: []
    },

    {
      item: "enquiry",
      name: "Task Manager Enquiry",
      action: ["view"],
      intro: "Keeps Track of user enquiries",
      storeid: "2",
      selected: []
    },
    {
      item: "callList",
      name: "Task Manager Call List",
      action: ["view"],
      intro: "Keeps track of call history of users onboard",
      storeid: "2",
      selected: []
    },

    {
      item: "corporate",
      name: "Task Manager Corporate",
      action: ["view"],
      intro: "Lists out the details of directors along with their company information",
      storeid: "2",
      selected: []
    },


    {
      item: "financier",
      name: "Financiers",
      action: ["view"],
      intro: "Lists of Banks providing financier services",
      storeid: "2",
      selected: []
    },




    {
      item: "tradeDiscovery",
      name: "Trade Discovery",
      action: ["view"],
      intro: "Allows to search and view trader information ",
      storeid: "3", selected: []

    },
    {
      item: "lcLimit",
      name: "Letter of credit ",
      action: ["create", "view"],
      intro: "View and Create limit application",
      storeid: "4",
      selected: []
    },
    {
      item: "invoiceLimit",
      name: "Apply Limit for Export Factoring",
      action: ["view", "create"],
      tro: "Apply Limit for Credit Guarantee Fund Trust for Micro and Small Enterprises",
      storeid: "5", selected: []
    },
    {
      item: "invoiceFinance",
      name: "Apply limit Finance ",
      action: ["view"],
      intro: "Apply Limit Finance for Export Factoring",
      storeid: "5",
      selected: []
    },
    {
      item: "invoiceApprovedFinance",
      name: "Finance Invoice Approved ",
      action: ["view"],
      intro: "Approved Finance Invoice ",
      storeid: "5", selected: []
    },
    {
      item: "leads",
      name: "Leads Assigned to traders",
      action: ["view"],
      intro: "View traders with their task and leads assigned to them",
      storeid: "7", selected: []
    },
    {
      item: "crmdata",
      name: "CRM Traders Data",
      action: ["view"],
      intro: "View the CRM data ",
      storeid: "7", selected: []
    },
    {
      item: "adminPayments",
      name: "Admin Payments",
      action: ["view"],
      intro: "Monitors the payments across the platform",
      storeid: "8", selected: []
    },
    {
      item: "generateInvoiceFinancier",
      name: "Generate Invoice",
      action: ["view", "create"],
      intro: "Generate Invoice for Financier",
      storeid: "9", selected: []
    },
    {
      item: "generateInvoiceCP",
      name: "Generate Invoice for Channel Partner",
      action: ["view", "create"],
      intro: "Generate Invoice for Channel Partner",
      storeid: "9", selected: []
    },

    {
      item: "AdminReports",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "11",
      selected: []
    },
    {
      item: "masterdata",
      name: "Master Data Analysis",
      action: ["view"],
      intro: "View Master Data analysis",
      storeid: "12",
      selected: []
    },
    {
      item: "buyercreditcheck",
      name: "Check Buyer Credit",
      action: ["create"],
      intro: "View Buyers Credit",
      storeid: "13",
      selected: []
    },
    {
      item: "suppliercreditcheck",
      name: "Check Supplier Credit",
      action: ["create"],
      intro: "View Suppliers Credit",
      storeid: "13",
      selected: []
    },
    {
      item: "otherFinAdminwcQuote",
      name: "Working Capital Quote",
      action: ["view", "create"],
      intro: "Apply for Working Capital Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "otherFinAdmincgtmseQuote",
      name: "CGTMSE Finance Quote",
      action: ["view", "create"],
      intro: "Apply for CGTMSE Quote",
      storeid: "15",
      selected: []
    }, {
      item: "otherFinAdminbgQuote",
      name: "Bank Gurantee  Quote",
      action: ["view", "create"],
      intro: "Apply for Bank Gurantee Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "otherFinAdminscfQuote",
      name: "Supply Chain Finance  Quote",
      action: ["view", "create"],
      intro: "Apply for Supply Chain Finance Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "contractManagementList",
      name: "Contract Management",
      action: ["view"],
      intro: "View contract list",
      storeid: "16", selected: []
    },




    {
      item: "ShipmentBookingQuotes",
      name: "Booking Quotes",
      action: ["view"],
      intro: "View shipment booking quotes",
      storeid: "17", selected: []
    },



    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "10",
      selected: []
    },


  ]);





  const [rules, setRules] = useState([
    { id: 1, name: 'Limit Order Creation', emailEnabled: false },
    { id: 2, name: 'Purchase Order Cancellation', emailEnabled: false },
    { id: 3, name: 'Order Confirmation Creation', emailEnabled: false },
    { id: 4, name: 'Order Confirmation Cancellation', emailEnabled: false },
    { id: 5, name: 'Sales Quotation Creation', emailEnabled: false },
    { id: 6, name: 'Sales Quotation Cancellation', emailEnabled: false },
  ]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [salesPersonOverall, setsalesPersonOverall] = useState([]);
  useEffect(() => {
    call("POST", "getSubAdminUser", { userId })//------------------------------------------------------------------------------
      .then((res) => {

        setSalesPerson(res.data);
        setsalesPersonOverall(res.data);
      })
      .catch((err) => { });
  }, [])

  // const handleToggle = (index) => {
  //   setRules(prevRules => {
  //     console.log('Before:', prevRules);
  //     const updatedRules = prevRules.map((rule, i) =>
  //       i === index ? { ...rule, emailEnabled: !rule.emailEnabled } : rule
  //     );
  //     console.log('After:', updatedRules);
  //     return updatedRules;
  //   });
  // };

  // const handleToggle = (index) => {
  //   setRules(prevRules => {
  //     // Toggle the emailEnabled property for the selected rule
  //     const updatedRules = prevRules.map((rule, i) =>
  //       i === index ? { ...rule, emailEnabled: !rule.emailEnabled } : rule
  //     );

  //     // Update the accessObj state to include the updated rules
  //     setAccessObj(prevAccessObj => {
  //       // Create a copy of the accessObj array
  //       const updatedAccessObj = [...prevAccessObj];

  //       // Check if there is at least one element in the accessObj array
  //       if (updatedAccessObj.length > 0) {
  //         // Update the first element with the new rules
  //         updatedAccessObj[0] = {
  //           ...updatedAccessObj[0],
  //           rules: updatedRules // Add the updated rules here
  //         };
  //       }

  //       return updatedAccessObj;
  //     });

  //     return updatedRules;
  //   });
  // };

  const handleToggle = (index) => {
    setRules(prevRules => {
      // Toggle the emailEnabled property for the selected rule
      const updatedRules = prevRules.map((rule, i) =>
        i === index ? { ...rule, emailEnabled: !rule.emailEnabled } : rule
      );

      // Update the accessObj state to include the updated rules
      setAccessObj(prevAccessObj => {
        // Create a copy of the accessObj array
        const updatedAccessObj = [...prevAccessObj];

        // Check if there is at least one element in the accessObj array
        if (updatedAccessObj.length > 0) {
          // Extract the names of rules where emailEnabled is true
          const enabledNames = updatedRules
            .filter(rule => rule.emailEnabled)
            .map(rule => rule.name); // Assuming each rule has a 'name' property

          // Update the first element with the new rules and communicationSettings
          updatedAccessObj[0] = {
            ...updatedAccessObj[0],

            communicationSettings: enabledNames // Add or update communicationSettings
          };
        }

        return updatedAccessObj;
      });

      return updatedRules;
    });
  };



  console.log(data, "this is data")
  useEffect(() => {
    console.log("formdataaaaaaaa", formdata);
    if (isEditable) {
      setData({
        contact_name: formdata.contact_person,
        contact_personTitle: formdata.name_title,
        contact_number: formdata.contact_number,
        contact_phonecode: formdata.phone_code,
        email_id: formdata.email_id,
        addresss: formdata.user_address,
        panno: formdata.aadhar_no,
        aadhaarno: formdata.aadhar_no,
        password: decryptData(formdata.password),
      });
      setAccessObj(JSON.parse(formdata.UserPermissions));
    }
  }, []);

  console.log(accessObj, "accessobj--->>>>");
  const handleChangeUser = async (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" });
  };
  const [selectedModes, setSelectedModes] = useState({});

  useEffect(() => {
    call("GET", "getallCountry")
      .then((result) => {
        console.log("running getallCountry api-->", result);
        setCountrys(result);
      })
      .catch((e) => {
        // console.log('error in getBuyersDetail', e);
      });
  }, []);

  const handleFileUser = (event, isImage) => {
    event.persist();
    if (!event.target.files.length) {
      return null;
    } else {
      let file_type = event.target.files[0]["type"].toLowerCase();
      if (
        !(
          file_type.includes("pdf") ||
          file_type.includes("png") ||
          file_type.includes("jpeg")
        )
      ) {
        setErrors({
          ...errors,
          [event.target.name]:
            "Files with pdf, png & jpeg extension are allowed",
        });
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0];
        let fileDataUrl = e.target.result;

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(
            fileDataUrl,
            event.target.files[0]["name"]
          );
          console.log("pdfconversionresp", response);
          fileObj = response["file"];
          fileDataUrl = response["dataUrl"];
          toastDisplay("File converted into pdf format", "success");
        }
        fileObj["filebase64"] = fileDataUrl;
        setData({ ...data, [event.target.name]: fileObj });
        setErrors({ ...errors, [event.target.name]: "" });
      };
    }
  };
  const createSubUser = async () => {
    setShowLoader(true);
    try {
      let reqObj = {
        email: data.email_id,
        encryptedPassword: encryptData(data.password),
        type_id:
          userTokenDetails?.type_id === 19
            ? 21
            : userTokenDetails?.type_id === 8
              ? 22
              : 0,
        parent_id: userTokenDetails?.user_id,
        techType: "",
        user_address: data.addresss,
        nameTitle: data.contact_personTitle,
        contactName: data.contact_name,
        contactNumber: data.contact_number,
        PhoneCode: data.contact_phonecode,
        pan_no: data.panno,
        aadhar_no: data.aadhaarno,
        UserPermissions: JSON.stringify(accessObj),
      };
      console.log("reqobj", reqObj);
      const result = await call("POST", "createSubUser", reqObj);
      setShowLoader(false);
      // setSubUserModal(false);
      toastDisplay("User Added Succesfully", "success");
    } catch (e) {
      setShowLoader(false);
      toastDisplay("Failed to add the User", "error");
    }
  };
  const handleValidations = () => {
    let errors = {};
    if (!data.contact_name) {
      errors.contact_name = "Contact Name Cannot be empty";
    }
    if (!data.contact_personTitle) {
      errors.contact_personTitle = "Contact Name Title Cannot be empty";
    }
    if (!data.contact_number) {
      errors.contact_number = "Contact Number Cannot be empty";
    }
    if (!data.contact_phonecode) {
      errors.contact_phonecode = "Contact Number Code cannot be empty";
    }

    if (!data.password) {
      errors.password = "Password cannot be empty";
    }

    if (isEmpty(accessObj)) {
      errors.accesspermission = "You need to select at least one module";
    }
    if (isEmpty(errors)) {
      createSubUser();
    } else {
      setErrors(errors);
    }
  };


  console.log(accessObj, "accessobj--->>>>");


  useEffect(() => {
    call("GET", "getallCountry")
      .then((result) => {
        console.log("running getallCountry api-->", result);
        setCountrys(result);
      })
      .catch((e) => {
        // console.log('error in getBuyersDetail', e);
      });
  }, []);


  const selectedModesRef = useRef({});
  const [arr, setarr] = useState([]);
  const [anyUnchecked, setAnyUnchecked] = useState(false);
  const [storedItems, setStoredItems] = useState({});
  const [latermodes, setlatermodes] = useState({})
  useEffect(() => {


    if (userTokenDetails?.type_id === 1) {
      const initialSelectedModes = arr.reduce((acc, item, index) => {
        const actions = item.action;
        console.log(item, "this is actions----->>>>>>>>.");


        const selectedActions = {};
        if (actions.includes('create')) {
          selectedActions.create = true;
          item.selected.push("create");
        }
        if (actions.includes('view')) {
          selectedActions.view = true;
        }


        const viewAccessItem = viewAccessAdmin.find(v => v.name === item.name);
        if (viewAccessItem) {
          if (actions.includes('create') && !viewAccessItem.selected.includes('create')) {
            viewAccessItem.selected.push('create');
          }
          if (actions.includes('view') && !viewAccessItem.selected.includes('view')) {
            viewAccessItem.selected.push('view');
          }
        }
        console.log(viewAccessItem, "this is viewccess item--///////")
        // Add the 'name' property
        acc[index] = {
          ...selectedActions,
          name: item.name,
        };

        return acc;
      }, {});

      setSelectedModes(initialSelectedModes);
      setlatermodes(initialSelectedModes);
    }
  }, [arr, viewAccessAdmin]);


  console.log(selectedModes, "this is the selected modes---->>>>>>>>>>>>>>>>>>.")




  useEffect(() => {
    selectedModesRef.current = selectedModes;
  }, [selectedModes]);

  console.log(selectedModes, "this is selectedmodes obj")


  const handleCheckboxClick = (index, type) => {
    if (userTokenDetails?.type_id === 1) {
      // Retrieve the current state directly
      const currentSelectedModes = selectedModes;
      console.log("this is current selecetd modes-->>>", currentSelectedModes)
      // Initialize the item if it doesn't exist
      const item = currentSelectedModes[index] || {};

      console.log("Previous item state:", item);

      // Toggle the checkbox status for the specific item
      const updatedItem = {
        ...item,
        [type]: !item[type],
      };

      const newState = {
        ...currentSelectedModes,
        [index]: updatedItem,
      };

      console.log("New item state:", newState[index]);
      const viewAccessItem = viewAccessAdmin.find(v => v.name === newState[index].name);
      if (viewAccessItem) {
        if (newState[index].create === false && viewAccessItem.selected.includes('create')) {
          const val = viewAccessItem.selected.filter(action => action !== 'create')
          console.log(val, " this is val ----->>>>>>")
          viewAccessItem.selected = val
        } else if (newState[index]['create'] === true && !viewAccessItem.selected.includes('create')) {
          viewAccessItem.selected.push('create');
        }

        // Handle removal for 'view' similarly
        if (newState[index].view === false && viewAccessItem.selected.includes('view')) {
          const val = viewAccessItem.selected.filter(action => action !== 'view')
          console.log(val, " this is val ----->>>>>>")
          viewAccessItem.selected = val
        } else if (newState[index]['view'] === true && !viewAccessItem.selected.includes('view')) {
          viewAccessItem.selected.push('view');
        }
      }
      console.log(viewAccessItem, "this is viewccess item--///////")

      // Log the entire updated state
      console.log("Updated state in checkbox click:", newState);

      // Determine if any checkboxes are unchecked
      const isAnyUnchecked = Object.values(newState).some(
        (modes) => !modes.create || !modes.view
      );

      console.log("Is any unchecked:", isAnyUnchecked);

      // Update the states
      setSelectedModes(newState);
      setAnyUnchecked(isAnyUnchecked);
    }

  };

  // Adding an effect to log state changes
  useEffect(() => {
    console.log("State changed:", selectedModes);
  }, [selectedModes]);





  console.log(storedItems, "this is stored items-->>>>>>");


  const [selectedvalues, setSelectedvalues] = useState([]);
  const [roleValues, setRoleValues] = useState([]);
  const [assign, boolassign] = useState(false)
  const [editedCard, setEditedCard] = useState("Custom Role")

  const handleEditChange = (e) => {
    setEditedCard(e.target.value);
  };
  // Function to find matched entry by name

  useEffect(() => {
    if (userTokenDetails?.type_id === 1) {
      if (selectedvalues.length > 0) {
        let newArr = [];
        selectedvalues.forEach((ele) => {
          if (UserAcessAdmin.hasOwnProperty(ele.name)) {
            newArr = [...newArr, ...UserAcessAdmin[ele.name].split(",")];
          }
        });
        const filteredViewAccess = viewAccessAdmin.filter((item) =>
          newArr.includes(item.item)
        );

        setarr(filteredViewAccess);
        console.log(selectedModes, 'this is in useffect-->>>>>')
      } else {
        setarr([]);
      }
    }
  }, [selectedvalues, UserAcessAdmin, viewAccessAdmin]);

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    // Update data state
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
      });
    } else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : [],
      });
    }

    if (userTokenDetails.type_id === 1) {
      const selectedRoles = e
        .map((item) => routeObjAdmin.find((ele) => ele.id === item[val]?.toString()))
        .filter(Boolean);


      // Log selected roles
      console.log(selectedRoles, "these are selected roles--.....");
      setSelectedvalues(selectedRoles);

      const updatedAccessObj = [...accessObj];


      const allSelectedPermissions = [];
      let finalobj = {};
      selectedRoles.forEach((selectedRole) => {
        console.log(selectedRole, "this is selected role in multiselect----?????????")

        if (selectedRole) {

          finalobj.roleName = selectedRole.name;

          if (UserAcessAdmin[selectedRole.name]) {

            const arrPermit = UserAcessAdmin[selectedRole.name].split(',')

            const permissions = [];
            const sideId = new Set();

            arrPermit.forEach((element) => {
              const matchedItem = viewAccessAdmin.find((accessItem) => accessItem.item === element);
              if (matchedItem) {
                console.log(matchedItem, "this is matched item--->>>>>")

                sideId.add(matchedItem.storeid);
                permissions.push(matchedItem);


              }
            });
            const uniquesideId = Array.from(sideId)
            finalobj.sideBarId = uniquesideId
            finalobj.permissions = permissions

            console.log(sideId, "this is unique side ids--->>>>", permissions, "hey this is permissions---////")
            console.log(finalobj, "this is payload--->>>>>")

          }
        }
        setAccessObj([...accessObj, finalobj])
      });
      console.log(updatedAccessObj, "updated accessobj---/////");
    }

  };

  console.log(data.role_name, "this data roleeee---->>>>>>");



  const handleassign = () => {
    boolassign(!assign)
  };
  console.log(assign, "this is assiggn---->>>>")
  const handleSkip = (
  ) => {
    toastDisplay("Click on Add New User", "success")
  };

  const handleCreateandAssign = () => {
    call('POST', 'rolesandorganizations', { role: editedCard, permissions: accessObj, userId }).then(() => toastDisplay("New Subuser created and Assigned", "success"))
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <div className="row">
        <SideBarV2 state="viewprofile" userTokenDetails={userTokenDetails} />

        {showLoader && (
          <div className="loading-overlay">
            <span>
              <img
                className=""
                src="assets/images/loader.gif"
                alt="description"
              />
            </span>
          </div>
        )}
        <main
          role="main"
          className={
            "ml-sm-auto col-lg-10 "

          }
          id="app-main-div"
        >
          <HeaderV2
            title={"User profile"}
            userTokenDetails={userTokenDetails}

          />
          <div>
            <div className=" mr-0 my-0">
              <div className=" pb-4">
                <div className="border-0" style={{ marginTop: "3rem" }} >
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <label className="font-size-16 font-wt-600 text-color-value mx-3">
                      Add New User
                    </label>
                  </div>
                </div>

                <div className="modal-body px-4">
                  <div className="row">
                    <div className="col-md-6 py-2">
                      <InputWithSelect
                        isAstrix={false}
                        type={"text"}
                        label={"Name"}
                        isDisabled={isEditable}
                        selectData={[{ name: "Mr" }, { name: "Miss" }]}
                        selectName={"contact_personTitle"}
                        selectValue={data.contact_personTitle}
                        optionLabel={"name"}
                        optionValue={"name"}
                        name={"contact_name"}
                        value={data.contact_name}
                        error={errors.contact_name}
                        onChange={handleChangeUser}
                      />
                    </div>
                    <div className="col-md-6 py-2">
                      <InputWithSelect
                        isAstrix={false}
                        type={"text"}
                        label={"Contact No."}
                        isDisabled={isEditable}
                        selectData={countrys}
                        selectName={"contact_phonecode"}
                        selectValue={data.contact_phonecode}
                        optionLabel={"phonecode"}
                        optionValue={"phonecode"}
                        name={"contact_number"}
                        value={data.contact_number}
                        error={errors.contact_number}
                        onChange={handleChangeUser}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 py-2">
                      <NewInput
                        isAstrix={true}
                        type={"text"}
                        label={"Email ID"}
                        isDisabled={isEditable}
                        name={"email_id"}
                        value={data.email_id}
                        error={errors.email_id}
                        onChange={handleChangeUser}
                      />
                    </div>
                    <div className="col-md-6 py-2">
                      <div className="form-group w-100 position-relative">
                        <i
                          onClick={() => toggleSecurePw(!securePw)}
                          className={`fas ${!securePw ? "fa-eye" : "fa-eye-slash"
                            } input-icon`}
                          id="togglePassword"
                        ></i>
                        <input
                          type={securePw ? "password" : "text"}
                          className={
                            "form-control" +
                            (!errors.password ? "" : " border-danger")
                          }
                          placeholder="Enter Password"
                          name="password"
                          value={data.password}
                          disabled={isEditable}
                          onChange={handleChangeUser}
                        />
                        {errors.password && (
                          <div className="text-danger mt-2 font-size-12">
                            <i
                              className="fa fas fa-exclamation-circle mr-1"
                              aria-hidden="true"
                            ></i>
                            <b>{errors.password}</b>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 py-2">
                      <NewInput
                        type={"text"}
                        label={"Address"}
                        isDisabled={isEditable}
                        name={"address"}
                        value={data.address}
                        error={errors.address}
                        onChange={handleChangeUser}
                      />
                    </div>
                    <div className="col-md-6 py-2">
                      <NewInput
                        type={"text"}
                        label={"PAN No."}
                        isDisabled={isEditable}
                        name={"panno"}
                        value={data.panno}
                        error={errors.panno}
                        onChange={handleChangeUser}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 py-2">
                      <NewInput
                        type={"text"}
                        label={"Aadhaar No."}
                        isDisabled={isEditable}
                        name={"aadhaarno"}
                        value={data.aadhaarno}
                        error={errors.aadhaarno}
                        onChange={handleChangeUser}
                      />
                    </div>
                  </div>

                  <label className="font-size-15 font-wt-500 my-4">
                    <u>Attach Document</u>
                  </label>
                  <div>
                    <label className="pl-2 font-size-14 font-wt-300">
                      Aadhar card
                    </label>
                    <div className="col">
                      <FileInput
                        name={"User_Aadhaar_Document"}
                        value={data?.User_Aadhaar_Document}
                        error={errors.User_Aadhaar_Document}
                        onChange={handleFileUser}
                        isEditable={!isEditable}
                        userTokenDetails={userTokenDetails}
                        onUploadCancel={() =>
                          setData({ ...data, User_Aadhaar_Document: null })
                        }
                      />
                    </div>
                    <label className="pl-2 font-size-14 font-wt-300">
                      PAN card
                    </label>
                    <div className="col">
                      <FileInput
                        name={"User_PAN_Document"}
                        value={data?.User_PAN_Document}
                        error={errors.User_PAN_Document}
                        onChange={handleFileUser}
                        isEditable={!isEditable}
                        userTokenDetails={userTokenDetails}
                        onUploadCancel={() =>
                          setData({ ...data, User_PAN_Document: null })
                        }
                      />
                    </div>
                  </div>
                  <label className="font-size-15 font-wt-500 my-4">
                    <u>Assign Role</u>
                  </label>

                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <div
                      style={{
                        width: "50%",
                        maxWidth: "400px",
                        marginBottom: "15px",
                      }}
                    >
                      <MultipleSelect
                        Label={"Select Role"}
                        optiondata={userTokenDetails.type_id === 1 ? routeObjAdmin : []}
                        Id={`role_name`}
                        name={"role_name"}
                        labelKey={"name"}
                        valKey={"id"}
                        value={data["role_name"] ? data["role_name"] : []}
                        onChange={async (e) => {
                          handleMultiSelectchange(e, "role_name", "id");
                        }}
                      />

                    </div>
                  </div>

                  {(anyUnchecked && userTokenDetails.type_id !== 1) && (
                    <div style={{ marginTop: "1rem" }}>
                      {assign && (
                        <>
                          <div className="d-flex flex-column card-body cursor-pointer">
                            <label style={{ marginBottom: '1rem' }}>Create and Assign New Role</label>
                            <div className="d-flex align-items-center" style={{ width: "50%" }}>
                              <div style={{ position: "relative", width: "60%" }}>
                                <input
                                  type="text"
                                  value={editedCard}
                                  onChange={handleEditChange}
                                  style={{
                                    width: "100%",
                                    fontSize: "1rem",
                                    border: "1px solid lightgray",
                                    textAlign: "center",
                                    outline: "none",
                                    paddingRight: "25px", // Add padding to make space for the image
                                    height: "2rem",
                                    borderRadius: "0.5rem"
                                  }}
                                  placeholder="Custom Role"
                                />
                                <img
                                  src={"/assets/images/edit.png"}
                                  height={20}
                                  width={30}
                                  className="cursor"
                                  alt="cb"
                                  style={{
                                    position: "absolute",
                                    right: "10px", // Adjust the position of the image inside the input box
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                />
                              </div>
                              <button
                                style={{
                                  marginLeft: '1rem',
                                  height: "2rem",
                                  fontSize: "1rem",
                                  border: "none",
                                  borderRadius: "0.5rem",
                                  backgroundColor: "#5CB8D3",
                                  color: "white",
                                  cursor: "pointer",
                                  paddingInline: "1rem"
                                }}
                                onClick={handleCreateandAssign}
                              >
                                Create and Assign
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}



                  {arr.length > 0 && (
                    <div style={{ border: "1px solid lightgrey", width: "60rem" }}>
                      <label
                        className="mt-2 mb-4 d-flex justify-content-center  cursor-pointer"
                        style={{
                          color: "#5CB8D3",
                          fontSize: "1rem",
                        }}
                      >
                        Permissions included in the selected Roles are listed below
                      </label>
                      {arr.map((ele, index) => (
                        <div
                          key={index}
                          className="d-flex "
                          style={{ padding: "1rem" }}
                        >
                          <div>
                            <div style={{ width: "20rem" }} >{ele.name}</div>
                            {/* <div>{ele.intro}</div> */}
                          </div>
                          <div className="col-8 text-center">
                            <div className="d-flex justify-content-around align-items-center">
                              <div
                                className="checkbox-container"
                                onClick={() => handleCheckboxClick(index, "create")}
                              >
                                {ele.action.includes("create") && (
                                  <img
                                    src={
                                      selectedModes[index]?.create
                                        ? "/assets/images/checked-green.png"
                                        : "/assets/images/unchecked-box.png"
                                    }
                                    height={20}
                                    width={20}
                                    className="checkbox-image cursor"
                                    alt="checkbox"
                                  />
                                )}
                                {ele.action.includes("create") ? "Create" : ""}
                              </div>
                              <div
                                className="checkbox-container"
                                onClick={() => handleCheckboxClick(index, "view")}
                              >
                                {ele.action.includes("view") && (
                                  <img
                                    src={
                                      selectedModes[index]?.view
                                        ? "/assets/images/checked-green.png"
                                        : "/assets/images/unchecked-box.png"
                                    }
                                    height={20}
                                    width={20}
                                    className="checkbox-image cursor"
                                    alt="checkbox"
                                  />
                                )}
                                {ele.action.includes("view") ? "View" : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {(anyUnchecked && userTokenDetails.type_id !== 1) && (
                        <div style={{ marginTop: "1rem" }}>

                          <div className="d-flex justify-content-center" style={{ gap: "1rem", marginBottom: "2rem" }}>
                            <button
                              className={`new-btn py-2 px-2 text-white cursor`}
                              style={{ width: "150px" }}
                              onClick={handleassign}
                            >
                              Assign New Role
                            </button>
                            <button
                              className={`new-btn py-2 px-2 text-white cursor`}
                              style={{ width: "150px" }}
                              onClick={handleSkip}
                            >
                              Skip
                            </button>
                          </div>
                        </div>

                      )}
                    </div>
                  )}
                  {errors.accesspermission && (
                    <div class="text-danger mt-2 font-size-12">
                      <i
                        class="fa fas fa-exclamation-circle mr-1"
                        aria-hidden="true"
                      ></i>
                      <b>{errors.accesspermission}</b>
                    </div>
                  )}

                  <div>
                    <p className="text-decoration-underline font-size-14 mt-4">
                      Reporting Manager
                    </p>
                    <div className="col-md-12 p-0"
                      style={{
                        width: "50%",
                        maxWidth: "400px",
                        marginBottom: "15px",
                      }}>
                      <MultipleSelect
                        isMulti
                        Id="Select Reporting Manager"
                        Label="Select Reporting Manager"
                        selectedvalue="Select Reporting Manager"
                        filterOption={() => true}
                        optiondata={
                          data.userIdToUpdate ? salesPerson : salesPersonOverall
                        }
                        onChange={(e) =>
                          handleMultiSelectchange(
                            e,
                            "reportingManager",
                            "id",
                            true
                          )
                        }
                        value={
                          data.reportingManager ? [data.reportingManager] : []
                        }
                        name="reportingManager"
                        labelKey={"contact_person"}
                        valKey={"id"}
                        customStyles={{
                          backgroundColor: "#DEF7FF",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    <p className="text-decoration-underline font-size-14 mt-4">
                      Functional Manager
                    </p>
                    <div className="col-md-12 p-0" style={{
                      width: "50%",
                      maxWidth: "400px",
                      marginBottom: "15px",
                    }}>
                      <MultipleSelect
                        isMulti
                        Id="Select Functional Manager"
                        Label="Select Functional Manager"
                        selectedvalue="Select Functional Manager"
                        optiondata={
                          data.userIdToUpdate ? salesPerson : salesPersonOverall
                        }
                        onChange={(e) =>
                          handleMultiSelectchange(
                            e,
                            "functionalManager",
                            "id",
                            true
                          )
                        }
                        value={
                          data.functionalManager ? [data.functionalManager] : []
                        }
                        name="functionalManager"
                        labelKey={"contact_person"}
                        valKey={"id"}
                        customStyles={{
                          backgroundColor: "#DEF7FF",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>


                  <div style={{ padding: '1rem', textDecoration: "underline", fontWeight: "500" }}>Communication Settings</div>
                  <div>
                    <div className="row">
                      <div style={{ border: "1px solid lightgrey", borderRadius: "1rem" }}>
                        <table className="styled-table">
                          <thead>
                            <tr
                              style={{
                                height: "3.2rem",
                                alignItems: "baseline",
                                borderBottom: "1px solid lightgrey"
                              }}
                              className="position-relative font-wt-600 text-color-value font-size-13 pl-2"
                            >
                              <th style={{ width: "100%" }}></th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rules.map((entry, index) => (
                              <tr key={index} style={{ borderBottom: "1px solid lightgrey" }}>
                                <td >{entry.name}</td>
                                <td>
                                  <Switch
                                    onChange={() => handleToggle(index)}
                                    checked={entry.emailEnabled}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor="#4caf50"
                                    offColor="#ccc"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>



                        </table>
                      </div>
                    </div>
                  </div>

                  {!isEditable && (
                    <div className="d-flex gap-4">
                      <button
                        className={`mt-4 new-btn w-20 py-2 px-2 text-white cursor`}
                        onClick={handleValidations}
                      >
                        Add New User
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default CreateNewSubAdmin;
