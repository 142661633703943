import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { platformBackendUrl } from '../../urlConstants';
import { NewInput } from '../../utils/newInput';
import { PopupMessage } from '../popupMessage';

let searchBy = [
  { name: "HSN" },
  { name: "Description" },
  { name: "Category" }
]

let descSearchBy = [
  { name: "Goods" },
  { name: "Services" }]

const HSNCodeFinder = ({ userTokenDetails, navToggleState }) => {

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;


  const [showLoader, setShowLoader] = useState(false);
  const [data, setData] = useState({ searchBy: 0, searchDescription: 0 })
  const [error, setError] = useState({})
  const [hsnCategoryData, setHSNCategoryData] = useState([])
  const [hsnCatOpen, setHSNCatOpen] = useState({})
  const [hsnOpen, setHSNOpen] = useState({})

  const [chapData, setChapData] = useState({})
  const [catPopup, toggleCatPopup] = useState(false)
  const [faqQsts, setFaqQsts] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [faqOpened, toggleFaqOpened] = useState({})

  async function handleFaqOpen(index) {
    toggleFaqOpened({ ...faqOpened, [index]: !faqOpened[index] })
  }

  useEffect(() => {
    setShowLoader(true)
    axios.post(platformBackendUrl + "/getFaqQsts", {
      category: 'HSN', subCategory: 'HSN', search: data.searchInput2,
      resultPerPage: 3, currentPage: page, langCode: "en"
    }).then((result) => {
      // console.log("result==================>", result);
      setShowLoader(false)
      if (result?.data?.message?.data?.length) {
        if (data.searchInput2) {
          setFaqQsts(result.data.message.data);
        }
        else {
          setFaqQsts([...result.data.message.data]);
        }
        setCount(result.data.message.count)
      }
      else {
        setFaqQsts([])
        setCount(0)
      }
    }).catch(err => setShowLoader(false));
  }, [data.searchInput2, page]);


  useEffect(() => {
    setShowLoader(true)
    axios.post(platformBackendUrl + "/getHSNCategories", {}).then(res => {
      setShowLoader(false)
      console.log("getHSNCategories", res);
      setHSNCategoryData(res?.data?.message || [])
    }).catch(err => setShowLoader(false))
  }, [])

  async function fetchHSNData(chapterFor, subChapter, search) {
    setShowLoader(true)
    axios.post(platformBackendUrl + "/getHSNChapter", { chapterFor, subChapter, search }).then(res => {
      setShowLoader(false)
      // console.log("getHSNChapterrrrrrrrrrrrrrrrrrrrrrr", res);
      if (res.data?.message?.hsn?.length) {
        setChapData(res.data.message)
        toggleCatPopup(false)
      }
      else {
        setChapData({})
      }
    }).catch(err => setShowLoader(false))
  }

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  return (<>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
    <div className="container-fluid">
      <div className="row">
        <SideBarV2 state="hsnCodeFinder" userTokenDetails={userTokenDetails} />

        <div className={`modal fade ${catPopup && "show"}`} style={catPopup ? { display: "block", "zIndex": '100001' } : {}}>
          <div className="modal-dialog modal-md mr-0 my-0">
            <div className="modal-content submitmodal pb-4"
            >
              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >Category wise HSN Code</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close"
                      onClick={() => {
                        toggleCatPopup(false)
                        setData({ ...data, searchBy: 0 })
                      }}></button>
                  </div>
                </div>
              </div>
              <div className="modal-body px-4">
                <>
                  {hsnCategoryData.map((i, j) => {
                    return (
                      <div>
                        <div
                          onClick={() => setHSNCatOpen({ ...hsnCatOpen, [j]: !hsnCatOpen[j] })}
                          className="d-flex cursor mb-4">
                          <img
                            className="cursor"
                            style={hsnCatOpen[j] ? { transform: 'rotate(180deg)' } : {}}
                            src="assets/images/arrow_down.svg" />
                          <label style={{ marginLeft: '1.3rem' }} className="font-size-16 fc-0C0C0C font-wt-600 cursor">{i.title}</label>
                        </div>
                        {hsnCatOpen[j] ?
                          i.hsn.map((k, l) => {
                            return (
                              <div>
                                <div
                                  onClick={() => setHSNOpen({ ...hsnOpen, [k.HS_CODE]: !hsnOpen[k.HS_CODE] })}
                                  style={{ paddingLeft: '2rem' }}
                                  className="d-flex cursor mb-4" >
                                  <img
                                    className="cursor align-self-start"
                                    style={hsnOpen[k.HS_CODE] ? { transform: 'rotate(180deg)' } : {}}
                                    src="assets/images/arrow_down.svg" />
                                  <label
                                    onClick={() => {
                                      fetchHSNData(k.HS_CODE)
                                    }}
                                    className="mx-3 font-size-15 fc-0C0C0C font-wt-500 cursor fc-1B94B7"><u>{k.HS_CODE}</u></label>
                                  <label className=" font-size-15 fc-0C0C0C font-wt-500 cursor">{k.Description}</label>
                                </div>
                                {hsnOpen[k.HS_CODE] ?
                                  k.hsnUpto4Digit.map((m, n) => {
                                    return (
                                      <div
                                        style={{ paddingLeft: '3.3rem' }}
                                        className="d-flex cursor mb-4" >
                                        <label
                                          onClick={() => {
                                            fetchHSNData(k.HS_CODE, m.HS_CODE)
                                          }} className="mx-3 font-size-15 fc-0C0C0C font-wt-500 cursor fc-1B94B7"><u>{m.HS_CODE}</u></label>
                                        <label className="font-size-15 fc-0C0C0C font-wt-500 cursor">{m.Description}</label>
                                      </div>
                                    )
                                  })
                                  : null}
                              </div>)
                          })
                          : null}
                      </div>
                    )
                  })}
                </>
              </div>
            </div>
          </div>
        </div>

        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"HSN Code Finder"}
            userTokenDetails={userTokenDetails} />
          <div className='card d-flex'>
            <div className="pt-5 pb-lg-2 pb-0 px-lg-5 px-3 my-lg-5" >
              <p className="font-size-22 font-wt-700 fc-0C0C0C text-center">{'HSN Code Finder'}</p>
              <p className="font-size-16 font-wt-400 ff-roboto fc-0C0C0C text-center mx-5 px-5">{'Looking for the correct HSN Code or ITC HS Code for your product and its corresponding GST rate? Look no further than our HSN Code Search Tool!'}</p>
            </div>

            <div
              className="d-flex row justify-content-center">
              <div
                className="align-items-center col-auto"
              ><label className="font-size-15 font-wt-500 fc-0C0C0C">Search By</label>
              </div>
              <div className="d-flex col-auto align-items-center">
                {searchBy.map((i, j) => {
                  return (
                    <div
                      onClick={() => {
                        if (j === 2) {
                          toggleCatPopup(true)
                        }
                        setData({ ...data, searchBy: j })
                      }}
                      className="cursor d-flex align-items-center">
                      <img
                        className="mx-3 align-items-center cursor"
                        src={j != data.searchBy ? "assets/images/bullet_box_empty.svg" : "assets/images/bullet_box.svg"}
                      />
                      <label className="font-size-15 font-wt-500 fc-0C0C0C align-items-center cursor m-0" >{i.name}</label>
                    </div>
                  )
                })}
                {data.searchBy === 1 ? <><div
                  style={{ marginLeft: '4rem' }}
                  className="align-items-center col-auto"
                ><label className="font-size-15 font-wt-500 fc-0C0C0C">Search Under</label>
                </div>
                  {descSearchBy.map((i, j) => {
                    return (
                      <div
                        onClick={() => { setData({ ...data, searchDescription: j }) }}
                        className="cursor">
                        <img
                          className="mx-3 align-items-center cursor"
                          src={j != data.searchDescription ? "assets/images/bullet_box_empty.svg" : "assets/images/bullet_box.svg"}
                        />
                        <label className="font-size-15 font-wt-500 fc-0C0C0C align-items-center cursor" >{i.name}</label>
                      </div>
                    )
                  })}</> : null}
              </div>
            </div>

            <div className="d-flex justify-content-center my-5 py-3">
              <div className="w-60 w-mob-100 position-relative">
                <NewInput inputStyles={{ height: "4rem", paddingLeft: "3rem", fontWeight: 600 }} label={data.searchBy === 0 ? 'Type HSN & Press Enter' : 'Type Product Description & Press Enter'} name={"searchInput"} onChange={handleChange}
                  value={data.searchInput} onKeyDown={(event) => {
                    event.persist()
                    console.log("onKeyDownnnnnnnnnnnnnn", event.target.name, event.target.value);
                    if (event.target.value?.trim()) {
                      fetchHSNData(null, null, event.target.value.trim())
                    }
                    else {
                      setChapData({})
                    }
                  }} />
              </div>
            </div>

            {chapData.hsn?.length ? (<div className="my-5 px-3 py-3">
              <p className="font-size-18 font-wt-600 fc-0C0C0C mb-5 mx-5">{chapData.chapterFor ?
                `HSN Code For Chapter ${chapData.chapterFor} - ${chapData.title}` : `All HS Codes for "${data.searchInput}" With GST Value`}</p>
              {chapData.hsn.map((i, j) => {
                return (
                  <div>
                    <div
                      onClick={() => setHSNCatOpen({ ...hsnCatOpen, [i.HS_CODE]: !hsnCatOpen[i.HS_CODE] })}
                      className="d-flex mx-5 mb-5 cursor">
                      <label
                        className="font-size-16 font-wt-600 px-3 py-2 align-self-start cursor"
                        style={{
                          background: '#B9EFFF',
                          borderRadius: 10
                        }}>{i.HS_CODE}
                      </label>
                      <label className="font-size-16 font-wt-600 mx-4 cursor">{i.Description}</label>
                      <img
                        className="cursor"
                        style={hsnCatOpen[i.HS_CODE] ? { transform: 'rotate(180deg)', marginLeft: 'auto' } : { marginLeft: 'auto' }}
                        src="assets/images/arrow_down.svg" />
                    </div>
                    {hsnCatOpen[i.HS_CODE] ?
                      <div className="mx-5">
                        <table class="table mb-5">
                          <thead>
                            <tr className="tabletr">
                              <th scope="col"><label className="font-size-16 font-wt-600 fc-0C0C0C">{"Sub-heading/ tariff item"}</label>
                              </th>
                              <th scope="col"><label className="font-size-16 font-wt-600 fc-0C0C0C">{'Description'}</label></th>
                              <th scope="col"><label className="font-size-16 font-wt-600 fc-0C0C0C">{'GST (%)'}</label></th>
                            </tr>
                          </thead>
                          <tbody
                          // className="table-body-scroll"
                          >
                            {i.details.map((l, m) => {
                              return (
                                <tr style={{ marginTop: '0.8rem' }} className="tabletr" >
                                  <td><label className="font-size-15 font-wt-500 fc-0C0C0C" >{`${l.HS_CODE}`}</label></td>
                                  <td><label className="font-size-15 font-wt-500 fc-0C0C0C">{l.Description}</label></td>
                                  <td><label className="font-size-15 font-wt-500 fc-0C0C0C">{i.gst + " %"}</label></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div> : null}
                  </div>)
              })}
            </div>) : null}
          </div>
        </main>
      </div>
    </div>

  </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    refresh: state.chatRefresh.refresh
  }
}

export default connect(
  mapStateToProps,
  null
)(HSNCodeFinder)