import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call,{callV2} from '../../../../service';

import { NewTable } from '../../../../utils/newTable';
import HeaderV2 from '../../../partial/headerV2';
import SideBarV2 from '../../../partial/sideBarV2';
import Filter from "../../components/Filter";
import moment from 'moment';
import Pagination from '../../../InvoiceDiscounting/contract/components/pagination';
import { convertImageToPdf, dataURItoBlob, getDocDetails, most_used_currencies } from '../../../../utils/myFunctions';
import { InputWithSelect, NewInput } from '../../../../utils/newInput';
import { ToastContainer } from 'react-toastify';
import { FileInput } from '../../../../utils/FileInput';
import toastDisplay from '../../../../utils/toastNotification';
import { PopupMessage } from '../../../popupMessage';
import { DocumentViewer } from '../../../../utils/documentViewer';
import FinanceInvoiceModal from '../../contract/components/financeinvoiceModal';
import ChatBoxPopUp from '../../../chatRoom/components/ChatBoxPopUp';
import axios from 'axios';
import { aiServerBackendUrl } from '../../../../urlConstants';

export const DisbursementV2 = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setdbData] = useState([])
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam || "" })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [disbPopup, setDisbPopup] = useState({ show: false, data: [], page: 1 })
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [limitinvoice, setLimitinvoice] = useState({ show: false, data: [] });
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [filterData, setFilterData] = useState({})
  const [applicationCounts, setApplicationCounts] = useState({})
  const [messagePopUp , setmessagepopUp] = useState(false)
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const prompt = `Analyze below disbursement OCR response & give me details in json format like this {disbursement_amount: Number} docTxtData`


  useEffect(() => {
    fetchApplicationCounts()
  }, [])

  async function fetchApplicationCounts() {
    call('POST', 'getInvoiceDisbursementApplicationCounts', { userId }).then(res => setApplicationCounts(res))
  }

  useEffect(() => {
    let objectAPI = {
      "userId": userId,
      "currentPage": page,
      "resultPerPage": 10,
      ...filter
    }
    call('POST', 'getDisbursementDetailsV2', objectAPI).then((result) => {
      console.log("getDisbursementDetailsV2 then", result);
      setTableData(formatDataForTable(result.limitData))
      setdbData(result.limitData)
      setCount(result.countData);
    }).catch((e) => {
      throw e;
    });
  }, [refresh])

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)

      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }
  const getChatRoomDetails = () => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    call('POST', 'getChatRoomIdByName', { chat_room_name: chatRoomName }).then((result) => {
      console.log('success in getChatRoomIdByName ', result)
      getChatDetails({
        chat_room_id: result.chat_room_id,
        loggedInUser: userId
      })
    }).catch(e => {
      console.log('error in getChatRoomIdByName', e)
    })
  }
  useEffect(() => {
    getChatRoomDetails()
  }, [selectedChat])

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let isOutstandingDatePassed = moment(item.scheduledOn).add(1, "day") < moment() && item.status / 1 == 0
      row[0] = item.invRefNo
      row[1] = item.company_name
      row[2] = moment(item.scheduledOn).format('DD/MM/YYYY')
      row[3] = item.amount + " " + item.currency
      row[4] =
        <label className={`${isOutstandingDatePassed ? "text-danger font-wt-600" : "text-color-0C0C0C"}`}>
          {isOutstandingDatePassed && (item.amount - (item.disbursedAmount || 0)) ? item.amount - (item.disbursedAmount || 0) + " " + item.currency : "-"}
        </label>
      row[5] =
        <label className={`${isOutstandingDatePassed ? " text-danger font-wt-600 " : "text-color-0C0C0C"}`}>
          {isOutstandingDatePassed && moment().diff(moment(item.scheduledOn), "days") > 0 ? (moment().diff(moment(item.scheduledOn), "days") + " Days") : "-"}
        </label>
      row[6] =
        <div className='row ' >
          {item.status == 1 ? (
            <a>
              <button type="button"
                className={`border-0 mb-2 text-white approvedQuotebtn`}>
                {"Disbursed !"}
              </button>
            </a>
          ) : (
            <a>
              <button type="button"
                onClick={
                  async () => {
                    // Get disbursement details by invrefno
                    setshowLoader(true)
                    let dbResp = await call('POST', 'getInvoiceDisbursementSchedule', { invRefNo: item.invRefNo })
                    setDisbPopup({ show: true, page: 1, data: dbResp })
                    setshowLoader(false)
                  }}
                className={`border-0 mb-2 text-white enableQuotebtn2`}>
                {"Disburse"}
              </button>
            </a >
          )}

        </div>
      tableData.push(row)
      row = []
    })
    return tableData
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        console.log(fileObj,"fileobj in disbuserement")
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }
console.log(data,"dtaaaaaa file uploaded---->>>")
  async function scheduleDisbursement(item) {
    let formData = new FormData()
    formData.append("id", item.id)
    if (data?.disbAttachment?.name) {
      formData.append("file", data.disbAttachment)
    }
    formData.append("disbursedAmount", item.disbursedAmount)
    formData.append("quoteId", item.invRefNo)
    formData.append("lenderEmail", userEmail)
    formData.append("userId", userId)
    setshowLoader(true)
    await call("POST", 'submitDisbursementSchedule', formData)
    setDisbPopup({ show: false })
    setshowLoader(false)
    toggleMsgPopup(true)
  }

  async function showDisbursmentSchedule(indx) {
    setshowLoader(true)
    let inv = tableData[indx][0]
    call('POST', 'getInvoiceDisbursementSchedule', { invRefNo: inv }).then(async (result) => {
      // console.log('running getInvoiceDisbursementSchedule api-->', result);
      setshowLoader(false)
      if (result.length) {
        setLimitinvoice({ show: true, data: result })
      }
      else {
        toastDisplay("No schedule provided by financier", "info")
      }
    }).catch((e) => {
      // console.log('error in getInvoiceDisbursementSchedule', e);
      setshowLoader(false)
      toastDisplay('Something went wrong', "error")
    });
  }

const CheckDisbursedAmount = async()=>{
  if(data?.disbAttachment){

  
  const val1 = disbPopup?.page2data?.disbursedAmount

  console.log(val1,"here is val1 vLUE ------>>>>>>>")
  console.log("here is ai api ------>>>>>>>")


  const formData = new FormData();
formData.append('defaultResp', JSON.stringify({ "disbursement_amount": "null" }));
formData.append('prompt', prompt);
formData.append('doc', data.disbAttachment);

  let OCRResponse = await axios.post(`${aiServerBackendUrl}/get_json_details_from_gpt`, formData, {
  })
  console.log(OCRResponse,"ocrrresponse---->>>")
 
    if(OCRResponse?.data?.disbursement_amount != val1){
      setmessagepopUp(true)
    }

}
}

useEffect(()=>{
  CheckDisbursedAmount()
},[data])

console.log(data,"data that determines pop up")

// const handleDisbursement =  () => {
//   if (disbPopup?.page2data?.disbursedAmount) {
//        CheckDisbursedAmount();
//       if (messagePopUp === true) {
//          scheduleDisbursement(disbPopup?.page2data);
//           toggleMsgPopup(true);
//       }
//   } else {
//       toastDisplay("Kindly enter disbursed amount", "info");
//   }
// }
console.log(messagePopUp,"messagepopuppppp---->>>>>")

  const intiateChat = (id) => {
    console.log('tabldata', dbData[id])
    let key = dbData[id]
    setSelectedChat({
      receiverName: key.company_name,
      receiverId: key.user_id,
      userId: userId,
      isChatOpen: true,
      logo: key.user_avatar
    })
  }



  
  return (
    <>
      {showMsgPopup ? (
        <PopupMessage
          onClose={() => { toggleMsgPopup(false); window.location.reload() }}
          title={"Disbursement done successfully"}
        />
      ) : null}

      {limitinvoice && limitinvoice.show && <FinanceInvoiceModal modalSize={"lg"} limitinvoice={limitinvoice.show} closeSuccess={() => {
        setLimitinvoice({ show: false, data: [] });
      }} >
        <p>Disbursement schedule</p>
        <table
          className='details text-center mx-5'>
          <tr className='th p-1 d-flex justify-content-around'>
            <th className='heading text-secondary width16' scope="col">
              Scheduled Date
            </th>
            <th className='heading text-secondary width16' scope="col">
              Scheduled Amount
            </th>
            <th className='heading text-secondary width16' scope="col">
              Disbursement Date
            </th>
            <th className='heading text-secondary width16' scope="col">
              Disbursement Amount
            </th>
            <th className='heading text-secondary width16' scope="col">
              Status
            </th>
            <th className='heading text-secondary width16' scope="col">
              {" "}
            </th>
          </tr>

          {limitinvoice.data.map((item, index) => {
            return (
              <tr className={`th bg-white rounded-0 p-0`}
              >
                <td className='heading width16'>
                  {moment(item.scheduledOn).format('DD/MM/YYYY')}
                </td>
                <td className='heading width16'>
                  {item.currency + " " + item.amount}
                </td>
                <td className='heading width16'>
                  {item.status == 1 ? moment(item.updatedAt).format('DD/MM/YYYY') : "-"}
                </td>
                <td className='heading width16'>
                  {item.status == 1 ? (item.currency + " " + item.disbursedAmount) : "-"}
                </td>
                <td className='heading width16'>
                  {item.status ? (
                    <img src={"/assets/images/upload_done.png"} alt="Yes" height={16} width={17} />
                  ) : (
                    <img src={"/assets/images/processing.png"} alt="" height={16} width={17} />
                  )}
                </td>
                <td className='heading width16'>
                  <label
                    onClick={async () => {
                      if (item.attachment) {
                        let docFileObj = await getDocDetails(item.attachment)
                        toggleViewDoc({ show: true, doc: docFileObj })
                        setLimitinvoice({ show: false, data: [] });
                      }
                      else {
                        toastDisplay("Receipt not uploaded by financer", "info")
                      }
                    }}
                    className="text-color1 font-wt-600 cursor"
                  >{item.attachment ? "View Receipt" : "NA"}</label>
                </td>
              </tr>
            )
          })}
        </table>
      </FinanceInvoiceModal>}
      {(disbPopup?.show && disbPopup?.page === 1) ?
        <div className={`modal fade ${disbPopup.show && "show"}`} style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content submitmodal pb-4 mt-5">
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbPopup({ show: false, page: 0, data: [] })}></button>
              </div>
              <div className="modal-body text-center">
                <p className="mb-4">Disbursement Schedule</p>
                {disbPopup.data.map((item, index) => {
                  return (
                    <div className="row pl-5 ml-4">
                      <div className="col-4">
                        <NewInput
                          value={moment(item.scheduledOn).format('YYYY-MM-DD')}
                          label={"Date"} type={"date"} isDisabled={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputWithSelect
                          isDisabled={true}
                          selectData={most_used_currencies} selectName={"unit"}
                          defaultSelectValue={item["currency"]} name={"amount"} value={item["amount"]}
                          optionLabel={'code'} optionValue={'code'}
                          label={"Amount"} type={"number"}
                        />
                      </div>
                      <div className="col-4 d-flex align-items-center">
                        <button type="button" className={`border-0 text-white mb-3 ${item.status == 1 ? ' approvedQuotebtn ' : " enableQuotebtn2 "} `}
                          onClick={() => {
                            if (item.status == 0) {
                              setDisbPopup({ ...disbPopup, page: 2, page2data: item })
                            }
                          }
                          }
                        >{item.status == 0 ? "Disburse" : "Disbursed!"}</button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div> :
        (disbPopup.show && disbPopup.page === 2) &&
        <div className={`modal fade ${disbPopup.show && "show"}`} style={{ display: "block" }}>
          <div className="modal-dialog modal-m">
            <div className="modal-content submitmodal pb-4 mt-5">
        
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close"
                  onClick={() => setDisbPopup({ show: false })}
                ></button>
              </div>
              <div className="modal-body text-center">
                <p className="mb-4">Disbursement Schedule</p>
                <div className="row justify-content-center">
                  <div className="col-5">
                    <InputWithSelect
                      isDisabled={true}
                      selectData={most_used_currencies} selectName={"unit"}
                      defaultSelectValue={disbPopup?.page2data?.currency} name={"amount"} value={disbPopup?.page2data?.amount}
                      optionLabel={'code'} optionValue={'code'}
                      label={"Scheduled Amount"} type={"number"}
                    />
                  </div>
                  <div className="col-5">
                    <InputWithSelect
                      selectData={most_used_currencies} selectName={"unit"}
                      defaultSelectValue={disbPopup?.page2data?.currency} name={"disbursedAmount"}
                      value={disbPopup?.page2data?.disbursedAmount}
                      optionLabel={'code'} optionValue={'code'}
                      onChange={e => {
                        setDisbPopup({ ...disbPopup, page2data: { ...disbPopup.page2data, [e.target.name]: e.target.value } })
                      }}
                      label={"Disbursement Amount"} type={"number"}
                    />
                  </div>
                  <div className="col-10 mb-2">
                    <label className="my-2" >Disbursement Receipt</label>
                    <FileInput name={"disbAttachment"} value={data.disbAttachment} error={errors.disbAttachment}
                      onChange={handleFile} isEditable={true}
                      onUploadCancel={() => setData({ ...data, "disbAttachment": null })}
                    />
                  </div>
                  {messagePopUp && (
                    <div className='smallRedText mb-4'>
                      Disbursed Amount Entered and Disbursed Amount in receipt are different
                    </div>
                  )}
                  <div className="col-8 align-items-center d-flex justify-content-center mt-2">
                    <button type="button" className={` new-btn py-2 px-4 text-white `}
                      onClick={() => {
                        if (disbPopup?.page2data?.disbursedAmount) {
                          
                          scheduleDisbursement(disbPopup?.page2data)
                          
                        }
                        else {
                          toastDisplay("Kindly enter disbursed amount", "info")
                        }
                      }}
                    >{"Disburse"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        
      }
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_disbursement" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice-discounting > Disbursement >"}
              userTokenDetails={userTokenDetails} />
            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 border1Blue' style={{ width: '39.22%' }} >
                <label className='font-size-16 font-wt-500 color0C0C0C' >Total 12 weeks payment summary</label>
                <div className='d-flex row justify-content-between' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.disbursed12Week || 0))}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Disbursed</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.due12Week || 0))}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Due</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.overdue12Week || 0))}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Overdue</p>
                  </div>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '28.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.disbursed || 0))}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>
                    <img src='assets/images/disbursed_icon.png' className='pb-1 mr-2' style={{ width: '30px', height: '30px' }} />
                    Disbursed</p>
                </div>
              </div>
              <div
                className='card border1Blue pl-4 d-flex justify-content-center cursor' style={{ width: '28.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.overdue || 0))}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>
                    <img src='assets/images/overdue_icon2.png' className='pb-1 mr-2' style={{ width: '30px', height: '30px' }} />Over Due</p>
                </div>
              </div>
            </div>
            <div className='filter-div position-relative'>
              <Filter singleMultApplication={false}
                filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
            </div>
            <div className='mt-4'>
              <NewTable disableAction={false}
                columns={[{ name: "Transaction Id", filter: true }, { name: "Supplier Name", filter: true }, { name: "Disbursement Date", filter: true }, { name: "Disbursement Amount", filter: true }, { name: "Outstanding Amount", filter: true }, { name: "Outstanding Days", filter: true },
                { name: "Action", filter: false }, {name: " "}
                ]}
                data={tableData}
                options={[
                  { name: "Chat with supplier", onClick: (id) => intiateChat(id) },
                  { name: "Disbursement Schedule", onClick: (index) => { showDisbursmentSchedule(index) } }]}
              />
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} perPage={filter.resultPerPage || 0} />
            </div>
          </main>
        </div>
        {selectedChat.isChatOpen &&
          <div className="chatboxDiv">
            <ChatBoxPopUp
              chatList={chatList}
              user_avatar={selectedChat.logo}
              userId={selectedChat.userId}
              receiverName={selectedChat.receiverName}
              onChatSend={(file) => sendChatMsg(file)}
              message={message}
              setTextMsg={setTextMsg}
              onPopupClose={() => {
                setSelectedChat({
                  receiverName: '',
                  receiverId: '',
                  userId: userId,
                  isChatOpen: false,
                  logo: ''
                })
              }}
            />
          </div>
        }
      </div>
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(DisbursementV2)