import React, { useState } from 'react';
import NewTablev2 from '../../../utils/newTablev2';

const PaginatedTable = ({ data, columns, tableHeader, selectedData, freezeFirstColumn }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 11;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };
  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <div className='custom-scrollbar'>
        <NewTablev2 columns={columns} freezeFirstColumn={freezeFirstColumn} showSideBorders={true} customStyles={{ minWidthOfCell: "100px" }}>
          {currentRows.map((row, index) => (
            <tr key={index}>
              <td style={{
                minWidth: '150px',
                padding: '20px !important',
                position: freezeFirstColumn ? 'sticky' : 'static',
                left: freezeFirstColumn ? 0 : 'auto',
                backgroundColor: freezeFirstColumn ? 'white' : 'transparent',
                zIndex: freezeFirstColumn ? 1 : 'auto',
              }}>{capitalizeFirstLetter(row[tableHeader])}</td>
              {selectedData[0].hasOwnProperty("rate") && <td style={{ minwidth: "150px" }}>{row.rate}</td>}
              {selectedData[0].hasOwnProperty("QtyWithUnits") && <td style={{ width: "150px" }}>{row.QtyWithUnits}</td>}
              {selectedData[0].hasOwnProperty("taxPaid") && <td style={{ width: "150px" }}>{row.taxPaid}</td>}
              {selectedData[0].hasOwnProperty("taxLess") && <td style={{ width: "150px" }}>{row.taxLess}</td>}
              {selectedData[0].hasOwnProperty("ASPTaxPaid") && <td style={{ width: "150px" }}>{row.ASPTaxPaid}</td>}
              {selectedData[0].hasOwnProperty("ASPTaxLess") && <td style={{ width: "150px" }}>{row.ASPTaxLess}</td>}
              {selectedData[0].hasOwnProperty("totalAmount") && <td style={{ width: "150px" }}>{row.totalAmount}</td>}
              {selectedData[0].hasOwnProperty("contribution") && <td style={{ width: "150px" }}>{row.contribution}</td>}
            </tr>
          ))}
        </NewTablev2>

      </div>
      <div className="pagination-controls d-flex justify-content-between align-items-center" style={{ bottom: 0, right: 0 }}>
        <img onClick={handlePreviousPage} src={"/assets/images/arrow-left.png"} alt="" className="cursor ms-5" />

        {/* <button onClick={handlePreviousPage} disabled={currentPage === 1}> */}
        {/* &lt; */}
        {/* </button> */}
        {/* <span>Page {currentPage} of {totalPages}</span> */}
        <p>Page {currentPage} of {totalPages}</p>


        {/* <button onClick={handleNextPage} disabled={currentPage === totalPages}> */}
        <img onClick={handleNextPage} disabled={currentPage === totalPages} src={"/assets/images/arrow-right.png"} className="cursor me-5" alt="" />

        {/* &gt; */}
        {/* </button> */}
      </div>
    </>
  );
};

export default PaginatedTable;
