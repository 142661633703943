import moment from "moment";
import React, { useState } from "react";
import call from "../../../service";
import avatarUrl from "../../../utils/makeAvatarUrl";
import InputEdit from "../../viewProfile/components/inputEdit";
const ChatBodyGroup = ({ recievedchatdata, userId, selectedUser, checked, goback }) => {
  const [groupName, setGroupName] = useState("")
  const [errors, setErrors] = useState()
  const createGroup = () => {
    if (groupName === "") {
      setErrors("Group Name is empty")
    } else {
      //create Chat room
      let receivers = ''
      Object.values(checked).forEach((user, index) => {
        console.log('All the group members', user)
        if (user.tbl_user_id) {
          receivers += `${user.tbl_user_id}${index === Object.values(checked).length - 1 ? "" : ','}`
        } else {
          receivers += `${user.id}${index === Object.values(checked).length - 1 ? "" : ','}`
        }
      })
      console.log('recievers', receivers)
      let reqObj = {
        senderId: userId,
        receiverId: receivers,
        textMessage: 'Hii All',
        chat_room_name: groupName
      }
      call('POST', 'sendChatMessage', reqObj).then((result) => {
        console.log('success in sendChatMessage ', result)
        goback()
      })

    }
  }
  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    setGroupName(event.target.value)
    setErrors("")
  }
  return (
    <div className="h-100">
      {Object.keys(checked).length ?
        <div className="col-8 p-5">
          <p>Create new Group</p>
          <div className="col-md-10 px-0">
            <InputEdit Placeholder="Enter Group Name" Label="" value={groupName} name={"groupName"} onChange={handleChange} />
            {errors ? <div class="text-danger mt-2 font-size-12">
              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
              <b>{errors}</b></div> : ''}
          </div>
          <div className="w-100">
            <div className="selectedDiv">
              {Object.keys(checked).map(data => {
                return <div className="SelectedPart ">
                  <label className="text-center pt-2">{data}</label>
                  <img alt="" src="/assets/images/cross.svg" />
                </div>
              })}
            </div>

          </div>
          <div className="d-flex gap-4">
            <button className={`my-4 new-btn w-40 py-2 px-2 text-white cursor`} onClick={createGroup}>Create Group</button>
          </div>
        </div>
        :
        <div className="pt-5">
          <div className="d-flex justify-content-center">
            <img alt="" src="/assets/images/New-Group-illustration.svg" />
          </div>
          <div className="">
            <p className="font-size-16 font-wt-500 text-center">Create New Group</p>
            <p className="font-size-13 font-wt-500 text-center">Click on the tick box to add participants in your group,<br />selected participants will be shown at this screen.</p>
          </div>
        </div>}
    </div>
  );
}
export default ChatBodyGroup;