import React from "react";
import call from "../service";

export const TutorialVideoPopup = ({ show, videoLink, onClose }) => {
  return (
    <div className={`modal fade ${show && "show"}`} style={show ? { display: 'block', zIndex: 1000000 } : {}}
      onClick={onClose}>
      <div className="modal-dialog modal-md"
      >
        <div className="modal-content tutorialModal pb-0 bg-transparent" style={{}}>
          <div className="modal-header border-0 pb-0 px-4">
            {/* <label
              onClick={onClose}
              className="font-size-14 font-wt-600 text-color-value cursor">{"Close"}</label> */}
          </div>
          <div className="modal-body text-center pt-0 px-4 pb-2">
            <center>
              <iframe height={500} width={500}
                src={videoLink} allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen">
              </iframe>
            </center>
          </div>
        </div>
      </div>
    </div>
  )
}

const TutorialPopup = ({ show, children, showSkip, featureName, positioning, arrowPositioning, userId, videoLinkLabel, onVideoLinkClick, showBack, showNext,
  onBack, onNext, showDone, showCustomButtonLabel, onCustomButtonLabel }) => {
  return (
    <>
      <div className={`position-absolute ${!show ? ' d-none ' : ''}`} style={arrowPositioning}>
        <img className="" src="assets/images/bottom-top-left-arrow.png" alt="description" />
      </div>
      <div className={`modal fade ${show && "show"}`} style={show ? { display: 'block' } : {}}>
        <div className="modal-dialog modal-md" style={positioning}>
          <div className="modal-content tutorialModal pb-0 w-75 "
          >
            <div className="modal-header border-0 pb-0 px-4">
              <label className="font-size-14 font-wt-600 text-color-value"><u>{featureName}</u></label>
              {showSkip ? (
                <label
                  onClick={() => {
                    call('POST', 'markTutorialComplete', { userId, skippedStage: localStorage.getItem("lcTutorialStep") }).then((res) => {
                      localStorage.removeItem("lcTutorialStep")
                      window.location = '/buyerManagement'
                    })
                  }}
                  className="font-size-14 font-wt-400 cursor text-color-value cursor">{"Skip"}</label>
              ) : null}
            </div>
            <div className="modal-body text-center pt-0 px-4 pb-2">
              {children}
              {/* {videoLinkLabel ? (
                <label
                  onClick={onVideoLinkClick}
                  className="text-color1 font-size-14 font-wt-600 cursor w-100 text-start">{videoLinkLabel}</label>
              ) : null} */}
              <div className="d-flex flex-row justify-content-between pb-0">
                <label onClick={() => {
                  if (showBack) {
                    onBack()
                  }
                }} className="font-size-14 font-wt-600 text-color-label cursor">{showBack ? "< Back" : " "}</label>
                <label onClick={() => {
                  if (showNext && !showDone) {
                    onNext()
                  }
                }} className="font-size-14 font-wt-600 text-color-label cursor">{showNext && !showDone ? "Next >" : " "}
                </label>

                {showCustomButtonLabel ? (
                  <label onClick={() => { onCustomButtonLabel() }} className="font-size-14 font-wt-600 text-color1 cursor">{showCustomButtonLabel + " >"}
                  </label>
                ) : null}

                {showDone ? (
                  <label
                    onClick={() => {
                      call('POST', 'markTutorialComplete', { userId }).then((res) => {
                        localStorage.removeItem("lcTutorialStep")
                        window.location = '/buyerManagement'
                      })
                    }} className="font-size-14 font-wt-600 text-color1 cursor">Get Started</label>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TutorialPopup;