import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import ReactCountryFlag from "react-country-flag";
import avatarUrl from '../../utils/makeAvatarUrl';
import FilePreview from '../utilComponents/previewFile';
import toastDisplay from '../../utils/toastNotification';
import KYCDocumentsComp from './kycDocuments';
import ViewProfileDemo from '../admin/viewProfileToAdmin';
import Registration from '../registration/registration';
import { setRegistrationData } from '../../store/actions/action';

const AdminProfile = ({ userTokenDetails, userProfileDataG, values, clientType, KYCStatus, handleAvatar, handleChange, handleSubmit, errors, refresh, setrefresh }) => {

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  //States and Variables

  const [countryData, setcountryData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [admintab, setAdminTab] = useState(0);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [stennKYC, setStennKYC] = useState({});

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null
  const aclMap = userTokenDetails && userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  const astrix = <span className="required-field text-danger">*</span>


  //------------------------------------------------------------------------------------------------------------------------------------------------
  //UseEffect

  useEffect(() => {

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    call('POST', 'stennKYC', { "userEmail": userProfileDataG.email_id }).then((result) => {
      console.log("Result in  stennKYC:-->", result);
      setStennKYC(result);
    }).catch((e) => {
      console.log("Error while  stennKYC:-->", e);
    });

  }, []);


  //-------------------------------------------------------------------------------------------------------------------------------------------------
  //Functions

  function callPreviewFileComponent(values, action) {
    setfileData({ ...values, "action": action })
    setshowPreviewModal(true)
  };

  function copyToClipboard(textId) {
    let copyText = document.getElementById(textId);
    // copyText.select();
    // document.execCommand("copy");

    let temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = copyText.innerText
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);

    copyText.classList.remove("text-primary");
    copyText.classList.add("text-dark");

    setTimeout(function () {
      copyText.classList.remove("text-dark");
      copyText.classList.add("text-primary");
    }, 2000);

  }

  function regenMyReferCode() {
    call('get', 'regenmyrefercode').then((result) => {
      if (result) {
        toastDisplay(result, "success");
        setrefresh(refresh + 1)
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }

  function scrolltoError() {
    return (errors.companyName ? document.getElementById("POScompanyName").scrollIntoView({ behavior: "smooth", block: "center" })
      : errors.contactPerson ? document.getElementById("POScontactPerson").scrollIntoView({ behavior: "smooth", block: "center" })
        : errors.iecNo ? document.getElementById("POSiecNo").scrollIntoView({ behavior: "smooth", block: "center" })
          : errors.email ? document.getElementById("POSemail").scrollIntoView({ behavior: "smooth", block: "center" })
            : errors.contactNo ? document.getElementById("POScontactNo").scrollIntoView({ behavior: "smooth", block: "center" })
              : errors.address ? document.getElementById("POSaddress1").scrollIntoView({ behavior: "smooth", block: "center" })
                : errors.country ? document.getElementById("POScountry").scrollIntoView({ behavior: "smooth", block: "center" })
                  : errors.ownerIdentityProof ? document.getElementById("POSownerIdentityProof").scrollIntoView({ behavior: "smooth", block: "center" })
                    : errors.companyAddressProof ? document.getElementById("POScompanyAddressProof").scrollIntoView({ behavior: "smooth", block: "center" })
                      : "")
  }

  function kycVerification() {

    call('POST', 'kycVerification', values).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false);
      setrefresh(refresh + 1);
    }).catch(err => {
      setshowLoader(false);
      console.log("conn:", err);
      toastDisplay(err, "error");
    })
  }


  //------------------------------------------------------------------------------------------------------------------------
  //JSX Return

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <>

        <ul className="nav nav-tabs-custom mt-2" id="myTab" role="tablist">
          <li>
            <a className={"nav-link" + (admintab === 0 ? " active show" : "")} onClick={() => setAdminTab(0)}>User Profile</a>
          </li>
          {/* <li>
            <a className={"nav-link" + (admintab === 1 ? " active show" : "")} onClick={() => setAdminTab(1)}>User Details</a>
          </li> */}
          <li>
            <a className={"nav-link" + (admintab === 2 ? " active show" : "")} onClick={() => setAdminTab(2)}>KYC Documents</a>
          </li>
          {userProfileDataG.type_id != 9 &&
            <li>
              <a className={"nav-link" + (admintab === 3 ? " active show" : "")} onClick={() => setAdminTab(3)}>Add Sub-User</a>
            </li>}
        </ul>

        {admintab == 0 &&
          <div className="col-md-12" >
            <div className="row shadow bg-light pt-5 pb-5">
              <div className="col-md-3">
                <div className="profile-sidebar">
                  <div className="profile-userpic">
                    {/* {values.avatarBase64 ? console.log("value") : console.log("api")} */}
                    <img src={values.avatarBase64 ? values.avatarBase64 : avatarUrl(values.userAvatar)} id="myImg" className="img-responsive" alt="Avatar" />
                    <span className="icon-edit-profile">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                      <input type="file" accept=".png,.jpg" id="file_3" dir="2" name="avatar" onChange={handleAvatar} />
                    </span>
                  </div>
                  <div className="profile-userbuttons text-center mt-2 ">
                    <label>Avatar {astrix} </label>
                  </div>
                  {errors.avatar && scrolltoError()}
                  {errors.avatar && <p className="text-danger error-contract">{errors.avatar}</p>}
                </div>

                <div className="mt-3 text-center">
                  <h5>KYC STATUS</h5>
                </div>

                {/* <div className="mt-3 text-center">
                  {kycStatus.map((item) => {
                    return (<>
                      <h6
                        onClick={() => {
                          setKYCStatus({ modal: true, data: item })
                        }}
                      > {item.key} </h6>
                    </>)
                  })}
                </div> */}

                {/* {KYCStatus.modal && KYCStatus.data.key == 'Trade Reboot' && */}
                <>
                  <div className="mt-3 text-right">
                    <div className="mt-3 text-center">
                      {(values.company_pan_verification == "true") ?
                        <div className="text-center">
                          <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> PAN Verified</h6>
                        </div> :
                        <div className="text-center">
                          <h6 className="text-danger cursor-pointer" onClick={() => setAdminTab(2)}><i class="fa fa-times-circle" aria-hidden="true"></i> PAN Not-Verified</h6>
                        </div>
                      }
                    </div>

                    <div className="mt-3 text-center">
                      {(values.company_gst_verification == "true") ?
                        <div className="text-center">
                          <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> GST Verified</h6>
                        </div> :
                        <div className="text-center">
                          <h6 className="text-danger cursor-pointer" onClick={() => setAdminTab(2)}><i class="fa fa-times-circle" aria-hidden="true"></i> GST Not-Verified</h6>
                        </div>
                      }
                    </div>

                    <div className="mt-3 text-center">
                      {(values.company_iec_verification == "true") ?
                        <div className="text-center">
                          <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> IEC Verified</h6>
                        </div> :
                        <div className="text-center">
                          <h6 className="text-danger cursor-pointer" onClick={() => setAdminTab(2)}><i class="fa fa-times-circle" aria-hidden="true"></i> IEC Not-Verified</h6>
                        </div>
                      }
                    </div>

                    <div className="mt-3 text-center">
                      {(values.company_cin_verification == "true") ?
                        <div className="text-center">
                          <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> CIN Verified</h6>
                        </div> :
                        <div className="text-center">
                          <h6 className="text-danger cursor-pointer" onClick={() => setAdminTab(2)}><i class="fa fa-times-circle" aria-hidden="true"></i> CIN Not-Verified</h6>
                        </div>
                      }
                    </div>
                  </div>

                </>
                {/* } */}

                {/* {KYCStatus.modal && KYCStatus.data.key == 'STENN' &&
                  <div className="mt-3 text-center">
                    {(stennKYC.stennKYC == "true") ?
                      <div className="text-center">
                        <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i>STENN Verified</h6>
                      </div> :
                      <div className="text-center">
                        <h6 className="text-danger cursor-pointer"><i class="fa fa-times-circle" aria-hidden="true"></i> STENN Not-Verified</h6>
                      </div>
                    }
                  </div>
                } */}

                <hr />

                <div className="mt-3 text-center">
                  <h3 className="text-primary" id="myrefercode">{values.refercode ? values.refercode : "NA"}</h3>
                  <label>
                    My referral Code
                    <span className="cursor-pointer text-success pl-2 pr-2" title="Copy to Clipboard" onClick={() => copyToClipboard("myrefercode")}> <i class="fas fa-clipboard"></i></span>
                    <span className="cursor-pointer text-warning pr-2" title="Regenerate Referral Code" onClick={() => regenMyReferCode()}> <i class="fas fa-redo"></i></span>
                  </label>
                  <br />
                  <span className="help-icon" flow="right" tooltip="My Referral Code, Others can use it to add me">i</span>
                </div>

              </div>
              <div className="col-md-9 mt-2">
                <div className="pr-4">

                  <div className="form-group" id="POScontactPerson">
                    <label>Contact Person {astrix} </label>
                    <input type="text" className={" form-control" + (!errors.contactPerson ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="contactPerson" value={values.contactPerson} onChange={handleChange} required />
                    {errors.contactPerson && scrolltoError()}
                    {errors.contactPerson && <p className="text-danger error-contract">{errors.contactPerson}</p>}
                  </div>

                  <div className="form-group" id="POSdesignation">
                    <label>Designation</label>
                    <input type="text" className={" form-control" + (!errors.designation ? '' : ' border-danger')} placeholder="Enter Designation" name="designation" value={values.designation} onChange={handleChange} required />
                    {errors.designation && (<p className="text-danger error-contract">{errors.designation}</p>
                    )}
                  </div>


                  <div className="form-group" id="POSemail">
                    <label>Email ID {astrix} </label>
                    <input type="text" className={" form-control" + (!errors.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={values.email} onChange={handleChange} required disabled />
                    {errors.email && scrolltoError()}
                    {errors.email && <p className="text-danger error-contract">{errors.email}</p>}
                  </div>

                  <div className="form-group" id="POScontactNo">
                    <label>Contact No. {astrix} </label>
                    <input type="number" pattern=".{10,13}" className={" form-control" + (!errors.contactNo ? '' : ' border-danger')} placeholder="Enter Contact No" name="contactNo" value={values.contactNo} onChange={handleChange} required />
                    {errors.contactNo && scrolltoError()}
                    {errors.contactNo && <p className="text-danger error-contract">{errors.contactNo}</p>}
                  </div>

                  <div className="form-group" id="POSreferralCode">
                    <label>Referral Code</label>
                    <input type="text" className={" form-control" + (!errors.CPrefercode ? '' : ' border-danger')} placeholder="Enter Contact No" name="CPrefercode" value={values.CPrefercode} onChange={handleChange} disabled={values.CPrefercode ? true : false} required />
                    {errors.CPrefercode && <p className="text-danger error-contract">{errors.CPrefercode}</p>}
                  </div>

                  <div className="form-group" id="POSaddress1">
                    <label>Address {astrix} </label>
                    <textarea className={" form-control" + (!errors.address ? '' : ' border-danger')} placeholder="Enter Flat / House / Office No" name="address" onChange={handleChange} value={values.address} required />
                    {errors.address && scrolltoError()}
                    {errors.address && <p className="text-danger error-contract">{errors.address}</p>}
                  </div>

                  <div className="form-group flag-relative" id="POScountry">
                    <div className="flag-icon">
                      {values.country ? <span className="shadow">
                        <ReactCountryFlag
                          countryCode={values.country}
                          style={{ width: '25px', height: '25px' }} svg />
                      </span> :
                        <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image" />}
                    </div>
                    <label>Country {astrix}  </label>
                    <select className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={values.country} onChange={handleChange} >
                      <option value="" selected>Select Country</option>
                      {countryData.map((country) => {
                        return (
                          <option value={country.sortname} >{country.name}</option>
                        )
                      })}
                    </select>
                    {errors.country && scrolltoError()}
                    {errors.country && <p className="text-danger error-contract">{errors.country}</p>}
                  </div>
                  <hr className="pt-2 pb-2 text-primary" />
                  <div className="col-md-12">
                    <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => kycVerification()}>Re-Verification</button>

                    <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => handleSubmit()}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {admintab == 1 &&
          // <ViewProfileDemo
          //   userTokenDetails={userTokenDetails}
          //   refresh={refresh}
          //   setrefresh={setrefresh}
          //   compType={'admin'} />
          <></>
        }

        {admintab == 2 &&
          <div className={"tab-pane active show col-md-10 offset-md-1"}>
            <div className="col-md-12 shadow bg-light pt-5 pb-5">
              <KYCDocumentsComp userTokenDetails={userTokenDetails} userEmail_id={userProfileDataG.email_id} clientType={clientType} />
            </div>
          </div>}

        {admintab == 3 &&
          <div className={"tab-pane active show col-md-10 offset-md-1"}>
            <div className="col-md-12 shadow bg-light pt-5 pb-5">
              <Registration userTokenDetails={userTokenDetails} employeeData={{ type: 9, step: 2, manualUserId: userProfileDataG.tbl_user_id, manualUserTypeId: userProfileDataG.type_id, manualUserTechTypeId: userProfileDataG.tech_type_id }} />
            </div>
          </div>}
      </>
      {
        showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />
      }
    </>
  )
};


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProfile)