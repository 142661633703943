import React, { useEffect, useState } from 'react';
import SmallCard from '../headerCard';
import NewTablev2 from '../../../../utils/newTablev2';
import DropdownAndInput from './dropdownWithInput';
import { getUserDataFromCookie } from '../../../../utils/cookieHelper';
import call from '../../../../service';
import * as XLSX from 'xlsx';

export default function ItemView({ allData }) {
  const userTokenDetails = getUserDataFromCookie();
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;

  const [selectedBtn, setSelectedBtn] = useState('All');
  const [aggregatedData, setAggregatedData] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // filter pop up
  const [comparisonQty, setComparisonQty] = useState('');
  const [inputValueQty, setInputValueQty] = useState('');
  const [comparisonValue, setComparisonValue] = useState('');
  const [inputValueValue, setInputValueValue] = useState('');
  const [comparisonSales, setComparisonSales] = useState('');
  const [inputValueSales, setInputValueSales] = useState('');
  const [comparisonPurchases, setComparisonPurchases] = useState('');
  const [inputValuePurchases, setInputValuePurchases] = useState('');

  const headerBtns = [
    { name: 'All', number: "" },
    { name: 'Non Moving', number: "" },
  ];

  const [columns, setColumns] = useState([
    { subColumns: "Item Name", subColumnClass: "" },
    { subColumns: "Stock Qty", subColumnClass: "" },
    { subColumns: "Stock Value", subColumnClass: "" },
    { subColumns: "Sales", subColumnClass: "" },
    { subColumns: "Purchases", subColumnClass: "" }
  ]);

  const fetchStockData = async (itemName) => {
    try {
      const objectAPI = { userID: userId, itemName: itemName };
      const result = await call('POST', 'getstockdata', objectAPI);
      console.log("result.stockData", result.stockData)
      return result.stockData;
    } catch (error) {
      console.error('Error fetching stock data:', error);
      return { quantity: 0, rate: 0 }; // Default values or handle error as needed
    }
  };

  const aggregateData = async (data) => {
    setShowLoader(true);
    const uniqueItems = Array.from(new Set(data.map(item => item.itemName)));

    const aggregatedData = await Promise.all(uniqueItems.map(async (itemName) => {
      const stockData = await fetchStockData(itemName);
      const filteredItems = data.filter(item => item.itemName === itemName);
      console.log(filteredItems)
      const aggregatedItem = {
        itemName: itemName,
        quantity: `${stockData.quantity} ${stockData.units}`,
        stockValue: stockData.quantity * stockData.rate,
        sales: filteredItems.filter(item => item.type === 'Sales').reduce((acc, curr) => acc + curr.totalAmount, 0),
        purchases: filteredItems.filter(item => item.type === 'Purchase').reduce((acc, curr) => acc + curr.totalAmount, 0),
      };

      return aggregatedItem;
    }));
    setShowLoader(false);

    return aggregatedData;
  };

  useEffect(() => {
    console.log(allData)
    const fetchData = async () => {
      if (allData) {
        const aggregatedData = await aggregateData(allData);
        setAggregatedData(aggregatedData);
        setDataForTable(aggregatedData);
      }
    };

    fetchData();

  }, [allData]);

  useEffect(() => {
    const filterDataForTable = () => {
      let filteredData = aggregatedData;

      if (selectedBtn === 'Non Moving') {
        filteredData = filteredData.filter(item => item.sales === 0);
      }

      setDataForTable(filteredData);
    };

    filterDataForTable();
  }, [selectedBtn, aggregatedData]);

  const handleClickForTopMenu = (title) => {
    setSelectedBtn(title);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePopupClose = () => {
    setShowFilterPopup(false);
  };
  function extractQuantity(quantityString) {
    // Extract all numbers from the string
    const numbers = quantityString.match(/\d+(\.\d+)?/g);
    if (numbers) {
      // Convert extracted strings to numbers and sum them up
      return numbers.reduce((sum, num) => sum + parseFloat(num), 0);
    }
    return 0;
  }

  const filteredDataForTable = dataForTable.filter(item =>
    item.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const calculateTotals = (data) => {
    const totals = data.reduce(
      (acc, item) => {
        acc.totalItems += 1;
        // acc.totalQty += extractQuantity(item.quantity);
        acc.totalValue += item.stockValue;
        acc.totalSales += item.sales;
        acc.totalPurchases += item.purchases;
        return acc;
      },
      { totalItems: 0, totalQty: 0, totalValue: 0, totalSales: 0, totalPurchases: 0 }
    );
    return totals;
  };
  useEffect(() => {
    const newTotals = calculateTotals(dataForTable);

    setColumns([
      {
        subColumns: (
          <div>
            Item Name
            <div style={{ color: '#fd7e14' }}>{newTotals.totalItems}</div>
          </div>
        ),
        subColumnClass: ""
      },
      {
        subColumns: (
          <div>
            Stock Qty
            {/* <div style={{ color: '#fd7e14' }}>{newTotals.totalQty}</div> */}
          </div>
        ),
        subColumnClass: ""
      },
      {
        subColumns: (
          <div>
            Stock Value ($)
            <div style={{ color: '#fd7e14' }}>{newTotals.totalValue}</div>
          </div>
        ),
        subColumnClass: ""
      },
      {
        subColumns: (
          <div>
            Sales ($)
            <div style={{ color: '#fd7e14' }}>{newTotals.totalSales}</div>
          </div>
        ),
        subColumnClass: ""
      },
      {
        subColumns: (
          <div>
            Purchases ($)
            <div style={{ color: '#fd7e14' }}>{newTotals.totalPurchases}</div>
          </div>
        ),
        subColumnClass: ""
      }
    ]);

  }, [dataForTable])
  // filter logics-popup
  const handleResetFilters = () => {
    setComparisonQty('');
    setInputValueQty('');
    setComparisonValue('');
    setInputValueValue('');
    setComparisonSales('');
    setInputValueSales('');
    setComparisonPurchases('');
    setInputValuePurchases('');
  };
  const handleGo = () => {
    const applyFilter = (data, comparison, inputValue, key) => {
      if (!comparison || !inputValue) return data;
      const value = parseFloat(inputValue);
      return data.filter(item => {
        switch (comparison) {
          case 'Greater Than':
            return item[key] > value;
          case 'Equals':
            return item[key] === value;
          case 'Less Than':
            return item[key] < value;
          default:
            return true;
        }
      });
    };

    let filteredData = aggregatedData;

    if (inputValueQty) filteredData = applyFilter(filteredData, comparisonQty, inputValueQty, 'quantity');
    if (inputValueValue) filteredData = applyFilter(filteredData, comparisonValue, inputValueValue, 'stockValue');
    if (inputValueSales) filteredData = applyFilter(filteredData, comparisonSales, inputValueSales, 'sales');
    if (inputValuePurchases) filteredData = applyFilter(filteredData, comparisonPurchases, inputValuePurchases, 'purchases');

    console.log(filteredData);
    setDataForTable(filteredData);
    handlePopupClose();
  };

  const handleDownload = () => {
    const tableData = filteredDataForTable.map(item => ({
      'Item Name': item.itemName,
      'Stock Qty': item.quantity,
      'Stock Value': item.stockValue,
      'Sales': item.sales,
      'Purchases': item.purchases
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "tableData.xlsx");
  };

  return (
    <div className='shadow rounded p-2'>
      <div className='d-flex justify-content-between w-100 mb-2'>
        <p>Ageing Report</p>
        <div className='d-flex justify-content-between align-items-center' style={{ height: "40px" }}>
          <input
            type="text"
            style={{ width: "350px" }}
            placeholder="Search by item name"
            value={searchQuery}
            onChange={handleSearchChange}
            className="form-control"
          />

          <button className='btn btn-primary ms-2' onClick={() => { setShowFilterPopup(true); handleResetFilters() }}>Filter</button>
          <button onClick={handleDownload} className="bg-white rounded m-3" style={{ border: "0.5px solid grey", width: "40px", height: "40px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>

          {showFilterPopup && (
            <div className="popup-container position-absolute rounded" style={{ width: "400px", background: "#edf4f5", padding: "10px", top: "26%" }}>
              <DropdownAndInput
                label="Stock Qty"
                items={['Greater Than', 'Equals', 'Less Than']}
                comparison={comparisonQty}
                setComparison={setComparisonQty}
                inputValue={inputValueQty}
                setInputValue={setInputValueQty}
              />
              <DropdownAndInput
                label="Stock Value"
                comparison={comparisonValue}
                items={['Greater Than', 'Equals', 'Less Than']}

                setComparison={setComparisonValue}
                inputValue={inputValueValue}
                setInputValue={setInputValueValue}
              />
              <DropdownAndInput
                label="Sales"
                comparison={comparisonSales}
                setComparison={setComparisonSales}
                items={['Greater Than', 'Equals', 'Less Than']}

                inputValue={inputValueSales}
                setInputValue={setInputValueSales}
              />
              <DropdownAndInput
                label="Purchases"
                comparison={comparisonPurchases}
                setComparison={setComparisonPurchases}
                items={['Greater Than', 'Equals', 'Less Than']}

                inputValue={inputValuePurchases}
                setInputValue={setInputValuePurchases}
              />
              <div className='d-flex justify-content-between align-items-center mt-2'>
                <button className="btn btn-secondary" onClick={handlePopupClose}>Cancel</button>
                <button className="btn btn-primary mr-2" onClick={handleResetFilters}>Reset</button>
                <button className="btn btn-primary mr-2" onClick={handleGo}>Go</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex w-100 align-items-center justify-content-start mb-2">
        {headerBtns.map((item, index) => (
          <SmallCard
            key={index}
            title={item.name}
            number={item.number}
            height="60px"
            width="250px"
            handleClick={handleClickForTopMenu}
            selected={selectedBtn === item.name}
            isborder="true"
          />
        ))}
      </div>
      {/* <div className="position-relative w-100" style={{ height: "50px" }}>
        <button onClick={handleDownload} className="bg-white rounded position-absolute m-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>
      </div> */}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {filteredDataForTable?.length ? <div>
        <NewTablev2 columns={columns} tableFixed={true} hideHeading={false} customStyles={{ rowBg: "#e7f9ff", allBorders: false }} showSideBorders={true}>
          {filteredDataForTable.map((item, index) => (
            <tr key={index} >
              <td>{item.itemName}</td>
              <td>{item.quantity}</td>
              <td>{item.stockValue}</td>
              <td>{item.sales}</td>
              <td>{item.purchases}</td>
            </tr>
          ))}
        </NewTablev2>
      </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <h3 className='text-warning'>No Data Found</h3>
      </div>}
    </div>
  );
}
