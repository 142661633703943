import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { inspectionTypeIds, traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import { setContractDetails, setContractState, setContractDocList } from '../../store/actions/action';


import { getSocket } from '../../socket'
import NewTablev2 from '../../utils/newTablev2';
import { formatDate_Application } from '../../utils/dateFormaters';
import { getStatusDisplayName } from '../dataTablesColumsMap/contractListColoums';
import { Action } from '../myCounterPartComp/action';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';

let statsType = [
  { name: 'Pending', color: '#FFAC1C' },
  { name: 'Cancelled', color: '#1B94B7' },
  { name: 'Lost', color: '#E74C3C' },
  { name: 'Won', color: '#48DA87' },
  { name: 'Draft', color: '#FFAC1C' }]

const SalesAndPurchaseTab = ({ userTokenDetails, cDetailsState, setContractDetails, contractState, setContractState, clientType, setContractDocList, navToggleState, dispatch }) => {


  const queryParams = new URLSearchParams(window.location.search)

  const [contractCount, setcontractCount] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [filterData, setFilterData] = useState({})
  const [statusFilter, setstatusFilter] = useState(0)
  const [contractLog, setcontractLog] = useState({ modal: false, contract_no: "" })
  const [showQuotRaiseModal, setshowQuotRaiseModal] = useState({})
  const [showInvestigationRemark, setshowInvestigationRemark] = useState({})
  const [showInsuranceRemark, setshowInsuranceRemark] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [contDeleteData, setcontDeleteData] = useState({});
  const [inspectionContractModal, setInspectionContractModal] = useState({ show: false, data: {} })
  const [inspectionWorkorderDetails, setInspectionWorkorderDetails] = useState({ show: false, data: {}, offer_data: {} })

  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [action, setAction] = useState({ show: false, index: null })
  const [filter, setFilter] = useState({ resultPerPage: 10, search: queryParams.get("search") || "" })
  const [page, setPage] = useState(1)
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [userPermissions, setUserPermissions] = useState(null);
  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);
  const [tagPopup, setTagPopup] = useState({})
  const [tagsData, setTagsData] = useState([])
  const [data, setData] = useState({ tagType: 'Quotation' })
  const [error, setError] = useState({})
  const [statsCount, setStatsCount] = useState({})


  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const parentId = userTokenDetails.parentId !== undefined ? userTokenDetails.parentId : null

  useEffect(() => {
    fetchTableData({ userId: userId, type_id: userTypeId })
    call('POST', 'getTags', { type: data.tagType }).then(res => {
      setTagsData([...res])
    })
  }, [refresh, clientType.type, contractState.info.refresh, filter, filterData])

  function fetchTableData(inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": filter.resultPerPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      // "activeType": clientType.type,
      "userParentId": parentId,
      "gridOnly": true,
      search: filter.search
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    objectAPI["fromDate"] = objectAPI?.["dateRangeFilter"]?.[0]
    objectAPI["toDate"] = objectAPI?.["dateRangeFilter"]?.[1]
    if (objectAPI?.status?.includes("In-Pilot")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("In-Progress")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false
      }
    }
    else if (objectAPI?.status?.includes("Completed")) {
      objectAPI = {
        ...objectAPI,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true
      }
    }
    call('POST', 'getSalesPurchaseQuotation', objectAPI).then(async (result) => {
      console.log('running getSalesPurchaseQuotation api-->', result);
      let drafts = []
      // let drafts = await call('POST', 'getAllInvoiceLimitDrafts', { userId, forContract: true })
      // drafts = drafts.length ? drafts : []
      drafts = drafts.concat(result.data || [])
      settableData(drafts)
      setStatsCount(result.countData)
      setcontractCount(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  }

  const handleNew = () => {

    if (booladd || userPermissions == null) {
      window.location = '/contractManagementAdd';
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  async function handleChange(e) {
    e.persist()
    setData({ ...data, [e.target.name]: e.target.value })
    setError({ ...error, [e.target.name]: "" })
  }

  function onTagPopupClose() {
    setTagPopup({ show: false })
    setrefresh(refresh + 1)
    setData({ ...data, tagName: undefined, selectTag: undefined })
  }

  return (
    <>
      {tagPopup.show && (
        <FinanceInvoiceModal
          limitinvoice={tagPopup.show}
          setLimitinvoice={() => { onTagPopupClose() }}
          closeSuccess={() => { onTagPopupClose() }}
        >
          <div className="col-md-10 mb-2 ml-5">
            <label className="text-center font-wt-600 font-size-16 mb-4 w-100">
              Select Tag
            </label>
            <div className='position-relative'>
              <MultipleSelect singleSelection
                Label={"Select Tag"}
                Id={`selectTag`}
                optiondata={tagsData}
                onChange={(e) => {
                  handleChange(multiSelectEventHandler(e, `selectTag`, "name"))
                }}
                value={data[`selectTag`] ? [data[`selectTag`]] : []}
                name={`selectTag`}
                labelKey={"name"}
                valKey={"name"}
                error={error[`selectTag`]}
              />
            </div>
            <label className="text-center font-wt-600 font-size-16 my-4 w-100">
              OR Create New
            </label>
            <div className='position-relative'>
              <MultipleSelect isDisabled singleSelection
                Label={"Tag Type"}
                Id={`tagType`}
                optiondata={[{ type: 'Quotation' }]}
                onChange={(e) => {
                  handleChange(multiSelectEventHandler(e, `tagType`, "type"))
                }}
                value={data[`tagType`] ? [data[`tagType`]] : []}
                name={`tagType`}
                labelKey={"type"}
                valKey={"type"}
                error={error[`tagType`]}
              />
            </div>
            <div className='position-relative'>
              <NewInput name={"tagName"} value={data.tagName} label={"Tag Name"} onChange={handleChange}
                error={error[`tagName`]} />
            </div>
            {tagPopup.data.details?.tags.length ? <>
              <label className="text-center font-wt-600 font-size-16 my-4 w-100">
                OR Remove Tag
              </label>
              <div className='d-flex row'>
                {tagPopup.data.details?.tags?.map((i, j) => {
                  return (<div
                    onClick={() => {
                      let tempTags = tagPopup.data.details?.tags
                      tempTags = tempTags.filter(tagName => {
                        if (tagName != i) { return true }
                      })
                      let updatedTagPopup = tagPopup
                      updatedTagPopup["data"]["details"]["tags"] = tempTags
                      setTagPopup({ ...updatedTagPopup })
                    }}
                    className='col-6 cursor'>
                    <label className='font-size-13 font-wt-500 cursor'>{i}
                      <img src='assets/images/cancel-icon.png' className='mx-2 cursor' />
                    </label>
                  </div>)
                })}
              </div></> : null}
            <div className='d-flex row justify-content-center mt-4'>
              <button
                className={`new-btn py-2 px-2 text-white cursor w-35`}
                onClick={async () => {
                  if (!(data.selectTag || (data.tagType && data.tagName))) {
                    // update tags
                    setLoading(true)
                    await call('POST', 'updateSalesPurchaseQuotation', {
                      sellerId: tagPopup.data.sellerId, buyerId: tagPopup.data.buyerId,
                      data: { ...tagPopup.data.details, tags: tagPopup.data.details?.tags },
                      appId: tagPopup.data.id
                    })
                    setLoading(false)
                    onTagPopupClose()
                  }
                  else {
                    setLoading(true)
                    let tagNameToSet = data.selectTag
                    // first save the tag
                    if (data.tagType && data.tagName) {
                      await call("POST", 'createNewTag', { type: data.tagType, name: data.tagName })
                      tagNameToSet = data.tagName
                    }
                    // update tag to transaction
                    let existingTags = tagPopup.data.details.tags || []
                    existingTags = existingTags.filter(i => {
                      if (tagNameToSet != i) { return true }
                    })

                    existingTags.push(tagNameToSet)
                    await call('POST', 'updateSalesPurchaseQuotation', {
                      sellerId: tagPopup.data.sellerId, buyerId: tagPopup.data.buyerId,
                      data: { ...tagPopup.data.details, tags: existingTags },
                      appId: tagPopup.data.id
                    })
                    setLoading(false)
                    onTagPopupClose()
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </FinanceInvoiceModal>
      )}
      {loading && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className='d-flex row m-0 p-0 mt-3' style={{ gap: '2rem' }}>
        {statsType.map((item, j) => {
          return <div
            onClick={() => {
              // let tempFilterData = filterData
              // let doesFilterActivated = false
              // for (let index = 0; index < tempFilterData["Status"]["data"].length; index++) {
              //   let element = tempFilterData["Status"]["data"][index];
              //   if (element.name === "In-Pilot") {
              //     element["isChecked"] = !element["isChecked"]
              //     if (element["isChecked"]) {
              //       doesFilterActivated = true
              //     }
              //   }
              //   else {
              //     element["isChecked"] = false
              //   }
              // }
              // tempFilterData["Status"] = { ...tempFilterData["Status"], isFilterActive: doesFilterActivated }
              // setFilterData({ ...filterData })
            }}
            className='card border1Blue d-flex justify-content-center cursor' style={{ width: '12.44%' }} >
            <div>
              <p className='font-size-22 font-wt-600  p-0 m-0' style={{ 'color': item.color }} >{statsCount?.[item.name] || 0}</p>
              <p className='font-size-16 font-wt-400 colorFFC107 p-0 m-0 mt-3 mb-4'>{item.name}</p>
            </div>
          </div>
        })}
      </div>

      <div className='filter-div position-relative'>

        <Filter
          filterData={filterData}
          setFilterData={setFilterData}
          showFilterBtn={true}
          showResultPerPage={true}
          count={contractCount}
          filter={filter}
          setFilter={setFilter}
          refresh={refresh}
          setRefresh={setrefresh}
          isAdditionalButton
        >
          {userTokenDetails.main_user_name !== "Admin FOB" && (
            <div className="d-flex gap-4">
              <button
                className={`new-btn py-2 px-2 text-white cursor`}
                // onClick={() => {
                //   window.location = '/contractManagementAdd';

                // }}
                onClick={handleNew}
              >
                Create Shipment Quotation
              </button>
            </div>
          )}
        </Filter>


      </div>
      <div>
        <NewTablev2
          columns={[{ subColumns: "Quotation No" },
          { subColumns: "Company" },
          {
            subColumns: "Enquiry No", filterDataKey: "Buyer Name",

          },
          { subColumns: "Total Amount", },
          { subColumns: "Deal Status", },
          { subColumns: "Deal Owner", },
          { subColumns: "Next Action Date", },
          { subColumns: "Tags", },
          { subColumns: "Creation Date", },
          { subColumns: "Action", }
          ]}
        >
          {tableData.map((item, i) => {
            return (
              <tr>
                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.documentNo || "-"}</label></td>
                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.buyerName || "-"}</label></td>
                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.enquiryNo || "-"}</label></td>
                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.totalAmount + " " + item.details?.totalAmountCurrency}</label></td>
                <td><div>
                  {item.status / 1 == 0 || item.status / 1 == 5 ?
                    <button type="button"
                      class={`inprogress text-white border-0 `}>
                      {item.status / 1 == 5 ? "Draft" : "Pending"}
                    </button> : item.status / 1 == 1 ? <button type="button"
                      class={`expiredStatus text-white border-0 `}>
                      {"Cancelled"}
                    </button> : item.status / 1 == 2 ? <button type="button"
                      class={`rejected text-white border-0 `}>
                      {"Lost"}
                    </button> : item.status / 1 == 3 ? <button type="button"
                      class={`approved text-white border-0 `}>
                      {"Won"}
                    </button> : null}</div></td>
                <td><label className='font-size-13 font-wt-400 text-break' >{item.details.supplierName || "-"}</label></td>
                <td><label className='font-size-13 font-wt-400 text-break' >{item.details.nextActionDate ? moment(item.details.nextActionDate).format('YYYY-MM-DD') : "-"}</label></td>
                <td><label className='font-size-13 font-wt-500 text-break text-color1' >{item.details.tags ? item.details.tags.join(", ") : "-"}</label></td>
                <td><label className='font-size-13 font-wt-400 text-break' >{moment(item.createdAt).format('YYYY-MM-DD')}</label></td>
                <td
                  className='position-relative'>
                  <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                    onClick={() => {
                      setAction({ show: true, index: i })
                    }}
                    aria-hidden="true"></i>
                  {action.show && action.index === i ? (
                    <Action
                      id={i}
                      onDismiss={() => setAction({ show: false, index: i })}
                      options={[{
                        name: "View/Edit", onClick: () => {
                          // window.location = `/contractManagementAdd?contractNo=${encodeURIComponent(tempObj.actions.contract_number)}&type=edit`
                        }
                      },
                      {
                        name: 'Manage Tag', onClick: () => {
                          setTagPopup({ show: true, data: item })
                        }
                      }
                      ]} />
                  ) : null}
                </td>
              </tr>
            )
          })}
        </NewTablev2>
        <Pagination page={page} totalCount={contractCount} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 0} />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setContractDocList: (id, data) => { dispatch(setContractDocList({ modal: true, contractId: id, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesAndPurchaseTab)