import React, { useRef, useState } from 'react'
import BottomPopup from './BottomPopup'
import MultipleSelect from '../../utils/MultipleSelect'
import call from '../../service'
import { NewInput, NewTextArea } from '../../utils/newInput'
import { getDocDetails } from '../../utils/myFunctions'
import toastDisplay from '../../utils/toastNotification'
import { ToastContainer } from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';


var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'color': [] }],
  [{ 'font': [] }],
  [{ 'align': [] }]
];

const SendEmailPopup = ({ emailPopup, toggleemailPopup, CurrentEmailIds, userId, setCurrentOverallEmailIds, setCurrentEmailIds, CurrentOverallEmailIds, type, EXPORTER_CODE, EXPORTER_NAME, userName, successHandler }) => {
  const [showMailTemplates, setshowMailTemplates] = useState({
    show: false,
    templates: []
  })
  const [selectedTemplate, setselectedTemplate] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [data, setdata] = useState({})
  const [errors, setErrors] = useState({})
  const [isMinimized, setISMinimized] = useState(false)
  const [showLoader, setshowLoader] = useState(false)
  const pinFile = useRef(null)
  const imgFile = useRef(null)


  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setdata({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      if (e?.[0]?.id === "temp") {
        let allcontactdata = CurrentOverallEmailIds
        allcontactdata.push({ ...e[0], [val]: e[0]["typedInput"], id: e[0]["typedInput"] })
        setCurrentOverallEmailIds(allcontactdata)
        setCurrentEmailIds(allcontactdata)
        setdata({
          ...data,
          [name]: data[name] ? data[name].concat([e[0]["typedInput"]]) : [e[0]["typedInput"]]
        })
      } else {
        setdata({
          ...data,
          [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
        });
      }
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Add the selected files to the state
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const removeFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);

    // Update the state to remove the selected file
    setSelectedFiles(newFiles);
  };

  const getEmailTemplates = () => {
    setshowLoader(true)
    let reqBody = {
      adminId: userId
    }
    call('POST', 'getEmailTemplates', reqBody).then(result => {
      setshowLoader(false)
      setshowMailTemplates({ show: true, templates: result })
    }).catch(e => {
      toastDisplay(e, 'error')
      setshowLoader(false)
    })
  }
  const sendEmail = () => {
    setshowLoader(true)
    let reqformdata = new FormData()
    reqformdata.append('emailIds', JSON.stringify(data.emailto))
    reqformdata.append('subject', data.subject)
    reqformdata.append('mailBody', data.mailbody)
    reqformdata.append('userId', userId)
    reqformdata.append('type', type)
    reqformdata.append('EXPORTER_CODE', EXPORTER_CODE)
    reqformdata.append('EXPORTER_NAME', EXPORTER_NAME)
    reqformdata.append('userName', userName)

    for (let i = 0; i <= selectedFiles.length - 1; i++) {
      reqformdata.append(`attachment_${i + 1}`, selectedFiles[i])
    }
    call('POST', 'sendEmailFromCRM', reqformdata).then(result => {
      toastDisplay(result, 'success')
      toggleemailPopup({
        show: false,
        data: null,
        selectedIndex: null
      })
      setshowLoader(false)
      if (successHandler) {
        successHandler()
      }
    }).catch(e => {
      toastDisplay(e, 'error')
      setshowLoader(false)

    })
  }
  const addEmailTemplates = () => {
    setshowLoader(true)
    let reqformdata = new FormData()
    reqformdata.append('subject', data.subject)
    reqformdata.append('mailBody', data.mailbody)
    reqformdata.append('userId', userId)
    reqformdata.append('adminId', userId)
    for (let i = 0; i <= selectedFiles.length - 1; i++) {
      reqformdata.append(`attachment_${i + 1}`, selectedFiles[i])
    }
    call('POST', 'addEmailTemplates', reqformdata).then(result => {
      toastDisplay(result, 'success')
      setshowLoader(false)
      setdata({
        ...data,
        subject: '',
        mailbody: '',
        emailto: []
      })
    }).catch(e => {
      toastDisplay(e, 'error')
      setshowLoader(false)

    })
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let tempEmailId = []
    let filtered = []
    tempEmailId = [{ id: "temp", [name]: "Add New Option", typedInput }]
    filtered = CurrentOverallEmailIds.filter((i) => {
      if (i[name] && i[name].toLowerCase().includes(typedInput.toLowerCase())) {
        return i
      }
    })
    if (!filtered.length) {
      filtered = tempEmailId
    }
    setCurrentEmailIds(filtered)
  };
  const handleChange = async (event) => {

    if (event.persist) {
      event.persist()
    }
    setdata({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })

  }
  return (
    <div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className={`bottom-popup ${emailPopup.show ? "open" : ""} bottomPopup`}>
        <div className="popup-content">
          <>
            <div className='d-flex flex-row align-items-center  justify-content-between my-3'>
              <label className='text-left font-size-14 font-wt-500'>{showMailTemplates.show ? 'Saved Templates' : `Drafting mail for ${emailPopup.emailFor} `}</label>
              <div className='d-flex gap-4 flex-row'>
                <img src='assets/images/arrow.png' className='cursor' onClick={() => setISMinimized(!isMinimized)} style={isMinimized ? { transform: "rotate(180deg)" } : {}} />
                <img src='assets/images/cross.png' className='cursor' onClick={() => {
                  toggleemailPopup({
                    show: false,
                    data: null
                  })
                  setdata({
                    ...data,
                    subject: '',
                    mailbody: '',
                    emailto: []
                  })
                }} />
              </div>

            </div>
            {showMailTemplates.show ?
              <div className='col-md-12 px-0'>
                <div className=''>
                  {showMailTemplates.templates.map((item, index) => {
                    return <div className='d-flex flex-row px-2 mb-3' onClick={() => {
                      setselectedTemplate(index)
                    }}>
                      <img src={selectedTemplate === index ? 'assets/images/radio-Select.svg' : 'assets/images/radio-NonSelect.svg'} className='mr-1' />
                      <label className="form-check-label font-size-14 p-0 m-0" >
                        {item.email_subject}
                      </label>
                    </div>

                  })}
                  <div className='mt-3 d-flex gap-2 col-md-10 pl-0'>
                    <button className={`new-btn w-40 py-2 px-2 text-white cursor`} onClick={async () => {
                      const item = showMailTemplates.templates[selectedTemplate]
                      setshowMailTemplates({
                        show: false,
                        templates: []
                      })
                      setdata({
                        ...data,
                        subject: item.email_subject,
                        mailbody: item.email_body
                      })
                      const docsplited = item?.docs?.split(",") || []
                      let selfiles = []
                      for (let i = 0; i <= docsplited.length - 1; i++) {
                        const docs = await getDocDetails(parseInt(docsplited[i]))
                        selfiles.push(docs)
                      }
                      setSelectedFiles(selfiles)

                    }}>Continue</button>
                    <button className={`new-btn2 w-60 py-2 px-2 text-color1 cursor`} onClick={() => {
                      setshowMailTemplates({
                        show: false,
                        templates: []
                      })
                      setdata({
                        ...data,
                        subject: '',
                        mailbody: '',
                        emailto: []
                      })
                    }}>Create New</button>
                  </div>
                </div>
              </div>
              :
              <div className='col-md-12 px-0'>
                <div className=''>
                  <div className="col-md-12 p-0">
                    <div className="col-md-12 px-0">
                      <MultipleSelect
                        Id="Email Ids"
                        Label="Email Ids"
                        selectedvalue="EmailIds"
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "Email ID")
                        }}
                        optiondata={CurrentEmailIds}
                        onChange={(e) => handleMultiSelectchange(e, "emailto", "Email ID", false)}
                        value={data.emailto ? data.emailto : []}
                        name="emailto"
                        labelKey={"Email ID"}
                        valKey={"Email ID"}
                        customStyles={{
                          backgroundColor: '#DEF7FF',
                          borderRadius: '10px',
                          borderWidth: '0px'
                        }}
                      />
                    </div>
                    <div className="col-md-12 px-0">
                      <NewInput isAstrix={true} type={"text"} label={"Subject"}
                        name={"subject"}
                        value={data.subject} error={errors.subject}
                        onChange={handleChange} customclass={"bottomInput border0 "}
                      />
                    </div>
                    <div className="col-md-12 px-0" style={{ height: 250 }}>
                      <ReactQuill style={{ height: '70%' }} theme="snow" value={data.mailbody} onChange={(val) => {

                        setdata({ ...data, mailbody: val })
                      }} modules={{ toolbar: toolbarOptions }} />
                      {/* <NewTextArea
                      rows={8}
                      type={"text"} label={`Body Of Mail`} name={"mailbody"}
                      value={data.mailbody} error={errors.mailbody}
                      onChange={handleChange} customclass={"bottomInput border0 "}
                    /> */}
                    </div>
                    <div>
                      <label className='text-color1 font-size-13 font-wt-600 cursor' onClick={getEmailTemplates}>Add from templates</label>
                    </div>
                    <div className='col-md-12 px-0'>
                      {selectedFiles.map((file, index) => (
                        <div key={index} className='mailattachments'>
                          <span>{file.name}</span>
                          <img src='assets/images/cross.png' className='cursor' onClick={() => removeFile(index)} />
                        </div>
                      ))}
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                      <div className='d-flex gap-2 pl-0'>
                        <button className={`new-btn  py-2 px-4 text-white cursor`} onClick={sendEmail}>Send</button>
                        <button className={`new-btn2  py-2 px-2 text-color1 cursor`} onClick={addEmailTemplates}>Save as template</button>
                      </div>
                      <div className='d-flex flex-row gap-2'>
                        <img onClick={(e) => pinFile.current.click()} src='assets/images/pin_icon.png' className='cursor' height={20} width={20} />
                        <input type='file' id='pinFile' ref={pinFile} name="chatDoc" style={{ display: 'none' }} multiple onChange={(e) => {
                          handleFileChange(e)
                        }} />
                        <img src='assets/images/link_icon.png' className='cursor' height={20} width={20} />
                        <img onClick={(e) => imgFile.current.click()} src='assets/images/img_icon.png' className='cursor' height={20} width={20} />
                        <input type='file' id='imgFile' ref={imgFile} name="chatDoc" style={{ display: 'none' }} accept="image/*" multiple onChange={(e) => {
                          handleFileChange(e)
                        }} />
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            }

          </>
        </div>
      </div>
    </div>
  )
}

export default SendEmailPopup
