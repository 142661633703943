import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../../../utils/newInput';
import { PopupMessage } from '../../../popupMessage';
import { CustomProgressBar } from "../../../myCounterPartComp/checkBuyerHealth";
import { toast, ToastContainer } from "react-toastify";
import { ClearCache, convertImageToPdf, GetCache, modifi_currencies, most_used_currencies, SetCache, getDocDetails } from '../../../../utils/myFunctions';
import Switch from "react-switch";
import SignDocument from "../../../InvoiceDiscounting/components/SignDocument";
import { useHistory } from "react-router";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const quotationTabs = [
  { name: "LC Details" },
  { name: "Exim Bank Charges" },
  { name: "Documents" },
]

const otherDetails = [
  { "name": "No. of finance applied: ", val: "totalInvApplied", unit: "" },
  { "name": "No. of finance approved: ", val: "totalInvApproved", unit: "" },
  { "name": "Total shipment done: ", val: "totalShipmentsDone", unit: "" }
]

const generalDetails = [
  { "name": "Name: ", val: 'company_name', unit: '' },
  { "name": "Organization Type: ", val: 'organization_type', unit: '' },
  { "name": "Country: ", val: 'country', unit: '' },
  { "name": "Industry Type: ", val: 'industry_type', unit: '' }
]

const SignEximBankTermSheet = ({ userTokenDetails, navToggleState }) => {

  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  let applicationDetails = JSON.parse(localStorage.getItem("applicationDetails"))
  let onlyView = applicationDetails.onlyView ? true : false

  const quoteId = applicationDetails.id
  const supplierId = applicationDetails.createdBy

  const [tab, setTab] = useState(1)
  const [lcCodes, setLCCodes] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [signdoc, setSigndoc] = useState(false);
  const [optionsPopup, toggleOptionsPopup] = useState(false)

  const [data, setData] = useState({
    giveConfirmationFees: true, giveDiscountingRate: true, setupFeesCurrency: "USD", assignValueCurrency: "USD",
    termSofr: "3 Months", till_maturity: true
  })
  const [errors, setErrors] = useState({})
  const [lcMoreDetails, setLCMoreDetails] = useState(false);
  const [userFinData, setUserFinData] = useState([])
  const [userData, setUserData] = useState({})
  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [lcMoreDocuments, setLCMoreDocuments] = useState({ modal: false, page: 0 });
  const [lcRequestMoreDetails, setLCRequestMoreDetails] = useState({ modal: false, page: 0 });
  const [additionalDetailsReq, setAdditionalDetailsReq] = useState(false);
  const [saveContinue, setSaveContinue] = useState(false);
  const [ammendFields, setAmmendFields] = useState({})
  const [ammendPopup, toggleAmmendPopup] = useState({ show: false, key: null })
  const [addMore, setAddMore] = useState(false);
  const [showComment, toggleComment] = useState(null)
  const [showDocComment, toggleDocComment] = useState(null)
  const [isAllFinancierRejected, setIsAllFinancierRejected] = useState(false)

  const [quoteForm, setQuoteForm] = useState({ modal: false, page: 0 });
  const [denyForm, setDenyForm] = useState({ modal: false, page: 0 });


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const currency = most_used_currencies

  const reviewForm = [
    { "name": "Buyer Name", val: "buyerName" },
    { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
    { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
    { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

    { "name": "Email Id", val: "buyerEmail" },
    { "name": "Inco Terms", val: "incoTerms" },
    { "name": "Website", val: "buyerWebsite" },
    { "name": "Terms of Payment", val: "termsOfPayment" },

    { "name": "Address", val: "buyerAddress" },
    { "name": "Product Details", val: "productDetails" },

    { "name": "DUNS No", val: "buyerDUNSNo" },
    { "name": "HSN Code", val: "buyerHsnCode" },

  ]

  const reviewForm3 = [
    { "name": "LC type", val: "lcType" },
    { "name": "LC number", val: "lcNo" },
    { "name": "LC tenor", val: "lcTenor" },
    { "name": "Shipment from country", val: "shipmentFromCountry" },
    { "name": "Shipment to country", val: "shipmentToCountry" },
    { "name": "Port of loading", val: "portOfLoading" },
    { "name": "Port of discharge", val: "portOfDischarge" },
    { "name": "Commodity", val: "commodity" },
    { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
    { "name": "Beneficiary bank name", val: "beneficiaryBankName", notAmmendable: true },
    { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo", notAmmendable: true },
    { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode", notAmmendable: true },
    { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode", notAmmendable: true },
    { "name": "LC issuing bank name", val: "lcIssuingBankName", notAmmendable: true },
    { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
    { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
    { "name": "Country of origin", val: "countryOfOrigin" },
    // { "name": "LC Purpose", val: "lcPurpose" },
    { "name": "Confirming bank name", val: "confirmingBankName" },
    { "name": "Confirming bank address", val: "confirmingBankAddress" },
    { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" }
  ]

  const supplierForm = [
    { "name": "Financial Year: ", val: "year", unit: "" },
    { "name": "Turn Over: ", val: "turnover", unit: "finCurrency" },
    { "name": "Net Profit: ", val: "netprofit", unit: "finCurrency" },
    { "name": "Net Worth: ", val: "networth", unit: "finCurrency" }
  ]

  const ProgressBars = [
    { name: "Portpolio comparision score", min: 1, max: 9, key: "portfolio_comparison_score" },
    { name: "Failure score", min: 1, max: 100, key: "failure_score" },
    { name: "Delinqency score", min: 1, max: 100, key: "delinquency_score" },
    { name: "Paydex score", min: 1, max: 100, key: "paydex_scrore", reverse: true }
  ]

  const fieldTypes = [
    { "name": "Currency", "label": "$", "icon": "" },
    { "name": "Percentage", "label": "%", "icon": "" },
    { "name": "Text", "icon": "" },
    { "name": "Date", "icon": "calender" }
  ]

  async function fetchAllDocs(appendData) {
    setshowLoader(true)
    let invoiceDocument = await getDocDetails(applicationDetails.buyersDocId ? applicationDetails.buyersDocId.split(":")[0] : null)
    let poDocument = await getDocDetails(applicationDetails.buyersDocId ? applicationDetails.buyersDocId.split(":")[1] : null)
    let draftLCDocument = await getDocDetails(applicationDetails.draftLC)
    let termSheetDocument = await getDocDetails(applicationDetails.eximBankTermSheet)
    let buyerOtherDocIdArr = applicationDetails.buyerOtherDocs ? applicationDetails.buyerOtherDocs.split(":") : []
    let otherDocObj = {}
    let moreDocArr = addMoreDoc
    for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
      otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
      moreDocArr.push(null)
    }
    let { financierQuotes } = applicationDetails
    let providedQuote = {}
    if (financierQuotes) {
      financierQuotes = JSON.parse(financierQuotes).forEach(i => {
        if (i.lender_id / 1 == userId / 1) {
          providedQuote = i
          providedQuote["till_maturity"] = i.confirmationFeesValidity == "till_maturity"
        }
      })
    }

    // Get amendment data start
    let amendmentData = {}
    let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, userTypeId, userId })
    if (amendmentDataRes && amendmentDataRes.length) {
      setAmmendFields(amendmentDataRes[0]["details"])
      amendmentData["additionalDetails"] = amendmentDataRes[0]["lcAdditionalDetails"]
      amendmentData["additionalDocuments"] = amendmentDataRes[0]["additionalDocuments"]
      amendmentData["amendmentId"] = amendmentDataRes[0]['id']
    }
    // Get amendment data end

    setData({ ...data, ...amendmentData, ...providedQuote, ...appendData, invoiceDocument, poDocument, draftLCDocument, termSheetDocument, ...otherDocObj })
    setAddMoreDoc(moreDocArr)
    setshowLoader(false)
  }

  async function asyncUseEffectFunction() {

    // Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    // Get master data end

    call('POST', 'isAllFinanciersRejectedQuote', {
      applicationId: quoteId
    }).then((result) => {
      console.log('running isAllFinanciersRejectedQuote api-->', result);
      setIsAllFinancierRejected(result.status)
    }).catch((e) => {
      // console.log('error in isAllFinanciersRejectedQuote', e);
    });

    setshowLoader(true)
    call('POST', 'getuserfinancegrid', {
      "currentPage": 1,
      "manualUserId": supplierId,
      "resultPerPage": 10,
      userTypeId
    }).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setshowLoader(false)
      setUserFinData(result.finData)
    }).catch((e) => {
      // console.log('error in getuserfinancegrid', e);
      setshowLoader(false)
    });

    call('POST', 'getGeneralDetailsOfExporter', {
      "userId": supplierId
    }).then((result) => {
      console.log('running getGeneralDetailsOfExporter api-->', result);
      setshowLoader(false)
      setUserData(result)
    }).catch((e) => {
      // console.log('error in getGeneralDetailsOfExporter', e);
      setshowLoader(false)
    });

    onCheckBuyerHealthClick(applicationDetails.buyerDUNSNo)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [])

  function onCheckBuyerHealthClick(duns) {
    setshowLoader(true)
    call('POST', 'getBuyerHealth', { duns }).then((result) => {
      setshowLoader(false)
      // console.log("inside getBuyerHealth then", result);
      fetchAllDocs({ ...result, ...applicationDetails })
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
      fetchAllDocs(applicationDetails)
      // toastDisplay(e, "error")
    });
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else {
        return setTab(tabIndex)
      }
    }
    let err = {}
    if (tab == 2) {
      if (!(data.termSheetDocument && data.termSheetDocument.signatureId)) {
        err["termSheetDocument"] = "Signing term sheet document is mandatory"
      }
      if (data.draftLCDocument && !data.draftLCDocument.signatureId) {
        err["draftLCDocument"] = "Signing draft LC document is mandatory"
      }
    }
    if (!Object.keys(err).length) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("userId", userId)
      formData.append("termSheetId", applicationDetails.eximBankTermSheet)
      formData.append("draftLCId", applicationDetails.draftLC)
      formData.append("termSheetDocument", data.termSheetDocument)
      formData.append("draftLCDocument", data.draftLCDocument)
      formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)
      formData.append("draftLCDocSignId", data.draftLCDocument.signatureId)
      // formData.append("sblcApplicationId", applicationDetails.sblcApplicationId)
      formData.append("showChargesInQuote", data.showChargesInQuote)
      formData.append("applicationId", applicationDetails.id)
      formData.append("lcNo", applicationDetails.lcNo)
      formData.append("userName", userName)
      call('POST', 'revertEximBankQuote', formData).then((result) => {
        console.log('running revertEximBankQuote api-->', result);
        setshowLoader(false)
        toastDisplay(result, "success", () => {
          history.go(-1)
          history.go(-1)
        })
      }).catch((e) => {
        // console.log('error in revertEximBankQuote', e);
        setshowLoader(false)
        toastDisplay(e, "error")
      });
    }
    setErrors(err)
  }

  async function declineEximBankQuote() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("userId", userId)
    formData.append("applicationId", applicationDetails.id)
    formData.append("lcNo", applicationDetails.lcNo)
    formData.append("userName", userName)
    formData.append("isRejected", true)
    call('POST', 'revertEximBankQuote', formData).then((result) => {
      console.log('running revertEximBankQuote api-->', result);
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      // console.log('error in revertEximBankQuote', e);
      setshowLoader(false)
      toastDisplay(e, "error")
    });
  }

  async function submitQuote(quotation) {
    setshowLoader(true)
    let reqObj = {
      "quote": quotation,
      userId,
      quoteId,
      userName,
      amendmentId: data.amendmentId
    }
    if (Object.keys(ammendFields).length) {
      reqObj["ammendFields"] = ammendFields
    }
    if (data.additionalDetails && data.additionalDetails.length) {
      reqObj["lcAdditionalDetails"] = data.additionalDetails
    }
    if (data.additionalDocuments && data.additionalDocuments.length) {
      reqObj["additionalDocuments"] = data.additionalDocuments
    }
    call('POST', 'submitLCQuote', reqObj).then((result) => {
      console.log('running submitLCQuote api-->', result);
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        window.location = "/finLCQuotes"
      })
    }).catch((e) => {
      // console.log('error in submitLCQuote', e);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    });
  }

  async function validateQuote(action) {

    if (action && action === "denied") {
      let reqBody = {
        // assignValue: "",
        assignRemark: data.assignRemark,
        lender_id: userId,
        lender_name: userName,
        status: "denied",
        assignDate: new Date()
      }
      return submitQuote(reqBody)
    }

    let validateFields = ["setupFees", "offerValidity", "discountingPercentage"]
    let err = {}
    validateFields.forEach((item, index) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })
    if (!Object.keys(err).length) {
      let reqBody = {
        confirmationFees: data.confirmationFees,
        discountingRate: data.discountingRate,
        giveConfirmationFees: data.giveConfirmationFees,
        giveDiscountingRate: data.giveDiscountingRate,
        setupFeesCurrency: data.setupFeesCurrency,
        setupFees: data.setupFees,
        offerValidity: data["offerValidity"],
        // assignValueCurrency: data.assignValueCurrency,
        // assignValue: data.assignValue,
        discountingPercentage: data.discountingPercentage,
        assignRemark: data.assignRemark,
        otherCharges: data.otherCharges || [],
        lender_id: userId,
        lender_name: userName,
        status: "approved",
        assignDate: new Date(),
        confirmationFeesValidity: data.till_maturity ? "till_maturity" : "till_expiry",
        termSofr: data.termSofr
      }
      submitQuote(reqBody)
    }
    setErrors(err)
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function appendOtherCharge() {
    let otherCharges = data.otherCharges || []
    let temp = {
      "name": data.otherChargeTitle,
      "unit": data.otherChargeCurrency || "%",
      "amount": data.otherChargeCurrency ? data.otherChargesAmt : data.otherChargePercentage
    }
    otherCharges.push(temp)
    setData({
      ...data, otherChargeCurrency: "", otherChargePercentage: "", otherChargeTitle: "",
      otherChargesAmt: "", otherCharges
    })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="viewDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC - Quotes"}
              userTokenDetails={userTokenDetails} />
            {showMsgPopup ? (
              <PopupMessage
                onClose={() => { toggleMsgPopup(false); window.location = "/finLCQuotes" }}
                title={''}
                msg={`Finance limit for Invoice discounting denied successfully !`}
              />
            ) : null}
            <a className="cursor" href="/finLCQuotes"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {quotationTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={quotationTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                <p
                  style={{
                    "right": "5%",
                    "fontSize": "3rem",
                    "top": "0rem"
                  }}
                  onClick={() => toggleOptionsPopup(!optionsPopup)}
                  className="position-absolute totalChargesLabel text-right text-color-label">{"..."}</p>
                {optionsPopup ? (
                  <div className="denyConfirmationPopup">
                    <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                      <label
                        onClick={declineEximBankQuote}
                        className="text-color-label">{"Reject Application"}</label>
                    </div>
                  </div>
                ) : null}

                {tab == 0 ? (
                  <div className='px-5 py-4 pt-5 '>
                    {!lcMoreDetails ? (
                      <div className="row">

                        <div className='w-100'>

                          <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                          <div className='row'>
                            {reviewForm3.map((item) => {
                              return (
                                <div className="col-md-6">
                                  <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              )
                            })}
                          </div>
                          <p className="mt-3 text-blue cursor" onClick={() => setLCMoreDetails(true)}>View more LC details
                            <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                          </p>
                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                          <div className='row mt-2'>

                            <div className="col-md-4">
                              <label className="font-size-14">Draft LC</label>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div> */}

                            {/* <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div> */}

                            {/* {addMoreDoc.length ? addMoreDoc.map((item, index) => {
                            return (
                              <div className="col-md-4">
                                <label className="font-size-14">Add More</label>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <FileInput name={"otherDoc" + index} value={data["otherDoc" + index]}
                                      isEditable={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null} */}

                          </div>
                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Bank</u></label>
                          <div className="d-flex">
                            <div className="mb-3 col-2">
                              {/* <label className="font11">{"Exim Bank"}</label> */}
                              <p
                                className="browse-div font-size-13 cursor text-center m-0 col-10 border-finance text-dark" >
                                {"Exim Bank"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}


                    <div className="">
                      {lcMoreDetails ? <>
                        <img className="mb-2 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} />
                        <div className='py-4 pt-4 '>
                          <div className="row">
                            <div className='w-100'>
                              <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                              <div className='row'>
                                {reviewForm3.map((item) => {
                                  return (
                                    <div className='col-md-4'>
                                      <NewInput type={"text"} isDisabled={true} label={item.name}
                                        value={data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>

                            <div className='w-100 mt-5'>
                              <label className='font-wt-500 font-size-16 mb-4'><u>More LC Details</u></label>
                              <div className='row'>
                                {lcCodes && Object.keys(lcCodes).map((item) => {
                                  return (
                                    <div className='col-md-4'>
                                      <NewTextArea isDisabled={true} label={item + "-" + lcCodes[item]}
                                        value={data["ocrFields"][item] || " "}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </> : null}
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (<>
                  <div className='px-5 py-5'>
                    <div className="eximBankQuoteCard px-5 py-4 w-35">
                      <label className="text-color1 font-size-18 font-wt-600">Price Details</label>
                      <div className="my-3">
                        <img src={"assets/images/eximBankLogo.png"} height={30} width={200} />
                      </div>
                      <div>
                        <div className="d-flex justify-content-between py-2">
                          <label className="text-color-label font-size-16 font-wt-500 ">Confirmation Fees </label>
                          <label className="text-color-value font-size-16 font-wt-500 text-end">{applicationDetails.sblcQuoteDetails.confirmationFees + " %"} </label>
                        </div>
                        <div className="d-flex justify-content-between py-2">
                          <label className="text-color-label font-size-16 font-wt-500 ">Confirmation Fees Validity</label>
                          <label className="text-color-value font-size-16 font-wt-500 text-end">
                            {applicationDetails.sblcQuoteDetails.confirmationFeesValidity.toUpperCase().split("_").join(" ")} </label>
                        </div>
                        {applicationDetails.sblcQuoteDetails.otherCharges.length ?
                          applicationDetails.sblcQuoteDetails.otherCharges.map((item) => {
                            return (
                              <div className="d-flex justify-content-between py-2">
                                <label className="text-color-label font-size-16 font-wt-500 ">{item.name}</label>
                                <label className="text-color-value font-size-16 font-wt-500 text-end">{item.amount + " " + item.unit} </label>
                              </div>
                            )
                          }) : null}
                        <div className="d-flex justify-content-between py-2">
                          <label className="text-color1 font-size-16 font-wt-600">Show charges in quote</label>
                          <Switch height={25} width={45}
                            onChange={() => setData({ ...data, showChargesInQuote: !data.showChargesInQuote })}
                            checked={data.showChargesInQuote} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                ) : null}

                {tab === 2 && lcMoreDetails ? <>
                  <img className="mb-3 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} />
                  <div className='px-5 py-4 pt-5 '>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                        {/* <div className='row'>
                          {reviewForm3.map((item) => {
                            return (
                              <div className='col-5 p-0'>
                                <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                <label className='lh-18 font-size-14'>:</label>
                                <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                  {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                </label>
                              </div>
                            )
                          })}
                        </div> */}
                        <div className='row'>
                          {reviewForm3.map((item) => {
                            let isFieldChanged = false
                            // let isFieldChanged = afterAmendmentFiels[item] && afterAmendmentFiels[item] != beforeAmendmentFiels[item] ? true : false
                            return (
                              <div className='col-md-4'>
                                <NewInput type={"text"} isDisabled={true} label={item.name}
                                  value={data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                />
                                {item.notAmmendable ? null : (
                                  <div
                                    onClick={() => toggleAmmendPopup({ show: true, key: item.val })}
                                    className="cursor ammendCommentIcon"
                                  >
                                    <img className="genericImgIcon" src={ammendFields[item.val] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                  </div>
                                )}
                                {ammendPopup && ammendPopup.show && ammendPopup.key === item.val ? (
                                  <div className="ammendPopup">
                                    <button
                                      type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                    <div className="text-center">
                                      <NewTextArea
                                        rows={6}
                                        type={"text"} label={`Comment`} name={ammendPopup.key}
                                        value={ammendFields[ammendPopup.key]}
                                        onChange={(e) => {
                                          setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16 mb-4'><u>More LC Details</u></label>
                        <div className='row'>
                          {lcCodes && Object.keys(lcCodes).map((item) => {
                            let isFieldChanged = false
                            // let isFieldChanged = afterAmendmentFiels[item] && afterAmendmentFiels[item] != beforeAmendmentFiels[item] ? true : false
                            return (
                              <div className='col-md-4'>
                                <NewTextArea isDisabled={true} label={item + "-" + lcCodes[item]}
                                  value={data["ocrFields"][item] || " "}
                                />
                                <div
                                  onClick={() => toggleAmmendPopup({ show: true, key: item })}
                                  className="cursor ammendCommentIcon"
                                >
                                  <img className="genericImgIcon" src={ammendFields[item] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                </div>
                                {ammendPopup && ammendPopup.show && ammendPopup.key === item ? (
                                  <div className="ammendPopup">
                                    <button
                                      type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                    <div className="text-center">
                                      <NewTextArea
                                        rows={6}
                                        type={"text"} label={`Comment`} name={ammendPopup.key}
                                        value={ammendFields[ammendPopup.key]}
                                        onChange={(e) => {
                                          setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                        }}
                                      />
                                      {/* <button className={`mx-2 new-btn w-25 py-2 px-2 text-white`} onClick={() => toggleAmmendPopup({ show: false, key: null })}>SAVE</button> */}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                        <p className="mt-3 text-blue" onClick={() => setLCRequestMoreDetails({ modal: true, page: 1 })} >Request more details  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} /></p>
                      </div>

                      {data.additionalDetails && data.additionalDetails.length ?
                        <div className='w-100 mt-4'>
                          <label className='font-wt-500 font-size-16'><u>Additional Details Required</u></label>
                          <div className="row">
                            {data.additionalDetails.map((item, index) =>
                              <div className='col-md-3'>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    {item.type === "date" ? (
                                      <img
                                        src={"/assets/images/calender.png"} alt="" className="input-date-icon2" />) : null}
                                    <input disabled={true} className={"form-control bg-white"}
                                      placeholder={`${item.type === "percentage" ? "%  " : ""}${item.type === "currency" ? "$  " : ""}${item.title}`} />
                                    <img onClick={() => toggleComment(index)} src={"/assets/images/comment_filled.png"} alt="" className="input-date-icon" />
                                  </div>
                                  {showComment === index ? (
                                    <div className="ammendPopup">
                                      <button
                                        type="button" className="btn-close ammendCommentCrossIcon"
                                        onClick={() => toggleComment(null)}></button>
                                      <div className="text-center">
                                        <NewTextArea
                                          rows={6}
                                          type={"text"} label={`Reason`}
                                          value={item.reason}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        : null}

                      {/* {additionalDetailsReq &&
                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16'><u>Additional Details Required</u></label>
                          <div className="row">
                            <div className='w-100'>
                              <div className='row'>
                                <div className="col-md-3">
                                  <FileInput name={"Invoice"} value={data.invoice} error={errors.invoice}
                                    isEditable={false}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <FileInput name={"Finance Credit Day"} value={data.financeCreditDay} error={errors.financeCreditDay}
                                    isEditable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      } */}

                    </div>
                  </div>
                </> : tab === 2 && (
                  <div className='px-5 py-4 pt-5 '>
                    <div className="row">

                      <div className='w-100 mt-1'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        <div className='row mt-2'>
                          <div className="col-md-7 mb-3">
                            <label className="font-size-14">Term Sheet</label>
                            <div className="row align-items-center ">
                              <div className="form-group col-md-8 mb-0">
                                <FileInput isEditable={false} name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                                  onChange={handleFile}
                                  onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                                />
                                {errors.termSheetDocument ? <div class="text-danger mt-2">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors.termSheetDocument}</b></div> : ''}
                              </div>
                              {data.termSheetDocument && data.termSheetDocument.name && !data.termSheetDocument.signatureId ? (
                                <div className="col-md-4 text-end">
                                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.termSheetDocument, docSignName: "termSheetDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                                </div>
                              ) : null}
                              {data.termSheetDocument && data.termSheetDocument.signatureId ? (
                                <div className="col-md-4 text-end">
                                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-7 mb-3">
                            <label className="font-size-14">Draft LC</label>
                            <div className="row align-items-center ">
                              <div className="form-group col-md-8 mb-0">
                                <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument}
                                  onChange={handleFile} isEditable={false}
                                  onUploadCancel={() => setData({ ...data, "draftLCDocument": null })}
                                />
                                {errors.draftLCDocument ? <div class="text-danger mt-2">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors.draftLCDocument}</b></div> : ''}
                              </div>
                              {data.draftLCDocument && data.draftLCDocument.name && !data.draftLCDocument.signatureId ? (
                                <div className="col-md-4 text-end">
                                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.draftLCDocument, docSignName: "draftLCDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                                </div>
                              ) : null}
                              {data.draftLCDocument && data.draftLCDocument.signatureId ? (
                                <div className="col-md-4 text-end">
                                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )}

                {(tab === 2 && lcMoreDocuments.modal && lcMoreDocuments.page === 1) ?
                  <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCMoreDocuments({ modal: false, page: 0 })}></button>
                        </div>
                      </div>
                    </div>
                  </div> :
                  (tab === 2 && lcMoreDocuments.modal && lcMoreDocuments.page === 2) &&
                  <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-m">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCMoreDocuments({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                          <p>Request for additional documents sent successfully !</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {(tab === 2 && lcMoreDetails) ? <div className="row pb-5 mx-4">
                  <button type="button"
                    onClick={() => { setSaveContinue(true); setLCMoreDetails(false) }}
                    className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                    {"Save & Continue"}
                  </button>

                </div> : (tab === 2) && (
                  <>
                    {data.additionalDocuments && data.additionalDocuments.length ?
                      <div className='w-100 mx-4 px-4 pb-4'>
                        <label className='font-wt-500 font-size-16'><u>Additional Documents Required</u></label>
                        <div className="row">
                          {data.additionalDocuments.map((item, index) =>
                            <div className='col-md-3'>
                              <div className="row form-group">
                                <div className="col-md-12">
                                  <input disabled={true} className={"form-control bg-white text-al"} placeholder={item.title} />
                                  <img onClick={() => {
                                    toggleDocComment(index)
                                  }} src={"/assets/images/comment_filled.png"} alt="" className="input-date-icon" />
                                  <div className="folderCommentIcon">
                                    <img src={"/assets/images/folder.png"} alt="" className="mb-3" />
                                  </div>
                                  {showDocComment === index ? (
                                    <div className="ammendPopup">
                                      <button
                                        type="button" className="btn-close ammendCommentCrossIcon"
                                        onClick={() => toggleDocComment(null)}></button>
                                      <div className="text-center">
                                        <NewTextArea
                                          rows={6}
                                          type={"text"} label={`Reason`}
                                          value={item.reason}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      : null}
                  </>
                )}

                {
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => { tab == 2 ? handleValidation(undefined) : handleValidation(tab + 1) }}
                      className={`mx-4 enableQuotebtn w-15 py-2 px-3 text-white border-0`}>
                      {tab == 2 ? "Submit" : "Continue"}
                    </button>
                  </div>
                }

                {lcMoreDetails && lcRequestMoreDetails.modal && lcRequestMoreDetails.page === 1 &&
                  <div className={`modal fade ${lcRequestMoreDetails.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCRequestMoreDetails({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p>Request more details</p>
                          <div className="row">
                            <div className='w-100'>
                              <div className=''>
                                {data.additionalDetails && data.additionalDetails.length ? data.additionalDetails.map((item, index) => {
                                  return (
                                    <div className="row">
                                      <div className='col-md-6 move-m'>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <input disabled={true} className={"form-control bg-white"}
                                              placeholder={`${item.type === "percentage" ? "%  " : ""}${item.type === "currency" ? "$  " : ""}${item.title}`} />
                                            {item.type === "date" ? (
                                              <img src={"/assets/images/calender.png"} alt="" className="input-date-icon" />) : null}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-2 move-m align-self-center'>
                                        <img
                                          onClick={() => {
                                            let temp = data.additionalDetails;
                                            temp = temp.filter((i, j) => {
                                              if (j != index) {
                                                return i
                                              }
                                            })
                                            setData({ ...data, additionalDetails: temp, fieldTitle: item.title, fieldType: item.type, fieldReason: item.reason })
                                          }}
                                          src={"/assets/images/edit.png"} alt="" className="mb-3" />
                                        <img
                                          onClick={() => {
                                            let temp = data.additionalDetails;
                                            temp = temp.filter((i, j) => {
                                              if (j != index) {
                                                return i
                                              }
                                            })
                                            setData({ ...data, additionalDetails: temp })
                                          }}
                                          src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3" />
                                      </div></div>
                                  )
                                }) : null}
                              </div>
                              <div className='row'>
                                <p>Select field type</p>
                                <div className="col-md-3 move-m">
                                  <NewSelect highlightBorder={data.fieldType === "currency"}
                                    onClick={() => { setData({ ...data, fieldType: "currency" }) }} isDisabled={true} label={"Currency"}
                                    selectData={[]} />
                                </div>
                                <div className="col-md-3 move-m">
                                  <NewInput highlightBorder={data.fieldType === "percentage"}
                                    onClick={() => { setData({ ...data, fieldType: "percentage" }) }}
                                    isDisabled={true} label={"% Percentage"}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-3 move-m">
                                  <NewInput highlightBorder={data.fieldType === "text"}
                                    onClick={() => { setData({ ...data, fieldType: "text" }) }}
                                    isDisabled={true} label={"Text"}
                                  />
                                </div>
                                <div className="col-md-3 move-m">
                                  <NewInput highlightBorder={data.fieldType === "date"}
                                    onClick={() => { setData({ ...data, fieldType: "date" }) }}
                                    isDisabled={true} label={"Date"} type={'date'}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-6 move-m">
                                  <NewInput isAstrix={true} type={"text"} label={"Title"}
                                    name={"fieldTitle"} value={data.fieldTitle} onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-6 move-m">
                                  <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason.`}
                                    name={"fieldReason"} value={data.fieldReason} onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 move-left text-blue">
                            <a onClick={() => {
                              if (!(data.fieldType && data.fieldTitle && data.fieldReason)) {
                                toastDisplay("Fill details to continue", "info")
                              }
                              else {
                                let temp = data.additionalDetails || []
                                temp.push({ title: data.fieldTitle, type: data.fieldType, reason: data.fieldReason })
                                setData({ ...data, additionalDetails: temp, fieldTitle: "", fieldTitle: "", fieldReason: "" })
                                // setAddMore(true)
                              }
                            }
                            }><p>Add more</p></a>
                          </div>
                          <button
                            type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => {
                              setLCRequestMoreDetails({ modal: false, page: 2 });
                              setAdditionalDetailsReq(true)
                            }}>Send Request</button>
                        </div>
                      </div>
                    </div>
                  </div>}

                {quoteForm.modal && <>
                  <div className={`modal fade ${quoteForm.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setQuoteForm({ modal: false, page: 0 })}></button>
                        </div>
                        {(quoteForm.modal && quoteForm.page === 1) ? <div className="modal-body text-center">
                          <p>Quote</p>
                          <div className="row">
                            <div className='w-100'>
                              <div className='row'>
                                <div className='col-md-5 move-left'>
                                  <InputWithSelect
                                    isAstrix={false} type={"number"} label={"Confirmation Fees"}
                                    selectData={[{ name: "%" }]}
                                    optionValue={"name"} optionLabel={"name"}
                                    name={"confirmationFees"} value={data.confirmationFees} error={errors.confirmationFees}
                                    onChange={handleChange} />
                                  <div className='mb-3 d-flex justify-content-between px-4'>
                                    <input type="radio" id="till_maturity" name="till_maturity"
                                      checked={data.till_maturity ? true : false}
                                      onChange={() => {
                                        setData({ ...data, ["till_maturity"]: !data.till_maturity })
                                      }} />
                                    <label for="till_maturity">Till maturity</label>
                                    <input type="radio" id="till_expiry" name="till_maturity"
                                      checked={!data.till_maturity ? true : false}
                                      onChange={() => {
                                        setData({ ...data, ["till_maturity"]: !data.till_maturity })
                                      }}
                                    />
                                    <label for="till_expiry">Till expiry</label>
                                  </div>
                                </div>


                                <div className='col-md-3 move-left'>
                                  <button type="button"
                                    onClick={() => { setData({ ...data, "giveConfirmationFees": true }) }}
                                    className={`border-0 mb-2 text-white ${data.giveConfirmationFees ? "approvedQuotebtn" : 'disabledQuotebtn'} `}>
                                    {"Give"}
                                  </button>
                                </div>

                                <div className='col-md-3'>
                                  <button type="button"
                                    onClick={() => { setData({ ...data, "giveConfirmationFees": false }) }}
                                    className={`border-0 mb-2 text-white ${data.giveConfirmationFees ? " disabledQuotebtn " : " rejectedQuotebtn "} `}>
                                    {"Deny"}
                                  </button>
                                </div>

                                <div className='col-md-5 move-left'>
                                  <InputWithSelect
                                    isAstrix={false} type={"number"} label={"Discounting Rate"}
                                    selectData={[{ name: "%" }]}
                                    optionValue={"name"} optionLabel={"name"}
                                    name={"discountingRate"} value={data.discountingRate} error={errors.discountingRate}
                                    onChange={handleChange} />

                                  <div className='mb-3 d-flex justify-content-between pr-4 h-1'>
                                    <label className="pt-2">Inclusive of TERM SOFR</label>
                                    <NewSelect
                                      selectData={[{ "label": "3 Months" }, { "label": "6 Months" }, { "label": "9 Months" }, { "label": "12 Months" }]}
                                      optionLabel={'label'} optionValue={'label'}
                                      name={"termSofr"} value={data.termSofr} onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className='col-md-3 move-left'>
                                  <button type="button"
                                    onClick={() => { setData({ ...data, "giveDiscountingRate": true }) }}
                                    className={`border-0 mb-2 text-white ${data.giveDiscountingRate ? "approvedQuotebtn" : 'disabledQuotebtn'} `}>
                                    {"Give"}
                                  </button>
                                </div>

                                <div className='col-md-3'>
                                  <button type="button"
                                    onClick={() => { setData({ ...data, "giveDiscountingRate": false }) }}
                                    className={`border-0 mb-2 text-white ${!data.giveDiscountingRate ? "rejectedQuotebtn" : "disabledQuotebtn"} `}>
                                    {"Deny"}
                                  </button>
                                </div>

                                <div className='col-md-5 move-left'>
                                  <InputWithSelect
                                    isAstrix={false} type={"number"} label={"Setup Fees"}
                                    selectData={most_used_currencies}
                                    selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                    optionValue={"code"} optionLabel={"code"}
                                    name={"setupFees"} value={data.setupFees} error={errors.setupFees}
                                    onChange={handleChange} />
                                </div>

                                <div className='col-md-5 move-left'>
                                  <NewInput isAstrix={false} type={"date"} label={"Offer Valid Till"}
                                    name={"offerValidity"} value={data.offerValidity} error={errors.offerValidity}
                                    onChange={handleChange}
                                  />
                                </div>

                                {/* <div className='col-md-5 move-left'>
                                  <InputWithSelect
                                    isAstrix={false} type={"number"} label={"Finance Limit"}
                                    selectData={most_used_currencies}
                                    selectName={"assignValueCurrency"} selectValue={data.assignValueCurrency}
                                    optionValue={"code"} optionLabel={"code"}
                                    name={"assignValue"} value={data.assignValue} error={errors.assignValue}
                                    onChange={handleChange} />
                                </div> */}


                                <div className='col-md-5 move-left'>
                                  <InputWithSelect
                                    isAstrix={false} type={"number"} label={"Discounting Percentage"}
                                    selectData={[{ name: "%" }]}
                                    optionValue={"name"} optionLabel={"name"}
                                    name={"discountingPercentage"} value={data.discountingPercentage} error={errors.discountingPercentage}
                                    onChange={handleChange} />
                                </div>


                                {data.otherCharges && data.otherCharges.length ? data.otherCharges.map((item, index) => {
                                  return (
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect isDisabled={true}
                                        isAstrix={false} type={"number"} label={item["name"]}
                                        selectData={item.unit === "%" ? [{ code: "%" }] : most_used_currencies}
                                        optionValue={"code"} optionLabel={"code"} selectValue={item.unit}
                                        value={item["amount"]} />
                                    </div>
                                  )
                                }) : null}

                                <div className="col-md-12 move-lg">
                                  <a onClick={() => setQuoteForm({ modal: true, page: 2 })}><p>+ Add other charges</p></a>
                                </div>
                                <div className="col-md-12 move-pg">
                                  <a onClick={() => setQuoteForm({ modal: true, page: 3 })}><p>+ Add remark</p></a>
                                </div>

                              </div>
                            </div>
                          </div>
                          <button
                            onClick={validateQuote}
                            type="button" className={`new-btn w-20 py-2 px-2 text-white`} >Send Quote</button>
                        </div> :
                          (quoteForm.modal && quoteForm.page === 2) ?
                            <>
                              <a className="cursor" onClick={() => {
                                setData({
                                  ...data, otherChargeCurrency: "", otherChargePercentage: "", otherChargeTitle: "",
                                  otherChargesAmt: ""
                                })
                                setQuoteForm({ modal: true, page: 1 })
                              }} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                              <div className="modal-body text-center">
                                <p>Add Other Charges</p>
                                <div className="row">
                                  <div className='w-100'>
                                    <div className='row'>
                                      <div className='col-md-5 move-left'>
                                        <NewSelect isDisabled={data.otherChargePercentage} label={"Select Currency"}
                                          selectData={currency} name={"otherChargeCurrency"}
                                          value={data.otherChargeCurrency} optionLabel={"code"} optionValue={'code'}
                                          error={errors.otherChargeCurrency} onChange={handleChange} />
                                      </div>
                                      <div className='col-md-5 move-left'>
                                        <NewInput isDisabled={data.otherChargeCurrency} type={"number"} label={"% Percentage"}
                                          onChange={handleChange}
                                          name={"otherChargePercentage"} value={data.otherChargePercentage} error={errors.otherChargePercentage}
                                        />
                                      </div>
                                      <div className='col-md-10 move-left'>
                                        <NewInput isAstrix={false} type={"text"} label={"Title"} onChange={handleChange}
                                          name={"otherChargeTitle"} value={data.otherChargeTitle} error={errors.otherChargeTitle}
                                        />
                                      </div>
                                      <div className='col-md-10 move-left'>
                                        <NewInput isDisabled={data.otherChargePercentage} type={"number"} label={"Enter Charges"} onChange={handleChange}
                                          name={"otherChargesAmt"} value={data.otherChargesAmt} error={errors.otherChargesAmt}
                                        />
                                      </div>
                                      <div className="col-md-12 move-lg">
                                        {/* <a onClick={() => setQuoteForm({ modal: true, page: 2 })}><p>+ Add other charges</p></a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                                  onClick={() => {
                                    appendOtherCharge()
                                    setQuoteForm({ modal: true, page: 1 })
                                  }}
                                >Add</button>
                              </div></> :
                            (quoteForm.modal && quoteForm.page === 3) && <>
                              <a className="cursor" onClick={() => setQuoteForm({ modal: true, page: 1 })} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                              <div className="modal-body text-center">
                                <p>Add Remark</p>
                                <div className="col-md-10 move-left">
                                  <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Write a remark for supplier.`}
                                    name={"assignRemark"} value={data.assignRemark} error={errors.assignRemark}
                                    onChange={handleChange}
                                  />
                                </div>
                                <button type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                                  onClick={() => setQuoteForm({ modal: true, page: 1 })}>Add</button>
                              </div></>}
                      </div>
                    </div>
                  </div></>}

                {denyForm.modal && denyForm.page === 0 ? <>
                  <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-md">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
                          <p>Deny Finance Limit</p>
                          <button type="button" className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`} onClick={() => setDenyForm({ modal: true, page: 1 })}>Yes</button>
                          <button type="button" className={`mx-2 new-btn w-15 py-2 px-2 text-white`} onClick={() => setDenyForm({ modal: false, page: 0 })}>No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :
                  denyForm.page === 1 ?
                    <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                      <div className="modal-dialog modal-md">
                        <div className="modal-content submitmodal pb-4">
                          <div className="modal-header border-0">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                          </div>
                          <div className="modal-body text-center">
                            <p>Denial Remark</p>
                            <div className="col-md-10 move-left">
                              <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Leave a message for the supplier.`}
                                name={"assignRemark"} value={data.assignRemark} error={errors.assignRemark} onChange={handleChange}
                              />
                            </div>
                            <button
                              disabled={!data.assignRemark}
                              type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => {
                                setDenyForm({ modal: true, page: 2 })
                                validateQuote("denied")
                              }}>Send</button>
                          </div>
                        </div>
                      </div>
                    </div> : null
                  // denyForm.page === 2 && <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                  //   <div className="modal-dialog modal-md">
                  //     <div className="modal-content submitmodal pb-4">
                  //       <div className="modal-header border-0">
                  //         <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                  //       </div>
                  //       <div className="modal-body text-center">
                  //         <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                  //         <p>Finance limit for LC Confirmation denied successfully !</p>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                }
              </div>
            </div>
          </main>
        </div>
      </div >
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(SignEximBankTermSheet)