import React from "react";

const FinanceInvoiceModal = ({ headerTitle, limitinvoice, children, closeSuccess, modalSize, isCentered, hideCloseIcon, marginTop }) => {
  return (
    <>
      <div className={`modal fade ${limitinvoice && "show"}`} style={{ display: "block", "zIndex": '1000001' }}>
        <div style={{ marginTop: marginTop || '8rem' }} className={`modal-dialog modal-${modalSize || "md"} ${isCentered ? 'modal-dialog-centered' : ''}`}>
          <div className="modal-content submitmodal pb-4">
            {hideCloseIcon ? null :
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => closeSuccess()}></button>
              </div>}
            <div className="modal-body">
              {headerTitle ?
                <div>
                  <label className="font-size-16 font-wt-600 text-color-value" >{headerTitle}</label>
                </div> : null}
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FinanceInvoiceModal;


