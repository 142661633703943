import moment from "moment"
import { ONDCSUBSCRIBERID, platformBackendUrl } from "../../urlConstants"

export let ondcFirstSelectObj = {
    "context": {
        "domain": "ONDC:FIS12",
        "location": {
            "country": {
                "code": "IND"
            },
            "city": {
                "code": "*"
            }
        },
        "transaction_id": "a9aaecca-10b7-4d19-b640-b047a7c62196", //replace this
        "message_id": "bb579fb8-cb82-4824-be12-fcbc405b6608",//replace this
        "action": "select",
        "timestamp": "2023-05-25T05:23:03.443Z",//replace this
        "version": "2.1.0",
        "bap_uri": platformBackendUrl + "/ondc/",
        "bap_id": ONDCSUBSCRIBERID,
        "ttl": "PT10M",
        "bpp_id": "bpp.credit.becknprotocol.org",//replace this
        "bpp_uri": "https://bpp.credit.becknprotocol.org"//replace this
    },
    "message": {
        "order": {
            "provider": {
                "id": "PROVIDER_ID"//replace this
            },
            "items": [
                {
                    "id": "CHILD_ITEM_ID_INVOICE_LOAN",
                    "parent_item_id": "ITEM_ID_INVOICE_LOAN",
                    "xinput": {
                        "form": {
                            "id": "F02" //replace this
                        },
                        "form_response": {
                            "status": "SUCCESS",
                            "submission_id": "1813063f-10bf-454c-a830-a4e2ae6f7ab5" //replace this
                        }
                    }
                }
            ]
        }
    }
}

export let ondcSecondSearchObj = {
    "context": {
        "domain": "ONDC:FIS12",
        "location": {
            "country": {
                "code": "IND"
            },
            "city": {
                "code": "*"
            }
        },
        "transaction_id": `ONDCTXN-${new Date().getTime()}`, //replace this
        "message_id": `ONDCMSG-${new Date().getTime()}`, //replace this
        "action": "search",
        "timestamp": moment(), //replace this
        "version": "2.1.0",
        "bap_uri": platformBackendUrl + "/ondc/",
        "bap_id": ONDCSUBSCRIBERID,
        "ttl": "PT10M",
        "bpp_id": "bpp.credit.becknprotocol.org", //replace this 
        "bpp_uri": "https://bpp.credit.becknprotocol.org" //replace this
    },
    "message": {
        "intent": {
            "category": {
                "descriptor": {
                    "code": "INVOICE_BASED_LOAN"
                }
            },
            "provider": {
                "id": "PROVIDER_ID", // replace this
                "items": [
                    {
                        "id": "ITEM_ID_INVOICE_LOAN",
                        "xinput": {
                            "form": {
                                "id": "F01" //replace this
                            },
                            "form_response": {
                                "status": "SUCCESS",
                                "submission_id": "b2630883-3bc3-48d8-8913-a2ee294163f6" //replace this
                            }
                        }
                    }
                ]
            },
            "payment": {
                "collected_by": "BPP",
                "tags": [
                    {
                        "descriptor": {
                            "code": "BUYER_FINDER_FEES"
                        },
                        "display": false,
                        "list": [
                            {
                                "descriptor": {
                                    "code": "BUYER_FINDER_FEES_TYPE"
                                },
                                "value": "percent-annualized"
                            },
                            {
                                "descriptor": {
                                    "code": "BUYER_FINDER_FEES_PERCENTAGE"
                                },
                                "value": "1"
                            }
                        ]
                    },
                    {
                        "descriptor": {
                            "code": "SETTLEMENT_TERMS"
                        },
                        "display": false,
                        "list": [
                            {
                                "descriptor": {
                                    "code": "DELAY_INTEREST"
                                },
                                "value": "2.5"
                            },
                            {
                                "descriptor": {
                                    "code": "STATIC_TERMS"
                                },
                                "value": "https://bap.credit.becknprotocol.io/personal-banking/loans/personal-loan"
                            },
                            {
                                "descriptor": {
                                    "code": "OFFLINE_CONTRACT"
                                },
                                "value": "true"
                            }
                        ]
                    }
                ]
            }
        }
    }
}

export let ondcInitialSearchObj = {
    "context": {
        "domain": "ONDC:FIS12",
        "location": {
            "country": {
                "code": "IND"
            },
            "city": {
                "code": "*"
            }
        },
        "transaction_id": `ONDCTXN-${new Date().getTime()}`, //replace this
        "message_id": `ONDCMSG-${new Date().getTime()}`, //replace this
        "action": "search",
        "timestamp": moment(), //replace this
        "version": "2.1.0",
        "bap_uri": platformBackendUrl + "/ondc/",
        "bap_id": ONDCSUBSCRIBERID,
        "ttl": "PT10M"
    },
    "message": {
        "intent": {
            "category": {
                "descriptor": {
                    "code": "INVOICE_BASED_LOAN"
                }
            },
            "payment": {
                "collected_by": "BPP",
                "tags": [
                    {
                        "descriptor": {
                            "code": "BUYER_FINDER_FEES"
                        },
                        "display": false,
                        "list": [
                            {
                                "descriptor": {
                                    "code": "BUYER_FINDER_FEES_TYPE"
                                },
                                "value": "percent-annualized"
                            },
                            {
                                "descriptor": {
                                    "code": "BUYER_FINDER_FEES_PERCENTAGE"
                                },
                                "value": "1"
                            }
                        ]
                    },
                    {
                        "descriptor": {
                            "code": "SETTLEMENT_TERMS"
                        },
                        "display": false,
                        "list": [
                            {
                                "descriptor": {
                                    "code": "DELAY_INTEREST"
                                },
                                "value": "2.5"
                            },
                            {
                                "descriptor": {
                                    "code": "STATIC_TERMS"
                                },
                                "value": "https://bap.credit.becknprotocol.io/personal-banking/loans/personal-loan"
                            },
                            {
                                "descriptor": {
                                    "code": "OFFLINE_CONTRACT"
                                },
                                "value": "true"
                            },
                            {
                                "descriptor": {
                                    "code": "BAP_OFFLINE_CONTRACT"
                                },
                                "value": "true"
                            }
                        ]
                    }
                ]
            }
        }
    }
}

export let htmlFormTemplate = `<!DOCTYPE HTML>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1.0" name="viewport">
    <title>Kotak Mahindra Bank Limited</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        header {
            text-align: center;
            padding: 20px;
            background-color: #393737;
        }

        header img {
            max-width: 200px;
            max-height: 200px;
        }

        .form-container {
            width: 60%;
            padding: 20px;
            border: 1px solid #393737;
            border-radius: 10px;
            margin: 20px auto 0;
        }

        form {
            display: grid;
            gap: 10px;
        }

        label {
            font-weight: bold;
        }

        input[type="text"],
        input[type="file"],
        input[type="checkbox"] {
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
        }

        input[type="submit"] {
            background-color: #4caf50;
            color: white;
            padding: 10px 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }

        label {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
        }

        .checkbox-container {
            display: inline-block;
            margin-top: 5px;
        }

        input[type="checkbox"] {
            width: 200px;
            /* Set a width to input fields for consistency */
            padding: 8px;
            box-sizing: border-box;
        }

        .status-container {
            text-align: center;
            padding: 20px;
            color: black;
            border-radius: 8px;
        }
    </style>
</head>

<body>
    <header>
        <img alt="No-Logo" src="https://www.kotak.com/content/dam/Kotak/kotak-logo.png">
    </header>
    <div class="form-container">
        <form action="/forms/invoice-based/kotak/submit-personal-info/ONDCTXN-1715843677782" method="post"
            enctype="multipart/form-data">
            <label for="udyamNumber">UDYAM Number</label>
            <input id="udyamNumber" type="text" name="udyamNumber" value=""/>

            <label for="mobNo">Contact Number</label>
            <input id="mobNo" name="mobNo" type="text" value=""/>

            <label for="Email">Email</label>
            <input id="Email" name="Email" type="text" value=""/>

            <label for="gstinProfile">GSTIN Profile</label>
            <input id="gstinProfile" name="gstinProfile" type="file"/>

            <label for="gstr1">GSTR1</label>
            <input id="gstr1" name="gstr1" type="file"/>

            <label for="gstr2A">GSTR2A</label>
            <input id="gstr2A" name="gstr2A" type="file"/>

            <label for="gstr3B">GSTR3B</label>
            <input id="gstr3B" name="gstr3B" type="file"/>

            <label for="aa_id">Account Aggregator ID</label>
            <input id="aa_id" name="aa_id" required type="text" value=""/>

            <div class="checkbox-container">
                <input id="tnc" type="checkbox"/>
                <label for="tnc">I have read the <a
          href="https://www.kotak.com/en/corporate/trade-supply-chain-finance/supply-chain-finance/invoice-financing.html">Terms
        and Conditions</a></label>
            </div>

            <input id="formId" name="formId" type="hidden" value="submit_personal_info">
            <input type="submit" value="Submit">
        </form>
    </div>
</body>

</html>`