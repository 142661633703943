import React, { Component } from 'react'
import SignaturePad from 'react-signature-canvas'
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

class SignPad extends Component {
  state = { trimmedDataURL: null, preview: false }
  value = { data: null }
  sigPad = {}
  clear = () => {
    this.sigPad.clear()
  }
  preview = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png'),
      preview: true
    })
  }
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png')
    })

    let object = {
      'signPadBase64': this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
      // 'reqId': this.props.reqId ? this.props.reqId : null,
      'signType': this.props.signType ? this.props.signType : null,
      'document_name': this.props.document_name ? this.props.document_name : null,
      'userId': this.props.userId,
      'email': this.props.userId,
      'userName': this.props.userName,
      'tbldocid': this.props.tbldocid,
      'userTypeId': this.props.userTypeId,
      'userIp': this.props.userIp
    }

    if (this.props.mktPlaceShipment) {
      object["mktPlaceShipment"] = true
    }

    if (this.props.fileData.financeId) {
      object['financeId'] = this.props.fileData.financeId
    }

    call('POST', 'signimage', object).then((result) => {
      console.log('post request result:', result);
      toastDisplay("Digital Signature Added successfuly", "success");
      if (this.props.setrefreshDoc) {
        this.props.setrefreshDoc(this.props.refreshDoc + 1)
      }
    }).catch(err => {
      console.log("conn:", err)
    })
  }
  render() {
    let { trimmedDataURL } = this.state
    let { preview } = this.state
    return <div style={{
      "width": "100 %",
      "top": "10 %",
      "left": "10 %"
    }}>
      <div style={{ "border": "2px solid rgba(0, 0, 0, 0.1)" }}>
        {this.props.document_name == 'email' ?
          <SignaturePad canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }} ref={(ref) => { this.sigPad = ref }} />
          :
          <SignaturePad canvasProps={{ width: 1000, height: 100, className: 'sigCanvas' }} ref={(ref) => { this.sigPad = ref }} />
        }
      </div>
      <div className="col-md-12">
        <button type="button" className="text-center btn btn-dark btn-sm m-1 float-left" onClick={this.clear}>
          Clear
        </button>
        <button type="button" className="text-center btn btn-success btn-sm m-1 float-right" onClick={this.trim}>
          Submit
        </button>
        <button type="button" className="text-center btn btn-warning btn-sm m-1 border border-warning float-right" onClick={this.preview}>
          Preview
        </button>
      </div>

      {trimmedDataURL && preview == true &&
        <div className="modal show" id="CreateContract">
          <div className={`modal-dialog ${this.props.document_name == 'email' ? 'modal-xs border-inner' : 'modal-xl border-inner'}`} >
            <form noValidate>
              <div className="modal-content">
                <div className="modal-header primary">
                  <h4 className="modal-title text-white">Preview Sign</h4>
                  <button type="button" className="close" onClick={() => this.setState({ trimmedDataURL: null })}>×</button>
                </div>
                <div className="modal-body">
                  <div className="col-md-12">
                    <img src={trimmedDataURL} />
                  </div>
                  <div className="modal-footer primary">
                    <button type="button" className="btn btn-info btn-sm" onClick={this.trim}>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>}
    </div>
  }
}
export default SignPad;