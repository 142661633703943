import React, { useState } from "react";
import FAQsList from "./faqsList";

const FAQsCard = ({ dbData, faqType, setFaqType, search }) => {

  // const [tab, setTab] = useState("LC");

  return (
    <>
      <div className="card borderRadius helpsupportcard">
        {search ? null : (
          <div className="card-header bg-transparent border-0 px-4 mb-0">
            <nav >
              <div className="nav nav-tabs faqs-tab border-0 " id="faqs-tab" role="tablist">
                <button className={`nav-link fablink borderright rounded-0 bg-transparent ${faqType === "LC" && 'active'}`} id="nav-LC-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-LC" aria-selected="true" onClick={() => setFaqType("LC")}>LC</button>
                {/* <span className="border-left"></span> */}
                <button className={`nav-link fablink borderright rounded-0 bg-transparent ${faqType === "Invoice discounting" && 'active'}`} id="nav-Invoicediscounting-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Invoicediscounting" aria-selected="false" onClick={() => setFaqType("Invoice discounting")}>Invoice discounting</button>
                {/* <span className="border-left"></span> */}
                <button className={`nav-link fablink borderright rounded-0 bg-transparent ${faqType === "Wallet" && 'active'}`} id="nav-Wallet-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Wallet" aria-selected="false" onClick={() => setFaqType("Wallet")}>Wallet</button>
                <button className={`nav-link fablink bg-transparent borderright rounded-0 ${faqType === "Other service" && 'active'}`} id="nav-Otherservice-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Otherservice" aria-selected="false" onClick={() => setFaqType("Other service")}>Other service</button>
                <button className={`nav-link fablink bg-transparent borderright rounded-0 ${faqType === "Buyer management" && 'active'}`} id="nav-Buyermanagement-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Buyermanagement" aria-selected="false" onClick={() => setFaqType("Buyer management")}>Buyer management</button>

                <button className={`nav-link fablink bg-transparent border-right borderright rounded-0 ${faqType === "Reports" && 'active'}`} id="nav-Reports-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Reports" aria-selected="false" onClick={() => setFaqType("Reports")}>Reports</button>

                <button className={`nav-link fablink bg-transparent rounded-0 ${faqType === "Document vault" && 'active'}`} id="nav-Documentvault-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Documentvault" aria-selected="false" onClick={() => setFaqType("Document vault")}>Document vault</button>
              </div>
            </nav>
          </div>
        )}
        <div className="pb-2 tab-content d-block" id="nav-tabContent">
          {faqType === "LC" && <div className="tab-pane fade show active bg-transparent " id="nav-LC" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
          {faqType === "Invoice discounting" && <div className="tab-pane fade show active bg-transparent " id="nav-Invoicediscounting" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
          {faqType === "Wallet" && <div className="tab-pane fade show active bg-transparent " id="nav-Wallet" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
          {faqType === "Other service" && <div className="tab-pane fade show active bg-transparent " id="nav-Otherservice" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
          {faqType === "Buyer management" && <div className="tab-pane fade show active bg-transparent " id="nav-Buyermanagement" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
          {faqType === "Reports" && <div className="tab-pane fade show active bg-transparent " id="nav-Reports" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
          {faqType === "Document vault" && <div className="tab-pane fade show active bg-transparent " id="nav-Documentvault" role="tabpanel" >
            <FAQsList dbData={dbData} />
          </div>
          }
        </div>

      </div>
    </>
  );
}
export default FAQsCard;