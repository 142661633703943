import { initializeApp } from 'firebase/app'
//import swal from 'sweetalert';
import 'firebase/messaging'
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import swal from 'sweetalert';
import store from './store';
import { refreshChat, setIncomingNotifications } from './store/actions/action';

let firebaseConfig = {
  "apiKey": "AIzaSyDpX2quCZeVqinNC2sOSulvHxmjBhgZMYk",
  "authDomain": "tradereboot-c4d65.firebaseapp.com",
  "projectId": "tradereboot-c4d65",
  "storageBucket": "tradereboot-c4d65.appspot.com",
  "messagingSenderId": "903675687266",
  "appId": "1:903675687266:web:e58a20c247beb747d0fc3b",
  "measurementId": "G-Q4VDYPHXLM"
}

try {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  Notification.requestPermission().then(async (permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      let tokenInLocalStorage = localStorage.getItem('fcmToken')
      if (!tokenInLocalStorage) {
        let currentToken = await getToken(messaging, { vapidKey: 'BL3Pgy9bxrajyY8Hr1StDeEyvnVSFKL56AppZw32elW_bQtuO8-Ney2FlW8RofFEcp4G1uMemgRuodgFo0L-eOo' })
        if (currentToken) {
          console.log("fcmtoken==>", currentToken);
          localStorage.setItem('fcmToken', currentToken)
        }
      }
    }
  })

  onMessage(messaging, (payload) => {
    console.log('Message received in foreground', payload);
    if (payload.notification) {
      //swal(payload.notification.title, payload.notification.body, "info")
      //window.location.reload()
      store.dispatch(refreshChat())
      store.dispatch(setIncomingNotifications(payload.notification))
    }
  })

} catch (e) {
  console.log('error', e)
}

