import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewTable } from "../../../utils/newTable";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from "../components/Filter";
import Pagination from "../contract/components/pagination";
import config from '../../../config.json';
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { convertImageToPdf, getDocDetails, SetCache, ClearCache, most_used_currencies, GetCache, encryptData, roundOfNumberInDecimal, dataURItoBlob } from "../../../utils/myFunctions";
import moment from "moment";
import TooltipModal from "../components/Tooltipmodal";
import { ToastContainer } from 'react-toastify';
import ChatBoxPopUp2 from "../../chatRoom/components/ChatBoxPopUp2";
import ColumnSelector from "../../lcV2/qoutes/columnselector";


const addBuyerTabs = [
  { name: "Application form" },
  { name: "Select financer" },
  { name: "Documents" },
  { name: "Review" },
]
const reviewForm = [
  { "name": "Buyer Name", val: "buyerName", unit: "buyerNameTitle" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },
  { "name": "Industry Type", val: "industryType" },
  // { "name": "Country", val: "buyerCountry" },
  // { "name": "Postal Code", val: "buyerPostalCode" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },


]

const reviewForm2 = [
  { "name": "Buyer Name", val: "buyerName", unit: "buyerNameTitle" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },
  { "name": "Industry Type", val: "industryType" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },
  { "name": "Limit Required", val: "LimitRequired" },
]







const financeSelected = [
  { name: "Drip" },
];

const financeSelected2 = [
  { name: "Stenn" },
  { name: "Drip" },
  { name: "Modifi" },
  { name: "ICICI" },
  { name: "Federal" },
];


const selectFinancedata = [
  {
    companyName: "Stenn",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/stenn-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Drip",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/Drip-logo.png",
    ribbon: true,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Modifi",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/modifi-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },

  {
    companyName: "Modifi",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/modifi-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: false
  },
];


export const ApplyForFinance = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [tooltip, setTooltip] = useState(false);
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [applicationCounts, setApplicationCounts] = useState({})

  const [columnVisibility, setColumnVisibility] = useState([
    { name: 'Date', isChecked: true },
    { name: "Buyer Name", isChecked: true },
    { name: "BL Number", isChecked: true },
    { name: "Invoice Number", isChecked: true },
    { name: "Terms of Payments", isChecked: true },
    { name: "Financers Selected", isChecked: true },
    { name: "Limit Available", isChecked: true },
    { name: "Action", isChecked: true },

  ]);
  const [filteredSearch, setFilteredSearch] = useState([])

  // useEffect(() => {
  //   if (!Object.keys(filterData)?.length) {
  //     call('POST', 'getApplyForQuoteFilter', { userId }).then(res => {
  //       console.log("getApplyForQuoteFilter then", res);
  //       res["Grid Columns"] = { accordianId: 'GridColumns', data: [], labelName: 'name', type: 'checkbox', isAllChecked: true }
  //       for (let index = 0; index < columnVisibility.length; index++) {
  //         const element = columnVisibility[index];
  //         res["Grid Columns"].data.push({ name: element.name, isChecked: true })
  //       }
  //       setFilterData(res)
  //     }).catch(err => { })
  //   }
  // }, [])


  // const toggleColumnVisibility = (columnName) => {
  //   setColumnVisibility(prevState => ({
  //     ...prevState,
  //     [columnName]: !prevState[columnName]
  //   }));
  // };

  // useEffect(()=>{
  //   filterData['Grid Columns'].data = columnVisibility
  //   setFilterData()
  // },[])


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("apply-finance");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  useEffect(() => {
    loadApplicationCounts()
  }, [])

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getIDApplicationCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  // useEffect(() => {
  //   call('POST', 'getApplyInvoiceFilter', { userId }).then(res => {
  //     console.log("getApplyInvoiceFilter then", res);
  //     setFilterData(res)

  //   }).catch(err => { })
  // }, [])


  useEffect(() => {
    call('POST', 'getApplyInvoiceFilter', { userId }).then(res => {
      console.log("getApplyInvoiceFilter then", res);
      setFilterData(prevFilterData => ({
        ...prevFilterData,
        ...res
      }));
    }).catch(err => { });
  }, []);

  useEffect(() => {
    if (!Object.keys(filterData)?.length) {
      call('POST', 'getApplyForQuoteFilter', { userId }).then(res => {
        console.log("getApplyForQuoteFilter then", res);
        res["Grid Columns"] = { accordianId: 'GridColumns', data: [], labelName: 'name', type: 'checkbox', isAllChecked: true };
        // Assuming columnVisibility is an array of objects with 'name' property
        for (let index = 0; index < columnVisibility.length; index++) {
          const element = columnVisibility[index];
          res["Grid Columns"].data.push({ name: element.name, isChecked: true });
        }
        setFilterData(prevFilterData => ({
          ...prevFilterData,
          ...res
        }));
        setFilteredSearch(prevFilterData => ({
          ...prevFilterData,
          ...res
        }))
      }).catch(err => { });
    }
  }, [filterData, columnVisibility]); // Adding columnVisibility to the dependency array



  useEffect(() => {
    setshowLoader(true)
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      onlyReadyForInvoiceFinance: true,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "invoiceNo" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      setDbData(result.buyerData);
      setTableData(formatDataForTable(result.buyerData))
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
    //Get buyer list end
  }, [refresh, page, filterData])


  console.log(filterData, 'filterdatainfin')


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      row[0] = item.invCreatedAt ? moment(item.invCreatedAt).format('DD/MM/YYYY') : "-"
      row[1] = item.buyerName
      row[2] = item.blNo || "-"
      row[3] = item.invRefNo || "-"
      row[4] = item.termsOfPayment

      let selectedLenderName = item.selectedQuote.lender_name
      let selectedLenderId = item.selectedQuote.lender_id
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
      let openChatRoomIndx = null

      chatRoomUsers.forEach((u, i) => {
        if (u?.split("::")[1] / 1 == selectedLenderId / 1) {
          openChatRoomIndx = i
        }
      })

      row[5] = <div
        className='flex-row position-relative'
      >
        <img className='cursor'
          onClick={async () => {
            let reqObj = {
              senderId: userId,
              receiverId: selectedLenderId,
              textMessage: 'Hii',
              chat_room_name: "CHAT" + new Date().getTime(),
              includeAdmins: true,
              invApplicationId: item.applicationId,
              receiverParties: selectedLenderId,
              dontSendInitialMsg: true
            }
            setshowLoader(true)
            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
            setshowLoader(false)
            getChatDetails({
              chat_room_id: apiResp.id,
              loggedInUser: userId
            })
            setSelectedChat({
              chatRoomId: apiResp.id,
              receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
              invApplicationId: item.applicationId,
              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
              userId: userId,
              isChatOpen: true,
              receiverId: selectedLenderId
            })
          }}
          src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
        <label className='p-1 mx-1 font-size-13 font-wt-400' >{selectedLenderName}
          <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
      </div>

      row[6] = roundOfNumberInDecimal(item.selectedQuote.financeLimit) + " " + item.selectedQuote.financeLimitCurrency

      row[7] =
        <div className='' >
          {item.invApplicationStatus / 1 == 5 ?
            <button type="button"
              disabled={true}
              className={` border-0 mb-2 rejectedQuotebtn2 text-white`}
              style={{ width: '8.5rem' }}
            >
              {"Application Rejected"}
            </button> :
            <button type="button"
              disabled={item.invRefNo}
              onClick={() => {
                if (booladd) {
                  window.location = `/apply-invoice-multiple?buyer=${item.id}`
                  localStorage.setItem("selectedQuote", JSON.stringify(item.selectedQuote))
                  localStorage.setItem("termSheet", JSON.stringify(item.termSheet))
                  localStorage.setItem("applicationId", item.applicationId)
                  localStorage.setItem("limitPendingFrom", item.limitPendingFrom)
                  localStorage.setItem("documentStatus", item.documentStatus)
                }
              }}
              class={` border-0 mb-2 ${item.invRefNo ? "disablesigncontract text-dark" : " enablesigncontract text-white  "}`}
              style={{ width: '8.5rem' }}>
              {item.invRefNo ? "Applied for Finance" : "Apply for Finance"}
            </button>}
          {item.invRefNo ?
            <p className="font-size-12 text-color-value ml-3">
              <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Waiting for financier to send agreement</span>
            </p>
            : null}
        </div >

      // if (item.invRefNo) {
      //   row[7] =
      //     <img
      //       onClick={() => {
      //         window.location = `/apply-invoice?buyer=${item.id}&view=${encodeURIComponent(encryptData(item.invRefNo))}`
      //         localStorage.setItem("selectedQuote", JSON.stringify(item.selectedQuote))
      //         localStorage.setItem("termSheet", JSON.stringify(item.termSheet))
      //         localStorage.setItem("applicationId", item.applicationId)
      //       }}
      //       src={"assets/images/eye.png"} className={'cursor px-4'} alt='' />
      // }

      let isFinancierRemark = null

      for (let index = 0; index < Object.values(item.buyersRemark || {}).length; index++) {
        const element = Object.values(item.buyersRemark)[index];
        if (element.isVisible && element?.stage === 'InvoiceApplication' + item.applicationId) {
          isFinancierRemark = element
        }
      }

      if (isFinancierRemark) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>Remarks received from financier</b> </span>
          <label
            onClick={() => { setTooltip(true); setData({ ...data, showRemark: isFinancierRemark.remark, showLenderName: isFinancierRemark.lenderName }) }}
            className="text-color1 text-decoration-underline cursor">View Remark</label>
        </p>
      }

      tableData.push(row)
      row = []
    })
    return tableData
  }



  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
        }
        setTab(tab + 1)
      }
      else {
      }
    }
    setErrors(err)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1)
    }
  };

  function openApplication(index) {
    let item = dbData[index]
    if (item.invRefNo) {
      window.location = `/apply-invoice?buyer=${item.id}&view=${encodeURIComponent(encryptData(item.invRefNo))}`
      localStorage.setItem("selectedQuote", JSON.stringify(item.selectedQuote))
      localStorage.setItem("termSheet", JSON.stringify(item.termSheet))
      localStorage.setItem("applicationId", item.applicationId)
    }
    else {
      window.location = `/apply-invoice-multiple?buyer=${item.id}`
      localStorage.setItem("selectedQuote", JSON.stringify(item.selectedQuote))
      localStorage.setItem("termSheet", JSON.stringify(item.termSheet))
      localStorage.setItem("applicationId", item.applicationId)
      localStorage.setItem("limitPendingFrom", item.limitPendingFrom)
      localStorage.setItem("documentStatus", item.documentStatus)
    }
  }

  function onChatWithFinancier() {
    window.location = '/ChatRoomV2'
  }



  return (
    <>
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {selectedChat.isChatOpen && (
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() =>
              getChatDetails({
                chat_room_id: selectedChat.chatRoomId,
                loggedInUser: userId,
              })
            }
            onPopupClose={() => {
              setSelectedChat({
                receiverName: "",
                receiverId: "",
                userId: userId,
                isChatOpen: false,
                logo: "",
              });
              setRefresh(refresh + 1);
            }}
          />
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2
            state="invoice_apply-finance"
            userTokenDetails={userTokenDetails}
          />
          <main
            role="main"
            className={
              "ml-sm-auto col-lg-10 " +
              (navToggleState.status ? " expanded-right" : "")
            }
            id="app-main-div"
          >
            <HeaderV2
              title={"Invoice Discounting > Apply for Finance"}
              userTokenDetails={userTokenDetails}
            />

            <div className="d-flex row justify-content-between px-2">
              <div
                className="card border1Blue pl-4 d-flex justify-content-center cursor py-3"
                style={{ width: "21.44%" }}
              >
                <div>
                  <p className="font-size-22 font-wt-600 text2ECC71 p-0 m-0">
                    {applicationCounts.availableForApplication || 0}
                  </p>
                  <p className="font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3">
                    Available for Application
                  </p>
                </div>
              </div>
              <div
                className="card border1Blue pl-4 d-flex justify-content-center cursor py-3"
                style={{ width: "19.44%" }}
              >
                <div>
                  <p className="font-size-22 font-wt-600 text-color1 p-0 m-0">
                    {applicationCounts.appliedForApplication || 0}
                  </p>
                  <p className="font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3">
                    Applied for Finance
                  </p>
                </div>
              </div>
              <div
                className="card border1Blue pl-4 d-flex justify-content-center cursor py-3"
                style={{ width: "19.44%" }}
              >
                <div>
                  <p className="font-size-22 font-wt-600 text-color-E74C3C p-0 m-0">
                    {applicationCounts.rejected || 0}
                  </p>
                  <p className="font-size-16 font-wt-400 color0C0C0C p-0 m-0 mt-3">
                    Rejected
                  </p>
                </div>
              </div>
              <div style={{ width: "28.44%" }}></div>
            </div>

            <div>
              {/* <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
              {/* <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} /> */}
              <div className="filter-div">
                <Filter
                  filterData={filterData}
                  setFilterData={setFilterData}
                  showFilterBtn={true}
                  showResultPerPage={true}
                  count={count}
                  filter={filter}
                  setFilter={setFilter}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
                <ColumnSelector

                  filterData={filterData}
                  setFilterData={setFilterData}
                />
              </div>
            </div>
            <NewTable
              filterData={filterData}
              setFilterData={setFilterData}
              filteredSearch={filteredSearch}
              setFilteredSearch={setFilteredSearch}
              disableAction={false}
              columns={[
                {
                  name: "Date",
                  filter: true,
                  filterDataKey: "Date",
                  sort: [
                    {
                      name: "Latest First",
                      selected: filter.sortDateBy === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortDateBy: "DESC",
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Oldest First",
                      selected: filter.sortDateBy === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortDateBy: "ASC",
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Buyer Name",
                  filter: true,
                  filterDataKey: "Buyer Name",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortBuyerName === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortBuyerName: "ASC",
                          sortDateBy: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortBuyerName === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortBuyerName: "DESC",
                          sortDateBy: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "BL Number",
                  filter: true,
                  filterDataKey: "BL Number",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortBLnumber === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortBLnumber: "ASC",
                          sortDateBy: false,
                          sortBuyerName: false,
                          sortTermsOfPayment: false,
                          sortFinancerName: false,
                          sortInvoiceNumber: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortBLnumber === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortBLnumber: "DESC",
                          sortDateBy: false,
                          sortBuyerName: false,
                          sortTermsOfPayment: false,
                          sortFinancerName: false,
                          sortInvoiceNumber: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Invoice Number",
                  filter: true,
                  filterDataKey: "Invoice Number",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortInvoiceNumber === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortInvoiceNumber: "ASC",
                          sortDateBy: false,
                          sortBLnumber: false,
                          sortBuyerName: false,
                          sortTermsOfPayment: false,
                          sortFinancerName: false,
                          sortLimitAvailable: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortInvoiceNumber === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortInvoiceNumber: "DESC",
                          sortDateBy: false,
                          sortBLnumber: false,
                          sortBuyerName: false,
                          sortTermsOfPayment: false,
                          sortFinancerName: false,
                          sortLimitAvailable: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Terms of Payments", filter: true,
                  filterDataKey: "Terms of Payments",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortTermsOfPayment === "ASC",
                      onActionClick: () => {
                        setFilter({ ...filter, sortTermsOfPayment: "ASC", sortDateBy: false, sortBuyerName: false, sortBLnumber: false, sortInvoiceNumber: false });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortTermsOfPayment === "DESC",
                      onActionClick: () => {
                        setFilter({ ...filter, sortTermsOfPayment: "DESC", sortDateBy: false, sortBuyerName: false, sortBLnumber: false, sortInvoiceNumber: false });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Financers Selected",
                  filter: true,
                  filterDataKey: "Financers Selected",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortFinancerName === "ASC",
                      onActionClick: () => {
                        setFilter({ ...filter, sortFinancerName: "ASC", sortDateBy: false, sortBuyerName: false, sortTermsOfPayment: false, sortBLnumber: false, sortInvoiceNumber: false, sortLimitAvailable: false });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortFinancerName === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter, sortFinancerName: "DESC", sortDateBy: false, sortBuyerName: false, sortTermsOfPayment: false, sortBLnumber: false,
                          sortInvoiceNumber: false, sortLimitAvailable: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ]
                },
                {
                  name: "Limit Available",
                  filter: true,
                  filterDataKey: "Limit Available",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortLimitAvailable === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortLimitAvailable: "ASC",
                          sortDateBy: false,
                          sortBLnumber: false,
                          sortInvoiceNumber: false,
                          sortTermsOfPayment: false,
                          sortFinancerName: false,
                          sortBuyerName: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortLimitAvailable === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortLimitAvailable: "DESC",
                          sortDateBy: false,
                          sortBLnumber: false,
                          sortInvoiceNumber: false,
                          sortTermsOfPayment: false,
                          sortFinancerName: false,
                          sortBuyerName: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                { name: "Action", filter: false },
              ]}
              data={tableData}
              options={[
                { name: "Application", onClick: openApplication },
                { name: "Chat with Financier", onClick: onChatWithFinancier },
              ]}
            />
            <Pagination
              perPage={filter.resultPerPage || 10}
              page={page}
              totalCount={count}
              onPageChange={(p) => setPage(p)}
            />
          </main>
        </div>
      </div>
      {tooltip && (
        <TooltipModal
          tooltip={tooltip}
          setTooltip={setTooltip}
          data={{
            showRemark: data.showRemark,
            showLenderName: data.showLenderName,
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyForFinance)