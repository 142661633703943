import React, { useState, useEffect } from "react";
import DropdownSearch from "../dropdownWithSearch";
import NewTablev2 from "../../../../utils/newTablev2";
import * as XLSX from 'xlsx';

const ButNot = ({ allData, commodityNames }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterCustomerBy, setFilterCustomerBy] = useState(null);
  const [valuesForNotBilled, setValuesForNotBilled] = useState([]);
  const [notBilled, setNotBilled] = useState([]);
  const [salesData, setSalesData] = useState([])
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [showText, setShowText] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [propertyForFilter, setPropertyForFilter] = useState('')
  const customerDataDropDown = ["Item Name", "Customer Group", "Items Group", "Item Category"];
  useEffect(() => {
    if (allData.length) {
      const items = allData.filter(item => item.type === "Sales")
      console.log("sales items", items)
      setSalesData(items)
    }
  }, [allData])
  useEffect(() => {
    setShowText(false);
  }, [filterCustomerBy]);

  useEffect(() => {
    // Update valuesForBilled based on filterCustomerBy
    if (allData.length && filterCustomerBy) {
      let segment;
      switch (filterCustomerBy) {
        case "Item Name":
          segment = 'itemName'
          // const items = commodityNames
          //   .map((item) => item["commodity_name"])
          //   .filter(Boolean);
          // setValuesForBilled(items);
          break;
        case "Items Group":
          segment = "itemGroup";
          break;
        case "Item Category":
          segment = "category";
          break;
        case "Customer Group":
          segment = "customerGroup";
          break;
        default:
          segment = "";
      }
      if (segment) {
        setPropertyForFilter(segment)
        const items = allData.map((item) => item[segment]).filter(Boolean);
        setValuesForNotBilled(items);
      }
    }
  }, [filterCustomerBy, allData, commodityNames]);


  const handleFromDateChange = (e) => {
    setShowText(false);
    setFilterCustomerBy(null);
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setShowText(false);
    setFilterCustomerBy(null);
    setToDate(e.target.value);
  };

  const handleFilterCustomerChange = (value) => {
    setFilterCustomerBy(value);
  };


  const handleNotBilledChange = (selectedItems) => {
    setShowText(false);
    setShowTable(false)
    setNotBilled(selectedItems);
  };



  const handleSubmit = () => {
    setShowText(true);
  };

  const handleAndORSubmit = () => {
    console.log("clciked")
    setShowTable(true)
    filterSalesData();
  }
  const filterSalesData = () => {
    console.log("called")
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const filtered = salesData.filter((item) => {
      const itemDate = new Date(item.date);
      const isInDateRange = itemDate >= from && itemDate <= to;
      const isNotBilled = !notBilled.includes(item[propertyForFilter]);
      return isInDateRange && isNotBilled;
    });
    console.log(filtered)
    setFilteredSalesData(filtered);
  };
  const columns = [
    { subColumns: "Customer", subColumnClass: "customer-class" },
    { subColumns: "Invoice Number", subColumnClass: "invoice-class" },
    { subColumns: "Quantity", subColumnClass: "quantity-class" },
    { subColumns: "Taxless", subColumnClass: "taxless-class" },
    { subColumns: "Taxpaid", subColumnClass: "taxpaid-class" }
  ];
  const totalQuantity = filteredSalesData.reduce((total, item) => total + item.quantity, 0);
  const totalTaxLess = filteredSalesData.reduce((total, item) => total + item.taxLess, 0);
  const totalTaxPaid = filteredSalesData.reduce((total, item) => total + item.taxPaid, 0);
  const handleDownload = () => {
    const tableData = filteredSalesData.map(row => ({
      Customer: row.partyName,
      "Invoice Number": row.invoiceNumber,
      Quantity: row.quantity,
      Taxless: row.taxLess,
      Taxpaid: row.taxPaid,
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "filteredData.xlsx");
  };
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <div className="mb-3">
          <label htmlFor="fromDate" className="form-label">
            Time Period From
          </label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={handleFromDateChange}
            className="form-control"
            style={{ width: "200px" }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="toDate" className="form-label">
            Time Period To
          </label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={handleToDateChange}
            className="form-control"
            style={{ width: "200px" }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="filterCustomer" className="form-label">
            Filter Customer By:
          </label>
          <DropdownSearch
            placeholder="Filter Customer By"
            items={customerDataDropDown}

            onSelect={handleFilterCustomerChange}
            isDisabled={!fromDate || !toDate}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="notBilled" className="form-label">
            Who Have Not Billed
          </label>

          <DropdownSearch
            placeholder="But Have Not Billed"
            items={valuesForNotBilled}
            onSelect={handleNotBilledChange}
            isDisabled={!filterCustomerBy}
            multipleSelection={true}
            selectAll={true}
          />

        </div>
        <button onClick={handleSubmit} type="button" className="btn btn-primary">
          Submit
        </button>
      </div>
      {showText && (
        <div className="mt-2 mb-2 w-100 d-flex justify-content-between align-items-center">
          <div>
            <p className="fs-6 m-0">
              Customers Who Have Not Billed For {" "}
              <span style={{ color: "rgb(255, 123, 109)" }}>{notBilled.join(", ")}</span>
            </p>
          </div>
          <div>
            <div className="w-50 d-flex justify-content-between align-items-center">
              <button onClick={handleAndORSubmit} className="btn btn-info">Go</button>

            </div>


          </div>
        </div>
      )}
      {showText && showTable &&
        <>
          {filteredSalesData.length ? <><div className="position-relative">
            <h7 className="border border-secondary rounded d-inline-block p-2 ">Quantity : {totalQuantity}</h7>
            <h7 className="border border-secondary rounded d-inline-block p-2 m-3">Tax Less $ {totalTaxLess}</h7>
            <h7 className="border border-secondary rounded d-inline-block p-2 m-3">Tax Paid $ {totalTaxPaid}</h7>
            <button onClick={handleDownload} className="bg-white rounded position-absolute m-3" style={{ border: "0.5px solid grey", width: "30px", height: "30px", right: 0, bottom: 0 }}><img src='assets/images/new_dowd_icon.svg' /></button>
          </div>
            <NewTablev2 showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }} className="mt-5" columns={columns} tableFixed={true} hideHeading={false}>
              {filteredSalesData.map((data, index) => (
                <tr key={index}>
                  <td>{data.partyName}</td>
                  <td>{data.invoiceNumber}</td>
                  <td>{data.quantity}</td>
                  <td>{data.taxLess}</td>
                  <td>{data.taxPaid}</td>
                </tr>
              ))}
            </NewTablev2></> : <h2 style={{ color: "rgb(255, 123, 109)" }} className="text-center m-5 rounded">No Data Found</h2>}
        </>

      }
    </div>
  );
}

export default ButNot;
