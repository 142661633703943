export default function validate(values) {
  console.log("values in validate-->", values)

  let errors = {};

  if (!values.quantity) {
    errors.quantity = 'Quantity is a Mandatory Field';
  }
  if (!values.quantityLoadUnit) {
    errors.quantityLoadUnit = 'MOL is a Mandatory Field';
  }
  if (!values.quantityIndexType) {
    errors.quantityIndexType = 'Quantity Vary Index is a Mandatory Field';
  }
  if (values.quantityIndexType && !values.quantityIndex) {
    errors.quantityIndex = 'Quantity Index is a Mandatory Field';
  }
  if (!values.shipPrice) {
    errors.shipPrice = 'Freight is a Mandatory Field';
  }
  if (!values.shipCurrency) {
    errors.shipCurrency = 'Freight Currency is a Mandatory Field';
  }
  if (!values.demmurageRate) {
    errors.demmurageRate = 'Demmurage rate is a Mandatory Field';
  }
  if (!values.shipmentType) {
    errors.shipmentType = 'Charter Type is a Mandatory Field';
  }
  if (!values.charterType) {
    errors.charterType = 'Charter Type is a Mandatory Field';
  }
  // if (values.charterType / 1 === 2) {
  //   if (!values.charterStart) {
  //     errors.charterStart = 'Charter Start is a Mandatory Field';
  //   }
  //   if (!values.charterEnd) {
  //     errors.charterEnd = 'Charter End is a Mandatory Field';
  //   }
  // }
  if (values.berthLoading) {
    if (!values.berthLoadingNumber) {
      errors.berthLoadingNumber = 'Provide BerthLoading details';
    }
  }
  if (values.anchorageLoading) {
    if (!values.anchorageLoadingNumber) {
      errors.anchorageLoadingNumber = 'Provide AnchorageLoading Details';
    }
  }
  if (values.berthUnloading) {
    if (!values.berthUnloadingNumber) {
      errors.berthUnloadingNumber = 'Provide BerthUnloading Details';
    }
  }
  if (values.anchorageUnloading) {
    if (!values.anchorageUnloadingNumber) {
      errors.anchorageUnloadingNumber = 'Provide AnchorageUnloading Details';
    }
  }
  if (!values.berthLoading && !values.anchorageLoading) {
    errors.loadingType = 'Loading Type is a Mandatory Field';
  }
  if (!values.berthUnloading && !values.anchorageUnloading) {
    errors.unloadingType = 'Unloading Type is a Mandatory Field';
  }
  if (!values.loadingRate) {
    errors.loadingRate = 'Loading Rate is a Mandatory Field';
  }
  if (!values.unloadingRate) {
    errors.unloadingRate = 'Unloading Rate is a Mandatory Field';
  }

  console.log(errors)
  return errors;
}
