import React, { useEffect } from 'react'
import { useState } from 'react'
import { NewInput, NewSelect } from '../../../utils/newInput'
import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { ExportExcel } from '../../../utils/myFunctions';
import PieChartComponent from './PieChartComponent';
import { NewTable } from '../../../utils/newTable';
import toastDisplay from '../../../utils/toastNotification';
import CustomAreaChart from '../../Dashboard/components/CustomAreaChart';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import Filter from '../../InvoiceDiscounting/components/Filter';
import call from '../../../service';
const lanesColor = ['#2ECC71', '#5CB8D3']
const piechartColor = ['#FF774C', '#FFB801', '#F26191', '#9A60B4', '#5470C6', '#91CC75', '#EE6666', '#73C0DE', '#EA7CCC', '#C65454']
const bardataConfig = [
  { dataKey: "int_count", fill: "#2ECC71", display: 'Interested' },
  { dataKey: "others_count", fill: "#AED8FF", display: 'Others' },
  { dataKey: "not_interested", fill: "#FF7B6D", display: 'Not Interested' },
]
const shipmentdetails = [
  { "name": "Date", val: "DATE", type: 'date' },
  { "name": "Amount", val: "FOB_VALUE_USD", unit: "$", type: 'amount' },
  { "name": "Location", val: "DESTINATION_PORT" },
  { "name": "Buyer", val: "CONSIGNEE_NAME" }

]
const label = {
  "int_count": "Interested",
  "others_count": "Others",
  "not_interested": "Not Interested"
}
const BuyerReportsTab = ({ userTokenDetails }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [graphData, setGraphData] = useState({})
  const [stats, setStats] = useState({})
  const [userOnboardSelect, setUserOnboardSelect] = useState('all')
  const [onboardGraphdata, setOnboardGraphdata] = useState([])
  const [salesPerson, setSalesPerson] = useState([])
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })

  const [EarningSelect, setEarningSelect] = useState('all')
  const [earningGraphData, setearningGraphData] = useState([])
  const [graphConfiguration, setGraphConfiguration] = useState({
    buyerOnboardGraph: "6 Months",
    onboardedGraphMode: true,
    invoiceLcGraphMode: true,
    applicationStageGraphMode: true,
    applicationStageFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    applicationStageTo: todayDateObj.clone().format("YYYY-MM-DD"),
  })
  const [buyersgraph, setbuyersGraph] = useState([])
  const [filterData, setfilterdata] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [refresh, setrefresh] = useState(0)
  const [Count, setCount] = useState(0)
  const [page, setpage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [filteredSearch, setFilteredSearch] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [buyerStatusGraph, setbuyerStatusGraph] = useState([])
  const [approvedBuyers, setApprovedBuyers] = useState([])
  const [topLimitBuyers, settopLimitBuyers] = useState([])
  const [topCountryBuyers, settopCountryBuyers] = useState([])

  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const userId = userTokenDetails?.user_id

  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getReportsBuyerList()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  const getApprovedBuyers = () => {
    setshowLoader(true)
    call('POST', 'getApprovedBuyers', {}).then(result => {
      let finalRes = []
      for (let i = 0; i <= result.length - 1; i++) {
        finalRes.push({
          type: result[i].type,
          value: parseFloat(result[i].value)
        })
      }
      setApprovedBuyers(finalRes)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
      console.log('error in api', e);
    })
  }
  const getTopLimitBuyers = () => {
    setshowLoader(true)
    call('POST', 'getTopLimitBuyers', {}).then(result => {
      let finalRes = []
      for (let i = 0; i <= result.length - 1; i++) {
        finalRes.push({
          type: result[i].type,
          value: parseFloat(result[i].value)
        })
      }
      settopLimitBuyers(finalRes)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
      console.log('error in api', e);
    })
  }
  const getTopBuyersByCountry = () => {
    setshowLoader(true)
    call('POST', 'getTopBuyersByCountry', {}).then(result => {
      let finalRes = []
      for (let i = 0; i <= result.length - 1; i++) {
        finalRes.push({
          type: result[i].type,
          value: parseFloat(result[i].value)
        })
      }
      settopCountryBuyers(finalRes)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
      console.log('error in api', e);
    })
  }
  useEffect(() => {
    getApprovedBuyers()
    getTopLimitBuyers()
    getTopBuyersByCountry()
  }, [])
  async function handleTransactionPopupINV(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.buyerId,
      applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }
  const getXAxisDateFormat = (data, value) => {
    let countForMonths = data?.split(" ")[0] / 1
    let dateFormat = ''
    if (countForMonths > 3) {
      console.log('dartataaadsasdsdasad', moment(value).format('MMM YYYY'));
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths === 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }
  const CustomTooltip = ({ payload }) => {
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10,
        }}>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0' style={{ color: payload?.[0]?.payload?.["fill"] }}>{`${payload?.[0]?.payload?.["type"]} - ${payload?.[0]?.payload?.["value"]}`}</p>

      </div>
    );
  }
  async function downloadActiveUserApplicationStage() {
    if (!graphData.activeApplicationStageTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.activeApplicationStageTableData
      let csvString = "Active Users (Application Stage),Finance Limit,Quote,Termsheet/Contract,Finance,Agreement,Approved\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]},${temp[0][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]},${temp[1][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ActiveUserApplicationStage.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadActiveUserApplicationStage", error);
    }
  }

  async function downloadInactiveUserDays() {
    if (!graphData.inactiveUserDaysTableData?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = graphData.inactiveUserDaysTableData
      let csvString = "Inactive Users(Days),15 Days,30 Days,45 Days,60 Days,75 Days\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[2][0]},${temp[2][1]},${temp[2][2]},${temp[2][3]},${temp[2][4]},${temp[2][5]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `InactiveUserSummary.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadInactiveUserDays", error);
    }
  }
  const getBuyersOnboardgraph = () => {
    setshowLoader(true)
    call('POST', 'getBuyersOnboardgraph', { buyersAddedDuration: graphConfiguration.buyerOnboardGraph }).then(result => {
      setbuyersGraph(result)
      setshowLoader(false)
    }).catch(err => {
      setshowLoader(false)
      console.log('error in getAdminLCLimitGraph', err);
    })
  }
  const getBuyersStatusGraph = () => {
    setshowLoader(true)
    call('POST', 'getBuyersStatusGraph', {}).then(result => {
      setbuyerStatusGraph(result)
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getBuyersStatusGraph()
  }, [])
  useEffect(() => {
    getBuyersOnboardgraph()
  }, [graphConfiguration.buyerOnboardGraph])
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getTaskManagerGraphData', graphConfiguration).then(res => {
      console.log("getTaskManagerGraphData response===============>", res);
      setshowLoader(false)
      let activeApplicationStageFunnelGraphData = []
      activeApplicationStageFunnelGraphData[0] = { "name": "Finance Limit", "value": res.activeUserApplicationSummary["Finance Limit"]["invoice"] + res.activeUserApplicationSummary["Finance Limit"]["lc"] }
      activeApplicationStageFunnelGraphData[1] = { "name": "Quote", "value": res.activeUserApplicationSummary["Quote"]["invoice"] + res.activeUserApplicationSummary["Quote"]["lc"] }
      activeApplicationStageFunnelGraphData[2] = { "name": "Termsheet/Contract", "value": res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"] + res.activeUserApplicationSummary["Termsheet/Contract"]["lc"] }
      activeApplicationStageFunnelGraphData[3] = { "name": "Finance", "value": res.activeUserApplicationSummary["Finance"]["invoice"] + res.activeUserApplicationSummary["Finance"]["lc"] }
      activeApplicationStageFunnelGraphData[4] = { "name": "Agreement", "value": res.activeUserApplicationSummary["Agreement"]["invoice"] }
      activeApplicationStageFunnelGraphData[5] = { "name": "Approved", "value": res.activeUserApplicationSummary["Approved"]["invoice"] + res.activeUserApplicationSummary["Approved"]["lc"] }

      let inactiveUserDaysFunnelGraphData = []
      inactiveUserDaysFunnelGraphData[0] = { "name": "15 Days", "value": res.inactiveUserDayWiseSummary["15 Days"]["exporter"] + res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["15 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[1] = { "name": "30 Days", "value": res.inactiveUserDayWiseSummary["30 Days"]["exporter"] + res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["30 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[2] = { "name": "45 Days", "value": res.inactiveUserDayWiseSummary["45 Days"]["exporter"] + res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["45 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[3] = { "name": "60 Days", "value": res.inactiveUserDayWiseSummary["60 Days"]["exporter"] + res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["60 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[4] = { "name": "75 Days", "value": res.inactiveUserDayWiseSummary["75 Days"]["exporter"] + res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["75 Days"]["financers"] }

      let activeApplicationStageTableData = []
      activeApplicationStageTableData[0] = ["Invoice Discounting", res.activeUserApplicationSummary["Finance Limit"]["invoice"], res.activeUserApplicationSummary["Quote"]["invoice"], res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"],
        res.activeUserApplicationSummary["Finance"]["invoice"], res.activeUserApplicationSummary["Agreement"]["invoice"], res.activeUserApplicationSummary["Approved"]["invoice"]]
      activeApplicationStageTableData[1] = ["LC Discounting", res.activeUserApplicationSummary["Finance Limit"]["lc"], res.activeUserApplicationSummary["Quote"]["lc"], res.activeUserApplicationSummary["Termsheet/Contract"]["lc"],
        res.activeUserApplicationSummary["Finance"]["lc"], 0, res.activeUserApplicationSummary["Approved"]["lc"]]

      let inactiveUserDaysTableData = []
      inactiveUserDaysTableData[0] = ["Exporter", res.inactiveUserDayWiseSummary["15 Days"]["exporter"], res.inactiveUserDayWiseSummary["30 Days"]["exporter"], res.inactiveUserDayWiseSummary["45 Days"]["exporter"],
        res.inactiveUserDayWiseSummary["60 Days"]["exporter"], res.inactiveUserDayWiseSummary["75 Days"]["exporter"]]
      inactiveUserDaysTableData[1] = ["financers", res.inactiveUserDayWiseSummary["15 Days"]["financers"], res.inactiveUserDayWiseSummary["30 Days"]["financers"], res.inactiveUserDayWiseSummary["45 Days"]["financers"],
        res.inactiveUserDayWiseSummary["60 Days"]["financers"], res.inactiveUserDayWiseSummary["75 Days"]["financers"]]
      inactiveUserDaysTableData[2] = ["Channel Partner", res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"],
        res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"]]

      setGraphData({
        ...res, activeApplicationStageFunnelGraphData, inactiveUserDaysFunnelGraphData, activeApplicationStageTableData, inactiveUserDaysTableData
      })
    })
  }, [graphConfiguration])
  const getReportsBuyerList = () => {
    setshowLoader(true)
    let reqObj = {
      currentPage: page,
      resultPerPage: filter.resultPerPage
    }
    call('POST', 'getReportsBuyerList', reqObj).then(result => {
      setshowLoader(false)
      setdbData(formatDataForTable(result.data))
      setCount(result.total_records)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getReportsBuyerList()
  }, [page, refresh, filterData, salesPerson])
  function formatDataForTable(data) {

    let tableData = []
    let row = []
    data.forEach((item, index) => {
      try {
        let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
        let status = ''
        if (item.buyers_credit === null) {
          status = "Waiting for financer quote"
        }
        else if (item.buyers_credit != null && item.selectedFinancier == null) {
          status = 'Quote Recieved'
        } else if (item.termSheetSignedByBank && item.termSheetSignedByExporter) {
          status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Quote Locked" : "Limit Approved"
        } else if (item.termSheetSignedByExporter) {
          status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Contract Signed by Exporter" : 'TermSheet signed by exporter'
        }
        else if (item.selectedFinancier) {
          status = 'Quote Selected by exporter'
        } else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation') {
          if (buyers_credit.every(data => data.status === 'denied')) {
            status = 'Limit Denied'
          }
        } else if (item.invRefNo != 'lc_discounting' && item.invRefNo != 'lc_confirmation' && item.invRefNo != 'sblc') {

          if (buyers_credit.every(data => data.financierAction === 'deny')) {
            status = 'Limit Denied'
          }
        }

        row[0] = item.buyerName
        row[1] = item.company_name
        row[2] = item.buyerCountryName
        row[3] = item.availableLimit ? "$ " + item.availableLimit : 'NA'
        row[4] = item.limitCount ? item.limitCount : 'NA'
        row[5] = item.requiredLimit ? "$ " + item.requiredLimit : 'NA'
        row[6] = <div class="dropdown w-100" >
          <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {item.SubAdminName || '-'}
          </label>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {salesPerson.map(element => {
              return <li className="dropdown-item cursor" onClick={() => updateLeadAssignedTo(element.id, item.userId)} >{element.contact_person}</li>
            })}
          </ul>
        </div>
        row[7] = <label className='text-color1 font-wt-600 cursor' onClick={() => {
          handleTransactionPopupINV({
            buyerId: item.buyerId,
            applicationId: item.limitId,
            invRefNo: item.invRefNo
          })
        }}>{status}</label>

        tableData.push(row)
        row = []
      } catch (e) {
        console.log('error in', e, item);
      }

    })
    return tableData
  }
  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin || userPermissionsForSubAdmin?.["Assign Task"]) {
      setshowLoader(true)
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  return (
    <>
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div>
        <div className='row mt-5'>

          <div className='col-md-6'>
            <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
              {true ? (
                <div className='col-md-12 text-center ml-9rem'>
                  <PieChartComponent hideDollar={true}
                    customToolTip={<CustomTooltip />}
                    data={buyerStatusGraph} dataKey="value" colors={lanesColor} cornerRadius={30} totalVal={10} />
                </div>
              ) : null}
              <label className='text-center w-100 font-size-14 font-wt-600 mr-3 mb-0 cursor'>Buyers Status <img src='assets/images/downloadBu.svg' className='ml-2 cursor' /></label>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='card dashboard-card shadow-sm'>
              <div>
                <div class="dropdown">
                  <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0  text-color1' >Buyers Added</label>

                    <div className='w-20 align-items-center d-flex justify-content-end'>
                      <div className='pr-3'>
                        <img
                          onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                          className='cursor'
                          src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                      </div>
                      <div className=''>
                        <img
                          onClick={() => { }}
                          className='cursor' src='/assets/images/download_icon_with_bg.png' />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <CustomAreaChart XFormatter={(value) => getXAxisDateFormat(graphConfiguration.leadGraphDuration, value)} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={buyersgraph} xDataKey={"label"} dataKey={"buyers"} />

            </div>
          </div>

        </div>
        <div className='row mt-5'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Active Applications (Application stage)</label>

                  </div>
                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            {!graphConfiguration.applicationStageGraphMode ? (
              <>
                <div>
                  <NewTable
                    disableAction={true}
                    data={graphData.activeApplicationStageTableData || []}
                    columns={[{ name: "Active Users(Application Stage)" }, { name: "Finance Limit" }, { name: "Quote" },
                    { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                  />
                </div>
                <div className='mt-4'>
                  <NewTable
                    disableAction={true}
                    data={graphData.inactiveUserDaysTableData || []}
                    columns={[{ name: "Inactive Users(Days)" }, { name: "15 Days" }, { name: "30 Days" },
                    { name: "45 Days" }, { name: "60 Days" }, { name: "75 Days" }]}
                  />
                </div>
              </>
            ) : (
              <div className='h-90 d-flex flex-row pt-5 pb-4 mx-0' >
                <div className='col-6'>
                  {graphData?.activeApplicationStageFunnelGraphData?.length ? (
                    <>
                      <div className='text-center h-90'>
                        <img src='/assets/images/Active users application stage.png' width={"70%"} height={"85%"} />
                      </div>
                      <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.activeApplicationStageFunnelGraphData[0]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.activeApplicationStageFunnelGraphData[1]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.activeApplicationStageFunnelGraphData[2]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.activeApplicationStageFunnelGraphData[3]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.activeApplicationStageFunnelGraphData[4]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.activeApplicationStageFunnelGraphData[5]?.value}</label>

                    </>
                  ) : null}
                  <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Active Users (Application Stage)</label>
                </div>

                <div className='col-6'>
                  {graphData?.inactiveUserDaysFunnelGraphData?.length ? (
                    <>
                      <div className='text-center h-90'>
                        <img src='/assets/images/inactive days.png' width={"70%"} height={"75%"} />
                      </div>
                      <label className='position-absolute font-size-16 font-wt-700 inact-15-days'>{graphData?.inactiveUserDaysFunnelGraphData[0]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 inact-30-days'>{graphData?.inactiveUserDaysFunnelGraphData[1]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 inact-45-days'>{graphData?.inactiveUserDaysFunnelGraphData[2]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 inact-60-days'>{graphData?.inactiveUserDaysFunnelGraphData[3]?.value}</label>
                      <label className='position-absolute font-size-16 font-wt-700 inact-75-days'>{graphData?.inactiveUserDaysFunnelGraphData[4]?.value}</label>

                    </>
                  ) : null}
                  <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Inactive Users (Days)</label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-6'>
            <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
              <div>
                <div class="dropdown">
                  <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                    <div className='d-flex align-items-center '>
                      <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Top Buyers</label>

                    </div>
                    <div className='w-20 align-items-center d-flex justify-content-end'>
                      <div className='pr-3'>
                        <img
                          onClick={() => { }}
                          className='cursor'
                          src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                      </div>
                      <div className=''>
                        <img
                          onClick={() => { }}
                          className='cursor' src='/assets/images/download_icon_with_bg.png' />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='col-md-12 text-center'>
                    <PieChartComponent hideDollar={true}
                      customToolTip={<CustomTooltip />}
                      paddingAngle={2}
                      innerRadius={30} data={approvedBuyers} dataKey="value" colors={piechartColor} cornerRadius={0} />
                  </div>
                  <label className='text-center w-100 font-size-14 font-wt-600 mr-3 mb-0 cursor'>Buyers Status</label>
                </div>

                <div className='col-md-6'>
                  <div className='col-md-12 text-center'>
                    <PieChartComponent hideDollar={true}
                      paddingAngle={2}
                      customToolTip={<CustomTooltip />}
                      innerRadius={30} data={topLimitBuyers} dataKey="value" colors={piechartColor} cornerRadius={0} />
                  </div>
                  <label className='text-center w-100 font-size-14 font-wt-600 mr-3 mb-0 cursor'>Buyers Approval</label>

                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
              <div>
                <div class="dropdown">
                  <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                    <div className='d-flex align-items-center '>
                      <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>No Of Buyers by Country</label>

                    </div>
                    <div className='w-20 align-items-center d-flex justify-content-end'>
                      <div className='pr-3'>
                        <img
                          onClick={() => { }}
                          className='cursor'
                          src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                      </div>
                      <div className=''>
                        <img
                          onClick={() => { }}
                          className='cursor' src='/assets/images/download_icon_with_bg.png' />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              {true ? (
                <div className='col-md-12 text-center '>
                  <PieChartComponent hideDollar={true}
                    paddingAngle={2}
                    customToolTip={<CustomTooltip />}
                    innerRadius={30} data={topCountryBuyers} dataKey="value" colors={piechartColor} cornerRadius={0} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='my-2 card p-3 dashboard-card border-0 borderRadius'>
            <div className='filter-div ml-4'>
              <Filter
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData} setFilterData={setfilterdata} showFilterBtn={true}
                showResultPerPage={true} count={Count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} isAdditionalButton={true}>
                <div className="d-flex gap-4">
                  <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => { }}>Add new</button>
                </div>
              </Filter>
            </div>
            <div>
              <NewTable
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData}
                setFilterData={setfilterdata}
                columns={[
                  {
                    name: "Buyer Name", filter: true, filterDataKey: "Buyer Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
                  },
                  {
                    name: "Supplier Name", filter: true, filterDataKey: "Buyer Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
                  },
                  { name: "Country" },
                  {
                    name: "Limit", filter: true, filterDataKey: "Application Type"
                  },
                  {
                    name: "Discounting(Nos.)", filter: true, filterDataKey: "Financier Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortFinName === "ASC", onActionClick: () => { setFilter({ ...filter, sortFinName: 'ASC', sortBuyerName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortFinName === "DESC", onActionClick: () => { setFilter({ ...filter, sortFinName: "DESC", sortBuyerName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
                  },
                  {
                    name: "Discounting(Amt)", filter: true, filterDataKey: "Amount", sort: [
                      { name: "Sort Lowest", selected: filter.sortAmount === "ASC", onActionClick: () => { setFilter({ ...filter, sortAmount: 'ASC', sortBuyerName: false, sortFinName: false, sortBydate: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Highest", selected: filter.sortAmount === "DESC", onActionClick: () => { setFilter({ ...filter, sortAmount: "DESC", sortBuyerName: false, sortFinName: false, sortBydate: false }); setrefresh(refresh + 1) } }]
                  },
                  { name: "Admin" },
                  { name: "Status" }
                ]}
                data={dbData}
                options={[
                  {
                    name: "View Application", icon: "eye.png", onClick: (index) => {
                      //handleOpeningApplication(index, 0)
                    }
                  },
                ]}
              />
              <Pagination page={page} totalCount={Count} onPageChange={(p) => setpage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 10} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuyerReportsTab