import React, { useState, useEffect } from "react";
import { NewInput, NewSelect } from "../../../utils/newInput";
import { getDocDetails } from "../../../utils/myFunctions";


const ScheduleMeetingModal = ({ schedule, setSchedule, meeturl, setMeeturl }) => {

  const [dbData, setDbData] = useState([])

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([])

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData[0]
      let buyers_credit = JSON.parse(selectedBuyer.buyers_credit)

      for (let index = 0; index < buyers_credit.length; index++) {
        const element = buyers_credit[index];
        if (element.lender_name.toLowerCase().includes("stenn")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (element.lender_name.toLowerCase().includes("modifi")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }


      let termSheetDocument = await getDocDetails(localStorage.getItem("termSheet"))
      let invoiceDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[0])
      let poDocument = await getDocDetails(selectedBuyer.buyersDocId.split(":")[1])

      let otherDocsObj = {}

      if (selectedBuyer.buyerOtherDocs) {
        let buyerOtherDocs = selectedBuyer.buyerOtherDocs.split(":")
        for (let index = 0; index < buyerOtherDocs.length; index++) {
          const element = buyerOtherDocs[index];
          otherDocsObj["otherDoc" + index] = await getDocDetails(element)
          addMoreDoc.push(null)
        }
      }

      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        termSheetDocument, invoiceDocument, poDocument, ...otherDocsObj, buyers_credit
      })

    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  const CloseFn = () => {
    setSchedule(false);
    setMeeturl(true);
  }

  return (
    <>
      <div class={`modal fade ${schedule && "show"}`} style={{ display: "block" }}>
        <div class="modal-dialog">
          <div class="modal-content schedulecard borderRadius">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add title</h5>
              <img className="cursor" src={'/assets/images/cancel-icon.png'} alt="" onClick={() => CloseFn()} />

            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label for="date" class="form-label">Date</label>
                  <input type="Date" class="form-control" id="date" placeholder="dd/mm/yy" />
                </div>
                <div className="col-md-4">
                  <label for="from" class="form-label">From</label>
                  <input type="time" class="form-control" id="from " placeholder="dd/mm/yy" />
                </div>
                <div className="col-md-4">
                  <label for="to" class="form-label">To</label>
                  <input type="time" class="form-control" id="to" placeholder="dd/mm/yy" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label for="date" class="form-label">Meet type</label>
                  <div className="d-flex gap-2">
                    <div className="col-6 meettypebtn text-center pt-2">
                      <i class="fa-solid fa-phone font-size-18"></i>
                    </div>
                    <div className="col-6 meettypebtn text-center pt-2">
                      <i class="fa-solid fa-video font-size-18"></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 locationselect mb-3">
                  <label for="from" class="form-label">Time zone</label>
                  <select class="form-select">
                    <option selected>IST</option>
                  </select>
                </div>
                <div className="col-md-5 locationselect mb-3">
                  <label for="to" class="form-label">Location</label>
                  <select class="form-select">
                    <option selected>Trade reboot fin</option>
                  </select>
                </div>
              </div>
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Add description</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Type"></textarea>
              </div>
              <div className="row align-items-end">
                <div className="col-md-8">
                  <label for="to" class="form-label"></label>
                  <NewInput isAstrix={false} type={"text"} label={"Enter mail / whatsapp / phone"}
                    name={"Expecteddateshipment"} value={data.Purchaseordernumber} error={errors.Purchaseordernumber}
                    onChange={handleChange} />
                </div>
                <div className="col-md-4 locationselect mb-3">
                  <label for="Reminder" class="form-label">Reminder <small>(before)</small></label>
                  <select class="form-select">
                    <option selected>30 minutes</option>
                  </select>
                </div>
              </div>
              <div className="row col-md-12 gap-2">
                <div className="w-auto d-flex scheduledetails align-items-center">
                  <div className="font-size-13">akanshagupta@gmail.com </div> <div><img src={'/assets/images/cancel-icon.png'} alt="" height={10} /></div>
                </div>
                <div className="w-auto d-flex scheduledetails align-items-center">
                  <div className="font-size-13">9856985412 </div> <div><img src={'/assets/images/cancel-icon.png'} alt="" height={10} /></div>
                </div>
                <div className="w-auto d-flex align-items-center gap-2">
                  <div className="font-size-13 text-color1 font-wt-600">View all  </div> <div><img src={'/assets/images/schedule-arrow.png'} alt="" height={10} /></div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 col-md-12 my-3">
                <div><img src={'/assets/images/add-remark.png'} alt="" height={10} /> </div><div className="font-size-13 text-color1 font-wt-600">Add remark  </div>
              </div>
              <button onClick={() => CloseFn()} type="button"
                className={`mx-2 new-btn w-35 py-2 px-2 text-white mb-4`}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ScheduleMeetingModal;