import React, { useEffect, useState } from 'react';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import { connect } from 'react-redux';
import { setshowModalAddComm } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import Header from '../partial/header';
import UserCommodityAddModal from './userCommodityAddModal'
import AdminCommodityAddModal from './adminCommodityAddModal'
import Pagination from 'react-bootstrap/Pagination';
import toastDisplay from '../../utils/toastNotification';
import commImageUrl from '../../utils/makeCommImageUrl'
import Footer from '../partial/footer';


const PAGE_CAPACITY = 6;

const CommoditiesList = ({ userTokenDetails, showModalAddComm, setshowModalAddComm, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  let [selectedComm, setselectedComm] = useState('');
  let [refresh, setrefresh] = useState(0);
  // pagination
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [entryCountField, setEntryCountField] = useState('');
  const [commodityCategories, setcommodityCategories] = useState([])
  const [categoryId, setcategoryId] = useState('');
  const [searchKey, setsearchKey] = useState('')

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (userTypeId == 1) {
      call('GET', 'getcommoditycategory').then((result) => {
        if (result.length) {
          setcommodityCategories(result);
        }
      }).catch((e) => {
        console.log("Error while querying getcommoditycategory:", e);
      })
    }
    else {
      call('POST', 'getusercommoditycategory', { userId: userId }).then((result) => {
        if (result.length) {
          setcommodityCategories(result);
        }
      }).catch((e) => {
        console.log("Error while querying getcommoditycategory:", e);
      })
    }
    getCommodityList()
    // let last = count ? Math.ceil(count / PAGE_CAPACITY) : 0;
    // if ((data[page] && data[page].length) && (page !== last)) {
    // setPagination(count)
    // setshowLoader(false)
    // } else {
    // let reqBody = { "userId": userId, 'userTypeId': userTypeId, page, pageLimit: PAGE_CAPACITY, grid: true }
    // if (categoryId) {
    //   reqBody["commCatId"] = categoryId
    // }
    // call('POST', 'getusercommoditylist', reqBody).then((result) => {
    //   console.log("api result in getusercommoditylist->", result)
    //   data[page] = result.data;
    //   setData(data);
    //   setCount(result.count)
    //   setshowLoader(false)
    //   setPagination(result.count)

    // }).catch((e) => {
    //   setshowLoader(false)
    //   console.log("Error while querying users:", e);
    // })
    // }
    //------------------------------------------------------------------

  }, [page, refresh, showModalAddComm.info.refresh, categoryId]);
  //---------------------------------------------------------------------------------------------------------------------

  function getCommodityList() {
    setshowLoader(true)
    let reqBody = { "userId": userId, 'userTypeId': userTypeId, page, searchKey, pageLimit: PAGE_CAPACITY, grid: true }
    if (categoryId) {
      reqBody["commCatId"] = categoryId
    }
    call('POST', 'getusercommoditylist', reqBody).then((result) => {
      console.log("api result in getusercommoditylist->", result)
      setshowLoader(false)
      if (result.data.length) {
        data[page] = result.data;
        setData(data);
        setCount(result.count)
        setPagination(result.count)
      }
      else {
        setData([]);
      }

    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying users:", e);
    })
  }


  //---------------------------------------------------------------------------------------------------------------------
  // Pagination

  function setPagination(count) {
    const last = Math.ceil(count / PAGE_CAPACITY);
    let items = [];

    items.push(<Pagination.Prev onClick={() => { setshowLoader(true); setPage(1) }} />)
    for (let number = Math.max(1, page - 1); number <= Math.min(page + 1, last); number++) {

      items.push(
        <Pagination.Item key={number} active={number === page.currentPage} onClick={() => { if (number !== page) setshowLoader(true); setPage(number) }}>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next onClick={() => { setshowLoader(true); setPage(last) }} />)

    let startingEntry = ((page - 1) * PAGE_CAPACITY) + 1;
    let endingEntry = page !== last ? (page * PAGE_CAPACITY) : count;
    setItems(items);
    setEntryCountField(<p className='col-md-6' style={{ flexGrow: true }}>Showing {startingEntry + '-' + endingEntry + ' of ' + count} Entities</p>);
  }

  // Pagination End
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function deleteSelectedComm() {
    call('POST', 'deleteCommodity', { "selectedComm": selectedComm, 'userTypeId': userTypeId }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      // setrefresh(refresh + 1)
      toastDisplay("Operation Success", "success", () => window.location.reload())
      setdeleteConfirm(false)
    }).catch((e) => {
      console.log("Error while querying users:", e);
      toastDisplay("Something Went Wrong", "error")
      setdeleteConfirm(false)
    })

  }

  const handleChange = (event) => {
    event.persist();
    setcategoryId(event.target.value);
    setCount(0)
    setPagination(0)
    setPage(1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      getCommodityList()
    }
  };
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid" >
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar context={'admin'} state={'commoditieslist'} userTokenDetails={userTokenDetails} />   {/* Sidebar */}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={!showModalAddComm.modal ? 'Commodity Management' : showModalAddComm.info.status ? "Edit Commodity" : 'Add Commodity'}
              total={'Total ' + count + ' Commodity'}
              showNavBack={showModalAddComm.modal}
              onBackAction={() => setshowModalAddComm(false, {})}
              userTokenDetails={userTokenDetails} />
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

            {!showModalAddComm.modal ? (<div className='row pt-3 justify-content-end'>
              <div className='col-auto'>
                {/* <label >Filter by Commodity Type/Category</label> */}
                <div className=''>
                  <select
                    className={"form-control"} name={"commdCategorys"}
                    value={categoryId} onChange={handleChange}>
                    <option value="" selected>-- Select Category --</option>
                    {
                      commodityCategories.length ? commodityCategories.map((item, index) => {
                        return (<option value={item.id}>{item.category}</option>)
                      }) : ''
                    }
                  </select>
                </div>
              </div>
              <div className='col-auto'>
                {/* <label >Search by Commodity Name</label> */}
                <ul className="range-slider">
                  <li>
                    <input name="search" placeholder="Commodity Name" className="form-control" value={searchKey}
                      onChange={event => setsearchKey(event.target.value)} id="searchInput" onKeyDown={handleKeyPress} />
                    <button type="button"
                      onClick={() => {
                        getCommodityList()
                      }}
                    ><i class="fas fa-search fa-lg"></i></button>
                  </li>
                </ul>
              </div>
              <div className='col-auto align-self-center'>
                <button
                  onClick={() => { setsearchKey(""); setcategoryId('') }}
                  className="btn text-white bg-danger btn-sm">Reset</button>
              </div>
            </div>
            ) : null}

            {!showModalAddComm.modal ? (
              <div className="table-responsive mt-4">
                <table className="table table-striped table-sm">
                  <tbody>
                    {(data.length && data[page] && data[page].length) ? data[page].map((commData) => {
                      return (
                        <tr>
                          <td>
                            <img width="92" src={commImageUrl(commData.filename)} className="img-responsive" alt="" />
                          </td>
                          <td className="align-middle">
                            <span className="top-content">{commData.category ? commData.category : "--"} </span>
                            <span className="bottom-content">Type/Category</span>
                          </td>
                          <td className="align-middle">
                            <span className="top-content">{commData.commodity_pretty_name ? commData.commodity_pretty_name : "--"} </span>
                            <span className="bottom-content">Commodity </span>
                          </td>
                          {(userTypeId !== 1 && userTypeId !== 2) && <><td className="align-middle">
                            <span className="top-content">{commData.total_qty != null ? commData.total_qty : "--"} mt. </span>
                            <span className="bottom-content">Total </span>
                          </td>
                            <td className="align-middle">
                              <span className="top-content"> 0 mt.</span>
                              {/* commData.quantity ? commData.quantity : "--" */}
                              <span className="bottom-content"> In-process</span>
                            </td>
                            <td className="align-middle">
                              <span className="top-content">0 mt.</span>
                              <span className="bottom-content">Processed </span>
                            </td></>}
                          <td className="align-middle text-center">
                            {(aclMap.commodity_access && aclMap.commodity_access.commodity_editUser && aclMap.commodity_access.commodity_editUser.isExist) &&
                              <button className="edit-icon mr-2 text-warning" onClick={() => { setshowModalAddComm(true, { ...commData, status: true, "type": "editCommodityUser" }) }}>
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                              </button>}
                            {(aclMap.commodity_access && aclMap.commodity_access.commodity_delete && aclMap.commodity_access.commodity_delete.isExist) &&
                              <button className="edit-icon mr-2 text-danger" onClick={() => { setdeleteConfirm(true); setselectedComm(commData.id) }}>
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>}
                          </td>
                        </tr>)
                    }) :
                      <>
                        <hr />
                        {(!showLoader) && <h2 className="text-primary text-center mt-5">NO Commodity Found, Start Adding Commodity now</h2>}
                      </>
                    }
                  </tbody>
                </table>
              </div>
            ) : null}


            {/* admin commodity edit modal start */}
            {(showModalAddComm.modal && (aclMap.commodity_access && aclMap.commodity_access.commodity_addAdmin && aclMap.commodity_access.commodity_addAdmin.isExist)) &&
              <AdminCommodityAddModal userTokenDetails={userTokenDetails} />}
            {/* admin commodity edit modal end */}

            {/* user commodity edit modal start */}
            {(showModalAddComm.modal &&
              ((aclMap.commodity_access && aclMap.commodity_access.commodity_addUser && aclMap.commodity_access.commodity_addUser.isExist) ||
                (aclMap.commodity_access && aclMap.commodity_access.commodity_editUser && aclMap.commodity_access.commodity_editUser.isExist))) &&
              <UserCommodityAddModal userTokenDetails={userTokenDetails} />}
            {/* user commodity edit modal end */}

            {(deleteConfirm && (aclMap.commodity_access && aclMap.commodity_access.commodity_delete && aclMap.commodity_access.commodity_delete.isExist)) &&
              <div className={"modal" + (deleteConfirm ? " show" : "")} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Confirmation</h4>
                      <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
                    </div>
                    <div className="modal-body">
                      Please Confirm Delete Operation
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteSelectedComm()}>Delete</button>
                      <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm(false)}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>}

            {!showModalAddComm.modal ? (
              <>
                <hr />
                {(items.length !== 0) && data.length ? <div className="float-right row">
                  {entryCountField}
                  <Pagination className='col-md-6'>{items}</Pagination>
                  <br />
                </div> : null}
              </>
            ) : null}
          </main>
        </div>
      </div >
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
};

const mapStateToProps = state => {

  return {
    showModalAddComm: state.showModalAddComm,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommoditiesList)
