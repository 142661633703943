import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setBookShip } from '../../store/actions/action';
import CountryList from '../utilComponents/countryList'
import { formatDate_Application } from '../../utils/dateFormaters';
import validate from '../../utils/validation_files/bookVesselValidationRules';
import toastDisplay from '../../utils/toastNotification';


const BookShip = ({ userTokenDetails, bookShip, setBookShip, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [currencyData, setcurrencyData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const [data, setData] = useState({
    shipId: bookShip.info.value.id,
    bookedFrom: bookShip.info.value.current_owner,
    bookedTo: userId,
    shipIMO: (bookShip.info && bookShip.info.value && bookShip.info.value.imo_no) ? bookShip.info.value.imo_no : ""
  });

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    setshowLoader(true)

    //------------------------------------------------------------------
    //API Calls
    call('get', 'getcurrencylist').then((result) => {
      setshowLoader(false)
      setcurrencyData(result)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getcurrencylist', e);
    })

    //------------------------------------------------------------------
  }, [])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitForm()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
    setError({ ...error, [event.target.name]: "" });
  }
  console.log('data ', data);

  console.log('bookShip ==>', bookShip);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit Handler
  const submitForm = () => {
    setshowLoader(true)
    call('post', 'shipBook', data).then((result) => {
      setBookShip(false, { refresh: true });
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in shipBook', e);
    })
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Ports Component
  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX return
  return (
    <>
      {bookShip.modal && <div className="modal show" id="CreateContract">
        <div className={"modal-dialog d-flex modal-xl border-inner "} id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Book Ship</h4>
              <button type="button" className="close" onClick={() => setBookShip(false, {})}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="modal-padding">
                <ul className="price-ul">
                  <li>
                    <div className="price-left">{(bookShip.info && bookShip.info.value && bookShip.info.value.imo_no) ? bookShip.info.value.imo_no : "NA"}</div>
                    <div className="price-right">IMO No. </div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.ship_name) ? bookShip.info.value.ship_name : "NA"}</div>
                    <div className="price-right"> Name of Ship</div>
                  </li>
                  <li>
                    <div className="price-left">{(bookShip.info && bookShip.info.value && bookShip.info.value.type) ? bookShip.info.value.type : "NA"}</div>
                    <div className="price-right"> Ship Type</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.date_manufacture) ? formatDate_Application(bookShip.info.value.date_manufacture) : "NA"}</div>
                    <div className="price-right">Date of Manufacture</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.registry_port) ? bookShip.info.value.registry_port : "NA"}</div>
                    <div className="price-right">Port of Registry</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.open_from_country) ? bookShip.info.value.open_from_country : "NA"}</div>
                    <div className="price-right">Open From</div>
                  </li>
                  <li>
                    <div className="price-left">{(bookShip.info && bookShip.info.value && bookShip.info.value.ship_status / 1 === 1) ? "Availabe for Booking" : "NA"}</div>
                    <div className="price-right">Ship Status</div>
                  </li>
                  <li>
                    <div className="price-left">{(bookShip.info && bookShip.info.value && bookShip.info.value.lengthv) ? bookShip.info.value.lengthv : "NA"}</div>
                    <div className="price-right">Length</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.breadthv) ? bookShip.info.value.breadthv : "NA"}</div>
                    <div className="price-right">Breadth</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.depth) ? bookShip.info.value.depth : "NA"}</div>
                    <div className="price-right">Depth</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.draft) ? bookShip.info.value.draft : "NA"}</div>
                    <div className="price-right">Draft</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.dead_weight) ? bookShip.info.value.dead_weight : "NA"}</div>
                    <div className="price-right">Deadweight</div>
                  </li>
                  <li>
                    <div className="price-left">{(bookShip.info && bookShip.info.value && bookShip.info.value.gross) ? bookShip.info.value.gross : "NA"}</div>
                    <div className="price-right">Gross Tons</div>
                  </li>
                  <li>
                    <div className="price-left"> {(bookShip.info && bookShip.info.value && bookShip.info.value.net) ? bookShip.info.value.net : "NA"}</div>
                    <div className="price-right">Net Tons</div>
                  </li>
                </ul>

                <div className="col-md-12 mt-3">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label>Charter Type {astrix} </label>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="charterType" id="charterType1" value="1" checked={data.charterType / 1 === 1 ? true : false} onChange={handleChange} className="position-absolute" />
                            <label className="form-check-label" for="charterType1">Voyage Charter {astrix} </label>
                          </div>
                          <div className="form-check form-check-inline ml-4">
                            <input className="form-check-input" type="radio" name="charterType" id="charterType2" value="2" checked={data.charterType / 1 === 2 ? true : false} onChange={handleChange} className="position-absolute" />
                            <label className="form-check-label" for="charterType2">Time Charter {astrix} </label>
                          </div>
                        </div>
                        {error.charterType ? <div class="text-danger error-contract ml-3" >{error.charterType}</div> : ''}
                        {error.shipIMO ? <div class="text-danger error-contract ml-3" >{error.shipIMO}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-md-12">Price {astrix} </label>
                    <div className="col-md-6">
                      <input type="number" step="0.01" name="price" value={data.price} placeholder="Enter Price" className={" form-control" + (!error.price ? '' : ' border-danger')} onChange={handleChange} />
                      {error.price ? <div class="text-danger error-contract">{error.price}</div> : ''}
                    </div>

                    <div className="col-md-6">
                      <select className={" form-control" + (!error.currency ? '' : ' border-danger')} name="currency" value={data.currency} onChange={handleChange} >
                        <option value="" selected>--Select Currency--</option>
                        {
                          currencyData.map((currency) => {
                            return (
                              <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                            )
                          })
                        }
                      </select>
                      {error.currency ? <div class="text-danger error-contract">{error.currency}</div> : ''}
                    </div>
                  </div>
                </div>
                {(data.charterType / 1 === 1) && <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Loading Country {astrix} </label>
                        <div className="col-md-12">
                          <select className={" form-control" + (!error.loadingCountry ? '' : ' border-danger')} name="loadingCountry" value={data.loadingCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                            <option value="" selected> Select country </option>
                            <CountryList />
                          </select>
                          {error.loadingCountry ? <div class="text-danger error-contract">{error.loadingCountry}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Loading Port {astrix} </label>
                        <div className="col-md-12">
                          <select className={" form-control" + (!error.loadingPort ? '' : ' border-danger')} name="loadingPort" value={data.loadingPort} onChange={handleChange}>
                            <option value="" selected> Select loading port </option>
                            {portLoading.map((portLoading) => {
                              return (
                                <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                              )
                            })}
                          </select>
                          {error.loadingPort ? <div class="text-danger error-contract">{error.loadingPort}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Unloading Country {astrix} </label>
                        <div className="col-md-12">
                          <select className={" form-control" + (!error.unloadingCountry ? '' : ' border-danger')} name="unloadingCountry" value={data.unloadingCountry} onChange={(event) => { handleChange(event); unloadPort(event); }}>
                            <option value="" selected> Select country </option>
                            <CountryList />
                          </select>
                          {error.unloadingCountry ? <div class="text-danger error-contract">{error.unloadingCountry}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Unloading Port {astrix} </label>
                        <div className="col-md-12">
                          <select className={" form-control" + (!error.unloadingPort ? '' : ' border-danger')} name="unloadingPort" value={data.unloadingPort} onChange={handleChange}>
                            <option value="" selected>Select Unloading port</option>
                            {
                              portUnloading.map((portUnloading) => {
                                return (
                                  <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                                )
                              })
                            }
                          </select>
                          {error.unloadingPort ? <div class="text-danger error-contract">{error.unloadingPort}</div> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                {(data.charterType / 1 === 2) && <>
                  <div className="col-md-12">
                    <div className="row form-group">
                      <div className="col-md-6">
                        <label >Start Date {astrix} </label>
                        <input type="datetime-local" name="charterStart" value={data.charterStart} className={" form-control" + (!error.charterStart ? '' : ' border-danger')} onChange={handleChange} />
                        {error.charterStart ? <div class="text-danger error-contract">{error.charterStart}</div> : ''}
                      </div>
                      <div className="col-md-6">
                        <label >End Date {astrix} </label>
                        <input type="datetime-local" name="charterEnd" value={data.charterEnd} className={" form-control" + (!error.charterEnd ? '' : ' border-danger')} onChange={handleChange} />
                        {error.charterEnd ? <div class="text-danger error-contract">{error.charterEnd}</div> : ''}
                      </div>
                    </div>
                  </div>
                </>}

              </div>
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => { setBookShip(false, {}); }}>Cancel</button>
              <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Submit</button>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    bookShip: state.bookShip
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBookShip: (flag, data) => { dispatch(setBookShip({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookShip);
