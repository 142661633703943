import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { setShowViewProfileModal } from '../../store/actions/action';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import Footer from '../partial/footer';
import columns from '../dataTablesColumsMap/channelPartnerUserList';
import ManageChannelProfile from './manageChannelProfile';
import ManageLeads from '../admin/manageLeads';
import ChannelPartnerProfileDetails from './channelPartnerProfileDetails';

const ChannelPartnerList = ({ userTokenDetails, setShowViewProfileModal, showViewProfileModal }) => {

  const [tab, setTab] = useState(0);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  useEffect(() => {

  }, [])

  console.log('showViewProfileModal => ', showViewProfileModal);

  //-----------------------------------------------------------------------------------------------------------------------------------------
  //JSX
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className='row'>
          <Sidebar state={'channelPartnerUser'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 "} id="app-main-div">
            <Header
              title={showViewProfileModal.modal ? 'Profile Details' : 'Manage Channel / Franchise Partner'}
              userTokenDetails={userTokenDetails}
            />
            {showViewProfileModal.modal ? <ChannelPartnerProfileDetails userTokenDetails={userTokenDetails} /> :
              <>
                <ul className="nav nav-tabs-custom mt-2" id="myTab" role="tablist">
                  <li>
                    <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => { setTab(0); }}>All Users</a>
                  </li>
                  <li>
                    <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => { setTab(1); }}>New Leads</a>
                  </li>
                  <li>
                    <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => { setTab(2); }}>Approved Leads</a>
                  </li>
                  {/* <li>
                    <a className={"nav-link" + (tab === 3 ? " active show" : "")} onClick={() => { setTab(3); }}>Rejected Leads</a>
                  </li> */}
                </ul>

                <div className="d-flex">
                  {tab === 0 &&
                    <div className={"tab-pane active show col-md-12"}>
                      <ManageChannelProfile userTokenDetails={userTokenDetails} channelPartnerUser={true} />
                    </div>
                  }
                  {tab === 1 &&
                    <div className={"tab-pane active show col-md-12"}>
                      <ManageLeads userTokenDetails={userTokenDetails} status="request" />
                    </div>
                  }
                  {tab === 2 &&
                    <div className={"tab-pane active show col-md-12"}>
                      <ManageLeads userTokenDetails={userTokenDetails} status="approved" />
                    </div>
                  }
                  {tab === 3 &&
                    <div className={"tab-pane active show col-md-12"}>
                      <ManageLeads userTokenDetails={userTokenDetails} status="rejected" />
                    </div>
                  }
                </div></>}

          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return {
    showViewProfileModal: state.showViewProfileModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerList)