const columns = [
  {
    name: 'Buyer Name',
    selector: 'buyerName',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
    grow: 2
  },
  {
    name: 'Email',
    selector: 'buyerEmail',
    sortable: true,
    grow: 2
  },
  {
    name: 'Contact No.',
    selector: 'buyerContact',
    sortable: false,
  },
  {
    name: 'Address',
    selector: 'buyerAddress',
    sortable: true,
    grow: 2
  },
  {
    name: 'Country',
    selector: 'buyerCountry',
    sortable: true,
  },
  {
    name: 'DUNS No',
    selector: 'buyerDUNSNo',
    sortable: true,
  },
  {
    name: 'Annual Sale(Previous Year)',
    selector: 'annualSale',
    sortable: false,
  },
  {
    name: 'Expected Annual Sale(Current Year)',
    selector: 'expectedAnnualSale',
    sortable: false,
  },
  {
    name: 'Previous Invoice Doc',
    selector: 'invoiceDoc',
    sortable: false
  },
  {
    name: 'Previous PO Doc',
    selector: 'poDoc',
    sortable: false
  },
  // {
  //   name: 'Admin Approval',
  //   selector: 'admin_status',
  //   sortable: false,
  //   cell: row =>
  //     (row.admin_status === 0) ? "Pending" :
  //       (row.admin_status === 1) ? "Rejected" :
  //         (row.admin_status === 2) ? "Approved" : "NA",
  //   conditionalCellStyles: [
  //     {
  //       when: row => (row.admin_status === 0),
  //       style: {
  //         backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.admin_status === 1),
  //       style: {
  //         backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.admin_status === 2),
  //       style: {
  //         backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
  //         color: 'white',
  //       },
  //     }
  //   ]
  // },
  // {
  //   name: 'Admin Comment',
  //   selector: 'admin_comment',
  //   sortable: false,
  //   grow: 2,
  // },
  {
    name: 'Actions',
    selector: 'action',
    sortable: false,
    grow: 2
  }
];

export default columns;