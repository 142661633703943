import React from 'react'
import { useState } from 'react'
import call from '../../../service'
import { ExportExcel, getInitials, removeDuplicates } from '../../../utils/myFunctions'
import CustomLineChart from '../../CRM/CustomLineChart'
import { useEffect } from 'react'
import PieChartComponent from './PieChartComponent'
import Pagination from '../../InvoiceDiscounting/contract/components/pagination'
import { NewTable } from '../../../utils/newTable'
import Filter from '../../InvoiceDiscounting/components/Filter'
import moment from 'moment'

const discontingConfig = [
  { dataKey: 'discounting', fill: '#5CB8D3', display: 'Discounting' }
]
const piechartColor = ['#FF774C', '#FFB801', '#F26191', '#9A60B4', '#5470C6', '#91CC75', '#EE6666', '#73C0DE', '#EA7CCC', '#C65454']
const lcInvLineConfig = [
  { dataKey: 'approved', fill: '#2ECC71', display: 'Approved' },
  { dataKey: 'pending', fill: '#FFB801', display: 'Pending' },
  { dataKey: 'rejected', fill: '#FE4141', display: 'Rejected' },
]

const lcInvLineValuesConfig = [
  { dataKey: 'approvedAmount', fill: '#2ECC71', display: 'Approved' },
  { dataKey: 'pendingAmount', fill: '#FFB801', display: 'Pending' },
  { dataKey: 'rejectedAmount', fill: '#FE4141', display: 'Rejected' },
]

const ApplicationReportsTab = ({ dateRange, userIds, userTokenDetails }) => {
  const [lclimTab, setlclimTab] = useState('Values')
  const [lclimitGraph, setlclimitGraph] = useState([])
  const [lclimitoverallGraph, setlclimitoverallGraph] = useState([])

  const [invlimTab, setinvlimTab] = useState('Values')
  const [invlimitGraph, setinvlimitGraph] = useState([])
  const [invlimitoverallGraph, setinvlimitoverallGraph] = useState([])

  const [invFinTab, setinvFinTab] = useState('Values')
  const [invFinGraph, setinvFinGraph] = useState([])
  const [invFinoverallGraph, setinvFinoverallGraph] = useState([])

  const [showLoader, setshowLoader] = useState(false)
  const [discountingGraph, setdiscountingGraph] = useState([])
  const [discountingoverallGraph, setdiscountingoverallGraph] = useState([])
  const [graphConfiguration, setGraphConfiguration] = useState({
    applicationStageGraphMode: true
  })
  const [filterData, setfilterdata] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [refresh, setrefresh] = useState(0)
  const [Count, setCount] = useState(0)
  const [page, setpage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [filteredSearch, setFilteredSearch] = useState([])
  const [overalldata, setoveralldata] = useState([])
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [graphData, setGraphData] = useState({})
  const [discountingLoader, setdiscountingLoader] = useState(false)
  const [approvedApplicationsLoader, setApprovedApplicationLoaders] = useState(false)
  const [applicationHistoryLoader, setapplicationHistoryLoader] = useState(false)
  const [lcdiscLoader, setlcdiscLoader] = useState(false)
  const [invdiscLoader, setinvdiscLoader] = useState(false)
  const [lclimitLoader, setlclimitLoader] = useState(false)
  const [invlimitLoader, setinvlimitLoader] = useState(false)
  const [countrywiseApproved, setcountrywiseApproved] = useState([])
  const [taskManagerLoader, setTaskManagerLoader] = useState(false)

  const userId = userTokenDetails?.user_id
  const typeId = userTokenDetails?.type_id
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  const onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId

  const getAdminINVLimitGraph = () => {
    setinvlimitLoader(true)
    call('POST', 'getAdminINVLimitGraph', { from: dateRange.from, to: dateRange.to, userIds, onlyShowForUserId }).then(result => {
      setinvlimitoverallGraph(result)
      setinvlimitGraph(result)
      setinvlimitLoader(false)
    }).catch(err => {
      setinvlimitLoader(false)
      console.log('error in getAdminINVLimitGraph', err);
    })
  }
  const getAdminLCLimitGraph = () => {
    setlclimitLoader(true)
    call('POST', 'getAdminLCLimitGraph', { from: dateRange.from, to: dateRange.to, userIds, onlyShowForUserId }).then(result => {
      setlclimitoverallGraph(result)
      setlclimitGraph(result)
      setlclimitLoader(false)
    }).catch(err => {
      setlclimitLoader(false)
      console.log('error in getAdminLCLimitGraph', err);
    })
  }
  const getDiscountingGraph = () => {
    setdiscountingLoader(true)
    call('POST', 'getDiscountingGraph', { from: dateRange.from, to: dateRange.to, userIds, onlyShowForUserId }).then(result => {
      setdiscountingGraph(result)
      setdiscountingoverallGraph(result)
      setdiscountingLoader(false)
    }).catch(err => {
      setdiscountingLoader(false)
      console.log('error in getAdminLCLimitGraph', err);
    })
  }
  const getAdminINVFinGraph = () => {
    setinvdiscLoader(true)
    call('POST', 'getAdminINVFinGraph', { from: dateRange.from, to: dateRange.to, userIds, onlyShowForUserId }).then(result => {
      setinvFinoverallGraph(result)
      setinvFinGraph(result)
      setinvdiscLoader(false)
    }).catch(err => {
      setinvdiscLoader(false)
      console.log('error in getAdminINVLimitGraph', err);
    })
  }
  const getApprovedApplicationByCountry = () => {
    setApprovedApplicationLoaders(true)
    call('POST', 'getApprovedApplicationByCountry', { from: dateRange.from, to: dateRange.to, userIds, onlyShowForUserId }).then(result => {
      setcountrywiseApproved(result)
      setApprovedApplicationLoaders(false)
    }).catch(err => {
      setApprovedApplicationLoaders(false)
      console.log('error in getAdminINVLimitGraph', err);
    })
  }
  useEffect(() => {
    getAdminINVLimitGraph()
    getAdminLCLimitGraph()
    getDiscountingGraph()
    getAdminINVFinGraph()
    getApprovedApplicationByCountry()
  }, [dateRange, userIds])
  useEffect(() => {
    getApplications()
  }, [refresh, page, filterData])
  useEffect(() => {
    call('POST', 'getApplicationsFilters', {}).then(res => {
      console.log("getApplicationsFilters then", res);
      setfilterdata(res)
      setFilteredSearch(res)
    }).catch(err => { })

  }, [])
  const getApplications = () => {
    let objectAPI = {
      currentPage: page,
      // exactMatchId: editBuyer || undefined,
      ...filter,
      userIds,
      onlyShowForUserId
    }
    setshowLoader(true)
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(element.accordianId === "hsCodes" ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getApplications', objectAPI).then((result) => {
      setshowLoader(false)
      console.log('running getBuyersDetail api-->', result, formatDataForTable(result.message));
      setdbData(formatDataForTable(result.message))
      setoveralldata(result.message)
      setCount(result.total_records);
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
  }
  useEffect(() => {
    setTaskManagerLoader(true)
    call('POST', 'getStageWiseApplicationStats', {
      applicationStageFrom: dateRange.from,
      applicationStageTo: dateRange.to,
      onlyShowForUserId,
      userIds
    }).then(res => {
      console.log("getTaskManagerGraphData response===============>", res);
      setTaskManagerLoader(false)
      let activeApplicationStageFunnelGraphData = []
      activeApplicationStageFunnelGraphData[0] = { "name": "Finance Limit", "value": res.activeUserApplicationSummary["Finance Limit"]["invoice"] + res.activeUserApplicationSummary["Finance Limit"]["lc"] }
      activeApplicationStageFunnelGraphData[1] = { "name": "Quote", "value": res.activeUserApplicationSummary["Quote"]["invoice"] + res.activeUserApplicationSummary["Quote"]["lc"] }
      activeApplicationStageFunnelGraphData[2] = { "name": "Termsheet/Contract", "value": res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"] + res.activeUserApplicationSummary["Termsheet/Contract"]["lc"] }
      activeApplicationStageFunnelGraphData[3] = { "name": "Finance", "value": res.activeUserApplicationSummary["Finance"]["invoice"] + res.activeUserApplicationSummary["Finance"]["lc"] }
      activeApplicationStageFunnelGraphData[4] = { "name": "Agreement", "value": res.activeUserApplicationSummary["Agreement"]["invoice"] }
      activeApplicationStageFunnelGraphData[5] = { "name": "Approved", "value": res.activeUserApplicationSummary["Approved"]["invoice"] + res.activeUserApplicationSummary["Approved"]["lc"] }

      let activeApplicationStageFunnelGraphDataAmt = []
      activeApplicationStageFunnelGraphDataAmt[0] = { "name": "Finance Limit", "value": res.activeUserApplicationSummaryAmount["Finance Limit"]["invoice"] + res.activeUserApplicationSummaryAmount["Finance Limit"]["lc"] }
      activeApplicationStageFunnelGraphDataAmt[1] = { "name": "Quote", "value": res.activeUserApplicationSummaryAmount["Quote"]["invoice"] + res.activeUserApplicationSummaryAmount["Quote"]["lc"] }
      activeApplicationStageFunnelGraphDataAmt[2] = { "name": "Termsheet/Contract", "value": res.activeUserApplicationSummaryAmount["Termsheet/Contract"]["invoice"] + res.activeUserApplicationSummaryAmount["Termsheet/Contract"]["lc"] }
      activeApplicationStageFunnelGraphDataAmt[3] = { "name": "Finance", "value": res.activeUserApplicationSummaryAmount["Finance"]["invoice"] + res.activeUserApplicationSummaryAmount["Finance"]["lc"] }
      activeApplicationStageFunnelGraphDataAmt[4] = { "name": "Agreement", "value": res.activeUserApplicationSummaryAmount["Agreement"]["invoice"] }
      activeApplicationStageFunnelGraphDataAmt[5] = { "name": "Approved", "value": res.activeUserApplicationSummaryAmount["Approved"]["invoice"] + res.activeUserApplicationSummaryAmount["Approved"]["lc"] }

      let activeApplicationStageTableData = []
      activeApplicationStageTableData[0] = ["Invoice Discounting", res.activeUserApplicationSummary["Finance Limit"]["invoice"], res.activeUserApplicationSummary["Quote"]["invoice"], res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"],
        res.activeUserApplicationSummary["Finance"]["invoice"], res.activeUserApplicationSummary["Agreement"]["invoice"], res.activeUserApplicationSummary["Approved"]["invoice"]]
      activeApplicationStageTableData[1] = ["LC Discounting", res.activeUserApplicationSummary["Finance Limit"]["lc"], res.activeUserApplicationSummary["Quote"]["lc"], res.activeUserApplicationSummary["Termsheet/Contract"]["lc"],
        res.activeUserApplicationSummary["Finance"]["lc"], 0, res.activeUserApplicationSummary["Approved"]["lc"]]

      let activeApplicationStageTableDataAmt = []
      activeApplicationStageTableDataAmt[0] = ["Invoice Discounting", res.activeUserApplicationSummaryAmount["Finance Limit"]["invoice"], res.activeUserApplicationSummaryAmount["Quote"]["invoice"], res.activeUserApplicationSummaryAmount["Termsheet/Contract"]["invoice"],
        res.activeUserApplicationSummary["Finance"]["invoice"], res.activeUserApplicationSummaryAmount["Agreement"]["invoice"], res.activeUserApplicationSummaryAmount["Approved"]["invoice"]]
      activeApplicationStageTableDataAmt[1] = ["LC Discounting", res.activeUserApplicationSummaryAmount["Finance Limit"]["lc"], res.activeUserApplicationSummaryAmount["Quote"]["lc"], res.activeUserApplicationSummaryAmount["Termsheet/Contract"]["lc"],
        res.activeUserApplicationSummary["Finance"]["lc"], 0, res.activeUserApplicationSummaryAmount["Approved"]["lc"]]

      setGraphData({
        ...res, activeApplicationStageFunnelGraphData, activeApplicationStageFunnelGraphDataAmt, activeApplicationStageTableData, activeApplicationStageTableDataAmt
      })
    }).catch(e => {
      setTaskManagerLoader(false)
    })
  }, [dateRange, userIds, graphConfiguration])
  const CustomTooltip = ({ payload }) => {
    console.log('PAyloaddddd', payload?.[0]?.payload?.["fill"])
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10,
        }}>
        <p className='font-wt-600  font-size-14 m-0 p-0' style={{ color: payload?.[0]?.payload?.["fill"] }}>{`${payload?.[0]?.payload?.["name"]} - ${payload?.[0]?.payload?.["totalCount"]}`}</p>

      </div>
    );
  }
  function formatDataForTable(data) {

    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
      let status = ''
      if (item.buyers_credit === null) {
        status = "Waiting for financer quote"
      }
      else if (item.buyers_credit != null && item.selectedFinancier == null) {
        status = 'Quote Recieved'
      } else if (item.termSheetSignedByBank && item.termSheetSignedByExporter) {
        status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Quote Locked" : "Limit Approved"
      } else if (item.termSheetSignedByExporter) {
        status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Contract Signed by Exporter" : 'TermSheet signed by exporter'
      }
      else if (item.selectedFinancier) {
        status = 'Quote Selected by exporter'
      } else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation') {
        if (buyers_credit.every(data => data.status === 'denied')) {
          status = 'Limit Denied'
        }
      } else if (item.invRefNo != 'lc_discounting' && item.invRefNo != 'lc_confirmation' && item.invRefNo != 'sblc') {

        if (buyers_credit.every(data => data.financierAction === 'deny')) {
          status = 'Limit Denied'
        }
      }
      let amount = ""
      if (item.finance_type === 'Invoice Discounting') {
        amount += `${item.requiredLimit ? `${item.requiredLimitCurrency ? item.requiredLimitCurrency : ''} ${item.requiredLimit}` : 'NA'}`
      } else {
        if (item.ocrFields) {
          const ocrfields = JSON.parse(item.ocrFields)
          amount += ocrfields["32B"]
        }

      }
      let allDeniedCount = 0
      let allApprovedCount = 0
      let buyersCredit = []

      if (item.buyers_credit) {
        buyersCredit = JSON.parse(item.buyers_credit)
        for (let index = 0; index < buyersCredit.length; index++) {
          const j = buyersCredit[index];
          if (j.financierAction === "deny" || j.status === 'denied') {
            allDeniedCount += 1
          }
          else {
            allApprovedCount += 1
          }
        }
      }
      console.log('financers', item?.selectedLenderName);
      let selectedLenderName = item.finance_type === 'Invoice Discounting' ? item.selectedLenderName ? item.selectedLenderName.split(",") : [] : item.selectedLCLenderName ? removeDuplicates(item.selectedLCLenderName.split(",")) : []
      let selectedLenderId = item.finance_type === 'Invoice Discounting' ? item.selectedLenderId ? item.selectedLenderId.split(",") : [] : item.selectedLCLenderId ? removeDuplicates(item.selectedLCLenderId.split(",")) : []
      if (allDeniedCount / 1 == selectedLenderName.length / 1) {
        status = 'Limit Rejected'
      }

      row[0] = item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : 'NA'
      row[1] = item.company_name ? item.company_name : 'NA'
      row[2] = item.buyerName ? item.buyerName : 'NA'
      row[3] = <span className='text-color1'>{item.finance_type === 'Invoice Discounting' ? item.invRefNo ? item.invRefNo : `${getInitials(item.buyerName)}/${item.buyerCountry ? item.buyerCountry : '-'}/${item.requiredLimit ? item.requiredLimit : '-'}` : item.id ? item.id : '-'}</span>
      row[4] = item.finance_type
      row[5] = item.FinancierName ? <span className='text2ECC71'>{item.FinancierName}</span> :
        < >
          <div
            className=''
          >
            {selectedLenderName.length ? selectedLenderName.map((key, j) => {
              let isApprovedByFinancier = buyersCredit?.filter(i => {
                console.log('condtion', i.lender_id / 1, selectedLenderId[j] / 1);
                if ((i.lender_id / 1 == selectedLenderId[j] / 1) && (i.financierAction === "Approved" || i.status === "approved")) {
                  return i
                }
              })?.[0]
              console.log('isApproved', isApprovedByFinancier);
              let isRejectedByFinancier = buyersCredit?.filter(i => {
                if ((i.lender_id / 1 == selectedLenderId[j] / 1) && (i.financierAction === "deny" || i.status === 'denied')) {
                  return i
                }
              })?.[0]
              return (
                <div
                  className={`position-relative cursor ${isApprovedByFinancier ? " text2ECC71 " : ''} ${isRejectedByFinancier ? ' text-danger ' : '   '}`} >
                  <label className='font-wt-600 font-size-22 position-absolute cursor' style={{ top: "-0.8rem" }} >{`.`}</label>
                  <label className='ml-3 font-size-14  font-wt-600 cursor' >{`${key}`}</label>
                </div>
              )
            }) : "NA"}
          </div>
        </>
      row[6] = amount
      row[7] = <label className='text-color1 font-wt-600 cursor' onClick={() => {
        if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
          handleTransactionPopupLC({
            buyerId: item.buyerId,
            applicationId: item.tblId
          })
        } else {
          handleTransactionPopupINV({
            buyerId: item.buyerId,
            applicationId: item.tblId,
            invRefNo: item.invRefNo
          })
        }
      }}>{status}</label>
      row[7] = allDeniedCount / 1 == selectedLenderName.length / 1 ?
        <button type="button"
          onClick={() => {
            if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
              handleTransactionPopupLC({
                buyerId: item.buyerId,
                applicationId: item.tblId
              })
            } else {
              handleTransactionPopupINV({
                buyerId: item.buyerId,
                applicationId: item.tblId,
                invRefNo: item.invRefNo
              })
            }
          }}
          class={`rejected text-white border-0 `}>
          {"Rejected"}
        </button> :
        moment().diff(moment(item.updated_at), "days") >= 60 &&
          (!item.termSheetSignedByExporter || !item.termSheetSignedByBank) ?
          <button type="button"
            onClick={() => {
              if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
                handleTransactionPopupLC({
                  buyerId: item.buyerId,
                  applicationId: item.tblId
                })
              } else {
                handleTransactionPopupINV({
                  buyerId: item.buyerId,
                  applicationId: item.tblId,
                  invRefNo: item.invRefNo
                })
              }
            }}
            class={`expiredStatus text-white border-0 `}>
            {"Expired"}
          </button> :
          (item.termSheetSignedByExporter && item.termSheetSignedByBank) ?
            <button type="button"
              onClick={() => {
                if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
                  handleTransactionPopupLC({
                    buyerId: item.buyerId,
                    applicationId: item.tblId
                  })
                } else {
                  handleTransactionPopupINV({
                    buyerId: item.buyerId,
                    applicationId: item.tblId,
                    invRefNo: item.invRefNo
                  })
                }
              }}
              class={`approved text-white border-0 `}>
              {"Approved"}
            </button> :
            <button type="button"
              onClick={() => {
                if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
                  handleTransactionPopupLC({
                    buyerId: item.buyerId,
                    applicationId: item.tblId
                  })
                } else {
                  handleTransactionPopupINV({
                    buyerId: item.buyerId,
                    applicationId: item.tblId,
                    invRefNo: item.invRefNo
                  })
                }
              }}
              class={`inprogress text-white border-0 `}>
              {"Inprogress"}
            </button>

      tableData.push(row)
      row = []
    })
    return tableData
  }
  async function handleTransactionPopupLC(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.buyerId, applicationId: itemData.applicationId
    })
    console.log("getTransactionHistoryForLC api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  async function handleTransactionPopupINV(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.buyerId,
      applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }
  function handleOpeningApplication(indx, tabIndx) {
    let item = overalldata[indx]
    if (item.finance_type == 'Invoice Discounting') {
      if (item.buyers_credit == null || item.selectedFinancier == null) {
        window.location = `/seeQuotes?buyer=${item.buyerId}`;
        localStorage.setItem("applicationId", item.tblId)
        localStorage.setItem("invIfAppliedNo", item.invRefNo)
        localStorage.setItem("isAdmin", true)
        localStorage.setItem("defaultTabForAdmin", tabIndx)
        localStorage.setItem("selectedLenderName", item.FinancierName)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
      } else {
        window.location = `/sign-agreement`
        localStorage.setItem("item", JSON.stringify(item))
        localStorage.setItem("isAdminUser", true)
        localStorage.setItem("defaultSetTab", tabIndx)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
        localStorage.setItem("headerBreadCum", "Invoice Discounting > Finance > Application Details")
      }
    } else {
      window.location = `/LcSeequotes?id=${item.tblId}`
      localStorage.setItem("isAdmin", true)
      localStorage.setItem("defaultTabForAdmin", tabIndx)

      // setting manual user id & email for user
      localStorage.setItem("manualUserId", item.userId)
      localStorage.setItem("manualUserEmail", item.email_id)
    }
  }
  return (
    <div>
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Discounting</label>

                  </div>


                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="pt-4">
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} bardataConfig={discontingConfig} formatterFunction={(value, name) => ["$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value), name]} data={discountingGraph} xDataKey={"label"} tab={"Values"} type={"Sum"} loader={discountingLoader} reloadFn={getDiscountingGraph} />
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>No. of Approved application by import country</label>

                  </div>
                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 '>
              <PieChartComponent hideDollar={true}
                paddingAngle={2}
                loader={approvedApplicationsLoader} reloadFn={getApprovedApplicationByCountry}
                customToolTip={<CustomTooltip />}
                innerRadius={30} data={countrywiseApproved} dataKey="totalCount" colors={piechartColor} cornerRadius={0} />
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <div className='d-flex align-items-center '>
                  <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Active Applications (Application stage)</label>

                </div>
                <div className='w-20 align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => {
                        setGraphConfiguration({
                          ...graphConfiguration,
                          applicationStageGraphMode: !graphConfiguration.applicationStageGraphMode
                        })
                      }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => { }}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          {!graphConfiguration.applicationStageGraphMode ? (
            <>
              <div>
                <NewTable
                  disableAction={true}
                  data={graphData.activeApplicationStageTableData || []}
                  columns={[{ name: "Active Users(Application Stage)" }, { name: "Finance Limit" }, { name: "Quote" },
                  { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                />
              </div>
              <div className='mt-4'>
                <NewTable
                  disableAction={true}
                  data={graphData.activeApplicationStageTableDataAmt || []}
                  columns={[{ name: "Active Users(Application Stage)" }, { name: "Finance Limit" }, { name: "Quote" },
                  { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                />
              </div>
            </>
          ) : (
            <div className='h-90 d-flex flex-row pt-5 pb-4 mx-0' >
              <div className='col-6'>
                {true ? (
                  <>
                    <div className='text-center h-90'>
                      <img src='/assets/images/Active users application stage.png' width={"70%"} height={"85%"} />
                    </div>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.activeApplicationStageFunnelGraphDataAmt?.[0]?.value ? `$ ${Intl.NumberFormat('en-US', { notation: 'compact' }).format(graphData?.activeApplicationStageFunnelGraphDataAmt?.[0]?.value)}` : ""}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.activeApplicationStageFunnelGraphDataAmt?.[1]?.value ? `$ ${Intl.NumberFormat('en-US', { notation: 'compact' }).format(graphData?.activeApplicationStageFunnelGraphDataAmt?.[1]?.value)}` : ""}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.activeApplicationStageFunnelGraphDataAmt?.[2]?.value ? `$ ${Intl.NumberFormat('en-US', { notation: 'compact' }).format(graphData?.activeApplicationStageFunnelGraphDataAmt?.[2]?.value)}` : ""}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.activeApplicationStageFunnelGraphDataAmt?.[3]?.value ? `$ ${Intl.NumberFormat('en-US', { notation: 'compact' }).format(graphData?.activeApplicationStageFunnelGraphDataAmt?.[3]?.value)}` : ""}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.activeApplicationStageFunnelGraphDataAmt?.[4]?.value ? `$ ${Intl.NumberFormat('en-US', { notation: 'compact' }).format(graphData?.activeApplicationStageFunnelGraphDataAmt?.[4]?.value)}` : ""}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.activeApplicationStageFunnelGraphDataAmt?.[5]?.value ? `$ ${Intl.NumberFormat('en-US', { notation: 'compact' }).format(graphData?.activeApplicationStageFunnelGraphDataAmt?.[5]?.value)}` : ""}</label>

                  </>
                ) : null}
                <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Amount ($)</label>
              </div>

              <div className='col-6'>
                {true ? (
                  <>
                    <div className='text-center h-90'>
                      <img src='/assets/images/Active users application stage.png' width={"70%"} height={"85%"} />
                    </div>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.activeApplicationStageFunnelGraphData?.[0]?.value}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.activeApplicationStageFunnelGraphData?.[1]?.value}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.activeApplicationStageFunnelGraphData?.[2]?.value}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.activeApplicationStageFunnelGraphData?.[3]?.value}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.activeApplicationStageFunnelGraphData?.[4]?.value}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.activeApplicationStageFunnelGraphData?.[5]?.value}</label>

                  </>
                ) : null}
                <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>No. of Applications</label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>LC Discounting</label>

                  </div>

                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="pt-4">
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={lcInvLineConfig} formatterFunction={(value, name) => [value, name]} data={[]} xDataKey={"label"} isLegend={true} type={"Sum"} loader={lcdiscLoader} reloadFn={() => { }} />
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Invoice Discounting</label>

                  </div>
                  <div >
                    <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setinvFinTab('Values')
                        }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Values ($)</button>
                      </li>
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setinvFinTab('Count')
                        }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">Count</button>
                      </li>
                    </ul>
                  </div>
                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="pt-4">
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => invFinTab === 'Values' ? "$ " + "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value} bardataConfig={invFinTab === 'Values' ? lcInvLineValuesConfig : lcInvLineConfig} formatterFunction={(value, name) => [invFinTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value, name]} data={invFinGraph} xDataKey={"label"} isLegend={true} tab={invFinTab} type={"Sum"} loader={invdiscLoader} reloadFn={getAdminINVFinGraph} />
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>LC Limit</label>

                  </div>

                  <div >
                    <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setlclimTab('Values')
                        }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Values ($)</button>
                      </li>
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setlclimTab('Count')
                        }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">Count</button>
                      </li>
                    </ul>
                  </div>

                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="pt-4">
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => lclimTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value} bardataConfig={lclimTab === 'Values' ? lcInvLineValuesConfig : lcInvLineConfig} formatterFunction={(value, name) => [lclimTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value, name]} data={lclimitGraph} xDataKey={"label"} isLegend={true} tab={lclimTab} type={"Sum"} loader={lclimitLoader} reloadFn={getAdminLCLimitGraph} />
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Invoice Limit</label>

                  </div>
                  <div >
                    <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setinvlimTab('Values')
                        }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Values ($)</button>
                      </li>
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setinvlimTab('Count')
                        }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">Count</button>
                      </li>
                    </ul>
                  </div>
                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => { }}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="pt-4">
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => invlimTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value} bardataConfig={invlimTab === 'Values' ? lcInvLineValuesConfig : lcInvLineConfig} formatterFunction={(value, name) => [invlimTab === 'Values' ? "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value, name]} data={invlimitGraph} xDataKey={"label"} isLegend={true} tab={invlimTab} type={"Sum"} loader={invlimitLoader} reloadFn={getAdminINVLimitGraph} />
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='card p-3 dashboard-card border-0 borderRadius h-90 d-flex flex-row pt-5 pb-4 ml-3 w-98'>
          <div className='col-md-12'>
            <div className='filter-div ml-4'>
              <Filter
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData} setFilterData={setfilterdata} showFilterBtn={true}
                showResultPerPage={true} count={Count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setrefresh} />
            </div>
            <div>
              <NewTable
                filteredSearch={filteredSearch}
                setFilteredSearch={setFilteredSearch}
                filterData={filterData}
                setFilterData={setfilterdata}
                columns={[
                  {
                    name: "Date", filter: true, filterDataKey: "Date", sort: [
                      { name: "Sort Oldest", selected: filter.sortBydate === "ASC", onActionClick: () => { setFilter({ ...filter, sortBydate: 'ASC', sortBuyerName: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Latest", selected: filter.sortBydate === "DESC", onActionClick: () => { setFilter({ ...filter, sortBydate: "DESC", sortBuyerName: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
                  },
                  {
                    name: "Supplier Name", filter: true, filterDataKey: "Supplier Name"
                  },
                  {
                    name: "Buyer Name", filter: true, filterDataKey: "Buyer Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortBydate: false, sortFinName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
                  },
                  { name: "Application No." },
                  {
                    name: "Application Type", filter: true, filterDataKey: "Application Type"
                  },
                  {
                    name: "Financer", filter: true, filterDataKey: "Financier Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortFinName === "ASC", onActionClick: () => { setFilter({ ...filter, sortFinName: 'ASC', sortBuyerName: false, sortAmount: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortFinName === "DESC", onActionClick: () => { setFilter({ ...filter, sortFinName: "DESC", sortBuyerName: false, sortAmount: false }); setrefresh(refresh + 1) } }]
                  },
                  {
                    name: "Amount", filter: true, filterDataKey: "Amount", sort: [
                      { name: "Sort Lowest", selected: filter.sortAmount === "ASC", onActionClick: () => { setFilter({ ...filter, sortAmount: 'ASC', sortBuyerName: false, sortFinName: false, sortBydate: false }); setrefresh(refresh + 1) } },
                      { name: "Sort Highest", selected: filter.sortAmount === "DESC", onActionClick: () => { setFilter({ ...filter, sortAmount: "DESC", sortBuyerName: false, sortFinName: false, sortBydate: false }); setrefresh(refresh + 1) } }]
                  },
                  { name: "Status" }
                ]}
                data={dbData}
                options={[
                  {
                    name: "View Application", icon: "eye.png", onClick: (index) => {
                      handleOpeningApplication(index, 0)
                    }
                  },
                ]}
              />
              <Pagination page={page} totalCount={Count} onPageChange={(p) => setpage(p)} refresh={refresh} setRefresh={setrefresh} perPage={filter.resultPerPage || 10} />

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default ApplicationReportsTab