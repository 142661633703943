import React, { useEffect, useState } from 'react';
import { setContractStateLogi, setLaytimesheet, setContractDetailLogi } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import Header from '../partial/header';
import Laytime from '../laytimeSheets/laytime';
import ContractDetailShipment from './contractDetailShipment'
import ContractDetailShipmentV2 from './contractDetailShipmentV2'
import ReactCountryFlag from "react-country-flag";
import call from '../../service';
import { ToastContainer } from 'react-toastify';
import ContractModal from './contractModal';
import { formatDate_Application } from '../../utils/dateFormaters';
import AssignVesselModal from './assignVessekModal.js'
import AssignContainerModal from './assignContainerModal.js'
import DataTable from 'react-data-table-component';
import customStyles from '../dataTablesColumsMap/customTableCss';
import columns from '../dataTablesColumsMap/shipmentContractListColumns';
import Footer from '../partial/footer';
import moment from 'moment'
import Pagination from "react-js-pagination";

const queryString = require('query-string');


const Shipment = ({ userTokenDetails, contractLogi, setContractStateLogi, laytimesheet, setLaytimesheet, setContractDetailLogi,
  contractDetailLogi, clientType, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [assignVessel, setassignVessel] = useState({ modal: false, data: {} })
  const [assignContainer, setassignContainer] = useState({ modal: false, data: {} })

  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });
  const [currentPage, setCurrentPage] = useState(1)
  const [mktPlaceContractDetail, setmktPlaceContractDetail] = useState({
    show: false,
    data: {}
  })
  const [filterIndex, setFilterIndex] = useState("")

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, { userId: userId, gridOnly: true })
    //------------------------------------------------------------------
  }, [contractLogi.info.refresh, refresh, filterData]);

  useEffect(() => {
    checkFilterQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [statusFilter, setstatusFilter] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [count, setCount] = useState({})


  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "activeType": clientType.type,
      "gridOnly": true,
      "showMktPlaceQuotations": true,
      "showContractQuotations": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getShipContracts', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.contractdata))
      setTotalRows(result.countdata.totalCount)
      setCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    setCurrentPage(page)
    fetchTableData(page, {});
  };

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   setLoading(true);
  //   setPerPage(newPerPage);
  //   let objectAPI = {
  //     "currentPage": page,
  //     "resultPerPage": newPerPage,
  //     "userId": userId,
  //     "type_id": userTypeId,
  //     "activeType": clientType.type,
  //     "gridOnly": true
  //   }

  //   objectAPI = { ...objectAPI, ...filterData }

  //   call('POST', 'getShipContracts', objectAPI).then((result) => {
  //     console.log('running getcontract api-->', result);
  //     settableData(formatDataFortable(result.contractdata))
  //     setTotalRows(result.countdata.totalCount)
  //     setCount(result.countdata)
  //     setLoading(false);
  //   }).catch((e) => {
  //     console.log('error in getContracts', e);
  //     setLoading(false)
  //   })
  // };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      if (!dataArray[i].mode) {

        tempObj.created_at = dataArray[i].created_at
        tempObj.contractName = dataArray[i].ship_contract_alias ? dataArray[i].ship_contract_alias : dataArray[i].contract_no;
        tempObj.charterer = dataArray[i].charterer ? dataArray[i].charterer : "--";
        tempObj.shipOwner = dataArray[i].shipOwner ? dataArray[i].shipOwner : "--";
        tempObj.ship_name = dataArray[i].ship_name ? dataArray[i].ship_name : "--";
        tempObj.commodity = (dataArray[i].contract_type === 0) ? dataArray[i].commodity_name : dataArray[i].off_data ? dataArray[i].off_data.commodity : '--';
        tempObj.quantity = (dataArray[i].ship_quantity) ? `${dataArray[i].ship_quantity} mt` : "--";
        tempObj.laycan = `${dataArray[i].laycan_start ? formatDate_Application(dataArray[i].laycan_start) : "NA"} to ${dataArray[i].laycan_end ? formatDate_Application(dataArray[i].laycan_end) : "NA"}`
        tempObj.loadingPort = (<>
          <span className="shadow">
            <ReactCountryFlag
              countryCode={dataArray[i].ship_load_country ? dataArray[i].ship_load_country.split(':')[0] : ''}
              style={{ width: '25px', height: '25px' }} svg />
          </span> <p>{dataArray[i].ship_load_port}</p>
        </>);
        tempObj.unloadingPort = (<>
          <span className="shadow">
            <ReactCountryFlag
              countryCode={dataArray[i].ship_unload_country ? dataArray[i].ship_unload_country.split(':')[0] : ''}
              style={{ width: '25px', height: '25px' }} svg />
          </span> <p>  {"  " + dataArray[i].ship_unload_port}</p>
        </>);
        tempObj.price = `${dataArray[i].ship_currency ? dataArray[i].ship_currency.split(':')[1] : 'NA'} ${dataArray[i].ship_price}`
        tempObj.status = (dataArray[i].charterer_status === 0 && dataArray[i].shipowner_status === 0) ? "Pilot Mode" :
          (dataArray[i].charterer_status === 1 && dataArray[i].shipowner_status === 0) ? "Pending by Shipowner" :
            (dataArray[i].charterer_status === 0 && dataArray[i].shipowner_status === 1) ? "Pending by Charterer" :
              (dataArray[i].charterer_status === 1 && dataArray[i].shipowner_status === 1) ? "Contract Locked" :
                "Agreed"

        tempObj.charterer_status = dataArray[i].charterer_status;
        tempObj.shipowner_status = dataArray[i].shipowner_status;
        tempObj.actions = {
          "contract_type": dataArray[i].master_contract_type,
          "contract_no": dataArray[i].contract_no,
          "charterer_status": dataArray[i].charterer_status,
          "shipowner_status": dataArray[i].shipowner_status,
          "shipment_type": dataArray[i].shipment_type,
          "charterer": dataArray[i].charterer,
          "shipOwner": dataArray[i].shipOwner,
          "vessel_master_id": dataArray[i].vessel_master_id,
          "carrier_agent_id": dataArray[i].carrier_agent_id,
          "ship_imo_no": dataArray[i].ship_imo_no,
          "loadingPort": <>
            <span><span className="shadow">
              <ReactCountryFlag
                countryCode={dataArray[i].ship_load_country ? dataArray[i].ship_load_country.split(':')[0] : ''}
                style={{ width: '25px', height: '25px' }} svg />
            </span> {dataArray[i].ship_load_port}</span>
          </>,
          "unloadingPort": <>
            <span> <span className="shadow">
              <ReactCountryFlag
                countryCode={dataArray[i].ship_unload_country ? dataArray[i].ship_unload_country.split(':')[0] : ''}
                style={{ width: '25px', height: '25px' }} svg />
            </span>  {dataArray[i].ship_unload_port}</span>
          </>

        }
      }
      else {
        tempObj = dataArray[i]
      }

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const ExpandedComponentForMarketplace = ({ data }) => (
    <>
      <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
        <div className="border-left"><button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 "
          onClick={() => setmktPlaceContractDetail({ show: true, data })}>
          <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true"></i></span>
          Contract Detail
        </button>
        </div>
      </div>
      <hr />
    </>
  );

  const TableExpandedComponent = ({ data }) => (
    <>
      <div className="col-md-12 row pt-2 text-center pb-0 mb-0">

        {/* <div className="ml-5 mr-5 border-left" style={{ "width": "150px" }}>
          <p className="pl-2 pt-1" >{data.actions.contract_no}</p>
        </div> */}

        {(((aclMap.shipment_access && aclMap.shipment_access.shipment_edit && aclMap.shipment_access.shipment_edit.isExist) ||
          (aclMap.shipment_access && aclMap.shipment_access.shipment_view && aclMap.shipment_access.shipment_view.isExist)) &&
          (data.actions.charterer_status / 1 === 0 || data.actions.shipowner_status / 1 === 0)) &&
          <div className="border-left"><button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setContractStateLogi(true, { contractNo: data.actions.contract_no, logiContractType: 'OnlineContract' })}>
            <span className="pr-2"><i class="fa fa-eye" aria-hidden="true"></i></span>
            View/Edit Contract
          </button>
          </div>}

        {(aclMap.shipment_access && (data.actions.charterer_status / 1 === 1 && data.actions.shipowner_status / 1 === 1) && aclMap.shipment_access.shipment_details && aclMap.shipment_access.shipment_details.isExist) &&
          <div className="border-left"><button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setContractDetailLogi(true, { contract_number: data.actions.contract_no })}>
            <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true"></i></span>
            Contract Detail
          </button>
          </div>}


        {(aclMap.shipment_access && (data.actions.charterer_status / 1 === 1 && data.actions.shipowner_status / 1 === 1) && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) &&
          <div className="border-left"><button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setLaytimesheet(data.actions.contract_no)}>
            <span className="pr-2"><i class="fa fa-pencil" aria-hidden="true"></i></span>
            Laytime Sheet
          </button>
          </div>}

        {(aclMap.shipment_access && (data.actions.charterer_status / 1 === 1 && data.actions.shipowner_status / 1 === 1) && data.actions.shipment_type === "bulk" && aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist) &&
          <div className="border-left"><button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setassignVessel({ modal: true, data: data.actions })}>
            <span className="pr-2"><i class="fa fa-ship" aria-hidden="true"></i></span>
            Assign Vessel
          </button>
          </div>}

        {(aclMap.shipment_access && (data.actions.charterer_status / 1 === 1 && data.actions.shipowner_status / 1 === 1) && data.actions.shipment_type === "container" && aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist) &&
          <div className="border-left"><button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setassignContainer({ modal: true, data: data.actions })}>
            <span className="pr-2"> <i class="fas fa-box"></i></span>
            Assign Containers
          </button>
          </div>}

      </div>
      <hr />
    </>
  );

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Filters

  function checkFilterQuery() {
    let query = queryString.parse(window.location.search);
    filterStateButtons(query.status / 1)
    setstatusFilter(query.status / 1)
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    if (type === 3) {
      setFilterData({
        ...filterData,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true,
        "showMktPlaceQuotations": false,
        "showContractQuotations": true
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false,
        "showMktPlaceQuotations": false,
        "showContractQuotations": true
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false,
        "showMktPlaceQuotations": false,
        "showContractQuotations": true
      })
    }
    else if (type === 4) {
      setFilterData({
        ...filterData,
        onlyProgress: true,
        "showMktPlaceQuotations": true,
        "showContractQuotations": false
      })
    }
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };


  const reset = () => {
    setstatusFilter(0)
    setFilterData({})
    setCurrentPage(1)
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Export csv
  // function ExportTableCSV() {

  //   let objectAPI = {
  //     "currentPage": 1,
  //     "resultPerPage": totalRows,
  //     "userId": userId,
  //     "type_id": userTypeId,
  //     "activeType": clientType.type,
  //     "gridOnly": true
  //   }

  //   objectAPI = { ...objectAPI, ...filterData }

  //   call('POST', 'getShipContracts', objectAPI).then((result) => {
  //     console.log('running getcontract api-->', result);

  //     const shipments = result.contractdata
  //     let csvString = "Contract No.,Charterer,Ship Owner,Vessel Name,Commodity,Quantity,Laycan,Loading Port,Unloading Port,Price,Charterer Status,Ship Owner Status\r\n"

  //     for (let i = 0; i < shipments.length; i++) {
  //       let rowString = `${shipments[i].contract_no},
  //     ${shipments[i].charterer ? shipments[i].charterer : '--'},
  //     ${shipments[i].shipOwner ? shipments[i].shipOwner : '--'},
  //     ${shipments[i].ship_name ? shipments[i].ship_name : '--'},
  //     ${(shipments[i].contract_type / 1 === 0) ? shipments[i].commodity_name : (shipments[i].contract_type / 1 === 1) ? shipments[i].off_data.commodity : '--'},
  //     ${shipments[i].ship_quantity ? shipments[i].ship_quantity + "mt" : '--'},
  //     ${shipments[i].laycan_start ? formatDate_Application(shipments[i].laycan_start) + " - " + formatDate_Application(shipments[i].laycan_end) : '--'},
  //     ${shipments[i].ship_load_port ? shipments[i].ship_load_port + "| " + shipments[i].ship_load_country.split(':')[0] : '--'},
  //     ${shipments[i].ship_unload_port ? shipments[i].ship_unload_port + "| " + shipments[i].ship_unload_country.split(':')[0] : "--"},
  //     ${(shipments[i].ship_currency) ? shipments[i].ship_currency.split(':')[1] + " " + shipments[i].ship_price : '--'},
  //     ${shipments[i].status_buyer === 1 ? "Agree" : "Pending"},
  //     ${shipments[i].status_seller === 1 ? "Agree" : "Pending"}\r\n`

  //       rowString = rowString.replace(/(\n)/gm, "")
  //       csvString += rowString
  //     }

  //     let link = document.createElement('a');
  //     link.style.display = 'none';
  //     link.setAttribute('target', '_blank');
  //     link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
  //     link.setAttribute('download', `Payments_Record_${userEmail.split('@')[0]}.csv`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //   }).catch((e) => {
  //     console.log('error in getPaymets', e);
  //   })
  // }
  //---------------------------------------------------------------------------------------------------------------------

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function getAcceptedOfferDetails(offerArray) {
    return offerArray.filter(val => {
      if (val.agreed_by_shipper && val.agreed_by_trader) {
        return val
      }
    })[0]
  }

  function createQuotationListBody(data) {
    // console.log('datareceved', data);
    return (
      data && data.map(item => {
        if (item.mode) {
          let offer_data = traderTypeIds.includes(userTypeId) ? getAcceptedOfferDetails(item.offers_received)["offer_details_obj"] : item.offer_details_obj
          return (
            <div style={{ background: "#DEF8FB" }}>
              <li
                className={"d-flex mb-2"}
              >
                {/* <h6
                  className='bg-primary text-white position-absolute p-1'
                  style={{ position: 'absolute' }}
                >Marketplace Contract</h6> */}
                <div className="ntf_time">
                  {traderTypeIds.includes(userTypeId) ? (
                    <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  ) : (
                    <label>{moment(item.booking_created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  )}
                </div>
                <div className="ntf_content">
                  <ul className="p-0 d-flex">
                    {offer_data.mode ? (
                      <li className="col-md-4">
                        <label>{offer_data.mode}</label>
                        <span>{"Mode"}</span>
                      </li>
                    ) : null}
                    {offer_data.mode_type ? (
                      <li className="col-md-4">
                        <label>{offer_data.mode_type}</label>
                        <span>{"Mode Type"}</span>
                      </li>
                    ) : null}
                    {offer_data.origin_country ? (
                      <li className="col-md-4">
                        <label>{offer_data.origin_country}</label>
                        <span>Origin Country</span>
                      </li>
                    ) : null}
                    {offer_data.origin_port ? (
                      <li className="col-md-4">
                        <label>{offer_data.origin_port}</label>
                        <span>Origin Port</span>
                      </li>
                    ) : null}
                    {offer_data.destination_country ? (
                      <li className="col-md-4">
                        <label>{offer_data.destination_country}</label>
                        <span>Destination Country</span>
                      </li>
                    ) : null}
                    {offer_data.destination_port ? (
                      <li className="col-md-4">
                        <label>{offer_data.destination_port}</label>
                        <span>Destination Port</span>
                      </li>
                    ) : null}
                    {offer_data.container_type ? (
                      <li className="col-md-4">
                        <label>{offer_data.container_type}</label>
                        <span>Container Type</span>
                      </li>
                    ) : null}
                    {offer_data.container_no ? (
                      <li className="col-md-4">
                        <label>{offer_data.container_no}</label>
                        <span>Container No</span>
                      </li>
                    ) : null}
                    {offer_data.cargo_type ? (
                      <li className="col-md-4">
                        <label>{offer_data.cargo_type}</label>
                        <span>Cargo Type</span>
                      </li>
                    ) : null}
                    {offer_data.start_date ? (
                      <li className="col-md-4">
                        <label>{moment(offer_data.start_date).format("YYYY-MM-DD")}</label>
                        <span>Start Date (yyyy-mm-dd)</span>
                      </li>
                    ) : null}
                    {offer_data.end_date ? (
                      <li className="col-md-4">
                        <label>{moment(offer_data.end_date).format("YYYY-MM-DD")}</label>
                        <span>End Date (yyyy-mm-dd)</span>
                      </li>
                    ) : null}
                    {offer_data.load_volume ? (
                      <li className="col-md-4">
                        <label>{offer_data.load_volume}</label>
                        <span>Load Volume (cubic meter)</span>
                      </li>
                    ) : null}
                    {offer_data.load_weight ? (
                      <li className="col-md-4">
                        <label>{offer_data.load_weight}</label>
                        <span>Load Weight (Kg)</span>
                      </li>
                    ) : null}
                    {offer_data.vessel_type ? (
                      <li className="col-md-4">
                        <label>{offer_data.vessel_type}</label>
                        <span>Vessel Type</span>
                      </li>
                    ) : null}
                    {offer_data.commodity_type ? (
                      <li className="col-md-4">
                        <label>{offer_data.commodity_type}</label>
                        <span>Commodity Type</span>
                      </li>
                    ) : null}
                    {offer_data.flexibility_days ? (
                      <li className="col-md-4">
                        <label>{offer_data.flexibility_days}</label>
                        <span>Flexibility Days</span>
                      </li>
                    ) : null}
                    {offer_data.price ? (
                      <li className="col-md-4">
                        <label>{offer_data.price}</label>
                        <span>Price ($)</span>
                      </li>
                    ) : null}
                    <li className="col-md-4">
                      <label>{"Offer Accepted"}</label>
                      <span>Status</span>
                    </li>
                  </ul>
                </div>
              </li>
              <div style={{
                background: "#e4f9c4"
              }}>
                <ExpandedComponentForMarketplace data={item} />
              </div>
            </div>
          )
        }
        if (item.contractName) {
          return (
            <div style={{ background: "#DEF8FB" }}>
              <li
                className={"d-flex mb-2"}
              >
                {item.created_at ? (
                  <div className="ntf_time">
                    <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  </div>
                ) : null}
                <div className="ntf_content">
                  <ul className="p-0 d-flex">
                    {/* {item.actions.contract_no ? (
                      <li className="col-md-4">
                        <label>{item.actions.contract_no}</label>
                        <span>Contract No</span>
                      </li>
                    ) : null} */}
                    {item.contractName ? (
                      <li className="col-md-4">
                        <label>{item.contractName}</label>
                        <span>Contract Name</span>
                      </li>
                    ) : null}
                    {item.charterer ? (
                      <li className="col-md-4">
                        <label>{item.charterer}</label>
                        <span>Charterer</span>
                      </li>
                    ) : null}
                    {item.shipOwner ? (
                      <li className="col-md-4">
                        <label>{item.shipOwner}</label>
                        <span>Ship Owner</span>
                      </li>
                    ) : null}
                    {item.vesselName ? (
                      <li className="col-md-4">
                        <label>{item.vesselName || "-"}</label>
                        <span>Vessel Name</span>
                      </li>
                    ) : null}
                    {item.commodity ? (
                      <li className="col-md-4">
                        <label>{item.commodity}</label>
                        <span>Commodity</span>
                      </li>
                    ) : null}
                    {item.quantity ? (
                      <li className="col-md-4">
                        <label>{item.quantity}</label>
                        <span>Quantity</span>
                      </li>
                    ) : null}
                    {item.price ? (
                      <li className="col-md-4">
                        <label>{item.price}</label>
                        <span>Price</span>
                      </li>
                    ) : null}
                    {item.laycan ? (
                      <li className="col-md-4">
                        <label>{item.laycan}</label>
                        <span>Laycan</span>
                      </li>
                    ) : null}
                    {item.loadingPort ? (
                      <li className="col-md-4">
                        <label>{item.loadingPort}</label>
                        <span>Loading Port</span>
                      </li>
                    ) : null}
                    {item.unloadingPort ? (
                      <li className="col-md-4">
                        <label>{item.unloadingPort}</label>
                        <span>Unloading Port</span>
                      </li>
                    ) : null}
                    {item.status ? (
                      <li className="col-md-4">
                        <label>{item.status}</label>
                        <span>Status</span>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </li>
              <div style={{
                background: "#e4f9c4"
              }}>
                <TableExpandedComponent data={item} />
              </div>
            </div>
          )
        }
      })
    )
  }

  // function createQuotationListBody2(data) {
  //   // console.log('datareceved', data);
  //   return (
  //     data && data.map(item => {
  //       if (item.contractName) {
  //         return (
  //           <div style={{ background: "#DEF8FB" }}>
  //             <li
  //               className={"d-flex mb-2"}
  //             >
  //               {item.created_at ? (
  //                 <div className="ntf_time">
  //                   <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
  //                 </div>
  //               ) : null}
  //               <div className="ntf_content">
  //                 <ul className="p-0 d-flex">
  //                   {item.actions.contract_no ? (
  //                     <li className="col-md-4">
  //                       <label>{item.actions.contract_no}</label>
  //                       <span>Contract No</span>
  //                     </li>
  //                   ) : null}
  //                   {item.contractName ? (
  //                     <li className="col-md-4">
  //                       <label>{item.contractName}</label>
  //                       <span>Contract Name</span>
  //                     </li>
  //                   ) : null}
  //                   {item.charterer ? (
  //                     <li className="col-md-4">
  //                       <label>{item.charterer}</label>
  //                       <span>Charterer</span>
  //                     </li>
  //                   ) : null}
  //                   {item.shipOwner ? (
  //                     <li className="col-md-4">
  //                       <label>{item.shipOwner}</label>
  //                       <span>Ship Owner</span>
  //                     </li>
  //                   ) : null}
  //                   {item.vesselName ? (
  //                     <li className="col-md-4">
  //                       <label>{item.vesselName || "-"}</label>
  //                       <span>Vessel Name</span>
  //                     </li>
  //                   ) : null}
  //                   {item.commodity ? (
  //                     <li className="col-md-4">
  //                       <label>{item.commodity}</label>
  //                       <span>Commodity</span>
  //                     </li>
  //                   ) : null}
  //                   {item.quantity ? (
  //                     <li className="col-md-4">
  //                       <label>{item.quantity}</label>
  //                       <span>Quantity</span>
  //                     </li>
  //                   ) : null}
  //                   {item.price ? (
  //                     <li className="col-md-4">
  //                       <label>{item.price}</label>
  //                       <span>Price</span>
  //                     </li>
  //                   ) : null}
  //                   {item.laycan ? (
  //                     <li className="col-md-4">
  //                       <label>{item.laycan}</label>
  //                       <span>Laycan</span>
  //                     </li>
  //                   ) : null}
  //                   {item.loadingPort ? (
  //                     <li className="col-md-4">
  //                       <label>{item.loadingPort}</label>
  //                       <span>Loading Port</span>
  //                     </li>
  //                   ) : null}
  //                   {item.unloadingPort ? (
  //                     <li className="col-md-4">
  //                       <label>{item.unloadingPort}</label>
  //                       <span>Unloading Port</span>
  //                     </li>
  //                   ) : null}
  //                   {item.status ? (
  //                     <li className="col-md-4">
  //                       <label>{item.status}</label>
  //                       <span>Status</span>
  //                     </li>
  //                   ) : null}
  //                 </ul>
  //               </div>
  //             </li>
  //             <div style={{
  //               background: "#e4f9c4"
  //             }}>
  //               <TableExpandedComponent data={item} />
  //             </div>
  //           </div>
  //         )
  //       }
  //     })
  //   )
  // }

  const handleFilter = event => {
    event.persist()
    setFilterIndex(event.target.value)
    if (!event.target.value) {
      reset()
    }
    else {
      setstatusFilter(event.target.value / 1);
      filterStateButtons(event.target.value / 1)
    }
  }


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">

          {/* laytime component */}
          {((aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) && laytimesheet.state) &&
            <Laytime userTokenDetails={userTokenDetails} />}
          {/* laytime component */}

          {/* contract modal component */}
          {contractLogi.modal && <ContractModal userTokenDetails={userTokenDetails} />}
          {/* contract modal component */}

          {/* contract modal component */}
          {(assignVessel.modal && (aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist)) &&
            <AssignVesselModal
              userTokenDetails={userTokenDetails}
              assignVessel={assignVessel}
              setassignVessel={setassignVessel}
              setrefresh={setrefresh}
              refresh={refresh}
            />}

          {(assignContainer.modal && (aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist)) &&
            <AssignContainerModal
              userTokenDetails={userTokenDetails}
              assignContainer={assignContainer}
              setassignContainer={setassignContainer}
              setrefresh={setrefresh}
              refresh={refresh}
            />}
          {/* contract modal component */}

          {!laytimesheet.state && <>
            {/* sidebar start */}
            <Sidebar state={userTypeId === 13 ? 'insurershipment' : 'shipment'} userTokenDetails={userTokenDetails} />
            {/* sidebar end */}
            <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

              {/* header start */}
              {(!contractDetailLogi.page && !mktPlaceContractDetail.show) &&
                <Header
                  title={userTypeId === 13 ? 'Insured Shipments' : 'Shipment Contracts'}
                  // total={'Total ' + count.totalCount + ' Contracts '}
                  userTokenDetails={userTokenDetails} />}
              {/* header end */}

              {/* shipment contract details start */}
              {((aclMap.shipment_access && aclMap.shipment_access.shipment_details && aclMap.shipment_access.shipment_details.isExist) && contractDetailLogi.page) &&
                <ContractDetailShipment userTokenDetails={userTokenDetails} assignContainer={assignContainer}
                  setassignContainer={setassignContainer}
                  assignVessel={assignVessel}
                  setassignVessel={setassignVessel}
                  setrefresh={setrefresh}
                  refresh={refresh} />}
              {/* shipment contract details end */}

              {/* marketplace contract details start */}
              {mktPlaceContractDetail.show &&
                <ContractDetailShipmentV2
                  userTokenDetails={userTokenDetails}
                  offerData={mktPlaceContractDetail.data}
                  resetData={() => setmktPlaceContractDetail({ show: false, data: {} })}
                // assignContainer={assignContainer}
                // setassignContainer={setassignContainer}
                // assignVessel={assignVessel}
                // setassignVessel={setassignVessel}
                // setrefresh={setrefresh}
                // refresh={refresh}
                />}
              {/* marketplace contract details end */}

              {/* {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)} */}
              {(contractDetailLogi.page === false && mktPlaceContractDetail.show === false) && <>
                <div className="d-md-flex mt-3 mb-3">
                  <div className="filter-bg">
                    {/* <ul> */}
                    {/* <li className={"pilot-cont cursor-pointer " + (statusFilter === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setstatusFilter(1); filterStateButtons(1) }}>
                        <span className="icon"><img src="assets/images/filter-icon.svg" /></span>In-Pilot
                      </li> */}
                    {/* {contractCount.pilotcount ? contractCount.pilotcount : ''} */}
                    {/* <li className={"progress-cont cursor-pointer " + (statusFilter === 2 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(2); filterStateButtons(2) }}>
                        <span className="icon"><img src="assets/images/filter-icon.svg" /></span>In-progress
                      </li> */}
                    {/* {contractCount.progresscount ? contractCount.progresscount : ''} */}
                    {/* <li className={"complete-cont cursor-pointer " + (statusFilter === 3 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(3); filterStateButtons(3) }}>
                        <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Completed
                      </li> */}
                    {/* {contractCount.completecount ? contractCount.completecount : ''} */}
                    {/* </ul> */}

                    <select className="form-control" name="filter"
                      onChange={handleFilter} value={filterIndex}
                    >
                      <option value="" selected>Filter by</option>
                      <option value="1" >In-Pilot</option>
                      <option value="2" >In-Progress</option>
                      <option value="3" >Completed</option>
                      <option value="4" >Offer Accepted</option>
                    </select>


                  </div>
                  <div className="ml-md-auto relative">
                    <ul className="range-slider">
                      <li>
                        <input name="search" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} id="searchInput" onKeyDown={handleKeyPress} />
                        <button type="button" onClick={() => {
                          setFilterData({
                            ...filterData,
                            "search": searchKey
                          })
                        }}><i class="fas fa-search fa-lg"></i></button>
                      </li>
                      <li>
                        <label>From</label>
                        <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
                      </li>
                      <li>
                        <label>To</label>
                        <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
                      </li>
                      <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

                    </ul>
                  </div>
                </div>

                <div className="notification_listing">
                  {tableData && tableData.length ?
                    tableData.map((item, index) => (
                      <div className={"accordionItem" + (accorddata["accord_" + index] ? " open" : " closed")}>
                        <>
                          {!traderTypeIds.includes(userTypeId) ? (
                            <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_' + index)}>{
                              (item.actions ? item.actions.contract_no : item.contract_id) + (" | " + userName + " | " +
                                (item.charterer || item.company_name))
                            }</h2>

                          ) : (
                            <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_' + index)}>{
                              (item.actions ? item.actions.contract_no : item.contract_id) + (" | " + userName + " | " +
                                (item.offers_received && getAcceptedOfferDetails(item.offers_received)["company_name"] || item.shipOwner))
                            }</h2>
                          )}
                        </>
                        <div className="accordionItemContent">
                          <div className="nt_data">
                            <ul className="p-0  Ul-list">
                              {createQuotationListBody([item])}
                              {/* {tableData && tableData.length ? "" :
                                <h1 className="accordionItemHeading">No Records Found</h1>} */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    <h5 className='text-center' style={{ "marginTop": "7rem" }}>No Records Found</h5>}
                </div>

                {/* <div className="notification_listing">
                  <div className={"accordionItem" + (accorddata.accord_1 ? " open" : " closed")}>
                    <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_1')}>Shipment Contracts</h2>
                    <div className="accordionItemContent">
                      <div className="nt_data">
                        <ul className="p-0  Ul-list">
                          {createQuotationListBody2(tableData)}
                          {tableData && tableData.length ? "" :
                            <h1 className="accordionItemHeading">No Records Found</h1>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className='row justify-content-end pr-3' >
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalRows}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    activeLinkClass="bg-primary"
                  />
                </div>

                {/* <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    expandableRows
                    expandableRowsComponent={<TableExpandedComponent />}
                    expandOnRowClicked
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div> */}
                <hr />
                {/* <div className="float-left col-md-7 ">
                  <div className="col-md-12 row">
                    <div className="col-md-3">
                      <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                        <i class="fas fa-file-csv"></i> Export CSV
                      </button>
                    </div>
                  </div>
                </div> */}
              </>}
            </main></>}
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    contractLogi: state.contractLogi,
    laytimesheet: state.laytimesheet,
    contractDetailLogi: state.contractDetailLogi,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractStateLogi: (flag, data) => { dispatch(setContractStateLogi({ modal: flag, info: data })) },
    setLaytimesheet: (cNumber) => { dispatch(setLaytimesheet({ state: true, cNo: cNumber })) },
    setContractDetailLogi: (flag, data) => { dispatch(setContractDetailLogi({ page: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shipment)
