import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import {
  InputForTable,
  InputWithSelectForTable,
  NewInput,
  NewSelect,
  NewTextArea,
  NewTextAreaForTable,
  SelectForTable,
} from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import {
  handleFileCommonFunction,
  most_used_currencies,
  printDiv,
  printOnlyDiv,
} from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import avatarUrl from "../../utils/makeAvatarUrl";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FileInput } from "../../utils/FileInput";
import SignDocument, {
  signFonts,
} from "../InvoiceDiscounting/components/SignDocument";
import "../../App.css";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import RichTextEditor3 from "../../utils/RichTextEditor3";
import moment from "moment";
let invItemsTable = [
  { subColumns: "SR. NO.", subColumnStyle: { width: "7%" } },
  { subColumns: "ITEM DESCRIPTION", subColumnStyle: { width: "30%" } },
  { subColumns: "HSN NO.", subColumnStyle: { width: "10%" } },
  { subColumns: "QUANTITY", subColumnStyle: { width: "10%" } },
  { subColumns: "UNIT PRICE", subColumnStyle: { width: "12%" } },
  { subColumns: "TAX AMOUNT", subColumnStyle: { width: "12%" } },
  { subColumns: "TOTAL PRICE", subColumnStyle: { width: "15%" } },
];

let chargesTable = [
  { subColumns: "CHARGE TITLE", subColumnStyle: { width: "45%" } },
  { subColumns: "AMOUNT", subColumnStyle: { width: "45%" } },
];

const DraftLC = ({
  userTokenDetails,
  navToggleState,
  selectedInvoiceData,
  handleContractTypeChange,
  contractChosen,
  showContractModal,
  setshowcontractmodal,
  contractdata,
  existingData,
  templateSelectionForm,
  toggleTemplateSelectionForm,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  let serarchParam = queryParams.get("search");
  console.log(showContractModal, "yes this is show contract modal---->>>>>");
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null;
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null;
  const subUserId = userTokenDetails.sub_user_id
    ? userTokenDetails.sub_user_id
    : null;
  const parentData = userTokenDetails.parent_data
    ? userTokenDetails.parent_data
    : null;

  const [showLoader, setshowLoader] = useState(false);
  const [filter, setFilter] = useState({
    resultPerPage: 10,
    search: serarchParam ? serarchParam : "",
  });
  const [filterData, setFilterData] = useState({});
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState({
    invCurrency: "USD",
    invChargeTitle0: "SUBTOTAL",
    invChargeTitle1: "HANDLING",
    invChargeTitle2: "FREIGHT",
    invChargeTitle3: "MISC.",
  });
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [error, setErrors] = useState({});
  const [countrys, setCountrys] = useState([]);
  const [invItems, setInvItems] = useState([null]);
  const [invOther, setInvOther] = useState([null, null, null, null]);
  const [preview, setPreview] = useState({});
  const [showPopup, togglePopup] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [letterHeadArr, setLetterHeadArr] = useState([null, null]);
  const [lcCodes, setLCCodes] = useState([]);
  const [formData, setFormData] = useState({});
  const [checkData, setCheckData] = useState(true);
  const [mappedValue, setMappedValue] = useState([]);
  let jj = -1;
  let kk = -1;
  let mm = -1;
  let categories = [];
  const [userinfo, setuserInfo] = useState({});
  const [historydata, sethistoryData] = useState([]);
  const [datadup, setdatadup] = useState({});
  const [tab, setTab] = useState(0);
  useEffect(() => {
    if (selectedInvoiceData?.docData) {
      setData(selectedInvoiceData.docData?.data);
      setLetterHeadArr(selectedInvoiceData.docData.letterHeadArr);
    }
  }, []);

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountrys(result.data.message);
      }
    });
  }, []);

  async function handleChange(e) {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...error, [e.target.name]: "" });
    e.persist();
    if (e.target.name.includes("itemTotalAmount")) {
      let invSubTotalAmount = 0;
      for (let index = 0; index < invItems.length; index++) {
        let amountToAdd =
          e.target.name === `itemTotalAmount${index}`
            ? e.target.value
            : data[`itemTotalAmount${index}`] || 0;
        if (invItems[index] === null) {
          invSubTotalAmount += amountToAdd / 1;
        }
      }
      setData({
        ...data,
        [e.target.name]: e.target.value,
        [`invSubTotalAmount0`]: invSubTotalAmount,
      });
      setErrors({ ...error, [e.target.name]: "" });
    } else if (e.target.name.includes(`invSubTotalAmount`)) {
      let invTotalAmount = 0;
      for (let index = 0; index < invOther.length; index++) {
        let amountToAdd =
          e.target.name === `invSubTotalAmount${index}`
            ? e.target.value
            : data[`invSubTotalAmount${index}`] || 0;
        if (invOther[index] === null) {
          invTotalAmount += amountToAdd / 1;
        }
      }
      setData({ ...data, [e.target.name]: e.target.value, invTotalAmount });
      setErrors({ ...error, [e.target.name]: "" });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
      setErrors({ ...error, [e.target.name]: "" });
    }
  }

  const handlevalidation = () => {
    //   let newErrors = {};
    //   let isValid = true;
    //   console.log(formData,"formdataaaaa")
    //    lcCodes?.forEach((item)=>{
    //   if(item.status === "M")
    //   setMandatoryFields([...mandatoryFields, item.fieldName])
    // })
    //   mandatoryFields.forEach(field => {
    //     if (formData[field] === "") {
    //       // error[field] = 'Mandatory Field';
    //       newErrors.error= 'Mandatory field';
    //       isValid = false;
    //       setCheckData(false)
    //       setErrors({...error,newErrors})
    //     }
    //   });
    //   return isValid

    let newErrors = {};
    let isValid = true;
    setErrors({ ...error, ...newErrors });

    return isValid;
  };

  useEffect(() => {
    if (tab === 5) {
      call("POST", "getuserinfo", { userId: userId, fields: data })
        .then((res) => {
          setdatadup(res.matchedRows || []); // Handle case where matchedRows might be undefined
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error appropriately
        });
    }
  }, [userId, data, tab]);
  useEffect(() => {
    if (data?.commInvoice?.name) {
      console.log(data?.commInvoice?.name, "comminvoiceeeeeeee");
      saveCommInvoice();
    }
  }, [data.commInvoice]);

  useEffect(() => {
    call("POST", "getuserinfo", { userId }).then((res) => setuserInfo(res));
  }, []);

  // function compareDocuments(documents) {
  //   for (let i = 0; i < documents.length; i++) {
  //     const baseData = documents[i].new_doc_data.data;
  //     const timebase = documents[i].timestamp;
  //     const changebase = documents[i].company_name;

  //     for (let j = i + 1; j < documents.length; j++) {
  //       const newData = documents[j].new_doc_data.data;
  //       const changedby = documents[j].company_name;
  //       const time = documents[j].timestamp;
  //       const diffFields = {};

  //       for (const key in baseData) {
  //         if (baseData.hasOwnProperty(key)) {
  //           if (newData[key] !== baseData[key]) {
  //             diffFields[key] = {
  //               oldValue: baseData[key],
  //               newValue: newData[key],
  //               changedby,
  //               time,
  //             };
  //           }
  //         }
  //       }
  //       sethistoryData(diffFields);
  //       if (Object.keys(diffFields).length > 0) {
  //         changes.push(diffFields);
  //       }
  //     }
  //   }

  //   console.log(changes, "hello chnagesss-----------")

  //   return changes;
  // }

  function compareDocuments(documents) {
    const changes = [];

    for (let i = 0; i < documents.length; i++) {
      const baseData = documents[i].new_doc_data.data;
      const timebase = documents[i].timestamp;
      const changebase = documents[i].company_name;

      for (let j = i + 1; j < documents.length; j++) {
        const newData = documents[j].new_doc_data.data;
        const changedby = documents[j].company_name;
        const time = documents[j].timestamp;

        for (const key in baseData) {
          if (baseData.hasOwnProperty(key)) {
            if (newData[key] !== baseData[key]) {
              // Check if changes array already has an entry for this field
              let existingChange = changes.find(
                (item) => item.fieldName === key
              );

              if (existingChange) {
                // If entry exists, add the new difference to it
                existingChange.differences.push({
                  oldValue: baseData[key],
                  newValue: newData[key],
                  changedby,
                  time,
                  remark: `${baseData[key]}remark`,
                });
              } else {
                // If entry doesn't exist, create a new entry
                changes.push({
                  fieldName: key,
                  differences: [
                    {
                      oldValue: baseData[key],
                      newValue: newData[key],
                      changedby,
                      time,
                      remark: `${baseData[key]}remark`,
                    },
                  ],
                });
              }
            }
          }
        }
      }
      changes.forEach((change) => {
        change.differences.sort((a, b) => new Date(b.time) - new Date(a.time));
      });

      sethistoryData(changes);
    }

    return changes;
  }

  // function compareDocuments(documents) {
  //   const changes = [];

  //   for (let i = 0; i < documents.length; i++) {
  //     const baseData = documents[i].new_doc_data.data;
  //     const timebase = documents[i].timestamp;
  //     const changebase = documents[i].company_name;
  //     console.log(baseData, "this is basedata---->>>>");

  //     for (let j = i + 1; j < documents.length; j++) {
  //       const newData = documents[j].new_doc_data.data;
  //       const changedby = documents[j].company_name;
  //       const time = documents[j].timestamp;
  //       console.log(newData, "this is newdata---->>>>");

  //       for (const key in baseData) {
  //         console.log(key, "key in basedata");
  //         if (baseData.hasOwnProperty(key)) {
  //           if (newData[key] !== baseData[key]) {
  //             // Check if changes array already has an entry for this field
  //             let existingChange = changes.find(
  //               (item) => item.fieldName === key
  //             );
  //             console.log(existingChange, "existing changes-----");
  //             if (existingChange && Array.isArray(existingChange.differences)) {
  //               // If entry exists, add the new difference to it
  //               existingChange.differences.push({
  //                 oldValue: baseData[key],
  //                 newValue: newData[key],
  //                 changedby,
  //                 time,
  //               });
  //             } else {
  //               // If entry doesn't exist, create a new entry
  //               changes.push({
  //                 fieldName: key,
  //                 differences: [
  //                   {
  //                     oldValue: baseData[key],
  //                     newValue: newData[key],
  //                     changedby,
  //                     time,
  //                   },
  //                 ],
  //               });
  //             }
  //           }

  //           // Check and push remark field if it exists
  //           const remarkKey = `${key}remark`;
  //           if (baseData[remarkKey] !== newData[remarkKey]) {
  //             let existingRemarkChange = changes.find(
  //               (item) => item.fieldName === remarkKey
  //             );
  //             if (existingRemarkChange) {
  //               existingRemarkChange.differences.push({
  //                 oldValue: baseData[remarkKey],
  //               });
  //             } else {
  //               changes.push({
  //                 fieldName: remarkKey,
  //                 differences: {
  //                   oldValue: baseData[remarkKey],
  //                 },
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }
  //     changes.forEach((change) => {
  //       change.differences.sort((a, b) => new Date(b.time) - new Date(a.time));
  //     });

  //     sethistoryData(changes);
  //   }

  //   return changes;
  // }

  useEffect(() => {
    if (existingData.length > 0) {
      compareDocuments(existingData);
    }
  }, [existingData]);

  // useEffect(() => {
  //   if (existingData.length > 0) {
  //     compareDocuments(existingData);
  //   }
  // }, []);

  useEffect(() => {
    call("POST", "getLCAllFields", {}).then((res) => {
      categories = [...new Set(res.map((item) => item.category))];

      const mappedData = categories.map((category) => {
        // Filter data for the current category
        const filteredData = res.filter((item) => item.category === category);

        res.sort((a, b) => {
          if (a.status === b.status) {
            return 0;
          } else if (a.status === "M") {
            return -1;
          } else {
            return 1;
          }
        });
        // Return an object containing the category and filtered data
        return {
          category,
          data: filteredData,
        };
      });

      setMappedValue(mappedData);
      setLCCodes([...res]);
    });
  }, []);

  async function saveCommInvoice() {
    setshowLoader(true);
    let formData = new FormData();
    formData.append("userId", userId);
    formData.append("docType", "lc");
    formData.append("docTemplate", "Draft LC");
    formData.append("docName", `Draft LC - ${data["20"]}`);
    // formData.append("docData", JSON.stringify({ data, letterHeadArr }));
    formData.append("doc", data.commInvoice);
    formData.append("lcid", Math.floor(10000 + Math.random() * 90000));
    if (selectedInvoiceData?.id) {
      formData.append("updateDocId", selectedInvoiceData.id);
    }
    let tempData = data
    delete tempData["commInvoice"]
    formData.append('docData', JSON.stringify({ data: data, letterHeadArr }))
    await call("POST", "saveEdoc", formData);
    setshowLoader(false);
    toastDisplay("Document downloaded & saved", "success");
    toggleTemplateSelectionForm({
      show: false,
      data: { type: "" },
    });
  }
  // const [contractdata, setcontractdata] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isInfoDropdownOpen, setInfoDropdownOpen] = useState(false);

  const limitfields = useMemo(
    () => [
      { fieldName: "Applicants Reference" },
      { fieldName: "Delivery" },
      { fieldName: "Issuing Branch" },
      { fieldName: "Branch State" },
      { fieldName: "Branch City" },
      { fieldName: "Branch" },
      { fieldName: "For" },
      { fieldName: "Invoice Value" },
      { fieldName: "Days" },
    ],
    []
  );

  const documentfields = useMemo(
    () => [
      { fieldName: "Advising Bank" },
      { fieldName: "Advising Branch" },
      { fieldName: " Address Line 1" },
      { fieldName: " Address Line 2" },
      { fieldName: " City" },
      { fieldName: " Country" },
      { fieldName: "Advising Postal Code" },
      { fieldName: "BIC Code" },
      { fieldName: "Confirmation of Audit (Yes/ No)" },
      { fieldName: "Drawee" },
    ],
    []
  );

  const bankfields = useMemo(
    () => [
      { fieldName: "Beneficiary", dataKey: "beneficiaryBankName" },

      {
        fieldName: " Beneficiary Address Line 1",
        dataKey: "beneficiaryBranchAddress",
      },
      {
        fieldName: "Beneficiary Address Line 2",
        dataKey: "beneficiaryBankName",
      },
      { fieldName: "Beneficiary City", dataKey: "beneficiaryCity" },
      { fieldName: "Beneficiary State", dataKey: "beneficiaryState" },
      { fieldName: "Beneficiary Country", dataKey: "beneficiaryCountry" },
      {
        fieldName: "Beneficiary Postal Code",
        dataKey: "beneficiaryPostalCode",
      },

      { fieldName: "Email Id", dataKey: "email" },
    ],
    []
  );

  const applicantfields = useMemo(
    () => [
      { fieldName: "Applicant", dataKey: "buyername" },
      { fieldName: "Customer Code", dataKey: "contract_type" },
      { fieldName: "Address Line 1", dataKey: "company_address1" },
      { fieldName: "Address Line 2", dataKey: "company_address2" },
      { fieldName: "City", dataKey: "company_city" },
      { fieldName: "State", dataKey: "company_state" },
      { fieldName: "Country", dataKey: "country_code" },
      { fieldName: "Postal Code", dataKey: "company_postal_code" },
      { fieldName: "IEC Code", dataKey: "iec_no" },
    ],
    []
  );

  async function handleValidation() {
    setTab(tab + 1);
    if (tab === 5) {
      handlevalidation();
    }
  }

  const tabsArr = [
    { name: "Letter Head Information" },
    { name: "Applicant Information" },
    { name: "Limit Information" },
    { name: "Document Information" },
    { name: "Bank Information" },
    { name: "Preview" },
  ];
  const [newTnc, setnewTnc] = useState({ newTncName: "", newTncValue: "" });
  const handlenewTnc = (event) => {
    event.persist();
    setnewTnc((newTnc) => ({
      ...newTnc,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    if (contractChosen?.length && userinfo && userinfo.userinfo) {
      const updatedData = { ...data };

      // Process applicantfields
      applicantfields.forEach((field) => {
        if (contractChosen[0]?.hasOwnProperty(field.dataKey)) {
          const fieldValue = contractChosen[0][field.dataKey];
          updatedData[field.fieldName] =
            fieldValue !== null && fieldValue !== undefined
              ? fieldValue.toString()
              : "";
        } else {
          const fieldValue = userinfo.userinfo[0]?.[field.dataKey];
          updatedData[field.fieldName] =
            fieldValue !== null && fieldValue !== undefined
              ? fieldValue.toString()
              : "";
        }
      });

      // Process bankfields if beneficiary is defined and has elements
      if (userinfo.beneficiary && userinfo.beneficiary.length > 0) {
        bankfields.forEach((field) => {
          if (contractChosen[0]?.hasOwnProperty(field.dataKey)) {
            const fieldValue = contractChosen[0][field.dataKey];
            updatedData[field.fieldName] =
              fieldValue !== null && fieldValue !== undefined
                ? fieldValue.toString()
                : "";
          } else {
            const fieldValue = userinfo.beneficiary[0]?.[field.dataKey];
            updatedData[field.fieldName] =
              fieldValue !== null && fieldValue !== undefined
                ? fieldValue.toString()
                : "";
          }
        });
      }

      setData(updatedData);
    }
  }, [contractChosen, userinfo, applicantfields, bankfields]);

  const [openFields, setOpenFields] = useState({}); // State to track open/close status of chat components
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedFieldName, setSelectedFieldName] = useState(null);
  const handleChatIconClick = (fieldName) => {
    setOpenFields((prevOpenFields) => ({
      ...prevOpenFields,
      [fieldName]: !prevOpenFields[fieldName],
    }));
    if (fieldName === selectedFieldName && showOverlay) {
      // If the same field is clicked again, toggle off
      setShowOverlay(false);
      setSelectedFieldName(null);
    } else {
      // Otherwise, toggle on and set selected field
      setShowOverlay(true);
      setSelectedFieldName(fieldName);
    }
  };
  console.log(preview, "this is previw in draftlc----->>>>");
  return (
    <>
      {signdoc && (
        <SignDocument
          onlyReturnSign={true}
          setSigndoc={setSigndoc}
          setUpdatedDoc={(signDetails) => {
            console.log("signDetailsssssssssssssssss", signDetails);
            setData({ ...data, invSign: signDetails });
          }}
        />
      )}
      {showPopup && (
        <FinanceInvoiceModal
          limitinvoice={showPopup}
          setLimitinvoice={togglePopup}
          closeSuccess={() => togglePopup(false)}
        >
          <div className="col-md-10 mb-2 ml-5">
            <label className="text-center font-wt-600 text-color1 font-size-14 mb-2">
              Upload Company Logo
            </label>
            <FileInput
              onUploadCancel={() => {
                setData({ ...data, companyLogo: {} });
              }}
              name={"companyLogo"}
              value={data["companyLogo"]}
              onChange={(event) => {
                let file_type =
                  event.target.files?.[0]?.["type"]?.toLowerCase();
                if (
                  file_type &&
                  (file_type.includes("png") || file_type.includes("jpeg"))
                ) {
                  let reader = new FileReader();
                  reader.readAsDataURL(event.target.files[0]);
                  reader.onloadend = async (e) => {
                    let fileObj = event.target.files[0];
                    let fileDataUrl = e.target.result;
                    fileObj["filebase64"] = fileDataUrl;
                    setData({ ...data, [event.target.name]: fileObj });
                    togglePopup(false);
                  };
                } else {
                  setErrors({
                    ...error,
                    companyLogo: "Only png & jpeg images are supported",
                  });
                }
              }}
              error={error.companyLogo}
              isEditable={true}
            />
            {error.companyLogo ? (
              <div class="text-danger mt-2 font-size-12">
                <i
                  class="fa fas fa-exclamation-circle mr-1"
                  aria-hidden="true"
                ></i>
                <b>{error.companyLogo}</b>
              </div>
            ) : (
              ""
            )}
          </div>
        </FinanceInvoiceModal>
      )}
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      {showContractModal && userTypeId === 19 ? (
        <FinanceInvoiceModal
          limitinvoice={showContractModal}
          hideCloseIcon={true}
          closeSuccess={() => {
            setshowcontractmodal(!showContractModal);
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <label className="font-size-16 font-wt-600 text-center w-100 col-12 pb-3 mt-4">{`Select Contract`}</label>

            <div className="col-8">
              <NewSelect
                label={""}
                name={"contractType"}
                selectData={contractdata}
                optionLabel={"contract_name"}
                optionValue={"contract_name"}
                onChange={handleContractTypeChange}
              />
            </div>

            <div className="col-12 d-flex mt-3 ml-auto justify-content-between">
              {" "}
              {/* Center the buttons */}
              <button
                type="button"
                className="new-btn w-auto py-2 mr-4 px-4 text-white mr-2"
                style={{ marginLeft: "5rem" }}
                onClick={() => {
                  setshowcontractmodal(!showContractModal);
                }}
              >
                OK
              </button>
              <button
                type="button"
                className="new-btn w-auto py-2 px-3 ml-4 text-white"
                style={{ marginRight: "5rem" }}
                onClick={() => {
                  setshowcontractmodal(!showContractModal);
                }}
              >
                Skip
              </button>
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null}

      <div className="card mt-1">
        <div className="d-flex justify-content-center mt-5 mb-3">
          <FormProgressBar
            tabs={tabsArr}
            activeTab={tab}
            label={"name"}
            separationWidth={"3rem"}
            handleClick={(i, index) => {
              if (index < tab) {
                setTab(index);
              }
            }}
          />
        </div>
        {tab === 0 ? (
          <div className="d-flex row px-5 mb-5 pb-4 pt-4">
            <div className="col-9 my-5 p-0ml-8">
              <RichTextEditor3
                // rows={3}
                value={newTnc.newTncValue}
                name="newTncValue"
                onChange={handlenewTnc}
              />
            </div>
          </div>
        ) : null}
        {/* {tab === 1 ? (
          <div className="d-flex row px-5 mb-5 pb-4 pt-4">
            <div className="col-12 mb-4">
              <div className="accordion-item">
                <div className="row d-flex">
                  {applicantfields.map((i, j) => (
                    <div className="col-6" key={j}>
                      <label className="font-size-16 font-wt-400 mb-2">
                        {i.fieldName}
                       
                      </label>
                      <div className="mb-4 col-span-2">
                        <div className="relative">
                          <NewTextArea
                            fontClass="font-size-15 font-wt-600"
                            isDisabled={preview.show}
                            label="Enter Value"
                            name={i.fieldName}
                            value={data[i.fieldName] || ""}
                            onChange={handleChange}
                            error={error[i.fieldName]}
                          />
                          <div
                            className=" inset-y-0 right-0 flex items-center pr-3"
                            style={{
                              position: "absolute",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <img
                              src={"assets/images/chat.png"}
                              onClick={() => handleChatIconClick(i.fieldName)}
                            />
                          </div>
                        </div>

                        {openFields[i.fieldName] &&
                          showOverlay &&
                          selectedFieldName === i.fieldName && (
                            <div
                              className="mt-12"
                              style={{
                                backgroundColor: "white",
                                padding: "10px",
                                border: "1px solid #ccc",
                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                position: "absolute",
                                zIndex: 200,
                                top: "inherit",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "96%",
                              }}
                            >
                              <div className="parent-containerforLcHistory border rounded p-3">
                                <div className="remark-input mb-3">
                                  <NewTextArea
                                    fontClass="font-size-15 font-wt-600"
                                    isDisabled={preview.show}
                                    label="Enter Remark"
                                    name={`${i.fieldName}remark`}
                                    value={data[`${i.fieldName}remark`] || ""}
                                    onChange={handleChange}
                                    error={error[i.fieldName]}
                                  />
                                </div>
                                <div className="card-container border p-3">
                             
                                  {historydata.map((ele, j) => (
                                    <div>
                                      {ele[i.fieldName] && (
                                        <div
                                          className="cards-wrapper"
                                          style={{
                                            maxHeight: "200px",
                                            overflow: "scroll",
                                          }}
                                        >
                                          <div
                                            className="card-2 border p-2 mb-2"
                                            style={{ width: "100%" }}
                                          >
                                            <p>
                                              {ele[i.fieldName].changedby} |{" "}
                                              {moment(
                                                ele[i.fieldName].time
                                              ).format("YYYY-MM-DD HH:mm:ss")}
                                            </p>
                                            <p>{ele[i.fieldName].oldValue}</p>
                                            <p>{`${ele[i.fieldName]}remark`}</p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                 
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-2 mb-4"></div>
            <div className="col-8 d-flex row justify-content-end pb-2"></div>
          </div>
        ) : null} */}
        {tab === 1 ? (
          <div className="d-flex row px-5 mb-5 pb-4 pt-4">
            <div className="col-12 mb-4">
              <div className="accordion-item">
                <div className="row d-flex">
                  {applicantfields.map((i, j) => (
                    <div className="col-6" key={j}>
                      <label className="font-size-16 font-wt-400 mb-2">
                        {i.fieldName}
                        {/* {i.status === "M" ? "*" : ""} */}
                      </label>
                      <div className="mb-4 col-span-2">
                        <div className="relative">
                          <NewTextArea
                            fontClass="font-size-15 font-wt-600"
                            isDisabled={preview.show}
                            label="Enter Value"
                            name={i.fieldName}
                            value={data[i.fieldName] || ""}
                            onChange={handleChange}
                            error={error[i.fieldName]}
                          />
                          <div
                            className=" inset-y-0 right-0 flex items-center pr-3"
                            style={{
                              position: "absolute",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <img
                              src={"assets/images/chat.png"}
                              onClick={() => handleChatIconClick(i.fieldName)}
                            />
                          </div>
                        </div>

                        {openFields[i.fieldName] &&
                          showOverlay &&
                          selectedFieldName === i.fieldName && (
                            <div
                              className="mt-12"
                              style={{
                                backgroundColor: "white",
                                padding: "10px",
                                border: "1px solid #ccc",
                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                position: "absolute",
                                zIndex: 200,
                                top: "inherit",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "96%",
                              }}
                            >
                              <div className="parent-containerforLcHistory border rounded p-3">
                                <div className="remark-input mb-3">
                                  <NewTextArea
                                    fontClass="font-size-15 font-wt-600"
                                    isDisabled={preview.show}
                                    label="Enter Remark"
                                    name={`${i.fieldName}remark`}
                                    value={data[`${i.fieldName}remark`] || ""}
                                    onChange={handleChange}
                                    error={error[i.fieldName]}
                                  />
                                </div>
                                <div
                                  className="card-container border p-3"
                                  style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    overflowX: "hidden", // Ensure no horizontal scroll
                                  }}
                                >
                                  {historydata.length > 0 ? (
                                    historydata.map((change, changeIndex) => {
                                      if (change.fieldName === i.fieldName) {
                                        return (
                                          <div key={changeIndex}>
                                            {change.differences.map(
                                              (diff, diffIndex) => (
                                                <div
                                                  key={diffIndex}
                                                  className="cards-wrapper cardwrap "
                                                  style={{
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <div
                                                    className="card-2 border p-2 mb-1"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <div>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.changedby} |{" "}
                                                        {moment(
                                                          diff.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.oldValue}
                                                      </p>

                                                      <p style={{ margin: 0 }}>
                                                        Remark: {diff.remark}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })
                                  ) : (
                                    <div
                                      className="card-2 border p-2 mb-1"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>No History available</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-2 mb-4"></div>
            <div className="col-8 d-flex row justify-content-end pb-2"></div>
          </div>
        ) : null}
        {tab === 2 ? (
          <div className="d-flex row px-5 mb-5 pb-4 pt-4">
            <div className="col-12 mb-4">
              <div className="accordion-item">
                <div className="row d-flex">
                  {limitfields.map((i, j) => (
                    <div className="col-6" key={j}>
                      <label className="font-size-16 font-wt-400 mb-2">
                        {i.fieldName}
                        {/* {i.status === "M" ? "*" : ""} */}
                      </label>
                      <div className="mb-4 col-span-2">
                        <div className="relative">
                          <NewTextArea
                            fontClass="font-size-15 font-wt-600"
                            isDisabled={preview.show}
                            label="Enter Value"
                            name={i.fieldName}
                            value={data[i.fieldName]}
                            onChange={handleChange}
                            error={error[i.fieldName]}
                          />
                          {/* Chat icon placed at the right of the input area */}
                          <div
                            className=" inset-y-0 right-0 flex items-center pr-3"
                            style={{
                              position: "absolute",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <img
                              src={"assets/images/chat.png"}
                              onClick={() => handleChatIconClick(i.fieldName)}
                            />
                          </div>
                        </div>

                        {openFields[i.fieldName] &&
                          showOverlay &&
                          selectedFieldName === i.fieldName && (
                            <div
                              className="mt-12"
                              style={{
                                backgroundColor: "white",
                                padding: "10px",
                                border: "1px solid #ccc",
                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                position: "absolute",
                                zIndex: 200,
                                top: "inherit",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "96%",
                              }}
                            >
                              <div className="parent-containerforLcHistory border rounded p-3">
                                <div className="remark-input mb-3">
                                  <NewTextArea
                                    fontClass="font-size-15 font-wt-600"
                                    isDisabled={preview.show}
                                    label="Enter Remark"
                                    name={`${i.fieldName}remark`}
                                    value={data[`${i.fieldName}remark`] || ""}
                                    onChange={handleChange}
                                    error={error[i.fieldName]}
                                  />
                                </div>
                                <div
                                  className="card-container border p-3"
                                  style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    overflowX: "hidden", // Ensure no horizontal scroll
                                  }}
                                >
                                  {historydata.length > 0 ? (
                                    historydata.map((change, changeIndex) => {
                                      if (change.fieldName === i.fieldName) {
                                        return (
                                          <div key={changeIndex}>
                                            {change.differences.map(
                                              (diff, diffIndex) => (
                                                <div
                                                  key={diffIndex}
                                                  className="cards-wrapper"
                                                  style={{
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <div
                                                    className="card-2 border p-2 mb-1"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <div>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.changedby} |{" "}
                                                        {moment(
                                                          diff.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.oldValue}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        Remark:{" "}
                                                        {data[
                                                          `${i.fieldName}remark`
                                                        ]
                                                          ? data[
                                                          `${i.fieldName}remark`
                                                          ]
                                                          : "NA"}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })
                                  ) : (
                                    <div
                                      className="card-2 border p-2 mb-1"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>No History available</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-2 mb-4"></div>
            <div className="col-8 d-flex row justify-content-end pb-2"></div>
          </div>
        ) : null}
        {tab === 3 ? (
          <div className="d-flex row px-5 mb-5 pb-4 pt-4">
            <div className="col-12 mb-4">
              <div className="accordion-item">
                <div className="row d-flex">
                  {documentfields.map((i, j) => (
                    <div className="col-6" key={j}>
                      <label className="font-size-16 font-wt-400 mb-2">
                        {i.fieldName}
                        {/* {i.status === "M" ? "*" : ""} */}
                      </label>
                      <div className="mb-4 col-span-2">
                        <div className="relative">
                          <NewTextArea
                            fontClass="font-size-15 font-wt-600"
                            isDisabled={preview.show}
                            label="Enter Value"
                            name={i.fieldName}
                            value={data[i.fieldName]}
                            onChange={handleChange}
                            error={error[i.fieldName]}
                          />

                          <div
                            className=" inset-y-0 right-0 flex items-center pr-3"
                            style={{
                              position: "absolute",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <img
                              src={"assets/images/chat.png"}
                              onClick={() => handleChatIconClick(i.fieldName)}
                            />
                          </div>
                        </div>
                        {openFields[i.fieldName] &&
                          showOverlay &&
                          selectedFieldName === i.fieldName && (
                            <div
                              className="mt-12"
                              style={{
                                backgroundColor: "white",
                                padding: "10px",
                                border: "1px solid #ccc",
                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                position: "absolute",
                                zIndex: 200,
                                top: "inherit",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "96%",
                              }}
                            >
                              <div className="parent-containerforLcHistory border rounded p-3">
                                <div className="remark-input mb-3">
                                  <NewTextArea
                                    fontClass="font-size-15 font-wt-600"
                                    isDisabled={preview.show}
                                    label="Enter Remark"
                                    name={`${i.fieldName}remark`}
                                    value={data[`${i.fieldName}remark`] || ""}
                                    onChange={handleChange}
                                    error={error[i.fieldName]}
                                  />
                                </div>
                                <div
                                  className="card-container border p-3"
                                  style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    overflowX: "hidden", // Ensure no horizontal scroll
                                  }}
                                >
                                  {historydata.length > 0 ? (
                                    historydata.map((change, changeIndex) => {
                                      if (change.fieldName === i.fieldName) {
                                        return (
                                          <div key={changeIndex}>
                                            {change.differences.map(
                                              (diff, diffIndex) => (
                                                <div
                                                  key={diffIndex}
                                                  className="cards-wrapper"
                                                  style={{
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <div
                                                    className="card-2 border p-2 mb-1"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <div>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.changedby} |{" "}
                                                        {moment(
                                                          diff.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.oldValue}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        Remark:{" "}
                                                        {data[
                                                          `${i.fieldName}remark`
                                                        ]
                                                          ? data[
                                                          `${i.fieldName}remark`
                                                          ]
                                                          : "NA"}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })
                                  ) : (
                                    <div
                                      className="card-2 border p-2 mb-1"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>No History available</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}{" "}
                        {isDropdownOpen && (
                          <div className="mt-12">
                            <NewTextArea
                              fontClass="font-size-15 font-wt-600"
                              isDisabled={preview.show}
                              label="Enter Remark"
                              name={`${i.tag}remark`}
                              value={data[`${i.tag}remark`] || ""}
                              onChange={handleChange}
                              error={error[i.tag]}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-2 mb-4"></div>
            <div className="col-8 d-flex row justify-content-end pb-2"></div>
          </div>
        ) : null}
        {tab === 4 ? (
          <div className="d-flex row px-5 mb-5 pb-4 pt-4">
            <div className="col-12 mb-4">
              <div className="accordion-item">
                <div className="row d-flex">
                  {bankfields.map((i, j) => (
                    <div className="col-6" key={j}>
                      <label className="font-size-16 font-wt-400 mb-2">
                        {i.fieldName}
                        {/* {i.status === "M" ? "*" : ""} */}
                      </label>
                      <div className="mb-4 col-span-2">
                        <div className="relative">
                          <NewTextArea
                            fontClass="font-size-15 font-wt-600"
                            isDisabled={preview.show}
                            label="Enter Value"
                            name={i.fieldName}
                            value={data[i.fieldName]}
                            onChange={handleChange}
                            error={error[i.fieldName]}
                          />

                          <div
                            className=" inset-y-0 right-0 flex items-center pr-3"
                            style={{
                              position: "absolute",
                              top: "0.5rem",
                              right: "0.5rem",
                            }}
                          >
                            <img
                              src={"assets/images/chat.png"}
                              onClick={() => handleChatIconClick(i.fieldName)}
                            />
                          </div>
                        </div>

                        {openFields[i.fieldName] &&
                          showOverlay &&
                          selectedFieldName === i.fieldName && (
                            <div
                              className="mt-12"
                              style={{
                                backgroundColor: "white",
                                padding: "10px",
                                border: "1px solid #ccc",
                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                position: "absolute",
                                zIndex: 200,
                                top: "inherit",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "96%",
                              }}
                            >
                              <div className="parent-containerforLcHistory border rounded p-3">
                                <div className="remark-input mb-3">
                                  <NewTextArea
                                    fontClass="font-size-15 font-wt-600"
                                    isDisabled={preview.show}
                                    label="Enter Remark"
                                    name={`${i.fieldName}remark`}
                                    value={data[`${i.fieldName}remark`] || ""}
                                    onChange={handleChange}
                                    error={error[i.fieldName]}
                                  />
                                </div>
                                <div
                                  className="card-container border p-3"
                                  style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    overflowX: "hidden", // Ensure no horizontal scroll
                                  }}
                                >
                                  {historydata.length > 0 ? (
                                    historydata.map((change, changeIndex) => {
                                      if (change.fieldName === i.fieldName) {
                                        return (
                                          <div key={changeIndex}>
                                            {change.differences.map(
                                              (diff, diffIndex) => (
                                                <div
                                                  key={diffIndex}
                                                  className="cards-wrapper"
                                                  style={{
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <div
                                                    className="card-2 border p-2 mb-1"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <div>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.changedby} |{" "}
                                                        {moment(
                                                          diff.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        {diff.oldValue}
                                                      </p>
                                                      <p style={{ margin: 0 }}>
                                                        Remark:{" "}
                                                        {data[
                                                          `${i.fieldName}remark`
                                                        ]
                                                          ? data[
                                                          `${i.fieldName}remark`
                                                          ]
                                                          : "NA"}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })
                                  ) : (
                                    <div
                                      className="card-2 border p-2 mb-1"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>No History available</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-2 mb-4"></div>
            <div className="col-8 d-flex row justify-content-end pb-2"></div>
          </div>
        ) : null}
        {tab === 5 ? (
          <>
            {preview.show && (
              <div id={"invoiceDiv"} className="p-4">
                <div style={{ borderRadius: "5px" }}>
                  <div
                    style={{ border: "2px solid #000", borderBottom: 0 }}
                    className="d-flex justify-content-center align-items-center py-2"
                  >
                    <label className="font-size-18 font-wt-600">DRAFT LC</label>
                  </div>
                  <div className="row m-0 d-flex row">
                    <div
                      style={{ border: "2px solid #000", paddingLeft: "6rem" }}
                      className="col-12 pt-4 pb-4 pr-4"
                    >
                      <div className="col-9 my-5 p-0">
                        <div>
                          <InputForTable
                            fontClass={" font-size-20 font-wt-600 "}
                            isDisabled={preview.show}
                            placeholder={
                              !data.letterHeadTitle && preview.show
                                ? "<Letter Head Title>"
                                : ""
                            }
                            name={"letterHeadTitle"}
                            value={data.letterHeadTitle}
                            onChange={handleChange}
                          />
                        </div>
                        <label className="w-100 font-size-16 font-wt-500">
                          ===============================================================================
                        </label>
                        <div className="d-flex row">
                          <div className="d-flex row mt-3">
                            <div className="col-6">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: newTnc.newTncName,
                                }}
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: newTnc.newTncValue,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <label className="w-100 font-size-16 font-wt-500 mt-3">
                          ===============================================================================
                        </label>
                      </div>
                      {datadup.length && (
                        <div className="row d-flex">
                          {datadup?.map((i, j) => {
                            return (
                              <div className="col-12">
                                {/* <label className="font-size-16 font-wt-600 w-100 mb-2">{`${i.tag}: ${i.fieldName}`}</label> */}
                                <label className="font-size-16 font-wt-600 w-100 mb-2">
                                  {`${i.tag}: ${i.fieldName}`}
                                </label>

                                <div className="w-75 mb-4">
                                  <NewTextAreaForTable
                                    fontClass={" font-size-15 font-wt-600 "}
                                    isDisabled={preview.show}
                                    placeholder={
                                      !data[i.fieldName] && !preview.show
                                        ? "Enter Value"
                                        : ""
                                    }
                                    name={i.fieldName}
                                    value={data[i.user_fieldname]}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {preview.show && (
                  <div
                    className="px-3 py-5"
                    style={{ border: "1px solid #000", borderTop: 0 }}
                  >
                    <div className="d-flex m-0 p-0 align-items-center">
                      <label className="font-size-14 font-wt-500 w-45">
                        "WE HEREBY CERTIFY THIS INVOICE TO BE TRUE AND CORRECT."
                      </label>
                      <div className="w-55  d-flex">
                        <div className="position-relative w-50">
                          <label className="font-size-14 font-wt-500">
                            <u>Name of Authorized Signatory</u>
                          </label>
                          <InputForTable
                            isDisabled={preview.show}
                            placeholder={
                              !data.authorizedSignatoryName && !preview.show
                                ? "Enter Name"
                                : ""
                            }
                            name={"authorizedSignatoryName"}
                            value={data.authorizedSignatoryName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="position-relative w-50">
                          <label
                            onClick={() => {
                              setSigndoc(true);
                            }}
                            className="font-size-14 font-wt-500 cursor"
                          >
                            <u>Signature</u>
                            {preview.show ? null : (
                              <img
                                src="assets/images/edit.png"
                                className="cursor"
                              />
                            )}
                          </label>
                          <div>
                            {data?.invSign?.typedSign ? (
                              <label
                                style={{
                                  fontFamily: signFonts.find((i, j) => {
                                    if (j === data.invSign.typedSignStyle) {
                                      return true;
                                    }
                                  })["font"],
                                }}
                                className="font-size-16"
                              >
                                {data.invSign.typedSign}
                              </label>
                            ) : data?.invSign?.signBase64 ? (
                              <img src={data?.invSign?.signBase64} />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : null}
        <div
          style={
            tab === 0
              ? { marginTop: "-3rem", marginBottom: "1.5rem", width: "50%" }
              : { marginTop: "1.5rem", marginBottom: "1.5rem", width: "50%" }
          }
          className={"d-flex finActionTabContainer mx-4 "}
        >
          {tab !== 5 ? (
            <div
              onClick={() => {
                handleValidation();
                if (tab === 4) {
                  setPreview({ ...preview, show: !preview.show });
                }
              }}
              style={{
                borderRight: "2px solid #5CB8D3",
                flex: "1 1 50%",
                maxWidth: "50%",
              }} // Adjusted flex properties to occupy half of the container
              className={`text-center py-0 cursor bg-5CB8D3 `}
            >
              <label
                className={
                  "font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "
                }
              >
                Next
              </label>
            </div>
          ) : (
            <div
              onClick={() => {
                if (handlevalidation()) {
                  // setPreview({ ...preview, show: !preview.show });
                }

                printDiv(
                  "invoiceDiv",
                  `Draft LC - ${data[`Applicant`]}`,
                  [],
                  data,
                  setData,
                  "commInvoice"
                );
              }}
              style={{
                borderRight: "2px solid #5CB8D3",
                flex: "1 1 50%",
                maxWidth: "50%",
              }}
              className={`text-center py-0 cursor bg-5CB8D3 `}
            >
              <label
                className={
                  "font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"
                }
              >
                Submit
              </label>
            </div>
          )}
          <div
            onClick={() => {
              if (tab > 0) {
                setTab(tab - 1);
              }
            }}
            style={{
              borderRight: "2px solid #5CB8D3",
              flex: "1 1 50%",
              maxWidth: "50%",
            }} // Adjusted flex properties to occupy half of the container
            className={`position-relative text-center py-0 px-2 cursor ${tab === 0 ? " disable-bg " : " "
              }`}
          >
            <label
              className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `}
            >
              Previous
            </label>
          </div>
        </div>
        ; ;
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    navToggleState: state.navToggleState,
  };
};

export default connect(mapStateToProps, null)(DraftLC);
