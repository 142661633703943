
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"
import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
// import { reviewBG, reviewBusiness, reviewOther } from "./BGGetConfirmation";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import moment from 'moment';
import { ONDCSUBSCRIBERID, platformBackendUrl } from "../../urlConstants";
import axios from 'axios'
import { htmlFormTemplate, ondcInitialSearchObj } from "./ondcSampleObjects";


const GSTBasedInvoiceFinance = ({ userTokenDetails, navToggleState }) => {

  const [showLoader, setshowLoader] = useState(false)
  const [quotes, setQuotes] = useState([])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const quoteCardRef = useRef([])

  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("gstBasedInvoiceFinanceDiscovery");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    loadQuotes()
  }, [])

  async function loadQuotes() {
    setshowLoader(true)
    let ondcSearchObj = ondcInitialSearchObj

    ondcSearchObj["context"]["transaction_id"] = `ONDCTXN-${new Date().getTime()}`
    ondcSearchObj["context"]["message_id"] = `ONDCMSG-${new Date().getTime()}`
    ondcSearchObj["context"]["timestamp"] = moment()

    call('POST', 'searchGSTBasedInvoiceOffers', {
      "ondc": ondcSearchObj
    }).then(res => {
      setshowLoader(false)
      if (res?.length) {
        setQuotes(res)
      }
      else {
        toastDisplay("Failed to load offers", "error")
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay("Failed to load offers", "error")
    })
  }

  console.log("quotessssssssssssssssssssssssssss", quotes);

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {/* <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} /> */}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state={"otherFingstBasedInvoiceFinanceDiscovery"} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > GST Based Invoice Finance >  Offers"}
              userTokenDetails={userTokenDetails} />
            <div className='px-2 pt-4 pb-4 '>
              <div className="lineheight19">
                {/* <label className='font-wt-500 font-size-16 mb-0 letter-spacing05'><u>Select Offer </u></label> */}
                <span className="font-size-15 font-wt-600">{` (By clicking on the 'Check Offer' button, the respective financier's form will open for the further process.)`}</span><br />
              </div>
              <div id={"scrollContainerId"} style={{ flexWrap: 'nowrap', overflowX: 'hidden' }} className="row align-items-center d-flex w-100 my-4">
                {quotes?.length ? quotes.map((key, index) => {
                  return (
                    <div
                      ref={(el) => quoteCardRef.current[index] = el}
                      className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                      <div className={`text-center card py-4 pb-0 px-3 selectfinancercardrec`}
                      >
                        <>
                          <p className={`cursor ${recommendedFinanciers.includes(key.lender_id / 1) ? ' mt-5 ' : ' mt-1 '} pt-2 mb-1 d-flex flex-row justify-content-center align-items-center`}><img className=""
                            style={{ width: '5rem' }} src={key.message?.catalog?.providers?.[0]?.descriptor?.images?.[0]?.url} alt="" />
                          </p>
                          <label className="font-size-16 mt-3 font-wt-600 text-dark">{key.message?.catalog?.descriptor?.name}</label>
                        </>
                        {(
                          <>
                            <div className="mt-2 lineheight19">
                              {/* <p className="font-size-14 mb-1">
                                {`Offer Validity : ${key.offerValidity}`}</p> */}
                              <p className="mb-2" style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                            </div>
                            {key.message?.catalog?.providers?.[0]?.items?.[0]?.tags?.[0]?.list?.length ?
                              key.message?.catalog?.providers?.[0]?.items?.[0]?.tags[0].list.map((o, p) => {
                                return (
                                  <div className="mt-2 lineheight19">
                                    <p className="mb-0 text-secondary  font-size-12">{o.descriptor?.name || "-"}</p>
                                    <p className="font-size-16">{o.descriptor?.short_desc || "-"}</p>
                                  </div>
                                )
                              })
                              : null}
                          </>
                        )}
                        <div className="mb-4">
                          <button
                            onClick={() => {
                              console.log("cccccccccccccccccccccccccccccccc", key,);
                              if (key?.message?.catalog?.providers?.[0]?.items?.[0]?.xinput?.form?.mime_type === "text/html" && key?.message?.catalog?.providers?.[0]?.items?.[0]?.xinput?.form?.url) {
                                call('POST', 'getONDCHtmlForm', { url: key?.message?.catalog?.providers?.[0]?.items?.[0]?.xinput?.form?.url }).then(res => {
                                  console.log('axiosThennnnnnnnnnnnnn', res);
                                  localStorage.setItem('offerData', JSON.stringify(key))
                                  localStorage.setItem("ondcHtmlFormContent", res)
                                  window.location = '/FillFormONDC'
                                }).catch(err => {
                                  toastDisplay("Unable to open form", "error")
                                })
                              }
                              else {
                                toastDisplay("Something went wrong", "error")
                              }
                            }}
                            className={`mx-2  w-75 py-2 px-2 new-btn text-white `}>Check Offer</button>
                        </div>
                        {/* <p className="mt-1 mb-4"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-12 color434343 letter-spacing05 ms-2 cursor"
                          onClick={async () => {
                            let reqObj = {
                              senderId: userId,
                              receiverId: key.lender_id / 1,
                              textMessage: 'Hii',
                              chat_room_name: "CHAT" + new Date().getTime(),
                              includeAdmins: true,
                              bgApplicationId: bgQuoteDetails.applicationId,
                              receiverParties: key.lender_id / 1,
                              dontSendInitialMsg: true
                            }
                            setshowLoader(true)
                            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                            setshowLoader(false)
                            getChatDetails({
                              chat_room_id: apiResp.id,
                              loggedInUser: userId
                            })
                            setSelectedChat({
                              chatRoomId: apiResp.id,
                              receiverName: `${key.lender_name} - ${apiResp.id}`,
                              bgApplicationId: bgQuoteDetails.applicationId,
                              parties: apiResp.parties,
                              userId: userId,
                              isChatOpen: true,
                              receiverId: key.lender_id / 1
                            })
                          }}
                        >Chat with Insurer</span></p> */}
                      </div>
                    </div>
                  )
                }) : null}
              </div>
              {quotes?.length > 4 ? (
                <div className="col-12 mx-auto text-end">
                  <img onClick={() => {
                    scrollLeft()
                  }}
                    className={` mx-3 cursor fs-5 cursor`} src={"/assets/images/left_side_circle_arrow.svg"} alt="" />
                  <img onClick={() =>
                    scrollRight()
                  }
                    className={`cursor fs-5`} src={"/assets/images/right_side_circle_arrow.svg"} alt="arrow" />
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(GSTBasedInvoiceFinance)