import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
// import ReportTrailModal from './reportTrailModal'
import FilePreview from '../utilComponents/previewFile'
import validate from '../../utils/validation_files/invReportValidationRules'
import AddUserInContract from '../utilComponents/addUserInContract.js'
import avatarUrl from '../../utils/makeAvatarUrl';

const InvestigationReportForm = ({ userTokenDetails, contractNo, contractData, mktPlaceContract, commoditySpecs }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [dbData, setDbData] = useState({})
  const [repData, setrepData] = useState({})
  const [specOrder, setspecOrder] = useState([])


  const [dbDoc, setdbDoc] = useState({})
  const [spec, setSpecification] = useState({});
  const [specShow, setSpecificationShow] = useState({});
  const [canSubmitReport, setcanSubmitReport] = useState(true);
  const [refresh, setrefresh] = useState(0);
  const [reportDataDB, setreportDataDB] = useState([])
  const [fileData, setfileData] = useState({})
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [showLoader, setshowLoader] = useState(false);

  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {


    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    if (userTypeId === 6) {

      call('POST', 'getcontractinvreport', {
        "contract_number": contractNo, "userTypeId": userTypeId, "userId": userId,
        mktPlaceContract
      }).then((result) => {
        console.log("result in getcontractinvreport api->", result)

        if (mktPlaceContract && result.reportdata && Object.keys(result.reportdata).length) {
          setcanSubmitReport(false)
          setSpecificationShow(result.reportdata.report_details ? result.reportdata.report_details : {})
          setspecOrder(result.reportdata.report_details ? Object.keys(result.reportdata.report_details) : "")
        }
        else if (mktPlaceContract) {
          setSpecificationShow(commoditySpecs)
          setSpecification(commoditySpecs)
          setspecOrder(Object.keys(commoditySpecs))
        }
        else {
          setcanSubmitReport((result.contractdata.investigation_status / 1 > 0 && result.reportdata.report_status / 1 > 0) ? false : true)
          setSpecificationShow(result.contractdata.specification ? result.contractdata.specification : {})
          setspecOrder(result.contractdata.json_order.specOrder.split(":"))
        }

        if (result.reportdata && (result.reportdata.report_status / 1) > 0) {
          setSpecification(result.reportdata.report_details ? result.reportdata.report_details : {})

          setrepData(repData => ({
            ...repData,
            reportRemark: result.reportdata.remark
          }));

        } else if (!mktPlaceContract) {

          let shallowCopy = result.contractdata.specification ? JSON.stringify(result.contractdata.specification) : JSON.stringify({})
          let newSpecObj = JSON.parse(shallowCopy)
          let keyArray = Object.keys(newSpecObj)

          for (let i = 0; i < keyArray.length; i++) {
            newSpecObj[keyArray[i]].value = ""
          }
          setSpecification(newSpecObj)

          setrepData(repData => ({
            ...repData,
            "resportSpecifications": newSpecObj,
            "contractId": result.contractdata.contract_number ? result.contractdata.contract_number : "",
            "seller": result.contractdata.exporter_id,
            "buyer": result.contractdata.importer_id,
          }));

        }

        setDbData(result.contractdata)
        setdbDoc(result.reportdoc)
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log("Error while querying getcontractinvreport:", e);
      })
    } else {
      call('POST', 'getcontractinvreport', {
        "contract_number": contractNo, "userTypeId": userTypeId, "userId": userId,
        mktPlaceContract
      }).then((result) => {
        console.log("result in getcontractinvreport api2->", result)
        setreportDataDB(result)
        // setspecOrder(result.contractdata.json_order.specOrder.split(":"))
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log("Error while querying getcontractinvreport:", e);
      })

    }

    //------------------------------------------------------------------

  }, [refresh]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleSpecification = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setSpecification(specdataElement);
    setError({ ...error, [tempArray[0]]: "" });
    setrepData(repData => ({ ...repData, "resportSpecifications": specdataElement }));

  }


  const handleChange = (event) => {
    event.persist();
    setrepData(repData => ({ ...repData, [event.target.name]: event.target.value }));
  }

  const handleFile = (event) => {
    event.persist();
    setrepData(repData => ({ ...repData, [event.target.name]: event.target.files[0] }));
  }


  function callPreviewFileComponent(id, action) {
    setfileData({ "action": action, "tbl_doc_id": id, })
    setshowPreviewModal(true)
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmit() {
    setshowLoader(true)

    repData.resportSpecifications = JSON.stringify(repData.resportSpecifications)

    let formData = new FormData();

    Object.keys(repData).forEach((key) => {
      formData.append(key, repData[key]);
    })

    if (mktPlaceContract) {
      formData.append("mktPlaceContractNo", contractNo)
      formData.append("mktPlaceContract", true)
    }

    call('POST', 'submitReport', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay(result, "success");
        setrefresh(refresh + 1)
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err.message, "error");
    })
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(repData, userTypeId));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components

  function investigationReportForm(contractSpec, reportSpec) {
    let contractkeyArray = specOrder.length ? specOrder : Object.keys(contractSpec).length ? Object.keys(contractSpec) : []
    return (
      <div className="col-md-12">

        <h3 className="pt-2 pb-2"><u>Report Remarks</u></h3>
        <div className="col-md-12 row">
          <label>Enter Remark</label>
          <textarea rows="2" className={" form-control" + (!error.reportRemark ? '' : ' border-danger')} name='reportRemark' value={repData.reportRemark} onChange={handleChange} disabled={canSubmitReport ? false : true}></textarea>
          {error.reportRemark ? <div class="text-danger error-contract">{error.reportRemark}</div> : ''}
        </div>
        <hr />

        <h3 className="pt-2 pb-2"><u>Report Documentation</u></h3>
        <div className="col-md-12 row">
          {canSubmitReport && <div className="col-md-12 row">
            <div className="file-browse col-md-3">
              <button className="btn btn-primary btn-sm">Select Report Doc</button>
              <input type="file" accept=".png,.jpg,.pdf" name="reportDocument" onChange={handleFile} />
            </div>
            {(repData.reportDocument) && <div className="col-md-8">
              <div className="filePath"><div className="file-name">{repData.reportDocument.name}</div></div>
            </div>}
            {(error.reportDocument) && <div className="col-md-8">
              <div class="text-danger error-contract">{error.reportDocument}</div>
            </div>}
          </div>}

          <div className="col-md-6">
            {(dbDoc.file_hash && dbDoc.file_name) && <>
              <button className=" text-center btn btn-success btn-sm mt-0" onClick={
                () => callPreviewFileComponent(dbDoc.id, "view")}>
                View Last Report
              </button></>}
          </div>

          {/* <div className="col-md-6">
            {(dbDoc.file_hash && dbDoc.file_name) && <>
              <button className=" text-center btn btn-success btn-sm mt-0" onClick={() => setenablereportTrail(true)}>
                View Report Trail
                </button></>}
          </div> */}
        </div>
        <hr />

        <h3 className="pt-2 pb-2"><u>Report Specification</u></h3>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped table-bordered mb-0" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Report Value</th>
                </tr>
              </thead>
              <tbody>
                {
                  contractkeyArray.map((key) => {
                    if (contractSpec[key]) {
                      return (
                        <tr>
                          <td>{contractSpec[key].name ? contractSpec[key].name : '--'} <span className="required-field text-danger">*</span></td>
                          <td>
                            <input type="text" name={key ? key + ',value' : ''} value={reportSpec[key] && reportSpec[key].value ? reportSpec[key].value : ""} className={" form-control" + (!error[key] ? '' : ' border-danger')} onChange={handleSpecification} disabled={canSubmitReport ? false : true} />
                            {error[key] ? <div class="text-danger error-contract" id={error[key]}>{error[key]}</div> : ''}
                          </td>
                        </tr>
                      )
                    }
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    )
  }



  function reportshowBody(contractSpec, specification, order) {
    if (!mktPlaceContract) {
      order = order.specOrder.split(":")
    }
    else {
      contractSpec = specification
    }
    let keyArray = order && order.length ? order : Object.keys(specification).length ? Object.keys(specification) : []

    console.log("contractSpec==>", contractSpec)
    console.log("specification==>", specification)

    // const operators = {
    //   '>': function (a, b) { return a > b },
    //   '<': function (a, b) { return a < b },
    // };

    const operators = (symbol, a, b) => {
      if (symbol === ">") {
        return a > b
      }
      if (symbol === "<") {
        return a < b
      }
    }

    return (
      <div className="">
        <ul className="list-unstyled">
          <li>
            <div className="col-md-12 row">
              <div className="col-md-4 text-left">
                <label className="text-primary">Properties</label>
              </div>
              {!mktPlaceContract && (
                <div className="col-md-2">
                  <label className="text-primary">Contract value</label>
                </div>
              )}
              <div className="col-md-2">
                <label className="text-primary">Rejection value</label>
              </div>
              <div className="col-md-2">
                <label className="text-primary">Report value</label>
              </div>
              {/* <div className="col-md-2">
                <label className="text-primary">Status</label>
              </div> */}
            </div>
          </li>
          {
            keyArray.map((key) => {
              console.log("key==>", key)
              return (
                <li>
                  <div className="col-md-12 row">
                    <div className="col-md-4 text-left">
                      {contractSpec[key].name ? contractSpec[key].name : '--'}
                    </div>
                    {!mktPlaceContract && (
                      <div className="col-md-2">
                        {contractSpec[key].value ? contractSpec[key].value : '--'}
                      </div>
                    )}
                    <div className="col-md-2">
                      {contractSpec[key].rejectionValue ? contractSpec[key].rejectionValue : '--'}
                    </div>
                    <div className="col-md-2">
                      {specification[key].value ? specification[key].value : '--'}
                    </div>
                    {/* <div className="col-md-2">
                      {(key === "type" ? (contractSpec[key].value === specification[key].value ?
                        <i className="fa fa-check text-success" aria-hidden="true"></i> :
                        <i className="fa fa-times text-danger" aria-hidden="true"></i>) :
                        // contractSpec[key].rejectionSymbol && operators[contractSpec[key].rejectionSymbol](contractSpec[key].rejectionValue / 1, specification[key].value / 1) ?
                        contractSpec[key].rejectionSymbol && operators(contractSpec[key].rejectionSymbol, contractSpec[key].rejectionValue / 1, specification[key].value / 1) ?
                          <i className="fa fa-check text-success" aria-hidden="true"></i> :
                          <i className="fa fa-times text-danger" aria-hidden="true"></i>)}
                    </div> */}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //console.log("dbDoc-->", fileData)
  console.log("repData in inv rep comp-->", repData)
  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {(contractData.inv_agency_importer / 1 === 0 && contractData.inv_agency_exporter / 1 === 0 && contractData.inv_agency_third / 1 === 0) ?
        <>
          <AddUserInContract
            userTokenDetails={userTokenDetails}
            addUserData={{ usertype: 6, name: "Investigation Agency" }}
            contractData={contractData}
            refresh={refresh}
            setrefresh={setrefresh}
          /></> :
        <div className="mt-5">

          {(userTypeId === 6) && <>
            {(dbData.status_seller === 1 && dbData.status_buyer === 1) || mktPlaceContract ?
              <>
                {investigationReportForm(specShow, spec)}
                <hr />
                {canSubmitReport && <div className="text-right">
                  <button type="button" className="btn btn-danger mr-3 btn-sm" onClick={""}>Cancel</button>
                  <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Submit</button>
                </div>}
              </> :
              <div className="col-md-12">
                <h3 className="green pb-2">Contract is in Pilot Mode</h3>
              </div>
            }
          </>}

          {(userTypeId !== 6) && <>
            {(reportDataDB.length) ? <>
              {(reportDataDB && reportDataDB.length) ? reportDataDB.map((value) => {
                return (
                  <>
                    <h3 className="pb-2"><u>
                      {value.reportFrom === 'importerAgencyReport' ? "Report Submitted by Importer's Agency" :
                        value.reportFrom === 'exporterAgencyReport' ? "Report Submitted by Exporter's Agency" :
                          value.reportFrom === 'thirdAgencyReport' ? (mktPlaceContract ? "Report Submitted by Investigation Agency" :
                            "Report Submitted by Third Party Agency") : "--"}
                      {/* {statusPrint(value.report_status)} */}
                    </u></h3>
                    <div className="col-md-12 row">
                      <div className="col-md-10">
                        {value.report_details && reportshowBody(value.contractSpec, value.report_details, value.json_order)}
                      </div>
                      <div className="col-md-2">
                        <ul className="report-image">
                          <li className="text-center">
                            {/* <button className=" text-center btn btn-sm mt-0" onClick={() => { setenablereportTrail(true); setagencyId(value.created_by) }}>
                              <i className="fa fa-wpforms fa-3x " aria-hidden="true"></i>
                            </button> */}
                            <button className=" text-center btn btn-sm mt-0" onClick={() => callPreviewFileComponent(value.tbl_doc_id, "view")}>
                              <i className="fa fa-wpforms fa-3x " aria-hidden="true"></i>
                            </button>
                            <br />
                            <label className="">View Report</label>
                          </li>
                          <li className="text-center">
                            <img src={avatarUrl(value.user_avatar)} height="100" />
                            <label>Report by {value.company_name}</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                  </>
                )
              }) : ''}
            </> :
              <div className="col-md-12">
                <h3 className="green pb-2">Agency has not Submitted any Reports yet</h3>
              </div>}
          </>}
        </div>}

      {/* {
        (enablereportTrail) &&
        <ReportTrailModal
          userTokenDetails={userTokenDetails}
          trailBool={enablereportTrail}
          settrailBool={setenablereportTrail}
          contractid={contractNo}
          agencyId={agencyId} />
      } */}

      {showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}
    </>)
}


export default InvestigationReportForm
