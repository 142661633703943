

import React, { useEffect, useState } from 'react';
import call from '../../../service';
import { formatDate_Application } from '../../../utils/dateFormaters';
import toastDisplay from '../../../utils/toastNotification';


const BankDocumentsComp = ({ userTokenDetails, contractData, setfileData, setshowPreviewModal, setshowLoader, refresh, setrefresh, processStatus }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [dbDocData, setdbDocData] = useState({});
  const [pendingDoc, setpendingDoc] = useState([]);
  const [unsignedDocArray, setunsignedDocArray] = useState([]);
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getDocList', { "cid": contractData.contractNo, "userId": userId, "userTypeId": userTypeId }).then((result) => {
      console.log("doc data in doc bank page-->", result)
      setdbDocData(result);
    }).catch((e) => {
      console.log("Error while querying Doc:", e);
    })

    console.log("contractData ---->", contractData)


    call('POST', 'getpendingdocuments', { "contract_number": contractData.contractNo, linkedDocs: contractData.linkedDocs }).then((result) => {
      console.log("setpendingDoc-->", result)
      setpendingDoc(result);
    }).catch((e) => {
      console.log("Error while querying Doc:", e);
    })

    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------
  console.log("unsignedDocArray ---->", unsignedDocArray)
  console.log("pendingDoc ---->", pendingDoc)
  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  function generateBody() {
    let keyArray = Object.keys(dbDocData)

    let finalResult = keyArray.length ? keyArray.map((key) => {

      if (dbDocData[key].categoryData.length) {
        return (
          <div className="col-md-12 mt-2 mb-2 text-left">
            <h3>{dbDocData[key].categoryPrettyname}</h3>
            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th width="20">#</th>
                  <th>Type of Document</th>
                  <th width="200">Document Name</th>
                  <th width="150">Uploaded By</th>
                  <th width="150">Uploaded On</th>
                  <th width="150">Signed By</th>
                  <th className="text-center" width="250">Actions</th>
                </tr>
              </thead>
              <tbody>
                {dbDocData[key].categoryData.map((values, index) => {

                  if ([2, 3, 8, 9, 10, 11, 12, 14, 23].includes(values.doc_type / 1)) {
                    if (values && values.docSignArray.length) {
                      let uniqeArray = []
                      for (let i = 0; i < values.docSignArray.length; i++) {
                        if (!uniqeArray.includes(values.docSignArray[i].id)) {
                          uniqeArray.push(values.docSignArray[i].id)
                        }
                      }
                      if (uniqeArray.length < 2) {
                        // let tempArray = [...unsignedDocArray]
                        // tempArray.push(values.document_name)
                        // setunsignedDocArray(tempArray)
                        unsignedDocArray.push(values.document_name)
                      }
                    } else {
                      // let tempArray = [...unsignedDocArray]
                      // tempArray.push(values.document_name)
                      // setunsignedDocArray(tempArray)
                      unsignedDocArray.push(values.document_name)
                    }
                  }

                  return (<tr>
                    <td>{index + 1}</td>
                    <td>{(values && values.document_name) ? values.document_name : 'NA'}</td>
                    <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
                    <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td>
                    <td>{(values && values.uploaded_on) ? formatDate_Application(values.uploaded_on) : 'NA'}</td>
                    <td>
                      {(values && values.docSignArray.length) ? <pre><span className="text-success mr-1">{values.docSignArray.map((signV) => { return (<><i className="fa fa-certificate" /> {signV.sign}<br /></>) })}</span></pre> :
                        ''}
                    </td>
                    <td className="row justify-content-center  m-0 ">
                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(values, "view")}>
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(values, "download")}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr >)
                })}
              </tbody>
            </table>
          </div>
        )
      }
    }) : ""

    return finalResult

  }


  function callPreviewFileComponent(values, action) {
    setfileData({ ...values, "action": action })
    setshowPreviewModal(true)
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function submitLCprocessData() {
    setshowLoader(true)
    console.log("processStatus==>", 8)
    let postObj = {
      "contract_id": contractData.id,
      "contract_no": contractData.contractNo,
      "party_a": contractData.exporter_id,
      "party_b": contractData.buyer_bank,
      "process_status": 8,
      "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank
    }

    console.log("postObj===>", postObj)

    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    call('POST', 'updatelcprocess', formData).then(async (result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      //toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in updatelcprocess ->", error)
      toastDisplay(error, "error")
    })

  }

  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="col-md-12 mt-3 text-center row">
      <h2 className=""> Following documents are available </h2>
      {generateBody()}
      {(processStatus === 7) && <>
        <hr />
        <div className="col-md-12 mt-2 mb-2 text-center">
          <h2 className="mt-5"> Following documents are Pending </h2>
          <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th width="20">#</th>
                <th>Type of Document</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(pendingDoc.length) ? pendingDoc.map((doc, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{doc.document_name}</td>
                    <td><span className="text-warning mr-1"><i className="fa fa-hourglass-start" /> Pending</span> </td>
                  </tr>
                )
              }) : ""
              }
            </tbody>
          </table>
        </div>

        <div className="col-md-12 mt-2 mb-2 text-center">
          {console.log("unsignedDocArray.length ===>", unsignedDocArray.length)}
          {(!unsignedDocArray.length || pendingDoc.length) ?
            <h3 className="text-danger error-contract"> Document Submission will be open once all party have signed the mandatory documents, Please ensure all doc are signed </h3>
            :
            <button type="button" className="btn btn-success btn-sm" onClick={() => submitLCprocessData()}>Present Documents for LC Reimbursement</button>
          }
        </div>
      </>}
    </div>
  </>)
}


export default BankDocumentsComp
