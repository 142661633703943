import React from 'react';

export default function SmallCard({ title, number, src, height, width, handleClick, selected,isborder }) {
  const cardStyle = selected
    ? {
      backgroundColor: 'rgb(27, 148, 183)',
      color: 'white',
      border: '1px solid rgb(27, 148, 183)',
      width: width,
      height: height,
      borderLeft: '5px solid grey',
    }
    : {
      width: width,
      height: height,

      borderLeft: '5px solid grey',
    };

  const numberStyle = {
    color: selected ? 'white' : '#1B94B7',
  };

  return (
    <div
      style={cardStyle}
      className={`shadow rounded ms-1 ${isborder ? "border":""}` }
      onClick={() => handleClick(title)}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <div className="p-2">
          <p className="m-0 fw-bold" style={numberStyle}>{title}</p>
          <p className="mt-0 fw-bold" style={numberStyle}>{number}</p>
        </div>
        {src && (
          <div className="p-2 me-2 rounded-circle" style={{ border: '0.5px solid grey' }}>
            <img className="" src={src} alt={`${title} icon`} />
          </div>
        )}

      </div>
      
    </div>
  );
}
