import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Area, AreaChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import call from '../../service';
import Filter from '../InvoiceDiscounting/components/Filter';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import LineChartComponent from '../Reports/components/LineChartComponent';
import PieChartComponent from '../Reports/components/PieChartComponent';
import { AccordionTable } from '../wallet/components/accordionTable';
import { NewTable } from '../../utils/newTable'
import { NewInput, NewSelect, NewTextArea } from '../../utils/newInput';
import toastDisplay from '../../utils/toastNotification';
import { ExportExcel, getInitials, insertObjectInArray } from '../../utils/myFunctions';
import UserDetails from '../UserOnboard/UserDetails';
import ChannelPartnerDetails from '../UserOnboard/ChannelPartnerDetails';
import CustomLineChart from '../CRM/CustomLineChart';
import { ExpandableTable } from '../wallet/components/ExpandableTable';
const discontingConfig = [
  { dataKey: 'discounting', fill: '#5CB8D3', display: 'Discounting' }
]
const labels = {
  'value': 'Exporters',
  'FinValue': 'Buyers',
  'CPValue': 'Channel Partner'
}
const labelsEarning = {
  'value': 'Plans Earning',
  'valuePlan': 'Commission Earning'
}
const lanesColor = ['#E8AF7B', '#98BCDE', '#FDB601', '#F887E0']

const invoiceLcStatusColor = ['#48DA87', '#FF7B6D', '#FFAC1C']

const invoiceLcStatus = [
  { label: "Approved", color: '#48DA87' },
  { label: "Rejected", color: "#FF7B6D" },
  { label: "Inprocess", value: "#FFAC1C" }

]

// const salesPerson = [
//   "Nishi",
//   "Fiza",
//   "Manju",
//   "Dhruvi"
// ]

export const AdminDashbord = ({ userTokenDetails, dateRange, subadmins }) => {

  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")


  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dbData, setdbData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [salesPerson, setSalesPerson] = useState([])


  const [stats, setStats] = useState({})
  const [graphConfiguration, setGraphConfiguration] = useState({
    earningDuration: "6 Months",
    onbordedDuration: "6 Months",
    earningGraphMode: true,
    onbordedDurationGraphMode: true,
    onboardedGraphMode: true,
    invoiceLcGraphMode: true,
    from: dateRange.from,
    to: dateRange.to,
    applicationStageGraphMode: true,
    isDiscountingGraphMode: true
  })
  const [viewDetails, setViewDetails] = useState({
    type: '',
    isVisible: false,
    data: {}
  })
  const [overalldata, setoveralldata] = useState([])
  const [notePopup, toggleNotePopup] = useState({ show: false, data: "", selectedIndex: null, noteFor: "" })
  const [userOnboardSelect, setUserOnboardSelect] = useState('all')
  const [onboardGraphdata, setOnboardGraphdata] = useState([])

  const [EarningSelect, setEarningSelect] = useState('all')
  const [earningGraphData, setearningGraphData] = useState([])
  const [discountingGraph, setdiscountingGraph] = useState([])
  const [discountingoverallGraph, setdiscountingoverallGraph] = useState([])
  const [graphData, setGraphData] = useState({})
  const [stageTable, setStageTable] = useState([])
  const [discountingtbl, setdiscountingtbl] = useState([])
  const userId = userTokenDetails?.user_id
  const typeId = userTokenDetails?.type_id
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  console.log('dateRange', dateRange);
  useEffect(() => {
    if (userPermissionsForSubAdmin.mainAdmin || userPermissionsForSubAdmin?.["Assign Task"]) {
      setshowLoader(true)
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getTransactionCounts', {
      applicationStageFrom: dateRange.from,
      applicationStageTo: dateRange.to
    }).then(result => {
      setGraphData(result)
      setshowLoader(false)
      let overalldata = []
      const lcdata = []
      lcdata.push("LC")
      lcdata.push(result["LC_Limit_Count"])
      lcdata.push(result["LC_Quotes_Count"])
      lcdata.push(result["LC_Contracts_Count"])
      lcdata.push(result["LC_Apply_Fin_Count"])
      lcdata.push(result["LC_Agreement"])
      lcdata.push(result["LC_Approved_Fin_Count"])
      overalldata.push(lcdata)

      let invdata = []
      invdata.push("Invoice Discounting")
      invdata.push(result["INV_Limit_Count"])
      invdata.push(result["INV_Quotes_Count"])
      invdata.push(result["INV_Contracts_Count"])
      invdata.push(result["INV_Apply_Fin_Count"])
      invdata.push(result["INV_Agreement"])
      invdata.push(result["INV_Approved_Fin_Count"])
      overalldata.push(invdata)
      setStageTable(overalldata)

    }).catch(e => {
      console.log('errror in getTransactionCounts', e);
      setshowLoader(false)
    })
  }, [graphConfiguration])
  const getTasks = () => {
    setshowLoader(true)
    let dateRangeFilter = [moment().subtract(3, "days").format("YYYY-MM-DD"), moment().add(1, "days").format("YYYY-MM-DD")]
    let objectAPI = {
      dateRangeFilter,
      currentPage: page,
      resultPerPage: filter.resultPerPage,
      isTodaysTasks: true,
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getTasksForAdmin', objectAPI)
      .then((result) => {
        setdbData(formatDataForTable(result.message))
        setCount(result.totalCount)
        setoveralldata(result.message)
        setshowLoader(false)
      }).catch(e => {
        setshowLoader(false)
      })
  }
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getTasks()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  useEffect(() => {
    getTasks()
  }, [page, refresh, salesPerson, subadmins])
  useEffect(() => {
    setGraphConfiguration({
      ...graphConfiguration,
      from: dateRange.from,
      to: dateRange.to
    })
  }, [dateRange])

  async function downloadActiveUserApplicationStage() {
    if (!stageTable?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let temp = stageTable
      let csvString = "Application Stage,Finance Limit,Quote,Termsheet/Contract,Finance,Agreement,Approved\r\n"
      let rowString = `${temp[0][0]},${temp[0][1]},${temp[0][2]},${temp[0][3]},${temp[0][4]},${temp[0][5]},${temp[0][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      rowString = `${temp[1][0]},${temp[1][1]},${temp[1][2]},${temp[1][3]},${temp[1][4]},${temp[1][5]},${temp[1][6]}\r\n`
      rowString = rowString.replace(/(\n)/gm, "")
      csvString += rowString

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ApplicationStages.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadActiveUserApplicationStage", error);
    }
  }
  // function formatDataForTable(data) {
  //   let tableData = []
  //   let row = []
  //   for (let index = 0; index < data.length; index++) {
  //     const item = data[index];

  //     row[0] = item.supplierName
  //     row[1] = item.selectedLenderName || item.lenderName || "NA"
  //     row[2] = item.supplierCountryName
  //     row[3] = `${item.invoiceApplication ? "Invoice" : "LC"} ${item.atLimitStage ? " Limit Application " : " Finance Application "}`

  //     if (item.atOnboardingStage) {
  //       if (item.buyerId) {
  //         row[3] = "New Buyer"
  //       }
  //       else {
  //         row[3] = "New Exporter"
  //       }
  //     }

  //     row[4] = "NA"
  //     row[5] = "NA"
  //     if (item.invoiceApplication) {
  //       if (item.atLimitStage) {
  //         if (item.termSheet) {
  //           if (!item.termSheetSignedByExporter || !item.termSheetSignedByBank) {
  //             row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{`Term sheet sent by financier, waiting for
  //             ${!item.termSheetSignedByExporter ? " exporter," : ""} ${!item.termSheetSignedByBank ? " bank," : ""} to sign`}</label>
  //           }
  //           else if (!item.invRefNo) {
  //             row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{`Term sheet has signed by both parties, waiting for user to upload the invoice`}</label>
  //           }
  //           else if (item.invRefNo) {
  //             row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{`User has uploaded the invoice`}</label>
  //           }
  //         }
  //         else if (!item.buyers_credit) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Application sent waiting for quote"}</label>
  //         }
  //         else if (item.buyers_credit) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Quote received from financier"}</label>
  //         }
  //       }
  //       if (item.atFinanceStage) {
  //         let isBuyerSignedAgreement = (item.frameworkBuyerSign || item.exhibitBuyerSign || item.noaBuyerSign)
  //         let isExporterSignedAgreement = (item.frameworkExporterSign || item.exhibitExporterSign || item.noaExporterSign)
  //         let isFinancierSignedAgreement = (item.frameworkFinancierSign || item.exhibitFinancierSign || item.noaFinancierSign)
  //         if (isBuyerSignedAgreement || isExporterSignedAgreement || isFinancierSignedAgreement) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>
  //             {`Application signed by
  //             ${isBuyerSignedAgreement ? ' Buyer,' : ''}
  //             ${isExporterSignedAgreement ? ' Exporter,' : ''}
  //             ${isFinancierSignedAgreement ? ' Financier' : ''}`}</label>
  //         }
  //         else if (item.frameworkDoc || item.exhibitDoc || item.noaDoc) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Application sent by financier"}</label>
  //         }
  //         else if (item.invRefNo) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Applied for invoice discounting"}</label>
  //         }
  //       }
  //     }
  //     else if (item.lcApplication) {
  //       if (item.atLimitStage) {
  //         if (!item.financierQuotes) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Application sent by supplier, waiting for quote from financier"}</label>
  //         }
  //         else if (item.selectedFinancier && !item.quoteLocked) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Financer selected by supplier, waiting for financier to lock the deal"}</label>
  //         }
  //         else if (item.financierQuotes) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{"Quote received from financier"}</label>
  //         }
  //       }
  //     }
  //     else {
  //       if (item.atOnboardingStage) {
  //         if (item.buyerId) {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{`Buyer (${item.buyerName}) added but didn't applied for limit`}</label>
  //         }
  //         else {
  //           row[5] = <label className='font-wt-600 font-size-14 text-48DA87'>{`User onboarded but didn't added any buyer`}</label>
  //         }
  //       }
  //     }
  //     tableData.push(row)
  //     row = []
  //   }
  //   return tableData
  // }


  // useEffect(() => {
  //   if (dbData?.length) {
  //     setTableData(formatDataForTable(dbData))
  //   }
  // }, [dbData])
  async function handleTransactionPopupLC(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.id, applicationId: itemData.applicationId
    })
    console.log("getTransactionHistoryForLC api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  async function handleTransactionPopupINV(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForInvoiceLimit', {
      buyerId: itemData.id,
      applicationId: itemData.applicationId,
      invRefNo: itemData.reference_no
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }
  useEffect(() => {
    let overalldata = []
    if (userOnboardSelect === 'all') {
      overalldata = stats.customerOnboardedData || []
    } else {
      let keysincluded = ["label"]
      keysincluded.push(userOnboardSelect)
      overalldata = stats.customerOnboardedData?.map(obj => {
        const newObj = {};
        keysincluded.forEach(key => {
          newObj[key] = obj[key];
        });
        return newObj;
      }) || []
    }
    setOnboardGraphdata(overalldata)
  }, [userOnboardSelect, subadmins])

  useEffect(() => {
    let overalldata = []
    if (EarningSelect === 'all') {
      overalldata = stats.earningGraphData || []
    } else {
      let keysincluded = ["label"]
      keysincluded.push(EarningSelect)
      overalldata = stats.earningGraphData?.map(obj => {
        const newObj = {};
        keysincluded.forEach(key => {
          newObj[key] = obj[key];
        });
        return newObj;
      }) || []
    }
    console.log('userOnboardSelect', overalldata);
    setearningGraphData(overalldata)
  }, [EarningSelect, subadmins])
  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      ...graphConfiguration,
      onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
    }
    if (subadmins && subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = subadmins
    }
    call('POST', 'getAdminDashboardStats', objectAPI).then(res => {
      console.log("getAdminDashboardStats then====>", res);
      setshowLoader(false)
      setStats(res)
      setOnboardGraphdata(res.customerOnboardedData)
      setearningGraphData(res.earningGraphData)
    }).catch(err => { setshowLoader(false) })

    let dateRangeFilter = [moment().subtract(3, "days").format("YYYY-MM-DD"), moment().add(1, "days").format("YYYY-MM-DD")]

    let tempData = []

    // call('POST', 'getInvoiceDiscountingListForAdmin', { dateRangeFilter, atLimitStage: true }).then((result) => {
    //   // console.log('atLimitStage-->', result);
    //   tempData = tempData.concat(insertObjectInArray(result.listData, { atLimitStage: true, invoiceApplication: true }))

    //   call('POST', 'getInvoiceDiscountingListForAdmin', {
    //     dateRangeFilter, atFinanceStage: true
    //   }).then((result) => {
    //     // console.log('atFinanceStage-->', result);
    //     tempData = tempData.concat(insertObjectInArray(result.listData, { atFinanceStage: true, invoiceApplication: true }))

    //     call('POST', 'getLCListForAdmin', { dateRangeFilter, atLimitStage: true }).then((result) => {
    //       // console.log('getLCListForAdmin atLimitStage-->', result);
    //       tempData = tempData.concat(insertObjectInArray(result.listData, { atLimitStage: true, lcApplication: true }))

    //       call('POST', 'getTodaysUpdateForAdminDashboard', { dateRangeFilter }).then((result) => {
    //         // console.log('getTodaysUpdateForAdminDashboard-->', result);
    //         tempData = tempData.concat(insertObjectInArray(result, { atOnboardingStage: true }))
    //         setDbData(tempData)
    //       })
    //     })
    //   })
    // })

  }, [graphConfiguration, subadmins])


  const handleGraphConfigurationChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setGraphConfiguration({ ...graphConfiguration, [event.target.name]: event.target.value })
  }

  const CustomTooltipForCustomerOnboarded = ({ payload }) => {
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10
        }}>
        <p className='font-wt-600 text-custom2 font-size-14 m-0 p-0'>{`${stats?.pieDataForUserOnboarded?.[payload?.[0]?.name]?.["type"]} - ${stats?.pieDataForUserOnboarded?.[payload?.[0]?.name]?.["value"]}`}</p>
        <p className='font-wt-600 text-48DA87 font-size-14 m-0 p-0'>{`Active - ${stats?.pieDataForUserOnboarded?.[payload?.[0]?.name]?.["active"]}`}</p>
        <p className='font-wt-600 colorFF7B6D font-size-14 m-0 p-0'>{`Inactive - ${stats?.pieDataForUserOnboarded?.[payload?.[0]?.name]?.["inActive"]}`}</p>
      </div>
    );
  };

  const CustomTooltipForInvoiceLc = ({ payload, data }) => {
    let payloadIndex = payload?.[0]?.name
    let values = {
      0: { "label": "Approved", total: "approved", amount: 'approvedAmount', class: ' text-48DA87 ' },
      1: { "label": "Rejected", total: "rejected", amount: 'rejectedAmount', class: ' colorFF7B6D ' },
      2: { "label": "Inprogress", total: "pending", amount: 'pendingAmount', class: ' text-F1C40F ' }
    }
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10
        }}>
        <p className={`font-wt-600 font-size-14 m-0 p-0 ${values?.[payloadIndex]?.["class"]} `}>{`${values?.[payloadIndex]?.["label"]}`}</p>
        <p className={`font-wt-600 font-size-14 m-0 p-0 ${values?.[payloadIndex]?.["class"]} `}>{`Application - ${data?.[values?.[payloadIndex]?.["total"]]}`}</p>
        <p className={`font-wt-600 font-size-14 m-0 p-0 ${values?.[payloadIndex]?.["class"]} `}>{`Amount - $ ${Intl.NumberFormat("en", { notation: 'compact' }).format(data?.[values?.[payloadIndex]?.["amount"]])}`}</p>
      </div>
    );
  };

  async function downloadUserOnboardData() {
    if (!stats?.tableDataForUserOnboarded?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let csvString = "User Onboarded,Exporter,Importer,Financer,Channel Partner\r\n"
      for (let index = 0; index < stats?.tableDataForUserOnboarded.length; index++) {
        const element = stats?.tableDataForUserOnboarded[index];
        let rowString = `${element[0]},
          ${element[1]},
          ${element[2]},
          ${element[3]},
          ${element[4]}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `UserOnboardData.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadUserOnboardData", error);
    }
  }

  async function downloadInvoiceLcApplicationData() {
    if (!stats?.tableDataForInvoiceLcApplication?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let csvString = "Application Status,Approved Application,Approved Amount ($),Rejected Application,Rejected Amount ($),Inprocess Application,Inprocess Amount ($)\r\n"
      for (let index = 0; index < stats?.tableDataForInvoiceLcApplication.length; index++) {
        const element = stats?.tableDataForInvoiceLcApplication[index];
        let rowString = `${element[0]},
          ${element[1]},
          ${element[2]},
          ${element[3]},
          ${element[4]},
          ${element[5]},
          ${element[6]}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `InvoiceLCApplicationData.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadInvoiceLcApplicationData", error);
    }
  }

  function formatDataForTable(data) {

    let obj = {
      "19": "Exporter",
      "8": "Bank",
      "20": "Channel Partner"
    }
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let buyers_credit = item.buyers_credit ? JSON.parse(item.buyers_credit) : []
      let status = 'NA'
      if (!item.hasOwnProperty("buyers_credit")) {
        if (item.LeadAssignedTo === null) {
          status = 'New User'
        } else if (item.LeadAssignedTo != null && item.buyers_count == null && item.type_id == 19) {
          status = 'Buyer Not added'
        } else if (item.LeadAssignedTo != null && item.buyers_count != null && item.limit_count == null && item.type_id == 19) {
          status = 'Limit not applied'
        }
        else if (item.type_id == 20 && item.CPStatus != 3) {
          status = 'Agreement Pending'
        }
        else if (item.type_id == 20 && item.CPStatus == 3) {
          status = 'Agreement Sent'
        }
        else if (item.type_id == 20) {
          status = 'New User'
        }
        else {
          status = 'New User'
        }

      }
      else if (item.buyers_credit === null) {
        status = "Waiting for financer quote"
      }
      else if (item.buyers_credit != null && item.selectedFinancier == null) {
        status = 'Quote Recieved'
      } else if (item.termSheetSignedByBank && item.termSheetSignedByExporter) {
        status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Quote Locked" : "Limit Approved"
      } else if (item.termSheetSignedByExporter) {
        status = (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') ? "Contract Signed by Exporter" : 'TermSheet signed by exporter'
      }
      else if (item.selectedFinancier) {
        status = 'Quote Selected by exporter'
      } else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation') {
        if (buyers_credit.every(data => data.status === 'denied')) {
          status = 'Limit Denied'
        }
      } else if (item.invRefNo != 'lc_discounting' && item.invRefNo != 'lc_confirmation' && item.invRefNo != 'sblc') {

        if (buyers_credit.every(data => data.financierAction === 'deny')) {
          status = 'Limit Denied'
        }
      }

      row[0] = item.company_name
      row[1] = item.finance_type === 'invoice_discounting' ? item.invRefNo ? item.invRefNo : `${getInitials(item.buyerName)}/${item.buyerCountry ? item.buyerCountry : '-'}/${item.requiredLimit ? item.requiredLimit : '-'}` : item.id ? item.id : '-'
      row[2] = `${item.name_title ? item.name_title : ''} ${item.contact_person ? item.contact_person : ''}`
      row[3] = `${item.phone_code ? "+" + item.phone_code : ''} ${item.contact_number ? item.contact_number : ''}`
      row[4] = obj[item.type_id]
      row[5] = <div class="dropdown w-100" >
        <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          {item.subAdminContactPersonName || '-'}
        </label>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {salesPerson.map(element => {
            return <li className="dropdown-item cursor" onClick={() => updateLeadAssignedTo(element.id, item.userId)} >{element.contact_person}</li>
          })}
        </ul>
      </div>
      row[6] = moment().diff(item.updated_at, 'days') + " days"
      row[7] = <label className='text-color1 font-wt-600 cursor' onClick={() => {
        if ((status === 'New User' || status === 'Agreement Pending') && item.type_id == 20) {
          setViewDetails({
            isVisible: true,
            type: "Channel Partner",
            data: {
              type_id: item.type_id,
              id: item.userId,
              email_id: item.email_id,
              company_name: item.company_name,
              ...item
            }
          })
        }
        else if ((status === 'New User' || status === 'Buyer Not added' || status === 'Limit not applied') && item.type_id == 19) {
          setViewDetails({
            isVisible: true,
            type: "Exporter",
            data: {
              type_id: item.type_id,
              id: item.userId,
              email_id: item.email_id,
              company_name: item.company_name,
              ...item
            }
          })
        }
        else if (item.invRefNo === 'lc_discounting' || item.invRefNo === 'lc_confirmation' || item.invRefNo === 'sblc') {
          handleTransactionPopupLC({
            buyerId: item.buyerId,
            applicationId: item.tblId
          })
        } else {
          handleTransactionPopupINV({
            buyerId: item.buyerId,
            applicationId: item.tblId,
            invRefNo: item.invRefNo
          })
        }
      }}>{status}</label>
      if (item.type_id == 20 && item.status === 0) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2  font-size-13"><b>
            New Channel partner signed up.</b><span className='ml-2 text-color1 text-decoration-underline cursor font-wt-600 font-size-13' onClick={() => {
              setViewDetails({
                isVisible: true,
                type: "Channel Partner",
                data: {
                  type_id: item.type_id,
                  id: item.userId,
                  email_id: item.email_id,
                  company_name: item.company_name,
                  ...item
                }
              })
            }}>Send an agreement </span> </span>
        </p>
      }

      tableData.push(row)
      row = []
    })
    return tableData
  }

  function handleOpeningApplication(indx, tabIndx) {
    let item = overalldata[indx]
    if (item.finance_type == 'invoice_discounting') {
      if (item.buyers_credit == null || item.selectedFinancier == null) {
        window.location = `/seeQuotes?buyer=${item.buyerId}`;
        localStorage.setItem("applicationId", item.tblId)
        localStorage.setItem("invIfAppliedNo", item.invRefNo)
        localStorage.setItem("isAdmin", true)
        localStorage.setItem("defaultTabForAdmin", tabIndx)
        localStorage.setItem("selectedLenderName", item.FinancierName)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
      } else {
        window.location = `/sign-agreement`
        localStorage.setItem("item", JSON.stringify(item))
        localStorage.setItem("isAdminUser", true)
        localStorage.setItem("defaultSetTab", tabIndx)

        // setting manual user id & email for user
        localStorage.setItem("manualUserId", item.userId)
        localStorage.setItem("manualUserEmail", item.email_id)
        localStorage.setItem("headerBreadCum", "Invoice Discounting > Finance > Application Details")
      }
    } else {
      window.location = `/LcSeequotes?id=${item.tblId}`
      localStorage.setItem("isAdmin", true)
      localStorage.setItem("defaultTabForAdmin", tabIndx)

      // setting manual user id & email for user
      localStorage.setItem("manualUserId", item.userId)
      localStorage.setItem("manualUserEmail", item.email_id)
    }
  }

  const getDiscountingGraph = () => {
    call('POST', 'getDiscountingGraph', { from: dateRange.from, to: dateRange.to, onlyShowForUserId: userPermissionsForSubAdmin?.mainAdmin ? undefined : userId, userIds: subadmins }).then(result => {
      setdiscountingGraph(result)
      setdiscountingoverallGraph(result)
      setshowLoader(false)
    }).catch(err => {
      setshowLoader(false)
      console.log('error in getDiscountingGraph', err);
    })
  }
  useEffect(() => {
    getDiscountingGraph()
  }, [dateRange, subadmins])
  const formatDataForDiscountingTbl = () => {
    let tableData = []
    let row = []
    let total = 0
    discountingoverallGraph.forEach((item, index) => {
      row[0] = item.label
      row[1] = "$ " + Intl.NumberFormat("en", { notation: 'compact' }).format(item.discounting)
      total += item.discounting
      tableData.push(row)
      row = []
    })
    tableData.push(["Total", "$ " + Intl.NumberFormat("en", { notation: 'compact' }).format(total)])
    return tableData
  }
  useEffect(() => {
    if (!graphConfiguration.isDiscountingGraphMode) {
      setdiscountingtbl(formatDataForDiscountingTbl())
    }
  }, [graphConfiguration.isDiscountingGraphMode])

  const downloadCustomersOnboarded = () => {
    let finaldata = []
    stats.customerOnboardTableData?.forEach(item => {
      finaldata.push({
        "Date": item[0],
        "Exporters": item[1],
        "Buyers": item[2],
        "Channel Partner": item[3]
      })
    })
    ExportExcel(finaldata, 'Customers_Onboarded')
  }

  return (
    <div>
      <div className={`modal fade ${notePopup.show && "show"}`} style={notePopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >{`Add Note for ${notePopup.noteFor}`}</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleNotePopup({ show: false, data: "", selectedIndex: null })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              <div className="col-md-12 px-0">
                <div className="">
                  <NewTextArea
                    rows={6}
                    type={"text"} label={`Write Note`} name={"note"}
                    value={notePopup.data}
                    onChange={(e) => {
                      toggleNotePopup({ ...notePopup, data: e.target.value })
                    }}
                  />
                </div>
              </div>

              <button type="button"
                onClick={async () => {
                  setshowLoader(true)
                  let temp = overalldata[notePopup.selectedIndex]
                  // console.log("temp==========================>", overalldata, notePopup);
                  let req = { leadNote: notePopup.data }
                  if (!temp?.buyers_credit) {
                    req["userTblId"] = temp.userId
                  }
                  else if (temp?.finance_type === "invoice_discounting") {
                    req["invoiceLimitId"] = temp.id
                  }
                  else {
                    req["lcNo"] = temp.id
                  }
                  call('POST', 'addNoteForLead', req).then(res => {
                    setshowLoader(false)
                    toastDisplay(res, "success")
                    setRefresh(refresh + 1)
                    toggleNotePopup({ show: false, data: "", selectedIndex: null })
                  }).catch(err => {
                    setshowLoader(false)
                    toastDisplay("Something went wrong", "error")
                  })
                }}
                className={`new-btn w-20 py-2 mt-2 px-2 text-white enablesigncontract text-white `}>
                {"Submit"}
              </button>

            </div>
          </div>
        </div>
      </div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>
      <div className='row'>
        <div className='row pr-0'>
          <div className='w-17 w-mob-auto'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className={`value font-wt-600 text-custom2`}>
                {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["lifetimeEarnings"])}` || "NA"}
              </label>
              <label className={'font-size-14 font-wt-600 text-color-value'}>{"Lifetime Earnings"}</label>
            </div>
          </div>

          <div className='w-17 w-mob-auto'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className={`value font-wt-600 text-custom2`}>
                {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(stats["totalDisbursed"])}` || "NA"}
              </label>
              <label className={'font-size-14 font-wt-600 text-color-value'}>{"Total Discounting"}</label>
            </div>
          </div>

          <div className='w-20 w-mob-auto'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className='font-size-14 text-color-value font-wt-600 pt-2'>{`Clients Onboarded - `}
                <label className='font-size-14 text-color-value font-wt-600 text-custom2'>{` ${stats["clientsOnboarded"] || "NA"}`}</label></label>
              <div className='row px-0 w-100'>
                <div className='w-50'>
                  <label className={`value font-wt-600 text-48DA87  w-100`}>
                    {`${stats["activeUsers"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Active"}</label>
                </div>

                <div className='w-50'>
                  <label className={`value font-wt-600 colorFF7B6D w-100`}>
                    {`${stats["inactiveUsers"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Inactive"}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='w-20 w-mob-auto'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className='font-size-14 text-color-value font-wt-600 pt-2'>{`LC Application`}</label>
              <div className='row px-0 w-100'>
                <div className='w-50 cursor' onClick={() => window.location = '/lcLimit'}>
                  <label className={`value font-wt-600 text-custom2 w-100`}>
                    {`${stats["lcLimitApplications"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Limit"}</label>
                </div>

                <div className='w-50 cursor' onClick={() => window.location = '/lcLimit'}>
                  <label className={`value font-wt-600 text-custom2 w-100`}>
                    {`${stats["lcFinanceApplications"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Finance"}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='w-26 px-0 w-mob-auto'>
            <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
              <label className='font-size-14 text-color-value font-wt-600 py-2'>{`Invoice Application`}</label>
              <div className='row px-0 w-100'>
                <div className='w-33 cursor' onClick={() => window.location = '/invoiceLimit'}>
                  <label className={`value font-wt-600 text-custom2 w-100`}>
                    {`${stats["invoiceLimitApplications"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Limit"}</label>
                </div>

                <div className='w-33 cursor' onClick={() => window.location = '/invoiceFinance'}>
                  <label className={`value font-wt-600 text-custom2 w-100`}>
                    {`${stats["invoiceFinanceApplications"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Finance"}</label>
                </div>
                <div className='w-33 cursor' onClick={() => window.location = '/invoiceApprovedFinance'}>
                  <label className={`value font-wt-600 text-custom2 w-100`}>
                    {`${stats["invoiceFinanceApproved"] || "NA"}`}
                  </label>
                  <label className={'font-size-14 font-wt-600 text-color-value'}>{"Approved"}</label>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setEarningSelect('all')}>Earning</label>
                    {/* <NewSelect selectData={[{ name: "1 Month" }, { name: "2 Months" }, { name: "3 Months" }, { name: "4 Months" },
                    { name: "5 Months" }, { name: "6 Months" }, { name: "7 Months" },
                    { name: "8 Months" }, { name: "9 Months" }, { name: "10 Months" }, { name: "11 Months" }, { name: "12 Months" }]}
                      name={"earningDuration"} value={graphConfiguration.earningDuration}
                      onChange={handleGraphConfigurationChange} optionLabel={"name"} optionValue={"name"} removeMb={true}
                    /> */}
                  </div>

                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { setGraphConfiguration({ ...graphConfiguration, earningGraphMode: !graphConfiguration.earningGraphMode }) }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.earningGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => ExportExcel(stats.earningGraphData || [], "Earnings")}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            {graphConfiguration.earningGraphMode ?
              <ResponsiveContainer width="100%" height={250}>
                <LineChart
                  width={500}
                  height={400}
                  data={earningGraphData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="label" tick={({ x, y, payload }) => {
                    let { value = 0, valuePlan = 0 } = earningGraphData[payload.index]
                    let total = value + valuePlan
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                          {payload.value}
                        </text>
                        <text x={0} y={16} dy={16} textAnchor="middle" fill="#666" fontWeight={"bold"}>
                          {"$" + Intl.NumberFormat('en-US', { notation: 'compact' }).format(total)}
                        </text>
                      </g>
                    );
                  }} />
                  <YAxis type="number" tickFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                  <Tooltip formatter={(value, name) => ["$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value), labelsEarning[name]]}
                  />
                  <Legend iconType={'circle'} formatter={(value) => labelsEarning[value]} onClick={(e) => setEarningSelect(e.dataKey)} wrapperStyle={{ bottom: -11 }} />

                  <Line dataKey="value" stroke="#76EEA9" strokeWidth={"2px"} dot={{ stroke: '#2ECC71', fill: "#2ECC71", strokeWidth: 5 }} />
                  <Line dataKey="valuePlan" stroke="#FFB801" strokeWidth={"2px"} dot={{ stroke: '#FFB801', fill: "#FFB801", strokeWidth: 5 }} />

                  {/* <defs>
                  <linearGradient id="fillcolor" x1="0" y1="0" x2="0" y2="1" >
                    <stop offset={"30%"} stopColor="#61FFA4" stopOpacity={1} />
                    <stop offset={"100%"} stopColor="#2ECC71" stopOpacity={0.3} />
                  </linearGradient>
                </defs>
                <Area type="linear" dataKey="value" stroke="#61FFA4" fill="url(#fillcolor)" dot={{ stroke: '#3DB16F', strokeWidth: 5, fill: "#3DB16F" }} /> */}
                </LineChart>
              </ResponsiveContainer>
              : <div>
                <ExpandableTable columns={[
                  { name: "Date", width: '32%' },
                  { name: "Comission", width: '32%' },
                  { name: "Plan", width: '32%' }
                ]}
                  data={stats.earningTableData || []} disableAction expand={[]} expandKey={"Date"} />
              </div>
            }

            {/* <div className="d-flex gap-2 align-content-center">
              <label className="paymentsdots mb-0 cursor" onClick={() => setEarningSelect('valuePlan')}><div className='yellowdot' /> Plans </label>
              <label className="paymentsdots mb-0 cursor" onClick={() => setEarningSelect('value')}><div className='greendot' /> Commission </label>

            </div> */}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => setUserOnboardSelect('all')}>Customers Onboarded</label>
                    {/* <NewSelect selectData={[{ name: "1 Month" }, { name: "2 Months" }, { name: "3 Months" }, { name: "4 Months" },
                    { name: "5 Months" }, { name: "6 Months" }, { name: "7 Months" },
                    { name: "8 Months" }, { name: "9 Months" }, { name: "10 Months" }, { name: "11 Months" }, { name: "12 Months" }]}
                      name={"onbordedDuration"} value={graphConfiguration.onbordedDuration}
                      onChange={handleGraphConfigurationChange} optionLabel={"name"} optionValue={"name"} removeMb={true}
                    /> */}
                  </div>
                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => { setGraphConfiguration({ ...graphConfiguration, onbordedDurationGraphMode: !graphConfiguration.onbordedDurationGraphMode }) }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.onbordedDurationGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={downloadCustomersOnboarded}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            {graphConfiguration.onbordedDurationGraphMode ?
              <ResponsiveContainer width="100%" height={250}>
                <LineChart
                  width={500}
                  height={300}
                  data={onboardGraphdata}
                  //data={stats.customerOnboardedData || []}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="label" tick={({ x, y, payload }) => {
                    let { CPValue = 0, FinValue = 0, value = 0 } = onboardGraphdata[payload.index]
                    let total = CPValue + FinValue + value
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                          {payload.value}
                        </text>
                        <text x={0} y={16} dy={16} textAnchor="middle" fill="#666" fontWeight={"bold"}>
                          {total}
                        </text>
                      </g>
                    );
                  }} />
                  <YAxis type="number" tickFormatter={(value) => Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                  <Tooltip formatter={(value, name) => [value, labels[name]]} />
                  <Legend iconType={'circle'} formatter={(value) => labels[value]} onClick={(e) => setUserOnboardSelect(e.dataKey)} wrapperStyle={{ bottom: -11 }} />

                  {/* <defs>
                  <linearGradient id="fillcolor2" x1="0" y1="0" x2="0" y2="1" >
                    <stop offset={"30%"} stopColor="#95E7FF" stopOpacity={1} />
                    <stop offset={"100%"} stopColor="#95E7FF" stopOpacity={0.3} />
                  </linearGradient>
                </defs> */}
                  <Line dataKey="value" stroke="#76EEA9" strokeWidth={"2px"} dot={{ stroke: '#2ECC71', strokeWidth: 5, fill: "#2ECC71" }} />
                  <Line dataKey="FinValue" stroke="#FFB801" strokeWidth={"2px"} dot={{ stroke: '#FFB801', strokeWidth: 5, fill: "#FFB801" }} />
                  <Line dataKey="CPValue" stroke="#F26191" strokeWidth={"2px"} dot={{ stroke: '#F26191', strokeWidth: 5, fill: "#F26191" }} />

                </LineChart>
              </ResponsiveContainer>
              : <div>
                <ExpandableTable columns={[
                  { name: "Date", width: '23%' },
                  { name: "Exporters", width: '23%' },
                  { name: "Buyers", width: '23%' },
                  { name: "Channel Partners", width: '23%' }
                ]} data={stats.customerOnboardTableData || []} disableAction expand={[]} expandKey={"Date"} />
              </div>
            }

          </div>
        </div>

        <div className='col-md-12 pl-0 pr-2  mt-5'>
          <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
            <div>
              <div class="dropdown">
                <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                  <div className='d-flex align-items-center '>
                    <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Discounting</label>

                  </div>


                  <div className='w-20 align-items-center d-flex justify-content-end'>
                    <div className='pr-3'>
                      <img
                        onClick={() => {
                          setGraphConfiguration({
                            ...graphConfiguration,
                            isDiscountingGraphMode: !graphConfiguration.isDiscountingGraphMode
                          })
                        }}
                        className='cursor'
                        src={`/assets/images/${graphConfiguration.isDiscountingGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                    </div>
                    <div className=''>
                      <img
                        onClick={() => ExportExcel(discountingGraph, "DiscountingData")}
                        className='cursor' src='/assets/images/download_icon_with_bg.png' />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            {graphConfiguration.isDiscountingGraphMode ?
              <div className="pt-4">
                <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} bardataConfig={discontingConfig} formatterFunction={(value, name) => ["$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value), name]} data={discountingGraph} xDataKey={"label"} tab={"Values"} type={"Sum"} />
              </div>
              :
              <div>
                <ExpandableTable columns={[
                  { name: "Date", width: '50%' },
                  { name: "Discounting (Amt)", width: '50%' }
                ]} data={discountingtbl} disableAction expand={[]} expandKey={"Date"} />
              </div>
            }
          </div>
        </div>

        <div>
          <label className='text-color-value font-size-14 font-wt-600 mt-5 mb-4'>Today's Update</label>

          {/* <div className="" >
            <NewTable
              data={tableData}
              columns={[{ name: "Company Name" }, { name: "Financier" }, { name: "Country" },
              { name: "Type" }, { name: "Lead By" }, { name: "Status" }]}
              options={[
                { name: "Application", onClick: (indx) => handleOpeningApplication(indx, 0) },
                { name: "Chat with Supplier", onClick: () => null },
                { name: "Chat with Buyer", onClick: () => null }
              ]}
            />
          </div> */}
          {viewDetails.isVisible && viewDetails.type == 'Exporter' &&
            <div className='mt-4'>
              <UserDetails data={viewDetails.data} goBack={() => {
                setViewDetails({
                  isVisible: false,
                  data: {},
                  type: ''
                })
              }} userTokenDetails={userTokenDetails} />
            </div>
          }
          {viewDetails.isVisible && viewDetails.type == 'Channel Partner' &&
            <div className='mt-4'>
              <ChannelPartnerDetails data={viewDetails.data} goBack={() => {
                setViewDetails({
                  isVisible: false,
                  data: {},
                  type: ''
                })
              }} userTokenDetails={viewDetails.data} />
            </div>
          }
          {!viewDetails.isVisible &&
            <>

              <div className="filter-div ml-0 mt-1">
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              </div>

              <div className="mb-3">
                <NewTable
                  //columns={Shipmentcolumns} 
                  tableFixed data={dbData}
                  columns={[
                    { name: "Company Name" },
                    { name: "Application No." },
                    { name: "Contact Person" },
                    { name: "Contact No" },
                    { name: "User Type" },
                    { name: "Lead Assigned" },
                    { name: "Period" },
                    { name: "Status" },
                  ]}
                  options={[{
                    name: "Add Note", icon: "", onClick: (index) => {
                      let temp = overalldata[index]
                      toggleNotePopup({ show: true, data: temp.invoiceLimitLeadNote || temp.lcLimitLeadNote || temp.leadNote, selectedIndex: index, noteFor: temp.contact_person })
                    }
                  },
                  {
                    name: "View Application", icon: "", onClick: (index) => {
                      const tabledata = dbData[index]
                      let status = tabledata[7].props.children
                      const item = overalldata[index]
                      console.log('Itemmmmm', status)

                      if ((status === 'New User' || status === 'Agreement Pending') && item.type_id == 20) {
                        setViewDetails({
                          isVisible: true,
                          type: "Channel Partner",
                          data: {
                            type_id: item.type_id,
                            id: item.userId,
                            email_id: item.email_id,
                            company_name: item.company_name,
                            ...item
                          }
                        })
                      }
                      else if ((status === 'New User' || status === 'Limit not applied' || status === 'Buyer Not added') && item.type_id == 19) {
                        setViewDetails({
                          isVisible: true,
                          type: "Exporter",
                          data: {
                            type_id: item.type_id,
                            id: item.userId,
                            email_id: item.email_id,
                            company_name: item.company_name,
                            ...item
                          }
                        })
                      } else {
                        handleOpeningApplication(index, 0)
                      }
                    }
                  },
                  ]}
                />

              </div>
              <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
            </>
          }

        </div>

        <div className='pt-5 mb-3 pr-0 d-flex justify-content-end'>
          {/* <div className='w-80 align-items-center d-flex'>
            <div className='w-auto pr-3'>
              <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
            </div>
            <div className='w-15 pr-3'>
              <NewInput type={"date"} name={"userOnboardedFrom"} value={graphConfiguration.userOnboardedFrom}
                onChange={handleGraphConfigurationChange} />
            </div>
            <div className='w-15 pr-3'>
              <NewInput type={"date"} name={"userOnboardedTo"} value={graphConfiguration.userOnboardedTo}
                onChange={handleGraphConfigurationChange} />
            </div>
          </div> */}
          <div className='w-20 align-items-center d-flex justify-content-end p-0'>
            <div className='pr-3'>
              <img
                onClick={() => { setGraphConfiguration({ ...graphConfiguration, onboardedGraphMode: !graphConfiguration.onboardedGraphMode }) }}
                className='cursor'
                src={`/assets/images/${graphConfiguration.onboardedGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
            </div>
            <div className=''>
              <img
                onClick={downloadUserOnboardData}
                className='cursor' src='/assets/images/download_icon_with_bg.png' />
            </div>
          </div>
        </div>


        <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
          {graphConfiguration.onboardedGraphMode ? (
            <div className='row'>
              <div className='col-md-6'>
                {stats?.pieDataForUserOnboarded?.length ? (
                  <div className='col-md-12 text-center ml-9rem'>
                    <PieChartComponent hideDollar={true}
                      customToolTip={<CustomTooltipForCustomerOnboarded />}
                      data={stats.pieDataForUserOnboarded} dataKey="value" colors={lanesColor} cornerRadius={30} totalVal={stats.totalUsersOnboarded} />
                  </div>
                ) : null}
              </div>

              <div className='col-md-6 row py-5 my-5'>
                <div className='row align-items-center justify-content-center text-center'>
                  <label className='font-size-16 font-wt-600'>{"User Onboarded"}</label>
                </div>
                {stats?.pieDataForUserOnboarded?.length ? stats.pieDataForUserOnboarded.map((i, index) => {
                  return (
                    <div className='row align-items-center justify-content-center'>
                      <div className=''
                        style={{ width: '40px', height: '20px', backgroundColor: lanesColor[index] }}>
                      </div>
                      <div className='w-30'>
                        <label className='text-center font-size-14 font-wt-600'>{i.type}</label>
                      </div>
                    </div>
                  )
                }) : null}
              </div>
            </div>
          ) : (
            <NewTable
              disableAction={true}
              columns={[{ name: "User Onboarded" }, { name: "Exporter" }, { name: "Importer" },
              { name: "Financier" }, { name: "Channel Partner" }]}
              data={stats.tableDataForUserOnboarded || []}
            />
          )}

        </div>

        <div className='pt-5 mb-3 pr-0 d-flex justify-content-end'>
          {/* <div className='w-80 align-items-center d-flex'>
            <div className='w-auto pr-3'>
              <label className='text-color-value font-size-14 font-wt-600'>Custom</label>
            </div>
            <div className='w-15 pr-3'>
              <NewInput type={"date"} name={"invoiceLcApplicationFrom"} value={graphConfiguration.invoiceLcApplicationFrom}
                onChange={handleGraphConfigurationChange} />
            </div>
            <div className='w-15 pr-3'>
              <NewInput type={"date"} name={"invoiceLcApplicationTo"} value={graphConfiguration.invoiceLcApplicationTo}
                onChange={handleGraphConfigurationChange} />
            </div>
          </div> */}
          <div className='w-20 align-items-center d-flex justify-content-end p-0'>
            <div className='pr-3'>
              <img
                onClick={() => { setGraphConfiguration({ ...graphConfiguration, invoiceLcGraphMode: !graphConfiguration.invoiceLcGraphMode }) }}
                className='cursor'
                src={`/assets/images/${graphConfiguration.invoiceLcGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
            </div>
            <div className=''>
              <img
                onClick={downloadInvoiceLcApplicationData}
                className='cursor' src='/assets/images/download_icon_with_bg.png' />
            </div>
          </div>
        </div>

        <div className='card p-3 dashboard-card border-0 borderRadius h-100'>
          {graphConfiguration.invoiceLcGraphMode ? (
            <div className='row'>
              <div className='col-md-6'>
                {stats?.lcSummary ? (
                  <div className='col-md-12 text-center ml-9rem'>
                    <PieChartComponent hideDollar={true}
                      customToolTip={<CustomTooltipForInvoiceLc data={stats.lcSummary} />}
                      data={[{ type: "Approved", value: stats.lcSummary.approved },
                      { type: "Rejected", value: stats.lcSummary.rejected },
                      { type: "Inprocess", value: stats.lcSummary.pending }]} dataKey="value" colors={invoiceLcStatusColor} cornerRadius={30} totalVal={stats.lcSummary.totalApplication} />
                  </div>
                ) : null}
                <label className='font-wt-600 font-size-16 text-color-value w-90 text-center'>LC Limit Application</label>
              </div>

              <div className='col-md-6'>
                {stats?.invSummary ? (
                  <div className='col-md-12 text-center ml-9rem'>
                    <PieChartComponent hideDollar={true}
                      customToolTip={<CustomTooltipForInvoiceLc data={stats.invSummary} />}
                      data={[{ type: "Approved", value: stats.invSummary.approved },
                      { type: "Rejected", value: stats.invSummary.rejected },
                      { type: "Inprocess", value: stats.invSummary.pending }]} dataKey="value" colors={invoiceLcStatusColor} cornerRadius={30} totalVal={stats.invSummary.totalApplication} />
                  </div>
                ) : null}
                <label className='font-wt-600 font-size-16 text-color-value w-90 text-center'>Invoice Limit Application</label>
              </div>

              <div className="d-flex justify-content-center flex-wrap pt-3">
                {invoiceLcStatus.map((i, index) => {
                  return (
                    <div className='d-flex mr-4'>
                      <div style={{ width: '40px', height: '20px', backgroundColor: invoiceLcStatusColor[index] }}>
                      </div>
                      <label className='ml-3 text-center font-size-14 font-wt-600'>{i.label}</label>
                    </div>
                  )
                })}
              </div>

            </div>
          ) : (
            <NewTable
              disableAction={true}
              columns={[{ name: "Application Status" }, { name: "Approved Application" }, { name: "Approved Amount ($)" },
              { name: "Rejected Application" }, { name: "Rejected Amount ($)" }, { name: "Inprocess Application" }, { name: "Inprocess Amount ($)" }
              ]}
              data={stats.tableDataForInvoiceLcApplication || []}
            />
          )}
        </div>

        <div className='card p-3 dashboard-card border-0 borderRadius mt-5'>
          <div className='row justify-content-end mb-3'>
            <div className='w-30 align-items-center d-flex justify-content-end'>
              <div className='px-3'>
                <img
                  onClick={() => { setGraphConfiguration({ ...graphConfiguration, applicationStageGraphMode: !graphConfiguration.applicationStageGraphMode }) }}
                  className='cursor'
                  src={`/assets/images/${graphConfiguration.applicationStageGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
              </div>
              <div className=''>
                <img
                  onClick={() => {
                    downloadActiveUserApplicationStage()
                  }}
                  className='cursor' src='/assets/images/download_icon_with_bg.png' />
              </div>
            </div>
          </div>
          {!graphConfiguration.applicationStageGraphMode ? (
            <>
              <div>
                <NewTable
                  disableAction={true}
                  data={stageTable || []}
                  columns={[{ name: "Stage Wise Application Summary" }, { name: "Finance Limit" }, { name: "Quote" },
                  { name: "Termsheet/Contract" }, { name: "Finance" }, { name: "Agreement" }, { name: "Approved" }]}
                />
              </div>
            </>
          ) : (
            <div className='h-100 d-flex flex-row pt-5 pb-4 mx-3' >
              <div className='col-6'>
                {true ? (
                  <>
                    <div className='text-center h-90'>
                      <img src='assets/images/LC application funnel.png' width={"70%"} />
                    </div>
                    <label className='position-absolute font-size-16 font-wt-700 inact-15-days'>{graphData?.LC_Limit_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 inact-30-days'>{graphData?.LC_Quotes_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 inact-45-days'>{graphData?.LC_Contracts_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700  inact-60-days'>{graphData?.LC_Apply_Fin_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 inact-75-days'>{graphData?.LC_Approved_Fin_Count}</label>
                  </>
                ) : null}
                <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>LC Application</label>
              </div>

              <div className='col-6'>
                {true ? (
                  <>
                    <div className='text-center h-90'>
                      <img src='assets/images/Invoice application funnel.png' width={"70%"} height={"90%"} />
                    </div>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fl'>{graphData?.INV_Limit_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-qts'>{graphData?.INV_Quotes_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-ts'>{graphData?.INV_Contracts_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-fin'>{graphData?.INV_Apply_Fin_Count}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-agree'>{graphData?.INV_Agreement}</label>
                    <label className='position-absolute font-size-16 font-wt-700 appstage-approved'>{graphData?.INV_Approved_Fin_Count}</label>

                  </>
                ) : null}
                <label className='text-color-value font-size-14 font-wt-600 w-100 text-center mt-3'>Invoice Application</label>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>

  )
}