import { useState, useEffect } from 'react';
import Charts from '../charts';
import DropdownSearch from '../dropdownWithSearch';

export default function GraphCard({ itemsData, defaultData, defaultGraph, stateData, continentData, customerGroupData, itemGroupData, itemCategoryData }) {
  const graphsTypes = [
    // { symbol: "|T|", tooltip: "Table View" },
    // { symbol: "|A|", tooltip: "Area Chart" },
    // { symbol: "|B|", tooltip: "Bar Chart" },
    { symbol: "|D|", tooltip: "Donut Chart" },
    { symbol: "|P|", tooltip: "Pie Chart" }
  ];

  const [selectedGraph, setSelectedGraph] = useState(defaultGraph);
  const [showAll, setShowAll] = useState(false);
  const [dataForGraphs, setDataForGraphs] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(defaultData);

  useEffect(() => {
    handleDataselection(dropdownValue);
  }, [itemsData, stateData, continentData, customerGroupData, itemGroupData, itemCategoryData]);

  useEffect(() => {
    const bootstrap = require('bootstrap');
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 100, hide: 10 },
        customClass: 'custom-tooltip',
        placement: "bottom",
        trigger: "hover"
      });
    });
  }, []);

  const handleDataselection = (dataDropdown) => {
    if (!itemsData || !itemsData.length) {
      setDataForGraphs([]);
      return;
    }
    setDropdownValue(dataDropdown);
    switch (dataDropdown) {
      case 'Item Name':
        setDataForGraphs(calculateContribution(itemsData));
        break;
      case "Refference Number":
        setDataForGraphs(calculateContribution(itemsData));
        break;
      case "Customer Name":
        setDataForGraphs(calculateContribution(itemsData));
        break;
      case "State":
        setDataForGraphs(calculateContribution(stateData));
        break;
      case "Continent":
        setDataForGraphs(calculateContribution(continentData));
        break;
      case "Item Category":
        setDataForGraphs(calculateContribution(itemCategoryData));
        break;
      case "Customer Group":
        setDataForGraphs(calculateContribution(customerGroupData));
        break;
      case "Items Group":
        setDataForGraphs(calculateContribution(itemGroupData));
        break;
      default:
        setDataForGraphs([]);
        break;
    }
  };

  function calculateContribution(data) {
    // const filteredData = data.filter(Boolean);
    const totalAmount = data.reduce((acc, item) => acc + parseInt(item.totalAmount), 0);
    // console.log("totalAmount",totalAmount)
    return data.map(item => ({
      ...item,
      contribution: parseFloat((item.totalAmount / totalAmount * 100).toFixed(1))
    }));
  }

  return (

    <div className=" card shadow w-100 h-100">
      <div className="rounded card-body p-2" style={{ width: "100%", height: "100%" }}>
        <div className="rounded-top d-flex align-items-center justify-content-between upper border-bottom border-secondary p-2">
          <DropdownSearch items={["Item Name", "State", "Items Group", "Customer Group", "Continent", "Item Category"]} placeholder={"Select or Search"} onSelect={handleDataselection} defaultItem={defaultData} />
          {/* if you want add them "Item Name", "Refference Number", "Customer Name", */}
          <div className="d-flex align-items-center">
            <div
              onMouseEnter={() => setShowAll(true)}
              onMouseLeave={() => setShowAll(false)}
              style={{ display: 'inline-block' }}
            >
              {graphsTypes.map((button, index) => (
                <button
                  key={index}
                  className="bg-white  rounded me-1"
                  style={{
                    border: "0.5px solid blue",
                    display: showAll || button.tooltip === selectedGraph ? 'inline-block' : 'none'
                  }}
                  onClick={() => setSelectedGraph(button.tooltip)}
                  data-toggle="tooltip"
                  title={button.tooltip}
                >
                  {button.symbol}
                </button>
              ))}
            </div>
            {/* <button className="bg-white rounded" style={{ border: "0.5px solid blue" }}>|D|</button> */}
          </div>
        </div>
        <div className="graphArea bg-white overflow-auto w-100 rounded p-2 position-relative" style={{ height: "90%" }} >
          <Charts customStyles={{ align: "center", verticalAlign: "bottom", dataToShow: "GP", legendPosition: "horizontal", innerRadius: 80, outerRadius: 130, heightOfDiv: "100%" }} selectedChart={selectedGraph} selectedData={dataForGraphs} dropdownValue={dropdownValue} customStyles0={{ width: "200px", maxHeight: "200px", marginTop: "5%" }} />
        </div>
      </div>
    </div>

  );
}
