import React, { useState, useEffect } from 'react';
import DropdownSearch from "../dropdownWithSearch";
import NewTablev2 from "../../../../utils/newTablev2";
import call from '../../../../service';

export default function ItemDetails({ selectedItem, userId, selectedLabel, handleGoBack, data = {}, details = [] }) {
  const mainButtons = ["Month", "Quarter", "Year"];
  const [clickedIndex, setClickedIndex] = useState(0);
  const [tableData, setTableData] = useState({});
  const [columns, setColumns] = useState([]);
  const [duration, setDuration] = useState("2024-2025");
  const [filteredData, setFilteredData] = useState([]);
  const [stockQty, setStockQty] = useState(0);

  const dataForHeader = [
    { label: "Customer Name Alias", value: filteredData.length },
    { label: "Sku", value: selectedLabel === "itemName" ? "1" : "-" },
    { label: "Stock Qty", value: stockQty },
    { label: "Paid ($)", value: filteredData.reduce((acc, item) => item.type === "Purchase" ? acc + item.totalAmount : acc, 0) },
  ];

  const fetchStockData = async (itemName) => {
    try {
      const objectAPI = { userID: userId, itemName: itemName };
      const result = await call('POST', 'getstockdata', objectAPI);
      return result.stockData;
    } catch (error) {
      console.error('Error fetching stock data:', error);
      return { quantity: 0, rate: 0 }; // Default values 
    }
  };

  const fetchData = async () => {
    try {
      console.log(selectedLabel, selectedItem);
      console.log(data);

      const stockData = await fetchStockData(selectedItem);
      setStockQty(stockData.quantity);
    } catch (error) {
      console.error('Error in useEffect:', error);
    }
  };

  useEffect(() => {
    handleButtonClick(0);
    fetchData();
  }, []);
  useEffect(() => {
    handleButtonClick(clickedIndex);
  }, [details, clickedIndex, duration]);
  useEffect(() => {
    if (data && data.length && selectedItem && selectedLabel) {
      const [startYear, endYear] = duration.split('-').map(Number);

      const filtered = data.filter(item => {
        const itemYear = new Date(item.date).getUTCFullYear(); // Extract year from date
        return item[selectedLabel] === selectedItem && itemYear >= startYear && itemYear <= endYear;
      });

      console.log(filtered);
      setFilteredData(filtered);
    }
  }, [data, selectedItem, selectedLabel, duration]);

  const handleButtonClick = (index) => {
    setClickedIndex(index);

    let calculatedColumns;

    if (index === 0) {
      // Month: Based on duration
      calculatedColumns = calculateColumnsForLastNMonths(duration);
    } else if (index === 1) {
      // Quarter: Based on duration
      calculatedColumns = calculateColumnsForLastNQuarters(duration);
    } else if (index === 2) {
      // Year: Current year and previous year
      calculatedColumns = calculateColumnsForYears();
    }

    const calculatedData = {
      Sales: calculateTableData(["Gross Sales ($)", "Gross Qty"], calculatedColumns),
      Profitability: calculateTableData(["Gp ($)", "Gp%"], calculatedColumns),
      // Exposure: calculateTableData(["Outstanding", "Interest"], calculatedColumns),
      Invoices: calculateTableData(["Avg Val ($)", "Avg Rate ($)", "Avg Sku", "Invoices"], calculatedColumns),
      Spread: calculateTableData(["Customer", "Sku", "Pincode"], calculatedColumns)
    };

    setColumns([{ subColumns: "" }, ...calculatedColumns]);
    setTableData(calculatedData);
  };

  const calculateColumnsForLastNMonths = (duration) => {
    const [startYear, endYear] = duration.split('-').map(Number);
    const columns = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-indexed month

    for (let year = startYear; year <= endYear; year++) {
      const shortYear = year.toString();
      const monthLimit = (year === currentYear) ? currentMonth + 1 : (year === startYear ? 12 : 0);

      for (let month = 0; month < monthLimit; month++) {
        const monthName = new Date(year, month).toLocaleString('default', { month: 'short' });
        columns.push({ subColumns: `${monthName} ${shortYear}` });
      }
    }

    columns.push({ subColumns: 'Total' });
    return columns;
  };

  const calculateColumnsForLastNQuarters = (duration) => {
    const [startYear, endYear] = duration.split('-').map(Number);
    const columns = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = Math.floor(currentDate.getMonth() / 3);

    for (let year = startYear; year <= endYear; year++) {
      const quarterLimit = (year === currentYear) ? currentQuarter + 1 : (year === startYear ? 4 : 0);

      for (let quarter = 0; quarter < quarterLimit; quarter++) {
        const quarterName = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'][quarter];
        columns.push({ subColumns: `${quarterName} ${year}` });
      }
    }

    columns.push({ subColumns: 'Total' });
    return columns;
  };

  const calculateColumnsForYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const previousYear = currentYear - 1;
    const columns = [
      { subColumns: `${previousYear}-${previousYear + 1}` },
      { subColumns: `${currentYear}-${currentYear + 1}` },
      { subColumns: 'Total' }
    ];
    return columns;
  };

  const calculateTableData = (rows, columns) => {
    return rows.map(row => {
      const values = columns.slice(0, -1).map(column => {
        let totalValue = 0;
        const columnData = Array.isArray(details) ? details.filter(detail => {
          if (clickedIndex === 2) {
            // Year logic
            const year = parseInt(column.subColumns.split('-')[0], 10);
            return new Date(detail.date).getUTCFullYear() === year;
          } else if (clickedIndex === 1) {
            // Quarter logic
            const [monthStr, year] = column.subColumns.split(' ');
            const month = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'].indexOf(monthStr) * 3;
            const startMonth = month;
            const endMonth = month + 2;
            const detailDate = new Date(detail.date);
            const detailMonth = detailDate.getMonth();
            const detailYear = detailDate.getFullYear();
            return detailYear === parseInt(year, 10) && detailMonth >= startMonth && detailMonth <= endMonth;
          } else {
            // Month logic
            return detail.date === column.subColumns;
          }
        }) : [];

        columnData.forEach(detail => {
          switch (row) {
            case "Gross Sales":
              totalValue += detail.GrossSales || 0;
              break;
            case "Gross Qty":
              totalValue += detail.GrossQty || 0;
              break;
            case "Gp":
              totalValue += detail.Gp || 0;
              break;
            case "Gp%":
              totalValue += detail.GpPercentage ? parseFloat(detail.GpPercentage) : 0;
              break;
            case "Avg Val":
              totalValue += detail.AvgVal || 0;
              break;
            case "Avg Rate":
              totalValue += detail.AvgRate || 0;
              break;
            case "Avg Sku":
              totalValue += detail.AvgSku || 0;
              break;
            case "Invoices":
              totalValue += detail.Invoices || 0;
              break;
            case "Customer":
              totalValue += detail.Customer || 0;
              break;
            case "Sku":
              totalValue += detail.Sku || 0;
              break;
            case "Pincode":
              totalValue += detail.PinCode || 0;
              break;
            case "Outstanding":
            case "Interest":
              totalValue += 0;
              break;
            default:
              totalValue += 0;
              break;
          }
        });

        return totalValue;
      });

      // Calculate the total
      let total = values.reduce((acc, val) => acc + parseFloat(val), 0);
      total = total.toFixed(1)
      return {
        label: row,
        values: [...values, total]
      };
    });
  };


  const handleDurationChange = (selectedDuration) => {
    setDuration(selectedDuration);

    if (clickedIndex === 0) {
      // Update columns for Month view
      const newColumns = calculateColumnsForLastNMonths(selectedDuration);
      setColumns([{ subColumns: "" }, ...newColumns]);

    } else if (clickedIndex === 1) {
      // Update columns for Quarter view
      const newColumns = calculateColumnsForLastNQuarters(selectedDuration);
      setColumns([{ subColumns: "" }, ...newColumns]);

    }
  };

  return (
    <>
      <div className='p-2 pe-0 rounded shadow h-100' style={{ width: "75vw" }}>
        <div className='d-flex justify-content-between border-bottom border-secondary align-items-center pb-2'>
          <div className="d-flex align-items-center">
            <div>
              <img className="bg-transparent d-block cursor-pointer" onClick={handleGoBack} src="assets/images/back.png" alt="Back" height="20" width="25" />
            </div>
            <p className="d-block fs-5 mb-0 ms-2 fw-bold">{selectedItem}</p>
          </div>
          <div className="d-flex align-items-center">
            {mainButtons.map((item, index) => (
              <button
                type="button"
                className="btn btn-primary me-2"
                style={{
                  backgroundColor: clickedIndex === index ? "rgb(27, 148, 183)" : "transparent",
                  color: clickedIndex === index ? "white" : "rgb(27, 148, 183)",
                  border: `1px solid rgb(27, 148, 183)`
                }}
                onClick={() => handleButtonClick(index)}
                key={index}
              >
                {item}
              </button>
            ))}
            <DropdownSearch
              defaultItem={"2024-2025"}
              items={["2023-2024", "2024-2025"]}
              className=""
              onSelect={handleDurationChange}
              disabled={clickedIndex === 2}
            />
          </div>
        </div>
        <div className='bg-white'>
          <div className='mt-3 me-2 rounded border p-2'>
            <div className="row">
              <div className="col-md-6 d-flex justify-content-between border-end">
                <span>{dataForHeader[0].label}</span>
                <span>{dataForHeader[0].value}</span>
              </div>
              <div className="col-md-6 d-flex justify-content-between">
                <span>{dataForHeader[1].label}</span>
                <span>{dataForHeader[1].value}</span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 d-flex justify-content-between border-end">
                <span>{dataForHeader[2].label}</span>
                <span>{dataForHeader[2].value}</span>
              </div>
              <div className="col-md-6 d-flex justify-content-between">
                <span>{dataForHeader[3].label}</span>
                <span>{dataForHeader[3].value}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-1 me-2" style={{ overflowX: "scroll", height: "500px" }}>
          {Object.keys(tableData).map((key, index) => (
            <div key={index} className="mt-4">
              <h5>{key}</h5>
              <NewTablev2 showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }} columns={columns} freezeFirstColumn={true}>
                {tableData[key].map((row, rowIndex) => (
                  <tr style={{ background: "#e7f9ff" }} key={rowIndex}>
                    <td style={{ minWidth: "100px" }}>{row.label}</td>
                    {row.values.map((value, valueIndex) => (
                      <td key={valueIndex} style={{ minWidth: "100px" }}>{value}</td>
                    ))}
                  </tr>
                ))}
              </NewTablev2>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
