import React, { useState } from "react";
import NotificationOtherCard from "./notificationOtherCard";
import { QuoteData } from "../JsonData";

const NotificationsLC = ({ Heading, tab, setTab, data, getNotificationdata }) => {


  return (
    <>
      <p className="font-size-16 font-wt-500 letter-spacing05 color0C0C0C lh-22">{Heading}</p>
      <div className="card notification-card borderRadius border-0">
        <nav className="navheader">
          <div className="nav nav-tabs faqs-tab border-0 " id="faqs-tab" role="tablist">
            <button className={`nav-link fablink borderright rounded-0 bg-transparent ${tab === "Quote" && 'active'}`} id="nav-Quote-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Quote" aria-selected="true" onClick={() => setTab("Quote")}>Quote</button>
            <button className={`nav-link d-flex gap-2 align-items-center fablink borderright rounded-0 bg-transparent ${tab === "Contract" && 'active'}`} id="nav-Contract-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Contract" aria-selected="false" onClick={() => setTab("Contract")}>
              <span>Contract</span>
              <img className="" src={"assets/images/red-dot.png"} alt="" />
            </button>
            <button className={`nav-link fablink borderright rounded-0 bg-transparent ${tab === "Finance application" && 'active'}`} id="nav-Financeapplication-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Financeapplication" aria-selected="false" onClick={() => setTab("Finance application")}>Finance application</button>
            <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "Approved finance" && 'active'}`} id="nav-Approvedfinance-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Approvedfinance" aria-selected="false" onClick={() => setTab("Approved finance")}>Approved finance</button>
            <button className={`nav-link fablink bg-transparent borderright rounded-0 ${tab === "Amendment" && 'active'}`} id="nav-Amendment-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-Amendment" aria-selected="false" onClick={() => setTab("Amendment")}>Amendment</button>
          </div>
        </nav>

        <div className="pb-2 tab-content d-block" id="nav-tabContent">
          {tab === "Quote" && <div className="tab-pane fade show active bg-transparent " id="nav-Quote" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Contract" && <div className="tab-pane fade show active bg-transparent " id="nav-Contract" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Finance application" && <div className="tab-pane fade show active bg-transparent " id="nav-Financeapplication" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Approved finance" && <div className="tab-pane fade show active bg-transparent " id="nav-Approvedfinance" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
          {tab === "Amendment" && <div className="tab-pane fade show active bg-transparent " id="nav-Amendment" role="tabpanel" >
            <NotificationOtherCard notificationothercardData={data} getNotificationdata={getNotificationdata} />
          </div>
          }
        </div>
      </div>
    </>
  );
}
export default NotificationsLC;