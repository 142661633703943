import React from "react";

export const PopupMessage = ({ onClose, title, msg }) => {
  return (
    <div className="modal show d-block" id="">
      <div className="modal-dialog modal-m border-inner" >
        <div className="modal-content px-4 mx-4 py-2">
          <div className="modal-header border-0">
            <button type="button" className="close" onClick={onClose}>×</button>
          </div>
          <img
            style={{
              width: "25px",
              height: "25px",

            }}
            className={"align-self-center"}
            src={"assets/images/upload_done.png"} />
          <h6 className="modal-title text-dark text-center my-3 font-wt-600">{title}</h6>
          <label className="mb-5 text-center">{msg}</label>
        </div>
      </div>
    </div>
  )
}