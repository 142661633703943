import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}
let userTypeId = userDetails.type_id ? userDetails.type_id : null

export function getStatusDisplayName(row) {
  console.log("rowwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww", row);
  let style = {}
  let label = ""
  if (aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) {
    console.log("inIfffffffffffffffffffffffffffffffffffffffffffff");
    label = (row.status === 0) ? "NEW" :
      (row.status === 1) ? "In Progress" :
        (row.status === 2) ? "Verified" :
          (row.status === 3) ? "Completed" : ""
    style = (row.status === 0) ?
      {
        backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
      } : (row.status === 1) ?
        {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
        } : (row.status === 2) ?
          {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
          } : (row.status === 3) ? {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          } : {}
  }
  else {
    if ([3, 4, 19,1].includes(userTypeId / 1)) {
      label = row.isMktPlaceContract ? row.status.display_status :
        (row.status.payment_status === 1) ? "Completed" :
          (row.status.status_buyer === 1 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)) ? ([3, 4, 19].includes(userTypeId / 1) ? row.status.display_status : "In Progress") :
            (row.status.status_buyer === 1 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)) ? row.status.display_status :
              (row.status.status_buyer === 0 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)) ? row.status.display_status :
                (row.status.status_buyer === 0 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)) ? row.status.display_status :
                  (row.status.edit_request / 1 === row.status.userId) ? "Edit Request Sent" : "Edit Request Received"
      style = (row.isMktPlaceContract) ? {
        backgroundColor: 'rgba(248, 148, 6, 0.9)'
      } : (row.status.status_buyer === 0 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)) ?
        {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
        } : (row.status.status_buyer === 1 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)) ?
          {
            backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          } : (row.status.status_buyer === 0 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)) ?
            {
              backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
            } : (row.status.status_buyer === 1 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0) && row.status.payment_status / 1 != 1) ?
              {
                backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
              } : (row.status.payment_status / 1 === 1) ?
                {
                  backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
                } : row.status.edit_request / 1 ?
                  {
                    backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
                  } : {}
    }
  }
  return {
    style, label
  }
}

const columns = [
  {
    name: 'Contract Name',
    selector: 'contract_name',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
    conditionalCellStyles: [
      {
        when: row => (row.isMktPlaceContract),
        style: {
          backgroundColor: "#1ea3ae",
          color: 'white',
        },
      }]
  },
  {
    name: 'Buyer/Importer',
    selector: 'buyername',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Seller/Exporter',
    selector: 'suppliername',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Commodity',
    selector: 'commodity_name',
    sortable: true,
  },
  {
    name: 'Quantity',
    selector: 'quantity',
    sortable: true,
  },
  {
    name: 'Price',
    selector: 'price',
    sortable: true,
  },
  {
    name: 'Created on',
    selector: 'created_at',
    sortable: true,
  },

];

if (aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) {
  columns.push(
    {
      name: 'Category',
      selector: 'category',
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: 'rgba(48, 135, 142,0.9)',
        color: 'white',
      },
    },
    // {
    //   name: 'LC/Check No',
    //   selector: 'lc',
    //   sortable: true,
    //   grow: 2,
    // },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
      grow: 1,
      cell: row =>
        (row.status === 0) ? "NEW" :
          (row.status === 1) ? "In Progress" :
            (row.status === 2) ? "Verified" :
              (row.status === 3) ? "Completed" : "",
      conditionalCellStyles: [
        {
          when: row => (row.status === 0),
          style: {
            backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
            color: 'white',
          },
        },
        {
          when: row => (row.status === 1),
          style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
            color: 'white',
          },
        },
        {
          when: row => (row.status === 2),
          style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
            color: 'white',
          },
        },
        {
          when: row => (row.status === 3),
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
            color: 'white',
          },
        }
      ]
    }
  )
} else {

  columns.push(
    {
      name: 'Laycan Start',
      selector: 'laycan_start',
      sortable: true,
    },
    {
      name: 'Laycan End',
      selector: 'laycan_end',
      sortable: true,
    })

  if ([3, 4, 19].includes(userTypeId / 1)) {
    columns.push(
      {
        name: 'Status',
        selector: 'status',
        sortable: false,
        grow: 2,
        cell: row =>
          row.isMktPlaceContract ? row.status.display_status :
            (row.status.payment_status === 1) ? "Completed" :
              (row.status.status_buyer === 1 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)) ? ([3, 4, 19].includes(userTypeId / 1) ? row.status.display_status : "In Progress") :
                (row.status.status_buyer === 1 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)) ? row.status.display_status :
                  (row.status.status_buyer === 0 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)) ? row.status.display_status :
                    (row.status.status_buyer === 0 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)) ? row.status.display_status :
                      (row.status.edit_request / 1 === row.status.userId) ? "Edit Request Sent" : "Edit Request Received",
        conditionalCellStyles: [
          {
            when: row => (row.isMktPlaceContract),
            style: {
              backgroundColor: 'rgba(248, 148, 6, 0.9)',
              color: 'white'
            },
          },
          {
            when: row => (row.status.status_buyer === 0 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)),
            style: {
              backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
              color: 'white',
            },
          },
          {
            when: row => (row.status.status_buyer === 1 && row.status.status_seller === 0 && (row.status.edit_request / 1 === 0)),
            style: {
              backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
              color: 'white',
            },
          },
          {
            when: row => (row.status.status_buyer === 0 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)),
            style: {
              backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
              color: 'white',
            },
          },
          {
            when: row => (row.status.status_buyer === 1 && row.status.status_seller === 1 && (row.status.edit_request / 1 === 0)),
            style: {
              backgroundColor: 'rgba(248, 148, 6, 0.9)',  //orange
              color: 'white',
            },
          },
          {
            when: row => (row.status.payment_status / 1 === 1),
            style: {
              backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
              color: 'white',
            },
          },
          {
            when: row => row.status.edit_request / 1,
            style: {
              backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
              color: 'white',
            },
          }
        ]
      })
  }

}

export default columns
