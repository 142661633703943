import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { GetCache, getDocDetails, isOutStandingDatePassed, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import MultipleSelect from '../../../utils/MultipleSelect';
import toastDisplay from '../../../utils/toastNotification';
import ChatBoxPopUp2 from '../../chatRoom/components/ChatBoxPopUp2';
import FinanceInvoiceModal from '../../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { EmojiData } from '../../InvoiceDiscounting/approvedFinance/approvedFinance';
import NewTablev2 from '../../../utils/newTablev2';
import { Action } from '../../myCounterPartComp/action';
import ColumnSelector from '../../lcV2/qoutes/columnselector';

const overallCounts = [
  { name: "Disbursed", alt: 'totalDisbursed', class: ' text-F1C40F ', src: '/assets/images/disbursed_icon.png' },
  { name: "Over due", alt: 'totalOverDue', class: ' colorFF7B6D ', src: '/assets/images/OverDue.png' }
]

const twelveWeeksSummary = [
  { name: "Disbursed", alt: 'totalDisbursed12Week', class: ' text-F1C40F ' },
  { name: "Due", alt: 'totalDue12Week', class: ' colorFF7B6D ' },
  { name: "Over Due", alt: 'totalOverDue12Week', class: ' colorFF7B6D ' }
]

const InvoiceApprovedFinance2 = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [filterCount, setFilterCount] = useState({})


  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState(Object.keys(GetCache("invoiceApprovedFinanceFilter")).length ? GetCache("invoiceApprovedFinanceFilter") : { resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState(GetCache("invoiceApprovedFinanceFilterData"))
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [filteredSearch, setFilteredSearch] = useState(GetCache("invoiceApprovedFinanceSearchFilterData"))
  const [salesPerson, setSalesPerson] = useState([])
  const [data, setdata] = useState({})
  const [experience, setExperience] = useState({ show: false, data: {} });
  const [emoji, setEmoji] = useState("");

  const [action, setAction] = useState({ show: false, index: null })
  const [applicationCounts, setApplicationCounts] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [columnVisibility, setColumnVisibility] = useState([
    { name: 'Date', isChecked: true },
    { name: "Application No", isChecked: true },
    { name: "Buyer", isChecked: true },
    { name: "Financer", isChecked: true },
    { name: "Terms of Payment", isChecked: true },
    { name: "Disbursement Date", isChecked: true },
    { name: "Disbursement Amount", isChecked: true },
    { name: "Outstanding Days", isChecked: true },
    { name: "Status", isChecked: true },

  ]);

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }


  const getAdminInvoiceApprovedFinance = () => {
    setshowLoader(true)
    let objectAPI = {
      "supplierId": userId,
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      atApprovedStage: true,
      ...filter
    }


    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }

    call('POST', 'getInvoiceDiscountingListForAdmin', objectAPI).then((result) => {
      console.log('running getInvoiceDiscountingListForAdmin api-->', result);
      setDbData(result.listData);
      setTableData(formatDataForTable(result.listData))
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getInvoiceDiscountingListForAdmin', e);
      setshowLoader(false)
    });
  }

  useEffect(() => {
    if (userTypeId / 1 != 1) {
      fetchApplicationCounts()
    }
  }, [])

  async function fetchApplicationCounts() {
    call('POST', 'getIDApprovedApplicationCounts', { userId }).then(res => setApplicationCounts(res))
  }

  useEffect(() => {
    getAdminInvoiceApprovedFinance()
  }, [refresh, page, filterData, data.subadmins, salesPerson])

  useEffect(() => {
    let isCacheExist = localStorage.getItem('invoiceApprovedFinanceFilterData') != "{}"
    let isSearchCacheExist = localStorage.getItem('invoiceApprovedFinanceSearchFilterData') != "{}"
    call('POST', 'getInvoiceDiscountingFiltersForAdmin', {
      userId,
      atApprovedStage: true,
      supplierId: userId
    }).then(res => {
      console.log("getInvoiceDiscountingFiltersForAdmin then", res);
      delete res["Exporter Name"]
      setFilterData(res)

      if (!isSearchCacheExist) {
        setFilteredSearch(res)
      }
    }).catch(err => { })

  }, [])

  useEffect(() => {
    if (!Object.keys(filterData)?.length && columnVisibility.length > 0) {
      call('POST', 'getApplyForQuoteFilter', { userId }).then(res => {
        console.log("getApplyForQuoteFilter then", res);
        res["Grid Columns"] = { accordianId: 'GridColumns', data: [], labelName: 'name', type: 'checkbox', isAllChecked: true };
        // Assuming columnVisibility is an array of objects with 'name' property
        for (let index = 0; index < columnVisibility.length; index++) {
          const element = columnVisibility[index];
          res["Grid Columns"].data.push({ name: element.name, isChecked: true });
        }
        setFilterData(prevFilterData => ({
          ...prevFilterData,
          ...res
        }));
      }).catch(err => { });
    }
  }, [filterData]);

  console.log(filterData, "filterdataininvoice")


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      let allDeniedCount = 0
      let allApprovedCount = 0

      if (item.buyers_credit) {
        for (let index = 0; index < JSON.parse(item.buyers_credit).length; index++) {
          const j = JSON.parse(item.buyers_credit)[index];
          if (j.financierAction === "deny") {
            allDeniedCount += 1
          }
          else {
            allApprovedCount += 1
          }
        }
      }
      row[0] = moment(item.applicationCreatedAt).format('DD/MM/YYYY')
      row[1] = item.reference_no
      row[2] = item.buyerName

      let selectedLenderName = item.selectedQuote?.lender_name
      let selectedLenderId = item.selectedQuote?.lender_id
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
      let openChatRoomIndx = null

      chatRoomUsers.forEach((u, i) => {
        if (u?.split("::")[1] / 1 == selectedLenderId / 1) {
          openChatRoomIndx = i
        }
      })

      row[3] = <div
        className='flex-row position-relative'
      >
        <img className='cursor'
          onClick={async () => {
            let reqObj = {
              senderId: userId,
              receiverId: selectedLenderId,
              textMessage: 'Hii',
              chat_room_name: "CHAT" + new Date().getTime(),
              includeAdmins: true,
              invApplicationId: item.applicationId,
              receiverParties: selectedLenderId,
              dontSendInitialMsg: true
            }
            setshowLoader(true)
            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
            setshowLoader(false)
            getChatDetails({
              chat_room_id: apiResp.id,
              loggedInUser: userId
            })
            setSelectedChat({
              chatRoomId: apiResp.id,
              receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
              invApplicationId: item.applicationId,
              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
              userId: userId,
              isChatOpen: true,
              receiverId: selectedLenderId
            })
          }}
          src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
        <label className='p-1 mx-1 font-size-14 font-wt-600' >{selectedLenderName}
          <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
      </div>

      row[4] = item.termsOfPayment

      let disbScheduledOn = item.disbScheduledOn?.split(",")?.reverse() || []
      let disbCurrency = item.disbCurrency?.split(",")?.reverse() || []
      let disbAmount = item.disbAmount?.split(",")?.reverse() || []
      let disbActualAmount = item.disbActualAmount?.split(",")?.reverse() || []
      let disbActualDate = item.disbActualDate?.split(",")?.reverse() || []
      let disbAttachment = item.disbAttachment?.split(",")?.reverse() || []
      let disbStatus = item.disbStatus?.split(",")?.reverse() || []

      row[5] = <div
        className=''
      >
        {disbScheduledOn.length ? disbScheduledOn.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className='font-size-14 text-color-label font-wt-600' >{`${moment(key).format("DD/MM/YYYY")}`}</label>
            </div>
          )
        }) : "NA"}
      </div>
      row[6] = <div
        className=''
      >
        {disbAmount.length ? disbAmount.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className='font-size-14 text-color-label font-wt-600' >{`${key} ${disbCurrency[index]}`}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[7] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className={`font-size-14 
              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-600`} >
                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? `${disbAmount[index]} ${disbCurrency[index]}` : '-'}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[8] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative' >
              <label className={`font-size-14 
              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-600`} >
                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? (moment().diff(moment(disbScheduledOn[index]), "days") + " Days") : '-'}</label>
            </div>
          )
        }) : "NA"}
      </div>

      row[9] = <div
        className=''
      >
        {disbActualDate.length ? disbActualDate.map((key, index) => {
          return (
            <div className='position-relative mb-2' >
              {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ?
                <img src={"/assets/images/overdue_icon.png"} alt="" height={24} width={25} /> : disbStatus / 1 == 0 ?
                  <img src={"/assets/images/processing.png"} alt="" height={24} width={25} /> :
                  <img src={"/assets/images/upload_done.png"} alt="" height={24} width={25} />}
            </div>
          )
        }) : "NA"}
      </div>

      tableData.push(row)
      row = []
    }
    return tableData
  }

  const handleChange = async (event) => {
    event.persist()
    setdata({ ...data, [event.target.name]: event.target.value })
    // setErrors({ ...errors, [event.target.name]: "" })
  }

  const closeExpFn = () => {
    setExperience({ show: false, data: {} });
    setEmoji("")
    setdata({})
  }

  async function postRating() {
    setshowLoader(true)
    await call("POST", "postRatingToFinancier", {
      ratingFor: 'ID', applicationId: experience.data.invRefNo, lenderId: experience.data.selectedQuote.lender_id,
      ratings: emoji, remarks: data.remarks, userId
    })
    toastDisplay("Feedback submitted successfully", "success")
    closeExpFn()
    setshowLoader(false)
  }

  async function submitRating(index) {
    setExperience({ show: true, data: dbData[index] })
  }

  function openApplication(indx, tabIndx) {
    let item = dbData[indx]
    window.location = `/sign-agreement`
    localStorage.setItem("item", JSON.stringify(item))
    localStorage.setItem("isAdminUser", true)
    localStorage.setItem("defaultSetTab", tabIndx)

    // setting manual user id & email for user
    localStorage.setItem("manualUserId", item.user_id)
    localStorage.setItem("manualUserEmail", item.supplierEmailId)
    localStorage.setItem("headerBreadCum", "Invoice Discounting > Approved Finance > Application Details")
  }

  async function downloadCSVFile() {
    try {
      let csvString = "Application No,Supplier,Buyer,Financer,Disbursement Date,Disbursement Amount,Outstanding Amount,Outstanding Days,Status\r\n"

      for (let i = 0; i < dbData.length; i++) {
        let element = dbData[i]

        let disbScheduledOn = element.disbScheduledOn?.split(",")?.reverse() || []
        let disbCurrency = element.disbCurrency?.split(",")?.reverse() || []
        let disbAmount = element.disbAmount?.split(",")?.reverse() || []
        let disbStatus = element.disbStatus?.split(",")?.reverse() || []

        let disbDateString = ''
        let disbAmountString = ''
        let outstandingAmountString = ''
        let outstandingDaysString = ''
        let statusString = ''

        for (let index = 0; index < disbScheduledOn.length; index++) {
          const disbElement = disbScheduledOn[index];
          disbDateString += `${disbElement}||`
          disbAmountString += `${disbAmount[index]} ${disbCurrency[index]}||`
          if (isOutStandingDatePassed(disbElement, disbStatus[index])) {
            outstandingAmountString += `${disbAmount[index]} ${disbCurrency[index]}||`
            outstandingDaysString += `${moment().diff(moment(disbScheduledOn[index]), "days") + " Days"}||`
            statusString += `Overdue||`
          }
          else {
            outstandingAmountString += `NA||`
            outstandingDaysString += `NA||`
            if (disbStatus[index] / 1 == 1) {
              statusString += `Disbursed||`
            }
            if (disbStatus[index] / 1 == 0) {
              statusString += `Pending||`
            }
          }
        }

        let rowString = `${element.reference_no},
          ${element.supplierName || 'NA'},
          ${element.buyerName || 'NA'},
          ${element.lenderName || 'NA'},
          ${disbDateString || 'NA'},
          ${disbAmountString || 'NA'},
          ${outstandingAmountString || 'NA'},
          ${outstandingDaysString || 'NA'},
          ${statusString || 'NA'}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ApprovedFinanceList.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.log("error in downloadCSVFile", error);
    }
  }




  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_approvedFinance" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice Discounting > Approved Finance"}
              userTokenDetails={userTokenDetails} />
            {userTypeId / 1 != 1 ? <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4  ' style={{ width: '39.22%' }} >
                <label className='font-size-16 font-wt-500 color0C0C0C' >Total 12 weeks payment summary</label>
                <div className='d-flex row justify-content-between' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.received12Week || 0))}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Received</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.due12Week || 0))}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Due</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.overdue12Week || 0))}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Overdue</p>
                  </div>
                </div>
              </div>
              <div
                className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '28.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.receivable || 0))}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>
                    <img src='assets/images/disbursed_icon.png' className='pb-1 mr-2' style={{ width: '30px', height: '30px' }} />
                    Receivable</p>
                </div>
              </div>
              <div
                className='card   pl-4 d-flex justify-content-center cursor' style={{ width: '28.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-danger p-0 m-0' >{"$ " + (Intl.NumberFormat("en", { notation: 'compact' }).format(applicationCounts.overdue || 0))}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>
                    <img src='assets/images/overdue_icon2.png' className='pb-1 mr-2' style={{ width: '30px', height: '30px' }} />Over Due</p>
                </div>
              </div>
            </div> : null}

            <div className='mt-4' style={{}} >

              <div className='filter-div'>
                <Filter showDownloadIcon={false}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  onDownloadClick={downloadCSVFile}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
                {/* <ColumnSelector filterData={filterData} setFilterData={setFilterData} /> */}
              </div>

              <div
                style={{ overflowX: 'auto' }}>
                <NewTablev2
                  tableStyles={{ width: '75rem' }}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  columns={[{ subColumns: "Date", subColumnStyle: { width: '10%' } },
                  { subColumns: "Application No", subColumnStyle: { width: '12%' } },
                  {
                    subColumns: "Buyer", filterDataKey: "Buyer Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } }],
                    subColumnStyle: { width: '15%' }
                  },
                  { subColumns: "Financer", subColumnStyle: { width: '14%' }, filterDataKey: 'Financier Selected' },
                  { subColumns: "Terms of Payment", subColumnStyle: { width: '12%' } },
                  { subColumns: "Disbursement Date", subColumnStyle: { width: '12%' } },
                  { subColumns: "Disbursement Amount", subColumnStyle: { width: '12%' } },
                  { subColumns: "Outstanding Amount", subColumnStyle: { width: '12%' } },
                  { subColumns: "Outstanding Days", subColumnStyle: { width: '12%' } },
                  { subColumns: "Status", subColumnStyle: { width: '5%' } }, { subColumns: "", subColumnStyle: { width: '5%' } }
                  ]}
                >
                  {dbData.map((item, index) => {
                    let selectedLenderName = item.selectedQuote?.lender_name
                    let selectedLenderId = item.selectedQuote?.lender_id
                    let chatRoomIds = item.chatRoomIds?.split(",") || []
                    let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                    let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
                    let openChatRoomIndx = null

                    let disbScheduledOn = item.disbScheduledOn?.split(",")?.reverse() || []
                    let disbCurrency = item.disbCurrency?.split(",")?.reverse() || []
                    let disbAmount = item.disbAmount?.split(",")?.reverse() || []
                    let disbActualAmount = item.disbActualAmount?.split(",")?.reverse() || []
                    let disbActualDate = item.disbActualDate?.split(",")?.reverse() || []
                    let disbAttachment = item.disbAttachment?.split(",")?.reverse() || []
                    let disbStatus = item.disbStatus?.split(",")?.reverse() || []

                    chatRoomUsers.forEach((u, i) => {
                      if (u?.split("::")[1] / 1 == selectedLenderId / 1) {
                        openChatRoomIndx = i
                      }
                    })

                    return (
                      <tr>
                        <td><label className='font-size-13 font-wt-400' >{moment(item.applicationCreatedAt).format('DD/MM/YYYY')}</label></td>
                        <td><label style={{ wordBreak: 'break-word' }} className='font-size-13 font-wt-400' >{item.reference_no}</label></td>
                        <td><label className='font-size-13 font-wt-400' >{item.buyerName}</label></td>
                        <td><div
                          className='flex-row position-relative'
                        >
                          <img className='cursor'
                            onClick={async () => {
                              let reqObj = {
                                senderId: userId,
                                receiverId: selectedLenderId,
                                textMessage: 'Hii',
                                chat_room_name: "CHAT" + new Date().getTime(),
                                includeAdmins: true,
                                invApplicationId: item.applicationId,
                                receiverParties: selectedLenderId,
                                dontSendInitialMsg: true
                              }
                              setshowLoader(true)
                              let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                              setshowLoader(false)
                              getChatDetails({
                                chat_room_id: apiResp.id,
                                loggedInUser: userId
                              })
                              setSelectedChat({
                                chatRoomId: apiResp.id,
                                receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                                invApplicationId: item.applicationId,
                                parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                                userId: userId,
                                isChatOpen: true,
                                receiverId: selectedLenderId
                              })
                            }}
                            src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
                          <label className='p-1 mx-1 font-size-13 font-wt-400' >{selectedLenderName}
                            <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
                        </div></td>
                        <td><label className='font-size-13 font-wt-400' >{item.termsOfPayment}</label></td>
                        <td><div
                          className=''
                        >
                          {disbScheduledOn.length ? disbScheduledOn.map((key, index) => {
                            return (
                              <div className='position-relative' >
                                <label className='font-size-13 text-color-label font-wt-400' >{`${moment(key).format("DD/MM/YYYY")}`}</label>
                              </div>
                            )
                          }) : "NA"}
                        </div></td>
                        <td><div
                          className=''
                        >
                          {disbAmount.length ? disbAmount.map((key, index) => {
                            return (
                              <div className='position-relative' >
                                <label className='font-size-13 font-wt-400' >{`${key} ${disbCurrency[index]}`}</label>
                              </div>
                            )
                          }) : "NA"}
                        </div></td>
                        <td><div
                          className=''
                        >
                          {disbActualDate.length ? disbActualDate.map((key, index) => {
                            return (
                              <div className='position-relative' >
                                <label className={`font-size-13
                              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-400 `} >
                                  {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? `${disbAmount[index]} ${disbCurrency[index]}` : '-'}</label>
                              </div>
                            )
                          }) : "NA"}
                        </div></td>
                        <td><div
                          className=''
                        >
                          {disbActualDate.length ? disbActualDate.map((key, index) => {
                            return (
                              <div className='position-relative' >
                                <label className={`font-size-13 
                              ${isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? ' text-danger ' : ' text-color-label '} font-wt-400`} >
                                  {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ? (moment().diff(moment(disbScheduledOn[index]), "days") + " Days") : '-'}</label>
                              </div>
                            )
                          }) : "NA"}
                        </div></td>
                        <td><div
                          className=''
                        >
                          {disbActualDate.length ? disbActualDate.map((key, index) => {
                            return (
                              <div className='position-relative mb-2' >
                                {isOutStandingDatePassed(disbScheduledOn[index], disbStatus[index]) ?
                                  <img src={"/assets/images/overdue_icon.png"} alt="" height={24} width={25} /> : disbStatus / 1 == 0 ?
                                    <img src={"/assets/images/processing.png"} alt="" height={24} width={25} /> :
                                    <img src={"/assets/images/upload_done.png"} alt="" height={24} width={25} />}
                              </div>
                            )
                          }) : "NA"}
                        </div></td>
                        <td
                          // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          className='position-relative'>
                          <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                            onClick={() => setAction({ show: true, index })}
                            aria-hidden="true"></i>
                          {action.show && action.index === index ? (
                            <Action
                              id={index}
                              onDismiss={() => setAction({ show: false, index })}
                              options={[
                                { name: "Application", onClick: (indx) => openApplication(indx, 0) },
                                { name: 'Ratings', onClick: (index) => submitRating(index) }
                              ]} />
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}
                </NewTablev2>
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div>
      </div>
      {experience.show && <FinanceInvoiceModal limitinvoice={experience.show} closeSuccess={closeExpFn}>
        <p className='text-center' >How was your experience with <span className="text-custom">{experience.data.selectedQuote.lender_name}</span> ?</p>
        <div className="d-flex gap-3 justify-content-center">
          {EmojiData.map((key, index) => {

            return (
              <>
                <img src={emoji === key.rating ? key.fillimage : key.image} alt="angry" key={index} className="cursor " onClick={() => setEmoji(key.rating)} />
              </>
            );
          })}

        </div>
        {emoji !== "" && < div className="my-3 col-9 mx-auto text-end border px-0 send p-2">
          <textarea name={"remarks"} value={data.remarks} onChange={handleChange} className="form-control border-0 bg-transparent" id="exampleFormControlTextarea1" rows="3" placeholder="Write a review..."></textarea>
          <img className="cursor px-1" src={"/assets/images/Send.png"} alt="" onClick={() => postRating()} />
        </div>}
      </FinanceInvoiceModal >}
    </>)
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(InvoiceApprovedFinance2)