import React from 'react';

export default function DropdownAndInput({ items, label, comparison, setComparison, inputValue, setInputValue }) {
  // const dropdownValuesForPopup = ['Greater Than', 'Equals', 'Less Than'];

  return (
    <div>
      <div className='d-flex align-items-center justify-content-center mb-1'>
        <h6 style={{ margin: "0", marginRight: "10px", width: "50px" }}>{label}</h6>
        <select
          style={{ width: "150px", marginRight: "10px" }}
          value={comparison}
          onChange={(e) => setComparison(e.target.value)}
          className="form-control"
        >
          <option value="">Select</option>
          {items?.map((value, index) => (
            <option key={index} value={value}>{value}</option>
          ))}
        </select>
        <input
          type="number"
          value={inputValue}
          style={{ width: "150px" }}
          onChange={(e) => setInputValue(e.target.value)}
          className="form-control"
        />
      </div>
    </div>
  );
}
