import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import HeaderV2 from '../partial/headerV2';
import SideBarV2 from '../partial/sideBarV2';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import CalenderView from './components/calenderView';
import ChatBody from './components/chatBody';
import ChatBodyGroup from './components/ChatBodyGroup';
import ChatFooter from './components/chatFooter';
import ChatHeader from './components/chatHeader';
import ChatList from './components/chatList';
import ScheduleList from './components/scheduleList';
import { getDocDetails } from '../../utils/myFunctions';
import { selectIsLocalAudioEnabled, selectIsLocalVideoEnabled, selectLocalPeer, selectPeers, selectRemotePeers, useHMSActions, useHMSStore, useVideo } from '@100mslive/react-sdk';
import { NewSelect, NewTextArea } from '../../utils/newInput';
import MultipleSelect from '../../utils/MultipleSelect';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { platformBackendUrl } from '../../urlConstants';
import axios from 'axios';

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "8rem",
      "right": "10rem"
    },
    arrow: {
      "top": "20rem",
      "zIndex": 10,
      transform: "rotate(453deg)",
      left: "14.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>You can chat, call or schedule a with Financers & your Buyers in "Chat room".</span></label>
  }
}


const recievedchatdata = [
  {
    receivedtext: "Consectetur malesuada mollis vel, laoreet tempor. ",
    dpdisplay: ""
  },
  {
    receivedtext: "Consectetur malesuada mollis vel ",
    dpdisplay: "d-none"
  },
  {
    receivedtext: "Consectetur malesuada mollis vel, laoreet temperfi mollis vel, laoreet temperfi",
    dpdisplay: "d-none"
  },
];


const TradeGpt = ({ userTokenDetails, navToggleState }) => {

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [refresh, setRefresh] = useState(0)
  const [nextQstSuggestion, setNextQstSuggestion] = useState(["What is TradeReboot?", "What services does TradeReboot provide?", "How do I sign up for an account on TradeReboot?"])

  let chatViewRef = useRef()

  useEffect(() => {
    call('POST', 'getTradeGptChatHistory', { userId }).then(res => {
      setMessages([...res])
    })
  }, [refresh])



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("tradeGpt")
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  const handleSubmit = (e, inputTxt) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    let tempInputTxt = inputTxt || inputText
    if (tempInputTxt.trim() !== '') {
      setShowLoader(true)
      axios.post(platformBackendUrl + "/chatWithBot", { qst: tempInputTxt.trim(), userId }).
        then(result => {
          // console.log("chatWithBottttttttttttttttttt", result);
          setShowLoader(false)
          setNextQstSuggestion([...result?.data?.message?.nextQstSuggestions])
          setInputText('');
          setRefresh(refresh + 1)
        }).catch(err => {
          setShowLoader(false)
          toastDisplay("Something went wrong", "error")
          setRefresh(refresh + 1)
        });
    }
  };

  return (<>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
    <div className="container-fluid">
      <div className="row">
        <SideBarV2 state="tradeGpt" userTokenDetails={userTokenDetails} />
        <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
          <HeaderV2
            title={"Trade Gpt"}
            userTokenDetails={userTokenDetails} />
          <div
            ref={chatViewRef}
            style={{ height: '32rem', overflow: 'auto' }}
            className="row">
            <div className="col">
              <ul className="list-group chat-list">
                {messages.map((message, index) => (
                  // <div >
                  <li
                    key={index}
                    className={`list-group-item chat-item`}
                  >
                    <span className={` ${message.role === "user" ? 'text-primary' : 'text-success'} font-size-15 font-wt-600`}>{`${message.role} `}
                      <label className='w-100 font-size-14 font-wt-500'>{moment(message.timestamp).format('(YYYY-MM-DD hh:mm a)')}</label></span>
                    <p
                      className='font-size-14 pt-2 pb-3 font-wt-400'
                      style={{ whiteSpace: 'pre-wrap', overflowX: 'hidden', borderBottom: '1px dotted' }}
                    >{message.msg}</p>
                  </li>
                  // </div>
                ))}
              </ul>
            </div>
          </div>
          <div className='' >
            <div>
              {nextQstSuggestion.map((i, j) => {
                return <label
                  onClick={() => handleSubmit(null, i)}
                  style={{ backgroundColor: '#B0E0E6', borderRadius: 20 }}
                  className='font-size-15 text-black mx-1 px-3 cursor mt-4 text-align-center' >{i}</label>
              })}
            </div>
          </div>
          <div className="row pt-4">
            <div className="col">
              <form onSubmit={handleSubmit} className='d-flex justify-content-between'>
                <div className="form-group w-85">
                  <NewTextArea type="text" rows={1} label={"Type your message..."}
                    className="form-control" hideInputHeader
                    placeholder="Type your message..."
                    value={inputText || ""} name={"inputText"}
                    onChange={(e) => setInputText(e.target.value)} />
                </div>
                <button type="button" onClick={handleSubmit} className={` border-0 mb-2 text-white enableQuotebtn `}>
                  {"Submit"}
                </button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    refresh: state.chatRefresh.refresh
  }
}

export default connect(
  mapStateToProps,
  null
)(TradeGpt)