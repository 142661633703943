import React, { useEffect, useState } from 'react';
import toastDisplay from '../../utils/toastNotification';
import call from '../../service';
import CreditLineDocumentGrid from './creditlineDocumentsGrid';

const CreditlineDocuments = ({ userTokenDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [modalStatus, setmodalStatus] = useState(true);
  const [showLoader, setshowLoader] = useState(false);
  const [update, setupdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentRow, setDocumentRow] = useState({ data: [{ name: null }] });
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [refresh, setrefresh] = useState(0);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);


  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  const handleChange = (event, ind) => {
    if (event.target.name === 'name') {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    } else {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    }
    setDocumentRow({ ...documentRow })
  };

  const handleFiles = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const handleSubmit = function () {
    setshowLoader(true)

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    for (let i = 0; i < documentRow.data.length; i++) {
      formData.append(documentRow.data[i].documentName, documentRow.data[i].documentName);
    }

    call('POST', "creditlineDocument", formData).then((result) => {
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }


  function addDocumentRow() {
    documentRow.data.push({ name: null });
    setDocumentRow({ ...documentRow })
    console.log(documentRow);
  }

  function deleteDocumentRow(ind) {
    documentRow.data.splice(ind, 1)
    setDocumentRow({ ...documentRow })
  }

  //---------------------------------------------------------------------------------------------------------------------

  function getDocumentRow() {
    let rowsArr = [];
    for (let i = 0; i < documentRow.data.length; i++) {
      rowsArr.push(
        <>
          <div className="row form-group">
            <div className="col-md-1">
              <span className="field">
                <i className="fa fa-trash fa-2x" aria-hidden="true" onClick={() => deleteDocumentRow(i)}></i>
              </span>
            </div>
            <div className="col-md-2">
              <span className="field">
                <label className="checkbox" for="checkbox1">Document Name</label>
              </span>
            </div>

            <div className="col-md-4">
              <span className="field">
                <input type="text" className={" form-control" + (!error.documentName ? '' : ' border-danger')} placeholder="" name="documentName" value={data.multipledocumentName} onChange={(event) => handleChange(event, i)} required />
              </span>
            </div>
          </div>
        </>
      )
    }
    return rowsArr;
  }

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return

  return (
    <>
      { modalStatus ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="accordionItemContent">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Credit Line Documents
                      <button type="button" onClick={() => setmodalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                          <i class="fas fa-times fa-sm text-danger "></i>
                        </button>
                      </h3>
                      <hr />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="other-documents">
                          <li style={{ "min-width": "300px" }}>
                            <label className="mb-0" >
                              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                            Credit Line Document
                          </label>
                          </li>
                          <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                            <div className="file-browse">
                              <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
                              <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name="CreditLine:49" onChange={handleFiles} />
                              {error["CreditLine:49"] && <p className="error-contract p-0 m-0">{error["CreditLine:49"]}</p>}
                            </div>
                          </li>
                          <li style={{ "min-width": "500px" }}>
                            {(data["CreditLine:49"]) ? <div className="filePath text-left"><div className="file-name">{`${data["CreditLine:49"].name}`}</div></div> : ""}
                          </li>
                        </ul>
                        <hr />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-primary btn-sm" onClick={() => addDocumentRow()}>Add Documents Name</button>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                  {
                    getDocumentRow()
                  }
                </div>
                <div className="col-md-12">
                  <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()}>Submit</button>
                </div>
              </div>
              <br />
            </div>
            <CreditLineDocumentGrid userTokenDetails={userTokenDetails} refresh={refresh} setrefresh={setrefresh} />
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              Credit Line Documents
              <button type="button" onClick={() => setmodalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>
      }
    </>)
}

export default CreditlineDocuments;