import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const minDistance = 10;

export default function SliderComponent({ label, value, setValue, max, min, hideInfo }) {

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };
  return (
    <Box >
      {hideInfo ? null : (
        <div className='d-flex flex-row justify-content-between'>
          <p className='font-size-13 font-wt-400'>{label}</p>
          <p className='font-size-20 font-wt-600 text-color1'>{value?.join(' - ')}</p>
        </div>
      )}
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={value}
        onChange={handleChange1}
        valueLabelDisplay="auto"
        disableSwap
        min={min}
        max={max}
      />
      <div className='d-flex flex-row justify-content-between'>
        <p className='font-size-13 font-wt-400'>{min}</p>
        <p className='font-size-13 font-wt-400'>{max}</p>
      </div>
    </Box>
  );
}