import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import SideBarV2 from '../partial/sideBarV2';
import HeaderV2 from '../partial/headerV2';
import { NewInput } from '../../utils/newInput';
import MultipleSelect from '../../utils/MultipleSelect';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { FileInput } from '../../utils/FileInput';
import { convertImageToPdf, dataURItoBlob, downloadJSONFile, most_used_currencies } from '../../utils/myFunctions';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ExpandableTable } from '../wallet/components/ExpandableTable';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import ReactJson from "react-json-view";
import { environment } from '../../urlConstants';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';

const addBuyerTabs = [
  { name: "Select Buyer" },
  { name: "DUNS No." },
  { name: "Details" },
  { name: "Result" }
]

const BuyerCreditCheck = ({ userTokenDetails, navToggleState }) => {

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)


  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [dunsData, setDunsData] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [management, setManagement] = useState([null])
  const [reasonPopup, toggleReasonPopup] = useState({ show: false })
  const [result, setResult] = useState({})


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const ttvExporterCode = userTokenDetails.ttvExporterCode ? userTokenDetails.ttvExporterCode : ''
  const subUserName = userTokenDetails?.sub_user_name
  const mainUserName = userTokenDetails?.main_user_name

  useEffect(() => {
    if (result?.reason?.length) {
      formatDataForTable(result.reason)
    }
  }, [result])

  async function handleReasonPopup(reason) {
    toggleReasonPopup({ show: true, data: reason })
  }

  async function formatDataForTable(data) {
    let temp = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let row = []
      row[0] = element.title
      row[1] = element.score
      row[2] = <label
        onClick={() => {
          handleReasonPopup(element.reason)
        }}
        className='font-size-14 cursor font-wt-600 text-color1'
      ><u>View</u></label>
      temp.push(row)
    }
    setTableData(temp)
  }

  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    // call('get', 'getHsnCodes').then((result) => {
    //   setHsnCodes(result)
    // }).catch((e) => {
    //   console.log('error in getHsnCodes', e);
    // })
    // call("POST", "getLenderDetails").then((result) => {
    //   setFinancierData(result);
    // }).catch((e) => {
    //   console.log("error in getLenderDetails", e);
    // });
  }, [])

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  function getDunsList() {
    setData({
      ...data, selectedDuns: null,
      buyerAddress: "",
      buyerPostalCode: "", buyerDunsNo: ""
    })
    setshowLoader(true)
    call('POST', 'getDUNSNo', {
      companyName: data.buyerName, countryCode: data.buyerCountry,
      userId, userEmail
    }).then((result) => {
      setshowLoader(false)
      if (result.length > 0) {
        setDunsData(result);
        setTab(1)
      }
      else {
        toastDisplay("No Related Buyer Details Found", "error")
        setDunsData([])
      }
    })
  }

  async function handleValidation(tabIndex) {

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 1) {
      if (data.selectedDuns === undefined || data.selectedDuns === null) {
        toastDisplay('Select DUNS No', "info")
        err["selectDuns"] = "Select DUNS No"
      }
    }

    if (tab === 2) {
      if (data.auditedFinancialDocument?.name && !data.reportCurrency) {
        err["reportCurrency"] = 'Kindly select report currency'
      }
    }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab / 1 == 0) {
        getDunsList()
      }
      if (tab / 1 == 1) {
        setTab(2)
      }
      if (tab / 1 == 2) {
        let formData = new FormData()
        if (environment === "local") {
          formData.append("useHardCodeData", true)
        }
        formData.append('buyerCountryCode', data.buyerCountry)
        formData.append('dunsNo', data.buyerDunsNo)
        formData.append('buyerReferenceName', data.buyerName)
        if (data?.auditedFinancialDocument?.name) {
          formData.append('auditedFinancialReport', data.auditedFinancialDocument)
          formData.append('financialReportCurrencyCode', data.reportCurrency)
        }
        let tempManagementDetails = []
        for (let index = 0; index < management.length; index++) {
          if (data[`firstName${index}`] && data[`lastName${index}`] && data[`position${index}`] && (data[`expYears${index}`] || data[`expMonths${index}`])) {
            tempManagementDetails.push({
              firstName: data[`firstName${index}`],
              lastName: data[`lastName${index}`],
              expYears: data[`expYears${index}`],
              expMonths: data[`expMonths${index}`]
            })
          }
        }
        if (tempManagementDetails?.length) {
          formData.append('managementDetails', JSON.stringify(tempManagementDetails))
        }
        setshowLoader(true)
        let apiResp = await call('POST', 'buyerCreditCheck', formData)
        setResult(apiResp)
        setshowLoader(false)
        setTab(3)
      }
    }
    setErrors(err)
  }

  const handleFile = async (event) => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        let tempData = { ...data, [event.target.name]: fileObj }
        setData(tempData)
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {reasonPopup.show ? (
        <FinanceInvoiceModal limitinvoice={reasonPopup.show} closeSuccess={() => {
          toggleReasonPopup({ show: false })
        }}>
          <div className=''>
            {/* <img src='/assets/images/Questionmark.png' /> */}
            {typeof (reasonPopup.data) === 'object' ?
              <ReactJson
                displayDataTypes={false}
                style={{ padding: '0.8rem', marginTop: '0.8rem', marginBottom: '0.8rem', fontSize: '14px', textAlign: 'left' }}
                collapseStringsAfterLength={50}
                theme={"chalk"}
                name={false}
                src={reasonPopup.data} />
              :
              <p className='font-size-14 font-wt-400 mt-2 text-center'>{reasonPopup.data}</p>}
            {/* <button className={`my-4 new-btn w-30 py-2 px-1 text-white cursor`} onClick={() => {
              window.location = `applyForLimit?buyer=${newBuyerId}`
            }}>Continue</button>
            <p className='font-size-14 font-wt-400 cursor' onClick={() => {
              window.location = '/buyerManagement'
            }}><u>Back to buyer management</u></p> */}
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="buyercreditcheck" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Buyer Credit Check"}
              userTokenDetails={userTokenDetails} />

            <div className='' style={{}} >
              {/* <i
                onClick={() => {
                  window.location = "/buyerManagement"
                }}
                class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i> */}
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          // handleValidation(index)
                          setTab(index)
                        }}
                      >{item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className='bg-white pb-5'
                style={{
                  "height": "40rem",
                  "overflow": "auto"
                }}
              >

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { setTab(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-4 py-5'>
                    <div className="row">

                      <div className="col-md-4">
                        <NewInput isAstrix={true} type={"text"} label={"Buyer's Name"}
                          name={"buyerName"} value={data.buyerName} error={errors.buyerName}
                          onChange={handleChange} />
                      </div>

                      <div className="col-md-4">
                        <MultipleSelect
                          Id="Select Country"
                          Label="Select Country"
                          selectedvalue="Select Country"
                          optiondata={countrys}
                          onChange={(e) => handleMultiSelectchange(e, "buyerCountry", "sortname", true)}
                          value={data.buyerCountry ? [data.buyerCountry] : []}
                          name="buyerCountry"
                          labelKey={"name"}
                          valKey={"sortname"}
                          error={errors.buyerCountry}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='px-4 py-5'>
                    <label className='mb-4' >Select DUNS No.</label>
                    <div className='row dunsCardView px-3'>
                      {dunsData.length ? dunsData.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setData({
                                ...data, selectedDuns: index,
                                buyerAddress: item.address.street,
                                buyerPostalCode: item.address.postalCode, buyerDunsNo: item.duns,
                                buyersAPIDetail: item
                              })
                            }}
                            className={`dunsCard cursor mb-5 mr-5 p-4 ${data.selectedDuns === index ? "selectedDunsCard" : ""} `}
                          >
                            <h6 className='text-color1'>{item.duns}</h6>
                            <label className='text-color-label' >Company name</label>
                            <br />
                            <label className='text-color-value'>{item.companyName}</label>
                            <br />
                            <label className='text-color-label'>Address</label>
                            <br />
                            <label className='text-color-value'>{`${item.address.street}, ${item.address.city}, ${item.address.countryCode}, ${item.address.postalCode}`}</label>
                          </div>
                        )
                      }) : (
                        <h6>No Related Buyer Details Found</h6>
                      )}
                    </div>
                  </div>
                ) : null}

                {tab === 2 ?
                  <div className='px-4 py-5'>
                    <div className="row">

                      <div className="col-md-5">
                        <label className="">Audited Financial Report</label>
                        <div className="row form-group">
                          <div className="col-md-12">
                            <FileInput name={"auditedFinancialDocument"} value={data.auditedFinancialDocument} error={errors.auditedFinancialDocument}
                              onChange={handleFile} isEditable={true}
                              onUploadCancel={() => setData({ ...data, "auditedFinancialDocument": null })}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 pt-1">
                        <label className="">Financial Report Currency</label>
                        <div className="row form-group">
                          <div className="col-md-12">
                            <MultipleSelect
                              Id="Report Currency"
                              Label="Select"
                              selectedvalue="Report Currency"
                              optiondata={currencyData}
                              onChange={(e) => handleMultiSelectchange(e, "reportCurrency", "code", true)}
                              value={data.reportCurrency ? [data.reportCurrency] : []}
                              name="reportCurrency"
                              labelKey={"code"}
                              valKey={"code"}
                              error={errors.reportCurrency}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='mt-3'>
                        <label className="">Management Details</label>
                        {management.map((i, j) => {
                          return (
                            <div className='row mt-4'>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"text"} label={"First Name"}
                                  name={`firstName` + j} value={data[`firstName` + j]} error={errors[`firstName` + j]}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"text"} label={"Last Name"}
                                  name={`lastName${j}`} value={data[`lastName` + j]} error={errors[`lastName` + j]}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"text"} label={"Position"}
                                  name={`position${j}`} value={data[`position${j}`]} error={errors[`position${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"number"} label={"Experience in years"}
                                  name={`expYears${j}`} value={data[`expYears${j}`]} error={errors[`expYears${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className='col-2'>
                                <NewInput isAstrix={false} type={"number"} label={"Experience in months"}
                                  name={`expMonths${j}`} value={data[`expMonths${j}`]} error={errors[`expMonths${j}`]}
                                  onChange={handleChange} />
                              </div>
                              <div className=' col-2 ' >
                                <img
                                  onClick={() => {
                                    let temp = management
                                    temp.push(null)
                                    setManagement([...temp])
                                  }}
                                  className="cursor mx-4"
                                  src={"assets/images/vector_add_blue.svg"}
                                />
                                <img
                                  onClick={() => {
                                    let temp = management
                                    if (temp.length > 1) {
                                      let tempUpdatedPo = []
                                      for (let m = 0; m < temp.length; m++) {
                                        if (m != j) {
                                          tempUpdatedPo.push(null)
                                        }
                                      }
                                      temp = tempUpdatedPo
                                      setManagement([...temp])
                                    }
                                    else {
                                      toastDisplay("Atleast 1 details are required", "info")
                                    }
                                  }}
                                  className="cursor"
                                  src={"assets/images/cross.png"}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>

                    </div>

                  </div> : null
                }

                {tab === 3 ?
                  <div className='px-4 '>
                    <div className='d-flex justify-content-end'>
                      <button type="button"
                        onClick={() => {
                          downloadJSONFile(result, `${data.buyerName}_report.json`)
                        }}
                        className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                        {"Download JSON Report"}
                      </button>
                    </div>
                    <div className='row py-5'>
                      <div className="col-8">
                        <div>
                          <label className='font-size-14 font-wt-500'>{`Check Points :  `}</label><label className='font-size-14 font-wt-800'>{result.check_points}</label></div>
                        <div>
                          <label className='font-size-14 font-wt-500'>{`Total Score :  `}</label><label className='font-size-14 font-wt-800'>{result.score}</label></div>
                        <div>
                          <label className='font-size-14 font-wt-500'>{`Per checkpoint Max Score :  `}</label><label className='font-size-14 font-wt-800'>{2}</label></div>

                      </div>
                      <div className="col-4 d-flex">
                        <div>
                          <div style={{ width: '6rem' }}>
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: '#2ECC71',
                                textColor: '#000000'
                              })}
                              value={result.tr_score_percentage} text={`${Math.round(result.tr_score_percentage)}%`} />
                          </div>
                          <label className='font-size-14 font-wt-500'>{`TR Score percentage`}</label>
                          <label className='font-size-14 font-wt-800'>
                            {`(total score / (check points * max score per checkpoint)) * 100`}</label>
                          <div>
                            <label className='font-size-16 font-wt-800'>{`Final Limit : ${result.limit || "NA"} USD`}</label>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className='mt-4' >
                      <ExpandableTable
                        data={tableData}
                        columns={[
                          { name: "Title", filter: true, width: '60%', filter: false },
                          { name: "Score", filter: true, width: '20%', filter: false },
                          { name: "Reason", filter: true, width: '20%', filter: false },
                        ]}
                        overalldata={[]}
                        expand={[]}
                        tableExpand={[]}
                        expandKey={""}
                        disableAction={true}
                      // options={[
                      //   {
                      //     name: "View Reason", icon: "", onClick: (index) => {
                      //     }
                      //   }
                      // ]}
                      ></ExpandableTable>
                    </div>
                  </div>

                  : null}

                {tab != 3 ? (
                  <div className={tab === 1 ? "buyerContinuBtn row" : "row mx-0 px-3"}>
                    <button type="button"
                      onClick={() => {
                        handleValidation(undefined)
                      }}
                      className={`mx-4 new-btn ${tab === 1 ? '' : 'w-25'} py-2 px-2 text-white`}>
                      {"Continue"}
                    </button>
                  </div>

                ) : null}

              </div>

            </div>
          </main>

        </div >
      </div >
    </>
  )

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(BuyerCreditCheck)