import React, { useEffect, useState } from 'react'
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import { ExportExcel, isUserInactive } from '../../../utils/myFunctions';
import call from '../../../service';
import moment from 'moment';
import toastDisplay from '../../../utils/toastNotification';
import CustomAreaChart from '../../Dashboard/components/CustomAreaChart';
import CustomBarChart from '../../Dashboard/components/CustomBarChart';
import CustomLineChart from '../../CRM/CustomLineChart';
const bardataConfig = [
  { dataKey: 'active', fill: '#2ECC71', display: 'Active' },
  { dataKey: 'inActive', fill: '#FF7B6D', display: 'Inactive' },
]

const taskAssignedConfig = [
  { dataKey: "value", fill: "#76EEA9", display: 'Exporter' },
  { dataKey: "FinValue", fill: "#FFB801", display: 'Financier' },
  { dataKey: "CPValue", fill: "#F26191", display: 'Channel Partner' }
]

const stackedbarconfig = [
  { dataKey: "task_complete", fill: "#5CB8D3", display: 'Task Complete' },
  { dataKey: "task_incomplete", fill: "#FF7B6D", display: 'Task Incomplete' }
]

const newuserStatus = [
  { name: "Send Agreement", value: 19 },
  { name: "Sign Agreement", value: 23 },
  { name: "Add Buyer", value: 5 },
  { name: "Apply for limit", value: 59 },
  { name: "Apply for Finance", value: 10 }
]

const averagetaskGraph = [
  {
    "label": "Nov 2022",
    "value": 24
  },
  {
    "label": "Dec 2022",
    "value": 10
  },
  {
    "label": "Jan 2023",
    "value": 10
  },
  {
    "label": "Feb 2023",
    "value": 25
  },
  {
    "label": "Mar 2023",
    "value": 13
  },
  {
    "label": "Apr 2023",
    "value": 12
  },
  {
    "label": "May 2023",
    "value": 15
  },
  {
    "label": "Jun 2023",
    "value": 10,
  },
  {
    "label": "Jul 2023",
    "value": 12,
  },
  {
    "label": "Aug 2023",
    "value": 11,
  },
  {
    "label": "Sep 2023",
    "value": 12
  },
  {
    "label": "Oct 2023",
    "value": 14
  },
  {
    "label": "Nov 2023",
    "value": 15
  },
  {
    "label": "Dec 2023",
    "value": 10
  },
  {
    "label": "Jan 2024",
    "value": 10
  }
]

const SubAdminProfileDetailsAdmin = ({ subAdminId, dateRange, disbursedAmount }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [graphData, setGraphData] = useState({})
  const [filterData, setFilterData] = useState([])
  const [refresh, setRefresh] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10, search: '' })
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [filteredSearch, setFilteredSearch] = useState([])
  const [dbData, setDbData] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [salesPerson, setSalesPerson] = useState([])
  const [showDetails, setShowDetails] = useState({
    isVisible: false,
    data: {}
  })
  const [graphConfiguration, setGraphConfiguration] = useState({
    taskUpdateFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    taskUpdateTo: todayDateObj.clone().format("YYYY-MM-DD"),
    leadsFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    leadsTo: todayDateObj.clone().format("YYYY-MM-DD"),
    closureFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    closureTo: todayDateObj.clone().format("YYYY-MM-DD"),
    performanceFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    performanceTo: todayDateObj.clone().format("YYYY-MM-DD"),
    isTaskUpdateGraph: true,
    isLeadsGraph: true,
    isClosureGraph: true,
    isPerformanceGraph: true
  })
  const [userStatusGraph, setUserStatusGraph] = useState([])
  const [onboardGraphdata, setOnboardGraphdata] = useState([])
  const [overallonboarddata, setoverallonboardata] = useState([])
  const [statsdata, setstatsdata] = useState({})
  useEffect(() => {
    setShowLoader(true)
    call('POST', 'getTaskManagerGraphData', {
      applicationStageFrom: dateRange.from,
      applicationStageTo: dateRange.to
    }).then(res => {
      console.log("getTaskManagerGraphData response===============>", res);
      setShowLoader(false)
      let activeApplicationStageFunnelGraphData = []
      activeApplicationStageFunnelGraphData[0] = { "name": "Finance Limit", "value": res.activeUserApplicationSummary["Finance Limit"]["invoice"] + res.activeUserApplicationSummary["Finance Limit"]["lc"] }
      activeApplicationStageFunnelGraphData[1] = { "name": "Quote", "value": res.activeUserApplicationSummary["Quote"]["invoice"] + res.activeUserApplicationSummary["Quote"]["lc"] }
      activeApplicationStageFunnelGraphData[2] = { "name": "Termsheet/Contract", "value": res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"] + res.activeUserApplicationSummary["Termsheet/Contract"]["lc"] }
      activeApplicationStageFunnelGraphData[3] = { "name": "Finance", "value": res.activeUserApplicationSummary["Finance"]["invoice"] + res.activeUserApplicationSummary["Finance"]["lc"] }
      activeApplicationStageFunnelGraphData[4] = { "name": "Agreement", "value": res.activeUserApplicationSummary["Agreement"]["invoice"] }
      activeApplicationStageFunnelGraphData[5] = { "name": "Approved", "value": res.activeUserApplicationSummary["Approved"]["invoice"] + res.activeUserApplicationSummary["Approved"]["lc"] }

      let inactiveUserDaysFunnelGraphData = []
      inactiveUserDaysFunnelGraphData[0] = { "name": "15 Days", "value": res.inactiveUserDayWiseSummary["15 Days"]["exporter"] + res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["15 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[1] = { "name": "30 Days", "value": res.inactiveUserDayWiseSummary["30 Days"]["exporter"] + res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["30 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[2] = { "name": "45 Days", "value": res.inactiveUserDayWiseSummary["45 Days"]["exporter"] + res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["45 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[3] = { "name": "60 Days", "value": res.inactiveUserDayWiseSummary["60 Days"]["exporter"] + res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["60 Days"]["financers"] }
      inactiveUserDaysFunnelGraphData[4] = { "name": "75 Days", "value": res.inactiveUserDayWiseSummary["75 Days"]["exporter"] + res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"] + res.inactiveUserDayWiseSummary["75 Days"]["financers"] }

      let activeApplicationStageTableData = []
      activeApplicationStageTableData[0] = ["Invoice Discounting", res.activeUserApplicationSummary["Finance Limit"]["invoice"], res.activeUserApplicationSummary["Quote"]["invoice"], res.activeUserApplicationSummary["Termsheet/Contract"]["invoice"],
        res.activeUserApplicationSummary["Finance"]["invoice"], res.activeUserApplicationSummary["Agreement"]["invoice"], res.activeUserApplicationSummary["Approved"]["invoice"]]
      activeApplicationStageTableData[1] = ["LC Discounting", res.activeUserApplicationSummary["Finance Limit"]["lc"], res.activeUserApplicationSummary["Quote"]["lc"], res.activeUserApplicationSummary["Termsheet/Contract"]["lc"],
        res.activeUserApplicationSummary["Finance"]["lc"], 0, res.activeUserApplicationSummary["Approved"]["lc"]]

      let inactiveUserDaysTableData = []
      inactiveUserDaysTableData[0] = ["Exporter", res.inactiveUserDayWiseSummary["15 Days"]["exporter"], res.inactiveUserDayWiseSummary["30 Days"]["exporter"], res.inactiveUserDayWiseSummary["45 Days"]["exporter"],
        res.inactiveUserDayWiseSummary["60 Days"]["exporter"], res.inactiveUserDayWiseSummary["75 Days"]["exporter"]]
      inactiveUserDaysTableData[1] = ["financers", res.inactiveUserDayWiseSummary["15 Days"]["financers"], res.inactiveUserDayWiseSummary["30 Days"]["financers"], res.inactiveUserDayWiseSummary["45 Days"]["financers"],
        res.inactiveUserDayWiseSummary["60 Days"]["financers"], res.inactiveUserDayWiseSummary["75 Days"]["financers"]]
      inactiveUserDaysTableData[2] = ["Channel Partner", res.inactiveUserDayWiseSummary["15 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["30 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["45 Days"]["channelPartner"],
        res.inactiveUserDayWiseSummary["60 Days"]["channelPartner"], res.inactiveUserDayWiseSummary["75 Days"]["channelPartner"]]

      setGraphData({
        ...res, activeApplicationStageFunnelGraphData, inactiveUserDaysFunnelGraphData, activeApplicationStageTableData, inactiveUserDaysTableData
      })
    }).catch(e => {

    })
  }, [dateRange])
  useEffect(() => {
    setShowLoader(true)
    call("POST", 'getSubAdminUser', { onlyUserId: subAdminId }).then(res => {
      setShowLoader(false)
      setSalesPerson(res.data)
    }).catch(err => setShowLoader(false))
  }, [])
  const getLeadsListByAdmin = (isDownload) => {
    setShowLoader(true)
    let reqObj = {
      resultPerPage: filter.resultPerPage,
      currentPage: page,
      search: filter.search,
      onlyShowForUserId: subAdminId,
      ...filter
    }
    if (isDownload) {
      delete reqObj["resultPerPage"]
      delete reqObj["currentPage"]
    }

    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          reqObj[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              reqObj[element.accordianId].push((element.accordianId === "status" || element.accordianId === "applicationStatus") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          reqObj[element.accordianId] = element["value"]
        }
      }
    }
    if (reqObj["leadAssignedTo"]) {
      delete reqObj["onlyShowForUserId"]
    }
    call('POST', 'getLeadsListByAdmin', reqObj).then(result => {
      if (isDownload) {
        let downloadData = []
        for (let i = 0; i <= result.message.length - 1; i++) {
          let item = result.message[i]
          const downloadObj = {
            'EXPORTER NAME': item.company_name,
            'Contact Person': `${item.name_title ? item.name_title : ''} ${item.contact_person ? item.contact_person : ''}`,
            'Contact Number': `${item.phone_code ? "+" + item.phone_code : ''} ${item.contact_number ? item.contact_number : ''}`,
            'EMAIL ID': item.email_id,
            'Exporter Address': item.user_address,
            'Exporter City': item.company_city,
            'Assigned To': item.TaskAssignedToName
          }
          if (subAdminId) {
            delete downloadObj['Assigned To']
          }
          downloadData.push(downloadObj)
        }
        ExportExcel(downloadData, 'Exporter_List')
      } else {
        setDbData(formatDataForTable(result.message))
        setCount(result.total_count)
      }

      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    let obj = {
      8: 'Financier',
      19: 'Exporter',
      20: "Channel Partner"
    }
    data.forEach((item, index) => {
      let isUserInActive = isUserInactive(item.last_login_at)
      row.push(<div>
        <div>
          {item.type_id / 1 == 21 ? (
            <label className={"text-color1 font-size-10 font-wt-700"}>Subuser</label>
          ) : null}
        </div>
        <div>
          <label className={"text-color-label font-size-13 font-wt-600"}>
            {item.company_name}
          </label>
        </div>
      </div>)

      //row[0] =

      row.push(`${item.name_title ? item.name_title : ''} ${item.contact_person ? item.contact_person : ''}`)
      row.push(`${item.phone_code ? "+" + item.phone_code : ''} ${item.contact_number ? item.contact_number : ''}`)
      row.push(item.designation ? item.designation : '-')
      row.push(`${obj[item.type_id]}`)
      row.push(<div class="dropdown w-100" >
        <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          {item.TaskAssignedToName || '-'}
        </label>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {salesPerson.map(element => {
            return <li className="dropdown-item cursor" onClick={() => updateLeadAssignedTo(element.id, item.id)} >{element.contact_person}</li>
          })}
        </ul>
      </div>)
      row.push(moment().diff(item.created_at, 'days') + " days")

      row.push(item.notification_description ? <span className='color3DB16F'>{item.notification_description}</span> :
        isUserInactive ? <span className='colorFE4141'>Inactive</span> : <span className='text2ECC71'>Active</span>)

      row.push(<img src='/assets/images/redirect.svg' className='cursor' onClick={async () => {
        setShowLoader(true)
        // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", item);
        await call("POST", 'checkKYCDetailsOfUser', { userId: item.tbl_user_id })
        setShowLoader(false)
        let tempObj = {
          isVisible: true,
          data: item
        }
        if (item.type_id / 1 == 21) {
          tempObj["data"] = {
            ...item,
            type_id: 19,
            id: item.parent_id,
            ttvExporterCode: item.parent_ttv_exporter_code,
            subUserProfileDetails: {
              type_id: item.type_id, id: item.id, parent_email_id: item.parent_email_id,
              contactPerson: item.contact_person, contact_number: item.contact_number,
              phone_code: item.phone_code, email_id: item.email_id
            }
          }
        }
        console.log("viewexpdetails=============>", tempObj);
        setShowDetails(tempObj)
      }} />)
      tableData.push(row)
      row = []
    })
    return tableData
  }
  useEffect(() => {
    getLeadsListByAdmin()
  }, [page, refresh, filterData, salesPerson])
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getLeadsListByAdmin()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }
  const getUserStatusGraph = () => {
    setShowLoader(true)
    call('POST', 'getUserStatusGraph', { from: dateRange.from, to: dateRange.to, onlyShowForUserId: subAdminId }).then(result => {
      setUserStatusGraph(result.barDataForUserOnboarded)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
      console.log('error in getReportsUserStats', e);
    })
  }
  useEffect(() => {
    getUserOnboardgraph()
    getUserStatusGraph()
    getAdminProfileStats()
  }, [dateRange])
  const getUserOnboardgraph = () => {
    setShowLoader(true)
    call('POST', 'getUserOnboardgraph', { from: dateRange.from, to: dateRange.to, onlyShowForUserId: subAdminId }).then(result => {
      setOnboardGraphdata(result)
      setoverallonboardata(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
      console.log('error in getReportsUserStats', e);
    })
  }
  const getAdminProfileStats = () => {
    setShowLoader(true)
    call('POST', 'getAdminProfileStats', { from: dateRange.from, to: dateRange.to, onlyShowForUserId: subAdminId }).then(result => {
      setstatsdata(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
      console.log('error in getReportsUserStats', e);
    })
  }
  const getXAxisDateFormat = (value) => {
    let countForMonths = moment(dateRange.to).diff(dateRange.from, 'month')
    let dateFormat = ''
    if (countForMonths > 3) {
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths <= 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }
  return (
    <div className='mt-5'>
      <div className='row'>

        <div className='w-20'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>

            <label className='w-100 font-size-16 text-color-value font-wt-600 pt-2 text-left'>{"Task Assigned"}
              <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${statsdata.total_assigned || 0}`}</label></label>
            <div className='row px-0 w-100'>
              <div className='w-50 cursor'>
                <label className={`value font-wt-600 text-48DA87  w-100 cursor`}>
                  {`${statsdata.active_assigned || 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Active"}</label>
              </div>

              <div className='w-50 cursor'>
                <label className={`value font-wt-600 colorFF7B6D w-100 cursor`}>
                  {`${statsdata.inactive_assigned || 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Inactive"}</label>
              </div>
            </div>
          </div>
        </div>

        <div className='w-26'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <label className='w-100 font-size-16 text-color-value font-wt-600 pt-2 text-left'>{"Finance Application"}
              <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${(statsdata.applied || 0) + (statsdata.approved || 0) + (statsdata.rejected || 0)}`}</label></label>
            <div className='row px-0 w-100'>
              <div className='w-33 cursor'>
                <label className={`value font-wt-600 text-custom2  w-100 cursor`}>
                  {`${statsdata.applied || 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Ongoing"}</label>
              </div>

              <div className='w-33 cursor'>
                <label className={`value font-wt-600 text-48DA87  w-100 cursor`}>
                  {`${statsdata.approved || 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Approved"}</label>
              </div>

              <div className='w-33'>
                <label className={`value font-wt-600 colorFF7B6D w-100 cursor`}>
                  {`${statsdata.rejected || 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Rejected"}</label>
              </div>

            </div>
          </div>
        </div>

        <div className='w-40'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <label className='w-100 font-size-16 text-color-value font-wt-600 pt-2 text-left'>{"Finance"}
            </label>
            <div className='row px-0 w-100'>
              <div className='w-33 cursor'>
                <label className={`value font-wt-600 text-custom2  w-100 cursor`}>
                  {`$ ${statsdata.approved_limit_amount ? Intl.NumberFormat("en", { notation: 'compact' }).format(statsdata.approved_limit_amount) : 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Limit"}</label>
              </div>

              <div className='w-33 cursor'>
                <label className={`value font-wt-600  text-custom2   w-100 cursor`}>
                  {`$ ${statsdata.approved_fin_amount ? Intl.NumberFormat("en", { notation: 'compact' }).format(statsdata.approved_fin_amount) : 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Finance"}</label>
              </div>

              <div className='w-33 cursor'>
                <label className={`value font-wt-600  text-custom2   w-100 cursor`}>
                  {`$ ${statsdata.total_disbursed ? Intl.NumberFormat("en", { notation: 'compact' }).format(statsdata.total_disbursed) : 0}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Disbursement"}</label>
              </div>



            </div>
          </div>
        </div>

        <div className='w-14'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <div className='row px-0 w-100'>
              <div className='w-100 cursor'>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Added"}</label>

                <label className={`value font-wt-600  w-100 cursor`}>
                  {`${statsdata.total_buyers}`}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Buyer"}</label>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div>
        <label className='font-size-14 text-decoration-underline'>Leads</label>
        <div className='my-0'>
          <div className='filter-div ml-4 '>
            <Filter
              filteredSearch={filteredSearch}
              setFilteredSearch={setFilteredSearch}
              filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
              showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} showDownloadIcon onDownloadClick={() => { }} isAdditionalButton={true} >
            </Filter>
          </div>
          <div>
            <NewTable
              filterData={filterData}
              setFilterData={setFilterData}
              filteredSearch={filteredSearch}
              setFilteredSearch={setFilteredSearch}
              disableAction={true}
              columns={
                [
                  {
                    name: "Company", filter: true, filterDataKey: "Exporter Name", sort: [
                      { name: "Sort A-Z", selected: filter.sortCompanyName === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: 'ASC', sortContactPerson: false, sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortCompanyName === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: 'DESC', sortContactPerson: false, sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Contact person", filter: true, filterDataKey: "Contact Person", sort: [
                      { name: "Sort A-Z", selected: filter.sortContactPerson === 'ASC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: 'ASC', sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortContactPerson === 'DESC', onActionClick: () => { setFilter({ ...filter, sortCompanyName: false, sortContactPerson: 'DESC', sortCompanyCity: false, sortLeadAssignedTo: false, sortByDate: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Contact no.", filter: true, filterDataKey: "Contact Number"
                  },
                  {
                    name: "Designation"
                  },
                  { name: "Lead Type" },
                  {
                    name: "Admin"
                  },
                  {
                    name: "Period"
                  },
                  { name: "Status" },
                  {
                    name: ""
                  }
                ]


              }
              data={dbData} />
            <Pagination page={page} perPage={filter.resultPerPage} totalCount={count} onPageChange={(p) => setPage(p)} refresh={refresh} setRefresh={setRefresh} />

          </div>
        </div>
      </div>
      <div className='row pl-3 mt-4 gap-4'>

        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Task Assigned</label>

                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isLeadsGraph: !graphConfiguration.isLeadsGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isLeadsGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Leads Graph')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div>
            {graphConfiguration.isLeadsGraph ?
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={taskAssignedConfig} formatterFunction={(value, name) => [value, name]} data={onboardGraphdata} xDataKey={"label"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Leads Count" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
        <div className='card h-50 w-49 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>User Status</label>
                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isClosureGraph: !graphConfiguration.isClosureGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isClosureGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Average Closure days')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            {graphConfiguration.isClosureGraph ?
              <CustomBarChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, name]} data={userStatusGraph} xDataKey={"type"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Average Days" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
      </div>
      <div className='row pl-3 mt-4 gap-4'>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Average task close days</label>

                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isLeadsGraph: !graphConfiguration.isLeadsGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isLeadsGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Leads Graph')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div>
            {graphConfiguration.isLeadsGraph ?
              <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, name]} data={averagetaskGraph} xDataKey={"label"} dataKey={"value"} fillColor={"#FDD775"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Leads Count" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
        <div className='card h-50 w-49 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>New User Status</label>
                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isClosureGraph: !graphConfiguration.isClosureGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isClosureGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Average Closure days')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            {graphConfiguration.isClosureGraph ?
              <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, name]} data={newuserStatus} xDataKey={"name"} dataKey={"value"} fillColor={"#BAEA55"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Average Days" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
      </div>
      <div className='row pl-3 mt-4 gap-4'>

        <div className='card  w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>No. of Active Applications(Application Stage)</label>

                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isLeadsGraph: !graphConfiguration.isLeadsGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isLeadsGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Leads Graph')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div>
            {graphConfiguration.isLeadsGraph ?
              <>
                <div className='text-center h-100'>
                  <img src='/assets/images/Active users application stage.png' width={"70%"} height={"100%"} />
                </div>
                <label className='position-absolute font-size-16 font-wt-700 appstage-fl-sa'>{graphData?.activeApplicationStageFunnelGraphData?.[0]?.value || 0}</label>
                <label className='position-absolute font-size-16 font-wt-700 appstage-qts-sa'>{graphData?.activeApplicationStageFunnelGraphData?.[1]?.value || 0}</label>
                <label className='position-absolute font-size-16 font-wt-700 appstage-ts-sa'>{graphData?.activeApplicationStageFunnelGraphData?.[2]?.value || 0}</label>
                <label className='position-absolute font-size-16 font-wt-700 appstage-fin-sa'>{graphData?.activeApplicationStageFunnelGraphData?.[3]?.value || 0}</label>
                <label className='position-absolute font-size-16 font-wt-700 appstage-agree-sa'>{graphData?.activeApplicationStageFunnelGraphData?.[4]?.value || 0}</label>
                <label className='position-absolute font-size-16 font-wt-700 appstage-approved-sa'>{graphData?.activeApplicationStageFunnelGraphData?.[5]?.value || 0}</label>

              </>
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Leads Count" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
        <div className='card w-49 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Inactive Users(days since inactive)</label>
                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isClosureGraph: !graphConfiguration.isClosureGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isClosureGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Average Closure days')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            {graphConfiguration.isClosureGraph ?
              <>
                <div className='text-center h-100'>
                  <img src='/assets/images/inactive days.png' width={"70%"} height={"100%"} />
                </div>
                <label className='position-absolute font-size-16 font-wt-700 inact-15-days-sa'>{graphData?.inactiveUserDaysFunnelGraphData?.[0]?.value}</label>
                <label className='position-absolute font-size-16 font-wt-700 inact-30-days-sa'>{graphData?.inactiveUserDaysFunnelGraphData?.[1]?.value}</label>
                <label className='position-absolute font-size-16 font-wt-700 inact-45-days-sa'>{graphData?.inactiveUserDaysFunnelGraphData?.[2]?.value}</label>
                <label className='position-absolute font-size-16 font-wt-700 inact-60-days-sa'>{graphData?.inactiveUserDaysFunnelGraphData?.[3]?.value}</label>
                <label className='position-absolute font-size-16 font-wt-700 inact-75-days-sa'>{graphData?.inactiveUserDaysFunnelGraphData?.[4]?.value}</label>

              </>
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Average Days" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default SubAdminProfileDetailsAdmin