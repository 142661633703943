import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, NewTextArea, SelectForTable } from '../../../../utils/newInput';
import { PopupMessage } from '../../../popupMessage';
import { CustomProgressBar } from "../../../myCounterPartComp/checkBuyerHealth";
import { toast, ToastContainer } from "react-toastify";
import { ClearCache, convertImageToPdf, GetCache, modifi_currencies, most_used_currencies, SetCache, getDocDetails, LCPurposeObject, LCTypesObject, most_used_currencies_with_percentage, dataURItoBlob } from '../../../../utils/myFunctions';
import NewTablev2 from "../../../../utils/newTablev2";
import ChatBoxPopUp2 from "../../../chatRoom/components/ChatBoxPopUp2";
import { ExpandableTable } from "../../../wallet/components/ExpandableTable";
import { useHistory } from "react-router";
import { Action } from "../../../myCounterPartComp/action";
import ViewBuyerDetails from "../../../myCounterPartComp/viewBuyerDetails";
import moment from "moment";
import LcDetailsV3 from "../../applyforLimit/components/lcDetailsV3";
import { aiServerBackendUrl } from "../../../../urlConstants";
import axios from "axios";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const quotationTabs = [
  { name: "Supplier Details" },
  { name: "Buyer Details" },
  { name: "Application Form" },
]

const otherDetails = [
  { "name": "No. of finance applied: ", val: "totalInvApplied", unit: "" },
  { "name": "No. of finance approved: ", val: "totalInvApproved", unit: "" },
  { "name": "Total shipment done: ", val: "totalShipmentsDone", unit: "" }
]

const generalDetails = [
  { "name": "Name: ", val: 'company_name', unit: '' },
  { "name": "Organization Type: ", val: 'organization_type', unit: '' },
  { "name": "Country: ", val: 'country', unit: '' },
  { "name": "Industry Type: ", val: 'industry_type', unit: '' }
]

let supplierDetails = [
  { name: 'Supplier Name', val: "company_name" },
  { name: 'Active Application', val: 'activeApplication' },
  { name: 'Contact Number', val: 'contact_number', unit: 'phone_code' },
  { name: 'Approved Application', val: 'totalApproved' },
  { name: 'Email Id', val: 'email_id' },
  { name: 'Rejected Application', val: 'totalRejected' },
  { name: 'Website', val: 'website' },
  { name: 'Pending Application', val: 'totalPending' },
  { name: 'Address', val: 'user_address' },
  { name: '' },
  { name: 'Industry Type', val: 'industry_type' }
]

const ViewDetails = ({ userTokenDetails, navToggleState }) => {
  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)


  let [applicationDetails, setApplicationDetails] = useState(JSON.parse(localStorage.getItem("applicationDetails")))
  let onlyView = applicationDetails.onlyView ? true : false
  console.log("qqqqqqqqqqqqqqqqqqqqqqqq", applicationDetails);

  const quoteId = applicationDetails.id
  const supplierId = applicationDetails.createdBy

  const [tab, setTab] = useState(2)
  const [lcCodes, setLCCodes] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [data, setData] = useState({
    giveConfirmationFees: true, giveDiscountingRate: true, setupFeesCurrency: "USD", assignValueCurrency: "USD",
    termSofr: "3 Months", till_maturity: true, setUpFeesNegotiable: 'no', ocUnit0: '%'
  })
  const [errors, setErrors] = useState({})
  const [lcMoreDetails, setLCMoreDetails] = useState(false);
  const [userFinData, setUserFinData] = useState([])
  const [userData, setUserData] = useState({})
  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [lcMoreDocuments, setLCMoreDocuments] = useState({ modal: false, page: 0 });
  const [lcRequestMoreDetails, setLCRequestMoreDetails] = useState({ modal: false, page: 0 });
  const [additionalDetailsReq, setAdditionalDetailsReq] = useState(false);
  const [saveContinue, setSaveContinue] = useState(false);
  const [ammendFields, setAmmendFields] = useState({})
  const [ammendPopup, toggleAmmendPopup] = useState({ show: false, key: null })
  const [addMore, setAddMore] = useState(false);
  const [showComment, toggleComment] = useState(null)
  const [showDocComment, toggleDocComment] = useState(null)
  const [isAllFinancierRejected, setIsAllFinancierRejected] = useState(false)

  const [quoteForm, setQuoteForm] = useState({ modal: false, page: 0 });
  const [denyForm, setDenyForm] = useState({ modal: false, page: 0 });

  const [multInvPo, setMultInvPo] = useState([])
  const [openApplication, setOpenApplication] = useState("")
  const [expandData, setExpandData] = useState([{}])
  const [allApplicationsWithSupplier, setAllApplicationsWithSupplier] = useState([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [finActionTab, setFinancierActionTab] = useState(0)
  const [applicationType, setApplicationType] = useState(0)
  const [allApplicationTableData, setAllApplicationTableData] = useState([])
  const [quoteScreen, toggleQuoteScreen] = useState({ show: false })
  const [otherCharges, setOtherCharges] = useState([null])
  const [currencyData, setCurrencyData] = useState(most_used_currencies_with_percentage)
  const [action, setAction] = useState({ show: false, index: null })
  const [accordion, setAccordion] = useState(1);
  const [viewBuyerDetails, setViewBuyerDetails] = useState({ show: false, data: {} })

  const [SubUsers, setSubUsers] = useState([])
  const [popupMsg, setPopupMessage] = useState({ show: false })
  const [ocrTxtFormat, setOcrTxtFormat] = useState([])
  const [countrys, setCountrys] = useState([])
  const [ports, setPorts] = useState([])
  const [swiftData, setSwiftData] = useState([])
  const [userBanks, setUserBanks] = useState([])


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const currency = most_used_currencies

  const reviewForm = [
    { "name": "Buyer Name", val: "buyerName" },
    { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
    { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
    { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

    { "name": "Email Id", val: "buyerEmail" },
    { "name": "Inco Terms", val: "incoTerms" },
    { "name": "Website", val: "buyerWebsite" },
    { "name": "Terms of Payment", val: "termsOfPayment" },

    { "name": "Address", val: "buyerAddress" },
    { "name": "Product Details", val: "productDetails" },

    { "name": "DUNS No", val: "buyerDUNSNo" },
    { "name": "HSN Code", val: "buyerHsnCode" },

  ]

  const reviewForm3 = [
    { "name": "LC type", val: "lcType" },
    { "name": "LC number", val: "lcNo" },
    { "name": "LC tenor", val: "lcTenor" },
    { "name": "Shipment from country", val: "shipmentFromCountry" },
    { "name": "Shipment to country", val: "shipmentToCountry" },
    { "name": "Port of loading", val: "portOfLoading" },
    { "name": "Port of discharge", val: "portOfDischarge" },
    { "name": "Commodity", val: "commodity" },
    { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
    { "name": "Beneficiary bank name", val: "beneficiaryBankName", notAmmendable: true },
    { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo", notAmmendable: true },
    { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode", notAmmendable: true },
    { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode", notAmmendable: true },
    { "name": "LC issuing bank name", val: "lcIssuingBankName", notAmmendable: true },
    { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
    { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
    { "name": "Country of origin", val: "countryOfOrigin" },
    // { "name": "LC Purpose", val: "lcPurpose" },
    { "name": "Confirming bank name", val: "confirmingBankName" },
    { "name": "Confirming bank address", val: "confirmingBankAddress" },
    { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" }
  ]

  const supplierForm = [
    { "name": "Financial Year: ", val: "year", unit: "" },
    { "name": "Turn Over: ", val: "turnover", unit: "finCurrency" },
    { "name": "Net Profit: ", val: "netprofit", unit: "finCurrency" },
    { "name": "Net Worth: ", val: "networth", unit: "finCurrency" }
  ]

  const ProgressBars = [
    { name: "Portpolio comparision score", min: 1, max: 9, key: "portfolio_comparison_score" },
    { name: "Failure score", min: 1, max: 100, key: "failure_score" },
    { name: "Delinqency score", min: 1, max: 100, key: "delinquency_score" },
    { name: "Paydex score", min: 1, max: 100, key: "paydex_scrore", reverse: true }
  ]

  const fieldTypes = [
    { "name": "Currency", "label": "$", "icon": "" },
    { "name": "Percentage", "label": "%", "icon": "" },
    { "name": "Text", "icon": "" },
    { "name": "Date", "icon": "calender" }
  ]

  async function toggleViewBuyerDetails(buyerData) {
    setViewBuyerDetails({ show: true, data: buyerData })
  }

  async function fetchAllDocs(appendData) {
    setshowLoader(true)
    let draftLCDocument = await getDocDetails(applicationDetails.draftLC)
    // let buyerOtherDocIdArr = applicationDetails.buyerOtherDocs ? applicationDetails.buyerOtherDocs.split(":") : []
    // let otherDocObj = {}
    // let moreDocArr = addMoreDoc
    // for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
    //   otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
    //   moreDocArr.push(null)
    // }

    // load all limit docs start
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: appendData.id, fetchLCDocs: true })
    let totalInvoiceDetails = limitDocs.maxIndx
    let maxInvPoDocs = limitDocs.maxSubIndx
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    setshowLoader(false)
    setMultInvPo([...new Array(totalInvoiceDetails).fill({ invoices: new Array(maxInvPoDocs).fill(null), purchase_orders: new Array(maxInvPoDocs).fill(null) })])
    // load all limit docs end

    let { financierQuotes } = applicationDetails
    let providedQuote = {}
    if (applicationDetails.showChargesInQuote && !financierQuotes) {
      let temp = JSON.parse(applicationDetails.sblcQuoteDetails)
      providedQuote["confirmationFees"] = temp.confirmationFees
      providedQuote["till_maturity"] = temp.confirmationFeesValidity === "till_maturity"
      providedQuote["otherCharges"] = temp.otherCharges
    }
    else if (financierQuotes) {
      financierQuotes = JSON.parse(financierQuotes).forEach(i => {
        if (i.lender_id / 1 == userId / 1) {
          providedQuote = i
          providedQuote["till_maturity"] = i.confirmationFeesValidity == "till_maturity"
        }
      })
    }

    // Get amendment data start
    let amendmentData = {}
    let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, userTypeId, userId })
    if (amendmentDataRes && amendmentDataRes.length) {
      setAmmendFields(amendmentDataRes[0]["details"])
      amendmentData["additionalDetails"] = amendmentDataRes[0]["lcAdditionalDetails"]
      amendmentData["additionalDocuments"] = amendmentDataRes[0]["additionalDocuments"]
      amendmentData["amendmentId"] = amendmentDataRes[0]['id']
    }
    // Get amendment data end

    setData({ ...data, ...amendmentData, ...providedQuote, ...appendData, draftLCDocument, ...tempLimitDocsDetails })
    // setAddMoreDoc(moreDocArr)
    setshowLoader(false)
  }

  async function convertPdfToTxt(fileObj) {
    setshowLoader(true)
    let tempOCRTxtFormat = ocrTxtFormat
    let formData = new FormData()
    formData.append("doc", fileObj)
    let res = {}
    try {
      res = await axios.post(`${aiServerBackendUrl}/pdf-to-text`, formData, {
      })
      tempOCRTxtFormat[0] = res?.data?.["pages"] || []
    } catch (error) {
      tempOCRTxtFormat[0] = []
    }
    setshowLoader(false)
    setOcrTxtFormat(tempOCRTxtFormat)
  }

  // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaa", data);


  useEffect(() => {
    if (data.draftLCDocument?.filebase64) {
      let tempFileObj = dataURItoBlob(data.draftLCDocument?.filebase64)
      tempFileObj = new File([tempFileObj], data.draftLCDocument.path, { type: "application/pdf" });
      tempFileObj["filebase64"] = data.draftLCDocument.filebase64
      tempFileObj["path"] = data.draftLCDocument.path
      tempFileObj["signatureId"] = data.draftLCDocument.signatureId
      convertPdfToTxt(tempFileObj)
    }
  }, [data.draftLCDocument])

  async function asyncUseEffectFunction() {

    // Get master data start
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
    call('POST', 'getOldPortMaster').then((result) => {
      setPorts(result)
    }).catch((e) => {
      console.log('error in getOldPortMaster', e);
    })
    call('POST', 'getSwiftMaster').then((result) => {
      setSwiftData(result)
    }).catch((e) => {
      console.log('error in getSwiftMaster', e);
    })

    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    // Get master data end

    call('POST', 'isAllFinanciersRejectedQuote', {
      applicationId: quoteId
    }).then((result) => {
      console.log('running isAllFinanciersRejectedQuote api-->', result);
      setIsAllFinancierRejected(result.status)
    }).catch((e) => {
      // console.log('error in isAllFinanciersRejectedQuote', e);
    });

    setshowLoader(true)
    call('POST', 'getuserfinancegrid', {
      "currentPage": 1,
      "manualUserId": supplierId,
      "resultPerPage": 10,
      userTypeId
    }).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setshowLoader(false)
      setUserFinData(result.finData)
    }).catch((e) => {
      // console.log('error in getuserfinancegrid', e);
      setshowLoader(false)
    });

    call('POST', 'getGeneralDetailsOfExporter', {
      "userId": supplierId
    }).then((result) => {
      console.log('running getGeneralDetailsOfExporter api-->', result);
      setshowLoader(false)
      setUserData(result)
    }).catch((e) => {
      // console.log('error in getGeneralDetailsOfExporter', e);
      setshowLoader(false)
    });

    onCheckBuyerHealthClick(applicationDetails.buyerDUNSNo)

  }

  async function getAllApplicationsWithSeller() {
    setshowLoader(true)
    let resp = await call('POST', 'getAppliedForLCLimit', {
      userId, userEmail, "userTypeId": 8,
      "currentPage": 1, "resultPerPage": applicationType / 1 == 0 ? 1 : 1000, mergedApplicationId: applicationDetails.multipleApplicationId,
      singleApplication: true,
      "onlyAppliedForQuote": true
    })
    setshowLoader(false)
    setAllApplicationsWithSupplier(resp.data || [])
    let temp = []
    for (let index = 0; index < resp.data?.length; index++) {
      const element = resp.data[index];
      let row = []
      row[0] = element.buyerName || "-"
      row[1] = element.countryOfOriginName || "-"
      row[2] = element.lcPurpose ? LCPurposeObject[element.lcPurpose] : "-"
      row[3] = element.lcTenor ? (element.lcTenor + " Days") : "-"
      row[4] = element.lcIssuingBankName || "-"
      row[5] = element.lcType ? LCTypesObject[element.lcType] : "-"
      row[6] = <div className="d-flex">
        <img
          onClick={() => {
            handleApplication(index, null, null, resp.data)
          }}
          className="cursor mr-3" src="assets/images/view_docs.svg" />
        <img className="cursor"
          onClick={() => handleApplication(index, null, "openChat")} src="assets/images/chat_new_btn.svg" />
      </div>
      row[7] = <div className="d-flex">
        <img
          // onClick={() => {
          //   handleApplication(index, "approve");
          // }}
          className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
        <img
          // onClick={() => {
          //   handleApplication(index, "deny");
          // }}
          className="cursor"
          src="assets/images/reject_new_btn.svg" />
      </div>
      row[99] = element.createdAt
      temp.push(row)
    }
    setAllApplicationTableData(temp)
  }

  useEffect(() => {
    asyncUseEffectFunction()
    if (applicationDetails.multipleApplicationId?.includes("MERGED")) {
      getAllApplicationsWithSeller()
    }
  }, [applicationDetails, applicationType])

  useEffect(() => {
    getMyUsers()
  }, []);

  async function getMyUsers() {
    setSubUsers(await call('POST', 'getSubUsers', { parent_id: userId }))
  }


  function onCheckBuyerHealthClick(duns) {
    setshowLoader(true)
    call('POST', 'getBuyerHealth', { duns }).then((result) => {
      setshowLoader(false)
      // console.log("inside getBuyerHealth then", result);
      fetchAllDocs({ ...result, ...applicationDetails })
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
      fetchAllDocs(applicationDetails)
      // toastDisplay(e, "error")
    });
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else {
        return setTab(tabIndex)
      }
    }
  }

  async function submitQuote(quotation) {
    setshowLoader(true)
    let loopToIterate = applicationType / 1 == 0 ? [null] : allApplicationsWithSupplier
    for (let j = 0; j < loopToIterate.length; j++) {
      let reqObj = {
        "quote": quotation,
        userId,
        quoteId,
        userName,
        amendmentId: data.amendmentId,
        lcNo: data.lcNo,
        sellerId: allApplicationsWithSupplier?.[0]?.createdBy
      }
      if (applicationType / 1 == 1) {
        reqObj["quote"]["assignRemark"] = data[`assignRemark${j}`]
        reqObj["quote"]["status"] = data[`buyerAction`] ? "approved" : 'denied'
      }
      if (Object.keys(ammendFields).length) {
        reqObj["ammendFields"] = ammendFields
      }
      if (data.additionalDetails && data.additionalDetails.length) {
        reqObj["lcAdditionalDetails"] = data.additionalDetails
      }
      if (data.additionalDocuments && data.additionalDocuments.length) {
        reqObj["additionalDocuments"] = data.additionalDocuments
      }
      await call('POST', 'submitLCQuote', reqObj)
    }
    setshowLoader(false)
    toastDisplay("Quote sent successfully", "success", () => {
      window.location = "/finLCQuotes"
    })
  }

  async function validateQuote(action, type) {

    if (action && action === "denied") {
      let reqBody = {
        // assignValue: "",
        assignRemark: data.assignRemark,
        lender_id: userId.toString(),
        lender_name: userName,
        status: "denied",
        financierActionType: type,
        assignDate: new Date()
      }
      return submitQuote(reqBody)
    }

    let err = {}

    let validateFields = ["setupFees", "offerValidity", "handlingCharges", "courierCharges"]

    if (applicationType / 1 == 1) {
      let isAllRejected = true
      for (let index = 0; index < allApplicationsWithSupplier.length; index++) {
        if (data[`buyerAction${index}`] === undefined) {
          err["buyerAction"] = 'buyerAction'
          toastDisplay("Kindly select LC status to proceed", "info")
        }
        if (data[`buyerAction${index}`] === true) {
          isAllRejected = false
        }
      }
      if (isAllRejected) {
        validateFields = []
      }
    }

    validateFields.forEach((item, index) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    let otherChargesNewFlow = []
    //Convert otherCharges as per new flow
    for (let index = 0; index < otherCharges.length; index++) {
      if (data[`ocName${index}`] && data[`ocAmount${index}`] && data[`ocUnit${index}`]) {
        otherChargesNewFlow.push({
          name: data[`ocName${index}`],
          amount: data[`ocAmount${index}`],
          unit: data[`ocUnit${index}`],
          other: data[`ocOther${index}`]
        })
      }
    }


    if (!Object.keys(err).length) {
      let reqBody = {
        confirmationFees: data.confirmationFees,
        discountingRate: data.discountingRate,
        giveConfirmationFees: data.confirmationFees ? true : false,
        giveDiscountingRate: data.discountingRate ? true : false,
        setupFeesCurrency: data.setupFeesCurrency,
        setupFees: data.setupFees,
        offerValidity: data["offerValidity"],
        // assignValueCurrency: data.assignValueCurrency,
        // assignValue: data.assignValue,
        handlingCharges: data.handlingCharges,
        handlingChargeCurrency: data.setupFeesCurrency,
        courierCharges: data.courierCharges,
        CourierChargeCurrency: data.setupFeesCurrency,
        assignRemark: data.assignRemark,
        otherCharges: otherChargesNewFlow.length ? otherChargesNewFlow : data.otherCharges?.length ? data.otherCharges : [],
        lender_id: userId.toString(),
        lender_name: userName,
        status: "approved",
        assignDate: new Date(),
        confirmationFeesValidity: data.till_maturity ? "till_maturity" : "till_expiry",
        termSofr: data.termSofr
      }
      submitQuote(reqBody)
    }
    setErrors(err)
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function appendOtherCharge() {
    let otherCharges = data.otherCharges || []
    let temp = {
      "name": data.otherChargeTitle,
      "unit": data.setupFeesCurrency || "%",
      "amount": data.setupFeesCurrency ? data.otherChargesAmt : data.otherChargePercentage
    }
    otherCharges.push(temp)
    setData({
      ...data, otherChargePercentage: "", otherChargeTitle: "",
      otherChargesAmt: "", otherCharges
    })
  }

  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaa", data);

  const onDelete = async (datakey) => {
    const objWithIdIndex = data.otherCharges.findIndex((obj, key) => key === datakey);
    data.otherCharges.splice(objWithIdIndex, 1);
    if (data.otherCharges.length == 0) {
      delete data.otherCharges;
    }
    setData({ ...data })
  }

  const onEdit = async (item, datakey) => {
    if (item.unit == '%') {
      setData({
        ...data,
        otherChargeTitle: item.name,
        otherChargePercentage: item.amount
      })
    }
    else {
      setData({
        ...data,
        otherChargeTitle: item.name,
        otherChargesAmt: item.amount,
        otherChargeCurrency: item.unit
      })
    }
    const objWithIdIndex = data.otherCharges.findIndex((obj, key) => key === datakey);
    data.otherCharges.splice(objWithIdIndex, 1);
    if (data.otherCharges.length == 0) {
      delete data.otherCharges;
    }
  }

  // console.log("ooooooooooooooooooooooooooooooooooooooo", allApplicationsWithSupplier);

  const handleApplication = async (index, quoteForm, openChat, tempAllAppWithSeller) => {
    tempAllAppWithSeller = tempAllAppWithSeller || allApplicationsWithSupplier
    // console.log("handleApplicationnnnnnnnnnnnnnnnnnnnnnnnnnn", tempAllAppWithSeller);
    setApplicationDetails({ ...applicationDetails, ...tempAllAppWithSeller[index] })
    if (!quoteForm && !openChat) {
      setExpandData([{ ...tempAllAppWithSeller[index] }])
      setOpenApplication(tempAllAppWithSeller[index]['createdAt'])
    }
    if (openChat) {
      let item = tempAllAppWithSeller[index] || applicationDetails
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []

      let openChatRoomIndx = null
      chatRoomUsers.forEach((u, i) => {
        if (u?.split("::")[1] / 1 == userId / 1) {
          openChatRoomIndx = i
        }
      })

      let reqObj = {
        userTypeId,
        senderId: userId,
        sellerId: item.createdBy,
        receiverId: userId,
        textMessage: 'Hii',
        chat_room_name: "CHAT" + new Date().getTime(),
        includeAdmins: true,
        lcApplicationId: item.id,
        receiverParties: userId,
        dontSendInitialMsg: true
      }
      setshowLoader(true)
      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
      setshowLoader(false)
      getChatDetails({
        chat_room_id: apiResp.id,
        loggedInUser: userId
      })
      setSelectedChat({
        chatRoomId: apiResp.id,
        receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
        lcApplicationId: item.id,
        parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
        userId: userId,
        isChatOpen: true,
        receiverId: userId,
        sellerId: item.createdBy
      })
    }
    if (quoteForm) {
      if (quoteForm === "approve") {
        setQuoteForm({ modal: true, page: 1 })
      }
      if (quoteForm === 'deny') {
        setDenyForm({ modal: true, page: 0 })
      }
    }
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }


  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="viewDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Letter of Credit - Quotes"}
              userTokenDetails={userTokenDetails} />
            {viewBuyerDetails.show ? (
              <ViewBuyerDetails data={viewBuyerDetails.data} userTokenDetails={userTokenDetails}
                goback={() => setViewBuyerDetails({ show: false })}
              />
            ) : null}
            {showMsgPopup ? (
              <PopupMessage
                onClose={() => { toggleMsgPopup(false); window.location = "/finLCQuotes" }}
                title={''}
                msg={`Finance limit for Invoice discounting denied successfully !`}
              />
            ) : null}
            {popupMsg.show ? (
              <PopupMessage
                onClose={() => {
                  setPopupMessage({ show: false });
                }}
                title={''}
                msg={popupMsg.msg}
              />
            ) : null}
            {viewBuyerDetails.show ? null :
              <>

                <a className="cursor"
                  onClick={() => {
                    if (quoteScreen.show) {
                      toggleQuoteScreen({ show: false })
                    }
                    else {
                      history.go(-1)
                    }
                  }}
                ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div>
                  {quoteScreen.show ? null : <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                    {/* {quotationTabs.map((item, index) => {
                      return (
                        <li>
                          <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                            onClick={() => { handleValidation(index) }}
                          >
                            {item.name}</a>
                        </li>
                      )
                    })} */}
                    {applicationDetails.multipleApplicationId?.includes("MERGED") ?
                      <li className="ml-auto pb-1">
                        <div className="ml-2 borderRadius10 border1pxSolidD5D5D5D">
                          {[{ name: 'Single Application' }, { name: 'All Application' }].map((i, j) => {
                            return (
                              <label
                                onClick={() => setApplicationType(j)}
                                className={`m-0 borderRadius10 cursor p-2 font-size-14 ${applicationType / 1 == j ? ' bg-B9EFFF font-wt-800' : ' font-wt-500 '}  `}>{i.name}</label>
                            )
                          })}
                        </div>
                      </li> : null}
                  </ul>}

                  <div className="card mt-1">

                    {quoteScreen.show ? null : <div className="d-flex justify-content-center mt-5 mb-3" >
                      <FormProgressBar
                        tabs={quotationTabs} activeTab={tab} label={"name"}
                        separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                      />
                    </div>}

                    {tab === 0 ? (
                      <div className='px-5 py-4 pt-5 '>
                        <div className="row">
                          <div className='w-100'>
                            <label className='font-wt-500 font-size-16'><u>Finance Details</u></label>
                            <div className='row'>
                              {userFinData.length ? userFinData.map((item, index) => (
                                <div className="col-3 p-0 mb-1">
                                  {
                                    supplierForm.map((j, i) => {
                                      return (
                                        <div className='p-0'>
                                          <label className='col-6 lh-18 text-color-label font-size-14'>{j.name}</label>
                                          <label className='lh-18 font-size-14'>:</label>
                                          <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                            {item[j["val"]]} {j["unit"] === "finCurrency" ? item["currency"].split(":")[1] : ""}
                                          </label>
                                        </div>
                                      )
                                    })}
                                </div>
                              )) : null}
                            </div>
                          </div>

                          <div className='w-100 mt-4'>
                            <label className='font-wt-500 font-size-16'><u>Other Details</u></label>
                            <div className='row'>
                              {otherDetails.map((item) => {
                                return (
                                  <div className='col-12 p-0'>
                                    <label className='col-2 lh-18 text-color-label font-size-14'>{item.name}</label>
                                    <label className='lh-18 font-size-14'>:</label>
                                    <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                      {userData[item.val] || "NA"}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                          <div className='w-100 mt-5'>
                            <label className='font-wt-500 font-size-16'><u>General Details</u></label>
                            <div className='row'>
                              {generalDetails.map((item) => {
                                return (
                                  <div className='col-12 p-0'>
                                    <label className='col-2 lh-18 text-color-label font-size-14'>{item.name}</label>
                                    <label className='lh-18 font-size-14'>:</label>
                                    <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                      {userData[item.val] || "NA"}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                        </div>
                      </div>
                    ) : null}

                    {tab === 1 ? (
                      <div className='ulFormsContainer'>
                        <div className="accordionWrapper p-0 m-0">
                          {allApplicationsWithSupplier?.map((key, index) => {
                            return (
                              <div className="col-md-12 row p-0 m-0">
                                <div
                                  style={errors[`accordionRow${index}`] ? { border: '2px solid red' } : { border: accordion === (index + 1) ? '2px solid rgb(92, 184, 211)' : '0' }}
                                  className={"accordionItem col-md-12" + (accordion === (index + 1) ? " open" : " closed")}>
                                  <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion(accordion === index + 1 ? null : (index + 1)) }}>
                                    <div className="col-5" >
                                      <label className="font-size-14 font-wt-600" >{key[`buyerName`] || "NA"}</label>
                                    </div>
                                    {key.buyerName ?
                                      <div style={{ right: '2.5rem' }} className="col-7 d-flex justify-content-end" >
                                        <button type="button"
                                          onClick={() => {
                                            toggleViewBuyerDetails(key)
                                          }}
                                          className={`new-btn2 py-2 px-3 text-blue font-wt-600`}>
                                          {"View Profile"}
                                        </button>
                                      </div> : null}
                                  </div>
                                  <div className="accordionItemContent">
                                    <div className='mx-0 px-0'>
                                      <p><u>Credit Summary</u></p>
                                      <label className="font-size-16 text-color1 font-wt-600" >Maximum credit recommendation : NA</label>
                                      <div className="row d-flex px-2 pt-2">
                                        {ProgressBars.map((item, index) => {
                                          return (
                                            <div className="bg-white w-35 card-layout mr-5 mb-4 px-4 py-2">
                                              <label className="font-wt-400 font-size-14" >{item.name}</label>
                                              <CustomProgressBar reverse={item.reverse}
                                                value={data[item.key] ? data[item.key] / 1 : 0}
                                                min={item.min} max={item.max} />
                                              <div className="row pt-2 d-flex">
                                                <div className="w-50">
                                                  <label>{item.reverse ? "High Risk" : "Low risk :"} <label className="font-wt-600">{item.min}</label></label>
                                                </div>
                                                <div className="w-50 text-right">
                                                  <label>{item.reverse ? "Low Risk" : "High risk :"} <label className="font-wt-600">{item.max}</label></label>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>)
                          })}
                        </div>
                      </div>
                    ) : null}

                    {tab === 2 && lcMoreDetails ? <>
                      <img className="mb-3 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} />
                      {/* <div className='px-5 py-4 pt-5 '>
                        <div className="row">
                          <div className='w-100'>
                            <label className='font-wt-500 font-size-16'><u>LC Details</u></label>

                            <div className='row'>
                              {reviewForm3.map((item) => {
                                let isFieldChanged = false
                                return (
                                  <div className='col-md-4'>
                                    <NewInput type={"text"} isDisabled={true} label={item.name}
                                      value={data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                    />
                                    {item.notAmmendable ? null : (
                                      <div
                                        onClick={() => toggleAmmendPopup({ show: true, key: item.val })}
                                        className="cursor ammendCommentIcon"
                                      >
                                        <img className="genericImgIcon" src={ammendFields[item.val] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                      </div>
                                    )}
                                    {ammendPopup && ammendPopup.show && ammendPopup.key === item.val ? (
                                      <div className="ammendPopup">
                                        <button
                                          type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                        <div className="text-center">
                                          <NewTextArea
                                            rows={6}
                                            type={"text"} label={`Comment`} name={ammendPopup.key}
                                            value={ammendFields[ammendPopup.key]}
                                            onChange={(e) => {
                                              setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                          <div className='w-100 mt-5'>
                            <label className='font-wt-500 font-size-16 mb-4'><u>More LC Details</u></label>
                            <div className='row'>
                              {lcCodes && Object.keys(lcCodes).map((item) => {
                                let isFieldChanged = false
                                return (
                                  <div className='col-md-4'>
                                    <NewTextArea isDisabled={true} label={item + "-" + lcCodes[item]}
                                      value={data["ocrFields"][item] || " "}
                                    />
                                    <div
                                      onClick={() => toggleAmmendPopup({ show: true, key: item })}
                                      className="cursor ammendCommentIcon"
                                    >
                                      <img className="genericImgIcon" src={ammendFields[item] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                                    </div>
                                    {ammendPopup && ammendPopup.show && ammendPopup.key === item ? (
                                      <div className="ammendPopup">
                                        <button
                                          type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                                        <div className="text-center">
                                          <NewTextArea
                                            rows={6}
                                            type={"text"} label={`Comment`} name={ammendPopup.key}
                                            value={ammendFields[ammendPopup.key]}
                                            onChange={(e) => {
                                              setAmmendFields({ ...ammendFields, [ammendPopup.key]: e.target.value || undefined })
                                            }}
                                          /></div>
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              })}
                            </div></div>

                          {data.additionalDetails && data.additionalDetails.length ?
                            <div className='w-100 mt-4'>
                              <label className='font-wt-500 font-size-16'><u>Additional Details Required</u></label>
                              <div className="row">
                                {data.additionalDetails.map((item, index) =>
                                  <div className='col-md-3'>
                                    <div className="row form-group">
                                      <div className="col-md-12">
                                        {item.type === "date" ? (
                                          <img
                                            src={"/assets/images/calender.png"} alt="" className="input-date-icon2" />) : null}
                                        <input disabled={true} className={"form-control bg-white"}
                                          placeholder={`${item.type === "percentage" ? "%  " : ""}${item.type === "currency" ? "$  " : ""}${item.title}`} />
                                        <img onClick={() => toggleComment(index)} src={"/assets/images/comment_filled.png"} alt="" className="input-date-icon" />
                                      </div>
                                      {showComment === index ? (
                                        <div className="ammendPopup">
                                          <button
                                            type="button" className="btn-close ammendCommentCrossIcon"
                                            onClick={() => toggleComment(null)}></button>
                                          <div className="text-center">
                                            <NewTextArea
                                              rows={6}
                                              type={"text"} label={`Reason`}
                                              value={item.reason}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            : null}

                        </div>
                      </div> */}
                      <div className="mt-5 mx-5" >
                        <LcDetailsV3
                          indx={undefined}
                          ocrTxtFormat={ocrTxtFormat?.[0] || []}
                          adminView={true}
                          reloadUserBankDetails={() => { }}
                          handleMultiSelect={(e, name, val) => { }}
                          handleFilterOptions={() => { }}
                          // amendmentId={amendmentId}
                          fieldLabels={[]} fieldComments={{}}
                          fieldChanged={{}}
                          // ammendPopup={ammendPopup} 
                          // toggleAmmendPopup={toggleAmmendPopup}
                          // ammendPopup2={ammendPopup2} toggleAmmendPopup2={toggleAmmendPopup2}
                          ammendFields={ammendFields} setAmmendFields={setAmmendFields}
                          onlyView={false} lcCodes={[lcCodes]}
                          ocrData={data.ocrFields || {}}
                          handleOCRFieldsChange={(e) => { }}
                          data={data} setData={setData}
                          // handleChange={(e) => handleChange(e, ocrScreen.index)} 
                          errors={errors}
                          // handleFile={(e) => handleFile(e, ocrScreen.index)} 
                          countrys={countrys} currencyData={currencyData}
                          ports={ports} userBanks={userBanks} swiftData={swiftData}
                        // tab1={tab1} setTab1={setTab1}
                        // confirmbanking={confirmbanking} setConfirmbanking={setConfirmbanking} confirmBank={confirmBank}
                        />
                      </div>
                    </> : tab === 2 && quoteScreen.show ? (
                      <div className="d-flex row px-5 pt-5 pb-4" >
                        <div className="d-flex" >
                          <div className="col-9" >
                            <label className="font-size-18 font-wt-600" >Quote</label>
                            <img className="ml-3 mr-2" src="assets/images/warning.png" />
                            <label className="font-size-13 font-wt-600">Click on the “%” symbol to change is to currency.</label>
                          </div>
                          <div className="col-3 d-flex justify-content-end" >
                            <button type="button"
                              onClick={() => {
                                validateQuote()
                              }}
                              className={`border-0 mb-2 text-white enableQuotebtn2`}>
                              {"Send Quote"}
                            </button>
                          </div>
                        </div>

                        {applicationType / 1 === 1 ? <div className="mt-3" >
                          <NewTablev2 stickTableHeaderToTop columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '30%' } },
                          { subColumns: 'LC No', subColumnStyle: { width: '15%' } },
                          { subColumns: 'Limit Type', subColumnStyle: { width: '15%' } },
                          { subColumns: 'Remark', subColumnStyle: { width: '20%' } },
                          { subColumns: 'Status', subColumnStyle: { width: '16%' } },
                          { subColumns: '', subColumnStyle: { width: '7%' } }]}
                          >
                            {allApplicationsWithSupplier.map((i, j) => {
                              return (
                                <tr>
                                  <td><label className='font-size-12 font-wt-500'>{i.buyerName || "-"}</label></td>
                                  <td><label className='font-size-12 font-wt-500'>{i.lcNo || "-"}</label></td>
                                  <td><label className='font-size-12 font-wt-500'>{LCPurposeObject[i.lcPurpose]}</label></td>
                                  <td>
                                    <InputForTable name={`assignRemark${j}`} onChange={handleChange} value={data[`assignRemark${j}`]} />
                                  </td>
                                  <td>
                                    {data[`buyerAction${j}`] === undefined ?
                                      <div className="d-flex">
                                        <img
                                          onClick={() => {
                                            setData({ ...data, [`buyerAction${j}`]: true })
                                          }}
                                          className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                                        <img
                                          onClick={() => {
                                            setData({ ...data, [`buyerAction${j}`]: false })
                                          }}
                                          className="cursor"
                                          src="assets/images/reject_new_btn.svg" />
                                      </div> :
                                      < button type="button"
                                        className={` ${data[`buyerAction${j}`] ? ' approvedBtnWithBorder ' : ' rejectedBtnWithBorder'}`}>
                                        {data[`buyerAction${j}`] ? "Approved" : "Rejected"}
                                      </button>
                                    }
                                  </td>
                                  <td
                                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                    className='position-relative'>
                                    <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                      onClick={() => setAction({ show: true, index: j })}
                                      aria-hidden="true"></i>
                                    {action.show && action.index === j ? (
                                      <Action
                                        id={j}
                                        onDismiss={() => setAction({ show: false, index: j })}
                                        options={[
                                          {
                                            name: data[`buyerAction${j}`] ? "Reject LC" : "Approve LC", onClick: () => {
                                              setData({ ...data, [`buyerAction${j}`]: !data[`buyerAction${j}`] })
                                            }
                                          }
                                        ]} />
                                    ) : null}
                                  </td>
                                </tr>
                              )
                            })}
                          </NewTablev2>
                        </div> : null}

                        <div className="my-3" >
                          <NewTablev2 stickTableHeaderToTop showSideBorders={true} columns={[{ subColumns: 'Charges', subColumnStyle: { width: '25%' } }, { subColumns: 'Rate/fees', subColumnStyle: { width: '25%' } },
                          { subColumns: 'Others', subColumnStyle: { width: '40%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                            <tr>
                              <td  ><label className='font-size-12 font-wt-500'>Confirmation</label> </td>
                              <td className='' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"}
                                  selectData={[{ code: '%' }]}
                                  selectName={"na"} selectValue={"%"}
                                  optionValue={"code"} optionLabel={"code"}
                                  name={"confirmationFees"} value={data.confirmationFees} error={errors.confirmationFees}
                                  onChange={handleChange} />
                              </td>
                              <td>
                                <div className='mb-3 d-flex px-1'>
                                  <input type="radio" id="till_maturity" name="till_maturity"
                                    checked={data.till_maturity ? true : false}
                                    onChange={() => {
                                      setData({ ...data, ["till_maturity"]: !data.till_maturity })
                                    }} />
                                  <label for="till_maturity">Till maturity</label>
                                  <input type="radio" id="till_expiry" name="till_maturity"
                                    checked={!data.till_maturity ? true : false}
                                    onChange={() => {
                                      setData({ ...data, ["till_maturity"]: !data.till_maturity })
                                    }}
                                  />
                                  <label className="ml-2" for="till_expiry">Till expiry</label>
                                </div>
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td ><label className='font-size-12 font-wt-500'>Discounting</label> </td>
                              <td className='' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"}
                                  selectData={[{ code: '%' }]}
                                  selectName={"na"} selectValue={"%"}
                                  optionValue={"code"} optionLabel={"code"}
                                  name={"discountingRate"} value={data.discountingRate} error={errors.discountingRate}
                                  onChange={handleChange} />
                              </td>
                              <td className="font-size-12 font-wt-500 " >
                                <div className="d-flex row " >
                                  <div className="w-auto">
                                    <label className="font-size-12 font-wt-500 ">Inclusive of TERM SOFR? If yes, </label>
                                  </div>
                                  <div className="w-auto font-size-13 font-wt-600" ><SelectForTable name={"termSofr"}
                                    selectData={[{ name: '3 Months' }, { name: '6 Months' }, { name: '9 Months' }, { name: '12 Months' }]}
                                    optionLabel={"name"} optionValue={'name'} value={data.termSofr} onChange={handleChange} /> </div>
                                </div>
                              </td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td  ><label className='font-size-12 font-wt-500'>Setup Fees</label> </td>
                              <td className='' >
                                <InputWithSelectForTable
                                  isAstrix={false} type={"number"} label={"Setup Fees"}
                                  selectData={most_used_currencies}
                                  selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                  optionValue={"code"} optionLabel={"code"}
                                  name={"setupFees"} value={data.setupFees} error={errors.setupFees}
                                  onChange={handleChange} />
                              </td>
                              <td className="font-size-12 font-wt-500" >
                                <div className="d-flex row " >
                                  <div className="w-auto pt-1" >
                                    <label className="font-size-12 font-wt-500 ">Negotiable?</label>
                                  </div>
                                  <div className="col-7 row d-flex my-0 p-0 px-2 font-size-13 font-wt-600" >
                                    <div className='w-auto' onClick={() => {
                                      setData({ ...data, setUpFeesNegotiable: "no" })
                                    }}>
                                      <input className="form-check-input" type="radio" value={"no"} checked={data.setUpFeesNegotiable === 'no'} />
                                      <label className="form-check-label p-0 m-0" >
                                        No
                                      </label>
                                    </div>
                                    <div className='w-auto' onClick={() => {
                                      setData({ ...data, setUpFeesNegotiable: "yes" })
                                    }}>
                                      <input className="form-check-input" type="radio" value={"yes"} checked={data.setUpFeesNegotiable === 'yes'} />
                                      <label className="form-check-label p-0 m-0" >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>-</td>
                            </tr>
                            <tr>
                              <td  ><label className='font-size-12 font-wt-500'>Offer Validity</label></td>
                              <td className='font-size-13 font-wt-500' >
                                <InputForTable isAstrix={false} type={"date"} minDate={moment().format("YYYY-MM-DD")}
                                  name={"offerValidity"} value={data.offerValidity} error={errors.offerValidity}
                                  onChange={handleChange}
                                />
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td><label className='font-size-12 font-wt-500'>Handling Charges</label></td>
                              <td className='font-size-13 font-wt-500' >
                                <div className="col-9 p-0" >
                                  <InputWithSelectForTable
                                    isAstrix={true} type={"number"} label={"Handling Charges"}
                                    selectData={most_used_currencies}
                                    selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                    optionValue={"code"} optionLabel={"code"}
                                    name={"handlingCharges"} value={data.handlingCharges} error={errors.handlingCharges}
                                    onChange={handleChange} />
                                </div>
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td ><label className='font-size-12 font-wt-500'>Courier Charges</label></td>
                              <td className='font-size-13 font-wt-500' >
                                <InputWithSelectForTable
                                  isAstrix={true} type={"number"} label={"Courier Charges"}
                                  selectData={most_used_currencies}
                                  selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                  optionValue={"code"} optionLabel={"code"}
                                  name={"courierCharges"} value={data.courierCharges} error={errors.courierCharges}
                                  onChange={handleChange} />
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            {otherCharges.map((i, j) => {
                              return (<tr>
                                <td className='' >
                                  <div>
                                    <InputForTable name={`ocName${j}`} value={data[`ocName${j}`]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td className='font-size-13 font-wt-500' >
                                  <div className="" >
                                    <InputWithSelectForTable type={"number"} label={""}
                                      selectData={currencyData}
                                      selectName={`ocUnit${j}`} selectValue={data[`ocUnit${j}`]}
                                      optionValue={"code"} optionLabel={"code"}
                                      name={`ocAmount${j}`} value={data[`ocAmount${j}`]}
                                      onChange={handleChange} />
                                  </div>
                                </td>
                                <td className='' >
                                  <div>
                                    <InputForTable name={`ocOther${j}`} value={data[`ocOther${j}`]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex w-100 justify-content-between" >
                                    <div className="" >
                                      {otherCharges.length - 1 === j ?
                                        <img
                                          onClick={async () => {
                                            let temp = otherCharges
                                            temp.push(null)
                                            setOtherCharges([...temp])
                                            setData({ ...data, [`ocUnit${j + 1}`]: '%' })
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = otherCharges
                                            temp = temp.filter((l, m) => {
                                              if (m != j) {
                                                return true
                                              }
                                            })
                                            setOtherCharges([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              )
                            })}
                          </NewTablev2>
                        </div>

                        {/* <div className="my-3" >
                      <label className="font-size-13 font-wt-500" ><u>Documents</u></label>
                      <NewTablev2 columns={[{ subColumns: 'Document Name', subColumnStyle: { width: '25%' } }, { subColumns: 'Attach Document', subColumnStyle: { width: '55%' } },
                      { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                        <tr>
                          <td className='font-size-12 font-wt-500' >Term Sheet</td>
                          <td>
                            <FileInput isEditable={true} value={data.termSheet} name={"termSheet"}
                              onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                              onUploadCancel={() => setData({ ...data, termSheet: null })} />
                          </td>
                          <td>
                            {!data[`termSheet`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`termSheet`]?.name); setData({ ...data, docToSign: data[`termSheet`], docSignName: `termSheet` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`termSheet`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        {otherDocs.map((i, j) => {
                          return (
                            <tr>
                              <td className='' >
                                <div className="col-9 p-0" >
                                  <InputForTable name={`otherDocumentName${j}`} value={data[`otherDocumentName${j}`]} onChange={handleChange} />
                                </div>
                              </td>
                              <td>
                                <FileInput isEditable={true} value={data[`otherDocument${j}`]} name={`otherDocument${j}`}
                                  onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                                  onUploadCancel={() => setData({ ...data, [`otherDocument${j}`]: null })} />
                              </td>
                              <td>
                                {!data[`otherDocument${j}`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`otherDocument${j}`]?.name); setData({ ...data, docToSign: data[`otherDocument${j}`], docSignName: `otherDocument${j}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {data[`otherDocument${j}`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`otherDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`otherDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    <div className="" >
                                      {otherDocs.length - 1 === j ?
                                        <img
                                          onClick={async () => {
                                            let temp = otherDocs
                                            temp.push(null)
                                            setOtherDocs([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = otherDocs
                                            temp = temp.filter((l, m) => {
                                              if (m != j) {
                                                return true
                                              }
                                            })
                                            setOtherDocs([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div></>
                                ) : null}
                              </div></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div> */}
                      </div>
                    ) : tab === 2 ? (
                      <div className='px-5 py-4 pt-5 '>
                        <div className="row">
                          <div className='w-100'>
                            <label className='font-wt-500 font-size-16'><u>Seller Details</u></label>
                            <div className='row'>
                              {supplierDetails.map((item) => {
                                return (
                                  <div className="col-md-6 pe-5">
                                    <p className="d-flex align-items-top mb-2">
                                      <span className="col-md-4 px-0 BuyerdetailsLabel">{item.name}</span>
                                      {item.name ? <>
                                        <span className="mx-3">:</span>
                                        <span className="col-md-8 BuyerdetailsDesc" > {!item.unit ? (userData[item.val] || "-") : `${userData[item.unit]} ${userData[item.val] || "-"}`}</span>
                                      </> : null}
                                    </p>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          {applicationType / 1 == 0 ? <>
                            {applicationDetails.buyerName ? (
                              <div className='w-100 mt-4'>
                                <label className='font-wt-500 font-size-16'><u>Buyer Details</u></label>
                                <div className='row'>
                                  {reviewForm.map((item) => {
                                    return (
                                      <div className="col-md-6 pe-5">
                                        <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                      </div>
                                    )
                                  })}
                                  {/* <div className="col-md-6">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Required Limit"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                  {data["requiredLimitCurrency"] + " " + data["requiredLimit"]}
                                </span> </p>
                              </div> */}
                                </div>
                              </div>
                            ) : null}
                            <div className={`w-100 ${applicationDetails.buyerName ? 'mt-5' : ''}`}>
                              <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                              <div className='row'>
                                {reviewForm3.map((item) => {
                                  return (
                                    // <div className='col-5 p-0'>
                                    //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                                    //   <label className='lh-18 font-size-14'>:</label>
                                    //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                                    //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                    //   </label>
                                    // </div>
                                    <div className="col-md-6 pe-5">
                                      <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-4 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-8 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                    </div>
                                  )
                                })}
                              </div>
                              <p className="mt-3 text-blue cursor" onClick={() => setLCMoreDetails(true)}>View more LC details
                                <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                              </p>
                            </div>

                            <div className='w-100 mt-5'>
                              <div className='row mt-2'>
                                <div className='w-100'>
                                  <label className='font-wt-500 font-size-16'><u>Invoice & PO Details</u>
                                    <span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                    </span></label>
                                  <div className='row mt-2'>
                                    <div className="my-4">
                                      <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                                      ]}>
                                        {multInvPo.map((i, j) => {
                                          return <>
                                            {i.invoices?.map((o, p) => {
                                              return (
                                                <tr>
                                                  <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                                  <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                                  <td><div>
                                                    <FileInput hideViewDowdBtns={false} isEditable={false}
                                                      name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                                      onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                                    />
                                                    {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                      <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                                  </div></td>
                                                </tr>
                                              )
                                            })}
                                            {
                                              i.purchase_orders?.map((o, p) => {
                                                return (
                                                  <tr>
                                                    <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                                    <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                                    <td>
                                                      <div>
                                                        <FileInput hideViewDowdBtns={false}
                                                          name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                                          isEditable={false}
                                                          onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                                        />
                                                        {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                          <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                          </>
                                        })}
                                      </NewTablev2>
                                    </div>
                                    <label className='font-wt-500 font-size-16'><u>Documents</u><span className="text-blue w-40 cursor mt-2 font-wt-600 font-size-14 ml-3" onClick={() => handleApplication(0, null, true)}>Request more documents  <img src={"assets/images/right-arrow.png"} alt="Back" height={15} width={15} />
                                    </span></label>
                                    {data.draftLCDocument && data.draftLCDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Draft LC</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                    {data.logisticDocument && data.logisticDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Bill of Lading/Airway Bill</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                    {data.invReportDocument && data.invReportDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Investigation Report</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                    {data.grnDocument && data.grnDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Certificate of Origin</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                    {data.shippingBillDocument && data.shippingBillDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Shipping Bill</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                    {data.packingListDocument && data.packingListDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Packing List</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                    {data.salesContractDocument && data.salesContractDocument.name &&
                                      <div className="col-md-4">
                                        <label className="font-size-14">Sales Contract</label>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument} handleValidation
                                              isEditable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </> :
                            <div className='w-100 mt-4'>
                              <div className="d-flex align-items-center my-2" >
                                <label className='font-wt-500 font-size-16 w-40'><u>LC Details</u></label>
                                <div className="d-flex w-60 justify-content-end" >
                                  <div className="mr-4" >
                                    <img
                                      className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                                    <label className="font-size-14 font-wt-600" >Approve LC</label>
                                  </div>
                                  <div>
                                    <img
                                      className="cursor mr-3"
                                      src="assets/images/reject_new_btn.svg" />
                                    <label className="font-size-14 font-wt-600">Reject LC</label>
                                  </div>
                                </div>
                              </div>
                              <ExpandableTable
                                tableFixed data={allApplicationTableData}
                                columns={[
                                  { name: "Buyer Name", width: '12%' },
                                  { name: "Issuing Country", width: '12%' },
                                  { name: "Limit Type", width: '12%' },
                                  { name: "LC Tenor", width: '12%' },
                                  { name: "Issuing Bank Name", width: '14%' },
                                  { name: "LC Type", width: '12%' },
                                  { name: "Action", width: '9%' },
                                  { name: " ", width: '11%' }
                                ]}
                                expand={expandData}
                                tableExpand={openApplication}
                                expandKey={"createdAt"}
                                disableAction={true}
                              >
                                {expandData.length === 1 ?
                                  <div className="row">
                                    <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                                    ]}>
                                      {multInvPo.map((i, j) => {
                                        return <>
                                          {i.invoices?.map((o, p) => {
                                            return (
                                              <tr>
                                                <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                                <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                                <td><div>
                                                  <FileInput hideViewDowdBtns={false} isEditable={false}
                                                    name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                                    onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                                  />
                                                  {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                    <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                                </div></td>
                                              </tr>
                                            )
                                          })}
                                          {
                                            i.purchase_orders?.map((o, p) => {
                                              return (
                                                <tr>
                                                  <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                                  <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                                  <td>
                                                    <div>
                                                      <FileInput hideViewDowdBtns={false}
                                                        name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                                        isEditable={false}
                                                        onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                                      />
                                                      {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                        <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          {data.draftLCDocument?.name ?
                                            <tr>
                                              <td>Draft LC</td>
                                              <td>-</td>
                                              <td><FileInput name={"draftLCDocument"} value={data.draftLCDocument} error={errors.draftLCDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                          {data.logisticDocument?.name ?
                                            <tr>
                                              <td>Bill of Lading/Airway Bill</td>
                                              <td>-</td>
                                              <td><FileInput name={"logisticDocument"} value={data.logisticDocument} error={errors.logisticDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                          {data.invReportDocument?.name ?
                                            <tr>
                                              <td>Investigation Report</td>
                                              <td>-</td>
                                              <td><FileInput name={"invReportDocument"} value={data.invReportDocument} error={errors.invReportDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                          {data.grnDocument?.name ?
                                            <tr>
                                              <td>Certificate of Origin</td>
                                              <td>-</td>
                                              <td><FileInput name={"grnDocument"} value={data.grnDocument} error={errors.grnDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                          {data.shippingBillDocument?.name ?
                                            <tr>
                                              <td>Shipping Bill</td>
                                              <td>-</td>
                                              <td><FileInput name={"shippingBillDocument"} value={data.shippingBillDocument} error={errors.shippingBillDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                          {data.packingListDocument?.name ?
                                            <tr>
                                              <td>Packing List</td>
                                              <td>-</td>
                                              <td><FileInput name={"packingListDocument"} value={data.packingListDocument} error={errors.packingListDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                          {data.salesContractDocument?.name ?
                                            <tr>
                                              <td>Sales Contract</td>
                                              <td>-</td>
                                              <td><FileInput name={"salesContractDocument"} value={data.salesContractDocument} error={errors.salesContractDocument} handleValidation
                                                isEditable={false}
                                              /></td>
                                            </tr>
                                            : null}
                                        </>
                                      })}
                                    </NewTablev2>
                                  </div>
                                  : null}
                              </ExpandableTable>
                            </div>}
                        </div>
                      </div>
                    ) : null}

                    {!quoteScreen.show && tab / 1 == 2 ? <div
                      className="d-flex finActionTabContainer mt-4 mb-0 p-0 mx-5" >
                      <div
                        onClick={() => {
                          // setQuoteForm({ modal: true, page: 1 })
                          toggleQuoteScreen({ show: true })
                        }}
                        style={{ borderRight: '2px solid #5CB8D3' }}
                        className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                        <label className={"font-size-16 font-wt-700 text-white cursor"} >Send Quote</label>
                      </div>
                      {!applicationDetails.hideApplyForSblc && !applicationDetails.sblcAvailable ?
                        <div style={{ borderRight: '2px solid #5CB8D3' }}
                          className={`position-relative w-25 text-center py-2 px-2 cursor`} >
                          <label className={"font-size-14 font-wt-500 text-dark cursor"}
                            onClick={() => { window.location = `/ApplyforSblc?id=${quoteId}` }}
                          >Apply For SBLC</label>
                        </div> : null}
                      <div
                        onClick={() => {
                          setFinancierActionTab(finActionTab == 1 ? 0 : 1)
                        }}
                        style={{ borderRight: '2px solid #5CB8D3' }}
                        className={`position-relative w-25 text-center py-2 px-2 cursor`} >
                        {finActionTab == 1 ? <div
                          style={{
                            width: '14rem',
                            top: '-7rem',
                            borderRadius: "10px"
                          }}
                          className="position-absolute bg-DEF7FF " >
                          <label
                            onClick={() => validateQuote("denied", "Application Rejected")}
                            className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6" >Reject this Application</label>
                          <label
                            onClick={() => validateQuote("denied", "Buyer Rejected")}
                            className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Buyer</label>
                          <label
                            onClick={() => validateQuote("denied", "Supplier Rejected")}
                            className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Supplier</label>
                        </div> : null}
                        <label className={"font-size-14 font-wt-500 text-dark cursor"} >Reject Application</label>
                        <img
                          src="assets/images/arrow.png"
                        />
                      </div>
                      <div
                        onClick={() => handleApplication(0, null, true)}
                        style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-2">
                        <img className="mr-2"
                          src="assets/images/chat_new_icon.svg"
                        />
                        <label className={"font-size-14 font-wt-500 text-dark cursor"} >Chat / Remarks</label>
                      </div>
                      <div onClick={() => {
                        setFinancierActionTab(finActionTab == 3 ? 0 : 3)
                      }} className="w-25 text-center py-2 position-relative">
                        {finActionTab == 3 ? <div
                          style={{
                            width: '14rem',
                            top: `${(SubUsers.length + 1) * -4}rem`,
                            borderRadius: "10px"
                          }}
                          className="position-absolute " >
                          {SubUsers.map((i, j) => {
                            return (
                              <div
                                onClick={async () => {
                                  setshowLoader(true)
                                  await call('POST', 'sendSubUserApplication', {
                                    userName, subUserEmail: i.email_id,
                                    linkObj: {
                                      link: "finLCQuotesDetails", metadata: JSON.parse(localStorage.getItem("applicationDetails")),
                                      metadatakey: "applicationDetails"
                                    }
                                  })
                                  setshowLoader(false)
                                  setPopupMessage({ show: true, msg: `Application forwarded to ${i.contact_person}` })
                                }}
                                style={{ height: '5rem' }} className="d-flex row bg-white border-bottom-solid-dee2e6" >
                                <label className="font-size-13 font-wt-600 w-100 cursor text-left" >{`${i.contact_person} - ${i.designation}`}</label>
                                <label className="font-size-13 font-wt-600 w-100 cursor truncate text-left">{`${i.company_city} - ${i.company_state}`}</label>
                              </div>
                            )
                          })}
                          <div
                            onClick={() => { window.open("/viewprofile", "_blank") }}
                            style={{ height: '2rem' }} className="d-flex row bg-white border-bottom-solid-dee2e6 align-items-center" >
                            <label
                              style={{ color: 'rgb(92, 184, 211)' }} className="font-size-13 font-wt-600 w-100 cursor text-left m-0 " >{`Add New`}</label>
                          </div>
                        </div> : null}
                        <label className={"font-size-14 font-wt-500 text-dark cursor"} >Assign to other user</label>
                        <img className="ml-2"
                          src="assets/images/redirect_new_icon.png"
                        />
                      </div>
                    </div> : null
                    }

                    {(tab === 2 && lcMoreDocuments.modal && lcMoreDocuments.page === 1) ?
                      <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCMoreDocuments({ modal: false, page: 0 })}></button>
                            </div>
                            <div className="modal-body text-center">
                              <p>Request more documents</p>
                              <div className="row">
                                <div className='w-100'>
                                  <div className='row'>
                                    {data.additionalDocuments && data.additionalDocuments.length ?
                                      data.additionalDocuments.map((item, index) => <>
                                        <div className='col-md-7 move-l'>
                                          <div className="row form-group">
                                            <div className="col-md-12">
                                              <input disabled={true} className={"form-control bg-white text-al"} placeholder={item.title} />
                                              <div className="folderCommentIcon">
                                                <img src={"/assets/images/folder.png"} alt="" className="mb-3" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-md-2 move-l align-self-center'>
                                          <img
                                            onClick={() => {
                                              let temp = data.additionalDocuments;
                                              temp = temp.filter((i, j) => {
                                                if (j != index) {
                                                  return i
                                                }
                                              })
                                              setData({ ...data, additionalDocuments: temp, documentName: item.title, documentReason: item.reason })
                                            }}
                                            src={"/assets/images/edit.png"} alt="" className="mb-3" />
                                          <img
                                            onClick={() => {
                                              let temp = data.additionalDocuments;
                                              temp = temp.filter((i, j) => {
                                                if (j != index) {
                                                  return i
                                                }
                                              })
                                              setData({ ...data, additionalDocuments: temp })
                                            }} src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3" />
                                        </div></>) : null
                                    }
                                    <div className='col-md-7 move-l'>
                                      <NewInput isAstrix={true} type={"text"} label={"Document Name"}
                                        name={"documentName"} value={data.documentName} onChange={handleChange}
                                      />
                                    </div>
                                    <div className='col-md-7 move-l'>
                                      <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason`}
                                        name={"documentReason"} value={data.documentReason} onChange={handleChange}
                                      />
                                    </div>
                                    {/* <div className="col-md-7 move-l">
                                  <FileInput name={"draftLC"} value={data.draftLC} error={errors.draftLC}
                                    isEditable={true}
                                  />
                                </div> */}
                                    <div className="col-md-11 move-md text-blue">
                                      <a onClick={() => {
                                        if (!(data.documentName && data.documentReason)) {
                                          toastDisplay("Fill details to continue", "info")
                                        }
                                        else {
                                          let temp = data.additionalDocuments || []
                                          temp.push({ title: data.documentName, reason: data.documentReason })
                                          setData({ ...data, additionalDocuments: temp, documentName: "", documentReason: "" })
                                        }
                                      }}><p>Add more</p></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button type="button" className={`new-btn w-20 py-2 px-2 text-white`}
                                onClick={() => setLCMoreDocuments({ modal: false, page: 2 })}
                              >Send Request</button>
                            </div>
                          </div>
                        </div>
                      </div> :
                      (tab === 2 && lcMoreDocuments.modal && lcMoreDocuments.page === 2) &&
                      <div className={`modal fade ${lcMoreDocuments.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-m">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCMoreDocuments({ modal: false, page: 0 })}></button>
                            </div>
                            <div className="modal-body text-center">
                              <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                              <p>Request for additional documents sent successfully !</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {(tab === 2 && lcMoreDetails) ? <div className="row pb-5 mx-4">
                      {/* <button type="button"
                        onClick={() => { setSaveContinue(true); setLCMoreDetails(false) }}
                        className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                        {"Save & Continue"}
                      </button> */}

                    </div> : (tab === 2) && (
                      <>
                        {data.additionalDocuments && data.additionalDocuments.length ?
                          <div className='w-100 mx-4 px-4 pb-4'>
                            <label className='font-wt-500 font-size-16'><u>Additional Documents Required</u></label>
                            <div className="row">
                              {data.additionalDocuments.map((item, index) =>
                                <div className='col-md-3'>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <input disabled={true} className={"form-control bg-white text-al"} placeholder={item.title} />
                                      <img onClick={() => {
                                        toggleDocComment(index)
                                      }} src={"/assets/images/comment_filled.png"} alt="" className="input-date-icon" />
                                      <div className="folderCommentIcon">
                                        <img src={"/assets/images/folder.png"} alt="" className="mb-3" />
                                      </div>
                                      {showDocComment === index ? (
                                        <div className="ammendPopup">
                                          <button
                                            type="button" className="btn-close ammendCommentCrossIcon"
                                            onClick={() => toggleDocComment(null)}></button>
                                          <div className="text-center">
                                            <NewTextArea
                                              rows={6}
                                              type={"text"} label={`Reason`}
                                              value={item.reason}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          : null}
                        <div className="row pb-5 mx-4">
                          {/* <button type="button"
                        onClick={() => setQuoteForm({ modal: true, page: 1 })}
                        className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                        {"Send Quote"}
                      </button>
                      <button type="button"
                        onClick={() => setDenyForm({ modal: true, page: 0 })}
                        className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                        {"Deny"}
                      </button> */}
                          {applicationDetails.sblcAvailable ? (
                            <button type="button"
                              onClick={() => {
                                window.location = `/signEximBankTermSheet`;
                                localStorage.setItem("applicationDetails", JSON.stringify({
                                  ...applicationDetails,
                                  sblcQuoteDetails: JSON.parse(applicationDetails.sblcQuoteDetails)
                                }))
                              }}
                              className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                              {"SBLC Available"}
                            </button>
                          ) : null}
                        </div>
                      </>
                    )}

                    {tab === 0 && (
                      <div className="row pb-5 mx-4">
                        <button type="button"
                          onClick={() => window.location = `/supplierDetails?id=${supplierId}`}
                          className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`}>
                          {"View Profile"}
                        </button>
                      </div>
                    )}

                    {lcMoreDetails && lcRequestMoreDetails.modal && lcRequestMoreDetails.page === 1 &&
                      <div className={`modal fade ${lcRequestMoreDetails.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setLCRequestMoreDetails({ modal: false, page: 0 })}></button>
                            </div>
                            <div className="modal-body text-center">
                              <p>Request more details</p>
                              <div className="row">
                                <div className='w-100'>
                                  <div className=''>
                                    {data.additionalDetails && data.additionalDetails.length ? data.additionalDetails.map((item, index) => {
                                      return (
                                        <div className="row">
                                          <div className='col-md-6 move-m'>
                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <input disabled={true} className={"form-control bg-white"}
                                                  placeholder={`${item.type === "percentage" ? "%  " : ""}${item.type === "currency" ? "$  " : ""}${item.title}`} />
                                                {item.type === "date" ? (
                                                  <img src={"/assets/images/calender.png"} alt="" className="input-date-icon" />) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-2 move-m align-self-center'>
                                            <img
                                              onClick={() => {
                                                let temp = data.additionalDetails;
                                                temp = temp.filter((i, j) => {
                                                  if (j != index) {
                                                    return i
                                                  }
                                                })
                                                setData({ ...data, additionalDetails: temp, fieldTitle: item.title, fieldType: item.type, fieldReason: item.reason })
                                              }}
                                              src={"/assets/images/edit.png"} alt="" className="mb-3" />
                                            <img
                                              onClick={() => {
                                                let temp = data.additionalDetails;
                                                temp = temp.filter((i, j) => {
                                                  if (j != index) {
                                                    return i
                                                  }
                                                })
                                                setData({ ...data, additionalDetails: temp })
                                              }}
                                              src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3" />
                                          </div></div>
                                      )
                                    }) : null}
                                  </div>
                                  <div className='row'>
                                    <p>Select field type</p>
                                    <div className="col-md-3 move-m">
                                      <NewSelect highlightBorder={data.fieldType === "currency"}
                                        onClick={() => { setData({ ...data, fieldType: "currency" }) }} isDisabled={true} label={"Currency"}
                                        selectData={[]} />
                                    </div>
                                    <div className="col-md-3 move-m">
                                      <NewInput highlightBorder={data.fieldType === "percentage"}
                                        onClick={() => { setData({ ...data, fieldType: "percentage" }) }}
                                        isDisabled={true} label={"% Percentage"}
                                      />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className="col-md-3 move-m">
                                      <NewInput highlightBorder={data.fieldType === "text"}
                                        onClick={() => { setData({ ...data, fieldType: "text" }) }}
                                        isDisabled={true} label={"Text"}
                                      />
                                    </div>
                                    <div className="col-md-3 move-m">
                                      <NewInput highlightBorder={data.fieldType === "date"}
                                        onClick={() => { setData({ ...data, fieldType: "date" }) }}
                                        isDisabled={true} label={"Date"} type={'date'}
                                      />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className="col-md-6 move-m">
                                      <NewInput isAstrix={true} type={"text"} label={"Title"}
                                        name={"fieldTitle"} value={data.fieldTitle} onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className="col-md-6 move-m">
                                      <NewTextArea rows="6" isAstrix={true} type={"text"} label={`Reason.`}
                                        name={"fieldReason"} value={data.fieldReason} onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 move-left text-blue">
                                <a onClick={() => {
                                  if (!(data.fieldType && data.fieldTitle && data.fieldReason)) {
                                    toastDisplay("Fill details to continue", "info")
                                  }
                                  else {
                                    let temp = data.additionalDetails || []
                                    temp.push({ title: data.fieldTitle, type: data.fieldType, reason: data.fieldReason })
                                    setData({ ...data, additionalDetails: temp, fieldTitle: "", fieldTitle: "", fieldReason: "" })
                                    // setAddMore(true)
                                  }
                                }
                                }><p>Add more</p></a>
                              </div>
                              <button
                                type="button" className={`mx-2 new-btn w-25 py-2 px-2 text-white`} onClick={() => {
                                  setLCRequestMoreDetails({ modal: false, page: 2 });
                                  setAdditionalDetailsReq(true)
                                }}>Send Request</button>
                            </div>
                          </div>
                        </div>
                      </div>}

                    {quoteForm.modal && <>
                      <div className={`modal fade ${quoteForm.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setQuoteForm({ modal: false, page: 0 })}></button>
                            </div>
                            {(quoteForm.modal && quoteForm.page === 1) ? <div className="modal-body text-center">
                              <p>Quote</p>
                              <div className="row mt-4">
                                <div className='w-100'>
                                  <div className='row'>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Confirmation Fees"}
                                        selectData={[{ name: "%" }]}
                                        optionValue={"name"} optionLabel={"name"}
                                        name={"confirmationFees"} value={data.confirmationFees} error={errors.confirmationFees}
                                        onChange={handleChange} />
                                      <div className='mb-3 d-flex justify-content-between px-4'>
                                        <input type="radio" id="till_maturity" name="till_maturity"
                                          checked={data.till_maturity ? true : false}
                                          onChange={() => {
                                            setData({ ...data, ["till_maturity"]: !data.till_maturity })
                                          }} />
                                        <label for="till_maturity">Till maturity</label>
                                        <input type="radio" id="till_expiry" name="till_maturity"
                                          checked={!data.till_maturity ? true : false}
                                          onChange={() => {
                                            setData({ ...data, ["till_maturity"]: !data.till_maturity })
                                          }}
                                        />
                                        <label for="till_expiry">Till expiry</label>
                                      </div>
                                    </div>

                                    <div className="d-flex col-md-6 justify-content-center gap-3">
                                      <div className='col-md-3'>
                                        <button type="button"
                                          onClick={() => { setData({ ...data, "giveConfirmationFees": true }) }}
                                          // className={`border-0 mb-2 text-white ${data.giveConfirmationFees ? "approvedQuotebtn" : 'disabledQuotebtn'} `}
                                          className={` mb-2 GiveDenyBtn font-size-14 ${data.giveConfirmationFees ? "giveBtn text-white " : 'disabledQuotebtn border-0'} `}
                                        >
                                          {"Give"}
                                        </button>
                                      </div>

                                      <div className='col-md-3'>
                                        <button type="button"
                                          onClick={() => { setData({ ...data, "giveConfirmationFees": false }) }}
                                          // className={`border-0 mb-2 text-white ${data.giveConfirmationFees ? " disabledQuotebtn " : " rejectedQuotebtn "} `}
                                          className={` mb-2 GiveDenyBtn font-size-14 ${!data.giveConfirmationFees ? "DenyBtn text-white" : "disabledQuotebtn border-0"} `}
                                        >
                                          {"Deny"}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Discounting Rate"}
                                        selectData={[{ name: "%" }]}
                                        optionValue={"name"} optionLabel={"name"}
                                        name={"discountingRate"} value={data.discountingRate} error={errors.discountingRate}
                                        onChange={handleChange} />

                                      <div className='mb-3 d-flex justify-content-between pr-4 h-1'>
                                        <label className="pt-2">Inclusive of TERM SOFR</label>
                                        <NewSelect
                                          selectData={[{ "label": "3 Months" }, { "label": "6 Months" }, { "label": "9 Months" }, { "label": "12 Months" }]}
                                          optionLabel={'label'} optionValue={'label'}
                                          name={"termSofr"} value={data.termSofr} onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex col-md-6 justify-content-center gap-3">
                                      <div className='col-md-3'>
                                        <button type="button"
                                          onClick={() => { setData({ ...data, "giveDiscountingRate": true }) }}
                                          // className={`border-0 mb-2 text-white ${data.giveDiscountingRate ? "approvedQuotebtn " : 'disabledQuotebtn'} `}
                                          className={` mb-2 GiveDenyBtn font-size-14 ${data.giveDiscountingRate ? "giveBtn text-white " : 'disabledQuotebtn border-0'} `}
                                        >
                                          {"Give"}
                                        </button>
                                      </div>

                                      <div className='col-md-3'>
                                        <button type="button"
                                          onClick={() => { setData({ ...data, "giveDiscountingRate": false }) }}
                                          className={` mb-2 GiveDenyBtn font-size-14 ${!data.giveDiscountingRate ? "DenyBtn text-white" : "disabledQuotebtn border-0"} `}>
                                          {"Deny"}
                                        </button>
                                      </div>
                                    </div>

                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={false} type={"number"} label={"Setup Fees"}
                                        selectData={most_used_currencies}
                                        selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                        optionValue={"code"} optionLabel={"code"}
                                        name={"setupFees"} value={data.setupFees} error={errors.setupFees}
                                        onChange={handleChange} />
                                    </div>

                                    <div className='col-md-5 move-left'>
                                      <NewInput isAstrix={false} type={"date"} label={"Offer Valid Till"}
                                        name={"offerValidity"} value={data.offerValidity} error={errors.offerValidity}
                                        onChange={handleChange}
                                      />
                                    </div>

                                    {/* <div className='col-md-5 move-left'>
                                  <InputWithSelect
                                    isAstrix={false} type={"number"} label={"Finance Limit"}
                                    selectData={most_used_currencies}
                                    selectName={"assignValueCurrency"} selectValue={data.assignValueCurrency}
                                    optionValue={"code"} optionLabel={"code"}
                                    name={"assignValue"} value={data.assignValue} error={errors.assignValue}
                                    onChange={handleChange} />
                                </div> */}


                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={true} type={"number"} label={"Handling Charges"}
                                        selectData={most_used_currencies}
                                        selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                        optionValue={"code"} optionLabel={"code"}
                                        name={"handlingCharges"} value={data.handlingCharges} error={errors.handlingCharges}
                                        onChange={handleChange} />

                                    </div>

                                    <div className='col-md-5 move-left'>
                                      <InputWithSelect
                                        isAstrix={true} type={"number"} label={"Courier Charges"}
                                        selectData={most_used_currencies}
                                        selectName={"setupFeesCurrency"} selectValue={data.setupFeesCurrency}
                                        optionValue={"code"} optionLabel={"code"}
                                        name={"courierCharges"} value={data.courierCharges} error={errors.courierCharges}
                                        onChange={handleChange} />

                                    </div>


                                    {data.otherCharges && data.otherCharges.length ? data.otherCharges.map((item, index) => {
                                      return (
                                        <div className='col-md-5 move-left'>
                                          <InputWithSelect isDisabled={true}
                                            isAstrix={false} type={"number"} label={item["name"]}
                                            selectData={item.unit === "%" ? [{ code: "%" }] : most_used_currencies}
                                            optionValue={"code"} optionLabel={"code"} selectValue={item.unit}
                                            value={item["amount"]} />
                                        </div>
                                      )
                                    }) : null}

                                    <div className="col-md-10 text-start mx-auto ps-3 cursor">
                                      <a onClick={() => setQuoteForm({ modal: true, page: 2 })}><p className="font-size-13 fontHover">+ Add other charges</p></a>
                                    </div>
                                    <div className="col-md-10 text-start mx-auto ps-3 cursor">
                                      <a onClick={() => setQuoteForm({ modal: true, page: 3 })}><p className="font-size-13 fontHover">+ Add remark</p></a>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              <button
                                onClick={validateQuote}
                                type="button" className={`new-btn w-20 py-2 px-2 text-white`} >Send Quote</button>
                            </div> :
                              (quoteForm.modal && quoteForm.page === 2) ?
                                <>
                                  <a className="cursor" onClick={() => {
                                    setData({
                                      ...data, otherChargePercentage: "", otherChargeTitle: "",
                                      otherChargesAmt: ""
                                    })
                                    setQuoteForm({ modal: true, page: 1 })
                                  }} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                                  <div className="modal-body text-center">
                                    <p>Add Other Charges</p>
                                    <div className='row'>
                                      {data.otherCharges && data.otherCharges.map((item, key) => {
                                        return (
                                          <>
                                            <div className='col-md-6 move-m'>
                                              <InputWithSelect isDisabled={true}
                                                isAstrix={false} type={"number"} label={item["name"]}
                                                selectData={item.unit === "%" ? [{ code: "%" }] : most_used_currencies}
                                                optionValue={"code"} optionLabel={"code"} selectValue={item.unit}
                                                value={item["amount"]} />
                                            </div>
                                            <div className='col-md-3 move-l'>
                                              <img src={"/assets/images/edit.png"} alt="" className="mb-3 mt-2"
                                                onClick={() => onEdit(item, key)}
                                              />
                                              <img src={"/assets/images/cancel-icon.png"} alt="" className="mb-3 px-3 mt-2"
                                                onClick={() => onDelete(key)}
                                              />
                                            </div>
                                          </>
                                        )
                                      })}
                                    </div>
                                    <div className="row mt-4">
                                      <div className='w-100'>
                                        <div className='row mx-5 justify-content-center'>
                                          <div className='col-md-4 '>
                                            <NewSelect isDisabled={data.otherChargePercentage} label={"Select Currency"}
                                              selectData={currency} name={"setupFeesCurrency"}
                                              value={data.setupFeesCurrency} optionLabel={"code"} optionValue={'code'}
                                              error={errors.setupFeesCurrency} onChange={handleChange} />
                                          </div>
                                          <div className='col-md-4 '>
                                            <NewInput isDisabled={data.setupFeesCurrency} type={"number"} label={"% Percentage"}
                                              onChange={handleChange}
                                              name={"otherChargePercentage"} value={data.otherChargePercentage} error={errors.otherChargePercentage}
                                            />
                                          </div>
                                          <div className='col-md-8 '>
                                            <NewInput isAstrix={false} type={"text"} label={"Title"} onChange={handleChange}
                                              name={"otherChargeTitle"} value={data.otherChargeTitle} error={errors.otherChargeTitle}
                                            />
                                          </div>
                                          <div className='col-md-8'>
                                            <NewInput isDisabled={data.otherChargePercentage} type={"number"} label={"Enter Charges"} onChange={handleChange}
                                              name={"otherChargesAmt"} value={data.otherChargesAmt} error={errors.otherChargesAmt}
                                            />
                                          </div>
                                          <div className="col-md-12 move-lg">
                                            {/* <a onClick={() => setQuoteForm({ modal: true, page: 2 })}><p>+ Add other charges</p></a> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      type="button" className={` mt-4 mx-2 new-btn w-20 py-2 px-2 text-white`}
                                      onClick={() => {
                                        appendOtherCharge()
                                        setQuoteForm({ modal: true, page: 1 })
                                      }}
                                    >Add</button>
                                  </div></> :
                                (quoteForm.modal && quoteForm.page === 3) && <>
                                  <a className="cursor" onClick={() => setQuoteForm({ modal: true, page: 1 })} ><img className="mb-3 back-btn" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                                  <div className="modal-body text-center">
                                    <p>Add Remark</p>
                                    <div className="col-md-8 mx-auto mt-3 ">
                                      <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Write a remark for supplier.`}
                                        name={"assignRemark"} value={data.assignRemark} error={errors.assignRemark}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <button type="button" className={`mx-2 mt-4 new-btn w-25 py-2 px-2 text-white`}
                                      onClick={() => setQuoteForm({ modal: true, page: 1 })}>Add</button>
                                  </div></>}
                          </div>
                        </div>
                      </div></>}

                    {denyForm.modal && denyForm.page === 0 ? <>
                      <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                        <div className="modal-dialog modal-md">
                          <div className="modal-content submitmodal pb-4">
                            <div className="modal-header border-0">
                              <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                            </div>
                            <div className="modal-body text-center">
                              <img src={"/assets/images/Questionmark.png"} alt="" className="mb-3" />
                              <p>Deny Finance Limit</p>
                              <button type="button" className={`mx-4 new-btn2 w-15 py-2 px-3 text-blue`} onClick={() => setDenyForm({ modal: true, page: 1 })}>Yes</button>
                              <button type="button" className={`mx-2 new-btn w-15 py-2 px-2 text-white`} onClick={() => setDenyForm({ modal: false, page: 0 })}>No</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> :
                      denyForm.page === 1 ?
                        <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                          <div className="modal-dialog modal-md">
                            <div className="modal-content submitmodal pb-4">
                              <div className="modal-header border-0">
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                              </div>
                              <div className="modal-body text-center">
                                <p>Denial Remark</p>
                                <div className="col-md-10 mx-auto mt-2">
                                  <NewTextArea rows={6} isAstrix={false} type={"text"} label={`Leave a message for the supplier.`}
                                    name={"assignRemark"} value={data.assignRemark} error={errors.assignRemark} onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-11 text-end mt-2">
                                  <button
                                    disabled={!data.assignRemark}
                                    type="button" className={`new-btn w-20 py-2 px-2 text-white`} onClick={() => {
                                      setDenyForm({ modal: true, page: 2 })
                                      validateQuote("denied")
                                    }}>Send</button>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div> : null
                      // denyForm.page === 2 && <div className={`modal fade ${denyForm.modal && "show"}`} style={{ display: "block" }}>
                      //   <div className="modal-dialog modal-md">
                      //     <div className="modal-content submitmodal pb-4">
                      //       <div className="modal-header border-0">
                      //         <button type="button" className="btn-close" aria-label="Close" onClick={() => setDenyForm({ modal: false, page: 0 })}></button>
                      //       </div>
                      //       <div className="modal-body text-center">
                      //         <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
                      //         <p>Finance limit for LC Confirmation denied successfully !</p>
                      //       </div>
                      //     </div>
                      //   </div>
                      // </div>
                    }
                  </div>
                </div>
              </>
            }
          </main>
        </div >
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ViewDetails)