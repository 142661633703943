import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import QRCode from 'qrcode';
import SignatureCanvas from 'react-signature-canvas'
import call from "../../../service";
import { getUserDataFromCookie } from "../../../utils/cookieHelper";
import { FileInput } from "../../../utils/FileInput";
import { convertImageToPdf, dataURItoBlob, decryptData, getDocDetails, getMd5FromBase64 } from "../../../utils/myFunctions";
import toastDisplay from "../../../utils/toastNotification";
import { NewInput } from "../../../utils/newInput";
import SignDocument from "./SignDocument";

const PerformDigitalSign = ({ }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let params = queryParams.get("params")
  if (params) {
    params = decodeURIComponent(params)
    params = decryptData(params)
    params = JSON.parse(params)
  }
  console.log("paramsssssssssssssssssss", params);

  let [data, setData] = useState({})
  const [signdoc, setSigndoc] = useState(false);

  useEffect(() => {
    if (params?.doc_id) {
      loadDocument()
    }
  }, [])

  async function loadDocument() {
    let docDetails = await getDocDetails(params?.doc_id)
    console.log("doccccccccccccccccc", docDetails);
    setData({ ...data, docToSign: docDetails })
  }

  return (<div>
    {signdoc && <SignDocument customUserId={params?.senderPersonUserId} hideAuthSignSelector={true} signingPersonDesignation={params?.details?.signingPersonDesignation}
      signingPersonName={params?.details?.signingPersonName} signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
      setUpdatedDoc={async (updatedDoc) => {
        setData({ ...data, [data.docSignName]: updatedDoc })
        let formData = new FormData()
        formData.append("signedDoc", updatedDoc)
        formData.append("signatureId", updatedDoc?.signatureId)
        formData.append("docId", params?.doc_id)
        await call('POST', 'updateDigitalSign', formData)
      }} />}
    <div className="d-flex row mx-3">
      <div className="col-2 my-4">
        <img style={{ width: '10rem' }} src="assets/images/logo_1.png" />
      </div>
      <div className="col-8 d-flex row align-items-center">
        <label className="font-size-16 font-wt-600">{`Perform Digital Sign`}</label>
      </div>
      <div className="col-4 d-flex row justify-content-center align-items-center">
        <FileInput isEditable={false} value={data.docToSign} />
      </div>
      <div className="col-1 d-flex row justify-content-center align-items-center">
        {!data[`docToSign`]?.signatureId ?
          <img className="cursor" style={{ width: '3rem' }}
            onClick={() => { setSigndoc(data[`docToSign`]?.name); setData({ ...data, docSignName: `docToSign` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
          :
          <img style={{ width: '3rem' }} className=""
            src={"assets/images/doc_sign_filled.svg"} alt="" />
        }
      </div>
    </div>
  </div>)
}

export default PerformDigitalSign