import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, finLogoByUserId, GetCache, getDocDetails, getFinancerAvailability, most_used_currencies, multiSelectEventHandler, replaceFileBase64WithFileObj, SetCache, toolTip } from "../../utils/myFunctions";
import call from '../../service';
import config from '../../config.json';
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import { FileInput } from "../../utils/FileInput";
import SelectBuyerModal from "./components/selectBuyerModal";
import SignDocument from "./components/SignDocument";
import { ToastContainer } from "react-toastify";
import TutorialPopup from "../tutorialPopup";
import moment from "moment";
import TooltipModal from "./components/Tooltipmodal";
import MultipleSelect from "../../utils/MultipleSelect";
import { environment, platformURL } from "../../urlConstants";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { NewTable } from "../../utils/newTable";
import avatarUrl from "../../utils/makeAvatarUrl";
import { documentTable, documentTable2, documentTable3, multipleBuyerTable } from "../Dashboard/TableJson";
import NewTablev2 from "../../utils/newTablev2";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import ReactSwitch from "react-switch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import MultipleSelectForTable from "../../utils/MultipleSelectForTable";
import ReactCountryFlag from "react-country-flag";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";
import { useHistory } from "react-router";

const tutorialPopupCSS = {
  "0": {
    stageName: "Apply For Finance Limit",
    showNext: true,
    showBack: false,
    popup: {
      "top": "0rem",
      "right": "-3rem",
    },
    arrow: {
      "top": "8rem",
      "zIndex": 10004,
      transform: "rotate(253deg)",
      left: "30.5%",
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>Enter required limit for you & click on submit.</span></label>
  }
}

const addBuyerTabs = [
  { name: "Buyer details" },
  { name: "Documents" },
  { name: "Select financer" },
  { name: "Review" }
]

const reviewFormOld = [
  { "name": "Contract", val: "commodityContract" },
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "Buyer Annual Turnover", val: "annualTurnOver" }

]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName0" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency0" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode0" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale0", unit: "buyerCurrency0" },

  { "name": "Email Id", val: "buyerEmail0" },
  { "name": "Inco Terms", val: "incoTerms0" },
  { "name": "Website", val: "buyerWebsite0" },
  { "name": "Terms of Payment", val: "termsOfPayment0" },

  { "name": "Address", val: "buyerAddress0" },
  { "name": "Product Details", val: "productDetails0" },

  { "name": "DUNS No", val: "buyerDUNSNo0" },
  { "name": "HSN Code", val: "buyerHsnCode0" },
  { "name": "Buyer Annual Turnover", val: "annualTurnOver0" }

]

const reviewForm2 = [
  {
    name: "Buyer Name",
    value: "XYZ Pvt. Ltd.",
  },
  { name: "Previous year annual sale", value: "NIL" },

  { name: "Contact Number", value: "+91 85694 25634", },
  {
    name: "Expected current year’s annual sale",
    value: "$ 30,00,000"
  },
  { name: "Email ID", value: "xyzpvtltd@gmail.com", },
  {
    name: "Inco Terms",
    value: "$ 32,00,000"
  },
  { name: "Website", value: "www.xyzpvtltd.com", },
  {
    name: "Terms of Payment",
    value: "30 Days"
  },
  {
    name: "Address", value: "Manufacturing Solutions No.1, Huazheng Street Suzhou,Mumbai, Maharashtra,India - 400078",
  },
  {
    name: "Product details",
    value: "Accent Lumispa White Clear Linc Cbhu, Tpc Auto Nvocc Banqsha,  Lumispa Dock Spare Ass Pkgs."
  },
  { name: "Industry Type", value: "Packaging ", },
  {
    name: "HSN Code",
    value: "8509.90 ; 5896.12 ; 8549.45 ; 9856"
  },
  { name: "DUNS No.", value: "458965234751265", },

];

const reviewLabels = [
  { name: 'Total Finance Application', color: "#1B94B7", alt: "totalFinanceApplied" },
  { name: 'Application Rejected', color: '#FE4141', alt: "applicationRejected" },
  { name: 'Application Approved', color: '#00B74D', alt: "applicationApproved" },
]

const ApplyLimitComponentV2 = ({ userTokenDetails, navToggleState }) => {
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const adminINVApply = queryParams.get("adminINVApply") ? true : false
  const formContinue = queryParams.get("formContinue")
  let adminEdit = queryParams.get("adminEdit")
  let appDetails = JSON.parse(localStorage.getItem('appDetails') || "{}")
  console.log(appDetails, "appdetails")
  const [shouldSaveCache, toggleShouldSaveCache] = useState(formContinue ? false : true)

  const [multipleBuyerApplication, setMultipleBuyerApplication] = useState(true)
  const [buyerRows, setBuyerRows] = useState([null])
  const [accordion, setAccordion] = useState(1);
  const [accordion2, setAccordion2] = useState(1);
  const [accordion3, setAccordion3] = useState(1);
  const [totalDocsCount, setTotalDocsCount] = useState({})

  const [dbData, setDbData] = useState([])
  const [dbDataFilter, setDbDataFilter] = useState([])
  const [userdata, setUserdata] = useState([])
  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ limitRequiredCurrency0: "USD", currentDocuments0: true, "currency0:0:0": "USD" })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [tooltip, setTooltip] = useState(false)
  const [tooltipdata, setTooltipdata] = useState({
    lenderName: '',
    remarks: ''
  })

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [tempValue, setTempValue] = useState("")
  const [docNamePicker, toggleDocNamePicker] = useState({ show: false, id: null })
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [seeReview, setSeeReview] = useState({})

  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 8)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [finList, setFinList] = useState([])
  const [buyerdata, setBuyerdata] = useState([])
  const [multInvPo, setMultInvPo] = useState([{ purchase_orders: [null], invoices: [null] }])
  const [tableDocsData, setTableDocsData] = useState([])


  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false
  })
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')

  const [overlay, setOverlay] = useState(false);
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [cacheId, setCacheId] = useState(formContinue || null)
  const [finActionTab, setFinancierActionTab] = useState(0)
  const [draftData, setDraftData] = useState([])
  const [refresh, setRefresh] = useState(0)

  const [contractList, setContractList] = useState([])

  let jj = -1

  const userData = localStorage.getItem("UserDetails")
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1 || userTokenDetails.type_id / 1 == 20) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = userToken.user_id ? userToken.user_id : null
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const user_avatar = userToken.userAvatar ? userToken.userAvatar : null

  useEffect(() => {
    getContractList()
  }, [])


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (userPermissions.length > 0) {


      for (const role of userPermissions) {
        for (const perm of role.permissions) {
          if (perm.item === componentPath) {
            return perm.selected;
          }
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("applyLimitV2");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  async function getContractList() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getContracts', {
      "currentPage": 1,
      "resultPerPage": 1000,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    })
    // console.log("apiResppppppppppppppppppppppppppppppppp", apiResp);
    if (apiResp?.contractdata?.length) {
      setContractList(apiResp?.contractdata)
    }
    setshowLoader(false)
  }

  useEffect(() => {
    call('POST', 'getAllInvoiceLimitDrafts', { userId }).then((res) => setDraftData(res))
  }, [refresh])

  useEffect(() => {
    formatInvPoDocsForReview()
  }, [data])

  useEffect(() => {
    if (cacheId) {
      getCache()
    }
  }, [])

  async function getCache() {
    let cacheDetails = await call('POST', 'getInvoiceLimitCacheById', { cacheId })
    if (cacheDetails?.details) {
      setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
      setTab(cacheDetails.details.tab)
      setBuyerRows(cacheDetails.details.buyerRows)
      setMultInvPo(cacheDetails.details.multInvPo)
      setMultipleBuyerApplication(cacheDetails.details.multipleBuyerApplication)
      setFinList(cacheDetails.details.finList)
      toggleShouldSaveCache(true)
    }
  }

  useEffect(() => {
    if (shouldSaveCache && !adminEdit) {
      saveCache()
    }
  }, [data, tab, buyerRows, multInvPo, multipleBuyerApplication, finList])

  async function saveCache() {
    let reqBody = { userId }
    reqBody["details"] = { data, tab, buyerRows, multInvPo, multipleBuyerApplication, finList, percentageComplete: (tab) * 25 }
    if (cacheId) {
      reqBody["cacheId"] = cacheId
    }
    let tempCacheId = await call('POST', 'saveInvoiceLimitCache', reqBody)
    if (tempCacheId) {
      setCacheId(tempCacheId)
    }
  }


  async function formatInvPoDocsForReview() {
    let row = []
    for (let index = 0; index < multInvPo.length; index++) {
      const element = multInvPo[index];
      row.push(["Invoice Details " + (index + 1)])
      for (let j = 0; j < element.invoices.length; j++) {
        if (data?.["invoiceDocument" + index + ":" + j]?.name) {
          row.push([`Invoice - ${data["invoiceRefrenceNo" + index + ":" + j]}`, `${data["currency" + index + ":" + j]} ${data["contractAmount" + index + ":" + j]}`,
          <FileInput
            isEditable={false}
            value={data["invoiceDocument" + index + ":" + j]}
          />,
          <>
            {
              !data?.["invoiceDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["invoiceDocument" + index + ":" + j], docSignName: ["invoiceDocument" + index + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["invoiceDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      for (let j = 0; j < element.purchase_orders.length; j++) {
        if (data?.["poDocument" + index + ":" + j]?.name) {
          row.push([`PO - ${data["poRefrenceNo" + index + ":" + j]}`, `-`,
          <FileInput
            isEditable={false}
            value={data["poDocument" + index + ":" + j]}
          />,
          <>
            {
              !data?.["poDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data["poDocument" + index + ":" + j], docSignName: ["poDocument" + index + ":" + j] }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                </div>
              ) : null
            }
            {
              data?.["poDocument" + index + ":" + j]?.["signatureId"] ? (
                <div className="">
                  <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                </div>
              ) : null
            }
          </>])
        }
      }
      if (row.length) {
        let lastElementInArr = row[row.length - 1]
        lastElementInArr[100] = "To Add Line"
      }
    }
    setTableDocsData([...row])
  }

  useEffect(() => {
    if (buyer && dbData.length) {
      let event = {
        target: {
          name: "selectedBuyer0",
          value: buyer
        }
      }
      handleChange(event, 0)
    }
  }, [buyer, dbData])
  const getBuyerDetails = () => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getBuyerDetailsV2', objectAPI).then((result) => {
      // console.log('running getBuyersDetail api-->', result);
      setshowLoader(false)
      // setDbData([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData]);
      // setBuyerdata([{ id: "addNewBuyer", buyerName: "Add New buyer" }, ...result.buyerData])
      let tempResultBuyerData = result.buyerData
      for (let index = 0; index < tempResultBuyerData.length; index++) {
        tempResultBuyerData[index][`buyerNameHtml`] = <div>
          <label className="font-size-12 font-wt-500" >{`${tempResultBuyerData[index]["buyerName"]}`}
            <ReactCountryFlag
              countryCode={tempResultBuyerData[index].buyerCountry}
              style={{ width: '20px', height: '20px', marginLeft: '1rem' }} svg />
          </label>
        </div>
      }
      setDbData([...tempResultBuyerData]);
      setDbDataFilter([...tempResultBuyerData]);
      setBuyerdata([...result.buyerData])
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)

    });
  }

  useEffect(() => {

    //Get Buyer list start
    getBuyerDetails()
    call('POST', 'getUserdataForCredit', { userId }).then(result => {
      setUserdata(result)
    }).catch(e => {
      {
        console.log('error in getUserdataForCredit', e)
      }
    })
    //Get buyer list end

    // Get master data start
    // call('get', 'getallCountry').then((result) => {
    //   setCountrys(result)
    // }).catch((e) => {
    //   console.log('error in getallCountry', e);
    // })
    // call('get', 'getHsnCodes').then((result) => {
    //   setHsnCodes(result)
    // }).catch((e) => {
    //   console.log('error in getHsnCodes', e);
    // })
    // call("get", "getcurrencylist").then((result) => {
    //   setcurrencyData(result);
    // }).catch((e) => {
    //   console.log("error in getcurrencylist", e);
    // });
    // call("POST", "getLenderDetails").then((result) => {
    //   setFinancierData(result);
    // }).catch((e) => {
    //   console.log("error in getLenderDetails", e);
    // });
    // Get master data end
    // setOverlay(true);
  }, [])
  const getFinancersForInvoice = async (buyerdata) => {
    // setshowLoader(true)
    let exporterTurnOver = await call('POST', 'getUserDetailsFromIEC', { userId })
    exporterTurnOver = exporterTurnOver.fobUsd
    call('POST', "getFinanciersForInvoice", {}).then(async res => {
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        // element["logo"] = finLogoByUserId[element["id"]]
        element["logo"] = element.user_avatar || null
        console.log('userdataaaaaaa', {
          ...userdata,
          ...buyerdata,
          exporterTurnOver
        })
        const financerAvailability = await getFinancerAvailability(element, 'invoice', {
          ...userdata,
          ...buyerdata,
          exporterTurnOver
        })
        console.log('Financerdata', financerAvailability)
        element["isChecked"] = financerAvailability.isAvailable
        element["isAvailable"] = financerAvailability.isAvailable
        element["info_text"] = financerAvailability.info_text
        if (!financerAvailability.isAvailable) {
          element["isRecommened"] = 0
        }
        element["buyerIndex"] = buyerdata.buyerIndex
      }
      console.log("inside getFinanciersForInvoice", res);
      let tempFinList = finList
      tempFinList[buyerdata["index"]] = res
      setFinList(tempFinList)
      setshowLoader(false)
    }).catch(err => setshowLoader(false))
  }

  const hideBuyerModal = () => {
    setModal(false);
  }

  const handleChange = async (event, index, addDataToSet = {}) => {
    if (event.persist) {
      event.persist()
    }
    console.log("eventtttttttttttttttttttttt", event);
    if (event.target.name?.includes("limitRequired")) {
      setOverlay(false);
      getFinancersForInvoice({
        ...data,
        buyerIndex: index,
        limitCurrency: data[`limitRequiredCurrency${index}`],
        limitRequired: event.target.value
      })
    }
    if (event.target.name?.includes('limitRequiredCurrency')) {
      getFinancersForInvoice({
        ...data,
        buyerIndex: index,
        limitCurrency: event.target.value,
        limitRequired: data[`limitRequired${index}`]
      })
    }

    if (event.target.name.includes("selectedBuyer")) {
      setshowLoader(true)
      let selectedBuyer = dbData.filter(item => {
        if (item.id == event.target.value) {
          return item
        }
      })[0]
      getFinancersForInvoice({
        ...selectedBuyer,
        buyerIndex: index,
        limitCurrency: data[`limitRequiredCurrency${index}`],
        limitRequired: data[`limitRequired${index}`],
        index
      })
      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value,
        [`buyerName${index}`]: selectedBuyer.buyerName,
        [`buyerEmail${index}`]: selectedBuyer.buyerEmail,
        [`buyerPhone${index}`]: selectedBuyer.buyerPhone,
        [`buyerAddress${index}`]: selectedBuyer.buyerAddress,
        [`buyerWebsite${index}`]: selectedBuyer.buyerWebsite,
        [`buyerCurrency${index}`]: selectedBuyer.buyerCurrency?.split(":")?.[1],
        [`buyerDUNSNo${index}`]: selectedBuyer.buyerDUNSNo,
        [`previousAnnualSale${index}`]: selectedBuyer.previousAnnualSale,
        [`currentAnnualSale${index}`]: selectedBuyer.currentAnnualSale,
        [`buyerCountry${index}`]: selectedBuyer.buyerCountry,
        [`incoTerms${index}`]: selectedBuyer.incoTerms,
        [`productDetails${index}`]: selectedBuyer.productDetails,
        [`termsOfPayment${index}`]: selectedBuyer.termsOfPayment,
        [`buyerPhoneCode${index}`]: selectedBuyer.buyerPhoneCode,
        [`buyerHsnCode${index}`]: selectedBuyer.buyerHsnCode,
        [`annualTurnOver${index}`]: selectedBuyer.annualTurnOver,
        ...addDataToSet
      })
      hideBuyerModal()
    }
    else if (event.target.name.includes("limitRequired")) {
      let temp = buyerRows
      if (data[`selectedBuyer${temp.length - 1}`]) {
        if (multipleBuyerApplication) {
          temp.push(null)
          setBuyerRows([...temp])
        }
      }
      setData({ ...data, [event.target.name]: event.target.value, [`limitRequiredCurrency${index + 1}`]: 'USD', [`currentDocuments${index + 1}`]: true })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    else if (event.target.name?.includes('commodityContract') && event.target.value) {
      let selectedContract = contractList.find(i => {
        if (i.contract_number === event.target.value) {
          return true
        }
      })
      let selectedBuyer = dbData.find(i => {
        if (i.buyerName.toLowerCase() === selectedContract.buyername.toLowerCase()) {
          return true
        }
      })
      getFinancersForInvoice({
        ...selectedBuyer,
        buyerIndex: index,
        limitCurrency: data[`limitRequiredCurrency${index}`],
        limitRequired: data[`limitRequired${index}`],
        index
      })
      let tempData = { ...data }
      if (selectedContract?.invoice_status) {
        try {
          let invResp = await call('POST', 'getinvoiceforcontract', { "contract_number": selectedContract.contract_number, "invoiceType": "contract" })
          if (invResp?.fileBase64) {
            let blob = dataURItoBlob(invResp?.fileBase64);
            let fileObj = new File([blob], invResp.fileData.file_name, { type: "application/pdf" });
            fileObj['filebase64'] = invResp?.fileBase64
            tempData[`invoiceDocument${index}:0:0`] = fileObj
          }
        } catch (error) {
        }
      }
      if (selectedBuyer?.id) {
        tempData = {
          ...tempData, [event.target.name]: event.target.value, [`selectedBuyer${index}`]: selectedBuyer?.id,
          [`buyerName${index}`]: selectedBuyer.buyerName,
          [`buyerEmail${index}`]: selectedBuyer.buyerEmail,
          [`buyerPhone${index}`]: selectedBuyer.buyerPhone,
          [`buyerAddress${index}`]: selectedBuyer.buyerAddress,
          [`buyerWebsite${index}`]: selectedBuyer.buyerWebsite,
          [`buyerCurrency${index}`]: selectedBuyer.buyerCurrency?.split(":")?.[1],
          [`buyerDUNSNo${index}`]: selectedBuyer.buyerDUNSNo,
          [`previousAnnualSale${index}`]: selectedBuyer.previousAnnualSale,
          [`currentAnnualSale${index}`]: selectedBuyer.currentAnnualSale,
          [`buyerCountry${index}`]: selectedBuyer.buyerCountry,
          [`incoTerms${index}`]: selectedBuyer.incoTerms,
          [`productDetails${index}`]: selectedBuyer.productDetails,
          [`termsOfPayment${index}`]: selectedBuyer.termsOfPayment,
          [`buyerPhoneCode${index}`]: selectedBuyer.buyerPhoneCode,
          [`buyerHsnCode${index}`]: selectedBuyer.buyerHsnCode,
          [`annualTurnOver${index}`]: selectedBuyer.annualTurnOver
        }
      }
      else {
        tempData = {
          ...tempData, [event.target.name]: event.target.value, [`selectedBuyer${index}`]: ""
        }
      }
      setData({ ...tempData })
      setErrors({ ...errors, [event.target.name]: "", selectedBuyer0: '' })
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  console.log('ShowBuyerrrr', data)
  // function getDunsList() {
  //   setData({
  //     ...data, selectedDuns: null,
  //     buyerAddress: "",
  //     buyerPostalCode: "", buyerDunsNo: ""
  //   })
  //   setshowLoader(true)
  //   call('POST', 'getDUNSNo', {
  //     companyName: data.buyerName, countryCode: data.buyerCountry,
  //     userId, userEmail
  //   }).then((result) => {
  //     setshowLoader(false)
  //     if (result.length > 0) {
  //       setDunsData(result);
  //       setTab(1)
  //     }
  //     else {
  //       toastDisplay("No Related Buyer Details Found", "error")
  //       setTab(2)
  //       setDunsData([])
  //     }
  //   })
  // }

  // async function submitBuyerDetails() {
  //   setshowLoader(true)
  //   let formData = new FormData();
  //   formData.append("buyerName", data.buyerName)
  //   formData.append("buyerCountry", data.buyerCountry)
  //   formData.append("buyerDUNSNo", data.buyerDunsNo)
  //   formData.append("buyerAddress", data.buyerAddress)
  //   formData.append("buyerPostalcode", data.buyerPostalCode)
  //   formData.append("buyerWebsite", data.buyerWebsite)
  //   formData.append("buyerEmail", data.buyerEmail)
  //   formData.append("buyerContact", data.buyerContact)
  //   formData.append("annualSale", data.buyerPrevSale)
  //   formData.append("expectedAnnualSale", data.buyerExpectedSale)
  //   let buyerCurrency = most_used_currencies.filter(item => item.code === data.buyerCurrency)[0]
  //   formData.append("buyerCurrency", buyerCurrency["id"] + ":" + buyerCurrency["code"])
  //   formData.append("incoTerms", data.buyerIncoTerms)
  //   formData.append("payment", data.buyerTermsPayment)
  //   formData.append("productDetail", data.buyerProductDetails)
  //   formData.append("userId", userId)
  //   formData.append("userEmail", userEmail)
  //   formData.append("userName", userName)
  //   formData.append("baseUrl", config.baseUrl)
  //   formData.append("previousInvoiceDocument", data.invoiceDocument)
  //   formData.append("previousPODocument", data.poDocument)
  //   //Additional fields
  //   formData.append("buyerHsnCode", data.buyerHsnCode.length ? data.buyerHsnCode.join(",") : "")
  //   formData.append("buyerNameTitle", data.buyerNameTitle)
  //   formData.append("buyerPhoneCode", data.buyerPhoneCode)
  //   call('POST', "insertBuyersDetail", formData).then((result) => {
  //     setshowLoader(false)
  //     toggleMsgPopup(true)
  //   }).catch(err => {
  //     // console.log("insertBuyersDetail error:", err)
  //     setshowLoader(false)
  //     toastDisplay("Something went wrong", "error");
  //   })
  // }

  useEffect(() => {
    updateDocsCount()
  }, [data])

  useEffect(() => {
    if (adminEdit && dbData?.length && appDetails?.id) {
      loadTheFormForAdmin()
    }
  }, [adminEdit, dbData])

  async function loadTheFormForAdmin() {
    setshowLoader(true)
    let event = {
      target: {
        name: "selectedBuyer0",
        value: appDetails.id
      }
    }
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: appDetails.applicationId })
    let totalInvoiceDetails = limitDocs.maxIndx
    let maxInvPoDocs = limitDocs.maxSubIndx
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      if (element.label?.includes("invoiceDoc") || element.label?.includes("poDoc")) {
        tempLimitDocsDetails[element.label + ":0"] = await getDocDetails(element.docId)
      }
      else {
        tempLimitDocsDetails[element.label + "0"] = await getDocDetails(element.docId)
      }
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    // console.log("tempLimitDocsDetails====", tempLimitDocsDetails);
    if (!tempLimitDocsDetails?.['salesContractDocument0']?.name) {
      tempLimitDocsDetails["salesContractNotAvailable0"] = true
    }
    setshowLoader(false)
    handleChange(event, 0, {
      limitRequired0: appDetails.requiredLimit, limitRequiredCurrency0: appDetails.requiredLimitCurrency,
      expShipmentDate0: appDetails.expShipmentDate, currentDocuments0: appDetails.documentStatus === "currentDocuments",
      pastDocuments0: appDetails.documentStatus != "currentDocuments", ...tempLimitDocsDetails
    })
  }

  async function updateDocsCount() {
    let tempObj = {}
    for (let index = 0; index < buyerRows.length; index++) {
      if (buyerRows[index] === null) {
        tempObj[index] = 0
        for (let j = 0; j < multInvPo.length; j++) {
          for (let k = 0; k < 1; k++) {
            if (data?.[`invoiceDocument${index}:${j}:${k}`]?.name) {
              tempObj[index] += 1
            }
          }
          for (let k = 0; k < 1; k++) {
            if (data?.[`poDocument${index}:${j}:${k}`]?.name) {
              tempObj[index] += 1
            }
          }
        }
        if (data[`invReportDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`logisticDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`grnDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`salesContractDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`shippingBillDocument${index}`]?.name) {
          tempObj[index] += 1
        }
        if (data[`packingListDocument${index}`]?.name) {
          tempObj[index] += 1
        }
      }
    }
    setTotalDocsCount(tempObj)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
            setshowLoader(false)
          } catch (error) {
            setshowLoader(false)
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }
    console.log("hello validations ---->>>>>..")

    let validateFields = []
    let err = {}

    if (tab === 1) {
      for (let index = 0; index < buyerRows.length; index++) {
        if (buyerRows[index] === null) {
          let rowContentsError = false
          if (!data[`salesContractDocument${index}`]?.name && !data[`salesContractNotAvailable${index}`]) {
            err[`salesContractDocument${index}`] = "Mandatory"
            rowContentsError = true
          }
          for (let j = 0; j < multInvPo.length; j++) {
            for (let k = 0; k < multInvPo[j].purchase_orders.length; k++) {
              if ((data[`currentAnnualSale${index}`] || data[`previousAnnualSale${index}`]) && !data?.[`invoiceDocument${index}:${j}:${k}`]?.name && !data?.[`poDocument${index}:${j}:${k}`]?.name) {
                err[`poDocument${index}:${j}:${k}`] = "Po Document is mandatory"
                rowContentsError = true
              }
              if ((data[`currentAnnualSale${index}`] || data[`previousAnnualSale${index}`]) && !data?.[`invoiceDocument${index}:${j}:${k}`]?.name && !data?.[`poDocument${index}:${j}:${k}`]?.name) {
                err[`invoiceDocument${index}:${j}:${k}`] = "Invoice Document is mandatory"
                rowContentsError = true
              }
            }
          }
          if (rowContentsError) {
            err[`accordionRow${index}`] = true
          }
        }
      }
    }

    if (tab === 2) {
      for (let j = 0; j < buyerRows.length; j++) {
        if (buyerRows[j] === null) {
          let isFinSelected = false
          for (let index = 0; index < finList[j].length; index++) {
            const element = finList[j][index];
            if (element.isChecked) {
              isFinSelected = true
            }
          }
          if (!isFinSelected) {
            // err["selectFinancer"] = "Mandatory"
            err[`accordionTab2${j}`] = true
            toastDisplay("Kindly select atleast 1 financer", "info")
          }
        }
      }
    }

    if (tab == 3 && !data.tcAccepted) {
      err["tcAccepted"] = "Mandatory"
      toastDisplay("Kindly accept terms & conditions, to continue", "info")
    }

    if (tab === 0) {
      for (let index = 0; index < buyerRows.length; index++) {
        if (buyerRows[index] === null) {
          if (data[`selectedBuyer${index}`] && index > 0) {
            if (!data[`limitRequired${index}`]) {
              err[`limitRequired${index}`] = "Mandatory field"
            }
            if (!data[`limitRequiredCurrency${index}`]) {
              err[`limitRequiredCurrency${index}`] = "Mandatory field"
            }
            if (!data[`expShipmentDate${index}`]) {
              err[`expShipmentDate${index}`] = "Mandatory field"
            }
          }
          else if (index === 0) {
            if (!data[`selectedBuyer${index}`]) {
              err[`selectedBuyer${index}`] = "Mandatory field"
            }
            if (!data[`limitRequired${index}`]) {
              err[`limitRequired${index}`] = "Mandatory field"
            }
            if (!data[`limitRequiredCurrency${index}`]) {
              err[`limitRequiredCurrency${index}`] = "Mandatory field"
            }
            if (!data[`expShipmentDate${index}`]) {
              err[`expShipmentDate${index}`] = "Mandatory field"
            }
          }
        }
      }
    }

    if (!Object.keys(err).length) {
      let mergedApplicationId = null

      // delete unnecessary buyerRows
      if (tab === 0) {
        let tempBuyerRows = buyerRows
        for (let index = 0; index < buyerRows.length; index++) {
          if (!data[`selectedBuyer${index}`]) {
            tempBuyerRows[index] = undefined
          }
        }
        setBuyerRows([...tempBuyerRows])
      }

      if (buyerRows.length > 1) {
        mergedApplicationId = "MERGED" + new Date().getTime()
      }

      if (tab == 3) {
        console.log("inside tab 3---...")
        setshowLoader(true)
        for (let k = 0; k < buyerRows.length; k++) {
          if (buyerRows[k] === null) {
            let formData = new FormData()
            if (mergedApplicationId) {
              formData.append("mergedApplicationId", mergedApplicationId)
            }
            formData.append("userEmail", userEmail)
            formData.append("userId", userId)
            formData.append("buyerId", data[`selectedBuyer${k}`])
            formData.append("limitRequired", data[`limitRequired${k}`])
            formData.append("limitRequiredCurrency", data[`limitRequiredCurrency${k}`])
            formData.append("userName", userName)
            formData.append("expShipmentDate", data[`expShipmentDate${k}`])
            formData.append("buyersDocId", data[`buyersDocId${k}`])
            formData.append("bankList", JSON.stringify(finList[k]))
            console.log(formData, "this is formdata--->>>>>")
            formData.append('to', "+917310004469");
            formData.append('templateName', "export_factoring_v3");
            formData.append('mediaUrl', "https://quickscale-template-media.s3.ap-south-1.amazonaws.com/org_xn6JC4JvuN/8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
            formData.append('filename', "8cdf3dc2-3dc4-42fd-af1a-eac7a569c7fa.png");
            formData.append('placeholders', JSON.stringify(["nikita", "6676778"]));

            if (data[`commodityContract${k}`]) {
              formData.append('commodityContract', data[`commodityContract${k}`])
            }

            for (let index = 0; index < multInvPo.length; index++) {
              const element = multInvPo[index];
              for (let j = 0; j < element.invoices.length; j++) {
                if (data[`invoiceDocument${k}:${index}:${j}`]?.name) {
                  formData.append("invoiceDocument" + index + ":" + j, data[`invoiceDocument${k}:${index}:${j}`])
                  if (data[`invoiceDocument${k}:${index}:${j}`]?.signatureId) {
                    formData.append("invoiceRefrenceNo" + index + ":" + j + "SignId", data[`invoiceDocument${k}:${index}:${j}`].signatureId)
                  }
                  formData.append("invoiceRefrenceNo" + index + ":" + j, data["invoiceRefrenceNo" + k + ":" + index + ":" + j])
                  formData.append("currency" + index + ":" + j, data["currency" + k + ":" + index + ":" + j])
                  formData.append("contractAmount" + index + ":" + j, data["contractAmount" + k + ":" + index + ":" + j])
                }
              }
              for (let j = 0; j < element.purchase_orders.length; j++) {
                if (data[`poDocument${k}:${index}:${j}`]?.name) {
                  formData.append("poDocument" + index + ":" + j, data[`poDocument${k}:${index}:${j}`])
                  if (data[`poDocument${k}:${index}:${j}`]?.signatureId) {
                    formData.append("poDocument" + index + ":" + j + "SignId", data[`poDocument${k}:${index}:${j}`].signatureId)
                  }
                  formData.append("poRefrenceNo" + index + ":" + j, data["poRefrenceNo" + k + ":" + index + ":" + j])
                  formData.append("poAmount" + index + ":" + j, data["poAmount" + k + ":" + index + ":" + j])
                }
              }
            }

            if (data?.[`logisticDocument${k}`]?.name && !data?.[`logisticDocument${k}`]?.fromDb) {
              formData.append("logisticDocument", data[`logisticDocument${k}`])
            }
            if (data?.[`invReportDocument${k}`]?.name && !data?.[`invReportDocument${k}`]?.fromDb) {
              formData.append("invReportDocument", data[`invReportDocument${k}`])
            }
            if (data?.[`grnDocument${k}`]?.name && !data?.[`grnDocument${k}`]?.fromDb) {
              formData.append("grnDocument", data[`grnDocument${k}`])
            }
            if (data?.[`packingListDocument${k}`]?.name && !data?.[`packingListDocument${k}`]?.fromDb) {
              formData.append("packingListDocument", data[`packingListDocument${k}`])
            }
            if (data?.[`shippingBillDocument${k}`]?.name && !data?.[`shippingBillDocument${k}`]?.fromDb) {
              formData.append("shippingBillDocument", data[`shippingBillDocument${k}`])
            }
            if (data?.[`salesContractDocument${k}`]?.name && !data?.[`salesContractDocument${k}`]?.fromDb) {
              formData.append("salesContractDocument", data[`salesContractDocument${k}`])
            }
            if (data?.[`invoiceDocument${k}`]?.signatureId) {
              formData.append("invoiceDocSignId", data[`invoiceDocument${k}`].signatureId)
            }
            if (data?.[`poDocument${k}`]?.signatureId) {
              formData.append("poDocSignId", data[`poDocument${k}`].signatureId)
            }
            if (data?.[`logisticDocument${k}`]?.signatureId) {
              formData.append("logisticDocumentSignId", data[`logisticDocument${k}`].signatureId)
            }
            if (data?.[`invReportDocument${k}`]?.signatureId) {
              formData.append("invReportDocumentSignId", data[`invReportDocument${k}`].signatureId)
            }
            if (data?.[`grnDocument${k}`]?.signatureId) {
              formData.append("grnDocumentSignId", data[`grnDocument${k}`].signatureId)
            }
            if (data?.[`packingListDocument${k}`]?.signatureId) {
              formData.append("packingListDocumentSignId", data[`packingListDocument${k}`].signatureId)
            }
            if (data?.[`shippingBillDocument${k}`]?.signatureId) {
              formData.append("shippingBillDocumentSignId", data[`shippingBillDocument${k}`].signatureId)
            }
            if (data?.[`salesContractDocument${k}`]?.signatureId) {
              formData.append("salesContractDocumentSignId", data[`salesContractDocument${k}`].signatureId)
            }
            if (data[`currentDocuments${k}`]) {
              formData.append("documentStatus", "currentDocuments")
            }
            else if (data[`pastDocuments${k}`]) {
              formData.append("documentStatus", "pastDocuments")
            }
            // data to update
            if (data[`termsOfPayment${k}`]) {
              formData.append(`termsOfPayment`, data[`termsOfPayment${k}`])
            }
            if (data[`annualTurnOver${k}`]) {
              formData.append(`annualTurnOver`, data[`annualTurnOver${k}`])
            }
            if (adminEdit) {
              formData.append("adminReviewDone", "true")
              formData.append("appQuoteIdToUpdate", appDetails.applicationId)
            }
            await call('POST', 'applyForBuyerLimitV3', formData)
          }
        }
        setshowLoader(false)
        if (cacheId) {
          await call("POST", 'clearInvoiceLimitCacheById', { cacheId })
        }
        toastDisplay("Applied for buyer limit successfully", "success",
          () => {
            // window.location = "/Quotes"
            if (adminEdit) {
              window.location = '/invoiceLimit'
            }
            else {
              window.location = `/extraDetails?buyerId=${data[`selectedBuyer0`]}${adminINVApply ? '&adminINVApply=true' : ''}`
            }
          })
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors(err)
  }

  function handleFinSelection(j, index) {
    let temp = finList
    temp[j][index]["isChecked"] = !temp[j][index]["isChecked"]
    setFinList([...temp])
  }

  // console.log("fintlisttttttttttttttttttttttttttttttt", finList);

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredBuyers = []
    if (name.includes("buyerName")) {
      if (typedInput) {
        filteredBuyers = dbData.filter((i) => {
          if (i.buyerName.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredBuyers = dbData
      }
      setDbDataFilter([...filteredBuyers])
    }
  }

  const sendChatMsg = (file) => {
    let chatRoomName = userName + "/" + selectedChat.receiverName

    const formdata = new FormData()
    formdata.append('senderId', userId)
    formdata.append('receiverId', selectedChat.receiverId)
    if (message) {
      formdata.append('textMessage', message)
    }
    formdata.append('chat_room_name', chatRoomName)
    if (file) {
      formdata.append('docs', file)
    }
    call('POST', 'sendChatMessage', formdata).then((result) => {
      console.log('success in sendChatMessage ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }
  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(result => {
      setChatList(result)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleFileWithAsyncAwait = async (event, poNo, poSubNo, prevData, tempMultInvPoLength) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setshowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
              setshowLoader(false)
            } catch (error) {
              setshowLoader(false)
              return toastDisplay("failed to convert file", "error")
            }
            setshowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          console.log("insidehandleFileeeeeeeeeeeeeeee", tempData);
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }


  return (
    <>
      <div className={`modal fade ${seeReview.show && "show"}`} style={seeReview.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div>
                <i onClick={() => { setSeeReview({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="d-flex flex-row">
                {/* <img className="mr-2"
                  src={finLogoByUserId[seeReview.id]} alt="" /> */}
                {seeReview.logo ? (<img
                  style={{ maxWidth: '8rem', maxHeight: '2rem' }}
                  className="mx-2" src={avatarUrl(seeReview.logo)} alt="" />) : null}
                <label
                  className="font-size-16 text-color-label font-wt-500"
                >{seeReview.name}</label>
              </div>
              <div>
                <label
                  className="font-size-14 text-color1 font-wt-600"
                >{seeReview.website || "NA"}</label>
              </div>
              <div className="my-4">
                <label
                  className="font-size-14 text-color-label font-wt-500"
                >{seeReview.bio || "NA"}</label>
              </div>
              <div>
                {reviewLabels.map((item, index) => {
                  return (
                    <div
                      className={'d-flex justify-content-between py-1 px-3'}
                      style={{
                        "border": "1px solid #EEEEEE",
                        "borderRadius": "10px"
                      }}
                    >
                      <label className="font-size-14 text-color-label font-wt-500">{item.name}</label>
                      <label
                        style={{
                          color: item.color
                        }}
                        className="font-size-14 font-wt-600">{seeReview[item.alt] || "NA"}</label>
                    </div>
                  )
                })}
              </div>

              {seeReview?.reviews?.length ? (
                <>
                  <div
                    className={"darkPinkBg text-center my-4 py-4 justify-content-center align-items-center"}
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="mb-3">
                      <label
                        className="font-size-14 text-color-label font-wt-500"
                      >{seeReview?.reviews?.[reviewScrollIndex]?.remarks || "NA"}</label>
                    </div>

                    <div className="mb-3">
                      <label
                        className="font-size-22 text-color-label font-wt-500"
                      >----------------------------------------------------------</label>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center" >
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length
                          if (reviewScrollIndex == 0) {
                            toggleReviewScrollIndex(reviewsLength - 1)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex - 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-left.png'} />
                      <label className="font-size-14 text-color-label font-wt-500 mx-3">{seeReview.reviews?.[reviewScrollIndex]?.createdByName || "NA"}</label>
                      <img
                        onClick={() => {
                          let reviewsLength = seeReview?.reviews?.length - 1
                          if (reviewScrollIndex == reviewsLength) {
                            toggleReviewScrollIndex(0)
                          }
                          else {
                            toggleReviewScrollIndex(reviewScrollIndex + 1)
                          }
                        }}
                        className="cursor" src={'assets/images/arrow-right.png'} />
                    </div>

                    <div className="d-flex px-2 align-items-center justify-content-center">
                      <div className="col-6 px-0 cursor">
                        <span>
                          <p className="mb-0 font-size-14">{seeReview?.reviews?.[reviewScrollIndex]?.ratings?.toFixed(2) || "NA"}</p>
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                          <img className={`${seeReview?.reviews?.[reviewScrollIndex]?.ratings / 1 < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <TutorialPopup show={lcTutorial}
        featureName={tutorialPopupCSS[tutorialStage]["stageName"]} positioning={{
          ...tutorialPopupCSS[tutorialStage]["popup"]
        }} showSkip={true} userId={userId}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: tooltipdata.remarks, showLenderName: tooltipdata.lenderName }} />}
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {selectedChat.isChatOpen &&
        <div className="chatboxDiv">
          <ChatBoxPopUp
            chatList={chatList}
            user_avatar={user_avatar}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            onChatSend={() => sendChatMsg()}
            message={message}
            setTextMsg={setTextMsg}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false
              })
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className={`${overlay && 'overlay_in'}`}></div>
        <div className="row">
          <SideBarV2 state="invoice_applyLimit" userTokenDetails={userToken} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Apply for Limit"}
              userTokenDetails={userToken} />
            <a
              onClick={() => {
                history.go(-1)
              }}
              className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1"
                style={lcTutorial ? {
                  "background": "white",
                  "position": "relative",
                  "zIndex": 10001,
                  "padding": "1rem 0rem 1rem 0rem",
                  "borderRadius": "1rem",
                } : {}}>
                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, j) => {
                      handleValidation(j)
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='ulFormsContainer'>
                    <div className="row"
                    >
                      <div className='mb-3 d-flex pr-2 align-items-center'>
                        <label className={!multipleBuyerApplication ? "font-wt-500 font-size-16" :
                          "font-wt-700 font-size-16 color-2ECC71"} for="currentDocuments">Multiple Buyer</label>
                        <img
                          className="cursor mx-3"
                          onClick={() => setMultipleBuyerApplication(!multipleBuyerApplication)}
                          src={multipleBuyerApplication ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                        />
                        <label className={multipleBuyerApplication ? "font-wt-500 font-size-16" :
                          "font-wt-700 font-size-16 color-2ECC71"} for="pastDocuments">Single Buyer</label>
                      </div>

                      {multipleBuyerApplication ? <div>
                        <NewTablev2 showSideBorders={true} columns={multipleBuyerTable}>
                          {buyerRows.map((i, j) => {
                            if (i === null) {
                              jj = jj + 1
                              return (
                                <tr>
                                  <td>
                                    <MultipleSelectForTable
                                      Label={''}
                                      Id={`commodityContract${j}`}
                                      optiondata={contractList}
                                      onChange={(e) => {
                                        handleChange(multiSelectEventHandler(e, `commodityContract${j}`, "contract_number"), j)
                                      }}
                                      value={data[`commodityContract${j}`] ? [data[`commodityContract${j}`]] : []}
                                      name={`commodityContract${j}`}
                                      labelKey={"contract_name"}
                                      valKey={"contract_number"}
                                      error={errors[`commodityContract${j}`]}
                                    />
                                  </td>
                                  <td>
                                    <MultipleSelectForTable
                                      filterOption={() => true}
                                      onInputChange={(e) => {
                                        handleFilterOptions(e, "buyerName")
                                      }}
                                      Label={""}
                                      Id={`selectedBuyer${j}`}
                                      optiondata={dbDataFilter}
                                      onChange={(e) => {
                                        handleChange(multiSelectEventHandler(e, `selectedBuyer${j}`, "id"), j)
                                      }}
                                      value={data[`selectedBuyer${j}`] ? [parseInt(data[`selectedBuyer${j}`])] : []}
                                      name={`selectedBuyer${j}`}
                                      labelKey={"buyerNameHtml"}
                                      valKey={"id"}
                                      error={errors[`selectedBuyer${j}`]}
                                    />
                                  </td>
                                  <td className="cursor"
                                    onClick={() => {
                                      if (data[`selectedBuyer${j}`]) {
                                        window.open(`/buyerManagement?editBuyer=${data[`selectedBuyer${j}`]}`, "_blank");
                                        localStorage.setItem('afterUpdateCloseTab', true)
                                      }
                                    }}
                                  >{data[`buyerDUNSNo${j}`] || data[`buyerAddress${j}` || "-"]}</td>
                                  <td><InputForTable type={"text"} label={""} name={`termsOfPayment${j}`} value={data[`termsOfPayment${j}`]}
                                    onChange={(e) => handleChange(e, j)}
                                  /></td>
                                  <td>{
                                    <SelectForTable label={""}
                                      selectData={[{ title: 'More than 250 Cr' }, { title: 'Less than 250 Cr' }]} name={`annualTurnOver${j}`} error={errors[`annualTurnOver${j}`]}
                                      value={data[`annualTurnOver${j}`]} optionLabel={"title"} optionValue={'title'}
                                      onChange={(e) => handleChange(e, j)} />}</td>
                                  <td><InputForTable
                                    type={"date"} name={`expShipmentDate${j}`} value={data[`expShipmentDate${j}`]}
                                    onChange={(e) => handleChange(e, j)} error={errors[`expShipmentDate${j}`]}
                                  /></td>
                                  <td><InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                    selectData={currencyData}
                                    selectName={`limitRequiredCurrency${j}`} selectValue={data[`limitRequiredCurrency${j}`]}
                                    optionLabel={"code"} optionValue={'code'}
                                    name={`limitRequired${j}`} value={data[`limitRequired${j}`]} error={errors[`limitRequired${j}`] || errors[`limitRequiredCurrency${j}`]}
                                    onChange={(e) => {
                                      handleChange(e, j)
                                    }} /></td>
                                  <td>
                                    <div className="d-flex w-100 justify-content-between" >
                                      {adminEdit ? null :
                                        <div className="" >
                                          {(buyerRows.length - buyerRows.filter(i => {
                                            if (i === undefined) {
                                              return true
                                            }
                                          }).length) - 1 === jj ?
                                            <img
                                              onClick={async () => {
                                                let temp = buyerRows
                                                temp.push(null)
                                                setBuyerRows([...temp])
                                                setData({ ...data, [`limitRequiredCurrency${j + 1}`]: 'USD', [`currentDocuments${j + 1}`]: true })
                                              }}
                                              className="cursor" src="assets/images/add_black_icon.png" /> :
                                            <img
                                              onClick={() => {
                                                let temp = buyerRows
                                                temp[j] = undefined
                                                setBuyerRows([...temp])
                                              }}
                                              className="cursor" src="assets/images/delete.png" />}
                                        </div>}
                                      <div className="">
                                        <img className="cursor"
                                          onClick={() => {
                                            if (data[`selectedBuyer${j}`]) {
                                              window.open(`/buyerManagement?editBuyer=${data[`selectedBuyer${j}`]}`, "_blank");
                                              localStorage.setItem('afterUpdateCloseTab', true)
                                            }
                                          }}
                                          src="assets/images/open-link.png" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </NewTablev2>
                        {!adminEdit ? <div
                          className="cursor" >
                          <label
                            onClick={() => {
                              window.open(`/buyerManagement?action=addNewBuyer`, "_blank");
                              localStorage.setItem('afterUpdateCloseTab', true)
                            }} className="font-size-14 text-color1 font-wt-600 mx-1 cursor">{"Add New Buyer"}</label>
                          <img
                            onClick={() => {
                              window.open(`/buyerManagement?action=addNewBuyer`, "_blank");
                              localStorage.setItem('afterUpdateCloseTab', true)
                            }}
                            className="cursor text-color1" src={'assets/images/right-arrow.png'} />
                        </div> : null}

                      </div> :
                        <div className='w-100'>
                          <div className="row d-flex py-3" >
                            <div className="col-3" style={{ position: "relative", zIndex: "9" }}>
                              <MultipleSelect singleSelection
                                Label={'Select Contract'}
                                Id={`commodityContract0`}
                                optiondata={contractList}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `commodityContract0`, "contract_number"), 0)
                                }}
                                value={data[`commodityContract0`] ? [data[`commodityContract0`]] : []}
                                name={`commodityContract0`}
                                labelKey={"contract_name"}
                                valKey={"contract_number"}
                                error={errors[`commodityContract0`]}
                              />
                            </div>
                            <div className="col-3" style={{ position: "relative", zIndex: "9" }} >
                              <NewSelect isAstrix={false} label={"Select Buyer"}
                                selectData={dbData} name={`selectedBuyer0`} error={errors[`selectedBuyer0`]}
                                value={data[`selectedBuyer0`]} optionLabel={"buyerName"} optionValue={'id'}
                                onChange={(e) => handleChange(e, 0)} />
                            </div>
                            <div className="col-3" style={{ position: "relative", zIndex: "9" }} >
                              <InputWithSelect isAstrix={true} type={"number"} label={"Enter Limit Required"}
                                selectData={currencyData}
                                selectName={"limitRequiredCurrency0"} selectValue={data[`limitRequiredCurrency0`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={'limitRequired0'} value={data[`limitRequired0`]} error={errors[`limitRequired0`]}
                                onChange={(e) => handleChange(e, 0)} />
                            </div>
                            <div className="col-3" style={{ position: "relative", zIndex: "9" }} >
                              <NewInput isAstrix={true} type={"date"} label={"Expected date of shipment"} minDate={moment().subtract(1, "month").format("YYYY-MM-DD")}
                                name={'expShipmentDate0'} value={data[`expShipmentDate0`]} error={errors[`expShipmentDate0`]}
                                onChange={(e) => handleChange(e, 0)} />
                            </div>
                          </div>
                          <div className="row">
                            {reviewForm.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            onClick={() => {
                              if (data[`selectedBuyer0`]) {
                                window.open(`/buyerManagement?editBuyer=${data[`selectedBuyer0`]}`, "_blank");
                                localStorage.setItem('afterUpdateCloseTab', true)
                              }
                            }}
                            className="cursor py-2" >
                            <label className="font-size-14 text-color1 font-wt-600 mx-1 cursor">{"Edit Buyer Details"}</label>
                            <img
                              className="cursor text-color1" src={'assets/images/right-arrow.png'} />
                          </div>
                        </div>}
                    </div>
                  </div>
                ) : null}
                {tab === 1 ? (
                  <div className='ulFormsContainer'>

                    <div className="accordionWrapper p-0 m-0">
                      {buyerRows.map((key, index) => {
                        if (key === null) {
                          return (
                            <div className="col-md-12 row p-0 m-0">
                              <div
                                style={errors[`accordionRow${index}`] ? { border: '2px solid red' } : { border: accordion === (index + 1) ? '2px solid rgb(92, 184, 211)' : '0' }}
                                className={"accordionItem col-md-12" + (accordion === (index + 1) ? " open" : " closed")}>
                                <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion(accordion === index + 1 ? null : (index + 1)) }}>
                                  <label className="font-size-14 font-wt-600 col-3" >{data[`buyerName${index}`]}</label>
                                  <div className='mb-3 d-flex pl-2 pt-3 align-items-center col-auto'>
                                    <label className={!data[`currentDocuments${index}`] ? "font-wt-500 font-size-14" :
                                      "font-wt-800 font-size-14 color-2ECC71"} for={`currentDocuments${index}`}>Current Trade Documents</label>
                                    <img
                                      className="cursor mx-3"
                                      onClick={() => setData({ ...data, [`currentDocuments${index}`]: !data[`currentDocuments${index}`], [`pastDocuments${index}`]: !data[`pastDocuments${index}`] })}
                                      src={data[`currentDocuments${index}`] ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                                    />
                                    <label className={!data[`pastDocuments${index}`] ? "font-wt-500 font-size-14" :
                                      "font-wt-800 font-size-14 color-2ECC71"} for={`pastDocuments${index}`}>Past Trade Documents</label>
                                    <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2 mb-2" />
                                    <div style={{ top: '3rem' }} className="otherChargePopup p-3">
                                      <div>
                                        <label className="font-size-13 font-wt-600" ><u>Current Trade Documents:</u></label>
                                        <label className="font-size-12 font-wt-500">Current trade documents are documents related to ongoing or future trade transactions</label>
                                        <label className="font-size-13 font-wt-600" ><u>Past Trade Documents:</u></label>
                                        <label className="font-size-12 font-wt-500">Past trade documents are records of completed trade transactions</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{
                                    // height: '2rem',
                                    width: '5rem'
                                  }} className="" >
                                    <CircularProgressbar
                                      styles={buildStyles({
                                        pathColor: '#2ECC71',
                                        textColor: '#000000'
                                      })}
                                      value={(totalDocsCount[index] / 8) * 100} text={`${totalDocsCount[index]}/8`} />
                                  </div>
                                </div>
                                <div className="accordionItemContent">
                                  <div
                                    className={"mx-0 px-0"}
                                  >
                                    <label
                                      className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                                    ><u>{`Invoice & Purchase Order`}</u></label>

                                    <NewTablev2 columns={documentTable}>
                                      {multInvPo.map((i, j) => {
                                        return <>
                                          {i.invoices?.map((o, p) => {
                                            return (
                                              <tr>
                                                <td>{"Invoice"}</td>
                                                <td><div>
                                                  <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                                    onMultipleDragDrop={async (e) => {
                                                      let tempErr = errors
                                                      let tempData = data
                                                      for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                                                        let element = e.target.files[indexx];
                                                        let customEvent = {
                                                          target: { name: "invoiceDocument" + index + ":" + j + ":" + (p + indexx), files: [element] },
                                                          persist: () => console.log("...")
                                                        }
                                                        let resp = await handleFileWithAsyncAwait(customEvent, j, p + indexx, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                                        if (resp.status / 1 == 0) {
                                                          tempErr = { ...tempErr, ...resp.msg }
                                                        }
                                                        if (resp.status / 1 == 1) {
                                                          tempData = { ...tempData, ...resp.msg }
                                                        }
                                                      }
                                                      setData(tempData)
                                                      setErrors(tempErr)
                                                      let temp = multInvPo
                                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                                        temp[j].invoices.push(null)
                                                      }
                                                      setMultInvPo([...temp])
                                                    }}
                                                    name={`invoiceDocument${index}:${j}:${p}`} value={data[`invoiceDocument${index}:${j}:${p}`]} error={errors[`invoiceDocument${index}:${j}:${p}`]}
                                                    isEditable={true} onChange={handleFile}
                                                    onUploadCancel={() => setData({ ...data, [`invoiceDocument${index}:${j}:${p}`]: null })}
                                                  />
                                                  {errors[`invoiceDocument${index}:${j}:${p}`] ? <div class="text-danger mt-2 font-size-12">
                                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                    <b>{errors[`invoiceDocument${index}:${j}:${p}`]}</b></div> : ''}
                                                </div></td>
                                                <td><div className="position-relative">
                                                  <InputForTable isAstrix={false} type={"text"} label={""}
                                                    name={`invoiceRefrenceNo${index}:${j}:${p}`} value={data[`invoiceRefrenceNo${index}:${j}:${p}`]} error={errors[`invoiceRefrenceNo${index}:${j}:${p}`]}
                                                    onChange={handleChange} />
                                                </div></td>
                                                <td><div className="position-relative">
                                                  <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                                    selectData={currencyData}
                                                    selectName={`currency${index}:${j}:${p}`} selectValue={data[`currency${index}:${j}:${p}`]}
                                                    optionLabel={"code"} optionValue={'code'}
                                                    name={`contractAmount${index}:${j}:${p}`} value={data[`contractAmount${index}:${j}:${p}`]} error={errors[`contractAmount${index}:${j}:${p}`] || errors[`currency${index}:${j}:${p}`]}
                                                    onChange={handleChange} />
                                                </div></td>
                                                <td>
                                                  {!data[`invoiceDocument${index}:${j}:${p}`]?.signatureId ?
                                                    <img className="cursor"
                                                      onClick={() => { setSigndoc(data[`invoiceDocument${index}:${j}:${p}`]?.name); setData({ ...data, docToSign: data[`invoiceDocument${index}:${j}:${p}`], docSignName: `invoiceDocument${index}:${j}:${p}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                                    :
                                                    <img style={{ width: '20px' }} className=""
                                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                                  }
                                                </td>
                                                <td><div
                                                  className="d-flex"
                                                >
                                                  {data[`invoiceDocument${index}:${j}:${p}`]?.name ? (
                                                    <>
                                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invoiceDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invoiceDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                    </>
                                                  ) : null}
                                                  {(i.invoices.length - 1 > p) ?
                                                    <img
                                                      onClick={() => {
                                                        let temp = multInvPo
                                                        let tempUpdatedPo = []
                                                        for (let m = 0; m < temp[j].invoices.length; m++) {
                                                          if (m != p) {
                                                            tempUpdatedPo.push(null)
                                                          }
                                                        }
                                                        temp[j].invoices = tempUpdatedPo
                                                        setMultInvPo([...temp])
                                                      }}
                                                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                                    /> : <img
                                                      onClick={() => {
                                                        let temp = multInvPo
                                                        temp[j].invoices.push(null)
                                                        setMultInvPo([...temp])
                                                      }}
                                                      style={{ width: '22px', height: '22px' }}
                                                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                                </div></td>
                                              </tr>
                                            )
                                          })}
                                          {
                                            i.purchase_orders?.map((o, p) => {
                                              return (
                                                <tr>
                                                  <td>{"Purchase Order"}</td>
                                                  <td>
                                                    <div>
                                                      <FileInput hideViewDowdBtns={true} multipleDragDropEnable={true}
                                                        onMultipleDragDrop={async (e) => {
                                                          let tempErr = errors
                                                          let tempData = data
                                                          for (let indexx = 0; indexx < e.target.files.length; indexx++) {
                                                            let element = e.target.files[indexx];
                                                            let customEvent = {
                                                              target: { name: "poDocument" + index + ":" + j + ":" + (p + indexx), files: [element] },
                                                              persist: () => console.log("...")
                                                            }
                                                            let resp = await handleFileWithAsyncAwait(customEvent, j, p + index, tempData, (multInvPo.length + (e.target.files.length - 1)))
                                                            if (resp.status / 1 == 0) {
                                                              tempErr = { ...tempErr, ...resp.msg }
                                                            }
                                                            if (resp.status / 1 == 1) {
                                                              tempData = { ...tempData, ...resp.msg }
                                                            }
                                                            // console.log("customEventtttttttttttttttt", customEvent, resp);
                                                          }
                                                          setData(tempData)
                                                          setErrors(tempErr)
                                                          let temp = multInvPo
                                                          for (let index = 0; index < e.target.files.length - 1; index++) {
                                                            temp[j].purchase_orders.push(null)
                                                          }
                                                          setMultInvPo([...temp])
                                                        }}
                                                        name={`poDocument${index}:${j}:${p}`} value={data[`poDocument${index}:${j}:${p}`]} error={errors[`poDocument${index}:${j}:${p}`]}
                                                        isEditable={true}
                                                        onUploadCancel={() => setData({ ...data, [`poDocument${index}:${j}:${p}`]: null })}
                                                      />
                                                      {errors[`poDocument${index}:${j}:${p}`] ? <div class="text-danger mt-2 font-size-12">
                                                        <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                        <b>{errors[`poDocument${index}:${j}:${p}`]}</b></div> : ''}
                                                    </div>
                                                  </td>
                                                  <td><div className="position-relative">
                                                    <InputForTable isAstrix={false} type={"text"} label={""}
                                                      name={`poRefrenceNo${index}:${j}:${p}`} value={data[`poRefrenceNo${index}:${j}:${p}`]} error={errors[`poRefrenceNo${index}:${j}:${p}`]}
                                                      onChange={(e) => handleChange(e, j, p)} />
                                                  </div></td>
                                                  <td><div className="position-relative">
                                                    <InputWithSelectForTable isAstrix={false} type={"number"} label={""}
                                                      selectData={currencyData}
                                                      selectName={`currency${index}:${j}:${p}`} selectValue={data[`currency${index}:${j}:${p}`]}
                                                      optionLabel={"code"} optionValue={'code'}
                                                      name={`poAmount${index}:${j}:${p}`} value={data[`poAmount${index}:${j}:${p}`]} error={errors[`poAmount${index}:${j}:${p}`] || errors[`currency${index}:${j}:${p}`]}
                                                      onChange={handleChange} />
                                                  </div></td>
                                                  <td>
                                                    {!data[`poDocument${index}:${j}:${p}`]?.signatureId ?
                                                      <img className="cursor"
                                                        onClick={() => { setSigndoc(data[`poDocument${index}:${j}:${p}`]?.name); setData({ ...data, docToSign: data[`poDocument${index}:${j}:${p}`], docSignName: `poDocument${index}:${j}:${p}` }) }}
                                                        src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                                      :
                                                      <img className=""
                                                        style={{ width: '20px' }}
                                                        src={"assets/images/doc_sign_filled.svg"} alt="" />
                                                    }
                                                  </td>
                                                  <td><div
                                                    className="d-flex"
                                                  >
                                                    {data[`poDocument${index}:${j}:${p}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`poDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`poDocument${index}:${j}:${p}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                    {i.purchase_orders.length - 1 > p ?
                                                      <img
                                                        onClick={() => {
                                                          let temp = multInvPo
                                                          let tempUpdatedPo = []
                                                          for (let m = 0; m < temp[j].purchase_orders.length; m++) {
                                                            if (m != p) {
                                                              tempUpdatedPo.push(null)
                                                            }
                                                          }
                                                          temp[j].purchase_orders = tempUpdatedPo
                                                          setMultInvPo([...temp])
                                                        }}
                                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                                      /> :
                                                      <img
                                                        onClick={() => {
                                                          let temp = multInvPo
                                                          temp[j].purchase_orders.push(null)
                                                          setMultInvPo([...temp])
                                                        }}
                                                        style={{ width: '22px', height: '22px' }}
                                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />
                                                    }
                                                  </div></td>
                                                </tr>
                                              )
                                            })}
                                        </>
                                      })}

                                    </NewTablev2>
                                  </div>
                                  <div
                                    className={""}
                                  >
                                    <label
                                      className="font-size-16 text-color-0C0C0C font-wt-500 my-3"
                                    ><u>{`Other Documents`}</u></label>
                                    <NewTablev2 columns={documentTable2}>
                                      <tr>
                                        <td>Investigation Report</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`invReportDocument${index}`} value={data[`invReportDocument${index}`]} error={errors[`invReportDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`invReportDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>
                                          {!data[`invReportDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`invReportDocument${index}`]?.name); setData({ ...data, docToSign: data[`invReportDocument${index}`], docSignName: `invReportDocument${index}` }) }}
                                              src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`invReportDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Bill of Lading / Airway Bill</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`logisticDocument${index}`} value={data[`logisticDocument${index}`]} error={errors[`logisticDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`logisticDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>
                                          {!data[`logisticDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`logisticDocument${index}`]?.name); setData({ ...data, docToSign: data[`logisticDocument${index}`], docSignName: `logisticDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`logisticDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Certificate of Origin</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`grnDocument${index}`} value={data[`grnDocument${index}`]} error={errors[`grnDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`grnDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>
                                          {!data[`grnDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`grnDocument${index}`]?.name); setData({ ...data, docToSign: data[`grnDocument${index}`], docSignName: `grnDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`grnDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td><div>
                                          <label>Sales Contract</label>
                                          <div className="align-self-center">
                                            <img
                                              onClick={() => {
                                                setData({ ...data, [`salesContractNotAvailable${index}`]: !data[`salesContractNotAvailable${index}`] })
                                              }}
                                              className='cursor mr-3' src={`assets/images/${data[`salesContractNotAvailable${index}`] ? 'checked-green' : 'empty-check'}.png`} />
                                            <label>Not Available</label>
                                          </div>
                                        </div></td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`salesContractDocument${index}`} value={data[`salesContractDocument${index}`]} error={errors[`salesContractDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`salesContractDocument${index}`]: null })}
                                          />
                                          {errors[`salesContractDocument${index}`] ? <div class="text-danger mt-2 font-size-12">
                                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            <b>{errors[`salesContractDocument${index}`]}</b></div> : ''}
                                        </td>
                                        <td>
                                          {!data[`salesContractDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`salesContractDocument${index}`]?.name); setData({ ...data, docToSign: data[`salesContractDocument${index}`], docSignName: `salesContractDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`salesContractDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Shipping Bill</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`shippingBillDocument${index}`} value={data[`shippingBillDocument${index}`]} error={errors[`shippingBillDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`shippingBillDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>
                                          {!data[`shippingBillDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`shippingBillDocument${index}`]?.name); setData({ ...data, docToSign: data[`shippingBillDocument${index}`], docSignName: `shippingBillDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`shippingBillDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Packing List</td>
                                        <td>
                                          <FileInput hideViewDowdBtns={true} name={`packingListDocument${index}`} value={data[`packingListDocument${index}`]} error={errors[`packingListDocument${index}`]}
                                            onChange={handleFile} isEditable={true}
                                            onUploadCancel={() => setData({ ...data, [`packingListDocument${index}`]: null })}
                                          />
                                        </td>
                                        <td>
                                          {!data[`packingListDocument${index}`]?.signatureId ?
                                            <img className="cursor"
                                              onClick={() => { setSigndoc(data[`packingListDocument${index}`]?.name); setData({ ...data, docToSign: data[`packingListDocument${index}`], docSignName: `packingListDocument${index}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                            :
                                            <img style={{ width: '20px' }} className=""
                                              src={"assets/images/doc_sign_filled.svg"} alt="" />
                                          }
                                        </td>
                                        <td><div
                                          className="d-flex"
                                        >
                                          {data[`packingListDocument${index}`]?.name ? (
                                            <>
                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                            </>
                                          ) : null}
                                        </div>
                                        </td>
                                      </tr>
                                    </NewTablev2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>


                  </div>
                ) : null}
                {tab === 2 ? (
                  <div className='ulFormsContainer'>

                    <div className="accordionWrapper p-0 m-0">
                      {buyerRows.map((i, j) => {
                        if (i === null) {
                          let finListWithJ = finList?.[j] || []
                          return (
                            <div className="col-md-12 row p-0 m-0">
                              <div
                                style={errors[`accordionTab2${j}`] ? { border: '2px solid red' } : { border: '2px solid #4CA0B9 ' }}
                                className={"accordionItem col-md-12" + (accordion2 === (j + 1) ? " open" : " closed")}>
                                <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion2(accordion2 === j + 1 ? null : (j + 1)) }}>
                                  <label className="font-size-14 font-wt-600 col-auto pt-2" >{data[`buyerName${j}`]}</label>
                                </div>
                                <div className="accordionItemContent">
                                  <label className='font-wt-500 font-size-16'>Select financer</label>
                                  <div style={{ marginBottom: '2.5rem' }} className='row justify-content-between '>
                                    {
                                      finListWithJ.map((item, index) => {
                                        return <div style={{ width: '22%', marginTop: '2.2rem' }} className={item.isAvailable ? ` ${item.isRecommened ? ' financierCardNewRecommended ' : ' financierCardNew '}  ` : '  financierCardNewDisabled '}>
                                          {item.isRecommened ? <img src="assets/images/recommended_ribbon.svg" className={'recommendRibbon2'} /> : null}
                                          <div className='d-flex w-100 gap-4 align-items-center justify-content-between'>
                                            <div className='d-flex gap-4 align-items-center'>
                                              <img style={{ maxWidth: '120px', maxHeight: '40px' }} src={avatarUrl(item.logo)} />
                                            </div>
                                            <div className="" >
                                              {!item.isAvailable ?
                                                <img src="/assets/images/info-circle-outlined_remark.png"
                                                  className={'cursor mr-3'} alt="" height={20} width={20} onClick={() => {
                                                    setTooltip(true)
                                                    setTooltipdata({
                                                      lenderName: item.name,
                                                      remarks: item.info_text
                                                    })
                                                  }} /> :
                                                <img
                                                  onClick={() => {
                                                    if (item.isAvailable || environment != "prod") {
                                                      handleFinSelection(j, index)
                                                    } else {
                                                      toastDisplay("Action not availble", "info")
                                                    }
                                                  }}
                                                  className={'cursor mr-3'} src={`assets/images/${finListWithJ?.[index]["isChecked"] ? 'checked_vector' : 'unchecked_vector'}.svg`} height={21} width={21} />
                                              }
                                            </div>
                                          </div>
                                          <div className="d-flex px-2 pt-3 w-100">
                                            <div className="col-6 px-0 cursor"><p className="mb-0 font-size-14 font-wt-500">{item.avgRatings.toFixed(2)}</p>
                                              <span>
                                                <img className={`${item.avgRatings < 0.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 1.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 2.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 3.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                                <img className={`${item.avgRatings < 4.5 ? ' d-none ' : ''}`} src={"assets/images/supplier-images/Rating.png"} alt="" />
                                              </span>
                                            </div>
                                            <div
                                              onClick={async () => {
                                                // Get getUserTradeInfoWithFinancier
                                                let temp = item
                                                temp["show"] = true
                                                toggleReviewScrollIndex(0)
                                                setshowLoader(true)
                                                temp = { ...temp, ...await call('POST', 'getUserTradeInfoWithFinancier', { lenderId: item.id, sellerId: userId }) }
                                                setshowLoader(false)
                                                setSeeReview(temp)
                                              }}
                                              className="col-6 px-0 cursor"><p className="mb-1 font-size-14 font-wt-500">{item.reviewsCount}</p><p className="font10 font-size-14 font-wt-500">See reviews</p></div>
                                          </div>
                                          <p
                                            onClick={() => {
                                              setSelectedChat({
                                                receiverName: item.name,
                                                receiverId: item.id,
                                                userId: userId,
                                                isChatOpen: true
                                              })
                                            }}
                                            className="cursor"
                                          ><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2 font-wt-500">Chat with financer</span></p>
                                        </div>
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>)
                        }
                      })}
                    </div>
                  </div>
                ) : null}
                {tab === 3 ? (
                  <div className='ulFormsContainer'>
                    <div className="accordionWrapper p-0 m-0">
                      {buyerRows.map((i, index) => {
                        if (i === null) {
                          return (
                            <div className="col-md-12 row p-0 m-0">
                              <div
                                style={{ border: '2px solid #4CA0B9 ' }}
                                className={"accordionItem col-md-12" + (accordion3 === (index + 1) ? " open" : " closed")}>
                                <div className={"accordionItemHeading d-flex row align-items-center "} onClick={() => { setAccordion3(accordion3 === index + 1 ? null : (index + 1)) }}>
                                  <label className="font-size-16 font-wt-600 col-auto pt-2" >{data[`buyerName${index}`]}</label>
                                </div>
                                <div className="accordionItemContent">
                                  <div className="row">
                                    <div className='w-100'>
                                      <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                                        <img
                                          onClick={() => {
                                            window.open(`/buyerManagement?editBuyer=${data[`selectedBuyer${index}`]}`, "_blank");
                                            localStorage.setItem('afterUpdateCloseTab', true)
                                          }}
                                          className="ml-2 cursor" src="assets/images/edit.png" />
                                      </label>
                                      <div className='row'>
                                        {reviewFormOld.map((item) => {
                                          return (
                                            <div className="col-md-6 pe-5">
                                              <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${index}`] ? (item.unit ? `${data[`${item.unit}${index}`]} ` : "") + (data[`${item.val}${index}`]) : "NA"}</span> </p>
                                            </div>
                                          );
                                        })}
                                        <div className="col-md-6 pe-5">
                                          <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Limit Required"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                            {data[`limitRequired${index}`] + " " + data[`limitRequiredCurrency${index}`]}</span> </p>
                                        </div>
                                        <div className="col-md-6 pe-5">
                                          <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{"Expected Date of Shipment"}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" >
                                            {data[`expShipmentDate${index}`]}</span> </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='w-100 mt-4'>
                                      <label className='font-wt-500 font-size-16'><u>Document</u></label>
                                      <div className='row mt-2'>

                                        <div className="">
                                          <NewTablev2 columns={documentTable3}>
                                            {multInvPo.map((i, j) => {
                                              return <>
                                                {i.invoices?.map((o, p) => {
                                                  if (data["invoiceDocument" + index + ":" + j + ":" + p]?.name) {
                                                    return (<tr>
                                                      <td>{`Invoice - ${data["invoiceRefrenceNo" + index + ":" + j + ":" + p] || ""}`}</td>
                                                      <td>{`${data["contractAmount" + index + ":" + j + ":" + p] || ""} ${data["currency" + index + ":" + j + ":" + p] || ""}`}</td>
                                                      <td>
                                                        <FileInput value={data["invoiceDocument" + index + ":" + j + ":" + p]} isEditable={false}
                                                        />
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="d-flex"
                                                        >
                                                          {data["invoiceDocument" + index + ":" + j + ":" + p]?.name ? (
                                                            <>
                                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data["invoiceDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data["invoiceDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    )
                                                  }
                                                })}
                                                {i.purchase_orders?.map((o, p) => {
                                                  if (data["poDocument" + index + ":" + j + ":" + p]?.name) {
                                                    return (<tr>
                                                      <td>{`Purchase Order - ${data["poRefrenceNo" + index + ":" + j + ":" + p] || ""}`}</td>
                                                      <td>{`${data["poAmount" + index + ":" + j + ":" + p] || ""} ${data["currency" + index + ":" + j + ":" + p] || ""}`}</td>
                                                      <td>
                                                        <FileInput value={data["poDocument" + index + ":" + j + ":" + p]} isEditable={false}
                                                        />
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="d-flex"
                                                        >
                                                          {data["poDocument" + index + ":" + j + ":" + p]?.name ? (
                                                            <>
                                                              <img onClick={() => { toggleDowdDoc({ show: true, doc: data["poDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                              <img onClick={() => { toggleViewDoc({ show: true, doc: data["poDocument" + index + ":" + j + ":" + p] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    )
                                                  }
                                                })}
                                              </>
                                            })}
                                            {data[`invReportDocument${index}`]?.name ?
                                              <tr>
                                                <td>Investigation Report</td>
                                                <td>-</td>
                                                <td>
                                                  <FileInput name={`invReportDocument${index}`} value={data[`invReportDocument${index}`]} error={errors[`invReportDocument${index}`]}
                                                    onChange={handleFile} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data[`invReportDocument${index}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invReportDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr> : null}
                                            {data[`logisticDocument${index}`]?.name ?
                                              <tr>
                                                <td>Bill of Lading / Airway Bill</td>
                                                <td>-</td>
                                                <td>
                                                  <FileInput name={`logisticDocument${index}`} value={data[`logisticDocument${index}`]} error={errors[`logisticDocument${index}`]}
                                                    onChange={handleFile} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data[`logisticDocument${index}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`logisticDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr> : null}
                                            {data[`grnDocument${index}`]?.name ?
                                              <tr>
                                                <td>Certificate of Origin</td>
                                                <td>-</td>
                                                <td>
                                                  <FileInput name={`grnDocument${index}`} value={data[`grnDocument${index}`]} error={errors[`grnDocument${index}`]}
                                                    onChange={handleFile} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data[`grnDocument${index}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`grnDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr> : null}
                                            {data[`salesContractDocument${index}`]?.name ?
                                              <tr>
                                                <td>Sales Contract</td>
                                                <td>-</td>
                                                <td>
                                                  <FileInput name={`salesContractDocument${index}`} value={data[`salesContractDocument${index}`]} error={errors[`salesContractDocument${index}`]}
                                                    onChange={handleFile} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data[`salesContractDocument${index}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`salesContractDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr> : null}
                                            {data[`shippingBillDocument${index}`]?.name ?
                                              <tr>
                                                <td>Shipping Bill</td>
                                                <td>-</td>
                                                <td>
                                                  <FileInput name={`shippingBillDocument${index}`} value={data[`shippingBillDocument${index}`]} error={errors[`shippingBillDocument${index}`]}
                                                    onChange={handleFile} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data[`shippingBillDocument${index}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`shippingBillDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr> : null}
                                            {data[`packingListDocument${index}`]?.name ?
                                              <tr>
                                                <td>Packing List</td>
                                                <td>-</td>
                                                <td>
                                                  <FileInput name={`packingListDocument${index}`} value={data[`packingListDocument${index}`]} error={errors[`packingListDocument${index}`]}
                                                    onChange={handleFile} isEditable={false}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex"
                                                  >
                                                    {data[`packingListDocument${index}`]?.name ? (
                                                      <>
                                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data[`packingListDocument${index}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              </tr> : null}
                                          </NewTablev2>
                                        </div>

                                        <div className='w-100 mt-4'>
                                          <label className='font-wt-500 font-size-16'><u>Financer selected</u></label>
                                          <div className="d-flex gap-4 my-3">
                                            {finList[index].map((key) => {
                                              if (key.isChecked) {
                                                return (
                                                  <label key={key.name}
                                                    className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                                                    {key.name}
                                                  </label>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                    <div className='mt-3'>
                      <img
                        onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                        className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                      <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                    </div>
                  </div>
                ) : null}

                <div
                  className="d-flex finActionTabContainer mx-4 my-4 " >
                  {tab / 1 != 3 ? <div
                    onClick={() => {
                      handleValidation(undefined)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100 "} >Next</label>
                  </div> : <div
                    onClick={() => {
                      setFinancierActionTab(0)
                      handleValidation(undefined)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-0 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor d-flex justify-content-center align-items-center h-100"} >Submit</label>
                  </div>}
                  <div
                    onClick={() => {
                      if (tab > 0) {
                        setTab(tab - 1)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor ${tab === 0 ? ' disable-bg ' : " "}`} >
                    <label className={`font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 `} >Previous</label>
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-0">
                    <label
                      onClick={() => { toastDisplay('Application saved as draft', "success"); setRefresh(refresh + 1) }}
                      className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100 "} >Save as Draft</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                    }}
                    className={`position-relative w-25 text-center py-0 px-2 cursor`} >
                    {finActionTab == 2 && draftData.length ? <div
                      style={{
                        width: '14rem',
                        top: `${draftData.length * -2.5}rem`,
                        borderRadius: "10px"
                      }}
                      className="position-absolute " >
                      {draftData.map((i, j) => {
                        return (
                          <div
                            onClick={() => { window.location = `/applyLimitV2?formContinue=${i.id}` }}
                            style={{ height: '2.5rem' }} className="d-flex bg-white border-bottom-solid-dee2e6 align-items-center" >
                            <label
                              className="font-size-14 font-wt-500 col-8 cursor truncate" >{i.details?.data?.buyerName0 || "NA"}</label>
                            <div className="col-4 d-flex align-items-center" >
                              <label
                                style={{
                                  background: "#5CB8D3",
                                  color: "#fff",
                                  borderRadius: "100%"
                                }}
                                className="ml-3 px-1 py-2 m-0" >{i.details.percentageComplete + " %"}</label>
                            </div>
                          </div>
                        )
                      })}
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor d-flex justify-content-center align-items-center h-100"} >Draft
                      <span
                        style={{
                          background: "#5CB8D3",
                          color: "#fff",
                          borderRadius: "100%"
                        }}
                        className="ml-3 px-2 py-1" >{draftData.length}</span>
                    </label>
                  </div>
                </div>

                {/* <div className="px-5 pb-5">
                  <button type="button"
                    onClick={() => handleValidation(undefined)}
                    className={` new-btn w-17 py-2 px-2  text-white`}>
                    {tab == 3 ? "Submit" : "Next"}
                  </button>
                </div> */}

              </div>
            </div>
          </main>
        </div>
      </div >
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ApplyLimitComponentV2)