import React, { useEffect, useState } from 'react';
import ReactCountryFlag from "react-country-flag";
import call from '../../service';
import FilePreview from '../utilComponents/previewFile';
import toastDisplay from '../../utils/toastNotification';
import ShareHolderDetailsGrid from './shareholderDetailGrid';
import validate from '../../utils/validation_files/shareholderDetailsComp';

const docsFormArray = [
  { "key": "PANCard", "name": "PAN Document", "dbId": ":2:1" },
  { "key": "AadhaarCard", "name": "Aadhaar Document", "dbId": ":2:1" }
]

const ShareHolderDetails = ({ userTokenDetails }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [modalStatus, setmodalStatus] = useState(true);
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [countryData, setcountryData] = useState([]);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [fileData, setfileData] = useState({});
  const [values, setValues] = useState({});

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  const handleFile = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.files[0] }));
    setError(error => ({ ...error, [event.target.name]: "" }))
  }

  const handleChange = (event) => {
    event.persist();
    setValues({ ...values, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(values));
    setIsSubmitting(true);
  };

  function handleSubmit(type) {
    setshowLoader(true)

    let formData = new FormData();
    values.userId = userId
    values.userEmail = userEmail
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    call('POST', 'insertshareholder', formData).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setTimeout(() => {
        window.location.reload()
      }, 1500);
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {modalStatus ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      ShareHolder Details
                      <button type="button" onClick={() => setmodalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-times fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">

                      <div className="form-group col-md-6">
                        <label>Name {astrix} </label>
                        <input type="text" className={" form-control" + (!error.name ? '' : ' border-danger')} placeholder="" name="name" value={values.name} onChange={handleChange} />
                        {error.name && <p className="text-danger error-contract">{error.name}</p>}
                      </div>

                      <div className="form-group col-md-6" id="POScountry">
                        <div className="flag-icon">
                          <ReactCountryFlag
                            style={{ width: '25px', height: '25px' }} svg />
                        </div>
                        <label>Country {astrix}  </label>
                        <select className={" form-control" + (!error.country ? '' : ' border-danger')} name="country" value={values.country} onChange={handleChange} >
                          <option value="" selected>Select Country</option>
                          {countryData.map((country) => {
                            return (
                              <option value={country.sortname} >{country.name}</option>
                            )
                          })}
                        </select>
                        {error.country}
                        {error.country && <p className="text-danger error-contract">{error.country}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Nationality {astrix} </label>
                        <input type="text" className={" form-control" + (!error.nationality ? '' : ' border-danger')} placeholder="" name="nationality" value={values.nationality} onChange={handleChange} />
                        {error.nationality && <p className="text-danger error-contract">{error.nationality}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Email ID {astrix} </label>
                        <input type="text" className={" form-control" + (!error.emailId ? '' : ' border-danger')} placeholder="" name="emailId" value={values.emailId} onChange={handleChange} />
                        {error.emailId && <p className="text-danger error-contract">{error.emailId}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Share in Company(%)</label>
                        <input type="number" className={" form-control" + (!error.sharePercent ? '' : ' border-danger')} placeholder="" name="sharePercent" value={values.sharePercent} onChange={handleChange} />
                        {error.sharePercent && <p className="text-danger error-contract">{error.sharePercent}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>ITR (For India Only)</label>
                        <input type="text" className={" form-control" + (!error.itr ? '' : ' border-danger')} placeholder="" name="itr" value={values.itr} onChange={handleChange} />
                        {error.itr && <p className="text-danger error-contract">{error.itr}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Director Identification Number</label>
                        <input type="text" className={" form-control" + (!error.din ? '' : ' border-danger')} placeholder="" name="din" value={values.din} onChange={handleChange} />
                        {error.din && <p className="text-danger error-contract">{error.din}</p>}
                      </div>

                      <div className="col-md-6">
                        <div className="row form-group">
                          <label className="col-md-12">Authorized Signatory</label>
                          <div className="col-md-12">
                            <select className="form-control" name="signatory" value={values.signatory} onChange={handleChange} >
                              <option value="" selected>Select any one</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {docsFormArray.map((item) => {
                          return (<>
                            <div className="form-group pb-0 mb-0" id={item.key}>
                              <ul className="other-documents pb-0 mb-0">
                                <li style={{ "min-width": "300px" }}>
                                  <label className="mb-0" >
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                    {item.name} {astrix}
                                  </label>
                                </li>
                                <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                  <div className="file-browse">
                                    <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                    <input type="file" accept=".png,.jpg,.pdf" id="file_1" name={item.key} onChange={handleFile} />
                                    {error[item.key] && <p className="error-contract p-0 m-0">{error[item.key]}</p>}
                                  </div>
                                </li>
                                <li style={{ "min-width": "500px" }}>
                                  {(values[item.key]) ? <div className="filePath text-left"><div className="file-name">{`${values[item.key].name}`}</div></div> : ""}
                                </li>
                              </ul>
                              <hr className="" />
                            </div>
                          </>)
                        })}
                      </div>

                      <div className="form-group col-md-12">
                        <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={() => preHandleSubmit()}>Submit</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              ShareHolder Details
              <button type="button" onClick={() => setmodalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>}

      {/* { docModalStatus ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      ShareHolder Documents
                      <button type="button" onClick={() => setdocModalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-times fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">

                    {docData.length > 0 ? <>
                      <div className="col-md-12">
                        <div className="table-responsive" style={{ "min-height": "150px" }}>
                          <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                            <thead>
                              <tr>
                                <th width="20">#</th>
                                <th>Type of Document</th>
                                <th width="300">Document Name</th>
                                <th width="200">Uploaded By</th>
                                <th width="200">Uploaded On</th>
                                <th className="text-center" width="150">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {docGridCreator(docData)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </> :
                      <>
                        {docsFormArray.map((item) => {
                          return (<>
                            <div className="form-group pb-0 mb-0" id={item.key}>
                              <ul className="other-documents pb-0 mb-0">
                                <li style={{ "min-width": "300px" }}>
                                  <label className="mb-0" >
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                    {item.name}
                                  </label>
                                </li>
                                <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                  <div className="file-browse">
                                    <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                    <input type="file" accept=".png,.jpg,.pdf" id="file_1" name={item.key} onChange={handleFile} />
                                    {error[item.key] && <p className="error-contract p-0 m-0">{error[item.key]}</p>}
                                  </div>
                                </li>
                                <li style={{ "min-width": "500px" }}>
                                  {(values[item.key]) ? <div className="filePath text-left"><div className="file-name">{`${values[item.key].name}`}</div></div> : ""}
                                </li>
                              </ul>
                              <hr className="" />
                            </div>
                          </>)
                        })}

                        <div className="form-group col-md-12">
                          <button type="button" className="btn-primary btn mt-3 text-center btn-sm" >Submit</button>
                        </div>
                      </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              Shareholder Documents
              <button type="button" onClick={() => setdocModalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>
      } */}

      {showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}

      <ShareHolderDetailsGrid userTokenDetails={userTokenDetails} refresh={refresh} setrefresh={setrefresh} />
    </>)
}



export default ShareHolderDetails
