import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import Footer from '../partial/footer';
import FilePreview from '../utilComponents/previewFile';
import DigitalSignDoc from '../contractDocument/digitalSignDoc';
import { formatDate_Application } from '../../utils/dateFormaters';

const CreditDocuments = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail, userDetail, userPendingDetail, CreditDocuments }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [userBankDetail, setUserBankDetail] = useState(false);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //UseEffects

  useEffect(() => {
    let objectAPI = {
      "userId": manualUserId,
      "userName": manualUserName,
      "userEmail": manualUserEmail,
      "gridType": "bankType"
    }

    objectAPI = { ...objectAPI }

    call("POST", "creditBankDocument", objectAPI).then(async (result) => {
      setUserBankDetail(result);
    }).catch((e) => {
      console.log("error in creditBankDocument", e.message);
    })
  }, []);


  //---------------------------------------------------------------------------------------------------------------------
  // functions

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }


  //---------------------------------------------------------------------------------------------------------------------
  //Documents Signature

  function docGridCreator(userid) {
    if (CreditDocuments.info.action.hasOwnProperty('tabType') && CreditDocuments.info.action.tabType == 'pendingTabType') {
      for (let i = 0; i < userPendingDetail.length; i++) {
        let usersCredit = JSON.parse(userPendingDetail[i].userDetail);
        if (userPendingDetail[i].id == userid) {
          return (<>
            {
              usersCredit.map((arrayElement, index) => {
                arrayElement.tbl_doc_id = arrayElement.doc_id;
                arrayElement.document_name = 'Credit Line Document';
                arrayElement.doc_name = arrayElement.doc_name;
                arrayElement.uploaded_by = arrayElement.uploaded_by ? arrayElement.uploaded_by : manualUserName;
                arrayElement.uploaded_on = userPendingDetail[i].created_at;
                arrayElement.signType = 'withoutBlockChain'

                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>Credit Line Document</td>
                    <td>{(arrayElement && arrayElement.doc_name) ? arrayElement.doc_name : 'NA'}</td>
                    <td>{arrayElement.uploaded_by ? arrayElement.uploaded_by : manualUserName}</td>
                    <td>{formatDate_Application(userPendingDetail[i].created_at)}</td>
                    <td>{(arrayElement && arrayElement.docSignArray) ? <pre><span className="text-success mr-1">
                      {arrayElement.docSignArray.map((signV) => {
                        return (
                          <>
                            {signV.doc_id == arrayElement.doc_id &&
                              <div><i className="fa fa-certificate" /> {signV.sign}<br /></div>}
                          </>
                        )
                      })}
                    </span></pre> : ''}</td>
                    <td className="row justify-content-center m-0"><>
                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(arrayElement.doc_id, "view")}>
                        <i class="fas fa-eye"></i>
                      </button>
                      <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(arrayElement.doc_id, "download")}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                      {/* <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                        () => callAclAuthComponent(arrayElement)}>
                        <i class="fas fa-file-signature"></i>
                      </button> */}
                    </></td>
                  </tr>
                )
              })
            }
          </>
          )
        }
      }
    }
    else if (CreditDocuments.info.hasOwnProperty('gridType') && CreditDocuments.info.gridType == 'bankType') {
      for (let i = 0; i < userBankDetail.length; i++) {
        let usersCredit = JSON.parse(userBankDetail[i].userDetail);
        if (userBankDetail[i].id == userid) {
          return (<>
            {
              usersCredit.map((arrayElement, index) => {
                arrayElement.tbl_doc_id = arrayElement.doc_id;
                arrayElement.document_name = 'Credit Line Document';
                arrayElement.doc_name = arrayElement.doc_name;
                arrayElement.uploaded_by = manualUserName;
                arrayElement.uploaded_on = userBankDetail[i].created_at;
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>Credit Line Document</td>
                    <td>{(arrayElement && arrayElement.doc_name) ? arrayElement.doc_name : 'NA'}</td>
                    <td>{userName ? userName : 'NA'}</td>
                    <td>{formatDate_Application(userBankDetail[i].created_at)}</td>
                    <td>{(userBankDetail[i] && userBankDetail[i].docSignArray) ? <pre><span className="text-success mr-1">
                      {userBankDetail[i].docSignArray.map((signV) => {
                        return (
                          <>
                            {signV.doc_id == arrayElement.doc_id &&
                              <div><i className="fa fa-certificate" /> {signV.sign}<br /></div>}
                          </>
                        )
                      })}
                    </span></pre> : ''}</td>
                    <td className="row justify-content-center m-0"><>
                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(arrayElement.doc_id, "view")}>
                        <i class="fas fa-eye"></i>
                      </button>
                      <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(arrayElement.doc_id, "download")}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                      <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                        () => callAclAuthComponent(arrayElement)}>
                        <i class="fas fa-file-signature"></i>
                      </button>
                    </></td>
                  </tr>
                )
              })
            }
          </>
          )
        }
      }
    }
    else {
      for (let i = 0; i < userDetail.length; i++) {
        let usersCredit = JSON.parse(userDetail[i].doc_id);
        if (userDetail[i].id == userid) {
          return (<>
            {
              usersCredit.map((arrayElement, index) => {
                arrayElement.tbl_doc_id = arrayElement.doc_id;
                arrayElement.document_name = 'Credit Line Document';
                arrayElement.doc_name = arrayElement.doc_name;
                arrayElement.uploaded_by = manualUserName;
                arrayElement.uploaded_on = userDetail[i].created_at;
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>Credit Line Document</td>
                    <td>{(arrayElement && arrayElement.doc_name) ? arrayElement.doc_name : 'NA'}</td>
                    <td>{userName ? userName : 'NA'}</td>
                    <td>{formatDate_Application(userDetail[i].created_at)}</td>
                    <td>{(userDetail[i] && userDetail[i].docSignArray) ? <pre><span className="text-success mr-1">
                      {userDetail[i].docSignArray.map((signV) => {
                        return (
                          <>
                            {signV.doc_id == arrayElement.doc_id &&
                              <div><i className="fa fa-certificate" /> {signV.sign}<br /></div>}
                          </>
                        )
                      })}
                    </span></pre> : ''}</td>
                    <td className="row justify-content-center m-0"><>
                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(arrayElement.doc_id, "view")}>
                        <i class="fas fa-eye"></i>
                      </button>
                      <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(arrayElement.doc_id, "download")}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                      <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                        () => callAclAuthComponent(arrayElement)}>
                        <i class="fas fa-file-signature"></i>
                      </button>
                    </></td>
                  </tr>
                )
              })
            }
          </>
          )
        }
      }
    }
  }


  //---------------------------------------------------------------------------------------------------------------------
  // Mini Preview



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 pt-2 pb-5 mt-5">
        <br />
        <div className="accordionItemContent">
          <div className="table-responsive" style={{ "min-height": "150px" }}>
            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th width="20">#</th>
                  <th width="300">Type of Document</th>
                  <th width="300">Document Name</th>
                  <th width="300">Uploaded By</th>
                  <th width="150">Uploaded On</th>
                  <th width="150">Signed By</th>
                  <th className="text-center" width="300">Actions</th>
                </tr>
              </thead>
              <tbody>
                {docGridCreator(CreditDocuments.info.action.userid)}
              </tbody>
            </table>
          </div>
          <hr />
        </div>


      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

      {
        showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
          viewTrail={false}
        />
      }

      {showAclAuthModal &&
        <DigitalSignDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refresh}
          setrefreshDoc={setrefresh} />}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditDocuments)