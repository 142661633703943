import React, { useState, useEffect } from "react";
import { NewTable } from "../../../../utils/newTable";
import FinanceInvoiceModal from "../../contract/components/financeinvoiceModal";
import call from "../../../../service";

export const InvoicePOComparisonModal = ({ invNo, buyerId, invPoComparisonData, setInvPoComparisonData, showInvPoAnalysis,
  setShowInvPoAnalysis }) => {

  const [matchedItemDetails, setmatchedItemDetails] = useState([])
  const [mismatchedItemDetails, setmismatchedItemDetails] = useState([])
  const [buyerComparisonTableData, setBuyerComparisonTableData] = useState([])

  async function fetchInvoiceAndPoComparisonData() {
    setInvPoComparisonData(await call('POST', 'analyzeInvoicePoOcr', { invNo, buyerId }))
  }

  useEffect(() => {
    fetchInvoiceAndPoComparisonData()
  }, [])

  useEffect(() => {
    if (showInvPoAnalysis.show) {
      formatDataForTable()
    }
  }, [showInvPoAnalysis])

  async function formatDataForTable() {
    let temp = []
    temp.push(["Name", showInvPoAnalysis.data?.buyerDetails?.inv?.name || "-", showInvPoAnalysis.data?.buyerDetails?.po?.name || "-", showInvPoAnalysis.data?.buyerDetails?.dnb?.name || "-",
      showInvPoAnalysis.data?.buyerDetails?.comparisoniInvPo?.name || "-", showInvPoAnalysis.data?.buyerDetails?.comparisoniInvDnb?.name || "-"])
    temp.push(["Address", showInvPoAnalysis.data?.buyerDetails?.inv?.address || "-", showInvPoAnalysis.data?.buyerDetails?.po?.address || "-", showInvPoAnalysis.data?.buyerDetails?.dnb?.address || "-",
      showInvPoAnalysis.data?.buyerDetails?.comparisoniInvPo?.address || "-", showInvPoAnalysis.data?.buyerDetails?.comparisoniInvDnb?.address || "-"])
    setBuyerComparisonTableData(temp)
    temp = []
    for (let index = 0; index < showInvPoAnalysis.data?.itemDetails?.matched?.comparison?.length; index++) {
      const element = showInvPoAnalysis.data?.itemDetails?.matched?.comparison[index]
      let invArr = showInvPoAnalysis.data?.itemDetails?.matched?.inv
      let poArr = showInvPoAnalysis.data?.itemDetails?.matched?.po
      temp.push([index + 1, "Invoice", invArr?.[index]?.product_code || "-", invArr?.[index]?.product_description || "-",
      invArr?.[index]?.product_quantity || "-", invArr?.[index]?.product_per_unit_price || "-", invArr?.[index]?.product_total_amount || "-"])

      temp.push([index + 1, "PO", poArr?.[index]?.product_code || "-", poArr?.[index]?.product_description || "-",
      poArr?.[index]?.product_quantity || "-", poArr?.[index]?.product_per_unit_price || "-", poArr?.[index]?.product_total_amount || "-"])

      temp.push([index + 1, "Match (%)", element?.product_code || "-", element?.product_description || "-",
      element?.product_quantity || "-", element?.product_per_unit_price || "-", element?.product_total_amount || "-"])
    }
    setmatchedItemDetails(temp)
    temp = []
    for (let index = 0; index < showInvPoAnalysis.data?.itemDetails?.mismatched?.comparison?.length; index++) {
      const element = showInvPoAnalysis.data?.itemDetails?.mismatched?.comparison[index]
      let invArr = showInvPoAnalysis.data?.itemDetails?.mismatched?.inv
      let poArr = showInvPoAnalysis.data?.itemDetails?.mismatched?.po
      temp.push([index + 1, "Invoice", invArr?.[index]?.product_code || "-", invArr?.[index]?.product_description || "-",
      invArr?.[index]?.product_quantity || "-", invArr?.[index]?.product_per_unit_price || "-", invArr?.[index]?.product_total_amount || "-"])
      temp.push([index + 1, "PO", poArr?.[index]?.product_code || "-", poArr?.[index]?.product_description || "-",
      poArr?.[index]?.product_quantity || "-", poArr?.[index]?.product_per_unit_price || "-", poArr?.[index]?.product_total_amount || "-"])
      temp.push([index + 1, "Match (%)", element?.product_code || "-", element?.product_description || "-",
      element?.product_quantity || "-", element?.product_per_unit_price || "-", element?.product_total_amount || "-"])
    }
    setmismatchedItemDetails(temp)
  }

  return (
    <>
      {showInvPoAnalysis?.show && <FinanceInvoiceModal modalSize={"lg"} limitinvoice={showInvPoAnalysis?.show} setLimitinvoice={() => {
        setShowInvPoAnalysis({ show: false })
      }} closeSuccess={() => {
        setShowInvPoAnalysis({ show: false })
      }}>
        <p
          className="font-size-16 font-wt-600"
        >Invoice - PO Analysis</p>
        <p
          className="font-size-14 font-wt-600 mt-4"
        >Buyer Details</p>
        <div>
          <NewTable
            data={buyerComparisonTableData}
            columns={[
              { name: "Details", },
              { name: "Invoice", },
              { name: "PO" },
              { name: "DNB" },
              { name: "Invoice-PO Match (%)" },
              { name: "Invoice-DNB Match (%)" }]} disableAction={true} />
        </div>
        <p
          className="font-size-14 font-wt-600 mt-4"
        >Item Details</p>
        <div className="accordion accordion-flush card border-0 borderRadius commonaccordion" id="accordionFlushExample">
          <div
            style={{ overflowX: 'hidden' }}
            className="accordion-item financeacc mb-3 ">
            <h2
              className="accordion-header font-size-14 font-wt-600" id={"Heading0"}>
              <button
                className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                data-bs-target={`#Collapse0`} aria-expanded="true" aria-controls={"Collapse0"}>
                Matched Items
              </button>
            </h2>

            <div
              style={{ overflow: 'auto' }}
              id={"Collapse0"} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading0"} data-bs-parent="#accordionFlushExample">
              <div className="accordion-body d-flex"
              >
                <NewTable
                  data={matchedItemDetails}
                  columns={[
                    { name: "Item Number" },
                    { name: "Document" },
                    { name: "Product Code", },
                    { name: "Description" },
                    { name: "Quantity" },
                    { name: "Unit Price" },
                    { name: "Amount" }]} disableAction={true} />
              </div>
            </div>

          </div>

          <div
            style={{ overflowX: 'hidden' }} className="accordion-item financeacc mb-3 ">
            <h2
              className="accordion-header font-size-14 font-wt-600" id={"Heading1"}>
              <button
                className="accordion-button shadow-none border-0 font-size-14 font-wt-600 collapsed bg-F8F8F8" type="button" data-bs-toggle="collapse"
                data-bs-target={`#Collapse1`} aria-expanded="true" aria-controls={"Collapse0"}>
                Mis-matched Items
              </button>
            </h2>

            <div
              style={{ overflow: 'auto' }}
              id={"Collapse1"} className={`accordion-collapse collapse ${"show"}`} aria-labelledby={"Heading1"} data-bs-parent="#accordionFlushExample">
              <div className="accordion-body d-flex"
              >
                <NewTable
                  data={mismatchedItemDetails}
                  columns={[
                    { name: "Item Number" },
                    { name: "Document" },
                    { name: "Product Code", },
                    { name: "Description" },
                    { name: "Quantity" },
                    { name: "Unit Price" },
                    { name: "Amount" }]} disableAction={true} />
              </div>
            </div>

          </div>
        </div>
      </FinanceInvoiceModal>}
    </>
  )

}