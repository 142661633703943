import moment from 'moment';
import React, { useEffect, useState } from 'react'
import call from '../../../service';
import avatarUrl from '../../../utils/makeAvatarUrl';


const NewGroupChat = ({ goback, setDetailsChat, setSelectedChat, setChatList, chatList, getChatDetails, userId, checked, handleChange, userEmail, userTypeId, chat_type }) => {
  const [buyerList, setBuyerList] = useState([])
  const [financierList, setFinanciers] = useState([])
  const [searchText, setSearchText] = useState('')

  const [tab, setTab] = useState(chat_type === 'Internal' ? 'Internal' : 'Buyer')
  const getSupplierList = () => {
    let objectAPI =
    {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": 1,
      search: searchText

    }
    call('POST', 'getuserwithfindetail', objectAPI).then((result) => {
      console.log('running getuserwithfindetail api-->', result);
      setFinanciers(result.userData)
    }).catch((e) => {
      // console.log('error in getuserwithfindetail', e);
    });
  }
  const getBuyerList = () => {
    let req = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": "all",
      "currentPage": 1,
      search: searchText

    }
    call('POST', 'getBuyersDetail', req).then(result => {
      console.log('success in getBuyersDetail', result)
      setBuyerList(result.buyerData)
    }).catch(e => {
      console.log('error in getBuyersDetail', e)
    })
  }
  const getFinancierList = () => {
    let req = {
      search: searchText
    }
    call('POST', 'getFinanciersList', req).then(result => {
      console.log('success in getBuyersDetail', result)
      setFinanciers(result)
    }).catch(e => {
      console.log('error in getBuyersDetail', e)
    })
  }
  useEffect(() => {
    if (chat_type === 'Admin' || chat_type === 'Internal') {
      getInternalTeam()
    } else {
      getBuyerList()
      if (userTypeId === 8) {
        getSupplierList()
      } else {
        getFinancierList()
      }
    }
  }, [])
  const onSearchPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      if (tab === 'Buyer') {
        getBuyerList()
      } else if (tab === 'Supplier') {
        getSupplierList()
      } else if (tab === 'Financer') {
        getFinancierList()
      } else if (tab === 'Internal') {
        getInternalTeam()
      }
    }
  }
  useEffect(() => {
    if (tab === 'Buyer') {
      getBuyerList()
    } else if (tab === 'Supplier') {
      getSupplierList()
    } else if (tab === 'Financer') {
      getFinancierList()
    } else if (tab === 'Internal') {
      getInternalTeam()
    }
  }, [tab])
  const getInternalTeam = () => {
    call("POST", 'getSubAdminUser', {}).then(result => {
      setFinanciers(result.data)
    }).catch(err => { })
  }
  console.log('REsult', checked)
  return (
    <>
      <div className='p-2'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-row justify-content-center'>
            <div>
              <img src='/assets/images/ArrowBackLeft.png' alt='' onClick={goback} />
            </div>
            <div>
              <label className='pl-2 font-size-16 font-wt-500'>New Group</label>
            </div>
          </div>
          <div>
            <img alt='' src='/assets/images/more_horizontal.svg' />
          </div>
        </div>
        <div className='d-flex align-items-center'>

          <div className="input-group rounded-pill bg-F8F8F8 chatsearch">
            <span className="input-group-text border-0 bg-transparent px-0" id="basic-addon1"><img src={'/assets/images/fe_search.png'} alt='' /></span>
            <input type="text" className="form-control border-0 bg-transparent" placeholder="Search" onChange={(e) => setSearchText(e.target.value)} onKeyDown={onSearchPress} />
          </div>
        </div>
        <nav>
          <div className="nav nav-tabs signdoctabs justify-content-around border-0" id="nav-tab" role="tablist">
            {chat_type === 'Admin' &&
              <button className={`nav-link ${tab === "Internal" && 'active'} bg-transparent font-size-14`} id="nav-internal-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-internal" aria-selected="true" onClick={() => {
                setTab('Internal')
              }}>{"Internal"}</button>
            }
            {chat_type !== 'Internal' &&
              <>
                <button className={`nav-link ${(tab === chat_type === ('Admin' ? 'Suppplier' : 'Buyer')) && 'active'} bg-transparent font-size-14`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="false" onClick={() => {
                  setTab(chat_type === 'Admin' ? 'Supplier' : 'Buyer')
                }}>{chat_type === 'Admin' ? 'Supppliers' : "Buyers"}</button>
                <button className={`nav-link ${(tab === (userTypeId === 8 ? 'Supplier' : 'Financer')) && 'active'} bg-transparent font-size-14`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => {
                  setTab(userTypeId === 8 ? 'Supplier' : 'Financer')
                }}>{userTypeId === 8 ? 'Suppliers' : 'Financers'}</button>
              </>

            }

          </div>
        </nav>
        <div className="col-md-12 pt-2 chatContainer">
          <ul className="chatcontacts ">
            {tab === 'Buyer' ?
              buyerList.map((key, index) => {
                return (
                  <li className={`cursor`} onClick={() => {
                    const chatindex = chatList.findIndex(data => data.receiverId === `${key.id}`)
                    if (chatindex === -1) {
                      setChatList(chatList.concat({
                        senderId: userId,
                        receiverId: `${key.id}`,
                        ReceiverName: key.buyerName,
                        ReceiverAvatar: null
                      }))
                      setSelectedChat(chatList.length)
                      setDetailsChat([])
                    } else {
                      setSelectedChat(chatindex)
                      getChatDetails({
                        chat_room_id: chatList[chatindex].chat_room_id,
                        loggedInUser: userId
                      })
                    }
                    console.log('UserChatIndexxx', chatindex, chatList)
                  }} key={index} >
                    <div className="d-flex p-2 align-items-center w-100 mb-3">
                      <div className="">
                        <img src={checked[key.buyerName] ? "/assets/images/tick-box-check.svg" : "/assets/images/tick-box.svg"} alt="" className="" onClick={(e) => handleChange(e, key, "buyerName")} />
                      </div>
                      <div className="img_cont w-17">
                        <img src={key.ReceiverAvatar ? typeof (key.ReceiverAvatar) == 'string' ? avatarUrl(key.ReceiverAvatar) : key.ReceiverAvatar.filebase64 : "/assets/images/user-profile.png"} alt="" className="rounded-circle user_img" />
                      </div>
                      <div className={`user_info ms-2 w-83 ${key.activestate ? "" : "border-bottom pb-2"}`}>
                        <p className={`mb-1 d-flex justify-content-between w-100 ${key.unreadstate ? "font-wt-600" : ""}`}>  <span>{key.buyerName}</span></p>
                        <p className='font-size-13 mb-0'>{key.buyerDUNSNo}</p>
                      </div>
                    </div>
                  </li>
                );
              })
              : financierList.map((key, index) => {
                return (
                  <li className={`cursor`} onClick={() => {
                    const chatindex = chatList.findIndex(data => data.recieverId === key.tbl_user_id)
                    if (chatindex === -1) {
                      setChatList(chatList.concat({
                        senderId: userId,
                        receiverId: `${key.tbl_user_id}`,
                        ReceiverName: key.company_name,
                        ReceiverAvatar: key.user_avatar
                      }))
                      console.log('ChatIndex', {
                        senderId: userId,
                        receiverId: `${key.tbl_user_id}`,
                        ReceiverName: key.company_name,
                        ReceiverAvatar: key.user_avatar
                      })

                      setSelectedChat(chatList.length)
                      setDetailsChat([])
                    } else {
                      setSelectedChat(chatindex)
                      getChatDetails({
                        chat_room_id: chatList[chatindex].chat_room_id,
                        loggedInUser: userId
                      })
                    }
                  }} key={index}>
                    <div className="d-flex p-2 align-items-center w-100 mb-3">
                      <div className="">
                        <img src={checked[key.company_name] ? "/assets/images/tick-box-check.svg" : "/assets/images/tick-box.svg"} alt="" className="" onClick={(e) => handleChange(e, key, "company_name")} />
                      </div>
                      <div className="img_cont w-17">
                        <img src={key.user_avatar ? typeof (key.user_avatar) == 'string' ? avatarUrl(key.user_avatar) : key.user_avatar.filebase64 : "/assets/images/user-profile.png"} alt="" className="rounded-circle user_img" />
                      </div>
                      <div className={`user_info ms-2 w-83 ${key.activestate ? "" : "border-bottom pb-2"}`}>
                        <p className={`mb-1 d-flex justify-content-between w-100 ${key.unreadstate ? "font-wt-600" : ""}`}>  <span>{key.company_name}</span></p>
                        {/* <p className='font-size-13 mb-0'>{key.buyerDUNSNo}</p> */}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>

        </div>
      </div>
    </>
  )
}

export default NewGroupChat