import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { GetCache, LCPurposeObject, LCPurposeObjectV2, getContactObject, isEmpty, most_used_currencies, SetCache, getDocDetails, multiSelectEventHandler, mapOrganizationTypeWithKarza } from '../../../utils/myFunctions';
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import MultipleSelect from '../../../utils/MultipleSelect';
import toastDisplay from '../../../utils/toastNotification';
import ChatBoxPopUp2 from '../../chatRoom/components/ChatBoxPopUp2';
import FinanceInvoiceModal from '../../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { InputWithSelect, NewInput, NewSelect } from '../../../utils/newInput';
import { companyTypes, industryData } from '../../registration/newRegistration';
import SubAdminPopup from '../SubAdminPopup';

const overallCounts = [
  { name: "Under Review", alt: 'underreview', class: ' text-F1C40F ' },
  { name: "Inprogress", alt: 'inprogress', class: ' text-F1C40F ' },
  { name: "Approved", alt: 'approved', class: ' text-48DA87 ' },
  { name: "Rejected", alt: 'rejected', class: ' colorFF7B6D ' }
]

const CPLCLimit = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)


  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState(Object.keys(GetCache("lcLimitFilter")).length ? GetCache("lcLimitFilter") : { resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState(GetCache("lcLimitFilterData"))
  const [transactionPopup, toggleTransactionPopup] = useState({ show: false, data: [] })
  const [filterCount, setFilterCount] = useState({})
  const [filteredSearch, setFilteredSearch] = useState(GetCache("lcLimitSearchFilterData"))
  const [salesPerson, setSalesPerson] = useState([])
  const [data, setdata] = useState({ phone_code: "91", name_title: "Mr" })

  const [internalRemarks, setInternalRemarks] = useState([])
  const [internalRemarkPopup, setInternalRemarkPopup] = useState({
    show: false
  })
  const [addRemarkPopup, setAddRemarkPopup] = useState({
    show: false
  })
  const [selectSupplierPopup, setselectSupplierPopup] = useState(false)
  const [suppliers, setsuppliersdata] = useState([])
  const [suppliersoverall, setsuppliersdataoverall] = useState([])
  const [supplierName, setSupplierName] = useState('')
  const [countrys, setCountrys] = useState([])
  const [errors, setErrors] = useState({})
  const [matchingSuppliers, setMatchingSuppliers] = useState([])
  const [panArr, setPanArr] = useState([])


  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [subadminPopup, togglesubadminPopup] = useState({ data: [], show: false, userId: '' })

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId



  const handleValidation = () => {
    let err = {}
    const validationFields = ["supplierName", "contactPerson", "contact_number", "designation", "email_id"]
    for (let index = 0; index < validationFields.length; index++) {
      const element = validationFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      }
    }
    let reqBodyFields = ["supplierName", "contactPerson", "name_title", "phone_code", "contact_number", "designation", "email_id", "industry_type", "organization_type", "user_address",
      "gstDocument", "iecDocument", "panDocument", "cinDocument"
    ]
    if (isEmpty(err)) {
      let obj = {
        'application_type': 'Invoice Limit'
      }
      reqBodyFields.forEach(k => {
        obj[k] = data[k]
      })
      let exporter = suppliersoverall.find(item => item.EXPORTER_NAME === data["supplierName"])
      if (exporter) {
        obj["EXPORTER_CODE"] = exporter.EXPORTER_CODE
      }
      setshowLoader(true)
      obj["cpUserId"] = userId
      call('POST', 'createdirectapplication', obj).then(result => {
        localStorage.setItem("UserDetails", JSON.stringify(result))
        window.open(`/applyForLimit`, "_blank")
        setshowLoader(false)
        setselectSupplierPopup(false)
        setdata({
          ...data,
          supplierName: "",
          contactPerson: "",
          contact_number: "",
          designation: "",
          email_id: "",
          user_address: "",
          organization_type: ''
        })
        setSupplierName('')
        setsuppliersdata([])
        setsuppliersdataoverall([])
      }).catch(e => {
        setshowLoader(false)
        toastDisplay("Failed To Create Application", "error")
      })
    } else {
      console.log('error in mandat', data);
      toastDisplay("Please Fill Mandatory Fields", "info")
    }
  }

  useEffect(() => {
    SetCache("lcLimitSearchFilterData", filteredSearch)
  }, [filteredSearch])
  const getCountrydata = () => {
    call('GET', 'getallCountry').then((result) => {
      console.log('running getallCountry api-->', result);
      setCountrys(result)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
    });
  }
  useEffect(() => {
    getCountrydata()
    getAllIndiaExporters()
  }, [])

  const getAllIndiaExporters = () => {
    setshowLoader(true)
    call('POST', 'getAllIndiaExporters', { supplierName, userTypeId, userId }).then(result => {
      setsuppliersdata(result)
      setsuppliersdataoverall(result)
      setshowLoader(false)
    }).catch(e => {
      setsuppliersdata([{ id: "temp", "EXPORTER_NAME": "Add New Option", typedInput: supplierName }])
      setshowLoader(false)
    })
  }
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (supplierName) {
        getAllIndiaExporters()
      }
    }, 1000);
    return () => {
      clearTimeout(debounce)
    }
  }, [supplierName])
  useEffect(() => {

    if (onlyShowForUserId) {
      let reqObj = {
        parentId: onlyShowForUserId
      }
      console.log('onlyshowfor userid', reqObj);
      call("POST", 'getSubAdminUser', reqObj).then(res => {
        console.log('onlyshowfor userid', res);
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    } else {
      call("POST", 'getSubAdminUser', {}).then(res => {
        setshowLoader(false)
        setSalesPerson(res.data)
      }).catch(err => setshowLoader(false))
    }
  }, [])
  const getAdminLCList = () => {
    SetCache("lcLimitFilterData", filterData)
    SetCache("lcLimitFilter", filter)
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      atLimitStage: true,
      ...filter
    }
    // objectAPI["onlyShowForUserId"] = onlyShowForUserId
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    if (data.subadmins && data.subadmins?.length) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["subadminIds"] = data.subadmins
    }
    call('POST', 'getLCListForCP', objectAPI).then((result) => {
      console.log('running getLCListForCP api-->', result);
      setDbData(result.listData);
      setTableData(formatDataForTable(result.listData))
      setCount(result.countData);
      setFilterCount(result.filterCount)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCListForCP', e);
      setshowLoader(false)
    });
  }
  useEffect(() => {
    getAdminLCList()
  }, [refresh, page, filterData, data.subadmins, salesPerson])

  useEffect(() => {
    let isCacheExist = localStorage.getItem('lcLimitFilterData') != "{}"
    let isSearchCacheExist = localStorage.getItem('lcLimitSearchFilterData') != "{}"

    call('POST', 'getLCFiltersForAdmin', { userId, atLimitStage: true }).then(res => {
      console.log("getLCFiltersForAdmin then", res);
      if (!isCacheExist) {
        setFilterData(res)
      }
      if (!isSearchCacheExist) {
        setFilteredSearch(res)
      }
    }).catch(err => { })
  }, [])

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('lcApplicationId', selectedChat.lcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))

    formdata.append("userTypeId", userTypeId)
    formdata.append("sellerId", selectedChat.sellerId)


    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  async function handleTransactionPopup(itemData) {
    setshowLoader(true)
    let apiResp = await call('POST', 'getTransactionHistoryForLC', {
      buyerId: itemData.id, applicationId: itemData.applicationId
    })
    console.log("getTransactionHistoryForLC api resp====>", itemData, apiResp);
    setshowLoader(false)
    toggleTransactionPopup({ show: true, data: apiResp })
  }

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      row[0] = moment(item.applicationUpdatedAt).format('DD/MM/YYYY')

      row[1] = item.lcNo
      row[2] = item.supplierName
      row[3] = item.buyerName || "-"


      // row[4] = <div class="dropdown w-100" >
      //   <label class="font-wt-600 font-size-13 cursor" onClick={() => {
      //     togglesubadminPopup({
      //       show: true,
      //       data: [],
      //       userId: item.user_id
      //     })
      //   }} >
      //     {item.leadAssignToName || '-'}
      //   </label>

      // </div>

      let selectedLenderName = item.selectedLenderName ? item.selectedLenderName.split(",") : []
      let selectedLenderId = item.selectedLenderId ? item.selectedLenderId.split(",") : []
      let buyersCredit = []
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []


      if (item.financierQuotes) {
        buyersCredit = JSON.parse(item.financierQuotes)
      }

      row[4] = <div
        className=''
      >
        {selectedLenderName.length ? selectedLenderName.map((key, j) => {
          let isApprovedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.status === "approved") {
              return i
            }
          })?.[0]
          let isRejectedByFinancier = buyersCredit?.filter(i => {
            if ((i.lender_id / 1 == selectedLenderId[j] / 1) && i.status != "approved") {
              return i
            }
          })?.[0]

          let openChatRoomIndx = null
          chatRoomUsers.forEach((u, i) => {
            if (u?.split("::")[1] / 1 == selectedLenderId[j]) {
              openChatRoomIndx = i
            }
          })

          return (
            <div
              className={`position-relative cursor ${isApprovedByFinancier ? " text2ECC71 " : ''} ${isRejectedByFinancier ? ' text-danger ' : '   '}`} >
              <img className='cursor'
                onClick={async () => {
                  let reqObj = {
                    userTypeId,
                    senderId: userId,
                    sellerId: item.supplierUserId,
                    receiverId: selectedLenderId[j],
                    textMessage: 'Hii',
                    chat_room_name: "CHAT" + new Date().getTime(),
                    includeAdmins: true,
                    lcApplicationId: item.applicationId,
                    receiverParties: selectedLenderId[j],
                    dontSendInitialMsg: true
                  }
                  setshowLoader(true)
                  let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                  setshowLoader(false)
                  getChatDetails({
                    chat_room_id: apiResp.id,
                    loggedInUser: userId
                  })
                  setSelectedChat({
                    chatRoomId: apiResp.id,
                    receiverName: `${key} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                    lcApplicationId: item.applicationId,
                    parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                    userId: userId,
                    isChatOpen: true,
                    receiverId: selectedLenderId[j],
                    sellerId: item.supplierUserId
                  })
                }}
                src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
              <label className='ml-3 font-size-14  font-wt-600 cursor' >{`${key}`}
                <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
            </div>
          )
        }) : "NA"}
      </div>

      row[5] = `${LCPurposeObjectV2[item.lcPurpose]}${item.sameBankForLCDiscounting ? " & Discounting" : ""}`

      row[6] = item.reviewPending ?
        <>
          <button type="button"
            onClick={() => { handleTransactionPopup(item) }}
            class={`inprogress text-white border-0 w-auto`}>
            {"Review Pending"}
          </button>
        </> : item.countOfDeniedQuotes / 1 == selectedLenderName.length / 1 ?
          <>
            <button type="button"
              onClick={() => { handleTransactionPopup(item) }}
              class={`rejected text-white border-0 `}>
              {"Rejected"}
            </button>
          </> :
          (item.contractDocsSignedByExporter && item.contractDocsSignedByFinancier) ?
            <>
              <button type="button"
                onClick={() => { handleTransactionPopup(item) }}
                class={`approved text-white border-0 `}>
                {"Approved"}
              </button>
            </>
            : <> <button type="button"
              onClick={() => { handleTransactionPopup(item) }}
              class={`inprogress text-white border-0 `}>
              {"Inprogress"}
            </button>
            </>

      if (item.reviewPending) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
            Application uploaded by supplier, admin will review application</b> </span>
          <label
            onClick={() => {
              localStorage.setItem("manualUserId", item.supplierUserId)
              localStorage.setItem("manualUserName", item.supplierName)
              localStorage.setItem("manualEmailId", item.supplierEmailId)
              window.location = `/LcDiscounting?buyer=${item.buyerId}&purpose=${item.lcPurpose}&id=${item.applicationId}&adminView=true`
            }}
            className="text-color1 text-decoration-underline cursor mr-2"></label>
        </p>
      }
      else if (!item.financierQuotes) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
            Application sent by supplier, waiting for quote from financier</b> </span>
        </p>
      }
      else if (item.selectedFinancier && !item.quoteLocked) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" mr-2"><b>
            Financer selected by supplier, waiting for financier to lock the deal</b> </span>
        </p>
      }

      tableData.push(row)
      row = []

    }
    return tableData
  }

  function openApplication(indx, tabIndx) {
    let item = dbData[indx]
    if (tabIndx / 1 == 0) {
      localStorage.setItem("manualUserId", item.supplierUserId)
      localStorage.setItem("manualUserName", item.supplierName)
      localStorage.setItem("manualEmailId", item.supplierEmailId)
      window.location = `/LcDiscounting?buyer=${item.buyerId}&purpose=${item.lcPurpose}&id=${item.applicationId}&adminView=true`
    }
    else {
      window.location = `/LcSeequotes?id=${item.applicationId}`
      localStorage.setItem("isAdmin", true)
      localStorage.setItem("defaultTabForAdmin", tabIndx)

      // setting manual user id & email for user
      localStorage.setItem("manualUserId", item.user_id)
      localStorage.setItem("manualUserEmail", item.supplierEmailId)
    }
  }

  async function downloadCSVFile() {
    try {
      let csvString = "Date of Application,BDM,Beneficiary Name,Type,Tenor,Currency,Amount,LC Issuing Bank,Destination Country,Status,LC No,Financer,Limit Type\r\n"
      for (let i = 0; i < dbData.length; i++) {
        let element = dbData[i]

        let rowString = `${moment(element.applicationUpdatedAt).format('DD/MM/YYYY')},${element.leadAssignToName || "NA"},${element.supplierName || 'NA'},${element.lcType},${element.lcTenor},${element?.ocrFields?.["32B1"] || "NA"},${element?.ocrFields?.["32B2"] || "NA"},"${element.lcIssuingBankName}",${element.shipmentToCountryName},
        ${element.countOfDeniedQuotes / 1 == element?.selectedLenderName?.split(",")?.length / 1 ?
            "Rejected" :
            (element.contractDocsSignedByExporter && element.contractDocsSignedByFinancier) ?
              "Approved" : 'Inprogress'},${element.lcNo || 'NA'},${element?.selectedLenderName?.length ? element?.selectedLenderName?.split(",").join("||") : 'NA'},${LCPurposeObjectV2[element.lcPurpose]}${element.sameBankForLCDiscounting ? " & Discounting" : ""}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `LCLimitList.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadCSVFile", error);
    }
  }
  const handleMultiSelectchange = async (e, name, val, singleSelect) => {
    if (singleSelect) {
      if (e?.[0]?.id === "temp") {
        console.log('1111111111111111111111111', { ...e[0], [val]: e[0]["typedInput"], id: e[0]["typedInput"] });
        let allsuppliers = suppliersoverall
        let EXPORTER_NAME = e[0]["typedInput"]
        allsuppliers.push({ ...e[0], ["EXPORTER_NAME"]: e[0]["typedInput"], id: e[0]["typedInput"] })
        setsuppliersdata(allsuppliers)
        setsuppliersdataoverall(allsuppliers)
        setdata({
          ...data,
          [name]: EXPORTER_NAME,
          organization_type: (EXPORTER_NAME?.toLowerCase().includes("pvt ltd") || EXPORTER_NAME?.toLowerCase().includes("ltd")) ? 'pvtPubLtd' : EXPORTER_NAME?.toLowerCase().includes("llp") ? 'llp' : ''

        })
        // fetch matching company's start
        let matchingCompanys = await call('POST', 'searchEntity', { supplierName: e[0]["typedInput"] })
        setMatchingSuppliers([...matchingCompanys])
        // fetch matching company's end
      } else {
        const exporter = suppliersoverall.find(item => item.EXPORTER_NAME == e?.[0]?.["EXPORTER_NAME"])
        let contact = getContactObject(exporter?.EXTRA_DETAILS || [])
        setdata({
          ...data,
          [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
          contactPerson: contact ? contact["Contact Person"] : "",
          contact_number: contact ? contact["Contact Number"] : "",
          designation: contact ? contact["Designation"] : "",
          email_id: contact ? contact["Email ID"] : "",
          user_address: exporter?.EXPORTER_ADDRESS || "",
          industry_type: exporter?.industryType || "",
          organization_type: (exporter?.EXPORTER_NAME?.toLowerCase().includes("pvt ltd") || exporter?.EXPORTER_NAME?.toLowerCase().includes("ltd")) ? 'pvtPubLtd' : exporter?.EXPORTER_NAME?.toLowerCase().includes("llp") ? 'llp' : ''
        })
      }

    }
    else {
      setdata({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  const updateLeadAssignedTo = (leadAssignedName, userId) => {
    call('POST', 'updateLeadAssignedTo', { leadAssignedName, userId }).then(result => {
      toastDisplay("Lead updated", "success")
      getAdminLCList()
    }).catch(e => {
      toastDisplay("Failed to assign lead to " + leadAssignedName, "error")
    })
  }

  const handleChange = async (event) => {
    event.persist()
    if (event.target.name === 'matchingSupplierName' && event.target.value) {
      setshowLoader(true)
      try {
        let apiResp = await call('POST', 'getAndVerifyKYCV2', { "entityId": event.target.value, bypassGSTCheck: true })
        setshowLoader(false)
        if (apiResp) {
          let addressComponents = {}
          setshowLoader(true)
          if (apiResp?.company_address?.length) {
            addressComponents = await call('POST', 'getAddressComponents', { address: apiResp.company_address })
          }
          setshowLoader(false)
          let tempMultPanArr = []
          for (let index = 0; index < apiResp.multiplePans?.length; index++) {
            tempMultPanArr.push({ name: apiResp.multiplePans[index] })
          }
          setPanArr(tempMultPanArr)
          setdata({
            ...data,
            [event.target.name]: event.target.value,
            iecArr: apiResp.iecArr,
            organization_type: mapOrganizationTypeWithKarza(apiResp.organizationType),
            gstDocument: apiResp?.gst,
            iecDocument: apiResp.iecArr?.[0]?.["iec"] || null,
            panDocument: apiResp.pan, cinDocument: apiResp.cin || null,
            "user_address": apiResp.company_address,
            "companyCity": addressComponents.city,
            "companyPostalCode": addressComponents.postalCode,
            "country": addressComponents.countrySortName,
            "companyState": addressComponents.state,
            "gst_company_detail": null,
            "iec_company_detail": null,
            "cin_company_detail": null,
            "type": "finTech"
          })
        }
      } catch (error) {
        console.log("e", error);
        setshowLoader(false)
        toastDisplay(error, "error")
      }
    }
    else {
      setdata({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }

  return (
    <>
      <SubAdminPopup togglesubadminpopup={togglesubadminPopup} subadminpopup={subadminPopup} setShowLoader={setshowLoader} refreshtable={getAdminLCList} />

      {addRemarkPopup.show && <div className={`modal fade ${addRemarkPopup.show && "show"}`} style={{ display: "block" }}>
        <div className="modal-dialog modal-md">
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setAddRemarkPopup({ show: false })}></button>
            </div>
            <div className="modal-body text-center">
              <p>Add Remark</p>
              <div className="col-md-10 move-p">
                <textarea rows="4" cols="50" className={"form-control"} placeholder="Write a remark for internal purpose." name="addRemark" value={data.addRemark}
                  onChange={handleChange}
                />
              </div>
              <button type="button"
                onClick={() => {
                  if (!data.addRemark) {
                    return toastDisplay('Kindly add remark to continue', "info")
                  }
                  setshowLoader(true)
                  call('POST', 'addAdminRemark', {
                    userId, remark: data.addRemark, lcApplicationId: addRemarkPopup.lcApplicationId
                  }).then(async (result) => {
                    toastDisplay("Remark added", "success")
                    setRefresh(refresh + 1)
                    setshowLoader(false)
                    setAddRemarkPopup({ show: false })
                  }).catch((error) => {
                    toastDisplay(error, "error")
                    setshowLoader(false)
                    setAddRemarkPopup({ show: false })
                  })
                }}
                className={`mx-2 new-btn w-25 py-2 px-2 mt-4 text-white`}>
                {"Send Remark"}
              </button>
            </div>
          </div>
        </div>
      </div>}
      <div className={`modal fade ${internalRemarkPopup.show && "show"}`} style={internalRemarkPopup.show ? { display: "block", "zIndex": '1000001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="">
                <i onClick={() => { setInternalRemarkPopup({ show: false }) }}
                  class="fas fa-2x fa-arrow-left mx-1 icon-color cursor"></i>
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Internal Remarks</label>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className='d-flex row'>
                {internalRemarks.map((i, j) => {
                  return (
                    <div className='card-layout p-3 mb-4'>
                      <div>
                        <label className='col-7 font-size-14 font-wt-600'>{i.contact_person || "NA"}</label>
                        <label className='col-5 font-size-14 font-wt-600'>{moment(i.createdAt).format("DD/MM/YYYY hh:mm a") || "NA"}</label>
                      </div>
                      <p
                        style={{ whiteSpace: 'pre-wrap' }}
                        className='font-size-15 font-wt-500 mx-3'>{i.remark}</p>
                    </div>
                  )
                })}
              </div>
              <button style={{ height: '2rem', borderRadius: 0, fontSize: 18, background: '#5CB8D3' }} type="button"
                onClick={() => { setdata({ ...data, addRemark: "" }); setInternalRemarkPopup({ show: false }); setAddRemarkPopup({ show: true, lcApplicationId: internalRemarkPopup.lcApplicationId }) }}
                class={`text-white border-0 w-100 `}>
                {"Add Remark"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }

      {selectSupplierPopup &&
        <FinanceInvoiceModal modalSize={"md"} isCentered={true} limitinvoice={selectSupplierPopup} closeSuccess={() => {
          setselectSupplierPopup(false)
          setdata({
            ...data,
            supplierName: "",
            contactPerson: "",
            contact_number: "",
            designation: "",
            email_id: "",
            user_address: "",
            organization_type: ''
          })
          setSupplierName('')
          setsuppliersdata([])
          setsuppliersdataoverall([])
        }}>
          <div>
            <div className='col-md-12'>
              <MultipleSelect
                isMulti
                Id="Supplier Name"
                Label="Supplier Name"
                selectedvalue="Supplier Name"
                filterOption={() => true}
                onInputChange={(e) => {
                  setSupplierName(e)
                }}
                optiondata={suppliers}
                onChange={(e) => handleMultiSelectchange(e, "supplierName", "EXPORTER_NAME", true)}
                value={data.supplierName ? [data.supplierName] : []}
                name="supplierName"
                labelKey={"EXPORTER_NAME"}
                valKey={"EXPORTER_NAME"}
                customStyles={{
                  backgroundColor: '#DEF7FF',
                  borderRadius: '10px'
                }}
              />
            </div>
            {matchingSuppliers.length ?
              <div className='col-md-12'>
                <MultipleSelect
                  isMulti
                  Id="Match Company's Found"
                  Label="Match Company's Found"
                  optiondata={matchingSuppliers}
                  onChange={(e) => {
                    handleChange(multiSelectEventHandler(e, `matchingSupplierName`, "entityId"))
                  }}
                  value={data.matchingSupplierName ? [data.matchingSupplierName] : []}
                  name="matchingSupplierName"
                  labelKey={"primaryName"}
                  valKey={"entityId"}
                  customStyles={{
                    backgroundColor: '#DEF7FF',
                    borderRadius: '10px'
                  }}
                />
              </div> : null}
            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput label={"GST No"}
                  name={"gstDocument"} value={data.gstDocument} error={errors.gstDocument}
                  onChange={handleChange} />
              </div>
            </div>
            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect selectData={data.iecArr || []} name={"iecDocument"} value={data["iecDocument"]} onChange={handleChange}
                  error={errors["iecDocument"]} label={"Enter IEC Number"} optionLabel={"iec"} optionValue={"iec"}
                />
              </div>
            </div>
            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput type="text" id={"cinDocument"} name={"cinDocument"} value={data["cinDocument"]} onChange={handleChange}
                  error={errors["cinDocument"]} label={"Enter CIN Number"}
                />
              </div>
            </div>
            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect selectData={panArr} name={"panDocument"} value={data["panDocument"]} onChange={handleChange}
                  error={errors["panDocument"]} label={"Select PAN Number"} optionLabel={"name"} optionValue={"name"}
                />
              </div>
            </div>
            <div className="col py-2 ">
              <div className="col-md-12 px-0">
                <InputWithSelect isAstrix={true} type={"text"} label={"Contact Person"}
                  selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                  selectName={"name_title"} selectValue={data.name_title}
                  optionLabel={"name"} optionValue={'name'}
                  name={'contactPerson'} value={data.contactPerson} error={errors.contactPerson}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2 ">
              <div className="col-md-12 px-0">
                {/* <label>Contact Number</label> */}
                <InputWithSelect isAstrix={true} type={"text"} label={"Contact Number"}
                  selectData={countrys}
                  selectName={"phone_code"} selectValue={data.phone_code}
                  optionLabel={"phonecode"} optionValue={'phonecode'}
                  name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={true} type={"text"} label={"Designation"}
                  name={"designation"} value={data.designation} error={errors.designation}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={true} type={"text"} label={"Email ID"}
                  name={"email_id"} value={data.email_id} error={errors.email_id}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect
                  isAstrix={true}
                  selectData={companyTypes}
                  optionLabel={'name'}
                  optionValue={'alt'}
                  name={"organization_type"}
                  label={'Organization Type'}
                  value={data.organization_type || ""}
                  onChange={handleChange}
                  error={errors.organization_type} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewSelect
                  isAstrix={true}
                  selectData={industryData}
                  optionLabel={'name'}
                  optionValue={'value'}
                  name={"industry_type"}
                  label={'Industry Type'}
                  value={data.industry_type || ""}
                  onChange={handleChange}
                  error={errors.industry_type} />
              </div>
            </div>

            <div className="col py-2">
              <div className="col-md-12 px-0">
                <NewInput isAstrix={false} type={"text"} label={"Address"}
                  name={"user_address"} value={data.user_address} error={errors.user_address}
                  onChange={handleChange} />
              </div>
            </div>

            <div className="d-flex gap-4  px-3 ">
              <button className={`mt-4 new-btn w-40 py-2 px-2 text-white cursor`} onClick={handleValidation}>Create Application</button>
            </div>

          </div>
        </FinanceInvoiceModal>
      }
      <div className={`modal fade ${transactionPopup.show && "show"}`} style={transactionPopup.show ? { display: "block", "zIndex": '100001' } : {}}>
        <div className="modal-dialog modal-md mr-0 my-0">
          <div className="modal-content submitmodal pb-4"
          >

            <div className="modal-header border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Transaction History</label>
                <div className="modal-header border-0">
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleTransactionPopup({ show: false, data: [] })}></button>
                </div>
              </div>
            </div>

            <div className="modal-body px-4">
              {transactionPopup.data.length ? transactionPopup.data.map((item, index) => {
                return (
                  <div className='d-flex flex-row ml-3'>
                    <div className="progressBarContainer">
                      <div className="progressBarInnerCircle">
                      </div>
                    </div>
                    <div className='pl-4 pt-3'>
                      <p className='font-size-14 text-color1 font-wt-500 mb-0'>{item.action}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.date}</p>
                      <p className='font-size-14 text-color-label font-wt-500 mb-0'>{item.time}</p>
                    </div>
                  </div>
                )
              }) :
                null}
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="CPlcLimit" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"LC > Limit"}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              {/* <div className='d-flex justify-content-end p-0 '>
                <div className="col-md-3">
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      Id="Select User"
                      Label="Select User"
                      selectedvalue="Select Requirement"
                      optiondata={salesPerson}
                      onChange={(e) => handleMultiSelectchange(e, "subadmins", "id")}
                      value={data.subadmins ? data.subadmins : []}
                      name="subadmins"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                      isCheckableList={true}
                    />
                  </div>
                </div>
              </div> */}

              <div className='row mb-3'>
                {overallCounts.map((i, j) => {
                  return (
                    <div className='w-15 cursor'
                      onClick={() => {
                        let temp = filterData
                        for (let index = 0; index < temp["Status"]["data"].length; index++) {
                          const element = temp["Status"]["data"][index];
                          if (index / 1 == j / 1) {
                            element["isChecked"] = true
                          }
                          else {
                            element["isChecked"] = false
                          }
                        }
                        temp["Status"]["isFilterActive"] = true
                        setFilterData({ ...temp })
                      }}>
                      <div className='card h-100 dashboard-card shadow-sm align-items-center cursor'>
                        <label className={`cursor value font-wt-600 ${i.class}`}>{filterCount[i.alt] || "NA"}</label>
                        <label className={'cursor font-size-14 font-wt-600 text-color-label'}>{i.name}</label>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className='filter-div'>
                <Filter
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  showDownloadIcon={true} onDownloadClick={downloadCSVFile}
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} isAdditionalButton={true}>

                  <div className="d-flex gap-4">
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setselectSupplierPopup(true)}>Create New Application</button>
                  </div>

                </Filter>
              </div>

              <div
                style={{}}>
                <NewTable disableAction={false}
                  filterData={filterData}
                  filteredSearch={filteredSearch}
                  setFilteredSearch={setFilteredSearch}
                  setFilterData={setFilterData}
                  columns={[{
                    name: "Date", filter: true,
                    filterDataKey: "Date",
                    sort: [
                      { name: "Latest First", selected: filter.sortDateBy === "DESC", onActionClick: () => { setFilter({ ...filter, sortDateBy: 'DESC', sortBuyerName: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Oldest First", selected: filter.sortDateBy === "ASC", onActionClick: () => { setFilter({ ...filter, sortDateBy: "ASC", sortBuyerName: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  { name: 'LC No', filter: false },
                  {
                    name: "Supplier", filter: true,
                    filterDataKey: "Exporter Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortExpName === "ASC", onActionClick: () => { setFilter({ ...filter, sortExpName: 'ASC', sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortExpName === "DESC", onActionClick: () => { setFilter({ ...filter, sortExpName: "DESC", sortDateBy: false, sortBuyerName: false }); setRefresh(refresh + 1) } }]
                  },
                  {
                    name: "Buyer", filter: true,
                    filterDataKey: "Buyer Name",
                    sort: [
                      { name: "Sort A-Z", selected: filter.sortBuyerName === "ASC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: 'ASC', sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } },
                      { name: "Sort Z-A", selected: filter.sortBuyerName === "DESC", onActionClick: () => { setFilter({ ...filter, sortBuyerName: "DESC", sortDateBy: false, sortExpName: false }); setRefresh(refresh + 1) } }]
                  },
                  // {
                  //   name: "Admin"
                  // },
                  {
                    name: "Financer", filter: true, filterDataKey: "Financier Selected"
                  },
                  { name: "Limit Type" },
                  {
                    name: "Status", filter: true, filterDataKey: "Status", sort: []

                  }
                  ]}
                  data={tableData}
                  options={[
                    { name: "Application", onClick: (indx) => openApplication(indx, 0) },
                    { name: "Quote", onClick: (indx) => openApplication(indx, 1) },
                    { name: "Documents", onClick: (indx) => openApplication(indx, 2) },
                    // { name: "Chat with Supplier", onClick: () => null },
                    // { name: "Chat with Buyer", onClick: () => null }
                    {
                      name: "Add Remark", onClick: (indx) => {
                        setdata({ ...data, addRemark: '' })
                        setAddRemarkPopup({ show: true, lcApplicationId: dbData[indx]["applicationId"] })
                      }
                    }
                  ]}
                />
              </div>
              <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(CPLCLimit)