import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import { InputForTable, InputWithSelectForTable, NewInput, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { platformBackendUrl } from "../../urlConstants";
import axios from "axios";
import { handleFileCommonFunction, most_used_currencies, printDiv } from "../../utils/myFunctions";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import avatarUrl from "../../utils/makeAvatarUrl";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { FileInput } from "../../utils/FileInput";
import SignDocument, { signFonts } from "../InvoiceDiscounting/components/SignDocument";


let invItemsTable = [
  { subColumns: "SR. NO.", subColumnStyle: { width: '5%' } },
  { subColumns: "NO. OF SHIPPING UNITS", subColumnStyle: { width: '12%' } },
  { subColumns: "PKG TYPE", subColumnStyle: { width: '12%' } },
  { subColumns: "HM", subColumnStyle: { width: '6%' } },
  { subColumns: "DESCRIPTION OF ARTICLES", subColumnStyle: { width: '15%' } },
  { subColumns: "WEIGHT", subColumnStyle: { width: '8%' } },
  { subColumns: "CHARGES", subColumnStyle: { width: '9%' } }
]

let chargesTable = [{ subColumns: "CHARGE TITLE", subColumnStyle: { width: '45%' } }, { subColumns: "AMOUNT", subColumnStyle: { width: '45%' } }]

const EBLTemplate = ({ userTokenDetails, navToggleState, selectedInvoiceData }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState({
    invCurrency: 'USD', invChargeTitle0: "SUBTOTAL", invChargeTitle1: 'HANDLING', invChargeTitle2: 'FREIGHT',
    invChargeTitle3: 'MISC.'
  })
  const [error, setErrors] = useState({})
  const [countrys, setCountrys] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [invOther, setInvOther] = useState([null, null, null, null])
  const [preview, setPreview] = useState({})
  const [showPopup, togglePopup] = useState(false)
  const [signdoc, setSigndoc] = useState(false);

  let jj = -1
  let kk = -1

  useEffect(() => {
    if (selectedInvoiceData?.docData) {
      setData(selectedInvoiceData.docData?.data)
      setInvItems(selectedInvoiceData.docData?.invItems)
      setInvOther(selectedInvoiceData.docData.invOther)
    }
  }, [])

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setCountrys(result.data.message);
      }
    });
  }, []);

  async function handleChange(e) {
    e.persist()
    if (e.target.name.includes('itemTotalAmount')) {
      let invSubTotalAmount = 0
      for (let index = 0; index < invItems.length; index++) {
        let amountToAdd = e.target.name === `itemTotalAmount${index}` ? e.target.value :
          (data[`itemTotalAmount${index}`] || 0)
        if (invItems[index] === null) {
          invSubTotalAmount += amountToAdd / 1
        }
      }
      setData({ ...data, [e.target.name]: e.target.value, [`invSubTotalAmount0`]: invSubTotalAmount })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else if (e.target.name.includes(`invSubTotalAmount`)) {
      let invTotalAmount = 0
      for (let index = 0; index < invOther.length; index++) {
        let amountToAdd = e.target.name === `invSubTotalAmount${index}` ? e.target.value :
          (data[`invSubTotalAmount${index}`] || 0)
        if (invOther[index] === null) {
          invTotalAmount += amountToAdd / 1
        }
      }
      setData({ ...data, [e.target.name]: e.target.value, invTotalAmount })
      setErrors({ ...error, [e.target.name]: "" })
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value })
      setErrors({ ...error, [e.target.name]: "" })
    }
  }

  useEffect(() => {
    if (data?.commInvoice?.name) {
      saveCommInvoice()
    }
  }, [data.commInvoice])

  async function saveCommInvoice() {
    setshowLoader(true)
    let formData = new FormData()
    formData.append("userId", userId)
    formData.append("docType", "bl")
    formData.append("docTemplate", "Electronic Bill of Lading")
    formData.append("docName", `Electronic Bill of Lading - ${data.invoiceNo}`)
    formData.append('docData', JSON.stringify({ data, invItems, invOther }))
    formData.append("doc", data.commInvoice)
    if (selectedInvoiceData?.id) {
      formData.append("updateDocId", selectedInvoiceData.id)
    }
    await call('POST', 'saveEdoc', formData)
    setshowLoader(false)
    toastDisplay("Document downloaded & saved", "success")
  }

  return (
    <>
      {signdoc && <SignDocument onlyReturnSign={true} setSigndoc={setSigndoc}
        setUpdatedDoc={(signDetails) => {
          console.log("signDetailsssssssssssssssss", signDetails);
          setData({ ...data, [data.signFor]: signDetails })
        }} />
      }
      {showPopup && <FinanceInvoiceModal limitinvoice={showPopup} setLimitinvoice={togglePopup} closeSuccess={() => togglePopup(false)} >
        <div className="col-md-10 mb-2 ml-5">
          <label className='text-center font-wt-600 text-color1 font-size-14 mb-2'>Upload Company Logo</label>
          <FileInput
            onUploadCancel={() => { setData({ ...data, companyLogo: {} }) }} name={"companyLogo"} value={data["companyLogo"]}
            onChange={(event) => {
              let file_type = event.target.files?.[0]?.["type"]?.toLowerCase()
              if (file_type && (file_type.includes("png") || file_type.includes("jpeg"))) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = async (e) => {
                  let fileObj = event.target.files[0]
                  let fileDataUrl = e.target.result
                  fileObj["filebase64"] = fileDataUrl
                  setData({ ...data, [event.target.name]: fileObj })
                  togglePopup(false)
                }
              }
              else {
                setErrors({ ...error, companyLogo: 'Only png & jpeg images are supported' })
              }
            }}
            error={error.companyLogo} isEditable={true} />
          {error.companyLogo ? <div class="text-danger mt-2 font-size-12">
            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
            <b>{error.companyLogo}</b></div> : ''}
        </div>
      </FinanceInvoiceModal>}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className='card mt-1 pt-4' >
        <div className="d-flex row m-0 p-0 justify-content-end" >
          <div className={`${!preview.show ? ' w-20 ' : ' w-30 '} d-flex justify-content-between`} >
            <button type="button"
              onClick={() => { setPreview({ ...preview, show: !preview.show }) }}
              className={` new-btn ${!preview.show ? ' w-100 ' : ' w-45 '} py-2 px-2 text-white`}>
              {!preview.show ? "Preview" : "Back to Editing"}
            </button>
            {preview.show ?
              <button type="button"
                onClick={() => {
                  printDiv("invoiceDiv", `Electronic Bill of Lading - ${data.invoiceNo}`, [], data, setData, "commInvoice")
                }}
                className={` new-btn w-45 py-2 px-2  text-white`}>
                {"Save & Download"}
              </button> : null}
          </div>
        </div>
        <div id={"invoiceDiv"} className="p-4" >
          <div style={{ borderRadius: "5px" }} >
            <div
              style={{ border: '2px solid #000', borderBottom: '0px solid #000' }}
              className="d-flex justify-content-center align-items-center py-2" >
              <div className="w-50 px-3 py-3" >
                <label className="font-size-24 font-wt-600" >Electronic Bill of Lading</label>
              </div>
              <div className="w-50 p-3">
                <div className="d-flex row align-items-center justify-content-end"
                >
                  <label className="font-size-15 font-wt-500 w-auto" ><u>BILL.#:</u></label>
                  <div className="position-relative w-70" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.invoiceNo && !preview.show) ? "Enter No" : ""} name={"invoiceNo"} value={data.invoiceNo} onChange={handleChange} />
                  </div>
                </div>
                <div className="d-flex row align-items-center justify-content-end"
                >
                  <label className="font-size-15 font-wt-500 w-auto" ><u>BILL DATE:</u></label>
                  <div className="position-relative w-70" >
                    <InputForTable type={"date"} isDisabled={preview.show} placeholder={(!data.invoiceDate && !preview.show) ? "Enter Date" : ""} name={"invoiceDate"} value={data.invoiceDate} onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>


            <div className="row m-0 d-flex row" >
              <div className="col-6 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderRight: 0 }}>
                  <label className="font-size-15 font-wt-500" ><u>SHIP TO</u></label>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      isDisabled={preview.show} placeholder={(!data.consigneeName && !preview.show) ? "Enter Consignee Name" : ""} name={"consigneeName"} value={data.consigneeName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.consigneeCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"consigneeCompanyName"} value={data.consigneeCompanyName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.clientAddress && !preview.show) ? "Enter Address" : ""} name={"clientAddress"} value={data.clientAddress} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.consigneePhoneNo && !preview.show) ? "Enter Phone No" : ""} name={"consigneePhoneNo"} value={data.consigneePhoneNo} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.consigneeEmail && !preview.show) ? "Enter Email Id" : ""} name={"consigneeEmail"} value={data.consigneeEmail} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderLeft: '1px solid #000', borderBottom: '1px solid #000' }}>
                  <label className="font-size-15 font-wt-500" ><u>SHIP FROM</u></label>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      isDisabled={preview.show} placeholder={(!data.shipperName && !preview.show) ? "Enter Shipper Name" : ""} name={"shipperName"} value={data.shipperName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.shipperCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"shipperCompanyName"} value={data.shipperCompanyName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.shipperAddress && !preview.show) ? "Enter Address" : ""} name={"shipperAddress"} value={data.shipperAddress} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.shipperPhoneNo && !preview.show) ? "Enter Phone No" : ""} name={"shipperPhoneNo"} value={data.shipperPhoneNo} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.shipperEmail && !preview.show) ? "Enter Email Id" : ""} name={"shipperEmail"} value={data.shipperEmail} onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 d-flex row" >
              <div className="col-6 p-0" >
                <div className="p-3"
                  style={{
                    border: '1px solid #000', borderLeft: '2px solid #000', borderBottom: '2px solid #000',
                    borderTop: 0
                  }}>
                  <label className="font-size-15 font-wt-500" ><u>BILLING INFORMATION</u></label>
                  <div className="position-relative w-70 mb-2 mt-2" >
                    <InputForTable
                      isDisabled={preview.show} placeholder={(!data.billingName && !preview.show) ? "Enter Billing Name" : ""} name={"billingName"} value={data.billingName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <InputForTable isDisabled={preview.show} placeholder={(!data.billingCompanyName && !preview.show) ? "Enter Company Name" : ""} name={"billingCompanyName"} value={data.billingCompanyName} onChange={handleChange} />
                  </div>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable isDisabled={preview.show} placeholder={(!data.billingAddress && !preview.show) ? "Enter Address" : ""} name={"billingAddress"} value={data.billingAddress} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0" >
                <div className="p-3"
                  style={{ border: '1px solid #000', borderBottom: '2px solid #000', borderLeft: 0, height: '9.8rem', borderTop: 0 }}>
                  <label className="font-size-15 font-wt-500" ><u>SHIPPING OR SPECIAL INSTRUCTIONS</u></label>
                  <div className="position-relative w-70 mb-2" >
                    <NewTextAreaForTable rows={4} isDisabled={preview.show} placeholder={(!data.shippingInstructions && !preview.show) ? "Enter Text" : ""} name={"shippingInstructions"} value={data.shippingInstructions} onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>


            <div className="p-3 mt-2"
              style={{ border: '2px solid #000', }}>
              <NewTablev2 addTableClass={`m-0 invBorder`}
                columns={preview.show ? invItemsTable : invItemsTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '5%' } }])}
              >
                {invItems.map((i, j) => {
                  if (i === null) {
                    jj = jj + 1
                    if (preview.show && !data[`itemDesc${j}`]) {
                      return null
                    }
                    return (
                      <tr>
                        <td><label className="font-size-16 font-wt-500" >{jj + 1}</label></td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show}
                              placeholder={(!data[`itemQuantity${j}`] && !preview.show) ? "Enter Quantity" : ""} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show} placeholder={(!data[`itemPkgType${j}`] && !preview.show) ? "Enter PKG Type" : ""} name={`itemPkgType${j}`} value={data[`itemPkgType${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <SelectForTable isDisabled={preview.show} selectData={[{ name: 'Yes' }, { name: 'No' }]} optionLabel={"name"} optionValue={"name"}
                              name={`itemHM${j}`} value={data[`itemHM${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show} placeholder={(!data[`itemDesc${j}`] && !preview.show) ? "Enter Description" : ""} name={`itemDesc${j}`} value={data[`itemDesc${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputForTable isDisabled={preview.show} placeholder={(!data[`itemWt${j}`] && !preview.show) ? "Enter Weight" : ""} name={`itemWt${j}`} value={data[`itemWt${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        <td>
                          <div className="position-relative " >
                            <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                              selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                              optionLabel={"code"} optionValue={"code"}
                              name={`itemTotalAmount${j}`} value={data[`itemTotalAmount${j}`]} onChange={handleChange} />
                          </div>
                        </td>
                        {!preview.show ?
                          <td>
                            <div className="d-flex w-100 justify-content-between" >
                              <div className="" >
                                {(invItems.length - invItems.filter(i => {
                                  if (i === undefined) {
                                    return true
                                  }
                                }).length) - 1 === jj ?
                                  <img
                                    onClick={async () => {
                                      let temp = invItems
                                      temp.push(null)
                                      setInvItems([...temp])
                                    }}
                                    className="cursor" src="assets/images/add_black_icon.png" /> :
                                  <img
                                    onClick={() => {
                                      let temp = invItems
                                      temp[j] = undefined
                                      setInvItems([...temp])
                                    }}
                                    className="cursor" src="assets/images/delete.png" />}
                              </div>
                            </div>
                          </td> : null}
                      </tr>
                    )
                  }
                })}
              </NewTablev2>

              <div className="d-flex justify-content-end"
              >
                <div style={{ width: '67%', border: '2px solid #000', borderTop: 0, borderRight: 0, borderBottom: 0 }} >
                  <label className="font-size-14 font-wt-500 w-65 p-3" >If this shipment is to be delivered to the consignee without recourse on the consignor, the consignor shall sign the following statement: The carrier shall not make delivery of this shipment without payment of the freight and all other lawful charges.</label>
                  <div className="position-relative w-50 px-3" >
                    <label
                      onClick={() => {
                        setSigndoc(true);
                        setData({ ...data, signFor: 'consignorSign' })
                      }}
                      className="font-size-14 font-wt-500 cursor" ><u>Signature of the consignor</u>
                      {preview.show ? null :
                        <img src="assets/images/edit.png" className="cursor" />}</label>
                    <div>
                      {data?.consignorSign?.typedSign ?
                        <label style={{
                          fontFamily: signFonts.find((i, j) => {
                            if (j === data.consignorSign.typedSignStyle) {
                              return true
                            }
                          })['font']
                        }} className="font-size-16" >{data.consignorSign.typedSign}</label>
                        : data?.consignorSign?.signBase64 ?
                          <img src={data?.consignorSign?.signBase64} />
                          : null}
                    </div>
                  </div>
                </div>
                <div className="w-33" >
                  <NewTablev2 hideHeading={preview.show} addTableClass={"m-0 invBorder"}
                    columns={preview.show ? chargesTable : chargesTable.concat([{ subColumns: "ACTION", subColumnStyle: { width: '10%' } }])}>
                    {invOther.map((i, j) => {
                      if (i === null) {
                        kk = kk + 1
                        if (preview.show && !data[`invChargeTitle${j}`]) {
                          return null
                        }
                        return (
                          <tr>
                            <td><label className="font-size-16 font-wt-500" >
                              <InputForTable isDisabled={j === 0 || preview.show} placeholder={(!data[`invChargeTitle${j}`] && !preview.show) ? "Enter Title" : ""} name={`invChargeTitle${j}`}
                                value={data[`invChargeTitle${j}`]} onChange={handleChange} />
                            </label></td>
                            <td>
                              <div className="position-relative " >
                                <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                                  selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                                  optionLabel={"code"} optionValue={"code"}
                                  name={`invSubTotalAmount${j}`} value={data[`invSubTotalAmount${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            {!preview.show ?
                              <td>
                                {j === 0 ? null :
                                  <div className="d-flex w-100 justify-content-between" >
                                    <div className="" >
                                      {(invOther.length - invOther.filter(i => {
                                        if (i === undefined) {
                                          return true
                                        }
                                      }).length) - 1 === kk ?
                                        <img
                                          onClick={async () => {
                                            let temp = invOther
                                            temp.push(null)
                                            setInvOther([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = invOther
                                            temp[j] = undefined
                                            setInvOther([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div>
                                  </div>}
                              </td> : null}
                          </tr>
                        )
                      }
                    })}
                  </NewTablev2>

                  <div className="w-100 d-flex row align-items-center m-0 p-0 py-2 "
                    style={{ border: '1px solid #000' }} >
                    <label className={`font-size-14 font-wt-500 ${preview.show ? ' w-55 ' : ' w-40 '} `} >TOTAL</label>
                    <div className={`position-relative ${preview.show ? ' w-45 pl-3 ' : ' w-60 '}`} >
                      <InputWithSelectForTable isDisabled={preview.show} type={"number"}
                        selectData={most_used_currencies} selectName={"invCurrency"} selectValue={data[`invCurrency`]}
                        optionLabel={"code"} optionValue={"code"}
                        name={`invTotalAmount`} value={data[`invTotalAmount`]} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end"
              >
                <div style={{ width: '67%', border: '2px solid #000', borderBottom: 0, borderRight: 0 }} >
                  <label className="font-size-14 font-wt-500 w-65 p-3" >
                    NOTE: Where the rate is dependent on value, shippers are required to state specifically in writing the agreed or declared value of the property.
                    The agreed or declared value of the property is hereby specifically stated by the shipper to be not excedding
                  </label>
                  <div className="position-relative w-50 px-3" >
                    <InputWithSelectForTable type={"number"} isDisabled={preview.show}
                      selectData={most_used_currencies} selectName={"invCurreny"} selectValue={data[`invCurrency`]}
                      optionLabel={"code"} optionValue={"code"}
                      name={`notExceedAmt`} value={data[`notExceedAmt`]} onChange={handleChange} />
                  </div>
                </div>
                <div style={{ border: '2px solid #000', borderTop: 0, borderBottom: 0 }} className="w-33" >
                  <label className="font-size-16 font-wt-600 w-65 p-3">Freight charges are collect unless Box is Marked Prepaid</label>
                  <div className="position-relative w-50 px-3 pb-3 " >
                    <label className="font-size-16 font-wt-600">Is Prepaid?</label>
                    <SelectForTable isDisabled={preview.show} selectData={[{ name: 'Yes' }, { name: 'No' }]} optionLabel={"name"} optionValue={"name"}
                      name={`isFreightPrepaid`} value={data[`isFreightPrepaid`]} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', border: '2px solid #000', borderBottom: 0 }} >
                <label className="font-size-14 font-wt-500 w-100 p-3" >Received subject to individually determined rates, classifications or written contracts that have been agreed on in writing between the carrier and shipper on the date of issue, if applicable, otherwise to rates, classifications and rules that have been established by the carrier and are available to the shipper on request.
                </label>
                <label className="font-size-14 font-wt-500 w-100 px-3 pb-3" > The property described above is in apparent good order, except as noted. If on its route, otherwise to deliver to another carrier on the route to said destination. It is mutually agreed as to each carrier of all or any of said property, over all or any portion of said route to destination and as to each party at any time interested in all or any said property, that every service to be performed he bunder shall be subject to all the Bill of Lading terms and conditions in the governing classification on the date of shipment. Shipper hereby certifies that he is familiar with all the Bill of Lading terms and conditions in the governing classification and the said terms and conditions.
                </label>
              </div>

              <div className="p-3"
                style={{ border: '2px solid #000' }}>
                <div className="d-flex m-0 p-0 align-items-center" >
                  <div className="w-100  d-flex" >
                    <div className="position-relative w-33" >
                      <label className="font-size-14 font-wt-500" ><u>Carrier</u></label>
                      <InputForTable isDisabled={preview.show}
                        placeholder={(!data.carrier && !preview.show) ? "Enter Name" : ""} name={"carrier"} value={data.carrier} onChange={handleChange} />
                    </div>
                    <div className="position-relative w-33" >
                      <label className="font-size-14 font-wt-500" ><u>Name of Authorized Signatory</u></label>
                      <InputForTable isDisabled={preview.show}
                        placeholder={(!data.authorizedSignatoryName && !preview.show) ? "Enter Name" : ""} name={"authorizedSignatoryName"} value={data.authorizedSignatoryName} onChange={handleChange} />
                    </div>
                    <div className="position-relative w-33" >
                      <label
                        onClick={() => {
                          setSigndoc(true);
                          setData({ ...data, signFor: 'shipperSign' })
                        }}
                        className="font-size-14 font-wt-500 cursor" ><u>Signature</u>
                        {preview.show ? null :
                          <img src="assets/images/edit.png" className="cursor" />}</label>
                      <div>
                        {data?.shipperSign?.typedSign ?
                          <label style={{
                            fontFamily: signFonts.find((i, j) => {
                              if (j === data.shipperSign.typedSignStyle) {
                                return true
                              }
                            })['font']
                          }} className="font-size-16" >{data.shipperSign.typedSign}</label>
                          : data?.shipperSign?.signBase64 ?
                            <img src={data?.shipperSign?.signBase64} />
                            : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>





          </div>
        </div>
      </div>
    </>
  )

}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(EBLTemplate)

