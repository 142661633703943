export default function validate(values, userExist) {
  let errors = {};


  if (!userExist.id) {
    if (!values.dirEmail) {
      errors.dirEmail = 'Email is Mandatory ';
    } else if (!/\S+@\S+\.\S+/.test(values.dirEmail)) {
      errors.dirEmail = 'Email ID is invalid';
    }
    if (!values.dirCompanyName) {
      errors.dirCompanyName = 'Company Name is Mandatory ';
    }
    if (!values.dirContactPerson) {
      errors.dirContactPerson = 'Contact Person is Mandatory';
    }
    if (!values.dirContactNo) {
      errors.dirContactNo = 'Contact No is Mandatory ';
    } else if (isNaN(values.dirContactNo)) {
      errors.dirContactNo = 'Contact No Should be a number';
    } else if (values.dirContactNo.length < 10) {
      errors.dirContactNo = 'Contact No should be of atleast 10 digits';
    }
    if (!values.dirUserType) {
      errors.dirUserType = 'Select User Type is Mandatory';
    }
    if (!values.dirAddress) {
      errors.dirAddress = 'Address is Mandatory';
    }
    if (!values.dirCountry) {
      errors.dirCountry = 'Country is Mandatory';
    }
  }

  console.log(errors);
  return errors;
}
