import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, GetCache, most_used_currencies, SetCache, toolTip, getDocDetails, dataURItoBlob } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { ToastContainer } from "react-toastify";
import { FileInput } from "../../../../utils/FileInput";
import { NewTable } from "../../../../utils/newTable";
import { useHistory } from "react-router";
import { InputWithSelect, NewInput } from "../../../../utils/newInput";
import moment from 'moment';
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const addBuyerTabs = [
  { name: "Application form" },
  { name: "Documents" }
]

const serialLabels = ["1st", "2nd", "3rd"]

const DisbursementDetails = ({ userTokenDetails, navToggleState }) => {

  const [tab, setTab] = useState(1);
  const [data, setData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [errors, setErrors] = useState({});
  const [addMoreDoc, setAddMoreDoc] = useState([null]);
  const [showLoader, setshowLoader] = useState(false);
  const [disbursement, setDisbursement] = useState({ modal: false, page: 0 });
  const [disbursementArr, setDisbursementArr] = useState([{}, {}])
  const [agreementConfiguration, setAgreementConfiguration] = useState({})

  let history = useHistory()

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  let disbursementDetail = localStorage.getItem("disbursementDetail");
  disbursementDetail = disbursementDetail ? JSON.parse(disbursementDetail) : 'NA';


  const reviewForm = [
    { "name": "Applicant Name:", val: disbursementDetail.supplierName, unit: disbursementDetail.supplierName },
    { "name": "Request amount:", val: disbursementDetail.currencyCode + ' ' + disbursementDetail.amount, unit: disbursementDetail.amount },
    { "name": "Invoice amount:", val: disbursementDetail.currencyCode + ' ' + disbursementDetail.contract_amount, unit: disbursementDetail.contract_amount },
    { "name": "Finance credit days:", val: disbursementDetail.credit_days + ' days', unit: disbursementDetail.credit_days + ' days' },
    { "name": "Invoice No.:", val: disbursementDetail.reference_no },
    { "name": "Bank:", val: disbursementDetail.bankList.split(':')[1] },
    { "name": "Invoice issue date:", val: disbursementDetail.issue_date },
    { "name": "Lender:", val: userName },
    { "name": "Invoice due date:", val: disbursementDetail.due_date },
    { "name": "Buyer:", val: disbursementDetail.buyerName },
    { "name": "Purchase order no.:", val: disbursementDetail.po_no },
    { "name": "Shipper / Broker:", val: "NA" }
  ]

  useEffect(() => {
    // Load agreement configuration start
    call('POST', 'getAgreementDocConfigurationByInvoiceNo', { "invRefNo": disbursementDetail.reference_no }).then((result) => {
      if (result.configuration) {
        setAgreementConfiguration(result.configuration)
      }
    }).catch((e) => {
    })
    // Load agreement configuration end

  }, [])

  async function asyncUseEffectFunction() {
    if (Object.keys(agreementConfiguration).length) {

      setshowLoader(true);
      let investigationReport, certificateOfOrigin, noaDocument, frameworkDocument, exhibitDocument = "";
      let docId = disbursementDetail.tbl_doc_id.split(",");
      let invoiceDocument = await getDocDetails(docId[0]);
      let poDocument = await getDocDetails(docId[1]);
      let shipment = await getDocDetails(docId[2]);
      if (docId.length > 3) {
        investigationReport = await getDocDetails(docId[3]);
      }
      if (docId.length > 4) {
        certificateOfOrigin = await getDocDetails(docId[4]);
      }
      if (disbursementDetail.frameworkDoc) {
        frameworkDocument = await getDocDetails(disbursementDetail.frameworkDoc);
      }
      if (disbursementDetail.exhibitDoc) {
        exhibitDocument = await getDocDetails(disbursementDetail.exhibitDoc);
      }
      if (disbursementDetail.noaDoc) {
        noaDocument = await getDocDetails(disbursementDetail.noaDoc);
      }
      let termSheet = await getDocDetails(disbursementDetail.termSheet);

      let docSignStatus = await call("POST", 'invoiceAgreementSignStatus', { invRefNo: disbursementDetail.reference_no })
      setData({
        ...data, invoiceDocument, poDocument, shipment, investigationReport, certificateOfOrigin,
        termSheet, frameworkDocument, exhibitDocument, noaDocument, docSignStatus
      })

      setTableData(formatDataForTable([{ frameworkDocument, key: "frameworkDocument", title: "Framework Document", configurationKey: "framework" },
      { exhibitDocument, key: "exhibitDocument", title: "Exhibit Document", configurationKey: "exhibit" },
      { noaDocument, key: "noaDocument", title: "Noa Document", configurationKey: "noa" }], docSignStatus))

      call('POST', 'getInvoiceDisbursementSchedule', {
        invRefNo: disbursementDetail.reference_no
      }).then((result) => {
        if (result.length) {
          let temp = []
          for (let index = 0; index < result.length; index++) {
            const element = result[index];
            temp.push({
              "date": moment(element.scheduledOn).format("YYYY-MM-DD"), "id": element.id, "amount": element.amount, "unit": element.currency,
              "status": element.status
            })
          }
          setDisbursementArr(temp)
        }
      }).catch((e) => {
        throw e;
      })
    }
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [agreementConfiguration])

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }
  }

  const handleDisbursementArr = (e, index) => {
    if (e.persist) {
      e.persist()
    }
    let temp = disbursementArr
    temp[index][e.target.name] = e.target.value
    setDisbursementArr([...temp])
  }

  async function validateDisbursementArr() {
    let err = {}
    disbursementArr.forEach((element, index) => {
      if (!element.unit) {
        err["amount" + index] = "Mandatory field"
      }
      if (!element.amount) {
        err["amount" + index] = "Mandatory field"
      }
      if (!element.date) {
        err["date" + index] = "Mandatory field"
      }
    });
    if (!Object.keys(err).length) {
      setshowLoader(true)
      let objectAPI = {
        'quoteId': disbursementDetail.reference_no,
        'disbursementArr': disbursementArr,
        'userId': userId,
        'isInvoice': true,
        "lenderEmail": userEmail
      }
      call('POST', 'scheduleDisbursementInvoice', objectAPI).then((result) => {
        setshowLoader(false)
        toastDisplay(result, "success", () => {
          history.go(-1)
        })
      }).catch((e) => {
        setshowLoader(false)
        toastDisplay("Something went wrong", "error")
      })
    }
    setErrors(err)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  function updateInvoiceStatus(status) {
    let objectAPI = {
      'quoteId': disbursementDetail.reference_no,
      'status': status
    }
    call('POST', 'updateInvoiceStatus', objectAPI).then((result) => {
      setshowLoader(false)
      toastDisplay(result, "success", () => {
        history.go(-1)
      })
    }).catch((e) => {
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    })
  }

  function formatDataForTable(param, docSignStatus) {
    let tableData = []
    let row = []
    for (let index = 0; index < param.length; index++) {
      const item = param[index];
      if (item[item.key]) {
        row[0] = item[item.key]?.doc_name
        row[1] = <div className="w-75">
          <FileInput name={item.key} value={item[item.key]}
            isEditable={false}
          />
        </div>
        row[2] = <div className="signStatusDiv">
          <div className="w-15">
            {agreementConfiguration?.[item.configurationKey + "ToExporter"] ? (
              <img src={"assets/images/green_tick.png"} />
            ) :
              <label className="font-wt-600 font-size-12 text-color1">Not Sent</label>}
          </div>
          <div className="w-15">
            {agreementConfiguration?.[item.configurationKey + "ToBuyer"] ? (
              <img src={"assets/images/green_tick.png"} />
            ) :
              <label className="font-wt-600 font-size-12 text-color1">Not Sent</label>}
          </div>
          <div className="w-15">
            <img src={"assets/images/green_tick.png"} />
          </div>
        </div>
        tableData.push(row)
        row = []
      }

    }
    return tableData
  }

  return (
    <>
      {/* {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)} */}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoice_disbursement" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Invoice-discounting > Disbursement >"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/disbursement"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 0 ? (
                  <div className='px-5 py-4 pt-5 '>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Applicant Details</u></label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              // <div className='col-5 p-0'>
                              //   <label className='col-6 lh-18 text-color-label font-size-14'>{item.name}</label>
                              //   <label className='lh-18 font-size-14'>:</label>
                              //   <label className='col-5 lh-18 pl-4 font-wt-600 text-color-value font-size-14'>
                              //     {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                              //   </label>
                              // </div>
                              <div className="col-md-6">
                                <p className="d-flex d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {item.val ? item.val : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u></label>
                        <div className='row mt-2'>
                          <div className="col-md-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Bill of Lading/Airway Bill</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"shipment"} value={data.shipment} error={errors.shipment}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Investigation Report</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"investigationReport"} value={data.investigationReport} error={errors.investigationReport}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Certificate of Origin</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"certificateOfOrigin"} value={data.certificateOfOrigin} error={errors.certificateOfOrigin}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label className="font-size-14">Term Sheet</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"termSheet"} value={data.termSheet} error={errors.termSheet}
                                  onChange={handleFile} isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <NewTable
                      disableAction={true}
                      columns={[{ name: "Document Name" }, { name: "Documents Attached" },
                      { name: "Signed By", subColumns: ["Supplier", "Buyer", "Financier"] }]}
                      data={tableData}
                    />
                  </div>
                ) : null}

                {tab === 0 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => setTab(1)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Continue"}
                    </button>
                  </div>
                )}

                {tab === 1 && (
                  <div className="row pb-5 mx-4">
                    <button type="button"
                      onClick={() => updateInvoiceStatus(3)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Approve"}
                    </button>
                    <button type="button"
                      onClick={() => updateInvoiceStatus(5)}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Reject"}
                    </button>
                    <button type="button"
                      onClick={() => setDisbursement({ modal: true, page: 1 })}
                      className={`mx-4 new-btn w-15 py-2 px-3 text-white`}>
                      {"Schedule Disbursment"}
                    </button>
                  </div>
                )}

                {(disbursementArr && disbursement.modal && disbursement.page === 1) ?
                  <div className={`modal fade ${disbursement.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbursement({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p className="mb-4">Schedule Disbursement</p>
                          {disbursementArr.map((item, index) => {
                            return (
                              <div className="row pl-5">
                                <div className="col-1 text-center pt-2">
                                  <label>{serialLabels[index] || (index + 1) + "th"}</label>
                                </div>
                                <div className="col-4">
                                  <NewInput key={index} label={"Date"} type={"date"} name={"date"} defaultValue={item["date"]}
                                    onChange={(e) => handleDisbursementArr(e, index)} error={errors["date" + index]} />
                                </div>
                                <div className="col-5">
                                  <InputWithSelect
                                    key={index}
                                    selectData={most_used_currencies} selectName={"unit"}
                                    defaultSelectValue={item["unit"]} name={"amount"} defaultValue={item["amount"]}
                                    optionLabel={'code'} optionValue={'code'} error={errors["amount" + index]}
                                    label={"Amount"} type={"number"} onChange={(e) => handleDisbursementArr(e, index)} />
                                </div>
                                <div className="col-1 pt-1">
                                  {item.status ? (
                                    <img
                                      onClick={() => handleDisbursementArr({ target: { name: "status", value: false } }, index)}
                                      className="cursor" src={"/assets/images/upload_done.png"} alt="" height={20} width={20} />
                                  ) : (
                                    <img
                                      onClick={() => handleDisbursementArr({ target: { name: "status", value: true } }, index)}
                                      className="cursor" src={"/assets/images/processing.png"} alt="" height={20} width={20} />)}
                                </div>
                                {index > 0 ? (
                                  <div className="col-1 pt-1">
                                    <button
                                      type="button" className="btn-close"
                                      onClick={() => {
                                        let temp = disbursementArr
                                        temp = temp.filter((i, j) => {
                                          if (j != index) {
                                            return i
                                          }
                                        })
                                        setDisbursementArr(temp)
                                      }}
                                    >
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                          <a
                            onClick={() => {
                              let temp = disbursementArr
                              temp = temp.concat([{}])
                              setDisbursementArr(temp)
                            }}
                          >
                            <p className="text-color1 font-wt-600 cursor"
                            >Add more</p>
                          </a>
                          <button type="button" className={`mx-2 new-btn w-25 py-2 px-2 mt-3 text-white`}
                            onClick={() => validateDisbursementArr()}>Save</button>
                        </div>
                      </div>
                    </div>
                  </div> :
                  (disbursement.modal && disbursement.page === 2) &&
                  <div className={`modal fade ${disbursement.modal && "show"}`} style={{ display: "block" }}>
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content submitmodal pb-4">
                        <div className="modal-header border-0">
                          <button type="button" className="btn-close" aria-label="Close" onClick={() => setDisbursement({ modal: false, page: 0 })}></button>
                        </div>
                        <div className="modal-body text-center">
                          <p>Disbursement Schedule</p>
                          <NewTable disableAction={false}
                            columns={[{ name: "Date", filter: false }, { name: "Amount", filter: false }]}
                            data={tableData}
                          />
                          <button type="button" className={`mx-2 new-btn py-2 px-2 mt-3 text-white`}
                            onClick={() => { setDisbursement({ modal: false, page: 0 }); window.location = "/lcFinApplication" }}>Confirm</button>
                          <p className="mt-3 text-blue" onClick={() => setDisbursement({ modal: true, page: 1 })}>Change Schedule</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>
          </main>
        </div>
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(DisbursementDetails)