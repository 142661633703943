import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import InspectionQuotationList from '../inspectionMktPlace/InspectionquotationList';
import Header from '../partial/header';
import Sidebar, { inspectionTypeIds } from '../partial/sidebar';
import QuotationslistV2 from './quotationslistV2';

const MarkeplaceQuotationDashboard = ({ userTokenDetails, navToggleState, dispatch }) => {

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>

  const [tabIndex, toggleTabIndex] = useState(inspectionTypeIds.includes(userTypeId) ? 1 : 0)



  return (
    <>
      <div className="container-fluid">

        <div className="row">
          <Sidebar state={'my-quotations'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={'My Quotations'}
              selectedView={tabIndex}
              userTokenDetails={userTokenDetails}
              onViewChange={index => toggleTabIndex(index)} />

            {tabIndex === 0 ? (
              <QuotationslistV2 userTokenDetails={userTokenDetails} />
            ) : (
              <InspectionQuotationList userTokenDetails={userTokenDetails} />

            )}
          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarkeplaceQuotationDashboard)