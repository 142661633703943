export default function validate(values, file, financeDetails, userTypeId, tab) {
  let errors = {};

  if (!values.applicantEmail) {
    errors.applicantEmail = 'Applicant is Missing';
  }

  if (!values.buyerId) {
    errors.buyerId = 'Select a Buyer';
  }

  if (!values.referenceNo) {
    errors.referenceNo = 'Enter LC Number';
  }

  if (!values.issueDate) {
    errors.issueDate = 'Enter LC issue date';
  }

  if (!values.currency) {
    errors.currency = 'Select a Currency';
  }

  if (!values.contractAmount) {
    errors.contractAmount = 'Enter Contract Amount';
  }

  if (!values.reqAmount) {
    errors.reqAmount = 'Enter Requested Amount';
  }

  if (!values.lenderId) {
    errors.lenderId = 'Select a Lending Bank';
  }

  if (!values.creditDays) {
    errors.creditDays = 'Enter Credit Days';
  }

  // if (!values.logisticId) {
  //   errors.logisticId = 'Select a Bank Master';
  // }

  if (!values.grnDate) {
    errors.grnDate = 'Enter GRN Date';
  }

  if (!file.lcDocument && financeDetails.info.action === "applyFinance" && tab === 1) {
    errors.lcDocument = 'Upload the LC Copy';
  }

  if (!values.interestRate && userTypeId === 8) {
    errors.interestRate = 'Enter Interest Rate';
  }

  if (!values.advisingBank) {
    errors.advisingBank = 'Enter Advising Bank';
  }

  if (!values.confirmingBank) {
    errors.confirmingBank = 'Enter Confirming Bank';
  }

  if (!values.dueDate) {
    errors.dueDate = 'Enter Due Date';
  }

  console.log("error in finance form==>", errors)

  return errors;
}
