import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { FileInput } from "../../../../utils/FileInput";
import toastDisplay from "../../../../utils/toastNotification";
import call from "../../../../service";
import { ToastContainer } from "react-toastify";
import { getDocDetails, most_used_currencies, convertImageToPdf, LcFieldsMaster, LCPurposeObject, LCTypesObject } from "../../../../utils/myFunctions";
import { getUserDataFromCookie } from "../../../../utils/cookieHelper";
import { useHistory } from "react-router";
import Filter from '../../../InvoiceDiscounting/components/Filter';
import { NewTable } from "../../../../utils/newTable";
import moment from "moment";
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import Pagination from "../../../InvoiceDiscounting/contract/components/pagination";

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "HSN Code", val: "buyerHsnCode" },

]

const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "LC number", val: "lcNo" },
  { "name": "LC tenor", val: "lcTenor" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName", notAmmendable: true },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo", notAmmendable: true },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode", notAmmendable: true },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode", notAmmendable: true },
  { "name": "LC issuing bank name", val: "lcIssuingBankName", notAmmendable: true },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  // { "name": "LC Purpose", val: "lcPurpose" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" }
]


const SblcQuotations = ({ navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const amendmentId = queryParams.get("id")
  const quoteId = queryParams.get("quoteId")

  const [accord, setaccord] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });

  const [lcCodes, setLCCodes] = useState({})
  const [LcFields, setLCFields] = useState(LcFieldsMaster)

  const [data, setData] = useState({ ocrFields: {} })
  const [dbData, setDbData] = useState([])
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(false)

  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const [showBuyerComment, toggleBuyerComment] = useState(null)

  const [formTabs, setFormTabs] = useState([])

  const [amend, setAmend] = useState(false);

  const [addValues, setAddValues] = useState({})
  const [fieldValues, setFieldValues] = useState({})

  let userTokenDetails = getUserDataFromCookie()

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;




  async function getMasterData() {
    let temp = LcFields
    setshowLoader(true)
    temp["shipmentFromCountry"]["selectData"] = await call('get', 'getallCountry')
    temp["shipmentToCountry"]["selectData"] = temp["shipmentFromCountry"]["selectData"]
    temp["countryOfOrigin"]["selectData"] = temp["shipmentFromCountry"]["selectData"]
    temp["portOfDischarge"]["selectData"] = await call('POST', 'getOldPortMaster')
    temp["portOfLoading"]["selectData"] = temp["portOfDischarge"]["selectData"]
    setshowLoader(false)
    setLCFields(temp)
  }

  async function fetchAllDocs(applicationDetails) {
    setshowLoader(true)
    let invoiceDocument = await getDocDetails(applicationDetails.buyersDocId.split(":")[0])
    let poDocument = await getDocDetails(applicationDetails.buyersDocId.split(":")[1])
    let draftLCDocument = await getDocDetails(applicationDetails.draftLC)
    let buyerOtherDocIdArr = applicationDetails.buyerOtherDocs ? applicationDetails.buyerOtherDocs.split(":") : []
    let otherDocObj = {}
    let moreDocArr = addMoreDoc
    for (let index = 0; index < buyerOtherDocIdArr.length; index++) {
      otherDocObj["otherDoc" + index] = await getDocDetails(buyerOtherDocIdArr[index])
      moreDocArr.push(null)
    }
    let { financierQuotes } = applicationDetails
    let providedQuote = {}
    if (financierQuotes) {
      financierQuotes = JSON.parse(financierQuotes).forEach(i => {
        if (i.lender_id / 1 == userId / 1) {
          providedQuote = i
        }
      })
    }

    // Get amendment data start
    let amendmentData = {}
    let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, amendmentId })
    if (amendmentDataRes && amendmentDataRes.length) {
      if (amendmentDataRes[0]['amendmentStatus'] == 1) {
        amendmentData["fieldLabels"] = Object.keys(amendmentDataRes[0].details)
        amendmentData["fieldComments"] = Object.values(amendmentDataRes[0].details)
        amendmentData["fields"] = amendmentDataRes[0].lcAdditionalDetails || []
        setFieldValues(amendmentDataRes[0].beforeDetails)
      }
      else {
        toastDisplay("Invalid link", "info", () => { window.location = "/login" })
      }
    }
    // Get amendment data end

    setData({
      ...data, ...applicationDetails, ...amendmentData, ...providedQuote, invoiceDocument, poDocument, draftLCDocument, ...otherDocObj,
      lcType: LCTypesObject[applicationDetails["lcType"]]
    })
    setAddMoreDoc(moreDocArr)
    setshowLoader(false)
  }

  useEffect(() => {
    //Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })

    // call('POST', 'getFinanciersForLC', {
    // }).then((result) => {
    //   console.log('running getFinanciersForLC api -->', result);
    //   setLCFinanciers(result)
    // }).catch((e) => {
    //   // console.log('error in getFinanciersForLC', e);
    // })
    // getMasterData()
    // Get master data end

  }, [refresh])

  useEffect(() => {
    // if (lcFinanciers.length) {
    setshowLoader(true)
    call('POST', 'getSblcForwardedApplications', {
      ...filter, currentPage: page, showOnlyNotSigned: true
    }).then((result) => {
      // console.log('running getSblcForwardedApplications api -->', result);
      setTableData(formatDataForTable(result.data))
      setDbData(result.data)
      setCount(result.totalCount)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getSblcForwardedApplications', e);
      toastDisplay("Something went wrong", "error")
      setshowLoader(false)
    })
    // }
  }, [page, refresh])

  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let showFinanciers = item?.selectedLenderName?.split(",") || []
      // if (item.tieupBank) {
      //   showFinanciers = showFinanciers.filter((i) => {
      //     if (i.id / 1 == item.tieupBank / 1) {
      //       return i
      //     }
      //   })
      // }
      // if (item.forwardedBy && item.forwardedByUserTypeId && item.forwardedByUserTypeId / 1 == 8) {
      //   showFinanciers = showFinanciers.filter((i) => {
      //     if (i.id / 1 == item.forwardedBy / 1) {
      //       return i
      //     }
      //   })
      // }
      let createdAtPlus10Days = moment(item.createdAt).add(10, "days")
      let ocrFields = item.ocrFields || {}
      row[0] = moment(item.updatedAt).format('DD/MM/YYYY')
      row[1] = item.lcIssuingBankName
      row[2] = item.beneficiaryBankName || "NA"
      row[3] = item.lcNo
      row[4] = item.lcTenor
      row[5] = <div
        className='flex-row'
      >
        {showFinanciers.length ? showFinanciers.map((key, index) => {
          return (
            <label className='bg-color1 p-1 mx-1 border-radius-5' >{key}</label>
          )
        }) : "NA"}
      </div>
      // if (item.termSheet && item.termsheetSignedByFinancier && !item.termsheetSignedByEximBank) {
      //   row[6] = <div
      //   >
      //     <a>
      //       <button type="button"
      //         onClick={() => {
      //           localStorage.setItem("selectedItem", JSON.stringify({ ...item, lcFinanciers, onlySignTermSheet: true }))
      //           window.location = "/sblcQuotationDetails"
      //         }}
      //         className={`enableQuotebtn text-white border-0 `}>
      //         {"Sign Termsheet"}
      //       </button>
      //     </a>
      //   </div>
      // }
      if (moment() > createdAtPlus10Days) {
        row[6] = <div
        >
          <a>
            <button type="button"
              // onClick={() => {
              //   localStorage.setItem("selectedItem", JSON.stringify({ ...item, lcFinanciers }))
              //   window.location = "/sblcQuotationDetails"
              // }}
              disabled={true}
              className={`disabledQuotebtn text-dark border-0 `}>
              {"Quote Expired"}
            </button>
          </a>
        </div>
      }
      else if (item.sblcTermSheet) {
        row[6] = <div
        >
          <a>
            <button type="button"
              onClick={() => {
                localStorage.setItem("selectedItem", JSON.stringify({ ...item }))
                window.location = "/sblcQuotationDetails"
              }}
              className={`viewfinancersbtnnew text-color1`}>
              {"Termsheet Sent"}
            </button>
          </a>
        </div>
      }
      else if (item.isQuoteApproved != null && item.isQuoteApproved == 0) {
        row[6] = <div
        >
          <a>
            <button type="button"
              onClick={() => {
                localStorage.setItem("selectedItem", JSON.stringify({ ...item }))
                window.location = "/sblcQuotationDetails"
              }}
              className={`rejectedQuotebtn text-white border-0 `}>
              {"Application Rejected"}
            </button>
          </a>
        </div>
      }
      else {
        row[6] = <div
        >
          <a>
            <button type="button"
              onClick={() => {
                localStorage.setItem("selectedItem", JSON.stringify({ ...item }))
                window.location = "/sblcQuotationDetails"
              }}
              className={`enableQuotebtn text-white border-0 `}>
              {"View Application"}
            </button>
          </a>
        </div>
      }


      if (item.forwardedByUserTypeId && item.forwardedByUserTypeId == 8) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="mr-1" /> <span className="mr-2">
            <b>Applied by financier - {item.forwardedByCompanyName} </b> </span>
        </p>
      }
      else if (item.forwardedByUserTypeId && item.forwardedByUserTypeId == 19) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="mr-1" /> <span className="mr-2">
            <b>Applied by exporter - {item.forwardedByCompanyName} </b> </span>
        </p>
      }

      tableData.push(row)
      row = []
    })
    return tableData
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation() {
    let err = {}

    data.fields && data.fields.forEach((i) => {
      if (!addValues[i["title"]]) {
        err[i["title"]] = "Mandatory field"
      }
    })

    data.fieldLabels && data.fieldLabels.forEach((i) => {
      if (!fieldValues[i]) {
        err[i] = 'Mandatory field'
      }
    })

    // if (!(data.amendedLC && data.amendedLC.name)) {
    //   err["amendedLC"] = "Mandatory field"
    // }

    if (!Object.keys(err).length) {
      let temp
      temp = data.fields || []
      temp.forEach((i) => {
        if (i.type === "currency") {
          i["unit"] = addValues[i["title"] + "Unit"] || 'INR'
        }
        if (i.type === "percentage") {
          i["unit"] = "%"
        }
        i["val"] = addValues[i["title"]]
      })
      let ocrUpdatedFieldValues = {}

      Object.keys(fieldValues).forEach((i) => {
        if (lcCodes[i]) {
          ocrUpdatedFieldValues[i] = fieldValues[i]
        }
      })
      let formData = new FormData()
      formData.append("ocrUpdatedFieldValues", JSON.stringify(ocrUpdatedFieldValues))
      formData.append("lcAdditionalDetails", JSON.stringify(temp))
      formData.append("changedDetails", JSON.stringify(fieldValues))
      formData.append('amendedLC', data.amendedLC)
      formData.append('quoteId', quoteId)
      formData.append('amendmentId', amendmentId)
      setshowLoader(true)
      call('POST', 'processLCAmendment', formData).then((result) => {
        // console.log('running processLCAmendment api -->', result);
        setshowLoader(false)
        toastDisplay(result, "success", () => {
          window.location.reload()
        })
      }).catch((e) => {
        // console.log('error in processLCAmendment', e);
        setshowLoader(false)
        toastDisplay('Something went wrong', "error")
      })
    }
    else {
      toastDisplay("Kindly fill required details to continue", "info")
    }
    setErrors(err)
  }

  async function onView(index) {
    localStorage.setItem("selectedItem", JSON.stringify({ ...dbData[index] }))
    window.location = "/sblcQuotationDetails"
  }

  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        {/* <div className="row px-4 mx-4">
          <div className="row mt-4 pt-4">
            <div className="col-5 d-flex">
              <img height="60" className="default_logo align-self-center" src="assets/images/logos/logo_1.png" />
            </div>
            <div className="col-7 d-flex text-center">
              <label className="text-color1 font-wt-600 font-size-18 align-self-center">SBLC Applications</label>
            </div>
          </div>
        </div > */}
        <div className="row">
          <SideBarV2 state="sblcQuotations" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"SBLC - Quotes"}
              userTokenDetails={userTokenDetails} />
            <div className='mt-4'>
              <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
              <NewTable
                disableAction={false}
                columns={[{
                  name: "Date", filter: true
                }, { name: "Issuing Bank", filter: true },
                {
                  name: "Advising Bank", filter: true
                },
                {
                  name: "LC Number", filter: true
                },
                {
                  name: "LC Tenor", filter: true
                },
                {
                  name: "Financers", filter: true
                },
                {
                  name: "Action", filter: true
                },
                {
                  name: "", filter: false
                }]}
                data={tableData}
                options={
                  [
                    { name: "See Quote", icon: "eye.png", onClick: onView }]}
              />
              <Pagination perPage={filter.resultPerPage} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
            </div>
          </main>
        </div >
      </div>

    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SblcQuotations)