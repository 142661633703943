import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropdownSearch = ({ items, onSelect, placeholder, defaultItem, selectAll, isDisabled, multipleSelection, customStyles = {} }) => {
  const [filter, setFilter] = useState('');
  const [dropdownItems, setDropdownItems] = useState(items);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const searchInputRef = useRef(null);

  const handleUnselectAll = () => {
    setSelectedItems([]);
    onSelect([]);
    setFilter('');
    setShowDropdown(false);
  };


  useEffect(() => {
    setDropdownItems(items);
    if (multipleSelection) {
      setSelectedItems([]);
    }

  }, [items]);


  useEffect(() => {
    if (defaultItem) {
      setFilter(defaultItem);
    }
  }, [defaultItem]);

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);
    setDropdownItems(items.filter(item => item.toLowerCase().includes(value)));
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };

  const handleSelect = (item) => {
    if (multipleSelection) {
      let newSelectedItems;
      if (selectedItems.includes(item)) {
        newSelectedItems = selectedItems.filter(i => i !== item);
      } else {
        newSelectedItems = [...selectedItems, item];
      }
      setSelectedItems(newSelectedItems);
      onSelect(newSelectedItems);
      setFilter('');
    } else {
      onSelect(item);
      setFilter(item);
      setShowDropdown(false);
    }
  };

  const handleSelectAll = () => {
    setSelectedItems(items);
    onSelect(items);
    setFilter('Select All');
    setShowDropdown(false);
  };



  const renderSelectedItems = () => {
    if (multipleSelection && selectedItems.length > 0) {
      return (
        <div className="selected-items" style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {selectedItems.map((item, index) => (
            <span key={index} className="badge bg-primary me-1">
              ✓ {item}
            </span>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="dropdown-search relative" style={{ width: customStyles.widthOfInput ? customStyles.widthOfInput : '200px' }}>
      <InputGroup className="mb-1">
        <FormControl
          style={{ width: '200px', marginRight: customStyles.marginRight ? customStyles.marginRight : '10px' }}
          ref={searchInputRef}
          placeholder={placeholder}
          aria-label="Search"
          aria-describedby="basic-addon1"
          value={filter}
          onChange={handleSearchChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
      </InputGroup>
      {renderSelectedItems()}
      {showDropdown && (
        <div className="dropdown-menu show mt-0 absolute overflow-auto" style={{ width: customStyles.widthOfInput ? customStyles.widthOfInput : '200px', maxHeight: "200px" }}>
          {dropdownItems.map((item, index) => (
            <div
              key={index}
              className="dropdown-item"
              onMouseDown={() => handleSelect(item)}
            >
              <input
                type="checkbox"
                checked={selectedItems.includes(item)}
                readOnly
                className="me-2"
              />
              {selectedItems.includes(item) && <span>✓</span>}
              {item}
            </div>
          ))}
          {selectAll && (
            <>
              <div
                className="dropdown-item text-primary"
                onMouseDown={handleSelectAll}
              >
                Select All
              </div>
              <div
                className="dropdown-item text-primary"
                onMouseDown={handleUnselectAll}
              >
                Unselect All
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
