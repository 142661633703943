import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import toastDisplay from '../../utils/toastNotification';
import price_in_words from '../../utils/wordtoNumber'
import { setContractState, setaddBankObj } from '../../store/actions/action';
import toolTipText from '../../utils/toolTips/masterContract.json'
import ReactCountryFlag from "react-country-flag";
import validate from '../../utils/validation_files/createContractValidationRules'
import validateContractPilot from '../../utils/validation_files/editContractValidationRules'
import { todayDate_DatePicker, dateFormatter_DatePicker, formatDate_Application } from '../../utils/dateFormaters';
import AddBankComponent from '../utilComponents/addBankComponent'
import SpecificationsForm from '../specifications/specificationForm'
import SpecificationsPreview from '../specifications/specificationPreview'
import ContractTrail from '../contractDetails/contractTrail'
import TncForm from '../tncComponents/tncForms'
import DocumentACL from './documentACL.js'
import AddPortComponent from '../utilComponents/addPortComponent';
import { getTraderType } from '../../utils/cookieHelper';
import avatarUrl from '../../utils/makeAvatarUrl';
import commImageUrl from '../../utils/makeCommImageUrl'


const CreateContractModal = ({ userTokenDetails, contractState, setContractState, setaddBankObj, addBankObj, clientType, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({
    'contractType': 'FOB',
    "paymentMode": 1,
    'quantity': 0
  });
  const [supplierInfo, setSupplierInfo] = useState({})
  const [dbSupplier, setDbSupplier] = useState([])
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [contract, setContract] = useState({
    state: 0,
    col: 'FOB'
  });
  const [viewAccordion, setViewAccordion] = useState(0);
  const [commodity, setCommodity] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [changelogs, setchangelogs] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({ state: false, name: '', location: '' });
  const [spec, setSpecification] = useState({});
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agency, setAgency] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [buyerBank, setBuyerBank] = useState([]);
  const [sellerBank, setSellerBank] = useState([]);
  const [refresh, setrefresh] = useState(0)
  const [dataUpdate, setDataUpdate] = useState({});
  const [dataTnc, setdataTnc] = useState({});
  const [tabing, settabing] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [currencyData, setcurrencyData] = useState([]);
  const [paymentModes, setpaymentModes] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierAvatar, setsupplierAvatar] = useState('');
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [countryData, setcountryData] = useState([]);
  const [contractEditMode, setcontractEditMode] = useState(false);
  const [refreshSupplier, setrefreshSupplier] = useState(0)
  const [supplierSearch, setsupplierSearch] = useState('')
  const [editCount, setEditCount] = useState(0);
  // const [extraTnc, setextraTnc] = useState([]);
  const [docCategoryACL, setdocCategoryACL] = useState({});
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [specChange, setspecChange] = useState(0)
  const [tncChange, settncChange] = useState(0)
  const [specOrder, setspecOrder] = useState([])
  const [tncOrder, settncOrder] = useState([])



  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const traderActiveType = getTraderType()

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls

    if (contractState.info.modalMode === "create") {
      if (traderActiveType === 'buyer') {
        setData({
          ...data,
          'user_type': userTypeId,
          'buyerEmail': userEmail,
          'importer_id': userId,
          'buyerName': userName
        })
      } else {
        setData({
          ...data,
          'user_type': userTypeId,
          'supplierEmail': userEmail,
          'exporter_id': userId,
          'supplierName': userName,
        })
      }

      call('POST', 'getjsonfromdb', { "jsonFor": "fobTNCcoal" }).then((result) => {
        console.log("getjsonfromdb:=>", result);
        setdataTnc(result.json_data)
        settncOrder(result.order_array.split(":"))
      }).catch((e) => {
        console.log("Error while querying getjsonfromdb:", e);
      })

      call('POST', 'getAgencys', { traderId: (userTypeId === 14 ? userId : null) }).then((result) => {
        setAgency(result)
      }).catch((e) => {
        console.log("Error while querying getAgencys:", e);
      })

      call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 14 ? userId : null) }).then(async (result) => {
        setInsurers(result)
      }).catch((error) => {
        console.log("error occur in getinsuranceagencylist ->", error)
      })

      call('get', 'v1/plan/quota').then((result) => {
        if (result && result.trades && result.trades.enable && ((result.trades.limitLeft / 1) > 0 || (result.trades.limitLeft / 1) === -1))
          setQuotaAvail(true)
      }).catch((e) => {
        console.log("Error while  getUserCurrentPlanQuota header:-->", e);
      })

    } else {
      setshowLoader(true)
      setQuotaAvail(true)

      call('POST', 'getContracts', { "contract_number": contractState.info.contractNo, "contractOnly": true, "type_id": userTypeId }).then((result) => {
        console.log("api result get contract data--->", result)

        setData({
          "user_type": userTypeId,
          "loggedUserID": userId,
          "userTypeClient": traderActiveType ? traderActiveType : '',
          contract_id: result.id ? result.id : '',
          contract_type: result.contract_type ? result.contract_type : '',
          contract_number: result.contract_number ? result.contract_number : '',
          contractAlias: result.contract_name ? result.contract_name : '',
          commodity_id: result.commodity_id ? result.commodity_id : '',
          commFilename: result.comm_filename ? result.comm_filename : '',
          quantity: result.quantity ? result.quantity : '',
          currency: result.currency ? result.currency : '',
          laycanStart: result.laycan_start ? dateFormatter_DatePicker(result.laycan_start) : '',
          laycanEnd: result.laycan_end ? dateFormatter_DatePicker(result.laycan_end) : '',
          supplierBank: result.supplier_bank,
          buyerBank: result.buyer_bank,
          price: result.price ? result.price : '',
          priceType: result.price_type ? result.price_type : '',
          loadingCountry: result.load_country ? result.load_country : '',
          unloadingCountry: result.unload_country ? result.unload_country : '',
          loadingPort: result.loading_port ? result.loading_port : '',
          unloadingPort: result.unloading_port ? result.unloading_port : '',
          supplierName: result.suppliername ? result.suppliername : '',
          buyerName: result.buyername ? result.buyername : '',
          supplierEmail: result.supplier_email ? result.supplier_email : '',
          buyerEmail: result.buyer_email ? result.buyer_email : '',
          commodity_name: result.commodity_name ? result.commodity_name : '',
          exporter_id: result.exporter_id ? result.exporter_id : '',
          importer_id: result.importer_id ? result.importer_id : '',
          investigationAgencyImporter: result.inv_agency_importer,
          investigationAgencyExporter: result.inv_agency_exporter,
          investigationAgencythird: result.inv_agency_third,
          commodityInsurer: result.commodity_insurer,
          tradeInsurer: result.trade_insurer,
          contract_type_id: result.contract_type_id ? result.contract_type_id : '',
          reference_contract: result.reference_contract ? result.reference_contract : 0,
          investigation_status: result.investigation_status ? result.investigation_status : '',
          payment_status: result.payment_status,
          created_by: result.created_by ? result.created_by : '',
          quotation_status: result.quotation_status ? result.quotation_status : '',
          shipment_status: result.shipment_status ? result.shipment_status : '',
          paymentMode: result.payment_mode ? result.payment_mode : '',
          rejection_level: result.rejection_level ? result.rejection_level : '',
          status_buyer: result.status_buyer,
          status_seller: result.status_seller,
          commenter: userId,
          supplierAvatar: result.supplieravatar ? result.supplieravatar : '',
          buyerAvatar: result.buyeravatar ? result.buyeravatar : '',
          edit_request: result.edit_request
        })

        // fillTNCValue(result.tnc ? JSON.parse(result.tnc) : {}, result.contract_type)
        setCommodity(result.commodity_id)
        setdataTnc(result.tnc)

        if (((userTypeId === 14 || userTypeId === 3) && result.status_buyer === 0) || ((userTypeId === 4) && result.status_seller === 0)) {
          setcontractEditMode(true)
        }

        call('POST', 'getAgencys', { traderId: (userTypeId === 14 ? userId : result.importer_id) }).then((result) => {
          setAgency(result)
        }).catch((e) => {
          console.log("Error while querying getAgencys:", e);
        })

        call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 14 ? userId : result.importer_id) }).then((result) => {
          setInsurers(result)
        }).catch((error) => {
          console.log("error occur in getinsuranceagencylist ->", error)
        })

        call('POST', 'getCountryPort', { 'countryId': result.load_country.split(':')[1] }).then((result) => {
          // console.log('setPortLoading in getCountryPort-->', result);
          setPortLoading(result)
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })
        call('POST', 'getCountryPort', { 'countryId': result.unload_country.split(':')[1] }).then((result) => {
          //console.log('setPortUnloading in getCountryPort-->', result);
          setPortUnloading(result)
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })

        setSpecification(result.specification)
        setspecOrder(result.json_order.specOrder ? result.json_order.specOrder.split(":") : "")
        settncOrder(result.json_order.tncOrder.split(":"))
        loadbanks(result.buyer_email, result.supplier_email)
        setshowLoader(false)

      }).catch((e) => {
        setshowLoader(false)
        console.log("error in view contract useeffect-->", e)
      })

    }

    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getpaymentmodes').then((result) => {
      console.log("getpaymentmodes api result->", result)
      setpaymentModes(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (contractState.info.modalMode === "create") {
      if (userEmail) {
        // call('POST', 'getUserBanks', { email: userEmail }).then((result) => {
        //   // console.log("result in bank api->", result)
        //   if (traderActiveType === 'buyer') {
        //     setBuyerBank(result)
        //   } else {
        //     setSellerBank(result)
        //   }
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log("result in bank api->", result)
          if (traderActiveType === 'buyer') {
            setBuyerBank(result)
          } else {
            setSellerBank(result)
          }
        }).catch((e) => {
          console.log("Error while querying getUserBanks:", e);
        })
      }

      if (supplierInfo.user_name) {
        // call('POST', 'getUserBanks', { email: supplierInfo.user_name }).then((result) => {
        //   // console.log("result in bank api->", result)
        //   if (traderActiveType === 'buyer') {
        //     setSellerBank(result)
        //   } else {
        //     setBuyerBank(result)
        //   }
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log("result in bank api->", result)
          if (traderActiveType === 'buyer') {
            setSellerBank(result)
          } else {
            setBuyerBank(result)
          }
        }).catch((e) => {
          console.log("Error while querying getUserBanks:", e);
        })
      }
    } else {
      if (data.supplierEmail) {
        // call('POST', 'getUserBanks', { "email": data.supplierEmail }).then((result) => {
        //   // console.log('api result for getUserBanks supplierEmail', result);
        //   setSellerBank(result)
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks for seller:", e);
        // })

        call('GET', 'getBanksListMaster', {}).then((result) => {
          // console.log('api result for getUserBanks supplierEmail', result);
          setSellerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks for seller:", e);
        })
      }

      if (data.buyerEmail) {
        // call('POST', 'getUserBanks', { "email": data.buyerEmail }).then((result) => {
        //   //  console.log('api result for getUserBanks buyerEmail', result);
        //   setBuyerBank(result)
        // }).catch((e) => {
        //   console.log("Error while querying getUserBanks for buyer:", e);
        // })

        call('POST', 'getBanksListMaster', {}).then((result) => {
          //  console.log('api result for getUserBanks buyerEmail', result);
          setBuyerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks for buyer:", e);
        })
      }
    }
  }, [addBankObj.info.refresh, supplierInfo]);

  useEffect(() => {
    if (contractState.info.modalMode === "create") {
      call('POST', 'getUsersListing', { "searchUserType": (traderActiveType === 'buyer') ? 4 : 3, "searchKey": '', "network": "innetwork", "userAvatars": true }).then((result) => {
        // console.log("result of api in getUsersListing-->", result)
        setDbSupplier(result)
      }).catch((e) => {
        console.log('error in getUsersListing', e);
      })
    }
  }, [refreshSupplier]);


  useEffect(() => {
    if (Object.keys(errorData).length === 0 && isSubmitting) {
      doNext();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errorData]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Handlers

  const handleActiveClass = (id) => {
    if (id === 7 && (contractState.info.modalMode === "create")) {
      fillTNCValue()
    }
    if (id < activeClass) {
      setActiveClass(id)
    } else {
      settempactiveClass(id);
      setIsSubmitting(true)
      settabing(true)
      if (contractState.info.modalMode === "create") {
        seterrorData(validate(data, Object.keys(spec).length, Object.keys(dataTnc).length, activeClass))
      } else {
        seterrorData(validateContractPilot(data, spec, dataTnc, activeClass, contractEditMode))
      }
    }
  }

  const handleNext = () => {
    setIsSubmitting(true)
    if (contractState.info.modalMode === "create") {
      seterrorData(validate(data, Object.keys(spec).length, Object.keys(dataTnc).length, activeClass))
    } else {
      seterrorData(validateContractPilot(data, spec, dataTnc, activeClass, contractEditMode))
    }
    if (activeClass === 6 && (contractState.info.modalMode === "create")) {
      fillTNCValue()
    }
  }

  const doNext = () => {
    if (tabing === true) {
      setActiveClass(tempactiveClass);
      setIsSubmitting(false)
      settabing(false)
    } else {
      if (activeClass >= maxactiveClass) {
        setmaxactiveClass(activeClass + 1)
      }
      setActiveClass(activeClass + 1);
      setIsSubmitting(false)
    }
  }

  const handlePrev = (id) => setActiveClass(id - 1);

  const handleContract = (con, val) => {
    setData(data => ({ ...data, 'contractType': val }))
    setContract({ state: con, col: val });

    let contractTypecheck = val;
    if (contractTypecheck === "FOB") {
      getTNConChange("fobTNCcoal")
    } else if (contractTypecheck === "CIF") {
      getTNConChange("cifTNCcoal")
    }
  }

  const handleViewAccordion = () => {
    setViewAccordion(viewAccordion ? 0 : 1);
  }

  const handleCommodity = () => setCommodity(true);  // For checkbox at select commodity

  const handleSupplierShow = () => setSupplier(true);

  const handleSupplierClose = () => setSupplier(false);

  const handleSelectedSupplier = (value) => {
    console.log("value in supplier-->", value)
    setSelectedSupplier({
      state: true,
      supplierId: value.id ? value.id : 0,
      name: value.company_name ? value.company_name : '',
      location: value.country_code ? value.country_code : '',
      address: value.user_address ? value.user_address : '',
    });
    setSupplierInfo(value)
    setsupplierAvatar(value.user_avatar ? value.user_avatar : '')
    seterrorData(errorData => ({ ...errorData, supplierName: "" }))

    if (traderActiveType === 'buyer') {
      setData(data => ({
        ...data,
        'supplierName': value.company_name ? value.company_name : '',
        'supplierAddress': value.country_code ? value.country_code : '',
        'supplierEmail': value.user_name ? value.user_name : '',
        "exporter_id": value.id ? value.id : 0
      }));

      call('post', 'getusercommoditycategory', { userId: value.id }).then((result) => {
        console.log("API result getusercommoditycategory in comm modal:-->", result);
        setCommCategory(result);
      }).catch((e) => {
        console.log("Error while querying getusercommoditycategory:", e);
      })

    } else {
      setData(data => ({
        ...data,
        'buyerName': value.company_name ? value.company_name : '',
        'buyerAddress': value.country_code ? value.country_code : '',
        'buyerEmail': value.user_name ? value.user_name : '',
        "importer_id": value.id ? value.id : 0
      }));

      call('post', 'getusercommoditycategory', { userId: userId }).then((result) => {
        console.log("API result getusercommoditycategory in comm modal:-->", result);
        setCommCategory(result);
      }).catch((e) => {
        console.log("Error while querying getusercommoditycategory:", e);
      })

    }
  }

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))

    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, [event.target.name]: event.target.value }));
    }
    setEditCount(editCount + 1)
    if (event.target.name === "commodity" && event.target.value) {
      let selectedCommodity = supplierCommodityList.filter(item => { if (item.commodity_pretty_name + ":" + item.id === event.target.value) { return item } })[0]
      setSpecification(selectedCommodity.specifications ? selectedCommodity.specifications : {});
      setspecOrder(selectedCommodity.spec_order_string ? selectedCommodity.spec_order_string.split(":") : "")
    }
  };

  const verifyDate = (event) => {
    event.persist();
    if (event.target.name === "laycanStart") {

      if (new Date(event.target.value) < new Date()) {
        setData(data => ({ ...data, [event.target.name]: todayDate_DatePicker(true) }));
        seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
        // toastDisplay("Incorrect Date , Min date can be of today's date", "warn")
      }
      //  else if (new Date(event.target.value) > new Date(data.laycanEnd)) {
      //   let tempDate = new Date(data.laycanEnd)
      //   tempDate.setDate(tempDate.getDate() - 10)
      //   setData(data => ({ ...data, [event.target.name]: dateFormatter_DatePicker(tempDate) }));
      //   seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
      //   toastDisplay("Incorrect Date , Min date can be of today's date", "warn")
      // }

    } else if (event.target.name === "laycanEnd") {
      if (new Date(event.target.value) < new Date()) {
        let tempDate = new Date()
        tempDate.setDate(tempDate.getDate() + 10)
        setData(data => ({ ...data, [event.target.name]: dateFormatter_DatePicker(tempDate) }));
        seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
        // toastDisplay("Incorrect Date , Min date can be of today's date", "warn")

      } else if (new Date(event.target.value) < new Date(data.laycanStart)) {
        let tempDate = new Date(data.laycanStart)
        tempDate.setDate(tempDate.getDate() + 10)
        setData(data => ({ ...data, [event.target.name]: dateFormatter_DatePicker(tempDate) }));
        seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
        // toastDisplay("Incorrect Date , Min date can be of today's date", "warn")

      }
    }
  };

  // const handleChangeInsurerdefault = (event) => {
  //   event.persist();
  //   setData(data => ({
  //     ...data,
  //     commodityInsurer: event.target.value,
  //     tradeInsurer: event.target.value
  //   }));
  //   seterrorData(errorData => ({
  //     ...errorData,
  //     commodityInsurer: "",
  //     tradeInsurer: ""
  //   }));
  // }

  // const handleChangeAgencydefault = (event) => {
  //   event.persist();
  //   setData(data => ({
  //     ...data,
  //     investigationAgencyImporter: event.target.value,
  //     investigationAgencyExporter: event.target.value,
  //     investigationAgencythird: event.target.value
  //   }));
  //   seterrorData(errorData => ({
  //     ...errorData,
  //     investigationAgencyImporter: "",
  //     investigationAgencyExporter: "",
  //     investigationAgencythird: "",
  //   }));
  // }


  const handleSpecification = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setSpecification(specdataElement);
    seterrorData(errorData => ({ ...errorData, "Specification": "" }))
    setEditCount(editCount + 1)
    setspecChange(specChange + 1)
  }

  const handleTNC = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let tncdataElement = { ...dataTnc }
    tncdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setdataTnc(tncdataElement);
    seterrorData(errorData => ({ ...errorData, "tnc": "" }))
    setEditCount(editCount + 1)
    settncChange(tncChange + 1)
  }


  function handleInc() {
    document.getElementById('quantity').stepUp(1)
    setData({
      ...data, quantity: parseInt(data.quantity, 10) + 1
    })
    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) + 1 }));
    }
    seterrorData(errorData => ({ ...errorData, quantity: "" }))
    setEditCount(editCount + 1)
  }

  function handleDec() {
    document.getElementById('quantity').stepDown(1)
    setData({
      ...data, quantity: parseInt(data.quantity, 10) - 1
    })
    if (contractState.info.modalMode === "edit") {
      setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) - 1 }));
    }
    seterrorData(errorData => ({ ...errorData, quantity: "" }))
    setEditCount(editCount + 1)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // functions

  function getTNConChange(type) {
    call('POST', 'getjsonfromdb', { "jsonFor": type }).then((result) => {
      console.log("getjsonfromdb:=>", result);
      setdataTnc(result.json_data)
      settncOrder(result.order_array.split(":"))
    }).catch((e) => {
      console.log("Error while querying getjsonfromdb:", e);
    })
  }

  function fillTNCValue() {

    let finalTnc = { ...dataTnc }

    let keyArray = Object.keys(finalTnc).length ? Object.keys(finalTnc) : []
    for (let i = 0; i < keyArray.length; i++) {
      finalTnc[keyArray[i]].value = insertVariable(finalTnc[keyArray[i]].value)
    }
    //console.log("fillTNCValue finalTnc in contract -->", finalTnc)
    setdataTnc(finalTnc)
  }

  function insertVariable(tncData) {
    const replaceData = {
      '{price}': data.currency ? data.currency.split(':')[1] : '' + data.price,
      '{currency}': data.currency ? data.currency.split(':')[1] : '',
      '{priceinwords}': price_in_words(data.price),
      '{priceWords}': price_in_words(data.price),
      '{grossCalorificVal}': ((data.commodity && data.commodity.split(':')[0] === "Coal") ? spec.grosscalorificvalue.value : ''),
      '{supplierAddress}': data.supplierAddress,
      '{commodity}': (data.commodity ? data.commodity.split(':')[0] : "Commodity"),
      '{quantity}': data.quantity,
      '{loadingPort}': data.loadingPort + "," + data.loadingCountry.split(':')[0],
      '{laycan}': formatDate_Application(data.laycanStart) + " - " + formatDate_Application(data.laycanEnd)
    };

    let keyArray = Object.keys(replaceData)
    for (let i = 0; i < keyArray.length; i++) {
      tncData = replaceGlobally(tncData, keyArray[i], replaceData[keyArray[i]])
    }

    return tncData;

    function replaceGlobally(original, searchTxt, replaceTxt) {
      const regex = new RegExp(searchTxt, 'g');
      return original.replace(regex, replaceTxt);
    }
  }


  function getSupplierCommodities(event, supplierId) {
    event.persist();
    setData(data => ({ ...data, "commodity": "" }));
    if (!event.target.value) {
      setsupplierCommodityList([])
      return null
    }
    call('post', 'getusercommoditylist', { "userId": supplierId, "commCatId": event.target.value === "All" ? "" : event.target.value }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      setsupplierCommodityList(result)
    }).catch((e) => {
      console.log('error in getusercommoditylist', e);
    })
  }

  function getSelectName(id, value) {
    let sel = document.getElementById(id);
    if (sel) {
      for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].value / 1 === value / 1) {
          return sel.options[i].text
        }
      }
    } else {
      return '--'
    }

  }

  function loadIndexPrice(event) {
    event.persist();
    if ((event.target.value / 1) === 2) {
      call('POST', 'getcommodityindexprice', { commodity: data.commodity ? data.commodity.split(':')[1] : data.commodity ? data.commodity.split(':')[1] : 0 }).then((result) => {
        // console.log("result in getcommodityindexprice api->", result)
        setData(data => ({
          ...data,
          price: result.lastPrice,
          currency: result.priceUnit
        }));
      }).catch((e) => {
        console.log("Error while querying getcommodityindexprice:", e);
        toastDisplay(e, "info")
      })
    }
  }

  function searchSupplier() {
    call('POST', 'getUsersListing', { "searchUserType": (traderActiveType === 'buyer') ? 4 : 3, "searchKey": supplierSearch, "network": "innetwork", "userAvatars": true }).then((result) => {
      // console.log("result of api in getUsersListing-->", result)
      setDbSupplier(result)
    }).catch((e) => {
      console.log('error in getUsersListing', e);
    })
  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Submit Handlers
  const handleSubmit = () => {
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })

    formData.append("specification", JSON.stringify(spec));
    formData.append("contractTnc", JSON.stringify(dataTnc));
    formData.append("docCategoryACL", JSON.stringify(docCategoryACL));
    formData.append("orderJson", JSON.stringify({ specOrder: specOrder && specOrder.length ? specOrder.join(":") : "", tncOrder: tncOrder.join(":") }));

    call('POST', 'initContract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        //toastDisplay("Operation Success", "success")
        setContractState(false, { refresh: true })
        setrefresh(refresh + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      // setContractState()
    })
  }

  const agreement = () => {

    let formData = new FormData();

    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("userTypeClient", traderActiveType ? traderActiveType : '');
    formData.append("contract_id", data.contract_id);
    formData.append("contract_number", data.contract_number);

    call('POST', 'agreeContract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setContractState(false, { refresh: true })
      }
    }).catch(e => {
      console.log("Error while arreeing contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  const handleSubmitUpdate = (operator) => {

    var formData = new FormData();
    Object.keys(dataUpdate).forEach((key) => {
      formData.append(key, dataUpdate[key]);
    })

    let showToStringArrray = data.exporter_id + "," +
      data.importer_id + "," +
      data.investigationAgencyImporter / 1 + "," +
      data.investigationAgencyExporter / 1 + "," +
      data.investigationAgencythird / 1 + "," +
      data.commodityInsurer / 1 + "," +
      data.tradeInsurer / 1 + "," +
      data.supplierBank / 1 + "," +
      data.buyerBank / 1

    formData.append("showToStringArrray", showToStringArrray);
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);
    formData.append("userTypeClient", traderActiveType ? traderActiveType : '');
    formData.append("contract_id", data.contract_id);
    formData.append("contract_number", data.contract_number);
    if (specChange / 1 > 0) {
      formData.append("specification", JSON.stringify(spec));
    }
    if (tncChange / 1 > 0) {
      formData.append("contractTnc", JSON.stringify(dataTnc));
    }

    if (specChange / 1 > 0 || tncChange / 1 > 0) {
      formData.append("orderJson", JSON.stringify({ specOrder: specOrder ? specOrder.join(":") : "", tncOrder: tncOrder.join(":") }));
    }

    formData.append("docCategoryACL", JSON.stringify(docCategoryACL));
    formData.append("specChange", specChange);
    formData.append("tncChange", tncChange);


    formData.delete("supplierAvatar")
    formData.delete("buyerAvatar")
    // console.log('Here --->>');
    call('POST', 'updateContract', formData).then((result) => {
      if (result) {
        if (operator === "U&A") {
          agreement()
        } else {
          setshowLoader(false)
          setContractState(false, { refresh: true })
        }
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }


  function raiseContractEditRequest() {
    let formData = new FormData();
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("contract_id", data.contract_id);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);

    call('POST', 'raisecontracteditrequest', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setContractState(false, { refresh: true })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  function openContractEdit(edit) {
    let formData = new FormData();
    formData.append("loggedUserID", userId);
    formData.append("importer_id", data.importer_id);
    formData.append("exporter_id", data.exporter_id);
    formData.append("contract_id", data.contract_id);
    formData.append("editSingle", edit);

    call('POST', 'opencontractforedit', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setContractState(false, { refresh: true })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // TNC Components

  function tncPreviewGenerator() {
    let keyArray = Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    return (
      <ol className="terms-view-ul">
        {keyArray.map((key, index) => {
          return (
            <li>
              <label className="date-format">{dataTnc[key].name ? dataTnc[key].name : "--"} </label>
              <p>{dataTnc[key].value ? dataTnc[key].value.replace(/<br>/g, "\n") : "--"}</p>
            </li>
          )
        })}
      </ol>
    )
  }

  function getUserArray() {
    let usersArray = [
      {
        "userId": data.exporter_id,
        "userName": data.supplierName ? data.supplierName : data.supplier
      },
      {
        "userId": data.importer_id,
        "userName": data.buyerName ? data.buyerName : data.buyerEmail
      },
      {
        "userId": data.investigationAgencyExporter,
        "userName": getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)
      },
      {
        "userId": data.investigationAgencyImporter,
        "userName": getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)
      },
      {
        "userId": data.investigationAgencythird,
        "userName": getSelectName('investigationAgencythird', data.investigationAgencythird)
      },
      {
        "userId": data.commodityInsurer,
        "userName": getSelectName('commodityInsurer', data.commodityInsurer)
      },
      {
        "userId": data.tradeInsurer,
        "userName": getSelectName('tradeInsurer', data.tradeInsurer)
      },
      {
        "userId": data.supplierBank,
        "userName": getSelectName('supplierBank_select', data.supplierBank)
      },
      {
        "userId": data.buyerBank,
        "userName": getSelectName('buyerBank_select', data.buyerBank)
      }
    ]

    let mapArray = [], finalArray = []
    for (let i = 0; i < usersArray.length; i++) {
      // console.log("here is id usersArray[i]===>", usersArray[i])
      if (usersArray[i].userId / 1 !== 0) {
        if (!mapArray.includes(usersArray[i].userId)) {
          finalArray.push(usersArray[i])
          mapArray.push(usersArray[i].userId)
        }
      }
    }
    // console.log("here is id finalArray===>", finalArray)
    return finalArray

  }


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //  Mini Preview Component
  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left">{data.supplierName ? data.supplierName : '--'}</div>
            <div className="price-right">Exporter </div>
          </li>
          <li>
            <div className="price-left"> {data.commodity ? ((data.commodity + "'").split(':')[0]) : data.commodity_name ? data.commodity_name : '--'} </div>
            <div className="price-right"> Commodity</div>
          </li>
          <li>
            <div className="price-left">{data.contractType ? data.contractType : data.contract_type}</div>
            <div className="price-right"> Contract Type</div>
          </li>
          <li>
            <div className="price-left"> {data.laycanStart ? data.laycanStart : '--'}</div>
            <div className="price-right">Laycan Start</div>
          </li>
          <li>
            <div className="price-left"> {data.buyerName ? data.buyerName : '--'}</div>
            <div className="price-right">Importer</div>
          </li>
          <li>
            <div className="price-left">{data.price ? data.price : '--'}</div>
            <div className="price-right">{data.priceType / 1 === 1 ? "Commodity Price/mt" : data.priceType / 1 === 2 ? "Current Index Price" : "Commodity Price"}</div>
          </li>
          <li>
            <div className="price-left">{data.quantity ? data.quantity : '--'}</div>
            <div className="price-right">Quantity</div>
          </li>
          <li>
            <div className="price-left"> {data.laycanEnd ? data.laycanEnd : '--'}</div>
            <div className="price-right">Laycan End</div>
          </li>
        </ul>
      </div>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //agency list
  function getinvestigationAgencyList() {
    let investigationAgencyList = agency ? agency.map((value, index) => {
      return (
        <option value={value.tbl_user_id} >{value.company_name}</option>
      )
    }) : ''
    return investigationAgencyList
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //agency list
  function getinsuranceAgencyList() {
    let insurersList = (insurers && insurers.length) ? insurers.map((insurers) => {
      return (<option value={insurers.tbl_user_id}>{insurers.company_name}</option>)
    }) : ''
    return insurersList
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Payment options list
  function getPaymentOptionslist() {
    let PaymentOptionslist = paymentModes ? paymentModes.map((value, index) => {
      return (
        <>
          <div className="switch-field ml-1 mr-1">
            <input type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id} checked={(data.paymentMode / 1 === value.id) ? true : false} onChange={handleChange} />
            <label for={"paymentMode" + value.id}>{value.payment_type_pretty}</label>
          </div>
        </>
      )
    }) : ''
    return PaymentOptionslist
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //suppliers list
  function getsuppliersList() {
    let suppliersList = dbSupplier && dbSupplier.length ? dbSupplier.map((value, index) => {  // Logistic will be dbSupplier
      return (
        <>
          <div className="chat-message clearfix" onClick={() => { handleSelectedSupplier(value); handleSupplierClose() }}>
            <img src={avatarUrl(value.user_avatar)} alt="description" width="32" height="32" />
            <div className="chat-message-content clearfix">
              <h5>{value ? value.company_name : '--'}</h5>
              <p>
                {value ? <span className="shadow" >
                  <ReactCountryFlag
                    countryCode={value.country_code ? value.country_code : ''}
                    style={{ width: '25px', height: '25px' }} svg />
                </span> : '--'}
                <span className="pl-1 pr-1" >-</span>{value.user_address ? value.user_address : ''}
              </p>
            </div>
            <button className="edit-icon"><i class="fas fa-edit text-primary"></i></button>
          </div>

        </>)
    }) : 'No Connections Found in Network'
    return suppliersList
  }
  //---------------------------------------------------------------------------------------------------------------------


  var loadbanks = (buyerEmail, sellerEmail) => {
    // call('POST', 'getUserBanks', { email: sellerEmail }).then((result) => {
    //   // console.log('api result for getUserBanks supplierEmail load', result);
    //   setSellerBank(result)
    // }).catch((e) => {
    //   console.log("Error while querying getUserBanks for seller:", e);
    // })

    // call('POST', 'getUserBanks', { email: buyerEmail }).then((result) => {
    //   //console.log('api result for getUserBanks supplierEmail load', result);
    //   setBuyerBank(result)
    // }).catch((e) => {
    //   console.log("Error while querying getUserBanks for buyer:", e);
    // })

    call('GET', 'getBanksListMaster', {}).then((result) => {
      //console.log('api result for getUserBanks supplierEmail load', result);
      setBuyerBank(result)
      setSellerBank(result)
    }).catch((e) => {
      console.log("Error while querying getUserBanks for buyer:", e);
    })
  }



  //---------------------------------------------------------------------------------------------------------------------
  //supplierBankList
  function getsupplierBankList() {
    let supplierBankList = sellerBank && sellerBank.length ? sellerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return supplierBankList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //buyerBankList
  function getbuyerBankList() {
    let buyerBankList = buyerBank && buyerBank.length ? buyerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return buyerBankList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Ports List
  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort unloadPort', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //commodityList
  function getcommodityList() {
    let commodityList = supplierCommodityList && supplierCommodityList.length ? supplierCommodityList.map((value, index) => {
      return (
        <>
          <li className={"nav-link mr-2" + ((data.commodity ? data.commodity.split(':')[0] : '') === value.commodity_pretty_name ? ' active' : '')}>
            <span className="field">
              <input type="radio" id={"checkbox" + index} name="commodity" value={value.commodity_pretty_name + ':' + value.id}
                onChange={(event) => {
                  handleCommodity();
                  handleChange(event);
                  setSpecification(value.specifications ? value.specifications : {});
                  setspecOrder(value.spec_order_string ? value.spec_order_string.split(":") : "")
                }} />
              <label for={"checkbox" + index}>
                {value.commodity_pretty_name}
                <span className="cate-img">
                  <img src={commImageUrl(value.filename)} className="img-responsive" alt="description" />
                </span>
                <br />
                <hr />
                <label className="text-center pt-0 mt-0">{value.category}</label>
              </label>
            </span>
          </li>
        </>
      )
    }) : ''
    return commodityList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // contract listing from api data

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  console.log("Data in contract modal-->", data)
  // console.log("docCategoryACL-->", docCategoryACL)
  // console.log("datatnc in contract -->", dataTnc)
  // console.log("contractEditMode in contract -->", contractEditMode)
  //console.log("select component-->", document.getElementById('investigationAgencyImporter'))

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {quotaAvail &&
        <>
          <div className={"modal" + (activeClass < ((userTypeId === 14) ? 7 : 6) ? " show" : "")} id="CreateContract">
            <div className="modal-dialog d-flex modal-xl border-inner" id="parent_class">
              <div className="modal-content">

                <div className="modal-header primary">
                  <h4 className="modal-title text-white">
                    {(contractState.info.modalMode === "create") ? "Create Contract" :
                      (contractState.info.modalMode === "edit") ? "Edit/Update Contract" :
                        (contractState.info.modalMode === "view" ? "View Contract" : "--")}
                    {(" - ") + ((userTypeId === 3) ? "Buyer/Importer" :
                      (userTypeId === 4) ? "Seller/Exporter" :
                        (userTypeId === 14 && traderActiveType === 'buyer') ? "Buyer/Importer" :
                          (userTypeId === 14 && traderActiveType === 'seller') ? "Seller/Exporter" : '')
                    }
                  </h4>
                  <button type="button" className="close" onClick={() => setContractState(false, {})}>×</button>
                </div>
                <div className="modal-body calc-modal">
                  {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                  {/* <div className="modal-body calc-modal"> */}
                  <div id="tabs1" className="tab-contents no-js">
                    <div className="tab-nav">
                      <li id="1" className={"tab-link" + (activeClass === 1 ? " is-active" : "") + ((1 < maxactiveClass) ? " is-completed" : "")} onClick={(1 <= maxactiveClass) ? () => handleActiveClass(1) : ""}>Contract info</li>
                      <li id="2" className={"tab-link" + (activeClass === 2 ? " is-active" : "") + ((2 < maxactiveClass) ? " is-completed" : "")} onClick={(2 <= maxactiveClass) ? () => handleActiveClass(2) : ""}>Specification</li>
                      <li id="3" className={"tab-link" + (activeClass === 3 ? " is-active" : "") + ((3 < maxactiveClass) ? " is-completed" : "")} onClick={(3 <= maxactiveClass) ? () => handleActiveClass(3) : ""}>Quantity & Price</li>
                      {/* <li id="4" className={"tab-link" + (activeClass === 4 ? " is-active" : "") + ((4 < maxactiveClass) ? " is-completed" : "")} onClick={(4 <= maxactiveClass) ? () => handleActiveClass(4) : ""}>Investigation</li>
                      <li id="5" className={"tab-link" + (activeClass === 5 ? " is-active" : "") + ((5 < maxactiveClass) ? " is-completed" : "")} onClick={(5 <= maxactiveClass) ? () => handleActiveClass(5) : ""}>Insurance</li> */}
                      <li id="4" className={"tab-link" + (activeClass === 4 ? " is-active" : "") + ((4 < maxactiveClass) ? " is-completed" : "")} onClick={(4 <= maxactiveClass) ? () => handleActiveClass(4) : ""}>Bank Details</li>
                      <li id="5" className={"tab-link" + (activeClass === 5 ? " is-active" : "") + ((5 < maxactiveClass) ? " is-completed" : "")} onClick={(5 <= maxactiveClass) ? () => handleActiveClass(5) : ""}>T&C</li>
                      {(userTypeId === 14) && <li id="6" className={"tab-link" + (activeClass === 6 ? " is-active" : "") + ((6 < maxactiveClass) ? " is-completed" : "")} onClick={(6 <= maxactiveClass) ? () => handleActiveClass(6) : ""}>Documents ACL</li>}
                    </div>
                    <div className={"tab-content" + (activeClass === 1 ? " is-active" : "")}>

                      <div className="calc-inner-modal">
                        {(contractState.info.modalMode === "create") ? <>
                          <div className="d-flex">
                            <div className="col-md-12 row m-0 p-0">
                              <div className="col-md-6 row">
                                <div className="col-md-5">
                                  <h3>Contract Name {astrix} {toolTip('contractAlias')}</h3>
                                </div>
                                <div className="col-md-7 pr-0">
                                  <div className="form-group">
                                    <input type="text" name="contractAlias" placeholder="Enter Contract Name" value={data.contractAlias} className={"form-control" + (!errorData.contractAlias ? '' : ' border-danger')} onChange={handleChange} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 row">
                                <div className="col-md-6">
                                  <h3>Contract Type {astrix} {toolTip('contractType')}</h3>
                                </div>
                                <div className="ml-auto">
                                  <div className="switch-field">
                                    <input type="radio" id="fob" name="contractType" value="fob" defaultChecked={contract.state === 0 ? 'checked' : ''} onClick={() => handleContract(0, 'FOB')} />
                                    <label for="fob">FOB</label>
                                    <input type="radio" id="cif" name="contractType" value="cif" defaultChecked={contract.state === 1 ? 'checked' : ''} onClick={() => handleContract(1, 'CIF')} />
                                    <label for="cif" className="mr-0">CIF</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 border-top border-bottom pt-3">
                            <div className="form-group row d-flex align-items-center">
                              <label className="col-md-3 m-0">
                                {(traderActiveType === 'buyer') ? "Seller/Exporter" : "Buyer/Importer"} {astrix} {toolTip((traderActiveType === 'buyer') ? 'supplier' : 'buyer')}
                              </label>
                              <div className="ml-auto pr-3 mb-0" >
                                <div id="SelectValue"> {!selectedSupplier.state && <div className="btn btn-primary" onClick={() => handleSupplierShow()}> {(traderActiveType === 'buyer') ? "Select Seller/Exporter" : "Select Buyer/Importer"}</div>}
                                  {selectedSupplier.state && <div className="chat-message">
                                    <img src={avatarUrl(supplierAvatar)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix" >
                                      <h5>{selectedSupplier.name}</h5>
                                      <div className="col-md-12 row">
                                        <p className="date-format p-0 m-0 col-md-11" style={{ "max-width": "500px", "font-size": "10px" }}>{selectedSupplier.address}</p>
                                        <p className="date-format p-0 m-0 col-md-1">{selectedSupplier.location ?
                                          <span className="shadow">
                                            <ReactCountryFlag
                                              countryCode={selectedSupplier.location ? selectedSupplier.location : ''}
                                              style={{ width: '25px', height: '25px' }} svg />
                                          </span> : '--'}
                                        </p>
                                      </div>

                                    </div>
                                    <button className="edit-icon" onClick={() => handleSupplierShow()}><i class="fas fa-edit text-primary"></i> </button>
                                  </div>}
                                  {errorData.supplier && <p className="text-danger error-contract">{errorData.supplier}</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-padding">
                            {selectedSupplier.state &&
                              <>
                                {/* <div className="col-md-12 row">
                                <div className="col-md-6">
                                  <h3 className="mt-3"> Commodity {toolTip('commodity')}</h3>
                                </div>
                                <div className="col-md-6">
                                  <select className={" form-control" + (!errorData.commCatId ? '' : ' border-danger')} name="commCatId" value={data.commCatId} onChange={(event) => { handleChange(event); getSupplierCommodities(event, selectedSupplier.supplierId); }}>
                                    <option value="0" selected>--Select Commodity Type--</option>
                                    <option value="">Show All Commodity</option>
                                    {
                                      commCategory.length ? commCategory.map((item, index) => {
                                        return (<option value={item.id}>{item.category}</option>)
                                      }) : ''
                                    }
                                  </select>
                                </div>
                              </div> */}
                                <h3 className="mt-3"> Commodity {astrix}  {toolTip('commodity')}</h3>
                                <div className='row mx-2'>
                                  <div className="col-md-4 pl-0 mt-2 mb-2">
                                    <select className={" form-control" + (!errorData.commCatId ? '' : ' border-danger')} name="commCatId" value={data.commCatId} onChange={
                                      (event) => {
                                        handleChange(event);
                                        getSupplierCommodities(event, (traderActiveType === 'buyer') ? selectedSupplier.supplierId : userId);
                                      }}>
                                      <option value="" selected>--Select Commodity Type/Category--</option>
                                      <option value="All">Show All Commodity</option>
                                      {
                                        commCategory.length ? commCategory.map((item, index) => {
                                          return (<option value={item.id}>{item.category}</option>)
                                        }) : ''
                                      }
                                    </select>
                                    {errorData.commCatId && <p className="text-danger error-contract ml-2">{errorData.commCatId}</p>}
                                  </div>
                                  <div className="col-md-4 pl-0 mt-2 mb-2">
                                    <select className={" form-control" + (!errorData.commodity ? '' : ' border-danger')} name="commodity"
                                      value={data.commodity}
                                      onChange={
                                        (event) => {
                                          handleCommodity();
                                          handleChange(event);
                                        }}>
                                      <option value="">--Select Commodity Name</option>
                                      {
                                        supplierCommodityList.length ? supplierCommodityList.map((item, index) => {
                                          return (<option value={item.commodity_pretty_name + ':' + item.id}>{item.commodity_pretty_name}</option>)
                                        }) : ''
                                      }
                                    </select>
                                    {errorData.commodity && <p className="text-danger error-contract ml-2">{errorData.commodity}</p>}
                                  </div>
                                </div>

                                {/* <ul className="category-ul d-flex flex-wrap justify-content-left" id="category-ul">
                                  {
                                    getcommodityList()
                                  }
                                </ul> */}
                                {/* {errorData.commodity && <p className="text-danger error-contract">{errorData.commodity}</p>} */}
                              </>}
                          </div>
                        </> : <>
                          <div className="d-flex">
                            <div className="col-md-12 row m-0 p-0">
                              <div className="col-md-6 row">
                                <div className="col-md-5">
                                  <h3>Contract Name {astrix} {toolTip('contractAlias')}</h3>
                                </div>
                                <div className="col-md-7 pr-0">
                                  <div className="form-group">
                                    <input type="text" name="contractAlias" placeholder="Enter Contract Name" value={data.contractAlias} className={"form-control" + (!errorData.contractAlias ? '' : ' border-danger')} onChange={handleChange} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 row">
                                <div className="col-md-6">
                                  <h3>Contract Type {astrix} </h3>
                                </div>
                                <div className="ml-auto">
                                  <div className="switch-field">
                                    {data.contract_type === 'FOB' && <><input type="radio" id="fob" name="contract_type" value="fob" checked />
                                      <label for="fob">FOB</label> </>}
                                    {data.contract_type === 'CIF' && <><input type="radio" id="cif" name="contract_type" value="cif" checked />
                                      <label for="cif" className="mr-0">CIF</label></>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 border-top border-bottom pt-3">
                            <div className="row">
                              <div className="col-md-6 ">
                                <div className="mr-auto pr-3 form-group">
                                  <label className="col-md-12">Buyer/Importer</label>
                                  <div className="chat-message pl-2">
                                    <img src={avatarUrl(data.buyerAvatar)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix pt-2">
                                      <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span><br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 ">
                                <div className="mr-auto pr-3 form-group">
                                  <label className="col-md-12">Seller/Exporter</label>
                                  <div className="chat-message pl-2">
                                    <img src={avatarUrl(data.supplierAvatar)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix pt-2">
                                      <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span><br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-padding">
                            <div id="Check-box" className="mt-3">
                              <h3 className="mt-3 ml-2"> Commodity </h3>
                              <div className="com-md-12 row">
                                <div className="com-md-4 ml-5 text-center">
                                  {data.commFilename && <img width="100" src={commImageUrl(data.commFilename)} className="img-responsive" alt="description" />}
                                  <h3>{data.commodity_name}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>}

                        <div className="modal-padding">
                          {commodity && <>
                            <hr className="mb-3" />
                            <div id="Check-box">
                              <div className=" mt-2">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="">Laycan Start Date {astrix} {toolTip('laycanStart')}</label>
                                    <input type="date" min={todayDate_DatePicker(true)} name="laycanStart" value={data.laycanStart}
                                      className={" form-control" + (!errorData.laycanStart ? '' : ' border-danger')} onChange={handleChange} onBlur={verifyDate} required />
                                    {errorData.laycanStart && <p className="text-danger error-contract">{errorData.laycanStart}</p>}
                                  </div>
                                  <div className="col-md-6">
                                    <label className="">Laycan End Date {astrix} {toolTip('laycanEnd')}</label>
                                    <input type="date" min={todayDate_DatePicker(true)} name="laycanEnd" value={data.laycanEnd}
                                      className={" form-control" + (!errorData.laycanEnd ? '' : ' border-danger')} onChange={handleChange} onBlur={verifyDate} required disabled={data.laycanStart ? false : true} />
                                    {errorData.laycanEnd && <p className="text-danger error-contract">{errorData.laycanEnd}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="mt-3 mb-3" />

                            <div className="row">
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">Loading Country {astrix} {toolTip('loadingCountry')}</label>
                                  <div className="col-md-12">
                                    <select className={" form-control" + (!errorData.loadingCountry ? '' : ' border-danger')} name="loadingCountry" value={data.loadingCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                                      <option value="" selected>--Select country--</option>
                                      {/* <CountryList /> */}
                                      {
                                        countryData.length ? countryData.map((item, index) => {
                                          return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                        }) : ''
                                      }
                                    </select>
                                    {errorData.loadingCountry && <p className="text-danger error-contract">{errorData.loadingCountry}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">Loading Port {astrix} {toolTip('loadingPort')}</label>
                                  <div className="col-md-12">
                                    <select className={" form-control" + (!errorData.loadingPort ? '' : ' border-danger')} name="loadingPort" value={data.loadingPort} onChange={handleChange}>
                                      <option value="" selected>--Select loading port--</option>
                                      {portLoading.map((portLoading) => {
                                        return (
                                          <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                        )
                                      })}
                                    </select>
                                    {errorData.loadingPort && <p className="text-danger error-contract">{errorData.loadingPort}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">Unloading Country {astrix} {toolTip('unloadingCountry')}</label>
                                  <div className="col-md-12">
                                    <select className={" form-control" + (!errorData.unloadingCountry ? '' : ' border-danger')} name="unloadingCountry" value={data.unloadingCountry} onChange={(event) => { handleChange(event); unloadPort(event); }}>
                                      <option value="" selected>--Select country--</option>
                                      {/* <CountryList /> */}
                                      {
                                        countryData.length ? countryData.map((item, index) => {
                                          return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                        }) : ''
                                      }
                                    </select>
                                    {errorData.unloadingCountry && <p className="text-danger error-contract">{errorData.unloadingCountry}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">Unloading Port {astrix} {toolTip('unloadingPort')}</label>
                                  <div className="col-md-12">
                                    <select className={" form-control" + (!errorData.unloadingPort ? '' : ' border-danger')} name="unloadingPort" value={data.unloadingPort} onChange={handleChange}>
                                      <option value="" selected>--Select Unloading port--</option>
                                      {
                                        portUnloading.map((portUnloading) => {
                                          return (
                                            <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                                          )
                                        })
                                      }
                                    </select>
                                    {errorData.unloadingPort && <p className="text-danger error-contract">{errorData.unloadingPort}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <AddPortComponent countryList={countryData} />
                          </>}
                        </div>
                      </div>

                    </div>
                    <div className={"tab-content" + (activeClass === 2 ? " is-active" : "")}>
                      <div className="calc-inner-modal">
                        {miniPreview()}
                        <hr />
                        {/* Commodity Form Component Start */}
                        {errorData.Specification && <p className="text-danger text-center">{errorData.Specification}</p>}
                        {((data.commodity || data.commodity_id) && activeClass === 2) && <SpecificationsForm
                          commodity={data.commodity ? data.commodity.split(':')[1] : data.commodity_id}
                          spec={spec}
                          handleSpecification={handleSpecification}
                          setSpecification={setSpecification}
                          specOrder={specOrder}
                          setspecOrder={setspecOrder}
                          contMode={contractState.info.modalMode}
                          editCount={editCount}
                          setEditCount={setEditCount}
                          editLevel={true}
                          masterid={false} />}
                        {/* Commodity Form Component End */}
                      </div>
                    </div>
                    <div className={"tab-content" + (activeClass === 3 ? " is-active" : "")}>
                      <div className="calc-inner-modal">
                        {miniPreview()}
                        <hr />
                        <div className="modal-padding">
                          <div className="row">
                            <label className="col-md-3">Commodity Price/mt ( metric tonne* ) {astrix} {toolTip('priceType')}</label>
                            <div className="col-md-3">
                              <div className="form-group">

                                <select className={" form-control" + (!errorData.priceType ? '' : ' border-danger')} name="priceType" value={data.priceType}
                                  onChange={(event) => { handleChange(event); loadIndexPrice(event) }}>
                                  <option value="" selected>--Select Price Type--</option>
                                  <option value="1"> Fixed Price</option>
                                  <option value="2">Index Price</option>
                                </select>
                                {errorData.priceType && <p className="text-danger error-contract">{errorData.priceType}</p>}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="row">
                                {(data.priceType / 1) === 2 ? <>
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <input type="number" step="0.01" name="price" value={data.price} placeholder="Price" className={" form-control" + (!errorData.price ? '' : ' border-danger')} onChange={handleChange} disabled={(data.priceType / 1) === 2 ? true : false} />
                                      {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                                    </div>
                                  </div>
                                  <span className="col-md-2">+-</span>
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <input type="number" name="priceTolerance" value={data.priceTolerance} placeholder="" className={" form-control" + (!errorData.priceTolerance ? '' : ' border-danger')} onChange={handleChange} />
                                      {errorData.priceTolerance && <p className="text-danger error-contract">{errorData.priceTolerance}</p>}
                                    </div>
                                  </div>
                                </> : <>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input type="number" step="0.01" name="price" value={data.price} placeholder="Enter Price" className={" form-control" + (!errorData.price ? '' : ' border-danger')} onChange={handleChange} />
                                      {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                                    </div>
                                  </div>
                                </>}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <select className={" form-control" + (!errorData.currency ? '' : ' border-danger')} name="currency" value={data.currency} onChange={handleChange} disabled={(data.priceType / 1) === 2 ? true : false}>
                                  <option value="" selected>--Select Currency--</option>
                                  {currencyData.map((currency) => {
                                    return (
                                      <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                                    )
                                  })}
                                </select>
                                {errorData.currency && <p className="text-danger error-contract">{errorData.currency}</p>}
                              </div>
                            </div>

                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <div className="form-group d-flex align-items-center mb-0 pb-2">
                                <label for="message-text" className="col-form-label">Add Quantity {astrix} {toolTip('quantity')}</label>
                                <span className="ml-3">(mt)</span>
                                <div className="ml-auto">
                                  <button type="button" onClick={() => { handleDec() }} value="-" className="edit-icon"><i class="fas fa-minus text-primary"></i></button>
                                  <input type="number" name="quantity" placeholder="0" min="0" size="1" id="quantity" value={data.quantity} className={" text-center increment" + (!errorData.quantity ? '' : ' border-danger')} onChange={handleChange} />
                                  <button type="button" onClick={() => { handleInc() }} value="+" className="edit-icon"><i class="fas fa-plus text-primary"></i></button>
                                </div>
                              </div>
                              <p className="text-primary">(Tolerance = +/- 10%)</p>
                              {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                            </div>
                          </div>
                          <hr />
                          <div className="row d-flex mt-5">
                            <label className="col-md-6">Total Amount = Commodity Price/mt x Quantity</label>
                            <div className="col-md-6 ">
                              <h4 className="text-primary text-right" ><span >{data.price && data.quantity && data.currency ? (data.currency.split(':')[1]) : ''} </span>{data.price && data.quantity ? Intl.NumberFormat('en-IN').format(data.price * data.quantity) : 0}</h4>
                              <p className="text-primary text-right" >{data.price && data.quantity ? price_in_words(data.price * data.quantity) : ''}<span >{data.price && data.quantity && data.currency ? (data.currency.split(':')[0]) : ''} </span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className={"tab-content" + (activeClass === 4 ? " is-active" : "")}>
                      <div className="calc-inner-modal">
                        {miniPreview()}
                        <hr />
                        <div className="modal-padding">
                          <div className="mt-3 row">
                            <label className="col-md-6">Importer's Investigation Agency {astrix} {toolTip('investigationAgencyImporter')}</label>
                            <div className="col-md-6">
                              <select className={"form-control" + (!errorData.investigationAgencyImporter ? '' : ' border-danger')} name="investigationAgencyImporter" value={data.investigationAgencyImporter}
                                onChange={handleChange} id="investigationAgencyImporter">
                                <option value="" selected>--Select Agency--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinvestigationAgencyList()
                                }
                              </select>
                              {errorData.investigationAgencyImporter && <p className="text-danger error-contract">{errorData.investigationAgencyImporter}</p>}
                            </div>
                          </div>
                          <div className=" mt-3 row">
                            <label className="col-md-6">Exporter's Investigation Agency {astrix} {toolTip('investigationAgencyExporter')}</label>
                            <div className="col-md-6">

                              <select className={"form-control" + (!errorData.investigationAgencyExporter ? '' : ' border-danger')} name="investigationAgencyExporter" value={data.investigationAgencyExporter}
                                onChange={handleChange} id="investigationAgencyExporter">
                                <option value="" selected>--Select Agency--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinvestigationAgencyList()
                                }
                              </select>
                              {errorData.investigationAgencyExporter && <p className="text-danger error-contract">{errorData.investigationAgencyExporter}</p>}
                            </div>
                          </div>
                          <div className=" mt-3 row">
                            <label className="col-md-6">Third Party Investigation Agency {astrix} {toolTip('investigationAgencythird')}</label>
                            <div className="col-md-6">

                              <select className={"form-control" + (!errorData.investigationAgencythird ? '' : ' border-danger')} name="investigationAgencythird" value={data.investigationAgencythird}
                                onChange={handleChange} id="investigationAgencythird">
                                <option value="" selected>--Select Agency--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinvestigationAgencyList()
                                }
                              </select>
                              {errorData.investigationAgencythird && <p className="text-danger error-contract">{errorData.investigationAgencythird}</p>}
                            </div>
                          </div>
                          <hr />
                          <div className="row d-flex mt-5">
                            {(data.investigationAgencyExporter / 1 > 0 && (document.getElementById('investigationAgencyExporter'))) &&
                              <div className="d-flex col-md-12 mb-1">
                                <label className="col-md-6 pl-0">Investigation at time of Loading will be conducted by</label>
                                <h3 className="text-primary ml-auto" > {getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)}</h3>
                              </div>}
                            {(data.investigationAgencyImporter / 1 > 0 && (document.getElementById('investigationAgencyImporter'))) &&
                              <div className="d-flex col-md-12 mb-1">
                                <label className="col-md-6 pl-0">Investigation at time of Unloading will be conducted by</label>
                                <h3 className="text-primary ml-auto" > {getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)}</h3>
                              </div>}
                            {(data.investigationAgencythird / 1 > 0 && (document.getElementById('investigationAgencythird'))) &&
                              <div className="d-flex col-md-12 mb-1">
                                <label className="col-md-6 pl-0">Third Party Investigation will be conducted by</label>
                                <h3 className="text-primary ml-auto" > {getSelectName('investigationAgencythird', data.investigationAgencythird)}</h3>
                              </div>}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className={"tab-content" + (activeClass === 5 ? " is-active" : "")}>
                      <div className="calc-inner-modal">
                        {miniPreview()}
                        <hr />
                        <div className="modal-padding">
                          <div className="mt-3 row">
                            <label className="col-md-6">Commodity Insurance by {astrix} {toolTip('commodityInsurer')}</label>
                            <div className="col-md-6">
                              <select className={"form-control" + (!errorData.commodityInsurer ? '' : ' border-danger')} name="commodityInsurer" value={data.commodityInsurer}
                                onChange={handleChange} id="commodityInsurer">
                                <option value="" selected>--Select Insurer--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinsuranceAgencyList()
                                }
                              </select>
                              {errorData.commodityInsurer && <p className="text-danger error-contract">{errorData.commodityInsurer}</p>}
                            </div>
                          </div>

                          <div className="mt-3 row">
                            <label className="col-md-6">Trade Insurance by {astrix} {toolTip('tradeInsurer')}</label>
                            <div className="col-md-6">
                              <select className={"form-control" + (!errorData.tradeInsurer ? '' : ' border-danger')} name="tradeInsurer" value={data.tradeInsurer}
                                onChange={handleChange} id="tradeInsurer">
                                <option value="" selected>--Select Insurer--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinsuranceAgencyList()
                                }
                              </select>
                              {errorData.tradeInsurer && <p className="text-danger error-contract">{errorData.tradeInsurer}</p>}
                            </div>
                          </div>

                          <hr />
                          <div className="row d-flex mt-5">
                            {(data.commodityInsurer / 1 > 0 && (document.getElementById('commodityInsurer'))) &&
                              <div className="d-flex col-md-12 mb-1">
                                <label className="col-md-6 pl-0">Commodity insurance will be provided by </label>
                                <h3 className="text-primary ml-auto" > {getSelectName('commodityInsurer', data.commodityInsurer)}</h3>
                              </div>}
                            {(data.tradeInsurer / 1 > 0 && (document.getElementById('tradeInsurer'))) &&
                              <div className="d-flex col-md-12 mb-1">
                                <label className="col-md-6 pl-0">Trade insurance will be provided by </label>
                                <h3 className="text-primary ml-auto" > {getSelectName('tradeInsurer', data.tradeInsurer)}</h3>
                              </div>}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className={"tab-content" + (activeClass === 4 ? " is-active" : "")}>
                      <div className="calc-inner-modal">

                        {miniPreview()}
                        <hr />
                        <div className="modal-padding">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-md-12">Payment Mode {astrix} {toolTip('paymentMode')}</label>
                                <div className="modal-padding row">
                                  {
                                    getPaymentOptionslist()
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* //---------------------------------------------- */}
                          {/* add bank Component */}
                          {addBankObj.modal && <AddBankComponent />}
                          {/* //---------------------------------------------- */}


                          <div className="row" id="Bank1">
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-md-8"> Importer's Bank  {astrix} {toolTip("buyerBank")}</label>
                                <div className="col-md-8">
                                  <select className="form-control" name="buyerBank" value={data.buyerBank} onChange={handleChange} id="buyerBank_select">
                                    <option selected value="">--Select Bank--</option>
                                    <option value="0" >Not Available</option>
                                    {
                                      getbuyerBankList()
                                    }
                                  </select>
                                  {errorData.buyerBank && <p className="text-danger error-contract">{errorData.buyerBank}</p>}
                                </div>
                                <div className="col-md-4">
                                  <div className="modal-padding">
                                    {/* <button className="btn btn-primary float-right btn-sm" onClick={() => setaddBankObj(true, { "email": data.buyerEmail ? data.buyerEmail : '' })} >Add Bank</button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row" id="Bank1">
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-md-8">Exporter's bank {astrix} {toolTip("supplierBank")}</label>
                                <div className="col-md-8">
                                  <select className="form-control" name="supplierBank" value={data.supplierBank} onChange={handleChange} id="supplierBank_select">
                                    <option selected value="">--Select Bank--</option>
                                    <option value="0" >Not Available</option>
                                    {
                                      getsupplierBankList()
                                    }
                                  </select>
                                  {errorData.supplierBank && <p className="text-danger error-contract">{errorData.supplierBank}</p>}
                                </div>
                                <div className="col-md-4">
                                  <div className="modal-padding">
                                    {/* <button className="btn btn-primary float-right btn-sm" onClick={() => setaddBankObj(true, { "email": data.supplierEmail ? data.supplierEmail : '' })} >Add Bank</button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"tab-content" + (activeClass === 5 ? " is-active" : "")}>

                      <div className="calc-inner-modal">
                        {miniPreview()}
                        <hr />
                        {/* <h2>Contract TNC</h2> */}
                        {errorData.tnc && <p className="text-danger text-center">{errorData.tnc}</p>}
                        {(activeClass === 5) ? <TncForm
                          dataTnc={dataTnc}
                          setdataTnc={setdataTnc}
                          tncOrder={tncOrder}
                          settncOrder={settncOrder}
                          contMode={contractState.info.modalMode}
                          editCount={editCount}
                          setEditCount={setEditCount}
                          handleTNC={handleTNC} /> : ''}
                        {errorData.tnc && <p className="text-danger text-center">{errorData.tnc}</p>}
                      </div>
                    </div>

                    <div className={"tab-content" + (activeClass === 6 ? " is-active" : "")}>

                      <div className="calc-inner-modal">
                        {miniPreview()}
                        <hr />
                        {activeClass === 6 ? <DocumentACL
                          userArray={getUserArray()}
                          docCategoryACL={docCategoryACL}
                          setdocCategoryACL={setdocCategoryACL}
                          contractId={data.contract_id ? data.contract_id : null}
                          contractNO={null}
                          dataObj={{ "exporter_id": data.exporter_id / 1, "importer_id": data.importer_id / 1, "importer_bank": data.buyerBank / 1, "exporter_bank": data.supplierBank / 1 }}
                          modalType={contractState.info.modalMode} /> : ""}
                      </div>
                    </div>

                  </div>
                  {/* </div> */}
                </div>
                <div className="modal-footer primary justify-content-start">
                  {(contractState.info.modalMode === "edit" || contractState.info.modalMode === "view") &&
                    <>
                      {(aclMap.contract_access && aclMap.contract_access.cont_changelog && aclMap.contract_access.cont_changelog.isExist) && <span className="chat_button" onClick={() => setchangelogs(true)}>
                        <i className="fa fa-line-chart"></i>
                      </span>}
                    </>}


                  {(data.status_buyer === 1 || data.status_seller === 1) ? <>
                    {(data.edit_request / 1 === 0) ?
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => raiseContractEditRequest()} >Request Edit</button>
                      : (data.edit_request / 1 === userId) ?
                        <button type="button" className="btn btn-danger btn-sm" onClick="" disabled>Edit Request Raised</button> :
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => { openContractEdit(false) }}>Accept Edit Request</button>
                    }
                  </>
                    : ""}
                  {((((userTypeId === 14 && traderActiveType === 'buyer') || userTypeId === 3) && data.status_buyer === 0 && data.status_seller === 1) ||
                    (((userTypeId === 14 && traderActiveType === 'seller') || userTypeId === 4) && data.status_seller === 0 && data.status_buyer === 1)) &&
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { agreement(); setshowLoader(true) }} >Agree</button>}

                  <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>
                  <button type="button" className="btn btn-info btn-sm" onClick={() => handleNext()} id="nextid">Next</button>

                </div>
              </div>
            </div>
          </div>

          <div className={"modal" + (activeClass === ((userTypeId === 14) ? 7 : 6) ? " show" : "")} >
            <div className={"modal-dialog modal-xl"} id="parent_class">
              <div className="modal-content" >
                <div className="modal-header primary">
                  <h4 className="modal-title text-white">View Contract</h4>
                  <button type="button" className="close" onClick={() => setContractState(false, {})}>×</button>
                </div>

                <div className="modal-body calc-modal modal-view">
                  {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                  <div className="modal-body calc-modal modal-view">
                    <div className="calc-inner-modal">
                      <div className={"accordionWrapper p-0" + (viewAccordion === 0 ? " open" : " closed")}>
                        <span >Show/Hide</span><br />
                        <i className={"p-2 fa" + (viewAccordion === 0 ? " fa-minus-square-o fa-2x" : " fa-plus-square-o fa-2x")} aria-hidden="true" onClick={handleViewAccordion}></i>
                        {/*className={ "accordionItem" + (viewAccordion === 0? " open": " closed") }*/}
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" >Contract info</h2>
                          <div className="accordionItemContent">
                            {(contractState.info.modalMode === "create") ? <div className="mr-auto pr-3 form-group">
                              <label className="col-md-12">
                                {(traderActiveType === 'buyer') ? "Seller/Exporter" : "Buyer/Importer"}
                              </label>
                              <div className="chat-message">
                                <img src={avatarUrl(supplierAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  {(traderActiveType === 'buyer') ? <>
                                    <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span>
                                    <br />
                                    <span className="date-format">  {data.supplierAddress ? data.supplierAddress : '--'}</span>
                                  </> :
                                    <>
                                      <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span>
                                      <br />
                                      <span className="date-format">  {data.buyerAddress ? data.buyerAddress : '--'}</span>
                                    </>}
                                </div>
                              </div>
                            </div> :
                              <div className="row">
                                <div className="col-md-6 ">
                                  <div className="mr-auto pr-3 form-group">
                                    <label className="col-md-12">Buyer/Importer</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(data.buyerAvatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="date-format">  {data.buyerName ? data.buyerName : '--'}</span><br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="mr-auto pr-3 form-group">
                                    <label className="col-md-12">Seller/Exporter</label>
                                    <div className="chat-message pl-2">
                                      <img src={avatarUrl(data.supplierAvatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="date-format">  {data.supplierName ? data.supplierName : '--'}</span><br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                            <hr />
                            <div className="row">
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{contract === 0 ? 'FOB' : 'CIF'} </label>
                                  <div className="col-md-12"><span className="date-format">Contract Type</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{data.commodity ? ((data.commodity + "'").split(':')[0]) : data.commodity_name ? data.commodity_name : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Commodity</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{data.laycanStart ? formatDate_Application(data.laycanStart) : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Laycan Start Date</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{data.laycanEnd ? formatDate_Application(data.laycanEnd) : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Laycan End Date</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    <span className="shadow">
                                      <ReactCountryFlag
                                        countryCode={data.loadingCountry ? data.loadingCountry.split(':')[0] : ''}
                                        style={{ width: '25px', height: '25px' }} svg />
                                    </span>{data.loadingPort ? data.loadingPort : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">Loading Country</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    <span className="shadow">
                                      <ReactCountryFlag
                                        countryCode={data.unloadingCountry ? data.unloadingCountry.split(':')[0] : ''}
                                        style={{ width: '25px', height: '25px' }} svg />
                                    </span>{data.unloadingPort ? data.unloadingPort : '--'} </label>
                                  <div className="col-md-12"><span className="date-format">UnLoading Country</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" > Specification</h2>
                          <div className="accordionItemContent">
                            {/* Commodity Preview Component Start */}
                            {((data.commodity || data.commodity_id) && activeClass === 7) && <SpecificationsPreview specification={spec} specOrder={specOrder} />}
                            {/* Commodity Preview Component Start */}
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" > Price &amp; Rate</h2>
                          <div className="accordionItemContent">
                            <div className="d-flex divider">
                              <div className="modal-padding">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{data.priceType / 1 === 1 ? "Fixed price" : data.priceType / 1 === 2 ? "Index Price" : "--"} </label>
                                      <div className="col-md-12">
                                        <span className="date-format">Price Type</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{data.price ? data.price : 0} </label>
                                      <div className="col-md-12">
                                        <span className="date-format"> {data.priceType / 1 === 1 ? "Commodity Price/mt ( metric tonne* )" : data.priceType / 1 === 2 ? "Current Index Price" : "--"}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{data.quantity ? data.quantity : '--'}</label>
                                      <div className="col-md-12"> <span className="date-format">Quantity</span></div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{data.quantity * data.price}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Total Amount = Commodity Price/mt x Quantity</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" >Investigation Agency</h2>
                          <div className="accordionItemContent">
                            <div className="d-flex divider">
                              <div className="modal-padding">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12"> {getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Importer's Investigation Agency</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Exporter's Investigation Agency</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{getSelectName('investigationAgencythird', data.investigationAgencythird)}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Third Party Investigation Agency</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" >Insurance Agency</h2>
                          <div className="accordionItemContent">
                            <div className="d-flex divider">
                              <div className="modal-padding">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{getSelectName('commodityInsurer', data.commodityInsurer)} </label>
                                      <div className="col-md-12">
                                        <span className="date-format">Commodity Insurer</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{getSelectName('tradeInsurer', data.tradeInsurer)}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Trade Insurer</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" >Bank </h2>
                          <div className="accordionItemContent">
                            <div className="d-flex divider">
                              <div className="modal-padding">
                                <div className="row" id="Bank1">
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">
                                        {(paymentModes && paymentModes.length) ? paymentModes.map((value) => {
                                          if (value.id === (data.paymentMode / 1)) {
                                            return value.payment_type_pretty
                                          }
                                        }) : ''}
                                      </label>
                                      <div className="col-md-12">
                                        <span className="date-format">Payment Mode</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{getSelectName('buyerBank', data.buyerBank)}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Importer's Bank</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="row form-group">
                                      <label className="col-md-12">{getSelectName('supplierBank', data.supplierBank)}</label>
                                      <div className="col-md-12">
                                        <span className="date-format">Exporter's Bank</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordionItem">
                          <h2 className="accordionItemHeading" > Terms and Conditions</h2>
                          <div className="accordionItemContent">
                            {(activeClass === ((userTypeId === 14) ? 7 : 8)) ? tncPreviewGenerator() : ''}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer primary">
                  {(contractState.info.modalMode === "create") ? <>
                    <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Edit</button>
                    <button type="button" className="btn btn-info btn-sm" onClick={() => { handleSubmit(); setshowLoader(true) }} id="nextid">Submit</button>
                  </> :
                    <>
                      <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>


                      {(data.status_buyer === 0 && data.status_seller === 0) &&
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => { handleSubmitUpdate("U&A"); setshowLoader(true) }} >Update & Agree</button>}

                      {((((userTypeId === 14 && traderActiveType === 'buyer') || userTypeId === 3) && data.status_buyer === 0) ||
                        (((userTypeId === 14 && traderActiveType === 'seller') || userTypeId === 4) && data.status_seller === 0)) && editCount === 0 &&
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => { agreement(); setshowLoader(true) }} >Agree</button>}


                      {(data.status_buyer === 0 && data.status_seller === 0) &&
                        <button type="button" className="btn btn-info btn-sm" onClick={() => { handleSubmitUpdate(""); setshowLoader(true) }} >Update</button>}

                    </>}
                </div>
              </div>
            </div>
          </div>


          {supplier && <div className={"modal" + (supplier ? " show" : "")} id="SuppliersModal">
            <div className="modal-dialog modal-lg border-inner" id="parent_class">
              <div className="modal-content">
                <div className="modal-header primary">
                  <h4 className="modal-title text-white">Select Seller/Exporter</h4>
                  <button type="button" className="close" onClick={handleSupplierClose}>×</button>
                </div>
                <div className="modal-body">
                  <div className="col-md-12 row">
                    <div className="form-group col-md-6">
                      <input type="text" name="supplierSearchKey" placeholder="Search Keyword" value={supplierSearch} className="form-control" onChange={(event) => { event.persist(); setsupplierSearch(event.target.value) }} />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="button" value="Search" className="btn btn-primary btn-sm" onClick={() => searchSupplier()} />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="button" value="Add New" className="btn btn-primary btn-sm" onClick={
                        () => { window.open((userTypeId === 14 ? (window.location.origin + '/manageprofile') : (window.location.origin + '/registration')), "_blank") }
                      } />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="button" value="Refresh" className="btn btn-primary btn-sm" onClick={() => { setrefreshSupplier(refreshSupplier + 1); setsupplierSearch("") }} />
                    </div>
                  </div>
                  <hr />
                  {
                    getsuppliersList()
                  }
                </div>
              </div>
            </div>
          </div>}

          {(changelogs && (aclMap.contract_access && aclMap.contract_access.cont_changelog && aclMap.contract_access.cont_changelog.isExist)) &&
            <div className={"modal" + (changelogs ? " show" : "")} id="changelogsModal">
              <div className="modal-dialog modal-xl border-inner" id="parent_class">
                <div className="modal-content">
                  <div className="modal-header primary">
                    <h4 className="modal-title text-white">Contract Change logs</h4>
                    <button type="button" className="close" onClick={() => setchangelogs(false)}>×</button>
                  </div>
                  <div className="modal-body">
                    <ContractTrail contractNo={data.contract_number} modal={true} />
                  </div>
                </div>
              </div>
            </div>}
        </>
      }
    </>
  )
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

const mapStateToProps = state => {
  return {
    contractState: state.contract,
    clientType: state.clientType,
    addBankObj: state.addBankObj,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContractModal)
