export default function validate(typeId, kycDocs, payload) {


  const userTypeId = typeId
  return formValidator;

  function formValidator(values) {

    //variables
    let errors = {};
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    // Input's value check conditions
    // Common Conditions
    if (!values.email) {
      errors.email = 'Email ID is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email ID is invalid';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    } else if (!strongRegex.test(values.password)) {
      errors.password = 'Password Does not meet the Policy. It requires min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol';
    }

    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = "Those Passwords didn't match. Try again";
    }

    if (!values.companyName) {
      errors.companyName = userTypeId === 8 ? 'Bank Name is required' : 'Company Name is required';
    }

    if (!values.contactPerson) {
      errors.contactPerson = 'Contact Person Name is required';
    }

    if (!values.contactNo) {
      errors.contactNo = 'Contact No is required';
    } else if (isNaN(values.contactNo)) {
      errors.contactNo = 'Contact No Should be a number';
    } else if (values.contactNo.length < 10) {
      errors.contactNo = 'Contact No should be of atleast 10 digits';
    }


    if (!values.country) {
      errors.country = 'Country is required';
    }

    if (!values.industryType && (userTypeId == 19 || userTypeId == 14 || userTypeId == 3 || userTypeId == 4)) {
      errors.industryType = "Industry Type is required";
    }



    //doc checks
    if (values.type != 'finTech' && userTypeId !== 8 && payload.kycDetail && payload.kycDetail.organizationType &&
      payload.kycDetail.organizationType != "foreign") {
      for (let i = 0; i < kycDocs.length; i++) {
        if (kycDocs[i].userType.includes(typeId / 1)) {
          if (kycDocs[i].key !== 'cinDocument') {
            if (!values[kycDocs[i].key + "Name"]) {
              errors[[kycDocs[i].key + "Name"]] = kycDocs[i].name + ' Name is required';
            } else if (values[kycDocs[i].key + "Name"].length < kycDocs[i].length) {
              errors[kycDocs[i].key + "Name"] = kycDocs[i].name + ' Name should be of atleast ' + kycDocs[i].length + ' digits';
            }
          }
        }
      }
    }

    console.log(errors)
    return errors;
  }

}
