import React, { useState, useEffect, useRef } from "react";
import Logo from "../assets/images/logo_1.png";
import RefImg from "../assets/images/ref_img.jpg";
import { ToastContainer } from "react-toastify";
// import SelectField from "../common-components/SelectField";
import axios from "axios";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import { platformBackendUrl } from "../../urlConstants";
import { InputWithSelect, NewInput, NewSelect } from "../../utils/newInput";
import ChatBot from "../../utils/ChatBot";
import { decryptData, encryptData, multiSelectEventHandler } from "../../utils/myFunctions";
import CommodityAdd from './commodityAdd'
import { filter } from "jszip";
import MultipleSelect from "../../utils/MultipleSelect";
const onboardingTabs = [
  { name: "Work Field" },
  { name: "Company Type", hide: true },
  { name: "KYC details" },
  { name: "Company details" },
  { name: "Personal details" },
  { name: "Create password", last: true }
]

const FieldTypes = [
  {
    name: 'Company', type: "finTech", typeId: 19, techType: 2,
    soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true
  },
  // {
  //   name: 'Importer', type: "finTech", typeId: 3, techType: 2,
  //   soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true
  // },
  {
    name: 'Banks/Finance/NBFC', type: 'finTech', typeId: 8, techType: 2,
    soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, foreign: true, disabled: true
  },
  {
    name: 'Channel Partner', type: "CP", typeId: 20, techType: null,
    individual: true, soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true
  },
  {
    name: 'Franchise Partner', type: "FP", typeId: 20, techType: null,
    individual: true, soleProprietorship: true, partnership: true, pvtPubLtd: true, llp: true, disabled: true
  }
]

export const companyTypes = [
  { index: 0, karzaType: "", name: 'Individual', alt: "individual", aadharDocument: true, panDocument: true },
  { index: 1, karzaType: "PROPRIETORSHIP", name: 'Sole Proprietorship', alt: "soleProprietorship", gstDocument: true, iecDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { index: 2, karzaType: 'PARTNERSHIP', name: 'Partnership', alt: "partnership", gstDocument: true, iecDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { index: 3, karzaType: 'PRIVATE LIMITED', name: 'PVT/PUB. LTD', alt: 'pvtPubLtd', gstDocument: true, iecDocument: true, cinDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { index: 4, karzaType: 'LIMITED LIABILITY PARTNERSHIP', name: 'LLP', alt: "llp", gstDocument: true, iecDocument: true, iecDocumentIgnore1: true, iecDocumentIgnore2: true, iecDocumentIgnore3: true },
  { index: 5, karzaType: "", name: 'Outside India', alt: "foreign" }
]

const kycDocTypes = [
  { name: 'CIN Number', alt: "cinDocument" },
  { name: 'GST Number', alt: "gstDocument" },
  { name: 'IEC Number', alt: "iecDocument" },
  { name: 'PAN Number', alt: "panDocument" },
  { name: 'Aadhar Number', alt: "aadharDocument" }
]

const cpDocTypes = [
  { name: 'PAN Number' },
  { name: 'Aadhar Number' }
]

const formTitles = [
  { name: "Select your field" },
  { name: "Select your company type" },
  { name: "Enter your registration details" },
  { name: "Enter your company details" },
  { name: "Enter your personal details" },
  { name: "Create password" }
]

export const industryData = [
  { value: "apparelTextile", name: "Apparels & Textile" },
  { value: "industrialMech", name: "Industrial & Mechanical" },
  { value: "foodBeverage", name: "Food & Beverage" },
  { value: "electronicConsumer", name: "Electronics & Consumer Goods" },
  { value: "eCommerce", name: "E-Commerce" },
  { value: "gamingMedia", name: "Gaming & Media" },
  { value: "fmcg", name: "FMCG" },
  { value: "medicalPharma", name: "Medical & Pharmaceutical" },
  { value: "auto", name: "Auto" },
  { value: "frozenFood", name: "Frozen Foods" },
  { value: "ITStaffing", name: "IT Staffing" },
  { value: "argo", name: "AGRO" },
  { value: "govtEntity", name: "Buyer/Supplier Government Entity" },
  { value: "oilGas", name: "Oil/Gas" },
  { value: "diamond", name: "Diamond" },
  { value: "preciousStone", name: "Precious Stone" },
  { value: "gold", name: "Gold" },
  { value: "others", name: "Others" },
];

const NewRegistration = () => {
  const refOtp1 = useRef(null);
  const refOtp2 = useRef(null);
  const refOtp3 = useRef(null);
  const refOtp4 = useRef(null);

  const refMobOtp1 = useRef(null);
  const refMobOtp2 = useRef(null);
  const refMobOtp3 = useRef(null);
  const refMobOtp4 = useRef(null);

  const [stepperProgress, updateStepperProgress] = useState(0);
  const [data, setData] = useState({
    nameTitle: "Mr", phoneCode: "91", tcAccept: false,
    designation: "Director"
  })
  const [errors, setErrors] = useState({})
  const [countryData, setcountryData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showReferralPopup, toggleReferralPopup] = useState(false);
  const [isEmailVerified, toggleIsEmailVerified] = useState(false);
  const [isMobVerified, toggleIsMobVerified] = useState(false);
  const [otpPopup, setOtpPopup] = useState({ show: false, type: "" });
  const [showMoreKYC, toggleMoreKYC] = useState(false)
  const [outsideIndiaOrg, setoutSideIndiaOrg] = useState("no")

  const [panArr, setPanArr] = useState([])
  const [commodityList, setCommodityList] = useState([])

  const [commodityDropDown, setCommodityDropDown] = useState([])
  const [additionalCommodity, setAdditionalCommodity] = useState([]);
  const [addMorecommodity, setAddMoreCommodity] = useState(false)
  const [searchedCompanys, setSearchedCompanys] = useState([])

  // #1
  const onCancel = (selectedCommodityId, commodityName) => {

    const temp = commodityDropDown.find(item => parseInt(item.id) === parseInt(selectedCommodityId));
    const filteredItems = []
    for (let i of commodityList) {
      if (i.commdCategory !== temp.category && i.commodityName !== commodityName) {
        filteredItems.push(i)
      }
    }
    console.log("filteredItems....", filteredItems)
    setCommodityList(filteredItems)

    // const filteredItems = commodityList.filter(item => item.commdCategory !== temp.category && item.commodityName !== commodityName);
    console.log(filteredItems)
    setCommodityList(filteredItems)


  }

  const onPlus = (id, name) => {
    if (id && name) {

      const temp = commodityDropDown.find(item => parseInt(item.id) === parseInt(id));
      console.log("temp item in add", temp)

      const obj = { category_id: id, commodity_name: name, commodity_pretty_name: name.toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) }
      console.log("object added", obj)
      setCommodityList(prev => [...prev, obj])

      setAddMoreCommodity(true)
    }
    else {
      return
    }
  }
  useEffect(() => { console.log("in use Efeect", commodityList) }, [commodityList])

  const handleAddMore = () => {
    setAdditionalCommodity(prev => [...prev, <CommodityAdd commodityDropDown={commodityDropDown} onPlus={onPlus} setAddMoreCommodity={setAddMoreCommodity} onCancel={onCancel} />])
  }

  const astrix = <span className="required-field text-danger">*</span>;
  const queryParams = new URLSearchParams(window.location.search)
  const typeId = queryParams.get("typeId")
  let params = queryParams.get("params")
  let userIdToOnboard = queryParams.get("user")
  if (params) {
    params = decodeURIComponent(params)
    params = decryptData(params)
    params = JSON.parse(params)
  }
  // console.log("paramssssssssssssssssssss", params, data);

  async function getBuyerDetails() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getBuyerDetailsById', { buyerId: params.id })
    // console.log("apiResppppppppppppppppppppppppp", apiResp);
    if (apiResp.length) {
      let tempData = {
        nameTitle: apiResp[0]["buyerNameTitle"] || "Mr", phoneCode: apiResp[0]["buyerPhoneCode"], designation: apiResp[0]["buyerPosition"] || "Director",
        "directorArr": [
          {
            "name": "Other"
          }
        ], workField: 0, organizationType: 5, companyName: apiResp[0]["buyerName"], industryType: apiResp[0]["exporterIndustryType"],
        country: apiResp[0]["buyerCountry"], companyAddress: apiResp[0]["buyerAddress"], companyCity: " ", companyPostalCode: " ",
        contactPerson: apiResp[0]["buyerName"], contactPersonDropDown: 'Other', email: apiResp[0]["buyerEmail"], contactNo: apiResp[0]["buyerPhone"]
      }
      setData({ ...tempData })
      updateStepperProgress(5)
      toggleIsEmailVerified(true)
      toggleIsMobVerified(true)
    }
    setShowLoader(false)
  }

  useEffect(() => {
    if (params?.id) {
      getBuyerDetails()
    }
  }, [])

  // useEffect(() => {
  //   if (data.organizationType && companyTypes[data.organizationType]["alt"] === "foreign") {
  //     toggleMoreKYC(true)
  //   }
  //   else {
  //     toggleMoreKYC(false)
  //   }
  // }, [data.organizationType])

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
    // axios.get(commodityDropDownUrl + "/getcommoditycategory").then((result) => {
    //   if (result.data.message && result.data.message.length) {
    //     // const values = [...new Set(result.data.message.map(item => item.category))];
    //     setCommodityDropDown(result.data.message);
    //   }
    // });
    call('GET', 'getcommoditycategory')
      .then(result => {
        if (result && result.length) {
          setCommodityDropDown(result);
        }
      })
      .catch(error => {
        console.error("Error in fetching commodity categories", error);

      });
  }, []);

  const handleFieldChange = (index, type) => {
    if (type === "workField") {
      setData({ ...data, [type]: index, organizationType: outsideIndiaOrg === "yes" ? 5 : null })
    }
    else {
      setData({ ...data, [type]: index })
    }
  }

  function mapOrganizationTypeWithKarza(karzaType) {
    return companyTypes.filter(i => {
      if (i.karzaType === karzaType) {
        return i
      }
    })?.[0]?.index || ""
  }

  const handleStepperProgress = (type) => {
    type === "inc" ? updateStepperProgress(stepperProgress + 1) : updateStepperProgress(stepperProgress - 1)
  }

  const handleNext = async () => {
    let err = {}
    if (stepperProgress === 0 && !FieldTypes[data.workField]) {
      err["msg"] = "Select work field"
    }
    else if (stepperProgress === 0) {
      if (outsideIndiaOrg === "yes") {
        return updateStepperProgress(3)
      }
      else {
        return updateStepperProgress(2)
      }
    }
    // if (stepperProgress === 1 && !companyTypes[data.organizationType]) {
    //   err["msg"] = "Select company type"
    // }
    if (stepperProgress === 2) {
      if (showMoreKYC) {
        let validateFields = []
        let isChannelPartner = FieldTypes[data.workField]["typeId"] / 1 == 20
        // console.log("organizationTypeeeeeeeeeeeeeeeeeee", data.organizationType);
        if (!data?.organizationType?.toString()?.length) {
          return setErrors({ ...errors, organizationType: "Mandatory Field" })
        }
        else {
          if (companyTypes[data.organizationType]["alt"] === "individual") {
            validateFields = ["aadharDocument", "panDocument"]
          }
          if (companyTypes[data.organizationType]["alt"] === "soleProprietorship" && !isChannelPartner) {
            // validateFields = ["iecDocument"]
          }
          if (companyTypes[data.organizationType]["alt"] === "partnership" && !isChannelPartner) {
            // validateFields = ["iecDocument"]
          }
          if (companyTypes[data.organizationType]["alt"] === "pvtPubLtd" && !isChannelPartner) {
            validateFields = ["cinDocument"]
          }
          if (companyTypes[data.organizationType]["alt"] === "llp" && !isChannelPartner) {
            // validateFields = ["iecDocument"]
          }
        }
        for (let index = 0; index < validateFields.length; index++) {
          const element = validateFields[index];
          if (!data[element]) {
            err[element] = "Mandatory field"
          }
        }
      }
      else {
        let isIndividualCompany = companyTypes?.[data.organizationType]?.["alt"] === "individual"
        let isChannelPartner = FieldTypes[data.workField]["typeId"] / 1 == 20
        if (!isIndividualCompany && !data.gstDocument && !isChannelPartner && !data.selectedCompanyName) {
          err["gstDocument"] = "Enter GST Number"
        }
        else if (isChannelPartner && !data.panDocument) {
          err["panDocument"] = "Enter PAN Number"
        }
        else {
          setShowLoader(true)
          try {
            let apiResp = await call('POST', 'getAndVerifyKYCV2', isChannelPartner ?
              { "pan": data.panDocument, typeId: FieldTypes[data.workField]["typeId"] / 1 } :
              {
                "gst": data.gstDocument || undefined, typeId: FieldTypes[data.workField]["typeId"] / 1,
                "entityId": data.gstDocument ? undefined : data.selectedCompanyName
              })
            setShowLoader(false)
            if (apiResp) {
              let addressComponents = {}
              setShowLoader(true)
              if (apiResp?.company_address?.length) {
                addressComponents = await call('POST', 'getAddressComponents', { address: apiResp.company_address })
              }
              setShowLoader(false)
              let tempMultPanArr = []
              for (let index = 0; index < apiResp.multiplePans?.length; index++) {
                tempMultPanArr.push({ name: apiResp.multiplePans[index] })
              }
              setPanArr(tempMultPanArr)
              setData({
                ...data,
                gstDocument: data.gstDocument || apiResp?.gst,
                iecArr: apiResp.iecArr,
                organizationType: mapOrganizationTypeWithKarza(apiResp.organizationType),
                iecDocument: apiResp.iecArr?.[0]?.["iec"] || null,
                panDocument: isChannelPartner ? data.panDocument : apiResp.pan, cinDocument: apiResp.cin || null,
                "companyName": apiResp.company_name,
                "contactPerson": apiResp.company_name,
                "companyAddress": apiResp.company_address,
                "companyCity": addressComponents.city,
                "companyPostalCode": addressComponents.postalCode,
                "country": addressComponents.countrySortName,
                "companyState": addressComponents.state,
                "gst_company_detail": null,
                "iec_company_detail": null,
                "cin_company_detail": null,
                "type": "finTech",
                categoryOfExporters: apiResp?.categoryOfExporters
              })
              toggleMoreKYC(true)
              return null
            }
          } catch (error) {
            console.log("e", error);
            setShowLoader(false)
            toastDisplay(error, "error")
            return null
          }
        }
      }
    }
    if (stepperProgress === 3) {
      let validateFields = ["companyName", "country", "companyAddress", "companyCity", "companyPostalCode"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    // #2
    if (stepperProgress === 4) {
      let validateFields = ["contactPersonDropDown", "contactPerson", "nameTitle", "email", "contactNo", "designation"]
      if (!typeId) {
        if (!isEmailVerified) {
          err["email"] = "Kindly verify email id to proceed"
          sendCombinedOTP()
        }
        else if (!isMobVerified) {
          err["contactNo"] = "Kindly verify mobile number to proceed"
          sendCombinedOTP()
        }
      }

      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    if (stepperProgress === 5) {
      let validateFields = ["password", "repassword"]
      if (data.password && data.password.length < 6) {
        err["password"] = "Password should be atleast 6 characters long"
      }
      else if (data.password != data.repassword) {
        err["repassword"] = "Password mismatch"
      }
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = "Mandatory field"
        }
      }
    }
    if (!Object.keys(err).length) {
      if (stepperProgress == 5) {
        onRegister()
      }
      // else if (stepperProgress == 2) {
      //   let req = {
      //     "organizationType": companyTypes[data.organizationType]["alt"],
      //     "cinDocumentName": data["cinDocument"],
      //     "gstDocumentName": data["gstDocument"],
      //     "iecDocumentName": data["iecDocument"]
      //   }
      //   if (req.organizationType != "foreign" && req.organizationType != "individual") {
      //     setShowLoader(true)
      //     call('POST', 'getKYCDetail', req).then((result) => {
      //       // console.log("getKYCDetail==>", result);
      //       if (result.company_name != '') {
      //         setData({
      //           ...data,
      //           "companyName": result.company_name,
      //           "contactPerson": result.company_name,
      //           "companyAddress": result.company_address,
      //           "email": result.company_email,
      //           "contactNo": result.company_mobile,
      //           "gst_company_detail": result.gst_company_detail ? result.gst_company_detail : null,
      //           "iec_company_detail": result.iec_company_detail ? result.iec_company_detail : null,
      //           "cin_company_detail": result.cin_company_detail ? result.cin_company_detail : null,
      //           "type": "finTech"
      //         });
      //         toastDisplay("KYC verified successfully", "success");
      //         handleStepperProgress("inc")
      //       }
      //       else {
      //         toastDisplay("Your KYC is Not-Verified", "error");
      //       }
      //       setShowLoader(false)
      //     }).catch((e) => {
      //       setShowLoader(false)
      //       toastDisplay("Something went wrong", "error");
      //     })
      //   }
      //   else {
      //     handleStepperProgress("inc")
      //   }
      // }
      else {
        handleStepperProgress("inc")
      }
    }
    else {
      if (stepperProgress != 4) {
        toastDisplay(err["msg"] || "Form validation error", "error")
      }
    }
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", err);
    setErrors(err)
  }

  async function asyncUseEffectFunction() {
    let dataToSet = {}
    if (data.iecDocument) {
      let addressComponents = {}
      let selectedIecDetails = data.iecArr.filter((i, j) => {
        if (i.iec === data.iecDocument) {
          return i
        }
      })?.[0]
      if (selectedIecDetails?.address?.length) {
        addressComponents = await call('POST', 'getAddressComponents', { address: selectedIecDetails.address })
      }
      let directorArr = selectedIecDetails?.directors || []
      directorArr.push({ name: "Other" })
      dataToSet = {
        ...data,
        "companyName": selectedIecDetails.name,
        "companyAddress": selectedIecDetails.address,
        "companyCity": addressComponents.city,
        "companyState": addressComponents.state,
        "companyPostalCode": addressComponents.postalCode,
        "country": addressComponents.countrySortName,
        "contactNo": selectedIecDetails.contact || "",
        "email": selectedIecDetails.email || "",
        directorArr,
        contactPersonDropDown: directorArr?.[0]?.["name"] || "",
        contactPerson: directorArr?.[0]?.["name"] || ""
      }
    }
    else {
      dataToSet = {
        ...data,
        directorArr: [{ name: "Other" }]
      }
    }
    setData(dataToSet)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [data.iecDocument])

  function handleChange(e) {
    e.persist()
    if (e.target.name === "country" && e.target.value) {
      let tempSelectedCountry = countryData.filter((i) => {
        if (i.sortname === e.target.value) {
          return i
        }
      })?.[0] || {}
      setData({ ...data, [e.target.name]: e.target.value, phoneCode: tempSelectedCountry.phonecode })
      setErrors({ ...errors, [e.target.name]: "" })
      return null
    }
    if (e.target.name === 'selectedCompanyName' && e.target.value) {
      let selectedCompany = searchedCompanys.filter((i) => {
        if (i.entityId === e.target.value) {
          return true
        }
      })?.[0] || {}
      setData({ ...data, [e.target.name]: e.target.value, companyName: selectedCompany.primaryName })
      setErrors({ ...errors, [e.target.name]: "" })
      toggleMoreKYC(false)
      return null
    }
    if (e.target.name === "commodity" && e.target.value) {

      let tempSelectedCommodity = commodityDropDown.filter((i) => {
        if (i.id == e.target.value) {
          return i
        }
      })?.[0] || {}
      console.log("temp", tempSelectedCommodity, e.target.value)
      // setSelectedCommodity(tempSelectedCommodity)
      setData({ ...data, [e.target.name]: e.target.value, id: tempSelectedCommodity.id })
      setErrors({ ...errors, [e.target.name]: "" })
      // console.log("....", e.target.name, "....", e.target.value, '......', tempSelectedCommodity.id)
      return null
    }
    if (e.target.name === "gstDocument" && FieldTypes[data.workField]["typeId"] / 1 != 20) {
      if (!(data.organizationType && companyTypes[data.organizationType]["alt"] === "foreign")) {
        toggleMoreKYC(false)
      }
    }
    if (e.target.name === "contactNo") {
      toggleIsMobVerified(false)
    }
    if (e.target.name === "email") {
      toggleIsEmailVerified(false)
    }
    if (e.target.name.includes('otp')) {
      let currentIndex = e.target.name.split("p")[1] / 1
      if (e.target.value) {
        if (currentIndex == 1) {
          refOtp2.current.focus()
        }
        if (currentIndex == 2) {
          refOtp3.current.focus()
        }
        if (currentIndex == 3) {
          refOtp4.current.focus()
        }
      }
      else {
        if (currentIndex == 4) {
          refOtp3.current.focus()
        }
        if (currentIndex == 3) {
          refOtp2.current.focus()
        }
        if (currentIndex == 2) {
          refOtp1.current.focus()
        }
      }
    }
    if (e.target.name.includes('phoneotp')) {
      let currentIndex = e.target.name.split("_")[1] / 1
      if (e.target.value) {
        if (currentIndex == 1) {
          refMobOtp2.current.focus()
        }
        if (currentIndex == 2) {
          refMobOtp3.current.focus()
        }
        if (currentIndex == 3) {
          refMobOtp4.current.focus()
        }
      }
      else {
        if (currentIndex == 4) {
          refMobOtp3.current.focus()
        }
        if (currentIndex == 3) {
          refMobOtp2.current.focus()
        }
        if (currentIndex == 2) {
          refMobOtp1.current.focus()
        }
      }
    }
    if (e.target.name === "contactPersonDropDown") {
      if (e.target.value === "Other") {
        setData({ ...data, "contactPerson": "", [e.target.name]: e.target.value })
      }
      else {
        setData({ ...data, "contactPerson": [e.target.value], [e.target.name]: e.target.value })
      }
    }
    else {
      setData({ ...data, [e.target.name]: e.target.value })
      setErrors({ ...errors, [e.target.name]: "" })
    }
  }


  const onRegister = () => {
    let reqObject = {
      "typeId": FieldTypes[data.workField]["typeId"],
      "cinDocumentName": data.cinDocument || null,
      "gstDocumentName": data.gstDocument || null,
      "iecDocumentName": data.iecDocument || null,
      "panDocumentName": data.panDocument || null,
      "aadharDocumentName": data.aadharDocument || null,
      "iecDocumentName": data.iecDocument || null,
      "panDocumentName": data.panDocument || null,
      "aadharDocumentName": data.aadharDocument || null,
      "organizationType": companyTypes[data.organizationType]["alt"],
      "companyName": data.companyName,
      "contactPerson": data.contactPerson,
      "companyAddress": data.companyAddress,
      "email": data.email,
      "contactNo": data.contactNo,
      "gst_company_detail": data.gst_company_detail || null,
      "iec_company_detail": data.iec_company_detail || null,
      "cin_company_detail": data.cin_company_detail || null,
      "type": FieldTypes[data.workField]["type"],
      "referralCode": data.referalCode,
      "password": data.password,
      "passwordConfirm": data.repassword,
      "termsAndCondition": true,
      "country": data.country,
      "industryType": data.industryType,
      "techType": FieldTypes[data.workField]["techType"],
      "companyCity": data.companyCity,
      "companyPostalCode": data.companyPostalCode,
      "phoneCode": data.phoneCode,
      "nameTitle": data.nameTitle,
      "companyState": data.companyState,
      "designation": data.designation

    }

    if (userIdToOnboard) {
      reqObject["userIdToOnboard"] = decodeURIComponent(decryptData(userIdToOnboard))
    }
    if (FieldTypes[data.workField]["typeId"] === 20) {
      reqObject["adminProfile"] = false
      reqObject["finTechType"] = true
      reqObject["role"] = FieldTypes[data.workField]["type"]
      reqObject["status"] = 1
      if (data.country != "IN") {
        reqObject["setKycTrue"] = true
      }
      setShowLoader(true)
      console.log("req obj", reqObject)
      call('POST', 'registration', reqObject).then((result) => {
        setShowLoader(false)
        toastDisplay("Operation success, contact admin for next process", "success")
        setTimeout(() => {
          window.location = 'login'
        }, 1500);
      }).catch(err => {
        setShowLoader(false)
        toastDisplay("Something went wrong", "error");
      })
    }
    else {
      let formData = new FormData()
      Object.keys(reqObject).forEach(item => {
        formData.append(item, reqObject[item])
      })
      if (commodityList.length) {
        formData.append('commodityList', JSON.stringify(commodityList))
      }
      setShowLoader(true)
      call('POST', 'registration', formData).then((result) => {
        setShowLoader(false)
        toastDisplay("User registered successfully", "success")
        setTimeout(() => {
          window.location = `login?attr1=${encodeURIComponent(encryptData(data.email))}&attr2=${encodeURIComponent(encryptData(data.password))}`
        }, 1500);
      }).catch(err => {
        setShowLoader(false)
        toastDisplay(err.message || "Something went wrong", "error");
      })
    }
  }

  function toolTip(msg) {
    return (
      <span className="error-icon" flow="right" tooltip={msg}>i</span>
    )
  }

  async function validateReferralCode() {
    if (!data["referalCode"]) {
      return setErrors({ ...errors, "referalCode": "Enter Referral Code" })
    }
    setShowLoader(true)
    call("POST", 'verifyrefercode', { "referCode": data.referalCode }).then(result => {
      setShowLoader(false)
      if (result.length) {
        toggleReferralPopup(false)
      }
      else {
        setData({ ...data, "referalCode": "" })
        setErrors({ ...errors, "referalCode": "Invalid Referal Code" })
      }
    }).catch(err => {
      setShowLoader(false)
      setData({ ...data, "referalCode": "" })
      setErrors({ ...errors, "referalCode": "Something went wrong" })
    })
  }
  async function sendCombinedOTP() {
    if (!data.email && !data.contactNo) {
      return null
    }
    setShowLoader(true)
    let reqObj = {
      contactNo: "+" + data.phoneCode + "" + data.contactNo,
      email: data.email,
      sendOnMob: true,
      sendOnEmail: true
    }
    call('POST', 'sendVerifyOtp', reqObj).then(result => {
      setShowLoader(false)
      if (result) {
        toastDisplay(result, "success")
        setOtpPopup({ show: true })
      }
    }).catch(err => {
      setShowLoader(false)
      toastDisplay(err, 'error')
    })
  }
  async function sendOtp(type) {
    if (type === "email" && !data.email) {
      return null
    }
    if (type === "mob" && !data.contactNo) {
      return null
    }

    setShowLoader(true)
    call("POST", 'sendVerifyOtp', type === "email" ? { sendOnEmail: true, email: data.email } :
      { sendOnMob: true, contactNo: "+" + data.phoneCode + "" + data.contactNo }).then(result => {
        setShowLoader(false)
        if (result) {
          toastDisplay(result, "success")
          setOtpPopup({ show: true, type })
        }
      }).catch(err => {
        setShowLoader(false)
        toastDisplay(err, 'error')
      })
  }
  async function verifyCombinedOTP() {
    setShowLoader(true)
    let req1 = {
      email: data.email,
      verifyEmail: true,
      otp: data.otp1 + "" + data.otp2 + "" + data.otp3 + "" + data.otp4
    }
    let req2 = {
      contactNo: "+" + data.phoneCode + "" + data.contactNo,
      verifyMob: true,
      otp: data.phoneotp_1 + "" + data.phoneotp_2 + "" + data.phoneotp_3 + "" + data.phoneotp_4
    }
    let mobileOTPRes, emailOTPRes
    try {
      emailOTPRes = await call('POST', 'sendVerifyOtp', req1)
    } catch (err) {
      setShowLoader(false)
      toastDisplay(err, 'error')
    }
    try {
      mobileOTPRes = await call('POST', 'sendVerifyOtp', req2)
    } catch (err) {
      setShowLoader(false)
      toastDisplay(err, 'error')
    }
    setShowLoader(false)
    if (emailOTPRes && mobileOTPRes) {
      toastDisplay("OTP Verification Succesful.", "success")
      setOtpPopup({ show: false, type: "" })
      setData({ ...data, otp1: "", otp2: "", otp3: "", otp4: "", phoneotp_1: "", phoneotp_2: "", phoneotp_3: "", phoneotp_4: "" })

      setErrors({})
      if (emailOTPRes) {
        toggleIsEmailVerified(true)
      }
      if (mobileOTPRes) {
        toggleIsMobVerified(true)
      }
      // handleStepperProgress("inc")
    }
  }
  async function verifyOtp(type) {
    setShowLoader(true)
    let req = {
      otp: data.otp1 + "" + data.otp2 + "" + data.otp3 + "" + data.otp4
    }
    call("POST", 'sendVerifyOtp', type === "email" ? { ...req, verifyEmail: true, email: data.email } :
      { ...req, verifyMob: true, contactNo: "+" + data.phoneCode + "" + data.contactNo }).then(result => {
        setShowLoader(false)
        if (result) {
          toastDisplay(result, "success")
          setOtpPopup({ show: false, type: "" })
          setData({ ...data, otp1: "", otp2: "", otp3: "", otp4: "" })
          setErrors({})
          if (type === "email") {
            toggleIsEmailVerified(true)
          }
          else {
            toggleIsMobVerified(true)
          }
        }
      }).catch(err => {
        setShowLoader(false)
        toastDisplay(err, 'error')
      })
  }

  const handleFilterOptions = async (typedInput, name) => {
    // console.log("typedInput", typedInput);
    if (name === "selectedCompanyName" && typedInput) {
      let entitySearchApiResp = await call('POST', 'searchEntity', { supplierName: typedInput })
      if (entitySearchApiResp?.length) {
        setSearchedCompanys([...entitySearchApiResp])
      }
    }
  }

  return (
    <>
      <ChatBot />

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showReferralPopup ? (
        <div className="modal show" id="" style={{ display: "block" }}>
          <div className="modal-dialog modal-m border-inner" >
            <div className="modal-content px-4 mx-4 py-2">
              <div className="modal-header border-0">
                <button type="button" className="close" onClick={() => toggleReferralPopup(false)}>×</button>
              </div>
              <h6 className="modal-title text-dark text-center">Enter who referred you</h6>
              <img
                className="cursor my-4" src={RefImg} alt="logo" height="250rem" width="100%"
              />
              <div className="form-floating mb-4 w-100 py-2 position-relative ">
                <input
                  type="text"
                  className={`form-control enterOtpInput  ${errors["referalCode"] ? "border-danger" : ""}`}
                  name={"referalCode"}
                  value={data["referalCode"]}
                  onChange={handleChange}
                  placeholder={"Enter Referral Code"}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      validateReferralCode()
                    }
                  }}
                />
                {errors["referalCode"] ? (
                  <span className="text-danger fontValid">{errors["referalCode"]}</span>
                ) : (
                  ""
                )}
                <i
                  style={{
                    "left": "85%",
                    "top": "1.3rem"
                  }}
                  onClick={validateReferralCode}
                  className="position-absolute cursor
                fas fa-2x fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      ) : null
      }
      {otpPopup.show ? (
        <div className="modal show" id="" style={{ display: "block" }} >
          <div className="modal-dialog modal-m border-inner" >
            <div className="modal-content px-4 mx-4 py-2">
              <div className="modal-header border-0">
                <button type="button" className="close" onClick={() => setOtpPopup({ show: false, type: "" })}>×</button>
              </div>
              <h6 className="modal-title text-dark text-center">{`Verification`}</h6>
              <div className="py-4 justify-content-center d-flex flex-column align-items-center">
                <>
                  <label className="cursor w-90" >Enter Email OTP</label>
                  <div className="form-group mb-4 w-85 d-flex flex-row justify-content-between"
                    style={{ height: "3rem" }}
                  >
                    <input type="text" id="otp1" ref={refOtp1} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp1" value={data.otp1} onChange={handleChange} />
                    <input type="text" id="otp2" ref={refOtp2} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp2" value={data.otp2} onChange={handleChange} />
                    <input type="text" id="otp3" ref={refOtp3} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp3" value={data.otp3} onChange={handleChange} />
                    <input type="text" id="otp4" ref={refOtp4} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp4" value={data.otp4} onChange={handleChange} />
                  </div>
                  <label className="cursor w-90" >Enter Mobile OTP</label>
                  <div className="form-group mb-4 w-85 d-flex flex-row justify-content-between"
                    style={{ height: "3rem" }}
                  >
                    <input type="text" id="phoneotp_1" ref={refMobOtp1} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_1" value={data.phoneotp_1} onChange={handleChange} />
                    <input type="text" id="phoneotp_2" ref={refMobOtp2} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_2" value={data.phoneotp_2} onChange={handleChange} />
                    <input type="text" id="phoneotp_3" ref={refMobOtp3} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_3" value={data.phoneotp_3} onChange={handleChange} />
                    <input type="text" id="phoneotp_4" ref={refMobOtp4} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_4" value={data.phoneotp_4} onChange={handleChange} />
                  </div>
                  {errors.otp && <label className="w-90 text-danger">{errors.otp}</label>}
                  <label className="cursor w-90 light-font" >{`An OTP has been sent on your mentioned 
                  ${otpPopup.type === "mob" ? "Mobile Number" : "Email Id"}`}</label>
                  <div className="row w-100 p-4">
                    <label
                      onClick={() => sendCombinedOTP()}
                      className="cursor w-50 text-color1" >Resend OTP</label>
                    <label
                      onClick={() => setOtpPopup({ show: false, type: "" })}
                      className="cursor w-50 text-color1" >{`Change Email Id/Mobile Number`}</label>
                  </div>
                  <button type="button"
                    onClick={() => verifyCombinedOTP()}
                    class={`new-btn py-2 w-50 mb-4 text-white`}>
                    {"Verify OTP"}
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>

      ) : null}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <div>
        <div
          className="row bg-img loginBanner">
          <div className="w-100 align-items-center signup-div d-none">
            <div className="col-6">
              <h5 style={{}} className="font-size-30 font-wt-600 my-4 text-center">Sign up</h5>
            </div>
            <div className="col-6 text-center">
              <img onClick={() => { window.location = "/" }}
                style={{}}
                className="cursor" src={Logo} alt="logo" height="60px"
              />
            </div>
          </div>

          <div className="col-md-12 justify-content-center stepper-mobile-view" style={{}}>
            <h5 style={{}} className="font-size-22 font-wt-600 my-4 py-4 text-center signup-h">Sign up</h5>
            <div className="d-flex flex-row stepper-div justify-content-center mx-0">
              {onboardingTabs.map((item, index) => {
                if (!item.hide) {
                  return (
                    <>
                      <div style={{
                        "border": `1px solid ${index <= stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                        "background": index <= stepperProgress ? "#5CB8D3" : "#BDBDBD",
                        "border-radius": "100%", "height": '1.8rem', "width": '1.8rem'
                      }}>
                        <label
                          style={{ fontSize: '10px' }}
                          className="stepper-label-horizontal font-wt-400"
                        >{item.name}</label>
                      </div>

                      <div className={`${item.last ? "d-none" : "" + "stepper-bar-horizontal"}`} style={{
                        "border": `1px solid ${index < stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                        "background": index < stepperProgress ? "#5CB8D3" : "#BDBDBD"
                      }} />
                    </>
                  )
                }
              })}
            </div>
          </div>

          <div className="col-md-5 justify-content-center mb-4 pb-4 left-class stepper-desktop-view" style={{}}>
            <div className="d-flex flex-column stepper-div ">
              <h5 style={{}} className="font-size-30 font-wt-600 my-4 py-4 text-center signup-h">Sign up</h5>
              {onboardingTabs.map((item, index) => {
                if (!item.hide) {
                  return (
                    <>
                      <div style={{
                        "border": `1px solid ${index <= stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                        "background": index <= stepperProgress ? "#5CB8D3" : "#BDBDBD",
                        "border-radius": "100%", "height": '1.8rem', "width": '1.8rem'
                      }}>
                        <label className="stepper-label font-size-16 font-wt-400"
                        >{item.name}</label>
                      </div>

                      <div className={`${item.last ? "d-none" : "" + "stepper-bar"}`} style={{
                        "border": `1px solid ${index < stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                        "background": index < stepperProgress ? "#5CB8D3" : "#BDBDBD"
                      }} />
                    </>
                  )
                }
              })}
            </div>
          </div>

          <div className="d-flex flex-column col-md-6 align-items-center right-class" style={{ paddingBottom: '5rem' }}>
            <div className="my-4 py-4 text-center signup-h">
              <img onClick={() => { window.location = "/" }}
                style={{}}
                className="cursor" src={Logo} alt="logo" height="60px"
              />
            </div>
            <h5 className="pb-4 font-size-18 font-wt-500"><span><i
              onClick={() => {
                if (stepperProgress / 1 == 2) {
                  updateStepperProgress(0)
                }
                else {
                  if (stepperProgress / 1 == 3 && outsideIndiaOrg === "yes") {
                    updateStepperProgress(0)
                  }
                  else {
                    handleStepperProgress('dec')
                  }
                }
              }}
              class={`fa fa-arrow-left mx-4 ${stepperProgress === 0 && 'd-none'}`} aria-hidden="true"></i></span>{formTitles[stepperProgress]["name"]}</h5>
            {
              stepperProgress == 0 && (
                <>
                  {FieldTypes.map((item, index) => {
                    if (!item.disabled) {
                      return (
                        <div
                          onClick={() => {
                            handleFieldChange(index, "workField");
                            // handleStepperProgress("inc")
                            if (outsideIndiaOrg === "yes") {
                              updateStepperProgress(3)
                            }
                            else {
                              updateStepperProgress(2)
                            }
                          }} className={`new-btn py-2 w-50 mb-4 ${data.workField === index ? "text-white" : 'text-dark bg-white'}`}>
                          {item.name}
                        </div>
                      )
                    }
                  })}

                  <div className='d-flex flex-row align-items-center'>
                    <div className='d-flex flex-row px-2'>
                      <label className="form-check-label mt-1 fs-15 font-wt-600" >
                        Is your organization outside of India?
                      </label>
                    </div>
                  </div>

                  <div
                    className="row d-flex my-2 w-mob-45"
                    style={{ width: '25%' }}
                  >
                    <div className='d-flex flex-row px-2 col-6' onClick={() => {
                      setoutSideIndiaOrg("no");
                      setData({ ...data, organizationType: null })
                    }}>
                      <input className="form-check-input" type="radio" value={"no"} checked={outsideIndiaOrg === 'no'} />
                      <label className="form-check-label p-0 m-0" >
                        No
                      </label>
                    </div>
                    <div className='d-flex flex-row px-2 col-6' onClick={() => {
                      setoutSideIndiaOrg("yes");
                      setData({ ...data, organizationType: 5 })
                    }}>
                      <input className="form-check-input" type="radio" value={"yes"} checked={outsideIndiaOrg === 'yes'} />
                      <label className="form-check-label p-0 m-0" >
                        Yes
                      </label>
                    </div>
                  </div>
                </>
              )
            }
            {
              stepperProgress == 1 && (
                <>
                  {companyTypes.map((item, index) => {
                    if (FieldTypes[data.workField][item.alt]) {
                      return (
                        <div
                          onClick={() => { handleFieldChange(index, "organizationType"); handleStepperProgress("inc") }}
                          className={`new-btn py-2 w-50 mb-4 ${data.organizationType === index ? "text-white" : 'text-dark bg-white'}`}>
                          {item.name}
                        </div>
                      )
                    }
                  })}</>
              )
            }

            {
              stepperProgress == 2 && (
                <>
                  {FieldTypes[data.workField]["typeId"] / 1 == 20 ? (
                    <div className="form-floating mb-2 w-50 position-relative">
                      <NewInput type="text" id={"panDocument"} name={"panDocument"} value={data["panDocument"]} onChange={handleChange}
                        error={errors["panDocument"]} label={"Enter PAN Number"}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="form-floating mb-2 w-50 position-relative">
                        <MultipleSelect
                          Label={'Enter Company Name'}
                          Id={`selectedCompanyName`}
                          optiondata={searchedCompanys}
                          filterOption={() => true}
                          onInputChange={(e) => {
                            handleFilterOptions(e, "selectedCompanyName")
                          }}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `selectedCompanyName`, "entityId"))
                          }}
                          value={data[`selectedCompanyName`] ? [data[`selectedCompanyName`]] : []}
                          name={`selectedCompanyName`}
                          labelKey={"primaryName"}
                          valKey={"entityId"}
                          error={errors[`selectedCompanyName`]}
                        />
                      </div>
                      <label className="font-size-16 font-wt-500 mb-4">OR</label>
                      <div className="form-floating mb-2 w-50 position-relative">
                        <NewInput type="text" id={"gstDocument"} name={"gstDocument"} value={data["gstDocument"]} onChange={handleChange}
                          error={errors["gstDocument"]} label={"Enter GST Number"}
                        />
                      </div></>)}
                  {showMoreKYC ? (
                    <>
                      <div className="form-floating mb-2 w-50 position-relative">
                        <NewSelect
                          selectData={companyTypes.filter((item) => {
                            if (FieldTypes[data.workField][item.alt]) {
                              return item
                            }
                          })} name={"organizationType"} value={data["organizationType"]} onChange={handleChange}
                          error={errors["organizationType"]} label={"Organization Type"} optionLabel={"name"} optionValue={"index"}
                        />
                      </div>
                      <div className="form-floating mb-2 w-50 position-relative">
                        {/* <NewInput isDisabled={true} type="text" id={"iecDocument"} name={"iecDocument"} value={data["iecDocument"]} onChange={handleChange}
                          error={errors["iecDocument"]} label={"Enter IEC Number"}
                        /> */}
                        <NewSelect selectData={data.iecArr || []} name={"iecDocument"} value={data["iecDocument"]} onChange={handleChange}
                          error={errors["iecDocument"]} label={"Enter IEC Number"} optionLabel={"iec"} optionValue={"iec"}
                        />
                      </div>
                      <div className="form-floating mb-2 w-50 position-relative">
                        <NewInput isDisabled={true} type="text" id={"cinDocument"} name={"cinDocument"} value={data["cinDocument"]} onChange={handleChange}
                          error={errors["cinDocument"]} label={"Enter CIN Number"}
                        />
                      </div>
                      {FieldTypes[data.workField]["typeId"] / 1 == 20 ? (
                        <div className="form-floating mb-2 w-50 position-relative">
                          <NewInput type="text" id={"gstDocument"} name={"gstDocument"} value={data["gstDocument"]} onChange={handleChange}
                            error={errors["gstDocument"]} label={"Enter GST Number"}
                          />
                        </div>
                      ) : (
                        <div className="form-floating mb-2 w-50 position-relative">
                          <NewSelect selectData={panArr} name={"panDocument"} value={data["panDocument"]} onChange={handleChange}
                            error={errors["panDocument"]} label={"Select PAN Number"} optionLabel={"name"} optionValue={"name"}
                          />
                          {/* <NewInput isDisabled={true} type="text" id={"panDocument"} name={"panDocument"} value={data["panDocument"]} onChange={handleChange}
                            error={errors["panDocument"]} label={"Enter PAN Numberss"}
                          /> */}
                        </div>
                      )}
                      <div className="form-floating mb-2 w-50 position-relative">
                        <NewInput type="text" id={"aadharDocument"} name={"aadharDocument"} value={data["aadharDocument"]} onChange={handleChange}
                          error={errors["aadharDocument"]} label={"Enter Aadhar Number"}
                        />
                      </div>
                    </>
                  ) : null}
                  {/* {kycDocTypes.map((item, index) => (
                  <div className="form-floating mb-2 w-50 position-relative">
                    <NewInput type="text" id={item.alt} name={item.alt} value={data[item.alt]} onChange={handleChange}
                      error={errors[item.alt]}
                      label={item.name + (companyTypes[data.organizationType][item.alt]
                        && !companyTypes[data.organizationType][item.alt + "Ignore" + data.workField] ? ' *' : "")}
                    />
                  </div>
                ))} */}
                </>
              )
            }
            {stepperProgress == 3 && (
              <>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type="text" name={"companyName"} value={data["companyName"]} onChange={handleChange}
                    label={"Company Name *"} error={errors["companyName"]} />
                  {/* <input
                  type="text"
                  className={`form-control ${errors["companyName"] ? "border-danger mb-0" : ""}`}
                  name={"companyName"}
                  value={data["companyName"]}
                  onChange={handleChange}
                  placeholder={"Company Name *"}
                /> */}
                  {/* <label htmlFor="floatingInput">{"Company Name"}
                  {astrix}
                </label> */}
                  {/* {errors["companyName"] ? (
                  <span className="text-danger fontValid">{errors["companyName"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["companyName"] && (
                  toolTip(errors["companyName"])
                )} */}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  {/* <SelectField
                  Label={`Industry Type`}
                  AriaLabel="industryType"
                  Id="industryType"
                  Name="industryType"
                  Value={data.industryType}
                  OnChange={handleChange}
                  Astrix={astrix}
                  ValidError={errors.industryType}
                >
                  <option value="">Select Industry Type</option>
                  {industryData.length
                    ? industryData.map((item) => (
                      <option value={item.value} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </SelectField> */}
                  {/* <NewSelect
                    label={"Select Industry Type"}
                    selectData={industryData} name="industryType" value={data.industryType} onChange={handleChange}
                    optionLabel={"name"} optionValue={"value"} error={errors["industryType"]} /> */}
                  {data.categoryOfExporters ?
                    <NewInput isDisabled label={'Business Category'} name={'categoryOfExporters'} value={data.categoryOfExporters} onChange={handleChange} /> : null}
                  {/* <select
                  className={" form-control" + (!errors.industryType ? '' : ' border-danger')} name="industryType" value={data.industryType} onChange={handleChange} >
                  <option value="">Select Industry Type</option>
                  {industryData.length
                    ? industryData.map((item) => (
                      <option value={item.value} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </select> */}
                  {/* {errors["industryType"] ? (
                  <span className="text-danger fontValid">{errors["industryType"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["industryType"] && (
                  toolTip(errors["industryType"])
                )} */}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  {/* <SelectField
                  Label="Country "
                  AriaLabel="country"
                  Id="country"
                  Name="country"
                  Value={data.country}
                  OnChange={handleChange}
                  Astrix={astrix}
                  ValidError={errors.country}
                >
                  <option value="">Select Country</option>
                  {countryData.length
                    ? countryData.map((item) => (
                      <option value={item.sortname} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </SelectField> */}
                  <NewSelect
                    label={"Select Country *"}
                    selectData={countryData} name="country" value={data.country} onChange={handleChange}
                    optionLabel={"name"} optionValue={"sortname"} error={errors["country"]} />
                  {/* <select
                  className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange} >
                  <option value="">Select Country</option>
                  {countryData.length
                    ? countryData.map((item) => (
                      <option value={item.sortname} key={item}>
                        {item.name}
                      </option>
                    ))
                    : null}
                </select> */}
                  {/* {errors["country"] ? (
                  <span className="text-danger fontValid">{errors["country"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["country"] && (
                  toolTip(errors["country"])
                )} */}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type="text" name={"companyAddress"} value={data["companyAddress"]} onChange={handleChange}
                    label={"Company Address *"} error={errors["companyAddress"]} />
                  {/* <input
                  type="text"
                  className={`form-control ${errors["companyAddress"] ? "border-danger" : ""}`}
                  name={"companyAddress"}
                  value={data["companyAddress"]}
                  onChange={handleChange}
                  placeholder={"Company Address *"}
                /> */}
                  {/* <label htmlFor="floatingInput">{"Company Address"}
                  {astrix}
                </label> */}
                  {/* {errors["companyAddress"] ? (
                  <span className="text-danger fontValid">{errors["companyAddress"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["companyAddress"] && (
                  toolTip(errors["companyAddress"])
                )} */}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type="text" name={"companyCity"} value={data["companyCity"]} onChange={handleChange}
                    label={"Company City *"} error={errors["companyCity"]} />
                  {/* <input
                  type="text"
                  className={`form-control ${errors["companyCity"] ? "border-danger" : ""}`}
                  name={"companyCity"}
                  value={data["companyCity"]}
                  onChange={handleChange}
                  placeholder={"Company City *"}
                /> */}
                  {/* {errors["companyCity"] ? (
                  <span className="text-danger fontValid">{errors["companyCity"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["companyCity"] && (
                  toolTip(errors["companyCity"])
                )} */}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type="text" name={"companyPostalCode"} value={data["companyPostalCode"]} onChange={handleChange}
                    label={"Company Postal Code *"} error={errors["companyPostalCode"]} />
                  {/* <input
                  type="text"
                  className={`form-control ${errors["companyPostalCode"] ? "border-danger" : ""}`}
                  name={"companyPostalCode"}
                  value={data["companyPostalCode"]}
                  onChange={handleChange}
                  placeholder={"Company Postal Code *"}
                /> */}
                  {/* {errors["companyPostalCode"] ? (
                  <span className="text-danger fontValid">{errors["companyPostalCode"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["companyPostalCode"] && (
                  toolTip(errors["companyPostalCode"])
                )} */}
                </div>
                {/* #3 */}
                {/* <div className="w-50">
                  <div className="form-floating d-flex justify-content-between mb-2 w-100 position-relative">
                    <NewSelect onChange={handleChange}
                      label={"Select Commodity"} value={data.category} optionLabel={"category"} optionValue={"id"}
                      selectData={commodityDropDown} name="commodity"
                    // onChange={(e) => { setSelectedCommodity(e.target.value) }}
                    />
                    <NewInput className="mr-1" type="text" name={""} value={commodityName} onChange={(e) => setCommodityName(e.target.value)}
                      label={"Commodity Name"} optionLabel={"category"} optionValue={"id"} isAstrix={"false"} />
                    <button height={"30px"} type="button" class="btn btn-primary">+</button>

                  </div>
                  <button height={"30px"} type="button" class="btn btn-secondary">Add More</button>
                </div> */}
                <>
                  <CommodityAdd commodityDropDown={commodityDropDown} onPlus={onPlus} onCancel={onCancel} indexOf={1} />
                  {additionalCommodity.map((component, index) => (
                    <React.Fragment key={index}>
                      {component}
                    </React.Fragment>
                  ))}

                  {addMorecommodity ? (<button onClick={handleAddMore} height={"20px"} type="button" class="mb-2 btn btn-secondary">Add More</button>) : <></>}
                </>

              </>
            )}

            {stepperProgress == 4 && (
              <>
                <div className="form-floating mb-2 w-50 position-relative row">
                  <div className="w-25 p-0 m-0">
                    <NewSelect selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                      name="nameTitle" value={data.nameTitle} optionLabel={'name'} optionValue={'name'}
                      onChange={handleChange} error={errors.nameTitle} />
                  </div>
                  <div className="w-75 p-0 m-0">
                    <NewSelect selectData={data.directorArr || []} name={"contactPersonDropDown"} value={data.contactPersonDropDown}
                      optionLabel={'name'} optionValue={'name'} onChange={handleChange}
                      error={errors.contactPersonDropDown} label={"Select Name*"}
                    />
                  </div>
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  {data.contactPersonDropDown === "Other" ? (
                    <NewInput name={'contactPerson'} value={data.contactPerson} onChange={handleChange}
                      error={errors.contactPerson} label={"Full Name*"}
                    />
                  ) : null}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type={"text"} name={"designation"}
                    value={data["designation"]}
                    onChange={handleChange} label={"Designation *"} error={errors["designation"]} />
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type={"email"} name={"email"}
                    value={data["email"]}
                    onChange={handleChange} label={"Email Id *"} error={errors["email"]} />
                  {data.email ? (
                    <i
                      style={{
                        "left": "90%",
                        "top": "0.8rem"
                      }}
                      onClick={() => sendCombinedOTP()}
                      className={`position-absolute cursor fa font-size-18 ${isEmailVerified ? "fa-check" : ""} ${isEmailVerified ? "text-success" : ""}`}></i>
                  ) : null}
                </div>
                <div className="form-floating mb-2 w-50 position-relative">
                  <InputWithSelect
                    selectData={countryData} selectName={"phoneCode"} selectValue={data.phoneCode} optionLabel={"phonecode"}
                    optionValue={'phonecode'}
                    type="number" name={"contactNo"} value={data["contactNo"]}
                    onChange={handleChange}
                    label={"Mobile Number *"} error={errors["contactNo"]} />
                  {/* <select
                  disabled={isMobVerified} className={"form-control w-25" + (errors.phoneCode ? " border-danger" : "")}
                  name="phoneCode" value={data.phoneCode} onChange={handleChange}>
                  {countryData.length ? countryData.map((item, index) => (
                    <option value={item.phonecode}>{`+${item.phonecode}`}</option>
                  ))
                    : ""}
                </select> */}
                  {/* <input
                  disabled={isMobVerified}
                  type="number"
                  className={`form-control w-75 ${errors["contactNo"] ? "border-danger" : ""}`}
                  name={"contactNo"}
                  value={data["contactNo"]}
                  onChange={handleChange}
                  placeholder={"Mobile Number *"}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      sendOtp("mob")
                    }
                  }}
                /> */}
                  {/* {errors["contactNo"] && (
                  toolTip(errors["contactNo"])
                )} */}
                  {data.contactNo ? (
                    <i
                      style={{
                        "left": "90%",
                        "top": "0.8rem"
                      }}
                      onClick={() => sendCombinedOTP()}
                      className={`position-absolute cursor fa font-size-18 ${isMobVerified ? "fa-check" : ""} ${isMobVerified ? "text-success" : ""}`}></i>
                  ) : null}
                </div>
                {/* <div className="form-floating mb-4 w-50">
                <input
                  type="text"
                  className={`form-control ${errors["referalCode"] ? "border-danger" : ""}`}
                  name={"referalCode"}
                  value={data["referalCode"]}
                  onChange={handleChange}
                  placeholder={"User Referral Code (If Available)"}
                />
                {errors["referalCode"] ? (
                  <span className="text-danger fontValid">{errors["referalCode"]}</span>
                ) : (
                  ""
                )}
              </div> */}
              </>
            )}

            {stepperProgress == 5 && (
              <>
                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type="password" name={"password"}
                    value={data["password"]}
                    onChange={handleChange}
                    label={"Enter password *"} error={errors["password"]} />
                  {/* <input
                  type="password"
                  className={`form-control ${errors["password"] ? "border-danger" : ""}`}
                  name={"password"}
                  value={data["password"]}
                  onChange={handleChange}
                  placeholder={"Enter password *"}
                /> */}
                  {/* <label htmlFor="floatingInput">{"Enter password "}{astrix}
                </label> */}
                  {/* {errors["password"] ? (
                  <span className="text-danger fontValid">{errors["password"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["password"] && (
                  toolTip(errors["password"])
                )} */}
                </div>

                <div className="form-floating mb-2 w-50 position-relative">
                  <NewInput type="password" name={"repassword"}
                    value={data["repassword"]}
                    onChange={handleChange}
                    label={"Re-enter password *"} error={errors["repassword"]} />
                  {/* <input
                  type="password"
                  className={`form-control ${errors["repassword"] ? "border-danger" : ""}`}
                  name={"repassword"}
                  value={data["repassword"]}
                  onChange={handleChange}
                  placeholder={"Re-enter password *"}
                /> */}
                  {/* <label htmlFor="floatingInput">{"Re-enter password "}{astrix}
                </label> */}
                  {/* {errors["repassword"] ? (
                  <span className="text-danger fontValid">{errors["repassword"]}</span>
                ) : (
                  ""
                )} */}
                  {/* {errors["repassword"] && (
                  toolTip(errors["repassword"])
                )} */}
                </div>
              </>
            )}

            {stepperProgress === 5 ? (
              <>
                <div class="form-check mb-2 w-50">
                  <label className={`font-wt-400 mb-4 cursor ${data.referalCode ? 'text-color1' : ""}`}
                    onClick={() => toggleReferralPopup(true)}
                  ><b>{data.referalCode ? "Refferal Code Applied" : "I have a referral code"}</b>
                    {!data.referalCode ? (
                      <i class="fas fa fa-arrow-right mx-1"></i>) : " !"}
                  </label>
                  <label class="form-check-label text-center" for="tcAccept">
                    <input class="form-check-input new-check-box" type="checkbox" name="tcAccept" id="tcAccept"
                      onChange={handleChange}
                      checked={data.tcAccept}
                    />
                    I Agree to the
                    <span
                      onClick={() => { window.open("https://www.tradereboot.com/privacy-policy", "_blank") }}
                      className="text-primary cursor"> Terms & Conditions</span> and <span
                        onClick={() => { window.open("https://www.tradereboot.com/privacy-policy", "_blank") }}
                        className="text-primary cursor">Privacy policy</span> of Trade reboot fin</label>
                </div>
                <button type="button"
                  disabled={!data.tcAccept}
                  onClick={handleNext}
                  class={`new-btn py-2 w-50 mb-4 text-white`}>
                  {"Register"}
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={handleNext}
                className={`new-btn py-2 w-50 mb-4 text-white`}>{"Continue"}</button>
            )}

            <label className="font-wt-400" >Already registered? <span
              onClick={() => { window.location = 'login' }}
              className="text-custom2 font-wt-500 cursor ">Login</span></label>
          </div>

        </div>

      </div>
      {/* <div className="col-md-4 " style={{}}>
            <div className="">
              <h5 style={{}} className="font-size-30 font-wt-600 my-4 py-4 text-center signup-h">Sign up</h5>
              {onboardingTabs.map((item, index) => {
                return (
                  <>
                    <div style={{
                      "border": `1px solid ${index <= stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                      "background": index <= stepperProgress ? "#5CB8D3" : "#BDBDBD",
                      "border-radius": "100%", "height": '1.8rem', "width": '1.8rem'
                    }}>
                      <label className="stepper-label font-size-16 font-wt-400"
                      >{item.name}</label>
                    </div>

                    <div className={`${item.last ? "d-none" : "" + "stepper-bar"}`} style={{
                      "border": `1px solid ${index < stepperProgress ? "#5CB8D3" : "#BDBDBD"}`,
                      "background": index < stepperProgress ? "#5CB8D3" : "#BDBDBD"
                    }} />
                  </>
                )
              })}
            </div>
          </div> */}
    </>
  )

}

export default NewRegistration;