import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import toastDisplay from '../../utils/toastNotification';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/manageChannelPartnerLeads';
import customStyles from '../dataTablesColumsMap/customTableCss';
import validate from '../../utils/validation_files/channelPartnerProfile';
import ReactCountryFlag from "react-country-flag";
import avatarUrl from '../../utils/makeAvatarUrl';
import { setShowViewProfileModal } from '../../store/actions/action';

const PAGE_CAPACITY = 7;

const ManageLeads = ({ userTokenDetails, status, setShowViewProfileModal, showViewProfileModal }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [actionButton, setActionButton] = useState({ modal: false, data: {} });
  const [refresh, setrefresh] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [searchKey, setsearchKey] = useState("")
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [addMoreProfile, setAddMoreProfile] = useState({ modal: false, data: {} });

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})
    //---------------------------------------------------------------------------------------------------------------------

  }, [refresh, filterData]);

  useEffect(() => {
    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    call('POST', 'getChannelPartnerLead').then((result) => {
      setUserData(result)
    }).catch((e) => {
      console.log('error in getChannelPartnerLead', e);
    })

  }, [])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitForm();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);


  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": page,
      "resultPerPage": perPage,
      "status": status
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerLead', objectAPI).then((result) => {
      console.log('running getChannelPartnerLead api-->', result);
      settableData(formatDataFortable(result.data))
      setTotalRows(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerLead', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": page,
      "resultPerPage": newPerPage,
      "searchUserType": filterData.searchUserType,
      "status": status
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerLead', objectAPI).then((result) => {
      settableData(formatDataFortable(result.data))
      setTotalRows(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerLead', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      dataArray[i].manageLeads = true;
      tempObj.email = dataArray[i].userDetails.email_id;
      tempObj.name = dataArray[i].userDetails.company_name;
      tempObj.user_address = dataArray[i].userDetails.user_address;
      tempObj.country = (<span className="shadow">
        <ReactCountryFlag
          countryCode={dataArray[i].userDetails.country_code ? dataArray[i].userDetails.country_code : ''}
          style={{ width: '25px', height: '25px' }} svg />
      </span>)
      tempObj.status = dataArray[i].status;
      if (dataArray[i].status === 1 || dataArray[i].status === 2) {
        tempObj.action = <button className="btn btn-success btn-sm"
          onClick={() => {
            { setAddMoreProfile({ modal: true, data: dataArray[i] }); setShowViewProfileModal(true, { "data": dataArray[i], "page": (dataArray[i].status == 1 || dataArray[i].status == 2) ? 1 : 2 }) }
          }}>
          <i class="fa fa-pencil"></i>
        </button>
      }
      else {
        tempObj.action = 'NA'
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  const reset = () => {
    setFilterData({ searchUserType: "", search: "" })
    setsearchKey("")
  }

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  function submitForm() {

    data.adminProfile = true;
    data.typeId = 20;
    data.techType = 4;
    call('POST', 'registration', data).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  const setUserStatus = (userData, userID, status) => {
    call('POST', 'setUserStatus', userData).then((result) => {
      toastDisplay('Successfully Added', 'success');
    }).catch((e) => {
      console.log('error in setUserStatus', e);
    })
  }

  const handleAvatar = (event) => {
    event.persist();
    console.log('fileUPloaded:==>', event.target.files[0])
    if (event.target.files && event.target.files[0] && event.target.files[0].size && event.target.files[0].size <= 5242880) {
      setError(errors => ({ ...errors, [event.target.name]: "" }))
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setData(values => ({ ...values, avatarBase64: reader.result, avatarFile: event.target.files[0] }));
      };
    } else {
      setError(errors => ({ ...errors, [event.target.name]: "Max size limit is 5MB" }))
      setData(values => ({ ...values, avatarBase64: "" }));
    }
  }



  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Html body
  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="d-flex mt-3 mb-3">
        <div className="ml-auto">
          <ul className="range-slider">
            <li>
              <div className="d-md-flex">

                <div className=" ml-1 position-relative input-margin">
                  <input placeholder="Search" type="text" name="search" className="form-control" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                  <button type="button" onClick={() => {
                    setFilterData({
                      ...filterData,
                      "search": searchKey
                    })
                  }}><i class="fas fa-search fa-lg"></i></button>
                </div>
              </div>
            </li>
            <li>
            </li>
            <li>
            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
          </ul>
        </div>
      </div>


      <div className="d-flex mt-3 custom-tab-content">
        <div className="row w-100">
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={tableData}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
              persistTableHead
              highlightOnHover
              onSort={handleSort}
              sortServer
              striped
              noHeader
              customStyles={customStyles}
            />
          </div>

        </div>
      </div>

      {actionButton.modal &&
        <div className={"modal show"}>
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Register Channel Partner</h4>
                <button type="button" className="close" onClick={() => { setActionButton({ modal: false, data: {} }) }} >×</button>
              </div>
              <div className="modal-body calc-inner-modal">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <h3 className="green border-bottom pb-2">Create Profile :</h3>
                        </div>
                        <div className="col-md-3">
                          <div className="profile-sidebar">
                            <div className="profile-userpic">
                              <img src={data.avatarBase64 ? data.avatarBase64 : avatarUrl(data.userAvatar)} id="myImg" className="img-responsive" alt="Avatar" />
                              <span className="icon-edit-profile">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                <input type="file" accept=".png,.jpg" id="file_3" dir="2" name="avatar" onChange={handleAvatar} />
                              </span>
                            </div>
                            <div className="profile-userbuttons text-center mt-2 ">
                              <label>Avatar {astrix} </label>
                            </div>
                            {error.avatar && <p className="text-danger error-contract">{error.avatar}</p>}
                          </div>

                        </div>
                        <div className="col-md-9">
                          <form className="pr-4">
                            <div className="row pt-3">
                              <div className="form-group col-md-6">
                                <label>Select Role {astrix}  </label>
                                <select className={" form-control" + (!error.role ? '' : ' border-danger')} name="role" value={data.role} onChange={handleChange} >
                                  <option value="" selected>Select Role</option>
                                  <option value="introducer">Introducer</option>
                                  <option value="channelPartner">Channel Partner</option>
                                  <option value="techAggregator">Tech Aggregator</option>
                                </select>
                                {error.role && <p className="text-danger error-contract">{error.role}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Company Name {astrix} </label>
                                <input type="text" className={" form-control" + (!error.companyName ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="companyName" value={data.companyName} onChange={handleChange} required disabled />
                                {error.companyName && <p className="text-danger error-contract">{error.companyName}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Contact Person {astrix} </label>
                                <input type="text" className={" form-control" + (!error.contactPerson ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="contactPerson" value={data.contactPerson} onChange={handleChange} required />
                                {error.contactPerson && <p className="text-danger error-contract">{error.contactPerson}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Email ID {astrix} </label>
                                <input type="text" className={" form-control" + (!error.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={data.email} onChange={handleChange} required disabled />
                                {error.email && <p className="text-danger error-contract">{error.email}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Password {astrix}</label>
                                <input type="password" className={" form-control" + (!error.password ? '' : ' border-danger')} placeholder="Min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol" name="password" value={data.password} onChange={handleChange} required />
                                {error.password && (<p className="text-danger error-contract">{error.password}</p>
                                )}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Re-enter password {astrix}</label>
                                <input type="password" className={" form-control" + (!error.passwordConfirm ? '' : ' border-danger')} placeholder="Re-enter password.." name="passwordConfirm" value={data.passwordConfirm} onChange={handleChange} required />
                                {error.passwordConfirm && (<p className="text-danger error-contract">{error.passwordConfirm}</p>
                                )}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Contact No. {astrix} </label>
                                <input type="number" pattern=".{10,13}" className={" form-control" + (!error.contactNo ? '' : ' border-danger')} placeholder="Enter Contact No" name="contactNo" value={data.contactNo} onChange={handleChange} required />
                                {error.contactNo && <p className="text-danger error-contract">{error.contactNo}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Country {astrix}  </label>
                                <select className={" form-control" + (!error.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange} >
                                  <option value="" selected>Select Country</option>
                                  {countryData.map((country) => {
                                    return (
                                      <option value={country.sortname} >{country.name}</option>
                                    )
                                  })}
                                </select>
                                {error.country && <p className="text-danger error-contract">{error.country}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Select Entity {astrix}  </label>
                                <select className={" form-control" + (!error.organizationType ? '' : ' border-danger')} name="organizationType" value={data.organizationType} onChange={handleChange} >
                                  <option value="" selected>Select entity</option>
                                  <option value="individual">Individual</option>
                                  <option value="soleProprietorship">Sole proprietorship</option>
                                  <option value="partnership">Partnership</option>
                                  <option value="llp">LLP</option>
                                  <option value="pvtPubLtd">Pvt / Pub Ltd</option>
                                </select>
                                {error.organizationType && <p className="text-danger error-contract">{error.organizationType}</p>}
                              </div>

                              <div className="form-group col-md-12">
                                <label>Address {astrix} </label>
                                <textarea className={" form-control" + (!error.companyAddress ? '' : ' border-danger')} placeholder="Enter Flat / House / Office No" name="companyAddress" onChange={handleChange} value={data.companyAddress} required />
                                {error.companyAddress && <p className="text-danger error-contract">{error.companyAddress}</p>}
                              </div>

                              {(data.country === 'IN') && data.organizationType &&
                                <><h3 className="mb-4" id="KYCDoc" >KYC Documents {astrix}</h3>
                                  <div id="newDoc">
                                    {data.organizationType === 'individual' && <div className="form-group pb-0 mb-0">
                                      <div className="row">
                                        <ul className="other-documents">
                                          <li style={{ "min-width": "350px" }}>
                                            <label className="mb-0" >
                                              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                              PAN Number
                                            </label>
                                          </li>
                                          <li style={{ "min-width": "350px" }}>
                                            <input type="text" maxLength={10} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!error["panDocumentName"] ? '' : ' border-danger')} placeholder={"Enter PAN Number"} name="panDocumentName" onChange={handleChange} required />
                                            {error["panDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{error["panDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"PAN Number"}</p>}
                                          </li>
                                        </ul>
                                        <hr className="" />
                                      </div>
                                    </div>}
                                    {(data.organizationType !== 'individual') && <div className="form-group pb-0 mb-0">
                                      <div className="row">
                                        <ul className="other-documents">
                                          <li style={{ "min-width": "350px" }}>
                                            <label className="mb-0" >
                                              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                              PAN Number
                                            </label>
                                          </li>
                                          <li style={{ "min-width": "350px" }}>
                                            <input type="text" maxLength={10} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!error["panDocumentName"] ? '' : ' border-danger')} placeholder={"Enter PAN Number"} name="panDocumentName" onChange={handleChange} required />
                                            {error["panDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{error["panDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"PAN Number"}</p>}
                                          </li>
                                        </ul>
                                        <hr className="" />
                                      </div>
                                      <div className="row">
                                        <ul className="other-documents">
                                          <li style={{ "min-width": "350px" }}>
                                            <label className="mb-0" >
                                              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                              GST Number
                                            </label>
                                          </li>
                                          <li style={{ "min-width": "350px" }}>
                                            <input type="text" maxLength={15} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!error["gstDocumentName"] ? '' : ' border-danger')} placeholder={"Enter GST Number"} name="gstDocumentName" onChange={handleChange} required />
                                            {error["gstDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{error["gstDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"GST Number"}</p>}
                                          </li>
                                        </ul>
                                        <hr className="" />
                                      </div>
                                    </div>}
                                    {data.organizationType === 'pvtPubLtd' && <div className="form-group pb-0 mb-0">
                                      <div className="row">
                                        <ul className="other-documents">
                                          <li style={{ "min-width": "350px" }}>
                                            <label className="mb-0" >
                                              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                              CIN Number
                                            </label>
                                          </li>
                                          <li style={{ "min-width": "350px" }}>
                                            <input type="text" maxLength={21} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!error["cinDocumentName"] ? '' : ' border-danger')} placeholder={"Enter CIN Number"} name="cinDocumentName" onChange={handleChange} required />
                                            {error["cinDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{error["cinDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"CIN Number"}</p>}
                                          </li>
                                        </ul>
                                        <hr className="" />
                                      </div>
                                    </div>}
                                  </div>
                                </>}

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <label>Select User Type of Channel Partner</label>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="tradeTechType" id="tradeTechType" onChange={hanleDocChecks} checked={data.tradeTechType} />
                                    <label className="form-check-label" for="tradeTechType">Channel Partner Trade-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="finTechType" id="finTechType" onChange={hanleDocChecks} checked={data.finTechType} />
                                    <label className="form-check-label" for="finTechType">Channel Partner Fin-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="logiTechType" id="logiTechType" onChange={hanleDocChecks} checked={data.logiTechType} />
                                    <label className="form-check-label" for="logiTechType">Channel Partner Logi-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className=" col-md-12 row mt-2">
                                <div className=" col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="insureTechType" id="insureTechType" onChange={hanleDocChecks} checked={data.insureTechType} />
                                    <label className="form-check-label" for="insureTechType">Channel Partner Insure-Tech</label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-10 mt-1">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="termsAndCondition" id="termsAndCondition" onChange={hanleDocChecks} checked={data.termsAndCondition} />
                                  <label className="form-check-label" for="termsAndCondition">
                                    I acknowledge that i have read and fully understand the <a href="./assets/docs/Terms_of_Use.pdf" target="true">terms and conditions</a> of the
                                    tradereboot onboarding agreement and that i will comply with the same.
                                  </label>
                                </div>
                              </div>


                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer primary">
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => setActionButton({ modal: false, data: {} })} >Cancel</button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => handleSubmit()} disabled={(data.tradeTechType || data.finTechType || data.logiTechType || data.insureTechType) && data.termsAndCondition ? false : true} >Submit & Agree</button>
              </div>

            </div>
          </div>
        </div>
      }

      {addMoreProfile.modal &&
        <div className={"modal show"} id="registration" >
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Channel Partner Profile Details {addMoreProfile.data.email} </h4>
                <button type="button" className="close" onClick={() => { setAddMoreProfile({ modal: false, data: {} }) }} >×</button>
              </div>
              <div className="modal-body calc-inner-modal">
                <div className="accordionItem open">
                  <h2 className="accordionItemHeading" >Company Profile</h2>
                  <div className="accordionItemContent">
                    <div className="divider border-0">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{(addMoreProfile.data.name) ? addMoreProfile.data.name : "--"}</label>
                            <div className="col-md-12 "><span className="date-format">Company Name</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{(addMoreProfile.data.contact_person) ? addMoreProfile.data.contact_person : "--"}</label>
                            <div className="col-md-12 "><span className="date-format">Contact Person</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{(addMoreProfile.data.email) ? addMoreProfile.data.email : "--"}</label>
                            <div className="col-md-12 "><span className="date-format">Email</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{(addMoreProfile.data.name) ? addMoreProfile.data.user_address : "--"}</label>
                            <div className="col-md-12 "><span className="date-format">Address</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">
                              {(addMoreProfile.data.country_code) ? <span className="shadow">
                                <ReactCountryFlag
                                  countryCode={addMoreProfile.data.country_code}
                                  style={{ width: '25px', height: '25px' }} svg />
                              </span> : "--"}</label>
                            <div className="col-md-12 "><span className="date-format">Country</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{(addMoreProfile.data.contact_person) ? addMoreProfile.data.contact_person : "--"}</label>
                            <div className="col-md-12 "><span className="date-format">Contact No</span></div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => setAddMoreProfile({ modal: false, data: {} })} >Cancel</button>
                <button type="button" className="btn btn-primary btn-sm" onClick={() => setUserStatus(addMoreProfile.data, addMoreProfile.data.id, true)}>Approve</button>
                <button type="button" className="btn btn-danger btn-sm" onClick={() => setUserStatus(addMoreProfile.data, addMoreProfile.data.id, false)} >Reject</button>
              </div>

            </div>
          </div>
        </div>}

    </div >
  )
};

const mapStateToProps = state => {
  return {
    showViewProfileModal: state.showViewProfileModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLeads)
