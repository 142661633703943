import React from "react";
import { useState } from "react";


const FAQsData = [
  {
    Heading: "What are coins?",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingOne",
    FaqsCollapse: "CollapseOne"
  },
  {
    Heading: "How does coins work?",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingTwo",
    FaqsCollapse: "CollapseTwo"
  },
  {
    Heading: "How to add coins?",
    Description: "Fermentum porttitor faucibus ac commodo quis ullamcorper nunc. Tortor morbi arcu integer urna, euismod mauris scelerisque morbi sed. Commodo tellus at a et. Nibh pharetra volutpat cursus urna ultrices proin. Posuere quis elit urna arcu rhoncus.",
    FaqsHeading: "HeadingThree",
    FaqsCollapse: "CollapseThree"
  },
];

const FAQs = () => {

  const [accordion, setAccordion] = useState("HeadingOne");

  return (
    <>
      <div className='mt-4'>
        <h6>FAQs</h6>
        <div className="input-group mb-3 col-md-2 currency mt-3 px-0">
          <span className="input-group-text bg-white border-end-0" id="basic-addon1"><img src={"assets/images/fe_search.png"} alt="search" /></span>
          <input type="text" name='search'
            className="form-control border-start-0" placeholder="Search" />
        </div>

        <div className="accordion accordion-flush col-md-7 card p-4 border-0 borderRadius" id={"Faqs"}>
          {FAQsData.map((key) => {
            return (
              < div className="accordion-item border-0 rounded-0 border-bottom " key={key.Heading}>
                <h2 className="accordion-header" id={key.FaqsHeading}>
                  <button onClick={() => setAccordion(key.FaqsHeading)} className={`accordion-button border-0 font-size-14 text-secondary ${accordion !== key.FaqsHeading && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${key.FaqsCollapse}`} aria-expanded="true" aria-controls={key.FaqsCollapse} >
                    {key.Heading}
                  </button>
                </h2>
                <div id={key.FaqsCollapse} className={`accordion-collapse collapse ${accordion === key.FaqsHeading && "show"}`} aria-labelledby={key.FaqsHeading} data-bs-parent="#Faqs">
                  <div className="accordion-body pt-0">
                    <p className="font-size-14 text-secondary">
                      {key.Description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

    </>
  );
}
export default FAQs;