import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import call from '../../service';
import PaypalButton from './paypalbutton';
import toolTipText from '../../utils/toolTips/plans.json'

const Plans = ({ userTokenDetails, setplanName }) => {

  const [login, setlogin] = useState(false);
  const [showLoader, setshowLoader] = useState(true);
  const [planDetails, setplanDetails] = useState([]);
  const [planData, setplanData] = useState([]);
  const [planDatakeys, setplanDataKeys] = useState([]);
  const [paymentType, setpaymentType] = useState(1);
  const userTypeId = (userTokenDetails && userTokenDetails.type_id) ? userTokenDetails.type_id : null
  const userToken = (userTokenDetails && userTokenDetails.token) ? userTokenDetails.token : null
  const status = (userTokenDetails && userTokenDetails.status) ? userTokenDetails.status : null
  const userId = (userTokenDetails && userTokenDetails.user_id) ? userTokenDetails.user_id : null
  const [userActivePlan, setuserActivePlan] = useState({});
  // let [stanPlan, setstanPlan] = useState({});
  // let [proPlan, setproPlan] = useState({});
  // let [entrPlan, setentrPlan] = useState({});

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    if ((userToken !== undefined && userToken !== null) && (userTypeId !== undefined && userTypeId !== null) && (status !== undefined && status !== null)) {
      setlogin(true)
    } else {
      setlogin(false)
    }
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'v1/plan/list', { "userType": 14 }).then((result) => {
      console.log("API result gettraderplans:-->", result);
      result = result.results
      let tempDetails = [], tempData = []

      setplanDataKeys(Object.keys(result[0].features))

      for (let i = 0; i < result.length; i++) {
        if (result[i].showPlan) {
          tempData.push(result[i].features)
          delete result[i].features
          tempDetails.push(result[i])
        }
      }
      setplanData(tempData)
      setplanDetails(tempDetails)

      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying gettraderplans:", e);
    })

    call('GET', 'v1/plan').then((result) => {
      console.log("API result in user plans:-->", result);
      setuserActivePlan(result)
      setplanName("Subscribtion Plans (Active: " + result.name + ")")
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying gettraderplans:", e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  console.log("planDetails-->", planDetails)
  console.log("planData-->", planData)
  // console.log("planDatakeys-->", planDatakeys)

  function toolTip(inputName) {
    return (
      <span className="help-icon float-right" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className={"col-md-12 text-center " + (login ? "mt-5" : "")}>
        {/* <h1 className="text-primary" style={{ "text-decoration": "underline" }}>Plans for Traders</h1> */}
      </div>
      <div className="d-flex text-center db_pricig pt-1 pb-2 col-md-12">
        <div className="sticky-tabs d-block col-md-12" id={1}>
          <div className="">
            <div className="container d-md-flex">
              <div className="table-cell">
                <div className="card card-pricing text-center ">
                  <div className="bg-transparent card-header">
                    <h1 className="h1">Pricing Plans</h1>
                    <span className="heading h6">Payment type</span>
                    <ul className="list-unstyled">
                      <li>{paymentType === 2 ? <i class="fa fa-toggle-on cursor-pointer text-primary fa-2x" aria-hidden="true" onClick={() => setpaymentType(1)}></i> :
                        paymentType === 1 ? <i class="fa fa-toggle-off cursor-pointer text-primary fa-2x" aria-hidden="true" onClick={() => setpaymentType(2)}></i> : ""}</li>
                    </ul>
                  </div>
                  <div className="card-body p-0">
                    <div className="view-feature">
                      {paymentType === 2 ? "Subscription" :
                        paymentType === 1 ? "Single Month" : "NA"}
                    </div>
                  </div>
                </div>
              </div>
              {(planDetails && planDetails.length) ? planDetails.map((plan) => {
                return (<>
                  {/* <div className={"table-cell " + ((plan.id / 1 === userActivePlan / 1) ? "current" : "")}> */}
                  <div className="table-cell">
                    <div className="card card-pricing text-center ">
                      <div className="bg-transparent card-header">
                        <h1 className="h1">{plan.name}</h1>
                        <span className="heading h6">${plan.price}</span>
                        <ul className="list-unstyled">
                          <li> Per Month </li>
                        </ul>
                      </div>
                      <div className="card-body p-0">

                        {(login) ? <>
                          {(plan.groupWeight / 1 < userActivePlan.groupWeight / 1) && (plan.id / 1 !== userActivePlan.id / 1) ?
                            <button className="btn btn-sm btn-success col-md-12" disabled><i class="fas fa-info-circle"></i> Higher Plan Active</button> :
                            (plan.groupWeight / 1 === userActivePlan.groupWeight / 1) && (plan.id / 1 === userActivePlan.id / 1) ?
                              <button className="btn btn-sm btn-success col-md-12" disabled><i class="fa fa-check-circle" aria-hidden="true"></i> Active Plan</button> :
                              <div className="view-feature">
                                <PaypalButton product={{
                                  "userId": userId,
                                  "id": plan.id,
                                  "price": plan.price,
                                  "name": plan.name,
                                  "plan_code": plan.code,
                                  "description": plan.description,
                                  "planId": plan.product_id,
                                  "paymentType": paymentType
                                }} />
                              </div>
                          }
                        </> :
                          <Link to="/registration"><button className="btn btn-sm btn-primary">Sign-Up Now</button></Link>}

                      </div>
                    </div>
                  </div>
                </>)
              }) : ""}
            </div>
          </div>
          <div className="features">
            <div className="container p-0">
              <table className="price-table" cellSpacing={0}>
                <tbody>
                  {(planDatakeys && planDatakeys.length) ? planDatakeys.map((key) => {
                    // console.log("key-->", key)
                    // console.log("planData[0].key.desc -->", (planData.length) ? planData[0][key].desc : "")
                    return (<>
                      <tr>
                        <td width="20%">{(planData.length) ? planData[0][key].desc : ""}{toolTip(key)}</td>
                        {(planData && planData.length) ? planData.map((plan, index) => {
                          return (<>
                            <td width="20%">
                              {(plan[key].totalLimit) ?
                                <span class="fa-stack" style={{ "vertical-align": "top" }}>
                                  <i class={"far fa-circle fa-stack-2x" + (index === 0 ? " text-danger" : index === 1 ? " text-warning" : index === 2 ? " text-success" : "")}></i>
                                  <i class={(plan[key].totalLimit / 1) > 0 ? "" : "fas fa-infinity fa-stack-1x"}>{(plan[key].totalLimit / 1) > 0 ? plan[key].totalLimit : ""}</i>
                                </span> :
                                (plan[key].enable) ?
                                  <i class="fas fa-check  text-success"></i> :
                                  <i class="fas fa-times  text-danger"></i>}
                            </td>
                          </>)
                        }) : ""}
                      </tr>
                    </>)
                  }) : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};




export default Plans
