import React from "react";

const ViewFinancers = ({ limitinvoice, setLimitinvoice, children, closeSuccess }) => {
  return (
    <>
      <div className={`modal fade ${limitinvoice && "show"}`} style={{ display: "block" }}>
        <div className={"modal-dialog modal-lg"}>
          <div className="modal-content submitmodal pb-4">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" aria-label="Close" onClick={() => closeSuccess()}></button>
            </div>
            <div className="modal-body text-center">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewFinancers;


