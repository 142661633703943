import React from 'react'
import call from '../../service'
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'
import { SelectForTable } from '../../utils/newInput'
import toastDisplay from '../../utils/toastNotification'
import { ToastContainer } from 'react-toastify'
import { MoonLoader } from 'react-spinners'

const SubAdminPopup = ({ subadminpopup, togglesubadminpopup, setShowLoader, refreshtable }) => {
  const [data, setdata] = useState({})
  const [errors, setErrors] = useState({})
  const [dbdata, setdbdata] = useState([])
  const [callHistoryPopup, toggleCallHistoryPopup] = useState({ show: false, data: [], ADMIN_ID: '' })
  const [activeIndex, setActiveIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null)
  const [salesPerson, setSalesPerson] = useState([])
  const [componentLoader, setcomponentLoader] = useState(false)
  const [historyLoader, sethistoryLoader] = useState(false)
  const getsubadminsByUser = () => {
    setcomponentLoader(true)
    call('POST', 'getsubadminsByUser', { userId: subadminpopup.userId }).then(result => {
      setcomponentLoader(false)
      setdbdata(result)
      setEditIndex(null)
    }).catch(e => {
      setcomponentLoader(false)
    })
  }
  useEffect(() => {
    setcomponentLoader(true)
    call("POST", 'getSubAdminUser', {}).then(res => {
      setcomponentLoader(false)
      setSalesPerson(res.data)
    }).catch(err => setcomponentLoader(false))
  }, [])
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name) {
      try {
        const res = await call('POST', 'updatesubadmin', { subAdminId: event.target.value, colName: editIndex === 0 ? 'LeadAssignedTo' : 'SecondaryLeadAssignedTo', userId: subadminpopup.userId })
        setdata({ ...data, [event.target.name]: event.target.value })
        setErrors({ ...errors, [event.target.name]: "" })
        toastDisplay(res, "success")
        getsubadminsByUser()
        if (refreshtable) {
          refreshtable()
        }
        return

      } catch (err) {
        toastDisplay("Something went wrong", "error")
        return
      }
    }
    setdata({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  useEffect(() => {
    if (subadminpopup.userId) {
      getsubadminsByUser()
    }
  }, [subadminpopup])
  const handleAccordianClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  async function handleCallHistoryPopup(ADMIN_ID) {
    sethistoryLoader(true)
    let apiResp = await call('POST', 'getUserOnboardedHistoryAdminWise', {
      EXPORTER_CODE: subadminpopup.userId,
      ADMIN_ID
    })
    // console.log("getTransactionHistoryForInvoiceLimit api resp====>", itemData, apiResp);
    sethistoryLoader(false)
    toggleCallHistoryPopup({ show: true, data: apiResp, ADMIN_ID: ADMIN_ID })
  }
  return (
    <div className={`modal fade ${subadminpopup.show && "show"}`} style={subadminpopup.show ? { display: "block", "zIndex": '100001' } : {}}>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <div className="modal-dialog modal-md mr-0 my-0">
        <div className="modal-content submitmodal p-0"
        >

          <div className="modal-header border-0">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <label
                className="font-size-16 font-wt-600 text-color-value mx-3"
              >Sub admins</label>
              <div className="modal-header border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => togglesubadminpopup({ show: false, data: [], userId: '' })}></button>
              </div>
            </div>
          </div>

          <div className="modal-body p-0 ">
            <div className='px-4'>
              {componentLoader ?
                <div className="d-flex flex-row justify-content-center align-items-center h-100">
                  <MoonLoader
                    color={"#1B94B7"}
                    loading={componentLoader}
                    size={80}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
                :

                dbdata.map((item, index) => {
                  return (
                    <div className={`${dbdata.length - 1 == index ? '' : 'border-bottom'} p-4`}>
                      <div className='d-flex gap-4 align-items-center'>
                        <div className='d-flex align-items-center justify-content-center gap-2'>
                          {editIndex === index ?
                            <>
                              <SelectForTable selectData={salesPerson}
                                name={`subAdminId`}
                                optionLabel={"contact_person"}
                                optionValue={'id'}
                                value={data.subAdminId}
                                onChange={handleChange} />
                              <img src='assets/images/cross.png' className='cursor' onClick={() => setEditIndex(null)} />
                            </> :
                            <>
                              <label className='subadminNameLabel mb-0'>{item.name}</label>
                              <img src='assets/images/edit.png' className='cursor' onClick={() => {
                                setEditIndex(index)
                                setdata({
                                  ...data,
                                  subAdminId: item.id
                                })
                              }} />
                            </>
                          }

                        </div>

                        <div className={item.admin_priority === 'Primary' ? 'bg-2ECC71 br-1' : 'bg-FFB801  br-1'}>
                          <label className='subadminPriorityLabel mb-0 p-2'>{item.admin_priority}</label>
                        </div>
                      </div>
                      <span>
                        <label className='subadminlabel'>No. Of Task Created</label>
                        <label className='subadminlabel'>:</label>
                        <label className='subadminValue'>{item.number_of_tasks}</label>
                      </span>
                      <div className='d-flex align-items-center mt-1 cursor' onClick={() => {
                        if (callHistoryPopup.ADMIN_ID === item.id) {
                          toggleCallHistoryPopup({ show: false, data: [], ADMIN_ID: '' })
                        } else {
                          handleCallHistoryPopup(item.id)
                        }
                      }}>
                        <label className='subadminShowHistoryLabel mb-0 cursor'>{callHistoryPopup.ADMIN_ID === item.id ? 'Hide History' : `Show History`}</label>
                        <img src='assets/images/ep_arrow-down-bold.png' className='cursor' style={callHistoryPopup.ADMIN_ID === item.id ? { marginTop: 3 } : { transform: "rotate(-90deg)", marginTop: 3 }} />
                      </div>
                      {callHistoryPopup.ADMIN_ID === item.id ?
                        <div className='p-2'>


                          {callHistoryPopup.data.length ? callHistoryPopup.data.map((item, index) => {
                            return (
                              <div className='d-flex flex-row ml-3'>
                                <div className="progressBarContainer2">
                                  <div className="progressBarInnerCircle">
                                  </div>
                                </div>
                                <div className='pl-4 pt-4 mt-2'>
                                  <p className='font-size-14 text-color1 font-wt-500 mb-0'>
                                    {item.CREATED_AT ? moment(item.CREATED_AT).format('Do MMM, YYYY - hh:mm A') : '-'}
                                    <span><img src='assets/images/arrow.png' className='cursor' onClick={() => handleAccordianClick(index)} /></span>
                                  </p>
                                  {activeIndex === index &&
                                    <div>
                                      {historyLoader ?
                                        <div className="d-flex flex-row justify-content-center align-items-center h-100">
                                          <MoonLoader
                                            color={"#1B94B7"}
                                            loading={historyLoader}
                                            size={80}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                          />
                                        </div>
                                        : <>
                                          <p className='mb-0 font-size-14'>{item.LOG_TYPE}</p>
                                          <div dangerouslySetInnerHTML={{ __html: item.REMARK }}>
                                          </div>
                                          <p>
                                            {item.CONTACT_PERSON && <span className='mb-0 font-size-14 font-wt-600'>{item.CONTACT_PERSON + " - "}</span>}
                                            {item.CONTACT_NUMBER && <span className='mb-0 font-size-14 font-wt-600'>{item.CONTACT_NUMBER}</span>}
                                          </p>
                                          <p>
                                            {item.EVENT_TIME &&
                                              <span className='mb-0 font-size-14 '>Next followup date:
                                                <span className='mb-0 font-size-14 '>
                                                  {moment(item.EVENT_TIME).format('DD/MM/YYYY')}
                                                </span>
                                              </span>
                                            }
                                          </p>
                                        </>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          }) :
                            null
                          }


                        </div>
                        : null
                      }

                    </div>
                  )
                })
              }

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default SubAdminPopup
