import React, { useState } from 'react';
import { InputForTable, NewInput, NewTextArea } from '../../utils/newInput';
import RichTextEditor3 from '../../utils/RichTextEditor3';

// editLevel (true= editable, false=viewonly) ; userLevel (1->admin , 2->User)
const TncForm = ({ dataTnc, setdataTnc, handleTNC, contMode, editCount, tncOrder, settncOrder, setEditCount, }) => {

  console.log("tncOrder in TncForm-->", tncOrder, dataTnc)
  // console.log("setdataTnc in TncForm-->", setdataTnc)
  // console.log("handleTNC in TncForm-->", handleTNC)


  //let [extraTnc, setextraTnc] = useState([]);
  const [newTnc, setnewTnc] = useState({ newTncName: "", newTncValue: "" });
  // const [userEmail, setuserEmail] = useState('');
  // const [userTypeId, setuserTypeId] = useState('');
  // const [count, setcount] = useState(50);
  const [accordion, setAccordion] = useState({});


  const handleAccordion = (acc) => setAccordion({ ...accordion, [acc]: !accordion[acc] });

  //---------------------------------------------------------------------------------------------------------------------
  // Functions to add dynamic specification elements
  function addElement() {
    // const dataElement = [...extraTnc]
    // dataElement.push(moreTncElement())
    // setextraTnc(dataElement)
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    setdataTnc({ ...dataTnc, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    // setcount(count + 1)
    if (contMode)
      setEditCount(editCount + 1)

    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }

  function removeElement(key) {
    // const dataElement = [...extraTnc]
    let tncdataElement = { ...dataTnc }
    // console.log("dataElement[i]--->", dataElement[i].props.children.props.children)
    // let keytoDelete = dataElement[i].props.children.props.children.toLowerCase().replace(/\s/g, '')
    delete tncdataElement[key]
    // dataElement.splice(i, 1)
    setdataTnc(tncdataElement)
    // setextraTnc(dataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }


  const handlenewTnc = (event) => {
    event.persist();
    setnewTnc(newTnc => ({ ...newTnc, [event.target.name]: event.target.value }));
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Commodity Spec Components

  function tncBodyGenerator() {
    let keyArray = (tncOrder && tncOrder.length) ? tncOrder : Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    // keyArray.sort((a, b) => (a.split(':')[0] / 1) - (b.split(':')[0] / 1))
    console.log("keyArray=>", keyArray)
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row position-relative">
              <div className={"accordionItem col-md-11" + (accordion[index] ? " open" : " closed")}>
                <label className={"accordionItemHeading d-flex " + (!dataTnc?.[key]?.value ? " text-danger" : "")} onClick={() => handleAccordion(index)}>
                  <div className='w-90'>
                    <InputForTable onChange={handleTNC} name={key + ':|:|name'} value={dataTnc?.[key]?.name} />
                  </div>
                  {/* {dataTnc[key].name ? dataTnc[key].name : ''} */}
                </label>
                <div className="accordionItemContent">
                  <RichTextEditor3 rows={5} name={key + ':|:|value'} value={dataTnc?.[key]?.value} onChange={handleTNC} />
                  {/* <textarea rows="7" className="form-control" name={key + ':|:|value'} value={dataTnc[key]?.value?.replace(/<br>/g, "\n")} onChange={handleTNC}></textarea> */}
                </div>
              </div>
              <div onClick={() => removeElement(key)} className='position-absolute cursor' style={{ left: '93%' }} >
                <img src='assets/images/delete.png' className='cursor' />
              </div>
              {/* <button type="button" className="delete-icon tnc-delete" onClick={() => removeElement(key)} ><i className="fa fa-trash"></i></button> */}
            </div>
          )
        })}
      </div>
    )
  }


  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {tncBodyGenerator()}
      <hr />
      <div className=" mt-2">
        {/* <h3 className="mt-2 mb-2">Extra TNC (if any)<span className="help-icon" flow="right" tooltip="Additional TNC">i</span></h3> */}
        {/* <div className=""> */}
        {/* {extraTnc.map((extraTnc, idx) => {
            return (<>
              {extraTnc &&
                <div className="col-md-12 row d-flex pl-0">
                  <div className="accordionWrapper col-md-11">{extraTnc}</div>
                  <div className="col-md-1 ml-auto">{extraTnc !== undefined ? <button type="button" className="delete-icon" onClick={() => removeElement(idx)} ><i className="fa fa-trash"></i></button> : ""}</div>
                </div>}
            </>
            )
          })} */}
        <div className="col-md-12 pb-3 p-0 m-0">
          <label className="col-md-12 font-size-16 font-wt-500 mb-3">Add New TNC</label>
          <div className="col-md-10">
            {/* <label className="">TNC Name/Title</label>
            <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} /> */}
            <NewInput label={'TNC Name/Title'} value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" onChange={handlenewTnc} />
          </div>
          <div className="col-md-10 mt-2 mb-2">
            <RichTextEditor3 rows={3} value={newTnc.newTncValue} name="newTncValue" onChange={handlenewTnc} />
            {/* <NewTextArea label={"TNC Value/Text"} rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} /> */}
            {/* <label className="">TNC Value/Text</label>
            <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea> */}
          </div>
          {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="new-btn py-2  w-15 ml-3 text-white" onClick={() => addElement()} >Add +</button>}
        </div>
        {/* </div> */}
      </div>
    </>)
}



export default TncForm;
