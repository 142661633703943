import React, { PureComponent } from 'react'
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line, Tooltip } from 'recharts'

const label = {
  "total_applications": "Total Finance Applications",
  "limitrejected": "Total limit Rejected",
  "total_limit_applied": "Total limit Applied"
}
class LineChartComponent extends PureComponent {
  render() {
    console.log('render', this.props)
    return (
      <ResponsiveContainer width={"100%"} height={200}>
        <LineChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}

        >
          <XAxis dataKey="year" type="category" />
          <YAxis type="number" tickFormatter={(value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
          <Tooltip formatter={(value, name) => [name === "total_applications" ? value : "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value), label[name]]} />
          <Line dataKey={this.props.key1} stroke="#76EEA9" strokeWidth={"2px"} activeDot={{ r: 8 }} />
          <Line dataKey={this.props.key2} stroke="#FF7B6D" strokeWidth={"2px"} activeDot={{ r: 8 }} />
          <Line dataKey={this.props.key3} stroke="#AED8FF" strokeWidth={"2px"} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

export default LineChartComponent