import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import { platformBackendUrl } from '../../../urlConstants';
import { FileInput } from '../../../utils/FileInput';
import { convertImageToPdf, decryptData, GetCache, getDocDetails, getProgressPercentage, isEmpty, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import { CustomSelect, InputWithSelect, NewInput, NewSelect } from '../../../utils/newInput';
import { NewTable } from '../../../utils/newTable';
import toastDisplay from '../../../utils/toastNotification';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import HeaderV2 from '../../partial/headerV2';
import SideBarV2 from '../../partial/sideBarV2';
import TaskManager from '../../TaskManager/TaskManager';
import InputEdit from '../../viewProfile/components/inputEdit';
import FinanceInvoiceModal from '../../InvoiceDiscounting/contract/components/financeinvoiceModal';
import Calendar from "react-calendar";
import { useRef } from "react";
import SubAdminProfileDetailsAdmin from './SubAdminProfileDetailsAdmin';
import SubAdminProfileDetailsCRM from './SubAdminProfileDetailsCRM';
import MultipleSelect from '../../../utils/MultipleSelect';
const subadminUserPermissions = [
  { name: 'Exporter' },
  { name: 'Financier' },
  { name: 'Importer' },
  { name: 'Channel Partner' },
  { name: 'Assign Task' }
]

const userDetailLabels = [
  { name: 'Contact No', alt1: 'phone_code', alt2: "contact_number" },
  { name: 'Address', alt1: "user_address" },
  { name: 'Email Id', alt1: "email_id" },
  { name: 'City, State', alt1: 'company_city', alt2: "company_state" },
  { name: 'Postal Code', alt1: "company_postal_code" },
  { name: 'Designation', alt1: "designation" }
]

const UserAcessFinancer = {
  "Exporter": true,
  "Financier": true,
  "Importer": true,
  "Channel Partner": true,
  "Assign Task": true,
  "Task Manager Users": 'taskManager',
  "Task Manager Enquiry": 'enquiry',
  "Task Manager Call List": 'callList',
  "LC Limit": 'lcLimit',
  "Invoice Limit": 'invoiceLimit',
  "Invoice Finance": 'invoiceFinance',
  'Invoice Approved Finance': 'invoiceApprovedFinance',
  "CRM Leads": 'leads',
  "CRM Data": 'crmdata',
  "Payments": 'adminPayments',
  "Invoice Financer": 'generateInvoiceFinancier',
  "Invoice Channel Partner": 'generateInvoiceCP',
  "Chat Room": 'ChatRoomV2',
  "Master Data": "masterdata",
  "Exporter Complete": "exporterComplete",
  "Financier Complete": "financierComplete",
  "Channel Partner Complete": "channelPartnerComplete",
  "Invoice Limit Complete": 'invoiceLimitComplete',
  "Invoice Finance Complete": 'invoiceFinanceComplete',
  "Invoice Approved Finance Complete": 'invoiceApprovedFinanceComplete',
  "LC Limit Complete": 'lcLimitComplete',
  "Task Manager Users Complete": 'taskManagerComplete',
  "Task Manager Enquiry Complete": 'enquiryComplete',
  "Task Manager Call List Complete": 'callListComplete',
  "CRM Leads Complete": 'leadsComplete'

}

const SubAdminProfileDetails = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  let history = useHistory()
  const [tab, setTab] = useState("Admin");
  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10 })
  const [filterData, setFilterData] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [showAddNewUserPopup, toggleAddNewUserPopup] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [countryData, setcountryData] = useState([])
  const [viewDetails, setViewDetails] = useState({
    type: '',
    isVisible: false,
    data: {}
  })

  const [overalldata, setoveralldata] = useState([])
  const [leadStatusIndex, setLeadStatusIndex] = useState()
  const [applicationStatus, setApplicationStatus] = useState()
  const [addTasksCountPopup, setaddTasksCountPopup] = useState(false)
  const [defaultDailyTasks, setdefaultDailyTasks] = useState('')
  const [isAdminOnLeave, setisAdminOnLeave] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showTransferScreen, setShowTransferScreen] = useState(false)
  const [salesPersonOverall, setsalesPersonOverall] = useState([])
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const [subAdminDetails, setsubAdminDetails] = useState(JSON.parse(localStorage.getItem("subAdminDetails")))
  const subAdminDetailsRef = useRef(JSON.parse(localStorage.getItem("subAdminDetails")))
  const userPermissionsForSubAdmin = JSON.parse(userTokenDetails.UserAccessPermission || "{}")
  let onlyShowForUserId = userPermissionsForSubAdmin?.mainAdmin ? undefined : userId
  const [salesPerson, setSalesPerson] = useState([])
  const [goalspopup, setGoalsPopup] = useState(false)
  const [goalsProgress, setGoalsProgress] = useState({
    onboarding: [0, 0, 0, 0],
    buyers: [0, 0, 0, 0],
    invoice_disbursement: [0, 0, 0, 0],
    lc_disbursement: [0, 0, 0, 0]
  })
  const box = useRef(null)
  const [selectedDateFilter, setselectedDateFilter] = useState('Current Month')
  const [showCalendar, setshowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState({})
  const [datas, setDatas] = useState({})
  const [accessObj, setAccessObj] = useState({})

  useEffect(() => {
    let startDate
    let endDate
    const today = new Date();
    if (selectedDateFilter === 'Today') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, -1);
    } else if (selectedDateFilter === 'Previous Week') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedDateFilter === 'Previous Month') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedDateFilter === 'Current Week') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedDateFilter === 'Current Month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedDateFilter === 'Custom') {
      setshowCalendar(true)
      return
    } else if (selectedDateFilter === 'Yesterday') {
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
      endDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999);
    } else if (selectedDateFilter === 'Overall') {
      startDate = new Date(2020, 1, 1);
      endDate = new Date(today.getFullYear() + 1, today.getMonth() + 1, 0);
    }
    setDateRange({
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    })

  }, [selectedDateFilter])
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  }, [])
  const handleOutsideClick = (event) => {
    let eventTargetStr = event?.target?.outerHTML
    console.log("handleOutsideClick", box.current, event?.target?.outerHTML);
    if (box && box.current && !box.current.contains(event.target) && !eventTargetStr?.includes("<select class=")) {
      //setshowCalendar(false)
    }
  }
  useEffect(() => {
    refreshexistingdata()
  }, [])

  const refreshexistingdata = () => {
    setshowLoader(true)
    call("POST", 'getSubAdminUser', { isAllSubAdmins: true }).then(res => {
      setshowLoader(false)
      const subadmindata = res.data.find(ele => ele.id == subAdminDetailsRef.current.id)
      subAdminDetailsRef.current = {
        ...subAdminDetailsRef.current,
        ...subadmindata
      }
      setsubAdminDetails({
        ...subAdminDetails,
        ...subadmindata
      })
    }).catch(err => setshowLoader(false))
  }
  const deleteSubAdmin = () => {
    let reqObj = {
      deleteUserId: subAdminDetails.tbl_user_id,
      AssignTo: data.selectedUser
    }
    setshowLoader(true)
    console.log('Delete', reqObj, subAdminDetails);
    call('POST', 'deleteSubAdmin', reqObj).then(result => {
      toastDisplay(result, "success")
      setshowLoader(false)
      refreshexistingdata()
      setShowDeletePopup(false)
    }).catch(e => {
      toastDisplay(e, "error")
      setshowLoader(false)
    })
  }
  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getMonthlyGoals', { userId: subAdminDetails?.tbl_user_id }).then(result => {
      setDatas({
        ...datas,
        ...result
      })
      setshowLoader(false)

    }).catch(e => {
      setshowLoader(false)

    })
    call('POST', 'getMonthlyGoalsProgress', { userId: subAdminDetails?.tbl_user_id }).then(result => {
      setGoalsProgress({
        onboarding: getProgressPercentage(result.userOnboardPer),
        buyers: getProgressPercentage(result.buyerAddedPer),
        invoice_disbursement: getProgressPercentage(result.lcPer),
        lc_disbursement: getProgressPercentage(result.invPer)
      })
      setshowLoader(false)

    }).catch(e => {
      setshowLoader(false)

    })
  }, [])

  console.log('dataaaaaaaaaaaaaaaaaa', goalsProgress);
  const addMonthlyGoals = () => {
    let error = {}
    if (!datas.onboarding_goal) {
      error.onboarding_goal = 'Mandatory Field'
    }
    if (!datas.buyeradded_goal) {
      error.buyeradded_goal = 'Mandatory Field'
    }
    if (!datas.invDis_goal) {
      error.invDis_goal = 'Mandatory Field'
    }
    if (!datas.lcDis_goal) {
      error.lcDis_goal = 'Mandatory Field'
    }
    if (!datas.lc_currency) {
      error.lcDis_goal = 'Mandatory Field'
    }
    if (!datas.inv_currency) {
      error.invDis_goal = 'Mandatory Field'
    }
    if (isEmpty(error)) {
      //add api
      setshowLoader(true)
      let reqObj = {
        onboarding_goal: datas.onboarding_goal,
        buyeradded_goal: datas.buyeradded_goal,
        invDis_goal: datas.invDis_goal,
        lcDis_goal: datas.lcDis_goal,
        lc_currency: datas.lc_currency,
        inv_currency: datas.inv_currency,
        userId: subAdminDetails?.tbl_user_id
      }
      call('POST', 'addMonthlyGoals', reqObj).then(result => {
        toastDisplay(result, "success")
        setshowLoader(false)
        setGoalsPopup(false)
      }).catch(e => {
        setshowLoader(false)
        toastDisplay(e, "error")
      })
    } else {
      setErrors(error)
    }
  }
  useEffect(() => {
    // let isCacheExist = localStorage.getItem('taskManagerFilterData') != "{}"
    // if (!isCacheExist) {
    let objectAPI = {
      onlyShowForUserId: subAdminDetails?.tbl_user_id
    }
    call('POST', 'getTasksForAdminFilters', objectAPI).then(res => {
      console.log("getTasksForAdminFilters then", res);
      setFilterData(res)
    }).catch(err => { })
    // }
  }, [])

  async function asyncUseEffectFunction() {
    setshowLoader(true)
    let kycDocs = {}
    let tempDocsId = subAdminDetails?.kycDocs?.split(",") || []
    let docResp = await getDocDetails(tempDocsId[0])
    kycDocs[docResp?.gen_doc_label] = docResp
    docResp = await getDocDetails(tempDocsId[1])
    kycDocs[docResp?.gen_doc_label] = docResp
    setData(kycDocs)
    setshowLoader(false)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [])

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setDatas({ ...data, [event.target.name]: event.target.value })
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const assignDailyDefaultTasks = () => {
    setshowLoader(true)
    let reqObj = {
      defaultTasksCount: defaultDailyTasks,
      userId: subAdminDetails?.tbl_user_id
    }
    call('POST', 'assignDailyDefaultTasks', reqObj).then(result => {
      setshowLoader(false)
      toastDisplay(result, "success")
    }).catch(e => {
      setshowLoader(false)

    })
  }
  const getDailyDefaultTasks = () => {
    setshowLoader(true)
    let reqObj = {
      userId: subAdminDetails?.tbl_user_id
    }
    call('POST', 'getDailyDefaultTasks', reqObj).then(result => {
      setshowLoader(false)
      setdefaultDailyTasks(result?.defaultTasksCount || 0)
    }).catch(e => {
      setshowLoader(false)
    })
  }
  useEffect(() => {
    getDailyDefaultTasks()
  }, [])

  const InsertadditionalTasksCount = () => {
    let errors = {}
    if (!data.taskCountStart) {
      errors.taskCountStart = "Mandatory Field"
    }
    if (!data.taskCountEnd) {
      errors.taskCountEnd = "Mandatory Field"
    }
    if (!isAdminOnLeave) {
      if (!data.tasksCount) {
        errors.tasksCount = "Mandatory Field"
      }
    }

    if (isEmpty(errors)) {
      setshowLoader(true)
      let taskCount = data.tasksCount
      if (isAdminOnLeave) {
        taskCount = 0
      }
      let additionalTasksCount = {}
      additionalTasksCount[`${moment(data.taskCountStart).format('DD-MM-YYYY')}-${moment(data.taskCountEnd).format('DD-MM-YYYY')}`] = taskCount
      let reqObj = {
        userId: subAdminDetails?.tbl_user_id,
        additionalTasksCount
      }
      call('POST', 'InsertadditionalTasksCount', reqObj).then(result => {
        toastDisplay(result, "success")
        setshowLoader(false)
        setaddTasksCountPopup(false)
      }).catch(e => {
        setshowLoader(false)
      })
    } else {
      setErrors(errors)
    }

  }

  async function popupHandler() {
    const item = subAdminDetailsRef.current
    console.log('Subadminpopup', item)
    setshowLoader(true)
    toggleAddNewUserPopup(true)
    let kycDocs = {}
    let tempDocsId = item?.kycDocs?.split(",") || []
    let docResp = await getDocDetails(tempDocsId[0])
    kycDocs[docResp?.gen_doc_label] = docResp
    docResp = await getDocDetails(tempDocsId[1])
    kycDocs[docResp?.gen_doc_label] = docResp
    const response = await call("POST", 'getSubAdminManagers', { userId: item.tbl_user_id })

    setData({
      nameTitle: item.name_title,
      contactPerson: item.contact_person,
      designation: item.designation,
      phoneCode: item.phone_code,
      contactNo: item.contact_number,
      email: item.email_id,
      password: decryptData(item.password),
      country: item.country_code,
      userAddress: item.user_address,
      aadharDocumentName: item.aadhar_no,
      panDocumentName: item.pan_no,
      ...kycDocs,
      userIdToUpdate: item.id,
      userAccess: JSON.parse(item.UserPermissions),
      functionalManager: response.functional_manager_id,
      reportingManager: response.reporting_manager_id,
    })
    setAccessObj(JSON.parse(item.UserPermissions))
    setshowLoader(false)
  }
  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
    setshowLoader(true)
    call("POST", 'getSubAdminUser', {}).then(res => {
      setshowLoader(false)
      setSalesPerson(res.data)
      setsalesPersonOverall(res.data)
    }).catch(err => setshowLoader(false))
  }, [])
  useEffect(() => {
    if (data.userIdToUpdate) {
      setSalesPerson(salesPersonOverall.filter(item => item.id != data.userIdToUpdate))
    }
  }, [data.userIdToUpdate])
  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null
      })
    }
    else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
      });
    }
  };
  async function handleValidation() {
    let validateFields = ["contactPerson", "contactNo", "email", "password", "designation", "country", "userAddress", "aadharDocumentName", "panDocumentName"]
    let err = {}

    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      }
    }

    if (!Object.keys(err).length) {
      setshowLoader(true)
      let formData = new FormData()
      Object.keys(data).forEach(k => {
        if (k != "userAccess") {
          formData.append(k, data[k])
        }
      })
      formData.append("userAccess", JSON.stringify(accessObj || {}))
      formData.append("userId", userId)
      // if (data.selectedCallers) {
      //   formData.append("selectedCallers", JSON.stringify(data.selectedCallers))
      // }
      call('POST', 'onboardSubAdminUser', formData).then(res => {
        setshowLoader(false)
        toastDisplay(res, "success")
        toggleAddNewUserPopup(false)
        setData({ nameTitle: 'Mr', phoneCode: "91", country: "IN" });
        refreshexistingdata()
        setRefresh(refresh + 1)
      }).catch(err => {
        setshowLoader(false)
        toastDisplay('Something went wrong', "error")
      })
    }

    setErrors(err)
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {addTasksCountPopup &&
        <FinanceInvoiceModal limitinvoice={addTasksCountPopup} closeSuccess={() => setaddTasksCountPopup(false)} isCentered={true} headerTitle={"Add Daily Customized Task"}>
          <div>
            <div className='d-flex align-items-center ml-4 my-2'>
              <div className='pr-3'>
                <label className='w-100 text-left font-size-14 font-wt-600'>From</label>
                <NewInput type={"date"} name={"taskCountStart"} onChange={handleChange} value={data.taskCountStart} error={errors.taskCountStart}
                />
              </div>
              <div className='pr-3'>
                <label className='w-100 text-left font-size-14 font-wt-600'>To</label>
                <NewInput type={"date"} name={"taskCountEnd"} error={errors.taskCountEnd} value={data.taskCountEnd} onChange={handleChange} />
              </div>
            </div>
            <div className='ml-4 w-30 my-2'>
              <div className="col-md-12 px-0">
                <div className="col-md-10 px-0">
                  <NewInput name={"tasksCount"} error={errors.tasksCount} isDisabled={isAdminOnLeave} type={"number"} label={"No. of tasks"} value={isAdminOnLeave ? 0 : data.tasksCount} onChange={handleChange} />
                </div>
              </div>
            </div>
            <div className='ml-4 w-40 my-2 d-flex gap-2 align-items-center' onClick={() => {
              setisAdminOnLeave(!isAdminOnLeave)
            }}>
              <img src={`assets/images/${isAdminOnLeave ? 'checked-green' : 'unchecked-box'}.png`} />
              <label className='w-100 text-left font-size-14 font-wt-500 mb-0'>Admin On Leave</label>
            </div>
            <div className="d-flex gap-4">
              <button className={`my-2 new-btn text-white w-17 py-2 px-2 ml-4 cursor`} onClick={InsertadditionalTasksCount}>Add Task</button>
            </div>
          </div>
        </FinanceInvoiceModal>
      }
      {showDeletePopup &&
        <FinanceInvoiceModal modalSize={"sm"} limitinvoice={showDeletePopup} closeSuccess={() => {
          setShowDeletePopup(false)
          setShowTransferScreen(false)
        }} headerTitle={""}>
          <div>
            {!showTransferScreen ?
              <div>
                <div>
                  <label>{`Are You sure you want to deactivate ` + '"'}<span className='font-wt-600 text-color1'>{subAdminDetails.contact_person + `'s`}</span>{`" Account?`}</label>
                </div>
                <div className="d-flex gap-4">
                  <button className={`my-2 new-btn2 text-color1 w-50 py-2 px-2 cursor`} onClick={() => { setShowTransferScreen(true) }}>Yes</button>
                  <button className={`my-2 new-btn text-white w-50 py-2 px-2 cursor`} onClick={() => { setShowDeletePopup(false) }}>No</button>
                </div>
              </div>
              :
              <>
                <div>
                  <label>{`Transfer` + '"'}<span className='font-wt-600 text-color1'>{subAdminDetails.contact_person + `'s`}</span>{`" data to`}</label>
                </div>
                <div className="col-md-12">
                  <NewSelect
                    selectData={salesPerson}
                    optionLabel={'contact_person'} optionValue={'id'}
                    onChange={handleChange}
                    removeMb={true}
                    name={"selectedUser"}
                    value={data.selectedUser}
                  />
                </div>
                <div className="d-flex gap-4 justify-content-center mt-2">
                  <button className={`my-2 new-btn text-white w-60 py-2 px-2 cursor`} onClick={deleteSubAdmin}>Transfer data</button>
                </div>
              </>
            }

          </div>
        </FinanceInvoiceModal>
      }
      {goalspopup &&
        <FinanceInvoiceModal modalSize={"sm"} limitinvoice={goalspopup} closeSuccess={() => {
          setGoalsPopup(false)
        }} isCentered={true} headerTitle={""}>
          <div>
            <>
              <div className='mb-4'>
                <label className='font-size-14 font-wt-500'>Set Monthly Goals</label>
              </div>
              <div className="col-md-12">
                <NewInput
                  onChange={handleChange}
                  name={"onboarding_goal"}
                  value={datas.onboarding_goal || 0}
                  label={"Onboarding"}
                  error={errors.onboarding_goal}
                />
              </div>
              <div className="col-md-12">
                <NewInput
                  onChange={handleChange}
                  name={"buyeradded_goal"}
                  value={datas.buyeradded_goal || 0}
                  label={"Buyer Added"}
                  error={errors.buyeradded_goal}

                />
              </div>
              <div className="col-md-12">
                <InputWithSelect
                  selectData={most_used_currencies} selectName={"inv_currency"} selectValue={datas.inv_currency} optionLabel={"code"}
                  optionValue={'code'}
                  type="number" name={"invDis_goal"} value={datas.invDis_goal || 0}
                  onChange={handleChange}
                  label={"Invoice  disbursement"} error={errors.invDis_goal} />
              </div>
              <div className="col-md-12">
                <InputWithSelect
                  selectData={most_used_currencies} selectName={"lc_currency"} selectValue={datas.lc_currency} optionLabel={"code"}
                  optionValue={'code'}
                  type="number" name={"lcDis_goal"} value={datas.lcDis_goal || 0}
                  onChange={handleChange}
                  label={"LC disbursement"} error={errors.lcDis_goal} />
              </div>
              <div className="d-flex gap-4 justify-content-center mt-2">
                <button className={`my-2 new-btn text-white w-60 py-2 px-2 cursor`} onClick={addMonthlyGoals}>Add Goals</button>
              </div>
            </>
          </div>
        </FinanceInvoiceModal>
      }
      {showAddNewUserPopup &&
        <div className={`modal fade ${showAddNewUserPopup && "show"}`} style={showAddNewUserPopup ? { display: "block", "zIndex": '10000001' } : {}}>
          <div className="modal-dialog modal-md mr-0 my-0">
            <div className="modal-content submitmodal pb-4"
            >
              <div className="modal-header border-0">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <label
                    className="font-size-16 font-wt-600 text-color-value mx-3"
                  >{data.userIdToUpdate ? "Update SubAdmin" : 'Add New SubAdmin'}</label>
                  <div className="modal-header border-0">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                      setData({ nameTitle: 'Mr', phoneCode: "91", country: "IN" });
                      toggleAddNewUserPopup(false)
                    }}></button>
                  </div>
                </div>
              </div>

              <div className="modal-body px-4">
                <div>
                  <div className='mb-4 position-relative'>
                    <InputWithSelect selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                      selectName="nameTitle" selectValue={data.nameTitle} optionLabel={'name'} optionValue={'name'}
                      onChange={handleChange} type="text" name={"contactPerson"} value={data["contactPerson"]}
                      label={"Full Name"} error={errors["contactPerson"]}
                    />
                  </div>
                  <div className='mb-4 position-relative'>
                    <InputWithSelect
                      selectData={countryData} selectName={"phoneCode"} selectValue={data.phoneCode} optionLabel={"phonecode"}
                      optionValue={'phonecode'}
                      type="number" name={"contactNo"} value={data["contactNo"]}
                      onChange={handleChange}
                      label={"Mobile Number"} error={errors["contactNo"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewInput type={"email"} name={"email"}
                      value={data["email"]}
                      onChange={handleChange} label={"Email Id"} error={errors["email"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewInput type={"text"} name={"password"}
                      value={data["password"]}
                      onChange={handleChange} label={"Password"} error={errors["password"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewInput type={"text"} name={"designation"}
                      value={data["designation"]}
                      onChange={handleChange} label={"Designation"} error={errors["designation"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewSelect
                      label={"Country"}
                      selectData={countryData} name="country" value={data.country} onChange={handleChange}
                      optionLabel={"name"} optionValue={"sortname"} error={errors["country"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewInput type={"text"} name={"userAddress"}
                      value={data["userAddress"]}
                      onChange={handleChange} label={"Address"} error={errors["userAddress"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewInput type={"text"} name={"aadharDocumentName"}
                      value={data["aadharDocumentName"]}
                      onChange={handleChange} label={"Aadhar No"} error={errors["aadharDocumentName"]} />
                  </div>
                  <div className='mb-4 position-relative'>
                    <NewInput type={"text"} name={"panDocumentName"}
                      value={data["panDocumentName"]}
                      onChange={handleChange} label={"PAN No"} error={errors["panDocumentName"]} />
                  </div>
                  <p className='text-decoration-underline font-size-14 mt-3'>Attach Documents</p>

                  <p className='font-size-14 mt-3'>Aadhar Document</p>
                  <div className="col-md-12">
                    <div className="row form-group">
                      <div className="col-md-11">
                        <FileInput name={"aadharDocument"} value={data.aadharDocument} error={errors.aadharDocument}
                          onChange={handleFile} isEditable={true}
                          onUploadCancel={() => setData({ ...data, "aadharDocument": null })} />
                        {errors.aadharDocument ? <div class="text-danger mt-2 font-size-12">
                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                          <b>{errors.aadharDocument}</b></div> : ''}
                      </div>
                    </div>
                  </div>

                  <p className='font-size-14 mt-3'>PAN Document</p>
                  <div className="col-md-12">
                    <div className="row form-group">
                      <div className="col-md-11">
                        <FileInput name={"panDocument"} value={data.panDocument} error={errors.panDocument}
                          onChange={handleFile} isEditable={true}
                          onUploadCancel={() => setData({ ...data, "panDocument": null })} />
                        {errors.panDocument ? <div class="text-danger mt-2 font-size-12">
                          <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                          <b>{errors.panDocument}</b></div> : ''}
                      </div>
                    </div>
                  </div>

                  <p className='text-decoration-underline font-size-14 mt-4'>User Access</p>

                  {/* <div className='row'> */}
                  {/* {subadminUserPermissions.map((i, j) => {
                  return (
                    <div className="d-flex flex-row align-items-center mb-3">
                      <div className="align-self-center">
                        <img
                          onClick={() => {
                            let temp = data.userAccess || {}
                            temp[i.name] = !temp[i.name]
                            setData({ ...data, userAccess: temp })
                          }}
                          className='cursor mr-3' src={`assets/images/${data.userAccess?.[i.name] ? 'checked-green' : 'empty-check'}.png`} />
                      </div>
                      <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{i.name}</label>
                    </div>
                  )
                })} */}
                  {/* </div> */}
                  <div className='row py-2'>
                    {Object.keys(UserAcessFinancer).map((item, index) => {
                      return <div className="col-md-6 pt-2">
                        <div className='d-flex flex-row align-items-center'>
                          <img src={accessObj[item] ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                            const temp = accessObj
                            if (accessObj[item]) {
                              delete temp[item]
                            } else {
                              let userAccess = UserAcessFinancer
                              temp[item] = userAccess[item]
                            }
                            setAccessObj({
                              ...temp
                            })
                          }} />
                          <p className="m-0">{item}</p>
                        </div>
                      </div>
                    })}
                  </div>
                  {errors.accesspermission &&
                    <div class="text-danger mt-2 font-size-12">
                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                      <b>{errors.accesspermission}</b>
                    </div>
                  }
                  <p className='text-decoration-underline font-size-14 mt-4'>Reporting Manager</p>
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      isMulti
                      Id="Select Reporting Manager"
                      Label="Select Reporting Manager"
                      selectedvalue="Select Reporting Manager"
                      filterOption={() => true}
                      optiondata={data.userIdToUpdate ? salesPerson : salesPersonOverall}
                      onChange={(e) => handleMultiSelectchange(e, "reportingManager", "id", true)}
                      value={data.reportingManager ? [data.reportingManager] : []}
                      name="reportingManager"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                    />
                  </div>
                  <p className='text-decoration-underline font-size-14 mt-4'>Functional Manager</p>
                  <div className='col-md-12 p-0'>
                    <MultipleSelect
                      isMulti
                      Id="Select Functional Manager"
                      Label="Select Functional Manager"
                      selectedvalue="Select Functional Manager"
                      optiondata={data.userIdToUpdate ? salesPerson : salesPersonOverall}
                      onChange={(e) => handleMultiSelectchange(e, "functionalManager", "id", true)}
                      value={data.functionalManager ? [data.functionalManager] : []}
                      name="functionalManager"
                      labelKey={"contact_person"}
                      valKey={"id"}
                      customStyles={{
                        backgroundColor: '#DEF7FF',
                        borderRadius: '10px'
                      }}
                    />
                  </div>
                  <div className='mt-4'>
                    <button type="button"
                      onClick={() => { handleValidation() }}
                      className={` border-0 mb-2 text-white enableQuotebtn`}>
                      {data.userIdToUpdate ? 'Update' : "Submit"}
                    </button>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      }
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="SubAdminProfileDetails" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={subAdminDetails.contact_person}
              userTokenDetails={userTokenDetails} />
            <div className='d-flex flex-row align-items-center justify-content-between'>
              <div className='d-flex gap-3 align-items-center'>
                <a className="cursor"
                  onClick={() => { history.go(-1) }}
                ><img className="mb-0" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <div className='d-flex flex-column'>
                  <label className='font-size-14 font-wt-700 mb-0'>{subAdminDetails.contact_person}</label>
                  {/* <label className='font-size-14 font-wt-600 text-color1'>{subAdminDetails.email_id}</label> */}
                </div>

                <div className='card px-2 py-0  dashboard-card shadow-sm w-50'>
                  <div className='d-flex gap-2 align-items-center'>
                    <label className='font-wt-600 font-size-14 mb-0 rounded'>Default daily number of tasks:</label>
                    <input value={defaultDailyTasks} type='number' class="form-control border-0 w-17 p-0 text-color1 font-wt-600" onChange={(e) => {
                      setdefaultDailyTasks(e.target.value)
                    }} onKeyDown={(event) => {
                      event.persist();
                      if (event.keyCode === 13) {
                        assignDailyDefaultTasks()
                      }
                    }} />
                    <img src={"/assets/images/bytesize_edit.png"} alt="" />
                  </div>
                </div>
                <img className="mb-0" src={"assets/images/add_more.svg"} onClick={() => setaddTasksCountPopup(true)} />
                {/* <a className="cursor"
              ><img className="mb-0" src={"assets/images/add more-bg-white.png"} alt="Back" height={15} width={15} /></a> */}
              </div>
              <div className='d-flex flex-row gap-4'>
                <div className={`${subAdminDetails.status === 0 ? 'inactive-div' : 'active-div'}`}>
                  <label className='statusText mb-0'> {subAdminDetails.status === 0 ? 'Inactive' : 'Active'}</label>
                </div>
                {!onlyShowForUserId &&
                  <div className='d-flex gap-4 align-items-center'>
                    <img src='assets/images/edit-icon.svg' className='cursor' height={22} width={22} onClick={popupHandler} />
                    <img src='assets/images/delete-icon.svg' className='cursor' onClick={() => setShowDeletePopup(true)} height={22} width={22} />
                  </div>
                }
              </div>
            </div>



            <div className='mt-4' style={{}} >

              {/* <div className='row'>

                <div className='w-20'>
                  <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                    <label className={`text-custom2 font-wt-600 font-size-20  w-100 text-center`}>
                      {`$ ${Intl.NumberFormat("en", { notation: 'compact' }).format(subAdminDetails["totalDisbursedAmount"])}`}
                    </label>
                    <label className='font-size-16 text-color-value font-wt-600 pt-2'>{"Disbursement"}</label>
                  </div>
                </div>

                <div className='w-20'>
                  <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                    <label className='font-size-16 text-color-value font-wt-600 pt-2'>{"Leads Assigned"}
                      <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${subAdminDetails["totalLeadAssigned"]}`}</label></label>
                    <div className='row px-0 w-100'>
                      <div className='w-50 cursor'
                        onClick={() => {
                          setLeadStatusIndex(0)
                          // let temp = filterData
                          // temp["Lead Status"]["data"][0]["isChecked"] = true
                          // temp["Lead Status"]["data"][1]["isChecked"] = false
                          // temp["Lead Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 text-48DA87  w-100 cursor`}>
                          {`${subAdminDetails.totalActiveLeadAssigned}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Active"}</label>
                      </div>

                      <div className='w-50 cursor'
                        onClick={() => {
                          setLeadStatusIndex(1)
                          // let temp = filterData
                          // temp["Lead Status"]["data"][0]["isChecked"] = false
                          // temp["Lead Status"]["data"][1]["isChecked"] = true
                          // temp["Lead Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 colorFF7B6D w-100 cursor`}>
                          {`${subAdminDetails.totalInactiveLeadAssigned}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Inactive"}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-30'>
                  <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
                    <label className='font-size-16 text-color-value font-wt-600 pt-2'>{"Finance Application"}
                      <label className='font-size-16 text-color-value font-wt-600 text-custom2'>{` - ${subAdminDetails["totalFinanceApplication"] || 0}`}</label></label>
                    <div className='row px-0 w-100'>
                      <div className='w-33 cursor'
                        onClick={() => {
                          setApplicationStatus(0)
                          // let temp = filterData
                          // temp["Application Status"]["data"][0]["isChecked"] = true
                          // temp["Application Status"]["data"][1]["isChecked"] = false
                          // temp["Application Status"]["data"][2]["isChecked"] = false
                          // temp["Application Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 text-custom2  w-100 cursor`}>
                          {`${subAdminDetails.totalOngoingFinanceApplication || 0}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Ongoing"}</label>
                      </div>

                      <div className='w-33 cursor'
                        onClick={() => {
                          setApplicationStatus(1)
                          // let temp = filterData
                          // temp["Application Status"]["data"][0]["isChecked"] = false
                          // temp["Application Status"]["data"][1]["isChecked"] = true
                          // temp["Application Status"]["data"][2]["isChecked"] = false
                          // temp["Application Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 text-48DA87  w-100 cursor`}>
                          {`${subAdminDetails.totalApprovedFinanceApplication || 0}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Approved"}</label>
                      </div>

                      <div className='w-33'
                        onClick={() => {
                          setApplicationStatus(2)
                          // let temp = filterData
                          // temp["Application Status"]["data"][0]["isChecked"] = false
                          // temp["Application Status"]["data"][1]["isChecked"] = false
                          // temp["Application Status"]["data"][2]["isChecked"] = true
                          // temp["Application Status"]["isFilterActive"] = true
                          // setFilterData({ ...temp })
                        }}>
                        <label className={`value font-wt-600 colorFF7B6D w-100 cursor`}>
                          {`${subAdminDetails.totalRejectedFinanceApplication || 0}`}
                        </label>
                        <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Rejected"}</label>
                      </div>
                    </div>
                  </div>
                </div>


              </div> */}

              <div className='card dashboard-card'>
                <div className='d-flex gap-2 align-items-center'>
                  <label className='font-size-16 font-wt-500 text-decoration-underline mb-0'>Monthly goals</label>
                  <img src='assets/images/edit.png' className='cursor' height={20} width={20} onClick={() => setGoalsPopup(true)} />

                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>Onboarding</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{datas.onboarding_goal || 0}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.onboarding.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}


                  </div>
                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>Buyer Added</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{datas.buyeradded_goal || 0}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.buyers.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}

                  </div>
                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>Invoice discounting disbursement</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{`${datas.inv_currency ? datas.inv_currency : ''} ${datas.invDis_goal ? Intl.NumberFormat('en-US', { notation: 'compact' }).format(datas.invDis_goal) : ''}`}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.invoice_disbursement.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}

                  </div>
                </div>
                <div className='mt-2 py-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='font-size-14 font-wt-50 mb-0'>LC discounting disbursement</label>
                    <label className='font-size-14 font-wt-50 mb-0'>{`${datas.lc_currency ? datas.lc_currency : ''} ${datas.lcDis_goal ? Intl.NumberFormat('en-US', { notation: 'compact' }).format(datas.lcDis_goal) : ''}`}</label>
                  </div>
                  <div className='outerDiv d-flex gap-2 align-items-center'>
                    {goalsProgress.lc_disbursement.filter(item => item != 0).map((item) => {
                      return <div className='innerDiv' style={{ width: `${item}%` }} ></div>
                    })}

                  </div>
                </div>
              </div>

              <div className='card dashboard-card mt-5'>
                <div className='d-flex row'>
                  {userDetailLabels.map(j => {
                    return (
                      <div className='w-50 row'>
                        <div className='w-30'>
                          <label className='font-size-14 font-wt-600 text-color-label '>{j.name}:</label>
                        </div>
                        <div className='w-70'>
                          <label className='font-size-14 font-wt-600 text-color-label '>{(subAdminDetails[j.alt1] || "NA")}{(subAdminDetails[j.alt2] || "")}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='card dashboard-card mt-5'>
                <div className='d-flex row'>
                  <label className='font-size-14 font-wt-600 text-color-label text-decoration-underline '>Documents</label>
                  <div className='w-40'>
                    <p className='font-size-14 mt-3'>Aadhar Document</p>
                    <div className="col-md-12">
                      <div className="row form-group">
                        <div className="col-md-11">
                          <FileInput name={"aadharDocument"} value={data.aadharDocument} error={errors.aadharDocument}
                            onChange={handleFile} isEditable={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-40'>
                    <p className='font-size-14 mt-3'>PAN Document</p>
                    <div className="col-md-12">
                      <div className="row form-group">
                        <div className="col-md-11">
                          <FileInput name={"panDocument"} value={data.panDocument} error={errors.panDocument}
                            isEditable={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className=''>
              <TaskManager
                leadStatusIndex={leadStatusIndex} applicationStatusIndex={applicationStatus}
                showForThisUser={subAdminDetails.tbl_user_id} hideGraphs={true} userTokenDetails={userTokenDetails} renderAsChildren={true} />
            </div> */}
            <div className='mt-5'>
              <div className='d-flex justify-content-between w-100'>

                <nav>
                  <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                    <button className={`nav-link  bg-transparent ${tab === "Admin" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("Admin")}>Admin</button>
                    <span className="border-left"></span>
                    <button className={`nav-link  bg-transparent ${tab === "CRM" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("CRM")}>CRM</button>

                  </div>
                </nav>
                <div className='d-flex gap-3 col-md-5 justify-content-end'>
                  <div className="col-md-6 pr-0">
                    <CustomSelect
                      selectData={[{ name: "Overall" }, { name: 'Previous Month' }, { name: 'Previous Week' }, { name: 'Yesterday' }, { name: 'Today' }, { name: 'Current Week' }, { name: 'Current Month' }, { name: 'Custom' }]}
                      optionLabel={'name'} optionValue={'name'}
                      onItemCllick={(e) => {
                        console.log('onselecttttttt', e);
                        if (e === 'Custom') {
                          setselectedDateFilter(e)
                          setshowCalendar(true)

                        } else {
                          setselectedDateFilter(e)
                          setshowCalendar(false)
                        }

                      }}
                      removeMb={true}
                      value={selectedDateFilter}
                    />
                    {showCalendar &&
                      <div className='position-absolute dropdownZindex' ref={box} style={{ right: "0px" }}>
                        <Calendar onChange={(val) => {
                          setDateRange({
                            from: moment(val[0]).format('YYYY-MM-DD'),
                            to: moment(val[1]).format('YYYY-MM-DD')
                          })
                          setshowCalendar(false)
                        }}
                          //value={[new Date(filterData["Date"]["value"][0]), new Date(filterData["Date"]["value"][1])]}
                          className="borderRadius border-0 calenderBorder col-md-12"
                          next2Label={null}
                          rev2Label={null}
                          selectRange={true}
                          calendarType={"US"} />
                      </div>


                    }
                  </div>
                </div>
              </div>
              <div className="tab-content d-block" id="nav-tabContent" >
                {tab === 'Admin' &&
                  <SubAdminProfileDetailsAdmin subAdminId={subAdminDetails.tbl_user_id} dateRange={dateRange} disbursedAmount={subAdminDetails["totalDisbursedAmount"]} />
                }
                {tab === 'CRM' &&
                  <SubAdminProfileDetailsCRM subAdminId={subAdminDetails.tbl_user_id} dateRange={dateRange} />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SubAdminProfileDetails)