import React, { useState } from 'react';
import NewTablev2 from '../../../utils/newTablev2';


const TableWithPages = ({ data, columns, noOfRowsPerPage, freezeFirstColumn, handleInputChange }) => {
  // console.log("inside", data, columns)
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = noOfRowsPerPage;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  return (
    <>
      <div className=''>
        <NewTablev2 columns={columns} freezeFirstColumn={freezeFirstColumn} showSideBorders={true} customStyles={{ rowBg: "#e7f9ff" }}>
          {currentRows.map((row, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column.field_Name === "date"
                    ? new Date(row[column.field_Name]).toLocaleDateString()
                    : column.field_Name === "inputBar"
                      ? <input
                        type="number"
                        value={row[column.field_Name]}
                        onChange={(e) => handleInputChange(e, index, colIndex)}
                      />
                      : row[column.field_Name]}              </td>
              ))}
            </tr>
          ))}
        </NewTablev2>
      </div>
      <div className="pagination-controls d-flex justify-content-between align-items-center">
        {/* <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          &lt;
        </button> */}
        <img onClick={handlePreviousPage} disabled={currentPage === 1} src={"/assets/images/arrow-left.png"} alt="" className="cursor ms-5" />

        <p>Page {currentPage} of {totalPages}</p>
        {/* <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          &gt;
        </button> */}
        <img onClick={handleNextPage} disabled={currentPage === totalPages} src={"/assets/images/arrow-right.png"} className="cursor me-5" alt="" />

      </div>
    </>
  );
};

export default TableWithPages;
