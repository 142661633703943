import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import call from "../../../../service";
import { getDocDetails } from "../../../../utils/myFunctions";
import { convertImageToPdf } from "../../../../utils/myFunctions";
import toastDisplay from "../../../../utils/toastNotification";
import { LCTypesObject } from "../../../../utils/myFunctions";
import { NewInput } from "../../../../utils/newInput";
import AccordionCommon from "./accordionCommon";
import { FileInput } from "../../../../utils/FileInput";
import InformationIcon from "./informationIcon";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";

const addBuyerTabs = [
  { name: "finName" }
]

const FAQsData = [
  {
    Heading: "Last 5 years",
    FaqsHeading: "HeadingOne",
    FaqsCollapse: "CollapseOne",
    points: [
      "Audit report",
      "Gst document",
      "ITR Document",
      "Debtors statement",
      "Creditors statement",
      "Balance sheet *",
      "Cash flow statement * ",
      "Profit & loss * ",
      " Ratios *",
    ],
    count: "500"
  },
  {
    Heading: "Last 3 years",
    FaqsHeading: "HeadingTwo",
    FaqsCollapse: "CollapseTwo",
    points: [
      "Audit report",
      "Gst document",
      "ITR Document",
      "Debtors statement",
      "Creditors statement",
      "Balance sheet *",
      "Cash flow statement * ",
      "Profit & loss * ",
      " Ratios *",
    ],
    count: "400"
  },

];


const DocsRequested = ({ userTokenDetails, navToggleState }) => {

  let history = useHistory()

  const [showLoader, setshowLoader] = useState(false)
  const [financierList, setFinancierList] = useState([])
  const [selectedFinancierRemarks, setSelectedFinancierRemark] = useState({})


  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  // const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  // const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  useEffect(() => {
    setshowLoader(true)
    call("POST", 'getApplicationFinancierList', { applicationId: localStorage.getItem("applicationId") }).then(res => {
      setFinancierList(res)
      // get selected financier remarks
      call('POST', 'getBuyerCreditAndRemark', {
        userId: localStorage.getItem("lenderId"), buyerId: localStorage.getItem("buyerId"),
        applicationId: localStorage.getItem("applicationId")
      }).then(res => {
        setSelectedFinancierRemark(res)
        setshowLoader(false)
      }).catch(err => {
        setshowLoader(false)
        toastDisplay("Something went wrong", err)
      })
    }).catch(err => {
      setshowLoader(false)
      toastDisplay("Something went wrong", err)
    })
  }, [])

  async function changeSelectedFinancierAndReload(selectedFin) {
    localStorage.setItem("lenderId", selectedFin.id)
    localStorage.setItem("lenderName", selectedFin.name)
    setshowLoader(true)
    let additionalReqResp = await call('POST', 'isAdditionalDetailsReqByFinancer', {
      lenderIdArr: [{ lender_id: selectedFin.id }],
      applicationId: localStorage.getItem('applicationId')
    })
    // console.log("additionalReqResp========", additionalReqResp);
    setshowLoader(false)
    if (additionalReqResp?.[0]) {
      localStorage.setItem("additionalReqObj", JSON.stringify(additionalReqResp?.[0]))
    }
    else {
      localStorage.removeItem("additionalReqObj")
    }
    window.location.reload()
  }


  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="/DocsRequested" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Financer Remark"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => {
                history.go(-1)
              }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
              {financierList.map((item, index) => {
                return (
                  <li>
                    <a className={"nav-link formTab formTabwidth pl-4 pr-4 cursor-pointer " + (localStorage.getItem("lenderId") / 1 == item.id / 1 ? " formActiveTab show" : "")}
                      onClick={() => {
                        changeSelectedFinancierAndReload(item)
                      }}
                    >
                      {item.name}</a>
                  </li>
                )
              })}
            </ul>
            <InformationIcon selectedFinancierRemarks={selectedFinancierRemarks} userTokenDetails={userTokenDetails} additionalReqObj={localStorage.getItem("additionalReqObj")} />

          </main>

        </div >
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(DocsRequested)
