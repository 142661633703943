export default function validate(repData, userTypeId) {

  let errors = {};

  let values = repData.resportSpecifications ? repData.resportSpecifications : {}
  let keysArray = Object.keys(values)

  if (userTypeId != 6) {
    if (keysArray.length) {
      keysArray.map((key) => {
        if (!values[key].value) {
          errors[key] = values[key].name + " is Mandatory"
        }
        return null
      })
    } else {
      errors["formJson"] = "Specification is Missing"
    }
  }

  if (!repData.reportRemark) {
    errors.reportRemark = "Remark is Mandatory"
  }

  if (!repData.reportDocument) {
    errors.reportDocument = "Report Document is Mandatory"
  }


  console.log(errors);
  return errors;
}
