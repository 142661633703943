import React, { useEffect, useState } from "react";
import Header from '../../partial/header';
import FilePreview from '../../utilComponents/previewFile'
import DigitalSignDoc from '../../contractDocument/digitalSignDoc';
import call from "../../../service";

const ViewApplication = ({ application, setApplication, userTokenDetails }) => {
  const [data, setData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [fileData, setfileData] = useState({});
  const [accorddata, setaccorddata] = useState({
    1: true,
    2: true,
    3: true
  });

  // UseEffects
  useEffect(() => {
    call('POST', 'getDiscounting', { id: application.id, type: application.type, finalAppView: true, tradeType: (application.type / 1 === 4) ? "offline" : "" }).then((result) => {
      console.log('Get Discounting in view app =====> ', result);
      if (application.type / 1 < 4) {
        setData(result);
      } else {
        setData({
          sellerName: result.finData.sellerName,
          buyerName: result.finData.buyerName,
          lenderName: result.finData.lenderName,
          contractAmountCurrencyName: result.finData.offline_data.dirCurrency.split(":")[1],
          contract_amount: result.finData.contract_amount,
          amountCurrencyName: result.finData.offline_data.dirCurrency.split(":")[1],
          amount: result.finData.amount,
          interest_rate: result.finData.interest_rate,
          credit_days: result.finData.credit_days,
          status: result.finData.status,
          loan_id: result.finData.loan_id,
          manager_name: result.finData.manager_name,
          disbursement_date: result.finData.disbursement_date,
          docData: result.docArray,
          disbursedDoc: result.disbursedDoc,
        });
      }
    }).catch((e) => {
      console.log('error in getDiscountingnn', e);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  // JSX body
  return (
    <>
      <Header
        title={'Discounting Application'}
        userTokenDetails={userTokenDetails}
        application={application}
        setApplication={setApplication} />
      <div className="col-md-12 text-center m-4">
        <h1><u>Discounting Application</u></h1>
        <br />
      </div>

      <div className={"accordionItem" + (accorddata[1] ? " open" : " closed")}>
        <h2 className="accordionItemHeading ml-2" onClick={() => handleAccordion(1)}> Application Form</h2>
        <div className="accordionItemContent">
          <div className="col-md-12 row m-4">
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Applicant</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.sellerName}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Buyer</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.buyerName}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>{application.type === 1 ? 'PO' : application.type === 2 ? 'LC' : application.type === 3 ? 'Invoice' : application.type === 4 ? 'My Trade' : ''} Number</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.reference_no ? data.reference_no : data.lc_number ? data.lc_number : ""}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>{application.type === 1 ? 'PO' : application.type === 2 ? 'LC' : application.type === 3 ? 'Invoice' : application.type === 4 ? 'Trade' : ''} Issue Date</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.issue_date ? data.issue_date : data.lc_issue_date ? data.lc_issue_date : ""}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Contract Amount</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.contractAmountCurrencyName} {data.contract_amount}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Amount</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.amountCurrencyName} {data.amount}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Lender</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.lenderName}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Credit Days</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.credit_days}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"accordionItem" + (accorddata[2] ? " open" : " closed")}>
        <h2 className="accordionItemHeading ml-2" onClick={() => handleAccordion(2)}> Disbursal Form</h2>
        <div className="accordionItemContent">
          <div className="col-md-12 row m-4">
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Finance Id</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.loan_id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Finance Manager</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.manager_name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Disbursal Amount</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.amountCurrencyName} {data.amount}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h3><u>Disbursal At</u></h3>
                <p className="text-primary text-md font-italic font-weight-bold">{data.disbursement_date}</p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <br />
            <h3><u>Disbursal Documents</u></h3>
            <br />
            <div className="row form-group">
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Document Name</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload At</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload By</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Actions</u></h3>
              </div>
              {data.disbursedDoc && data.disbursedDoc.length ? data.disbursedDoc.map((doc) => {
                doc.tbl_doc_id = doc.id
                doc.document_name = doc.doc_name
                doc.uploaded_by = doc.createdBy
                doc.uploaded_on = doc.created_at
                return (
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.document_name || '--'}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.modified_at}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.createdBy}</label>
                    </div>

                    <div className="col-md-2 ml-3">
                      <div className="row col-md-12">
                        <button title="View File" className="text-center btn btn-primary btn-sm ml-3" onClick={() => callPreviewFileComponent(doc, "view")}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button title="Download File" className="text-center btn btn-primary btn-sm ml-2" onClick={() => callPreviewFileComponent(doc, "download")}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                        <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={() => callAclAuthComponent(doc)}>
                          <i class="fas fa-file-signature"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }) : ''}

            </div>
          </div>


        </div>
      </div>

      <div className={"accordionItem" + (accorddata[3] ? " open" : " closed")}>
        <h2 className="accordionItemHeading ml-2" onClick={() => handleAccordion(3)}> Supporting Documents</h2>
        <div className="accordionItemContent">
          <div className="col-md-12">
            <br />
            <h3><u>Initial Documents</u></h3>
            <br />
            <div className="row form-group">
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Document Name</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload At</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload By</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Actions</u></h3>
              </div>
              {data.docData && data.docData.docList.length ? data.docData.docList.map((doc) => {
                doc.tbl_doc_id = doc.id
                doc.document_name = doc.doc_name
                doc.uploaded_by = doc.createdBy
                doc.uploaded_on = doc.created_at
                return (
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.document_name || '--'}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.modified_at}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.createdBy}</label>
                    </div>

                    <div className="col-md-2 ml-3">
                      <div className="row col-md-12">
                        <button title="View File" className="text-center btn btn-primary btn-sm ml-3" onClick={() => callPreviewFileComponent(doc, "view")}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button title="Download File" className="text-center btn btn-primary btn-sm ml-2" onClick={() => callPreviewFileComponent(doc, "download")}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }) : ''}

            </div>
          </div>

          <div className="col-md-12">
            <br />
            <h3><u>Framework Agreement</u></h3>
            <br />
            <div className="row form-group">
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Document Name</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload At</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload By</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Actions</u></h3>
              </div>
              {data.docData && data.docData.frameworkDocList.length ? data.docData.frameworkDocList.map((doc) => {
                let valueData = JSON.parse(doc.frameworkDocList);
                console.log(valueData);
                doc.tbl_doc_id = valueData.id
                doc.document_name = valueData.doc_name
                doc.uploaded_by = doc.lendername
                doc.uploaded_on = valueData.created_at
                return (
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.document_name || '--'}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.uploaded_on}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.uploaded_by}</label>
                    </div>

                    <div className="col-md-2 ml-3">
                      <div className="row col-md-12">
                        <button title="View File" className="text-center btn btn-primary btn-sm ml-3" onClick={() => callPreviewFileComponent(doc, "view")}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button title="Download File" className="text-center btn btn-primary btn-sm ml-2" onClick={() => callPreviewFileComponent(doc, "download")}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }) : ''}

            </div>
          </div>

          <div className="col-md-12">
            <br />
            <h3><u>Exhibit Agreement</u></h3>
            <br />
            <div className="row form-group">
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Document Name</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload At</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload By</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Actions</u></h3>
              </div>
              {data.docData && data.docData.exhibitDocList.length ? data.docData.exhibitDocList.map((doc) => {
                doc.tbl_doc_id = doc.id
                doc.document_name = doc.doc_name
                doc.uploaded_by = doc.createdBy
                doc.uploaded_on = doc.created_at
                return (
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.document_name || '--'}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.modified_at}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.createdBy}</label>
                    </div>

                    <div className="col-md-2 ml-3">
                      <div className="row col-md-12">
                        <button title="View File" className="text-center btn btn-primary btn-sm ml-3" onClick={() => callPreviewFileComponent(doc, "view")}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button title="Download File" className="text-center btn btn-primary btn-sm ml-2" onClick={() => callPreviewFileComponent(doc, "download")}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }) : ''}

            </div>
          </div>

          <div className="col-md-12">
            <br />
            <h3><u>Notice of Assignment</u></h3>
            <br />
            <div className="row form-group">
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Document Name</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload At</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Upload By</u></h3>
              </div>
              <div className="col-md-3">
                <h3 className="col-md-12"><u>Actions</u></h3>
              </div>
              {data.docData && data.docData.NOADocList.length ? data.docData.NOADocList.map((doc) => {
                doc.tbl_doc_id = doc.id
                doc.document_name = doc.doc_name
                doc.uploaded_by = doc.createdBy
                doc.uploaded_on = doc.created_at
                return (
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.document_name || '--'}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.modified_at}</label>
                    </div>
                    <div className="col-md-3">
                      <label className="col-md-12 text-primary text-md font-italic font-weight-bold">{doc.createdBy}</label>
                    </div>

                    <div className="col-md-2 ml-3">
                      <div className="row col-md-12">
                        <button title="View File" className="text-center btn btn-primary btn-sm ml-3" onClick={() => callPreviewFileComponent(doc, "view")}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        <button title="Download File" className="text-center btn btn-primary btn-sm ml-2" onClick={() => callPreviewFileComponent(doc, "download")}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }) : ''}

            </div>
          </div>
        </div>
      </div>

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
      />}

      {showAclAuthModal &&
        <DigitalSignDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refresh}
          setrefreshDoc={setrefresh} />}

    </>
  );
};

export default ViewApplication;
