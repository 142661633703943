import React, { useState, useEffect } from "react";

export const FormProgressBar = ({ tabs, activeTab, label, separationWidth, handleClick }) => {
  return (
    <div className="d-flex row " >
      {tabs.map((i, j) => {
        return (
          <div className="d-flex flex-row w-auto align-items-center m-0 p-0" >
            <div className="mr-2" >
              <img
                // style={activeTab / 1 >= j / 1 ? {} : { marginBottom: '0.3rem' }}
                src={activeTab / 1 == j / 1 ?
                  "assets/images/formProgressCircleActive.png" :
                  activeTab / 1 > j / 1 ? "assets/images/green_tick.png" :
                    "assets/images/formProgressCircleInactive.png"} />
            </div>
            <label
              onClick={() => { handleClick(i, j) }}
              className={(activeTab / 1 >= j / 1 ? "font-size-14 font-wt-500 text-color1" : "font-size-14 font-wt-500 text-secondary") + " m-0 p-0 cursor "}
            >{i[label]}</label>
            {j != tabs.length - 1 ?
              <div
                className="mx-3"
                style={{
                  width: separationWidth, height: '0.2rem', background: 'red', borderRadius: '100px',
                  backgroundColor: activeTab - 1 / 1 >= j / 1 ? '#1B94B7' : '#D5D5D5'
                }}
              /> : null}
          </div>
        )
      })}

    </div>
  )
}