import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import call from '../../service'
import NewTablev2 from '../../utils/newTablev2'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import { TTVBuyerColumns } from '../Reports/TableJson'
import Filter from '../InvoiceDiscounting/components/Filter'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import { ToastContainer } from 'react-toastify'
import toastDisplay from '../../utils/toastNotification'
import { NewInput } from '../../utils/newInput'
import CustomLineChart from '../CRM/CustomLineChart'
import SideBarV2 from '../partial/sideBarV2'
import HeaderV2 from '../partial/headerV2'
import { Area, AreaChart, Bar, BarChart, Funnel, Legend, Line, FunnelChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import { NewTable } from '../../utils/newTable'
import moment from 'moment'
import TreeMap from "react-d3-treemap";
import "react-d3-treemap/dist/react.d3.treemap.css";
import Tree from 'react-d3-tree';


const myTreeData = [
  {
    name: 'TOP 05 Supplier',
    children: [
      {
        name: 'Supplier 1',
      },
      {
        name: 'Buyer 2',
      },
      {
        name: 'Buyer 3',
      }
    ],
  },
  {
    name: 'Supplier 2',
    attributes: {},
    children: [
      {
        name: 'Buyer 1',
      },
      {
        name: 'Buyer 2',
      },
      {
        name: 'Buyer 3',
      }
    ],
  },
  {
    name: 'Supplier 3',
    attributes: {},
    children: [
      {
        name: 'Buyer 1',
      },
      {
        name: 'Buyer 2',
      },
      {
        name: 'Buyer 3',
      }
    ],
  },
];


let tempDbData = {
  productDetails: "sfsdf sdufhsdjk f sfsdgsd fjfjsfjhs gfjs bfjhs bfsd gf", hsnCodes: [12, 234, 4356, 56546],
  buyerAddress: "sagsadjhsdgf shfgvsd hf",
  buyerIndustryType: "Apparels", buyerDunsNo: '12234234', buyerTurnOver: 2000000,
  shipmentGraphData: [{
    label: 2021, Jan: 100, Feb: 500, Mar: 4543, Apr: 4545, May: 45435, Jun: 23213, Jul: 21321, Aug: 23213, Sep: 2334, Oct: 45,
    Nov: 7665, Dec: 787, index: 0
  }, {
    label: 2022, Jan: 100, Feb: 500, Mar: 4543, Apr: 4545, May: 45435, Jun: 23213, Jul: 21321, Aug: 23213, Sep: 2334, Oct: 45,
    Nov: 7665, Dec: 787, index: 1
  }, {
    label: 2022, Jan: 100, Feb: 500, Mar: 4543, Apr: 4545, May: 45435, Jun: 23213, Jul: 21321, Aug: 23213, Sep: 2334, Oct: 45,
    Nov: 7665, Dec: 787, index: 2
  }]
}

const label = {
  "paid": "Paid",
  "upcoming": "Upcoming",
  "due": "Due",
  "overdue": "Overdue",
  "paidApp": "Paid",
  "dueApp": "Due",
  "exporterCoinsUsed": "Exporter",
  "importerCoinsUsed": "Importer",
  "financierCoinsUsed": "Financier"
}

const graphdropdown = [
  { fromYear: 2016, toYear: 2023 },
  { fromYear: 2009, toYear: 2016 }
]

const TTVBuyerDetail = ({ userTokenDetails, navToggleState }) => {

  let ttvBuyerInfo = JSON.parse(localStorage.getItem("ttvBuyerInfo") || "{}")

  const queryParams = new URLSearchParams(window.location.search)
  let ttvUserType = queryParams.get('type')
  let isTTVSellerUser = ttvUserType === "exporter"
  let isFromExporter = ttvBuyerInfo.isFromExporter || false

  const [showLoader, setShowLoader] = useState(false)
  const [dbData, setDbData] = useState(tempDbData)
  const [isPurchaseSuccesful, setPurchaseSuccess] = useState(ttvBuyerInfo.purchased || false)
  const [data, setData] = useState({
    shipmentGraphDD: 0, shipmentGraphDataWise: 'Value', shipmentGraphMode: 'filterGraphMode',
    hsnGraphMode: 'filterGraphMode'
  })
  const [errors, setErrors] = useState({})

  const [showPopup, togglePopup] = useState(false)
  const [walletData, setWalletData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPage2, setCurrentPage2] = useState(1)
  const [shipmentList, setShipmentList] = useState({})
  const [hsnShipmentRaw, setHsnShipmentRaw] = useState([])
  const [hsnShipmentList, setHsnShipmentList] = useState({})
  const [shipmentTreeMapData, setShipmentTreeMapData] = useState(null)
  const [horizontalTreeData, setHorizontalTreeData] = useState(null)

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  let ttvBuyerId = JSON.parse(localStorage.getItem("ttvBuyerInfo"))["_id"]


  useEffect(() => {
    getWalletBalance()
    getTTVBuyerDetails()
    getTTVBuyerList()
    setTreeMapData()
    getHorizontalTreeMapData()
  }, [data, currentPage])

  useEffect(() => {
    getHSNWiseShipmentData()
  }, [currentPage2, data.searchHsnTable])

  async function getHorizontalTreeMapData() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getHorizontalTreeMapData', { ttvBuyerId, isTTVSellerUser, isFromExporter })
    setHorizontalTreeData(apiResp)
    setShowLoader(false)
  }

  async function setTreeMapData() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getShipmentTreemapData', { ttvBuyerId, isTTVSellerUser, isFromExporter })
    setShipmentTreeMapData(apiResp)
    setShowLoader(false)
  }

  function handleChange(event) {
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function downloadShipmentData() {
    if (!shipmentList?.data?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let csvString = "Date,Exporter,Product,HSN Code,Weight\r\n"
      for (let index = 0; index < shipmentList?.data?.length; index++) {
        const element = shipmentList?.data?.[index];
        let rowString = `${element[0]},${element[1]},${element[2]},${element[3]},${element[4]}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `ShipmentDetails.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadShipmentData", error);
    }
  }

  async function downloadHsnData() {
    if (!hsnShipmentRaw?.length) {
      return toastDisplay('No data found to download', "info")
    }
    try {
      let csvString = `HSN Code,Shipment Value,${isTTVSellerUser ? "Buyer" : "Supplier"},Shipment,Product Description,Top ${isTTVSellerUser ? "Export" : "Import"} Countries\r\n`
      for (let index = 0; index < hsnShipmentRaw.length; index++) {
        const element = hsnShipmentRaw[index];
        let rowString = `${element._id?.HS_CODE},${element.totalFOBValueUSD},${element.totalExporters?.length},${element.totalShipments},${element.productDescription},${element.topImportCountries?.join(":")}\r\n`
        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `HSNWiseShipmentDetails.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("error in downloadHsnData", error);
    }
  }

  const CustomTooltipForCoinsUtilization = ({ payload }) => {
    let shimentData = data.shipmentGraphDataWise === "Value" ? dbData.shipmentSummaryFOB : dbData.shipmentSummaryCount
    let label = data.shipmentGraphDataWise === "Value" ? ` Shipment Value ` : ` Shipment Count `
    return (
      <div
        className='bg-dark px-4 py-3'
        style={{
          borderRadius: 10
        }}>
        <p className='font-wt-600 text-white font-size-14 m-0 p-0'>{`${shimentData?.[payload?.[0]?.payload?.index]?.["label"]} ${label}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Jan - ${shimentData?.[payload?.[0]?.payload?.index]?.["Jan"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Feb - ${shimentData?.[payload?.[0]?.payload?.index]?.["Feb"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Mar - ${shimentData?.[payload?.[0]?.payload?.index]?.["Mar"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Apr - ${shimentData?.[payload?.[0]?.payload?.index]?.["Apr"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`May - ${shimentData?.[payload?.[0]?.payload?.index]?.["May"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Jun - ${shimentData?.[payload?.[0]?.payload?.index]?.["Jun"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Jul - ${shimentData?.[payload?.[0]?.payload?.index]?.["Jul"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Aug - ${shimentData?.[payload?.[0]?.payload?.index]?.["Aug"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Sep - ${shimentData?.[payload?.[0]?.payload?.index]?.["Sep"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Oct - ${shimentData?.[payload?.[0]?.payload?.index]?.["Oct"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Nov - ${shimentData?.[payload?.[0]?.payload?.index]?.["Nov"]}`}</p>
        <p className='font-wt-600 text5CB8D3 font-size-14 m-0 p-0'>{`Dec - ${shimentData?.[payload?.[0]?.payload?.index]?.["Dec"]}`}</p>
      </div>
    );
  };


  const getWalletBalance = () => {
    call('POST', 'getWalletBalance', { userId }).then((result) => {
      console.log("API result getWalletBalance", result);
      setShowLoader(false)
      if (result && result.length) {
        setWalletData(result[0])
      }
    }).catch((e) => {
      console.log("Error while getWalletBalance", e);
      setShowLoader(false)
    });
  }

  const unlockBuyerdetails = async () => {
    setShowLoader(true)
    try {
      const result = await call('POST', 'unlockBuyerdetails', { userId })
      console.log('success in unlockBuyerdetails', result)
      setShowLoader(false)
      setPurchaseSuccess(true)
      await call('POST', "updateRecentlyViewedBuyer", { userId, details: JSON.parse(localStorage.getItem("ttvBuyerInfo")) })
      togglePopup(false)
      toastDisplay("Purchase Succesful", "success")
    } catch (e) {
      toastDisplay(e, "error")
      setShowLoader(false)
    }
  }

  async function getHSNWiseShipmentData() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getHSNWiseShipmentData', {
      ttvBuyerId,
      resultPerPage: 10, currentPage: currentPage2, search: data.searchHsnTable,
      isTTVSellerUser,
      isFromExporter
    })
    setShowLoader(false)
    setHsnShipmentRaw(apiResp.data)
    let tempTblData = []
    for (let index = 0; index < apiResp?.data?.length; index++) {
      const element = apiResp?.data[index];
      let row = []
      row[0] = element?._id?.HS_CODE
      row[1] = Intl.NumberFormat('en-US', { notation: 'compact' }).format(element?.totalFOBValueUSD) + " USD"
      row[2] = element?.totalExporters?.length
      row[3] = element?.totalShipments
      row[4] = element?.productDescription
      row[5] = isFromExporter ?
        <div>
          <div className='position-relative' >
            <label className={`font-wt-600 font-size-22 position-absolute `} style={{ top: "-0.8rem" }} >{`.`}</label>
            <label className={`ml-3 font-size-14 text-color-label font-wt-600 `} >{`INDIA`}</label>
          </div>
        </div>
        :
        <div>
          {element.topImportCountries?.map((i, j) => {
            return (<div className='position-relative' >
              <label className={`font-wt-600 font-size-22 position-absolute `} style={{ top: "-0.8rem" }} >{`.`}</label>
              <label className={`ml-3 font-size-14 text-color-label font-wt-600 `} >{`${i}`}</label>
            </div>)
          })}
        </div>


      tempTblData.push(row)
    }
    setHsnShipmentList({ data: tempTblData, totalCount: apiResp?.totalCount })
  }

  async function getTTVBuyerList() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getTTVBuyerShipments', {
      ttvBuyerId,
      fromYear: graphdropdown[data.shipmentGraphDD].fromYear, toYear: graphdropdown[data.shipmentGraphDD].toYear,
      resultPerPage: 10, currentPage, search: data.search,
      isTTVSellerUser,
      isFromExporter
    })
    setShowLoader(false)
    let tempTblData = []
    for (let index = 0; index < apiResp?.data?.length; index++) {
      const element = apiResp?.data[index];
      let row = []
      row[0] = moment(element["DATE"]).format("YYYY-MM-DD")
      row[1] = isTTVSellerUser ? element.CONSIGNEE_NAME : element?.EXPORTER_NAME
      row[2] = element?.PRODUCT_DESCRIPTION
      row[3] = element?.HS_CODE
      row[4] = isFromExporter ? element.TOTAL_GROSS_WEIGHT + " " + element.UNIT : element?.WEIGHT + " " + element?.WEIGHT_UNIT
      tempTblData.push(row)
    }
    setShipmentList({ data: tempTblData, totalCount: apiResp?.totalCount })
  }

  async function getTTVBuyerDetails() {
    setShowLoader(true)
    let apiResp = await call('POST', 'getTTVBuyerInformation', {
      ttvBuyerId,
      fromYear: graphdropdown[data.shipmentGraphDD].fromYear, toYear: graphdropdown[data.shipmentGraphDD].toYear,
      isTTVSellerUser,
      isFromExporter
    })
    // console.log("apiResppppppppppppppppppppppppppppp", apiResp);
    setShowLoader(false)
    setDbData(apiResp)
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {showPopup && <FinanceInvoiceModal
        isCentered={true}
        limitinvoice={showPopup}
        setLimitinvoice={togglePopup}
        closeSuccess={() => togglePopup(false)}
      >
        <div className='col-md-12 text-center'>
          <div>
            <img src="/assets/images/sidebarV2/buyermanagement.svg" height={50} width={50} />
          </div>
          <div>
            <label className='font-size-16 font-wt-500'>{`Unlock ${isTTVSellerUser ? "exporter" : "buyer"} details`}</label>
          </div>
          <div className='mt-3'>
            <button className={`new-btn p-2 text-white cursor`} onClick={() => { unlockBuyerdetails() }}>
              Unlock with <img src="/assets/images/getfinancedetails-icon.png" alt="" height="20" class="ms-1" /> 30
            </button>
          </div>
          <div className='mt-2'>
            Available Balance : <label className='text-color1 font-size-14 font-wt-500'>
              <img src="/assets/images/getfinancedetails-icon.png" alt="" height="20" class="ms-1" />
              {walletData?.coins || 0}
            </label>

          </div>

        </div>
      </FinanceInvoiceModal>}

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="buyerManagement" userTokenDetails={userTokenDetails} />
          <main role="main" className={"container-bg ml-sm-auto col-lg-10 " + (navToggleState?.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              // title={`Buyer Management ${showAddBuyerForm ? ` > ${data.isEditing ? 'Edit Buyer' : 'Add Buyer'}` : ""}`}
              title={isTTVSellerUser ? `Exporter Details` : `Buyer Details`}
              userTokenDetails={userTokenDetails} />


            <div className='mb-4'>
              <div className='row d-flex' >
                <div className='w-auto'>
                  <i
                    onClick={() => {
                      window.close();
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>

                </div>
                <div className='w-auto'>
                  <p className='text-color-0C0C0C font-size-16 font-wt-600 m-0 p-0' >{dbData.buyerName}</p>
                  <p className='text-color1 font-size-14 font-wt-600 m-0 p-0' >{dbData.buyerWebsite || "NA"}</p>
                </div>
                {!isPurchaseSuccesful ? <div className='ml-auto w-auto'>
                  <button type="button"
                    onClick={() => {
                      togglePopup(true)
                    }}
                    className={` new-btn py-2 px-4 text-white font-size-18`}>
                    {`Get detailed ${isTTVSellerUser ? 'seller' : 'buyer'} information`}
                  </button>
                </div>
                  : null}
              </div>
            </div>

            <div className='mb-5'>

              <div
                style={{ display: 'flex', flexFlow: 'row' }}
                className='card h-100 dashboard-card shadow-sm'>
                <div className="col-4">
                  <label className="font-size-15 font-wt-500 text-secondary">Product Details</label>
                  <p className={`font-size-15 font-wt-400 ${isPurchaseSuccesful ? '' : 'blurBackground'}`}>{dbData.productDescription}</p>
                  <div className={`row ${isPurchaseSuccesful ? '' : 'blurBackground'}`}>
                    {dbData?.buyerHSN &&
                      dbData?.buyerHSN.map(item => {
                        return <label className='bg-color1 p-1 px-2 mx-2 my-2 border-radius-5 w-auto'>{item}</label>
                      })
                    }
                  </div>
                </div>

                <div className="col-4 ">
                  <label className="font-size-15 font-wt-500 text-secondary">Contact Details</label>
                  <div>
                    <label className="w-20 font-wt-700">Address</label>
                    <label className="font-wt-500">:</label>
                    <label className={`ml-2 font-wt-400 ${isPurchaseSuccesful ? '' : 'blurBackground'}`}>{dbData.buyerAddress || "NA"}</label>
                  </div>
                  <div>
                    <label className="w-20 font-wt-700">Mobile No.</label>
                    <label className="font-wt-500">:</label>
                    <label className={`ml-2 font-wt-400 ${isPurchaseSuccesful ? '' : 'blurBackground'}`}>{dbData.buyerPhone ? (dbData.buyerPhoneCode + " " + dbData.buyerPhone) : "NA"}</label>
                  </div>
                  <div>
                    <label className="w-20 font-wt-700">Email Id</label>
                    <label className="font-wt-500">:</label>
                    <label className={`ml-2 font-wt-400 ${isPurchaseSuccesful ? '' : 'blurBackground'}`}>{dbData.buyerEmail || "NA"}</label>
                  </div>
                </div>

                <div className="col-4">
                  <label className="font-size-15 font-wt-500 text-secondary">Other Details</label>
                  <div>
                    <label className="w-30 font-wt-700">Industry type</label>
                    <label className="font-wt-500">:</label>
                    <label className={`ml-2 font-wt-400 ${isPurchaseSuccesful ? '' : 'blurBackground'}`}>{dbData.industryTypes?.[0] || "NA"}</label>
                  </div>
                  <div>
                    <label className="w-30 font-wt-700">DUNS No.</label>
                    <label className="font-wt-500">:</label>
                    <label className={`ml-2 font-wt-400`}>{dbData.buyerDUNSNo || "NA"}</label>
                  </div>
                  <div>
                    <label className="w-30 font-wt-700">Turnover</label>
                    <label className="font-wt-500">:</label>
                    <label className={`ml-2 font-wt-400`}>{"$" + Intl.NumberFormat("en", { notation: 'compact' }).format(ttvBuyerInfo.FOB)}</label>
                  </div>
                </div>

              </div>
            </div>

            <div >
              <div className='card p-3 dashboard-card border-0 borderRadius h-100 d-flex flex-row pt-5 pb-4 ' >
                <div className='col-12'>

                  <div class=" w-100 cursor" >
                    <div className='d-flex gap-2 align-items-center' >
                      <div className='w-auto dropdown' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <label class="font-wt-600 font-size-16" >
                          {`Shipment  ${!data.shipmentGraphMode ? (shipmentList.totalCount || 0) : (graphdropdown[data.shipmentGraphDD].fromYear + "-" + graphdropdown[data.shipmentGraphDD].toYear)}`}
                        </label>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          {graphdropdown.map((element, index) => {
                            return <li className="dropdown-item cursor"
                              onClick={() => { setData({ ...data, shipmentGraphDD: index }) }}>{element.fromYear + "-" + element.toYear}</li>
                          })}
                        </ul>
                        <img className='align-self-baseline' src='assets/images/arrowdown.png' />

                      </div>

                      {data.shipmentGraphMode ? (<div className="text-center d-flex flex-row align-items-baseline justify-content-center w-65">
                        <div >
                          <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setData({ ...data, shipmentGraphDataWise: "Value" })
                              }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Values ($)</button>
                            </li>
                            <li className="nav-item p-0 " role="presentation">
                              <button onClick={() => {
                                setData({ ...data, shipmentGraphDataWise: "Count" })
                              }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">Count</button>
                            </li>
                          </ul>
                        </div>
                      </div>) : (
                        <div className="text-center d-flex flex-row align-items-baseline  w-75">
                          <div className='w-100 position-relative'>
                            <NewInput label={"Search"}
                              type={"text"} name={"search"} value={data.search} onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}

                      <div className='d-flex row align-items-end'>
                        <div className='px-3 col-6'>
                          <img
                            onClick={() => { setData({ ...data, shipmentGraphMode: !data.shipmentGraphMode }) }}
                            className='cursor'
                            src={`/assets/images/${data.shipmentGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                        </div>
                        <div className='col-6'>
                          <img
                            onClick={downloadShipmentData}
                            className='cursor' src='/assets/images/download_icon_with_bg.png' />
                        </div>
                      </div>

                    </div>

                  </div>

                  <div
                    className={`mt-4 ${isPurchaseSuccesful ? ' ' : ' blurBackground '}  `}
                  >
                    {!data.shipmentGraphMode ? (
                      <div
                      >
                        <NewTable
                          disableAction={true}
                          data={shipmentList?.data || []}
                          columns={[{ name: "Date" }, { name: isTTVSellerUser ? "Buyer" : "Exporter" },
                          { name: "Product" }, { name: "HSN Code" }, { name: "Weight" }]}
                        />
                        <Pagination perPage={data.resultPerPage || 10} page={currentPage} totalCount={shipmentList.totalCount} onPageChange={(p) => setCurrentPage(p)} />
                      </div>
                    ) : (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          width={500}
                          height={300}
                          data={data.shipmentGraphDataWise === "Value" ? (dbData.shipmentSummaryFOB || []) : (dbData.shipmentSummaryCount || [])}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis dataKey="label" />
                          <YAxis type="number" tickFormatter={(value) => Intl.NumberFormat('en-US', { notation: 'compact' }).format(value)} />
                          <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltipForCoinsUtilization />} />
                          {/* <Legend formatter={(value, name) => [label[value]]} /> */}
                          <Bar dataKey="Jan" fill="#5CB8D3" width={1} display="Jan" />
                          <Bar dataKey="Feb" fill="#5CB8D3" width={1} display="Feb" />
                          <Bar dataKey="Mar" fill="#5CB8D3" width={1} display="Mar" />
                          <Bar dataKey="Apr" fill="#5CB8D3" width={1} display="Apr" />
                          <Bar dataKey="May" fill="#5CB8D3" width={1} display="May" />
                          <Bar dataKey="Jun" fill="#5CB8D3" width={1} display="Jun" />
                          <Bar dataKey="Jul" fill="#5CB8D3" width={1} display="Jul" />
                          <Bar dataKey="Aug" fill="#5CB8D3" width={1} display="Aug" />
                          <Bar dataKey="Sep" fill="#5CB8D3" width={1} display="Sep" />
                          <Bar dataKey="Oct" fill="#5CB8D3" width={1} display="Oct" />
                          <Bar dataKey="Nov" fill="#5CB8D3" width={1} display="Nov" />
                          <Bar dataKey="Dec" fill="#5CB8D3" width={1} display="Dec" />
                        </BarChart>
                      </ResponsiveContainer>
                    )}

                  </div>
                </div>
              </div>
            </div>

            {shipmentTreeMapData ? (
              <div className='card p-3 dashboard-card border-0 borderRadius  pt-5 pb-4 mt-5 
            ' >

                <div class="col-12 cursor mb-3" >
                  <div className='d-flex gap-2 align-items-center' >
                    <div className='w-auto dropdown' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <label class="font-wt-600 font-size-16" >
                        {`HSN`}
                      </label>
                    </div>

                    {data.hsnGraphMode ? (
                      <div className='w-80'></div>
                    ) : (
                      <div className="text-center d-flex flex-row align-items-baseline  w-80">
                        <div className='w-100 position-relative'>
                          <NewInput label={"Search"}
                            type={"text"} name={"searchHsnTable"} value={data.searchHsnTable} onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}

                    <div className='d-flex row align-items-end'>
                      <div className='px-3 col-6'>
                        <img
                          onClick={() => { setData({ ...data, hsnGraphMode: !data.hsnGraphMode }) }}
                          className='cursor'
                          src={`/assets/images/${data.hsnGraphMode ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                      </div>
                      <div className='col-6'>
                        <img
                          onClick={downloadHsnData}
                          className='cursor' src='/assets/images/download_icon_with_bg.png' />
                      </div>
                    </div>

                  </div>
                </div>

                <div
                >
                  {data.hsnGraphMode ? (
                    <div className={`col-12 d-flex flex-row justify-content-center `}>
                      <TreeMap
                        id="myTreeMap"
                        width={1000}
                        height={500}
                        data={shipmentTreeMapData}
                        valueUnit={""}
                        className={`treemapContainer ${isPurchaseSuccesful ? '' : ' blurBackground '}`}
                      />
                    </div>
                  ) :
                    <div
                      className={`${isPurchaseSuccesful ? '' : ' blurBackground '}`}
                    >
                      <NewTable
                        disableAction={true}
                        data={hsnShipmentList?.data || []}
                        columns={[{ name: "HSN Code" },
                        { name: "Shipment Value" }, { name: isTTVSellerUser ? "Buyer" : "Supplier" }, { name: "Shipment" },
                        { name: "Product Description" }, { name: isTTVSellerUser ? "Top Export Countries" : "Top Import Countries" }]}
                      />
                      <Pagination perPage={data.resultPerPage || 10} page={currentPage2} totalCount={hsnShipmentList.totalCount} onPageChange={(p) => setCurrentPage2(p)} />
                    </div>
                  }
                </div>

              </div>

            ) : null}

            {horizontalTreeData ? (
              <div
                className={`mt-5 pt-2 `}
              >
                <label class="font-wt-600 font-size-16" >
                  {isTTVSellerUser ? `TOP 05 Buyers & their top 3 Suppliers` : `TOP 05 Supplier & their top 3 Buyers`}
                </label>
                <div id="treeWrapper" className={`${isPurchaseSuccesful ? '' : 'blurBackground'}`} style={{ width: '100%', height: '50rem' }}>
                  <Tree data={horizontalTreeData}
                    zoomable={false}
                    textLayout={{ textAnchor: "middle", x: 10, y: -20, transform: undefined }}
                    zoom={0.7}
                    scaleExtent={{ min: 0.1, max: 50 }}
                    nodeSize={{ x: 500, y: 50 }}
                    translate={{ x: 90, y: 300 }}
                  />
                </div>
              </div>
            ) : null}

          </main>

        </div>
      </div>
    </>
  )
}

export default TTVBuyerDetail