
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges, handleFileCommonFunction, getWCTotalCharges } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { facilityNames1, facilityNames2, ownerReview, reviewBusiness, wcyesnoqsts } from "../workingCapital/WCApplyLimit";
import { InputForTable, InputWithSelect, SelectForTable } from "../../utils/newInput";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import { FinancialMonths, companyDetailsReviewForm, yesnoqsts } from "./ApplyLimitCGTMSE";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

const tabLabels = [
  { name: "Quote" },
  { name: "Application Form" },
  { name: "Documents" }
]

const CGTMSESignTermSheet = ({ userTokenDetails, navToggleState }) => {

  let cgtmseQuoteDetails = JSON.parse(localStorage.getItem("cgtmseQuoteDetails"))

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(2)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({ invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30 })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])
  const [additionalReqDetails, setAdditionalReqDetails] = useState([])
  const [overlay, setOverlay] = useState(false);
  const [enableStennQuote, toggleStennQuote] = useState(false)
  const [seeRateCalculator, toggleSeeRateCalculator] = useState(false)
  const [financiers, setFinanciers] = useState([])
  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [availableTermSheets, setAvailableTermSheets] = useState([])
  const [tableDocsData, setTableDocsData] = useState([])
  const [multInvPo, setMultInvPo] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [owners, setOwners] = useState([])
  const [exFacilties, setExFacilities] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [currencyData, setCurrencyData] = useState(most_used_currencies)


  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  async function loadData() {
    setshowLoader(true)
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: cgtmseQuoteDetails.applicationId, fetchBGDocs: true })
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    let termSheetDocument = await getDocDetails(cgtmseQuoteDetails.termSheet)
    setData({
      ...data, ...cgtmseQuoteDetails.details, ...tempLimitDocsDetails, selectedLenderName: cgtmseQuoteDetails.selectedLenderName,
      financierQuotes: JSON.parse(cgtmseQuoteDetails.financierQuotes), termSheetDocument
    })
    call('POST', 'getRecommendedFinanciers', { forBG: true }).then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    // Set lists
    let tempOwners = []
    let tempExFac = []
    let tempSuppliers = []
    let tempBuyers = []
    let allKeyes = Object.keys(cgtmseQuoteDetails.details) || []
    for (let index = 0; index < allKeyes.length; index++) {
      const element = allKeyes[index];
      if (element.includes(`ownerNameTitle`)) {
        tempOwners.push(null)
      }
      if (element.includes(`lenderName`)) {
        tempExFac.push(null)
      }
      if (element.includes(`supplierName`)) {
        tempSuppliers.push(null)
      }
      if (element.includes(`buyerName`)) {
        tempBuyers.push(null)
      }
    }
    setOwners(tempOwners)
    setExFacilities(tempExFac)
    setshowLoader(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  async function handleValidation() {
    let err = {}
    if (tab === 0) {
      setTab(1)
    }
    else if (tab === 1) {
      setTab(2)
    }
    if (tab === 2) {
      if (!(data.termSheetDocument && data.termSheetDocument.signatureId)) {
        err["termSheetDocument"] = "Signing term sheet is mandatory"
      }
      if (!Object.keys(err).length) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("applicationId", cgtmseQuoteDetails.applicationId)
        formData.append("exporterSignedContract", true)
        formData.append("selectedLenderId", cgtmseQuoteDetails.selectedFinancier)
        formData.append("userName", userName)
        formData.append("termSheetDocId", cgtmseQuoteDetails.termSheet)
        if (data.termSheetDocument?.name) {
          formData.append("termSheetDocument", data.termSheetDocument)
        }
        if (data.termSheetDocument?.signatureId) {
          formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)
        }
        call('POST', 'signCGTMSETermSheet', formData).then((result) => {
          // console.log('running signWCTermSheet api-->', result);
          toggleApplicationSubmitPopup({ show: true })
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in signWCTermSheet', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false)
        });
      }
    }
    setErrors(err)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('cgtmseApplicationId', cgtmseQuoteDetails.applicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'cgtmseQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >Termsheet signed successfully</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="otherFinCGTMSESignTermsheet" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > CGTMSE > Sign Termsheet"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => { history.go(-1) }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (index < tab) {
                            setTab(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}

              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabLabels} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => {
                      if (index < tab) {
                        setTab(index)
                      }
                    }}
                  />
                </div>

                {tab === 0 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mb-3'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {companyDetailsReviewForm.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    {owners.map((i, j) => {
                      return (
                        <div className='w-100 mb-3'>
                          <label className='font-wt-500 font-size-16'><u>{`Owner ${j + 1} Details`}</u></label>
                          <div className='row'>
                            {ownerReview.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  {item.name ?
                                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                    : null} </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    })}

                    <div className="mb-3">
                      <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } }, { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                        {Object.keys(yesnoqsts).map((i, j) => {
                          console.log('dataaaaaaaaaaaa', data, data[i]);
                          return (
                            <tr>
                              <td>{yesnoqsts[i]["label"]}</td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setData({ ...data, [i]: data[i] === true ? undefined : true })
                                  // }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === "true" ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setData({ ...data, [i]: data[i] === false ? undefined : false })
                                  // }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === "false" ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="mt-4">
                      <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>

                      <NewTablev2 columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]} showSideBorders={true}>
                        {FinancialMonths.map((i, j) => {
                          return (
                            <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_2ndLastValue`] || '-'}
                              </td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_LastValue`] || '-'}
                              </td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_CurrenntValue`] || '-'}
                              </td>

                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="d-flex align-items-center my-2" >
                      <label className='font-wt-500 font-size-16 w-40'><u>Buyer Details</u></label>
                      <div className="d-flex w-60 justify-content-end" >
                        <div className="mr-4" >
                          <img
                            className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                          <label className="font-size-14 font-wt-600" >Approve Buyer</label>
                        </div>
                        <div>
                          <img
                            className="cursor mr-3"
                            src="assets/images/reject_new_btn.svg" />
                          <label className="font-size-14 font-wt-600">Reject Buyer</label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '10%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '10%' } }, { name: " ", width: '11%' }]} >
                        {top10Buyers.map((i, j) => {
                          if (data[`buyerName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <div className="d-flex">
                                    {data[`buyerAction${j}`] === "Denied" ? null : <img
                                      onClick={() => {
                                        setData({ ...data, [`buyerAction${j}`]: data[`buyerAction${j}`] ? undefined : "Approved" })
                                      }}
                                      className="cursor mr-3" src="assets/images/approve_new_btn.svg" />}
                                    {data[`buyerAction${j}`] === "Approved" ? null : <img
                                      onClick={() => {
                                        setData({ ...data, [`buyerAction${j}`]: data[`buyerAction${j}`] ? undefined : "Denied" })
                                      }}
                                      className="cursor"
                                      src="assets/images/reject_new_btn.svg" />}
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>

                    <label className="font-size-15 font-wt-500 mb-1" ><u>Supplier Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Suppliers.map((i, j) => {
                          if (data[`supplierName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 1 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mb-3'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {reviewBusiness.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    {owners.map((i, j) => {
                      return (
                        <div className='w-100 mb-3'>
                          <label className='font-wt-500 font-size-16'><u>{`Owner ${j + 1} Details`}</u></label>
                          <div className='row'>
                            {ownerReview.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  {item.name ?
                                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                    : null} </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    })}

                    <div className="mb-3">
                      <NewTablev2 columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } }, { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                        {Object.keys(wcyesnoqsts).map((i, j) => {
                          return (
                            <tr>
                              <td>{wcyesnoqsts[i]["label"]}</td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setData({ ...data, [i]: data[i] === true ? undefined : true })
                                  // }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === true ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                              <td>
                                <img
                                  // onClick={() => {
                                  //   setData({ ...data, [i]: data[i] === false ? undefined : false })
                                  // }}
                                  className='cursor mr-3' src={`assets/images/${data[i] === false ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="mb-3" >
                      <label className="font-size-15 font-wt-500" ><u>Existing Facility Details</u></label>
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Lender", subColumnStyle: { width: '15%' } },
                        { subColumns: "Facility", subColumnStyle: { width: '15%' } },
                        { subColumns: "Limits", subColumnStyle: { width: '15%' } },
                        { subColumns: "Outstanding (Lacs)", subColumnStyle: { width: '15%' } },
                        { subColumns: "ROI/Commission (%)", subColumnStyle: { width: '15%' } },
                        { subColumns: "Tendor (months)", subColumnStyle: { width: '15%' } }
                        ]}
                      >
                        {exFacilties.map((i, j) => {
                          return (
                            <tr>
                              <td><InputForTable isDisabled={true} name={`lenderName${j}`} value={data[`lenderName${j}`]} onChange={handleChange} /></td>
                              <td><SelectForTable isDisabled={true} label={""}
                                selectData={[{ name: 'CC (Cash Credit)' }, { name: 'OD (Overdraft)' }, { name: 'LC' }, { name: 'BG' }, { name: 'TL' }]}
                                name={`facility${j}`} value={data[`facility${j}`]} optionLabel={"name"} optionValue={'name'}
                                onChange={handleChange} /> </td>
                              <td><InputWithSelect isDisabled={true} isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`faciltiyLimitCurrency${j}`} selectValue={data[`faciltiyLimitCurrency${j}`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`faciltiyLimit${j}`} value={data[`faciltiyLimit${j}`]} onChange={handleChange} /></td>
                              <td><InputWithSelect isDisabled={true} isAstrix={false} type={"number"} label={""}
                                selectData={currencyData}
                                selectName={`faciltiyOutstandingCurrency${j}`} selectValue={data[`faciltiyOutstandingCurrency${j}`]}
                                optionLabel={"code"} optionValue={'code'}
                                name={`faciltiyOutstanding${j}`} value={data[`faciltiyOutstanding${j}`]} onChange={handleChange} /></td>
                              <td>
                                <InputForTable isDisabled={true} type={"number"} name={`facilityROI${j}`} value={data[`facilityROI${j}`]} onChange={handleChange} />
                              </td>
                              <td>
                                <InputForTable isDisabled={true} type={"number"} name={`facilityTenor${j}`} value={data[`facilityTenor${j}`]} onChange={handleChange} /></td>

                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true}
                        columns={[{ subColumns: "Security Type", subColumnStyle: { width: '20%' } },
                        { subColumns: "Brief Property Address", subColumnStyle: { width: '40%' } },
                        { subColumns: "Upload Document", subColumnStyle: { width: '40%' } }]}
                      >
                        <tr>
                          <td>
                            <SelectForTable isDisabled label={""}
                              selectData={[{ name: 'Residential' }, { name: 'Commercial' }, { name: 'Industrial' }, { name: 'Land/Others' },
                              { name: 'FD/Liquid Security' }]}
                              name={`securityType`} value={data[`securityType`]} optionLabel={"name"} optionValue={'name'}
                              onChange={handleChange} />
                          </td>
                          <td>
                            <InputForTable isDisabled name={"briefPropertyAddress"} value={data.briefPropertyAddress} onChange={handleChange} />
                          </td>
                          <td>
                            <FileInput isEditable={false} value={data.securityDocument} name={"securityDocument"}
                              onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)} />
                          </td>
                        </tr>
                      </NewTablev2>
                    </div>

                    <div className="mb-3 d-flex row" >
                      <label className="font-size-15 font-wt-500" ><u>Requested Facility Details</u></label>
                      <div className="w-50" >
                        <NewTablev2 showSideBorders={true}
                          columns={[{ subColumns: "Facility", subColumnStyle: { width: '50%' } },
                          { subColumns: "Limits", subColumnStyle: { width: '50%' } }]}
                        >
                          {facilityNames1.map((i, j) => {
                            return (
                              <tr>
                                <td><label className="font-size-14 font-wt-500" >{i.name}</label></td>
                                <td>
                                  <InputWithSelect isDisabled isAstrix={false} type={"number"} label={""}
                                    selectData={currencyData}
                                    selectName={`facilityCurrency`} selectValue={data[`facilityCurrency`]}
                                    optionLabel={"code"} optionValue={'code'}
                                    name={`${i.name}:facilityLimit`} value={data[`${i.name}:facilityLimit`]}
                                    onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          })}
                        </NewTablev2>
                      </div>
                      <div className="w-50" >
                        <NewTablev2 showSideBorders={true}
                          columns={[{ subColumns: "Facility", subColumnStyle: { width: '50%' } },
                          { subColumns: "Limits", subColumnStyle: { width: '50%' } }]}
                        >
                          {facilityNames2.map((i, j) => {
                            return (
                              <tr>
                                <td><label className="font-size-14 font-wt-500" >{i.name}</label></td>
                                <td>
                                  <InputWithSelect isDisabled isAstrix={false} type={"number"} label={""}
                                    selectData={currencyData}
                                    selectName={`facilityCurrency`} selectValue={data[`facilityCurrency`]}
                                    optionLabel={"code"} optionValue={'code'}
                                    name={`${i.name}:facilityLimit`} value={data[`${i.name}:facilityLimit`]}
                                    onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          })}</NewTablev2>
                      </div>
                    </div>


                    <label className="font-size-15 font-wt-500 mb-1" ><u>Buyer Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Buyers.map((i, j) => {
                          if (data[`buyerName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>

                    <label className="font-size-15 font-wt-500 mb-1" ><u>Supplier Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Suppliers.map((i, j) => {
                          if (data[`supplierName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {tab === 2 ? (
                  <div className="d-flex row px-5 pt-4 pb-4">
                    <div className='w-100 mt-4'>
                      <label className="font-size-14 font-wt-500" ><u>Other Documents</u></label>
                      <NewTablev2 columns={[
                        {
                          subColumns: "Document Name", subColumnStyle: { width: '30%' }
                        },
                        {
                          subColumns: "Attach Document", subColumnStyle: { width: '40%' }
                        },
                        {
                          subColumns: "Sign", subColumnStyle: { width: '15%' }
                        },
                        {
                          subColumns: "Action", subColumnStyle: { width: '15%' }
                        }
                      ]}>
                        <tr>
                          <td>Term Sheet
                            {cgtmseQuoteDetails.termSheetSignType === "Wet" ? (
                              <div class="text-danger mb-4 font-size-10">
                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                <b>{"Upload Signed & Stamped Termsheet"}</b>
                              </div>
                            ) : null}
                          </td>
                          <td>
                            <FileInput
                              changeEditIconToUpload={true}
                              name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                              onChange={handleFile} isEditable={cgtmseQuoteDetails.termSheetSignType === "Wet"}
                              onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                            />
                            {errors.termSheetDocument ? <div class="text-danger mt-2">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors.termSheetDocument}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`termSheetDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`termSheetDocument`]?.name); setData({ ...data, docToSign: data[`termSheetDocument`], docSignName: `termSheetDocument` }) }}
                                src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`termSheetDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div>
                          </td>
                        </tr>
                        {data.bgTextDocument?.name ?
                          <tr>
                            <td>BG Text</td>
                            <td><FileInput isEditable={false} value={data.bgTextDocument} /></td>
                            <td>
                              {!data[`bgTextDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`bgTextDocument`]?.name); setData({ ...data, docToSign: data[`bgTextDocument`], docSignName: `bgTextDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`bgTextDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`bgTextDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.contractCopyDocument?.name ?
                          <tr>
                            <td>Contract Copy</td>
                            <td><FileInput isEditable={false} value={data.contractCopyDocument} /></td>
                            <td>
                              {!data[`contractCopyDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`contractCopyDocument`]?.name); setData({ ...data, docToSign: data[`contractCopyDocument`], docSignName: `contractCopyDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`contractCopyDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractCopyDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.declarationDocument?.name ?
                          <tr>
                            <td>Declaration</td>
                            <td><FileInput isEditable={false} value={data.declarationDocument} /></td>
                            <td>
                              {!data[`declarationDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`declarationDocument`]?.name); setData({ ...data, docToSign: data[`declarationDocument`], docSignName: `declarationDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`declarationDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`declarationDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                        {data.boardResolutionDocument?.name ?
                          <tr>
                            <td>Board Resolution</td>
                            <td><FileInput isEditable={false} value={data.boardResolutionDocument} /></td>
                            <td>
                              {!data[`boardResolutionDocument`]?.signatureId ?
                                <img className="cursor"
                                  onClick={() => { setSigndoc(data[`boardResolutionDocument`]?.name); setData({ ...data, docToSign: data[`boardResolutionDocument`], docSignName: `boardResolutionDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                :
                                <img style={{ width: '20px' }} className=""
                                  src={"assets/images/doc_sign_filled.svg"} alt="" />
                              }
                            </td>
                            <td><div
                              className="d-flex"
                            >
                              {data[`boardResolutionDocument`]?.name ? (
                                <>
                                  <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                  <img onClick={() => { toggleViewDoc({ show: true, doc: data[`boardResolutionDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                </>
                              ) : null}
                            </div></td>
                          </tr> : null}
                      </NewTablev2>
                    </div>
                    <div>
                      <ExtraDetailsForLimitApplication isDisabled userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton={false} mainTagMarginPadding0={true} />
                    </div>
                  </div>
                ) : null}

                {tab === 0 ? null :
                  <div className="pb-5 px-5">
                    <button type="button"
                      onClick={() => handleValidation()}
                      className={`new-btn w-20 py-2 px-2 text-white cursor`}>
                      {tab === 2 ? "Submit" : "Continue"}
                    </button>
                  </div>}

              </div>

            </div>

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(CGTMSESignTermSheet)