import React from "react";
import { useEffect } from "react";
import { getUserDataFromCookie } from "../utils/cookieHelper";

export const HandleRedirect = () => {

  const queryParams = new URLSearchParams(window.location.search)
  const redirectTo = queryParams.get("to")

  useEffect(() => {

    //User details from cookie
    let userDetails = getUserDataFromCookie()
    let userToken = userDetails.token ? userDetails.token : null

    if (userToken !== undefined && userToken !== null) {
      window.location = redirectTo
    } else {
      localStorage.setItem("redirectTo", redirectTo)
      window.location = '/login'
    }
  }, [])



  return (
    <></>
  )
}