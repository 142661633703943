import React from "react";
import { useState } from "react";
import { FileInput } from "../../../utils/FileInput";
import toastDisplay from "../../../utils/toastNotification";
import { convertImageToPdf } from "../../../utils/myFunctions";

const History = ({ setHistory, history }) => {

  // const amendmentId = queryParams.get("amendmentId")
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [addMoreDoc, setAddMoreDoc] = useState([null])


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png & jpeg extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (!file_type.includes("pdf")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          // console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }


  return (
    <>
      <div class={`offcanvas offcanvas-end ${history && "show"}`} tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ visibility: 'visible' }}>
        <div class="offcanvas-header">
          <span data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setHistory(false)}><img className='cursor' src={"assets/images/ArrowBackLeft.png"} /> </span>
        </div>
        <div class="offcanvas-body">
          <div class="wrapper">
            <ul class="StepProgress">
              <li class="StepProgress-item is-done">
                <div class="accordion" id="accordionHistoryOne">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="HeadingHistoryOne">
                      <span class="accordion-button bg-transparent px-0 shadow-none text-custom1 pb-1" data-bs-toggle="collapse" data-bs-target="#CollpaseHistoryOne" aria-expanded="true" aria-controls="CollpaseHistoryOne">
                        <div className="col-md-10 px-0 historyacc">
                          <p className="historyacc-heading mb-0">Disbursement - $ 10,000</p>
                          <span className="font-size-14">Thrusday, 13th october, 2022 10:30 AM</span>
                        </div>
                      </span>
                    </h2>
                    <div id="CollpaseHistoryOne" class="accordion-collapse collapse show" aria-labelledby="HeadingHistoryOne" data-bs-parent="#accordionHistoryOne">
                      <div class="accordion-body p-0">
                        <div className="my-2">
                          <p className="mb-0 color434343 font-size-14 letter-spacing04 ">Payment mode : NEFT</p>
                          <p className="mb-0 color434343 font-size-14 letter-spacing04">Transaction ID : 584589658745896</p>
                        </div>

                        <a href="" className="color5CB8D3 font-size-12 letter-spacing04 textunderline">View transaction details <img className='' src={"assets/images/schedule-arrow.png"} /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="StepProgress-item is-done">
                <div class="accordion" id="accordionHistoryTwo">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="HeadingHistoryTwo">
                      <span class="accordion-button bg-transparent px-0 shadow-none text-custom1 pb-1 collapsed" data-bs-toggle="collapse" data-bs-target="#CollpaseHistoryTwo" aria-expanded="true" aria-controls="CollpaseHistoryTwo">
                        <div className="col-md-10 px-0 historyacc">
                          <p className="historyacc-heading mb-0">Email to financer</p>
                          <span className="font-size-14"> Thrusday, 13th october, 2022 10:30 AM</span>
                        </div>

                      </span>
                    </h2>
                    <div id="CollpaseHistoryTwo" class="accordion-collapse collapse" aria-labelledby="HeadingHistoryTwo" data-bs-parent="#accordionHistoryTwo">
                      <div class="accordion-body p-0">
                        <p className="color434343 font-size-14 letter-spacing05">Below are the details of overdue payment related to 1 invoice with due date 13/10/2022.
                          Request you to share updates on this matter.</p>
                        <div className="col-md-10 px-0">
                          <div className="d-flex"> <p className="col-6 px-0 BuyerdetailsLabel mb-0">Transaction ID</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc mb-0" >485975864258965 </p></div>
                          <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Invoice no</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >1458965235 </p></div>
                          <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Supplier</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >XYZ Pvt.Ltd. </p></div>
                          <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Buyer name</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >Rosti integrated</p></div>
                          <div className="d-flex"> <p className="col-6 px-0 BuyerdetailsLabel  mb-0" >Outstanding amount</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >$ 10,000</p></div>
                          <div className="d-flex"><p className="col-6 px-0 BuyerdetailsLabel  mb-0">Due date</p><span className="mx-3">:</span><p className="col-6 BuyerdetailsDesc  mb-0" >13/10/2022</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="StepProgress-item is-done">
                <div class="accordion" id="accordionHistoryThree">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="HeadingHistoryThree">
                      <span class="accordion-button bg-transparent px-0 shadow-none text-custom1 pb-1 collapsed" data-bs-toggle="collapse" data-bs-target="#CollpaseHistoryThree" aria-expanded="true" aria-controls="CollpaseHistoryThree">
                        <div className="col-md-10 px-0 historyacc">
                          <p className="historyacc-heading mb-0">Disbursement - $ 90,000</p>
                          <span className="font-size-14">
                            Thrusday, 13th october, 2022 10:30 AM</span>
                        </div>
                      </span>
                    </h2>
                    <div id="CollpaseHistoryThree" class="accordion-collapse collapse" aria-labelledby="HeadingHistoryThree" data-bs-parent="#accordionHistoryThree">
                      <div class="accordion-body p-0">
                        <div className="my-2">
                          <p className="mb-0 color434343 font-size-14 letter-spacing04 ">Payment mode : NEFT</p>
                          <p className="mb-0 color434343 font-size-14 letter-spacing04">Transaction ID : 584589658745896</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="StepProgress-item is-done">
                <div class="accordion" id="accordionHistoryFour">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="HeadingHistoryFour">
                      <span class="accordion-button bg-transparent px-0 shadow-none text-custom1 pb-1 collapsed" data-bs-toggle="collapse" data-bs-target="#CollpaseHistoryFour" aria-expanded="true" aria-controls="CollpaseHistoryFour">
                        <div className="col-md-10 px-0 historyacc">
                          <p className="historyacc-heading mb-0">   Amendment</p>
                          <span className="font-size-14">

                            Thrusday, 13th october, 2022
                            10:30 AM</span>
                        </div>
                      </span>
                    </h2>
                    <div id="CollpaseHistoryFour" class="accordion-collapse collapse" aria-labelledby="HeadingHistoryFour" data-bs-parent="#accordionHistoryFour">
                      <div class="accordion-body p-0">
                        <label className="font-size-14 font-wt-400 color434343 letter-spacing04">Invoice <img className='ms-2' src={"assets/images/info-circle-outlined_remark.png"} /></label>

                        <div className="col-md-12 px-0">
                          <label className="font-size-14">Amended</label>
                          <div className="row form-group">
                            <div className="col-md-12">
                              <FileInput name={"draftLCDocument"} value={data.draftLCDocument}
                                isEditable={false}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 px-0">
                          <label className="font-size-14">Original</label>
                          <div className="row form-group">
                            <div className="col-md-12">
                              <FileInput name={"draftLCDocument"} value={data.draftLCDocument}
                                isEditable={false}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="historyviewall mb-0">View all <img className='ms-2' src={"assets/images/schedule-arrow.png"} /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="StepProgress-item is-done">
                <div class="accordion" id="accordionHistoryFour">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="HeadingHistoryFour">
                      <span class="accordion-button bg-transparent px-0 shadow-none text-custom1 pb-1 collapsed" data-bs-toggle="collapse" data-bs-target="#CollpaseHistoryFour" aria-expanded="true" aria-controls="CollpaseHistoryFour">
                        <div className="col-md-10 px-0 historyacc">
                          <p className="historyacc-heading mb-0"> Whatsapp message to financer</p>
                          <span className="font-size-14">
                            Thrusday, 13th october, 2022
                            10:30 AM</span>
                        </div>
                      </span>
                    </h2>
                    <div id="CollpaseHistoryFour" class="accordion-collapse collapse" aria-labelledby="HeadingHistoryFour" data-bs-parent="#accordionHistoryFour">
                      <div class="accordion-body p-0">
                        <div className="my-2">
                          <p className="mb-0 color434343 font-size-14 letter-spacing04 ">Payment mode : NEFT</p>
                          <p className="mb-0 color434343 font-size-14 letter-spacing04">Transaction ID : 584589658745896</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="StepProgress-item is-done">
                <div class="accordion" id="accordionHistoryFive">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="HeadingHistoryFive">
                      <span class="accordion-button bg-transparent px-0 shadow-none text-custom1 pb-1 collapsed" data-bs-toggle="collapse" data-bs-target="#CollpaseHistoryFive" aria-expanded="true" aria-controls="CollpaseHistoryFive">
                        <div className="col-md-10 px-0 historyacc">
                          <p className="historyacc-heading mb-0"> Disbursement scheduled by financer</p>
                          <span className="font-size-14">
                            Thrusday, 13th september, 2022
                            10:30 AM</span>
                        </div>
                      </span>
                    </h2>
                    <div id="CollpaseHistoryFive" class="accordion-collapse collapse" aria-labelledby="HeadingHistoryFive" data-bs-parent="#accordionHistoryFive">
                      <div class="accordion-body p-0">
                        <div className="my-2">
                          <p className="mb-0 color434343 font-size-14 letter-spacing04 ">Payment mode : NEFT</p>
                          <p className="mb-0 color434343 font-size-14 letter-spacing04">Transaction ID : 584589658745896</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li class="StepProgress-item is-done"><strong>Award an entry</strong>
                Got more entries that you love?Buy more entries anytime!Just hover on your favorite entry and  Got more entries that you love?Buy more entries anytime!Just hover on your favorite entry and click the Buy button
                Got more entries that you love? Buy more entries anytime! Just hover on your favorite entry and click the Buy button Got more entries that you love? Buy more entries anytime! Just hover on your favorite entry and click the Buy buttonvclick the Buy button
              </li>
              <li class="StepProgress-item current"><strong>Post a contest</strong></li>
              <li class="StepProgress-item"><strong>Handover</strong></li>
              <li class="StepProgress-item"><strong>Provide feedback</strong></li> */}
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    </>
  );
}
export default History;