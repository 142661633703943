import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import useForm from '../../utils/useForm';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/editProfileValidationRules';
import { setNewAvatar } from '../../utils/cookieHelper';
import { setaddBankObj } from '../../store/actions/action';
import AddBankComponent from '../utilComponents/addBankComponent'
import { formatDate_Application, } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile'
import TrulioKYCForm from './trulioKycForm'
import avatarUrl from '../../utils/makeAvatarUrl';
import ChangePassword from './updatePassword'
import BussinessDetailsComp from './bussinessDetails'
import KYCDocumentsComp from './kycDocuments';
import FinanceDetailsComp from './financeDetails'
import Footer from '../partial/footer';
import { kycDocs } from "../registration/objectsForReg.js"
import toolTipText from '../../utils/toolTips/onboardingProfileForm.json'
import ShareHolderDetails from './shareholderDetails'
import BillingDetails from './billingDetails'
import PlatfornDetails from './platformDetails'
import CreditlineDocuments from './creditlineDocuments';
import AdminProfile from './adminProfile';
import OtherDocuments from './otherDocuments';

const EditProfile = ({ userTokenDetails, userEmail_id, isAdmin, setaddBankObj, addBankObj, clientType, navToggleState, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [countryData, setcountryData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [userProfileDataG, setuserProfileDataG] = useState({});
  const [doKYC, setdoKYC] = useState(false);
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})
  const [refresh, setrefresh] = useState()
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [tab, setTab] = useState(0);
  const [useBankData, setuseBankData] = useState([]);
  const [kycData, setkycData] = useState([]);
  const [stennKYC, setStennKYC] = useState({});
  const [KYCStatus, setKYCStatus] = useState({ modal: false, data: [] });
  const [editBankDetails, seteditBankDetails] = useState(null)


  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null
  const aclMap = userTokenDetails && userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  const astrix = <span className="required-field text-danger">*</span>

  // const kycStatus = [{ "key": "Trade Reboot" }, { "key": "STENN" }];

  //------------------------------------------------------------------
  //use Form func/comp imports
  const {
    values,
    errors,
    handleChange,
    handleAvatar,
    handleSubmit,
    handleFile,
    SETvaluesData,
  } = useForm(form_sub, scrolltoError, validate);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //User details from cookie
    setaddBankObj(true, { "email": userEmail_id ? userEmail_id : userEmail })
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getuserprofiledata', { 'email': userEmail_id ? userEmail_id : userEmail, "kyc": true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      let userProfileData = result.userProfileData
      setuserProfileDataG(userProfileData)
      setkycData(result.userKYCData)
      setshowLoader(false)
      if (result.userProfileData) {
        SETvaluesData({
          userTypeId: userProfileData.type_id,
          userId: userProfileData.tbl_user_id,
          email: userProfileData.login_id,
          contactPerson: userProfileData.contact_person,
          designation: userProfileData.designation,
          contactNo: userProfileData.contact_number,
          address: userProfileData.user_address,
          companyCity: userProfileData.company_city,
          companyPostalCode: userProfileData.company_postal_code,
          country: userProfileData.country_code,
          userAvatar: userProfileData.user_avatar ? userProfileData.user_avatar : null,
          kyc_done: (userProfileData.kyc_done / 1),
          panNo: userProfileData.pan_no,
          gstNo: userProfileData.gst_vat_no,
          iecNo: userProfileData.iec_no,
          cinNo: userProfileData.cin_no,
          company_pan_verification: userProfileData.company_pan_verification,
          company_cin_verification: userProfileData.company_cin_verification,
          company_gst_verification: userProfileData.company_gst_verification,
          company_iec_verification: userProfileData.company_iec_verification,
          refercode: userProfileData.refercode,
          organization_type: userProfileData.organization_type,
          CPrefercode: userProfileData.CPrefercode,
          CPdisable: (userProfileData.CPrefercode ? true : false)
        })
      }
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getuserprofiledata', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    if (!isAdmin) {
      call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
        console.log("userProfileData in getUserBanks-->", result)

        setuseBankData(result.message)
      }).catch((e) => {
        console.log("Error while querying getUserBanks:", e);
      })
    }
    //------------------------------------------------------------------

    call('get', 'v1/plan/quota').then((result) => {
      if (result && result.ekyc && result.ekyc.enable)
        setQuotaAvail(true)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota header:-->", e);
    })

  }, [refresh, doKYC === false]);

  useEffect(() => {

    call('POST', 'stennKYC', { "userEmail": userEmail_id }).then((result) => {
      console.log("Result in  stennKYC:-->", result);
      setStennKYC(result);
    }).catch((e) => {
      console.log("Error while  stennKYC:-->", e);
    });

  }, []);


  useEffect(() => {
    if (!isAdmin) {
      call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
        console.log("userProfileData in getUserBanks-->", result)

        setuseBankData(result.message)
      }).catch((e) => {
        console.log("Error while querying getUserBanks:", e);
      })
    }
  }, [addBankObj.info.refresh]);


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function form_sub() {
    sPFormSub(values);
  }

  const sPFormSub = function (values) {
    setshowLoader(true)
    let formData = new FormData();
    delete values.avatarBase64
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    call('POST', 'updateUserDetails', formData).then((result) => {
      setshowLoader(false)
      if (result) {
        if ((userEmail_id === undefined) && result.newAvatar) {
          setNewAvatar(result.newAvatar)
        }
        // setrefresh(refresh + 1)
        toastDisplay(result.msg, "success");
        setTimeout(function () { window.location.reload(); }, 2000);
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  function scrolltoError() {
    return (errors.companyName ? document.getElementById("POScompanyName").scrollIntoView({ behavior: "smooth", block: "center" })
      : errors.contactPerson ? document.getElementById("POScontactPerson").scrollIntoView({ behavior: "smooth", block: "center" })
        : errors.iecNo ? document.getElementById("POSiecNo").scrollIntoView({ behavior: "smooth", block: "center" })
          : errors.email ? document.getElementById("POSemail").scrollIntoView({ behavior: "smooth", block: "center" })
            : errors.contactNo ? document.getElementById("POScontactNo").scrollIntoView({ behavior: "smooth", block: "center" })
              : errors.address ? document.getElementById("POSaddress1").scrollIntoView({ behavior: "smooth", block: "center" })
                : errors.country ? document.getElementById("POScountry").scrollIntoView({ behavior: "smooth", block: "center" })
                  : errors.ownerIdentityProof ? document.getElementById("POSownerIdentityProof").scrollIntoView({ behavior: "smooth", block: "center" })
                    : errors.companyAddressProof ? document.getElementById("POScompanyAddressProof").scrollIntoView({ behavior: "smooth", block: "center" })
                      : "")
  }

  function regenMyReferCode() {
    call('get', 'regenmyrefercode').then((result) => {
      if (result) {
        toastDisplay(result, "success");
        setrefresh(refresh + 1)
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }

  function copyToClipboard(textId) {
    let copyText = document.getElementById(textId);
    let temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = copyText.innerText
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);

    copyText.classList.remove("text-primary");
    copyText.classList.add("text-dark");

    setTimeout(function () {
      copyText.classList.remove("text-dark");
      copyText.classList.add("text-primary");
    }, 2000);

  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
          {(userEmail_id === undefined) && <>
            <Sidebar state={'editProfile'} userTokenDetails={userTokenDetails} />
          </>}

          {/* <main role="main" className={(userEmail_id === undefined) ? ("ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")) : "col-md-12 p-0"}> */}

          <main role="main" className={((userEmail_id === undefined) && navToggleState.status === 1) ? "ml-sm-auto col-lg-10 expanded-right" :
            ((userEmail_id === undefined) && navToggleState.status === 0) ? "ml-sm-auto col-lg-10" : "col-md-12 p-0"}>


            {(userEmail_id === undefined) && <>
              <Header
                title={doKYC ? "KYC Verfication" : 'Edit Profile'}
                total={values.email}
                userTokenDetails={userTokenDetails} />
            </>}

            {/* {(!isAdmin) && */}
            <ul className="nav nav-tabs-custom mt-2" id="myTab" role="tablist">
              <li>
                <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>{doKYC ? "E-KYC (Trulio)" : "Profile Details"}</a>
              </li>
              {/* {(aclMap.credit_line && aclMap.credit_line.mng_bussiness_details && aclMap.credit_line.mng_bussiness_details.isExist) &&} */}
              <li>
                <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>Business Details</a>
              </li>
              <li>
                <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>Shareholder Details</a>
              </li>
              {/* <li>
                  <a className={"nav-link" + (tab === 3 ? " active show" : "")} onClick={() => setTab(3)}>Company KYC Documents</a>
                </li> */}
              <li>
                <a className={"nav-link" + (tab === 4 ? " active show" : "")} onClick={() => setTab(4)}>KYC Documents</a>
              </li>
              {/* {(aclMap.credit_line && aclMap.credit_line.mng_financial_details && aclMap.credit_line.mng_financial_details.isExist) && */}
              <li>
                <a className={"nav-link" + (tab === 5 ? " active show" : "")} onClick={() => setTab(5)}>Financial Details</a>
              </li>
              {/* } */}
              {userTypeId == 8 &&
                <li>
                  <a className={"nav-link" + (tab === 10 ? " active show" : "")} onClick={() => setTab(10)}>Credit Line Documents</a>
                </li>
              }
              <li>
                <a className={"nav-link" + (tab === 6 ? " active show" : "")} onClick={() => setTab(6)}>Bank Details</a>
              </li>
              <li>
                <a className={"nav-link" + (tab === 7 ? " active show" : "")} onClick={() => setTab(7)}>Change Password</a>
              </li>
              <li>
                <a className={"nav-link" + (tab === 8 ? " active show" : "")} onClick={() => setTab(8)}>Billing Details</a>
              </li>
              {/* <li>
                  <a className={"nav-link" + (tab === 9 ? " active show" : "")} onClick={() => setTab(9)}>Platform Details</a>
                </li> */}
              <li>
                <a className={"nav-link" + (tab === 11 ? " active show" : "")} onClick={() => setTab(11)}>Other Documents</a>
              </li>
            </ul>
            {/* } */}

            <div className="d-flex">

              {tab === 0 &&
                <div className={"tab-pane active show col-md-12"}>

                  {(showLoader === false && doKYC === false && !isAdmin) ?
                    <div className="col-md-10 offset-md-1" >
                      <div className="row shadow bg-light pt-5 pb-5">
                        <div className="col-md-3">

                          <div className="profile-sidebar">
                            <div className="profile-userpic">
                              {values.avatarBase64 ? console.log("value") : console.log("api")}
                              <img src={values.avatarBase64 ? values.avatarBase64 : avatarUrl(values.userAvatar)} id="myImg" className="img-responsive" alt="Avatar" />
                              <span className="icon-edit-profile">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                <input type="file" accept=".png,.jpg" id="file_3" dir="2" name="avatar" onChange={handleAvatar} />
                              </span>
                            </div>
                            <div className="profile-userbuttons text-center mt-2 ">
                              <label>Avatar {astrix} </label>
                            </div>
                            {errors.avatar && scrolltoError()}
                            {errors.avatar && <p className="text-danger error-contract">{errors.avatar}</p>}
                          </div>

                          <div className="mt-3 text-center">
                            <h5>KYC STATUS</h5>
                          </div>

                          {/* <div className="mt-3 text-center">
                            {kycStatus.map((item) => {
                              return (<>
                                <h6
                                  onClick={() => {
                                    setKYCStatus({ modal: true, data: item })
                                  }}
                                > {item.key} </h6>
                              </>)
                            })}
                          </div> */}

                          {/* {KYCStatus.modal && KYCStatus.data.key == 'Trade Reboot' && */}
                          <>
                            <div className="mt-3 text-right">
                              <div className="mt-3 text-center">
                                {(values.company_pan_verification == "true") ?
                                  <div className="text-center">
                                    <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> PAN Verified</h6>
                                  </div> :
                                  <div className="text-center">
                                    <h6 className="text-danger cursor-pointer"
                                      // onClick={() => {
                                      //   if (quotaAvail) {
                                      //     setdoKYC(true)
                                      //   } else {
                                      //     toastDisplay("Your Plan does not support Trulio KYC", "warn");
                                      //   }
                                      // }}
                                      onClick={() => { setTab(4) }}
                                    ><i class="fa fa-times-circle" aria-hidden="true"></i> PAN Not-Verified</h6>
                                  </div>
                                }
                              </div>

                              <div className="mt-3 text-center">
                                {(values.company_gst_verification == "true") ?
                                  <div className="text-center">
                                    <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> GST Verified</h6>
                                  </div> :
                                  <div className="text-center">
                                    <h6 className="text-danger cursor-pointer" onClick={() => { setTab(4) }}><i class="fa fa-times-circle" aria-hidden="true"></i> GST Not-Verified</h6>
                                  </div>
                                }
                              </div>

                              <div className="mt-3 text-center">
                                {(values.company_iec_verification == "true") ?
                                  <div className="text-center">
                                    <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> IEC Verified</h6>
                                  </div> :
                                  <div className="text-center">
                                    <h6 className="text-danger cursor-pointer" onClick={() => { setTab(4) }}><i class="fa fa-times-circle" aria-hidden="true"></i> IEC Not-Verified</h6>
                                  </div>
                                }
                              </div>

                              <div className="mt-3 text-center">
                                {(values.company_cin_verification == "true") ?
                                  <div className="text-center">
                                    <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> CIN Verified</h6>
                                  </div> :
                                  <div className="text-center">
                                    <h6 className="text-danger cursor-pointer" onClick={() => setTab(4)}><i class="fa fa-times-circle" aria-hidden="true"></i> CIN Not-Verified</h6>
                                  </div>
                                }
                              </div>

                              {(values.organization_type == "pvtPubLtd") ?
                                '' : ''
                              }
                            </div>

                          </>
                          {/* } */}

                          {/* {KYCStatus.modal && KYCStatus.data.key == 'STENN' &&
                            <div className="mt-3 text-center">
                              {(stennKYC.stennKYC == "true") ?
                                <div className="text-center">
                                  <h6 className="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i> STENN Verified</h6>
                                </div> :
                                <div className="text-center">
                                  <h6 className="text-danger cursor-pointer"><i class="fa fa-times-circle" aria-hidden="true"></i> STENN Not-Verified</h6>
                                </div>
                              }
                            </div>
                          } */}

                          <hr />

                          <div className="mt-3 text-center">
                            <h3 className="text-primary" id="myrefercode">{values.refercode ? values.refercode : "NA"}</h3>
                            <label>
                              My referral Code
                              <span className="cursor-pointer text-success pl-2 pr-2" title="Copy to Clipboard" onClick={() => copyToClipboard("myrefercode")}> <i class="fas fa-clipboard"></i></span>
                              <span className="cursor-pointer text-warning pr-2" title="Regenerate Referral Code" onClick={() => regenMyReferCode()}> <i class="fas fa-redo"></i></span>
                            </label>
                            <br />
                            <span className="help-icon" flow="right" tooltip="My Referral Code, Others can use it to add me">i</span>
                          </div>

                        </div>
                        <div className="col-md-9 mt-2">
                          <div className="pr-4">

                            <div className="form-group" id="POScontactPerson">
                              <label>Contact Person {astrix} </label>
                              <input type="text" className={" form-control" + (!errors.contactPerson ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="contactPerson" value={values.contactPerson} onChange={handleChange} required />
                              {errors.contactPerson && scrolltoError()}
                              {errors.contactPerson && <p className="text-danger error-contract">{errors.contactPerson}</p>}
                            </div>

                            <div className="form-group" id="POSdesignation">
                              <label>Designation</label>
                              <input type="text" className={" form-control" + (!errors.designation ? '' : ' border-danger')} placeholder="Enter Designation" name="designation" value={values.designation} onChange={handleChange} required />
                              {errors.designation && (<p className="text-danger error-contract">{errors.designation}</p>
                              )}
                            </div>


                            <div className="form-group" id="POSemail">
                              <label>Email ID {astrix} </label>
                              <input type="text" className={" form-control" + (!errors.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={values.email} onChange={handleChange} required disabled />
                              {errors.email && scrolltoError()}
                              {errors.email && <p className="text-danger error-contract">{errors.email}</p>}
                            </div>

                            <div className="form-group" id="POScontactNo">
                              <label>Contact No. {astrix} </label>
                              <input type="number" pattern=".{10,13}" className={" form-control" + (!errors.contactNo ? '' : ' border-danger')} placeholder="Enter Contact No" name="contactNo" value={values.contactNo} onChange={handleChange} required />
                              {errors.contactNo && scrolltoError()}
                              {errors.contactNo && <p className="text-danger error-contract">{errors.contactNo}</p>}
                            </div>

                            <div className="form-group" id="POSreferralCode">
                              <label>User Referral Code</label>
                              <input type="text" className={" form-control" + (!errors.CPrefercode ? '' : ' border-danger')} placeholder="Enter Contact No" name="CPrefercode" value={values.CPrefercode} onChange={handleChange} required disabled={values.CPdisable} />
                              {errors.CPrefercode && <p className="text-danger error-contract">{errors.CPrefercode}</p>}
                            </div>

                            <div className="form-group" id="POSaddress1">
                              <label>Address {astrix} </label>
                              <textarea className={" form-control" + (!errors.address ? '' : ' border-danger')} placeholder="Enter Flat / House / Office No" name="address" onChange={handleChange} value={values.address} required />
                              {errors.address && scrolltoError()}
                              {errors.address && <p className="text-danger error-contract">{errors.address}</p>}
                            </div>

                            <div className="form-group" id="POSaddress1">
                              <label>City {astrix} </label>
                              <input className={" form-control" + (!errors.companyCity ? '' : ' border-danger')} placeholder="Enter City" name="companyCity" onChange={handleChange} value={values.companyCity} required />
                              {/* {errors.companyCity && scrolltoError()} */}
                              {errors.companyCity && <p className="text-danger error-contract">{errors.companyCity}</p>}
                            </div>

                            <div className="form-group" id="POSaddress1">
                              <label>Postal Code {astrix} </label>
                              <input className={" form-control" + (!errors.companyPostalCode ? '' : ' border-danger')} placeholder="Enter Postal Code" name="companyPostalCode" onChange={handleChange} value={values.companyPostalCode} required />
                              {/* {errors.address && scrolltoError()} */}
                              {errors.companyPostalCode && <p className="text-danger error-contract">{errors.companyPostalCode}</p>}
                            </div>

                            <div className="form-group flag-relative" id="POScountry">
                              <div className="flag-icon">
                                {values.country ? <span className="shadow">
                                  <ReactCountryFlag
                                    countryCode={values.country}
                                    style={{ width: '25px', height: '25px' }} svg />
                                </span> :
                                  <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image" />}
                              </div>
                              <label>Country {astrix}  </label>
                              <select className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={values.country} onChange={handleChange} >
                                <option value="" selected>Select Country</option>
                                {countryData.map((country) => {
                                  return (
                                    <option value={country.sortname} >{country.name}</option>
                                  )
                                })}
                              </select>
                              {errors.country && scrolltoError()}
                              {errors.country && <p className="text-danger error-contract">{errors.country}</p>}
                            </div>

                            {(!values.kyc_done) && <>
                              <hr />
                              {([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 18, 19].includes(values.userTypeId)) &&
                                <>
                                  <h3 className="mb-4" id="KYCDoc" >KYC Documents<>{(values.userTypeId !== 8) && <span className="required-field text-danger">*</span>}</></h3>
                                  <div id="newDoc">

                                    {kycDocs.map((item) => {
                                      return (<>
                                        {item.userType.includes(values.userTypeId) && <>

                                          <div className="form-group pb-0 mb-0" id={item.key}>
                                            <ul className="other-documents pb-0 mb-0">
                                              <li style={{ "min-width": "250px" }}>
                                                <label className="mb-0" >
                                                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                                  {item.name}
                                                  <span className="required-field text-danger">*</span>

                                                </label>
                                              </li>
                                              <li>{toolTip(item.key)}</li>
                                              <li style={{ "min-width": "250px" }}>
                                                <input type="text" className={" form-control" + (!errors[item.key + "Name"] ? '' : ' border-danger')} placeholder={"Enter " + item.name + " Number"} name={item.key + "Name"} value={values[item.key + "Name"]} onChange={handleChange} required />
                                                {errors[item.key + "Name"] ? <p className="text-danger error-contract p-0 m-0">{errors[item.key + "Name"]}</p> : <p className="text-dark error-contract p-0 m-0">{item.name + " Number"}</p>}
                                              </li>
                                              <li className="text-right pr-0">
                                                <div className="file-browse">
                                                  <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                                  <input type="file" accept=".png,.jpg,.pdf" id="file_1" name={item.key + item.dbId} onChange={handleFile} />
                                                  {errors[item.key + item.dbId] && <p className="error-contract p-0 m-0">{errors[item.key + item.dbId]}</p>}
                                                </div>
                                              </li>
                                            </ul>

                                            {(values[item.key + item.dbId]) ? <div className="filePath text-right"><div className="file-name">{`${values[item.key + item.dbId].name}`}</div></div> : ""}
                                            <hr className="" />
                                          </div>

                                        </>}
                                      </>)
                                    })}
                                  </div>
                                </>
                              }
                            </>
                            }
                          </div>
                          <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => handleSubmit()}>Submit</button>

                        </div>
                      </div>
                    </div>
                    :
                    doKYC ?
                      <TrulioKYCForm
                        userTokenDetails={userTokenDetails}
                        userData={{ countryCode: values.country }}
                        setdoKYC={setdoKYC}
                      />
                      : ""
                  }

                  {(showLoader === false && doKYC === false && isAdmin == true) &&
                    <AdminProfile userTokenDetails={userTokenDetails} userProfileDataG={userProfileDataG} values={values} clientType={clientType} KYCStatus={KYCStatus} handleAvatar={handleAvatar} handleChange={handleFile} handleSubmit={handleSubmit} errors={errors} refresh={refresh} setrefresh={setrefresh} />
                  }

                </div>}

              {tab === 1 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <BussinessDetailsComp userTokenDetails={userTokenDetails} userProfileDataG={userProfileDataG} />
                  </div>
                </div>}

              {tab === 2 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <ShareHolderDetails userTokenDetails={userTokenDetails} />
                  </div>
                </div>}

              {/* {tab === 3 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    company KYC
                  </div>
                </div>} */}

              {tab === 4 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <KYCDocumentsComp userTokenDetails={userTokenDetails} userEmail_id={userEmail_id} clientType={clientType} />
                  </div>
                </div>}

              {tab === 5 && <>
                <div className={"tab-pane active show col-md-12"}>
                  <FinanceDetailsComp userTokenDetails={userTokenDetails} />
                </div>
              </>}

              {tab === 6 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <h2 className="text-primary">Bank Details</h2>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">User Email</th>
                          <th scope="col">Beneficiary Account No.</th>
                          <th scope="col">Beneficiary Bank Name</th>
                          <th scope="col">Beneficiary IFSC Code</th>
                          <th scope="col">Beneficiary SWIFT Code</th>
                          <th scope="col">Correspondent Bank /name</th>
                          <th scope="col">Correspondent Account No.</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(useBankData && useBankData.length) ? useBankData.map((bankData, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{bankData.email}</td>
                              <td>{bankData.beneficiaryAccountNo}</td>
                              <td>{bankData.beneficiaryBankName}</td>
                              <td>{bankData.beneficiaryIfscCode}</td>
                              <td>{bankData.beneficiarySwiftCode}</td>
                              <td>{bankData.correspondentBankName}</td>
                              <td>{bankData.correspondentAccountNumber}</td>
                              <td><button
                                onClick={() => {
                                  seteditBankDetails(bankData);
                                  setaddBankObj(true, { "email": userEmail_id ? userEmail_id : userEmail })
                                }}
                                className="edit-icon"><i class="fas fa-edit text-primary"></i></button></td>
                            </tr>
                          )
                        }) : ""}
                      </tbody>
                    </table>
                    <hr className="mt-3 mb-3" />
                    {addBankObj.modal ?
                      <AddBankComponent editBankDetails={editBankDetails}
                        seteditBankDetails={seteditBankDetails} />
                      :
                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <h3 className="green mt-3">
                            Add New Bank
                            <button type="button" onClick={() => setaddBankObj(true, { "email": userEmail_id ? userEmail_id : userEmail })} className="close pt-0 mb-4" data-dismiss="modal" >
                              <i class="fas fa-plus fa-sm text-primary"></i>
                            </button>
                          </h3>
                        </div>
                      </div>}
                  </div>
                </div>}

              {tab === 7 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <ChangePassword
                      userTokenDetails={userTokenDetails}
                      compCall={true} />
                  </div>
                </div>}

              {tab === 8 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <BillingDetails userTokenDetails={userTokenDetails} />
                  </div>
                </div>}

              {tab === 9 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <PlatfornDetails userTokenDetails={userTokenDetails} />
                  </div>
                </div>}

              {tab === 10 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <CreditlineDocuments userTokenDetails={userTokenDetails} />
                  </div>
                </div>}

              {tab === 11 &&
                <div className={"tab-pane active show col-md-10 offset-md-1"}>
                  <div className="col-md-12 shadow bg-light pt-5 pb-5">
                    <OtherDocuments userTokenDetails={userTokenDetails} />
                  </div>
                </div>}

            </div>

            {showPreviewModal &&
              <FilePreview
                userTokenDetails={userTokenDetails}
                fileData={fileData}
                showPreviewModal={showPreviewModal}
                setshowPreviewModal={setshowPreviewModal} />}

          </main>
        </div>
      </div >
      {/* footer start */}
      {(userEmail_id === undefined) && < Footer />}
      {/* footer end */}
    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    addBankObj: state.addBankObj,
    clientType: state.clientType,
    navToggleState: state.navToggleState

  }
}

const mapDispatchToProps = dispatch => {
  return {
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile)
