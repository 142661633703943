import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import TutorialPopup, { TutorialVideoPopup } from "../tutorialPopup";
import Finance from "./components/finance";
import FinPayment from "./components/FinPayments";
import Payment from "./components/payment";
import ShipmentTab from "./components/shipmentTab";

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "18rem",
      "right": "6rem"
    },
    arrow: {
      "top": "28rem",
      "zIndex": 10,
      transform: "rotate(110deg)",
      left: "15.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>You can check your "Payment, Finance & Shipment report" by clicking on the respective tab under Reports.</span></label>
  }
}

const Reportsv2 = ({ userTokenDetails, navToggleState }) => {

  const [tab, setTab] = useState("");
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 3)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })


  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null


  let userPermissions = [];
  if (userTokenDetails.UserAccessPermission) {
    try {
      userPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
    } catch (error) {
      console.error("Failed to parse user permissions:", error);
    }
  }

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.name === componentPath) {
          return perm.subPermissions;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("Reportv2");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("add");
    const hasViewPermission = subPermissions.includes("view");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions]);

  console.log(subPermissions, "this is buyermanagement--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    const tabName = localStorage.getItem('report_name');
    setTab(tabName)
  }, [])





  return (
    <>

      <TutorialPopup show={lcTutorial} featureName={"Reports"} positioning={{
        ...tutorialPopupCSS[tutorialStage]["popup"]
      }} showSkip={true} userId={userId} showNext={true}
        onNext={() => {
          localStorage.setItem('lcTutorialStep', 4)
          window.location.reload()
        }}
        onBack={() => {
          localStorage.setItem("lcTutorialStep", 2)
          window.location.reload()
        }}
        showBack={false} videoLinkLabel={"Watch detailed video about Reports"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="Reportv2" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Reports"}
              userTokenDetails={userTokenDetails} />
            <nav>
              <div className="nav nav-tabs signdoctabs gap-4 border-0" id="signdoctabs" role="tablist">
                <button className={`nav-link  bg-transparent ${tab === "Payment" && 'active'} paymenttab`} id="nav-home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setTab("Payment")}>Payment</button>
                <span className="border-left"></span>
                <button className={`nav-link  bg-transparent ${tab === "Finance" && 'active'} paymenttab`} id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setTab("Finance")}>Finance</button>
                {userTypeId / 1 == 19 &&
                  <>
                    <span className="border-left"></span>
                    <button className={`nav-link  bg-transparent ${tab === "Shipment" && 'active'} paymenttab`} id="nav-contact-tab " data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => setTab("Shipment")}>Shipment</button>
                  </>
                }
              </div>
            </nav>

            <div className="tab-content d-block" id="nav-tabContent" style={lcTutorial ? { position: 'relative', zIndex: -1 } : {}}>
              {tab === "Payment" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                {userTypeId / 1 == 8 &&
                  <FinPayment userTokenDetails={userTokenDetails} navToggleState={navToggleState} />
                }
                {userTypeId / 1 == 19 &&
                  <Payment userTokenDetails={userTokenDetails} navToggleState={navToggleState} />
                }
              </div>
              }
              {tab === "Finance" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <Finance userTokenDetails={userTokenDetails} navToggleState={navToggleState} />
              </div>
              }
              {tab === "Shipment" && <div className="tab-pane fade show active bg-transparent " id="nav-home" role="tabpanel" >
                <ShipmentTab userTokenDetails={userTokenDetails} navToggleState={navToggleState} />
              </div>
              }
            </div>
          </main>
        </div>
      </div >
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Reportsv2)