import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap//disbursedInvoiceColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import { setChannelPartnerDisbursedInvoice } from '../../store/actions/action';
import { formatDate_Application } from '../../utils/dateFormaters';

const DisbursedInvoice = ({ userTokenDetails, navToggleState, dispatch, channelPartnerDisbursedInvoice, setChannelPartnerDisbursedInvoice }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [channelPartnerUsers, setChannelPartnerUsers] = useState([]);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;

  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    call('POST', 'getChannelPartnerUsers', { "userId": userId }).then((result) => {
      console.log('running getChannelPartnerUsers api-->', result);
      setChannelPartnerUsers(result.userData);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
    })

  }, []);

  useEffect(() => {

    fetchTableData(1, {});

  }, [filterData]);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerUsers', objectAPI).then((result) => {
      console.log('running getChannelPartnerUsers api-->', result);
      settableData(formatDataFortable(result.userData));
      // setTotalRows(result.userData.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerUsers', objectAPI).then((result) => {
      settableData(formatDataFortable(result.userData));
      // setTotalRows(result.userData.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      for (let j = 0; j < dataArray[i].disbursedInvoice.length; j++) {
        let tempObj = {}
        tempObj.reference_no = dataArray[i].disbursedInvoice[j].reference_no;
        tempObj.company_name = (dataArray[i].company_name ? dataArray[i].company_name : 'NA');
        tempObj.contract_amount = dataArray[i].disbursedInvoice[j].contract_amount;
        tempObj.amount = (dataArray[i].disbursedInvoice[j].amount);
        tempObj.disbursed_at = formatDate_Application(dataArray[i].disbursedInvoice[j].disbursed_at);
        tempObj.action = <button className="btn btn-success btn-sm" onClick={() => { setChannelPartnerDisbursedInvoice(true, { "userId": dataArray[i].tbl_user_id, "userName": dataArray[i].company_name, "userEmail": dataArray[i].email_id }) }}>
          <i className={"fas fa-folder-open"}></i>
        </button>
        resultArray.push(tempObj)
      }

    }
    return resultArray
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setFilterData({ supplierType: "", buyerType: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'disbursedinvoice'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={channelPartnerDisbursedInvoice.modal ? `Finance Against Invoice (${channelPartnerDisbursedInvoice.info.userName})` : "Disbursed Invoice"}
              userTokenDetails={userTokenDetails} />

            {channelPartnerDisbursedInvoice.modal ?
              <>
              </>
              // <FinanceDetailGrid userTokenDetails={userTokenDetails} manualUserId={channelPartnerAccountList.info.userId} manualUserName={channelPartnerAccountList.info.userName} manualUserEmail={channelPartnerAccountList.info.userEmail} />
              :
              <>
                <div class="d-md-flex mt-3 mb-3">
                  <div class="ml-md-auto">
                    <ul class="range-slider">
                      <li>
                        <select className="form-control" name="supplierType" value={filterData.supplierType} onChange={(event) => { setFilterData({ supplierType: event.target.value }) }}>
                          <option value="" selected>--Select Exporter--</option>
                          {channelPartnerUsers && channelPartnerUsers.length > 0 && channelPartnerUsers.map((supplier) => {
                            return (<option value={supplier.tbl_user_id + ':' + supplier.company_name}>{supplier.company_name}</option>)
                          })}
                        </select>
                      </li>
                      <li></li>
                      <li></li>
                      <li>
                        <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div>
                <hr />
              </>

            }

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChannelPartnerDisbursedInvoice: (modal, info) => { dispatch(setChannelPartnerDisbursedInvoice({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisbursedInvoice)