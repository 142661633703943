
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, convertImageToPdf, decryptData, getDocDetails, getInvoiceTotalOtherCharges, getInvoiceTotalCharges, most_used_currencies, finLogoByUserId, getTotalCharges, dataURItoBlob, getBGTotalOtherCharges, getBGTotalCharges, handleFileCommonFunction, getWCTotalCharges, most_used_currencies_with_percentage, monthsNoMapping } from "../../utils/myFunctions";
import call from '../../service';
import config from "../../config.json"


import { useHistory } from "react-router";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import { FileInput } from "../../utils/FileInput";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { facilityNames1, facilityNames2, ownerReview, reviewBusiness, wcyesnoqsts } from "../workingCapital/WCApplyLimit";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import moment from "moment";
import { FinancialMonths, companyDetailsReviewForm, yesnoqsts } from "../CGTMSE/ApplyLimitCGTMSE";
import { Action } from "../myCounterPartComp/action";
import { InputForTable, SelectForTable } from "../../utils/newInput";
import { FormProgressBar } from "../CommonComponent/FormProgressBar";

const tabLabels = [
  { name: "Application Form" },
  { name: "Documents" }
]

const SCFFinancierSendQuote = ({ userTokenDetails, navToggleState }) => {

  let scfQuoteDetails = JSON.parse(localStorage.getItem("scfQuoteDetails"))

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const user_name = userTokenDetails.userName ? userTokenDetails.userName : null

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  const onlyView = queryParams.get("onlyView")
  const isAdmin = localStorage.getItem("isAdmin")
  const defaultTabForAdmin = localStorage.getItem("defaultTabForAdmin")
  let history = useHistory()


  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(scfQuoteDetails.disableFinSelection ? 0 : 0)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({
    invoiceAmountCurrency: "USD", invoiceAmount: 50000, termsOfPaymentInDays: 30, factoringRateCurrency: '%',
    interestRateCurrency: '%', setUpFeesCurrency: 'USD', setUpFeesNegotiable: 'no', financeLimitCurrency: 'USD',
    ocUnit0: '%', offerValidity: moment().add(1, "month").format("YYYY-MM-DD")
  })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([null])
  const [recommendedFinanciers, setRecommendedFinanciers] = useState([])

  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [quoteView, setQuoteView] = useState("card")

  const quoteCardRef = useRef([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [applicationSubmitPopup, toggleApplicationSubmitPopup] = useState({ show: false, data: {} })

  const [owners, setOwners] = useState([])
  const [exFacilties, setExFacilities] = useState([])
  const [top10Buyers, setTop10Buyers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [top10Suppliers, setTop10Suppliers] = useState([null, null, null, null, null, null, null, null, null, null])
  const [currencyData, setCurrencyData] = useState(most_used_currencies_with_percentage)

  const [finActionTab, setFinancierActionTab] = useState(0)
  const [quoteScreen, toggleQuoteScreen] = useState({ show: false })
  const [otherCharges, setOtherCharges] = useState([null])
  const [otherDocs, setOtherDocs] = useState([null])
  const [action, setAction] = useState({ show: false, index: null })


  const scrollLeft = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    // console.log("letCardOffsetWidthhhhhhhhhhhhh", cardOffsetWidth);
    containerRef.scrollTo({ left: -cardOffsetWidth * 4, behavior: 'smooth' })
  };

  const scrollRight = () => {
    let cardOffsetWidth = quoteCardRef.current[0].offsetWidth
    let containerRef = document.getElementById("scrollContainerId")
    containerRef.scrollTo({ left: cardOffsetWidth * 4, behavior: 'smooth' })
  };

  async function loadData() {
    setshowLoader(true)
    let tempLimitDocsDetails = {}
    let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: scfQuoteDetails.limit_id, fetchSCFDocs: true })
    for (let index = 0; index < limitDocs.data.length; index++) {
      const element = limitDocs.data[index];
      tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
      if (element.attributes) {
        tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
      }
    }
    let gstOCRData = {}
    // GST DETAILS FROM OCR START
    setshowLoader(true)
    let apiResp = await call('POST', 'getLast3YearsGstMonthOnMonthSales', { userId: scfQuoteDetails.createdBy })
    setshowLoader(false)
    if (apiResp?.length) {
      let Year0 = moment().format("YYYY")
      let Year1 = Year0 - 1
      for (let index = 0; index < apiResp.length; index++) {
        const element = apiResp[index];
        let selectedYear = element.financialYear.split("-")[1]
        gstOCRData[`${monthsNoMapping[element.month]}${Year0 === selectedYear ? '_CurrenntValue' :
          Year1 === selectedYear ? '_LastValue' : "_2ndLastValue"}`] = element.sales || 0
      }
    }
    // GST DETAILS FROM OCR START
    setData({
      ...data, ...scfQuoteDetails.details, ...tempLimitDocsDetails, selectedLenderName: scfQuoteDetails.selectedLenderName,
      financierQuotes: JSON.parse(scfQuoteDetails.financierQuotes), ...gstOCRData
    })
    call('POST', 'getRecommendedFinanciers', { forBG: true }).then((result) => {
      console.log("getRecommendedFinanciers", result);
      setRecommendedFinanciers(result)
    }).catch((e) => {
      console.log('error in getRecommendedFinanciers', e);
    })
    // Set lists
    let tempOwners = []
    let tempExFac = []
    let tempSuppliers = []
    let tempBuyers = []
    let allKeyes = Object.keys(scfQuoteDetails.details) || []
    for (let index = 0; index < allKeyes.length; index++) {
      const element = allKeyes[index];
      if (element.includes(`ownerNameTitle`)) {
        tempOwners.push(null)
      }
      if (element.includes(`lenderName`)) {
        tempExFac.push(null)
      }
      if (element.includes(`supplierName`)) {
        tempSuppliers.push(null)
      }
      if (element.includes(`buyerName`)) {
        tempBuyers.push(null)
      }
    }
    setOwners(tempOwners)
    setExFacilities(tempExFac)
    setshowLoader(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  async function handleValidation() {
    let err = {}
    if (tab === 0) {
      setTab(1)
    }
    if (tab === 1) {
      setTab(2)
    }
    setErrors(err)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('scfApplicationId', scfQuoteDetails.limit_id)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function submitForm(value, type) {
    let quoteGiven = true
    let buyerQuoteDetails = {}
    // Decide value
    value = "deny"
    for (let index = 0; index < top10Suppliers.length; index++) {
      if (data[`supplierAction${index}`] === "Approved") {
        value = "Approved"
      }
    }
    if (value != 'deny') {
      if (!(data.factoringRate && data.factoringRateCurrency && data.interestRate &&
        data.interestRateCurrency && data.offerValidity && data.setUpFeesCurrency && data.setUpFees)) {
        quoteGiven = false
      }
      for (let index = 0; index < top10Suppliers.length; index++) {
        if (data[`supplierAction${index}`] === 'Approved') {
          if (!(data[`discountingPercentage${index}`] && data[`financeLimit${index}`] && data[`financeLimitCurrency${index}`])) {
            quoteGiven = false
          }
          buyerQuoteDetails[`supplierAction${index}`] = data[`supplierAction${index}`]
          buyerQuoteDetails[`discountingPercentage${index}`] = data[`discountingPercentage${index}`]
          buyerQuoteDetails[`financeLimit${index}`] = data[`financeLimit${index}`]
          buyerQuoteDetails[`financeLimitCurrency${index}`] = data[`financeLimitCurrency${index}`]
          buyerQuoteDetails[`supplierRemark${index}`] = data[`supplierRemark${index}`]
        }
        if (data[`supplierAction${index}`] === 'Denied') {
          buyerQuoteDetails[`supplierAction${index}`] = data[`supplierAction${index}`]
          buyerQuoteDetails[`supplierRemark${index}`] = data[`supplierRemark${index}`]
        }
      }
    }

    if (!quoteGiven) {
      return toastDisplay("Fill the quote details to continue", "info")
    }
    setshowLoader(true);
    let financierAction = '';
    let financierActionType = ""
    if (value && value === 'deny') {
      financierAction = 'deny'
      financierActionType = type
    }
    else {
      financierAction = 'Approved'
    }
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (!key.toLowerCase().includes("document")) {
        formData.append(key, data[key]);
      }
    })

    formData.append("userName", userName)
    formData.append("lenderId", userId);
    formData.append("limit_id", scfQuoteDetails.limit_id);
    formData.append("financierAction", financierAction);
    formData.append("financierActionType", financierActionType);
    formData.append("sellerId", scfQuoteDetails.createdBy)
    formData.append("buyerQuoteDetails", JSON.stringify(buyerQuoteDetails))
    formData.append("logo", userTokenDetails.userAvatar)

    let tempOtherCharges = []

    for (let index = 0; index < otherCharges.length; index++) {
      if (data[`ocName${index}`] && data[`ocAmount${index}`] && data[`ocUnit${index}`]) {
        tempOtherCharges.push({
          name: data[`ocName${index}`],
          amount: data[`ocAmount${index}`],
          unit: data[`ocUnit${index}`],
          other: data[`ocOther${index}`]
        })
      }
    }

    formData.append("otherCharge", JSON.stringify(tempOtherCharges));
    if (data.termSheet?.name) {
      formData.append('termSheet', data.termSheet)
    }
    for (let index = 0; index < otherDocs.length; index++) {
      if (data[`otherDocument${index}`]?.name) {
        formData.append(`otherDocument${index}`, data[`otherDocument${index}`])
        if (data[data[`otherDocument${index}`].signatureId]) {
          formData.append(`otherDocumentSignId${index}`, data[`otherDocumentSignId${index}`])
        }
      }
    }

    call('POST', 'sendSCFQuote', formData).then(async (result) => {
      toggleApplicationSubmitPopup({ show: true, msg: value === "deny" ? "Application rejected successfully" : "Quote sent successfully" })
      setshowLoader(false)
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error")
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      {applicationSubmitPopup.show ? (
        <FinanceInvoiceModal limitinvoice={applicationSubmitPopup} closeSuccess={() => {
          toggleApplicationSubmitPopup({ show: false })
          window.location = 'cgtmseFinancierQuote'
        }}>
          <div className='text-center'>
            <img src='/assets/images/successful_icon.svg' />
            <div className="my-4">
              <img style={{}} src="assets/images/greetings_congratulation.svg" />
            </div>
            <p className="m-0 p-0 px-5 font-size-16" >{applicationSubmitPopup.msg}</p>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="otherFinSCFFinancierQuote" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Other Financial > Supply Chain Finance > Quote Details"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor"
              onClick={() => {
                if (quoteScreen.show) {
                  toggleQuoteScreen({ show: false })
                }
                else {
                  history.go(-1)
                }
              }}
            ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>

            <div>
              {/* {quoteScreen.show ? null : <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {tabLabels.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => {
                          if (index < tab) {
                            setTab(index)
                          }
                        }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul>} */}

              <div className="card mt-1">

                {quoteScreen.show ? null : <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={tabLabels} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => {
                      if (index < tab) {
                        setTab(index)
                      }
                    }}
                  />
                </div>}

                {tab === 0 ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className='w-100 mb-3'>
                      <label className='font-wt-500 font-size-16'><u>Business Details</u></label>
                      <div className='row'>
                        {companyDetailsReviewForm.map((item) => {
                          return (
                            <div className="col-md-6 pe-5">
                              {item.name ?
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}`] ? (item.unit ? `${data[`${item.unit}`]} ` : "") + (data[`${item.val}`]) : "-"} {item.after && data[item.after] && ` ${data[item.after]} `}</span> </p>
                                : null} </div>
                          );
                        })}
                      </div>
                    </div>

                    {owners.map((i, j) => {
                      return (
                        <div className='w-100 mb-3'>
                          <label className='font-wt-500 font-size-16'><u>{`Owner ${j + 1} Details`}</u></label>
                          <div className='row'>
                            {ownerReview.map((item) => {
                              return (
                                <div className="col-md-6 pe-5">
                                  {item.name ?
                                    <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[`${item.val}${j}`] ? (item.unit ? `${data[`${item.unit}${j}`]} ` : "") + (data[`${item.val}${j}`]) : "-"} {item.after && data[`${item.after}${j}`] && ` ${data[`${item.after}${j}`]} `}</span> </p>
                                    : null} </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    })}


                    <div className="mt-4">
                      <label className='underlinedLabel'>Month on month (Sales in Lacs)</label>

                      <NewTablev2 columns={[{ subColumns: "Months" }, { subColumns: '2nd Last Year' }, { subColumns: 'Last Year' }, { subColumns: 'Current Year' }]} showSideBorders={true}>
                        {FinancialMonths.map((i, j) => {
                          return (
                            <tr style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>{i}</td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_2ndLastValue`] || '-'}
                              </td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_LastValue`] || '-'}
                              </td>
                              <td style={{ borderWidth: 1, borderColor: '#D5D5D5' }}>
                                {data[`${i}_CurrenntValue`] || '-'}
                              </td>

                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="d-flex align-items-center my-2" >
                      <label className='font-wt-500 font-size-16 w-40'><u>Supplier Details</u></label>
                      <div className="d-flex w-60 justify-content-end" >
                        <div className="mr-4" >
                          <img
                            className="cursor mr-3" src="assets/images/approve_new_btn.svg" />
                          <label className="font-size-14 font-wt-600" >Approve Supplier</label>
                        </div>
                        <div>
                          <img
                            className="cursor mr-3"
                            src="assets/images/reject_new_btn.svg" />
                          <label className="font-size-14 font-wt-600">Reject Supplier</label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '10%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '10%' } }, { name: " ", width: '11%' }]} >
                        {top10Suppliers.map((i, j) => {
                          if (data[`supplierName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`supplierAddress${j}`} value={data[`supplierAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <div className="d-flex">
                                    {data[`supplierAction${j}`] === "Denied" ? null : <img
                                      onClick={() => {
                                        setData({ ...data, [`supplierAction${j}`]: data[`supplierAction${j}`] ? undefined : "Approved" })
                                      }}
                                      className="cursor mr-3" src="assets/images/approve_new_btn.svg" />}
                                    {data[`supplierAction${j}`] === "Approved" ? null : <img
                                      onClick={() => {
                                        setData({ ...data, [`supplierAction${j}`]: data[`supplierAction${j}`] ? undefined : "Denied" })
                                      }}
                                      className="cursor"
                                      src="assets/images/reject_new_btn.svg" />}
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>

                    <label className="font-size-15 font-wt-500 mb-1" ><u>Buyer Details</u></label>
                    <div className="mb-3" >
                      <NewTablev2 showSideBorders={true} columns={[{ subColumns: 'Buyer Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Address', subColumnStyle: { width: '20%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '13%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '13%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '13%' } }]} >
                        {top10Buyers.map((i, j) => {
                          if (data[`buyerName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`buyerName${j}`} value={data[`buyerName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled name={`buyerAddress${j}`} value={data[`buyerAddress${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerCreditLimitRequired${j}`} value={data[`buyerCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerTermsOfPayment${j}`} value={data[`buyerTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerAnnualSale${j}`} value={data[`buyerAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerYearsOfRelation${j}`} value={data[`buyerYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`buyerRelationWithClient${j}`} value={data[`buyerRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>


                  </div>
                ) : null}

                {
                  tab === 1 && !quoteScreen.show ? (
                    <div className='d-flex row px-5 pt-4 pb-4'>
                      <ExtraDetailsForLimitApplication manualUserId={scfQuoteDetails.createdBy} isDisabled userTokenDetails={userTokenDetails} hideHeader={true} hideSideBar={true}
                        hideSubmitDiv={true} hideBackArrow={true} expandAll={true} cardBorder0={true} cardPadding0={true}
                        showContinueButton={false} mainTagMarginPadding0={true} />
                    </div>
                  ) : null
                }

                {quoteScreen.show ? (
                  <div className="d-flex row px-5 pt-5 pb-4" >
                    <div className="d-flex" >
                      <div className="col-9" >
                        <label className="font-size-18 font-wt-600" >Quote</label>
                        <img className="ml-3 mr-2" src="assets/images/warning.png" />
                        <label className="font-size-13 font-wt-600">Click on the “%” symbol to change is to currency.</label>
                      </div>
                      <div className="col-3 d-flex justify-content-end" >
                        <button type="button"
                          onClick={() => {
                            submitForm("Approved")
                          }}
                          className={`border-0 mb-2 text-white enableQuotebtn2`}>
                          {"Send Quote"}
                        </button>
                      </div>
                    </div>
                    <div>
                      <NewTablev2 stickTableHeaderToTop showSideBorders={false} columns={[{ subColumns: 'Supplier Name', subColumnStyle: { width: '11%' } },
                      { subColumns: 'Credit Limit Required ($)', subColumnStyle: { width: '7%' } },
                      { subColumns: 'Terms of Payment (days)', subColumnStyle: { width: '7%' } }, { subColumns: 'Annual Sale ($)', subColumnStyle: { width: '7%' } },
                      { subColumns: 'Years of Relation', subColumnStyle: { width: '7%' } },
                      { subColumns: 'Relation with Client', subColumnStyle: { width: '7%' } },
                      { subColumns: 'Finance Limit', subColumnStyle: { width: '16%' } },
                      { subColumns: 'Discounting Percentage', subColumnStyle: { width: '16%' } },
                      { subColumns: 'Remarks', subColumnStyle: { width: '12%' } }, { name: "Status", width: '11%' },
                      { name: "", width: '4%' }]} >
                        {top10Suppliers.map((i, j) => {
                          if (data[`supplierName${j}`]) {
                            return (
                              <tr>
                                <td>
                                  <InputForTable isDisabled name={`supplierName${j}`} value={data[`supplierName${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierCreditLimitRequired${j}`} value={data[`supplierCreditLimitRequired${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierTermsOfPayment${j}`} value={data[`supplierTermsOfPayment${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierAnnualSale${j}`} value={data[`supplierAnnualSale${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierYearsOfRelation${j}`} value={data[`supplierYearsOfRelation${j}`]} onChange={handleChange} />
                                </td>
                                <td>
                                  <InputForTable isDisabled type={"number"} name={`supplierRelationWithClient${j}`} value={data[`supplierRelationWithClient${j}`]} onChange={handleChange} />
                                </td>
                                <td className='font-size-13 font-wt-500 ' >
                                  <div className="d-flex w-100" >
                                    <div className="col-6 p-0" >
                                      <InputForTable type={"number"} name={`financeLimit${j}`} value={data[`financeLimit${j}`]} onChange={handleChange} />
                                    </div>
                                    <div className="col-6 p-0" >
                                      <SelectForTable selectData={currencyData} name={`financeLimitCurrency${j}`}
                                        optionLabel={"code"} optionValue={'code'}
                                        value={data[`financeLimitCurrency${j}`]} onChange={handleChange} />
                                    </div>
                                  </div>
                                </td>
                                <td className='font-size-13 font-wt-500' >
                                  <div className="d-flex w-100" >
                                    <div className="col-6 p-0" >
                                      <InputForTable type={"number"} name={`discountingPercentage${j}`} value={data[`discountingPercentage${j}`]} onChange={handleChange} />
                                    </div>
                                    <div className="col-6 p-0" >
                                      <SelectForTable isDisabled selectData={currencyData}
                                        optionLabel={"code"} optionValue={'code'}
                                        value={"%"} />
                                    </div>
                                  </div>
                                </td>
                                <td className='font-size-13 font-wt-500' >
                                  <div className="d-flex w-100" >
                                    <div className="col-12 p-0" >
                                      <InputForTable type={"text"} name={`supplierRemark${j}`} value={data[`supplierRemark${j}`]} onChange={handleChange} />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    {data[`supplierAction${j}`] === "Denied" ? null : <img
                                      // onClick={() => {
                                      //   setData({ ...data, [`supplierAction${ j }`]: data[`supplierAction${ j }`] ? undefined : "Approved" })
                                      // }}
                                      className="cursor mr-3" src="assets/images/approve_new_btn.svg" />}
                                    {data[`supplierAction${j}`] === "Approved" ? null : <img
                                      // onClick={() => {
                                      //   setData({ ...data, [`supplierAction${ j }`]: data[`supplierAction${ j }`] ? undefined : "Denied" })
                                      // }}
                                      className="cursor"
                                      src="assets/images/reject_new_btn.svg" />}
                                  </div>
                                </td>
                                <td
                                  style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                  className='position-relative'>
                                  <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                    onClick={() => setAction({ show: true, index: j })}
                                    aria-hidden="true"></i>
                                  {action.show && action.index === j ? (
                                    <Action
                                      id={j}
                                      onDismiss={() => setAction({ show: false, index: j })}
                                      options={[
                                        {
                                          name: data[`supplierAction${j}`] === "Approved" ? "Reject supplier" : "Approve supplier", onClick: () => {
                                            setData({ ...data, [`supplierAction${j}`]: data[`supplierAction${j}`] === "Approved" ? "Denied" : "Approved" })
                                          }
                                        }
                                      ]} />
                                  ) : null}
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </NewTablev2>
                    </div>
                    <div className="my-3" >
                      <NewTablev2 stickTableHeaderToTop showSideBorders={true} columns={[{ subColumns: 'Charges', subColumnStyle: { width: '25%' } }, { subColumns: 'Rate/fees', subColumnStyle: { width: '25%' } },
                      { subColumns: 'Others', subColumnStyle: { width: '40%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                        <tr>
                          <td className='font-size-12 font-wt-600' >Factoring Rate</td>
                          <td className='font-size-13 font-wt-500 d-flex w-100' >
                            <div className="col-9 p-0" >
                              <InputForTable type={"number"} name={"factoringRate"} value={data.factoringRate} onChange={handleChange} />
                            </div>
                            <div className="col-3 p-0" >
                              <SelectForTable selectData={currencyData} name={"factoringRateCurrency"}
                                optionLabel={"code"} optionValue={'code'}
                                value={data.factoringRateCurrency} onChange={handleChange} />
                            </div>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >Interest Rate</td>
                          <td style={{ height: '5rem' }} className='font-size-13 font-wt-500 d-flex w-100' >
                            <div className="col-9 p-0" >
                              <InputForTable type={"number"} name={"interestRate"} value={data.interestRate} onChange={handleChange} />
                            </div>
                            <div className="col-3 p-0" >
                              <SelectForTable selectData={currencyData} name={"interestRateCurrency"}
                                optionLabel={"code"} optionValue={'code'}
                                value={data.interestRateCurrency} onChange={handleChange} />
                            </div>
                          </td>
                          <td className="font-size-12 font-wt-600 " >
                            <label className="font-size-12 font-wt-600 ">Inclusive of TERM SOFR? If yes, </label>
                            <span className="w-40 d-block font-size-13 font-wt-600" ><SelectForTable name={"termSofr"}
                              selectData={[{ name: '3 Months' }, { name: '6 Months' }, { name: '9 Months' }, { name: '12 Months' }]}
                              optionLabel={"name"} optionValue={'name'} value={data.termSofr} onChange={handleChange} /> </span>
                          </td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >Setup fees</td>
                          <td style={{ height: '5rem' }} className='font-size-13 font-wt-500 d-flex w-100' >
                            <div className="col-9 p-0" >
                              <InputForTable type={"number"} name={"setUpFees"} value={data.setUpFees} onChange={handleChange} />
                            </div>
                            <div className="col-3 p-0" >
                              <SelectForTable selectData={currencyData} name={"setUpFeesCurrency"}
                                optionLabel={"code"} optionValue={'code'}
                                value={data.setUpFeesCurrency} onChange={handleChange} />
                            </div>
                          </td>
                          <td className="font-size-12 font-wt-600" >
                            <label className="font-size-12 font-wt-600 ">Negotiable?</label>
                            <label className="w-40 d-block font-size-13 font-wt-600" >
                              <div
                                className="row d-flex my-0 px-2"
                              // style={{ width: '25%' }}
                              >
                                <div className='d-flex flex-row px-2 col-6' onClick={() => {
                                  setData({ ...data, setUpFeesNegotiable: "no" })
                                }}>
                                  <input className="form-check-input" type="radio" value={"no"} checked={data.setUpFeesNegotiable === 'no'} />
                                  <label className="form-check-label p-0 m-0" >
                                    No
                                  </label>
                                </div>
                                <div className='d-flex flex-row px-2 col-6' onClick={() => {
                                  setData({ ...data, setUpFeesNegotiable: "yes" })
                                }}>
                                  <input className="form-check-input" type="radio" value={"yes"} checked={data.setUpFeesNegotiable === 'yes'} />
                                  <label className="form-check-label p-0 m-0" >
                                    Yes
                                  </label>
                                </div>
                              </div> </label>
                          </td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >Offer Validity</td>
                          <td className='font-size-13 font-wt-500 d-flex w-100' >
                            <div className="col-9 p-0" >
                              <InputForTable minDate={moment().format("YYYY-MM-DD")} type={"date"} name={"offerValidity"} value={data.offerValidity} onChange={handleChange} />
                            </div>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >Finance Limit</td>
                          <td className='font-size-13 font-wt-500 d-flex w-100' >
                            <div className="col-9 p-0" >
                              <InputForTable type={"number"} name={"financeLimit"} value={data.financeLimit} onChange={handleChange} />
                            </div>
                            <div className="col-3 p-0" >
                              <SelectForTable selectData={currencyData} name={"financeLimitCurrency"}
                                optionLabel={"code"} optionValue={'code'}
                                value={data.financeLimitCurrency} onChange={handleChange} />
                            </div>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className='font-size-12 font-wt-600' >Discounting Percentage</td>
                          <td className='font-size-13 font-wt-500 d-flex w-100' >
                            <div className="col-9 p-0" >
                              <InputForTable type={"number"} name={"discountingPercentage"} value={data.discountingPercentage} onChange={handleChange} />
                            </div>
                            <div className="col-3 p-0" >
                              <SelectForTable isDisabled selectData={currencyData}
                                optionLabel={"code"} optionValue={'code'}
                                value={"%"} />
                            </div>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        {otherCharges.map((i, j) => {
                          return (<tr>
                            <td className='' >
                              <div>
                                <InputForTable name={`ocName${j}`} value={data[`ocName${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            <td className='font-size-13 font-wt-500 d-flex w-100' >
                              <div className="col-9 p-0" >
                                <InputForTable type={"number"} name={`ocAmount${j}`} value={data[`ocAmount${j}`]} onChange={handleChange} />
                              </div>
                              <div className="col-3 p-0" >
                                <SelectForTable selectData={currencyData} name={`ocUnit${j}`}
                                  optionLabel={"code"} optionValue={'code'}
                                  value={data[`ocUnit${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            <td className='' >
                              <div>
                                <InputForTable name={`ocOther${j}`} value={data[`ocOther${j}`]} onChange={handleChange} />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex w-100 justify-content-between" >
                                <div className="" >
                                  {otherCharges.length - 1 === j ?
                                    <img
                                      onClick={async () => {
                                        let temp = otherCharges
                                        temp.push(null)
                                        setOtherCharges([...temp])
                                        setData({ ...data, [`ocUnit${j + 1}`]: '%' })
                                      }}
                                      className="cursor" src="assets/images/add_black_icon.png" /> :
                                    <img
                                      onClick={() => {
                                        let temp = otherCharges
                                        temp = temp.filter((l, m) => {
                                          if (m != j) {
                                            return true
                                          }
                                        })
                                        setOtherCharges([...temp])
                                      }}
                                      className="cursor" src="assets/images/delete.png" />}
                                </div>
                              </div>
                            </td>
                          </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>

                    <div className="my-3" >
                      <label className="font-size-13 font-wt-500" ><u>Documents</u></label>
                      <NewTablev2 columns={[{ subColumns: 'Document Name', subColumnStyle: { width: '25%' } }, { subColumns: 'Attach Document', subColumnStyle: { width: '55%' } },
                      { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                        <tr>
                          <td className='font-size-12 font-wt-500' >Term Sheet</td>
                          <td>
                            <FileInput isEditable={true} value={data.termSheet} name={"termSheet"}
                              onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                              onUploadCancel={() => setData({ ...data, termSheet: null })} />
                          </td>
                          <td>
                            {!data[`termSheet`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`termSheet`]?.name); setData({ ...data, docToSign: data[`termSheet`], docSignName: `termSheet` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`termSheet`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheet`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        {otherDocs.map((i, j) => {
                          return (
                            <tr>
                              <td className='' >
                                <div className="col-9 p-0" >
                                  <InputForTable name={`otherDocumentName${j}`} value={data[`otherDocumentName${j}`]} onChange={handleChange} />
                                </div>
                              </td>
                              <td>
                                <FileInput isEditable={true} value={data[`otherDocument${j}`]} name={`otherDocument${j}`}
                                  onChange={(e) => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader)}
                                  onUploadCancel={() => setData({ ...data, [`otherDocument${j}`]: null })} />
                              </td>
                              <td>
                                {!data[`otherDocument${j}`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`otherDocument${j}`]?.name); setData({ ...data, docToSign: data[`otherDocument${j}`], docSignName: `otherDocument${j}` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {data[`otherDocument${j}`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`otherDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`otherDocument${j}`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    <div className="" >
                                      {otherDocs.length - 1 === j ?
                                        <img
                                          onClick={async () => {
                                            let temp = otherDocs
                                            temp.push(null)
                                            setOtherDocs([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = otherDocs
                                            temp = temp.filter((l, m) => {
                                              if (m != j) {
                                                return true
                                              }
                                            })
                                            setOtherDocs([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div></>
                                ) : null}
                              </div></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </div>
                ) : null}

                {quoteScreen.show ? null : <div
                  className="d-flex finActionTabContainer mx-5 my-4 p-0" >
                  {tab / 1 == 0 ? <div
                    onClick={() => {
                      setTab(tab + 1)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor"} >Next</label>
                  </div> : <div
                    onClick={() => {
                      setFinancierActionTab(0)
                      toggleQuoteScreen({ show: true })
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`w-25 text-center py-2 cursor bg-5CB8D3 `} >
                    <label className={"font-size-16 font-wt-700 text-white cursor"} >Send Quote</label>
                  </div>}
                  <div
                    onClick={() => {
                      if (tab > 0) {
                        setTab(tab - 1)
                      }
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-2 px-2 cursor`} >
                    <label className={"font-size-14 font-wt-500 text-dark cursor"} >Previous</label>
                  </div>
                  <div
                    onClick={() => {
                      setFinancierActionTab(finActionTab == 0 ? 2 : 0)
                    }}
                    style={{ borderRight: '2px solid #5CB8D3' }}
                    className={`position-relative w-25 text-center py-2 px-2 cursor`} >
                    {finActionTab == 2 ? <div
                      style={{
                        width: '14rem',
                        top: '-7rem',
                        borderRadius: "10px"
                      }}
                      className="position-absolute bg-DEF7FF " >
                      <label
                        onClick={() => submitForm("deny", "Application Rejected")}
                        className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6" >Reject this Application</label>
                      <label
                        onClick={() => submitForm("deny", "Buyer Rejected")}
                        className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Buyer</label>
                      <label
                        onClick={() => submitForm("deny", "Supplier Rejected")}
                        className="font-size-14 font-wt-500 w-100 cursor text-left py-1 px-3 border-bottom-solid-dee2e6">Reject this Supplier</label>
                    </div> : null}
                    <label className={"font-size-14 font-wt-500 text-dark cursor"} >Reject Application</label>
                    <img
                      src="assets/images/arrow.png"
                    />
                  </div>
                  <div
                    style={{ borderRight: '2px solid #5CB8D3' }} className="w-25 text-center py-2">
                    <img className="mr-2"
                      src="assets/images/chat_new_icon.svg"
                    />
                    <label
                      onClick={async () => {
                        let chatRoomIds = scfQuoteDetails.chatRoomIds?.split(",") || []
                        let chatRoomUsers = scfQuoteDetails.chatRoomUsers?.split(",") || []

                        let openChatRoomIndx = null
                        chatRoomUsers.forEach((u, i) => {
                          if (u?.split("::")[1] / 1 == userId / 1) {
                            openChatRoomIndx = i
                          }
                        })

                        let reqObj = {
                          userTypeId,
                          senderId: userId,
                          sellerId: scfQuoteDetails.createdBy,
                          receiverId: userId,
                          textMessage: 'Hii',
                          chat_room_name: "CHAT" + new Date().getTime(),
                          includeAdmins: true,
                          bgApplicationId: scfQuoteDetails.limit_id,
                          receiverParties: userId,
                          dontSendInitialMsg: true
                        }
                        setshowLoader(true)
                        let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                        setshowLoader(false)
                        getChatDetails({
                          chat_room_id: apiResp.id,
                          loggedInUser: userId
                        })
                        setSelectedChat({
                          chatRoomId: apiResp.id,
                          receiverName: `${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
                          bgApplicationId: scfQuoteDetails.limit_id,
                          parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
                          userId: userId,
                          isChatOpen: true,
                          receiverId: userId,
                          sellerId: scfQuoteDetails.createdBy
                        })
                      }}
                      className={"font-size-14 font-wt-500 text-dark cursor"} >Chat / Remarks</label>
                  </div>
                  <div className="w-25 text-center py-2">
                    <label className={"font-size-14 font-wt-500 text-dark cursor"} >Assign to other user</label>
                    <img className="ml-2"
                      src="assets/images/redirect_new_icon.png"
                    />
                  </div>
                </div>}

              </div>

            </div>

          </main>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

export default connect(
  mapStateToProps,
  null
)(SCFFinancierSendQuote)