import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import call from "../../service";
import ChatBot from "../../utils/ChatBot";
import toastDisplay from "../../utils/toastNotification";
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import TutorialPopup, { TutorialVideoPopup } from "../tutorialPopup";
import FAQsCard from "./components/faqsCard";
import SupportChat from "./components/supportChat";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import MultipleSelect from "../../utils/MultipleSelect";
import { handleFileWithAsyncAwait, multiSelectEventHandler } from "../../utils/myFunctions";
import { NewTextArea } from "../../utils/newInput";
import { FileInput } from "../../utils/FileInput";

const tutorialPopupCSS = {
  "0": {
    popup: {
      "top": "3rem",
      "right": "-12rem"
    },
    arrow: {
      "top": "4rem",
      "zIndex": 10,
      transform: "rotate(465deg)",
      left: "77.5%"
    },
    children: <label className='font-size-14 font-wt-600 text-color-value'><span>In case of any query you can search for solution or contact us by clicking on "Help" icon.</span></label>
  }
}

let issueCategories = [{ name: "Account Related" }, { name: "Referral Bonus" },
{ name: "Network Management" }, { name: "Contract Management" },
{ name: "Export Factoring" }, { name: "Letter of Credit" },
{ name: "Working Capital" }, { name: "CGTMSE" },
{ name: "Bank Guarantee" }, { name: "Supply Chain Finance" },
{ name: "Domestic Invoice Discounting" }, { name: "Insurance" },
{ name: "Logistics" }, { name: "E-Docs" }, { name: 'Other' }]

const HelpSupport = ({ userTokenDetails, navToggleState }) => {

  const [chat, setChat] = useState(false);
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 5)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [showLoader, setshowLoader] = useState(false)
  const [dbData, setDbData] = useState([])
  const [faqType, setFaqType] = useState('LC')
  const [data, setData] = useState({})
  const [supportTicketPopup, setSupportTicketPopup] = useState({})
  const [error, setError] = useState({})
  const [issueCategoriesFiltered, setIssueCategoriesFiltered] = useState(issueCategories)
  const [issueAttachments, setIssueAttachments] = useState([null])

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  async function asyncUseEffectFunction() {
    setshowLoader(true)
    let resp = await call('POST', 'getFaqQsts', { category: faqType, search: data.search })
    setDbData(resp)
    setshowLoader(false)
  }

  useEffect(() => {
    asyncUseEffectFunction()
  }, [faqType, data.search])

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setData({ ...data, [event.target.name]: event.target.value })
    }
  };



  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <TutorialPopup show={lcTutorial} featureName={"Help"} positioning={{
        ...tutorialPopupCSS[tutorialStage]["popup"]
      }} showSkip={true} userId={userId} showNext={true}
        onNext={() => {
          localStorage.setItem('lcTutorialStep', 6)
          window.location.reload()
        }}
        onBack={() => {
          localStorage.setItem("lcTutorialStep", 4)
          window.location.reload()
        }}
        showBack={true} videoLinkLabel={"Watch detailed video about Help"}
        onVideoLinkClick={() => toggleTutorialVideo({ show: true, link: "https://www.youtube.com/embed/tgbNymZ7vqY" })}
        children={tutorialPopupCSS[tutorialStage]["children"]}
        arrowPositioning={{
          ...tutorialPopupCSS[tutorialStage]["arrow"]
        }} />

      <TutorialVideoPopup
        show={showTutorialVideo.show}
        videoLink={showTutorialVideo.link}
        onClose={() => toggleTutorialVideo({ show: false, link: null })}
      />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="HelpSupport" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Help Support"}
              userTokenDetails={userTokenDetails} />
            <div className='' style={lcTutorial ? { position: 'relative', zIndex: -1 } : {}}>
              <p className="font-size-20 font-wt-400 letter-spacing05 lh-27">Search for a question</p>
              <div className="d-flex align-items-center">
                <div className="col-md-4 px-0 ">
                  <div className="input-group col-md-9 borderEEEEEE px-0 borderRadius currency">
                    <span className="input-group-text bg-white border-0 borderRadius"><img className="me-1" src={"assets/images/fe_search.png"} /></span>
                    <input
                      onKeyDown={handleKeyDown} name={"search"}
                      // value={data.search}
                      type="text" className=" px-0 form-control border-0" placeholder="Start typing..." aria-label="Username" />
                  </div>
                </div>
                <div className="d-flex gap-4 col-md-8 justify-content-end px-0">
                  <button onClick={async () => {
                    setshowLoader(true)
                    await call('POST', 'restartTutorial', { userId })
                    setshowLoader(false)
                    window.location.reload()
                  }} className="text-color1 px-4 py-2 new-btn bg-transparent border2px-5CB8D3"><img className="me-1" src={"assets/images/play.png"} /> Restart tutorial</button>
                  <button onClick={() => {
                    window.location = '/trackSupportTickets?createNew=true'
                  }} className="new-btn text-white px-5 py-2 btn-shadow">Raise a Support Ticket</button>
                  <label
                    onClick={() => { window.location = '/trackSupportTickets' }}
                    className="font-size-15 font-wt-700 text-color1 d-flex align-items-center cursor"><u>Track Support Tickets</u></label>
                </div>
              </div>
              <div>
                <p className="mt-5 font-wt-400 letter-spacing05 lineheight19 text-color-0C0C0C">FAQs</p>
                <FAQsCard search={data.search} dbData={dbData} faqType={faqType} setFaqType={selectedFaqType => setFaqType(selectedFaqType)} />
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* {chat && <SupportChat chat={chat} setChat={setChat} />} */}
      {chat ? <ChatBot /> : null}

    </>
  );

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(HelpSupport)