

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setViewTrack } from '../../store/actions/action';
import ReactCountryFlag from "react-country-flag";


const ShipmentDetailAccord = ({ userTokenDetails, contractNo, shipContractNo, setViewTrack, ViewTrack, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [logData, setlogData] = useState({});
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'contractDetailsShipment', { "contract_number": contractNo, "ship_contract_number": shipContractNo }).then(async (result) => {
      setlogData(result.message[0])

    }).catch((error) => {
      console.log("error occur in bccontractDetailsShipmenttrail ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="mt-5 bg-white">

      <div className="col-md-12">
        <div className="col-md-12">
          <div className="  ">
            <button type="button" className="btn btn-link btn-sm" onClick={() => { setViewTrack(true, { contractId: contractNo }) }}>Track Shipment</button>
          </div>
        </div>
        <div className="col-md-12 mapouter">
          <div className="gmap_canvas">
            <iframe width="100%" height="234" id="gmap_canvas" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="row ml-2">
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.ship_quantity ? logData.ship_quantity : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Quantity to be Shipped</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.ship_currency ? logData.ship_currency.split(':')[1] : ""}{logData && logData.ship_price ? logData.ship_price : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Shipping Rate</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.ship_imo_no ? logData.ship_imo_no : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Ship IMO</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.ship_name ? logData.ship_name : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Ship Name</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.lengthv ? logData.lengthv : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Length</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.breadthv ? logData.breadthv : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Breadth</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.depth ? logData.depth : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Depth</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.gross ? logData.gross : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Gross Tons</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">{logData && logData.net ? logData.net : '--'}</label>
              <div className="col-md-12"> <span className="date-format">Net Tons</span></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">
                <>
                  {logData && logData.ship_load_country ?
                    <span className="shadow">
                      <ReactCountryFlag
                        countryCode={logData && logData.ship_load_country ? logData.ship_load_country.split(':')[0] : ""}
                        style={{ width: '25px', height: '25px' }} svg /></span> : ""}
                  {logData && logData.ship_load_port ? logData.ship_load_port : '--'}
                </>
              </label>
              <div className="col-md-12">
                <span className="date-format">Loading Port</span>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row form-group">
              <label className="col-md-12">
                <>
                  {logData && logData.ship_unload_country ? <span className="shadow">
                    <ReactCountryFlag
                      countryCode={logData && logData.ship_unload_country ? logData.ship_unload_country.split(':')[0] : ""}
                      style={{ width: '25px', height: '25px' }} svg /> </span> : ""}
                  {logData && logData.ship_unload_port ? logData.ship_unload_port : '--'}
                </>
              </label>
              <div className="col-md-12">
                <span className="date-format">Unloading Port</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    ViewTrack: state.ViewTrack
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentDetailAccord)
