import call from '../../service';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setShowViewProfileModal } from '../../store/actions/action';
import ReactCountryFlag from "react-country-flag";
import toastDisplay from '../../utils/toastNotification';
import FilePreview from '../utilComponents/previewFile';
import commImageUrl from '../../utils/makeCommImageUrl';
import config from '../../config.json';
import dataUser from './agreementT&C.json';
import tradeTechAnnexure from './tradeTechAnnexure.json';
import finTechAnnexure from './finTechAnnexure.json';
import logiTechAnnexure from './logiTechAnnexure.json';
import insureTechAnnexure from './insureTechAnnexure.json';
import validate from '../../utils/validation_files/channelPartnerAgreement';

const ChannelPartnerProfileDetails = ({ userTokenDetails, setShowViewProfileModal, showViewProfileModal }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [dbData, setdbData] = useState([]);
  const [dbTypeData, setdbTypeData] = useState([]);
  const [fileData, setfileData] = useState({});
  const [countryData, setcountryData] = useState([]);
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [accord, setAccord] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true
  });
  const [channeltab, setChannelTab] = useState(0);
  const [accordion, setAccordion] = useState(0);
  const [dataTnc, setdataTnc] = useState({});
  const [tradetechAnnexure, settradetechAnnexure] = useState({});
  const [fintechAnnexure, setfintechAnnexure] = useState({});
  const [logitechAnnexure, setlogitechAnnexure] = useState({});
  const [insuretechAnnexure, setinsuretechAnnexure] = useState({});
  const [errorData, seterrorData] = useState({});
  const [editCount, setEditCount] = useState(0);
  const [tncChange, settncChange] = useState(0);
  const [tncOrder, settncOrder] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tab, setTab] = useState(0);
  const signStyle = {
    "font-family": "Times New Roman",
    "font-style": "italic",
    "font-size": "28px"
  }
  const [techTypeId, setTechTypeId] = useState(0);
  const [newTnc, setnewTnc] = useState({ newTncName: "", newTncValue: "" });
  const [reqId, setReqId] = useState(0);
  const [agreementTemplate, setAgreementTemplate] = useState({})
  const [doc, setDoc] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [viewFileFromMstTable, toggleViewFileFromMst] = useState(false)


  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  const astrix = <span className="required-field text-danger">*</span>;

  const handleAccordion = (accordion) => setAccordion(accordion);
  //---------------------------------------------------------------------------------------------------------------------

  function getDomainNameByTypeId(type_id) {
    if (type_id === 4) {
      return "Trade Tech"
    }
    if (type_id === 5) {
      return "Fin Tech"
    }
    if (type_id === 6) {
      return "Logi Tech"
    }
    if (type_id === 7) {
      return "Insure Tech"
    }
  }


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    if (dbData[0] && dbData[0].userData && dbData[0].userData.channel_partner_role && techTypeId) {
      setDoc({
        ...doc,
        docName: `${dbData[0].userData.channel_partner_role === "FP" ?
          'Franchise Partner Agreement ' : 'Channel Partner Agreement '}${getDomainNameByTypeId(techTypeId)}`
      })
      getAgreementTemplate(dbData[0].userData.channel_partner_role, techTypeId)
    }
  }, [techTypeId, dbData, refresh])

  function getAgreementTemplate(role, type) {
    call('POST', 'getChannelPartnerAgreementTemplate', { role, type }).then(async (result) => {
      console.log("api result in getChannelPartnerAgreementTemplate-->", result)
      if (result.file_hash) {
        setAgreementTemplate(result)
      }
      else {
        setAgreementTemplate({})
      }
    }).catch((e) => {
      // console.log("Error while querying getChannelPartnerAgreementTemplate:", e);
    })
  }

  useEffect(() => {

    //API Calls
    call('POST', 'getSingleUserByReq', { id: showViewProfileModal.info.data.id }).then(async (result) => {
      console.log("api result in admin modal view page-->", result)
      setdbData(result);
      setTechTypeId(result[0].tech_type_id);
      setshowLoader(false);
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying users:", e);
    })


    call('GET', 'getuserstypelist').then((result) => {
      console.log('result in getuserstypelist', result);
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    setdataTnc(dataUser);
    settradetechAnnexure(tradeTechAnnexure);
    setfintechAnnexure(finTechAnnexure);
    setlogitechAnnexure(logiTechAnnexure);
    setinsuretechAnnexure(insureTechAnnexure);

    if (showViewProfileModal.modal) {
      setData({
        ...data,
        ['userId']: showViewProfileModal.info.data.id,
        ['company_name']: showViewProfileModal.info.data.userDetails.company_name,
        ['contact_person']: showViewProfileModal.info.data.userDetails.contact_person,
        ['email']: showViewProfileModal.info.data.userDetails.email_id,
        ['address']: showViewProfileModal.info.data.userDetails.user_address,
        ['country']: showViewProfileModal.info.data.userDetails.country_code,
        ['contact_number']: showViewProfileModal.info.data.userDetails.contact_number,
        ['organization_type']: showViewProfileModal.info.data.userDetails.organization_type,
        // ['organization_type']: (showViewProfileModal.info.data.userDetails.organization_type === 'individual') ? 'Individual' :
        //   (showViewProfileModal.info.data.userDetails.organization_type === 'soleProprietorship') ? 'Sole proprietorship' :
        //     (showViewProfileModal.info.data.userDetails.organization_type === 'partnership') ? 'Partnership' :
        //       (showViewProfileModal.info.data.userDetails.organization_type === 'llp') ? 'LLP' :
        //         (showViewProfileModal.info.data.userDetails.organization_type === 'pvtPubLtd') ? 'Pvt / Pub Ltd' : '',
        ['panDocumentName']: showViewProfileModal.info.data.userDetails.panDocumentName,
        ['gstDocumentName']: showViewProfileModal.info.data.userDetails.gstDocumentName,
        ['cinDocumentName']: showViewProfileModal.info.data.userDetails.cinDocumentName,
        ['tech_type_id']: showViewProfileModal.info.data.tech_type_id
      })
    }
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitAgreement();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  // function callPreviewFileComponent(id, action) {
  //   setfileData({
  //     "action": action,
  //     "tbl_doc_id": id,
  //   })
  //   setshowPreviewModal(true)
  // }

  function submitDoc() {
    setshowLoader(true)
    let formData = new FormData();
    formData.append('file', doc["doc"]);
    formData.append('doc_name', doc.docName);
    formData.append('role', dbData[0].userData.channel_partner_role);
    formData.append('type', techTypeId);
    call('POST', 'addChannelPartnerAgreementTemplate', formData).then((result) => {
      toastDisplay("Template uploaded successfully", "success")
      setrefresh(refresh + 1)
      setshowLoader(false)
    }).catch((error) => {
      // console.log("error occur in addUserOtherDocs ->", error)
      setshowLoader(false)
    })
  }

  function deleteDoc() {
    setshowLoader(true)
    call('POST', 'deleteChannelPartnerAgreementTemplate', { id: agreementTemplate.id }).then((result) => {
      toastDisplay("Template removed successfully", "success")
      setAgreementTemplate({})
      setshowLoader(false)
    }).catch((error) => {
      // console.log("error occur in deleteChannelPartnerAgreementTemplate ->", error)
      setshowLoader(false)
    })
  }


  function tncBodyGenerator(index2) {
    let keyArray = Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!dataTnc[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{dataTnc[key].name ? dataTnc[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={dataTnc[key].value.replace(/<br>/g, "\n")} onChange={handleTNC} disabled={index2.tech_type_id == techTypeId && index2.status == 3 ? true : false} ></textarea>
                </div>
              </div>
              {index2.tech_type_id == techTypeId && index2.status == 3 ? '' : <button type="button" className="delete-icon tnc-delete" onClick={() => removeElement(key)} ><i className="fa fa-trash"></i></button>}
            </div>
          )
        })}
      </div>
    )
  }

  function tradeAnnexureBody(index2) {
    let keyArray = Object.keys(tradetechAnnexure).length ? Object.keys(tradetechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!tradetechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{tradetechAnnexure[key].name ? tradetechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={tradetechAnnexure[key].value.replace(/<br>/g, "\n")} onChange={handleTNC} disabled={index2.tech_type_id == techTypeId && index2.status == 3 ? true : false}></textarea>
                </div>
              </div>
              {index2.tech_type_id == techTypeId && index2.status == 3 ? '' : <button type="button" className="delete-icon tnc-delete" onClick={() => removeTradeAnnexureElement(key)} ><i className="fa fa-trash"></i></button>}
            </div>
          )
        })}
      </div>
    )
  }

  function finAnnexureBody(index2) {
    let keyArray = Object.keys(fintechAnnexure).length ? Object.keys(fintechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!fintechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{fintechAnnexure[key].name ? fintechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={fintechAnnexure[key].value.replace(/<br>/g, "\n")} onChange={handleTNC} disabled={index2.tech_type_id == techTypeId && index2.status == 3 ? true : false}></textarea>
                </div>
              </div>
              {index2.tech_type_id == techTypeId && index2.status == 3 ? '' : <button type="button" className="delete-icon tnc-delete" onClick={() => removeFinAnnexureElement(key)} ><i className="fa fa-trash"></i></button>}
            </div>
          )
        })}
      </div>
    )
  }

  function logiAnnexureBody(index2) {
    let keyArray = Object.keys(logitechAnnexure).length ? Object.keys(logitechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!logitechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{logitechAnnexure[key].name ? logitechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={logitechAnnexure[key].value.replace(/<br>/g, "\n")} onChange={handleTNC} disabled={index2.tech_type_id == techTypeId && index2.status == 3 ? true : false}></textarea>
                </div>
              </div>
              {index2.tech_type_id == techTypeId && index2.status == 3 ? '' : <button type="button" className="delete-icon tnc-delete" onClick={() => removeLogiAnnexureElement(key)} ><i className="fa fa-trash"></i></button>}
            </div>
          )
        })}
      </div>
    )
  }

  function insureAnnexureBody(index2) {
    let keyArray = Object.keys(insuretechAnnexure).length ? Object.keys(insuretechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!insuretechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{insuretechAnnexure[key].name ? insuretechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={insuretechAnnexure[key].value.replace(/<br>/g, "\n")} onChange={handleTNC} disabled={index2.tech_type_id == techTypeId && index2.status == 3 ? true : false}></textarea>
                </div>
              </div>
              {index2.tech_type_id == techTypeId && index2.status == 3 ? '' : <button type="button" className="delete-icon tnc-delete" onClick={() => removeInsureAnnexureElement(key)} ><i className="fa fa-trash"></i></button>}
            </div>
          )
        })}
      </div>
    )
  }

  function removeElement(key) {
    let tncdataElement = { ...dataTnc }
    delete tncdataElement[key]
    setdataTnc(tncdataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }

  function removeTradeAnnexureElement(key) {
    let tncdataElement = { ...tradetechAnnexure }
    delete tncdataElement[key]
    settradetechAnnexure(tncdataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }

  function removeFinAnnexureElement(key) {
    let tncdataElement = { ...fintechAnnexure }
    delete tncdataElement[key]
    setfintechAnnexure(tncdataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }

  function removeLogiAnnexureElement(key) {
    let tncdataElement = { ...logitechAnnexure }
    delete tncdataElement[key]
    setlogitechAnnexure(tncdataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }

  function removeInsureAnnexureElement(key) {
    let tncdataElement = { ...insuretechAnnexure }
    delete tncdataElement[key]
    setinsuretechAnnexure(tncdataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }

  function addElement() {
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    setdataTnc({ ...dataTnc, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }

  function addTradeAnnexureElement() {
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    settradetechAnnexure({ ...tradetechAnnexure, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }

  function addFinAnnexureElement() {
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    setfintechAnnexure({ ...fintechAnnexure, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }

  function addLogiAnnexureElement() {
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    setlogitechAnnexure({ ...logitechAnnexure, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }

  function addInsureAnnexureElement() {
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    setinsuretechAnnexure({ ...insuretechAnnexure, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }
  // console.log('dataTnc => ', dataTnc);
  // console.log('dataAnnexure1 => ', dataAnnexure1);
  // console.log('dataAnnexure2 => ', dataAnnexure2);
  function submitAgreement() {
    let techTypeId;
    if (data.tech_type_id) {
      let tech_type_id = data.tech_type_id.split(',');
      if (tech_type_id.length > 0) {
        if (channeltab === 0) {
          techTypeId = tech_type_id[0];
        }
        else if (channeltab === 1) {
          techTypeId = tech_type_id[1];
        }
        else if (channeltab === 2) {
          techTypeId = tech_type_id[2];
        }
        else if (channeltab === 3) {
          techTypeId = tech_type_id[3];
        }
      }
    }
    let reqObj = {
      "techTypeId": techTypeId, "baseUrl": config.baseUrl, "status": true, "userData": data, "reqId": reqId,
      "agreementFileHash": agreementTemplate.file_hash,
      party_designation: data.party_designation,
      company_name: data.company_name,
      contact_person: data.contact_person,
      organization_type: data.organization_type,
      role: dbData[0].userData.channel_partner_role,
      pan_no: dbData[0].userData.pan_no,
      aadhar_no: dbData[0].userData.aadhar_no,
      typeId: 20,
      country_code: dbData[0].userData.country_code,
      email_id: dbData[0].userData.email_id,
      address: dbData[0].userData.user_address
    }

    call('POST', 'submitAgreement', reqObj).then((result) => {
      if (result) {
        toastDisplay('Sussessfully submitted', 'success')
      }
    }).catch((e) => {
      console.log('error in submitAgreement', e);
    })
  }

  function handleInput(event) {
    event.persist()
    // console.log("eveneeeeeeeeeeeeeeeee", event.target.files[0], event.target.name);
    if (event.target.name === "doc") {
      setDoc({ ...doc, [event.target.name]: event.target.files[0] })
    }
    else {
      setDoc({ ...doc, [event.target.name]: event.target.value })
    }
  }

  // console.log("docccccccccccccccccccccccccccc", doc);

  function callPreviewFileComponent(values, action, showFromMst) {
    if (showFromMst) {
      setfileData({
        "action": action,
        "tbl_doc_id": values,
      })
    }
    else {
      setfileData({
        id: values.id,
        file_hash: values.file_hash,
        file_name: values.file_name,
        file_type: values.doc_name,
        created_at: values.created_at,
        "action": action
      })
    }
    toggleViewFileFromMst(!showFromMst)
    setshowPreviewModal(true)
  }

  function channelPartnerFormView(index, key) {
    if (index.tech_type_id == techTypeId && index.status == 4) {
      return (<div>
        <div className={"accordionItem mt-3" + (accord['1'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Company Profile</h2>
          <div className="accordionItemContent">
            <div className="col-md-12 row">
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">{(index && index.userData) ? index.userData.company_name : "--"}</label>
                  <div className="col-md-12 "><span className="date-format">Company Name</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">{(index && index.userData) ? index.userData.contact_person : "--"}</label>
                  <div className="col-md-12 "><span className="date-format">Contact Person</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">{(index && index.userData) ? index.userData.email_id : "--"}</label>
                  <div className="col-md-12 "><span className="date-format">Email</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">{(index && index.userData) ? index.userData.user_address : "--"}</label>
                  <div className="col-md-12 "><span className="date-format">Address</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">
                    {(index && index.userData && index.userData.country_code) ? <span className="shadow">
                      <ReactCountryFlag
                        countryCode={index.userData.country_code}
                        style={{ width: '25px', height: '25px' }} svg />
                    </span> : "--"}</label>
                  <div className="col-md-12 "><span className="date-format">Country</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">{(index && index.userData) ? index.userData.contact_number : "--"}</label>
                  <div className="col-md-12 "><span className="date-format">Contact No</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"accordionItem" + (accord['2'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Domain Facility</h2>
          < div className="accordionItemContent" >
            <div className="col-md-12 row">
              {index && index.userData && index.userData.domain_key.split(',').map((item, key) => {
                let domain_name = [];
                for (let i = 0; i < index.userTypeData.length; i++) {
                  if (parseInt(item) == parseInt(index.userTypeData[i].id)) {
                    if (index.userData.type_id / 1 == 20) {
                      domain_name.push(index.userData.channel_partner_role === 'CP' ? "Channel Partner" : "Franchise Partner")
                    }
                    else {
                      domain_name.push(index.userTypeData[i].usertype_pretty_name);
                    }
                    return (
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">{domain_name.join(', ')}</label>
                          <ul>
                            <li>
                              <img alt="description" height="60" src={"../assets/images/onbording/usertype/" + `${parseInt(item)}.png`} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  }
                }
              })}
            </div>
          </div>
        </div>

        {/* {(index && (index.userData.type_id === 14 || index.userData.type_id === 4)) &&
          <div className={"accordionItem" + (accord['3'] ? " open" : " closed")}>
            <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '3': !accord['3'] })}>Selected Commodities</h2>
            < div className="accordionItemContent" >
              <ul className="row p-0">
                {(index && index.commodities !== undefined) && index.commodities.map((commData) => {
                  return (
                    <>
                      <li className="col-12 col-md-6 col-lg-3">
                        <div className="cnt-block equal-hight">
                          <figure>
                            <img src={commImageUrl(commData.commodityInfo.filename)} className="img-responsive" alt="" />
                          </figure>
                          <h3>
                            {commData.commodityInfo ? commData.commodityInfo.commodity_pretty_name : '--'}
                          </h3>
                        </div>
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>
          </div>} */}

        {(index && index.docRelations && index.docRelations.length > 0) &&
          <div className={"accordionItem" + (accord['4'] ? " open" : " closed")}>
            <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '4': !accord['4'] })}>Documents</h2>
            <div className="accordionItemContent" >
              <ul className="row p-0">
                {(index && index.docRelations.length) && index.docRelations.map((docData) => {
                  return (
                    <>
                      <li className="col-12 col-md-6 col-lg-3">
                        <div className="cnt-block equal-hight">
                          <a onClick={
                            () => callPreviewFileComponent(docData.userDocs.id, "view", true)}
                            target="_blank">
                            <figure>
                              <i class={"fas text-primary fa-5x m-3 " + ((docData.userDocs.file_name.split(".")[1] === "pdf") ? "fa-file-pdf" : "fa-file-image")}></i>
                            </figure>
                          </a>
                          <h3>
                            {docData.userDocs.doc_name ? docData.userDocs.doc_name : '--'}
                          </h3>
                          <p className="date-format">
                            {/* {docData.docMaster.document_name ? docData.docMaster.document_name : '--'} */}
                          </p>
                        </div>
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>
          </div>}

        {/* {(dbData && dbData.bank && dbData.bank.length > 0) &&
          <div className={"accordionItem" + (accord['5'] ? " open" : " closed")}>
            <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '5': !accord['5'] })}>Bank Details</h2>
            <div className="accordionItemContent" >
              {(dbData && dbData.bank !== undefined) && dbData.bank.map((bankData) => {
                return (
                  <>
                    <div className="divider border-0">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.ac_no ? bankData.ac_no : "--"}</label>
                            <div className="col-md-12"><span className="date-format">Account Number</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.bank_name ? bankData.bank_name : "--"}</label>
                            <div className="col-md-12"><span className="date-format">Bank Name</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.acount_type ? bankData.acount_type : "--"}</label>
                            <div className="col-md-12"><span className="date-format">Account Type</span></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.account_alias ? bankData.account_alias : "--"}</label>
                            <div className="col-md-12"><span className="date-format">Account Name </span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.ifsc_code ? bankData.ifsc_code : "--"}</label>
                            <div className="col-md-12"><span className="date-format">IFSC Code</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.swift_code ? bankData.swift_code : "--"}</label>
                            <div className="col-md-12"><span className="date-format">SWIFT Code</span></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{bankData.beneficiary_name ? bankData.beneficiary_name : "--"}</label>
                            <div className="col-md-12"><span className="date-format">Beneficiary Name</span></div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </>
                )
              })}
            </div>
          </div>} */}
      </div>)
    }
    else {
      return (<>
        <div className={"accordionItem mt-3" + (accord['1'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Company Profile</h2>
          <div className="accordionItemContent">
            <div className="col-md-12 row">
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Company Name {astrix} </label>
                    <input disabled type="text" className={" form-control" + (!error.company_name ? '' : ' border-danger')} placeholder="Enter Company Name" name="company_name" value={data.company_name} onChange={handleChange} required
                    // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} 
                    />
                    {error.company_name && <p className="text-danger error-contract">{error.company_name}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Contact Person {astrix} </label>
                    <input disabled type="text" className={" form-control" + (!error.contact_person ? '' : ' border-danger')} placeholder="Enter Company Name" name="contact_person" value={data.contact_person} onChange={handleChange} required
                    // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false}
                    />
                    {error.contact_person && <p className="text-danger error-contract">{error.contact_person}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Email {astrix} </label>
                    <input disabled type="text" className={" form-control" + (!error.email ? '' : ' border-danger')} placeholder="Enter Company Name" name="email" value={data.email} onChange={handleChange} required
                    // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} 
                    />
                    {error.email && <p className="text-danger error-contract">{error.email}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Address {astrix} </label>
                    <input disabled type="text" className={" form-control" + (!error.address ? '' : ' border-danger')} placeholder="Enter Company Name" name="address" value={data.address} onChange={handleChange} required
                    // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} 
                    />
                    {error.address && <p className="text-danger error-contract">{error.address}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">
                    <div className="col-md-12 ">
                      <label>Country {astrix} </label>
                      <select disabled className={" form-control" + (!error.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange}
                      // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} 
                      >
                        <option value="" selected>Select Country</option>
                        {countryData.map((country) => {
                          return (
                            <option value={country.sortname} >{country.name}</option>
                          )
                        })}
                      </select>
                      {error.country && <p className="text-danger error-contract">{error.country}</p>}
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Contact No {astrix} </label>
                    <input disabled type="text" className={" form-control" + (!error.contact_number ? '' : ' border-danger')} placeholder="Enter Company Name" name="contact_number" value={data.contact_number} onChange={handleChange} required
                    // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false}
                    />
                    {error.contact_number && <p className="text-danger error-contract">{error.contact_number}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Entity {astrix} </label>
                    {/* <input type="text" className={" form-control" + (!error.organization_type ? '' : ' border-danger')} placeholder="Enter Company Name" name="organization_type" value={data.organization_type} onChange={handleChange} required disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} /> */}
                    <select
                      // disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false}
                      disabled
                      className="form-control" name="organization_type" value={data.organization_type}
                      onChange={handleChange}>
                      <option value="" selected>Select Organization Type</option>
                      <option value="individual">Individual</option>
                      <option value="soleProprietorship">Sole Proprietorship</option>
                      <option value="partnership">Partnership</option>
                      <option value="pvtPubLtd">PVT/PUB.LTD</option>
                      <option value="llp">LLP</option>
                    </select>
                    {error.organization_type && <p className="text-danger error-contract">{error.organization_type}</p>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <div className="col-md-12 ">
                    <label>Designation {astrix} </label>
                    <select
                      className="form-control" name="party_designation" value={data.party_designation}
                      onChange={handleChange}>
                      <option value="" selected>--Select Designation--</option>
                      <option value={"Proprietor"}>{"Proprietor"}</option>
                      <option value={"Partner"}>{"Partner"}</option>
                      <option value={"Director"}>{"Director"}</option>
                    </select>
                    {error.party_designation && <p className="text-danger error-contract">{error.party_designation}</p>}
                  </div>
                </div>
              </div>
              {/* {data.country === 'IN' && data.organization_type === 'Individual' && <>
                <div className="col-md-4">
                  <div className="row form-group">
                    <div className="col-md-12 ">
                      <label>PAN Number {astrix} </label>
                      <input type="text" className={" form-control" + (!error.panDocumentName ? '' : ' border-danger')} placeholder="Enter Company Name" name="panDocumentName" value={data.panDocumentName} onChange={handleChange} required disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} />
                      {error.panDocumentName && <p className="text-danger error-contract">{error.panDocumentName}</p>}
                    </div>
                  </div>
                </div>
              </>}
              {data.country === 'IN' && data.organization_type !== 'Individual' && <>
                <div className="col-md-4">
                  <div className="row form-group">
                    <div className="col-md-12 ">
                      <label>PAN Number {astrix} </label>
                      <input type="text" className={" form-control" + (!error.panDocumentName ? '' : ' border-danger')} placeholder="Enter Company Name" name="panDocumentName" value={data.panDocumentName} onChange={handleChange} required disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} />
                      {error.panDocumentName && <p className="text-danger error-contract">{error.panDocumentName}</p>}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row form-group">
                    <div className="col-md-12 ">
                      <label>GST Number {astrix} </label>
                      <input type="text" className={" form-control" + (!error.gstDocumentName ? '' : ' border-danger')} placeholder="Enter Company Name" name="gstDocumentName" value={data.gstDocumentName} onChange={handleChange} required disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} />
                      {error.gstDocumentName && <p className="text-danger error-contract">{error.gstDocumentName}</p>}
                    </div>
                  </div>
                </div>
              </>}
              {data.country === 'IN' && data.organization_type === 'Pvt / Pub Ltd' && <>
                <div className="col-md-4">
                  <div className="row form-group">
                    <div className="col-md-12 ">
                      <label>CIN Number {astrix} </label>
                      <input type="text" className={" form-control" + (!error.cinDocumentName ? '' : ' border-danger')} placeholder="Enter Company Name" name="cinDocumentName" value={data.cinDocumentName} onChange={handleChange} required disabled={index.tech_type_id == techTypeId && index.status == 3 ? true : false} />
                      {error.cinDocumentName && <p className="text-danger error-contract">{error.cinDocumentName}</p>}
                    </div>
                  </div>
                </div>
              </>} */}
            </div>
          </div>
        </div>

        <div className={"accordionItem mt-3" + (accord['2'] ? " open" : " closed")}>
          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Agreement Template</h2>
          <div className="accordionItemContent">
            <div className='row my-4'>
              <div className='col-md-4'>
                <input type="text" className={" form-control"} disabled placeholder={"Enter document name"} name={"docName"}
                  value={doc.docName} onChange={handleInput} />
              </div>

              <div className='col-md-4'>
                {/* {doc.docName ? null : ( */}
                {!agreementTemplate.file_hash ? (
                  <div className="file-browse ">
                    <button className="btn btn-primary btn-sm">Select Document</button>
                    <input type="file" accept=".pdf" name={"doc"} onChange={handleInput} />
                  </div>
                ) : null}
                {/* )} */}
                {((doc.doc && doc.doc.name) || agreementTemplate.file_name) ?
                  <div className="form-group mb-0"><div className="file-name mt-2">{`${agreementTemplate.file_name || doc.doc.name}`}</div></div> : ""}
              </div>

              <div className='col-md-2'>
                {agreementTemplate.file_name ? (
                  <>
                    <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                      () => callPreviewFileComponent(agreementTemplate, "view", false)}>
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    </button>
                    <button title="Delete File" className="text-center btn btn-danger btn-sm m-1" onClick={
                      () => deleteDoc()}>
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </>
                ) : (<button disabled={!(doc.doc && doc.doc.name && doc.docName)}
                  onClick={() => submitDoc()} className="btn btn-primary btn-sm">Submit</button>
                )}
              </div>
            </div>

          </div>
        </div>

        {/* <div className={"accordionItem"}>
          <h2 className="accordionItemHeading">Terms & Conditions</h2>
          <div className="accordionItemContent">
            <div className="col-md-12 row">
              {tncBodyGenerator(index)}
            </div>
          </div>
          {index.tech_type_id == techTypeId && index.status == 3 ? '' :
            <div className="col-md-12 pb-3">
              <h3 className="col-md-12">Add New TNC</h3>
              <div className="col-md-12">
                <label className="">TNC Name/Title</label>
                <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} />
              </div>
              <div className="col-md-12 mt-2 mb-2">
                <label className="">TNC Value/Text</label>
                <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea>
              </div>
              {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="btn-primary btn ml-2 btn-sm col-md-2" onClick={() => addElement()} >Add +</button>}
            </div>}
        </div> */}

        {/* {index.tech_type_id == 4 &&
          <div className={"accordionItem"}>
            <h2 className="accordionItemHeading">ANNEXURE A</h2>
            <div className="accordionItemContent">
              <div className="col-md-12 row">
                {tradeAnnexureBody(index)}
              </div>
            </div>
            {index.tech_type_id == techTypeId && index.status == 3 ? '' :
              <div className="col-md-12 pb-3">
                <h3 className="col-md-12">Add New Annexure 1</h3>
                <div className="col-md-12">
                  <label className="">New Annexure Name/Title</label>
                  <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} />
                </div>
                <div className="col-md-12 mt-2 mb-2">
                  <label className="">New Annexure Value/Text</label>
                  <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea>
                </div>
                {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="btn-primary btn ml-2 btn-sm col-md-2" onClick={() => addTradeAnnexureElement()} >Add +</button>}
              </div>}
          </div>}

        {index.tech_type_id == 5 &&
          <div className={"accordionItem"}>
            <h2 className="accordionItemHeading">ANNEXURE A</h2>
            <div className="accordionItemContent">
              <div className="col-md-12 row">
                {finAnnexureBody(index)}
              </div>
            </div>
            {index.tech_type_id == techTypeId && index.status == 3 ? '' :
              <div className="col-md-12 pb-3">
                <h3 className="col-md-12">Add New Annexure 2</h3>
                <div className="col-md-12">
                  <label className="">New Annexure Name/Title</label>
                  <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} />
                </div>
                <div className="col-md-12 mt-2 mb-2">
                  <label className="">New Annexure Value/Text</label>
                  <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea>
                </div>
                {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="btn-primary btn ml-2 btn-sm col-md-2" onClick={() => addFinAnnexureElement()} >Add +</button>}
              </div>}
          </div>} */}

        {/* {index.tech_type_id == 6 &&
          <div className={"accordionItem"}>
            <h2 className="accordionItemHeading">ANNEXURE A</h2>
            <div className="accordionItemContent">
              <div className="col-md-12 row">
                {logiAnnexureBody(index)}
              </div>
            </div>
            {index.tech_type_id == techTypeId && index.status == 3 ? '' :
              <div className="col-md-12 pb-3">
                <h3 className="col-md-12">Add New Annexure 2</h3>
                <div className="col-md-12">
                  <label className="">New Annexure Name/Title</label>
                  <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} />
                </div>
                <div className="col-md-12 mt-2 mb-2">
                  <label className="">New Annexure Value/Text</label>
                  <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea>
                </div>
                {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="btn-primary btn ml-2 btn-sm col-md-2" onClick={() => addLogiAnnexureElement()} >Add +</button>}
              </div>}
          </div>} */}

        {/* {index.tech_type_id == 7 &&
          <div className={"accordionItem"}>
            <h2 className="accordionItemHeading">ANNEXURE A</h2>
            <div className="accordionItemContent">
              <div className="col-md-12 row">
                {insureAnnexureBody(index)}
              </div>
            </div>
            {index.tech_type_id == techTypeId && index.status == 3 ? '' :
              <div className="col-md-12 pb-3">
                <h3 className="col-md-12">Add New Annexure 2</h3>
                <div className="col-md-12">
                  <label className="">New Annexure Name/Title</label>
                  <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} />
                </div>
                <div className="col-md-12 mt-2 mb-2">
                  <label className="">New Annexure Value/Text</label>
                  <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea>
                </div>
                {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="btn-primary btn ml-2 btn-sm col-md-2" onClick={() => addInsureAnnexureElement()} >Add +</button>}
              </div>}
          </div>} */}
      </>)
    }
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const setUserStatus = (status) => {
    setshowLoader(true)
    let userData = {
      "status": status,
      "userId": showViewProfileModal.info.id,
      "tech_type_id": showViewProfileModal.info.tech_type_id,
      "baseUrl": config.baseUrl
    }
    call('POST', 'setUserStatus', userData).then((result) => {
      setshowLoader(false)
      if (result) {
        setfileData({
          "action": "view",
          "tbl_doc_id": result,
        })
        setshowPreviewModal(true)
      }
      toastDisplay('Successfully Added', 'success');
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in setUserStatus', e);
    })
  }

  const handleTNC = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let tncdataElement = { ...dataTnc }
    tncdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setdataTnc(tncdataElement);
    seterrorData(errorData => ({ ...errorData, "tnc": "" }))
    setEditCount(editCount + 1)
    settncChange(tncChange + 1)
  }

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const preHandleSubmit = (index, event) => {
    setReqId(index.id);
    if (event) event.preventDefault();
    setError(validate(data, false));
    setIsSubmitting(true);
  };

  const handlenewTnc = (event) => {
    event.persist();
    setnewTnc(newTnc => ({ ...newTnc, [event.target.name]: event.target.value }));
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Retun
  return (
    <>
      <ul className="nav nav-tabs-custom mt-2" id="myTab" role="tablist">
        {dbData && dbData.length > 0 && dbData.map((index, key) => {
          return (<li>
            <a className={"nav-link" + (channeltab === key ? " active show" : "")} onClick={() => { setChannelTab(key); setTechTypeId(index.tech_type_id) }}>
              {index.tech_type_id == 4 ? 'Trade-Tech' : index.tech_type_id == 5 ? 'Fin-Tech' : index.tech_type_id == 6 ? 'Logi-Tech' : index.tech_type_id == 7 ? 'Insure-Tech' : ''}
            </a>
          </li>)
        })}
      </ul>
      <div className="d-flex">
        {dbData && dbData.length > 0 && dbData.map((index, key) => {
          if (channeltab == key) {
            return (
              <div className="col-md-12 pt-2 pb-5 mt-5">
                <h1 className="text-center"><u>Party Agreement of {index.userData.company_name}</u></h1><br />
                {channelPartnerFormView(index, key)}
              </div>
            )
          }
        })}
      </div>
      {showPreviewModal && <FilePreview
        mktPlaceShipment={viewFileFromMstTable}
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false} />}

      <hr className="pt-2 pb-2 text-primary" />
      {dbData && dbData.length > 0 && dbData.map((index, key) => {
        if (channeltab == key) {
          if (index.tech_type_id == techTypeId && index.status == 3) {
            return (
              <button type="button" className="btn btn-info float-right btn-sm" disabled>
                Already Submitted
              </button>
            )
          }
          else if (index.tech_type_id == techTypeId && index.status != 4) {
            return (
              <button type="button"
                disabled={!agreementTemplate.file_hash}
                className="btn btn-primary float-right btn-sm" onClick={() => preHandleSubmit(index)}>
                Submit
              </button>
            )
          }
        }
      })}
    </>
  )
}



const mapStateToProps = state => {
  return {
    showViewProfileModal: state.showViewProfileModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerProfileDetails)