const columns = [
  {
    name: 'Lender Name',
    selector: 'lendername',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Finance Amount',
    selector: 'approvedFinanceAmount',
    sortable: true,
  },
  {
    name: 'Funding Percentage',
    selector: 'fundingPercentage',
    sortable: true,
  },
  {
    name: 'Funding Tenor',
    selector: 'fundingTenor',
    sortable: true,
  },
  {
    name: 'Interest Rate',
    selector: 'interestRate',
    sortable: true,
  },
  {
    name: 'Interest Collection',
    selector: 'interestCollection',
    sortable: true,
  },
  {
    name: 'Finance Request(%)',
    selector: 'finRequest',
    sortable: true,
  },
  {
    name: 'Additional Fees',
    selector: 'additionalFee',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'description',
    grow: 2,
    sortable: true,
  },
  {
    name: 'Last Fee Charges',
    selector: 'lastfeeCharges',
    sortable: true,
  },
  // {
  //   name: 'Platform Fee',
  //   selector: 'platformFee',
  //   sortable: true,
  // },
  {
    name: 'Offer Valid Till',
    selector: 'credit_validity',
    sortable: false,
    grow: 2
  },
  {
    name: 'Status',
    selector: 'status',
    grow: 2,
    sortable: false,
    cell: row =>
      (row.status === 'Credit Line Created') ? "Credit Line Created" :
        (row.status === 'User Approval Pending') ? "User Approval Pending" :
          (row.status === 'Admin Revision') ? "Admin Revision" :
            (row.status === 'User Rejected') ? "User Rejected" :
              (row.status === 'User Approved') ? "User Approved" :
                (row.status === 'Admin Approved') ? "Admin Approved" : "NA",
    conditionalCellStyles: [
      {
        when: row => (row.status === 'Credit Line Created'),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'User Approval Pending'),
        style: {
          backgroundColor: 'rgba(255, 150, 0, 0.9)',  //orange
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'Admin Revision'),
        style: {
          backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'User Rejected'),
        style: {
          backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'User Approved'),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      },
      {
        when: row => (row.status === 'Admin Approved'),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      }
    ]
  }
];

export default columns