import React, { useEffect, useState } from 'react';
import call from "../../../service";
import { connect } from "react-redux";
import { setFinanceDetails } from '../../../store/actions/action';
import toastDisplay from "../../../utils/toastNotification";
import toolTipText from '../../../utils/toolTips/financeForm.json'
import { dateFormatter_DatePicker, formatDate_Application } from '../../../utils/dateFormaters';
import FilePreview from '../../utilComponents/previewFile';
import DigitalSignDoc from '../../contractDocument/digitalSignDoc';
import validate from '../../../utils/validation_files/myOffTradeFinance.js'
import ReactCountryFlag from "react-country-flag";


const MyTradeOnline = ({ userTokenDetails, setFinanceDetails, financeDetails, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // Const State and their handlers declaration and initiation.
  const [bankMaster, setbankMasters] = useState([]);
  const [buyerArr, setBuyerArr] = useState([]);
  const [contractDirData, setcontractDirData] = useState([]);
  const buyerType = 1;
  const [currencyData, setcurrencyData] = useState([]);
  const [data, setData] = useState({});
  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [refresh, setrefresh] = useState(0);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [fileData, setfileData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [docData, setDocData] = useState([]);
  const [creditData, setCreditData] = useState([]);


  const [accord, setAccord] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false
  });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    if (financeDetails.info.action === "viewFinance") {
      setshowLoader(true)
      call('POST', 'getDiscounting', { id: financeDetails.info.FinanceID, type: financeDetails.type }).then((result) => {
        console.log('mytrade Get Discounting API =====>', result);

        let temp = result.finData.offline_data.dirCurrency
        temp = temp.split(":")
        temp.shift();

        // console.log('temp', temp.join(":"));

        result.finData.offline_data.dirCurrency = temp.join(":")

        let poDbData = {
          id: result.finData.id,
          contractAttach: result.finData.contract_id,
          buyerId: result.finData.buyer_id / 1 === 0 ? "ME" : result.finData.buyer_id,
          sellerId: result.finData.seller_id,
          applicantEmail: userEmail,
          amount: result.finData.amount,
          creditDays: result.finData.credit_days,
          interestRate: result.finData.interest_rate,
          lenderId: result.finData.lender_id,
          status: result.finData.status,
          sellerStatus: result.finData.seller_status,
          lenderStatus: result.finData.lender_status,
          dnc: "on",
          fintnc: result.finData.fin_tnc,
          buyerNameManual: result.finData.offline_data.buyer_name,
          buyerAddrManual: result.finData.offline_data.buyer_addr,
          lenderName: result.finData.lenderName,
          buyerName: result.finData.buyerName,
          ...result.finData.offline_data
        }

        setbankMasters([{
          id: result.finData.lender_id,
          company_name: result.finData.lenderName
        }]);

        setAmount(result.finData.amount)
        setData({ ...poDbData, userId: userId })

        setDocData(result.docArray)
        setAccord({
          0: true,
          1: true,
          2: true,
          3: true,
          4: true,
          5: true
        })

        call('POST', 'getCountryPort', { 'countryId': result.finData.offline_data.dirLoadingCountry.split(':')[1] }).then((result) => {
          setPortLoading(result)
        }).catch((e) => {
          console.log('error in getCountryPort loadPort', e);
        })

        call('POST', 'getCountryPort', { 'countryId': result.finData.offline_data.dirUnloadingCountry.split(':')[1] }).then((result) => {
          setPortUnloading(result)
        }).catch((e) => {
          console.log('error in getCountryPort loadPort', e);
        })

        if (userTypeId / 1 === 8) {
          call("post", "getfincontractselectlist", { type: 2, userId: result.finData.seller_id }).then((result) => {
            setcontractDirData(result);
            setshowLoader(false)
          }).catch((e) => {
            console.log("error in getfincontractselectlist", e);
          });
        }

        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log('error in getDiscounting', e);
      })



    } else {
      setshowLoader(true)
      setData({ ...data, userId: userId, applicantEmail: userEmail });
    }

    // Get currency list from Master table.
    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    if (userTypeId / 1 !== 8) {
      call("post", "getfincontractselectlist", { userId: "", type: 2 }).then((result) => {
        // console.log("result in getfincontractselectlist", result);
        setcontractDirData(result);
        setshowLoader(false)
      }).catch((e) => {
        console.log("error in getfincontractselectlist", e);
      });
    }

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    call('POST', 'getUsersListing', { "searchUserType": 3, "searchKey": '', "network": "innetwork", "userAvatars": false })
      .then((result) => {
        console.log("result in getUsersListing", result);
        setBuyerArr(result);
        setshowLoader(false)
      })
      .catch((e) => {
        console.log("error in getUsersListing", e);
      });

    if (financeDetails.info.action === "applyFinance") {
      // Get bank master list that are involved in trade finance.
      call("GET", "getBanksListMaster")
        .then((result) => {
          setbankMasters(result);
        })
        .catch((e) => {
          console.log("Error while querying getBanksListMaster:", e);
        });
    }

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "userapproved"
    }

    objectAPI = { ...objectAPI }


    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setCreditData(result.finData);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      forward()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // console.log("data====>", data)
  // console.log("file====>", file)
  // console.log("error====>", error)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  const handleChange = (event) => {
    event.persist();

    if (event.target.name === "dnc") {
      if (data.dnc === "on") {
        setData({ ...data, "dnc": "" });
      } else {
        setData({ ...data, [event.target.name]: event.target.value });
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
      setError({ ...error, [event.target.name]: "" });
    }
  };

  // const handleFiles = (event) => {
  //   event.persist();
  //   setFile({ ...file, [event.target.name]: event.target.files[0] });
  //   setError({ ...error, [event.target.name]: "" });
  // };

  const prehandleForward = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, file, financeDetails, userTypeId));
    setIsSubmitting(true);
  };

  const handleContractAttach = (event) => {
    event.persist();

    call("post", "getContractDetailFinance", { contractId: event.target.value }).then((result) => {
      console.log("result in getdircontractdetails", result);

      let docString = []
      if (result.docArray.length) {
        setDocData(result.docArray)

        for (let i = 0; i < result.docArray.length; i++) {
          docString.push(result.docArray[i].id)
        }
      }

      setData({
        ...data,
        dirCurrency: result.contData.currency,
        dirContractNo: result.contData.contract_number,
        dirContractName: result.contData.contract_name,
        dirContractType: result.contData.contract_type,
        buyerId: result.contData.importer_id,
        dirLaycanStart: dateFormatter_DatePicker(result.contData.laycan_start),
        dirLaycanEnd: dateFormatter_DatePicker(result.contData.laycan_end),
        dirCommodityCategory: result.contData.category,
        dirCommodity: result.contData.commodity_pretty_name,
        dirCommCountryOrigin: result.contData.load_country.split(":")[0],
        dirQuantity: result.contData.quantity,
        dirPrice: result.contData.price,
        dirInvoicePrice: result.contData.total_amount ? result.contData.total_amount : (result.contData.price / 1) * result.contData.quantity,
        dirLoadingCountry: result.contData.load_country,
        dirLoadingPort: result.contData.loading_port,
        dirUnloadingCountry: result.contData.unload_country,
        dirUnloadingPort: result.contData.unloading_port,
        dirShipper: result.contData.shipper_name,
        dirVessel: result.contData.qwerewq,
        dirBLNumber: result.contData.qwerewq,
        dirBLDate: result.contData.qwerewq,
        buyerName: result.contData.buyer_name,
        dir_doc_array: docString.join(),
        contractAttach: event.target.value / 1,
      });

      setAccord({
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true
      })

      call('POST', 'getCountryPort', { 'countryId': result.contData.load_country.split(':')[1] }).then((result) => {
        setPortLoading(result)
      }).catch((e) => {
        console.log('error in getCountryPort loadPort', e);
      })

      call('POST', 'getCountryPort', { 'countryId': result.contData.unload_country.split(':')[1] }).then((result) => {
        setPortUnloading(result)
      }).catch((e) => {
        console.log('error in getCountryPort loadPort', e);
      })

    }).catch((e) => {
      console.log("error in getdircontractdetails", e);
    });

    handleChange(event)

  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  // Handler for next button.
  function forward() {
    setData({
      ...data,
      buyerName: data.buyerName ? data.buyerName : getSelectName("select_buyerId", data.buyerId),
      lenderName: data.lenderName ? data.lenderName : getSelectName("select_lenderId", data.lenderId),
    });
    setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) + 1, financeDetails.info)
  }

  // Handler for back button.
  function backward() {
    if (financeDetails.page === 1) {
      if (financeDetails.info.action === "viewFinance") {
        setFinanceDetails(false, 0, 0, {})
      } else {
        setFinanceDetails(financeDetails.status, 0, 0, financeDetails.info)
      }
    } else if (financeDetails.page === 2 && financeDetails.info.sellerStatus === 2 && financeDetails.info.lenderStatus === 2) {
      setFinanceDetails(false, 0, 0, {})
    } else {
      setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) - 1, financeDetails.info)
      setIsSubmitting(false)
    }
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      // console.log("value in doc render=>", values)
      values.tbl_doc_id = values.id
      values.document_name = values.doc_name
      values.uploaded_by = values.createdBy
      values.uploaded_on = values.created_at
      values.signType = 'withoutBlockChain'

      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.doc_name) ? values.doc_name : values.name ? values.name : 'NA'}</td>
        {/* <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td> */}
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td>
          {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
        </td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={() => callAclAuthComponent(values)}>
            <i class="fas fa-file-signature"></i>
          </button>


        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }

  function getSelectName(id, value) {
    let sel = document.getElementById(id);
    if (sel) {
      for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].value / 1 === value / 1) {
          return sel.options[i].text
        }
      }
    } else {
      return '--'
    }

  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Submit handlers

  const submitFinanceForm = (event) => {

    setshowLoader(true)
    data.sellerId = userId
    data.finType = 2

    for (let i = 0; i < currencyData.length; i++) {
      if ((currencyData[i].name + ":" + currencyData[i].symbol + ":" + currencyData[i].code) === data.dirCurrency) {
        data.dirCurrency = currencyData[i].id + ":" + data.dirCurrency
      }
    }


    call("post", "applymytradeofflinefinance", data)
      .then((result) => {
        setshowLoader(false)
        toastDisplay(result, "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in create finance modal", e);
      });
  };

  // Accept  Discounting Call
  function acceptDiscounting(party) {
    call("POST", "acceptmytradeofflinefinance", { party: userTypeId, id: data.id })
      .then((result) => {
        toastDisplay("Request Accepted successfuly", "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        toastDisplay(e, "error");
        console.log("error in acceptPO", e);
      });
  }

  // Update Finance form
  function updateDiscounting() {

    setshowLoader(true)


    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("amount", data.amount);
    formData.append("creditDays", data.creditDays);
    formData.append("interestRate", data.interestRate);
    formData.append("fintnc", data.fintnc);

    call("POST", "updatemytradeofflinefinance", formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay("Updated successfuly", "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in updatePoDiscounting", e);
      });
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {(financeDetails.status && financeDetails.type === 5 && financeDetails.page === 1) ?
        <div className="col-md-12 shadow bg-light pt-2 pb-5">
          {/* <h1 className="text-center"><u>Purchase Order Form</u></h1><br /> */}
          <div className="row">

            <div className="col-md-6">
              <div className="row form-group">
                <label className="col-md-12">Applicant  {astrix} {toolTip('buyerId')}</label>
                <div className="col-md-12">
                  <input type="text" className="form-control" name="applicant" value={data.applicantEmail} readOnly />
                  {error.applicantEmail ? <div class="text-danger error-contract">{error.applicantEmail}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row form-group">
                <label className="col-md-12">Select Contract {toolTip('contractAttach')}</label>
                <div className="col-md-12">
                  <select className={"form-control" + (error.contractAttach ? " border-danger" : "")} name="contractAttach" value={data.contractAttach} onChange={(event) => { handleContractAttach(event) }} disabled={financeDetails.info.action === "viewFinance"}>
                    <option value="" selected>Not Available</option>
                    {contractDirData.map((dataCDD) => {
                      return (
                        <option value={dataCDD.id} >{dataCDD.contract_name + " ( " + dataCDD.contract_number + " )"}</option>
                      )
                    })}
                  </select>
                  {error.contractAttach ? <div class="text-danger error-contract">{error.contractAttach}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="row form-group">
                <label className="col-md-12">Currency  {astrix} {toolTip('loanCurrency')}</label>
                <div className="col-md-12 ">
                  <select className={"form-control" + (error.dirCurrency ? " border-danger" : "")} name="dirCurrency" value={data.dirCurrency} onChange={handleChange} disabled>
                    <option value="">--Select Currency--</option>
                    {currencyData.map((currency) => {
                      return (
                        <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                      )
                    })}
                  </select>
                  {error.dirCurrency ? <div class="text-danger error-contract">{error.dirCurrency}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row form-group">
                <label className="col-md-12">Requested Amount {astrix}  {toolTip('loanAmount')}</label>
                <div className="col-md-12">
                  <input className={"form-control" + (error.amount ? " border-danger" : "")} type="number" step="0.01" name="amount" value={data.amount} placeholder="Enter Finance Amount" onChange={handleChange} />
                  {error.amount ? <div class="text-danger error-contract">{error.amount}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row form-group">
                <label className="col-md-12">Select Lender  {astrix} {toolTip('lenderId')}</label>
                <div className="col-md-12">
                  <select className={"form-control" + (error.lenderId ? " border-danger" : "")} name="lenderId" id="select_lenderId" value={data.lenderId} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                    <option value="">--Select Lender--</option>
                    {creditData.map((bank) => {
                      return (
                        <option value={bank.id} >{bank.lendername}</option>
                      )
                    })}
                  </select>
                  <br />
                  {creditData.map((bank) => {
                    if (data.lenderId == bank.id) {
                      return (
                        <p>   Approved Finance Amount: {bank.approvedFinanceAmount}</p>
                      )
                    }
                  })
                  }
                  {error.lenderId ? <div class="text-danger error-contract">{error.lenderId}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Interest Rate (<small>To be filled by Banker</small>){astrix}  {toolTip('interestRate')}</label>
                <div className="col-md-12">
                  <input className={"form-control" + (error.interestRate ? " border-danger" : "")} type="number" name="interestRate" value={data.interestRate} placeholder="Enter Interest rate" onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2} />
                  {error.interestRate ? <div class="text-danger error-contract"> {error.interestRate} </div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Finance Credit Days {astrix}  {toolTip('loanCreditDays')}</label>
                <div className="col-md-12">
                  <input className={"form-control" + (error.creditDays ? " border-danger" : "")} type="number" name="creditDays" value={data.creditDays} placeholder="Enter Finance Credit Days" onChange={handleChange} />
                  {error.creditDays ? <div class="text-danger error-contract"> {error.creditDays} </div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row form-group">
                <label className="col-md-12">Finance TNC (<small>To be filled by Banker</small>) {astrix}  {toolTip('fintnc')}</label>
                <div className="col-md-12">
                  <textarea rows="4" className={"form-control" + (error.fintnc ? " border-danger" : "")} name="fintnc" value={data.fintnc} onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2}></textarea>
                  {error.fintnc ? <div class="text-danger error-contract"> {error.fintnc} </div> : ''}
                </div>
              </div>
            </div>

            <hr />

            {creditData.map((bank) => {
              if (data.lenderId == bank.id) {
                return (
                  <div className={"accordionItem mt-3" + (accord['0'] ? " open" : " closed")}>
                    <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Credit Line Details</h2>
                    <div className="accordionItemContent">
                      <div className="col-md-12 row">

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Requested Amount</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.credit_amount} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Approved Finance Amount</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.approvedFinanceAmount} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Funding Percentage</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.fundingPercentage + '%'} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Funding Tenor</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.fundingTenor} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Due Date Calculation From</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.duedateCalcFrom} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Interest Rate</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.interestRate + '%'} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Interest Collection</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.interestCollection} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Finance Request Percentage</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.finRequestPercentage + '%'} readOnly={true} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Finance Request Collection</label>
                            <div className="col-md-12">
                              <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.finRequestCollection} readOnly={true} />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )
              }
            })
            }

            <div className={"accordionItem mt-3" + (accord['1'] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Contract Details</h2>
              <div className="accordionItemContent">
                <div className="col-md-12 row">

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Contract Number  {astrix} {toolTip('dirContractNo')}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={data.dirContractNo} onChange={handleChange} readOnly={true} />
                        {error.dirContractNo ? <div class="text-danger error-contract">{error.dirContractNo}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Contract Name  {astrix} {toolTip('dirContractName')}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.dirContractName ? " border-danger" : "")} name="dirContractName" value={data.dirContractName} onChange={handleChange} readOnly={true} />
                        {error.dirContractName ? <div class="text-danger error-contract">{error.dirContractName}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Contract Type {astrix}  {toolTip('dirContractType')}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.dirContractType ? " border-danger" : "")} name="dirContractType" value={data.dirContractType} onChange={handleChange} readOnly={true} />
                        {error.dirContractType ? <div class="text-danger error-contract">{error.dirContractType}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="row form-group">
                      <label className="col-md-12">Data Line {toolTip('dirDataLine')}</label>
                      <div className="col-md-12">
                        <select className="form-control" name="buyerType" value={buyerType} disabled={true}>
                          <option value="1" selected>Network</option>
                          <option value="2">Directory</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row form-group">
                      <label className="col-md-12">Select Buyer {astrix}  {toolTip('buyerId')}</label>
                      <div className="col-md-12">
                        <select className={"form-control" + (error.buyerId ? " border-danger" : "")} name="buyerId" id="select_buyerId" value={data.buyerId} onChange={handleChange} disabled={true}>
                          <option value="" selected>--Select Buyer--</option>
                          {buyerArr.map((buyer) => {
                            return (
                              <option value={buyer.id} >{buyer.company_name}</option>
                            )
                          })}
                        </select>
                        {error.buyerId ? <div class="text-danger error-contract">{error.buyerId}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Laycan Start {astrix}  {toolTip('dirLaycanStart')}</label>
                      <div className="col-md-12">
                        <input type="date" name="dirLaycanStart" value={data.dirLaycanStart} className={"form-control" + (error.dirLaycanStart ? " border-danger" : "")} onChange={handleChange} readOnly={true} />
                        {error.dirLaycanStart ? <div class="text-danger error-contract">{error.dirLaycanStart}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Laycan End {astrix}  {toolTip('dirLaycanEnd')}</label>
                      <div className="col-md-12">
                        <input type="date" name="dirLaycanEnd" value={data.dirLaycanEnd} className={"form-control" + (error.dirLaycanEnd ? " border-danger" : "")} onChange={handleChange} readOnly={true} />
                        {error.dirLaycanEnd ? <div class="text-danger error-contract">{error.dirLaycanEnd}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"accordionItem" + (accord['2'] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Commodity Details</h2>
              < div className="accordionItemContent" >
                <div className="col-md-12 row">

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Commodity Category {astrix}  {toolTip('dirCommodityCategory')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirCommodityCategory ? " border-danger" : "")} type="text" name="dirCommodityCategory" value={data.dirCommodityCategory} onChange={handleChange} readOnly={true} />
                        {error.dirCommodityCategory ? <div class="text-danger error-contract">{error.dirCommodityCategory}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Commodity {astrix}  {toolTip('dirCommodity')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirCommodity ? " border-danger" : "")} type="text" name="dirCommodity" value={data.dirCommodity} onChange={handleChange} readOnly={true} />
                        {error.dirCommodity ? <div class="text-danger error-contract">{error.dirCommodity}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group flag-relative" id="POScountry">
                      <div className="flag-icon">
                        {data.dirCommCountryOrigin ? <span className="shadow">
                          <ReactCountryFlag
                            countryCode={data.dirCommCountryOrigin}
                            style={{ width: '25px', height: '25px' }} svg />
                        </span> :
                          <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image" />}
                      </div>
                      <label>Country of Origin {astrix}  </label>
                      <select className={"form-control" + (error.dirCommCountryOrigin ? " border-danger" : "")} name="dirCommCountryOrigin" value={data.dirCommCountryOrigin} onChange={handleChange} readOnly={true} >
                        <option value="" selected>Select Country</option>
                        {countryData.map((country) => {
                          return (
                            <option value={country.sortname} >{country.name}</option>
                          )
                        })}
                      </select>
                      {error.dirCommCountryOrigin ? <div class="text-danger error-contract">{error.dirCommCountryOrigin}</div> : ''}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Quantity (mt){astrix}  {toolTip('dirQuantity')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirQuantity ? " border-danger" : "")} type="text" name="dirQuantity" value={data.dirQuantity} onChange={handleChange} readOnly={true} />
                        {error.dirQuantity ? <div class="text-danger error-contract">{error.dirQuantity}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="row form-group">
                      <label className="col-md-12">Currency {astrix}  {toolTip('dirCurrency')}</label>
                      <div className="col-md-12 ">
                        <select className={"form-control" + (error.dirCurrency ? " border-danger" : "")} name="dirCurrency" value={data.dirCurrency} onChange={handleChange} disabled={true}>
                          <option value="">--Select Currency--</option>
                          {currencyData.map((currency) => {
                            return (
                              <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                            )
                          })}
                        </select>
                        {error.dirCurrency ? <div class="text-danger error-contract">{error.dirCurrency}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row form-group">
                      <label className="col-md-12">Price/Unit {astrix}  {toolTip('dirPrice')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirPrice ? " border-danger" : "")} type="text" name="dirPrice" value={data.dirPrice} onChange={handleChange} readOnly={true} />
                        {error.dirPrice ? <div class="text-danger error-contract">{error.dirPrice}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="row form-group">
                      <label className="col-md-12">Currency {astrix}  {toolTip('dirCurrency')}</label>
                      <div className="col-md-12 ">
                        <select className={"form-control" + (error.dirCurrency ? " border-danger" : "")} name="dirCurrency" value={data.dirCurrency} onChange={handleChange} disabled>
                          <option value="">--Select Currency--</option>
                          {currencyData.map((currency) => {
                            return (
                              <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                            )
                          })}
                        </select>
                        {error.dirCurrency ? <div class="text-danger error-contract">{error.dirCurrency}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row form-group">
                      <label className="col-md-12">Total Invoice value {astrix}  {toolTip('dirInvoicePrice')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirInvoicePrice ? " border-danger" : "")} type="text" name="dirInvoicePrice" value={data.dirInvoicePrice} onChange={handleChange} readOnly={true} />
                        {error.dirInvoicePrice ? <div class="text-danger error-contract">{error.dirInvoicePrice}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"accordionItem" + (accord['3'] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '3': !accord['3'] })}>Shipping Details</h2>
              <div className="accordionItemContent">
                <div className="col-md-12 row">


                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Loading Country {astrix} {toolTip('dirLoadingCountry')}</label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.dirLoadingCountry ? '' : ' border-danger')} name="dirLoadingCountry" value={data.dirLoadingCountry} onChange={(event) => { handleChange(event); }} readOnly={true}>
                          <option value="" selected>--Select country--</option>
                          {/* <CountryList /> */}
                          {
                            countryData.length ? countryData.map((item, index) => {
                              return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                            }) : ''
                          }
                        </select>
                        {error.dirLoadingCountry && <p className="text-danger error-contract">{error.dirLoadingCountry}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Loading Port {astrix} {toolTip('dirLoadingPort')}</label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.dirLoadingPort ? '' : ' border-danger')} name="dirLoadingPort" value={data.dirLoadingPort} onChange={handleChange} readOnly={true}>
                          <option value="" selected>--Select loading port--</option>
                          {portLoading.map((portLoading) => {
                            return (
                              <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                            )
                          })}
                        </select>
                        {error.dirLoadingPort && <p className="text-danger error-contract">{error.dirLoadingPort}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Unloading Country {astrix} {toolTip('dirUnloadingCountry')}</label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.dirUnloadingCountry ? '' : ' border-danger')} name="dirUnloadingCountry" value={data.dirUnloadingCountry} onChange={(event) => { handleChange(event); }} readOnly={true}>
                          <option value="" selected>--Select country--</option>
                          {/* <CountryList /> */}
                          {
                            countryData.length ? countryData.map((item, index) => {
                              return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                            }) : ''
                          }
                        </select>
                        {error.dirUnloadingCountry && <p className="text-danger error-contract">{error.dirUnloadingCountry}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Unloading Port {astrix} {toolTip('dirUnloadingPort')}</label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.dirUnloadingPort ? '' : ' border-danger')} name="dirUnloadingPort" value={data.dirUnloadingPort} onChange={handleChange} readOnly={true}>
                          <option value="" selected>--Select Unloading port--</option>
                          {
                            portUnloading.map((portUnloading) => {
                              return (
                                <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                              )
                            })
                          }
                        </select>
                        {error.dirUnloadingPort && <p className="text-danger error-contract">{error.dirUnloadingPort}</p>}
                      </div>
                    </div>
                  </div>



                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Shipping Company {astrix}  {toolTip('dirShipper')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirShipper ? " border-danger" : "")} type="text" name="dirShipper" value={data.dirShipper} onChange={handleChange} readOnly={true} />
                        {error.dirShipper ? <div class="text-danger error-contract">{error.dirShipper}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Vessel Name {astrix}  {toolTip('dirVessel')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirVessel ? " border-danger" : "")} type="text" name="dirVessel" value={data.dirVessel} onChange={handleChange} readOnly={true} />
                        {error.dirVessel ? <div class="text-danger error-contract">{error.dirVessel}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Bl Number {astrix}  {toolTip('dirBLNumber')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirBLNumber ? " border-danger" : "")} type="text" name="dirBLNumber" value={data.dirBLNumber} onChange={handleChange} readOnly={true} />
                        {error.dirBLNumber ? <div class="text-danger error-contract">{error.dirBLNumber}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">BL Date {astrix}  {toolTip('dirBLDate')}</label>
                      <div className="col-md-12">
                        <input className={"form-control" + (error.dirBLDate ? " border-danger" : "")} type="date" name="dirBLDate" value={data.dirBLDate} onChange={handleChange} readOnly={true} />
                        {error.dirBLDate ? <div class="text-danger error-contract">{error.dirBLDate}</div> : ''}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className={"accordionItem" + (accord['5'] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '5': !accord['5'] })}>Documents</h2>
              <div className="accordionItemContent">
                <div className="col-md-12">
                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                      <thead>
                        <tr>
                          <th width="20">#</th>
                          <th>Type of Document</th>
                          <th width="150">Uploaded On</th>
                          <th>Signed By</th>
                          <th className="text-center" width="150">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(docData && docData.length > 0) && docPreviewGenerator(docData)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>




          </div>
          {/* <hr />
          <OtherDocs
            data={data}
            setData={setData}
            file={file}
            handleFiles={handleFiles}
            docLimit={2}
          /> */}
        </div> :
        (financeDetails.status && financeDetails.type === 5 && financeDetails.page === 2) ?
          <>
            <div class="col-md-12 p-2">
              <h1 className="text-center"><u>Finance Aginst My Trade Form- Review</u></h1><br />
              <div className="row m-2">
                <div class="col-md-2 p-2">
                  <label>Applicant</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.applicantEmail} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Buyer</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.buyerNameManual ? data.buyerNameManual : data.buyerName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Contract No</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.dirContractNo} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Contract Name</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.dirContractName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Currency</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.dirCurrency ? data.dirCurrency.split(":")[0] : ""} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Contract Amount</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.dirInvoicePrice} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Currency</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.dirCurrency ? data.dirCurrency.split(":")[0] : ""} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Amount</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.amount} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Lender</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.lenderName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Interest Rate</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.interestRate} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Credit Days</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.creditDays} disabled />
                </div>

                <div className="col-md-12 text-left">
                  <div className="row form-group">

                    {financeDetails.info.action === "viewFinance" &&
                      <div className="col-md-12 mt-3">
                        <h3 className="pt-2 pb-2"><u>Available Documents</u></h3>
                        <div className="table-responsive" style={{ "min-height": "150px" }}>
                          <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                            <thead>
                              <tr>
                                <th width="20">#</th>
                                <th>Type of Document</th>
                                <th width="150">Uploaded On</th>
                                <th>Signed By</th>
                                <th className="text-center" width="150">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(docData && docData.length > 0) && docPreviewGenerator(docData)}
                            </tbody>
                          </table>
                        </div>
                      </div>}

                    {(file && Object.keys(file).length > 0) &&
                      <div className="col-md-12 row mt-3">

                        <h3 className="pt-2 pb-2 col-md-12 "><u>Added Documents</u></h3>
                        <div className="col-md-6">
                          <h3 className="col-md-12 pl-0 ml-0"><u>Document Name</u></h3>
                        </div>
                        <div className="col-md-6">
                          <h3 className="col-md-12  pl-0 ml-0"><u>File Name</u></h3>
                        </div>

                        {file && Object.keys(file).length ? Object.keys(file).map((doc) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">{doc.toUpperCase() || '--'}</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12 text-primary">{file[doc].name}</label>
                                </div>
                              </div>
                            </>
                          )
                        }) : ''}
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <input type="checkbox" id="checkDNC" name="dnc" value="on" onChange={handleChange} required checked={data.dnc === "on" ? true : false} />
              <label className="checkbox-smallLabel width-auto" for="checkDNC">
                <span>
                  I hereby declare that the given information by me is best in my
                  knowledge and I did not apply any loan against this contract.
            </span>
              </label>
            </div>
          </> : "oops something is wrong , your were not supposed to get here."
      }

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
      />}

      {showAclAuthModal &&
        <DigitalSignDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refresh}
          setrefreshDoc={setrefresh} />}

      <hr className="pt-2 pb-2 text-primary" />
      <div className="col-md-12">
        {(financeDetails.type === 5 && financeDetails.page < 2) ?
          <button type="button" className="btn btn-success btn-sm float-right" onClick={() => prehandleForward()}>
            Next<i className="fa fa-chevron-right pl-2" aria-hidden="true"></i>
          </button> :

          data.sellerStatus === 2 && data.lenderStatus === 2 ?
            <button type="button" className="btn btn-success btn-sm border border-success float-right" disabled>
              <b> Approved </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            </button> :

            ((data.sellerStatus === 2 && data.sellerId === userId && data.lenderStatus === 1) || (data.sellerStatus === 1 && data.lenderId === userId && data.lenderStatus === 2)) ?
              <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" disabled>
                <b> Approved By You</b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </button> :

              ((data.sellerStatus === 1 && data.sellerId === userId && data.status === 3) || (data.lenderStatus === 1 && data.lenderId === userId && data.status === 3 && data.sellerStatus === 2)) ?
                <>
                  {(amount / 1 === data.amount / 1) &&
                    <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={() => acceptDiscounting()}>
                      <b> Approve </b><i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}
                  {(data.sellerStatus === 1 && data.lenderStatus === 1) &&
                    <button type="button" className="btn btn-success btn-sm border border-success float-right  mr-2" onClick={updateDiscounting}>
                      <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}
                </> :

                ((data.sellerStatus === 1 && data.lenderStatus === 1) && financeDetails.info.action === "viewFinance" && data.sellerId === userId) ?
                  <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                    <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                  </button> :

                  ((data.sellerStatus === 1 && data.lenderStatus === 1) && financeDetails.info.action === "viewFinance" && data.lenderId === userId) ?
                    <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                      <b> Send Offer </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button> :

                    <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={submitFinanceForm} disabled={data.dnc === "on" ? false : true} > <b> Apply </b>
                      <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}

        {(financeDetails.type === 5 && financeDetails.page > 0) &&
          <button type="button" className="btn btn-danger btn-sm float-left" onClick={() => backward()}>
            <i className="fa fa-chevron-left pr-2" aria-hidden="true"></i>Back
          </button>}
      </div>
    </>
  );
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    financeDetails: state.financeDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTradeOnline)
