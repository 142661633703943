import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import call from '../../service'
import NewTablev2 from '../../utils/newTablev2'
import Pagination from '../InvoiceDiscounting/contract/components/pagination'
import { TTVBuyerColumns, TTVExporterColumns } from '../Reports/TableJson'
import Filter from '../InvoiceDiscounting/components/Filter'
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal'
import { ToastContainer } from 'react-toastify'
import toastDisplay from '../../utils/toastNotification'
import { NewInput } from '../../utils/newInput'
import CustomLineChart from '../CRM/CustomLineChart'
import TTVBuyerDetail from './TTVBuyerDetail'
import { platformURL } from '../../urlConstants'



const turnoverRange = [
  { min: "0", max: '100,000', is_checked: true, minVal: 0, maxVal: 100000 },
  { min: "100,000", max: '1 Million', is_checked: true, minVal: 100000, maxVal: 1000000 },
  { min: "1 Million", max: '5 Million', is_checked: true, minVal: 1000000, maxVal: 5000000 },
  { min: "5 Million", max: '10 Million', is_checked: true, minVal: 5000000, maxVal: 10000000 },
  { min: "10 Million", max: 'More', is_checked: true, maxVal: 10000000 }
]

const bardataConfig = [
  { dataKey: "total_exporters", fill: "#2ECC71", display: 'country' },
  { dataKey: "total_buyers", fill: "#5CB8D3", display: 'country' },
]

const valuesConfig = [
  { dataKey: "total_fob", fill: "#2ECC71", display: 'country' },
]

const label = {
  "total_exporters": "Exporters",
  "total_buyers": "Buyers",
}

const TTVBuyerList = ({ prevScreenData, showBuyerdetails, filter: list_filter, setShowLoader, userTokenDetails, showLoader }) => {

  // console.log("prevScreenDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", prevScreenData);

  const allfilters = [
    { name: "Importer/Exporter", filterKey: "IMPEXP", filterCount: 0 },
    // { name: "Available Contacts", filterKey: "AvailContacts", filterCount: 0 },
    { name: "Turnover", filterKey: "turnover", filterCount: 0 },
    { name: "Region", filterKey: "region", filterCount: 0 },
    // { name: "CRM", filterKey: "crm", filterCount: 0 },
    // { name: "Organization Type", filterKey: "orgType", filterCount: 0 },
    { name: list_filter.showImports ? "Import Countries" : "Export Countries", filterKey: "exportCountries", filterCount: 0 },
    { name: "Clients", filterKey: "clientsType", filterCount: 0 },
    // { name: "More Filters", filterKey: "morefilter", filterCount: 0 }
  ]

  const graphdropdown = [
    { label: 'Top 10 Countries', key: list_filter.showImports ? 'EXPORTER_COUNTRY' : 'DESTINATION_COUNTRY' },
    { label: 'Top 10 HSN Code', key: 'HS_CODE' },
    { label: 'Top 10 Commodities', key: 'Commodities' },
    { label: 'Top 10 Exporters', key: 'EXPORTER_NAME' },
    { label: 'Top 10 Port of loading', key: 'SOURCE_PORT' },
    { label: 'Top 10 Port of discharge', key: 'DESTINATION_PORT' },
    { label: 'Top 10 Buyers', key: 'CONSIGNEE_NAME' }
  ]

  const [dbdata, setDbdata] = useState([])
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState({})
  const [refresh, setRefresh] = useState(1)
  const [showPopup, togglePopup] = useState(true)
  const [isPurchaseSuccesful, setPurchaseSuccess] = useState(false)
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const [walletData, setWalletData] = useState({})
  const [overallFilters, setOverallFilters] = useState(allfilters)
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const [allFilters, setAllFilters] = useState({})
  const [CRMCityFilters, setCRMCityFilters] = useState([])
  const [CRMCountriesFilters, setCRMCountriesFilters] = useState([])
  const [CRMBuyersFilters, setCRMBuyersFilters] = useState([])
  const [filterData, setFilterData] = useState({})
  const [turnOverMasterFilter, setturnOverMasterFilter] = useState(turnoverRange)
  const [turnoverPopup, setTurnoverPopup] = useState({
    show: false,
    data: null
  })
  let [data, setData] = useState({})
  const [errors, setErrors] = useState({});
  const [addMore, setAddMore] = useState([
    { name: 'assignee1', key1: 'subadminContact1', key2: 'Start1', key3: 'End1' }
  ])
  const [selectedGraphIndex, setSelectedGraphIndex] = useState(0)
  const [tab, setTab] = useState('Count')
  const [countryData, setCountrydata] = useState([])
  const [ttvBuyerDetail, toggleTTVBuyerDetail] = useState({ show: false, data: {} })

  // console.log("showBuyerdetailsssssssssssssssssssssssssssssssss", showBuyerdetails);

  useEffect(() => {
    setOverallFilters([
      { name: "Importer/Exporter", filterKey: "IMPEXP", filterCount: 1 },
      { name: "Turnover", filterKey: "turnover", filterCount: turnOverMasterFilter.filter(item => item.is_checked).length },
      { name: "Region", filterKey: "region", filterCount: CRMCityFilters.filter(item => item.is_checked).length },
      { name: list_filter.showImports ? "Import Countries" : "Export Countries", filterKey: "exportCountries", filterCount: CRMCountriesFilters.filter(item => item.is_checked).length },
      { name: "Clients", filterKey: "clientsType", filterCount: CRMBuyersFilters.filter(item => item.is_checked).length }
    ])
  }, [turnOverMasterFilter, CRMCityFilters, CRMCountriesFilters, CRMBuyersFilters])

  const getCRMMasterdataFilters = (country_name) => {
    let objectAPI = {
      ...filter,
      showImports: list_filter.showImports,
      country_name,
      search: showBuyerdetails.data.searchParam,
      searchParam: showBuyerdetails.data.searchParam,
      HS_CODES: showBuyerdetails.data.HS_CODES.filter(item => item.is_checked).map(item => item.HS_CODE)
    }
    // if (!country_name) {
    //   objectAPI["country_name"] = showBuyerdetails.data?.country
    // }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "newUserType" ||
                element.accordianId === "leadAssignmentStatus" ||
                element.accordianId === "leadStatus" || element.accordianId === "applicationStatus") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    setShowLoader(true)
    call('POST', 'getCRMMasterdataFiltersV2', objectAPI).then(result => {
      console.log("getCRMMasterdataFiltersV222222222222222222222222", result);
      setShowLoader(false)
      setAllFilters(result)
      let city = result?.EXPORTER_CITY || result?.BUYER_CITY
      if (city?.length) {
        let sortedata = city?.sort()
        let finalData = []
        for (let i = 0; i <= sortedata?.length - 1; i++) {
          const item = sortedata[i]
          finalData.push({
            name: item,
            is_checked: true
          })
        }
        setCRMCityFilters(finalData)
      }
      let countrys = result.EXPORT_COUNTRIES || result.IMPORT_COUNTRIES
      if (countrys?.length) {
        let temp = []
        for (let i = 0; i <= countrys.length - 1; i++) {
          const item = countrys[i]
          temp.push({
            name: item,
            is_checked: true
          })
        }
        setCRMCountriesFilters(temp)
      }
      let names = result.CONSIGNEE_NAMES || result.EXPORTER_NAMES
      if (names) {
        let temp = []
        for (let i = 0; i <= names.length - 1; i++) {
          const item = names[i]
          temp.push({
            name: list_filter.showImports ? item.CONSIGNEE_NAME : item.EXPORTER_NAME,
            value: list_filter.showImports ? item.CONSIGNEE_CODE : item.EXPORTER_CODE,
            is_checked: true
          })
        }
        setCRMBuyersFilters(temp)
      }
    }).catch(e => {
      setShowLoader(false)

    })
  }

  const getWalletBalance = () => {
    call('POST', 'getWalletBalance', { userId }).then((result) => {
      console.log("API result getWalletBalance", result);
      setShowLoader(false)
      if (result && result.length) {
        setWalletData(result[0])
      }
    }).catch((e) => {
      console.log("Error while getWalletBalance", e);
      setShowLoader(false)
    });
  }
  const getTTVBuyerList = async () => {
    setShowLoader(true)
    let reqObj = {
      [list_filter.showImports ? "DESTINATION_COUNTRY" : "ORIGIN_COUNTRY"]: showBuyerdetails.data.country,
      searchParam: list_filter.search,
      currentPage: page,
      resultPerPage: 10,
      search: filter.search,
      TURNOVER_RANGE: turnOverMasterFilter.filter(item => item.is_checked),
      CITIES: CRMCityFilters.filter(item => item.is_checked).map(item => item.name),
      COUNTRIES: CRMCountriesFilters.filter(item => item.is_checked).map(item => item.name),
      // COUNTRIES: ["INDIA"], //NNTTRR
      [list_filter.showImports ? "BUYERS" : "EXPORTERS"]: CRMBuyersFilters.filter(item => item.is_checked).map(item => item.value),
      countFrom: prevScreenData.countFrom,
      countTo: prevScreenData.countTo
    }
    try {
      const result = await call('POST', list_filter.showImports ? 'getTTVBuyersListV2' : 'getTTVExporterListV2', reqObj)
      console.log('Sucess in getTTVBuyersListV2/getTTVSupplierListV2', result)
      setDbdata(result)
      setShowLoader(false)
    } catch (e) {
      setShowLoader(false)
    }

  }
  const unlockBuyerdetails = async () => {
    setShowLoader(true)
    try {
      const result = await call('POST', 'unlockBuyerdetails', { userId })
      console.log('success in unlockBuyerdetails', result)
      setPurchaseSuccess(true)
      setShowLoader(false)
      togglePopup(false)
      toastDisplay("Purchase Succesful", "success")
    } catch (e) {
      toastDisplay(e, "error")
      setShowLoader(false)
    }
  }

  useEffect(() => {
    getTTVBuyerList()
  }, [page, refresh, turnOverMasterFilter, CRMCityFilters, CRMCountriesFilters, CRMBuyersFilters])

  useEffect(() => {
    getCRMMasterdataFilters()
  }, [page, refresh])
  useEffect(() => {
    getWalletBalance()
  }, [])

  const handleChange = (event) => {
    const temp = [...addMore]

    if (event.target.name?.includes("subadminContact")) {
      const index = addMore.findIndex(item => item.key1 === event.target.name)
      temp[index] = {
        ...temp[index],
        [`subadminContactVal${index + 1}`]: event.target.value
      }
    }
    else if (event.target.name === 'event_date') {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    } else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
    event.persist();
    setAddMore(temp)
  }

  const getCountriesCount = (country_name) => {
    let objectAPI = {
      currentPage: page,
      resultPerPage: filter.resultPerPage,
      ...filter,
      country_name,
      searchParam: showBuyerdetails.data.searchParam,
      HS_CODES: showBuyerdetails.data.HS_CODES.filter(item => item.is_checked).map(item => item.HS_CODE),
      // AVAILABLE_CONTACTS: contactsMasterFilter.filter(item => item.is_checked),
      TURNOVER_RANGE: turnOverMasterFilter.filter(item => item.is_checked),
      CITIES: CRMCityFilters.filter(item => item.is_checked).map(item => item.name),
      // STATUS: CRMMasterFilter.filter(item => item.is_checked),
      // ORGANIZATION_TYPE: CRMOrgTypeFilter.filter(item => item.is_checked).map(item => item.name),
      COUNTRIES: CRMCountriesFilters.filter(item => item.is_checked).map(item => item.name),
      BUYERS: CRMBuyersFilters.filter(item => item.is_checked).map(item => item.value),
      showImports: list_filter.showImports
    }
    if (!country_name) {
      objectAPI["country_name"] = showBuyerdetails.data?.country
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(i[element["labelName"]])
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    objectAPI["groupParam"] = graphdropdown[selectedGraphIndex].key
    //setShowLoader(true)
    call('POST', 'getCountriesCountV3', objectAPI).then(result => {
      setShowLoader(false)
      setCountrydata(result)
    }).catch(e => {
      setShowLoader(false)

    })
  }


  useEffect(() => {
    getCountriesCount()
  }, [selectedGraphIndex, turnOverMasterFilter, CRMCityFilters, CRMCountriesFilters, CRMBuyersFilters, filterData])

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className={`col-md-12 text-center `}>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        {turnoverPopup.show &&
          <FinanceInvoiceModal limitinvoice={turnoverPopup.show} headerTitle={'Add Turnover Filter'} modalSize={"sm"} isCentered={true} closeSuccess={() => {
            setTurnoverPopup({ show: false, data: null })
          }}>
            <>
              <div className='d-flex flex-row align-items-center gap-2 pl-4'>
                <label className='font-size-14 font-wt-400 mb-0'>From</label>
              </div>
              <div className="col-md-10 ml-4 mt-4">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"number"} label={""}
                    name={"turnOverFrom"} defaultValue={turnoverPopup?.data?.turnOverFrom || ""}
                    value={data.turnOverFrom} error={errors.turnOverFrom}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='d-flex flex-row align-items-center gap-2 pl-4'>
                <label className='font-size-14 font-wt-400 mb-0'>To</label>
              </div>
              <div className="col-md-10 ml-4 mt-4">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"number"} label={""}
                    name={"turnOverTo"} defaultValue={turnoverPopup?.data?.turnOverTo || ""}
                    value={data.turnOverTo} error={errors.turnOverTo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button type="button"
                onClick={() => {
                  if ((data.turnOverFrom && data.turnOverTo)) {
                    const minRange = parseInt(data.turnOverFrom)
                    const maxRange = parseInt(data.turnOverTo)
                    if (maxRange > minRange) {
                      const temp = [...turnOverMasterFilter]
                      const insertObj = {
                        min: Intl.NumberFormat('en-US', { notation: 'compact' }).format(data.turnOverFrom),
                        max: Intl.NumberFormat('en-US', { notation: 'compact' }).format(data.turnOverTo),
                        is_checked: true,
                        minVal: minRange,
                        maxVal: maxRange
                      }
                      temp.push(insertObj)
                      console.log('Unsersssss', insertObj);
                      setturnOverMasterFilter(temp)
                      setTurnoverPopup({ show: false, data: null })
                    } else {
                      toastDisplay("To Value Should be greater than from", "info")
                    }

                  } else {
                    toastDisplay("Range cannot be empty", "info")
                  }

                }}
                className={`new-btn w-40 py-2 px-3 text-white`}>
                {"Save"}
              </button>
            </>
          </FinanceInvoiceModal>
        }
        {showPopup && <FinanceInvoiceModal
          isCentered={true}
          limitinvoice={showPopup}
          setLimitinvoice={togglePopup}
          closeSuccess={() => togglePopup(false)}
        >
          <div className='col-md-12 text-center'>
            <div>
              <img src="/assets/images/sidebarV2/buyermanagement.svg" height={50} width={50} />
            </div>
            <div>
              <label className='font-size-16 font-wt-500'>{list_filter.showImports ? `Unlock buyer details` : `Unlock exporter details`}</label>
            </div>
            <div className='mt-3'>
              <button className={`new-btn p-2 text-white cursor`} onClick={() => { unlockBuyerdetails() }}>
                Unlock with <img src="/assets/images/getfinancedetails-icon.png" alt="" height="20" class="ms-1" /> 30
              </button>
            </div>
            <div className='mt-2'>
              Available Balance : <label className='text-color1 font-size-14 font-wt-500'>
                <img src="/assets/images/getfinancedetails-icon.png" alt="" height="20" class="ms-1" />
                {walletData?.coins || 0}
              </label>

            </div>

          </div>
        </FinanceInvoiceModal>}


        <div>
          <ReactCountryFlag
            countryCode={showBuyerdetails.data.sortname}
            style={{ width: '50px', height: '50px', "borderRadius": "15px" }} svg />
          <label className='font-size-16 font-wt-500 ml-3'>{showBuyerdetails.data.country}</label>
        </div>
        <label className='font-size-14 font-wt-400'>{`Search results for ${showBuyerdetails.data.total_buyers}`}</label>

        {isPurchaseSuccesful ? <div className='row mt-2 ml-1'>
          <div className='card p-0 w-35 rounded d-flex flex-row'>
            <div className='col-md-6 px-0'>
              {overallFilters.map((item, index) => {
                return <div className='p-2 border-left-0 d-flex justify-content-between align-items-center detailssection cursor' onClick={() => setSelectedFilterIndex(index)}>
                  <label className='font-size-14 font-wt-600'>{item.name}</label>
                  <div className='circle bg-5CB8D3'>
                    <label className='color-white font-size-14 font-wt-600 m-0'>{item.filterCount}</label>
                  </div>
                </div>
              })}
            </div>

            <div className='col-md-6 px-0 cityDiv'>
              {selectedFilterIndex === 0 &&
                <div className='card p-4 rounded h-100'>
                  <div className='text-center'>
                    <div className='d-flex flex-row gap-3'>
                      <img src={'/assets/images/checked-green.png'} height={20} width={20} className='mr-2 cursor' />
                      <label className='font-size-14 font-wt-600 text-decoration-underline'>Total</label>
                    </div>
                    <label className='font-size-14 font-wt-600'>{list_filter.showImports ? allFilters?.EXPORTER_COUNT : allFilters?.CONSIGNEE_COUNT}</label>
                  </div>
                </div>
              }
              {selectedFilterIndex === 1 &&
                <div className='p-3'>
                  {turnOverMasterFilter.map((item, index) => {
                    return <div className='d-flex flex-row gap-2 mt-2' key={index}>
                      <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                        const updatedTO = [...turnOverMasterFilter]; // create a new array
                        updatedTO[index] = {
                          ...updatedTO[index],
                          is_checked: !updatedTO[index].is_checked
                        };
                        setturnOverMasterFilter(updatedTO);
                      }} />
                      <label className='font-size-14 font-wt-600'>{item.min + " - " + item.max}</label>
                    </div>
                  })}
                  <div>
                    <button className={`new-btn2 py-1 px-3 mt-1 text-color1 cursor`} onClick={() => setTurnoverPopup({ show: true, data: null })}>New</button>
                  </div>
                </div>
              }
              {selectedFilterIndex === 2 &&
                <div className='p-3 '>
                  {CRMCityFilters.map((item, index) => {
                    return <div className='d-flex flex-row gap-2 mt-2' key={index}>
                      <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                        const updatedTO = [...CRMCityFilters]; // create a new array
                        updatedTO[index] = {
                          ...updatedTO[index],
                          is_checked: !updatedTO[index].is_checked
                        };
                        setCRMCityFilters(updatedTO);
                      }} />
                      <label className='font-size-14 font-wt-600'>{item.name}</label>
                    </div>
                  })}
                </div>
              }
              {selectedFilterIndex === 3 &&
                <div className='p-3 '>
                  {CRMCountriesFilters.map((item, index) => {
                    return <div className='d-flex flex-row gap-2 mt-2' key={index}>
                      <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                        const updatedTO = [...CRMCountriesFilters]; // create a new array
                        updatedTO[index] = {
                          ...updatedTO[index],
                          is_checked: !updatedTO[index].is_checked
                        };
                        setCRMCountriesFilters(updatedTO);
                      }} />
                      <label className='font-size-14 font-wt-600'>{item.name}</label>
                    </div>
                  })}
                </div>
              }
              {selectedFilterIndex === 4 &&
                <div className='p-3 '>
                  {CRMBuyersFilters.map((item, index) => {
                    return <div className='d-flex flex-row gap-2 mt-2' key={index}>
                      <img src={item.is_checked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} className='mr-2 cursor' onClick={() => {
                        const updatedTO = [...CRMBuyersFilters]; // create a new array
                        updatedTO[index] = {
                          ...updatedTO[index],
                          is_checked: !updatedTO[index].is_checked
                        };
                        setCRMBuyersFilters(updatedTO);
                      }} />
                      <label className='font-size-14 font-wt-600'>{item.name}</label>
                    </div>
                  })}
                </div>
              }
            </div>
          </div>
          <div className='card p-4 w-63 rounded ml-3'>
            <div className="d-flex justify-content-between align-items-center">
              <div class="dropdown w-100 cursor" >
                <div className='d-flex gap-2 align-items-center' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <label class="font-wt-600 font-size-13 mb-0" >
                    {graphdropdown[selectedGraphIndex].label}
                  </label>
                  <img src='assets/images/arrowdown.png' />
                </div>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {graphdropdown.map((element, index) => {
                    return <li className="dropdown-item cursor" onClick={() => { setSelectedGraphIndex(index) }}>{element.label}</li>
                  })}
                </ul>
              </div>
            </div>
            <div className="pt-4">
              <CustomLineChart XFormatter={(value) => value} YFormatter={(tab === 'Values' || selectedGraphIndex === 2) ? (value) => "$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : (value) => value} bardataConfig={(tab === 'Values' || selectedGraphIndex === 2) ? valuesConfig : bardataConfig} formatterFunction={(tab === 'Values' || selectedGraphIndex === 2) ? (value, name) => ["$ " + Intl.NumberFormat('en-US', { notation: 'compact' }).format(value), name] : (value, name) => [value, label[name]]} data={countryData} xDataKey={(selectedGraphIndex === 2) ? "PRODUCT_TYPE" : "country"} tab={tab} type={"Sum"} />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {selectedGraphIndex === 2 ? null
                : <div className="text-center d-flex flex-row align-items-center justify-content-center">
                  <div >
                    <ul className="nav pricingtabs nav-pills bg-white mx-auto rounded-pill p-0 shadow-sm" id="pills-tab" role="tablist">
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setTab("Count")
                        }} className="nav-link active w-100 roundedpillleft font-size-14" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Count</button>
                      </li>
                      <li className="nav-item p-0 " role="presentation">
                        <button onClick={() => {
                          setTab("Values")
                        }} className="nav-link w-100 roundedpillright font-size-14 " id="pills-Yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-Yearly" type="button" role="tab" aria-controls="pills-Yearly" aria-selected="false">Values ($)</button>
                      </li>
                    </ul>
                  </div>
                </div>
              }

              <div>
                {(tab === 'Values' || selectedGraphIndex === 2) ?
                  <label className="paymentsdots"><div className='greendot' /> FOB </label>
                  : <div className="d-flex gap-3">
                    <label className="paymentsdots"><div className='greendot' /> Exporter </label>
                    <label className="paymentsdots"><div className='bluedot' /> Buyers </label>
                  </div>
                }
              </div>
            </div>
          </div>
        </div> : null}


        <div className='mt-4'>
          <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
          <div className='mt-2'>
            <NewTablev2 columns={list_filter.showImports ? TTVBuyerColumns : TTVExporterColumns} tableFixed>
              {dbdata.map((key, index) => {
                console.log("dbDataaaaaaaaaaaaaaaaaaaaaaa", dbdata);
                let userName = list_filter.showImports ? "buyerName" : 'expName'
                let userArrKey = list_filter.showImports ? "exporters" : 'buyers'
                return (
                  <tr className={`${isPurchaseSuccesful ? '' : 'blurBackground'}`}>
                    <td className="text-left">
                      <div>
                        {key[userName] && key[userName].length > 60 ? key[userName].slice(0, 60) + '...' : key[userName]}
                      </div>
                    </td>
                    <td className=""> {key[`unique_${userArrKey}_count`]} </td>
                    <td className="text-left"> {key.total_shipments} </td>
                    <td className="text-left">{"$" + Intl.NumberFormat("en", { notation: 'compact' }).format(key.FOB)}</td>
                    <td className="text-left">{
                      <ul className='py-0 pl-3'>
                        {key[userArrKey]?.map(item => {
                          return <li >
                            <div>
                              {item.name && item.name.length > 60 ? item.name.slice(0, 60) + '...' : item.name}
                            </div>
                          </li>
                        })}
                      </ul>
                    }</td>
                    <td className="text-left">{key.port}</td>
                    <td className="text-left">{"NA"}</td>
                    <td className="text-right">
                      <img
                        onClick={() => {
                          // console.log("keyyyyyyyyyyyyyyyyyyyyyyyy", key);
                          localStorage.setItem("ttvBuyerInfo", JSON.stringify(key))
                          window.open(`/userDetail?type=${!key.expName ? "buyer" : "exporter"}`, '_blank');
                        }}
                        src='/assets/images/redirect.svg' className='cursor' />
                    </td>
                  </tr>
                );
              })}
              {dbdata.length === 0 &&
                <tr className="w-100 py-4 text-center">
                  <td className="font-size-16 font-wt-600"></td>
                  <td className="font-size-16 font-wt-600"></td>
                  <td className="font-size-16 font-wt-600 py-5">No Data Found</td>
                  <td className="font-size-16 font-wt-600"></td>
                  <td className="font-size-16 font-wt-600"></td>
                </tr>
              }
            </NewTablev2>
            <Pagination page={page} totalCount={showBuyerdetails.data.total_buyers} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
          </div>

        </div>
      </div>
    </>
  )
}

export default TTVBuyerList