import React, { useEffect } from 'react';
import call from '../../service';
import { removeCookieandAvatar, setCookieInAPP } from '../../utils/cookieHelper';
import { decryptData } from '../../utils/myFunctions';

const RefLink = () => {

  useEffect(() => {

    let refLinkData = window.location.href.split("refLink?")

    // console.log("refLinkData", refLinkData);

    if (refLinkData[1]) {
      let decryptedRefLinkData = decryptData(refLinkData[1]).split("::")
      console.log("decryptedRefLinkData==>", decryptedRefLinkData);
      removeCookieandAvatar()
      localStorage.clear()
      localStorage.setItem("onlyAllowedUrl", decryptedRefLinkData[2])
      login({ username: decryptedRefLinkData[0], otp: decryptedRefLinkData[1] }, decryptedRefLinkData[2])
    }

  }, [])

  async function login(payload, redirectUrl) {
    console.log("redirectUrl", redirectUrl);

    await call('post', 'login', payload).then((result) => {
      if (result) {
        // console.log("result in login==>", result)
        setCookieInAPP(result)
        window.location = redirectUrl
      }
    }).catch(e => {
      // console.log("Error in login====>", e)
    })
  }

  return (
    <></>
  )
}

export default RefLink