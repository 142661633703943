import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, getInvoiceTotalCharges, getInvoiceTotalOtherCharges, most_used_currencies, SetCache, toolTip } from "../../../../utils/myFunctions";
import call from '../../../../service';
import config from "../../../../config.json";
import { FileInput } from "../../../../utils/FileInput";
import SignDocument from "../../components/SignDocument";
import AdavancePaymentTab from "./adavancePaymentTab";
import FinanceInvoiceModal from "./financeinvoiceModal";
import TooltipModal from "../../components/Tooltipmodal";
import { ToastContainer } from "react-toastify";
import { AddMoreDocumentHandler } from "../../../addMoreDocumentHandler";
import NewTablev2 from "../../../../utils/newTablev2";
import { DocumentDownloader } from "../../../../utils/DocumentDownloader";
import { DocumentViewer } from "../../../../utils/documentViewer";
import { FormProgressBar } from "../../../CommonComponent/FormProgressBar";

const addBuyerTabs = [
  { name: "Financer" },
  { name: "Application form" },
  { name: "Documents" },
  // { name: "Advance payment" },
]

const reviewForm = [
  { "name": "Buyer Name", val: "buyerName" },
  { "name": "Previous year annual sales", val: "previousAnnualSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerPhone", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "currentAnnualSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "incoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "termsOfPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "productDetails" },

  { "name": "DUNS No", val: "buyerDUNSNo" },
  { "name": "Limit Required", val: "requiredLimit", unit: "requiredLimitCurrency" },
  { "name": "HSN Code", val: "buyerHsnCode" }

]

const reviewForm2 = [
  { "name": "Buyer Name", val: "buyerName", unit: "buyerNameTitle" },
  { "name": "Previous year annual sales", val: "buyerPrevSale", unit: "buyerCurrency" },
  { "name": "Contact Number:", val: "buyerContact", unit: "buyerPhoneCode" },
  { "name": "Expected current year’s annual sale", val: "buyerExpectedSale", unit: "buyerCurrency" },

  { "name": "Email Id", val: "buyerEmail" },
  { "name": "Inco Terms", val: "buyerIncoTerms" },
  { "name": "Website", val: "buyerWebsite" },
  { "name": "Terms of Payment", val: "buyerTermsPayment" },

  { "name": "Address", val: "buyerAddress" },
  { "name": "Product Details", val: "buyerProductDetails" },
  { "name": "Industry Type", val: "industryType" },
  { "name": "HSN Code", val: "buyerHsnCode" },
  { "name": "DUNS No", val: "buyerDunsNo" },
  // { "name": "Limit Required", val: "LimitRequired" },
]

const financeSelected = [
  { name: "Drip" },
];


const selectFinancedata = [
  {
    companyName: "Stenn",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/stenn-logo.png",
    ribbon: true,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Drip",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/Drip-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Modifi",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/modifi-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },

  {
    companyName: "Modifi",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/modifi-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: true
  },
  {
    companyName: "Federal bank",
    rating: "4.8",
    reviews: "178",
    logo: "assets/images/supplier-images/federal-logo.png",
    ribbon: false,
    fees: "3.5", rate: "6.0", setupfee: "800", totalcharges: "2,300", validitydate: "30/09/2023",
    limited: false
  },
];


const EmojiData = [
  {
    image: "/assets/images/angry.png",
    fillimage: "/assets/images/angry-fill.png",
    name: "Angry"
  },
  {
    image: "/assets/images/Not satisfied.png",
    fillimage: "/assets/images/notsatisfed-fill.png",
    name: "Not satisfied"
  },
  {
    image: "/assets/images/ok.png",
    fillimage: "/assets/images/ok-fill.png",
    name: "ok"
  }, {
    image: "/assets/images/Satisfied.png",
    fillimage: "/assets/images/satisfied-fill.png",
    name: "Satisfied"
  }, {
    image: "/assets/images/excellent.png",
    fillimage: "/assets/images/excellent-fill.png",
    name: "excellent"
  },
];

const adavanceData = [
  { name: "Factoring fees", amount: "$ 50" },
  { name: "Interest rate", amount: "$ 100" },
  { name: "One time set-up fees", amount: "$ 750" },
  { name: "Finance credit days", amount: "50" },
];

const SignContract = ({ userTokenDetails, navToggleState }) => {

  let termSheetLink = localStorage.getItem("termSheetLink") != "null" ? localStorage.getItem("termSheetLink") : false

  const queryParams = new URLSearchParams(window.location.search)
  const buyer = queryParams.get("buyer")
  let selectedLenderId = localStorage.getItem("selectedLenderId")

  let onlyViewMode = localStorage.getItem('onlyViewMode')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [tab, setTab] = useState(2)
  const [modal, setModal] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [experience, setExperience] = useState(false);
  const [emoji, setEmoji] = useState("");

  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const [addMoreDoc, setAddMoreDoc] = useState([])
  const [multInvPo, setMultInvPo] = useState([])
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [moreDocsArray, setMoreDocsArray] = useState([])
  const [moreDocsArray2, setMoreDocsArray2] = useState([])
  const [similarRequest, setSimilarRequest] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [firstindex, setFirstindex] = useState(0);
  const [lastindex, setLastindex] = useState(4);
  const [slice, setSlice] = useState(1);

  const Next = () => {
    setFirstindex(firstindex + 4);
    setLastindex(lastindex + 4);
    setSlice(slice + 1);
  };

  const Back = () => {
    setFirstindex(firstindex - 4);
    setLastindex(lastindex - 4);
    setSlice(slice - 1);
  };


  const closeSuccess = () => {
    setLimitinvoice(false);
    setExperience(true);
  }

  useEffect(() => {
    call("POST", 'getSimilarTermSheetRequestsToSign', {
      userId, applicationId: localStorage.getItem("applicationId"),
      lenderId: selectedLenderId, termSheet: localStorage.getItem("termSheet")
    }).
      then(res => {
        if (res.length) {
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            element["isChecked"] = true
            if (element.id / 1 == localStorage.getItem("applicationId") / 1) {
              element["isFixed"] = true
            }
          }
          setSimilarRequest(res)
        }
      })
    //Get Buyer list start
    call('POST', 'getBuyersDetailWithDoc', { buyersId: buyer, applicationId: localStorage.getItem("applicationId") }).then((result) => {
      // console.log('running getBuyersDetailWithDoc api-->', result);
      setDbData(result);
    }).catch((e) => {
      // console.log('error in getBuyersDetailWithDoc', e);
    });
    //Get buyer list end
  }, [])

  useEffect(() => {
    if (buyer && dbData.length) {
      let event = {
        target: {
          name: "selectedBuyer",
          value: buyer
        }
      }
      handleChange(event)
    }
  }, [buyer, dbData])

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }

    if (event.target.name === "selectedBuyer") {
      setshowLoader(true)
      let selectedBuyer = dbData[0]
      let buyers_credit = JSON.parse(selectedBuyer.buyers_credit)

      for (let index = 0; index < buyers_credit?.length; index++) {
        const element = buyers_credit[index];
        if (element.lender_name.toLowerCase().includes("stenn")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/stenn-logo.png"
        }
        else if (element.lender_name.toLowerCase().includes("modifi")) {
          buyers_credit[index]["logo"] = "assets/images/supplier-images/modifi-logo.png"
        }
      }


      let termSheetDocument = await getDocDetails(localStorage.getItem("termSheet"))


      let tempLimitDocsDetails = {}
      let limitDocs = await call('POST', 'getInvLimitApplicationDocs', { userId, quoteId: localStorage.getItem("applicationId") })
      let totalInvoiceDetails = limitDocs.maxIndx
      let maxInvPoDocs = limitDocs.maxSubIndx

      for (let index = 0; index < limitDocs.data.length; index++) {
        const element = limitDocs.data[index];
        tempLimitDocsDetails[element.label] = await getDocDetails(element.docId)
        if (element.attributes) {
          tempLimitDocsDetails = { ...tempLimitDocsDetails, ...element.attributes }
        }
      }
      setMultInvPo([...new Array(totalInvoiceDetails).fill({ invoices: new Array(maxInvPoDocs).fill(null), purchase_orders: new Array(maxInvPoDocs).fill(null) })])


      let otherDocsObj = {}

      if (selectedBuyer.buyerOtherDocs) {
        let buyerOtherDocs = selectedBuyer.buyerOtherDocs.split(":")
        for (let index = 0; index < buyerOtherDocs.length; index++) {
          const element = buyerOtherDocs[index];
          otherDocsObj["otherDoc" + index] = await getDocDetails(element)
          addMoreDoc.push(null)
        }
      }

      setshowLoader(false)

      setData({
        ...data, [event.target.name]: event.target.value, ...selectedBuyer, buyerCurrency: selectedBuyer.buyerCurrency.split(":")[1],
        termSheetDocument, ...otherDocsObj, buyers_credit, ...tempLimitDocsDetails
      })

    }
    else {
      setData({ ...data, [event.target.name]: event.target.value })
      setErrors({ ...errors, [event.target.name]: "" })
    }
  }



  const closeFn = () => {
    setLimitinvoice(true);
  }

  const closeExpFn = () => {
    setExperience(false);
  }


  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      if (event.target.name.includes("otherDoc")) {
        setAddMoreDoc(addMoreDoc.concat(null))
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let err = {}

    if (tab === 2) {
      if (termSheetLink) {
        if (!data.termSheetLinkSigned) {
          err["termSheetDocument"] = "Signing term sheet document by link is mandatory"
        }
      }
      else if (!(data.termSheetDocument && data.termSheetDocument.signatureId)) {
        err["termSheetDocument"] = "Signing term sheet is mandatory"
      }
    }

    if (!Object.keys(err).length) {
      if (tab === 2) {
        setshowLoader(true)
        let formData = new FormData()
        formData.append("buyerId", data.id)
        if (!data.termSheetDocument.fromDb) {
          formData.append("termSheetDocument", data.termSheetDocument)
        }
        formData.append("buyersDocId", data.buyersDocId)
        formData.append("buyerOtherDocs", data.buyerOtherDocs)
        formData.append("userId", userId)
        formData.append("applicationId", localStorage.getItem("applicationId"))
        formData.append("termSheetDocId", localStorage.getItem("termSheet"))
        if (data.termSheetDocument?.signatureId) {
          formData.append("termSheetDocSignId", data.termSheetDocument.signatureId)
        }
        addMoreDoc.forEach((k, index) => {
          if (data["otherDoc" + index]) {
            formData.append("otherDoc" + index, data["otherDoc" + index])
            if (data["otherDoc" + index]["signatureId"]) {
              formData.append("otherDoc" + index + "SignId", data["otherDoc" + index]["signatureId"])
            }
          }
        })
        if (userTypeId != 8) {
          formData.append("exporterSignedContract", true)
        }
        formData.append("selectedLenderId", selectedLenderId)
        formData.append("userName", userName)

        let tempSimilarRequest = []

        for (let index = 0; index < similarRequest.length; index++) {
          const element = similarRequest[index];
          if (element.isChecked && !element.isFixed) {
            tempSimilarRequest.push(element.id)
          }
        }

        if (tempSimilarRequest.length) {
          formData.append("similarRequest", tempSimilarRequest)
        }

        call('POST', 'submitInvQuotation', formData).then((result) => {
          // console.log('running submitInvQuotation api-->', result);
          toastDisplay("Contract updated successfully", "success",
            () => window.location = "/invoicecontract"
          )
          setshowLoader(false)
        }).catch((e) => {
          // console.log('error in submitInvQuotation', e);
          toastDisplay("Something went wrong", "error")
          setshowLoader(false)
        });
        return null
      }
      else {
        setTab(tab + 1)
      }
    }

    setErrors(err)
  }


  return (
    <>
      <AddMoreDocumentHandler hide={true}
        purpose={"ApplyForLimitInvoice"} purposeId={buyer}
        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
      />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="invoicecontract" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={"Sign Contract"}
              userTokenDetails={userTokenDetails} />
            <a className="cursor" href="/invoicecontract"><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <div>
              {/* <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                {addBuyerTabs.map((item, index) => {
                  return (
                    <li>
                      <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        onClick={() => { handleValidation(index) }}
                      >
                        {item.name}</a>
                    </li>
                  )
                })}
              </ul> */}
              <div className="card mt-1">

                <div className="d-flex justify-content-center mt-5 mb-3" >
                  <FormProgressBar
                    tabs={addBuyerTabs} activeTab={tab} label={"name"}
                    separationWidth={'7rem'} handleClick={(i, index) => { handleValidation(index) }}
                  />
                </div>

                {tab === 1 ? (
                  <div className='px-5 py-4 pt-5'>
                    <div className="row">
                      <div className='w-100'>
                        <label className='font-wt-500 font-size-16'><u>Buyer Details</u>
                          <img className="ms-3"
                            onClick={() => { window.location = `/buyerManagement?editBuyer=${data.id}` }}
                            src={"assets/images/edit.png"} alt="edit" />
                        </label>
                        <div className='row'>
                          {reviewForm.map((item) => {
                            return (
                              <div className="col-md-6 pe-5">
                                <p className="d-flex align-items-top mb-2"><span className="col-md-5 px-0 BuyerdetailsLabel">{item.name}</span><span className="mx-3">:</span><span className="col-md-7 BuyerdetailsDesc" > {data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}</span> </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Documents</u>
                          {/* <img className="ms-3"
                            onClick={() => { window.location = `/buyerManagement?editBuyer=${data.id}` }}
                            src={"assets/images/edit.png"} alt="edit" /> */}
                        </label>
                        <div className='row mt-2'>
                          <div className="col-md-5 col-xl-4">
                            <label className="font-size-14">Invoice</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"invoiceDocument"} value={data.invoiceDocument} error={errors.invoiceDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-xl-4">
                            <label className="font-size-14">Purchase Order</label>
                            <div className="row form-group">
                              <div className="col-md-12">
                                <FileInput name={"poDocument"} value={data.poDocument} error={errors.poDocument}
                                  isEditable={false}
                                />
                              </div>
                            </div>
                          </div>

                          {moreDocsArray?.length ? moreDocsArray.map((item, index) => {
                            if (item?.name) {
                              return (
                                <div className="col-md-5 col-xl-4">
                                  <label className="font-size-14">{item.doc_name}</label>
                                  <div className="row form-group">
                                    <div className="col-md-12">
                                      <FileInput value={item}
                                        isEditable={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }) : null}
                        </div>

                      </div>

                      <div className='w-100 mt-5'>
                        <label className='font-wt-500 font-size-16'><u>Financier selected</u></label>
                        <div className="d-flex gap-4 my-3">
                          <label
                            className="browse-div cursor text-center m-0 col-1 border-finance text-dark" >
                            {localStorage.getItem("selectedLenderName")}
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : null}
                {tab === 0 ? (
                  <div className='px-5 pt-4 pb-3'>
                    <div className="text-center">
                      <label className='font-wt-500 font-size-16 mb-0'>Select financer</label><br />
                      <small>Quotes for Invoice discounting</small>
                    </div>
                    <div className="row align-items-center mt-3">
                      {data.buyers_credit && data.buyers_credit.length ? data.buyers_credit.map((key, index) => {

                        return (
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-3">
                            <div className={`text-center card py-4 pb-0 px-3 
                              ${key.financierAction != "Approved" ? "limitdeniedcontract" : "selectfinancercardrec"}`}
                            >
                              <>
                                <p className="cursor mt-2 mb-1"><img className="mr-2"
                                  src={key.logo} alt="" />
                                  <span className="font-size-14 font-wt-600 text-dark">{key.lender_name}</span>
                                  {/* <img
                                    onClick={() => { setTooltip(true); setData({ ...data, showRemark: key.assignRemark, showLenderName: key.lender_name }) }}
                                    src={"assets/images/warning.png"} alt="" /> */}
                                  <a href="/docsRequested" >
                                    <img src={"assets/images/warning.png"} alt="" />
                                  </a>
                                </p>
                              </>

                              {key.financierAction != "Approved" ? (
                                <>
                                  <div className="limitDeniedCard">
                                    <div className="mb-2">
                                      <img src={"assets/images/block.png"} height={25} width={25} />
                                    </div>
                                    <div className="font-size-14 font-wt-600">
                                      Limit Denied
                                    </div>
                                  </div>
                                </>)
                                : null}

                              {selectedLenderId / 1 == key.lender_id / 1 ? (
                                <div class="containercustom " data-ribbon="Selected"></div>
                              ) : null}


                              {key.financierAction != "Approved" ? <></> : (
                                <>

                                  <p className="font-size-12 mb-1 font-wt-600 text-color1">
                                    {`Limit ${key.financeLimit} ${key.financeLimitCurrency} upto ${key.discountingPercentage} % of invoice`}</p>
                                  <p className="font-size-14 mb-1">
                                    {`Offer Validity : ${key.offerValidity}`}</p>
                                  <p style={{ width: "70%", margin: "auto", borderBottom: "2px solid #5CB8D3" }} />
                                  <div className="mt-2 lineheight19">
                                    <p className="mb-0 text-secondary  font-size-12">Factoring Fees</p>
                                    <p className="font-size-16">{key.factoringFees ? (key.factoringFees + " " + key.factoringFeesCurrency) : "NA"}</p>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">Interest Rate
                                    </p>
                                    <span className="selectfinacne-subheading font-size-10">{"(Inclusive TERM for " + key.termSofr + ")"}</span>
                                    <p className="font-size-16">{key.interestRate ? (key.interestRate + " %") : "NA"}</p>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">One time set-up fees</p>
                                    {/* <span className="selectfinacne-subheading font-size-10">(negotiable)</span> */}
                                    <p className="font-size-16">{key.setupFees ? (key.setupFees + " " + key.setupFeesCurrency) : "NA"}</p>
                                  </div>
                                  <div className="position-relative lineheight19">
                                    <p
                                      className="mb-0 font-wt-600 font-size-12">Other Charges
                                    </p>
                                    <p className="font-size-16 totalChargesLabel">{getInvoiceTotalOtherCharges(key.otherCharges).toFixed(2) + " " + key.financeLimitCurrency}</p>
                                    <div className="otherChargePopup">
                                      {key.otherCharges && key.otherCharges.length ? key.otherCharges.map((j) => (
                                        <>
                                          <div className="border-bottom d-flex px-3 justify-content-between align-items-center pt-2">
                                            <label className="text-color-label">{j.name}</label>
                                            <label className="font-wt-600 text-color-value">{j.amount + " " + j.unit}</label>
                                          </div>
                                        </>
                                      )) : null}
                                    </div>
                                  </div>
                                  <div className="mt-0 lineheight19">
                                    <p className="mb-0 font-wt-600 font-size-12">Total Charges</p>
                                    <span className="selectfinacne-subheading font-size-10">($ 50,000/30 days)</span>
                                    <p className="font-size-16">{getInvoiceTotalCharges(key).toFixed(2) + " " + key.financeLimitCurrency}</p>
                                  </div>
                                  {/* <div>
                                      <p className="mb-0 font-size-14 font-wt-500">Offer validity
                                      </p>
                                      <p className="font-size-16">{key.offerValidity || "NA"}</p>
                                    </div> */}
                                </>
                              )}
                              {/* {key.financierAction === "Approved" ? ( */}
                              <>
                                {/* <div>
                                  {key.financierAction === "Approved" ? (
                                    <button
                                      onClick={() => { setData({ ...data, selectedFinancier: key.lender_id, selectedFinancierName: key.lender_name, selectedQuote: key }); setTab(tab + 1) }}
                                      className={`mx-2 new-btn w-50 py-2 px-2 text-white`}>Continue</button>
                                  ) : null}</div> */}
                                <p className="mt-2"><img className="ms-2" src={"assets/images/supplier-images/chat.png"} alt="" /><span className="font-size-14 text-dark ms-2">Chat with financer</span></p></>
                              {/* ) : (<></>
                                )} */}
                            </div>
                          </div>
                        );
                      }) : null}
                    </div>
                    <div className="col-12 mx-auto text-end">
                      <img onClick={() => firstindex !== 0 && Back()}
                        className={` cursor fs-5 ${firstindex === 0 ? "not-allowed d-none" : "cursor"
                          }`} src={"/assets/images/arrow-left.png"} alt="" />
                      <img onClick={() =>
                        Math.ceil(data.buyers_credit && data.buyers_credit.length / 4) !== slice && Next()
                      }
                        className={`cursor fs-5 ${slice === Math.ceil(data.buyers_credit && data.buyers_credit.length / 4)
                          ? "not-allowed d-none"
                          : "cursor"
                          }`} src={"/assets/images/arrow-right.png"} alt="arrow" />

                    </div>

                  </div>
                ) : null}
                {tab === 2 ? (
                  <div className='px-5 py-4 pt-5'>
                    {/* <div className="col-md-8 mb-3">
                      <label className="font-size-14">Term sheet</label>
                      <div className="row align-items-center ">
                        <div className="form-group col-md-8 mb-0">
                          <FileInput
                            changeEditIconToUpload={true}
                            name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                            onChange={handleFile} isEditable={localStorage.getItem("termSheetSignType") === "Wet"}
                            onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                          />
                          {errors.termSheetDocument ? <div class="text-danger mt-2">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors.termSheetDocument}</b></div> : ''}
                        </div>
                        {termSheetLink ? <>
                          {data.termSheetLinkSigned ? (
                            <div className="col-md-4 text-end">
                              <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                            </div>
                          ) : (
                            <div className="col-md-4 text-end">
                              <a onClick={() => {
                                setData({ ...data, termSheetLinkSigned: true })
                                window.open(termSheetLink, '_blank')
                              }}
                                className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                            </div>
                          )}
                        </> : data.termSheetDocument && data.termSheetDocument.name && !data.termSheetDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <a onClick={() => { setSigndoc(true); setData({ ...data, docToSign: data.termSheetDocument, docSignName: "termSheetDocument" }) }} className="text-custom2 cursor font-wt-600"><img className="ms-2" src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Sign document</a>
                          </div>
                        ) : null}
                        {data.termSheetDocument && data.termSheetDocument.signatureId ? (
                          <div className="col-md-4 text-end">
                            <p className="cursor text-secondary"><img className="ms-2 " src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" /> Document signed</p>
                          </div>
                        ) : null}
                      </div>
                    </div> */}

                    {/* <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler
                        changeEditIconToUpload={true}
                        titleNotEditable={true} notEditable={localStorage.getItem("termSheetSignType") != "Wet"}
                        purpose={"SendTermSheet"} purposeId={localStorage.getItem("applicationId")}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray2(docs)} />
                    </div> */}

                    {similarRequest?.length ?
                      <div className='mb-2 d-flex'
                        style={{ flexWrap: 'wrap' }}
                      >
                        <label className="font-wt-500 font-size-16 pt-1 mr-3" >Also Sign for:</label>
                        {similarRequest.map((item, index) => {
                          return (
                            <div className="d-flex flex-row align-items-center mb-3">
                              <div className="align-self-center">
                                <img
                                  onClick={() => {
                                    toastDisplay("Action not available", "info")
                                    // let temp = similarRequest
                                    // temp[index]["isChecked"] = !temp[index]["isChecked"]
                                    // setSimilarRequest([...temp])
                                  }}
                                  className='cursor mr-3' src={`assets/images/${item.isChecked ? 'checked-green' : 'empty-check'}.png`} />
                              </div>
                              <label className="font-wt-500 font-size-14 mr-4 align-self-center pt-1" for="currentDocuments">{item.buyerName}</label>
                            </div>
                          )
                        })}
                      </div> : null}

                    <div className='w-100 mt-3'>
                      <label className='font-wt-500 font-size-16'><u>Other Documents</u></label>
                      <div className='row mt-2'>
                        <div className="">
                          <NewTablev2 columns={[{ subColumns: 'Document Name', subColumnStyle: { width: '30%' } }, { subColumns: 'Attach Document', subColumnStyle: { width: '40%' } },
                          { subColumns: 'Sign', subColumnStyle: { width: '15%' } }, { subColumns: 'Action', subColumnStyle: { width: '15%' } }
                          ]}>
                            <tr>
                              <td>Term Sheet
                                {localStorage.getItem("termSheetSignType") === "Wet" ? (
                                  <div class="text-danger mb-4 font-size-10">
                                    <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    <b>{"Upload Signed & Stamped Termsheet"}</b>
                                  </div>
                                ) : null}
                              </td>
                              <td>
                                <FileInput
                                  changeEditIconToUpload={true}
                                  name={"termSheetDocument"} value={data.termSheetDocument} error={errors.termSheetDocument}
                                  onChange={handleFile} isEditable={localStorage.getItem("termSheetSignType") === "Wet"}
                                  onUploadCancel={() => setData({ ...data, "termSheetDocument": null })}
                                />
                                {errors.termSheetDocument ? <div class="text-danger mt-2">
                                  <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  <b>{errors.termSheetDocument}</b></div> : ''}
                              </td>
                              <td>
                                {!data[`termSheetDocument`]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data[`termSheetDocument`]?.name); setData({ ...data, docToSign: data[`termSheetDocument`], docSignName: `termSheetDocument` }) }}
                                    src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {data[`termSheetDocument`]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}
                              </div>
                              </td>
                            </tr>

                            {data.invReportDocument?.name ?
                              <tr>
                                <td>Investigation Document</td>
                                <td>
                                  <FileInput isEditable={false}
                                    name={"invReportDocument"} value={data["invReportDocument"]} />
                                </td>
                                <td>
                                  {!data[`invReportDocument`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`invReportDocument`]?.name); setData({ ...data, docToSign: data[`invReportDocument`], docSignName: `invReportDocument` }) }}
                                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data[`invReportDocument`]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invReportDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invReportDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div>
                                </td>
                              </tr>
                              : null}

                            {data.logisticDocument?.name ?
                              <tr>
                                <td>Bill of Lading/Airway Bill</td>
                                <td>
                                  <FileInput isEditable={false}
                                    name={"logisticDocument"} value={data["logisticDocument"]} />
                                </td>
                                <td>
                                  {!data[`logisticDocument`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`logisticDocument`]?.name); setData({ ...data, docToSign: data[`logisticDocument`], docSignName: `logisticDocument` }) }}
                                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data[`logisticDocument`]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`logisticDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`logisticDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div>
                                </td>
                              </tr>
                              : null}

                            {data.grnDocument?.name ?
                              <tr>
                                <td>Certificate of Origin</td>
                                <td>
                                  <FileInput isEditable={false}
                                    name={"grnDocument"} value={data["grnDocument"]} />
                                </td>
                                <td>
                                  {!data[`grnDocument`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`grnDocument`]?.name); setData({ ...data, docToSign: data[`grnDocument`], docSignName: `grnDocument` }) }}
                                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data[`grnDocument`]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`grnDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`grnDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div>
                                </td>
                              </tr>
                              : null}

                            {data.shippingBillDocument?.name ?
                              <tr>
                                <td>Shipping Bill</td>
                                <td>
                                  <FileInput isEditable={false}
                                    name={"shippingBillDocument"} value={data["shippingBillDocument"]} />
                                </td>
                                <td>
                                  {!data[`shippingBillDocument`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`shippingBillDocument`]?.name); setData({ ...data, docToSign: data[`shippingBillDocument`], docSignName: `shippingBillDocument` }) }}
                                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data[`shippingBillDocument`]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`shippingBillDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`shippingBillDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div>
                                </td>
                              </tr>
                              : null}

                            {data.packingListDocument?.name ?
                              <tr>
                                <td>Packaging List</td>
                                <td>
                                  <FileInput isEditable={false}
                                    name={"packingListDocument"} value={data["packingListDocument"]} />
                                </td>
                                <td>
                                  {!data[`packingListDocument`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`packingListDocument`]?.name); setData({ ...data, docToSign: data[`packingListDocument`], docSignName: `packingListDocument` }) }}
                                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data[`packingListDocument`]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`packingListDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`packingListDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div>
                                </td>
                              </tr>
                              : null}

                            {data.salesContractDocument?.name ?
                              <tr>
                                <td>Sales Contract</td>
                                <td>
                                  <FileInput isEditable={false}
                                    name={"salesContractDocument"} value={data["salesContractDocument"]} />
                                </td>
                                <td>
                                  {!data[`salesContractDocument`]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data[`salesContractDocument`]?.name); setData({ ...data, docToSign: data[`salesContractDocument`], docSignName: `salesContractDocument` }) }}
                                      src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data[`salesContractDocument`]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`salesContractDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data[`salesContractDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div>
                                </td>
                              </tr>
                              : null}
                          </NewTablev2>
                        </div>
                      </div>
                    </div>


                    <div className='w-100 mt-3'>
                      <label className='font-wt-500 font-size-16'><u>Invoice & PO Details</u></label>
                      <div className='row mt-2'>
                        <div className="">
                          <NewTablev2 columns={[{ subColumns: 'Document Name' }, { subColumns: 'Amount' }, { subColumns: 'Attach Document' },
                          ]}>
                            {multInvPo.map((i, j) => {
                              return <>
                                {i.invoices?.map((o, p) => {
                                  if (data["invoiceDocument" + j + ":" + p]?.name) {
                                    return (
                                      <tr>
                                        <td>{`Invoice - ${data["invoiceRefrenceNo" + j + ":" + p]}`}</td>
                                        <td>{`${data["contractAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`} </td>
                                        <td><div>
                                          <FileInput hideViewDowdBtns={false} isEditable={false}
                                            name={"invoiceDocument" + j + ":" + p} value={data["invoiceDocument" + j + ":" + p]}
                                            onChange={handleFile}
                                            onUploadCancel={() => setData({ ...data, ["invoiceDocument" + j + ":" + p]: null })}
                                          />
                                          {errors["invoiceDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            <b>{errors["invoiceDocument" + j + ":" + p]}</b></div> : ''}
                                        </div></td>
                                      </tr>
                                    )
                                  }
                                })}
                                {
                                  i.purchase_orders?.map((o, p) => {
                                    if (data["poDocument" + j + ":" + p]?.name) {
                                      return (
                                        <tr>
                                          <td>{`Purchase Order - ${data["poRefrenceNo" + j + ":" + p]}`}</td>
                                          <td>{`${data["poAmount" + j + ":" + p]} ${data["currency" + j + ":" + p]}`}</td>
                                          <td>
                                            <div>
                                              <FileInput hideViewDowdBtns={false}
                                                name={"poDocument" + j + ":" + p} value={data["poDocument" + j + ":" + p]} error={errors["poDocument" + j + ":" + p]}
                                                isEditable={false}
                                                onUploadCancel={() => setData({ ...data, ["poDocument" + j + ":" + p]: null })}
                                              />
                                              {errors["poDocument" + j + ":" + p] ? <div class="text-danger mt-2 font-size-12">
                                                <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                <b>{errors["poDocument" + j + ":" + p]}</b></div> : ''}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  })}
                              </>
                            })}
                          </NewTablev2>
                        </div>

                      </div>
                    </div>

                    {/* <div className="col-md-8 mb-3">
                      <AddMoreDocumentHandler
                        purpose={"ApplyForLimitInvoice"} purposeId={data.id}
                        userTokenDetails={userTokenDetails} onChange={(docs) => setMoreDocsArray(docs)}
                      />
                    </div> */}

                    {onlyViewMode ? null : (
                      <div className='mt-3'>
                        <img
                          onClick={() => setData({ ...data, tcAccepted: !data.tcAccepted })}
                          className='cursor mr-3' src={`assets/images/${data.tcAccepted ? 'checked-green' : 'empty-check'}.png`} />
                        <label>I hereby declare that the above given information by me is true to my knowledge.</label>
                      </div>
                    )}
                  </div>
                ) : null}
                {/* {tab === 3 ? (
                  <div className='p-5'>
                    <AdavancePaymentTab adavanceData={adavanceData} />
                  </div>
                ) : null} */}

                {tab === 2 && !data.tcAccepted ? null : (
                  <div className="pb-5 px-5">
                    <button type="button"
                      onClick={() => handleValidation(undefined)}
                      className={`new-btn w-17 py-2 px-2 text-white`}>
                      {tab === 2 ? "Submit" : "Continue"}
                    </button>
                  </div>
                )}

              </div>
            </div>
          </main>
        </div>
      </div >
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Finance limit for invoice discounting assigned..</p>
      </FinanceInvoiceModal>}
      {experience && <FinanceInvoiceModal limitinvoice={experience} setLimitinvoice={setExperience} closeSuccess={closeExpFn}>
        <p>How was your experience with <span className="text-custom">TradeRebootFin</span> ?</p>
        <div className="d-flex gap-3 justify-content-center">
          {EmojiData.map((key, index) => {

            return (
              <>
                <img src={emoji === key.name ? key.fillimage : key.image} alt="angry" key={index} className="cursor " onClick={() => setEmoji(key.name)} />
              </>
            );
          })}

        </div>
        {emoji !== "" && < div className="my-3 col-9 mx-auto text-end border px-0 send p-2">
          <textarea className="form-control border-0 bg-transparent" id="exampleFormControlTextarea1" rows="3" placeholder="Write a review..."></textarea>
          <img className="cursor px-1" src={"/assets/images/Send.png"} alt="" onClick={() => closeExpFn()} />
        </div>}
      </FinanceInvoiceModal >}


      {tooltip && <TooltipModal tooltip={tooltip} setTooltip={setTooltip}
        data={{ showRemark: data.showRemark, showLenderName: data.showLenderName }} />}

      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => {
          setData({ ...data, [data.docSignName]: updatedDoc })
          setErrors({ ...errors, [data.docSignName]: '' })
        }} />}

    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(SignContract)