import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../partial/headerV2";
import SideBarV2 from "../partial/sideBarV2";
import toastDisplay from "../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, finLogoByUserId, GetCache, getDocDetails, getFinancerAvailability, handleMultipleFile, isFormDataEmpty, mergeMultiplePdfs, most_used_currencies, replaceFileBase64WithFileObj, SetCache, toolTip } from "../../utils/myFunctions";
import call from '../../service';
import config from '../../config.json';
import { InputForTable, InputWithSelect, NewInput, NewSelect, NewTextArea, NewTextAreaForTable, SelectForTable } from "../../utils/newInput";
import { FileInput } from "../../utils/FileInput";
import SelectBuyerModal from "./components/selectBuyerModal";
import SignDocument from "./components/SignDocument";
import { ToastContainer } from "react-toastify";
import TutorialPopup, { TutorialVideoPopup } from "../tutorialPopup";
import moment from "moment";
import TooltipModal from "./components/Tooltipmodal";
import MultipleSelect from "../../utils/MultipleSelect";
import { aiServerBackendUrl, environment, platformURL } from "../../urlConstants";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import { AddMoreDocumentHandler } from "../addMoreDocumentHandler";
import { NewTable } from "../../utils/newTable";
import avatarUrl from "../../utils/makeAvatarUrl";
import { documentTable, documentTable2, documentTable3 } from "../Dashboard/TableJson";
import NewTablev2 from "../../utils/newTablev2";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { SmallFileInput } from "../../utils/SmallFileInput";
import axios from 'axios';
import { FileDragView } from "../../utils/FileDragView";
import FinanceInvoiceModal from "./contract/components/financeinvoiceModal";

export let yesnoqsts = {
  "qst1": {
    label: "The agreement between the client and the debtor does not prohibit assignment of receivables by the client to a third party including a Factoring Company."
  },
  "qst2": {
    label: "Any irregular and slow payments in the past by the proposed debtor."
  },
  "qst3": {
    label: "Are there any overdue invoices outstanding on account of the debtor in the client’s books."
  },
  "qst4": {
    label: "The overseas debtor is not related to the client, in any way."
  },
  "qst5": {
    label: "Whether there have been any disputes with the debtor in recent past with respect to the goods supplied by the client to the debtor or otherwise."
  },
  "qst6": {
    label: "Whether direct contact with buyer is allowed by the financier."
  }
}

export let yesnoqsts2 = {
  "qst1": {
    label: "Company Incorporation Date",
    hide: true
  },
  "qst2": {
    label: "Does your company already have any loans?"
  },
  "qst3": {
    label: "What is last cibil of all promoters which you know?"
  },
  "qst4": {
    label: "Any Overdue of Company in last 2 years"
  },
  "qst5": {
    label: "Any EMI Bounce of promoters in last 12 months"
  },
  "qst6": {
    label: "Do you know your Company Bureau Score? If yes what is score?"
  },
  "qst7": {
    label: "Any legal case on company for EMI Bounce?"
  },
  "qst8": {
    label: "Loan enquiries in last 3 months on behalf of your company?"
  },
  "qst9": {
    label: "Has turnover increased after you took last loan?"
  },
  "qst10": {
    label: "Any company Emi Bounces in last 12 months?If yes, how many?"
  },
  "qst11": {
    label: "Any delayed payments of EMI of your company in last 12 months?"
  },
  "qst12": {
    label: "Any Cheque bounces in last 2 years. If yes, how many?"
  },
  "qst13": {
    label: "Any legal case on company for cheque bounce?"
  },
  "qst14": {
    label: "Has there been Q-O-Q increase in Revenue?"
  },
  "qst15": {
    label: "Any current offer from any Banks or NBFC. If yes, what is the interest rate offered?"
  },
  "qst16": {
    label: "Is your company rated(AAA, BBB etc). If yes, what is the rating?"
  },
  "qst17": {
    label: "Any GST non-filling since inception?"
  }
}

let docName = {
  "doc1": "Last 3 years monthly/yearly GST Reports",
  "doc2": "Experian & Cibil Report",
  "doc3": "Bank statement of all working capital & Current accounts (last 12 months)",
  "doc4": "Limit details & sanctioned letter of existing banking lines",
  "doc5": "Last 3 years Audited financials including ITR",
  "doc6": "Sales Ledger with all the buyer (last 12 months)",
  "doc7": "Stock statement shared with banks ageing breakup of debtor /creditors (last 2 months)",
  "doc8": "Partnership deed (in case of partnership firm)",
  "doc9": "Aadhar & PAN documents of all partners",
  "doc10": "Any other company related documents"
}

let docDesc = {
  "doc1": "A summary of the monthly and yearly Goods and Services Tax (GST) reports for the past three years.",
  "doc2": `<p>Experian & CIBIL Report: Credit reports provided by Experian and CIBIL, containing detailed information about an individual's credit history and credit score.</p>
  <ul>
    <li><a href='https://drive.google.com/file/d/1SfL_n-kB-aXMd2R4og3gRAcNVeRv-IY6/view?usp=sharing' target="_blank">How to get an Experian report on WhatsApp: Video demo.</a></li>
    <li><a href='https://wa.me/9920035444?text=Hi' target="_blank">Get Experian Report on Whatsapp</a></li>
    <li><a href="https://www.cibil.com/freecibilscore" target="_blank">Get CIBIL Report</a></li>
  </ul>`,
  "doc3": "A summary of bank statements for all working capital and current accounts over the past 12 months.",
  "doc4": "Details of existing credit limits and the sanction letters for current banking lines.",
  "doc5": "Audited financial statements and income tax returns for the past three years.",
  "doc6": "Sales ledger detailing all buyers for the past 12 months.",
  "doc7": "Stock statements shared with banks, including an aging breakdown of debtors (customers who owe money) and creditors (suppliers to whom money is owed) for the last 2 months.",
  "doc8": "The legal document outlining the terms, conditions, and responsibilities of the partners in a partnership firm.",
  "doc9": "Aadhar and PAN identification documents for all partners in the partnership firm.",
  "doc10": "Any additional documents related to the company's operations, structure, or compliance."
}

let currentYear = moment().format("YYYY")

let financialYearMonths = [{ name: "April", lastYear: true, month: 4 }, { name: "May", lastYear: true, month: 5 }, { name: 'June', lastYear: true, month: 6 }, { name: 'July', lastYear: true, month: 7 },
{ name: "August", lastYear: true, month: 8 }, { name: "Sept", lastYear: true, month: 9 }, { name: "Oct", lastYear: true, month: 10 },
{ name: "Nov", lastYear: true, month: 11 }, { name: 'Dec', lastYear: true, month: 12 }, { name: 'Jan', month: 1 }, { name: "Feb", month: 2 }, { name: "March", month: 3 }]

let last5Years = [
  { label: `${currentYear}-${currentYear / 1 + 1}`, fromYear: currentYear, toYear: currentYear / 1 + 1 },
  { label: `${currentYear - 1}-${currentYear}`, fromYear: currentYear - 1, toYear: currentYear },
  { label: `${currentYear - 2}-${currentYear - 1}`, fromYear: currentYear - 2, toYear: currentYear - 1 },
  { label: `${currentYear - 3}-${currentYear - 2}`, fromYear: currentYear - 3, toYear: currentYear - 2 },
  { label: `${currentYear - 4}-${currentYear - 3}`, fromYear: currentYear - 4, toYear: currentYear - 3 },
  { label: `${currentYear - 5}-${currentYear - 4}`, fromYear: currentYear - 5, toYear: currentYear - 4 }]

const ExtraDetailsForLimitApplication = ({ isCGTMSE, controlBasedOnStage, hideInvestigationDocs, hideInsuranceDocs, hideDiscountingDocs, showContractPO, showContractInvoice, showContractAgreement, showShipmentDocs, contractNo, manualUserId, userTokenDetails, navToggleState, hideHeader, hideSubmitDiv, hideSideBar, hideBackArrow,
  cardBorder0, cardPadding0, expandAll, showContinueButton, afterDetailsSavedOn, mainTagMarginPadding0, isDisabled, gstCredentialsViewHide, gstDoc1Hide, bankDoc1Hide,
  bankDoc2Hide, bankDoc3Hide, finDoc1Hide, fintDoc2Hide, finDoc3Hide, finDoc4Hide, orgDoc1Hide, orgDoc2Hide, orgDoc3Hide, orgDocLabel,
  onSave, hideToastAfterDetailsSaved, continueBtnLabel, loadDataInWriteModeFromCacheIfPresent, continueBtnCSS, contractClientType, unkTypeHide }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let buyerId = queryParams.get('buyerId')
  let isAdmin = queryParams.get('adminINVApply') === 'true'
  buyerId = buyerId ? buyerId.split(",") : []
  let contractId = queryParams.get('contractNo') || contractNo

  // console.log("buyerIddddddddddddddddddddddddddd", buyerId);

  const userData = localStorage.getItem("UserDetails")
  const adminINVApply = localStorage.getItem('adminINVApply') ? true : false
  let userToken = userTokenDetails
  if (userTokenDetails.type_id === 1) {
    userToken = userData ? JSON.parse(userData) : userTokenDetails
  }
  const userTypeId = userToken.type_id ? userToken.type_id : null
  const userEmail = userToken.email ? userToken.email : null
  const userId = manualUserId || (userToken.user_id ? userToken.user_id : null)
  const userName = userToken.userName ? userToken.userName : null
  const subUserId = userToken.sub_user_id ? userToken.sub_user_id : null;
  const parentData = userToken.parent_data ? userToken.parent_data : null;
  const ttvExporterCode = userToken.ttvExporterCode ? userToken.ttvExporterCode : ''
  const subUserName = userToken?.sub_user_name
  const mainUserName = userToken?.main_user_name

  const [data, setData] = useState({
    sameAnsForAllBuyers: true,
    "gstDoc:0Year": last5Years[0]["label"], "gstDoc:1Year": last5Years[1]["label"],
    "finDoc1:0Year": last5Years[0]["label"], "finDoc1:1Year": last5Years[1]["label"], "finDoc1:2Year": last5Years[2]["label"],
  })
  const [bulkFiles, setBulkFiles] = useState([])
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })

  const [chanceOfApproval, setChanceOfApproval] = useState(40)
  const [gstToggle, setGstToggle] = useState(0)

  const [gstReturns, setGstReturns] = useState([null, null])
  const [signdoc, setSigndoc] = useState(false);

  const [bankDocs1, setBankDocs1] = useState([null])
  const [bankDocs2, setBankDocs2] = useState([null])
  const [bankDocs3, setBankDocs3] = useState([null])

  const [financialDoc1, setFinancialDoc1] = useState([null, null, null])
  const [financialDoc2, setFinancialDoc2] = useState([null])
  const [financialDoc3, setFinancialDoc3] = useState([null])
  const [financialDoc4, setFinancialDoc4] = useState([null])

  const [orgDoc1, setOrgDoc1] = useState([null])
  const [orgDoc2, setOrgDoc2] = useState([null])
  const [orgDoc3, setOrgDoc3] = useState([null])

  const [unkType, setUnkType] = useState([null])

  const [buyerDetails, setBuyerDetails] = useState([])

  const [addBankPopup, setAddBankPopup] = useState({ show: false, data: {} })
  const [shouldSaveCache, setShouldSaveCache] = useState(false)
  const [cpAgreementDoc, setCPAgreementDoc] = useState({})
  const [docTypes, setDocTypes] = useState(docName)
  const [docExplain, setDocExplain] = useState(docDesc)
  const [experianTutorialPopup, setExperianTutorialPopup] = useState({ link: 'https://drive.google.com/file/d/1SfL_n-kB-aXMd2R4og3gRAcNVeRv-IY6/view?usp=sharing' })
  const [gstPortalLoginPopup, setGstPortalLoginPopup] = useState({ show: false })


  useEffect(() => {
    if (isCGTMSE) {
      setDocTypes({
        "doc1": "Current financial year monthly/yearly GST Reports*",
        "doc2": "Experian & Cibil Report*",
        "doc3": 'Complete Collection of Income TAX Return of last financial Year*'
      })
      setDocExplain({
        "doc1": "A summary of the monthly or yearly Goods and Services Tax (GST) reports for the current financial year.",
        "doc2": `<p>Experian & CIBIL Report: Credit reports provided by Experian and CIBIL, containing detailed information about an individual's credit history and credit score.</p>
  <ul>
    <li><a href='https://drive.google.com/file/d/1SfL_n-kB-aXMd2R4og3gRAcNVeRv-IY6/view?usp=sharing' target="_blank">How to get an Experian report on WhatsApp: Video demo.</a></li>
    <li><a href='https://wa.me/9920035444?text=Hi' target="_blank">Get Experian Report on Whatsapp</a></li>
    <li><a href="https://www.cibil.com/freecibilscore" target="_blank">Get CIBIL Report</a></li>
  </ul>`,
        "doc3": 'A comprehensive compilation of all income tax returns filed for the previous financial year.'
      })
    }
  }, [isCGTMSE])

  useEffect(() => {
    call('POST', 'getChannelPartnerSignedAgreement', { userEmail }).then(res => {
      if (res?.id) {
        getDocDetails(res.id).then(doc => { setCPAgreementDoc(doc) })
      }
    })
  }, [])

  useEffect(() => {
    if (buyerId.length) {
      getBuyerDetailsById()
    }
  }, [])

  async function getBuyerDetailsById() {
    let tempBuyerDetails = []
    for (let index = 0; index < buyerId.length; index++) {
      tempBuyerDetails[index] = await call('POST', 'getBuyerDetailsById', { buyerId: buyerId[index] })
      tempBuyerDetails[index] = tempBuyerDetails[index]?.[0] || {}
    }
    setBuyerDetails(tempBuyerDetails)
  }

  useEffect(() => {
    if (!isDisabled && loadDataInWriteModeFromCacheIfPresent && shouldSaveCache) {
      // saveCache()
    }
  }, [data, gstReturns, bankDocs1, bankDocs2, bankDocs3, financialDoc1, financialDoc2, financialDoc3, financialDoc4, orgDoc1, orgDoc2, orgDoc3])

  async function saveCache() {
    let reqBody = { userId, forAdditionalDocs: true }
    reqBody["details"] = { data, gstReturns, bankDocs1, bankDocs2, bankDocs3, financialDoc1, financialDoc2, financialDoc3, financialDoc4, orgDoc1, orgDoc2, orgDoc3 }
    await call('POST', 'saveInvoiceLimitCache', reqBody)
  }

  async function loadUserExtraDetails() {
    let tempData = data
    // setshowLoader(true)
    // Load contract docs start
    if (contractId) {
      let contractDocApiResp = await call('POST', 'getContractDocs', { contractId })
      for (let index = 0; index < contractDocApiResp?.length; index++) {
        const element = contractDocApiResp[index];
        let docDetails = await getDocDetails(element.docId, null, null, contractClientType)
        tempData[docDetails.gen_doc_label] = docDetails
      }
    }
    // Load contract docs end

    // Set Partner names from karza 
    let directors = await call('POST', 'getPartnerDetailsFromKarza', { userId })
    for (let index = 0; index < directors?.length; index++) {
      tempData["partnerName:" + index] = directors[index]["name"]
    }
    let apiResp = await call("POST", 'getUserExtraDocs', { userId, buyerId: buyerId.length ? buyerId : undefined })
    tempData = { ...tempData, gstDetailsFetched: apiResp?.gstDetailsFetched }
    if (!apiResp?.id) {
      setShouldSaveCache(true)
    }
    if (apiResp?.yesNoQsts) {
      tempData = { ...tempData, ...apiResp?.yesNoQsts }
    }
    if (apiResp?.yesNoQsts2) {
      tempData = { ...tempData, ...apiResp?.yesNoQsts2 }
    }
    if (apiResp?.details) {
      tempData = { ...tempData, ...apiResp?.details }
    }
    if (apiResp?.gst) {
      let gstArr = []
      for (let index = 0; index < apiResp.gst?.doc.length; index++) {
        const docId = apiResp.gst?.doc[index];
        tempData["gstDoc:" + index] = await getDocDetails(docId)
        tempData["gstDoc:" + index + "Year"] = apiResp.gst.year[index]
        gstArr[index] = null
      }
      for (let index = 0; index < apiResp.gst?.monthWiseDoc.length; index++) {
        const row = apiResp.gst.monthWiseDoc[index]
        for (let j = 0; j < row.length; j++) {
          tempData[`gstDoc:${index}:${j}Monthly`] = await getDocDetails(row[j])
          tempData["gstDoc:" + index + "Year"] = apiResp.gst.year[index]
        }
        gstArr[index] = null
      }
      if (gstArr.length) {
        setGstReturns(gstArr)
      }
      if (gstArr.length / 1 == 1) {
        gstArr.push(null)
      }
    }
    if (apiResp?.bank) {
      let bankDoc1Arr = []
      let bankDoc2Arr = []
      let bankDoc3Arr = []
      for (let index = 0; index < apiResp.bank?.doc1.length; index++) {
        const docId = apiResp.bank?.doc1[index];
        tempData["bankDoc1:" + index] = await getDocDetails(docId)
        // Getting bank details 
        let bankDetails = await call('POST', 'getUserBankById', { bankId: apiResp.bank.doc1BankId?.[index] })
        tempData[`bankName${index}`] = bankDetails?.beneficiaryBankName
        tempData[`branch${index}`] = bankDetails?.beneficiaryBranch
        tempData[`accountHolderName${index}`] = bankDetails?.beneficiaryAccountName
        tempData[`accountNo${index}`] = bankDetails?.beneficiaryAccountNo
        tempData[`ifscCode${index}`] = bankDetails?.beneficiaryIfscCode
        tempData[`swiftCode${index}`] = bankDetails?.beneficiarySwiftCode
        bankDoc1Arr.push(null)
      }
      for (let index = 0; index < apiResp.bank?.doc2.length; index++) {
        const docId = apiResp.bank?.doc2[index];
        tempData["bankDoc2:" + index] = await getDocDetails(docId)
        bankDoc2Arr.push(null)
      }
      for (let index = 0; index < apiResp.bank?.doc3.length; index++) {
        const docId = apiResp.bank?.doc3[index];
        tempData["bankDoc3:" + index] = await getDocDetails(docId)
        tempData["bankDoc3:" + index + "Name"] = tempData["bankDoc3:" + index]?.doc_name
        bankDoc3Arr.push(null)
      }
      if (bankDoc1Arr.length) { setBankDocs1(bankDoc1Arr) }
      if (bankDoc2Arr.length) { setBankDocs2(bankDoc2Arr) }
      if (bankDoc3Arr.length) { setBankDocs3(bankDoc3Arr) }
    }
    if (apiResp?.financial) {
      let doc1Arr = []
      let doc2Arr = []
      let doc3Arr = []
      let doc4Arr = []
      for (let index = 0; index < apiResp.financial.doc1.length; index++) {
        const docId = apiResp.financial.doc1[index];
        tempData["finDoc1:" + index] = await getDocDetails(docId)
        tempData["finDoc1:" + index + "Year"] = apiResp.financial?.doc1Year?.[index]
        doc1Arr.push(null)
      }
      for (let index = 0; index < apiResp.financial.doc2.length; index++) {
        const docId = apiResp.financial.doc2[index];
        tempData["finDoc2:" + index] = await getDocDetails(docId)
        doc2Arr.push(null)
      }
      for (let index = 0; index < apiResp.financial.doc3.length; index++) {
        const docId = apiResp.financial.doc3[index];
        tempData["finDoc3:" + index] = await getDocDetails(docId)
        doc3Arr.push(null)
      }
      for (let index = 0; index < apiResp.financial.doc4.length; index++) {
        const docId = apiResp.financial.doc4[index];
        tempData["finDoc4:" + index] = await getDocDetails(docId)
        tempData["finDoc4:" + index + "Name"] = tempData["finDoc4:" + index]?.doc_name
        doc4Arr.push(null)
      }
      if (doc1Arr.length) { setFinancialDoc1(doc1Arr) }
      if (doc2Arr.length) { setFinancialDoc2(doc2Arr) }
      if (doc3Arr.length) { setFinancialDoc3(doc3Arr) }
      if (doc4Arr.length) { setFinancialDoc4(doc4Arr) }
    }
    if (apiResp?.organizational) {
      let doc1Arr = []
      let doc2Arr = []
      let doc3Arr = []
      for (let index = 0; index < apiResp.organizational.doc1.length; index++) {
        const docId = apiResp.organizational.doc1[index];
        tempData["orgDoc1:" + index] = await getDocDetails(docId)
        doc1Arr.push(null)
      }
      for (let index = 0; index < apiResp.organizational.partnerName.length; index++) {
        const partnerName = apiResp.organizational.partnerName[index];
        tempData["aadharDoc:" + index] = await getDocDetails(apiResp.organizational.aadharDoc[index])
        tempData["panDoc:" + index] = await getDocDetails(apiResp.organizational.panDoc[index])
        tempData["partnerName:" + index] = partnerName
        tempData[`aadharDoc:${index}DocNo`] = apiResp.organizational?.aadharId?.[index] || ""
        tempData[`panDoc:${index}DocNo`] = apiResp.organizational?.panId?.[index] || ""
        doc2Arr.push(null)
      }
      for (let index = 0; index < apiResp.organizational.doc3.length; index++) {
        const docId = apiResp.organizational.doc3[index];
        tempData["orgDoc3:" + index] = await getDocDetails(docId)
        tempData["orgDoc3:" + index + "Name"] = tempData["orgDoc3:" + index]?.doc_name
        doc3Arr.push(null)
      }
      if (doc1Arr.length) { setOrgDoc1(doc1Arr) }
      if (doc2Arr.length) { setOrgDoc2(doc2Arr) }
      if (doc3Arr.length) { setOrgDoc3(doc3Arr) }
    }
    if (apiResp?.other) {
      let unkDocArr = []
      for (let index = 0; index < apiResp.other?.unknown.length; index++) {
        const docId = apiResp.other?.unknown[index];
        tempData["unkType:" + index] = await getDocDetails(docId)
        tempData["unkType:" + index + "Name"] = tempData["unkType:" + index]?.doc_name
        unkDocArr.push(null)
      }
      if (unkDocArr.length) { setUnkType(unkDocArr) }
    }
    setData(tempData)
    setshowLoader(false)
  }

  useEffect(() => {
    updateChanceOfApproval()
  }, [data])

  async function updateChanceOfApproval() {
    let tempChanceOfApproval = 40
    let yesNoQsts = {}
    let yesNoQstLabels = Object.keys(yesnoqsts)
    for (let index = 0; index < yesNoQstLabels.length; index++) {
      const element = yesNoQstLabels[index];
      if (data[element] === true) {
        yesNoQsts[element] = true
      }
      if (data[element] === false) {
        yesNoQsts[element] = false
      }
    }
    if (Object.keys(yesNoQsts)?.length) {
      tempChanceOfApproval += Object.keys(yesNoQsts).length
    }
    if (data.gstDetailsFetched) {
      tempChanceOfApproval += 5
    }
    for (let index = 0; index < gstReturns.length; index++) {
      if (data[`gstDoc:${index}Year`] && data[`gstDoc:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < bankDocs1.length; index++) {
      if (data[`bankDoc1:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < bankDocs2.length; index++) {
      if (data[`bankDoc2:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < bankDocs3.length; index++) {
      if (data[`bankDoc3:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < financialDoc1.length; index++) {
      if (data[`finDoc1:${index}`]?.name && data[`finDoc1:${index}Year`]) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < financialDoc2.length; index++) {
      if (data[`finDoc2:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < financialDoc3.length; index++) {
      if (data[`finDoc3:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < financialDoc4.length; index++) {
      if (data[`finDoc4:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }

    for (let index = 0; index < orgDoc1.length; index++) {
      if (data[`orgDoc1:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    for (let index = 0; index < orgDoc2.length; index++) {
      if (data[`aadharDoc:${index}`]?.name) {
        tempChanceOfApproval += 1
      }
      if (data[`panDoc:${index}`]?.name) {
        tempChanceOfApproval += 1
      }
    }
    for (let index = 0; index < orgDoc3.length; index++) {
      if (data[`orgDoc3:${index}`]?.name) {
        tempChanceOfApproval += 2.5
      }
    }
    setChanceOfApproval(tempChanceOfApproval < 80 ? tempChanceOfApproval : 80)
  }

  useEffect(() => {
    // if (loadDataInWriteModeFromCacheIfPresent && !isDisabled) {
    //   call('POST', 'getInvoiceLimitCacheById', { forAdditionalDocs: true }).then(cacheDetails => {
    //     if (cacheDetails?.id && cacheDetails?.details) {
    //       setData(replaceFileBase64WithFileObj(cacheDetails.details.data))
    //       setGstReturns(cacheDetails.details.gstReturns)
    //       setBankDocs1(cacheDetails.details.bankDocs1)
    //       setBankDocs2(cacheDetails.details.bankDocs2)
    //       setBankDocs3(cacheDetails.details.bankDocs3)

    //       setFinancialDoc1(cacheDetails.details.financialDoc1)
    //       setFinancialDoc2(cacheDetails.details.financialDoc2)
    //       setFinancialDoc3(cacheDetails.details.financialDoc3)
    //       setFinancialDoc4(cacheDetails.details.financialDoc4)

    //       setOrgDoc1(cacheDetails.details.orgDoc1)
    //       setOrgDoc2(cacheDetails.details.orgDoc2)
    //       setOrgDoc3(cacheDetails.details.orgDoc3)
    //       setShouldSaveCache(true)
    //     }
    //     else {
    //       loadUserExtraDetails()
    //     }
    //   }).catch(err => { loadUserExtraDetails() })
    // }
    // else {
    loadUserExtraDetails()
    // }
  }, [])

  useEffect(() => {
    if (gstPortalLoginPopup.show) {
      loadCaptchaEnginge(6);
    }
  }, [gstPortalLoginPopup.show])

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  const handleFileWithAsyncAwait = async (event) => {
    // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
    return new Promise((resolve, reject) => {
      event.persist()
      if (!event.target.files.length) {
        resolve({ status: 0, msg: { [event.target.name]: 'Kindly Select File' } })
        return null
      }
      else {
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
          resolve({ status: 0, msg: { [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" } })
          return null
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
          let fileObj = event.target.files[0]
          let fileDataUrl = e.target.result
          if (file_type.includes("png") || file_type.includes("jpeg")) {
            let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
            console.log("pdfconversionresp", response);
            fileObj = response["file"]
            fileDataUrl = response['dataUrl']
            toastDisplay("File converted into pdf format", "success")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            try {
              setshowLoader(true)
              let formData = new FormData()
              formData.append("file", fileObj)
              if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                formData.append("docType", "docx")
              }
              if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                formData.append("docType", "xlsx")
              }
              let apiResp = await call('POST', "docToPdf", formData)
              let blob = dataURItoBlob(apiResp);
              fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
              fileDataUrl = apiResp
              toastDisplay("File converted into pdf format", "success")
            } catch (error) {
              return toastDisplay("failed to convert file", "error")
            }
            setshowLoader(false)
          }
          fileObj["filebase64"] = fileDataUrl
          // merge current po with previous data start
          let tempData = { [event.target.name]: fileObj }
          // merge current po with previous data end
          resolve({ status: 1, msg: tempData })
          return null
        }
      }
    })
  }

  async function validateUserLogin() {
    let validateFields = ["gstUsername", "gstPassword", "typedCaptcha"]
    let err = {}
    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = "Mandatory Field"
      }
    }
    if (data.typedCaptcha && validateCaptcha(data.typedCaptcha) != true) {
      err["typedCaptcha"] = "Invalid Captcha"
    }
    if (!Object.keys(err)?.length) {
      // setshowLoader(true)
      toastDisplay("Fetching GST details in background", "info")
      setGstPortalLoginPopup({ show: false })
      call('POST', 'fetchUserGSTReport', { userId, gstUsername: data.gstUsername, gstPassword: data.gstPassword })
        .then(res => {
          setData({ ...data, gstDetailsFetched: true })
          toastDisplay("GST details fetched", "success")
          setshowLoader(false)
        }).catch(err => {
          toastDisplay("Unable to fetch GST details, check if credentials are proper", "error")
          setshowLoader(false)
        })
    }
    setErrors(err)
  }

  useEffect(() => {
    if (onSave) {
      saveDetails()
    }
  }, [onSave])

  async function saveDetails() {
    let formData = new FormData()

    let yesNoQsts = {}
    let yesNoQstLabels = Object.keys(yesnoqsts)

    if (buyerId.length) {
      for (let j = 0; j < buyerId.length; j++) {
        for (let index = 0; index < yesNoQstLabels.length; index++) {
          const element = yesNoQstLabels[index];
          if (data[`${element}:${buyerId[j]}`] === true) {
            yesNoQsts[`${element}:${buyerId[j]}`] = true
          }
          if (data[`${element}:${buyerId[j]}`] === false) {
            yesNoQsts[`${element}:${buyerId[j]}`] = false
          }
        }
      }
    }
    if (Object.keys(yesNoQsts)?.length) {
      formData.append([`yesNoQsts`], JSON.stringify(yesNoQsts))
    }

    let yesNoQsts2 = {}
    let yesNoQstLabels2 = Object.keys(yesnoqsts2)
    let details = {}

    for (let index = 0; index < Object.keys(docTypes).length; index++) {
      details[`isDocPresent${index}`] = data[`isDocPresent${index}`]
      details[`docComment${index}`] = data[`docComment${index}`]
    }

    formData.append('details', JSON.stringify(details))

    for (let index = 0; index < yesNoQstLabels2.length; index++) {
      const element = yesNoQstLabels2[index];
      yesNoQsts2[`${element}`] = data[element]
      yesNoQsts2[`${element}:Comments`] = data[`${element}:Comments`]
    }

    if (Object.keys(yesNoQsts2)?.length) {
      formData.append([`yesNoQsts2`], JSON.stringify(yesNoQsts2))
    }

    for (let index = 0; index < gstReturns.length; index++) {
      if (data[`gstDoc:${index}Year`] && data[`gstDoc:${index}`]?.name) {
        if (!formData.has(`gstDoc:${index}Year`)) {
          formData.append(`gstDoc:${index}Year`, data[`gstDoc:${index}Year`])
        }
        formData.append(`gstDoc:${index}`, data[`gstDoc:${index}`])
        if (data[`gstDoc:${index}`]?.signatureId) {
          formData.append(`gstDoc:${index}SignId`, data[`gstDoc:${index}`].signatureId)
        }
      }
      for (let j = 0; j < financialYearMonths.length; j++) {
        if (data[`gstDoc:${index}Year`] && data[`gstDoc:${index}:${j}Monthly`]?.name) {
          if (!formData.has(`gstDoc:${index}Year`)) {
            formData.append(`gstDoc:${index}Year`, data[`gstDoc:${index}Year`])
          }
          formData.append(`gstDoc:${index}:${j}Monthly`, data[`gstDoc:${index}:${j}Monthly`])
        }
      }
    }

    for (let index = 0; index < bankDocs1.length; index++) {
      if (data[`bankDoc1:${index}`]?.name) {
        formData.append(`bankDoc1:${index}`, data[`bankDoc1:${index}`])
        if (data[`bankDoc1:${index}`]?.signatureId) {
          formData.append(`bankDoc1:${index}SignId`, data[`bankDoc1:${index}`].signatureId)
        }
        if (data[`accountNo${index}`]) {
          formData.append(`bankDoc1:${index}Details`, JSON.stringify({
            "bank_name": data[`bankName${index}`],
            "bank_branch_name": data[`branch${index}`],
            "account_holder_name": data[`accountHolderName${index}`],
            "account_number": data[`accountNo${index}`],
            "ifsc_code": data[`ifscCode${index}`],
            "swift_code": data[`swiftCode${index}`],
            userId, userEmail
          }))
        }
      }
    }
    for (let index = 0; index < bankDocs2.length; index++) {
      if (data[`bankDoc2:${index}`]?.name) {
        formData.append(`bankDoc2:${index}`, data[`bankDoc2:${index}`])
        if (data[`bankDoc2:${index}`]?.signatureId) {
          formData.append(`bankDoc2:${index}SignId`, data[`bankDoc2:${index}`].signatureId)
        }
      }
    }
    for (let index = 0; index < bankDocs3.length; index++) {
      if (data[`bankDoc3:${index}`]?.name) {
        formData.append(`bankDoc3:${index}`, data[`bankDoc3:${index}`])
        if (data[`bankDoc3:${index}`]?.signatureId) {
          formData.append(`bankDoc3:${index}SignId`, data[`bankDoc3:${index}`].signatureId)
        }
        formData.append(`bankDoc3:${index}Name`, data[`bankDoc3:${index}Name`] || "Add More")
      }
    }

    for (let index = 0; index < financialDoc1.length; index++) {
      if (data[`finDoc1:${index}`]?.name && data[`finDoc1:${index}Year`]) {
        formData.append(`finDoc1:${index}`, data[`finDoc1:${index}`])
        formData.append(`finDoc1:${index}Year`, data[`finDoc1:${index}Year`])
        if (data[`finDoc1:${index}`]?.signatureId) {
          formData.append(`finDoc1:${index}SignId`, data[`finDoc1:${index}`].signatureId)
        }
      }
    }
    for (let index = 0; index < financialDoc2.length; index++) {
      if (data[`finDoc2:${index}`]?.name) {
        formData.append(`finDoc2:${index}`, data[`finDoc2:${index}`])
        if (data[`finDoc2:${index}`]?.signatureId) {
          formData.append(`finDoc2:${index}SignId`, data[`finDoc2:${index}`].signatureId)
        }
      }
    }
    for (let index = 0; index < financialDoc3.length; index++) {
      if (data[`finDoc3:${index}`]?.name) {
        formData.append(`finDoc3:${index}`, data[`finDoc3:${index}`])
        if (data[`finDoc3:${index}`]?.signatureId) {
          formData.append(`finDoc3:${index}SignId`, data[`finDoc3:${index}`].signatureId)
        }
      }
    }
    for (let index = 0; index < financialDoc4.length; index++) {
      if (data[`finDoc4:${index}`]?.name) {
        formData.append(`finDoc4:${index}`, data[`finDoc4:${index}`])
        if (data[`finDoc4:${index}`]?.signatureId) {
          formData.append(`finDoc4:${index}SignId`, data[`finDoc4:${index}`].signatureId)
        }
        formData.append(`finDoc4:${index}Name`, data[`finDoc4:${index}Name`] || "Add More")
      }
    }

    for (let index = 0; index < orgDoc1.length; index++) {
      if (data[`orgDoc1:${index}`]?.name) {
        formData.append(`orgDoc1:${index}`, data[`orgDoc1:${index}`])
        if (data[`orgDoc1:${index}`]?.signatureId) {
          formData.append(`orgDoc1:${index}SignId`, data[`orgDoc1:${index}`].signatureId)
        }
      }
    }
    for (let index = 0; index < orgDoc2.length; index++) {
      if (data[`aadharDoc:${index}`]?.name || data[`panDoc:${index}`]?.name) {
        formData.append(`partnerName:${index}`, data[`partnerName:${index}`] || "Partner " + (index + 1))
      }
      if (data[`aadharDoc:${index}`]?.name) {
        formData.append(`orgDoc2Aadhar:${index}`, data[`aadharDoc:${index}`])
        formData.append(`aadharDoc:${index}DocNo`, data[`aadharDoc:${index}DocNo`])
        if (data[`aadharDoc:${index}`]?.signatureId) {
          formData.append(`orgDoc2Aadhar:${index}SignId`, data[`aadharDoc:${index}`].signatureId)
        }
      }
      if (data[`panDoc:${index}`]?.name) {
        formData.append(`orgDoc2Pan:${index}`, data[`panDoc:${index}`])
        formData.append(`panDoc:${index}DocNo`, data[`panDoc:${index}DocNo`])
        if (data[`panDoc:${index}`]?.signatureId) {
          formData.append(`orgDoc2Pan:${index}SignId`, data[`panDoc:${index}`].signatureId)
        }
      }
    }
    for (let index = 0; index < orgDoc3.length; index++) {
      if (data[`orgDoc3:${index}`]?.name) {
        formData.append(`orgDoc3:${index}`, data[`orgDoc3:${index}`])
        if (data[`orgDoc3:${index}`]?.signatureId) {
          formData.append(`orgDoc3:${index}SignId`, data[`orgDoc3:${index}`].signatureId)
        }
        formData.append(`orgDoc3:${index}Name`, data[`orgDoc3:${index}Name`] || "Add More")
      }
    }
    for (let index = 0; index < unkType.length; index++) {
      if (data[`unkType:${index}`]?.name) {
        formData.append(`unkType:${index}`, data[`unkType:${index}`])
        if (data[`unkType:${index}`]?.signatureId) {
          formData.append(`unkType:${index}SignId`, data[`unkType:${index}`].signatureId)
        }
        formData.append(`unkType:${index}Name`, data[`unkType:${index}Name`] || "Add More")
      }
    }

    // if (isFormDataEmpty(formData)) {
    //   return toastDisplay("Kindly fill details to save", "info")
    // }
    formData.append("userId", userId)
    if (buyerId.length && Object.keys(yesNoQsts)?.length) {
      formData.append("buyerId", buyerId)
    }
    setshowLoader(true)
    call('POST', 'saveUserExtraDocs', formData).then(async res => {
      // Save contract doc start
      if (contractId) {
        let docNames = ['contractPartyAB', "invoiceDocument", "poDocument", 'freightInvoice', "billOfLading", "charterPartyAgreement", "vesselFixtureRecapDocument",
          "investigationDoc", "certOfSampling", "sofDoc", "shipInsurance", "commodityInsurance", "tradeInsurance", "exhibitDocument", "noaDocument", "frameworkDocument", "termSheetDocument"]
        let newFormData = new FormData()
        newFormData.append("contractId", contractId)
        newFormData.append("clientType", contractClientType)
        newFormData.append("userId", userId)
        for (let index = 0; index < docNames.length; index++) {
          const element = docNames[index];
          if (data[element]?.name) {
            newFormData.append(element, data[element])
            if (data[element]?.signatureId) {
              newFormData.append(`${element}SignId`, data[element]?.signatureId)
            }
          }
        }
        await call('POST', 'saveContractDocs', newFormData)
      }
      // Save contract doc end
      await call("POST", 'clearInvoiceLimitCacheById', { userId, forAdditionalDocs: true })
      // save bulk docs if exist start
      if (bulkFiles.length) {
        let bulkFormData = new FormData()
        bulkFormData.append("userId", userId)
        for (let index = 0; index < bulkFiles.length; index++) {
          const element = bulkFiles[index];
          bulkFormData.append(`bulkFile${index}`, element)
        }
        await call('POST', 'saveBulkUserDocs', bulkFormData)
      }
      // save bulk docs if exist end
      setshowLoader(false)
      if (!hideToastAfterDetailsSaved) {
        toastDisplay("Details saved", "success")
      }
      setData({ ...data, informationSaved: true })
      if (afterDetailsSavedOn) {
        afterDetailsSavedOn()
      }
      // else {
      //   toastDisplay('Details saved', "success")
      //   window.location = "/Quotes"
      // }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay('Something went wrong', "error")
    })
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        fileObj["filebase64"] = fileDataUrl
        let tempData = { ...data, [event.target.name]: fileObj }
        if (event.target.name.includes('aadharDoc') || event.target.name.includes('panDoc')) {
          toastDisplay('Analyzing document kindly wait', "info")
          setshowLoader(true)
          let formData = new FormData()
          formData.append(`doc`, fileObj)
          let kycOCRResp = await axios.post(`${aiServerBackendUrl}/analyzeKYCDoc`, formData, {
          })
          if (kycOCRResp?.data?.doc_number) {
            tempData[`${event.target.name}DocNo`] = kycOCRResp.data?.doc_number
            tempData[`partnerName:${event.target.name.split(":")[1]}`] = kycOCRResp.data?.user_name
          }
        }
        setshowLoader(false)
        setData(tempData)
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  async function setGSTYearlyFilesProperly(filesArr) {
    setshowLoader(true)
    try {
      let qst = `Below is an array of file name which might contain month & year, by analyzing this can u porvide me details in json format like this 
    [{file_name: "XYZ.pdf", financialYear: YYYY-YYYY}], if u dont know financial year then simply provide null in json for those keys.

    ${JSON.stringify(filesArr.map(i => i.name))}
    `
      let aiApiResp = await axios.post(`${aiServerBackendUrl}/getJSONDetailsFromGptV3`, { qst })
      setshowLoader(false)
      aiApiResp = JSON.parse(aiApiResp.data)

      let tempGstReturns = gstReturns
      let tempData = data

      if (aiApiResp.length) {
        for (let index = 0; index < aiApiResp.length; index++) {
          const element = aiApiResp[index];
          let respectiveFileObj = filesArr.filter(i => {
            if (i.name === element?.file_name) {
              return true
            }
          })?.[0] || null
          if (respectiveFileObj) {
            tempData[`gstDoc:${tempGstReturns.length - 1}`] = respectiveFileObj
            tempData[`gstDoc:${tempGstReturns.length - 1}Year`] = element['financialYear'] || null
            tempGstReturns.push(null)
          }
        }
      }
      setGstReturns([...tempGstReturns])
      setData({ ...tempData })
      // console.log("prompttttttttttttttttt", aiApiResp);
    } catch (error) {
      console.log("errorinsetGSTYearlyFilesProperly", error);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    }
  }

  async function setGSTMonthlyFilesProperly(filesArr) {
    setshowLoader(true)
    try {
      let qst = `Below is an array of file name which might contain month & year, by analyzing this can u porvide me details in json format like this 
    [{file_name: "XYZ.pdf", year: YYYY, month: MM}], month & year should be integer, if u dont know month or year then simply provide null in json for those keys.

    ${JSON.stringify(filesArr.map(i => i.name))}
    `
      let aiApiResp = await axios.post(`${aiServerBackendUrl}/getJSONDetailsFromGptV3`, { qst })
      setshowLoader(false)
      aiApiResp = JSON.parse(aiApiResp.data)

      let tempGstReturns = gstReturns
      let tempData = data

      if (aiApiResp.length) {
        for (let index = 0; index < aiApiResp.length; index++) {
          const element = aiApiResp[index];
          if (element.year / 1 >= (currentYear - 4) && element.month / 1 >= 1) {
            let isRowExistWithThisDate = false

            for (let j = 0; j < tempGstReturns.length; j++) {
              if (tempData["gstDoc" + ":" + j + "Year"] && last5Years.filter(item => {
                if (item.label === tempData["gstDoc" + ":" + j + "Year"] &&
                  (([4, 5, 6, 7, 8, 9, 10, 11, 12].includes(element.month / 1) && item.fromYear / 1 == element.year / 1) ||
                    ([1, 2, 3].includes(element.month / 1) && item.toYear / 1 == element.year / 1))
                ) {
                  return true
                }
              })?.length) {
                isRowExistWithThisDate = true
              }
            }

            if (!isRowExistWithThisDate) {
              tempGstReturns.push(null)
              tempData["gstDoc" + ":" + (tempGstReturns.length - 1) + "Year"] = [4, 5, 6, 7, 8, 9, 10, 11, 12].includes(element.month / 1) ? `${element.year / 1}-${element.year / 1 + 1}` : `${element.year / 1 - 1}-${element.year / 1}`
            }

            // setting files object in data
            let indexJ = undefined
            let indexM = undefined

            for (let j = 0; j < tempGstReturns.length; j++) {
              if (tempData["gstDoc" + ":" + j + "Year"] && (([4, 5, 6, 7, 8, 9, 10, 11, 12].includes(element.month / 1) && tempData["gstDoc" + ":" + j + "Year"] === `${element.year / 1}-${element.year / 1 + 1}`) ||
                ([1, 2, 3].includes(element.month / 1) && tempData["gstDoc" + ":" + j + "Year"] === `${element.year / 1 - 1}-${element.year / 1}`))) {
                indexJ = j
              }
              for (let m = 0; m < financialYearMonths.length; m++) {
                if (element.month / 1 == financialYearMonths[m]["month"] / 1) {
                  indexM = m
                }
              }
            }
            if (indexJ != undefined && indexM != undefined) {
              tempData["gstDoc" + ":" + indexJ + ":" + indexM + "Monthly"] = filesArr[index]
            }
          }
        }
      }

      setGstReturns([...tempGstReturns])
      setData({ ...tempData })

      // console.log("prompttttttttttttttttt", aiApiResp);
    } catch (error) {
      console.log("errorinsetGSTMonthlyFilesProperly", error);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    }
  }

  async function setBankDocFilesProperly(filesArr) {
    setshowLoader(true)
    try {
      let qst = `Below is an array of file names, pls saggregate all the files in 3 categories, first is bankStatements, second is sanctioned limit documents & third is other & 
      give me response in json form like this {bankStatements:[...fileNames], limitDocuments:[...fileNames], other:[...fileNames]}, if their is no data still return json key with empty array.

    ${JSON.stringify(filesArr.map(i => i.name))}
    `
      let aiApiResp = await axios.post(`${aiServerBackendUrl}/getJSONDetailsFromGptV3`, { qst })
      setshowLoader(false)
      aiApiResp = JSON.parse(aiApiResp.data)

      let tempBankDocs1 = bankDocs1
      let tempBankDocs2 = bankDocs2
      let tempBankDocs3 = bankDocs3
      let tempData = data

      if (aiApiResp?.bankStatements?.length) {
        for (let index = 0; index < aiApiResp?.bankStatements.length; index++) {
          const fileName = aiApiResp?.bankStatements[index];
          let respectiveFileObj = filesArr.filter(i => {
            if (i.name === fileName) {
              return true
            }
          })?.[0] || null
          if (respectiveFileObj) {
            tempData[`bankDoc1:${tempBankDocs1.length - 1}`] = respectiveFileObj
            tempBankDocs1.push(null)
          }
        }
        setBankDocs1([...tempBankDocs1])
      }
      if (aiApiResp?.limitDocuments?.length) {
        for (let index = 0; index < aiApiResp?.limitDocuments.length; index++) {
          const fileName = aiApiResp?.limitDocuments[index];
          let respectiveFileObj = filesArr.filter(i => {
            if (i.name === fileName) {
              return true
            }
          })?.[0] || null
          if (respectiveFileObj) {
            tempData[`bankDoc2:${tempBankDocs2.length - 1}`] = respectiveFileObj
            tempBankDocs2.push(null)
          }
        }
        setBankDocs2([...tempBankDocs2])
      }
      if (aiApiResp?.other?.length) {
        for (let index = 0; index < aiApiResp?.other.length; index++) {
          const fileName = aiApiResp?.other[index];
          let respectiveFileObj = filesArr.filter(i => {
            if (i.name === fileName) {
              return true
            }
          })?.[0] || null
          if (respectiveFileObj) {
            tempData[`bankDoc3:${tempBankDocs3.length - 1}`] = respectiveFileObj
            tempData[`bankDoc3:${tempBankDocs3.length - 1}Name`] = respectiveFileObj?.name?.split(".")[0]
            tempBankDocs3.push(null)
          }
        }
        setBankDocs3([...tempBankDocs3])
      }
      setData({ ...tempData })
    } catch (error) {
      console.log("errorinsetBankDocFilesProperly", error);
      setshowLoader(false)
      toastDisplay("Something went wrong", "error")
    }
  }

  return (
    <>
      {/* <TutorialVideoPopup
        show={!experianTutorialPopup.show}
        videoLink={experianTutorialPopup.link}
        onClose={() => setExperianTutorialPopup({ ...experianTutorialPopup, show: false })}
      /> */}
      <div className={`modal fade ${addBankPopup.show && "show"}`} style={addBankPopup.show ? { display: "block", "zIndex": '1000001' } : {}}>
        <div className="modal-dialog modal-md mr-0">
          <div className="modal-content submitmodal pb-4"
          >
            <div className="modal-header border-0">
              <div className="">
                <i onClick={() => { setAddBankPopup({ show: false, index: null, data: {} }) }}
                  class="fas fa-2x fa-arrow-left mx-1 icon-color cursor"></i>
                <label
                  className="font-size-16 font-wt-600 text-color-value mx-3"
                >Bank Details</label>
              </div>
            </div>
            <div className="modal-body px-4">
              <div className="position-relative">
                <NewInput label={"Bank Name"} name={`bankName${addBankPopup.index}`} value={data[`bankName${addBankPopup.index}`] || ""} onChange={handleChange} />
              </div>
              <div className="position-relative">
                <NewInput label={"Branch"} name={`branch${addBankPopup.index}`} value={data[`branch${addBankPopup.index}`] || ""} onChange={handleChange} />
              </div>
              <div className="position-relative">
                <NewInput label={"Account Holder Name"} name={`accountHolderName${addBankPopup.index}`} value={data[`accountHolderName${addBankPopup.index}`] || ""} onChange={handleChange} />
              </div>
              <div className="position-relative">
                <NewInput label={"Account Number"} name={`accountNo${addBankPopup.index}`} value={data[`accountNo${addBankPopup.index}`] || ""} onChange={handleChange} />
              </div>
              <div className="position-relative">
                <NewInput label={"IFSC Code"} name={`ifscCode${addBankPopup.index}`} value={data[`ifscCode${addBankPopup.index}`] || ""} onChange={handleChange} />
              </div>
              <div className="position-relative">
                <NewInput label={"Swift Code"} name={`swiftCode${addBankPopup.index}`} value={data[`swiftCode${addBankPopup.index}`] || ""} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {gstPortalLoginPopup.show ? (
        <FinanceInvoiceModal limitinvoice={gstPortalLoginPopup.show} closeSuccess={() => {
          setGstPortalLoginPopup({ show: false })
        }} >
          <label className="font-size-16 font-wt-500 w-100 text-center" >Authenticate with GST Portal Credentials</label>
          <div className="ml-5 mt-2">
            <img src="assets/images/warning.png" />
            <label className="font-size-12 font-wt-500 text-color-value col-11">Your information is secure. Share only your GST ID, and leave the rest to us.</label>
          </div>
          <div className="px-5 py-2 mx-4">
            <div className="row d-flex justify-content-between mt-2">
              <div className="relative w-100 my-2">
                <NewInput label={"User Id"}
                  name="gstUsername" value={data.gstUsername} onChange={handleChange}
                />
              </div>
              <div className="relative w-100 mb-2">
                <NewInput type={data.securePw ? "password" : 'text'} label={data.authenticateUsingOtp ? 'OTP' : "Password"}
                  name="gstPassword" value={data.gstPassword} onChange={handleChange} error={errors.gstPassword}
                />
                <i
                  style={{ position: 'absolute', left: '84%' }}
                  onClick={() => setData({ ...data, securePw: !data.securePw })}
                  className={`fas ${!data.securePw ? "fa-eye" : 'fa-eye-slash'} input-icon`} id="togglePassword" ></i>
              </div>
              <div className="relative w-100">
                <LoadCanvasTemplate reloadColor="#1B94B7" />
              </div>
              <div className="relative w-100 my-2">
                <NewInput label={"Type the Characters"} error={errors.typedCaptcha}
                  name="typedCaptcha" value={data.typedCaptcha} onChange={handleChange}
                />
              </div>
              <div className="relative w-100">
                <button type="button"
                  onClick={() => {
                    validateUserLogin()
                  }}
                  className={`mx-2 new-btn py-2 px-2 text-white w-100`}>
                  {"Authenticate"}
                </button>
              </div>
            </div>
          </div>
        </FinanceInvoiceModal>
      ) : null}
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className={`row`}>
          {hideSideBar ? null : (
            <SideBarV2 state="invoice_applyLimit" userTokenDetails={userToken} />
          )}
          <main role="main" style={expandAll ? { width: '100%' } : {}}
            className={`ml-sm-auto col-lg-10 ${navToggleState.status ? " expanded-right" : ""} ${mainTagMarginPadding0 ? ' m-0 p-0 ' : " "}`} id="app-main-div">
            {hideHeader ? null : (
              <HeaderV2
                title={"Apply for Limit"}
                userTokenDetails={userToken} />
            )}
            {hideBackArrow ? null : (
              <a
                onClick={() => {
                  if (isAdmin) {
                    window.close()
                  } else {
                    window.location = "Quotes"
                  }
                }}
                className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            )}
            <div>
              <div style={cardBorder0 ? { border: "0px" } : {}} className={`card ${cardPadding0 ? " p-0 " : ' p-5 '}`}>
                {hideSubmitDiv ? null : (
                  <div className="row">
                    {data.informationSaved ? (
                      <div className="d-flex align-items-center">
                        <div className="col-7" >
                          <img style={{ width: '100%' }} src="assets/images/congratsForSharingDetails.svg" />
                        </div>
                        <div className="col-4" >
                          <div className="d-flex row justify-content-center">
                            <div className="w-60">
                              <button type="button"
                                onClick={() => {
                                  window.location = isAdmin ? '/invoiceLimit' : '/Quotes'
                                }}
                                className={`mx-2 new-btn py-2 px-2 text-white w-100`}>
                                {"Go back to Quotes"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <div style={{ width: '6rem' }}>
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: '#2ECC71',
                                textColor: '#000000'
                              })}
                              value={chanceOfApproval} text={`${Math.round(chanceOfApproval)}%`} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-6 d-flex align-items-center" >
                        <label className="font-size-14 font-wt-600 text-color-value ">Attach the below mentioned documents to increase the changes of Approval on Finance limit application</label>
                      </div>
                    )}
                    {data.informationSaved ? null : (
                      <div className="col-1">
                        <div style={{ width: '6rem' }}>
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#2ECC71',
                              textColor: '#000000'
                            })}
                            value={chanceOfApproval} text={`${Math.round(chanceOfApproval)}%`} />
                        </div>
                      </div>
                    )}
                    {data.informationSaved ? null : (
                      <div className="col-5 d-flex row align-items-center justify-content-end" >
                        <div className="d-flex row">
                          <div className="w-50">
                            <button type="button"
                              onClick={() => {
                                saveDetails()
                              }}
                              className={`mx-2 new-btn py-2 px-2 text-white w-100`}>
                              {"Send Document"}
                            </button>
                          </div>
                          <div className="w-50">
                            <button type="button"
                              onClick={() => {
                                window.location = isAdmin ? "invoiceLimit" : "Quotes"
                              }}
                              className={`mx-2 new-btn3 py-2 px-2 w-100`}>
                              {"Remind me Later"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {buyerId.length ? (
                  <div className="mt-4">
                    {buyerId.length / 1 > 1 ?
                      <div className='mb-3 d-flex pl-2 pt-3 align-items-center col-auto'>
                        <label className={!data[`sameAnsForAllBuyers`] ? "font-wt-500 font-size-14" :
                          "font-wt-800 font-size-14 color-2ECC71"} for={`sameAnsForAllBuyers`}>Same answer for all buyers</label>
                        <img
                          className="cursor mx-3"
                          onClick={() => setData({ ...data, [`sameAnsForAllBuyers`]: !data[`sameAnsForAllBuyers`], [`diffAnsForAllBuyers`]: !data[`diffAnsForAllBuyers`] })}
                          src={data[`sameAnsForAllBuyers`] ? "assets/images/turn_off_green_switch.svg" : "assets/images/turn_on_green_switch.svg"}
                        />
                        <label className={!data[`diffAnsForAllBuyers`] ? "font-wt-500 font-size-14" :
                          "font-wt-800 font-size-14 color-2ECC71"} for={`diffAnsForAllBuyers`}>Different answer for all buyers</label>
                      </div> : null}
                    {data.sameAnsForAllBuyers ? (
                      <NewTablev2
                        columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '80%' } }, { subColumns: 'YES', subColumnStyle: { width: '10%' } }, { subColumns: 'NO', subColumnStyle: { width: '10%' } }]}>
                        {Object.keys(yesnoqsts).map((i, j) => {
                          return (
                            <tr>
                              <td><label className="font-size-12 font-wt-500" >{yesnoqsts[i]["label"]}</label></td>
                              <td>
                                <img
                                  onClick={() => {
                                    let isAllReadyTrueAll = buyerId.filter((m, n) => {
                                      if (data[`${i}:${m}`] === true) {
                                        return true
                                      }
                                    })?.length === buyerId.length
                                    let tempData = data
                                    for (let index = 0; index < buyerId.length; index++) {
                                      tempData[`${i}:${buyerId[index]}`] = isAllReadyTrueAll ? undefined : true
                                    }
                                    setData({ ...tempData })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${buyerId.filter((m, n) => {
                                    if (data[`${i}:${m}`] === true) {
                                      return true
                                    }
                                  })?.length === buyerId.length ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                              <td>
                                <img
                                  onClick={() => {
                                    let isAllReadyFalseAll = buyerId.filter((m, n) => {
                                      if (data[`${i}:${m}`] === false) {
                                        return true
                                      }
                                    })?.length === buyerId.length
                                    let tempData = data
                                    for (let index = 0; index < buyerId.length; index++) {
                                      tempData[`${i}:${buyerId[index]}`] = isAllReadyFalseAll ? undefined : false
                                    }
                                    setData({ ...tempData })
                                  }}
                                  className='cursor mr-3' src={`assets/images/${buyerId.filter((m, n) => {
                                    if (data[`${i}:${m}`] === false) {
                                      return true
                                    }
                                  })?.length === buyerId.length ? 'checked-green' : 'empty-check'}.png`} />
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    ) : null}

                    {data.diffAnsForAllBuyers ? (
                      <NewTablev2
                        columns={[{ subColumns: "Yes / No Questions", subColumnStyle: { width: '35%' } }, { subColumns: 'If "YES" then click on the tickbox to select buyer/s, if "NO" then Deselct buyer/s', subColumnStyle: { width: '65%' } }]}>
                        {Object.keys(yesnoqsts).map((i, j) => {
                          return (
                            <tr>
                              <td>
                                <div>
                                  <label className="font-size-12 font-wt-500" >
                                    {yesnoqsts[i]["label"]}
                                  </label>
                                </div>
                                <div className="my-2"
                                  onClick={() => {
                                    let tempData = data
                                    tempData[`${i}:selectAll`] = !tempData[`${i}:selectAll`]
                                    for (let index = 0; index < buyerId.length; index++) {
                                      tempData[`${i}:${buyerId[index]}`] = tempData[`${i}:selectAll`]
                                    }
                                    setData({ ...tempData })
                                  }}
                                >
                                  <img
                                    className='cursor mr-3' src={`assets/images/${data[`${i}:selectAll`] === true ? 'checked-green' : 'empty-check'}.png`} />
                                  <label className="font-size-14 font-wt-500" >Select all buyer</label>
                                </div>
                              </td>
                              <td>
                                {buyerDetails.map((m, n) => {
                                  return (
                                    <div className="d-flex mb-2" >
                                      <div className="col-6" >
                                        <span className=" font-size-12 font-wt-500 w-80" >
                                          <img
                                            onClick={() => {
                                              setData({ ...data, [`${i}:${m.id}`]: data[`${i}:${m.id}`] === true ? undefined : true })
                                            }}
                                            className='cursor mr-3' src={`assets/images/${data[`${i}:${m.id}`] === true ? 'checked-green' : 'empty-check'}.png`} />
                                          {m.buyerName}
                                        </span>
                                      </div>
                                      <div className="col-6 ">
                                        <span className=" font-size-12 font-wt-500 w-80" >
                                          <img
                                            onClick={() => {
                                              setData({ ...data, [`${i}:${m.id}`]: data[`${i}:${m.id}`] === false ? undefined : false })
                                            }}
                                            className='cursor mr-3' src={`assets/images/${data[`${i}:${m.id}`] === false ? 'checked-green' : 'empty-check'}.png`} />
                                          {m.buyerName}
                                        </span>
                                      </div>
                                    </div>
                                  )
                                })}
                              </td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    ) : null}
                  </div>
                ) : null}

                {gstCredentialsViewHide ? null : !contractId ?
                  <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>GST</u> (You can provide GST portal credentials or upload documents below.)</label>

                    {data.gstDetailsFetched ?
                      <div className="my-3">
                        <img src="assets/images/thanks_for_sharing_gst.svg" />
                      </div> :
                      isDisabled ? <></> : <>
                        <label
                          onClick={() => {
                            setGstPortalLoginPopup({ show: true })
                          }}
                          className="m-0 p-0 font-size-13 font-wt-700 col-10 cursor text-color1">{"Authenticate with GST Portal Credentials"}</label>
                      </>
                    }
                  </> : null
                }

                {gstDoc1Hide ? null :
                  <>
                    {!contractId ?
                      <div className="mt-3">
                        {/* <label className="w-100 text-center font-size-18 font-wt-700 mb-4" >{`OR`}</label> */}
                        {isDisabled ? null : <>
                          <label className="font-size-14 font-wt-600 w-100 text-danger m-0 p-0 mt-4" >Please upload all the files listed below into the designated view, and our advanced AI engine will segregate the documents.</label>
                          <label className="w-100 font-size-14 font-wt-600 mt-3" >Required Documents -</label>
                          <NewTablev2 columns={[{ subColumns: 'Document Name', subColumnStyle: { width: '60%' } }, { subColumns: 'Available?', subColumnStyle: { width: '10%' } },
                          { subColumns: 'Remarks', subColumnStyle: { width: '20%' } }]}>
                            {Object.keys(docTypes).map((i, j) => {
                              return (
                                <tr>
                                  <td className="position-relative">
                                    <label className="w-100 font-size-13 font-wt-500">{`${j + 1}. ${docTypes[i]}`}
                                      <img src={"assets/images/warning.png"} className="totalChargesLabel ml-2" />
                                      <div style={{ top: '1rem', left: '8rem' }} className="otherChargePopup p-3">
                                        <div>
                                          <div className="font-size-12 font-wt-500" dangerouslySetInnerHTML={{ __html: docExplain[i] }} />
                                        </div>
                                      </div>
                                    </label>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center pl-2">
                                      <img
                                        onClick={() => {
                                          setData({ ...data, [`isDocPresent${j}`]: !data[`isDocPresent${j}`] })
                                        }}
                                        className='cursor mr-3' src={`assets/images/${data[`isDocPresent${j}`] ? 'checked-green' : 'empty-check'}.png`} />
                                    </div></td>
                                  <td>
                                    <NewTextAreaForTable name={`docComment${j}`} value={data[`docComment${j}`]} onChange={handleChange} />
                                  </td>
                                </tr>
                              )
                            })}

                          </NewTablev2>
                          <div className="mt-4">
                            <FileDragView isEditable={true} onChange={e => {
                              let tempBulkFiles = bulkFiles
                              if (e?.target?.files?.length) {
                                tempBulkFiles = tempBulkFiles.concat(e.target.files)
                                setBulkFiles([...tempBulkFiles])
                              }
                            }} name={"bulkFiles"} value={bulkFiles} setBulkFiles={setBulkFiles} />
                          </div>
                        </>}
                        {isDisabled ?
                          <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } }, { subColumns: 'Year', subColumnStyle: { width: '15%' } }, {
                            subColumns: 'Attach document', subColumnClass: " d-flex align-items-center ", subColumnStyle: { width: '50%' },
                            colAdditional: <div className="ml-2 borderRadius20 border1pxSolidD5D5D5D">
                              {[{ name: 'Consolidated' }, { name: 'Monthly' }].map((i, j) => {
                                return (
                                  <label
                                    onClick={() => setGstToggle(j)}
                                    className={`m-0 borderRadius20 cursor p-2 font-size-14 ${gstToggle / 1 == j ? ' bg-B9EFFF font-wt-800' : ' font-wt-500 '}  `}>{i.name}</label>
                                )
                              })}
                            </div>
                          }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                            {gstReturns.map((i, j) => {
                              let gstYearArr = data["gstDoc" + ":" + j + "Year"]?.split("-")
                              return (
                                <tr>
                                  <td className="w-30"><label className="font-size-12 font-wt-500" >GST return</label></td>
                                  <td><SelectForTable isDisabled={isDisabled} optionLabel={"label"} optionValue={"label"}
                                    selectData={last5Years}
                                    value={data["gstDoc" + ":" + j + "Year"]} name={"gstDoc" + ":" + j + "Year"} onChange={handleChange}
                                  /></td>
                                  {gstToggle / 1 == 0 ? <td>
                                    <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                      onMultipleDragDrop={async (e) => {
                                        let tempErr = errors
                                        let tempData = data
                                        let filesDataArray = []
                                        for (let index = 0; index < e.target.files.length; index++) {
                                          let element = e.target.files[index];
                                          let customEvent = {
                                            target: { name: "gstDoc" + ":" + (j + index), files: [element] },
                                            persist: () => console.log("...")
                                          }
                                          let resp = await handleFileWithAsyncAwait(customEvent)
                                          if (resp.status / 1 == 0) {
                                            tempErr = { ...tempErr, ...resp.msg }
                                          }
                                          if (resp.status / 1 == 1) {
                                            tempData = { ...tempData, ...resp.msg }
                                            filesDataArray[index] = resp.msg[customEvent.target.name]
                                          }
                                        }
                                        setErrors(tempErr)
                                        if (filesDataArray.length / 1 == 1) {
                                          setData(tempData)
                                          let temp = gstReturns
                                          temp.push(null)
                                          setGstReturns([...temp])
                                        }
                                        else if (filesDataArray.length / 1 > 1) {
                                          setGSTYearlyFilesProperly(filesDataArray)
                                        }
                                      }}
                                      name={"gstDoc" + ":" + j} value={data["gstDoc" + ":" + j]} error={errors["gstDoc" + ":" + j]}
                                      onChange={handleFile}
                                      onUploadCancel={() => setData({ ...data, ["gstDoc" + ":" + j]: null })}
                                    />
                                  </td> :
                                    <td>
                                      <div className="d-flex flex-wrap">
                                        {financialYearMonths.map((i, m) => {
                                          return <SmallFileInput isEditable={!isDisabled}
                                            multipleDragDropEnable={true}
                                            containerStyle={{ width: '25%', height: '4rem' }}
                                            docName={`${i.name} ${i.lastYear ? (gstYearArr?.[0] || "") : (gstYearArr?.[1] || "")}`} hideViewDowdBtns={true}
                                            name={"gstDoc" + ":" + j + ":" + m + "Monthly"} value={data["gstDoc" + ":" + j + ":" + m + "Monthly"]} error={errors["gstDoc" + ":" + j + ":" + m + "Monthly"]}
                                            onUploadCancel={() => setData({ ...data, ["gstDoc" + ":" + j + ":" + m + "Monthly"]: null })}
                                            onMultipleDragDrop={async (e) => {
                                              let tempErr = errors
                                              let tempData = data
                                              let filesDataArray = []
                                              for (let index = 0; index < e.target.files.length; index++) {
                                                let element = e.target.files[index];
                                                let customEvent = {
                                                  target: { name: "gstDoc" + ":" + j + ":" + m + "Monthly", files: [element] },
                                                  persist: () => console.log("...")
                                                }
                                                let resp = await handleFileWithAsyncAwait(customEvent)
                                                if (resp.status / 1 == 0) {
                                                  tempErr = { ...tempErr, ...resp.msg }
                                                }
                                                if (resp.status / 1 == 1) {
                                                  tempData = { ...tempData, ...resp.msg }
                                                  filesDataArray[index] = resp.msg[customEvent.target.name]
                                                }
                                              }
                                              if (filesDataArray.length / 1 == 1) {
                                                setData(tempData)
                                              }
                                              else if (filesDataArray.length / 1 > 1) {
                                                setGSTMonthlyFilesProperly(filesDataArray)
                                              }
                                              setErrors(tempErr)
                                            }}
                                          />
                                        })}
                                      </div>
                                    </td>}
                                  <td>
                                    {!data["gstDoc" + ":" + j]?.signatureId ?
                                      <img className="cursor"
                                        onClick={() => { setSigndoc(data["gstDoc" + ":" + j]?.name); setData({ ...data, docToSign: data["gstDoc" + ":" + j], docSignName: "gstDoc" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                      :
                                      <img style={{ width: '20px' }} className=""
                                        src={"assets/images/doc_sign_filled.svg"} alt="" />
                                    }
                                  </td>
                                  <td><div
                                    className="d-flex"
                                  >
                                    <>{isDisabled ? null : <>
                                      {(gstReturns.length - 1 > j) ?
                                        <img
                                          onClick={() => {
                                            let temp = gstReturns
                                            temp = temp.filter((m, n) => {
                                              console.log("temppppppppppppppp", j, n);
                                              if (n != j) {
                                                return true
                                              }
                                            })
                                            setGstReturns([...temp])
                                          }}
                                          className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                        /> : <img
                                          onClick={() => {
                                            let temp = gstReturns
                                            temp.push(null)
                                            setGstReturns([...temp])
                                          }}
                                          style={{ width: '22px', height: '22px' }}
                                          className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                    </>}</>
                                    {data["gstDoc" + ":" + j]?.name ? (
                                      <>
                                        <img onClick={() => { toggleDowdDoc({ show: true, doc: data["gstDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                        <img onClick={() => { toggleViewDoc({ show: true, doc: data["gstDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                      </>
                                    ) : null}

                                  </div></td>
                                </tr>
                              )
                            })}
                          </NewTablev2> : null}
                      </div> : null}
                  </>}

                {!buyerId.length && !contractId ?
                  <div className="mt-2">
                    <NewTablev2
                      columns={[{
                        subColumns: "Yes / No Questions", subColumnStyle: { width: '60%' },
                        subColumnClass: ' font-wt-600 '
                      }, { subColumns: 'Answer', subColumnStyle: { width: '18%' }, subColumnClass: ' font-wt-600 ' },
                        , { subColumns: 'Comments', subColumnStyle: { width: '22%' }, subColumnClass: ' font-wt-600 ' }]}>
                      {Object.keys(yesnoqsts2).map((i, j) => {
                        if (yesnoqsts2[i]["hide"]) {
                          return null
                        }
                        return (
                          <tr>
                            <td><label className="font-size-12 font-wt-500" >{yesnoqsts2[i]["label"]}</label></td>
                            <td>
                              <div className="d-flex align-items-center" >
                                <div>
                                  <img
                                    onClick={() => {
                                      setData({ ...data, [i]: data[i] == true ? undefined : true })
                                    }}
                                    className='cursor mr-3' src={`assets/images/${data[i] == true ? 'checked-green' : 'empty-check'}.png`} />
                                  <label className="font-size-13 font-wt-500" >Yes</label>
                                </div>
                                <div className="ml-4">
                                  <img
                                    onClick={() => {
                                      setData({ ...data, [i]: data[i] == false ? undefined : false })
                                    }}
                                    className='cursor mr-3' src={`assets/images/${data[i] == false ? 'checked-green' : 'empty-check'}.png`} />
                                  <label className="font-size-13 font-wt-500" >No</label>
                                </div>
                              </div>
                            </td>
                            {/* <td>
                              <img
                                onClick={() => {
                                  setData({ ...data, [i]: data[i] == false ? undefined : false })
                                }}
                                className='cursor mr-3' src={`assets/images/${data[i] == false ? 'checked-green' : 'empty-check'}.png`} />
                            </td> */}
                            <td>
                              <NewTextAreaForTable name={`${i}:Comments`} value={data[`${i}:Comments`]} onChange={handleChange} />
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div> : null}

                {bankDoc1Hide && bankDoc2Hide && bankDoc3Hide ? null :
                  isDisabled ?
                    <>
                      <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>Bank</u></label>
                      <div>
                        <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } }, { subColumns: 'Year', subColumnStyle: { width: '15%' } },
                        { subColumns: 'Attach document', subColumnStyle: { width: '45%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '15%' } }]}>
                          {bankDocs1.map((i, j) => {
                            return (
                              <tr>
                                <td className="w-30"><label className="font-size-12 font-wt-500" >
                                  Bank statement of all working capital & Current accounts  (last 12 months)</label></td>
                                <td>-</td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true}
                                    multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      let filesDataArray = []
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "bankDoc1" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                          filesDataArray[index] = resp.msg[customEvent.target.name]
                                          // toastDisplay('Analyzing bank statement kindly wait', "info")
                                          // setshowLoader(true)
                                          // let formData = new FormData()
                                          // formData.append(`doc`, resp.msg["bankDoc1" + ":" + (j + index)])
                                          // let bankOCRResp = await axios.post(`${aiServerBackendUrl}/analyzeBankStatement`, formData, {
                                          // })
                                          // setshowLoader(false)
                                          // // console.log("bankOCRResppppppppppppppppp", bankOCRResp);
                                          // if (bankOCRResp?.data?.account_number) {
                                          //   tempData[`bankName${j + index}`] = bankOCRResp.data.bank_name || "NA"
                                          //   tempData[`branch${j + index}`] = bankOCRResp.data.bank_branch_name || "NA"
                                          //   tempData[`accountHolderName${j + index}`] = bankOCRResp.data.account_holder_name || "NA"
                                          //   tempData[`accountNo${j + index}`] = bankOCRResp.data.account_number
                                          //   tempData[`ifscCode${j + index}`] = bankOCRResp.data.ifsc_code || "NA"
                                          //   tempData[`swiftCode${j + index}`] = bankOCRResp.data.swift_code || "NA"
                                          // }
                                        }
                                      }
                                      setErrors(tempErr)
                                      if (filesDataArray.length / 1 == 1) {
                                        let temp = bankDocs1
                                        setData(tempData)
                                        temp.push(null)
                                        setBankDocs1([...temp])
                                      }
                                      else if (filesDataArray.length / 1 > 1) {
                                        setBankDocFilesProperly(filesDataArray)
                                      }
                                    }}
                                    name={"bankDoc1" + ":" + j} value={data["bankDoc1" + ":" + j]} error={errors["bankDoc1" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["bankDoc1" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["bankDoc1" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["bankDoc1" + ":" + j]?.name); setData({ ...data, docToSign: data["bankDoc1" + ":" + j], docSignName: "bankDoc1" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? null : <>
                                    {(bankDocs1.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = bankDocs1
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setBankDocs1([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = bankDocs1
                                          temp.push(null)
                                          setBankDocs1([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                  {data["bankDoc1" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["bankDoc1" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["bankDoc1" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                  <img onClick={() => {
                                    setAddBankPopup({ show: true, index: j })
                                  }} className="cursor" src={"assets/images/external_link.svg"} />
                                </div>
                                </td>
                              </tr>
                            )
                          })}
                          {bankDocs2.map((i, j) => {
                            return (
                              <tr>
                                <td className="w-30"><label className="font-size-12 font-wt-500" >Limit details & sanctioned letter of existing banking lines</label></td>
                                <td>-</td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      let filesDataArray = []
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "bankDoc2" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                          filesDataArray[index] = resp.msg[customEvent.target.name]
                                        }
                                      }
                                      setErrors(tempErr)
                                      if (filesDataArray.length / 1 == 1) {
                                        let temp = bankDocs2
                                        setData(tempData)
                                        temp.push(null)
                                        setBankDocs2([...temp])
                                      }
                                      else if (filesDataArray.length / 1 > 1) {
                                        setBankDocFilesProperly(filesDataArray)
                                      }
                                    }}
                                    name={"bankDoc2" + ":" + j} value={data["bankDoc2" + ":" + j]} error={errors["bankDoc2" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["bankDoc2" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["bankDoc2" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["bankDoc2" + ":" + j]?.name); setData({ ...data, docToSign: data["bankDoc2" + ":" + j], docSignName: "bankDoc2" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? '-' : <>
                                    {(bankDocs2.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = bankDocs2
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setBankDocs2([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = bankDocs2
                                          temp.push(null)
                                          setBankDocs2([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                  {data["bankDoc2" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["bankDoc2" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["bankDoc2" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                </div></td>
                              </tr>
                            )
                          })}
                          {bankDocs3.map((i, j) => {
                            return (
                              <tr>
                                <td className="w-30">
                                  <div className="position-relative">
                                    <InputForTable isDisabled={isDisabled} label={""} name={"bankDoc3" + ":" + j + "Name"} value={data["bankDoc3" + ":" + j + "Name"]} onChange={handleChange} />
                                  </div></td>
                                <td>-</td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      let filesDataArray = []
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "bankDoc3" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                          filesDataArray[index] = resp.msg[customEvent.target.name]
                                        }
                                      }
                                      setErrors(tempErr)
                                      if (filesDataArray.length / 1 == 1) {
                                        let temp = bankDocs3
                                        setData(tempData)
                                        temp.push(null)
                                        setBankDocs3([...temp])
                                      }
                                      else if (filesDataArray.length / 1 > 1) {
                                        setBankDocFilesProperly(filesDataArray)
                                      }
                                    }}
                                    name={"bankDoc3" + ":" + j} value={data["bankDoc3" + ":" + j]} error={errors["bankDoc3" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["bankDoc3" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["bankDoc3" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["bankDoc3" + ":" + j]?.name); setData({ ...data, docToSign: data["bankDoc3" + ":" + j], docSignName: "bankDoc3" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? "-" : <>
                                    {(bankDocs3.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = bankDocs3
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setBankDocs3([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = bankDocs3
                                          temp.push(null)
                                          setBankDocs3([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                  {data["bankDoc3" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["bankDoc3" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["bankDoc3" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                </div></td>
                              </tr>
                            )
                          })}
                        </NewTablev2>
                      </div>
                    </> : null}

                {finDoc1Hide && fintDoc2Hide && finDoc3Hide && finDoc4Hide ? null :
                  isDisabled ?
                    <>

                      <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>Financial</u></label>

                      <div>
                        <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } }, { subColumns: 'Year', subColumnStyle: { width: '15%' } },
                        { subColumns: 'Attach document', subColumnStyle: { width: '50%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                          {financialDoc1.map((i, j) => {
                            return (
                              <tr
                                style={{ borderTop: '2px solid transparent' }}>
                                <td className="w-30">
                                  <label className="font-size-12 font-wt-500" >Audited financials including ITR</label></td>
                                <td><SelectForTable isDisabled={isDisabled} optionLabel={"label"} optionValue={"label"}
                                  selectData={last5Years}
                                  value={data["finDoc1" + ":" + j + "Year"]} name={"finDoc1" + ":" + j + "Year"} onChange={handleChange}
                                /></td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "finDoc1" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                        }
                                      }
                                      setData(tempData)
                                      setErrors(tempErr)
                                      let temp = financialDoc1
                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                        temp.push(null)
                                      }
                                      setFinancialDoc1([...temp])
                                    }}
                                    name={"finDoc1" + ":" + j} value={data["finDoc1" + ":" + j]} error={errors["finDoc1" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["finDoc1" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["finDoc1" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["finDoc1" + ":" + j]?.name); setData({ ...data, docToSign: data["finDoc1" + ":" + j], docSignName: "finDoc1" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? "-" : <>
                                    {(financialDoc1.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = financialDoc1
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setFinancialDoc1([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = financialDoc1
                                          temp.push(null)
                                          setFinancialDoc1([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                  {data["finDoc1" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["finDoc1" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["finDoc1" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                </div></td>
                              </tr>
                            )
                          })}
                          {financialDoc2.map((i, j) => {
                            return (
                              <tr>
                                <td className="w-30">
                                  <label className="font-size-12 font-wt-500" >Sales Ledger with all the buyer (last 12 months)</label>
                                </td>
                                <td>-</td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "finDoc2" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                        }
                                      }
                                      setData(tempData)
                                      setErrors(tempErr)
                                      let temp = financialDoc2
                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                        temp.push(null)
                                      }
                                      setFinancialDoc1([...temp])
                                    }}
                                    name={"finDoc2" + ":" + j} value={data["finDoc2" + ":" + j]} error={errors["finDoc2" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["finDoc2" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["finDoc2" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["finDoc2" + ":" + j]?.name); setData({ ...data, docToSign: data["finDoc2" + ":" + j], docSignName: "finDoc2" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? "-" : <>
                                    {(financialDoc2.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = financialDoc2
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setFinancialDoc2([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = financialDoc2
                                          temp.push(null)
                                          setFinancialDoc2([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}</>}
                                  {data["finDoc2" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["finDoc2" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["finDoc2" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                </div></td>
                              </tr>
                            )
                          })}
                          {financialDoc3.map((i, j) => {
                            return (
                              <tr>
                                <td className="w-30">
                                  <label className="font-size-12 font-wt-500" >Stock statement shared with banks ageing breakup of debtor /creditors (last 2 months)</label>
                                </td>
                                <td>-</td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "finDoc3" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                        }
                                      }
                                      setData(tempData)
                                      setErrors(tempErr)
                                      let temp = financialDoc3
                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                        temp.push(null)
                                      }
                                      setFinancialDoc3([...temp])
                                    }}
                                    name={"finDoc3" + ":" + j} value={data["finDoc3" + ":" + j]} error={errors["finDoc3" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["finDoc3" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["finDoc3" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["finDoc3" + ":" + j]?.name); setData({ ...data, docToSign: data["finDoc3" + ":" + j], docSignName: "finDoc3" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? "-" : <>
                                    {(financialDoc3.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = financialDoc3
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setFinancialDoc3([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = financialDoc3
                                          temp.push(null)
                                          setFinancialDoc3([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                  {data["finDoc3" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["finDoc3" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["finDoc3" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                </div></td>
                              </tr>
                            )
                          })}
                          {financialDoc4.map((i, j) => {
                            return (
                              <tr>
                                <td>
                                  <div className="position-relative">
                                    <InputForTable isDisabled={isDisabled} label={""} name={"finDoc4" + ":" + j + "Name"} value={data["finDoc4" + ":" + j + "Name"]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>-</td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                    onMultipleDragDrop={async (e) => {
                                      let tempErr = errors
                                      let tempData = data
                                      for (let index = 0; index < e.target.files.length; index++) {
                                        let element = e.target.files[index];
                                        let customEvent = {
                                          target: { name: "finDoc4" + ":" + (j + index), files: [element] },
                                          persist: () => console.log("...")
                                        }
                                        let resp = await handleFileWithAsyncAwait(customEvent)
                                        if (resp.status / 1 == 0) {
                                          tempErr = { ...tempErr, ...resp.msg }
                                        }
                                        if (resp.status / 1 == 1) {
                                          tempData = { ...tempData, ...resp.msg }
                                        }
                                      }
                                      setData(tempData)
                                      setErrors(tempErr)
                                      let temp = financialDoc4
                                      for (let index = 0; index < e.target.files.length - 1; index++) {
                                        temp.push(null)
                                      }
                                      setFinancialDoc4([...temp])
                                    }}
                                    name={"finDoc4" + ":" + j} value={data["finDoc4" + ":" + j]} error={errors["finDoc4" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["finDoc4" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["finDoc4" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["finDoc4" + ":" + j]?.name); setData({ ...data, docToSign: data["finDoc4" + ":" + j], docSignName: "finDoc4" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {isDisabled ? "-" : <>
                                    {(financialDoc4.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = financialDoc4
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setFinancialDoc4([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = financialDoc4
                                          temp.push(null)
                                          setFinancialDoc4([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                  {data["finDoc4" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["finDoc4" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["finDoc4" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}

                                </div></td>
                              </tr>
                            )
                          })}
                        </NewTablev2>
                      </div>
                    </> : null}

                {orgDoc1Hide && orgDoc2Hide && orgDoc3Hide ? null :
                  userTypeId / 1 != 20 ? isDisabled ? <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{orgDocLabel || 'Organizational'}</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } }, { subColumns: 'Partner name', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '50%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                        {orgDoc1Hide ? null : orgDoc1.map((i, j) => {
                          return (
                            <tr
                            >
                              <td className="w-30">
                                <label className="font-size-12 font-wt-500" >Partnership deed (in case of partnership firm)</label></td>
                              <td>-</td>
                              <td>
                                <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                  onMultipleDragDrop={async (e) => {
                                    let tempErr = errors
                                    let tempData = data
                                    for (let index = 0; index < e.target.files.length; index++) {
                                      let element = e.target.files[index];
                                      let customEvent = {
                                        target: { name: "orgDoc1" + ":" + (j + index), files: [element] },
                                        persist: () => console.log("...")
                                      }
                                      let resp = await handleFileWithAsyncAwait(customEvent)
                                      if (resp.status / 1 == 0) {
                                        tempErr = { ...tempErr, ...resp.msg }
                                      }
                                      if (resp.status / 1 == 1) {
                                        tempData = { ...tempData, ...resp.msg }
                                      }
                                    }
                                    setData(tempData)
                                    setErrors(tempErr)
                                    let temp = orgDoc1
                                    for (let index = 0; index < e.target.files.length - 1; index++) {
                                      temp.push(null)
                                    }
                                    setOrgDoc1([...temp])
                                  }}
                                  name={"orgDoc1" + ":" + j} value={data["orgDoc1" + ":" + j]} error={errors["orgDoc1" + ":" + j]}
                                  onChange={handleFile}
                                  onUploadCancel={() => setData({ ...data, ["orgDoc1" + ":" + j]: null })}
                                />
                              </td>
                              <td>
                                {!data["orgDoc1" + ":" + j]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data["orgDoc1" + ":" + j]?.name); setData({ ...data, docToSign: data["orgDoc1" + ":" + j], docSignName: "orgDoc1" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {isDisabled ? "-" : <>
                                  {(orgDoc1.length - 1 > j) ?
                                    <img
                                      onClick={() => {
                                        let temp = orgDoc1
                                        temp = temp.filter((m, n) => {
                                          if (n != j) {
                                            return true
                                          }
                                        })
                                        setOrgDoc1([...temp])
                                      }}
                                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                    /> : <img
                                      onClick={() => {
                                        let temp = orgDoc1
                                        temp.push(null)
                                        setOrgDoc1([...temp])
                                      }}
                                      style={{ width: '22px', height: '22px' }}
                                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                </>}

                                {data["orgDoc1" + ":" + j]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data["orgDoc1" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data["orgDoc1" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}

                              </div></td>
                            </tr>
                          )
                        })}
                        {orgDoc2Hide ? null : orgDoc2.map((i, j) => {
                          return (
                            <>
                              <tr
                                style={{ borderBottom: '2px solid transparent' }}
                              >
                                <td className="w-30">
                                  <div className="d-flex">
                                    <div className="w-auto font-size-12 font-wt-500">Aadhar</div>
                                    <div className="position-relative w-100 ml-2">
                                      <InputForTable isDisabled={isDisabled} name={"aadharDoc" + ":" + j + "DocNo"} value={data["aadharDoc" + ":" + j + "DocNo"]} onChange={handleChange} />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="position-relative">
                                    <InputForTable isDisabled={isDisabled} name={"partnerName" + ":" + j} value={data["partnerName" + ":" + j]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={false}
                                    name={"aadharDoc" + ":" + j} value={data["aadharDoc" + ":" + j]} error={errors["aadharDoc" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["aadharDoc" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["aadharDoc" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["aadharDoc" + ":" + j]?.name); setData({ ...data, docToSign: data["aadharDoc" + ":" + j], docSignName: "aadharDoc" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data["aadharDoc" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["aadharDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["aadharDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                  {isDisabled ? "-" : <>
                                    {(orgDoc2.length - 1 > j) ?
                                      <img
                                        onClick={() => {
                                          let temp = orgDoc2
                                          temp = temp.filter((m, n) => {
                                            if (n != j) {
                                              return true
                                            }
                                          })
                                          setOrgDoc2([...temp])
                                        }}
                                        className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                      /> : <img
                                        onClick={() => {
                                          let temp = orgDoc2
                                          temp.push(null)
                                          setOrgDoc2([...temp])
                                        }}
                                        style={{ width: '22px', height: '22px' }}
                                        className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                  </>}
                                </div></td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-auto font-size-12 font-wt-500">PAN</div>
                                    <div className="position-relative w-auto ml-2">
                                      <InputForTable isDisabled={isDisabled} name={"panDoc" + ":" + j + "DocNo"} value={data["panDoc" + ":" + j + "DocNo"]} onChange={handleChange} />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="position-relative">
                                    <InputForTable isDisabled={isDisabled} name={"partnerName" + ":" + j} value={data["partnerName" + ":" + j]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={false}
                                    name={"panDoc" + ":" + j} value={data["panDoc" + ":" + j]} error={errors["panDoc" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["panDoc" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["panDoc" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["panDoc" + ":" + j]?.name); setData({ ...data, docToSign: data["panDoc" + ":" + j], docSignName: "panDoc" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data["panDoc" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["panDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["panDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                  {/* {(orgDoc2.length - 1 > j) ?
                                <img
                                  onClick={() => {
                                    let temp = orgDoc2
                                    temp = temp.filter((m, n) => {
                                      if (n != j) {
                                        return true
                                      }
                                    })
                                    setOrgDoc2([...temp])
                                  }}
                                  className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                /> :
                                 <img
                                  onClick={() => {
                                    let temp = orgDoc2
                                    temp.push(null)
                                    setOrgDoc2([...temp])
                                  }}
                                  className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />
                                  } */}
                                </div></td>
                              </tr>
                            </>
                          )
                        })}
                        {orgDoc3Hide ? null : orgDoc3.map((i, j) => {
                          return (
                            <tr>
                              <td className="w-30">
                                <div className="position-relative">
                                  <InputForTable isDisabled={isDisabled} label={""} name={"orgDoc3" + ":" + j + "Name"} value={data["orgDoc3" + ":" + j + "Name"]} onChange={handleChange} />
                                </div>
                              </td>
                              <td>-</td>
                              <td>
                                <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                  onMultipleDragDrop={async (e) => {
                                    let tempErr = errors
                                    let tempData = data
                                    for (let index = 0; index < e.target.files.length; index++) {
                                      let element = e.target.files[index];
                                      let customEvent = {
                                        target: { name: "orgDoc3" + ":" + (j + index), files: [element] },
                                        persist: () => console.log("...")
                                      }
                                      let resp = await handleFileWithAsyncAwait(customEvent)
                                      if (resp.status / 1 == 0) {
                                        tempErr = { ...tempErr, ...resp.msg }
                                      }
                                      if (resp.status / 1 == 1) {
                                        tempData = { ...tempData, ...resp.msg }
                                      }
                                    }
                                    setData(tempData)
                                    setErrors(tempErr)
                                    let temp = orgDoc3
                                    for (let index = 0; index < e.target.files.length - 1; index++) {
                                      temp.push(null)
                                    }
                                    setOrgDoc3([...temp])
                                  }}
                                  name={"orgDoc3" + ":" + j} value={data["orgDoc3" + ":" + j]} error={errors["orgDoc3" + ":" + j]}
                                  onChange={handleFile}
                                  onUploadCancel={() => setData({ ...data, ["orgDoc3" + ":" + j]: null })}
                                />
                              </td>
                              <td>
                                {!data["orgDoc3" + ":" + j]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data["orgDoc3" + ":" + j]?.name); setData({ ...data, docToSign: data["orgDoc3" + ":" + j], docSignName: "orgDoc3" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {data["orgDoc3" + ":" + j]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data["orgDoc3" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data["orgDoc3" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}
                                {isDisabled ? "-" : <>
                                  {(orgDoc3.length - 1 > j) ?
                                    <img
                                      onClick={() => {
                                        let temp = orgDoc3
                                        temp = temp.filter((m, n) => {
                                          if (n != j) {
                                            return true
                                          }
                                        })
                                        setOrgDoc3([...temp])
                                      }}
                                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                    /> : <img
                                      onClick={() => {
                                        let temp = orgDoc3
                                        temp.push(null)
                                        setOrgDoc3([...temp])
                                      }}
                                      style={{ width: '22px', height: '22px' }}
                                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                </>}
                              </div></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </> : null : <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{orgDocLabel || 'Organizational'}</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '12%' } }, { subColumns: 'Document no', subColumnStyle: { width: '22%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '50%' } }, { subColumns: 'Sign', subColumnStyle: { width: '16%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '16%' } }]}>
                        {orgDoc2Hide ? null : orgDoc2.map((i, j) => {
                          return (
                            <>
                              <tr
                                style={{ borderBottom: '2px solid transparent' }}
                              >
                                <td className="w-30">
                                  <div className="d-flex">
                                    <div className="w-auto font-size-12 font-wt-500">Aadhar</div>
                                  </div>
                                </td>
                                <td>
                                  <div className="position-relative">
                                    <InputForTable isDisabled={isDisabled} name={"aadharDoc" + ":" + j + "DocNo"} value={data["aadharDoc" + ":" + j + "DocNo"]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={false}
                                    name={"aadharDoc" + ":" + j} value={data["aadharDoc" + ":" + j]} error={errors["aadharDoc" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["aadharDoc" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["aadharDoc" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["aadharDoc" + ":" + j]?.name); setData({ ...data, docToSign: data["aadharDoc" + ":" + j], docSignName: "aadharDoc" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data["aadharDoc" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["aadharDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["aadharDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div></td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-auto font-size-12 font-wt-500">PAN</div>
                                  </div>
                                </td>
                                <td>
                                  <div className="position-relative">
                                    <InputForTable isDisabled={isDisabled} name={"panDoc" + ":" + j + "DocNo"} value={data["panDoc" + ":" + j + "DocNo"]} onChange={handleChange} />
                                  </div>
                                </td>
                                <td>
                                  <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={false}
                                    name={"panDoc" + ":" + j} value={data["panDoc" + ":" + j]} error={errors["panDoc" + ":" + j]}
                                    onChange={handleFile}
                                    onUploadCancel={() => setData({ ...data, ["panDoc" + ":" + j]: null })}
                                  />
                                </td>
                                <td>
                                  {!data["panDoc" + ":" + j]?.signatureId ?
                                    <img className="cursor"
                                      onClick={() => { setSigndoc(data["panDoc" + ":" + j]?.name); setData({ ...data, docToSign: data["panDoc" + ":" + j], docSignName: "panDoc" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                    :
                                    <img style={{ width: '20px' }} className=""
                                      src={"assets/images/doc_sign_filled.svg"} alt="" />
                                  }
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {data["panDoc" + ":" + j]?.name ? (
                                    <>
                                      <img onClick={() => { toggleDowdDoc({ show: true, doc: data["panDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: data["panDoc" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div></td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-auto font-size-12 font-wt-500">Agreement</div>
                                  </div>
                                </td>
                                <td>
                                  <div className="position-relative">
                                    -
                                  </div>
                                </td>
                                <td>
                                  <FileInput isEditable={false} hideViewDowdBtns={true} multipleDragDropEnable={false}
                                    name={"agreementDoc"} value={cpAgreementDoc}
                                  />
                                </td>
                                <td>
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                </td>
                                <td><div
                                  className="d-flex"
                                >
                                  {cpAgreementDoc?.name ? (
                                    <>
                                      <img onClick={() => {
                                        toggleDowdDoc({ show: true, doc: cpAgreementDoc })
                                      }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                      <img onClick={() => { toggleViewDoc({ show: true, doc: cpAgreementDoc }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                    </>
                                  ) : null}
                                </div></td>
                              </tr>
                            </>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </>}

                {unkTypeHide ? null :
                  isDisabled ? <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>Other</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } }, { subColumns: 'Year', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '45%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '15%' } }]}>
                        {unkType.map((i, j) => {
                          return (
                            <tr>
                              <td className="w-30">
                                <div className="position-relative">
                                  <InputForTable isDisabled={isDisabled} label={""} name={"unkType" + ":" + j + "Name"} value={data["unkType" + ":" + j + "Name"]} onChange={handleChange} />
                                </div></td>
                              <td>-</td>
                              <td>
                                <FileInput isEditable={!isDisabled} hideViewDowdBtns={true} multipleDragDropEnable={true}
                                  onMultipleDragDrop={async (e) => {
                                    let tempErr = errors
                                    let tempData = data
                                    let filesDataArray = []
                                    let temp = unkType
                                    for (let index = 0; index < e.target.files.length; index++) {
                                      let element = e.target.files[index];
                                      let customEvent = {
                                        target: { name: "unkType" + ":" + (j + index), files: [element] },
                                        persist: () => console.log("...")
                                      }
                                      let resp = await handleFileWithAsyncAwait(customEvent)
                                      if (resp.status / 1 == 0) {
                                        tempErr = { ...tempErr, ...resp.msg }
                                      }
                                      if (resp.status / 1 == 1) {
                                        tempData = { ...tempData, ...resp.msg }
                                        filesDataArray[index] = resp.msg[customEvent.target.name]
                                        temp.push(null)
                                      }
                                    }
                                    setErrors(tempErr)
                                    setData(tempData)
                                    setUnkType([...temp])
                                  }}
                                  name={"unkType" + ":" + j} value={data["unkType" + ":" + j]} error={errors["unkType" + ":" + j]}
                                  onChange={handleFile}
                                  onUploadCancel={() => setData({ ...data, ["unkType" + ":" + j]: null })}
                                />
                              </td>
                              <td>
                                {!data["unkType" + ":" + j]?.signatureId ?
                                  <img className="cursor"
                                    onClick={() => { setSigndoc(data["unkType" + ":" + j]?.name); setData({ ...data, docToSign: data["unkType" + ":" + j], docSignName: "unkType" + ":" + j }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                                  :
                                  <img style={{ width: '20px' }} className=""
                                    src={"assets/images/doc_sign_filled.svg"} alt="" />
                                }
                              </td>
                              <td><div
                                className="d-flex"
                              >
                                {isDisabled ? "-" : <>
                                  {(unkType.length - 1 > j) ?
                                    <img
                                      onClick={() => {
                                        let temp = unkType
                                        temp = temp.filter((m, n) => {
                                          if (n != j) {
                                            return true
                                          }
                                        })
                                        setUnkType([...temp])
                                      }}
                                      className="mr-2 cursor" src={"assets/images/blue_cross_icon.svg"}
                                    /> : <img
                                      onClick={() => {
                                        let temp = unkType
                                        temp.push(null)
                                        setUnkType([...temp])
                                      }}
                                      style={{ width: '22px', height: '22px' }}
                                      className="mr-2 cursor" src={"assets/images/new_add_icon.svg"} />}
                                </>}
                                {data["unkType" + ":" + j]?.name ? (
                                  <>
                                    <img onClick={() => { toggleDowdDoc({ show: true, doc: data["unkType" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                    <img onClick={() => { toggleViewDoc({ show: true, doc: data["unkType" + ":" + j] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                                  </>
                                ) : null}
                              </div></td>
                            </tr>
                          )
                        })}
                      </NewTablev2>
                    </div>
                  </> : null}

                {contractId ? <>
                  <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{'Contract Documents'}</u></label>
                  <div>
                    <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } },
                    // { subColumns: 'Partner name', subColumnStyle: { width: '15%' } },
                    { subColumns: 'Attach document', subColumnStyle: { width: '40%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                    { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                      {showContractPO ?
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >PO Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "poDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, poDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['poDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`poDocument`]}
                              error={errors[`poDocument`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['poDocument']: null })
                              }}
                            />
                            {errors["poDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["poDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`poDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`poDocument`]?.name); setData({ ...data, docToSign: data[`poDocument`], docSignName: `poDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`poDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`poDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`poDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr> : null}
                      {showContractInvoice ?
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Invoice Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "invoiceDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, invoiceDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['invoiceDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`invoiceDocument`]}
                              error={errors[`invoiceDocument`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['invoiceDocument']: null })
                              }}
                            />
                            {errors["invoiceDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["invoiceDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`invoiceDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`invoiceDocument`]?.name); setData({ ...data, docToSign: data[`invoiceDocument`], docSignName: `invoiceDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`invoiceDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`invoiceDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`invoiceDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr> : null}
                      {showContractAgreement ?
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Contract of Party A/B</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "contractPartyAB", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, contractPartyAB: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['contractPartyAB'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`contractPartyAB`]}
                              error={errors[`contractPartyAB`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['contractPartyAB']: null })
                              }}
                            />
                            {errors["contractPartyAB"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["contractPartyAB"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`contractPartyAB`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`contractPartyAB`]?.name); setData({ ...data, docToSign: data[`contractPartyAB`], docSignName: `contractPartyAB` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`contractPartyAB`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`contractPartyAB`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`contractPartyAB`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr> : null}
                    </NewTablev2>
                  </div>

                  {showShipmentDocs ? <>

                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{'Shipment Documents'}</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } },
                      // { subColumns: 'Partner name', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '40%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Freight Invoice</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "freightInvoice", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, freightInvoice: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['freightInvoice'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`freightInvoice`]}
                              error={errors[`freightInvoice`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['freightInvoice']: null })
                              }}
                            />
                            {errors["freightInvoice"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["freightInvoice"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`freightInvoice`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`freightInvoice`]?.name); setData({ ...data, docToSign: data[`freightInvoice`], docSignName: `freightInvoice` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`freightInvoice`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`freightInvoice`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`freightInvoice`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Bill of Lading</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "billOfLading", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, billOfLading: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['billOfLading'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`billOfLading`]}
                              error={errors[`billOfLading`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['billOfLading']: null })
                              }}
                            />
                            {errors["billOfLading"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["billOfLading"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`billOfLading`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`billOfLading`]?.name); setData({ ...data, docToSign: data[`billOfLading`], docSignName: `billOfLading` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`billOfLading`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`billOfLading`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`billOfLading`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Charter Party Agreement</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "charterPartyAgreement", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, charterPartyAgreement: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['charterPartyAgreement'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`charterPartyAgreement`]}
                              error={errors[`charterPartyAgreement`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['charterPartyAgreement']: null })
                              }}
                            />
                            {errors["charterPartyAgreement"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["charterPartyAgreement"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`charterPartyAgreement`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`charterPartyAgreement`]?.name); setData({ ...data, docToSign: data[`charterPartyAgreement`], docSignName: `charterPartyAgreement` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`charterPartyAgreement`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`charterPartyAgreement`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`charterPartyAgreement`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Vessel Fixture Recap Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "vesselFixtureRecapDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, vesselFixtureRecapDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['vesselFixtureRecapDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`vesselFixtureRecapDocument`]}
                              error={errors[`vesselFixtureRecapDocument`]}
                              isEditable={!isDisabled}
                              onUploadCancel={() => {
                                setData({ ...data, ['vesselFixtureRecapDocument']: null })
                              }}
                            />
                            {errors["vesselFixtureRecapDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["vesselFixtureRecapDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`vesselFixtureRecapDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`vesselFixtureRecapDocument`]?.name); setData({ ...data, docToSign: data[`vesselFixtureRecapDocument`], docSignName: `vesselFixtureRecapDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`vesselFixtureRecapDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`vesselFixtureRecapDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`vesselFixtureRecapDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                      </NewTablev2>
                    </div>
                  </> : null}

                  {hideInvestigationDocs ? null : <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{'Investigation Documents'}</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } },
                      // { subColumns: 'Partner name', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '40%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Investigation Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "investigationDoc", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, investigationDoc: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['investigationDoc'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`investigationDoc`]}
                              error={errors[`investigationDoc`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['investigationDoc']: null })
                              }}
                            />
                            {errors["investigationDoc"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["investigationDoc"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`investigationDoc`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`investigationDoc`]?.name); setData({ ...data, docToSign: data[`investigationDoc`], docSignName: `investigationDoc` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`investigationDoc`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`investigationDoc`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`investigationDoc`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Certificate of Sampling & Analysis</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "certOfSampling", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, certOfSampling: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['certOfSampling'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`certOfSampling`]}
                              error={errors[`certOfSampling`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['certOfSampling']: null })
                              }}
                            />
                            {errors["certOfSampling"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["certOfSampling"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`certOfSampling`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`certOfSampling`]?.name); setData({ ...data, docToSign: data[`certOfSampling`], docSignName: `certOfSampling` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`certOfSampling`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`certOfSampling`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`certOfSampling`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                      </NewTablev2>
                    </div>
                  </>}

                  {hideInsuranceDocs ? null : <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{'Insurance Documents'}</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } },
                      // { subColumns: 'Partner name', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '40%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Statement of Facts (SOF)</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "sofDoc", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, sofDoc: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['sofDoc'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`sofDoc`]}
                              error={errors[`sofDoc`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['sofDoc']: null })
                              }}
                            />
                            {errors["sofDoc"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["sofDoc"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`sofDoc`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`sofDoc`]?.name); setData({ ...data, docToSign: data[`sofDoc`], docSignName: `sofDoc` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`sofDoc`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`sofDoc`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`sofDoc`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Ship Insurance</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "shipInsurance", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, shipInsurance: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['shipInsurance'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`shipInsurance`]}
                              error={errors[`shipInsurance`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['shipInsurance']: null })
                              }}
                            />
                            {errors["shipInsurance"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["shipInsurance"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`shipInsurance`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`shipInsurance`]?.name); setData({ ...data, docToSign: data[`shipInsurance`], docSignName: `shipInsurance` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`shipInsurance`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`shipInsurance`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`shipInsurance`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Commodity Insurance</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "commodityInsurance", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, commodityInsurance: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['commodityInsurance'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`commodityInsurance`]}
                              error={errors[`commodityInsurance`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['commodityInsurance']: null })
                              }}
                            />
                            {errors["commodityInsurance"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["commodityInsurance"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`commodityInsurance`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`commodityInsurance`]?.name); setData({ ...data, docToSign: data[`commodityInsurance`], docSignName: `commodityInsurance` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`commodityInsurance`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`commodityInsurance`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`commodityInsurance`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Trade Insurance</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "tradeInsurance", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, tradeInsurance: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['tradeInsurance'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`tradeInsurance`]}
                              error={errors[`tradeInsurance`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['tradeInsurance']: null })
                              }}
                            />
                            {errors["tradeInsurance"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["tradeInsurance"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`tradeInsurance`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`tradeInsurance`]?.name); setData({ ...data, docToSign: data[`tradeInsurance`], docSignName: `tradeInsurance` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`tradeInsurance`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`tradeInsurance`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`tradeInsurance`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                      </NewTablev2>
                    </div></>}

                  {hideDiscountingDocs ? null : <>
                    <label className="font-size-14 font-wt-600 text-color-value col-10 my-4 p-0"><u>{'Discounting Documents'}</u></label>
                    <div>
                      <NewTablev2 columns={[{ subColumns: "Document name", subColumnStyle: { width: '18%' } },
                      // { subColumns: 'Partner name', subColumnStyle: { width: '15%' } },
                      { subColumns: 'Attach document', subColumnStyle: { width: '40%' } }, { subColumns: 'Sign', subColumnStyle: { width: '10%' } },
                      { subColumns: 'Action', subColumnStyle: { width: '10%' } }]}>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Exhibit Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "exhibitDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, exhibitDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['exhibitDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`exhibitDocument`]}
                              error={errors[`exhibitDocument`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['exhibitDocument']: null })
                              }}
                            />
                            {errors["exhibitDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["exhibitDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`exhibitDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`exhibitDocument`]?.name); setData({ ...data, docToSign: data[`exhibitDocument`], docSignName: `exhibitDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`exhibitDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`exhibitDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`exhibitDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}

                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >NOA Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "noaDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, noaDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['noaDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`noaDocument`]}
                              error={errors[`noaDocument`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['noaDocument']: null })
                              }}
                            />
                            {errors["noaDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["noaDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`noaDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`noaDocument`]?.name); setData({ ...data, docToSign: data[`noaDocument`], docSignName: `noaDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`noaDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`noaDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`noaDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Framework Document</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "frameworkDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, frameworkDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['frameworkDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`frameworkDocument`]}
                              error={errors[`frameworkDocument`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['frameworkDocument']: null })
                              }}
                            />
                            {errors["frameworkDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["frameworkDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`frameworkDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`frameworkDocument`]?.name); setData({ ...data, docToSign: data[`frameworkDocument`], docSignName: `frameworkDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`frameworkDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`frameworkDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`frameworkDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                        <tr
                        >
                          <td className="w-30">
                            <label className="font-size-12 font-wt-500" >Term Sheet</label></td>
                          <td>
                            <FileInput multipleDragDropEnable={true}
                              onMultipleDragDrop={async (e) => {
                                setshowLoader(true)
                                let tempFilesArr = []
                                let tempData = data
                                let tempErrors = errors
                                for (let index = 0; index < e.target.files.length; index++) {
                                  let element = e.target.files[index];
                                  let customEvent = {
                                    target: { name: "termSheetDocument", files: [element] },
                                    persist: () => console.log("...")
                                  }
                                  let resp = await handleMultipleFile(customEvent)
                                  if (resp.success) {
                                    tempFilesArr.push(resp.msg)
                                    tempErrors = { ...tempErrors, termSheetDocument: "" }
                                  }
                                  else {
                                    tempErrors = { ...tempErrors, ...resp.msg }
                                  }
                                }
                                if (tempFilesArr.length) {
                                  tempData['termSheetDocument'] = await mergeMultiplePdfs(tempFilesArr, "Merged Document")
                                }
                                setData(tempData)
                                setErrors(tempErrors)
                                setshowLoader(false)
                              }}
                              value={data[`termSheetDocument`]}
                              error={errors[`termSheetDocument`]}
                              isEditable={true}
                              onUploadCancel={() => {
                                setData({ ...data, ['termSheetDocument']: null })
                              }}
                            />
                            {errors["termSheetDocument"] ? <div class="text-danger mt-2 font-size-12">
                              <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              <b>{errors["termSheetDocument"]}</b></div> : ''}
                          </td>
                          <td>
                            {!data[`termSheetDocument`]?.signatureId ?
                              <img className="cursor"
                                onClick={() => { setSigndoc(data[`termSheetDocument`]?.name); setData({ ...data, docToSign: data[`termSheetDocument`], docSignName: `termSheetDocument` }) }} src={"assets/images/supplier-images/fluent_signature-20-regular.png"} alt="" />
                              :
                              <img style={{ width: '20px' }} className=""
                                src={"assets/images/doc_sign_filled.svg"} alt="" />
                            }
                          </td>
                          <td><div
                            className="d-flex"
                          >
                            {data[`termSheetDocument`]?.name ? (
                              <>
                                <img onClick={() => { toggleDowdDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_dowd_icon.svg"} />
                                <img onClick={() => { toggleViewDoc({ show: true, doc: data[`termSheetDocument`] }) }} className="mr-2 cursor" src={"assets/images/new_eye_icon.svg"} />
                              </>
                            ) : null}
                          </div></td>
                        </tr>
                      </NewTablev2>
                    </div></>}


                </> : null}




                {showContinueButton ?
                  <div style={continueBtnCSS} className=" py-4">
                    <button type="button" id="extraDetailsForLimitContinueBtn"
                      onClick={() => saveDetails()}
                      className={` new-btn w-17 py-2 px-2  text-white`}>
                      {continueBtnLabel || "Continue"}
                    </button>
                  </div> : null}

                {/* {data.informationSaved ? null : (
                  <div className="d-flex row">
                    <div className="w-20">
                      <button type="button"
                        onClick={() => {
                          saveDetails()
                        }}
                        className={`mx-2 new-btn py-2 px-2 text-white w-100`}>
                        {"Send Document"}
                      </button>
                    </div>
                    <div className="w-20">
                      <button type="button"
                        onClick={() => {
                          if (adminINVApply) {
                            window.close()
                          } else {
                            window.location = "Quotes"
                          }
                        }}
                        className={`mx-2 new-btn3 py-2 px-2 w-100`}>
                        {"Remind me Later"}
                      </button>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </main>
        </div >
      </div >
    </>
  )
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(ExtraDetailsForLimitApplication)