import React, { useEffect, useState } from 'react';
// import { } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import Header from '../partial/header';
import ReactCountryFlag from "react-country-flag";
import call from '../../service';
import { ToastContainer } from 'react-toastify';
import toastDisplay from '../../utils/toastNotification';
import { formatDateTime_Application, formatDate_Application } from '../../utils/dateFormaters';
import toolTipText from '../../utils/toolTips/quotations.json';
import validate from '../../utils/validation_files/quotationValidationRules'
import customStyles from '../dataTablesColumsMap/customTableCss';
import columns from '../dataTablesColumsMap/quotationListColumns';
import DataTable from 'react-data-table-component';
import Footer from '../partial/footer';
import AddQuotation from './addQuotation';
import moment from 'moment'
import Pagination from "react-js-pagination";
import NegotiationPopup from '../ships/marketPlaceBookings/shipmentQuotations/negotiationPopup';
const queryString = require('query-string');

const Shipment = ({ userTokenDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  let [showLoader, setshowLoader] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  let [quotationdata, setquotationdata] = useState({});
  let [messageData, setmessageData] = useState([]);
  let [contractData, setcontractData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [chat, setChat] = useState(false);
  let [currencyData, setcurrencyData] = useState([]);
  let [data, setData] = useState({})
  const [completeButton, setcompleteButton] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [containerTypeData, setcontainerTypeData] = useState([]);

  const [showNegPopup, toggleNegPopup] = useState(false)
  const [shipmentData, setShipmentData] = useState([])
  const [popupType, setpopupType] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    // Set user details in main state
    setData(data => ({ ...data, senderUserId: userId, user_type: userTypeId }));
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(currentPage, { user_id: userId })

    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })
    //------------------------------------------------------------------
  }, [refresh, filterData]);


  useEffect(() => {
    if (Object.keys(errorData).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errorData]);

  useEffect(() => {
    checkFilterQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [contractCount, setcontractCount] = useState({})
  const [searchKey, setsearchKey] = useState("")



  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "user_id": inptObject.user_id ? inptObject.user_id : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true,
      "showMktPlaceQuotations": true,
      "showContractQuotations": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getquotationlist', objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.quotdata))
      setTotalRows(result.countdata.totalCount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    setCurrentPage(page)
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "user_id": userId,
      "type_id": userTypeId,
      "gridOnly": true,
      "showMktPlaceQuotations": true,
      "showContractQuotations": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getquotationlist', objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.quotdata))
      setTotalRows(result.countdata.totalCount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };



  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      if (!dataArray[i].mode) {
        tempObj.created_at = dataArray[i].created_at;
        tempObj.contract_name = dataArray[i].contract_name;
        tempObj.contract_id = dataArray[i].contract_id;
        tempObj.quotation_party = (userTypeId / 1 === 5 || userTypeId / 1 === 10 || userTypeId / 1 === 11) ? dataArray[i].to_company : dataArray[i].from_company;
        tempObj.type = dataArray[i].type / 1 === 1 ? "Shipment Quotation" : '--';
        tempObj.price = dataArray[i].currency ? dataArray[i].currency.split(':')[1] + ' ' + dataArray[i].amount : "NA";
        tempObj.bidPrice = dataArray[i].currency ? dataArray[i].currency.split(':')[1] + ' ' + dataArray[i].bid_amount : "NA";
        tempObj.message = dataArray[i].message;
        tempObj.status = dataArray[i].status;
        tempObj.action = (<>
          <span className="btn btn-info btn-sm mr-2 ml-2" onClick={() => { setupdateModal(true); setquotationdata(dataArray[i]); getContractAndCommunicationData(dataArray[i]); fillQuotationData(dataArray[i]) }}>
            <i className={"fa " + (dataArray[i].status === 0 ? "fa-pencil-square-o" : "fa-eye")} aria-hidden="true"></i>
          </span>
        </>)
      }
      else {
        tempObj = dataArray[i]
      }

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //---------------------------------------------------------------------------------------------------------------------




  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
  }
  const handleChecked = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
  }

  const handleShipmentType = (event) => {
    event.persist();
    if (event.target.value === "container") {
      call('get', 'getcontainertypes').then((result) => {
        setcontainerTypeData(result)
      }).catch((e) => {
        console.log('error in getcurrencylist', e);
      })
    }
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  function checkFilterQuery() {
    let query = queryString.parse(window.location.search);
    filterStateButtons(query.status / 1)
    setcompleteButton(query.status / 1)
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    if (type === 2) {
      setFilterData({
        ...filterData,
        onlyCompleted: true,
        onlyIncompleted: false,
        "showMktPlaceQuotations": false,
        "showContractQuotations": true
      })
    }
    else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyCompleted: false,
        onlyIncompleted: true,
        "showMktPlaceQuotations": false,
        "showContractQuotations": true
      })
    }
    else if (type === 3) {
      setFilterData({
        ...filterData,
        onlyCompleted: false,
        onlyIncompleted: true,
        "showMktPlaceQuotations": true,
        "showContractQuotations": false
      })
    }
    else if (type === 4) {
      setFilterData({
        ...filterData,
        onlyCompleted: true,
        onlyIncompleted: false,
        "showMktPlaceQuotations": true,
        "showContractQuotations": false
      })
    }
  };

  const reset = () => {
    setcompleteButton(0)
    setFilterData({})
    setCurrentPage(1)
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  // function ExportTableCSV() {

  //   let objectAPI = {
  //     "currentPage": 1,
  //     "resultPerPage": perPage,
  //     "user_id": userId,
  //     "type_id": userTypeId,
  //     "gridOnly": true
  //   }

  //   objectAPI = { ...objectAPI, ...filterData }

  //   call('POST', 'getquotationlist', objectAPI).then((result) => {
  //     //  console.log('running getcontract api-->', result);

  //     const quotationList = result.quotdata;

  //     let csvString = "Contract No.," + ((userTypeId / 1 === 5 || userTypeId / 1 === 10 || userTypeId / 1 === 11) ? "Quotation Sent To," : "Quotation Received From,") + "Type,Price,Biding Price,Message,Status\r\n"

  //     for (let i = 0; i < quotationList.length; i++) {
  //       let rowString = `${quotationList[i].contract_id},
  //     ${(userTypeId / 1 === 5 || userTypeId / 1 === 10 || userTypeId / 1 === 11) ? quotationList[i].to_company : quotationList[i].from_company},
  //     ${quotationList[i].type / 1 === 1 ? "Shipment Quotation" : '--'},
  //     ${(quotationList[i].currency) ? quotationList[i].currency.split(':')[1] + " " + quotationList[i].amount : '--'},
  //     ${(quotationList[i].currency) ? quotationList[i].currency.split(':')[1] + " " + quotationList[i].bid_amount : '--'},
  //     ${quotationList[i].message ? quotationList[i].message : "--"},
  //     ${quotationList[i].status === 0 ? "Pending" : quotationList[i].status === 1 ? "Accepted" : quotationList[i].status === 2 ? "Rejected" : "Closed"}\r\n`

  //       rowString = rowString.replace(/(\n)/gm, "")
  //       csvString += rowString
  //     }
  //     let link = document.createElement('a');
  //     link.style.display = 'none';
  //     link.setAttribute('target', '_blank');
  //     link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
  //     link.setAttribute('download', ((userTypeId / 1 === 5 || userTypeId / 1 === 10 || userTypeId / 1 === 11) ? "Sent_Quotations" : "Received_Quotations") + `_${userEmail.split('@')[0]}.csv`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);


  //   }).catch((e) => {
  //     console.log('error in getContracts', e);
  //     setLoading(false)
  //   })

  // }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function getContractAndCommunicationData(dataF) {
    setshowLoader(true)
    call('post', 'getcommunication', { contractId: dataF.contract_id, user_id1: dataF.from_user, user_id2: dataF.to_user, type: 3, userType: userTypeId }).then((result) => {
      console.log('result in getcommunication', result);
      setmessageData(result)
    }).catch((e) => {
      console.log('error in getcommunication', e);
    })

    call('POST', 'getContracts', { contract_number: dataF.contract_id, contractOnly: true, type_id: userTypeId }).then(async (result) => {
      // console.log('result in getContracts', result);
      setcontractData(result)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getContracts', e);
    })
  }

  function fillQuotationData(funcdata) {
    setData(data => ({
      ...data,
      quotStatus: funcdata.status ? funcdata.status : 0,
      shipPrice: funcdata.bid_amount,
      shipCurrency: funcdata.currency,
      quantity: funcdata.quantity,
      quantityIndexType: funcdata.quantity_index_type,
      quantityIndex: funcdata.quantity_index,
      quantityLoadUnit: funcdata.mol_type,
      demmurageRate: funcdata.demurrage_rate,
      charterType: `${funcdata.charter_type}`,
      containerCount: funcdata.container_count,
      containerType: funcdata.container_type,
      shipmentType: funcdata.shipment_type,
      berthLoadingNumber: funcdata.berth_loading,
      anchorageLoadingNumber: funcdata.anchorage_loading,
      berthUnloadingNumber: funcdata.berth_unloading,
      anchorageUnloadingNumber: funcdata.anchorage_unloading,
      berthLoading: funcdata.berth_loading > 0 ? true : false,
      anchorageLoading: funcdata.anchorage_loading > 0 ? true : false,
      berthUnloading: funcdata.berth_unloading > 0 ? true : false,
      anchorageUnloading: funcdata.anchorage_unloading > 0 ? true : false,
      loadingRate: funcdata.loading_rate ? funcdata.loading_rate : 0,
      unloadingRate: funcdata.unloading_rate ? funcdata.unloading_rate : 0,
    }));
    handleShipmentType({ target: { value: funcdata.shipment_type }, persist: () => { } })
  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit Handlers

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    seterrorData(validate(data));
    setIsSubmitting(true);
  };

  function handleSubmit() {
    setshowLoader(true)
    setIsSubmitting(false)

    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("id", quotationdata.id);
    formData.append("contract_id", quotationdata.contract_id);
    formData.append("from_user", quotationdata.from_user);
    formData.append("to_user", quotationdata.to_user);

    call('POST', 'insertquotation', formData).then((result) => {
      //  console.log('post request result:', result);
      if (result) {
        setshowLoader(false)
        setupdateModal(false)
        setrefresh(refresh + 1)
        toastDisplay("Quotation Updated", "success");
        setData(data => ({ ...data, "quotMessage": "" }));
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })

  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Mini Preview Component
  function miniPreview() {
    return (
      <ul className="price-ul">
        <li>
          <div className="price-left"> {contractData ? '#' + contractData.contract_number : "--"}</div>
          <div className="price-right"> Type/Contract No.</div>
        </li>
        <li>
          <div className="price-left">{contractData ? contractData.buyername : "--"}</div>
          <div className="price-right"> Buyer</div>
        </li>
        <li>
          <div className="price-left">{contractData ? contractData.suppliername : "--"}</div>
          <div className="price-right"> Seller</div>
        </li>
        <li>
          <div className="price-left">{contractData.commodity_name ? contractData.commodity_name : "--"}</div>
          <div className="price-right"> Commodity</div>
        </li>
        <li>
          <div className="price-left">{(contractData.specification && contractData.specification.type) ? contractData.specification.type.value : "--"}</div>
          <div className="price-right"> Commodity Type</div>
        </li>
        <li>
          <div className="price-left">{contractData ? contractData.quantity + ' mt' : "--"}</div>
          <div className="price-right"> Quantity</div>
        </li>
        <li>
          <div className="price-left">{contractData && contractData.load_country ?
            <span className="shadow">
              <ReactCountryFlag
                countryCode={contractData.load_country ? contractData.load_country.split(':')[0] : ''}
                style={{ width: '25px', height: '25px' }} svg />
            </span> : "--"}
            {" " + (contractData.load_country ? contractData.load_country.split(':')[0] : '')}
          </div>
          <div className="price-right"> Loading Country</div>
        </li>
        <li>
          <div className="price-left">{contractData.loading_port}</div>
          <div className="price-right"> Loading Port</div>
        </li>
        <li>
          <div className="price-left">{contractData.laycan_end ? formatDate_Application(contractData.laycan_end) : "--"}</div>
          <div className="price-right"> Laycan Start</div>
        </li>
        <li>
          <div className="price-left">{contractData.laycan_end ? formatDate_Application(contractData.laycan_end) : "--"}</div>
          <div className="price-right">Laycan End</div>
        </li>
        <li>
          <div className="price-left">{contractData && contractData.unload_country ?
            <span className="shadow">
              <ReactCountryFlag
                countryCode={contractData.unload_country ? contractData.unload_country.split(':')[0] : ''}
                style={{ width: '25px', height: '25px' }} svg />
            </span> : "--"}
            {" " + (contractData.unload_country ? contractData.unload_country.split(':')[0] : '')}
          </div>
          <div className="price-right"> Unloading Country</div>
        </li>
        <li>
          <div className="price-left"> {contractData.unloading_port}</div>
          <div className="price-right"> Unloading Port</div>
        </li>
        <li>
          <div className="price-left">{quotationdata.currency ? quotationdata.currency.split(':')[1] + quotationdata.amount : "--"}</div>
          <div className="price-right"> Price</div>
        </li>
        <li>
          <div className="price-left">{quotationdata.currency ? quotationdata.currency.split(':')[1] + quotationdata.bid_amount : "--"}</div>
          <div className="price-right">Biding Price</div>
        </li>
      </ul>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------




  //---------------------------------------------------------------------------------------------------------------------
  // Message list Component
  function gettrailList() {
    const trailList = messageData ? messageData.map((messageData, index) => {
      return (
        <>
          {(messageData.msg_from !== userId) &&
            <div className="incoming_msg">
              <div className="msg_img">
                <img className="rounded-circle" src={"https://ui-avatars.com/api/?name=" + messageData.from_company} alt="" />
              </div>
              <div className="msg_parent">
                <p>{messageData.message}</p>
                <span className="time_date"><i className="fa fa-user-o"></i> {messageData.from_company}</span>
                <span className="time_date"><i className="fa fa-clock-o  pl-5"></i> {formatDateTime_Application(messageData.created_at)}</span>
              </div>
            </div>
          }
          {(messageData.msg_from === userId) &&
            <div className="outgoing_msg">
              <div className="msg_parent">
                <p>{messageData.message}</p>
                <span className="time_date"><i className="fa fa-user-o"></i> {messageData.from_company}</span>
                <span className="time_date"><i className="fa fa-clock-o  pl-5"></i> {formatDateTime_Application(messageData.created_at)}</span>
              </div>
              <div className="msg_img">
                <img className="rounded-circle" src={"https://ui-avatars.com/api/?name=" + messageData.from_company} alt="" />
              </div>
            </div>
          }
        </>
      )
    }) : ''
    return trailList
  }

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function createQuotationListBody(data) {
    // console.log('data receved', data);
    return (
      data && data.map(item => {
        if (item.mode) {
          return (
            <>
              <li className={"d-flex mb-2"}>
                {/* <h6
                  className='bg-primary text-white position-absolute p-1'
                  style={{ position: 'absolute' }}
                >Marketplace</h6> */}
                <div className="ntf_time">
                  {traderTypeIds.includes(userTypeId) ? (
                    <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  ) : (
                    <label>{moment(item.booking_created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                  )}
                </div>
                <div className="ntf_content">
                  <ul className="p-0 d-flex">
                    {item.mode ? (
                      <li className="col-md-4">
                        <label>{item.mode}</label>
                        <span>{"Mode"}</span>
                      </li>
                    ) : null}
                    {item.mode_type ? (
                      <li className="col-md-4">
                        <label>{item.mode_type}</label>
                        <span>{"Mode Type"}</span>
                      </li>
                    ) : null}
                    {item.origin_country ? (
                      <li className="col-md-4">
                        <label>{item.origin_country}</label>
                        <span>Origin Country</span>
                      </li>
                    ) : null}
                    {item.origin_port ? (
                      <li className="col-md-4">
                        <label>{item.origin_port}</label>
                        <span>Origin Port</span>
                      </li>
                    ) : null}
                    {item.destination_country ? (
                      <li className="col-md-4">
                        <label>{item.destination_country}</label>
                        <span>Destination Country</span>
                      </li>
                    ) : null}
                    {item.destination_port ? (
                      <li className="col-md-4">
                        <label>{item.destination_port}</label>
                        <span>Destination Port</span>
                      </li>
                    ) : null}
                    {item.container_type ? (
                      <li className="col-md-4">
                        <label>{item.container_type}</label>
                        <span>Container Type</span>
                      </li>
                    ) : null}
                    {item.container_no ? (
                      <li className="col-md-4">
                        <label>{item.container_no}</label>
                        <span>Container No</span>
                      </li>
                    ) : null}
                    {item.cargo_type ? (
                      <li className="col-md-4">
                        <label>{item.cargo_type}</label>
                        <span>Cargo Type</span>
                      </li>
                    ) : null}
                    {item.start_date ? (
                      <li className="col-md-4">
                        <label>{moment(item.start_date).format("YYYY-MM-DD")}</label>
                        <span>Start Date (yyyy-mm-dd)</span>
                      </li>
                    ) : null}
                    {item.end_date ? (
                      <li className="col-md-4">
                        <label>{moment(item.end_date).format("YYYY-MM-DD")}</label>
                        <span>End Date (yyyy-mm-dd)</span>
                      </li>
                    ) : null}
                    {item.load_volume ? (
                      <li className="col-md-4">
                        <label>{item.load_volume}</label>
                        <span>Load Volume (cubic meter)</span>
                      </li>
                    ) : null}
                    {item.load_weight ? (
                      <li className="col-md-4">
                        <label>{item.load_weight}</label>
                        <span>Load Weight (Kg)</span>
                      </li>
                    ) : null}
                    {item.vessel_type ? (
                      <li className="col-md-4">
                        <label>{item.vessel_type}</label>
                        <span>Vessel Type</span>
                      </li>
                    ) : null}
                    {item.commodity_type ? (
                      <li className="col-md-4">
                        <label>{item.commodity_type}</label>
                        <span>Commodity Type</span>
                      </li>
                    ) : null}
                    {item.flexibility_days ? (
                      <li className="col-md-4">
                        <label>{item.flexibility_days}</label>
                        <span>Flexibility Days</span>
                      </li>
                    ) : null}
                    {item.price ? (
                      <li className="col-md-4">
                        <label>{item.price}</label>
                        <span>Price</span>
                      </li>
                    ) : null}
                    {item.currency ? (
                      <li className="col-md-4">
                        <label>{item.currency}</label>
                        <span>Currency</span>
                      </li>
                    ) : null}
                    <li className="col-md-4">
                      {traderTypeIds.includes(userTypeId) ? (
                        <label>{item.offers_received && item.offers_received.length ? "Offer Received" : "Query Raised"}</label>
                      ) : (
                        <label>{item.offer_details_obj ? "Offer Sent" : "Query Raised"}</label>
                      )}
                      <span>Status</span>
                    </li>
                  </ul>
                </div>
                {traderTypeIds.includes(userTypeId) ? (
                  <div className="ntf_mode">
                    {
                      <>
                        <span className="btn btn-info btn-sm mr-2 ml-2"
                          onClick={() =>
                            item.offers_received && item.offers_received.length ?
                              (setShipmentData(item.offers_received), toggleNegPopup(true), setpopupType(2))
                              :
                              toastDisplay("No offers received yet", "info")
                          }
                        >
                          <i className={"fa fa-pencil-square-o"} aria-hidden="true"></i>
                        </span>
                      </>
                    }
                    {/* {item.offers_received && item.offers_received.length ? item.offers_received.map(val => (
                      <>
                        {val.agreed_by_shipper && val.agreed_by_trader ? (
                          <button type="button" className="btn btn-info btn-sm" onClick={
                            () => {
                              setShipmentData({ ...val.offer_details_obj, contactUserId: val.created_by }); toggleNegPopup(true); setpopupType(3);
                            }}
                            id="view_offer">{"View Offer & Contact Details"}</button>
                        ) : (
                          <button
                            disabled={item.status === 2}
                            type="button" className="btn btn-info btn-sm" onClick={
                              () => {
                                setShipmentData({ ...val.offer_details_obj, offer_id: val.id }); toggleNegPopup(true); setpopupType(2);
                              }}
                            id="update_offer">{"Update Offer"}</button>
                        )}
                      </>
                    )) : <h6>Query Raised</h6>} */}
                  </div>
                ) : null}

                {!traderTypeIds.includes(userTypeId) ? (
                  <div className="ntf_mode">
                    {
                      <>
                        <span className="btn btn-info btn-sm mr-2 ml-2"
                          onClick={() =>
                            item.offer_details_obj ?
                              (setShipmentData([item]), toggleNegPopup(true), setpopupType(2))
                              :
                              (setShipmentData([item]), toggleNegPopup(true), setpopupType(0))
                          }
                        >
                          <i className={"fa fa-pencil-square-o"} aria-hidden="true"></i>
                        </span>
                      </>
                    }

                    {/* {item.offer_details_obj ? (
                      <>
                        {item.agreed_by_shipper && item.agreed_by_trader ? (
                          <button type="button" className="btn btn-info btn-sm" onClick={
                            () => {
                              setShipmentData({ ...item.offer_details_obj, contactUserId: item.offer_details_obj.owner_id });
                              toggleNegPopup(true); setpopupType(3);
                            }}
                            id="view_offer">{"View Offer & Contact Details"}</button>
                        ) : (
                          <button type="button"
                            disabled={item.status === 2} className="btn btn-primary btn-sm float-left" onClick={
                              () => {
                                setShipmentData({ ...item.offer_details_obj, offer_id: item.id })
                                toggleNegPopup(true)
                                setpopupType(2)
                              }
                            }>Update Offer</button>

                        )}
                      </>
                    ) : (
                      <button disabled={item.status === 2}
                        type="button" className="btn btn-primary btn-sm float-left" onClick={
                          () => {
                            setShipmentData(item)
                            toggleNegPopup(true)
                            setpopupType(0)
                          }
                        }>Send Offer</button>
                    )} */}
                  </div>
                ) : null}
              </li>
              {/* {
                item.offers_received && item.offers_received.length && userTypeId === 14 ? (
                  <li className={"d-flex mb-2 green-bg"}>
                    <div className="ntf_content">
                      <ul className="p-0 d-flex">
                        {item.offers_received.map(val =>
                          <li className="col-md-4">
                            <label>{"Offer Details"}</label>
                            <span>{"Received On : " + moment(val.created_at).format("YYYY-MM-DD hh:mm:ss")}</span>
                            {val.updated_at ? (
                              <span>{"Updated On : " + moment(val.updated_at).format("YYYY-MM-DD hh:mm:ss")}</span>
                            ) : null}
                            <span>{"Status : " + (getOfferStatusLabel(val))}</span>
                          </li>)}
                      </ul>
                    </div>
                  </li>
                ) : null
              } */}
              {/* {item.offer_details_obj && userTypeId != 14 ? (
                <li className={"d-flex mb-2 green-bg"}>
                  <div className="ntf_content">
                    <ul className="p-0 d-flex">
                      <li className="col-md-4">
                        <span>{"Offer Sent On : " + moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</span>
                        {item.updated_at ? (
                          <span>{"Updated On : " + moment(item.updated_at).format("YYYY-MM-DD hh:mm:ss")}</span>
                        ) : null}
                        <span>{"Status : " + (getShipperOfferStatusLabel(item))}</span>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null
              } */}
            </>
          )
        }
        if (item.contract_id) {
          return (
            <>
              <li
                className={"d-flex mb-2"}
              >
                {/* <h6
                  className='bg-primary text-white position-absolute p-1'
                  style={{ position: 'absolute' }}
                >Contract</h6> */}
                <div className="ntf_time">
                  <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
                </div>
                <div className="ntf_content">
                  <ul className="p-0 d-flex">
                    {item.contract_name ? (
                      <li className="col-md-4">
                        <label>{item.contract_name}</label>
                        <span>Contract Name</span>
                      </li>
                    ) : null}
                    {/* {item.contract_id ? (
                      <li className="col-md-4">
                        <label>{item.contract_id}</label>
                        <span>Contract Id</span>
                      </li>
                    ) : null} */}
                    {item.quotation_party ? (
                      <li className="col-md-4">
                        <label>{item.quotation_party}</label>
                        <span>Quotation Received From</span>
                      </li>
                    ) : null}
                    {item.type ? (
                      <li className="col-md-4">
                        <label>{item.type}</label>
                        <span>Type</span>
                      </li>
                    ) : null}
                    {item.price ? (
                      <li className="col-md-4">
                        <label>{item.price}</label>
                        <span>Price ($)</span>
                      </li>
                    ) : null}
                    {item.bidPrice ? (
                      <li className="col-md-4">
                        <label>{item.bidPrice}</label>
                        <span>Biding Price</span>
                      </li>
                    ) : null}
                    {item.message ? (
                      <li className="col-md-4">
                        <label>{item.message}</label>
                        <span>Message</span>
                      </li>
                    ) : null}
                    {item.status != undefined ? (
                      <li className="col-md-4">
                        <label>{item.status ? "Accepted" : "Pending"}</label>
                        <span>Status</span>
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="ntf_mode">
                  {item.action}
                </div>
              </li>
            </>
          )
        }
      })
    )
  }
  // function createQuotationListBody2(data) {
  //   // console.log('data receved', data);
  //   return (
  //     data && data.map(item => {
  //       if (item.contract_id) {
  //         return (
  //           <>
  //             <li
  //               className={"d-flex mb-2"}
  //             >
  //               {/* <h6
  //                 className='bg-primary text-white position-absolute p-1'
  //                 style={{ position: 'absolute' }}
  //               >Contract</h6> */}
  //               <div className="ntf_time">
  //                 <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
  //               </div>
  //               <div className="ntf_content">
  //                 <ul className="p-0 d-flex">
  //                   {item.contract_name ? (
  //                     <li className="col-md-4">
  //                       <label>{item.contract_name}</label>
  //                       <span>Contract Name</span>
  //                     </li>
  //                   ) : null}
  //                   {item.contract_id ? (
  //                     <li className="col-md-4">
  //                       <label>{item.contract_id}</label>
  //                       <span>Contract Id</span>
  //                     </li>
  //                   ) : null}
  //                   {item.quotation_party ? (
  //                     <li className="col-md-4">
  //                       <label>{item.quotation_party}</label>
  //                       <span>Quotation Received From</span>
  //                     </li>
  //                   ) : null}
  //                   {item.type ? (
  //                     <li className="col-md-4">
  //                       <label>{item.type}</label>
  //                       <span>Type</span>
  //                     </li>
  //                   ) : null}
  //                   {item.price ? (
  //                     <li className="col-md-4">
  //                       <label>{item.price}</label>
  //                       <span>Price ($)</span>
  //                     </li>
  //                   ) : null}
  //                   {item.bidPrice ? (
  //                     <li className="col-md-4">
  //                       <label>{item.bidPrice}</label>
  //                       <span>Biding Price</span>
  //                     </li>
  //                   ) : null}
  //                   {item.message ? (
  //                     <li className="col-md-4">
  //                       <label>{item.message}</label>
  //                       <span>Message</span>
  //                     </li>
  //                   ) : null}
  //                   {item.status != undefined ? (
  //                     <li className="col-md-4">
  //                       <label>{item.status ? "Accepted" : "Pending"}</label>
  //                       <span>Status</span>
  //                     </li>
  //                   ) : null}
  //                 </ul>
  //               </div>
  //               <div className="ntf_mode">
  //                 {item.action}
  //               </div>
  //             </li>
  //           </>
  //         )
  //       }
  //     })
  //   )
  // }
  //---------------------------------------------------------------------------------------------------------------------


  const handleFilter = event => {
    event.persist()
    if (!event.target.value) {
      reset()
    }
    else {
      setcompleteButton(event.target.value / 1);
      filterStateButtons(event.target.value / 1)
    }
  }
  //---------------------------------------------------------------------------------------------------------------------
  // Body Console
  // console.log("data ----=>", data)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showNegPopup ? (
        <NegotiationPopup closePopup={() => { toggleNegPopup(false); setrefresh(refresh + 1) }} shipment_data={shipmentData} userId={userId}
          userTypeId={userTypeId} popupType={popupType}
        />
      ) : null}
      {/* <div className="container-fluid"> */}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {/* <div className="row"> */}
      {/* <Sidebar state={'my-quotations'} userTokenDetails={userTokenDetails} /> */}
      {/* <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div"> */}

      {/* <Header
              title={'My Shipment Quotations'}
              // total={'Total Quotations ' + contractCount.totalCount}
              userTokenDetails={userTokenDetails} /> */}
      <div className="d-md-flex mt-3 mb-3">
        <div className="filter-bg">
          {/* <ul>
                  <li className={"pilot-cont cursor-pointer " + (completeButton === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setcompleteButton(1); filterStateButtons(1) }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Pending
                  </li>
                  <li className={"complete-cont cursor-pointer " + (completeButton === 2 ? "shadow" : '')} id="completeButton" onClick={() => { setcompleteButton(2); filterStateButtons(2) }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Accepted
                  </li>
                </ul> */}

          <select className="form-control" name="filter"
            onChange={handleFilter} value={completeButton}
          >
            <option value="" selected>Filter by</option>
            <option value="1" >Pending</option>
            <option value="2" >Accepted</option>
            <option value="3" >Query Raised</option>
            <option value="4" >{traderTypeIds.includes(userTypeId) ? "Offer Received" : "Offer Sent"}</option>
          </select>
        </div>
        <div className="ml-md-auto relative">
          <ul className="range-slider">
            <li>
              <input name="search" placeholder="Search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} id="searchInput" onKeyDown={handleKeyPress} />
              <button type="button" onClick={() => {
                setFilterData({
                  ...filterData,
                  "search": searchKey
                })
              }}><i class="fas fa-search fa-lg"></i></button>
            </li>
            <li>
              <label>From</label>
              <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
            </li>
            <li>
              <label>To</label>
              <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

          </ul>
        </div>
      </div>

      <div className="notification_listing">
        {tableData && tableData.length ?
          tableData.map((item, index) => (
            <div className={"accordionItem" + (accorddata["accord_" + index] ? " open" : " closed")}>
              <>
                {!traderTypeIds.includes(userTypeId) ? (
                  <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_' + index)}>{
                    item.contract_id + " | " + userName + " | " + (item.quotation_party || item.company_name)
                  }</h2>
                ) : (
                  <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_' + index)}>{
                    item.contract_id + " | " + userName +
                    (item.offers_received && item.offers_received.length ? (" | " + item.offers_received.length + " Offers Received") : "")
                  }</h2>
                )}
              </>
              <div className="accordionItemContent">
                <div className="nt_data">
                  <ul className="p-0  Ul-list">
                    {createQuotationListBody([item])}
                    {/* {tableData && tableData.length ? "" :
                            <h1 className="accordionItemHeading">No Records Found</h1>} */}
                  </ul>
                </div>
              </div>
            </div>
          ))
          :
          <h5 className='text-center' style={{ "marginTop": "7rem" }}>No Records Found</h5>}
      </div>

      {/* <div className="notification_listing">
              <div className={"accordionItem" + (accorddata.accord_1 ? " open" : " closed")}>
                <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_1')}>Contract Shipment Quotations</h2>
                <div className="accordionItemContent">
                  <div className="nt_data">
                    <ul className="p-0  Ul-list">
                      {createQuotationListBody2(tableData)}
                      {tableData && tableData.length ? "" :
                        <h1 className="accordionItemHeading">No Records Found</h1>}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

      <div className='row justify-content-end pr-3' >
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={10}
          totalItemsCount={totalRows}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          activeLinkClass="bg-primary"
        />
      </div>
      {/* <div className="table-responsive">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                // expandableRows
                // expandableRowsComponent={<TableExpandedComponent />}
                expandOnRowClicked
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
                  </div> */}
      <hr />
      {/* <div className="float-left col-md-7 ">
                <div className="col-md-12 row">
                  <div className="col-md-3">
                    <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                      <i class="fas fa-file-csv"></i> Export CSV
                    </button>
                  </div>
                </div>
              </div> */}

      <div className={"modal" + (updateModal ? " show" : "")} id="updateModal">
        <div className="modal-dialog d-flex modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Quotation Details</h4>
              <button type="button" className="close" onClick={() => { setupdateModal(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">

              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="">
                {miniPreview()}
                <hr />
                <h3 className="text-primary text-center" >Quantity & Pricing</h3>
                <hr width="50%" />

                {/* <div className="row"> */}
                {/* <div className=""> */}
                <div className="row form-group">
                  <label className="col-md-6">Quantity to be Shipped {astrix}  {toolTip('quantity')}</label>
                  <div className="col-md-6">
                    <input type="number" name="quantity" value={data.quantity} className="form-control" disabled />
                  </div>
                  {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                </div>
                {/* </div> */}

                <div className="row form-group">
                  <label className="col-md-6">MOL Type  {astrix} {toolTip('quantityLoadUnit')}</label>
                  <div className="col-md-6">
                    <select className={"form-control" + (!errorData.quantityLoadUnit ? '' : ' border-danger')} name="quantityLoadUnit" value={data.quantityLoadUnit} onChange={handleChange}>
                      <option value="" selected>--Select MOL Type--</option>
                      <option value="MOLOO">MOLOO</option>
                      <option value="MOLCO">MOLCO</option>
                    </select>
                    {errorData.quantityLoadUnit && <p className="text-danger error-contract">{errorData.quantityLoadUnit}</p>}
                  </div>
                </div>

                <div className="row form-group">
                  <label className="col-md-6">Quantity vary index {astrix}  {toolTip('quantityIndexType')}</label>
                  <div className="col-md-3">
                    <select className={"form-control form-control-bottom" + (!errorData.quantityIndexType ? '' : ' border-danger')} name="quantityIndexType" value={data.quantityIndexType} onChange={handleChange}>
                      <option value="" selected>--Select Quantity vary index--</option>
                      <option value="Percentage">Percentage</option>
                      <option value="Min/Max">Min/Max</option>
                    </select>
                    {errorData.quantityIndexType && <p className="text-danger error-contract">{errorData.quantityIndexType}</p>}
                  </div>
                  {(data.quantityIndexType === "Percentage") && <div className="col-md-3">
                    <input type="number" name="quantityIndex" placeholder="Enter Percentage ±" value={data.quantityIndex} className={" form-control" + (!errorData.quantityIndex ? '' : ' border-danger')} onChange={handleChange} />
                    {errorData.quantityIndex && <p className="text-danger error-contract">{errorData.quantityIndex}</p>}
                  </div>}
                  {(data.quantityIndexType === "Min/Max") && <div className="col-md-3">
                    <input type="number" name="quantityIndex" placeholder="Enter Min/Max" value={data.quantityIndex} className={" form-control" + (!errorData.quantityIndex ? '' : ' border-danger')} onChange={handleChange} />
                    {errorData.quantityIndex && <p className="text-danger error-contract">{errorData.quantityIndex}</p>}
                  </div>}
                </div>

                {/* </div> */}

                <div className="row form-group">
                  <label className="col-md-6">Freight (pmt) {astrix}  {toolTip('shipPrice')}</label>
                  <div className="col-md-3">
                    <input type="number" step="0.01" name="shipPrice" placeholder="Enter Price" value={data.shipPrice} className={"form-control-bottom form-control" + (!errorData.shipPrice ? '' : ' border-danger')} onChange={handleChange} />
                    {errorData.shipPrice && <p className="text-danger error-contract">{errorData.shipPrice}</p>}
                  </div>
                  <div className="col-md-3">
                    <select className={" form-control" + (!errorData.shipCurrency ? '' : ' border-danger')} name="shipCurrency" value={data.shipCurrency} onChange={handleChange}>
                      <option value="" selected>--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {errorData.shipCurrency && <p className="text-danger error-contract">{errorData.shipCurrency}</p>}
                  </div>
                </div>

                <div className="row form-group">
                  <label className="col-md-6">Demurrage Rate {astrix}  {toolTip('demmurageRate')}</label>
                  <div className="col-md-3">
                    <input type="number" step="0.01" name="demmurageRate" placeholder="Enter Price" value={data.demmurageRate} className={"form-control-bottom form-control" + (!errorData.demmurageRate ? '' : ' border-danger')} onChange={handleChange} />
                    {errorData.demmurageRate && <p className="text-danger error-contract">{errorData.demmurageRate}</p>}
                  </div>
                  <div className="col-md-3">
                    <select className="form-control" name="demmurageCurrency" value={data.shipCurrency} disabled>
                      <option value="" selected>--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {errorData.shipCurrency && <p className="text-danger error-contract">{errorData.shipCurrency}</p>}
                  </div>
                </div>

                <hr />
                <h3 className="text-primary text-center" >Shipment Details</h3>
                <hr width="50%" />
                {/* <div className="row mt-2"> */}

                <div className="row form-group">
                  <label className="col-md-6 ">Shipment Type {astrix}  {toolTip('shipmentType')}</label>
                  <div className="col-md-6">
                    <select className={"form-control" + (!errorData.shipmentType ? '' : ' border-danger')} name="shipmentType" value={data.shipmentType} onChange={(event) => { handleChange(event); handleShipmentType(event) }}>
                      <option value="" selected>--Select Shipment Type--</option>
                      <option value="bulk">Bulk Shipment</option>
                      <option value="container">Container Shipment</option>
                    </select>
                    {errorData.shipmentType && <p className="text-danger error-contract">{errorData.shipmentType}</p>}
                  </div>
                </div>

                {(data.shipmentType === "container") && <>
                  <div className="col-md-12">
                    <div className="row form-group">
                      <div className="col-md-6">
                        <label >Container Type {toolTip('containerType')}</label>
                        <select className={" form-control" + (!errorData.containerType ? '' : ' border-danger')} name="containerType" value={data.containerType} onChange={handleChange}>
                          <option value="" selected>--Select Container Type--</option>
                          {containerTypeData.map((contType) => {
                            return (<option value={contType.id} >{contType.container_type_pretty}</option>)
                          })}
                        </select>
                        {errorData.containerType && <p className="text-danger error-contract">{errorData.containerType}</p>}
                      </div>
                      <div className="col-md-6">
                        <label >No. Of Containers{toolTip('containerCount')}</label>
                        <input type="number" name="containerCount" value={data.containerCount} className="form-control" onChange={handleChange} />
                        {errorData.containerCount && <p className="text-danger error-contract">{errorData.containerCount}</p>}
                      </div>
                    </div>
                  </div>
                </>}

                <div className="row form-group">
                  <label className="col-md-6">Charter Type  {astrix} {toolTip('charterType')}</label>
                  <div className="row col-md-6 ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="charterType" id="charterType1" value="1" onChange={handleChange} checked={data.charterType === '1' ? true : false} />
                      <label className="form-check-label" for="charterType1">Voyage Charter</label>
                    </div>
                    {/* <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="charterType" id="charterType2" value="2" onChange={handleChange} checked={data.charterType === '2' ? true : false} />
                            <label className="form-check-label" for="charterType2">Time Charter</label>
                          </div> */}
                    {errorData.charterType && <p className="text-danger error-contract">{errorData.charterType}</p>}
                  </div>
                </div>
                {/* {(data.charterType / 1 === 2) && <>
                        <div className="col-md-12">
                          <div className="row form-group">
                            <div className="col-md-6">
                              <label >Start Date {astrix}  {toolTip('charterStart')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="charterStart" value={data.charterStart} className="form-control" onChange={handleChange} />
                              {errorData.charterStart && <p className="text-danger error-contract">{errorData.charterStart}</p>}
                            </div>
                            <div className="col-md-6">
                              <label >End Date  {astrix} {toolTip('charterEnd')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="charterEnd" value={data.charterEnd} className="form-control" onChange={handleChange} />
                              {errorData.charterEnd && <p className="text-danger error-contract">{errorData.charterEnd}</p>}
                            </div>
                          </div>
                        </div>
                      </>} */}
                <div className="row">
                  <label className="col-md-12">Loading Type {astrix}  {toolTip('loadingType')}</label>
                  <div className="row col-md-6 mt-2">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="berthLoading" id="berthLoading" onChange={handleChecked} checked={data.berthLoading} />
                        <label className="form-check-label" for="berthLoading">Berth Loading</label>
                      </div>
                    </div>
                    <div className="row col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="berthLoadingNumber" id="berthLoadingNumber1" value="1" onChange={handleChange} checked={data.berthLoadingNumber / 1 === 1 ? true : false} disabled={!data.berthLoading ? true : false} />
                        <label className="form-check-label" for="berthLoadingNumber1">1</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="berthLoadingNumber" id="berthLoadingNumber2" value="2" onChange={handleChange} checked={data.berthLoadingNumber / 1 === 2 ? true : false} disabled={!data.berthLoading ? true : false} />
                        <label className="form-check-label" for="berthLoadingNumber2">2</label>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-6 mt-2">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="anchorageLoading" id="anchorageLoading" onChange={handleChecked} checked={data.anchorageLoading} />
                        <label className="form-check-label" for="anchorageLoading">Anchorage Loading</label>
                      </div>
                    </div>
                    <div className="row col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="anchorageLoadingNumber" id="anchorageLoadingNumber1" value="1" onChange={handleChange} checked={data.anchorageLoadingNumber / 1 === 1 ? true : false} disabled={!data.anchorageLoading ? true : false} />
                        <label className="form-check-label" for="anchorageLoadingNumber1">1</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="anchorageLoadingNumber" id="anchorageLoadingNumber2" value="2" onChange={handleChange} checked={data.anchorageLoadingNumber / 1 === 2 ? true : false} disabled={!data.anchorageLoading ? true : false} />
                        <label className="form-check-label" for="anchorageLoadingNumber2">2</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-12">
                      {errorData.loadingType && <p className="text-danger error-contract">{errorData.loadingType}</p>}
                    </div>
                    <div className="col-md-6">
                      {errorData.berthLoadingNumber && <p className="text-danger error-contract">{errorData.berthLoadingNumber}</p>}
                    </div>
                    <div className="col-md-6">
                      {errorData.anchorageLoadingNumber && <p className="text-danger error-contract">{errorData.anchorageLoadingNumber}</p>}
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="row form-group">
                      <label className="col-md-12">Loading Rate (PDPR) {astrix}  {toolTip('loadingRate')}</label>
                      <div className="col-md-12">
                        <input type="number" name="loadingRate" placeholder="Enter Loading Rate" value={data.loadingRate} className={" form-control" + (!errorData.loadingRate ? '' : ' border-danger')} onChange={handleChange} />
                        {errorData.loadingRate && <p className="text-danger error-contract">{errorData.loadingRate}</p>}
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row">
                  <label className="col-md-12">Unloading Type  {astrix} {toolTip('unloadingType')}</label>
                  <div className="row col-md-6 mt-2">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="berthUnloading" id="berthUnloading" onChange={handleChecked} checked={data.berthUnloading} />
                        <label className="form-check-label" for="berthUnloading">Berth Unloading</label>
                      </div>
                    </div>
                    <div className="row col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="berthUnloadingNumber" id="berthUnloadingNumber1" value="1" onChange={handleChange} checked={data.berthUnloadingNumber / 1 === 1 ? true : false} disabled={!data.berthUnloading ? true : false} />
                        <label className="form-check-label" for="berthUnloadingNumber1">1</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="berthUnloadingNumber" id="berthUnloadingNumber2" value="2" onChange={handleChange} checked={data.berthUnloadingNumber / 1 === 2 ? true : false} disabled={!data.berthUnloading ? true : false} />
                        <label className="form-check-label" for="berthUnloadingNumber2">2</label>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-6 mt-2">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="anchorageUnloading" id="anchorageUnloading" onChange={handleChecked} checked={data.anchorageUnloading} />
                        <label className="form-check-label" for="anchorageUnloading">Anchorage Unloading</label>
                      </div>
                    </div>
                    <div className="row col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="anchorageUnloadingNumber" id="anchorageUnloadingNumber1" value="1" onChange={handleChange} checked={data.anchorageUnloadingNumber / 1 === 1 ? true : false} disabled={!data.anchorageUnloading ? true : false} />
                        <label className="form-check-label" for="anchorageUnloadingNumber1">1</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="anchorageUnloadingNumber" id="anchorageUnloadingNumber2" value="2" onChange={handleChange} checked={data.anchorageUnloadingNumber / 1 === 2 ? true : false} disabled={!data.anchorageUnloading ? true : false} />
                        <label className="form-check-label" for="anchorageUnloadingNumber2">2</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-12">
                      {errorData.unloadingType && <p className="text-danger error-contract">{errorData.unloadingType}</p>}
                    </div>
                    <div className="col-md-6">
                      {errorData.berthUnloadingNumber && <p className="text-danger error-contract">{errorData.berthUnloadingNumber}</p>}
                    </div>
                    <div className="col-md-6">
                      {errorData.anchorageUnloadingNumber && <p className="text-danger error-contract">{errorData.anchorageUnloadingNumber}</p>}
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="row form-group">
                      <label className="col-md-12">Unloading Rate  {astrix} (PDPR) {toolTip('unloadingRate')}</label>
                      <div className="col-md-12">
                        <input type="number" name="unloadingRate" placeholder="Enter Unloading Rate" value={data.unloadingRate} className={" form-control" + (!errorData.unloadingRate ? '' : ' border-danger')} onChange={handleChange} />
                        {errorData.unloadingRate && <p className="text-danger error-contract">{errorData.unloadingRate}</p>}
                      </div>
                    </div>
                  </div>

                </div>
                {/* </div> */}
                <hr />
                <h3 className="text-primary text-center" >Message</h3>
                <hr width="50%" />
                <div className="row d-flex mt-3">
                  <div className="d-flex col-md-12">
                    <label className="col-md-6 pl-0">Message {toolTip('quotMessage')}</label>
                    <div className="col-md-6">
                      <textarea type="number" name="quotMessage" value={data.quotMessage} placeholder="Enter Message" className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer primary">
              <div className="col-md-12">
                <div className="col-md-12">
                  {data.userAction === "update" ? "Update: Send Changes back to the Ship-owner/Broker for further negotiation" :
                    data.userAction === "accept" ? "Accept: Accept Quoat Given by Ship-owner/Broker Current edited changes will not save" :
                      data.userAction === "reject" ? "Reject: Reject Quoat Given by Ship-owner/Broker" : ""}
                </div>
                {(aclMap.mquotation_access && aclMap.mquotation_access.mquot_edit && aclMap.mquotation_access.mquot_edit.isExist) ?
                  <div className="col-md-12 mt-2 row">
                    <div className="col-md-6">
                      <select className="form-control" name="userAction" value={data.userAction} onChange={handleChange}>
                        <option value="" selected>--Choose Action--</option>
                        <option value="update">Update </option>
                        <option value="accept">Accept</option>
                        <option value="reject">Reject</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <button type="button" className="btn btn-secondary ml-2 btn-sm" onClick={() => { setupdateModal(false); seterrorData({}) }}>Close</button>
                      {quotationdata.status === 0 ? <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => prehandleSubmit()}>Save changes</button> :
                        quotationdata.status === 1 ? <button type="button" className="btn btn-success btn-sm ml-2" onClick="">Quotation Accepted</button> :
                          quotationdata.status === 2 ? <button type="button" className="btn btn-danger btn-sm ml-2" onClick="">Quoatation Rejected</button> : ""}
                    </div>
                  </div> :
                  <button type="button" className="btn btn-secondary ml-2 btn-sm" onClick={() => setupdateModal(false)}>Close</button>
                }
                <span className="chat_button" onClick={() => setChat(chat ? false : true)}><i className="fa fa-comments-o"></i></span>
              </div>
            </div>

          </div>
          <div className={"messaging modal-content " + (chat ? " active" : "")} id="messaging" >
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Chat</h4>

            </div>
            <div className="inbox_msg">
              <div className="mesgs">
                <div className="msg_history">
                  {gettrailList()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddQuotation userTokenDetails={userTokenDetails} />
      {/* </main> */}
      {/* </div> */}
      {/* </div> */}
      {/* footer start */}
      {/* <Footer /> */}
      {/* footer end */}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
// const mapStateToProps = state => {
//   return {
//     navToggleState: state.navToggleState
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//   }
// }
//---------------------------------------------------------------------------------------------------------------------

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Shipment)

export default Shipment
