import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import HeaderV2 from "../../../partial/headerV2";
import SideBarV2 from "../../../partial/sideBarV2";
import toastDisplay from "../../../../utils/toastNotification";
import { astrix, ClearCache, convertImageToPdf, decryptData, GetCache, getDocDetails, most_used_currencies, SetCache, toolTip } from "../../../../utils/myFunctions";
import call from '../../../../service';
import { FileInput } from "../../../../utils/FileInput";
import config from "../../../../config.json"
import SignDocument from "../../components/SignDocument";
import FinanceInvoiceModal from "../../contract/components/financeinvoiceModal";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from "../../../../utils/newInput";
import { ToastContainer } from "react-toastify";
import swal from 'sweetalert';
import { useHistory } from "react-router";

const addBuyerTabs = [
  { name: "Application Details" },
  // { name: "Documents" },
  // { name: "Review" },
]
const reviewForm = [
  { "name": "Applicant Name", val: "applicantName", unit: "nameTitle" },
  { "name": "Request amount", val: "reqAmount", unit: "currency" },
  { "name": "Invoice amount:", val: "contractAmount", unit: "currency" },
  { "name": "Finance credit days", val: "creditDays" },
  { "name": "Invoice No.", val: "invoiceRefrenceNo" },
  { "name": "Bank", val: "beneficiaryBankTitle" },
  { "name": "Invoice issue date", val: "invoiceIssueDate" },
  { "name": "Lender", val: "lenderNameTitle" },
  { "name": "Invoice due date", val: "invoiceDueDate" },
  { "name": "Buyer", val: "buyerName" },
  { "name": "Purchase order no.", val: "poRefrenceNo" }
]

const reviewForm3 = [
  { "name": "LC type", val: "lcType" },
  { "name": "LC number", val: "lcNo" },
  { "name": "LC tenor", val: "lcTenor" },
  { "name": "Shipment from country", val: "shipmentFromCountry" },
  { "name": "Shipment to country", val: "shipmentToCountry" },
  { "name": "Port of loading", val: "portOfLoading" },
  { "name": "Port of discharge", val: "portOfDischarge" },
  { "name": "Commodity", val: "commodity" },
  { "name": "Expected date of shipment", val: "expectedDateOfShipment" },
  { "name": "Beneficiary bank name", val: "beneficiaryBankName", notAmmendable: true },
  { "name": "Beneficiary bank account no.", val: "beneficiaryAccountNo", notAmmendable: true },
  { "name": "Beneficiary bank IFSC code", val: "beneficiaryIfscCode", notAmmendable: true },
  { "name": "Beneficiary bank SWIFT code", val: "beneficiarySwiftCode", notAmmendable: true },
  { "name": "LC issuing bank name", val: "lcIssuingBankName", notAmmendable: true },
  { "name": "LC issuing bank address", val: "lcIssuingBankAddress" },
  { "name": "LC issuing bank SWIFT code", val: "lcIssuingBankSwiftCode" },
  { "name": "Country of origin", val: "countryOfOrigin" },
  // { "name": "LC Purpose", val: "lcPurpose" },
  { "name": "Confirming bank name", val: "confirmingBankName" },
  { "name": "Confirming bank address", val: "confirmingBankAddress" },
  { "name": "Confirming bank SWIFT code", val: "confirmingBankSwiftCode" }
]


const AmendmentDetails = ({ userTokenDetails, navToggleState, params }) => {

  const queryParams = new URLSearchParams(window.location.search)
  const amendmentId = params?.amendmentId || queryParams.get("amendmentId")
  const quoteId = params?.quoteId || queryParams.get("quoteId")

  let isAdmin = params?.isAdmin

  let history = useHistory()

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)

  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [signdoc, setSigndoc] = useState(false);
  const [limitinvoice, setLimitinvoice] = useState(false);
  const [amendReq, setAmendReq] = useState({})
  const [showComment, setShowComment] = useState(null)

  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)

  const [lcCodes, setLCCodes] = useState({})
  const [currencyData, setcurrencyData] = useState(most_used_currencies);

  const userTypeId = userTokenDetails?.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails?.email ? userTokenDetails.email : null
  const userId = userTokenDetails?.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails?.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails?.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails?.parent_data ? userTokenDetails.parent_data : null;
  const userIdentifier = userTokenDetails?.identifier ? userTokenDetails.identifier : null

  async function getFinanceDetails() {
    setshowLoader(true)
    call('POST', 'getLCQuoteApplicationById', { quoteId }).then(async (result) => {
      console.log('running getLCQuoteApplicationById api-->', result);
      let draftLCDocument = await getDocDetails(result[0].draftLC)
      setData({
        ...result[0], buyerCurrency: result[0].buyerCurrency ? result[0].buyerCurrency.split(":")[1] : null, draftLCDocument
      })
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getLCQuoteApplicationById', e);
      setshowLoader(false)
    });

  }


  useEffect(() => {
    // Get master data start
    call('POST', 'getLCFieldMaster', {
    }).then((result) => {
      // console.log('running getLCFieldMaster api -->', result);
      let lcFieldObj = {}
      result.mandatoryFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      result.optionalFields.forEach((item) => {
        lcFieldObj[item.tag] = item.fieldName
      })
      setLCCodes(lcFieldObj)
    }).catch((e) => {
      // console.log('error in getLCFieldMaster', e);
    })
    // Get master data end
    getAmendmentDetailsById()
    getFinanceDetails()
  }, [])

  async function getAmendmentDetailsById() {
    // if (amendmentId) {
    let amendmentData = {}
    let amendmentDataRes = await call('POST', 'getAmendmentsByApplicationId', { quoteId, amendmentId })
    if (amendmentDataRes && amendmentDataRes.length) {
      amendmentData["fieldLabels"] = Object.keys(amendmentDataRes[0].details) || []
      amendmentData["fieldComments"] = amendmentDataRes[0].details || {}
      amendmentData["beforeDetails"] = amendmentDataRes[0].beforeDetails || {}
      amendmentData["changedDetails"] = amendmentDataRes[0].changedDetails || {}
      setAmendReq(amendmentData)
    }
    // }
  }

  const closeSuccess = () => {
    setLimitinvoice(false);
  }

  console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", amendReq);




  return (
    <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          {!isAdmin ?
            <SideBarV2 state="finance-btn" userTokenDetails={userTokenDetails} /> : null}
          <main role="main" className={`ml-sm-auto col-lg-${isAdmin ? '12' : "10"} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {!isAdmin ? <>
              <HeaderV2
                title={"Apply for Finance"}
                userTokenDetails={userTokenDetails} />
              <a className="cursor"><img
                onClick={() => { history.go(-1) }}
                className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a></> : null}
            <div>
              {!isAdmin ? (
                <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                  {addBuyerTabs.map((item, index) => {
                    return (
                      <li>
                        <a className={"nav-link formTab pl-4 pr-4 cursor-pointer " + (tab === index ? " formActiveTab show" : "")}
                        // onClick={() => { handleValidation(index) }}
                        >
                          {item.name}</a>
                      </li>
                    )
                  })}
                </ul>
              ) : null}
              <div className={`card mt-${isAdmin ? '4' : '1'}`}>
                {tab === 0 ?
                  <>
                    {/* <img className="mb-3 back-btn cursor" src={"assets/images/back.png"} alt="Back" height={15} width={15} onClick={() => setLCMoreDetails(false)} /> */}
                    <div className='px-5 py-4 pt-5 '>
                      <div className="row">
                        <div className='w-100'>
                          <label className='font-wt-500 font-size-16'><u>LC Details</u></label>
                          <div className='row'>
                            {reviewForm3.map((item) => {
                              let beforeDetails = amendReq["beforeDetails"] || {}
                              let changedDetails = amendReq["changedDetails"] || {}
                              let fieldComments = amendReq["fieldComments"] || {}
                              // let isFieldChanged = beforeDetails[item.val] === changedDetails[item.val] ? true : false
                              // console.log("11111111111111111111111", isFieldChanged);
                              return (
                                <div className='col-md-4'>
                                  <NewInput type={"text"} isDisabled={true} label={item.name}
                                    value={data[item.val] ? (item.unit ? `${data[item.unit]} ` : "") + (data[item.val]) : "NA"}
                                  />
                                  {fieldComments[item.val] ? (
                                    <div
                                      onClick={() => setShowComment(item.val)}
                                      className="cursor ammendCommentIcon2"
                                    >
                                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                                    </div>
                                  ) : null}
                                  {showComment === item.val ? (
                                    <div className="ammendPopup2">
                                      <button
                                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => setShowComment(null)}></button>
                                      <div className="p-3">
                                        <div className="mr-5 pr-2">
                                          <NewInput isDisabled={true}
                                            value={beforeDetails[item.val] ? beforeDetails[item.val] : ""}
                                          />
                                        </div>
                                        <label className="font-wt-500 font-size-12 pl-1">
                                          {fieldComments[item.val]}
                                        </label>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className='w-100 mt-5'>
                          <label className='font-wt-500 font-size-16 mb-4'><u>More LC Details</u></label>
                          <div className='row'>
                            {lcCodes && Object.keys(lcCodes).map((item) => {
                              let beforeDetails = amendReq["beforeDetails"] || {}
                              let changedDetails = amendReq["changedDetails"] || {}
                              let fieldComments = amendReq["fieldComments"] || {}
                              // let isFieldChanged = beforeDetails[item] === changedDetails[item] ? true : false
                              // console.log("11111111111111111111111", isFieldChanged);
                              return (
                                <div className='col-md-4'>
                                  <NewTextArea isDisabled={true} label={item + "-" + lcCodes[item]}
                                    value={data["ocrFields"] && data["ocrFields"][item] || " "}
                                  />
                                  {fieldComments[item] ? (
                                    <div
                                      onClick={() => setShowComment(item)}
                                      className="cursor ammendCommentIcon2"
                                    >
                                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                                    </div>
                                  ) : null}
                                  {showComment === item ? (
                                    <div className="ammendPopup2">
                                      <button
                                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => setShowComment(null)}></button>
                                      <div className="p-3">
                                        <div className="mr-5 pr-2">
                                          <NewTextArea rows={4} isDisabled={true}
                                            value={beforeDetails[item] ? beforeDetails[item] : ""}
                                          />
                                        </div>
                                        <label className="font-wt-500 font-size-12 pl-1">
                                          {fieldComments[item]}
                                        </label>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            })}
                          </div></div>
                      </div>
                    </div>
                  </> : null
                }
              </div>
            </div>
          </main>
        </div>
      </div>
      {signdoc && <SignDocument signdoc={signdoc} setSigndoc={setSigndoc} docToSign={data.docToSign}
        setUpdatedDoc={(updatedDoc) => setData({ ...data, [data.docSignName]: updatedDoc })} />}
      {limitinvoice && <FinanceInvoiceModal limitinvoice={limitinvoice} setLimitinvoice={setLimitinvoice} closeSuccess={closeSuccess} >
        <img src={"/assets/images/upload_done.png"} alt="" className="mb-3" />
        <p>Your application for “Invoice discounting” for “Rosti Suzhou Integrated” has been submitted successfully.</p>
      </FinanceInvoiceModal>}
    </>
  );
}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AmendmentDetails)