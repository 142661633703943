import React, { useEffect, useState } from 'react'
import { NewTable } from '../../../utils/newTable';
import Filter from '../../InvoiceDiscounting/components/Filter';
import Pagination from '../../InvoiceDiscounting/contract/components/pagination';
import { ExportExcel, addDaysSkipSundays, getContactObject, isUserInactive } from '../../../utils/myFunctions';
import call from '../../../service';
import moment from 'moment';
import toastDisplay from '../../../utils/toastNotification';
import CustomAreaChart from '../../Dashboard/components/CustomAreaChart';
import CustomBarChart from '../../Dashboard/components/CustomBarChart';
import CustomLineChart from '../../CRM/CustomLineChart';

const bardataConfig = [
  { dataKey: "int_count", fill: "#2ECC71", display: 'Interested' },
  { dataKey: "others_count", fill: "#AED8FF", display: 'Others' },
  { dataKey: "not_interested", fill: "#FF7B6D", display: 'Not Interested' },
]

const taskAssignConfig = [
  { dataKey: "total_exporters", fill: "#76EEA9", display: 'Exporters' },
  { dataKey: "total_importers", fill: "#FFB801", display: 'Importers' },
  { dataKey: "total_audiotrs", fill: "#F26191", display: 'Auditors' },
]

const inboundConfig = [
  { dataKey: "value", fill: "#76EEA9", display: "Users" }
]
const label = {
  "int_count": "Interested",
  "others_count": "Others",
  "not_interested": "Not Interested"
}

const taskAssignLabel = {
  "total_exporters": "Exporters",
  "total_importers": "Importers",
  "total_audiotrs": "Auditors"
}

const taskColorMapping = {
  'Hot': 'text-color1',
  'Cold': 'text-color1',
  'Cold': 'text-color1',
  'Onboarded': 'color-2ECC71',
  'Not Reachable': 'text-F1C40F',
  'Not Interested': 'text-color-E74C3C',
  'Lost': 'text-color-value',
  'Call': 'color-2ECC71'

}

const taskAssignedConfig = [
  { dataKey: "exporter_count", fill: "#2ECC71", display: 'Others' },
  { dataKey: "financer_count", fill: "#5CB8D3", display: 'Interested' },
  { dataKey: "cp_count", fill: "#FFB801", display: 'Not Interested' }
]

const stackedbarconfig = [
  { dataKey: "task_complete", fill: "#5CB8D3", display: 'Task Complete' },
  { dataKey: "task_incomplete", fill: "#FF7B6D", display: 'Task Incomplete' }
]

const LogsArr = [
  { name: 'Over Due', val: 'total_exporters_overdue', color: 'colorFE4141' },
  { name: "Hot", val: 'Hot (30 days or less)', color: 'text-color1' },
  { name: "Warm", val: 'Warm (30-60 days)', color: 'text-color1' },
  { name: "Cold", val: 'Cold (60 days or more)', color: 'text-color1' },
  { name: "Not Interested", val: 'Not Interested', color: 'textFFB801' }
]

const SubAdminProfileDetailsCRM = ({ subAdminId, dateRange }) => {
  let todayDateObj = moment()
  let lastMonthDateObj = moment().subtract("1", "months")
  const [filterData, setFilterData] = useState([])
  const [refresh, setRefresh] = useState([])
  const [filter, setFilter] = useState({ resultPerPage: 10, search: '' })
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [filteredSearch, setFilteredSearch] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [salesPerson, setSalesPerson] = useState([])
  const [showDetails, setShowDetails] = useState({
    isVisible: false,
    data: {}
  })
  const [graphConfiguration, setGraphConfiguration] = useState({
    taskUpdateFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    taskUpdateTo: todayDateObj.clone().format("YYYY-MM-DD"),
    leadsFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    leadsTo: todayDateObj.clone().format("YYYY-MM-DD"),
    closureFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    closureTo: todayDateObj.clone().format("YYYY-MM-DD"),
    performanceFrom: lastMonthDateObj.clone().format("YYYY-MM-DD"),
    performanceTo: todayDateObj.clone().format("YYYY-MM-DD"),
    isTaskUpdateGraph: true,
    isLeadsGraph: true,
    isClosureGraph: true,
    isPerformanceGraph: true
  })
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState({
    data: null,
    isVisible: false
  });
  const [isOpenDidntRec, setisOpenDidntRec] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [isOpenCallback, setisOpenCallback] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [isOpenNotInt, setisOpenNotInt] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [isOpenLost, setisOpenLost] = useState({
    isVisible: false,
    selectedIndex: 0
  })
  const [selectedExpIndex, setSelectedExpIndex] = useState(null)
  const [data, setdata] = useState({})
  const [errors, setErrors] = useState({})
  const [overalldata, setoveralldata] = useState([])
  const [dbData, setdbData] = useState([])
  const [includedStatus, setIncludedStatus] = useState([0, 1, 2, 3, 4])
  const [LogStatus, setLogStatus] = useState(LogsArr)
  const [taskAssignedGraph, setTaskAssignedGraph] = useState([])
  const [taskUpdateGraph, settaskUpdateGraph] = useState([])
  const [inBoundTasksAssigned, setInboundTaskAssigned] = useState([])
  const [performancedata, setperformancedata] = useState([])
  const [closuredata, setclosuredata] = useState([])
  const [statsdata, setstatsdata] = useState([])
  useEffect(() => {
    setShowLoader(true)
    call("POST", 'getSubAdminUser', { onlyUserId: subAdminId }).then(res => {
      setShowLoader(false)
      setSalesPerson(res.data)
    }).catch(err => setShowLoader(false))
  }, [])

  const getTasks = (isDownload) => {
    setShowLoader(true)
    let objectAPI = {
      currentPage: page,
      ...filter,
      resultPerPage: filter.resultPerPage,
      included_status: includedStatus,
    }
    if (isDownload) {
      delete objectAPI["currentPage"]
      delete objectAPI["resultPerPage"]
    }
    if (subAdminId) {
      objectAPI["onlyShowForUserId"] = subAdminId
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(i[element["labelName"]])
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        } else if (element.type === 'singleDate') {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    objectAPI['taskType'] = 'Call List'
    if (data.subadmins) {
      delete objectAPI["onlyShowForUserId"]
      objectAPI["leadAssignedTo"] = data.subadmins
    }
    call('POST', 'getOverallCRMTasks', objectAPI)
      .then((result) => {
        setdbData(formatDataForTable(result.message))
        setCount(result.total_records)
        setoveralldata(result.message)
        setShowLoader(false)
      }).catch(e => {
        setShowLoader(false)
      })
  }
  useEffect(() => {
    getTasks()
  }, [page, refresh, salesPerson, filterData])
  function formatDataForTable(data) {
    let tableData = []
    let row = []
    console.log('SalesPersoon', salesPerson)
    data.forEach((item, index) => {

      let mappedData = getContactObject(item.EXTRA_DETAILS ? item.EXTRA_DETAILS : [])

      row[0] = item.task_logs ? item.task_logs?.[0]?.EVENT_STATUS?.split("(")[0] || 'NA' : 'NA'
      row[1] = item.EXPORTER_NAME
      row[2] = mappedData ? mappedData['Contact Person'] ? mappedData['Contact Person'] : 'NA' : 'NA'
      row[3] = mappedData ? mappedData['Designation'] ? mappedData['Designation'] : 'NA' : 'NA'
      row[4] = <div onClick={() => { }} className='cursor'>
        {mappedData ? mappedData['Contact Number'] ? mappedData['Contact Number'] : 'NA' : 'NA'}
      </div>
      row[5] = item.task_logs ? item.task_logs.length : 0
      row[6] = <div class="dropdown w-100" >
        <label class="font-wt-600 font-size-13 cursor" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          {item.TASK_ASSIGNED_TO[0].contact_person || '-'}
        </label>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {salesPerson.map(element => {
            return <li className="dropdown-item cursor" >{element.contact_person}</li>
          })}
        </ul>
      </div>
      row[7] = item.LAST_NOTE ? item.LAST_NOTE : 'NA'

      tableData.push(row)
      row = []
    })
    return tableData
  }
  const getTaskAssignedGraph = () => {
    setShowLoader(true)
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds: [subAdminId],

    }
    call('POST', 'getTaskAssignedGraph', reqObj).then(result => {
      setTaskAssignedGraph(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  const getTaskUpdateGraph = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds: [subAdminId],
    }
    setShowLoader(true)
    call('POST', 'getTaskUpdateGraph', reqObj).then(result => {
      settaskUpdateGraph(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  const getCRMPerformanceSubAdmin = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds: [subAdminId],
    }
    setShowLoader(true)
    call('POST', 'getCRMPerformanceSubAdmin', reqObj).then(result => {
      setShowLoader(false)
      setperformancedata(result)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  const getClosureSubadmin = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds: [subAdminId],
    }
    setShowLoader(true)
    call('POST', 'getClosureSubadmin', reqObj).then(result => {
      setShowLoader(false)
      setclosuredata(result)
    }).catch(e => {
      setShowLoader(false)
    })
  }

  const getCRMSubAdminStats = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds: [subAdminId],
    }
    setShowLoader(true)
    call('POST', 'getCRMSubAdminStats', reqObj).then(result => {
      setShowLoader(false)
      let logRes = []
      let total_count = 0
      for (let index = 0; index < LogStatus.length; index++) {
        const element = LogStatus[index];
        if (element.val === 'total_exporters_overdue') {
          element["count"] = result?.assignedResponse?.[0]?.total_exporters_overdue || 0
          total_count += result?.assignedResponse?.[0]?.total_exporters_overdue || 0

        } else {
          const matcheddata = result?.eventdata?.find(item => item._id === element.val)
          element["count"] = matcheddata ? matcheddata.total_records : 0
          total_count += matcheddata ? matcheddata.total_records : 0
        }
        logRes.push(element)
      }
      setLogStatus(logRes)
      let onboardedCnt = result?.eventdata?.find(item => item._id == 'User Onboarded')?.total_records

      let leadsCount = result?.eventdata?.find(item => item._id == 'Lead Created')?.total_records
      let total_exporters = result?.assignedResponse?.[0]?.total_exporters || 0

      setstatsdata({
        onboarded: onboardedCnt,
        assinged_exporters: total_exporters,
        leadCreated: leadsCount,
        task_update_total: total_count,

      })
    }).catch(e => {
      setShowLoader(false)
    })
  }
  useEffect(() => {
    getTaskUpdateGraph()
    getInboundTasks()
    getTaskAssignedGraph()
    getCRMPerformanceSubAdmin()
    getClosureSubadmin()
    getCRMSubAdminStats()
  }, [dateRange, subAdminId])

  const getInboundTasks = () => {
    let reqObj = {
      from: dateRange.from,
      to: dateRange.to,
      userIds: [subAdminId],
    }
    setShowLoader(true)
    call('POST', 'getInboundTasks', reqObj).then(result => {
      setInboundTaskAssigned(result)
      setShowLoader(false)
    }).catch(e => {
      setShowLoader(false)
    })
  }
  const getXAxisDateFormat = (value) => {
    let countForMonths = moment(dateRange.to).diff(dateRange.from, 'month')
    let dateFormat = ''
    if (countForMonths > 3) {
      dateFormat = moment(value).format('MMM YYYY')
    } else if (countForMonths <= 1) {
      dateFormat = moment(value).format('DD MMM YYYY')
    } else {
      dateFormat = value
    }
    return dateFormat
  }

  return (
    <div className='mt-5'>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <div className='row'>

        <div className='w-15'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <div className='row px-0 w-100'>
              <div className='w-50 cursor'>
                <label className={`value font-wt-600  w-100 cursor`}>
                  {statsdata.onboarded || 0}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Onboarded"}</label>
              </div>
            </div>
          </div>
        </div>

        <div className='w-25'>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <div className='row px-0 w-100'>
              <div className='w-50 cursor'>
                <label className={`value font-wt-600   w-100 cursor`}>
                  {statsdata.assinged_exporters}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Task Assigned"}</label>
              </div>

              <div className='w-50 cursor'>
                <label className={`value font-wt-600  w-100 cursor`}>
                  {statsdata.leadCreated}
                </label>
                <label className={'font-size-14 font-wt-600 text-color-value cursor'}>{"Leads Created"}</label>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "44%" }}>
          <div className='card h-75 dashboard-card shadow-sm align-items-center justify-content-center'>
            <label className='w-100 font-size-14 text-color-value font-wt-600 pt-2 text-left'>{`Task Update - `}
              <label className='font-size-14 text-color-value font-wt-600 text-color1'>{statsdata.task_update_total}</label></label>
            <div className='row px-0 w-100'>
              {LogStatus.map((item, index) => {
                return <div className='w-20 cursor'>
                  <label className={`value font-wt-600  w-100 cursor ${item.color}`}>
                    {item.count || 0}
                  </label>
                  <label className={'font-size-14 font-wt-700 text-color-value cursor'}>{item.name}</label>
                </div>
              })}

            </div>
          </div>
        </div>

      </div>
      <div>
        <label className='font-size-14 text-decoration-underline'>Leads</label>
        <div className='my-0'>
          <div className="filter-div ml-4 mt-3">
            <Filter
              filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
              showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} />
          </div>

          <div className="mb-3">
            <NewTable
              //columns={Shipmentcolumns} 
              tableFixed data={dbData}
              columns={[
                { name: "Status" },
                { name: "Exporter Name" },
                { name: "Director Name" },
                { name: "Designation" },
                { name: "Contact No." },
                { name: "Task Created" },
                { name: "Assign To" },
                { name: "Last note" }
              ]}
              options={[
                {
                  name: "Create Task", icon: "createTask.svg", onClick: (index) => {
                    setSelectedExpIndex(index)
                    const item = overalldata[index]
                    const groupedArray = item?.HS_CODES?.reduce((groups, obj) => {
                      const { HS_CODES } = obj;
                      const firstTwoDigits = HS_CODES.substring(0, 2);

                      if (!groups[firstTwoDigits]) {
                        groups[firstTwoDigits] = [];
                      }

                      groups[firstTwoDigits].push(obj);
                      return groups;
                    }, {});

                    // Step 2: Extract the first two digits as keys
                    const keys = Object.keys(groupedArray);
                    const result = keys.map(key => ({ HS_CODE: key }));
                    console.log(result);
                    const days = addDaysSkipSundays(new Date(), 2)
                    const todaysdata = moment().format("hh:mm")
                    item["HS_2_DIGIT"] = result
                    setdata({
                      ...data,
                      event_type: "Call",
                      event_status: "Hot (30 days or less)",
                      event_date: moment(days).format('YYYY-MM-DD'),
                      event_time: todaysdata,
                      reminder: "30 minutes",
                      assignedTo: subAdminId
                    })
                    setIsOpen({
                      isVisible: true,
                      data: item
                    })
                  }

                },
                {
                  name: "Didn’t connect", icon: "didntconnect.svg", onClick: (index) => {
                    setSelectedExpIndex(index)
                    setdata({
                      ...data,
                      event_status: "Busy",
                      assignedTo: subAdminId
                    })
                    setisOpenDidntRec({
                      isVisible: true,
                      selectedIndex: index
                    })
                  }
                },
                {
                  name: "Call Back", icon: "callback.svg", onClick: (index) => {
                    const days = moment().format('YYYY-MM-DD')
                    setSelectedExpIndex(index)
                    const todaysdata = moment().add(5, "hours").format('hh:mm')
                    setdata({
                      ...data,
                      event_status: "Busy",
                      event_date: days,
                      event_time: todaysdata,
                      reminder: "30 minutes",
                      assignedTo: subAdminId
                    })
                    setisOpenCallback({
                      isVisible: true,
                      selectedIndex: index
                    })
                  }

                },
                {
                  name: "Not Interested", icon: "not_intrested.svg", onClick: (index) => {
                    setSelectedExpIndex(index)
                    const days = moment().format('YYYY-MM-DD')
                    const todaysdata = moment().add(7, "day").format('hh:mm')
                    setdata({
                      ...data,
                      event_status: "Busy",
                      event_date: days,
                      event_time: todaysdata,
                      assignedTo: subAdminId
                    })
                    setisOpenNotInt({
                      isVisible: true,
                      selectedIndex: index
                    })
                  }
                },
                {
                  name: "Marked as lost", icon: "marked_as_lost.svg", onClick: (index) => {
                    setSelectedExpIndex(index)
                    setdata({
                      ...data,
                      assignedTo: subAdminId
                    })
                    setisOpenLost({
                      isVisible: true,
                      selectedIndex: index
                    })
                  }
                },
                {
                  name: "Add Note", icon: "edit.png", onClick: (index) => {
                    let temp = overalldata[index]
                    //toggleNotePopup({ show: true, data: temp.invoiceLimitLeadNote || temp.lcLimitLeadNote || temp.adminNote, selectedIndex: index, noteFor: temp.contact_person })
                  }
                }
              ]}
            />

          </div>
          <Pagination page={page} totalCount={count} onPageChange={(p) => setPage(p)} perPage={filter.resultPerPage || 10} />
        </div>
      </div>
      <div className='row pl-3 mt-4 gap-4'>

        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Outbound Task Assigned</label>

                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isLeadsGraph: !graphConfiguration.isLeadsGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isLeadsGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Leads Graph')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div>
            {graphConfiguration.isLeadsGraph ?
              <CustomLineChart XFormatter={(value) => getXAxisDateFormat(value)} YFormatter={(value) => value} bardataConfig={taskAssignConfig} formatterFunction={(value, name) => [value, taskAssignLabel[name]]} data={taskAssignedGraph} xDataKey={"xLabel"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Leads Count" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
        <div className='card h-50 w-49 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Inbound task assigned</label>
                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isClosureGraph: !graphConfiguration.isClosureGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isClosureGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Average Closure days')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            {graphConfiguration.isClosureGraph ?
              <CustomLineChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={inboundConfig} formatterFunction={(value, name) => [value, name]} data={inBoundTasksAssigned} xDataKey={"label"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Average Days" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
      </div>
      <div className='row pl-3 mt-4 gap-4'>
        <div className='card h-50 w-48 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Task Update</label>

                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isLeadsGraph: !graphConfiguration.isLeadsGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isLeadsGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'Leads Graph')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div>
            {graphConfiguration.isLeadsGraph ?
              <CustomAreaChart XFormatter={(value) => value} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, label[name]]} data={taskUpdateGraph} xDataKey={"label"} dataKey={"value"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Leads Count" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
        <div className='card h-50 w-49 dashboard-card shadow-sm '>
          <div>
            <div class="dropdown">
              <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Average task close days</label>
                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isClosureGraph: !graphConfiguration.isClosureGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isClosureGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel(closuredata, 'Average Closure days')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div>
            {graphConfiguration.isClosureGraph ?
              <CustomAreaChart XFormatter={(value) => getXAxisDateFormat(value)} YFormatter={(value) => value} bardataConfig={bardataConfig} formatterFunction={(value, name) => [value, name]} data={closuredata} xDataKey={"label"} dataKey={"averageDays"} fillColor={"#FDD775"} />
              : <div>
                <NewTable columns={[
                  { name: "Admin" },
                  { name: "Average Days" },
                ]}
                  data={[]} disableAction />
              </div>
            }
          </div>

        </div>
      </div>
      <div className='card h-75 w-100 dashboard-card shadow-sm mt-4 '>
        <div>
          <div class="dropdown">
            <div className='d-flex flex-row align-items-center justify-content-between my-3 ml-3'>
              <div className='d-flex align-items-center '>
                <label className='text-left font-size-14 font-wt-600 mr-3 mb-0 cursor' onClick={() => { }}>Daily user task performance</label>

              </div>

              <div className='d-flex flex-row align-items-center gap-1'>
                <div className="d-flex gap-2">
                  {stackedbarconfig.map(item => {
                    return <label className="paymentsdots mb-0 cursor" onClick={() => { }}><div className='colorlessdot' style={{ backgroundColor: item.fill }} /> {item.display} </label>
                  })}
                </div>
                <div className='align-items-center d-flex justify-content-end'>
                  <div className='pr-3'>
                    <img
                      onClick={() => { setGraphConfiguration({ ...graphConfiguration, isPerformanceGraph: !graphConfiguration.isPerformanceGraph }) }}
                      className='cursor'
                      src={`/assets/images/${graphConfiguration.isPerformanceGraph ? 'filterTableMode' : 'filterGraphMode'}.png`} />
                  </div>
                  <div className=''>
                    <img
                      onClick={() => ExportExcel([], 'CRM Performance')}
                      className='cursor' src='/assets/images/download_icon_with_bg.png' />
                  </div>
                </div>

              </div>



            </div>

          </div>
        </div>
        <div>
          {graphConfiguration.isPerformanceGraph ?
            <CustomBarChart XFormatter={(value) => getXAxisDateFormat(value)} YFormatter={(value) => value} bardataConfig={stackedbarconfig} formatterFunction={(value, name) => [value, name]} data={performancedata} xDataKey={"label"} isStackedChart={true} barSize={20} />
            : <div>
              <NewTable columns={[
                { name: "Admin" },
                { name: "Task Complete" },
                { name: "Task Incomplete" }
              ]}
                data={[]} disableAction />
            </div>

          }
        </div>

      </div>
    </div>
  )
}

export default SubAdminProfileDetailsCRM