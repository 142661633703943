import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../../../partial/sideBarV2";
import HeaderV2 from "../../../partial/headerV2";
import toastDisplay from "../../../../utils/toastNotification";
import { convertImageToPdf, dataURItoBlob, getCompletePdfBase64, most_used_currencies } from "../../../../utils/myFunctions";
import call from "../../../../service";
import config from "../../../../config.json";
import { FileInput } from "../../../../utils/FileInput";
import AdavancePaymentTab from "../../../InvoiceDiscounting/contract/components/adavancePaymentTab";
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from "../../../../utils/newInput";
import FinanceInvoiceModal from "../../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { Lcdetailsdata, lcTenorDD } from "./lcdiscountingcard";
import MultipleSelect from "../../../../utils/MultipleSelect";

const LcDetailsV2 = ({ amendmentId, fieldLabels, fieldComments, fieldChanged, ammendPopup2, toggleAmmendPopup2, ammendPopup, toggleAmmendPopup, ammendFields, setAmmendFields, onlyView, ocrData, handleOCRFieldsChange, lcCodes, data, setData, ports, userBanks, swiftData, errors, handleChange, countrys, currencyData, handleFile, tab1, setTab1, confirmbanking, setConfirmbanking, confirmBank,
  handleMultiSelect, handleFilterOptions, reloadUserBankDetails, adminView, draftLCBlob, ocrTxtFormat }) => {

  const viewerRef = useRef(null);
  const [pdfPageNo, setPdfPageNo] = useState(1)
  const [showScannedCopy, toggleShowScannedCopy] = useState(false)
  const [isPdfLoaded, setIsPdfLoaded] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [scanTxtPageNo, setScanTxtPageNo] = useState(1)
  const apiDivRef = useRef([]);
  const containerRef = useRef(null)

  async function tagMapperFunction(tagName) {
    let highlightText = ocrData?.[tagName] || "";
    let ocrTagMapper = ocrData?.["ocrTagMapper"]?.[tagName] || null
    console.log("tagMapperFunctionnnnnnnnnnnnn", tagName, highlightText);
    if (highlightText && ocrTagMapper?.linesIncluded?.length) {
      // console.log("preTextttttttttttttttttttttttttt", apiDivRef);
      let perPageTotalLineIndex = {}
      // let lineMappedByPageRef = []
      let textSplitByLinesArray = []
      for (let index = 0; index < apiDivRef.current.length; index++) {
        let preElem = apiDivRef?.current?.[index];
        let preText = preElem.textContent;
        let textSplitByLines = preText?.split("\n") || []

        // If last element is empty then remove it
        if (!textSplitByLines?.[textSplitByLines.length - 1]) {
          textSplitByLines.pop()
        }

        textSplitByLinesArray = textSplitByLinesArray.concat(textSplitByLines)
        let tempMinIndex = perPageTotalLineIndex[index - 1]?.max ? (perPageTotalLineIndex[index - 1]?.max + 1) : 0
        let tempMaxIndex = tempMinIndex + (textSplitByLines.length - 1)

        perPageTotalLineIndex[index] = {
          min: tempMinIndex,
          max: tempMaxIndex
        }
      }
      console.log("perPageTotalLineIndexxxxxxxxxxxxxxxxx", perPageTotalLineIndex, textSplitByLinesArray);
      let pagesInfoArr = Object.values(perPageTotalLineIndex)

      for (let index = 0; index < pagesInfoArr.length; index++) {
        let pageInfo = pagesInfoArr[index]
        let preTag = apiDivRef?.current?.[index];
        let completePreText = preTag.textContent;
        let preTextToReplace = []

        console.log("pretaggggggggggggggggggggggggg", preTag, index, pageInfo);

        for (let k = 0; k < ocrTagMapper.linesIncluded.length; k++) {
          let pageNoToCheck = ocrTagMapper.linesIncluded[k] / 1
          console.log("pageNoToCheckkkkkkkkkkkkkk", pageNoToCheck)
          if (pageNoToCheck >= pageInfo["min"] / 1 && pageNoToCheck <= pageInfo["max"]) {
            preTextToReplace = preTextToReplace.concat([textSplitByLinesArray[pageNoToCheck]])
            // console.log("pre111111111111111111111111111111", pageInfo, pageNoToCheck);
          }
        }
        preTextToReplace = preTextToReplace.join("\n")
        console.log("preTextToReplaceeeeeeeeeeeeeeeeeeee", preTextToReplace,);
        console.log("completePreTextttttttttttttttttttt", completePreText);
        if (preTextToReplace && completePreText?.includes(preTextToReplace)) {
          completePreText = completePreText.replace(preTextToReplace,
            `<span class="cursor" style="background-color: yellow">${preTextToReplace}</span>`)
          preTag.innerHTML = completePreText;
          let container = containerRef.current;
          let lineHeight = 22
          let startLineMargin = ((ocrTagMapper.linesIncluded[0] - pageInfo["min"]) * lineHeight) - 15
          container.scrollTop = preTag.offsetTop + startLineMargin;
          setScanTxtPageNo(index + 1)
        }
      }
    }
  }


  const navigateToScannedPage = (pageIndex) => {
    // apiDivRef.current[pageIndex].scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'nearest', blockOffset: 200 });
    const container = containerRef.current;
    const element = apiDivRef.current[pageIndex];
    // console.log("bothindexxxxxxxxxxxxxxxxxxxxxxxx", element.offsetTop, container.offsetTop);
    container.scrollTop = element.offsetTop;
  };

  // console.log("ooooooooooooooooooooooooooooooo", isPdfLoaded, getCompletePdfBase64(data.draftLCDocument?.filebase64),
  //   draftLCBlob);

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {!tab1 && <>
        <div className="row align-items-end  mb-4">
          {ocrTxtFormat?.length ? (
            <>
              <div
                className="d-flex mb-3 align-items-center"
              >
                <img
                  className="cursor"
                  width="25"
                  height="25"
                  src={showScannedCopy ? "assets/images/pdf_icon.png" : "assets/images/scann.png"}
                  onClick={() => {
                    toggleShowScannedCopy(!showScannedCopy)
                  }}
                />
                <label
                  className="font-size-20 font-wt-700 mx-2 pt-1"
                >{`Advance OCR System`}</label>
              </div>
              {showScannedCopy ? (<div className="d-flex row ">
                {ocrTxtFormat.map((i, j) => {
                  return (
                    <div className="position-relative mr-3 mb-3 cursor"
                      style={{
                        width: '100px', height: '70px'
                      }}
                      onClick={() => {
                        setScanTxtPageNo(j + 1)
                        navigateToScannedPage(j)
                      }}
                    >
                      <label
                        style={{ bottom: 0, borderRadius: '5px' }}
                        className={`fs-12 text-white ${j + 1 == scanTxtPageNo ? " bg5CB8D3 " : " bg-dark "}  position-absolute py-1 px-2 mb-0 `}
                      >{j + 1}</label>
                      <pre
                        style={{
                          backgroundColor: '#F3EEE9', width: '100%', height: '100%', fontSize: '2px',
                          overflow: 'hidden', borderRadius: '5px',
                          border: `${j + 1 == scanTxtPageNo ? '2px' : '1px'} solid${j + 1 == scanTxtPageNo ? "#5CB8D3" : "#707070"}`
                        }}
                        className="p-1 mb-0" dangerouslySetInnerHTML={{ __html: i }}></pre>
                    </div>
                  )
                })}
              </div>) : null}
            </>
          ) : null}


          {draftLCBlob ? (
            <div className="row d-flex mt-3">
              {showScannedCopy ? (
                <div
                  ref={containerRef}
                  className="col-7 lcViewDiv">
                  {ocrTxtFormat.map((i, j) => {
                    // console.log("datatorenderrrrrrrrrrrrrrrrrrrrrrrrrrrr", j, i);
                    // if (i) {
                    return (
                      <pre
                        id={"scanPage" + j}
                        ref={(el) => apiDivRef.current[j] = el}
                        style={{
                          backgroundColor: '#F3EEE9',
                          border: `1px solid #707070`, borderRadius: '5px', lineHeight: '22px'
                        }}
                        className="fs-12 mb-3 p-3" dangerouslySetInnerHTML={{ __html: i }}></pre>
                    )
                    // }
                  })}
                </div>
              ) : (
                <div
                  style={{ height: '30rem' }}
                  className="col-7">
                  <object
                    data={draftLCBlob} type="application/pdf" width="100%" height="100%">
                    <param name="navpanes" value="0"></param>
                    <p><a>Failed to load pdf</a></p>
                  </object>
                </div >
              )}
              <div className="col-5 py-4 lcViewDiv">
                <div className="col-md-12">
                  <NewInput
                    onClick={() => tagMapperFunction(20)}
                    isDisabled={onlyView && !fieldLabels.includes("lcNo")} isAstrix={false} type={"text"} label={"20 - LC number"}
                    name={"lcNo"} value={data.lcNo} error={errors.lcNo}
                    onChange={handleChange} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "lcNo" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["lcNo"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "lcNo" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup["lcNo"]}
                          value={ammendFields[ammendPopup["lcNo"]]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["lcNo"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("lcNo") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "lcNo" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "lcNo" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["lcNo"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["lcNo"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewInput
                    onClick={() => tagMapperFunction(48)}
                    isDisabled={onlyView && !fieldLabels.includes("lcTenor")} isAstrix={false} label={"48 - LC Tenor"}
                    type={"number"} name={"lcTenor"}
                    value={data.lcTenor}
                    onChange={handleChange} error={errors.lcTenor} />
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "lcTenor" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["lcTenor"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "lcTenor" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["lcTenor"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("lcTenor") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "lcTenor" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "lcTenor" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["lcTenor"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["lcTenor"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewSelect
                    isDisabled={onlyView && !fieldLabels.includes("lcType")} isAstrix={false} label={"LC type"}
                    selectData={Lcdetailsdata} name={"lctype"}
                    value={data.lctype} optionLabel={"name"} optionValue={'value'}
                    onChange={handleChange} error={errors.lctype} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "lcType" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["lcType"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "lcType" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["lcType"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("lcType") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "lcType" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "lcType" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["lcType"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["lcType"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewSelect isDisabled={onlyView && !fieldLabels.includes("shipmentFromCountry")} isAstrix={false} label={"Shipment from Country"}
                    selectData={countrys} name={"shipmentFromCountry"}
                    value={data.shipmentFromCountry} optionLabel={"name"} optionValue={'sortname'}
                    onChange={handleChange} error={errors.shipmentFromCountry} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "shipmentFromCountry" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["shipmentFromCountry"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "shipmentFromCountry" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["shipmentFromCountry"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("shipmentFromCountry") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "shipmentFromCountry" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "shipmentFromCountry" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["shipmentFromCountry"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["shipmentFromCountry"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewSelect isDisabled={onlyView && !fieldLabels.includes("shipmentToCountry")} isAstrix={false} label={"Shipment to Country"}
                    selectData={countrys} name={"shipmentToCountry"}
                    value={data.shipmentToCountry} optionLabel={"name"} optionValue={'sortname'}
                    onChange={handleChange} error={errors.shipmentToCountry} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "shipmentToCountry" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["shipmentToCountry"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "shipmentToCountry" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["shipmentToCountry"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("shipmentToCountry") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "shipmentToCountry" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "shipmentToCountry" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["shipmentToCountry"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["shipmentToCountry"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <MultipleSelect
                    onClick={() => tagMapperFunction("44E")}
                    isDisabled={onlyView && !fieldLabels.includes("portOfLoading")}
                    Id="44E - Port of Loading"
                    Label="44E - Port of Loading"
                    filterOption={() => true} // disable the default option filter
                    onInputChange={(e) => {
                      handleFilterOptions(e, "ports")
                    }}
                    optiondata={ports}
                    onChange={(e) => handleMultiSelect(e, "portOfLoading", "id")}
                    value={data.portOfLoading ? [data.portOfLoading] : []}
                    name="portOfLoading"
                    labelKey={"port_name"}
                    valKey={"id"}
                    error={errors.portOfLoading}
                  />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "portOfLoading" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["portOfLoading"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "portOfLoading" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["portOfLoading"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("portOfLoading") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "portOfLoading" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "portOfLoading" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["portOfLoading"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["portOfLoading"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <MultipleSelect
                    onClick={() => tagMapperFunction("44F")}
                    isDisabled={onlyView && !fieldLabels.includes("portOfDischarge")}
                    Id="44F - Port of Discharge"
                    Label="44F - Port of Discharge"
                    filterOption={() => true}
                    onInputChange={(e) => {
                      handleFilterOptions(e, "ports")
                    }}
                    optiondata={ports}
                    onChange={(e) => handleMultiSelect(e, "portOfDischarge", "id")}
                    value={data.portOfDischarge ? [data.portOfDischarge] : []}
                    name="portOfDischarge"
                    labelKey={"port_name"}
                    valKey={"id"}
                    error={errors.portOfDischarge}
                  />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "portOfDischarge" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["portOfDischarge"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "portOfDischarge" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["portOfDischarge"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("portOfDischarge") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "portOfDischarge" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "portOfDischarge" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["portOfDischarge"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["portOfDischarge"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewTextArea
                    onClick={() => tagMapperFunction("45A")}
                    isDisabled={onlyView && !fieldLabels.includes("commodity")} isAstrix={false} type={"text"} label={"45A - Commodity"}
                    name={"commodity"} value={data.commodity} error={errors.commodity}
                    onChange={handleChange} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "commodity" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["commodity"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "commodity" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["commodity"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("commodity") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "commodity" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "commodity" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["commodity"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["commodity"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewInput
                    onClick={() => tagMapperFunction("44C")}
                    isDisabled={onlyView && !fieldLabels.includes("expectedDateOfShipment")} isAstrix={false} type={"date"} label={"44C - Expected date of shipment"}
                    name={"expectedDateOfShipment"} value={data.expectedDateOfShipment} error={errors.expectedDateOfShipment}
                    onChange={handleChange} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "expectedDateOfShipment" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["expectedDateOfShipment"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "expectedDateOfShipment" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["expectedDateOfShipment"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("expectedDateOfShipment") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "expectedDateOfShipment" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "expectedDateOfShipment" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["expectedDateOfShipment"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["expectedDateOfShipment"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <MultipleSelect
                    onClick={reloadUserBankDetails}
                    isDisabled={onlyView}
                    Id="Beneficiary bank name"
                    Label="Beneficiary bank name"
                    optiondata={userBanks}
                    onChange={(e) => handleMultiSelect(e, "beneficiaryBank", "id")}
                    value={data.beneficiaryBank ? [data.beneficiaryBank] : []}
                    name="beneficiaryBank"
                    labelKey={"beneficiaryBankName"}
                    valKey={"id"}
                    error={errors.beneficiaryBank}
                  />
                </div>

                <div className="col-md-12">
                  <NewInput isAstrix={false} isDisabled={true} type={"text"} label={"Beneficiary bank account no."}
                    name={"beneficiaryAccountNo"} value={data.beneficiaryAccountNo} error={errors.beneficiaryAccountNo}
                    onChange={handleChange} />
                </div>

                <div className="col-md-12">
                  <NewInput isAstrix={false} isDisabled={true} type={"text"} label={"Beneficiary bank IFSC code"}
                    name={"beneficiaryIfscCode"} value={data.beneficiaryIfscCode} error={errors.beneficiaryIfscCode}
                    onChange={handleChange} />
                </div>

                <div className="col-md-12">
                  <NewInput isAstrix={false} isDisabled={true} type={"text"} label={"Beneficiary bank SWIFT code"}
                    name={"beneficiarySwiftCode"} value={data.beneficiarySwiftCode} error={errors.beneficiarySwiftCode}
                    onChange={handleChange} />
                </div>

                <div className="col-md-12">
                  <MultipleSelect
                    isDisabled={onlyView}
                    Id="LC issuing bank name"
                    Label="LC issuing bank name"
                    filterOption={() => true}
                    onInputChange={(e) => {
                      handleFilterOptions(e, "swiftData")
                    }}
                    optiondata={swiftData}
                    onChange={(e) => handleMultiSelect(e, "lcIssuingBank", "id")}
                    value={data.lcIssuingBank ? [data.lcIssuingBank] : []}
                    name="lcIssuingBank"
                    labelKey={"bankName"}
                    valKey={"id"}
                    error={errors.lcIssuingBank}
                  />
                </div>

                <div className="col-md-12">
                  <NewInput isDisabled={onlyView && !fieldLabels.includes("lcIssuingBankAddress")} isAstrix={false} type={"text"} label={"LC issuing bank address"}
                    name={"lcIssuingBankAddress"} value={data.lcIssuingBankAddress} error={errors.lcIssuingBankAddress}
                    onChange={handleChange} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "lcIssuingBankAddress" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["lcIssuingBankAddress"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "lcIssuingBankAddress" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["lcIssuingBankAddress"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("lcIssuingBankAddress") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "lcIssuingBankAddress" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "lcIssuingBankAddress" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["lcIssuingBankAddress"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["lcIssuingBankAddress"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewInput isDisabled={onlyView && !fieldLabels.includes("lcIssuingBankSwiftCode")} isAstrix={false} type={"text"} label={"LC issuing bank SWIFT code"}
                    name={"lcIssuingBankSwiftCode"} value={data.lcIssuingBankSwiftCode} error={errors.lcIssuingBankSwiftCode}
                    onChange={handleChange} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "lcIssuingBankSwiftCode" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["lcIssuingBankSwiftCode"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "lcIssuingBankSwiftCode" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["lcIssuingBankSwiftCode"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("lcIssuingBankSwiftCode") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "lcIssuingBankSwiftCode" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "lcIssuingBankSwiftCode" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["lcIssuingBankSwiftCode"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["lcIssuingBankSwiftCode"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <NewSelect isDisabled={onlyView && !fieldLabels.includes("countryOfOrigin")} isAstrix={false} label={"Country of origin "}
                    selectData={countrys} name={"countryOfOrigin"}
                    value={data.countryOfOrigin} optionLabel={"name"} optionValue={'sortname'}
                    onChange={handleChange} error={errors.countryOfOrigin} />
                  {/* For create amendment */}
                  {onlyView && !amendmentId ? (
                    <div
                      onClick={() => toggleAmmendPopup2({ show: true, key: "countryOfOrigin" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={ammendFields["countryOfOrigin"] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === "countryOfOrigin" ? (
                    <div className="ammendPopup">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                      <div className="text-center">
                        <NewTextArea
                          rows={6}
                          type={"text"} label={`Comment`} name={ammendPopup2.key}
                          value={ammendFields[ammendPopup2.key]}
                          onChange={(e) => {
                            setAmmendFields({ ...ammendFields, ["countryOfOrigin"]: e.target.value || undefined })
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* For uploading amendment lc */}
                  {fieldLabels.includes("countryOfOrigin") ? (
                    <div
                      onClick={() => toggleAmmendPopup({ show: true, key: "countryOfOrigin" })}
                      className="cursor ammendCommentIcon2"
                    >
                      <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                    </div>
                  ) : null}
                  {ammendPopup && ammendPopup.show && ammendPopup.key === "countryOfOrigin" ? (
                    <div className="ammendPopup2">
                      <button
                        type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                      <div className="p-3">
                        <div className="mr-5 pr-2">
                          <NewInput isDisabled={true} value={fieldChanged["countryOfOrigin"]} />
                        </div>
                        <label className="font-wt-500 font-size-12 pl-1">
                          {fieldComments["countryOfOrigin"]}
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <InputWithSelect
                    onClick={() => tagMapperFunction("32B")}
                    isDisabled={onlyView}
                    isAstrix={false} type={"number"} label={`${'32B'} - ${"Currency Code, Amount"}`}
                    selectData={currencyData}
                    selectName={"32B1"} selectValue={ocrData["32B1"]}
                    optionLabel={"code"} optionValue={'code'}
                    name={'32B2'} value={ocrData["32B2"]} error={errors["32B2"]}
                    onChange={handleOCRFieldsChange} />
                </div>

                {lcCodes.map((item, index) => {
                  if (item.tag != "32B") {
                    return (
                      <div className="col-md-12">

                        <NewTextArea
                          onClick={() => tagMapperFunction(item.tag)}
                          isDisabled={onlyView && !fieldLabels.includes(item.tag)}
                          isAstrix={false} type={"text"} label={`${item.tag} - ${item.fieldName}`}
                          name={item.tag} value={ocrData[item.tag]} errors={item.tag}
                          onChange={handleOCRFieldsChange} />

                        {/* For create amendment */}
                        {onlyView && !amendmentId ? (
                          <span
                            onClick={() => toggleAmmendPopup2({ show: true, key: item.tag })}
                            className="cursor ammendCommentIcon2 "
                          >
                            <img className="genericImgIcon" src={ammendFields[item.tag] ? "assets/images/comment_filled.png" : "assets/images/chat.png"} alt="" />
                          </span>
                        ) : null}
                        {ammendPopup2 && ammendPopup2.show && ammendPopup2.key === item.tag ? (
                          <div className="ammendPopup">
                            <button
                              type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup2({ show: false, key: null })}></button>
                            <div className="text-center">
                              <NewTextArea
                                rows={6}
                                type={"text"} label={`Comment`} name={ammendPopup2.key}
                                value={ammendFields[ammendPopup2.key]}
                                onChange={(e) => {
                                  setAmmendFields({ ...ammendFields, [item.tag]: e.target.value || undefined })
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {/* For uploading amendment lc */}
                        {fieldLabels.includes(item.tag) ? (
                          <div
                            onClick={() => toggleAmmendPopup({ show: true, key: item.tag })}
                            className="cursor ammendCommentIcon2"
                          >
                            <img className="genericImgIcon" src={"assets/images/comment_filled.png"} alt="" />
                          </div>
                        ) : null}
                        {ammendPopup && ammendPopup.show && ammendPopup.key === item.tag ? (
                          <div className="ammendPopup2">
                            <button
                              type="button" className="btn-close ammendCommentCrossIcon" onClick={() => toggleAmmendPopup({ show: false, key: null })}></button>
                            <div className="p-3">
                              <div className="mr-5 pr-2">
                                <NewTextArea isDisabled={true} value={fieldChanged[item.tag]} />
                              </div>
                              <label className="font-wt-500 font-size-12 pl-1">
                                {fieldComments[item.tag]}
                              </label>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )
                  }
                }
                )}

                {confirmbanking && <div className="">
                  <p><label className='font-wt-500 font-size-16 ml-4'><u>Confirming bank details</u></label></p>

                  <div className="col-md-12">
                    <NewInput isDisabled={onlyView} isAstrix={false} type={"text"} label={"Confirming bank name"}
                      name={"confirmingBankName"} value={data.confirmingBankName} error={errors.confirmingBankName}
                      onChange={handleChange} />
                  </div>

                  <div className="col-md-12">
                    <NewInput isDisabled={onlyView} isAstrix={false} type={"text"} label={"Confirming bank address"}
                      name={"confirmingBankAddress"} value={data.confirmingBankAddress} error={errors.confirmingBankAddress}
                      onChange={handleChange} />
                  </div>

                  <div className="col-md-12">
                    <NewInput isDisabled={onlyView} isAstrix={false} type={"text"} label={"Confirming bank SWIFT code"}
                      name={"confirmingBankSwiftCode"} value={data.confirmingBankSwiftCode} error={errors.confirmingBankSwiftCode}
                      onChange={handleChange} />
                  </div>

                </div>}

              </div>
            </div>
          ) : null}
        </div>
      </>}

    </>
  );
}
export default LcDetailsV2;