import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '2017',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2018',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '2019',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '2020',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '2021',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '2022',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  }
];

export default class FinancerDisbursement extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-area-chart-4ujxw';

  render() {
    console.log('111111111111111111111111', this.props.data)
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={this.props.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="year" type="category" />
          <YAxis />
          <Tooltip />
          <defs>
            <linearGradient id="fillcolor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={"50%"} stopColor="#76EEA9" stopOpacity={0.58} />
              <stop offset={"100%"} stopColor="#76EEA9" stopOpacity={0.17} />
            </linearGradient>
          </defs>
          <Area type="linear" dataKey="finance" stroke="#76EEA9" fill="url(#fillcolor)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
