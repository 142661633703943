import React, { useEffect, useState } from 'react';
import Header from '../partial/header';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import call from '../../service';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import ContractPieChart from "./piechart";
import ContractLineChart from "./line";
import avatarUrl from '../../utils/makeAvatarUrl';
import Footer from '../partial/footer';
import LineChart from './lineChart.js';
import toastDisplay from "../../utils/toastNotification";
import AccountListTable from "./AccountListTable";
import DisbursedInvoices from "./DisbursedInvoices";
import RaisedInvoices from "./RaisedInvoices";
import { platformURL } from '../../urlConstants';
import { setChannelPartnerAccountList, setChannelPartnerDisbursedInvoice, setChannelPartnerRaisedInvoice } from '../../store/actions/action';
import FinanceDetailGrid from '../userProfile/financeDetailGrid';
import InvoiceFinance from '../finance/financeApply/invoiceFinance';
import LCFinance from '../finance/financeApply/lcFinance';
export const TableHeadData = [
  "Avatar",
  "Exporter",
  "Contact Person",
  "Buyers (In Number)",
  "Address",
  "Country",
  "Action",
];

const Dashboard = ({ userTokenDetails, navToggleState, dispatch, channelPartnerAccountList, setChannelPartnerAccountList, channelPartnerDisbursedInvoice, setChannelPartnerDisbursedInvoice, channelPartnerRaisedInvoice, setChannelPartnerRaisedInvoice }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [show, setShow] = useState(true);
  const [dashData, setdashData] = useState({});
  const [tab, setTab] = useState(0);
  const [kycAction, setkycAction] = useState({ modal: true, data: {} });
  const [numberOfBuyer, setNumberOfBuyer] = useState([]);
  const [userDataPoints, setUserDataPoints] = useState([]);
  const [userMonthPoints, setUserMonthPoints] = useState([]);
  const [newlyAddedBuyer, setNewlyAddedBuyer] = useState([]);
  const [financeGrid, setFinanceGrid] = useState([]);
  const [channelPartnerUsers, setChannelPartnerUsers] = useState([]);
  const [accord, setaccord] = useState({
    0: false,
    1: false,
  });
  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    //------------------------------------------------------------------
    //API
    let mkt_ref_id = localStorage.getItem("mkt_ref_id")
    let mkt_insp_ref_id = localStorage.getItem("mkt_insp_ref_id")
    if (mkt_ref_id && traderTypeIds.includes(userTypeId)) {
      call('post', 'placeShippingMarketPlaceBooking', {
        mkt_ref_id,
        "owner_id": userId,
        "status": 0,
        "created_by": userId
      }).then((result) => {
        // console.log("placeShippingMarketPlaceBooking result=>", result);
        localStorage.removeItem("mkt_ref_id")
        window.open(platformURL + "/my-quotations", "_self")
      })
    }
    if (mkt_insp_ref_id && traderTypeIds.includes(userTypeId)) {
      call('post', 'placeInspectionMarketPlaceBooking', {
        mkt_insp_ref_id,
        "created_by": userId
      }).then((result) => {
        // console.log("placeInspectionMarketPlaceBooking result=>", result);
        localStorage.removeItem("mkt_insp_ref_id")
        window.open(platformURL + "/my-quotations", "_self")
      })
    }
    call('get', 'getDashboardData')
      .then((result) => {
        console.log("fetched result in dashboard===>", result)
        setdashData(result);
        setShow(false);
      })
      .catch((err) => {
        console.log("error:", err)
        setShow(false);
      });

    let buyerdataSet = [];
    call('POST', 'getNumberOfBuyer').then((result) => {
      console.log('result in getNumberOfBuyer => ', result);
      for (let i = 0; i < result.length; i++) {
        buyerdataSet.push({ "label": result[i].lender_name, "y": result[i].buyer_number })
      }
      setNumberOfBuyer(buyerdataSet);
    }).catch((err) => {
      console.log('Error in getNumberOfBuyer => ', err);
    })

    call('POST', 'getBuyerReport').then((result) => {
      console.log('result in getBuyerReport => ', result);
      setNewlyAddedBuyer(result.reportData);
    }).catch((e) => {
      console.log('error in getBuyerReport', e);
    })

    let userdataSet = [];

    call('POST', 'getUserReport').then((result) => {
      console.log('result in getUserReport', result);
      setUserDataPoints(result.userdataSet);
      setUserMonthPoints(result.usersMonthData)

    }).catch((e) => {
      console.log('error in getUserReport', e);
    });

    let objectAPI = {
      "userId": userId,
      "type_id": userTypeId,
      "currentPage": 1,
      "resultPerPage": 5
    }

    call('POST', 'getLoans', objectAPI).then((result) => {
      console.log('running getLoans api-->', result);
      setFinanceGrid(result.data);
    }).catch((e) => {
      console.log('error in getLoans', e);
    })

    if (userTypeId == 20) {
      call('POST', 'getChannelPartnerUsers', { "userId": userId }).then((result) => {
        console.log('running getChannelPartnerUsers api-->', result);
        setChannelPartnerUsers(result.userData);
      }).catch((e) => {
        console.log('error in getChannelPartnerUsers', e);
      })
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //------------------------------------------------------------------
    if ([4, 3, 14, 8, 6, 13].includes(userTypeId / 1)) {
      setTab(0)
    } else if ([5, 7, 10, 11, 18].includes(userTypeId / 1)) {
      setTab(1)
    } else if ([19].includes(userTypeId / 1)) {
      setTab(3)
    } else if ([1].includes(userTypeId / 1)) {
      setTab(4)
    } else {
      setTab("")
    }
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  const handlefiles = (event) => {
    event.persist();
    setError({ ...error, "bulk_upld_error": "" });
    if (!event.target.files[0]['name'].includes("csv")) {
      setError({ ...error, "bulk_upld_error": "Please select csv file" });
      return
    }
    setFile({ ...file, [event.target.name]: event.target.files[0] });
  }

  function genStatsCard() {
    if (tab === 0) {
      return (
        <>
          <h3>Trade Contract Statistic</h3>
          <ContractPieChart contractDetails={{
            total: dashData.tradeContractDetails ? dashData.tradeContractDetails.totalcount : 0,
            complete: dashData.tradeContractDetails ? dashData.tradeContractDetails.completecount : 0,
            progress: dashData.tradeContractDetails ? dashData.tradeContractDetails.progresscount : 0,
            pilot: dashData.tradeContractDetails ? dashData.tradeContractDetails.pilotcount : 0
          }} />
          <br />
        </>
      )
    } else if (tab === 2) {
      return (
        <>
          <h3>Quotations Statistic</h3>
          <ContractPieChart contractDetails={{
            total: dashData.quotationDetails ? dashData.quotationDetails.totalcount : 0,
            complete: dashData.quotationDetails ? dashData.quotationDetails.acceptcount : 0,
            progress: dashData.quotationDetails ? dashData.quotationDetails.pendingcount : 0,
            rejected: dashData.quotationDetails ? dashData.quotationDetails.rejectcount : 0
          }} />
          <br />
        </>
      )
    } else if (tab === 1) {
      return (
        <>
          <h3>Shipment Contract Statistic</h3>
          <ContractPieChart contractDetails={{
            total: dashData.shipmentDetails ? dashData.shipmentDetails.totalcount : 0,
            complete: dashData.shipmentDetails ? dashData.shipmentDetails.completecount : 0,
            progress: dashData.shipmentDetails ? dashData.shipmentDetails.progresscount : 0,
            pilot: dashData.shipmentDetails ? dashData.shipmentDetails.pilotcount : 0
          }} />
          <br />
        </>
      )
    }
    // else if (tab === 3 && userTypeId != 19) {
    //   return (
    //     <>
    //       <h3>Finance Statistic</h3>

    //       {/* <ContractPieChart contractDetails={{
    //         total: dashData.financeDetails ? dashData.financeDetails.totalamount : 0,
    //         complete: dashData.financeDetails ? dashData.financeDetails.completeamount : 0,
    //         progress: dashData.financeDetails ? dashData.financeDetails.progressamount : 0,
    //         pilot: dashData.financeDetails ? dashData.financeDetails.pendingamount : 0
    //       }} /> */}
    //       <br />
    //     </>
    //   )
    // }
    else if (tab === 3) {
      if ([5, 7, 10, 11, 18].includes(userTypeId / 1)) {
        return (
          <>
            {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <h3>Upload Bulk Shipment Quotation</h3>
            <div className='row' style={{ "margin-left": '5%' }}>
              <div className='col pl-4 align-self-center'>
                <h6 className=''>
                  <a className="text-dark cursor-pointer" href="./assets/docs/ShipQuotationList.csv"
                    target="_blank" download>
                    <img className='mr-2' src="./assets/images/logos/csv.jpg" alt="" width="45" height="45"></img>
                    {"Download Template"}</a>
                </h6>
              </div>
              <div className='col pl-2 pt-2 d-flex'>
                <div>
                  <label className="">Select CSV file for bulk upload</label>
                  <div className="file-browse">
                    <button className="btn btn-primary btn-sm">Upload  File</button>
                    <input type="file" accept=".csv" name="shipmentQuotation" onChange={handlefiles} />
                    {file.shipmentQuotation ? <div className="form-group mb-0"><div className="file-name">{`${file.shipmentQuotation.name}`}</div></div> : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button type="button" style={{ "margin-right": '55%', "margin-top": '5%' }} className="btn btn-primary btn-sm float-right" onClick={() => setShippmentData()} disabled={file.shipmentQuotation ? false : true}>Submit</button>
            </div>
          </>
        )
      }
      else {
        return (
          <>
            <h3>Newly Assigned Buyers</h3>
            {/* <div className="row">
              <div className="col-md-12"> */}
            <div className="table-responsive" style={{ height: "350px" }}>
              <table className="table table-striped" cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr style={{ fontSize: "14px" }}>
                    <th width="20">#</th>
                    <th width="30">Establishment Date</th>
                    <th width="30">Supplier Name</th>
                    <th width="30">Buyer Name</th>
                    <th width="30">Lender Name</th>
                    <th width="30">Limit</th>
                    <th width="30">Remark</th>
                  </tr>
                </thead>
                {newlyAddedBuyer.length > 0 && newlyAddedBuyer.map((values, key) => {
                  if (key < 50) {
                    return (
                      <tbody>
                        <tr style={{ fontSize: "14px" }}>
                          <td >{key + 1}</td>
                          <td>{values.date_of_establishment}</td>
                          <td>{values.supplier_name}</td>
                          <td >{values.buyer_name}</td>
                          <td>{values.financier_name}</td>
                          <td>{values.limit}</td>
                          <td>{values.remark}</td>
                        </tr>
                      </tbody>
                    )
                  }
                })}
              </table>
            </div>
            {/* </div>
            </div> */}
          </>
        )
      }
    } else if (tab === 4) {
      return (
        <>
          <a href={'/reports'}>
            <button type="button" className="text-center btn btn-primary btn-sm m-1 float-right">View All</button>
          </a>
          <h3>Newly Assigned Buyers</h3>
          <div className="table-responsive" style={{ height: "350px" }}>
            <table className="table table-striped" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr style={{ fontSize: "14px" }}>
                  <th width="20">#</th>
                  <th width="30">Establishment Date</th>
                  <th width="30">Supplier Name</th>
                  <th width="30">Buyer Name</th>
                  <th width="30">Stenn</th>
                  <th width="30">Modifi</th>
                  <th width="30">Tradewind</th>
                </tr>
              </thead>
              {newlyAddedBuyer.length > 0 && newlyAddedBuyer.map((values, key) => {
                if (key < 50) {
                  return (
                    <tbody>
                      <tr style={{ fontSize: "14px" }}>
                        <td >{key + 1}</td>
                        <td>{values.date_of_establishment}</td>
                        <td>{values.supplier_name}</td>
                        <td >{values.buyer_name}</td>
                        <td>{`${(values.stenn ? values.stenn : 'NA')}`}</td>
                        <td>{`${(values.modifi ? values.modifi : 'NA')}`}</td>
                        <td>{`${(values.tradewind ? values.tradewind : 'NA')}`}</td>
                      </tr>
                    </tbody>
                  )
                }
              })}
            </table>
          </div>
          <br />
        </>
      )
    } else if (tab === 5) {
      return (
        <>
          <h3>Plan Payment Statistic</h3>
          <ContractPieChart contractDetails={{
            total: dashData.planDetails ? dashData.planDetails.totalcount : 0,
            complete: dashData.planDetails ? dashData.planDetails.completecount : 0,
            reject: dashData.planDetails ? dashData.planDetails.declinecount : 0,
            progress: dashData.planDetails ? dashData.planDetails.pendingcount : 0,
            refund: dashData.planDetails ? dashData.planDetails.refundcount : 0
          }} />
          <br />
        </>
      )
    }
  }

  function setShippmentData() {
    setshowLoader(true)
    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('file', file.shipmentQuotation);
    call('POST', 'setShippmentData', formData).then((result) => {
      if (result) {
        toastDisplay("File Uploaded", "success");
        setrefresh(refresh + 1);
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar state="dashboard" userTokenDetails={userTokenDetails} />

          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={channelPartnerAccountList.modal ? `Financial, Credit Line & KYC Details (${channelPartnerAccountList.info.userName})` : "Dashboard"}
              userTokenDetails={userTokenDetails} />

            <div className="mt-4">
              {show && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

              {/* Company details start */}
              {/* <div className={"accordionItem col-md-12 pl-1 pr-1" + (accord['0'] ? " open" : " closed")}>
                <h2 className={"accordionItemHeading text-dark"} onClick={() => setaccord({ ...accord, '0': !accord['0'] })}>{accord['0'] == true ? 'Profile Details' : 'Click here to View Profile Details'}</h2>
                <div className="accordionItemContent">

                  <div className="row mt-4">
                    <div className="col-md-2 profile-img-pro">
                      <img src={avatarUrl(dashData.userDetails ? dashData.userDetails.user_avatar : '--')} alt="Avatar" />
                    </div>
                    <div className="col-md-10 profile-pro-right">
                      <h3>{dashData.userDetails ? dashData.userDetails.company_name : '--'}   <a href="/editprofile"><i className="fa fa-edit" href="/editprofile" /></a> </h3>
                      <div className="profile-top">
                        <span>{(dashData.userDetails && dashData.userDetails.country_code) ?
                          <span className="shadow">
                            <ReactCountryFlag
                              countryCode={dashData.userDetails ? dashData.userDetails.country_code : '--'}
                              style={{ width: '25px', height: '25px' }} svg />
                          </span> : "--"}
                        </span>{dashData.userDetails ? dashData.userDetails.user_address : '--'}
                      </div>
                      <div className="profile-bottom">
                        <ul>
                          <li>
                            <div className="edit-pro-top">Contact Person</div>
                            <div className="edit-pro-bottom">{dashData.userDetails ? dashData.userDetails.contact_person : '--'}</div>
                          </li>
                          {(userTypeId !== 1) && <>
                            <li>
                              <div className="edit-pro-top">IEC No.</div>
                              <div className="edit-pro-bottom">{dashData.userDetails ? dashData.userDetails.iec_no : '--'}</div>
                            </li>
                            <li>
                              <div className="edit-pro-top">GST/VAT No.</div>
                              <div className="edit-pro-bottom">{dashData.userDetails ? dashData.userDetails.gst_vat_no : '--'}</div>
                            </li>
                          </>}
                          <li>
                            <div className="edit-pro-top">Email id.</div>
                            <div className="edit-pro-bottom">{dashData.userDetails ? dashData.userDetails.email_id : '--'}</div>
                          </li>
                          <li>
                            <div className="edit-pro-top">Contact No.</div>
                            <div className="edit-pro-bottom">{dashData.userDetails ? dashData.userDetails.contact_number : '--'}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* company details end */}

              {/* tabs start */}
              {userTypeId !== 20 && <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                    {[1].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 4 ? " active show" : "")} onClick={() => setTab(4)}>
                          <h3><span><img src="assets/images/sidebar/users.png" height="25px" className="pr-2" /></span>Users Details</h3>
                        </a>
                      </li>}
                    {[1, 3, 4, 14, 8, 6, 13].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>
                          <h3><span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-2" /></span>{userTypeId / 1 === 8 ? "Cases" : " Trade Contract"}</h3>
                        </a>
                      </li>}
                    {[1, 3, 4, 14, 5, 7, 10, 11, 18].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>
                          <h3><span><img src="assets/images/sidebar/shipment.png" height="25px" className="pr-2" /></span>Shipment Contract</h3>
                        </a>
                      </li>}
                    {[1, 3, 4, 14, 5, 10, 11].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>
                          <h3><span><img src="assets/images/sidebar/myQuoatation.png" height="25px" className="pr-2" /></span>Shipment Quotations</h3>
                        </a>
                      </li>}
                    {[5, 7, 10, 11, 18].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 3 ? " active show" : "")} onClick={() => setTab(3)}>
                          <h3><span><img src="assets/images/sidebar/shipment.png" height="25px" className="pr-2" /></span>Upload Shipment Quotation</h3>
                        </a>
                      </li>}
                    {[1, 4, 14, 8, 19].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 3 ? " active show" : "")} onClick={() => setTab(3)}>
                          <h3><span><img src="assets/images/sidebar/finance.png" height="25px" className="pr-2" /></span>Finance Details</h3>
                        </a>
                      </li>}
                    {[1].includes(userTypeId / 1) &&
                      <li>
                        <a className={"nav-link" + (tab === 5 ? " active show" : "")} onClick={() => setTab(5)}>
                          <h3><span><img src="assets/images/sidebar/planPayments.png" height="25px" className="pr-2" /></span>Plan Payment Details</h3>
                        </a>
                      </li>}
                  </ul>

                  <div className="d-flex">
                    {tab === 0 && [1, 3, 4, 14, 8, 6, 13].includes(userTypeId / 1) &&
                      <div className={"tab-pane active show col-md-12 p-0"}>
                        <div className="accordionWrapper p-0">
                          <div className={"accordionItem open"}>
                            {/* <h1 className="accordionItemHeading">
                          <h2><span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-2" /></span>{userTypeId / 1 === 8 ? "Cases" : " Trade Contract"}</h2>
                        </h1> */}
                            <div className="accordionItemContent">
                              {/* <div className="col-md-12"> */}
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/contract"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg1">
                                      <span className="icon-pro"><i class="fas fa-file-contract fa-lg"></i></span>
                                      <h4>{(dashData.tradeContractDetails ? dashData.tradeContractDetails.totalcount : 0)}</h4>
                                      <p>Total {userTypeId / 1 === 8 ? "Cases" : " Contract"}</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/contract?status=1"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg2">
                                      <span className="icon-pro"><i class="fas fa-drafting-compass fa-lg"></i></span>
                                      <h4>{(dashData.tradeContractDetails ? dashData.tradeContractDetails.pilotcount : 0)}</h4>
                                      <p>Pilot {userTypeId / 1 === 8 ? "Cases" : " Contract"}</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/contract?status=2"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg3">
                                      <span className="icon-pro"><i class="fas fa-list-ul fa-lg"></i></span>
                                      <h4>{(dashData.tradeContractDetails ? dashData.tradeContractDetails.progresscount : 0)}</h4>
                                      <p>in Progress {userTypeId / 1 === 8 ? "Cases" : " Contract"}</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/contract?status=3"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg4">
                                      <span className="icon-pro"><i class="fas fa-tasks fa-lg"></i></span>
                                      <h4>{(dashData.tradeContractDetails ? dashData.tradeContractDetails.completecount : 0)}</h4>
                                      <p>Completed {userTypeId / 1 === 8 ? "Cases" : " Contract"}</p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {tab === 1 && [1, 3, 4, 14, 5, 7, 10, 11, 18].includes(userTypeId / 1) &&
                      <div className={"tab-pane active show  p-0 col-md-12"}>
                        <div className="accordionWrapper p-0">
                          <div className={"accordionItem open"}>
                            {/* <h1 className="accordionItemHeading">
                          <h2><span><img src="assets/images/sidebar/shipment.png" height="25px" className="pr-2" /></span>Shipment Contract</h2>
                        </h1> */}
                            <div className="accordionItemContent">
                              {/* <div className="col-md-12"> */}
                              <div className="row">

                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/shipment"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg1">
                                      <span className="icon-pro"><i class="fas fa-ship fa-lg"></i></span>
                                      <h4>{(dashData.shipmentDetails ? dashData.shipmentDetails.totalcount : 0)}</h4>
                                      <p>Total Contract</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/shipment?status=1"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg2">
                                      <span className="icon-pro"><i class="fas fa-drafting-compass fa-lg"></i></span>
                                      <h4>{(dashData.shipmentDetails ? dashData.shipmentDetails.pilotcount : 0)}</h4>
                                      <p>Pilot Contract</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/shipment?status=2"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg3">
                                      <span className="icon-pro"><i class="fas fa-list-ul fa-lg"></i></span>
                                      <h4>{(dashData.shipmentDetails ? dashData.shipmentDetails.progresscount : 0)}</h4>
                                      <p>in Progress Contract</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/shipment?status=3"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg4">
                                      <span className="icon-pro"><i class="fas fa-tasks fa-lg"></i></span>
                                      <h4>{(dashData.shipmentDetails ? dashData.shipmentDetails.completecount : 0)}</h4>
                                      <p>Completed Contract</p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {tab === 2 && [1, 3, 4, 14, 5, 10, 11].includes(userTypeId / 1) &&
                      <div className={"tab-pane active show col-md-12 p-0"}>
                        <div className="accordionWrapper p-0">
                          <div className={"accordionItem open"}>
                            {/* <h1 className="accordionItemHeading">
                          <h2><span><img src="assets/images/sidebar/myQuoatation.png" height="25px" className="pr-2" /></span>Shipment Quotations</h2>
                        </h1> */}
                            <div className="accordionItemContent">
                              {/* <div className="col-md-12"> */}
                              <div className="row">

                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/quotationlist"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg1">
                                      <span className="icon-pro"><i class="fas fa-tags fa-lg"></i></span>
                                      <h4>{(dashData.quotationDetails ? dashData.quotationDetails.totalcount : 0)}</h4>
                                      <p>Total Quotations</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/quotationlist?status=1"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg3">
                                      <span className="icon-pro"><i class="fas fa-list-ul fa-lg"></i></span>
                                      <h4>{(dashData.quotationDetails ? dashData.quotationDetails.pendingcount : 0)}</h4>
                                      <p>Active Quotations</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/quotationlist?status=2"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg4">
                                      <span className="icon-pro"><i class="fas fa-tasks fa-lg"> </i></span>
                                      <h4>{(dashData.quotationDetails ? dashData.quotationDetails.acceptcount : 0)}</h4>
                                      <p>Completed Quotations</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/quotationlist"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg5">
                                      <span className="icon-pro"><i class="fas fa-window-close fa-lg"></i></span>
                                      <h4>{(dashData.quotationDetails ? dashData.quotationDetails.rejectcount : 0)}</h4>
                                      <p>Rejected Quotations</p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {tab === 3 && [1, 4, 14, 8, 19].includes(userTypeId / 1) ?
                      <div className={"tab-pane active show col-md-12 p-0"}>
                        <div className="accordionWrapper p-0">
                          <div className={"accordionItem open"}>
                            {/* <h1 className="accordionItemHeading">
                          <h2><span><img src="assets/images/sidebar/finance.png" height="25px" className="pr-2" /></span>Finance Details</h2>
                        </h1> */}
                            <div className="accordionItemContent">
                              {/* <div className="col-md-12"> */}
                              <div className="row">

                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/finance"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg1">
                                      <span className="icon-pro"><i class="fas fa-piggy-bank fa-lg"></i></span>
                                      <h4><i class="fas fa-dollar-sign fa-sm"></i>{(dashData.financeDetails ? dashData.financeDetails.totalamount : 0)}</h4>
                                      <p>{userTypeId === 8 ? "Total Req. Received" : "Total Applied"}: {(dashData.financeDetails ? dashData.financeDetails.totalcount : 0)}</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/finance?status=1"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg2">
                                      <span className="icon-pro"><i class="fas fa-drafting-compass fa-lg"></i></span>
                                      <h4><i class="fas fa-dollar-sign fa-sm"></i>{(dashData.financeDetails ? dashData.financeDetails.pendingamount : 0)}</h4>
                                      <p>Pending: {(dashData.financeDetails ? dashData.financeDetails.pendingcount : 0)}</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/finance?status=3"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg3">
                                      <span className="icon-pro"><i class="fas fa-list-ul fa-lg"></i></span>
                                      <h4><i class="fas fa-dollar-sign fa-sm"></i>{(dashData.financeDetails ? dashData.financeDetails.progressamount : 0)}</h4>
                                      <p>Approved: {(dashData.financeDetails ? dashData.financeDetails.progresscount : 0)}</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/finance?status=4"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg4">
                                      <span className="icon-pro"><i class="fas fa-tasks fa-lg"></i></span>
                                      <h4><i class="fas fa-dollar-sign fa-sm"></i>{(dashData.financeDetails ? dashData.financeDetails.completeamount : 0)}</h4>
                                      <p>Disbursed: {(dashData.financeDetails ? dashData.financeDetails.completecount : 0)}</p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      [5, 7, 10, 11, 18].includes(userTypeId / 1) ?
                        <div className={"tab-pane active show col-md-12 p-0"}>
                        </div>
                        : ''
                    }

                    {tab === 4 && [1].includes(userTypeId / 1) &&
                      <div className={"tab-pane active show col-md-12 p-0"}>
                        <div className="accordionWrapper p-0">
                          <div className={"accordionItem open"}>
                            {/* <h1 className="accordionItemHeading">
                          <h2><span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-2" /></span>{userTypeId / 1 === 8 ? "Cases" : " Trade Contract"}</h2>
                        </h1> */}
                            <div className="accordionItemContent">
                              {/* <div className="col-md-12"> */}
                              <div className="row">

                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg1">
                                      <span className="icon-pro"><i class="fas fa-users fa-lg"></i></span>
                                      <h4>{(dashData.adminUserDetails ? dashData.adminUserDetails.totalcount : 0)}</h4>
                                      <p>Total Users</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg2">
                                      <span className="icon-pro"><i class="fas fa-users-slash fa-lg"></i></span>
                                      <h4>{(dashData.adminUserDetails ? dashData.adminUserDetails.inactivecount : 0)}</h4>
                                      <p>Inactive users</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg3">
                                      <span className="icon-pro"><i class="fas fa-users-cog fa-lg"></i></span>
                                      <h4>{(dashData.adminUserDetails ? dashData.adminUserDetails.activecount : 0)}</h4>
                                      <p>Active users</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg5">
                                      <span className="icon-pro"><i class="fas fa-user-times fa-lg"></i></span>
                                      <h4>{(dashData.adminUserDetails ? dashData.adminUserDetails.blockedcount : 0)}</h4>
                                      <p>Blocked Users</p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {tab === 5 && [1].includes(userTypeId / 1) &&
                      <div className={"tab-pane active show col-md-12 p-0"}>
                        <div className="accordionWrapper p-0">
                          <div className={"accordionItem open"}>
                            {/* <h1 className="accordionItemHeading">
                          <h2><span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-2" /></span>{userTypeId / 1 === 8 ? "Cases" : " Trade Contract"}</h2>
                        </h1> */}
                            <div className="accordionItemContent">
                              {/* <div className="col-md-12"> */}
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg1">
                                      <span className="icon-pro"><i class="fas fa-money-bill-alt fa-lg"></i></span>
                                      <h4>{(dashData.planDetails ? dashData.planDetails.totalcount : 0)}</h4>
                                      <p>Total Transactions</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg2">
                                      <span className="icon-pro"><i class="fas fa-money-bill-wave fa-lg"></i></span>
                                      <h4>{(dashData.planDetails ? dashData.planDetails.refundcount : 0)}</h4>
                                      <p>Refunded Transactions</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg3">
                                      <span className="icon-pro"><i class="fas fa-list-ul fa-lg"></i></span>
                                      <h4>{(dashData.planDetails ? dashData.planDetails.pendingcount : 0)}</h4>
                                      <p>Pending Transactions</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg4">
                                      <span className="icon-pro"><i class="fas fa-tasks fa-lg"></i></span>
                                      <h4>{(dashData.planDetails ? dashData.planDetails.completecount : 0)}</h4>
                                      <p>Completed Transactions</p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                  <a href={userTypeId === 1 ? "javascript:void(0)" : "/"} style={{ "text-decoration": "none" }}>
                                    <div className="contract-bg bg5">
                                      <span className="icon-pro"><i class="fas fa-window-close fa-lg"></i></span>
                                      <h4>{(dashData.planDetails ? dashData.planDetails.declinecount : 0)}</h4>
                                      <p>Declined Transactions</p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>}
              {/* tabs end */}


              {userTypeId !== 20 && <div className="row mt-3">
                {tab == 3 && [5, 7, 10, 11, 18].includes(userTypeId / 1) ?
                  <div className="col-md-12">
                    <div className="pro-piechart">
                      {genStatsCard()}
                    </div>
                  </div>
                  :
                  <div className="col-md-8">
                    <div className="pro-piechart">
                      {genStatsCard()}
                    </div>
                  </div>
                }
                {/* contract stats pie chart end */}

                {/* commodities card start */}
                {/* {[1, 4, 14].includes(userTypeId / 1) ? */}
                {
                  // [4, 14].includes(userTypeId / 1)
                  // ?
                  //   <div className="col-md-6 pro-piechart-bg ">
                  //     <div className="pro-piechart">
                  //       <h3>Commodities</h3>
                  //       {(dashData && dashData.commodityDetails && dashData.commodityDetails.length) ?
                  //         <div className="row overflow-auto" style={{ height: '280px' }}>
                  //           {(dashData && dashData.commodityDetails && dashData.commodityDetails.length) ? dashData.commodityDetails.map(commodity => {
                  //             return (
                  //               <>
                  //                 <div className="col-md-4 mt-2">
                  //                   <div className="text-center">
                  //                     <img height='60' src={commImageUrl(commodity.filename)} alt="commodity" />
                  //                   </div>
                  //                   <div className="col-md-12 bg-light" >
                  //                     <h4 className="col-md-12" >{commodity.commodity_pretty_name}</h4>
                  //                     <p className="p-0 m-0"><b>Cat.:</b>{commodity.category}</p>
                  //                     <p className="p-0 m-0"><b>Qnt.:</b>{commodity.total_qty}</p>
                  //                   </div>
                  //                 </div>
                  //               </>
                  //             )
                  //           }) : ""}
                  //         </div> :
                  //         <p>No Commodities to show.</p>
                  //       }
                  //     </div>
                  //   </div> :
                  [5, 10, 11].includes(userTypeId / 1) && tab != 3 ?
                    <div className="col-md-6 pro-piechart-bg ">
                      <div className="pro-piechart">
                        <h3>Vessels</h3>
                        {(dashData && dashData.vesselDetails && dashData.vesselDetails.length) ?
                          <div className="row overflow-auto" style={{ height: '280px' }}>
                            {(dashData && dashData.vesselDetails && dashData.vesselDetails.length) ? dashData.vesselDetails.map((vessel, index) => {
                              return (
                                <>
                                  <div className="col-md-6 row mt-2 ml-1">
                                    <div className="col-md-4">
                                      <img src={"assets/images/dashboard/" + ((index & 1) ? 'ship1.png' : 'ship2.png')} height="80px" className="pr-2" />
                                    </div>
                                    <div className="col-md-8 bg-light" >
                                      <h4>{vessel.ship_name}</h4>
                                      <p className="p-0 m-0"><b>IMO:</b>{vessel.imo_no}</p>
                                      <p className="p-0 m-0"><b>Cat.:</b>{vessel.category}</p>
                                      <p className="p-0 m-0"><b>Typ.:</b>{vessel.type}</p>
                                    </div>
                                  </div>
                                </>
                              )
                            }) : ""}
                          </div> :
                          <p>No Vessels to show.</p>
                        }
                      </div>
                    </div> : ""}

                {(tab === 4) && [1].includes(userTypeId / 1) &&
                  <LineChart datapoints={userDataPoints} title="User's Daily Report" x_axis="Date" y_axis="Number of Users" />
                }

                {(tab === 3) && [1, 4, 14, 8, 19].includes(userTypeId / 1) &&
                  <>
                    <LineChart datapoints={numberOfBuyer} title="Number of Buyers" x_axis="Name of Lender" y_axis="Number of Buyers" />
                    <br />
                  </>
                }
                {/* commodities card end */}

              </div>}

              {/* finance grid */}
              {tab == 3 && ![5, 7, 10, 11, 18].includes(userTypeId / 1) &&
                <div className="row mt-3">
                  <div className="col-md-8">
                    <div className="pro-piechart">
                      <h3>Newly Requested Finance</h3>
                      <div class="table-responsive" style={{ height: "350px" }}>
                        <table className="table table-striped" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr style={{ fontSize: "14px" }}>
                              <th width="20">#</th>
                              <th width="30">Invoice Number</th>
                              <th width="30">Supplier Name</th>
                              <th width="30">Buyer Name</th>
                              <th width="30">Lender Name</th>
                              <th width="30">Amount</th>
                              <th width="30">Status</th>
                            </tr>
                          </thead>
                          {financeGrid.length > 0 && financeGrid.map((values, key) => {
                            return (
                              <tbody>
                                <tr style={{ fontSize: "14px" }}>
                                  <td >{key + 1}</td>
                                  <td>{values.reference_no}</td>
                                  <td>{values.suppliername}</td>
                                  <td>{values.buyername}</td>
                                  <td>{values.lendername}</td>
                                  <td >{values.amount}</td>
                                  <td style={{
                                    backgroundColor: (values.status === 1 && values.seller_status === 1 && values.lender_status === 1 && userTypeId != 8) ? 'rgba(255, 193, 7, 0.9)' :     //yellow
                                      (values.status === 1 && values.seller_status === 1 && values.lender_status === 1 && userTypeId === 8) ? 'rgba(9, 164, 170, 1)' :  //orange
                                        (values.status === 3 && values.seller_status === 1 && values.lender_status === 1 && userTypeId === 8) ? 'rgba(9, 164, 170, 1)' : //green
                                          (values.status === 3 && values.seller_status === 1 && values.lender_status === 1 && userTypeId != 8) ? 'rgba(63, 195, 128, 0.9)' :  //green
                                            (values.status !== 4 && values.seller_status === 1 && values.lender_status === 2) ? 'rgba(212, 222, 54)' : //green
                                              (values.status !== 4 && values.seller_status === 2 && values.lender_status === 1) ? "Approved By Seller" :
                                                (values.status !== 4 && values.seller_status === 2 && values.lender_status === 2) ? "Approved" :
                                                  (values.status === 4) ? "Disbursed" : '',
                                    color: 'white'
                                  }}>{(values.status === 1 && values.seller_status === 1 && values.lender_status === 1 && userTypeId != 8) ? "Applied" :
                                    (values.status === 1 && values.seller_status === 1 && values.lender_status === 1 && userTypeId === 8) ? "In-Progress" :
                                      (values.status === 3 && values.seller_status === 1 && values.lender_status === 1 && userTypeId === 8) ? "Offer Sent" :
                                        (values.status === 3 && values.seller_status === 1 && values.lender_status === 1 && userTypeId != 8) ? "Offer Received" :
                                          (values.status !== 4 && values.seller_status === 1 && values.lender_status === 2) ? "Approved By Lender" :
                                            (values.status !== 4 && values.seller_status === 2 && values.lender_status === 1) ? "Approved By Seller" :
                                              (values.status !== 4 && values.seller_status === 2 && values.lender_status === 2) ? "Approved" :
                                                (values.status === 4) ? "Disbursed" : ""}</td>
                                </tr>
                              </tbody>
                            )
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {tab === 4 &&
                <>
                  <br />
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <LineChart datapoints={userMonthPoints} title="User's Monthly Report" x_axis="Month of 2021" y_axis="Number of Users" />
                    </div>
                  </div>
                </>
              }

              {/* Channel Partner[Starts] -------------------------------------------------------------------------------------------------------- */}


              {userTypeId === 20 && !channelPartnerAccountList.modal && !channelPartnerRaisedInvoice.info.hasOwnProperty('financeType') ?
                <>
                  <div className="mx-4 bgCustom px-5 py-4 shadow-sm ">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <a href={'/accountlist'}>
                          {channelPartnerUsers.length > 0 && <button type="button" className="text-center btn btn-primary btn-sm m-1 float-right">View All</button>}
                        </a>
                        <h5>Account List</h5>
                      </div>
                    </div>
                    <AccountListTable TableData={channelPartnerUsers} TableHeadData={TableHeadData}>
                      {channelPartnerUsers.length > 0 ? channelPartnerUsers.map((key, index) => {
                        if (index < 5) {
                          return (
                            <tr key={index}>
                              <td><img src={avatarUrl(key.user_avatar ? key.user_avatar : '--')} alt="Avatar" width="32" height="32" /></td>
                              <td>{key.company_name}</td>
                              <td>{key.contact_person}</td>
                              <td>{key.no_of_buyers}</td>
                              <td>{key.user_address}</td>
                              <td><span className="shadow">
                                <ReactCountryFlag
                                  countryCode={key.country_code ? key.country_code : ''}
                                  style={{ width: '25px', height: '25px' }} svg />
                              </span></td>
                              <td>
                                <button className="btn btn-success btn-sm" onClick={() => { setChannelPartnerAccountList(true, { "userId": key.tbl_user_id, "userName": key.company_name, "userEmail": key.email_id }) }} >
                                  <i class="fas fa-folder-open"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      }) : <p className="pt-5">No Data is available</p>}
                    </AccountListTable>
                  </div>
                </>
                :
                (channelPartnerAccountList.modal && !channelPartnerRaisedInvoice.info.hasOwnProperty('financeType') &&
                  <FinanceDetailGrid userTokenDetails={userTokenDetails} manualUserId={channelPartnerAccountList.info.userId} manualUserName={channelPartnerAccountList.info.userName} manualUserEmail={channelPartnerAccountList.info.userEmail} />)
              }

              {userTypeId === 20 && !channelPartnerRaisedInvoice.modal && !channelPartnerAccountList.info.hasOwnProperty('userId') &&
                <>
                  <RaisedInvoices userTokenDetails={userTokenDetails} channelPartnerRaisedInvoice={channelPartnerRaisedInvoice} />
                  {/* <DisbursedInvoices userTokenDetails={userTokenDetails} /> */}
                </>
              }

              {userTypeId === 20 && channelPartnerRaisedInvoice.modal && !channelPartnerAccountList.info.hasOwnProperty('userId') &&
                <>
                  {channelPartnerRaisedInvoice.info.financeType == 2 &&
                    <LCFinance
                      userTokenDetails={userTokenDetails} />}
                  {channelPartnerRaisedInvoice.info.financeType == 3 &&
                    <InvoiceFinance
                      userTokenDetails={userTokenDetails} />}
                </>}



              {/* Channel Partner[Ends] ----------------------------------------------------------------------------------------------------------- */}

              {/* payments Stats */}
              {(dashData && dashData.paymentDetails) &&
                (([4, 14].includes(userTypeId / 1) && tab === 0) ||
                  ([5, 10, 11].includes(userTypeId / 1) && tab === 1) ||
                  ([1].includes(userTypeId / 1) && tab === 5)) &&
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="pro-piechart">
                      <h3>{([4, 14].includes(userTypeId / 1) && tab === 0) ? "Commodity Trade Contract " :
                        ([5, 10, 11].includes(userTypeId / 1) && tab === 1) ? "Shipment Contract " :
                          ([1].includes(userTypeId / 1) && tab === 5) ? "Plan " : ""}Payments Statistic</h3>
                      <ContractLineChart data={dashData.paymentDetails} />
                      <br />
                    </div>
                  </div>
                </div>}

            </div>
            {kycAction.modal &&
              (dashData && dashData.userDetails) &&
              (dashData.userDetails.company_gst_verification !== "true" || dashData.userDetails.company_iec_verification !== "true") &&
              <div className={"modal show"} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Verification</h4>
                      <button type="button" className="close" onClick={() => { setkycAction({ modal: false, data: {} }) }} >×</button>
                    </div>
                    <div className="modal-body">

                      <div className="col-md-12 text-center">
                        <h3> KYC Verification Alert </h3>
                      </div>
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-md-12 text-center">
                            <label className="form-check-label text-danger">{`Hey "${dashData.userDetails.company_name}" Your KYC is not verified. Please complete your KYC process.`}</label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="modal-footer primary">
                      <a href={'/editprofile'}>
                        <button type="button" className="btn btn-info btn-sm" onClick={() => setkycAction({ modal: false, data: {} })} >OK</button>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            }
          </main>
        </div>
      </div >
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}


const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    channelPartnerAccountList: state.channelPartnerAccountList,
    channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChannelPartnerAccountList: (modal, info) => { dispatch(setChannelPartnerAccountList({ modal: modal, info: info })) },
    setChannelPartnerDisbursedInvoice: (modal, info) => { dispatch(setChannelPartnerDisbursedInvoice({ modal: modal, info: info })) },
    setChannelPartnerRaisedInvoice: (modal, info) => { dispatch(setChannelPartnerRaisedInvoice({ modal: modal, info: info })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
