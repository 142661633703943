import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { isEmpty, most_used_currencies } from '../../utils/myFunctions'
import { InputWithSelect, NewInput } from '../../utils/newInput'
import toastDisplay from '../../utils/toastNotification'
import { PopupMessage } from '../popupMessage'


const AddBuyerHealth = ({ countrydata }) => {
  const [data, setData] = useState({
    isChecked: false
  })
  const [errors, setErrors] = useState({})
  const [showMsgPopup, toggleMsgPopup] = useState(false)
  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }
  const handleValidations = () => {
    if (data.isChecked) {
      let errors = {}
      if (!data.contact_person) {
        errors.contact_person = 'Contact Name cannot be empty'
      }
      if (!data.nameprefix) {
        errors.contact_person = 'Contact Prefix cannot be empty'
      }
      if (!data.company_name) {
        errors.company_name = 'Company Name cannot be empty'
      }
      if (!data.contact_number) {
        errors.contact_number = 'Contact Number cannot be empty'
      }
      if (!data.phonecode) {
        errors.contact_number = 'Phone Code cannot be empty'
      }
      if (!data.email_id) {
        errors.email_id = 'Email ID cannot be empty'
      }
      if (!data.pan_no) {
        errors.pan_no = 'PAN Number cannot be empty'
      }
      if (!data.postal_code) {
        errors.postal_code = 'Postal Code cannot be empty'
      }
      if (!data.annual_turonver) {
        errors.annual_turonver = 'Annual Turnover cannot be empty'
      }
      if (!data.currency_code) {
        errors.annual_turonver = 'Currency cannot be empty'
      }
      if (isEmpty(errors)) {
        //do api call
        toggleMsgPopup(true)
      } else {
        setErrors(errors)
      }
    } else {
      toastDisplay("Please provide consent for the authenticity of the information", "info")
    }

  }
  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showMsgPopup ? (
        <PopupMessage
          onClose={() => {
            toggleMsgPopup(false)
          }}
          title={'Application for buyer health of “Rosti Suzhou Integrated” has been submitted'}
          msg={`You will receive the credit report from Dun & bradstreet at the email provided by you.`}
        />
      ) : null}
      <div className="col-md-11 mx-auto mt-4">
        <div className="p-4 card border-0 rounded-3 ">
          <div className='d-flex flex-row align-items-center'>
            <a className="cursor"
              onClick={() => { window.location = "/buyerManagement" }}
            ><img
                src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
            <p className="font-size-16 ml-2 mr-0 my-0 font-wt-600" >{"Credit Report from "}</p>
            <p className='font-size-16 ml-2 mr-0 my-0 font-wt-600 text-color1'>Dun & bradstreet</p>
          </div>
          <div className='col-md-10 py-4 my-2'>
            <div className='row'>
              <div className='col-md-6'>
                <div className="col-md-12 px-0">
                  <InputWithSelect isAstrix={false} type={"text"} label={"Contact Person Name"}
                    selectData={[{ name: "Mr" }, { name: 'Miss' }]}
                    selectName={"nameprefix"} selectValue={data.nameprefix}
                    optionLabel={"name"} optionValue={'name'}
                    name={'contact_person'} value={data.contact_person} error={errors.contact_person}
                    onChange={handleChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"Company Name"}
                    name={"company_name"} value={data.company_name} error={errors.company_name}
                    onChange={handleChange} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="col-md-12 px-0">
                  <InputWithSelect isAstrix={false} type={"number"} label={"Contact No."}
                    selectData={countrydata}
                    selectName={"phonecode"} selectValue={data.phonecode}
                    optionLabel={"phonecode"} optionValue={'phonecode'}
                    name={'contact_number'} value={data.contact_number} error={errors.contact_number}
                    onChange={handleChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"email"} label={"Email Id"}
                    name={"email_id"} value={data.email_id} error={errors.email_id}
                    onChange={handleChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"PAN No."}
                    name={"pan_no"} value={data.pan_no} error={errors.pan_no}
                    onChange={handleChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 px-0">
                  <NewInput isAstrix={true} type={"text"} label={"Postal Code"}
                    name={"postal_code"} value={data.postal_code} error={errors.postal_code}
                    onChange={handleChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 px-0">
                  <InputWithSelect isAstrix={false} type={"text"} label={"Annual Turnover"}
                    selectData={most_used_currencies}
                    selectName={"currency_code"} selectValue={data.currency_code}
                    optionLabel={"code"} optionValue={'code'}
                    name={'annual_turonver'} value={data.annual_turonver} error={errors.annual_turonver}
                    onChange={handleChange} />
                </div>
              </div>
            </div>
            <div className='mt-5'>
              <div className='d-flex flex-row align-items-center'>
                <img className='mr-4 cursor' src={data.isChecked ? '/assets/images/checked-green.png' : '/assets/images/unchecked-box.png'} height={20} width={20} onClick={() => setData({ ...data, isChecked: !data.isChecked })} />
                <p className="font-size-16 ml-2 mr-0 my-0 font-wt-500" >{"I hereby declare that the above given information by me is true to my knowledge."}</p>
              </div>
            </div>
            <div className="d-flex gap-4 mt-2">
              <button className={`my-4 new-btn w-20 py-2 px-2 text-white cursor`} onClick={handleValidations}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBuyerHealth