import React, { useEffect, useState } from 'react';

const items = [
  "Item Name",
  "Reference Number",
  "Customer Name",
  "State",
  "Items Group",
  "Customer Group",
  "Continent",
  "Item Category"
];

const TypingAnimation = () => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (isTyping) {
      if (charIndex < items[currentIndex].length) {
        timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + items[currentIndex][charIndex]);
          setCharIndex((prev) => prev + 1);
        }, 100);
      } else {
        timeout = setTimeout(() => {
          setIsTyping(false);
        }, 1000); // Wait 1 second before starting to backspace
      }
    } else {
      if (charIndex > 0) {
        timeout = setTimeout(() => {
          setDisplayedText((prev) => prev.slice(0, -1));
          setCharIndex((prev) => prev - 1);
        }, 100);
      } else {
        timeout = setTimeout(() => {
          setIsTyping(true);
          setCurrentIndex((prev) => (prev + 1) % items.length);
        }, 500); // Wait 0.5 sec
      }
    }

    return () => clearTimeout(timeout);
  }, [charIndex, isTyping, currentIndex]);

  return (
    <div>
      <h5 style={{ color: "blue", opacity: "0.8" }}>{displayedText}</h5>
    </div>
  );
};

export default TypingAnimation;
