import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link } from "react-router-dom";
import useForm from '../../utils/useForm';
import { removeCookieandAvatar, setCookieInAPP } from '../../utils/cookieHelper';
import ResetPassword from './resetPassword';
import validate from '../../utils/validation_files/LoginFormValidationRules';
import validateRequest from '../../utils/validation_files/ChannelPartnerRequestValidation';
import toastDisplay from '../../utils/toastNotification';
import { decryptData, encryptData } from '../../utils/myFunctions';
import { getUserDataFromCookie } from '../../utils/cookieHelper';
import { ToastContainer } from 'react-toastify';
// import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import ChannelPartnerRegistration from '../admin/channelPartnerRegistration';

const queryString = require('query-string');

const ChannelPartnerDashboard = () => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  var [showLoader, setshowLoader] = useState(false);
  var [hide, sethide] = useState(true);
  var [forgotPasswordView, setForgotPasswordView] = useState(0);
  const [passwordForgot, setpasswordForgot] = useState(false);
  const [openChannelPartner, setOpenChannelPartner] = useState({ modal: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reqError, setReqError] = useState({});
  const [data, setData] = useState({});

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(ChannelPartnerDashboard, null, validate);

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    if (Object.keys(reqError).length === 0 && isSubmitting) {
      submitRequest();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [reqError]);

  useEffect(() => {
    let mkt_ref_id = window.location.href.split("mkt_ref=")[1]
    if (mkt_ref_id) {
      localStorage.setItem("mkt_ref_id", mkt_ref_id)
    }
    let tradefin_ref = window.location.href.split("tradefin_ref=")[1];
    if (tradefin_ref) {
      removeCookieandAvatar()
      localStorage.clear()
      let user_credentials = JSON.parse(decryptData(tradefin_ref));
      values.email = user_credentials.username
      values.password = user_credentials.password
      ChannelPartnerDashboard(user_credentials)
    }
    //reading querystring to check queries if any
    let query = queryString.parse(window.location.search);
    if (query.resetPass && query.id) {
      setForgotPasswordView(1)
    }


    //------------------------------------------------------------------
    //User details from cookie
    let userDetails = getUserDataFromCookie()
    let userTypeId = userDetails.type_id ? userDetails.type_id : null
    let userToken = userDetails.token ? userDetails.token : null
    let status = userDetails.status ? userDetails.status : null
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //Redirect if user already logged in
    if ((userToken !== undefined && userToken !== null) && (userTypeId !== undefined && userTypeId !== null) && (status !== undefined && status !== null)) {
      redirectFunc(userTypeId, status)
    } else {
      sethide(false)
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  const handleRequest = (event) => {
    if (event) event.preventDefault();
    setReqError(validateRequest(data));
    setIsSubmitting(true);
  };

  const handleReqChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  async function ChannelPartnerDashboard(payload) {
    setshowLoader(true)
    let loginPayload = {}
    if (payload) {
      loginPayload = payload
    }
    else {
      loginPayload = {
        username: values.email,
        password: values.password
      };
      if (loginPayload.password) {
        loginPayload.password = encryptData(loginPayload.password)
      }
    }
    loginPayload.userTypeId = 20;
    await call('post', 'login', loginPayload).then((result) => {
      if (result) {
        console.log("result in login==>", result)
        setCookieInAPP(result)
        redirectFunc(result.type_id, result.status)
      }
      setshowLoader(false)
    }).catch(e => {
      setshowLoader(false)
      console.log("Error in login====>", e)
      setpasswordForgot(true)
      toastDisplay(e, "error");
    })
  }
  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  var redirectFunc = (redirectTo, regStatus) => {
    // console.log('redirectTo,regStatus===>', redirectTo, regStatus)

    if (regStatus / 1 === 1)
      window.location = "/dashboard"
    else
      toastDisplay("Admin verification is pending, Please try later or contact administrator !!", "warn");

  }
  //---------------------------------------------------------------------------------------------------------------------

  //forgetPassword views:

  function submitRequest() {
    call('POST', 'submitChannelRequest', data).then((result) => {
      if (result) {
        toastDisplay("Request raised successfully", "success");
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="col-xl-7 col-lg-8 col-md-11 col-sm-11 bg-white shadow mx-auto login">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row my-3">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 m-auto">
            <img src="assets/images/login.webp" alt="" className="img-fluid" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="col-md-6">
              <a href={'/'}><img height="80" src="../assets/images/logos/logo_1.png" /></a>
            </div>
            <div className="authfy-heading">
              <br />
              <h2 className="auth-title text-center"><b>Login as a Channel/Franchise Partner</b></h2>
            </div>
            <div className="col-10 mt-5">
              <div className="form-floating mb-3">
                <label htmlFor="floatingInputGrid">Email</label>
                <input
                  type="text"
                  className="form-control InputCustom"
                  id="Email"
                  placeholder="Email"
                  name="email"
                  defaultValue={data.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-10">
              <div className="form-floating mb-3">
                <label htmlFor="floatingInputGrid">Password</label>
                <input
                  type="password"
                  className="form-control InputCustom"
                  id="Password"
                  placeholder="Password"
                  name="password"
                  defaultValue={data.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-check mt-5 mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Remember Me
              </label>
            </div>
            <button className="btn btnCustom px-4" onClick={(event) => { handleSubmit(event); }} noValidate >Login In</button>
          </div>
        </div>
        <div className="d-flex mt-5 align-items-center">
          <span
            className="col-xl-5 col-lg-5 col-md-6 col-sm-6 m-auto cursor m-auto text-center text-decoration-underline">{" "}
          </span>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className='col-md-12'>
              <h3><pre className="auth-description">Don’t have an account? <Link onClick={() => { setOpenChannelPartner({ modal: true }) }}>Sign Up </Link></pre></h3>
            </div>
            {/* or login with{" "}
            <span className="">
              {" "} */}
            {/* <FaFacebookSquare className="fs-4 me-2 textinfo rounded-3" />
              <FaTwitterSquare className="fs-4 me-2 textinfo rounded-3" />
              <FaLinkedin className="fs-4 me-2 textinfo rounded-3" /> */}
            {/* </span> */}
          </div>
        </div>
        {openChannelPartner.modal &&
          <div className={"modal show"}>
            <div className="modal-dialog modal-xl border-inner" id="parent_class">
              <div className="modal-content">
                <div className="modal-header primary">
                  <h4 className="modal-title text-white">Raise a Request</h4>
                  <button type="button" className="close" onClick={() => { setOpenChannelPartner({ modal: false }) }} >×</button>
                </div>
                <ChannelPartnerRegistration userTokenDetails={''} adminProfile={false} status={1}
                  onClose={() => { setOpenChannelPartner({ modal: false }) }} />
                <div className="modal-footer primary">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => setOpenChannelPartner({ modal: false })} >Cancel</button>
                </div>

              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
};

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerDashboard)