import moment from "moment";
import React, { useState } from "react";
import { Calendar } from "react-calendar";
import 'react-calendar/dist/Calendar.css';

const CalendarTab = ({ value, onChange, calendardata }) => {

  console.log('val;ueeeee', value);
  return (
    <>
      <div className="row gap-4">
        <Calendar onChange={onChange} value={value} className=" borderRadius border-0 col-md-12 col-md-6 px-0 " next2Label={null} prev2Label={null} selectRange={false} calendarType={"US"} />
        <div className="col-md-6">

          <div>
            <p className='font-size-13 font-wt-600 lineheight19 text-color-0C0C0C mt-3'>{`Reminders on ${moment(value).format('DD-MM-YYYY')}`}</p>
            {calendardata.length === 0 &&
              <p className='text-center text-secondary font-wt-500 font-size-13'>No reminder for Today !</p>
            }
          </div>
          <div className='videolinklist pe-2'>
            {calendardata.map(item => {
              return <div>
                <p className='border-bottom lineheight19 font-size-14 pb-1 d-flex justify-content-between gap-3'><span className='videolink'>{`${item.EVENT_TYPE} - ${moment(item.EVENT_TIME).format('HH:MM A')} - ${item.EXPORTER_NAME}`}</span> <span><img src={"/assets/images/open-link.png"} className="cursor" alt="" onClick={() => window.open(`/calllist?date=${moment(item.EVENT_TIME).format('YYYY-MM-DD')}`)} /></span></p>
              </div>
            })}
          </div>
        </div>
      </div>

    </>
  );
}
export default CalendarTab;