import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { NewTable } from "../../../utils/newTable";
import HeaderV2 from "../../partial/headerV2";
import SideBarV2 from "../../partial/sideBarV2";
import Filter from "../components/Filter";
import config from '../../../config.json';
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { convertImageToPdf, getDocDetails, SetCache, ClearCache, most_used_currencies, GetCache, roundOfNumberInDecimal, dataURItoBlob } from "../../../utils/myFunctions";
import Pagination from "./components/pagination";
import moment from "moment";
import TooltipModal from "../components/Tooltipmodal";
import { ToastContainer } from 'react-toastify';
import ChatBoxPopUp2 from "../../chatRoom/components/ChatBoxPopUp2";
import ColumnSelector from "../../lcV2/qoutes/columnselector";



const Contract = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [tooltip, setTooltip] = useState(false);

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [applicationCounts, setApplicationCounts] = useState({})

  const [Column, setColumns] = useState()
  const [totalBuyersData, setTotalBuyersData] = useState([])
  const [filteredSearch, setFilteredSearch] = useState([])
  useEffect(() => {
    loadApplicationCounts()
  }, [])


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is buyermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("invoicecontract");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");

  async function loadApplicationCounts() {
    setshowLoader(true)
    let apiResp = await call("POST", 'getIDTermsheetCounts', { userId })
    setApplicationCounts({ ...apiResp })
    setshowLoader(false)
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('invApplicationId', selectedChat.invApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  // useEffect(() => {
  //   call('POST', 'getInvoiceTermsheetFilter', { userId }).then(res => {
  //     console.log("getInvoiceTermsheetFilter then", res);
  //     setFilterData(res)
  //   }).catch(err => { })
  // }, [])
  const [columnVisibility, setColumnVisibility] = useState([
    { name: 'Date', isChecked: true },
    { name: "Buyer Name", isChecked: true },


    { name: "Terms of Payment", isChecked: true },
    { name: "Financer Selected", isChecked: true },
    { name: "Limit Available", isChecked: true },
    { name: "Action", isChecked: true },
    { name: "Time Left", isChecked: true },
    { name: "Buyer Email", isChecked: false },
    { name: "Required Limit", isChecked: false },
    { name: "Buyer Address", isChecked: false },
    { name: "Country Name", isChecked: false },
    { name: "Expected Shipment Date", isChecked: false },
    { name: "Current Annual Sale", isChecked: false },


  ]);


  //  useEffect(() => {
  //   call('POST', 'getBuyersDetail', { userId }).then(res => {
  //     console.log("getBuyersDetail then", res);
  //     setFilterData(res)
  //   }).catch(err => { })
  // }, [])



  useEffect(() => {
    call('POST', 'getInvoiceTermsheetFilter', { userId }).then(res => {
      console.log("getInvoiceTermsheetFilter then", res);
      setFilterData(prevFilterData => ({
        ...prevFilterData,
        ...res
      }));
    }).catch(err => { });
  }, []);

  useEffect(() => {
    if (!Object.keys(filterData)?.length) {
      call('POST', 'getApplyForQuoteFilter', { userId }).then(res => {
        console.log("getApplyForQuoteFilter then", res);
        res["Grid Columns"] = { accordianId: 'GridColumns', data: [], labelName: 'name', type: 'checkbox', isAllChecked: true };
        // Assuming columnVisibility is an array of objects with 'name' property
        for (let index = 0; index < columnVisibility.length; index++) {
          const element = columnVisibility[index];
          res["Grid Columns"].data.push({ name: element.name, isChecked: element.isChecked });
        }
        setFilterData(prevFilterData => ({
          ...prevFilterData,
          ...res
        }));
        setFilteredSearch(prevFilterData => ({
          ...prevFilterData,
          ...res
        }))
      }).catch(err => { });
    }
  }, [filterData, columnVisibility]);


  console.log(filterData, "here's thee filter dataaa-----///.....")

  useEffect(() => {
    setshowLoader(true)
    //Get Buyer list start
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      onlyAppliedForInvoiceContract: true,
      ...filter
    }
    // for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
    //   let filterName = Object.keys(filterData)[index]
    //   const element = filterData[filterName];
    //   if (element.isFilterActive) {
    //     if (element.type === "checkbox") {
    //       objectAPI[element.accordianId] = []
    //       element["data"].forEach((i) => {
    //         if (i.isChecked) {
    //           objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
    //         }
    //       })
    //     }
    //     else if (element.type === "minMaxDate") {
    //       objectAPI[element.accordianId] = element["value"]
    //     }
    //   }
    // }
    setshowLoader(true)
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push(element.accordianId === "hsCodes" ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMax") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);

      setFilter(result)
      setDbData(result.buyerData);
      setTableData(formatDataForTable(result.buyerData))
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getBuyersDetail', e);
      setshowLoader(false)
    });
    //Get buyer list end
  }, [refresh, page, filterData])


  function formatDataForTable(data) {
    let tableData = []
    let row = []
    data.forEach((item, index) => {
      let isRejected = item.selectedQuote.deniedstage && (item.selectedQuote.deniedstage === "send_term" || item.selectedQuote.deniedstage === "sign_term")
      row[0] = moment(item.applicationUpdatedAt).format('DD/MM/YYYY')
      row[1] = item.buyerName

      row[2] = item.termsOfPayment
      row[3] = item.selectedQuote.financeLimit ? (roundOfNumberInDecimal(item.selectedQuote.financeLimit) + " " + item.selectedQuote.financeLimitCurrency) : "NA"

      let selectedLenderName = item.selectedQuote.lender_name
      let selectedLenderId = item.selectedQuote.lender_id
      let chatRoomIds = item.chatRoomIds?.split(",") || []
      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []
      let openChatRoomIndx = null

      chatRoomUsers.forEach((u, i) => {
        if (u?.split("::")[1] / 1 == selectedLenderId / 1) {
          openChatRoomIndx = i
        }
      })

      row[4] = <div
        className='flex-row position-relative'
      >
        <img className='cursor'
          onClick={async () => {
            let reqObj = {
              senderId: userId,
              receiverId: selectedLenderId,
              textMessage: 'Hii',
              chat_room_name: "CHAT" + new Date().getTime(),
              includeAdmins: true,
              invApplicationId: item.applicationId,
              receiverParties: selectedLenderId,
              dontSendInitialMsg: true
            }
            setshowLoader(true)
            let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
            setshowLoader(false)
            getChatDetails({
              chat_room_id: apiResp.id,
              loggedInUser: userId
            })
            setSelectedChat({
              chatRoomId: apiResp.id,
              receiverName: `${selectedLenderName} - ${chatRoomIds[openChatRoomIndx] || apiResp.id}`,
              invApplicationId: item.applicationId,
              parties: chatRoomUsers[openChatRoomIndx] || apiResp.parties,
              userId: userId,
              isChatOpen: true,
              receiverId: selectedLenderId
            })
          }}
          src={chatRoomIds[openChatRoomIndx] ? `assets/images/comment_filled.png` : `assets/images/chat.png`} />
        <label className='p-1 mx-1 font-size-13 font-wt-400' >{selectedLenderName}
          <span className='text-color1 mx-2' ><u>{chatRoomUnreadMsgCount[openChatRoomIndx] / 1 ? (chatRoomUnreadMsgCount[openChatRoomIndx] < 10 ? `0${chatRoomUnreadMsgCount[openChatRoomIndx]}` : chatRoomUnreadMsgCount[openChatRoomIndx]) : null}</u></span></label>
      </div>
      if (isRejected) {
        row[99] = <p className="font-size-12 text-color-value ml-3">
          <img src={"assets/images/warning.png"} alt="info" className="" /> <span className="mr-2"><b>Application rejected by financier</b> </span>
          <label
            onClick={() => { setTooltip(true); setData({ ...data, showRemark: item.selectedQuote.deniedRemark, showLenderName: item.selectedQuote.lender_name }) }}
            className="text-color1 text-decoration-underline cursor">View Remark</label>
        </p>
      }
      let threeDaysFromUpdationDate = moment(item.applicationUpdatedAt).add(4, "days")
      let daysLeft = threeDaysFromUpdationDate.clone().diff(moment(), "days")

      row[5] = (daysLeft / 1 > 0) ? <p
        class={`${daysLeft == 3 ? 'text-success' : daysLeft == 2 ? 'text-F1C40F' : 'text-danger'} mb-0 font-size-13`}>
        {daysLeft + `${daysLeft == 1 ? ' day' : ' days'}`} <img src={'/assets/images/hourglass-not-done.png'} alt='hourglass' />
      </p> : "-";

      row[6] = item.buyerEmail
      row[7] = item.requiredLimit

      row[8] = item.buyerAddress
      row[9] = item.countryName || ""
      row[10] = item.expShipmentDate || ""
      row[11] = item.currentAnnualSale || ""


      row[12] =
        <div >
          {isRejected ? (
            <a>

              <button type="button"
                onClick={() => {
                  window.location = `/seequotes?buyer=${item.id}`;
                  localStorage.setItem("applicationId", item.applicationId)
                  localStorage.setItem("invIfAppliedNo", item.invRefNo)
                }}
                className={` border-0 mb-2  text-white enableQuotebtn `}>
                {"See Quotes"}
              </button>
              <p className="font-size-12 text-color-value ml-3">
                <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-600 text-danger">Your application is rejected by financier.</span>
                <p className="mt-2" >Continue with other financier by clicking on "See Quotes" button above</p>
              </p>
            </a>
          ) : (
            <a className="" >
              <button type="button"
                onClick={() => {
                  if (booladd) {


                    window.location = `/signContract?buyer=${item.id}`
                    localStorage.setItem("selectedLenderId", item.selectedLenderId)
                    localStorage.setItem("selectedLenderName", item.selectedQuote.lender_name)
                    localStorage.setItem("termSheet", item.termSheet)
                    localStorage.setItem("termSheetLink", item.termSheetLink)
                    localStorage.setItem("applicationId", item.applicationId)
                    localStorage.setItem("onlyViewMode", item.termSheetSignedByExporter ? true : "")
                    localStorage.setItem("termSheetSignType", item.termSheetSignType)
                  }
                }}
                disabled={!item.termSheet || item.termSheetSignedByExporter}
                className={` border-0 mb-2 ${(!item.termSheet || item.termSheetSignedByExporter) ? "disablesigncontract text-dark" : " enablesigncontract text-white  "}`}>
                {item.termSheetSignedByExporter ? "Termsheet Signed" : "Sign Termsheet"}
              </button>
              {!item.termSheet ?
                <p className="font-size-12 text-color-value ml-3">
                  <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Waiting for termsheet, from financier</span>
                </p>
                : item.termSheetSignedByExporter && !item.termSheetSignedByFinancier ?
                  <p className="font-size-12 text-color-value ml-3">
                    <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Waiting for financier to sign termsheet</span>
                  </p>
                  : item.termSheetSignedByFinancier ?
                    <p className="font-size-12 text-color-value ml-3">
                      <img src={"assets/images/warning.png"} alt="info" className="me-1" /> <span className=" font-wt-500">Termsheet signed by financier</span>
                    </p>
                    : null}
            </a >

          )}
        </div >
      tableData.push(row)
      row = []
    })
    return tableData
  }



  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }


  async function handleValidation(tabIndex) {

    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
      else {
        return toastDisplay("Click on continue button to go to next form", "info")
      }
    }

    let validateFields = []
    let err = {}
    if (tab === 0) {
      validateFields = ["buyerName", "buyerCountry"]
    }

    if (tab === 2) {
      validateFields = ["buyerName", "buyerCountry", "buyerAddress", "buyerPostalCode",
        "buyerPrevSale", "buyerExpectedSale", "buyerIncoTerms", "buyerTermsPayment", "buyerProductDetails"]
    }

    if (tab === 3) {
      validateFields = ["invoiceDocument", "poDocument"]
    }

    // if (tab === 4) {
    //   if (!data.selectedFinanciers.length) {
    //     return toastDisplay("Select atleast 1 financier", "info")
    //   }
    // }

    validateFields.forEach((item) => {
      if (!data[item]) {
        err[item] = "Mandatory Field"
      }
    })

    if (!Object.keys(err).length) {
      if (tab != 4) {
        if (tab === 0) {
        }
        setTab(tab + 1)
      }
      else {
      }
    }
    setErrors(err)
  }

  const handleFile = event => {
    event.persist()
    if (!event.target.files.length) {
      return null
    }
    else {
      let file_type = event.target.files[0]["type"].toLowerCase()
      if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
        setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
        return
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0]
        let fileDataUrl = e.target.result
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
          console.log("pdfconversionresp", response);
          fileObj = response["file"]
          fileDataUrl = response['dataUrl']
          toastDisplay("File converted into pdf format", "success")
        }
        if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          try {
            setshowLoader(true)
            let formData = new FormData()
            formData.append("file", fileObj)
            if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              formData.append("docType", "docx")
            }
            if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              formData.append("docType", "xlsx")
            }
            let apiResp = await call('POST', "docToPdf", formData)
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
            fileDataUrl = apiResp
            toastDisplay("File converted into pdf format", "success")
          } catch (error) {
            return toastDisplay("failed to convert file", "error")
          }
          setshowLoader(false)
        }
        setData({ ...data, [event.target.name]: fileObj })
        setErrors({ ...errors, [event.target.name]: "" });
      }
    }
  }

  const handleSelectedFinanciers = (id) => {
    let temp = data.selectedFinanciers
    if (temp.includes(id)) {
      temp = temp.filter(e => e !== id)
    }
    else {
      temp.push(id)
    }
    setData({ ...data, selectedFinanciers: temp })
  }

  const handleMultiSelectchange = (e, name, val) => {
    setData({
      ...data,
      [name]: Array.isArray(e) ? e.map((x) => x[val]) : []
    });
  };

  const handleKeyDown = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setRefresh(refresh + 1)
    }
  };

  function openApplication(index) {
    let item = dbData[index]
    let isRejected = item.selectedQuote.deniedstage && (item.selectedQuote.deniedstage === "send_term" || item.selectedQuote.deniedstage === "sign_term")
    if (!isRejected) {
      window.location = `/signContract?buyer=${item.id}`
      localStorage.setItem("selectedLenderId", item.selectedLenderId)
      localStorage.setItem("selectedLenderName", item.selectedQuote.lender_name)
      localStorage.setItem("termSheet", item.termSheet)
      localStorage.setItem("termSheetLink", item.termSheetLink)
      localStorage.setItem("applicationId", item.applicationId)
      localStorage.setItem("onlyViewMode", item.termSheetSignedByExporter ? true : "")
      localStorage.setItem("termSheetSignType", item.termSheetSignType)
    }
    else {
      toastDisplay("Your termsheet application has been rejected", "info")
    }
  }

  function onChatWithFinancier() {
    window.location = '/ChatRoomV2'
  }


  const updateColumns = (newColumns) => {
    setColumns(newColumns)
  };

  const updateData = (tableData) => {
    setData(data)
  }

  return (
    <>
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {selectedChat.isChatOpen && (
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() =>
              getChatDetails({
                chat_room_id: selectedChat.chatRoomId,
                loggedInUser: userId,
              })
            }
            onPopupClose={() => {
              setSelectedChat({
                receiverName: "",
                receiverId: "",
                userId: userId,
                isChatOpen: false,
                logo: "",
              });
              setRefresh(refresh + 1);
            }}
          />
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <SideBarV2
            state="invoicecontract"
            userTokenDetails={userTokenDetails}
          />
          <main
            role="main"
            className={
              "ml-sm-auto col-lg-10 " +
              (navToggleState.status ? " expanded-right" : "")
            }
            id="app-main-div"
          >
            <HeaderV2
              title={"Invoice Discounting > Termsheet"}
              userTokenDetails={userTokenDetails}
            />

            <div className='d-flex row justify-content-between px-2' >
              <div className='card p-4 ' style={{ width: '35.22%' }} >
                <label className='font-size-16 font-wt-500 color0C0C0C' >Application in process - <label className='text-color1 font-size-16 font-wt-600' >{applicationCounts.total}</label></label>
                <div className='d-flex row' >
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.waitingForTermsheet || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Waiting for Termsheet</p>
                  </div>
                  <div className='w-auto' >
                    <p className='font-size-22 font-wt-600 text-color1 p-0 m-0' >{applicationCounts.sign || 0}</p>
                    <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0'>Sign Termsheet</p>
                  </div>
                </div>
              </div>
              <div
                className='card  pl-4 d-flex justify-content-center cursor' style={{ width: '19.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text2ECC71 p-0 m-0' >{applicationCounts.approved || 0}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Approved</p>
                </div>
              </div>
              <div
                className='card  pl-4 d-flex justify-content-center cursor' style={{ width: '19.44%' }} >
                <div>
                  <p className='font-size-22 font-wt-600 text-color-E74C3C p-0 m-0' >{applicationCounts.rejected || 0}</p>
                  <p className='font-size-15 font-wt-400 color0C0C0C p-0 m-0 mt-3'>Rejected</p>
                </div>
              </div>
              <div style={{ width: "19.22%" }}></div>
            </div>

            <div>
              {/* <a className="cursor"><img src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a> */}
              {/* <Filter filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh} /> */}
              <div className="filter-div">
                <Filter
                  filterData={filterData}
                  setFilterData={setFilterData}
                  showFilterBtn={true}
                  setFilteredSearch={setFilteredSearch}
                  filteredSearch={filteredSearch}
                  showResultPerPage={true}
                  count={count}
                  filter={filter}
                  setFilter={setFilter}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
                <ColumnSelector
                  filterData={filterData}
                  setFilterData={setFilterData}
                />
              </div>
            </div>
            <NewTable
              filterData={filterData}
              setFilterData={setFilterData}
              filteredSearch={filteredSearch}
              setFilteredSearch={setFilteredSearch}
              // filter={filter}
              // setFilter={setFilter}
              expand={[]}
              tableExpand={[]}
              expandKey={"id"}
              columns={[
                {
                  name: "Date",
                  filter: true,
                  filterDataKey: "Date",
                  sort: [
                    {
                      name: "Latest First",
                      selected: filter.sortDateBy === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortDateBy: "DESC",
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Oldest First",
                      selected: filter.sortDateBy === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortDateBy: "ASC",
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },

                {
                  name: "Buyer Name",
                  filter: true,
                  filterDataKey: "Buyer Name",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortBuyerName === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortBuyerName: "ASC",
                          sortDateBy: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortBuyerName === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortBuyerName: "DESC",
                          sortDateBy: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Terms of Payment",
                },
                {
                  name: "Limit Available", filter: true,
                  filterDataKey: "Limit Available",
                  sort: [
                    {
                      name: "Sort Low to High",
                      selected: filter.sortLimitAvailable === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortLimitAvailable: "ASC",
                          sortDateBy: false,
                          sortBuyerName: false,
                          sortFinancerName: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort High to Low",
                      selected: filter.sortLimitAvailable === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortLimitAvailable: "DESC",
                          sortFinancerName: false,
                          sortDateBy: false,
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Financer Selected",
                  filter: true,
                  filterDataKey: "Financer Selected",
                  sort: [
                    {
                      name: "Sort A-Z",
                      selected: filter.sortFinancerName === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortFinancerName: "ASC",
                          sortDateBy: false,
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Sort Z-A",
                      selected: filter.sortFinancerName === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortFinancerName: "DESC",
                          sortDateBy: false,
                          sortBuyerName: false,
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                {
                  name: "Time Left", filter: true,
                  filterDataKey: "Time Left",
                  sort: [
                    {
                      name: "Latest First",
                      selected: filter.sortTimeLeftBy === "DESC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortTimeLeftBy: "DESC",
                          sortDateBy: false,
                          sortBuyerName: false,
                          sortFinancerName: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                    {
                      name: "Oldest First",
                      selected: filter.sortTimeLeftBy === "ASC",
                      onActionClick: () => {
                        setFilter({
                          ...filter,
                          sortTimeLeftBy: "ASC",
                          sortDateBy: false,
                          sortBuyerName: false,
                          sortFinancerName: false
                        });
                        setRefresh(refresh + 1);
                      },
                    },
                  ],
                },
                { name: "Buyer Email" },
                { name: "Required Limit" },
                { name: "Buyer Address" },
                { name: "Country Name" },
                { name: "Expected Shipment Date" },
                { name: "Current Annual Sale" },

                { name: "Action" },
              ]}
              options={[{ name: "Application", onClick: openApplication }]}
              setColumns={updateColumns}
              setData={updateData}
              data={tableData}
              disableAction={false}
              tableContainsNotification={true}
            />
            <Pagination
              perPage={filter.resultPerPage || 10}
              page={page}
              totalCount={count}
              onPageChange={(p) => setPage(p)}
            />
          </main>
        </div>
      </div>
      {tooltip && (
        <TooltipModal
          tooltip={tooltip}
          setTooltip={setTooltip}
          data={{
            showRemark: data.showRemark,
            showLenderName: data.showLenderName,
          }}
        />
      )}
    </>
  );
}
const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Contract)