import React, { useState, useRef, useEffect } from 'react';
import DropdownSearch from '../dropdownWithSearch';
import { DateRangePicker } from 'react-date-range';
import { format, parse } from 'date-fns';
import TargetParameters from './targetParameters';
import { FormControl, InputGroup } from 'react-bootstrap';

const AddTarget = ({
  handleClosePopup,

  pickerRef,
  itemsForCompanies,
  itemsForWhom,
  itemsForTargetTypes,
  setIsPopupVisible,
  allData, onAddTarget, editTarget = {}, variables = []
}) => {
  // console.log("in add taget", editTarget.companies)
  const [showPicker, setShowPicker] = useState(false);

  const [periodSelected, setPeriodSelected] = useState(false);
  const [companiesSelected, setCompaniesSelected] = useState(false);
  const [targetsSelected, setTargetsSelected] = useState(false);
  const [typesSelected, setTypesSelected] = useState(false);
  const [tooManyWhomSelected, setTooManyWhomSelected] = useState(false);
  const [showParameters, setShowParameters] = useState(editTarget.start_date ? true : false); // Corrected state name
  // State variables to track selected items in dropdowns
  const [selectedCompanies, setSelectedCompanies] = useState(editTarget.companies || []);
  const [selectedWhom, setSelectedWhom] = useState(editTarget.whom || []);
  const [selectedTargetTypes, setSelectedTargetTypes] = useState(variables || []);
  const [dateRange, setDateRange] = useState({
    startDate: editTarget.start_date ? editTarget.start_date : null,
    endDate: editTarget.end_date ? editTarget.end_date : null,
    key: 'selection',
  });
  // Ref for the component to handle click outside detection
  const popupRef = useRef(null);

  const formattedDateRange = dateRange.startDate && dateRange.endDate
    ? `${format(dateRange.startDate, 'MM/dd/yyyy')} → ${format(dateRange.endDate, 'MM/dd/yyyy')}`
    : '';

  const handleDateChange = (ranges) => {
    const { selection } = ranges;
    setDateRange(selection);
  };

  // Effect to add event listener when showPicker changes
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Click outside of popup detected, close the picker
        setShowPicker(false);
      }
    };

    // Attach the event listener when showPicker is true
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker, setShowPicker]);

  // Validation function to check if all required fields are filled
  const validateFields = () => {
    let isValid = true;
    if (!formattedDateRange) {
      setPeriodSelected(true);
      isValid = false;
    } else {
      setPeriodSelected(false);
    }
    if (selectedCompanies.length === 0) {
      setCompaniesSelected(true);
      isValid = false;
    } else {
      setCompaniesSelected(false);
    }
    if (selectedWhom.length === 0 || selectedWhom.length > 3) {
      setTargetsSelected(true);
      isValid = false;
    } else {
      setTargetsSelected(false);
    }
    if (selectedTargetTypes.length === 0) {
      setTypesSelected(true);
      isValid = false;
    } else {
      setTypesSelected(false);
    }
    return isValid;
  };

  // Handle selection of Whom items
  const handleWhomSelect = (selectedItems) => {
    if (selectedItems.length > 3) {
      setShowParameters(false)
      setTooManyWhomSelected(true);
    } else {
      setTooManyWhomSelected(false);
      setSelectedWhom(selectedItems);
    }
  };

  // Handle next button click
  const handleNextClick = () => {
    if (validateFields()) {
      // Form is valid, proceed to the next step
      setShowParameters(true);
      console.log("Validation successful. Proceeding to next step...");
    } else {
      // Display error messages or handle validation failure
      console.log("Validation failed. Please fill all required fields.");
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div ref={popupRef} style={{
        background: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        maxWidth: 'fit-content',
        width: "80%"
      }}>

        <div>
          <div className="d-flex justify-content-between align-items-center pb-2 mb-1">
            <div className="d-flex justify-content-between align-items-center"  >
              {/* <img className="bg-transparent d-block cursor-pointer me-2" onClick={handleClosePopup} src="assets/images/back.png" alt="Back" height="20" width="25" /> */}
              <h4 className='m-0 p-0' style={{ color: "blue" }}>Add Target</h4 >
            </div>
            <div className='border rounded-circle shadow d-flex justify-content-center align-items-center' style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              width: "40px",
              backgroundColor: "#858c8c"
              , height: "40px"


            }} onClick={handleClosePopup}><p style={{ fontSize: '20px', fontFamily: "sans-serif", color: "white", backgroundColor: "#858c8c" }} className='p-0 m-0 fw-bold'>X</p></div>
          </div>
          <div className='d-flex justify-content-between align-items-top'>
            <div className='me-2' style={{ width: "250px", height: "100px" }} >
              <label htmlFor="period" className="form-label d-block">
                For which period? *
              </label>
              <InputGroup>
                <FormControl
                  className={`p-1 during-input fs-6 ${periodSelected ? 'is-invalid' : ''}`}
                  type="text"
                  style={{ width: "250px" }}
                  readOnly
                  value={formattedDateRange}
                  onClick={() => setShowPicker((prev) => !prev)}
                  placeholder='MM/DD/YYYY → MM/DD/YYYY'
                />
              </InputGroup>
              {periodSelected && <div style={{ color: 'red' }}>Select period is required.</div>}
              {showPicker && (
                <div
                  className='position-absolute mt-3 border border-secondary'
                  style={{ width: "250px", zIndex: 1150 }}
                  ref={pickerRef}
                >
                  <DateRangePicker
                    ranges={[dateRange]}
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                  />
                </div>
              )}
            </div>
            <div onClick={() => setShowPicker(false)}>
              <label htmlFor="companies" className="form-label d-block">
                For which companies? *
              </label>
              <DropdownSearch
                customStyles={{ widthOfInput: "250px", heightOfInput: '100px' }}
                items={itemsForCompanies}
                placeholder={"Select Companies"}
                selectAll={true}
                multipleSelection={true}
                initialSelectedItems={selectedCompanies}
                onSelect={setSelectedCompanies} // Update selected companies
              />
              {companiesSelected && <p style={{ color: 'red' }}>Select companies is required.</p>}
            </div>
            <div>
              <label htmlFor="whom" className="form-label d-block">
                Set target for whom? * (Up to 3)
              </label>
              <DropdownSearch
                customStyles={{ widthOfInput: "250px", heightOfInput: '100px' }}
                items={itemsForWhom}
                placeholder={"Set Targets"}
                multipleSelection={true}
                initialSelectedItems={selectedWhom}
                onSelect={handleWhomSelect} // Update selected whom
              />
              {targetsSelected && <div style={{ color: 'red' }}>Set targets is required.</div>}
              {tooManyWhomSelected && <div style={{ color: 'red' }}>You can select up to 3 items only.</div>}
            </div>
            <div>
              <label htmlFor="targetTypes" className="form-label d-block">
                Select types of target *
              </label>
              <DropdownSearch
                customStyles={{ widthOfInput: "250px", heightOfInput: '100px' }}
                selectAll={true}
                items={itemsForTargetTypes}
                placeholder={"Select Types of Target"}
                multipleSelection={true}
                initialSelectedItems={selectedTargetTypes}
                isDisabled={tooManyWhomSelected} // Disable if too many items selected
                onSelect={setSelectedTargetTypes} // Update selected target types
              />
              {typesSelected && <div style={{ color: 'red' }}>Select types of target is required.</div>}
            </div>
            {!showParameters ?
              <div className=''>
                <label htmlFor="targetTypes" className="form-label d-block">
                  Go:
                </label>
                <button className='btn btn-primary ' style={{ height: "fit-content", width: "100px" }} onClick={handleNextClick}>Next</button>

              </div> : <></>}


          </div>
        </div>
        {showParameters && (
          <div style={{ height: "60vh", overflowY: "scroll", overflowX: "scroll" }}>
            <TargetParameters
              setShowParameters={setShowParameters}
              selectedCompanies={selectedCompanies}
              selectedWhom={selectedWhom}
              selectedTargetTypes={selectedTargetTypes}
              formattedDateRange={formattedDateRange}
              setIsPopupVisible={setIsPopupVisible}
              allData={allData}
              onAddTarget={onAddTarget}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTarget;