import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CanvasJSReact from './canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LineChart = ({ datapoints, title, x_axis, y_axis }) => {

  //----------------------------------------------------------------------------------------------------------------------------
  //States & Variables

  const [options, setOptions] = useState({});


  //----------------------------------------------------------------------------------------------------------------------------
  //UseEffect
  useEffect(() => {
    setOptions(
      {
        animationEnabled: true,
        title: {
          // text: "Monthly Sales - 2017"
        },
        axisX: {
          title: x_axis,
          valueFormatString: "MMM"
        },
        axisY: {
          title: y_axis,
          // prefix: "$"
        },
        data: [{
          // yValueFormatString: "###, # Buyers",
          // xValueFormatString: "MMMM",
          type: "spline",
          dataPoints: datapoints
        }]
      }
    )

  }, [datapoints.length > 0])

  return (
    <div>
      <CanvasJSChart options={options}
      /* onRef={ref => this.chart = ref} */
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
}

//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineChart);