export default function validate(values, file, financeDetails, userTypeId, tab) {
  let errors = {};

  if (!values.applicantEmail) {
    errors.applicantEmail = 'Applicant is Missing';
  }

  if (!values.buyerId) {
    errors.buyerId = 'Select a Buyer';
  }

  if (!values.referenceNo) {
    errors.referenceNo = 'Enter PO Number';
  }

  if (!values.issueDate) {
    errors.issueDate = 'Enter PO issue date';
  }

  if (!values.contractAmountCurrency) {
    errors.contractAmountCurrency = 'Select a Currency';
  }

  if (!values.contractAmount) {
    errors.contractAmount = 'Enter Contract Amount';
  }

  if (!values.amountCurrency) {
    errors.amountCurrency = 'Select a Currency';
  }

  if (!values.amount) {
    errors.amount = 'Enter Requested Amount';
  }

  if (!values.lenderId) {
    errors.lenderId = 'Select a Lending Bank';
  }

  if (!values.creditDays) {
    errors.creditDays = 'Enter Credit Days';
  }

  if (!file.poDocument && financeDetails.info.action === "applyFinance" && tab === 1) {
    errors.poDocument = 'Upload the PO Copy';
  }

  if (!values.interestRate && userTypeId === 8) {
    errors.interestRate = 'Enter Interest Rate';
  }

  console.log("error in finance form==>", errors)

  return errors;
}
