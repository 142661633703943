import React, { useEffect, useState } from 'react';
import { setCreditlineDataBank, setCreditDocuments } from '../../store/actions/action';
import { connect } from 'react-redux';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import toastDisplay from '../../utils/toastNotification';
import columns from '../dataTablesColumsMap/reportsListColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import { formatDate_Application } from '../../utils/dateFormaters';

const Reports = ({ userTokenDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [bankData, setBankData] = useState({});
  const [supplierList, setSupplierList] = useState({});
  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const [buyerList, setBuyerList] = useState({});
  const [lead, setLead] = useState({ "modal": false, "id": 0 });
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;
  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    call('POST', 'getBankersList', {})
      .then((result) => {
        console.log("result in getBankersList===>", result)
        setBankData(result);
      })
      .catch((err) => {
        console.log("error:", err)
      })

    call('POST', 'getBuyerReport').then((result) => {
      console.log('running getBuyerReport api-->', result);
      setSupplierList(result.supplierList);
      setBuyerList(result.buyerList);
      setChannelPartnerList(result.channelPartnerList)
    }).catch((e) => {
      console.log('error in getBuyerReport', e);
    })

  }, []);

  useEffect(() => {

    fetchTableData(1, {});

  }, [filterData]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  console.log('error => ', error)

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  };

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    if (!data.leadBy) {
      setError({ ...error, ["leadBy"]: "Please select lead by" });
    }
    else {
      setError({});
    }
    setIsSubmitting(true);
  };

  const handleSubmit = () => {

    data.buyer_id = lead.id;

    setshowLoader(true);
    call('POST', 'updateLeadBy', data).then((result) => {
      setshowLoader(false)
      setLead({ "modal": false, "id": 0 })
      toastDisplay(result, "success")
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");
  const [completeButton, setcompleteButton] = useState(0);

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyerReport', objectAPI).then((result) => {
      console.log('running getBuyerReport api-->', result);
      settableData(formatDataFortable(result.reportData));
      setTotalRows(result.countData);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getBuyerReport', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyerReport', objectAPI).then((result) => {
      settableData(formatDataFortable(result.reportData));
      setTotalRows(result.countData);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getBuyerReport', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.dateofestablishment = dataArray[i].date_of_establishment;
      tempObj.lead_by = (dataArray[i].lead_by ? dataArray[i].lead_by : 'NA');
      tempObj.suppliername = dataArray[i].supplier_name;
      tempObj.industryType = (dataArray[i].supplier_sector ? dataArray[i].supplier_sector : 'NA');
      tempObj.buyerDUNS = dataArray[i].buyer_DUNS;
      tempObj.buyerCountry = dataArray[i].buyer_country;
      tempObj.buyerCountryName = dataArray[i].buyer_country_name;
      tempObj.buyerTermsOfPayment = dataArray[i].buyer_terms_of_payment;
      tempObj.buyerProductDetails = dataArray[i].buyer_product_details;
      tempObj.buyerPreviousYearSale = dataArray[i].buyer_previous_sale;
      tempObj.buyerExpectedSale = dataArray[i].buyer_expected_sale;
      tempObj.buyername = dataArray[i].buyer_name;
      tempObj.stennLimit = (dataArray[i].stenn ? dataArray[i].stenn : 'NA');
      tempObj.stennRemarks = (dataArray[i].stenn_remark ? dataArray[i].stenn_remark : 'NA');
      tempObj.modifiLimit = (dataArray[i].modifi ? dataArray[i].modifi : 'NA');
      tempObj.modifiRemarks = (dataArray[i].modifi_remark ? dataArray[i].modifi_remark : 'NA');
      tempObj.tradewindLimit = (dataArray[i].tradewind ? dataArray[i].tradewind : 'NA');
      tempObj.tradewindRemarks = (dataArray[i].tradewind_remark ? dataArray[i].tradewind_remark : "NA");
      tempObj.cp_contact_person = dataArray[i].cp_contact_person || "NA"
      tempObj.cp_email_id = dataArray[i].cp_email_id || "NA"
      tempObj.cp_contact_no = dataArray[i].cp_contact_no || "NA"

      tempObj.created_at = formatDate_Application(dataArray[i].date);
      tempObj.action = <button className="btn btn-success btn-sm" onClick={() => setLead({ "modal": true, "id": dataArray[i].buyer_id })}>
        <i className={"fa fa-pencil-square-o"}></i>
      </button>

      resultArray.push(tempObj)
    }
    return resultArray
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setFilterData({ supplierType: "", buyerType: "", cpType: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      // "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyerReport', objectAPI).then((result) => {
      console.log('running getBuyerReport api in csv-->', result);

      const dbResult = result.reportData
      let csvString = "Channel Partner Name,Channel Partner Email,Channel Partner Contact,Lead By,Date of Establishment,Supplier Name,Industry Type,Buyer Name,DUNS No,Country,Country Name,Terms of Payment,Product Details,Previous Annual Sale,Expected Sale,Stenn,Remark,Modifi,Remark,Tradewind,Remark,Created At\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${(dbResult[i].cp_contact_person ? dbResult[i].cp_contact_person : 'NA')},
        ${(dbResult[i].cp_email_id ? dbResult[i].cp_email_id : 'NA')},
        ${(dbResult[i].cp_contact_no ? dbResult[i].cp_contact_no : 'NA')},
        ${(dbResult[i].lead_by ? dbResult[i].lead_by : 'NA')},
        ${dbResult[i].date_of_establishment},
        ${dbResult[i].supplier_name},
        ${dbResult[i].supplier_sector ? dbResult[i].supplier_sector : 'NA'},
        ${dbResult[i].buyer_name},
        ${dbResult[i].buyer_DUNS},
        ${dbResult[i].buyer_country},
        ${dbResult[i].buyer_country_name},
        ${dbResult[i].buyer_terms_of_payment},
        ${dbResult[i].buyer_product_details},
        ${dbResult[i].buyer_previous_sale},
        ${dbResult[i].buyer_expected_sale},
        ${(dbResult[i].stenn ? dbResult[i].stenn : 'NA')},
        ${(dbResult[i].stenn_remark ? dbResult[i].stenn_remark : 'NA')},
        ${(dbResult[i].modifi ? dbResult[i].modifi : 'NA')},
        ${(dbResult[i].modifi_remark ? dbResult[i].modifi_remark : 'NA')},
        ${(dbResult[i].tradewind ? dbResult[i].tradewind : 'NA')},
        ${(dbResult[i].tradewind_remark ? dbResult[i].tradewind_remark : 'NA')},
        ${formatDate_Application(dbResult[i].date)}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `User_Report.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((e) => {
      console.log('error in getLoans', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'reports'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title="Reports"
              // total={(creditlineDataBank.modal) ? "" : ("Total - " + count + " Users")}
              userTokenDetails={userTokenDetails} />

            <div class="d-md-flex mt-3 mb-3">
              <div class="ml-md-auto">
                <ul class="range-slider">
                  <li>
                    <label>From</label>
                    <input type="date" id="searchFromdate" name="fromDate" onChange={filterState} />
                  </li>
                  <li>
                    <label>To</label>
                    <input type="date" id="searchTodate" name="toDate" onChange={filterState} />
                  </li>
                  {/* <li>
                    <select className="form-control" name="lenderType" value={filterData.lenderType} onChange={(event) => { setFilterData({ lenderType: event.target.value }) }}>
                      <option value="" selected>--Select Lender--</option>
                      {bankData && bankData.length > 0 && bankData.map((bankList) => {
                        return (<option value={bankList.tbl_user_id + ':' + bankList.company_name}>{bankList.company_name}</option>)
                      })}
                    </select>
                  </li> */}
                  {userTypeId == 1 && <li>
                    <select className="form-control" name="supplierType" value={filterData.cpType} onChange={(event) => { setFilterData({ cpType: event.target.value }) }}>
                      <option value="" selected>--Select Channel Partner--</option>
                      {channelPartnerList && channelPartnerList.length > 0 && channelPartnerList.map((cp) => {
                        return (<option value={cp.id + ':' + cp.name}>{cp.contact_person}</option>)
                      })}
                    </select>
                  </li>}
                  {userTypeId == 1 && <li>
                    <select className="form-control" name="supplierType" value={filterData.supplierType} onChange={(event) => { setFilterData({ supplierType: event.target.value }) }}>
                      <option value="" selected>--Select Supplier--</option>
                      {supplierList && supplierList.length > 0 && supplierList.map((supplier) => {
                        return (<option value={supplier.id + ':' + supplier.name}>{supplier.name}</option>)
                      })}
                    </select>
                  </li>}
                  <li>
                    <select className="form-control" name="buyerType" value={filterData.buyerType} onChange={(event) => { setFilterData({ buyerType: event.target.value }) }}>
                      <option value="" selected>--Select Buyer--</option>
                      {buyerList && buyerList.length > 0 && buyerList.map((buyer) => {
                        return (<option value={buyer.id + ':' + buyer.name}>{buyer.name}</option>)
                      })}
                    </select>
                  </li>
                  <li>
                    <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                  </li>
                </ul>
              </div>
            </div>

            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
            <hr />
            <div className="float-left col-md-6 ">
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
                  </button>
                </div>
              </div>
            </div>

            {lead.modal &&
              <div className={"modal" + (lead.modal ? " show" : "")} id="leadmodal">
                <div className="modal-dialog modal-xs border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Select Lead By</h4>
                      <button type="button" className="close" onClick={() => { setLead({ "modal": false, "id": 0 }); }}>×</button>
                    </div>
                    <div className="modal-body">
                      <div className="col-md-12 m-2 row">

                        <div className="col-md-12">
                          <div className="row text-center form-group">
                            <div className="col-md-12">
                              <select className="form-control" name="leadBy" value={data.leadBy} onChange={handleChange}>
                                <option value="" selected>--Select Lead By--</option>
                                <option value="Arpit">Arpit</option>
                                <option value="Ravi">Ravi</option>
                                <option value="Fiza">Fiza</option>
                                <option value="Ayushi">Ayushi</option>
                                <option value="Garima">Garima</option>
                                <option value="Zarina">Zarina</option>
                                {/* <option value="Simran">Simran</option> */}
                                <option value="Mukta">Mukta</option>
                                <option value="Dhrumi">Dhrumi</option>
                                <option value="Dhruvi">Dhruvi</option>
                              </select>
                              {error.leadBy ? <div class="text-danger error-contract">{error.leadBy}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <hr className="col-md-11" />
                      </div>
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => { setLead({ "modal": false, "id": 0 }); }}>Cancel</button>
                      <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>}

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports)