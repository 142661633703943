import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../../service';
import customStyles from '../../../dataTablesColumsMap/customTableCss';
import Header from '../../../partial/header';
import Sidebar from '../../../partial/sidebar';
import moment from 'moment';
import Footer from '../../../partial/footer';
import NegotiationPopup from './negotiationPopup';
import QuotationslistV2 from '../../../logistics/quotationslistV2';
import Pagination from "react-js-pagination";

const ShipmentQuotationsDashboard = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {

  const [showRateListPopup, toggleRateListPopup] = useState(false)
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [completeButton, setcompleteButton] = useState(0)
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });
  const [dbData, setdbData] = useState({})
  const [searchkey, setSearchKey] = useState("");
  const [showNegPopup, toggleNegPopup] = useState(false)
  const [shipmentData, setShipmentData] = useState({})
  const [popupType, setpopupType] = useState(null)
  const [totalCount, settotalCount] = useState(0)
  const [page, setpage] = useState(1)
  const [showMarketplaceShipments, setshowMarketplaceShipments] = useState(0)

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  useEffect(() => {
    setpage(1)
    fetchShippingList(1)
  }, [filterData, refresh])

  const fetchShippingList = (pageNo) => {
    setshowLoader(true);
    call('POST', 'getRaisedShippingMarketPlaceBookings', {
      // "owner_id": userId,
      // "sortColum": filterData.sortColum,
      // "sortDirection": filterData.sortDirection
      // "dataInSlot": (filterData.search || (filterData.from && filterData.to)) ? false : true,
      "from": filterData.from,
      "to": filterData.to,
      "search": filterData.search,
      userId,
      page: pageNo
    }).then((result) => {
      // console.log('running getShippingRates api-->', result);
      setdbData(result)
      settotalCount(result.count)
      // settableData(formatDataFortable(result.message))
      // setTotalRows(result.count)
      // setCount(result.count)
      setshowLoader(false);
    }).catch((e) => {
      // console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })
  }

  const handlePageChange = page => {
    setpage(page)
    fetchShippingList(page)
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
    // console.log("filter data", filterData);
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      // console.log("dataArray[i]===>", dataArray[i])
      let tempObj = {}

      tempObj.origin_port = dataArray[i].origin_port ? dataArray[i].origin_port : "NA"
      tempObj.destination_port = dataArray[i].destination_port ? dataArray[i].destination_port : "NA"
      tempObj.container_type = dataArray[i].container_type ? dataArray[i].container_type : "NA"
      tempObj.container_no = dataArray[i].container_no ? dataArray[i].container_no : "NA"
      tempObj.cargo_type = dataArray[i].cargo_type ? dataArray[i].cargo_type : "NA"
      tempObj.start_date = dataArray[i].start_date ? moment(dataArray[i].start_date).format("YYYY-MM-DD") : "NA"
      tempObj.end_date = dataArray[i].end_date ? moment(dataArray[i].end_date).format("YYYY-MM-DD") : "NA"
      tempObj.load_volume = dataArray[i].load_volume ? dataArray[i].load_volume : "NA"
      tempObj.load_weight = dataArray[i].load_weight ? dataArray[i].load_weight : "NA"
      tempObj.vessel_type = dataArray[i].vessel_type ? dataArray[i].vessel_type : "NA"
      tempObj.commodity_type = dataArray[i].commodity_type ? dataArray[i].commodity_type : "NA"
      tempObj.flexibility_days = dataArray[i].flexibility_days ? dataArray[i].flexibility_days : "NA"
      tempObj.price = dataArray[i].price ? dataArray[i].price : "NA"
      tempObj.created_at = dataArray[i].created_at ? moment(dataArray[i].created_at).format("YYYY-MM-DD") : "NA"
      resultArray.push(tempObj)
    }
    return resultArray
  }

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  const getOfferStatusLabel = data => {
    console.log("getOfferStatusLabel", data);
    if (!data.updated_at) {
      return "Offer Sent"
    }
    if (data.agreed_by_trader && data.agreed_by_shipper) {
      return "Shipment locked"
    }
    if (data.agreed_by_shipper) {
      if (data.updated_by == userId) {
        return "Updated & Agreed by you"
      }
      else {
        return "Agreed by you"
      }
    }
    if (data.agreed_by_trader) {
      if (data.updated_by != userId) {
        return "Updated & Agreed trader company"
      }
      else {
        return "Agreed by trader company"
      }
    }
    if (data.updated_by == userId) {
      return "Updated by you"
    }
    else {
      return "Updated by trader company"
    }
  }

  function createRateListBody(data) {
    // console.log('data receved', data);
    return (
      data && data.map(item => {
        return (
          <>
            <li className={"d-flex mb-2 " + (item.mode === "Container" ? "green-bg" : "blue-bg")}>
              <div className="ntf_time">
                <label>{moment(item.booking_created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
              </div>
              <div className="ntf_content">
                <ul className="p-0 d-flex">
                  {item.mode_type ? (
                    <li className="col-md-4">
                      <label>{item.mode_type}</label>
                      <span>{"Mode Type"}</span>
                    </li>
                  ) : null}
                  {item.origin_country ? (
                    <li className="col-md-4">
                      <label>{item.origin_country}</label>
                      <span>Origin Country</span>
                    </li>
                  ) : null}
                  {item.origin_port ? (
                    <li className="col-md-4">
                      <label>{item.origin_port}</label>
                      <span>Origin Port</span>
                    </li>
                  ) : null}
                  {item.destination_country ? (
                    <li className="col-md-4">
                      <label>{item.destination_country}</label>
                      <span>Destination Country</span>
                    </li>
                  ) : null}
                  {item.destination_port ? (
                    <li className="col-md-4">
                      <label>{item.destination_port}</label>
                      <span>Destination Port</span>
                    </li>
                  ) : null}
                  {item.container_type ? (
                    <li className="col-md-4">
                      <label>{item.container_type}</label>
                      <span>Container Type</span>
                    </li>
                  ) : null}
                  {item.container_no ? (
                    <li className="col-md-4">
                      <label>{item.container_no}</label>
                      <span>Container No</span>
                    </li>
                  ) : null}
                  {item.cargo_type ? (
                    <li className="col-md-4">
                      <label>{item.cargo_type}</label>
                      <span>Cargo Type</span>
                    </li>
                  ) : null}
                  {item.start_date ? (
                    <li className="col-md-4">
                      <label>{moment(item.start_date).format("YYYY-MM-DD")}</label>
                      <span>Start Date (yyyy-mm-dd)</span>
                    </li>
                  ) : null}
                  {item.end_date ? (
                    <li className="col-md-4">
                      <label>{moment(item.end_date).format("YYYY-MM-DD")}</label>
                      <span>End Date (yyyy-mm-dd)</span>
                    </li>
                  ) : null}
                  {item.load_volume ? (
                    <li className="col-md-4">
                      <label>{item.load_volume}</label>
                      <span>Load Volume (cubic meter)</span>
                    </li>
                  ) : null}
                  {item.load_weight ? (
                    <li className="col-md-4">
                      <label>{item.load_weight}</label>
                      <span>Load Weight (Kg)</span>
                    </li>
                  ) : null}
                  {item.vessel_type ? (
                    <li className="col-md-4">
                      <label>{item.vessel_type}</label>
                      <span>Vessel Type</span>
                    </li>
                  ) : null}
                  {item.commodity_type ? (
                    <li className="col-md-4">
                      <label>{item.commodity_type}</label>
                      <span>Commodity Type</span>
                    </li>
                  ) : null}
                  {item.flexibility_days ? (
                    <li className="col-md-4">
                      <label>{item.flexibility_days}</label>
                      <span>Flexibility Days</span>
                    </li>
                  ) : null}
                  {item.price ? (
                    <li className="col-md-4">
                      <label>{item.price}</label>
                      <span>Price ($)</span>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="ntf_mode">
                <>
                  <h6>{(item.status === 0 ? "Query Raised" : item.status === 1 ? "Offer Sent" : "Shipment Locked")}</h6>
                </>
              </div>
            </li>

            <li className={"d-flex mb-2 " + (item.mode === "Container" ? "green-bg" : "blue-bg")}>
              <div className="ntf_content">
                <ul className="p-0 d-flex">
                  <li className="col-md-4">
                    {item.offer_details_obj ? (
                      <>
                        <span>{"Offer Sent On : " + moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</span>
                        {item.updated_at ? (
                          <span>{"Updated On : " + moment(item.updated_at).format("YYYY-MM-DD hh:mm:ss")}</span>
                        ) : null}
                        <span>{"Status : " + (getOfferStatusLabel(item))}</span>
                        {item.agreed_by_shipper && item.agreed_by_trader ? (
                          <button type="button" className="btn btn-info btn-sm" onClick={
                            () => {
                              setShipmentData({ ...item.offer_details_obj, contactUserId: item.offer_details_obj.owner_id });
                              toggleNegPopup(true); setpopupType(3);
                            }}
                            id="view_offer">{"View Offer & Contact Details"}</button>
                        ) : (
                          <button type="button"
                            disabled={item.status === 2} className="btn btn-primary btn-sm float-left" onClick={
                              () => {
                                setShipmentData({ ...item.offer_details_obj, offer_id: item.id })
                                toggleNegPopup(true)
                                setpopupType(2)
                              }
                            }>Update Offer</button>

                        )}
                      </>
                    ) : (
                      <button disabled={item.status === 2}
                        type="button" className="btn btn-primary btn-sm float-left" onClick={
                          () => {
                            setShipmentData(item)
                            toggleNegPopup(true)
                            setpopupType(0)
                          }
                        }>Send Offer</button>
                    )
                    }
                  </li>
                </ul>
              </div>
            </li>
          </>
        )
      })
    )
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      applyFilter()
    }
  };

  function applyFilter() {
    setFilterData(filterData => ({ ...filterData, "search": searchkey }));
  }

  const handleChange = (event) => {
    event.persist();
    setFilterData(filterData => ({ ...filterData, [event.target.name]: event.target.value }));
  };

  function resetFilter() {
    setFilterData({ ...filterData, search: "", from: "", to: "" })
    setSearchKey("")
  }

  return (
    <>
      {showNegPopup ? (
        <NegotiationPopup closePopup={() => { toggleNegPopup(false); setrefresh(refresh + 1) }} shipment_data={shipmentData} userId={userId}
          userTypeId={userTypeId} popupType={popupType}
        />
      ) : null}
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        {/* {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)} */}
        <div className='row'>
          <Sidebar state={'my-quotations'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={'My Shipment Quotations'}
              // total={'Total Finance Transactions'}
              userTokenDetails={userTokenDetails}
              selectedView={showMarketplaceShipments}
              onViewChange={(index) => setshowMarketplaceShipments(index)}
            />
            {
              showMarketplaceShipments === 1 ? (
                <QuotationslistV2 userTokenDetails={userTokenDetails} />
              ) : (
                <>
                  <div className="d-md-flex mt-3 mb-3 notify_list">
                    <div className="mr-md-auto">
                      <ul className="view-slider">
                        <li className="green-bg">
                          <label>Container</label>
                        </li>
                        <li className="blue-bg">
                          <label>Vessel</label>
                        </li>
                      </ul>
                    </div>
                    <div className="ml-md-auto relative">
                      {/* {filter === true && <div className="reset" onClick={() => resetFilter()}>reset</div>} */}
                      <ul className="range-slider">
                        <li>
                          <input placeholder="Search" name="search" className="form-control" value={searchkey} onChange={(event) => { setSearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                          <button onClick={() => applyFilter()}><i class="fas fa-search fa-lg"></i></button>
                        </li>
                        <li>
                          <label>From</label>
                          <input type="date" name="from" className="form-control" value={filterData.from} onChange={handleChange} />
                        </li>
                        <li>
                          <label>To</label>
                          <input type="date" name="to" className="form-control" value={filterData.to} onChange={handleChange} />
                        </li>
                        <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => resetFilter()} /></li>
                      </ul>
                    </div>
                  </div>

                  <div className="notification_listing">
                    <div className="">
                      <div className={"accordionItem" + (accorddata.accord_0 ? " open" : " closed")}>
                        <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}>Marketplace Shipment Quotations</h2>
                        <div className="accordionItemContent">
                          <div className="nt_data">
                            <ul className="p-0  Ul-list">
                              {createRateListBody(dbData.data)}
                              {dbData.data && dbData.data.length ? "" :
                                <h1 className="accordionItemHeading">No Records Found</h1>}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className={"accordionItem" + (accorddata.accord_1 ? " open" : " closed")}>
                          <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_1')}> Last 7 Days</h2>
                          <div className="accordionItemContent">
                            <div className="nt_data">
                              <ul className="p-0  Ul-list">
                                {createRateListBody(dbData.sevendayAddedRates)}
                                {dbData.sevendayAddedRates && dbData.sevendayAddedRates.length ? "" :
                                  <h1 className="accordionItemHeading">No Records Found </h1>}
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      {/* <div className={"accordionItem" + (accorddata.accord_2 ? " open" : " closed")}>
                          <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_2')}> Older</h2>
                          <div className="accordionItemContent">
                            <div className="nt_data">
                              <ul className="p-0  Ul-list">
                                {createRateListBody(dbData.olderAddedRates)}
                                {dbData.olderAddedRates && dbData.olderAddedRates.length ? "" :
                                  <h1 className="accordionItemHeading">No Records Found </h1>}
                              </ul>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                  <div className='row justify-content-end pr-3'>
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalCount}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                      activeLinkClass="bg-primary"
                    />
                  </div>
                  <hr />
                </>
              )}
          </main>
        </div>
      </div>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}

const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentQuotationsDashboard)